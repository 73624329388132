/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {SubscriptionsConstants} from './subscriptions-actions';
import Dispatcher from '../../../dispatcher/dispatcher';
import {TitleConstants} from '../../../titles/title-actions';

/**
 * Look for a constant with id === objectId in a map.
 */
let findInConstant = function(constants, objectId) {
    return Immutable.fromJS(
        Object.keys(constants)
            .map(k => constants[k])
            .filter(obj => objectId === obj.id)[0]
    );
};

class SubscriptionsStore extends ReduceStore {

    getInitialState() {
        let state = Immutable.Map({
            recentlyWatched: Immutable.List(),
            rwOffset: 0,
            rwSize: 20,
            rwTotalCount: 0,
            subscriptions: Immutable.List(),
            subscriptionType: SubscriptionsConstants.toArray(SubscriptionsConstants.SUBSCRIPTION_CONTENT_TYPES),
            titlesToAdd: Immutable.List(),
            totalCount: 0
        });

        state = state.set('originalSubscriptions', state.get('subscriptions'));

        return state;
    }

    getSubscriptionContentType(id) {
        return findInConstant(SubscriptionsConstants.SUBSCRIPTION_CONTENT_TYPES, id);
    }

    reduce(state, action) {
        switch (action.actionType) {

        case SubscriptionsConstants.CLEAR:
            state = this.getInitialState();
            break;

        case SubscriptionsConstants.CLONE:
        case SubscriptionsConstants.SAVE:
            state = state.set('originalSubscriptions', state.get('subscriptions'));
            break;

        case SubscriptionsConstants.GET.ASSETS.SUCCESS:
            let assets = action.watchListAssets.map(a => {
                return Immutable.Map({
                    id: a.get('id'),
                    name: a.get('assetName'),
                    title: a.get('displayTitle')
                });
            });
            state.get('recentlyWatched').map((rw, i) => {
                let asset = assets.find(a => a.get('id') === rw.get('assetId')) || Immutable.Map();
                state = state.mergeIn(['recentlyWatched', i], {
                    name: asset.get('name') || '-',
                    title: asset.get('title') || '-'
                });
            });
            break;

        case SubscriptionsConstants.GET.EPISODE:
            state.get('subscriptions').map((s, i) => {
                if (s.get('titleId') === action.episodeId) {
                    state = state.mergeIn(['subscriptions', i], {
                        episodeData: action.episodeData
                    });
                }
            });
            break;

        case SubscriptionsConstants.GET.SUBSCRIPTIONS.SUCCESS:
            state = state.merge({
                originalSubscriptions: action.subscriptions,
                subscriptions: action.subscriptions,
                totalCount: action.totalCount
            });
            break;

        case SubscriptionsConstants.GET.TITLES.SUCCESS:
            let titles = action.subscriptionsTitles.map(t => {
                return Immutable.Map({
                    id: t.get('id'),
                    categoryId: t.get('category'),
                    episodeNumber: t.get('runningOrder') || '',
                    name: t.get('name') || '',
                    seasonNumber: t.get('season') || ''
                });
            });
            state.get('subscriptions').map((s, i) => {
                let title = titles.find(t => t.get('id') === s.get('titleId')) || Immutable.Map();
                state = state.mergeIn(['subscriptions', i], {
                    categoryId: title.get('categoryId'),
                    episodeNumber: title.get('episodeNumber'),
                    seasonNumber: title.get('seasonNumber'),
                    titleName: title.get('name')
                });
            });
            break;

        case SubscriptionsConstants.GET.WATCHLIST.SUCCESS:
            state = state.merge({
                recentlyWatched: action.recentlyWatched,
                rwOffset: action.rwOffset,
                rwTotalCount: action.rwTotalCount
            });
            break;

        case SubscriptionsConstants.SUBSCRIPTION.ADD:
            state = state.updateIn(['subscriptions'], subscriptions => {
                let titleName = action.title.get('name');
                switch (action.title.get('category')) {
                case TitleConstants.TITLE_TYPES.ANIMATED_SERIES_SEASON.id:
                case TitleConstants.TITLE_TYPES.CARTOONS_SEASON.id:
                case TitleConstants.TITLE_TYPES.DOCUSERIES_SEASON.id:
                case TitleConstants.TITLE_TYPES.GAME_SHOW_SEASON.id:
                case TitleConstants.TITLE_TYPES.LIMITED_SERIES_SEASON.id:
                case TitleConstants.TITLE_TYPES.REALITY_SEASON.id:
                case TitleConstants.TITLE_TYPES.SEASON_HALF_HOUR.id:
                case TitleConstants.TITLE_TYPES.SEASON_ONE_HOUR.id:
                case TitleConstants.TITLE_TYPES.SHORT_PROGRAMS_SEASON.id:
                case TitleConstants.TITLE_TYPES.TALK_SHOW_SEASON.id:
                    titleName = `${titleName} - Season ${action.title.get('season')}`;
                    break;
                case TitleConstants.TITLE_TYPES.EPISODE.id:
                    titleName = `${titleName} - ${action.title.get('parentTitleDisplayName') || ''} ${action.title.get('fullRunningOrder') || ''}`;
                    break;
                }
                return subscriptions.push(Immutable.Map({
                    subscriptionContentType: action.subscriptionContentType,
                    subscriptionType: TitleConstants.SUBSCRIPTION_CONTENT_TYPES[action.subscriptionContentType],
                    suggestedByUserId: action.suggestedByUserId,
                    suggestedByUserName: action.suggestedByUserName,
                    titleId: action.titleId,
                    titleName: titleName
                }));
            });
            break;

        case SubscriptionsConstants.SUBSCRIPTION.REMOVE:
            state = state.updateIn(['subscriptions'], subscriptions => {
                return subscriptions.delete(action.index);
            });
            break;

        case SubscriptionsConstants.SUBSCRIPTION.TITLE.ADD:
            state = state.updateIn(['titlesToAdd'], titlesToAdd => {
                return titlesToAdd.push(action.title);
            });
            break;

        case SubscriptionsConstants.SUBSCRIPTION.TITLE.CLEAR:
            state = state.set('titlesToAdd', Immutable.List());
            break;

        case SubscriptionsConstants.SUBSCRIPTION.TITLE.REMOVE:
            let index = state.get('titlesToAdd').findIndex(t => t.get('id') === action.id);
            state = state.updateIn(['titlesToAdd'], titlesToAdd => {
                return titlesToAdd.delete(index);
            });
            break;

        }

        return state;
    }
}

export default new SubscriptionsStore(Dispatcher);
