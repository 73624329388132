/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, ControlLabel, FormGroup, Modal} from 'react-bootstrap';

import {EventActions, EventConstants} from '../event-actions';
import EventStore from '../event-store';

import FileInput from '~/src/common/file-input';
import {FormItem, FormSection, FormRow} from '~/src/common/form/form';
import TinyMCE from '~/src/common/tinymce/tinymce';

class Notifications extends React.Component {

    static get propTypes() {
        return {
            disabled: PropTypes.bool,
            event: PropTypes.instanceOf(Immutable.Map).isRequired,
            lastEventEmailSent: PropTypes.string.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            isEmailNotificationModalOpen: false,
            isModalOpen: false,
            sendMailDisabled: false
        });

        this.handleChangeNotificationType = this.handleChangeNotificationType.bind(this);
        this.handleTinyMCEChange = this.handleTinyMCEChange.bind(this);
        this.sendManualEmailNotification = this.sendManualEmailNotification.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.toggleEmailNotificationModal = this.toggleEmailNotificationModal.bind(this);
    }

    componentDidMount() {
        this.interval = setInterval(() => this.tick(), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    handleChangeNotificationType(e) {
        EventActions.updateEvent('eventUserNotificationType', e.target.value);
    }

    handleImageChange(e) {
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        EventActions.updateEvent('customNotificationFile', e.target.files[0]);
        EventActions.updateEvent('customNotificationImageS3', URL.createObjectURL(e.target.files[0]));
    }

    handleTinyMCEChange(attr) {
        return (e) => {
            EventActions.updateEvent(attr, e.target.getContent());
            return;
        };
    }

    removeImage() {
        EventActions.updateEvent('customNotificationFile', null);
        EventActions.updateEvent('customNotificationImageS3', null);
    }

    sendManualEmailNotification() {
        this.interval = setInterval(() => this.tick(), 1000);
        EventActions.sendManualEmailNotification(this.props.event);
        this.toggleEmailNotificationModal();
    }

    tick() {
        const timezone = this.props.event.get('displayTimeZone');
        const lastSentNotification = Moment.tz(this.props.lastEventEmailSent, timezone).add(1, 'minutes');
        const now = Moment();
        this.setState(()=>({
            sendMailDisabled: Moment(now).isBefore(lastSentNotification)
        }));

        if (!Moment(now).isBefore(lastSentNotification)) {
            clearInterval(this.interval);
        }
    }

    toggleModal() {
        this.setState((prevState)=>({
            isModalOpen: !prevState.isModalOpen
        }));
    }

    toggleEmailNotificationModal() {
        this.setState((prevState)=>({
            isEmailNotificationModalOpen: !prevState.isEmailNotificationModalOpen
        }));
    }

    render() {
        let customNotificationImageUrl = this.props.event.get('customNotificationImageS3');

        if (this.props.event.get('customNotificationImageS3')?.substring(0, 5) !== 'blob:') {
            customNotificationImageUrl = `${location.origin}/${this.props.event.get('customNotificationImageS3')}`;
        }

        const customNotificationValidations = EventStore.getCustomEmailValidations();

        const radioEventNotificationType = (value, event) => {
            return (
                <div>
                    <label>
                        <strong>
                            <input
                                checked={event.get('eventUserNotificationType') === value.id}
                                disabled={this.props.disabled}
                                id={value.id}
                                name="eventUserNotificationType"
                                onChange={this.handleChangeNotificationType}
                                type="radio"
                                value={value.id}
                            />&nbsp;{this.context.intl.messages[value.message]}
                        </strong>
                    </label>
                    <p>
                        <small><em>{this.context.intl.messages[`${value.message}.description`]}</em></small>
                    </p>
                </div>
            );
        };

        let notificationCustomImageButtons = <FileInput
            id="custom-notification-image"
            accept="image/*"
            disabled={this.props.disabled}
            files={[]}
            onChange={this.handleImageChange}
            withoutFormSection={true}
            withoutTable={true}
        />;

        if (this.props.event.get('customNotificationImageS3')) {
            notificationCustomImageButtons = <div className="email-image-section">
                <Button className="btn btn-md btn-default-outline custom-notification-button" onClick={this.toggleModal}>
                    <i className="fa fa-eye"></i>&nbsp;{this.context.intl.messages['events.notifications.custom-image.button.view']}
                </Button>
                <Button className="btn btn-md btn-danger-outline" disabled={this.props.disabled} onClick={this.removeImage}>
                    <i className="fa fa-trash"></i>&nbsp;{this.context.intl.messages['events.notifications.custom-image.button.remove']}
                </Button>
            </div>;
        }

        let sendCustomEmail;
        if (this.props.event.get('eventUserNotificationType') === EventConstants.EVENT_USER_NOTIFICATION_TYPES.CUSTOM_NOTIFICATION.id) {
            const subjectLabel = <span>{this.context.intl.messages['events.notifications.options.send-custom.subject']}&nbsp;<span className="text-red">*</span></span>;
            sendCustomEmail = <div style={{paddingBottom: '20px'}}>
                <FormRow>
                    <FormGroup>
                        <FormItem attr="customNotificationSubject"
                            disabled={this.props.disabled}
                            label={subjectLabel}
                            model={this.props.event}
                            placeholder={this.context.intl.messages['events.notifications.options.send-custom.subject.placeholder']}
                            setter={EventActions.updateEvent}
                            type="text"
                        />
                    </FormGroup>
                </FormRow>
                <FormRow>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['events.notifications.partner-logo.title']}</ControlLabel>
                        <p>
                            <small>
                                <em>{this.context.intl.messages['events.notifications.partner-logo.description']}</em>
                            </small>
                        </p>
                        <FormItem attr="showLogo"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['events.notifications.partner-logo.label']}
                            model={this.props.event}
                            setter={EventActions.updateEvent}
                            type="checkbox"
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['events.notifications.custom-image.title']}</ControlLabel>
                        <p>
                            <small>
                                <em>{this.context.intl.messages['events.notifications.custom-image.description']}</em>
                            </small>
                        </p>
                        {notificationCustomImageButtons}
                    </FormGroup>
                </FormRow>
                <FormRow>
                    <ControlLabel>{this.context.intl.messages['events.notifications.options.send-custom.message-body']}&nbsp;<span className="text-red">*</span></ControlLabel>
                </FormRow>
                <FormRow>
                    <TinyMCE
                        allowLink={true}
                        content={this.props.event.get('customNotificationMessage') || ''}
                        disabled={this.props.disabled}
                        height={250}
                        id="customNotificationMessage"
                        pasteAsText={false}
                        resize
                        onChange={this.handleTinyMCEChange('customNotificationMessage')}
                    />
                </FormRow>
                <Button
                    bsStyle="primary"
                    onClick={this.toggleEmailNotificationModal}
                    style={{marginTop: 20}}
                    disabled={this.state.sendMailDisabled || this.props.disabled || customNotificationValidations.length}
                >
                    <i className="fa fa-envelope"></i>&nbsp;{this.context.intl.messages['events.notifications.send-email-notification.button']}
                </Button>
            </div>;
        }

        return (
            <div className="event-notifications">
                <h3><i className="far fa-paper-plane"></i>{this.context.intl.messages['events.notifications.title']}</h3>
                <h4>
                    {this.context.intl.messages['events.notifications.description']}
                </h4>
                <FormSection>
                    <FormRow>
                        <FormGroup>
                            {radioEventNotificationType(EventConstants.EVENT_USER_NOTIFICATION_TYPES.NO_NOTIFICATION, this.props.event)}
                        </FormGroup>
                    </FormRow>
                    <hr />
                    <FormRow>
                        <FormGroup>
                            {radioEventNotificationType(EventConstants.EVENT_USER_NOTIFICATION_TYPES.STANDARD_NOTIFICATION, this.props.event)}
                        </FormGroup>
                    </FormRow>
                    <hr />
                    <FormRow>
                        <FormGroup>
                            {radioEventNotificationType(EventConstants.EVENT_USER_NOTIFICATION_TYPES.CUSTOM_NOTIFICATION, this.props.event)}
                        </FormGroup>
                    </FormRow>
                    {sendCustomEmail}
                </FormSection>

                <Modal dialogClassName="modal-900w" show={this.state.isModalOpen} onHide={this.toggleModal}>
                    <Modal.Header className="bg-gray" closeButton>
                        <Modal.Title className="text-center">{this.context.intl.messages['events.notifications.custom-image.modal.title']}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <img className="custom-notification-image" src={customNotificationImageUrl} />
                    </Modal.Body>
                </Modal>

                <Modal dialogClassName="modal-500w" show={this.state.isEmailNotificationModalOpen} onHide={this.toggleEmailNotificationModal}>
                    <Modal.Header className="bg-gray" closeButton>
                        <Modal.Title className="text-center">{this.context.intl.messages['events.notifications.send-email-notification.header']}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{this.context.intl.messages['events.notifications.send-email-notification.disclaimer']}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="pull-left Ml(5)" onClick={this.toggleEmailNotificationModal}>
                            <i className="fas fa-ban"></i>&nbsp;{this.context.intl.messages['common.cancel']}
                        </Button>
                        <Button bsStyle="primary" onClick={this.sendManualEmailNotification}>
                            <i className="fa fa-envelope"></i>&nbsp;{this.context.intl.messages['events.notifications.send-email-notification.button.ok']}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default Notifications;
