/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {TimeFormat} from '@accurate-player/accurate-player-core';
import ClassNames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Modal} from 'react-bootstrap';

import AccuratePlayer from '../../../player/accurate-player';

export default class ThumbnailSelectionModal extends React.Component {
    static get propTypes() {
        return {
            clip: PropTypes.instanceOf(Immutable.Map).isRequired,
            onSelectThumbnail: PropTypes.func.isRequired,
            show: PropTypes.bool,
            sources: PropTypes.object.isRequired,
            timeline: PropTypes.instanceOf(Immutable.Map).isRequired,
            toggle: PropTypes.func.isRequired,
            viewingMode: PropTypes.string.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            show: false
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            player: undefined
        };

        this.handleSelectClipFrame = this.handleSelectClipFrame.bind(this);
        this.handleUpdateStatus = this.handleUpdateStatus.bind(this);
    }

    handleSelectClipFrame() {
        const currentFrames = this.state.player.api.getCurrentTime(TimeFormat.Frame);
        this.props.onSelectThumbnail(this.props.clip.get('clipInFrame') + currentFrames);
    }

    handleUpdateStatus(player) {
        this.setState({
            player: player
        });
    }

    render() {
        const modalContent = (
            <div>
                <h4>{this.context.intl.messages['hardac.timeline.thumbnail.selection-modal.text']}</h4>
                <AccuratePlayer
                    clipIn={this.props.clip.get('clipInFrame')}
                    clipOut={this.props.clip.get('clipOutFrame')}
                    onUpdatePlayer={this.handleUpdateStatus}
                    src={this.props.sources.get(this.props.timeline.get('id').toString())}
                    video={this.props.timeline}
                />
            </div>
        );
        return (
            <Modal
                className={ClassNames({'skin-dark': this.props.viewingMode === 'skin-dark'})}
                show={this.props.show}
                onHide={this.props.toggle}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="modal-title text-center">{this.context.intl.messages['hardac.timeline.thumbnail.selection-modal.title']}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    {modalContent}
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-default-outline pull-left Ml(5)" onClick={this.props.toggle}>{this.context.intl.messages['common.close']}</Button>
                    <Button className="btn btn-success-outline" onClick={this.handleSelectClipFrame}>
                        <i className="fas fa-check-square"></i>&nbsp;{this.context.intl.messages['hardac.timeline.thumbnail.selection-modal.button']}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
