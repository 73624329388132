/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import SearchBox from '../../common/search-box/search-box';
import {WithStoreOnRoute} from '../../common/store-on-route';
import Table from '../../common/table';
import Pagination from '../../common/table/pagination';
import {Debounce} from '../../common/utils/utils';
import {PermissionActions} from '../../security/permission/permission-actions';

class Users extends React.Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            permissionId: PropTypes.number.isRequired,
            users: PropTypes.instanceOf(Immutable.Map).isRequired,
            userRoles: PropTypes.instanceOf(Immutable.Map).isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
        };
    }

    constructor(props) {
        super(props);

        this.handleCellMouseEnter = this.handleCellMouseEnter.bind(this);
        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    componentWillUpdate(nextProps) {
        if (this.props.location.query !== nextProps.location.query) {
            this.refresh(nextProps);
        }
    }

    handleCellMouseEnter(event) {
        const child = event.target.children[0];
        if (child && child.getAttribute('data-id')) {
            const userId = child.getAttribute('data-id');

            if (!this.props.userRoles.get(userId)) {
                PermissionActions.getUserRoles(this.props.permissionId, userId);
            }
        }
    }

    handleLoadPage(pageNumber) {
        this.setRouteState('offset', pageNumber * this.props.users.get('size')).apply();
    }

    handleSearchTerm(term) {
        this.setRouteState('name', term).clearRouteState('offset').apply();
    }

    handleSortChange(sortFieldName, sortDirection) {
        this.setRouteState('sort-field-name', sortFieldName)
            .setRouteState('sort-direction', sortDirection)
            .apply();
    }

    refresh(props) {
        props = props || this.props;
        const routeState = this.getRouteState(props);

        PermissionActions.getUsers(
            props.permissionId,
            routeState.get('offset'),
            this.props.users.get('size'),
            routeState.get('name'),
            routeState.get('sort-field-name'),
            routeState.get('sort-direction'),
        );
    }

    render() {
        let activePage = Math.ceil((this.getRouteState().get('offset') || 0) / this.props.users.get('size')) || 0;
        let totalPages = Math.ceil(this.props.users.get('total') / this.props.users.get('size')) || 0;

        return (
            <div>
                <div className="container-fluid no-x-padding">
                    <div className="row">
                        <div className="col-md-3 show-grid"/>
                        <div className="col-md-6 show-grid">
                            <SearchBox onChange={this.handleSearchTerm} value={this.getRouteState().get('name')}/>
                        </div>
                    </div>
                    <div className="container-fluid no-x-padding">
                        <div className="row">
                            <Table
                                columns={[{
                                    getHeader: () => this.context.intl.messages['permission.users.table.name'],
                                    getValue: (item) => `<a href="/accounts/${item.get('id')}">${item.get('fullName')}</a>`,
                                    sortFieldName: 'name',
                                },
                                {
                                    getHeader: () => this.context.intl.messages['permission.users.table.company'],
                                    getValue: (item) => item.get('company'),
                                    sortFieldName: 'company',
                                },
                                {
                                    getHeader: () => this.context.intl.messages['permission.users.table.email'],
                                    getValue: (item) => item.get('email'),
                                    sortFieldName: 'email',
                                },
                                {
                                    getHeader: () => <i className="fa fa-user"></i>,
                                    getValue: (item) => {
                                        const userId = item.get('id');
                                        const roles = this.props.userRoles.get(userId);
                                        let title = this.context.intl.messages['permission.users.table.loading_roles'];

                                        if (roles) {
                                            const rolesString = roles.map((role) => {
                                                return role.name;
                                            }).join(', ');
                                            title = [this.context.intl.messages['permission.users.table.roles'], rolesString].join('');
                                        }

                                        return `<a class="user parcel-tooltip" data-id="${userId}" title="${title}"><i class="fa fa-user"></i><span class="tooltip-inner">${title}</span></a>`;
                                    },
                                    width: 15,
                                }]}
                                cellMouseEnter={this.handleCellMouseEnter}
                                items={this.props.users.get('list')}
                                sortChange={this.handleSortChange}
                                sortDirection={this.getRouteState().get('sort-direction')}
                                sortFieldName={this.getRouteState().get('sort-field-name')}
                            />
                        </div>
                        <div className="row">
                            <div className="col-sm-7 col-sm-offset-5">
                                <Pagination
                                    activePage={activePage}
                                    onChange={this.handleLoadPage}
                                    totalPages={totalPages}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default WithStoreOnRoute(Users);
