/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {DOCUMENT_CT_TYPES} from '@wbdt-sie/brainiac-web-common';
import ClassNames from 'classnames';
import Immutable from 'immutable';
import jQuery from 'jquery';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Tooltip, OverlayTrigger} from 'react-bootstrap';

import {AssetTabActions} from './asset-tab-actions';
import {NotifySubscribersNowActions} from './notify-subscribers-now-modal/notify-subscribers-now-actions';

import SessionStore from '~/src/user/session/session-store';

require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('datatables.net-rowreorder-bs/css/rowReorder.bootstrap.css');
require('../styles/data-tables-brainiac.css');
require('datatables.net-rowreorder');
require('datatables.net-responsive-bs');

class AssetTableItem extends Component {

    static get propTypes() {
        return {
            asset: PropTypes.instanceOf(Immutable.Map).isRequired,
            assetType: PropTypes.string.isRequired,
            columns: PropTypes.array.isRequired,
            disabled: PropTypes.bool,
            onMoveAssetUp: PropTypes.func.isRequired,
            order: PropTypes.number.isRequired,
            openModal: PropTypes.func,
            reorder: PropTypes.bool,
            path: PropTypes.string.isRequired,
            thumbnails: PropTypes.instanceOf(Immutable.List)
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
            router: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: false,
            openModal: () => void 0,
            thumbnails: undefined,
            reorder: false,
        };
    }

    constructor(props) {
        super(props);

        this.handleCatalogs = this.handleCatalogs.bind(this);
        this.handleMoveAssetUp = this.handleMoveAssetUp.bind(this);
        this.handleShowAsset = this.handleShowAsset.bind(this);
        this.handleSendEmailModal = this.handleSendEmailModal.bind(this);
    }

    shouldComponentUpdate(nextProps/*, nextState*/) {
        if (nextProps.asset !== this.props.asset ||
            nextProps.order !== this.props.order ||
            nextProps.thumbnails !== this.props.thumbnails) {
            return true;
        }
        return false;
    }

    handleCatalogs(event) {
        event.preventDefault();
        /* istanbul ignore else */
        if (!this.props.asset.get('catalogs')) {
            AssetTabActions.getCatalogs(this.props.asset.get('assetId'), this.props.path.split('.'));
        }
    }

    handleMoveAssetUp(event) {
        event.preventDefault();
        this.props.onMoveAssetUp(this.props.order);
    }

    handleShowAsset(event) {
        event.preventDefault();
        AssetTabActions.show(this.props.path.split('.'), this.props.asset);
    }

    handleSendEmailModal() {
        const documentSubtype = Object.values(DOCUMENT_CT_TYPES.CONTENT_TYPES.STATION_DOCUMENT.subType).find(subtype => subtype.id === this.props.asset.get('contentTypeId'));
        /* istanbul ignore if */
        if (!documentSubtype) {
            throw new Error(`Missing document subtype for asset [assetId ${this.props.asset.get('assetId')}]: contentTypeId ${this.props.asset.get('contentTypeId')}`);
        }
        /* istanbul ignore if */
        if (!this.props.asset.get('titleId')) {
            // We need a titleId to call the endpoint properly. Newly added assets (prior to saving title) do not have this association yet, so we error here
            throw new Error(`Missing asset titleId [assetId ${this.props.asset.get('assetId')}]: titleId ${this.props.asset.get('titleId')}`);
        }
        this.props.openModal(
            this.props.asset.get('assetId'),
            this.props.asset.get('titleId'),
            documentSubtype.subscriptionTypeId,
            this.props.asset.get('name')
        );
    }

    render() {
        let createdDate = Moment(this.props.asset.get('createdDate'));
        if (createdDate.isValid()) {
            createdDate = createdDate.format(this.context.intl.messages['date-format']);
        } else {
            createdDate = '-';
        }
        let contentType;
        if (this.props.columns.indexOf('contentType') !== -1) {
            contentType = (<td className="text-center">
                <span className="label bg-gray">{this.props.asset.get('contentType')}</span>
            </td>);
        }
        let sendEmailNotifications;
        if (this.props.columns.indexOf('sendEmailNotifications') !== -1 &&
            this.props.asset.get('isActive') &&
            SessionStore.canUser(SessionStore.PERMISSIONS.STATIONS.SEND_DOCUMENT_NOTIFICATIONS)) {
            if (this.props.asset.get('titleId')) {
                sendEmailNotifications = (<td className="no-padding text-center">
                    <a onClick={this.handleSendEmailModal}>
                        <i className="fas fa-envelope" />
                    </a>
                </td>);
            } else {
                // For newly added assets, titleId is not available until after the title is saved. Do not show email icon until we have titleId
                sendEmailNotifications = (<td />);
            }
        }
        let image;
        if (this.props.columns.indexOf('image') !== -1) {
            let thumbnail;
            if (this.props.assetType === 'image') {
                thumbnail = this.props.thumbnails.find(
                    th => th.get('imageId') === this.props.asset.get('assetId')
                )?.get('thumbnailUrl') || '';
            }
            /* istanbul ignore else */
            if (!thumbnail) {
                thumbnail = this.props.asset.getIn(['thumbnails', 0, 'thumbnailUrl']);
            }
            image = <td className="no-padding text-center">
                <a href="#">
                    <img src={thumbnail} className="table-thumbnail"/>
                </a>
            </td>;
        }
        let runtime;
        if (this.props.columns.indexOf('runtime') !== -1) {
            runtime = (<td className="text-center">
                <span className="label bg-gray">{this.props.asset.get('runtime')}</span>
            </td>);
        }

        let direction;
        if (this.props.columns.indexOf('direction') !== -1) {
            direction = <td>&nbsp;</td>;

            /* istanbul ignore next */
            switch (true) {
            case this.props.asset.get('defaultPortrait') === 1:
                direction = (
                    <td className="text-center">
                        <i className="fas fa-arrows-alt-v"></i>
                    </td>
                );
                break;
            case this.props.asset.get('defaultHorizontal') === 1:
                direction = (
                    <td className="text-center">
                        <i className="fas fa-arrows-alt-h"></i>
                    </td>
                );
                break;
            case this.props.asset.get('defaultBanner') === 1:
                direction = (
                    <td className="text-center">
                        <i className="fas fa-expand-arrows-alt"></i>
                    </td>
                );
                break;
            case this.props.asset.get('defaultTitleTreatment') === 1:
                direction = (
                    <td className="text-center">
                        <i className="fas fa-text-width"></i>
                    </td>
                );
                break;
            }
        }

        let format;
        if (this.props.columns.indexOf('format') !== -1) {
            format = (<td>
                <span className="label bg-gray">
                    <i className="far fa-image"/> {this.props.asset.get('mediaFile') || '-'}
                </span>
            </td>);
        }
        let catalogs = this.context.intl.messages['titles.create.assets.loading-catalogs'];
        if (this.props.asset.get('catalogs')) {
            catalogs = this.props.asset.get('catalogs').map(t => t.get('name')).reduce((r, v, i) => {
                if (i === 0) {
                    return `Catalogs: ${v}`;
                }

                return `${r}, ${v}`;
            }, this.context.intl.messages['titles.create.assets.no-catalogs']);
        }

        let moveToTop;
        if (this.props.columns.indexOf('moveToTop') !== -1) {
            moveToTop = <td className="text-center">
                <a className="asset-move-top" onClick={this.handleMoveAssetUp}>
                    <button className="move-to-top btn btn-primary-outline btn-sm" data-toggle="tooltip" title="" data-original-title="Move To Top">
                        <i className="fas fa-arrow-up"></i>
                    </button>
                </a>
            </td>;
        }

        let tooltip = <Tooltip id={'tooltip-'+this.props.asset.get('assetId')} positionTop={0}>{catalogs}</Tooltip>;

        let tdReorder;
        if (this.props.reorder) {
            tdReorder = <td className={ClassNames({'row-reorder-handle':!this.props.disabled})}>{this.props.order}</td>;
        }
        return (
            <tr>
                {tdReorder}
                {contentType}
                {image}
                {runtime}
                {direction}
                {moveToTop}
                {format}
                <td>
                    <a href="#" onClick={this.handleShowAsset}>{this.props.asset.get('name')}</a>
                </td>
                {sendEmailNotifications}
                <td>{createdDate}</td>
                <td onMouseOver={this.handleCatalogs} >
                    <OverlayTrigger
                        overlay={tooltip} placement="bottom"
                        delayShow={300} delayHide={150}>
                        <a className="display-asset-catalogs" data-title-id={this.props.asset.get('assetId')} data-toggle="tooltip" data-placement="top">
                            <span className="hidden-for-sort">10</span>
                            <i className="fas fa-book"/>
                        </a>
                    </OverlayTrigger>
                </td>
                <td style={ {display:'none'} }></td>
            </tr>
        );
    }

}

class AssetTable extends Component {

    static get propTypes() {
        return {
            assetType: PropTypes.string.isRequired,
            assets: PropTypes.instanceOf(Immutable.List).isRequired,
            clientTableSearch: PropTypes.bool,
            columns: PropTypes.array.isRequired,
            disabled: PropTypes.bool,
            isActive: PropTypes.bool,
            path: PropTypes.string.isRequired,
            reorder: PropTypes.bool,
            thumbnails: PropTypes.instanceOf(Immutable.List)
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
            router: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            clientTableSearch: false,
            disabled: false,
            isActive: false,
            reorder: true,
            thumbnails: undefined
        };
    }

    constructor(props) {
        super(props);

        this.getTableId = this.getTableId.bind(this);
        this.handleReorder = this.handleReorder.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.initializeTable = this.initializeTable.bind(this);
        this.moveAssetUp = this.moveAssetUp.bind(this);
        this.openModal = this.openModal.bind(this);
    }

    componentDidMount() {
        this.initializeTable();
        return;
    }

    shouldComponentUpdate(nextProps, /*nextState*/) {
        if (nextProps.assets !== this.props.assets ||
            nextProps.thumbnails !== this.props.thumbnails) {
            return true;
        }
        return false;
    }

    componentDidUpdate(/*prevProps, prevState*/) {
        this.initializeTable();
    }

    componentWillUnmount() {
        // check if undefinded only to enable render test
        if (this.$table && !this.props.disabled) {
            this.$table.off('row-reorder');
        }
        // check if undefinded only to enable render test
        if (this.$tableAPI) {
            this.$tableAPI.destroy();
        }
        return;
    }

    getTableId() {
        let id = this.props.assetType + '-assets-table-';
        id = id + this.props.path.split('.').join('-').split(' ').join('-').split('/').join('-').toLowerCase();
        return id;
    }

    handleReorder( event, diff, edit ) {
        event.preventDefault();
        let fromPos = edit.triggerRow[0][0];
        if (diff.length < 2) {
            return;
        }
        let toPos;
        if (diff[0].oldPosition === fromPos) {
            toPos = diff[0].newPosition;
        } else {
            toPos = diff[diff.length-1].newPosition;
        }
        setTimeout(() => {
            AssetTabActions.reorder(this.props.path.split('.'), fromPos, toPos);
            return;
        }, 0);
    }

    /**
     * Update the datatable columns size.
     */
    handleResize() {
        this.$tableAPI.responsive.recalc();
        return;
    }

    initializeTable() {
        this.$table = jQuery('#'+this.getTableId());
        let configTable = {
            autoWidth: false,
            columnDefs: [{
                // Add the control class to the last column. This colum will
                // contain the button to show the responsive data.
                className: 'control',
                targets:   -1,
                width: 20
            },
            {
                targets:1
            }, {
                targets: 'no-sort',
                orderable: false
            }],
            iDisplayLength: 1,
            info: false,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: this.props.clientTableSearch
        };

        /* istanbul ignore if */
        if (!this.props.disabled && this.props.reorder) {
            configTable.rowReorder = {
                selector: '.row-reorder-handle'
            };
        }
        /* istanbul ignore if */
        if (this.props.reorder) {
            configTable.columnDefs.push({
                targets: 0,
                classname: ClassNames('text-center row-index ', {'row-reorder-handle': this.props.disabled})
            });
        }

        if (this.props.assetType === 'image') {
            configTable.columnDefs.push({targets: 2, className: 'text-center'});
        }
        this.$tableAPI = this.$table.DataTable(configTable);

        // Add some custom handlers
        /* istanbul ignore if */
        if (!this.props.disabled && this.props.reorder) {
            this.$tableAPI.on('row-reorder', this.handleReorder);
        }
        // Register global listeners.
        window.addEventListener('resize', this.handleResize);
        // And trigger the resize handler once so that the datatable
        // knows its initial dimensions.
        this.$tableAPI.draw(false);
        this.handleResize();
    }

    moveAssetUp(order) {
        if (order !== 0) {
            order = --order;
        }

        AssetTabActions.reorder(this.props.path.split('.'), order, 0);
    }

    openModal(assetId, titleId, subscriptionTypeId, subject) {
        NotifySubscribersNowActions.showModal(assetId, titleId, subscriptionTypeId, subject);
    }

    render() {
        let order = 0;
        if (this.$table && !this.props.disabled && this.props.reorder) {
            this.$table.off('row-reorder');
        }
        // check if undefinded only to enable render test
        if (this.$tableAPI) {
            this.$tableAPI.destroy();
        }
        delete this.$table;
        delete this.$tableAPI;

        let contentType;
        if (this.props.columns.indexOf('contentType') !== -1) {
            contentType = (<th className="no-sort text-center sorting_disabled" style={ {width:'40px'} }>Type</th>);
        }
        let image;
        if (this.props.columns.indexOf('image') !== -1) {
            image = (<th className="no-sort text-center no-padding sorting_disabled" style={ {width:'40px'} }>
                <i className="far fa-image"/>
            </th>);
        }
        let runtime;
        if (this.props.columns.indexOf('runtime') !== -1) {
            runtime = (<th className="no-sort text-center sorting_disabled" style={ {width:'40px'} }>Runtime</th>);
        }
        let direction;
        if (this.props.columns.indexOf('direction') !== -1) {
            direction = (<th className="no-sort text-center sorting_disabled" style={ {width:'20px'} }>
                <strong>D</strong>
            </th>);
        }
        let format;
        if (this.props.columns.indexOf('format') !== -1) {
            format = (<th className="no-sort sorting_disabled" style={ {width:'40px'} }>Format</th>);
        }
        let moveToTop;
        if (this.props.columns.indexOf('moveToTop') !== -1) {
            moveToTop = <th aria-label="Type" className="no-sort sorting_disabled text-center" style={{width:'40px'}}>
                <i className="fas fa-arrow-up"/>
            </th>;
        }
        let sendEmailNotifications;
        if (this.props.columns.indexOf('sendEmailNotifications') !== -1 &&
            this.props.isActive &&
            SessionStore.canUser(SessionStore.PERMISSIONS.STATIONS.SEND_DOCUMENT_NOTIFICATIONS)) {
            sendEmailNotifications = (<th className="no-sort sorting_disabled" style={ {width:'80px'} }>Send Email</th>);
        }
        let thReorder;
        if (this.props.reorder) {
            thReorder = <th className="no-sort no-arrow text-center row-reorder-handle row-index sorting_asc" style={{width:'40px'}} aria-label="#">#</th>;
        }

        //STUDIO-7627: since station tables have search input by content type it looks better with an extra margin
        let tableMB;
        if (this.props.assetType === 'station') {
            tableMB = {marginBottom: '20px'};
        }
        return (
            <div style={tableMB}>
                <table id={this.getTableId()} className="table table-bordered table-striped responsive asset-table-list">
                    <thead>
                        <tr>
                            {thReorder}
                            {contentType}
                            {image}
                            {runtime}
                            {direction}
                            {moveToTop}
                            {format}
                            <th className="no-sort sorting_disabled" aria-label="Name">Name</th>
                            {sendEmailNotifications}
                            <th className="no-sort sorting_disabled" style={{width:'100px'}} aria-label="Date Entered">Date Entered</th>
                            <th className="no-sort sorting_disabled" style={{width:'20px'}} aria-label="10">
                                <span className="hidden-for-sort">10</span>
                                <i className="fas fa-book"></i>
                            </th>
                            <th className="no-sort control sorting_disabled"/>
                        </tr>
                    </thead>
                    <tbody onClick={this.handleRowClick}>
                        {this.props.assets.map((asset) => {
                            let itemPath = this.props.path + '.'+order;
                            order = order + 1;
                            return (
                                <AssetTableItem
                                    asset={asset}
                                    assetType={this.props.assetType}
                                    columns={this.props.columns}
                                    disabled={this.props.disabled}
                                    key={asset.get('assetId')}
                                    onMoveAssetUp={this.moveAssetUp}
                                    openModal={this.openModal}
                                    order={order}
                                    reorder={this.props.reorder}
                                    path={itemPath}
                                    thumbnails={this.props.thumbnails}
                                />
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}

export {AssetTable};
export {AssetTableItem};

class AssetTableComponent extends Component {
    static get propTypes() {
        return {
            active: PropTypes.bool,
            assetType: PropTypes.string.isRequired,
            assets: PropTypes.instanceOf(Immutable.Map).isRequired,
            clientTableSearch: PropTypes.bool,
            columns: PropTypes.array.isRequired,
            disabled: PropTypes.bool,
            reorder: PropTypes.bool,
            thumbnails: PropTypes.instanceOf(Immutable.List)
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            active: false,
            clientTableSearch: false,
            disabled: false,
            displayLinks: false,
            reorder: true,
            thumbnails: undefined
        };
    }

    shouldComponentUpdate(nextProps/*, nextState*/) {
        if (nextProps.assets !== this.props.assets ||
            nextProps.thumbnails !== this.props.thumbnails) {
            return true;
        }
        return false;
    }

    render() {
        if (this.props.assets.size === 0) {
            return null;
        }
        let toRender = [];
        let statusClass = 'inactive';

        if (this.props.active) {
            statusClass = 'active';
        }

        let orderedAssets = this.props.assets;
        if (this.props.assetType === 'station') {
            orderedAssets = this.props.assets.sort((a, b) => a.toJS()[0].assetCMSOrder - b.toJS()[0].assetCMSOrder);
        }
        orderedAssets.forEach((value, key) => {
            const path = `${this.props.assetType}.${statusClass}`;
            toRender.push(<div key={path+'.'+key} >
                <h4>{key}</h4>
                <AssetTable
                    assets={value}
                    clientTableSearch={this.props.clientTableSearch}
                    assetType={this.props.assetType}
                    columns={this.props.columns}
                    disabled={this.props.disabled}
                    isActive={this.props.active}
                    path={path+'.'+key}
                    reorder={this.props.reorder}
                    thumbnails={this.props.thumbnails}
                />
            </div>);
        });
        return (
            <div>
                {toRender}
            </div>
        );
    }

}

export default AssetTableComponent;
