/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

const CC_BUTTON_PATH = [
    'apc-controls',
    'apc-control-header',
    'apc-icon-subtitles',
];

type XElement = {
    root: ShadowRoot;
} & HTMLElement;

function isXElement(el: unknown): el is XElement {
    return el instanceof HTMLElement && (el as any).root instanceof ShadowRoot;
}

export function HideCCButton() {
    const el = findByPath(CC_BUTTON_PATH);
    if (isXElement(el)) {
        el.style.display = 'none';
    }
}

function findByPath(path: string[]) {
    return path.reduce((el: Element | Document | null, selector) => {
        if (el instanceof Document) {
            return el.querySelector(selector);
        }

        if (isXElement(el)) {
            return el.root.querySelector(selector);
        }

        return null;
    }, document);
}

