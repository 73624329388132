/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {LanguageCodeType} from '@wbdt-sie/brainiac-web-common';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import AdditionalInfo from './additional-info';
import AlternativeTitles from './alternative-titles';
import Languages from './languages';
import Links from './links';
import MasteringInfo from './mastering-info';
import NonTheatricalRights from './non-theatrical-rights';
import ProductionAndDistribution from './production-and-distribution';
import Releases from './releases';
import Rights from './rights';
import SynopsisAndLogLines from './synopsis-and-log-lines';
import TitleInfo from './title-info';
import TitleType from './title-type';

class Summary extends Component {
    static get propTypes() {
        return {
            canEditLocalized: PropTypes.bool,
            canViewAdditionalInfo: PropTypes.bool,
            canViewAlternativeTitles: PropTypes.bool,
            canViewLanguages: PropTypes.bool,
            canViewLinks: PropTypes.bool,
            canViewLocalized: PropTypes.bool,
            canViewMasteringInfo: PropTypes.bool,
            canViewNonTheatricalRights: PropTypes.bool,
            canViewProductionAndDistribution: PropTypes.bool,
            canViewReleases: PropTypes.bool,
            canViewRights: PropTypes.bool,
            canViewSynopsisLogLines: PropTypes.bool,
            currentLanguage: PropTypes.string.isRequired,
            disabled: PropTypes.bool,
            disableExpandButton: PropTypes.bool,
            title: PropTypes.instanceOf(Immutable.Map).isRequired,
            titleLocalized: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get defaultProps() {
        return {
            canEditLocalized: false,
            canViewAdditionalInfo: false,
            canViewAlternativeTitles: false,
            canViewLanguages: false,
            canViewLinks: false,
            canViewLocalized: false,
            canViewMasteringInfo: false,
            canViewNonTheatricalRights: false,
            canViewProductionAndDistribution: false,
            canViewReleases: false,
            canViewRights: false,
            canViewSynopsisLogLines: false,
            disabled: true,
            disableExpandButton: false,
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            expanded: false
        };

        this.handleToggleExpandSections = this.handleToggleExpandSections.bind(this);
    }

    handleToggleExpandSections() {
        this.setState((prevState) => {
            return {
                expanded: !prevState.expanded
            };
        });
    }

    render() {
        let localized = this.props.canViewLocalized && this.props.currentLanguage !== LanguageCodeType.DEFAULT_LANGUAGE;

        let disabledOnLocalized = this.props.disabled || localized;
        let readOnlyLocalized = this.props.disabled || (localized && !this.props.canEditLocalized);

        let additionalInfo;
        if (this.props.canViewAdditionalInfo) {
            additionalInfo = <AdditionalInfo {...this.props} expanded={this.state.expanded} disabled={disabledOnLocalized}/>;
        }

        let alternativeTitles;
        if (this.props.canViewAlternativeTitles) {
            alternativeTitles = <AlternativeTitles {...this.props} expanded={this.state.expanded} disabled={readOnlyLocalized}/>;
        }

        let languages;
        if (this.props.canViewLanguages) {
            languages = <Languages {...this.props} expanded={this.state.expanded} disabled={disabledOnLocalized}/>;
        }

        let links;
        if (this.props.canViewLinks) {
            links = <Links {...this.props} expanded={this.state.expanded} disabled={disabledOnLocalized}/>;
        }

        let masteringInfo;
        if (this.props.canViewMasteringInfo) {
            masteringInfo = <MasteringInfo {...this.props} expanded={this.state.expanded} disabled={readOnlyLocalized}/>;
        }

        let nonTheatricalRights;
        if (this.props.canViewNonTheatricalRights) {
            nonTheatricalRights = <NonTheatricalRights {...this.props} expanded={this.state.expanded} disabled={disabledOnLocalized}/>;
        }

        let productionAndDistribution;
        if (this.props.canViewProductionAndDistribution) {
            productionAndDistribution = <ProductionAndDistribution {...this.props} expanded={this.state.expanded} disabled={readOnlyLocalized}/>;
        }

        let releases;
        if (this.props.canViewReleases) {
            releases = <Releases {...this.props} expanded={this.state.expanded} title={this.props.title} disabled={disabledOnLocalized}/>;
        }

        let rights;
        if (this.props.canViewRights) {
            rights = <Rights {...this.props} expanded={this.state.expanded} disabled={readOnlyLocalized}/>;
        }

        let synopsisLogLines;
        if (this.props.canViewSynopsisLogLines) {
            synopsisLogLines = <SynopsisAndLogLines {...this.props} expanded={this.state.expanded} disabled={readOnlyLocalized}/>;
        }

        return (
            <div>
                <TitleType {...this.props}/>

                <hr />

                <TitleInfo {...this.props} expanded={this.state.expanded} onToggleExpand={this.handleToggleExpandSections} disabled={readOnlyLocalized}/>

                {alternativeTitles}
                {synopsisLogLines}
                {productionAndDistribution}
                {rights}
                {nonTheatricalRights}
                {releases}
                {additionalInfo}
                {masteringInfo}
                {links}
                {languages}
            </div>
        );
    }
}

export default Summary;
