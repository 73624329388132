/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React, {Component} from 'react';
import Select from 'react-select';

import Panel from '../common/panel/panel';
import {WithStoreOnRoute} from '../common/store-on-route';
import {LayoutActions} from '../layout/layout-actions';
import {GroupActions} from '../security/group/group-actions';
import GroupStore from '../security/group/group-store';

let ActiveOptions = WithStoreOnRoute(class ActiveOptions extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object,
            onChange: PropTypes.func,
            selected: PropTypes.string,
            type: PropTypes.object
        };
    }

    static get defaultProps() {
        return {
            location: undefined,
            onChange: undefined,
            selected: '',
            type: undefined
        };
    }

    constructor(props) {
        super(props);

        this.setActiveFilter = this.setActiveFilter.bind(this);
    }

    setActiveFilter(e) {
        const filterAttr = 'active';
        let value = true;
        if (e && e.value === false) {
            value = false;
        }

        if (this.props.location) {
            this.setRouteState(filterAttr, value.toString())
                .setRouteState('operator', 'AND')
                .clearRouteState('offset')
                .apply();
            return;
        }
        GroupActions.setFilter(this.props.type, filterAttr, value);
        this.props.onChange();
    }

    render() {
        let options = [
            {name: 'Active', value: true},
            {name: 'Inactive', value: false}
        ];

        let selected = options[0];
        if (this.props.selected === 'false') {
            selected = options[1];
        }

        return (
            <Panel title="Status" classTitle="box-title" collapsible defaultExpanded>
                <div data-style="display: block;" className="box-body">
                    <div className="form-group">
                        <Select
                            getOptionLabel={data => data.name}
                            getOptionValue={data => data.value}
                            isClearable={true}
                            isMulti={false}
                            name="active"
                            options={options}
                            placeholder="Select..."
                            value={selected}
                            onChange={this.setActiveFilter}
                        />
                    </div>
                </div>
            </Panel>
        );
    }
});


class FilterOptions extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object,
            type: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            location: undefined
        };
    }

    constructor(props) {
        super(props);

        this.handleToggleFiltersPanel = this.handleToggleFiltersPanel.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.location !== this.props.location) {
            return true;
        }

        return false;
    }

    handleToggleFiltersPanel() {
        LayoutActions.toggleFiltersPanel();
    }

    onChange() {
        GroupActions.setFilter('operator', 'AND');
        GroupActions.get(
            GroupStore.getState().getIn([this.props.type.name, 'filters']),
            0,
            9999
        );
    }

    render() {
        let activeValue;
        let onChange;
        if (this.props.location) {
            activeValue = this.props.location.query.active;
        } else {
            activeValue = GroupStore.getState().getIn([this.props.type.name, 'filters', 'active']);
            onChange = this.onChange;
        }
        return (
            <div>
                <aside className="control-sidebar B(0) Ovx(h) Ovy(a)">

                    <div className="tab-content">

                        <p>
                            <button className="btn btn-block bg-navy" onClick={this.handleToggleFiltersPanel}><i className="fas fa-chevron-right pull-right"></i>&nbsp;Close Options</button>
                        </p>

                        <ActiveOptions
                            selected={activeValue}
                            location={this.props.location}
                            onChange={onChange}
                            type={this.props.type}
                        />
                    </div>

                </aside>

                <div style={{position: 'fixed', height: 'auto'}} className="control-sidebar-bg"></div>
            </div>
        );
    }
}

export default WithStoreOnRoute(FilterOptions);

export {
    ActiveOptions
};
