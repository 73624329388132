/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {ControlLabel, FormControl, FormGroup, OverlayTrigger, Tooltip, Label} from 'react-bootstrap';
import ReactSelect from 'react-select';

import FileInfo from './file-info';
import PublishingInfo from './publishing-info';
import AssetStore from '../../../assets/asset-store';
import TimelineAssetStore from '../../../assets/timeline/timeline-asset-store';
import VideoStore from '../../../assets/video/video-store';
import AssetRights from '../../../common/asset-rights/asset-rights';
import {AssetRightsConstants} from '../../../common/asset-rights/asset-rights-constants';
import BaseSelect from '../../../common/base-select/base-select';
import {FormItem, FormRow, FormSection} from '../../../common/form/form';
import {SectionPanel} from '../../../common/panel/panel';
import {TimecodeFromSeconds, TimecodeToString} from '../../../common/utils/timecode';
import config from '../../../config/config.js';
import {TimelineActions} from '../timeline-actions';
import TimelineStore, {TimelineValidations} from '../timeline-store';

class Summary extends React.Component {
    static get propTypes() {
        return {
            canViewMediaInfo: PropTypes.bool,
            clips: PropTypes.number.isRequired,
            disabled: PropTypes.bool,
            oapShowCodes: PropTypes.instanceOf(Immutable.List),
            originalTimeline: PropTypes.instanceOf(Immutable.Map).isRequired,
            publishingInfo: PropTypes.instanceOf(Immutable.Map).isRequired,
            timeline: PropTypes.instanceOf(Immutable.Map).isRequired,
            tracks: PropTypes.number,
            viewingMode: PropTypes.string.isRequired
        };
    }

    static get defaultProps() {
        return {
            canViewMediaInfo: true,
            disabled: true,
            oapShowCodes: Immutable.List(),
            tracks: 0
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            selectedContentType: TimelineStore.getContentType(this.props.timeline.get('contentType')) || null,
            selectedDeliveryType: AssetStore.getDeliveryType(this.props.timeline.get('deliveryType')),
        };

        this.handleSelectContentType = this.handleSelectContentType.bind(this);
        this.handleSelectDeliveryType = this.handleSelectDeliveryType.bind(this);
        this.handleSelectLanguage = this.handleSelectLanguage.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            selectedContentType: TimelineStore.getContentType(nextProps.timeline.get('contentType')),
            selectedDeliveryType: AssetStore.getDeliveryType(nextProps.timeline.get('deliveryType'))
        });
        return;
    }

    handleSelectContentType(value) {
        if (value === null) {
            TimelineActions.updateTimeline('contentType', null);
            return;
        }
        TimelineActions.updateTimeline('contentType', value.id);
        return;
    }

    handleSelectDeliveryType(value) {
        if (value === null) {
            TimelineActions.updateTimeline('deliveryType', null);
            return;
        }
        TimelineActions.updateTimeline('deliveryType', value.id);
        return;
    }

    handleSelectLanguage(value) {
        if (value !== undefined && value !== null) {
            TimelineActions.updateTimeline('language', value.id);
            return;
        }
        TimelineActions.updateTimeline('language', null);
        return;
    }

    handleSelectProtectionType(value) {
        if (value !== undefined && value !== null) {
            TimelineActions.updateTimeline('protectionType', value.id);
        } else {
            TimelineActions.updateTimeline('protectionType', null);
        }
        return;
    }

    handleSelectTextedOrTextless(value) {
        TimelineActions.updateTimeline('texted', value.texted);
        TimelineActions.updateTimeline('textless', value.textless);
    }

    render() {
        let {
            barcodeNum,
            createdBy,
            createdDate,
            frameRate,
            runtime,
            texted,
            textless,
            updatedBy,
            updatedDate
        } = this.props.timeline.toJS();

        let createdDateFormatted = '-';
        if (createdDate) {
            createdDateFormatted = Moment(createdDate).tz(config.DefaultTimezone).format(this.context.intl.messages['datetime-format']);
        }

        if (runtime && frameRate) {
            runtime = TimecodeToString(TimecodeFromSeconds(runtime, parseFloat(frameRate)));
        }

        let updatedDateFormatted = '-';
        if (updatedDate) {
            updatedDateFormatted = Moment(updatedDate).tz(config.DefaultTimezone).format(this.context.intl.messages['datetime-format']);
        }

        let mediaInfoSection;
        if (this.props.canViewMediaInfo && this.props.timeline.get('mediaInfo')) {
            let mediaInfo;
            try {
                mediaInfo = (
                    <pre>{JSON.stringify(JSON.parse(JSON.parse(this.props.timeline.get('mediaInfo'))), null, 2)}</pre>
                );
            } catch (e) {
                mediaInfo = (
                    <div>
                        <em>{this.context.intl.messages['hardac.timeline.summary.media-info.error']}</em>
                        <pre>{this.props.timeline.get('mediaInfo')}</pre>
                    </div>
                );
            }

            mediaInfoSection = <SectionPanel title={this.context.intl.messages['hardac.timeline.summary.media-info']}>
                {mediaInfo}
            </SectionPanel>;
        }

        const textedOptions = [
            {name: this.context.intl.messages['hardac.timeline.summary.texted-textless.both'], texted: true, textless: true},
            {name: this.context.intl.messages['hardac.timeline.summary.texted-textless.none'], texted: false, textless: false},
            {name: this.context.intl.messages['hardac.timeline.summary.texted-textless.texted'], texted: true, textless: false},
            {name: this.context.intl.messages['hardac.timeline.summary.texted-textless.textless'], texted: false, textless: true}
        ];
        const selectedTextedValue = textedOptions.find(o => (!!texted === o.texted && !!textless === o.textless));

        let barcode = (
            <FormGroup>
                <ControlLabel>{this.context.intl.messages['hardac.timeline.summary.barcode']}</ControlLabel>
                <FormControl disabled type="text" value={barcodeNum} />
            </FormGroup>
        );
        if (!this.props.originalTimeline.get('barcodeNum')) {
            barcode = (
                <FormGroup>
                    <FormItem
                        attr="barcodeNum"
                        label={this.context.intl.messages['hardac.timeline.summary.barcode']}
                        disabled={this.props.disabled}
                        model={this.props.timeline}
                        required
                        setter={TimelineActions.updateTimeline}
                        type="text"
                    />
                </FormGroup>
            );
        }

        return (
            <div>
                <h3><i className="fas fa-stream"></i>&nbsp;{this.context.intl.messages['hardac.timeline.summary.info']}</h3>
                <FormSection>
                    <FormRow>
                        <FormItem
                            attr="assetName"
                            label={this.context.intl.messages['hardac.timeline.summary.name']}
                            disabled={this.props.disabled}
                            model={this.props.timeline}
                            required
                            setter={TimelineActions.updateTimeline}
                            type="text"
                            validations={TimelineValidations.assetName.validations}
                        />
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.timeline.summary.content-type']}&nbsp;<span className="text-red">*</span></ControlLabel>
                            <BaseSelect
                                classNamePrefix="dropdown"
                                disabled={this.props.disabled}
                                isClearable={true}
                                multi={false}
                                name="contentType"
                                onChange={this.handleSelectContentType}
                                options="contentType"
                                store={TimelineStore}
                                validations={TimelineValidations.contentType.validations}
                                value={this.state.selectedContentType}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.timeline.summary.protection-type']}&nbsp;<span className="text-red">*</span></ControlLabel>
                            <BaseSelect
                                classNamePrefix="dropdown"
                                disabled={this.props.disabled}
                                isClearable={true}
                                multi={false}
                                name="selectedProtectionType"
                                onChange={this.handleSelectProtectionType}
                                options="selectedProtectionType"
                                store={TimelineAssetStore}
                                validations={TimelineValidations.protectionType.validations}
                                value={TimelineAssetStore.getProtectionType(this.props.timeline.get('protectionType'))}
                            />
                        </FormGroup>
                    </FormRow>
                    <hr />
                    <AssetRights
                        defaultRightsInfoValue={AssetRightsConstants.RIGHTS_INFO_TYPES.CUSTOM.id}
                        disabled={this.props.disabled}
                        model={this.props.timeline}
                        updater={TimelineActions.updateTimeline}
                        validations={TimelineValidations}
                    />
                    <hr />
                    <FormRow>
                        {barcode}
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.timeline.summary.tracks']}</ControlLabel>
                            <FormControl disabled type="text" value={this.props.tracks || '-'} />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.timeline.summary.clips']}</ControlLabel>
                            <FormControl disabled type="text" value={this.props.clips || '-'} />
                        </FormGroup>
                    </FormRow>
                </FormSection>
                <h3><i className="fas fa-file-lines"></i>&nbsp;{this.context.intl.messages['hardac.timeline.summary.serviceable-properties']}</h3>
                <FormSection>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.timeline.summary.texted-textless']}</ControlLabel>
                            <ReactSelect
                                classNamePrefix="dropdown"
                                getOptionLabel={data => data.name}
                                getOptionValue={data => data}
                                disabled={this.props.disabled}
                                isClearable={false}
                                multi={false}
                                onChange={this.handleSelectTextedOrTextless}
                                options={textedOptions.filter(o => !(o.texted && o.textless))}
                                value={selectedTextedValue}
                            />
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <FormGroup md={6}>
                            <ControlLabel>{this.context.intl.messages['hardac.timeline.summary.language']}</ControlLabel>
                            <BaseSelect
                                classNamePrefix="dropdown"
                                disabled={this.props.disabled}
                                name={this.context.intl.messages['hardac.timeline.summary.language']}
                                onChange={this.handleSelectLanguage}
                                options="languages"
                                store={VideoStore}
                                value={VideoStore.getLanguage(this.props.timeline.get('language'))}
                            />
                        </FormGroup>
                        <FormGroup md={3}>
                            <ControlLabel>{this.context.intl.messages['hardac.timeline.summary.dubs']}</ControlLabel>
                            <FormItem
                                attr="hasDub"
                                label={this.context.intl.messages['hardac.timeline.summary.has-dubs']}
                                disabled={this.props.disabled}
                                model={this.props.timeline}
                                setter={TimelineActions.updateTimeline}
                                type="checkbox"
                            />
                        </FormGroup>
                        <FormGroup md={3}>
                            <ControlLabel>{this.context.intl.messages['hardac.timeline.summary.subtitles']}</ControlLabel>
                            <FormItem
                                attr="hasSub"
                                label={this.context.intl.messages['hardac.timeline.summary.has-subs']}
                                disabled={this.props.disabled}
                                model={this.props.timeline}
                                setter={TimelineActions.updateTimeline}
                                type="checkbox"
                            />
                        </FormGroup>
                    </FormRow>
                    <hr />
                    <FormRow>
                        <FormGroup md="6">
                            <ControlLabel>{this.context.intl.messages['hardac.timeline.summary.delivery-type']}&nbsp;<span className="text-red">*</span></ControlLabel>
                            <BaseSelect
                                classNamePrefix="dropdown"
                                disabled={this.props.disabled}
                                isClearable={false}
                                multi={false}
                                name="deliveryType"
                                onChange={this.handleSelectDeliveryType}
                                options="deliveryType"
                                store={AssetStore}
                                validations={TimelineValidations.deliveryType.validations}
                                value={this.state.selectedDeliveryType}
                            />
                        </FormGroup>
                        <FormGroup md="3">
                            <ControlLabel>{this.context.intl.messages['hardac.timeline.summary.mfa-required']}</ControlLabel>
                            <FormItem
                                attr="mfaRequired"
                                label={this.context.intl.messages['hardac.timeline.summary.mfa-required']}
                                disabled={this.props.disabled}
                                model={this.props.timeline}
                                setter={TimelineActions.updateTimeline}
                                type="checkbox"
                            />
                        </FormGroup>
                        <FormGroup md="3">
                            <ControlLabel>{this.context.intl.messages['hardac.timeline.summary.delivery-formats']}</ControlLabel>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>{this.context.intl.messages['common.video-delivery-format.mxf-mp4.tooltip']}</Tooltip>
                                }>
                                <Label bsSize="md" className="label label-gray">{this.context.intl.messages['common.video-delivery-format.mxf-mp4']}</Label>
                            </OverlayTrigger>
                        </FormGroup>
                    </FormRow>
                </FormSection>
                <PublishingInfo id={this.props.timeline.get('id')} publishingInfo={this.props.publishingInfo}/>
                <FileInfo isEditInfo={false} timeline={this.props.timeline} viewingMode={this.props.viewingMode} oapShowCodes={this.props.oapShowCodes}/>
                {mediaInfoSection}
                <hr />
                <FormRow>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['hardac.timeline.summary.created']}</ControlLabel>
                        <FormControl.Static>{createdDateFormatted}</FormControl.Static>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['hardac.timeline.summary.created-by']}</ControlLabel>
                        <FormControl.Static>{createdBy}</FormControl.Static>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['hardac.timeline.summary.updated']}</ControlLabel>
                        <FormControl.Static>{updatedDateFormatted}</FormControl.Static>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['hardac.timeline.summary.updated-by']}</ControlLabel>
                        <FormControl.Static>{updatedBy}</FormControl.Static>
                    </FormGroup>
                </FormRow>
            </div>
        );
    }
}

export default Summary;
