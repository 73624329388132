/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, Col, Row} from 'react-bootstrap';

import Link from './link';
import {SectionPanel} from '../../../common/panel/panel';
import {TitleActions} from '../../title-actions';

class Links extends Component {
    static get propTypes() {
        return {
            expanded: PropTypes.bool,
            disabled: PropTypes.bool,
            title: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            expanded: false,
            disabled: true
        };
    }

    shouldComponentUpdate(nextProps/*, nextState*/) {
        if (this.props.title.get('links') !== nextProps.title.get('links') ||
            this.props.disabled !== nextProps.disabled ||
            this.props.expanded !== nextProps.expanded) {
            return true;
        }

        return false;
    }

    handleAdd() {
        TitleActions.addLink();
        return;
    }

    render() {
        const links = this.props.title.get('links').map((l, i) => <Link disabled={this.props.disabled} index={i} key={i} link={l}/>);
        let validationState;
        if (links.size) {
            validationState = 'success';
        }

        return (
            <SectionPanel
                expanded={this.props.expanded}
                title={this.context.intl.messages['titles.create.summary.links']}
                validationState={validationState}>
                {links}
                <Row>
                    <Col md={4} mdOffset={4}>
                        <Button bsStyle="primary" className="btn-lg btn-block" onClick={this.handleAdd} disabled={this.props.disabled}>
                            <i className="fas fa-plus"/>&nbsp;{this.context.intl.messages['titles.create.link.add']}
                        </Button>
                    </Col>
                </Row>
            </SectionPanel>
        );
    }
}

export default Links;
