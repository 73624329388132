/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, ControlLabel, FormGroup, Modal} from 'react-bootstrap';

import AssetStore from '../../../assets/asset-store';
import BaseSelect from '../../../common/base-select/base-select';
import {FormItem, FormRow, FormSection} from '../../../common/form/form';
import Validations from '../../../common/validations/validations';
import Preloader from '../../../preloader';
import {BatchActions, BatchConstants} from '../../batch-actions';
import BatchStore, {BatchValidations} from '../../batch-store';

class DuplicateModal extends React.Component {
    static get propTypes() {
        return {
            batch: PropTypes.instanceOf(Immutable.Map).isRequired,
            disabled: PropTypes.bool,
            onHide: PropTypes.func.isRequired,
            show: PropTypes.bool.isRequired,
            showPreloader: PropTypes.bool.isRequired,
            type: PropTypes.string.isRequired,
        };
    }

    static get defaultProps() {
        return {
            disabled: false
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.handleConfirmDuplicate = this.handleConfirmDuplicate.bind(this);
        this.handleHide = this.handleHide.bind(this);
        this.isValid = this.isValid.bind(this);
    }

    handleConfirmDuplicate() {
        BatchActions.saveDuplicatedBatch(this.props.batch);
        this.handleHide();
    }

    handleHide() {
        this.props.onHide();
    }

    handleSelectDeliveryType(value) {
        if (value === null) {
            BatchActions.updateBatchDuplicated('deliveryType', null);
            return;
        }
        BatchActions.updateBatchDuplicated('deliveryType', value.id);
        return;
    }

    handleSelectBatchActiveType(value) {
        if (value === null) {
            BatchActions.updateBatchDuplicated('active', BatchConstants.BATCH_ACTIVE_TYPES.NO_CHANGE.id);
            return;
        }

        BatchActions.updateBatchDuplicated('active', value.id);

        return;
    }

    handleTimeChange(attr, value) {
        value = Moment(value, ['hh:mm a']).format('HH:mm:ss');
        BatchActions.updateBatchDuplicated(attr, value);
        return;
    }

    isValid() {
        return !Validations.validate(this.props.batch, BatchValidations).length && !this.props.disabled;
    }

    render() {
        const executionTime = this.props.batch.get('executionTime');
        const selectedDeliveryType = AssetStore.getDeliveryType(this.props.batch.get('deliveryType'));
        const selectedBatchActiveType = BatchStore.getBatchActiveType(this.props.batch.get('active'));
        const disabled = this.props.disabled;
        let batchInfo;
        let timeFormatted;

        let activeSelect = (
            <FormGroup>
                <ControlLabel>{this.context.intl.messages['common.status']}</ControlLabel>
                <BaseSelect
                    disabled={disabled}
                    isClearable={false}
                    multi={false}
                    name="active"
                    md={12}
                    onChange={this.handleSelectBatchActiveType}
                    options="batchActiveTypes"
                    store={BatchStore}
                    value={selectedBatchActiveType}
                />
            </FormGroup>
        );

        switch (this.props.type.toUpperCase()) {
        case BatchConstants.BATCH_TYPES.IMAGE:
        case BatchConstants.BATCH_TYPES.VIDEO:
            batchInfo = (
                <FormRow>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['scheduling.batch.summary.delivery-type']}</ControlLabel>
                        <BaseSelect
                            disabled={disabled}
                            isClearable={true}
                            multi={false}
                            name="deliveryType"
                            onChange={this.handleSelectDeliveryType}
                            options="deliveryType"
                            store={AssetStore}
                            value={selectedDeliveryType}
                        />
                    </FormGroup>
                    <FormGroup>
                        {activeSelect}
                    </FormGroup>
                </FormRow>
            );
            break;
        case BatchConstants.BATCH_TYPES.DOCUMENT:
        case BatchConstants.BATCH_TYPES.TITLE:
            batchInfo = (
                <FormRow>
                    <FormGroup>
                        {activeSelect}
                    </FormGroup>
                </FormRow>
            );
            break;
        }

        if (executionTime) {
            timeFormatted = Moment(executionTime, ['HH:mm']).format('hh:mm a');
        }

        return (
            <Modal onHide={this.handleHide} show={this.props.show}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="text-center">
                        {this.context.intl.messages['scheduling.batch.duplicate.modal.title']}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Preloader show={this.props.showPreloader} fixed>
                        <h3><i className="fa fa-info-circle"></i>&nbsp;{this.context.intl.messages['scheduling.batch.summary.info']}</h3>
                        <FormSection>
                            <FormRow>
                                <FormItem
                                    attr="batchName"
                                    label={this.context.intl.messages['scheduling.batch.summary.name']}
                                    disabled={disabled}
                                    model={this.props.batch}
                                    required
                                    setter={BatchActions.updateBatchDuplicated}
                                    type="text"
                                    validations={BatchValidations.batchName.validations}
                                />
                            </FormRow>
                            <FormRow>
                                <FormItem
                                    attr="executionDate"
                                    addonBefore={<i className="far fa-calendar-alt"></i>}
                                    datepicker={{
                                        isClearable: false,
                                        popoverTargetOffset: '10px - 36px',
                                        minDate: new Date()
                                    }}
                                    disabled={disabled}
                                    label={this.context.intl.messages['scheduling.batch.summary.date']}
                                    model={this.props.batch}
                                    setter={BatchActions.updateBatchDuplicated}
                                    type="date"
                                    validations={BatchValidations.executionDate.validations}
                                />
                                <FormItem
                                    attr="executionTime"
                                    addonBefore={<i className="far fa-clock"></i>}
                                    datepicker={{
                                        isClearable: false,
                                        popoverTargetOffset: '10px -36px',
                                        shouldCloseOnSelect: true,
                                        showTimeSelect: true,
                                        showTimeSelectOnly: true,
                                        minDate: new Date(),
                                        value: timeFormatted
                                    }}
                                    disabled={this.props.disabled}
                                    label={this.context.intl.messages['scheduling.batch.summary.time']}
                                    model={this.props.batch}
                                    setter={this.handleTimeChange}
                                    type="time"
                                    validations={BatchValidations.executionTime.validations}
                                />
                            </FormRow>
                            {batchInfo}
                        </FormSection>
                    </Preloader>
                </Modal.Body>

                <Modal.Footer>
                    <Button className="pull-left Ml(5)" onClick={this.handleHide}>
                        {this.context.intl.messages['common.cancel']}
                    </Button>
                    <Button bsStyle="primary" type="submit"
                        disabled={!this.isValid()}
                        onClick={this.handleConfirmDuplicate}>
                        <i className="fas fa-copy"/>&nbsp;{this.context.intl.messages['scheduling.batch.duplicate.modal.confirm']}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default DuplicateModal;
