/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

import FilterOptions from './filter-options';
import RoleTable from './table';
import SearchBox from '../../common/search-box/search-box';
import {WithStoreOnRoute} from '../../common/store-on-route';
import {Debounce} from '../../common/utils/utils';
import {LayoutActions} from '../../layout/layout-actions';
import LayoutStore from '../../layout/layout-store';
import Preloader from '../../preloader/';
import PreloaderStore from '../../preloader/preloader-store';
import RoleStore from '../../security/role/role-store';
import SessionStore from '../../user/session/session-store';


class Browse extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            route: undefined
        };
    }

    static calculateState() {
        return {
            showFiltersPanel: LayoutStore.getState().get('showFiltersPanel'),
            roles: RoleStore.getState().get('roles'),
            role: RoleStore.getState().get('role'),
            total: RoleStore.getState().get('total'),
            roleHistory: RoleStore.getState().get('roleHistory'),
            selectedPermission: RoleStore.getState().get('selectedPermission'),
            basePermission: RoleStore.getState().get('basePermission'),
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible'),
        };
    }

    static getStores() {
        return [LayoutStore, RoleStore, PreloaderStore];
    }

    static getPermissions() {
        return {
            canReadRoles: SessionStore.canUser(SessionStore.PERMISSIONS.ROLES.READ)
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.getRolesCount = this.getRolesCount.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
        this.handleToggleFiltersPanel = this.handleToggleFiltersPanel.bind(this);
    }

    getRolesCount(count) {
        this.refs.rolesCount.innerHTML = `(${count} ${this.context.intl.messages['roles.title']})`;
        return;
    }

    handleSearchTerm(term) {
        // This is a local search
        this.setRouteState('q', term)
            .clearRouteState('offset')
            .apply();
    }

    handleToggleFiltersPanel() {
        LayoutActions.toggleFiltersPanel();
        return;
    }

    render() {
        let showInactive = this.getRouteState().get('show-inactive') === 'true';
        return (
            <div className={ClassNames({'control-sidebar-open': this.state.showFiltersPanel})}>
                <div className={ClassNames({'sidebar-opened': this.state.showFiltersPanel})}>
                    <Preloader show={this.state.preloaderVisible} fixed loadingDots>
                        <section className="content-header">
                            <h1>
                                <i className="fas fa-male"></i>
                                &nbsp;
                                {this.context.intl.messages['roles.title']}
                                &nbsp;
                                <small ref="rolesCount"></small>
                            </h1>
                        </section>
                        <section className="content">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="container-fluid no-x-padding">
                                        <div className="row">
                                            <div className="col-md-6 show-grid">
                                                <SearchBox value={this.getRouteState().get('q')} onChange={this.handleSearchTerm}/>
                                            </div>
                                            <div className="col-md-6 show-grid">
                                                <Button onClick={this.handleToggleFiltersPanel} bsStyle="primary" bsSize="large" className="pull-right">
                                                    <i className="fas fa-sliders-h"></i>&nbsp;{this.context.intl.messages['common.filteringOptions']}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container-fluid no-x-padding">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="box">
                                                    <div className="box-body padding-x-20 padding-y-20">
                                                        <RoleTable
                                                            displayLinks={this.props.permissions.canReadRoles}
                                                            filters={this.getRouteState().get('q')}
                                                            location={this.props.location}
                                                            roles={this.state.roles}
                                                            rolesCount={this.getRolesCount}
                                                            showInactive={showInactive}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <FilterOptions
                            location={this.props.location}
                        />
                    </Preloader>
                </div>
            </div>
        );
    }
}

export default WithStoreOnRoute(Container.create(Browse));
