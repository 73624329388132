/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {WorkOrdersConstants} from './work-orders-actions';
import Validations from '../../common/validations/validations';
import Dispatcher from '../../dispatcher/dispatcher';

const WorkOrdersValidations = {
    name: {validations: [Validations.required]},
};

class WorkOrdersStore extends ReduceStore {

    getInitialState() {
        let state = Immutable.Map({
            recipientUser: null,
            selectedTitle: Immutable.Map(),
            showAddTitleDialog: false,
            total:0,
            workOrder: Immutable.Map({
                fallbackEnglish: true,
                status: WorkOrdersConstants.STATUS_BY_NAME.ACTIVE
            }),
            workOrderTitles: Immutable.List(),
            workOrders: Immutable.List()
        });

        state = state.merge({
            originalWorkOrder: state.get('workOrder'),
            originalWorkOrderTitles: state.get('workOrderTitles')
        });
        return state;
    }

    getValidations() {
        return Validations.validate(this.getState().get('workOrder'), WorkOrdersValidations);
    }

    reduce(state, action) {
        switch (action.actionType) {
        case WorkOrdersConstants.ADD_TITLES:
            let woid = state.getIn(['workOrder', 'id']);
            let titles = action.titles.map(t => t.set('workOrderId', woid).set('titleId', t.get('id')).set('titleDisplayName', t.get('displayName')));
            state = state.updateIn(['workOrderTitles'], wot => wot.concat(titles));
            break;

        case WorkOrdersConstants.ADD_TITLES_SHOW:
            state = state.set('showAddTitleDialog', true)
                .set('selectedTitle', action.title);
            break;

        case WorkOrdersConstants.ADD_TITLES_HIDE:
            state = state.set('showAddTitleDialog', false);
            break;

        case WorkOrdersConstants.CLEAR:
        case WorkOrdersConstants.GET.START:
            state = this.getInitialState();
            break;

        case WorkOrdersConstants.FIND_BY_ID.RECIPIENT_USER:
            state = state.set('recipientUser', action.recipientUser);
            break;

        case WorkOrdersConstants.FIND_BY_ID.SUCCESS:
            state = state.merge({
                workOrder: action.workOrder,
                originalWorkOrder: action.workOrder,
                workOrderTitles: action.workOrderTitles,
                originalWorkOrderTitles: action.workOrderTitles
            });
            break;

        case WorkOrdersConstants.GET.SUCCESS:
            state = state.set('workOrders', action.workOrders)
                .set('total', action.total);
            break;

        case WorkOrdersConstants.REMOVE_TITLE:
            state = state.updateIn(['workOrderTitles'], wot => {
                return wot.remove(action.index);
            });
            break;

        case WorkOrdersConstants.REORDER:
            state = state.updateIn(['workOrderTitles'], wot => {
                let from = wot.get(action.from);
                return wot.remove(action.from)
                    .insert(action.to, from);
            });
            break;

        case WorkOrdersConstants.SAVE.SUCCESS:
            state = state.merge({
                workOrder: action.workOrder,
                originalWorkOrder: action.workOrder,
                workOrderTitles: action.workOrderTitles,
                originalWorkOrderTitles: action.workOrderTitles
            });
            break;

        case WorkOrdersConstants.UPDATE:
            state = state.setIn(['workOrder', ...action.attr.split('.')], action.value);
            break;

        }
        return state;
    }
}

const store = new WorkOrdersStore(Dispatcher);

export default store;
export {WorkOrdersValidations};
