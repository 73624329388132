/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

import Dispatcher from '../../dispatcher/dispatcher';
import Request from '../../request';

const CONSTANTS = {
    LOAD: 'user.company.load'
};

class UserCompanyActions {
    buildCompanies(values) {
        let companies = [];
        values.forEach(function(value) {
            companies.push ({id:value, name:value});
        });
        return Immutable.fromJS(companies);
    }

    get(filter, offset, size) {
        let query = {
            offset: offset || 0,
            size: size || 20
        };

        if (filter) {
            query.name = filter;
        }

        Request.get('user/company').query(query).exec().then(res => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.LOAD,
                offset: res.body.offset,
                companies: this.buildCompanies(res.body.results),
                size: res.body.size,
                total: res.header['wbtv-total-count']
            });
        });

        return;
    }
}

let actions = new UserCompanyActions();

export {
    actions as UserCompanyActions,
    CONSTANTS as UserCompanyConstants
};
