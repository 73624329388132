/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import AddChildrenDialog from './add-children-dialog';
import {SlidingPanelActions} from '../../../common/sliding-panel/sliding-panel-actions';
import {Debounce} from '../../../common/utils/utils';
import LayoutStore from '../../../layout/layout-store';
import ListTitles from '../../../titles/browse/list-titles';
import FilterOptions from '../../../titles/filter-options/filter-options';
import {TitleActions, TitleConstants} from '../../../titles/title-actions';
import TitleStore from '../../../titles/title-store';
import {BatchActions} from '../../batch-actions';
import BatchStore from '../../batch-store';

class AddExistingTitles extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            onAddTitle: PropTypes.func.isRequired,
            selectedTitles: PropTypes.instanceOf(Immutable.List).isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
        };
    }

    static calculateState() {
        const ts = TitleStore.getState();
        return {
            titles: ts.get('titles'),
            filters: ts.get('filters'),
            size: ts.get('size'),
            total: ts.get('total'),
            offset: ts.get('offset'),
            selectedTitle: BatchStore.getState().get('selectedTitle'),
            showAddChildrenDialog: BatchStore.getState().get('showAddChildrenDialog'),
            showFiltersPanel: LayoutStore.getState().get('showFiltersPanel'),
            sortDirection: ts.get('sortDirection'),
            sortFieldName: ts.get('sortFieldName')
        };
    }

    static getStores() {
        return [BatchStore, TitleStore, LayoutStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            selectedTitle: Immutable.Map(),
            showAddChildrenDialog: false
        }, this.constructor.calculateState());

        this.handleAddTitle = this.handleAddTitle.bind(this);
        this.handleAddTitleAndChildren = this.handleAddTitleAndChildren.bind(this);
        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
        this.handleSortChange = this.handleSortChange.bind(this);
    }

    componentDidMount() {
        TitleActions.clear();
        TitleActions.get(this.state.filters, 0, 20);
        return;
    }

    shouldComponentUpdate(nextProps, nextState /*, nextContext*/) {
        return nextState.titles !== this.state.titles ||
            nextState.showFiltersPanel !== this.state.showFiltersPanel;
    }

    handleAddTitle(event) {
        if (~event.target.className.indexOf('add-title-panel') ||
            ~event.target.className.indexOf('fa-plus')) {
            let titleId = parseInt(event.target.getAttribute('data-title-id'));

            if (~event.target.className.indexOf('fa-plus')) {
                titleId = parseInt(event.target.parentNode.getAttribute('data-title-id'));
            }

            const titleToAdd = this.state.titles.find(title => title.get('id') === titleId);
            switch (TitleActions.getCategoryGroup(titleToAdd.get('category'))) {
            case TitleConstants.TITLE_CATEGORY_GROUPS.EPISODE:
            case TitleConstants.TITLE_CATEGORY_GROUPS.SEASON:
            case TitleConstants.TITLE_CATEGORY_GROUPS.SERIES:
                if (event.target.getAttribute('data-title-label') === 'remove-title') {
                    this.props.onAddTitle(titleToAdd);
                } else {
                    BatchActions.toggleShowAddChildrenModal(titleToAdd);
                }
                break;
            default:
                this.props.onAddTitle(titleToAdd);
            }
        }

        return;
    }

    handleAddTitleAndChildren(titles) {
        BatchActions.addChildren(titles);
        SlidingPanelActions.hide('addExistingItemToBatch');
        return;
    }


    handleLoadPage(pageNumber) {
        // pageNumber x this.state.size = offset.
        TitleActions.get(this.state.filters, pageNumber * this.state.size, this.state.size);
        return;
    }

    handleSearchTerm(term) {
        TitleActions.setFilter('title', term);
        TitleActions.setFilter('operator', 'AND');
        TitleActions.get(this.state.filters, 0, 20);
        return;
    }

    handleSortChange(sortFieldName, sortDirection) {
        TitleActions.setSort(sortFieldName, sortDirection);
        TitleActions.get(this.state.filters, 0, this.state.size, this.state.sortFieldName, this.state.sortDirection);
        return;
    }

    render() {
        const {titles, totalPages, offset, size} = this.state;
        let columns = ['mpmNumber', 'title', 'titleReleaseDate', 'categoryName',
            {
                name: 'actions',
                get: (title, props, context) => {
                    let buttonLabel;
                    let buttonStyle;
                    if (this.props.selectedTitles.findIndex(t => t.get('id') === title.get('id')) === -1) {
                        buttonLabel = 'add-title';
                        buttonStyle = 'btn-primary';
                    } else {
                        buttonLabel = 'remove-title';
                        buttonStyle = 'btn-danger';
                    }
                    return `<button class="btn ${buttonStyle} add-title-panel" data-title-id="${title.get('id')}" data-title-label="${buttonLabel}">
                        <i class="fas fa-plus"></i>&nbsp;${context.intl.messages[`scheduling.batch.items-list.adding-existing.${buttonLabel}`]}</button>`;
                },
                onClick: this.handleAddTitle
            }
        ];

        let filterOptions;
        if (this.state.showFiltersPanel) {
            filterOptions = <FilterOptions
                filters={this.state.filters}
                showActive={true}
                showCategory={false}
                showCreateDate={false}
                showMpmNumber={false}
                showReferenceId={false}
                showReleaseDate={false}
                showTitle={false}
                showStatusFields={true}
                location={this.props.location}
                enableOnChange={true}
            />;
        }

        return (
            <div>
                <AddChildrenDialog
                    onAddTitle={this.props.onAddTitle}
                    onAddTitleAndChildren={this.handleAddTitleAndChildren}
                    show={this.state.showAddChildrenDialog}
                    title={this.state.selectedTitle}/>
                <ListTitles
                    activePage={Math.ceil(offset/size) || 0}
                    columns={columns}
                    filterButton={true}
                    displayLinks={false}
                    linkTarget="_blank"
                    location={this.props.location}
                    onPageChange={this.handleLoadPage}
                    onSearchChange={this.handleSearchTerm}
                    onSortChange={this.handleSortChange}
                    sortDirection={this.state.sortDirection}
                    sortFieldName={this.state.sortFieldName}
                    titles={titles}
                    totalPages={totalPages}
                />
                {filterOptions}
            </div>
        );
    }

}

export default Container.create(AddExistingTitles);
