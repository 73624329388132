/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, Modal} from 'react-bootstrap';

class AddNewTitle extends Component {
    static get propTypes() {
        return {
            onConfirmSave: PropTypes.func.isRequired,
            onHide: PropTypes.func.isRequired,
            show: PropTypes.bool.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.handleAdd = this.handleAdd.bind(this);
    }

    handleAdd() {
        this.props.onConfirmSave();
    }

    render() {
        return (
            <Modal {...this.props}>
                <Modal.Header className="bg-gray">
                    <Modal.Title className="text-center">{this.context.intl.messages['titles.create.related.list.add-new.title']}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.context.intl.messages['titles.create.related.list.add-new.msg']}
                </Modal.Body>
                <Modal.Footer>
                    <Button className="pull-left Ml(5)" onClick={this.props.onHide}>&nbsp;{this.context.intl.messages['titles.create.related.list.cancel-return']}</Button>
                    <Button bsStyle="primary" onClick={this.handleAdd}><i className="fas fa-plus-square"></i>&nbsp;{this.context.intl.messages['titles.create.related.list.continue-save']}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default AddNewTitle;
