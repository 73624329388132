/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

import {AlertTypes} from '../common/notification/alert';
import {NotificationActions} from '../common/notification/notification-actions';
import Dispatcher from '../dispatcher/dispatcher';
import Request from '../request';

const CONSTANTS = {
    GET: {
        ERROR: 'asset_user_actions.get.error',
        SUCCESS: 'asset_user_actions.get.success',
        START: 'asset_user_actions.get.start'
    },
    FILTER: {
        CLEAR: 'asset_user_actions.filter.clear',
        SET: 'asset_user_actions.filter.set'
    },
    CLEAR: 'asset_user_actions.clear'
};

class AssetUserActions {

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });

        return;
    }

    get(assetId, queryParams, offset=0, size=20, sortFieldName='name', sortDirection='asc') {
        Dispatcher.dispatch({
            actionType: CONSTANTS.GET.START
        });
        queryParams = queryParams.toJS();
        Request.get(`asset/${assetId}/user`).query({
            offset: offset,
            size: size,
            'sort-field': sortFieldName,
            'sort-order': sortDirection
        }).query(
            queryParams
        ).exec().then(res => {
            let users = Immutable.fromJS(res.body.results);
            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.SUCCESS,
                offset: offset,
                size: size,
                total: res.body.totalCount,
                users: users
            });
            return;
        }).catch(err => {
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'asset.users.load-error');
            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.ERROR
            });
            throw err;
        });
        return;
    }
}

let actions = new AssetUserActions();

export {
    actions as AssetUserActions,
    CONSTANTS as AssetUserConstants
};
