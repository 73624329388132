/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {ImageConstants} from './image-actions';
import Validations, {SEVERITY} from '../../common/validations/validations';
import Dispatcher from '../../dispatcher/dispatcher';
import {ActionHistoryConstants} from '../../system/action-history/action-history-actions';

const ImageValidations = {
    caption: {
        label: 'asset.image.caption',
        validations: [Validations.max(1000)]
    },
    contentType: {
        label: 'asset.image.contentType',
        validations: [Validations.required]
    },
    copyrightText: {
        label: 'asset.image.copyrightText',
        validations: [Validations.max(200)]
    },
    copyrightYear: {
        label: 'asset.image.copyrightYear',
        validations: [Validations.max(4)]
    },
    credit: {
        label: 'asset.image.edit.credit',
        validations: [Validations.max(50)]
    },
    deliveryType: {
        label: 'asset.image.deliveryType',
        validations: [Validations.required]
    },
    keyword: {
        label: 'asset.image.edit.keyword',
        validations: [Validations.max(250)]
    },
    assetName: {
        label: 'asset.image.name',
        validations: [Validations.max(255), Validations.required]
    },
    assetComment: {
        label: 'asset.image.assetComment',
        validations: [Validations.max(255)]
    },
    sourcefileExtension: {
        validations: [
            Validations.custom(
                () => {
                    return 'Files without extension are not allowed.';
                },
                () => {
                    let isValid = true;

                    //source file(s)
                    const allFiles = image.getState().getIn(['asset', 'files']);
                    if (allFiles) {
                        allFiles.forEach(asset => {
                            if (!image.hasExtension(asset)) {
                                isValid = false;
                            }
                        });
                    }

                    //rendition
                    const rendition = image.getState().getIn(['asset', 'rendition']);
                    if (rendition && rendition.name && !image.hasExtension(rendition)) {
                        isValid = false;
                    }

                    return isValid;
                }
            )
        ]
    },
};

const ImageFilesValidation = {
    file: {
        validations: [
            Validations.custom(
                () => {
                    return 'Suggested file types: BMP, DASH, GIF, JPEG, MPEGURL, MP4, OGG, PNG, SSTR, SVG, ZIP.';
                },
                () => {
                    let isValid = true;

                    //source file(s)
                    const allFiles = image.getState().getIn(['asset', 'files']);
                    if (allFiles) {
                        allFiles.forEach(asset => {
                            if (!image.hasAllowedMimeType(asset)) {
                                isValid = false;
                            }
                        });
                    }

                    //rendition
                    const rendition = image.getState().getIn(['asset', 'rendition']);
                    if (rendition && rendition.name && !image.hasAllowedMimeType(rendition)) {
                        isValid = false;
                    }

                    return isValid;
                },
                SEVERITY.WARNING
            )
        ]
    },
    uploadedImage: {
        validations: [
            Validations.custom(
                () => {
                    return 'Image rendition processing can take up to 1-2mins. Your image will automatically appear when it becomes available.';
                },
                () => {
                    return image.getState().get('isImageOnS3Bucket');
                },
                SEVERITY.WARNING
            )
        ]
    }
};

class ImageStore extends ReduceStore {

    /**
     * Gets image type given the asset contentType
     * @param {string} contentType
     */
    getImageTypeByContentType(contentType) {
        const imageTypeKey = Object.keys(ImageConstants.IMAGE_TYPES).find(key => {
            const imageType = ImageConstants.IMAGE_TYPES[key];
            return Object.keys(imageType.subType).findIndex(subTypeKey =>
                ImageConstants.IMAGE_TYPES[key].subType[subTypeKey].id === contentType
            ) !== -1;
        });
        return ImageConstants.IMAGE_TYPES[imageTypeKey];
    }

    /**
     * Gets images grouped by image type
     * @param {Immutable.List} assets
     */
    groupAssetsByType(assets) {
        assets = assets.toJS();
        const defaultGroup = ImageConstants.IMAGE_TYPES.OTHER.subType.OTHER;

        return assets.reduce((groups, asset) => {
            const imageType = this.getImageTypeByContentType(asset.contentType) || defaultGroup;

            if (imageType) {
                const list = groups[imageType.name] || [];
                groups[imageType.name] = [...list, asset];
            }

            return groups;
        }, {});
    }

    getImageType(imageSuperType, contentType) {
        if (imageSuperType === undefined || imageSuperType === null) {
            return;
        }

        let result = Object.keys(ImageConstants.IMAGE_TYPES)
            .map(k => ImageConstants.IMAGE_TYPES[k])
            .filter(ct => ct.id === imageSuperType)[0];

        if (contentType === undefined || contentType === null) {
            return Immutable.fromJS(result);
        }

        return Immutable.fromJS(
            Object.keys(result.subType)
                .map(k => result.subType[k])
                .filter(st => st.id === contentType)[0]
        );
    }

    hasExtension(file) {
        let extension = file.name.match(/\.[0-9a-z]+$/i) || [];
        return extension[0];

    }

    hasAllowedMimeType(file) {
        return file.type.match('application.(zip|dash+xml|vnd.(apple.mpegurl|ms-sstr+xml))|image.*|video.*');
    }

    getInitialState() {
        return Immutable.Map({
            asset: Immutable.Map({
                active: 1,
                files: [],
                rendition: null
            }),
            contentType: Immutable.fromJS(ImageConstants.toArray('IMAGE_TYPES')),
            history: Immutable.List(),
            isImageOnS3Bucket: true,
            originalAsset: Immutable.Map({
                active: 1,
            }),
            showPreloader: false,
        });
    }

    getValidations() {
        return Validations.validate(this.getState().get('asset'), ImageValidations);
    }

    getImageTypeValidations() {
        return Validations.validate(this.getState().get('asset'), ImageFilesValidation);
    }

    reduce(state, action) {
        switch (action.actionType) {
        case ActionHistoryConstants.ADD_NOTE.SUCCESS:
            if ( action.actionObjectType === ActionHistoryConstants.ACTION_OBJECTS.IMAGE ) {
                state = state.updateIn(['history'], history => history.unshift(action.note));
            }
            break;

        case ImageConstants.IMAGE.CLEAR:
            state = this.getInitialState();
            break;

        case ImageConstants.IMAGE.GET.START:
            state = this.getInitialState();
            state = state.merge({
                showPreloader: true
            });
            break;

        case ImageConstants.IMAGE.GET.SUCCESS:
            state = state.merge({
                asset: action.asset,
                contentType: Immutable.fromJS(ImageConstants.toArray('IMAGE_TYPES')),
                history: action.history,
                isImageOnS3Bucket: action.isImageOnS3Bucket,
                originalAsset: action.asset,
                showPreloader: false,
            });
            break;

        case ImageConstants.IMAGE.SAVE.START:
            state = state.set('showPreloader', true);
            break;

        case ImageConstants.IMAGE.SAVE.ERROR:
            state = state.set('showPreloader', false);
            break;

        case ImageConstants.IMAGE.RE_FETCH_COMPLETE:
            state = state.mergeIn(['asset'], {
                fullResolutionUrl: action.loadedImgData.fullResolutionUrl,
                largePreviewUrl: action.loadedImgData.largePreviewUrl,
                largeThumbnailUrl: action.loadedImgData.largeThumbnailUrl,
                previewUrl: action.loadedImgData.previewUrl,
                thumbnailUrl: action.loadedImgData.thumbnailUrl,
                sourceUrl: action.loadedImgData.sourceUrl
            }).merge({
                isImageOnS3Bucket: true
            });
            break;

        case ImageConstants.IMAGE.SAVE.SUCCESS:
            state = state.setIn(['asset', 'id'], action.id);
            state = state.set('originalAsset', state.get('asset'));
            state = state.set('showPreloader', false);
            state = state.merge({
                files: [],
                rendition: {
                    name: ''
                }
            });
            break;

        case ImageConstants.IMAGE.UPDATE:
            state = state.setIn(['asset', ...action.attr.split('.')], action.value);
            break;

        }
        return state;
    }
}

let image = new ImageStore(Dispatcher);

export default image;
export {ImageValidations, ImageFilesValidation};
