/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {CATEGORY_TYPES, VIDEO_INSIGHT_STATUS_TYPES} from '../constants';
import {IsAssignedModel} from '../typeguards';

import AssetAiModelsStore from '~/src/assets/video/ai-models-store';
import {GetValueOfTimestamp} from '~/src/common/utils/date-converters';
import {ConvertFluxStoreToBehaviorSubject, TransformBehaviorSubject} from '~/src/common/utils/flux-to-rx-helpers';
import KeyBy from '~/src/common/utils/key-by';
import {CreateSimpleStore} from '~/src/common/utils/rx-store-helpers';

// simple stores
export const [NotAppliedSearchTerm$, ChangeNotAppliedSearchTerm] = CreateSimpleStore('');

// flux stores
export const Models$ = ConvertFluxStoreToBehaviorSubject(AssetAiModelsStore, 'models');
export const NotAppliedModels$ = ConvertFluxStoreToBehaviorSubject(AssetAiModelsStore, 'notAppliedModels');
export const OriginalModels$ = ConvertFluxStoreToBehaviorSubject(AssetAiModelsStore, 'originalModels');

// computed stores
export const GroupedModelsByActiveStatus$ = TransformBehaviorSubject(Models$, groupModelsByActiveState);
export const FilteredNotAppliedModels$ = TransformBehaviorSubject(NotAppliedModels$, NotAppliedSearchTerm$, filterNotAppliedModels);
export const HasNewlyAssignedModels$ = TransformBehaviorSubject(Models$, hasNewlyAssignedModels);
export const HasSomeModels$ = TransformBehaviorSubject(Models$, NotAppliedModels$, hasSomeModels);
export const HasUnassignedViModel$ = TransformBehaviorSubject(NotAppliedModels$, hasModelByTypes(CATEGORY_TYPES.VI));
export const HasContentAiModelActivated$ = TransformBehaviorSubject(Models$, hasContentAiModelActivated);
export const OriginalModelsAsCache$ = TransformBehaviorSubject(OriginalModels$, keyByProjectId);

type Models = VideoMachineLearningProject[];

export type GroupedModelsByActiveStatus = DeepImmutable<{
    active: Models,
    inactive: Models,
}>

function groupModelsByActiveState(models: ReadonlyArray<VideoMachineLearningProject>): GroupedModelsByActiveStatus {
    const arr = [...models].sort((m1, m2) => {
        let t1 = getTimestampFromModel(m1, 'update');
        let t2 = getTimestampFromModel(m2, 'update');

        if (t1 === t2) {
            t1 = getTimestampFromModel(m1, 'create');
            t2 = getTimestampFromModel(m2, 'create');
        }

        return t2 - t1;
    });

    const res = {
        active: [] as Models,
        inactive: [] as Models,
    };
    const cache = {} as Record<VideoMachineLearningCategoryType, VideoMachineLearningProject>;

    arr.forEach((model) => {
        if (!IsAssignedModel(model)) {
            res.active.push(model);
            return;
        }

        if (!model.categoryType || cache[model.categoryType]) {
            return;
        }
        cache[model.categoryType] = model;
        if (model.insightActive) {
            res.active.push(model);
        } else {
            res.inactive.push(model);
        }
    });

    return res;
}

function filterNotAppliedModels(models: ReadonlyArray<VideoMachineLearningProject>, searchTerm: string) {
    return models.filter((m) => {
        const r = new RegExp(searchTerm, 'i');
        return r.test(m.projectName);
    });
}

function hasNewlyAssignedModels(models: ReadonlyArray<VideoMachineLearningProject>): boolean {
    return models.some((m) => !IsAssignedModel(m));
}

function hasSomeModels(models: ReadonlyArray<VideoMachineLearningProject>, notAppliedModels: ReadonlyArray<VideoMachineLearningProject>) {
    return models?.length > 0 || notAppliedModels?.length > 0;
}

function keyByProjectId(models: ReadonlyArray<VideoMachineLearningProject>): Dictionary<VideoMachineLearningProject> {
    return KeyBy('projectId', models);
}

function hasModelByTypes(...types: VideoMachineLearningCategoryType[]) {
    return function hasModel(models: VideoMachineLearningProject[]) {
        return models.some((model) => types.includes(model.categoryType));
    };
}

function hasContentAiModelActivated(models: ReadonlyArray<VideoMachineLearningProjectDetail>) {
    return !!models.find((model) =>
        model.categoryType === CATEGORY_TYPES.BRAINIAC_WORKFLOW_START
        && model.statusType === VIDEO_INSIGHT_STATUS_TYPES.SUCCEEDED
    );
}

type ModelDateType = 'create' | 'update';
const VI_MODEL_DATE_FIELD: Record<ModelDateType, keyof SubType<VideoMachineLearningProjectDetail, Timestamp>> = {
    create: 'insightCreatedDate',
    update: 'insightUpdatedDate',
};
const BASE_MODEL_DATE_FIELD: Record<ModelDateType, keyof SubType<VideoMachineLearningProject, Timestamp>> = {
    create: 'createdDate',
    update: 'updatedDate',
};
function getTimestampFromModel(model: VideoMachineLearningProject, type: ModelDateType) {
    if (IsAssignedModel(model)) {
        return GetValueOfTimestamp(model[VI_MODEL_DATE_FIELD[type]]);
    }
    return GetValueOfTimestamp(model[BASE_MODEL_DATE_FIELD[type]]);
}
