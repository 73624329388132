/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';
import {IndexRoute, Route} from 'react-router';

import CreateAudio from './audio/create/create';
import Browse from './browse/browse';
import CatalogsTab from './catalogs/catalogs';
import EventCatalogsTab from './catalogs/event-catalogs';
import CreateDocument from './document/create/create';
import CreateImage from './image/create/create';
import CreateMerchandise from './merchandise/create/create';
import CreateScript from './script/create/create';
import TalentTab from './talent/talent';
import EditTimeline from './timeline';
import TitleTab from './title/title';
import UserTab from './user/user';
import {History} from '../common/side-notes/side-notes';
import WithPermissions from '../decorators/with-permissions';
import {RequirePerm} from '../router/router-helpers';
import AiModels from './video/create/ai-models/ai-models';
import CreateVideo from './video/create/create';
import SessionStore from '../user/session/session-store';
import ForensicTab from './video/create/forensic/forensic';
import PlaybackRestrictions from './video/create/playback-restrictions/playback-restrictions';

export default (
    <Route path="assets">
        <IndexRoute
            component={WithPermissions(Browse)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.ASSETS.BROWSE_ALL)}
        />

        {/*Browse Images*/}
        <Route
            component={WithPermissions(Browse)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.ASSETS.ASSET_IMAGE.BROWSE)}
            path="/assets/images"
        />

        {/*Browse Videos*/}
        <Route
            component={WithPermissions(Browse)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.ASSETS.VIDEO.BROWSE)}
            path="/assets/videos"
        />

        {/*Browse Documents*/}
        <Route
            component={WithPermissions(Browse)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.ASSETS.ASSET_DOCUMENT.BROWSE)}
            path="/assets/documents"
        />

        {/*Create and Edit Audio*/}
        <Route
            component={WithPermissions(CreateAudio)}
            mode="create"
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.ASSETS.AUDIO.CREATE)}
            path="/assets/audio/create"
        />
        <Route
            component={WithPermissions(CreateAudio)}
            mode="edit"
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.ASSETS.AUDIO.READ)}
            path="/assets/audio/:id"
        >
            <Route path="titles" component={TitleTab}/>
            <Route path="talent" component={TalentTab}/>
            <Route path="users" component={UserTab}/>
            <Route path="catalogs" component={CatalogsTab}/>
            <Route path="history" component={History}/>
        </Route>

        {/*Create and Edit Document*/}
        <Route
            component={WithPermissions(CreateDocument)}
            mode="create"
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.ASSETS.ASSET_DOCUMENT.CREATE)}
            path="/assets/document/create"
        >
            <Route path="catalogs" component={CatalogsTab}/>
        </Route>
        <Route
            component={WithPermissions(CreateDocument)}
            mode="edit"
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.ASSETS.ASSET_DOCUMENT.READ)}
            path="/assets/document/:id"
        >
            <Route path="titles" component={TitleTab}/>
            <Route path="talent" component={TalentTab}/>
            <Route path="users" component={UserTab}/>
            <Route path="catalogs" component={CatalogsTab}/>
            <Route path="history" component={History}/>
        </Route>

        {/*Create and Edit Image*/}
        <Route
            component={WithPermissions(CreateImage)}
            mode="create"
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.ASSETS.ASSET_IMAGE.CREATE)}
            path="/assets/image/create"
        >
            <Route path="catalogs" component={CatalogsTab}/>
        </Route>
        <Route
            component={WithPermissions(CreateImage)}
            mode="edit"
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.ASSETS.ASSET_IMAGE.READ)}
            path="/assets/image/:id"
        >
            <Route path="titles" component={TitleTab}/>
            <Route path="talent" component={TalentTab}/>
            <Route path="users" component={UserTab}/>
            <Route path="catalogs" component={CatalogsTab}/>
            <Route path="history" component={History}/>
        </Route>

        {/*Create and Edit Merchandise*/}
        <Route
            component={WithPermissions(CreateMerchandise)}
            mode="create"
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.ASSETS.MERCHANDISE.CREATE)}
            path="/assets/merchandise/create"
        />
        <Route
            component={WithPermissions(CreateMerchandise)}
            mode="edit"
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.ASSETS.MERCHANDISE.READ)}
            path="/assets/merchandise/:id"
        >
            <Route path="titles" component={TitleTab}/>
            <Route path="talent" component={TalentTab}/>
            <Route path="users" component={UserTab}/>
            <Route path="catalogs" component={CatalogsTab}/>
            <Route path="history" component={History}/>
        </Route>

        {/*Create and Edit Script*/}
        <Route
            component={WithPermissions(CreateScript)}
            mode="create"
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.ASSETS.SCRIPT.CREATE)}
            path="/assets/script/create"
        />
        <Route
            component={WithPermissions(CreateScript)}
            mode="edit"
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.ASSETS.SCRIPT.READ)}
            path="/assets/script/:id"
        >
            <Route path="titles" component={TitleTab}/>
            <Route path="talent" component={TalentTab}/>
            <Route path="users" component={UserTab}/>
            <Route path="catalogs" component={CatalogsTab}/>
            <Route path="history" component={History}/>
        </Route>

        {/*Create and Edit Video*/}
        <Route
            component={WithPermissions(CreateVideo)}
            entity="video"
            mode="create"
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.ASSETS.VIDEO.CREATE)}
            path="/assets/video/create"
        />
        <Route
            component={WithPermissions(CreateVideo)}
            entity="video"
            mode="edit"
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.ASSETS.VIDEO.READ)}
            path="/assets/video/:id"
        >
            <Route path="titles" component={TitleTab}/>
            <Route path="talent" component={TalentTab}/>
            <Route path="users" component={UserTab}/>
            <Route path="catalogs" component={CatalogsTab}/>
            <Route path="events" component={EventCatalogsTab}/>
            <Route path="forensic" component={ForensicTab}/>
            <Route path="history" component={History}/>
            <Route path="ai-models" component={AiModels}/>
            <Route path="playback-restrictions" component={PlaybackRestrictions}/>
        </Route>

        {/*Edit Video Timeline*/}
        <Route
            component={EditTimeline}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.HARDAC.TIMELINE.READ)}
            path="/assets/timeline/:id"
        >
            <Route path="talent"/>
            <Route path="titles"/>
            <Route path="users"/>
            <Route path="history"/>
            <Route path="catalogs"/>
        </Route>

        {/*Edit Video Proxy*/}
        <Route
            component={WithPermissions(CreateVideo)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.HARDAC.PROXY.READ)}
            path="/assets/proxy/:id"
            mode="edit"
            entity="proxy"
        >
            <Route path="titles"/>
            <Route path="talent"/>
            <Route path="users"/>
            <Route path="catalogs"/>
            <Route path="events"/>
            <Route path="forensic"/>
            <Route path="history"/>
            <Route path="ai-models" component={AiModels}/>
            <Route path="playback-restrictions"/>
        </Route>
    </Route>
);
