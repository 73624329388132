/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';

import {AlertTypes} from '../common/notification/alert';
import {NotificationActions} from '../common/notification/notification-actions';
import Dispatcher from '../dispatcher/dispatcher';
import {PreloaderActions} from '../preloader/preloader-actions';
import Request from '../request';
import {RouterActions} from '../router/router-actions';
import {ActionHistoryConstants} from '../system/action-history/action-history-actions';

const CONSTANTS = {
    CLEAR: 'homescreen.clear',
    FILTERS: {
        ACTIVE_OPTIONS: {
            ACTIVE: {id: 'ACTIVE', value: 1, name: 'Active'},
            INACTIVE: {id: 'INACTIVE', value: 0, name: 'Inactive'},
            BOTH: {id: 'BOTH', value: 2, name: 'Both'}
        }
    },
    FILTER: {
        CLEAR: 'homescreen.filter.clear',
        SET: 'homescreen.filter.set'
    },
    FIND_BY_ID: {
        SUCCESS: 'homescreen.findById.success'
    },
    GET: {
        ERROR: 'homescreen.get.error',
        SUCCESS: 'homescreen.get.success'
    },
    ITEMS: {
        GET: {
            SUCCESS: 'homescreen.get.items.success'
        },
    },
    PUBLISHING_LIST: {
        ADD: 'homescreen.publishingList.add',
        REMOVE: 'homescreen.publishingList.remove'
    },
    REMOVE: {
        SUCCESS: 'homescreen.remove.success'
    },
    SAVE: {
        SUCCESS: 'homescreen.save.success'
    },
    UPDATE: 'homescreen.update',
    USER_GROUPS: {
        GET: {
            SUCCESS: 'homescreen.get.user.groups.success'
        }
    }
};

class HomescreenActions {
    addPublisingList() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.PUBLISHING_LIST.ADD,
        });
    }

    removePublishingList(section) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.PUBLISHING_LIST.REMOVE,
            section
        });
    }

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR,
        });
    }

    clearFilter() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.FILTER.CLEAR
        });
    }

    findById(id) {
        let homescreen;

        let actionHistoryQuery = {
            'action-object': ActionHistoryConstants.ACTION_OBJECTS.HOMESCREEN,
            'object-id': id,
            offset: 0,
            size: 4
        };

        const preloaderSource = 'homescreen-actions.findById';
        PreloaderActions.show(preloaderSource);

        Promise.all([
            Request.get(`app/home-screen/${id}`).exec(),
            Request.get('system/action-history').query(actionHistoryQuery).exec(),
            Request.get(`app/home-screen/${id}/user-group`).exec(),
            Request.get(`app/home-screen/${id}/partner`).exec().catch(err => {
                switch (err.status) {
                case 404:
                    // Catch the error.
                    // But fix the promise by returning a valid value that will not break the logic:
                    return {body: []};
                }
            }),
            Request.get(`app/home-screen/${id}/publish-list`).exec()
        ]).spread((homescreenRes, historyRes, groupsRes, partnersRes, publishingListsRes) => {
            homescreen = homescreenRes.body;
            homescreen.history = historyRes.body.results;
            homescreen.history.sort((h1, h2) => h2.actionDate.localeCompare(h1.actionDate));
            homescreen.partners = partnersRes.body;
            homescreen.publishingLists = publishingListsRes.body;
            return Promise.all(groupsRes.body.map(
                g => Request.get(`security/group/${g.userGroupId}`).exec()
            ));
        }).then(groupsRes => {
            homescreen.groups = groupsRes.map(res => res.body);

            return Promise.all(homescreen.partners.map(
                p => Request.get(`partner/${p.partnerId}`).exec().catch(err => {
                    switch (err.status) {
                    case 404:
                        // Catch the error.
                        NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-partner.error');
                        // But fix the promise by returning a valid value that will not break the logic:
                        return {body: null};
                    }
                })
            ));
        }).then(partnersRes => {
            homescreen.partners = partnersRes.map(res => res.body).filter(partner => !!partner);

            return Promise.all(homescreen.publishingLists.map(
                pl => Request.get(`web/publish-list/${pl.publishListId}`).exec()
            ));
        }).then(publishingListRes => {
            homescreen.publishingLists = homescreen.publishingLists.map( (pl, i) => {
                pl.publishingList = publishingListRes[i].body;
                pl.publishListDisplayName = pl.publishListDisplayName || pl.publishingList.displayName || pl.publishingList.description;
                pl.publishListDisplayName = pl.publishListDisplayName.replace(/<\/?(div|h\d|i|p|strong)>/g, '');
                return pl;
            }).sort((a, b) => a.displayOrder - b.displayOrder);
            // Separate BILLBOARD
            if (homescreen.publishingLists.length > 0) {
                homescreen.billboard = homescreen.publishingLists.shift();
                this.getItems(homescreen.billboard.publishListId, 'billboard');
            }
            homescreen.publishingLists.forEach( (pl, i) => {
                this.getItems(pl.publishListId, `publishingLists.${i}`);
            });

            homescreen = Immutable.fromJS(homescreen);
            homescreen = homescreen.update('groups', g => g.toOrderedSet().sortBy(g2 => g2.get('name')));
            Dispatcher.dispatch({
                actionType: CONSTANTS.FIND_BY_ID.SUCCESS,
                homescreen
            });
            PreloaderActions.hide(preloaderSource);
        }).catch(err => {
            PreloaderActions.hide(preloaderSource);
            switch (err.status) {
            case 404:
                RouterActions.notFound();
                break;
            default:
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
                break;
            }
            throw err;
        });
        return;
    }

    remove(id) {
        const preloaderSource = 'homescreen-actions.remove';
        PreloaderActions.show(preloaderSource);

        Request.del(`app/home-screen/${id}`)
            .exec()
            .then(() => {
                PreloaderActions.hide(preloaderSource);
                Dispatcher.dispatch({
                    actionType: CONSTANTS.REMOVE.SUCCESS
                });

                RouterActions.redirect('/homescreen');
                NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'homescreens.delete.success');
                return;
            })
            .catch(err => {
                PreloaderActions.hide(preloaderSource);
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'homescreens.delete.error');
                throw err;
            });
    }

    get(queryParams) {
        queryParams = queryParams.toJS();
        queryParams.active = CONSTANTS.FILTERS.ACTIVE_OPTIONS[queryParams.active || CONSTANTS.FILTERS.ACTIVE_OPTIONS.ACTIVE.id].id;
        queryParams.offset = queryParams.offset || 0;
        queryParams.size = queryParams.size || 99999;

        const req = Request.get('app/home-screen').query(queryParams);

        const preloaderSource = 'homescreen-actions.get';
        PreloaderActions.show(preloaderSource);
        req.exec()
            .then(res => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET.SUCCESS,
                    homescreens: Immutable.fromJS(res.body.results).sortBy(r => r.get('name'))
                });
                return;
            }).then(()=>{
                PreloaderActions.hide(preloaderSource);
                return;
            })
            .catch((err) => {
                PreloaderActions.hide(preloaderSource);
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
                throw err;
            });

        return;
    }

    getItems(id, section) {
        let items;
        Request.get(`web/publish-list/${id}/items`)
            .exec()
            .then(res => {
                let reqs=[];
                items = res;
                res.body.forEach(item => {
                    switch (true) {
                    case (item.targetType === 1):
                        reqs.push(Request.get(`title/${item.assetId}`).exec());
                        break;
                    case (item.targetType === 2):
                        let assetType = item.displayAssetTypeName.toLowerCase();
                        reqs.push(Request.get(`asset/${assetType}/${item.assetId}`).exec());
                        break;
                    }
                });
                return Promise.all(reqs);
            })
            .then(resps => {
                let i = 0;
                items.body.forEach(item => {
                    switch (true) {
                    case (item.targetType === 1):
                        item.name = resps[i].body.name;
                        i++;
                        break;
                    case (item.targetType === 2):
                        item.name = resps[i].body.assetName;
                        i++;
                        break;
                    }

                });
                Dispatcher.dispatch({
                    actionType: CONSTANTS.ITEMS.GET.SUCCESS,
                    items: Immutable.fromJS(items.body).sortBy(r => r.get('displayOrder')),
                    section
                });
            })
            .catch(err => {
                switch (err.status) {
                case 404:
                    NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'homescreens.list-items.does-not-have-access', id);
                    break;
                default:
                    NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'homescreens.list-items.does-not-load', id);
                    break;
                }
                throw err;
            });
        return;
    }

    getUserGroups(id) {
        Request.get(`app/home-screen/${id}/user-group`)
            .exec()
            .then(res => {
                return Promise.all(res.body.map(
                    g => Request.get(`security/group/${g.userGroupId}`).exec()
                ));
            }).then(groupsRes => {
                let groups = groupsRes.map(res => res.body);
                Dispatcher.dispatch({
                    actionType: CONSTANTS.USER_GROUPS.GET.SUCCESS,
                    homeScreenId: id,
                    groups: Immutable.fromJS(groups)
                });
            })
            .catch(err => {
                throw err;
            });
    }

    save(homescreen, originalHomescreen) {
        let dataHomescreen = homescreen.toJS();
        // // Remove extra attributes.
        ['billboard', 'groups', 'history', 'partners', 'publishingLists'].forEach(p => delete dataHomescreen[p]);

        const activeValue = dataHomescreen.active;
        dataHomescreen.active = 0;
        if (activeValue) {
            dataHomescreen.active = 1;
        }

        // Assume POST.
        let method = Request.post;
        let uri = 'app/home-screen';
        // Check if PUT.
        let id = dataHomescreen.id;
        if (id !== undefined) {
            method = Request.put;
            uri = `app/home-screen/${id}`;
        }

        let defaults = {
            messages: {
                error: 'homescreens.save.error',
                success: 'homescreens.save.success'
            }
        };

        const preloaderSource = 'homepage-actions.save';
        PreloaderActions.show(preloaderSource);

        method(uri)
            .send(dataHomescreen)
            .exec()
            .then(res => {
                id = res.body.id;
                let requests = [];
                if (homescreen.get('groups') !== originalHomescreen.get('groups')) {
                    // Add/Remove groups by sending a PUT with the list of ids.
                    let groups = homescreen.get('groups')
                        .toJS()
                        .map(g => g.id);
                    requests.push(
                        Request.put(`app/home-screen/${id}/user-group`)
                            .send(groups)
                            .exec()
                    );
                }
                if (homescreen.get('partners') !== originalHomescreen.get('partners')) {
                    // Add/Remove partners by sending a PUT with the list of ids.
                    let partners = homescreen.get('partners')
                        .toJS()
                        .map(g => g.id);
                    requests.push(
                        Request.put(`app/home-screen/${id}/partner`)
                            .send(partners)
                            .exec()
                    );
                }
                if (homescreen.get('billboard') !== originalHomescreen.get('billboard') ||
                    homescreen.get('publishingLists') !== originalHomescreen.get('publishingList')) {
                    // Add/Remove publishingLists
                    let pls = [];
                    if (homescreen.get('billboard')) {
                        pls.push(homescreen.get('billboard').toJS());
                    }
                    pls = pls.concat(homescreen.get('publishingLists').toJS())
                        .map((p, i) => {
                            return {
                                homeScreenId: id,
                                id: p.id,
                                displayOrder: i + 1,
                                publishListId: p.publishListId
                            };
                        });
                    requests.push(
                        Request.put(`app/home-screen/${id}/publish-list`)
                            .send(pls)
                            .exec()
                    );
                }
                return Promise.all(requests);
            }).then(() => {
                PreloaderActions.hide(preloaderSource);
                Dispatcher.dispatch({
                    actionType: CONSTANTS.SAVE.SUCCESS
                });

                RouterActions.redirect(`/homescreen/${id}`, true);
                NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, defaults.messages.success);
                return;
            })
            .catch(err => {
                PreloaderActions.hide(preloaderSource);
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, defaults.messages.error);
                throw err;
            });
        return;
    }

    setFilter(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.FILTER.SET,
            attr,
            value
        });
    }

    update(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.UPDATE,
            attr: attr,
            value: value
        });

        return;
    }
}

const actions = new HomescreenActions();

export {
    actions as HomescreenActions,
    CONSTANTS as HomescreenConstants
};
