/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {Link} from 'react-router';

import {PermissionActions} from '../../security/permission/permission-actions';
import PermissionStore from '../../security/permission/permission-store';
import {RoleActions} from '../../security/role/role-actions';
import RoleStore from '../../security/role/role-store';

const PermissionCheckbox = Container.create(class extends Component {
    static get propTypes() {
        return {
            audit: PropTypes.bool,
            disabled: PropTypes.bool,
            permissionId: PropTypes.number.isRequired,
            permissionsData: PropTypes.object.isRequired,
            requiresFuncPermissionId: PropTypes.object.isRequired,
            title: PropTypes.string.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            audit: false,
            disabled: true,
            permissionData: undefined,
        };
    }

    static calculateState() {
        return {
            roles: PermissionStore.getState().get('roles'),
            permission: PermissionStore.getState().get('permission'),
            selectedPermission: RoleStore.getState().get('selectedPermission'),
        };
    }

    static getStores() {
        return [PermissionStore, RoleStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.getPermissionRoles = this.getPermissionRoles.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    getPermissionRoles() {
        if (!this.state.roles.get(this.props.permissionId)) {
            PermissionActions.getRoles(this.props.permissionId);
            PermissionActions.getPermission(this.props.permissionId);
        }
    }

    handleChange(event) {
        const checked = event.target.checked;
        if (checked) {
            // add
            RoleActions.addRolePermission([this.props.permissionId]);
            // add null & undefined handling
            if (this.props.requiresFuncPermissionId && this.props.requiresFuncPermissionId !== 0) {
                // has dependent permission in API
                RoleActions.addRolePermission([this.props.requiresFuncPermissionId]);
            }
        } else {
            // remove
            RoleActions.removeRolePermission([this.props.permissionId]);
        }
        return;
    }

    render() {
        let label = this.props.title;
        let roleNames;
        let checkbox;
        let permissionUri;

        if (this.props.audit) {
            label = (
                <Link
                    to={`permission/${this.props.permissionId}`}
                >
                    {label}
                </Link>
            );
        } else {
            checkbox = (
                <input
                    checked={this.state.selectedPermission.includes(this.props.permissionId)}
                    className="checkbox-option"
                    disabled={this.props.disabled}
                    onChange={this.handleChange}
                    type="checkbox"
                />
            );
        }

        if (this.props.permissionsData) {
            roleNames = [];
            permissionUri = '';
            this.props.permissionsData.filter(pd => {
                if (pd.get('id') === this.props.permissionId) {
                    roleNames.push(pd.get('associatedRoles'));
                    permissionUri = ' URI: '.concat(pd.get('uri'));
                }
            });
        } else {
            const roles = this.state.roles.get(this.props.permissionId);
            permissionUri = this.state.permission.get('uri');
            if (roles) {
                roleNames = roles.map((role) => {
                    return role.name;
                });
            }
        }

        let content = (
            <div onMouseEnter={this.getPermissionRoles}>
                <label>
                    {checkbox}
                    {label}
                </label>
                <br/>
            </div>
        );

        // Apparently react-bootstrap tooltips aren't meant to be updated, so we won't render one until we actually have something to show
        if (roleNames) {

            let tooltip = <span/>;
            if (roleNames) {
                tooltip = <Tooltip>{roleNames.join(', ').concat(permissionUri)}</Tooltip>;
            }

            content = (
                <OverlayTrigger placement="top" overlay={tooltip}>
                    {content}
                </OverlayTrigger>
            );
        }

        return content;
    }
});

class PermissionGroup extends Component {

    static get propTypes() {
        return {
            audit: PropTypes.bool,
            childGroup: PropTypes.bool,
            disabled: PropTypes.bool,
            permissions: PropTypes.object.isRequired,
            permissionsData: PropTypes.object.isRequired,
            title: PropTypes.string.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            audit: false,
            childGroup: false,
            disabled: true,
            permissionData: undefined,
        };
    }

    static calculateState() {
        return {
            selectedPermission: RoleStore.getState().get('selectedPermission')
        };
    }

    static getStores() {
        return [RoleStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.checked = this.checked.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }

    componentWillMount() {
        this.storeListeners = [
            RoleStore.addListener(() => {
                this.setState({
                    selectedPermission:RoleStore.getState().get('selectedPermission')
                });
                return;
            })
        ];
        return;
    }

    componentWillUnmount() {
        this.storeListeners.forEach(listener => listener.remove());
        return;
    }

    handleChange(event) {
        let ids = [];
        const checked = event.target.checked;

        this.props.permissions.forEach((v) => {

            if (v.get('mainFuncPermissionId') !== undefined) {
                ids.push(v.get('mainFuncPermissionId'));

                if (v.get('requiresFuncPermissionId') !== 0 && checked) {
                    // add API dependent permission
                    ids.push(v.get('requiresFuncPermissionId'));
                }

            } else {
                // is a permission group
                v.forEach((p) => {
                    ids.push(p.get('mainFuncPermissionId'));
                    if (p.get('requiresFuncPermissionId') !== 0 && checked) {
                        // add API dependent permission
                        ids.push(p.get('requiresFuncPermissionId'));
                    }
                });
            }

        });

        if (checked) {
            // add
            RoleActions.addRolePermission(ids);
        } else {
            // remove
            RoleActions.removeRolePermission(ids);
        }
        return;
    }

    checked() {
        let allChecked = true;

        this.props.permissions.forEach((v) => {
            if (v.get('mainFuncPermissionId') === undefined) {
                v.forEach((p) => {
                    if (!this.state.selectedPermission.includes(p.get('mainFuncPermissionId'))) {
                        allChecked = false;
                    }
                });
            } else if (!this.state.selectedPermission.includes(v.get('mainFuncPermissionId'))) {
                allChecked = false;
            }
        });
        return allChecked;
    }

    render() {
        let titleCheckbox;
        let checkboxes = [];
        let checkboxGroups = [];
        let childGroup = this.props.childGroup;

        if (!this.props.audit) {
            titleCheckbox = (
                <input type="checkbox"
                    className="checkbox-option"
                    checked={this.checked()}
                    disabled={this.props.disabled}
                    onChange={this.handleChange}
                />
            );
        }

        this.props.permissions.forEach((v, k) => {
            const permissionId = v.get('mainFuncPermissionId');
            if (permissionId === undefined) { // has children (not a leaf node)
                childGroup = true;
                checkboxGroups.push(<PermissionGroup audit={this.props.audit} disabled={this.props.disabled} key={k} title={k} permissions={v} childGroup={childGroup} permissionsData={this.props.permissionsData}/>);
            } else {
                const requiresFuncPermissionId = v.get('requiresFuncPermissionId');
                checkboxes.push(<PermissionCheckbox audit={this.props.audit} disabled={this.props.disabled} key={k} title={k} permissionId={permissionId} requiresFuncPermissionId={requiresFuncPermissionId} permissionsData={this.props.permissionsData}/>);
            }
            return true;
        });

        return (
            <div className={ClassNames('col-md-3 margin-bottom-20', {'child-group': this.props.childGroup})}>
                <label className="checkbox-header">
                    {titleCheckbox}
                    &nbsp;
                    {this.props.title}
                </label>
                <div className="gray-wrapper checkbox">
                    {checkboxGroups}
                    {checkboxes}
                </div>
            </div>
        );
    }

}

class PermissionPanel extends Component {

    static get propTypes() {
        return {
            audit: PropTypes.bool,
            disabled: PropTypes.bool,
            expandAll: PropTypes.bool,
            permissions: PropTypes.object.isRequired,
            permissionsData: PropTypes.object.isRequired,
            title: PropTypes.string.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            audit: false,
            disabled: true,
            permissionData: undefined,
            expandAll: false,
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            expanded: false
        };

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event) {
        event.preventDefault();
        this.setState(prevState => ({expanded : !prevState.expanded}));
        return;
    }

    render() {
        let checkboxGroups = [];
        this.props.permissions.forEach((v, k) => {
            let id = v.get('mainFuncPermissionId');
            if (id === undefined) { // has children (not a leaf node)
                checkboxGroups.push( <PermissionGroup audit={this.props.audit} disabled={this.props.disabled} key={k} title={k} permissions={v} permissionsData={this.props.permissionsData}/> );
            } else {
                checkboxGroups.push( <div className="col-md-3 margin-bottom-20">
                    <div className="gray-wrapper checkbox">
                        <PermissionCheckbox audit={this.props.audit} disabled={this.props.disabled} key={k} title={k} permissionId={id} permissionsData={this.props.permissionsData}/>
                    </div>
                </div>);
            }
            return true;
        });
        let row4 = [];
        for (let i=0; i<checkboxGroups.length; i+=4) {
            let cbg1 = '';
            if (checkboxGroups[i]) {cbg1 = checkboxGroups[i];}
            let cbg2 = '';
            if (checkboxGroups[i+1]) {cbg2 = checkboxGroups[i+1];}
            let cbg3 = '';
            if (checkboxGroups[i+2]) {cbg3 = checkboxGroups[i+2];}
            let cbg4 = '';
            if (checkboxGroups[i+3]) {cbg4 = checkboxGroups[i+3];}

            row4.push(<div className="row" key={i}>
                {cbg1}
                {cbg2}
                {cbg3}
                {cbg4}
            </div>);
        }
        let children = '';
        if (this.props.expandAll || this.state.expanded) {
            children = <div className="panel-collapse padding-x-20 padding-y-20 collapse in" aria-expanded="true">{row4}</div>;
        }
        return (
            <div className="panel panel-default margin-top-20">
                <div className="panel-heading bg-navy clickable" onClick={this.handleClick}>
                    <div className="panel-title">
                        <h4 data-toggle="collapse" className="clickable">
                            <i className="fas fa-plus"></i>&nbsp;{this.props.title}
                        </h4>
                    </div>
                </div>
                {children}
            </div>
        );
    }
}

class Permissions extends Component {

    static get propTypes() {
        return {
            APIPermissions: PropTypes.object.isRequired,
            CMSPermissions: PropTypes.object.isRequired,
            WBTVDPermissions: PropTypes.object.isRequired,
            FYCPermissions: PropTypes.object.isRequired,
            audit: PropTypes.bool,
            disabled: PropTypes.bool,
            permissionsData: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            audit: false,
            disabled: true,
            permissionData: undefined,
            expandAll: false,
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            expandAll: false
        };

        this.toggleExpand = this.toggleExpand.bind(this);
    }

    toggleExpand() {
        this.setState(prevState => ({expandAll: !prevState.expandAll}));
    }

    render() {
        const permissionPanel = (v, k) => {
            return <PermissionPanel
                audit={this.props.audit}
                disabled={this.props.disabled}
                expandAll={this.state.expandAll}
                key={k}
                title={k}
                permissions={v}
                permissionsData={this.props.permissionsData}
            />;
        };

        let wbitv = [];
        this.props.APIPermissions.forEach((v, k) => {
            wbitv.push(permissionPanel(v, k));
            return true;
        });

        let cms = [];
        this.props.CMSPermissions.forEach((v, k) => {
            cms.push(permissionPanel(v, k));
            return true;
        });

        let wbtvd = [];
        this.props.WBTVDPermissions.forEach((v, k) => {
            wbtvd.push(permissionPanel(v, k));
            return true;
        });

        let fyc = [];
        this.props.FYCPermissions.forEach((v, k) => {
            fyc.push(permissionPanel(v, k));
            return true;
        });

        let caption = <span><i className="fas fa-plus"/> {this.context.intl.messages['common.expand-all']}</span>;
        if (this.state.expandAll) {
            caption = <span><i className="fas fa-minus"/> {this.context.intl.messages['common.collapse-all']}</span>;
        }

        return (

            <div className="tab-pane padding-x-20">
                <div className="pull-right"><Button bsSize="sm" className="btn btn-navy-outline" onClick={this.toggleExpand}>{caption}</Button></div>

                <h3>
                    <i className="fas fa-wrench"/>&nbsp;{this.context.intl.messages['permissions.api-functions']}
                </h3>
                <hr />
                {wbitv}
                <h3>
                    <i className="fas fa-wrench"/>&nbsp;{this.context.intl.messages['permissions.brainiac-cms-functions']}
                </h3>
                <hr />
                {cms}
                <h3>
                    <i className="fas fa-wrench"/>&nbsp;{this.context.intl.messages['permissions.wbtvd-functions']}
                </h3>
                <hr />
                {wbtvd}
                <h3>
                    <i className="fas fa-wrench"/>&nbsp;{this.context.intl.messages['permissions.fyc-functions']}
                </h3>
                <hr />
                {fyc}
            </div>
        );
    }
}


export default Permissions;

export {
    PermissionCheckbox,
    PermissionGroup,
    PermissionPanel
};
