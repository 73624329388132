/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React from 'react';
import {Button} from 'react-bootstrap';

import {AssetActions, AssetConstants} from '../../assets/asset-actions';
import AssetStore from '../../assets/asset-store';
import ListAssets from '../../assets/browse/list-assets';
import FilterOptions from '../../assets/filter-options/filter-options';
import DocumentTitle from '../../common/document-title';
import SearchBox from '../../common/search-box/search-box';
import {WithStoreOnRoute} from '../../common/store-on-route';
import {Debounce} from '../../common/utils/utils';
import {LayoutActions} from '../../layout/layout-actions';
import LayoutStore from '../../layout/layout-store';
import Preloader from '../../preloader';
import PreloaderStore from '../../preloader/preloader-store';
import SessionStore from '../../user/session/session-store';

class BrowseProxies extends React.Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static getStores() {
        return [AssetStore, LayoutStore, PreloaderStore];
    }

    static calculateState() {
        return {
            assets: AssetStore.getState().get('assets'),
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible'),
            showFiltersPanel: LayoutStore.getState().get('showFiltersPanel'),
            total: AssetStore.getState().get('total'),
            filters: AssetStore.getState().get('filters')
        };
    }

    static getPermissions() {
        return {
            canReadProxies: SessionStore.canUser(SessionStore.PERMISSIONS.HARDAC.PROXY.READ)
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
        this.handleToggleFiltersPanel = this.handleToggleFiltersPanel.bind(this);

        return;
    }

    componentDidMount() {
        AssetActions.clear();
        const q = this.props.location.query;
        if (q['active-type'] && q['video-source-content-type'] && q.operator) {
            AssetActions.get(this.getRouteState());
            return;
        }
        this.setRouteState('active-type', 'ACTIVE')
            .setRouteState('video-source-content-type', AssetConstants.SOURCE_CONTENT_TYPE.PROXY.id)
            .setRouteState('operator', 'AND')
            .apply();
        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.location.query !== this.props.location.query ||
            nextState.showFiltersPanel !== this.state.showFiltersPanel ||
            nextState.total !== this.state.total ||
            nextState.proxies !== this.state.proxies ||
            nextState.preloaderVisible !== this.state.preloaderVisible) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            AssetActions.get(this.getRouteState(this.props));
        }
        return;
    }

    handleLoadPage(pageNumber) {
        // pageNumber x this.state.size = offset.
        this.setRouteState('offset', pageNumber * 20)
            .apply();
        return;
    }

    handleSearchTerm(term) {
        this.setRouteState('asset-name', term)
            .setRouteState('operator', 'AND')
            .clearRouteState('offset')
            .apply();
        return;
    }

    handleSortChange(sortFieldName, sortDirection) {
        this.setRouteState('sort-field-name', sortFieldName)
            .setRouteState('sort-direction', sortDirection)
            .apply();
        return;
    }

    handleToggleFiltersPanel() {
        LayoutActions.toggleFiltersPanel();
        return;
    }

    render() {
        const columnDefs = [
            {
                // Add the control class to the last column. This colum will
                // contain the button to show the responsive data.
                className: 'control',
                targets: -1,
                width: 20
            },
            {
                targets: [0], // assetThumbnail
                className: 'text-center',
                width: 30
            }, {
                targets: [1], // assetTypeName
            }, {
                targets: [2], // createdDate
            }, {
                targets: [3], // catalogs
                width: 19
            }
        ];

        return (
            <DocumentTitle message="document-titles.hardac.proxies">
                <div className={ClassNames({'control-sidebar-open': this.state.showFiltersPanel})}>
                    <div className={ClassNames({'sidebar-opened': this.state.showFiltersPanel})}>
                        <Preloader show={this.state.preloaderVisible} fixed loadingDots>
                            <section className="content-header">
                                <h1>
                                    <i className="fas fa-tv-retro"></i>&nbsp;{this.context.intl.messages['hardac.proxies.browse.title']}
                                    &nbsp;<small>{this.state.total}</small>
                                </h1>
                            </section>
                            <section className="content">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="container-fluid no-x-padding">
                                            <div className="row">
                                                <div className="col-md-6 show-grid">
                                                    <SearchBox onChange={this.handleSearchTerm} value={this.getRouteState().get('asset-name')}/>
                                                </div>
                                                <div className="col-md-6 show-grid">
                                                    <Button onClick={this.handleToggleFiltersPanel} bsStyle="primary" bsSize="large" className="pull-right">
                                                        <i className="fas fa-sliders-h"></i>&nbsp;{this.context.intl.messages['common.filteringOptions']}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container-fluid no-x-padding">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="box">
                                                        <div className="box-body">
                                                            <ListAssets
                                                                activePage={Math.ceil((this.getRouteState().get('offset') || 0) / 20 ) || 0}
                                                                assets={this.state.assets}
                                                                columns={['hardacProxyAssetThumbnail', 'hardacProxyAssetName', 'createdDate', 'catalogs']}
                                                                columnDefs={columnDefs}
                                                                displayProxyLinks={this.props.permissions.canReadProxies}
                                                                onPageChange={this.handleLoadPage}
                                                                onSortChange={this.handleSortChange}
                                                                sortDirection={this.getRouteState().get('sort-direction') || 'desc'}
                                                                sortFieldName={this.getRouteState().get('sort-field-name') || 'assetname'}
                                                                totalPages={Math.ceil(this.state.total / 20) || 0}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <FilterOptions
                                location={this.props.location}
                                showAssetType={false}
                                filters={this.state.filters}
                            />
                        </Preloader>
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}

export {BrowseProxies};

export default WithStoreOnRoute(
    Container.create(BrowseProxies)
);
