/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import createReactClass from 'create-react-class';
import {ReduceStore} from 'flux/utils';
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';

export default createReactClass({
    propTypes: {
        classNamePrefix: PropTypes.string,
        components: PropTypes.object,
        disabled: PropTypes.bool,
        filterOptions: PropTypes.func,
        getter: PropTypes.func,
        groupBy: PropTypes.string,
        isClearable: PropTypes.bool,
        labelKey: PropTypes.string,
        multi: PropTypes.bool,
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        onInputChange: PropTypes.func,
        options: PropTypes.string,
        placeholder: PropTypes.string,
        store: PropTypes.instanceOf(ReduceStore).isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        valueKey: PropTypes.string
    },

    contextTypes: {
        intl: PropTypes.object.isRequired
    },

    getDefaultProps: function() {
        return {
            classNamePrefix: undefined,
            components: {},
            disabled: true,
            filterOptions: (o) => o,
            getter: () => void 0,
            groupBy: null,
            isClearable: true,
            labelKey: 'name',
            multi: false,
            onChange: undefined,
            onInputChange: undefined,
            options: 'items',
            placeholder: undefined,
            value: undefined,
            valueKey: 'id'
        };
    },

    getInitialState: function() {
        return {
            options: this.props.store.getState().getIn(this.props.options.split('.'))
        };
    },

    /**
     * Register to the store that was passed as prop.
     */
    componentWillMount: function() {
        this.storeListeners = [
            this.props.store.addListener(() => {
                /**
                 * Read the options from the options prop. Use the split('.')
                 * to allow reading from nested objects like 'user.groups'.
                 */
                this.setState({
                    options: this.props.store.getState().getIn(this.props.options.split('.'))
                });
                return;
            })
        ];
        return;
    },

    /**
     * Always call the getter after mount.
     */
    componentDidMount: function() {
        this.props.getter();
        return;
    },

    shouldComponentUpdate: function(nextProps, nextState) {
        if (nextProps.disabled !== this.props.disabled ||
            nextProps.filterOptions !== this.props.filterOptions ||
            nextProps.labelKey !== this.props.labelKey ||
            nextProps.multi !== this.props.multi ||
            nextProps.name !== this.props.name ||
            nextProps.onChange !== this.props.onChange ||
            nextProps.options !== this.props.options ||
            nextProps.store !== this.props.store ||
            nextProps.value !== this.props.value ||
            nextProps.valueKey !== this.props.valueKey) {
            return true;
        }

        if (nextState.options !== this.state.options) {
            return true;
        }

        return false;
    },

    componentWillUnmount: function() {
        this.storeListeners.forEach(listener => listener.remove());
        return;
    },

    groupOptions: function(options) {
        let groupByOptions = [];

        options.forEach(option => {
            if (option[this.props.groupBy]) {
                option.disabled = true;
                const group = Object.keys(option[this.props.groupBy]).map(k => {
                    let o = option[this.props.groupBy][k];
                    o.name = `---- ${o.name}`;
                    return o;
                }).sort((a, b) => a.name.localeCompare(b.name));
                groupByOptions.push(option, ...group);
            } else {
                groupByOptions.push(option);
            }
        });
        return groupByOptions;
    },

    render: function() {
        let options;
        if (this.state.options) {
            options = this.props.filterOptions(this.state.options).toJS();
            if (this.props.groupBy) {
                options = this.groupOptions(options);
            }
        }

        // This is done here because this.context isn't available during
        // getDefaultProps().
        let placeholder = this.context.intl.messages['common.please-select'];
        if (this.props.placeholder) {
            placeholder = this.props.placeholder;
        }

        let value = '';
        if (this.props.value) {
            if (this.props.value.toJS) {
                value = this.props.value.toJS();
            } else {
                value = this.props.value;
            }
        }

        return (
            <Select
                classNamePrefix={this.props.classNamePrefix}
                components={this.props.components}
                getOptionLabel={data => data[this.props.labelKey]}
                getOptionValue={data => data[this.props.valueKey]}
                isClearable={this.props.isClearable}
                isMulti={this.props.multi}
                isDisabled={this.props.disabled}
                name={this.props.name}
                onChange={this.props.onChange}
                onInputChange={this.props.onInputChange}
                options={options}
                placeholder={placeholder}
                value={value}
                isOptionDisabled={(option) => option.disabled === true}
            />
        );
    }
});
