/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';

import {AlertTypes} from '../../common/notification/alert';
import {NotificationActions} from '../../common/notification/notification-actions';
import {SlidingPanelActions} from '../../common/sliding-panel/sliding-panel-actions';
import Dispatcher from '../../dispatcher/dispatcher';
import {PreloaderActions} from '../../preloader/preloader-actions';
import Request from '../../request';
import {RouterActions} from '../../router/router-actions';
import {ActionHistoryConstants} from '../../system/action-history/action-history-actions';

const CONSTANTS = {
    CLEAR: 'security.client.rep.group.clear',
    CREATE: {
        ERROR: 'security.client.rep.group.create.error',
        SUCCESS: 'security.client.rep.group.create.success'
    },
    GET: {
        ERROR: 'security.client.rep.group.error',
        START: 'security.client.rep.group.start',
        SUCCESS: 'security.client.rep.group.success',
        USERS: {
            START: 'security.client.rep.group.users.start',
            SUCCESS: 'security.client.rep.group.users.success',
            ERROR: 'security.client.rep.group.users.error'
        }
    },
    LOAD: 'security.client.rep.group.load',
    REMOVE: {
        ERROR: 'security.client.rep.group.remove.error',
        SUCCESS: 'security.client.rep.group.remove.success'
    },
    SAVE: {
        SUCCESS: 'security.client.rep.group.save.success'
    },
    SORT: {
        SET: 'user_actions.sort.set'
    },
    UPDATE: 'security.client.rep.group.update',
    USERS: {
        TOGGLE_SELECT: 'security.client.rep.group.select',
        TOGGLE_SELECT_ALL: 'security.client.rep.group.select.all'
    }
};

class ClientRepGroupActions {

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
        return;
    }

    deleteClientRepGroup(clientRepGroup) {
        // Remove the history field, since it contains json and trips a cloudflare error
        const data = clientRepGroup.delete('history').toJS();

        Request.del(`security/client-rep-group/${data.id}`)
            .send(data)
            .exec()
            .then(() => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.REMOVE.SUCCESS,
                });

                RouterActions.redirect('/groups/client-rep-groups');
                NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'groups.client-rep-group.delete.success');
                return;
            })
            .catch(() => {
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'groups.client-rep-group.delete.error');
                return;
            });

        return;
    }

    findById(id) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.GET.START,
            id: id
        });
        let actionHistoryQuery = {
            'action-object': ActionHistoryConstants.ACTION_OBJECTS.CLIENTREPGROUP,
            'object-id': id,
            offset: 0,
            size: 4
        };

        Promise.all([
            Request.get(`security/client-rep-group/${id}`).exec(),
            Request.get('system/action-history').query(actionHistoryQuery).exec()
        ])
            .spread((client, history) => {
                let clientRepGroup = client.body;
                clientRepGroup.history = history.body.results;
                clientRepGroup.history.sort((h1, h2) => h2.actionDate.localeCompare(h1.actionDate));

                clientRepGroup = Immutable.fromJS(clientRepGroup);
                clientRepGroup = clientRepGroup.merge({
                    territories: clientRepGroup.get('territories').toSet().sortBy(g => g.get('name'))
                });
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET.SUCCESS,
                    clientRepGroup: clientRepGroup
                });
                return;
            }).catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET.ERROR,
                    error: err
                });

                switch (err.status) {
                case 404:
                    RouterActions.notFound();
                    break;
                default:
                    NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
                    break;
                }

                return;
            });
        return;
    }

    get(options) {
        let defaults = {
            offset: 0,
            size: 20
        };
        // Extend the default options with the options descriptor.
        Object.assign(defaults, options);

        let req = Request.get('security/client-rep-group')
            .query({
                offset: defaults.offset,
                size: defaults.size
            });

        PreloaderActions.show('client-rep-group-actions.get');
        req.exec().then(res => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.LOAD,
                offset: 0,
                clientRepGroups: Immutable.fromJS(res.body).sortBy(r => r.get('name')),
                size: res.body.size,
                total: res.header['wbtv-total-count']
            });
            return;
        }).then(()=>{
            PreloaderActions.hide('client-rep-group-actions.get');
            return;
        }).catch(err => {
            PreloaderActions.hide('client-rep-group-actions.get');

            throw err;
        });

        return;
    }

    /**
     * Find all users that belong to a group.
     */
    findUsersByClientRepGroupId(clientRepGroupId, sortFieldName, sortDirection, offset, size) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.GET.USERS.START
        });
        PreloaderActions.show('client-rep-group-actions.findUsersByClientRepGroupId');
        Request.get(`security/client-rep-group/${clientRepGroupId}/user`).query({
            offset,
            size,
            'sort-field': sortFieldName,
            'sort-order': sortDirection
        }).exec().then(res => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.USERS.SUCCESS,
                offset: res.body.offset,
                size: res.body.size,
                total: res.body.totalCount,
                users: Immutable.fromJS(res.body)
            });
            PreloaderActions.hide('client-rep-group-actions.findUsersByClientRepGroupId');
        }).catch(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.USERS.ERROR
            });

            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
            PreloaderActions.hide('client-rep-group-actions.findUsersByClientRepGroupId');
            return;
        });
    }

    toggleSelect(index, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.USERS.TOGGLE_SELECT,
            index: index,
            value: value
        });

        return;
    }

    toggleSelectAll(value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.USERS.TOGGLE_SELECT_ALL,
            value: value
        });

        return;
    }

    addExistingUser(userId, clientRepGroupId, slidingPanelId) {
        Request.post(`user/${userId}/client-rep/${clientRepGroupId}`)
            .send()
            .exec()
            .then(() => {
                SlidingPanelActions.hide(slidingPanelId);
                this.findUsersByClientRepGroupId(clientRepGroupId);
                return;
            })
            .catch(err => {
                throw err;
            });
        return;
    }

    /**
     * Remove a list of users from a certain client rep group.
     */
    removeUsers(clientRepGroup, users) {
        // Send a DELETE request for each user in the users array.
        Promise.all(users.map(
            u => Request.del(`user/${u.get('id')}/client-rep/${clientRepGroup.get('id')}`).exec()
        ).toJS()).then(() => {
            NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'groups.user.remove-users.success');
            // Reload all users for the group.
            this.findUsersByClientRepGroupId(clientRepGroup.get('id'));
            return;
        });
        return;
    }

    saveClientRepGroup(clientRepGroup, options) {
        // Set the data to send to the server.
        let clientRepGroupJS = clientRepGroup.delete('history').toJS();
        clientRepGroupJS.territories = clientRepGroupJS.territories.map(r => {return {id: r.id};});

        // Assume POST.
        let method = Request.post;
        let uri = 'security/client-rep-group';

        // Check if PUT.
        let id = clientRepGroupJS.id;
        if (id !== undefined) {
            method = Request.put;
            uri = `security/client-rep-group/${id}`;
        }

        const preloaderSource = 'client-rep-group-actions.saveClientRepGroup';
        PreloaderActions.show(preloaderSource);

        method(uri)
            .send(clientRepGroupJS)
            .exec()
            .then(res => {
                PreloaderActions.hide(preloaderSource);
                if (id === undefined) {
                    // POST
                    id = res.body.id;
                    Dispatcher.dispatch({
                        actionType: CONSTANTS.CREATE.SUCCESS,
                    });
                    RouterActions.redirect(`/groups/client-rep-groups/${id}`, true);
                } else {
                    Dispatcher.dispatch({
                        actionType: CONSTANTS.SAVE.SUCCESS,
                        clientRepGroup: clientRepGroup
                    });
                }

                NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, options.messages.success);
                return;
            })
            .catch(err => {
                PreloaderActions.hide(preloaderSource);
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, options.messages.error);
                throw err;
            });

        return;
    }

    setSort(sortFieldName, sortDirection) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SORT.SET,
            sortFieldName: sortFieldName,
            sortDirection: sortDirection
        });
    }

    updateClientRepGroup(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.UPDATE,
            attr: attr,
            value: value
        });
        return;
    }

}

let actions = new ClientRepGroupActions();

export {
    actions as ClientRepGroupActions,
    CONSTANTS as ClientRepGroupConstants
};
