/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ControlLabel, FormControl, FormGroup} from 'react-bootstrap';

import BaseSelect from '../../../../common/base-select/base-select';
import {FormItem, FormRow, FormSection} from '../../../../common/form/form';
import TinyMCE from '../../../../common/tinymce/tinymce';
import {CompareImmutable} from '../../../../common/utils/utils';
import config from '../../../../config/config.js';
import {MerchandiseActions} from '../../merchandise-actions';
import MerchandiseStore, {MerchandiseAssetValidations} from '../../merchandise-store';

let componentProps = [
    'active', 'assetComment', 'name', 'price', 'sku', 'type',
    'description', 'updatedBy', 'updatedDate'
];


class Summary extends Component {

    static get propTypes() {
        return {
            asset: PropTypes.instanceOf(Immutable.Map).isRequired,
            disabled: PropTypes.bool,
            mode: PropTypes.string.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true
        };
    }

    constructor(props) {
        super(props);

        this.handleTinyMCEChange = this.handleTinyMCEChange.bind(this);
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.disabled !== this.props.disabled ||
            !CompareImmutable(nextProps.asset, this.props.asset, componentProps)) {
            return true;
        }
        return false;
    }

    handleTinyMCEChange(attr) {
        return function handler(e) {
            MerchandiseActions.update(attr, e.target.getContent());
            return;
        };
    }

    render() {
        let updatedDate = <div>N/A</div>;
        let updatedBy = <div>N/A</div>;
        let additionalInformation;
        if (this.props.mode === 'edit') {

            if (this.props.asset.get('updatedDate')) {
                updatedDate = Moment(this.props.asset.get('updatedDate')).tz(config.DefaultTimezone).format(this.context.intl.messages['datetime-format']);
            } else {
                updatedDate = '-';
            }

            if (this.props.asset.get('updatedBy')) {
                updatedBy = this.props.asset.get('updatedBy');
            } else {
                updatedBy = '-';
            }

            additionalInformation = <FormRow>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['asset.edit.last-modified-date']}</ControlLabel>
                    <FormControl.Static>{updatedDate}</FormControl.Static>
                </FormGroup>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['asset.edit.last-modifier']}</ControlLabel>
                    <FormControl.Static>{updatedBy}</FormControl.Static>
                </FormGroup>
            </FormRow>;
        }

        return (
            <div>
                <h3><i className="fas fa-info-circle"></i>{this.context.intl.messages['asset.info']}</h3>
                <FormSection>
                    <FormRow>
                        <FormItem
                            attr="name"
                            label={this.context.intl.messages['asset.merchandise.create.name']}
                            disabled={this.props.disabled}
                            model={this.props.asset}
                            required
                            setter={MerchandiseActions.update}
                            type="text"
                            validations={MerchandiseAssetValidations.name.validations}
                        />
                        <FormItem
                            attr="assetComment"
                            label={this.context.intl.messages['asset.merchandise.create.assetComment']}
                            disabled={this.props.disabled}
                            model={this.props.asset}
                            required
                            setter={MerchandiseActions.update}
                            type="text"
                            validations={MerchandiseAssetValidations.assetComment.validations}
                        />
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['asset.merchandise.create.contentType']}</ControlLabel>
                            <BaseSelect
                                name={this.context.intl.messages['asset.merchandise.create.contentType']}
                                options="type"
                                readonly
                                store={MerchandiseStore}
                                value={this.props.asset.get('contentType')}
                            />
                        </FormGroup>
                        <FormItem
                            attr="price"
                            label={this.context.intl.messages['asset.merchandise.create.price']}
                            disabled={this.props.disabled}
                            model={this.props.asset}
                            setter={MerchandiseActions.update}
                            type="text"
                            validations={MerchandiseAssetValidations.price.validations}
                        />
                    </FormRow>
                    <FormRow>
                        <FormItem attr="sku"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['asset.merchandise.create.sku']}
                            setter={MerchandiseActions.update}
                            model={this.props.asset}
                            type="text"
                            validations={MerchandiseAssetValidations.sku.validations}
                        />
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['common.active']}</ControlLabel>
                            <FormItem
                                attr="active"
                                disabled={this.props.disabled}
                                label={this.context.intl.messages['common.active']}
                                model={this.props.asset}
                                setter={MerchandiseActions.update}
                                type="bool-checkbox"
                            />
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['asset.merchandise.create.description']}</ControlLabel>
                            <TinyMCE
                                content={this.props.asset.get('description') || ''}
                                id="description-comment"
                                onChange={this.handleTinyMCEChange('description')}
                            />
                        </FormGroup>
                    </FormRow>
                    <hr />
                    {additionalInformation}
                </FormSection>
            </div>
        );
    }

}
export default Summary;
