/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {FormatTitleReleaseDate} from '@wbdt-sie/brainiac-web-common';
import {AgGridReact} from 'ag-grid-react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import Pagination from '../../common/table/pagination';
import {TitleActions, TitleConstants} from '../../titles/title-actions';

import 'ag-grid-community/dist/styles/ag-grid.css';
import '~/src/styles/ag-theme-brainiac.css';

export class ListTitlesAG extends Component {
    static get propTypes() {
        return {
            activePage: PropTypes.number.isRequired,
            columns: PropTypes.array,
            displayLinks: PropTypes.bool,
            handleSynopsisChange: PropTypes.func,
            linkTarget: PropTypes.string,
            onPageChange: PropTypes.func,
            onSelect: PropTypes.func,
            titles: PropTypes.instanceOf(Immutable.List).isRequired,
            toRemoveIds: PropTypes.array.isRequired,
            totalPages: PropTypes.number.isRequired,
        };
    }

    static get defaultProps() {
        return {
            columns: ['mpmNumber', 'title', 'titleReleaseDate', 'categoryName', 'catalogs'],
            displayLinks: false,
            handleSynopsisChange: () => void 0,
            linkTarget: '_self',
            onPageChange: () => void 0,
            onSelect: undefined,
            titles: Immutable.List()
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
            router: PropTypes.object.isRequired
        };
    }

    render() {
        const columnDefs = [
            {
                cellRendererFramework: RORenderer,
                cellStyle: {whiteSpace: 'pre-wrap'},
                checkboxSelection: true,
                field: 'ro',
                headerCheckboxSelection: true,
                headerName: this.context.intl.messages['titles.table.header.ro'],
                showDisabledCheckboxes: true,
                width: 140,
            },
            {
                autoHeight: true,
                cellRendererFramework: TitleAndParentRenderer,
                cellRendererParams: {linkTarget: this.props.linkTarget, displayLinks: this.props.displayLinks},
                field: 'TitleAndParentRenderer',
                headerName: this.context.intl.messages['titles.table.header.title-and-parent-title'],
                width: 400,
            },
            {
                cellStyle: {whiteSpace: 'pre-wrap'},
                field: 'categoryName',
                flex: 1,
                headerName: this.context.intl.messages['titles.table.header.category-name'],
            },
            {
                cellRendererFramework: SynopsisUsageTypeRenderer,
                cellRendererParams: {onChange: this.props.handleSynopsisChange},
                field: 'synopsisUsageType',
                flex: 1,
                headerName: this.context.intl.messages['titles.table.header.synopsis-usage-type'],
                width: 250,
            },
            {
                field: 'mpmNumber',
                flex: 1,
                headerName: this.context.intl.messages['titles.table.header.mpm-number'],
                width: 110,
            },
            {
                cellRendererFramework: ReleaseDateRenderer,
                field: 'titleReleaseDate',
                flex: 1,
                headerName: this.context.intl.messages['titles.table.header.title-release-date'],
                width: 140,
            },
        ];

        const filteredColumnDefs = columnDefs.filter(column => this.props.columns.includes(column.field));

        const rowData = this.props.titles.toJS();
        return <div style={{width: '100%', height: '100%'}} className="ag-theme-brainiac">
            <AgGridReact
                onGridReady={/* istanbul ignore next */(params) => {
                    const api = params.api;
                    api.sizeColumnsToFit();
                    window.addEventListener('resize', /* istanbul ignore next */ () => {
                        setTimeout(/* istanbul ignore next */() => {
                            api.sizeColumnsToFit();
                            api.resetRowHeights();
                        });
                    });
                }}
                onRowDataChanged={/* istanbul ignore next */(params) => {
                    const api = params.api;
                    api.forEachNode((node) => {
                        if (this.props.toRemoveIds.includes(node.data.id)) {
                            node.setSelected(true);
                        }
                    });
                }}
                cacheBlockSize={20}
                columnDefs={filteredColumnDefs}
                headerHeight={40}
                onRowSelected={this.props.onSelect}
                pagination={false}
                paginationPageSize={20}
                rowData={rowData}
                rowHeight={60}
                rowSelection="multiple"
                serverSidePagination
                suppressCellSelection
                suppressHorizontalScroll
                suppressRowClickSelection
            />
            <div className="row">
                <div className="col-sm-7 col-sm-offset-5">
                    <Pagination
                        activePage={this.props.activePage}
                        onChange={this.props.onPageChange}
                        totalPages={this.props.totalPages}
                    />
                </div>
            </div>
        </div>;
    }
}

/*Renderers START*/
class TitleAndParentRenderer extends Component {
    static get propTypes() {
        return {
            data: PropTypes.object.isRequired,
            displayLinks: PropTypes.bool.isRequired,
            linkTarget: PropTypes.string.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    render() {
        const content = this.props.data;
        let id = content.id;
        const style = {fontSize: '80%', whiteSpace: 'pre-wrap'};
        let titleName = <span style={{whiteSpace: 'pre-wrap'}}>{content.name}</span>;
        let parentTitle = content.displayName;
        switch (TitleActions.getCategoryGroup(content.category)) {
        case TitleConstants.TITLE_CATEGORY_GROUPS.EPISODE:
            let season = TitleActions.seasonFromFullRunningOrder(content.fullRunningOrder);
            parentTitle = `${content.parentTitleDisplayName || 'SERIE'} (S${season})`;
            id = content.parentTitleId;
            break;
        case TitleConstants.TITLE_CATEGORY_GROUPS.SEASON:
            parentTitle = content.parentTitleDisplayName || content.displayName;
            break;
        }
        if (this.props.displayLinks) {
            parentTitle = <a style={{whiteSpace: 'pre-wrap'}} href={`/titles/${id}`} className="edit-title" target={this.props.linkTarget}>{parentTitle}</a>;
        }
        switch (content.category) {
        case TitleConstants.TITLE_TYPES.ANIMATED_SERIES_SEASON.id:
        case TitleConstants.TITLE_TYPES.CARTOONS_SEASON.id:
        case TitleConstants.TITLE_TYPES.DOCUSERIES_SEASON.id:
        case TitleConstants.TITLE_TYPES.GAME_SHOW_SEASON.id:
        case TitleConstants.TITLE_TYPES.LIMITED_SERIES_SEASON.id:
        case TitleConstants.TITLE_TYPES.REALITY_SEASON.id:
        case TitleConstants.TITLE_TYPES.SEASON_HALF_HOUR.id:
        case TitleConstants.TITLE_TYPES.SEASON_ONE_HOUR.id:
        case TitleConstants.TITLE_TYPES.SHORT_PROGRAMS_SEASON.id:
        case TitleConstants.TITLE_TYPES.TALK_SHOW_SEASON.id:
            titleName = `${content.name} - Season ${content.season}`;
            break;
        case TitleConstants.TITLE_TYPES.EPISODE.id:
            titleName = `${content.name} - ${content.parentTitleDisplayName || ''} ${content.fullRunningOrder || ''}`;
            break;
        }
        if (this.props.displayLinks) {
            titleName = <a style={{whiteSpace: 'pre-wrap'}} href={`/titles/${content.id}`} className="edit-title" target={this.props.linkTarget}>{content.name}</a>;
        }
        return (
            <div>
                <span style={{whiteSpace: 'pre-wrap'}}>{titleName}</span>
                <small className="d-block" style={style}>
                    {this.context.intl.messages['titles.table.body.title.parent']}{parentTitle}
                </small>
            </div>
        );
    }
}

class ReleaseDateRenderer extends Component {
    static get propTypes() {
        return {
            data: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    render() {
        return <span style={{whiteSpace: 'pre-wrap'}}>{FormatTitleReleaseDate(Immutable.Map(this.props.data), this.context.intl.messages)}</span>;
    }
}

class RORenderer extends Component {
    static get propTypes() {
        return {
            data: PropTypes.object.isRequired
        };
    }

    render() {
        const content = this.props.data;
        const roStyle = {wordBreak: 'break-word', whiteSpace: 'pre-wrap'};
        switch (TitleActions.getCategoryGroup(content.category)) {
        case TitleConstants.TITLE_CATEGORY_GROUPS.EPISODE:
            let season = TitleActions.seasonFromFullRunningOrder(content.fullRunningOrder);
            return <small style={roStyle}>S{season} {content.runningOrder}</small>;
        case TitleConstants.TITLE_CATEGORY_GROUPS.SEASON:
            return <small style={roStyle}>S{content.season}</small>;
        case TitleConstants.TITLE_CATEGORY_GROUPS.SERIES:
            return <small style={roStyle}>SERIES</small>;
        case TitleConstants.TITLE_CATEGORY_GROUPS.SINGLE_RELEASE:
            return <small style={roStyle}>FEATURE</small>;
        }
        return null;
    }
}

class SynopsisUsageTypeRenderer extends Component {
    static get propTypes() {
        return {
            data: PropTypes.object.isRequired,
            onChange: PropTypes.func.isRequired,
        };
    }

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event) {
        this.props.onChange(event.target.value, this.props.data);
    }
    render() {
        const content = this.props.data;
        return <select className="form-control pull-right media-asset-type-select"
            onChange={this.handleClick}
            value={content.synopsisUsageType}
        >
            <option value={TitleConstants.SYNOPSIS_TYPES.DEFAULT.id}>{TitleConstants.SYNOPSIS_TYPES.DEFAULT.name.replace(' Synopsis', '')}</option>
            <option value={TitleConstants.SYNOPSIS_TYPES.TEMPORARY.id}>{TitleConstants.SYNOPSIS_TYPES.TEMPORARY.name.replace(' Synopsis', '')}</option>
            <option value={TitleConstants.SYNOPSIS_TYPES.AIRLINE.id}>{TitleConstants.SYNOPSIS_TYPES.AIRLINE.name.replace(' Synopsis', '')}</option>
            <option value={TitleConstants.SYNOPSIS_TYPES.SCREENER.id}>{TitleConstants.SYNOPSIS_TYPES.SCREENER.name.replace(' Synopsis', '')}</option>
            <option value={TitleConstants.SYNOPSIS_TYPES.AWARDS.id}>{TitleConstants.SYNOPSIS_TYPES.AWARDS.name.replace(' Synopsis', '')}</option>
        </select>

        ;
    }
}

/*Renderers END*/

export default ListTitlesAG;

export {
    ReleaseDateRenderer,
    RORenderer,
    SynopsisUsageTypeRenderer,
    TitleAndParentRenderer
};
