/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';
import {IndexRoute, Route} from 'react-router';

import Browse from './browse';
import Create from './create/create';
import Summary from './create/summary';
import Users from './create/users';
import {RequirePerm} from '../router/router-helpers';
import SessionStore from '../user/session/session-store';

export default (
    <Route path="permission">
        <IndexRoute
            component={Browse}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.ACCOUNTS.PERMISSIONS.READ)}
        />
        <Route
            component={Create}
            mode="edit"
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.ACCOUNTS.PERMISSIONS.READ)}
            path=":id"
        >
            <IndexRoute component={Summary}/>
            <Route path="users" component={Users}/>
        </Route>
    </Route>
);
