/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, Modal} from 'react-bootstrap';

import {FormItem, FormRow} from '../../common/form/form';
import Validations from '../../common/validations/validations';
import {AudioProfileConstants} from '../audio-profile-table/audio-profile-actions';
import AudioProfileStore from '../audio-profile-table/audio-profile-store';

class ApproveVideoDeliveryModal extends Component {
    static get propTypes() {
        return {
            // The following two props are used in a method, but the linter
            // is not smart enough to notice it.
            audioProfile: PropTypes.instanceOf(Immutable.List).isRequired, // eslint-disable-line react/no-unused-prop-types
            audioProfilePresetId: PropTypes.number.isRequired, // eslint-disable-line react/no-unused-prop-types
            onApproveVideoDelivery: PropTypes.func.isRequired,
            onToggleModal: PropTypes.func.isRequired,
            show: PropTypes.bool.isRequired,
            viewingMode: PropTypes.string.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            proxyChannels: this.getProxy(this.props)
        };

        this.handleHide = this.handleHide.bind(this);
        this.handleApproveVideoDelivery = this.handleApproveVideoDelivery.bind(this);
        this.handleUpdateProxy = this.handleUpdateProxy.bind(this);
        this.isValid = this.isValid.bind(this);

        return;
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.show === true && this.props.show === false) {
            this.setState(() => ({
                proxyChannels: this.getProxy(nextProps)
            }));
        }
    }

    getChannelByType(audioProfile, channelType) {
        if (!audioProfile) {
            return null;
        }

        return audioProfile.find(c => c.get('type') === channelType);
    }

    getProxy({audioProfile, audioProfilePresetId}) {
        const audioPreset = AudioProfileStore.getPreset(audioProfilePresetId);
        const proxyChannels = {};

        if (audioPreset?.get('id') === AudioProfileConstants.AUDIO_PROFILE.CUSTOM_PROFILE_ID) {
            // Only calculate proxy L/R strings for the custom profile.
            // See BRAIN-2056 and BRAIN-2513 for business rule logic

            const stereoMixL = this.getChannelByType(audioProfile, AudioProfileConstants.CHANNELS.STEREO_MIX_L);
            const stereoMixR = this.getChannelByType(audioProfile, AudioProfileConstants.CHANNELS.STEREO_MIX_R);
            if (stereoMixL && stereoMixR) {
                return Immutable.fromJS({
                    left: stereoMixL.get('audioChannel'),
                    right: stereoMixR.get('audioChannel'),
                });
            }

            let proxyLeft = [];
            let proxyRight = [];

            const getProxyString = (p) => `${p.get('audioChannel')}(0.707)`;

            const leftFront51 = this.getChannelByType(audioProfile, AudioProfileConstants.CHANNELS.LEFT_FRONT_5_1);
            const leftSurround51 = this.getChannelByType(audioProfile, AudioProfileConstants.CHANNELS.LS_LEFT_SURROUND_5_1);
            const center51 = this.getChannelByType(audioProfile, AudioProfileConstants.CHANNELS.CENTER_5_1);
            const rightFront51 = this.getChannelByType(audioProfile, AudioProfileConstants.CHANNELS.RIGHT_FRONT_5_1);
            const rightSurround51 = this.getChannelByType(audioProfile, AudioProfileConstants.CHANNELS.RS_RIGHT_SURROUND_5_1);
            const subLFE51 = this.getChannelByType(audioProfile, AudioProfileConstants.CHANNELS.SUB_LFE_5_1);
            if (leftFront51 && leftSurround51 && center51 && rightFront51 && rightSurround51 && subLFE51) {

                proxyLeft.push(getProxyString(leftFront51),
                    getProxyString(leftSurround51),
                    getProxyString(center51),
                    getProxyString(subLFE51));

                proxyRight.push(getProxyString(rightFront51),
                    getProxyString(rightSurround51),
                    getProxyString(center51),
                    getProxyString(subLFE51));
            } else {
                audioProfile.forEach(p => {
                    const type = p.get('type');
                    const lookup = AudioProfileStore.getLookup(type);
                    if (!lookup) {return;}

                    const lookupName = lookup.get('description').toLowerCase();
                    let added = false;
                    const reLeft = new RegExp(/left|\(lt?\)/);
                    if (reLeft.test(lookupName)) {
                        proxyLeft.push(getProxyString(p));
                        added = true;
                    }
                    const reRight = new RegExp(/right|\(rt?\)/);
                    if (reRight.test(lookupName)) {
                        proxyRight.push(getProxyString(p));
                        added = true;
                    }

                    if (!added) {
                        proxyLeft.push(getProxyString(p));
                        proxyRight.push(getProxyString(p));
                    }
                });
            }

            return Immutable.fromJS({
                left: proxyLeft.join('+'),
                right: proxyRight.join('+'),
            });

        }

        if (audioPreset?.get('proxyLeftChannel')?.length >= 3) {
            return Immutable.fromJS({
                left: audioPreset.get('proxyLeftChannel'),
                right: audioPreset.get('proxyRightChannel'),
            });
        }

        // Iterate audio channels and look for proxy L/R.
        if (audioProfile.find(c => c.get('isProxyLeft') || c.get('isProxyRight'))) {
            audioProfile.forEach(c => {
                if (c.get('isProxyLeft')) {
                    proxyChannels.proxyLeftChannel = c.get('audioChannel').toString();
                }

                if (c.get('isProxyRight')) {
                    proxyChannels.proxyRightChannel = c.get('audioChannel').toString();
                }
                return;
            });

            return Immutable.fromJS({
                left: proxyChannels.proxyLeftChannel,
                right: proxyChannels.proxyRightChannel
            });
        }

        return Immutable.fromJS({
            left: '',
            right: ''
        });
    }

    handleHide() {
        this.props.onToggleModal();
    }

    handleApproveVideoDelivery() {
        this.props.onApproveVideoDelivery(this.state.proxyChannels);
        this.handleHide();
    }

    handleUpdateProxy(attr, value) {
        this.setState(prevState => {
            return {
                proxyChannels: prevState.proxyChannels.setIn([attr], value)
            };
        });
    }

    isValid() {
        const left = this.state.proxyChannels.get('left');
        const right = this.state.proxyChannels.get('right');

        return Validations.required.validate(left)
            && Validations.required.validate(right);
    }

    render() {
        return (
            <Modal
                backdrop="static"
                className={ClassNames({'skin-dark': this.props.viewingMode === 'skin-dark'})}
                onHide={this.handleHide}
                show={this.props.show}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="text-center">
                        {this.context.intl.messages['hardac.video-inbox.approve.title']}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <FormRow>
                        <FormItem
                            attr="left"
                            label={this.context.intl.messages['hardac.video-inbox.proxy-left']}
                            model={this.state.proxyChannels}
                            required
                            setter={this.handleUpdateProxy}
                            type="text"
                            validations={[
                                Validations.required
                            ]}
                        />
                        <FormItem
                            attr="right"
                            label={this.context.intl.messages['hardac.video-inbox.proxy-right']}
                            model={this.state.proxyChannels}
                            required
                            setter={this.handleUpdateProxy}
                            type="text"
                            validations={[
                                Validations.required
                            ]}
                        />
                    </FormRow>
                </Modal.Body>

                <Modal.Footer>
                    <Button className="btn btn-default-outline pull-left Ml(5)" onClick={this.handleHide}>
                        {this.context.intl.messages['common.cancel']}
                    </Button>
                    <Button className="btn btn-primary-outline" type="submit"
                        disabled={!this.isValid()}
                        onClick={this.handleApproveVideoDelivery}>
                        {this.context.intl.messages['common.approve']}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ApproveVideoDeliveryModal;
