/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {LayoutConstants} from './layout-actions';
import Dispatcher from '../dispatcher/dispatcher';


class LayoutStore extends ReduceStore {

    getInitialState() {
        return Immutable.Map({
            brainiacAppTheme: localStorage.getItem('brainiacAppTheme') || 'skin-blue',
            hardacAppTheme: localStorage.getItem('hardacAppTheme') || 'skin-dark',
            showFiltersPanel: false,
            showSideBar: true
        });
    }

    reduce(state, action) {
        switch (action.actionType) {
        case LayoutConstants.CLEAR:
            state = this.getInitialState();
            break;
        case LayoutConstants.THEME.SET:
            state = state.set(action.themeName, action.themeValue);
            localStorage.setItem(action.themeName, action.themeValue);
            break;

        case LayoutConstants.TOGGLE.FILTERS_PANEL:
            state = state.set('showFiltersPanel', !state.get('showFiltersPanel'));
            break;

        case LayoutConstants.TOGGLE.SIDE_BAR:
            state = state.set('showSideBar', !state.get('showSideBar'));
            break;
        }

        return state;
    }
}

let layoutStore = new LayoutStore(Dispatcher);

export default layoutStore;
