/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

/**
 * Collection of helpers shared by all Lookup Select components.
 */

/**
 * Generic Select event handler.
 */
let HandleSelect = function(attr, setter, options) {
    let defaults = {
        multi: false,
        valueAttr: undefined
    };

    Object.assign(defaults, options);

    return function InnerHandleSelect(value) {
        if (value === null) {
            if (defaults.multi) {
                // This is a special case, if the user clicks the clear button
                // instead of en empty array we get null, but for the app
                // is better to have an empty list.
                setter(attr, Immutable.List());
                return;
            }

            setter(attr, null);
            return;
        }

        if (defaults.valueAttr) {
            value = value[defaults.valueAttr];
        }

        setter(attr, Immutable.fromJS(value));
        return;
    };
};

/**
 * Map a model attribute (usually an id or an array of ids), to
 * a list of complete model objects.
 *
 * Example:
 * Starting from {Genres: [{id: 523}, {id: 941}]}, get an array like
 * [{id: 523, name: 'Romance'}, {id: 941, name: 'Drama'}]
 */
let MapToValues = function(attr, store) {
    return function InnerMapToValues(option) {
        if (!option) {return;}

        /**
         * Immutable.Iterable.isKeyed only returns true for maps.
         * For lists, sets and ordered sets it returns false. So it's
         * the only way of telling if option is an Array-like object.
         *
         * All Immutable instances inherit from Iterable. So we can
         * assert that:
         * Immutable.isImmutable === Immutable.Iterable.isIterable
         * And since Immutable.isImmutable doesn't exist...
         */
        if (Immutable.Iterable.isIterable(option) && !Immutable.Iterable.isKeyed(option)) {
            return option.map(o => {
                return store.findItemByKey(attr, o.get(attr));
            });
        }

        if (Immutable.Iterable.isIterable(option)) {
            return store.findItemByKey(attr, option.get(attr));
        } else {
            return store.findItemByKey(attr, option);
        }

    };
};

export {
    HandleSelect,
    MapToValues
};
