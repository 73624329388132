/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router';

class Tab extends React.Component {
    static get propTypes() {
        return {
            children: PropTypes.oneOfType([
                PropTypes.array,
                PropTypes.object
            ]).isRequired
        };
    }

    static get defaultProps() {
        return {
            children: undefined
        };
    }

    render() {
        return (
            <div className="tab-content">
                <div className="tab-pane padding-x-20 padding-bottom-20 active">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

class TabTitle extends React.Component {
    static get propTypes() {
        return {
            active: PropTypes.bool.isRequired,
            disabled: PropTypes.bool,
            id: PropTypes.string,
            onClick: PropTypes.func,
            route: PropTypes.string.isRequired,
            tabClassName: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: false,
            id: '',
            onClick: undefined
        };
    }

    render() {
        let onClick = this.props.onClick;
        if (this.props.active && !this.props.onClick) {
            // prevent default to avoid navigate to same page over and over again
            onClick = function(event) {
                event.preventDefault();
            };
        }

        let tab;
        if (this.props.disabled) {
            tab = (
                <a className="tab-disabled">
                    {this.props.title}
                </a>
            );
        } else {
            tab = (
                <Link
                    id={ClassNames({[`${this.props.id}-tab-link`]: this.props.id})}
                    onClick={onClick}
                    to={this.props.route}
                >
                    {this.props.title}
                </Link>
            );
        }

        return (
            <li
                className={ClassNames({active: this.props.active}, this.props.tabClassName)}
                id={ClassNames({[`${this.props.id}-tab`]: this.props.id})}
                key={this.props.route}
            >
                {tab}
            </li>
        );
    }
}

class Tabs extends React.Component {
    static get propTypes() {
        return {
            children: PropTypes.oneOfType([
                PropTypes.array,
                PropTypes.object
            ]).isRequired,
            location: PropTypes.object.isRequired
        };
    }

    render() {
        let tabs = [];
        let tabTitles = [];
        let content;
        React.Children.forEach(this.props.children, child => {
            if (child && child.type === Tab) {
                let active = false;
                if (child.props.route === this.props.location.pathname) {
                    active = true;
                    content = child;
                }
                tabs = tabs.concat(child);
                tabTitles = tabTitles.concat(
                    <TabTitle
                        active={active}
                        key={child.props.title}
                        {...child.props}
                    />
                );
            }
        });
        if (!content) {
            // default route activate first node
            if (tabs.length === 0) {
                // no tabs this is an error
                // should redirect to unauthorized for now just console.error and draw nothing
                console.error('No tabs where defined! (maybe permission to browse lookups in general but none in particular)');
                // cancel render!!!
                return null;
            }
            content = tabs[0];
            tabTitles[0] = (
                <TabTitle
                    active
                    key="0"
                    {...content.props}
                />
            );

        }
        return (
            <div className="nav-tabs-custom">
                <ul className="nav nav-tabs">
                    {tabTitles}
                </ul>
                {content}
            </div>
        );
    }
}

export {
    Tab,
    Tabs,
    TabTitle
};
