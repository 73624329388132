/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React from 'react';

import Summary from './summary';
import Users from './users';
import DocumentTitle from '../../common/document-title';
import {Tab, Tabs} from '../../common/routing-tab/routing-tab';
import Preloader from '../../preloader';
import {PermissionActions} from '../../security/permission/permission-actions';
import PermissionStore from '../../security/permission/permission-store';

class Create extends React.Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            params: PropTypes.object.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            permission: PermissionStore.getState().get('permission'),
            roles: PermissionStore.getState().get('roles'),
            showPreloader: PermissionStore.getState().get('showPreloader'),
            users: PermissionStore.getState().get('users'),
            userRoles: PermissionStore.getState().get('userRoles'),
        };
    }

    static getStores() {
        return [PermissionStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();
    }

    componentDidMount() {
        PermissionActions.getPermission(this.props.params.id);
        PermissionActions.getRoles(this.props.params.id);
    }

    render() {
        let permissionName = this.state.permission.get('name');
        const roles = this.state.roles.get(this.props.params.id, []);

        return (
            <DocumentTitle
                message={'permission.create.title'}
            >
                <Preloader show={this.state.showPreloader} fixed loadingDots>
                    <section className="content-header">
                        <h1><i className="fa fa-key"></i>&nbsp;{this.context.intl.messages['permission.create.title']}&nbsp;<small>{permissionName}</small></h1>
                        <p className="padding-top-20"><em>{this.context.intl.messages['permission.create.description']}</em></p>
                    </section>
                    <section className="content">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs location={this.props.location}>
                                    <Tab route={`/permission/${this.props.params.id}`} title={this.context.intl.messages['permission.summary.title']}>
                                        <Summary
                                            permission={this.state.permission}
                                            roles={roles}
                                        />
                                    </Tab>
                                    <Tab route={`/permission/${this.props.params.id}/users`} title={this.context.intl.messages['permission.users.title']}>
                                        <Users
                                            location={this.props.location}
                                            permissionId={this.props.params.id}
                                            users={this.state.users}
                                            userRoles={this.state.userRoles}
                                        />
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </section>
                </Preloader>
            </DocumentTitle>
        );
    }
}

export default Container.create(Create);
