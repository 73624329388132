/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {ReportConstants} from './report-actions';
import Dispatcher from '../../dispatcher/dispatcher';

class ReportStore extends ReduceStore {
    getInitialState() {
        return Immutable.Map({
            reports: Immutable.List(),
            reportTypes: Immutable.List(),
            showPreloader: false
        });
    }

    reduce(state, action) {
        switch (action.actionType) {
        case ReportConstants.CLEAR:
            state = this.getInitialState();

            break;
        case ReportConstants.FIND_REPORTS.START:
        case ReportConstants.FIND_REPORT_TYPES.START:
            state = state.set('showPreloader', true);

            break;
        case ReportConstants.FIND_REPORTS.ERROR:
        case ReportConstants.FIND_REPORT_TYPES.ERROR:
            state = state.set('showPreloader', false);

            break;
        case ReportConstants.FIND_REPORTS.SUCCESS:
            state = state.merge({
                showPreloader: false,
                reports: action.reports,
            });

            break;
        case ReportConstants.FIND_REPORT_TYPES.SUCCESS:
            state = state.merge({
                showPreloader: false,
                reportTypes: action.reportTypes,
            });

            break;
        }

        return state;
    }
}

const store = new ReportStore(Dispatcher);

export {
    store as ReportStore
};
