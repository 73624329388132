/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React from 'react';

import {LayoutActions, LayoutConstants} from './layout-actions';
import Item from './sidebar-menu/item';
import Menu from './sidebar-menu/menu';
import StarredStore from './sidebar-menu/starred/starred-store';
import Tree from './sidebar-menu/tree';
import {DashboardActions} from '../dashboard/dashboard-actions';
import DashboardStore from '../dashboard/dashboard-store';
import SessionStore from '../user/session/session-store';

class Sidebar extends React.Component {
    static get propTypes() {
        return {
            applyLastWeekFilter: PropTypes.bool,
            collapsed: PropTypes.bool.isRequired,
            location: PropTypes.shape({
                pathname: PropTypes.string.isRequired
            }).isRequired,
            savedFilters: PropTypes.object
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            applyLastWeekFilter: false,
            savedFilters: undefined
        };
    }

    static calculateState() {
        return {
            starred: StarredStore.getState().get('starred'),
            user: SessionStore.getState().get('authUser'),
        };
    }

    static getStores() {
        return [DashboardStore, StarredStore, SessionStore];
    }

    constructor(props) {
        super(props);
        this.state = {
            permissions: this.buildPermissionsTree()
        };
    }

    shouldComponentUpdate(nextProps) {
        if (
            nextProps.collapsed !== this.props.collapsed ||
            nextProps.location.pathname !== this.props.location.pathname
        ) {
            return true;
        }

        return false;
    }

    /**
     * Create the permissions tree needed for this component.
     */
    buildPermissionsTree() {
        /**
         * The permissions object here is going to be used during render
         * to know which menus and sub-menus should be displayed.
         */
        const permissions = {
            accounts: {
                applicants: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.APPLICANTS.BROWSE),
                browse: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.BROWSE),
                create: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.APPLICANTS.CREATE),
                mass: {
                    check: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.MASS_UPDATE.UPLOAD),
                    account_wizard: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.ACCOUNT_WIZARD.UPLOAD),
                    update: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.MASS_UPDATE.UPLOAD)
                },
                userErrors: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.VIEW_USER_ERRORS),
                permissions: {
                    read: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.PERMISSIONS.READ)
                }
            },
            analytics: {
                reports: {
                    browse: SessionStore.canUser(SessionStore.PERMISSIONS.ANALYTICS.REPORTS.BROWSE),
                },
            },
            assets: {
                audio: {
                    create: SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.AUDIO.CREATE)
                },
                assetDocument: {
                    browse: SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.ASSET_DOCUMENT.BROWSE),
                    create: SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.ASSET_DOCUMENT.CREATE)
                },
                image: {
                    browse: SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.ASSET_IMAGE.BROWSE),
                    create: SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.ASSET_IMAGE.CREATE)
                },
                merchandise: {
                    create: SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.MERCHANDISE.CREATE)
                },
                script: {
                    create: SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.SCRIPT.CREATE)
                },
                video: {
                    browse: SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.VIDEO.BROWSE),
                    create: SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.VIDEO.CREATE)
                },
                browse_all: SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.BROWSE_ALL)
            },
            brands: {
                browse: SessionStore.canUser(SessionStore.PERMISSIONS.BRANDS.BROWSE),
                create: SessionStore.canUser(SessionStore.PERMISSIONS.BRANDS.CREATE)
            },
            catalogs: {
                asset: {
                    browse: SessionStore.canUser(SessionStore.PERMISSIONS.CATALOGS.ASSET.BROWSE),
                    create: SessionStore.canUser(SessionStore.PERMISSIONS.CATALOGS.ASSET.CREATE),
                    mass:{
                        update: SessionStore.canUser(SessionStore.PERMISSIONS.CATALOGS.ASSET.MASS_UPDATE.UPLOAD)
                    }
                },
                title: {
                    browse: SessionStore.canUser(SessionStore.PERMISSIONS.CATALOGS.TITLE.BROWSE),
                    create: SessionStore.canUser(SessionStore.PERMISSIONS.CATALOGS.TITLE.CREATE),
                    mass:{
                        update: SessionStore.canUser(SessionStore.PERMISSIONS.CATALOGS.TITLE.MASS_UPDATE.UPLOAD)
                    }
                },
                language: {
                    browse: SessionStore.canUser(SessionStore.PERMISSIONS.CATALOGS.LANGUAGE.BROWSE)
                }
            },
            events: {
                browse: SessionStore.canUser(SessionStore.PERMISSIONS.EVENTS.BROWSE),
                browseArchived: SessionStore.canUser(SessionStore.PERMISSIONS.EVENTS.BROWSE_ARCHIVED),
                create: SessionStore.canUser(SessionStore.PERMISSIONS.EVENTS.CREATE)
            },
            groups: {
                clientRepGroups: {
                    browse: SessionStore.canUser(SessionStore.PERMISSIONS.GROUPS.CLIENT_REP_GROUPS.BROWSE),
                    clientReps: SessionStore.canUser(SessionStore.PERMISSIONS.GROUPS.CLIENT_REP_GROUPS.BROWSECR),
                    create: SessionStore.canUser(SessionStore.PERMISSIONS.GROUPS.CLIENT_REP_GROUPS.CREATE)
                },
                userGroups: {
                    browse: SessionStore.canUser(SessionStore.PERMISSIONS.GROUPS.BROWSE),
                    create: SessionStore.canUser(SessionStore.PERMISSIONS.GROUPS.CREATE)
                }
            },
            guilds: {
                create: SessionStore.canUser(SessionStore.PERMISSIONS.GUILDS.CREATE),
                browse: SessionStore.canUser(SessionStore.PERMISSIONS.GUILDS.BROWSE),
                read: SessionStore.canUser(SessionStore.PERMISSIONS.GUILDS.READ),
                update: SessionStore.canUser(SessionStore.PERMISSIONS.GUILDS.UPDATE),
            },
            hardac: {
                videoDelivery: {
                    browse: SessionStore.canUser(SessionStore.PERMISSIONS.HARDAC.VIDEO_DELIVERY.BROWSE)
                },
                processes: {
                    browse: SessionStore.canUser(SessionStore.PERMISSIONS.HARDAC.PROCESSES.BROWSE),
                    browseJobs: SessionStore.canUser(SessionStore.PERMISSIONS.HARDAC.JOBS.BROWSE)
                },
                proxies: {
                    browse: SessionStore.canUser(SessionStore.PERMISSIONS.HARDAC.PROXY.BROWSE)
                },
                timelines: {
                    browse: SessionStore.canUser(SessionStore.PERMISSIONS.HARDAC.TIMELINE.BROWSE)
                },
                machineLearning: {
                    browse: SessionStore.canUser(SessionStore.PERMISSIONS.HARDAC.MACHINE_LEARNING.BROWSE)
                },
                configuration: {
                    edit: SessionStore.canUser(SessionStore.PERMISSIONS.HARDAC.CONFIGURATION.EDIT)
                }
            },
            history: {
                browse: SessionStore.canUser(SessionStore.PERMISSIONS.HISTORY.BROWSE)
            },
            helpVideos: {
                browse: SessionStore.canUser(SessionStore.PERMISSIONS.HELP_PAGES.BROWSE),
            },
            presets: {
                browse: SessionStore.canUser(SessionStore.PERMISSIONS.PRESETS.BROWSE),
                create: SessionStore.canUser(SessionStore.PERMISSIONS.PRESETS.CREATE)
            },
            privacy: {
                browse: SessionStore.canUser(SessionStore.PERMISSIONS.PRIVACY_REQUESTS.BROWSE),
                create: SessionStore.canUser(SessionStore.PERMISSIONS.PRIVACY_REQUESTS.CREATE)
            },
            publishing: {
                publishingList: {
                    browse: SessionStore.canUser(SessionStore.PERMISSIONS.PUBLISHING.BROWSE),
                    create: SessionStore.canUser(SessionStore.PERMISSIONS.PUBLISHING.CREATE)
                },
                homepage: {
                    browse: SessionStore.canUser(SessionStore.PERMISSIONS.HOMEPAGE.BROWSE),
                    create: SessionStore.canUser(SessionStore.PERMISSIONS.HOMEPAGE.CREATE)
                },
                homescreen: {
                    browse: SessionStore.canUser(SessionStore.PERMISSIONS.HOMESCREEN.BROWSE),
                    create: SessionStore.canUser(SessionStore.PERMISSIONS.HOMESCREEN.CREATE)
                },
                loginBackground: {
                    browse: SessionStore.canUser(SessionStore.PERMISSIONS.LOGIN_BACKGROUND.BROWSE),
                    remove: SessionStore.canUser(SessionStore.PERMISSIONS.LOGIN_BACKGROUND.REMOVE),
                    upload: SessionStore.canUser(SessionStore.PERMISSIONS.LOGIN_BACKGROUND.UPLOAD)
                },
            },
            pushNotifications: {
                browse: SessionStore.canUser(SessionStore.PERMISSIONS.PUSH_NOTIFICATIONS.BROWSE),
                create: SessionStore.canUser(SessionStore.PERMISSIONS.PUSH_NOTIFICATIONS.CREATE)
            },
            roles: {
                browse: SessionStore.canUser(SessionStore.PERMISSIONS.ROLES.BROWSE),
                create: SessionStore.canUser(SessionStore.PERMISSIONS.ROLES.CREATE)
            },
            scheduling: {
                documentBatches: {
                    browse: SessionStore.canUser(SessionStore.PERMISSIONS.SCHEDULING.DOCUMENT_BATCHES.BROWSE),
                    create: SessionStore.canUser(SessionStore.PERMISSIONS.SCHEDULING.DOCUMENT_BATCHES.CREATE)
                },
                imageBatches: {
                    browse: SessionStore.canUser(SessionStore.PERMISSIONS.SCHEDULING.IMAGE_BATCHES.BROWSE),
                    create: SessionStore.canUser(SessionStore.PERMISSIONS.SCHEDULING.IMAGE_BATCHES.CREATE)
                },
                titleBatches: {
                    browse: SessionStore.canUser(SessionStore.PERMISSIONS.SCHEDULING.TITLE_BATCHES.BROWSE),
                    create: SessionStore.canUser(SessionStore.PERMISSIONS.SCHEDULING.TITLE_BATCHES.CREATE)
                },
                videoBatches: {
                    browse: SessionStore.canUser(SessionStore.PERMISSIONS.SCHEDULING.VIDEO_BATCHES.BROWSE),
                    create: SessionStore.canUser(SessionStore.PERMISSIONS.SCHEDULING.VIDEO_BATCHES.CREATE)
                }
            },
            setup: {
                authorizedPartners: {
                    browse: SessionStore.canUser(SessionStore.PERMISSIONS.SETUP.AUTHORIZED_PARTNERS.BROWSE),
                    create: SessionStore.canUser(SessionStore.PERMISSIONS.SETUP.AUTHORIZED_PARTNERS.CREATE)
                },
                emailWhitelist: {
                    browse: SessionStore.canUser(SessionStore.PERMISSIONS.SETUP.EMAIL_WHITELIST.BROWSE),
                    create: SessionStore.canUser(SessionStore.PERMISSIONS.SETUP.EMAIL_WHITELIST.CREATE)
                },
                lookups: {
                    browse: SessionStore.canUser(SessionStore.PERMISSIONS.SETUP.LOOKUPS.BROWSE),
                },
                utilities: {
                    browse: SessionStore.canUser(SessionStore.PERMISSIONS.SETUP.UTILITIES.BROWSE)
                },
            },
            stations: {
                browse: SessionStore.canUser(SessionStore.PERMISSIONS.STATIONS.CONFIGURATION),
            },
            talent: {
                browse: SessionStore.canUser(SessionStore.PERMISSIONS.TALENT.BROWSE),
                create: SessionStore.canUser(SessionStore.PERMISSIONS.TALENT.CREATE)
            },
            titles: {
                viewDeleted: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.VIEW_DELETED_SECTION),
                workOrders:{
                    browse: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.WORK_ORDERS.BROWSE),
                    create: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.WORK_ORDERS.CREATE)
                },
                mass:{
                    browse: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.MASS_ADD.BROWSE),
                    create: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.MASS_ADD.UPLOAD),
                    update: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.MASS_UPDATE.UPLOAD),
                },
                browse: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.BROWSE),
                create: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.CREATE)
            }
        };

        // Recursive method to know if parents should be displayed based on
        // children objects.
        // If one children is true => display the parent.
        Object.keys(permissions).forEach(menuName => {
            const menuObj = permissions[menuName];

            (function shouldDisplayMenu(menu) {
                Object.keys(menu).forEach(subMenuName => {
                    // Base case, if boolean, return its value.
                    if (typeof menu[subMenuName] === 'boolean') {
                        if (menu[subMenuName]) {
                            menu.__showMenu = true; // true or false
                        }

                        return;
                    }

                    shouldDisplayMenu(menu[subMenuName]);
                    if (menu[subMenuName].__showMenu) {
                        menu.__showMenu = true;
                    }
                    return;
                });

                return;
            })(menuObj);

            return;
        });

        return permissions;
    }

    render() {
        let {permissions} = this.state;

        let accounts;
        if (permissions.accounts.__showMenu) {
            let mass;
            if (permissions.accounts.mass.__showMenu) {
                mass = (
                    <Tree title={this.context.intl.messages['sidebar.accounts.mass-ingest/update']}>
                        {permissions.accounts.mass.account_wizard && <Item href={'/accounts/account-wizard'} permission={SessionStore.PERMISSIONS.ACCOUNTS.ACCOUNT_WIZARD.UPLOAD}>{this.context.intl.messages['sidebar.accounts.mass-ingest']}</Item>}
                        {permissions.accounts.mass.check && <Item href={'/accounts/mass-check'} permission={SessionStore.PERMISSIONS.ACCOUNTS.MASS_UPDATE.UPLOAD}>{this.context.intl.messages['sidebar.accounts.mass-check']}</Item>}
                        {permissions.accounts.mass.update && <Item href={'/accounts/mass-update'} permission={SessionStore.PERMISSIONS.ACCOUNTS.MASS_UPDATE.UPLOAD}>{this.context.intl.messages['sidebar.accounts.mass-update']}</Item>}
                        {permissions.accounts.mass.account_wizard && <Item href={'/accounts/account-wizard/browse'} permission={SessionStore.PERMISSIONS.ACCOUNTS.ACCOUNT_WIZARD.UPLOAD}>{this.context.intl.messages['sidebar.accounts.mass-ingest.browse']}</Item>}
                    </Tree>
                );
            }
            accounts = <Tree iconClass="fas fa-user" title={this.context.intl.messages['sidebar.accounts']}>
                {permissions.accounts.applicants && <Item href={'/accounts/applicants?operator=OR&account-status=2&account-status=8&account-status=6&account-status=9&account-status=0&account-status=7'} permission={SessionStore.PERMISSIONS.ACCOUNTS.APPLICANTS.BROWSE}>{this.context.intl.messages['sidebar.accounts.applicants']}</Item>}
                {permissions.accounts.browse && <Item href={'/accounts'} permission={SessionStore.PERMISSIONS.ACCOUNTS.BROWSE} >{this.context.intl.messages['sidebar.accounts.browse']}</Item>}
                {permissions.accounts.create && <Item href={'/accounts/applicants/create'} permission={SessionStore.PERMISSIONS.ACCOUNTS.APPLICANTS.CREATE}>{this.context.intl.messages['sidebar.accounts.create-applicant']}</Item>}
                {mass}
                {permissions.accounts.userErrors && <Item href={'/user-errors'} permission={SessionStore.PERMISSIONS.ACCOUNTS.VIEW_USER_ERRORS}>{this.context.intl.messages['sidebar.accounts.user-errors']}</Item>}
                {permissions.accounts.browse && <Item iconClass="fa-solid fa-question-circle" href={'/accounts/help'} permission={SessionStore.PERMISSIONS.ACCOUNTS.BROWSE}>{this.context.intl.messages['sidebar.help']}</Item>}
            </Tree>;
        }

        let assets;
        if (permissions.assets.__showMenu) {
            let browseAssets;
            let createAssets;
            if (permissions.assets.audio.__showMenu || permissions.assets.assetDocument.__showMenu) {
                createAssets = <Tree title={this.context.intl.messages['sidebar.assets.create']}>
                    {permissions.assets.audio.create && <Item iconClass="fas fa-volume-up" href={'/assets/audio/create'} permission={SessionStore.PERMISSIONS.ASSETS.AUDIO.CREATE} >&nbsp;{this.context.intl.messages['sidebar.assets.create.audio']}</Item>}
                    {permissions.assets.assetDocument.create && <Item iconClass="fas fa-file-alt" href={'/assets/document/create'} permission={SessionStore.PERMISSIONS.ASSETS.ASSET_DOCUMENT.CREATE}>&nbsp;{this.context.intl.messages['sidebar.assets.create.document']}</Item>}
                    {permissions.assets.script.create && <Item iconClass="far fa-file-video" href={'/assets/script/create'} permission={SessionStore.PERMISSIONS.ASSETS.SCRIPT.CREATE}>&nbsp;{this.context.intl.messages['sidebar.assets.create.script']}</Item>}
                    {permissions.assets.image.create && <Item iconClass="fas fa-camera" href={'/assets/image/create'} permission={SessionStore.PERMISSIONS.ASSETS.ASSET_IMAGE.CREATE}>&nbsp;{this.context.intl.messages['sidebar.assets.create.image']}</Item>}
                    {permissions.assets.merchandise.create && <Item iconClass="fas fa-tag" href={'/assets/merchandise/create'} permission={SessionStore.PERMISSIONS.ASSETS.MERCHANDISE.CREATE}>&nbsp;{this.context.intl.messages['sidebar.assets.create.merchandise']}</Item>}
                    {permissions.assets.video.create && <Item iconClass="fas fa-video" href={'/assets/video/create'} permission={SessionStore.PERMISSIONS.ASSETS.VIDEO.CREATE}>&nbsp;{this.context.intl.messages['sidebar.assets.create.video']}</Item>}
                </Tree>;
            }
            if (permissions.assets.browse_all || permissions.assets.image.browse || permissions.assets.video.browse || permissions.assets.assetDocument.browse) {
                browseAssets = <Tree title={this.context.intl.messages['sidebar.assets.browse']}>
                    {permissions.assets.image.browse && <Item iconClass="fas fa-camera" href={'/assets/images?asset-type=1&operator=AND&active-type=ACTIVE'} permission={SessionStore.PERMISSIONS.ASSETS.ASSET_IMAGE.BROWSE}>&nbsp;{this.context.intl.messages['sidebar.assets.browse.image']}</Item>}
                    {permissions.assets.video.browse && <Item iconClass="fas fa-video" href={'/assets/videos?asset-type=2&operator=AND&active-type=ACTIVE'} permission={SessionStore.PERMISSIONS.ASSETS.VIDEO.BROWSE}>&nbsp;{this.context.intl.messages['sidebar.assets.browse.video']}</Item>}
                    {permissions.assets.assetDocument.browse && <Item iconClass="fas fa-file-alt" href={'/assets/documents?asset-type=21&operator=AND&active-type=ACTIVE'} permission={SessionStore.PERMISSIONS.ASSETS.ASSET_DOCUMENT.BROWSE}>&nbsp;{this.context.intl.messages['sidebar.assets.browse.document']}</Item>}
                    {permissions.assets.browse_all && <Item iconClass="fas fa-asterisk" href={'/assets?active-type=ACTIVE'} permission={SessionStore.PERMISSIONS.ASSETS.BROWSE_ALL}>&nbsp;{this.context.intl.messages['sidebar.assets.browse.all']}</Item>}
                </Tree>;
            }
            assets = <Tree iconClass="far fa-file-image" title={this.context.intl.messages['sidebar.assets']}>
                {browseAssets}
                {createAssets}
                {permissions.assets.browse && <Item iconClass="fa-solid fa-question-circle" href={'/assets/help'} permission={SessionStore.PERMISSIONS.ASSETS.BROWSE}>&nbsp;{this.context.intl.messages['sidebar.help']}</Item>}
            </Tree>;
        }

        let brands;
        if (permissions.brands.__showMenu) {
            brands = <Tree iconClass="fas fa-tag" title={this.context.intl.messages['sidebar.brands']}>
                {permissions.brands.browse && <Item href={'/brands'} permission={SessionStore.PERMISSIONS.BRANDS.BROWSE}>{this.context.intl.messages['sidebar.browse']}</Item>}
                {permissions.brands.create && <Item href={'/brands/create'} permission={SessionStore.PERMISSIONS.BRANDS.CREATE}>{this.context.intl.messages['sidebar.create']}</Item>}
                {permissions.brands.browse && <Item iconClass="fa-solid fa-question-circle" href={'/brands/help'} permission={SessionStore.PERMISSIONS.BRANDS.BROWSE}>{this.context.intl.messages['sidebar.help']}</Item>}
            </Tree>;
        }

        let catalogs;
        if (permissions.catalogs.__showMenu) {
            let assetCatalogs;
            if (permissions.catalogs.asset.__showMenu) {
                assetCatalogs = <Tree title={this.context.intl.messages['sidebar.catalogs.asset']}>
                    {permissions.catalogs.asset.browse && <Item href={'/catalogs/asset?active=true'} permission={SessionStore.PERMISSIONS.CATALOGS.ASSET.BROWSE}>{this.context.intl.messages['sidebar.browse']}</Item>}
                    {permissions.catalogs.asset.create && <Item href={'/catalogs/asset/create'} permission={SessionStore.PERMISSIONS.CATALOGS.ASSET.CREATE}>{this.context.intl.messages['sidebar.create']}</Item>}
                    {permissions.catalogs.asset.mass.update && <Item href={'/catalogs/asset/mass-update'} permission={SessionStore.PERMISSIONS.CATALOGS.ASSET.MASS_UPDATE.UPLOAD}>{this.context.intl.messages['sidebar.catalogs.asset.mass-update']}</Item>}
                </Tree>;
            }
            let titleCatalogs;
            if (permissions.catalogs.title.__showMenu) {
                titleCatalogs = <Tree title={this.context.intl.messages['sidebar.catalogs.title']}>
                    {permissions.catalogs.title.browse && <Item href={'/catalogs/title?active=true'} permission={SessionStore.PERMISSIONS.CATALOGS.TITLE.BROWSE}>{this.context.intl.messages['sidebar.browse']}</Item>}
                    {permissions.catalogs.title.create && <Item href={'/catalogs/title/create'} permission={SessionStore.PERMISSIONS.CATALOGS.TITLE.CREATE}>{this.context.intl.messages['sidebar.create']}</Item>}
                    {permissions.catalogs.title.mass.update && <Item href={'/catalogs/title/mass-update'} permission={SessionStore.PERMISSIONS.CATALOGS.TITLE.MASS_UPDATE.UPLOAD}>{this.context.intl.messages['sidebar.catalogs.title.mass-update']}</Item>}
                </Tree>;
            }
            let languageCatalogs;
            if (permissions.catalogs.language.__showMenu) {
                languageCatalogs = <Tree title={this.context.intl.messages['sidebar.catalogs.language']}>
                    {permissions.catalogs.language.browse && <Item href={'/catalogs/language?active=true'} permission={SessionStore.PERMISSIONS.CATALOGS.LANGUAGE.BROWSE}>{this.context.intl.messages['sidebar.browse']}</Item>}
                </Tree>;
            }

            catalogs = <Tree iconClass="fas fa-book" title={this.context.intl.messages['sidebar.catalogs']}>
                {assetCatalogs}
                {titleCatalogs}
                {languageCatalogs}
                {permissions.catalogs.asset.browse && <Item iconClass="fa-solid fa-question-circle" href={'/catalogs/help'} permission={SessionStore.PERMISSIONS.CATALOGS.ASSET.BROWSE}>{this.context.intl.messages['sidebar.help']}</Item>}
            </Tree>;
        }

        let groups;
        if (permissions.groups.__showMenu) {
            let clientRepGroups;
            if (permissions.groups.clientRepGroups.__showMenu) {
                clientRepGroups = <Tree title={this.context.intl.messages['sidebar.groups.client-rep-groups']}>
                    {permissions.groups.clientRepGroups.browse && <Item href={'/groups/client-rep-groups'} permission={SessionStore.PERMISSIONS.GROUPS.CLIENT_REP_GROUPS.BROWSE}>{this.context.intl.messages['sidebar.browse']}</Item>}
                    {permissions.groups.clientRepGroups.create && <Item href={'/groups/client-rep-groups/create'} permission={SessionStore.PERMISSIONS.GROUPS.CLIENT_REP_GROUPS.CREATE}>{this.context.intl.messages['sidebar.create']}</Item>}
                    {permissions.groups.clientRepGroups.clientReps && <Item href={'/groups/client-rep-groups/client-reps'} permission={SessionStore.PERMISSIONS.GROUPS.CLIENT_REP_GROUPS.BROWSECR}>{this.context.intl.messages['sidebar.client-rep.browse']}</Item>}
                </Tree>;
            }
            let userGroups;
            if (permissions.groups.userGroups.__showMenu) {
                userGroups = <Tree title={this.context.intl.messages['sidebar.groups.user-groups']}>
                    {permissions.groups.userGroups.browse && <Item href={'/groups/user-groups'} permission={SessionStore.PERMISSIONS.GROUPS.BROWSE}>{this.context.intl.messages['sidebar.browse']}</Item>}
                    {permissions.groups.userGroups.create && <Item href={'/groups/user-groups/create'} permission={SessionStore.PERMISSIONS.GROUPS.CREATE}>{this.context.intl.messages['sidebar.create']}</Item>}
                </Tree>;
            }

            groups = <Tree iconClass="fas fa-users" title={this.context.intl.messages['sidebar.groups']}>
                {clientRepGroups}
                {userGroups}
                {permissions.groups.userGroups.browse && <Item iconClass="fa-solid fa-question-circle" href={'/groups/help'} permission={SessionStore.PERMISSIONS.GROUPS.BROWSE}>{this.context.intl.messages['sidebar.help']}</Item>}
            </Tree>;
        }

        let helpVideos;
        if (permissions.helpVideos.__showMenu) {
            helpVideos = (
                <Item
                    className={ClassNames('treeview', {active: this.props.location.pathname === '/help'})}
                    iconClass="fas fa-info-circle"
                    href={'/help'}
                >
                    {this.context.intl.messages['sidebar.help-videos']}
                </Item>
            );
        }

        let presets;
        if (permissions.presets.__showMenu) {
            presets = <Tree iconClass="fas fa-list-ul" title={this.context.intl.messages['sidebar.presets']}>
                {permissions.presets.browse && <Item href={'/presets?active=ACTIVE'} permission={SessionStore.PERMISSIONS.PRESETS.BROWSE}>{this.context.intl.messages['sidebar.browse']}</Item>}
                {permissions.presets.create && <Item href={'/presets/create'} permission={SessionStore.PERMISSIONS.PRESETS.CREATE}>{this.context.intl.messages['sidebar.create']}</Item>}
                {permissions.presets.browse && <Item iconClass="fa-solid fa-question-circle" href={'/presets/help'} permission={SessionStore.PERMISSIONS.PRESETS.BROWSE}>{this.context.intl.messages['sidebar.help']}</Item>}
            </Tree>;
        }

        let pushNotifications;
        if (permissions.pushNotifications.__showMenu) {
            pushNotifications = <Tree iconClass="fas fa-bell" title={this.context.intl.messages['sidebar.push-notifications']}>
                {permissions.pushNotifications.browse && <Item href={'/push-notifications'} permission={SessionStore.PERMISSIONS.PUSH_NOTIFICATIONS.BROWSE}>{this.context.intl.messages['sidebar.browse']}</Item>}
                {permissions.pushNotifications.create && <Item href={'/push-notifications/create'} permission={SessionStore.PERMISSIONS.PUSH_NOTIFICATIONS.CREATE}>{this.context.intl.messages['sidebar.create']}</Item>}
                {permissions.pushNotifications.browse && <Item iconClass="fa-solid fa-question-circle" href={'/push-notifications/help'} permission={SessionStore.PERMISSIONS.PUSH_NOTIFICATIONS.BROWSE}>{this.context.intl.messages['sidebar.help']}</Item>}
            </Tree>;
        }

        let publishing;
        if (permissions.publishing.__showMenu) {
            let publishingList;
            if (permissions.publishing.publishingList.__showMenu) {
                publishingList = <Tree title={this.context.intl.messages['sidebar.publishing-list']}>
                    {permissions.publishing.publishingList.browse && <Item href={'/publishing-list?active=ACTIVE'} permission={SessionStore.PERMISSIONS.PUBLISHING.BROWSE}>{this.context.intl.messages['sidebar.browse']}</Item>}
                    {permissions.publishing.publishingList.create && <Item href={'/publishing-list/create'} permission={SessionStore.PERMISSIONS.PUBLISHING.CREATE}>{this.context.intl.messages['sidebar.create']}</Item>}
                </Tree>;
            }
            let homepage;
            if (permissions.publishing.homepage.__showMenu) {
                homepage = <Tree title={this.context.intl.messages['sidebar.homepages']}>
                    {permissions.publishing.homepage.browse && <Item href={'/homepage?active=ACTIVE'} permission={SessionStore.PERMISSIONS.HOMEPAGE.BROWSE}>{this.context.intl.messages['sidebar.browse']}</Item>}
                    {permissions.publishing.homepage.create && <Item href={'/homepage/create'} permission={SessionStore.PERMISSIONS.HOMEPAGE.CREATE}>{this.context.intl.messages['sidebar.create']}</Item>}
                </Tree>;
            }
            let homescreen;
            if (permissions.publishing.homescreen.__showMenu) {
                homescreen = <Tree title={this.context.intl.messages['sidebar.homescreens']}>
                    {permissions.publishing.homescreen.browse && <Item href={'/homescreen?active=ACTIVE'} permission={SessionStore.PERMISSIONS.HOMESCREEN.BROWSE}>{this.context.intl.messages['sidebar.browse']}</Item>}
                    {permissions.publishing.homescreen.create && <Item href={'/homescreen/create'} permission={SessionStore.PERMISSIONS.HOMESCREEN.CREATE}>{this.context.intl.messages['sidebar.create']}</Item>}
                </Tree>;
            }
            let loginBackground;
            if (permissions.publishing.loginBackground.__showMenu) {
                loginBackground = <Tree title={this.context.intl.messages['sidebar.login-backgrounds']}>
                    {permissions.publishing.loginBackground.browse && <Item href={'/login-background'} permission={SessionStore.PERMISSIONS.LOGIN_BACKGROUND.BROWSE}>{this.context.intl.messages['sidebar.browse']}</Item>}
                </Tree>;
            }
            publishing = <Tree iconClass="far fa-list-alt" title={this.context.intl.messages['sidebar.publishing']}>
                {publishingList}
                {homepage}
                {homescreen}
                {loginBackground}
                {permissions.publishing.publishingList.browse && <Item iconClass="fa-solid fa-question-circle" href={'/publishing-list/help'} permission={SessionStore.PERMISSIONS.PUBLISHING.BROWSE}>{this.context.intl.messages['sidebar.help']}</Item>}
            </Tree>;
        }

        let roles;
        if (permissions.roles.__showMenu) {
            roles = <Tree iconClass="fas fa-male" title={this.context.intl.messages['sidebar.roles']}>
                {permissions.roles.browse && <Item href={'/roles'} permission={SessionStore.PERMISSIONS.ROLES.BROWSE}>{this.context.intl.messages['sidebar.browse']}</Item>}
                {permissions.roles.create && <Item href={'/roles/create'} permission={SessionStore.PERMISSIONS.ROLES.CREATE}>{this.context.intl.messages['sidebar.create']}</Item>}
                {permissions.accounts.permissions.read && <Item href={'/permission'} permission={SessionStore.PERMISSIONS.ACCOUNTS.PERMISSIONS.READ}>{this.context.intl.messages['sidebar.roles.permissions-audit']}</Item>}
                {permissions.roles.browse && <Item iconClass="fa-solid fa-question-circle" href={'/roles/help'} permission={SessionStore.PERMISSIONS.ROLES.BROWSE}>{this.context.intl.messages['sidebar.help']}</Item>}
            </Tree>;
        }

        let setup;
        if (permissions.setup.__showMenu) {
            let authorizedPartners;
            let emailWhitelist;
            let lookups;
            let Utilities;

            if (permissions.setup.authorizedPartners.__showMenu) {
                authorizedPartners = <Tree title={this.context.intl.messages['sidebar.setup.authorized-partners']}>
                    {permissions.setup.authorizedPartners.browse && <Item href={'/setup/authorized-partners'} permission={SessionStore.PERMISSIONS.SETUP.AUTHORIZED_PARTNERS.BROWSE}>{this.context.intl.messages['sidebar.browse']}</Item>}
                    {permissions.setup.authorizedPartners.create && <Item href={'/setup/authorized-partners/create'} permission={SessionStore.PERMISSIONS.SETUP.AUTHORIZED_PARTNERS.CREATE}>{this.context.intl.messages['sidebar.create']}</Item>}
                </Tree>;
            }


            if (permissions.setup.emailWhitelist.__showMenu) {
                emailWhitelist = <Tree title={this.context.intl.messages['sidebar.setup.email-whitelist']}>
                    {permissions.setup.emailWhitelist.browse && <Item href={'/setup/email-whitelists'} permission={SessionStore.PERMISSIONS.SETUP.EMAIL_WHITELIST.BROWSE}>{this.context.intl.messages['sidebar.browse']}</Item>}
                    {permissions.setup.emailWhitelist.create && <Item href={'/setup/email-whitelists/create'} permission={SessionStore.PERMISSIONS.SETUP.EMAIL_WHITELIST.CREATE}>{this.context.intl.messages['sidebar.create']}</Item>}
                </Tree>;
            }

            if (permissions.setup.lookups.__showMenu) {
                lookups = <Item href={'/setup/lookup'} permission={SessionStore.PERMISSIONS.SETUP.LOOKUPS.BROWSE}>{this.context.intl.messages['sidebar.setup.lookups']}</Item>;
            }

            if (permissions.setup.utilities.__showMenu) {
                Utilities = <Item href={'/setup/utilities'} permission={SessionStore.PERMISSIONS.SETUP.UTILITIES.BROWSE}>{this.context.intl.messages['sidebar.setup.utilities']}</Item>;
            }


            setup = <Tree iconClass="fas fa-cog" title={this.context.intl.messages['sidebar.setup']}>
                {authorizedPartners}
                {emailWhitelist}
                {lookups}
                {Utilities}
                {permissions.setup.emailWhitelist.browse && <Item iconClass="fa-solid fa-question-circle" href={'/setup/help'} permission={SessionStore.PERMISSIONS.SETUP.EMAIL_WHITELIST.BROWSE}>{this.context.intl.messages['sidebar.help']}</Item>}
            </Tree>;
        }

        let stations;
        if (permissions.stations.__showMenu) {
            stations = <Tree iconClass="fa-solid fa-tv-retro" title={this.context.intl.messages['sidebar.stations']}>
                <Item href={'/stations'} permission={SessionStore.PERMISSIONS.STATIONS.CONFIGURATION}>{this.context.intl.messages['sidebar.browse']}</Item>
                <Item href={'/stations/create'} permission={SessionStore.PERMISSIONS.STATIONS.CONFIGURATION}>{this.context.intl.messages['sidebar.create']}</Item>
                <Tree title={this.context.intl.messages['sidebar.stations.station-groups']}>
                    <Item href={'/station-groups'} permission={SessionStore.PERMISSIONS.STATIONS.CONFIGURATION}>{this.context.intl.messages['sidebar.browse']}</Item>
                    <Item href={'/station-groups/create'} permission={SessionStore.PERMISSIONS.STATIONS.CONFIGURATION}>{this.context.intl.messages['sidebar.create']}</Item>
                </Tree>
                <Item iconClass="fa-solid fa-question-circle" href={'/stations/help'} permission={SessionStore.PERMISSIONS.STATIONS.CONFIGURATION}>{this.context.intl.messages['sidebar.help']}</Item>
            </Tree>;
        }

        let titles;
        if (permissions.titles.__showMenu) {
            let mass;
            if (permissions.titles.mass.__showMenu) {
                mass = (
                    <Tree title={this.context.intl.messages['sidebar.titles.mass-add/update']}>
                        {permissions.titles.mass.create && <Item href={'/titles/mass-add'} permission={SessionStore.PERMISSIONS.TITLES.MASS_ADD.UPLOAD}>{this.context.intl.messages['sidebar.titles.mass-add-titles']}</Item>}
                        {permissions.titles.mass.update && <Item href={'/titles/mass-update'} permission={SessionStore.PERMISSIONS.TITLES.MASS_UPDATE.UPLOAD}>{this.context.intl.messages['sidebar.titles.mass-update-titles']}</Item>}
                        {permissions.titles.mass.browse && <Item href={'/titles/mass-update-logs?operator=AND'} permission={SessionStore.PERMISSIONS.TITLES.MASS_ADD.BROWSE}>{this.context.intl.messages['sidebar.titles.mass-add/update.logs']}</Item>}
                    </Tree>
                );
            }

            let workOrders;
            if (permissions.titles.workOrders.__showMenu) {
                workOrders = (
                    <Tree title={this.context.intl.messages['sidebar.titles.work-orders-title']}>
                        {permissions.titles.workOrders.browse && <Item href={'/titles/work-orders'} permission={SessionStore.PERMISSIONS.TITLES.WORK_ORDERS.BROWSE}>{this.context.intl.messages['sidebar.titles.work-orders.browse']}</Item>}
                        {permissions.titles.workOrders.create && <Item href={'/titles/work-orders/create'} permission={SessionStore.PERMISSIONS.TITLES.WORK_ORDERS.CREATE}>{this.context.intl.messages['sidebar.titles.work-orders.create']}</Item>}
                    </Tree>
                );
            }


            titles = <Tree iconClass="far fa-newspaper" title={this.context.intl.messages['sidebar.titles']}>
                {permissions.titles.browse && <Item href={'/titles?active=ACTIVE'} permission={SessionStore.PERMISSIONS.TITLES.BROWSE}>{this.context.intl.messages['sidebar.titles.browse']}</Item>}
                {permissions.titles.create && <Item href={'/titles/create'} permission={SessionStore.PERMISSIONS.TITLES.CREATE}>{this.context.intl.messages['sidebar.titles.add']}</Item>}
                {mass}
                {workOrders}
                {permissions.titles.viewDeleted && <Item href={'/titles/deleted'} permission={SessionStore.PERMISSIONS.TITLES.VIEW_DELETED_SECTION}>{this.context.intl.messages['sidebar.titles.delete-queue']}</Item>}
                {permissions.titles.mass.browse && <Item iconClass="fa-solid fa-question-circle" href={'/titles/help'} permission={SessionStore.PERMISSIONS.TITLES.MASS_ADD.BROWSE}>{this.context.intl.messages['sidebar.help']}</Item>}
            </Tree>;
        }

        let talent;
        if (permissions.talent.__showMenu) {
            talent = <Tree iconClass="fas fa-star" title={this.context.intl.messages['sidebar.talent']}>
                {permissions.talent.browse && <Item href={'/talent'} permission={SessionStore.PERMISSIONS.TALENT.BROWSE}>{this.context.intl.messages['sidebar.talent.browse']}</Item>}
                {permissions.talent.create && <Item href={'/talent/create'} permission={SessionStore.PERMISSIONS.TALENT.CREATE}>{this.context.intl.messages['sidebar.talent.create']}</Item>}
                {permissions.talent.browse && <Item iconClass="fa-solid fa-question-circle" href={'/talent/help'} permission={SessionStore.PERMISSIONS.TALENT.BROWSE}>{this.context.intl.messages['sidebar.help']}</Item>}
            </Tree>;
        }

        let history;
        if (permissions.history.browse) {
            history = <Tree iconClass="fas fa-history" title={this.context.intl.messages['history.browse']}>
                <Item href={'/history?'} permission={SessionStore.PERMISSIONS.HISTORY.BROWSE}>{this.context.intl.messages['history-tab-title']}</Item>
                <Item iconClass="fa-solid fa-question-circle" href={'/history/help'} permission={SessionStore.PERMISSIONS.HISTORY.BROWSE}>{this.context.intl.messages['sidebar.help']}</Item>
            </Tree>;
        }

        let analytics;
        if (permissions.analytics.__showMenu) {
            analytics = (
                <Tree iconClass="fas fa-chart-line" title={this.context.intl.messages['sidebar.analytics']}>
                    <Item href={'/reports/scheduled'} permission={SessionStore.PERMISSIONS.ANALYTICS.REPORTS.BROWSE}>{this.context.intl.messages['sidebar.analytics.scheduled-reports']}</Item>
                    <Item href={'/reports/tracking'} permission={SessionStore.PERMISSIONS.ANALYTICS.REPORTS.BROWSE}>{this.context.intl.messages['sidebar.analytics.tracking-report']}</Item>
                    <Item iconClass="fa-solid fa-question-circle" href={'/reports/help'} permission={SessionStore.PERMISSIONS.ANALYTICS.REPORTS.BROWSE}>{this.context.intl.messages['sidebar.help']}</Item>
                </Tree>
            );
        }

        let integration;
        if (permissions.guilds.__showMenu || permissions.privacy.__showMenu) {
            integration = <Tree iconClass="fas fa-plug" title={'Integrations'}>
                {permissions.guilds.__showMenu && <Tree iconClass="fas" title={this.context.intl.messages['sidebar.guilds']}>
                    {permissions.guilds.browse && <Item href={'/guild'} permission={SessionStore.PERMISSIONS.GUILDS.BROWSE}>{this.context.intl.messages['sidebar.guilds.browse']}</Item>}
                    {permissions.guilds.create && <Item href={'/guild/create'} permission={SessionStore.PERMISSIONS.GUILDS.CREATE}>{this.context.intl.messages['sidebar.guilds.add']}</Item>}
                </Tree>}
                {permissions.privacy.__showMenu && <Tree iconClass="fas" title={this.context.intl.messages['sidebar.privacy']}>
                    {permissions.privacy.browse && <Item href={'/privacy'} permission={SessionStore.PERMISSIONS.PRIVACY_REQUESTS.BROWSE}>{this.context.intl.messages['sidebar.privacy.browse']}</Item>}
                    {permissions.privacy.create && <Item href={'/privacy/create'} permission={SessionStore.PERMISSIONS.PRIVACY_REQUESTS.CREATE}>{this.context.intl.messages['sidebar.create']}</Item>}
                </Tree>}
                {permissions.guilds.browse && <Item iconClass="fa-solid fa-question-circle" href={'/guild/help'} permission={SessionStore.PERMISSIONS.GUILDS.BROWSE}>{this.context.intl.messages['sidebar.help']}</Item>}
            </Tree>;
        }
        let events;
        if (permissions.events.__showMenu) {
            events = <Tree iconClass="fas fa-calendar-star" title={this.context.intl.messages['sidebar.events']}>
                <Item href={'/events'} permission={SessionStore.PERMISSIONS.EVENTS.BROWSE}>{this.context.intl.messages['sidebar.events.browse']}</Item>
                {permissions.events.create && <Item href={'/events/create'} permission={SessionStore.PERMISSIONS.EVENTS.CREATE}>{this.context.intl.messages['sidebar.events.add']}</Item>}
                <Item iconClass="fa-solid fa-question-circle" href={'/events/help'} permission={SessionStore.PERMISSIONS.EVENTS.BROWSE}>{this.context.intl.messages['sidebar.help']}</Item>
                {/* Hidden for now until the browse archived page is designed */}
                {/* {permissions.events.browseArchived && <Item href={'/events/archived'} permission={SessionStore.PERMISSIONS.EVENTS.BROWSE_ARCHIVED}>{this.context.intl.messages['sidebar.events.archived']}</Item>} */}
            </Tree>;
        }
        let scheduling;
        if (permissions.scheduling.__showMenu) {
            let documentBatches;
            if (permissions.scheduling.documentBatches.__showMenu) {
                documentBatches = <Tree iconClass="fas fa-file-alt" title={this.context.intl.messages['sidebar.scheduling.document']}>
                    {permissions.scheduling.documentBatches.browse && <Item href={'/scheduling/document'} permission={SessionStore.PERMISSIONS.SCHEDULING.DOCUMENT_BATCHES.BROWSE}>{this.context.intl.messages['sidebar.browse']}</Item>}
                    {permissions.scheduling.documentBatches.create && <Item href={'/scheduling/document/create'} permission={SessionStore.PERMISSIONS.SCHEDULING.DOCUMENT_BATCHES.CREATE}>&nbsp;{this.context.intl.messages['sidebar.create']}</Item>}
                </Tree>;
            }
            let imageBatches;
            if (permissions.scheduling.imageBatches.__showMenu) {
                imageBatches = <Tree iconClass="fas fa-camera" title={this.context.intl.messages['sidebar.scheduling.image']}>
                    {permissions.scheduling.imageBatches.browse && <Item href={'/scheduling/image'} permission={SessionStore.PERMISSIONS.SCHEDULING.IMAGE_BATCHES.BROWSE}>{this.context.intl.messages['sidebar.browse']}</Item>}
                    {permissions.scheduling.imageBatches.create && <Item href={'/scheduling/image/create'} permission={SessionStore.PERMISSIONS.SCHEDULING.IMAGE_BATCHES.CREATE}>&nbsp;{this.context.intl.messages['sidebar.create']}</Item>}
                </Tree>;
            }
            let titleBatches;
            if (permissions.scheduling.titleBatches.__showMenu) {
                titleBatches = <Tree iconClass="far fa-newspaper" title={this.context.intl.messages['sidebar.scheduling.title']}>
                    {permissions.scheduling.titleBatches.browse && <Item href={'/scheduling/title'} permission={SessionStore.PERMISSIONS.SCHEDULING.TITLE_BATCHES.BROWSE}>{this.context.intl.messages['sidebar.browse']}</Item>}
                    {permissions.scheduling.titleBatches.create && <Item href={'/scheduling/title/create'} permission={SessionStore.PERMISSIONS.SCHEDULING.TITLE_BATCHES.CREATE}>{this.context.intl.messages['sidebar.create']}</Item>}
                </Tree>;
            }
            let videoBatches;
            if (permissions.scheduling.videoBatches.__showMenu) {
                videoBatches = <Tree iconClass="fas fa-video" title={this.context.intl.messages['sidebar.scheduling.video']}>
                    {permissions.scheduling.videoBatches.browse && <Item href={'/scheduling/video'} permission={SessionStore.PERMISSIONS.SCHEDULING.VIDEO_BATCHES.BROWSE}>{this.context.intl.messages['sidebar.browse']}</Item>}
                    {permissions.scheduling.videoBatches.create && <Item href={'/scheduling/video/create'} permission={SessionStore.PERMISSIONS.SCHEDULING.VIDEO_BATCHES.CREATE}>{this.context.intl.messages['sidebar.create']}</Item>}
                </Tree>;
            }
            scheduling = <Tree iconClass="far fa-stopwatch" title={this.context.intl.messages['sidebar.scheduling']}>
                {documentBatches}
                {imageBatches}
                {videoBatches}
                {titleBatches}
                {permissions.scheduling.documentBatches.browse && <Item iconClass="fa-solid fa-question-circle" href={'/scheduling/help'} permission={SessionStore.PERMISSIONS.SCHEDULING.DOCUMENT_BATCHES.BROWSE}>{this.context.intl.messages['sidebar.help']}</Item>}
            </Tree>;
        }

        let hardacProcesses;
        let proxies;
        let timelines;
        let workspace;
        let hardacAI;
        let hardacSetup;

        if (permissions.hardac.__showMenu) {
            let viewingMode;

            if (permissions.hardac.processes.__showMenu) {
                hardacProcesses = (
                    <Tree iconClass="fas fa-tasks-alt" title={this.context.intl.messages['sidebar.hardac.processes']}>
                        {permissions.hardac.processes.browse && <Item href={'/hardac/processes?processed=false'} permission={SessionStore.PERMISSIONS.HARDAC.PROCESSES.BROWSE}>&nbsp;{this.context.intl.messages['sidebar.hardac.processes']}</Item>}
                        {permissions.hardac.processes.browseJobs && <Item href={'/hardac/jobs'} permission={SessionStore.PERMISSIONS.HARDAC.JOBS.BROWSE}>&nbsp;{this.context.intl.messages['sidebar.hardac.jobs']}</Item>}
                    </Tree>
                );
            }

            if (permissions.hardac.machineLearning.browse) {
                hardacAI = <Tree iconClass="fas fa-robot" title={this.context.intl.messages['sidebar.hardac.ai']}>
                    <Item href={'/hardac/machine-learning'} permission={SessionStore.PERMISSIONS.HARDAC.MACHINE_LEARNING.BROWSE}>&nbsp;{this.context.intl.messages['sidebar.hardac.ai.browse']}</Item>
                </Tree>;
            }

            if (permissions.hardac.configuration.edit) {
                hardacSetup = <Tree iconClass="fas fa-cog" title={this.context.intl.messages['sidebar.hardac.setup']}>
                    <Item
                        href={'/hardac/setup/configuration'}
                        permission={SessionStore.PERMISSIONS.HARDAC.CONFIGURATION.EDIT}>&nbsp;{this.context.intl.messages['sidebar.hardac.setup.configuration']}
                    </Item>
                </Tree>;
            }

            let hardacVideoDelivery;
            if (permissions.hardac.videoDelivery.browse) {
                hardacVideoDelivery = <Item href={'/hardac/video-inbox?operator=AND'} permission={SessionStore.PERMISSIONS.HARDAC.VIDEO_DELIVERY.BROWSE}>&nbsp;{this.context.intl.messages['sidebar.hardac.timelines.video-qc-inbox']}</Item>;
            }

            let hardacBrowseVideoTimelines;
            if (permissions.hardac.timelines.browse) {
                hardacBrowseVideoTimelines = <Item href={'/hardac/timelines?active-type=ACTIVE&asset-type=23&operator=AND'} permission={SessionStore.PERMISSIONS.HARDAC.TIMELINE.BROWSE}>&nbsp;{this.context.intl.messages['sidebar.hardac.timelines.browse']}</Item>;
            }

            if (permissions.hardac.proxies.browse) {
                proxies = <Tree iconClass="fas fa-tv-retro" title={this.context.intl.messages['sidebar.hardac.proxies']}>
                    <Item href={'/hardac/proxies?active-type=ACTIVE&video-source-content-type=Proxy&operator=AND'} permission={SessionStore.PERMISSIONS.HARDAC.PROXY.BROWSE}>{this.context.intl.messages['sidebar.hardac.proxies.browse']}</Item>
                </Tree>;
            }

            if (this.props.location.pathname.match('/hardac')) {
                viewingMode = <Tree iconClass="fas fa-lamp-desk" title={this.context.intl.messages['sidebar.viewing-mode']}>
                    <Item
                        onClick={LayoutActions.setTheme.bind(
                            // Eventually, when we allow Brainiac to switch modes, this will need to
                            // be changed to a component function to tell in which app is the user.
                            this, LayoutConstants.APPS.HARDAC, LayoutConstants.DARK_THEME
                        )}
                    >{this.context.intl.messages['sidebar.viewing-mode.dark']}</Item>
                    <Item
                        onClick={LayoutActions.setTheme.bind(
                            // Same as above.
                            this, LayoutConstants.APPS.HARDAC, LayoutConstants.LIGHT_THEME
                        )}
                    >{this.context.intl.messages['sidebar.viewing-mode.light']}</Item>
                </Tree>;

            }
            workspace = <Menu title={this.context.intl.messages['sidebar.work-space']}>
                <Tree iconClass="fas fa-solar-system" title={this.context.intl.messages['sidebar.system']}>
                    <Item href={DashboardActions.getDashboardLink(this.props.applyLastWeekFilter, this.props.savedFilters)}>{this.context.intl.messages['sidebar.brainiac']}</Item>
                    <Item href={'/hardac/video-inbox?operator=AND'}>{this.context.intl.messages['sidebar.hardac']}</Item>
                </Tree>
                {viewingMode}
            </Menu>;

            timelines = <Tree iconClass="fas fa-stream" title={this.context.intl.messages['sidebar.hardac.timelines']}>
                {hardacVideoDelivery}
                {hardacBrowseVideoTimelines}
            </Tree>;
        }

        let brainiacNavigationCaption = this.context.intl.messages['sidebar.site-navigation'];
        let brainiacNavigationIsCollapsible;
        let hardacNavigation;
        let brainiacNavigation = <Menu collapsible={brainiacNavigationIsCollapsible} title={brainiacNavigationCaption}>
            {titles}
            {scheduling}
            {assets}
            {catalogs}
            {talent}
            {accounts}
            {groups}
            {roles}
            {brands}
            {publishing}
            {pushNotifications}
            {stations}
            {presets}
            {analytics}
            {integration}
            {events}
            {setup}
            {history}
            {helpVideos}
        </Menu>;

        let starred;
        const userId = this.state.user.get('id');
        const starredItems = this.state.starred.filter(item => item.get('userId') === userId);
        if (!this.props.collapsed && starredItems.size) {
            starred = (
                <Menu title={this.context.intl.messages['sidebar.starred']}>
                    <Tree expanded={true} iconClass="fas fa-thumbtack" title={this.context.intl.messages['sidebar.favorites']}>
                        {
                            starredItems.reduce((itemsToShow, item, index) => {
                                if (SessionStore.canUser(item.get('permission')) || item.get('permission') === undefined) {
                                    itemsToShow = [
                                        ...itemsToShow,
                                        <Item key={index} href={item.get('path')} className="starred-item">{item.get('title')}</Item>
                                    ];
                                }
                                return itemsToShow;
                            }, [])
                        }
                    </Tree>
                </Menu>
            );
        }

        if (this.props.location.pathname.match('/hardac')) {
            hardacNavigation = <Menu title={this.context.intl.messages['sidebar.hardac-navigation']}>
                {timelines}
                {proxies}
                {hardacProcesses}
                {hardacAI}
                {hardacSetup}
            </Menu>;

            brainiacNavigationIsCollapsible = true;
            brainiacNavigationCaption = this.context.intl.messages['sidebar.brainiac-navigation'];
            brainiacNavigation = null;
        }

        return (
            <aside className={ClassNames('main-sidebar', 'B(0)', {'Ovx(h)':!this.props.collapsed}, {'Ovy(a)':!this.props.collapsed})}>
                <section className="sidebar">
                    {starred}
                    {workspace}
                    {hardacNavigation}
                    {brainiacNavigation}
                </section>
            </aside>
        );
    }
}

export default Container.create(Sidebar);
export {Sidebar as Sidebar_BASE};
