/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {GroupConstants} from './group-actions';

import {AssetConstants} from '~/src/assets/asset-actions';
import Validations from '~/src/common/validations/validations';
import Dispatcher from '~/src/dispatcher/dispatcher';
import {ActionHistoryConstants} from '~/src/system/action-history/action-history-actions';
import {TitleConstants} from '~/src/titles/title-actions';
import {UserConstants} from '~/src/user/user-actions';

/**
 * Look for a constant with id === objectId in a map.
 */
const findInConstant = function(constants, objectId) {
    return Immutable.fromJS(
        Object.keys(constants)
            .map(k => constants[k])
            .filter(obj => objectId === obj.id)[0]
    );
};

const AssetCatalogsValidations = {
    name: {label: 'catalogs.name', validations: [Validations.missing()]},
    assetType: {label: 'catalogs.asset.type', validations: [Validations.missing()]},
    description: {label: 'catalogs.description', validations: [Validations.missing()]}
};

const LanguageCatalogsValidations = {
    name: {label: 'catalogs.name', validations: [Validations.missing()]},
    description: {label: 'catalogs.description', validations: [Validations.missing()]}
};

const StationGroupValidations = {
    name: {label: 'stations.create.info.name', validations: [Validations.missing(), Validations.max(90)]}
};

const TitleCatalogsValidations = {
    name: {label: 'catalogs.name', validations: [Validations.missing()]},
    titleCatalogType: {label: 'catalogs.type', validations: [Validations.missing()]},
    description: {label: 'catalogs.description', validations: [Validations.missing()]}
};

const UserGroupValidations = {
    name: {label: 'groups.user.create.info.name', validations: [Validations.missing(), Validations.max(90)]},
    description: {label: 'groups.user.create.info.description', validations: [Validations.missing()]}
};

class GroupStore extends ReduceStore {

    getAssetType(assetTypeId) {
        return findInConstant(AssetConstants.ASSET_TYPES, assetTypeId);
    }

    getCatalogType(id) {
        return findInConstant(TitleConstants.TITLE_CATALOG_TYPES, id);
    }

    getClassification(id) {
        return findInConstant(UserConstants.CLASSIFICATION_TYPE, id || 0);
    }

    getInitialState() {
        let state = Immutable.Map({
            assetType: Immutable.fromJS(AssetConstants.toArray('ASSET_TYPES')),
            classificationTypes: Immutable.fromJS(UserConstants.toArray('CLASSIFICATION_TYPE')),
            titleCatalogType:Immutable.fromJS(TitleConstants.toJSArray('TITLE_CATALOG_TYPES')),
            asset: Immutable.Map({
                filters: Immutable.Map(),
                group: Immutable.Map({
                    isActive: true
                }),
                groupAssets: Immutable.List(),
                groups: Immutable.List(),
                history: Immutable.List(),
                offset: 0,
                originalGroupAssets: Immutable.List(),
                size: 0,
                totalCount: 0,
            }),
            isLoading: false,
            partners: Immutable.List(),
            title: Immutable.Map({
                filters: Immutable.Map(),
                group: Immutable.Map({
                    isActive: true,
                    titleCatalogType: TitleConstants.TITLE_CATALOG_TYPES.Regular.id
                }),
                groupTitles: Immutable.List(),
                groups: Immutable.List(),
                history: Immutable.List(),
                offset: 0,
                size: 0,
                totalCount: 0
            }),
            language: Immutable.Map({
                filters: Immutable.Map(),
                group: Immutable.Map({
                    isActive: true
                }),
                history: Immutable.List(),
                groupTitles: Immutable.List(),
                groups: Immutable.List(),
                offset: 0,
                size: 0,
                totalCount: 0
            }),
            station: Immutable.Map({
                filters: Immutable.Map(),
                group: Immutable.Map({
                    assetGroups: Immutable.OrderedSet(),
                    brandId: null,
                    description: '',
                    isActive: true,
                    name: '',
                    titleGroups: Immutable.OrderedSet(),
                    stationGroups: Immutable.List(), // Only utilized for station groups (see STUDIO-8962)
                }),
                history: Immutable.List(),
                groups: Immutable.List(),
                offset: 0,
                size: 0,
                total: 0
            }),
            user: Immutable.Map({
                filters: Immutable.Map(),
                group: Immutable.Map({
                    assetGroups: Immutable.OrderedSet(),
                    brandId: null,
                    criteria: Immutable.fromJS({
                        companies: [],
                        territories: [],
                        groups: []
                    }),
                    description: '',
                    isActive: true,
                    name: '',
                    titleGroups: Immutable.OrderedSet(),
                }),
                groups: Immutable.List(),
                history: Immutable.List(),
                offset: 0,
                size: 0,
                total: 0
            }),
            group: Immutable.Map({
                userGroups: Immutable.List()
            }),
            showPreloader: false,
        });

        state = state.setIn(['asset', 'originalGroup'], state.get('asset').get('group'));
        state = state.setIn(['language', 'originalGroup'], state.get('language').get('group'));
        state = state.setIn(['title', 'originalGroup'], state.get('title').get('group'));
        state = state.setIn(['user', 'originalGroup'], state.get('user').get('group'));
        state = state.setIn(['station', 'originalGroup'], state.get('station').get('group'));
        return state;
    }

    getAssetsValidations() {
        return Validations.validate(this.getState().get('asset').get('group'), AssetCatalogsValidations);
    }

    getLanguageValidations() {
        return Validations.validate(this.getState().get('language').get('group'), LanguageCatalogsValidations);
    }

    getStationValidations() {
        return Validations.validate(this.getState().get('station').get('group'), StationGroupValidations);
    }

    getTitlesValidations() {
        return Validations.validate(this.getState().get('title').get('group'), TitleCatalogsValidations);
    }

    getUserGroupValidations() {
        return Validations.validate(this.getState().get('user').get('group'), UserGroupValidations);
    }

    reduce(state, action) {
        switch (action.actionType) {
        case ActionHistoryConstants.ADD_NOTE.SUCCESS:
            if (action.actionObjectType === ActionHistoryConstants.ACTION_OBJECTS.GROUP) {
                state = state.updateIn([action.groupType, 'history'], (history) => {
                    return history.unshift(action.note);
                });
            }
            break;
        case GroupConstants.CLEAR:
            state = this.getInitialState();
            break;

        case GroupConstants.LOAD:
            state = state.merge({
                activePage: Math.ceil(action.offset/action.size),
                offset: action.offset,
                size: action.size,
                total: action.total,
                totalPages: Math.ceil(action.total/action.size),
                userGroups: action.groups,
            });
            break;

        case GroupConstants.SET.FILTER:
            state = state.setIn([action.type.name, 'filters', action.attr], action.value);
            break;

        case GroupConstants.ASSET.GROUP.ADD:
            let itemsToAdd = [];
            action.groupAssets.forEach(item => {
                if (state.getIn(['asset', 'groupAssets']).findIndex(i => item.id === i.get('id')) === -1) {
                    itemsToAdd.push(item);
                }
            });
            state = state.updateIn(['asset', 'groupAssets'], items => items.concat(Immutable.fromJS(itemsToAdd)));
            break;

        /**
         * These cases load lists of Groups.
         */
        case GroupConstants.ASSET.GET.SUCCESS:
            state = this.setGroups(state, 'asset', action);
            break;

        case GroupConstants.LANGUAGE.GET.SUCCESS:
            state = this.setGroups(state, 'language', action);
            break;

        case GroupConstants.STATION.GET.ERROR:
            state = state.set('showPreloader', false);
            break;

        case GroupConstants.STATION.GET.START:
            state = state.set('showPreloader', true);
            break;

        case GroupConstants.STATION.GET.SUCCESS:
            state = this.setGroups(state, 'station', action);
            state = state.set('showPreloader', false);
            break;

        case GroupConstants.STATION_GROUP.ADD_STATION:
            state = state.updateIn(['station', 'group', 'stationGroups'], (stations) => {
                return stations.push(action.station);
            });
            break;

        case GroupConstants.STATION_GROUP.REMOVE_STATION:
            state = state.removeIn(['station', 'group', 'stationGroups', action.index]);
            break;

        case GroupConstants.TITLE.GET.SUCCESS:
            state = this.setGroups(state, 'title', action);
            break;

        case GroupConstants.USER.GET.START:
            state = state.set('isLoading', true);
            break;

        case GroupConstants.USER.GET.SUCCESS:
            state = this.setGroups(state, 'user', action);
            state = state.set('isLoading', false);
            break;

        case GroupConstants.GET_GROUP_ASSETS.SUCCESS:
            state = state.mergeIn(['asset'], {
                offset: action.offset,
                groupAssets: action.groupAssets,
                originalGroupAssets: action.groupAssets,
                size: action.size,
                totalCount: action.totalCount
            });
            break;

        case GroupConstants.GET_GROUP_PARTNERS.START:
            state = state.set('isLoading', true);
            break;

        case GroupConstants.GET_GROUP_PARTNERS.SUCCESS:
            state = state.merge({
                isLoading: false,
                partners: action.partners
            });
            break;

        case GroupConstants.GET_GROUP_PARTNERS.ERROR:
            state = state.set('isLoading', false);
            break;

        case GroupConstants.GET_GROUP_TITLES.SUCCESS:
            state = state.mergeIn(['title'], {
                offset: action.offset,
                groupTitles: action.groupTitles,
                size: action.size,
                totalCount: action.totalCount
            });
            break;
        case GroupConstants.GET_CATALOG_USER_GROUPS.SUCCESS:
            state = state.mergeIn(['group'], {
                userGroups: action.userGroups
            });
            break;
        case GroupConstants.GET_GROUP_LANGUAGES.START:
            state = state.set('isLoading', true);
            break;

        case GroupConstants.GET_GROUP_LANGUAGES.ERROR:
            state = state.set('isLoading', false);
            break;

        case GroupConstants.GET_GROUP_LANGUAGES.SUCCESS:
            state = state.mergeIn(['language'], {
                offset: action.offset,
                groupTitles: action.groupTitles,
                size: action.size,
                totalCount: action.totalCount
            });
            break;

        /**
         * These cases load just one Group for edit.
         */
        case GroupConstants.ASSET.GROUP.GET.START:
            state = state.merge({showPreloader: true});
            break;

        case GroupConstants.ASSET.GROUP.GET.SUCCESS:
            state = state.mergeIn(['asset'], {
                assetType: Immutable.fromJS(AssetConstants.toArray('ASSET_TYPES')),
                group: action.group,
                originalGroup: action.group,
                history: action.history
            });
            state = state.merge({showPreloader: false});
            break;

        case GroupConstants.ASSET.GROUP.GET.ERROR:
            state = state.merge({showPreloader: false});
            break;

        case GroupConstants.LANGUAGE.GROUP.GET.START:
            state = state.merge({showPreloader: true});
            break;

        case GroupConstants.LANGUAGE.GROUP.GET.SUCCESS:
            state = state.setIn(['language', 'group'], action.group);
            state = state.setIn(['language', 'originalGroup'], action.group);
            state = state.setIn(['language', 'history'], action.history);
            state = state.merge({showPreloader: false});
            break;

        case GroupConstants.LANGUAGE.GROUP.GET.ERROR:
            state = state.merge({showPreloader: false});
            break;

        case GroupConstants.STATION.GROUP.GET.START:
            state = state.merge({showPreloader: true});
            break;

        case GroupConstants.STATION.GROUP.GET.SUCCESS:
            state = state.setIn(['station', 'group'], action.group);
            state = state.setIn(['station', 'originalGroup'], action.group);
            state = state.setIn(['station', 'history'], action.history);
            state = state.merge({showPreloader: false});
            break;

        case GroupConstants.STATION.GROUP.GET.ERROR:
            state = state.merge({showPreloader: false});
            break;

        case GroupConstants.TITLE.GROUP.GET.START:
            state = state.merge({showPreloader: true});
            break;

        case GroupConstants.TITLE.GROUP.GET.SUCCESS:
            state = state.setIn(['title', 'group'], action.group);
            state = state.setIn(['title', 'originalGroup'], action.group);
            state = state.setIn(['title', 'history'], action.history);
            state = state.merge({showPreloader: false});
            break;

        case GroupConstants.TITLE.GROUP.GET.ERROR:
            state = state.merge({showPreloader: false});
            break;

        case GroupConstants.USER.GROUP.GET.START:
            state = state.merge({showPreloader: true});
            break;

        case GroupConstants.USER.GROUP.GET.SUCCESS:
            state = state.setIn(['user', 'group'], action.userGroup);
            state = state.setIn(['user', 'originalGroup'], action.userGroup);
            state = state.setIn(['user', 'history'], action.history);
            state = state.merge({showPreloader: false});
            break;

        case GroupConstants.USER.GROUP.GET.ERROR:
            state = state.merge({showPreloader: false});
            break;

        /**
         * Save group data
         */
        case GroupConstants.ASSET.GROUP.SAVE.START:
            state = state.merge({showPreloader: true});
            break;

        case GroupConstants.ASSET.GROUP.SAVE.SUCCESS:
            state = state.merge({showPreloader: false});
            state = state.setIn(['asset', 'originalGroup'], state.getIn(['asset', 'group']));
            break;

        case GroupConstants.ASSET.GROUP.SAVE.ERROR:
            state = state.merge({showPreloader: false});
            break;

        case GroupConstants.LANGUAGE.GROUP.SAVE.START:
            state = state.merge({showPreloader: true});
            break;

        case GroupConstants.LANGUAGE.GROUP.SAVE.SUCCESS:
            state = state.merge({showPreloader: false});
            state = state.setIn(['language', 'originalGroup'], state.getIn(['language', 'group']));
            break;

        case GroupConstants.LANGUAGE.GROUP.SAVE.ERROR:
            state = state.merge({showPreloader: false});
            break;

        case GroupConstants.TITLE.GROUP.SAVE.START:
            state = state.merge({showPreloader: true});
            break;

        case GroupConstants.TITLE.GROUP.SAVE.SUCCESS:
            state = state.merge({showPreloader: false});
            state = state.setIn(['title', 'originalGroup'], state.getIn(['title', 'group']));
            break;

        case GroupConstants.TITLE.GROUP.SAVE.ERROR:
            state = state.merge({showPreloader: false});
            break;

        case GroupConstants.USER.GROUP.SAVE.START:
            state = state.merge({showPreloader: true});
            break;

        case GroupConstants.USER.GROUP.SAVE.SUCCESS:
            // CHECK: to see if this is actually dispatched
            state = state.merge({showPreloader: false});
            state = state.setIn(['user', 'originalGroup'], state.getIn(['user', 'group']));
            break;

        case GroupConstants.USER.GROUP.SAVE.ERROR:
            state = state.merge({showPreloader: false});
            break;

        /**
         * Update a value in a group.
         */
        case GroupConstants.ASSET.GROUP.UPDATE:
            state = state.setIn(['asset', 'group', ...action.attr.split('.')], action.value);
            break;

        case GroupConstants.LANGUAGE.GROUP.UPDATE:
            state = state.setIn(['language', 'group', ...action.attr.split('.')], action.value);
            break;

        case GroupConstants.TITLE.GROUP.UPDATE:
            state = state.setIn(['title', 'group', ...action.attr.split('.')], action.value);
            break;

        case GroupConstants.USER.GROUP.UPDATE:
            state = state.setIn(['user', 'group', ...action.attr.split('.')], action.value);
            break;

        case GroupConstants.STATION.GROUP.UPDATE:
            state = state.setIn(['station', 'group', ...action.attr.split('.')], action.value);
            break;

        /**
         * Clone a group
         */
        case GroupConstants.USER.GROUP.CLONE:
            state = state.setIn(['user', 'originalGroup'], state.get('user').get('group'));
            break;

        case GroupConstants.STATION.GROUP.CLONE:
            state = state.setIn(['station', 'originalGroup'], state.get('station').get('group'));
            break;

        case AssetConstants.ASSET.CATALOG.GET.SUCCESS:
            let indexAsset = state.getIn(['asset', 'groupAssets']).findIndex(t => t.get('id') === action.assetId);
            state = state.setIn(['asset', 'groupAssets', indexAsset, 'catalogs'], action.catalogs);
            break;

        case TitleConstants.TITLE.CATALOGS.GET.SUCCESS:
            let indexTitle = state.getIn(['title', 'groupTitles']).findIndex(t => t.get('id') === action.titleId);
            state = state.setIn(['title', 'groupTitles', indexTitle, 'catalogs'], action.catalogs);
            break;
        }

        return state;
    }

    setGroups(state, type, action) {
        return state.mergeIn([type], {
            offset: action.offset,
            groups: action.groups,
            size: action.size,
            total: action.total
        });
    }
}

let store = new GroupStore(Dispatcher);

export default store;

export {
    AssetCatalogsValidations,
    TitleCatalogsValidations,
    UserGroupValidations,
    LanguageCatalogsValidations,
    StationGroupValidations,
};
