/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';

import TitledBlock from '../common/titled-block';

type Props = {
    video: ImmutableMap<PlayableVideoAsset>
}

export default function VideoAssetBlock(props: Props) {
    return (
        <TitledBlock title="cc-editor.tab.settings.video-asset" icon="far fa-video">
            <a
                target="_blank"
                href={`/assets/video/${props.video.get('id')}`}
            >
                {props.video.get('assetName')}
                &nbsp;
                <i className="fas fa-external-link" />
            </a>
        </TitledBlock>
    );
}
