/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */


import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Col, ControlLabel, FormGroup, Row} from 'react-bootstrap';

import {SubscriptionsActions} from './subscriptions-actions';
import SubscriptionsStore from './subscriptions-store';
import BaseSelect from '../../../common/base-select/base-select';
import SearchBox from '../../../common/search-box/search-box';
import {WithStoreOnRoute} from '../../../common/store-on-route';
import {Debounce} from '../../../common/utils/utils';
import ListTitles from '../../../titles/browse/list-titles';
import {TitleActions} from '../../../titles/title-actions';
import TitleStore from '../../../titles/title-store';

class AddTitlePanel extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            onChangeSubscriptionType: PropTypes.func,
            selectedSubscriptionType: PropTypes.object
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            subscriptionType: SubscriptionsStore.getState().get('subscriptionType'),
            titlesToAdd: SubscriptionsStore.getState().get('titlesToAdd'),
            titles: TitleStore.getState().get('titles'),
            total: TitleStore.getState().get('total')
        };
    }

    static getStores() {
        return [SubscriptionsStore, TitleStore];
    }

    static get defaultProps() {
        return {
            onChangeSubscriptionType: undefined,
            selectedSubscriptionType: undefined
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.handleSelectSubscriptionType = this.handleSelectSubscriptionType.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
        this.handleSortChange = this.handleSortChange.bind(this);
    }

    componentDidMount() {
        TitleActions.get(this.getRouteState());
        return;
    }

    componentWillUpdate(nextProps) {
        if (this.props.location.query !== nextProps.location.query) {
            TitleActions.get(this.getRouteState(nextProps));
        }
        return;
    }

    componentWillUnmount() {
        this.props.onChangeSubscriptionType(null);
        this.clearRouteState('title').apply();
    }

    /**
     * Handle clicks on pagination controls.
     * @param  {Number} pageNumber page to load.
     */
    handleLoadPage(pageNumber) {
        // pageNumber x this.state.size = offset.
        this.setRouteState('offset', pageNumber * 20)
            .apply();
        return;
    }

    handleRowClick(event) {
        let title;
        switch (true) {
        case !!~event.target.className.indexOf('add-title'):
            title = TitleStore.getState().get('titles').find(t => t.get('id') === parseInt(event.target.getAttribute('data-title-id'), 10)).toJS();
            SubscriptionsActions.addTitle(title);
            break;
        case !!~event.target.className.indexOf('fa-plus'):
            title = TitleStore.getState().get('titles').find(t => t.get('id') === parseInt(event.target.parentNode.getAttribute('data-title-id'), 10)).toJS();
            SubscriptionsActions.addTitle(title);
            break;
        case !!~event.target.className.indexOf('remove-title'):
            SubscriptionsActions.removeTitle(parseInt(event.target.getAttribute('data-title-id'), 10));
            break;
        case !!~event.target.className.indexOf('fa-times'):
            SubscriptionsActions.removeTitle(parseInt(event.target.parentNode.getAttribute('data-title-id'), 10));
            break;

        }
        return;
    }

    handleSelectSubscriptionType(value) {
        this.props.onChangeSubscriptionType(value);
        return;
    }

    handleSearchTerm(term) {
        this.setRouteState('title', term)
            .clearRouteState('offset')
            .apply();
        return;
    }

    handleSortChange(sortFieldName, sortDirection) {
        this.setRouteState('sort-field-name', sortFieldName)
            .setRouteState('sort-direction', sortDirection)
            .apply();
        return;
    }

    render() {
        let columns = ['mpmNumber', 'title', 'titleReleaseDate', 'categoryName', 'catalogs', {
            name: 'actions',
            get: function(title, props, context) {
                return `<button
                    class="btn btn-primary add-title"
                    data-title-id="${title.get('id')}"><i class="fas fa-plus"></i>&nbsp;${context.intl.messages['accounts.create.subscriptions.add.btn']}</button>`;
            },
            onClick: this.handleRowClick
        }];
        let columnsToAdd = ['mpmNumber', 'title', 'titleReleaseDate', 'categoryName', 'catalogs', {
            name: 'actions',
            get: function(title, props, context) {
                return `<button
                    class="btn btn-primary remove-title"
                    data-title-id="${title.get('id')}"><i class="fas fa-times"></i>&nbsp;${context.intl.messages['accounts.create.subscriptions.remove.btn']}</button>`;

            },
            onClick: this.handleRowClick
        }];
        let titles = this.state.titles.filter(t => {
            return !this.state.titlesToAdd.find(titleToAdd => t.get('id') === titleToAdd.get('id'));
        });
        return (
            <div>
                <Row>
                    <Col mdOffset={3} md={6}>
                        <FormGroup>
                            <ControlLabel><h3><i className="fas fa-share-alt"></i>&nbsp;{this.context.intl.messages['accounts.create.subscriptions.add.type']}</h3></ControlLabel>
                            <BaseSelect
                                disabled={false}
                                name="subscriptionType"
                                onChange={this.handleSelectSubscriptionType}
                                options="subscriptionType"
                                store={SubscriptionsStore}
                                value={this.props.selectedSubscriptionType}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <hr/>
                <h3>{this.context.intl.messages['accounts.create.subscriptions.titles.to-add']}</h3>
                <ListTitles
                    columns={columnsToAdd}
                    onSortChange={this.handleSortChange}
                    sortDirection={this.getRouteState().get('sort-direction') || 'desc'}
                    sortFieldName={this.getRouteState().get('sort-field-name') || 'updatedDate'}
                    tableId="list-titles-to-add-table"
                    titles={this.state.titlesToAdd}
                />
                <hr/>
                <h3>{this.context.intl.messages['accounts.create.subscriptions.titles']}</h3>
                <div className="col-md-6 col-md-offset-3 show-grid add-subscription-panel">
                    <SearchBox onChange={this.handleSearchTerm} value={this.getRouteState().get('title')}/>
                </div>
                <ListTitles
                    activePage={Math.ceil((this.getRouteState().get('offset') || 0) / 20 ) || 0}
                    columns={columns}
                    onPageChange={this.handleLoadPage}
                    onSortChange={this.handleSortChange}
                    sortDirection={this.getRouteState().get('sort-direction') || 'desc'}
                    sortFieldName={this.getRouteState().get('sort-field-name') || 'updatedDate'}
                    titles={titles}
                    totalPages={Math.ceil(this.state.total/20) || 0}
                />
            </div>
        );
    }

}

export default WithStoreOnRoute(
    Container.create(AddTitlePanel)
);
