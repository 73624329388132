/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React from 'react';

import {FormatImageSrc} from '../common/utils/utils';

const ImageLoader = ({src, placeholder, ...other}) => {
    let element;

    /* istanbul ignore next */
    const changeToPlaceholder = () => {
        if (element.src === placeholder) {return;}

        element.src = placeholder;
    };

    if (!src) {
        src = placeholder;
    }

    src = FormatImageSrc(src);

    /* istanbul ignore next */
    return (
        <img
            onContextMenu={e => e.preventDefault()}
            onError={changeToPlaceholder}
            ref={el => element = el}
            src={src}
            {...other}
        />
    );
};

ImageLoader.propTypes = {
    placeholder: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired
};

export default ImageLoader;
