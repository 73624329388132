/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import QueryString from 'querystring-es3';

import {RouterActions} from '../../router/router-actions';

export default {
    getRouteState(props) {
        let p = props || this.props;
        let t = Immutable.Map();

        if (p.location.query) {
            t = Immutable.fromJS(p.location.query);
        }

        return t;
    },

    __state() {
        if (!this.__routeState) {
            this.__routeState = Immutable.Map();
        }
        return this.__routeState;
    },

    setRouteState(attr, value) {
        this.__routeState = this.__state().set(attr, value);
        return this;
    },

    clearRouteState(attr) {
        this.__routeState = this.__state().set(attr, '');
        return this;
    },

    addToSetRoute(attr, value) {
        let arr = this.__state().get(attr);
        if (!arr) {
            let query = this.props.location.query;
            if (!query[attr]) {
                // set not found
                arr = Immutable.Set();
            } else if (!Array.isArray(query[attr])) {
                // found but not array (single value)
                arr = Immutable.Set([query[attr]]);
            } else {
                // Array IUJU!
                arr = Immutable.fromJS(query[attr]).toSet();
            }
        }
        this.__routeState = this.__state().set(attr, arr.add(value));
        return this;
    },

    readSetRoute(attr) {
        let arr = this.__state().get(attr);
        if (!arr) {
            let query = this.props.location.query;
            if (!query[attr]) {
                // set not found
                arr = Immutable.Set();
            } else if (!Array.isArray(query[attr])) {
                // found but not array (single value)
                arr = Immutable.Set([query[attr]]);
            } else {
                // Array IUJU!
                arr = Immutable.fromJS(query[attr]).toSet();
            }
        }
        return arr;
    },

    removeFromSetRoute(attr, value) {
        let arr = this.__state().get(attr);
        // if empty load from current route :)
        if (!arr) {
            let query = this.props.location.query;
            if (!query[attr]) {
                // set not found on bulk nor on current route
                // nothing to do
                return this;
            }
            if (!Array.isArray(query[attr])) {
                // last one check if match
                if (query[attr] === value) {
                    // mark for remotion
                    return this.clearRouteState(attr);
                }
                return this; // not mach do nothing
            }
            arr = Immutable.fromJS(query[attr]).toSet();
        }
        this.__routeState = this.__state().set(attr, arr.delete(value));
        return this;
    },


    apply(ignore) {
        let toRemove = this.__state().filter( (v) => {return !v;}).map( (v, k) => {return k;}).toSet();
        let filtered = this.__state().filter( (v) => {return v;});
        let filteredQuery = this.props.location.query;
        if (ignore !== undefined) {
            filteredQuery = {};
            Object.keys(this.props.location.query).forEach( k => {
                if (ignore.indexOf(k) !== -1) {
                    filteredQuery[k] = this.props.location.query[k];
                }
            });
        }
        if (toRemove.size > 0) {
            filteredQuery = toRemove.reduce( (q, v) => {
                delete q[v];
                return q;
            }, filteredQuery);
        }
        let query = Object.assign(
            filteredQuery,
            filtered.toJS()
        );
        // clear buffer for next batch of operations
        this.__routeState = Immutable.Map();
        RouterActions.redirect(`${this.props.location.pathname}?${QueryString.stringify(query)}`);
        return;
    }

};

const WithStoreOnRoute = Base => {
    return class WithStoreOnRouteImpl extends Base {
        getRouteState(props) {
            let p = props || this.props;
            let t = Immutable.Map();

            if (p.location.query) {
                t = Immutable.fromJS(p.location.query);
            }

            return t;
        }

        __state() {
            if (!this.__routeState) {
                this.__routeState = Immutable.Map();
            }
            return this.__routeState;
        }

        setRouteState(attr, value) {
            this.__routeState = this.__state().set(attr, value);
            return this;
        }

        emptyRouteState() {
            RouterActions.redirect(this.props.location.pathname);
        }

        clearRouteState(attr) {
            this.__routeState = this.__state().set(attr, '');
            return this;
        }

        addToSetRoute(attr, value) {
            let arr = this.__state().get(attr);
            if (!arr) {
                let query = this.props.location.query;
                if (!query[attr]) {
                    // set not found
                    arr = Immutable.Set();
                } else if (!Array.isArray(query[attr])) {
                    // found but not array (single value)
                    arr = Immutable.Set([query[attr]]);
                } else {
                    // Array IUJU!
                    arr = Immutable.fromJS(query[attr]).toSet();
                }
            }
            this.__routeState = this.__state().set(attr, arr.add(value));
            return this;
        }

        readSetRoute(attr) {
            let arr = this.__state().get(attr);
            if (!arr) {
                let query = this.props.location.query;
                if (!query[attr]) {
                    // set not found
                    arr = Immutable.Set();
                } else if (!Array.isArray(query[attr])) {
                    // found but not array (single value)
                    arr = Immutable.Set([query[attr]]);
                } else {
                    // Array IUJU!
                    arr = Immutable.fromJS(query[attr]).toSet();
                }
            }
            return arr;
        }

        removeFromSetRoute(attr, value) {
            let arr = this.__state().get(attr);
            // if empty load from current route :)
            if (!arr) {
                let query = this.props.location.query;
                if (!query[attr]) {
                    // set not found on bulk nor on current route
                    // nothing to do
                    return this;
                }
                if (!Array.isArray(query[attr])) {
                    // last one check if match
                    if (query[attr] === value) {
                        // mark for remotion
                        return this.clearRouteState(attr);
                    }
                    return this; // not mach do nothing
                }
                arr = Immutable.fromJS(query[attr]).toSet();
            }
            this.__routeState = this.__state().set(attr, arr.delete(value));
            return this;
        }


        apply(ignore) {
            let toRemove = this.__state().filter( (v) => {return !v;}).map( (v, k) => {return k;}).toSet();
            let filtered = this.__state().filter( (v) => {return v;});
            let filteredQuery = this.props.location.query;
            if (ignore !== undefined) {
                filteredQuery = {};
                Object.keys(this.props.location.query).forEach( k => {
                    if (ignore.indexOf(k) !== -1) {
                        filteredQuery[k] = this.props.location.query[k];
                    }
                });
            }
            if (toRemove.size > 0) {
                filteredQuery = toRemove.reduce( (q, v) => {
                    delete q[v];
                    return q;
                }, filteredQuery);
            }
            let query = Object.assign(
                filteredQuery,
                filtered.toJS()
            );
            // clear buffer for next batch of operations
            this.__routeState = Immutable.Map();
            RouterActions.redirect(`${this.props.location.pathname}?${QueryString.stringify(query)}`);
            return;
        }

    };
};

export {
    WithStoreOnRoute
};
