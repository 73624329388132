/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {GetDeliverableFormatData} from '@wbdt-sie/brainiac-web-common';
import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ControlLabel, Modal, FormControl, FormGroup, Button} from 'react-bootstrap';
import ReactSelect from 'react-select';

import Captions from './captions';
import VideoAssetFormatLabel from './video-asset-format-label';
import {AssetConstants} from '../../../asset-actions';
import AssetStore from '../../../asset-store';
import {VideoActions} from '../../video-actions';
import VideoStore, {VideoValidations} from '../../video-store';

import AssetRights from '~/src/common/asset-rights/asset-rights';
import {AssetRightsConstants} from '~/src/common/asset-rights/asset-rights-constants';
import BaseSelect from '~/src/common/base-select/base-select';
import {FormItem, FormRow, FormSection} from '~/src/common/form/form';
import {SectionPanel} from '~/src/common/panel/panel';
import TinyMCE from '~/src/common/tinymce/tinymce';
import {CompareImmutable, IsHardacLocation} from '~/src/common/utils/utils';
import {WithValidations} from '~/src/common/validations/validations';
import config from '~/src/config/config.js';
import {RouterActions} from '~/src/router/router-actions';
import SessionStore from '~/src/user/session/session-store';

// TODO: this page started to be slow we should cut it into pieces to Enable
// shouldComponentUpdate to do it's work

/**
 * Generic Select event handler.
 * TODO: use the generic one.
 */
const handleSelect = (attr) => {
    return (value) => {
        if (value === null) {
            VideoActions.update(attr, null);
            return;
        }
        VideoActions.update(attr, value.id);
        return;
    };
};

/**
 * Video props to watch.
 */
const videoProps = [
    'active', 'assetComment', 'assetName', 'contentType', 'copyrightText', 'copyrightYear',
    'creditStartSeconds', 'deliveryType', 'disableVisualWatermark', 'disclaimerText',
    'forensicUrlRequired', 'keyword', 'mfaRequired', 'mediaKey', 'persistentVisualWatermark',
    'referenceId', 'runtime', 'rightsNotes', 'streamingOnly', 'tapes', 'updatedBy', 'updatedDate',
    'videoRightsMediaType', 'videoRightsTermType', 'videoRightsTerritoryType', 'videoRightsType',
    'videoSynopsis', 'watermarkText', 'webVTTFiles'
];

class Summary extends Component {

    static get propTypes() {
        return {
            asset: PropTypes.instanceOf(Immutable.Map).isRequired,
            canEditCaptions: PropTypes.bool,
            disabled: PropTypes.bool,
            location: PropTypes.object.isRequired,
            newWebVttData: PropTypes.instanceOf(Immutable.Map).isRequired,
            mode: PropTypes.string.isRequired,
            originalAsset: PropTypes.instanceOf(Immutable.Map).isRequired,
            webVTTFiles: PropTypes.instanceOf(Immutable.List).isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            canEditCaptions: false,
            disabled: true
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            isAudioConfigTypeDirty: false,
            isContentTypeDirty: false,
            isDeliveryTypeDirty: false,
            isLanguageDirty: false,
            isMimeTypeDirty: false,
            selectedAudioConfigType: VideoStore.getAudioConfigType(this.props.asset.get('audioConfigType')),
            selectedContentType: VideoStore.getContentType(this.props.asset.get('contentType')),
            selectedDeliveryType: AssetStore.getDeliveryType(this.props.asset.get('deliveryType')),
            selectedLanguage: VideoStore.getLanguage(this.props.asset.get('language')),
            selectedMimeType: AssetStore.getAssetMimeType(AssetConstants.MIME_TYPES_VIDEO, this.props.asset.get('mimeType')),
            showGeorestrictionModal: false
        };

        this.handleEnableGeorestriction = this.handleEnableGeorestriction.bind(this);
        this.handleParentTimeline = this.handleParentTimeline.bind(this);
        this.handleSelectAudioConfigType = this.handleSelectAudioConfigType.bind(this);
        this.handleSelectContentType = this.handleSelectContentType.bind(this);
        this.handleSelectDeliveryType = this.handleSelectDeliveryType.bind(this);
        this.handleSelectLanguage = this.handleSelectLanguage.bind(this);
        this.handleSelectMimeType = this.handleSelectMimeType.bind(this);
        this.handleTinyMCEChange = this.handleTinyMCEChange.bind(this);
        this.hasStreamingURLs = this.hasStreamingURLs.bind(this);
        this.toggleGeorestrictionModal = this.toggleGeorestrictionModal.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            selectedAudioConfigType: VideoStore.getAudioConfigType(nextProps.asset.get('audioConfigType')),
            selectedContentType: VideoStore.getContentType(nextProps.asset.get('contentType')),
            selectedDeliveryType: AssetStore.getDeliveryType(nextProps.asset.get('deliveryType')),
            selectedLanguage: VideoStore.getLanguage(nextProps.asset.get('language')),
            selectedMimeType: AssetStore.getAssetMimeType(AssetConstants.MIME_TYPES_VIDEO, nextProps.asset.get('mimeType'))
        });
        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.disabled !== this.props.disabled ||
            nextProps.newWebVttData !== this.props.newWebVttData ||
            !CompareImmutable(nextProps.asset, this.props.asset, videoProps)) {
            return true;
        }

        if (nextState.selectedAudioConfigType !== this.state.selectedAudioConfigType ||
            nextState.selectedContentType !== this.state.selectedContentType ||
            nextState.selectedDeliveryType !== this.state.selectedDeliveryType ||
            nextState.selectedLanguage !== this.state.selectedLanguage ||
            nextState.showGeorestrictionModal !== this.state.showGeorestrictionModal ||
            nextState.selectedMimeType !== this.state.selectedMimeType) {
            return true;
        }

        return false;
    }

    handleEnableGeorestriction() {
        VideoActions.enableGeorestriction(this.props.asset.get('id'), this.props.asset.get('geo-region-id'));
        this.toggleGeorestrictionModal();
    }

    handleParentTimeline() {
        // Change URL depending if in hardac or brainiac
        let prefix = 'assets';
        if (IsHardacLocation(this.props.location)) {
            prefix = 'hardac';
        }

        let route = `/${prefix}/timeline/${this.props.asset.get('videoTimelineId')}`;
        RouterActions.redirect(route);
    }

    handleSelectAudioConfigType(value) {
        if (!this.state.isAudioConfigTypeDirty) {
            this.setState({isAudioConfigTypeDirty: true});
        }
        VideoActions.update('audioConfigType', Immutable.fromJS(value));
        return;
    }

    handleSelectContentType(value) {
        if (!this.state.isContentTypeDirty) {
            this.setState({isContentTypeDirty: true});
        }
        // TODO: call the generic function here.
        handleSelect('contentType')(value);
        return;
    }

    handleSelectDeliveryType(value) {
        if (!this.state.isDeliveryTypeDirty) {
            this.setState({isDeliveryTypeDirty: true});
        }
        handleSelect('deliveryType')(value);
        return;
    }

    handleSelectLanguage(value) {
        if (!this.state.isLanguageDirty) {
            this.setState({isLanguageDirty: true});
        }
        handleSelect('language')(value);
        return;
    }

    handleSelectTextless(option) {
        VideoActions.update('textless', option.textless);
        VideoActions.update('texted', option.texted);
        return;
    }

    handleSelectMimeType(value) {
        if (!this.state.isMimeTypeDirty) {
            this.setState({isMimeTypeDirty: true});
        }
        handleSelect('mimeType')(value);
        return;
    }

    handleTinyMCEChange(attr) {
        return function handler(e) {
            VideoActions.update(attr, e.target.getContent());
            return;
        };
    }

    hasStreamingURLs(asset) {
        return [
            'drmHlsUrl', 'drmHlsvthreeUrl', 'drmMpegDashUrl',
            'src', 'trickplayUrl'
        ].some(attr => !!asset.getIn(['streams', 0, attr]));
    }

    toggleGeorestrictionModal() {
        this.setState(prevState => ({
            showGeorestrictionModal: !prevState.showGeorestrictionModal
        }));
    }

    render() {
        let updatedDate = <div>N/A</div>;
        let updatedBy = <div>N/A</div>;
        let additionalInformation;
        let captions;
        let streaming;
        let streamingValidationState;
        let videoFileFormatData;
        let videoTimelineLink = <div/>;

        if (this.props.asset.get('videoTimelineId')) {
            videoTimelineLink = (
                <FormGroup md={6}>
                    <ControlLabel>{this.context.intl.messages['asset.video.hardac-source']}</ControlLabel>
                    <span style={{display: 'block'}}>
                        <Button
                            className="btn btn-default-outline btn-sm"
                            onClick={this.handleParentTimeline}
                        >
                            <i className="fas fa-stream"/>&nbsp;{this.context.intl.messages['asset.proxy.edit.parent-timeline']}
                        </Button>
                    </span>
                </FormGroup>
            );
        }

        const textlessOptions = [{
            textless: false,
            texted: true,
            name: this.context.intl.messages['asset.video.create.texted']
        }, {
            textless: true,
            texted: false,
            name: this.context.intl.messages['asset.video.create.textless']
        }, {
            textless: false,
            texted: false,
            name: '--'
        }];

        let selectedTextedValue = textlessOptions[0];

        if (!this.props.asset.get('texted') && this.props.asset.get('textless')) {
            selectedTextedValue = textlessOptions[1];
        } else if (!this.props.asset.get('texted') && !this.props.asset.get('textless')) {
            selectedTextedValue = textlessOptions[2];
        }

        if (this.props.mode === 'edit') {
            updatedBy = '-';
            updatedDate = '-';
            if (this.props.asset.get('updatedBy')) {
                updatedBy = this.props.asset.get('updatedBy');
            }
            if (this.props.asset.get('updatedDate')) {
                updatedDate = Moment(this.props.asset.get('updatedDate')).tz(config.DefaultTimezone).format(this.context.intl.messages['datetime-format']);
            }
            additionalInformation = <FormRow>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['asset.edit.last-modified-date']}</ControlLabel>
                    <FormControl.Static>{updatedDate}</FormControl.Static>
                </FormGroup>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['asset.edit.last-modifier']}</ControlLabel>
                    <FormControl.Static>{updatedBy}</FormControl.Static>
                </FormGroup>
            </FormRow>;

            captions = (
                <Captions
                    canEditCaptions={this.props.canEditCaptions}
                    disabled={this.props.disabled}
                    newWebVttData={this.props.newWebVttData}
                    webVTTFiles={this.props.webVTTFiles}
                />
            );

            if (this.hasStreamingURLs(this.props.asset)) {
                streamingValidationState = 'success';
            }

            streaming = (
                <div>
                    <SectionPanel
                        title={this.context.intl.messages['asset.video.edit.summary.streaming-urls.title']}
                        validationState={streamingValidationState}
                    >
                        <FormRow>
                            <FormGroup>
                                <FormItem
                                    attr="streams.0.drmHlsUrl"
                                    disabled={true}
                                    label="HLS"
                                    model={this.props.asset}
                                    setter={VideoActions.update}
                                    type="textarea"
                                />
                            </FormGroup>
                        </FormRow>
                        <FormRow>
                            <FormGroup>
                                <FormItem
                                    attr="streams.0.drmHlsvthreeUrl"
                                    disabled={true}
                                    label="HLS V3"
                                    model={this.props.asset}
                                    setter={VideoActions.update}
                                    type="textarea"
                                />
                            </FormGroup>
                        </FormRow>
                        <FormRow>
                            <FormGroup>
                                <FormItem
                                    attr="streams.0.drmMpegDashUrl"
                                    disabled={true}
                                    label="MPEGDash"
                                    model={this.props.asset}
                                    setter={VideoActions.update}
                                    type="textarea"
                                />
                            </FormGroup>
                        </FormRow>
                        <hr/>
                        <FormRow>
                            <FormGroup>
                                <FormItem
                                    attr="streams.0.src"
                                    disabled={true}
                                    label="Source"
                                    model={this.props.asset}
                                    setter={VideoActions.update}
                                    type="textarea"
                                />
                            </FormGroup>
                        </FormRow>
                        <FormRow>
                            <FormGroup>
                                <FormItem
                                    attr="streams.0.trickplayUrl"
                                    disabled={true}
                                    label="TrickPlay"
                                    model={this.props.asset}
                                    setter={VideoActions.update}
                                    type="textarea"
                                />
                            </FormGroup>
                        </FormRow>
                    </SectionPanel>
                </div>
            );
        }

        videoFileFormatData = GetDeliverableFormatData(this.props.asset);

        const visualWatermark = (
            <SectionPanel
                title={this.context.intl.messages['asset.video.edit.summary.watermark.title']}
                validationState={this.getAccordionValidationState(this.props.asset, ['watermarkText', 'disableVisualWatermark', 'persistentVisualWatermark'], VideoValidations)}
            >
                <FormGroup>
                    <FormItem
                        attr="disableVisualWatermark"
                        disabled={this.props.disabled}
                        label={this.context.intl.messages['asset.video.create.disable-visual-watermark']}
                        legend={this.context.intl.messages['asset.video.create.disable-visual-watermark.legend']}
                        model={this.props.asset}
                        setter={VideoActions.update}
                        type="checkbox"
                    />
                </FormGroup>

                <FormSection>
                    <FormRow>
                        <FormGroup>
                            <FormItem
                                attr="watermarkText"
                                disabled={this.props.disabled || this.props.asset.get('disableVisualWatermark')}
                                label={this.context.intl.messages['asset.video.create.watermark']}
                                model={this.props.asset}
                                setter={VideoActions.update}
                                validations={VideoValidations.watermarkText.validations}
                                type="textarea"
                                legend={this.context.intl.messages['asset.video.create.watermark.legend']}
                            />
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <FormItem
                                attr="persistentVisualWatermark"
                                disabled={this.props.disabled || this.props.asset.get('disableVisualWatermark')}
                                label={this.context.intl.messages['asset.video.create.persistent-watermark']}
                                model={this.props.asset}
                                setter={VideoActions.update}
                                type="checkbox"
                                legend={this.context.intl.messages['asset.video.create.persistent-watermark.legend']}
                            />
                        </FormGroup>
                    </FormRow>
                </FormSection>
            </SectionPanel>
        );

        let barcode = (
            <FormGroup>
                <ControlLabel>{this.context.intl.messages['asset.video.create.tapes']}</ControlLabel>
                <FormControl disabled type="text" value={this.props.asset.get('tapes')} />
            </FormGroup>
        );
        if (this.props.originalAsset && !this.props.originalAsset.get('tapes')) {
            barcode = (
                <FormGroup>
                    <FormItem attr="tapes"
                        disabled={this.props.disabled}
                        label={this.context.intl.messages['asset.video.create.tapes']}
                        setter={VideoActions.update}
                        md={6}
                        model={this.props.asset}
                        type="text"
                        validations={VideoValidations.tapes.validations}
                    />
                </FormGroup>
            );
        }

        const validMediaKey = VideoStore.validateMediaKey(this.props.asset.get('mediaKey'));

        const geoRestrictionModal = <Modal backdrop="static" onHide={this.toggleGeorestrictionModal} show={this.state.showGeorestrictionModal}>
            <Modal.Header className="bg-gray" closeButton>
                <Modal.Title className="text-center">
                    {this.context.intl.messages['asset.video.create.enable-geo-restriction']}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {this.context.intl.messages['asset.video.create.enable-geo-restriction.modal.body']}
                <br /><br />
                <span className="text-yellow"><i className="fas fa-exclamation-triangle" />&nbsp;<b>{this.context.intl.messages['asset.video.create.enable-geo-restriction.modal.note']}</b></span>
                <hr />
                <FormItem attr="geo-region-id"
                    label={this.context.intl.messages['asset.video.create.enable-geo-restriction.modal.label']}
                    md={2}
                    setter={VideoActions.update}
                    model={this.props.asset}
                    type="text"
                />
            </Modal.Body>

            <Modal.Footer>
                <Button className="pull-left Ml(5)" onClick={this.toggleGeorestrictionModal}>
                    {this.context.intl.messages['common.cancel']}
                </Button>
                <Button bsStyle="success-outline" type="submit"
                    disabled={!this.props.asset.get('geo-region-id')}
                    onClick={this.handleEnableGeorestriction}>
                    <i className="fas fa-globe-americas" /> {this.context.intl.messages['asset.video.create.enable-geo-restriction']}
                </Button>
            </Modal.Footer>
        </Modal>;

        let geoRestrictionButton;
        if (SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.VIDEO.CREATE)) { // GEORESTRICTION)) {
            geoRestrictionButton = <span style={{display: 'block'}}>
                <Button
                    className="btn btn-primary-outline btn-sm"
                    disabled={!this.props.asset.get('forensicUrlRequired')}
                    onClick={this.toggleGeorestrictionModal}
                >
                    <i className="fas fa-globe-americas"/>&nbsp;{this.context.intl.messages['asset.video.create.enable-geo-restriction']}
                </Button>
            </span>;
        }

        return (
            <div>
                <h3><i className="fa-regular fa-info-circle"></i>&nbsp;{this.context.intl.messages['asset.video.info']}</h3>
                <FormSection>
                    <FormRow>
                        <FormItem
                            attr="assetName"
                            label={this.context.intl.messages['asset.video.create.name']}
                            disabled={this.props.disabled}
                            model={this.props.asset}
                            setter={VideoActions.update}
                            type="text"
                            validations={VideoValidations.assetName.validations}
                        />
                        <FormItem
                            attr="assetComment"
                            label={this.context.intl.messages['asset.video.create.assetComment']}
                            disabled={this.props.disabled}
                            model={this.props.asset}
                            setter={VideoActions.update}
                            type="text"
                            validations={VideoValidations.assetComment.validations}
                        />
                    </FormRow>
                    <FormRow>
                        <FormGroup validationState={this.getValidationState(
                            this.state.selectedContentType,
                            this.state.isContentTypeDirty,
                            VideoValidations.contentType.validations
                        )}>
                            <ControlLabel>{this.context.intl.messages['asset.video.create.contentType']}&nbsp;<span className="text-red">*</span></ControlLabel>
                            <BaseSelect
                                classNamePrefix="dropdown"
                                disabled={this.props.disabled}
                                name={this.context.intl.messages['asset.video.create.contentType']}
                                onChange={this.handleSelectContentType}
                                options="contentType"
                                store={VideoStore}
                                value={this.state.selectedContentType}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['common.status']}</ControlLabel>
                            <FormItem
                                attr="active"
                                disabled={this.props.disabled || !validMediaKey}
                                label={this.context.intl.messages['common.active']}
                                model={this.props.asset}
                                setter={VideoActions.update}
                                type="bool-checkbox"
                            />
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <FormItem attr="runtime"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['asset.video.create.runtime']}
                            setter={VideoActions.update}
                            model={this.props.asset}
                            type="text"
                            validations={VideoValidations.runtime.validations}
                        />
                        <FormItem attr="creditStartSeconds"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['asset.video.create.credits-start-timecode']}
                            setter={VideoActions.update}
                            model={this.props.asset}
                            type="text"
                            validations={VideoValidations.creditStartSeconds.validations}
                        />
                    </FormRow>
                    <FormRow>
                        <FormItem attr="copyrightYear"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['asset.video.create.copyrightYear']}
                            md={3}
                            setter={VideoActions.update}
                            model={this.props.asset}
                            type="text"
                            validations={VideoValidations.copyrightYear.validations}
                        />
                        <FormItem attr="copyrightText"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['asset.video.create.copyrightText']}
                            setter={VideoActions.update}
                            model={this.props.asset}
                            type="text"
                            validations={VideoValidations.copyrightText.validations}
                        />
                    </FormRow>
                    <FormRow>
                        <FormItem attr="keyword"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['asset.video.create.keywords']}
                            setter={VideoActions.update}
                            model={this.props.asset}
                            type="text"
                            validations={VideoValidations.keyword.validations}
                        />
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['asset.video.create.videoSynopsis']}</ControlLabel>
                            <TinyMCE
                                content={this.props.asset.get('videoSynopsis') || ''}
                                id="videoSynopsis"
                                onChange={this.handleTinyMCEChange('videoSynopsis')}
                            />
                        </FormGroup>
                    </FormRow>
                    <hr />
                    <FormRow>
                        <FormGroup>
                            <FormItem
                                attr="mfaRequired"
                                disabled={this.props.disabled}
                                label={this.context.intl.messages['asset.video.create.mfa-required']}
                                model={this.props.asset}
                                setter={VideoActions.update}
                                type="checkbox"
                                legend={this.context.intl.messages['asset.video.create.mfa-required.legend']}
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormItem
                                attr="streamingOnly"
                                disabled={this.props.disabled}
                                label={this.context.intl.messages['asset.video.create.disable-offline-playback']}
                                model={this.props.asset}
                                setter={VideoActions.update}
                                type="checkbox"
                                legend={this.context.intl.messages['asset.video.create.disable-offline-playback.legend']}
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormItem
                                attr="forensicUrlRequired"
                                disabled={true}
                                label={this.context.intl.messages['asset.video.create.forensic-url-required']}
                                model={this.props.asset}
                                type="checkbox"
                            />
                            {geoRestrictionButton}
                        </FormGroup>
                    </FormRow>
                </FormSection>

                <h3 className="padding-top-20"><i className="fa-regular fa-file-lines"></i>
                    &nbsp;{this.context.intl.messages['asset.video.info-serviceable-properties']}
                </h3>
                <FormSection>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['asset.video.create.texted-textless']}&nbsp;</ControlLabel>
                            <ReactSelect
                                getOptionLabel={data => data.name}
                                getOptionValue={data => data.name}
                                isClearable={false}
                                isMulti={false}
                                disabled={this.props.disabled}
                                onChange={this.handleSelectTextless}
                                options={textlessOptions}
                                value={selectedTextedValue}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['asset.video.create.audio-configuration']}&nbsp;</ControlLabel>
                            <BaseSelect
                                classNamePrefix="dropdown"
                                disabled={this.props.disabled}
                                isClearable={true}
                                name={this.context.intl.messages['asset.video.create.audio-configuration']}
                                multi={true}
                                onChange={this.handleSelectAudioConfigType}
                                options="audioConfigType"
                                store={VideoStore}
                                value={this.state.selectedAudioConfigType}
                            />
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <FormGroup md={6}>
                            <ControlLabel>{this.context.intl.messages['asset.video.create.language']}&nbsp;</ControlLabel>
                            <BaseSelect
                                classNamePrefix="dropdown"
                                disabled={this.props.disabled}
                                name={this.context.intl.messages['asset.video.create.language']}
                                onChange={this.handleSelectLanguage}
                                options="languages"
                                store={VideoStore}
                                value={this.state.selectedLanguage}
                            />
                        </FormGroup>
                        <FormGroup md={3}>
                            <ControlLabel>{this.context.intl.messages['asset.video.create.dubs']}</ControlLabel>
                            <FormItem
                                attr="hasDub"
                                disabled={this.props.disabled}
                                label={this.context.intl.messages['asset.video.create.has-dubs']}
                                model={this.props.asset}
                                setter={VideoActions.update}
                                type="checkbox"
                            />
                        </FormGroup>
                        <FormGroup md={3}>
                            <ControlLabel>{this.context.intl.messages['asset.video.create.subtitles']}</ControlLabel>
                            <FormItem
                                attr="hasSub"
                                disabled={this.props.disabled}
                                label={this.context.intl.messages['asset.video.create.has-sub']}
                                model={this.props.asset}
                                setter={VideoActions.update}
                                type="checkbox"
                            />
                        </FormGroup>
                    </FormRow>
                    <hr />
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['asset.image.mimeType']}&nbsp;</ControlLabel>
                            <BaseSelect
                                classNamePrefix="dropdown"
                                disabled={this.props.disabled}
                                name={this.context.intl.messages['asset.image.mimeType']}
                                onChange={this.handleSelectMimeType}
                                options="videoMimeTypes"
                                store={AssetStore}
                                value={this.state.selectedMimeType}
                            />
                        </FormGroup>
                        <FormGroup validationState={this.getValidationState(
                            this.state.selectedDeliveryType,
                            this.state.isDeliveryTypeDirty,
                            VideoValidations.deliveryType.validations
                        )}>
                            <ControlLabel>{this.context.intl.messages['asset.video.create.delivery-type']}&nbsp;<span className="text-red">*</span></ControlLabel>
                            <BaseSelect
                                classNamePrefix="dropdown"
                                disabled={this.props.disabled}
                                name={this.context.intl.messages['asset.video.create.delivery-type']}
                                onChange={this.handleSelectDeliveryType}
                                options="deliveryType"
                                store={AssetStore}
                                value={this.state.selectedDeliveryType}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['asset.video.create.delivery-formats']}</ControlLabel>
                            <br/>
                            <VideoAssetFormatLabel data={videoFileFormatData}/>
                        </FormGroup>
                    </FormRow>
                </FormSection>

                <h3 className="padding-top-20"><i className="far fa-copyright"></i>
                    &nbsp;{this.context.intl.messages['common.asset-rights.rights-info']}
                </h3>
                <FormSection>
                    <FormRow>
                        <AssetRights
                            defaultRightsInfoValue={AssetRightsConstants.RIGHTS_INFO_TYPES.CUSTOM.id}
                            disabled={this.props.disabled}
                            model={this.props.asset}
                            updater={VideoActions.update}
                            validations={VideoValidations}
                        />
                    </FormRow>
                </FormSection>

                <h3 className="padding-top-20"><i className="fa-regular fa-wifi"></i>
                    &nbsp;{this.context.intl.messages['asset.video.info-playback-properties']}
                </h3>
                <FormSection>
                    {streaming}
                    <SectionPanel
                        title={this.context.intl.messages['asset.video.edit.summary.disclaimer.title']}
                        validationState={this.getAccordionValidationState(this.props.asset, ['disclaimerText'], VideoValidations)}
                    >
                        <FormSection>
                            <FormRow>
                                <FormGroup>
                                    <FormItem
                                        attr="disclaimerText"
                                        disabled={this.props.disabled}
                                        label={this.context.intl.messages['asset.video.create.disclaimer']}
                                        model={this.props.asset}
                                        setter={VideoActions.update}
                                        validations={VideoValidations.disclaimerText.validations}
                                        type="textarea"
                                    />
                                </FormGroup>
                            </FormRow>
                        </FormSection>
                    </SectionPanel>
                    {visualWatermark}
                    {captions}
                </FormSection>

                <h3 className="padding-top-20"><i className="fa-regular fa-brightness-low"></i>
                    &nbsp;{this.context.intl.messages['asset.video.info-source-properties']}
                </h3>
                <FormSection>
                    <FormRow>
                        <FormGroup md={6}>
                            <FormItem attr="mediaKey"
                                disabled={this.props.disabled}
                                label={this.context.intl.messages['asset.video.create.mediakey']}
                                setter={VideoActions.update}
                                model={this.props.asset}
                                type="text"
                                validations={VideoValidations.mediaKey.validations}
                            />
                        </FormGroup>
                        {videoTimelineLink}
                    </FormRow>
                    <FormRow>
                        {barcode}
                        <FormItem attr="referenceId"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['asset.video.create.reference-id']}
                            setter={VideoActions.update}
                            model={this.props.asset}
                            type="text"
                        />
                    </FormRow>
                    <hr />
                    {additionalInformation}
                </FormSection>
                {geoRestrictionModal}
            </div>
        );
    }

}

export default WithValidations(Summary);
