/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Classnames from 'classnames';
import React, {DOMAttributes, Ref} from 'react';

import pick from '~/src/common/utils/pick';

type InheritAttributes = Pick<DOMAttributes<HTMLButtonElement>, 'onBlur' | 'onFocus' | 'onMouseOut' | 'onMouseOver'> & Partial<{'data-testid': string}>;

type Props = {
    className?: string,
    children: React.ReactNode,
    isPushed: boolean;
    onChange: (isPushed: boolean) => void;
    ref?: Ref<unknown>,
    tooltip?: string,
} & InheritAttributes;

const INHERIT_ATTRS: Array<keyof InheritAttributes> = [
    'onBlur', 'onFocus', 'onMouseOut', 'onMouseOver', 'data-testid'
];

export default function ToggleButton(props: Props) {
    const cn = Classnames(props.className, {
        btn: true,
        'btn btn-primary-outline': props.isPushed,
        'btn btn-default-outline': !props.isPushed,
    });

    return (
        <button
            className={cn}
            onClick={() => props.onChange(!props.isPushed)}
            ref={props.ref}
            {...pick(props, INHERIT_ATTRS)}
        >
            {props.children}
        </button>
    );
}

ToggleButton.defaultProps = {
    className: undefined,
    ref: undefined,
    tooltip: undefined
};
