/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';

import {AlertTypes} from '../../common/notification/alert';
import {NotificationActions} from '../../common/notification/notification-actions';
import Dispatcher from '../../dispatcher/dispatcher';
import {PreloaderActions} from '../../preloader/preloader-actions';
import Request from '../../request';
import {RouterActions} from '../../router/router-actions';
import {ActionHistoryConstants} from '../../system/action-history/action-history-actions';
import {UserActions} from '../../user/user-actions';

const CONSTANTS = {
    CLEAR: 'security.role.clear',
    DUPLICATE_ROLE: {
        ERROR: 'security.role.duplicate-role.error',
        START: 'security.role.duplicate-role.start',
        SUCCESS: 'security.role.duplicate-role.success',
    },
    ERROR: 'security.role.error',
    FIND_BY_ID: {
        START: 'security.role.findById.start',
        SUCCESS: 'security.role.findById.success'
    },
    GET_ROLE_PERMISSIONS: {
        START: 'security.role.permissions.start',
        SUCCESS: 'security.role.permissions.success'
    },
    LOAD: 'security.role.load',
    ROLE: {
        UPDATE: 'security.role.attribute.update',
        SAVE: 'security.role.save',
        SAVE_OK: 'security.role.save.ok',
        DELETE_OK: 'security.role.delete.ok'
    },
    ROLE_PERMISSION: {
        ADD: 'security.role.permissions.add',
        REMOVE: 'security.role.permissions.remove'
    }
};

class RoleActions {
    addRolePermission(ids) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ROLE_PERMISSION.ADD,
            ids: ids
        });
    }

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
    }

    duplicateRole(id) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.DUPLICATE_ROLE.START,
            id: id
        });

        const preloaderSource = 'role-actions.duplicateRole';
        PreloaderActions.show(preloaderSource);

        Promise.all([
            Request.get(`security/role/${id}`).exec(),
            Request.get(`security/role/${id}/permission`).exec()
        ]).spread((originalRoleRes, originalRolePermissionsRes) => {
            const role = originalRoleRes.body;
            const permissions = originalRolePermissionsRes.body.map(c => c.id);

            role.id = undefined;
            role.name += ' (Copy)';
            this.saveRole(undefined, Immutable.fromJS(role), permissions, []);

            Dispatcher.dispatch({
                actionType: CONSTANTS.DUPLICATE_ROLE.SUCCESS,
            });

            NotificationActions.showAlertSuccess('roles.create.duplicate.success');

            PreloaderActions.hide(preloaderSource);
        }).catch((err) => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.DUPLICATE_ROLE.ERROR,
            });

            NotificationActions.showAlertDanger('roles.create.duplicate.error');

            console.error(err);

            PreloaderActions.hide(preloaderSource);
        });


    }

    findById(id) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.FIND_BY_ID.START,
            id: id
        });

        let actionHistoryQuery = {
            'action-object': ActionHistoryConstants.ACTION_OBJECTS.ROLE,
            'object-id': id,
            offset: 0,
            size: 4
        };

        const preloaderSource = 'role-actions.findById';
        PreloaderActions.show(preloaderSource);

        Promise.all([
            Request.get(`security/role/${id}`).exec(),
            Request.get('system/action-history').query(actionHistoryQuery).exec()
        ])
            .spread((roleRes, history) => {
                let role = roleRes.body;
                role.history = history.body.results;
                role.history.sort((h1, h2) => h2.actionDate.localeCompare(h1.actionDate));

                role = Immutable.fromJS(role);
                Dispatcher.dispatch({
                    actionType: CONSTANTS.FIND_BY_ID.SUCCESS,
                    role: role
                });
                return;
            }).then(()=>{
                PreloaderActions.hide(preloaderSource);
                return;
            }).catch((err) => {
                PreloaderActions.hide(preloaderSource);

                Dispatcher.dispatch({
                    actionType: CONSTANTS.ERROR,
                    error: err
                });

                switch (err.status) {
                case 404:
                    RouterActions.notFound();
                    break;
                default:
                    NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
                    break;
                }
            });
    }

    get(offset, size, countUsers) {
        const preloaderSource = 'role-actions.get';
        PreloaderActions.show(preloaderSource);
        Request.get('security/role')
            .query({
                offset: offset,
                size: size,
                'count-users': countUsers
            })
            .exec()
            .then(res => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.LOAD,
                    offset: res.body.offset,
                    roles: Immutable.fromJS(res.body.results).sortBy(r => r.get('role')),
                    size: res.body.size,
                    total: res.header['wbtv-total-count']
                });
                return;
            }).then(()=>{
                PreloaderActions.hide(preloaderSource);
                return;
            }).catch(err => {
                PreloaderActions.hide(preloaderSource);

                throw err;
            });

        return;
    }

    getRolePermissions(id) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.GET_ROLE_PERMISSIONS.START,
            id: id
        });

        const preloaderSource = 'role-actions.getRolePermissions';
        PreloaderActions.show(preloaderSource);

        Request.get(`security/role/${id}/permission`)
            .exec()
            .then((res) => {
                let ids = res.body.map(function(v) {
                    return v.id;
                });
                let permissions = Immutable.fromJS(ids).toSet();
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET_ROLE_PERMISSIONS.SUCCESS,
                    permissions: permissions
                });
            }).then(()=>{
                PreloaderActions.hide(preloaderSource);
                return;
            }).catch((err) => {
                PreloaderActions.hide(preloaderSource);

                Dispatcher.dispatch({
                    actionType: CONSTANTS.ERROR,
                    error: err
                });

                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
                return;
            });
    }

    updateRole(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ROLE.UPDATE,
            attr: attr,
            value: value
        });
        return;
    }

    remove(id) {
        const preloaderSource = 'role-actions.remove';
        PreloaderActions.show(preloaderSource);

        Request.del(`security/role/${id}`)
            .exec()
            .then(() => {
                PreloaderActions.hide(preloaderSource);
                Dispatcher.dispatch({
                    actionType: CONSTANTS.ROLE.DELETE_OK,
                });

                RouterActions.redirect('/roles');
                NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'roles.edit.remove.success');
                return;
            })
            .catch(() => {
                PreloaderActions.hide(preloaderSource);
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'roles.edit.remove.error');
                return;
            });
    }

    removeRolePermission(ids) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ROLE_PERMISSION.REMOVE,
            ids: ids
        });
    }

    /**
     * Remove a list of users from a role.
     */
    removeUsers(roleId, users) {
        // Send a DELETE request for each user in the users array.
        Promise.all(users.map(
            u => Request.del(`user/${u.get('id')}/role/${roleId}`).exec()
        ).toJS()).then(() => {
            NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'roles.edit.remove-users.success');
            // Reload all users for the group.
            UserActions.findAllByRoleId(roleId, 0, 10);
            return;
        }).catch((err) => {
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'roles.edit.remove-users.error');
            // Reload all users for the group.
            UserActions.findAllByRoleId(roleId, 0, 10);
            throw err;
        });

        return;
    }

    saveRole(id, role, permissionsToAdd, permissionsToRemove) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ROLE.SAVE,
            id: id
        });
        let newRole;
        let method = Request.post;
        let uri = 'security/role';
        if (id !== undefined) {
            // create => PUT
            method = Request.put;
            uri = `security/role/${id}`;
        }
        let jsRole = role.toJS();
        ['history', 'numberOfAccountsInRole', 'partnerBrainiac', 'partnerWbitv'].forEach(p => delete jsRole[p]);
        if (jsRole.isActive) {
            jsRole.isActive = 1;
        } else {
            jsRole.isActive = 0;
        }

        const preloaderSource = 'role-actions.saveRole';
        PreloaderActions.show(preloaderSource);

        method(uri)
            .send(jsRole)
            .exec()
            .then((res) => {
                return res.body.id;
            })
            .then((roleId) => {
                newRole = roleId;
                let ops = [];
                permissionsToAdd.filter(permissionId => !!permissionId).forEach(
                    permissionId => ops.push(Request.post(`security/role/${roleId}/permission/${permissionId}`).exec())
                );
                permissionsToRemove.filter(permissionId => !!permissionId).forEach(
                    permissionId => ops.push(Request.del(`security/role/${roleId}/permission/${permissionId}`).exec())
                );
                return Promise.all(ops);
            })
            .then(() => {
                PreloaderActions.hide(preloaderSource);
                Dispatcher.dispatch({
                    actionType: CONSTANTS.ROLE.SAVE_OK
                });
                if (!id) {
                    RouterActions.redirect(`/roles/${newRole}`, true);
                }
                NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'roles.edit.save.success');
                return;
            })
            .catch((err) => {
                PreloaderActions.hide(preloaderSource);
                Dispatcher.dispatch({
                    actionType: CONSTANTS.ERROR,
                    error: err
                });

                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'roles.edit.save.error');
                return;
            });
    }
}

let actions = new RoleActions();

export {
    actions as RoleActions,
    CONSTANTS as RoleConstants
};
