/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import ReactTable, {Column} from 'react-table'; // eslint-disable-line import/named

import {ACTIVE_STATUS_COLUMNS, CellExtraProps, INACTIVE_STATUS_COLUMNS} from './columns';

import pick from '~/src/common/utils/pick';

type Props = {
    alert?: React.ReactChild,
    group: 'active' | 'inactive'
    models: ReadonlyArray<VideoMachineLearningProject>,
    originalModels: DeepImmutable<Dictionary<VideoMachineLearningProject>>,
    showDelimiter: boolean;
}

export default class AiModelsTable extends React.PureComponent<Props> {
    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    private cellExtraProps: CellExtraProps = {
        messages: pick(this.context.intl.messages, ['asset.edit.ai-models-tab.messages.run.tooltip', 'date-format-title-release-0']),
    };

    private getCellExtraProps = () => this.cellExtraProps;
    private getTheadThProps = (state: unknown, rowInfo: unknown, column: Column, instance: any) => ({
        onClick: () => {
            if (column) {
                instance.sortColumn(column);
            }
        }
    });

    render() {
        if (this.props.models.length === 0) {
            return null;
        }

        let columns = INACTIVE_STATUS_COLUMNS;
        if (this.props.group === 'active') {
            columns = ACTIVE_STATUS_COLUMNS;
        }

        return (
            <div>
                {this.props.showDelimiter && <hr />}
                <h4>
                    <FormattedMessage id={`asset.edit.ai-models-tab.${this.props.group}.title`} />
                    <small>
                        &nbsp;-&nbsp;
                        <FormattedMessage id={`asset.edit.ai-models-tab.${this.props.group}.description`} />
                    </small>
                </h4>

                {this.props.alert}

                <ReactTable
                    className="-striped table-bordered table-striped responsive ai-models"
                    columns={columns}
                    // react-table defines data as mutable array =(
                    data={this.props.models as VideoMachineLearningProject[]}
                    getTheadThProps={this.getTheadThProps}
                    getTdProps={this.getCellExtraProps}
                    loading={false}
                    pageSize={this.props.models.length}
                    showPagination={false}
                    sortable={true}
                    resizable={false}
                />

            </div>
        );
    }
}
