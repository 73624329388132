/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {GetVideoDisplayName} from '@wbdt-sie/brainiac-web-common';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {ControlLabel, FormGroup} from 'react-bootstrap';
import ReactSelect from 'react-select';

import ThumbnailSelectionModal from './thumbnail-selection-modal';
import TimelineAssetStore from '../../../assets/timeline/timeline-asset-store';
import VideoStore from '../../../assets/video/video-store';
import AssetRights from '../../../common/asset-rights/asset-rights';
import {AssetRightsConstants} from '../../../common/asset-rights/asset-rights-constants';
import BaseSelect from '../../../common/base-select/base-select';
import {EVENT_BLUR, FormItem, FormRow, FormSection} from '../../../common/form/form';
import {GetThumbnailFromClip, TimecodeFromString, TimecodeToFrames, FrameRateToFraction} from '../../../common/utils/timecode';
import {TimelineActions} from '../timeline-actions';
import TimelineStore, {VideoClipValidations} from '../timeline-store';

const updateMarkerInPlayer = (player, timeline, clip) => {
    player?.api.plugin.apPointPlugin.setInPointFrame(clip.get('clipInFrame'), FrameRateToFraction(timeline.get('frameRate')));
    player?.api.plugin.apPointPlugin.setOutPointFrame(clip.get('clipOutFrame'), FrameRateToFraction(timeline.get('frameRate')));
    player?.api.plugin.apPointPlugin.actions.find(action => action.name === 'seekToIn').callback();
};
class ClipInfo extends React.Component {
    static get propTypes() {
        return {
            canEdit: PropTypes.bool.isRequired,
            clip: PropTypes.instanceOf(Immutable.Map).isRequired,
            player: PropTypes.object,
            selectedDeliveryType: PropTypes.instanceOf(Immutable.Map),
            sources: PropTypes.instanceOf(Immutable.Map).isRequired,
            timeline: PropTypes.instanceOf(Immutable.Map).isRequired,
            thumbnails: PropTypes.instanceOf(Immutable.List).isRequired,
            viewingMode: PropTypes.string.isRequired
        };
    }

    static get defaultProps() {
        return {
            player: undefined,
            selectedDeliveryType: Immutable.Map()
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.textedOptions = [
            {name: 'hardac.timeline.clip.texted-textless.both', texted: true, textless: true},
            {name: 'hardac.timeline.clip.texted-textless.none', texted: false, textless: false},
            {name: 'hardac.timeline.clip.texted-textless.texted', texted: true, textless: false},
            {name: 'hardac.timeline.clip.texted-textless.textless', texted: false, textless: true}
        ];

        this.state = {
            isThumbnailSelectionModalOpen: false
        };

        this.handleSelectLanguage = this.handleSelectLanguage.bind(this);
        this.handleGetTextedOrTextless = this.handleGetTextedOrTextless.bind(this);
        this.handleSelectThumbnail = this.handleSelectThumbnail.bind(this);
        this.handleSelectTextedOrTextless = this.handleSelectTextedOrTextless.bind(this);
        this.setThumbnailClipInfo = this.setThumbnailClipInfo.bind(this);
        this.updateSelectedClipTimecode = this.updateSelectedClipTimecode.bind(this);
        this.updateTimeline = this.updateTimeline.bind(this);
        this.toggleSelectThumbnailModal = this.toggleSelectThumbnailModal.bind(this);

        return;
    }

    handleGetTextedOrTextless(texted, textless) {
        return this.textedOptions.find(o => (!!texted === o.texted && !!textless === o.textless));
    }

    handleSelectThumbnail(thumbnailFrames) {
        TimelineActions.updateSelectedClipAttr('thumbnailFrameNum', thumbnailFrames);
        this.toggleSelectThumbnailModal();
    }

    handleSelectContentType(value) {
        if (value !== undefined && value !== null) {
            TimelineActions.updateSelectedClipAttr('contentType', value.id);
        } else {
            TimelineActions.updateSelectedClipAttr('contentType', null);
        }
    }

    handleSelectDeliveryType(value) {
        if (value !== undefined && value !== null) {
            TimelineActions.updateSelectedClipAttr('deliveryType', value.id);
        } else {
            TimelineActions.updateSelectedClipAttr('deliveryType', null);
        }
    }

    handleSelectLanguage(value) {
        if (value !== undefined && value !== null) {
            TimelineActions.updateSelectedClipAttr('language', value.id);
            return;
        }
        TimelineActions.updateSelectedClipAttr('language', null);
        return;
    }

    handleSelectProtectionType(value) {
        if (value !== undefined && value !== null) {
            TimelineActions.updateSelectedClipAttr('protectionType', value.id);
        } else {
            TimelineActions.updateSelectedClipAttr('protectionType', null);
        }
    }

    handleSelectTextedOrTextless(value) {
        if (value !== undefined && value !== null) {
            TimelineActions.updateSelectedClipAttr('texted', value.texted);
            TimelineActions.updateSelectedClipAttr('textless', value.textless);
            return;
        }

        TimelineActions.updateSelectedClipAttr('texted', null);
        TimelineActions.updateSelectedClipAttr('textless', null);
    }

    toggleSelectThumbnailModal() {
        this.setState(prevState => ({
            isThumbnailSelectionModalOpen: !prevState.isThumbnailSelectionModalOpen
        }));
    }

    /**
     * Observes refThumbnail when it resizes, in order we can also resize thumbanil img
     */
    setThumbnailClipInfo(elem) {
        if (!elem) {
            this.resizeObserver.unobserve(this.thumbnailClipInfo);
            return;
        }

        this.thumbnailClipInfo = elem;

        // Set the initial width.
        this.setState(() => ({
            thumbnailWidth: this.thumbnailClipInfo.offsetWidth
        }));

        // Then call it again whenever the size changes.
        this.resizeObserver = new ResizeObserver(entries => {
            this.setThumbnailSize(entries[0]);
        });
        this.resizeObserver.observe(this.thumbnailClipInfo);

        return;
    }

    setThumbnailSize(element) {
        const {width} = element.contentRect;
        this.setState(() =>({
            thumbnailWidth: width
        }));
    }

    updateSelectedClipTimecode(attr, displayClipInOut) {
        let f = TimecodeToFrames(TimecodeFromString(displayClipInOut), this.props.timeline.get('frameRate'));
        if (this.props.timeline.get('firstFrame')) {
            f -= this.props.timeline.get('firstFrame');
        }
        TimelineActions.updateSelectedClipAttr(
            attr,
            f
        );
    }

    updateTimeline(attr) {
        // Update the array so that the timeline is in sync with the user input.
        TimelineActions.addUpdateClipToList(TimelineStore.getState().get('selectedClip'), 'unpublished');
        if (attr === 'clipInFrame' || attr === 'clipOutFrame') {
            updateMarkerInPlayer(this.props.player, this.props.timeline, TimelineStore.getState().get('selectedClip'));
        }
    }

    render() {
        let thumbnail;
        const {thumbnailWidth} = this.state;
        if (thumbnailWidth) {
            const expectedSize = {width: thumbnailWidth};
            const extraStyles = {transformOrigin: '0 0'};
            thumbnail = GetThumbnailFromClip(this.props.clip, this.props.thumbnails.toJS(), expectedSize, extraStyles);
        }

        const thumbnailModal = (
            <ThumbnailSelectionModal
                clip={this.props.clip}
                onSelectThumbnail={this.handleSelectThumbnail}
                show={this.state.isThumbnailSelectionModalOpen}
                sources={this.props.sources}
                timeline={this.props.timeline}
                toggle={this.toggleSelectThumbnailModal}
                viewingMode={this.props.viewingMode}
            />
        );

        let programmaticName = '-';
        if (this.props.clip.get('displayName')) {
            const video = this.props.clip.set('name', this.props.clip.get('displayName'));
            programmaticName = GetVideoDisplayName(video);
        }

        return (
            <div className="clip-details">
                <h3><i className="far fa-cut"></i>{this.context.intl.messages['hardac.timeline.clip.info']}</h3>
                <FormSection>
                    <FormRow>
                        <FormItem
                            attr="name"
                            label={this.context.intl.messages['hardac.timeline.clip.form.name']}
                            model={this.props.clip}
                            required
                            onChange={this.updateTimeline}
                            setter={TimelineActions.updateSelectedClipAttr}
                            type="text"
                            validations={VideoClipValidations.name.validations}
                        />
                    </FormRow>
                    <FormRow>
                        <FormItem
                            attr="displayName"
                            label={this.context.intl.messages['hardac.timeline.clip.display-name']}
                            model={this.props.clip}
                            onChange={this.updateTimeline}
                            setter={TimelineActions.updateSelectedClipAttr}
                            type="text"
                        />
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.timeline.clips.content-type']}&nbsp;<span className="text-red">*</span></ControlLabel>
                            <BaseSelect
                                classNamePrefix="dropdown"
                                disabled={false}
                                isClearable={true}
                                name={this.context.intl.messages['hardac.timeline.clips.content-type']}
                                onChange={this.handleSelectContentType}
                                options="contentType"
                                store={TimelineStore}
                                value={TimelineStore.getContentType(this.props.clip.get('contentType'))}
                            />
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.timeline.clip.front-end-preview']}</ControlLabel>
                            <p><em>{programmaticName}</em></p>
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.timeline.clip.protection-type']}&nbsp;<span className="text-red">*</span></ControlLabel>
                            <BaseSelect
                                classNamePrefix="dropdown"
                                disabled={false}
                                isClearable={true}
                                multi={false}
                                name="selectedProtectionType"
                                onChange={this.handleSelectProtectionType}
                                options="selectedProtectionType"
                                store={TimelineAssetStore}
                                value={TimelineAssetStore.getProtectionType(this.props.clip.get('protectionType'))}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.timeline.clip.deliveryType']}&nbsp;<span className="text-red">*</span></ControlLabel>
                            <BaseSelect
                                classNamePrefix="dropdown"
                                disabled={false}
                                isClearable={true}
                                multi={false}
                                name="deliveryType"
                                onChange={this.handleSelectDeliveryType}
                                options="deliveryType"
                                store={TimelineStore}
                                value={this.props.selectedDeliveryType}
                            />
                        </FormGroup>
                    </FormRow>
                    <hr/>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.timeline.clip.texted-textless']}</ControlLabel>
                            <ReactSelect
                                classNamePrefix="dropdown"
                                getOptionLabel={data => this.context.intl.messages[data.name]}
                                getOptionValue={data => data}
                                disabled={false}
                                isClearable={false}
                                multi={false}
                                onChange={this.handleSelectTextedOrTextless}
                                options={this.textedOptions.filter(o => !(o.texted && o.textless))}
                                value={this.handleGetTextedOrTextless(this.props.clip.get('texted'), this.props.clip.get('textless'))}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.timeline.clip.status']}</ControlLabel>
                            <FormItem
                                attr="active"
                                label={this.context.intl.messages['hardac.timeline.clip.active']}
                                disabled={false}
                                model={this.props.clip}
                                setter={TimelineActions.updateSelectedClipAttr}
                                type="checkbox"
                            />
                        </FormGroup>
                    </FormRow>
                    <hr/>
                    <FormRow>
                        {/* TODO: this field was requested but not yey in a model.
                        <FormItem
                            attr="requiresMfa"
                            label={this.context.intl.messages['hardac.timeline.clip.mfaRequired']}
                            disabled={false}
                            model={this.props.clip}
                            setter={TimelineActions.updateSelectedClipAttr}
                            type="checkbox"
                        />
                        */}
                        <FormGroup md={6}>
                            <ControlLabel>{this.context.intl.messages['hardac.timeline.clip.language']}</ControlLabel>
                            <BaseSelect
                                classNamePrefix="dropdown"
                                disabled={!this.props.canEdit}
                                name={this.context.intl.messages['hardac.timeline.clip.language']}
                                onChange={this.handleSelectLanguage}
                                options="languages"
                                store={VideoStore}
                                value={VideoStore.getLanguage(this.props.clip.get('language'))}
                            />
                        </FormGroup>
                        <FormGroup md={3}>
                            <ControlLabel>{this.context.intl.messages['hardac.timeline.clip.subtitles']}</ControlLabel>
                            <FormItem
                                attr="hasSub"
                                label={this.context.intl.messages['hardac.timeline.clip.hasSub']}
                                disabled={false}
                                model={this.props.clip}
                                setter={TimelineActions.updateSelectedClipAttr}
                                type="checkbox"
                            />
                        </FormGroup>
                        <FormGroup md={3}>
                            <ControlLabel>{this.context.intl.messages['hardac.timeline.clip.dubs']}</ControlLabel>
                            <FormItem
                                attr="hasDub"
                                label={this.context.intl.messages['hardac.timeline.clip.hasDub']}
                                disabled={false}
                                model={this.props.clip}
                                setter={TimelineActions.updateSelectedClipAttr}
                                type="checkbox"
                            />
                        </FormGroup>
                    </FormRow>
                    <hr/>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.timeline.thumbnail']}</ControlLabel>
                            <div ref={this.setThumbnailClipInfo} className="thumbnail-clip-details">
                                {thumbnail}
                                <span className="thumbnail-select-icon fas fa-edit" onClick={this.toggleSelectThumbnailModal}></span>
                                {thumbnailModal}
                            </div>
                        </FormGroup>
                        <FormItem
                            attr="clipInFrame"
                            label={this.context.intl.messages['hardac.timeline.clips.clip-in']}
                            firstFrame={this.props.timeline.get('firstFrame')}
                            frameRate={this.props.timeline.get('frameRate')}
                            model={this.props.clip}
                            notifyEvent={EVENT_BLUR}
                            onBlur={this.updateTimeline.bind(this, 'clipInFrame')}
                            required
                            setter={this.updateSelectedClipTimecode}
                            type="timecode"
                            validations={VideoClipValidations.clipIn.validations}
                        />
                        <FormItem
                            attr="clipOutFrame"
                            label={this.context.intl.messages['hardac.timeline.clips.clip-out']}
                            firstFrame={this.props.timeline.get('firstFrame')}
                            frameRate={this.props.timeline.get('frameRate')}
                            model={this.props.clip}
                            notifyEvent={EVENT_BLUR}
                            onBlur={this.updateTimeline.bind(this, 'clipOutFrame')}
                            required
                            setter={this.updateSelectedClipTimecode}
                            type="timecode"
                            validations={VideoClipValidations.clipOut.validations}
                        />
                    </FormRow>
                    <hr/>
                    <AssetRights
                        defaultRightsInfoValue={AssetRightsConstants.RIGHTS_INFO_TYPES.CUSTOM.id}
                        disabled={!this.props.canEdit}
                        model={this.props.clip}
                        updater={TimelineActions.updateSelectedClipAttr}
                        validations={VideoClipValidations}
                    />
                </FormSection>
            </div>
        );
    }
}

export default ClipInfo;

export {
    updateMarkerInPlayer as UpdateMarkerInPlayer
};
