/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';
import {IndexRoute, Route} from 'react-router';

import Browse from './browse';
import Create from './create';
import Summary from './create/summary';
import WithPermissions from '../decorators/with-permissions';
import {RequirePerm} from '../router/router-helpers';
import SessionStore from '../user/session/session-store';
import './scheduling.less';

export default (
    <Route path="scheduling">
        {/* Document Batches */}
        <Route
            component={WithPermissions(Browse)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.SCHEDULING.DOCUMENT_BATCHES.BROWSE)}
            path="document"
        />
        <Route
            component={WithPermissions(Create)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.SCHEDULING.DOCUMENT_BATCHES.CREATE)}
            mode="create"
            path="document/create"
        >
            <IndexRoute component={Summary}/>
            <Route path="catalogs"/>
            <Route path="documents-list"/>
        </Route>
        <Route
            component={WithPermissions(Create)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.SCHEDULING.DOCUMENT_BATCHES.EDIT)}
            mode="edit"
            path="document/:id"
        >
            <IndexRoute component={Summary}/>
            <Route path="catalogs"/>
            <Route path="documents-list"/>
            <Route path="history"/>
            <Route path="users"/>
        </Route>

        {/* Image Batches */}
        <Route
            component={WithPermissions(Browse)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.SCHEDULING.IMAGE_BATCHES.BROWSE)}
            path="image"
        />
        <Route
            component={WithPermissions(Create)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.SCHEDULING.IMAGE_BATCHES.CREATE)}
            mode="create"
            path="image/create"
        >
            <IndexRoute component={Summary}/>
            <Route path="catalogs"/>
            <Route path="images-list"/>
        </Route>
        <Route
            component={WithPermissions(Create)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.SCHEDULING.IMAGE_BATCHES.EDIT)}
            mode="edit"
            path="image/:id"
        >
            <IndexRoute component={Summary}/>
            <Route path="catalogs"/>
            <Route path="images-list"/>
            <Route path="history"/>
            <Route path="users"/>
        </Route>

        {/* Title Batches */}
        <Route
            component={WithPermissions(Browse)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.SCHEDULING.TITLE_BATCHES.BROWSE)}
            path="title"
        />
        <Route
            component={WithPermissions(Create)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.SCHEDULING.TITLE_BATCHES.CREATE)}
            mode="create"
            path="title/create"
        >
            <IndexRoute component={Summary}/>
            <Route path="catalogs"/>
            <Route path="titles-list"/>
        </Route>
        <Route
            component={WithPermissions(Create)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.SCHEDULING.TITLE_BATCHES.EDIT)}
            mode="edit"
            path="title/:id"
        >
            <IndexRoute component={Summary}/>
            <Route path="catalogs"/>
            <Route path="titles-list"/>
            <Route path="history"/>
            <Route path="users"/>
        </Route>

        {/* Video Batches */}
        <Route
            component={WithPermissions(Browse)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.SCHEDULING.VIDEO_BATCHES.BROWSE)}
            path="video"
        />
        <Route
            component={WithPermissions(Create)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.SCHEDULING.VIDEO_BATCHES.CREATE)}
            mode="create"
            path="video/create"
        >
            <IndexRoute component={Summary}/>
            <Route path="catalogs"/>
            <Route path="videos-list"/>
        </Route>
        <Route
            component={WithPermissions(Create)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.SCHEDULING.VIDEO_BATCHES.EDIT)}
            mode="edit"
            path="video/:id"
        >
            <IndexRoute component={Summary}/>
            <Route path="catalogs"/>
            <Route path="videos-list"/>
            <Route path="history"/>
            <Route path="users"/>
        </Route>
    </Route>
);
