/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React, {Component} from 'react';
import ReactDOM from 'react-dom';

import Resizer from './resizer';

export default class ResizablePanels extends Component {
    static get propTypes() {
        return {
            bkcolor: PropTypes.string,
            children: PropTypes.node.isRequired,
            displayDirection: PropTypes.string.isRequired,
            height: PropTypes.string.isRequired,
            onResize: PropTypes.func,
            panelsMaxSize: PropTypes.array.isRequired,
            panelsMinSize: PropTypes.array.isRequired,
            panelsSize: PropTypes.array.isRequired,
            resizerColor: PropTypes.string.isRequired,
            resizerSize: PropTypes.string.isRequired,
            sizeUnitMeasure: PropTypes.string.isRequired,
            width: PropTypes.string.isRequired,
        };
    }

    static get defaultProps() {
        return {
            bkcolor: undefined,
            onResize: () => void 0,
        };
    }

    constructor(props) {
        super(props);

        let {panelsSize} = this.props;
        this.state = {
            panelsSize,
            resizing: false
        };

        this.executeResize = this.executeResize.bind(this);
        this.stopResize = this.stopResize.bind(this);
    }

    componentDidMount() {
        ReactDOM.findDOMNode(this).addEventListener(
            'mousemove',
            this.executeResize
        );
        ReactDOM.findDOMNode(this).addEventListener('mouseup', this.stopResize);
        ReactDOM.findDOMNode(this).addEventListener('mouseleave', this.stopResize);
    }

    convertToPercentage(displacement) {
        const size = this.getCurrentComponentSize();

        return (displacement * 100) / size;
    }

    displayDirectionIsColumn() {
        return this.props.displayDirection === 'column';
    }

    executeResize(e) {
        if (this.state.resizing) {
            let currentMousePosition;

            if (this.displayDirectionIsColumn()) {
                currentMousePosition = e.clientY;
            } else {
                currentMousePosition =e.clientX;
            }

            // eslint-disable-next-line
            const displacement = this.state.initialPos - currentMousePosition;

            const nextPanelsSize = this.getNextPanelsSize(displacement);

            this.setState({
                initialPos: currentMousePosition,
                panelsSize: nextPanelsSize,
            });
        }
    }

    getCurrentComponentSize() {
        const componentSizes = this.resizable.getBoundingClientRect();

        if (this.displayDirectionIsColumn()) {
            return componentSizes.height;
        } else {
            return componentSizes.width;
        }
    }

    getNextPanelsSize(displacement) {
        const currentPanelsSize = this.state.panelsSize;
        const {panelsMinSize, panelsMaxSize, sizeUnitMeasure} = this.props;
        const usePercentage = sizeUnitMeasure === '%';

        let resizeSize;

        if (usePercentage) {
            resizeSize = this.convertToPercentage(displacement);
        } else {
            resizeSize = displacement;
        }

        const newPanelsSize = currentPanelsSize.map((panelSize, index) => {
            if (index === this.state.currentPanel) {
                let newSize = panelSize + resizeSize;
                if (resizeSize > 0) {
                    if (newSize > panelsMaxSize[index]) {
                        newSize = panelsMaxSize[index];
                    }
                } else {
                    if (newSize < panelsMinSize[index]) {
                        newSize = panelsMinSize[index];
                    }
                }
                return newSize;
            } else {
                if (index === this.state.currentPanel - 1) {
                    let newSize = panelSize - resizeSize;
                    if (resizeSize > 0) {
                        if (newSize > panelsMinSize[index]) {
                            // eslint-disable-next-line no-self-assign
                            newSize = newSize;
                        } else {
                            newSize = panelsMinSize[index];
                        }
                    } else {
                        if (newSize < panelsMaxSize[index]) {
                            // eslint-disable-next-line no-self-assign
                            newSize = newSize;
                        } else {
                            newSize = panelsMaxSize[index];
                        }
                    }
                    return newSize;
                }
            }

            return panelSize;
        });

        return newPanelsSize;
    }

    getStyle(index) {
        const panelsSize = this.state.panelsSize || [];
        const panelsSizeLength = panelsSize.length - 1;

        let size;
        if (index > panelsSizeLength) {
            size = '100%';
        } else {
            size = panelsSize[index];
        }

        const unitMeasure = this.props.sizeUnitMeasure || 'px';

        if (this.displayDirectionIsColumn()) {
            return {
                height: `${size}${unitMeasure}`,
                width: '100%',
                overflow: 'auto'
            };
        }

        return {
            height: '100%',
            width: `${size}${unitMeasure}`,
            overflow: 'auto'
        };
    }

    renderChildren(children, index) {
        return (
            <div
                className="resizable-fragment"
                key={`fragment_${index}`}
                style={this.getStyle(index)}
            >
                {children}
            </div>
        );
    }

    renderFirst() {
        return this.renderChildren(this.props.children[0], 0);
    }

    renderRest(rest) {
        return [].concat(
            ...rest.map((children, index) => {
                return [
                    this.renderResizer(index + 1),
                    this.renderChildren(children, index + 1)
                ];
            })
        );
    }

    renderResizer(index) {
        return (
            <Resizer
                size={this.props.resizerSize || '10px'}
                key={`resizer_${index}`}
                direction={this.props.displayDirection}
                onMouseDown={e => this.startResize(e, index)}
                color={this.props.resizerColor}
            />
        );
    }

    startResize(e, index) {
        e.preventDefault();

        let tempPos;
        if (this.displayDirectionIsColumn()) {
            tempPos = e.clientY;
        } else {
            tempPos = e.clientX;
        }

        this.setState({
            resizing: true,
            currentPanel: index,
            initialPos: tempPos
        });
    }

    stopResize() {
        this.setState({
            resizing: false,
            currentPanel: null,
        });
        this.props.onResize([...this.state.panelsSize]);
    }

    render() {
        const {bkcolor} = this.props;

        let rest = [];

        if (this.props.children.length > 1) {
            rest = this.props.children.slice(1);
        }

        return (
            <div
                style={{
                    width: this.props.width,
                    height: this.props.height,
                    background: bkcolor,
                    display: 'flex',
                    flexDirection: this.props.displayDirection || 'row'
                }}
                ref={ref => {this.resizable = ref;}}
            >
                {this.renderFirst()}
                {this.renderRest(rest)}
            </div>
        );
    }
}
