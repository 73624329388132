/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ControlLabel, FormGroup} from 'react-bootstrap';
import Select from 'react-select';

import {ConfigurationActions, ConfigurationConstants} from './configuration-actions';

import {FormSection, FormRow, FormItem} from '~/src/common/form/form';
import {GetAttr} from '~/src/common/utils/utils';

class OptionsTab extends Component {

    static get propTypes() {
        return {
            configuration: PropTypes.instanceOf(Immutable.Map).isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.handleSetCDN = this.handleSetCDN.bind(this);
    }

    handleSetCDN(o) {
        switch (o.id) {
        case ConfigurationConstants.CDN_OPTIONS.CDN_LIMELIGHT_ENABLED.id:
            ConfigurationActions.update_attribute(ConfigurationConstants.FEATURE_CONTROLS.CDN_LIMELIGHT_FORENSIC_STREAMING.id, 'active', true);
            ConfigurationActions.update_attribute(ConfigurationConstants.FEATURE_CONTROLS.CDN_AKAMAI_FORENSIC_STREAMING.id, 'active', false);
            break;
        case ConfigurationConstants.CDN_OPTIONS.CDN_AKAMAI_ENABLED.id:
            ConfigurationActions.update_attribute(ConfigurationConstants.FEATURE_CONTROLS.CDN_LIMELIGHT_FORENSIC_STREAMING.id, 'active', false);
            ConfigurationActions.update_attribute(ConfigurationConstants.FEATURE_CONTROLS.CDN_AKAMAI_FORENSIC_STREAMING.id, 'active', true);
            break;
        case ConfigurationConstants.CDN_OPTIONS.CDN_BOTH_ENABLED.id:
            ConfigurationActions.update_attribute(ConfigurationConstants.FEATURE_CONTROLS.CDN_LIMELIGHT_FORENSIC_STREAMING.id, 'active', true);
            ConfigurationActions.update_attribute(ConfigurationConstants.FEATURE_CONTROLS.CDN_AKAMAI_FORENSIC_STREAMING.id, 'active', true);
            break;
        }
    }

    render() {
        const cdnOptions = ConfigurationConstants.toArray('CDN_OPTIONS');

        const limelight_streaming_active = this.props.configuration.getIn([ConfigurationConstants.FEATURE_CONTROLS.CDN_LIMELIGHT_FORENSIC_STREAMING.id, 'active']);
        const akamai_streaming_active = this.props.configuration.getIn([ConfigurationConstants.FEATURE_CONTROLS.CDN_AKAMAI_FORENSIC_STREAMING.id, 'active']);

        let selectedCdnOption;
        /*istanbul ignore next*/
        if (limelight_streaming_active) {
            selectedCdnOption = cdnOptions[0];
        }

        /*istanbul ignore next*/
        if (akamai_streaming_active) {
            selectedCdnOption = cdnOptions[1];
        }

        /*istanbul ignore next*/
        if (akamai_streaming_active && limelight_streaming_active) {
            selectedCdnOption = cdnOptions[2];
        }

        const buildCheckbox = (attr) => {
            const model = this.props.configuration.get(attr);
            if (model) {
                return <FormItem
                    attr="active"
                    label={attr}
                    disabled={false}
                    model={model}
                    setter={/*istanbul ignore next*/(a, v) => ConfigurationActions.update_attribute(attr, a, v)}
                    type="checkbox"
                />;
            }
        };

        const sectionCDNs = <FormRow>
            <FormGroup>
                <ControlLabel>{this.context.intl.messages['hardac.setup.configuration.options.cdns']}</ControlLabel>
                <FormRow>
                    <div className="col-md-4">
                        <Select
                            classNamePrefix="dropdown"
                            getOptionLabel={GetAttr('name')}
                            getOptionValue={GetAttr('id')}
                            isClearable={false}
                            isMulti={false}
                            isSearchable={false}
                            name="cdn"
                            options={cdnOptions}
                            value={selectedCdnOption}
                            onChange={this.handleSetCDN} />
                    </div>
                </FormRow>
            </FormGroup>
        </FormRow>;


        const sectionForensicHandling = <FormRow>
            <FormGroup>
                <ControlLabel>{this.context.intl.messages['hardac.setup.configuration.options.forensic-handling']}</ControlLabel>
                <FormRow>
                    <div className="col-md-4">
                        {buildCheckbox(ConfigurationConstants.FEATURE_CONTROLS.CDN_AKAMAI_FORENSIC_HANDLING.id)}
                    </div>
                    <div className="col-md-4">
                        {buildCheckbox(ConfigurationConstants.FEATURE_CONTROLS.CDN_LIMELIGHT_FORENSIC_ALGO2_HANDLING.id)}
                    </div>
                    <div className="col-md-4">
                        {buildCheckbox(ConfigurationConstants.FEATURE_CONTROLS.CDN_LIMELIGHT_FORENSIC_ALGO4_HANDLING.id)}
                    </div>
                </FormRow>
            </FormGroup>
        </FormRow>;

        // Section: Other Feature Flags (all remaining/uncategorized options will appear here automatically when returned by api)
        let otherFeatureKeys = Object.keys(this.props.configuration.toJS()).filter((v) => {
            return !ConfigurationConstants.toArray('FEATURE_CONTROLS').map(k => k.id).includes(v);
        });

        otherFeatureKeys = otherFeatureKeys.sort();

        let groups = [[], [], []];
        let groupNum = 0;
        otherFeatureKeys.forEach(k => {
            if (groups[groupNum].length > (otherFeatureKeys.length/3)) {
                groupNum++;
            }
            groups[groupNum].push(k);
        });

        const sectionOtherFeatures = (
            <FormRow>
                <div className="col-md-4">
                    {groups[0].map(k => buildCheckbox(k))}
                </div>
                <div className="col-md-4">
                    {groups[1].map(k => buildCheckbox(k))}
                </div>
                <div className="col-md-4">
                    {groups[2].map(k => buildCheckbox(k))}
                </div>
            </FormRow>
        );

        return (
            <div>
                <FormSection>
                    {sectionCDNs}

                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['hardac.setup.configuration.options.other-feature-flags']}</ControlLabel>
                        {sectionOtherFeatures}
                    </FormGroup>


                    {sectionForensicHandling}
                </FormSection>
            </div>
        );
    }
}

export default OptionsTab;
