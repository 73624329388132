/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Modal, OverlayTrigger, Tooltip, ResponsiveEmbed} from 'react-bootstrap';

import {Alert} from '../../common/notification/alert';
import {GetThumbnail} from '../../common/utils/utils';
import Config from '../../config/config';
import Player from '../../player';
import {AssetTitleActions} from '../asset-title-actions';
import AssetTitleStore from '../asset-title-store';
import VideoStore from '../video/video-store';

class VideoPreviewModal extends React.Component {

    static get propTypes() {
        return {
            canVideoPlayback: PropTypes.bool,
            onHide: PropTypes.func.isRequired,
            onShowPlayer: PropTypes.func.isRequired,
            show: PropTypes.bool,
            showPlayer: PropTypes.bool,
            showTitle: PropTypes.bool,
            video: PropTypes.instanceOf(Immutable.Map),
            viewingMode: PropTypes.string.isRequired,
        };
    }

    static get defaultProps() {
        return {
            canVideoPlayback: false,
            show: false,
            showPlayer: false,
            showTitle: false,
            video: Immutable.Map()
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
        };
    }

    static calculateState() {
        return {
            titles: AssetTitleStore.getState().get('titles'),
        };
    }

    static getStores() {
        return [AssetTitleStore];
    }

    constructor(props) {
        super(props);

        this.handleClose = this.handleClose.bind(this);
        this.handleOpenInWBTVD = this.handleOpenInWBTVD.bind(this);
        this.handleShowPlayer = this.handleShowPlayer.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.showTitle) {
            if ((prevProps.video?.get('id') !== this.props.video?.get('id')) && this.props.video.get('id')) {
                AssetTitleActions.get(this.props.video?.get('id'));
            }
        }
    }

    handleClose() {
        this.props.onHide();
    }

    handleOpenInWBTVD(activeTitle) {
        window.open(`${Config.WBTVDBaseUrl}/titles/${activeTitle.get('id')}/videos/${this.props.video.get('id')}`);
    }

    handleShowPlayer() {
        this.props.onShowPlayer();
    }

    render() {
        let assetRenderer;

        // eslint-disable-next-line @typescript-eslint/no-var-requires
        let thumbnailUrl = require('../../layout/placeholder-horizontal.svg');
        let thumbnail = GetThumbnail(this.props.video?.get('thumbnails'), 800);
        if (thumbnail) {
            thumbnailUrl = thumbnail.get('thumbnailUrl');
        }

        let playIcon;
        if (this.props.canVideoPlayback) {
            playIcon = (
                <span
                    className="thumbnail-play-icon glyphicon glyphicon-play-circle"
                    onClick={this.handleShowPlayer}
                ></span>
            );
        }

        assetRenderer = (
            <ResponsiveEmbed a16by9>
                <div>
                    <img
                        className="img-thumbnail"
                        src={thumbnailUrl}
                    />
                    {playIcon}
                </div>
            </ResponsiveEmbed>
        );

        if (this.props.showPlayer) {
            assetRenderer = (
                <ResponsiveEmbed a16by9>
                    <Player
                        position="static"
                        src={VideoStore.convertVideoAssetToPlayerSource(this.props.video)}
                        video={this.props.video}
                    />
                </ResponsiveEmbed>
            );
        }

        const activeTitle = this.state.titles.find(title=> title.get('active') === 1);
        let openInWBTVDButton;
        if (this.props.showTitle && this.props.video.get('active')) {
            if (!this.state.titles.size) {
                const renderTooltip = <Tooltip id="view-in-wbtvd-tooltip">{this.context.intl.messages['titles.create.preview-modal.view-in-wbtvd.disabled']}</Tooltip>;
                openInWBTVDButton = (
                    <OverlayTrigger placement="top" overlay={renderTooltip}>
                        <Button
                            id="view-in-wbtvd-disabled"
                            className="btn btn-navy-outline Mr(3px) Mb(3px) pull-left disabled"
                        >
                            <i className="fas fa-external-link"></i>&nbsp;{this.context.intl.messages['titles.create.preview-modal.view-in-wbtvd']}
                        </Button>
                    </OverlayTrigger>
                );
            } else if (activeTitle) {
                openInWBTVDButton = (
                    <Button
                        className="btn btn-navy-outline Mr(3px) Mb(3px) pull-left"
                        onClick={this.handleOpenInWBTVD.bind(this, activeTitle)}
                    >
                        <i className="fas fa-external-link"></i>&nbsp;{this.context.intl.messages['titles.create.preview-modal.view-in-wbtvd']}
                    </Button>
                );
            }
        }

        return (
            <Modal dialogClassName="modal-900w" show={this.props.show} onHide={this.handleClose} className={ClassNames({'skin-dark': this.props.viewingMode === 'skin-dark'})}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="text-center">{this.context.intl.messages['titles.create.preview-modal']}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert withHeader/>
                    {assetRenderer}
                </Modal.Body>
                <Modal.Footer>
                    {openInWBTVDButton}
                    <Button
                        className="btn btn-default-outline Mr(3px) Mb(3px)"
                        onClick={this.handleClose}
                    >
                        <i className="fas fa-times"/>&nbsp;{this.context.intl.messages['common.close']}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default Container.create(VideoPreviewModal);
