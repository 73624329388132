/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';

import {AlertTypes} from '../../../common/notification/alert';
import {NotificationActions} from '../../../common/notification/notification-actions';
import {CompareImmutableList, SubtractImmutableSet, UploadFile} from '../../../common/utils/utils';
import Dispatcher from '../../../dispatcher/dispatcher';
import Request from '../../../request';
import {RouterActions} from '../../../router/router-actions';

const CONSTANTS = {
    MASS_UPDATE: {
        CLEAR: 'asset-catalogs.mass_update.clear',
        CLEAR_REMOVELIST: 'asset-catalogs.mass_update.clear_removelist',
        FINDBY: {
            ERROR: 'asset-catalogs.mass_update.find_by.error',
            SUCCESS: 'asset-catalogs.mass_update.find_by.success'
        },
        CATALOG: {
            ADD: 'asset-catalogs.mass_update.catalog.add',
            REMOVE: 'asset-catalogs.mass_update.catalog.remove'
        },
        GET: {
            ASSET: {
                CATALOGS: 'asset-catalogs.mass_update.get.asset.catalogs',
                ERROR: 'asset-catalogs.mass_update.get.asset.error',
                SUCCESS: 'asset-catalogs.mass_update.get.asset.success'
            },
            CATALOGS: 'asset-catalogs.mass_update.get.catalogs',
            PROCESS: 'asset-catalogs.mass_update.get.process'
        },
        OPERATION_TYPES: {
            ADD: 'Add',
            REMOVE: 'Remove',
            REPLACE: 'Replace'
        },
        SAVE: {
            ERROR: 'asset-catalogs.mass_update.save.error',
            SUCCESS: 'asset-catalogs.mass_update.save.success'
        },
        SET: {
            MODE: 'asset-catalogs.mass_update.set.mode'
        },
    }
};


class MassUpdateActions {
    addCatalog(catalog, isRemoveList) {
        let catalogList;
        if (isRemoveList) {
            catalogList = 'removeList';
        } else {
            catalogList = 'assignedCatalogs';
        }
        Dispatcher.dispatch({
            actionType: CONSTANTS.MASS_UPDATE.CATALOG.ADD,
            catalog: catalog,
            catalogList: catalogList
        });
    }

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.MASS_UPDATE.CLEAR
        });
        return;
    }

    clearRemoveList() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.MASS_UPDATE.CLEAR_REMOVELIST
        });
        return;
    }

    findById(id) {
        Promise.all([
            Request.get(`asset/catalog/ingest/${id}`).exec(),
            Request.get(`asset/catalog/ingest/${id}/rows`).exec()
        ])
            .spread((ingestHeaderRes, rowsRes) => {
                this.getAssets(rowsRes.body);
                Dispatcher.dispatch({
                    actionType: CONSTANTS.MASS_UPDATE.FINDBY.SUCCESS,
                    massUpdateProcess: Immutable.fromJS(ingestHeaderRes.body),
                    rows: Immutable.fromJS(rowsRes.body)
                });
            })
            .catch(err => {
                switch (err.status) {
                case 404:
                    RouterActions.notFound();
                    break;
                default:
                    Dispatcher.dispatch({
                        actionType: CONSTANTS.MASS_UPDATE.FINDBY.ERROR,
                        error: err
                    });
                    NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'catalogs.mass-update.error.loading');
                    break;
                }
                throw err;
            });
        return;
    }

    getAssets(assets) {
        assets.forEach((asset, i) => {
            let assetId = asset.assetId;
            Promise.all([
                Request.get(`asset/${assetId}`).exec(),
                Request.get(`asset/${assetId}/catalog`).exec()
            ])
                .spread((assetRes, assetCatalogsRes) => {
                    Dispatcher.dispatch({
                        actionType: CONSTANTS.MASS_UPDATE.GET.ASSET.SUCCESS,
                        assetCatalogs: Immutable.fromJS(assetCatalogsRes.body),
                        assetIndex: i,
                        assetName: assetRes.body.assetName,
                        assetType: assetRes.body.displayAssetTypeName
                    });
                    return;
                })
                .catch(err => {
                    switch (err.status) {
                    case 404:
                        Dispatcher.dispatch({
                            actionType: CONSTANTS.MASS_UPDATE.GET.ASSET.ERROR,
                            assetIndex: i
                        });
                        break;
                    }
                });
            return;
        });
    }

    getCatalogs() {
        Request.get('security/group')
            .query({
                'category-id': 2,
                offset: 0,
                size: 999999
            })
            .exec()
            .then(res => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.MASS_UPDATE.GET.CATALOGS,
                    catalogs: Immutable.fromJS(res.body.results)
                });
                return;
            });
        return;
    }

    getConfirmationData(id) {
        Promise.all([
            Request.get(`asset/catalog/ingest/${id}`).exec(),
            Request.get(`asset/catalog/ingest/${id}/rows`).exec()
        ])
            .spread((ingestHeaderRes, rowsRes) => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.MASS_UPDATE.GET.PROCESS,
                    massUpdateProcess: Immutable.fromJS(ingestHeaderRes.body)
                });
                return Promise.all(rowsRes.body.map(
                    asset => Request.get(`asset/${asset.assetId}/catalog`).exec()
                ));
            }).then(assetCatalogsRes => {
                let assignedCatalogs = [];
                assetCatalogsRes.forEach(res => {
                    res.body.forEach(catalog => {
                        assignedCatalogs.push(catalog);
                    });
                });
                Dispatcher.dispatch({
                    actionType: CONSTANTS.MASS_UPDATE.GET.ASSET.CATALOGS,
                    catalogs: Immutable.fromJS(assignedCatalogs)
                });
                return;
            }).catch(err => {
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'catalogs.mass-update.error.loading');
                throw err;
            });
        return;
    }

    removeCatalog(catalog, isRemoveList) {
        let catalogList;
        if (isRemoveList) {
            catalogList = 'removeList';
        } else {
            catalogList = 'assignedCatalogs';
        }
        Dispatcher.dispatch({
            actionType: CONSTANTS.MASS_UPDATE.CATALOG.REMOVE,
            catalog,
            catalogList
        });
    }

    save(action, id, catalogs, massUpdateProcess, originalAssignedCatalogs) {

        if (action === 'Add') {
            if (CompareImmutableList(catalogs, originalAssignedCatalogs)) {
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'catalogs.mass-update.save.error.noCatalogs');
                return;
            }
            let originalSet = originalAssignedCatalogs.toSet();
            let newSet = catalogs.toSet();
            let toAdd = SubtractImmutableSet(newSet, originalSet);
            catalogs = toAdd;
        }

        massUpdateProcess = massUpdateProcess.toJS();
        massUpdateProcess.operationType = action;

        let catalogsData = catalogs.map(g => {
            return {
                groupId: g.get('id')
            };
        });
        let requests = [];
        if (catalogsData.size) {
            requests.push(
                Request.put(`asset/catalog/ingest/${id}/catalog`).send(catalogsData.toJS()).exec()
            );
        }
        requests.push(
            Request.put(`asset/catalog/ingest/${id}`).send(massUpdateProcess).exec()
        );
        Promise.all(requests)
            .then(() => {
                Request.post(`asset/catalog/ingest/${id}/process`).exec()
                    .then(() => {
                        Dispatcher.dispatch({
                            actionType: CONSTANTS.MASS_UPDATE.SAVE.SUCCESS
                        });
                        RouterActions.redirect('/catalogs/asset');
                        NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, `catalogs.mass-update.save.success${action}`);
                    })
                    .catch(err => {
                        Dispatcher.dispatch({
                            actionType: CONSTANTS.MASS_UPDATE.SAVE.ERROR,
                            error: err
                        });
                        NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, `catalogs.mass-update.save.error${action}`);
                        throw err;
                    });
                return;
            });
    }

    setMode(id, mode) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.MASS_UPDATE.SET.MODE,
            mode: mode
        });
        RouterActions.redirect(`/catalogs/asset/mass-update/${id}/confirmation`);
    }

    toggleSelect(value, catalog) {
        if (value) {
            this.addCatalog(catalog, true);
        } else {
            this.removeCatalog(catalog, true);
        }
        return;
    }

    uploadMassUpdate(file) {
        if (file) {
            return UploadFile(
                'POST',
                'asset/catalog/ingest',
                file,
                new XMLHttpRequest()
            ).then((res) => {
                let id = JSON.parse(res).assetCatalogIngestHeaderId;
                let statusMessage = JSON.parse(res).batchErrorMessage;
                if (statusMessage !== null) {
                    NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'catalogs.mass-update.error.uploading-file');
                } else {
                    RouterActions.redirect(`/catalogs/asset/mass-update/${id}`);
                }
            }).catch(err => {
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'catalogs.mass-update.error.uploading-file');
                throw err;
            });
        }
    }
}

let actions = new MassUpdateActions();

export {
    actions as MassUpdateActions,
    CONSTANTS as MassUpdateConstants
};
