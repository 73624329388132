/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ControlLabel, FormGroup} from 'react-bootstrap';

import {FormSection, FormRow, FormItem} from '../../../common/form/form';
import {CompareImmutable} from '../../../common/utils/utils';
import {WithValidations} from '../../../common/validations/validations';
import {UserActions, UserConstants} from '../../../user/user-actions';
import UserStore, {UserValidations} from '../../../user/user-store';

const userProps = [
    'accountStatus', 'accountType', 'applicationDate', 'jobTitle',
    'serviceCompany', 'referrerEmail'
];

class AdditionalInformation extends Component {

    static get propTypes() {
        return {
            disabled: PropTypes.bool,
            mode: PropTypes.string.isRequired,
            user: PropTypes.instanceOf(Immutable.Map).isRequired,
            userType: PropTypes.instanceOf(Immutable.Map)
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true,
            userType: Immutable.Map()
        };
    }

    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps) {
        return !CompareImmutable(nextProps.user, this.props.user, userProps);
    }

    render() {
        let applicationDate = <div>{this.context.intl.messages['common.na']}</div>;
        let expirationDate = <div>{this.context.intl.messages['common.na']}</div>;
        let status = this.context.intl.messages['common.na'];

        if (this.props.mode === 'edit') {
            if (this.props.user.get('applicationDate')) {
                applicationDate = <div>{Moment(this.props.user.get('applicationDate')).format(this.context.intl.messages['date-format'])}</div>;
            }
            if (this.props.user.get('expirationDate')) {
                expirationDate = <div>{Moment(this.props.user.get('expirationDate')).format(this.context.intl.messages['date-format'])}</div>;
            }

            // Status Value as well as tooltip
            let st = UserStore.getStatus(this.props.user) || {
                description: '',
                icon: '',
                label: ''
            };
            let tip = '';
            if (st.tip) {
                tip = this.context.intl.messages[st.tip];
            }
            status = <span className={ClassNames('label', st.label)} data-toggle="tooltip" title={tip} data-placement="top"><i className={ClassNames(st.icon)}></i>&nbsp;<span className="hidden-xs hidden-sm">{st.description}</span></span>;

            if (this.props.userType.get('id') === UserConstants.USER_TYPES.ANONYMOUS.id) {
                return (
                    <FormSection title={this.context.intl.messages['accounts.create.summary.additional-information']} iconClass="fas fa-info-circle">
                        <FormRow>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['accounts.create.summary.apply-date']}</ControlLabel>
                                {applicationDate}
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['accounts.create.summary.expiration-date']}</ControlLabel>
                                {expirationDate}
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['accounts.create.summary.status']}</ControlLabel>
                                <br/>
                                {status}
                            </FormGroup>
                        </FormRow>
                    </FormSection>
                );
            }
        }

        return (
            <FormSection title={this.context.intl.messages['accounts.create.summary.additional-information']} iconClass="fas fa-info-circle">
                <FormRow>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['accounts.create.summary.apply-date']}</ControlLabel>
                        {applicationDate}
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['accounts.create.summary.status']}</ControlLabel>
                        <br/>
                        {status}
                    </FormGroup>
                    <FormItem attr="jobTitle"
                        disabled={this.props.disabled}
                        label={this.context.intl.messages['accounts.create.summary.user-title']}
                        model={this.props.user}
                        setter={UserActions.updateUser}
                        type="text"
                        validations={UserValidations.jobTitle.validations}
                    />
                </FormRow>
                <hr/>
                <FormRow>
                    <FormItem attr="referrerEmail"
                        disabled={this.props.disabled}
                        label={this.context.intl.messages['accounts.create.summary.referrers-email']}
                        model={this.props.user}
                        setter={UserActions.updateUser}
                        type="text"
                        validations={UserValidations.referrerEmail.validations}
                    />
                </FormRow>
            </FormSection>
        );
    }
}

export default WithValidations(AdditionalInformation);
