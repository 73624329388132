/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';
import {BehaviorSubject, Subscription, combineLatest} from 'rxjs';


type GetComponentProps<T> = T extends React.ComponentType<infer P> | React.Component<infer P> ? P : never

export default function WithRxSubscriptions<
    ComponentType extends React.ComponentType<any>,
    ComponentProps extends GetComponentProps<ComponentType>,
    K extends keyof ComponentProps,
    MapOfStores extends {[key in K]?: BehaviorSubject<ComponentProps[K]>},
    SK extends string & keyof MapOfStores,
    State extends Pick<ComponentProps, SK>
>(Component: ComponentType, stores: MapOfStores): React.ComponentType<Omit<ComponentProps, SK>> {
    const generateState = () => {
        return (Object.keys(stores)).reduce(<T extends keyof State & keyof MapOfStores & string>(state: State, prop: T) => {
            return {...state, [prop]: stores[prop]?.value};
        }, {}) as State;
    };

    return class extends React.PureComponent<ComponentProps> {

        constructor(props: ComponentProps) {
            super(props);
            this.state = generateState();
            this.refreshState = this.refreshState.bind(this);
        }

        componentDidMount() {
            this.subscription = combineLatest(Object.values(stores)).subscribe(this.refreshState);
        }

        componentWillUnmount() {
            this.subscription?.unsubscribe();
        }

        subscription: null | Subscription = null;

        private refreshState() {
            this.setState(() => generateState());
        }

        render() {
            return (
                <Component {...this.props} {...this.state} />
            );
        }
    };
}

