/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

export const MIN_DURATION_OF_CUE = 1; // 1s

export const STICKY_CUE_PERIOD = .1; // 100ms

export const CLOSED_CAPTION: WebVttFileType = 1;
export const SUBTITLES: WebVttFileType = 2;

export const STATUS_TYPES = {
    UNREVIEWED: 'UNREVIEWED',
    PASSEDQC: 'PASSEDQC',
} as const;

export const SOURCE_TYPES = {
    VI: 'VI',
    MASTERING: 'MASTERING',
    USER: 'USER',
} as const;

export const SOURCE_TYPE_LABELS: Record<VideoWebVttSourceType, string> = {
    [SOURCE_TYPES.VI]: 'cc-editor.vtt.source.vi',
    [SOURCE_TYPES.MASTERING]: 'cc-editor.vtt.source.mastering',
    [SOURCE_TYPES.USER]: 'cc-editor.vtt.source.user',
};

export const DEFAULT_FRAME_RATE = 23.976;
