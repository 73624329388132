/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Checkbox, ControlLabel, Button, Modal} from 'react-bootstrap';

import VideoStore from '~/src/assets/video/video-store';
import {AssetTabActions} from '~/src/assets-tab/asset-tab-actions';
import AssetTabStore from '~/src/assets-tab/asset-tab-store';
import ReplaceAssetMetadata from '~/src/assets-tab/replace-asset-metadata';
import SlidingPanel from '~/src/common/sliding-panel/sliding-panel';
import {SlidingPanelActions} from '~/src/common/sliding-panel/sliding-panel-actions';

class ReplaceVideoPanel extends Component {
    static get propTypes() {
        return {
            caller: PropTypes.string.isRequired,
            headerTitle: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
            title: PropTypes.node.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            videoAsset: VideoStore.getState().get('asset'),
            copyFromAsset: AssetTabStore.getState().get('copyFromAsset'),
            copyToAsset: AssetTabStore.getState().get('copyToAsset'),
        };
    }

    static getStores() {
        return [VideoStore, AssetTabStore];
    }

    constructor(props) {
        super(props);
        this.state = Object.assign({
            copyMetadata: true, // Note: API currently doesn't support optional control params for what to copy, so we set this to true simply for the ui
            copyRestrictions: true,
            copyTitleCatalogs: true,
            showModal: false,
        }, this.constructor.calculateState());

        this.replaceVideo = this.replaceVideo.bind(this);
        this.toggleCopyData = this.toggleCopyData.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }

    replaceVideo() {
        // Close modal, call api, close panel
        this.toggleModal();
        AssetTabActions.replaceAssetMetadata(
            this.state.copyToAsset,
            this.state.copyFromAsset,
            this.props.id,
            this.props.caller,
            this.state.copyRestrictions,
            this.state.copyTitleCatalogs
        );
        SlidingPanelActions.hide('replaceVideo');
    }

    toggleCopyData(data) {
        this.setState((prevState) => ({[data]: !prevState[data]}));
    }

    toggleModal() {
        this.setState((prevState) => ({showModal: !prevState.showModal}));
    }

    render() {
        const confirmModal = (
            <Modal backdrop="static" show={this.state.showModal} onHide={this.toggleModal}>
                <Modal.Header className="alert-success" closeButton>
                    <Modal.Title className="text-center" >{this.context.intl.messages['titles.create.assets.replace-video.panel.modal.title']}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{this.context.intl.messages['titles.create.assets.replace-video.panel.modal.body.please-confirm']} <strong>{this.state.copyToAsset.get('name')}</strong>&nbsp;
                        with <strong>{this.state.copyFromAsset.get('assetName')}</strong></p>
                    <hr />
                    <ControlLabel>{this.context.intl.messages['titles.create.assets.replace-video.panel.modal.body.select-data']}</ControlLabel>
                    <Checkbox
                        disabled={true}
                        checked={this.state.copyMetadata}
                        onChange={this.toggleCopyData.bind(this, 'copyMetadata')}>
                        {this.context.intl.messages['titles.create.assets.replace-video.panel.modal.body.select-data.metadata']}
                    </Checkbox>
                    <Checkbox
                        disabled={true}
                        // For now when replacing a video event catalogs will be copied always. API doesnt not support a flag for this
                        checked={true}>
                        {this.context.intl.messages['titles.create.assets.replace-video.panel.modal.body.select-data.event-catalogs']}
                    </Checkbox>
                    <Checkbox
                        checked={this.state.copyRestrictions}
                        onChange={this.toggleCopyData.bind(this, 'copyRestrictions')}>
                        {this.context.intl.messages['titles.create.assets.replace-video.panel.modal.body.select-data.restrictions']}
                    </Checkbox>
                    <Checkbox
                        checked={this.state.copyTitleCatalogs}
                        onChange={this.toggleCopyData.bind(this, 'copyTitleCatalogs')}>
                        {this.context.intl.messages['titles.create.assets.replace-video.panel.modal.body.select-data.title-catalogs']}
                    </Checkbox>
                    <hr />
                    <p><em><small>{this.context.intl.messages['titles.create.assets.replace-video.panel.modal.body.note']}</small></em></p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-default-outline pull-left" onClick={this.toggleModal}>
                        <i className="fas fa-ban" /> {this.context.intl.messages['common.cancel']}</Button>
                    <Button className="btn btn-primary-outline" onClick={this.replaceVideo}>
                        <i className="fa-solid fa-right-left" /> {this.context.intl.messages['titles.create.assets.replace-video']}
                    </Button>
                </Modal.Footer>
            </Modal>
        );

        const slidePanelTitle = <span>{this.props.headerTitle} <small>{this.props.title}</small></span>;
        /* istanbul ignore next */
        const replaceVideoButton = (
            <button
                className="btn btn-large bg-green"
                onClick={this.toggleModal}
                disabled={!this.state.copyFromAsset.get('id') || !this.state.copyToAsset.get('assetId')}
            >
                <i className="fa-solid fa-right-left"></i>&nbsp;{this.context.intl.messages['titles.create.assets.replace-video']}
            </button>
        );
        return (
            <div>
                <SlidingPanel
                    id="replaceVideo"
                    title={slidePanelTitle}
                    icon="fa-solid fa-right-left"
                    extraButtons={replaceVideoButton}
                >
                    <div>
                        <ReplaceAssetMetadata assetType="video" caller="replaceVideo"/>
                    </div>
                </SlidingPanel>
                {confirmModal}
            </div>
        );
    }
}

export {ReplaceVideoPanel};

export default Container.create(ReplaceVideoPanel);

