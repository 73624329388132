/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import createReactClass from 'create-react-class';
import Immutable from 'immutable';
import jQuery from 'jquery';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('../../../../styles/data-tables-brainiac.css');
require('datatables.net-responsive-bs');

let ForensicTable = createReactClass({
    propTypes: {
        forensicUrls: PropTypes.instanceOf(Immutable.List).isRequired
    },

    contextTypes: {
        intl: PropTypes.object.isRequired
    },

    componentDidMount: function() {
        this.$table = jQuery('#forensic-urls-table');
        this.$tableAPI = this.$table.DataTable({
            autoWidth: false,
            columnDefs: [{
                className: 'control',
                targets:   -1,
                width: 20
            }, {
                targets: 'no-sort',
                orderable: false
            }],
            info: false,
            ordering: false,
            paging: false,
            iDisplayLength: 100,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        });

        window.addEventListener('resize', this.handleResize);

        this.handleResize();
        return;
    },

    componentWillUpdate: function(nextProps) {
        this.$tableAPI.clear();

        nextProps.forensicUrls.forEach((f) => {
            let assignedDate = Moment(f.get('userIdAssignedDate'));
            if (assignedDate.isValid()) {
                assignedDate = assignedDate.format(this.context.intl.messages['datetime-format']);
            } else {
                assignedDate = '-';
            }
            let row = [
                f.get('fullName') || '',
                f.get('partnerName') || '',
                assignedDate,
                f.get('userIdIPAddress') || '',
                f.get('nextGuardId') || '',
                ''
            ];

            this.$tableAPI.row.add(row);
            return;
        });

        this.$tableAPI.draw(false);
        this.handleResize();
        return;
    },

    componentWillUnmount: function() {
        if (this.$tableAPI) {
            this.$tableAPI.destroy();
        }
        return;
    },

    handleResize: function() {
        this.$tableAPI.responsive.recalc();
        return;
    },

    render: function() {
        return (
            <table id="forensic-urls-table" className="table table-bordered table-striped responsive">
                <thead>
                    <tr>
                        <th>User</th>
                        <th>Partner</th>
                        <th>View Timestamp</th>
                        <th>IP Address</th>
                        <th>nextGuard ID / Url</th>
                        <th className="no-sort"></th>
                    </tr>
                </thead>
                <tbody>
                </tbody>
            </table>
        );
    }
});

export default ForensicTable;
