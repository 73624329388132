/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {LanguageCodeType} from '@wbdt-sie/brainiac-web-common';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ControlLabel, FormGroup} from 'react-bootstrap';

import {FormRow, FormSection} from '../../../common/form/form';
import {SectionPanel} from '../../../common/panel/panel';
import {LocalizedTinyMCE} from '../../../common/tinymce/tinymce';
import {CompareImmutable} from '../../../common/utils/utils';
import {WithValidations} from '../../../common/validations/validations';
import {HandleSelect} from '../../../lookup/lookup-select-helpers';
import AspectRatioSelect from '../../../lookup/title/aspect-ratio/aspect-ratio-select';
import AspectRatioStore from '../../../lookup/title/aspect-ratio/aspect-ratio-store';
import AudioSelect from '../../../lookup/title/audio/audio-select';
import AudioStore from '../../../lookup/title/audio/audio-store';
import OriginalSourceSelect from '../../../lookup/title/original-source/original-source-select';
import OriginalSourceStore from '../../../lookup/title/original-source/original-source-store';
import StandardSelect from '../../../lookup/title/standard/standard-select';
import StandardStore from '../../../lookup/title/standard/standard-store';
import {TitleActions} from '../../title-actions';
import {TitleLocalizedActions} from '../../title-localized-actions';
import {TitleValidations} from '../../title-store';

/**
 * Title props to watch.
 */
const titleProps = [
    'aspectRatioId', 'audioId', 'masteringComments',
    'sourceMediaId', 'standardId'
];

class MasteringInfo extends Component {

    static get propTypes() {
        return {
            currentLanguage: PropTypes.string,
            expanded: PropTypes.bool,
            disabled: PropTypes.bool,
            title: PropTypes.instanceOf(Immutable.Map).isRequired,
            titleLocalized: PropTypes.instanceOf(Immutable.Map)
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            expanded: false,
            disabled: true,
            currentLanguage: undefined,
            titleLocalized: undefined
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign(
            this.readState(this.props), {
                isAspectRatioDirty: false,
                isAudioDirty: false,
                isOriginalSourceDirty: false,
                isStandardDirty: false
            });

        this.handleSelectAspectRatio = this.handleSelectAspectRatio.bind(this);
        this.handleSelectAudio = this.handleSelectAudio.bind(this);
        this.handleSelectOriginalSource = this.handleSelectOriginalSource.bind(this);
        this.handleSelectStandard = this.handleSelectStandard.bind(this);
        this.handleTinyMCEChange = this.handleTinyMCEChange.bind(this);
        this.readState = this.readState.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState(this.readState(nextProps));
        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.disabled !== this.props.disabled ||
            nextProps.titleLocalized !== this.props.titleLocalized || // actually have to check if name or selected language has changed
            nextProps.currentLanguage !== this.props.currentLanguage ||
            nextProps.expanded !== this.props.expanded ||
            !CompareImmutable(nextProps.title, this.props.title, titleProps)) {
            return true;
        }

        if (nextState.isAspectRatioDirty !== this.state.isAspectRatioDirty ||
            nextState.isAudioDirty !== this.state.isAudioDirty ||
            nextState.isOriginalSourceDirty !== this.state.isOriginalSourceDirty ||
            nextState.isStandardDirty !== this.state.isStandardDirty) {
            return true;
        }
        return false;
    }

    handleSelectAspectRatio(value) {
        if (!this.state.isAspectRatioDirty) {
            this.setState({isAspectRatioDirty: true});
        }

        HandleSelect('aspectRatioId', TitleActions.updateTitle, {valueAttr: 'id'})(value);
        return;
    }
    handleSelectAudio(value) {
        if (!this.state.isAudioDirty) {
            this.setState({isAudioDirty: true});
        }

        HandleSelect('audioId', TitleActions.updateTitle, {valueAttr: 'id'})(value);
        return;
    }
    handleSelectOriginalSource(value) {
        if (!this.state.isOriginalSourceDirty) {
            this.setState({isOriginalSourceDirty: true});
        }

        HandleSelect('sourceMediaId', TitleActions.updateTitle, {valueAttr: 'id'})(value);
        return;
    }
    handleSelectStandard(value) {
        if (!this.state.isStandardDirty) {
            this.setState({isStandardDirty: true});
        }

        HandleSelect('standardId', TitleActions.updateTitle, {valueAttr: 'id'})(value);
        return;
    }

    handleTinyMCEChange(f, attr) {
        return function handler(e) {
            f(attr, e.target.getContent());
        };
    }

    readState(props) {
        return {
            selectedAspectRatio: AspectRatioStore.findItemByKey('id', props.title.get('aspectRatioId')),
            selectedAudio: AudioStore.findItemByKey('id', props.title.get('audioId')),
            selectedOriginalSource: OriginalSourceStore.findItemByKey('id', props.title.get('sourceMediaId')),
            selectedStandard: StandardStore.findItemByKey('id', props.title.get('standardId'))
        };
    }

    render() {
        let localized = this.props.currentLanguage !== LanguageCodeType.DEFAULT_LANGUAGE;
        let f = TitleActions.updateTitle;
        if (localized) {
            f = TitleLocalizedActions.update;
        }

        let masteringComments = '';
        if (this.props.titleLocalized) {
            masteringComments = this.props.titleLocalized.get('masteringComments');
        }
        let disabledOnLocalized = this.props.disabled || localized;
        return (
            <SectionPanel
                expanded={this.props.expanded}
                title={this.context.intl.messages['titles.create.summary.mastering-info']}
                validationState={this.getAccordionValidationState(this.props.title, titleProps, TitleValidations)}
                localized={localized}
            >
                <FormSection>
                    <FormRow>
                        <LocalizedTinyMCE
                            content={this.props.title.get('masteringComments') || ''}
                            disabled={this.props.disabled}
                            id="mastering-comments"
                            onChange={this.handleTinyMCEChange(f, 'masteringComments')}
                            localized={localized}
                            localizedContent={masteringComments || ''}
                            title={this.context.intl.messages['titles.create.summary.mastering-comment']}
                        />
                    </FormRow>
                    <FormRow>
                        <FormGroup validationState={this.getValidationState(
                            this.props.title.get('aspectRatioId'),
                            this.state.isAspectRatioDirty,
                            TitleValidations.aspectRatioId.validations
                        )}>
                            <ControlLabel>{this.context.intl.messages['titles.create.summary.aspect-ratio']}</ControlLabel>
                            <AspectRatioSelect
                                disabled={disabledOnLocalized}
                                name={this.context.intl.messages['titles.create.summary.aspect-ratio']}
                                onChange={this.handleSelectAspectRatio}
                                value={this.state.selectedAspectRatio}
                            />
                        </FormGroup>
                        <FormGroup validationState={this.getValidationState(
                            this.props.title.get('audioId'),
                            this.state.isAudioDirty,
                            TitleValidations.audioId.validations
                        )}>
                            <ControlLabel>{this.context.intl.messages['titles.create.summary.audio']}</ControlLabel>
                            <AudioSelect
                                disabled={disabledOnLocalized}
                                name={this.context.intl.messages['titles.create.summary.audio']}
                                onChange={this.handleSelectAudio}
                                value={this.state.selectedAudio}
                            />
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <FormGroup validationState={this.getValidationState(
                            this.props.title.get('standardId'),
                            this.state.isStandardDirty,
                            TitleValidations.standardId.validations
                        )}>
                            <ControlLabel>{this.context.intl.messages['titles.create.summary.standard']}</ControlLabel>
                            <StandardSelect
                                disabled={disabledOnLocalized}
                                name={this.context.intl.messages['titles.create.summary.standard']}
                                onChange={this.handleSelectStandard}
                                value={this.state.selectedStandard}
                            />
                        </FormGroup>
                        <FormGroup validationState={this.getValidationState(
                            this.props.title.get('sourceMediaId'),
                            this.state.isOriginalSourceDirty,
                            TitleValidations.sourceMediaId.validations
                        )}>
                            <ControlLabel>{this.context.intl.messages['titles.create.summary.source-media']}</ControlLabel>
                            <OriginalSourceSelect
                                disabled={disabledOnLocalized}
                                name={this.context.intl.messages['titles.create.summary.source-media']}
                                onChange={this.handleSelectOriginalSource}
                                value={this.state.selectedOriginalSource}
                            />
                        </FormGroup>
                    </FormRow>
                </FormSection>
            </SectionPanel>
        );
    }
}

export default WithValidations(MasteringInfo);
