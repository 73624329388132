/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React from 'react';

import SettingsModal from './caption-positioning-modal';
import {IsChangedSelectedCue$, ResetSelectedCues, ShowCaptionPositioningModal} from '../../bl';
import CCEditorActions from '../../cc-editor-actions';
import NoBrBlock from '../common/nobr-block';

import {MODAL_TYPE} from '~/src/common/notification/modal';
import {NotificationActions} from '~/src/common/notification/notification-actions';
import WithRxSubscriptions from '~/src/decorators/with-rx-subscriptions';

type Props = {
    cues: ReadonlyArray<WBTVDCue>,
    frameRate?: number,
    isChanged: boolean,
}

export class SingleCueEditor extends React.PureComponent<Props> {
    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props: Props) {
        super(props);

        this.cancelEditing = this.cancelEditing.bind(this);
        this.handleSaveRequest = this.handleSaveRequest.bind(this);
        this.removeCue = this.removeCue.bind(this);
        this.showRemovingConfirmation = this.showRemovingConfirmation.bind(this);
    }

    private async cancelEditing() {
        if (this.props.isChanged) {
            const confirmText = this.context.intl.messages['common.confirm'];
            const message = this.context.intl.messages['cc-editor.cue.cancel.confirm'];
            NotificationActions.show(MODAL_TYPE.DANGER, confirmText, message, confirmText, ResetSelectedCues);
        } else {
            ResetSelectedCues();
        }
    }

    private handleSaveRequest() {
        CCEditorActions.changeCues(this.props.cues);
    }

    private async removeCue() {
        CCEditorActions.removeCues(this.props.cues);
    }

    private showRemovingConfirmation() {
        const confirmText = this.context.intl.messages['common.confirm'];
        const message = this.context.intl.messages['cc-editor.cue.remove.confirm'];

        NotificationActions.show(MODAL_TYPE.DANGER, confirmText, message, confirmText, this.removeCue);
    }

    render() {
        return (
            <div className="cue-editor form-section">
                <NoBrBlock>
                    <button
                        className="btn btn-sm btn-default-outline"
                        data-testid="settings-button"
                        onClick={ShowCaptionPositioningModal}
                    >
                        <i className="fas fa-cog"/>
                    </button>
                </NoBrBlock>
                <NoBrBlock>
                    <button
                        className="btn btn-sm btn-success-outline cue-action-btn"
                        data-testid="save-button"
                        disabled={!this.props.isChanged}
                        onClick={this.handleSaveRequest}
                    >
                        <i className="fas fa-check"/>
                    </button>
                    <button
                        className="btn btn-sm btn-danger-outline cue-action-btn"
                        data-testid="cancel-button"
                        onClick={this.cancelEditing}
                    >
                        <i className="fas fa-times"/>
                    </button>
                    <button
                        className="btn btn-sm btn-danger-outline cue-action-btn"
                        data-testid="remove-button"
                        onClick={this.showRemovingConfirmation}
                    >
                        <i className="fas fa-trash"/>
                    </button>
                </NoBrBlock>

                <SettingsModal />
            </div>
        );
    }
}

export default WithRxSubscriptions(SingleCueEditor, {
    isChanged: IsChangedSelectedCue$,
});
