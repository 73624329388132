/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import createReactClass from 'create-react-class';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Modal} from 'react-bootstrap';

import {FormItem} from '../../common/form/form';

export default createReactClass({

    propTypes: {
        item: PropTypes.instanceOf(Immutable.Map),
        onHide: PropTypes.func.isRequired,
        onRemove: PropTypes.func.isRequired,
        show: PropTypes.bool,
        title: PropTypes.string.isRequired
    },

    contextTypes: {
        intl: PropTypes.object.isRequired,
    },

    getDefaultProps: function() {
        return {
            item: Immutable.Map(),
            show: false,
        };
    },

    getInitialState: function() {
        return {
            model: Immutable.Map(),
        };
    },

    componentWillReceiveProps: function(/*nextProps*/) {
        // this is because dialog is always present (it may not be visible)
        // and once you have written something on 'remove' field will be store in state 'forever'
        // this force a clean state on every show.
        this.setState({
            model: Immutable.Map(),
        });
        return;
    },


    updateAttr: function(attr, value) {
        this.setState(prevState => ({
            model: prevState.model.setIn([...attr.split('.')], value)
        }));
    },

    handleRemove: function() {
        this.props.onHide();
        this.props.onRemove(this.props.item);
    },

    render: function() {
        let label =<p className="text-red">
            {this.context.intl.messages['lookups.remove.msg2']}
        </p>;
        return (
            <Modal show={this.props.show} onHide={this.props.onHide}>
                <Modal.Header className="bg-red" closeButton>
                    <Modal.Title className="text-center">{this.context.intl.messages['common.remove'] + ' ' + this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-red">
                        <i className="fas fa-exclamation-triangle"/>
                        <strong>&nbsp;{this.context.intl.messages['common.warning']}</strong>&nbsp;:&nbsp;
                        {this.context.intl.messages['lookups.remove.msg1'].replace('{{entity}}', this.props.title.toLowerCase())}
                    </p>
                    <hr/>
                    <FormItem
                        type="string"
                        attr="value"
                        label={label}
                        model={this.state.model}
                        setter={this.updateAttr}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button bsStyle="default" className="pull-left" onClick={this.props.onHide}>{this.context.intl.messages['common.cancel']}</Button>
                    <Button bsStyle="danger" disabled={this.state.model.get('value') !== 'Remove'} onClick={this.handleRemove}>{this.context.intl.messages['common.remove']}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
});
