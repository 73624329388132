/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ControlLabel, FormControl, FormGroup} from 'react-bootstrap';
import ReactSelect from 'react-select';

import MetadataExportSpec from '../../../accounts/create/delivery/metadata-export-spec';
import {FormItem, FormRow, FormSection} from '../../../common/form/form';
import config from '../../../config/config.js';
import UserSelect from '../../../lookup/user-select/user-select';
import {UserSelectActions} from '../../../lookup/user-select/user-select-actions';
import UserSelectStore from '../../../lookup/user-select/user-select-store';
import {WorkOrdersActions, WorkOrdersConstants} from '../work-orders-actions';
import {WorkOrdersValidations} from '../work-orders-store';

import {CompareImmutable, Debounce, GetAttr} from '~/src/common/utils/utils';

let attrs = [
    'client', 'dueDate', 'exportSpecification', 'fallbackEnglish',
    'id', 'name', 'needsArtWork', 'recipientUserId', 'status',
    'updatedBy', 'updatedDate', 'createdBy', 'createdDate'
];

class Summary extends Component {

    static get propTypes() {
        return {
            disabled: PropTypes.bool,
            mode: PropTypes.string.isRequired,
            recipientUser: PropTypes.instanceOf(Immutable.Map),
            workOrder: PropTypes.instanceOf(Immutable.Map).isRequired,
        };
    }

    static get defaultProps() {
        return {
            disabled: true,
            recipientUser: null
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.handleEnumChange = this.handleEnumChange.bind(this);
        this.handleRelatedUserInputChange = Debounce(this.handleRelatedUserInputChange.bind(this), 200);
        return;
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.disabled !== this.props.disabled ||
            !CompareImmutable(nextProps.workOrder, this.props.workOrder, attrs) ||
            nextProps.mode !== this.props.mode ||
            nextProps.recipientUser !== this.props.recipientUser) {
            return true;
        }
        return false;
    }

    handleEnumChange(attr, value) {
        WorkOrdersActions.update(attr, value?.id);
    }

    handleFallbackEnglishChange(value) {
        WorkOrdersActions.update('fallbackEnglish', value.id === 1);
    }

    handleRelatedUserInputChange(search) {
        if (search) {
            UserSelectActions.get(search);
        }
    }

    render() {
        let updatedDate = <div>N/A</div>;
        let updatedBy = <div>N/A</div>;
        let updateInformation;

        let row = [];
        let md = 12;
        if (this.props.mode === 'edit') {
            md = 6;
        }
        row.push(<FormItem
            key="name"
            attr="name"
            md={md}
            label={this.context.intl.messages['titles.work-orders.summary.name']}
            disabled={this.props.disabled}
            model={this.props.workOrder}
            setter={WorkOrdersActions.update}
            type="text"
            validations={WorkOrdersValidations.name.validations}
        />);

        if (this.props.mode === 'edit') {
            if (this.props.workOrder.get('updatedDate')) {
                updatedDate = Moment(this.props.workOrder.get('updatedDate')).tz(config.DefaultTimezone).format(this.context.intl.messages['datetime-format']);
            } else {
                updatedDate = '-';
            }

            updatedBy = this.props.workOrder.get('updatedBy');

            updateInformation = <FormRow>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['titles.work-orders.summary.last-modified-date']}</ControlLabel>
                    <FormControl.Static>{updatedDate}</FormControl.Static>
                </FormGroup>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['titles.work-orders.summary.last-modifier']}</ControlLabel>
                    <FormControl.Static>{updatedBy}</FormControl.Static>
                </FormGroup>
            </FormRow>;

            row.push(<FormGroup key="id">
                <ControlLabel>{this.context.intl.messages['titles.work-orders.summary.id']}</ControlLabel>
                <FormControl.Static>{this.props.workOrder.get('id')}</FormControl.Static>
            </FormGroup>);

            let createdDate;

            if (this.props.workOrder.get('createdDate')) {
                createdDate = Moment(this.props.workOrder.get('createdDate')).tz(config.DefaultTimezone).format(this.context.intl.messages['date-format']);
            } else {
                createdDate = '-';
            }

            row.push(<FormGroup key="createdDate">
                <ControlLabel>{this.context.intl.messages['titles.work-orders.summary.created-date']}</ControlLabel>
                <FormControl.Static>{createdDate}</FormControl.Static>
            </FormGroup>);

            let completedDate;

            if (this.props.workOrder.get('completedDate')) {
                completedDate = Moment(this.props.workOrder.get('completedDate')).tz(config.DefaultTimezone).format(this.context.intl.messages['date-format']);
            } else {
                completedDate = '-';
            }

            row.push(<FormGroup key="completedDate">
                <ControlLabel>{this.context.intl.messages['titles.work-orders.summary.completed-date']}</ControlLabel>
                <FormControl.Static>{completedDate}</FormControl.Static>
            </FormGroup>);
        }

        let selectedStatus = WorkOrdersConstants.STATUS.find((item) => {
            return item.id === this.props.workOrder.get('status');
        });

        let selectedUser = UserSelectStore.findItemByKey('id', this.props.workOrder.get('recipientUserId'));

        if (!selectedUser && this.props.recipientUser && this.props.workOrder.get('recipientUserId')) {
            let fullName;

            if (this.props.recipientUser.get('name')) {
                fullName = this.props.recipientUser.get('name');
            } else {
                fullName = '';
            }

            if (this.props.recipientUser.get('middleName')) {
                fullName += ' ' + this.props.recipientUser.get('middleName');
            }
            if (this.props.recipientUser.get('lastName')) {
                fullName += ' ' + this.props.recipientUser.get('lastName');
            }
            selectedUser = Immutable.fromJS({id: this.props.recipientUser.get('id'), name: fullName});
        }

        let fallbackEnglish = WorkOrdersConstants.EXPORT_FALLBACK_ENGLISH[0];
        if (this.props.workOrder.get('fallbackEnglish')) {
            fallbackEnglish = WorkOrdersConstants.EXPORT_FALLBACK_ENGLISH[1];
        }

        let selectedUserOption = undefined;
        if (selectedUser) {
            selectedUserOption = selectedUser.toJS();
        }
        return (
            <div>
                <h3><i className="fas fa-info-circle"></i>{this.context.intl.messages['titles.work-orders.summary.info']}</h3>
                <FormSection>
                    <FormRow>
                        {row}
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['titles.work-orders.summary.status']}</ControlLabel>
                            <ReactSelect
                                getOptionLabel={GetAttr('name')}
                                getOptionValue={GetAttr('id')}
                                isClearable={false}
                                isMulti={false}
                                isDisabled={this.props.disabled}
                                name="status"
                                onChange={this.handleEnumChange.bind(this, 'status')}
                                options={WorkOrdersConstants.STATUS}
                                value={selectedStatus}
                            />
                        </FormGroup>
                        <FormItem id="work-order-due-date"
                            attr="dueDate"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['titles.work-orders.summary.due-date']}
                            setter={WorkOrdersActions.update}
                            model={this.props.workOrder}
                            type="date"
                        />
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['titles.work-orders.summary.art-work']}</ControlLabel>
                            <FormItem
                                attr="needsArtWork"
                                disabled={true}
                                label={this.context.intl.messages['titles.work-orders.summary.art-work.text']}
                                model={this.props.workOrder}
                                setter={WorkOrdersActions.update}
                                type="checkbox"
                            />
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['accounts.create.summary.related-user']}</ControlLabel>
                            <UserSelect
                                getOptionLabel={GetAttr('name')}
                                getOptionValue={GetAttr('id')}
                                isClearable={true}
                                disabled={this.props.disabled}
                                name={this.context.intl.messages['accounts.create.summary.related-user']}
                                onChange={this.handleEnumChange.bind(this, 'recipientUserId')}
                                onInputChange={this.handleRelatedUserInputChange}
                                placeholder={this.context.intl.messages['accounts.create.summary.related-user-placeholder']}
                                value={selectedUserOption}
                            />
                        </FormGroup>
                        <FormItem attr="client"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['titles.work-orders.summary.client']}
                            setter={WorkOrdersActions.update}
                            model={this.props.workOrder}
                            type="text"
                        />
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['titles.work-orders.summary.export-fallback-english']}</ControlLabel>
                            <ReactSelect
                                getOptionLabel={data => this.context.intl.messages[data.name]}
                                getOptionValue={GetAttr('id')}
                                isClearable={false}
                                isMulti={false}
                                isDisabled={this.props.disabled}
                                name="fallbackEnglish"
                                onChange={this.handleFallbackEnglishChange.bind(this)}
                                options={WorkOrdersConstants.EXPORT_FALLBACK_ENGLISH}
                                value={fallbackEnglish}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['titles.work-orders.summary.export-spec']}</ControlLabel>
                            <MetadataExportSpec
                                disabled={this.props.disabled}
                                onChange={this.handleEnumChange.bind(this, 'exportSpecification')}
                                value={this.props.workOrder.get('exportSpecification')}
                            />
                        </FormGroup>
                    </FormRow>
                    {this.props.mode === 'edit' && <hr />}
                    {updateInformation}
                </FormSection>
            </div>
        );
    }
}

export default Summary;
