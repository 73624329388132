/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {TalentConstants} from './talent-actions';
import {AssetTabConstants} from '../assets-tab/asset-tab-actions';
import Validations from '../common/validations/validations';
import Dispatcher from '../dispatcher/dispatcher';
import {ActionHistoryConstants} from '../system/action-history/action-history-actions';

const TalentStoreValidations = {
    bio: {label: 'talent.create.bio.title', validations: [Validations.max(8000)]},
    prefix: {label: 'talent.create.talent-info.prefix', validations: [Validations.max(50)]},
    firstName: {label: 'talent.create.talent-info.first-name', validations: [Validations.required, Validations.max(50)]},
    middleName: {label: 'talent.create.talent-info.middle-name', validations: [Validations.max(50)]},
    lastName: {label: 'talent.create.talent-info.last-name', validations: [Validations.max(50)]},
    suffix: {label: 'talent.create.talent-info.suffix', validations: [Validations.max(50)]}
};

class TalentStore extends ReduceStore {
    getInitialState() {
        return Immutable.Map({
            akas: Immutable.List(),
            filters: Immutable.Map(),
            offset: 0,
            originalAKAS: Immutable.List(),
            originalTalent: Immutable.Map({
            }),
            showPreloader: false,
            removedAKAS: Immutable.List(),
            selected: Immutable.List(),
            size: 20,
            talentList: Immutable.List(),
            total: 0,
            talent: Immutable.Map({
                akas: Immutable.List(),
                history: Immutable.List()
            }),
            thumbnails: Immutable.Map()
        });
    }

    getValidations() {
        return Validations.validate(this.getState().get('talent'), TalentStoreValidations);
    }

    /**
     * Get a Talent - Role by id.
     */
    getTalentRole(id) {
        let role;

        Object.keys(TalentConstants.TALENT_ROLES_TYPES).some(k => {
            if (TalentConstants.TALENT_ROLES_TYPES[k].id === id) {
                role = TalentConstants.TALENT_ROLES_TYPES[k];
                return true;
            }

            return false;
        });

        return Immutable.fromJS(role);
    }

    reduce(state, action) {
        switch (action.actionType) {
        case AssetTabConstants.GET.START:
            state = state.merge({showPreloader:true});
            break;

        case AssetTabConstants.GET.SUCCESS:
        case AssetTabConstants.GET.ERROR:
            state = state.merge({showPreloader: false});
            break;

        case AssetTabConstants.REMOVE:
            if (action.entityType === 'talent' &&
                state.getIn(['talent', 'defaultImagePortraitId']) === action.asset.get('assetId')) {
                // With the current UI, it only matters if the user removes the default portrait image
                // because it's the only one that is visible in the "Default Photo" section.
                state = state.mergeIn(['talent'], {
                    defaultImagePortraitFullResolutionUrl: null,
                    defaultImagePortraitId: null,
                    defaultImagePortraitPreviewUrl: null,
                    defaultImagePortraitThumbnailUrl: null
                });
            }
            break;

        case AssetTabConstants.SET_DEFAULT:
            if (action.entityType === 'talent' && action.type === 'defaultPortrait') {
                // The following code acts on the current value, but knowing it will toggle
                // it's value in the asset-tab-store. If current value is 0, then make it default.
                // If current value is 1, then remove it.
                if (!action.asset.get('defaultPortrait')) {
                    state = state.mergeIn(['talent'], {
                        defaultImagePortraitFullResolutionUrl: action.asset.getIn(['thumbnails', 0, 'fullResolutionUrl']),
                        defaultImagePortraitId: action.asset.get('assetId'),
                        defaultImagePortraitPreviewUrl: action.asset.getIn(['thumbnails', 0, 'previewUrl']),
                        defaultImagePortraitThumbnailUrl: action.asset.getIn(['thumbnails', 0, 'thumbnailUrl'])
                    });
                } else {
                    state = state.mergeIn(['talent'], {
                        defaultImagePortraitFullResolutionUrl: null,
                        defaultImagePortraitId: null,
                        defaultImagePortraitPreviewUrl: null,
                        defaultImagePortraitThumbnailUrl: null
                    });
                }
            }
            break;

        case ActionHistoryConstants.ADD_NOTE.SUCCESS:
            if (action.actionObjectType === ActionHistoryConstants.ACTION_OBJECTS.TALENT) {
                state = state.updateIn(['talent', 'history'], history => history.unshift(action.note));
            }
            break;

        case TalentConstants.TALENT.AKA.ADD:
            state = state.updateIn(['talent', 'akas'], akas => {
                return akas.push(Immutable.fromJS({
                    displayOrder: akas.size + 1,
                    prefix: '',
                    firstName: '',
                    middleName: '',
                    lastName: '',
                    suffix: ''
                }));
            });
            break;

        case TalentConstants.TALENT.AKA.REMOVE:
            let item = -1;
            let index = 0;
            state.get('talent').get('akas').forEach((v) => {
                if (v.equals(action.aka)) {
                    item = index;
                }
                index++;
            });
            if (item === -1) {
                break;
            }
            let previous = state.get('talent').get('akas').slice(0, item);
            let toFix = state.get('talent').get('akas').slice(item+1).toJS();
            toFix.forEach((v) => {
                item++;
                v.displayOrder = item;
                previous = previous.push(Immutable.fromJS(v));
            });
            state = state.merge({
                talent: state.get('talent').set('akas', previous),
                removedAKAS: state.get('removedAKAS').push(action.aka)
            });
            break;
        case TalentConstants.CLEAR:
            state = this.getInitialState();
            break;

        case TalentConstants.TALENT.FIND_BY_ID:
            state = state.merge({
                showPreloader: true,
            });
            break;

        case TalentConstants.TALENT.FIND_BY_ID_OK:
            state = state.merge({
                talent: action.talent,
                originalAKAS: action.talent.get('akas'),
                originalTalent: action.talent,
                removedAKAS: Immutable.List(),
                showPreloader: false,
            });
            break;

        case TalentConstants.TALENT.FIND_BY_ID_ERROR:
            state = state.merge({
                showPreloader: false,
            });
            break;

        case TalentConstants.TALENT.SAVE:
            state = state.merge({
                showPreloader: true,
            });
            break;

        case TalentConstants.TALENT.SAVE_OK:
            state = state.merge({
                showPreloader: false,
                originalTalent: state.get('talent')
            });
            break;

        case TalentConstants.TALENT.SAVE_ERROR:
            state = state.merge({
                showPreloader: false,
            });
            break;

        case TalentConstants.TALENT.UPDATE:
            state = state.setIn(['talent', ...action.attr.split('.')], action.value);
            break;

        case TalentConstants.TALENT.AKA.UPDATE:
            state = state.setIn(
                [
                    // Get to the AKA to update.
                    'talent', 'akas', action.order - 1,
                    // Choose the value to update
                    ...action.attr.split('.')
                ],
                action.value
            );
            break;

        case TalentConstants.TALENT.ASSETS.THUMBNAILS.GET.SUCCESS:
            state = state.set('thumbnails', action.thumbnails);
            break;

        case TalentConstants.TALENT.GET.SUCCESS:
            state = state.merge({
                offset: action.offset,
                showPreloader: false,
                size: action.size,
                talentList: action.talentList,
                total: action.total
            });
            break;

        case TalentConstants.TALENT.SELECTED:
            state = state.set('selected', action.selected);
            break;

        case TalentConstants.TALENT.SET.FILTERS:
            state = state.set('filters', action.filters);
            break;
        }
        return state;
    }
}

let store = new TalentStore(Dispatcher);

export default store;

export {TalentStoreValidations};
