/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';

import {AlertTypes} from '../common/notification/alert';
import {NotificationActions} from '../common/notification/notification-actions';
import Dispatcher from '../dispatcher/dispatcher';
import {PreloaderActions} from '../preloader/preloader-actions';
import Request from '../request';

const CONSTANTS = {
    ADD_NEW: 'publishing-list.localized.add.new',
    ADD_NEW_HIDE: 'publishing-list.localized.add.new.hide',
    ADD_NEW_SHOW: 'publishing-list.localized.add.new.show',
    CLEAR:'publishing-list.localized.clear',
    GET: {
        SUCCESS: 'publishing-list.localized.get.success',
        START:'publishing-list.localized.get.start',
        ERROR:'publishing-list.localized.get.error'
    },
    ITEM: {
        EDIT: {
            SET: 'publishing-list.localized.item.edit.set',
        },
        GET: {
            SUCCESS: 'publishing-list.localized.items.get.success',
        },
        SAVE: {
            SUCCESS: 'publishing-list.localized.item.save.success',
        },
        UPDATE: 'publishing-list.localized.item.update'
    },
    ITEMS: {
        UPDATE: 'publishing-list.localized.update.items',
    },
    REMOVE_CURRENT_LANGUAGE: 'publishing-list.localized.remove.current.language',
    SET_CURRENT_LANGUAGE: 'publishing-list.localized.set.current.language',
    SET_NEW_LANGUAGE: 'publishing-list.localized.set.new.language',
    SAVE: {
        SUCCESS: 'publishing-list.localized.save',
    },
    UPDATE: 'publishing-list.localized.update'
};

class PublishingListLocalizedActions {

    addNew(languageCode) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ADD_NEW,
            languageCode: languageCode
        });
    }

    addNewShow() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ADD_NEW_SHOW,
        });
    }

    addNewHide() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ADD_NEW_HIDE,
        });
    }

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
    }

    findById(publishingListId) {
        PreloaderActions.show('publishing-list-localized-actions.get');
        Promise.all([
            Request.get(`web/publish-list/${publishingListId}/localized`).exec(),
            Request.get(`web/publish-list/${publishingListId}/items/localized`).exec()
        ]).spread((publishingListRes, publishingListItemRes) => {
            PreloaderActions.hide('publishingList-localized-actions.get');

            let publishingListLocalized = publishingListRes.body.reduce( (o, l) => {
                o[l.languageCode] = l;
                return o;
            }, {});

            /*structure: List of language map, language map is a list of raslated objects
             {
                FR: [{displayName: 'french 1', itemId: 999, languageCode: 'FR'}, {...}],
                ESP: [{...}],
                ...
             }
             */
            let listItemsLocalized = publishingListItemRes.body.reduce((results, l) => {
                if (!results[l.languageCode]) {
                    results[l.languageCode] = [];
                }

                results[l.languageCode].push(l);

                return results;
            }, {});

            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.SUCCESS,
                publishingListLocalized: Immutable.fromJS(publishingListLocalized)
            });

            Dispatcher.dispatch({
                actionType: CONSTANTS.ITEM.GET.SUCCESS,
                listItemsLocalized: Immutable.fromJS(listItemsLocalized)
            });

            return;
        }).catch(err => {
            PreloaderActions.hide('publishing-list-localized-actions.get');
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
            throw err;
        });
    }

    removeCurrentLanguage() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.REMOVE_CURRENT_LANGUAGE
        });
    }

    update(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.UPDATE,
            attr,
            value
        });
    }

    updateItem(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ITEM.UPDATE,
            attr,
            value
        });
    }

    updateListItems(currentLanguage, itemLocalized) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ITEMS.UPDATE,
            currentLanguage,
            itemLocalized
        });
    }

    save(publishingListId, publishingListLocalized, originaPublishingListLocalized, listItemsLocalized, originalListItemsLocalized) {
        const preloaderSource = 'publishing-list-actions.save';
        let plLocalized = [];
        let localized = publishingListLocalized.reduce((arr, l) => {
            plLocalized.push(...l.get('publishingList', Immutable.Map()).toList().flatten(1).toJS());
            arr.push(l.delete('publishingList').toJS());
            return arr;
        }, []);

        let apiArr = [];
        listItemsLocalized.map(l => {
            switch (l.size) {
            case 1:
                apiArr.push(l.toMap().flatten(1).toJS());
                break;
            default:
                l.map(subL => {
                    apiArr.push(subL.toMap().flatten(1).toJS());
                });
                break;
            }
        });


        PreloaderActions.show(preloaderSource);
        if (listItemsLocalized !== originalListItemsLocalized) {
            Request.put(`web/publish-list/${publishingListId}/items/localized`)
                .send(apiArr)
                .exec()
                .then(() => {
                    Dispatcher.dispatch({
                        actionType: CONSTANTS.ITEM.SAVE.SUCCESS
                    });
                    PreloaderActions.hide(preloaderSource);
                }).catch(err => {
                    PreloaderActions.hide(preloaderSource);
                    throw err;
                });
        }

        if (publishingListLocalized !== originaPublishingListLocalized) {
            PreloaderActions.show(preloaderSource);
            Request.put(`web/publish-list/${publishingListId}/localized`)
                .send(localized)
                .exec()
                .then(() => {
                    Dispatcher.dispatch({
                        actionType: CONSTANTS.SAVE.SUCCESS
                    });
                    PreloaderActions.hide(preloaderSource);
                }).catch(err => {
                    PreloaderActions.hide(preloaderSource);
                    throw err;
                });
        }

        return;

    }

    setCurrentLanguage(languageCode) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SET_CURRENT_LANGUAGE,
            languageCode
        });
    }

    setItem(itemsLocalized, itemId) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ITEM.EDIT.SET,
            itemsLocalized,
            itemId
            //item: Immutable.fromJS(item)
        });

        return;
    }

    setNewLanguage(languageCode) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SET_NEW_LANGUAGE,
            languageCode
        });
    }
}

let actions = new PublishingListLocalizedActions();

export {
    actions as PublishingListLocalizedActions,
    CONSTANTS as PublishingListLocalizedConstants
};
