/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import jQuery from 'jquery';
import Moment from 'moment';
import PropTypes from 'prop-types';
import QueryString from 'querystring-es3';
import React from 'react';
import {Button} from 'react-bootstrap';

import machineLearningActions from './store/machine-learning-actions';
import machineLearningStore from './store/machine-learning-store';
import {MachineLearningContainerProps, MachineLearningState} from './types';

import Panel from '~/src/common/panel/panel';
import ActiveFilter from '~/src/hardac/machine-learning/active-filter';
import Paging from '~/src/hardac/machine-learning/paging';
import {LayoutActions} from '~/src/layout/layout-actions';
import LayoutStore from '~/src/layout/layout-store';
import Preloader from '~/src/preloader';
import SessionStore from '~/src/user/session/session-store';
require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('../../styles/data-tables-brainiac.css');
require('datatables.net-responsive-bs');

const rowActive = (isActive: boolean) => {
    if (isActive) {
        return `<span class="text-success"><i class="fas fa-check"></i></span> ${isActive}`;
    }

    return `<span class="text-danger"><i class="fas fa-times"></i></span> ${isActive}`;
};

class MachineLearning extends React.PureComponent<
    MachineLearningContainerProps,
    MachineLearningState
> {
    static getPermissions() {
        return {
            canBrowseMachineLearning: SessionStore.canUser(
                SessionStore.PERMISSIONS.HARDAC.MACHINE_LEARNING.BROWSE
            ),
            canReadMachineLearning: SessionStore.canUser(
                SessionStore.PERMISSIONS.HARDAC.MACHINE_LEARNING.READ
            ),
        };
    }

    static getStores() {
        return [machineLearningStore, LayoutStore];
    }

    static calculateState() {
        return {
            machineLearningIsLoading:
                machineLearningStore.getState()?.loading ?? false,
            machineLearningData:
                machineLearningStore.getState()?.data?.results ?? [],
            machineLearningTotalCount:
                machineLearningStore.getState()?.data?.totalCount ?? 0,
            showFiltersPanel: LayoutStore.getState().get('showFiltersPanel'),
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
        };
    }

    componentDidMount() {
        this.getData();

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        this.$table = jQuery('#list-projects-table');

        this.$tableAPI = this.$table.DataTable({
            autoWidth: false,
            iDisplayLength: 1,
            info: false,
            paging: false,
            columnDefs: [{targets: [6, 7, 8], width: 150}],
            responsive: {
                details: {
                    target: -1,
                    type: 'column',
                },
            },
            searching: false,
        });

        this.drawColumns();
    }

    componentDidUpdate(prevProps: Readonly<{ location: Location }>) {
        if (prevProps.location?.search !== this.props.location?.search) {
            this.getData();
        }

        this.drawColumns();
    }

    componentWillUnmount() {
        if (this.$tableAPI) {
            this.$tableAPI.destroy();
        }
    }

    private $table: any;
    private $tableAPI: any;

    getData = () => {
        const query = QueryString.parse(
            this.props.location.search?.replace('?', '') ?? ''
        );
        const queryActiveFilterValue = query['active-filter-value'];
        const queryOffsetValue = query.offset;
        let activeFilterValue = '';
        let offset = '';

        if (Array.isArray(queryActiveFilterValue)) {
            activeFilterValue = queryActiveFilterValue[0];
        } else if (queryActiveFilterValue) {
            activeFilterValue = queryActiveFilterValue;
        }

        if (Array.isArray(queryOffsetValue)) {
            activeFilterValue = queryOffsetValue[0];
        } else if (queryOffsetValue) {
            offset = queryOffsetValue;
        }

        machineLearningActions.get(activeFilterValue, offset);
    };

    handleToggleFiltersPanel = () => {
        LayoutActions.toggleFiltersPanel();
    };

    drawColumns = () => {
        this.$tableAPI.clear();
        this.state.machineLearningData.forEach((item) => {
            this.$tableAPI.row.add([
                rowActive(item.active),
                item.categoryType,
                item.iterationName,
                item.projectName,
                Moment(item.updatedDate).format(
                    this.context.intl.messages['date-format']
                ),
                Moment(item.createdDate).format(
                    this.context.intl.messages['date-format']
                ),
                item.description,
                item.iterationId,
                item.projectId,
            ]);
        });
        this.$tableAPI.draw(false);
    };

    render() {
        return (
            <div
                className={ClassNames({
                    'control-sidebar-open': this.state.showFiltersPanel,
                })}
            >
                <div
                    className={ClassNames({
                        'sidebar-opened': this.state.showFiltersPanel,
                    })}
                >
                    <Preloader
                        show={this.state.machineLearningIsLoading}
                        fixed
                        loadingDots
                    >
                        <section className="content-header">
                            <h1>
                                <i className="fas fa-robot" />
                                &nbsp;
                                {
                                    this.context.intl.messages[
                                        'hardac.processes.machine-learning'
                                    ]
                                }
                            </h1>
                        </section>
                        <section className="content">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="container-fluid no-x-padding">
                                        <div className="row">
                                            <div className="col-md-6 show-grid">
                                                <Button
                                                    onClick={
                                                        this
                                                            .handleToggleFiltersPanel
                                                    }
                                                    bsStyle="primary"
                                                    bsSize="large"
                                                    className="pull-right"
                                                >
                                                    <i className="fas fa-sliders-h" />
                                                    &nbsp;
                                                    {
                                                        this.context.intl
                                                            .messages[
                                                                'common.filteringOptions'
                                                            ]
                                                    }
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container-fluid no-x-padding">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="box">
                                                    <div className="box-body">
                                                        <table
                                                            id="list-projects-table"
                                                            className="table table-bordered table-striped responsive dataTable"
                                                        >
                                                            <thead>
                                                                <tr>
                                                                    <th className="sorting">
                                                                        Active
                                                                    </th>
                                                                    <th className="sorting">
                                                                        Category
                                                                        Type
                                                                    </th>
                                                                    <th className="sorting">
                                                                        Iteration
                                                                        Name
                                                                    </th>
                                                                    <th className="sorting">
                                                                        Project
                                                                        Name
                                                                    </th>
                                                                    <th className="sorting">
                                                                        Updated
                                                                        Date
                                                                    </th>
                                                                    <th className="sorting">
                                                                        Created
                                                                        Date
                                                                    </th>
                                                                    <th className="sorting">
                                                                        Description
                                                                    </th>
                                                                    <th className="sorting">
                                                                        Iteration
                                                                        ID
                                                                    </th>
                                                                    <th className="sorting">
                                                                        Project
                                                                        ID
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody />
                                                        </table>
                                                        <div className="row">
                                                            <div className="col-sm-7 col-sm-offset-5">
                                                                <Paging
                                                                    location={
                                                                        this
                                                                            .props
                                                                            .location
                                                                    }
                                                                    totalItems={
                                                                        this
                                                                            .state
                                                                            .machineLearningTotalCount
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div>
                            <aside className="control-sidebar B(0) Ovx(h) Ovy(a)">
                                <div className="tab-content">
                                    <p>
                                        <button
                                            className="btn btn-block bg-navy"
                                            onClick={
                                                this.handleToggleFiltersPanel
                                            }
                                        >
                                            <i className="fas fa-chevron-right pull-right" />
                                            &nbsp;Close Options
                                        </button>
                                    </p>
                                    <Panel
                                        title="Active State"
                                        classTitle="box-title"
                                        collapsible
                                        defaultExpanded
                                    >
                                        <div
                                            data-style="display: block;"
                                            className="box-body"
                                        >
                                            <div className="form-group">
                                                <ActiveFilter
                                                    location={
                                                        this.props.location
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </Panel>
                                </div>
                            </aside>
                        </div>
                    </Preloader>
                </div>
            </div>
        );
    }
}

export default Container.create(MachineLearning);
