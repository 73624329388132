/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */


import Immutable from 'immutable';
import jQuery from 'jquery';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {Sort} from '../../common/local-search/filter';
import {WithStoreOnRoute} from '../../common/store-on-route';
import {HomescreenActions} from '../homescreen-actions';

require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('datatables.net-responsive-bs');


class Table extends Component {

    static get propTypes() {
        return {
            displayLinks: PropTypes.bool,
            filters: PropTypes.string,
            homescreens: PropTypes.instanceOf(Immutable.List).isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
            router: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            displayLinks: false,
            filters: ''
        };
    }

    constructor(props) {
        super(props);

        this.getSortByClass = this.getSortByClass.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.handleUserGroups = this.handleUserGroups.bind(this);
        this.handleSort = this.handleSort.bind(this);
    }

    componentDidMount() {
        this.$table = jQuery('#homescreen-table');
        this.$tableAPI = this.$table.DataTable({
            autoWidth: false,
            // Add the control class to the last column. This colum will
            // contain the button to show the responsive data.
            columnDefs: [{
                className: 'control',
                targets:   -1,
                width: 20
            }, {
                className: 'Whs(nw)',
                targets: 'no-wrap'
            }],
            iDisplayLength: 1,
            info: false,
            ordering: false,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        });
        // Register global listeners.
        window.addEventListener('resize', this.handleResize);
        // And trigger the resize handler once so that the datatable
        // knows its initial dimensions.
        this.$table.on('mouseenter', '.display-user-groups', this.handleUserGroups);
        this.handleResize();
    }

    componentWillUpdate(nextProps) {
        this.$tableAPI.clear();
        // Add data to the jQuery datatable.
        let homescreens = nextProps.homescreens;
        if (nextProps.filters) {
            let filterRegExp = new RegExp(nextProps.filters, 'i');
            homescreens = nextProps.homescreens.filter(h => h.get('name').match(filterRegExp));
        }
        // Reorder rows
        homescreens = Sort(homescreens, this.getRouteState(nextProps).get('sortBy', 'name'), this.getRouteState(nextProps).get('sortDir', 'asc'));

        homescreens.forEach((h) => {
            let created = Moment(h.get('createdDate'));
            if (created.isValid()) {
                created = created.format(this.context.intl.messages['date-format']);
            } else {
                created = '-';
            }

            let activeData;
            if (h.get('active')) {
                activeData = '<i class="fas fa-check-circle text-green"></i> <span class="hidden-xs hidden-sm"> Active</span>';
            } else {
                activeData = '<i class="fas fa-minus-circle text-red"></i> <span class="hidden-xs hidden-sm"> Inactive</span>';
            }

            let homescreenName = h.get('name');
            if (nextProps.displayLinks) {
                homescreenName = `<a href="/homescreen/${h.get('id')}" class="edit-homescreen">${homescreenName}</a>`;
            }
            let userGroups = this.context.intl.messages['homescreens.browse.usergroups.loading'];
            if (h.get('userGroups')) {
                userGroups = h.get('userGroups').map(ug => ug.get('name')).reduce((r, v, i) => {
                    if (i === 0) {
                        return `User Groups: ${v}`;
                    }

                    return `${r}, ${v}`;
                }, this.context.intl.messages['homescreens.browse.usergroups.empty']);

            }
            this.$tableAPI.row.add([
                // Now, since the data has changed the columns widths, trigger
                // the resize handler in order to update the responsive feature.
                homescreenName,
                activeData,
                h.get('userCount') || 0,
                h.get('description') || '',
                created,
                `<a class="display-user-groups parcel-tooltip" data-group-id="${h.get('id')}" title="${userGroups}"><i class="fas fa-users"></i><span class="tooltip-inner-left">${userGroups}</span></a>`,
                '']);
            return;
        });

        this.$tableAPI.draw(false);
        this.handleResize();

        return;
    }

    componentWillUnmount() {
        // Remove global listeners.
        if (this.$table) {
            this.$table.off('mouseenter', '.display-user-groups');
        }

        window.removeEventListener('resize', this.handleResize);
    }

    getSortByClass(column) {
        let r = 'sorting';

        if (column === this.getRouteState().get('sortBy', 'name')) {
            r = `sorting_${this.getRouteState().get('sortDir', 'asc')}`;
        }

        return r;
    }

    handleResize() {
        this.$tableAPI.responsive.recalc();
    }

    /**	    handleRowClick(event) {
     * This function is kind of "special" because it needs to handle
     * events bubbled from the data table rows, these rows cannot use
     * the JSX syntax because they are created by the data table
     * jQuery plugin instead of React.
     */

    handleRowClick(event) {
        switch (true) {
        case !!~event.target.className.indexOf('edit-homescreen'):
            event.preventDefault();
            this.context.router.push(event.target.getAttribute('href'));
            break;
        }
    }

    handleUserGroups(event) {
        let title = event.currentTarget.getAttribute('title');
        if (title !== this.context.intl.messages['homescreens.browse.usergroups.loading']) {
            return;
        }
        HomescreenActions.getUserGroups(
            parseInt(event.currentTarget.getAttribute('data-group-id'), 10)
        );
    }

    handleSort(column) {
        let newSortDir = 'asc';
        if (this.getRouteState().get('sortBy', 'name') === column && this.getRouteState().get('sortDir', 'asc') === 'asc') {
            newSortDir = 'desc';
        }

        this.setRouteState('sortBy', column)
            .setRouteState('sortDir', newSortDir)
            .apply();
    }

    render() {
        return (
            <div>
                <table id="homescreen-table" className="table table-bordered table-striped responsive">
                    <thead>
                        <tr>
                            <th className={this.getSortByClass('name')} onClick={this.handleSort.bind(this, 'name')}>Homescreen Name</th>
                            <th className="no-wrap">Status</th>
                            <th className={this.getSortByClass('userCount')} onClick={this.handleSort.bind(this, 'userCount')}>Users</th>
                            <th className={this.getSortByClass('description')} onClick={this.handleSort.bind(this, 'description')}>Description</th>
                            <th className={this.getSortByClass('createdDate')} onClick={this.handleSort.bind(this, 'createdDate')}>Date Added</th>
                            <th className="no-sort"><i className="fas fa-users"></i></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody onClick={this.handleRowClick}>
                    </tbody>
                </table>
            </div>
        );
    }

}

export default WithStoreOnRoute(Table);
