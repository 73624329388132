/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {browserHistory} from 'react-router';
import {Subject} from 'rxjs';

import Dispatcher from '../dispatcher/dispatcher';

const CONSTANTS = {
    REDIRECT: 'router.redirect',
    ROUTE_CHANGE_START: 'router.route.change.start',
    ROUTE_CHANGE_SUCCESS: 'router.route.change.success'
};

let o = new Subject();

// This is an special actions object, it forces a Flux workflow
// into the react-router.
class RouterActions {
    // Redirect the user inside the app. Here since we are skipping the
    // router lifecycle
    back() {
        browserHistory.goBack();
    }

    forbidden() {
        browserHistory.replace('/access-denied');
    }

    notFound() {
        // Redirect the user.
        browserHistory.replace('/errors/not-found');
        return;
    }

    redirect(to, force) {
        // Redirect the user.
        this.force = force;
        browserHistory.push(to);
        this.force = false;
        return;
    }

    routeChangeStart() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ROUTE_CHANGE_START
        });
        return;
    }

    routeChangeSuccess(route) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ROUTE_CHANGE_SUCCESS
        });
        o.next(route);
        return;
    }

    registerRedirectCheck(callback) {
        if (!this.redirectCallbacks) {
            this.redirectCallbacks = [];
        }
        this.redirectCallbacks.push(callback);
    }

    clearRedirectChecks() {
        if (this.redirectCallbacks) {
            this.redirectCallbacks = [];
        }
    }

    needsConfirmation(location) {
        if (
            !this.redirectCallbacks ||
            this.force ||
            !location ||
            // This check is because some Bootstrap components like dropdowns
            // change the URL by adding a # at the end. In these cases the pathname
            // stays the same and allows to know the user has not navigated.
            // Must check location.action !== pop to catch browser back button
            (location.pathname === window.location.pathname && location.action !== 'POP')
        ) {
            return false;
        }

        return this.redirectCallbacks.some(f => f(location));
    }
}

let ra = new RouterActions();

window.onbeforeunload = confirmExit;

function confirmExit() {
    // STUDIO-8928 disable the unsaved changes modal until further notice
    // if (ra.needsConfirmation()) {
    //     return 'You have unsaved changes.';
    // }
    return;
}

// Cache for the previous pathname to know when to clear
// the callbacks.
let prevPathname = window.location.pathname;
let prevPathnameForClearRedirects = window.location.pathname;
browserHistory.listenBefore((location, callback) => {
    let a = true;
    // STUDIO-8928 disable the unsaved changes modal
    // if (ra.needsConfirmation(location)) {
    //     a = window.confirm('You have unsaved changes. Are you sure you want to leave?');
    // }
    callback(a);
    // This check is to avoid triggering this event when the user action has only changed
    // a query string param. Something that happens with most filters.
    if (location.pathname !== prevPathname) {
        ra.routeChangeStart();
        prevPathname = location.pathname;
        prevPathnameForClearRedirects = window.location.pathname;
    }
});

browserHistory.listen(location => {
    if (location.pathname !== prevPathname) {
        ra.routeChangeSuccess(location);
    }

    // See comment in needsConfirmation about this clause.
    if (location.pathname !== prevPathnameForClearRedirects) {
        // TODO allow to unsuscribe router redirects instead clearing all
        ra.clearRedirectChecks();
    }
});

export {
    ra as RouterActions,
    CONSTANTS as RouterConstants,
    o as ObservableRoute
};
