/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';
import ReactTable, {CellInfo, Column} from 'react-table'; // eslint-disable-line import/named

import {VideoActions} from '~/src/assets/video/video-actions';
import SectionHeader from '~/src/common/form/section-header';
import {AlertTypes} from '~/src/common/notification/alert';
import {MODAL_TYPE} from '~/src/common/notification/modal';
import {NotificationActions} from '~/src/common/notification/notification-actions';
import {FormatDateToDefaultTZString} from '~/src/common/utils/date-converters';
import pick from '~/src/common/utils/pick';
import {CLOSED_CAPTION, SUBTITLES} from '~/src/hardac/cc-editor/constants';

type Props = {
    canEditCaptions: boolean,
    disabled: boolean,
    files: ReadonlyArray<VideoWebVtt>,
    hasActiveFileForType: Record<WebVttFileType, boolean>,
    isActive: boolean,
    language: string,
}

const TYPES_MAP = {
    [CLOSED_CAPTION]: 'asset.video.edit.captions.type.cc',
    [SUBTITLES]: 'asset.video.edit.captions.type.sub',
};

const STATUSES_ICONS_CLASS: Record<VideoWebVttStatusType, string> = {
    PASSEDQC: 'fas fa-thumbs-up',
    UNREVIEWED: 'fas fa-eye-slash',
};

const STATUSES_MESSAGES: Record<VideoWebVttStatusType, string> = {
    PASSEDQC: 'asset.video.edit.captions.status.passedqc',
    UNREVIEWED: 'asset.video.edit.captions.status.unreviewed',
};

const SOURCES_MESSAGES: Record<VideoWebVttSourceType, string> = {
    MASTERING: 'asset.video.edit.captions.source.mastering',
    USER: 'asset.video.edit.captions.source.user',
    VI: 'asset.video.edit.captions.source.vi'
};


// hack: CellInfo is not generic =(
type VttCell = Omit<CellInfo, 'original'> & {original: VideoWebVtt};
type CellExtraProps = {
    canEditCaptions: boolean,
    disabled: boolean,
    hasActiveFileForType: Record<WebVttFileType, boolean>,
    language: string,
    removeCaption: (vttId: number) => void,
    messages: {
        'asset.video.edit.captions.make-active': string,
        'asset.video.edit.captions.make-inactive': string,
        'common.edit': string,
        'common.delete': string,
    },
}

const COLUMNS: Array<Column<VideoWebVtt>> = [{
    Header: () => <strong><FormattedMessage id="asset.video.edit.captions.id" /></strong>,
    Cell: (ci: VttCell) => {
        const {externalUrl, s3Path} = ci.original;
        const filename = s3Path?.split('/').pop() || '';
        let icon;
        if (filename) {
            icon = (
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id={`filename-tooltip-${ci.original.videoWebVttId}`}>
                            <FormattedMessage id="asset.video.edit.captions.filename" values={{filename}} />
                        </Tooltip>
                    }>
                    <i className="far fa-info-circle"/>
                </OverlayTrigger>
            );
        }
        return (
            <span>
                <a href={externalUrl as string}>
                    {ci.original.videoWebVttId}
                </a>
                &nbsp;
                {icon}
            </span>
        );
    },
}, {
    Header: () => <strong><FormattedMessage id="asset.video.edit.captions.date" /></strong>,
    Cell: (ci: VttCell) => <span>{FormatDateToDefaultTZString(ci.original.createdDate, 'MMMM D, YYYY')}</span>,
}, {
    Header: () => <strong><FormattedMessage id="asset.video.edit.captions.type" /></strong>,
    Cell: (ci: VttCell) => <FormattedMessage id={TYPES_MAP[ci.original.fileType]} />,
    width: 70,
}, {
    Header: () => <strong><FormattedMessage id="asset.video.edit.captions.status" /></strong>,
    Cell: (ci: VttCell) => (
        <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip id={`status-tooltip-${ci.original.videoWebVttId}`}>
                    <FormattedMessage id={STATUSES_MESSAGES[ci.original.status]} />
                </Tooltip>
            }>
            <i className={STATUSES_ICONS_CLASS[ci.original.status]} />
        </OverlayTrigger>
    ),
    width: 70,
}, {
    Header: () => <strong><FormattedMessage id="asset.video.edit.captions.notes" /></strong>,
    Cell: (ci: VttCell) => <span>{ci.original.notes}</span>,
}, {
    Header: () => <strong><FormattedMessage id="asset.video.edit.captions.source" /></strong>,
    Cell: (ci: VttCell) => <FormattedMessage id={SOURCES_MESSAGES[ci.original.source]} />,
    width: 120,
}, {
    Header: () => <strong><FormattedMessage id="asset.video.edit.captions.actions" /></strong>,
    Cell: (ci: VttCell) => {
        const cellProps = ci.tdProps.rest as CellExtraProps;
        let editButton = null;
        let activateButton = null;
        let deleteButton = null;

        if (cellProps.canEditCaptions && ci.original.fileType === CLOSED_CAPTION) {
            editButton = (
                <a
                    className="btn btn-sm btn-primary-outline"
                    href={`/hardac/cc-editor/assets/${ci.original.videoId}/vtt/${ci.original.videoWebVttId}`}
                    target="_blank"
                    title={cellProps.messages['common.edit']}
                >
                    <i className="fas fa-pencil"/>
                </a>
            );
        }

        if (ci.original.active) {
            activateButton = (
                <Button
                    bsSize="sm"
                    className="btn btn-danger-outline"
                    data-testid={`inactivate-btn-${ci.original.videoWebVttId}`}
                    disabled={cellProps.disabled}
                    title={cellProps.messages['asset.video.edit.captions.make-inactive']}
                    onClick={() => VideoActions.changeActiveFlagForCaption(Immutable.fromJS(ci.original), false)}
                >
                    <i className="fas fa-times" />
                </Button>
            );
        } else {
            const activateCaption = () => VideoActions.changeActiveFlagForCaption(Immutable.fromJS(ci.original), true);

            const title = <FormattedMessage id="asset.edit.ai-models-tab.messages.activate-confirm.title" />;
            const confirmText = (
                <span>
                    <i className="fas fa-check"></i>
                    &nbsp;
                    <FormattedMessage id="asset.edit.ai-models-tab.messages.activate-confirm.title" />
                </span>
            );
            const message = (
                <div className="form-section text-center">
                    <p>
                        <FormattedMessage
                            id="asset.edit.ai-models-tab.messages.activate-confirm.message"
                            values={{language: cellProps.language}}
                        />
                        &nbsp;
                        <a href="#">{ci.original.videoWebVttId}</a>
                        ?
                    </p>
                </div>
            );

            const handleClickOnActivateButton = () => {
                if (cellProps.hasActiveFileForType[ci.original.fileType]) {
                    NotificationActions.show(AlertTypes.ALERT_INFO, title, message, confirmText, activateCaption);
                } else {
                    activateCaption();
                }
            };

            activateButton = (
                <Button
                    bsSize="sm"
                    className="btn btn-success-outline"
                    data-testid={`activate-btn-${ci.original.videoWebVttId}`}
                    disabled={cellProps.disabled}
                    title={cellProps.messages['asset.video.edit.captions.make-active']}
                    onClick={handleClickOnActivateButton}
                >
                    <i className="fas fa-check" />
                </Button>
            );
            deleteButton = (
                <Button
                    bsSize="sm"
                    className="btn btn-danger-outline"
                    data-testid={`remove-btn-${ci.original.videoWebVttId}`}
                    disabled={cellProps.disabled}
                    title={cellProps.messages['common.delete']}
                    onClick={() => cellProps.removeCaption(ci.original.videoWebVttId)}
                >
                    <i className="fas fa-trash-alt" />
                </Button>
            );
        }

        return (
            <div key={ci.original.videoWebVttId}>
                {editButton}
                &nbsp;
                {activateButton}
                &nbsp;
                {deleteButton}
            </div>
        );
    },
    width: 132,
}];

export default class CaptionsTable extends React.PureComponent<Props> {
    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    private getCellExtraProps = (): CellExtraProps => ({
        canEditCaptions: this.props.canEditCaptions,
        disabled: this.props.disabled,
        hasActiveFileForType: this.props.hasActiveFileForType,
        language: this.props.language,
        removeCaption: this.removeCaption,
        messages: pick(this.context.intl.messages, [
            'asset.video.edit.captions.make-active',
            'asset.video.edit.captions.make-inactive',
            'common.edit',
            'common.delete',
        ]),
    });

    private removeCaption = (vttId: number) => {
        const messages = this.context.intl.messages;
        NotificationActions.show(
            MODAL_TYPE.DANGER,
            messages['asset.video.edit.captions.delete.title'],
            messages['asset.video.edit.captions.delete.confirmation'],
            messages['common.delete'],
            () => {
                VideoActions.removeCaption(vttId);
            }
        );
    };

    render() {
        if (this.props.files.length === 0) {
            return null;
        }

        let title = 'cc-editor.vtt.inactive';
        if (this.props.isActive) {
            title = 'cc-editor.vtt.active';
        }
        return (
            <div className="margin-bottom-20">
                <SectionHeader
                    title={<FormattedMessage id={title} />}
                    level={4}
                />
                <ReactTable
                    className="-striped table-bordered table-striped responsive captions"
                    columns={COLUMNS}
                    // react-table defines data as mutable array =(
                    data={this.props.files as VideoWebVtt[]}
                    getTdProps={this.getCellExtraProps}
                    loading={false}
                    pageSize={this.props.files.length}
                    showPagination={false}
                    sortable={false}
                    resizable={false}
                />
            </div>
        );
    }
}
