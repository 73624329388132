/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {UtilitiesConstants} from './utilities-actions';
import Validations from '../common/validations/validations';
import Dispatcher from '../dispatcher/dispatcher';

const UtilitiesValidations = {
    startDate: {
        validations: [Validations.required]
    },
    endDate: {
        validations: [Validations.required]
    }
};

class UtilitiesStore extends ReduceStore {

    getInitialState() {
        let state = Immutable.Map({
            anonymousAccount: Immutable.Map()
        });

        return state;
    }

    reduce(state, action) {
        switch (action.actionType) {

        case UtilitiesConstants.ANONYMOUS_ACCOUNT.CREATE:
            state = state.setIn(['anonymousAccount', ...action.attr.split('.')], action.value);
            break;
        }

        return state;
    }
}

let store = new UtilitiesStore(Dispatcher);

export default store;
export {
    UtilitiesValidations
};
