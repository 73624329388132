/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */
import {stationCatNames, stationCatNamesById} from '@wbdt-sie/brainiac-web-common';

const videoContentTypes = {
    '101' : 'Full Episode/Feature',
    '102' : 'Full Episode/Feature',
    '103' : 'Full Episode/Feature',

    '121':'Promo',
    '122':'Promo',
    '123':'Promo',
    '124':'Promo',
    '125':'Promo',
    '126':'Promo',
    '127':'Promo',
    '128':'Promo',
    '129':'Promo',
    '130':'Promo',
    '131':'Promo',

    '141':'Element',
    '142':'Element',
    '143':'Element',
    '144':'Element',
    '145':'Element',
    '146':'Element',
    '147':'Element',
    '148':'Element',
    '149':'Element',
    '150':'Element',
    '151':'Element',
    '152':'Element',

    '161': 'Behind the Scenes',
    '162': 'Behind the Scenes',
    '163': 'Behind the Scenes',
    '164': 'Behind the Scenes',
    '165': 'Behind the Scenes',
    '167': 'Behind the Scenes',
    '168': 'Behind the Scenes',
    '169': 'Behind the Scenes',
    '170': 'Behind the Scenes',

    '181': 'Sales',
    '182': 'Sales',
    '183': 'Sales',
    '184': 'Sales',
    '185': 'Sales',
    '186': 'Sales',

    '201': 'Sample',
    '202': 'Sample',

    'Full Episode':'Full Episode/Feature',
    'Full Program':'Full Episode/Feature',
    'Feature':'Full Episode/Feature',

    'Series Launch Promo':'Promo',
    'Season Launch Promo':'Promo',
    'Mid-Season Promo':'Promo',
    'Episodic Promo':'Promo',
    'Generic Promo':'Promo',
    'Teaser':'Promo',
    'Trailer':'Promo',
    'Next On':'Promo',
    'Recap':'Promo',
    'TV Spot':'Promo',
    'Sneak Peek':'Promo',

    'Green Screen':'Element',
    'Clip':'Element',
    'Tags':'Element',
    'B-Roll':'Element',
    'Graphic':'Element',
    'Element':'Element',
    'Source Materials':'Element',
    'Interstitial':'Element',
    'Clip Reel':'Element',
    'VAM': 'Element',
    'Textless Materials': 'Element',
    'Music': 'Element',

    'EPK':'Behind the Scenes',
    'Featurette':'Behind the Scenes',
    'Interview':'Behind the Scenes',
    'Promotional Special':'Behind the Scenes',
    'BTS - Finished':'Behind the Scenes',
    'Panel':'Behind the Scenes',
    'Event Footage':'Behind the Scenes',
    'Open Ends':'Behind the Scenes',
    'Webisode':'Behind the Scenes',

    'Sales Presentation':'Sales',
    'Sizzle Reel':'Sales',
    'Brand Extension':'Sales',
    'Integration Clip':'Sales',
    'Sales Kit':'Sales',
    'Marketing Kit':'Sales',

    'Client Produced Samples':'Sample',
    'US Network Promos':'Sample',
    'default.category':'Other'
};

const Categories = {
    video: videoContentTypes,
    audio: {
        'default.category':'Audio'
    },
    image: {
        'Art - Promo': 'Key Art',
        'Box Art': 'Key Art',
        'Key Art': 'Key Art',
        'Key Art - International Version': 'Key Art',
        'Key Art - Television Series': 'Key Art',
        'Key Art - Texted': 'Key Art',
        'Key Art - Textless': 'Key Art',
        'Key Art - Titled': 'Key Art',
        'Key Art - With Title Treatment': 'Key Art',
        'Video Box Art': 'Key Art',

        'Kiosk Art': 'Outdoor Art',
        'Billboard': 'Outdoor Art',

        'Banner': 'Online',
        'Web Site - Other': 'Online',
        'Web Site - WBITV': 'Online',
        'Web Site - WBITV B2B': 'Online',

        'Logo - Animation': 'Logo',
        'Logo - Movie Of The Week': 'Logo',
        'Logo - Network/Station': 'Logo',
        'Logo - Production Company': 'Logo',
        'Logo - Series': 'Logo',
        'Logo - Theatrical': 'Logo',
        'Logo - WB Division': 'Logo',
        'Title Treatment - On-air': 'Logo',
        'Title Treatment - Print': 'Logo',

        'Photography': 'Photography/Stills',
        'Unit': 'Photography/Stills',
        'Episodic': 'Photography/Stills',
        'Animated Stills': 'Photography/Stills',

        'Gallery': 'Gallery',
        'Gallery - Cast': 'Gallery',
        'Gallery - Multiple': 'Gallery',
        'Gallery - Single': 'Gallery',
        'Headshots': 'Gallery',
        'Set': 'Gallery',

        'SVOD vertical display art': 'SVOD',
        'SVOD horizontal display art': 'SVOD',
        'Story Art': 'SVOD',

        'Client-created': 'Client Samples/ Created',

        'Animation Composite': 'Animation Production Material',
        'Backgrounds': 'Animation Production Material',
        'Character - Stock': 'Animation Production Material',
        'Character Art': 'Animation Production Material',
        'Character Art - Animation': 'Animation Production Material',
        'Character Art- General': 'Animation Production Material',

        'Behind the Scenes': 'BTS',

        'Events': 'Events',
        'Events - IPJ': 'Events',
        'Events - LA Screenings': 'Events',
        'Events - Misc.': 'Events',

        'GIF': 'Social Media',
        'Countdown Card': 'Social Media',
        'Social Media Clip': 'Social Media',
        'Character Card': 'Social Media',

        'Tile': 'HBO Max',
        'Tile - Burned In': 'HBO Max',
        'Background': 'HBO Max',
        'Logo - HBO Max': 'HBO Max',
        'Universal Search': 'HBO Max',
        'Episodic Tile': 'HBO Max',

        '1': 'Key Art',
        '2': 'Key Art',
        '3': 'Key Art',
        '4': 'Key Art',
        '5': 'Key Art',
        '6': 'Key Art',
        '7': 'Key Art',
        '8': 'Key Art',
        '9': 'Key Art',
        '10': 'Key Art',

        '11': 'Outdoor Art',
        '12': 'Outdoor Art',

        '13': 'Online',
        '15': 'Online',
        '16': 'Online',
        '17': 'Online',

        '18': 'Logo',
        '19': 'Logo',
        '20': 'Logo',
        '21': 'Logo',
        '22': 'Logo',
        '23': 'Logo',
        '24': 'Logo',
        '25': 'Logo',
        '26': 'Logo',

        '27': 'Photography/Stills',
        '28': 'Photography/Stills',
        '29': 'Photography/Stills',
        '30': 'Photography/Stills',

        '31': 'Gallery',
        '32': 'Gallery',
        '33': 'Gallery',
        '34': 'Gallery',
        '35': 'Gallery',
        '36': 'Gallery',

        '37': 'SVOD',
        '38': 'SVOD',
        '39': 'SVOD',

        '40': 'Client Samples/ Created',

        '41': 'Animation Production Material',
        '42': 'Animation Production Material',
        '43': 'Animation Production Material',
        '44': 'Animation Production Material',
        '45': 'Animation Production Material',
        '46': 'Animation Production Material',

        '47': 'BTS',

        '48': 'Events',
        '49': 'Events',
        '50': 'Events',
        '51': 'Events',

        '72': 'Social Media',
        '73': 'Social Media',
        '74': 'Social Media',
        '75': 'Social Media',

        '76': 'HBO Max',
        '77': 'HBO Max',
        '78': 'HBO Max',
        '79': 'HBO Max',
        '80': 'HBO Max',
        '81': 'HBO Max',

        'default.category': 'Other',
    },
    document: {
        'default.category': 'Document'
    },
    station: stationCatNames,
    merchandise: {
        'default.category': 'Merchandise'
    },
    script: {
        'default.category': 'Script'
    },
    'video-timeline': videoContentTypes,
    /*

  categoryOrder following
  https://warnerbrostv.atlassian.net/wiki/spaces/PB/pages/31555621/Asset+types+and+subtypes

Videos
-Full Episode/Feature
-Promo
-Element
-Behind the Scenes
-Sales
-Sample
Images
-Logo
-Key Art
-Outdoor Art
-Online
-SVOD
-Gallery
-Animation Production Material
-BTS
-Events
-Photography/Stills
-Other
-Client Samples/ Created

 */
    categoryOrder: {
        // Video categories
        'Full Episode/Feature': 10,
        'Promo': 20,
        'Element': 30,
        'Behind the Scenes': 40,
        'Sales': 50,
        'Sample': 60,

        // Image categories. The Ids are the priority to show first each supertype
        'Logo': 100,
        'Key Art': 110,
        'Outdoor Art':120,
        'Online':130,
        'SVOD': 140,
        'Gallery':150,
        'Animation Production Material': 160,
        'BTS': 170,
        'Events': 175,
        'Photography/Stills':180,
        'Social Media': 190,
        'HBO Max': 200,
        'Other': 1000,
        'Client Samples/ Created': 2000,

        // Station categories
        ...stationCatNamesById
    },

    getCategory: function(assetType, contentType) {
        const types = Categories[assetType];
        return types[contentType] || types['default.category'];
    },

    getCategoryOrder: function(assetType, contentType) {
        return Categories.categoryOrder[Categories.getCategory(assetType, contentType)];
    }
};

export default Categories;

export {videoContentTypes as VideoContentTypes};
