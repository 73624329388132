/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';

import LabelWithIcon from './label-with-icons';

type Props = {
    children: React.ReactNode;
    className?: string;
    icon: string;
    title: string;
};

export default function TitledBlock(props: Props) {
    return (
        <div className={props.className}>
            <LabelWithIcon icon={props.icon} message={props.title} />
            {props.children}
        </div>
    );
}

TitledBlock.defaultProps = {
    className: ''
};

