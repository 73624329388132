/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export default class LayoutContent extends React.Component {
    static get propTypes() {
        return {
            children: PropTypes.node.isRequired,
            className: PropTypes.string,
            minHeight: PropTypes.number
        };
    }

    static get defaultProps() {
        return {
            className: '',
            minHeight: 0
        };
    }

    render() {
        return (
            <div className={ClassNames('content-wrapper', this.props.className)} style={{minHeight: this.props.minHeight + 'px'}} >
                {this.props.children}
            </div>
        );
    }
}
