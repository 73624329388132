/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import SuperAgent from 'superagent';
import url from 'url';

import Config from '../config/config';
import {SessionActions} from '../user/session/session-actions';
import SessionStore from '../user/session/session-store';

import {AlertTypes} from '~/src/common/notification/alert';
import {NotificationActions} from '~/src/common/notification/notification-actions';
import {UserActions, UserConstants} from '~/src/user/user-actions';


/**
 * Enable promises in SuperAgent.
 */
SuperAgent.Request.prototype.exec = function(canceler) {
    let subscription;
    if (canceler) {
        subscription = canceler.subscribe(() => {
            this.abort();
        });
    }
    return new Promise((resolve, reject) => {
        this.end(function(err, res) {
            subscription && subscription.unsubscribe();
            if (err) {
                return reject(err);
            }
            return resolve(res);
        });
    });
};

// its hard to test this with SuperAgent_mock
// so will ignore next.
/* istanbul ignore next */
const getAgent = function() {
    // don't patch end if was patched already
    if (SuperAgent._withSession) {
        return SuperAgent;
    }

    SuperAgent._withSession = true;

    // Tell SuperAgent how to parse the API response body.
    SuperAgent.parse['application/vnd.wbtv-v1+json'] = JSON.parse;
    SuperAgent.parse['application/vnd.wbtv-v2+json'] = JSON.parse;
    SuperAgent.parse['application/vnd.wbtv-v3+json'] = JSON.parse;

    // Patch Request.end()
    let oldEnd = SuperAgent.Request.prototype.end;
    SuperAgent.Request.prototype.end = function(cb) {
        // set logged in request headers
        if (localStorage.getItem('__token') && !this.get('Authorization')) {
            this.set('Authorization', localStorage.getItem('__token'));
        }
        if (!this.get('Accept')) {
            this.set('Accept', 'application/vnd.wbtv-v3+json,application/vnd.wbtv-v2+json;q=0.2,application/vnd.wbtv-v1+json;q=0.1');
        }
        if (this.method !== 'GET' && this.get('Content-Type') === undefined) {
            this.set('Content-Type', 'application/json');
        }
        oldEnd.call(this, function(err, res) {
            if (!err && res.ok) {
                // Reset the timeout timer, but do it later, please.
                setTimeout(SessionActions.update);
            } else {
                if (err.status === 404 && SessionStore.isSessionValid()) {
                    //Session is active, but received a 404, check heartbeat to see if it has active session
                    SessionActions.keepAlive();
                }

                // If CloudFlare fails, then x-braniac-epoch is not present in the res header. And we inform the user.
                if (err.status === 403 && !res.headers['x-brainiac-epoch']) {
                    if (!res.req.url.includes('user/error')) {
                        // Prevent infinite loop if the user/error request fails due to cloudflare
                        const payload = res.req._data;

                        console.error('Cloudflare blocked request detected', {res, err, payload});

                        UserActions.reportError(res.error.message, res.req.method, res.req.url, payload, UserConstants.USER_ERROR_TYPES.CLOUDFLARE.id, true);
                        NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'request.cloudflare.error');
                    }
                }
            }

            cb(err, res);
        });
    };
    return SuperAgent;
};

const resolveURL = function(uri) {
    if (!url.parse(uri).protocol) {
        return url.resolve(Config.ApiBaseUrl, uri);
    }

    return uri;
};

// its hard to test this with SuperAgent_mock
// so will ignore next.
/* istanbul ignore next */
let agent = {
    del: function(uri) {
        return getAgent().del(resolveURL(uri));
    },

    get: function(uri) {
        return getAgent().get(resolveURL(uri));
    },

    post: function(uri) {
        return getAgent().post(resolveURL(uri));
    },

    put: function(uri) {
        return getAgent().put(resolveURL(uri));
    }
};

export default agent;
export {
    getAgent,
    resolveURL
};
