/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React from 'react';
import {Col, Row} from 'react-bootstrap';

import IngestRowsTable from './ingest-rows-table';
import {MassAddUpdateActions} from './mass-add-update-actions';
import MassAddUpdateStore from './mass-add-update-store';
import Table from './table';
import AuthLink from '../../common/auth-link';
import {RouterActions} from '../../router/router-actions';

class Confirmation extends React.Component {
    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            massAddProcess: MassAddUpdateStore.getState().get('massAddProcess'),
            relatedTitle: MassAddUpdateStore.getState().get('relatedTitle'),
            rows: MassAddUpdateStore.getState().get('rows')
        };
    }

    static getStores() {
        return [MassAddUpdateStore];
    }

    componentDidMount() {
        this.init(this.props);
        return;
    }

    init(props) {
        if (props.route.mode === 'edit') {
            MassAddUpdateActions.findById(props.params.id);
        } else {
            MassAddUpdateActions.clear();
        }
    }

    handleOpenMassAddLogs() {
        RouterActions.redirect('/titles/mass-update-logs', true);
    }

    render() {
        let columns = ['status', 'undo', 'complete', 'failed', 'rejected', 'batchId', 'relatedTitle', 'createdBy', 'createdDate', 'updatedBy', 'updatedDate'];
        let complete = 0;
        let failed = 0;
        let rejected = 0;
        let showComplete;
        let showFailed;
        let showRejected;

        this.state.rows.forEach(row => {
            switch (row.get('status')) {
            case 'Complete':
                complete++;
                break;
            case 'Rejected':
                rejected++;
                break;
            default:
                failed++;
            }
        });

        if (complete) {
            showComplete = (
                <div>
                    <p>
                        <span className="text-green">
                            <i className="fas fa-check"></i> <strong> {complete} {this.context.intl.messages['titles.mass-add.confirmation-message.successful']}</strong>
                        </span>
                    </p>
                    <hr/>
                </div>
            );
        }

        if (failed) {
            showFailed = (
                <div>
                    <p>
                        <span className="text-red">
                            <i className="fas fa-exclamation-triangle"></i> <strong> {this.context.intl.messages['titles.mass-add.confirmation-message.errors-found']} </strong>
                        </span>
                        {this.context.intl.messages['titles.mass-add.confirmation-message.errors-message']}
                        <AuthLink href={`title/ingest/${this.state.massAddProcess.get('titleIngestHeaderId')}/failed`} className="btn btn-default">
                            <i className="fas fa-download"></i> {this.context.intl.messages['titles.mass-add.confirmation-message.errors-download']}
                        </AuthLink>
                    </p>
                    <hr/>
                </div>
            );
        }

        if (rejected) {
            showRejected = (
                <div>
                    <p>
                        <i className="fas fa-ban"></i>
                        <strong> {rejected} {this.context.intl.messages['titles.mass-add.confirmation-message.rows-rejected']}</strong>
                    </p>
                    <hr/>
                </div>
            );
        }

        return (
            <div>
                <section className="content-header">
                    <h1><i className="far fa-newspaper"></i>&nbsp;{this.context.intl.messages['titles.mass-add.title']}
                        &nbsp;<small>{this.state.rows.size} {this.context.intl.messages['titles.mass-add.titles-length']}</small>
                    </h1>
                </section>
                <div>
                    <div className="content">
                        <Row>
                            <Col xs={12}>
                                <div className="container-fluid no-x-padding">
                                    <Row>
                                        <Col xs={12}>
                                            <div className="box">
                                                <div className="box-body">
                                                    <h3>{this.context.intl.messages['titles.mass-add.confirmation.title']}
                                                        <a className="btn btn-default pull-right" onClick={this.handleOpenMassAddLogs}>
                                                            <i className="fas fa-reply"></i>{this.context.intl.messages['titles.mass-log.summary-tab-title']}
                                                        </a>
                                                    </h3>
                                                    <hr/>
                                                    <Table massAddProcess={this.state.massAddProcess}
                                                        columns={columns}
                                                        complete={complete}
                                                        failed={failed}
                                                        rejected={rejected}
                                                        relatedTitle={this.state.relatedTitle}
                                                        step="confirmation"
                                                    />
                                                    <hr/>
                                                    {showComplete}
                                                    {showFailed}
                                                    {showRejected}
                                                    <IngestRowsTable step="confirmation" rows={this.state.rows}/>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default Container.create(Confirmation);
