/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

import {AlertTypes} from '../../common/notification/alert';
import {NotificationActions} from '../../common/notification/notification-actions';
import Dispatcher from '../../dispatcher/dispatcher';
import Request from '../../request';

const CONSTANTS = {
    ACTION_OBJECTS: {
        ASSETTOCATALOG: 'AssetToCatalog',
        ASSETTOTITLE : 'AssetToTitle',
        AUDIO: 'Audio',
        CLIENTREPGROUP: 'ClientRepGroup',
        DOCUMENT: 'Document',
        DOWNLOAD: 'Download',
        EVENT: 'Event',
        FEATURE_CONTROL: 'FEATURE_CONTROL',
        GROUP: 'Group',
        GUILD: 'Guild',
        HOMEPAGE: 'HomePage',
        HOMESCREEN: 'HomeScreen',
        IMAGE: 'Image',
        MERCHANDISE: 'Merchandise',
        PRIVACYREQUEST: 'PrivacyRequest',
        PUBLISHLIST: 'PublishList',
        PUBLISHLISTITEM: 'PublishListItem',
        PUBLISHLISTITEMGROUP:'PublishListItemGroup',
        PUSHNOTIFICATIONS: 'PushNotifications',
        ROLE: 'Role',
        SCHEDULEBATCH: 'ScheduleBatch',
        SCRIPT: 'Script',
        SIMPLE_WEB_PAGE: 'simple_web_page',
        TALENT: 'Talent',
        TITLE: 'Title',
        TITLEINGESTHEADER: 'TitleIngestHeader',
        TITLEINGESTROW: 'TitleIngestRow',
        TITLETOCATALOG: 'TitleToCatalog',
        TITLETOGENRE: 'TitleToGenre',
        TITLETOPRODUCTIONCOMPANY: 'TitleToProductionCompany',
        USER: 'User',
        USERTOGROUP: 'UserToGroup',
        VIDEO: 'Video',
        VIDEODELIVERY: 'video_delivery',
        VIDEOTIMELINE: 'video_timeline',
        VIDEO_CLIP: 'video_clip'
    },
    TYPES: {
        USER_GENERATED: 'User Generated',
        CREATE: 'Create',
        UPDATE: 'Update',
        DELETE: 'Delete',
        DECOMISSION: 'Decomission',
        DEACTIVATE: 'Deactivate'
    },
    ADD_NOTE: {
        SUCCESS: 'history_actions.add_note.success',
        ERROR: 'history_actions.add_note.error'
    }
};

class ActionHistoryActions {
    /**
     * @param id int
     * @param description object
     * @param actionObjectType string
     * @param groupType string
     */
    addNote(id, description, actionObjectType, groupType) {
        Request.post('system/action-history').send({
            objectId: id,
            actionObjectType: actionObjectType,
            changeDescription: description
        }).exec().then(res => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.ADD_NOTE.SUCCESS,
                note: Immutable.fromJS(res.body),
                actionObjectType,
                groupType
            });
            NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, CONSTANTS.ADD_NOTE.SUCCESS);
        }).catch(err => {
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, CONSTANTS.ADD_NOTE.ERROR);
            throw err;
        });
        return;
    }
}


const actions = new ActionHistoryActions();

export {
    actions as ActionHistoryActions,
    CONSTANTS as ActionHistoryConstants
};
