/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

const ENVIRONMENTS = {
    DEV: 'dev',
    PRODUCTION: 'production',
    QA: 'qa',
    UAT: 'uat'
};

module.exports = {
    ApiBaseUrl: 'https://api.wbtvd.com/',
    APIEnvironment: 2, // From https://github.com/wbdt-sie/brainiac-api-monolith/blob/develop/brainiac-core/src/main/java/com/wbtv/brainiac/api/system/Environment.java#L20
    Datadog: {
        // WBD tagging documentation: https://wbddigital.atlassian.net/wiki/spaces/STUDIO/pages/92438568/Datadog
        applicationId: '60f19096-026d-4f07-a856-d2919c11a2da',
        clientToken: 'pubced63cb3efedef185277a7e7a093ceca',
        enabledForEnvironment: true,
        env: 'production',
        service: 'sie:brainiac:cms',
        sessionReplaySampleRate: 5,
        sessionSampleRate: 50,
        traceSampleRate: 50,
    },
    DefaultTimezone: 'America/Los_Angeles',
    Environment: ENVIRONMENTS.PRODUCTION,
    Environments: ENVIRONMENTS,
    GoogleAnalyticsId: 'UA-78344802-1',
    Mux: '29f2f9e4284e53afe975cdff9',
    SessionTimeout: 1500,
    SessionTimeoutWarning: 1200,
    WBTVDBaseUrl: 'https://www.wbtvd.com',
    WBTVPartnerId: 'BRAINIAC',
    WBTVPartnerKey: 'Wny4rRjC0f8bilvncO'
};
