/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';
import Moment from 'moment';
import {timer} from 'rxjs';
import {flatMap} from 'rxjs/operators';

import {AlertTypes} from '~/src/common/notification/alert';
import {NotificationActions} from '~/src/common/notification/notification-actions';
import {UploadFile} from '~/src/common/utils/utils';
import config from '~/src/config/config.js';
import Dispatcher from '~/src/dispatcher/dispatcher';
import {PreloaderActions} from '~/src/preloader/preloader-actions';
import Request from '~/src/request';
import {RouterActions} from '~/src/router/router-actions';

// Require for Proper Timezone Display
require('moment-timezone');
const configtz = Moment().tz(config.DefaultTimezone).format('ZZ');

const CONSTANTS = {
    AUTO_REFRESH: {
        SET_AUTO_REFRESH: 'accounts.account-wizard.auto-refresh.set-auto-refresh',
        SET_AUTO_REFRESH_OBSERVABLE: 'accounts.account-wizard.auto-refresh.set-auto-refresh-observable'
    },
    BATCH_STATUS_TYPES: {
        // Additional status types defined by IngestHeaderStatusType.java
        // FAILED (only used in a test)
        // NOT_PROCESSED (used by title ingestservice)
        CONTAINS_ERRORS: {
            description: 'accounts.account-wizard.batch-status-types.contains-errors',
            tooltip: 'accounts.account-wizard.batch-status-types.contains-errors.tooltip',
            tooltipEditBatch: 'accounts.account-wizard.batch-status-types.contains-errors.tooltip-edit',
            icon: 'fa-solid fa-triangle-exclamation fa-fw text-red',
            id: 'Contains Errors'
        },
        PENDING: {
            description: 'accounts.account-wizard.batch-status-types.pending',
            tooltip: 'accounts.account-wizard.batch-status-types.pending.tooltip',
            tooltipEditBatch: 'accounts.account-wizard.batch-status-types.pending.tooltip',
            icon: 'fa-solid fa-ellipsis-h fa-fw text-gray',
            id: 'Pending'
        },
        PROCESSED: {
            description: 'accounts.account-wizard.batch-status-types.processed',
            tooltip: 'accounts.account-wizard.batch-status-types.processed.tooltip',
            tooltipEditBatch: 'accounts.account-wizard.batch-status-types.processed.tooltip',
            icon: 'fa-solid fa-check fa-fw text-success',
            id: 'Processed'
        },
        PROCESSING: {
            description: 'accounts.account-wizard.batch-status-types.processing',
            tooltip: 'accounts.account-wizard.batch-status-types.processing.tooltip',
            tooltipEditBatch: 'accounts.account-wizard.batch-status_types.processing.tooltip',
            icon: 'fa-solid fa-ellipsis-h fa-fw text-primary',
            id: 'Processing'
        },
        REJECTED: {
            description: 'accounts.account-wizard.batch-status-types.rejected',
            tooltip: 'accounts.account-wizard.batch-status-types.rejected.tooltip',
            tooltipEditBatch: 'accounts.account-wizard.batch-status-types.rejected.tooltip',
            icon: 'fa-solid fa-thumbs-down fa-fw text-red',
            id: 'Rejected'
        }
    },
    CLEAR: 'accounts.account-wizard.clear',
    CLIENT_REP_GROUPS: {
        GET: {
            ERROR: 'accounts.account-wizard.client-rep-groups.get.error',
            START: 'accounts.account-wizard.client-rep-groups.get.start',
            SUCCESS: 'accounts.account-wizard.client-rep-groups.get.success'
        }
    },
    FINDBY: {
        ERROR: 'accounts.account-wizard.find-by.error',
        SUCCESS: 'accounts.account-wizard.find-by.success'
    },
    GET: {
        ERROR: 'accounts.account-wizard.get.error',
        START: 'accounts.account-wizard.get.start',
        SUCCESS: 'accounts.account-wizard.get.success'
    },
    SAVE: {
        ERROR: 'accounts.account-wizard.save.error',
        SUCCESS: 'accounts.account-wizard.save.success'
    },
    SET: {
        ADDITIONAL_SECURITY: 'accounts.account-wizard.set.additionalSecurity',
        BATCH_NAME: 'accounts.account-wizard.set.batch-name',
        CLIENT_REP_GROUPS: 'accounts.account-wizard.set.client-rep-groups',
        BATCH_PROP: 'accounts.account-wizard.set.batch-prop',
        DEFAULT_PARTNER: 'accounts.account-wizard.set.default-partner',
        EXCEEDED_USERS: 'accounts.account-wizard.set.exceeded-users',
        GROUPS: 'accounts.account-wizard.set.groups',
        ROLES: 'accounts.account-wizard.set.roles',
        PARTNERS: 'accounts.account-wizard.set.partners',
        PERMISSION_PACKAGE: 'accounts.account-wizard.set.permission-package',
    },
    USERS: {
        ADD_NEW_USER: 'accounts.account-wizard.users.add-new-user',
        CLEAR_EDITING: 'accounts.account-wizard.users.clear-editing',
        REMOVE_USER: 'accounts.account-wizard.users.remove-user',
        SELECT_ALL: 'accounts.account-wizard.users.select-all',
        UPDATE_USER: 'accounts.account-wizard.users.update-user',
        STATUS: {
            CONTAINS_ERRORS: {id: 'Contains Errors'},
            FAILED: {id: 'FAILED'},
            PENDING: {id: 'PENDING'},
        },
        ERRORS: {
            EXCEEDED: {id: 'Error Processing csv, Batch size limit exceeded, max limit is 200'},
            PROCESSING: {id: 'Error Processing csv'}
        }
    },
    VALIDATIONS: {
        GET: {
            ERROR: 'accounts.account-wizard.validations.get.error',
            START: 'accounts.account-wizard.validations.get.start',
            SUCCESS: 'accounts.account-wizard.validations.get.success',
        }
    },
    VALIDATIONS_ROW: {
        GET: {
            ERROR: 'accounts.account-wizard.validations-row.get.error',
            START: 'accounts.account-wizard.validations-row.get.start',
            SUCCESS: 'accounts.account-wizard.validations-row.get.success',
        }
    },
};

class AccountWizardActions {
    addNewUser(user) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.USERS.ADD_NEW_USER,
            user
        });
        return;
    }

    cancelAutoRefresh(autoRefreshObservable) {
        autoRefreshObservable?.unsubscribe();

        Dispatcher.dispatch({
            actionType: CONSTANTS.AUTO_REFRESH.SET_AUTO_REFRESH_OBSERVABLE,
            autoRefreshObs: null
        });
    }

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
        return;
    }

    clearEditing() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.USERS.CLEAR_EDITING
        });
        return;
    }

    createEmptyBatch() {
        // Request API create a batch without uploading a CSV file
        Request.post('user/ingest').exec()
            .then((res) => {
                const id = res.body.id;
                RouterActions.redirect(`/accounts/account-wizard/${id}`);
                NotificationActions.showAlertSuccess('accounts.account-wizard.create-empty-batch.success');
            }).catch(err => {
                NotificationActions.showAlertDanger('accounts.account-wizard.create-empty-batch.error');
                throw err;
            });
    }

    delete(id) {
        Request.del(`user/ingest/${id}`).exec().then(() => {
            NotificationActions.showAlertSuccess('accounts.account-wizard.delete-batch.success');
            RouterActions.redirect('/accounts/account-wizard/browse');
        }).catch(err => {
            NotificationActions.showAlertDanger('accounts.account-wizard.delete-batch.error');
            throw err;
        });
    }

    findById(id) {
        Promise.all([
            Request.get(`user/ingest/${id}`).exec(),
            Request.get(`user/ingest/${id}/rows`).exec(),
            Request.get(`user/ingest/${id}/client-rep`).exec(),
            Request.get(`user/ingest/${id}/partners`).exec(),
            Request.get(`user/ingest/${id}/groups`).exec(),
            Request.get(`user/ingest/${id}/roles`).exec(),
            Request.get('partner').query({offset: 0, size: 999999}).exec(),
            Request.get('security/group').query({offset: 0, size: 999999}).exec(),
            Request.get('security/role').query({offset: 0, size: 999999, 'count-users': false}).exec(),
        ]).spread((ingestRes, rowsRes, clientRepRes, partnersRes, groupsRes, rolesRes, allPartnersRes, allGroupsRes, allRolesRes) => {
            const apr = Immutable.fromJS(allPartnersRes.body);
            const batchPartners = partnersRes.body.map(p => {
                const ap = apr.get('results').find(a => a.get('id') === p.partnerId);
                p.name = ap.get('name');
                return p;
            });

            const agr = Immutable.fromJS(allGroupsRes.body);
            const groups = groupsRes.body.map(g => {
                const ag = agr.get('results').find(a => a.get('id') === g.groupId);
                g.name = ag.get('name');
                return g;
            });

            const arr = Immutable.fromJS(allRolesRes.body);
            const roles = rolesRes.body.map(r => {
                const ar = arr.get('results').find(a => a.get('id') === r.roleId);
                r.name = ar.get('name');
                return r;
            });

            let batch = ingestRes.body;
            if (!batch.name) {
                // BRAIN-3517 set the default name to Batch #1234
                batch.name = `Batch #${batch.id}`;
            }

            let findByResults = {
                actionType: CONSTANTS.FINDBY.SUCCESS,
                clientRepGroups: Immutable.fromJS(clientRepRes.body),
                contactUser: undefined,
                defaultPartner: Immutable.fromJS(batchPartners.find(p => p.defaultPartner === true)),
                groups: Immutable.fromJS(groups),
                accountWizardProcess: Immutable.fromJS(batch),
                partners: Immutable.fromJS(batchPartners),
                roles: Immutable.fromJS(roles),
                selectedEventId: ingestRes.body.eventId,
                users: Immutable.fromJS(rowsRes.body),
            };

            const batchErrorMessage = ingestRes.body.batchErrorMessage;
            if (batchErrorMessage === CONSTANTS.USERS.ERRORS.EXCEEDED.id) {
                this.setExceededUsers(true);
            }

            if (clientRepRes.body.length ) {
                const contactUserId = clientRepRes.body[0].contactUserId;
                Request.get(`user/${contactUserId}`).exec().then(contactRes => {
                    findByResults.contactUser = Immutable.fromJS(contactRes.body);

                    Dispatcher.dispatch(findByResults);
                });
            } else {
                Dispatcher.dispatch(findByResults);
            }
        }).catch(err => {
            switch (err.status) {
            case 404:
                RouterActions.notFound();
                break;
            default:
                Dispatcher.dispatch({
                    actionType: CONSTANTS.FINDBY.ERROR,
                    error: err
                });
                NotificationActions.showAlertDanger('accounts.account-wizard.error.load');
                break;
            }
            throw err;
        });
        return;
    }

    get(queryParams, offset, size, sortFieldName, sortDirection) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.GET.START
        });

        queryParams = queryParams.toJS();

        PreloaderActions.show('account-wizard-actions.get');

        offset = offset || queryParams.offset || 0;
        size = size || 20;
        queryParams.offset = offset ;
        queryParams.size = size;
        queryParams['sort-field-name'] = sortFieldName || queryParams['sort-field-name'] || 'CREATED_DATE';
        queryParams['sort-direction'] = sortDirection || queryParams['sort-direction'] || 'desc';

        ['start-last-updated', 'end-last-updated'].forEach(attr => {
            let d = queryParams[attr];
            if (d) {
                d = Moment(d);
                if (d.isValid()) {
                    d = d.format('YYYY-MM-DDT00:00:00.000'+configtz);
                } else {
                    d = '';
                }
                queryParams[attr] = d;
            }
        });

        return Request.get('user/ingest').query(queryParams).exec()
            .then(response => {
                PreloaderActions.hide('account-wizard-actions.get');

                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET.SUCCESS,
                    offset: offset,
                    size: size,
                    batches: Immutable.fromJS(response.body.results),
                    total: parseInt(response.body.totalCount, 10)
                });

                return;
            }).catch(err => {
                PreloaderActions.hide('account-wizard-actions.get');

                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET.ERROR
                });

                NotificationActions.showAlertDanger('accounts.account-wizard.browse.error');

                throw err;
            });
    }

    getClientReps(offset, size) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLIENT_REP_GROUPS.GET.START
        });

        Request.get('security/client-rep-group/rep').query({
            offset: offset,
            size: size
        }).exec().then(res => {
            const clientReps = res.body.results.map(rep => {
                return {
                    id: rep.clientRepGroupId,
                    name: rep.clientRepGroupName,
                    contactUserId: rep.userId,
                    contactFirstName: rep.firstName,
                    contactLastName: rep.lastName
                };
            });

            Dispatcher.dispatch({
                actionType: CONSTANTS.CLIENT_REP_GROUPS.GET.SUCCESS,
                clientRepGroups: Immutable.fromJS(clientReps)
            });
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.CLIENT_REP_GROUPS.GET.ERROR
            });
            throw err;
        });

        return;
    }

    getUserRowValidations(id) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.VALIDATIONS.GET.START,
        });

        PreloaderActions.show('account-wizard-actions.validations.get');

        Request.get(`user/ingest/${id}/rows/validate`).exec().then((res) => {
            PreloaderActions.hide('account-wizard-actions.validations.get');
            const results = res.body;

            let unique = [];
            let userRowValidations = {};
            results.forEach(c => {
                // Build unique list of errors
                c.userIngestValidations.forEach(v => {
                    if (!unique.find(e => e.userValidationType === v.userValidationType)) {
                        unique.push(v);
                    }
                });

                // Build map of user row validations based on row id
                userRowValidations[c.userIngestRow.id] = {
                    userIngestRow: c.userIngestRow,
                    userIngestValidations: c.userIngestValidations
                };
            });

            Dispatcher.dispatch({
                actionType: CONSTANTS.VALIDATIONS.GET.SUCCESS,
                userRowValidations: Immutable.fromJS(userRowValidations),
                uniqueUserRowValidations: Immutable.fromJS(unique)
            });
        }).catch((err) => {
            PreloaderActions.hide('account-wizard-actions.validations.get');
            Dispatcher.dispatch({
                actionType: CONSTANTS.VALIDATIONS.GET.ERROR,
            });

            NotificationActions.showAlertDanger('accounts.account-wizard.validations.error');

            throw err;
        });
    }

    removeUser(userId) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.USERS.REMOVE_USER,
            id: userId
        });
        return;
    }

    saveBatch(batch) {
        const preloaderSource = 'account-wizard-actions.saveBatch';
        PreloaderActions.show(preloaderSource);
        batch = batch.merge({
            groups: batch.get('groups').map(g => {
                if (!g.get('groupId')) {
                    return {groupId: g.get('id')};
                }

                return g;
            }),
            roles: batch.get('roles').map(r => {
                if (!r.get('roleId')) {
                    return {roleId: r.get('id')};
                }

                return r;
            }),
            partners: batch.get('partners').map(p => {
                let partnerId = p.get('id');
                if (p.get('partnerId')) {
                    partnerId = p.get('partnerId');
                }

                let partner = {
                    partnerId,
                    defaultPartner: false
                };
                if (partnerId === batch.get('defaultPartner').get('id') || partnerId === batch.get('defaultPartner').get('partnerId')) {
                    partner.defaultPartner = true;
                }
                return partner;
            })
        });

        const id = batch.get('id');
        let requests = [];
        const ingestRows = batch.get('rows').toJS().map(user => {
            if (user.newUserRow) {
                user.id = null;
            }
            // Update status if company and country have a value
            if (
                user.country !== null && user.country !== undefined &&
                user.company !== null && user.company !== undefined &&
                user.status === CONSTANTS.USERS.STATUS.FAILED.id
            ) {
                user.status = CONSTANTS.USERS.STATUS.PENDING.id;
            }
            return user;
        });

        const clientRepGroupsData = batch.get('clientRepGroups').toJS().reduce((prevValue, currentValue) => {
            let userId = prevValue.userId;
            if (!prevValue.userId) {
                userId = currentValue.contactUserId;
            }

            return {
                userId: userId,
                clientRepGroupList: prevValue.clientRepGroupList.concat([currentValue])
            };
        }, {userId: null, clientRepGroupList: []});

        requests.push(
            Request.put(`user/ingest/${id}/rows`).send(ingestRows).exec()
        );

        requests.push(
            Request.put(`user/ingest/${id}/client-rep`).send(clientRepGroupsData).exec()
        );

        requests.push(
            Request.put(`user/ingest/${id}/partners`).send(batch.get('partners').toJS()).exec()
        );

        requests.push(
            Request.put(`user/ingest/${id}/groups`).send(batch.get('groups').toJS()).exec()
        );

        requests.push(
            Request.put(`user/ingest/${id}/roles`).send(batch.get('roles').toJS()).exec()
        );

        let maddAddProcessData = batch.get('accountWizardProcess').merge({eventId: batch.get('selectedEventId')});
        if (
            maddAddProcessData.get('status') === CONSTANTS.USERS.STATUS.CONTAINS_ERRORS.id &&
            batch.get('rows').findIndex(user => user.get('status') === CONSTANTS.USERS.STATUS.FAILED.id) === -1
        ) {
            maddAddProcessData = maddAddProcessData.set('status', CONSTANTS.USERS.STATUS.PENDING.id);
        }
        requests.push(
            Request.put(`user/ingest/${id}`).send(maddAddProcessData).exec()
        );

        Promise.all(requests)
            .then(() => {
                Request.get(`user/ingest/${id}/rows`).exec().then((rowsRes) => {
                    Dispatcher.dispatch({
                        actionType: CONSTANTS.SAVE.SUCCESS,
                        originalEventId: maddAddProcessData.get('eventId'),
                        users: Immutable.fromJS(rowsRes.body)
                    });

                    if (batch.get('createAccounts') === true) {
                        const createRowsData = rowsRes.body.map(u => {
                            u.status = 'CONFIRMED';
                            return u;
                        });

                        return Request.put(`user/ingest/${id}/rows`).send(createRowsData).exec().then(() => {
                            return Request.post(`user/ingest/${id}/process?threaded=true`).exec().then(() => {
                                PreloaderActions.hide(preloaderSource);
                                NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'accounts.account-wizard.save.success');
                                RouterActions.redirect('/accounts/account-wizard/browse');
                            }).catch(() => {
                                PreloaderActions.hide(preloaderSource);
                                NotificationActions.showAlertDanger('accounts.account-wizard.batch-create.error');
                            });
                        }).catch(() => {
                            PreloaderActions.hide(preloaderSource);
                            NotificationActions.showAlertDanger('accounts.account-wizard.batch-create.error');
                        });
                    } else {
                        PreloaderActions.hide(preloaderSource);
                        NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'accounts.account-wizard.batch-save.success');
                        this.getUserRowValidations(id);
                    }
                });
            }).catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.SAVE.ERROR,
                    error: err
                });
                PreloaderActions.hide(preloaderSource);
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'accounts.account-wizard.batch-save.error');
                throw err;
            });
    }

    selectPermissionPackage(permissionPackage) {
        Request.get(`security/permission-package/${permissionPackage.get('id')}`).exec().then(res => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.SET.PERMISSION_PACKAGE,
                groups: Immutable.fromJS(res.body.groups).toSet(),
                roles: Immutable.fromJS(res.body.roles).toSet(),
                defaultPartnerId: res.body.defaultPartnerId,
                webPartnerIds: res.body.webPartnerIds,
            });
            return;
        });
        return;
    }

    save(id, groups, roles, defaultPartner, partners, clientRepGroupData) {
        let groupsData = groups.map(g => {
            return {
                groupId: g.get('id')
            };
        });
        let requests = [];
        let rolesData = roles.map(r => {
            return {
                roleId: r.get('id')
            };
        });
        let partnersData = partners.map(p => {
            let partner = {
                partnerId: p.get('id'),
                defaultPartner: false
            };
            if (p.get('id') === defaultPartner.get('id')) {
                partner.defaultPartner = true;
            }
            return partner;
        });
        if (groupsData.size) {
            requests.push(
                Request.put(`user/ingest/${id}/groups`).send(groupsData.toJS()).exec()
            );
        }
        if (rolesData.size) {
            requests.push(
                Request.put(`user/ingest/${id}/roles`).send(rolesData.toJS()).exec()
            );
        }
        if (partnersData.size) {
            requests.push(
                Request.put(`user/ingest/${id}/partners`).send(partnersData.toJS()).exec()
            );
        }

        if (clientRepGroupData) {
            requests.push(
                Request.put(`user/ingest/${id}/client-rep`).send(clientRepGroupData).exec()
            );
        }

        Promise.all(requests)
            .then(() => {
                Request.get(`user/ingest/${id}/rows`).exec().then((rowsRes) => {
                    let rowsData = rowsRes.body;
                    rowsData.forEach(row => {
                        row.status = 'CONFIRMED';
                    });
                    return Request.put(`user/ingest/${id}/rows`).send(rowsData).exec();
                }).then(() => {
                    return Request.post(`user/ingest/${id}/process?threaded=true`).exec().then(() => {
                        Dispatcher.dispatch({
                            actionType: CONSTANTS.SAVE.SUCCESS
                        });
                        RouterActions.redirect('/accounts');
                        NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'accounts.account-wizard.save.success');
                    }).catch(err => {
                        Dispatcher.dispatch({
                            actionType: CONSTANTS.SAVE.ERROR,
                            error: err
                        });
                        NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'accounts.account-wizard.save.error');
                        throw err;
                    });
                });
            });
    }

    selectAll(selected) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.USERS.SELECT_ALL,
            selected
        });
        return;
    }

    setAdditionalSecurity(value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SET.ADDITIONAL_SECURITY,
            value
        });
        return;
    }

    setAutoRefresh(isChecked, autoRefreshObservable, userId, queryParams) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.AUTO_REFRESH.SET_AUTO_REFRESH,
            isChecked,
            userId
        });

        let autoRefreshObs = null;
        if (isChecked) {
            // Start auto refresh.
            autoRefreshObs = timer(
                30000, // Start in 30 sec.
                30000 // Every 30 seconds
            ).pipe(
                flatMap(() => this.get(queryParams)),
            ).subscribe();

            Dispatcher.dispatch({
                actionType: CONSTANTS.AUTO_REFRESH.SET_AUTO_REFRESH_OBSERVABLE,
                autoRefreshObs
            });
        } else {
            // Cancel auto refresh
            this.cancelAutoRefresh(autoRefreshObservable);
        }
    }

    setBatchName(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SET.BATCH_NAME,
            attr,
            value
        });
        return;
    }

    setBatchProp(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SET.BATCH_PROP,
            attr,
            value
        });
        return;
    }

    setClientRepGroups(selectedClientRepGroups) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SET.CLIENT_REP_GROUPS,
            selectedClientRepGroupIds: Immutable.fromJS(selectedClientRepGroups)
        });
        return;
    }

    setDefaultPartner(defaultPartner) {
        if (defaultPartner.partnerId) {
            defaultPartner = {
                ...defaultPartner,
                id: defaultPartner.partnerId
            };
        }

        Dispatcher.dispatch({
            actionType: CONSTANTS.SET.DEFAULT_PARTNER,
            defaultPartner: Immutable.fromJS(defaultPartner)
        });
        return;
    }

    setExceededUsers(showExceededUsers) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SET.EXCEEDED_USERS,
            showExceededUsers
        });
    }

    setGroups(groups) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SET.GROUPS,
            selected: groups
        });
        return;
    }

    setPartners(partners) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SET.PARTNERS,
            selected: partners
        });
        return;
    }

    setRoles(roles) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SET.ROLES,
            selected: roles
        });
        return;
    }

    updateUser(userId, attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.USERS.UPDATE_USER,
            id: userId,
            attr: attr,
            value: value
        });
        return;
    }

    updateUserRowValidations(userRow) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.VALIDATIONS_ROW.GET.START,
        });

        PreloaderActions.show('account-wizard-actions.validations-row.get');

        Request.post('user/validation/user-detail').send(userRow.toJS()).exec().then((res) => {
            PreloaderActions.hide('account-wizard-actions.validations-row.get');
            const results = res.body;

            Dispatcher.dispatch({
                actionType: CONSTANTS.VALIDATIONS_ROW.GET.SUCCESS,
                id: userRow.get('id'),
                userRowValidations: Immutable.fromJS(results),
            });
        }).catch((err) => {
            PreloaderActions.hide('account-wizard-actions.validations-row.get');
            Dispatcher.dispatch({
                actionType: CONSTANTS.VALIDATIONS_ROW.GET.ERROR,
            });

            NotificationActions.showAlertDanger('accounts.account-wizard.validations.error');

            throw err;
        });
    }

    uploadAccountWizard(file) {
        if (file) {
            return UploadFile(
                'POST',
                'user/ingest',
                file,
                new XMLHttpRequest()
            ).then((res) => {
                let id = JSON.parse(res).id;
                let statusMessage = JSON.parse(res).batchErrorMessage;
                if (statusMessage !== null) {
                    let errorMsg;
                    switch (statusMessage) {
                    case CONSTANTS.USERS.ERRORS.EXCEEDED.id:
                        errorMsg = 'exceeded';
                        break;
                    case CONSTANTS.USERS.ERRORS.PROCESSING.id:
                    default:
                        errorMsg = 'processing';
                        break;
                    }
                    NotificationActions.showAlertDanger(`accounts.account-wizard.error.${errorMsg}`);
                } else {
                    RouterActions.redirect(`/accounts/account-wizard/${id}`);
                }
            }).catch(err => {
                NotificationActions.showAlertDanger('accounts.account-wizard.error.uploading');
                throw err;
            });
        }
    }

}

let actions = new AccountWizardActions();

export {
    actions as AccountWizardActions,
    CONSTANTS as AccountWizardConstants
};
