/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';

import Dispatcher from '../../dispatcher/dispatcher';
import Request from '../../request';
import {TitleConstants} from '../title-actions';

const CONSTANTS = {
    CLEAR:'serie.navigation.clear',
    GET: {
        SUCCESS: 'serie.navigation.get.success',
        START:'serie.navigation.get.start',
        ERROR:'serie.navigation.get.error'
    }
};

class SerieNavigationActions {
    titleInSerie(currentNavigation, id) {
        if (currentNavigation) {
            if (currentNavigation.getIn(['serieMap', `${id}`]) !== undefined) {
                return true;
            }
        }
        return false;
    }

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
    }

    get(id, currentNavigation) {
        if (!this.titleInSerie(currentNavigation, id)) {
            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.START
            });
            Request.get(`title/${id}/season-episode-list`).query({active: false})
                .exec()
                .then(res => {
                    let serieData = res.body;
                    serieData.seasons.sort( (a, b) => {
                        return a.seasonNumber - b.seasonNumber;
                    });
                    serieData.seasons.forEach (s => {
                        s.episodes.sort( (a, b) => {
                            return a.episodeNumber - b.episodeNumber;
                        });
                    });
                    let serieMap = {};
                    serieMap[`${serieData.titleId}`]=[];
                    serieData.serieMap = serieData.seasons.reduce( (m, season, i) => {
                        m[`${season.titleId}`] = [i];
                        m = season.episodes.reduce( (me, episode, j) => {
                            me[`${episode.titleId}`] = [i, j];
                            return me;
                        }, m);
                        return m;
                    }, serieMap);
                    Dispatcher.dispatch({
                        actionType: CONSTANTS.GET.SUCCESS,
                        serieNavigation: Immutable.fromJS(serieData)
                    });
                    return;
                })
                .catch(err => {
                    if (err?.response?.statusCode === 422 || err?.response?.statusCode === 404) {
                        // There's no season related to title id, try mini series navigation
                        this.getMiniSerie(id, currentNavigation);
                    } else {
                        Dispatcher.dispatch({
                            actionType: CONSTANTS.GET.ERROR,
                            err: err
                        });
                    }
                });
            return;
        }
    }

    getMiniSerie(id, currentNavigation) {
        let relatedTitles;
        if (!this.titleInSerie(currentNavigation, id)) {
            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.START
            });
            let titleId = id;
            Request.get(`title/${titleId}/related-title`).query({active: false}).then(res => {
                if (res.body.length === 0) {
                    res.body = [
                        {}
                    ];
                }
                let isMiniSerie = res.body.length > 1;
                let request = {};
                if (!isMiniSerie) {
                    titleId = res.body[0].childTitleId;
                    request = Request.get(`title/${titleId}/related-title`).query({active: false}).exec();
                }
                return Promise.all([titleId, res.body, Promise.resolve(request)]);
            }).then(([serieId, res, requestRes]) => {
                relatedTitles = res;
                if (requestRes.body) {
                    relatedTitles = requestRes.body;
                }
                let episodes = [];
                let serieData = {
                    titleId: serieId
                };
                let serieMap = {};
                relatedTitles.forEach(relatedTitle => {
                    if (relatedTitle.relationshipType === TitleConstants.TITLE_RELATIONSHIP_TYPE.EPISODE.id) {
                        episodes.push(relatedTitle);
                    }
                });
                serieData.episodes = episodes.sort( (a, b) => {
                    return a.orderWithinParent - b.orderWithinParent;
                });
                serieMap[`${serieId}`]=[];
                serieData.serieMap = serieData.episodes.reduce((m, episode, i) => {
                    m[`${episode.childTitleId}`] = [i];
                    return m;
                }, serieMap);

                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET.SUCCESS,
                    serieNavigation: Immutable.fromJS(serieData)
                });

            }).catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET.ERROR,
                    err: err
                });
            });
            return;
        }
    }
}

let actions = new SerieNavigationActions();

export {
    actions as SerieNavigationActions,
    CONSTANTS as SerieNavigationConstants
};
