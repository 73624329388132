/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */


import Immutable from 'immutable';

import {NotificationActions} from '~/src/common/notification/notification-actions';
import Dispatcher from '~/src/dispatcher/dispatcher';
import Request from '~/src/request';
import {RouterActions} from '~/src/router/router-actions';

const CONSTANTS = {
    CLEAR: 'privacy.clear',
    CREATE_REQUEST: {
        ERROR: 'privacy.create_request.error',
        START: 'privacy.create_request.start',
        SUCCESS: 'privacy.create_request.success',
    },
    FIND_BY_ID: {
        START: 'privacy.find_by_id.start',
        SUCCESS: 'privacy.find_by_id.success',
        ERROR: 'privacy.find_by_id.error'
    },
    GET: {
        START: 'privacy.get.start',
        SUCCESS: 'privacy.get.success',
        ERROR: 'privacy.get.error'
    },
    REGULATION_TYPE: {
        CCPA: {
            id: 'ccpa', name: 'ccpa', label: 'privacy.regulation-type.ccpa'
        },
        USA: {
            id: 'usa', name: 'usa', label: 'privacy.regulation-type.usa'
        },
        CPRA: {
            id: 'cpra', name: 'cpra', label: 'privacy.regulation-type.cpra'
        },
    },
    REQUEST_TYPE: {
        ACCESS: {
            id: 'access_request', name: 'access_request', label: 'privacy.request-type.access'
        },
        DELETE: {
            id: 'delete_data', name: 'delete_data', label: 'privacy.request-type.delete'
        },
    },
    UPDATE: 'privacy.update',
};

class PrivacyActions {
    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
    }

    createRequest(privacyRequest) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CREATE_REQUEST.START,
        });

        const data = privacyRequest.toJS();
        Request.post('integration/ccpa/manual-irr')
            .send(data)
            .exec()
            .then(res => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.CREATE_REQUEST.SUCCESS,
                    privacyRequest: Immutable.fromJS(res.body),
                });

                setTimeout(() => {
                    NotificationActions.showAlertSuccess('privacy.create-request.success');
                }, 10);

                RouterActions.redirect(`/privacy/${res.body.id}`);
            }).catch((err) => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.CREATE_REQUEST.ERROR,
                });

                NotificationActions.showAlertDanger('privacy.create-request.error');
                throw err;
            });
    }

    findById(id) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.FIND_BY_ID.START,
        });

        Request.get(`integration/ccpa/${id}`)
            .exec()
            .then(res => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.FIND_BY_ID.SUCCESS,
                    privacyRequest: Immutable.fromJS(res.body),
                });
            }).catch((error) => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.FIND_BY_ID.ERROR,
                    error
                });

                NotificationActions.showAlertDanger('privacy.find_by_id.error');
                throw error;
            });

    }

    get(queryParams) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.GET.START,
        });

        queryParams = queryParams.toJS();
        queryParams.offset = queryParams.offset || 0;
        queryParams.size = queryParams.size || 20;

        Request.get('integration/ccpa')
            .query(queryParams)
            .exec()
            .then(res => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET.SUCCESS,
                    privacyRequests: Immutable.fromJS(res.body.results),
                    offset: res.body.offset,
                    size: res.body.size,
                    total: res.body.totalCount,
                });
            }).catch((error) => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET.ERROR,
                    error
                });

                NotificationActions.showAlertDanger('privacy.get.error');
                throw error;
            });
    }

    update(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.UPDATE,
            attr: attr,
            value: value
        });

        return;
    }
}

const actions = new PrivacyActions();

export {
    actions as PrivacyActions,
    CONSTANTS as PrivacyConstants
};
