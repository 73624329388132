/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Modal, Button} from 'react-bootstrap';

import {NotificationActions} from './notification-actions';
import NotificationStore from './notification-store';

const MODAL_TYPE = {
    DEFAULT: 0,
    APPROVE: 1,
    DANGER:2,
    NON_CANCELABLE:3,
    DENY_NON_CANCELABLE:4,
    WARNING: 5
};

class BrainiacModal extends React.Component {
    static get propTypes() {
        return {
            viewingMode: PropTypes.string.isRequired
        };
    }

    static calculateState() {
        return {
            queue: NotificationStore.getState().get('notifications')
        };
    }

    static getStores() {
        return [NotificationStore];
    }

    constructor(props) {
        super(props);

        this.onHide = this.onHide.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
        this.getModal = this.getModal.bind(this);
    }

    onHide(event) {
        if (event) {
            event.preventDefault();
        }
        let modal = this.getModal();
        if (modal.get('type') === MODAL_TYPE.NON_CANCELABLE || modal.get('type') === MODAL_TYPE.DENY_NON_CANCELABLE) {
            return;
        }
        let cb = modal.get('onCancel');
        NotificationActions.hide();
        if (cb) {
            cb();
        }
    }

    onConfirm(event) {
        if (event) {
            event.preventDefault();
        }
        let modal=this.getModal();
        let cb = modal.get('onConfirm');
        NotificationActions.hide();
        if (cb) {
            cb();
        }
    }

    getModal() {
        let modal = Immutable.Map({
            type:null,
            title:null,
            message: null,
            confirmText: null,
            show: false,
            onConfirm: null,
            onCancel: null
        });
        let queue = this.state.queue;
        if (queue.size > 0) {
            modal = queue.first();
        }
        return modal;
    }

    render() {
        let headerClass = 'bg-gray';
        let confirmClass = 'primary bg-wb-blue';
        let closeButton = true;
        let modal=this.getModal();
        switch (modal.get('type')) {
        case MODAL_TYPE.APPROVE:
            headerClass = 'bg-green';
            confirmClass = 'success';
            break;
        case MODAL_TYPE.DANGER:
            headerClass = 'bg-red';
            confirmClass = 'danger';
            break;
        case MODAL_TYPE.WARNING:
            headerClass = 'bg-yellow';
            confirmClass = 'warning';
            break;
        case MODAL_TYPE.DENY_NON_CANCELABLE:
            headerClass = 'bg-red';
            confirmClass = 'danger';
            closeButton = false;
            break;
        case MODAL_TYPE.NON_CANCELABLE:
            closeButton = false;
            break;
        }

        let closeBtn = '';
        if (closeButton) {
            closeBtn = <Button className="pull-left Ml(5)" onClick={this.onHide}>Cancel</Button>;
        }

        return (
            <Modal show={modal.get('show')} onHide={this.onHide} className={ClassNames({'skin-dark': this.props.viewingMode === 'skin-dark'})}>
                <Modal.Header className={headerClass} closeButton={closeButton}>
                    <Modal.Title className="text-center" >{modal.get('title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modal.get('message')}
                </Modal.Body>
                <Modal.Footer>
                    {closeBtn}
                    <Button bsStyle={confirmClass} onClick={this.onConfirm}>{modal.get('confirmText')}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const modal = Container.create(BrainiacModal);

export {
    MODAL_TYPE,
    modal as Modal
};
