/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {Validation} from './alerts-warnings';
import {SEVERITY, ERRORTYPE} from './validations';
import Panel from '../panel/panel';

// This Errors / Warnings component is the latest design used in Events and elsewhere
class ErrorsWarnings extends Component {
    static get propTypes() {
        return {
            addRequiredValidation: PropTypes.bool,
            children: PropTypes.node,
            defaultExpanded: PropTypes.bool,
            hasAnimation: PropTypes.bool,
            iconValid: PropTypes.string,
            stores: PropTypes.array,
            title: PropTypes.string,
            useH4: PropTypes.bool,
            validations: PropTypes.oneOfType([
                PropTypes.array,
                PropTypes.object
            ]),
            withBorder: PropTypes.bool
        };
    }

    static get defaultProps() {
        return {
            addRequiredValidation: true,
            children: undefined,
            defaultExpanded: true,
            hasAnimation: false,
            iconValid: 'fas fa-flag',
            stores: undefined,
            title: '',
            useH4: true,
            validations: [],
            withBorder: true,
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            validations: this.readValidations()
        };

        this.readValidations = this.readValidations.bind(this);
    }

    componentWillMount() {
        // FIXME: this should be removed when all validations come from this.props.stores
        if (!this.props.stores) {
            return;
        }

        this.listeners = this.props.stores.map(store => {
            return store.addListener(() => {
                let validations = this.readValidations();
                this.setState({
                    validations: validations
                });
            });
        });
    }

    componentWillUnmount() {
        // FIXME: this should be removed when all validations come from this.props.stores
        if (!this.listeners) {
            return;
        }

        this.listeners.forEach(listener => {
            listener.remove();
            return;
        });
    }

    readValidations() {
        // FIXME: this should be removed when all validations come from this.props.stores
        if (!this.props.stores) {
            return;
        }

        let validations = [];
        this.props.stores.forEach(store => {
            validations.push(...store.getValidations());
        });
        return validations;
    }

    render() {
        let validations = this.props.validations;

        if (this.props.stores) {
            validations = this.state.validations;
        }

        let extraClasses = ['MB10P'];
        if (!this.props.withBorder) {
            extraClasses.push('no-border');
            extraClasses.push('no-shadow');
        }
        if (this.props.hasAnimation) {
            extraClasses.push('hardac-error-container');
        }

        const vr = validations.reduce((r, v) => {
            if (v.type !== 'required') {
                r.push(v);
                return r;
            }

            // If v is of type required, then check if any required validation is already added
            // to r.
            // If any, return r.
            if (v.type === 'required' && r.some(vv => vv.type === 'required')) {
                return r;
            }
            // If not, add the required validation.
            if (this.props.addRequiredValidation) {
                r.push({
                    message: <span><b>One or more required fields missing</b> - Please complete all fields marked with *</span>,
                    severity: SEVERITY.ALERT,
                    type: 'required'
                });
            }

            return r;
        }, []);

        const errors = vr.filter(c => c.errorType === ERRORTYPE.ERROR);
        const warnings = vr.filter(c => c.errorType === ERRORTYPE.WARNING);
        const information = vr.filter(c => c.errorType === ERRORTYPE.INFO);

        let errorsSection;
        if (errors.length) {
            errorsSection = <p>
                <label>Errors</label>
                {errors.map((v, i) => <Validation key={i} validation={v} icon={v.icon} iconValid={this.props.iconValid}/>)}
            </p>;
        }

        let warningsSection;
        if (warnings.length) {
            warningsSection = <p>
                <label>Warnings</label>
                {warnings.map((v, i) => <Validation key={i} validation={v} icon={v.icon} iconValid={this.props.iconValid}/>)}
            </p>;
        }

        let infoSection;
        if (information.length) {
            infoSection = <p>
                <label>Info</label>
                {information.map((v, i) => <Validation key={i} validation={v} icon={v.icon} iconValid={this.props.iconValid}/>)}
            </p>;
        }

        return (
            <Panel
                collapsible
                defaultExpanded={this.props.defaultExpanded}
                extraClasses={extraClasses}
                withBorder={this.props.withBorder}
                iconClass="far fa-flag"
                hasAnimation={this.props.hasAnimation}
                title={this.props.title}
                useH4={this.props.useH4}
                additionalStyle="padding-x-20">
                {errorsSection}
                {warningsSection}
                {infoSection}
                {this.props.children}
            </Panel>
        );
    }
}

export {
    ErrorsWarnings,
};
