/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

import Summary from './summary/summary';
import Titles from './titles/titles';
import {AssetTabActions} from '../../assets-tab/asset-tab-actions';
import AssetTabStore from '../../assets-tab/asset-tab-store';
import Assets from '../../assets-tab/assets';
import RouteValidator from '../../common/custom-validator/route/route';
import DocumentTitle from '../../common/document-title';
import {MODAL_TYPE} from '../../common/notification/modal';
import {NotificationActions} from '../../common/notification/notification-actions';
import Panel from '../../common/panel/panel';
import {Tab, Tabs} from '../../common/routing-tab/routing-tab';
import {History, SideNotes} from '../../common/side-notes/side-notes';
import {AlertsWarnings} from '../../common/validations/alerts-warnings';
import ActionsMenu from '../../layout/actions-menu/actions-menu';
import StickyHeader from '../../layout/sticky-header/sticky-header';
import Preloader from '../../preloader';
import {RouterActions} from '../../router/router-actions';
import {ActionHistoryActions, ActionHistoryConstants} from '../../system/action-history/action-history-actions';
import SessionStore from '../../user/session/session-store';
import {TalentActions} from '../talent-actions';
import TalentStore from '../talent-store';

class Create extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            params: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired,
            route: RouteValidator // eslint-disable-line react/require-default-props
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            talent: TalentStore.getState().get('talent'),
            originalTalent: TalentStore.getState().get('originalTalent'),
            showPreloader: TalentStore.getState().get('showPreloader')
        };
    }

    static getStores() {
        return [TalentStore];
    }

    static getPermissions() {
        return {
            assets: {
                canBrowse: SessionStore.canUser(SessionStore.PERMISSIONS.TALENT.ASSETS.BROWSE),
                canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.TALENT.ASSETS.EDIT),
            },
            canCreate: SessionStore.canUser(SessionStore.PERMISSIONS.TALENT.CREATE),
            canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.TALENT.EDIT),
            titles: {
                canBrowse: SessionStore.canUser(SessionStore.PERMISSIONS.TALENT.TITLES.BROWSE),
                canRead: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.READ),
            }
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            activeTab: 0,
        }, this.constructor.calculateState());

        this.addNote = this.addNote.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleSaveAndCreate = this.handleSaveAndCreate.bind(this);
        this.isDirty = this.isDirty.bind(this);
        this.showDeleteDialog = this.showDeleteDialog.bind(this);
    }

    componentDidMount() {
        this.init(this.props);
        RouterActions.registerRedirectCheck((location) => {
            return this.isDirty(location);
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.params.id !== this.props.params.id) {
            this.init(nextProps);
        }
        RouterActions.registerRedirectCheck((location) => {
            return this.isDirty(location);
        });
    }

    componentWillUnmount() {
        RouterActions.clearRedirectChecks();
    }

    addNote(description) {
        ActionHistoryActions.addNote(this.props.params.id, description, ActionHistoryConstants.ACTION_OBJECTS.TALENT);
    }

    handleCancel() {
        RouterActions.redirect('/talent', true);
    }

    handleDelete() {
        TalentActions.remove(this.props.params.id);
        return;
    }

    handleSave() {
        if (this.props.params.id) {
            let assets = false;
            let original = AssetTabStore.getState().get('originalAssets');
            let current = AssetTabStore.getState().get('assets');
            if (original !== current) {
                assets = current;
            }
            TalentActions.save(
                TalentStore.getState().get('talent'),
                TalentStore.getState().get('originalAKAS'),
                TalentStore.getState().get('removedAKAS'),
                assets, AssetTabStore.getState().get('activatedAssets'), AssetTabStore.getState().get('deactivatedAssets')
            );
        } else {
            TalentActions.create(TalentStore.getState().get('talent'), false);
        }
    }

    handleSaveAndCreate() {
        TalentActions.create(TalentStore.getState().get('talent'), true);
    }

    init(props) {
        if (props.route.mode === 'edit') {
            TalentActions.findById(props.params.id);
            // Assets tabs
            if (props.permissions.assets.canBrowse) {
                AssetTabActions.get(props.params.id, 'talent');
            } else {
                AssetTabActions.clear();
            }
        } else {
            TalentActions.clear();
        }
    }

    isDirty(location) {
        if (this.props.route.mode === 'create') {
            return false;
        }
        const goingTo = location.pathname.split('/');
        const current = this.props.location.pathname.split('/');
        if (goingTo[1] === current[1] && /* talent */
            goingTo[2] === current[2] /* id */ ) {
            return false;
        }
        let talent = this.state.talent.delete('history').delete('akas');
        let originalTalent = this.state.originalTalent.delete('history').delete('akas');
        return !talent.equals(originalTalent) ||
        !TalentStore.getState().getIn(['talent', 'akas']).equals(TalentStore.getState().get('originalAKAS')) ||
        !AssetTabStore.getState().get('assets').equals(AssetTabStore.getState().get('originalAssets'));
    }

    showDeleteDialog() {
        NotificationActions.show(
            MODAL_TYPE.DANGER,
            this.context.intl.messages['talent.edit.confirmDelete.title'],
            this.context.intl.messages['talent.edit.confirmDelete.body'],
            this.context.intl.messages['talent.edit.confirmDelete.confirm'],
            this.handleDelete
        );
    }

    render() {
        let actionsMenu;
        let eventsAndNotes = null;
        let mode = this.props.route.mode;
        let picture = null;
        let subtitle = null;

        let validations = TalentStore.getValidations();

        // Tabs
        let assetsTab = null;
        let historyTab = null;
        let summaryRoute = '/talent/create';
        if (mode !== 'create') {
            summaryRoute = `/talent/${this.props.params.id}/summary`;
        }
        let summaryTab = <Tab route={summaryRoute} title={this.context.intl.messages['talent.create.summary-tab-title']}>
            <Summary {...this.props} talent={this.state.talent || ''} />
        </Tab>;
        let titlesTab = null;

        if (mode === 'create') {
            let className = 'btn btn-primary-outline Mr(3px) Mb(3px)';
            if (validations.length) {
                className = 'btn btn-default-outline Mr(3px) Mb(3px)';
            }
            actionsMenu = <ActionsMenu
                canSave={!validations.length}
                onCancel={this.handleCancel}
                onSave={this.handleSave}
                saveText={this.context.intl.messages['talent.create.save-button']}
            >
                <Button
                    bsSize="large"
                    className={className}
                    disabled={!!validations.length}
                    onClick={this.handleSaveAndCreate}
                >
                    <i className="fas fa-clone"/>&nbsp;{this.context.intl.messages['talent.create.save-and-create']}
                </Button>
            </ActionsMenu>;

            subtitle = (<p className="padding-top-10"><em> {this.context.intl.messages['talent.create.subtitle']} </em></p>);
        } else {
            actionsMenu = <ActionsMenu
                canEdit={this.props.permissions.canEdit}
                canDelete={this.props.permissions.canEdit}
                canSave={!validations.length && this.props.permissions.canEdit}
                onCancel={this.handleCancel}
                onDelete={this.showDeleteDialog}
                onSave={this.handleSave}
            />;

            let footer = <div className="text-center">
                <i className="text-center">{this.context.intl.messages['talent.edit.picture.subtitle']}</i>
            </div>;
            picture = <Panel iconClass="far fa-image" title={this.context.intl.messages['talent.edit.default-photo-title']} collapsible defaultExpanded footer={footer}>
                <div className="image-wrapper">
                    <img className="responsive-image" src={this.state.talent.get('defaultImagePortraitPreviewUrl')}/>
                </div>
            </Panel>;

            eventsAndNotes = (
                <SideNotes
                    basePath={`/talent/${this.props.params.id}`}
                    dialogMessage={this.context.intl.messages['common.side-notes.add-note.body']}
                    disabled={ !this.props.permissions.canEdit }
                    notes={this.state.talent.get('history').toJS().slice(0, 4)}
                    onAddNote={this.addNote}
                    title={this.context.intl.messages['common.side-notes.title']}
                />
            );

            // Tabs
            if (this.props.permissions.assets.canBrowse) {
                assetsTab = <Tab
                    route={`/talent/${this.props.params.id}/assets`}
                    title={this.context.intl.messages['talent.edit.assets-tab-title']}
                >
                    <Assets
                        caller="talent"
                        disabled={!this.props.permissions.assets.canEdit}
                        disableStacking={true}
                        id={this.props.params.id}
                        location={this.props.location}
                        title={`${this.state.talent.get('firstName')} ${this.state.talent.get('lastName')}`}
                    />
                </Tab>;
            }

            historyTab = <Tab route={`/talent/${this.props.params.id}/history`} tabClassName="pull-right" title={this.context.intl.messages['common.tab-title.history']}>
                <History
                    actionObject={ActionHistoryConstants.ACTION_OBJECTS.TALENT}
                    id={this.props.params.id}
                    pagination
                    pathname={this.props.location.pathname}
                    query={this.props.location.query}/>
            </Tab>;
            titlesTab = <Tab
                route={`/talent/${this.props.params.id}/titles`}
                title={this.context.intl.messages['talent.edit.titles-tab-title']}
            >
                <Titles
                    displayLinks={this.props.permissions.titles.canRead}
                    titles={this.state.talent.get('titles')}
                />
            </Tab>;
        }

        let entityName;

        if (this.state.talent.get('fullName')) {
            entityName = this.state.talent.get('fullName');
        }

        return (
            <DocumentTitle
                message={`document-titles.${mode}-talent`} entityName={entityName}
            >
                <div>
                    <StickyHeader>
                        <div className="col-md-6">
                            <h1>
                                <i className="fas fa-star"></i>&nbsp;{this.context.intl.messages[`talent.${mode}.title`]}&nbsp;
                                <small>{this.state.talent.get('firstName')} {this.state.talent.get('lastName')}</small>
                            </h1>
                            {subtitle}
                        </div>
                        {actionsMenu}
                    </StickyHeader>
                    <Preloader show={this.state.showPreloader} fixed loadingDots>
                        <section className="content" id="contentContainer">
                            <div className="row">
                                <div className="col-lg-9">
                                    <Tabs location={this.props.location}>
                                        {summaryTab}
                                        {assetsTab}
                                        {titlesTab}
                                        {historyTab}
                                    </Tabs>
                                </div>
                                <div className="col-lg-3">
                                    {picture}
                                    <AlertsWarnings
                                        title={this.context.intl.messages['common.alerts-warnings-title']}
                                        validations={validations}
                                    />
                                    {eventsAndNotes}
                                </div>
                            </div>
                        </section>
                    </Preloader>
                </div>
            </DocumentTitle>
        );
    }

}

export default Container.create(Create);
