/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Modal, Collapse} from 'react-bootstrap';

import {FormItem, FormRow} from '../../../../common/form/form';
import {AlertsWarnings} from '../../../../common/validations/alerts-warnings';
import Validations from '../../../../common/validations/validations';

class AdditionalRequirement extends React.Component {
    static get propTypes() {
        return {
            icon: PropTypes.string,
            text: PropTypes.string.isRequired,
        };
    }

    static get defaultProps() {
        return {
            icon: 'fa-ban',
        };
    }

    render() {
        return (
            <div>
                <small>
                    <i className={ClassNames('far', this.props.icon)}/>
                    &nbsp;{this.props.text}
                </small>
            </div>
        );
    }
}

class SetPasswordModal extends React.Component {
    static get propTypes() {
        return {
            onHide: PropTypes.func.isRequired,
            onSetPassword: PropTypes.func.isRequired,
            show: PropTypes.bool.isRequired,
            user: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            model: Immutable.fromJS({
                confirmPassword: '',
                password: ''
            }),
            showAdditionalRequirements: false
        };

        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleSetPassword = this.handleSetPassword.bind(this);
        this.handleToggleAdditionalRequirements = this.handleToggleAdditionalRequirements.bind(this);
        this.handleHide = this.handleHide.bind(this);
        this.isValid = this.isValid.bind(this);
    }

    handlePasswordChange(attr, newPassword) {
        this.setState(prevState => {
            return {model: prevState.model.setIn([attr], newPassword)};
        });
    }

    handleSetPassword() {
        this.props.onSetPassword(this.state.model.get('password'));
        this.handleHide();
    }

    handleHide() {
        this.props.onHide();

        setTimeout(() => {
            this.setState({
                model: Immutable.fromJS({
                    confirmPassword: '',
                    password: ''
                }),
                showAdditionalRequirements: false
            });
        }, 1000);
    }

    handleToggleAdditionalRequirements() {
        this.setState(prevState => ({
            showAdditionalRequirements: !prevState.showAdditionalRequirements
        }));
    }

    isValid() {
        const password = this.state.model.get('password');
        const confirmPassword = this.state.model.get('confirmPassword');

        return Validations.required.validate(password)
            && Validations.passwordHasUppercase.validate(password)
            && Validations.passwordHasLowercase.validate(password)
            && Validations.passwordHasNumberAndSymbol.validate(password)
            && Validations.passwordLength.validate(password)
            && Validations.passwordDoesNotContain([this.props.user.get('name'), this.props.user.get('lastName'), this.props.user.get('email', '').split('@')[0]]).validate(password)
            && Validations.passwordNotCommon.validate(password)
            && Validations.stringMatch('', () => password).validate(confirmPassword);
    }

    render() {
        const password = this.state.model.get('password');

        // Important! The order of password first and confirmPassword second
        // matters. It will display password messages first and the
        // "passwords must match" message last. This is by UX rule.
        const passwordValidations = {
            password: {
                label: 'accounts.create.management.password-create.new-password',
                validations: [
                    Validations.required,
                    Validations.passwordHasUppercase,
                    Validations.passwordHasLowercase,
                    Validations.passwordHasNumberAndSymbol,
                    Validations.passwordLength,
                    Validations.passwordDoesNotContain([this.props.user.get('name'), this.props.user.get('lastName'), this.props.user.get('email', '').split('@')[0]]),
                    Validations.passwordNotCommon
                ]
            },
            confirmPassword: {
                label: 'accounts.create.management.password-create.confirm-password',
                validations: [Validations.required, Validations.stringMatch(
                    () => 'Match the confirm password field',
                    () => this.state.model.get('password'),
                    true
                )]
            }
        };

        let validations = Validations.validate(this.state.model, passwordValidations, true);

        let additionalRequirementsIcon = <i className={ClassNames('fa', 'fa-caret-down')}/>;

        if (this.state.showAdditionalRequirements) {
            additionalRequirementsIcon = <i className={ClassNames('fa', 'fa-caret-up')}/>;
        }

        const additionalRequirements = <div className="padding-top-10 padding-left-20">
            <a
                role="button"
                // className="alert-info"
                onClick={this.handleToggleAdditionalRequirements}
                aria-controls="additional-password-requirements-text"
                aria-expanded={this.state.showAdditionalRequirements}>
                {this.context.intl.messages['accounts.create.management.password-create.additional-requirements']}&nbsp;{additionalRequirementsIcon}
            </a>

            <Collapse in={this.state.showAdditionalRequirements}>
                <div className="padding-left-10" id="additional-password-requirements-text">
                    <div className="padding-y-5 small">{this.context.intl.messages['accounts.create.management.password-create.additional-requirements.protect-privacy']}</div>
                    <AdditionalRequirement icon="fa-ban" text={this.context.intl.messages['accounts.create.management.password-create.additional-requirements.not-repeated']}/>
                </div>
            </Collapse>
        </div>;

        return (
            <Modal backdrop="static" onHide={this.handleHide} show={this.props.show}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="text-center">
                        {this.context.intl.messages['accounts.create.management.password-create.title']}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <AlertsWarnings
                        title={this.context.intl.messages['accounts.create.management.password-create.validation-check.title']}
                        type="info"
                        addRequiredValidation={false}
                        validations={validations}
                        icon="far fa-ban"
                        iconValid="far fa-check"
                        withBorder={false}>
                        {additionalRequirements}
                    </AlertsWarnings>
                    <p>
                        <strong>{this.context.intl.messages['common.note']}:</strong>&nbsp;
                        {this.context.intl.messages['accounts.create.management.password-create.note']}
                    </p>
                    <FormRow>
                        <FormItem
                            attr={'password'}
                            label={this.context.intl.messages['accounts.create.management.password-create.new-password']}
                            model={this.state.model}
                            setter={this.handlePasswordChange}
                            type="password"
                            showErrorMessage={true}
                            validations={[
                                Validations.required,
                                Validations.passwordHasUppercase,
                                Validations.passwordHasLowercase,
                                Validations.passwordHasNumberAndSymbol,
                                Validations.passwordLength,
                                Validations.passwordDoesNotContain([this.props.user.get('name'), this.props.user.get('lastName'), this.props.user.get('email', '').split('@')[0]]),
                                Validations.passwordNotCommon
                            ]}
                        />
                    </FormRow>
                    <FormRow>
                        <FormItem
                            attr={'confirmPassword'}
                            label={this.context.intl.messages['accounts.create.management.password-create.re-enter.new-password']}
                            model={this.state.model}
                            setter={this.handlePasswordChange}
                            type="password"
                            showErrorMessage={true}
                            validations={[
                                Validations.required,
                                Validations.stringMatch(
                                    () => 'Passwords do not match.',
                                    () => password
                                )
                            ]}
                        />
                    </FormRow>
                </Modal.Body>

                <Modal.Footer>
                    <Button className="pull-left Ml(5)" onClick={this.handleHide}>
                        {this.context.intl.messages['common.cancel']}
                    </Button>
                    <Button bsStyle="primary" type="submit"
                        disabled={!this.isValid()}
                        onClick={this.handleSetPassword}>
                        {this.context.intl.messages['accounts.create.management.password-create.set-password']}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default SetPasswordModal;
