/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {WorkOrdersActions, WorkOrdersConstants} from './work-orders-actions';
import WorkOrdersList from './work-orders-list';
import WorkOrdersStore from './work-orders-store';
import DocumentTitle from '../../common/document-title';
import SearchBox from '../../common/search-box/search-box';
import {WithStoreOnRoute} from '../../common/store-on-route';
import {Debounce} from '../../common/utils/utils';
import Preloader from '../../preloader';
import PreloaderStore from '../../preloader/preloader-store';
import SessionStore from '../../user/session/session-store';

class Browse extends Component {

    static get propTypes() {
        return {
            permissions: PropTypes.object.isRequired,
            location: PropTypes.object.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible'),
            workOrders: WorkOrdersStore.getState().get('workOrders'),
            total: WorkOrdersStore.getState().get('total'),
        };
    }

    static getStores() {
        return [PreloaderStore, WorkOrdersStore];
    }

    static getPermissions() {
        return {
            canReadWorkOrders: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.WORK_ORDERS.READ)
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
        this.handleSort = this.handleSort.bind(this);
    }

    componentDidMount() {
        WorkOrdersActions.get(this.getRouteState());
    }

    componentWillUpdate(nextProps) {
        if (this.props.location !== nextProps.location) {
            WorkOrdersActions.get(this.getRouteState(nextProps));
        }
        return;
    }

    handleLoadPage(pageNumber) {
        this.setRouteState('offset', pageNumber * WorkOrdersConstants.PAGE_SIZE)
            .apply();
        return;
    }

    handleSearchTerm(term) {
        this.setRouteState('q', term)
            .apply(); // do not remove other filters
        return;
    }

    handleSort(column) {
        let newSortDir = 'asc';
        if (this.getRouteState().get('sort-field-name', 'name') === column && this.getRouteState().get('sort-direction', 'asc') === 'asc') {
            newSortDir = 'desc';
        }

        this.setRouteState('sort-field-name', column)
            .setRouteState('sort-direction', newSortDir)
            .apply();
    }

    render() {
        return (
            <DocumentTitle
                message="document-titles.browse-work-orders"
            >
                <Preloader show={this.state.preloaderVisible} fixed loadingDots>
                    <section className="content-header">
                        <h1>
                            <i className="far fa-newspaper"></i>&nbsp;Browse Work Orders&nbsp;
                        </h1>
                    </section>
                    <section className="content">
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="container-fluid no-x-padding">
                                    <div className="row">
                                        <div className="col-md-6 show-grid">
                                            <SearchBox onChange={this.handleSearchTerm} value={this.getRouteState().get('q')}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="container-fluid no-x-padding">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="box">
                                                <div className="box-body padding-x-20 padding-y-20">
                                                    <WorkOrdersList activePage={Math.ceil((this.getRouteState().get('offset') || 0) / WorkOrdersConstants.PAGE_SIZE) || 0}
                                                        displayLinks={this.props.permissions.canReadWorkOrders}
                                                        onPageChange={this.handleLoadPage}
                                                        onSort={this.handleSort}
                                                        sortDirection={this.getRouteState().get('sort-direction') || 'asc'}
                                                        sortFieldName={this.getRouteState().get('sort-field-name') || 'name'}
                                                        totalPages={Math.ceil(this.state.total / WorkOrdersConstants.PAGE_SIZE) || 0}
                                                        workOrders={this.state.workOrders} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Preloader>
            </DocumentTitle>
        );
    }

}

export default WithStoreOnRoute(Container.create(Browse));
