/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {EditDomainConstants} from './edit-domain-actions';
import Validations from '../../common/validations/validations';
import Dispatcher from '../../dispatcher/dispatcher';
// import {PermissionPackageConstants} from '../../security/permission-package/permission-package-actions';


const EditDomainStoreValidations = {
    domainName: {label: 'emails.whitelist.edit.domain-name', validations: [Validations.required, Validations.max(50)]},
    permissionPackageId: {label: 'emails.whitelist.edit.permissions-package', validations: [Validations.required]},
    note: {label: 'emails.whitelist.edit.form.notes', validations: [Validations.required]}
};

class EditDomainStore extends ReduceStore {

    getInitialState() {
        return Immutable.Map({
            domain: Immutable.Map(),
            originalDomain: Immutable.Map(),
            notFound: false,
            networkError: false
        });
    }

    getValidations() {
        return Validations.validate(this.getState().get('domain'), EditDomainStoreValidations);
    }

    reduce(state, action) {
        switch (action.actionType) {
        case EditDomainConstants.ERROR:
            state = state.set('networkError', true);
            break;

        case EditDomainConstants.GET:
        case EditDomainConstants.CLEAR:
            state = this.getInitialState();
            break;

        // Store new data from the server.
        case EditDomainConstants.LOAD:
            let domain = action.domain;
            domain = Immutable.fromJS(domain);
            state = state.set('domain', domain);
            state = state.set('originalDomain', domain);
            break;

        case EditDomainConstants.SAVE:
            state = state.set('originalDomain', state.get('domain'));
            break;

        case EditDomainConstants.UPDATE:
            state = state.setIn(['domain', ...action.attr.split('.')], action.value);
            break;


        default:
            break;
        }

        return state;
    }
}

let as = new EditDomainStore(Dispatcher);

export default as;

export {EditDomainStoreValidations};
