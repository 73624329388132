/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ControlLabel, FormGroup} from 'react-bootstrap';

import Info from './info';
import {FormRow, FormSection} from '../../../common/form/form';
import {GroupActions, GroupConstants} from '../../../security/group/group-actions';
import GroupSelect from '../../../security/group/group-select';


/**
 * Summary Tab and its content.
 */
class Summary extends Component {
    static get propTypes() {
        return {
            mode: PropTypes.string.isRequired,
            disabled: PropTypes.bool,
            stationGroup: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true,
            permissions: {}
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    handleSelectAssetGroup(groups) {
        if (!groups) {
            // Reset the value.
            GroupActions.updateGroup(GroupConstants.STATION, 'assetGroups', Immutable.OrderedSet());
            return;
        }

        // Call the select action but with immutable objects.
        GroupActions.updateGroup(GroupConstants.STATION, 'assetGroups', Immutable.fromJS(groups).toSet().sortBy(g => g.get('name')));
        return;
    }

    handleSelectTitleGroup(groups) {
        if (!groups) {
            // Reset the value.
            GroupActions.updateGroup(GroupConstants.STATION, 'titleGroups', Immutable.OrderedSet());
            return;
        }

        // Call the select action but with immutable objects.
        GroupActions.updateGroup(GroupConstants.STATION, 'titleGroups', Immutable.fromJS(groups).toSet().sortBy(g => g.get('name')));
        return;
    }

    render() {
        return (
            <div className="tab-pane padding-x-20 padding-bottom-20">
                <Info
                    mode={this.props.mode}
                    disabled={this.props.disabled}
                    stationGroup={this.props.stationGroup}
                />
                <FormSection title={this.context.intl.messages['stations.create.title-catalogs.title']} iconClass="fas fa-book">
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['stations.create.title-catalogs.choose']}</ControlLabel>
                            <GroupSelect
                                disabled={this.props.disabled}
                                onChange={this.handleSelectTitleGroup}
                                selected={this.props.stationGroup.get('titleGroups')}
                                type={GroupConstants.TITLE}
                            />
                        </FormGroup>
                    </FormRow>
                </FormSection>
                <FormSection title={this.context.intl.messages['stations.create.asset-catalogs.title']} iconClass="far fa-file-video">
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['stations.create.asset-catalogs.choose']}</ControlLabel>
                            <GroupSelect
                                disabled={this.props.disabled}
                                onChange={this.handleSelectAssetGroup}
                                selected={this.props.stationGroup.get('assetGroups')}
                                type={GroupConstants.ASSET}
                            />
                        </FormGroup>
                    </FormRow>
                </FormSection>
            </div>
        );
    }
}

export default Summary;
