/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Modal} from 'react-bootstrap';

import {AssetActions, AssetConstants} from '../../assets/asset-actions';
import AssetStore from '../../assets/asset-store';
import ListAssets from '../../assets/browse/list-assets';
import FilterOptions from '../../assets/filter-options/filter-options';
import DocumentTitle from '../../common/document-title';
import SearchBox from '../../common/search-box/search-box';
import {WithStoreOnRoute} from '../../common/store-on-route';
import {Debounce} from '../../common/utils/utils';
import {LayoutActions} from '../../layout/layout-actions';
import LayoutStore from '../../layout/layout-store';
import Preloader from '../../preloader';
import PreloaderStore from '../../preloader/preloader-store';
import SessionStore from '../../user/session/session-store';
import {TimelineActions} from '../timeline/timeline-actions';
import TimelineStore from '../timeline/timeline-store';

import {FormSection} from '~/src/common/form/form';

class BrowseTimelines extends React.Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static getStores() {
        return [AssetStore, LayoutStore, PreloaderStore, TimelineStore];
    }

    static calculateState() {
        return {
            assets: AssetStore.getState().get('assets'),
            filters: AssetStore.getState().get('filters'),
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible'),
            showPreloader: TimelineStore.getState().get('showPreloader'),
            showFiltersPanel: LayoutStore.getState().get('showFiltersPanel'),
            total: AssetStore.getState().get('total'),
            viewingMode: LayoutStore.getState().get('hardacAppTheme')
        };
    }

    static getPermissions() {
        return {
            canReadVideoTimelineAssets: SessionStore.canUser(SessionStore.PERMISSIONS.HARDAC.TIMELINE.READ)
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            selectedIds: [],
            showExportAvidModal: false,
            showExportTimelinesModal: false,
            selectAll: false
        }, this.constructor.calculateState());

        this.clearSelectedTimelines = this.clearSelectedTimelines.bind(this);
        this.handleExportAvid = this.handleExportAvid.bind(this);
        this.handleExportTimeline = this.handleExportTimeline.bind(this);
        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
        this.handleToggleExportAvidModal = this.handleToggleExportAvidModal.bind(this);
        this.handleToggleExportTimelineModal = this.handleToggleExportTimelineModal.bind(this);
        this.handleToggleFiltersPanel = this.handleToggleFiltersPanel.bind(this);
        this.handleToggleSelectAll = this.handleToggleSelectAll.bind(this);
        this.handleToggleSelect = this.handleToggleSelect.bind(this);

        return;
    }

    componentDidMount() {
        AssetActions.clear();
        const q = this.props.location.query;
        if (q['active-type'] && q['asset-type']) {
            AssetActions.get(this.getRouteState());
            return;
        }
        this.setRouteState('active-type', 'ACTIVE')
            .setRouteState('asset-type', AssetConstants.ASSET_TYPES.VIDEO_TIMELINE.id)
            .setRouteState('operator', 'AND')
            .apply();
        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.location.query !== this.props.location.query ||
            nextState.showFiltersPanel !== this.state.showFiltersPanel ||
            nextState.total !== this.state.total ||
            nextState.assets !== this.state.assets ||
            nextState.preloaderVisible !== this.state.preloaderVisible ||
            nextState.showPreloader !== this.state.showPreloader) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            AssetActions.get(this.getRouteState(this.props));
        }
        return;
    }

    clearSelectedTimelines() {
        this.setState(() => ({
            selectedIds: [],
            selectAll: false,
        }));
    }

    handleExportAvid() {
        this.handleToggleExportAvidModal();
        TimelineActions.bulkExportAvid(this.state.selectedIds);
        this.clearSelectedTimelines();
    }

    handleExportTimeline() {
        this.handleToggleExportTimelineModal();
        TimelineActions.bulkExportTimelines(this.state.selectedIds);
        this.clearSelectedTimelines();
    }

    /**
     * Handle clicks on pagination controls.
     * @param  {Number} pageNumber page to load.
     */
    handleLoadPage(pageNumber) {
        // pageNumber x this.state.size = offset.
        this.setRouteState('offset', pageNumber * 20)
            .apply();
        return;
    }

    handleSortChange(sortFieldName, sortDirection) {
        this.setRouteState('sort-field-name', sortFieldName)
            .setRouteState('sort-direction', sortDirection)
            .apply();
        return;
    }

    handleSearchTerm(term) {
        this.setRouteState('asset-name', term)
            .setRouteState('operator', 'AND')
            .clearRouteState('offset')
            .apply();
        return;
    }

    handleToggleExportAvidModal() {
        this.setState((prevState) => ({
            showExportAvidModal: !prevState.showExportAvidModal
        }));
    }

    handleToggleExportTimelineModal() {
        this.setState((prevState) => ({
            showExportTimelinesModal: !prevState.showExportTimelinesModal
        }));
    }

    handleToggleFiltersPanel() {
        LayoutActions.toggleFiltersPanel();
        return;
    }

    handleToggleSelect(index, value) {
        if (value) {
            this.setState(prevState => ({
                selectedIds: [...prevState.selectedIds, prevState.assets.getIn([index, 'id'])],
                selectAll: (prevState.assets.size - prevState.selectedIds.length) < 2
            }));
        } else {
            this.setState(prevState => ({
                selectedIds: prevState.selectedIds.filter(id => id !== prevState.assets.getIn([index, 'id'])),
                selectAll: false,
            }));
        }
    }

    handleToggleSelectAll(value) {
        if (value) {
            this.setState(prevState => ({
                selectedIds: prevState.assets.toJS().map(asset => asset.id),
                selectAll: true
            }));
        } else {
            this.setState(()=>({
                selectedIds: [],
                selectAll: false
            }));
        }
    }

    render() {
        const columnDefs = [
            {
                className: 'control',
                targets: -1,
                width: 20
            }, {
                className: 'actions',
                targets: 'actions'
            }, {
                targets: 'no-sort',
                orderable: false
            }, {
                targets: [0], // checkbox
                className: 'text-center'
            }, {
                targets: [1], // assetThumbnail
                className: 'text-center'
            }, {
                targets: [3], // publishing
                width: '15%'
            }, {
                targets: [4], // createdDate
                width: '20%'
            }, {
                targets: [5], // mpm
                width: '8%'
            }, {
                targets: [6], // clip status
                width: '10%'
            },
        ];

        const buttonDiv = <div className="Pb(10px)">
            <Button
                className="btn btn-default-outline Mr(3px)"
                disabled={!this.state.selectedIds.length}
                onClick={this.handleToggleExportTimelineModal}
            ><i className="fa-solid fa-cloud-arrow-down" />&nbsp;
                {this.context.intl.messages['hardac.timeline.export.export-timelines']}
            </Button>
            <Button
                className="btn btn-default-outline Mr(3px)"
                disabled={!this.state.selectedIds.length}
                onClick={this.handleToggleExportAvidModal}
            ><i className="fa-solid fa-cloud-arrow-down" />&nbsp;
                {this.context.intl.messages['hardac.timeline.export.export-avid']}
            </Button>
        </div>;

        const modalExportTimelines = (
            <Modal
                className={ClassNames({'skin-dark': this.state.viewingMode === 'skin-dark'})}
                show={this.state.showExportTimelinesModal}
                onHide={this.handleToggleExportTimelineModal}
            >
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="text-center" >{this.context.intl.messages['hardac.timeline.export.modal.titles']}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormSection>
                        <div className="text-center">
                            <p><strong>{this.state.selectedIds.length}</strong> {this.context.intl.messages['hardac.timeline.export-bulk.selected-count']}</p>
                            <i>{this.context.intl.messages['hardac.timeline.export.modal.body']}</i>
                        </div>
                    </FormSection>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="pull-left Ml(5)" onClick={this.handleToggleExportTimelineModal}><i className="fas fa-ban" /> {this.context.intl.messages['common.cancel']}</Button>
                    <Button className="btn btn-success-outline" onClick={this.handleExportTimeline}>
                        <i className="far fa-cloud-download" /> {this.context.intl.messages['hardac.timeline.export.modal.export-now']}
                    </Button>
                </Modal.Footer>
            </Modal>
        );

        const modalExportAvid = (
            <Modal
                className={ClassNames({'skin-dark': this.state.viewingMode === 'skin-dark'})}
                show={this.state.showExportAvidModal}
                onHide={this.handleToggleExportAvidModal}
            >
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="text-center" >{this.context.intl.messages['hardac.timeline.export.modal.titles']}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormSection>
                        <div className="text-center">
                            <p><strong>{this.state.selectedIds.length}</strong> {this.context.intl.messages['hardac.timeline.export-bulk.selected-count']}</p>
                            <i>{this.context.intl.messages['hardac.timeline.export.modal.body']}</i>
                        </div>
                    </FormSection>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="pull-left Ml(5)" onClick={this.handleToggleExportAvidModal}><i className="fas fa-ban" /> {this.context.intl.messages['common.cancel']}</Button>
                    <Button className="btn btn-success-outline" onClick={this.handleExportAvid}>
                        <i className="far fa-cloud-download" />{this.context.intl.messages['hardac.timeline.export.modal.export-now']}
                    </Button>
                </Modal.Footer>
            </Modal>
        );

        return (
            <DocumentTitle
                message="hardac.timelines.browse.title"
            >
                <div className={ClassNames({'control-sidebar-open': this.state.showFiltersPanel})}>
                    <div className={ClassNames({'sidebar-opened': this.state.showFiltersPanel})}>
                        <Preloader show={this.state.preloaderVisible || this.state.showPreloader} fixed loadingDots>
                            <section className="content-header">
                                <h1>
                                    <i className="far fa-stream"></i>&nbsp;{this.context.intl.messages['hardac.timelines.browse.title']}
                                    &nbsp;<small>{this.state.total}</small>
                                </h1>
                            </section>
                            <section className="content">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="container-fluid no-x-padding">
                                            <div className="row">
                                                <div className="col-md-6 show-grid">
                                                    <SearchBox onChange={this.handleSearchTerm} value={this.getRouteState().get('asset-name')}/>
                                                </div>
                                                <div className="col-md-6 show-grid">
                                                    <Button onClick={this.handleToggleFiltersPanel} bsStyle="primary" bsSize="large" className="pull-right">
                                                        <i className="fas fa-sliders-h"></i>&nbsp;{this.context.intl.messages['common.filteringOptions']}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container-fluid no-x-padding">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="box">
                                                        <div className="box-body">
                                                            {buttonDiv}
                                                            <ListAssets
                                                                columns={['hardacTimelineAssetThumbnail', 'hardacTimelineAssetName', 'publishing', 'createdDate', 'hardacTimelineMPMNumber', 'hardacTimelineStatus', 'catalogs']}
                                                                columnDefs={columnDefs}
                                                                assets={this.state.assets.map(asset => {
                                                                    if (this.state.selectedIds.includes(asset.get('id')) ) {
                                                                        asset = asset.set('__selected', true);
                                                                    }
                                                                    return asset;
                                                                })}
                                                                activePage={Math.ceil((this.getRouteState().get('offset') || 0) / 20 ) || 0}
                                                                onSortChange={this.handleSortChange}
                                                                sortDirection={this.getRouteState().get('sort-direction') || 'desc'}
                                                                sortFieldName={this.getRouteState().get('sort-field-name') || 'updatedDate'}
                                                                displayVideoTimelineLinks={this.props.permissions.canReadVideoTimelineAssets}
                                                                onPageChange={this.handleLoadPage}
                                                                onSelect={this.handleToggleSelect}
                                                                onSelectAll={this.handleToggleSelectAll}
                                                                selectAll={this.state.selectAll}
                                                                totalPages={Math.ceil(this.state.total / 20) || 0}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <FilterOptions
                                location={this.props.location}
                                showAssetType={false}
                                filters={this.state.filters}
                            />
                            {modalExportTimelines}
                            {modalExportAvid}
                        </Preloader>
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}

export {BrowseTimelines};

export default WithStoreOnRoute(
    Container.create(BrowseTimelines)
);
