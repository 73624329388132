/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React from 'react';
import url from 'url';

import Config from '../../config/config';
import Request from '../../request';

export default class AuthLink extends React.Component {
    static get propTypes() {
        return {
            children: PropTypes.oneOfType([
                PropTypes.array,
                PropTypes.object,
                PropTypes.string
            ]).isRequired,
            className: PropTypes.string,
            disabled: PropTypes.bool,
            href: PropTypes.string.isRequired,
            onClick: PropTypes.func,
            title: PropTypes.string,
        };
    }

    static get defaultProps() {
        return {
            className: '',
            disabled: false,
            onClick: () => void 0,
            title: undefined,
        };
    }

    static click(href) {
        Request.post('web/authenticated-link').send({
            linkURL: href,
            numberOfViewsLeft: 1
        }).exec().then(res => {
            const downloadURL = url.resolve(Config.ApiBaseUrl, href);
            // true to parse the query string object too.
            const urlObject = url.parse(downloadURL, true);
            // Search must be undefined, if not, the format() method
            // ignores the query object.
            delete urlObject.search;
            urlObject.query.LinkToken = res.body.linkToken;

            // Create a link element and click it to start the download.
            const linkElement = document.createElement('a');
            linkElement.setAttribute(
                'href',
                url.format(urlObject)
            );
            linkElement.setAttribute('style', 'display: none');
            const evt = document.createEvent('MouseEvents');
            evt.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, linkElement);
            linkElement.dispatchEvent(evt);
            linkElement.remove();

            return;
        });
    }

    static download(blob, fileName) {
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
            // IE doesn't allow using a blob object directly as link href.
            window.navigator.msSaveBlob(blob, fileName);
            return;
        }

        // Create a link element and click it to start the download.
        const linkElement = document.createElement('a');
        const blobURL = window.URL.createObjectURL(blob);
        linkElement.setAttribute('href', blobURL);
        linkElement.setAttribute('download', fileName);
        linkElement.setAttribute('style', 'display: none');
        document.body.appendChild(linkElement);
        linkElement.click();
        document.body.removeChild(linkElement);
        setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(blobURL);
        }, 100);
    }

    handleClick(href, onClickCallback, event) {
        event.preventDefault();

        if (!this.props.disabled) {
            this.constructor.click(href);
            onClickCallback();
        }

        return;
    }

    render() {
        return (
            <a
                className={this.props.className}
                onClick={this.handleClick.bind(this, this.props.href, this.props.onClick)}
                disabled={this.props.disabled}
                title={this.props.title}
            >{this.props.children}</a>
        );
    }
}
