/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {ControlLabel, FormControl, FormGroup} from 'react-bootstrap';

import HomepageTemplates from './homepage-templates';
import {FormItem, FormRow, FormSection} from '../../../common/form/form';
import TinyMCE from '../../../common/tinymce/tinymce';
import {CompareImmutable} from '../../../common/utils/utils';
import config from '../../../config/config.js';
import PartnerSelect from '../../../partner/partner-select';
import {GroupConstants} from '../../../security/group/group-actions';
import GroupSelect from '../../../security/group/group-select';
import {HomepageActions} from '../../homepage-actions';
import {HomepageValidations} from '../../homepage-store';

const homepageProps = [
    'active', 'name', 'description', 'groups', 'pageType', 'partners', 'sections', 'updatedDate', 'updatedBy'
];

class Summary extends React.Component {

    static get propTypes() {
        return {
            disabled: PropTypes.bool,
            homepage: PropTypes.instanceOf(Immutable.Map).isRequired,
            mode: PropTypes.string.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true
        };
    }

    shouldComponentUpdate(nextProps) {
        return !CompareImmutable(nextProps.homepage, this.props.homepage, homepageProps);
    }

    handleSelectGroup(groups) {
        if (!groups) {
            // Reset the value.
            HomepageActions.update('groups', Immutable.OrderedSet());
            return;
        }
        // Call the select action but with immutable objects.
        HomepageActions.update('groups', Immutable.fromJS(groups).toSet().sortBy(g => g.get('name')));
    }

    handleSelectPartner(partners) {
        if (!partners) {
            HomepageActions.update('partners', Immutable.OrderedSet());
            return;
        }
        HomepageActions.update('partners', Immutable.fromJS(partners).toSet().sortBy(p => p.get('id')));
    }

    handleTinyMCEChange(attr) {
        return function handler(e) {
            HomepageActions.update(attr, e.target.getContent());
            return;
        };
    }

    render() {
        let modified;
        if (this.props.mode === 'edit') {
            let updatedDate = '';
            if (this.props.homepage.get('updatedDate')) {
                updatedDate = Moment(this.props.homepage.get('updatedDate')).tz(config.DefaultTimezone).format(this.context.intl.messages['datetime-format']);
            }

            modified = <div>
                <hr/>
                <FormRow>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['common.last-modified-date']}</ControlLabel>
                        <FormControl.Static>{updatedDate}</FormControl.Static>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['common.last-modifier']}</ControlLabel>
                        <FormControl.Static>{this.props.homepage.get('updatedBy')}</FormControl.Static>
                    </FormGroup>
                </FormRow>
            </div>;
        }

        return (
            <div>
                <h3><i className="far fa-info-circle"></i>{this.context.intl.messages['homepages.info']}</h3>
                <FormSection>
                    <FormRow>
                        <FormItem attr="name"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['homepages.name']}
                            model={this.props.homepage}
                            placeholder={this.context.intl.messages['homepages.name.placeholder']}
                            setter={HomepageActions.update}
                            type="text"
                            validations={HomepageValidations.name.validations}
                        />
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['homepages.status']}</ControlLabel>
                            <FormItem attr="active"
                                disabled={this.props.disabled}
                                label="Active"
                                model={this.props.homepage}
                                md={5}
                                setter={HomepageActions.update}
                                type="checkbox"
                            />
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['homepages.description']}</ControlLabel>
                            <TinyMCE
                                content={this.props.homepage.get('description') || ''}
                                id="description"
                                onChange={this.handleTinyMCEChange('description')}
                            />
                        </FormGroup>
                    </FormRow>
                    <hr />
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['homepages.create.groups.title']}</ControlLabel>
                            <GroupSelect
                                disabled={this.props.disabled}
                                onChange={this.handleSelectGroup}
                                selected={this.props.homepage.get('groups')}
                                selectedKey="id"
                                type={GroupConstants.USER}
                            />
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['homepages.create.partners.title']}</ControlLabel>
                            <PartnerSelect
                                disabled={this.props.disabled}
                                onChange={this.handleSelectPartner}
                                selected={this.props.homepage.get('partners')}
                                selectedKey="id"
                            />
                        </FormGroup>
                    </FormRow>
                    {modified}
                </FormSection>
                <HomepageTemplates
                    homepage={this.props.homepage}
                    disabled={this.props.disabled}
                    mode={this.props.mode}/>
            </div>
        );
    }
}

export default Summary;
