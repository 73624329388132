/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {PermissionsAuditConstants} from './permissions-audit-actions';
import Dispatcher from '../../../dispatcher/dispatcher';

class PermissionsAuditStore extends ReduceStore {

    getInitialState() {
        return Immutable.Map({
            permissionsData: Immutable.List()
        });
    }

    reduce(state, action) {
        switch (action.actionType) {
        case PermissionsAuditConstants.SET_PERMISSIONS_DATA:
            state = state.set('permissionsData', action.permissionsData);
            break;

        default:
            break;
        }

        return state;
    }
}

let store = new PermissionsAuditStore(Dispatcher);

export default store;
