/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {SEVERITY, ERRORTYPE} from './validations';
import Panel from '../panel/panel';

class Validation extends Component {
    static get propTypes() {
        return {
            icon: PropTypes.string,
            iconValid: PropTypes.string,
            validation: PropTypes.oneOfType([
                PropTypes.array,
                PropTypes.object
            ]).isRequired,
        };
    }

    static get defaultProps() {
        return {
            icon: 'fas fa-flag',
            iconValid: 'fas fa-flag'
        };
    }

    render() {
        let icon = this.props.icon;
        if (this.props.validation.valid) {
            icon = this.props.iconValid;
        }

        let className = 'text-yellow';
        if (this.props.validation.severity === SEVERITY.ALERT) {
            className = 'text-red';
        }

        if (this.props.validation.errorType === ERRORTYPE.INFO) {
            className = '';
        }

        if (this.props.validation.valid) {
            className = 'text-green';
        }

        return (
            <p>
                <span className={className}>
                    <i className={icon}/>
                    &nbsp;{this.props.validation.message}
                </span>
            </p>
        );
    }
}

class AlertsWarnings extends Component {
    static get propTypes() {
        return {
            addRequiredValidation: PropTypes.bool,
            children: PropTypes.node,
            icon: PropTypes.string,
            iconValid: PropTypes.string,
            stores: PropTypes.array,
            title: PropTypes.string,
            validations: PropTypes.oneOfType([
                PropTypes.array,
                PropTypes.object
            ]),
            withBorder: PropTypes.bool
        };
    }

    static get defaultProps() {
        return {
            addRequiredValidation: true,
            children: undefined,
            icon: 'fas fa-flag',
            iconValid: 'fas fa-flag',
            title: '',
            validations: [],
            withBorder: true,
            stores: undefined
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            validations: this.readValidations()
        };

        this.readValidations = this.readValidations.bind(this);
    }

    componentWillMount() {
        // FIXME: this should be removed when all validations come from this.props.stores
        if (!this.props.stores) {
            return;
        }

        this.listeners = this.props.stores.map(store => {
            return store.addListener(() => {
                let validations = this.readValidations();
                this.setState({
                    validations: validations
                });
            });
        });
    }

    componentWillUnmount() {
        // FIXME: this should be removed when all validations come from this.props.stores
        if (!this.listeners) {
            return;
        }

        this.listeners.forEach(listener => {
            listener.remove();
            return;
        });
    }

    readValidations() {
        // FIXME: this should be removed when all validations come from this.props.stores
        if (!this.props.stores) {
            return;
        }

        let validations = [];
        this.props.stores.forEach(store => {
            validations.push(...store.getValidations());
        });
        return validations;
    }

    render() {
        let validations = this.props.validations;

        if (this.props.stores) {
            validations = this.state.validations;
        }

        let extraClasses = ['MB10P'];
        if (!this.props.withBorder) {
            extraClasses.push('no-border');
            extraClasses.push('no-shadow');
        }
        return (
            <Panel extraClasses={extraClasses} withBorder={this.props.withBorder} iconClass="fas fa-flag" title={this.props.title} additionalStyle="padding-x-20">
                {validations.reduce((r, v) => {
                    if (v.type !== 'required') {
                        r.push(v);
                        return r;
                    }

                    // If v is of type required, then check if any required validation is already added
                    // to r.
                    // If any, return r.
                    if (v.type === 'required' && r.some(vv => vv.type === 'required')) {
                        return r;
                    }
                    // If not, add the required validation.
                    if (this.props.addRequiredValidation) {
                        r.push({
                            message: <span><b>One or more required fields missing</b> - Please complete all fields marked with *</span>,
                            severity: SEVERITY.ALERT,
                            type: 'required'
                        });
                    }

                    return r;
                }, []).map((v, i) => <Validation key={i} validation={v} icon={this.props.icon} iconValid={this.props.iconValid}/>)}
                {this.props.children}
            </Panel>
        );
    }
}

export {
    AlertsWarnings,
    Validation
};
