/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {Checkbox, ControlLabel, FormControl, FormGroup} from 'react-bootstrap';

import AssetStore from '../../../assets/asset-store';
import BaseSelect from '../../../common/base-select/base-select';
import {FormItem, FormRow, FormSection} from '../../../common/form/form';
import config from '../../../config/config.js';
import {GroupConstants} from '../../../security/group/group-actions';
import GroupSelect from '../../../security/group/group-select';
import {BatchActions, BatchConstants} from '../../batch-actions';
import BatchStore, {BatchValidations} from '../../batch-store';
import BatchStatus from '../../common/batch-status';


class Summary extends React.Component {
    static get propTypes() {
        return {
            batch: PropTypes.instanceOf(Immutable.Map).isRequired,
            disabled: PropTypes.bool,
            items: PropTypes.instanceOf(Immutable.List).isRequired,
            mode: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            selectedGroupsExternal: BatchStore.getState().get('emailGroupsExternal'),
            selectedGroupsInternal: BatchStore.getState().get('emailGroupsInternal'),
        };
    }

    static getStores() {
        return [BatchStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            showInactiveGroups: false,
        }, this.constructor.calculateState());

        this.handleSelectBatchActiveType = this.handleSelectBatchActiveType.bind(this);
        this.handleSelectDeliveryType = this.handleSelectDeliveryType.bind(this);
        this.handleSelectEmailGroupExternal = this.handleSelectEmailGroupExternal.bind(this);
        this.handleSelectEmailGroupInternal = this.handleSelectEmailGroupInternal.bind(this);
        this.handleSelectMFAOption = this.handleSelectMFAOption.bind(this);
        this.handleSelectTitleSynopsisLogLineDisplayType = this.handleSelectTitleSynopsisLogLineDisplayType.bind(this);
        this.handleShowInactiveGroups = this.handleShowInactiveGroups.bind(this);
        this.handleTimeChange = this.handleTimeChange.bind(this);
    }

    handleSelectDeliveryType(value) {
        if (value === null) {
            BatchActions.update('deliveryType', null);
            return;
        }
        BatchActions.update('deliveryType', value.id);
    }

    handleSelectMFAOption(value) {
        if (value === null) {
            BatchActions.update('mfaRequired', null);
            return;
        }
        BatchActions.update('mfaRequired', value.id);
    }

    handleSelectTitleSynopsisLogLineDisplayType(value) {
        if (value === null) {
            BatchActions.update('titleSynopsisLogLineDisplayType', null);
            return;
        }
        BatchActions.update('titleSynopsisLogLineDisplayType', value.id);

    }

    handleSelectBatchActiveType(value) {
        if (value === null) {
            BatchActions.update('active', BatchConstants.BATCH_ACTIVE_TYPES.NO_CHANGE.id);
            return;
        }
        BatchActions.update('active', value.id);

    }

    handleSelectEmailGroupExternal(groups) {
        if (!groups) {
            // Reset the value.
            BatchActions.setEmailGroupsExternal(Immutable.List());
            return;
        }

        // Call the select action but with immutable objects.
        BatchActions.setEmailGroupsExternal(Immutable.fromJS(groups).toList());

    }

    handleSelectEmailGroupInternal(groups) {
        if (!groups) {
            // Reset the value.
            BatchActions.setEmailGroupsInternal(Immutable.List());
            return;
        }

        // Call the select action but with immutable objects.
        BatchActions.setEmailGroupsInternal(Immutable.fromJS(groups).toList());

    }

    handleShowInactiveGroups(e) {
        this.setState({
            showInactiveGroups: e.target.checked
        });

    }

    handleTimeChange(attr, value) {
        value = Moment(value, ['hh:mm a']).format('HH:mm:ss');
        BatchActions.update(attr, value);

    }

    handleTurnOffDisclaimerNotice(event) {
        BatchActions.update('clearDisclaimerNotice', event.target.checked);

    }

    render() {
        const {
            createdBy,
            createdDate,
            executedAt,
            executionTime,
            updatedBy,
            updatedDate
        } = this.props.batch.toJS();

        let additionalInfo;
        let disabled = this.props.disabled;
        let eta = this.context.intl.messages['common.na'];
        let statusRow;
        let systemTime = this.context.intl.messages['common.na'];
        let totalItems = this.props.items.size;
        let timeFormatted;
        let totalItemsHeader;
        const completedMsg = this.context.intl.messages['scheduling.batches.status.completed'];
        const pendingMsg = this.context.intl.messages['scheduling.batches.status.pending'];

        // See STUDIO-9863 for details on why using a ControlLabel here causes an exception Maximum Call Stack Size Exceeded
        const activeSelect = <div>
            <strong>Sets Future Active/Inactive Status</strong>
            <BaseSelect
                disabled={disabled}
                isClearable={true}
                multi={false}
                name="active"
                onChange={this.handleSelectBatchActiveType}
                options="batchActiveTypes"
                store={BatchStore}
                value={BatchStore.getBatchActiveType(this.props.batch.get('active'))}
            />
        </div>;

        const deliveryType = [
            <ControlLabel>{this.context.intl.messages['scheduling.batch.summary.delivery-type']}</ControlLabel>,
            <BaseSelect
                disabled={disabled}
                isClearable={true}
                multi={false}
                name="deliveryType"
                onChange={this.handleSelectDeliveryType}
                options="deliveryType"
                store={AssetStore}
                value={AssetStore.getDeliveryType(this.props.batch.get('deliveryType'))}
            />
        ];

        let batchStatus;
        if (this.props.mode === 'edit') {
            batchStatus = [
                <ControlLabel>{this.context.intl.messages['scheduling.batches.status']}</ControlLabel>,
                <BatchStatus
                    completedMessage={completedMsg}
                    completedMessageTooltip={completedMsg}
                    executedAt={executedAt}
                    pendingMessage={pendingMsg}
                    pendingMessageTooltip={pendingMsg}
                />
            ];
        }

        const mfaSelect = <BaseSelect
            disabled={disabled}
            isClearable={true}
            multi={false}
            name="mfaRequired"
            onChange={this.handleSelectMFAOption}
            options="mfaOptions"
            store={BatchStore}
            value={BatchStore.getMFAOption(this.props.batch.get('mfaRequired'))}
        />;

        switch (this.props.type) {
        case BatchConstants.BATCH_TYPES.DOCUMENT:
            statusRow = <FormRow>
                <FormGroup md={3}>
                    {activeSelect}
                </FormGroup>
                <FormGroup md={3}>
                    {batchStatus}
                </FormGroup>
            </FormRow>;
            totalItemsHeader = <h3><i className="far fa-photo-video"></i>&nbsp;{this.context.intl.messages['scheduling.batch.summary.total-assets']}</h3>;
            break;
        case BatchConstants.BATCH_TYPES.IMAGE:
            statusRow = <FormRow>
                <FormGroup md={6}>
                    {deliveryType}
                </FormGroup>
                <FormGroup md={3}>
                    {activeSelect}
                </FormGroup>
                <FormGroup md={3}>
                    {batchStatus}
                </FormGroup>
            </FormRow>;
            totalItemsHeader = <h3><i className="far fa-photo-video"></i>&nbsp;{this.context.intl.messages['scheduling.batch.summary.total-assets']}</h3>;
            break;
        case BatchConstants.BATCH_TYPES.VIDEO:
            statusRow = <FormRow>
                <FormGroup md={6}>
                    {deliveryType}
                </FormGroup>
                <FormGroup md={6}>
                    <ControlLabel>{this.context.intl.messages['scheduling.batch.summary.mfa']}</ControlLabel>
                    {mfaSelect}
                </FormGroup>
                <FormGroup md={3}>
                    {activeSelect}
                </FormGroup>
                <FormGroup md={3}>
                    {batchStatus}
                </FormGroup>
                <FormGroup md={3}>
                    <FormItem
                        attr="clearDisclaimerNotice"
                        disabled={disabled}
                        label={this.context.intl.messages['scheduling.batch.summary.turn-off-disclaimer-notice']}
                        model={this.props.batch}
                        setter={BatchActions.update}
                        type="checkbox"
                    />
                </FormGroup>
            </FormRow>;
            totalItemsHeader = <h3><i className="far fa-photo-video"></i>&nbsp;{this.context.intl.messages['scheduling.batch.summary.total-assets']}</h3>;
            break;
        case BatchConstants.BATCH_TYPES.TITLE:
            statusRow = <FormRow>
                <FormGroup md={4}>
                    {activeSelect}
                </FormGroup>
                <FormGroup md={4}>
                    <ControlLabel>{this.context.intl.messages['scheduling.batch.summary.hide-show-synopsis-log-lines']}</ControlLabel>
                    <BaseSelect
                        disabled={disabled}
                        isClearable={false}
                        multi={false}
                        name="titleSynopsisLogLineDisplayTypeOptions"
                        onChange={this.handleSelectTitleSynopsisLogLineDisplayType}
                        options="titleSynopsisLogLineDisplayTypeOptions"
                        store={BatchStore}
                        value={BatchStore.getTitleSynopsisLogLineDisplayType(this.props.batch.get('titleSynopsisLogLineDisplayType'))}
                    />
                    <p><small><em>{this.context.intl.messages['scheduling.batch.summary.hide-show-synopsis-log-lines.description']}</em></small></p>
                </FormGroup>
                <FormGroup md={3}>
                    {batchStatus}
                </FormGroup>
            </FormRow>;
            totalItemsHeader = <h3><i className="far fa-newspaper"></i>&nbsp;{this.context.intl.messages['scheduling.batch.summary.total-titles']}</h3>;
            break;
        }

        if (executionTime) {
            timeFormatted = Moment(executionTime, ['HH:mm']).format('hh:mm a');
        }

        if (this.props.mode === 'edit') {
            if (executedAt) {
                eta = Moment(executedAt).fromNow();
                systemTime = Moment(executedAt).tz(config.DefaultTimezone).format(this.context.intl.messages['datetime-format']);
            }
            let createdDateFormatted = this.context.intl.messages['common.na'];
            if (createdDate) {
                createdDateFormatted = Moment(createdDate).tz(config.DefaultTimezone).format(this.context.intl.messages['datetime-format']);
            }
            let updatedDateFormatted = this.context.intl.messages['common.na'];
            if (updatedDate) {
                updatedDateFormatted = Moment(updatedDate).tz(config.DefaultTimezone).format(this.context.intl.messages['datetime-format']);
            }
            additionalInfo = <FormRow>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['scheduling.batch.summary.created']}</ControlLabel>
                    <FormControl.Static>{createdDateFormatted}</FormControl.Static>
                </FormGroup>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['scheduling.batch.summary.created-by']}</ControlLabel>
                    <FormControl.Static>{createdBy}</FormControl.Static>
                </FormGroup>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['scheduling.batch.summary.last-modified']}</ControlLabel>
                    <FormControl.Static>{updatedDateFormatted}</FormControl.Static>
                </FormGroup>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['scheduling.batch.summary.last-modifier']}</ControlLabel>
                    <FormControl.Static>{updatedBy}</FormControl.Static>
                </FormGroup>
            </FormRow>;
        }

        let checkboxShowInactiveInternal;
        let addExternalAnnouncementGroups;
        if (this.props.type !== BatchConstants.BATCH_TYPES.TITLE) {
            addExternalAnnouncementGroups = <div>
                <FormRow>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['scheduling.batch.summary.email.add-external-groups']}</ControlLabel>
                        <small className="padding-x-10"><a target="_blank" href="https://warnerbrostv.atlassian.net/wiki/spaces/PB/pages/1279459349/Scheduled+Update+Complete+Notification+-+For+Clients">{this.context.intl.messages['scheduling.batch.summary.email.view-template']}</a></small>
                        <Checkbox
                            disabled={disabled}
                            className="pull-right show-inactive"
                            onChange={this.handleShowInactiveGroups}>
                            {this.context.intl.messages['common.show-inactive']}
                        </Checkbox>
                    </FormGroup>
                </FormRow>
                <FormRow>
                    <FormGroup>
                        <GroupSelect
                            disabled={disabled}
                            onChange={this.handleSelectEmailGroupExternal}
                            selected={this.state.selectedGroupsExternal}
                            showInactive={this.state.showInactiveGroups}
                            type={GroupConstants.USER}
                            name="groups-external"
                        />
                    </FormGroup>
                </FormRow>
            </div>;
        } else {
            checkboxShowInactiveInternal = <Checkbox
                disabled={disabled}
                className="pull-right show-inactive"
                onChange={this.handleShowInactiveGroups}>
                {this.context.intl.messages['common.show-inactive']}
            </Checkbox>;
        }

        return (
            <div>
                <h3><i className="fa fa-info-circle"></i>&nbsp;{this.context.intl.messages['scheduling.batch.summary.info']}</h3>
                <FormSection>
                    <FormRow>
                        <FormItem
                            attr="batchName"
                            label={this.context.intl.messages['scheduling.batch.summary.name']}
                            disabled={disabled}
                            model={this.props.batch}
                            md={6}
                            required
                            setter={BatchActions.update}
                            type="text"
                            validations={BatchValidations.batchName.validations}
                        />
                        <FormItem
                            attr="executionDate"
                            addonBefore={<i className="far fa-calendar-alt"></i>}
                            datepicker={{
                                isClearable: false,
                                popoverTargetOffset: '10px -36px',
                                minDate: new Date()
                            }}
                            disabled={disabled}
                            label={this.context.intl.messages['scheduling.batch.summary.date']}
                            model={this.props.batch}
                            md={3}
                            setter={BatchActions.update}
                            type="date"
                            validations={[...BatchValidations.executionDate.validations, ...BatchValidations.executionTime.validations]}
                        />
                        <FormItem
                            attr="executionTime"
                            addonBefore={<i className="far fa-clock"></i>}
                            datepicker={{
                                isClearable: false,
                                popoverTargetOffset: '10px -36px',
                                shouldCloseOnSelect: true,
                                showTimeSelect: true,
                                showTimeSelectOnly: true,
                                timeIntervals: 15,
                                minDate: new Date(),
                                value: timeFormatted
                            }}
                            disabled={disabled}
                            label={this.context.intl.messages['scheduling.batch.summary.time']}
                            model={this.props.batch}
                            md={3}
                            setter={this.handleTimeChange}
                            type="time"
                            validations={[...BatchValidations.executionDate.validations, ...BatchValidations.executionTime.validations]}
                        />
                    </FormRow>
                    {statusRow}
                </FormSection>
                <hr/>

                <h3><i className="fa fa-users"></i>&nbsp;{this.context.intl.messages['scheduling.batch.summary.email.email-recipients']}</h3>
                <FormSection>
                    {addExternalAnnouncementGroups}

                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['scheduling.batch.summary.email.add-internal-groups']}</ControlLabel>
                            <small className="padding-x-10"><a target="_blank" href="https://warnerbrostv.atlassian.net/wiki/spaces/PB/pages/1279492134/Scheduled+Update+Complete+Notification+-+For+Internals">{this.context.intl.messages['scheduling.batch.summary.email.view-template']}</a></small>
                            {checkboxShowInactiveInternal}
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <GroupSelect
                                disabled={disabled}
                                onChange={this.handleSelectEmailGroupInternal}
                                selected={this.state.selectedGroupsInternal}
                                showInactive={this.state.showInactiveGroups}
                                type={GroupConstants.USER}
                                name="groups-internal"
                            />
                        </FormGroup>
                    </FormRow>
                </FormSection>
                <hr/>

                <FormSection>
                    <FormRow>
                        <FormGroup className="text-center">
                            {totalItemsHeader}
                            <h4>{totalItems}</h4>
                        </FormGroup>
                        <FormGroup className="text-center">
                            <h3><i className="fas fa-book"></i>&nbsp;{this.context.intl.messages['scheduling.batch.summary.total-catalogs']}</h3>
                            <h4>{this.props.batch.get('catalogs').size}</h4>
                        </FormGroup>
                        <FormGroup className="text-center">
                            <h3><i className="far fa-clock"></i>&nbsp;{this.context.intl.messages['scheduling.batch.summary.system-time']}</h3>
                            <h4>{systemTime}</h4>
                        </FormGroup>
                        <FormGroup className="text-center">
                            <h3><i className="far fa-stopwatch"></i>&nbsp;{this.context.intl.messages['scheduling.batch.summary.eta']}</h3>
                            <h4>{eta}</h4>
                        </FormGroup>
                    </FormRow>
                </FormSection>
                <hr />

                {additionalInfo}
            </div>
        );
    }
}

export default Container.create(Summary);
export {Summary};
