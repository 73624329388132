/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import AssignedTable from './assigned';
import UnassignedTable from './unassigned';
import {FormSection} from '../../../common/form/form';
import SearchBox from '../../../common/search-box/search-box';
import {Debounce} from '../../../common/utils/utils';
import {GroupActions, GroupConstants} from '../../../security/group/group-actions';
import GroupStore from '../../../security/group/group-store';

class EventCatalogs extends Component {

    static get propTypes() {
        return {
            assignedCatalogs: PropTypes.instanceOf(Immutable.List),
            serieNavigation: PropTypes.instanceOf(Immutable.Map).isRequired,
            title: PropTypes.instanceOf(Immutable.Map).isRequired,
            name: PropTypes.string,
            type: PropTypes.string
        };
    }

    static get defaultProps() {
        return {
            assignedCatalogs: Immutable.List(),
            name: 'catalogs',
            type: 'title'
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
            router: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            groups: GroupStore.getState()
        };
    }

    static getStores() {
        return [GroupStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            filters: ''
        }, this.constructor.calculateState(props));

        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
    }

    componentDidMount() {
        let constants;
        switch (this.props.type) {
        case GroupConstants.TITLE.name: constants = GroupConstants.TITLE; break;
        case GroupConstants.LANGUAGE.name: constants = GroupConstants.LANGUAGE; break;
        }
        GroupActions.get(constants,
            true,
            {
                filters: Immutable.Map(),
                offset: 0,
                size: 9999
            }
        );
    }

    handleSearchTerm(term) {
        this.setState({
            'filters': term
        });
        return;
    }

    render() {
        // show event catalogs only
        let assignedCatalogsToShow = this.props.assignedCatalogs.filter(catalog =>
            catalog.get('name').toLowerCase().startsWith('event:')
        );

        return (
            <div>
                <h3><i className="fas fa-book"></i>&nbsp;{this.context.intl.messages['titles.edit.event-catalogs.title']}</h3>
                <div className="tab-pane">
                    <hr/>
                    <span className="text-yellow"><i className="fas fa-exclamation-triangle" />&nbsp;<b>{this.context.intl.messages['titles.edit.event-catalogs.warning']}</b></span>
                    <hr/>
                    <h4>{this.context.intl.messages['titles.edit.catalogs-tab.assigned-title']}&nbsp;<small>{assignedCatalogsToShow.size}</small></h4>
                    <FormSection>
                        <AssignedTable
                            assignedCatalogs={assignedCatalogsToShow}
                            filters={this.state.filters}
                            name={this.props.name}
                            serieNavigation={this.props.serieNavigation}
                            title={this.props.title}
                        />
                    </FormSection>
                    <hr/>
                    <h4>{this.context.intl.messages['titles.edit.catalogs-tab.unassigned-title']}</h4>
                    <FormSection>
                        <div className="container-fluid no-x-padding">
                            <div className="row">
                                <div className="col-md-3"></div>
                                <div className="col-md-6 show-grid">
                                    <SearchBox value={this.state.searchTerm} onChange={this.handleSearchTerm}/>
                                </div>
                                <div className="col-md-3"></div>
                            </div>
                        </div>
                        <UnassignedTable
                            assignedCatalogs={assignedCatalogsToShow}
                            catalogs={this.state.groups.getIn([this.props.type, 'groups'])}
                            filters={this.state.filters}
                            showEventCatalogs
                            serieNavigation={this.props.serieNavigation}
                            title={this.props.title}
                            name={this.props.name}
                        />
                    </FormSection>
                </div>
            </div>
        );
    }
}

export default Container.create(EventCatalogs);
