/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Checkbox} from 'react-bootstrap';
import Select from 'react-select';

import Panel from '../../common/panel/panel';
import {WithStoreOnRoute} from '../../common/store-on-route';
import {LayoutActions} from '../../layout/layout-actions';

class FilterOptions extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            titleCatalogs: PropTypes.instanceOf(Immutable.List)
        };
    }

    static get defaultProps() {
        return {
            titleCatalogs: Immutable.List()
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
        };
    }

    constructor(props) {
        super(props);

        this.handleShowInactiveChange = this.handleShowInactiveChange.bind(this);
        this.handleChildGroupIdChange = this.handleChildGroupIdChange.bind(this);
        this.handleToggleFiltersPanel = this.handleToggleFiltersPanel.bind(this);
        this.isShowInactiveChecked = this.isShowInactiveChecked.bind(this);
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.location !== this.props.location ||
            nextProps.titleCatalogs !== this.props.titleCatalogs;
    }

    handleChildGroupIdChange(childGroupId) {
        if (childGroupId === null) {
            this.clearRouteState('child-group-id')
                .apply();
            return;
        }
        this.setRouteState('child-group-id', childGroupId.get('id'))
            .apply();
        return;
    }

    handleShowInactiveChange(event) {
        this.setRouteState('show-inactive', event.target.checked)
            .apply();
        return;
    }

    handleToggleFiltersPanel() {
        LayoutActions.toggleFiltersPanel();
    }

    isShowInactiveChecked() {
        return this.getRouteState().get('show-inactive') || false;
    }

    render() {
        let filterChildGroupId;

        if (this.props.titleCatalogs.size) {
            filterChildGroupId = <Panel title={this.context.intl.messages['groups.filter.syndication-title']} classTitle="box-title" collapsible defaultExpanded>
                <Select
                    isClearable={true}
                    options={this.props.titleCatalogs}
                    getOptionLabel={data => data.get('name')}
                    getOptionValue={data => data.get('id')}
                    onChange={this.handleChildGroupIdChange}
                    value={this.props.titleCatalogs.find(o => o.get('id') === this.getRouteState().get('child-group-id'))}
                />
            </Panel>;
        }

        return (
            <div>
                <aside className="control-sidebar B(0) Ovx(h) Ovy(a)">
                    <div className="tab-content">
                        <p>
                            <button className="btn btn-block bg-navy" onClick={this.handleToggleFiltersPanel}><i className="fas fa-chevron-right pull-right"></i>&nbsp;Close Options</button>
                        </p>
                        <Panel title={this.context.intl.messages['groups.filter.status']} classTitle="box-title" collapsible defaultExpanded>
                            <Checkbox
                                checked={this.isShowInactiveChecked()}
                                onChange={this.handleShowInactiveChange}>
                                {this.context.intl.messages['common.show-inactive']}
                            </Checkbox>
                        </Panel>
                        {filterChildGroupId}
                    </div>
                </aside>
                <div style={{position: 'fixed', height: 'auto'}} className="control-sidebar-bg"></div>
            </div>
        );
    }
}

export default WithStoreOnRoute(FilterOptions);
