/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {AbrPlayer, ShakaPlayerRequestType} from '@accurate-player/accurate-player-abr';
import {MediaEventType, PlayerEventType, SeekMode, TimeFormat} from '@accurate-player/accurate-player-core';
import {HotkeyPlugin, PointPlugin} from '@accurate-player/accurate-player-plugins';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import VideoPlayerThumbnails from './video-player-thumbnails';
import VideoStore from '../assets/video/video-store';
import {FrameRateToFraction, TimecodeToFrames, FramesToSeconds} from '../common/utils/timecode';
import {SessionActions} from '../user/session/session-actions';

import Analytics from '~/src/analytics';

import '@accurate-player/accurate-player-controls';

import './accurate-player.less';

// DO NOT TOUCH THIS!
const LICENSE_KEY = '57E130648E784C6EB2B09FEF8AA62AB3U593EBA36750D4125473101D5943DDDFE';

// Decide which type of player to create.
/*istanbul ignore next*/
const CreatePlayer = (playerElem, src, audioTrackNumber, asset, showThumbnails) => {
    const r = {
        player: new AbrPlayer(playerElem, LICENSE_KEY)
    };
    let isHLS = false;

    if (showThumbnails) {
        VideoPlayerThumbnails.LoadTimelinePreviewThumbs(r, asset.toJS());
    }

    switch (true) {
    // Look for streaming first.
    case !!src.get('hls'):
        if (playerElem.canPlayType('application/vnd.apple.mpegURL')) {
            r.src = src.get('hls');
            isHLS = true;
            // r.player.api.adapter.player.configure('drm.advanced.com\\.apple\\.fps\\.1_0.serverCertificate', window.__cert);
            break;
        }
    // fallthrough
    case !!src.get('dash'):
        r.src = src.get('dash');
        if (audioTrackNumber !== undefined) {
            r.src = r.src.replace(/\)$/, `,filter=audio${audioTrackNumber.toString().padStart(2, '0')})`);
        }
        // Make sure we buffer, a lot, so that the sync between the players
        // is easier.
        r.player.api.configureShaka({
            streaming: {
                bufferBehind: 5,
                bufferingGoal: 20,
                rebufferingGoal: 10
            }
        });
        break;
    }

    if (src.get('drmTokens')) {
        r.player.api.registerRequestFilter((type, request) => {
            // Only add headers to license requests:
            if (type === ShakaPlayerRequestType.LICENSE) {
                // This is the specific header name and value the server wants:
                if (!isHLS) {
                    request.headers.authorization = src.getIn(['drmTokens', 'token']);
                } else {
                    request.headers.authorization = src.getIn(['drmTokens', 'fairplayToken']);
                }
            }
        });
    }

    return r;
};

export class GhostPlayers extends React.Component {
    static get propTypes() {
        return {
            frameOffset: PropTypes.number.isRequired,
            getPlayerStatus: PropTypes.func.isRequired,
            onAddPlayer: PropTypes.func.isRequired,
            onRemovePlayers: PropTypes.func.isRequired,
            src: PropTypes.instanceOf(Immutable.Map).isRequired,
            tracks: PropTypes.instanceOf(Immutable.List).isRequired,
            video: PropTypes.instanceOf(Immutable.Map).isRequired,
        };
    }

    shouldComponentUpdate(nextProps) {
        return this.props.tracks.size !== nextProps.tracks.size;
    }

    componentWillUnmount() {
        this.props.onRemovePlayers();
    }

    /*istanbul ignore next*/
    initGhostPlayer(id, trackNumber, track, ghostPlayer) {
        if (!ghostPlayer) {
            return;
        }

        const {video} = this.props;

        const {player, src} = CreatePlayer(ghostPlayer, this.props.src, track.get('audioChannel') - 1);
        player.brainiac = {
            id,
            track
        };
        player.api.loadVideoFile({
            channelCount: 1,
            src,
            enabled: true,
            frameOffset: this.props.frameOffset,
            frameRate: FrameRateToFraction(video.get('frameRate')),
            dropFrame: false,
        });

        this.props.onAddPlayer(player);

        if (track.get('isMuted')) {
            player.api.mute();
        }

        // Make sure the player will start at the same current point
        // of the main player
        const mainPlayerStatus = this.props.getPlayerStatus();
        const syncPoint = {
            format: TimeFormat.Seconds,
            time: Math.floor(mainPlayerStatus.currentTime),
            mode: SeekMode.Absolute
        };
        player.api.seek(syncPoint);
        if (!mainPlayerStatus.paused) {
            player.api.play();
        }


    }

    render() {
        return (
            <div style={{display: 'none'}}>
                {this.props.tracks.map((t, i) => (
                    <video
                        id={`ghost-player-${i}`}
                        ref={this.initGhostPlayer.bind(this, `ghost-player-${i}`, i, t)}
                        crossOrigin={true}
                        playsInline={true}
                    />
                ))}
            </div>
        );
    }
}

/*istanbul ignore next*/
export default class AccuratePlayer extends React.Component {
    static get propTypes() {
        return {
            audioProfile: PropTypes.instanceOf(Immutable.List),
            audioSoloing: PropTypes.bool,
            clipIn: PropTypes.number,
            clipOut: PropTypes.number,
            enableSessionRefresh: PropTypes.bool,
            onUpdatePlayer: PropTypes.func,
            playerRef: PropTypes.object,
            position: PropTypes.oneOf(['absolute', 'fixed', 'relative', 'static']),
            showThumbnails: PropTypes.bool,
            src: PropTypes.instanceOf(Immutable.Map).isRequired,
            video: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get defaultProps() {
        return {
            audioProfile: Immutable.List(),
            audioSoloing: false,
            clipIn: undefined,
            clipOut: undefined,
            enableSessionRefresh: true,
            onUpdatePlayer: () => void 0,
            playerRef: {},
            position: 'relative',
            showThumbnails: true
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static getStores() {
        return [VideoStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            asset: VideoStore.getState().get('asset')
        });

        this.ghostPlayers = [];
        this.refreshSessionIntervalId = null;

        this.getPlayerStatus = this.getPlayerStatus.bind(this);
        this.ghostPlayersDo = this.ghostPlayersDo.bind(this);
        this.handleAddPlayer = this.handleAddPlayer.bind(this);
        this.handleRemovePlayers = this.handleRemovePlayers.bind(this);
        this.onMount = this.onMount.bind(this);

    }

    componentDidMount() {
        document.addEventListener('keyup', this.toggleControls);

        if (this.props.enableSessionRefresh) {
            // refresh session every 5 min
            this.refreshSessionIntervalId = setInterval(() => {
                SessionActions.keepAlive();
            }, 300000);
        }
    }

    shouldComponentUpdate(nextProps) {
        return (
            this.props.audioProfile !== nextProps.audioProfile ||
            this.props.src !== nextProps.src ||
            this.props.video !== nextProps.video
        );
    }

    componentDidUpdate(oldProps) {
        // If the audioProfile changed but it has the same number of elements,
        // it means the change was related to audio soloing or muting.
        if (
            this.props.audioProfile !== oldProps.audioProfile &&
            this.props.audioProfile.size === oldProps.audioProfile.size
        ) {
            this.getAudioTracks(this.props.audioProfile).forEach(track => {
                this.ghostPlayers.forEach(gp => {
                    if (gp.brainiac.track.get('audioChannel') === track.get('audioChannel')) {
                        if (track.get('isMuted')) {
                            gp.api.mute();
                            return;
                        }
                        gp.api.unmute();
                    }
                });
            });
        }
    }

    componentWillUnmount() {
        if (this.props.enableSessionRefresh) {
            clearInterval(this.refreshSessionIntervalId);
        }

        // This may conflict with the code in the ref handler.
        // There's probably a race condition.
        try {
            this.ghostPlayers.forEach(gp => gp.destroy());
        } catch (e) {
            console.error('Error trying to delete ghost player', e);
        }
        document.removeEventListener('keyup', this.toggleControls);


    }

    // Return audio tracks that should play, sorted by their track number.
    getAudioTracks(audioProfile) {
        return audioProfile.sortBy(t => t.get('audioChannel'));
    }

    getFrameOffset(video) {
        if (!video.get('firstFrame')) {
            return 0;
        }

        // Accurate Player requires the frame offset to be
        // calculated using a rounded value for the frame rate
        // (24 instead of 23.976).
        return TimecodeToFrames(video.get('firstFrameTimecode'), Math.round(video.get('frameRate')));
    }

    getPlayerStatus() {
        return this.mainPlayer.api.getStatus();
    }

    ghostPlayersDo(f) {
        this.ghostPlayers.forEach(f);
    }

    handleAddPlayer(gp) {
        this.ghostPlayers.push(gp);
    }

    handleRemovePlayers() {
        try {
            this.ghostPlayers.forEach(gp => gp.destroy());
        } catch (e) {
            console.error('Error destroying ghost AccuratePlayer', e);
        }
        this.ghostPlayers = [];


    }

    onMount(elem) {
        if (!elem) {
            clearInterval(this.playerSyncInterval);
            this.props.onUpdatePlayer(null);
            try {
                this.mainPlayer.destroy();
            } catch (e) {
                console.error('Error destroying main AccuratePlayer', e);
            }
            return;
        }

        const playerElem = elem.querySelector('video');
        const apControlsElem = elem.querySelector('apc-controls');

        const {video, clipIn, clipOut} = this.props;

        const {player, src} = CreatePlayer(playerElem, this.props.src, undefined, this.state.asset, this.props.showThumbnails);
        this.mainPlayer = player;
        this.props.playerRef.current = player;

        let videoFrameOffset = this.getFrameOffset(video);
        let videoStartOffset = 0;
        let duration;
        if (clipIn !== undefined) {
            duration = clipOut - clipIn;
            videoStartOffset = FramesToSeconds(clipIn, video.get('frameRate'));
            videoFrameOffset += clipIn;
        }

        this.mainPlayer.api.loadVideoFile({
            channelCount: 1,
            src,
            enabled: true,
            duration,
            frameOffset: videoFrameOffset,
            frameRate: FrameRateToFraction(video.get('frameRate') || 24),
            dropFrame: false,
            videoStartOffset
        });

        if (this.props.audioSoloing) {
            this.mainPlayer.api.mute();
        }

        apControlsElem.init(playerElem, this.mainPlayer, {
            saveLocalSettings: true // Enables storing of simple settings as volume, timeFormat and autoHide.
        });

        new HotkeyPlugin(this.mainPlayer);
        new PointPlugin(this.mainPlayer);

        apControlsElem.player = this.mainPlayer;

        // FIXME: this is to avoid dispatch in dispatch error.
        // Not very flux happy, but not many other options.
        setTimeout(() => this.props.onUpdatePlayer(this.mainPlayer));

        const gpPlay = () => {
            Analytics.videoPlaybackStartEvent();
        };
        playerElem.addEventListener(MediaEventType.Play, gpPlay);

        const gpPlaying = () => {
            Analytics.videoPlaybackSuccessEvent();
            this.ghostPlayers.forEach(gp => gp.api.play());
        };
        playerElem.addEventListener(MediaEventType.Playing, gpPlaying);

        const gpPause = () => this.ghostPlayers.forEach(gp => gp.api.pause());
        playerElem.addEventListener(MediaEventType.Pause, gpPause);
        playerElem.addEventListener(MediaEventType.Waiting, gpPause);

        const gpError = () => {
            Analytics.videoPlaybackErrorEvent();
        };
        playerElem.addEventListener(MediaEventType.Error, gpError);

        this.playerSyncInterval = setInterval(() => {
            const {currentTime, paused} = this.mainPlayer.api.getStatus();
            if (paused) {
                return;
            }
            this.ghostPlayers.forEach(gp => {
                const gpCurrentTime = gp.api.getStatus().currentTime;
                const gpSecondsDiff = gpCurrentTime - currentTime;

                switch (true) {
                // Ghost player is ahead.
                case gpSecondsDiff > 0.09 && gp.api.getStatus().playbackRate !== 0.9:
                    gp.api.setPlaybackRate(0.9);
                    break;
                // Ghost player is behind.
                case gpSecondsDiff < -0.09 && gp.api.getStatus().playbackRate !== 1.1:
                    gp.api.setPlaybackRate(1.1);
                    break;
                // If we get here and playback rate is not 1, that means we are
                // back in sync. Set playback rate back to 1.
                case gpSecondsDiff > -0.09 && gpSecondsDiff < 0.09 && gp.api.getStatus().playbackRate !== 1:
                    gp.api.setPlaybackRate(1);
                    break;
                }
            });
        }, 2000);

        playerElem.addEventListener(MediaEventType.Seeking, () => {
            // Seek for all players (to keep in sync).
            const syncPoint = {
                format: TimeFormat.Seconds,
                time: Math.floor(this.mainPlayer.api.getStatus().currentTime),
                mode: SeekMode.Absolute
            };
            this.ghostPlayers.forEach(gp => gp.api.seek(syncPoint));
        });

        if (clipIn) {
            playerElem.addEventListener(MediaEventType.TimeUpdate, () => {
                // Ends the clip and goes to clipIn again once it reaches the clipOut
                const currentTimeInFrames = this.mainPlayer.api.getCurrentTime(TimeFormat.Frame);
                if (currentTimeInFrames >= clipOut-clipIn) {
                    this.mainPlayer.api.seek({
                        format: TimeFormat.Frame,
                        time: 0,
                        mode: SeekMode.Absolute
                    });
                    this.mainPlayer.api.pause();
                    this.mainPlayer.emitEvent(PlayerEventType.Ended);
                }
            });
            this.mainPlayer.api.seek({
                format: TimeFormat.Frame,
                time: 0,
                mode: SeekMode.Absolute
            });
        }


    }

    toggleControls(e) {
        if (e.code === 'KeyS') {
            if (document.getElementsByTagName('apc-controls')[0].style.opacity === '0') {
                document.getElementsByTagName('apc-controls')[0].style.opacity = 1;
                document.getElementsByClassName('ap-video-container')[0].style.cursor = 'auto';
                return;
            }

            document.getElementsByTagName('apc-controls')[0].style.opacity = 0;
            document.getElementsByClassName('ap-video-container')[0].style.cursor = 'none';

        }
    }

    render() {
        let gps;

        if (this.mainPlayer && this.props.audioProfile.size) {
            gps = (
                <GhostPlayers
                    frameOffset={this.getFrameOffset(this.props.video)}
                    getPlayerStatus={this.getPlayerStatus}
                    onAddPlayer={this.handleAddPlayer}
                    onRemovePlayers={this.handleRemovePlayers}
                    src={this.props.src}
                    tracks={this.getAudioTracks(this.props.audioProfile)}
                    video={this.props.video}
                />
            );
        }

        return (
            <div className="ap-video-container" style={{position: this.props.position}} ref={this.onMount}>
                <video
                    crossOrigin={true}
                    playsInline={true}
                />
                <apc-controls/>
                {gps}
            </div>
        );
    }
}
