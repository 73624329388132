/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import createReactClass from 'create-react-class';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';

import {RoleActions} from './role-actions';
import RoleStore from './role-store';

/**
 * Add roles section.
 */
export default createReactClass({
    propTypes: {
        disabled: PropTypes.bool,
        isMulti: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
        selected: PropTypes.instanceOf(Immutable.OrderedSet).isRequired,
        showInactive: PropTypes.bool
    },

    getDefaultProps: function() {
        return {
            disabled: true,
            isMulti: true,
            showInactive: false
        };
    },

    getInitialState: function() {
        return {
            roles: RoleStore.getState().get('roles')
        };
    },

    componentWillMount: function() {
        this.storeListeners = [
            RoleStore.addListener(() => {
                this.setState({
                    roles: RoleStore.getState().get('roles')
                });
                return;
            })
        ];
        return;
    },

    componentDidMount: function() {
        RoleActions.get(0, 9999, false);
        return;
    },

    shouldComponentUpdate: function(nextProps, nextState) {
        if (nextProps.selected !== this.props.selected ||
            nextProps.showInactive !== this.props.showInactive) {
            return true;
        }

        if (nextState.roles !== this.state.roles) {return true;}

        return false;
    },

    componentWillUnmount: function() {
        this.storeListeners.forEach(listener => listener.remove());
        return;
    },

    render: function() {
        let roles = this.state.roles.filter(role => {
            if (!this.props.showInactive && role.get('isActive') === 0) {
                return;
            }
            return role;
        });
        return (
            <Select
                getOptionLabel={data => data.name}
                getOptionValue={data => data.id}
                isClearable={true}
                isMulti={this.props.isMulti}
                isDisabled={this.props.disabled}
                name="roles"
                onChange={this.props.onChange}
                options={roles.toJS()}
                placeholder="Select Role"
                value={this.props.selected.toJS()}
            />
        );
    }
});
