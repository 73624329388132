/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import LookupActions from '../lookup-actions';

const CONSTANTS = {
    GET: {
        ERROR: 'genre_actions.get.error',
        START: 'genre_actions.get.start',
        SUCCESS: 'genre_actions.get.success'
    },
    MESSAGES: {
        CREATE_ERROR: 'lookups.genre.create.error',
        CREATE_OK: 'lookups.genre.create.ok',
        REMOVE_ERROR: 'lookups.genre.remove.error',
        REMOVE_OK: 'lookups.genre.remove.ok',
        SAVE_ERROR: 'lookups.genre.save.error',
        SAVE_OK: 'lookups.genre.save.ok',
    },
    REMOVE_OK: 'genre_actions.remove',
    SAVE_OK: 'genre_actions.save'
};

class GenreActions extends LookupActions {

    constructor(path, CONSTS) {
        super(path, CONSTS);
    }

}

let actions = new GenreActions('lookup/genre', CONSTANTS);

export {
    actions as GenreActions,
    CONSTANTS as GenreConstants
};
