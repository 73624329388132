/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Glyphicon} from 'react-bootstrap';
import {Link} from 'react-router';

import {ApplicantNavigationActions} from './applicant-navigation-actions';
import ApplicantNavigationStore from './applicant-navigation-store';
import {WithStoreOnRoute} from '../../common/store-on-route';

class ApplicantNavigation extends Component {
    static get propTypes() {
        return {
            applicantId: PropTypes.number,
            location: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            applicantId: undefined
        };
    }

    static calculateState() {
        return {
            next: ApplicantNavigationStore.getState().get('next'),
            offset: ApplicantNavigationStore.getState().get('offset'),
            prev: ApplicantNavigationStore.getState().get('prev'),
            hasApplicants: ApplicantNavigationStore.getState().get('hasApplicants')
        };
    }

    static getStores() {
        return [ApplicantNavigationStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.handleLinkClick = this.handleLinkClick.bind(this);
    }

    componentDidMount() {
        ApplicantNavigationActions.clear();
    }

    componentDidUpdate(prevProps) {
        if (
            (prevProps.applicantId !== this.props.applicantId) &&
            (!prevProps.applicantId || (this.props.applicantId && this.state.hasApplicants))
        ) {
            ApplicantNavigationActions.setNavigation(this.props.applicantId, this.state.offset, this.getRouteState(this.props));
        }
        if (this.props.location.search === '' && prevProps.location.query['account-status']) {
            this.setRouteState('operator', 'OR')
                .setRouteState('account-status', prevProps.location.query['account-status'])
                .apply();
        }

        return;
    }

    handleLinkClick(href, event) {
        if (!href) {
            event.preventDefault();
        }
        return;
    }

    render() {
        if (!this.props.applicantId) {
            // render null if data is not available
            return null;
        }
        let currentTab = '';
        // Initialize the prev and next with something falsy but different
        // from undefined so that the Link object doesn't break.
        // This is because the "to" property must not be undefined or null.
        let next, nextText, prev, prevText = '';

        if (this.props.location) {
            let pathnames = this.props.location.pathname.split('/');
            if (pathnames.length > 4) {
                currentTab = '/' + pathnames[4];
            }
        }
        if (this.state.next) {
            next = `/accounts/applicants/${this.state.next}${currentTab}${this.props.location.search}`;
            nextText = this.context.intl.messages['accounts.edit.applicant.btn.next'];
        }
        if (this.state.prev) {
            prev = `/accounts/applicants/${this.state.prev}${currentTab}${this.props.location.search}`;
            prevText = this.context.intl.messages['accounts.edit.applicant.btn.prev'];
        }

        return (
            <span className="d-inline-block">
                <Link
                    className="btn btn-primary-outline btn-sm"
                    disabled={!this.state.prev}
                    title={prevText}
                    to={prev}
                    onClick={this.handleLinkClick.bind(this, prev)}>
                    <Glyphicon glyph="chevron-left"/>
                </Link>&nbsp;
                <Link
                    className="btn btn-primary-outline btn-sm"
                    disabled={!this.state.next}
                    title={nextText}
                    to={next}
                    onClick={this.handleLinkClick.bind(this, next)}>
                    <Glyphicon glyph="chevron-right"/>
                </Link>
            </span>
        );
    }
}

export default WithStoreOnRoute(
    Container.create(ApplicantNavigation)
);
