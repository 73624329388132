/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {Button} from 'react-bootstrap';

import RouteValidator from '../../common/custom-validator/route/route';
import DocumentTitle from '../../common/document-title';
import {MODAL_TYPE} from '../../common/notification/modal';
import {NotificationActions} from '../../common/notification/notification-actions';
import {Tab, Tabs} from '../../common/routing-tab/routing-tab';
import {History} from '../../common/side-notes/side-notes';
import TinyMCE from '../../common/tinymce/tinymce';
import StickyHeader from '../../layout/sticky-header/sticky-header';
import Preloader from '../../preloader';
import {RouterActions} from '../../router/router-actions';
import {ActionHistoryConstants} from '../../system/action-history/action-history-actions';
import SessionStore from '../../user/session/session-store';
import {HelpPageActions} from '../help-page-actions';
import HelpPageStore from '../help-page-store';

class HelpPages extends React.Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            route: RouteValidator,
            permissions: PropTypes.object.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            route: undefined
        };
    }

    static getPermissions() {
        return {
            canEditHelpPage: SessionStore.canUser(SessionStore.PERMISSIONS.HELP_PAGES.EDIT)
        };
    }

    static getStores() {
        return [HelpPageStore];
    }

    static calculateState() {
        const helpPageState = HelpPageStore.getState();
        return {
            helpHtml: helpPageState.get('helpHtml'),
            originalHelpHtml: helpPageState.get('originalHelpHtml'),
            id: helpPageState.get('id') || 'help-page',
            isLoading: helpPageState.get('isLoading'),
            updatedBy: helpPageState.get('updatedBy'),
            updatedDate: helpPageState.get('updatedDate'),
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({}, this.constructor.calculateState());

        this.confirmCancel = this.confirmCancel.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleTinyMCEChange = this.handleTinyMCEChange.bind(this);
        this.isDirty = this.isDirty.bind(this);
    }

    componentDidMount() {
        RouterActions.registerRedirectCheck((location) => {
            return this.isDirty(location);
        });
        HelpPageActions.get(this.props.route.helpPage);
        return;
    }

    componentDidUpdate(prevProps) {
        if (this.props.route.helpPage !== prevProps.route.helpPage) {
            HelpPageActions.get(this.props.route.helpPage);
            return;
        }
    }

    handleCancel() {
        if (this.state.originalHelpHtml !== this.state.helpHtml) {
            NotificationActions.show(
                MODAL_TYPE.DANGER,
                this.context.intl.messages['help-pages.modal.title'],
                this.context.intl.messages['help-pages.modal.body'],
                this.context.intl.messages['common.confirm'],
                this.confirmCancel,
            );
            return;
        }

        RouterActions.redirect(`/${this.props.route.helpPage}/help`);
    }

    confirmCancel() {
        this.setState((prevState)=>({
            helpHtml: prevState.originalHelpHtml,
        }));
    }

    handleSave() {
        HelpPageActions.savePage(this.props.route.helpPage, this.state.helpHtml);
    }

    handleTinyMCEChange(e) {
        //This if prevents a automatic save that fires this onChange when nothing changes.
        if (e?.originalEvent?.type !== 'savecontent') {
            this.setState(() => ({
                helpHtml: e.target.getContent()
            }));
        }
    }

    isDirty(location) {
        if (location) {
            let goingTo = location.pathname.split('/');
            let current = this.props.location.pathname.split('/');
            if (goingTo[1] === current[1] && /* help */
                goingTo[2] === current[2] /* help/history */) {
                return false; // just changing tabs no confirmation needed.
            }
        }

        if (this.props.route.mode !== 'edit') {
            return false;
        }

        return this.state.originalHelpHtml !== this.state.helpHtml;
    }

    render() {
        const baseRoute = `/${this.props.route.path}`;

        let historyTab;
        let subHeader;
        if (this.props.route.mode === 'view') {
            historyTab = <Tab route={`${baseRoute}/history`} title={this.context.intl.messages['common.tab-title.history']} tabClassName="pull-right">
                <History
                    actionObject={ActionHistoryConstants.ACTION_OBJECTS.SIMPLE_WEB_PAGE}
                    id={this.state.id}
                    pagination
                    pathname={this.props.location.pathname}
                    query={this.props.location.query}/>
            </Tab>;

            subHeader = <small>
                {this.context.intl.messages['help-pages.last-updated']}&nbsp;
                {Moment(this.state.updatedDate).format(this.context.intl.messages['date-format-hyphen'])}&nbsp;
                {this.context.intl.messages['help-pages.by']}&nbsp;
                {this.state.updatedBy}
            </small>;
        }

        let editOrSaveButton = <Button
            bsSize="large"
            className="btn btn-primary-outline Mr(3px) Mb(3px)"
            onClick={() => RouterActions.redirect(`/${this.props.route.helpPage}/help/edit`)}
        >
            <i className="fa-solid fa-pencil"/>&nbsp;{this.context.intl.messages['common.edit']}
        </Button>;

        let documentOrTinyMCE = documentOrTinyMCE = <div dangerouslySetInnerHTML={{
            __html: this.state.helpHtml
        }} />;

        if (this.props.route.mode === 'edit') {
            editOrSaveButton = <Button
                bsSize="large"
                className="btn btn-success-outline Mr(3px) Mb(3px)"
                onClick={this.handleSave}
            >
                <i className="fa-solid fa-save"/>&nbsp;{this.context.intl.messages['common.save']}
            </Button>;

            documentOrTinyMCE = <div>
                <h3>
                    <i className="fa-regular fa-pencil" />&nbsp;{this.context.intl.messages['help-pages.edit-help-page']}
                </h3>
                <p>
                    <small>
                        <em>{this.context.intl.messages['help-pages.edit-help-page.description']}</em>
                    </small>
                </p>
                <hr />
                <TinyMCE
                    content={this.state.helpHtml}
                    formats="Heading 1=h2; Heading 2=h3; Heading 3=h4; Paragraph=p"
                    height={500}
                    helpPagesToolbar={true}
                    id="help-page"
                    imageUploadHandler={(blobInfo, progress) => HelpPageActions.uploadImage(blobInfo, progress, this.props.route.helpPage)}
                    onChange={this.handleTinyMCEChange}
                />
            </div>;
        }

        let actionsMenu;
        if (this.props.permissions.canEditHelpPage) {
            actionsMenu = <div className="col-md-6 pull-right text-right padding-bottom-20 no-x-padding text-center-sm">
                {editOrSaveButton}
                <Button
                    bsSize="large"
                    className="btn btn-default-outline Mr(3px) Mb(3px)"
                    disabled={!this.props.route.mode === 'edit'}
                    onClick={this.handleCancel}
                >
                    <i className="fas fa-ban"/>&nbsp;{this.context.intl.messages['common.cancel']}
                </Button>
            </div>;
        }

        return <DocumentTitle
            message={'help-pages.help-pages'}
        >
            <Preloader show={this.state.isLoading} fixed loadingDots>
                <StickyHeader>
                    <div className="col-md-6">
                        <h1>
                            <i className="fa-regular fa-question-circle"></i>&nbsp;
                            {this.context.intl.messages['help-pages.title']}&nbsp;
                            {this.context.intl.messages[`help-pages.titles.${this.props.route.helpPage}`]}

                        </h1>
                        {subHeader}
                    </div>
                    {actionsMenu}
                </StickyHeader>
                <section className="content" id="contentContainer">
                    <div className="row">
                        <div className="col-lg-12">
                            <Tabs location={this.props.location}>
                                <Tab route={baseRoute} title={this.context.intl.messages['common.tab-title.summary']}>
                                    {documentOrTinyMCE}
                                </Tab>
                                {historyTab}
                            </Tabs>
                        </div>
                    </div>
                </section>
            </Preloader>
        </DocumentTitle>;
    }
}

export default Container.create(HelpPages);
// export {HelpPages as HelpPagesRoot};
