/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import jQuery from 'jquery';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {WorkOrdersConstants} from './work-orders-actions';
import Pagination from '../../common/table/pagination';
import config from '../../config/config.js';

require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('datatables.net-responsive-bs');

// Use the Dimensions decorator to provide with and height properties.

class WorkOrdersList extends Component {

    static get propTypes() {
        return {
            activePage: PropTypes.number.isRequired,
            displayLinks: PropTypes.bool,
            onPageChange: PropTypes.func.isRequired,
            onSort: PropTypes.func.isRequired,
            sortDirection: PropTypes.string.isRequired,
            sortFieldName: PropTypes.string.isRequired,
            totalPages: PropTypes.number.isRequired,
            workOrders: PropTypes.instanceOf(Immutable.List).isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
            router: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            displayLinks: false
        };
    }

    constructor(props) {
        super(props);

        this.handleResize = this.handleResize.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.getSortFieldNameClass = this.getSortFieldNameClass.bind(this);
        this.handleSort = this.handleSort.bind(this);
    }

    componentDidMount() {
        this.$table = jQuery('#work-orders-table').DataTable({
            autoWidth: false,
            columnDefs: [{
                // Add the control class to the last column. This colum will
                // contain the button to show the responsive data.
                className: 'control',
                targets:   -1,
                width: 20
            }, {
                targets: 'no-sort',
                orderable: false
            }],
            iDisplayLength: 1,
            info: false,
            ordering: false,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        });

        // Register global listeners.
        window.addEventListener('resize', this.handleResize);

        this.componentWillUpdate(this.props);
        return;

    }

    componentWillUpdate(props) {
        this.$table.clear();

        let fmt = (d) => {
            let md = Moment(d);
            if (md.isValid()) {
                return md.tz(config.DefaultTimezone).format(this.context.intl.messages['date-format']);
            }
            return '-';
        };

        props.workOrders.forEach( wo => {
            let status;
            status = WorkOrdersConstants.STATUS.find(s => s.id === wo.get('status'));
            status = status ?
                `<i class="${status.icon}"></i> <span class="hidden-xs hidden-sm"> ${status.name}</span>`
                : '';
            let exportSpecification;
            exportSpecification = WorkOrdersConstants.EXPORT_SPECIFICATION.find(e => e.id === wo.get('exportSpecification'));
            exportSpecification = exportSpecification ? exportSpecification.name : 'GENERIC';
            let workOrderName = wo.get('name');
            if (props.displayLinks) {
                workOrderName = `<a href="/titles/work-orders/${wo.get('id')}" class="edit-wo">${workOrderName}</a>`;
            }

            this.$table.row.add( [
                wo.get('id'),
                workOrderName,
                status,
                wo.get('client'),
                fmt(wo.get('dueDate')),
                fmt(wo.get('createdDate')),
                fmt(wo.get('completedDate')),
                exportSpecification,
                ''
            ]);
        });

        this.$table.draw(false);
        // Now, since the data has changed the columns widths, trigger
        // the resize handler in order to update the responsive feature.
        this.handleResize();

        return;
    }

    componentWillUnmount() {
        // Remove global listeners.
        window.removeEventListener('resize', this.handleResize);
    }

    /**
     * Update the datatable columns size.
     */
    handleResize() {
        this.$table.responsive.recalc();
        return;
    }

    /**
     * This function is kind of "special" because it needs to handle
     * events bubbled from the data table rows, these rows cannot use
     * the JSX syntax because they are created by the data table
     * jQuery plugin instead of React.
     */
    handleRowClick(event) {
        switch (true) {
        // Handle click on an autorized partner's name.
        case !!~event.target.className.indexOf('edit-wo'):
            // Prevent the default anchor click event.
            event.preventDefault();
            this.context.router.push(event.target.getAttribute('href'));
            break;
        }

        return;
    }

    getSortFieldNameClass(headerName) {
        let r = 'sorting';

        if (headerName === this.props.sortFieldName) {
            r = `sorting_${this.props.sortDirection}`;
        }

        return r;
    }

    handleSort(column) {
        if (!this.props.onSort) {
            return;
        }
        this.props.onSort(column);
    }

    render() {
        return (
            <div>
                <table id="work-orders-table" className="table table-bordered table-striped responsive">
                    <thead>
                        <tr>
                            <th className={this.getSortFieldNameClass('id')} onClick={this.handleSort.bind(this, 'id')}>ID #</th>
                            <th className={this.getSortFieldNameClass('name')} onClick={this.handleSort.bind(this, 'name')}>Work Order Name</th>
                            <th className={this.getSortFieldNameClass('status')} onClick={this.handleSort.bind(this, 'status')}>Status</th>
                            <th className={this.getSortFieldNameClass('clientname')} onClick={this.handleSort.bind(this, 'clientname')}>Client Name</th>
                            <th className={this.getSortFieldNameClass('duedate')} onClick={this.handleSort.bind(this, 'duedate')}>Due Date</th>
                            <th className={this.getSortFieldNameClass('datecreated')} onClick={this.handleSort.bind(this, 'datecreated')}>Date Created</th>
                            <th className={this.getSortFieldNameClass('datecompleted')} onClick={this.handleSort.bind(this, 'datecompleted')}>Date Completed</th>
                            <th className={this.getSortFieldNameClass('exportspecification')} onClick={this.handleSort.bind(this, 'exportspecification')}>Export Spec</th>
                            <th className="no-sort"></th>
                        </tr>
                    </thead>
                    <tbody onClick={this.handleRowClick}>
                    </tbody>
                </table>
                <div className="row">
                    <div className="col-sm-7 col-sm-offset-5">
                        <Pagination
                            activePage={this.props.activePage}
                            onChange={this.props.onPageChange}
                            totalPages={this.props.totalPages}
                        />
                    </div>
                </div>
            </div>
        );
    }

}

export default WorkOrdersList;
