/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {merge, fromEvent, pipe} from 'rxjs';
import {filter, map} from 'rxjs/operators';

import {CurrentTime$, SelectedCues$, IsSyncTime$, VideoDuration$} from './stores';
import CCEditorStore from '../cc-editor-store';
import {STICKY_CUE_PERIOD} from '../constants';

import {ConvertFluxStoreToBehaviorSubject} from '~/src/common/utils/flux-to-rx-helpers';

const detectCueIdFromMouseEvent = pipe(
    map((e: MouseEvent) => {
        if (e.target instanceof HTMLElement) {
            return e.target.closest('[data-cue-id]');
        }
        return null;
    }),
    filter((el) => !!el),
    map((el) => el?.getAttribute('data-cue-id')),
    filter((id) => !!id),
);

const documentClickSource = fromEvent(document, 'click');

export const SingleCueClickSource = documentClickSource.pipe(
    // filter(() => !IsChangedSelectedCue$.value),
    filter((e: MouseEvent) => !e.shiftKey && !e.metaKey && !e.altKey && !e.ctrlKey),
    detectCueIdFromMouseEvent,
);
const onCueClickTimeChangeSource = SingleCueClickSource.pipe(
    filter(() => IsSyncTime$.value),
    map(detectStartTimeForCue),
    filter((time) => typeof time === 'number'),
);
const documentKeyDownSource = fromEvent(document, 'keydown').pipe(
    filter((ev: KeyboardEvent) => !(ev.target instanceof HTMLInputElement)),
    map((ev: KeyboardEvent) => ev.key),
);
const arrowUpSource = documentKeyDownSource.pipe(
    filter((code) => code === 'ArrowUp' && SelectedCues$.value.length === 0),
    map(findNearestPrevCueStartTime)
);
const arrowDownSource = documentKeyDownSource.pipe(
    filter((code) => code === 'ArrowDown' && SelectedCues$.value.length === 0),
    map(findNearestNextCueEndTime)
);
export const ChangeVttCueSource$ = ConvertFluxStoreToBehaviorSubject(CCEditorStore, 'history').pipe(map(last));
export const MultiCuesSelectionClickSource = documentClickSource.pipe(
    filter(() => SelectedCues$.value.length > 0),
    filter((e: MouseEvent) => e.shiftKey),
    detectCueIdFromMouseEvent,
    filter((cueId) => SelectedCues$.value.every((c) => c.id !== cueId)),
);
export const TimeUpdateSource$ = merge(arrowDownSource, arrowUpSource, onCueClickTimeChangeSource);


function findNearestPrevCueStartTime() {
    const time = CurrentTime$.value;
    const predicate = (c: WBTVDCue) => (c.startTime + STICKY_CUE_PERIOD) < time;
    const cues = CCEditorStore.getState().cues.filter(predicate);
    return safetyStartTime(last(cues));
}

function findNearestNextCueEndTime() {
    const time = CurrentTime$.value;
    const predicate = (c: WBTVDCue) => (c.endTime - STICKY_CUE_PERIOD) > time;
    const cues = CCEditorStore.getState().cues.filter(predicate);
    return safetyEndTime(cues[0]);
}

function detectStartTimeForCue(id: string) {
    return safetyStartTime(findCueById(id));
}

function safetyStartTime(cue: WBTVDCue | undefined) {
    return (cue?.startTime ?? 0) + .01;
}

function safetyEndTime(cue: WBTVDCue | undefined) {
    if (cue) {
        return cue.endTime - .01;
    }
    return VideoDuration$.value;
}

function findCueById(id: string) {
    return CCEditorStore.getState().cues.find(c => c?.id === id);
}

function last<T>(arr: ArrayLike<T>): T | undefined {
    if (arr.length) {
        return arr[arr.length - 1];
    }
}
