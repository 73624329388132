/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {LanguageCodeType} from '@wbdt-sie/brainiac-web-common';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {FormRow, FormSection, LocalizedFormItem} from '../../../common/form/form';
import {SectionPanel} from '../../../common/panel/panel';
import {CompareImmutable} from '../../../common/utils/utils';
import {WithValidations} from '../../../common/validations/validations';
import {TitleActions} from '../../title-actions';
import {TitleLocalizedActions} from '../../title-localized-actions';
import {TitleValidations} from '../../title-store';

/**
 * Title props to watch.
 */
const titleProps = [
    'aka1', 'aka2', 'alternativeTitle1', 'alternativeTitle2',
    'ika1', 'ika2', 'pka1', 'pka2'
];

class AlternativeTitles extends Component {

    static get propTypes() {
        return {
            currentLanguage: PropTypes.string,
            disabled: PropTypes.bool,
            expanded: PropTypes.bool,
            title: PropTypes.instanceOf(Immutable.Map).isRequired,
            titleLocalized: PropTypes.instanceOf(Immutable.Map)
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true,
            expanded: false,
            currentLanguage: undefined,
            titleLocalized: undefined
        };
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.disabled !== this.props.disabled ||
            nextProps.titleLocalized !== this.props.titleLocalized || // actually have to check if name or selected language has changed
            nextProps.currentLanguage !== this.props.currentLanguage ||
            !CompareImmutable(nextProps.title, this.props.title, titleProps) ||
            nextProps.expanded !== this.props.expanded) {
            return true;
        }
        return false;
    }

    render() {
        let localized = this.props.currentLanguage !== LanguageCodeType.DEFAULT_LANGUAGE;
        return (
            <SectionPanel
                expanded={this.props.expanded}
                title={this.context.intl.messages['titles.create.summary.alternative-titles']}
                validationState={this.getAccordionValidationState(this.props.title, titleProps, TitleValidations)}
                localized={localized}
            >
                <FormSection>
                    <FormRow>
                        <LocalizedFormItem
                            attr="aka1"
                            label={this.context.intl.messages['titles.create.summary.aka-1']}
                            disabled={this.props.disabled}
                            max={50}
                            model={this.props.title}
                            setter={TitleActions.updateTitle}
                            type="text"
                            localized={localized}
                            localizedModel={this.props.titleLocalized}
                            localizedSetter={TitleLocalizedActions.update}
                        />
                        <LocalizedFormItem
                            attr="aka2"
                            label={this.context.intl.messages['titles.create.summary.aka-2']}
                            disabled={this.props.disabled}
                            max={50}
                            model={this.props.title}
                            setter={TitleActions.updateTitle}
                            type="text"
                            localized={localized}
                            localizedModel={this.props.titleLocalized}
                            localizedSetter={TitleLocalizedActions.update}
                        />
                    </FormRow>
                    <FormRow>
                        <LocalizedFormItem
                            attr="pka1"
                            label={this.context.intl.messages['titles.create.summary.pka-1']}
                            disabled={this.props.disabled}
                            max={50}
                            model={this.props.title}
                            setter={TitleActions.updateTitle}
                            type="text"
                            localized={localized}
                            localizedModel={this.props.titleLocalized}
                            localizedSetter={TitleLocalizedActions.update}
                        />
                        <LocalizedFormItem
                            attr="pka2"
                            label={this.context.intl.messages['titles.create.summary.pka-2']}
                            disabled={this.props.disabled}
                            max={50}
                            model={this.props.title}
                            setter={TitleActions.updateTitle}
                            type="text"
                            localized={localized}
                            localizedModel={this.props.titleLocalized}
                            localizedSetter={TitleLocalizedActions.update}
                        />
                    </FormRow>
                    <FormRow>
                        <LocalizedFormItem
                            attr="ika1"
                            label={this.context.intl.messages['titles.create.summary.ika-1']}
                            disabled={this.props.disabled}
                            max={50}
                            model={this.props.title}
                            setter={TitleActions.updateTitle}
                            type="text"
                            localized={localized}
                            localizedModel={this.props.titleLocalized}
                            localizedSetter={TitleLocalizedActions.update}
                        />
                        <LocalizedFormItem
                            attr="ika2"
                            label={this.context.intl.messages['titles.create.summary.ika-2']}
                            disabled={this.props.disabled}
                            max={50}
                            model={this.props.title}
                            setter={TitleActions.updateTitle}
                            type="text"
                            localized={localized}
                            localizedModel={this.props.titleLocalized}
                            localizedSetter={TitleLocalizedActions.update}
                        />
                    </FormRow>
                    <FormRow>
                        <LocalizedFormItem
                            attr="alternativeTitle1"
                            label={this.context.intl.messages['titles.create.summary.alternative-title-1']}
                            disabled={this.props.disabled}
                            max={50}
                            model={this.props.title}
                            setter={TitleActions.updateTitle}
                            type="text"
                            localized={localized}
                            localizedModel={this.props.titleLocalized}
                            localizedSetter={TitleLocalizedActions.update}
                        />
                        <LocalizedFormItem
                            attr="alternativeTitle2"
                            label={this.context.intl.messages['titles.create.summary.alternative-title-2']}
                            disabled={this.props.disabled}
                            max={50}
                            model={this.props.title}
                            setter={TitleActions.updateTitle}
                            type="text"
                            localized={localized}
                            localizedModel={this.props.titleLocalized}
                            localizedSetter={TitleLocalizedActions.update}
                        />
                    </FormRow>
                </FormSection>
            </SectionPanel>
        );
    }
}

export default WithValidations(AlternativeTitles);
