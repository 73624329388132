/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import QueryString from 'querystring-es3';
import React, {Component} from 'react';

import Summary from './summary/summary';
import RouteValidator from '../../common/custom-validator/route/route';
import DocumentTitle from '../../common/document-title';
import ListUsers from '../../common/list-users/list-users';
import {MODAL_TYPE} from '../../common/notification/modal';
import {NotificationActions} from '../../common/notification/notification-actions';
import {Tab, Tabs} from '../../common/routing-tab/routing-tab';
import {History, SideNotes} from '../../common/side-notes/side-notes';
import {Debounce} from '../../common/utils/utils';
import {AlertsWarnings} from '../../common/validations/alerts-warnings';
import ActionsMenu from '../../layout/actions-menu/actions-menu';
import StickyHeader from '../../layout/sticky-header/sticky-header';
import Preloader from '../../preloader/';
import PreloaderStore from '../../preloader/preloader-store';
import {RouterActions} from '../../router/router-actions';
import {ActionHistoryActions, ActionHistoryConstants} from '../../system/action-history/action-history-actions';
import SessionStore from '../../user/session/session-store';
import {HomepageActions} from '../homepage-actions';
import HomepageStore from '../homepage-store';


class Create extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            params: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired,
            route: RouteValidator // eslint-disable-line react/require-default-props
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            homepage: HomepageStore.getState().get('homepage'),
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible'),
            users: HomepageStore.getState().get('users')
        };
    }

    static getStores() {
        return [HomepageStore, PreloaderStore];
    }

    static getPermissions() {
        return {
            canCreate: SessionStore.canUser(SessionStore.PERMISSIONS.HOMEPAGE.CREATE),
            canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.HOMEPAGE.EDIT),
            canReadUsers: SessionStore.canUser(SessionStore.PERMISSIONS.HOMEPAGE.USERS.BROWSE),
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            activeTab: 0,
            size: 20,
            sortDirection: 'asc',
            sortFieldName: 'name'
        }, this.constructor.calculateState());

        this.addNote = this.addNote.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleLoadUsers = this.handleLoadUsers.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleSearchChange = Debounce(this.handleSearchChange.bind(this), 200);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.handleUsersPageChange = this.handleUsersPageChange.bind(this);
        this.isDirty = this.isDirty.bind(this);
        this.showDeleteDialog = this.showDeleteDialog.bind(this);
    }

    componentDidMount() {
        this.init(this.props);
        RouterActions.registerRedirectCheck(() => {
            return this.isDirty();
        });
        return;
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.route.mode === 'edit' && nextProps.route.mode === 'create') {
            this.init(nextProps);
        }
        RouterActions.registerRedirectCheck(() => {
            return this.isDirty();
        });
    }

    componentWillUpdate(nextProps) {
        if (this.props.location !== nextProps.location) {

            HomepageActions.getUsers(
                this.props.params.id,
                this.state.users.get('filters').toJS(),
                nextProps.location.query.offset,
                nextProps.location.query.size,
                nextProps.location.query['sort-field-name'],
                nextProps.location.query['sort-direction']
            );
        }
        return;
    }

    addNote(description) {
        ActionHistoryActions.addNote(this.props.params.id, description, ActionHistoryConstants.ACTION_OBJECTS.HOMEPAGE);
    }

    handleCancel() {
        RouterActions.redirect('/homepage', true);
    }

    handleLoadUsers() {
        HomepageActions.getUsers(
            this.props.params.id,
            this.state.users.get('filters').toJS(),
            this.props.location.query.offset,
            this.props.location.query.size,
            this.props.location.query['sort-field-name'],
            this.props.location.query['sort-direction']
        );
        return;
    }

    handleRemove() {
        HomepageActions.remove(this.props.params.id);
        return;
    }

    handleSave() {
        HomepageActions.save(this.state.homepage, HomepageStore.getState().get('originalHomepage'));
        return;
    }

    handleSearchChange(term) {
        HomepageActions.getUsers(
            this.props.params.id,
            {name: term},
            0,
            this.state.users.get('size')
        );
        return;
    }

    handleSortChange(sortFieldName, sortDirection) {
        this.setState({
            sortDirection: sortDirection,
            sortFieldName: sortFieldName
        });
        let query = Object.assign(
            {},
            this.props.location.query,
            {
                'sort-field-name': sortFieldName,
                'sort-direction': sortDirection,
                'keep-scroll': true
            }
        );

        RouterActions.redirect(`${this.props.location.pathname}?${QueryString.stringify(query)}`);
        return;
    }

    handleUsersPageChange(page) {
        // page x this.state.size = offset.
        let query = Object.assign(
            {},
            this.props.location.query,
            {
                'offset': page*this.state.size,
                'size': this.state.size
            }
        );

        RouterActions.redirect(`${this.props.location.pathname}?${QueryString.stringify(query)}`);

        return;
    }

    init(props) {
        switch (props.route.mode) {
        case 'edit':
            HomepageActions.findById(props.params.id);
            break;
        case 'create':
            HomepageActions.clear();
            break;
        }
    }

    isDirty() {
        if (this.props.route.mode === 'create') {
            return false;
        }
        return !this.state.homepage.equals(HomepageStore.getState().get('originalHomepage'));
    }

    showDeleteDialog() {
        NotificationActions.show(
            MODAL_TYPE.DANGER,
            this.context.intl.messages['homepages.edit.confirm-delete'],
            this.context.intl.messages['homepages.edit.confirm-delete.body'],
            this.context.intl.messages['homepages.edit.confirm-delete.confirm'],
            this.handleRemove
        );
    }

    render() {
        let actionsMenu;
        let disableForm = true;
        let eventsAndNotes = null;
        let historyTab = null;
        let mode = this.props.route.mode;
        let usersTab;

        const validations = HomepageStore.getValidations();
        let baseRoute = `/homepage/${this.props.params.id}`;
        if (mode === 'create') {
            baseRoute = '/homepage';
            disableForm = false;

            actionsMenu = <ActionsMenu
                canSave={!validations.length}
                onCancel={this.handleCancel}
                onSave={this.handleSave}
                saveText={this.context.intl.messages['homepages.create.button']}
            />;
        }

        if (mode === 'edit') {
            if (this.props.permissions.canEdit) {
                disableForm = false;
            }

            actionsMenu = (
                <ActionsMenu
                    canDelete={this.props.permissions.canEdit}
                    canEdit={this.props.permissions.canEdit}
                    canSave={!validations.length && this.props.permissions.canEdit}
                    onDelete={this.showDeleteDialog}
                    onCancel={this.handleCancel}
                    onSave={this.handleSave}
                />
            );

            eventsAndNotes = (
                <SideNotes
                    basePath={`/homepage/${this.props.params.id}`}
                    dialogMessage={this.context.intl.messages['common.side-notes.add-note.body']}
                    disabled={ !this.props.permissions.canEdit }
                    notes={this.state.homepage.get('history').toJS().slice(0, 4)}
                    onAddNote={this.addNote}
                    title={this.context.intl.messages['common.side-notes.title']}
                />
            );

            historyTab = <Tab route={`${baseRoute}/history`} title={this.context.intl.messages['common.tab-title.history']} tabClassName="pull-right">
                <History
                    actionObject={ActionHistoryConstants.ACTION_OBJECTS.HOMEPAGE}
                    id={this.props.params.id}
                    pagination
                    pathname={this.props.location.pathname}
                    query={this.props.location.query}/>
            </Tab>;

            if (this.props.permissions.canReadUsers) {
                usersTab = <Tab route={`${baseRoute}/users`} title={this.context.intl.messages['common.tab-title.users']}>
                    <ListUsers
                        activePage={Math.ceil(this.state.users.get('offset')/this.state.users.get('size'))}
                        columns={['name', 'company', 'email', 'status', 'userGroup']}
                        displayLinks={this.props.permissions.canReadUsers}
                        onLoadPage={this.handleLoadUsers}
                        onPageChange={this.handleUsersPageChange}
                        onSearchChange={this.handleSearchChange}
                        onSortChange={this.handleSortChange}
                        sortDirection={this.state.sortDirection}
                        sortFieldName={this.state.sortFieldName}
                        totalPages={Math.ceil(this.state.users.get('total')/this.state.users.get('size'))}
                        users={this.state.users.get('results')}
                    />
                </Tab>;
            }
        }

        let summaryTab = <Tab route={baseRoute} title={this.context.intl.messages['titles.create.summary-tab-title']}>
            <Summary disabled={disableForm} homepage={this.state.homepage} mode={this.props.route.mode}/>
        </Tab>;

        let entityName;

        if (this.state.homepage.get('name')) {
            entityName = this.state.homepage.get('name');
        }

        return (
            <DocumentTitle
                message={`document-titles.${mode}-homepage`} entityName={entityName}
            >
                <div>
                    <StickyHeader>
                        <div className="col-md-6">
                            <h1>
                                <i className="far fa-home"></i>&nbsp;{this.context.intl.messages[`homepages.${mode}.title`]}
                                &nbsp;
                            </h1>
                        </div>
                        {actionsMenu}
                    </StickyHeader>
                    <Preloader show={this.state.preloaderVisible} fixed loadingDots>
                        <section className="content" id="contentContainer">
                            <div className="row">
                                <div className="col-lg-9">
                                    <Tabs location={this.props.location}>
                                        {summaryTab}
                                        {usersTab}
                                        {historyTab}
                                    </Tabs>
                                </div>
                                <div className="col-lg-3">
                                    <AlertsWarnings
                                        title={this.context.intl.messages['common.alerts-warnings-title']}
                                        validations={validations}
                                    />
                                    {eventsAndNotes}
                                </div>
                            </div>
                        </section>
                    </Preloader>
                </div>
            </DocumentTitle>
        );
    }

}

export default Container.create(Create);
