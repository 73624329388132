/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

import Permissions from './permissions';
import Summary from './summary';
import Users from './users';
import RouteValidator from '../../common/custom-validator/route/route';
import DocumentTitle from '../../common/document-title';
import {MODAL_TYPE} from '../../common/notification/modal';
import {NotificationActions} from '../../common/notification/notification-actions';
import {Tab, Tabs} from '../../common/routing-tab/routing-tab';
import {History, SideNotes} from '../../common/side-notes/side-notes';
import {AlertsWarnings} from '../../common/validations/alerts-warnings';
import ActionsMenu from '../../layout/actions-menu/actions-menu';
import StickyHeader from '../../layout/sticky-header/sticky-header';
import Preloader from '../../preloader/';
import PreloaderStore from '../../preloader/preloader-store';
import {RouterActions} from '../../router/router-actions';
import {PermissionActions} from '../../security/permission/permission-actions';
import PermissionStore from '../../security/permission/permission-store';
import {RoleActions} from '../../security/role/role-actions';
import RoleStore from '../../security/role/role-store';
import {ActionHistoryActions, ActionHistoryConstants} from '../../system/action-history/action-history-actions';
import SessionStore from '../../user/session/session-store';


class Create extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            params: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired,
            /**
             * Validate custom props inside the route object.
             */
            route: RouteValidator // eslint-disable-line react/require-default-props
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            APIPermissions: PermissionStore.getState().get('API'),
            basePermission:RoleStore.getState().get('basePermission'),
            CMSPermissions: PermissionStore.getState().get('CMS'),
            FYCPermissions: PermissionStore.getState().get('FYC'),
            originalRole:RoleStore.getState().get('originalRole'),
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible'),
            role:RoleStore.getState().get('role'),
            selectedPermission:RoleStore.getState().get('selectedPermission'),
            WBTVDPermissions: PermissionStore.getState().get('WBTVD')
        };
    }

    static getStores() {
        return [PermissionStore, PreloaderStore, RoleStore];
    }

    static getPermissions() {
        return {
            canCreate: SessionStore.canUser(SessionStore.PERMISSIONS.ROLES.CREATE),
            canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.ROLES.EDIT),
            users: {
                canRead: SessionStore.canUser(SessionStore.PERMISSIONS.ROLES.USERS.READ),
                canRemove: SessionStore.canUser(SessionStore.PERMISSIONS.ROLES.USERS.REMOVE)
            }
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            activeTab: 0
        }, this.constructor.calculateState());

        this.addNote = this.addNote.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleConfirmDuplicate = this.handleConfirmDuplicate.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleDuplicate = this.handleDuplicate.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.isDirty = this.isDirty.bind(this);
        this.showDeleteDialog = this.showDeleteDialog.bind(this);
    }

    componentDidMount() {
        // Initialize function/permission checkboxtron
        PermissionActions.getPermissionTree();
        this.init(this.props);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.route.mode !== nextProps.route.mode ||
            this.props.params.id !== nextProps.params.id
        ) {
            this.init(nextProps);
        }
    }

    addNote(description) {
        ActionHistoryActions.addNote(this.props.params.id, description, ActionHistoryConstants.ACTION_OBJECTS.ROLE);
    }

    handleCancel() {
        RouterActions.redirect('/roles', true);
    }

    handleConfirmDuplicate() {
        RoleActions.duplicateRole(this.props.params.id);
    }

    handleDelete() {
        RoleActions.remove(this.props.params.id);
        return;
    }

    handleDuplicate() {
        NotificationActions.show(
            MODAL_TYPE.APPROVE,
            this.context.intl.messages['roles.create.duplicate-modal.title'],
            this.context.intl.messages['roles.create.duplicate-modal.body'],
            this.context.intl.messages['roles.create.duplicate-modal.title'],
            this.handleConfirmDuplicate
        );
    }

    handleSave(event) {
        event.preventDefault();
        let toAdd = this.state.selectedPermission.subtract(this.state.basePermission);
        let toRemove = this.state.basePermission.subtract(this.state.selectedPermission);
        RoleActions.saveRole(this.props.params.id, RoleStore.getState().get('role'), toAdd, toRemove);
    }

    init(props) {
        if (props.route.mode === 'edit') {
            RoleActions.findById(props.params.id);
            RoleActions.getRolePermissions(props.params.id);
        } else {
            RoleActions.clear();
        }
        RouterActions.registerRedirectCheck(() => {
            return this.isDirty();
        });
    }

    isDirty() {
        if (this.props.route.mode === 'create') {
            return false;
        }

        /*return !this.state.role.equals(this.state.originalRole) ||
        !this.state.selectedPermission.equals(this.state.basePermission);*/

        let role = this.state.role;
        let originalRole = this.state.originalRole;
        return !role.equals(originalRole);
    }

    showDeleteDialog(event) {
        event.preventDefault();

        NotificationActions.show(
            MODAL_TYPE.DANGER,
            this.context.intl.messages['roles.edit.confirm-delete.title'],
            this.context.intl.messages['roles.edit.confirm-delete.body'],
            this.context.intl.messages['roles.edit.confirm-delete.confirm'],
            this.handleDelete
        );
    }

    render() {
        let actionsMenu;
        let mode = this.props.route.mode;
        let pageTitle = this.context.intl.messages['roles.edit.title'];
        let pageDescription = this.context.intl.messages['roles.edit.description'];
        let eventsAndNotes = null;
        let disableForm = true;
        let validations = RoleStore.getValidations();
        let historyTab = null;
        let usersTab = null;
        let baseRoute = `/roles/${this.props.params.id}`;

        if (mode === 'create') {
            baseRoute = '/roles/create';
            actionsMenu = <ActionsMenu
                canSave={!validations.length}
                onCancel={this.handleCancel}
                onSave={this.handleSave}
                saveText={this.context.intl.messages['roles.create.save']}
            />;

            disableForm = false;
            pageTitle = this.context.intl.messages['roles.create.title'];
            pageDescription = this.context.intl.messages['roles.create.description'];
        } else {
            // edit
            if (this.props.permissions.canEdit) {
                disableForm = false;
            }

            actionsMenu = <ActionsMenu
                canEdit={this.props.permissions.canEdit}
                canDelete={this.props.permissions.canEdit && !this.state.originalRole.get('isActive')}
                canSave={!validations.length && this.props.permissions.canEdit}
                onCancel={this.handleCancel}
                onDelete={this.showDeleteDialog}
                onSave={this.handleSave}
            >
                <Button
                    bsSize="large"
                    className="btn btn-primary-outline Mr(3px) Mb(3px)"
                    disabled={!this.props.permissions.canEdit}
                    onClick={this.handleDuplicate}
                >
                    <i className="fas fa-copy"/>&nbsp;{this.context.intl.messages['common.duplicate']}
                </Button>
            </ActionsMenu>;

            if (this.props.permissions.users.canRead) {
                usersTab = (
                    <Tab
                        id="users"
                        route={`/roles/${this.props.params.id}/users`}
                        title={this.context.intl.messages['roles.edit.users-tab-title']}
                    >
                        <Users
                            disableRemove={!this.props.permissions.users.canRemove}
                            displayLinks={this.props.permissions.users.canRead}
                            location={this.props.location}
                            roleId={this.props.params.id}
                        />
                    </Tab>
                );
            }
            eventsAndNotes = (
                <SideNotes
                    basePath={`roles/${this.props.params.id}`}
                    dialogMessage={this.context.intl.messages['common.side-notes.add-note.body']}
                    disabled={ !this.props.permissions.canEdit }
                    notes={this.state.role.get('history').toJS()}
                    onAddNote={this.addNote}
                    title={this.context.intl.messages['common.side-notes.title']}
                />
            );
            historyTab = (
                <Tab
                    id="history"
                    route={`/roles/${this.props.params.id}/history`}
                    tabClassName="pull-right"
                    title={this.context.intl.messages['common.tab-title.history']}
                >
                    <History
                        actionObject={ActionHistoryConstants.ACTION_OBJECTS.ROLE}
                        id={this.props.params.id}
                        pagination
                        pathname={this.props.location.pathname}
                        query={this.props.location.query}/>
                </Tab>
            );
        }

        let permissionsTab = (
            <Tab
                id="permissions"
                route={`${baseRoute}/permissions`}
                title={this.context.intl.messages['roles.edit.permissions-tab-title']}
            >
                <Permissions
                    disabled={disableForm}
                    APIPermissions={this.state.APIPermissions}
                    CMSPermissions={this.state.CMSPermissions}
                    WBTVDPermissions={this.state.WBTVDPermissions}
                    FYCPermissions={this.state.FYCPermissions}
                />
            </Tab>
        );
        let summaryTab = (
            <Tab
                id="summary"
                route={baseRoute}
                title={this.context.intl.messages['roles.edit.summary-tab-title']}
            >
                <Summary mode={mode} disabled={disableForm}/>
            </Tab>
        );

        let entityName;

        if (this.state.role.get('name')) {
            entityName = this.state.role.get('name');
        }

        return (
            <DocumentTitle
                message={`document-titles.${mode}-roles`} entityName={entityName}
            >
                <div>
                    <StickyHeader>
                        <div className="col-md-6">
                            <h1>
                                <i className={ClassNames('fas fa-male')}></i>
                                &nbsp;{pageTitle}&nbsp;
                            </h1>
                            <p className="padding-top-20">
                                <em>{pageDescription}</em>
                            </p>
                        </div>
                        {actionsMenu}
                    </StickyHeader>
                    <Preloader show={this.state.preloaderVisible} fixed loadingDots>
                        <section className="content" id="contentContainer">
                            <div className="row">
                                <div className="col-lg-9">
                                    <Tabs location={this.props.location}>
                                        {summaryTab}
                                        {permissionsTab}
                                        {usersTab}
                                        {historyTab}
                                    </Tabs>
                                </div>
                                <div className="col-lg-3">
                                    <AlertsWarnings
                                        title={this.context.intl.messages['common.alerts-warnings-title']}
                                        validations={validations}
                                    />
                                    {eventsAndNotes}
                                </div>
                            </div>
                        </section>
                    </Preloader>
                </div>
            </DocumentTitle>
        );
    }

}

export default Container.create(Create);
