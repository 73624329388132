/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ControlLabel, FormGroup} from 'react-bootstrap';
import ReactSelect from 'react-select';

import {AccountWizardActions, AccountWizardConstants} from './account-wizard-actions';
import AccountWizardStore from './account-wizard-store';
import UserList from './user-list';

import {FormItem, FormRow, FormSection} from '~/src/common/form/form';
import Partners from '~/src/partner/partners';
import {RouterActions} from '~/src/router/router-actions';

class Summary extends Component {

    static get propTypes() {
        return {
            params: PropTypes.object.isRequired,
            readOnly: PropTypes.bool.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            accountWizardProcess: AccountWizardStore.getState().get('accountWizardProcess'),
            clientRepGroups: AccountWizardStore.getState().get('clientRepGroups'),
            contactUser: AccountWizardStore.getState().get('contactUser'),
            defaultPartner: AccountWizardStore.getState().get('defaultPartner'),
            partners: AccountWizardStore.getState().get('partners'),
            selectedClientRepGroupIds:  AccountWizardStore.getState().get('selectedClientRepGroupIds'),
            users: AccountWizardStore.getState().get('users'),
            userRowValidationsAll: AccountWizardStore.getState().getIn(['userRowValidations', 'all']),
        };
    }

    static getStores() {
        return [AccountWizardStore];
    }

    constructor(props) {
        super(props);

        this.handleClientRepGroupChange = this.handleClientRepGroupChange.bind(this);
        this.handleSelectPartners = this.handleSelectPartners.bind(this);
        this.handleSelectPrimaryPartner = this.handleSelectPrimaryPartner.bind(this);
    }

    componentDidMount() {
        AccountWizardActions.getClientReps(0, 9999);
        return;
    }

    handleCancel() {
        RouterActions.back();
    }

    handleClientRepGroupChange(value) {
        AccountWizardActions.setClientRepGroups(value);
        return;
    }

    handleSelectPartners(partners) {
        if (!partners) {
            AccountWizardActions.setPartners(Immutable.OrderedSet());
            return;
        }

        partners = partners.map(p => {
            // partnerId must be added prior to first save to db
            if (!p.partnerId) {
                p.partnerId = p.id;
            }
            return p;
        });

        AccountWizardActions.setPartners(Immutable.fromJS(partners));
    }

    handleSelectPrimaryPartner(defaultPartner) {
        if (!defaultPartner) {
            AccountWizardActions.setDefaultPartner({});
            return;
        }
        AccountWizardActions.setDefaultPartner(defaultPartner);
    }

    render() {
        const selectedPartners = this.state.partners;
        const defaultPartner = this.state.defaultPartner;
        let selectedClientRepGroups = [];
        if (this.state.selectedClientRepGroupIds) {
            selectedClientRepGroups = this.state.selectedClientRepGroupIds.toJS();
        }

        let clientRepOptions = this.state.clientRepGroups.toJS();
        if (selectedClientRepGroups.length) {
            clientRepOptions = this.state.clientRepGroups.toJS().reduce((prevData, currentData) => {
                if (!prevData.map(data => data.id).includes(currentData.id)) {
                    return prevData.concat([currentData]);
                }
                return prevData;
            }, []);
        }

        let batchInfoRow = [
            <FormGroup key="batch-name">
                <FormItem attr="name"
                    disabled={this.props.readOnly}
                    label={<span>{this.context.intl.messages['accounts.account-wizard.batch-name.title']}</span>}
                    legend={this.context.intl.messages['accounts.account-wizard.batch-name.caption']}
                    model={this.state.accountWizardProcess}
                    setter={AccountWizardActions.setBatchName}
                    type="text"
                />
            </FormGroup>,
            <FormGroup key="batch-contact">
                <ControlLabel>{this.context.intl.messages['accounts.account-wizard.contact-client-rep-group.title']}&nbsp;<span className="text-red">*</span></ControlLabel>
                <ReactSelect
                    getOptionLabel={data => {
                        if (selectedClientRepGroups.indexOf(data) > 0 ||
                        (selectedClientRepGroups.indexOf(data) === -1 && selectedClientRepGroups.length)) {
                            return data.name;
                        }

                        let contactFirstName = data.contactFirstName;
                        let contactLastName = data.contactLastName;
                        if (this.state.contactUser) {
                            contactFirstName = this.state.contactUser.get('name');
                            contactLastName = this.state.contactUser.get('lastName');
                        }
                        return `${contactFirstName} ${contactLastName} | ${data.name}`;
                    }}
                    getOptionValue={data => data.id}
                    isClearable={true}
                    isDisabled={this.props.readOnly}
                    isMulti={true}
                    onChange={this.handleClientRepGroupChange}
                    options={clientRepOptions}
                    required={true}
                    value={selectedClientRepGroups}
                />
                <small>
                    <em>{this.context.intl.messages['accounts.account-wizard.contact-client-rep-group.caption']}</em>
                </small>
            </FormGroup>
        ];

        let userTable;

        let status = this.context.intl.messages['common.na'];
        if (this.state.accountWizardProcess.get('status')) {
            status = this.state.accountWizardProcess.get('status');
            const batchStatusType = AccountWizardStore.getStatusType(status);

            const processed = this.state.users.filter(u => u.get('status') === 'COMPLETE').size;
            const total = this.state.users.size;

            if (batchStatusType) {
                const description = this.context.intl.messages[batchStatusType.description];
                const tooltip = this.context.intl.messages[batchStatusType.tooltipEditBatch];
                let append = `(${processed}/${total})`;
                if (batchStatusType === AccountWizardConstants.BATCH_STATUS_TYPES.CONTAINS_ERRORS) {
                    const errorCount = AccountWizardStore.getUserRowValidationsWithSeverity('ERROR');
                    append = `(${errorCount} user rows)`;
                }
                status = <span className="parcel-tooltip">
                    <i title={tooltip} className={batchStatusType.icon} />&nbsp;<span className="hidden-xs hidden-sm">{description}&nbsp;{append}</span>
                    <span className="tooltip-inner">{tooltip}</span>
                </span>;
            }

            userTable = <UserList
                batchId={this.props.params.id}
                batchStatusType={batchStatusType}
                readOnly={this.props.readOnly}
                users={this.state.users}
                userRowValidationsAll={this.state.userRowValidationsAll}
            />;
        }

        batchInfoRow.push(
            <FormGroup key="batch-status">
                <ControlLabel>{this.context.intl.messages['accounts.account-wizard.status']}</ControlLabel>
                <p>{status}</p>
            </FormGroup>
        );

        return (
            <div>
                <FormSection title={this.context.intl.messages['accounts.account-wizard.batch-info']} iconClass="fa-regular fa-info-circle">
                    <FormRow>
                        {batchInfoRow}
                    </FormRow>
                </FormSection>
                <div>
                    <hr/>
                    <Partners
                        defaultPartner={defaultPartner}
                        disabled={this.props.readOnly}
                        onSelectPrimaryPartner={this.handleSelectPrimaryPartner}
                        onSelectPartners={this.handleSelectPartners}
                        partners={selectedPartners}
                        required={true}
                    />
                </div>

                <div>
                    <hr />
                    {userTable}
                </div>
            </div>
        );
    }
}

export default Container.create(Summary);
