/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {FormGroup} from 'react-bootstrap';

import NominationTable from './nomination-table';

import {FormRow} from '~/src/common/form/form';

class NominationInfo extends React.Component {

    static get propTypes() {
        return {
            nominationInfo: PropTypes.instanceOf(Immutable.Map),
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            nominationInfo: Immutable.Map(),
        };
    }

    render() {
        return (
            <div className="event-presentation">
                <h3><i className="fa-regular fa-trophy-star"></i>&nbsp;{this.context.intl.messages['events.presentation.nomination-info']}</h3>
                <p>
                    <small>
                        <em>
                            {this.context.intl.messages['events.presentation.nomination-info.description']}
                        </em>
                    </small>
                </p>
                <FormRow>
                    <FormGroup md={6}>
                        <NominationTable
                            columnNumber="1"
                            nominationList={this.props.nominationInfo.get('1')}
                            title="events.presentation.nomination-info.column1"
                        />
                    </FormGroup>
                    <FormGroup md={6}>
                        <NominationTable
                            columnNumber="2"
                            nominationList={this.props.nominationInfo.get('2')}
                            title="events.presentation.nomination-info.column2"
                        />
                    </FormGroup>
                </FormRow>
            </div>
        );
    }
}

export default NominationInfo;
