/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {TitleStatusConstants} from './title-status-actions';
import Dispatcher from '../dispatcher/dispatcher';

class TitleStatusStore extends ReduceStore {

    getInitialState() {
        return Immutable.fromJS({
            field: {
                values: []
            },
            show: false,
            title: {},
            titles: [],
            total: 0
        });
    }

    reduce(state, action) {
        let titleIndex;

        switch (action.actionType) {

        case TitleStatusConstants.CLEAR:
        case TitleStatusConstants.GET.START:
            state = this.getInitialState();
            break;

        case TitleStatusConstants.GET.SUCCESS:
            state = state.merge({
                titles: action.titles,
                total: action.total
            });
            break;

        case TitleStatusConstants.HIDE:
            state = state.set('show', false);
            break;

        case TitleStatusConstants.SAVE.SUCCESS:
            titleIndex = state.get('titles').findIndex(t => t.get('id') === action.titleId);
            state = state.setIn(['titles', titleIndex, 'fieldStatusValues'], action.updatedStatus);
            break;

        case TitleStatusConstants.SET_ACTIVE.SUCCESS:
            titleIndex = state.get('titles').findIndex(t => t.get('id') === action.title.get('id'));
            state = state.setIn(['titles', titleIndex, 'active'], action.title.get('active'));
            break;

        case TitleStatusConstants.SHOW:
            state = state.merge({
                field: action.field,
                show: true,
                title: action.title
            });
            break;
        }
        return state;
    }
}

let store = new TitleStatusStore(Dispatcher);

export default store;
