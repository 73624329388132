/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';

import {AlertTypes} from '../../common/notification/alert';
import {NotificationActions} from '../../common/notification/notification-actions';
import {CompareImmutableList, SubtractImmutableSet} from '../../common/utils/utils';
import Dispatcher from '../../dispatcher/dispatcher';
import Request from '../../request';

const CONSTANTS = {
    ADD: 'asset_catalog_actions.add',
    CLEAR: 'asset_catalog_actions.clear',
    FINDBY: {
        SUCCESS: 'asset_catalog_actions.findby.success'
    },
    GET: {
        SUCCESS: 'asset_catalog_actions.get.success'
    },
    REMOVE: 'asset_catalog_actions.remove',
    REMOVE_BY_ID: 'asset_catalog_actions.remove_by_id',
    TARGET_COPY_ASSET: {
        SUCCESS: 'asset_catalog_actions.target_copy_asset.success',
        CLEAR: 'asset_catalog_actions.target_copy_asset.clear',
    },
};

class AssetCatalogActions {

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR,
        });
    }

    clearSelectedCopyTarget() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TARGET_COPY_ASSET.CLEAR,
        });
    }

    add(catalog) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ADD,
            catalog: catalog
        });
    }

    copyCatalogs(targetAssetId, targetCopyCatalogs, assignedCatalogs, isEventCatalog) {
        const toRemoveCatalogs = targetCopyCatalogs.reduce((toRemoveList, toRemoveCatalog) => {
            if (isEventCatalog) {
                if (toRemoveCatalog.get('name').toLowerCase().startsWith('event:')) {
                    toRemoveList.push(Request.del(`asset/${targetAssetId}/catalog/${toRemoveCatalog.get('id')}`).exec());
                }
            } else {
                if (!toRemoveCatalog.get('name').toLowerCase().startsWith('event:')) {
                    toRemoveList.push(Request.del(`asset/${targetAssetId}/catalog/${toRemoveCatalog.get('id')}`).exec());
                }
            }
            return toRemoveList;
        }, []);

        Promise.all(toRemoveCatalogs).spread( () => {
            const toAddCatalogs = assignedCatalogs.reduce((toAddList, toAddCatalog) => {
                if (isEventCatalog) {
                    if (toAddCatalog.get('name').toLowerCase().startsWith('event:')) {
                        toAddList.push(Request.post(`asset/${targetAssetId}/catalog/${toAddCatalog.get('id')}`).exec());
                    }
                } else {
                    if (!toAddCatalog.get('name').toLowerCase().startsWith('event:')) {
                        toAddList.push(Request.post(`asset/${targetAssetId}/catalog/${toAddCatalog.get('id')}`).exec());
                    }
                }
                return toAddList;
            }, []);

            Promise.all(toAddCatalogs).spread( () => {
                NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'asset.edit.catalogs-tab.copy-catalogs.success');
            }).catch(() => {
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'asset.edit.catalogs-tab.copy-catalogs.failure');
            });
        }).catch( () => {
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'asset.edit.catalogs-tab.copy-catalogs.failure');
        });
    }

    // get asset catalogs by assetId for the selected target of copy
    findTargetAssetByAssetId(assetId) {
        Request.get(`asset/${assetId}/catalog`)
            .exec()
            .then(res => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.TARGET_COPY_ASSET.SUCCESS,
                    targetCopyCatalogs: Immutable.fromJS(res.body).sortBy(g => g.get('name')),
                    targetAssetId: assetId
                });
                return;
            })
            .catch(err => {
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'asset.edit.catalogs-tab.copy-catalogs.error');
                throw err;
            });
        return;
    }

    // get asset catalogs by assetId
    findByAssetId(assetId) {
        Request.get(`asset/${assetId}/catalog`)
            .exec()
            .then(res => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.FINDBY.SUCCESS,
                    catalogs: Immutable.fromJS(res.body).sortBy(g => g.get('name')),
                    assetId: assetId
                });

                return;
            })
            .catch(err => {
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
                throw err;
            });
        return;
    }

    // get asset catalogs
    get() {
        Request.get('security/group').query({
            'category-id': 2,
            offset: 0,
            size: 999999
        }).exec().then(res => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.SUCCESS,
                catalogs: Immutable.fromJS(res.body.results).sortBy(g => g.get('name')),
            });
            return;
        });
        return;
    }

    remove(index) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.REMOVE,
            index: index
        });
    }

    removeById(id) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.REMOVE_BY_ID,
            id: id,
        });
        return;
    }

    /**
     * Saves list of catalogs to an asset
     * @param {*} assetId
     * @param {*} assignedCatalogs
     * @param {*} originalAssignedCatalogs
     */
    assetCatalogSave(assetId, assignedCatalogs, originalAssignedCatalogs) {
        if (CompareImmutableList(assignedCatalogs, originalAssignedCatalogs, ['id'])) {
            return [];
        }
        let originalSet = originalAssignedCatalogs.toSet();
        let newSet = assignedCatalogs.toSet();
        let toRemove = SubtractImmutableSet(originalSet, newSet, ['id']);
        let toAdd = SubtractImmutableSet(newSet, originalSet, ['id']);
        let ops = [];
        // Remove
        ops = ops.concat(toRemove.map(t => Request.del(`asset/${assetId}/catalog/${t.get('id')}`).exec()).toArray());
        // add
        ops = ops.concat(toAdd.map(t => Request.post(`asset/${assetId}/catalog/${t.get('id')}`).exec()).toArray());
        return ops;
    }

    /**
     * Save list of assets to a catalog
     * @param {*} catalogId
     * @param {*} assignedAssets
     * @param {*} originalAssignedAssets
     */
    catalogAssetsSave(catalogId, assignedAssets, originalAssignedAssets) {
        if (CompareImmutableList(assignedAssets, originalAssignedAssets, ['id'])) {
            return [];
        }
        let originalSet = originalAssignedAssets.toSet();
        let newSet = assignedAssets.toSet();
        let toRemove = SubtractImmutableSet(originalSet, newSet, ['id']);
        let toAdd = SubtractImmutableSet(newSet, originalSet, ['id']);

        let ops = [];
        // Remove
        ops = ops.concat(toRemove.map(asset => Request.del(`asset/${asset.get('id')}/catalog/${catalogId}`).exec()).toArray());
        // add
        ops = ops.concat(toAdd.map(asset => Request.post(`asset/${asset.get('id')}/catalog/${catalogId}`).exec()).toArray());
        return ops;
    }
}

let actions = new AssetCatalogActions();

export {
    actions as AssetCatalogActions,
    CONSTANTS as AssetCatalogConstants
};
