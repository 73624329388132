/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';

import {AssetTabActions} from '../../assets-tab/asset-tab-actions';
import {AlertTypes} from '../../common/notification/alert';
import {NotificationActions} from '../../common/notification/notification-actions';
import {SlidingPanelActions} from '../../common/sliding-panel/sliding-panel-actions';
import {GetEncodedHTML, UploadToAmazon} from '../../common/utils/utils';
import Dispatcher from '../../dispatcher/dispatcher';
import {PreloaderActions} from '../../preloader/preloader-actions';
import Request from '../../request';
import {RouterActions} from '../../router/router-actions';
import {ActionHistoryConstants} from '../../system/action-history/action-history-actions';
import {AssetTalentActions} from '../asset-talent-actions';
import {AssetTitleActions} from '../asset-title-actions';
import {AssetCatalogActions} from '../catalogs/asset-catalog-actions';

const CONSTANTS = {
    IMAGE: {
        CLEAR: 'asset.image.clear',
        GET: {
            SUCCESS: 'asset.image.get.success',
            ERROR: 'asset.image.get.error',
            START: 'asset.image.get.start'
        },
        SAVE: {
            ERROR: 'asset.image.save.error',
            START: 'asset.image.save.start',
            SUCCESS: 'asset.image.save.success'
        },
        UPDATE: 'asset.image.update',
        RE_FETCH_COMPLETE: 'asset.image.re-fetch-complete'
    },
    IMAGE_TYPES: {
        KEY_ART: {
            id: 1,
            name: 'Key Art',
            subType: {
                ART_PROMO: {id: 1, name: 'Art - Promo', imageSuperType: 1},
                BOX_ART: {id: 2, name: 'Box Art', imageSuperType: 1},
                KEY_ART: {id: 3, name: 'Key Art', imageSuperType: 1},
                KEY_ART_INTERNAITONAL_VERSION: {id: 4, name: 'Key Art - International Version', imageSuperType: 1},
                KEY_ART_TELEVISION_SERIES: {id: 5, name: 'Key Art - Television Series', imageSuperType: 1},
                KET_ART_TEXTED: {id: 6, name: 'Key Art - Texted', imageSuperType: 1},
                KEY_ART_TEXTLESS: {id: 7, name: 'Key Art - Textless', imageSuperType: 1},
                KEY_ART_TITLED: {id: 8, name: 'Key Art - Titled', imageSuperType: 1},
                KEY_ART_WITH_TITLE: {id: 9, name: 'Key Art - With Title Treatment', imageSuperType: 1},
                VIDEO_BOX_ART: {id: 10, name: 'Video Box Art', imageSuperType: 1},
            }
        },
        OUTDOOR_ART: {
            id: 2,
            name: 'Outdoor Art',
            subType: {
                KISOK_ART: {id: 11, name: 'Kiosk Art', imageSuperType: 2},
                BILLBOARD: {id: 12, name: 'Billboard', imageSuperType: 2},
            }
        },
        ONLINE: {
            id: 3,
            name: 'Online',
            subType: {
                BANNER: {id: 13, name: 'Banner', imageSuperType: 3},
                WEB_SITE_OTHER: {id: 15, name: 'Web Site - Other', imageSuperType: 3},
                WEB_SITE_WBITV: {id: 16, name: 'Web Site - WBITV', imageSuperType: 3},
                WEB_SITE_WBITV_B2B: {id: 17, name: 'Web Site - WBITV B2B', imageSuperType: 3}
            }
        },
        LOGO: {
            id: 4,
            name: 'Logo',
            subType: {
                LOGO_ANIMATION: {id: 18, name: 'Logo - Animation', imageSuperType: 4},
                LOGO_MOVIDE_OF_WEEK: {id: 19, name: 'Logo - Movie Of The Week', imageSuperType: 4},
                LOGO_NETWORK_STATION: {id: 20, name: 'Logo - Network/Station', imageSuperType: 4},
                LOGO_PRODUCTION_COMPANY: {id: 21, name: 'Logo - Production Company', imageSuperType: 4},
                LOGO_SERIES: {id: 22, name: 'Logo - Series', imageSuperType: 4},
                LOGO_THEATRICAL: {id: 23, name: 'Logo - Theatrical', imageSuperType: 4},
                LOGO_WB_DIVISION: {id: 24, name: 'Logo - WB Division', imageSuperType: 4},
                TITLE_TREATMENT_ON_AIR: {id: 25, name: 'Title Treatment - On-air', imageSuperType: 4},
                TITLE_TREATMENT_PRINT: {id: 26, name: 'Title Treatment - Print', imageSuperType: 4}
            }
        },
        PHOTOGRAPHY_STILLS: {
            id: 5,
            name: 'Photography/Stills',
            subType: {
                PHOTOGRAPHY: {id: 27, name: 'Photography', imageSuperType: 5},
                UNIT: {id: 28, name: 'Unit', imageSuperType: 5},
                EPISODIC: {id: 29, name: 'Episodic', imageSuperType: 5},
                ANIMATED_STILLS: {id: 30, name: 'Animated Stills', imageSuperType: 5}
            }
        },
        GALLERY: {
            id: 6,
            name: 'Gallery',
            subType: {
                GALLERY: {id: 31, name: 'Gallery', imageSuperType: 6},
                GALLERY_CAST: {id: 32, name: 'Gallery - Cast', imageSuperType: 6},
                GALLERY_MUTIPLE: {id: 33, name: 'Gallery - Multiple', imageSuperType: 6},
                GALLERY_SINGLE: {id: 34, name: 'Gallery - Single', imageSuperType: 6},
                HEADSHOTS: {id: 35, name: 'Headshots', imageSuperType: 6},
                SET: {id: 36, name: 'Set', imageSuperType: 6},
            }
        },
        SVOD: {
            id: 7,
            name: 'SVOD',
            subType: {
                SVOD_VERTICAL_DISPLAY_ART: {id: 37, name: 'SVOD vertical display art', imageSuperType: 7},
                SVOD_HORIZONTAL_DISPLAY_ART: {id: 38, name: 'SVOD horizontal display art', imageSuperType: 7},
                STORY_ART: {id: 39, name: 'Story Art', imageSuperType: 7}
            }
        },
        CLIENT_SAMPLES: {
            id: 8,
            name: 'Client Samples/ Created',
            subType: {
                CLIENT_CREATED: {id: 40, name: 'Client-created', imageSuperType: 8}
            }
        },
        ANIMATION_PRODUCTON_MATERIALS: {
            id: 9,
            name: 'Animation Production Material',
            subType: {
                ANIMATION_COMPOSITE: {id: 41, name: 'Animation Composite', imageSuperType: 9},
                BACKGROUNDS: {id: 42, name: 'Backgrounds', imageSuperType: 9},
                CHARACTER_STOCK: {id: 43, name: 'Character - Stock', imageSuperType: 9},
                CHARACTER_ART: {id: 44, name: 'Character Art', imageSuperType: 9},
                CHARACTER_ART_ANIMATION: {id: 45, name: 'Character Art - Animation', imageSuperType: 9},
                CHARACTER_ART_GENERAL: {id: 46, name: 'Character Art- General', imageSuperType: 9}
            }
        },
        BTS: {
            id: 10,
            name: 'BTS',
            subType: {
                BEHIND_THE_SCENES: {id: 47, name: 'Behind the Scenes', imageSuperType: 10}
            }
        },
        OTHER: {
            id: 11,
            name: 'Other',
            subType: {
                OTHER: {id: 52, name: 'Other', imageSuperType: 11},
                AD_CONTEST: {id: 53, name: 'Ad - Contest/Promo', imageSuperType: 11},
                AD_COVER: {id: 54, name: 'Ad - Cover', imageSuperType: 11},
                AD_TRADE: {id: 55, name: 'Ad - Trade', imageSuperType: 11},
                ADVERTISING: {id: 56, name: 'Advertising', imageSuperType: 11},
                ART_MERCHANDISE: {id: 57, name: 'Art - Merchandise', imageSuperType: 11},
                MERCHANDISE_ART: {id: 58, name: 'Merchandise Art', imageSuperType: 11},
                MERCHANDISE: {id: 59, name: 'Merchandise', imageSuperType: 11},
                BAGS: {id: 60, name: 'Bags', imageSuperType: 11},
                CONTENT_ASSET_LIBRARY: {id: 61, name: 'Content Asset Library', imageSuperType: 11},
                CUSTOM_DVD_PACKAGING: {id: 62, name: 'Custom DVD/CD/VHS Packaging Clamshells', imageSuperType: 11},
                ILLUSTRATOR_TEMPLATE: {id: 63, name: 'Illustrator - Template', imageSuperType: 11},
                INSERT_DVD: {id: 64, name: 'Insert - DVD', imageSuperType: 11},
                MARKETING_SALES_KIT: {id: 65, name: 'Marketing Sales Kit', imageSuperType: 11},
                PHOTOSHOP_ACTION: {id: 66, name: 'Photoshop - Action', imageSuperType: 11},
                PRODUCT: {id: 67, name: 'Product', imageSuperType: 11},
                PRMOTIONAL_PACKAGING: {id: 68, name: 'Promotional Packaging', imageSuperType: 11},
                STOCK: {id: 69, name: 'Stock', imageSuperType: 11},
                STOCK_WB_COPYRIGHT: {id: 70, name: 'Stock - WB Copyright', imageSuperType: 11},
                TEASER_CAMPAIGN: {id: 71, name: 'Teaser Campaign', imageSuperType: 11}
            }
        },
        SOCIAL_MEDIA: {
            id: 12,
            name: 'Social Media',
            subType: {
                GIF: {id: 72, name: 'GIF', imageSuperType: 12},
                COUNTDOWN_CARD: {id: 73, name: 'Countdown Card', imageSuperType: 12},
                SOCIAL_MEDIA_CLIP: {id: 74, name: 'Social Media Clip', imageSuperType: 12},
                CHARACTER_CARD: {id: 75, name: 'Character Card', imageSuperType: 12}
            }
        },
        EVENTS: {
            id: 13,
            name: 'Events',
            subType: {
                EVENTS: {id: 48, name: 'Events', imageSuperType: 13},
                EVENTS_IJP: {id: 49, name: 'Events - IPJ', imageSuperType: 13},
                EVENTS_LA_SCREENINGS: {id: 50, name: 'Events - LA Screenings', imageSuperType: 13},
                EVENTS_MISC: {id: 51, name: 'Events - Misc.', imageSuperType: 13}
            }
        },
        HBO_MAX: {
            id: 14,
            name: 'HBO Max',
            subType: {
                TILE: {id: 76, name: 'Tile', imageSuperType: 14},
                TILE_BURNED_IN: {id: 77, name: 'Tile - Burned In', imageSuperType: 14},
                BACKGROUND: {id: 78, name: 'Background', imageSuperType: 14},
                LOGO_HBO_MAX: {id: 79, name: 'Logo - HBO Max', imageSuperType: 14},
                UNIVERSAL_SEARCH: {id: 80, name: 'Universal Search', imageSuperType: 14},
                EPISODIC_TILE: {id: 81, name: 'Episodic Tile', imageSuperType: 14}
            }
        }
    },

    toArray: function(constant) {
        return Object.keys(this[constant])
            .map(k => this[constant][k])
            .sort((a, b) => a.name.localeCompare(b.name));
    }
};

CONSTANTS.CONTENT_TYPE_MAP = Object.keys(CONSTANTS.IMAGE_TYPES).reduce( (o, k) => {
    o = Object.keys(CONSTANTS.IMAGE_TYPES[k].subType).reduce( (o1, k1) => {
        let item = CONSTANTS.IMAGE_TYPES[k].subType[k1];
        o1[item.id] = item;
        return o1;
    }, o);
    return o;
}, {});

class ImageActions {

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.IMAGE.CLEAR
        });
    }

    // This method handles the POST/PUT request to create/edit an image asset,
    // and the requests to upload or delete files.
    _createAssetRequest(method, uri, currentAssetData, imageFile, sourceFile) {
        let fileName = '';

        if (sourceFile) {
            fileName = sourceFile.name;
        } else if (imageFile) {
            fileName = imageFile.name;
        }

        const processImage = (file, assetId, renditionURL, endpoint, uploadType) => {
            if (!file || file.length === 0) {return;}

            let deletePromise = Promise.resolve();
            if (currentAssetData[renditionURL]) {
                deletePromise = Request.del(`asset/image/${currentAssetData.id}/${endpoint}`).exec();
            }

            return deletePromise.then(
                () => UploadToAmazon(assetId, file, uploadType, new XMLHttpRequest())
            );
        };

        return method(uri).send(currentAssetData).exec().then(res => {
            if (!sourceFile && !imageFile) {
                return {
                    assetId: res.body.id,
                    assetName: res.body.assetName
                };
            }

            const imagesRequests = [];

            if (imageFile) {
                const imageFileRes = processImage(
                    imageFile,
                    res.body.id,
                    'fullResolutionUrl',
                    'full-resolution-file',
                    'upload-full-resolution-url'
                );

                imagesRequests.push(imageFileRes);
            }

            if (sourceFile) {
                const sourceFileRes = processImage(
                    sourceFile,
                    res.body.id,
                    'sourceUrl',
                    'source-file',
                    'upload-source-url'
                );

                imagesRequests.push(sourceFileRes);
            }

            return Promise.all(imagesRequests).then(() => {
                return {
                    assetId: res.body.id,
                    assetName: res.body.assetName,
                    fileName
                };
            });
        }).catch(err => {
            return {
                err,
                assetName: currentAssetData.assetName,
                assetId: err.assetId,
                fileName
            };
        });
    }

    findById(imageId) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.IMAGE.GET.START,
            imageId: imageId,
        });

        const actionHistoryQuery = {
            'action-object': ActionHistoryConstants.ACTION_OBJECTS.IMAGE,
            'object-id': imageId,
            offset: 0,
            size: 4
        };

        Promise.all([
            Request.get('system/action-history').query(actionHistoryQuery).exec(),
            Request.get(`asset/image/${imageId}`).exec()
        ]).spread((history, image) => {
            //If no sourceUrl is present, then image is uploading, request again so loading gif vanishes.
            let isImageOnS3Bucket = true;
            if (this._isLoadingGif(image.body)) {
                isImageOnS3Bucket= false;
                setTimeout(() => {
                    this._reFetchImageUrlById(image.body.id);
                }, 2000);
            }

            let asset = image.body;
            history = history.body.results;
            history.sort((h1, h2) => h2.actionDate.localeCompare(h1.actionDate));
            asset.active = asset.active === 1;
            Dispatcher.dispatch({
                actionType: CONSTANTS.IMAGE.GET.SUCCESS,
                asset: Immutable.fromJS(asset),
                history: Immutable.fromJS(history),
                isImageOnS3Bucket: isImageOnS3Bucket
            });
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.IMAGE.GET.ERROR,
                error: err
            });
            switch (err.status) {
            case 404:
                RouterActions.notFound();
                break;
            default:
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'asset.image.load-error');
                break;
            }
            throw err;
        });

    }

    save(asset, imageFile, sourceFiles, assignedCatalogs, originalAssignedCatalogs, talent, originalTalent, titles, originalTitles, options, mode) {
        const preloaderSource = 'image-actions.save';
        PreloaderActions.show(preloaderSource);
        Dispatcher.dispatch({
            actionType: CONSTANTS.IMAGE.SAVE.START
        });

        this._save(asset, imageFile, sourceFiles).then(response => {
            PreloaderActions.hide(preloaderSource);

            if (sourceFiles.length > 1) {
                RouterActions.redirect('/assets/images?asset-type=1&operator=AND&active-type=ACTIVE');
            }

            response.forEach(responseItem => {
                this._saveAssetImageData(
                    responseItem,
                    mode,
                    options,
                    sourceFiles,
                    assignedCatalogs,
                    originalAssignedCatalogs,
                    talent,
                    originalTalent,
                    titles,
                    originalTitles
                );
            });
        }).catch(err => {
            PreloaderActions.hide(preloaderSource);
            Dispatcher.dispatch({
                actionType: CONSTANTS.IMAGE.SAVE.ERROR
            });
            throw err;
        });

    }

    _isLoadingGif(obj) {
        return (obj.thumbnailUrl?.includes('loading/loading-image.gif') || !obj.sourceUrl);
    }

    _isUserEditingImage(id) {
        /*  If the user is no longer in the page, lets stop the requests otherwise
            user navigates to another url and we still try to fetch image.
        */
        return window.location.pathname.includes(`assets/image/${id}`);
    }

    _reFetchImageUrlById(id) {
        Request.get(`asset/image/${id}`).exec().then((res) => {
            if (this._isLoadingGif(res.body) && this._isUserEditingImage(id)) {
                setTimeout(() => {
                    this._reFetchImageUrlById(res.body.id);
                }, 2000);
                return;
            }
            Dispatcher.dispatch({
                actionType: CONSTANTS.IMAGE.RE_FETCH_COMPLETE,
                loadedImgData: res.body
            });
        }).catch((error) => {
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'asset.image.load-error');
            throw error;
        });
    }

    _saveAssetImageData(assetItem, mode, options, sourceFiles, assignedCatalogs, originalAssignedCatalogs, talent, originalTalent, titles, originalTitles) {
        if (assetItem.err && !assetItem.assetId) { // if some create asset has failed
            if (sourceFiles.length <= 1) {
                RouterActions.redirect('/assets');
            }
            NotificationActions.showAlert(
                AlertTypes.ALERT_DANGER.name,
                options.messages.error,
                assetItem.assetName
            );
        } else { // if asset was created succesfully
            const id = assetItem.assetId;
            return Promise.all([
                assetItem.imageFileRes,
                assetItem.sourceFilesRes,
                ...AssetCatalogActions.assetCatalogSave(id, assignedCatalogs, originalAssignedCatalogs),
                ...AssetTalentActions.assetTalentSave(id, talent, originalTalent),
                ...AssetTitleActions.assetTitleSave(id, titles, originalTitles)
            ]).then(() => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.IMAGE.SAVE.SUCCESS,
                    id: id
                });
                if (sourceFiles.length <= 1 && id) {
                    RouterActions.redirect(`/assets/image/${id}`, true);
                    if (mode === 'edit') {
                        this.findById(id);
                    }
                }
                if (assetItem.err && assetItem.assetId && sourceFiles.length >= 1) {
                    NotificationActions.showAlert(
                        AlertTypes.ALERT_DANGER.name,
                        'asset.image.create.error.incomplete-save',
                        assetItem.assetName,
                        assetItem.fileName
                    );
                } else {
                    NotificationActions.showAlert(
                        AlertTypes.ALERT_SUCCESS.name,
                        options.messages.success,
                        assetItem.assetName
                    );
                }
                if (assetItem.err) {
                    // The following displays a message in case the file delete & upload fails
                    // FIXME: this is not the full solution, as the success notification will
                    // still show. We must find a better logic around this.
                    NotificationActions.showAlert(
                        AlertTypes.ALERT_DANGER.name,
                        'asset.image.create.error.incomplete-save',
                        assetItem.assetName
                    );
                }
            }).catch(err => {
                if (sourceFiles.length <= 1 && assetItem.assetId) {
                    RouterActions.redirect(`/assets/image/${id}`, true);
                }
                NotificationActions.showAlertDanger('asset.image.error.incomplete-save', assetItem.assetName);
                console.error(err);
            });
        }
    }

    saveAndAdd(asset, sourceFiles, addConstants, assignedCatalogs, originalAssignedCatalogs) {
        const defaults = {
            messages: {
                error: 'asset.image.create.error',
                success: 'asset.image.create.success'
            }
        };
        const preloaderSource = 'image-actions.saveAndAdd';
        PreloaderActions.show(preloaderSource);
        this._save(asset, null, sourceFiles).then(response => {
            let addCatalogs = [];
            const errorSaving = response.some(responseItem => responseItem.err);
            if (!errorSaving) {
                addCatalogs = response.reduce((requests, responseItem) => (
                    [...requests, ...AssetCatalogActions.assetCatalogSave(responseItem.assetId, assignedCatalogs, originalAssignedCatalogs)]
                ), []);
            }
            return Promise.props({
                catalogs: Promise.all(addCatalogs),
                allAssets: response
            });
        }).then(response => {
            SlidingPanelActions.hide('addNew');
            PreloaderActions.hide(preloaderSource);
            response.allAssets.forEach(responseAsset => {
                this._showAddImageTitleMessages(
                    responseAsset,
                    defaults,
                    asset,
                    addConstants,
                );
            });

        }).catch(err => {
            PreloaderActions.hide(preloaderSource);
            Dispatcher.dispatch({
                actionType: CONSTANTS.IMAGE.SAVE.ERROR
            });

            throw err;
        });

    }

    _save(asset, imageFile, sourceFiles) {
        let assetData = asset.toJS();
        ['imageSuperType', 'thumbnail', 'files', 'rendition'].forEach(prop => delete assetData[prop]);
        const id = assetData.id;
        let method = Request.post;
        let uri = 'asset/image';
        if (assetData.active) {
            assetData.active = 1;
        } else {
            assetData.active = 0;
        }
        assetData.publicAsset = 0;

        assetData.caption = GetEncodedHTML(assetData.caption);
        assetData.credit = GetEncodedHTML(assetData.credit);

        if (id !== undefined) {
            method = Request.put;
            uri = `asset/image/${id}`;

            //Removing data that might make cloudflare fail
            const propsToRemove = ['sourceUrl', 'thumbnailUrl', 'previewUrl', 'largeThumbnailUrl', 'largePreviewUrl', 'fullResolutionUrl'];
            propsToRemove.forEach(prop => {
                delete assetData[prop];
            });
        }

        if (sourceFiles && sourceFiles.length === 0) {
            return Promise.all([this._createAssetRequest(method, uri, assetData, imageFile || null, null)]);
        }

        const createAssetRequests = [...sourceFiles].map((sourceFile, index) => {
            let fileNumber = index + 1;
            if (index < 10) {
                fileNumber = `${0}${index+1}`;
            }
            let assetName = assetData.assetName;
            if (sourceFiles.length > 1) {
                assetName = `${assetData.assetName} ${fileNumber}`;
            }
            const currentAssetData = {
                ...assetData,
                assetName
            };
            return this._createAssetRequest(method, uri, currentAssetData, imageFile, sourceFile);
        });

        return Promise.all(createAssetRequests);
    }

    _showAddImageTitleMessages(imageItem, defaults, asset, addConstants) {
        const assetList = [];
        if (imageItem.err) { // if something as failed when creating asset
            if (imageItem.assetId) {
                NotificationActions.showAlert(
                    AlertTypes.ALERT_DANGER.name,
                    'asset.image.create.error.incomplete-save',
                    imageItem.assetName,
                    imageItem.fileName
                );
                asset = asset.set('id', imageItem.assetId);
                assetList.push(asset);
            } else {
                NotificationActions.showAlert(
                    AlertTypes.ALERT_DANGER.name,
                    defaults.messages.error,
                    imageItem.assetName
                );
            }
        } else { // if asset was created succesfully
            asset = asset.set('id', imageItem.assetId);
            assetList.push(asset);
            Dispatcher.dispatch({
                actionType: CONSTANTS.IMAGE.SAVE.SUCCESS,
                id: imageItem.assetId
            });
            NotificationActions.showAlert(
                AlertTypes.ALERT_SUCCESS.name,
                defaults.messages.success,
                imageItem.assetName
            );
        }
        AssetTabActions.add('image', Immutable.List([...assetList]), addConstants);
    }

    showWrongExtension(file) {
        NotificationActions.showAlertDanger('asset.image.error.file-extension', file.name);
    }

    update(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.IMAGE.UPDATE,
            attr: attr,
            value: value
        });


    }
}

let actions = new ImageActions();

export {
    actions as ImageActions,
    CONSTANTS as ImageConstants
};
