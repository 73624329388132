/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';
import {IndexRoute, Route} from 'react-router';

import BrowseAsset from './asset/browse/browse';
import CreateAsset from './asset/create/create';
import SummaryAsset from './asset/create/summary/summary';
import MassUpdateAssetConfirmation from './asset/mass-update/confirmation';
import MassUpdateAsset from './asset/mass-update/mass-update';
import MassUpdateAssetReview from './asset/mass-update/review';
import BrowseLanguage from './language/browse/browse';
import CreateLanguage from './language/create/create';
import SummaryLanguage from './language/create/summary/summary';
import BrowseTitle from './title/browse/browse';
import CreateTitle from './title/create/create';
import SummaryTitle from './title/create/summary/summary';
import MassUpdateTitleConfirmation from './title/mass-update/confirmation';
import MassUpdateTitle from './title/mass-update/mass-update';
import MassUpdateTitleReview from './title/mass-update/review';
import UserGroups from './user-groups';
import ListAssets from '../assets/browse/list-assets';
import {History} from '../common/side-notes/side-notes';
import WithPermissions from '../decorators/with-permissions';
import {RequirePerm} from '../router/router-helpers';
import ListTitles from '../titles/browse/list-titles';
import SessionStore from '../user/session/session-store';

let Catalogs = <Route path="catalogs">

    <Route
        component={WithPermissions(BrowseAsset)}
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.CATALOGS.ASSET.BROWSE)}
        path="asset"
    />

    <Route
        component={WithPermissions(CreateAsset)}
        mode="create"
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.CATALOGS.ASSET.CREATE)}
        path="asset/create"
    />

    <Route
        component={MassUpdateAsset}
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.CATALOGS.ASSET.MASS_UPDATE.UPLOAD)}
        path="asset/mass-update"
    />

    <Route
        component={MassUpdateAssetReview}
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.CATALOGS.ASSET.MASS_UPDATE.UPLOAD)}
        path="asset/mass-update/:id"
    />

    <Route
        component={MassUpdateAssetConfirmation}
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.CATALOGS.ASSET.MASS_UPDATE.UPLOAD)}
        path="asset/mass-update/:id/confirmation"
    />

    <Route
        component={WithPermissions(CreateAsset)}
        mode="edit"
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.CATALOGS.ASSET.READ)}
        path="asset/:id"
    >

        <IndexRoute component={SummaryAsset}/>
        <Route path="assets" component={ListAssets}/>
        <Route path="user-groups" component={UserGroups}/>
        <Route path="history" component={History}/>
    </Route>

    <Route
        component={WithPermissions(BrowseTitle)}
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.CATALOGS.TITLE.BROWSE)}
        path="title"
    />

    <Route
        component={WithPermissions(CreateTitle)}
        mode="create"
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.CATALOGS.TITLE.CREATE)}
        path="title/create"
    />

    <Route
        component={MassUpdateTitle}
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.CATALOGS.TITLE.MASS_UPDATE.UPLOAD)}
        path="title/mass-update"
    />

    <Route
        component={MassUpdateTitleReview}
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.CATALOGS.TITLE.MASS_UPDATE.UPLOAD)}
        path="title/mass-update/:id"
    />

    <Route
        component={MassUpdateTitleConfirmation}
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.CATALOGS.TITLE.MASS_UPDATE.UPLOAD)}
        path="title/mass-update/:id/confirmation"
    />

    <Route
        component={WithPermissions(CreateTitle)}
        mode="edit"
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.CATALOGS.TITLE.READ)}
        path="title/:id"
    >

        <IndexRoute component={SummaryTitle}/>
        <Route path="titles" component={ListTitles}/>
        <Route path="user-groups" component={UserGroups}/>
        <Route path="history" component={History}/>
    </Route>

    <Route
        component={WithPermissions(BrowseLanguage)}
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.CATALOGS.LANGUAGE.BROWSE)}
        path="language"
    />

    <Route
        component={WithPermissions(CreateLanguage)}
        mode="edit"
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.CATALOGS.LANGUAGE.READ)}
        path="language/:id"
    >
        <IndexRoute component={SummaryLanguage}/>
        <Route path="titles" component={ListTitles}/>
        <Route path="history" component={History}/>
    </Route>

</Route>;

export default Catalogs;
