/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import QueryString from 'querystring-es3';
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

import Partners from './partners';
import Summary from './summary/summary';
import AuthLink from '../../common/auth-link';
import RouteValidator from '../../common/custom-validator/route/route';
import DocumentTitle from '../../common/document-title';
import ListUsers from '../../common/list-users/list-users';
import {MODAL_TYPE} from '../../common/notification/modal';
import {NotificationActions} from '../../common/notification/notification-actions';
import {Tab, Tabs} from '../../common/routing-tab/routing-tab';
import {History, SideNotes} from '../../common/side-notes/side-notes';
import {WithStoreOnRoute} from '../../common/store-on-route';
import {AlertsWarnings} from '../../common/validations/alerts-warnings';
import ActionsMenu from '../../layout/actions-menu/actions-menu';
import StickyHeader from '../../layout/sticky-header/sticky-header';
import Preloader from '../../preloader/';
import PreloaderStore from '../../preloader/preloader-store';
import {RouterActions} from '../../router/router-actions';
import {GroupActions, GroupConstants} from '../../security/group/group-actions';
import GroupStore from '../../security/group/group-store';
import {ActionHistoryActions, ActionHistoryConstants} from '../../system/action-history/action-history-actions';
import SessionStore from '../../user/session/session-store';
import {UserActions} from '../../user/user-actions';
import UserStore from '../../user/user-store';

class Create extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            params: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired,
            /**
             * Validate custom props inside the route object.
             */
            route: RouteValidator // eslint-disable-line react/require-default-props
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            activePage: UserStore.getState().get('activePage'),
            history: GroupStore.getState().get('user').get('history'),
            partners: GroupStore.getState().get('partners'),
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible'),
            selectAll: UserStore.getState().get('selectAll'),
            selectedUsers: UserStore.getState().get('selectedUsers'),
            total: UserStore.getState().get('total'),
            totalPages: UserStore.getState().get('totalPages'),
            userGroup: GroupStore.getState().get('user').get('group'),
            users: UserStore.getState().get('users')
        };
    }

    static getStores() {
        return [GroupStore, PreloaderStore, UserStore];
    }

    static getPermissions() {
        return {
            canRead: SessionStore.canUser(SessionStore.PERMISSIONS.GROUPS.READ),
            canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.GROUPS.EDIT),
            users: {
                canRead: SessionStore.canUser(SessionStore.PERMISSIONS.GROUPS.USERS.READ),
                canRemove: SessionStore.canUser(SessionStore.PERMISSIONS.GROUPS.USERS.REMOVE),
                canSyncElasticSearch: SessionStore.canUser(SessionStore.PERMISSIONS.GROUPS.USERS.SYNC_ES)
            }
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            activeTab: 0,
            size: 20,
            sortDirection: 'asc',
            sortFieldName: 'name'
        }, this.constructor.calculateState());

        this.addNote = this.addNote.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleElasticSearchSync = this.handleElasticSearchSync.bind(this);
        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleRemoveSelectedUsers = this.handleRemoveSelectedUsers.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.isDirty = this.isDirty.bind(this);
        this.showDeleteDialog = this.showDeleteDialog.bind(this);
        this.showRemoveUsersDialog = this.showRemoveUsersDialog.bind(this);
    }

    componentWillMount() {
        GroupActions.clear();
        UserActions.clear();
    }

    componentDidMount() {
        this.initStores(this.props);
        RouterActions.registerRedirectCheck((location) => {
            return this.isDirty(location);
        });
        return;
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location.query !== this.props.location.query ||
            nextProps.route.mode !== this.props.route.mode ||
            nextProps.params.id !== this.props.params.id) {

            this.initStores(nextProps);
            UserActions.toggleSelectAll(false);
        }
        RouterActions.registerRedirectCheck((location) => {
            return this.isDirty(location);
        });
        return;
    }

    componentWillUnmount() {
        RouterActions.clearRedirectChecks();
    }

    addNote(description) {
        ActionHistoryActions.addNote(this.props.params.id, description, ActionHistoryConstants.ACTION_OBJECTS.GROUP, GroupConstants.USER.name);
    }

    /**
     * Handle clicks on pagination controls.
     * @param  {Number} pageNumber page to load.
     */

    handleCancel() {
        RouterActions.redirect('/groups/user-groups', true);
    }

    handleDownloadAllResetPasswordTokens(groupId) {
        GroupActions.downloadAllResetPasswordTokens(groupId);
    }

    handleElasticSearchSync() {
        GroupActions.syncElasticSearch(this.props.params.id);
    }

    handleExportUsers(groupId) {
        GroupActions.downloadAllUsers(groupId);
    }

    handleLoadPage(pageNumber) {
        // pageNumber x this.state.size = offset.
        let query = Object.assign(
            {},
            this.props.location.query,
            {
                'offset': pageNumber*this.state.size,
                'size': this.state.size
            }
        );

        RouterActions.redirect(`${this.props.location.pathname}?${QueryString.stringify(query)}`);
        return;
    }

    handleRemove() {
        GroupActions.remove(GroupConstants.USER, this.props.params.id);
        return;
    }

    handleRemoveSelectedUsers() {
        GroupActions.removeUsers(this.state.userGroup, this.state.selectedUsers);
        return;
    }

    handleSave() {
        const options = {
            messages: {
                error: `groups.user.${this.props.route.mode}.error`,
                success: `groups.user.${this.props.route.mode}.success`
            }
        };

        GroupActions.saveUserGroup(
            GroupStore.getState().get('user').get('group'),
            GroupStore.getState().get('user').get('originalGroup'),
            options
        );
        return;
    }

    handleSortChange(sortFieldName, sortDirection) {
        this.setState({
            sortDirection: sortDirection,
            sortFieldName: sortFieldName
        });
        let query = Object.assign(
            {},
            this.props.location.query,
            {
                'sort-field-name': sortFieldName,
                'sort-direction': sortDirection,
                'keep-scroll': true
            }
        );

        RouterActions.redirect(`${this.props.location.pathname}?${QueryString.stringify(query)}`);
        return;
    }

    initStores(props) {
        if (props.route.mode === 'edit') {
            GroupActions.findUserGroupById(props.params.id);
            UserActions.findAllByGroupId(
                props.params.id,
                props.location.query.offset,
                props.location.query.size,
                props.location.query['sort-field-name'],
                props.location.query['sort-direction']
            );
        }
        return;
    }

    isDirty(location) {
        if (this.props.route.mode === 'create') {
            return false;
        }
        if (location) {
            let goingTo = location.pathname.split('/');
            let current = this.props.location.pathname.split('/');
            if (goingTo[1] === current[1] && /* groups */
                goingTo[2] === current[2] && /* user-groups */
                goingTo[3] === current[3] /* id */) {
                return false; // just changing tabs no confirmation needed.
            }
        }
        return !GroupStore.getState().get('user').get('group').equals(GroupStore.getState().get('user').get('originalGroup'));
    }

    showDeleteDialog(event) {
        event.preventDefault();
        NotificationActions.show(
            MODAL_TYPE.DANGER,
            this.context.intl.messages['groups.user.edit.modal.title'],
            this.context.intl.messages['groups.user.edit.modal.body'],
            this.context.intl.messages['groups.user.edit.modal.confirm'],
            this.handleRemove
        );
    }

    showRemoveUsersDialog(event) {
        event.preventDefault();

        NotificationActions.show(
            MODAL_TYPE.DANGER,
            this.context.intl.messages['groups.user.remove-users.modal.title'],
            this.context.intl.messages['groups.user.remove-users.modal.body'],
            this.context.intl.messages['groups.user.remove-users.modal.confirm'],
            this.handleRemoveSelectedUsers
        );
    }

    render() {
        let actionsMenu;
        const mode = this.props.route.mode;
        const validations = GroupStore.getUserGroupValidations();
        const intlPrefix = `${mode}`;
        let title;

        let baseRoute = `/groups/user-groups/${this.props.params.id}`;
        if (mode === 'create') {
            baseRoute = '/groups/user-groups/create';
            actionsMenu = <ActionsMenu
                canSave={!validations.length}
                onCancel={this.handleCancel}
                onSave={this.handleSave}
                saveText={this.context.intl.messages['groups.create-group']}
            />;

            title = <em>{this.context.intl.messages['groups.user.create.title']}</em>;
        }

        let partnersTab;
        let usersTab;
        let historyTab = null;
        let eventsAndNotes = null;

        if (mode === 'edit') {
            let elasticSearchBtn;
            if (this.props.permissions.users.canSyncElasticSearch) {
                elasticSearchBtn = <Button
                    bsSize="large"
                    className="btn btn-primary-outline Mr(3px) Mb(3px)"
                    onClick={this.handleElasticSearchSync}
                >
                    <i className="fas fa-database"/>&nbsp;{this.context.intl.messages['groups.user.edit.elastic-search.sync']}
                </Button>;
            }

            // The false values are hard-coded and should be removed once the endpoint gets fixed [BRAIN-1566]
            actionsMenu = <ActionsMenu
                canDelete={false}
                canEdit={this.props.permissions.canEdit}
                canSave={!validations.length && this.props.permissions.canEdit}
                onCancel={this.handleCancel}
                onDelete={false}
                onSave={this.handleSave}
            >
                {elasticSearchBtn}
            </ActionsMenu>;

            title = <em>{this.context.intl.messages['groups.user.edit.title']}</em>;

            if (this.props.permissions.users.canRead) {
                usersTab = <Tab route={`${baseRoute}/users`} title={this.context.intl.messages['groups.users']}>
                    <ListUsers
                        activePage={this.state.activePage}
                        columns={[
                            'flag', 'name', 'company', 'email', 'status',
                            'clientRepGroup', 'applicationDate'
                        ]}
                        displayLinks={this.props.permissions.users.canRead}
                        onPageChange={this.handleLoadPage}
                        onSelect={UserActions.toggleSelect}
                        onSelectAll={UserActions.toggleSelectAll}
                        onSortChange={this.handleSortChange}
                        selectAll={this.state.selectAll}
                        sortDirection={this.state.sortDirection}
                        sortFieldName={this.state.sortFieldName}
                        totalPages={this.state.totalPages}
                        users={this.state.users}
                    >
                        <div className="bulk-actions">
                            <Button
                                bsStyle="danger"
                                disabled={!(this.props.permissions.users.canRemove && this.state.selectedUsers.size)}
                                onClick={this.showRemoveUsersDialog}><i className="fas fa-trash-alt"></i>&nbsp;{this.context.intl.messages['groups.user.remove-users']}</Button>

                            <AuthLink
                                className="btn btn-default pull-right Ml(5px)"
                                onClick={this.handleExportUsers.bind(this, this.props.params.id)}
                                disabled={!this.state.users.size}
                            >
                                <i className="fas fa-download"></i> {this.context.intl.messages['groups.user.export-users']}
                            </AuthLink>

                            <AuthLink
                                className="btn btn-default pull-right"
                                onClick={this.handleDownloadAllResetPasswordTokens.bind(this, this.props.params.id)}
                                disabled={!this.state.users.size}
                            >
                                <i className="fas fa-download"></i> {this.context.intl.messages['groups.user.export-users-with-reset-token']}
                            </AuthLink>
                        </div>
                    </ListUsers>
                </Tab>;
            }

            eventsAndNotes = (
                <SideNotes
                    basePath={`/groups/user-groups/${this.props.params.id}`}
                    dialogMessage={this.context.intl.messages['common.side-notes.add-note.body']}
                    disabled={ !this.props.permissions.canEdit }
                    notes={this.state.history.toJS().slice(0, 4)}
                    onAddNote={this.addNote}
                    title={this.context.intl.messages['common.side-notes.title']}
                />
            );

            historyTab = <Tab route={`${baseRoute}/history`} title={this.context.intl.messages['common.tab-title.history']} tabClassName="pull-right">
                <History
                    actionObject={ActionHistoryConstants.ACTION_OBJECTS.GROUP}
                    id={this.props.params.id}
                    pagination
                    pathname={this.props.location.pathname}
                    query={this.props.location.query}
                />
            </Tab>;

            partnersTab = <Tab route={`${baseRoute}/partners`} title={this.context.intl.messages['groups.partners']}>
                <Partners id={this.props.params.id} partners={this.state.partners}/>
            </Tab>;
        }

        let summaryTab = <Tab route={baseRoute} title={this.context.intl.messages['groups.summary']}>
            <Summary mode={mode} permissions={this.props.permissions} userGroup={this.state.userGroup}/>
        </Tab>;

        let entityName;

        if (this.state.userGroup.get('name')) {
            entityName = this.state.userGroup.get('name');
        }

        return (
            <DocumentTitle
                message={`document-titles.${mode}-group`} entityName={entityName}
            >
                <div>
                    <StickyHeader>
                        <div className="col-md-6">
                            <h1>
                                <i className="fas fa-users"></i>&nbsp;{this.context.intl.messages[`groups.user.${intlPrefix}.title`]}
                                &nbsp;
                            </h1>
                            <p className="padding-top-20">
                                {title}
                            </p>
                        </div>
                        {actionsMenu}
                    </StickyHeader>
                    <Preloader show={this.state.preloaderVisible} fixed loadingDots>
                        <section className="content" id="contentContainer">
                            <div className="row">
                                <div className="col-lg-9">
                                    <Tabs location={this.props.location}>
                                        {summaryTab}
                                        {usersTab}
                                        {historyTab}
                                        {partnersTab}
                                    </Tabs>
                                </div>
                                <div className="col-lg-3">
                                    <AlertsWarnings title={this.context.intl.messages['common.alerts-warnings-title']} validations={validations} />
                                    {eventsAndNotes}
                                </div>
                            </div>
                        </section>
                    </Preloader>
                </div>
            </DocumentTitle>
        );
    }
}

export default WithStoreOnRoute(
    Container.create(Create)
);
