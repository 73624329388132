/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, Modal, FormGroup, ControlLabel} from 'react-bootstrap';
import ReactTable from 'react-table';

import {AccountWizardActions, AccountWizardConstants} from './account-wizard-actions';
import {ConfirmUserRemovalModal} from './confirm-user-removal-modal';

import {FormRow, FormSection} from '~/src/common/form/form';
import {GetAttr} from '~/src/common/utils/utils';
import TerritorySelect from '~/src/lookup/territory/territory-select';
import UserCompanySelect from '~/src/user/company/user-company-select';
import {UserConstants} from '~/src/user/user-actions';

import 'react-table/react-table.css';

const TableId = 'account-wizard-user-list-table-id';
class UserList extends Component {
    static get propTypes() {
        return {
            batchId: PropTypes.number.isRequired,
            batchStatusType: PropTypes.object.isRequired,
            readOnly: PropTypes.bool,
            userRowValidationsAll: PropTypes.instanceOf(Immutable.Map), // eslint-disable-line react/no-unused-prop-types
            users: PropTypes.instanceOf(Immutable.List).isRequired, // eslint-disable-line react/no-unused-prop-types
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
        };
    }

    static get defaultProps() {
        return {
            readOnly: false,
            userRowValidationsAll: Immutable.Map(),
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            editCountry: null,
            selectAll: false,
            showEditModal: false,
            showUserRemovalModal: false,
        };

        this.getSelectedUsers = this.getSelectedUsers.bind(this);
        this.getUserRowValidations = this.getUserRowValidations.bind(this);
        this.handleAddNewUser = this.handleAddNewUser.bind(this);
        this.handleCellInputOnChange = this.handleCellInputOnChange.bind(this);
        this.handleEditUsers = this.handleEditUsers.bind(this);
        this.handleEditUsersConfirmChange = this.handleEditUsersConfirmChange.bind(this);
        this.handleRemoveUsers = this.handleRemoveUsers.bind(this);
        this.handleSelectAll = this.handleSelectAll.bind(this);
        this.handleSelectRow = this.handleSelectRow.bind(this);
        this.handleToggleUserRemovalModal = this.handleToggleUserRemovalModal.bind(this);
        this.handleTrash = this.handleTrash.bind(this);
        this.renderCompanyCell = this.renderCompanyCell.bind(this);
        this.renderCountryCell = this.renderCountryCell.bind(this);
        this.renderEditableDivCell = this.renderEditableDivCell.bind(this);
        this.renderFlagCell = this.renderFlagCell.bind(this);
        this.renderUserImportStatusIconCell = this.renderUserImportStatusIconCell.bind(this);
    }

    getCellErrors(columnId, userValidations) {
        let result = [];
        userValidations.forEach((v) => {
            if (UserConstants.ACCOUNT_ALERTS[v]?.errorCols?.includes(columnId)) {
                result.push(v);
            }
        });
        return result;
    }

    getCellWarnings(columnId, userValidations) {
        let result = [];
        userValidations.forEach((v) => {
            if (UserConstants.ACCOUNT_ALERTS[v]?.warningCols?.includes(columnId)) {
                result.push(v);
            }
        });
        return result;
    }

    getSelectedUsers() {
        return this.props.users.filter(/*istanbul ignore next*/u => u.get('selected'));
    }

    getUserRowValidations(id) {
        const uiv = this.props.userRowValidationsAll.getIn([`${id}`, 'userIngestValidations']) || Immutable.List();
        return uiv.toJS();
    }

    handleAddNewUser() {
        const user = Immutable.fromJS({
            id: Date.now(),
            newUserRow: true,
            emailaddress: '',
            firstName: '',
            lastName: '',
            company: '',
            country: '',
            status: AccountWizardConstants.USERS.STATUS.PENDING.id,
            userIngestHeaderId: parseInt(this.props.batchId, 10),
        });
        AccountWizardActions.addNewUser(user);
        this.setState(/*istanbul ignore next*/() => ({selectAll: false}));
        AccountWizardActions.updateUserRowValidations(user);
    }

    handleCellInputOnChange(userId, columnId, value) {
        // STUDIO-12544 accept pasted hyperlinked email addresses and trim whitespace
        if (value) {
            value = this.stripHtmlExtractEmail(value);
        }

        AccountWizardActions.updateUser(userId, columnId, value);
        AccountWizardActions.clearEditing();

        // Call the API to update row validations
        let row = this.props.users.filter(/*istanbul ignore next*/u => u.get('id') === userId).first();
        row = row.set(columnId, value);
        AccountWizardActions.updateUserRowValidations(row);
    }

    handleEditUsers() {
        this.setState(/*istanbul ignore next*/(prevState) => ({
            editCountry: null,
            showEditModal: !prevState.showEditModal
        }));
        return;
    }

    handleEditUsersConfirmChange() {
        this.getSelectedUsers().forEach(u => {
            if (this.state.editCountry?.name) {
                AccountWizardActions.updateUser(u.get('id'), 'country', this.state.editCountry.name);
                u = u.set('country', this.state.editCountry.name);
            }

            AccountWizardActions.updateUser(u.get('id'), 'selected', false);
            AccountWizardActions.updateUserRowValidations(u);
        });

        this.setState(/*istanbul ignore next*/() => ({showEditModal: false, selectAll: false}));
        return;
    }

    handleRemoveUsers() {
        this.getSelectedUsers().toJS().forEach(u => AccountWizardActions.removeUser(u.id));
        this.setState(/*istanbul ignore next*/() => ({selectAll: false}));
        return;
    }

    handleSelectAll() {
        AccountWizardActions.selectAll(!this.state.selectAll);
        this.setState(/*istanbul ignore next*/(prevState) => ({selectAll: !prevState.selectAll}));
        return;
    }

    handleSelectRow(e) {
        const isChecked = e.target.checked;
        AccountWizardActions.updateUser(parseInt(e.target.getAttribute('data-user-id'), 10), 'selected', isChecked);
        if (isChecked && this.getSelectedUsers().size === this.props.users.size - 1) {
            this.setState(/*istanbul ignore next*/() => ({selectAll: true}));
        } else {
            this.setState(/*istanbul ignore next*/() => ({selectAll: false}));
        }

        return;
    }

    handleToggleUserRemovalModal(handler) {
        this.setState(/*istanbul ignore next*/prevState => ({
            showUserRemovalModal: !prevState.showUserRemovalModal,
            onConfirmRemoval: handler
        }));
    }

    handleTrash(userId) {
        AccountWizardActions.removeUser(userId);
        return;
    }

    handleCellOnClick(userId, columnId) {
        // Only used for company and country columns
        AccountWizardActions.clearEditing();
        AccountWizardActions.updateUser(userId, 'editing', columnId);
    }

    isBatchProcessed(batchStatusType) {
        return [AccountWizardConstants.BATCH_STATUS_TYPES.PROCESSING,
            AccountWizardConstants.BATCH_STATUS_TYPES.PROCESSED].includes(batchStatusType);
    }

    renderFlagCell(cellInfo) {
        if (cellInfo.original.get('status') !== 'FAILED') {
            return this.renderUserImportStatusIconCell(cellInfo);
        }

        const alerts = this.getUserRowValidations(cellInfo.original.get('id'));
        if (alerts.length === 0) {
            const validTip = this.context.intl.messages['user.account-alerts.tooltip.account-wizard-row-valid'];
            return <div className="parcel-tooltip" title={validTip}><i className="fa-solid fa-fw fa-user-plus text-info" /><span className="tooltip-inner">{validTip}</span></div>;
        }

        let alertFlag;
        if (alerts.length === 1) {
            alertFlag = UserConstants.ACCOUNT_ALERTS[alerts[0].userValidationType];
        } else if (alerts.length > 1) {
            alertFlag = UserConstants.ACCOUNT_ALERTS.MULTIPLE_ISSUES;
        }

        if (!alertFlag) {
            alertFlag = UserConstants.ACCOUNT_ALERTS.GENERIC_ERROR;
        }

        const tip = this.context.intl.messages[alertFlag.tip];
        return <div className="parcel-tooltip" title={tip}><i className={alertFlag.icon} /><span className="tooltip-inner" >{tip}</span></div>;
    }

    renderUserImportStatusIconCell(cellInfo) {
        let statusIcon;

        switch (cellInfo.original.get('status')) {
        case 'COMPLETE':
            statusIcon = UserConstants.ACCOUNT_IMPORT_STATUS.SUCCESS;
            break;
        default:
            statusIcon = UserConstants.ACCOUNT_IMPORT_STATUS.PENDING;
            break;
        }
        const tip = this.context.intl.messages[statusIcon.tip];
        return <div className="parcel-tooltip" title={tip}><i className={statusIcon.icon} /><span className="tooltip-inner" >{tip}</span></div>;
    }

    renderEditableDivCell(cellInfo) {
        const columnId = cellInfo.column.id;
        const userId = cellInfo.original.get('id');
        let classes = ['editable-cell'];
        const userValidations = this.getUserRowValidations(cellInfo.original.get('id'));
        const errors = this.getCellErrors(columnId, userValidations.filter(/*istanbul ignore next*/c => c.severityType === 'ERROR').map(c => c.userValidationType));
        if (errors?.length) {classes.push('cell-error');}

        const warnings = this.getCellWarnings(columnId, userValidations.filter(/*istanbul ignore next*/c => c.severityType === 'WARNING').map(c => c.userValidationType));
        if (warnings?.length) {classes.push('cell-warning');}

        // STUDIO-3586: Do not show background color styling for processing|processed batches on imported records
        if (this.isBatchProcessed(this.props.batchStatusType) && cellInfo.original.get('status') !== 'FAILED') {
            classes = [];
        }

        return (
            <div
                className={ClassNames(classes)}
                contentEditable={!this.props.readOnly}
                suppressContentEditableWarning
                onBlur={/*istanbul ignore next*/(e) => {
                    if (!e?.relatedTarget?.className?.includes('remove-user') && !e?.relatedTarget?.className?.includes('select-user')) {
                        this.handleCellInputOnChange(userId, columnId, e.target.innerHTML, e);
                    }
                }}
                dangerouslySetInnerHTML={{
                    __html: cellInfo.original.get(columnId)
                }}
            />
        );
    }

    renderCompanyCell(cellInfo) {
        // FIXME: Clicking outside select on page background/etc should remove editing state. onBlur doesn't fire properly.
        // FIXME: no way to tab into this cell from previous column when in edit mode
        const columnId = cellInfo.column.id;
        const rowId = cellInfo.original.get('id');
        const userValidations = this.getUserRowValidations(cellInfo.original.get('id'));

        let classes = ['editable-cell'];
        const errors = this.getCellErrors(columnId, userValidations.filter(/*istanbul ignore next*/c => c.severityType === 'ERROR').map(c => c.userValidationType));
        if (errors?.length) {classes.push('cell-error');}

        const warnings = this.getCellErrors(columnId, userValidations.filter(/*istanbul ignore next*/c => c.severityType === 'WARNING').map(c => c.userValidationType));
        if (warnings?.length) {classes.push('cell-warning');}

        let disabled = this.props.readOnly;
        // STUDIO-3586: Do not show background color styling for processing|processed batches on imported records
        if (this.isBatchProcessed(this.props.batchStatusType) && cellInfo.original.get('status') !== 'FAILED') {
            classes = [];
            disabled = true;
        }

        if (cellInfo.original.get('editing') === columnId) {
            // The styled div wrapper is needed to prevent the dropdown options hiding behind table
            // But this breaks on smaller resolutions by shifting dropdown far to the right
            return <UserCompanySelect
                allowCreate
                getOptionLabel={GetAttr('name')}
                getOptionValue={GetAttr('name')}
                labelKey="name"
                valueKey="name"
                isClearable={true}
                disabled={disabled}
                placeholder={cellInfo.original.get('company')}
                onChange={/*istanbul ignore next*/(e) => {
                    this.handleCellInputOnChange(rowId, columnId, e.name);
                }}
                selected={cellInfo.original.get('company')}
            />;
        }

        return <div className={ClassNames(classes)}>{cellInfo.original.get(columnId)}&nbsp;</div>;
    }

    renderCountryCell(cellInfo) {
        // FIXME: Clicking outside select on page background/etc should remove editing state. onBlur doesn't fire properly.
        // FIXME: no way to tab into this cell from previous column when in edit mode
        const columnId = cellInfo.column.id;
        const userId = cellInfo.original.get('id');
        const userValidations = this.getUserRowValidations(cellInfo.original.get('id'));

        let classes = ['editable-cell'];
        const errors = this.getCellErrors(columnId, userValidations.filter(/*istanbul ignore next*/c => c.severityType === 'ERROR').map(c => c.userValidationType));
        if (errors?.length) {classes.push('cell-error');}

        const warnings = this.getCellErrors(columnId, userValidations.filter(/*istanbul ignore next*/c => c.severityType === 'WARNING').map(c => c.userValidationType));
        if (warnings?.length) {classes.push('cell-warning');}

        let disabled = this.props.readOnly;
        // STUDIO-3586: Do not show background color styling for processing|processed batches on imported records
        if (this.isBatchProcessed(this.props.batchStatusType) && cellInfo.original.get('status') !== 'FAILED') {
            classes = [];
            disabled = true;
        }

        if (cellInfo.original.get('editing') === columnId) {
            // The styled div wrapper is needed to prevent the dropdown options hiding behind table
            // But this breaks on smaller resolutions by shifting dropdown far to the right
            return <TerritorySelect
                name="country"
                disabled={disabled}
                getOptionLabel={GetAttr('name')}
                getOptionValue={GetAttr('name')}
                valueKey="name"
                onChange={/*istanbul ignore next*/(e) => {
                    this.handleCellInputOnChange(userId, columnId, e.name);
                }}
                placeholder={cellInfo.original.get('country')}
                value={cellInfo.original.get('country')}
            />;
        }

        return <div className={ClassNames(classes)}>{cellInfo.original.get(columnId)}&nbsp;</div>;
    }

    stripHtmlExtractEmail(htmlString) {
        htmlString = htmlString?.replaceAll('&nbsp;', '');
        const match = htmlString?.match(/mailto:([\w.-]+@[\w.-]+\.\w+)/);
        if (match) {
            return match[1].trim();
        }

        // strip any html tags
        return htmlString?.replace(/<[^>]*>/g, '').trim();
    }

    render() {
        /*istanbul ignore next*/
        const columns = [{
            accessor: '_select',
            maxWidth: 31,
            Header:  () => <span><input type="checkbox" disabled={!this.props.users.size || this.props.readOnly} onChange={this.handleSelectAll} checked={this.state.selectAll} className="check-all"/></span>,
            Cell: c => <span className="select-user"><input type="checkbox" className="select-user" disabled={this.props.readOnly} data-user-id={c.original.get('id')} onChange={this.handleSelectRow} checked={c.original.get('selected')}/></span>
        },
        {
            accessor: '_flag',
            maxWidth: 36,
            Header:  () => (
                <strong>
                    <i className="far fa-flag" />
                </strong>
            ),
            Cell: this.renderFlagCell
        },
        {
            accessor: 'emailaddress',
            Header:  () => (
                <strong>
                    {this.context.intl.messages['accounts.account-wizard.user-list.email']}
                </strong>
            ),
            Cell: this.renderEditableDivCell
        }, {
            accessor: 'firstName',
            maxWidth: 160,
            Header:  () => (
                <strong>
                    {this.context.intl.messages['accounts.account-wizard.user-list.first-name']}
                </strong>
            ),
            Cell: this.renderEditableDivCell
        }, {
            accessor: 'lastName',
            maxWidth: 160,
            Header:  () => (
                <strong>
                    {this.context.intl.messages['accounts.account-wizard.user-list.last-name']}
                </strong>
            ),
            Cell: this.renderEditableDivCell
        }, {
            accessor: 'company',
            maxWidth: 230,
            Header:  () => (
                <strong>
                    {this.context.intl.messages['accounts.account-wizard.user-list.company']}
                </strong>
            ),
            Cell: this.renderCompanyCell
        }, {
            accessor: 'country',
            maxWidth: 163,
            Header:  () => (
                <strong>
                    {this.context.intl.messages['accounts.account-wizard.user-list.country']}
                </strong>
            ),
            Cell: this.renderCountryCell
        }, {
            accessor: '_trash',
            maxWidth: 50,
            Header:  () => (
                <div className="text-center"><strong>
                    <i className="far fa-trash" />
                </strong></div>
            ),
            Cell: c => {
                return <span><Button className="remove-user btn btn-danger-outline" disabled={this.props.readOnly} onClick={
                    this.handleToggleUserRemovalModal.bind(this, this.handleTrash.bind(this, c.original.get('id')))
                }><i className="fas fa-trash remove-user" /></Button></span>;
            }
        }];

        const modal = <Modal backdrop="static" onHide={this.handleEditUsers} show={this.state.showEditModal}>
            <Modal.Header className="bg-gray" closeButton>
                <Modal.Title className="text-center">
                    {this.context.intl.messages['accounts.account-wizard.user-list.edit-users']}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p className="text-center"><strong>{this.getSelectedUsers().size}</strong> {this.context.intl.messages['accounts.account-wizard.user-list.edit-users.modal.users-selected']}</p>
                <FormSection>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['accounts.account-wizard.user-list.country']}</ControlLabel><br />
                            <TerritorySelect
                                disabled={false}
                                getOptionLabel={GetAttr('name')}
                                getOptionValue={GetAttr('name')}
                                name="country"
                                onChange={/*istanbul ignore next*/(e) => {
                                    this.setState(() => ({editCountry: e}));
                                }}
                                value={this.state.editCountry}
                                valueKey="name"
                            />
                        </FormGroup>
                    </FormRow>
                    <div>
                        <label>{this.context.intl.messages['accounts.account-wizard.user-list.edit-users.modal.important-notes']}</label><br />
                        <ul>
                            <li><small><em>{this.context.intl.messages['accounts.account-wizard.user-list.edit-users.modal.important-notes.fields-left-blank']}</em></small></li>
                            <li><small><em>{this.context.intl.messages['accounts.account-wizard.user-list.edit-users.modal.important-notes.fields-with-values']}</em></small></li>
                            <li><small><em>{this.context.intl.messages['accounts.account-wizard.user-list.edit-users.modal.important-notes.after-confirming']}</em></small></li>
                        </ul>
                    </div>
                </FormSection>
            </Modal.Body>

            <Modal.Footer>
                <Button className="pull-left Ml(5)" onClick={this.handleEditUsers}>
                    {this.context.intl.messages['common.cancel']}
                </Button>
                <Button className="btn btn-primary-outline" type="submit"
                    disabled={false}
                    onClick={this.handleEditUsersConfirmChange}>
                    <i className="fas fa-check-square" /> {this.context.intl.messages['common.confirm-change']}
                </Button>
            </Modal.Footer>
        </Modal>;

        const tableButtonsDisabled = this.getSelectedUsers().size === 0;

        const confirmUserRemovalModal = <ConfirmUserRemovalModal
            onConfirm={this.state.onConfirmRemoval}
            onHide={this.handleToggleUserRemovalModal}
            show={this.state.showUserRemovalModal}
        />;

        let tableCaption;
        if (!this.props.readOnly && !this.isBatchProcessed(this.props.batchStatusType)) {
            tableCaption = <p><small><em>{this.context.intl.messages['accounts.account-wizard.user-list.click-on-a-cell']}</em></small></p>;
        }
        return (
            <div>
                <h3 style={{marginBottom: 0}}>
                    <i className="far fa-user" /> {this.context.intl.messages['accounts.account-wizard.user-list']}
                    <div className="pull-right">
                        <Button disabled={this.props.readOnly} className="btn-bulk btn btn-primary-outline" onClick={this.handleAddNewUser}>
                            <i className="fas fa-plus" /> {this.context.intl.messages['accounts.account-wizard.user-list.add-new-user']}
                        </Button>
                    </div>
                </h3>
                {tableCaption}
                <FormSection>
                    <div className="bulk-actions" style={{paddingBottom: 10}}>
                        <Button disabled={tableButtonsDisabled} className="btn-bulk btn btn-primary-outline" onClick={this.handleEditUsers}>
                            <i className="fas fa-pencil" /> {this.context.intl.messages['accounts.account-wizard.user-list.edit-users']}
                        </Button>&nbsp;

                        <Button disabled={tableButtonsDisabled} className="btn btn-danger-outline btn-bulk" onClick={this.handleToggleUserRemovalModal.bind(this, this.handleRemoveUsers)}>
                            <i className="fas fa-trash" /> {this.context.intl.messages['accounts.account-wizard.user-list.remove-users']}
                        </Button>
                    </div>

                    <div id={TableId}>

                        <ReactTable
                            className="-striped table-bordered table-striped responsive MB10P"
                            columns={columns}
                            data={this.props.users}
                            getTableProps={/*istanbul ignore next*/() => {
                                // Fix for dropdown option list zIndex/Scroll bug
                                return {
                                    style: {
                                        overflow: 'visible'
                                    }
                                };
                            }}
                            getTbodyProps={/*istanbul ignore next*/() => {
                                // Fix for dropdown option list zIndex/Scroll bug
                                return {
                                    style: {
                                        overflow: 'visible'
                                    }
                                };
                            }}
                            getTdProps={/*istanbul ignore next*/(_, rowInfo, column) => {
                                return {
                                    onClick: () => {
                                        const ignore = ['_select', '_trash'];
                                        if (!ignore.includes(column.id) && rowInfo.original.get('editing') !== column.id) {
                                            // Enable editing mode for company/country cells
                                            this.handleCellOnClick(rowInfo.original.get('id'), column.id);
                                        }
                                    }
                                };
                            }}
                            getNoDataProps= {/*istanbul ignore next*/() => {
                                if (this.props.users.size) {
                                    return {style: {display: 'none'}};
                                }
                                return {};
                            }}
                            id={'account-wizard-user-list-table-id'}
                            loading={false}
                            showPagination={false}
                            sortable={false}
                            resizable={false}
                            pageSize={this.props.users.size}
                        />
                    </div>
                </FormSection>
                {modal}
                {confirmUserRemovalModal}
            </div>
        );
    }
}

export default UserList;
