/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import createReactClass from 'create-react-class';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {ControlLabel, FormGroup} from 'react-bootstrap';
import Select from 'react-select';

import {FormRow, FormSection} from '../../../common/form/form';
import {ClientRepGroupActions} from '../../../security/client-rep-group/client-rep-group-actions';
import ClientRepGroupStore from '../../../security/client-rep-group/client-rep-group-store';
import {PermissionPackageActions} from '../../../security/permission-package/permission-package-actions';

export default createReactClass({
    propTypes: {
        disabled: PropTypes.bool,
        selected: PropTypes.instanceOf(Immutable.OrderedSet).isRequired
    },

    contextTypes: {
        intl: PropTypes.object.isRequired
    },

    getDefaultProps: function() {
        return {
            disabled: true
        };
    },

    getInitialState: function() {
        return {
            clientRepGroups: ClientRepGroupStore.getState().get('clientRepGroups')
        };
    },

    componentWillMount: function() {
        this.storeListeners = [
            ClientRepGroupStore.addListener(() => {
                this.setState({
                    clientRepGroups: ClientRepGroupStore.getState().get('clientRepGroups')
                });
                return;
            })
        ];
        return;
    },

    componentDidMount: function() {
        ClientRepGroupActions.get();
        return;
    },

    shouldComponentUpdate: function(nextProps, nextState) {

        if (nextProps.selected !== this.props.selected) {return true;}
        if (nextState.clientRepGroups !== this.state.clientRepGroups) {return true;}

        return false;
    },

    componentWillUnmount: function() {
        this.storeListeners.forEach(listener => listener.remove());
        return;
    },

    handleSelectClientRepGroup: function(clientRepGroups) {
        if (!clientRepGroups) {
            // Reset the value.
            PermissionPackageActions.setClientRepGroups(Immutable.OrderedSet());
            return;
        }

        // Call the select action but with immutable objects.
        PermissionPackageActions.setClientRepGroups(Immutable.fromJS(clientRepGroups).toSet().sortBy(g => g.get('name')));
        return;
    },

    render: function() {
        return (
            <FormSection title={this.context.intl.messages['presets.create.client-rep-groups.title']} iconClass="fas fa-user-secret">
                <FormRow>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['presets.create.client-rep-groups.chose']}</ControlLabel>
                        <Select
                            getOptionLabel={data => data.name}
                            getOptionValue={data => data.id}
                            isClearable={true}
                            isMulti={true}
                            isDisabled={this.props.disabled}
                            name="clientRepGroups"
                            onChange={this.handleSelectClientRepGroup}
                            options={this.state.clientRepGroups.toJS()}
                            placeholder="Select Group"
                            value={this.props.selected.toJS()}
                        />
                    </FormGroup>
                </FormRow>
            </FormSection>
        );
    }
});
