/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Modal} from 'react-bootstrap';

import {FormSection, FormItem, FormRow} from '../../../common/form/form';
import {GuildValidations} from '../../guild-store';

class ResetGuildModal extends React.Component {
    static get propTypes() {
        return {
            onHide: PropTypes.func.isRequired,
            onConfirm: PropTypes.func.isRequired,
            resetDates: PropTypes.instanceOf(Immutable.Map).isRequired,
            onChange: PropTypes.func.isRequired,
            show: PropTypes.bool.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Modal onHide={this.props.onHide} show={this.props.show}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="text-center">
                        {this.context.intl.messages['guild.reset.title']}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <h3 className="guild-dates">
                        <i className="far fa-calendar-alt"></i>{this.context.intl.messages['guild.summary.dates.title']}
                        <small>
                            <em><strong>{this.context.intl.messages['guild.summary.dates.note']}</strong>{this.context.intl.messages['guild.summary.dates.select.text']}</em>
                        </small>
                    </h3>
                    <FormSection>
                        <FormRow>
                            <FormItem attr="resetStartDate"
                                label={this.context.intl.messages['guild.summary.dates.start']}
                                type="date"
                                model={this.props.resetDates}
                                setter={this.props.onChange}
                                validations={GuildValidations.validStartDate.validations}
                            />
                        </FormRow>
                        <FormRow>
                            <FormItem attr="resetEndDate"
                                label={this.context.intl.messages['guild.summary.dates.end']}
                                type="date"
                                model={this.props.resetDates}
                                setter={this.props.onChange}
                                validations={GuildValidations.validEndDate.validations}
                            />
                        </FormRow>
                    </FormSection>
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        className="pull-left Ml(5)"
                        onClick={this.props.onHide}
                    >
                        {this.context.intl.messages['common.cancel']}
                    </Button>
                    <Button
                        bsStyle="primary"
                        type="submit"
                        onClick={this.props.onConfirm}
                    >
                        {this.context.intl.messages['common.confirm']}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ResetGuildModal;
