/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import QueryString from 'querystring-es3';
import React, {Component} from 'react';

import {SlidingPanelActions} from '../../../common/sliding-panel/sliding-panel-actions';
import {WithStoreOnRoute} from '../../../common/store-on-route';
import {Debounce} from '../../../common/utils/utils';
import LayoutStore from '../../../layout/layout-store';
import {RouterActions} from '../../../router/router-actions';
import ListTitles from '../../../titles/browse/list-titles';
import FilterOptions from '../../../titles/filter-options/filter-options';
import {TitleActions} from '../../../titles/title-actions';
import TitleStore from '../../../titles/title-store';
import SessionStore from '../../../user/session/session-store';
import {VideoDeliveryActions} from '../video-delivery-actions';

class GetExistingTitlePanel extends Component {

    static get propTypes() {
        return {
            disabled: PropTypes.bool,
            slidingPanelId: PropTypes.string.isRequired,
            location: PropTypes.object.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        const ts = TitleStore.getState();
        return {
            titles: ts.get('titles'),
            filters: ts.get('filters'),
            size: ts.get('size'),
            total: ts.get('total'),
            offset: ts.get('offset'),
            sortDirection: ts.get('sortDirection'),
            sortFieldName: ts.get('sortFieldName'),
            showFiltersPanel: LayoutStore.getState().get('showFiltersPanel')
        };
    }

    static getStores() {
        return [TitleStore, LayoutStore];
    }

    static get defaultProps() {
        return {
            disabled: false
        };
    }

    constructor(props) {
        super(props);

        this.handleAddTitle = this.handleAddTitle.bind(this);
        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
        this.handleSortChange = this.handleSortChange.bind(this);
    }

    componentDidMount() {
        TitleActions.clear();

        const savedFilters = TitleStore.getAddTitlePanelFilters();
        TitleActions.applySavedFilters(savedFilters);
        if (savedFilters) {
            RouterActions.redirect(`${location.pathname}?${QueryString.stringify(savedFilters)}`);

            // Return here so that the dashboard does not request the titles without filters
            // the next render cycle, componentWillUpdate will request the filtered titles.
            return;
        }

        TitleActions.get(this.getRouteState(), 0, this.state.size);
        return;
    }

    shouldComponentUpdate(nextProps, nextState /*, nextContext*/) {
        return nextState.titles !== this.state.titles ||
            nextState.showFiltersPanel !== this.state.showFiltersPanel;
    }

    componentWillUpdate(nextProps) {
        if (this.props.location !== nextProps.location) {

            const nrs = this.getRouteState(nextProps);
            TitleActions.get(nrs, nrs.get('offset)'), nrs.get('size'), nrs.get('sortFieldName'), nrs.get('sortDirection'));
        }
        return;
    }

    handleAddTitle(event) {
        if (~event.target.className.indexOf('add-title-panel')) {
            let titleId = event.target.getAttribute('data-title-id');
            const selectedTitle = this.state.titles.find(t => t.get('id') === parseInt(titleId));
            VideoDeliveryActions.setSelectedTitle(selectedTitle);

            const id = SessionStore.getState().getIn(['authUser', 'id']);
            TitleActions.saveAddTitlePanelFilters(id, this.getRouteState().toJS());

            SlidingPanelActions.hide(this.props.slidingPanelId);
        }

        return;
    }

    handleLoadPage(pageNumber) {
        // pageNumber x this.state.size = offset.
        this.setRouteState('offset', pageNumber * this.state.size)
            .setRouteState('size', this.state.size)
            .apply();

        const id = SessionStore.getState().getIn(['authUser', 'id']);
        TitleActions.saveAddTitlePanelFilters(id, this.getRouteState().toJS());
        return;
    }

    handleSearchTerm(term) {
        this.setRouteState('title', term)
            .setRouteState('operator', 'AND')
            .setRouteState('offset', 0)
            .apply();

        const id = SessionStore.getState().getIn(['authUser', 'id']);
        TitleActions.saveAddTitlePanelFilters(id, this.getRouteState().toJS());
        return;
    }

    handleSortChange(sortFieldName, sortDirection) {
        this.setRouteState('sortFieldName', sortFieldName)
            .setRouteState('sortDirection', sortDirection)
            .setRouteState('size', this.state.size)
            .apply();

        TitleActions.setSort(sortFieldName, sortDirection);

        const id = SessionStore.getState().getIn(['authUser', 'id']);
        TitleActions.saveAddTitlePanelFilters(id, this.getRouteState().toJS());
        return;
    }

    render() {
        const {titles, offset, size} = this.state;
        let columns = ['mpmNumber', 'title', 'titleReleaseDate', 'categoryName',
            {
                name: 'actions',
                get: function(title, props, context) {
                    return `<button
                        class="btn btn-primary add-title-panel"
                        data-title-id="${title.get('id')}"><i class="fas fa-plus add-title-panel" data-title-id="${title.get('id')}"></i>&nbsp;${context.intl.messages['common.add']}</button>`;
                },
                onClick: this.handleAddTitle
            }
        ];
        let filterOptions;
        if (this.state.showFiltersPanel) {
            filterOptions = <FilterOptions
                enableOnChange={true}
                filters={this.state.filters}
                location={this.props.location}
                showActive={true}
                showCategory={true}
                showCreateDate={true}
                showMpmNumber={true}
                showReferenceId={true}
                showReleaseDate={true}
                showStatusFields={false}
                showTitle={true}
            />;
        }
        const totalPages = Math.ceil(this.state.total/size) || 0;
        return (
            <div>
                <ListTitles
                    activePage={Math.ceil(offset/size) || 0}
                    columns={columns}
                    complete={totalPages <= 1}
                    filterButton
                    displayLinks={!this.props.disabled}
                    linkTarget="_blank"
                    location={this.props.location}
                    onPageChange={this.handleLoadPage}
                    onSearchChange={this.handleSearchTerm}
                    onSortChange={this.handleSortChange}
                    sortDirection={this.state.sortDirection}
                    sortFieldName={this.state.sortFieldName}
                    titles={titles}
                    totalPages={totalPages}
                />
                {filterOptions}
            </div>
        );
    }

}

export {GetExistingTitlePanel};

export default WithStoreOnRoute(
    Container.create(GetExistingTitlePanel)
);
