/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React, {PropTypes} from 'react';
import {Button, ControlLabel, Modal} from 'react-bootstrap';
import {FormattedMessage, FormattedDate, FormattedTime} from 'react-intl';

const ConfirmUpdatedSaveModal = ({onConfirm, onHide, updatedDate, show}) => {
    const handleSave = () => {
        onHide();
        onConfirm();
    };

    return <Modal onHide={onHide} show={show}>
        <Modal.Header className="bg-gray" closeButton>
            <Modal.Title className="text-center"><FormattedMessage id="titles.edit.confirm-modal.title"/></Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <ControlLabel><FormattedMessage id="titles.edit.confirm-modal.caption"/></ControlLabel>
            <br/>
            <ControlLabel>{<FormattedDate value={updatedDate}/>} at {<FormattedTime value={updatedDate} hour="numeric" minute="numeric"/>}.</ControlLabel>
            <br/>
            <ControlLabel><FormattedMessage id="titles.edit.confirm-modal.question"/></ControlLabel>
        </Modal.Body>

        <Modal.Footer>
            <Button
                className="pull-left Ml(5)"
                onClick={onHide}
            >
                <FormattedMessage id="common.cancel"/>
            </Button>
            <Button
                bsStyle="primary"
                type="submit"
                onClick={handleSave}
            >
                <FormattedMessage id="common.confirm"/>
            </Button>
        </Modal.Footer>
    </Modal>;
};

ConfirmUpdatedSaveModal.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    updatedDate: PropTypes.string
};

ConfirmUpdatedSaveModal.defaultProps = {
    updatedDate: undefined
};

export default ConfirmUpdatedSaveModal;
