/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';
import Select from 'react-select';

import VttFileSelector from './vtt-file-selector';
import {SOURCE_TYPE_LABELS, SOURCE_TYPES} from '../../constants';

import VideoStore, {WebVTTFilesValidations} from '~/src/assets/video/video-store';
import BaseSelect from '~/src/common/base-select/base-select';
import {FormItem} from '~/src/common/form/form';
import SectionHeader from '~/src/common/form/section-header';

type Props = {
    confirmMessage: string,
    confirmIcon: string,
    className?: string,
    description: string,
    hideSourceSelector?: boolean,
    hideTypeSelector?: boolean,
    onChangeVtt: <P extends keyof UnsavedVideoWebVtt>(prop: P, value: VideoWebVtt[P]) => void,
    onFileSelected?: (file: File) => void,
    onHide: () => void,
    onSave: () => void,
    selectedFile?: File | null,
    show: boolean,
    title: string,
    vtt: ImmutableMap<VideoWebVtt>,
}

type SourceItem = {
    label: string,
} & Entity<VideoWebVttSourceType>

export default class CreateVttModal extends React.PureComponent<Props> {
    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    private get sources(): SourceItem[] {
        const messages: Dictionary<string> = this.context.intl.messages;
        return [
            {id: SOURCE_TYPES.MASTERING, label: messages[SOURCE_TYPE_LABELS[SOURCE_TYPES.MASTERING]]},
            {id: SOURCE_TYPES.USER, label: messages[SOURCE_TYPE_LABELS[SOURCE_TYPES.USER]]},
        ];
    }

    private getSourceItem(id: VideoWebVttSourceType) {
        return this.sources.find((item) => item.id === id);
    }

    private handleFileTypeChanged = (type: Entity<WebVttFileType>) => this.props.onChangeVtt('fileType', type.id);
    private handleLanguageCodeChanged = (code: Entity) => this.props.onChangeVtt('languageCode', code.id);
    private handleSourceChanged = (item: SourceItem) => this.props.onChangeVtt('source', item.id);

    private renderFileSelector() {
        if (this.props.onFileSelected) {
            return (
                <div className="row">
                    <div className="col-md-12">
                        <SectionHeader
                            iconClass="far fa-upload"
                            level={4}
                            title={this.context.intl.messages['cc-editor.vtt.upload-file']}
                        />
                    </div>
                    <VttFileSelector
                        show={this.props.show}
                        onFileSelected={this.props.onFileSelected}
                    />
                </div>
            );
        }
    }

    private renderTypeSelector() {
        if (this.props.hideTypeSelector) {
            return;
        }
        return (
            <div className="col-md-12 form-group">
                <SectionHeader
                    iconClass="far fa-closed-captioning"
                    level={4}
                    title={this.context.intl.messages['cc-editor.vtt.field.type']}
                />

                <BaseSelect
                    classNamePrefix="dropdown"
                    disabled={false}
                    isClearable={false}
                    multi={false}
                    labelKey="label"
                    name="fileType"
                    onChange={this.handleFileTypeChanged}
                    options="webVTTFileType"
                    placeholder={this.context.intl.messages['common.please-select']}
                    store={VideoStore}
                    value={VideoStore.getWebVTTFileType(this.props.vtt.get('fileType'))}
                    validations={WebVTTFilesValidations.captionField}
                />
            </div>
        );
    }

    private renderSourceSelector() {
        if (this.props.hideSourceSelector) {
            return;
        }
        return (
            <div className="col-md-12 form-group">
                <SectionHeader
                    iconClass="fal fa-file-code"
                    level={4}
                    title={this.context.intl.messages['cc-editor.vtt.field.source']}
                />

                <Select
                    classNamePrefix="dropdown"
                    getOptionLabel={(item) => item.label}
                    getOptionValue={(item) => item.id}
                    name="sources"
                    onChange={this.handleSourceChanged}
                    options={this.sources}
                    placeholder={this.context.intl.messages['common.please-select']}
                    value={this.getSourceItem(this.props.vtt.get('source'))}
                />
            </div>
        );
    }

    render() {
        const vtt = this.props.vtt;
        const messages = this.context.intl.messages;

        let isValid = !!this.props.vtt.get('languageCode');
        if (this.props.onFileSelected) {
            isValid = isValid && !!this.props.selectedFile;
        }

        return (
            <Modal show={this.props.show} onHide={this.props.onHide} className={this.props.className}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="text-center">
                        <FormattedMessage id={this.props.title} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="clearfix">
                    <div className="form-section clearfix">
                        <p className="text-center">
                            <em>
                                <FormattedMessage id={this.props.description} />
                            </em>
                        </p>

                        <div className="row">
                            {this.renderTypeSelector()}

                            <div className="col-md-12 form-group">
                                <SectionHeader
                                    iconClass="fal fa-language"
                                    level={4}
                                    title={messages['cc-editor.vtt.field.language']}
                                />

                                <BaseSelect
                                    classNamePrefix="dropdown"
                                    disabled={false}
                                    isClearable={false}
                                    multi={false}
                                    labelKey="name"
                                    name="languageCode"
                                    onChange={this.handleLanguageCodeChanged}
                                    options="languages"
                                    placeholder={messages['common.please-select']}
                                    store={VideoStore}
                                    value={VideoStore.getLanguage(vtt.get('languageCode'))}
                                    validations={WebVTTFilesValidations.captionField}
                                />
                            </div>

                            <div className="col-md-12">
                                <FormItem
                                    attr="notes"
                                    label={<SectionHeader iconClass="far fa-comment-alt-edit" level={4} title={messages['cc-editor.vtt.field.notes']} />}
                                    model={vtt}
                                    setter={this.props.onChangeVtt}
                                    showErrorMessage={true}
                                    type="input"
                                    validations={WebVTTFilesValidations.notesField.validations}
                                />
                            </div>

                            {this.renderSourceSelector()}
                        </div>

                        {this.renderFileSelector()}
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="btn btn-success-outline pull-right"
                        disabled={!isValid}
                        onClick={this.props.onSave}
                    >
                        <i className={this.props.confirmIcon}/>
                         &nbsp;
                        <FormattedMessage id={this.props.confirmMessage} />
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
