/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';
import {timer} from 'rxjs';
import {flatMap} from 'rxjs/operators';

import {NotificationActions} from '../../common/notification/notification-actions';
import Dispatcher from '../../dispatcher/dispatcher';
import Request from '../../request';

const CONSTANTS = {
    CLEAR: 'hardac.jobs.clear',
    FILTER: {
        SET: 'hardac.jobs.filter.set',
    },
    FILTERS: {
        SET_AUTO_REFRESH: 'hardac.jobs.filter.set-auto-refresh',
        SET_AUTO_REFRESH_OBSERVABLE: 'hardac.jobs.filter.set-auto-refresh-observable'
    },
    JOB: {
        GET: {
            ERROR: 'hardac.jobs.get.error',
            START: 'hardac.jobs.get.start',
            SUCCESS: 'hardac.jobs.get.success'
        },
        REPROCESS: {
            ERROR: 'hardac.jobs.reprocess.error',
            START: 'hardac.jobs.reprocess.start',
            SUCCESS: 'hardac.jobs.reprocess.success'
        },
        SELECT_ALL: 'hardac.jobs.select_all',
        UPDATE_JOB: 'hardac.jobs.update',
    },
    STATUS_TYPE: {
        COMPLETE: {id: 'COMPLETE', icon: 'fas fa-check text-success', label: 'Completed'},
        FAILED: {id: 'FAILED', icon: 'fas fa-times text-danger', label: 'Failed'},
        RUNNING: {id: 'RUNNING', icon: 'fas fa-ellipsis-h text-primary', label: 'Running'}
    },
    JOB_TYPE: {
        CLIP: {id: 'CLIP', icon: 'far fa-cut', label: 'Clip'},
        OBJECT_DETECTION: {id: 'OBJECT_DETECTION', icon: 'far fa-archive', label: 'Archive'},
        ORDER: {id: 'ORDER', icon: 'far fa-shopping-cart', label: 'Order'},
        PROXY: {id: 'PROXY', icon: 'fas fa-tv-retro', label: 'Proxy'},
        QC_PROXY: {id: 'QC_PROXY', icon: 'fas fa-play-circle', label: 'QC Proxy'},
        TIMELINE: {id: 'TIMELINE', icon: 'far fa-stream', label: 'Timeline'},
        VIDEO_SIDECAR_AUDIO: {id: 'VIDEO_SIDECAR_AUDIO', icon: 'far fa-volume-up', label: 'Video Sidecar Audio'}
    },
};

class JobActions {
    /* istanbul ignore next */
    abandonJobs(jobs) {
        // TODO: implement logic once we have API support
        console.warn('abandonJobs', {jobs: jobs.toJSON()});
    }

    cancelAutoRefresh(autoRefreshObservable) {
        autoRefreshObservable?.unsubscribe();

        Dispatcher.dispatch({
            actionType: CONSTANTS.FILTERS.SET_AUTO_REFRESH_OBSERVABLE,
            autoRefreshObs: null
        });
    }

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
    }

    get(queryParams) {
        queryParams = queryParams.toJS();
        Dispatcher.dispatch({
            actionType: CONSTANTS.JOB.GET.START
        });

        const offset = queryParams.offset || 0;
        const size = queryParams.size || 20;
        queryParams.offset = offset;
        queryParams.size = size;

        return Request.get('integration/hardac/job').query(queryParams).exec().then(response => {
            const jobs = Immutable.fromJS(response.body.results);
            Dispatcher.dispatch({
                actionType: CONSTANTS.JOB.GET.SUCCESS,
                offset: response.body.offset,
                size: response.body.size,
                total: response.header['wbtv-total-count'],
                jobs
            });
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.JOB.GET.ERROR
            });
            NotificationActions.showAlertDanger('hardac.processes.jobs.get.error');

            throw err;
        });
    }

    retryJobs(jobs) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.JOB.REPROCESS.START
        });

        const jobRequests = jobs.map(j => {
            return Request.put(`integration/hardac/event/${j.get('processId')}/resend`).exec();
        });

        Promise.all(jobRequests)
            .then(() => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.JOB.REPROCESS.SUCCESS,
                });
                NotificationActions.showAlertSuccess('hardac.processes.reprocess.success');

                return;

            }).catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.JOB.REPROCESS.ERROR,
                });
                switch (err.status) {
                case 404:
                    NotificationActions.showAlertDanger('hardac.processes.reprocess.error.404');
                    break;
                default:
                    NotificationActions.showAlertDanger('hardac.processes.reprocess.error.422');
                    break;
                }

                throw err;
            });

        return;

    }

    selectAll(selected, ids) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.JOB.SELECT_ALL,
            selected,
            ids
        });
        return;
    }

    setAutoRefresh(isChecked, autoRefreshObservable, userId, queryParams) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.FILTERS.SET_AUTO_REFRESH,
            isChecked,
            userId
        });

        let autoRefreshObs = null;
        if (isChecked) {
            // Start auto refresh.
            autoRefreshObs = timer(
                30000, // Start in 30 sec.
                30000 // Every 30 seconds
            ).pipe(
                flatMap(() => this.get(queryParams)),
            ).subscribe();

            Dispatcher.dispatch({
                actionType: CONSTANTS.FILTERS.SET_AUTO_REFRESH_OBSERVABLE,
                autoRefreshObs
            });
        } else {
            // Cancel auto refresh
            this.cancelAutoRefresh(autoRefreshObservable);
        }
    }

    setFilter(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.FILTER.SET,
            attr,
            value
        });
    }

    updateJob(processId, attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.JOB.UPDATE_JOB,
            id: processId,
            attr: attr,
            value: value
        });
        return;
    }
}

const actions = new JobActions();

export {
    actions as JobActions,
    CONSTANTS as JobConstants
};
