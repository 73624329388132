/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';

import AiModelsActions from './ai-models-actions';
import {AssetTalentActions} from '../asset-talent-actions';
import {AssetCatalogActions} from '../catalogs/asset-catalog-actions';
import {AssetTitleActions} from '../manage-titles/asset-title-actions';

import Analytics from '~/src/analytics';
import {WBTVDAnalyticsConstants} from '~/src/analytics/wbtvd-analytics';
import {AssetTabActions} from '~/src/assets-tab/asset-tab-actions';
import {AlertTypes} from '~/src/common/notification/alert';
import {NotificationActions} from '~/src/common/notification/notification-actions';
import {SlidingPanelActions} from '~/src/common/sliding-panel/sliding-panel-actions';
import {UploadFile} from '~/src/common/utils/utils';
import Dispatcher from '~/src/dispatcher/dispatcher';
import VideoPlayerThumbnails from '~/src/player/video-player-thumbnails';
import {PreloaderActions} from '~/src/preloader/preloader-actions';
import Request from '~/src/request';
import {RouterActions} from '~/src/router/router-actions';
import {ActionHistoryConstants} from '~/src/system/action-history/action-history-actions';
import mfa from '~/src/user/session/mfa';
import SessionStore from '~/src/user/session/session-store';

const CONSTANTS = {
    AUDIO_CONFIG_TYPES: {
        STEREO_MIX: {id: 1, name: 'Stereo Mix'},
        FIVE_POINT_ONE_MIX: {id: 2, name: '5.1 Mix'},
        MONO: {id: 3, name: 'MONO'},
        MOS: {id: 4, name: 'Mos'},
        SOT: {id: 5, name: 'Sot'},
        SPLIT: {id: 6, name: 'Split'}
    },
    VIDEO: {
        ADD: 'asset.video.add',
        ADD_CAPTION: 'asset.video.add_caption',
        ALLOW_ON_ALL: 'asset.video.allow_on_all',
        CAPTION_CHANGE_ACTIVE_FLAG: 'asset.video.captions.change_active_flag',
        CHANGE_VTT_MODAL_VISIBILITY: 'asset.video.new_vtt_modal.change_visibility',
        CLEAR: 'asset.video.clear',
        DISABLE_ON_WEB: 'asset.video.disable_on_web',
        EDIT_PLATFORMS: 'asset.video.edit_platforms',
        ENRICH_CAPTION: 'asset.video.enrich_caption',
        GET: {
            ERROR: 'asset.video.get.error',
            START: 'asset.video.get.start',
            SUCCESS: 'asset.video.get.success'
        },
        GET_ALL_PLATFORMS: {
            ERROR: 'asset.video.get_all_platforms.error',
            START: 'asset.video.get_all_platforms.start',
            SUCCESS: 'asset.video.get_all_platforms.success'
        },
        GET_SELECTED_PLATFORMS: {
            ERROR: 'asset.video.get_selected_platforms.error',
            START: 'asset.video.get_selected_platforms.start',
            SUCCESS: 'asset.video.get_selected_platforms.success'
        },
        ONLY_ALLOW_ON_TV: 'asset.video.only_allow_on_tv',
        PLATFORM_TYPE: {
            DESKTOP: 'DESKTOP',
            MOBILE: 'MOBILE',
            TV: 'TV',
            WEB: 'WEB'
        },
        REMOVE_CAPTION: 'asset.video.remove_caption',
        SAVE: {
            ERROR: 'asset.video.save.error',
            START: 'asset.video.save.start',
            SUCCESS: 'asset.video.save.success'
        },
        SHOW_PLAYER: 'asset.video.show.player',
        SET_THUMBNAILS: 'asset.video.set_thumbnails.player',
        UPDATE: 'asset.video.update',
        UPDATE_NEW_VTT: 'asset.video.update_new_vtt',
        UPDATE_VTT: 'asset.video.update_vtt'
    },
    // TODO: if this will be exactly the same for video and video timeline asset types
    // this should be placed somewhere else
    CONTENT_TYPES: {
        FULL_EPISODE: {id: 101, name: 'Full Episode', color: '#E57373'},
        FULL_PROGRAM: {id: 102, name: 'Full Program', color: '#F06292'},
        FEATURE: {id: 103, name: 'Feature', color: '#BA68C8'},
        SERIES_LAUNCH_PROMO: {id: 121, name: 'Series Launch Promo', color: '#9C27B0'},
        SEASON_LAUNCH_PROMO: {id: 122, name: 'Season Launch Promo', color: '#D500F9'},
        MID_SEASON_PROMO: {id: 123, name: 'Mid-Season Promo', color: '#673AB7'},
        EPISODIC_PROMO: {id: 124, name: 'Episodic Promo', color: '#9FA8DA'},
        GENERIC_PROMO: {id: 125, name: 'Generic Promo', color: '#3949AB'},
        TEASER: {id: 126, name: 'Teaser', color: '#304FFE'},
        TRAILER: {id: 127, name: 'Trailer', color: '#90CAF9'},
        NEXT_ON: {id: 128, name: 'Next On', color: '#1E88E5'},
        RECAP: {id: 129, name: 'Recap', color: '#AAAA00'},
        TV_SPOT: {id: 130, name: 'TV Spot', color: '#AAAA00'},
        SNEAK_PEEK: {id: 131, name: 'Sneak Peek', color: '#AAAA00'},
        GREEN_SCREEN: {id: 141, name: 'Green Screen', color: '#AAAA00'},
        CLIP: {id: 142, name: 'Clip', color: '#AAAA00'},
        TAGS: {id: 143, name: 'Tags', color: '#AAAA00'},
        B_ROLL: {id: 144, name: 'B-Roll', color: '#F00170'},
        GRAPHIC: {id: 145, name: 'Graphic', color: '#AAAA00'},
        ELEMENT: {id: 146, name: 'Element', color: '#AAAA00'},
        SOURCE_MATERIALS: {id: 147, name: 'Source Materials', color: '#AAAA00'},
        INTERSTITIAL: {id: 148, name: 'Interstitial', color: '#AAAA00'},
        CLIP_REEL: {id: 149, name: 'Clip Reel', color: '#AAAA00'},
        VAM: {id: 150, name: 'VAM', color: '#AAAA00'},
        TEXTLESS_MATERIALS: {id: 151, name: 'Textless Materials', color: '#AAAA00'},
        MUSIC: {id: 152, name: 'Music', color: '#AAAA00'},
        EPK: {id: 161, name: 'EPK', color: '#AAAA00'},
        FEATURETTE: {id: 162, name: 'Featurette', color: '#AAAA00'},
        INTERVIEW: {id: 163, name: 'Interview', color: '#AAAA00'},
        PROMOTIONAL_SPECIAL: {id: 164, name: 'Promotional Special', color: '#AAAA00'},
        BTS_FINISHED: {id: 165, name: 'BTS - Finished', color: '#AAAA00'},
        PANEL: {id: 167, name: 'Panel', color: '#AAAA00'},
        EVENT_FOOTAGE: {id: 168, name: 'Event Footage', color: '#AAAA00'},
        OPEN_ENDS: {id: 169, name: 'Open Ends', color: '#AAAA00'},
        WEBISODE: {id: 170, name: 'Webisode', color: '#AAAA00'},
        SALES_PRESENTATION: {id: 181, name: 'Sales Presentation', color: '#AAAA00'},
        SIZZLE_REEL: {id: 182, name: 'Sizzle Reel', color: '#AAAA00'},
        BRAND_EXTENSION: {id: 183, name: 'Brand Extension', color: '#FFF330'},
        INTEGREATION_CLIP: {id: 184, name: 'Integration Clip', color: '#AAAA00'},
        SALES_KIT: {id: 185, name: 'Sales Kit', color: '#AAAA00'},
        MARKETING_KIT: {id: 186, name: 'Marketing Kit', color: '#AAAA00'},
        CLIENT_PRODUCED_SAMPLES: {id: 201, name: 'Client Produced Samples', color: '#AAAA00'},
        US_NETWORK_PRMOS: {id: 202, name: 'US Network Promos', color: '#FF0000'}
    },
    WEB_VTT_FILE_TYPES: {
        CLOSED_CAPTION: {id: 1, label: 'Captions', name: 'Closed Caption'},
        SUBTITLE: {id: 2, label: 'Subtitles', name: 'SubTitles'}
    },
    toArray: function(constant) {
        return Object.keys(this[constant])
            .map(k => this[constant][k])
            .sort((a, b) => a.name.localeCompare(b.name));
    },
};

CONSTANTS.CONTENT_TYPE_BY_ID = Object.keys(CONSTANTS.CONTENT_TYPES).reduce((r, sctName) => {
    let type = CONSTANTS.CONTENT_TYPES[sctName];
    r[type.id.toString()] = type;
    return r;
}, {});

class BrowserNotSupportedError extends Error {
    constructor() {
        super();

        this.i18nKey = 'player.errors.browser.not-supported';

        return;
    }
}

class DRMNotSupportedError extends Error {
    constructor() {
        super();
        // This key is used to call the nofications actions.
        this.i18nKey = 'player.errors.browser.not-supported';
        return;
    }
}

class VideoActions {
    // Return false if a stream object doesn't have any
    // of these properties.
    _areStreamsValid(streams) {
        return !['drmMpegDashUrl', 'drmHlsUrl', 'src', 'videoStreamUrl'].every(stream => !streams[stream]);
    }

    _hasDRM(video) {
        const hasDashLicense = !!video.getIn(['streams', 0, 'drmAmsCencLicenseUrl']);
        const hasDashSource = !!video.getIn(['streams', 0, 'drmMpegDashUrl']);

        const hasHlsLicense = !!video.getIn(['streams', 0, 'drmAmsHlsFairplayLicenseUrl']);
        const hasHlsSource = !!video.getIn(['streams', 0, 'drmHlsUrl']);

        // This is an error because it has a license but not a source.
        if ((hasDashLicense && !hasDashSource) ||
            (hasHlsLicense && !hasHlsSource)) {
            NotificationActions.showAlertDanger('player.errors.drm.has-license-but-no-source');

            const err = new Error(`Video ${video.get('assetId')} (${video.get('mediaKey')}) has license but no stream value.`);
            err.defaultPrevented = true;
            return {err};
        }

        // This is an error, because it has Dash DRM info but doesn't have HLS DRM.
        if (hasDashLicense !== hasHlsLicense) {
            NotificationActions.showAlertDanger('player.errors.drm.incomplete-license');

            const err = new Error(`Video ${video.get('assetId')} (${video.get('mediaKey')}) has incomplete DRM info (a license is missing).`);
            err.defaultPrevented = true;
            return {err};
        }

        if (hasDashLicense && hasHlsLicense) {
            // This means the video has valid DRM. Before returning true, let's check
            // if the browser can actually play DRM content.
            if (!this._isDRMCapable(window)) {
                const err = new DRMNotSupportedError();
                return {err};
            }

            return {hasDRM: true};
        }

        return {hasDRM: false};
    }

    _isBrowserSupported(n) {
        return !(!!n.platform && /iPad|iPhone|iPod/.test(n.platform));
    }

    _isDRMCapable(w) {
        return !!(
            w.MediaKeys || w.WebKitMediaKeys || w.MSMediaKeys
        );
    }

    addCaption(caption) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.VIDEO.ADD_CAPTION,
            caption
        });
        return;
    }

    allowOnAll() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.VIDEO.ALLOW_ON_ALL,
        });
    }

    analyticsVideoPlaybackError(video, errorCode, errorMessage) {
        let id = video.get('id');
        if (id === undefined) {
            id = video.get('assetId');
        }

        Analytics.videoPlaybackEvent(id, video.get('titleId'), WBTVDAnalyticsConstants.VIDEO.PLAYBACK_TYPE.ERROR, 0, null, null, false, errorCode, errorMessage);
    }

    changeActiveFlagForCaption(caption, active) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.VIDEO.CAPTION_CHANGE_ACTIVE_FLAG,
            active,
            caption
        });
    }

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.VIDEO.CLEAR
        });
    }

    /**
     * create a new vtt-metadata entity, upload vtt-file and update local storage
     *
     * @param {ImmutableMap<VideoWebVtt>} vtt VTT metadata
     * @param {File} file vtt file for upload
     * @param {Immutable.List<ImmutableMap<VideoWebVtt>>} storedVttFiles already stored VTT metadata entities
     * @returns void
     */
    createNewVtt(vtt, file, storedVttFiles) {
        this.hideNewVttModal();
        let version = 0;
        if (vtt.get('source') === 'USER') {
            version = 1;
            const languageCode = this.normalizeVTTLanguageCode(vtt.get('languageCode'));
            const fileType = vtt.get('fileType');
            version = storedVttFiles.reduce((memo, meta) => {
                if (meta?.get('fileType') === fileType && this.normalizeVTTLanguageCode(meta?.get('languageCode')) === languageCode) {
                    return Math.max(memo, meta.get('versionNumber'));
                }
                return memo;
            }, version);
        }
        vtt = vtt.set('versionNumber', version + 1);
        return this.saveSingleVttFile(vtt, file);
    }

    disableOnWeb() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.VIDEO.DISABLE_ON_WEB,
        });
    }

    editPlatformToList(list) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.VIDEO.EDIT_PLATFORMS,
            updateList: list
        });
    }

    enableGeorestriction(videoId, regionId) {
        return Request.put(`asset/video/${videoId}/forensic-url/geo-restrict/${regionId}`).exec().then(() => {
            NotificationActions.showAlertSuccess('asset.video.create.enable-geo-restriction.success');
        }).catch(() => {
            NotificationActions.showAlertDanger('asset.video.create.enable-geo-restriction.error');
        });
    }

    enrichVideoWebVTT(videoId, vttId) {
        return Request.get(`asset/video/${videoId}/web-vtt/${vttId}`).exec().then((resp) => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.VIDEO.ENRICH_CAPTION,
                caption: Immutable.fromJS(resp.body),
            });
        });
    }

    findById(videoId) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.VIDEO.GET.START,
            // videoId: videoId, doesn't uses in store
        });

        const actionHistoryQuery = {
            'action-object': ActionHistoryConstants.ACTION_OBJECTS.VIDEO,
            'object-id': videoId,
            offset: 0,
            size: 4
        };

        let history;
        let video;
        let webVTTFiles;

        // FIXME: this needs to be refactored! The catch are in the wrong place.
        // Ask @rvignacio for guidance.
        return Promise.all([
            Request.get(`asset/video/${videoId}`).exec(),
            Request.get(`asset/video/${videoId}/web-vtt`).exec().catch(err => {
                // Display an error message, then return null and continue the promise.
                NotificationActions.showAlertDanger('asset.video.web-vtt.load-error');
                console.error(err);
                return;
            }),
            Request.get(`asset/video/${videoId}/audio-configuration`).exec(),
            Request.get('system/action-history').query(actionHistoryQuery).exec()
        ]).spread((response, webVTTRes, audioConfigTypes, historyRes) => {
            video = response.body;
            // Need to turn tapes into a string, joined by comma's
            if (video.tapes) {
                video.tapes = video.tapes.join(', ');
            }
            if (typeof video.creditStartSeconds === 'number') {
                const date = new Date(video.creditStartSeconds * 1000);
                video.creditStartSeconds = date.toISOString().substr(11, 8).concat(':00');
            }

            //videoAudioConfig
            if (audioConfigTypes.body.length) {
                const audioConfigTypesData = audioConfigTypes.body.map(audioConfigId => {
                    const audioConfigObject = Object.keys(CONSTANTS.AUDIO_CONFIG_TYPES).find(key => CONSTANTS.AUDIO_CONFIG_TYPES[key].id === audioConfigId);
                    if (audioConfigObject) {
                        return CONSTANTS.AUDIO_CONFIG_TYPES[audioConfigObject];
                    }
                });
                video = {
                    ...video,
                    audioConfigType: audioConfigTypesData
                };
            }

            history = historyRes.body.results;
            history.sort((h1, h2) => h2.actionDate.localeCompare(h1.actionDate));
            video.active = video.active === 1;
            webVTTFiles = webVTTRes.body.map(vtt => {
                vtt.languageCode = this.normalizeVTTLanguageCode(vtt.languageCode);
                return vtt;
            });

            return Request.get('asset/video/thumbnailURL').query({'video-id': videoId}).exec();
        }).catch(err => {
            NotificationActions.showAlertDanger('asset.video.error.thumbnails');
            console.error(err);
            // Return undefined and continue the promise.
            return;
        }).then(thumbnailsRes => {
            if (thumbnailsRes) {
                video.thumbnails = thumbnailsRes.body[0].thumbnailList;
            }

            return Request.get('asset/video/streamURL').query({'video-id': videoId}).exec();
        }).catch(err => {
            if (err.status === 404 && video?.mfaRequired) {
                console.error(err);
                return;
            }

            NotificationActions.showAlertDanger('asset.video.error.streams');
            console.error(err);
            // Return undefined and continue the promise.
            return;
        }).then(streamsRes => {
            if (streamsRes) {
                video.streams = streamsRes.body;

                // adding externalUrl to webVTTFiles
                const videoWebVttList = streamsRes.body[0].videoWebVttList;
                webVTTFiles = webVTTFiles.map(webVTTFile => {
                    const webVTTIFound = videoWebVttList.find(webVTTItem => webVTTFile.videoWebVttId === webVTTItem.videoWebVttId);

                    if (webVTTIFound) {
                        webVTTFile.externalUrl = webVTTIFound.externalUrl;
                    } else {
                        webVTTFile.externalUrl = null;
                    }
                    return webVTTFile;
                });
            }

            Dispatcher.dispatch({
                actionType: CONSTANTS.VIDEO.GET.SUCCESS,
                history: Immutable.fromJS(history),
                asset: Immutable.fromJS(video),
                webVTTFiles: Immutable.fromJS(webVTTFiles)
            });

            this.getThumbnails(video);

            return;
        }).catch((err) => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.VIDEO.GET.ERROR,
                // error: err, doesn't use inside store
            });

            switch (err.status) {
            case 404:
                RouterActions.notFound();
                break;
            default:
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'asset.video.load-error');
                break;
            }
            throw err;
        });
    }

    hideNewVttModal() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.VIDEO.CHANGE_VTT_MODAL_VISIBILITY,
            show: false,
        });
    }

    getAllPlatforms() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.VIDEO.GET_ALL_PLATFORMS.START,
        });
        Request.get('partner').query({offset:0, size:9999}).exec().then(res => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.VIDEO.GET_ALL_PLATFORMS.SUCCESS,
                allPlatforms: Immutable.fromJS(res.body.results)
            });
        }) .catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.VIDEO.GET_ALL_PLATFORMS.ERROR
            });
            throw err;
        });
    }

    getThumbnails(video) {
        VideoPlayerThumbnails.GetVTTFile(video.spriteThumbVTTUrl).then(vttData => {
            return VideoPlayerThumbnails.ParseThumbnailTrack(vttData.toString(), video);
        }).then(cues => {
            const thumbnails = cues.map(cue => {
                let xywh = cue.text.split('=')[1].split(',');
                return {
                    src: video.spriteThumbJPEGUrl,
                    srcDrawRect: {
                        x: xywh[0],
                        y: xywh[1],
                        height: xywh[3],
                        width: xywh[2],
                    }
                };
            });
            Dispatcher.dispatch({
                actionType: CONSTANTS.VIDEO.SET_THUMBNAILS,
                thumbnails: Immutable.fromJS(thumbnails),
            });
            return;
        }).catch(e => {
            throw e;
        });
    }

    getSelectedPlatforms(videoId) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.VIDEO.GET_SELECTED_PLATFORMS.START,
        });
        Request.get(`asset/video/${videoId}/partner`).exec().then(res => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.VIDEO.GET_SELECTED_PLATFORMS.SUCCESS,
                selectedPlatforms: Immutable.fromJS(res.body)
            });
        }).catch(err => {
            switch (err.status) {
            case 404:
                NotificationActions.showAlertDanger('asset.video.playback-restrictions.error.partner-api');
                Dispatcher.dispatch({
                    actionType: CONSTANTS.VIDEO.GET_SELECTED_PLATFORMS.ERROR
                });
                break;
            default:
                Dispatcher.dispatch({
                    actionType: CONSTANTS.VIDEO.GET_SELECTED_PLATFORMS.ERROR
                });
                break;
            }
            throw err;
        });
    }

    normalizeVTTLanguageCode(languageCode) {
        // This is for the language codes to match with the same enum we use
        // for metadata localization.
        languageCode = (languageCode || '').toUpperCase();
        if (languageCode === 'ENG') {
            // Normalize to two letter code, this will slowly migrate values.
            languageCode = 'EN';
        }
        return languageCode;
    }

    onlyAllowOnTv() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.VIDEO.ONLY_ALLOW_ON_TV,
        });
    }

    removeCaption(videoWebVttId) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.VIDEO.REMOVE_CAPTION,
            videoWebVttId
        });
        return;
    }

    _requestToken(video) {
        const query = {
            minutes: 1,
            persistent: false,
        };

        return Request.get(`asset/video/${video.get('id')}/drm-token`)
            .query(query)
            .exec()
            .then(res => res)
            .catch(err => {
                NotificationActions.showAlertDanger('player.errors.drm.token.404');
                err.defaultPrevented = true;
                this.analyticsVideoPlaybackError(video, WBTVDAnalyticsConstants.VIDEO.PLAYBACK_ERROR_TYPE.DRM_REQUEST_FAILED, err.message);
                throw err;
            });
    }

    _save(asset, files, originalFile, posterImageFile, originalAsset) {
        const data = asset.toJS();
        const {thumbnailFrameNumber} = data;
        ['files', 'posterImageFile', 'thumbnailFrameNumber'].forEach(p => delete data[p]);
        let file;
        if (files) {
            file = files[0];
        }
        const fileExists = asset.get('videoURL');
        if (file === originalFile) {
            file = null;
        }

        if (data.active) {
            data.active = 1;
        } else {
            data.active = 0;
        }

        data.forensicVideoInventoryThreshold = parseInt(data.forensicVideoInventoryThreshold, 10);
        if (isNaN(data.forensicVideoInventoryThreshold)) {
            delete data.forensicVideoInventoryThreshold;
        }
        if (data.creditStartSeconds) {
            let timecode = new Date('1970-01-01T' + data.creditStartSeconds.substr(0, 8) + 'Z').getTime() / 1000;
            timecode = parseInt(timecode, 10);
            if (isNaN(timecode)) {
                data.creditStartSeconds = '';
            } else {
                data.creditStartSeconds = timecode;
            }
        }

        ['audioConfigType', 'drmToken', 'streams', 'thumbnails', 'videoSuperType'].forEach(p => delete data[p]);
        data.tapes = data.tapes.split(',').map(t => t.trim()).filter(t => t !== '');

        const id = data.id;

        let method = Request.post;
        let uri = 'asset/video';

        if (id !== undefined) {
            method = Request.put;
            uri = `asset/video/${id}`;
        }

        return method(uri)
            .send(data)
            .exec()
            .then(res => {
                let fileUpload;
                let posterImageUpload;
                if (file) {
                    let uploadMethod = 'POST';
                    if (fileExists) {
                        uploadMethod = 'PUT';
                    }
                    fileUpload = UploadFile(
                        uploadMethod,
                        `asset/video/${res.body.id}/file`,
                        file,
                        new XMLHttpRequest()
                    ).catch(e => {
                        throw {
                            assetId: res.body.id,
                            e
                        };
                    });
                }

                // if the poster image already exists, we need to delete it before uploading a new one as we would get a 404 otherwise
                let deleteReq = Promise.resolve();
                if (originalAsset && asset.get('thumbnailSourceS3Path') !== originalAsset.get('thumbnailSourceS3Path')) {
                    deleteReq = Request.del(`asset/video/${res.body.id}/thumbnail`).exec();
                }

                if (posterImageFile) {
                    posterImageUpload = deleteReq.then(() => {
                        return UploadFile(
                            'POST',
                            `asset/video/${res.body.id}/thumbnail`,
                            posterImageFile,
                            new XMLHttpRequest()
                        ).catch(e => {
                            throw {
                                assetId: res.body.id,
                                e
                            };
                        });
                    });
                }

                let setThumbnail;
                if (thumbnailFrameNumber) {
                    setThumbnail = Request.post(`asset/video/${id}/thumbnail/from-frame/${thumbnailFrameNumber}`).exec()
                        .catch(err => {
                            NotificationActions.showAlertDanger('asset.video.thumbnail.save-error');
                            throw err;
                        });
                }

                return [res, fileUpload, posterImageUpload, setThumbnail];
            });
    }

    save(asset, assignedCatalogs, originalAssignedCatalogs, talent, originalTalent,
        options, file, originalFile, posterImageFile, originalAsset, webVTTFiles, originalWebVTTFiles, basePath, tab,
        aiModels, originalAiModels, assignedTitles, originalAssignedTitles, manageTitles = false, selectedPlatforms) {
        const defaults = {
            messages: {
                error: 'asset.video.create.error',
                success: 'asset.video.create.success'
            }
        };
        Object.assign(defaults, options);
        Dispatcher.dispatch({
            actionType: CONSTANTS.VIDEO.SAVE.START
        });
        let id;
        let audioConfigData = [];
        if (asset.get('audioConfigType')) {
            audioConfigData = asset.get('audioConfigType').toJS();
        }
        const audioConfigTypes = audioConfigData.map(audioConfig => audioConfig.id);
        this._save(asset, file, originalFile, posterImageFile, originalAsset).spread( res => {
            id = res.body.id;

            const vttPromises = this.saveWebVTTFiles(id, webVTTFiles, originalWebVTTFiles);

            return Promise.all([
                ...AssetCatalogActions.assetCatalogSave(id, assignedCatalogs, originalAssignedCatalogs),
                ...AssetTalentActions.assetTalentSave(id, talent, originalTalent),
                Request.put(`asset/video/${id}/audio-configuration`).send(audioConfigTypes).exec(),
                vttPromises,
                AiModelsActions.saveModels(id, aiModels, originalAiModels),
                Request.put(`asset/video/${id}/partner`).send(selectedPlatforms).exec(),
            ]);
        }).spread(() => {
            let requests = [];
            if (manageTitles) {
                requests.push(AssetTitleActions.assetTitlesSave(asset, assignedTitles, originalAssignedTitles));
            }
            return Promise.all(requests);
        }).then(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.VIDEO.SAVE.SUCCESS,
                file: file[0],
                selectedPlatforms: selectedPlatforms
            });

            // Allows redirection back to brainiac proxy edit or to hardac proxy edit
            RouterActions.redirect(`${basePath}/${id}/${tab}`, true);

            NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, defaults.messages.success);
            return;
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.VIDEO.SAVE.ERROR
            });

            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, defaults.messages.error);
            throw err;
        });
        return;
    }

    saveAndAdd(asset, file, addConstants) {
        const defaults = {
            messages: {
                error: 'asset.video.create.error',
                success: 'asset.video.create.success'
            }
        };
        const preloaderSource = 'video-actions.saveAndAdd';
        PreloaderActions.show(preloaderSource);
        this._save(asset, file)
            .spread((res) => {
                PreloaderActions.hide(preloaderSource);
                Dispatcher.dispatch({
                    actionType: CONSTANTS.VIDEO.SAVE.SUCCESS,
                    // id: res.body.id, no need inside store
                    file
                });
                asset = asset.set('id', res.body.id);
                SlidingPanelActions.hide('addNew');
                AssetTabActions.add('video', Immutable.List([asset]), addConstants);
                NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, defaults.messages.success);
                return;
            })
            .catch(err => {
                PreloaderActions.hide(preloaderSource);
                Dispatcher.dispatch({
                    actionType: CONSTANTS.VIDEO.SAVE.ERROR
                });
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, defaults.messages.error);
                throw err;
            });
        return;
    }

    /**
     * save vtt metadata entity and upload new file if needed
     *
     * @param {ImmutableMap<VideoWebVtt>} vtt metadata entity
     * @param {File | Blob | null} file file for upload
     */
    saveSingleVttFile(vtt, file) {
        const videoId = vtt.get('videoId');
        const id = vtt.get('videoWebVttId');

        let request;
        if (id) {
            request = Request.put(`asset/video/${videoId}/web-vtt/${id}/detail`).send(vtt.toJS()).exec();
        } else {
            request = Request.post(`asset/video/${videoId}/web-vtt/detail`).send(vtt.toJS()).exec();
        }

        request.then((vttRes) => {
            if (file) {
                let method = 'POST';
                if (id) {
                    method = 'PUT';
                }
                const uri = `asset/video/${videoId}/web-vtt/${vttRes.body.videoWebVttId}/file`;
                return Promise.all([vttRes.body, UploadFile(method, uri, file)]);
            }
            return Promise.all([vttRes.body]);
        }).spread((savedVtt) => {
            // reload vtt after uploading file to get latest metadata
            return Request.get(`asset/video/${videoId}/web-vtt/${savedVtt.videoWebVttId}`).exec();
        }).then((vttRes) => {
            NotificationActions.showAlertSuccess('cc-editor.vtt.save.success');
            if (!id) {
                this.addCaption(Immutable.fromJS(vttRes.body));
            }
            return Request.put(`asset/video/${videoId}/regen-manifest`).send({}).exec().then(r => r).catch(err => {
                NotificationActions.showAlertDanger('asset.video.edit.captions.vtt-file.manifest-regen.error');
                err.prevented = true;
                throw err;
            });
        }).catch(e => {
            if (!e.prevented) {
                NotificationActions.showAlertDanger('asset.video.edit.captions.vtt-file.upload.error');
            }
            throw e;
        });
    }

    /**
     * save VTT files
     *
     * @param {number} videoId video id
     * @param {Immutable.List<ImmutableMap<VideoWebVtt>>} webVTTFiles
     * @param {Immutable.List<ImmutableMap<VideoWebVtt>>} originalWebVTTFiles
     */
    saveWebVTTFiles(videoId, webVTTFiles, originalWebVTTFiles) {
        /**
         * @param {Dictionary<ImmutableMap<VideoWebVtt>>} map vtt files keyed by id
         * @param {ImmutableMap<VideoWebVtt>} file vtt file
         */
        const keyByVttId = function(map, file) {
            const id = file.get('videoWebVttId');
            map[id] = file;
            return map;
        };

        const mapOfFiles = webVTTFiles.reduce(keyByVttId, {});
        let promises = [];

        /**
         * @param {ImmutableMap<VideoWebVtt>} original original vtt file
         */
        const requestMaker = function(original) {
            const id = original.get('videoWebVttId');
            const file = mapOfFiles[id];
            if (!file) {
                promises.push(Request.del(`asset/video/${videoId}/web-vtt/${id}/file`).exec().then(r => r).catch(err => {
                    NotificationActions.showAlertDanger('asset.video.edit.captions.vtt-file.delete.error');
                    err.prevented = true;
                    throw err;
                }));
                return;
            }

            if (original.get('active') !== file.get('active')) {
                const data = file.delete('externalUrl').set('languageCode', file.get('languageCode', '').toLowerCase());
                promises.push(Request.put(`asset/video/${videoId}/web-vtt/${id}/detail`).send(data.toJS()).exec());
                return;
            }
        };

        // we are going thru original files to have ability to remove file if needed
        originalWebVTTFiles.forEach(requestMaker);

        if (promises.length === 0) {
            return;
        }

        return Promise.all(promises).then(() => {
            return Request.put(`asset/video/${videoId}/regen-manifest`).send({}).exec().then(r => r).catch(err => {
                NotificationActions.showAlertDanger('asset.video.edit.captions.vtt-file.manifest-regen.error');
                err.prevented = true;
                throw err;
            });
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.VIDEO.SAVE.ERROR
            });

            if (!err.prevented) {
                NotificationActions.showAlertDanger('asset.video.edit.captions.vtt-file.upload.error');
            }
            throw err;
        });
    }

    showCreateNewVttModal() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.VIDEO.CHANGE_VTT_MODAL_VISIBILITY,
            show: true,
        });
    }

    /*istanbul ignore next*/
    showPlayer(video, constants = CONSTANTS.VIDEO) {
        // Only request video if incomplete. Right now, one of the few fields
        // we can use to know if we have an "Asset Search Result" or a full
        // fledged "Video" is the forensicUrlRequired field.
        let videoReq = Promise.resolve();
        if (video.get('forensicUrlRequired') === undefined ) {
            videoReq = Request.get(`asset/video/${video.get('assetId')}`).exec();
        }

        return videoReq.then(videoRes => {
            if (videoRes) {
                video = video.merge(videoRes.body);
            }

            const userId = SessionStore.getState().getIn(['authUser', 'id']);
            let mfaPromise = Promise.resolve();
            if (video.get('mfaRequired')) {
                mfaPromise = mfa.RequestMFA(userId, false);
            }

            return mfaPromise.catch(err => {
                switch (true) {
                case err instanceof mfa.MFARejectedError:
                    NotificationActions.showAlertDanger('player.errors.mfa.rejected');
                    break;
                case err instanceof mfa.MFARequestError:
                    NotificationActions.showAlertDanger('player.errors.mfa.status');
                    break;
                case err instanceof mfa.MFAUnknownStatusError:
                case err instanceof mfa.MFAUnregisteredError:
                    break;
                default:
                    NotificationActions.showAlertDanger('player.errors.mfa.400');
                    break;
                }

                // Set the error as default prevented so that the next handler
                // doesn't add alerts on top of existing alerts.
                err.defaultPrevented = true;
                throw err;
            });
        }).then(
            () => {
                if (video.get('forensicUrlRequired')) {
                    return Request.get(`asset/video/${video.get('id')}/forensic-url`)
                        .exec()
                        .catch(err => {
                            NotificationActions.showAlertDanger('player.errors.streams.400');
                            err.defaultPrevented = true;
                            throw err;
                        });
                }
                return Request.get('asset/video/streamURL').query({
                    'video-id': video.get('id')
                }).exec().catch(err => {
                    NotificationActions.showAlertDanger('player.errors.streams.400');
                    err.defaultPrevented = true;
                    throw err;
                });
            }
        ).then(streamsRes => {
            let arr = streamsRes.body;
            if (arr.id) {
                arr = [streamsRes.body];
            }
            arr.forEach(streams => {
                if (!this._areStreamsValid(streams)) {
                    NotificationActions.showAlertDanger('player.errors.no-sources');
                    const err = new Error(`No playable sources found for video ${video.get('id')} (${video.get('mediaKey')}).`);
                    err.defaultPrevented = true;
                    throw err;
                }
                video = video.set('spriteThumbVTTUrl', Immutable.fromJS(streams.spriteThumbVTTUrl))
                    .set('spriteThumbJPEGUrl', Immutable.fromJS(streams.spriteThumbJPEGUrl))
                    .set('streams', Immutable.fromJS([streams]));
            });

            const {hasDRM, err} = this._hasDRM(video);
            if (err) {
                if (err instanceof DRMNotSupportedError) {
                    this.analyticsVideoPlaybackError(video, WBTVDAnalyticsConstants.VIDEO.PLAYBACK_ERROR_TYPE.DRM_NOT_SUPPORTED);
                } else {
                    this.analyticsVideoPlaybackError(video, WBTVDAnalyticsConstants.VIDEO.PLAYBACK_ERROR_TYPE.UNKNOWN_ERROR, err.message);
                }
                // Throw instead of return to log the error to the console and exit.
                throw err;
            }

            let drmPromise = Promise.resolve();
            if (hasDRM) {
                if (!this._isBrowserSupported(navigator)) {
                    this.analyticsVideoPlaybackError(video, WBTVDAnalyticsConstants.VIDEO.PLAYBACK_ERROR_TYPE.BROWSER_NOT_SUPPORTED);
                    throw new BrowserNotSupportedError();
                }
                drmPromise = this._requestToken(video);
            }

            drmPromise.then(drmTokenRes => {
                if (drmTokenRes) {
                    video = video.set('drmToken', Immutable.fromJS(drmTokenRes.body));
                }

                Dispatcher.dispatch({
                    actionType: constants.SHOW_PLAYER,
                    video,
                });

                return;
            });
        }).catch(err => {
            if (!err) {
                return;
            }

            if (!err.defaultPrevented) {
                NotificationActions.showAlertDanger('titles.videos.error');
            }

            throw err;
        });
    }

    update(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.VIDEO.UPDATE,
            attr: attr,
            value: value
        });

        return;
    }

    updateThumbnailsStartTime(duration, thumbanils) {
        const step = duration/thumbanils.length;
        let i = 0;
        const thumbnailsUpdated = thumbanils.map(thumbnail => {
            thumbnail.start = i;
            i += step;
            return thumbnail;
        });
        Dispatcher.dispatch({
            actionType: CONSTANTS.VIDEO.SET_THUMBNAILS,
            thumbnails: Immutable.fromJS(thumbnailsUpdated)
        });
    }

    updateVtt(attr, vttId, value) {
        if (typeof vttId !== 'number') {
            return;
        }
        Dispatcher.dispatch({
            actionType: CONSTANTS.VIDEO.UPDATE_VTT,
            attr,
            vttId,
            value,
        });
    }

    updateNewVtt(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.VIDEO.UPDATE_NEW_VTT,
            attr,
            value,
        });
    }

}

const actions = new VideoActions();

export {
    actions as VideoActions,
    CONSTANTS as VideoConstants,
    DRMNotSupportedError,
    BrowserNotSupportedError
};

