/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React from 'react';
import {Button, Modal} from 'react-bootstrap';

class RetryModal extends React.Component {

    static get propTypes() {
        return {
            onClose: PropTypes.func.isRequired,
            onRetry: PropTypes.func.isRequired,
            show: PropTypes.bool.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
        };
    }

    constructor(props) {
        super(props);

        this.handleClose = this.handleClose.bind(this);
        this.handleRetry = this.handleRetry.bind(this);

        return;
    }

    handleClose() {
        this.props.onClose();
    }

    handleRetry() {
        this.props.onRetry();
    }

    render() {
        return (
            <Modal backdrop="static" className="skin-dark" show={this.props.show} onHide={this.handleClose}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="modal-title text-center">{this.context.intl.messages['hardac.processes.jobs.retry.modal.title']}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-center">
                        {this.context.intl.messages['hardac.processes.jobs.retry.modal.body']}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-success-outline" onClick={this.handleRetry}>{this.context.intl.messages['hardac.processes.jobs.retry']}</Button>
                    <Button bsStyle="default" className="pull-left" onClick={this.handleClose}>{this.context.intl.messages['common.cancel']}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default RetryModal;
