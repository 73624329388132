/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
*/

import ClassNames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, Row, Col} from 'react-bootstrap';
import ReactTable from 'react-table';

import {AudioProfileActions, AudioProfileConstants} from './audio-profile-actions';
import AudioProfileSelector from './audio-profile-selector';
import AudioProfileStore from './audio-profile-store';
import BaseSelect from '../../common/base-select/base-select';

import {GetNoDataProps} from '~/src/common/react-table/react-table';

import '../timeline/timeline.less';
import 'react-table/react-table.css';

const noop = () => void 0;

export default class AudioProfileTable extends Component {
    static get propTypes() {
        return {
            audioProfile: PropTypes.instanceOf(Immutable.List).isRequired,
            audioProfilePresetId: PropTypes.number.isRequired,
            id: PropTypes.number,
            isDisabled: PropTypes.bool,
            mediaInfoTotalAudioTracks: PropTypes.number,
            showCount: PropTypes.bool.isRequired,
            onAdd: PropTypes.func,
            onDelete: PropTypes.func,
            onReorder: PropTypes.func,
            onSelectAudioProfilePreset: PropTypes.func,
            onSelectProxy: PropTypes.func,
            onSoloAudioChannel: PropTypes.func,
            onToggleTrack: PropTypes.func,
            onUpdateAudioChannelType: PropTypes.func,
            readOnly: PropTypes.bool,
        };
    }

    static get defaultProps() {
        return {
            isDisabled: false,
            id: Math.ceil(Math.random() * 100000),
            onAdd: noop,
            onDelete: noop,
            onReorder: noop,
            onSelectAudioProfilePreset: noop,
            onSelectProxy: undefined,
            onSoloAudioChannel: noop,
            onToggleTrack: noop,
            onUpdateAudioChannelType: noop,
            readOnly: false,
            showCount: true,
            mediaInfoTotalAudioTracks: 0
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
            router: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.handleAddAudioChannelClick = this.handleAddAudioChannelClick.bind(this);
        this.handleReorder = this.handleReorder.bind(this);
        this.handleSelectAudioProfilePreset = this.handleSelectAudioProfilePreset.bind(this);

        return;
    }

    componentDidMount() {
        // Get options for the select, but do it only once.
        if (!AudioProfileStore.getState().get('audioLookups').size) {
            AudioProfileActions.getAudioLookups();
        }

        if (!AudioProfileStore.getState().get('audioProfilePresets').size) {
            AudioProfileActions.getAudioProfilePresets();
        }
    }

    handleAddAudioChannelClick() {
        this.props.onAdd();
    }

    handleDeleteAudioChannelClick(index) {
        this.props.onDelete(index);
    }

    handleReorder(e, row, position) {
        if (e.target.draggable === true) {
            let dragDiff = Math.round((parseInt(this.state.dragFrom, 10) - parseInt(position, 10)) / 50);
            dragDiff = -(dragDiff);
            const newPosition = row.index + dragDiff;
            this.props.onReorder(row.index, Math.abs(newPosition));
        }
    }

    handleSelectAudioProfilePreset(audioProfilePreset) {
        this.props.onSelectAudioProfilePreset(Immutable.fromJS(audioProfilePreset));
    }

    handleSelectAudioChannelType(index, attr) {
        // See BRAIN-2056 for business rule logic regarding stereo L/R selection/calculation
        if (this.props.audioProfilePresetId === AudioProfileConstants.AUDIO_PROFILE.CUSTOM_PROFILE_ID) {
            switch (attr.value) {
            case AudioProfileConstants.CHANNELS.STEREO_MIX_L:
                this.props.onSelectProxy(index, 'isProxyLeft');
                break;
            case AudioProfileConstants.CHANNELS.STEREO_MIX_R:
                this.props.onSelectProxy(index, 'isProxyRight');
                break;
            default:
                // Unset proxy L/R for this channel since it is no longer stereo mix L/R
                const currentChannel = this.props.audioProfile.get(index);
                if (currentChannel.get('isProxyLeft')) {
                    this.props.onSelectProxy(-1, 'isProxyLeft');
                }
                if (currentChannel.get('isProxyRight')) {
                    this.props.onSelectProxy(-1, 'isProxyRight');
                }
                break;
            }
        }

        this.props.onUpdateAudioChannelType(index, attr);
    }

    composeAudioTracks(noTrack, plural, singular, totalAudioTracks) {
        let first = noTrack;
        /*istanbul ignore next*/
        if (totalAudioTracks > 0) {
            /*istanbul ignore next*/
            first = totalAudioTracks + ' ';
        }
        let second = singular;
        /*istanbul ignore next*/
        if (totalAudioTracks !== 1) {
            second = plural;
        }

        return (
            <h4>
                <i className="fas fa-volume-up" /> {first}{second}
            </h4>
        );
    }

    render() {
        const {audioProfile, showCount} = this.props;
        // True if we are playing a single audio channel (soloing). It is used to set
        // the right icon in actions.
        const isPlayingSolo = (audioProfile.size > 1) && (audioProfile.filter(c => !c.get('isMuted')).size === 1);
        let soloChannelId = undefined;
        if (isPlayingSolo) {
            soloChannelId = audioProfile.find(c => !c.get('isMuted')).get('audioChannel');
        }

        const noTrack = this.context.intl.messages['hardac.audio-profile.tracks.no'] + ' ';
        const plural = this.context.intl.messages['hardac.audio-profile.tracks.plural'];
        const singular = this.context.intl.messages['hardac.audio-profile.tracks.singular'];
        let placeholder = this.context.intl.messages['hardac.audio-profile.select'];
        const {mediaInfoTotalAudioTracks} = this.props;
        if (!mediaInfoTotalAudioTracks) {
            placeholder = this.context.intl.messages['hardac.audio-profile.select-no-audio-presets'];
        }

        let columns = [{
            Header: <strong>{this.context.intl.messages['hardac.audio-profile.channel']}</strong>,
            headerClassName: 'h-alignment',
            className: 'h-alignment',
            width: 32,
            Cell: /*istanbul ignore next*/c => {
                return (
                    <div>
                        {c.original.get('audioChannel')}
                    </div>
                );
            }
        }, {
            Header:  <strong>{this.context.intl.messages['hardac.audio-profile.channel-type']}</strong>,
            className: 'select-alignment',
            Cell: /*istanbul ignore next*/c => {
                return (
                    <div style={{marginTop: '7px'}}>
                        <BaseSelect
                            classNamePrefix="dropdown"
                            disabled={this.props.readOnly || this.props.isDisabled}
                            labelKey={'description'}
                            isClearable={false}
                            multi={false}
                            name="audio-profile-type"
                            onChange={this.handleSelectAudioChannelType.bind(this, c.index)}
                            options="audioLookups"
                            store={AudioProfileStore}
                            value={AudioProfileStore.getLookup(c.original.get('type'))}
                            valueKey={'value'}
                        />
                    </div>
                );
            }
        }, {
            Header: <i className="far fa-volume-up"/>,
            headerClassName: 'h-alignment',
            className: 'h-alignment',
            width: 97,
            Cell: /*istanbul ignore next*/c => {
                const isMuted = c.original.get('isMuted');
                const isSoloed = c.original.get('audioChannel') === soloChannelId;

                // Only show the solo audio channel button if the parent
                // provided an implementation for onSoloAudioChannel.
                let solo;
                if (this.props.onSoloAudioChannel !== noop) {
                    solo = (
                        <Button
                            bsSize="sm"
                            className={ClassNames({'Mr(3px) btn btn-success-outline': isSoloed, 'Mr(3px) btn btn-success': !isSoloed})}
                            disabled={this.props.isDisabled}
                            onClick={this.props.onSoloAudioChannel.bind(this, c.index, isMuted)}
                        >
                            S
                        </Button>
                    );
                }

                return (
                    <div>
                        <Button
                            bsSize="sm"
                            className={ClassNames({'Mr(3px) btn btn-info-outline': isMuted, 'Mr(3px) btn btn-info': !isMuted})}
                            disabled={this.props.isDisabled}
                            onClick={this.props.onToggleTrack.bind(this, c.index)}
                        >
                            M
                        </Button>
                        {solo}
                    </div>
                );
            },
            show: !this.props.readOnly
        }];

        let audioProfileSelector;
        if (!this.props.readOnly) {
            audioProfileSelector = <AudioProfileSelector
                disabled={!mediaInfoTotalAudioTracks || this.props.isDisabled}
                mediaInfoTotalAudioTracks={mediaInfoTotalAudioTracks}
                onChange={this.handleSelectAudioProfilePreset}
                presetId={this.props.audioProfilePresetId}
                placeholder={placeholder}
            />;
        }

        return (
            <div className="audio-profile">
                <Row className="padding-y-10">
                    <Col md={6}>
                        {showCount && this.composeAudioTracks(noTrack, plural, singular, parseInt(mediaInfoTotalAudioTracks))}
                    </Col>
                    <Col md={6}>
                        {audioProfileSelector}
                    </Col>
                </Row>
                <Row>
                    <ReactTable
                        className="-striped table-bordered table-striped responsive audio-profile-table"
                        columns={columns}
                        data={audioProfile}
                        disabled={this.props.isDisabled}
                        getNoDataProps= {GetNoDataProps(this.props.audioProfile.size)}
                        getTdProps={/*istanbul ignore next*/(state, row, td) => {
                            const isDraggable = td.className.indexOf('row-reorder-handle') !== -1;
                            return {
                                draggable: isDraggable,
                                onDragEnd: e => this.handleReorder(e, row, e.screenY),
                                // Note: do not refactor this to the function form of setState, it will break the reorder functionality,
                                // due to the event recycling and e.screenY being undefined.
                                onDragStart: e => {
                                    e.dataTransfer.setData('application/node type', this);
                                    this.setState({dragFrom: e.screenY});
                                }
                            };
                        }}
                        id={`audio-profile-${this.props.id}`}
                        loading={false}
                        // This limit of 26 is a business rule. It's the max number of audio tracks.
                        pageSize={26}
                        resizable={false}
                        showPagination={false}
                        sortable={false}
                    />
                </Row>
            </div>
        );
    }
}

export {
    AudioProfileSelector
};
