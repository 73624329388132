/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

const BatchStatus = props => {
    const {completedMessage, completedMessageTooltip, executedAt, pendingMessage, pendingMessageTooltip} = props;
    const id = Math.round(Math.random() * 1000);
    let status;
    if (executedAt) {
        status = (
            <OverlayTrigger placement="top" overlay={
                <Tooltip id={`tooltip-${id}`} positionTop={0}>
                    {completedMessageTooltip}
                </Tooltip>}>
                <span className="label bg-white"><i className="fa fa-star-o"></i>
                    <span className="hidden-xs hidden-sm">
                        {completedMessage}
                    </span>
                </span>
            </OverlayTrigger>
        );
    } else {
        status = (
            <OverlayTrigger placement="top" overlay={
                <Tooltip id={ `tooltip-${ id }` } positionTop={ 0 }>
                    {pendingMessageTooltip}
                </Tooltip>
            }>
                <span className="label bg-yellow">
                    <i className="fa fa-ellipsis-h"></i>
                    <span className="hidden-xs hidden-sm">
                        {pendingMessage}
                    </span>
                </span>
            </OverlayTrigger>
        );
    }
    return (
        <div style={{paddingBottom: '3px'}}>
            {status}
        </div>
    );
};

BatchStatus.defaultProps = {
    executedAt: null
};

BatchStatus.propTypes = {
    completedMessage: PropTypes.string.isRequired,
    completedMessageTooltip: PropTypes.string.isRequired,
    executedAt: PropTypes.string,
    pendingMessage: PropTypes.string.isRequired,
    pendingMessageTooltip: PropTypes.string.isRequired
};

export default BatchStatus;
