/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {AudioProfileConstants} from './audio-profile-actions';
import Dispatcher from '../../dispatcher/dispatcher';

class AudioProfileStore extends ReduceStore {
    getLookup(type) {
        return this.getState().get('audioLookups').filter(lookup => type === lookup.get('value')).get(0);
    }

    getLookupByDescription(description) {
        return this.getState().get('audioLookups').find(lookup => description === lookup.get('description'));
    }

    getPreset(id) {
        return this.getState().get('audioProfilePresets').filter(preset => id === preset.get('id')).get(0);
    }

    getInitialState() {
        return Immutable.Map({
            audioLookups: Immutable.List(),
            audioProfilePresets: Immutable.List()
        });
    }

    reduce(state, action) {
        switch (action.actionType) {
        case AudioProfileConstants.AUDIO_PROFILE.LOOKUPS.GET.SUCCESS:
            state = state.merge({
                audioLookups: action.audioLookups,
            });
            break;
        case AudioProfileConstants.AUDIO_PROFILE.PRESETS.GET.SUCCESS:
            state = state.merge({
                audioProfilePresets: action.audioProfilePresets,
            });
            break;
        }

        return state;
    }
}

export default new AudioProfileStore(Dispatcher);
