/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Checkbox} from 'react-bootstrap';
import {Link} from 'react-router';

import AccountNavigation from '../account-navigation';
import ApplicantNavigation from '../applicant-navigation';
import Delivery from './delivery/delivery';
import DeliveryStore from './delivery/delivery-store';
import Management from './management/management';
import Permissions from './permissions/permissions';
import PermissionsAudit from './permissions-audit/permissions-audit';
import Subscriptions from './subscriptions/subscriptions';
import SubscriptionsStore from './subscriptions/subscriptions-store';
import Summary from './summary/summary';
import UserErrors from './user-errors/user-errors';

import DocumentTitle from '~/src/common/document-title';
import {MODAL_TYPE} from '~/src/common/notification/modal';
import {NotificationActions} from '~/src/common/notification/notification-actions';
import Panel from '~/src/common/panel/panel';
import {Tab, Tabs} from '~/src/common/routing-tab/routing-tab';
import {History, SideNotes} from '~/src/common/side-notes/side-notes';
import {ErrorsWarnings} from '~/src/common/validations/errors-warnings';
import Validations, {SEVERITY} from '~/src/common/validations/validations';
import ActionsMenu from '~/src/layout/actions-menu/actions-menu';
import StickyHeader from '~/src/layout/sticky-header/sticky-header';
import Preloader from '~/src/preloader/';
import PreloaderStore from '~/src/preloader/preloader-store';
import {RouterActions} from '~/src/router/router-actions';
import {ActionHistoryActions, ActionHistoryConstants} from '~/src/system/action-history/action-history-actions';
import SessionStore from '~/src/user/session/session-store';
import {UserActions, UserConstants} from '~/src/user/user-actions';
import UserStore from '~/src/user/user-store';

/**
 * Validate custom props inside the route object.
 */
const routePropValidator = function(props) {
    // Test the main prop.
    if (!props.route || typeof props.route !== 'object') {
        throw new Error(`Required prop route was not found or is not of type Object. Found "${props.route}".`);
    }

    // Test the route.mode value.
    if (!~['create', 'edit'].indexOf(props.route.mode)) {
        throw new Error(`Required prop route.mode was not found or is not one of ["create", "edit"]. Found "${props.route.mode}".`);
    }

    // Test the route.entity value.
    if (!~['account', 'applicant'].indexOf(props.route.entity)) {
        throw new Error(`Required prop route.entity was not found or is not one of ["account", "applicant"]. Found "${props.route.entity}".`);
    }

    // Finally, forbid some combinations.
    if (props.route.entity === 'account' && props.route.mode === 'create') {
        throw new Error('Entity:"account" and mode:"create" is not a valid pair.');
    }

    return;
};

export {routePropValidator as RoutePropValidator};


class Create extends React.Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            params: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired,
            /**
             * Validate custom props inside the route object.
             */
            route: routePropValidator // eslint-disable-line react/require-default-props
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            duplicatedEmails: UserStore.getState().get('duplicatedEmails'),
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible'),
            profile: DeliveryStore.getState().get('profile'),
            notifications: DeliveryStore.getState().get('notifications'),
            user: UserStore.getState().get('user'),
            userType: UserStore.getState().get('userType')
        };
    }

    static getStores() {
        return [DeliveryStore, PreloaderStore, UserStore];
    }

    static getPermissions() {
        return {
            account: {
                canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.EDIT),
                management: {
                    canBanUser: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.MANAGEMENT.BAN),
                    canDeactivate: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.MANAGEMENT.DEACTIVATE),
                    canRead: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.MANAGEMENT.READ),
                    canUnlock: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.MANAGEMENT.UNLOCK),
                    canSetPassword: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.MANAGEMENT.SET_PASSWORD)
                },
                permissions: {
                    canCopyPermissions: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.IMPERSONATE_USER),
                    canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.EDIT),
                    canEditRolesGroups: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.PERMISSIONS.EDIT_ROLES_GROUPS),
                    canPermissionPackages: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.PERMISSIONS.PERMISSION_PACKAGES),
                    canRead: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.PERMISSIONS.READ)
                },
                permissionsAudit: {
                    canRead: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.PERMISSIONS_AUDIT.READ)
                },
                subscriptions: {
                    canRead: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.SUBSCRIPTIONS.READ),
                    canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.SUBSCRIPTIONS.EDIT)
                },
                userErrors: {
                    canRead: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.VIEW_USER_ERRORS),
                }
            },
            applicant: {
                canCreate: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.APPLICANTS.CREATE),
                canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.APPLICANTS.EDIT),
                permissions: {
                    canApprove: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.APPLICANTS.PERMISSIONS.APPROVE),
                    canApproveWithoutPermissions: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.APPLICANTS.PERMISSIONS.APPROVE_WITHOUT_PERMISSION),
                    canDeny: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.APPLICANTS.PERMISSIONS.DENY),
                    canEditRolesGroups: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.APPLICANTS.PERMISSIONS.EDIT_ROLES_GROUPS),
                    canPermissionPackages: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.APPLICANTS.PERMISSIONS.PERMISSION_PACKAGES),
                    canRead: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.APPLICANTS.PERMISSIONS.READ),
                    canReject: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.APPLICANTS.PERMISSIONS.REJECT),
                    canValidate: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.APPLICANTS.PERMISSIONS.VALIDATE)
                }
            },
            assets: {
                canReadVideoAssets: SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.VIDEO.READ)
            },
            stations: {
                canViewStations: SessionStore.canUser(SessionStore.PERMISSIONS.STATIONS.CONFIGURATION)
            }
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            activeTab: 0,
            deniedComments: '',
            notes: Immutable.List(),
        }, this.constructor.calculateState());

        this.addNote = this.addNote.bind(this);
        this.doSave = this.doSave.bind(this);
        this.handleCancelEdit = this.handleCancelEdit.bind(this);
        this.handleDeniedCommentsChange = this.handleDeniedCommentsChange.bind(this);
        this.handleElasticSearchSync = this.handleElasticSearchSync.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleSendActivationEmail = this.handleSendActivationEmail.bind(this);
        this.initStores = this.initStores.bind(this);
        this.isDirty = this.isDirty.bind(this);
        this.showApproveApplicant = this.showApproveApplicant.bind(this);
        this.showDenyApplicant = this.showDenyApplicant.bind(this);
        this.warnOnTrue = this.warnOnTrue.bind(this);
    }

    componentDidMount() {
        UserActions.clear();
        this.initStores(this.props);
        RouterActions.registerRedirectCheck((location) => {
            return this.isDirty(location);
        });
        return;
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.route.mode !== this.props.route.mode ||
            nextProps.route.entity !== this.props.route.entity ||
            nextProps.params.id !== this.props.params.id) {
            UserActions.clear();
            this.initStores(nextProps);
        }
        RouterActions.registerRedirectCheck((location) => {
            return this.isDirty(location);
        });
        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.notifications !== this.state.notifications) {
            return true;
        }
        return false;
    }

    addNote(description) {
        ActionHistoryActions.addNote(this.props.params.id, description, ActionHistoryConstants.ACTION_OBJECTS.USER);
    }

    doSave() {
        const options = {
            messages: {
                error: `accounts.${this.props.route.mode}.${this.props.route.entity}.error`,
                success: `accounts.${this.props.route.mode}.${this.props.route.entity}.success`
            }
        };

        UserActions.saveUser(
            UserStore.getState().get('user'),
            UserStore.getState().get('originalUser'),
            options,
            DeliveryStore.getState().get('profile'),
            DeliveryStore.getState().get('originalProfile'),
            DeliveryStore.getState().get('notifications'),
            DeliveryStore.getState().get('originalNotifications'),
            SubscriptionsStore.getState().get('subscriptions'),
            SubscriptionsStore.getState().get('originalSubscriptions')
        );
        return;
    }

    handleCancelEdit() {
        if (this.props.route.entity === 'account') {
            RouterActions.redirect('/accounts', true);
        } else {
            RouterActions.redirect('/accounts/applicants', true);
        }
        return;
    }

    handleDeniedCommentsChange(e) {
        this.setState({
            deniedComments: e.target.value
        });
        return;
    }

    handleElasticSearchSync() {
        UserActions.syncElasticSearch(this.props.params.id);
    }

    handleSave() {
        let validations = UserStore.getValidations();
        let warn = validations.filter( (validation) => {
            return validation.type === Validations.warnOnTrue.type;
        });
        if (warn.length > 0) {
            let message = warn.map( (w) => {
                return w.message;
            });
            this.warnOnTrue(message.join('. ')); // maybe in the future some formatting must be done.
        } else {
            this.doSave();
        }
        return;
    }

    handleSendActivationEmail(event) {
        UserActions.updateUser('sendUserActivationEmail', event.target.checked);
        return;
    }

    isDirty(location) {
        if (location) {
            let goingTo = location.pathname.split('/');
            let current = this.props.location.pathname.split('/');
            if (goingTo[1] === current[1] && /* entity */
                goingTo[2] === current[2] /* entity id */) {
                return false; // just changing tabs no confirmation needed.
            }
        }
        if (this.props.route.mode === 'create') {
            return false;
        }
        let user = UserStore.getState().get('user').delete('companyIsNew');
        let originalUser = UserStore.getState().get('originalUser').delete('companyIsNew');
        return !user.equals(originalUser) ||
                !DeliveryStore.getState().get('profile').equals(DeliveryStore.getState().get('originalProfile')) ||
                !DeliveryStore.getState().get('notifications').equals(DeliveryStore.getState().get('originalNotifications')) ||
                !SubscriptionsStore.getState().get('subscriptions').equals(SubscriptionsStore.getState().get('originalSubscriptions'));
    }

    initStores(props) {
        if (props.route.mode === 'edit') {
            UserActions.findById(props.params.id);
        }
        return;
    }

    showApproveApplicant() {
        let approvalValidations = UserStore.getApplicantApprovalValidations(this.state.user);
        if (this.props.permissions.applicant.permissions.canApproveWithoutPermissions) {
            approvalValidations = [];
        }

        const validations = UserStore.getValidations();
        let message = this.context.intl.messages['accounts.create.approve-deny.no-flags-message'];

        if (validations.length || approvalValidations.length) {
            let continueMessage;
            if (!approvalValidations.length) {
                continueMessage = (
                    <div>
                        <hr />
                        <p><strong>{this.context.intl.messages['accounts.create.approve-deny.continue']}</strong></p>
                    </div>
                );
            }

            message = <div>
                <p>{this.context.intl.messages['accounts.create.approve-deny.flags']}</p>
                <ul className="list-unstyled">
                    {approvalValidations.map((v, i) =>
                        <li key={i}><strong><span className="text-red"><i className="fas fa-flag"></i>&nbsp;{v.message}</span></strong></li>
                    )}
                    {validations.map((v, i) =>
                        <li key={i}><span className="text-red"><i className="fas fa-flag"></i>&nbsp;{v.message}</span></li>
                    )}
                </ul>
                {continueMessage}
            </div>;
        }

        if (!approvalValidations.length) {
            NotificationActions.show(
                MODAL_TYPE.APPROVE,
                this.context.intl.messages['accounts.create.approve-deny.approve-title'],
                message,
                this.context.intl.messages['accounts.create.approve-deny.confirm-approve'],
                () => {
                    UserActions.approve();
                    this.doSave();
                    return;
                }
            );
        } else {
            NotificationActions.show(
                MODAL_TYPE.DENY_NON_CANCELABLE,
                this.context.intl.messages['accounts.create.approve-deny.approve-warning-title'],
                message,
                this.context.intl.messages['common.close']
            );

        }


        return;
    }

    showDenyApplicant() {
        let message = <div className="form-group gray-wrapper">
            <label className="control-label"><i className="fas fa-pencil-alt"></i>&nbsp;{this.context.intl.messages['accounts.create.approve-deny.deny-reasons']}</label>
            <textarea
                className="form-control"
                onChange={this.handleDeniedCommentsChange}
            >
            </textarea>
        </div>;

        NotificationActions.show(
            MODAL_TYPE.DANGER,
            this.context.intl.messages['accounts.create.approve-deny.deny-title'],
            message,
            this.context.intl.messages['accounts.create.approve-deny.add-note-confirm'],
            () => {
                UserActions.deny(this.state.deniedComments);
                this.doSave();
                return;
            }
        );

        return;
    }

    warnOnTrue(message) {
        let body = `${message}. ${this.context.intl.messages['accounts.create.warnOnTrue.body']}`;
        NotificationActions.show(
            MODAL_TYPE.DEFAULT,
            this.context.intl.messages['accounts.create.warnOnTrue.title'],
            body,
            this.context.intl.messages['accounts.create.warnOnTrue.confirm'],
            this.doSave
        );
    }

    render() {
        let actionsMenu;
        // Apply realtime api validations only while creating applicants
        const validations = UserStore.getValidations(this.props.route.mode === 'create').concat(DeliveryStore.getValidations());

        // This needs refactor
        let canSave = validations.filter( (validation) => {
            return validation.severity === SEVERITY.ALERT;
        }).length === 0;
        const entity = this.props.route.entity;
        const mode = this.props.route.mode;
        const intlPrefix = `${mode}.${entity}`;

        let activationSettings;
        let accountNavigation = <span className="title-header-buttons padding-y-10">
            <AccountNavigation location={this.props.location} accountId={this.state.user.get('id')}/>
        </span>;
        let approveDeny;

        // Tabs
        let deliveryTab = null;
        let historyTab = null;
        let managementTab = null;
        let permissionsTab = null;
        let permissionsAuditTab = null;
        let subscriptionsTab = null;
        let summaryTab = null;
        let userErrors = null;

        let baseRoute = `/accounts/${this.props.params.id}`;
        let permissionsRoute = `${baseRoute}/permissions`;

        if (entity === 'applicant') {
            if (mode === 'create') {
                actionsMenu = <ActionsMenu
                    canSave={canSave}
                    saveIcon="fa-user-plus"
                    saveText={this.context.intl.messages['accounts.create.add-applicant']}
                    onCancel={this.handleCancelEdit}
                    onSave={this.handleSave}
                />;

                baseRoute = '/accounts/applicants/create';
                permissionsRoute = `${baseRoute}/permissions`;
            }

            if (mode === 'edit' &&
                this.props.permissions.applicant.canEdit &&
                (
                    this.props.permissions.applicant.permissions.canApprove ||
                    this.props.permissions.applicant.permissions.canValidate ||
                    this.props.permissions.applicant.permissions.canDeny ||
                    this.props.permissions.applicant.permissions.canReject
                )
            ) {

                baseRoute = `/accounts/applicants/${this.props.params.id}`;
                permissionsRoute = `${baseRoute}/permissions`;

                // UI elements to show when editing an applicant.
                accountNavigation = <span className="title-header-buttons padding-y-10">
                    <ApplicantNavigation location={this.props.location} applicantId={this.state.user.get('id')}/>
                </span>;

                let approveBtn;
                let denyBtn;
                if (
                    this.props.permissions.applicant.permissions.canApprove ||
                    this.props.permissions.applicant.permissions.canValidate
                ) {
                    approveBtn = <Button
                        block
                        bsStyle="success"
                        bsSize="large"
                        disabled={!(this.props.permissions.applicant.canEdit && canSave)}
                        onClick={this.showApproveApplicant}
                    >
                        <i className="fas fa-check"/>&nbsp;{this.context.intl.messages['accounts.create.save-profile-and-approve']}
                    </Button>;
                }
                if (
                    this.props.permissions.applicant.permissions.canDeny ||
                    this.props.permissions.applicant.permissions.canReject
                ) {
                    denyBtn = <Button
                        block
                        bsStyle="danger"
                        bsSize="large"
                        onClick={this.showDenyApplicant}
                    >
                        <i className="fas fa-times"/>&nbsp;{this.context.intl.messages['accounts.create.save-profile-and-deny']}
                    </Button>;
                }
                approveDeny = <Panel iconClass="fas fa-balance-scale" title={this.context.intl.messages['accounts.create.approve-deny']} collapsible >
                    {approveBtn}
                    {denyBtn}
                </Panel>;
            }

            activationSettings = <Panel iconClass="fas fa-cog" title={this.context.intl.messages['accounts.create.activation-settings']} collapsible >
                <div className="form-section history-section margin-bottom-20">
                    <Checkbox
                        checked={this.state.user.get('sendUserActivationEmail')}
                        onChange={this.handleSendActivationEmail}>
                        {this.context.intl.messages['accounts.create.activation-settings.send-email']}
                    </Checkbox>
                </div>
                <p>
                    <strong>{this.context.intl.messages['accounts.create.activation-settings.send-email.info-title']}</strong>
                    <em>{this.context.intl.messages['accounts.create.activation-settings.send-email.info-text']}</em>
                </p>
            </Panel>;
        }

        let notesBox = null;
        let actionHistoryBox = null;
        let disabledSummary = true;

        if ((this.props.route.mode === 'create' && this.props.permissions[this.props.route.entity].canCreate)
            || (this.props.route.mode === 'edit' && this.props.permissions[this.props.route.entity].canEdit)) {
            disabledSummary = false;
        }

        let icon = 'fas fa-user-plus';
        if (mode === 'edit') {
            let basePath = `/accounts/${this.props.params.id}`;
            if (entity === 'applicant') {
                basePath = `/accounts/applicants/${this.props.params.id}`;
            }
            const notesExpanded = this.state.user.get('notes').size;
            notesBox = (
                <SideNotes
                    basePath={basePath}
                    defaultExpanded={notesExpanded}
                    dialogMessage={this.context.intl.messages['common.side-notes.add-note.body']}
                    disabled={!this.props.permissions[this.props.route.entity].canEdit}
                    icon="fa-sticky-note"
                    notes={this.state.user.get('notes').toJS().slice(0, 4)}
                    onAddNote={this.addNote}
                    title={this.context.intl.messages['accounts.edit.notes.title']}
                />
            );
            actionHistoryBox = (
                <SideNotes
                    basePath={basePath}
                    notes={this.state.user.get('history').toJS().slice(0, 4)}
                    title={this.context.intl.messages['accounts.edit.action-history.title']}
                />
            );

            icon = 'fa-regular fa-edit fa-fw';
            historyTab = <Tab route={`${baseRoute}/history`} tabClassName="pull-right" title={this.context.intl.messages['common.tab-title.history']}>
                <History
                    actionObject={ActionHistoryConstants.ACTION_OBJECTS.USER}
                    id={this.props.params.id}
                    pagination
                    pathname={this.props.location.pathname}
                    query={this.props.location.query}/>
            </Tab>;
        }

        summaryTab = <Tab route={baseRoute} title={this.context.intl.messages['accounts.create.summary-tab-title']}>
            <Summary mode={mode} disabled={disabledSummary} user={this.state.user} userType={this.state.userType}/>
        </Tab>;

        // Only draw the permissions tab if the user is able to see it.
        if (this.props.permissions[this.props.route.entity].permissions.canRead) {
            permissionsTab = <Tab route={permissionsRoute} title={this.context.intl.messages['accounts.create.permissions-tab-title']}>
                <Permissions
                    canViewStations={this.props.permissions.stations.canViewStations}
                    entity={this.props.route.entity}
                    permissions={this.props.permissions[this.props.route.entity].permissions}
                />
            </Tab>;
        }

        if (entity === 'account') {
            if (this.props.permissions.account.canEdit) {
                deliveryTab = <Tab route={`/accounts/${this.props.params.id}/delivery`} title={this.context.intl.messages['accounts.edit.delivery-tab-title']}>
                    <Delivery
                        disabled={!this.props.permissions.account.canEdit}
                        userId={this.props.params.id}
                    />
                </Tab>;
            }

            if (this.props.permissions.account.management.canRead) {
                // UI elements to show when editing an account.
                managementTab = <Tab route={`/accounts/${this.props.params.id}/management`} title={this.context.intl.messages['accounts.create.management-tab-title']}>
                    <Management {...this.props}
                        banUserAllowed={this.props.permissions.account.management.canBanUser}
                        disabled={!this.props.permissions.account.management.canDeactivate}
                        onUnlock={this.handleSave}
                        passwordResetAllowed={this.state.user.get('passwordResetAllowed')}
                        setPasswordAllowed={this.props.permissions.account.management.canSetPassword}
                        unlockUserAllowed={this.props.permissions.account.management.canUnlock}
                        userEmail={this.state.user.get('email')}
                        userId={this.props.params.id}
                    />
                </Tab>;
            }

            if (this.props.permissions.account.subscriptions.canRead) {
                subscriptionsTab = <Tab route={`/accounts/${this.props.params.id}/subscriptions`} title={this.context.intl.messages['accounts.create.subscriptions-tab-title']}>
                    <Subscriptions {...this.props}
                        disabled={!this.props.permissions.account.subscriptions.canEdit}
                        displayVideoLinks={this.props.permissions.assets.canReadVideoAssets}
                        userId={this.props.params.id}
                    />
                </Tab>;
            }

            if (this.props.permissions.account.permissionsAudit.canRead) {
                permissionsAuditTab = <Tab route={`/accounts/${this.props.params.id}/permissions-audit`} title={this.context.intl.messages['accounts.create.permissions-audit-tab-title']}>
                    <PermissionsAudit
                        disabled={true}
                        userId={this.props.params.id}
                    />
                </Tab>;
            }

            if (this.props.permissions.account.userErrors.canRead) {
                userErrors = <Tab route={`/accounts/${this.props.params.id}/user-errors`} title={this.context.intl.messages['accounts.create.user-errors.tab-title']}>
                    <UserErrors
                        location={this.props.location}
                        userId={this.props.params.id}
                    />
                </Tab>;
            }

            let activationEmail = this.context.intl.messages['accounts.edit.activation-settings.email-not-sent'];
            if (this.state.user.get('sendUserActivationEmail')) {
                activationEmail = this.context.intl.messages['accounts.edit.activation-settings.email-sent'];
            }
            activationSettings = <Panel iconClass="fas fa-cog" title={this.context.intl.messages['accounts.create.activation-settings']} collapsible >
                <p className="form-section history-section margin-bottom-20"><span>{activationEmail}</span></p>
                <p>
                    <strong>{this.context.intl.messages['accounts.edit.activation-settings.send-email.info-title']}</strong>
                    <em>Visit the <Link to={`/accounts/${this.props.params.id}/management`}>Management</Link> tab to either generate a password reset link or send the user a password reset email.</em>
                </p>
            </Panel>;
        }

        // If menu is still undefined, then add the default buttons.
        if (!actionsMenu) {
            actionsMenu = <ActionsMenu
                canEdit={this.props.permissions[entity].canEdit}
                canSave={canSave}
                hideSaveAndSync={!this.props.permissions[entity].canEdit}
                saveText={this.context.intl.messages['accounts.create.save-profile']}
                onCancel={this.handleCancelEdit}
                onElasticSearchSync={this.handleElasticSearchSync}
                onSave={this.handleSave}
            />;
        }

        let entityName;

        if (this.state.user.get('email')) {
            entityName = this.state.user.get('email');
        }

        let userDescription = this.state.user.get('email');
        if (this.state.userType.get('id') === UserConstants.USER_TYPES.SECONDARY.id ||
            this.state.userType.get('id') === UserConstants.USER_TYPES.ANONYMOUS.id) {
            userDescription = `${this.context.intl.messages['accounts.create.token']}: ${this.state.user.get('name')}`;
        }

        let oktaId;
        if (this.state.user.get('oktaId')) {
            oktaId = <small style={{display: 'block', marginTop: '5px'}}>{this.context.intl.messages['accounts.create.okta-id']}: {this.state.user.get('oktaId')}</small>;
        }

        let duplicatedEmailsSection;
        if (this.state.duplicatedEmails.size) {
            duplicatedEmailsSection = (
                <div>
                    <p>
                        <span className="text-yellow">
                            <i className="fas fa-people-arrows"></i>
                            &nbsp;<strong>{this.context.intl.messages['accounts.edit.duplicated-emails.title']}</strong>
                            {this.context.intl.messages['accounts.edit.duplicated-emails.description']}
                        </span>
                    </p>
                    {
                        this.state.duplicatedEmails.map(duplicated => {
                            let userPath = 'accounts';
                            if (duplicated.get('applicantStatus')) {
                                userPath = 'accounts/applicants';
                            }
                            return (
                                <ul>
                                    <li>
                                        <a href={`/${userPath}/${duplicated.get('id')}`} style={{wordBreak:'break-all'}} className="text-yellow" target="_blank">
                                            {duplicated.get('email')}&nbsp;<i className="far fa-external-link-alt"></i>
                                        </a>
                                    </li>
                                </ul>
                            );
                        })
                    }
                </div>
            );
        }

        let status = '';
        if (mode === 'edit') {
            status = this.context.intl.messages['common.na'];
            // Status Value as well as tooltip
            let st = UserStore.getStatus(this.state.user) || {
                description: '',
                icon: '',
                label: ''
            };
            let tip = '';
            if (st.tip) {
                tip = this.context.intl.messages[st.tip];
            }
            status = <span className={ClassNames('label', st.label)} data-toggle="tooltip" title={tip} data-placement="top"><i className={ClassNames(st.icon)}></i>&nbsp;<span className="hidden-xs hidden-sm">{st.description}</span></span>;
        }

        return (
            <DocumentTitle message={`document-titles.${mode}.accounts`} entityName={entityName}>
                <div>
                    <StickyHeader>
                        <div className="col-md-6">
                            <h1>
                                <i className={ClassNames(icon)}></i>&nbsp;{this.context.intl.messages[`accounts.${intlPrefix}.title`]}
                                &nbsp;
                                <small style={{display: 'block', marginTop: '5px'}}>{userDescription}</small>
                                {oktaId}
                                &nbsp;
                                {accountNavigation}
                            </h1>
                            <small>
                                {status}
                            </small>
                        </div>
                        {actionsMenu}
                    </StickyHeader>
                    <Preloader show={this.state.preloaderVisible} fixed loadingDots>
                        <section className="content" id="contentContainer">
                            <div className="row">
                                <div className="col-lg-9">
                                    <Tabs location={this.props.location}>
                                        {summaryTab}
                                        {permissionsTab}
                                        {permissionsAuditTab}
                                        {managementTab}
                                        {deliveryTab}
                                        {subscriptionsTab}
                                        {userErrors}
                                        {historyTab}
                                    </Tabs>
                                </div>
                                <div className="col-lg-3">
                                    <ErrorsWarnings title={this.context.intl.messages['common.errors-warnings-title']} validations={validations}>
                                        {duplicatedEmailsSection}
                                    </ErrorsWarnings>
                                    {approveDeny}
                                    {notesBox}
                                    {actionHistoryBox}
                                    {activationSettings}
                                </div>
                            </div>
                        </section>
                    </Preloader>
                </div>
            </DocumentTitle>
        );
    }
}

export default Container.create(Create);
export {Create as Create_BASE};
