/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ControlLabel, FormGroup} from 'react-bootstrap';
import Select from 'react-select';

import {EditDomainActions} from './edit-domain-actions';
import {EditDomainStoreValidations} from './edit-domain-store';
import {FormSection, FormRow, FormItem} from '../../common/form/form';
import {CompareImmutable, GetAttr} from '../../common/utils/utils';
import {WithValidations} from '../../common/validations/validations';

const domainProps = [
    'id',
    'domainName',
    'permissionPackageId',
    'adminApproval',
    'clientRepApproval',
    'note'
];

class EditDomainForm extends Component {

    static get propTypes() {
        return {
            permissionPackages: PropTypes.instanceOf(Immutable.List).isRequired,
            domain: PropTypes.instanceOf(Immutable.Map).isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            isPermissionPackageIdDirty: false,
            selectedPermissionPackage: this.props.permissionPackages.find(
                pp => pp.get('id') === this.props.domain.get('permissionPackageId')
            ),
        };

        this.handleSelectPermissionPackage = this.handleSelectPermissionPackage.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            selectedPermissionPackage: nextProps.permissionPackages.find(
                pp => pp.get('id') === nextProps.domain.get('permissionPackageId')
            )
        });

        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!CompareImmutable(nextProps.domain, this.props.domain, domainProps) ||
            nextState.selectedPermissionPackage !== this.state.selectedPermissionPackage
        ) {
            return true;
        }

        return false;
    }

    handleSelectPermissionPackage(permissionPackage) {

        if (!this.state.isPermissionPackageIdDirty) {
            this.setState({isPermissionPackageIdDirty: true});
        }

        if (!permissionPackage) {
            // Reset the value.
            EditDomainActions.update('permissionPackageId', undefined);
            return;
        }

        EditDomainActions.update('permissionPackageId', Immutable.fromJS(permissionPackage).get('id'));
    }

    render() {
        const req = (<span className="text-red">&nbsp;*</span>);

        return (
            <div className="tab-pane padding-x-20 padding-bottom-20">
                <FormSection title={this.context.intl.messages['emails.whitelist.edit.form.domain-info']} iconClass="fas fa-info-circle">
                    <FormRow>
                        <FormItem attr="domainName"
                            label={this.context.intl.messages['emails.whitelist.edit.domain-name']}
                            model={this.props.domain}
                            placeholder={this.context.intl.messages['emails.whitelist.edit.domain-name.placeholder']}
                            setter={EditDomainActions.update}
                            type="text"
                            validations={EditDomainStoreValidations.domainName.validations}
                        />
                        <FormGroup md={4} validationState={this.getValidationState(
                            this.props.domain.get('permissionPackageId'),
                            this.state.isPermissionPackageIdDirty,
                            EditDomainStoreValidations.permissionPackageId.validations
                        )}>
                            <ControlLabel>{this.context.intl.messages['emails.whitelist.edit.permissions-package']}{req}</ControlLabel>
                            <Select
                                getOptionLabel={GetAttr('name')}
                                getOptionValue={GetAttr('id')}
                                isClearable={false}
                                isMulti={false}
                                name="permissionsPackageId"
                                onChange={this.handleSelectPermissionPackage}
                                options={this.props.permissionPackages.toJS()}
                                isSearchable={true}
                                placeholder={this.context.intl.messages['emails.whitelist.edit.permissions-package-placeholder']}
                                value={this.state.selectedPermissionPackage?.toJS()}
                            />
                        </FormGroup>
                    </FormRow>
                    <hr />
                    <FormRow>
                        <div>
                            <label>{this.context.intl.messages['emails.whitelist.edit.automatically-approved-by']}</label>
                        </div>
                        <FormItem type="checkbox" md={3}
                            attr="adminApproval"
                            label={this.context.intl.messages['emails.whitelist.edit.cms-admin']}
                            model={this.props.domain}
                            setter={EditDomainActions.update}/>
                        <FormItem type="checkbox" md={3}
                            attr="clientRepApproval"
                            label={this.context.intl.messages['emails.whitelist.edit.client-rep']}
                            model={this.props.domain}
                            setter={EditDomainActions.update}/>
                    </FormRow>
                </FormSection>
                <FormSection title={this.context.intl.messages['emails.whitelist.edit.form.notes']} iconClass="fas fa-pencil-alt">
                    <FormRow>
                        <FormItem attr="note"
                            label={this.context.intl.messages['emails.whitelist.edit.notes']}
                            model={this.props.domain}
                            placeholder={this.context.intl.messages['emails.whitelist.edit.notes.placeholder']}
                            setter={EditDomainActions.update}
                            type="textarea"
                            validations={EditDomainStoreValidations.note.validations}
                        />
                    </FormRow>
                </FormSection>
            </div>
        );
    }

}

export default WithValidations(EditDomainForm);
