/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {AbrPlayer} from '@accurate-player/accurate-player-abr';
import React from 'react';

import {CurrentVttFile$} from './bl';
import CCEditorContent from './components/content';
import CCEditorPlayer from './components/player';
import CCEditorTimeline from './components/timeline';

import ResizablePanels from '~/src/common/resizable-panel/preserve-positioning';
import WithRxSubscriptions from '~/src/decorators/with-rx-subscriptions';

type Props = {
    location: unknown,
    video: ImmutableMap<PlayableVideoAsset>,
    vtt: ImmutableMap<VideoWebVtt> | undefined,
}

type State = {
    abr: AbrPlayer | null
}

export class CCEditorLayout extends React.PureComponent<Props, State> {
    state = {
        abr: null
    };

    handlePlayerInit = (abr: AbrPlayer) => this.setState(() => ({abr}));

    render() {
        const vtt = this.props.vtt;
        if (!vtt) {
            return null;
        }

        return (
            <div className="cc-editor">
                <ResizablePanels
                    displayDirection="column"
                    height="calc(100vh - 12rem)"
                    panelsSize={[90, 10]}
                    panelsMaxSize={[95, 25]}
                    panelsMinSize={[75, 5]}
                    resizerColor="#161616"
                    resizerSize="0.5rem"
                    sizeUnitMeasure="%"
                    storageKey="cc-editor.vertical"
                    width="100%"
                >
                    <ResizablePanels
                        displayDirection="row"
                        height="100%"
                        panelsSize={[60, 40]}
                        panelsMinSize={[33, 33]}
                        panelsMaxSize={[66, 66]}
                        resizerColor="#161616"
                        resizerSize="0.5rem"
                        sizeUnitMeasure="%"
                        storageKey="cc-editor.horizontal"
                        width="100%"
                    >
                        <CCEditorPlayer
                            handlePlayerInit={this.handlePlayerInit}
                            video={this.props.video}
                            vttExternalUrl={vtt.get('externalUrl')}
                        />

                        <CCEditorContent
                            location={this.props.location}
                            video={this.props.video}
                            vtt={vtt.toJS()}
                        />
                    </ResizablePanels>

                    <CCEditorTimeline
                        abrPlayer={this.state.abr}
                        frameRate={this.props.video.get('frameRate')}
                    />
                </ResizablePanels>
            </div>
        );
    }
}

export default WithRxSubscriptions(CCEditorLayout, {
    vtt: CurrentVttFile$,
});
