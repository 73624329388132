/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {EditDomainActions} from './edit-domain-actions';
import EditDomainForm from './edit-domain-form';
import EditDomainStore from './edit-domain-store';
import RouteValidator from '../../common/custom-validator/route/route';
import DocumentTitle from '../../common/document-title';
import {AlertsWarnings} from '../../common/validations/alerts-warnings';
import ActionsMenu from '../../layout/actions-menu/actions-menu';
import StickyHeader from '../../layout/sticky-header/sticky-header';
import Preloader from '../../preloader/';
import PreloaderStore from '../../preloader/preloader-store';
import {RouterActions} from '../../router/router-actions';
import {PermissionPackageActions, PermissionPackageConstants} from '../../security/permission-package/permission-package-actions';
import PermissionPackageStore from '../../security/permission-package/permission-package-store';

class EditDomain extends Component {

    static get propTypes() {
        return {
            /**
            * Validate custom props inside the route object.
            */
            params: PropTypes.object.isRequired,
            route: RouteValidator // eslint-disable-line react/require-default-props
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            domain: EditDomainStore.getState().get('domain'),
            permissionPackages: PermissionPackageStore.getState().get('permissionPackages'),
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible'),
        };
    }

    static getStores() {
        return [EditDomainStore, PreloaderStore, PermissionPackageStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.handleCancel = this.handleCancel.bind(this);
        this.handleSaveDomain = this.handleSaveDomain.bind(this);
        this.isDirty = this.isDirty.bind(this);
    }

    componentWillMount() {
        EditDomainActions.clear();
        return;
    }

    componentDidMount() {
        this.initStores(this.props);
        return;
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.route.mode !== this.props.route.mode ||
            nextProps.params.id !== this.props.params.id) {
            this.initStores(nextProps);
        }
        return;
    }

    handleCancel() {
        RouterActions.redirect('/setup/email-whitelists', true);
    }

    handleSaveDomain() {
        let domain = EditDomainStore.getState().get('domain');
        EditDomainActions.save(domain, this.props.route.mode);
        return;
    }

    initStores(props) {
        // Fetch all the permissionPackages for the select.
        PermissionPackageActions.get(Immutable.fromJS({active: PermissionPackageConstants.FILTERS.ACTIVE_OPTIONS.ACTIVE.id}), 0, 999999);

        switch (props.route.mode) {
        case 'create':
            EditDomainActions.clear();
            break;
        case 'edit':
            EditDomainActions.get(props.params.id);
            break;
        }
        RouterActions.registerRedirectCheck(() => {
            return this.isDirty();
        });
        return;
    }

    isDirty() {
        if (this.props.route.mode === 'create') {
            return false;
        }
        return !this.state.domain.equals(EditDomainStore.getState().get('originalDomain'));
    }

    render() {
        let actionsMenu;
        let mode = this.props.route.mode;
        let validations = EditDomainStore.getValidations();
        let saveStyle;
        if (validations.length) {
            saveStyle = 'btn btn-default-outline';
        } else {
            saveStyle = 'btn btn-primary-outline';
        }

        if (mode==='create') {
            actionsMenu = <ActionsMenu
                canSave={!validations.length}
                onCancel={this.handleCancel}
                onSave={this.handleSaveDomain}
                saveStyle={saveStyle}
                saveText={this.context.intl.messages['emails.whitelist.create.save']}
            />;
        } else {
            actionsMenu = <ActionsMenu
                canEdit={true}
                canSave={!validations.length}
                onCancel={this.handleCancel}
                onSave={this.handleSaveDomain}
            />;
        }

        let entityName;

        if (this.state.domain.get('domainName')) {
            entityName = this.state.domain.get('domainName');
        }

        return (
            <DocumentTitle
                message={`document-titles.${mode}-domain`} entityName={entityName}
            >
                <div>
                    <StickyHeader>
                        <div className="col-md-6">
                            <h1>
                                <i className="far fa-envelope"></i>&nbsp;{this.context.intl.messages[`emails.whitelist.${mode}.title`]}
                            </h1>
                        </div>
                        {actionsMenu}
                    </StickyHeader>
                    <Preloader show={this.state.preloaderVisible} fixed loadingDots>
                        <div>
                            <section className="content" id="contentContainer">
                                <div className="row form">
                                    <div className="col-lg-9">
                                        <div className="box">
                                            <div className="box-body padding-x-20 padding-y-20">
                                                <EditDomainForm domain={this.state.domain} permissionPackages={this.state.permissionPackages}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <AlertsWarnings title={this.context.intl.messages['common.alerts-warnings-title']} validations={validations} />
                                    </div>
                                </div>
                            </section>
                        </div>
                    </Preloader>
                </div>
            </DocumentTitle>
        );
    }

}

export default Container.create(EditDomain);

export {
    EditDomain
};
