/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {Button, Modal} from 'react-bootstrap';

class DeleteInfoModal extends PureComponent {
    static get propTypes() {
        return {
            onHide: PropTypes.func.isRequired,
            show: PropTypes.bool.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.handleHide = this.handleHide.bind(this);
    }

    handleHide() {
        this.props.onHide();
        return;
    }

    render() {
        return (
            <Modal onHide={this.handleHide} show={this.props.show}>
                <Modal.Header className="modal-header bg-danger" closeButton>
                    <Modal.Title className="text-center">{this.context.intl.messages['catalogs.delete.title']}</Modal.Title>
                </Modal.Header>

                <Modal.Body className="modal-body text-center">
                    <h4><i className="fas fa-exclamation-triangle"></i>&nbsp;{this.context.intl.messages['catalogs.delete.action-required']}</h4>
                    <p>{this.context.intl.messages['catalogs.delete.description']}</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button className="btn btn-default-outline" onClick={this.handleHide}>
                        <i className="fas fa-times"></i>&nbsp;{this.context.intl.messages['common.close']}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default DeleteInfoModal;
