/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

import mfa from './mfa';

import Analytics from '~/src/analytics';
import {AlertTypes} from '~/src/common/notification/alert';
import {NotificationActions} from '~/src/common/notification/notification-actions';
import Config from '~/src/config/config';
import {DashboardConstants} from '~/src/dashboard/dashboard-actions';
import Dispatcher from '~/src/dispatcher/dispatcher';
import {LayoutActions} from '~/src/layout/layout-actions';
import Request from '~/src/request';
import {RouterActions} from '~/src/router/router-actions';
import {PermissionConstants} from '~/src/security/permission/permission-actions';

const CONSTANTS = {
    CLEAR_TIMEOUT_WARNING: 'session.clear.timeout.warning',
    END_SESSION: 'session.end',
    GET: {
        AUTH_USER: {
            ROLES: {
                SUCCESS: 'session_actions.get.auth_user.roles.success'
            },
            SUCCESS: 'session_actions.get.auth_user.success'
        },
        PERMISSIONS: {
            SUCCESS: 'session_actions.get.permissions.success'
        },
        MFA_DATA: {
            SUCCESS: 'session_actions.get.mfa_data.success'
        },
        MFA_VERIFY_CODE: {
            ERROR: 'session_actions.get.mfa.verify_code.error',
            START: 'session_actions.get.mfa.verify_code.start',
            SUCCESS: 'session_actions.get.mfa.verify_code.success'
        }
    },
    JWT: {
        GET: {
            ERROR: 'session_actions.jwt.get.error',
            START: 'session_actions.jwt.get.start',
            SUCCESS: 'session_actions.jwt.get.success'
        }
    },
    LAST_LOGIN: {
        GET: {
            SUCCESS: 'session_actions.last_login.get.success'
        }
    },
    LOGIN: {
        ERROR: 'session_constants.login.error',
        START: 'session_actions.login.start',
        SUCCESS: 'session_constants.login.success'
    },
    NEW_SESSION: 'session.new',
    SET_DEFAULT_HOMESCREEN: 'session_actions.set_default_homescreen',
    TIMEOUT_WARNING: 'session.timeout.warning',
    UPDATE: 'session.update'
};

class SessionActions {
    /*istanbul ignore next*/
    login(email, password, next = DashboardConstants.getDefaultDashboardFilter()) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.LOGIN.START,
            email: email,
            password: password
        });

        Analytics.userLoginStartEvent();

        Request.post('login').send({
            email: email,
            password: password
        }).set(
            'Accept', '*'
        ).set(
            'Content-Type', 'application/json'
        ).set(
            'WBTV-Partner-Id', Config.WBTVPartnerId
        ).set(
            'WBTV-Partner-Key', Config.WBTVPartnerKey
        ).exec().catch(err => {
            // This catch is only for MFA Required error.
            if (err.status === 401 &&
                (err.response.body.error === 'MFARequired' || err.response.body.error === 'MFATokenRequired')) {
                let authorization = err.response.header.authorization;
                let userId = err.response.body.UserId;
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET.MFA_DATA.SUCCESS,
                    userId,
                    uuid: authorization
                });

                Analytics.userLoginMFAChallengeSentEvent();
                // Do MFA Stuff.
                return mfa.RequestMFA(userId, true, authorization).catch(mfaErr => {
                    switch (true) {
                    case mfaErr instanceof mfa.MFANotificationNotRequiredError:
                    case mfaErr instanceof mfa.MFARejectedError:
                        NotificationActions.showAlertDanger('login.error.mfa.rejected');
                        break;
                    default:
                        NotificationActions.showAlertDanger('login.error.mfa.400');
                        break;
                    }

                    throw mfaErr;
                }).then(() => {
                    // Mock the valid login server response.
                    Analytics.userLoginMFAChallengeCompleteEvent();
                    return {
                        header: {
                            authorization
                        }
                    };
                });
            }

            throw err;
        }).then(res => {
            return this.getNewSession(res);
        }).spread((cmsPermissionsRes, userRes, jwtRes, lastLoginRes) => {
            if (jwtRes.body.token) {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.JWT.GET.SUCCESS,
                    jwt: jwtRes.body.token
                });
            }

            Dispatcher.dispatch({
                actionType: CONSTANTS.LAST_LOGIN.GET.SUCCESS,
                lastLoginTimestamp: lastLoginRes.body.lastLogin
            });

            return this.getPermissions(cmsPermissionsRes, userRes);
        }).then(rolesRes => {
            return this.getRoles(rolesRes, next);
        }).catch(err => {
            return this.getErrorLogin(err);
        });
        return;
    }

    getNewSession(res) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.NEW_SESSION,
            ipv: res.header['x-brainiac-ipv'],
            uuid: res.header.authorization
        });

        const auth = res.header.authorization;

        // Get the user profile, permissions and JWT token for services like Analytics.
        return [
            Request.get('user/permission').query({
                'permission-type': PermissionConstants.SITES.CMS.PERMISSION_TYPE,
                'site-type': PermissionConstants.SITES.CMS.SITE_TYPE
            }).set('Authorization', res.header.authorization).exec(),
            Request.post('system/validate-session').set('Authorization', auth).exec().then(
                validateRes => Request.get(`user/${validateRes.body.userId}`).set('Authorization', res.header.authorization).exec()
            ),
            Request.post('system/analytics-token').set('Authorization', auth).exec().then(response => response).catch(err => {
                console.error('Error getting JWT.', err);
                // Don't break the process, just don't do analytics.
                return {body: {token: null}};
            }),
            Request.get('user/last-login').set('Authorization', auth).exec().then(r => r).catch(err => {
                console.error('Error getting last login timestamp.', err);
                // Don't break the process, just log the error.
                return {body: {lastLogin: '-'}};
            })
        ];
    }

    getPermissions(permissionsRes, userRes) {
        let permissions = Immutable.fromJS(permissionsRes.body.results.filter(r => r.url !== null));
        Dispatcher.dispatch({
            actionType: CONSTANTS.GET.PERMISSIONS.SUCCESS,
            permissions: permissions,
        });

        Dispatcher.dispatch({
            actionType: CONSTANTS.GET.AUTH_USER.SUCCESS,
            user: Immutable.fromJS(userRes.body)
        });

        Analytics.userLoginSuccessEvent();

        return Request.get(`user/${userRes.body.id}/role`).exec();
    }

    getRoles(rolesRes, next) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.GET.AUTH_USER.ROLES.SUCCESS,
            roles: Immutable.fromJS(rolesRes.body)
        });

        Dispatcher.dispatch({
            actionType: CONSTANTS.LOGIN.SUCCESS
        });

        RouterActions.redirect(decodeURIComponent(next));
        if (!navigator?.userAgentData?.brands.find(item => item?.brand === 'Google Chrome')) {
            NotificationActions.showAlertWarning('login.browser.not.supported');
        }
        return;
    }

    getErrorLogin(err) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.LOGIN.ERROR
        });

        let message = 'login.error.default';
        if (
            err instanceof mfa.MFARejectedCodeError ||
            err instanceof mfa.MFARejectedCodeError
        ) {
            // In this case, the modal will diplay a notification.
            // Don't do anything here.
            throw err;
        }
        switch (err.status) {
        case 404:
            message = 'login.error.not-found';
            break;
        case 429:
            message = 'login.error.too-many-requests';
            break;
        }

        Analytics.userLoginErrorEvent();

        NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, message);
        throw err;
    }

    endSession(reason) {
        const logoutWithForcedRedirect = () => {
            Request.post('logout')
                .exec()
                .finally(() => {
                    Dispatcher.dispatch({
                        actionType: CONSTANTS.END_SESSION
                    });

                    RouterActions.redirect('/login', true);
                });
        };

        switch (reason) {
        case 'logout':
            // Manually clicked Profile->Sign Out
            LayoutActions.clear();
            RouterActions.redirect('/logout');
            break;
        case 'end-session':
            logoutWithForcedRedirect();
            NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'login.logout.success');
            break;
        case 'timeout':
            logoutWithForcedRedirect();
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'login.logout.timeout');
            break;
        default:
            logoutWithForcedRedirect();
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'login.logout.invalid');
            break;
        }
        return;
    }

    keepAlive() {
        // Nothing to see here, move along!
        // agent is responsible for updating timeout
        // so there is nothing to do here.
        Request.post('heartbeat')
            .exec()
            .catch((err) => {
                this.endSession(err.status);
            });
    }

    setDefaultHomescreen(homepage) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SET_DEFAULT_HOMESCREEN,
            homepage: homepage
        });
        return;
    }

    timeoutWarning() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TIMEOUT_WARNING
        });
    }

    clearTimeoutWarning() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR_TIMEOUT_WARNING
        });
    }

    renewJWT(jwt) {
        // Check if token is about to expire and ask for a new one.
        const now = new Date();
        let expireDate;
        try {
            // Get the middle part of the JWT, base64 decode it, parse it, read expiration date
            // and convert it to a date.
            expireDate = new Date(
                JSON.parse(
                    atob(
                        jwt.split('.')[1]
                    )
                ).exp * 1000
            );
        } catch (err) {
            console.error('Error parsing JWT.', err);
        }

        const fiveMins = 5 * 60 * 1000;
        const isAboutToExpire = (expireDate - now) < fiveMins;

        if (!isAboutToExpire) {
            return;
        }

        Dispatcher.dispatch({
            actionType: CONSTANTS.JWT.GET.START
        });

        Request.post('system/analytics-token').exec().then(res => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.JWT.GET.SUCCESS,
                jwt: res.body.token
            });

            return;
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.JWT.GET.ERROR
            });
            throw err;
        });
    }

    update() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.UPDATE
        });
    }

    verifyToken(userId, verificationCode, authorization) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.GET.MFA_VERIFY_CODE.START
        });
        mfa.VerifyToken(userId, verificationCode, authorization).then(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.MFA_VERIFY_CODE.SUCCESS
            });
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.MFA_VERIFY_CODE.ERROR
            });

            if (err instanceof mfa.MFARejectedCodeError) {
                NotificationActions.showAlertDanger('login.error.mfa.code.rejected');
            }

            throw err;
        });
    }
}

const actions = new SessionActions();

export {
    actions as SessionActions,
    CONSTANTS as SessionConstants
};
