/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import createReactClass from 'create-react-class';
import jQuery from 'jquery';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import {WhitelistActions} from './whitelist-actions';
import WhitelistStore from './whitelist-store';
import {MODAL_TYPE} from '../../common/notification/modal';
import {NotificationActions} from '../../common/notification/notification-actions';

require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('datatables.net-responsive-bs');

export default createReactClass({
    contextTypes: {
        intl: PropTypes.object.isRequired,
        router: PropTypes.object.isRequired
    },

    getInitialState: function() {
        // The store is the only source of truth. Always
        // get the values (even the default values) from it.
        /* eslint-disable react/no-unused-state */
        return {whitelist:WhitelistStore.getInitialState()};
    },

    componentWillMount: function() {
        this.storeListeners = [
            // Update data from the whitelist store.
            WhitelistStore.addListener(() => {
                let whitelist = WhitelistStore.getState();
                this.setState({
                    whitelist: whitelist
                });

                return;
            })
        ];
        return;
    },

    componentDidMount: function() {
        this.$table = jQuery('#whitelist-table').DataTable({
            autoWidth: false,
            columnDefs: [{
                // Add the control class to the last column. This colum will
                // contain the button to show the responsive data.
                className: 'control',
                targets:   -1,
                width: 20
            }, {
                targets: 'no-sort',
                orderable: false
            }],
            iDisplayLength: 1,
            info: false,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        });

        // Trigger an action that will request the
        // initial data to the server.
        WhitelistActions.get();

        // Register global listeners.
        window.addEventListener('resize', this.handleResize);
        // And trigger the resize handler once so that the datatable
        // knows its initial dimensions.
        this.handleResize();
        return;
    },

    componentWillUpdate: function(props, state) {
        this.$table.clear();

        let edit = this.context.intl.messages['common.edit'];
        let remove = this.context.intl.messages['common.remove'];

        // Add data to the jQuery datatable.
        state.whitelist.toArray().forEach((domain, index) => this.$table.row.add([
            `${index + 1}`,
            `<a href="/setup/email-whitelists/${domain.id}" class="edit-whitelist-domain">${domain.domainName}</a>`,
            domain.displayOnlyPermissionPackageName,
            domain.note,
            this.getApprovedBy(domain),
            this.formatDate(domain.createdDate), // (!domain.updatedDate) ? domain.createdDate : domain.updatedDate
            `<a class="btn btn-default btn-edit-domain" href="/setup/email-whitelists/${domain.id}" data-index="${domain.id}"><i class="fas fa-pencil-alt"></i> ${edit}</a> <button class="btn btn-default btn-remove-domain" data-index="${domain.id}"><i class="fas fa-trash-alt"></i> ${remove}</button>`,
            ''
        ]));

        this.$table.draw(false);
        // Now, since the data has changed the columns widths, trigger
        // the resize handler in order to update the responsive feature.
        this.handleResize();

        return;
    },

    componentWillUnmount: function() {
        this.storeListeners.forEach(listener => listener.remove());

        // Remove global listeners.
        window.removeEventListener('resize', this.handleResize);
    },

    formatDate: function(date) {
        if (!date) {
            return '';
        }
        return Moment(date).format(this.context.intl.messages['date-format']);
    },

    getApprovedBy: function(domain) {
        switch (true) {
        case domain.adminApproval !== null && domain.clientRepApproval !== null:
            return 'Both';
        case domain.adminApproval !== null:
            return 'CMS Admin';
        case domain.clientRepApproval !== null:
            return 'Client Rep';
        default:
            return 'None';
        }
    },

    /**
     * Update the datatable columns size.
     */
    handleResize: function() {
        this.$table.responsive.recalc();
        return;
    },

    handleConfirmDelete: function( id ) {
        // confirm deletion, then perform delete
        NotificationActions.show(MODAL_TYPE.APPROVE, this.context.intl.messages['common.confirm'], this.context.intl.messages['emails.whitelist.remove-domain.confirm'], this.context.intl.messages['common.confirm'], function() {
            WhitelistActions.remove( id );
        }, function() {
            // event cancelled (nothing to do)
        });
    },

    /**
     * This function is kind of "special" because it needs to handle
     * events bubbled from the data table rows, these rows cannot use
     * the JSX syntax because they are created by the data table
     * jQuery plugin instead of React.
     */
    handleRowClick: function(event) {
        switch (true) {
        // Handle click on the whitelisted domain's name.
        case !!~event.target.className.indexOf('edit-whitelist-domain'):
        case !!~event.target.className.indexOf('btn-edit-domain'):
            // Prevent the default anchor click event.
            event.preventDefault();
            this.context.router.push(event.target.getAttribute('href'));
            break;

        case !!~event.target.className.indexOf('btn-remove-domain'):
            // Prevent the default anchor click event.
            event.preventDefault();
            this.handleConfirmDelete( event.target.getAttribute('data-index') );
            break;
        }
        return;
    },

    render: function() {
        return (
            <div>
                <table id="whitelist-table" className="table table-bordered table-striped responsive">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>{this.context.intl.messages['emails.whitelist.browse.domain']}</th>
                            <th>{this.context.intl.messages['emails.whitelist.browse.permissions-package']}</th>
                            <th>{this.context.intl.messages['emails.whitelist.browse.notes']}</th>
                            <th>{this.context.intl.messages['emails.whitelist.browse.approved-by']}</th>
                            <th>{this.context.intl.messages['emails.whitelist.browse.date-added']}</th>
                            <th>{this.context.intl.messages['emails.whitelist.browse.actions']}</th>
                            <th className="no-sort"></th>
                        </tr>
                    </thead>
                    <tbody onClick={this.handleRowClick}/>
                </table>
            </div>
        );
    }
});
