/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import Immutable from 'immutable';
import jQuery from 'jquery';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {Sort} from '../common/local-search/filter';
import {WithStoreOnRoute} from '../common/store-on-route';

require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('datatables.net-responsive-bs');

class Table extends Component {

    static get propTypes() {
        return {
            catalogs: PropTypes.instanceOf(Immutable.Map).isRequired,
            displayLinks: PropTypes.bool,
            filters: PropTypes.string,
            type: PropTypes.oneOf(['asset', 'title', 'language'])
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
            router: PropTypes.object
        };
    }

    static get defaultProps() {
        return {
            displayLinks: false,
            filters: '',
            location: undefined,
            type: undefined,
            router: undefined
        };
    }

    constructor(props) {
        super(props);

        this.getSortByClass = this.getSortByClass.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.handleSort = this.handleSort.bind(this);
    }


    componentDidMount() {
        this.$table = jQuery('#catalogs-table');
        this.$tableAPI = this.$table.DataTable({
            autoWidth: false,
            columnDefs: [{
                // Add the control class to the last column. This colum will
                // contain the button to show the responsive data.
                className: 'control',
                targets: -1,
                width: 20
            }, {
                className: 'Whs(nw)',
                targets: 'no-wrap'
            }],
            iDisplayLength: 1,
            info: false,
            ordering: false,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        });

        window.addEventListener('resize', this.handleResize);
        this.componentWillUpdate(this.props, this.state);
        return;
    }

    componentWillUpdate(nextProps) {
        this.$tableAPI.clear();

        // Add data to the jQuery datatable.
        let filterRegExp = new RegExp(nextProps.filters, 'i');

        let catalogs = nextProps.catalogs.get('groups').filter(c => {
            let keep = c;
            if (keep && nextProps.filters) {
                keep = c.get('name').match(filterRegExp);
            }

            return keep;
        });

        // Reorder rows
        // utils.js
        let routeState = this.getRouteState(nextProps);
        catalogs = Sort(catalogs, routeState.get('sortBy', 'name'), routeState.get('sortDir', 'asc'));

        catalogs.forEach((catalog) => {
            let created = Moment(catalog.get('createdDate'));
            if (created.isValid()) {
                created = created.format(this.context.intl.messages['date-format']);
            } else {
                created = '-';
            }

            let active = '<i class="fas fa-check-circle text-green"></i> <span class="hidden-xs hidden-sm"> Active</span>';
            let inactive = '<i class="fas fa-minus-circle text-red"></i> <span class="hidden-xs hidden-sm"> Inactive</span>';

            let catalogName = catalog.get('name');
            if (nextProps.displayLinks) {
                catalogName = `<a href="/catalogs/${nextProps.type}/${catalog.get('id')}" class="edit-catalog">${catalog.get('name')}</a>`;
            }

            let activeValue;
            if (catalog.get('isActive')) {
                activeValue = active;
            } else {
                activeValue = inactive;
            }

            let row = [];
            row.push(
                catalogName,
                activeValue,
                catalog.get('numberOfItemsInGroup'),
                catalog.get('description') || '',
                created,
                ''
            );

            if (nextProps.type === 'asset') {
                row.splice(2, 0, catalog.get('displayAssetType'));
            }

            this.$tableAPI.row.add(row);

            return;
        });

        this.$tableAPI.draw(false);
        // Now, since the data has changed the columns widths, trigger
        // the resize handler in order to update the responsive feature.
        this.handleResize();

        return;
    }

    componentWillUnmount() {
        // Remove global listeners.
        window.removeEventListener('resize', this.handleResize);
    }

    getSortByClass(column) {
        let r = 'sorting';

        if (column === this.getRouteState().get('sortBy', 'name')) {
            r = `sorting_${this.getRouteState().get('sortDir', 'asc')}`;
        }

        return r;
    }

    handleResize() {
        this.$tableAPI.responsive.recalc();
        return;
    }

    /**
     * This function is kind of "special" because it needs to handle
     * events bubbled from the data table rows, these rows cannot use
     * the JSX syntax because they are created by the data table
     * jQuery plugin instead of React.
     */
    handleRowClick(event) {
        switch (true) {
        // Handle click on an user's name.
        case !!~event.target.className.indexOf('edit-catalog'):
            // Prevent the default anchor click event.
            event.preventDefault();
            this.context.router.push(event.target.getAttribute('href'));
            break;
        }

        return;
    }

    handleSort(column) {
        let newSortDir = 'asc';
        if (this.getRouteState().get('sortBy', 'name') === column && this.getRouteState().get('sortDir', 'asc') === 'asc') {
            newSortDir = 'desc';
        }
        this.setRouteState('sortBy', column)
            .setRouteState('sortDir', newSortDir)
            .apply();
    }

    render() {
        let numberOfItemsCol = <th className={ClassNames('no-wrap', this.getSortByClass('numberOfItemsInGroup'))} onClick={this.handleSort.bind(this, 'numberOfItemsInGroup', 'number')}>Titles</th>;
        let assetTypeCol;
        if (this.props.type === 'asset') {
            assetTypeCol = <th className={this.getSortByClass('displayAssetType')} onClick={this.handleSort.bind(this, 'displayAssetType')}>Asset Type</th>;
            numberOfItemsCol = <th className={ClassNames('no-wrap', this.getSortByClass('numberOfItemsInGroup'))} onClick={this.handleSort.bind(this, 'numberOfItemsInGroup', 'number')}>Assets</th>;
        }

        return (
            <div>
                <table id="catalogs-table" className="table table-bordered table-striped responsive">
                    <thead>
                        <tr>
                            <th className={this.getSortByClass('name')} onClick={this.handleSort.bind(this, 'name')}>Catalog Name</th>
                            <th className="no-wrap">Status</th>
                            {assetTypeCol}
                            {numberOfItemsCol}
                            <th className={this.getSortByClass('description')} onClick={this.handleSort.bind(this, 'description')}>Description</th>
                            <th className={this.getSortByClass('createdDate')} onClick={this.handleSort.bind(this, 'createdDate')}>Date Added</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody onClick={this.handleRowClick}>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default WithStoreOnRoute(Table);
