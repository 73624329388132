/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import jQuery from 'jquery';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

import AddUserPanel from './add-user-panel.js';
import {Sort} from '../../../common/local-search/filter';
import SlidingPanel from '../../../common/sliding-panel/sliding-panel';
import {SlidingPanelActions} from '../../../common/sliding-panel/sliding-panel-actions';
import {UserActions} from '../../../user/user-actions';
import UserStore from '../../../user/user-store';
import {TitleActions} from '../../title-actions';

require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('../../../styles/data-tables-brainiac.css');
// Load jQuery and register the datatables plugin.
require('datatables.net-responsive-bs');

class ListSubscriptions extends Component {
    static get propTypes() {
        return {
            clientTableSearch: PropTypes.bool,
            label: PropTypes.string.isRequired,
            subscriptionContentType: PropTypes.object.isRequired,
            subscriptions: PropTypes.instanceOf(Immutable.List).isRequired,
            tableId: PropTypes.string.isRequired,
            title: PropTypes.instanceOf(Immutable.Map).isRequired,
        };
    }

    static get defaultProps() {
        return {
            clientTableSearch: false,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            filters: UserStore.getState().get('filters'),
            sortFieldName: UserStore.getState().get('sortFieldName'),
            sortDirection: UserStore.getState().get('sortDirection'),
        };
    }

    static getStores() {
        return [UserStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            sortBy: 'userName',
            sortDir: 'asc',
            subscriptionIndex: undefined
        }, this.constructor.calculateState());

        this.addSubscriber = this.addSubscriber.bind(this);
        this.getSortByClass = this.getSortByClass.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.updateRows = this.updateRows.bind(this);
    }

    componentDidMount() {
        this.$table = jQuery(`#${this.props.tableId}`);
        this.$tableAPI = this.$table.DataTable({
            autoWidth: false,
            columnDefs: [{
                // Add the control class to the last column. This colum will
                // contain the button to show the responsive data.
                className: 'control',
                targets:   -1,
                width: 20
            }, {
                className: 'actions',
                targets: 2,
                width: 20
            }],
            iDisplayLength: 20,
            info: false,
            lengthChange: false,
            ordering: false,
            pagingType: 'simple_numbers',
            paging: true,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: this.props.clientTableSearch
        });
        this.updateRows(this.props, this.state);
        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.subscriptions !== this.props.subscriptions ||
            nextState.sortBy !== this.state.sortBy ||
            nextState.filters !== this.state.filters ||
            nextState.sortDir !== this.state.sortDir) {
            return true;
        }
        return false;
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.subscriptions !== this.props.subscriptions ||
            nextState.sortBy !== this.state.sortBy ||
            nextState.filters !== this.state.filters ||
            nextState.sortDir !== this.state.sortDir) {
            this.updateRows(nextProps, nextState);
        }
        return;
    }

    componentWillUnmount() {
        if (this.$tableAPI) {
            this.$tableAPI.destroy();
        }
        return;
    }

    addSubscriber(panel) {
        UserActions.get(this.state.filters, 0, 20, this.state.sortFieldName, this.state.sortDirection);
        SlidingPanelActions.show(panel);
    }

    getSortByClass(column) {
        let r = 'sorting';
        if (column === this.state.sortBy) {
            r = `sorting_${this.state.sortDir}`;
        }
        return r;
    }

    /**
     * This function is kind of "special" because it needs to handle
     * events bubbled from the data table rows, these rows cannot use
     * the JSX syntax because they are created by the data table
     * jQuery plugin instead of React.
     */
    handleRowClick(event) {
        switch (true) {
        // Handle click on a remove button.
        case !!~event.target.className.indexOf('remove-subscription'):
            TitleActions.removeById(
                `subscriptions.${this.props.subscriptionContentType.id}`,
                parseInt(event.target.getAttribute('data-index'), 10), 'userId'
            );
            break;
        }

        return;
    }

    handleSort(column) {
        let newSortDir = 'asc';
        if (this.state.sortBy === column && this.state.sortDir === 'asc') {
            newSortDir = 'desc';
        }

        this.setState({
            sortBy: column,
            sortDir: newSortDir
        });
    }

    updateRows(props, state) {
        this.$tableAPI.clear();

        let subscriptions = props.subscriptions;
        subscriptions = Sort(subscriptions, state.sortBy, state.sortDir);

        // Add sorted data to the jQuery datatable.
        subscriptions.forEach(s => {
            let id = s.get('userId');
            this.$tableAPI.row.add([
                s.get('clientFullName') || '',
                s.get('suggestedByFullName') || '',
                `<button class="btn bg-navy remove-subscription" data-index="${id}"><i class="fas fa-trash-alt"></i>&nbsp;Unsubscribe</button>`,
                ''
            ]);
            return;
        });

        this.$tableAPI.draw(false);
        return;
    }

    render() {
        let titleId = this.props.title.get('id');
        let titleName = this.props.title.get('name');
        let addUserPanelId = `add-user-subscription-${this.props.subscriptionContentType.id}`;
        let addUserPanel = <AddUserPanel
            titleId={titleId}
            slidingPanelId={addUserPanelId}
            subscriptionContentType={this.props.subscriptionContentType.id}
            subscriptions={this.props.subscriptions}
        />;
        let addUserPanelTitle = <span>{this.context.intl.messages['titles.create.subscriptions.add-subscriber.title']} <small>{titleName}</small></span>;
        return (
            <div>
                <SlidingPanel id={addUserPanelId} title={addUserPanelTitle} icon="fas fa-plus-circle">{addUserPanel}</SlidingPanel>
                <h3>{this.props.label}:&nbsp;<Button bsStyle="primary" className="pull-right" onClick={this.addSubscriber.bind(this, addUserPanelId)}><i className="fas fa-plus-square"></i>&nbsp;{this.context.intl.messages['titles.create.subscriptions.add-subscriber']}</Button></h3>
                <table id={`${this.props.tableId}`} className="table table-bordered table-striped responsive">
                    <thead>
                        <tr>
                            <th className={this.getSortByClass('clientFullName')} onClick={this.handleSort.bind(this, 'clientFullName')}>{this.context.intl.messages['titles.create.subscriptions.list.user']}</th>
                            <th className={this.getSortByClass('suggestedByFullName')} onClick={this.handleSort.bind(this, 'suggestedByFullName')}>{this.context.intl.messages['titles.create.subscriptions.list.suggested-by']}</th>
                            <th>{this.context.intl.messages['titles.create.subscriptions.list.unsubscribe']}</th>
                            <th className="no-sort"></th>
                        </tr>
                    </thead>
                    <tbody onClick={this.handleRowClick}></tbody>
                </table>
            </div>
        );
    }
}

export default Container.create(ListSubscriptions);
