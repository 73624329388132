/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';
import {Route} from 'react-router';

import CCEditor from './cc-editor';
import Jobs from './jobs/browse';
import Processes from './processes/browse';
import ProcessDetail from './processes/process-detail';
import EventMessage from './processes/process-detail/messages/event-message';
import ProcessDetail2 from './processes/process-detail-v2';
import EventMessage2 from './processes/process-detail-v2/messages/event-message';
import ProcessDetail3 from './processes/process-detail-v3';
import EventMessage3 from './processes/process-detail-v3/messages/event-message';
import BrowseProxies from './proxies/browse';
import Configuration from './setup/configuration';
import Timeline from './timeline';
import BrowseTimelines from './timelines/browse';
import Browse from './video-delivery/browse';
import VideoDeliveryDetail from './video-delivery/video-delivery-detail';
import EditTimeline from '../assets/timeline';
import CreateVideo from '../assets/video/create/create';
import WithPermissions from '../decorators/with-permissions';
import {RequirePerm} from '../router/router-helpers';
import SessionStore from '../user/session/session-store';

import MachineLearning from '~/src/hardac/machine-learning';

export default (
    <Route path="hardac">
        <Route
            component={WithPermissions(BrowseTimelines)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.HARDAC.TIMELINE.BROWSE)}
            path="timelines"
        />

        <Route
            component={Timeline}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.HARDAC.TIMELINE.READ)}
            path="timeline/:id"
        >
            <Route path="audio"/>
            <Route path="clips"/>
            <Route path="history"/>
            <Route path="processes"/>
        </Route>

        <Route
            component={EditTimeline}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.HARDAC.TIMELINE.READ)}
            path="timeline/:id/edit-asset"
        >
            <Route path="catalogs"/>
            <Route path="history"/>
            <Route path="talent"/>
            <Route path="titles"/>
            <Route path="users"/>
        </Route>

        <Route
            component={WithPermissions(Browse)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.HARDAC.VIDEO_DELIVERY.BROWSE)}
            path="video-inbox"
        />
        <Route
            component={WithPermissions(VideoDeliveryDetail)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.HARDAC.VIDEO_DELIVERY.READ)}
            path="video-inbox/:id"
        >
            <Route path="audio"/>
            <Route path="options"/>
            <Route path="history"/>
        </Route>

        <Route
            component={WithPermissions(Jobs)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.HARDAC.JOBS.BROWSE)}
            path="jobs"
        />
        <Route
            component={WithPermissions(Processes)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.HARDAC.PROCESSES.BROWSE)}
            path="processes"
        />
        <Route
            component={WithPermissions(MachineLearning)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.HARDAC.MACHINE_LEARNING.BROWSE)}
            path="machine-learning"
        />
        <Route
            component={WithPermissions(ProcessDetail)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.HARDAC.PROCESSES.READ)}
            path="processes/:id"
        >
            <Route path="messages"/>
        </Route>
        <Route
            component={WithPermissions(EventMessage)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.HARDAC.PROCESSES.READ)}
            path="processes/:id/messages/:id"
        />

        {/* version 2 */}
        <Route
            component={WithPermissions(ProcessDetail2)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.HARDAC.PROCESSES.READ)}
            path="processes/v2/:id"
        >
            <Route path="messages"/>
        </Route>
        <Route
            component={WithPermissions(EventMessage2)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.HARDAC.PROCESSES.READ)}
            path="processes/v2/:id/messages/:id"
        />

        {/* check timeline processeses status */}
        <Route
            component={WithPermissions(ProcessDetail3)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.HARDAC.PROCESSES.READ)} // TODO: Also requires RequirePerm.bind(this, SessionStore.PERMISSIONS.HARDAC.TIMELINE.READ)}
            path="processes/v3/timeline/:timelineId/status"
        >
            <Route path="messages"/>
        </Route>
        <Route
            component={WithPermissions(EventMessage3)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.HARDAC.PROCESSES.READ)}
            path="processes/v3/timeline/:timelineId/status/messages/:id"
        />

        <Route
            component={WithPermissions(BrowseProxies)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.HARDAC.PROXY.BROWSE)}
            path="proxies"
        />
        <Route
            component={WithPermissions(CreateVideo)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.HARDAC.PROXY.READ)}
            path="proxy/:id"
            mode="edit"
        >
            <Route path="catalogs"/>
            <Route path="events"/>
            <Route path="history"/>
            <Route path="users"/>
            <Route path="talent"/>
            <Route path="titles"/>
            <Route path="forensic"/>
            <Route path="playback-restrictions"/>
        </Route>

        <Route
            component={WithPermissions(Configuration)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.HARDAC.CONFIGURATION.EDIT)}
            path="setup/configuration"
        >
            <Route path="history"/>
        </Route>

        {CCEditor}
    </Route>
);
