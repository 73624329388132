/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ControlLabel, FormControl, FormGroup} from 'react-bootstrap';

import {FormSection, FormRow} from '../../../common/form/form';
import Config from '../../../config/config.js';

class Summary extends Component {

    static get propTypes() {
        return {
            errorMessage: PropTypes.instanceOf(Immutable.Map).isRequired,
            process: PropTypes.instanceOf(Immutable.Map).isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    render() {
        let {
            id,
            processType,
            processTypeValue,
            eventHandlerClassName,
            eventMessageSent,
            eventType,
            handlerId,
            logEventId,
            objectId,
            logEventMessage,
            logRecordUrl,
            processComplete,
            sentDate,
            receivedDate,
            acknowledgeDate,
            failureDate,
            processCompleteDate
        } = this.props.process.toJS();

        let sentDateFormated = '-';
        if (sentDate) {
            sentDateFormated = Moment(sentDate).tz(Config.DefaultTimezone).format(this.context.intl.messages['datetime-format']);
        }

        let receivedDateFormated = '-';
        if (receivedDate) {
            receivedDateFormated = Moment(receivedDate).tz(Config.DefaultTimezone).format(this.context.intl.messages['datetime-format']);
        }

        let acknowledgeDateFormated = '-';
        if (acknowledgeDate) {
            acknowledgeDateFormated = Moment(acknowledgeDate).tz(Config.DefaultTimezone).format(this.context.intl.messages['datetime-format']);
        }

        let failureDateFormated = '-';
        if (failureDate) {
            failureDateFormated = Moment(failureDate).tz(Config.DefaultTimezone).format(this.context.intl.messages['datetime-format']);
        }

        let processCompleteDateFormated = '-';
        if (processCompleteDate) {
            processCompleteDateFormated = Moment(processCompleteDate).tz(Config.DefaultTimezone).format(this.context.intl.messages['datetime-format']);
        }

        let errorMessage = '-';
        if (this.props.errorMessage) {
            errorMessage = this.props.errorMessage.get('errorMessage') || '-';
        }

        let failureInfo;

        if (failureDate) {
            failureInfo = <div>
                <h3><i className="fas fa-times-circle"></i>&nbsp;{this.context.intl.messages['hardac.processes.summary.failure']}</h3>
                <FormSection>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.processes.failure-date']}</ControlLabel>
                            <FormControl.Static>{failureDateFormated}</FormControl.Static>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.processes.error-message']}</ControlLabel>
                            <FormControl.Static>{errorMessage}</FormControl.Static>
                        </FormGroup>
                    </FormRow>
                </FormSection>
            </div>;
        }

        return (
            <div>
                {failureInfo}
                <h3><i className="fas fa-info-circle"></i>&nbsp;{this.context.intl.messages['hardac.processes.summary.info']}</h3>
                <FormSection>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.processes.id']}</ControlLabel>
                            <FormControl.Static>{id || '-'}</FormControl.Static>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.processes.handler-id']}</ControlLabel>
                            <FormControl.Static>{handlerId || '-'}</FormControl.Static>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.processes.object-id']}</ControlLabel>
                            <FormControl.Static>{objectId || '-'}</FormControl.Static>
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.processes.process-type']}</ControlLabel>
                            <FormControl.Static>{processType || '-'}</FormControl.Static>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.processes.process-type-value']}</ControlLabel>
                            <FormControl.Static>{processTypeValue || '-'}</FormControl.Static>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.processes.process-complete']}</ControlLabel>
                            <FormControl.Static>{processComplete || '-'}</FormControl.Static>
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.processes.event-handler-class-name']}</ControlLabel>
                            <FormControl.Static>{eventHandlerClassName || '-'}</FormControl.Static>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.processes.event-message-sent']}</ControlLabel>
                            <FormControl.Static>{eventMessageSent || '-'}</FormControl.Static>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.processes.event-type']}</ControlLabel>
                            <FormControl.Static>{eventType || '-'}</FormControl.Static>
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.processes.log-event-id']}</ControlLabel>
                            <FormControl.Static>{logEventId || '-'}</FormControl.Static>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.processes.log-event-name']}</ControlLabel>
                            <FormControl.Static>{logEventMessage || '-'}</FormControl.Static>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.processes.log-event-url']}</ControlLabel>
                            <FormControl.Static>{logRecordUrl || '-'}</FormControl.Static>
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.processes.sent-date']}</ControlLabel>
                            <FormControl.Static>{sentDateFormated}</FormControl.Static>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.processes.received-date']}</ControlLabel>
                            <FormControl.Static>{receivedDateFormated}</FormControl.Static>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.processes.acknowledge-date']}</ControlLabel>
                            <FormControl.Static>{acknowledgeDateFormated}</FormControl.Static>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.processes.complete-date']}</ControlLabel>
                            <FormControl.Static>{processCompleteDateFormated}</FormControl.Static>
                        </FormGroup>
                    </FormRow>
                </FormSection>
            </div>
        );
    }
}

export default Summary;
