/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {AssetRightsStoreConstants} from './asset-rights-actions';
import {AssetRightsConstants} from './asset-rights-constants';
import Dispatcher from '../../dispatcher/dispatcher';

/**
 * Look for a constant with id === objectId in a map.
 */
const findInConstant = (constants, objectId) => {
    return Immutable.fromJS(
        Object.keys(constants)
            .map(k => constants[k])
            .filter(obj => objectId === obj.id)[0]
    );
};

class AssetRightsStore extends ReduceStore {
    getRightsMediaType(rightsMediaTypeId) {
        return findInConstant(AssetRightsConstants.RIGHTS_MEDIA_TYPES, rightsMediaTypeId);
    }

    getRightsTermType(rightsTermTypeId) {
        return findInConstant(AssetRightsConstants.RIGHTS_TERM_TYPES, rightsTermTypeId);
    }

    getRightsTerritoryType(rightsTerritoryTypeId) {
        return findInConstant(AssetRightsConstants.RIGHTS_TERRITORY_TYPES, rightsTerritoryTypeId);
    }

    getRightsType(rightsTypeId) {
        return findInConstant(AssetRightsConstants.RIGHTS_TYPES, rightsTypeId);
    }

    getRightsInfoTypeFromValues(rightsType, rightsTerritoryType, rightsMediaType, rightsTermType, rightsNotes) {
        let foundRights = AssetRightsConstants.RIGHTS_INFO_TYPES[Object.keys(AssetRightsConstants.RIGHTS_INFO_TYPES)
            .find(k => {
                let values = AssetRightsConstants.RIGHTS_INFO_TYPES[k].values;
                if (!values) {
                    values = {};
                }
                return values.videoRightsType === rightsType &&
                    values.videoRightsTerritoryType === rightsTerritoryType &&
                    values.videoRightsMediaType === rightsMediaType &&
                    values.videoRightsTermType === rightsTermType &&
                    (values.rightsNotes === rightsNotes || !rightsNotes);
            })] || AssetRightsConstants.RIGHTS_INFO_TYPES.CUSTOM;
        return foundRights;
    }

    getInitialState() {
        return Immutable.fromJS({
            rightsMatchedType: this.getRightsInfoTypeFromValues(undefined, undefined, undefined, undefined),
            rightsMediaType: Immutable.fromJS(AssetRightsConstants.toArray('RIGHTS_MEDIA_TYPES')),
            rightsTermType: Immutable.fromJS(AssetRightsConstants.toArray('RIGHTS_TERM_TYPES')),
            rightsTerritoryType: Immutable.fromJS(AssetRightsConstants.toArray('RIGHTS_TERRITORY_TYPES')),
            rightsType: Immutable.fromJS(AssetRightsConstants.toArray('RIGHTS_TYPES')),
        });
    }

    reduce(state, action) {
        switch (action.actionType) {
        case AssetRightsStoreConstants.ASSET_RIGHTS.SAVE_MATCHED_RIGHTS:
            state = state.merge({
                rightsMatchedType: action.value
            });
            break;
        }
        return state;
    }
}

export default new AssetRightsStore(Dispatcher);
