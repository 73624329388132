/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import QueryString from 'querystring-es3';
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

import Summary from './summary/summary';

import RouteValidator from '~/src/common/custom-validator/route/route';
import DocumentTitle from '~/src/common/document-title';
import {MODAL_TYPE} from '~/src/common/notification/modal';
import {NotificationActions} from '~/src/common/notification/notification-actions';
import {Tab, Tabs} from '~/src/common/routing-tab/routing-tab';
import {History, SideNotes} from '~/src/common/side-notes/side-notes';
import {WithStoreOnRoute} from '~/src/common/store-on-route';
import {ErrorsWarnings} from '~/src/common/validations/errors-warnings';
import ActionsMenu from '~/src/layout/actions-menu/actions-menu';
import StickyHeader from '~/src/layout/sticky-header/sticky-header';
import Preloader from '~/src/preloader/';
import PreloaderStore from '~/src/preloader/preloader-store';
import {RouterActions} from '~/src/router/router-actions';
import {GroupActions, GroupConstants} from '~/src/security/group/group-actions';
import GroupStore from '~/src/security/group/group-store';
import {ActionHistoryActions, ActionHistoryConstants} from '~/src/system/action-history/action-history-actions';
import SessionStore from '~/src/user/session/session-store';
import UserStore from '~/src/user/user-store';

class Create extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            params: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired,
            /**
             * Validate custom props inside the route object.
             */
            route: RouteValidator // eslint-disable-line react/require-default-props
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            activePage: UserStore.getState().get('activePage'),
            history: GroupStore.getState().get('station').get('history'),
            partners: GroupStore.getState().get('partners'),
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible'),
            selectAll: UserStore.getState().get('selectAll'),
            selectedUsers: UserStore.getState().get('selectedUsers'),
            stationGroup: GroupStore.getState().get('station').get('group'),
            total: UserStore.getState().get('total'),
            totalPages: UserStore.getState().get('totalPages'),
            users: UserStore.getState().get('users'),
        };
    }

    static getStores() {
        return [GroupStore, PreloaderStore, UserStore];
    }

    static getPermissions() {
        return {
            canView: SessionStore.canUser(SessionStore.PERMISSIONS.STATIONS.CONFIGURATION),
            users: {
                canRead: SessionStore.canUser(SessionStore.PERMISSIONS.GROUPS.USERS.READ),
                canRemove: SessionStore.canUser(SessionStore.PERMISSIONS.GROUPS.USERS.REMOVE),
                canSyncElasticSearch: SessionStore.canUser(SessionStore.PERMISSIONS.GROUPS.USERS.SYNC_ES)
            }
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            activeTab: 0,
            size: 20,
        }, this.constructor.calculateState());

        this.addNote = this.addNote.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleElasticSearchSync = this.handleElasticSearchSync.bind(this);
        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleRemoveSelectedUsers = this.handleRemoveSelectedUsers.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.isDirty = this.isDirty.bind(this);
        this.showDeleteDialog = this.showDeleteDialog.bind(this);
        this.showRemoveUsersDialog = this.showRemoveUsersDialog.bind(this);
    }

    componentWillMount() {
        GroupActions.clear();
    }

    componentDidMount() {
        this.initStores(this.props);
        RouterActions.registerRedirectCheck((location) => {
            return this.isDirty(location);
        });
        return;
    }

    componentWillReceiveProps(nextProps) {
        /* istanbul ignore else */
        if (nextProps.route.mode !== this.props.route.mode ||
            nextProps.params.id !== this.props.params.id) {

            this.initStores(nextProps);
        }
        RouterActions.registerRedirectCheck((location) => {
            return this.isDirty(location);
        });
        return;
    }

    componentWillUnmount() {
        RouterActions.clearRedirectChecks();
    }

    addNote(description) {
        ActionHistoryActions.addNote(this.props.params.id, description, ActionHistoryConstants.ACTION_OBJECTS.GROUP, GroupConstants.STATION.name);
    }

    handleCancel() {
        RouterActions.redirect('/station-groups', true);
    }

    handleElasticSearchSync() {
        GroupActions.syncElasticSearch(this.props.params.id);
    }

    handleLoadPage(pageNumber) {
        // pageNumber x this.state.size = offset.
        let query = Object.assign(
            {},
            this.props.location.query,
            {
                'offset': pageNumber * this.state.size,
                'size': this.state.size
            }
        );

        RouterActions.redirect(`${this.props.location.pathname}?${QueryString.stringify(query)}`);
        return;
    }

    handleRemove() {
        GroupActions.remove(GroupConstants.STATION, this.props.params.id);
        return;
    }

    handleRemoveSelectedUsers() {
        GroupActions.removeUsers(this.state.stationGroup, this.state.selectedUsers, GroupConstants.STATION);
        return;
    }

    handleSave() {
        const options = {
            messages: {
                error: `station-groups.${this.props.route.mode}.error`,
                success: `station-groups.${this.props.route.mode}.success`
            }
        };

        GroupActions.saveStationGroup(
            GroupStore.getState().get('station').get('group'),
            GroupStore.getState().get('station').get('originalGroup'),
            options,
            true
        );
        return;
    }

    initStores(props) {
        if (props.route.mode === 'edit') {
            GroupActions.findStationGroupById(props.params.id, true);
        }
        return;
    }

    /* istanbul ignore next */
    isDirty(location) {
        if (this.props.route.mode === 'create') {
            return false;
        }
        if (location) {
            let goingTo = location.pathname.split('/');
            let current = this.props.location.pathname.split('/');
            if (goingTo[1] === current[1] && /* groups */
                goingTo[2] === current[2] && /* user-groups */
                goingTo[3] === current[3] /* id */) {
                return false; // just changing tabs no confirmation needed.
            }
        }
        return !GroupStore.getState().get('station').get('group').equals(GroupStore.getState().get('station').get('originalGroup'));
    }

    showDeleteDialog(event) {
        event.preventDefault();
        NotificationActions.show(
            MODAL_TYPE.DANGER,
            this.context.intl.messages['groups.user.edit.modal.title'],
            this.context.intl.messages['groups.user.edit.modal.body'],
            this.context.intl.messages['groups.user.edit.modal.confirm'],
            this.handleRemove
        );
    }

    showRemoveUsersDialog(event) {
        event.preventDefault();

        NotificationActions.show(
            MODAL_TYPE.DANGER,
            this.context.intl.messages['groups.user.remove-users.modal.title'],
            this.context.intl.messages['groups.user.remove-users.modal.body'],
            this.context.intl.messages['groups.user.remove-users.modal.confirm'],
            this.handleRemoveSelectedUsers
        );
    }

    render() {
        let actionsMenu;
        const mode = this.props.route.mode;
        const validations = GroupStore.getStationValidations();
        let title;
        const disabled = !this.props.permissions.canView;

        let baseRoute = `/station-groups/${this.props.params.id}`;
        if (mode === 'create') {
            baseRoute = '/station-groups/create';
            actionsMenu = <ActionsMenu
                canSave={!validations.length}
                onCancel={this.handleCancel}
                onSave={this.handleSave}
                saveText={this.context.intl.messages['station-groups.create.text']}
            />;

            title = <em>{this.context.intl.messages['station-groups.create.title']}</em>;
        }

        let historyTab = null;
        let eventsAndNotes = null;

        if (mode === 'edit') {
            let elasticSearchBtn;
            if (this.props.permissions.users.canSyncElasticSearch) {
                elasticSearchBtn = <Button
                    bsSize="large"
                    className="btn btn-primary-outline Mr(3px) Mb(3px)"
                    onClick={this.handleElasticSearchSync}
                >
                    <i className="fas fa-database"/>&nbsp;{this.context.intl.messages['groups.user.edit.elastic-search.sync']}
                </Button>;
            }

            // The false values are hard-coded and should be removed once the endpoint gets fixed [BRAIN-1566]
            actionsMenu = <ActionsMenu
                canDelete={false}
                canEdit={this.props.permissions.canView}
                canSave={!validations.length && !disabled}
                onCancel={this.handleCancel}
                onDelete={false}
                onSave={this.handleSave}
            >
                {elasticSearchBtn}
            </ActionsMenu>;

            title = <em>{this.context.intl.messages['station-groups.edit.title']}</em>;

            eventsAndNotes = (
                <SideNotes
                    basePath={`/station-groups/${this.props.params.id}`}
                    dialogMessage={this.context.intl.messages['common.side-notes.add-note.body']}
                    disabled={disabled}
                    notes={this.state.history.toJS().slice(0, 4)}
                    onAddNote={this.addNote}
                    title={this.context.intl.messages['common.side-notes.title']}
                />
            );

            historyTab = <Tab route={`${baseRoute}/history`} title={this.context.intl.messages['common.tab-title.history']} tabClassName="pull-right">
                <History
                    actionObject={ActionHistoryConstants.ACTION_OBJECTS.GROUP}
                    id={this.props.params.id}
                    pagination
                    pathname={this.props.location.pathname}
                    query={this.props.location.query}
                />
            </Tab>;
        }

        let summaryTab = <Tab route={baseRoute} title={this.context.intl.messages['groups.summary']}>
            <Summary
                location={this.props.location}
                mode={mode}
                disabled={disabled}
                stationGroup={this.state.stationGroup}
            />
        </Tab>;

        let entityName;
        /* istanbul ignore if */
        if (this.state.stationGroup.get('name')) {
            entityName = this.state.stationGroup.get('name');
        }

        return (
            <DocumentTitle
                message={`document-titles.${mode}-station-group`} entityName={entityName}
            >
                <div>
                    <StickyHeader>
                        <div className="col-md-6">
                            <h1>
                                <i className="fa-solid fa-tv-retro"></i>&nbsp;{this.context.intl.messages[`station-groups.${mode}.title`]}
                                &nbsp;
                            </h1>
                            <p className="padding-top-20">
                                {title}
                            </p>
                        </div>
                        {actionsMenu}
                    </StickyHeader>
                    <Preloader show={this.state.preloaderVisible} fixed loadingDots>
                        <section className="content" id="contentContainer">
                            <div className="row">
                                <div className="col-lg-9">
                                    <Tabs location={this.props.location}>
                                        {summaryTab}
                                        {historyTab}
                                    </Tabs>
                                </div>
                                <div className="col-lg-3">
                                    <ErrorsWarnings
                                        title={this.context.intl.messages['common.errors-warnings-title']}
                                        validations={validations}
                                    />
                                    {eventsAndNotes}
                                </div>
                            </div>
                        </section>
                    </Preloader>
                </div>
            </DocumentTitle>
        );
    }
}

export default WithStoreOnRoute(
    Container.create(Create)
);
