/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React, {StatelessComponent} from 'react';
import Select from 'react-select';

import {ActiveFilterViewProps} from './types';

/* eslint-disable react/prop-types */
const ActiveFilterView: StatelessComponent<ActiveFilterViewProps> = (props) => {
    return <Select
        classNamePrefix="dropdown"
        getOptionLabel={data => data.label}
        getOptionValue={data => data.value}
        isClearable={true}
        isMulti={false}
        name="asset-type"
        onChange={props.onChange}
        options={props.options}
        placeholder="Select..."
        value={props.value}
    />;
};

export default ActiveFilterView;
