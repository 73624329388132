/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

/**
 *  Returns a partial copy of an object omitting the keys specified.
 *
 * @param obj The object to copy from
 * @param keys an array of String property names to omit from the new object
 * @returns {Object} A new object with properties from `keys` not on it.
 * @example
 *
 * const object = { 'a': 1, 'b': '2', 'c': 3 }
 *
 * omit(object, ['a', 'c'])
 * // => { 'b': '2' }
 */
export default function omit<T, K extends keyof T & string>(obj: T, keys: K[]): Omit<T, K> {
    const res = {...obj};
    keys.forEach((k) => {
        delete res[k];
    });
    return res;
}
