/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

import Dispatcher from '../../dispatcher/dispatcher';
import Request from '../../request';
import LookupActions from '../lookup-actions';

const CONSTANTS = {
    GET: {
        ERROR: 'user-select_actions.get.error',
        START: 'user-select_actions.get.start',
        SUCCESS: 'user-select_actions.get.success'
    }
};

class UserSelectActions extends LookupActions {

    constructor(path, CONSTS) {
        super(path, CONSTS);
    }

    get(usersearch, offset, size) {
        Dispatcher.dispatch({
            actionType: this.CONSTANTS.GET.START
        });

        Request.get(this.path).query({
            offset: offset,
            size: size,
            name: usersearch,
            email: usersearch,
            operator:'OR'
        }).exec().then(res => {
            // Some API's return pagination objects,
            // some other return plain arrays.
            let items = res.body.results.map(cr => {
                return {id: cr.id, name: cr.fullName};
            });
            Dispatcher.dispatch({
                actionType: this.CONSTANTS.GET.SUCCESS,
                offset: res.body.offset,
                items: Immutable.fromJS(items).sortBy(this.sortItemsBy),
                size: res.body.size,
                total: parseInt(res.header['wbtv-total-count'], 10)
            });
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: this.CONSTANTS.GET.ERROR
            });
            throw err;
        });

        return;
    }


}

let actions = new UserSelectActions('user', CONSTANTS);

export {
    actions as UserSelectActions,
    CONSTANTS as UserSelectConstants
};
