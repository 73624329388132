/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import Summary from './summary/summary';
import RouteValidator from '../../../common/custom-validator/route/route';
import DocumentTitle from '../../../common/document-title';
import {MODAL_TYPE} from '../../../common/notification/modal';
import {NotificationActions} from '../../../common/notification/notification-actions';
import {Tab, Tabs} from '../../../common/routing-tab/routing-tab';
import SearchBox from '../../../common/search-box/search-box';
import {History, SideNotes} from '../../../common/side-notes/side-notes';
import {WithStoreOnRoute} from '../../../common/store-on-route';
import {Debounce} from '../../../common/utils/utils';
import {ErrorsWarnings} from '../../../common/validations/errors-warnings';
import {ERRORTYPE} from '../../../common/validations/helpers';
import ActionsMenu from '../../../layout/actions-menu/actions-menu';
import StickyHeader from '../../../layout/sticky-header/sticky-header';
import Preloader from '../../../preloader';
import {RouterActions} from '../../../router/router-actions';
import {GroupActions, GroupConstants} from '../../../security/group/group-actions';
import GroupStore from '../../../security/group/group-store';
import {ActionHistoryActions, ActionHistoryConstants} from '../../../system/action-history/action-history-actions';
import ListTitles from '../../../titles/browse/list-titles';
import SessionStore from '../../../user/session/session-store';
import DeleteInfoModal from '../../delete-info-modal';
import UserGroups from '../../user-groups';

class Create extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            params: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired,
            route: RouteValidator // eslint-disable-line react/require-default-props
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            groupTitles: GroupStore.getState().get('title').get('groupTitles'),
            history: GroupStore.getState().get('title').get('history'),
            originalTitleCatalog: GroupStore.getState().get('title').get('originalGroup'),
            showPreloader: GroupStore.getState().get('showPreloader'),
            titleCatalog: GroupStore.getState().get('title').get('group'),
            totalCount: GroupStore.getState().get('title').get('totalCount'),
            userGroups: GroupStore.getState().get('group').get('userGroups')
        };
    }

    static getStores() {
        return [GroupStore];
    }

    static getPermissions() {
        return {
            canCreate: SessionStore.canUser(SessionStore.PERMISSIONS.CATALOGS.TITLE.CREATE),
            canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.CATALOGS.TITLE.EDIT),
            canReadGroups: SessionStore.canUser(SessionStore.PERMISSIONS.GROUPS.READ),
            canReadTitles: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.READ)
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            complete: false,
            activeTab: 0,
            showDeleteInfoModal: false
        }, this.constructor.calculateState());

        this.addNote = this.addNote.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
        this.isDirty = this.isDirty.bind(this);
        this.showDeleteDialog = this.showDeleteDialog.bind(this);
        this.toggleDeleteInfoModal = this.toggleDeleteInfoModal.bind(this);
    }

    componentDidMount() {
        this.init(this.props);
        return;
    }

    componentWillUpdate(nextProps) {
        if (this.props.location !== nextProps.location && nextProps.location.pathname.includes('titles')) {
            GroupActions.getGroupTitles(this.props.params.id, 20, this.getRouteState().get('offset'), this.getRouteState().get('title'));
        }
        return;
    }

    addNote(description) {
        ActionHistoryActions.addNote(this.props.params.id, description, ActionHistoryConstants.ACTION_OBJECTS.GROUP, GroupConstants.TITLE.name);
    }

    handleCancel() {
        RouterActions.redirect('/catalogs/title', true);
    }

    handleLoadPage(pageNumber) {
        // pageNumber x this.state.size = offset.
        this.setRouteState('offset', pageNumber * 20)
            .apply();
        return;
    }

    handleRemove() {
        GroupActions.remove(GroupConstants.TITLE, this.props.params.id);
        return;
    }

    handleSave() {
        let options = {
            messages: {
                error: `catalogs.title.${this.props.route.mode}.error`,
                success: `catalogs.title.${this.props.route.mode}.success`
            }
        };

        GroupActions.save(GroupConstants.TITLE, this.state.titleCatalog, options);
        return;
    }


    /**
     * Handle clicks on pagination controls.
     * @param  {Number} pageNumber page to load.
     */

    handleSearchTerm(term) {
        // FIXME: review how to enable this again.
        // The main problem is when the user wants to search using
        // both the global search box and the filter options.
        this.setRouteState('title', term)
            .setRouteState('size', 20)
            .clearRouteState('offset')
            .apply();
        return;
    }

    init(props) {
        if (props.route.mode === 'edit') {
            GroupActions.findGroupById(GroupConstants.TITLE, props.params.id);
            GroupActions.getCatalogUserGroups(props.params.id);
            GroupActions.getGroupTitles(props.params.id, this.getRouteState().get('offset'), this.getRouteState().get('offset'), this.getRouteState().get('title'));
        } else {
            GroupActions.clear();
        }
        RouterActions.registerRedirectCheck(() => {
            return this.isDirty();
        });
    }

    isDirty() {
        if (this.props.route.mode === 'create') {
            return false;
        }
        return !this.state.titleCatalog.equals(this.state.originalTitleCatalog);
    }

    showDeleteDialog() {
        if (this.state.originalTitleCatalog.get('isActive')) {
            this.toggleDeleteInfoModal();
        } else {
            NotificationActions.show(
                MODAL_TYPE.DANGER,
                this.context.intl.messages['catalogs.title.edit.confirm-delete.title'],
                this.context.intl.messages['catalogs.title.edit.confirm-delete.body'],
                this.context.intl.messages['catalogs.title.edit.confirm-delete.confirm'],
                this.handleRemove
            );
        }
    }

    toggleDeleteInfoModal() {
        this.setState((prevState)=>({
            showDeleteInfoModal: !prevState.showDeleteInfoModal
        }));
    }

    render() {
        let actionsMenu;
        let disabled = true;
        let mode = this.props.route.mode;
        let eventsAndNotes;
        let historyTab;
        let titlesTab;
        let userGroupsTab;

        let activePage = Math.ceil((this.props.location.query.offset || 0) / 20 ) || 0;
        let totalPages = Math.ceil(this.state.totalCount/20) || 0;

        let validations = GroupStore.getTitlesValidations();

        if (mode === 'create') {
            disabled = false;

            actionsMenu = <ActionsMenu
                canSave={!validations.length}
                onCancel={this.handleCancel}
                onSave={this.handleSave}
                saveText={this.context.intl.messages['catalogs.title.create.button']}
            />;
        }

        let summaryRoute = '/catalogs/title/create';
        if (mode === 'edit') {
            summaryRoute = `/catalogs/title/${this.props.params.id}`;

            //STUDIO-10346: block users from editing event catalogs
            const isEventCatalog = GroupStore.getCatalogType(this.state.titleCatalog.get('titleCatalogType')).get('name') === 'Screeners';
            disabled = !this.props.permissions.canEdit || isEventCatalog;
            if (isEventCatalog) {
                validations = [{
                    message: this.context.intl.messages['catalogs.event-catalogs.warning'],
                    severity: 2,
                    type: 'custom',
                    errorType: ERRORTYPE.WARNING,
                    icon: 'fas fa-flag',
                }];
            }

            actionsMenu = <ActionsMenu
                canDelete={!disabled}
                canEdit={!disabled}
                canSave={!validations.length && !disabled}
                hideSaveAndSync={!this.props.permissions.canEdit}
                onCancel={this.handleCancel}
                onDelete={this.showDeleteDialog}
                onSave={this.handleSave}
            />;

            eventsAndNotes = (
                <SideNotes
                    basePath={`/catalogs/title/${this.props.params.id}`}
                    dialogMessage={this.context.intl.messages['common.side-notes.add-note.body']}
                    disabled={ !this.props.permissions.canEdit }
                    notes={this.state.history.toJS().slice(0, 4)}
                    onAddNote={this.addNote}
                    title={this.context.intl.messages['common.side-notes.title']}
                />
            );

            historyTab = <Tab route={`/catalogs/title/${this.props.params.id}/history`} title={this.context.intl.messages['common.tab-title.history']} tabClassName="pull-right">
                <History
                    actionObject={ActionHistoryConstants.ACTION_OBJECTS.GROUP}
                    id={this.props.params.id}
                    pagination
                    pathname={this.props.location.pathname}
                    query={this.props.location.query}
                />
            </Tab>;

            titlesTab = <Tab route={`/catalogs/title/${this.props.params.id}/titles`} title={this.context.intl.messages['common.tab-title.titles']}>
                <h3><i className="far fa-newspaper"></i>&nbsp;{this.context.intl.messages['common.tab-title.titles']}</h3>
                <hr/>
                <div className="col-md-6 col-md-offset-3">
                    <SearchBox onChange={this.handleSearchTerm} value={this.getRouteState().get('title')}/>
                </div>
                <ListTitles
                    activePage={activePage}
                    complete={this.state.complete}
                    displayLinks={this.props.permissions.canReadTitles}
                    sortDirection={this.getRouteState().get('sort-direction') || 'desc'}
                    sortFieldName={this.getRouteState().get('sort-field-name') || 'updatedDate'}
                    onPageChange={this.handleLoadPage}
                    titles={this.state.groupTitles}
                    totalPages={totalPages}
                />
            </Tab>;
            userGroupsTab = <Tab route={`/catalogs/title/${this.props.params.id}/user-groups`} title={this.context.intl.messages['common.tab-title.user-groups']}>
                <h3><i className="far fa-users"></i>&nbsp;{this.context.intl.messages['common.tab-title.user-groups']}</h3>
                <hr/>
                <UserGroups
                    displayLinks={this.props.permissions.canReadGroups}
                    userGroups={this.state.userGroups}
                />
            </Tab>;

        }

        let summaryTab = <Tab route={summaryRoute} title={this.context.intl.messages['common.tab-title.summary']}>
            <Summary
                disabled={disabled}
                titleCatalog={this.state.titleCatalog}
                mode={this.props.route.mode}
            />
        </Tab>;

        let entityName;

        if (this.state.titleCatalog.get('name')) {
            entityName = this.state.titleCatalog.get('name');
        }

        return (
            <DocumentTitle
                message={`document-titles.${mode}-title-catalogs`} entityName={entityName}
            >
                <div>
                    <StickyHeader>
                        <div className="col-md-6">
                            <h1>
                                <i className="fas fa-book"></i>&nbsp;{this.context.intl.messages[`catalogs.title.${mode}.title`]}
                                &nbsp;
                            </h1>
                        </div>
                        {actionsMenu}
                    </StickyHeader>
                    <Preloader show={this.state.showPreloader} fixed loadingDots>
                        <section className="content" id="contentContainer">
                            <div className="row">
                                <div className="col-lg-9">
                                    <Tabs location={this.props.location}>
                                        {summaryTab}
                                        {titlesTab}
                                        {userGroupsTab}
                                        {historyTab}
                                    </Tabs>
                                </div>
                                <div className="col-lg-3">
                                    <ErrorsWarnings
                                        title={this.context.intl.messages['common.errors-warnings-title']}
                                        validations={validations}
                                    />
                                    {eventsAndNotes}
                                </div>
                            </div>
                        </section>
                    </Preloader>
                    <DeleteInfoModal show={this.state.showDeleteInfoModal} onHide={this.toggleDeleteInfoModal} />
                </div>
            </DocumentTitle>
        );
    }
}

export default WithStoreOnRoute(Container.create(Create));
