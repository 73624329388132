/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link} from 'react-router';
import ReactTable from 'react-table';

import DocumentTitle from '../../common/document-title';
import SearchBox from '../../common/search-box/search-box';
import {WithStoreOnRoute} from '../../common/store-on-route';
import Preloader from '../../preloader';
import PreloaderStore from '../../preloader/preloader-store';
import {TitleActions} from '../title-actions';
import TitleStore from '../title-store';

class BrowseDeleted extends Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible'),
            deletedTitles: TitleStore.getState().get('deletedTitles')
        };
    }

    static getStores() {
        return [PreloaderStore, TitleStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.handleSearchTerm = this.handleSearchTerm.bind(this);
    }

    componentDidMount() {
        if (this.props.location.query.name) {
            TitleActions.getDeletedTitles(this.props.location.query.name);
        } else {
            TitleActions.getDeletedTitles();
        }
        return;
    }

    componentWillUpdate(nextProps) {
        if (this.props.location !== nextProps.location) {
            TitleActions.getDeletedTitles(nextProps.location.query.name);
        }
        return;
    }

    handleSearchTerm(term) {
        this.setRouteState('name', term).apply();
        return;
    }

    render() {
        let columns = [
            {
                accessor: 'TITLE',
                Header: () => <strong>{this.context.intl.messages['titles.delete-queue.title']}</strong>,
                Cell: c => <Link to={`/titles/deleted/${c.original.get('id')}`}>{c.original.get('name')}</Link>
            },
            {
                accessor: 'TITLE_TYPE',
                maxWidth: 180,
                Header: () => <strong>{this.context.intl.messages['titles.delete-queue.title-type']}</strong>,
                Cell: c => <div>{c.original.get('categoryName')}</div>
            },
            {
                accessor: 'DELETION_DATE',
                maxWidth: 140,
                Header: () => <strong>{this.context.intl.messages['titles.delete-queue.deletion-date']}</strong>,
                Cell: c => {
                    const titleDeletedDate = c.original.get('titleDeletionDate');
                    const deletionDateMoment = Moment(titleDeletedDate);
                    const deletionDate = deletionDateMoment.format(this.context.intl.messages['date-format']);
                    return <div>{deletionDate}</div>;
                }
            },
            {
                accessor: 'DAYS_LEFT',
                maxWidth: 150,
                Header: () => <strong>{this.context.intl.messages['titles.delete-queue.days-until-deletion']}</strong>,
                Cell: c => {
                    const titleDeletedDate = c.original.get('titleDeletionDate');
                    const deletionDateMoment = Moment(titleDeletedDate);
                    let daysLeftToDeletion = Math.round((deletionDateMoment.diff(Moment().utc().startOf('day')))/(1000*60*60*24));
                    if (daysLeftToDeletion < 0) {
                        daysLeftToDeletion = 0;
                    }
                    return <div>{daysLeftToDeletion}</div>;
                }
            }
        ];

        return (
            <DocumentTitle
                message="document-titles.titles-deleted-title"
            >
                <div>
                    <Preloader show={this.state.preloaderVisible} fixed loadingDots>
                        <section className="content-header">
                            <h1>
                                <i className="far fa-newspaper"></i>&nbsp;{this.context.intl.messages['titles.delete-queue']}
                                &nbsp;<small>{this.state.total}</small>
                            </h1>
                        </section>
                        <section className="content">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="container-fluid no-x-padding">
                                        <div className="row">
                                            <div className="col-md-6 show-grid">
                                                <SearchBox onChange={this.handleSearchTerm} value={this.getRouteState().get('name')}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="container-fluid no-x-padding">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="box">
                                                <div className="box-body">
                                                    <ReactTable
                                                        className="-striped table-bordered table-striped responsive"
                                                        columns={columns}
                                                        data={this.state.deletedTitles}
                                                        getNoDataProps= {() => {
                                                            if (this.state.deletedTitles.size) {
                                                                return {style: {display: 'none'}};
                                                            }
                                                            return {};
                                                        }}
                                                        showPagination={false}
                                                        id={'deleted-titles'}
                                                        loading={false}
                                                        resizable={false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Preloader>
                </div>
            </DocumentTitle>
        );
    }

}

export default WithStoreOnRoute(Container.create(BrowseDeleted));
