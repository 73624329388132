/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

import {NotificationActions} from '../common/notification/notification-actions';
import Dispatcher from '../dispatcher/dispatcher';
import Request from '../request';

const CONSTANTS = {
    CLEAR: 'talent_aka_actions.clear',
    TALENT_AKA: {
        GET: {
            START: 'talent_aka_actions.get.start',
            SUCCESS: 'talent_aka_actions.get.success'
        },
    }
};

class TalentAKAActions {
    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
    }

    getAKAs(talentId) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TALENT_AKA.GET.START
        });

        Request.get(`talent/${talentId}/aka`).exec().then(akaRes => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.TALENT_AKA.GET.SUCCESS,
                akas: Immutable.fromJS(akaRes.body),
                talentId: talentId
            });
        }).catch(err => {
            NotificationActions.showAlertDanger('talent.aka.get.error');
            throw err;
        });
    }

}

let actions = new TalentAKAActions();

export {
    actions as TalentAKAActions,
    CONSTANTS as TalentAKAConstants
};
