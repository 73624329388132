/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';

import {AlertTypes} from '../common/notification/alert';
import {NotificationActions} from '../common/notification/notification-actions';
import {GetEncodedHTML} from '../common/utils/utils';
import Dispatcher from '../dispatcher/dispatcher';
import {PreloaderActions} from '../preloader/preloader-actions';
import Request from '../request';

const CONSTANTS = {
    ADD_NEW: 'title.localized.add.new',
    ADD_NEW_HIDE: 'title.localized.add.new.hide',
    ADD_NEW_SHOW: 'title.localized.add.new.show',
    CLEAR:'title.localized.clear',
    GET: {
        SUCCESS: 'title.localized.get.success',
        START:'title.localized.get.start',
        ERROR:'title.localized.get.error'
    },
    REMOVE_CURRENT_LANGUAGE: 'title.localized.remove.current.language',
    SAVE_OK: 'title.localized.save.ok',
    SET_CURRENT_LANGUAGE: 'title.localized.set.current.language',
    SET_NEW_LANGUAGE: 'title.localized.set.new.language',
    UPDATE: 'title.localized.update',
    UPDATE_AKA: 'title.localized.update_aka',
    UPDATE_CHARACTER_NAME: 'title.localized.update.character.name'
};

class TitleLocalizedActions {

    addNew(languageCode, synopsisValues) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ADD_NEW,
            languageCode: languageCode,
            synopsisValues: synopsisValues
        });
    }

    addNewShow() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ADD_NEW_SHOW,
        });
    }

    addNewHide() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ADD_NEW_HIDE,
        });
    }

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
    }

    findById(titleId) {
        PreloaderActions.show('title-localized-actions.get');
        Promise.all([
            Request.get(`title/${titleId}/localized`).exec(),
            Request.get(`title/${titleId}/talent/localized`).exec().catch(err => {
                if (err.status !== 404) {
                    NotificationActions.showAlertDanger('talent.localized.error.load');
                    throw err;
                }
                return {body: []};
            }),
            Request.get(`title/${titleId}/synopsis/localized`).exec(),
        ]).spread((titleRes, talentRes, synopsisRes) => {
            PreloaderActions.hide('title-localized-actions.get');

            let titleLocalized = titleRes.body.reduce( (o, l) => {
                o[l.languageCode] = l;
                return o;
            }, {});

            let talentLocalized = talentRes.body.reduce( (o, l) => {
                // Create a map that sorts by language
                if (!o[l.languageCode]) {
                    o[l.languageCode] = {};
                }

                // Create another map that sorts by roleInTitle
                if (!o[l.languageCode][l.roleInTitle.toString()]) {
                    o[l.languageCode][l.roleInTitle.toString()] = [];
                }

                o[l.languageCode][l.roleInTitle.toString()].push(l);
                return o;
            }, {});

            Object.keys(titleLocalized).forEach(language => {
                titleLocalized[language].talent = talentLocalized[language] || {};
                titleLocalized[language].synopsisValues = synopsisRes.body.filter(s => s.languageCode === language);
            });

            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.SUCCESS,
                titleLocalized: Immutable.fromJS(titleLocalized)
            });

            return;
        }).catch(err => {
            PreloaderActions.hide('title-localized-actions.get');
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
            throw err;
        });
    }

    removeCurrentLanguage() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.REMOVE_CURRENT_LANGUAGE
        });
    }

    update(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.UPDATE,
            attr,
            value
        });
    }

    save(titleId, titleLocalized, talents) {
        let talentLocalized = [];
        let synopsisRequests = [];
        let localized = titleLocalized.reduce((arr, l) => {
            talentLocalized.push(...l.get('talent', Immutable.Map()).toList().flatten(1).toJS());

            l = l.delete('talent').toJS();
            // We do this in order to encode the HTML before saving
            [
                'masteringComments',
                'programLegalline',
                'promotionalLegalline',
                'shortSynopsis',
                'synopsis',
            ].forEach(p => {
                l[p] = GetEncodedHTML(l[p]);
            });

            let synopsisValues = l.synopsisValues;
            synopsisValues.forEach(s => {
                if (!s.titleSynopsisId) {
                    synopsisRequests.push(
                        Request.post(`title/${titleId}/synopsis/localized`)
                            .send(s)
                            .exec()
                    );
                } else {
                    synopsisRequests.push(
                        Request.put(`title/${titleId}/synopsis/localized/${s.titleSynopsisId}`)
                            .send(s)
                            .exec()
                    );
                }
            });

            arr.push(l);
            return arr;
        }, []);
        talentLocalized = talentLocalized.map((t, i) => {
            let akaId = t.akaId;
            let result = {
                akaId: t.akaId,
                characterNameInTitle: t.characterNameInTitle,
                languageCode: t.languageCode,
                orderInTitle: 1000 + i,
                roleInTitle: t.roleInTitle,
                talentId: t.talentId,
                titleId: t.titleId
            };

            if (!akaId) {
                talents.forEach(talentsType => {
                    talentsType.forEach(talentsAkaId => {
                        if (talentsAkaId.get('talentId') === t.talentId) {
                            akaId = talentsAkaId.get('akaId');
                        }
                    });
                });

                result.akaId = akaId,
                result.orderInTitle = 1000 + i;
            }

            return result;
        });

        this.setNewLanguage(undefined);

        return Promise.all([
            Request.put(`title/${titleId}/localized`).send(localized).exec(),
            Request.put(`title/${titleId}/talent/localized`).send(talentLocalized).exec(),
            ...synopsisRequests
        ]);

    }

    setCurrentLanguage(languageCode) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SET_CURRENT_LANGUAGE,
            languageCode
        });
    }

    setNewLanguage(languageCode) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SET_NEW_LANGUAGE,
            languageCode
        });
    }

    updateAKA(talentRoleType, aka, index) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.UPDATE_AKA,
            aka,
            index,
            talentRoleType
        });
    }

    updateCharacterName(item, value, talent) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.UPDATE_CHARACTER_NAME,
            item,
            value,
            talent
        });
    }

}

let actions = new TitleLocalizedActions();

export {
    actions as TitleLocalizedActions,
    CONSTANTS as TitleLocalizedConstants
};
