/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {ProcessesConstants} from './processes-actions';
import Dispatcher from '../../dispatcher/dispatcher';


class ProcessesStore extends ReduceStore {

    getInitialState() {
        return Immutable.Map({
            errorMessage: Immutable.Map(),
            filters: Immutable.Map(),
            messages: Immutable.List(),
            message: Immutable.Map(),
            offset: 0,
            showPreloader: false,
            size: 0,
            total: 0,
            originalMessage: Immutable.Map(),
            process: Immutable.Map(),
            processes: Immutable.List(),
            progress: Immutable.Map({
                tasksCompleted: Immutable.Map(),
                tasksFailed: Immutable.Map(),
                tasksRunning: Immutable.Map(),
                progress: 0,
            }),
            reviewProcessesProgress: Immutable.Map(),
        });
    }

    reduce(state, action) {
        switch (action.actionType) {
        case ProcessesConstants.CLEAR:
            state = this.getInitialState();
            break;

        case ProcessesConstants.MESSAGES.CLEAR:
            state = state.merge({
                messages: Immutable.List(),
                offset: 0,
                showPreloader: false,
                size: 0,
                total: 0,
            });
            break;

        case ProcessesConstants.MESSAGES.EVENT_MESSAGE.SAVE.ERROR:
        case ProcessesConstants.MESSAGES.EVENT_MESSAGE.RE_PROCESS.ERROR:
        case ProcessesConstants.MESSAGES.EVENT_MESSAGE.RE_PROCESS.SUCCESS:
        case ProcessesConstants.MESSAGES.GET.ERROR:
        case ProcessesConstants.PROCESSES.FIND_BY_ID.ERROR:
        case ProcessesConstants.PROCESSES.GET.ERROR:
        case ProcessesConstants.PROCESSES.REPROCESS.ERROR:
        case ProcessesConstants.PROCESSES.REPROCESS.SUCCESS:
            state = state.set('showPreloader', false);
            break;

        case ProcessesConstants.PROCESSES.GET.SUCCESS:
            state = state.merge({
                offset: action.offset,
                processes: action.processes,
                showPreloader: false,
                size: action.size,
                total: action.total
            });
            break;

        case ProcessesConstants.MESSAGES.GET.SUCCESS:
            state = state.merge({
                offset: action.offset,
                messages: action.messages,
                showPreloader: false,
                size: action.size,
                total: action.total
            });
            break;

        case ProcessesConstants.PROCESSES.FIND_BY_ID.SUCCESS:
            state = state.merge({
                errorMessage: action.errorMessage,
                process: action.process,
                showPreloader: false,
            });
            break;

        case ProcessesConstants.MESSAGES.EVENT_MESSAGE.SAVE.START:
        case ProcessesConstants.MESSAGES.EVENT_MESSAGE.RE_PROCESS.START:
        case ProcessesConstants.MESSAGES.EVENT_MESSAGE.GET.START:
        case ProcessesConstants.MESSAGES.GET.START:
        case ProcessesConstants.PROCESSES.FIND_BY_ID.START:
        case ProcessesConstants.PROCESSES.GET.START:
        case ProcessesConstants.PROCESSES.REPROCESS.START:
            state = state.set('showPreloader', true);
            break;

        case ProcessesConstants.FILTER.SET:
            state = state.setIn(['filters', ...action.attr.split('.')], action.value);
            break;

        case ProcessesConstants.MESSAGES.EVENT_MESSAGE.SAVE.SUCCESS:
        case ProcessesConstants.MESSAGES.EVENT_MESSAGE.GET.SUCCESS:
            state = state.merge({
                message: action.message,
                originalMessage: action.message,
                showPreloader: false
            });
            break;

        case ProcessesConstants.UPDATE:
            state = state.setIn([...action.attr.split('.')], action.value);
            break;

        case ProcessesConstants.PROCESSES.PROGRESS.SET:
            state = state.merge({
                progress: action.progress
            });
            break;
        }

        return state;
    }
}

let store = new ProcessesStore(Dispatcher);

export default store;
