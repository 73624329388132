/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, Col, Row, ControlLabel, FormGroup} from 'react-bootstrap';
import ReactSelect from 'react-select';

import {MassUpdateActions, MassUpdateConstants} from './mass-update-actions';
import MassUpdateStore from './mass-update-store';
import {FormRow, FormSection} from '../../common/form/form';
import {WithValidations} from '../../common/validations/validations';
import ActionsMenu from '../../layout/actions-menu/actions-menu';
import StickyHeader from '../../layout/sticky-header/sticky-header';
import {RouterActions} from '../../router/router-actions';
import {ClientRepGroupActions} from '../../security/client-rep-group/client-rep-group-actions';
import ClientRepGroupStore from '../../security/client-rep-group/client-rep-group-store';
import {UserConstants} from '../../user/user-actions';

class UpdateUsersStatus extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            params: PropTypes.object.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            // brainiacGroups, defaultPartner, partners, roles and stations are here to maintain compatibility
            // and have a single MassUpdateActions.save call. It may be worth having a decorator
            // for the action.
            brainiacGroups: MassUpdateStore.getState().get('brainiacGroups'),
            defaultPartner: MassUpdateStore.getState().get('defaultPartner'),
            mode: MassUpdateStore.getState().get('mode'),
            partners: MassUpdateStore.getState().get('partners'),
            roles: MassUpdateStore.getState().get('roles'),
            selectedClientRepGroupId: MassUpdateStore.getState().get('selectedClientRepGroupId'),
            stations: MassUpdateStore.getState().get('stations'),
            userAccountStatus: MassUpdateStore.getState().get('selectedUsersStatusId'),
            users: MassUpdateStore.getState().get('users'),
            wbdGroups: MassUpdateStore.getState().get('wbdGroups')
        };
    }

    static getStores() {
        return [ClientRepGroupStore, MassUpdateStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();
        this.handleBack = this.handleBack.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleUserAccountStatus = this.handleUserAccountStatus.bind(this);
    }

    componentDidMount() {
        MassUpdateActions.getConfirmationData(this.props.params.id);
        ClientRepGroupActions.get();
        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.userAccountStatus !== nextState.userAccountStatus) {
            return true;
        }
        return false;
    }

    handleBack(mode, massModeStep) {
        if (massModeStep) {
            RouterActions.redirect(`/accounts/mass-${mode}/${this.props.params.id}?mode=${massModeStep}`);
        } else {
            RouterActions.redirect(`/accounts/mass-${mode}/${this.props.params.id}`);
        }
    }

    handleSave(action) {
        MassUpdateActions.save(
            action,
            this.props.params.id,
            this.state.wbdGroups,
            this.state.brainiacGroups,
            this.state.stations,
            this.state.roles,
            this.state.defaultPartner,
            this.state.partners,
            this.state.selectedClientRepGroupIds,
            false,
            this.state.userAccountStatus
        );
    }

    handleUserAccountStatus(value) {
        let statusValue = null;
        if (value) {
            statusValue = value.id;
        }
        MassUpdateActions.setUsersStatus(statusValue);
        return;
    }

    render() {
        let totalUsers = '-';
        if (this.state.users) {
            totalUsers = this.state.users.size;
        }
        let optionsUsersStatus = [{
            id: UserConstants.STATUS.REMOVED.id,
            name: UserConstants.STATUS.REMOVED.description
        },
        {
            id: UserConstants.STATUS.PENDING.id,
            name: UserConstants.STATUS.PENDING.description
        }];

        //to use in steps texts
        let massModeStep = 'update';
        const queryMode = this.props.location.query.mode;
        if (queryMode) {
            massModeStep = queryMode;
        }

        return (
            <div>
                <StickyHeader>
                    <div className="col-md-6">
                        <h1><i className="fas fa-user"></i>&nbsp;{this.context.intl.messages['accounts.mass-update.title']}
                            &nbsp;<small>{totalUsers} {this.context.intl.messages['accounts.mass-update.accounts-length']}</small>
                        </h1>
                    </div>
                    <ActionsMenu onCancel={this.handleCancel}>
                        <Button
                            bsSize="large"
                            className="btn btn-primary-outline Mr(3px) Mb(3px)"
                            onClick={this.handleSave.bind(this, MassUpdateConstants.MASS_UPDATE.OPERATION_TYPES.UPDATE)}
                        >
                            <i className="fas fa-pencil-alt"/>&nbsp;{this.context.intl.messages['accounts.mass-update.button.update-and-overwrite']}
                        </Button>
                        <Button
                            bsSize="large"
                            className="btn btn-default-outline Mr(3px) Mb(3px)"
                            onClick={this.handleBack.bind(this, 'update', massModeStep)}
                        >
                            <i className="fas fa-chevron-left"/>&nbsp;{this.context.intl.messages['accounts.mass-common.button.back']}
                        </Button>
                    </ActionsMenu>
                </StickyHeader>
                <section className="content" id="contentContainer">
                    <Row>
                        <Col xs={12}>
                            <div className="box">
                                <div className="box-body padding-x-20">
                                    <h3>{this.context.intl.messages[`accounts.mass-${massModeStep}.step.update-permissions.title`]}</h3>
                                    <div className="padding-y-20">
                                        <FormSection title={this.context.intl.messages['accounts.mass-update.users-status.title']} iconClass="fas fa-users">
                                            <FormRow>
                                                <FormGroup>
                                                    <ControlLabel>{this.context.intl.messages[`accounts.mass-update.users-status.${this.state.mode}.text`]}</ControlLabel>
                                                    <ReactSelect
                                                        getOptionLabel={data => data.name}
                                                        getOptionValue={data => data.id}
                                                        isClearable={true}
                                                        isMulti={false}
                                                        disabled={false}
                                                        onChange={this.handleUserAccountStatus}
                                                        options={optionsUsersStatus}
                                                        selected={this.state.userAccountStatus}
                                                    />
                                                </FormGroup>
                                            </FormRow>
                                        </FormSection>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </section>
            </div>
        );
    }
}

export default WithValidations(Container.create(UpdateUsersStatus));
