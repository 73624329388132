/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React, {Component} from 'react';

import AddExistingAssets from '../add-existing-assets';
import {AssetTabActions, AssetTabConstants} from '../asset-tab-actions';
import AssetTabStore from '../asset-tab-store';

import {MainAssetTypesMap} from '~/src/assets/asset-actions';
import SlidingPanel from '~/src/common/sliding-panel/sliding-panel';
import {SlidingPanelActions} from '~/src/common/sliding-panel/sliding-panel-actions';

class AddExistingPanel extends Component {
    static get propTypes() {
        return {
            assetType: PropTypes.string,
            caller: PropTypes.string,
            title: PropTypes.node
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            assetType:'',
            caller: '',
            title: undefined
        };
    }

    constructor(props) {
        super(props);

        this.handleClickAddSelectedAssets = this.handleClickAddSelectedAssets.bind(this);

        return;
    }

    handleCancelPanel() {
        AssetTabActions.clearSelectedAssetsToAdd();
    }

    handleClickAddSelectedAssets() {
        SlidingPanelActions.hide('addExisting');
        AssetTabActions.add(
            MainAssetTypesMap[this.props.assetType],
            AssetTabStore.getState().get('selectedAssetsToAdd'),
            AssetTabConstants.ADD
        );
        return;
    }

    render() {
        const addToTitle = (
            <button
                className="btn btn-large bg-green"
                onClick={this.handleClickAddSelectedAssets}
            >
                <i className="fas fa-plus"></i>&nbsp;{this.context.intl.messages['titles.create.assets.add-existing.confirm']}
            </button>
        );

        return (
            <SlidingPanel
                extraButtons={addToTitle}
                icon="fas fa-plus-circle"
                id="addExisting"
                onCancel={this.handleCancelPanel}
                title={this.props.title}
            >
                <AddExistingAssets assetType={this.props.assetType} caller={this.props.caller}/>
            </SlidingPanel>
        );
    }
}

export default AddExistingPanel;
