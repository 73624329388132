/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ControlLabel, FormGroup} from 'react-bootstrap';
import Select from 'react-select';

import {VideoActions, VideoConstants} from '../../video-actions';
import VideoStore, {VideoValidations} from '../../video-store';

import {FormItem, FormRow, FormSection} from '~/src/common/form/form';
import {WithValidations} from '~/src/common/validations/validations';

class PlaybackRestrictions extends Component {

    static get propTypes() {
        return {
            asset: PropTypes.object.isRequired,
            assetId: PropTypes.number.isRequired,
            disabled: PropTypes.bool,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        const vs = VideoStore.getState();
        return {
            allPlatforms: vs.get('allPlatforms'),
            asset: vs.get('asset'),
            platformsLoaded: vs.get('platformsLoaded'),
            selectedPlatforms: vs.get('selectedPlatforms'),
        };
    }

    static getStores() {
        return [VideoStore];
    }

    static get defaultProps() {
        return {
            disabled: true
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            displayCustomForEditing: false
        }, this.constructor.calculateState());

        this.handleAllowOnAll = this.handleAllowOnAll.bind(this);
        this.handleCustom = this.handleCustom.bind(this);
        this.handleDisableOnWeb = this.handleDisableOnWeb.bind(this);
        this.handleOnChangeSelectPlatforms = this.handleOnChangeSelectPlatforms.bind(this);
        this.handleOnlyAllowOnTv = this.handleOnlyAllowOnTv.bind(this);
        this.handleRestrictByIPAddress = this.handleRestrictByIPAddress.bind(this);
    }

    componentDidMount() {
        if (!this.state.platformsLoaded) {
            VideoActions.getAllPlatforms();
            VideoActions.getSelectedPlatforms(this.props.assetId);
            return;
        }
        return;
    }

    isSubset(a, b) {
        if (a.size !== b.size) {
            return false;
        }

        return a.isSubset(b);
    }

    handleAllowOnAll() {
        VideoActions.allowOnAll();
        this.setState(() => ({
            displayCustomForEditing: false
        }));
    }

    handleCustom() {
        VideoActions.allowOnAll();
        this.setState(() => ({
            displayCustomForEditing: true
        }));
    }

    handleDisableOnWeb() {
        VideoActions.disableOnWeb();
        this.setState(() => ({
            displayCustomForEditing: false
        }));
    }

    handleOnChangeSelectPlatforms(list) {
        VideoActions.editPlatformToList(list);
        this.setState(() => ({
            displayCustomForEditing: false
        }));
    }

    handleOnlyAllowOnTv() {
        VideoActions.onlyAllowOnTv();
        this.setState(() => ({
            displayCustomForEditing: false
        }));
    }

    handleRestrictByIPAddress() {
        VideoActions.update('restrictByIp', !this.state.asset.get('restrictByIp'));
    }

    getSelectedOption() {
        const result = {
            allowOnAllOption: false,
            disableOnWebOption: false,
            onlyAllowOnTvOption: false,
            customOption: false
        };

        if (this.state.displayCustomForEditing) {
            result.customOption = true;
            return result;
        }

        if (!this.state.selectedPlatforms.size) {
            result.allowOnAllOption = true;
            return result;
        }

        const allButWeb = this.state.allPlatforms.filter(platform => platform.get('platformType') !== VideoConstants.VIDEO.PLATFORM_TYPE.WEB);
        if (this.isSubset(allButWeb, this.state.selectedPlatforms)) {
            result.disableOnWebOption = true;
            return result;
        }

        const onlyTv = this.state.allPlatforms.filter(platform => platform.get('platformType') === VideoConstants.VIDEO.PLATFORM_TYPE.TV);
        if (this.isSubset(onlyTv, this.state.selectedPlatforms)) {
            result.onlyAllowOnTvOption = true;
            return result;
        }

        result.customOption = true;
        return result;
    }

    render() {
        const selectedOption = this.getSelectedOption();

        return (
            <div>
                <h3><i className="fas fa-video-slash"></i>{this.context.intl.messages['asset.video.playback-restrictions']}</h3>
                <hr />
                <h4>{this.context.intl.messages['asset.video.playback-restrictions.platform']}</h4>
                <FormSection>
                    <FormGroup>
                        <div>
                            <label className="form-check-label">
                                <input
                                    checked={this.state.asset.get('restrictByIp')}
                                    id="restrictByIp"
                                    name="restrictByIp"
                                    onClick={this.handleRestrictByIPAddress}
                                    type="checkbox"
                                /> {this.context.intl.messages['asset.video.playback-restrictions.restrict-by-ip-address']}
                            </label>
                            <div>
                                <small><em>{this.context.intl.messages['asset.video.playback-restrictions.restrict-by-ip-address.description']}</em></small>
                            </div>
                        </div>
                    </FormGroup>
                </FormSection>
                <hr />
                <FormSection>
                    <div>
                        <div className="radio">
                            <label className="radio-inline">
                                <input
                                    checked={selectedOption.allowOnAllOption}
                                    id="platform"
                                    name="platform"
                                    onClick={this.handleAllowOnAll}
                                    type="radio"
                                />
                                <strong>{this.context.intl.messages['asset.video.playback-restrictions.platform.allow-on-all']}</strong>
                            </label>
                            <p><small><em>{this.context.intl.messages['asset.video.playback-restrictions.platform.allow-on-all.description']}</em></small></p>
                        </div>
                        <hr />
                        <div className="radio">
                            <label className="radio-inline">
                                <input
                                    checked={selectedOption.disableOnWebOption}
                                    id="platform"
                                    name="platform"
                                    onClick={this.handleDisableOnWeb}
                                    type="radio"
                                />
                                <strong>{this.context.intl.messages['asset.video.playback-restrictions.platform.disable-on-web']}</strong>
                            </label>
                            <p><small><em>{this.context.intl.messages['asset.video.playback-restrictions.platform.disable-on-web.description']}</em></small></p>
                        </div>
                        <hr />
                        <div className="radio">
                            <label className="radio-inline">
                                <input
                                    checked={selectedOption.onlyAllowOnTvOption}
                                    id="platform"
                                    name="platform"
                                    onClick={this.handleOnlyAllowOnTv}
                                    type="radio"
                                />
                                <strong>{this.context.intl.messages['asset.video.playback-restrictions.platform.only-allow-on-tv-platforms']}</strong>
                            </label>
                            <p><small><em>{this.context.intl.messages['asset.video.playback-restrictions.platform.only-allow-on-tv-platforms.description']}</em></small></p>
                        </div>
                        <hr />
                        <div className="radio">
                            <label className="radio-inline">
                                <input
                                    checked={selectedOption.customOption}
                                    id="platform"
                                    name="platform"
                                    onChange={this.handleCustom}
                                    type="radio"
                                />
                                <strong>{this.context.intl.messages['asset.video.playback-restrictions.platform.custom-advanced']}</strong>
                            </label>
                            <p><small><em>{this.context.intl.messages['asset.video.playback-restrictions.platform.custom-advanced.description']}</em></small></p>
                        </div>
                        {(selectedOption.customOption || this.state.displayCustomForEditing) && <div style={{marginBottom: '20px'}}>
                            <ControlLabel><span>{this.context.intl.messages['asset.video.playback-restrictions.platform.all-platforms']}</span></ControlLabel>
                            <Select
                                getOptionLabel={data => data.name}
                                getOptionValue={data => data.id}
                                isClearable={true}
                                isMulti={true}
                                isDisabled={false}
                                name="partners"
                                onChange={this.handleOnChangeSelectPlatforms}
                                options={this.state.allPlatforms.toJS()}
                                placeholder={this.context.intl.messages['asset.video.playback-restrictions.platform.select-platform']}
                                value={this.state.selectedPlatforms.toJS()}
                            />
                        </div>}
                    </div>
                </FormSection>
                <hr />
                <h4>{this.context.intl.messages['asset.video.playback-restrictions.playback-number']}</h4>
                <FormSection>
                    <FormRow>
                        <FormGroup>
                            <FormItem
                                attr="maxNumberOfView"
                                disabled={this.props.disabled}
                                label={this.context.intl.messages['asset.video.playback-restrictions.playback-number.max-views-allowed']}
                                model={this.props.asset}
                                showErrorMessage={true}
                                setter={VideoActions.update}
                                type="number"
                                validations={VideoValidations.maxNumberOfView.validations}
                            />
                            <p><small><em>{this.context.intl.messages['asset.video.playback-restrictions.playback-number.max-views-allowed.description']}</em></small></p>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['asset.video.playback-restrictions.playback-number.important-note']}</ControlLabel>
                            <p><small><em>{this.context.intl.messages['asset.video.playback-restrictions.playback-number.important-note.description']}</em></small></p>
                        </FormGroup>
                    </FormRow>
                </FormSection>
            </div>
        );
    }

}

export {PlaybackRestrictions};
export default WithValidations(Container.create(PlaybackRestrictions));
