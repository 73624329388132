/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, ControlLabel, FormControl, FormGroup} from 'react-bootstrap';
import {Link} from 'react-router';

import {sectionHeader} from './section-header';
import {FormRow, FormSection} from '../../../common/form/form';
import {WithValidations} from '../../../common/validations/validations';
import {VideoDeliveryActions} from '../video-delivery-actions';

import {AudioProfileActions} from '~/src/hardac/audio-profile-table/audio-profile-actions';

class PullFromOAP extends Component {
    static get propTypes() {
        return {
            isDisabled: PropTypes.bool,
            videoDelivery: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get defaultProps() {
        return {
            isDisabled: false
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };

    }

    constructor(props) {
        super(props);

        this.state = {
            showOapAudioConfig: false,
        };

        this.handleBarcode = this.handleBarcode.bind(this);
        this.handlePullOAPAudioProfile = this.handlePullOAPAudioProfile.bind(this);
    }

    componentDidMount() {
        AudioProfileActions.getAudioLookups();
    }

    handleBarcode(e) {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
            VideoDeliveryActions.updateVideoDelivery('barcodeNum', value);
        }
    }

    handlePullOAPAudioProfile() {
        VideoDeliveryActions.getOapByBarcode(this.props.videoDelivery.get('barcodeNum'));
        this.setState(() => ({showOapAudioConfig: true}));
    }

    render() {
        let oapAudioConfig;
        if (this.state.showOapAudioConfig) {
            oapAudioConfig = <div>
                {sectionHeader('fas fa-volume',
                    this.context.intl.messages['hardac.video-inbox.summary.pull-from-oap.oap-audio-config'],
                    null)}
                <FormSection>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.video-inbox.summary.pull-from-oap.oap-audio-config.audio-configuration']}</ControlLabel>
                            <p>
                                <em>
                                    {this.context.intl.messages['hardac.video-inbox.summary.pull-from-oap.oap-audio-config.description']}
                                    <Link to={`/hardac/video-inbox/${this.props.videoDelivery.get('id')}/audio`}>{this.context.intl.messages['hardac.video-inbox.summary.send-to-oap.workflow.audio-configuration.audio-tab']}</Link>
                                    {this.context.intl.messages['hardac.video-inbox.summary.pull-from-oap.oap-audio-config.before-approving']}
                                </em>
                            </p>
                        </FormGroup>
                    </FormRow>
                </FormSection>
            </div>;
        }
        return (
            <div>
                {sectionHeader('fas fa-newspaper',
                    this.context.intl.messages['hardac.video-inbox.summary.pull-from-oap.get-oap-info'],
                    this.context.intl.messages['hardac.video-inbox.summary.pull-from-oap.get-oap-info.description'])}
                <FormSection>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.video-inbox.summary.pull-from-oap.barcode']} <span className="text-red">*</span></ControlLabel>
                            <FormControl disabled={this.props.isDisabled} type="text" minLength={6} maxLength={7} value={this.props.videoDelivery.get('barcodeNum', '')} onChange={this.handleBarcode}/>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.video-inbox.summary.pull-from-oap.pull-audio-profile-from-oap']}</ControlLabel>
                            <Button
                                className="btn btn-primary-outline btn-block"
                                disabled={((this.props.videoDelivery.get('barcodeNum') || '').length < 6) || this.props.isDisabled}
                                onClick={this.handlePullOAPAudioProfile}
                            >
                                <i className="fas fa-sync"></i> {this.context.intl.messages['hardac.video-inbox.summary.pull-from-oap.pull-oap-audio-profile']}
                            </Button>
                        </FormGroup>
                    </FormRow>
                </FormSection>
                {oapAudioConfig}
            </div>
        );
    }
}

export default WithValidations(PullFromOAP);
