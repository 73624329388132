/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {findCmsOrderById, isStation} from '@wbdt-sie/brainiac-web-common';
import Promise from 'bluebird';
import Immutable from 'immutable';

import AssetTabStore from './asset-tab-store';
import Categories from './categories';
import {AssetConstants, MainAssetTypesMap} from '../assets/asset-actions';
import {STATION_TYPE} from '../assets/document/document-actions';
import {AlertTypes} from '../common/notification/alert';
import {NotificationActions} from '../common/notification/notification-actions';
import Dispatcher from '../dispatcher/dispatcher';
import Request from '../request';

let sortedCategories = Object.keys(Categories.categoryOrder).sort(/*istanbul ignore next*/ (a, b) => {
    return Categories.categoryOrder[a] - Categories.categoryOrder[b];
});
sortedCategories.push(...['Audio', 'Document', 'Station', 'Merchandise', 'Script', 'Timeline']);

const CONSTANTS = {
    ADD: 'asset_tab_actions.add',
    CATALOG: {
        GET: {
            SUCCESS: 'asset_tab_actions.catalog.get.success'
        }
    },
    CLEAR: 'asset_tab_actions.clear',
    CLEAR_SELECTED_ASSETS_TO_ADD: 'asset_tab_actions.clear_selected_assets_to_add',
    GET: {
        ERROR: 'asset_tab_actions.get.error',
        START: 'asset_tab_actions.get.start',
        SUCCESS: 'asset_tab_actions.get.success',
    },
    HIDE_DETAIL: 'asset_tab_actions.hide.detail',
    LINK: 'asset_tab_actions.link',
    REMOVE: 'asset_tab_actions.remove',
    REORDER: 'asset_tab_actions.reorder',
    REPLACE_VIDEO: {
        ERROR: 'asset_tab_actions.replace_video.error',
        START: 'asset_tab_actions.replace_video.start',
        SUCCESS: 'asset_tab_actions.replace_video.success',
    },
    REPLACE_VIDEO_CLEAR: 'asset_tab_actions.replace_video.clear',
    SAVE: {
        SUCCESS: 'asset_tab_actions.save.success'
    },
    SELECT_ASSET_TO_ADD: 'asset_tab_actions.selecte_asset_to_add',
    SELECT_COPY_FROM_ASSET: 'asset_tab_actions.select_copy_from_asset',
    SELECT_COPY_TO_ASSET: 'asset_tab_actions.select_copy_to_asset',
    SET_ASSET_TYPE: 'asset_tab_actions.set.asset.type',
    SET_ASSET_URL: 'asset_tab_actions.set.asset.url',
    SET_DEFAULT: 'asset_tab_actions.set.default',
    SET_DEFAULT_CLEAR: 'asset_tab_actions.set.default-clear',
    SET_THUMBNAILS: 'asset_tab_actions.set.thumbnails',
    SET_TITLEID: 'asset_tab_actions.set.title-id',
    SHOW_ASSET_TYPE: 'asset_tab_actions.show.asset.type',
    SHOW_DETAIL: 'asset_tab_actions.show.detail',
    THUMBNAILS_BATCH_SIZE: 250,
    TOGGLE_ACTIVE: 'asset_tab_actions.toggle_active',
    UNLINK: 'asset_tab_actions.unlink'
};

class AssetTabActions {
    add(assetType, assets, addConstant) {
        if (assetType === 'video') {
            Request.get('asset/video/thumbnailURL').query({'video-id': assets.map(a => a.get('id')).toJS()}).exec().then(response => {
                const items = assets.toJS().map(asset => {
                    let assetToAdd = asset;
                    // Add assetId so that things like the video player work out of the box.
                    assetToAdd.assetId = assetToAdd.id;

                    const videoThumbnails = response.body.filter(vt => vt.videoId === assetToAdd.id)[0];
                    if (videoThumbnails && videoThumbnails.thumbnailList.length) {
                        assetToAdd.previewUrl = videoThumbnails.thumbnailList[0].thumbnailUrl;
                        assetToAdd.thumbnailUrl = videoThumbnails.thumbnailList[0].thumbnailUrl;
                    }

                    return assetToAdd;
                });
                Dispatcher.dispatch({
                    actionType: addConstant,
                    items: Immutable.fromJS(items),
                    assetType: assetType
                });
            }).catch((err) => {
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
                throw err;
            });
            return;
        }

        let items = assets.toJS();
        if (items.length) {
            // Set contentType to allow labels to show in table before title is saved
            if (assetType === 'document') {
                items.forEach(item => {
                    // STUDIO-12360 if the asset doesn't have documentType, we end up with an undefined contentType which breaks things
                    if (item.documentType) {
                        item.contentType = item.documentType;
                    }
                    if (isStation(item)) {
                        assetType = STATION_TYPE;
                        // Store original contentType since it's needed for getCategory
                        item.contentTypeId = item.contentType;
                        item.contentType = AssetTabStore.quickAndDirtyContentTypeToText(assetType, parseInt(item.contentType));
                    }
                });
            }
            Dispatcher.dispatch({
                actionType: addConstant,
                items: Immutable.fromJS(items),
                assetType: assetType
            });
        }
    }

    // Iterate an array of assets and return a server processable entity.
    // assets: list of assets.
    // id: title/talent id.
    // caller: one of title or talent
    // index: number at which to start setting the assetOrder value. Passed
    // as a parameter due to the recursive nature of this function.
    asArray(assets, id, caller, index) {
        let assetsArray = [];
        assets.forEach(asset => {
            let item = {
                assetId: asset.get('assetId'),
                assetOrder: index,
                defaultHorizontal: asset.get('defaultHorizontal', 0),
                defaultPortrait: asset.get('defaultPortrait', 0),
                [caller + 'Id']: id
            };

            if (caller === 'title') {
                item.defaultBanner = asset.get('defaultBanner', 0);
                item.defaultTitleTreatment = asset.get('defaultTitleTreatment', 0);

                if (asset.get('assetType') === AssetConstants.ASSET_MAIN_TYPES.VIDEO.toUpperCase()) {
                    item.defaultVideoBanner = asset.get('defaultVideoBanner', 0);
                }
            }

            if (asset.get('parentStackAssetId') !== null) {
                item.parentStackAssetId = asset.get('parentStackAssetId');
            }

            assetsArray.push(item);
            index = index + 1;
            if (asset.get('children') && asset.get('children').size > 0) {
                assetsArray.push(...this.asArray(asset.get('children'), id, caller, index));
            }
        });
        return assetsArray;
    }

    isParent(asset) {
        return !asset.parentStackAssetId;
    }

    isChild(asset) {
        return !!asset.parentStackAssetId;
    }

    buildTree(assets) {
        // Get the empty tree from the Store's initial state, so
        // that there's no need to keep two equal structures in sync.
        let tree = AssetTabStore.getInitialState().get('assets').toJS();

        // separate documents and stations
        assets = assets.map(asset => {
            if (asset.assetType === AssetConstants.ASSET_MAIN_TYPES.DOCUMENT.toUpperCase()) {
                if (isStation(asset)) {
                    asset.assetType = STATION_TYPE.toUpperCase();
                    //If its a station doc, we order according to requirements.
                    asset.assetCMSOrder = findCmsOrderById(asset.contentType);
                }
            }
            return asset;
        });

        // sort assets
        // sort by:
        // 1.- is parent / is child
        // 2.- category
        // 3.- assetOrder
        // 4.- name
        assets = assets.sort((a, b) => {
            let ordA = Categories.getCategoryOrder(a.assetType.toLowerCase(), a.contentType);
            let ordB = Categories.getCategoryOrder(b.assetType.toLowerCase(), b.contentType);
            if (this.isParent(a) && this.isChild(b)) {
                return -1;
            } else if (this.isParent(b) && this.isChild(a)) {
                return 1;
            } else if (ordA < ordB) {
                return -1;
            } else if (ordA > ordB) {
                return 1;
            } else if (a.assetOrder < b.assetOrder) {
                return -1;
            } else if (a.assetOrder > b.assetOrder) {
                return 1;
            }
            if (a.name < b.name) {
                return -1;
            }
            return 1;
        });

        // build tree
        let assetMap = {};

        assets.forEach((asset) =>{
            let assetType = asset.assetType.toLowerCase();
            let active = 'inactive';
            if (asset.isActive === 1) {
                active = 'active';
            }

            // Store original contentType so we can use it later
            asset.contentTypeId = asset.contentType;
            asset.contentType = AssetTabStore.quickAndDirtyContentTypeToText(assetType, parseInt(asset.contentType));

            asset.children = [];
            let parentStackAsset = null;
            if (asset.parentStackAssetId) {
                parentStackAsset = assetMap[asset.parentStackAssetId];
            }

            if (parentStackAsset && parentStackAsset.isActive === asset.isActive) {
                // if has parent and same active status connect thild
                parentStackAsset.children.push(asset);
            } else {
                // clear parent and add it to single list
                asset.parentStackAssetId = null;
                let category = Categories.getCategory(assetType, asset.contentType);
                if (!tree[assetType][active][category]) {
                    tree[assetType][active][category] = [];
                }
                tree[assetType][active][category].push(asset);
                // save in map for quick recover when inserting children
                assetMap[asset.assetId] = asset;
            }
        });
        return tree;
    }

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
    }

    clearReplaceVideoData() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.REPLACE_VIDEO_CLEAR
        });
    }

    clearSelectedAssetsToAdd() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR_SELECTED_ASSETS_TO_ADD
        });
    }

    get(id, caller, noStart) {
        //caller = talent/title
        if (!noStart) {
            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.START,
            });
        }

        Request.get(`${caller}/${id}/asset-summary`).exec().then(res => {
            let assets = res.body;

            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.SUCCESS,
                assets: Immutable.fromJS(this.buildTree(assets)),
                caller: caller,
                id: id
            });

            // Get ids for all image and video thumbnails
            let getIds = function(type) {
                let ids = assets.filter(asset => {
                    return asset.assetType === type;
                }).map(v => v.assetId);
                return ids;
            };

            let imageIds = getIds('IMAGE');
            let videoIds = getIds('VIDEO');

            // Group ids by THUMBNAILS_BATCH_SIZE value
            let groupByBatchSize = function(arr) {
                let groups = [];
                let i;
                for (i = 0; i < arr.length; i += CONSTANTS.THUMBNAILS_BATCH_SIZE) {
                    groups.push(arr.slice(i, i + CONSTANTS.THUMBNAILS_BATCH_SIZE));
                }
                return groups;
            };

            // Transform the array response into a Map{assetId => thumbnails}.
            let reduceThumbnails = function(type, thumbnailsResponse) {
                return thumbnailsResponse.body.reduce((r, t) => {
                    // Get the id attribute: imageId or videoId.
                    let idAttr = `${type}Id`;
                    r[`${type}-${t[idAttr].toString()}`] = t;
                    return r;
                }, {});
            };

            if (imageIds.length) {
                let imageIdsByGroup = groupByBatchSize(imageIds);
                imageIdsByGroup.forEach((group) => {
                    Request.get('asset/image/url').query({'image-id': group}).exec()
                        .then((thumbnailsResponse) => {
                            Dispatcher.dispatch({
                                actionType: CONSTANTS.SET_THUMBNAILS,
                                elementsCount: imageIds.length,
                                thumbnails: Immutable.fromJS(reduceThumbnails('image', thumbnailsResponse)),
                                type: 'image'
                            });
                        })
                        .catch(err => {
                            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'asset-summary.image-thumbnails.load-error');
                            throw err;
                        });
                });
            }
            if (videoIds.length) {
                let videoIdsByGroup = groupByBatchSize(videoIds);
                videoIdsByGroup.forEach((group) => {
                    Request.get('asset/video/thumbnailURL').query({'video-id': group}).exec()
                        .then((thumbnailsResponse) => {
                            Dispatcher.dispatch({
                                actionType: CONSTANTS.SET_THUMBNAILS,
                                elementsCount: videoIds.length,
                                thumbnails: Immutable.fromJS(reduceThumbnails('video', thumbnailsResponse)),
                                type: 'video',
                            });
                        })
                        .catch(err => {
                            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'asset-summary.video-thumbnails.load-error');
                            throw err;
                        });
                });
            }

            return;
        }).catch(err => {
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'asset-summary.load-error');
            throw err;
        });
    }

    getAssetUrl(assetId, preventError) {
        return Request.get('asset/download').query({
            'asset-id': assetId,
        }).exec().then((res) => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.SET_ASSET_URL,
                assetUrl: res.body.downloadUrl,
            });
        }).catch(err => {
            if (preventError) {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.SET_ASSET_URL,
                    assetUrl: ''
                });
                return;
            }

            NotificationActions.showAlertDanger('asset.download.error');
            throw err;
        });
    }

    getCatalogs(assetId, assetPath) {
        Request.get(`asset/${assetId}/catalog`).exec().then(res => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.CATALOG.GET.SUCCESS,
                catalogs: Immutable.fromJS(res.body),
                assetPath: assetPath,
                assetId: assetId
            });
            return;
        }).catch(err => {
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'asset.catalogs.load-error');
            throw err;
        });

        return;
    }

    hide() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.HIDE_DETAIL
        });
    }

    link(assetPath, from, to) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.LINK,
            assetPath: assetPath,
            from: from,
            to: to
        });
    }

    remove(assetPath, asset, entityType) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.REMOVE,
            assetPath,
            asset,
            entityType
        });

        return;
    }

    reorder(assetPath, from, to) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.REORDER,
            assetPath: assetPath,
            from: from,
            to: to
        });
    }

    replaceAssetMetadata(copyToAsset, copyFromAsset, id, caller, copyRestrictions, copyTitleCatalogs) {
        // Used for replacing one video with another.
        // Note: currently api doesn't support specifying which data (metadata, etc) is copied

        Dispatcher.dispatch({
            actionType: CONSTANTS.REPLACE_VIDEO.START,
        });

        Request.put(`asset/video/${copyFromAsset.get('id')}/metadata/replace-with/${copyToAsset.get('assetId')}`).query({
            'exclude-restriction': !copyRestrictions,
            'exclude-regular-catalogs': !copyTitleCatalogs
        }).exec().then(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.REPLACE_VIDEO.SUCCESS,
            });

            NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'titles.create.assets.replace-video.success');
            return;
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.REPLACE_VIDEO.ERROR,
            });

            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'titles.create.assets.replace-video.error');

            throw err;
        }).then(() => {
            // Reload video tab data
            this.get(id, caller);
        });
    }

    save(id, assets, activatedAssets, deactivatedAssets, caller) {
        // navigate tree and generate an array
        let assetsArray = [];
        // 1 iterate assetTypes
        // 2 iterate statuses
        // 3 iterate categories
        // 4 add asset
        // 5 add children

        let recentlyAddedAssets = [];

        ['image', 'video', 'document', 'station', 'audio', 'merchandise', 'script', 'video-timeline'].forEach(assetType => {
            ['active', 'inactive'].forEach( active => {
                sortedCategories.forEach(c => {
                    let as = assets.getIn([assetType, active, c], Immutable.List());
                    // Last parameter sets the assetOrder, it always starts at 1.
                    assetsArray.push(...this.asArray(as, id, caller, assetsArray.length + 1));
                    recentlyAddedAssets.push(...as.filter(a => a.get('isRecentlyAdded')).toJS());
                });
            });
        });

        let toggleActive = function(assetsSet, active) {
            return assetsSet.toJS().map(assetDescription => {
                // Build the asset url from the asset type and the asset id.
                let assetURL = `asset/${MainAssetTypesMap[assetDescription.type]}/${assetDescription.id}`;
                // Get the asset, update the active value and resubmit it.
                return Request.get(assetURL).exec().then(assetRes => {
                    let asset = assetRes.body;
                    asset.active = active;
                    // Remove all things that the server sends out but doesn't
                    // accept back ¬_¬
                    [
                        'imageSuperType',
                        'videoSuperType',
                        // These are for timelines:
                        'mediaInfo',
                        'videoAspectRatioTypeValue',
                        // These are for images:
                        'sourceUrl',
                        'thumbnailUrl',
                        'previewUrl',
                        'largeThumbnailUrl',
                        'largePreviewUrl',
                        'fullResolutionUrl'
                    ].forEach(p => delete asset[p]);
                    return Request.put(assetURL).send(asset).exec();
                });
            });
        };

        return Promise.all([
            Request.get(`${caller}/${id}/asset-summary`).exec(), // Temporary call only for tracing related to assets bug
            Request.put(`${caller}/${id}/asset`).send(assetsArray).exec(),
            ...toggleActive(activatedAssets, 1),
            ...toggleActive(deactivatedAssets, 0)
        ]).then(() => {
            let recentlyAddedRequests = [];
            if (recentlyAddedAssets.length > 0) {
                // only for recently added needs to save twice to set properly expected assetOrder
                recentlyAddedRequests = [Request.put(`${caller}/${id}/asset`).send(assetsArray).exec()];
            }

            return Promise.all([...recentlyAddedRequests]);
        }).then(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.SAVE.SUCCESS
            });

            if (recentlyAddedAssets.length > 0) {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.SET_TITLEID,
                    assets: recentlyAddedAssets,
                    titleId: id,
                });
            }
        });
    }

    selectAssetToAdd(asset) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SELECT_ASSET_TO_ADD,
            asset
        });
    }

    selectCopyFromAsset(asset) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SELECT_COPY_FROM_ASSET,
            asset
        });
    }

    selectCopyToAsset(asset) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SELECT_COPY_TO_ASSET,
            asset
        });
    }

    setAssetType(assetType) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SET_ASSET_TYPE,
            assetType
        });
    }

    setDefault(assetPath, type, asset, entityType) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SET_DEFAULT,
            asset,
            assetPath,
            type,
            entityType
        });

        return;
    }

    setDefaultClear(assetPath, asset, entityType) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SET_DEFAULT_CLEAR,
            asset,
            assetPath,
            entityType
        });
    }

    show(assetPath, asset) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SHOW_DETAIL,
            assetPath: assetPath,
            asset: asset
        });
    }

    showAssetType(assetType) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SHOW_ASSET_TYPE,
            assetType: assetType
        });
    }

    toggleActive(assetPath, asset) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TOGGLE_ACTIVE,
            assetPath: assetPath,
            asset: asset
        });
        return;
    }

    unlink(assetPath, order) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.UNLINK,
            assetPath: assetPath,
            order: order
        });
    }
}

let actions = new AssetTabActions();

export {
    actions as AssetTabActions,
    CONSTANTS as AssetTabConstants,
    sortedCategories
};
