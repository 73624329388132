/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */
import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import QueryString from 'querystring-es3';
import React, {Component} from 'react';
import {Button, DropdownButton, MenuItem} from 'react-bootstrap';
import {Link} from 'react-router';

import {DashboardActions, DashboardConstants} from './dashboard-actions';
import DashboardStore from './dashboard-store';
import FilterOptions from './filter-options';
import StatusDialog from './status-dialog';
import Table from './table';
import {DownloadActions} from '../common/download/download-actions';
import SearchBox from '../common/search-box/search-box';
import {WithStoreOnRoute} from '../common/store-on-route';
import Pagination from '../common/table/pagination';
import {Debounce} from '../common/utils/utils';
import {LayoutActions} from '../layout/layout-actions';
import LayoutStore from '../layout/layout-store';
import Preloader from '../preloader';
import PreloaderStore from '../preloader/preloader-store';
import {RouterActions} from '../router/router-actions';
import {TitleStatusActions} from '../titles/title-status-actions';
import TitleStatusStore from '../titles/title-status-store';
import SessionStore from '../user/session/session-store';

const pageSize = 40;

class Dashboard extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            permissions: PropTypes.object,

        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            applyLastWeekFilter: DashboardStore.getState().get('applyLastWeekFilter'),
            defaultHomescreen: SessionStore.getState().get('defaultHomescreen'),
            showFiltersPanel: LayoutStore.getState().get('showFiltersPanel'),
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible'),
            field: TitleStatusStore.getState().get('field'),
            show: TitleStatusStore.getState().get('show'),
            title: TitleStatusStore.getState().get('title'),
            titles: TitleStatusStore.getState().get('titles'),
            total: TitleStatusStore.getState().get('total')
        };
    }

    static getStores() {
        return [DashboardStore, LayoutStore, PreloaderStore, TitleStatusStore, SessionStore];
    }

    static get defaultProps() {
        return {
            route: undefined,
            permissions: undefined
        };
    }


    static getPermissions() {
        return {
            canViewMetadataStatus: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.METADATA.STATUS.VIEW),
            canEditMetadataStatus: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.METADATA.STATUS.EDIT)
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.handleExportList = this.handleExportList.bind(this);
        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleSaveQuery = this.handleSaveQuery.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
        this.handleToggleFiltersPanel = this.handleToggleFiltersPanel.bind(this);
        this.updateTableHeight = this.updateTableHeight.bind(this);
    }

    componentDidMount() {
        document.title = this.context.intl.messages['dashboard.title'];
        const savedFilters = DashboardStore.getSavedDashboardFilters();
        /* istanbul ignore next */
        if (savedFilters || this.state.applyLastWeekFilter) {
            if (this.state.applyLastWeekFilter) {
                delete savedFilters?.['end-release-date'];
                delete savedFilters?.['start-release-date'];
            }
            const filters = DashboardActions.getDashboardLink(this.state.applyLastWeekFilter, savedFilters);
            RouterActions.redirect(filters);

            // Return here so that the dashboard does not request the titles without filters
            // the next render cycle, componentWillUpdate will request the filtered titles.
            return;
        }

        if (this.props.permissions.canViewMetadataStatus) {
            TitleStatusActions.get(this.getRouteState().set('size', pageSize));
            this.updateTableHeight();
        }

        return;
    }

    /* istanbul ignore next */
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.location.query !== this.props.location.query ||
            nextState.preloaderVisible !== this.state.preloaderVisible ||
            nextState.showFiltersPanel !== this.state.showFiltersPanel ||
            nextState.show !== this.state.show ||
            nextState.titles !== this.state.titles ||
            nextState.total !== this.state.total) {
            return true;
        }
        return false;
    }

    componentWillUpdate(nextProps) {
        if (nextProps.permissions.canViewMetadataStatus && this.props.location !== nextProps.location) {
            TitleStatusActions.get(this.getRouteState(nextProps).set('size', pageSize));
        }
        return;
    }

    componentDidUpdate() {
        if (this.props.permissions.canViewMetadataStatus) {
            this.updateTableHeight();
        }
    }

    // Taken from layout/page.js
    calculateContentHeight() {
        const HEADER_SIZE = 53;
        const FOOTER_SIZE = 52;

        return window.innerHeight - HEADER_SIZE - FOOTER_SIZE;
    }

    handleLoadPage(pageNumber) {
        this.setRouteState('offset', pageNumber * pageSize)
            .apply();
        return;
    }

    handleSaveQuery() {
        const id = SessionStore.getState().getIn(['authUser', 'id']);
        DashboardActions.saveDashboardFilters(id, this.props.location.query);

        return;
    }

    handleSearchTerm(term) {
        this.setRouteState('q', term)
            .clearRouteState('offset')
            .apply();
        return;
    }

    handleExportList() {
        const currentFilters = this.getRouteState(this.props).set('size', pageSize).toJS();
        const query = {
            ...currentFilters,
            'hidden-title-field-name-type': currentFilters.hidden,
            download: true
        };
        DownloadActions.startDownloadExecution('title/status', query);
    }

    handleToggleFiltersPanel() {
        LayoutActions.toggleFiltersPanel();
        return;
    }

    updateTableHeight() {
        // Get the height of components that aren't the table
        const contentHeaderHeight = this.refs.contentHeader.offsetHeight;
        const searchContainerHeight = this.refs.searchContainer.offsetHeight;
        const paginationContainerHeight = this.refs.paginationContainer.offsetHeight;
        // There appears to be something that I'm missing, so I'm gonna round that off to a value that safely works: 55px
        const offset = contentHeaderHeight + searchContainerHeight + paginationContainerHeight + 55;

        this.refs.tableContainer.style.height = `${this.calculateContentHeight() - offset}px`;
    }

    render() {
        if (!this.props.permissions.canViewMetadataStatus) {
            return (
                <section className="content">
                    <div className="row padding-x-10 padding-y-10">
                        <div className="col-xs-12">
                            <div className="container-fluid no-x-padding">
                                <p>{this.context.intl.messages['dashboard.dashboards.no-homepage.p1']}</p>
                                <p>{this.context.intl.messages['dashboard.dashboards.no-homepage.p2']}</p>
                                <ol>
                                    <li>{this.context.intl.messages['dashboard.dashboards.no-homepage.p3']}</li>
                                    <li>{this.context.intl.messages['dashboard.dashboards.no-homepage.p4']}</li>
                                    <li>{this.context.intl.messages['dashboard.dashboards.no-homepage.p5']}</li>
                                </ol>
                                <p>{this.context.intl.messages['dashboard.dashboards.no-homepage.p6']}</p>
                                <p>{this.context.intl.messages['dashboard.dashboards.no-homepage.p7']}</p>
                                <p>{this.context.intl.messages['dashboard.dashboards.no-homepage.p8']}</p>
                            </div>
                        </div>
                    </div>
                </section>
            );
        }

        const dashboardsOptions = {
            all: {
                hiddenFields: [],
                url: DashboardActions.getDashboardFilters(this.state.applyLastWeekFilter, DashboardConstants.DASHBOARD_OPTIONS.ALL),
                label: 'dashboard.dashboards.filter.all'
            },
            video: {
                hiddenFields: [4, 5, 6, 7, 8, 9, 10, 16, 17, 18, 19, 20, 21, 23, 24, 25, 26, 27, 28, 30],
                url: DashboardActions.getDashboardFilters(this.state.applyLastWeekFilter, DashboardConstants.DASHBOARD_OPTIONS.VIDEO),
                label: 'dashboard.dashboards.filter.video'
            },
            metadata: {
                hiddenFields: [4, 5, 6, 7, 8, 9, 12, 14, 15, 30],
                url: DashboardActions.getDashboardFilters(this.state.applyLastWeekFilter, DashboardConstants.DASHBOARD_OPTIONS.METADATA),
                label: 'dashboard.dashboards.filter.metadata'
            },
            photo: {
                hiddenFields: [10, 12, 14, 15, 16, 17, 18, 19, 20, 21, 23, 24, 25, 26, 27, 28],
                url: DashboardActions.getDashboardFilters(this.state.applyLastWeekFilter, DashboardConstants.DASHBOARD_OPTIONS.PHOTO),
                label: 'dashboard.dashboards.filter.photo'
            }
        };

        let selectedDashboard;
        let qsHidden = QueryString.parse(this.props.location.search).hidden;
        if (!Array.isArray(qsHidden)) {
            qsHidden = [qsHidden];
        }
        const hiddenFields = qsHidden.sort((a, b) => a - b).toString();
        const {
            video: {hiddenFields: videoHiddenfields},
            metadata: {hiddenFields: metadataHiddenFields},
            photo: {hiddenFields: photHiddenFields}
        } = dashboardsOptions;
        /* istanbul ignore next */
        switch (hiddenFields) {
        case videoHiddenfields.toString():
            selectedDashboard = dashboardsOptions.video;
            break;
        case metadataHiddenFields.toString():
            selectedDashboard = dashboardsOptions.metadata;
            break;
        case photHiddenFields.toString():
            selectedDashboard = dashboardsOptions.photo;
            break;
        default:
            selectedDashboard = dashboardsOptions.all;
        }

        const hidden = this.readSetRoute('hidden').map( h => parseInt(h));
        return (
            <div className={ClassNames({'control-sidebar-open': this.state.showFiltersPanel})}>
                <div className={ClassNames({'sidebar-opened': this.state.showFiltersPanel})}>
                    <Preloader show={this.state.preloaderVisible} fixed loadingDots>
                        <StatusDialog
                            field={this.state.field}
                            show={this.state.show}
                            title={this.state.title}
                        />
                        <section className="content-header" ref="contentHeader">
                            <h1>
                                <i className="fas fa-tachometer-alt"></i> Dashboard <small>Title Asset Resources Tracking Hub</small>
                            </h1>
                        </section>
                        <section className="content">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="container-fluid no-x-padding" ref="searchContainer">
                                        <div className="row">
                                            <div className="col-md-10">
                                                <div className="row">
                                                    <div className="col-lg-8 show-grid">
                                                        <SearchBox onChange={this.handleSearchTerm} value={this.getRouteState().get('q')}/>
                                                    </div>
                                                    <div className="col-lg-2 margin-bottom-10" style={{minWidth: 389}}>
                                                        <div className="row" style={{display: 'flex', paddingLeft: 15}}>
                                                            <div className="dashboards" style={{width: 190, display: 'inline-block', marginBottom: 8}}>
                                                                <DropdownButton
                                                                    className="btn btn-default-outline btn-block dropdown-toggle"
                                                                    title={this.context.intl.messages[selectedDashboard.label]}
                                                                >
                                                                    <MenuItem
                                                                        className="as-link"
                                                                        componentClass={Link}
                                                                        href={dashboardsOptions.all.url}
                                                                        to={dashboardsOptions.all.url}
                                                                    >
                                                                        {this.context.intl.messages['dashboard.dashboards.filter.all']}
                                                                    </MenuItem>
                                                                    <MenuItem
                                                                        className="as-link"
                                                                        componentClass={Link}
                                                                        href={dashboardsOptions.video.url}
                                                                        to={dashboardsOptions.video.url}
                                                                    >
                                                                        {this.context.intl.messages['dashboard.dashboards.filter.video']}
                                                                    </MenuItem>
                                                                    <MenuItem
                                                                        className="as-link"
                                                                        componentClass={Link}
                                                                        href={dashboardsOptions.metadata.url}
                                                                        to={dashboardsOptions.metadata.url}
                                                                    >
                                                                        {this.context.intl.messages['dashboard.dashboards.filter.metadata']}
                                                                    </MenuItem>
                                                                    <MenuItem
                                                                        className="as-link"
                                                                        componentClass={Link}
                                                                        href={dashboardsOptions.photo.url}
                                                                        to={dashboardsOptions.photo.url}
                                                                    >
                                                                        {this.context.intl.messages['dashboard.dashboards.filter.photo']}
                                                                    </MenuItem>
                                                                </DropdownButton>
                                                            </div>
                                                            <button className="btn btn-primary" onClick={this.handleSaveQuery} style={{marginLeft: 8, marginBottom: 8}}>
                                                                {this.context.intl.messages['dashboard.filters.save-as-default']}
                                                            </button>
                                                            <button className="btn btn-default" onClick={this.handleExportList} style={{marginLeft: 8, marginBottom: 8}}>
                                                                <i className="fas fa-download"></i>&nbsp;{this.context.intl.messages['dashboard.filters.export-list']}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-2 show-grid">
                                                <Button onClick={this.handleToggleFiltersPanel} bsStyle="primary" bsSize="large" className="pull-right">
                                                    <i className="fas fa-sliders-h"></i>&nbsp;{this.context.intl.messages['common.filteringOptions']}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container-fluid no-x-padding">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="box" style={{marginBottom: '0px'}}>
                                                    <div className="box-body" style={{paddingBottom: '0px'}}>
                                                        <div ref="tableContainer">
                                                            <Table
                                                                canEdit={this.props.permissions.canEditMetadataStatus}
                                                                hide={hidden}
                                                                sortDirection={this.getRouteState().get('sort-direction') || 'desc'}
                                                                sortFieldName={this.getRouteState().get('sort-field-name') || 'updatedDate'}
                                                                titles={this.state.titles}
                                                            />
                                                        </div>
                                                        <div className="text-center bottom" ref="paginationContainer">
                                                            <Pagination
                                                                activePage={Math.ceil((this.getRouteState().get('offset') || 0) / pageSize )}
                                                                onChange={this.handleLoadPage}
                                                                totalPages={Math.ceil(this.state.total / pageSize) || 0}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <FilterOptions
                            applyLastWeekFilter={this.state.applyLastWeekFilter}
                            location={this.props.location}
                        />
                    </Preloader>
                </div>
            </div>
        );
    }

}

export default WithStoreOnRoute(Container.create(Dashboard));
