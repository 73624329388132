/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {ConfigurationActions} from './configuration-actions';
import ConfigurationStore from './configuration-store';
import OptionsTab from './options-tab';

import DocumentTitle from '~/src/common/document-title';
import {Tab, Tabs} from '~/src/common/routing-tab/routing-tab';
import {History} from '~/src/common/side-notes/side-notes';
import ActionsMenu from '~/src/layout/actions-menu/actions-menu';
import StickyHeader from '~/src/layout/sticky-header/sticky-header';
import Preloader from '~/src/preloader/';
import {ActionHistoryConstants} from '~/src/system/action-history/action-history-actions';
import SessionStore from '~/src/user/session/session-store';

class Configuration extends Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
        };
    }

    static calculateState() {
        return {
            configuration: ConfigurationStore.getState().get('configuration'),
            originalConfiguration: ConfigurationStore.getState().get('originalConfiguration'),
            preloaderVisible: ConfigurationStore.getState().get('showPreloader'),
        };
    }

    static getStores() {
        return [ConfigurationStore];
    }

    static getPermissions() {
        return {
            canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.HARDAC.CONFIGURATION.EDIT)
        };
    }

    constructor(props) {
        super(props);

        this.handleSave = this.handleSave.bind(this);
    }

    componentDidMount() {
        ConfigurationActions.get();
    }

    handleSave() {
        ConfigurationActions.save(this.state.configuration, this.state.originalConfiguration);
    }

    render() {
        const optionsTab = <Tab route="/hardac/setup/configuration" title={this.context.intl.messages['hardac.setup.configuration.options']}>
            <OptionsTab
                configuration={this.state.configuration}
                location={this.props.location}
                permissions={this.props.permissions}
            />
        </Tab>;

        const historyTab = <Tab route="/hardac/setup/configuration/history" tabClassName="pull-right" title={this.context.intl.messages['common.tab-title.history']}>
            <History
                actionObject={ActionHistoryConstants.ACTION_OBJECTS.FEATURE_CONTROL}
                pagination
                pathname={this.props.location.pathname}
                query={this.props.location.query}/>
        </Tab>;

        // Ensure at least one configuration property has been altered before enabling save button
        const canSave = ConfigurationActions.getConfigurationChanges(this.state.configuration, this.state.originalConfiguration).length !== 0;

        return (
            <DocumentTitle
                message="document-titles.configuration"
            >
                <div>
                    <StickyHeader>
                        <div className="row">
                            <div className="col-md-6">
                                <h1>
                                    <i className="fas fa-cog"></i>&nbsp;{this.context.intl.messages['hardac.setup.configuration.hardac-configuration']}
                                    <br/><small>{this.context.intl.messages['hardac.setup.configuration.hardac-configuration.caption']}</small>
                                </h1>
                            </div>
                            <div className="padding-x-20">
                                <ActionsMenu
                                    canEdit={this.props.permissions.canEdit}
                                    canSave={canSave && this.props.permissions.canEdit}
                                    onSave={this.handleSave}
                                    saveText={this.context.intl.messages['common.save']}
                                />
                            </div>
                        </div>
                    </StickyHeader>

                    <Preloader show={this.state.preloaderVisible} fixed loadingDots>
                        <section className="content" id="contentContainer">
                            <div className="row">
                                <div className="col-lg-12">
                                    <Tabs location={this.props.location}>
                                        {optionsTab}
                                        {historyTab}
                                    </Tabs>
                                </div>
                            </div>
                        </section>
                    </Preloader>
                </div>
            </DocumentTitle>
        );
    }
}

export {Configuration};

export default Container.create(Configuration);
