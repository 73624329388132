/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

import HomescreenTable from './table';
import DocumentTitle from '../../common/document-title';
import SearchBox from '../../common/search-box/search-box';
import {WithStoreOnRoute} from '../../common/store-on-route';
import {Debounce} from '../../common/utils/utils';
import {LayoutActions} from '../../layout/layout-actions';
import LayoutStore from '../../layout/layout-store';
import Preloader from '../../preloader/';
import PreloaderStore from '../../preloader/preloader-store';
import SessionStore from '../../user/session/session-store';
import FilterOptions from '../filter-options/filter-options';
import {HomescreenActions} from '../homescreen-actions';
import HomescreenStore from '../homescreen-store';

class Browse extends Component {

    static get propTypes() {
        return {
            permissions: PropTypes.object.isRequired,
            location: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            homescreens: HomescreenStore.getState().get('homescreens'),
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible'),
            showFiltersPanel: LayoutStore.getState().get('showFiltersPanel'),
        };
    }

    static getStores() {
        return [LayoutStore, HomescreenStore, PreloaderStore];
    }

    static getPermissions() {
        return {
            canReadHomescreens: SessionStore.canUser(SessionStore.PERMISSIONS.HOMESCREEN.READ)
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
        this.handleToggleFiltersPanel = this.handleToggleFiltersPanel.bind(this);
    }

    componentDidMount() {
        HomescreenActions.clear();
        if (this.props.location.query.active) {
            HomescreenActions.get(this.getRouteState());
            return;
        }
        this.setRouteState('active', 'ACTIVE').apply();
        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.location.query !== this.props.location.query ||
            nextState.showFiltersPanel !== this.state.showFiltersPanel ||
            nextState.homescreens !== this.state.homescreens ||
            nextState.preloaderVisible !== this.state.preloaderVisible) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            HomescreenActions.get(this.getRouteState(this.props));
        }
        return;
    }

    handleSearchTerm(term) {
        // This is a local search
        this.setRouteState('q', term)
            .clearRouteState('offset')
            .apply();
        return;
    }

    handleToggleFiltersPanel() {
        LayoutActions.toggleFiltersPanel();
        return;
    }

    render() {
        return (
            <DocumentTitle
                message="document-titles.homescreens"
            >
                <div className={ClassNames({'control-sidebar-open': this.state.showFiltersPanel})}>
                    <div className={ClassNames({'sidebar-opened': this.state.showFiltersPanel})}>
                        <Preloader show={this.state.preloaderVisible} fixed loadingDots>
                            <section className="content-header">
                                <h1>
                                    <i className="fas fa-desktop"></i>&nbsp;{this.context.intl.messages['homescreens.browse.title']}
                                </h1>
                            </section>
                            <section className="content">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="container-fluid no-x-padding">
                                            <div className="row">
                                                <div className="col-md-6 show-grid">
                                                    <SearchBox value={this.getRouteState().get('q')} onChange={this.handleSearchTerm}/>
                                                </div>
                                                <div className="col-md-6 show-grid">
                                                    <Button onClick={this.handleToggleFiltersPanel} bsStyle="primary" bsSize="large" className="pull-right">
                                                        <i className="fas fa-sliders-h"></i>&nbsp;{this.context.intl.messages['common.filteringOptions']}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container-fluid no-x-padding">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="box">
                                                        <div className="box-body padding-x-20 padding-y-20">
                                                            <HomescreenTable
                                                                homescreens={this.state.homescreens}
                                                                displayLinks={this.props.permissions.canReadHomescreens}
                                                                filters={this.getRouteState().get('q')}
                                                                location={this.props.location}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <FilterOptions
                                location={this.props.location}
                            />
                        </Preloader>
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}

export default WithStoreOnRoute(Container.create(Browse));

const BrowseWithRouter = WithStoreOnRoute(Browse);
export {
    BrowseWithRouter as Browse
};
