/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {TargetTypeConstants} from './target-type-actions';
import Dispatcher from '../../../dispatcher/dispatcher';

class TargetTypeStore extends ReduceStore {

    getInitialState() {
        return Immutable.Map({
            targetType: undefined,
            targetTypeId: undefined,
            assetId: undefined,
            assetType: undefined
        });
    }

    getTargetTypeName(id) {
        let tt = this.getTargetTypes();
        let val = '';
        tt.map(type => {
            if (type.id === id) {
                val = type.label;
                return;
            }
        });
        return val;
    }

    getTargetTypes() {
        return [
            {value: 'title', label: 'Title', id:1},
            {value: 'asset', label: 'Asset', id:2},
            {value: 'item', label: 'Internal Page', id:3},
            {value: 'item', label: 'App Section', id:4},
            {value: 'item', label: 'External web page', id:5}
        ];
    }

    reduce(state, action) {
        switch (action.actionType) {

        case TargetTypeConstants.CLEAR:
            state = this.getInitialState();
            break;

        case TargetTypeConstants.SHOW_ITEM:
            state = state.merge({
                targetType: action.targetType,
                targetTypeId: action.targetTypeId,
                assetId: action.assetId,
                assetType: action.assetType
            });
            break;

        case TargetTypeConstants.UPDATE_TARGET_TYPE:
            state = state.merge({
                targetType: action.targetType,
                targetTypeId: action.targetTypeId,
                assetId: action.assetId,
                assetType: action.assetType
            });
            break;

        }
        return state;
    }
}

let ttStore = new TargetTypeStore(Dispatcher);

export default ttStore;
