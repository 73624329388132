/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';

type Props = {
    title?: React.ReactNode,
    iconClass?: string,
    level?: 3 | 4,
}

export default function SectionHeader({level = 3, iconClass, title}: Props) {
    if (!iconClass && !title) {
        return null;
    }

    const HeaderTag = `h${level}` as 'h3' | 'h4';
    return (
        <HeaderTag>
            <i className={iconClass || ''} />
            &nbsp;
            {title}
        </HeaderTag>
    );
}

SectionHeader.defaultProps = {
    title: undefined,
    iconClass: undefined,
    level: undefined
};
