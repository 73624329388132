/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';

import {LanguageActions} from './language-actions';
import LanguageStore from './language-store';
import LookupTab from '../create/tab';

export default createReactClass({
    propTypes: {
        permissions: PropTypes.object.isRequired,
    },

    contextTypes: {
        intl: PropTypes.object.isRequired
    },

    render: function() {
        return (
            <LookupTab
                actions={LanguageActions}
                permissions={this.props.permissions}
                store={LanguageStore}
                title={this.context.intl.messages['lookups.language.title']}
            />
        );
    }

});
