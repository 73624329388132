/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {GetVideoDisplayName} from '@wbdt-sie/brainiac-web-common';
import ClassNames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Modal, ControlLabel, FormGroup} from 'react-bootstrap';
import ReactSelect from 'react-select';

import TimelineAssetStore from '../../../assets/timeline/timeline-asset-store';
import VideoStore from '../../../assets/video/video-store';
import AssetRights from '../../../common/asset-rights/asset-rights';
import BaseSelect from '../../../common/base-select/base-select';
import {FormItem, FormRow, FormSection} from '../../../common/form/form';
import {WithValidations} from '../../../common/validations/validations';
import {TimelineActions} from '../timeline-actions';
import TimelineStore, {CreatingClipValidations} from '../timeline-store';

import {AssetRightsConstants} from '~/src/common/asset-rights/asset-rights-constants';

class CreateClipModal extends React.Component {

    static get propTypes() {
        return {
            canEdit: PropTypes.bool,
            clip: PropTypes.instanceOf(Immutable.Map).isRequired,
            onHide: PropTypes.func.isRequired,
            show: PropTypes.bool,
            viewingMode: PropTypes.string.isRequired,
        };
    }

    static get defaultProps() {
        return {
            show: false,
            canEdit: false,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
        };
    }

    constructor(props) {
        super(props);

        this.textedOptions = [
            {name: 'hardac.timeline.clip.texted-textless.both', texted: true, textless: true},
            {name: 'hardac.timeline.clip.texted-textless.none', texted: false, textless: false},
            {name: 'hardac.timeline.clip.texted-textless.texted', texted: true, textless: false},
            {name: 'hardac.timeline.clip.texted-textless.textless', texted: false, textless: true}
        ];

        this.state = {
            isContentTypeDirty: false,
            isDeliveryTypeDirty: false,
            isProtectionTypeDirty: false,
            selectedContentType: TimelineStore.getContentType(this.props.clip.get('contentType')),
            selectedDeliveryType: TimelineStore.getDeliveryType(this.props.clip.get('deliveryType')),
            selectedProtectionType: TimelineAssetStore.getProtectionType(this.props.clip.get('protectionType')),
            selectedTextedOrTextlessValue: this.handleGetTextedOrTextless(this.props.clip.get('texted'), this.props.clip.get('textless'))
        };

        this.handleClose = this.handleClose.bind(this);
        this.handleCreateClip = this.handleCreateClip.bind(this);
        this.handleSelectContentType = this.handleSelectContentType.bind(this);
        this.handleSelectDeliveryType = this.handleSelectDeliveryType.bind(this);
        this.handleSelectLanguage = this.handleSelectLanguage.bind(this);
        this.handleSelectProtectionType = this.handleSelectProtectionType.bind(this);
        this.handleGetTextedOrTextless = this.handleGetTextedOrTextless.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            selectedContentType: TimelineStore.getContentType(nextProps.clip.get('contentType')),
            selectedDeliveryType: TimelineStore.getDeliveryType(nextProps.clip.get('deliveryType')),
            selectedProtectionType: TimelineAssetStore.getProtectionType(nextProps.clip.get('protectionType')),
            selectedTextedOrTextlessValue: this.handleGetTextedOrTextless(nextProps.clip.get('texted'), nextProps.clip.get('textless'))

        });
        return;
    }

    handleClose() {
        this.props.onHide();
        this.setState(() => ({
            isContentTypeDirty: false,
            isDeliveryTypeDirty: false,
            isProtectionTypeDirty: false,
        }));
    }

    handleCreateClip() {
        TimelineActions.addUpdateClipToList(this.props.clip, 'unpublished');
        this.handleClose();
    }

    handleGetTextedOrTextless(texted, textless) {
        return this.textedOptions.find(o => (!!texted === o.texted && !!textless === o.textless));
    }

    handleSelectContentType(value) {
        if (!this.state.isContentTypeDirty) {
            this.setState({isContentTypeDirty: true});
        }

        if (value !== undefined && value !== null) {
            TimelineActions.update('creatingClip.contentType', value.id);
        } else {
            TimelineActions.update('creatingClip.contentType', null);
        }
        return;
    }

    handleSelectDeliveryType(value) {
        if (!this.state.isDeliveryTypeDirty) {
            this.setState({isDeliveryTypeDirty: true});
        }

        if (value !== undefined && value !== null) {
            TimelineActions.update('creatingClip.deliveryType', value.id);
        } else {
            TimelineActions.update('creatingClip.deliveryType', null);
        }
        return;
    }

    handleSelectLanguage(value) {
        if (value !== undefined && value !== null) {
            TimelineActions.update('creatingClip.language', value.id);
            return;
        }
        TimelineActions.update('creatingClip.language', null);
        return;
    }

    handleSelectProtectionType(value) {
        if (!this.state.isProtectionTypeDirty) {
            this.setState({isProtectionTypeDirty: true});
        }

        if (value !== undefined && value !== null) {
            TimelineActions.update('creatingClip.protectionType', value.id);
        } else {
            TimelineActions.update('creatingClip.protectionType', null);
        }
        return;
    }

    handleSelectTextedOrTextlessValue(value) {
        if (value !== undefined && value !== null) {
            TimelineActions.update('creatingClip.texted', value.texted);
            TimelineActions.update('creatingClip.textless', value.textless);
            return;
        }

        TimelineActions.update('creatingClip.texted', null);
        TimelineActions.update('creatingClip.textless', null);
    }

    handleUpdateClipAttr(attr, value) {
        TimelineActions.update(`creatingClip.${attr}`, value);
    }

    render() {
        const rightsInfoSection = (
            <AssetRights
                defaultRightsInfoValue={AssetRightsConstants.RIGHTS_INFO_TYPES.DEFAULT.id}
                disabled={!this.props.canEdit}
                model={this.props.clip}
                updater={this.handleUpdateClipAttr}
                validations={CreatingClipValidations}
            />
        );

        const validations = TimelineStore.getCreatingClipValidations();
        let programmaticName = '-';
        if (this.props.clip.get('displayName')) {
            const video = this.props.clip.set('name', this.props.clip.get('displayName'));
            programmaticName = GetVideoDisplayName(video);
        }

        return (
            <Modal show={this.props.show} backdrop="static" onHide={this.handleClose} className={ClassNames({'skin-dark': this.props.viewingMode === 'skin-dark'})}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="text-center">{this.context.intl.messages['hardac.timeline.clip.create.title']}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormSection>
                        <FormRow>
                            <FormItem
                                attr="name"
                                label={this.context.intl.messages['hardac.timeline.clip.form.name']}
                                model={this.props.clip}
                                required
                                setter={this.handleUpdateClipAttr}
                                type="text"
                                validations={CreatingClipValidations.name.validations}
                            />
                        </FormRow>
                        <FormRow>
                            <FormItem
                                attr="displayName"
                                label={this.context.intl.messages['hardac.timeline.clip.display-name']}
                                model={this.props.clip}
                                setter={this.handleUpdateClipAttr}
                                type="text"
                                legend={<p><em>{this.context.intl.messages['hardac.timeline.clip.display-name.description']}</em></p>}
                            />
                        </FormRow>
                        <FormRow>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['hardac.timeline.clip.front-end-preview']}</ControlLabel>
                                <p><em>{programmaticName}</em></p>
                            </FormGroup>
                        </FormRow>
                        <FormRow>
                            <FormGroup validationState={this.getValidationState(
                                this.state.selectedContentType,
                                this.state.isContentTypeDirty,
                                CreatingClipValidations.contentType.validations
                            )}>
                                <ControlLabel>{this.context.intl.messages['hardac.timeline.clips.content-type']}&nbsp;<span className="text-red">*</span></ControlLabel>
                                <BaseSelect
                                    classNamePrefix="dropdown"
                                    disabled={!this.props.canEdit}
                                    isClearable={true}
                                    name={this.context.intl.messages['hardac.timeline.clips.content-type']}
                                    onChange={this.handleSelectContentType}
                                    options="contentType"
                                    store={TimelineStore}
                                    value={this.state.selectedContentType}
                                />
                            </FormGroup>
                        </FormRow>
                        <FormRow>
                            <FormGroup validationState={this.getValidationState(
                                this.state.selectedProtectionType,
                                this.state.isProtectionTypeDirty,
                                CreatingClipValidations.protectionType.validations
                            )}>
                                <ControlLabel>{this.context.intl.messages['hardac.timeline.clip.protection-type']}&nbsp;<span className="text-red">*</span></ControlLabel>
                                <BaseSelect
                                    classNamePrefix="dropdown"
                                    disabled={!this.props.canEdit}
                                    isClearable={true}
                                    multi={false}
                                    name="selectedProtectionType"
                                    onChange={this.handleSelectProtectionType}
                                    options="selectedProtectionType"
                                    store={TimelineAssetStore}
                                    value={this.state.selectedProtectionType}
                                />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['hardac.timeline.clip.texted-textless']}</ControlLabel>
                                <ReactSelect
                                    classNamePrefix="dropdown"
                                    getOptionLabel={data => this.context.intl.messages[data.name]}
                                    getOptionValue={data => data}
                                    disabled={!this.props.canEdit}
                                    isClearable={false}
                                    multi={false}
                                    onChange={this.handleSelectTextedOrTextlessValue}
                                    options={this.textedOptions.filter(o => !(o.texted && o.textless))}
                                    value={this.state.selectedTextedOrTextlessValue}
                                />
                            </FormGroup>
                        </FormRow>
                        <FormRow>
                            <FormGroup validationState={this.getValidationState(
                                this.state.selectedDeliveryType,
                                this.state.isDeliveryTypeDirty,
                                CreatingClipValidations.deliveryType.validations
                            )}>
                                <ControlLabel>{this.context.intl.messages['hardac.timeline.clip.deliveryType']}&nbsp;<span className="text-red">*</span></ControlLabel>
                                <BaseSelect
                                    classNamePrefix="dropdown"
                                    disabled={!this.props.canEdit}
                                    isClearable={true}
                                    multi={false}
                                    name="deliveryType"
                                    onChange={this.handleSelectDeliveryType}
                                    options="deliveryType"
                                    store={TimelineStore}
                                    value={this.state.selectedDeliveryType}
                                />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['hardac.timeline.clip.status']}</ControlLabel>
                                <FormItem
                                    attr="active"
                                    label={this.context.intl.messages['hardac.timeline.clip.active']}
                                    disabled={!this.props.canEdit}
                                    model={this.props.clip}
                                    setter={this.handleUpdateClipAttr}
                                    type="checkbox"
                                />
                            </FormGroup>
                        </FormRow>
                        <FormRow>
                            <FormGroup md={6}>
                                <ControlLabel>{this.context.intl.messages['hardac.timeline.clip.language']}</ControlLabel>
                                <BaseSelect
                                    classNamePrefix="dropdown"
                                    disabled={!this.props.canEdit}
                                    name={this.context.intl.messages['hardac.timeline.clip.language']}
                                    onChange={this.handleSelectLanguage}
                                    options="languages"
                                    store={VideoStore}
                                    value={VideoStore.getLanguage(this.props.clip.get('language'))}
                                />
                            </FormGroup>
                            <FormGroup md={3}>
                                <ControlLabel>{this.context.intl.messages['hardac.timeline.clip.subtitles']}</ControlLabel>
                                <FormItem
                                    attr="hasSub"
                                    label={this.context.intl.messages['hardac.timeline.clip.hasSub']}
                                    disabled={!this.props.canEdit}
                                    model={this.props.clip}
                                    setter={this.handleUpdateClipAttr}
                                    type="checkbox"
                                />
                            </FormGroup>
                            <FormGroup md={3}>
                                <ControlLabel>{this.context.intl.messages['hardac.timeline.clip.dubs']}</ControlLabel>
                                <FormItem
                                    attr="hasDub"
                                    label={this.context.intl.messages['hardac.timeline.clip.hasDub']}
                                    disabled={!this.props.canEdit}
                                    model={this.props.clip}
                                    setter={this.handleUpdateClipAttr}
                                    type="checkbox"
                                />
                            </FormGroup>
                        </FormRow>
                        <hr/>
                        {rightsInfoSection}
                    </FormSection>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="btn btn-success-outline"
                        disabled={validations.length}
                        onClick={this.handleCreateClip}
                    >
                        <i className="fas fa-cut"/>&nbsp;{this.context.intl.messages['hardac.timeline.clip.create.title']}
                    </Button>
                    <Button
                        className="btn btn-default-outline"
                        onClick={this.handleClose}
                    >
                        <i className="fas fa-ban"/>&nbsp;{this.context.intl.messages['common.cancel']}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default WithValidations(CreateClipModal);
