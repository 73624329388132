/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {LanguageCodeType} from '@wbdt-sie/brainiac-web-common';
import Promise from 'bluebird';
import Immutable from 'immutable';

import {AudioProfileActions, AudioProfileConstants} from '../audio-profile-table/audio-profile-actions';
import AudioProfileStore from '../audio-profile-table/audio-profile-store';

import {AlertTypes} from '~/src/common/notification/alert';
import {NotificationActions} from '~/src/common/notification/notification-actions';
import {AudioProfileToArray, ArrayToAudioProfile, FormatOAPAudioChannelName} from '~/src/common/utils/utils';
import Dispatcher from '~/src/dispatcher/dispatcher';
import Request from '~/src/request';
import {RouterActions} from '~/src/router/router-actions';

const CONSTANTS = {
    AUDIO_PROFILE: {
        ADD: 'video-delivery.audio-profile.add',
        DELETE: 'video-delivery.audio-profile.delete',
        SET: 'video-delivery.audio-profile.set',
        UPDATE: 'video-delivery.audio-profile.update',
        MOVE_ELEMENT: 'video-delivery.audio-profile.move_element',
    },
    CATEGORY_TYPES: {
        SOURCE: {id: 'Source', name: 'Source'},
    },
    CLEAR: 'video-delivery.clear',
    DIVISION_TYPES: {
        WORLDWIDE: {id: 'Worldwide', name: 'Worldwide'},
    },
    ELEMENT_TYPES: {
        ORIGINAL: {id: 'Original', name: 'Original'},
    },
    ENCODE_PLATFORM_TYPES: {
        AMS: {id: 'AMS', name: 'AMS'},
        MK: {id: 'MEDIA_KIND', name: 'MediaKind'},
    },
    FILTERS: {
        ACTIVE_OPTIONS: {
            INCLUDE_INACTIVE: 'video-delivery.filters.active-options.include_inactive',
            ONLY_ACTIVE: 'video-delivery.filters.active-options.include_inactive'
        }
    },
    FILE_DIMENSION_TYPES: {
        DIMENSIONS_1920_1080: {id: 'File: 1920x1080', name: 'File: 1920x1080'},
        DIMENSIONS_3840_2160:{id: 'File: 3840x2160', name: 'File: 3840x2160'},
    },
    FILTER: {
        SET: 'video-delivery.filter.set',
        CLEAR: 'video-delivery.filter.clear'
    },
    IMAGE_QUALITY_TYPES: {
        HIGH_QUALITY: {id: 'High Quality', name: 'High Quality'},
    },
    PROGRAMMING_TYPES: {
        WB_WORLDWIDE: {id: 'WB Worldwide', name: 'WB Worldwide'},
    },
    SERVICEABLE_TYPES: {
        DOMESTIC_CABLE: {id: 'DOMESTIC_CABLE', name: 'Domestic Cable'},
        DOMESTIC_TV: {id: 'DOMESTIC_TV', name: 'Domestic TV'},
        INTERNATIONAL: {id: 'INTERNATIONAL', name: 'International'},
        NONSERVICEABLE: {id: 'NON-SERVICEABLE', name: 'Non-Serviceable'},
        WORLDWIDE: {id: 'WORLDWIDE', name: 'Worldwide'},
    },
    STANDARD_TYPES: {
        DVD: {id: 'DVD', name: 'DVD'},
        DATA: {id: 'Data', name: 'Data'},
        HD_1080_29_97PSF: {id: 'HD-1080 29.97PSF', name: 'HD-1080 29.97PSF'},
        HD_1080i_59_94: {id: 'HD-1080i 59.94', name: 'HD-1080i 59.94'},
        HD_1080p_23_98: {id: 'HD-1080p 23.98', name: 'HD-1080p 23.98'},
        HD_1080p_59_94: {id: 'HD-1080p 59.94', name: 'HD-1080p 59.94'},
        HD_720p_23_98: {id: 'HD-720p 23.98', name: 'HD-720p 23.98'},
        HD_720p_59_94: {id: 'HD-720p 59.94', name: 'HD-720p 59.94'},
        MP3: {id: 'MP3', name: 'MP3'},
        MP4: {id: 'MP4', name: 'MP4'},
        MPEG2: {id: 'MPEG2', name: 'MPEG2'},
        NTSC: {id: 'NTSC', name: 'NTSC'},
        PAL: {id: 'PAL', name: 'PAL'},
        PAL_HD_1080i_50: {id: 'PAL/HD-1080i 50', name: 'PAL/HD-1080i 50'},
        PAL_HD_1080p_25: {id: 'PAL/HD-1080p 25', name: 'PAL/HD-1080p 25'},
        UHD_3840x1600_23_98: {id: 'UHD-3840x1600 23.98', name: 'UHD-3840x1600 23.98'},
        UHD_3840x2160_23_98: {id: 'UHD-3840x2160 23.98', name: 'UHD-3840x2160 23.98'}
    },
    SYNC_TO_OAP_TYPES: {
        NO_TRANSFER: {name: 'Do not transfer data to or from OAP', id: 'no_transfer'},
        PROXY_INGEST: {name: 'Proxy Ingest - do not transfer data to or from OAP', id: 'proxy_ingest'},
        SEND_TO_OAP: {name: 'Send HARDAC video data to OAP', id: 'send_to_oap'},
        PULL_FROM_OAP: {name: 'Pull audio profile from OAP barcode into HARDAC', id: 'pull_from_oap'},
    },
    VIDEO_DELIVERY: {
        APPROVE: {
            ERROR: 'video-delivery.approve.error',
            START: 'video-delivery.approve.start',
            SUCCESS: 'video-delivery.approve.success'
        },
        AUDIO_CHANNELS: {
            TOGGLE_ALL: 'video-delivery.audio-channels.toggle-all'
        },
        AUDIO_TRACK: {
            TOGGLE: 'video-delivery.audio-track.toggle',
            TOGGLE_PROXY_LR: 'video-delivery.audio-track.toggle-proxy-lr'
        },
        AUDIO_WAVEFORMS: {
            GET: {
                SUCCESS: 'video-delivery.audio-waveforms.get.success'
            }
        },
        CLEAR_OAP: 'video-delivery.clear.oap',
        FIND_BY_ID: {
            ERROR: 'video-delivery.find-by-id.error',
            START: 'video-delivery.find-by-id.start',
            SUCCESS: 'video-delivery.find-by-id.success'
        },
        GET: {
            ERROR: 'video-delivery.get.error',
            START: 'video-delivery.get.start',
            SUCCESS: 'video-delivery.get.success'
        },
        GET_OAP_BY_BARCODE: {
            ERROR: 'video-delivery.get-oap-by-barcode.error',
            START: 'video-delivery.get-oap-by-barcode.start',
            SUCCESS: 'video-delivery.get-oap-by-barcode.success'
        },
        GET_OAP_SHOWCODES: {
            ERROR: 'video-delivery.get-oap-showcodes.error',
            START: 'video-delivery.get-oap-showcodes.start',
            SUCCESS: 'video-delivery.get-oap-showcodes.success'
        },
        REJECT: {
            ERROR: 'video-delivery.deny.error',
            START: 'video-delivery.deny.start',
            SUCCESS: 'video-delivery.deny.success'
        },
        SAVE: {
            ERROR: 'video-delivery.save.error',
            START: 'video-delivery.save.start',
            SUCCESS: 'video-delivery.save.success',
        },
        SRC: {
            GET: {
                SUCCESS: 'hardac.video-delivery.src.get.success'
            }
        },
        SET_SELECTED_TITLE: 'video-delivery.set-selected-title',
        UPDATE: 'video-delivery.update.attribute'
    },
    STATUS_TYPES: {
        APPROVED: {
            id: 'APPROVED',
            icon: 'fas fa-check',
            label: 'bg-green',
            name: 'Approved'
        },
        COMPLETED: {
            id: 'COMPLETED',
            icon: 'fas fa-clipboard-list-check',
            label: 'bg-primary',
            name: 'Ready'
        },
        FAILED_INGEST_TO_OAP: {
            id: 'FAILED_INGEST_TO_OAP',
            icon: 'fas fa-exclamation-triangle',
            label: 'bg-red',
            name: 'OAP Ingest Failed'
        },
        INITIALIZED: {
            id: 'INITIALIZED',
            icon: 'fas fa-upload',
            label: 'bg-gray',
            name: 'Initializing'
        },
        REJECTED: {
            id: 'REJECTED',
            icon: 'fas fa-ban',
            label: 'bg-red',
            name: 'Rejected'
        },
        ENCODING: {
            id: 'ENCODING',
            icon: 'fas fa-ellipsis-h',
            label: 'bg-gray',
            name: 'Encoding'
        },
        PARTIAL_DELIVERY: {
            id: 'PARTIAL_DELIVERY',
            icon: 'fas fa-percentage',
            label: 'bg-gray',
            name: 'Partial'
        }
    },
    UPDATE: 'video-delivery.update',

    toArray: function(constant) {
        /* istanbul ignore next */
        return Object.keys(this[constant]).map(k => this[constant][k]);
    }
};

class EmptyOapMetadataError extends Error {
    constructor() {
        super();
        return;
    }
}

class VideoDeliveryActions {

    addAudioProfile() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.AUDIO_PROFILE.ADD
        });
    }

    approve(videoDelivery, audioProfile, proxyChannels) {
        const videoDeliveryId = videoDelivery.get('id');
        // BRANIAC GROUP is the only storage group, dropdown in the modal is removed BRAIN-2676
        const storageGroupId = 1;

        // Remove extraneous UI fields
        const videoDeliveryData = this.prepareVideoDeliveryData(videoDelivery);

        videoDeliveryData.publishInterplay = false;
        if (videoDelivery.get('syncInterplay')) {
            videoDeliveryData.publishInterplay = true;
        }

        Dispatcher.dispatch({
            actionType: CONSTANTS.VIDEO_DELIVERY.APPROVE.START,
        });

        const mediaAssetAudio = ArrayToAudioProfile(undefined, audioProfile);
        const vd = Object.assign({}, videoDeliveryData, {
            mediaAssetAudio,
            proxyLeftChannel: proxyChannels.get('left'),
            proxyRightChannel: proxyChannels.get('right')
        });
        Request.put(`asset/video/delivery/${videoDeliveryId}`).send(videoDeliveryData).exec().then(() => {
            return Request.put(`asset/video/delivery/${videoDeliveryId}/approve/${storageGroupId}`).send(vd).exec();
        }).then(response => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.VIDEO_DELIVERY.APPROVE.SUCCESS,
            });
            RouterActions.redirect(`/hardac/timeline/${response.body.timelineId}/edit-asset`, true);
            NotificationActions.showAlertSuccess('hardac.video-inbox.approve.success');
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.VIDEO_DELIVERY.APPROVE.ERROR,
            });

            switch (err.status) {
            case 404:
                NotificationActions.showAlertDanger('hardac.video-inbox.approve.forbidden');
                break;
            default:
                NotificationActions.showAlertDanger('hardac.video-inbox.approve.error');
                break;
            }

            throw err;
        });

        return;
    }

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
    }

    clearFilter() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.FILTER.CLEAR
        });
    }

    clearOAPSync() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.VIDEO_DELIVERY.CLEAR_OAP
        });
    }

    deleteAudioProfile(index) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.AUDIO_PROFILE.DELETE,
            index
        });
    }

    findById(id) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.VIDEO_DELIVERY.FIND_BY_ID.START,
            id
        });

        // Request.get('wb-fairplay.der').set('Accept', '*/*').responseType('blob').exec().then(r => {
        //     let fileReader = new FileReader();
        //     fileReader.onload = event => {
        //         window.__cert = new Uint8Array(event.target.result);
        //     };
        //     fileReader.readAsArrayBuffer(r.body);
        // }).catch(err => console.error(err));

        let videoDelivery;
        Request.get(`asset/video/delivery/${id}`).exec().then(response => {
            // Set syncOptionType to Send to Oap by default
            const data = response.body;
            if (!data.syncOptionType) {
                data.syncOptionType = CONSTANTS.SYNC_TO_OAP_TYPES.SEND_TO_OAP.id;
            }

            if (data.language === null || data.language === undefined) {
                data.language = LanguageCodeType.DEFAULT_LANGUAGE;
            }
            // default sendToOap
            if (data.syncOptionType === CONSTANTS.SYNC_TO_OAP_TYPES.SEND_TO_OAP.id) {
                data.sendToOap = true;
            }
            videoDelivery = Immutable.fromJS(data);

            // Try to seed the audio profile from the video delivery.
            let audioProfile = Immutable.List();
            let mediaInfoTotalAudioTracks = 0;

            try {
                const mediaInfo = JSON.parse(JSON.parse(response.body.mediaInfo));
                const audioTrack = mediaInfo.track.find(t => t['@type'] === 'Audio');
                const generalTrack = mediaInfo.track.filter(t => t['@type'] === 'General');
                const profileByLayout = AudioProfileConstants.PROFILES_BY_LAYOUT[audioTrack.ChannelLayout];

                if (generalTrack.length) {
                    mediaInfoTotalAudioTracks = generalTrack[0].AudioCount;
                }

                if (profileByLayout) {
                    audioProfile = Immutable.fromJS(profileByLayout);
                }

                const mediaAssetAudio = videoDelivery.get('mediaAssetAudio');
                if (mediaAssetAudio) {
                    audioProfile = AudioProfileToArray(mediaAssetAudio.toJS());
                }

                if (!AudioProfileStore.getState().get('audioLookups').size) {
                    AudioProfileActions.getAudioLookups();
                }
            } catch (e) {
                /* istanbul ignore next */
                console.error('Error trying to parse video delivery media info', e);
            }

            Dispatcher.dispatch({
                actionType: CONSTANTS.VIDEO_DELIVERY.FIND_BY_ID.SUCCESS,
                audioProfile,
                mediaInfoTotalAudioTracks,
                videoDelivery
            });


            let srcRequest;
            if (videoDelivery.get('proxyFileLocation') !== null) {
                srcRequest = Request.get(`asset/video/delivery/${id}/streamURL`).exec().catch(err => {
                    NotificationActions.showAlertDanger('hardac.video-inbox.src-url.error');
                    if (err.status === 404) {
                        return;
                    }
                    throw err;
                });
            } else {
                NotificationActions.showAlertWarning('hardac.video-inbox.warning.no-proxy');
            }

            if (videoDelivery.get('titleId')) {
                Request.get(`title/${videoDelivery.get('titleId')}`).exec()
                    .then((titleRes) => {
                        this.setSelectedTitle(Immutable.fromJS(titleRes.body));
                    });
            }

            return srcRequest;
        }).then(srcResponse => {
            if (!srcResponse) {return;}

            const src = Immutable.fromJS({
                dash: srcResponse.body.dashLocatorUri,
                hls: srcResponse.body.hlsLocatorUri,
                drmTokens: srcResponse.body.videoDrmTokens
            });

            Dispatcher.dispatch({
                actionType: CONSTANTS.VIDEO_DELIVERY.SRC.GET.SUCCESS,
                src,
                videoDeliveryId: id
            });

            const audioWaveformContainerUri = videoDelivery.get('audioWaveformContainerUri');
            return Promise.all(
                [...Array(16).keys()].map(i => {
                    // It is easier to use fetch over Promise because we need to find a way to not
                    // send the authorization token in some requests.
                    let p = Promise.resolve();
                    if (audioWaveformContainerUri) {
                        p = fetch(
                            `${audioWaveformContainerUri}/audio-profile${(i + 1).toString().padStart(2, '0')}.json`
                        ).then(/*istanbul ignore next*/res => {
                            if (res.ok) {
                                return res.json();
                            }

                            return undefined;
                        }).catch(/*istanbul ignore next*/(err) => {
                            console.error(err);
                            return;
                        });
                    }

                    return p;
                })
            );
        }).then(audioWaveforms => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.VIDEO_DELIVERY.AUDIO_WAVEFORMS.GET.SUCCESS,
                audioWaveforms: Immutable.fromJS(audioWaveforms)
            });
            return;
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.VIDEO_DELIVERY.FIND_BY_ID.ERROR,
            });

            switch (err.status) {
            case 404:
                RouterActions.notFound();
                break;
            default:
                NotificationActions.showAlertDanger('common.load-error');
                break;
            }

            throw err;
        });

        return;
    }

    get(queryParams, offset = 0, size = 20) {
        queryParams = queryParams.toJS();
        Dispatcher.dispatch({
            actionType: CONSTANTS.VIDEO_DELIVERY.GET.START
        });

        offset = queryParams.offset || offset;
        size = queryParams.size || size;
        queryParams.offset = offset ;
        queryParams.size = size;
        Request.get('asset/video/delivery')
            .query(
                queryParams
            ).exec()
            .then(response => {
                const videoDeliveryList = Immutable.fromJS(response.body.results);
                Dispatcher.dispatch({
                    actionType: CONSTANTS.VIDEO_DELIVERY.GET.SUCCESS,
                    offset: response.body.offset,
                    size: response.body.size,
                    total: response.header['wbtv-total-count'],
                    videoDeliveryList
                });
                return;
            }).catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.VIDEO_DELIVERY.GET.ERROR
                });

                switch (err.status) {
                case 404:
                    NotificationActions.showAlertDanger('hardac.video-inbox.error.does-not-have-access');
                    break;
                default:
                    NotificationActions.showAlertDanger('hardac.video-inbox.error.does-not-load');
                    break;
                }

                throw err;
            });

        return;
    }

    getOapByBarcode(barcode) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.VIDEO_DELIVERY.GET_OAP_BY_BARCODE.START,
            barcode
        });

        let oapMetadata;
        Request.get(`integration/oap/barcode/${barcode}`).exec().then(response => {
            if (!response.body.length) {throw new EmptyOapMetadataError();}

            oapMetadata = Immutable.fromJS(response.body[0]);
            let audioChannels = [];
            let errorChannels = [];
            for (let i = 1; i < 25; i++) {
                let tDesc = oapMetadata.get(FormatOAPAudioChannelName(i));
                let lookupType;

                if (!tDesc) {continue;}
                tDesc = tDesc.replace(/(\s-\s)|(-)/g, '/');
                const lookup = AudioProfileStore.getLookupByDescription(tDesc);
                if (!lookup) {
                    // Unable to find lookup match for OAP channel name!
                    // FIXME: Two audio channel lookups are mismatched in database: LS/Left Surround (5.1) and RS/Right Surround (5.1)
                    errorChannels.push(tDesc);
                } else {
                    lookupType = lookup.get('value');
                }
                audioChannels.push({audioChannel: i, type: lookupType});
            }

            let audioProfile = Immutable.fromJS({
                audioChannels,
            });

            if (errorChannels.length) {
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'hardac.video-inbox.summary.pull-from-oap.incomplete-audio-profile.error', errorChannels.join(', '));
            }

            this.setAudioProfile(audioProfile);

            Dispatcher.dispatch({
                actionType: CONSTANTS.VIDEO_DELIVERY.GET_OAP_BY_BARCODE.SUCCESS,
                oapMetadata,
                barcode,
            });
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.VIDEO_DELIVERY.GET_OAP_BY_BARCODE.ERROR,
            });

            if (err instanceof EmptyOapMetadataError) {
                NotificationActions.showAlertDanger('hardac.video-inbox.summary.pull-from-oap.empty-oap-metadata.error');
            } else {
                NotificationActions.showAlertDanger('hardac.video-inbox.summary.pull-from-oap.barcode.error');
            }

            throw err;
        });

        return;
    }

    getOapShowCodes() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.VIDEO_DELIVERY.GET_OAP_SHOWCODES.START
        });

        let oapShowCodes;
        Request.get('integration/oap/show-code').exec().then(response => {
            if (!response.body.length) {throw new EmptyOapMetadataError();}

            oapShowCodes = Immutable.fromJS(response.body);

            Dispatcher.dispatch({
                actionType: CONSTANTS.VIDEO_DELIVERY.GET_OAP_SHOWCODES.SUCCESS,
                oapShowCodes
            });
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.VIDEO_DELIVERY.GET_OAP_SHOWCODES.ERROR,
            });

            NotificationActions.showAlertDanger('hardac.video-inbox.summary.send-to-oap.show-codes.error');

            throw err;
        });

        return;
    }

    moveElement(from, to) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.AUDIO_PROFILE.MOVE_ELEMENT,
            from: from,
            to: to
        });
        return;
    }

    prepareVideoDeliveryData(videoDelivery) {
        return [
            'category',
            'Division',
            'elementType',
            'ffprobe',
            'fileDimensions',
            'imageQuality',
            'mediaInfo',
            'programming',
            'ffprobe',
            'serviceable',
        ].reduce((r, p) => {
            return r.delete(p);
        }, videoDelivery).toJS();
    }

    reject(videoDelivery, rejectedComments) {
        const videoDeliveryId = videoDelivery.get('id');
        // Remove extraneous UI fields
        const videoDeliveryData = this.prepareVideoDeliveryData(videoDelivery);

        videoDeliveryData.publishInterplay = false;
        if (videoDelivery.get('syncInterplay')) {
            videoDeliveryData.publishInterplay = true;
        }

        Dispatcher.dispatch({
            actionType: CONSTANTS.VIDEO_DELIVERY.REJECT.START,
        });

        Request.put(`asset/video/delivery/${videoDeliveryId}`).send(videoDeliveryData).exec().then(() => {
            return Request.put(`asset/video/delivery/${videoDeliveryId}/reject`).send(Object.assign({
                rejectedReason: rejectedComments
            }, videoDeliveryData)).exec();
        }).then(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.VIDEO_DELIVERY.REJECT.SUCCESS,
            });
            NotificationActions.showAlertSuccess('hardac.video-inbox.reject.success');
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.VIDEO_DELIVERY.REJECT.ERROR,
            });

            switch (err.status) {
            case 404:
                NotificationActions.showAlertDanger('hardac.video-inbox.reject.forbidden');
                break;
            default:
                NotificationActions.showAlertDanger('hardac.video-inbox.reject.error');
                break;
            }

            throw err;
        });

        return;
    }

    save(videoDelivery, audioProfile) {
        const videoDeliveryId = videoDelivery.get('id');

        // Remove extraneous UI fields
        const videoDeliveryData = this.prepareVideoDeliveryData(videoDelivery);

        Dispatcher.dispatch({
            actionType: CONSTANTS.VIDEO_DELIVERY.SAVE.START,
        });

        const mediaAssetAudio = ArrayToAudioProfile(undefined, audioProfile);
        videoDeliveryData.mediaAssetAudio = mediaAssetAudio;
        Request.put(`asset/video/delivery/${videoDeliveryId}`).send(videoDeliveryData).exec().then(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.VIDEO_DELIVERY.SAVE.SUCCESS,
            });

            NotificationActions.showAlertSuccess('hardac.video-inbox.save.success');
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.VIDEO_DELIVERY.SAVE.ERROR,
            });

            switch (err.status) {
            case 404:
                NotificationActions.showAlertDanger('hardac.video-inbox.save.forbidden');
                break;
            default:
                NotificationActions.showAlertDanger('hardac.video-inbox.save.error');
                break;
            }

            throw err;
        });

        return;
    }

    setAudioProfile(newAudioProfile, prevAudioProfile, totalAudioTracks) {
        let audioChannels = newAudioProfile.get('audioChannels');

        if (newAudioProfile.get('id') === AudioProfileConstants.AUDIO_PROFILE.CUSTOM_PROFILE_ID) {
            if (prevAudioProfile.size) {
                // If custom selected, do not blow away existing profile
                audioChannels = prevAudioProfile;
            } else {
                if (totalAudioTracks < audioChannels.size) {
                    // Else create the proper number of channels based on media info track count
                    audioChannels = audioChannels.slice(0, totalAudioTracks);
                }
            }
        }

        // Mute all channels
        audioChannels = audioChannels.map(t => t.set('isMuted', true));

        const setProxy = (ac, bool, ap, attr) => {
            const apProxy = ap.get(attr);
            if (apProxy?.length >= 3) {
                // Even for complex audio configurations, we still
                // want the first channel to be unmuted.
                return ac.mergeIn([0], {
                    isMuted: false
                });
            }

            const proxyIndex = parseInt(apProxy, 10) - 1;
            if (proxyIndex >= 0) {
                return ac.mergeIn([proxyIndex], {
                    [bool]: true,
                    isMuted: false
                });
            } else {
                return ac.mergeIn([0], {
                    [bool]: true,
                    isMuted: false
                });
            }
        };

        if (newAudioProfile.get('id') !== AudioProfileConstants.AUDIO_PROFILE.CUSTOM_PROFILE_ID) {
            // Do not set proxy L/R for "Custom" profile
            audioChannels = setProxy(audioChannels, 'isProxyLeft', newAudioProfile, 'proxyLeftChannel');
            audioChannels = setProxy(audioChannels, 'isProxyRight', newAudioProfile, 'proxyRightChannel');
        }

        Dispatcher.dispatch({
            actionType:  CONSTANTS.AUDIO_PROFILE.SET,
            audioProfile: audioChannels,
            audioProfilePresetId: newAudioProfile.get('id')
        });
    }

    setFilter(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.FILTER.SET,
            attr: attr,
            value: value
        });
    }

    setSelectedTitle(selectedTitle) {
        Request.get(`title/${selectedTitle.get('id')}`).exec().then(res => {
            let title = res.body;

            title.genres = selectedTitle.get('genres');

            if (title.parentTitleId) {
                return Request.get(`title/${title.parentTitleId}`).exec().then(tres => {
                    title.parentTitle = tres.body;
                    return title;
                });
            }

            return title;
        }).then((title) => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.VIDEO_DELIVERY.SET_SELECTED_TITLE,
                selectedTitle: Immutable.fromJS(title)
            });

            return;
        }).catch(err => {
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
            throw err;
        });

        return;
    }

    soloAudioChannel(index, isMuted) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.VIDEO_DELIVERY.AUDIO_CHANNELS.TOGGLE_ALL,
            index,
            isMuted
        });

        return;
    }

    toggleProxyLR(index, lr) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.VIDEO_DELIVERY.AUDIO_TRACK.TOGGLE_PROXY_LR,
            index,
            lr
        });
    }

    toggleTrack(index) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.VIDEO_DELIVERY.AUDIO_TRACK.TOGGLE,
            index
        });
    }

    updateAudioProfile(attr, value) {
        Dispatcher.dispatch({
            actionType:  CONSTANTS.AUDIO_PROFILE.UPDATE,
            attr,
            value
        });
    }

    updateVideoDelivery(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.VIDEO_DELIVERY.UPDATE,
            attr,
            value
        });

        return;
    }

    update(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.UPDATE,
            attr,
            value
        });

        return;
    }
}

const actions = new VideoDeliveryActions();

export {
    actions as VideoDeliveryActions,
    CONSTANTS as VideoDeliveryConstants
};
