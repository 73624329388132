/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React, {Component} from 'react';

class CountdownTimer extends Component {

    static get propTypes() {
        return {
            completeCallback: PropTypes.func,
            formatFunc: PropTypes.func,
            initialTimeRemaining: PropTypes.number.isRequired,
            interval: PropTypes.number,
            tickCallback: PropTypes.func
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            interval: 1000,
            formatFunc: null,
            tickCallback: null,
            completeCallback: null
        };
    }

    constructor(props) {
        super(props);
        this.state = Object.assign({
            timeRemaining: this.props.initialTimeRemaining,
            timeoutId: null,
            prevTime: null
        });

        this.getFormattedTime = this.getFormattedTime.bind(this);
        this.tick = this.tick.bind(this);
    }

    componentDidMount() {
        this.tick();
    }

    componentWillReceiveProps(newProps) {
        if (this.state.timeoutId) {
            clearTimeout(this.state.timeoutId);
        }
        this.setState({prevTime: null, timeRemaining: newProps.initialTimeRemaining});
    }

    componentDidUpdate() {
        if ((!this.state.prevTime) && this.state.timeRemaining > 0) {
            this.tick();
        }
    }

    componentWillUnmount() {
        clearTimeout(this.state.timeoutId);
    }

    tick() {
        let currentTime = Date.now();
        let dt = 0;
        if (this.state.prevTime) {
            dt = (currentTime - this.state.prevTime);
        }
        let interval = this.props.interval;

        // correct for small variations in actual timeout time
        let timeRemainingInInterval = (interval - (dt % interval));
        let timeout = timeRemainingInInterval;

        if (timeRemainingInInterval < (interval / 2.0)) {
            timeout += interval;
        }

        let timeRemaining = Math.max(this.state.timeRemaining - dt, 0);
        let countdownComplete = (this.state.prevTime && timeRemaining <= 0);

        if (this.state.timeoutId) {
            clearTimeout(this.state.timeoutId);
        }
        let timeoutId = null;
        if (!countdownComplete) {
            timeoutId = setTimeout(this.tick, timeout);
        }
        this.setState((prevState) => ({
            timeoutId: timeoutId,
            prevTime: currentTime,
            timeRemaining: Math.max(prevState.timeRemaining - dt, 0)
        }));
        if (countdownComplete) {
            if (this.props.completeCallback) {
                this.props.completeCallback();
            }
            return;
        }

        if (this.props.tickCallback) {
            this.props.tickCallback(timeRemaining);
        }
    }

    getFormattedTime(milliseconds) {
        if (this.props.formatFunc) {
            return this.props.formatFunc(milliseconds);
        }

        let totalSeconds = Math.round(milliseconds / 1000);

        let seconds = parseInt(totalSeconds % 60, 10);
        let minutes = parseInt(totalSeconds / 60, 10) % 60;
        let hours = parseInt(totalSeconds / 3600, 10);

        if (seconds < 10) {
            seconds = '0' + seconds;
        }

        if (minutes < 10) {
            minutes = '0' + minutes;
        }

        if (hours < 10) {
            hours = '0' + hours;
        }

        return hours + ':' + minutes + ':' + seconds;
    }

    render() {
        let timeRemaining = this.state.timeRemaining;
        return (
            <span className="timer">
                {this.getFormattedTime(timeRemaining)}
            </span>
        );
    }
}

export default CountdownTimer;
