/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React from 'react';

import Summary from './summary';
import {PrivacyActions} from '../privacy-actions';
import PrivacyStore from '../privacy-store';

import RouteValidator from '~/src/common/custom-validator/route/route';
import DocumentTitle from '~/src/common/document-title';
import {Tab, Tabs} from '~/src/common/routing-tab/routing-tab';
import {History} from '~/src/common/side-notes/side-notes';
import {ErrorsWarnings} from '~/src/common/validations/errors-warnings';
import ActionsMenu from '~/src/layout/actions-menu/actions-menu';
import StickyHeader from '~/src/layout/sticky-header/sticky-header';
import Preloader from '~/src/preloader';
import {RouterActions} from '~/src/router/router-actions';
import {ActionHistoryConstants} from '~/src/system/action-history/action-history-actions';
import SessionStore from '~/src/user/session/session-store';

class Create extends React.Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            params: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired,
            route: RouteValidator // eslint-disable-line react/require-default-props
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static getPermissions() {
        return {
            canCreate: SessionStore.canUser(SessionStore.PERMISSIONS.PRIVACY_REQUESTS.CREATE),
            canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.PRIVACY_REQUESTS.BROWSE),
        };
    }

    static getStores() {
        return [PrivacyStore];
    }

    static calculateState() {
        return {
            privacyRequest: PrivacyStore.getState().get('privacyRequest'),
            showPreloader: PrivacyStore.getState().get('showPreloader'),
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.handleCancel = this.handleCancel.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    componentDidMount() {
        this.init(this.props);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.route.mode === 'edit' && nextProps.route.mode === 'create') {
            this.init(nextProps);
        }
        RouterActions.registerRedirectCheck(() => {
            return this.isDirty();
        });
    }

    componentWillUnmount() {
        PrivacyActions.clear();
        return;
    }

    handleCancel() {
        RouterActions.redirect('/privacy');
    }

    handleSave() {
        PrivacyActions.createRequest(this.state.privacyRequest);
    }

    init(props) {
        switch (props.route.mode) {
        case 'edit':
            PrivacyActions.findById(props.params.id);
            break;
        case 'create':
            PrivacyActions.clear();
            break;
        }
    }

    isDirty() {
        return false;
    }

    render() {
        const mode = this.props.route.mode;
        let validations = [];
        if (mode === 'create') {
            validations = PrivacyStore.getValidations();
        }

        let baseRoute = `/privacy/${this.props.params.id}`;
        let actionsMenu;
        let historyTab;

        if (mode === 'create') {
            baseRoute = '/privacy';
            actionsMenu = <ActionsMenu
                canSave={!validations.length && this.props.permissions.canCreate}
                onCancel={this.handleCancel}
                onSave={this.handleSave}
                saveText={this.context.intl.messages['privacy.create-request']}
            />;
        } else {
            historyTab = <Tab route={`${baseRoute}/history`} title={this.context.intl.messages['common.tab-title.history']} tabClassName="pull-right">
                <History
                    actionObject={ActionHistoryConstants.ACTION_OBJECTS.PRIVACYREQUEST}
                    id={this.props.params.id}
                    pagination
                    pathname={this.props.location.pathname}
                    query={this.props.location.query}/>
            </Tab>;
        }

        return <DocumentTitle message="document-titles.privacy">
            <div>
                <StickyHeader>
                    <div className="col-md-6">
                        <h1>
                            <i className="fa-regular fa-user-shield"></i>&nbsp;{this.context.intl.messages[`privacy.title.${mode}`]}
                            &nbsp;
                            <small>{this.state.privacyRequest.get('subjectRequestId')}</small>
                        </h1>
                    </div>
                    {actionsMenu}
                </StickyHeader>
                <Preloader show={this.state.showPreloader} fixed loadingDots>
                    <section className="content" id="contentContainer">
                        <div className="row">
                            <div className="col-lg-9">
                                <Tabs location={this.props.location}>
                                    <Tab route={`${baseRoute}`} title={this.context.intl.messages['privacy.summary.title']}>
                                        <Summary permissions={this.props.permissions} mode={this.props.route.mode} privacyRequest={this.state.privacyRequest} />
                                    </Tab>
                                    {historyTab}
                                </Tabs>
                            </div>
                            <div className="col-lg-3">
                                <ErrorsWarnings
                                    title={this.context.intl.messages['common.errors-warnings-title']}
                                    validations={validations}
                                />
                            </div>
                        </div>
                    </section>
                </Preloader>
            </div>
        </DocumentTitle>;
    }
}

export default Container.create(Create);
