/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React from 'react';
import {Button} from 'react-bootstrap';

import AddExistingPanel from './add-existing-panel';
import Preloader from '../../../preloader';
import {RouterActions} from '../../../router/router-actions';
import {EventConstants, EventActions} from '../../event-actions';
import EventStore from '../../event-store';

import SearchBox from '~/src/common/search-box/search-box';
import {SlidingPanelActions} from '~/src/common/sliding-panel/sliding-panel-actions';
import ListTitlesAG from '~/src/titles/browse/list-titles-ag';

class Titles extends React.Component {
    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
        };
    }

    static get propTypes() {
        return {
            disabled: PropTypes.bool,
            location: PropTypes.object.isRequired,
        };
    }

    static get defaultProps() {
        return {
            disabled: true
        };
    }

    static calculateState() {
        const eventState = EventStore.getState();
        return {
            allTitles: eventState.getIn(['event', 'titles']),
            event: eventState.getIn(['event']),
            numberOfPages: Math.ceil(eventState.getIn(['event', 'titles']).size / EventConstants.EVENT.TITLES.PAGE_SIZE),
            originalEvent: eventState.get('originalEvent'),
            showPreloader: eventState.get('showPreloader'),
            titles: eventState.get('titlesToDisplay'),
        };
    }

    static getStores() {
        return [EventStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            currentPage: 0,
            toRemoveIds: []
        }, this.constructor.calculateState());

        this.handleChangeSynopsisType = this.handleChangeSynopsisType.bind(this);
        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleRemoveTitles = this.handleRemoveTitles.bind(this);
        this.handleToggleSelect = this.handleToggleSelect.bind(this);
    }

    componentDidMount() {
        EventActions.getTitlesBatch(this.state.currentPage, this.state.allTitles);
        RouterActions.registerRedirectCheck((location) => {
            return this.isDirty(location);
        });
        return;
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextState.allTitles.size !== this.state.allTitles.size) {
            EventActions.getTitlesBatch(this.state.currentPage, nextState.allTitles);
        }
        return;
    }

    handleAddTitles() {
        SlidingPanelActions.show('addTitlesToEvent');
    }

    handleChangeSynopsisType(synopsisType, title) {
        EventActions.titleSynopsisChange(title.id, synopsisType);
    }

    handleLoadPage(pageNumber) {
        this.setState(() => ({
            currentPage: pageNumber
        }));
        EventActions.getTitlesBatch(pageNumber, this.state.allTitles);
    }

    handleRemoveTitles() {
        EventActions.removeItems( EventConstants.EVENT.ITEM_TYPES.TITLE, this.state.toRemoveIds);
        this.setState(() => ({
            toRemoveIds: []
        }));
    }

    handleToggleSelect(event) {
        if (event.node.selected) {
            this.setState(prevState => ({
                toRemoveIds: [...prevState.toRemoveIds, prevState.titles.getIn([event.node.rowIndex, 'id'])],
            }));
        } else {
            this.setState(prevState => ({
                toRemoveIds: prevState.toRemoveIds.filter(id => id !== prevState.titles.getIn([event.node.rowIndex, 'id'])),
            }));
        }
    }

    isDirty(location) {
        if (location) {
            let goingTo = location.pathname.split('/');
            let current = this.props.location.pathname.split('/');
            if (goingTo[1] === current[1] && /* events */
                goingTo[2] === current[2] /* events id */) {
                return false; // just changing tabs no confirmation needed.
            }
        }

        return !this.state.originalEvent.equals(this.state.event);
    }

    render() {
        return (
            <Preloader show={this.state.showPreloader} fixed loadingDots>
                <div>
                    <h3><i className="fas fa-newspaper"></i> {this.context.intl.messages['events.titles']} <small>{this.state.allTitles.size}</small></h3>
                    <hr />
                    <div className="col-md-6 text-center">
                        <h4>{this.context.intl.messages['events.titles.manually-add']}</h4>
                        <p><em>{this.context.intl.messages['events.titles.select-individually']}</em></p>
                        <Button bsSize="large" className="btn btn-primary-outline" onClick={this.handleAddTitles} disabled={this.props.disabled}>
                            {this.context.intl.messages['events.titles.select-titles']}
                        </Button>
                    </div>
                    <div className="col-md-6 text-center">
                        <h4>{this.context.intl.messages['events.titles.sync-titles']}</h4>
                        <p><em>{this.context.intl.messages['events.titles.sync-titles.desc']}</em></p>
                        <Button disabled={true} bsSize="large" className="btn btn-primary-outline">
                            {this.context.intl.messages['events.titles.auto-add-titles']}
                        </Button>
                    </div>
                    <div className="col-md-12 text-center">
                        <hr />
                    </div>
                    <div className="container-fluid no-x-padding">
                        <div className="row">
                            <div className="col-md-3"></div>
                            <div className="col-md-6 show-grid">
                                <SearchBox value={''} onChange={this.handleSearchTerm}/>
                            </div>
                            <div className="col-md-3"></div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <Button disabled={!this.state.toRemoveIds.length || this.props.disabled} className="btn btn-danger-outline" onClick={this.handleRemoveTitles}>
                                    <i className="fas fa-trash-alt"></i>&nbsp;{this.context.intl.messages['events.remove.titles']}
                                </Button>
                            </div>
                            <div className="col-md-6 show-grid"></div>
                        </div>
                    </div>
                    <hr />
                    <ListTitlesAG
                        activePage={this.state.currentPage}
                        columns={['ro', 'TitleAndParentRenderer', 'categoryName', 'synopsisUsageType', 'mpmNumber', 'titleReleaseDate']}
                        displayLinks={true}
                        onPageChange={this.handleLoadPage}
                        onSelect={this.handleToggleSelect}
                        handleSynopsisChange={this.handleChangeSynopsisType}
                        sortDirection={'desc'}
                        sortFieldName={'updatedDate'}
                        titles={this.state.titles}
                        toRemoveIds={this.state.toRemoveIds}
                        totalPages={this.state.numberOfPages}
                    />
                </div>
                <AddExistingPanel
                    location={this.props.location}
                />
            </Preloader>
        );
    }
}

export default Container.create(Titles);
