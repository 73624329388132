/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import React, {PropTypes} from 'react';

class Avatar extends React.Component {
    static get propTypes() {
        return {
            name: PropTypes.string.isRequired,
            small: PropTypes.bool
        };
    }

    static get defaultProps() {
        return {
            small: false
        };
    }

    constructor(props) {
        super(props);
    }

    render() {
        const initials = this.props.name.split(' ').map((n)=>n[0]).join('').toUpperCase();

        const avatar = <div className={ClassNames('avatar-wrapper', {'small-avatar': this.props.small})} >
            <span className="avatar" >
                {initials}
            </span>
        </div>;

        return avatar;
    }
}

export default Avatar;
