/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

const GetAvailableTabs = function(tabsAndConditions) {
    const availableTabs = tabsAndConditions.reduce((result, tabsGroup) => {
        if (!tabsGroup.hasPermission) {
            return result;
        }

        tabsGroup.tabs.forEach(tab => {
            result[tab.value] = tabsGroup.canShow(tab.value);
        });
        return result;
    }, {});

    return Immutable.fromJS(availableTabs);
};

const GetTabDataByValue = function(tabsAndConditions, value) {
    return tabsAndConditions.reduce((result, tabsGroup) => {
        const tabFound = tabsGroup.tabs.find(tab => tab.value === value);
        if (tabFound) {
            result = tabFound;
        }
        return result;
    }, {});
};

export {
    GetAvailableTabs,
    GetTabDataByValue
};
