/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';
import {IndexRoute, Route} from 'react-router';

import Browse from './browse/index';
import Create from './create/create';

import Download from '~/src/common/download';
import WithPermissions from '~/src/decorators/with-permissions';
import {RequirePerm} from '~/src/router/router-helpers';
import SessionStore from '~/src/user/session/session-store';

const Stations = <Route path="stations">

    <IndexRoute
        component={WithPermissions(Browse)}
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.STATIONS.CONFIGURATION)}
    />

    <Route
        component={Download}
        path="download-users/:id"
    />

    <Route
        component={WithPermissions(Create)}
        mode="create"
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.STATIONS.CONFIGURATION)}
        path="create"
    />

    <Route
        component={WithPermissions(Create)}
        mode="edit"
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.STATIONS.CONFIGURATION)}
        path=":id"
    >

        <IndexRoute/>
        <Route path="history"/>
        <Route path="users"/>
        <Route path="partners"/>
    </Route>
</Route>;

export default Stations;
