/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Col, ControlLabel, FormGroup, Row} from 'react-bootstrap';

import {RelatedActions} from './related-actions';
import RelatedStore from './related-store';
import BaseSelect from '../../../common/base-select/base-select';
import {AlertTypes} from '../../../common/notification/alert';
import {NotificationActions} from '../../../common/notification/notification-actions';
import {SlidingPanelActions} from '../../../common/sliding-panel/sliding-panel-actions';
import {WithStoreOnRoute} from '../../../common/store-on-route';
import {Debounce} from '../../../common/utils/utils';
import LayoutStore from '../../../layout/layout-store';
import ListTitles from '../../browse/list-titles';
import FilterOptions from '../../filter-options/filter-options';
import {TitleActions} from '../../title-actions';
import TitleStore from '../../title-store';

class AddExistingTitle extends Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            onSelect: PropTypes.func.isRequired,
            relation: PropTypes.oneOf(['children', 'other', 'parents']).isRequired,
            showRelationshipType: PropTypes.bool,
            slidingPanelId: PropTypes.string.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            onSelect: (title, relationshipType, relation) => {
                RelatedActions.addRelatedTitle(title, relationshipType, relation);
            },
            showRelationshipType: true,
        };
    }

    static calculateState() {
        const ts = TitleStore.getState();
        return {
            titles: ts.get('titles'),
            activePage: Math.ceil(ts.get('offset')/ts.get('size')) || 0,
            totalPages: Math.ceil(ts.get('total')/ts.get('size')) || 0,
            showFiltersPanel: LayoutStore.getState().get('showFiltersPanel'),
        };
    }

    static getStores() {
        return [TitleStore, LayoutStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            activePage: 0,
            selectedTitleRelationshipType: undefined,
            size: 20,
            totalPages: 0
        }, this.constructor.calculateState());

        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.handleSearchChange = Debounce(this.handleSearchChange.bind(this), 200);
        this.handleSelectTitleRelationshipType = this.handleSelectTitleRelationshipType.bind(this);
    }

    componentDidMount() {
        TitleActions.get(Immutable.Map({}), 0, this.state.size);
        return;
    }

    componentWillUnmount() {
        this.emptyRouteState();
    }

    /**
     * Handle clicks on pagination controls.
     * @param  {Number} pageNumber page to load.
     */
    handlePageChange(pageNumber) {
        // pageNumber x this.state.size = offset.
        TitleActions.get(Immutable.Map(this.props.location.query), pageNumber * this.state.size, this.state.size);
        return;
    }

    handleRowClick(event) {
        this.emptyRouteState();
        if (~event.target.className.indexOf('add-existing-title') ||
            ~event.target.className.indexOf('fa-plus')) {
            if (this.props.showRelationshipType && !this.state.selectedTitleRelationshipType) {
                NotificationActions.showAlert(
                    AlertTypes.ALERT_WARNING.name,
                    'titles.create.related.errors.select.relationship'
                );
                return;
            }

            let childTitleId = parseInt(event.target.getAttribute('data-title-id'));
            if (~event.target.className.indexOf('fa-plus')) {
                childTitleId = parseInt(event.target.parentNode.getAttribute('data-title-id'));
            }
            let title = this.state.titles.find(t => t.get('id') === childTitleId);
            if (!RelatedStore.isRelatedTitle(childTitleId)) {
                this.props.onSelect(
                    title,
                    this.state.selectedTitleRelationshipType,
                    this.props.relation
                );
                SlidingPanelActions.hide(this.props.slidingPanelId);
            } else {
                NotificationActions.showAlert(AlertTypes.ALERT_WARNING.name, 'titles.create.related.errors.duplicated');
            }
        }
        return;
    }

    handleSearchChange(term) {
        this.setRouteState('title', term)
            .setRouteState('operator', 'AND')
            .clearRouteState('offset')
            .apply();
        TitleActions.get(Immutable.Map(this.props.location.query));
        return;
    }

    /**
     * The RelationshipType select can be stored locally (instead of in a Flux
     * Store like we normally do) because it's a temporal value that is needed
     * for calling the TitleActions.addRelatedTitle action (in the handleRowClick
     * function).
     */
    handleSelectTitleRelationshipType(value) {
        this.setState({
            selectedTitleRelationshipType: Immutable.fromJS(value)
        });
        return;
    }

    render() {
        // List of columns to display in the list-titles table.
        let columns = [
            'mpmNumber', 'title', 'titleReleaseDate',
            'categoryName', 'catalogs',
            {
                name: 'actions',
                // This get function will be invoked in the context of the ListTitles
                // component. The props and context params are the ones of ListTitles,
                // not the ones from this component.
                get: function(title, props, context) {
                    return `<button class="btn btn-primary add-existing-title" data-title-id="${title.get('id')}"><i class="fas fa-plus"></i>&nbsp;${context.intl.messages['titles.create.related.add-existing.add']}</button>`;
                },
                onClick: this.handleRowClick
            }
        ];

        let relationshipType;

        if (this.props.showRelationshipType) {
            let options = 'childrenRelationshipTypes';
            if (this.props.relation === 'parents') {
                options = 'parentsRelationshipTypes';
            }

            relationshipType = (
                <Row>
                    <Col mdOffset={3} md={6}>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['titles.create.related.add-existing.relationship-type']}:</ControlLabel>
                            <BaseSelect
                                disabled={false}
                                name="titleRelationshipType"
                                onChange={this.handleSelectTitleRelationshipType}
                                options={options}
                                store={RelatedStore}
                                value={this.state.selectedTitleRelationshipType}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            );
        }
        let filterOptions;
        if (this.state.showFiltersPanel) {
            filterOptions = <FilterOptions
                location={this.props.location}
            />;
        }

        return (
            <div className={ClassNames({'control-sidebar-open': this.state.showFiltersPanel})}>
                <div>
                    {relationshipType}
                    <ListTitles
                        activePage={this.state.activePage}
                        columns={columns}
                        filterButton
                        location={this.props.location}
                        onPageChange={this.handlePageChange}
                        onSearchChange={this.handleSearchChange}
                        titles={this.state.titles}
                        totalPages={this.state.totalPages}
                    />
                    {filterOptions}
                </div>
            </div>
        );
    }
}

export default WithStoreOnRoute(Container.create(AddExistingTitle));
