/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import jQuery from 'jquery';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

import {PartnerActions} from '../../../partner/partner-actions';

require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('datatables.net-responsive-bs');

// Use the Dimensions decorator to provide with and height properties.
class ApiKeyList extends Component {
    static get propTypes() {
        return {
            apiKeys: PropTypes.instanceOf(Immutable.List).isRequired,
            displayLinks: PropTypes.bool
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
            router: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            displayLinks: false
        };
    }

    constructor(props) {
        super(props);

        this.handleResize = this.handleResize.bind(this);

        this.handleRowClick = this.handleRowClick.bind(this);
    }

    componentDidMount() {
        this.$table = jQuery('#api-keys-table').DataTable({
            autoWidth: false,
            columnDefs: [{
                // Add the control class to the last column. This colum will
                // contain the button to show the responsive data.
                className: 'control',
                targets:   -1,
                width: 20
            }, {
                targets: 'no-sort',
                orderable: false
            }],
            iDisplayLength: 1,
            info: false,
            ordering: false,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        });

        // Register global listeners.
        window.addEventListener('resize', this.handleResize);

        this.initTable(this.props);
        return;

    }

    shouldComponentUpdate(nextProps) {
        return this.props.apiKeys !== nextProps.apiKeys ||
            this.props.displayLinks !== nextProps.displayLinks;
    }

    componentWillUpdate(nextProps) {
        this.initTable(nextProps);
        return;
    }

    componentWillUnmount() {
        // Remove global listeners.
        window.removeEventListener('resize', this.handleResize);
    }

    initTable(props) {
        this.$table.clear();
        // Add data to the jQuery datatable.
        props.apiKeys.forEach((apiKey, index) => {
            let userName = `${apiKey.getIn(['user', 'fullName'])} (${apiKey.getIn(['user', 'email'])})`;
            if (props.displayLinks) {
                userName = `<a href="/accounts/${apiKey.get('userId')}" class="navigate-user">${userName}</a>`;
            }

            this.$table.row.add([
                apiKey.get('apiKey'),
                userName,
                `<a href="#"><i class="delete-api-key fas fa-trash-alt" index=${index}></i></a>`,
                ''
            ]);
            return;
        });

        this.$table.draw(false);
        // Now, since the data has changed the columns widths, trigger
        // the resize handler in order to update the responsive feature.
        this.handleResize();
    }

    /**
     * Update the datatable columns size.
     */
    handleResize() {
        this.$table.responsive.recalc();
        return;
    }

    handleRowClick(event) {
        switch (true) {
        case !!~event.target.className.indexOf('navigate-user'):
            event.preventDefault();
            this.context.router.push(event.target.getAttribute('href'));
            break;
        case !!~event.target.className.indexOf('delete-api-key'):
            event.preventDefault();
            PartnerActions.deletePartnerApiKey(parseInt(event.target.getAttribute('index')));
            break;
        }
        return;
    }

    render() {
        return (
            <div>
                <Button className="pull-right"
                    bsStyle="primary"
                    onClick={PartnerActions.showAddApiKeyModal}>
                    <i className="fas fa-plus"></i>&nbsp;{this.context.intl.messages['authorized-partners.api-keys.modal.title']}
                </Button>
                <table id="api-keys-table" className="table table-bordered table-striped responsive">
                    <thead>
                        <tr>
                            <th className="no-sort">Api Key</th>
                            <th className="no-sort">User</th>
                            <th className="no-sort"><i className="fas fa-trash-alt"></i></th>
                            <th className="no-sort"></th>
                        </tr>
                    </thead>
                    <tbody onClick={this.handleRowClick}>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default ApiKeyList;
