/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

// import ActiveSessionsTable from './active-sessions-table';

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Col, Row, Modal} from 'react-bootstrap';

import IPAddressesTable from './ip-addresses-table';
import SetPasswordModal from './password/set-password-modal';
import VideoPlaybackRestrictionsTable from './video-playback-restrictions-table';

import {PermissionsAuditActions} from '~/src/accounts/create/permissions-audit/permissions-audit-actions';
import PermissionsAuditStore from '~/src/accounts/create/permissions-audit/permissions-audit-store';
import {MODAL_TYPE} from '~/src/common/notification/modal';
import {NotificationActions} from '~/src/common/notification/notification-actions';
import Preloader from '~/src/preloader';
import {UserActions, UserConstants} from '~/src/user/user-actions';
import UserStore from '~/src/user/user-store';

import 'react-table/react-table.css';

/**
 * Management Tab and its content.
 */
class ManagementUser extends React.Component {
    static get propTypes() {
        return {
            banUserAllowed: PropTypes.bool.isRequired,
            disabled: PropTypes.bool,
            onUnlock: PropTypes.func.isRequired,
            passwordResetAllowed: PropTypes.bool.isRequired,
            setPasswordAllowed: PropTypes.bool.isRequired,
            unlockUserAllowed: PropTypes.bool.isRequired,
            userEmail: PropTypes.string.isRequired,
            userId: PropTypes.string.isRequired,
        };
    }
    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }
    static calculateState() {
        return {
            permissionsData: PermissionsAuditStore.getState().get('permissionsData'),
            isSendingMFAEnrollmentEmail: UserStore.getState().get('isSendingMFAEnrollmentEmail'),
            passwordResetLink: UserStore.getState().get('passwordResetLink'),
            restrictedVideos: UserStore.getState().get('restrictedVideos'),
            restrictedIPVideos: UserStore.getState().get('restrictedIPVideos'),
            showPreloader: UserStore.getState().get('showPreloader'),
            user: UserStore.getState().get('user'),
            userSessionCount: UserStore.getState().get('userSessionCount'),
        };
    }

    static getStores() {
        return [UserStore];
    }

    static get defaultProps() {
        return {
            disabled: true
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            banUserReason: '',
            deactivateUserNotes: '',
            resetIPCountAssetName: undefined,
            resetIPCountVideoId: undefined,
            resetViewsAssetName: undefined,
            resetViewsVideoId: undefined,
            showResetIPCountModal: false,
            showResetViewsModal: false,
            showSetPasswordModal: false,
        }, this.constructor.calculateState());

        this.handleBanUser = this.handleBanUser.bind(this);
        this.handleBanUserReasonChange = this.handleBanUserReasonChange.bind(this);
        this.handleClearOktaHistory = this.handleClearOktaHistory.bind(this);
        this.handleDeactivateUser = this.handleDeactivateUser.bind(this);
        this.handleDeactivateUserNoteChange = this.handleDeactivateUserNoteChange.bind(this);
        this.handleEndSessions = this.handleEndSessions.bind(this);
        this.handleGenerateLink = this.handleGenerateLink.bind(this);
        this.handleHideSetPasswordModal = this.handleHideSetPasswordModal.bind(this);
        this.handlePasswordReset = this.handlePasswordReset.bind(this);
        this.handleReactivateUser = this.handleReactivateUser.bind(this);
        this.handleResetIPCount = this.handleResetIPCount.bind(this);
        this.handleResetIPCountConfirm = this.handleResetIPCountConfirm.bind(this);
        this.handleResetViews = this.handleResetViews.bind(this);
        this.handleResetViewsConfirm = this.handleResetViewsConfirm.bind(this);
        this.handleSetPasswordModal = this.handleSetPasswordModal.bind(this);
        this.handleShowSetPasswordModal = this.handleShowSetPasswordModal.bind(this);
        this.handleUnbanUser = this.handleUnbanUser.bind(this);
        this.handleUnlockUser = this.handleUnlockUser.bind(this);
        this.isUserBanned = this.isUserBanned.bind(this);
        this.onHideResetIPCountModal = this.onHideResetIPCountModal.bind(this);
        this.onHideResetViewsModal = this.onHideResetViewsModal.bind(this);
        this.sendEnrollmentEmail = this.sendEnrollmentEmail.bind(this);
        this.showBanUserModal = this.showBanUserModal.bind(this);
        this.showClearOktaHistoryModal = this.showClearOktaHistoryModal.bind(this);
        this.showDeactivateUserModal = this.showDeactivateUserModal.bind(this);
        this.showEndSessionsModal = this.showEndSessionsModal.bind(this);
    }

    componentWillMount() {
        // Request user session country
        UserActions.getSessionCount(this.props.userId);
        UserActions.getRestrictedVideoWatches(this.props.userId);
        UserActions.getRestrictedIPVideoWatches(this.props.userId);
        PermissionsAuditActions.getPermissions(this.props.userId);
    }

    handleBanUser() {
        UserActions.banUser(this.props.userId, this.state.banUserReason);
    }

    handleBanUserReasonChange(e) {
        e.persist();
        this.setState(() => ({
            banUserReason: e.target.value
        }));
        return;
    }


    showBanUserModal(event) {
        event.preventDefault();
        const confirmMessage = (
            <div className="form-group gray-wrapper">
                <label className="control-label"><i className="fas fa-pencil-alt"></i> {this.context.intl.messages['accounts.create.management.ban-user.ban-reason']}</label>
                <textarea maxLength="4000" className="form-control" onChange={this.handleBanUserReasonChange}></textarea>
            </div>
        );

        NotificationActions.show(
            MODAL_TYPE.DANGER,
            this.context.intl.messages['accounts.create.management.ban-user.title'],
            confirmMessage,
            this.context.intl.messages['accounts.create.management.ban-user.button'],
            this.handleBanUser
        );
        return;
    }

    /**
     * Send actions to clear okta history
     */
    handleClearOktaHistory() {
        const options = {
            messages: {
                error: 'accounts.create.management.clear-okta-history.error',
                success: 'accounts.create.management.clear-okta-history.success'
            }
        };

        UserActions.clearOktaHistory(this.props.userId, options);
        return;
    }

    /**
     * Send actions to deactivate the user.
     */
    handleDeactivateUser() {
        // Update the user and show a custom message.
        const options = {
            messages: {
                error: 'accounts.create.management.deactivate.error',
                success: 'accounts.create.management.deactivate.success'
            }
        };

        // End all active sessions (if any)
        if (this.state.userSessionCount) {
            this.handleEndSessions();
        }

        UserActions.deactivate(this.props.userId, this.state.deactivateUserNotes, options);
        return;
    }

    /**
     * Set the deactivation note for the user
     */
    handleDeactivateUserNoteChange(e) {
        e.persist();
        this.setState(() => ({
            deactivateUserNotes: e.target.value
        }));
        return;
    }

    /**
     * Send actions to end all active sessions
     */
    handleEndSessions() {
        // Ends all active sessions for a user
        const options = {
            messages: {
                error: 'accounts.create.management.end-active-sessions.error',
                success: 'accounts.create.management.end-active-sessions.success'
            }
        };

        UserActions.endActiveSessions(this.props.userId, options);
        return;
    }

    /**
     * Send actions to generate password reset link (do not email client)
     */
    handleGenerateLink() {
        const options = {
            messages: {
                error: 'accounts.create.management.password-reset-link.error',
                success: ''
            }
        };

        UserActions.resetPassword(this.props.userId, options);
        return;
    }

    /**
     * Hides create password modal.
     */
    handleHideSetPasswordModal() {
        this.setState(() => ({
            showSetPasswordModal: false
        }));
        return;
    }

    /**
     * Send actions to send password reset email to client
     */
    handlePasswordReset() {
        const options = {
            messages: {
                error: 'accounts.create.management.password-reset.error',
                success: 'accounts.create.management.password-reset.success'
            }
        };

        UserActions.resetPasswordWithEmail(this.props.userId, this.props.userEmail, options);
        return;
    }

    /**
     * Send actions to reactivate a user account
     */
    handleReactivateUser() {
        UserActions.reactivate(this.props.userId);
        return;
    }

    /**
     * Reset ip count button - open confirmation dialog
     */
    handleResetIPCount(videoId, assetName) {
        this.setState(() => ({
            resetIPCountAssetName: assetName,
            resetIPCountVideoId: videoId,
            showResetIPCountModal: true,
        }));

        return;
    }

    /**
     * Reset view count button - open confirmation dialog
     */
    handleResetViews(videoId, assetName) {
        this.setState(() => ({
            resetViewsAssetName: assetName,
            resetViewsVideoId: videoId,
            showResetViewsModal: true,
        }));

        return;
    }

    /**
     * Reset view count for user/asset
     */
    handleResetViewsConfirm() {
        const options = {
            messages: {
                error: 'accounts.create.management.video-playback-restrictions.reset-views.error',
                success: 'accounts.create.management.video-playback-restrictions.reset-views.success'
            }
        };

        UserActions.resetRestrictedVideoViews(this.props.userId, this.state.resetViewsVideoId, options);
        UserActions.getRestrictedVideoWatches(this.props.userId);

        this.setState(() => ({
            resetViewsVideoId: undefined,
            resetViewsAssetName: undefined,
            showResetViewsModal: false,
        }));

        return;
    }

    /**
     * Reset ip count for user/asset
     */
    handleResetIPCountConfirm() {
        const options = {
            messages: {
                error: 'accounts.create.management.video-playback-restrictions.reset-ip-count.error',
                success: 'accounts.create.management.video-playback-restrictions.reset-ip-count.success'
            }
        };

        UserActions.resetRestrictedVideoIPCount(this.props.userId, this.state.resetIPCountVideoId, options);
        UserActions.getRestrictedIPVideoWatches(this.props.userId);

        this.setState(() => ({
            resetIPCountVideoId: undefined,
            resetIPCountAssetName: undefined,
            showResetIPCountModal: false,
        }));

        return;
    }


    /**
     * Send Actions to set password.
     */
    handleSetPasswordModal(newPassword) {
        const options = {
            messages: {
                error: 'accounts.create.management.password-create.error',
                success: 'accounts.create.management.password-create.success'
            }
        };

        UserActions.resetPasswordAsAdmin(this.props.userId, newPassword, options);
        return;
    }

    /**
     * Shows create password modal.
     */
    handleShowSetPasswordModal() {
        this.setState(() => ({
            showSetPasswordModal: true
        }));
        return;
    }

    handleUnbanUser() {
        UserActions.unbanUser(this.props.userId);
    }

    /**
     * Send actions to unlock a user account
     */
    handleUnlockUser() {
        const options = {
            messages: {
                error: 'accounts.create.management.unlock-user.error',
                success: 'accounts.create.management.unlock-user.success'
            }
        };

        if (this.state.user.get('accountStatus') === UserConstants.STATUS.TEMP_LOCKED.id) {
            UserActions.updateUser('accountStatus', UserConstants.STATUS.ACTIVE.id);
            this.props.onUnlock();
            return;
        }

        UserActions.unlockUser(this.props.userId, options);
        return;
    }

    isUserBanned(user) {
        return user?.get('accountStatus') === UserConstants.STATUS.BANNED.id;
    }

    /**
     * Hide the reset views confirmation dialog
     */
    onHideResetViewsModal() {
        this.setState(() => ({
            resetViewsAssetName: undefined,
            resetViewsVideoId: undefined,
            showResetViewsModal: false,
        }));
    }

    /**
     * Hide the reset ip count confirmation dialog
     */
    onHideResetIPCountModal() {
        this.setState(() => ({
            resetIPCountAssetName: undefined,
            resetIPCountVideoId: undefined,
            showResetIPCountModal: false,
        }));
    }

    /**
     * Send enrollment email to user
     */
    sendEnrollmentEmail() {
        UserActions.sendEnrollmentEmail(this.props.userId);
        return;
    }

    /**
     * Show clear okta history confirmation modal
     */
    showClearOktaHistoryModal(event) {
        event.preventDefault();
        const confirmMessage = (
            <div>
                <p>{this.context.intl.messages['accounts.create.management.clear-okta-history.modal.message']}</p>
            </div>
        );

        NotificationActions.show(
            MODAL_TYPE.DEFAULT,
            this.context.intl.messages['accounts.create.management.clear-okta-history.title'],
            confirmMessage,
            this.context.intl.messages['common.confirm'],
            this.handleClearOktaHistory
        );
        return;
    }

    /**
     * Show user deactivation confirmation modal
     */
    showDeactivateUserModal(event) {
        event.preventDefault();
        const confirmMessage = (
            <div className="form-group gray-wrapper">
                <label className="control-label"><i className="fas fa-pencil-alt"></i> {this.context.intl.messages['accounts.create.management.deactivate.modal.message']}</label>
                <textarea maxLength="4000" className="form-control" onChange={this.handleDeactivateUserNoteChange}></textarea>
            </div>
        );

        NotificationActions.show(
            MODAL_TYPE.DANGER,
            this.context.intl.messages['accounts.create.management.deactivate.modal.title'],
            confirmMessage,
            this.context.intl.messages['accounts.create.management.deactivate.modal.confirm'],
            this.handleDeactivateUser
        );
        return;
    }

    /**
     * Show end all active sessions confirmation modal
     */
    showEndSessionsModal(event) {
        event.preventDefault();
        const confirmMessage = (
            <div>
                <p>{this.context.intl.messages['accounts.create.management.end-active-sessions.modal.message']}</p>
            </div>
        );

        NotificationActions.show(
            MODAL_TYPE.DEFAULT,
            this.context.intl.messages['accounts.create.management.end-active-sessions.title'],
            confirmMessage,
            this.context.intl.messages['common.confirm'],
            this.handleEndSessions
        );
        return;
    }

    render() {
        let activateAction;
        let activateButton;
        let activateIcon;

        if (this.state.user && [UserConstants.STATUS.REMOVED.id, UserConstants.STATUS.DENIED.id].includes(this.state.user.get('accountStatus'))) {
            activateAction = 'reactivate';
            activateButton = <Button bsStyle="success" bsSize="large" disabled={this.props.disabled} onClick={this.handleReactivateUser}>
                <i className="fas fa-check-square"></i>&nbsp;{this.context.intl.messages[`accounts.create.management.${activateAction}`]}
            </Button>;
            activateIcon = 'fas fa-check-square';
        } else {
            activateAction = 'deactivate';
            activateButton = <Button bsStyle="danger" bsSize="large" disabled={this.props.disabled} onClick={this.showDeactivateUserModal}>
                <i className="fas fa-times"></i>&nbsp;{this.context.intl.messages[`accounts.create.management.${activateAction}`]}
            </Button>;
            activateIcon = 'fas fa-times';
        }

        let activeSessionsMessage = (
            <span>{this.context.intl.messages['accounts.create.management.end-active-sessions.message']}
                &nbsp;<strong>{this.state.userSessionCount}</strong>&nbsp;
                {this.context.intl.messages['accounts.create.management.end-active-sessions.sessions']}
            </span>
        );

        let passwordLinkContainer = '';
        if (this.state.passwordResetLink) {
            passwordLinkContainer = (
                <div id="password-link-container">
                    <p><em>{this.context.intl.messages['accounts.create.management.password-reset-link.linkmessage']}</em></p>
                    <input type="text" value={this.state.passwordResetLink} className="form-control" readOnly="true" />
                </div>
            );
        }

        let unlockUserMessage = 'unlocked';
        if (this.state.user !== undefined && this.state.user.get('validations') !== undefined) {
            if (this.state.user.get('validations').find(m => m.get('validationType') === 'AccountLocked')) {
                unlockUserMessage = 'locked';
            }
        }
        const isUserUnlocked = (unlockUserMessage === 'unlocked' && this.state.user.get('accountStatus') !== UserConstants.STATUS.TEMP_LOCKED.id);
        if (unlockUserMessage === 'unlocked' && !isUserUnlocked) {
            unlockUserMessage = 'temp-locked';
        }

        const setPasswordModal = (
            <SetPasswordModal
                onHide={this.handleHideSetPasswordModal}
                onSetPassword={this.handleSetPasswordModal}
                show={this.state.showSetPasswordModal}
                user={this.state.user}
            />
        );

        let banOrUnbanUserColumn = (
            <Col md={4}>
                <h3><i className="fas fa-ban"></i>&nbsp;{this.context.intl.messages['accounts.create.management.ban-user.title']}</h3>
                <p><em>{this.context.intl.messages['accounts.create.management.ban-user.message']}</em></p>
                <Button
                    bsStyle="danger"
                    bsSize="large"
                    disabled={this.props.disabled || !this.props.banUserAllowed}
                    onClick={this.showBanUserModal}
                >
                    <i className="fas fa-ban"></i>&nbsp;{this.context.intl.messages['accounts.create.management.ban-user.button']}
                </Button>
            </Col>
        );

        if (this.isUserBanned(this.state.user)) {
            banOrUnbanUserColumn = (
                <Col md={4}>
                    <h3><i className="fas fa-ban"></i>&nbsp;{this.context.intl.messages['accounts.create.management.unban-user.title']}</h3>
                    <p><em>{this.context.intl.messages['accounts.create.management.unban-user.message']}</em></p>
                    <Button
                        bsStyle="primary"
                        bsSize="large"
                        disabled={this.props.disabled || !this.props.banUserAllowed}
                        onClick={this.handleUnbanUser}
                    >
                        <i className="fas fa-ban"></i>&nbsp;{this.context.intl.messages['accounts.create.management.unban-user.button']}
                    </Button>
                </Col>
            );

        }

        const setPasswordColumn = (
            <Col md={6}>
                <h3><i className="fas fa-ellipsis-h"></i>&nbsp;{this.context.intl.messages['accounts.create.management.password-create.title']}</h3>
                <p><em>{this.context.intl.messages['accounts.create.management.password-create.message']}</em></p>
                <Button
                    bsStyle="primary"
                    bsSize="large"
                    disabled={this.props.disabled || !this.props.setPasswordAllowed}
                    onClick={this.handleShowSetPasswordModal}
                >
                    <i className="fas fa-ellipsis-h"></i>
                    &nbsp;{this.context.intl.messages['accounts.create.management.password-create.title']}
                </Button>
            </Col>
        );

        const confirmResetViewsModal = (
            <Modal show={this.state.showResetViewsModal} onHide={this.onHideResetViewsModal}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="text-center" >{this.context.intl.messages['accounts.create.management.reset-view-count']}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {this.context.intl.messages['accounts.create.management.reset-view-count.description-prepend']}
                        <strong>
                            {this.state.resetViewsAssetName}
                        </strong>
                        {this.context.intl.messages['accounts.create.management.reset-view-count.description-append']}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-default-outline pull-left" onClick={this.onHideResetViewsModal}>
                        <i className="fas fa-ban" /> {this.context.intl.messages['common.cancel']}</Button>
                    <Button className="btn btn-primary-outline" onClick={this.handleResetViewsConfirm}>
                        <i className="far fa-undo" /> {this.context.intl.messages['accounts.create.management.reset-view-count']}
                    </Button>
                </Modal.Footer>
            </Modal>
        );

        const confirmResetIPCountModal = (
            <Modal show={this.state.showResetIPCountModal} onHide={this.onHideResetIPCountModal}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="text-center" >{this.context.intl.messages['accounts.create.management.reset-ip-count']}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {this.context.intl.messages['accounts.create.management.reset-ip-count.description-prepend']}
                        <strong>
                            {this.state.resetIPCountAssetName}
                        </strong>
                        {this.context.intl.messages['accounts.create.management.reset-ip-count.description-append']}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-default-outline pull-left" onClick={this.onHideResetIPCountModal}>
                        <i className="fas fa-ban" /> {this.context.intl.messages['common.cancel']}</Button>
                    <Button className="btn btn-primary-outline" onClick={this.handleResetIPCountConfirm}>
                        <i className="far fa-undo" /> {this.context.intl.messages['accounts.create.management.reset-ip-count']}
                    </Button>
                </Modal.Footer>
            </Modal>
        );

        const tables = <div>
            <Row>
                <Col md={12}>
                    <h3><i className="fas fa-video-slash"></i>&nbsp;{this.context.intl.messages['accounts.create.management.video-playback-restrictions']}</h3>
                    <h4>{this.context.intl.messages['accounts.create.management.video-playback-restrictions.video-count']}</h4>
                    <VideoPlaybackRestrictionsTable
                        handleResetViews={this.handleResetViews}
                        onSortChange={() => void 0}
                        sortDirection="asc"
                        sortFieldName="assetName"
                        videos={this.state.restrictedVideos}
                    />
                </Col>
            </Row>
            <hr />
            <Row>
                <Col md={12}>
                    <h4>{this.context.intl.messages['accounts.create.management.video-playback-restrictions.ip-addresses']}</h4>
                    <IPAddressesTable
                        handleResetIPCount={this.handleResetIPCount}
                        onSortChange={() => void 0}
                        sortDirection="asc"
                        permissionsData={this.state.permissionsData}
                        sortFieldName="assetName"
                        videos={this.state.restrictedIPVideos}
                    />
                </Col>
            </Row>
            { /* TODO: IMPLEMENT ACTIVE SESSIONS TABLE
            <hr />
            <Row>
                <Col md={12}>
                    <h3><i className="fas fa-power-off"></i>&nbsp;{this.context.intl.messages['accounts.create.management.active-sessions']}</h3>
                    <ActiveSessionsTable
                        onSortChange={() => void 0}
                        sortDirection="asc"
                        sortFieldName="sessionId"
                        sessions={Immutable.List()}
                    />
                </Col>
            </Row> */ }
        </div>;

        return (
            <div className="tab-pane padding-x-20 padding-bottom-20">
                <Preloader show={this.state.showPreloader} fixed loadingDots>
                    <section className="content">
                        <Row className="centered-content">
                            {this.props.setPasswordAllowed && setPasswordColumn}
                        </Row>
                        <hr />
                        <Row>
                            <Col md={4}>
                                <h3><i className="fas fa-unlock-alt"></i>&nbsp;{this.context.intl.messages['accounts.create.management.password-reset.title']}</h3>
                                <p><em>{this.context.intl.messages['accounts.create.management.password-reset.message']}</em></p>
                                <Button
                                    bsStyle="primary"
                                    bsSize="large"
                                    className="margin-bottom-20"
                                    disabled={this.props.disabled || !this.props.passwordResetAllowed}
                                    onClick={this.handlePasswordReset}><i className="fas fa-envelope"></i>&nbsp;{this.context.intl.messages['accounts.create.management.password-reset.button']}</Button>
                            </Col>

                            <Col md={4}>
                                <h3><i className="fas fa-link"></i>&nbsp;{this.context.intl.messages['accounts.create.management.password-reset-link.title']}</h3>
                                <p><em>{this.context.intl.messages['accounts.create.management.password-reset-link.message']}</em></p>
                                <Button
                                    bsStyle="primary"
                                    className="bg-navy margin-bottom-20"
                                    bsSize="large"
                                    disabled={this.props.disabled || !this.props.passwordResetAllowed}
                                    onClick={this.handleGenerateLink}><i className="fas fa-link"></i>&nbsp;{this.context.intl.messages['accounts.create.management.password-reset-link.button']}</Button>

                                {passwordLinkContainer}
                            </Col>

                            <Col md={4}>
                                <h3><i className="fas fa-unlock"></i>&nbsp;{this.context.intl.messages['accounts.create.management.unlock-user.title']}</h3>
                                <p><em>{this.context.intl.messages[`accounts.create.management.unlock-user.message.${unlockUserMessage}`]}</em></p>
                                <Button
                                    bsStyle="primary"
                                    bsSize="large"
                                    disabled={this.props.disabled || !this.props.unlockUserAllowed || isUserUnlocked}
                                    onClick={this.handleUnlockUser}><i className="fas fa-unlock"></i>&nbsp;{this.context.intl.messages['accounts.create.management.unlock-user.button']}</Button>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col md={4}>
                                <h3><i className="fas fa-anchor"></i>&nbsp;{this.context.intl.messages['accounts.create.management.end-active-sessions.title']}</h3>
                                <p><em>{activeSessionsMessage}</em></p>
                                <Button
                                    bsStyle="primary"
                                    bsSize="large"
                                    disabled={this.props.disabled}
                                    onClick={this.showEndSessionsModal}><i className="fas fa-anchor"></i>&nbsp;{this.context.intl.messages['accounts.create.management.end-active-sessions.button']}</Button>
                            </Col>
                            <Col md={4}>
                                <h3><i className={ClassNames(activateIcon)}></i>&nbsp;{this.context.intl.messages[`accounts.create.management.${activateAction}`]}</h3>
                                <p><em>{this.context.intl.messages[`accounts.create.management.${activateAction}-message`]}</em></p>
                                {activateButton}
                            </Col>
                            <Col md={4}>
                                <h3><i className="fas fa-address-card"></i>&nbsp;{this.context.intl.messages['accounts.create.management.clear-okta-history.button']}</h3>
                                <p><em>{this.context.intl.messages['accounts.create.management.clear-okta-history.message']}</em></p>
                                <Button
                                    bsStyle="primary"
                                    bsSize="large"
                                    disabled={this.props.disabled}
                                    onClick={this.showClearOktaHistoryModal}><i className="fas fa-address-card"></i>&nbsp;{this.context.intl.messages['accounts.create.management.clear-okta-history.button']}
                                </Button>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col md={4}>
                                <h3><i className="fas fa-mobile-alt"></i>&nbsp;{this.context.intl.messages['accounts.create.management.resend-mfa']}</h3>
                                <p><em>{this.context.intl.messages['accounts.create.management.resend-mfa.message']}</em></p>
                                <Button
                                    bsStyle="primary"
                                    bsSize="large"
                                    disabled={this.props.disabled || this.state.isSendingMFAEnrollmentEmail}
                                    onClick={this.sendEnrollmentEmail}
                                >
                                    <i className="fas fa-mobile-alt"></i>&nbsp;{this.context.intl.messages['accounts.create.management.resend-mfa']}
                                </Button>
                            </Col>
                            {banOrUnbanUserColumn}
                        </Row>
                        <hr />
                        {tables}
                    </section>
                </Preloader>
                {this.props.setPasswordAllowed && setPasswordModal}
                {confirmResetViewsModal}
                {confirmResetIPCountModal}
            </div>

        );
    }
}

export default Container.create(ManagementUser);
