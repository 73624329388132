/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import AddTitlesDialog from './add-titles-dialog';
import {SlidingPanelActions} from '../../../common/sliding-panel/sliding-panel-actions';
import {Debounce} from '../../../common/utils/utils';
import ListTitles from '../../browse/list-titles';
import {TitleActions, TitleConstants} from '../../title-actions';
import TitleStore from '../../title-store';
import {WorkOrdersActions} from '../work-orders-actions';
import WorkOrdersStore from '../work-orders-store';

class AddTitle extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            slidingPanelId: PropTypes.string.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            titles: TitleStore.getState().get('titles'),
            filters: TitleStore.getState().get('filters'),
            offset: TitleStore.getState().get('offset'),
            total: TitleStore.getState().get('total'),
            size: TitleStore.getState().get('size'),
            selectedTitle: WorkOrdersStore.getState().get('selectedTitle'),
            showAddTitleDialog: WorkOrdersStore.getState().get('showAddTitleDialog')
        };
    }

    static getStores() {
        return [TitleStore, WorkOrdersStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            offset: 0,
            size: 20,
            selectedTitle: Immutable.Map(),
            showAddTitleDialog: false,
            total: 0
        }, this.constructor.calculateState());

        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.handleSearchChange = Debounce(this.handleSearchChange.bind(this), 200);
    }

    componentDidMount() {
        TitleActions.clearFilter();
        TitleActions.get(TitleStore.getState().get('filters'), 0, 20);
        return;
    }

    /**
     * Handle clicks on pagination controls.
     * @param  {Number} pageNumber page to load.
     */
    handlePageChange(pageNumber) {
        // pageNumber x this.state.size = offset.
        TitleActions.get(
            TitleStore.getState().get('filters'),
            pageNumber * this.state.size,
            this.state.size
        );
        return;
    }

    handleRowClick(event) {
        if (~event.target.className.indexOf('add-existing-title') ||
            ~event.target.className.indexOf('fa-plus')) {
            let titleId = parseInt(event.target.getAttribute('data-title-id'));
            if (~event.target.className.indexOf('fa-plus')) {
                titleId = parseInt(event.target.parentNode.getAttribute('data-title-id'));
            }
            let title = TitleStore.getState().get('titles').find(t => t.get('id') === titleId);
            switch (TitleActions.getCategoryGroup(title.get('category'))) {
            case TitleConstants.TITLE_CATEGORY_GROUPS.EPISODE:
            case TitleConstants.TITLE_CATEGORY_GROUPS.SEASON:
            case TitleConstants.TITLE_CATEGORY_GROUPS.SERIES:
                WorkOrdersActions.show(title);
                break;
            default:
                WorkOrdersActions.addTitles([titleId]);
                SlidingPanelActions.hide(this.props.slidingPanelId);
            }
        }
        return;
    }

    handleSearchChange(term) {
        TitleActions.clearFilter();
        TitleActions.setFilter('title', term);
        TitleActions.get(TitleStore.getState().get('filters'), 0, this.state.size);
        return;
    }

    render() {
        // List of columns to display in the list-titles table.
        let columns = [
            'ro', 'parentTitle', 'title2', 'categoryName',
            'mpmNumber', 'titleReleaseDate',
            {
                name: 'actions',
                header: function() {
                    return <th className="actions" key="actions">Add Title</th>;
                },
                // This get function will be invoked in the context of the ListTitles
                // component. The props and context params are the ones of ListTitles,
                // not the ones from this component.
                // titles.work-orders.export.add-titles
                get: function(title, props, context) {
                    return `<button class="btn btn-primary add-existing-title" data-title-id="${title.get('id')}"><i class="fas fa-plus"></i>&nbsp;${context.intl.messages['titles.work-orders.export.add-titles.add']}</button>`;
                },
                onClick: this.handleRowClick
            }
        ];
        return (
            <div>
                <AddTitlesDialog show={this.state.showAddTitleDialog} title={this.state.selectedTitle} slidingPanelId={this.props.slidingPanelId}/>
                <ListTitles
                    activePage={Math.ceil((this.state.offset || 0) / this.state.size ) || 0}
                    columns={columns}
                    location={this.props.location}
                    onPageChange={this.handlePageChange}
                    onSearchChange={this.handleSearchChange}
                    titles={this.state.titles}
                    totalPages={Math.ceil(this.state.total/20) || 0}
                />
            </div>
        );
    }

}

export default Container.create(AddTitle);
