/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';

export default createReactClass({
    contextTypes: {
        onToggleSidebar: PropTypes.func.isRequired,
    },

    handleClick: function(event) {
        event.preventDefault();

        this.context.onToggleSidebar();

        return;
    },

    render() {
        return (
            <a href="#" className="sidebar-toggle" data-toggle="offcanvas" role="button" onClick={this.handleClick}>
                <span className="sr-only">Toggle navigation</span>
            </a>
        );
    },
});
