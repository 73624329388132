/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

import PhotoPreview from './photo-preview/photo-preview';
import Summary from './summary/summary';
import RouteValidator from '../../../common/custom-validator/route/route';
import DocumentTitle from '../../../common/document-title';
import FileInput from '../../../common/file-input';
import Panel from '../../../common/panel/panel';
import {Tab, Tabs} from '../../../common/routing-tab/routing-tab';
import {History, SideNotes} from '../../../common/side-notes/side-notes';
import {AlertsWarnings} from '../../../common/validations/alerts-warnings';
import ActionsMenu from '../../../layout/actions-menu/actions-menu';
import StickyHeader from '../../../layout/sticky-header/sticky-header';
import Preloader from '../../../preloader';
import {RouterActions} from '../../../router/router-actions';
import {ActionHistoryActions, ActionHistoryConstants} from '../../../system/action-history/action-history-actions';
import SessionStore from '../../../user/session/session-store';
import {AssetActions} from '../../asset-actions';
import {AssetTalentActions} from '../../asset-talent-actions';
import AssetTalentStore from '../../asset-talent-store';
import AssetUserStore from '../../asset-user-store';
import AssetCatalogStore from '../../catalogs/asset-catalog-store';
import CatalogsTab from '../../catalogs/catalogs';
import TalentTab from '../../talent/talent';
import TitleTab from '../../title/title';
import UserTab from '../../user/user';
import {MerchandiseActions} from '../merchandise-actions';
import MerchandiseStore from '../merchandise-store';


class Merchandise extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired,
            params: PropTypes.object.isRequired,
            route: RouteValidator
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            route: undefined
        };
    }

    static getPermissions() {
        return {
            canCreate: SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.MERCHANDISE.CREATE),
            canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.MERCHANDISE.EDIT),
            canReadCatalogs: SessionStore.canUser(SessionStore.PERMISSIONS.CATALOGS.ASSET.READ),
            canReadTalent: SessionStore.canUser(SessionStore.PERMISSIONS.TALENT.READ),
            canReadTitles: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.READ),
            canReadUsers: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.READ)
        };
    }

    static calculateState() {
        return {
            asset: MerchandiseStore.getState().get('asset'),
            history: MerchandiseStore.getState().get('history'),
            originalAsset: MerchandiseStore.getState().get('originalAsset'),
            originalFile: MerchandiseStore.getState().get('originalFile'),
            showPreloader: MerchandiseStore.getState().get('showPreloader'),
            showUserPreloader: AssetUserStore.getState().get('showPreloader')
        };
    }

    static getStores() {
        return [AssetUserStore, MerchandiseStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            activeTab:0
        }, this.constructor.calculateState());

        this.addNote = this.addNote.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleElasticSearchSync = this.handleElasticSearchSync.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.isDirty = this.isDirty.bind(this);
        this.onHide = this.onHide.bind(this);
        this.remove = this.remove.bind(this);
        this.showModal = this.showModal.bind(this);
    }

    componentDidMount() {
        this.init(this.props);
        return;
    }

    addNote(description) {
        ActionHistoryActions.addNote(this.props.params.id, description, ActionHistoryConstants.ACTION_OBJECTS.MERCHANDISE);
    }

    handleCancel() {
        RouterActions.redirect('/assets', true);
    }

    handleElasticSearchSync() {
        AssetActions.syncElasticSearch(this.props.params.id);
    }

    handleFileChange(e) {
        MerchandiseActions.update('files', [...e.target.files]);
    }

    handleSave() {
        let options = {
            messages: {
                error: `asset.merchandise.${this.props.route.mode}.error`,
                success: `asset.merchandise.${this.props.route.mode}.success`
            }
        };

        const merchandiseFile = this.state.asset.get('files') || [];

        MerchandiseActions.save(this.state.asset,
            MerchandiseStore.getState().get('originalAsset'),
            merchandiseFile,
            AssetCatalogStore.getState().get('assignedCatalogs'),
            AssetCatalogStore.getState().get('originalAssignedCatalogs'),
            AssetTalentStore.getState().get('talent'),
            AssetTalentStore.getState().get('originalTalent'),
            options,
            this.state.originalFile);
        return;
    }

    isDirty() {
        if (this.props.route.mode === 'create') {
            return false;
        }

        if (location) {
            let goingTo = location.pathname.split('/');
            let current = this.props.location.pathname.split('/');
            if (goingTo[1] === current[1] && /* asset */
                goingTo[2] === current[2] && /* merchandise */
                goingTo[3] === current[3]) /* asset id */ {
                return false; // just changing tabs no confirmation needed.
            }
        }
        return !this.state.asset.equals(this.state.originalAsset) ||
        !AssetCatalogStore.getState().get('assignedCatalogs').equals(AssetCatalogStore.getState().get('originalAssignedCatalogs')) ||
        !AssetTalentStore.getState().get('talent').equals(AssetTalentStore.getState().get('originalTalent'));
    }

    init(props) {
        if (props.route.mode === 'edit') {
            MerchandiseActions.findById(props.params.id);
        } else {
            MerchandiseActions.clear();
            AssetTalentActions.clear();
        }
        RouterActions.registerRedirectCheck(() => {
            return this.isDirty();
        });
    }

    onHide() {
        this.setState({
            showModal: false
        });
    }

    remove() {
        MerchandiseActions.update('filePath', undefined);
        document.getElementById('merchandise-file-dummy-input').value = '';
        this.onHide();
    }

    showModal() {
        this.setState({
            showModal: true
        });
    }

    render() {
        let actionsMenu;
        let eventsAndNotes = null;
        let disableForm = true;
        const filenameList = this.state.asset.get('files', Immutable.List()).map(f => f.name);
        let footer = <FileInput
            id="merchandise-file"
            accept="image/*"
            files={filenameList}
            onChange={this.handleFileChange}
        />;
        let filePath = this.state.asset.get('filePath') || '';
        let filename = filePath.split('/').pop();
        let mode = this.props.route.mode;
        let previewImage = <img className="responsive-image" src={require('../../../img/vid-thumb.jpg')}/>;
        let imageURL = this.state.asset.get('filePath');
        if (filename) {
            previewImage = <img className="responsive-image" onClick={this.showModal} src={imageURL}/>;
        }

        let validations = MerchandiseStore.getValidations();
        let fileUpload = <div>
            <Panel iconClass="far fa-image"
                title={this.context.intl.messages['asset.merchandise.create.upload']}
                additionalStyle="padding-x-20"
                footer={footer}
                collapsible
                defaultExpanded>
                <div className="image-wrapper">
                    {previewImage}
                </div>
                <i>{filename}</i>
            </Panel>
        </div>;

        let baseRoute;
        // Tabs
        let catalogsTab;
        let historyTab = null;
        let talentTab;
        let titlesTab;
        let userTab;

        switch (mode) {
        case 'create':
            baseRoute = '/assets/merchandise/create';
            disableForm = false;

            actionsMenu = <ActionsMenu
                canSave={!validations.length}
                onCancel={this.handleCancel}
                onSave={this.handleSave}
                saveText={this.context.intl.messages['common.create-asset']}
            />;

            break;
        case 'edit':
            baseRoute = `/assets/merchandise/${this.props.params.id}`;
            if (this.props.permissions.canEdit) {
                disableForm = false;
            }

            actionsMenu = <ActionsMenu
                canEdit={this.props.permissions.canEdit}
                canSave={!validations.length && this.props.permissions.canEdit}
                hideSaveAndSync={!this.props.permissions.canEdit}
                onCancel={this.handleCancel}
                onElasticSearchSync={this.handleElasticSearchSync}
                onSave={this.handleSave}
            />;

            eventsAndNotes = (
                <SideNotes
                    basePath={`/assets/merchandise/${this.props.params.id}`}
                    dialogMessage={this.context.intl.messages['common.side-notes.add-note.body']}
                    disabled={ !this.props.permissions.canEdit }
                    notes={this.state.history.toJS().slice(0, 4)}
                    onAddNote={this.addNote}
                    title={this.context.intl.messages['common.side-notes.title']}
                />
            );

            historyTab = <Tab route={`${baseRoute}/history`} title={this.context.intl.messages['common.tab-title.history']} tabClassName="pull-right">
                <History
                    actionObject={ActionHistoryConstants.ACTION_OBJECTS.MERCHANDISE}
                    id={this.props.params.id}
                    pagination
                    pathname={this.props.location.pathname}
                    query={this.props.location.query}/>
            </Tab>;
            titlesTab = <Tab route={`${baseRoute}/titles`} title={this.context.intl.messages['titles.create.edit-title-tab-title']}>
                <TitleTab
                    assetId={parseInt(this.props.params.id, 10)}
                    displayLinks={this.props.permissions.canReadTitles}
                    location={this.props.location}
                />
            </Tab>;
            talentTab = <Tab route={`${baseRoute}/talent`} title={this.context.intl.messages['asset.talent.tab.title']}>
                <TalentTab
                    assetId={parseInt(this.props.params.id, 10)}
                    displayLinks={this.props.permissions.canReadTalent}
                />
            </Tab>;
            userTab = <Tab route={`${baseRoute}/users`} title={this.context.intl.messages['asset.user.tab.title']}>
                <UserTab
                    assetId={parseInt(this.props.params.id, 10)}
                    displayLinks={this.props.permissions.canReadUsers}
                    location={this.props.location}
                />
            </Tab>;
            catalogsTab = <Tab route={`${baseRoute}/catalogs`} title={this.context.intl.messages['asset.edit.catalogs-tab-title']}>
                <CatalogsTab
                    assetId={parseInt(this.props.params.id, 10)}
                    displayLinks={this.props.permissions.canReadCatalogs}
                />
            </Tab>;

            break;
        }

        let summaryTab = <Tab route={baseRoute} title={this.context.intl.messages['titles.create.summary-tab-title']}>
            <Summary disabled={disableForm} asset={this.state.asset} mode={this.props.route.mode}/>
        </Tab>;

        let entityName;

        if (this.state.asset.get('name')) {
            entityName = this.state.asset.get('name');
        }

        return (
            <DocumentTitle
                message={`document-titles.${mode}-merchandise`} entityName={entityName}
            >
                <div>
                    <StickyHeader>
                        <div className="col-md-6">
                            <h1><i className="fas fa-tag"/>&nbsp;{this.context.intl.messages[`asset.merchandise.${this.props.route.mode}.title`]}&nbsp;
                                <small>{this.state.asset.get('name')}</small>
                            </h1>
                            <p className="padding-top-10">
                                <em>{this.context.intl.messages[`asset.merchandise.${mode}.info`]}</em>
                            </p>
                        </div>
                        {actionsMenu}
                    </StickyHeader>
                    <Preloader show={this.state.showPreloader||this.state.showUserPreloader} fixed loadingDots>
                        <PhotoPreview
                            onHide={this.onHide}
                            path={imageURL}
                            showModal={this.state.showModal}
                        >
                            <Button
                                bsStyle="danger"
                                onClick={this.remove}>
                                {this.context.intl.messages['asset.merchandise.create.photo-preview.button']}
                            </Button>
                        </PhotoPreview>
                        <section className="content" id="contentContainer">
                            <div className="row">
                                <div className="col-lg-9">
                                    <Tabs location={this.props.location}>
                                        {summaryTab}
                                        {titlesTab}
                                        {talentTab}
                                        {userTab}
                                        {catalogsTab}
                                        {historyTab}
                                    </Tabs>
                                </div>
                                <div className="col-lg-3">
                                    <AlertsWarnings title={this.context.intl.messages['common.alerts-warnings-title']} validations={validations}/>
                                    {fileUpload}
                                    {eventsAndNotes}
                                </div>
                            </div>
                        </section>
                    </Preloader>
                </div>
            </DocumentTitle>
        );
    }

}
export default Container.create(Merchandise);
