/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Bluebird from 'bluebird';

import AiModelsStore from './ai-models-store';
import * as AiModelsConstants from './create/ai-models/constants';
import {IsAssignedModel} from './create/ai-models/typeguards';

import {NotificationActions} from '~/src/common/notification/notification-actions';
import KeyBy from '~/src/common/utils/key-by';
import Dispatcher from '~/src/dispatcher/dispatcher';
import {GenericFetchAction} from '~/src/hardac/cc-editor/cc-editor-actions/generic';
import Request from '~/src/request';

class AiModelsActions {
    activate(model: VideoMachineLearningProjectDetail) {
        Dispatcher.dispatch({
            actionType: 'asset_ai_models_actions.model.update',
            model: {...model, insightActive: true},
        });
    }

    assign(model: VideoMachineLearningProject) {
        Dispatcher.dispatch({
            actionType: 'asset_ai_models_actions.model.assign',
            model,
        });
    }

    deactivate(model: VideoMachineLearningProject) {
        if (IsAssignedModel(model)) {
            Dispatcher.dispatch({
                actionType: 'asset_ai_models_actions.model.update',
                model: {...model, insightActive: false},
            });
            return;
        }

        Dispatcher.dispatch({
            actionType: 'asset_ai_models_actions.model.unassign',
            model,
        });
    }

    load(videoId: number, force = false) {
        if ((videoId === AiModelsStore.getState().assetId) && !force) {
            return;
        }

        GenericFetchAction('asset_ai_models_actions.load', async() => {
            const [modelsResp, notAppliedResp] = await Promise.all([
                Request.get(`asset/video/${videoId}/video-insight/detail`).exec(),
                Request.get(`asset/video/${videoId}/video-insight/not-applied`).exec(),
            ]);
            return {
                assetId: videoId,
                models: modelsResp.body,
                notAppliedModels: notAppliedResp.body,
            };
        });
    }

    runModel(model: VideoMachineLearningProjectDetail) {
        Dispatcher.dispatch({
            actionType: 'asset_ai_models_actions.model.update',
            model: {...model, statusType: 'creating'},
        });

        const url = `asset/video/${model.videoId}/video-insight/${model.categoryType}/run`;
        Request.post(url).send({}).exec().then(() => {
            NotificationActions.showAlertSuccess('asset.edit.ai-models-tab.messages.run.success');
        }).catch((err) => {
            NotificationActions.showAlertDanger('asset.edit.ai-models-tab.messages.run.failed');
            // revert model
            Dispatcher.dispatch({
                actionType: 'asset_ai_models_actions.model.update',
                model,
            });

            throw err;
        });
    }

    saveModels(videoId: number, models: ReadonlyArray<VideoMachineLearningProject>, originalModels: ReadonlyArray<VideoMachineLearningProjectDetail>) {
        const originalModelsAsCache = KeyBy('videoInsightId', originalModels);
        const promises = models.map((model) => {
            if (IsAssignedModel(model)) {
                const original = originalModelsAsCache[model.videoInsightId];
                if (original.insightActive === model.insightActive) {
                    return Promise.resolve(void 0);
                }

                const url = `asset/video/${videoId}/video-insight/${model.videoInsightId}/active`;
                return Request.put(url).query({'active-value': model.insightActive}).send({}).exec();
            }

            const url = `asset/video/${videoId}/video-insight/${model.categoryType}/run`;
            return Request.post(url).send({}).exec();
        });

        return Bluebird.all(promises);
    }

}

export default new AiModelsActions();
export {
    AiModelsConstants,
};
