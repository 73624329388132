/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';
import {IndexRoute, Route} from 'react-router';

import Browse from './browse/browse';
import Catalogs from './create/catalogs/catalogs';
import EventCatalogs from './create/catalogs/event-catalogs';
import Create from './create/create';
import Local from './create/local';
import Related from './create/related/related';
import Style from './create/style/style';
import Subscriptions from './create/subscriptions/subscriptions';
import Summary from './create/summary/summary';
import Talent from './create/talent/talent';
import Users from './create/users/users';
import BrowseDeleted from './deleted';
import DeletedTitle from './deleted/deleted-title';
import Confirmation from './mass-add/confirmation';
import MassAddTitles from './mass-add/mass-add-titles';
import MassAddUpdateLogsBrowse from './mass-add/mass-add-update-logs/browse';
import ValidateAndSave from './mass-add/validate-and-save';
import WorkOrdersBrowse from './work-orders/browse';
import WorkOrdersCreate from './work-orders/create';
import WorkOrdersExport from './work-orders/export';
import Assets from '../assets-tab/assets';
import {History} from '../common/side-notes/side-notes';
import WithPermissions from '../decorators/with-permissions';
import {RequirePerm} from '../router/router-helpers';
import SessionStore from '../user/session/session-store';

let Titles = <Route path="titles">

    <IndexRoute
        component={WithPermissions(Browse)}
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.TITLES.BROWSE)}
    />

    <Route
        component={WithPermissions(Create)}
        mode="create"
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.TITLES.CREATE)}
        path="create"
    >
        <IndexRoute component={Summary}/>
        <Route path="style" component={Style}/>
    </Route>

    <Route
        component={MassAddTitles}
        mode="create"
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.TITLES.MASS_ADD.UPLOAD)}
        path="mass-add"
    />

    <Route
        component={ValidateAndSave}
        mode="create"
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.TITLES.MASS_ADD.UPLOAD)}
        path="mass-add/:id"
    />

    <Route
        component={MassAddTitles}
        mode="edit"
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.TITLES.MASS_UPDATE.UPLOAD)}
        path="mass-update"
    />

    <Route
        component={ValidateAndSave}
        mode="edit"
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.TITLES.MASS_UPDATE.UPLOAD)}
        path="mass-update/:id"
    />

    <Route
        component={MassAddUpdateLogsBrowse}
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.TITLES.MASS_ADD.BROWSE)}
        path="mass-update-logs"
    />

    <Route
        component={Confirmation}
        mode="edit"
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.TITLES.MASS_ADD.UPLOAD)}
        path="mass-add/:id/confirmation"
    />

    <Route
        component={BrowseDeleted}
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.TITLES.VIEW_DELETED_SECTION)}
        path="deleted"
    />

    <Route
        component={WithPermissions(DeletedTitle)}
        mode="edit"
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.TITLES.READ)}
        path="deleted/:id"
    >

        <IndexRoute component={Summary}/>
        <Route path="history" component={History}/>
    </Route>

    <Route
        component={WithPermissions(WorkOrdersBrowse)}
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.TITLES.WORK_ORDERS.BROWSE)}
        path="work-orders"
    />

    <Route
        component={WithPermissions(WorkOrdersCreate)}
        mode="create"
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.TITLES.WORK_ORDERS.CREATE)}
        path="work-orders/create"
    >

        <IndexRoute component={Summary}/>
        <Route path="export" component={WorkOrdersExport}/>
    </Route>

    <Route
        component={WithPermissions(WorkOrdersCreate)}
        mode="edit"
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.TITLES.WORK_ORDERS.READ)}
        path="work-orders/:id"
    >
        <IndexRoute component={Summary}/>
        <Route path="export" component={WorkOrdersExport}/>
        <Route path="history" component={History}/>
    </Route>

    <Route
        component={WithPermissions(Create)}
        mode="edit"
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.TITLES.READ)}
        path=":id"
    >

        <IndexRoute component={Summary}/>
        <Route path="images" component={Assets} />
        <Route path="videos" component={Assets}/>
        <Route path="documents" component={Assets}/>
        <Route path="stations" component={Assets}/>
        <Route path="audio" component={Assets}/>
        <Route path="merchandise" component={Assets}/>
        <Route path="scripts" component={Assets}/>
        <Route path="catalogs" component={Catalogs}/>
        <Route path="events" component={EventCatalogs}/>
        <Route path="local" component={Local}/>
        <Route path="history" component={History}/>
        <Route path="related" component={Related}/>
        <Route path="style" component={Style}/>
        <Route path="subscriptions" component={Subscriptions}/>
        <Route path="talent" component={Talent}/>
        <Route path="users" component={Users}/>
    </Route>
</Route>;

export default Titles;
