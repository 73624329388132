/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import Table from './table';
import {WithStoreOnRoute} from '../../../../common/store-on-route';
import {ProcessesActions} from '../../processes-actions';
import ProcessesStore from '../../processes-store';

class Browse extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired,
            processes: PropTypes.instanceOf(Immutable.List).isRequired,
            timelineId: PropTypes.number.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            messages: ProcessesStore.getState().get('messages'),
            total: ProcessesStore.getState().get('total'),
            showPreloader: ProcessesStore.getState().get('showPreloader'),
        };
    }

    static getStores() {
        return [ProcessesStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();
        this.handleLoadPage = this.handleLoadPage.bind(this);
    }

    componentDidMount() {
        ProcessesActions.clearMessages();
        if (this.props.location.query) {
            ProcessesActions.getMessagesByProcessIds(this.props.processes, this.getRouteState());
            return;
        }
        this.setRouteState('operator', 'AND').apply();
        return;
    }

    componentWillUpdate(nextProps) {
        if (this.props.processes !== nextProps.processes) {
            ProcessesActions.getMessagesByProcessIds(nextProps.processes, this.getRouteState(nextProps));
        }
        return;
    }

    /**
     * Handle clicks on pagination controls.
     * @param  {Number} pageNumber page to load.
     */
    handleLoadPage(pageNumber) {
        // pageNumber x this.state.size = offset.
        this.setRouteState('offset', pageNumber * 20)
            .apply();
        return;
    }

    render() {
        return (
            <section>
                <h3><i className="fas fa-info-circle"></i>{this.context.intl.messages['hardac.processes.messages.title']}</h3>
                <Table
                    activePage={0}
                    displayLinks={this.props.permissions.canReadProcesses}
                    messages={this.state.messages}
                    onPageChange={this.handleLoadPage}
                    timelineId={this.props.timelineId}
                    totalPages={0}
                    pageSize={this.state.messages.size}
                />
            </section>
        );
    }
}

export default WithStoreOnRoute(Container.create(Browse));
