/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ControlLabel, FormGroup} from 'react-bootstrap';
import Select from 'react-select';

import TargetAsset from './target-asset';
import TargetTitle from './target-title';
import {TargetTypeActions} from './target-type-actions';
import TargetTypeStore from './target-type-store';

class TargetType extends Component {
    static get propTypes() {
        return {
            disabled: PropTypes.bool,
            location: PropTypes.object.isRequired,
            options: PropTypes.instanceOf(Immutable.List).isRequired,
            slidingPanelId: PropTypes.string.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            targetType: TargetTypeStore.getState().get('targetType'),
        };
    }

    static getStores() {
        return [TargetTypeStore];
    }

    static get defaultProps() {
        return {
            disabled: false,
            options: TargetTypeStore.getTargetTypes()
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.renderPage = this.renderPage.bind(this);
    }

    handleSelectionChange(targetType) {
        TargetTypeActions.updateTargetType(targetType);
        return;
    }

    renderPage() {
        let retVal;
        let targetType = this.state.targetType;
        switch (targetType) {
        case ('asset'):
            retVal = <TargetAsset
                disabled={this.props.disabled}
                slidingPanelId={this.props.slidingPanelId}/>;
            break;

        case ('title'):
            retVal = <TargetTitle
                disabled={this.props.disabled}
                location={this.props.location}
                slidingPanelId={this.props.slidingPanelId}/>;
            break;

        default:
            retVal =(
                <div>
                    <div className="col-md-6 col-md-offset-3">
                        <div className="form-group">
                            <ControlLabel>
                                <h3><i className="far fa-dot-circle"></i>&nbsp;{this.context.intl.messages['publishing-list.list-item.select.targetType']}</h3>
                            </ControlLabel>
                            <div>
                                <FormGroup>
                                    <Select
                                        getOptionValue={data => data.value}
                                        isClearable={true}
                                        isMulti={false}
                                        disabled={this.props.disabled}
                                        name="targetTypeList"
                                        onChange={this.handleSelectionChange}
                                        options={this.props.options}
                                        placeholder={this.context.intl.messages['publishing-list.add-item.select.placeholder']}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                </div>
            );
            break;
        }

        return retVal;
    }

    render() {
        let pageToRender = this.renderPage();
        return (
            <div>
                {pageToRender}
            </div>
        );
    }
}

export default Container.create(TargetType);
