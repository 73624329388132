/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';

import ShiftCaptionsBlock from './shift-captions-block';
import VideoAssetBlock from './video-asset-block';
import VttDetailsBlock from './vtt-details-block';
import VttUploadBlock from './vtt-upload-block';

type Props = {
    video: ImmutableMap<PlayableVideoAsset>
}

export default function Settings(props: Props) {
    return (
        <div>
            <VideoAssetBlock video={props.video} />
            <hr />
            <VttDetailsBlock />
            <hr />
            <VttUploadBlock />
            <hr />
            <ShiftCaptionsBlock />
        </div>
    );
}
