/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import createReactClass from 'create-react-class';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {ControlLabel, FormControl, FormGroup} from 'react-bootstrap';

import config from '../../config/config.js';
import {FormRow} from '../form/form';

export default createReactClass({
    propTypes: {
        updatedBy: PropTypes.string,
        updatedDate: PropTypes.string,
    },

    contextTypes: {
        intl: PropTypes.object.isRequired,
    },

    getDefaultProps: function() {
        return {
            updatedBy: '',
            updatedDate: '',
        };
    },

    render: function() {
        let updatedDate = 'N/A';
        let lastModifier = 'N/A';

        if (this.props.updatedDate) {
            updatedDate = Moment(this.props.updatedDate).tz(config.DefaultTimezone).format(this.context.intl.messages['datetime-format']);
        } else {
            updatedDate = '-';
        }

        if (this.props.updatedBy) {
            lastModifier = this.props.updatedBy;
        }
        return (
            <div>
                <hr/>
                <FormRow>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['common.last-modified-date']}</ControlLabel>
                        <FormControl.Static>{updatedDate}</FormControl.Static>
                    </FormGroup>
                </FormRow>
                <FormRow>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['common.last-modifier']}</ControlLabel>
                        <FormControl.Static>{lastModifier}</FormControl.Static>
                    </FormGroup>
                </FormRow>
            </div>
        );
    }
});
