/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Modal, Button} from 'react-bootstrap';

import {TitleStatusActions} from '../titles/title-status-actions';

export default class StatusDialog extends React.Component {
    static get propTypes() {
        return {
            field: PropTypes.instanceOf(Immutable.Map).isRequired,
            show: PropTypes.bool,
            title: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
            router: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            show: false
        };
    }

    render() {
        let setters = this.props.field.get('values').map((v, i) => {
            return (
                <Button
                    bsSize="large"
                    block
                    bsStyle={v.get('bsStyle')}
                    key={i}
                    onClick={
                        TitleStatusActions.setStatus.bind(
                            TitleStatusActions,
                            this.props.title,
                            this.props.field,
                            v
                        )
                    }
                >{v.get('value', '').toUpperCase()}</Button>
            );
        });

        if (!this.props.field.get('noRemove')) {
            setters = setters.push(
                <Button
                    bsSize="large"
                    block
                    bsStyle="default"
                    key="remove"
                    onClick={
                        TitleStatusActions.setStatus.bind(
                            TitleStatusActions,
                            this.props.title,
                            this.props.field,
                            null
                        )
                    }
                >REMOVE STATUS</Button>
            );
        }

        return (
            <Modal show={this.props.show} onEnter={this.showPreloader} onHide={TitleStatusActions.hide}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="text-center">Select Status</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    {setters}
                </Modal.Body>
                <Modal.Footer>
                    <Button target="_blank" onClick={TitleStatusActions.hide}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
