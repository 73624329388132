/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {TimelineAssetConstants} from './timeline-asset-actions';
import {VideoConstants} from '../../assets/video/video-actions';
import Validations from '../../common/validations/validations';
import Dispatcher from '../../dispatcher/dispatcher';

const TimelineAssetValidations = {
    assetName: {
        label: 'asset.timeline.summary.name',
        validations: [Validations.max(200), Validations.required]
    },
    contentType: {validations: [Validations.required]},
    deliveryType: {validations: [Validations.required]},
    protectionType: {validations: [Validations.required]},
};

/**
 * Look for a constant with id === objectId in a map.
 */
const findInConstant = (constants, objectId) => {
    return Immutable.fromJS(
        Object.keys(constants)
            .map(k => constants[k])
            .filter(obj => objectId === obj.id)[0]
    );
};

class TimelineAssetStore extends ReduceStore {
    getContentType(contentTypeId) {
        return findInConstant(VideoConstants.CONTENT_TYPES, contentTypeId);
    }

    getProtectionType(protectionTypeId) {
        if (protectionTypeId !== undefined && protectionTypeId !== null) {
            return findInConstant(TimelineAssetConstants.TIMELINE.PROTECTION_TYPES, protectionTypeId);
        }
    }

    getValidations() {
        return Validations.validate(this.getState().get('timeline'), TimelineAssetValidations);
    }

    getInitialState() {
        return Immutable.fromJS({
            audioProfile: [],
            clips: [],
            contentType: VideoConstants.toArray('CONTENT_TYPES'),
            history: [],
            player: null,
            protectionType: TimelineAssetConstants.TIMELINE.PROTECTION_TYPES.DRM.id,
            selectedProtectionType: TimelineAssetConstants.TIMELINE.toArray('PROTECTION_TYPES'),
            showPlayer: false,
            showPreloader: false,
            timeline: {},
            originalTimeline: {},
            video: null,
        });
    }

    reduce(state, action) {
        switch (action.actionType) {
        case TimelineAssetConstants.TIMELINE.CLEAR:
            state = this.getInitialState();
            break;

        case TimelineAssetConstants.TIMELINE.FIND_BY_ID.START:
            state = state.set('showPreloader', true);
            break;

        case TimelineAssetConstants.TIMELINE.FIND_BY_ID.SUCCESS:
            state = state.merge({
                audioProfile: action.audioProfile,
                clips: action.clips,
                history: action.history,
                originalTimeline: action.timeline,
                showPreloader: false,
                timeline: action.timeline
            });
            break;

        case TimelineAssetConstants.TIMELINE.SAVE.START:
            state = state.merge({
                showPreloader: true
            });
            break;

        case TimelineAssetConstants.TIMELINE.SAVE.SUCCESS:
            state = state.merge({
                originalTimeline: state.get('timeline'),
                showPreloader: false
            });
            break;
        case TimelineAssetConstants.TIMELINE.FIND_BY_ID.ERROR:
        case TimelineAssetConstants.TIMELINE.SAVE.ERROR:
            state = state.set('showPreloader', false);
            break;
        case TimelineAssetConstants.TIMELINE.SEND_TO_OAP.ERROR:
            state = state.merge({
                showPreloader: false
            });
            break;
        case TimelineAssetConstants.TIMELINE.SEND_TO_OAP.START:
            state = state.merge({
                showPreloader: true
            });
            break;
        case TimelineAssetConstants.TIMELINE.SEND_TO_OAP.SUCCESS:
            state = state.setIn(['timeline', 'barcodeNum'], action.barcode);
            state = state.merge({
                showPreloader: false
            });
            break;
        case TimelineAssetConstants.TIMELINE.SHOW_PLAYER:
            state = state.merge({
                showPlayer: true,
                video: action.video
            });
            break;

        case TimelineAssetConstants.TIMELINE.UPDATE:
            state = state.setIn(['timeline', ...action.attr.split('.')], action.value);
            break;

        }

        return state;
    }
}

export default new TimelineAssetStore(Dispatcher);
export {TimelineAssetValidations};
