/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {FormItem, FormRow, FormSection} from '../../../common/form/form';
import {CompareImmutable} from '../../../common/utils/utils';
import {UserActions} from '../../../user/user-actions';

/**
 * Set account expiration section.
 */
let expirationUserProps = ['expirationDate'];

class Expiration extends Component {
    static get propTypes() {
        return {
            disabled: PropTypes.bool,
            user: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true
        };
    }

    shouldComponentUpdate(nextProps) {
        if (!CompareImmutable(nextProps.user, this.props.user, expirationUserProps)) {
            return true;
        }
        return false;
    }

    render() {
        return (
            <FormSection title={this.context.intl.messages['accounts.create.permissions.expiration']} iconClass="far fa-calendar-times">
                <FormRow>
                    <FormItem type="date" md={4}
                        attr="expirationDate"
                        disabled={this.props.disabled}
                        label={this.context.intl.messages['accounts.create.permissions.account-expiration-date']}
                        model={this.props.user}
                        setter={UserActions.updateUser}/>
                </FormRow>
            </FormSection>
        );
    }
}

export default Expiration;
