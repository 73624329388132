/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {ControlLabel, FormGroup, FormControl} from 'react-bootstrap';
import {Link} from 'react-router';

import {FormSection, FormRow} from '../../common/form/form';

class Summary extends React.Component {
    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
        };
    }

    static get propTypes() {
        return {
            permission: PropTypes.instanceOf(Immutable.Map).isRequired,
            roles: PropTypes.instanceOf(Immutable.List).isRequired,
        };
    }

    render() {
        const permissionPath = this.props.permission.get('path');
        let roles;
        let pathAndName;

        if (permissionPath) {
            const sections = permissionPath.split('/').filter(e => e);

            pathAndName = sections.map((section, i) => (
                <span className={i === (sections.length - 1) ? 'text-bold' : ''}>
                    {section}
                </span>
            )).reduce((prev, cur) => [prev, ' » ', cur]);
        }

        if (this.props.roles.length > 0) {
            roles = this.props.roles.map((role) => (
                <span>
                    <Link to={`/roles/${role.id}`}>
                        {role.name}
                    </Link>
                </span>
            )).reduce((prev, cur) => [prev, ', ', cur]);
        }

        return (
            <div>
                <section className="content-header P(0)">
                    <h1><i className="fa fa-info-circle"></i>&nbsp;{this.context.intl.messages['permission.summary.info']}</h1>
                </section>
                <section className="content">
                    <FormSection>
                        <FormRow md={6}>
                            <FormGroup className="M(0)">
                                <ControlLabel>{this.context.intl.messages['permission.summary.path_and_name']}</ControlLabel>
                                <FormControl.Static>
                                    {pathAndName}
                                </FormControl.Static>
                            </FormGroup>
                        </FormRow>
                        <hr/>
                        <FormRow>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['permission.summary.associated_roles']}</ControlLabel>
                                <FormControl.Static>
                                    {roles}
                                </FormControl.Static>
                            </FormGroup>
                        </FormRow>
                    </FormSection>
                </section>
            </div>
        );
    }
}

export default Summary;
