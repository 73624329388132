/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';
import Moment from 'moment';

import {AlertTypes} from '../../common/notification/alert';
import {NotificationActions} from '../../common/notification/notification-actions';
import {CompareImmutableList, SubtractImmutableSet} from '../../common/utils/utils';
import Config from '../../config/config.js';
import Dispatcher from '../../dispatcher/dispatcher';
import Request from '../../request';

// Require for Proper Timezone Display
require('moment-timezone');
let configtz = Moment().tz(Config.DefaultTimezone).format('ZZ');

const CONSTANTS = {
    ADD: 'title_manage_actions.add',
    FINDBY: {
        SUCCESS: 'title_manage_actions.findby.success'
    },
    GET: {
        SUCCESS: 'title_manage_actions.get.success'
    },
    REMOVE: 'title_manage_actions.remove'
};

class AssetTitleActions {

    add(title) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ADD,
            title: title
        });
    }

    // get titles by assetId
    findByAssetId(assetId) {
        Request.get(`asset/${assetId}/title`)
            .exec()
            .then(res => {
                let titles = res.body;
                let lastOrder = 0;
                if (titles.length > 0) {
                    lastOrder = titles[titles.length-1].assetOrder;
                }
                return Promise.props({
                    lastOrder: lastOrder,
                    assetsToTitle: titles,
                    titles: Promise.all(titles.map(assetToTitle => Request.get(`title/${assetToTitle.titleId}`).exec()))
                });
            })
            .then(titlesData => {
                let titles = titlesData.titles.map((td, i) => {
                    let title = td.body;
                    title.asset = {};
                    title = ['defaultHorizontal', 'defaultPortrait', 'defaultBanner'].reduce( (t, attr) => {
                        t.asset[attr] = titlesData.assetsToTitle[i][attr] === 1;
                        return t;
                    }, title);
                    return title;
                });
                Dispatcher.dispatch({
                    actionType: CONSTANTS.FINDBY.SUCCESS,
                    assetId: assetId,
                    titles: Immutable.fromJS(titles).sortBy(t => t.name)
                });
                return;
            })
            .catch(err => {
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
                throw err;
            });
        return;
    }

    get(queryParams, offset, size, sortFieldName, sortDirection) {
        queryParams = queryParams.toJS();
        if (queryParams.category) {
            queryParams.category = queryParams.category.id;
        } else if (queryParams['category-id']) {
            queryParams.category = queryParams['category-id'];
        }

        ['start-date-created', 'start-release-date'].forEach( attr => {
            let d = queryParams[attr];
            if (d) {
                d = Moment(d);
                if (d.isValid()) {
                    d = d.format('YYYY-MM-DDT00:00:00.000'+configtz);
                } else {
                    d = '';
                }
                queryParams[attr] = d;
            }
        });

        ['end-date-created', 'end-release-date'].forEach( attr => {
            let d = queryParams[attr];
            if (d) {
                d = Moment(d);
                if (d.isValid()) {
                    d = d.format('YYYY-MM-DDT23:59:59.999'+configtz);
                } else {
                    d = '';
                }
                queryParams[attr] = d;
            }
        });

        offset = offset || queryParams.offset || 0;
        size = size || 20;
        queryParams.offset = offset ;
        queryParams.size = size;
        queryParams['sort-field-name'] = sortFieldName || queryParams['sort-field-name'] || 'updatedDate';
        queryParams['sort-direction'] = sortDirection || queryParams['sort-direction'] || 'desc';

        Request.get('title').query(queryParams).exec().then(res => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.SUCCESS,
                offset,
                size,
                titles: Immutable.fromJS(res.body.results),
                total: parseInt(res.header['wbtv-total-count'], 10)
            });

            return;
        }).catch(err => {
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
            throw err;
        });

        return;
    }

    remove(index) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.REMOVE,
            index: index
        });
    }

    assetTitlesSave(asset, assignedTitles, originalAssignedTitles) {
        if (CompareImmutableList(assignedTitles, originalAssignedTitles, ['id'])) {
            return [];
        }
        let originalSet = originalAssignedTitles.toSet();
        let newSet = assignedTitles.toSet();
        let toRemove = SubtractImmutableSet(originalSet, newSet, ['id']);
        let toAdd = SubtractImmutableSet(newSet, originalSet, ['id']);
        let ops = [];

        //use only needed attr
        const buildAssets = assets => {
            return assets.map(a => ({
                assetId: a.assetId,
                assetOrder: a.assetOrder,
                defaultHorizontal: a.defaultHorizontal,
                defaultPortrait: a.defaultPortrait,
                titleId: a.titleId,
                defaultBanner: a.defaultBanner,
                defaultTitleTreatment: a.defaultTitleTreatment,
                parentStackAssetId: a.parentStackAssetId
            }));
        };

        ops = ops.concat(toAdd.map(title => Request.get(`title/${title.get('id')}/asset-summary`).exec().then(res => ({
            titleId: title.get('id'),
            assets: res.body
        }))).toArray());
        ops = ops.concat(toRemove.map(title => Request.get(`title/${title.get('id')}/asset-summary`).exec().then(res => ({
            titleId: title.get('id'),
            assets: res.body
        }))).toArray());

        return Promise.all(ops).then(titles => {
            const updateTitles = titles.map(title => {
                const {titleId, assets} = title;

                if (toAdd.find(t => t.get('id')=== titleId)) {
                    const assetsUpdated = buildAssets(assets);
                    let assetToAdd = {
                        assetId: asset.get('id'),
                        assetOrder: assetsUpdated.length + 1,
                        defaultHorizontal: asset.get('defaultHorizontal', 0),
                        defaultPortrait: asset.get('defaultPortrait', 0),
                        titleId,
                        defaultBanner: asset.get('defaultBanner', 0),
                        defaultTitleTreatment: asset.get('defaultTitleTreatment', 0)
                    };
                    if (asset.get('parentStackAssetId') !== null) {
                        assetToAdd.parentStackAssetId = asset.get('parentStackAssetId');
                    }
                    assetsUpdated.push(assetToAdd);
                    return Request.put(`title/${titleId}/asset`).send(assetsUpdated).exec().catch(err => {throw err;});

                } else if (toRemove.find(t => t.get('id')=== titleId)) {
                    let updatedAssets = buildAssets(assets);
                    updatedAssets = updatedAssets.filter(a => a.assetId !== asset.get('id'));
                    return Request.put(`title/${titleId}/asset`).send(updatedAssets).exec().catch(err => {throw err;});
                }
            });

            return Promise.all(updateTitles);
        });
    }
}

let actions = new AssetTitleActions();

export {
    actions as AssetTitleActions,
    CONSTANTS as AssetTitleConstants
};
