/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, OverlayTrigger, Tooltip} from 'react-bootstrap';
import ReactTable from 'react-table';

import {UpdateMarkerInPlayer} from './clip-info';
import DeleteClipModal from './delete-clip-modal';
import {GetPublishMessage} from './edit-clip-panel';
import PublishClipModal from './publish-clip-modal';
import {GetThumbnailFromClip, TimecodeFromFrames, TimecodeToString} from '../../../common/utils/timecode';
import {TimelineActions, TimelineConstants} from '../timeline-actions';
import TimelineStore from '../timeline-store';

import '../timeline.less';
import 'react-table/react-table.css';

class ClipsTable extends React.Component {
    static get propTypes() {
        return {
            canEdit: PropTypes.bool.isRequired,
            canPublish: PropTypes.bool.isRequired,
            clips: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
            originalClips: PropTypes.instanceOf(Immutable.Map).isRequired,
            player: PropTypes.object,
            publishStatusType: PropTypes.oneOf(
                Object.keys(TimelineConstants.TIMELINE.CLIP.PUBLISH_TYPE).map(
                    key => TimelineConstants.TIMELINE.CLIP.PUBLISH_TYPE[key].id
                )
            ).isRequired,
            timeline: PropTypes.instanceOf(Immutable.Map).isRequired,
            thumbnails: PropTypes.instanceOf(Immutable.List).isRequired,
            viewingMode: PropTypes.string.isRequired
        };
    }

    static get defaultProps() {
        return {
            player: undefined,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            clipToDelete: Immutable.Map(),
            clipToPublish: Immutable.Map(),
            showDeleteModal: false,
            showPublishModal: false
        };

        this.isClipButtonEnabled = this.isClipButtonEnabled.bind(this);
        this.handleDeleteClip = this.handleDeleteClip.bind(this);
        this.handleEditClip = this.handleEditClip.bind(this);
        this.handlePublishClip = this.handlePublishClip.bind(this);
        this.handleToggleDeleteModal = this.handleToggleDeleteModal.bind(this);
        this.handleTogglePublishModal = this.handleTogglePublishModal.bind(this);
        this.handleShowDeleteModal = this.handleShowDeleteModal.bind(this);
        this.handleShowPublishModal = this.handleShowPublishModal.bind(this);
        return;
    }

    handleDeleteClip() {
        TimelineActions.deleteClip(this.state.clipToDelete.get('displayOrder'), 'unpublished');
    }

    handleEditClip(clip) {
        UpdateMarkerInPlayer(this.props.player, this.props.timeline, clip);
        TimelineActions.setSelectedClip(clip);
        TimelineActions.update('showEditClipPanel', true);
        return;
    }

    handlePublishClip() {
        TimelineActions.publishClip(this.state.clipToPublish, this.props.timeline);
    }

    handleToggleDeleteModal() {
        this.setState((prevState) => ({
            showDeleteModal: !prevState.showDeleteModal
        }));
    }

    handleTogglePublishModal() {
        this.setState((prevState) =>({
            showPublishModal: !prevState.showPublishModal
        }));
    }

    handleShowDeleteModal(clip) {
        this.setState(() => ({
            clipToDelete: clip
        }));
        this.handleToggleDeleteModal();
    }

    handleShowPublishModal(clip) {
        this.setState(() =>({
            clipToPublish: clip
        }));
        this.handleTogglePublishModal();
    }

    isClipButtonEnabled(clip) {
        return (
            this.props.canPublish &&
            this.props.canEdit &&
            !!clip.get('id')
        );
    }

    render() {
        const {active: timelineActive, deliveryType: timelineDeliveryType} = this.props.timeline.toJS();
        const {UNPUBLISHED, PROCESSING, FAILED} = TimelineConstants.TIMELINE.CLIP.PUBLISH_TYPE;

        let columns = [{
            Header: <i className="far fa-film" title={this.context.intl.messages['hardac.timeline.clips.thumbnail']}/>,
            headerClassName: 'h-alignment',
            className: 'h-alignment clip-thumbnail',
            width: 60,
            Cell: c => {
                const expectedSize = {
                    width: c.width - 12 //we want to be a little smaller than current cell size
                };
                const thumbnail = GetThumbnailFromClip(
                    c.original,
                    this.props.thumbnails.toJS(),
                    expectedSize
                );
                return (
                    <div>
                        {thumbnail}
                    </div>
                );
            }
        }, {
            Header: <i className="far fa-cut"/>,
            headerClassName: 'h-alignment',
            className: 'h-alignment',
            width: 58,
            Cell: c => {
                const firstFrame = this.props.timeline.get('firstFrame') || 0;
                const clipIn = TimecodeToString(TimecodeFromFrames(
                    firstFrame + c.original.get('clipInFrame'), this.props.timeline.get('frameRate')
                ));
                const clipOut = TimecodeToString(TimecodeFromFrames(
                    firstFrame + c.original.get('clipOutFrame'), this.props.timeline.get('frameRate')
                ));
                return (
                    <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${c.original.get('clipIn')}`} positionTop={0}>
                        {`In: ${clipIn}, Out: ${clipOut}`}
                    </Tooltip>}>
                        <i className="fas fa-info-circle" data-toggle="tooltip"/>
                    </OverlayTrigger>
                );
            }
        }, {
            Header: <strong>{this.context.intl.messages['hardac.timeline.clips.name']}</strong>,
            Cell: c => {
                let clipName = c.original.get('name');
                const publishStatusType = c.original.get('publishStatusType');
                switch (true) {
                case publishStatusType === TimelineConstants.TIMELINE.CLIP.PUBLISH_TYPE.FAILED.id:
                case publishStatusType === TimelineConstants.TIMELINE.CLIP.PUBLISH_TYPE.COMPLETE.id:
                case publishStatusType === TimelineConstants.TIMELINE.CLIP.PUBLISH_TYPE.PROCESSING.id:
                    clipName = <a target="_blank" href={`/assets/video/${c.original.get('videoId')}`}>{c.original.get('name')}</a>;
                    break;
                case this.props.canEdit && !publishStatusType: //unpublished clip
                    clipName = <a href="#" onClick={this.handleEditClip.bind(this, c.original)}>{clipName}</a>;
                    break;
                }
                return (
                    <div>
                        {clipName}
                    </div>
                );
            }
        }, {
            Header: <i className="far fa-cloud-upload"/>,
            headerClassName: 'h-alignment',
            className: 'h-alignment',
            width: 58,
            Cell: c => {
                let publishBtn = <div />;
                if (this.isClipButtonEnabled(c.original) && !c.original.get('published')) {
                    const clip = this.props.originalClips.get('unpublished').find(oc => oc.get('id') === c.original.get('id'));
                    let title = '';
                    if (clip !== c.original) {
                        title = this.context.intl.messages['hardac.timeline.clip.publish.disabled'];
                    }
                    publishBtn = <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${c.original.get('displayOrder')}`} positionTop={0}>
                        {this.context.intl.messages['hardac.timeline.clip.publish.enabled']}
                    </Tooltip>}>
                        <Button
                            bsSize="sm"
                            className="btn btn-primary-outline Mr(3px)"
                            disabled={!c.original.equals(clip)}
                            onClick={this.handleShowPublishModal.bind(this, c.original)}
                            title={title}
                        >
                            <i className="fas fa-cloud-upload"></i>
                        </Button>
                    </OverlayTrigger>;
                }
                return (
                    publishBtn
                );
            },
            show: this.props.publishStatusType === UNPUBLISHED.id
        }, {
            Header: <i className="far fa-battery-half"/>,
            headerClassName: 'h-alignment',
            className: 'h-alignment',
            width: 58,
            Cell: () => <div/>,
            show: this.props.publishStatusType === PROCESSING.id
        }, {
            Header: <i className="far fa-redo"/>,
            headerClassName: 'h-alignment',
            className: 'h-alignment',
            width: 58,
            Cell: () => {
                return (
                    <Button
                        bsSize="sm"
                        className="btn btn-success-outline Mr(3px)"
                        disabled={true}
                        title={this.context.intl.messages['hardac.timeline.clip.reprocess.not-available']}
                    >
                        <i className="far fa-redo"></i>
                    </Button>
                );
            },
            show: this.props.publishStatusType === FAILED.id
        }, {
            Header: <i className="far fa-times-circle"/>,
            headerClassName: 'h-alignment',
            className: 'h-alignment',
            width: 58,
            Cell: c => {
                // TODO for now disable delete for failed and processing clips
                let disableClip = this.props.publishStatusType === FAILED.id || this.props.publishStatusType === PROCESSING.id;
                let title = '';
                if (disableClip) {
                    title = this.context.intl.messages['hardac.timeline.clip.delete.not-available'];
                }
                return (
                    <Button
                        bsSize="sm"
                        className="btn btn-danger-outline Mr(3px)"
                        disabled={!this.props.canEdit || disableClip}
                        onClick={this.handleShowDeleteModal.bind(this, c.original)}
                        title={title}
                    >
                        <i className="fas fa-times-circle"></i>
                    </Button>
                );
            },
            show: this.props.publishStatusType === UNPUBLISHED.id || this.props.publishStatusType === FAILED.id || this.props.publishStatusType === PROCESSING.id
        }, {
            Header: <i className="far fa-flag-alt"/>,
            headerClassName: 'h-alignment',
            className: 'h-alignment',
            width: 58,
            Cell: (c) => {
                const {active, deliveryType, id} = c.original.toJS();
                if (!!timelineActive === active && timelineDeliveryType === deliveryType) {
                    return null;
                }

                let tooltipMessage = [];
                if (!!timelineActive !== active) {
                    tooltipMessage.push(`${this.context.intl.messages['hardac.timeline.summary.active']}: ${active}`);
                }
                if (timelineDeliveryType !== deliveryType && deliveryType !== null && deliveryType !== undefined) {
                    tooltipMessage.push(`${this.context.intl.messages['hardac.timeline.summary.delivery-type']}: ${TimelineStore.getDeliveryType(deliveryType).get('name')}`);
                }
                const tooltip = (
                    <Tooltip id={`tooltip-clip-${id}`} positionTop={0}>{tooltipMessage.join(', ')}</Tooltip>
                );
                return (
                    <OverlayTrigger placement="top" overlay={tooltip}>
                        <i className="fas fa-flag-alt" data-toggle="tooltip"/>
                    </OverlayTrigger>
                );
            }
        }];

        const deleteMessage = <div><p>{this.context.intl.messages['hardac.timeline.clip.delete.confirm.message'].replace('{}', this.state.clipToDelete.get('name'))}</p></div>;
        const publishMessage = GetPublishMessage(this.state.clipToPublish, this.context);

        return (
            <div>
                <ReactTable
                    className="-striped table-bordered table-striped responsive"
                    columns={columns}
                    data={this.props.clips}
                    getNoDataProps= {() => {
                        if (this.props.clips.size) {
                            return {style: {display: 'none'}};
                        }
                        return {};
                    }}
                    id="timeline-clips"
                    loading={false}
                    showPagination={false}
                    sortable={false}
                    resizable={false}
                    pageSize={this.props.clips.size}
                />
                <DeleteClipModal
                    title={this.context.intl.messages['hardac.timeline.clip.delete']}
                    onHide={this.handleToggleDeleteModal}
                    onConfirmDelete={this.handleDeleteClip}
                    show={this.state.showDeleteModal}
                    viewingMode={this.props.viewingMode}
                >
                    {deleteMessage}
                </DeleteClipModal>
                <PublishClipModal
                    onConfirmPublish={this.handlePublishClip}
                    onHide={this.handleTogglePublishModal}
                    show={this.state.showPublishModal}
                    title={this.context.intl.messages['hardac.timeline.clip.publish.clip']}
                    viewingMode={this.props.viewingMode}
                >
                    {publishMessage}
                </PublishClipModal>
            </div>
        );
    }
}

export default ClipsTable;
