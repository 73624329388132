/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ControlLabel, FormGroup, Button} from 'react-bootstrap';

import ListSection from './list-section';
import {FormSection, FormRow} from '../../../common/form/form';
import {PublishingListActions, PublishingListConstants} from '../../../publishing-list/publishing-list-actions';
import PublishingListStore from '../../../publishing-list/publishing-list-store';
import {HomepageActions, HomepageConstants, HomepageSections} from '../../homepage-actions';
import HomepageStore from '../../homepage-store';


class HomepageTemplates extends Component {
    static get propTypes() {
        return {
            disabled: PropTypes.bool,
            homepage: PropTypes.instanceOf(Immutable.Map).isRequired,
            mode: PropTypes.string.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true
        };
    }

    static calculateState() {
        return {
            homepage: HomepageStore.getState().get('homepage'),
            publishingLists: PublishingListStore.getState().get('publishingLists')
        };
    }

    static getStores() {
        return [HomepageStore, PublishingListStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.handleAdd = this.handleAdd.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleSelectList = this.handleSelectList.bind(this);
        this.handleSelectListDynamic = this.handleSelectListDynamic.bind(this);
        this.handleSelectTemplate = this.handleSelectTemplate.bind(this);
        this.handleSelectNavigationMenu = this.handleSelectNavigationMenu.bind(this);
        this.renderTemplateItem = this.renderTemplateItem.bind(this);
    }

    componentDidMount() {
        PublishingListActions.get(0, 9999);
        return;
    }

    componentDidUpdate() {
        if (this.props.homepage.get('navigationPublishListId') && !this.state.homepage.get('navigationItems')) {
            HomepageActions.getNavigationItems(this.props.homepage.get('navigationPublishListId'));
        }
    }

    handleAdd() {
        HomepageActions.addSection();
    }

    handleRemove(sectionIndex) {
        HomepageActions.removeSection(sectionIndex);
    }

    handleSelectList(sectionIndex, sectionType, publishingList) {
        const findSection = this.props.homepage.get('sections')?.find(s => s.get('sectionType') === sectionType);

        let section = {
            // Deal with the relationship ids. This is because the
            // publishingList object has the form:
            // {id: 1}
            // while the relationship object has the form:
            // {publishListId: 1}
            publishListId: publishingList?.id,
            sectionType: sectionType,
            publishingList: publishingList,
            displayOrder: this.props.homepage.get('sections')?.filter(s => s.get('sectionType') === HomepageSections.DYNAMIC_LIST.id)?.size-1
        };

        if (!publishingList) {
            // Reset the value.
            HomepageActions.update(`sections.${sectionIndex}`, undefined);
            return;
        }

        if (!findSection) {
            // Section doesn't exist, add it to list.
            HomepageActions.update(`sections.${this.props.homepage.get('sections')?.size}`, Immutable.fromJS(section));
            HomepageActions.getItems(publishingList.id, sectionIndex, sectionType);
            return;
        }

        // Calculate the proper sectionIndex, based on assumption sectionType is unique across sections
        sectionIndex = this.props.homepage.get('sections')?.findIndex(s => s.get('sectionType') === sectionType);

        // Call the select action but with immutable objects.
        HomepageActions.update(`sections.${sectionIndex}`, Immutable.fromJS(section));
        HomepageActions.getItems(publishingList.id, sectionIndex, sectionType);
        return;
    }

    handleSelectListDynamic(sectionIndex, sectionType, publishingList) {
        if (!publishingList) {
            // Reset the value.
            HomepageActions.update(`sections.${sectionIndex}`, undefined);
            return;
        }

        let section = {
            // Deal with the relationship ids. This is because the
            // publishingList object has the form:
            // {id: 1}
            // while the relationship object has the form:
            // {publishListId: 1}
            publishListId: publishingList?.id,
            sectionType: sectionType,
            publishingList: publishingList,
            displayOrder: this.props.homepage.get('sections')?.filter(s => s.get('sectionType') === HomepageSections.DYNAMIC_LIST.id)?.size - 1
        };

        // Call the select action but with immutable objects.
        HomepageActions.update(`sections.${sectionIndex}`, Immutable.fromJS(section));
        HomepageActions.getItems(publishingList?.id, sectionIndex, sectionType);
        return;
    }

    handleSelectTemplate(template) {
        if (template.id === this.props.homepage.get('pageType')) {
            return;
        }

        HomepageActions.update('pageType', template.id);
        HomepageActions.update('sections', Immutable.List());
        return;
    }

    handleSelectNavigationMenu(item) {
        if (!item) {
            HomepageActions.update('navigationPublishListId', undefined);
            return;
        }

        HomepageActions.getNavigationItems(item.id);
        HomepageActions.update('navigationPublishListId', item.id);
    }

    renderTemplateItem(pageType) {
        const inputId = `homepage-${pageType.id}`;
        return <div className="thumbnail-select">
            <input
                disabled={this.props.homepage.get('homePageId') !== undefined && this.props.homepage.get('pageType') !== pageType.id}
                checked={this.props.homepage.get('pageType') === pageType.id}
                type="radio"
                name="homepage-thumb"
                value={inputId}
                id={inputId}
                onChange={this.handleSelectTemplate.bind(this, pageType)}/>
            <ControlLabel htmlFor={inputId}>{this.context.intl.messages[pageType.label]}
                <i className="fa text-wb-blue"></i>
                <br/>
                <img alt={this.context.intl.messages[pageType.label]} className="img-responsive" src={pageType.THUMBNAIL}/>
            </ControlLabel>
        </div>;
    }

    render() {
        let templateMessage;

        if (this.props.mode === 'edit') {
            templateMessage = this.context.intl.messages['homepages.edit.cant-change'];
        }

        const templateSection = <div className="row">
            {
                [HomepageConstants.PAGE_TYPE.LEGACY,
                    HomepageConstants.PAGE_TYPE.UPFRONTS,
                    HomepageConstants.PAGE_TYPE.PLAYLIST,
                    HomepageConstants.PAGE_TYPE.PRESS_RELEASE].map(pt => (<div className="col-md-3" key={pt.name}>{this.renderTemplateItem(pt)}</div>))
            }
        </div>;

        const PAGE_TYPE = Object.values(HomepageConstants.PAGE_TYPE).filter(pt => pt.id === this.props.homepage.get('pageType'))[0];
        const listsSection = PAGE_TYPE.CONTENT.map((section, index) => {
            let selectedValue = this.props.homepage.get('sections').find(s => {
                if (s) {
                    return s.get('sectionType') === section.id;
                } else {
                    return false;
                }
            });

            return (
                <ListSection
                    disabled={this.props.disabled}
                    handleSelect={this.handleSelectList.bind(this, index, section.id)}
                    key={index}
                    publishingLists={this.state.publishingLists.toJS().filter(c => c.purposeType !== PublishingListConstants.PURPOSE_TYPES.NAVIGATION_MENU.id)}
                    section={section}
                    selectedValue={selectedValue}
                    separator={index === PAGE_TYPE.CONTENT.length - 1}
                />
            );
        });

        let dynamicLists;
        if (PAGE_TYPE.id === HomepageConstants.PAGE_TYPE.PRESS_RELEASE.id) {
            const additionalLists = this.props.homepage.get('sections').map((section, index) => {
                if (section.get('sectionType') !== HomepageSections.DYNAMIC_LIST.id) {
                    return null;
                }

                const listNumber = this.props.homepage.get('sections').filter(s => s.get('sectionType') === HomepageSections.DYNAMIC_LIST.id).indexOf(section) + 1;

                return (
                    <ListSection
                        disabled={this.props.disabled}
                        handleSelect={this.handleSelectListDynamic.bind(this, index, section.get('sectionType'))}
                        handleRemove={this.handleRemove.bind(this, this.props.homepage.get('sections').indexOf(section))}
                        listNumber={listNumber}
                        publishingLists={this.state.publishingLists.toJS().filter(c => c.purposeType !== PublishingListConstants.PURPOSE_TYPES.NAVIGATION_MENU.id)}
                        section={HomepageSections.DYNAMIC_LIST}
                        selectedValue={section}
                        separator={index === PAGE_TYPE.CONTENT.length - 1}
                    />
                );
            });

            dynamicLists = <FormSection>
                {additionalLists}
                <FormRow>
                    <FormGroup md={4}/>
                    <FormGroup md={4}>
                        <Button bsStyle="primary" className="btn-lg btn-block" onClick={this.handleAdd} disabled={this.props.disabled}>
                            <i className="fas fa-plus"/>&nbsp;{this.context.intl.messages['homepages.create.templates.additional-section.add']}
                        </Button>
                    </FormGroup>
                </FormRow>
            </FormSection>;
        }

        const navigationPublishingLists = this.state.publishingLists.toJS().filter(p => p.purposeType === PublishingListConstants.PURPOSE_TYPES.NAVIGATION_MENU.id);
        let selectedNavigationPublishingList;
        if (navigationPublishingLists.find(c => c.id === this.state.homepage.get('navigationPublishListId'))) {
            let npl = navigationPublishingLists.find(c => c.id === this.state.homepage.get('navigationPublishListId'));
            if (this.state.homepage.get('navigationItems')) {
                npl.items = this.state.homepage.get('navigationItems').toJS().map(i => ({name: i.displayName}));
            }
            selectedNavigationPublishingList = Immutable.fromJS({
                publishingList: npl
            });
        }

        const navigationMenuSection = <div>
            <ListSection
                disabled={this.props.disabled}
                handleSelect={this.handleSelectNavigationMenu}
                publishingLists={navigationPublishingLists}
                section={{name: 'navigationPublishListId', label: 'homepages.create.navigation-menu'}}
                selectedValue={selectedNavigationPublishingList}
                separator={true}
            />
            <p><em><small>{this.context.intl.messages['homepages.create.navigation-menu.description']}</small></em></p>
        </div>;

        return (
            <div>
                <h3><i className="far fa-th"></i> {this.context.intl.messages['homepages.create.templates.title']}<small>{templateMessage}</small></h3>
                <FormSection>
                    {templateSection}
                </FormSection>

                <hr />

                <h3><i className="far fa-list-alt"></i> {this.context.intl.messages['homepages.create.lists.title']}</h3>

                <FormSection>
                    {navigationMenuSection}
                </FormSection>

                <hr />

                <FormSection>
                    {listsSection}
                </FormSection>

                <hr />
                {dynamicLists}
            </div>
        );
    }
}

export default Container.create(HomepageTemplates);
