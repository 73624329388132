/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import jQuery from 'jquery';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Col, Row, Button} from 'react-bootstrap';

import {AccountWizardActions} from './account-wizard-actions';
import AuthLink from '../../common/auth-link';

import 'bootstrap-fileinput/css/fileinput.css';
import 'bootstrap-fileinput/js/fileinput';

const buttonBlock = (title, desc, btnCaption, btnHandler, btnDisabled = false, md = 4) => <Col md={md} className="text-center">
    <h4>{title}</h4>
    <p><em>{desc}</em></p>
    <p><Button className="btn btn-primary-outline" bsSize="large" onClick={btnHandler} disabled={btnDisabled}>{btnCaption}</Button></p>
</Col>;

const authLinkBlock = (title, desc, btnCaption, href, btnDisabled = false, md = 4) => <Col md={md} className="text-center">
    <h4>{title}</h4>
    <p><em>{desc}</em></p>
    <p><AuthLink className="btn btn-lg btn-primary-outline" href={href} disabled={btnDisabled}>{btnCaption}</AuthLink></p>
</Col>;

class AccountWizard extends Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        AccountWizardActions.clear();
        // Manually start the fileinput plugin.
        this.$fileUpload = jQuery('#excel-upload');
        this.$fileUpload.fileinput({
            showPreview: false,
            showRemove: false,
            showUpload: false,
            showCancel: false
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            document.querySelector('#account-wizard-excel-upload .input-group input').value = '';
        }
    }

    handleChange(event) {
        AccountWizardActions.uploadAccountWizard(event.target.files[0]);
    }

    handleCreateEmptyBatch() {
        AccountWizardActions.createEmptyBatch();
    }

    render() {
        const batchStartOptions = <Row>
            {buttonBlock(this.context.intl.messages['accounts.account-wizard.create-empty-batch'],
                this.context.intl.messages['accounts.account-wizard.create-empty-batch.description'],
                this.context.intl.messages['accounts.account-wizard.create-empty-batch.button'],
                this.handleCreateEmptyBatch,
                false,
                6)}
            {authLinkBlock('Download Template',
                <p>{this.context.intl.messages['accounts.account-wizard.details.1']}<i><strong>&nbsp;CSV&nbsp;</strong></i>{this.context.intl.messages['accounts.account-wizard.details.2']}</p>,
                this.context.intl.messages['accounts.account-wizard.download-template'],
                'user/ingest/template',
                false,
                6)}
        </Row>;

        return (
            <div>
                <section className="content-header">
                    <h1><i className="fas fa-user"></i>&nbsp;{this.context.intl.messages['accounts.account-wizard.title']}</h1>
                </section>
                <div className="content">
                    <Row>
                        <Col xs={12}>
                            <div className="container-fluid no-x-padding">
                                <Row>
                                    <Col xs={12}>
                                        <div className="box">
                                            <div className="box-body">
                                                <h3>{this.context.intl.messages['accounts.account-wizard.step.import.title']}</h3>
                                                <hr />
                                                {batchStartOptions}
                                                <hr />
                                                <div className="row margin-bottom-20">
                                                    <div className="col-lg-8 col-lg-push-2 margin-bottom-10">
                                                        <div className="form-section">
                                                            <div id="account-wizard-excel-upload" className="form-group">
                                                                <label>{this.context.intl.messages['accounts.account-wizard.upload-file']}</label>
                                                                <input
                                                                    id="excel-upload"
                                                                    type="file"
                                                                    onChange={this.handleChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default AccountWizard;
