/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

import {AlertTypes} from '../../../../common/notification/alert';
import {NotificationActions} from '../../../../common/notification/notification-actions';
import Dispatcher from '../../../../dispatcher/dispatcher';
import {PreloaderActions} from '../../../../preloader/preloader-actions';
import Request from '../../../../request';

const CONSTANTS = {
    CLEAR:'video.forensic-urls.clear',
    GET: {
        SUCCESS: 'video.forensic-urls.get.success',
        START:'video.forensic-urls.get.start',
        ERROR:'video.forensic-urls.get.error'
    },
    PAGE_SIZE: 20,
};

class ForensicActions {

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
    }

    get(videoId, queryParams) {
        queryParams = queryParams.toJS();
        PreloaderActions.show('video-forensic-urls.get');
        queryParams.offset = queryParams.offset || 0;
        queryParams.size = queryParams.size || CONSTANTS.PAGE_SIZE ;

        Request.get(`asset/video/${videoId}/forensic-url-used`)
            .query(queryParams)
            .exec()
            .then(res => {
                PreloaderActions.hide('video-forensic-urls.get');
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET.SUCCESS,
                    forensicUrls: Immutable.fromJS(res.body.results),
                    total: parseInt(res.header['wbtv-total-count'], 10)
                });
                return;
            })
            .catch(err => {
                PreloaderActions.hide('video-forensic-urls.get');
                switch (err.status) {
                case 404:
                    // it may not be a forensic video
                    // or may not have permissions emit empty array
                    Dispatcher.dispatch({
                        actionType: CONSTANTS.GET.SUCCESS,
                        forensicUrls: Immutable.List(),
                        total: 0
                    });
                    break;
                default:
                    NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
                    throw err;
                }
            });
        return;
    }
}

const actions = new ForensicActions();

export {
    actions as ForensicActions,
    CONSTANTS as ForensicConstants
};
