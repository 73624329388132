/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ControlLabel, FormControl, FormGroup} from 'react-bootstrap';

import {FormItem, FormRow, FormSection} from '~/src/common/form/form';
import {CompareImmutable} from '~/src/common/utils/utils';
import config from '~/src/config/config.js';
import {GroupActions, GroupConstants} from '~/src/security/group/group-actions';
import {StationGroupValidations} from '~/src/security/group/group-store';

const userGroupProps = [
    'name', 'isActive', 'description',
    'updatedDate', 'updatedBy'
];

class Info extends Component {
    static get propTypes() {
        return {
            mode: PropTypes.string.isRequired,
            disabled: PropTypes.bool,
            stationGroup: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true,
            permissions: {}
        };
    }

    shouldComponentUpdate(nextProps) {
        if (!CompareImmutable(nextProps.stationGroup, this.props.stationGroup, userGroupProps)) {
            return true;
        }
        return false;
    }

    render() {
        let updatedDate = <div>N/A</div>;
        let lastModifier = <div>N/A</div>;
        let additionalInformation;

        if (this.props.mode === 'edit') {
            if (this.props.stationGroup.get('updatedDate')) {
                updatedDate = Moment(this.props.stationGroup.get('updatedDate')).tz(config.DefaultTimezone).format(this.context.intl.messages['datetime-format']);
            } else {
                updatedDate = '';
            }

            lastModifier = this.props.stationGroup.get('updatedBy');

            additionalInformation = <FormRow>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['station-groups.edit.last-modified-date']}</ControlLabel>
                    <FormControl.Static>{updatedDate}</FormControl.Static>
                </FormGroup>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['station-groups.edit.last-modifier']}</ControlLabel>
                    <FormControl.Static>{lastModifier}</FormControl.Static>
                </FormGroup>
            </FormRow>;
        }

        return (
            <FormSection title={this.context.intl.messages['station-groups.create.info.title']} iconClass="fas fa-info-circle">
                <FormRow>
                    <FormItem attr="name"
                        disabled={this.props.disabled}
                        label={this.context.intl.messages['station-groups.create.info.name']}
                        model={this.props.stationGroup}
                        placeholder={this.context.intl.messages['station-groups.create.info.name.placeholder']}
                        setter={GroupActions.updateGroup.bind(this, GroupConstants.STATION)}
                        type="text"
                        validations={StationGroupValidations.name.validations}
                    />
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['station-groups.create.info.status']}</ControlLabel>
                        <FormItem attr="isActive"
                            disabled={this.props.disabled}
                            label="Active"
                            model={this.props.stationGroup}
                            md={5}
                            setter={GroupActions.updateGroup.bind(this, GroupConstants.STATION)}
                            type="checkbox"
                        />
                    </FormGroup>
                </FormRow>
                <FormRow>
                    <FormItem attr="description"
                        disabled={this.props.disabled}
                        label={this.context.intl.messages['station-groups.create.info.description']}
                        model={this.props.stationGroup}
                        placeholder={this.context.intl.messages['station-groups.create.info.description.placeholder']}
                        setter={GroupActions.updateGroup.bind(this, GroupConstants.STATION)}
                        type="textarea"
                    />
                </FormRow>
                <hr />
                {additionalInformation}
            </FormSection>
        );
    }
}

export default Info;
