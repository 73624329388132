/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {ControlLabel, FormControl, FormGroup} from 'react-bootstrap';
import {Link} from 'react-router';

import BaseSelect from '../../../common/base-select/base-select';
import {FormItem, FormRow, FormSection} from '../../../common/form/form';
import {WithValidations} from '../../../common/validations/validations';
import config from '../../../config/config.js';
import FileInfo from '../../../hardac/timeline/summary/file-info';
import AssetStore from '../../asset-store';
import {TimelineAssetActions} from '../timeline-asset-actions';
import TimelineAssetStore, {TimelineAssetValidations} from '../timeline-asset-store';

class Summary extends React.Component {
    static get propTypes() {
        return {
            clips: PropTypes.instanceOf(Immutable.List).isRequired,
            disabled: PropTypes.bool,
            isHardac: PropTypes.bool,
            oapShowCodes: PropTypes.instanceOf(Immutable.List),
            timeline: PropTypes.instanceOf(Immutable.Map).isRequired,
            tracks: PropTypes.instanceOf(Immutable.List).isRequired,
            viewingMode: PropTypes.string.isRequired,
        };
    }

    static get defaultProps() {
        return {
            disabled: true,
            isHardac: false,
            oapShowCodes: Immutable.List(),
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            isContentTypeDirty: false,
            isDeliveryTypeDirty: false,
            isProtectionTypeDirty: false,
            selectedContentType: TimelineAssetStore.getContentType(this.props.timeline.get('contentType')),
            selectedDeliveryType: AssetStore.getDeliveryType(this.props.timeline.get('deliveryType')),
            selectedProtectionType: TimelineAssetStore.getProtectionType(this.props.timeline.get('protectionType'))
        };

        this.handleSelectContentType = this.handleSelectContentType.bind(this);
        this.handleSelectDeliveryType = this.handleSelectDeliveryType.bind(this);
        this.handleSelectProtectionType = this.handleSelectProtectionType.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            selectedContentType: TimelineAssetStore.getContentType(nextProps.timeline.get('contentType')),
            selectedDeliveryType: AssetStore.getDeliveryType(nextProps.timeline.get('deliveryType')),
            selectedProtectionType: TimelineAssetStore.getProtectionType(nextProps.timeline.get('protectionType')),
        });
        return;
    }

    handleSelectContentType(value) {
        if (!this.state.isContentTypeDirty) {
            this.setState({isContentTypeDirty: true});
        }

        if (value === null) {
            TimelineAssetActions.update('contentType', null);
            return;
        }
        TimelineAssetActions.update('contentType', value.id);
        return;
    }

    handleSelectDeliveryType(value) {
        if (!this.state.isDeliveryTypeDirty) {
            this.setState({isDeliveryTypeDirty: true});
        }

        if (value === null) {
            TimelineAssetActions.update('deliveryType', null);
            return;
        }
        TimelineAssetActions.update('deliveryType', value.id);
        return;
    }

    handleSelectProtectionType(value) {
        if (!this.state.isProtectionTypeDirty) {
            this.setState({isProtectionTypeDirty: true});
        }

        if (value !== undefined && value !== null) {
            TimelineAssetActions.update('protectionType', value.id);
        } else {
            TimelineAssetActions.update('protectionType', null);
        }
    }

    render() {
        const {
            createdBy,
            createdDate,
            updatedBy,
            updatedDate
        } = this.props.timeline.toJS();

        let createdDateFormatted = '-';
        if (createdDate) {
            createdDateFormatted = Moment(createdDate).tz(config.DefaultTimezone).format(this.context.intl.messages['datetime-format']);
        }
        let updatedDateFormatted = '-';
        if (updatedDate) {
            updatedDateFormatted = Moment(updatedDate).tz(config.DefaultTimezone).format(this.context.intl.messages['datetime-format']);
        }

        let editText, editUrl;
        if (this.props.isHardac) {
            editText = 'asset.timeline.summary.brainiac.button';
            editUrl = `/assets/timeline/${this.props.timeline.get('id')}`;
        } else {
            editText = 'asset.timeline.summary.hardac.button';
            editUrl = `/hardac/timeline/${this.props.timeline.get('id')}`;
        }

        return (
            <div>
                <h3><i className="fas fa-stream"></i>&nbsp;{this.context.intl.messages['asset.timeline.summary.timeline-info']}</h3>
                <FormSection>
                    <FormRow>
                        <FormItem
                            attr="assetName"
                            label={this.context.intl.messages['asset.timeline.summary.name']}
                            disabled={this.props.disabled}
                            model={this.props.timeline}
                            required
                            setter={TimelineAssetActions.update}
                            type="text"
                            validations={TimelineAssetValidations.assetName.validations}
                        />
                    </FormRow>
                    <FormRow>
                        <FormGroup validationState={this.getValidationState(
                            this.state.selectedDeliveryType,
                            this.state.isDeliveryTypeDirty,
                            TimelineAssetValidations.deliveryType.validations
                        )}>
                            <ControlLabel>{this.context.intl.messages['asset.timeline.summary.delivery-type']}&nbsp;<span className="text-red">*</span></ControlLabel>
                            <BaseSelect
                                classNamePrefix="dropdown"
                                disabled={this.props.disabled}
                                isClearable={true}
                                multi={false}
                                name="deliveryType"
                                onChange={this.handleSelectDeliveryType}
                                options="deliveryType"
                                store={AssetStore}
                                value={this.state.selectedDeliveryType}
                            />
                        </FormGroup>
                        <FormGroup validationState={this.getValidationState(
                            this.state.selectedProtectionType,
                            this.state.isProtectionTypeDirty,
                            TimelineAssetValidations.protectionType.validations
                        )}>
                            <ControlLabel>{this.context.intl.messages['asset.timeline.summary.protection-type']}&nbsp;<span className="text-red">*</span></ControlLabel>
                            <BaseSelect
                                classNamePrefix="dropdown"
                                disabled={this.props.disabled}
                                isClearable={true}
                                multi={false}
                                name="selectedProtectionType"
                                onChange={this.handleSelectProtectionType}
                                options="selectedProtectionType"
                                store={TimelineAssetStore}
                                value={this.state.selectedProtectionType}
                            />
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <FormGroup validationState={this.getValidationState(
                            this.state.selectedContentType,
                            this.state.isContentTypeDirty,
                            TimelineAssetValidations.contentType.validations
                        )}>
                            <ControlLabel>{this.context.intl.messages['asset.timeline.summary.content-type']}&nbsp;<span className="text-red">*</span></ControlLabel>
                            <BaseSelect
                                classNamePrefix="dropdown"
                                disabled={this.props.disabled}
                                isClearable={true}
                                multi={false}
                                name="contentType"
                                onChange={this.handleSelectContentType}
                                options="contentType"
                                store={TimelineAssetStore}
                                value={this.state.selectedContentType}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['common.status']}</ControlLabel>
                            <FormItem
                                attr="active"
                                label={this.context.intl.messages['asset.timeline.summary.active']}
                                disabled={this.props.disabled}
                                model={this.props.timeline}
                                setter={TimelineAssetActions.update}
                                type="checkbox"
                            />
                        </FormGroup>
                    </FormRow>
                </FormSection>
                <h3 className="padding-bottom-10">
                    <i className="icon-hardac-logomark"></i>&nbsp;{this.context.intl.messages['asset.timeline.summary.editor-info']}
                    <a
                        className="btn btn-default-outline Mr(3px) Mb(3px) pull-right"
                        href={editUrl}
                        target="_blank">
                        <i className="fas fa-edit"></i>&nbsp;{this.context.intl.messages[editText]}
                    </a>
                </h3>
                <FormSection>
                    <FormRow>
                        <FormGroup className="text-center">
                            <h4><i className="fas fa-line-columns"></i>&nbsp;{this.context.intl.messages['hardac.timeline.summary.tracks']}</h4>
                            <Link
                                target="_blank"
                                title=""
                                to={`/hardac/timeline/${this.props.timeline.get('id')}/audio`}>
                                {this.props.tracks.size || '-'}
                            </Link>
                        </FormGroup>
                        <FormGroup className="text-center">
                            <h4><i className="fas fa-cut"></i>&nbsp;{this.context.intl.messages['hardac.timeline.summary.clips']}</h4>
                            <Link
                                target="_blank"
                                to={`/hardac/timeline/${this.props.timeline.get('id')}/clips`}>
                                {this.props.clips.size || '-'}
                            </Link>
                        </FormGroup>
                    </FormRow>
                </FormSection>
                <FileInfo oapShowCodes={this.props.oapShowCodes} isEditInfo={true} timeline={this.props.timeline} viewingMode={this.props.viewingMode}/>
                <hr />
                <FormRow>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['hardac.timeline.summary.created']}</ControlLabel>
                        <FormControl.Static>{createdDateFormatted}</FormControl.Static>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['hardac.timeline.summary.created-by']}</ControlLabel>
                        <FormControl.Static>{createdBy}</FormControl.Static>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['hardac.timeline.summary.updated']}</ControlLabel>
                        <FormControl.Static>{updatedDateFormatted}</FormControl.Static>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['hardac.timeline.summary.updated-by']}</ControlLabel>
                        <FormControl.Static>{updatedBy}</FormControl.Static>
                    </FormGroup>
                </FormRow>
            </div>
        );
    }
}

export default WithValidations(Summary);
