/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {AccountWizardConstants} from './account-wizard-actions';

import {DiffImmutableObjects} from '~/src/common/utils/dev-utils';
import Validations, {ERRORTYPE, SEVERITY} from '~/src/common/validations/validations';
import Dispatcher from '~/src/dispatcher/dispatcher';
import PartnerStore from '~/src/partner/partner-store';
import SessionStore from '~/src/user/session/session-store';
import {UserConstants} from '~/src/user/user-actions';

const findInConstant = function(constants, objectId) {
    return Object.keys(constants)
        .map(k => constants[k])
        .find(obj => objectId === obj.id);
};

const AccountWizardValidations = {
    unsavedChanges: {
        validations: [Validations.info(() => 'Accounts cannot be created until all changes have been saved.', () => {
            if (store.getState().get('hasUnsavedChanges')) {
                return false;
            }

            const originalBatch = store.getState().get('originalBatch');
            const currentBatch = store.getComparableBatchObject(store.getState());

            const diff = DiffImmutableObjects(originalBatch, currentBatch);
            const result = !(diff && diff.length > 0);

            return result;
        })]

    },
    defaultPartner: {
        label: 'partners.primary-partner',
        validations: [Validations.missing()]
    },
    exceededUsers: {
        validations: [Validations.custom(() => 'The maximum number of users per batch is 200. Users were not added. Please try again with a smaller quantity.', () => {
            const userValidations = store.getState().getIn(['userRowValidations', 'all']).toJS();
            let hasValidUsers = false;
            if (Object.keys(userValidations).length) {
                hasValidUsers = Object.keys(userValidations).some(key => userValidations[key].userIngestValidations.length === 0);
            }
            if (hasValidUsers) {
                return true;
            }
            return !store.getState().get('showExceededUsers');
        })]
    },
    name: {
        validations: [Validations.custom(() => 'Batch name cannot be more than 250 characters long.', () => {
            let isValid = true;
            const batchName = store.getState().get('accountWizardProcess').get('name');
            const batchNameRules = [Validations.max(250)];

            const validate = (rules, value) => {
                rules.forEach(r => {
                    if (!r.validate(value)) {
                        isValid = false;
                    }
                });
            };

            validate(batchNameRules, batchName);
            return isValid;
        })]
    },
    partners: {
        label: 'partners.web-partners',
        validations: [Validations.missing()]
    },
    selectedClientRepGroupIds: {
        label: 'accounts.account-wizard.contact-client-rep-group.title',
        validations: [Validations.missing()]
    },
    selectedEventId: {
        validations: [
            Validations.asWarning({
                validate: () => {
                    const eventId = store.getState().get('selectedEventId');
                    const originalEventId = store.getState().get('originalEventId');
                    if (originalEventId === eventId) {
                        return true;
                    }
                    return false;
                },
                getMessage: () => 'The selected Event was updated. This batch should be saved before creating accounts for the changes to take effect.',
                icon: 'fas fa-brake-warning'
            })
        ]
    }
    // BRAIN-4115: users row validations now come from the api
};

class AccountWizardStore extends ReduceStore {
    getComparableBatchObject(state) {
        return Immutable.Map({
            // roles, clientRepGroups, partners, groups and permission package are difficult to compare, because of the id vs groupId field when added and after saved
            // so we only store the simple fields for comparison to detect unsaved changes
            accountWizardProcess: state.get('accountWizardProcess'),
            requestAdditionalSecurity: state.get('requestAdditionalSecurity'),
            selectedEventId: state.get('selectedEventId'),
            users: state.get('users').map(u => u.delete('selected').delete('editing')), // disregard editing and selected field values
        });
    }

    getInitialState() {
        const autoRefreshList = JSON.parse(localStorage.getItem('__autoRefreshBrowseAccountWizard') || '[]');
        const userId = SessionStore.getUserId();
        const autoRefreshFromUser = autoRefreshList.find(item => item.userId === userId);
        return Immutable.Map({
            autoRefresh: Immutable.Map(autoRefreshFromUser),
            autoRefreshObservable: null,
            batches: Immutable.List(),
            clientRepGroups: Immutable.List(),
            contactUser: undefined,
            defaultPartner: Immutable.Map(),
            groups: Immutable.List(),
            hasUnsavedChanges: false,
            accountWizardProcess: Immutable.Map(),
            originalBatch: Immutable.Map(),
            partners: Immutable.List(),
            requestAdditionalSecurity: null,
            roles: Immutable.List(),
            selectedClientRepGroupIds: Immutable.List(),
            selectedEventId: undefined,
            showPreloader: false,
            showExceededUsers: false,
            total: 0,
            users: Immutable.List(),
            userRowValidations: Immutable.Map({
                all: Immutable.Map(),
                unique: Immutable.List(),
            })
        });
    }

    getStatusType(statusTypeId) {
        return findInConstant(AccountWizardConstants.BATCH_STATUS_TYPES, statusTypeId);
    }

    getValidations(combineUserRowValidations = true) {
        // This store is unique in that we are combining the AccountWizardValidations along with the userRowValidations results from the API!
        let result = Validations.validate(this.getState(), AccountWizardValidations);
        if (combineUserRowValidations) {
            const userRowValidations = this.getState().getIn(['userRowValidations', 'unique']);
            userRowValidations.toJS().forEach(urv => {
                const accountAlert = UserConstants.ACCOUNT_ALERTS[urv.userValidationType];

                if (accountAlert) {
                    let errorType = ERRORTYPE.ERROR;
                    let severity = SEVERITY.ALERT;

                    if (urv.severityType === 'WARNING') {
                        errorType = ERRORTYPE.WARNING;
                        severity = SEVERITY.WARNING;
                    }

                    const validation = {
                        errorType,
                        severity,
                        icon: accountAlert.icon,
                        message: accountAlert.errorText,
                    };
                    result.push(validation);
                } else {
                    console.error(`account-wizard-store.getValidations Unable to find matching account alert for user validation type: ${urv.userValidationType}`);
                }
            });
        }

        return result;
    }

    getUserRowValidationsWithSeverity(severity) {
        return this.getState().getIn(['userRowValidations', 'all']).filter(u => u.get('userIngestValidations').filter(v => v.get('severityType') === severity).size !== 0).size;
    }

    reduce(state, action) {
        switch (action.actionType) {
        case AccountWizardConstants.AUTO_REFRESH.SET_AUTO_REFRESH:
            const userId = action.userId;

            state = state.set('autoRefresh', Immutable.Map({
                isChecked: action.isChecked,
                userId
            }));

            let autoRefreshList = JSON.parse(localStorage.getItem('__autoRefreshBrowseAccountWizard') || '[]');
            const currentUserIndex = autoRefreshList.findIndex(item => item.userId === userId);
            if (currentUserIndex === -1) {
                autoRefreshList.push({
                    isChecked: action.isChecked,
                    userId
                });
            } else {
                autoRefreshList[currentUserIndex].isChecked = action.isChecked;
            }
            localStorage.setItem('__autoRefreshBrowseAccountWizard', JSON.stringify(autoRefreshList));
            break;

        case AccountWizardConstants.AUTO_REFRESH.SET_AUTO_REFRESH_OBSERVABLE:
            state = state.set('autoRefreshObservable', action.autoRefreshObs);
            break;

        case AccountWizardConstants.CLEAR:
            state = this.getInitialState();
            break;
        case AccountWizardConstants.CLIENT_REP_GROUPS.GET.SUCCESS:
            state = state.merge({
                clientRepGroups: action.clientRepGroups
            });
            break;
        case AccountWizardConstants.SET.PERMISSION_PACKAGE:
            const primaryPartner = PartnerStore.getPartner(action.defaultPartnerId);
            const partners = action.webPartnerIds.map(partner => PartnerStore.getPartner(partner));
            state = state.merge({
                defaultPartner: primaryPartner,
                groups: action.groups.sortBy(g => g.get('name')),
                partners: partners,
                roles: action.roles.sortBy(r => r.get('name')),
                hasUnsavedChanges: true,
            });
            break;

        case AccountWizardConstants.FINDBY.SUCCESS:
            state = state.merge({
                groups: action.groups,
                accountWizardProcess: action.accountWizardProcess,
                originalEventId: action.selectedEventId,
                roles: action.roles,
                selectedClientRepGroupIds: action.clientRepGroups,
                selectedEventId: action.selectedEventId,
                users: action.users,
                hasUnsavedChanges: false,
            });

            if (action.partners.size) {
                state = state.merge({
                    defaultPartner: action.defaultPartner,
                    partners: action.partners,
                });
            }

            if (action.contactUser) {
                state = state.set('contactUser', action.contactUser);
            }

            const originalBatch = this.getComparableBatchObject(state);
            state = state.set('originalBatch', originalBatch);
            break;

        case AccountWizardConstants.GET.ERROR:
            state = state.set('showPreloader', false);
            break;

        case AccountWizardConstants.GET.START:
            state = state.set('showPreloader', true);
            break;

        case AccountWizardConstants.GET.SUCCESS:
            state = state.merge({
                batches: action.batches,
                showPreloader: false,
                total: action.total
            });
            break;


        case AccountWizardConstants.SAVE.SUCCESS:
            state = state.merge({
                originalEventId: action.originalEventId,
                users: action.users,
                hasUnsavedChanges: false,
            });

            state = state.set('originalBatch', this.getComparableBatchObject(state));
            break;

        case AccountWizardConstants.SET.BATCH_NAME:
            state = state.setIn(['accountWizardProcess', action.attr], action.value);
            break;

        case AccountWizardConstants.SET.BATCH_PROP:
            state = state.set(action.attr, action.value);
            break;

        case AccountWizardConstants.SET.GROUPS:
            state = state.merge({
                groups: action.selected,
                hasUnsavedChanges: true,
            });
            break;
        case AccountWizardConstants.SET.ROLES:
            state = state.merge({
                roles: action.selected,
                hasUnsavedChanges: true,
            });
            break;
        case AccountWizardConstants.SET.PARTNERS:
            state = state.set('partners', action.selected);
            const partnerIndex = state.get('partners').toJS().findIndex(partner =>
                partner.partnerId === state.getIn(['defaultPartner', 'partnerId'])
            );
            if (partnerIndex === -1) {
                state = state.set('defaultPartner', Immutable.Map());
            }
            state = state.set('hasUnsavedChanges', true);
            break;

        case AccountWizardConstants.SET.CLIENT_REP_GROUPS:
            state = state.set('selectedClientRepGroupIds', action.selectedClientRepGroupIds);
            state = state.set('hasUnsavedChanges', true);
            break;

        case AccountWizardConstants.SET.DEFAULT_PARTNER:
            state = state.set('defaultPartner', action.defaultPartner);
            break;

        case AccountWizardConstants.USERS.ADD_NEW_USER: {
            state = state.update('users', users => {
                users = users.unshift(action.user);
                return users;
            });

            break;
        }

        case AccountWizardConstants.USERS.CLEAR_EDITING: {
            state = state.update('users', users => users.map(u => {
                u = u.delete('editing');
                return u;
            }));
            break;
        }

        case AccountWizardConstants.USERS.REMOVE_USER: {
            state = state.update('users', users => users.filter(u => u.get('id') !== action.id));
            // Remove any validation errors for the users
            state = state.deleteIn(['userRowValidations', 'all', `${action.id}`]);

            let unique = [];
            state.getIn(['userRowValidations', 'all']).forEach(c => {
                c.get('userIngestValidations').forEach(v => {
                    if (!unique.find(e => e.userValidationType === v.get('userValidationType'))) {
                        unique.push(v.toJS());
                    }
                });
            });

            state = state.setIn(['userRowValidations', 'unique'], Immutable.fromJS(unique));
            break;
        }

        case AccountWizardConstants.USERS.SELECT_ALL:
            state = state.update('users', users => users.map(u => {
                u = u.set('selected', action.selected);
                return u;
            }));
            break;

        case AccountWizardConstants.USERS.UPDATE_USER: {
            const index = state.get('users').findIndex(u => u.get('id') === action.id);

            state = state.setIn(['users', index, action.attr], action.value);
            break;
        }

        case AccountWizardConstants.VALIDATIONS.GET.SUCCESS: {
            state = state.merge({
                userRowValidations: {
                    all: action.userRowValidations,
                    unique: action.uniqueUserRowValidations
                }
            });
            break;
        }

        case AccountWizardConstants.VALIDATIONS_ROW.GET.SUCCESS: {
            state = state.setIn(['userRowValidations', 'all', `${action.id}`, 'userIngestValidations'], action.userRowValidations.get('userIngestValidations'));

            let unique = [];
            state.getIn(['userRowValidations', 'all']).forEach(c => {
                c.get('userIngestValidations').forEach(v => {
                    if (!unique.find(e => e.userValidationType === v.get('userValidationType'))) {
                        unique.push(v.toJS());
                    }
                });
            });

            state = state.setIn(['userRowValidations', 'unique'], Immutable.fromJS(unique));
            break;
        }

        case AccountWizardConstants.SET.ADDITIONAL_SECURITY:
            state = state.set('requestAdditionalSecurity', action.value);
            break;

        case AccountWizardConstants.SET.EXCEEDED_USERS:
            state = state.set('showExceededUsers', action.showExceededUsers);
            break;
        }

        return state;

    }
}

const store = new AccountWizardStore(Dispatcher);

export default store;
export {AccountWizardValidations};
