/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */


import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, ControlLabel, Modal} from 'react-bootstrap';
import Select from 'react-select';

class AddTabModal extends Component {
    static get propTypes() {
        return {
            availableTabs: PropTypes.instanceOf(Immutable.Map).isRequired,
            onAdd: PropTypes.func.isRequired,
            onHide: PropTypes.func.isRequired,
            show: PropTypes.bool.isRequired,
            tabsAndConditions: PropTypes.array.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            selected: []
        };

        this.handleAdd = this.handleAdd.bind(this);
        this.handleSelectTab = this.handleSelectTab.bind(this);
    }


    handleAdd() {
        this.props.onAdd(this.state.selected[0]);
        // close the modal.
        this.props.onHide();
        this.setState(() => {
            return {
                selected: []
            };
        });
        return;
    }

    handleSelectTab(value) {
        this.setState(() => {
            return {
                selected: [value]
            };
        });
        return;
    }

    render() {
        const options = this.props.tabsAndConditions.reduce((result, tabsGroup) => {
            const foundTabs = tabsGroup.tabs.filter(tab => !this.props.availableTabs.get(tab.value));
            if (foundTabs.length) {
                result = result.concat(foundTabs);
            }
            return result;
        }, []);
        return (
            <Modal onHide={this.props.onHide} show={this.props.show}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="text-center">{this.context.intl.messages['common.create.add-tab.modal.title']}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <ControlLabel>{this.context.intl.messages['common.create.add-tab.modal.select']}</ControlLabel>
                    <Select
                        classNamePrefix="dropdown"
                        getOptionLabel={data => this.context.intl.messages[data.message]}
                        getOptionValue={data => data.value}
                        isClearable={false}
                        isMulti={false}
                        name="tab"
                        onChange={this.handleSelectTab}
                        options={options}
                        placeholder={this.context.intl.messages['common.please-select']}
                        value={this.state.selected}
                    />
                </Modal.Body>

                <Modal.Footer>
                    <Button bsStyle="primary" onClick={this.handleAdd} disabled={!this.state.selected.length}><i className="fas fa-plus-square"></i>&nbsp;{this.context.intl.messages['common.create.add-tab.modal.button']}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default AddTabModal;
