/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ControlLabel, FormControl, FormGroup} from 'react-bootstrap';

import BaseSelect from '../../../../common/base-select/base-select';
import {FormItem, FormRow, FormSection} from '../../../../common/form/form';
import {CompareImmutable} from '../../../../common/utils/utils';
import config from '../../../../config/config.js';
import {AssetConstants} from '../../../asset-actions';
import AssetStore from '../../../asset-store';
import {AudioActions} from '../../audio-actions';
import AudioStore, {AudioAssetValidations} from '../../audio-store';


/**
 * Generic Select event handler.
 */
let handleSelect = function(attr) {
    return function(value) {
        if (value === null) {
            AudioActions.update(attr, null);
            return;
        }

        AudioActions.update(attr, value.id);
        return;
    };
};

/**
 * Audio props to watch.
 */
let audioProps = [
    'active', 'assetComment', 'contentType', 'copyrightYear', 'copyrightText',
    'description', 'keyword', 'mediaKey', 'name',
    'runtime', 'updatedBy', 'updatedDate'
];

class AudioAsset extends Component {

    static get propTypes() {
        return {
            asset: PropTypes.instanceOf(Immutable.Map).isRequired,
            disabled: PropTypes.bool,
            mode: PropTypes.string
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true,
            mode: ''
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            isMimeTypeDirty: false,
            selectedContentType: AudioStore.getContentType(this.props.asset.get('contentType')),
            selectedMimeType: AssetStore.getAssetMimeType(AssetConstants.MIME_TYPES_AUDIO, this.props.asset.get('mimeType'))
        };

        this.handleSelectContentType = this.handleSelectContentType.bind(this);
        this.handleSelectMimeType = this.handleSelectMimeType.bind(this);
    }

    componentWillReceiveProps(nextProps/*, nextContext*/) {
        this.setState({
            selectedContentType: AudioStore.getContentType(nextProps.asset.get('contentType')),
            selectedMimeType: AssetStore.getAssetMimeType(AssetConstants.MIME_TYPES_AUDIO, nextProps.asset.get('mimeType')),
        });

        return;
    }

    shouldComponentUpdate(nextProps, nextState/*, nextContext*/) {
        if (nextProps.disabled !== this.props.disabled ||
            !CompareImmutable(nextProps.asset, this.props.asset, audioProps)) {
            return true;
        }

        if (nextState.selectedContentType !== this.state.selectedContentType ||
            nextState.selectedMimeType !== this.state.selectedMimeType) {
            return true;
        }

        return false;
    }

    handleSelectContentType(value) {
        if (!this.state.isContentTypeDirty) {
            this.setState({isContentTypeDirty: true});
        }
        // TODO: call the generic function here.
        handleSelect('contentType')(value);
        return;
    }

    handleSelectMimeType(value) {
        if (!this.state.isMimeTypeDirty) {
            this.setState({isMimeTypeDirty: true});
        }
        handleSelect('mimeType')(value);
        return;
    }

    render() {
        let updatedDate = <div>N/A</div>;
        let updatedBy = <div>N/A</div>;
        let additionalInformation;

        if (this.props.mode === 'edit') {

            if (this.props.asset.get('updatedDate')) {
                updatedDate = Moment(this.props.asset.get('updatedDate')).tz(config.DefaultTimezone).format(this.context.intl.messages['datetime-format']);
            } else {
                updatedDate = '-';
            }

            if (this.props.asset.get('updatedBy')) {
                updatedBy = this.props.asset.get('updatedBy');
            } else {
                updatedBy = '-';
            }

            additionalInformation = <FormRow>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['asset.edit.last-modified-date']}</ControlLabel>
                    <FormControl.Static>{updatedDate}</FormControl.Static>
                </FormGroup>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['asset.edit.last-modifier']}</ControlLabel>
                    <FormControl.Static>{updatedBy}</FormControl.Static>
                </FormGroup>
            </FormRow>;
        }

        return (
            <div>
                <h3><i className="fas fa-info-circle"></i>{this.context.intl.messages['asset.info']}</h3>
                <FormSection>
                    <FormRow>
                        <FormItem
                            attr="name"
                            label={this.context.intl.messages['asset.audio.create.name']}
                            disabled={this.props.disabled}
                            model={this.props.asset}
                            setter={AudioActions.update}
                            type="text"
                            validations={AudioAssetValidations.name.validations}
                        />
                        <FormItem
                            attr="assetComment"
                            label={this.context.intl.messages['asset.audio.create.assetComment']}
                            disabled={this.props.disabled}
                            model={this.props.asset}
                            setter={AudioActions.update}
                            type="text"
                            validations={AudioAssetValidations.assetComment.validations}
                        />
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['asset.audio.create.contentType']}&nbsp;<span className="text-red">*</span></ControlLabel>
                            <BaseSelect
                                disabled={this.props.disabled}
                                name={this.context.intl.messages['asset.audio.create.contentType']}
                                onChange={this.handleSelectContentType}
                                options="contentType"
                                store={AudioStore}
                                value={this.state.selectedContentType}
                                validations={AudioAssetValidations.contentType.validations}
                            />
                        </FormGroup>
                        <FormItem attr="mediaKey"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['asset.audio.create.mediakey']}
                            setter={AudioActions.update}
                            model={this.props.asset}
                            type="text"
                            validations={AudioAssetValidations.mediaKey.validations}
                        />
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['asset.image.mimeType']}&nbsp;</ControlLabel>
                            <BaseSelect
                                disabled={this.props.disabled}
                                name={this.context.intl.messages['asset.image.mimeType']}
                                onChange={this.handleSelectMimeType}
                                options="audioMimeTypes"
                                store={AssetStore}
                                value={this.state.selectedMimeType}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['common.status']}</ControlLabel>
                            <FormItem
                                attr="active"
                                disabled={this.props.disabled}
                                label={this.context.intl.messages['common.active']}
                                model={this.props.asset}
                                setter={AudioActions.update}
                                type="bool-checkbox"
                            />
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <FormItem attr="copyrightYear"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['asset.audio.create.copyrightYear']}
                            md={3}
                            setter={AudioActions.update}
                            model={this.props.asset}
                            type="text"
                            validations={AudioAssetValidations.copyrightYear.validations}
                        />
                        <FormItem attr="copyrightText"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['asset.audio.create.copyrightText']}
                            setter={AudioActions.update}
                            model={this.props.asset}
                            type="text"
                            validations={AudioAssetValidations.copyrightText.validations}
                        />
                    </FormRow>
                    <FormRow>
                        <FormItem attr="description"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['asset.audio.create.description']}
                            setter={AudioActions.update}
                            model={this.props.asset}
                            type="textarea"
                            validations={AudioAssetValidations.description.validations}
                        />
                    </FormRow>
                    <FormRow>
                        <FormItem attr="runtime"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['asset.audio.create.runtime']}
                            setter={AudioActions.update}
                            model={this.props.asset}
                            type="text"
                            validations={AudioAssetValidations.runtime.validations}
                        />
                        <FormItem attr="keyword"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['asset.audio.create.keyword']}
                            setter={AudioActions.update}
                            model={this.props.asset}
                            type="text"
                            validations={AudioAssetValidations.keyword.validations}
                        />
                    </FormRow>
                    <hr />
                    {additionalInformation}
                </FormSection>
            </div>
        );
    }

}

export default AudioAsset;
