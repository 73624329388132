/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {PrivacyConstants} from './privacy-actions';

import Validations from '~/src/common/validations/validations';
import Dispatcher from '~/src/dispatcher/dispatcher';

const PrivacyRequestValidations = {
    email: {
        label: 'privacy.summary.email',
        validations: [Validations.email, Validations.max(50), Validations.missing()]
    },
    regulationType: {
        label: 'privacy.browse.regulation-type',
        validations: [Validations.missing()]
    },
    requestType: {
        label: 'privacy.browse.request-type',
        validations: [Validations.missing()]
    },
    requestId: {
        label: 'privacy.summary.irr-id',
        validations: [Validations.max(36), Validations.missing()]
    }
};

class PrivacyStore extends ReduceStore {
    getInitialState() {
        return Immutable.Map({
            privacyRequest: Immutable.Map(),
            privacyRequests: Immutable.List(),
            showPreloader: false,
            total: 0,
        });
    }

    getValidations() {
        return Validations.validate(this.getState().get('privacyRequest'), PrivacyRequestValidations);
    }

    reduce(state, action) {
        switch (action.actionType) {

        case PrivacyConstants.CLEAR:
            state = this.getInitialState();
            break;

        case PrivacyConstants.GET.START:
        case PrivacyConstants.FIND_BY_ID.START:
        case PrivacyConstants.CREATE_REQUEST.START:
            state = state.merge({
                showPreloader: true,
            });
            break;

        case PrivacyConstants.GET.ERROR:
        case PrivacyConstants.FIND_BY_ID.ERROR:
        case PrivacyConstants.CREATE_REQUEST.ERROR:
            state = state.merge({
                showPreloader: false,
            });
            break;

        case PrivacyConstants.FIND_BY_ID.SUCCESS:
            state = state.merge({
                privacyRequest: action.privacyRequest,
                showPreloader: false,
            });
            break;

        case PrivacyConstants.GET.SUCCESS:
            state = state.merge({
                privacyRequests: action.privacyRequests,
                showPreloader: false,
                total: action.total,
            });
            break;

        case PrivacyConstants.UPDATE:
            state = state.setIn(['privacyRequest', ...action.attr.split('.')], action.value);
            break;
        }
        return state;
    }
}

const store = new PrivacyStore(Dispatcher);

export default store;
export {
    PrivacyRequestValidations,
};
