/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import jQuery from 'jquery';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {Sort} from '../../common/local-search/filter';
import {WithStoreOnRoute} from '../../common/store-on-route';
import {PushNotificationActions} from '../push-notification-actions';

require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('datatables.net-responsive-bs');


class Table extends Component {

    static get propTypes() {
        return {
            displayLinks: PropTypes.bool,
            filters: PropTypes.string,
            pushNotifications: PropTypes.instanceOf(Immutable.List),
            pushNotificationsCount: PropTypes.func,
            showInactive: PropTypes.bool
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
            router: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            displayLinks: false,
            filters: '',
            showInactive: false,
            pushNotifications:undefined,
            pushNotificationsCount:undefined
        };
    }

    constructor(props) {
        super(props);

        this.getSortByClass = this.getSortByClass.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.handleSort = this.handleSort.bind(this);
    }

    componentDidMount() {
        this.$table = jQuery('#push-notifications-table');
        this.$tableAPI = this.$table.DataTable({
            autoWidth: false,
            columnDefs: [{
                className: 'control',
                targets:   -1,
                width: 20
            }, {
                targets: 'no-sort',
                orderable: false
            }],
            iDisplayLength: 1,
            info: false,
            ordering: false,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        });

        PushNotificationActions.get(0, 9999);

        window.addEventListener('resize', this.handleResize);

        this.handleResize();
        return;
    }

    componentWillUpdate(nextProps) {
        this.$tableAPI.clear();

        let filterRegExp = new RegExp(nextProps.filters, 'i');

        let pushNotifications = nextProps.pushNotifications.filter(pushNotification => {
            if (!nextProps.showInactive && pushNotification.get('isActive') === 0) {
                return;
            }
            let keep = pushNotification;
            if (keep && nextProps.filters) {
                keep = pushNotification.get('messageTitle').match(filterRegExp);
            }
            return keep;
        });

        pushNotifications = Sort(pushNotifications, this.getRouteState(nextProps).get('sortBy', 'id'), this.getRouteState(nextProps).get('sortDir', 'asc'));
        this.props.pushNotificationsCount(pushNotifications.size);
        pushNotifications.forEach((pushNotification) => {

            let pushNotificationId = pushNotification.get('id');
            let pushNotificationMessageTitle = pushNotification.get('messageTitle');
            let pushNotificationSentDate = Moment(pushNotification.get('sentDate'));
            if (pushNotificationSentDate.isValid()) {
                pushNotificationSentDate = pushNotificationSentDate.format(this.context.intl.messages['datetime-format']);
            } else {
                pushNotificationSentDate = '-';
            }
            let pushNotificationStatus = '<span><i class="fas fa-exclamation-triangle text-red"></i> Incomplete</span>';
            let pushNotificationTarget = '';
            if (pushNotification.get('mediaAssetId')) {
                pushNotificationTarget = `Asset: ${pushNotification.get('mediaAssetId')}`;
            } else if (pushNotification.get('titleId')) {
                pushNotificationTarget = `Title: <a href="titles/${pushNotification.get('titleId')}" target="_blank">${pushNotification.get('titleId')}</a>`;
            }
            if (nextProps.displayLinks) {
                pushNotificationMessageTitle = `<a href="/push-notifications/${pushNotification.get('id')}" class="edit-push-notification">${pushNotification.get('messageTitle')}</a>`;
            }

            this.$tableAPI.row.add([
                pushNotificationId,
                pushNotificationMessageTitle,
                pushNotificationTarget,
                pushNotificationSentDate,
                pushNotificationStatus,
                ''
            ]);

            return;
        });

        this.$tableAPI.draw(false);
        this.handleResize();

        return;
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    getSortByClass(column) {
        let r = 'sorting';

        if (column === this.getRouteState().get('sortBy', 'id')) {
            r = `sorting_${this.getRouteState().get('sortDir', 'asc')}`;
        }

        return r;
    }

    handleResize() {
        this.$tableAPI.responsive.recalc();
        return;
    }

    handleRowClick(event) {
        switch (true) {
        case !!~event.target.className.indexOf('edit-push-notification'):
            event.preventDefault();
            this.context.router.push(event.target.getAttribute('href'));
            break;
        }

        return;
    }

    handleSort(column) {
        let newSortDir = 'asc';
        if (this.getRouteState().get('sortBy', 'id') === column && this.getRouteState().get('sortDir', 'asc') === 'asc') {
            newSortDir = 'desc';
        }

        this.setRouteState('sortBy', column)
            .setRouteState('sortDir', newSortDir)
            .apply();
    }

    render() {
        return (
            <div>
                <table id="push-notifications-table" className="table table-bordered table-striped responsive">
                    <thead>
                        <tr>
                            <th className={this.getSortByClass('id')} onClick={this.handleSort.bind(this, 'id')}>ID #</th>
                            <th className={this.getSortByClass('messageTitle')} onClick={this.handleSort.bind(this, 'messageTitle')}>Message Title</th>
                            <th className={this.getSortByClass('target')} onClick={this.handleSort.bind(this, 'target')}>Target</th>
                            <th className={this.getSortByClass('sentDate')} onClick={this.handleSort.bind(this, 'sentDate')}>Send Date</th>
                            <th className={this.getSortByClass('status')} onClick={this.handleSort.bind(this, 'status')}>Status</th>
                            <th className="no-sort"></th>
                        </tr>
                    </thead>
                    <tbody onClick={this.handleRowClick}>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default WithStoreOnRoute(Table);
