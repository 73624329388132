/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React from 'react';

import AssetDetail from './asset-detail';
import {AssetTabActions, AssetTabConstants} from './asset-tab-actions';
import AssetTabStore from './asset-tab-store';
import AssetTableComponent from './asset-table';
import AddExistingPanel from './panels/add-existing-panel';
import AddNewPanelContainer from './panels/add-new-panel';
import MediaPanel from './panels/media-panel';
import ReplaceVideoPanelContainer from './panels/replace-video-panel';
import VideoTableComponent from './video-table';
import {STATION_TYPE} from '../assets/document/document-actions';

import {AssetConstants} from '~/src/assets/asset-actions';
import {SlidingPanelActions} from '~/src/common/sliding-panel/sliding-panel-actions';
import SessionStore from '~/src/user/session/session-store';

class Assets extends React.Component {
    static get propTypes() {
        return {
            assetTabType: PropTypes.string,
            showAssetType: PropTypes.oneOf(['audio', 'document', 'image', 'merchandise', 'script', 'video']),
            caller: PropTypes.string,
            disabled: PropTypes.bool,
            disableStacking: PropTypes.bool,
            id: PropTypes.number,
            loadOnMount: PropTypes.bool,
            location: PropTypes.object,
            title: PropTypes.string,
            showCatalogsTab: PropTypes.bool,
        };
    }

    static get defaultProps() {
        return {
            assetTabType: '',
            showAssetType: undefined,
            disabled: true,
            disableStacking: false,
            loadOnMount: false,
            location: undefined,
            id: undefined,
            title: '',
            caller: '',
            showCatalogsTab: false,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static getStores() {
        return [AssetTabStore];
    }

    static calculateState() {
        return {
            assetDetails: AssetTabStore.getState().get('assetDetails'),
            assets: AssetTabStore.getState().get('assets'),
            assetType: AssetTabStore.getState().get('assetType'),
            copyFromAsset: AssetTabStore.getState().get('copyFromAsset'),
            copyToAsset: AssetTabStore.getState().get('copyToAsset'),
            imgThumbsAreComplete: AssetTabStore.getState().getIn(['thumbnails', 'imageThumbnailsLoaded']),
            originalAssets: AssetTabStore.getState().get('originalAssets'),
            showAssetType: AssetTabStore.getState().get('showAssetType'),
            thumbnails: AssetTabStore.getState().get('thumbnails'),
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        return;
    }

    componentDidMount() {
        if (this.state.assets.equals(this.state.originalAssets) && this.props.loadOnMount) {
            AssetTabActions.get(this.props.id, this.props.caller);
        }
        return;
    }

    componentWillReceiveProps(nextProps) {
        if (
            nextProps.id !== this.props.id ||
            nextProps.caller !== this.props.caller
        ) {
            AssetTabActions.get(nextProps.id, nextProps.caller, true);
        }

        if (this.props.location.pathname.includes('videos') && !nextProps.location.pathname.includes('videos')) {
            AssetTabActions.clearReplaceVideoData();
        }

        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            nextProps.assetTabType !== this.props.assetTabType ||
            nextProps.caller !== this.props.caller ||
            nextProps.id !== this.props.id ||
            nextProps.showAssetType !== this.props.showAssetType ||

            nextState.assetDetails !== this.state.assetDetails ||
            nextState.assetType !== this.state.assetType ||
            nextState.assets !== this.state.assets ||
            nextState.showAssetType !== this.state.showAssetType ||
            nextState.imgThumbsAreComplete
        );
    }

    componentWillUnmount() {
        AssetTabActions.clear();
        SlidingPanelActions.hide('addNew');
    }

    addNew(assetType) {
        return () => {
            AssetTabActions.setAssetType(assetType);
            SlidingPanelActions.show('addNew');
        };
    }

    addExisting(assetType) {
        return () => {
            AssetTabActions.setAssetType(assetType);
            SlidingPanelActions.show('addExisting');
        };
    }

    handleAssetTypeChange(event) {
        AssetTabActions.showAssetType(event.target.value);
        return;
    }

    render() {
        let elements = [];
        let assetTabType = this.props.assetTabType || this.state.showAssetType || 'all';
        let singleAssetMode = !!this.props.assetTabType;
        if (!singleAssetMode) {
            elements.push(
                <h3 key="singleAssetMode">
                    <i className="far fa-file-image"/>&nbsp;{this.context.intl.messages['titles.create.assets.media-assets']}
                    <select
                        className="form-control pull-right media-asset-type-select"
                        onChange={this.handleAssetTypeChange}
                        defaultValue="all"
                        value={assetTabType}
                    >
                        <option value="all">{this.context.intl.messages['asset.filter-by.all']}</option>
                        <option value={AssetConstants.ASSET_MAIN_TYPES.IMAGE}>{this.context.intl.messages['asset.filter-by.image']}</option>
                        <option value={AssetConstants.ASSET_MAIN_TYPES.VIDEO}>{this.context.intl.messages['asset.filter-by.video']}</option>
                        <option value={AssetConstants.ASSET_MAIN_TYPES.DOCUMENT}>{this.context.intl.messages['asset.filter-by.document']}</option>
                        <option value={AssetConstants.ASSET_MAIN_TYPES.AUDIO}>{this.context.intl.messages['asset.filter-by.audio']}</option>
                        <option value={AssetConstants.ASSET_MAIN_TYPES.MERCHANDISE}>{this.context.intl.messages['asset.filter-by.merchandise']}</option>
                        <option value={AssetConstants.ASSET_MAIN_TYPES.SCRIPT}>{this.context.intl.messages['asset.filter-by.script']}</option>
                        <option value={AssetConstants.ASSET_MAIN_TYPES.VIDEO_TIMELINE}>{this.context.intl.messages['asset.filter-by.video-timeline']}</option>
                    </select>
                </h3>
            );
        }

        let videoDescription;
        if (!this.props.disableStacking) {
            videoDescription = (
                <small> Drag video below another and click<i className="fas fa-link no-x-margin"/> to stack / click <i className="fas fa-unlink no-x-margin"/> to unstack</small>
            );
        }

        let replaceVideoColumn;
        if (SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.REPLACE_VIDEO_METADATA)) {
            replaceVideoColumn = 'replaceVideo';
        }

        let sections = [
            {
                icon: 'fas fa-camera',
                title: 'titles.create.assets.image',
                mainType: AssetConstants.ASSET_MAIN_TYPES.IMAGE,
                addNew: true,
                columns:['image', 'direction', 'format', 'moveToTop'],
                thumbnails:this.state.thumbnails.get('image')
            }, {
                icon: 'fas fa-video',
                title: 'titles.create.assets.video',
                mainType: AssetConstants.ASSET_MAIN_TYPES.VIDEO,
                description: videoDescription,
                addNew: true,
                children: [
                    (
                        <VideoTableComponent
                            active
                            assets={this.state.assets.get(AssetConstants.ASSET_MAIN_TYPES.VIDEO).get('active')}
                            columns={['direction', replaceVideoColumn]}
                            copyFromAsset={this.state.copyFromAsset}
                            disabled={this.props.disabled}
                            disableStacking={this.props.disableStacking}
                            thumbnails={this.state.thumbnails.get('video')}
                            thumbnailsLoaded={this.state.thumbnails.get('videoThumbnailsLoaded')}
                        />
                    ), (
                        <VideoTableComponent
                            assets={this.state.assets.get(AssetConstants.ASSET_MAIN_TYPES.VIDEO).get('inactive')}
                            columns={['direction']}
                            copyToAsset={this.state.copyToAsset}
                            disabled={this.props.disabled}
                            disableStacking={this.props.disableStacking}
                            thumbnails={this.state.thumbnails.get('video')}
                            thumbnailsLoaded={this.state.thumbnails.get('videoThumbnailsLoaded')}
                        />
                    )
                ]
            }, {
                icon: 'far fa-file-alt',
                title: 'titles.create.assets.documents',
                mainType: AssetConstants.ASSET_MAIN_TYPES.DOCUMENT,
                addNew: true,
                columns: ['contentType']
            }, {
                clientTableSearch: true,
                icon: 'far fa-file-alt',
                title: 'titles.create.assets.stations',
                reorder: false,
                mainType: STATION_TYPE,
                addNew: true,
                columns: ['contentType', 'sendEmailNotifications']
            }, {
                icon: 'fas fa-volume-up',
                title: 'titles.create.assets.audio',
                mainType: AssetConstants.ASSET_MAIN_TYPES.AUDIO,
                addNew: true,
                columns: ['runtime']
            }, {
                icon: 'fas fa-tag',
                title: 'titles.create.assets.merchandise',
                mainType: AssetConstants.ASSET_MAIN_TYPES.MERCHANDISE,
                addNew: true,
                columns: ['image']
            }, {
                icon: 'far fa-file-alt',
                title: 'titles.create.assets.scripts',
                mainType: AssetConstants.ASSET_MAIN_TYPES.SCRIPT,
                addNew: true,
                columns: ['contentType']
            }, {
                icon: 'fas fa-stream',
                title: 'titles.create.assets.timelines',
                mainType: AssetConstants.ASSET_MAIN_TYPES.VIDEO_TIMELINE,
                addNew: false,
                columns: ['runtime']
            }
        ].filter( section => {
            // we should move selectedAssetType out of here and use only props
            return (
                assetTabType === 'all' ||
                assetTabType === section.mainType ||
                (section.mainType === AssetConstants.ASSET_MAIN_TYPES.VIDEO_TIMELINE &&
                assetTabType === AssetConstants.ASSET_MAIN_TYPES.VIDEO)
            );
        }).map(section => {
            let addNew = null;
            if (section.addNew) {
                addNew = this.addNew(section.mainType);
            }

            let children = section.children || [
                (
                    <AssetTableComponent
                        active
                        assetType={section.mainType}
                        assets={this.state.assets.get(section.mainType).get('active')}
                        clientTableSearch={section.clientTableSearch}
                        columns={section.columns}
                        disabled={this.props.disabled}
                        key="active"
                        reorder={section.reorder}
                        thumbnails={section.thumbnails} />
                ), (
                    <AssetTableComponent
                        assetType={section.mainType}
                        assets={this.state.assets.get(section.mainType).get('inactive')}
                        clientTableSearch={section.clientTableSearch}
                        columns={section.columns}
                        disabled={this.props.disabled}
                        key="inactive"
                        reorder={section.reorder}
                        thumbnails={section.thumbnails}/>
                )
            ];

            return (
                <MediaPanel
                    addExisting={this.addExisting(section.mainType)}
                    addNew={addNew}
                    description={section.description}
                    disabled={this.props.disabled}
                    icon={section.icon}
                    key={section.mainType}
                    noSeparator={singleAssetMode}
                    title={this.context.intl.messages[section.title]}
                >
                    {children}
                </MediaPanel>
            );
        });

        elements = elements.concat(sections);

        const title = <span>{this.context.intl.messages['titles.create.assets.add-existing']}<small>{this.props.title}</small></span>;
        let replaceVideoPanel;
        if (this.state.assetType === 'video') {
            replaceVideoPanel = <ReplaceVideoPanelContainer
                caller={this.props.caller}
                headerTitle={this.context.intl.messages['titles.create.assets.replace-video']}
                id={this.props.id}
                title={this.props.title}
            />;
        }
        return (
            <div>
                <AddExistingPanel
                    assetType={this.state.assetType}
                    caller={this.props.caller}
                    title={title}
                />
                <AddNewPanelContainer
                    addConstants={AssetTabConstants.ADD}
                    assetType={this.state.assetType}
                    caller={this.props.caller}
                    headerTitle={this.context.intl.messages['titles.create.assets.add-new.title']}
                    title={this.props.title}
                    showCatalogsTab={this.props.showCatalogsTab}
                />
                {replaceVideoPanel}
                <AssetDetail
                    asset={this.state.assetDetails.get('asset')}
                    caller={this.props.caller}
                    location={this.props.location}
                    path={this.state.assetDetails.get('path').toArray()}
                    show={this.state.assetDetails.get('show')}
                    disabled={this.props.disabled}
                    thumbnails={this.state.thumbnails}
                />
                {elements}
            </div>
        );

    }
}

export default Container.create(Assets);
