/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import Options from './options';
import Summary from './summary';
import {Tab, Tabs} from '../../../common/routing-tab/routing-tab';
import {History} from '../../../common/side-notes/side-notes';
import {ErrorsWarnings} from '../../../common/validations/errors-warnings';
import {ActionHistoryConstants} from '../../../system/action-history/action-history-actions';
import AudioProfileTable from '../../audio-profile-table';
import {AudioProfileConstants} from '../../audio-profile-table/audio-profile-actions';
import {VideoDeliveryActions} from '../video-delivery-actions';
import VideoDeliveryStore from '../video-delivery-store';

class VideoFileInformation extends Component {
    static get propTypes() {
        return {
            audioProfile: PropTypes.instanceOf(Immutable.List).isRequired,
            audioProfilePresetId: PropTypes.number.isRequired,
            isDisabled: PropTypes.bool,
            location: PropTypes.object.isRequired,
            mediaInfoTotalAudioTracks: PropTypes.number.isRequired,
            params: PropTypes.object.isRequired,
            selectedTitle: PropTypes.instanceOf(Immutable.Map).isRequired,
            videoDelivery: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get defaultProps() {
        return {
            isDisabled: false
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.handleSelectAudioProfilePreset = this.handleSelectAudioProfilePreset.bind(this);
        this.handleUpdateAudioChannelType = this.handleUpdateAudioChannelType.bind(this);

        return;
    }

    handleAddAudioProfile() {
        VideoDeliveryActions.addAudioProfile();
    }

    handleDeleteAudioProfile(index) {
        VideoDeliveryActions.deleteAudioProfile(index);
    }

    handleMoveElement(index, newPosition) {
        VideoDeliveryActions.moveElement(index, newPosition);
    }

    handleSelectAudioProfilePreset(audioProfile) {
        let prevAudioProfile = this.props.audioProfile;
        if (!prevAudioProfile) {
            prevAudioProfile = Immutable.List();
        }

        VideoDeliveryActions.setAudioProfile(audioProfile, prevAudioProfile, this.props.mediaInfoTotalAudioTracks);
    }

    handleSelectProxyLR(index, lr) {
        VideoDeliveryActions.toggleProxyLR(index, lr);
    }

    handleSoloAudioChannel(index, isMuted) {
        VideoDeliveryActions.soloAudioChannel(index, isMuted);
    }

    handleToggleTrack(index) {
        VideoDeliveryActions.toggleTrack(index);
    }

    handleUpdateAudioChannelType(index, attr) {
        // Changing any value on a 'stock profile' will change the profile to 'custom'
        if (this.props.audioProfilePresetId !== AudioProfileConstants.AUDIO_PROFILE.CUSTOM_PROFILE_ID) {
            this.handleSelectAudioProfilePreset(Immutable.fromJS({id: AudioProfileConstants.AUDIO_PROFILE.CUSTOM_PROFILE_ID}));
        }

        VideoDeliveryActions.updateAudioProfile(`${index}.type`, attr.value);
    }

    render() {
        const baseRoute = `/hardac/video-inbox/${this.props.params.id}`;


        const summaryTab = (
            <Tab route={baseRoute} title={this.context.intl.messages['hardac.video-inbox.summary']}>
                <Summary
                    isDisabled={this.props.isDisabled}
                    location={this.props.location}
                    videoDelivery={this.props.videoDelivery}
                    selectedTitle={this.props.selectedTitle}
                />
            </Tab>
        );

        const audioProfileTab = (
            <Tab route={`${baseRoute}/audio`} title={this.context.intl.messages['hardac.audio-profile']}>
                <AudioProfileTable
                    audioProfile={this.props.audioProfile}
                    audioProfilePresetId={this.props.audioProfilePresetId}
                    isDisabled={this.props.isDisabled}
                    mediaInfoTotalAudioTracks={this.props.mediaInfoTotalAudioTracks}
                    onAdd={this.handleAddAudioProfile}
                    onDelete={this.handleDeleteAudioProfile}
                    onReorder={this.handleMoveElement}
                    onSelectAudioProfilePreset={this.handleSelectAudioProfilePreset}
                    onSelectProxy={this.handleSelectProxyLR}
                    onSoloAudioChannel={this.handleSoloAudioChannel}
                    onUpdateAudioChannelType={this.handleUpdateAudioChannelType}
                    onToggleTrack={this.handleToggleTrack}
                />
            </Tab>
        );

        const optionsTab = (
            <Tab route={`${baseRoute}/options`} title={'Options'}>
                <Options
                    isDisabled={this.props.isDisabled}
                    videoDelivery={this.props.videoDelivery}
                    onSelectAudioProfilePreset={this.handleSelectAudioProfilePreset}
                    audioProfilePresetId={this.props.audioProfilePresetId}
                    mediaInfoTotalAudioTracks={this.props.mediaInfoTotalAudioTracks}
                />
            </Tab>
        );

        const historyTab = (
            <Tab route={`${baseRoute}/history`} title={this.context.intl.messages['hardac.video-inbox.histoy']} tabClassName="pull-right">
                <History
                    actionObject={ActionHistoryConstants.ACTION_OBJECTS.VIDEODELIVERY}
                    id={this.props.params.id}
                    pagination
                    pathname={this.props.location.pathname}
                    query={this.props.location.query}
                />
            </Tab>
        );

        return (
            <div className="tabs-info padding-y-20 padding-x-20">
                {
                    VideoDeliveryStore.getValidations(false).length > 0 && (
                        <ErrorsWarnings
                            hasAnimation
                            defaultExpanded={false}
                            title={this.context.intl.messages['common.errors-warnings-title']}
                            validations={VideoDeliveryStore.getValidations(false)}
                        />
                    )
                }
                <Tabs location={this.props.location}>
                    {summaryTab}
                    {audioProfileTab}
                    {optionsTab}
                    {historyTab}
                </Tabs>
            </div>
        );
    }
}

export default VideoFileInformation;
