/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {BehaviorSubject} from 'rxjs';

export function ReadonlySubject<T>(target: BehaviorSubject<T>): BehaviorSubject<T> {
    return new Proxy(target, {
        get(that, property, receiver) {
            if (property === 'next') {
                return () => {
                    throw new Error('Unsupported operation exception. Please, use proper action instead of calls `.next` function manually');
                };

            }
            return Reflect.get(that, property, receiver);
        }
    });
}
