/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';
import {IndexRoute, Route} from 'react-router';

import Browse from './browse/';
import Create from './create/index';
import Summary from './create/summary';
import WithPermissions from '../decorators/with-permissions';
import {RequirePerm} from '../router/router-helpers';
import SessionStore from '../user/session/session-store';
import './events.less';

export default (
    <Route path="events">

        <IndexRoute
            component={WithPermissions(Browse)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.EVENTS.BROWSE)}
        />

        <Route
            component={WithPermissions(Create)}
            mode="create"
            path="create"
        >
            <Route path="users"/>
            <Route path="assets"/>
            <Route path="titles"/>
            <Route path="presentation"/>
        </Route>

        <Route
            component={WithPermissions(Create)}
            mode="duplicate"
            path=":id/duplicate"
        />

        <Route
            component={WithPermissions(Create)}
            mode="edit"
            path=":id"
        >
            <IndexRoute component={Summary}/>
            <Route path="users"/>
            <Route path="assets"/>
            <Route path="titles"/>
            <Route path="notifications"/>
            <Route path="presentation"/>
            <Route path="history"/>
            <Route path="events"/>
        </Route>
    </Route>
);
