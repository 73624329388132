/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';
import {v4 as UUID} from 'uuid';

import {PartnerConstants} from './partner-actions';
import Validations from '../common/validations/validations';
import Dispatcher from '../dispatcher/dispatcher';

const PartnerStoreValidations = {
    name: {label: 'authorized-partners.create.info.name', validations: [Validations.required, Validations.max(50)]},
    clientId: {label: 'authorized-partners.create.info.client', validations: [Validations.required, Validations.max(50)]},
    description: {label: 'authorized-partners.create.info.description', validations: [Validations.required]},
    defaultSiteUrl: {label: 'authorized-partners.create.info.default-site-url', validations: [Validations.url]}
};

const findInConstant = (constants, objectId) => {
    return Immutable.fromJS(
        Object.values(constants).filter(obj => objectId === obj.id)[0]
    );
};

class PartnerStore extends ReduceStore {
    getPartner(partnerId) {
        return this.getState().get('partners').find(p => parseInt(p.get('id')) === partnerId, null, Immutable.Map());
    }

    getInitialState() {
        let state = Immutable.Map({
            offset: 0,
            partner: Immutable.Map({
                apiKeys: Immutable.List(),
                clientKey: '',
                description: '',
                files: [],
                name: '',
                newApiKey: Immutable.Map(),
                sessionLifespan: 24,
                sessionTimeoutDuration: 60,
                stations: Immutable.List(),
                userGroups: Immutable.List(),
            }),
            partners: Immutable.List(),
            platformType: Immutable.fromJS(PartnerConstants.toArray('PLATFORM_TYPE')),
            removedApiKeys: Immutable.List(),
            showAddApiKeyModal: false,
            size: 0,
            stations: Immutable.List(),
            totalStations: 0,
            totalUsers: 0,
            total: 0,
            usageType: Immutable.fromJS(PartnerConstants.toArray('USAGE_TYPE')),
            userGroups: Immutable.List(),
        });

        state = state.set('originalPartner', state.get('partner'));

        return state;
    }

    getPlatformType(platformType) {
        return findInConstant(PartnerConstants.PLATFORM_TYPE, platformType);
    }

    getUsageType(usageType) {
        return findInConstant(PartnerConstants.USAGE_TYPE, usageType);
    }

    getValidations() {
        return Validations.validate(this.getState().get('partner'), PartnerStoreValidations);
    }

    reduce(state, action) {
        switch (action.actionType) {

        case PartnerConstants.API_KEY.ADD:
            state = state.updateIn(['partner', 'apiKeys'], (apiKeys) => {
                let apiKey = action.apiKey.set('partnerId', state.getIn(['partner', 'id']));
                return apiKeys.push(apiKey);
            });
            break;

        case PartnerConstants.API_KEY.ADD_MODAL.HIDE:
            state = state.set('showAddApiKeyModal', false);
            break;

        case PartnerConstants.API_KEY.ADD_MODAL.SHOW:
            // seed dialog with some random data
            state = state.setIn(['partner', 'newApiKey', 'key'], UUID())
                .set('showAddApiKeyModal', true);
            break;

        case PartnerConstants.API_KEY.CLEAR:
            state = state.setIn(['partner', 'newApiKey'], Immutable.Map());
            break;

        case PartnerConstants.API_KEY.REMOVE:
            state = state.removeIn(['partner', 'apiKeys', action.index]);
            break;

        case PartnerConstants.CLEAR:
            state = this.getInitialState();
            break;

        case PartnerConstants.CLONE:
            state = state.updateIn(['partner', 'apiKeys'], (apiKeys) => {
                return apiKeys.map( key => {
                    let updated = action.updatedKeys.find(k => k.get('apiKey') === key.get('apiKey'));
                    if (updated) {
                        key = key.set('id', updated.get('id'));
                    }
                    return key;
                });
            });
            state = state.setIn(['partner', 'files'], []);
            state = state.set('originalPartner', state.get('partner'));
            break;

        case PartnerConstants.GET.SUCCESS:
            state = state.set('partner', action.partner);
            state = state.set('originalPartner', action.partner);
            break;

        case PartnerConstants.LOAD:
            state = state.merge({
                offset: action.offset,
                partners: action.partners,
                size: action.size,
                total: action.total,
            });
            break;

        case PartnerConstants.STATION.ADD:
            state = state.updateIn(['partner', 'stations'], (stations) => {
                return stations.push(action.station);
            });
            break;

        case PartnerConstants.STATION.GET.SUCCESS:
            state = state.set('stations', action.stations);
            state = state.set('totalStations', action.total);
            break;

        case PartnerConstants.STATION.REMOVE:
            state = state.removeIn(['partner', 'stations', action.index]);
            break;

        case PartnerConstants.UPDATE:
            state = state.setIn(['partner', ...action.attr.split('.')], action.value);
            break;

        case PartnerConstants.USER_GROUP.ADD:
            state = state.updateIn(['partner', 'userGroups'], (userGroups) => {
                return userGroups.push(action.group);
            });
            break;

        case PartnerConstants.USER_GROUP.GET.SUCCESS:
            state = state.set('userGroups', action.userGroups);
            state = state.set('totalUsers', action.total);
            break;

        case PartnerConstants.USER_GROUP.REMOVE:
            state = state.removeIn(['partner', 'userGroups', action.index]);
            break;

        }
        return state;
    }
}

const store = new PartnerStore(Dispatcher);

export default store;

export {PartnerStoreValidations};
