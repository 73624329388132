/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import {browserHistory, Link} from 'react-router';

import SessionStore from '../user/session/session-store';

export default createReactClass({
    contextTypes: {
        intl: PropTypes.object.isRequired
    },

    componentDidMount: function() {
        if (!SessionStore.sessionIsValid()) {
            browserHistory.push('/login');
        }
        return;
    },

    render: function() {
        return (
            <section className="content">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="container-fluid no-x-padding">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="error-page">
                                        <h2 className="headline">
                                            <img src={require('./404.gif')} width="150"/>
                                        </h2>
                                        <div className="error-content">
                                            <h3><i className="icon fas fa-exclamation-triangle text-wb-blue"></i>{this.context.intl.messages['errors.not-found.title']}</h3>
                                            <p>
                                                {this.context.intl.messages['errors.not-found.body.description']}
                                            </p>
                                            <p>
                                                {this.context.intl.messages['errors.not-found.body.check-url']}
                                                <a href="mailto:tvservicing@warnerbros.com">{this.context.intl.messages['errors.not-found.body.contact-administrator']}</a>
                                                {this.context.intl.messages['errors.not-found.body.display-in-error']}
                                            </p>
                                            <p>
                                                {this.context.intl.messages['errors.not-found.body.meanwhile']}
                                                <Link to="/">{this.context.intl.messages['errors.not-found.body.dashboard']}</Link>.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
});
