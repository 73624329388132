/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import jQuery from 'jquery';
import PropTypes from 'prop-types';
import React from 'react';

import {MassUpdateActions} from './mass-update-actions';

require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('../../../styles/data-tables-brainiac.css');
require('datatables.net-responsive-bs');

class AssignedTable extends React.Component {

    static get propTypes() {
        return {
            assignedCatalogs: PropTypes.instanceOf(Immutable.List).isRequired,
            mode: PropTypes.string.isRequired,
            removeList: PropTypes.instanceOf(Immutable.List).isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.handleResize = this.handleResize.bind(this);
        this.handleRemoveCatalog = this.handleRemoveCatalog.bind(this);
    }

    componentDidMount() {
        this.$table = jQuery('#assigned-catalogs-table');
        this.$tableAPI = this.$table.DataTable({
            autoWidth: false,
            columnDefs: [{
                className: 'control',
                targets: -1,
                width: 20
            }, {
                targets: 'no-sort',
                orderable: false
            }],
            order: [[1, 'dec']],
            iDisplayLength: 100,
            info: false,
            ordering: false,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        });

        window.addEventListener('resize', this.handleResize);

        this.handleResize();
        return;
    }

    componentWillUpdate(nextProps /*, nextState*/) {
        this.$tableAPI.clear();

        nextProps.assignedCatalogs.forEach((catalog, i) => {
            let row = [];
            let catalogDescription = catalog.get('description') || '';
            let catalogStatus = this.context.intl.messages['catalogs.mass-update.status.inactive'];
            if (catalog.get('isActive') === 1) {
                catalogStatus = this.context.intl.messages['catalogs.mass-update.status.active'];
            }
            let removeOption = `<button class="btn btn-small bg-navy remove-catalog-button" id="${catalog.get('id')}" data-index="${i}"><i class="fas fa-times"></i>&nbsp;${this.context.intl.messages['catalogs.mass-update.button.remove']}</button>`;
            if (nextProps.mode === 'remove') {
                let isChecked = false;
                nextProps.removeList.find((elem) => {
                    if (elem.get('id') === catalog.get('id')) {
                        isChecked = true;
                    }
                });
                let checked = '';
                if (isChecked) {checked = 'checked';}
                removeOption = `<input type="checkbox" ${checked} class="remove-catalog-checkbox" id=${catalog.get('id')} data-index="${i}"/>`;
            }

            row.push(
                catalog.get('name'),
                catalogDescription,
                catalogStatus,
                removeOption,
                ''
            );

            this.$tableAPI.row.add(row);
            return;
        });

        this.$tableAPI.draw(false);
        this.handleResize();
        return;
    }

    componentWillUnmount() {
        if (this.$tableAPI) {
            this.$tableAPI.destroy();
        }
        return;
    }

    handleResize() {
        this.$tableAPI.responsive.recalc();
        return;
    }

    handleRemoveCatalog(event) {
        let catalog = this.props.assignedCatalogs.find((elem) =>
            elem.get('id') === parseInt(event.target.getAttribute('id'))
        );
        switch (true) {
        case !!~event.target.className.indexOf('remove-catalog-button'):
            MassUpdateActions.removeCatalog(catalog);
            break;
        case !!~event.target.className.indexOf('fa-times'):
            catalog = this.props.assignedCatalogs.find((elem) =>
                elem.get('id') === parseInt(event.target.parentNode.getAttribute('id'))
            );
            MassUpdateActions.removeCatalog(catalog);
            break;
        case !!~event.target.className.indexOf('remove-catalog-checkbox'):
            MassUpdateActions.toggleSelect(event.target.checked, catalog);
            break;
        }
        return;
    }

    render() {
        return (
            <table id="assigned-catalogs-table" className="table table-bordered table-striped responsive">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Status</th>
                        <th>Actions</th>
                        <th className="no-sort"></th>
                    </tr>
                </thead>
                <tbody onClick={this.handleRemoveCatalog}>
                </tbody>
            </table>
        );
    }
}

export default AssignedTable;
