/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {ControlLabel, FormGroup} from 'react-bootstrap';

import {FormItem, FormRow, FormSection} from '../../../common/form/form';
import {SectionPanel} from '../../../common/panel/panel';
import TinyMCE from '../../../common/tinymce/tinymce';
import {CompareImmutable} from '../../../common/utils/utils';
import {WithValidations} from '../../../common/validations/validations';
import {TitleActions} from '../../title-actions';
import {TitleValidations} from '../../title-store';

/**
 * Title props to watch.
 */
const titleProps = [
    'nonTheatricalRights',
    'airlineAvailabilityDate'
];

class NonTheatricalRights extends React.Component {
    static get propTypes() {
        return {
            expanded: PropTypes.bool,
            disabled: PropTypes.bool,
            showErrors: PropTypes.bool,
            title: PropTypes.instanceOf(Immutable.Map).isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            expanded: false,
            disabled: true,
            showErrors: false
        };
    }

    shouldComponentUpdate(nextProps) {
        if (
            nextProps.disabled !== this.props.disabled ||
            nextProps.showErrors !== this.props.showErrors ||
            nextProps.expanded !== this.props.expanded ||
            !CompareImmutable(nextProps.title, this.props.title, titleProps)
        ) {
            return true;
        }

        return false;
    }

    handleTinyMCEChange(attr) {
        return function handler(e) {
            TitleActions.updateTitle(attr, e?.target?.getContent());
            return;
        };
    }

    updateAirlineDate(attr, value) {
        TitleActions.updateTitle(attr, value);
        return;
    }

    render() {
        return (
            <SectionPanel
                expanded={this.props.expanded}
                title={this.context.intl.messages['titles.create.summary.non-theatrical-rights']}
                validationState={this.getAccordionValidationState(this.props.title, titleProps, TitleValidations)}
            >
                <FormSection>
                    <FormRow>
                        <FormItem
                            attr="airlineAvailabilityDate"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['titles.create.summary.non-theatrical-rights-airline-date']}
                            model={this.props.title}
                            setter={this.updateAirlineDate}
                            type="date"
                            validations={TitleValidations.releases.date.validations}
                        />
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['titles.create.summary.non-theatrical-rights']}</ControlLabel>
                            <TinyMCE
                                disabled={this.props.disabled}
                                content={this.props.title.get('nonTheatricalRights') || ''}
                                id="non-theatrical-rights"
                                onChange={this.handleTinyMCEChange('nonTheatricalRights')}
                            />
                        </FormGroup>
                    </FormRow>
                </FormSection>
            </SectionPanel>
        );
    }
}

export default WithValidations(NonTheatricalRights);
