/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import AppStyle from '~/src/common/app-styles/app';
import {EventActions, EventConstants} from '~/src/events/event-actions';

class TitleStyle extends React.Component {

    static get propTypes() {
        return {
            selectedTitleStyleOption: PropTypes.string,
            titleStyle: PropTypes.instanceOf(Immutable.Map).isRequired,
            titleStyleFiles: PropTypes.instanceOf(Immutable.Map).isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true,
            selectedTitleStyleOption: null,
        };
    }

    constructor(props) {
        super(props);

        this.handleSelectModifyOption = this.handleSelectModifyOption.bind(this);
    }

    handleSelectModifyOption(selectedOption) {
        EventActions.setModifyOption('selectedTitleStyleOption', selectedOption);
    }

    render() {
        const options = EventConstants.TITLE_PRESENTATION.MODIFY_OPTIONS;
        return (
            <div className="event-presentation">
                <h3><i className="fa-regular fa-paintbrush-pencil"></i>&nbsp;{this.context.intl.messages['events.presentation.title-style.title']}</h3>
                <p>
                    <small>
                        <em>
                            {this.context.intl.messages['events.presentation.title-style.description']}
                        </em>
                    </small>
                </p>
                <div>
                    <div style={{display: 'flex'}}>
                        <span style={{display: 'flex', alignItems: 'center', gap: 6}}>
                            <input
                                checked={this.props.selectedTitleStyleOption === options.INHERIT}
                                id="inherit-title-style"
                                name="inherit-title-style"
                                onClick={this.handleSelectModifyOption.bind(this, options.INHERIT)}
                                type="radio"
                            />
                            <span><i className="fa-solid fa-link-horizontal"></i>&nbsp;&nbsp;{this.context.intl.messages['events.presentation.modify-option.inherit']}</span>
                        </span>
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        <span style={{display: 'flex', alignItems: 'center', gap: 6}}>
                            <input
                                checked={this.props.selectedTitleStyleOption === options.CUSTOM}
                                id="custom-title-style"
                                name="custom-title-style"
                                onClick={this.handleSelectModifyOption.bind(this, options.CUSTOM)}
                                type="radio"
                            />
                            <span><i className="fa-solid fa-pen-to-square"></i>&nbsp;&nbsp;{this.context.intl.messages['events.presentation.modify-option.custom']}</span>
                        </span>
                    </div>
                    <br />
                    {this.props.selectedTitleStyleOption === options.CUSTOM && (
                        <div>
                            <span className="text-yellow"><i className="fas fa-exclamation-triangle" />&nbsp;<b>{this.context.intl.messages['events.presentation.title-style.inherit-warning']}</b></span>
                            <br />
                            <br />
                            <AppStyle
                                actions={EventActions}
                                disabled={false}
                                title={this.props.titleStyle}
                                titleStyle={this.props.titleStyleFiles}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default TitleStyle;
