/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import PushNotificationsTable from './table';
import SearchBox from '../../common/search-box/search-box';
import {WithStoreOnRoute} from '../../common/store-on-route';
import {Debounce} from '../../common/utils/utils';
import LayoutStore from '../../layout/layout-store';
import Preloader from '../../preloader';
import PreloaderStore from '../../preloader/preloader-store';
import SessionStore from '../../user/session/session-store';
import {PushNotificationActions} from '../push-notification-actions';
import PushNotificationStore from '../push-notification-store';


class Browse extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            route: undefined
        };
    }

    static calculateState() {
        return {
            showFiltersPanel: LayoutStore.getState().get('showFiltersPanel'),
            partners: PushNotificationStore.getState().get('partners'),
            pushNotifications: PushNotificationStore.getState().get('pushNotifications'),
            pushNotification: PushNotificationStore.getState().get('pushNotification'),
            total: PushNotificationStore.getState().get('total'),
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible'),
        };
    }

    static getStores() {
        return [LayoutStore, PushNotificationStore, PreloaderStore];
    }

    static getPermissions() {
        return {
            canReadPushNotifications: SessionStore.canUser(SessionStore.PERMISSIONS.PUSH_NOTIFICATIONS.READ)
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.getPushNotificationsCount = this.getPushNotificationsCount.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
    }

    componentDidMount() {
        PushNotificationActions.clear();
        PushNotificationActions.getPartners(0, 9999);
    }

    getPushNotificationsCount(count) {
        this.refs.pushNotificationsCount.innerHTML = `(${count} ${this.context.intl.messages['push-notifications.title']})`;
        return;
    }

    handleSearchTerm(term) {
        // This is a local search
        this.setRouteState('q', term)
            .clearRouteState('offset')
            .apply();
    }

    render() {
        let showInactive = this.getRouteState().get('show-inactive') || false;
        return (
            <div className={ClassNames({'control-sidebar-open': this.state.showFiltersPanel})}>
                <div className={ClassNames({'sidebar-opened': this.state.showFiltersPanel})}>
                    <Preloader show={this.state.preloaderVisible} fixed loadingDots>
                        <section className="content-header">
                            <h1>
                                <i className="fas fa-bell"></i>
                                &nbsp;
                                {this.context.intl.messages['push-notifications.browse.title']}
                                &nbsp;
                                <small ref="pushNotificationsCount"></small>
                            </h1>
                        </section>
                        <section className="content">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="container-fluid no-x-padding">
                                        <div className="row">
                                            <div className="col-md-6 show-grid">
                                                <SearchBox value={this.getRouteState().get('q')} onChange={this.handleSearchTerm}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container-fluid no-x-padding">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="box">
                                                    <div className="box-body padding-x-20 padding-y-20">
                                                        <PushNotificationsTable
                                                            displayLinks={this.props.permissions.canReadPushNotifications}
                                                            filters={this.getRouteState().get('q')}
                                                            location={this.props.location}
                                                            pushNotifications={this.state.pushNotifications}
                                                            pushNotificationsCount={this.getPushNotificationsCount}
                                                            showInactive={showInactive}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Preloader>
                </div>
            </div>
        );
    }
}

export default WithStoreOnRoute(Container.create(Browse));
