/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */
import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, Col, Modal, Row} from 'react-bootstrap';
import {Link} from 'react-router';
import ReactTable from 'react-table';

import config from '../../config/config';
import Pagination from '../table/pagination';

import 'react-table/react-table.css';

export default class EventListUsers extends Component {
    static get propTypes() {
        return {
            activePage: PropTypes.number,
            columns: PropTypes.array,
            onPageChange: PropTypes.func,
            onSortChange: PropTypes.func.isRequired,
            sortDirection: PropTypes.string.isRequired,
            sortFieldName: PropTypes.string.isRequired,
            totalPages: PropTypes.number,
            userErrors: PropTypes.instanceOf(Immutable.List)
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object
        };
    }

    static get defaultProps() {
        return {
            activePage: 0,
            columns: ['userErrorId', 'userSessionId', 'uriMethod'],
            onPageChange: void 0,
            totalPages: 0,
            userErrors: Immutable.List()
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            showUriBodyModal: false,
            uriBodyContent: '',
        });


        this.getHeaderNameClass = this.getHeaderNameClass.bind(this);
        this.handleHeaderClick = this.handleHeaderClick.bind(this);
        this.onHideUriBodyModal = this.onHideUriBodyModal.bind(this);
    }

    getHeaderNameClass(headerName) {
        let headerClassName = 'sorting';

        if (headerName === this.props.sortFieldName) {
            headerClassName = `sorting-${this.props.sortDirection}`;
        }

        return headerClassName;
    }

    handleHeaderClick(headerName) {
        if (headerName === 'createdDate') {
            let newSortDirection = 'asc';
            if (this.props.sortFieldName === headerName && this.props.sortDirection === 'asc') {
                newSortDirection = 'desc';
            }
            if (this.props.onSortChange) {
                this.props.onSortChange(headerName, newSortDirection);
            }
        }

        return;
    }

    onHideUriBodyModal() {
        this.setState({
            showUriBodyModal: false,
            uriBodyContent: '',
        });
    }

    openUriBodyModal(content) {
        this.setState({
            showUriBodyModal: true,
            uriBodyContent: content,
        });
    }

    render() {
        let pagination;
        if (this.props.onPageChange) {
            pagination = <Row>
                <Col md={12} className="text-center">
                    <Pagination
                        activePage={this.props.activePage}
                        onChange={this.props.onPageChange}
                        totalPages={this.props.totalPages}
                    />
                </Col>
            </Row>;
        }

        const getHeader = this.getHeaderNameClass;
        const handleSortColumn = this.handleHeaderClick;

        const COLUMNS = [
            {
                accessor: 'userId',
                Header: () => <strong>{this.context.intl.messages['accounts.create.user-errors.table.header.user-id']}</strong>,
                maxWidth: 65,
                Cell: c => <Link to={`/accounts/${c.original.get('userId')}`}>{c.original.get('userId')}</Link>,
                sortable: true
            },
            {
                accessor: 'userErrorId',
                Header: () => <strong>{this.context.intl.messages['accounts.create.user-errors.table.header.error-id']}</strong>,
                maxWidth: 65,
                Cell: (c) => {
                    return <div>{c.original.get('userErrorId')}</div>;
                },
                sortable: true
            },
            {
                accessor: 'userSessionId',
                Header: () => <strong>{this.context.intl.messages['accounts.create.user-errors.table.header.session-id']}</strong>,
                maxWidth: 85,
                Cell: (c) => {
                    return <div>{c.original.get('userSessionId')}</div>;
                }
            },
            {
                accessor: 'uriMethod',
                maxWidth: 95,
                Header: () => <strong>{this.context.intl.messages['accounts.create.user-errors.table.header.uri-method']}</strong>,
                Cell: (c) => {
                    return <div>{c.original.get('uriMethod')}</div>;
                }
            },
            {
                accessor: 'uri',
                maxWidth: 100,
                Header: () => <strong>{this.context.intl.messages['accounts.create.user-errors.table.header.uri']}</strong>,
                Cell: (c) => {
                    return <div>{c.original.get('uri')}</div>;
                }
            },
            {
                accessor: 'uriBody',
                maxWidth: 90,
                Header: () => <strong>{this.context.intl.messages['accounts.create.user-errors.table.header.uri-body']}</strong>,
                Cell: (c) => {
                    return <Button bsStyle="primary" className="btn-sm" disabled={!c.original.get('uriBody')} onClick={() => this.openUriBodyModal(c.original.get('uriBody'))}>
                        {this.context.intl.messages['accounts.create.user-errors.table.header.show-body']}
                    </Button>;
                }
            },
            {
                accessor: 'userErrorType',
                maxWidth: 110,
                Header: () => <strong>{this.context.intl.messages['accounts.create.user-errors.table.header.error-type']}</strong>,
                Cell: (c) => {
                    return <div>{c.original.get('userErrorType')}</div>;
                }
            },
            {
                accessor: 'userError',
                maxWidth: 400,
                Header: () => <strong>{this.context.intl.messages['accounts.create.user-errors.table.header.user-error']}</strong>,
                Cell: (c) => {
                    return <small>{c.original.get('userError')}</small>;
                }
            },
            {
                accessor: 'createdDate',
                maxWidth: 150,
                Header: (props) => <strong className={getHeader(props.column.id)}>{this.context.intl.messages['accounts.create.user-errors.table.header.date-time']}</strong>,
                Cell: (c) => {
                    return <div>{Moment(c.original.get('createdDate'))?.tz(config.DefaultTimezone).format(this.context.intl.messages['datetime-hybrid-format'])}</div>;
                }
            },
        ];

        return (
            <div>
                <ReactTable
                    className="-striped table-bordered table-striped responsive"
                    columns={COLUMNS.filter(col => this.props.columns.includes(col.accessor))}
                    data={this.props.userErrors}
                    getNoDataProps= {() => {
                        if (this.props.userErrors.size) {
                            return {style: {display: 'none'}};
                        }
                        return {};
                    }}
                    id={'list-user-errors-table-id'}
                    loading={false}
                    showPagination={false}
                    sortable={false}
                    resizable={true}
                    pageSize={this.props.userErrors.size}
                    getTheadThProps={(state, rowInfo, column) => ({
                        onClick: () => {
                            handleSortColumn(column.id);
                        }
                    })}
                />
                {pagination}
                <Modal show={this.state.showUriBodyModal} onHide={this.onHideUriBodyModal}>
                    <Modal.Header className="bg-gray">
                        <Modal.Title className="text-center" >{this.context.intl.messages['accounts.create.user-errors.table.header.uri-body']}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            <code>{this.state.uriBodyContent}</code>
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="pull-left Ml(5)" onClick={this.onHideUriBodyModal}>{this.context.intl.messages['common.close']}</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
