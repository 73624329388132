/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {PermissionPackageConstants} from './permission-package-actions';
import Validations from '../../common/validations/validations';
import Dispatcher from '../../dispatcher/dispatcher';

const PermissionPackageStoreValidations = {
    name: {label: 'presets.create.info.name', validations: [Validations.required, Validations.max(50)]},
    description: {label: 'presets.create.info.description', validations: [Validations.required]},
    defaultPartner: {
        validations: [
            Validations.custom(
                () => {
                    return 'Please, select a default partner.';
                },
                () => {
                    let isValid = true;
                    const defaultPartner = store.getState().getIn(['permissionPackage', 'defaultPartnerId']);
                    const partnersList = store.getState().getIn(['permissionPackage', 'webPartnerIds']);
                    if (!defaultPartner && partnersList.length) {
                        isValid = false;
                    }
                    return isValid;
                },
            )
        ]
    },

};

class PermissionPackageStore extends ReduceStore {

    getInitialState() {
        return Immutable.Map({
            activePage: 0,
            filters: Immutable.Map(),
            offset: 0,
            originalPermissionPackage: Immutable.Map({
                clientRepGroups: Immutable.OrderedSet(),
                description: '',
                groups: Immutable.OrderedSet(),
                isActive: true,
                name: '',
                roles: Immutable.OrderedSet()
            }),
            permissionPackage: Immutable.Map({
                clientRepGroups: Immutable.OrderedSet(),
                description: '',
                groups: Immutable.OrderedSet(),
                isActive: true,
                name: '',
                roles: Immutable.OrderedSet(),
                defaultPartnerId: null,
                webPartnerIds: Immutable.List(),
            }),
            permissionPackages: Immutable.List(),
            size: 0,
            total: 0,
            totalPages: 0
        });
    }

    getValidations() {
        return Validations.validate(this.getState().get('permissionPackage'), PermissionPackageStoreValidations);
    }

    reduce(state, action) {
        switch (action.actionType) {
        case PermissionPackageConstants.LOAD:
            state = state.merge({
                activePage: Math.ceil(action.offset/action.size),
                offset: action.offset,
                size: action.size,
                total: action.total,
                totalPages: Math.ceil(action.total/action.size),
                permissionPackages: action.permissionPackages,
            });
            break;

        case PermissionPackageConstants.PRESET.UPDATE:
            state = state.setIn(['permissionPackage', ...action.attr.split('.')], action.value);
            break;

        case PermissionPackageConstants.SET.BRAINIAC_GROUPS:
            state = state.setIn(['permissionPackage', 'brainiacGroups'], action.groups);
            break;

        case PermissionPackageConstants.SET.CLIENT_REP_GROUPS:
            state = state.setIn(['permissionPackage', 'clientRepGroups'], action.clientRepGroups);
            break;

        case PermissionPackageConstants.SET.GROUPS:
            state = state.setIn(['permissionPackage', 'groups'], action.groups);
            break;

        case PermissionPackageConstants.SET.ROLES:
            state = state.setIn(['permissionPackage', 'roles'], action.roles);
            break;

        case PermissionPackageConstants.SET.STATIONS:
            state = state.setIn(['permissionPackage', 'stations'], action.stations);
            break;

        case PermissionPackageConstants.PRESET.GET.SUCCESS:
            state = state.set('permissionPackage', action.permissionPackage);
            state = state.set('originalPermissionPackage', action.permissionPackage);
            break;
        case PermissionPackageConstants.PRESET.UPDATE.SUCCESS:
            state = state.set('originalPermissionPackage', state.get('permissionPackage'));
            break;

        case PermissionPackageConstants.CLEAR:
            state = this.getInitialState();
            break;

        case PermissionPackageConstants.FILTER.CLEAR:
            state = state.set('filters', Immutable.Map());
            break;

        case PermissionPackageConstants.FILTER.SET:
            state = state.setIn(['filters', ...action.attr.split('.')], action.value);
            break;
        }

        return state;
    }
}

let store = new PermissionPackageStore(Dispatcher);

export default store;

export {PermissionPackageStoreValidations};
