/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

import DocumentTitle from '../../common/document-title';
import ListUsers from '../../common/list-users/react-list-users';
import SearchBox from '../../common/search-box/search-box';
import {WithStoreOnRoute} from '../../common/store-on-route';
import {Debounce} from '../../common/utils/utils';
import {LayoutActions} from '../../layout/layout-actions';
import LayoutStore from '../../layout/layout-store';
import Preloader from '../../preloader/';
import PreloaderStore from '../../preloader/preloader-store';
import SessionStore from '../../user/session/session-store';
import {UserActions} from '../../user/user-actions';
import UserStore from '../../user/user-store';
import FilterOptions from '../filter-options/filter-options';

class Browse extends Component {

    static get propTypes() {
        return {
            permissions: PropTypes.object.isRequired,
            location: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }


    static calculateState() {
        return {
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible'),
            showFiltersPanel: LayoutStore.getState().get('showFiltersPanel'),
            activePage: UserStore.getState().get('activePage'),
            total: UserStore.getState().get('total'),
            totalPages: UserStore.getState().get('totalPages'),
            users: UserStore.getState().get('users')
        };
    }

    static getStores() {
        return [PreloaderStore, LayoutStore, UserStore];
    }

    static get defaultProps() {
        return {
            disabled: true,
            passwordResetAllowed: false,
            unlockUserAllowed: false
        };
    }

    static getPermissions() {
        return {
            canDownloadUserList: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.MANAGEMENT.EXPORT_USERS),
            canReadAccounts: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.READ),
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.handleDownloadUsersList = this.handleDownloadUsersList.bind(this);
        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.handleToggleFiltersPanel = this.handleToggleFiltersPanel.bind(this);
    }

    componentDidMount() {
        // Trigger an action that will request the
        // initial data to the server.
        UserActions.clear();
        UserActions.get(
            this.getRouteState()
        );
        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.location.query !== this.props.location.query ||
            nextState.preloaderVisible !== this.state.preloaderVisible ||
            nextState.showFiltersPanel !== this.state.showFiltersPanel ||
            nextState.total !== this.state.total ||
            nextState.users !== this.state.users) {
            return true;
        }
        return false;
    }

    componentWillUpdate(nextProps) {
        if (this.props.location !== nextProps.location) {
            UserActions.get(this.getRouteState(nextProps));
        }
        return;
    }

    handleDownloadUsersList() {
        UserActions.downloadUserList(this.getRouteState());
    }

    /**
     * Handle clicks on pagination controls.
     * @param  {Number} pageNumber page to load.
     */
    handleLoadPage(pageNumber) {
        // pageNumber x size = offset.
        this.setRouteState('offset', pageNumber * 20)
            .apply();
        return;
    }

    handleSearchTerm(term) {
        this.setRouteState('q', term)
            .clearRouteState('offset')
            .apply();
        return;
    }

    handleSortChange(sortFieldName, sortDirection) {
        this.setRouteState('sort-field-name', sortFieldName)
            .setRouteState('sort-direction', sortDirection)
            .apply();
        return;
    }

    handleToggleFiltersPanel() {
        LayoutActions.toggleFiltersPanel();
        return;
    }

    render() {
        return (
            <div>
                <DocumentTitle
                    message="document-titles.accounts"
                >
                    <div className={ClassNames({'control-sidebar-open': this.state.showFiltersPanel})}>
                        <div className={ClassNames({'sidebar-opened': this.state.showFiltersPanel})}>
                            <Preloader show={this.state.preloaderVisible} fixed loadingDots>
                                <section className="content-header">
                                    <h1>
                                        <i className="fas fa-user"></i>&nbsp;{this.context.intl.messages['accounts.title']}
                                        &nbsp;<small>{this.state.total}</small>
                                    </h1>
                                </section>
                                <section className="content">
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <div className="container-fluid no-x-padding">
                                                <div className="row">
                                                    <div className="col-md-6 show-grid">
                                                        <SearchBox onChange={this.handleSearchTerm} value={this.getRouteState().get('q')}/>
                                                    </div>
                                                    <div className="col-md-3 show-grid">
                                                        {this.props.permissions.canDownloadUserList && <Button bsStyle="primary" bsSize="large" onClick={this.handleDownloadUsersList}>
                                                            <i className="fas fa-download"></i>&nbsp;{this.context.intl.messages['accounts.export-users']}
                                                        </Button>}
                                                    </div>
                                                    <div className="col-md-3 show-grid">
                                                        <Button onClick={this.handleToggleFiltersPanel} bsStyle="primary" bsSize="large" className="pull-right">
                                                            <i className="fas fa-sliders-h"></i>&nbsp;{this.context.intl.messages['common.filteringOptions']}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="container-fluid no-x-padding">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="box">
                                                            <div className="box-body">
                                                                <ListUsers
                                                                    activePage={this.state.activePage}
                                                                    columns={[
                                                                        'ACCOUNTALERTS', 'NAME', 'COMPANY', 'EMAIL', 'STATUS',
                                                                        'CLIENT_REP_GROUP', 'USER_GROUP_CLASSIFICATION', 'APPLICATION_DATE'
                                                                    ]}
                                                                    displayLinks={this.props.permissions.canReadAccounts}
                                                                    location={this.props.location}
                                                                    onPageChange={this.handleLoadPage}
                                                                    onSortChange={this.handleSortChange}
                                                                    sortDirection={this.getRouteState().get('sort-direction') || 'desc'}
                                                                    sortFieldName={this.getRouteState().get('sort-field-name') || 'updatedDate'}
                                                                    totalPages={this.state.totalPages}
                                                                    users={this.state.users}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <FilterOptions
                                    location={this.props.location}
                                />
                            </Preloader>
                        </div>
                    </div>
                </DocumentTitle>
            </div>
        );
    }

}

export {Browse as Browse_BASE};

export default WithStoreOnRoute(
    Container.create(Browse)
);
