/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {FormatTitleReleaseDate} from '@wbdt-sie/brainiac-web-common';
import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {AssetTitleActions} from './asset-title-actions';
import AssetTitleStore from './asset-title-store';
import AssignedTable from './assigned';
import UnassignedTable from './unassigned';
import {FormSection} from '../../common/form/form';
import SearchBox from '../../common/search-box/search-box';
import {WithStoreOnRoute} from '../../common/store-on-route';
import {Debounce} from '../../common/utils/utils';
import {TitleActions, TitleConstants} from '../../titles/title-actions';

/*istanbul ignore next*/
const COLUMNS = {
    mpmNumber: {
        get: title => title.get('mpmNumber')
    },
    title: {
        get: (title, props) => {
            let titleName = title.get('name');
            switch (title.get('category')) {
            case TitleConstants.TITLE_TYPES.ANIMATED_SERIES_SEASON.id:
            case TitleConstants.TITLE_TYPES.CARTOONS_SEASON.id:
            case TitleConstants.TITLE_TYPES.DOCUSERIES_SEASON.id:
            case TitleConstants.TITLE_TYPES.GAME_SHOW_SEASON.id:
            case TitleConstants.TITLE_TYPES.LIMITED_SERIES_SEASON.id:
            case TitleConstants.TITLE_TYPES.REALITY_SEASON.id:
            case TitleConstants.TITLE_TYPES.SEASON_HALF_HOUR.id:
            case TitleConstants.TITLE_TYPES.SEASON_ONE_HOUR.id:
            case TitleConstants.TITLE_TYPES.SHORT_PROGRAMS_SEASON.id:
            case TitleConstants.TITLE_TYPES.TALK_SHOW_SEASON.id:
                titleName = `${titleName} - Season ${title.get('season')}`;
                break;
            case TitleConstants.TITLE_TYPES.EPISODE.id:
                titleName = `${titleName} - ${title.get('parentTitleDisplayName') || ''} ${title.get('fullRunningOrder') || ''}`;
                break;
            }
            if (props.displayLinks) {
                titleName = <a href={`/titles/${title.get('id')}`} className="edit-title" target={props.linkTarget}>{titleName}</a>;
            }
            return titleName;
        }
    },
    titleReleaseDate: {
        get: (title, props, context) => {
            return FormatTitleReleaseDate(title, context.intl.messages);
        }
    },
    categoryGroupName: {
        get: title => {
            let gr = TitleActions.getCategoryGroup(title.get('category'));
            return TitleConstants.TITLE_CATEGORY_GROUPS_DESC[gr];
        }
    }
};

class Titles extends Component {
    static get propTypes() {
        return {
            assetId: PropTypes.number.isRequired,
            displayLinks: PropTypes.bool,
            location: PropTypes.object.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            displayLinks: false
        };
    }

    static calculateState() {
        return {
            assignedTitles: AssetTitleStore.getState().get('assignedTitles'),
            originalAssignedTitles: AssetTitleStore.getState().get('originalAssignedTitles'),
            titles: AssetTitleStore.getState().get('titles'),
            total: AssetTitleStore.getState().get('total'),
        };
    }

    static getStores() {
        return [AssetTitleStore];
    }

    constructor(props) {
        super(props);

        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
    }

    componentDidMount() {
        AssetTitleActions.get(this.getRouteState());
        AssetTitleActions.findByAssetId(this.props.assetId);
        return;
    }

    componentWillUpdate(nextProps) {
        if (this.props.location !== nextProps.location) {
            AssetTitleActions.get(this.getRouteState(nextProps));
        }
        return;
    }

    /**
     * Handle clicks on pagination controls.
     * @param  {Number} pageNumber page to load.
     */
    handleLoadPage(pageNumber) {
        this.setRouteState('offset', pageNumber * 20)
            .apply();
        return;
    }

    handleSortChange(sortFieldName, sortDirection) {
        this.setRouteState('sort-field-name', sortFieldName)
            .setRouteState('sort-direction', sortDirection)
            .apply();
        return;
    }

    /*istanbul ignore next*/
    handleSearchTerm(term) {
        this.setRouteState('title', term)
            .setRouteState('operator', 'AND')
            .clearRouteState('offset')
            .apply();
        return;
    }

    render() {
        let assignedTitlesCount = `${this.state.assignedTitles.size}`;

        return (
            <div>
                <h3><i className="fas fa-newspaper"></i>&nbsp;{this.context.intl.messages['asset.edit.titles-tab-title']}</h3>
                <div className="tab-pane">
                    <hr/>
                    <h4>{this.context.intl.messages['asset.edit.titles-tab.assigned-title']}&nbsp;<small>&nbsp;{assignedTitlesCount}</small></h4>
                    <FormSection>
                        <AssignedTable
                            assetId={this.props.assetId}
                            assignedTitles={this.state.assignedTitles}
                            displayLinks={this.props.displayLinks}
                        />
                    </FormSection>
                    <hr/>
                    <h4>{this.context.intl.messages['asset.edit.titles-tab.unassigned-title']}</h4>
                    <FormSection>
                        <div className="container-fluid no-x-padding">
                            <div className="row">
                                <div className="col-md-3"></div>
                                <div className="col-md-6 show-grid">
                                    <SearchBox value={this.getRouteState().get('title')} onChange={this.handleSearchTerm}/>
                                </div>
                                <div className="col-md-3"></div>
                            </div>
                        </div>
                        <UnassignedTable
                            activePage={Math.ceil((this.getRouteState().get('offset') || 0) / 20 ) || 0}
                            assignedTitles={this.state.assignedTitles}
                            displayLinks={this.props.displayLinks}
                            filters=""
                            onPageChange={this.handleLoadPage}
                            onSortChange={this.handleSortChange}
                            sortDirection={this.getRouteState().get('sort-direction') || 'desc'}
                            sortFieldName={this.getRouteState().get('sort-field-name') || 'updatedDate'}
                            titles={this.state.titles}
                            totalPages={Math.ceil(this.state.total/20) || 0}
                        />
                    </FormSection>
                </div>
            </div>
        );
    }
}

export default WithStoreOnRoute(Container.create(Titles));

export {
    COLUMNS as TitleColumns
};
