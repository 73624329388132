/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';

import Dispatcher from '../../../dispatcher/dispatcher';
import Request from '../../../request';
import {RoleConstants} from '../../../security/role/role-actions';

const CONSTANTS = {
    SET_PERMISSIONS_DATA: 'permissions_audit_actions.set.permissions.data'
};

class PermissionsAuditActions {

    getPermissions(userId) {
        let roleNames = [];
        Request.get(`user/${userId}/role`)
            .exec()
            .then(res => {
                roleNames = res.body.map(r => r.name);
                let requests = res.body.map((role) => Request.get(`security/role/${role.id}/permission`).exec());
                return Promise.all(requests);
            })
            .then(responses => {
                let ids = responses.reduce( (arr, response) => {
                    arr = arr.concat(response.body.map( permission => permission.id));
                    return arr;
                }, []);

                Dispatcher.dispatch({
                    actionType: RoleConstants.GET_ROLE_PERMISSIONS.SUCCESS,
                    permissions: Immutable.fromJS(ids).toSet()
                });

                let permissionsData = responses.reduce((arr, response, i) => {
                    arr = arr.concat(response.body.map((p) => {
                        let permission = {
                            associatedRoles:roleNames[i],
                            id: p.id,
                            uri: p.uri
                        };
                        return permission;
                    }));
                    return arr;
                }, []);

                Dispatcher.dispatch({
                    actionType: CONSTANTS.SET_PERMISSIONS_DATA,
                    permissionsData: Immutable.fromJS(permissionsData)
                });
            })
            .catch((err) => {
                throw err;
            });
    }
}

let actions = new PermissionsAuditActions();

export {
    actions as PermissionsAuditActions,
    CONSTANTS as PermissionsAuditConstants
};
