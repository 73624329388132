/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

import {StarredActions} from './starred-actions';
import StarredStore from './starred-store';
import SessionStore from '../../../user/session/session-store';

class Star extends Component {
    static get propTypes() {
        return {
            path: PropTypes.string.isRequired,
            permission: PropTypes.string,
            title: PropTypes.string
        };
    }

    static get defaultProps() {
        return {
            title: undefined,
            permission: undefined
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            id: Math.ceil(Math.random() * 100000),
            starred: StarredStore.getState().get('starred'),
            user: SessionStore.getState().get('authUser'),
        };
    }

    static getStores() {
        return [StarredStore, SessionStore];
    }

    constructor(props) {
        super(props);

        this.handleToggleStar = this.handleToggleStar.bind(this);
    }

    handleToggleStar() {
        const {path, title, permission} = this.props;
        StarredActions.toggleStar(path, title, this.state.user.get('id'), permission);
    }

    render() {
        const userId = this.state.user.get('id');
        const starredItems = this.state.starred.filter(item => item.get('userId') === userId);
        const isStarred = starredItems.some(s => s.get('path') === this.props.path);

        let tooltipMessage = this.context.intl.messages['common.star.add'];
        if (isStarred) {
            tooltipMessage = this.context.intl.messages['common.star.remove'];
        }

        const tooltip = <Tooltip id={`tooltip-start-${this.state.id}`} positionTop={0}>{tooltipMessage}</Tooltip>;

        return (
            <span className="star">
                <OverlayTrigger placement="top" overlay={tooltip}>
                    <a onClick={this.handleToggleStar}><i className={ClassNames('fas fa-star', {'selected': isStarred})}/></a>
                </OverlayTrigger>
            </span>
        );
    }
}

export default Container.create(Star);
