/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import jQuery from 'jquery';
import Moment from 'moment';
import PropTypes from 'prop-types';
import QueryString from 'querystring-es3';
import React, {Component} from 'react';
import {Button, Col, ControlLabel, FormControl, FormGroup, Row, DropdownButton, MenuItem} from 'react-bootstrap';
import {Link} from 'react-router';

import HistoryDefaultView from './default-view';
import {HistoryActions} from './history-actions';
import HistoryStore from './history-store';
import Avatar from '../../common/avatar';
import {MODAL_TYPE} from '../../common/notification/modal';
import {NotificationActions} from '../../common/notification/notification-actions';
import config from '../../config/config.js';
import {RouterActions} from '../../router/router-actions';
import {ActionHistoryConstants} from '../../system/action-history/action-history-actions';
import Panel from '../panel/panel';
import Pagination from '../table/pagination';

import './side-notes.less';

import 'datatables.net-responsive-bs/css/responsive.bootstrap.css';
import '../../styles/data-tables-brainiac.css';
// Load jQuery and register the datatables plugin.
import 'datatables.net-responsive-bs';

class Event extends Component {
    static get propTypes() {
        return {
            basePath: PropTypes.string,
            displayFull: PropTypes.bool.isRequired,
            note: PropTypes.object.isRequired,
            last: PropTypes.bool
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            basePath: '',
            last: false
        };
    }

    constructor(props) {
        super(props);

        this.getDescriptionTable = this.getDescriptionTable.bind(this);
        this.initTable = this.initTable.bind(this);
    }

    getDescriptionTable(note) {
        let changeDescription;
        try {
            changeDescription = JSON.parse(note.changeDescription);
        } catch (e) {
            // console.error(e);
            // This is a parse error. It's ok, sometimes the value is JSON,
            // sometimes it's not :)
            return note.changeDescription;
        }

        // WPB-8883 > Do not display Table rows for updatedBy/updatedDate history items
        if (note.actionHistoryType === ActionHistoryConstants.TYPES.UPDATE) {
            changeDescription.changedFields = changeDescription.changedFields.filter(c => !(c.fieldName === 'updatedDate' || c.fieldName === 'updatedBy'));
            if (!changeDescription.changedFields.length) {
                return note.displayDescription;
            }
        }

        // WPB-5479 > Display Delete action history using a table
        // just like the updates.
        if (note.actionHistoryType === ActionHistoryConstants.TYPES.DELETE) {
            changeDescription.changedFields = Object.keys(
                changeDescription
            ).filter(
                k => !k.match(/(created|updated)(By|Date)/)
            ).map(
                k => {
                    let oldValue = changeDescription[k];
                    if (Array.isArray(oldValue)) {
                        return oldValue;
                    }

                    return {
                        action: 'Remove',
                        fieldName: k,
                        oldValue
                    };
                }
            );

            changeDescription.changedFields = changeDescription.changedFields.reduce((r, nested) => r.concat(nested), []);
        }

        if (!changeDescription.changedFields || !Array.isArray(changeDescription.changedFields)) {
            return JSON.stringify(changeDescription);
        }

        let format = value => {
            if (value !== undefined && typeof value === 'string' && Moment.utc(value, 'YYYY-MM-DD HH:mm:ss.SSSSSS', true).isValid()) {
                return Moment.utc(value).tz(config.DefaultTimezone).format(this.context.intl.messages['datetime-format']);
            }

            return value;
        };

        return (
            <table className="history-table table table-bordered table-striped responsive" ref={this.initTable}>
                <thead>
                    <tr>
                        <th>Field</th>
                        <th>Action</th>
                        <th>New Value</th>
                        <th>Old Value</th>
                    </tr>
                </thead>
                <tbody>
                    {changeDescription.changedFields.map((f, i) => {
                        let newValue = format(f.newValue);
                        let oldValue = format(f.oldValue);

                        return (
                            <tr key={i}>
                                <td>{f.fieldName}</td>
                                <td>{f.action}</td>
                                <td><span className="parcel-tooltip" title={f.newValue}><span>{newValue}</span><span className="tooltip-inner">{f.newValue}</span></span></td>
                                <td><span className="parcel-tooltip" title={f.oldValue}><span>{oldValue}</span><span className="tooltip-inner">{f.oldValue}</span></span></td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    }

    getTimelineCreationLink(note) {
        let changeDescription;
        try {
            changeDescription = JSON.parse(note.changeDescription);
        } catch (e) {
            return note.changeDescription;
        }
        const {changedFields} = changeDescription;
        if (!changedFields || !Array.isArray(changedFields)) {
            return JSON.stringify(changedFields);
        }

        const qcVideoId = changedFields.find(field => field.fieldName === 'id')?.newValue;
        const qcVideoName = changedFields.find(field => field.fieldName === 'assetName')?.newValue;
        return (
            <div className="col-md-12 padding-y-10">
                <div className="col-md-6 old-value-column">
                    <div className="history-field-header">{this.context.intl.messages['history.qc-video.read-only']}</div>
                    <div>
                        <Link target="_blank" to={`/hardac/video-inbox/${qcVideoId}`} className="qc-link">
                            {qcVideoName}&nbsp;<i className="fa-solid fa-arrow-up-right-from-square"/>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }

    initTable(table) {
        if (!table) {
            this.$tableAPI.destroy();
            delete this.$tableAPI;
            delete this.$table;
            return;
        }

        this.$table = jQuery(table);
        this.$tableAPI = this.$table.DataTable({
            autoWidth: false,
            info: false,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        });

        return;
    }

    render() {
        let description, displayDescription;

        if (this.props.displayFull) {
            description = this.getDescriptionTable(this.props.note);

            if (this.props.note.actionHistoryType === ActionHistoryConstants.TYPES.DELETE && this.props.note.displayDescription !== this.props.note.changeDescription) {
                displayDescription = <small>{this.props.note.displayDescription}</small>;
            }
        } else if (this.props.note.actionHistoryType === ActionHistoryConstants.TYPES.UPDATE) {
            if (this.props.note.actor === this.props.note.displayDescription) {
                description = this.props.note.changeDescription;
            } else {
                description = this.props.note.displayDescription;
            }
        } else if (this.props.note.actionHistoryType === ActionHistoryConstants.TYPES.DELETE) {
            description = this.props.note.displayDescription;
        } else {
            description = `${this.props.note.changeDescription.slice(0, 50)}...`;
        }

        // In case Timeline creation do not show all fields changed, we will show only a link to QC video
        const isTimelineCreation = this.props.note.actionHistoryType === ActionHistoryConstants.TYPES.CREATE &&
            this.props.note.actionObjectType.toLowerCase() === ActionHistoryConstants.ACTION_OBJECTS.VIDEOTIMELINE.toLocaleLowerCase();
        if (isTimelineCreation) {
            description = this.getTimelineCreationLink(this.props.note);
        }

        if (description && description.type !== 'table') {
            let details;
            if (!this.props.displayFull) {
                details = (
                    <span>(<Link to={`${this.props.basePath}/history`}>Details</Link>)</span>
                );
            }

            description = (
                <p>
                    <small>
                        {description}<br/>
                        {details}
                    </small>
                </p>
            );
        }

        let last = '';
        if (!this.props.last) {
            last = <hr/>;
        }

        const avatar = <Avatar small name={this.props.note.actor}/>;

        return (
            <div>
                <p>
                    <small>
                        {avatar}&ensp;<strong style={{wordBreak: 'break-all'}}>{this.props.note.actor}</strong>
                    </small>
                    <small className="pull-right">
                        <em>{Moment(this.props.note.actionDate).tz(config.DefaultTimezone).format(this.context.intl.messages['datetime-short-format'])}</em>
                    </small>
                </p>
                {displayDescription}
                {description}
                {last}
            </div>
        );
    }
}

class Note extends Component {
    static get propTypes() {
        return {
            note: PropTypes.object.isRequired,
            last: PropTypes.bool.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    render() {
        let last = '';
        if (!this.props.last) {
            last = <hr/>;
        }

        const avatar = <Avatar small name={this.props.note.actor}/>;

        return (
            <div>
                <p>
                    <small>
                        {avatar}&ensp;<strong>{this.props.note.actor}</strong>
                    </small>
                    <small className="pull-right">
                        <em>{Moment(this.props.note.actionDate).tz(config.DefaultTimezone).format(this.context.intl.messages['datetime-short-format'])}</em>
                    </small>
                </p>
                <blockquote>
                    <i className="fas fa-quote-left"/>&nbsp;{this.props.note.changeDescription}
                </blockquote>
                {last}
            </div>
        );
    }
}

class History extends Component {
    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get propTypes() {
        return {
            actionObject: PropTypes.string.isRequired,
            basePath: PropTypes.string,
            displayFull: PropTypes.bool,
            id: PropTypes.number.isRequired,
            pagination: PropTypes.bool,
            pathname: PropTypes.string.isRequired,
            query: PropTypes.object
        };
    }

    static get defaultProps() {
        return {
            basePath: '',
            displayFull: true,
            offset: 0,
            pagination: false,
            query: {
                offset: 0,
                size: 50
            }
        };
    }

    static calculateState() {
        return {
            history: HistoryStore.getState().get('history')
        };
    }

    static getStores() {
        return [HistoryStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            defaultView: true, //TODO we should user url for default view and extended view
        }, this.constructor.calculateState());

        this.handlePageChange = this.handlePageChange.bind(this);

        return;
    }

    componentDidMount() {
        HistoryActions.get(this.props.id, this.props.actionObject, this.props.query.offset, this.props.query.size);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.id !== this.props.id ||
            nextProps.actionObject !== this.props.actionObject) {
            HistoryActions.get(nextProps.id, nextProps.actionObject, nextProps.query.offset, nextProps.query.size);
        }
    }

    componentWillUnmount() {
        HistoryActions.clear();
    }

    handlePageChange(pageNumber) {
        const size = this.props.query.size || 50;
        let query = Object.assign(
            {},
            this.props.query,
            {
                offset: pageNumber * size,
                size: size
            }
        );

        RouterActions.redirect(`${this.props.pathname}?${QueryString.stringify(query)}`);

        HistoryActions.get(this.props.id, this.props.actionObject, query.offset, query.size);

        return;
    }

    handleChangeView(value) {
        this.setState(() => ({
            defaultView: value
        }));
    }

    render() {
        let pagination;
        if (this.props.pagination) {
            const offset = this.props.query.offset || 0;
            const size = this.props.query.size || 50;
            pagination = (
                <Row>
                    <Col md={12} className="text-center">
                        <Pagination
                            activePage={Math.ceil(offset / size)}
                            onChange={this.handlePageChange}
                            totalPages={Math.ceil(this.state.history.get('totalCount', 0) / size)}
                        />
                    </Col>
                </Row>
            );
        }

        let totalItemsInPage = this.state.history.get('results', Immutable.List()).size;
        let historyViewTitle;
        let historyViewContent;
        if (this.state.defaultView) {
            historyViewTitle = this.context.intl.messages['history.default-view'].toUpperCase();
            historyViewContent = <HistoryDefaultView history={this.state.history.get('results')} />;
        } else {
            historyViewTitle = this.context.intl.messages['history.extended-view'].toUpperCase();
            historyViewContent = (
                <section className="history-section">
                    {this.state.history.get('results', Immutable.List()).toJS().map((note, i) => {
                        if (note.actionHistoryType === ActionHistoryConstants.TYPES.USER_GENERATED) {
                            return (
                                <Note key={note.id} note={note} last={(i + 1) === totalItemsInPage}/>
                            );
                        }
                        return (
                            <Event basePath={this.props.basePath} displayFull={this.props.displayFull} key={note.id} note={note} last={(i + 1) === totalItemsInPage}/>
                        );
                    })}
                </section>
            );
        }
        return (
            <div>
                <h3>
                    <i className="far fa-history"></i>{this.context.intl.messages['history.title']}
                    <div className="padding-x-10" style={{display: 'inline-block'}}>
                        <DropdownButton
                            className="btn btn-default-outline btn-block dropdown-toggle"
                            disabled={false}
                            title={historyViewTitle}
                        >
                            <MenuItem className="as-link" onClick={this.handleChangeView.bind(this, true)}>{this.context.intl.messages['history.default-view'].toUpperCase()}</MenuItem>
                            <MenuItem className="as-link" onClick={this.handleChangeView.bind(this, false)}>{this.context.intl.messages['history.extended-view'].toUpperCase()}</MenuItem>
                        </DropdownButton>
                    </div>
                </h3>
                <hr/>
                {historyViewContent}
                {pagination}
            </div>
        );
    }
}

const HistoryContainer = Container.create(History);

class SideNotes extends Component {
    static get propTypes() {
        return {
            basePath: PropTypes.string,
            defaultExpanded: PropTypes.bool,
            disabled: PropTypes.bool,
            onAddNote: PropTypes.func,
            onCancel: PropTypes.func,
            notes: PropTypes.array.isRequired,
            icon: PropTypes.string,
            title: PropTypes.string,
            dialogMessage: PropTypes.string
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true,
            basePath: '',
            onAddNote: undefined,
            onCancel: undefined,
            icon: 'fa-history',
            title: '',
            dialogMessage: '',
            defaultExpanded: true
        };
    }

    constructor(props) {
        super(props);

        this.onCancelNote = this.onCancelNote.bind(this);
        this.onAddNote = this.onAddNote.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.showAddNoteDialog = this.showAddNoteDialog.bind(this);

        return;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.defaultExpanded !== this.props.defaultExpanded) {
            return true;
        }
        return false;
    }

    onCancelNote() {
        if (this.props.onCancel) {
            return this.props.onCancel();
        }
    }

    onAddNote() {
        if (this.props.onAddNote && this.state.description) {
            return this.props.onAddNote(this.state.description);
        }
    }

    handleChange(event) {
        this.setState({description: event.target.value});
        return;
    }

    showAddNoteDialog(event) {
        this.setState({description:''});
        event.preventDefault();
        let dialog = <FormGroup className="gray-wrapper">
            <ControlLabel><i className="fas fa-pencil-alt"></i>{this.props.dialogMessage}</ControlLabel>
            <FormControl maxLength={4000} autoFocus componentClass="textarea" onChange={this.handleChange} />
        </FormGroup>;
        NotificationActions.show(
            MODAL_TYPE.DEFAULT,
            this.context.intl.messages['common.side-notes.add-note.title'],
            dialog,
            this.context.intl.messages['common.side-notes.add-note.button'],
            this.onAddNote,
            this.onCancelNote
        );
    }

    render() {
        let footerButton;
        if (!this.props.disabled) {
            footerButton = <Button
                bsStyle="default"
                className="btn btn-default btn-lg btn-block"
                onClick={this.showAddNoteDialog}><i className="fas fa-plus"></i>&nbsp;{this.context.intl.messages['common.side-notes.add-new']}
            </Button>;
        }

        let content = (<p><small><em>{this.context.intl.messages['common.side-notes.empty.notes']}</em></small></p>);
        if (this.props.notes.length > 0) {
            content = (
                <div className="history-section">
                    {this.props.notes.map((note, i) => {
                        if (note.actionHistoryType === ActionHistoryConstants.TYPES.USER_GENERATED) {
                            return (
                                <Note key={note.id} note={note} last={(i + 1) === this.props.notes.length}/>
                            );
                        }
                        return (
                            <Event basePath={this.props.basePath} displayFull={false} key={note.id} note={note} last={(i + 1) === this.props.notes.length}/>
                        );
                    })}
                </div>
            );
        }

        return (
            <Panel extraClasses={['MT10P']} iconClass={`fas ${this.props.icon}`} title={this.props.title} collapsible defaultExpanded={this.props.defaultExpanded} footer={footerButton}>
                {content}
            </Panel>
        );
    }
}

export {
    Event,
    HistoryContainer as History,
    Note,
    SideNotes
};
