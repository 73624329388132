/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Checkbox} from 'react-bootstrap';

import {FormItem} from '../../../../common/form/form';
import Panel from '../../../../common/panel/panel';
import {WithStoreOnRoute} from '../../../../common/store-on-route';
import {LayoutActions} from '../../../../layout/layout-actions';
import {MassAddUpdateActions, MassAddUpdateConstants} from '../../mass-add-update-actions';
import MassAddUpdateStore from '../../mass-add-update-store';

let StatusOptions = WithStoreOnRoute(class StatusOptions extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.isChecked = this.isChecked.bind(this);
        this.getCheckboxes = this.getCheckboxes.bind(this);
    }

    handleChange(status, event) {
        if (event.target.checked) {
            this.addToSetRoute('status', status)
                .setRouteState('operator', 'AND')
                .clearRouteState('offset')
                .apply();
        } else {
            this.removeFromSetRoute('status', status)
                .clearRouteState('offset')
                .apply();
        }
    }

    isChecked(status) {
        let accountStatus = this.props.location.query.status;
        if (!accountStatus) {
            return false;
        }
        if (!Array.isArray(accountStatus)) {
            // single string
            return accountStatus.toString() === status;
        } else {
            // is in array
            return accountStatus.indexOf(status) !== -1;
        }
    }

    getCheckboxes() {
        let checkboxes = Object.keys(MassAddUpdateConstants.INGEST_HEADERS_STATUS_TYPES).map(statusName => {
            let status = MassAddUpdateConstants.INGEST_HEADERS_STATUS_TYPES[statusName];
            return (
                <Checkbox
                    key={statusName}
                    checked={this.isChecked(status.id)}
                    onChange={this.handleChange.bind(this, status.id)}>
                    {status.name}
                </Checkbox>
            );
        }).filter(c=>{if (c) {return c;}});

        return checkboxes;
    }

    render() {
        let checkboxes = this.getCheckboxes();
        return (
            <Panel title="Status" classTitle="box-title" collapsible defaultExpanded>
                <div data-style="display: block;" className="box-body">
                    <div className="filtering-options">
                        <div className="form-group">
                            {checkboxes}
                        </div>
                    </div>
                </div>
            </Panel>
        );
    }
});

let CreateDateOptions = WithStoreOnRoute(class CreateDateOptions extends Component {

    static get propTypes() {
        return {
            filters: PropTypes.instanceOf(Immutable.Map),
            location: PropTypes.object,
            onChange: PropTypes.func
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            filters: undefined,
            location: undefined,
            onChange: undefined
        };
    }

    constructor(props) {
        super(props);

        this.setFormatDate = this.setFormatDate.bind(this);
    }

    setFormatDate(attr, value) {
        if (this.props.location) {
            this.setRouteState(attr, value)
                .setRouteState('operator', 'AND')
                .clearRouteState('offset')
                .apply();
            return;
        }
        MassAddUpdateActions.setFilter(attr, value);
    }

    render() {
        let model;
        if (this.props.location) {
            model = this.getRouteState();
        } else {
            model = this.props.filters;
        }
        return (
            <Panel title="Created Date" classTitle="box-title" collapsible defaultExpanded>
                <div className="box-body date-picker-filter">
                    <FormItem
                        label={this.context.intl.messages['filter-options.expire-date.from']}
                        attr="start-date-created"
                        datepicker={{popoverTargetOffset: '10px -36px', showYearDropdown: true}}
                        model={model}
                        onChange={this.props.onChange}
                        setter={this.setFormatDate}
                        type="date"
                    />
                    <FormItem
                        label={this.context.intl.messages['filter-options.expire-date.to']}
                        attr="end-date-created"
                        datepicker={{popoverTargetOffset: '10px -36px', showYearDropdown: true}}
                        model={model}
                        onChange={this.props.onChange}
                        setter={this.setFormatDate}
                        type="date"
                    />
                </div>
            </Panel>
        );
    }
});

let UpdatedDateOptions = WithStoreOnRoute(class UpdatedDateOptions extends Component {

    static get propTypes() {
        return {
            filters: PropTypes.instanceOf(Immutable.Map),
            location: PropTypes.object,
            onChange: PropTypes.func
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            filters: undefined,
            location: undefined,
            onChange: undefined
        };
    }

    constructor(props) {
        super(props);

        this.setFormatDate = this.setFormatDate.bind(this);
    }

    setFormatDate(attr, value) {
        if (this.props.location) {
            this.setRouteState(attr, value)
                .setRouteState('operator', 'AND')
                .clearRouteState('offset')
                .apply();
            return;
        }
        MassAddUpdateActions.setFilter(attr, value);
    }

    render() {
        let model;
        if (this.props.location) {
            model = this.getRouteState();
        } else {
            model = this.props.filters;
        }
        return (
            <Panel title="Updated Date" classTitle="box-title" collapsible defaultExpanded>
                <div className="box-body date-picker-filter">
                    <FormItem
                        label={this.context.intl.messages['filter-options.expire-date.from']}
                        attr="start-date-updated"
                        datepicker={{popoverTargetOffset: '10px -36px', showYearDropdown: true}}
                        model={model}
                        onChange={this.props.onChange}
                        setter={this.setFormatDate}
                        type="date"
                    />
                    <FormItem
                        label={this.context.intl.messages['filter-options.expire-date.to']}
                        attr="end-date-updated"
                        datepicker={{popoverTargetOffset: '10px -36px', showYearDropdown: true}}
                        model={model}
                        onChange={this.props.onChange}
                        setter={this.setFormatDate}
                        type="date"
                    />
                </div>
            </Panel>
        );
    }
});

let UpdatedBy = WithStoreOnRoute(class UpdatedBy extends Component {
    static get propTypes() {
        return {
            filters: PropTypes.instanceOf(Immutable.Map),
            location: PropTypes.object,
            onChange: PropTypes.func
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            filters: undefined,
            location: undefined,
            onChange: undefined
        };
    }

    constructor(props) {
        super(props);

        this.setValue = this.setValue.bind(this);
    }

    setValue(attr, value) {
        if (this.props.location) {
            this.setRouteState(attr, value)
                .setRouteState('operator', 'AND')
                .clearRouteState('offset')
                .apply();
            return;
        }
        MassAddUpdateActions.setFilter(attr, value);
    }

    render() {
        let model;
        if (this.props.location) {
            model = this.getRouteState();
        } else {
            model = this.props.filters;
        }
        return (
            <Panel title="Updated By" classTitle="box-title" collapsible defaultExpanded>
                <div className="box-body">
                    <FormItem
                        attr="updated-by"
                        label={this.context.intl.messages['titles.mass-add.top-table-header.updated-by']}
                        model={model}
                        onChange={this.props.onChange}
                        setter={this.setValue}
                        type="text"
                    />
                </div>
            </Panel>
        );
    }
});

let CreatedBy = WithStoreOnRoute(class CreatedBy extends Component {
    static get propTypes() {
        return {
            filters: PropTypes.instanceOf(Immutable.Map),
            location: PropTypes.object,
            onChange: PropTypes.func
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            filters: undefined,
            location: undefined,
            onChange: undefined
        };
    }

    constructor(props) {
        super(props);

        this.setValue = this.setValue.bind(this);
    }

    setValue(attr, value) {
        if (this.props.location) {
            this.setRouteState(attr, value)
                .setRouteState('operator', 'AND')
                .clearRouteState('offset')
                .apply();
            return;
        }
        MassAddUpdateActions.setFilter(attr, value);
    }

    render() {
        let model;
        if (this.props.location) {
            model = this.getRouteState();
        } else {
            model = this.props.filters;
        }
        return (
            <Panel title="Created By" classTitle="box-title" collapsible defaultExpanded>
                <div className="box-body">
                    <FormItem
                        attr="submitted-user"
                        label={this.context.intl.messages['titles.mass-add.top-table-header.created-by']}
                        model={model}
                        onChange={this.props.onChange}
                        setter={this.setValue}
                        type="text"
                    />
                </div>
            </Panel>
        );
    }
});

class FilterOptions extends Component {

    static get propTypes() {
        return {
            filters: PropTypes.instanceOf(Immutable.Map),
            location: PropTypes.object,
        };
    }

    static get defaultProps() {
        return {
            filters: undefined,
            location: undefined
        };
    }

    constructor(props) {
        super(props);

        this.handleToggleFiltersPanel = this.handleToggleFiltersPanel.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.filters !== this.props.filters ||
            nextProps.location !== this.props.location) {
            return true;
        }

        return false;
    }

    handleToggleFiltersPanel() {
        LayoutActions.toggleFiltersPanel();
    }

    onChange() {
        MassAddUpdateActions.setFilter('operator', 'AND');
        MassAddUpdateActions.get(
            MassAddUpdateStore.getState().get('filters'),
            0,
            20
        );
    }

    render() {

        return (
            <div>
                <aside className="control-sidebar B(0) Ovx(h) Ovy(a)">

                    <div className="tab-content">

                        <p>
                            <button className="btn btn-block bg-navy" onClick={this.handleToggleFiltersPanel}><i className="fas fa-chevron-right pull-right"></i>&nbsp;Close Options</button>
                        </p>

                        <StatusOptions
                            location={this.props.location}
                        />

                        <UpdatedDateOptions
                            filters={this.props.filters}
                            location={this.props.location}
                            onChange={this.onChange}
                        />

                        <UpdatedBy
                            filters={this.props.filters}
                            location={this.props.location}
                            onChange={this.onChange}
                        />

                        <CreateDateOptions
                            filters={this.props.filters}
                            location={this.props.location}
                            onChange={this.onChange}
                        />

                        <CreatedBy
                            filters={this.props.filters}
                            location={this.props.location}
                            onChange={this.onChange}
                        />

                    </div>

                </aside>

                <div style={{position: 'fixed', height: 'auto'}} className="control-sidebar-bg"></div>
            </div>
        );
    }
}

export default WithStoreOnRoute(FilterOptions);

export {
    StatusOptions,
    CreateDateOptions,
    UpdatedDateOptions,
    UpdatedBy,
    CreatedBy
};
