/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
*/

import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link} from 'react-router';
import ReactTable from 'react-table';

import {GetNoDataProps} from '~/src/common/react-table/react-table';
import Pagination from '~/src/common/table/pagination';
import Config from '~/src/config/config.js';

import 'react-table/react-table.css';

export const CellSubjectRequestId = (c, displayLinks) => {
    let requestId = c.original.get('subjectRequestId');
    if (displayLinks) {
        requestId = <Link to={`/privacy/${c.original.get('id')}`}>{requestId}</Link>;
    }
    return (
        <div>
            {requestId}
        </div>
    );
};

export const CellSimpleValue = (c, fieldName) => {
    return (
        <div>
            {c.original.get(fieldName)}
        </div>
    );
};

export const CellCreatedDate = (c, defaultTimezone, message) => {
    const createdDatetime = Moment(c.original.get('createdDate')).tz(defaultTimezone).format(message);
    return (
        <div>
            {createdDatetime}
        </div>
    );
};

export default class Table extends Component {
    static get propTypes() {
        return {
            activePage: PropTypes.number.isRequired,
            displayLinks: PropTypes.bool,
            onPageChange: PropTypes.func.isRequired,
            privacyRequests: PropTypes.instanceOf(Immutable.List).isRequired,
            totalPages: PropTypes.number.isRequired,
        };
    }

    static get defaultProps() {
        return {
            displayLinks: false
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
        };
    }

    static calculateState() {
        return {};
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();
    }

    render() {
        let columns = [{
            id: 'subjectRequestId',
            Header: <strong>{this.context.intl.messages['privacy.browse.subject']}</strong>,
            maxWidth: 400,
            Cell: /* istanbul ignore next */ c => CellSubjectRequestId(c, this.props.displayLinks),
        }, {
            id: 'subjectId',
            Header: <strong>{this.context.intl.messages['privacy.browse.subject-id']}</strong>,
            Cell: /* istanbul ignore next */ c => CellSimpleValue(c, 'subjectId')
        }, {
            id: 'subjectRequestType',
            Header: <strong>{this.context.intl.messages['privacy.browse.request-type']}</strong>,
            maxWidth: 260,
            Cell: /* istanbul ignore next */ c => CellSimpleValue(c, 'subjectRequestType')
        }, {
            id: 'status',
            Header: <strong>{this.context.intl.messages['privacy.browse.status']}</strong>,
            maxWidth: 260,
            Cell: /* istanbul ignore next */ c => CellSimpleValue(c, 'status')
        }, {
            id: 'createdDate',
            Header: <strong>{this.context.intl.messages['privacy.browse.date-received']}</strong>,
            maxWidth: 260,
            Cell: /* istanbul ignore next */ c => CellCreatedDate(c, Config.DefaultTimezone, this.context.intl.messages['datetime-format-0'])
        }];

        const pagination = <div className="row">
            <div className="col-sm-7 col-sm-offset-5">
                <Pagination
                    activePage={this.props.activePage}
                    onChange={this.props.onPageChange}
                    totalPages={this.props.totalPages}
                />
            </div>
        </div>;

        return (
            <div>
                <ReactTable
                    className="-striped table-bordered table-striped responsive"
                    columns={columns}
                    data={this.props.privacyRequests}
                    getNoDataProps={GetNoDataProps(this.props.privacyRequests.size)}
                    loading={false}
                    showPagination={false}
                    sortable={false}
                    resizable={false}
                    pageSize={this.props.privacyRequests.size}
                />
                {pagination}
            </div>
        );
    }
}
