/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';
import Moment from 'moment';

import SessionStore from './session/session-store';
import {DeliveryNotificationSave, DeliveryProfileSave} from '../accounts/create/delivery/delivery-actions';
import {SubscriptionsSave} from '../accounts/create/subscriptions/subscriptions-actions';
import {AlertTypes} from '../common/notification/alert';
import {NotificationActions} from '../common/notification/notification-actions';
import {CompareImmutable, CompareImmutableList} from '../common/utils/utils';
import config from '../config/config.js';
import Dispatcher from '../dispatcher/dispatcher';
import {PreloaderActions} from '../preloader/preloader-actions';
import Request, {resolveURL} from '../request';
import {RouterActions} from '../router/router-actions';
import {ActionHistoryConstants} from '../system/action-history/action-history-actions';

import {DownloadActions} from '~/src/common/download/download-actions';
import {GroupConstants} from '~/src/security/group/group-actions';

// Require for Proper Timezone Display
require('moment-timezone');
const configtz = Moment().tz(config.DefaultTimezone).format('ZZ');

const CONSTANTS = {
    ACCOUNT_ALERTS: {
        // errorCols and warningCols represent the columnIds on mass add accounts table to render as error/warning state
        ACCOUNT_LOCKED: {
            description: 'Account Locked',
            disableFilter: true,
            errorCols: [],
            errorText: 'Account Locked',
            icon: 'fas fa-fw fa-lock text-red',
            id: 'ACCOUNT_LOCKED',
            tip: 'user.account-alerts.tooltip.account-locked',
            warningCols: [],
            applyToApplicant: false,
        },
        CLIENT_REP_COUNTRY: {
            description: 'Your Client Rep is not valid for your Country',
            disableFilter: true,
            errorCols: ['country'],
            errorText: 'Client Rep Country Invalid',
            icon: 'fa-solid fa-fw fa-user-secret text-red',
            id: 'CLIENT_REP_COUNTRY',
            tip: 'user.account-alerts.tooltip.client-rep-country',
            warningCols: [],
            applyToApplicant: false,
        },
        COUNTRY_NOT_FOUND: {
            description: 'Country name not found in system',
            disableFilter: true,
            errorCols: ['country'],
            errorText: 'Country Not Found',
            icon: 'fa-solid fa-fw fa-globe text-red',
            id: 'COUNTRY_NOT_FOUND',
            tip: 'user.account-alerts.tooltip.country-not-found',
            warningCols: [],
            applyToApplicant: false,
        },
        DOMAIN_BLACKLIST: {
            description: 'Email domain is black listed',
            disableFilter: true,
            errorCols: [],
            errorText: 'Domain Blacklisted',
            icon: 'fa-solid fa-fw fa-envelope text-yellow',
            id: 'DOMAIN_BLACKLIST',
            tip: 'user.account-alerts.tooltip.domain-blacklist',
            warningCols: ['emailaddress'],
            applyToApplicant: true,
        },
        DUPLICATE_EMAIL: {
            description: 'Duplicate email found in system',
            disableFilter: true,
            errorCols: ['emailaddress'],
            errorText: 'Duplicate Email',
            icon: 'fa-solid fa-fw fa-envelopes text-red',
            id: 'DUPLICATE_EMAIL',
            tip: 'user.account-alerts.tooltip.duplicate-email',
            warningCols: [],
            applyToApplicant: true,
        },
        DUPLICATE_NAME: {
            description: 'Duplicate name found in system',
            disableFilter: true,
            errorCols: [],
            errorText: 'Duplicate Name',
            icon: 'fas fa-fw fa-exclamation-triangle text-yellow',
            id: 'DUPLICATE_NAME',
            tip: 'user.account-alerts.tooltip.duplicate-name',
            warningCols: ['firstName', 'lastName'],
            applyToApplicant: true,
        },
        GENERIC_ERROR: {
            // This is a generic flag to be applied if an exact match isn't found
            description: 'Generic error',
            disableFilter: true,
            errorCols: [],
            errorText: 'Generic Error',
            icon: 'fas fa-fw fa-exclamation-triangle text-red',
            id: 'GENERIC-ERROR',
            tip: 'user.account-alerts.tooltip.generic-error',
            warningCols: [],
            applyToApplicant: false,
        },
        HAS_DUPLICATE_ACCOUNTS: {
            description: 'Duplicate Account',
            errorCols: [],
            errorText: 'Duplicate Account',
            icon: 'fas fa-fw fa-people-arrows text-red',
            id: 'HAS_DUPLICATE_ACCOUNTS',
            tip: 'user.account-alerts.tooltip.duplicate-accounts',
            warningCols: [],
            applyToApplicant: true,
        },
        INVALID_EMAIL: {
            // NOTE: Currently the api is only checking for xss attacks, not validating email address format meets spec!
            description: 'Email address is invalid',
            disableFilter: true,
            errorCols: ['emailaddress'],
            errorText: 'Invalid Email',
            icon: 'fa-solid fa-fw fa-envelope text-red',
            id: 'INVALID_EMAIL',
            tip: 'user.account-alerts.tooltip.invalid-email',
            warningCols: [],
            applyToApplicant: false,
        },
        LOCKED_OUT: {
            description: 'Locked Out',
            errorCols: [],
            errorText: 'Account Locked Out',
            icon: 'fas fa-fw fa-lock text-red',
            id: 'LOCKED_OUT',
            tip: 'user.account-alerts.tooltip.locked-out',
            warningCols: [],
            applyToApplicant: false,
        },
        MISSING_COMPANY: {
            description: 'Company name is blank',
            disableFilter: true,
            errorCols: ['company'],
            errorText: 'Missing Company',
            icon: 'fas fa-fw fa-asterisk text-red',
            id: 'MISSING_COMPANY',
            tip: 'user.account-alerts.tooltip.missing-company',
            warningCols: [],
            applyToApplicant: false,
        },
        MISSING_COUNTRY: {
            description: 'Country name is blank',
            disableFilter: true,
            errorCols: ['country'],
            errorText: 'Missing Country',
            icon: 'fas fa-fw fa-asterisk text-red',
            id: 'MISSING_COUNTRY',
            tip: 'user.account-alerts.tooltip.missing-country',
            warningCols: [],
            applyToApplicant: false,
        },
        MISSING_EMAIL: {
            description: 'Email address is blank',
            disableFilter: true,
            errorCols: ['emailaddress'],
            errorText: 'Missing Email',
            icon: 'fas fa-fw fa-asterisk text-red',
            id: 'MISSING_EMAIL',
            tip: 'user.account-alerts.tooltip.missing-email',
            warningCols: [],
            applyToApplicant: false,
        },
        MISSING_FIRSTNAME: {
            description: 'First name is blank',
            disableFilter: true,
            errorCols: ['firstName'],
            errorText: 'Missing First Name',
            icon: 'fas fa-fw fa-asterisk text-red',
            id: 'MISSING_FIRSTNAME',
            tip: 'user.account-alerts.tooltip.missing-firstname',
            warningCols: [],
            applyToApplicant: false,
        },
        MISSING_LASTNAME: {
            description: 'Last name is blank',
            disableFilter: true,
            errorCols: ['lastName'],
            errorText: 'Missing Last Name',
            icon: 'fas fa-fw fa-asterisk text-red',
            id: 'MISSING_LASTNAME',
            tip: 'user.account-alerts.tooltip.missing-lastname',
            warningCols: [],
            applyToApplicant: false,
        },
        MULTIPLE_ISSUES: {
            description: 'Multiple Issues',
            disableFilter: true,
            errorCols: [],
            errorText: 'Multiple Issues',
            icon: 'fas fa-fw fa-exclamation-triangle text-red',
            id: 'MULTIPLE_ISSUES',
            tip: 'user.account-alerts.tooltip.multiple-issues',
            warningCols: [],
            applyToApplicant: false,
        },
        REQUEST_PERMISSION: {
            description: 'Access Request',
            errorCols: [],
            errorText: 'Access Request',
            icon: 'fas fa-fw fa-question-circle text-red',
            id: 'REQUEST_PERMISSION',
            tip: 'user.account-alerts.tooltip.request-permission',
            warningCols: [],
            applyToApplicant: false,
        }
    },
    ACCOUNT_IMPORT_STATUS: {
        // For User Import user list table to indicate the user record status
        SUCCESS: {
            description: 'Success',
            icon: 'fas fa-check text-success',
            id: 1,
            tip: 'user.import-status.success.tooltip'
        },
        PENDING: {
            description: 'Pending',
            icon: 'fas fa-ellipsis-h',
            id: 2,
            tip: 'user.import-status.pending.tooltip'
        },
    },
    ACCOUNT_TYPES: {
        ANONYMOUS: {
            description: 'Anonymous',
            id: 'ANONYMOUS'
        },
        PRIMARY: {
            description: 'Primary',
            id: 'PRIMARY'
        },
        SECONDARY: {
            description: 'Secondary',
            id: 'SECONDARY'
        }
    },
    CLEAR: 'user_actions.clear',
    CLASSIFICATION_TYPE: {
        UNCLASSIFIED: {
            filter: 'UNCLASSIFIED',
            id: 0,
            name: 'Unclassified',
        },
        ADMINISTRATOR: {
            filter: 'ADMINISTRATOR',
            id: 1,
            name: 'Administrator'
        },
        INTERNAL_EMPLOYEE: {
            filter: 'INTERNAL_EMPLOYEE',
            id: 2,
            name: 'Internal Employee'
        },
        INTERNATIONAL_CLIENT: {
            filter: 'INTERNATIONAL_CLIENT',
            id: 3,
            name: 'International Client'
        },
        DOMESTIC_CLIENT: {
            filter: 'DOMESTIC_CLIENT',
            id: 4,
            name: 'Domestic Client'
        },
        SYNDICATION_CLIENT: {
            filter: 'SYNDICATION_CLIENT',
            id: 5,
            name: 'Syndication Client'
        },
        AIRLINE_CLIENT: {
            filter: 'AIRLINE_CLIENT',
            id: 6,
            name: 'Airline Client'
        },
        FORMATS_CLIENT: {
            filter: 'FORMATS_CLIENT',
            id: 7,
            name: 'Formats Client'
        },
        GERMAN_B2B_CLIENT: {
            filter: 'GERMAN_B2B_CLIENT',
            id: 8,
            name: 'German B2B Client'
        },
        DTDS_CLIENT: {
            filter: 'DTDS_CLIENT',
            id: 9,
            name: 'DTDS Client'
        },
        BRANDED_PRESS_USER: {
            filter: 'BRANDED_PRESS_USER',
            id: 10,
            name: 'Branded Press User'
        },
        TRADITIONAL_PRESS_USER: {
            filter: 'TRADITIONAL_PRESS_USER',
            id: 11,
            name: 'Traditional Press User'
        },
        AWARDS_USER: {
            filter: 'AWARDS_USER',
            id: 12,
            name: 'Awards User'
        },
    },
    FILTER: {
        CLEAR: 'user_actions.filter.clear',
        SET: 'user_actions.filter.set'
    },
    GET: {
        USER: {
            ERROR: 'user_actions.get.user.error',
            START: 'user_actions.get.user.start',
            SUCCESS: 'user_actions.get.user.success'
        },
        USERS: {
            ERROR: 'user_actions.get.users.error',
            START: 'user_actions.get.users.start',
            SUCCESS: 'user_actions.get.users.success'
        },
    },
    RESTRICTED_VIDEO_STATUS: {
        LOCKED: {
            description: 'Locked',
            icon: 'fas fa-lock text-red',
            id: 'LOCKED',
        },
        UNLOCKED: {
            description: 'Unlocked',
            icon: 'fas fa-lock-open text-gray',
            id: 'UNLOCKED',
        },
    },
    SEND_MFA_ENROLLMENT_EMAIL: {
        ERROR: 'user_actions.send-mfa-enrollment-email.error',
        START: 'user_actions.send-mfa-enrollment-email.start',
        SUCCESS: 'user_actions.send-mfa-enrollment-email.success'
    },
    SORT: {
        SET: 'user_actions.sort.set'
    },
    STATUS: {
        ACTIVE: {
            description: 'Active',
            extraStatus: false,
            isExpiredOrTemporary: false,
            icon: 'fas fa-check',
            id: 5,
            label: 'bg-wb-blue',
            tip: 'user.status.tooltip.active'
        },
        APPROVED: {
            description: 'Approved',
            extraStatus: false,
            isExpiredOrTemporary: false,
            icon: 'fas fa-check',
            id: 2,
            label: 'bg-yellow',
            tip: 'user.status.tooltip.approved'
        },
        DENIED: {
            description: 'Denied',
            extraStatus: true,
            isExpiredOrTemporary: false,
            icon: 'fas fa-times',
            id: 1,
            label: 'bg-black',
            tip: 'user.status.tooltip.denied'
        },
        EXPIRED: {
            description: 'Expired',
            extraStatus: false,
            isExpiredOrTemporary: true,
            icon: 'fas fa-ellipsis-h',
            id: 12,
            label: 'bg-red',
            tip: 'user.status.tooltip.expired'
        },
        NEEDS_REVIEW: {
            description: 'Review',
            extraStatus: false,
            isExpiredOrTemporary: false,
            icon: 'fas fa-exclamation-triangle',
            id: 0,
            label: 'bg-yellow',
            tip: 'user.status.tooltip.needs-review'
        },
        PENDING: {
            description: 'Pending',
            extraStatus: true,
            isExpiredOrTemporary: false,
            icon: 'fas fa-ellipsis-h',
            id: 3,
            label: 'bg-green',
            tip: 'user.status.tooltip.pending'
        },
        PRE_APPROVED: {
            description: 'Pre Approved',
            extraStatus: false,
            isExpiredOrTemporary: false,
            icon: 'fas fa-ellipsis-h',
            id: 8,
            label: 'bg-orange',
            tip: 'user.status.tooltip.pre-approved'
        },
        PRE_VALIDATED: {
            description: 'Pre Validated',
            extraStatus: false,
            isExpiredOrTemporary: false,
            icon: 'fas fa-ellipsis-h',
            id: 9,
            label: 'bg-orange',
            tip: 'user.status.tooltip.pre-validated'
        },
        REJECTED: {
            description: 'Rejected',
            extraStatus: false,
            isExpiredOrTemporary: false,
            icon: 'fas fa-times',
            id: 7,
            label: 'bg-black',
            tip: 'user.status.tooltip.rejected'
        },
        REMOVED: {
            description: 'Removed',
            extraStatus: false,
            isExpiredOrTemporary: false,
            icon: 'fas fa-minus-circle',
            id: 4,
            label: 'bg-black',
            tip: 'user.status.tooltip.removed'
        },
        TEMP_LOCKED: {
            description: 'Temp Locked',
            extraStatus: false,
            isExpiredOrTemporary: false,
            icon: 'fas fa-ellipsis-h',
            id: 10,
            label: 'bg-red',
            tip: 'user.status.tooltip.temp-locked'
        },
        TEMPORARY: {
            description: 'Temporary',
            extraStatus: false,
            isExpiredOrTemporary: true,
            icon: 'fas fa-ellipsis-h',
            id: 11,
            label: 'bg-temporary-user',
            tip: 'user.status.tooltip.temporary'
        },
        VALIDATED: {
            description: 'Permission',
            extraStatus: false,
            isExpiredOrTemporary: false,
            icon: 'fas fa-check',
            id: 6,
            label: 'bg-yellow',
            tip: 'user.status.tooltip.validated'
        },
        BANNED: {
            description: 'Banned',
            extraStatus: false,
            isExpiredOrTemporary: false,
            icon: 'fas fa-times',
            id: 13,
            label: 'bg-red',
            tip: 'user.status.tooltip.banned'
        }
    },
    SUGGESTED_USERS: {
        CLEAR: 'user_actions.suggested_users.clear',
        GET: {
            SUCCESS: 'user_actions.suggested_users.get.success'
        },
    },
    UPDATE: 'user_actions.update',
    USER: {
        CLEAROKTA: {
            ERROR: 'user_actions.user.clearokta.error',
            START: 'user_actions.user.clearokta.start',
            SUCCESS: 'user_actions.user.clearokta.success',
        },
        CLONE: 'user_actions.user.clone',
        DEACTIVATE: {
            ERROR: 'user_actions.user.deactivate.error',
            START: 'user_actions.user.deactivate.start',
            SUCCESS: 'user_actions.user.deactivate.success',
        },
        ENDSESSIONS: {
            ERROR: 'user_actions.user.endsessions.error',
            START: 'user_actions.user.endsessions.start',
            SUCCESS: 'user_actions.user.endsessions.success',
        },
        GET_RELATED_ACCOUNTS: {
            ERROR: 'user_actions.user.get.related_accounts.error',
            START: 'user_actions.user.get.related_accounts.start',
            SUCCESS: 'user_actions.user.get.related_accounts.success',
        },
        GUILDS: {
            ERROR: 'user_actions.user.get.guilds.error',
            START: 'user_actions.user.get.guilds.start',
            SUCCESS: 'user_actions.user.get.guilds.success',
        },
        PASSWORDRESET: {
            ERROR: 'user_actions.user.passwordreset.error',
            START: 'user_actions.user.passwordreset.start',
            SUCCESS: 'user_actions.user.passwordreset.success'
        },
        REACTIVATE: {
            ERROR: 'user_actions.user.reactivate.error',
            START: 'user_actions.user.reactivate.start',
            SUCCESS: 'user_actions.user.reactivate.success',
        },
        RESET_RESTRICTED_VIDEO_IP_COUNT: {
            ERROR: 'user_actions.user.reset_restricted_video_ip_count.error',
            START: 'user_actions.user.reset_restricted_video_ip_count.start',
            SUCCESS: 'user_actions.user.reset_restricted_video_ip_count.success',
        },
        RESET_RESTRICTED_VIDEO_VIEWS: {
            ERROR: 'user_actions.user.reset_restricted_video_views.error',
            START: 'user_actions.user.reset_restricted_video_views.start',
            SUCCESS: 'user_actions.user.reset_restricted_video_views.success',
        },
        RESTRICTED_IP_VIDEOS: {
            ERROR: 'user_actions.user.restricted_ip_videos.error',
            START: 'user_actions.user.restricted_ip_videos.start',
            SUCCESS: 'user_actions.user.restricted_ip_videos.success',
        },
        RESTRICTED_VIDEOS: {
            ERROR: 'user_actions.user.restricted_videos.error',
            START: 'user_actions.user.restricted_videos.start',
            SUCCESS: 'user_actions.user.restricted_videos.success',
        },
        SESSIONCOUNT: {
            ERROR: 'user_actions.user.sessioncount.error',
            START: 'user_actions.user.sessioncount.start',
            SUCCESS: 'user_actions.user.sessioncount.success',
        },
        SET: {
            EVENT_GROUPS: 'user_actions.user.set.event_groups',
            BRAINIAC_GROUPS: 'user_actions.user.set.brainiac_groups',
            GROUPS: 'user_actions.user.set.groups',
            PASSWORDRESET_ALLOWED: 'user_actions.user.set.passwordreset_allowed',
            ROLES: 'user_actions.user.set.roles',
            STATIONS: 'user_actions.user.set.stations',
            VALIDATIONS: 'user_actions.user.set.validations'
        },
        SET_PASSWORD_AS_ADMIN: {
            ERROR: 'user_actions.user.set_passord_as_admin.error',
            START: 'user_actions.user.set_passord_as_admin.start',
            SUCCESS: 'user_actions.user.set_passord_as_admin.success',
        },
        UNLOCK: {
            ERROR: 'user_actions.user.unlock.error',
            START: 'user_actions.user.unlock.start',
            SUCCESS: 'user_actions.user.unlock.success',
        },
        UPDATE_CONTACT: 'user_actions.user.update.contact',
        UPDATE_HOMEPAGES: 'user_actions.user.update.homepages',
        UPDATE_PARTNERS: 'user_actions.user.update.partners',
        UPDATE: 'user_actions.user.update',
        VALIDATIONS: {
            ERROR: 'user_actions.user.validations.error',
            START: 'user_actions.user.validations.start',
            SUCCESS: 'user_actions.user.validations.success',
        },
    },
    USER_ERROR_TYPES: {
        CLOUDFLARE: {id: 'CLOUDFLARE', name: 'CLOUDFLARE', displayName: 'user.error-types.cloudflare'},
        IP_LIMITATION: {id: 'IP_LIMITATION', name: 'IP_LIMITATION', displayName: 'user.error-types.ip-limitation'},
        PERMISSION_DENIED: {id: 'PERMISSION_DENIED', name: 'PERMISSION_DENIED', displayName: 'user.error-types.permission-denied'}
    },
    USER_TYPES: {
        ANONYMOUS: {id: 'ANONYMOUS', name: 'ANONYMOUS', displayName: 'accounts.create.summary.information.anonymous'},
        PRIMARY: {id: 'PRIMARY', name: 'PRIMARY', displayName: 'accounts.create.summary.information.primary'},
        SECONDARY: {id: 'SECONDARY', name: 'SECONDARY', displayName: 'accounts.create.summary.information.secondary'}
    },
    USERS: {
        TOGGLE_SELECT: 'user_actions.users.select',
        TOGGLE_SELECT_ALL: 'user_actions.users.select.all',
        UPDATE: 'user_actions.users.update'
    },

    toArray: function(constant) {
        return Object.keys(this[constant])
            .map(k => this[constant][k])
            .sort((a, b) => a.name.localeCompare(b.name));
    },

    defaultOptions: function() {
        return {
            messages: {
                error: 'accounts.edit.account.error',
                success: 'accounts.edit.account.success'
            }
        };
    }
};

class UserActions {
    /**
     * Set the user as approved by setting the id's of the current
     * authenticated user based on his roles.
     */
    approve() {
        if (SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.APPLICANTS.PERMISSIONS.VALIDATE)) {
            this.updateUser('cmsAdminUserId', SessionStore.getState().getIn(['authUser', 'id']));
        }

        if (SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.APPLICANTS.PERMISSIONS.APPROVE)) {
            this.updateUser('repUserId', SessionStore.getState().getIn(['authUser', 'id']));
        }
        return;
    }

    banUser(id, reason = '') {
        const data = {
            reason: reason
        };

        PreloaderActions.show('user-actions.banUser');
        Request.put(`user/${id}/ban`).send(data).exec()
            .then(() => {
                NotificationActions.showAlertSuccess('accounts.create.management.ban-user.success');
                this.findById(id);
                PreloaderActions.hide('user-actions.banUser');
            }).catch((err) => {
                NotificationActions.showAlertDanger('accounts.create.management.ban-user.error');
                PreloaderActions.hide('user-actions.banUser');
                throw err;
            });
    }

    unbanUser(id) {
        PreloaderActions.show('user-actions.unbanUser');
        Request.put(`user/${id}/unban`).exec()
            .then(() => {
                NotificationActions.showAlertSuccess('accounts.create.management.unban-user.success');
                this.findById(id);
                PreloaderActions.hide('user-actions.unbanUser');
            }).catch((err) => {
                NotificationActions.showAlertDanger('accounts.create.management.unban-user.error');
                PreloaderActions.hide('user-actions.unbanUser');
                throw err;
            });
    }

    bulkApprove(users) {
        let currentUserId = SessionStore.getState().getIn(['authUser', 'id']);
        let attrs = [];

        if (SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.APPLICANTS.PERMISSIONS.VALIDATE)) {
            attrs.push('cmsAdminUserId');
        }

        if (SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.APPLICANTS.PERMISSIONS.APPROVE)) {
            attrs.push('repUserId');
        }


        if (SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.APPLICANTS.PERMISSIONS.APPROVE_WITHOUT_PERMISSION)) {
            Promise.all(
                users.map(
                    u => Request.get(`user/${u.get('id')}`).exec().then(userRes => {
                        let data = userRes.body;

                        attrs.forEach(attr => data[attr] = currentUserId);

                        this.ignoreAttributesInAPI(data);

                        return Request.put(`user/${u.get('id')}`).send(data).exec();
                    }).catch(err => {
                        NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'accounts.applicants.bulk-approve.error.user', u.get('email'));
                        err.defaultPrevented = true;
                        throw err;
                    })
                )
            ).then(usersRes => {
                let emails = usersRes.map(userRes => userRes.body.email);
                NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'accounts.applicants.bulk-approve.success', emails.join(', '));

                usersRes.forEach(u => {
                    Dispatcher.dispatch({
                        actionType: CONSTANTS.USERS.UPDATE,
                        user: Immutable.fromJS(u.body)
                    });
                });

                return;
            }).catch(err => {
                if (!err.defaultPrevented) {
                    NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'accounts.applicants.bulk-approve.error');
                }
                throw err;
            });
        } else {
            Promise.all(
                users.map(
                    u => Promise.all([
                        Request.get(`user/${u.get('id')}/group`).exec(),
                        Request.get(`user/${u.get('id')}/role`).exec(),
                        Request.get(`user/${u.get('id')}`).exec()
                    ])
                        .spread((groupRes, roleRes, userRes) => {
                            let data = userRes.body;
                            let groups = Immutable.fromJS(groupRes.body);
                            let roles = Immutable.fromJS(roleRes.body);
                            if (roles.size && groups.size) {
                                if (groups.size > 1 || (groups.size === 1 && groups.first().get('name') !== 'Applicant')) {
                                    attrs.forEach(attr => data[attr] = currentUserId);

                                    ['applicantStatus', 'fullName', 'userActive'].forEach(p => delete data[p]);

                                    return Request.put(`user/${u.get('id')}`).send(data).exec();
                                }
                            }
                        }).catch(err => {
                            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'accounts.applicants.bulk-approve.error.user', u.get('email'));
                            err.defaultPrevented = true;
                            throw err;
                        })
                )
            ).then(usersRes => {
                let emails = [];
                usersRes.forEach(res => {
                    if (res !== undefined) {
                        emails.push(res.body.email);
                    }
                });

                usersRes.forEach(u => {
                    Dispatcher.dispatch({
                        actionType: CONSTANTS.USERS.UPDATE,
                        user: Immutable.fromJS(u.body)
                    });
                });

                if (emails.length) {
                    NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'accounts.applicants.bulk-approve.success', emails.join(', '));
                } else {
                    NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'accounts.applicants.bulk-approve.error.assign-permissions');
                }

                return;
            }).catch(err => {
                if (!err.defaultPrevented) {
                    NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'accounts.applicants.bulk-approve.error');
                }
                throw err;
            });
        }
    }

    bulkDeny(users, comments) {
        let currentUserId = SessionStore.getState().getIn(['authUser', 'id']);
        let attrs = [];
        if (SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.APPLICANTS.PERMISSIONS.DENY)) {
            attrs.push('deniedCMSAdminUserId');
        }

        if (SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.APPLICANTS.PERMISSIONS.REJECT)) {
            attrs.push('deniedRepUserId');
        }

        Promise.all(
            users.map(
                u => Request.get(`user/${u.get('id')}`).exec().then(userRes => {
                    let data = userRes.body;

                    attrs.forEach(attr => data[attr] = currentUserId);
                    data.deniedComments = comments;

                    this.ignoreAttributesInAPI(data);

                    return Request.put(`user/${u.get('id')}`).send(data).exec();
                }).catch(err => {
                    NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'accounts.applicants.bulk-deny.error.user', u.get('email'));
                    err.defaultPrevented = true;
                    throw err;
                })
            )
        ).then(usersRes => {
            let emails = usersRes.map(userRes => userRes.body.email);
            NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'accounts.applicants.bulk-deny.success', emails.join(', '));

            usersRes.forEach(u => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.USERS.UPDATE,
                    user: Immutable.fromJS(u.body)
                });
            });
            return;
        }).catch(err => {
            if (!err.defaultPrevented) {
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'accounts.applicants.bulk-deny.error');
            }
            throw err;
        });
    }

    /**
     * Clear the store. Restore the initial state.
     */
    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });

        return;
    }

    clearFilter() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.FILTER.CLEAR
        });
    }

    clearOktaHistory(userId, options) {
        // Clear Okta history for the user
        let notificationOptions = Object.assign(CONSTANTS.defaultOptions(), options);

        Dispatcher.dispatch({
            actionType: CONSTANTS.USER.CLEAROKTA.START,
            userId: userId
        });

        Request.del(`user/${userId}/okta-history`).exec()
            .then( () => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.USER.CLEAROKTA.SUCCESS,
                    userId: userId
                });
                this.findById(userId);
                if (notificationOptions.messages.success) {
                    NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, notificationOptions.messages.success);
                }
            })
            .catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.USER.CLEAROKTA.ERROR,
                    error: err
                });
                if (notificationOptions.messages.error) {
                    NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, notificationOptions.messages.error);
                }
                throw err;
            });

        return;
    }

    clearUsers() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SUGGESTED_USERS.CLEAR
        });
    }

    /**
    * Deactivate user, saving notes
    */
    deactivate(userId, reason, options) {
        // End all active sessions for the user
        let notificationOptions = Object.assign(CONSTANTS.defaultOptions(), options);

        Dispatcher.dispatch({
            actionType: CONSTANTS.USER.DEACTIVATE.START,
            userId: userId
        });

        Request.put(`user/${userId}/decommission`).send({reason: reason}).exec()
            .then( () => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.USER.DEACTIVATE.SUCCESS,
                    userId: userId
                });
                this.findById(userId);
                if (notificationOptions.messages.success) {
                    NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, notificationOptions.messages.success);
                }
            })
            .catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.USER.DEACTIVATE.ERROR,
                    error: err
                });
                if (notificationOptions.messages.error) {
                    NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, notificationOptions.messages.error);
                }
                throw err;
            });

        return;
    }

    /**
     * Set the user as denied by setting the id's of the current
     * authenticated user based on his roles.
     */
    deny(comments) {
        if (SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.APPLICANTS.PERMISSIONS.VALIDATE)) {
            this.updateUser('deniedCMSAdminUserId', SessionStore.getState().getIn(['authUser', 'id']));
        }

        if (SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.APPLICANTS.PERMISSIONS.APPROVE)) {
            this.updateUser('deniedRepUserId', SessionStore.getState().getIn(['authUser', 'id']));
        }

        this.updateUser('deniedComments', comments);
        return;
    }

    downloadUserList(queryParams, offset, size, sortFieldName, sortDirection) {
        queryParams = queryParams.toJS();
        if (queryParams.q) {
            queryParams.name = queryParams.q;
            queryParams.email = queryParams.q;
            queryParams.company = queryParams.q;
            delete queryParams.q;
        }

        ['start-apply-date', 'start-expire-date'].forEach( attr => {
            let d = queryParams[attr];
            if (d) {
                d = Moment(d);
                if (d.isValid()) {
                    d = d.format('YYYY-MM-DDT00:00:00.000'+configtz);
                } else {
                    d = '';
                }
                queryParams[attr] = d;
            }
        });

        ['end-apply-date', 'end-expire-date'].forEach( attr => {
            let d = queryParams[attr];
            if (d) {
                d = Moment(d);
                if (d.isValid()) {
                    d = d.format('YYYY-MM-DDT23:59:59.999'+configtz);
                } else {
                    d = '';
                }
                queryParams[attr] = d;
            }
        });

        PreloaderActions.show('user-actions.get');

        offset = offset || queryParams.offset || 0;
        size = size || 20;
        queryParams.offset = offset ;
        queryParams.size = size;
        queryParams['sort-field-name'] = sortFieldName || queryParams['sort-field-name'] || 'updatedDate';
        queryParams['sort-direction'] = sortDirection || queryParams['sort-direction'] || 'desc';
        queryParams.download = true;
        Request.get('user').query(queryParams).exec().then(res => {
            DownloadActions.resolveDownloadExecutionFor(res, `/groups/user-groups/download-users/${res.body.downloadExecutionId}`, true);
            PreloaderActions.hide('user-actions.get');

            return;
        }).catch(err => {
            PreloaderActions.hide('user-actions.get');
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'accounts.export-users.error');
            throw err;
        });

        return;
    }

    endActiveSessions(userId, options) {
        // End all active sessions for the user
        let notificationOptions = Object.assign(CONSTANTS.defaultOptions(), options);

        Dispatcher.dispatch({
            actionType: CONSTANTS.USER.ENDSESSIONS.START,
            userId: userId
        });

        Request.del(`user/${userId}/session`).exec()
            .then( () => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.USER.ENDSESSIONS.SUCCESS,
                    userId: userId
                });
                this.findById(userId);
                if (notificationOptions.messages.success) {
                    NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, notificationOptions.messages.success);
                }
            })
            .catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.USER.ENDSESSIONS.ERROR,
                    error: err
                });
                if (notificationOptions.messages.error) {
                    NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, notificationOptions.messages.error);
                }
                throw err;
            });

        return;
    }

    /**
     * Find all users that belong to a group.
     */
    findAllByGroupId(groupId, offset=0, size=20, sortFieldName='name', sortDirection='asc') {
        const preloaderSource = 'user-actions.findAllByGroupId';
        PreloaderActions.show(preloaderSource);
        Request.get(`security/group/${groupId}/user`).query({
            offset,
            size,
            'sort-field': sortFieldName,
            'sort-direction': sortDirection
        }).exec().then(res => {
            // this is super ugly
            // please remove it when pagination gets support from server
            let users = Immutable.fromJS(res.body.results);

            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.USERS.SUCCESS,
                offset,
                size,
                total: res.body.totalCount,
                users: users
            });

            return;
        }).then(()=>{
            PreloaderActions.hide(preloaderSource);
            return;
        }).catch(err => {
            PreloaderActions.hide(preloaderSource);
            throw err;
        });
    }

    /**
     * Find all users that belong to a role.
     */
    findAllByRoleId(roleId, offset=0, size=20, sortFieldName='name', sortDirection='asc') {
        const preloaderSource = 'user-actions.findAllByRoleId';
        PreloaderActions.show(preloaderSource);
        Request.get(`security/role/${roleId}/user`).query({
            offset,
            size,
            'sort-field': sortFieldName,
            'sort-order': sortDirection
        }).exec().then(res => {
            let users = Immutable.fromJS(res.body.results);

            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.USERS.SUCCESS,
                offset,
                size,
                total: res.body.totalCount,
                users: users
            });
            PreloaderActions.hide(preloaderSource);

            return;
        });
    }

    /**
     * Find all users who can access a title.
     */
    findAllByTitleId(titleId, filters, offset=0, size=20, sortFieldName='name', sortDirecton='asc') {
        const preloaderSource = 'user-actions.findAllByTitleId';
        PreloaderActions.show(preloaderSource);

        Request.get(`title/${titleId}/user`)
            .query({
                offset: offset,
                size: size,
                'sort-field': sortFieldName,
                'sort-order': sortDirecton
            })
            .query(filters.toJS())
            .exec().then(res => {
                let users = Immutable.fromJS(res.body.results);

                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET.USERS.SUCCESS,
                    offset: res.body.offset,
                    size: res.body.size,
                    total: res.body.totalCount,
                    users: users
                });

                return;
            })
            .then(()=>{
                PreloaderActions.hide(preloaderSource);
                return;
            })
            .catch(err => {
                PreloaderActions.hide(preloaderSource);

                throw err;
            });
    }

    /**
     * Find all Client Rep users.
     */
    findAllClientReps(offset = 0, size = 20, filters, sortFieldName, sortDirection) {
        const preloaderSource = 'user-actions.findAllClientReps';
        PreloaderActions.show(preloaderSource);

        // Get all client reps.
        Request.get('security/client-rep-group/rep').set('Accept', 'application/vnd.wbtv-v1+json;q=0.1').query({
            offset: 0,
            size: 9999
        }).exec().then(res => {
            let users = Immutable.fromJS(res.body.results);
            let total = res.body.totalCount;
            if (filters) {
                users = users.filter(u => u.get('email').match(new RegExp(filters, 'i')));
                total = users.size;
            }
            if (sortDirection === 'asc') {
                users = users.sort((u, v) => {
                    return u.get(sortFieldName).localeCompare(v.get(sortFieldName));
                });
            }
            if (sortDirection === 'desc') {
                users = users.sort((u, v) => {
                    return (v.get(sortFieldName).localeCompare(u.get(sortFieldName)));
                });
            }
            users = users.slice(offset, offset + size);
            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.USERS.SUCCESS,
                offset,
                size,
                total,
                users: users
            });

            return;
        }).catch(err => {
            throw err;
        }).finally(()=>{
            PreloaderActions.hide(preloaderSource);
            return;
        });
    }

    /**
     * Find an user by it's id.
     * @param {Object} id
     */
    findById(id) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.GET.USER.START,
            id: id
        });

        let actionHistoryQuery = {
            'action-object': ActionHistoryConstants.ACTION_OBJECTS.USER,
            'object-id': id,
            offset: 0,
            size: 4
        };
        const notesQuery = Object.assign({}, actionHistoryQuery, {type: 'USER_GENERATED'});
        actionHistoryQuery = Object.assign(actionHistoryQuery, {'exclude-type': 'USER_GENERATED'});


        const preloaderSource = 'user-actions.findById';
        PreloaderActions.show(preloaderSource);

        Promise.all([
            Request.get(`user/${id}`).exec(),
            Request.get('system/action-history').query(actionHistoryQuery).exec(),
            Request.get('system/action-history').query(notesQuery).exec(),
        ]).spread((user, history, notes) => {
            user = this.toClientUser(user.body, history.body, notes.body);
            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.USER.SUCCESS,
                user,
            });

            let contact = user.get('contact');

            this.passwordResetValidation(user.get('email'));

            let contactRequest = {};
            if (contact) {
                contactRequest = Request.get(`user/${contact}`).exec().then(res => res).catch(err => {
                    err.preventDefault = true;
                    NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'accounts.create.summary.contact.error');

                    throw err;
                });
            }
            // Important: trigger this after the user was stored!
            // If not, there's a chance of adding groups and roles to
            // the base empty user in the store, which will be replaced
            // when the real user comes from the server.
            return Promise.all([
                // Fetch user groups (includes stations).
                Request.get(`user/${id}/group`).exec(),
                // Fetch user partners.
                Request.get(`user/${id}/partner`).exec().then(res => res).catch(err => {
                    err.preventDefault = true;
                    NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'accounts.create.summary.partner.error');

                    throw err;
                }),
                // Fetch user roles.
                Request.get(`user/${id}/role`).exec(),
                // fetch validations
                Request.get(`user/validation/${id}`).exec(),
                // fetch contact
                contactRequest
            ]);
        }).spread((groups, partners, roles, validations, contact) => {
            const stations = groups.body.filter(g => g.groupCategoryType === GroupConstants.STATION.categoryId);
            const eventGroups = groups.body.filter(g => g.name.toLowerCase().startsWith('event'));
            const brainiacGroups = groups.body.filter(g => g.isAdmin);
            this.setStations(Immutable.fromJS(stations).toSet().sortBy(g => g.get('name')));
            this.setEventGroups(Immutable.fromJS(eventGroups).toSet().sortBy(g => g.get('name')));
            this.setBrainiacGroups(Immutable.fromJS(brainiacGroups).toSet().sortBy(g => g.get('name')));
            this.setGroups(Immutable.fromJS(groups.body).toSet().sortBy(g => g.get('name')));
            this.setRoles(Immutable.fromJS(roles.body).toSet().sortBy(r => r.get('name')));

            const validationsFiltered = validations.body.filter(validation => validation.validationType !== 'DuplicateName'); // not show duplicated message
            this.setValidations(Immutable.fromJS(validationsFiltered));

            this.setContact(contact.body);
            this.setPartners(partners.body);

            Dispatcher.dispatch({
                actionType: CONSTANTS.USER.CLONE
            });

            let duplicatedEmailsRequest = {body: []};
            if (validations.body.findIndex(validation => validation.validationType === 'DuplicateName') !== -1) {
                duplicatedEmailsRequest = Request.get(`user/validation/${id}/duplicate-email`).exec();
            }
            return duplicatedEmailsRequest;
        }).then(duplicatedEmails => {
            this.update('duplicatedEmails', Immutable.fromJS(duplicatedEmails.body));

            return;
        }).then(()=>{
            PreloaderActions.hide(preloaderSource);
            return;
        }).catch((err) => {
            PreloaderActions.hide(preloaderSource);
            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.USER.ERROR,
                error: err
            });
            if (err.preventDefault) {
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
                throw err;
            }
            switch (err.status) {
            case 404:
                RouterActions.notFound();
                break;
            default:
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
                break;
            }

            throw err;
        });

        return;
    }

    /**
     * Get users and filters.
     * @param {Object} queryParams
     * @param {Number} offset
     * @param {Number} size
     */
    get(queryParams, offset, size, sortFieldName, sortDirection) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.GET.USERS.START
        });
        queryParams = queryParams.toJS();
        if (queryParams.q) {
            queryParams.name = queryParams.q;
            queryParams.email = queryParams.q;
            queryParams.company = queryParams.q;
            delete queryParams.q;
        }

        ['start-apply-date', 'start-expire-date'].forEach( attr => {
            let d = queryParams[attr];
            if (d) {
                d = Moment(d);
                if (d.isValid()) {
                    d = d.format('YYYY-MM-DDT00:00:00.000'+configtz);
                } else {
                    d = '';
                }
                queryParams[attr] = d;
            }
        });

        ['end-apply-date', 'end-expire-date'].forEach( attr => {
            let d = queryParams[attr];
            if (d) {
                d = Moment(d);
                if (d.isValid()) {
                    d = d.format('YYYY-MM-DDT23:59:59.999'+configtz);
                } else {
                    d = '';
                }
                queryParams[attr] = d;
            }
        });

        PreloaderActions.show('user-actions.get');

        offset = offset || queryParams.offset || 0;
        size = size || 20;
        queryParams.offset = offset ;
        queryParams.size = size;
        queryParams['sort-field-name'] = sortFieldName || queryParams['sort-field-name'] || 'updatedDate';
        queryParams['sort-direction'] = sortDirection || queryParams['sort-direction'] || 'desc';

        Request.get('user')
            .query(queryParams)
            .exec()
            .then(res => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET.USERS.SUCCESS,
                    offset: offset,
                    size: size,
                    total: parseInt(res.header['wbtv-total-count'], 10),
                    users: Immutable.fromJS(res.body.results),
                    filters: Immutable.fromJS(res.body.results)
                });

                return;
            }).then(()=>{
                PreloaderActions.hide('user-actions.get');
            })
            .catch(err => {
                PreloaderActions.hide('user-actions.get');
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET.USERS.ERROR
                });
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
                throw err;
            });

        return;
    }

    getHomepages(id) {
        Request.get(`web/homepage/debug-user/${id}`).exec()
            .then(res => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.USER.UPDATE_HOMEPAGES,
                    homepages: Immutable.fromJS(res.body)
                });
            })
            .catch(err => {
                NotificationActions.showAlertDanger('accounts.edit.summary.homepages.get.error');
                throw err;
            });
    }

    getGuilds(userId) {
        Request.get(`user/${userId}/guild`).exec()
            .then(res => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.USER.GUILDS.SUCCESS,
                    guilds: Immutable.fromJS(res.body),
                });
            })
            .catch(err => {
                NotificationActions.showAlertDanger('accounts.create.summary.guilds.get.error');
                throw err;
            });
    }

    getRelatedAccounts(userId) {
        Request.get(`user/${userId}/related-accounts`).query({size: 999}).exec()
            .then(res => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.USER.GET_RELATED_ACCOUNTS.SUCCESS,
                    relatedAccounts: Immutable.fromJS(res.body.results),
                });
            })
            .catch(err => {
                NotificationActions.showAlertDanger('accounts.create.summary.related-accounts.get.error');
                throw err;
            });
    }

    getRestrictedIPVideoWatches(userId) {
        // Get the list of watched videos which have ip restrictions for userId
        Dispatcher.dispatch({
            actionType: CONSTANTS.USER.RESTRICTED_IP_VIDEOS.START,
            userId: userId
        });

        let restrictedIPVideos = [];
        Request.get(`user/${userId}/restricted-video-ip`).exec()
            .then(res => {
                restrictedIPVideos = res.body;
                let requests = res.body.map((v) => Request.get(`asset/video/${v.videoId}`).exec());
                return Promise.all(requests);
            }).then(responses => {
                let restrictedIPVideosWatchData = responses.map((r) => {
                    const videoAsset = r.body;
                    const restrictedVideo = restrictedIPVideos.find(c => c.videoId === videoAsset.id);

                    return Object.assign({}, restrictedVideo, {
                        assetName: videoAsset.assetName,
                    });
                });

                Dispatcher.dispatch({
                    actionType: CONSTANTS.USER.RESTRICTED_IP_VIDEOS.SUCCESS,
                    videos: Immutable.fromJS(restrictedIPVideosWatchData),
                });
            })
            .catch( err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.USER.RESTRICTED_IP_VIDEOS.ERROR,
                    error: err
                });
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'accounts.create.management.video-playback-restrictions.error');
                throw err;
            });
    }

    getRestrictedVideoWatches(userId) {
        // Get the list of watched videos which have playback restrictions for userId
        Dispatcher.dispatch({
            actionType: CONSTANTS.USER.RESTRICTED_VIDEOS.START,
            userId: userId
        });

        let restrictedVideos = [];
        Request.get(`user/${userId}/restricted-video-watch`).exec()
            .then(res => {
                restrictedVideos = res.body;
                let requests = res.body.map((v) => Request.get(`asset/video/${v.videoId}`).exec());
                return Promise.all(requests);
            }).then(responses => {
                let restrictedVideosWatchData = responses.map((r) => {
                    const videoAsset = r.body;
                    const restrictedVideo = restrictedVideos.find(c => c.videoId === videoAsset.id);

                    let statusId = CONSTANTS.RESTRICTED_VIDEO_STATUS.UNLOCKED.id;
                    if (videoAsset.maxNumberOfView && restrictedVideo.viewCount >= videoAsset.maxNumberOfView) {
                        statusId = CONSTANTS.RESTRICTED_VIDEO_STATUS.LOCKED.id;
                    }

                    return Object.assign({}, restrictedVideo, {
                        assetName: videoAsset.assetName,
                        maxNumberOfView: videoAsset.maxNumberOfView,
                        statusId: statusId,
                    });
                });

                Dispatcher.dispatch({
                    actionType: CONSTANTS.USER.RESTRICTED_VIDEOS.SUCCESS,
                    videos: Immutable.fromJS(restrictedVideosWatchData),
                });
            })
            .catch( err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.USER.RESTRICTED_VIDEOS.ERROR,
                    error: err
                });
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'accounts.create.management.video-playback-restrictions.error');
                throw err;
            });
    }

    getSessionCount(userId) {
        // Get the session count for userId
        Dispatcher.dispatch({
            actionType: CONSTANTS.USER.SESSIONCOUNT.START,
            userId: userId
        });

        Request.get(`user/${userId}/session-count`).exec()
            .then(res => {
                let sessionCount = res.body.reduce( (total, item) => {
                    return total + item.count;
                }, 0);

                Dispatcher.dispatch({
                    actionType: CONSTANTS.USER.SESSIONCOUNT.SUCCESS,
                    sessions: Immutable.fromJS(res.body),
                    sessionCount: sessionCount,
                    userId: userId
                });
            })
            .catch( err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.USER.SESSIONCOUNT.ERROR,
                    error: err
                });
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'accounts.create.management.session-count.error');
                throw err;
            });
    }

    getSuggestedUsers(value) {
        Request.get('user')
            .query({
                name: value,
                email: value,
                company: value
            })
            .exec()
            .then(res => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.SUGGESTED_USERS.GET.SUCCESS,
                    users: Immutable.fromJS(res.body.results),
                });
                return;
            })
            .catch(err => {
                NotificationActions.showAlertDanger('accounts.edit.copy-permissions.suggested.error');
                throw err;
            });

        return;
    }

    ignoreAttributesInAPI(data) {
        [
            'clientRepGroups', 'clientRep', 'groups', 'roles', 'stations', 'validations', 'companyIsNew', 'fullName', 'passwordResetAllowed',
            'userActive', 'applicantStatus', 'defaultUserPartners', 'partners', 'defaultPartner'
        ].forEach(p => delete data[p]);
    }

    /**
     * @param {Immutable.Map} user from whom we want to copy groups, roles and partners
     * @param {Immutable.Map} targetUser user to whom we want to paste the obtained groups, roles and partners
     */
    overwritePermissions(user, targetUser) {
        targetUser = targetUser.toJS();
        let originalTargetUser;
        const preloaderSource = 'user-actions.saveUser';
        PreloaderActions.show(preloaderSource);

        return Promise.all([
            // Fetch user groups.
            Request.get(`user/${targetUser.id}/group`).exec().then(res => res).catch(err => {
                NotificationActions.showAlertDanger('accounts.edit.copy-permissions.groups.error');
                throw err;
            }),
            // Fetch user roles.
            Request.get(`user/${targetUser.id}/role`).exec().then(res => res).catch(err => {
                NotificationActions.showAlertDanger('accounts.edit.copy-permissions.roles.error');
                throw err;
            }),
            // Fetch user partners.
            Request.get(`user/${targetUser.id}/partner`).exec().then(res => res).catch(err => {
                NotificationActions.showAlertDanger('accounts.edit.copy-permissions.partners.error');
                throw err;
            }),
        ]).spread((groups, roles, partners) => {
            originalTargetUser = {
                ...targetUser,
                groups: groups.body,
                roles: roles.body,
                stations: groups.body.filter(g => g.groupCategoryType === GroupConstants.STATION.categoryId),
                partners: partners.body,
                defaultPartner: partners.body.find(partner => partner.defaultPartner)
            };
            originalTargetUser = Immutable.fromJS(originalTargetUser);

            targetUser = {
                ...targetUser,
                groups: user.get('groups'),
                roles: user.get('roles'),
                stations: user.get('stations'),
                partners: user.get('partners'),
                defaultPartner: user.get('defaultPartner')
            };

            targetUser = Immutable.fromJS(targetUser);

            // Save groups, roles and partners.
            return this.saveUserRelations([{
                attr: 'groups',
                urlName: 'group'
            }, {
                attr: 'stations',
                urlName: 'group'
            }, {
                attr: 'roles',
                urlName: 'role'
            }, {
                attr: 'partners',
                urlName: 'partner'
            }], targetUser.get('id'), targetUser, originalTargetUser);
        }).then(() => {
            PreloaderActions.hide(preloaderSource);
            NotificationActions.showAlertSuccess(
                'accounts.edit.copy-permissions.confirm.success',
                targetUser.get('email')
            );
        }).catch(err => {
            PreloaderActions.hide(preloaderSource);
            NotificationActions.showAlertDanger(
                'accounts.edit.copy-permissions.confirm.error',
                targetUser.get('email')
            );
            throw err;
        });
    }

    passwordResetValidation(email) {
        Request.get(`user/validation/password-reset-allowed/${email}`).exec()
            .then(() => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.USER.SET.PASSWORDRESET_ALLOWED,
                    passwordResetAllowed: true
                });
            })
            .catch((err) => {
                if (err.status === 403) {
                    Dispatcher.dispatch({
                        actionType: CONSTANTS.USER.SET.PASSWORDRESET_ALLOWED,
                        passwordResetAllowed: false
                    });
                }
            });
    }

    reactivate(userId) {
        let defaults = {
            messages: {
                error: 'accounts.create.management.reactivate.error',
                success: 'accounts.create.management.reactivate.success'
            }
        };
        Dispatcher.dispatch({
            actionType: CONSTANTS.USER.REACTIVATE.START
        });

        Request.put(`user/${userId}/activate`).exec()
            .then(() => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.USER.REACTIVATE.SUCCESS
                });
                this.findById(userId);
                NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, defaults.messages.success);
            })
            .catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.USER.REACTIVATE.ERROR,
                    error: err
                });
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, defaults.messages.error);
                throw err;
            });
        return;
    }

    reportError(userError, uriMethod, uri, uriBody, userErrorType, showAlert = false) {
        const currentUserId = SessionStore.getState().getIn(['authUser', 'id']);
        Request.post('user/error').send({
            userSessionId: localStorage.__token,
            userId: currentUserId,
            uriMethod,
            uri,
            uriBody,
            userErrorType,
            userError
        }).exec().then(() => {
            if (showAlert) {
                NotificationActions.showAlertInfo('user.error-reporting.success');
            }
        }).catch((err) => {
            NotificationActions.showAlertDanger('user.error-reporting.error');
            throw err;
        });
    }

    resetPassword(userId, options) {
        // passwordResetLink will be returned in API response
        let notificationOptions = Object.assign(CONSTANTS.defaultOptions(), options);

        Dispatcher.dispatch({
            actionType: CONSTANTS.USER.PASSWORDRESET.START,
            userId: userId,
            sendEmail: false
        });

        Request.post(`user/${userId}/request-password-reset`).send({resetAccountPassword: false}).exec()
            .then(res => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.USER.PASSWORDRESET.SUCCESS,
                    sendEmail: false,
                    userId: userId,
                    passwordResetLink: res.body.passwordResetLink,
                });
                //this.findById(userId);
                if (notificationOptions.messages.success) {
                    NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, notificationOptions.messages.success);
                }
            })
            .catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.USER.PASSWORDRESET.ERROR,
                    error: err
                });

                if (notificationOptions.messages.error) {
                    NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, notificationOptions.messages.error);
                }
                throw err;
            });

        return;
    }

    resetPasswordAsAdmin(userId, newPassword, options) {
        const notificationOptions = Object.assign(CONSTANTS.defaultOptions(), options);

        Dispatcher.dispatch({
            actionType: CONSTANTS.USER.SET_PASSWORD_AS_ADMIN.START
        });

        Request.post(`user/${userId}/set-password`).send({newPassword}).exec()
            .then(() => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.USER.SET_PASSWORD_AS_ADMIN.SUCCESS
                });
                if (notificationOptions.messages.success) {
                    NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, notificationOptions.messages.success);
                }
            })
            .catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.USER.SET_PASSWORD_AS_ADMIN.ERROR,
                    error: err
                });
                if (notificationOptions.messages.error) {
                    NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, notificationOptions.messages.error);
                }
                throw err;
            });

        return;
    }

    resetPasswordWithEmail(userId, userEmail, options) {
        let notificationOptions = Object.assign(CONSTANTS.defaultOptions(), options);

        Dispatcher.dispatch({
            actionType: CONSTANTS.USER.PASSWORDRESET.START,
            userId: userId,
            sendEmail: true
        });

        Request.post('user/request-password-reset').send({emailAddress: userEmail}).exec()
            .then(() => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.USER.PASSWORDRESET.SUCCESS,
                    sendEmail: true,
                    userId: userId
                });
                if (notificationOptions.messages.success) {
                    NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, notificationOptions.messages.success);
                }
            })
            .catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.USER.PASSWORDRESET.ERROR,
                    error: err
                });
                if (notificationOptions.messages.error) {
                    NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, notificationOptions.messages.error);
                }
                throw err;
            });

        return;
    }

    resetRestrictedVideoIPCount(userId, videoAssetId, options) {
        let notificationOptions = Object.assign(CONSTANTS.defaultOptions(), options);

        Dispatcher.dispatch({
            actionType: CONSTANTS.USER.RESET_RESTRICTED_VIDEO_IP_COUNT.START,
            userId: userId,
            videoAssetId: videoAssetId
        });

        Request.del(`user/${userId}/restricted-video-ip/${videoAssetId}`).exec()
            .then(() => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.USER.RESET_RESTRICTED_VIDEO_IP_COUNT.SUCCESS,
                    userId: userId,
                    videoAssetId: videoAssetId
                });
                if (notificationOptions.messages.success) {
                    NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, notificationOptions.messages.success);
                }
            })
            .then(() => {
                this.getRestrictedVideoWatches(userId);
            })
            .catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.USER.RESET_RESTRICTED_VIDEO_IP_COUNT.ERROR,
                    error: err
                });
                if (notificationOptions.messages.error) {
                    NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, notificationOptions.messages.error);
                }
                throw err;
            });

        return;
    }

    resetRestrictedVideoViews(userId, videoAssetId, options) {
        let notificationOptions = Object.assign(CONSTANTS.defaultOptions(), options);

        Dispatcher.dispatch({
            actionType: CONSTANTS.USER.RESET_RESTRICTED_VIDEO_VIEWS.START,
            userId: userId,
            videoAssetId: videoAssetId
        });

        Request.del(`user/${userId}/restricted-video-watch/${videoAssetId}`).exec()
            .then(() => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.USER.RESET_RESTRICTED_VIDEO_VIEWS.SUCCESS,
                    userId: userId,
                    videoAssetId: videoAssetId
                });
                if (notificationOptions.messages.success) {
                    NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, notificationOptions.messages.success);
                }
            })
            .then(() => {
                this.getRestrictedVideoWatches(userId);
            })
            .catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.USER.RESET_RESTRICTED_VIDEO_VIEWS.ERROR,
                    error: err
                });
                if (notificationOptions.messages.error) {
                    NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, notificationOptions.messages.error);
                }
                throw err;
            });

        return;
    }

    savePartners(id, user, originalUser) {
        let op = [];
        if (!CompareImmutableList(user.get('partners'), originalUser.get('partners')) ||
            !CompareImmutable(user.get('defaultPartner'), originalUser.get('defaultPartner'))) {
            let partnersData = [];
            //add default partner
            if (user.get('partners').size) {
                partnersData.push({
                    clientId: user.getIn(['defaultPartner', 'clientId']),
                    defaultPartner: true,
                    partnerId: user.getIn(['defaultPartner', 'id'])
                });
            }
            //add partners
            user.get('partners').map(p => {
                if (p.get('id') !== user.getIn(['defaultPartner', 'id'])) {
                    partnersData.push({
                        clientId: p.get('clientId'),
                        defaultPartner: false,
                        partnerId: p.get('id')
                    });
                }
            });
            op = Request.put(`user/${id}/partner`).send(partnersData).exec();
        }
        return op;
    }

    saveUser(user, originalUser, options, profile, originalProfile, notifications, originalNotifications, subscriptions, originalSubscriptions) {
        let defaults = {
            messages: {
                error: 'accounts.edit.account.error',
                success: 'accounts.edit.account.success'
            }
        };

        Object.assign(defaults, options);

        // Set the data to send to the server.
        const userData = ['history', 'guilds'].reduce((r, p) => {
            return r.delete(p);
        }, user).toJS();

        // And clean it a bit.
        this.ignoreAttributesInAPI(userData);
        userData.country = user.get('country').get('name');
        let activationEmail = 0;
        if (userData.sendUserActivationEmail) {
            activationEmail = 1;
        }
        userData.sendUserActivationEmail = activationEmail;

        // Assume POST.
        let isCreating = true;
        let method = Request.post;
        let uri = 'user';

        // Check if PUT.
        let id = userData.id;
        if (id !== undefined) {
            isCreating = false;
            method = Request.put;
            uri = `user/${id}`;
        }

        const isTempUnlock = originalUser.get('accountStatus') === CONSTANTS.STATUS.TEMP_LOCKED.id && user.get('accountStatus') === CONSTANTS.STATUS.ACTIVE.id;

        /**
         * First save the user.
         */
        const preloaderSource = 'user-actions.saveUser';
        PreloaderActions.show(preloaderSource);
        method(uri)
            .send(userData)
            .exec()
            /**
             * Then save the clientRep, roles and groups.
             */
            .then(res => {
                id = res.body.id;
                // Update the user model with response values (see STUDIO-9351 for updatedDate details)
                ['accountStatus', 'accountType', 'applicantStatus', 'updatedDate'].forEach(f => this.updateUser(f, res.body[f]));

                // Process relations
                return this.saveUserRelations([{
                    attr: 'clientRepGroups',
                    urlName: 'client-rep'
                }, {
                    attr: 'groups',
                    urlName: 'group'
                }, {
                    attr: 'brainiacGroups',
                    urlName: 'group'
                }, {
                    attr: 'stations',
                    urlName: 'group'
                }, {
                    attr: 'partners',
                    urlName: 'partner'
                }, {
                    attr: 'roles',
                    urlName: 'role'
                }], id, user, originalUser);
            }).then(() => {
                // // Save missing requests promises here.
                let requests = [];

                // Conditionally add DeliveryProfile save requests
                if (profile && originalProfile) {
                    requests.push(DeliveryProfileSave(id, profile, originalProfile));
                }

                // Conditionally add DeliveryNotificaiton save requests
                if (notifications && originalNotifications) {
                    requests.push(DeliveryNotificationSave(id, notifications, originalNotifications));
                }

                // Conditionally add Subscrtiptions save requests
                if (subscriptions && originalSubscriptions) {
                    requests.push(SubscriptionsSave(id, subscriptions, originalSubscriptions));
                }

                if (isTempUnlock) {
                    NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'accounts.edit.unlock.success');
                }

                return Promise.all(requests);
            })
            .then(() => {
                return Request.get(`user/validation/${id}`).exec();
            })
            .then((validations) => {
                let duplicatedEmailsRequest = {body: []};
                if (validations.body.findIndex(validation => validation.validationType === 'DuplicateName') !== -1) {
                    duplicatedEmailsRequest = Request.get(`user/validation/${id}/duplicate-email`).exec();
                }
                return Promise.all([validations, duplicatedEmailsRequest]);
            })
            .spread((validations, duplicatedEmails) => {
                if (isCreating) {
                    RouterActions.redirect(`/accounts/applicants/${id}`);
                } else {
                    // clear companyIsNew warning if existed. as saving created the company.
                    this.updateUser('companyIsNew', false);
                    this.setValidations(Immutable.fromJS(validations.body));
                }

                this.update('duplicatedEmails', Immutable.fromJS(duplicatedEmails.body));

                /**
                 * Then clone the user because we now are in edit mode.
                 */
                Dispatcher.dispatch({
                    actionType: CONSTANTS.USER.CLONE
                });

                NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, defaults.messages.success);

                return;
            }).then(()=>{
                PreloaderActions.hide(preloaderSource);
                return;
            })
            /**
             * Deal with errors.
             */
            .catch(err => {
                /**
                * If we created a new user but we weren't able to save a client rep group,
                * user group or role relationship, then we must redirect to the edit view
                * and show the error alert.
                */

                PreloaderActions.hide(preloaderSource);
                if (err.status === 409) {
                    NotificationActions.showAlertDanger('accounts.edit.save-duplicate');
                    return;
                }

                if (!(err.response.req.method === 'POST' &&
                    err.response.req.url === resolveURL(uri) &&
                    isCreating)) { // URI is https://host/user
                    RouterActions.redirect(`/accounts/applicants/${id}`);
                }
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, defaults.messages.error);
                return;
            });

        return;
    }

    /**
     * Save user relations requests
     * @param {*} relations we want to proccess (Groups, roles, partners or clientRepGroups)
     * @param {*} user
     * @param {*} originalUser
     */
    saveUserRelations(relations, id, user, originalUser) {
        // Save all requests promises here.
        let requests = [];

        // Process relations.
        relations.forEach(relation => {
            //Partners
            if (relation.attr === 'partners') {
                requests.push(this.savePartners(id, user, originalUser));
                return;
            }

            // If the array hasn't changed return immediatly.
            if (user.get(relation.attr).equals(originalUser.get(relation.attr))) {
                return;
            }

            let toAdd = user.get(relation.attr).filter(item =>
                !originalUser.get(relation.attr).find(i => item.get('id') === i.get('id'))
            );
            let toDelete = originalUser.get(relation.attr).filter(item =>
                !user.get(relation.attr).find(i => item.get('id') === i.get('id'))
            );

            if (relation.attr === 'stations') {
                toAdd = toAdd.filter(i => i.get('groupCategoryType') === GroupConstants.STATION.categoryId);
                toDelete = toDelete.filter(i => i.get('groupCategoryType') === GroupConstants.STATION.categoryId);
            }

            if (relation.attr === 'groups' || relation.attr === 'brainiacGroups') {
                toAdd = toAdd.filter(i => i.get('groupCategoryType') === GroupConstants.USER.categoryId);
                toDelete = toDelete.filter(i => i.get('groupCategoryType') === GroupConstants.USER.categoryId);
            }

            const filteredPostRequest = toAdd.map(item => Request.post(`user/${id}/${relation.urlName}/${item.get('id')}`).exec().then(req => req)
                .catch(err => {
                    NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, `accounts.relations.save.${relation.urlName}.error`);
                    throw err;
                })).toJS();

            const filteredDelRequest = toDelete.map(item => Request.del(`user/${id}/${relation.urlName}/${item.get('id')}`).exec().then(req => req)
                .catch(err => {
                    NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, `accounts.relations.save.${relation.urlName}.error`);
                    throw err;
                })).toJS();

            // Elements to add.
            requests.push(...filteredPostRequest);
            // Elements to remove.
            requests.push(...filteredDelRequest);
        });

        return Promise.all(requests);
    }

    sendEnrollmentEmail(userId) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SEND_MFA_ENROLLMENT_EMAIL.START
        });

        Request.get(`user/${userId}/mfa-activation`).exec().then(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.SEND_MFA_ENROLLMENT_EMAIL.SUCCESS
            });

            this.findById(userId);

            NotificationActions.showAlertSuccess('mfa.enrollment.sent');
            return;
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.SEND_MFA_ENROLLMENT_EMAIL.ERROR
            });

            NotificationActions.showAlertDanger('mfa.enrollment.error');
            throw err;
        });
    }

    setContact(contact) {
        let newContact = {};
        if (contact) {
            const {name: contactName = '', middleName = '', lastName = ''} = contact;
            const fullName = [contactName, middleName, lastName].filter(item => item !== '' && item !== null).join(' ');
            newContact = {id: contact.id, name: fullName};
        }
        this.updateContact(newContact);
    }

    setFilter(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.FILTER.SET,
            attr: attr,
            value: value
        });
    }

    setEventGroups(groups) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.USER.SET.EVENT_GROUPS,
            selected: groups
        });

        return;
    }

    setBrainiacGroups(groups) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.USER.SET.BRAINIAC_GROUPS,
            selected: groups
        });

        return;
    }

    setGroups(groups) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.USER.SET.GROUPS,
            selected: groups
        });

        return;
    }

    setPartners(userPartners) {
        let defaultPartner;
        let partners = userPartners.map(p => {
            let partner = {
                clientId: p.clientId,
                defaultPartner: p.defaultPartner,
                id: p.partnerId
            };
            if (p.defaultPartner) {
                defaultPartner = partner;
            }
            return partner;
        });

        this.updateUser('defaultPartner', Immutable.fromJS(defaultPartner));
        this.updatePartners(Immutable.fromJS(partners).toSet());

        return;
    }

    setRoles(roles) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.USER.SET.ROLES,
            selected: roles
        });

        return;
    }

    setStations(stations) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.USER.SET.STATIONS,
            selected: stations
        });

        return;
    }

    setSort(sortFieldName, sortDirection) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SORT.SET,
            sortFieldName: sortFieldName,
            sortDirection: sortDirection
        });
    }

    setValidations(validations) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.USER.SET.VALIDATIONS,
            validations: validations
        });
        return;
    }

    syncElasticSearch(userId) {
        Request.get(`user/${userId}/refresh-es`).exec().then(() => {
            NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'elastic-search.sync.success');
            return;
        }).catch(err => {
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'elastic-search.sync.error');
            throw err;
        });
    }

    /**
     * Transform the user into a more suitable object for our react app.
     */
    toClientUser(user, history, notes) {
        user.country = {
            id: -1,
            name: user.country
        };

        user.history = history.results;
        user.history.sort((h1, h2) => h2.actionDate.localeCompare(h1.actionDate));

        user.notes = notes.results;
        user.notes.sort((n1, n2) => n2.actionDate.localeCompare(n1.actionDate));

        user.sendUserActivationEmail = user.sendUserActivationEmail === 1;

        user = Immutable.fromJS(user);

        user = user.set('groups', Immutable.OrderedSet());
        user = user.set('roles', Immutable.OrderedSet());
        user = user.set('stations', Immutable.OrderedSet());

        return user;
    }

    toggleSelect(index, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.USERS.TOGGLE_SELECT,
            index: index,
            value: value
        });

        return;
    }

    toggleSelectAll(value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.USERS.TOGGLE_SELECT_ALL,
            value: value
        });

        return;
    }

    unlockUser(userId, options) {
        // Unlocks a user account
        let notificationOptions = Object.assign(CONSTANTS.defaultOptions(), options);

        Dispatcher.dispatch({
            actionType: CONSTANTS.USER.UNLOCK.START,
            userId: userId
        });

        Request.del(`user/${userId}/unlock`).exec()
            .then( () => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.USER.UNLOCK.SUCCESS,
                    userId: userId
                });
                if (notificationOptions.messages.success) {
                    NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, notificationOptions.messages.success);
                }
                return Request.get(`user/validation/${userId}`).exec();
            })
            .then(validations => {
                this.setValidations(Immutable.fromJS(validations.body));
            })
            .catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.USER.UNLOCK.ERROR,
                    error: err
                });
                if (notificationOptions.messages.error) {
                    NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, notificationOptions.messages.error);
                }
                throw err;
            });

        return;
    }

    update(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.UPDATE,
            attr: attr,
            value: value
        });
        return;
    }

    updateContact(contact) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.USER.UPDATE_CONTACT,
            value: contact
        });
        return;

    }

    updateUser(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.USER.UPDATE,
            attr: attr,
            value: value
        });
        return;
    }

    updateUserValidations(userRow) {
        if (!userRow) {
            return;
        }

        const preloaderId = 'user-actions.validations';
        Dispatcher.dispatch({
            actionType: CONSTANTS.USER.VALIDATIONS.START,
        });

        PreloaderActions.show(preloaderId);

        Request.post('user/validation/user-detail').send(userRow.toJS()).exec().then((res) => {
            PreloaderActions.hide(preloaderId);
            const results = res.body;

            Dispatcher.dispatch({
                actionType: CONSTANTS.USER.VALIDATIONS.SUCCESS,
                userValidations: Immutable.fromJS(results).get('userIngestValidations')
            });
        }).catch((err) => {
            console.error(err);

            PreloaderActions.hide(preloaderId);
            Dispatcher.dispatch({
                actionType: CONSTANTS.USER.VALIDATIONS.ERROR,
            });

            NotificationActions.showAlertDanger('accounts.create.validations.error');

            throw err;
        });
    }

    updatePartners(partners) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.USER.UPDATE_PARTNERS,
            selected: partners
        });

        return;
    }
}

const actions = new UserActions();

export {
    actions as UserActions,
    CONSTANTS as UserConstants
};
