/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, ControlLabel, FormGroup} from 'react-bootstrap';

import AppStylePreviewModal from './app-style-preview-modal';

import {FormItem, FormRow, FormSection} from '~/src/common/form/form';
import {CreateFile} from '~/src/common/utils/utils';
import Validations, {WithValidations} from '~/src/common/validations/validations';

import './app-style.less';

class AppStyle extends Component {
    static get propTypes() {
        return {
            actions: PropTypes.func.isRequired,
            disabled: PropTypes.bool,
            title: PropTypes.instanceOf(Immutable.Map).isRequired,
            titleStyle: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            selectedAttr: null,
            showAppStylePreviewModal: false
        };

        this.handleAppStylePreviewModalHide = this.handleAppStylePreviewModalHide.bind(this);
        this.handleAppStylePreviewModalRemove = this.handleAppStylePreviewModalRemove.bind(this);
        this.handleEditAssetClick = this.handleEditAssetClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

        return;
    }

    handleAppStylePreviewModalHide() {
        this.setState(() => ({
            selectedAttr: null,
            showAppStylePreviewModal: false
        }));

        return;
    }

    handleAppStylePreviewModalRemove() {
        // Remove the abcUrl from the title object so that the file
        // is removed from the screen.
        this.props.actions.updateTitle(`${this.state.selectedAttr}Url`, undefined);
        this.props.actions.updateTitleStyle(`remove.${this.state.selectedAttr}`, true);
        this.handleAppStylePreviewModalHide();
        return;
    }

    handleEditAssetClick(attr) {
        this.setState(() => ({
            selectedAttr: attr,
            showAppStylePreviewModal: true
        }));

        return;
    }

    handleInputChange(attr, value) {
        this.props.actions.updateTitle(attr, value);
    }

    handleFileInputChange(attr) {
        if (
            this.refs[attr].files &&
            this.refs[attr].files[0]
        ) {
            let file = this.refs[attr].files[0];
            //for custom background image, background video and title treatment
            file = CreateFile(file);
            this.props.actions.updateTitleStyle(attr, file);
        }
    }

    handleOpenFileInput(attr) {
        this.refs[attr].click();
        return;
    }

    render() {
        let inputStyle = {
            display: 'none'
        };

        let chooseAppBackgroundImage = (
            <div>
                <Button
                    bsStyle="default"
                    onClick={this.handleOpenFileInput.bind(this, 'appBackgroundImage')}
                >
                    <i className="far fa-image"></i>
                    &nbsp;
                    {this.context.intl.messages['titles.create.style.background-image-choose']}
                </Button>
                <em>{this.props.titleStyle.get('appBackgroundImage').name}</em>
                <input
                    accept="image/*"
                    onChange={this.handleFileInputChange.bind(this, 'appBackgroundImage')}
                    ref="appBackgroundImage"
                    style={inputStyle}
                    type="file"
                />
            </div>
        );
        if (this.props.title.get('appBackgroundImageUrl')) {
            chooseAppBackgroundImage = (
                <div className="thumbnail-image-container">
                    <img
                        className="img-responsive"
                        src={this.props.title.get('appBackgroundImageUrl')}
                    />
                    <div className="button-container">
                        <div className="button-container-2">
                            <Button
                                bsStyle="default"
                                onClick={this.handleEditAssetClick.bind(this, 'appBackgroundImage')}
                            >
                                <i className="fas fa-edit"></i>
                                &nbsp;
                                {this.context.intl.messages['titles.create.style.background-image-update']}
                            </Button>
                        </div>
                    </div>
                </div>
            );
        }

        let chooseAppBackgroundVideo = (
            <div>
                <Button
                    bsStyle="default"
                    onClick={this.handleOpenFileInput.bind(this, 'appBackgroundVideo')}
                >
                    <i className="fas fa-video"></i>
                    &nbsp;
                    {this.context.intl.messages['titles.create.style.background-video-choose']}
                </Button>
                <em>{this.props.titleStyle.get('appBackgroundVideo').name}</em>
                <input
                    accept="video/*"
                    onChange={this.handleFileInputChange.bind(this, 'appBackgroundVideo')}
                    type="file"
                    ref="appBackgroundVideo"
                    style={inputStyle}
                />
            </div>
        );
        if (this.props.title.get('appBackgroundVideoUrl')) {
            chooseAppBackgroundVideo = (
                <div className="thumbnail-image-container">
                    <div className="embed-responsive embed-responsive-16by9">
                        <video
                            autoPlay
                            loop
                            playsInline
                            muted
                        >
                            <source
                                src={this.props.title.get('appBackgroundVideoUrl')}
                                type="video/mp4"
                            />
                        </video>
                    </div>
                    <div className="button-container">
                        <div className="button-container-2">
                            <Button
                                bsStyle="default"
                                onClick={this.handleEditAssetClick.bind(this, 'appBackgroundVideo')}
                            >
                                <i className="fas fa-edit"></i>
                                &nbsp;
                                {this.context.intl.messages['titles.create.style.background-video-update']}
                            </Button>
                        </div>
                    </div>
                </div>
            );
        }

        let chooseAppBackgroundTitleTreatment = (
            <div>
                <Button
                    bsStyle="default"
                    style={{whiteSpace: 'break-spaces'}}
                    onClick={this.handleOpenFileInput.bind(this, 'appBackgroundTitleTreatment')}
                >
                    <i className="far fa-image"></i>
                    &nbsp;
                    {this.context.intl.messages['titles.create.style.title-treatment-choose']}
                </Button>
                <em>{this.props.titleStyle.get('appBackgroundTitleTreatment').name}</em>
                <input
                    accept="image/*"
                    onChange={this.handleFileInputChange.bind(this, 'appBackgroundTitleTreatment')}
                    type="file"
                    ref="appBackgroundTitleTreatment"
                    style={inputStyle}
                />
            </div>
        );
        if (this.props.title.get('appBackgroundTitleTreatmentUrl')) {
            chooseAppBackgroundTitleTreatment = (
                <div className="thumbnail-image-container">
                    <img
                        className="img-responsive"
                        src={this.props.title.get('appBackgroundTitleTreatmentUrl')}
                    />
                    <div className="button-container">
                        <div className="button-container-2">
                            <Button
                                bsStyle="default"
                                onClick={this.handleEditAssetClick.bind(this, 'appBackgroundTitleTreatment')}
                            >
                                <i className="fas fa-edit"></i>
                                &nbsp;
                                {this.context.intl.messages['titles.create.style.title-treatment-update']}
                            </Button>
                        </div>
                    </div>
                </div>
            );
        }

        let appStylePreviewModalContent;
        if (this.state.selectedAttr) {
            switch (this.state.selectedAttr) {
            case 'appBackgroundImage':
            case 'appBackgroundTitleTreatment':
                appStylePreviewModalContent = (
                    <img
                        className="img-responsive"
                        src={this.props.title.get(`${this.state.selectedAttr}Url`)}
                    />
                );
                break;

            case 'appBackgroundVideo':
                appStylePreviewModalContent = (
                    <video
                        autoPlay
                        controls
                        loop
                    >
                        <source
                            src={this.props.title.get('appBackgroundVideoUrl')}
                            type="video/mp4"
                        />
                    </video>
                );
                break;
            }
        }

        return (
            <div className="title-app-style">
                <FormSection>
                    <FormRow>
                        <FormGroup>
                            <FormItem
                                addonBefore="#"
                                attr="appBackgroundColor"
                                disabled={this.props.disabled}
                                label={this.context.intl.messages['titles.create.style.background-color']}
                                legend={<p><em>{this.context.intl.messages['titles.create.style.background-color-paragraph']}</em></p>}
                                md={12}
                                model={this.props.title}
                                placeholder={this.context.intl.messages['titles.create.style.background-color-placeholder']}
                                setter={this.handleInputChange}
                                type="text"
                                validations={[Validations.hexadecimal]}
                            />
                            <FormItem
                                addonBefore="#"
                                attr="appTextColor"
                                disabled={this.props.disabled}
                                label={this.context.intl.messages['titles.create.style.text-color']}
                                legend={<p><em>{this.context.intl.messages['titles.create.style.text-color-paragraph']}</em></p>}
                                md={12}
                                model={this.props.title}
                                placeholder={this.context.intl.messages['titles.create.style.text-color-placeholder']}
                                setter={this.handleInputChange}
                                type="text"
                                validations={[Validations.hexadecimal]}
                            />
                        </FormGroup>

                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['titles.create.style.background-image']}</ControlLabel>
                            <p><em>{this.context.intl.messages['titles.create.style.background-image-paragraph']}</em></p>
                            {chooseAppBackgroundImage}
                        </FormGroup>

                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['titles.create.style.background-video']}</ControlLabel>
                            <p><em>{this.context.intl.messages['titles.create.style.background-video-paragraph']}</em></p>
                            {chooseAppBackgroundVideo}
                        </FormGroup>

                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['titles.create.style.title-treatment']}</ControlLabel>
                            <p><em>{this.context.intl.messages['titles.create.style.title-treatment-paragraph']}</em></p>
                            {chooseAppBackgroundTitleTreatment}
                        </FormGroup>
                    </FormRow>
                </FormSection>

                <AppStylePreviewModal
                    onHide={this.handleAppStylePreviewModalHide}
                    onRemove={this.handleAppStylePreviewModalRemove}
                    show={this.state.showAppStylePreviewModal}
                    bgColor={this.props.title.get('appBackgroundColor')}
                >
                    {appStylePreviewModalContent}
                </AppStylePreviewModal>
            </div>
        );
    }

}

AppStyle.displayName = 'App';

export default WithValidations(AppStyle);
