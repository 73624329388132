/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {IsLocationDirty} from '@wbdt-sie/brainiac-web-common';
import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

import DeliveryTimeline from './delivery-timeline';
import VideoFileInformation from './video-file-information';
import ApproveVideoDeliveryModal from '../approve-video-delivery-modal';
import {VideoDeliveryActions, VideoDeliveryConstants} from '../video-delivery-actions';
import VideoDeliveryStore from '../video-delivery-store';

import DocumentTitle from '~/src/common/document-title';
import {MODAL_TYPE} from '~/src/common/notification/modal';
import {NotificationActions} from '~/src/common/notification/notification-actions';
import ResizablePanels from '~/src/common/resizable-panel';
import {WithValidations} from '~/src/common/validations/validations';
import LayoutStore from '~/src/layout/layout-store';
import AccuratePlayer from '~/src/player/accurate-player';
import Preloader from '~/src/preloader';
import {RouterActions} from '~/src/router/router-actions';
import SessionStore from '~/src/user/session/session-store';

import './video-delivery.less';

class VideoDeliveryDetail extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            permissions: PropTypes.object,
            params: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        const vds = VideoDeliveryStore.getState();
        return {
            audioProfile: vds.get('audioProfile'),
            audioProfilePresetId: vds.get('audioProfilePresetId'),
            audioWaveforms: vds.get('audioWaveforms'),
            mediaInfoTotalAudioTracks: vds.get('mediaInfoTotalAudioTracks'),
            originalAudioProfile: vds.get('originalAudioProfile'),
            originalVideoDelivery: vds.get('originalVideoDelivery'),
            player: vds.get('player'),
            sources: vds.get('sources'),
            showPreloader: vds.get('showPreloader'),
            selectedTitle: vds.get('selectedTitle'),
            videoDelivery: vds.get('videoDelivery'),
            viewingMode: LayoutStore.getState().get('hardacAppTheme')
        };
    }

    static getStores() {
        return [LayoutStore, VideoDeliveryStore];
    }

    static get defaultProps() {
        return {
            permissions: {}
        };
    }

    static getPermissions() {
        return {
            canRead: SessionStore.canUser(SessionStore.PERMISSIONS.HARDAC.VIDEO_DELIVERY.READ),
            canApprove: SessionStore.canUser(SessionStore.PERMISSIONS.HARDAC.VIDEO_DELIVERY.APPROVE),
            canReject: SessionStore.canUser(SessionStore.PERMISSIONS.HARDAC.VIDEO_DELIVERY.REJECT)
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            rejectComments: '',
            showApproveVideoDeliveryModal: false
        }, this.constructor.calculateState());

        this.handleApproveVideoDelivery = this.handleApproveVideoDelivery.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleRegisterRedirect = this.handleRegisterRedirect.bind(this);
        this.handleRejectedCommentsChange = this.handleRejectedCommentsChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.isDirty = this.isDirty.bind(this);
        this.onConfirmApprove = this.onConfirmApprove.bind(this);
        this.onConfirmReject = this.onConfirmReject.bind(this);
        this.showRejectDialog = this.showRejectDialog.bind(this);
        this.toggleApproveVideoDeliveryModal = this.toggleApproveVideoDeliveryModal.bind(this);
    }

    componentDidMount() {
        this.init(this.props);
        RouterActions.registerRedirectCheck(this.handleRegisterRedirect);
        return;
    }

    UNSAFE_componentWillReceiveProps() {
        RouterActions.registerRedirectCheck(this.handleRegisterRedirect);
    }

    init(props) {
        VideoDeliveryActions.findById(props.params.id);
    }

    isDirty(location) {
        // component pathname: /hardac/video-inbox/<id>/<tabname>
        if (IsLocationDirty(location, this.props.location, 3)) {
            return !this.state.videoDelivery.equals(this.state.originalVideoDelivery) ||
               !this.state.audioProfile.equals(this.state.originalAudioProfile);
        }

        return false;
    }

    handleApproveVideoDelivery(proxyChannels) {
        VideoDeliveryActions.approve(this.state.videoDelivery, this.state.audioProfile, proxyChannels);
    }

    handleCancel() {
        RouterActions.redirect('/hardac/video-inbox', true);
    }


    handleRegisterRedirect(location) {
        return this.isDirty(location);
    }

    handleRejectedCommentsChange(e) {
        this.setState({
            rejectComments: e.target.value
        });
        return;
    }

    handleSave() {
        VideoDeliveryActions.save(this.state.videoDelivery, this.state.audioProfile);
    }

    handleUpdatePlayer(player) {
        VideoDeliveryActions.update('player', player);
    }

    onConfirmApprove() {
        this.toggleApproveVideoDeliveryModal();
    }

    onConfirmReject() {
        VideoDeliveryActions.reject(this.state.videoDelivery, this.state.rejectComments);
    }

    showRejectDialog(event) {
        event.preventDefault();
        let rejectMessage = <div>
            <label className="control-label"><i className="fas fa-pencil-alt"></i>&nbsp;{this.context.intl.messages['hardac.video-inbox.reject.reasons']}</label>
            <textarea
                className="form-control"
                onChange={this.handleRejectedCommentsChange}
            >
            </textarea>
        </div>;

        NotificationActions.show(
            MODAL_TYPE.DANGER,
            this.context.intl.messages['hardac.video-inbox.reject.title'],
            rejectMessage,
            this.context.intl.messages['common.reject'],
            this.onConfirmReject
        );
    }

    toggleApproveVideoDeliveryModal() {
        this.setState(prevState => ({
            showApproveVideoDeliveryModal: !prevState.showApproveVideoDeliveryModal
        }));
    }

    render() {
        const assetName = this.state.videoDelivery.get('displayName');

        let ap;
        const src = this.state.sources.get(this.props.params.id.toString());
        if (!this.state.videoDelivery.get('sourceFileLocation')) {
            ap = <h4 className="timeline-placeholder">{this.context.intl.messages['hardac.video-inbox.source.not-available']}</h4>;
        } else if (src) {
            ap = (
                <AccuratePlayer
                    audioProfile={this.state.audioProfile}
                    audioSoloing={true}
                    onUpdatePlayer={this.handleUpdatePlayer}
                    showThumbnails={false}
                    src={src}
                    video={this.state.videoDelivery}
                />
            );
        } else {
            ap = <h4 className="timeline-placeholder">{this.context.intl.messages['hardac.video-inbox.source.retrieve-in-progress']}</h4>;
        }

        const {canApprove, canReject} = this.props.permissions;
        const canApproveWhenStatus = [
            VideoDeliveryConstants.STATUS_TYPES.COMPLETED.id,
            VideoDeliveryConstants.STATUS_TYPES.FAILED_INGEST_TO_OAP.id,
            VideoDeliveryConstants.STATUS_TYPES.INITIALIZED.id
        ];
        let approveEnabled = canApproveWhenStatus.indexOf(this.state.videoDelivery.get('status')) !== -1 &&
            !!this.state.videoDelivery.get('assetName') &&
            !!this.state.videoDelivery.get('contentType') &&
            !!this.state.videoDelivery.get('titleId') &&
            !!this.state.videoDelivery.get('sourceFileLocation');

        if (approveEnabled) {
            switch (this.state.videoDelivery.get('syncOptionType')) {
            case VideoDeliveryConstants.SYNC_TO_OAP_TYPES.SEND_TO_OAP.id:
                approveEnabled = !!this.state.videoDelivery.get('titleId') && !!this.state.videoDelivery.get('showCode');
                break;

            case VideoDeliveryConstants.SYNC_TO_OAP_TYPES.PULL_FROM_OAP.id:
                approveEnabled = !!this.state.videoDelivery.get('barcodeNum');
                break;

            case VideoDeliveryConstants.SYNC_TO_OAP_TYPES.NO_TRANSFER.id:
            default:
                approveEnabled = true;
                break;
            }
        }

        // if qc video is approved then disable qc video editing
        const isDisabled = this.state.videoDelivery.get('status') === VideoDeliveryConstants.STATUS_TYPES.APPROVED.id;

        let saveApproveDenyButtons = <span>
            <Button
                className="btn btn-primary-outline Mr(3px) Mb(3px)"
                disabled={isDisabled}
                onClick={this.handleSave}
            >
                <i className="fas fa-save"></i>&nbsp;{this.context.intl.messages['common.save']}
            </Button>
            <Button
                className="btn btn-success-outline Mr(3px) Mb(3px)"
                disabled={!canApprove || !approveEnabled || isDisabled}
                onClick={this.onConfirmApprove}
            >
                <i className="fas fa-check"></i>&nbsp;{this.context.intl.messages['common.approve']}
            </Button>
            <Button
                className="btn btn-danger-outline Mr(3px) Mb(3px)"
                disabled={!canReject || isDisabled}
                onClick={this.showRejectDialog}
            >
                <i className="fas fa-times"></i>&nbsp;{this.context.intl.messages['common.deny']}
            </Button>
        </span>;

        // STUDIO-10641 hide the save/approve/deny buttons if the video has been approved or rejected
        if ([VideoDeliveryConstants.STATUS_TYPES.APPROVED.id, VideoDeliveryConstants.STATUS_TYPES.REJECTED.id]
            .includes(this.state.videoDelivery.get('status'))) {
            saveApproveDenyButtons = undefined;
        }

        return (
            <DocumentTitle
                message={'document-titles.hardac.video-inbox'} entityName={assetName}
            >
                <Preloader show={this.state.showPreloader} fixed loadingDots>
                    <section className="video-delivery-detail-content">
                        <ResizablePanels
                            displayDirection="column"
                            width="100%"
                            height="94vh"
                            panelsSize={[78, 22]}
                            panelsMinSize={[0, 0]}
                            panelsMaxSize={[100, 100]}
                            sizeUnitMeasure="%"
                            resizerColor="#161616"
                            resizerSize="10px"
                        >
                            <div style={{height: '100%'}} className="resizable-info-detail-container">
                                <ResizablePanels
                                    displayDirection="row"
                                    width="100%"
                                    height="inherit"
                                    panelsSize={[60, 40]}
                                    panelsMinSize={[33, 33]}
                                    panelsMaxSize={[66, 66]}
                                    sizeUnitMeasure="%"
                                    resizerColor="#161616"
                                    resizerSize="10px"
                                >
                                    <div style={{display:'flex', justifyContent: 'center', height: '100%'}}>
                                        {ap}
                                    </div>
                                    <div className="info-detail-container">
                                        <div className="content-header no-top-padding padding-x-20">
                                            <h1>
                                                <i className="fas fa-eye"></i>&nbsp;{this.context.intl.messages['hardac.video-inbox.summary.title']}
                                                <div className="pull-right">
                                                    {saveApproveDenyButtons}
                                                    <Button
                                                        className="btn btn-default-outline Mr(3px) Mb(3px)"
                                                        disabled={isDisabled}
                                                        onClick={this.handleCancel}
                                                    >
                                                        <i className="fas fa-ban"></i>&nbsp;{this.context.intl.messages['common.cancel']}
                                                    </Button>
                                                </div>
                                                <br/><small>{assetName}</small>
                                            </h1>
                                        </div>
                                        <VideoFileInformation
                                            audioProfile={this.state.audioProfile}
                                            audioProfilePresetId={this.state.audioProfilePresetId}
                                            isDisabled={isDisabled}
                                            location={this.props.location}
                                            params={this.props.params}
                                            mediaInfoTotalAudioTracks={this.state.mediaInfoTotalAudioTracks}
                                            selectedTitle={this.state.selectedTitle}
                                            videoDelivery={this.state.videoDelivery}
                                        />
                                    </div>
                                </ResizablePanels>
                            </div>
                            <div className="padding-x-20" style={{overflow: 'auto'}}>
                                <DeliveryTimeline
                                    audioProfile={this.state.audioProfile}
                                    audioWaveforms={this.state.audioWaveforms}
                                    player={this.state.player}
                                    videoDelivery={this.state.videoDelivery}
                                />
                            </div>
                        </ResizablePanels>
                    </section>
                    <ApproveVideoDeliveryModal
                        audioProfile={this.state.audioProfile}
                        audioProfilePresetId={this.state.audioProfilePresetId}
                        onApproveVideoDelivery={this.handleApproveVideoDelivery}
                        onToggleModal={this.toggleApproveVideoDeliveryModal}
                        show={this.state.showApproveVideoDeliveryModal}
                        viewingMode={this.state.viewingMode}
                    />
                </Preloader>
            </DocumentTitle>
        );
    }
}

export default WithValidations(Container.create(VideoDeliveryDetail));

export {VideoDeliveryDetail};
