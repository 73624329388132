/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React from 'react';

import DocumentTitle from '../common/document-title';
import Preloader from '../preloader';
import PreloaderStore from '../preloader/preloader-store';
import Permissions from '../roles/create/permissions';
import {PermissionActions} from '../security/permission/permission-actions';
import PermissionStore from '../security/permission/permission-store';

class Browse extends React.Component {
    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            APIPermissions: PermissionStore.getState().get('API'),
            CMSPermissions: PermissionStore.getState().get('CMS'),
            WBTVDPermissions: PermissionStore.getState().get('WBTVD'),
            FYCPermissions: PermissionStore.getState().get('FYC'),
            showPreloader: PreloaderStore.getState().get('preloaderVisible'),
        };
    }

    static getStores() {
        return [PermissionStore, PreloaderStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();
    }

    componentDidMount() {
        PermissionActions.getPermissionTree();
    }

    render() {
        return (
            <DocumentTitle
                message={'permission.browse.title'}
            >
                <div>
                    <section className="content-header">
                        <h1><i className="fa fa-key"></i>&nbsp;{this.context.intl.messages['permission.browse.title']}&nbsp;</h1>
                        <p className="padding-top-20"><em>{this.context.intl.messages['permission.browse.description']}</em></p>
                    </section>
                    <Preloader show={this.state.showPreloader} fixed loadingDots>
                        <section className="content">
                            <Permissions
                                APIPermissions={this.state.APIPermissions}
                                CMSPermissions={this.state.CMSPermissions}
                                WBTVDPermissions={this.state.WBTVDPermissions}
                                FYCPermissions={this.state.FYCPermissions}
                                audit={true}
                            />
                        </section>
                    </Preloader>
                </div>
            </DocumentTitle>
        );
    }
}

export default Container.create(Browse);
