/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {LanguageCodeType} from '@wbdt-sie/brainiac-web-common';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {FormGroup} from 'react-bootstrap';

import BaseSelect from '../../../common/base-select/base-select';
import {FormRow, FormSection} from '../../../common/form/form';
import {WithValidations} from '../../../common/validations/validations';
import {HandleSelect} from '../../../lookup/lookup-select-helpers';
import {TitleActions} from '../../title-actions';
import TitleStore, {TitleValidations} from '../../title-store';


class TitleType extends Component {

    static get propTypes() {
        return {
            currentLanguage: PropTypes.string.isRequired,
            disabled: PropTypes.bool,
            title: PropTypes.instanceOf(Immutable.Map).isRequired,
            titleLocalized: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            isCategoryDirty: false,
            selectedTitleType: TitleStore.getTitleType(this.props.title.get('category')) || Immutable.Map()
        };

        this.handleSelectTitleType = this.handleSelectTitleType.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            selectedTitleType: TitleStore.getTitleType(nextProps.title.get('category')) || Immutable.Map()
        });

        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.disabled !== this.props.disabled ||
            nextProps.titleLocalized !== this.props.titleLocalized || // actually have to check if name or selected language has changed
            nextProps.currentLanguage !== this.props.currentLanguage) {
            return true;
        }

        if (nextState.isCategoryDirty !== this.state.isCategoryDirty ||
            !nextState.selectedTitleType.equals(this.state.selectedTitleType)) {
            return true;
        }

        return false;
    }

    handleSelectTitleType(value) {
        if (!this.state.isCategoryDirty) {
            this.setState({isCategoryDirty: true});
        }
        HandleSelect('category', TitleActions.updateTitle, {valueAttr: 'id'})(value);

        return;
    }

    render() {
        let localized = this.props.currentLanguage !== LanguageCodeType.DEFAULT_LANGUAGE;
        let disabledOnLocalized = this.props.disabled || localized;
        return (
            <FormSection iconClass="far fa-caret-square-down" title={<span>{this.context.intl.messages['titles.create.summary.title-type']}&nbsp;<span className="text-red">*</span></span>}>
                <FormRow>
                    <FormGroup validationState={this.getValidationState(this.props.title.get('category'), this.state.isCategoryDirty, TitleValidations.category.validations)}>
                        <BaseSelect
                            disabled={disabledOnLocalized}
                            name={this.context.intl.messages['titles.create.summary.title-type']}
                            onChange={this.handleSelectTitleType}
                            options="titleTypes"
                            store={TitleStore}
                            value={this.state.selectedTitleType}
                        />
                    </FormGroup>
                </FormRow>
            </FormSection>
        );
    }

}

export default WithValidations(TitleType);
