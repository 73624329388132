/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import QueryString from 'querystring-es3';
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

import ListUsers from '../../common/list-users/list-users';
import {MODAL_TYPE} from '../../common/notification/modal';
import {NotificationActions} from '../../common/notification/notification-actions';
import {RouterActions} from '../../router/router-actions';
import {RoleActions} from '../../security/role/role-actions';
import {UserActions} from '../../user/user-actions';
import UserStore from '../../user/user-store';

class Users extends Component {
    static get propTypes() {
        return {
            disableRemove: PropTypes.bool,
            displayLinks: PropTypes.bool,
            location: PropTypes.object.isRequired,
            roleId: PropTypes.string.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        const us = UserStore.getState();
        return {
            activePage: us.get('activePage'),
            selectAll: us.get('selectAll'),
            selectedUsers: us.get('selectedUsers'),
            total: us.get('total'),
            totalPages: us.get('totalPages'),
            users: us.get('users'),
        };
    }

    static getStores() {
        return [UserStore];
    }

    static get defaultProps() {
        return {
            disableRemove: true,
            displayLinks: false
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            size: 20,
            sortDirection: 'asc',
            sortFieldName: 'name',
        }, this.constructor.calculateState());

        this.handleRemoveSelectedUsers = this.handleRemoveSelectedUsers.bind(this);
        this.showRemoveUsersDialog = this.showRemoveUsersDialog.bind(this);
        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
    }

    componentDidMount() {
        UserActions.toggleSelectAll(false);
        this.initStores(this.props);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.roleId !== this.props.roleId ||
            this.props.location !== nextProps.location) {
            return true;
        }
        if (nextState.selectedUsers !== this.state.selectedUsers ||
            nextState.users !== this.state.users) {
            return true;
        }
        return false;
    }

    componentWillUpdate(nextProps) {
        if (this.props.location !== nextProps.location) {
            UserActions.toggleSelectAll(false);
            UserActions.findAllByRoleId(
                this.props.roleId,
                nextProps.location.query.offset,
                nextProps.location.query.size,
                nextProps.location.query['sort-field-name'],
                nextProps.location.query['sort-direction']
            );
        }
        return;
    }

    handleRemoveSelectedUsers() {
        RoleActions.removeUsers(this.props.roleId, this.state.selectedUsers);
        return;
    }

    showRemoveUsersDialog(event) {
        event.preventDefault();

        NotificationActions.show(
            MODAL_TYPE.DANGER,
            this.context.intl.messages['roles.edit.remove-users.modal.title'],
            this.context.intl.messages['roles.edit.remove-users.modal.body'],
            this.context.intl.messages['roles.edit.remove-users.modal.confirm'],
            this.handleRemoveSelectedUsers
        );
    }

    initStores(props) {
        UserActions.findAllByRoleId(
            props.roleId,
            props.location.query.offset,
            props.location.query.size,
            props.location.query['sort-field-name'],
            props.location.query['sort-direction']
        );
        return;
    }

    handleLoadPage(pageNumber) {
        // pageNumber x this.state.size = offset.
        let query = Object.assign(
            {},
            this.props.location.query,
            {
                'offset': pageNumber*this.state.size,
                'size': this.state.size
            }
        );

        RouterActions.redirect(`${this.props.location.pathname}?${QueryString.stringify(query)}`);
        return;
    }

    handleSortChange(sortFieldName, sortDirection) {
        this.setState({
            sortDirection: sortDirection,
            sortFieldName: sortFieldName
        });
        let query = Object.assign(
            {},
            this.props.location.query,
            {
                'sort-field-name': sortFieldName,
                'sort-direction': sortDirection,
                'keep-scroll': true
            }
        );

        RouterActions.redirect(`${this.props.location.pathname}?${QueryString.stringify(query)}`);
        return;
    }

    render() {
        return (
            <div className="tab-pane padding-x-20">
                <ListUsers
                    activePage={this.state.activePage}
                    columns={[
                        'flag', 'name', 'company', 'email', 'status',
                        'clientRepGroup', 'applicationDate'
                    ]}
                    displayLinks={this.props.displayLinks}
                    onPageChange={this.handleLoadPage}
                    onSelect={UserActions.toggleSelect}
                    onSelectAll={UserActions.toggleSelectAll}
                    onSortChange={this.handleSortChange}
                    selectAll={this.state.selectAll}
                    sortFieldName={this.state.sortFieldName}
                    sortDirection={this.state.sortDirection}
                    users={this.state.users}
                    totalPages={this.state.totalPages}
                >
                    <div className="bulk-actions">
                        <Button
                            bsStyle="danger"
                            disabled={!(!this.props.disableRemove && this.state.selectedUsers.size)}
                            onClick={this.showRemoveUsersDialog}><i className="fas fa-trash-alt"></i>&nbsp;{this.context.intl.messages['roles.edit.remove-users']}</Button>
                    </div>
                </ListUsers>
            </div>
        );
    }
}

export default Container.create(Users);
