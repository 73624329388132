/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import Moment from 'moment';

import {AlertTypes} from '../common/notification/alert';
import {NotificationActions} from '../common/notification/notification-actions';
import config from '../config/config.js';
import Dispatcher from '../dispatcher/dispatcher';
import Request from '../request';
import {RouterActions} from '../router/router-actions';

require('moment-timezone');
let configtz = Moment().tz(config.DefaultTimezone).format('ZZ');

let getUrl = (entity, id) => `${entity}/${id}`;

let getUrlHistory = (entity, id) => `${entity}/${id}/history`;

let getHistoryObjectItem = (displayName, body) => {
    return {
        actionObjectDescription: `${body[displayName]}`,
        actionObjectBody: body
    };
};

const CONSTANTS = {
    HISTORY: {
        GET: {
            ERROR: 'history_actions.history.get.error',
            START: 'history_actions.history.get',
            SUCCESS: 'history_actions.history.get.ok'
        },
        MODAL_DETAIL: {
            OPEN: 'history_actions.history.modal_detail.open',
            CLOSE: 'history_actions.history.modal_detail.close'
        },
        GET_ACTION_OBJECT_ITEM: {
            ERROR: 'history_actions.get_description.error',
            SUCCESS: 'history_actions.get_description.ok'
        }
    },
    FILTERS: {
        OBJECT_TYPE_OPTIONS: {
            AssetToCatalog: {
                id: 1,
                key: 'AssetToCatalog',
                name: 'Asset to catalog',
                commonActionObject: 'AssetToCatalog',
                getUrl: getUrl.bind(null, 'asset'),
                getHistoryObjectItemUrl: getUrlHistory.bind(null, 'catalogs/asset'),
                getHistoryObjectItem: getHistoryObjectItem.bind(null, 'assetName')
            },
            // AssetToTitle: { //TODO conflicts because it could asset or title, not defined
            //     id: 2,
            //     key: 'AssetToTitle',
            //     name: 'Asset to title',
            //     commonActionObject: 'AssetTo',
            //     getUrl: getUrl.bind(null, 'title'),
            //     getHistoryObjectItemUrl: getUrlHistory.bind(null, 'titles'),
            //     getHistoryObjectItem: getHistoryObjectItem.bind(null, 'displayName')
            // },
            Audio: {
                id: 3,
                key: 'Audio',
                name: 'Audio',
                commonActionObject: 'Audio',
                getUrl: getUrl.bind(null, 'asset'),
                getHistoryObjectItemUrl: getUrlHistory.bind(null, 'assets/audio'), //asset to catalog
                getHistoryObjectItem: getHistoryObjectItem.bind(null, 'assetName')
            },
            ClientRepGroup: {
                id: 4,
                key: 'ClientRepGroup',
                name:  'Client rep group',
                commonActionObject: 'ClientRepGroup',
                getUrl: getUrl.bind(null, 'security/client-rep-group'),
                getHistoryObjectItemUrl: getUrlHistory.bind(null, 'groups/client-rep-groups'),
                getHistoryObjectItem: getHistoryObjectItem.bind(null, 'name')
            },
            Document: {
                id: 5,
                key: 'Document',
                name: 'Document',
                commonActionObject: 'Document',
                getUrl: getUrl.bind(null, 'asset'),
                getHistoryObjectItemUrl: getUrlHistory.bind(null, 'assets/document'),
                getHistoryObjectItem: getHistoryObjectItem.bind(null, 'assetName')
            },
            Group: {
                id: 7,
                key: 'Group',
                name: 'Group',
                commonActionObject: 'Group',
                getUrl: getUrl.bind(null, 'security/group'),
                getHistoryObjectItemUrl: getUrlHistory.bind(null, 'groups/user-groups'),
                getHistoryObjectItem: getHistoryObjectItem.bind(null, 'name')
            },
            HomePage: {
                id: 8,
                key: 'HomePage',
                name: 'Homepage',
                commonActionObject: 'HomePage',
                getUrl: getUrl.bind(null, 'web/homepage'),
                getHistoryObjectItemUrl: getUrlHistory.bind(null, 'homepage'),
                getHistoryObjectItem: getHistoryObjectItem.bind(null, 'name')
            },
            Image: {
                id: 9,
                key: 'Image',
                name: 'Image',
                commonActionObject: 'Image',
                getUrl: getUrl.bind(null, 'asset'),
                getHistoryObjectItemUrl: getUrlHistory.bind(null, 'assets/image'),
                getHistoryObjectItem: getHistoryObjectItem.bind(null, 'assetName')
            },
            Merchandise: {
                id: 10,
                key: 'Merchandise',
                name: 'Merchandise',
                commonActionObject: 'Merchandise',
                getUrl: getUrl.bind('asset'),
                getHistoryObjectItemUrl: getUrlHistory.bind(null, 'assets/merchandise'),
                getHistoryObjectItem: getHistoryObjectItem.bind(null, 'assetName')
            },
            Order: {
                id: 11,
                key: 'Order',
                name: 'Order',
                commonActionObject: 'Order',
                getUrl: getUrl.bind(null, 'order'),
                getHistoryObjectItem: getHistoryObjectItem.bind(null, 'name')
            },
            PublishList: {
                id: 30,
                key: 'PublishList',
                name: 'Publish list',
                commonActionObject: 'PublishList',
                getUrl: getUrl.bind(null, 'web/publish-list'),
                getHistoryObjectItemUrl: getUrlHistory.bind(null, 'publishing-list'),
                getHistoryObjectItem: getHistoryObjectItem.bind(null, 'name')
            },
            PublishListItem: {
                id: 31,
                key: 'PublishListItem',
                name: 'Publish list item',
                commonActionObject: 'PublishListItem',
                getUrl: getUrl.bind(null, 'web/publish-list'),
                getHistoryObjectItemUrl: getUrlHistory.bind(null, 'publishing-list'),
                getHistoryObjectItem: getHistoryObjectItem.bind(null, 'name')
            },
            PublishListItemGroup: {
                id: 32,
                key: 'PublishListItemGroup',
                name: 'Publish list item group',
                commonActionObject: 'PublishListItemGroup',
                getUrl: getUrl.bind(null, 'web/publish-list'),
                getHistoryObjectItemUrl: getUrlHistory.bind(null, 'publishing-list'),
                getHistoryObjectItem: getHistoryObjectItem.bind(null, 'name')
            },
            RelatedTitle: {
                id: 13,
                key: 'RelatedTitle',
                name: 'Related title',
                commonActionObject: 'RelatedTitle',
                getUrl: getUrl.bind(null, 'title'),
                getHistoryObjectItemUrl: getUrlHistory.bind(null, 'titles'),
                getHistoryObjectItem: getHistoryObjectItem.bind(null, 'displayName')
            },
            Role: {
                id: 12,
                key: 'Role',
                name: 'Role',
                commonActionObject: 'Role',
                getUrl: getUrl.bind(null, 'security/role'),
                getHistoryObjectItemUrl: getUrlHistory.bind(null, 'roles'),
                getHistoryObjectItem: getHistoryObjectItem.bind(null, 'name')
            },
            Script: {
                id: 14, key: 'Script',
                name: 'Script',
                commonActionObject: 'Script',
                getUrl: getUrl.bind(null, 'asset'),
                getHistoryObjectItem: getHistoryObjectItem.bind(null, 'assetName')
            },
            Talent: {
                id: 16,
                key: 'Talent',
                name: 'Talent',
                commonActionObject: 'Talent',
                getUrl: getUrl.bind(null, 'talent'),
                getHistoryObjectItemUrl: getUrlHistory.bind(null, 'talent'),
                getHistoryObjectItem: getHistoryObjectItem.bind(null, 'fullName')
            },
            TalentTitle: {
                id: 23,
                key: 'TalentTitle',
                name: 'Title talent',
                commonActionObject: 'TalentTitle',
                getUrl: getUrl.bind(null, 'talent'),
                getHistoryObjectItemUrl: getUrlHistory.bind(null, 'talent'),
                getHistoryObjectItem: getHistoryObjectItem.bind(null, 'fullName')
            },
            Title: {
                id: 17,
                key: 'Title',
                name: 'Title',
                commonActionObject: 'Title',
                getUrl: getUrl.bind(null, 'title'),
                getHistoryObjectItemUrl: getUrlHistory.bind(null, 'titles'),
                getHistoryObjectItem: getHistoryObjectItem.bind(null, 'displayName')
            },
            TitleIngestHeader: {
                id: 18,
                key: 'TitleIngestHeader',
                name: 'Title ingest header',
                getUrl: getUrl,
                getHistoryObjectItem: getHistoryObjectItem
            },
            TitleIngestRow: {
                id: 19,
                key: 'TitleIngestRow',
                name: 'Title ingest row',
                getUrl: getUrl,
                getHistoryObjectItem: getHistoryObjectItem
            },
            TitleToCatalog: {
                id: 20,
                key: 'TitleToCatalog',
                name: 'Title to catalog',
                commonActionObject: 'TitleToCatalog',
                getUrl: getUrl.bind(null, 'title'),
                getHistoryObjectItemUrl: getUrlHistory.bind(null, 'titles'),
                getHistoryObjectItem: getHistoryObjectItem.bind(null, 'displayName')
            },
            TitleToGenre: {
                id: 21,
                key:'TitleToGenre',
                name: 'Title to genre',
                commonActionObject: 'TitleToGenre',
                getUrl: getUrl.bind(null, 'title'),
                getHistoryObjectItemUrl: getUrlHistory.bind(null, 'titles'),
                getHistoryObjectItem: getHistoryObjectItem.bind(null, 'displayName')
            },
            TitleToProductionCompany: {
                id: 22,
                key: 'TitleToProductionCompany',
                name: 'Title to production company',
                commonActionObject: 'TitleToProductionCompany',
                getUrl: getUrl.bind(null, 'title'),
                getHistoryObjectItemUrl: getUrlHistory.bind(null, 'titles'),
                getHistoryObjectItem: getHistoryObjectItem.bind(null, 'displayName')
            },
            TitleRelease: {
                id: 24,
                key: 'TitleRelease',
                name: 'Title release',
                commonActionObject: 'TitleRelease',
                getUrl: getUrl.bind(null, 'title'),
                getHistoryObjectItemUrl: getUrlHistory.bind(null, 'titles'),
                getHistoryObjectItem: getHistoryObjectItem.bind(null, 'displayName')
            },
            User: {
                id: 25,
                key: 'User',
                name: 'User',
                commonActionObject: 'user',
                getUrl: getUrl.bind(null, 'user'),
                getHistoryObjectItemUrl: getUrlHistory.bind(null, 'accounts'),
                getHistoryObjectItem: getHistoryObjectItem.bind(null, 'fullName')
            },
            UserIngestHeader: {
                id: 33,
                key: 'UserIngestHeader',
                name: 'User ingest header',
                commonActionObject: 'UserIngestHeader',
                getUrl: getUrl.bind(null, 'user/ingest'),
                getHistoryObjectItem: getHistoryObjectItem.bind(null, 'status')
            },
            UserIngestRow: {
                id: 34,
                key: 'UserIngestRow',
                name: 'User ingest trow',
                getUrl: getUrl,
                getHistoryObjectItem: getHistoryObjectItem
            },
            UserLogin: {
                id: 26,
                key: 'UserLogin',
                name: 'User login',
                getUrl: getUrl,
                getHistoryObjectItem: getHistoryObjectItem
            },
            UserToGroup: {
                id: 27,
                key: 'UserToGroup',
                name: 'User group',
                commonActionObject: 'user',
                getUrl: getUrl.bind(null, 'user'),
                getHistoryObjectItemUrl: getUrlHistory.bind(null, 'accounts'),
                getHistoryObjectItem: getHistoryObjectItem.bind(null, 'fullName')
            },
            UserToRole: {
                id: 28,
                key : 'UserToRole',
                name: 'User to role',
                commonActionObject: 'user',
                getUrl: getUrl.bind(null, 'user'),
                getHistoryObjectItemUrl: getUrlHistory.bind(null, 'accounts'),
                getHistoryObjectItem: getHistoryObjectItem.bind(null, 'fullName')
            },
            Video: {
                id: 29,
                key: 'Video',
                name: 'Video',
                commonActionObject: 'Video',
                getUrl: getUrl.bind(null, 'asset'),
                getHistoryObjectItemUrl: getUrlHistory.bind(null, 'assets/video'),
                getHistoryObjectItem: getHistoryObjectItem.bind(null, 'assetName')
            },
            WatchVideo: {
                id: 35,
                key: 'WatchVideo',
                name: 'Watch video',
                getUrl: getUrl,
                getHistoryObjectItem: getHistoryObjectItem
            }
        },
        ACTION_TYPES: {
            USER_GENERATED: {id: 1, key: 'USER_GENERATED', name: 'User generated'},
            CREATE: {id: 2, key: 'CREATE', name: 'Create'},
            CASCADE_UPDATE: {id: 3, key: 'CASCADE_UPDATE', name: 'Cascade Update'},
            CASCADE_DELETE: {id: 4, key: 'CASCADE_DELETE', name: 'Cascade Delete'},
            UPDATE: {id: 5, key: 'UPDATE', name: 'Update'},
            DELETE: {id: 6, key: 'DELETE', name: 'Delete'},
            DECOMISSION: {id: 7, key: 'DECOMISSION', name: 'Decomission'},
            GROUP_CRITERIA_ADD: {id: 8, key: 'GROUP_CRITERIA_ADD', name: 'Group Criteria Add'},
            GROUP_CRITERIA_DELETE: {id: 9, key: 'GROUP_CRITERIA_DELETE', name: 'Group Criteria Delete'},
            MASS_ADD: {id: 10, key: 'MASS_ADD', name: 'Mass Add'},
            MASS_UPDATE: {id: 11, key: 'MASS_UPDATE', name: 'Mass Update'},
            MASS_DELETE: {id: 12, key: 'MASS_DELETE', name: 'Mass Delete'},
            WEB: {id: 13, key: 'WEB', name: 'Web'},
            REQUEST: {id: 14, key: 'REQUEST', name: 'Request'},
            ROLE_CRITERIA_ADD: {id: 15, key: 'ROLE_CRITERIA_ADD', name: 'Role Criteria Add'},
            ROLE_CRITERIA_DELETE: {id: 16, key: 'ROLE_CRITERIA_DELETE', name: 'Role Criteria Delete'},
            DENY: {id: 17, key: 'DENY', name: 'Deny'},
            APPROVE: {id: 18, key: 'APPROVE', name: 'Approve'},
            FULFILL: {id: 19, key: 'FULFILL', name: 'Fulfill'},
            DENYFULFILLMENT: {id: 20, key: 'DENYFULFILLMENT', name: 'Deny Fulfillment'},
            REACTIVATED: {id: 21, key: 'REACTIVATED', name: 'Activate'},
            LOCK: {id: 22, key: 'LOCK', name: 'Lock'},
            BOUNCE_EMAIL: {id: 23, key: 'BOUNCE_EMAIL', name: 'Bounce Email'},
            SESSION: {id: 24, key: 'SESSION', name: 'Session'},
            PROXY_CREATED: {id: 25, key: 'PROXY_CREATED', name: 'Proxy Created'},
            ENCODE_STARTED: {id: 26, key: 'ENCODE_STARTED', name: 'Encode Started'},
            DEACTIVATE: {id: 27, key: 'DEACTIVATE', name: 'Deactivate'}
        }
    },
    FILTER: {
        CLEAR: 'title_actions.filter.clear',
        SET: 'title_actions.filter.set'
    }
};

class HistoryActions {

    getHistory(queryParams, offset, size) {
        queryParams = queryParams.toJS();

        offset = offset || queryParams.offset || 0;
        size = size || 20;
        queryParams.offset = offset ;
        queryParams.size = size;

        let startDate = queryParams['start-date'];
        if (startDate) {
            let objectStartDate = Moment(startDate);
            if (objectStartDate.isValid()) {
                startDate = objectStartDate.format('YYYY-MM-DDT00:00:00.000'+configtz);
            } else {
                startDate = '';
            }
            queryParams['start-action-date'] = startDate;
        }

        let endDate = queryParams['end-date'];
        if (endDate) {
            let objectEndDate = Moment(endDate);
            if (objectEndDate.isValid()) {
                endDate = objectEndDate.format('YYYY-MM-DDT00:00:00.000'+configtz);
            } else {
                endDate = '';
            }
            queryParams['end-action-date'] = endDate;
        }

        Dispatcher.dispatch({
            actionType: CONSTANTS.HISTORY.GET.START,
        });

        Request.get('system/action-history').query(queryParams).exec().then((response) => {

            Dispatcher.dispatch({
                actionType: CONSTANTS.HISTORY.GET.SUCCESS,
                history: Immutable.fromJS(response.body.results),
                offset,
                size,
                total: parseInt(response.header['wbtv-total-count'], 10)
            });

            this.fillHistoryDescription(response.body.results);

        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.HISTORY.GET.ERROR,
            });
            switch (err.status) {
            case 404:
                RouterActions.notFound();
                break;
            default:
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
                break;
            }

            throw err;
        });
    }

    fillHistoryDescription(historyData) {
        let historyActionObjects = [];
        historyData.forEach((item) => {
            if (!historyActionObjects.find(actionObject => actionObject.objectId === item.objectId)) {
                historyActionObjects.push(item);
            }
        });

        historyActionObjects.forEach((actionHistory) => {
            let urlRequest;
            let actionHistoryFound = CONSTANTS.FILTERS.OBJECT_TYPE_OPTIONS[actionHistory.actionObjectType];
            if (actionHistory.actionObjectType && actionHistoryFound) {
                urlRequest = actionHistoryFound.getUrl(actionHistory.objectId);
            }
            if (urlRequest) {
                Request.get(urlRequest).exec().then((response) => {
                    let historyActionItem = CONSTANTS.FILTERS.OBJECT_TYPE_OPTIONS[actionHistory.actionObjectType].getHistoryObjectItem(response.body);
                    let objectCommonType = CONSTANTS.FILTERS.OBJECT_TYPE_OPTIONS[actionHistory.actionObjectType].commonActionObject;

                    Dispatcher.dispatch({
                        actionType: CONSTANTS.HISTORY.GET_ACTION_OBJECT_ITEM.SUCCESS,
                        historyActionItem: Immutable.fromJS(historyActionItem),
                        key: `${actionHistory.objectId}-${objectCommonType}`
                    });
                }).catch((err) => {

                    Dispatcher.dispatch({
                        actionType: CONSTANTS.HISTORY.GET_ACTION_OBJECT_ITEM.SUCCESS,
                        historyActionItem: Immutable.fromJS({
                            actionObjectDescription: `Object Not Found: ${actionHistory.objectId} - ${actionHistory.actionObjectType}`,
                            actionObjectBody: {}
                        }),
                        key: `${actionHistory.objectId}-${actionHistory.actionObjectType}`
                    });
                    console.error(err);
                });
            }
        });
    }

    setFilter(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.FILTER.SET,
            attr: attr,
            value: value
        });
    }

    clearFilter() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.FILTER.CLEAR
        });
    }

    findHistoryObject(historyItemId) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.HISTORY.MODAL_DETAIL.OPEN,
            historyItemId: historyItemId,
        });
    }

    closeHistoryModalDetail() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.HISTORY.MODAL_DETAIL.CLOSE,
        });
    }
}

let actions = new HistoryActions();

export {
    actions as HistoryActions,
    CONSTANTS as HistoryConstants
};
