/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Row, OverlayTrigger, Tooltip} from 'react-bootstrap';

import ClipsTable from './clips-table';
import PublishClipModal from './publish-clip-modal';
import TimelineAssetStore from '../../../assets/timeline/timeline-asset-store';
import {TimelineActions, TimelineConstants} from '../timeline-actions';
import TimelineStore from '../timeline-store';

import '../timeline.less';
import 'react-table/react-table.css';

class ClipList extends React.Component {
    static get propTypes() {
        return {
            canEdit: PropTypes.bool.isRequired,
            canPublish: PropTypes.bool.isRequired,
            canPublishAll: PropTypes.bool.isRequired,
            clips: PropTypes.instanceOf(Immutable.Map).isRequired,
            hasUnpublishedClips: PropTypes.bool.isRequired,
            originalClips: PropTypes.instanceOf(Immutable.Map).isRequired,
            player: PropTypes.object,
            sources: PropTypes.instanceOf(Immutable.Map).isRequired,
            timeline: PropTypes.instanceOf(Immutable.Map).isRequired,
            thumbnails: PropTypes.instanceOf(Immutable.List).isRequired,
            viewingMode: PropTypes.string.isRequired
        };
    }

    static get defaultProps() {
        return {
            player: undefined,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            showPublishModal: false
        };

        this.handlePublishAll = this.handlePublishAll.bind(this);
        this.handleTogglePublishModal = this.handleTogglePublishModal.bind(this);
        return;
    }

    handlePublishAll() {
        TimelineActions.publishAll(this.props.clips.get('unpublished'), this.props.timeline);
    }

    handleTogglePublishModal() {
        this.setState((preveState) =>({
            showPublishModal: !preveState.showPublishModal
        }));
    }

    render() {
        const clipsAsText = this.props.clips.get('unpublished').map(clip => {
            const {name: clipName, contentType, active, deliveryType, protectionType} = clip.toJS();
            let contentTypeValue;
            if (contentType) {
                contentTypeValue = TimelineStore.getContentType(contentType).get('name');
            }
            let deliveryTypeValue;
            if (deliveryType !== undefined && deliveryType !== null) {
                deliveryTypeValue = TimelineStore.getDeliveryType(deliveryType).get('name');
            }
            let protectionTypeValue;
            if (protectionType !== undefined && protectionType !== null) {
                protectionTypeValue = TimelineAssetStore.getProtectionType(protectionType).get('name');
            }

            const activeText = (<span>{this.context.intl.messages['hardac.timeline.summary.active']}:&nbsp;<i>{this.context.intl.messages[`common.boolean.${active}`]}</i></span>);
            const contentTypeText = (<span>{this.context.intl.messages['hardac.timeline.clips.content-type']}:&nbsp;<i>{contentTypeValue || '-'}</i></span>);
            const protectionTypeText = (<span>{this.context.intl.messages['hardac.timeline.summary.protection-type']}:&nbsp;<i>{protectionTypeValue || '-'}</i></span>);
            const orderableText = (<span>{this.context.intl.messages['hardac.timeline.summary.delivery-type']}:&nbsp;<i>{deliveryTypeValue || '-'}</i></span>);
            return (<span><strong>{clipName}:&nbsp;</strong>{activeText},&nbsp;{contentTypeText},&nbsp;{protectionTypeText},&nbsp;{orderableText}</span>);
        });
        const publishMessage = (
            <ul>
                {clipsAsText.map((clipText, index) => <li key={`clip-order-${index}`}>{clipText}</li>)}
            </ul>
        );

        // publish all button enabled
        let button;
        if (this.props.hasUnpublishedClips) {
            if (this.props.canPublishAll) {
                button = <Button
                    className="btn btn-primary-outline Mr(3px) Mb(3px)"
                    onClick={this.handleTogglePublishModal}
                >
                    <i className="fas fa-cloud-upload"></i>&nbsp;{this.context.intl.messages['hardac.timeline.clips.publish-all']}
                </Button>;
            } else {
                const renderTooltip = <Tooltip id="button-tooltip">{this.context.intl.messages['hardac.timeline.clip.publish.disabled.tooltip']}</Tooltip>;
                button = <OverlayTrigger placement="top" overlay={renderTooltip}>
                    <Button
                        id="publish-all-disabled"
                        className="btn btn-default-outline Mr(3px) Mb(3px) disabled"
                        onClick={() => void 0}
                    >
                        <i className="fas fa-cloud-upload"></i>&nbsp;{this.context.intl.messages['hardac.timeline.clip.publish.disabled.text']}
                    </Button>
                </OverlayTrigger>;
            }
        }
        let publishingDisabled;
        if (!this.props.canPublishAll && this.props.hasUnpublishedClips) {
            publishingDisabled = <div className="text-danger padding-y-10">
                <em><i className="fas fa-exclamation-triangle"></i> Publishing disabled: save Timeline before publishing.</em>
            </div>;
        }

        return (
            <div className="timeline-clips">
                <Row style={{padding: '0 15px'}}>
                    <div className="padding-y-10">
                        <h4 className="pull-left">
                            {this.context.intl.messages['hardac.timeline.clips.unpublished-clips']}
                        </h4>
                        <div className="pull-right">
                            {button}
                        </div>
                    </div>
                </Row>
                <Row style={{padding: '0 15px 16px'}}>
                    {publishingDisabled}
                </Row>
                <ClipsTable
                    canPublish={this.props.canPublishAll}
                    canEdit={this.props.canEdit}
                    clips={this.props.clips.get('unpublished')}
                    originalClips={this.props.originalClips}
                    player={this.props.player}
                    publishStatusType={TimelineConstants.TIMELINE.CLIP.PUBLISH_TYPE.UNPUBLISHED.id}
                    sources={this.props.sources}
                    timeline={this.props.timeline}
                    thumbnails={this.props.thumbnails}
                    viewingMode={this.props.viewingMode}
                />
                <hr/>
                <Row style={{padding: '0 15px 16px'}}>
                    <h4>
                        <div className="padding-y-10 pull-left">
                            {this.context.intl.messages['hardac.timeline.clips.processing-clips']}
                        </div>
                    </h4>
                </Row>
                <ClipsTable
                    canPublish={this.props.canPublish}
                    canEdit={this.props.canEdit}
                    clips={this.props.clips.get('processing')}
                    originalClips={this.props.originalClips}
                    publishStatusType={TimelineConstants.TIMELINE.CLIP.PUBLISH_TYPE.PROCESSING.id}
                    sources={this.props.sources}
                    timeline={this.props.timeline}
                    thumbnails={this.props.thumbnails}
                    viewingMode={this.props.viewingMode}
                />
                <hr/>
                <Row style={{padding: '0 15px 16px'}}>
                    <h4>
                        <div className="padding-y-10 pull-left">
                            {this.context.intl.messages['hardac.timeline.clips.failed-clips']}
                        </div>
                    </h4>
                </Row>
                <ClipsTable
                    canPublish={this.props.canPublish}
                    canEdit={this.props.canEdit}
                    clips={this.props.clips.get('failed')}
                    originalClips={this.props.originalClips}
                    publishStatusType={TimelineConstants.TIMELINE.CLIP.PUBLISH_TYPE.FAILED.id}
                    sources={this.props.sources}
                    timeline={this.props.timeline}
                    thumbnails={this.props.thumbnails}
                    viewingMode={this.props.viewingMode}
                />
                <hr/>
                <Row style={{padding: '0 15px 16px'}}>
                    <h4>
                        <div className="padding-y-10 pull-left">
                            {this.context.intl.messages['hardac.timeline.clips.published-clips']}
                        </div>
                    </h4>
                </Row>
                <ClipsTable
                    canPublish={this.props.canPublish}
                    canEdit={this.props.canEdit}
                    clips={this.props.clips.get('completed')}
                    originalClips={this.props.originalClips}
                    publishStatusType={TimelineConstants.TIMELINE.CLIP.PUBLISH_TYPE.COMPLETE.id}
                    sources={this.props.sources}
                    timeline={this.props.timeline}
                    thumbnails={this.props.thumbnails}
                    viewingMode={this.props.viewingMode}
                />
                <PublishClipModal
                    onConfirmPublish={this.handlePublishAll}
                    onHide={this.handleTogglePublishModal}
                    show={this.state.showPublishModal}
                    title={this.context.intl.messages['hardac.timeline.clips.publish']}
                    viewingMode={this.props.viewingMode}
                >
                    {publishMessage}
                </PublishClipModal>
            </div>
        );
    }
}

export default ClipList;
