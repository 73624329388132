/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';

import {PublishingListLocalizedActions} from './publishing-list-localized-actions';
import {AlertTypes} from '../common/notification/alert';
import {NotificationActions} from '../common/notification/notification-actions';
import {UploadFile} from '../common/utils/utils';
import Dispatcher from '../dispatcher/dispatcher';
import {PreloaderActions} from '../preloader/preloader-actions';
import Request from '../request';
import {RouterActions} from '../router/router-actions';
import {ActionHistoryConstants} from '../system/action-history/action-history-actions';

const CONSTANTS = {
    CLEAR: 'publishing-list.clear',
    FILTERS: {
        ACTIVE_OPTIONS: {
            ACTIVE: {id: 'ACTIVE', value: 1, name: 'Active'},
            INACTIVE: {id: 'INACTIVE', value: 0, name: 'Inactive'},
            BOTH: {id: 'BOTH', value: 2, name: 'Both'}
        }
    },
    FILTER: {
        CLEAR: 'publishing-list.filter.clear',
        SET: 'publishing-list.filter.set'
    },
    FINDBY: {
        ERROR: 'publishing-list.findby.error',
        START: 'publishing-list.findby.start',
        SUCCESS: 'publishing-list.findby.success'
    },
    GET: {
        ERROR: 'publishing-list.get.error',
        SUCCESS: 'publishing-list.get.success'
    },
    HIDE_DETAIL: 'publishing-list.hide.detail',
    IMAGE: {
        SAVE: {
            SUCCESS: 'publishing-list.image.save.success'
        }
    },
    KEEP_LIST: {
        0: 'About',
        1: 'Catalogs',
        2: 'Tools',
        3: 'Internal',
        40: 'Animation',
        80: 'TV',
        120: 'Film'
    },
    REMOVE: {
        ERROR: 'publishing-list.remove.error',
        SUCCESS: 'publishing-list.remove.success'
    },
    SAVE: {
        SUCCESS: 'publishing-list.save.success'
    },
    SHOW_DETAIL: 'publishing-list.show.detail',
    TARGET_TYPES: {
        TITLE: 1,
        ASSET: 2,
        INTERNAL_PAGE: 3,
        APP_SECTION: 4,
        EXTERNAL_WEB_PAGE: 5
    },
    ITEMS: {
        CATALOGS: {
            GET: {
                SUCCESS: 'publishing-list.items.catalogs.get.success'
            }
        },
        CLEAR: 'publishing-list.items.clear',
        GO_TO_PAGE: 'publishing-list.items.go-to-page',
        EDIT: {
            CLEAR: 'publishing-list.items.edit.clear',
            DELETE: 'publishing-list.items.edit.delete',
            SAVE: 'publishing-list.items.edit.save',
            SET: 'publishing-list.items.edit.set',
            SET_GROUPS: 'publishing-list.items.edit.set-groups',
            UPDATE_ATTR: 'publishing-list.items.edit.update-attr'
        },
        GET: {
            START: 'publishing-list.items.get.start',
            SUCCESS: 'publishing-list.items.get.success',
        },
        MOVE: 'publishing-list.items.move',
        THUMBNAILS: {
            GET: {
                SUCCESS: 'publishing-list.items.thumbnails.get.success'
            }
        }
    },
    PURPOSE_TYPES: {
        BILLBOARDS: {id: 1, name: 'Billboards'},
        CATEGORY_SECTION: {id: 2, name: 'Category Section'},
        HOME_SELECTION: {id: 3, name: 'Home Section'},
        TITLE_SECTION_PORTRAIT: {id: 4, name: 'Title Section Portrait'},
        TITLE_SELECTION_LANDSCAPE: {id: 5, name: 'Title Section Landscape'},
        TITLE_SELECTION_RESPONSIVE: {id: 6, name: 'Title Selection Responsive'},
        TEXT_MENU: {id: 7, name: 'Text Menu'},
        VIDEO_SELECTION: {id: 8, name: 'Video Selection'},
        IMAGE_SELECTION: {id: 9, name: 'Image Selection'},
        MIXED_ASSET_SELECTION: {id: 10, name: 'Mixed Asset Selection'},
        DOCUMENT_SELECTION: {id: 11, name: 'Document Selection'},
        UNSTRUCTURED_SELECTION: {id: 12, name: 'Unstructured Selection'},
        NAVIGATION_MENU: {id:13, name: 'Navigation Menu'}
    },
    UPDATE: 'publishing-list.update',
    UPDATE_ATTR: 'publishing-list.update-attr',

    toArray: function(constant) {
        return Object.keys(this[constant])
            .map(k => this[constant][k])
            .sort((a, b) => a.name.localeCompare(b.name));
    }
};

class PublishingListActions {
    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR,
        });

        return;
    }

    clearFilter() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.FILTER.CLEAR
        });
    }

    clearItem() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ITEMS.EDIT.CLEAR,
        });

        return;
    }

    clearItems() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ITEMS.CLEAR,
        });

        return;
    }

    cleanList(list) {
        let listData = list.toJS();
        // Remove History as it is not a api variable
        delete listData.history;
        // Transform booleans to numbers.
        if (listData.active) {
            listData.active = 1;
        } else {
            listData.active = 0;
        }

        return listData;
    }

    cleanItems(listItems) {
        return listItems.toJS().map(item => {
            [
                'newFile', 'newVideoFile', 'groups', 'thumbnails',
                'asset', 'catalogs', 'userGroups', 'imageUrl', 'videoUrl',
            ].forEach(p => delete item[p]);
            return item;
        });
    }

    deleteList(list) {
        list = list.toJS();
        let id = list.id;

        const preloaderSource = 'publishing-list-actions.deleteList';

        PreloaderActions.show(preloaderSource);

        Request.del(`web/publish-list/${id}`)
            .send(list)
            .exec()
            .then(() => {
                PreloaderActions.hide(preloaderSource);
                Dispatcher.dispatch({
                    actionType: CONSTANTS.REMOVE.SUCCESS,
                });

                RouterActions.redirect('/publishing-list');
                NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'publishing-list.delete.success');

                return;
            })
            .catch(err => {
                PreloaderActions.hide(preloaderSource);
                this.errorMessage(err, 'publishing-list.delete.error');
            });

        return;
    }

    deleteListItem(index) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ITEMS.EDIT.DELETE,
            index,
        });

        return;
    }

    errorMessage(error, message) {
        NotificationActions.showAlertDanger(message);
        throw error;
    }

    findById(id) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.FINDBY.START,
            id: id,
        });

        let actionHistoryQuery = {
            'action-object': ActionHistoryConstants.ACTION_OBJECTS.PUBLISHLIST,
            'object-id': id,
            offset: 0,
            size: 4,
        };

        const preloaderSource = 'publishing-list-actions.findById';
        PreloaderActions.show(preloaderSource);

        PublishingListLocalizedActions.findById(id);

        Promise.all([
            Request.get(`web/publish-list/${id}`).exec().then(res => res).catch(error => {
                this.errorMessage(error, 'publishing-list.failed.load');
            }),
            Request.get('system/action-history').query(actionHistoryQuery).exec().then(res => res).catch(error => {
                this.errorMessage(
                    error,
                    'publishing-list.failed.load-history'
                );
            })
        ]).spread((listRes, historyRes) => {
            let list = listRes.body;
            list.history = historyRes.body.results;
            list.history.sort((h1, h2) => h2.actionDate.localeCompare(h1.actionDate));

            Dispatcher.dispatch({
                actionType: CONSTANTS.FINDBY.SUCCESS,
                publishingList: Immutable.fromJS(list),
            });

            PreloaderActions.hide(preloaderSource);

            return;
        }).catch(err => {
            PreloaderActions.hide(preloaderSource);
            Dispatcher.dispatch({
                actionType: CONSTANTS.FINDBY.ERROR,
                error: err,
            });

            switch (err.status) {
            case 404:
                RouterActions.notFound();
                throw err;
            default:
                this.errorMessage(err, 'common.load-error');
            }
        });

        return;
    }

    get(offset, size, filters = Immutable.Map()) {
        const preloaderSource = 'publishing-list-actions.get';
        PreloaderActions.show(preloaderSource);

        let query = filters.toJS();
        query.active = CONSTANTS.FILTERS.ACTIVE_OPTIONS[query.active || CONSTANTS.FILTERS.ACTIVE_OPTIONS.ACTIVE.id].id;
        query.offset = offset || query.offset || 0;
        query.size = size || query.size || 100;

        if (query.q) {
            query.name = query.q;
            delete query.q;
        }

        Request.get('web/publish-list').query(query).exec().then(res => {
            let results = res.body.results.map(pubList => {
                pubList.items = [];
                return pubList;
            });

            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.SUCCESS,
                offset: offset,
                publishingLists: Immutable.fromJS(results),
                size: size,
                total: parseInt(res.body.totalCount, 10),
            });

            return;
        }).then(() => {
            PreloaderActions.hide(preloaderSource);

            return;
        }).catch(err => {
            PreloaderActions.hide(preloaderSource);

            switch (err.status) {
            case 404:
                RouterActions.notFound();
                throw err;
            default:
                this.errorMessage(err, 'common.load-error');
            }
        });

        return;
    }

    getAssetItemCatalogs(index, assetId) {
        Request.get(`asset/${assetId}/catalog`).exec().then(res => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.ITEMS.CATALOGS.GET.SUCCESS,
                catalogs: Immutable.fromJS(res.body),
                assetId: assetId,
                index,
            });

            return;
        });

        return;
    }

    getItems(publishingListId) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ITEMS.GET.START,
            id: publishingListId,
        });

        let listItems = [];
        // We need these two arrays to get the thumbnails.
        let videoThumbnailIds = [];

        Request.get(`web/publish-list/${publishingListId}/items`).query({'load-urls': true}).exec().then(listItemsRes => {
            let requests = {};
            // Save all items:
            listItems = listItemsRes.body;

            // Iterate all items, and request the titles and assets.
            listItems.forEach(item => {
                let itemReq;

                switch (item.targetType) {
                case CONSTANTS.TARGET_TYPES.ASSET:
                    let assetType = item.displayAssetTypeName.toLowerCase();
                    // FIXME: will the server return Document instead of "Press Release"?
                    const isDocument = ['image', 'video', 'audio', 'merchandise'].indexOf(assetType) === -1;
                    if (isDocument) {
                        assetType = 'document';
                    }
                    itemReq = Promise.all([
                        Request.get(`asset/${assetType}/${item.assetId}`).exec(),
                        Request.get(`asset/${item.assetId}/title`).exec(),
                    ]).spread((assetRes, assetTitlesRes) => {
                        const asset = assetRes.body;
                        let titleReq = Promise.resolve(
                            {body: {results: []}});
                        if (Array.isArray(assetTitlesRes.body) &&
                            assetTitlesRes.body.length) {
                            asset.parentTitleId = assetTitlesRes.body[0].titleId;
                            titleReq = Request.get(
                                `title?title-id=${asset.parentTitleId}`)
                                .exec();
                        }
                        return Promise.all([
                            asset,
                            titleReq,
                        ]);
                    }).spread((asset, titleRes) => {
                        if (Array.isArray(titleRes.body.results) &&
                            titleRes.body.results.length) {
                            asset.parentTitleDisplayName = titleRes.body.results[0].parentTitleDisplayName;
                        }
                        return {body: asset};
                    });
                    // Save the ids to get the thumbnails later.
                    if (assetType === 'video') {
                        videoThumbnailIds.push(item.assetId);
                    }
                    break;

                case CONSTANTS.TARGET_TYPES.TITLE:
                    // We need the title object to get the title name.
                    itemReq = Request.get(
                        `title?title-id=${item.assetId}`).exec();
                    break;

                default:
                    itemReq = Request.get(
                        `web/publish-list/${publishingListId}/item/${item.id}/user-group`)
                        .exec();
                    break;
                }
                requests[item.id.toString()] = itemReq;

                return;
            });

            return Promise.props(requests);
        }).then(itemDetailsRes => {
            // Start requests as a map so that we don't request multiple times the
            // same group. It will be transformed to an array to be passed to Promise.all()
            // at the end of this method.
            let requests = {};

            // itemDetailsRes is an object where the keys are the item id's and
            // the values are the promises containing the detail for each item.
            listItems = listItems.map(item => {
                let itemDetail = itemDetailsRes[item.id.toString()].body;
                if (item.assetId) {
                    // If item has an assetId it can be an asset or a title, so look in itemDetailsRes for
                    // the API response that contains what we want.
                    if (itemDetail.results &&
                        Array.isArray(itemDetail.results)) {
                        item.asset = itemDetail.results[0];
                    } else {
                        item.asset = itemDetail;
                    }
                } else {
                    item.groups = itemDetail;
                    // If groups, then iterate and get the group object.
                    item.groups.forEach(g => {
                        let id = g.userGroupId.toString();
                        if (!requests[id]) {
                            requests[id] = Request.get(
                                `security/group/${g.userGroupId}`).exec();
                        }

                        return;
                    });
                    item.userGroups = [];
                }

                return item;
            });

            return Promise.all(Object.keys(requests).map(id => requests[id]));
        }).then(groupsRes => {
            // Add the complete group object to each publishing list item.
            groupsRes.forEach(res => {
                let group = res.body;
                listItems.forEach(item => {
                    if (item.groups) {
                        item.groups.forEach(g => {
                            if (g.userGroupId === group.id) {
                                item.userGroups.push(group);
                            }

                            return;
                        });
                    }

                    return;
                });
            });

            Dispatcher.dispatch({
                actionType: CONSTANTS.ITEMS.GET.SUCCESS,
                // The last map is for old data, previously the items start to count from 1
                // this is terrible... so we sort by displayOrder and re-enumerate the values.
                items: Immutable.fromJS(listItems)
                    .sortBy(item => item.get('displayOrder'))
                    .map((item, i) => item.set('displayOrder', i)),
            });

            if (videoThumbnailIds.length > 0) {
                return Request.get('asset/video/thumbnailURL').query({
                    'video-id': videoThumbnailIds,
                }).exec();
            }

            return;
        }).then(videoThumbnailsRes => {
            if (!videoThumbnailsRes) {return;}

            Dispatcher.dispatch({
                actionType: CONSTANTS.ITEMS.THUMBNAILS.GET.SUCCESS,
                thumbnails: videoThumbnailsRes.body.reduce((r, vt) => {
                    r[vt.videoId.toString()] = vt;
                    return r;
                }, {}),
            });

            return;
        }).catch(error => {
            this.errorMessage(error, 'publishing-list.failed.load-items');
        });

        return;
    }

    getTitleItemCatalogs(index, titleId) {
        Request.get(`title/${titleId}/catalog`).exec().then(res => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.ITEMS.CATALOGS.GET.SUCCESS,
                catalogs: Immutable.fromJS(res.body),
                assetId: titleId,
                index,
            });

            return;
        });

        return;
    }

    goToItemsPage(pageNumber) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ITEMS.GO_TO_PAGE,
            pageNumber,
        });

        return;
    }

    hide() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.HIDE_DETAIL,
        });
    }

    moveElement(from, to) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ITEMS.MOVE,
            from: from,
            to: to,
        });

        return;
    }

    save(
        list, originalList, items, originalItems, options, publishingListImage,
        publishingListLocalized, originalPublishingListLocalized,
        listItemsLocalized, originalListItemsLocalized) {

        let listData = this.cleanList(list);
        let id = listData.id;

        if (
            publishingListLocalized !== originalPublishingListLocalized ||
            listItemsLocalized !== originalListItemsLocalized
        ) {
            PublishingListLocalizedActions.save(id, publishingListLocalized,
                originalPublishingListLocalized, listItemsLocalized,
                originalListItemsLocalized);
        }

        if (
            list === originalList &&
            items === originalItems &&
            !publishingListImage &&
            publishingListLocalized === originalPublishingListLocalized &&
            listItemsLocalized === originalListItemsLocalized
        ) {
            // No changes, move on...
            return;
        }

        // Create Default Messages
        let defaults = {
            messages: {
                error: 'publishing-list.save.error',
                success: 'publishing-list.save.success',
            },
        };
        Object.assign(defaults, options);

        const preloaderSource = 'publishing-list-actions.save';
        PreloaderActions.show(preloaderSource);

        // Assume POST.
        let method = Request.post;
        let uri = 'web/publish-list';
        // Check if PUT.
        if (id !== undefined) {
            method = Request.put;
            uri = `web/publish-list/${id}`;
        }

        method(uri).send(listData).exec().then(res => {
            id = res.body.id;
            Dispatcher.dispatch({
                actionType: CONSTANTS.SAVE.SUCCESS,
                id: id,
            });

            return this.saveListItems(id, items, originalItems);
        }).then(() => {
            let del = Promise.resolve();

            if (list.get('imageUrl') && publishingListImage) {
                del = Request.del(`web/publish-list/${id}/image`).exec();
            }

            return del.then(() => {
                if (!publishingListImage) {
                    return;
                }
                return UploadFile(
                    'POST',
                    `web/publish-list/${id}/image`,
                    publishingListImage,
                    new XMLHttpRequest(),
                ).then(uploadRes => {
                    uploadRes = JSON.parse(uploadRes);
                    let imageS3Path = uploadRes.imageS3Path;
                    let imageUrl = uploadRes.imageUrl;
                    Dispatcher.dispatch({
                        actionType: CONSTANTS.IMAGE.SAVE.SUCCESS,
                        imageS3Path,
                        imageUrl,
                    });
                });
            });
        }).then(() => {
            PreloaderActions.hide(preloaderSource);
            RouterActions.redirect(`/publishing-list/${id}`, true);
            NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, defaults.messages.success);
            this.clearItems();

            return;
        }).catch(err => {
            PreloaderActions.hide(preloaderSource);
            this.errorMessage(err, defaults.messages.error);
        });

        return;
    }

    saveListItems(publishingListId, items, originalItems) {
        if (items === originalItems) {
            return;
        }

        let itemData = this.cleanItems(items);
        // Send the post even if we have an empty array.
        return Request.put(`web/publish-list/${publishingListId}/items`).send(itemData).exec().then(res => {
            return Promise.all(this.saveListItemFiles(publishingListId, items, res.body, originalItems));
        });
    }

    // items are the items in the browser before saving
    // updItems are the items saved by the API (new items include id)
    saveListItemFiles(publishingListId, listItems, updItems, originalItems) {
        // Make sure both lists are sorted by display order
        updItems = Immutable.fromJS(updItems)
            .sortBy(r => r.get('displayOrder'));
        listItems = listItems.sortBy(r => r.get('displayOrder'));

        // Iterate the items and return an array of promises.
        return listItems.map((item, index) => {
            let imageFile = item.get('newFile');
            let videoFile = item.get('newVideoFile');
            let itemId = updItems.get(index).get('id');
            let originalItem = originalItems.find(i => i.get('id') === itemId);
            if (item === originalItem) {
                return Promise.resolve();
            }
            // Create a dummy promise in case is not needed to delete a previous file.
            let updateFileRequest = Promise.resolve();
            let updateVideoFileRequest = Promise.resolve();
            let deleteImageRequest = Promise.resolve();
            if (
                originalItem &&
                (
                    (originalItem.get('imageS3Path') &&
                        !item.get('imageS3Path') || imageFile)
                )
            ) {
                deleteImageRequest = Request.del(
                    `web/publish-list/${publishingListId}/items/${itemId}/image`)
                    .exec()
                    .catch(() => {
                        console.error(
                            `Error on deleting the file for item ${itemId}, this is optional, keep processing.`);
                    });
            }

            if (imageFile) {
                updateFileRequest = deleteImageRequest.then(() => {
                    return UploadFile(
                        'POST',
                        `web/publish-list/${publishingListId}/items/${itemId}/image`,
                        imageFile,
                        new XMLHttpRequest(),
                    );
                });
            }

            let deleteVideoRequest = Promise.resolve();
            if (
                originalItem &&
                (
                    (originalItem.get('videoS3Path') &&
                        !item.get('videoS3Path')) || videoFile
                )
            ) {
                deleteVideoRequest = Request.del(
                    `web/publish-list/${publishingListId}/items/${itemId}/video`)
                    .exec()
                    .catch(() => {
                        console.error(
                            `Error on deleting the file for item ${itemId}, this is optional, keep processing.`);
                    });
            }

            if (videoFile) {
                updateVideoFileRequest = deleteVideoRequest.then(() => {
                    return UploadFile(
                        'POST',
                        `web/publish-list/${publishingListId}/items/${itemId}/video?hasAudio=${item.get('hasAudio')}`,
                        videoFile,
                        new XMLHttpRequest(),
                    );
                });
            }

            let groups = item.get('userGroups');
            let groupsRequest = Promise.resolve();
            if (groups) {
                let plGroups = groups.map(g => {
                    let groupId = g.get('id');
                    if (!groupId) {
                        groupId = g.get('userGroupId');
                    }
                    return {
                        userGroupId: groupId,
                        publishListId: publishingListId,
                        publishListItemId: itemId,
                    };
                }).toJS();

                groupsRequest = Request.put(`web/publish-list/${publishingListId}/item/${itemId}/user-group`).send(plGroups).exec();
            }

            // Return one promise for the whole array.
            return Promise.all([
                updateFileRequest, updateVideoFileRequest, groupsRequest
            ]);
        }).toJS();
    }

    setFilter(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.FILTER.SET,
            attr,
            value
        });
    }

    setItem(index) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ITEMS.EDIT.SET,
            index,
        });

        return;
    }

    setItemUserGroups(groups) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ITEMS.EDIT.SET_GROUPS,
            groups,
        });

        return;
    }

    show() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SHOW_DETAIL,
        });
    }

    update(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.UPDATE,
            attr: attr,
            value: value,
        });

        return;
    }

    updateAttr(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.UPDATE_ATTR,
            attr: attr,
            value: value,
        });
    }

    updateListItem(item, refreshThumbnail) {
        let thumbnailPromise = Promise.resolve(item);

        // Get the thumbnail if needed.
        if (refreshThumbnail) {

            switch (item.get('targetType')) {
            case CONSTANTS.TARGET_TYPES.ASSET:
                let assetType = item.get('displayAssetTypeName').toLowerCase();
                if (assetType === 'image') {
                    thumbnailPromise = Request.get(
                        `asset/image/${item.get('assetId')}`).exec().then(
                        res => item.set('asset', Immutable.fromJS(res.body)),
                    );
                }
                if (assetType === 'video') {
                    thumbnailPromise = Request.get('asset/video/thumbnailURL').query({'video-id': item.get('assetId')}).exec().then(res => {
                        if (
                            res.body.length &&
                            res.body[0].thumbnailList.length
                        ) {
                            item = item.setIn(
                                ['asset', 'thumbnailUrl'],
                                res.body[0].thumbnailList[0].thumbnailUrl
                            );
                        }
                        return item;
                    });
                }
                break;

            case CONSTANTS.TARGET_TYPES.TITLE:
                // We need the title object to get the title name.
                thumbnailPromise = Request.get(`title/${item.get('assetId')}`).exec().then(
                    res => item.set('asset', Immutable.fromJS(res.body))
                );
                break;
            }
        }

        thumbnailPromise.then(
            itemWithThumbnail => Dispatcher.dispatch({
                actionType: CONSTANTS.ITEMS.EDIT.SAVE,
                item: itemWithThumbnail,
            }),
        );

        return;
    }

    updateListItemAttr(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ITEMS.EDIT.UPDATE_ATTR,
            attr: attr,
            value: value,
        });

        return;
    }
}

const actions = new PublishingListActions();

export {
    actions as PublishingListActions,
    CONSTANTS as PublishingListConstants
};
