/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

import Table from './table';
import DocumentTitle from '../../../common/document-title';
import {WithStoreOnRoute} from '../../../common/store-on-route';
import {LayoutActions} from '../../../layout/layout-actions';
import LayoutStore from '../../../layout/layout-store';
import Preloader from '../../../preloader';
import SessionStore from '../../../user/session/session-store';
import FilterOptions from '../filter-options';
import {ProcessesActions} from '../processes-actions';
import ProcessesStore from '../processes-store';

class Browse extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            showFiltersPanel: LayoutStore.getState().get('showFiltersPanel'),
            processes: ProcessesStore.getState().get('processes'),
            total: ProcessesStore.getState().get('total'),
            showPreloader: ProcessesStore.getState().get('showPreloader'),
        };
    }

    static getStores() {
        return [LayoutStore, ProcessesStore];
    }

    static getPermissions() {
        return {
            canBrowseProcesses: SessionStore.canUser(SessionStore.PERMISSIONS.HARDAC.PROCESSES.BROWSE),
            canReadProcesses: SessionStore.canUser(SessionStore.PERMISSIONS.HARDAC.PROCESSES.READ)
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();
        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleToggleFiltersPanel = this.handleToggleFiltersPanel.bind(this);
    }

    componentDidMount() {
        ProcessesActions.clear();
        if (this.props.location.query.processed) {
            ProcessesActions.get(this.getRouteState());
            return;
        }
        this.setRouteState('processed', 'false').apply();
        return;
    }

    componentWillUpdate(nextProps) {
        if (this.props.location !== nextProps.location) {
            ProcessesActions.get(this.getRouteState(nextProps));
        }
        return;
    }

    /**
     * Handle clicks on pagination controls.
     * @param  {Number} pageNumber page to load.
     */
    handleLoadPage(pageNumber) {
        // pageNumber x this.state.size = offset.
        this.setRouteState('offset', pageNumber * 20)
            .apply();
        return;
    }

    handleToggleFiltersPanel() {
        LayoutActions.toggleFiltersPanel();
        return;
    }

    render() {
        return (
            <DocumentTitle
                message="hardac.processes.title"
            >
                <div className={ClassNames({'control-sidebar-open': this.state.showFiltersPanel})}>
                    <div className={ClassNames({'sidebar-opened': this.state.showFiltersPanel})}>
                        <Preloader show={this.state.showPreloader} fixed loadingDots>
                            <section className="content-header">
                                <h1>
                                    <i className="fas fa-tasks-alt"></i>
                                    &nbsp;
                                    {this.context.intl.messages['hardac.processes.title']}
                                    &nbsp;
                                    <small>{this.state.count}</small>
                                </h1>
                            </section>
                            <section className="content">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="container-fluid no-x-padding">
                                            <div className="row">
                                                <div className="col-md-6 show-grid" />
                                                <div className="col-md-6 show-grid">
                                                    <Button onClick={this.handleToggleFiltersPanel} bsSize="large" className="btn btn-primary-outline pull-right">
                                                        <i className="fas fa-sliders-h"></i>&nbsp;{this.context.intl.messages['common.filteringOptions']}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container-fluid no-x-padding">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="box">
                                                        <Table
                                                            activePage={Math.ceil((this.getRouteState().get('offset') || 0) / 20 ) || 0}
                                                            displayLinks={this.props.permissions.canReadProcesses}
                                                            onPageChange={this.handleLoadPage}
                                                            processes={this.state.processes}
                                                            totalPages={Math.ceil(this.state.total/20) || 0}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <FilterOptions
                                location={this.props.location}
                            />
                        </Preloader>
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}

export default WithStoreOnRoute(Container.create(Browse));
