/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Modal} from 'react-bootstrap';

import {Event} from '../../common/side-notes/side-notes';

export default class ShowModal extends React.Component {

    static get propTypes() {
        return {
            onClose: PropTypes.func.isRequired,
            show: PropTypes.bool.isRequired,
            historyItem: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.onClose = this.onClose.bind(this);
        return;
    }

    onClose() {
        this.props.onClose();
    }

    render() {
        let historyDetail;
        if (this.props.historyItem) {
            historyDetail = (
                <Event displayFull={true} key={this.props.historyItem.get('id')}
                    note={this.props.historyItem.toJS()} last={false}/>
            );
        }

        return (
            <Modal show={this.props.show} onHide={this.onClose}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="modal-title text-center text-uppercase">{this.context.intl.messages['history.browse.detail']}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {historyDetail}
                </Modal.Body>
                <Modal.Footer className=" padding-all-20">
                    <Button bsStyle="default" className="pull-right" onClick={this.onClose}>
                        <i className="fas fa-times-circle"></i>&nbsp;{this.context.intl.messages['common.close']}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
