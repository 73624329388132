/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {LanguageCodeType} from '@wbdt-sie/brainiac-web-common';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, ControlLabel, FormGroup, Modal} from 'react-bootstrap';
import {Link} from 'react-router';
import Select from 'react-select';

import {FormItem, FormRow, FormSection, LocalizedFormItem} from '../../../common/form/form';
import {SectionPanel} from '../../../common/panel/panel';
import {LocalizedTinyMCE} from '../../../common/tinymce/tinymce';
import {CompareImmutable, GetAttr} from '../../../common/utils/utils';
import {WithValidations} from '../../../common/validations/validations';
import {TitleActions, TitleConstants} from '../../title-actions';
import {TitleLocalizedActions} from '../../title-localized-actions';
import {TitleValidations} from '../../title-store';

/**
 * Title props to watch.
 */
const titleProps = [
    'log60Lines', 'log180Lines',
    'shortSynopsis', 'synopsis'
];

class SynopsisAndLogLinesForm extends Component {
    static get propTypes() {
        return {
            disabled: PropTypes.bool,
            localized: PropTypes.bool,
            title: PropTypes.instanceOf(Immutable.Map).isRequired,
            titleLocalized: PropTypes.instanceOf(Immutable.Map).isRequired,
            synopsisType: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true,
            disabledHideSynopsisLogLines: true,
            localized: false
        };
    }

    constructor(props) {
        super(props);

        this.handleTinyMCEChange = this.handleTinyMCEChange.bind(this);
    }

    handleTinyMCEChange(f, attr) {
        return function handler(e) {
            f(attr, e.target.getContent());
        };
    }

    render() {
        let typeMessage = `titles.create.summary.synopsis-type.${this.props.synopsisType.id.toLowerCase()}`;
        let helpLink;
        if (this.props.synopsisType.id === TitleConstants.SYNOPSIS_TYPES.DEFAULT.id) {
            helpLink = <Link title={this.context.intl.messages['common.learn-more']} target="_blank" to="/titles/help"><i className="fa-solid fa-question-circle" /></Link>;
        }

        let log180Lines = this.props.title.get('log180Lines') || '';
        let log60Lines = this.props.title.get('log60Lines') || '';
        let labelLog180Lines;
        if (log180Lines.length >= 0) {
            labelLog180Lines = (
                <div>
                    {this.context.intl.messages[typeMessage]} {this.context.intl.messages['titles.create.summary.log-180-lines']} ({log180Lines.length} {this.context.intl.messages['titles.create.summary.log-180-lines.limit']}) {helpLink}
                </div>
            );
        }

        let labelLog60Lines;
        if (log60Lines.length >= 0) {
            labelLog60Lines = (
                <div>
                    {this.context.intl.messages[typeMessage]} {this.context.intl.messages['titles.create.summary.log-60-lines']} ({log60Lines.length} {this.context.intl.messages['titles.create.summary.log-60-lines.limit']})
                </div>
            );
        }

        let f = TitleActions.updateTitle;
        if (this.props.localized) {
            f = TitleLocalizedActions.update;
        }

        let synopsisForm = <div />;
        let synopsisAttr;

        let shortSynopsisForm = <div />;
        let shortSynopsisAttr;

        let log180LinesForm = <div />;
        let log180LinesAttr;

        let log60LinesForm = <div />;
        let log60LinesAttr;

        let synopsisIndex = -1;

        let title = this.props.title;
        if (this.props.titleLocalized) {
            title = this.props.titleLocalized;
        }

        switch (this.props.synopsisType.id) {
        case TitleConstants.SYNOPSIS_TYPES.AWARDS.id:
            synopsisIndex = title?.toJS().synopsisValues.findIndex(s => s.usageType === this.props.synopsisType.id);
            if (synopsisIndex !== -1) {
                synopsisAttr = 'synopsisValues.'+synopsisIndex+'.synopsis';
                synopsisForm = <FormRow>
                    <LocalizedTinyMCE
                        content={this.props.title.getIn(synopsisAttr.split('.')) || ''}
                        disabled={this.props.disabled}
                        id={'synopsis-'+synopsisIndex}
                        onChange={this.handleTinyMCEChange(f, synopsisAttr)}
                        resize={true}
                        localized={this.props.localized}
                        localizedContent={title.getIn(synopsisAttr.split('.')) || ''}
                        title={`${this.context.intl.messages[typeMessage]} ${this.context.intl.messages['titles.create.summary.synopsis']}`}
                        wordCount={false}
                    />
                </FormRow>;
            } else {return <div />;}
            break;
        case TitleConstants.SYNOPSIS_TYPES.AIRLINE.id:
        case TitleConstants.SYNOPSIS_TYPES.SCREENER.id:
        case TitleConstants.SYNOPSIS_TYPES.TEMPORARY.id:
            synopsisIndex = title?.toJS().synopsisValues.findIndex(s => s.usageType === this.props.synopsisType.id);
            if (synopsisIndex !== -1) {
                synopsisAttr = 'synopsisValues.'+synopsisIndex+'.synopsis';
                synopsisForm = <FormRow>
                    <LocalizedTinyMCE
                        content={this.props.title.getIn(synopsisAttr.split('.')) || ''}
                        disabled={this.props.disabled}
                        id={'synopsis-'+synopsisIndex}
                        onChange={this.handleTinyMCEChange(f, synopsisAttr)}
                        resize={true}
                        localized={this.props.localized}
                        localizedContent={title.getIn(synopsisAttr.split('.')) || ''}
                        title={`${this.context.intl.messages[typeMessage]} ${this.context.intl.messages['titles.create.summary.synopsis']}`}
                        wordCount={false}
                    />
                </FormRow>;

                shortSynopsisAttr = 'synopsisValues.'+synopsisIndex+'.shortSynopsis';
                shortSynopsisForm = <FormRow>
                    <LocalizedTinyMCE
                        content={this.props.title.getIn(shortSynopsisAttr.split('.')) || ''}
                        disabled={this.props.disabled}
                        id={'short-synopsis-'+synopsisIndex}
                        onChange={this.handleTinyMCEChange(f, shortSynopsisAttr)}
                        resize={true}
                        localized={this.props.localized}
                        localizedContent={title.getIn(shortSynopsisAttr.split('.')) || ''}
                        title={`${this.context.intl.messages[typeMessage]} ${this.context.intl.messages['titles.create.summary.short-synopsis']}`}
                        wordCount={true}
                    />
                </FormRow>;

                log180LinesAttr = 'synopsisValues.'+synopsisIndex+'.log180Lines';
                log180LinesForm = <FormRow>
                    <LocalizedFormItem
                        attr={log180LinesAttr}
                        label={labelLog180Lines}
                        disabled={this.props.disabled}
                        model={this.props.title}
                        setter={TitleActions.updateTitle}
                        type="text"
                        localized={this.props.localized}
                        localizedModel={this.props.titleLocalized}
                        localizedSetter={TitleLocalizedActions.update}
                    />
                </FormRow>;

                log60LinesAttr = 'synopsisValues.'+synopsisIndex+'.log60Lines';
                log60LinesForm = <FormRow>
                    <LocalizedFormItem
                        attr={log60LinesAttr}
                        label={labelLog60Lines}
                        disabled={this.props.disabled}
                        model={this.props.title}
                        onChange={this.counterCharacter}
                        setter={TitleActions.updateTitle}
                        type="text"
                        localized={this.props.localized}
                        localizedModel={this.props.titleLocalized}
                        localizedSetter={TitleLocalizedActions.update}
                    />
                </FormRow>;
            } else {return <div />;}
            break;
        case TitleConstants.SYNOPSIS_TYPES.DEFAULT.id:
        default:
            synopsisAttr = 'synopsis';
            synopsisForm = <FormRow>
                <LocalizedTinyMCE
                    content={this.props.title.getIn(synopsisAttr.split('.')) || ''}
                    disabled={this.props.disabled}
                    id={'synopsis-'+synopsisIndex}
                    onChange={this.handleTinyMCEChange(f, synopsisAttr)}
                    resize={true}
                    localized={this.props.localized}
                    localizedContent={title.getIn(synopsisAttr.split('.')) || ''}
                    helpElement={helpLink}
                    title={`${this.context.intl.messages[typeMessage]} ${this.context.intl.messages['titles.create.summary.synopsis']}`}
                    wordCount={false}
                />
            </FormRow>;

            shortSynopsisAttr = 'shortSynopsis';
            shortSynopsisForm = <FormRow>
                <LocalizedTinyMCE
                    content={this.props.title.getIn(shortSynopsisAttr.split('.')) || ''}
                    disabled={this.props.disabled}
                    id={'short-synopsis-'+synopsisIndex}
                    onChange={this.handleTinyMCEChange(f, shortSynopsisAttr)}
                    resize={true}
                    localized={this.props.localized}
                    localizedContent={title.getIn(shortSynopsisAttr.split('.')) || ''}
                    title={`${this.context.intl.messages[typeMessage]} ${this.context.intl.messages['titles.create.summary.short-synopsis']}`}
                    wordCount={true}
                />
            </FormRow>;

            log180LinesAttr = 'log180Lines';
            log180LinesForm = <FormRow>
                <LocalizedFormItem
                    attr={log180LinesAttr}
                    label={labelLog180Lines}
                    disabled={this.props.disabled}
                    model={this.props.title}
                    setter={TitleActions.updateTitle}
                    type="text"
                    localized={this.props.localized}
                    localizedModel={this.props.titleLocalized}
                    localizedSetter={TitleLocalizedActions.update}
                />
            </FormRow>;

            log60LinesAttr = 'log60Lines';
            log60LinesForm = <FormRow>
                <LocalizedFormItem
                    attr={log60LinesAttr}
                    label={labelLog60Lines}
                    disabled={this.props.disabled}
                    model={this.props.title}
                    onChange={this.counterCharacter}
                    setter={TitleActions.updateTitle}
                    type="text"
                    localized={this.props.localized}
                    localizedModel={this.props.titleLocalized}
                    localizedSetter={TitleLocalizedActions.update}
                />
            </FormRow>;
            break;
        }

        return <FormSection>
            {synopsisForm}
            {shortSynopsisForm}
            {log180LinesForm}
            {log60LinesForm}
        </FormSection>;
    }
}

class SynopsisAndLogLines extends Component {

    static get propTypes() {
        return {
            currentLanguage: PropTypes.string.isRequired,
            disabled: PropTypes.bool,
            expanded: PropTypes.bool,
            mode: PropTypes.oneOf(['create', 'edit']),
            title: PropTypes.instanceOf(Immutable.Map).isRequired,
            titleLocalized: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true,
            expanded: true,
            mode: 'create'
        };
    }

    static calculateState() {
        return {
            selectedSynopsisValue: null,
            showAddSynopsisModal: false
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.handleAddSynopsis = this.handleAddSynopsis.bind(this);
        this.handleCloseAddSynopsisModal = this.handleCloseAddSynopsisModal.bind(this);
        this.handleSelectNewSynopsisOption = this.handleSelectNewSynopsisOption.bind(this);
        this.handleShowAddSynopsisModal = this.handleShowAddSynopsisModal.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.disabled !== this.props.disabled ||
            nextProps.titleLocalized !== this.props.titleLocalized || // actually have to check if name or selected language has changed
            nextProps.currentLanguage !== this.props.currentLanguage ||
            nextProps.expanded !== this.props.expanded ||
            !CompareImmutable(nextProps.title, this.props.title, titleProps) ||
            nextState.showAddSynopsisModal !== this.state.showAddSynopsisModal ||
            nextState.selectedSynopsisValue !== this.state.selectedSynopsisValue ||
            !nextProps.title.get('synopsisValues').equals(this.props.title.get('synopsisValues'))) {
            return true;
        }

        return false;
    }

    handleAddSynopsis() {
        TitleActions.addSynopsis(this.state.selectedSynopsisValue.id, this.props.title.get('id'));
        this.setState({
            selectedSynopsisValue: null
        });
        this.handleCloseAddSynopsisModal();
    }

    handleCloseAddSynopsisModal() {
        this.setState({
            showAddSynopsisModal: false
        });
    }

    handleSelectNewSynopsisOption(value) {
        this.setState({
            selectedSynopsisValue: value
        });
    }

    handleShowAddSynopsisModal() {
        this.setState({
            showAddSynopsisModal: true
        });
    }

    render() {
        let log180Lines = this.props.title.get('log180Lines') || '';
        let log60Lines = this.props.title.get('log60Lines') || '';

        let localized = this.props.currentLanguage !== LanguageCodeType.DEFAULT_LANGUAGE;

        const createMode = this.props.mode === 'create';
        const emptySynopsisLogLines = !this.props.title.get('synopsis') && !this.props.title.get('shortSynopsis') && !log180Lines && !log60Lines;
        let addNewSynopsis = <div />;
        if (!localized) {
            addNewSynopsis = <FormSection>
                <FormRow>
                    <FormGroup md={4} />
                    <FormGroup md={4}>
                        <Button
                            bsStyle="primary"
                            className="btn-lg btn-block"
                            onClick={this.handleShowAddSynopsisModal}
                        >
                            <i className="fas fa-plus"/>&nbsp;Add Synopsis
                        </Button>
                    </FormGroup>
                </FormRow>
            </FormSection>;
        }
        const disabledHideSynopsisLogLines = createMode || emptySynopsisLogLines || this.props.disabled || localized;
        const getSynopsisComponent = (type) => {
            return <SynopsisAndLogLinesForm
                disabled={this.props.disabled}
                localized={localized}
                title={this.props.title}
                titleLocalized={this.props.titleLocalized}
                synopsisType={type}
            />;
        };
        const defaultSynopsisAndLogLines = getSynopsisComponent(TitleConstants.SYNOPSIS_TYPES.DEFAULT);
        const airlineSynopsisAndLogLines = getSynopsisComponent(TitleConstants.SYNOPSIS_TYPES.AIRLINE);
        const awardsSynopsisAndLogLines = getSynopsisComponent(TitleConstants.SYNOPSIS_TYPES.AWARDS);
        const screenerSynopsisAndLogLines = getSynopsisComponent(TitleConstants.SYNOPSIS_TYPES.SCREENER);
        const temporarySynopsisAndLogLines = getSynopsisComponent(TitleConstants.SYNOPSIS_TYPES.TEMPORARY);

        const synopsisComponents = [
            airlineSynopsisAndLogLines,
            awardsSynopsisAndLogLines,
            screenerSynopsisAndLogLines,
            temporarySynopsisAndLogLines
        ];

        return (
            <div>
                <Modal show={this.state.showAddSynopsisModal} onHide={this.handleCloseAddSynopsisModal}>
                    <Modal.Header className="bg-gray" closeButton>
                        <Modal.Title className="text-center">{this.context.intl.messages['titles.create.summary.synopsis.modal.title']}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['titles.create.summary.synopsis.modal.label']}</ControlLabel>
                            <Select
                                getOptionLabel={GetAttr('name')}
                                getOptionValue={GetAttr('id')}
                                isClearable={true}
                                isMulti={false}
                                name="synopsisType"
                                onChange={this.handleSelectNewSynopsisOption}
                                options={TitleConstants.toJSArray('SYNOPSIS_TYPES').filter(op => {
                                    return (op.id !== TitleConstants.SYNOPSIS_TYPES.DEFAULT.id &&
                                        !this.props.title.get('synopsisValues').find(sv => {
                                            return sv.get('usageType') === op.id;
                                        }));
                                })}
                                value={this.state.selectedSynopsisValue}
                            />
                        </FormGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            className="btn btn-default-outline Mr(3px) Mb(3px) pull-left"
                            onClick={this.handleCloseAddSynopsisModal}
                        >
                            <i className="fas fa-times"/>&nbsp;{this.context.intl.messages['common.cancel']}
                        </Button>
                        <Button
                            className="btn btn-primary-outline Mr(3px) Mb(3px) pull-right"
                            onClick={this.handleAddSynopsis}
                        >
                            <i className="fas fa-check"/>&nbsp;{this.context.intl.messages['titles.create.summary.synopsis.modal.confirm']}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <SectionPanel
                    title={this.context.intl.messages['titles.create.summary.synopsis-and-log-lines']}
                    validationState={this.getAccordionValidationState(this.props.title, titleProps, TitleValidations)}
                    expanded={this.props.expanded}
                    localized={localized}
                >
                    <FormSection>
                        <FormRow>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['titles.create.summary.hide-synopsis-log-lines']}</ControlLabel>
                                <FormItem
                                    attr="hideSynopsisLogLines"
                                    label={this.context.intl.messages['titles.create.summary.hide-synopsis-log-lines.description']}
                                    disabled={disabledHideSynopsisLogLines}
                                    model={this.props.title}
                                    setter={TitleActions.updateTitle}
                                    type="checkbox"
                                />
                                <hr />
                                <p><em>{this.context.intl.messages['titles.create.summary.hide-synopsis-log-lines.note']}</em></p>
                            </FormGroup>
                        </FormRow>
                    </FormSection>
                    <hr/>
                    {defaultSynopsisAndLogLines}
                    {synopsisComponents}
                    {addNewSynopsis}
                </SectionPanel>
            </div>
        );
    }
}

export default WithValidations(SynopsisAndLogLines);
export {SynopsisAndLogLinesForm};
