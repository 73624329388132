/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

import {AlertTypes} from '../../common/notification/alert';
import Dispatcher from '../../dispatcher/dispatcher';
import {PreloaderActions} from '../../preloader/preloader-actions';
import Request from '../../request';
import {NotificationActions} from '../notification/notification-actions';

const CONSTANTS = {
    CLEAR: 'history-actions.clear',
    GET: {
        ERROR: 'history-actions.get.error',
        START: 'history-actions.get.start',
        SUCCESS: 'history-actions.get.success'
    },
    ACTION_OBJECT_TYPE_DATA: {
        AssetToCatalog: {displayName: 'Catalog', icon: 'far fa-book'},
        Authenticated: {},
        AssetToTalent: {},
        AssetToTitle: {displayName: 'Asset in Title', icon: 'far fa-file-image'},
        AssetCatalogIngestHeader: {},
        AssetCatalogIngestRow: {},
        AssetCatalogIngestGroup: {},
        Audio: {displayName: 'Audio', icon: 'fas fa-volume-up'},
        ClientRepGroup: {displayName: 'Client Rep Group', icon: 'fas fa-user-secret'},
        Document: {displayName: 'Document', icon: 'fas fa-file-alt'},
        DomainWhitelist: {},
        DownloadContent: {displayName: 'Content'},
        FunctionPermission: {},
        Group: {displayName: 'Group', icon: 'fas fa-users'},
        HomePage: {displayName: 'Homepage', icon: 'fas fa-home'},
        HomePagePartner: {},
        HomeScreen: {displayName: 'Homescreen', icon: 'fas fa-desktop'},
        HomeScreenGroup: {},
        HomeScreenPartner: {},
        HomeScreenPublishList: {},
        Image: {displayName: 'Image', icon: 'fas fa-camera'},
        Merchandise: {displayName: 'Merchandise', icon: 'fas fa-tag'},
        Order: {},
        Partner: {},
        PermissionPackage: {},
        PrivacyRequest: {displayName: 'Privacy Request'},
        PublishList: {displayName: 'Publishing List', icon: 'far fa-list-alt'},
        PublishListItem: {displayName: 'Publishing List Item', icon: 'far fa-list-alt'},
        PublishListItemLocalized: {},
        PublishListItemGroup: {displayName: 'Publishing List Item Group', icon: 'far fa-list-alt'},
        PublishListLocalized: {},
        RelatedTitle: {displayName: 'Related Title', icon: 'far fa-newspaper'},
        Role: {displayName: 'Role', icon: 'fas fa-male'},
        RoleCriteria: {},
        ScheduleBatch: {displayName: 'Schedule Batch', icon: 'far fa-stopwatch'},
        Script: {displayName: 'Script', icon: 'far fa-file-video'},
        Session: {},
        SubmitOrderRequest: {},
        Talent: {displayName: 'Talent', icon: 'fas fa-star'},
        TalentAka: {},
        TalentTitle: {displayName: 'Title', icon: 'far fa-newspaper'},
        Title: {displayName: 'Title', icon: 'far fa-newspaper'},
        TitleCatalogIngestHeader: {},
        TitleCatalogIngestRow: {},
        TitleCatalogIngestGroup: {},
        TitleIngestHeader: {displayName: 'Title Ingest Header', icon: 'far fa-newspaper'},
        TitleIngestRow: {displayName: 'Title Ingest Header Row', icon: 'far fa-newspaper'},
        TitleLocalized: {},
        TitleMetaDataStatus: {},
        TitleRelease: {displayName: 'Title Release', icon: 'far fa-newspaper'},
        TitleTalent: {displayName: 'Talent', icon: 'fas fa-star'},
        TitleToCatalog: {displayName: 'Catalog', icon: 'far fa-book'},
        TitleToGenre: {displayName: 'Genre', icon: 'fas fa-cog'},
        TitleToRatingReason: {},
        TitleToProductionCompany: {displayName: 'Production Company', icon: 'fas fa-cog'},
        TitleUserSubscription: {displayName: 'Subscription', icon: 'fas fa-user'},
        User: {displayName: 'User', icon: 'fas fa-user'},
        UserDeliveryProfile: {},
        UserDeliveryNotification: {},
        UserMassUpdate: {},
        UserIngestHeader: {},
        UserIngestRow: {},
        UserLogin: {},
        UserTitleSubscription: {displayName: 'Subscription', icon: 'fas fa-user'},
        UserToGroup: {displayName: 'Group', icon: 'fas fa-users'},
        UserToPartner: {},
        UserToRole: {displayName: 'Role', icon: 'fas fa-male'},
        UserToClientRepGroup: {displayName: 'Client Rep Group'},
        Video: {displayName: 'Video', icon: 'fas fa-video'},
        Video_Delivery: {displayName: 'Video Delivery', icon: 'fas fa-inbox-in'},
        Video_Timeline: {displayName: 'Video Timeline', icon: 'fas fa-stream'},
        VideoRights: {},
        VideoUserClip: {},
        VideoWebVtt: {},
        WatchVideo: {}
    },
    ACTION_HISTORY_TYPE_DATA: {
        'Bounce Email': {action: 'bounced email'},
        'Cascade Delete': {action: 'removed', iconColor: 'text-red'},
        'Cascade Update': {action: 'updated', iconColor: 'text-blue'},
        'Create Anonymous Acc': {action: 'created', iconColor: 'text-blue'},
        'Create Clip': {action: 'added', iconColor: 'text-green'},
        'Create Rights': {action: 'updated', iconColor: 'text-blue'},
        'Encode Started': {action: 'started'},
        'Event Lock': {action: 'tried to get lock'},
        'Group Criteria Add': {action: 'added', iconColor:'text-green'},
        'Group Criteria Delete': {action: 'removed', iconColor:'text-red'},
        'Login Link Create': {action: 'created', iconColor:'text-blue'},
        'Login Link Redeem': {action: 'updated', iconColor:'text-blue'},
        'Mass Add': {action: 'added', iconColor:'text-green'},
        'Mass Delete': {action: 'removed', iconColor:'text-red'},
        'Mass Update': {action: 'updated', iconColor:'text-blue'},
        'Notification Sent': {action: 'notified'},
        'Proxy Created': {action: 'added', iconColor:'text-green'},
        'Publishing Clip': {action: 'added', iconColor: 'text-green'},
        'Role Criteria Add': {action: 'added', iconColor:'text-green'},
        'Role Criteria Delete': {action: 'removed', iconColor:'text-red'},
        'User Banned': {action: 'banned'},
        'User Generated': {action: 'updated', iconColor: 'text-blue'},
        'User Unbanned': {action: 'unbanned'},
        'Video Migrated': {action: 'updated', iconColor:'text-blue'},
        Activate: {action: 'activated'},
        Approve: {action: 'approved'},
        Create: {action: 'added', iconColor: 'text-green'},
        Decomission: {action: 'decomissioned'},
        Delete: {action: 'removed', iconColor:'text-red'},
        Deny: {action: 'denied'},
        DenyFulfillment: {action: 'denied fulfillment'},
        Fulfill: {action: 'fulfilled'},
        Lock: {action: 'locked'},
        Download: {action: 'PrivacyRequest'},
        Request: {action: 'requested'},
        Session: {action: 'updated', iconColor:'text-blue'},
        Update: {action: 'updated', iconColor:'text-blue'},
        Web: {action: 'downloaded'},
        Replace: {action: 'replaced'}
    },
    CHANGED_FIELDS_ACTION : { //for changed fields that are in table
        Remove: {action: 'removed', iconColor: 'text-red'},
        Update: {action: 'edited', iconColor: 'text-blue'},
        Add: {action: 'added', iconColor: 'text-green'}
    }
};

class HistoryActions {
    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
    }

    get(id, actionObject, offset = 0, size = 50) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.GET.START
        });

        PreloaderActions.show('history-preloader');
        Request.get('system/action-history').query({
            'action-object': actionObject,
            'object-id': id,
            offset,
            size
        }).exec().then(res => {
            let history = Immutable.fromJS(res.body);
            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.SUCCESS,
                history: history
            });

            return;
        }).catch(err => {
            console.error(err);
            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.ERROR,
                error: err
            });

            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');

            throw err;
        }).finally(() => PreloaderActions.hide('history-preloader'));
    }
}

const actions = new HistoryActions();

export {
    actions as HistoryActions,
    CONSTANTS as HistoryConstants
};
