/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

import AbandonModal from './abandon-modal';
import RetryModal from './retry-modal';
import Table from './table';
import FilterOptions from '../filter-options';
import {JobActions} from '../job-actions';
import JobsStore from '../job-store';

import DocumentTitle from '~/src/common/document-title';
import SearchBox from '~/src/common/search-box/search-box';
import {WithStoreOnRoute} from '~/src/common/store-on-route';
import {Debounce} from '~/src/common/utils/utils';
import {LayoutActions} from '~/src/layout/layout-actions';
import LayoutStore from '~/src/layout/layout-store';
import Preloader from '~/src/preloader';
import SessionStore from '~/src/user/session/session-store';


class Browse extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            jobs: JobsStore.getState().get('jobs'),
            showAbandonModal: false,
            showFiltersPanel: LayoutStore.getState().get('showFiltersPanel'),
            showPreloader: JobsStore.getState().get('showPreloader'),
            showRetryModal: false,
            total: JobsStore.getState().get('total'),
        };
    }

    static getStores() {
        return [JobsStore, LayoutStore];
    }

    static getPermissions() {
        return {
            canBrowseJobs: SessionStore.canUser(SessionStore.PERMISSIONS.HARDAC.JOBS.BROWSE),
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.getSelectedJobs = this.getSelectedJobs.bind(this);
        this.handleAbandonJobs = this.handleAbandonJobs.bind(this);
        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleRetryJobs = this.handleRetryJobs.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.toggleAbandonModal = this.toggleAbandonModal.bind(this);
        this.toggleRetryModal = this.toggleRetryModal.bind(this);
    }

    componentDidMount() {
        JobActions.get(this.getRouteState());
        return;
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            JobActions.get(this.getRouteState(this.props));
        }
        return;
    }

    getSelectedJobs() {
        return this.state.jobs.filter(u => u.get('selected'));
    }

    handleAbandonJobs() {
        const jobs = this.getSelectedJobs();
        JobActions.abandonJobs(jobs);
        this.toggleAbandonModal();
    }

    handleLoadPage(pageNumber) {
        this.setRouteState('offset', pageNumber * 20)
            .apply();
        return;
    }

    handleRetryJobs() {
        const jobs = this.getSelectedJobs();
        JobActions.retryJobs(jobs);
        this.toggleRetryModal();
    }

    handleSearchTerm(term) {
        this.setRouteState('job-name', term)
            .setRouteState('operator', 'AND')
            .clearRouteState('offset')
            .apply();
        return;
    }

    handleSortChange(sortFieldName, sortDirection) {
        this.setRouteState('sort-field-name', sortFieldName)
            .setRouteState('sort-direction', sortDirection)
            .apply();
        return;
    }

    handleToggleFiltersPanel() {
        LayoutActions.toggleFiltersPanel();
        return;
    }

    toggleAbandonModal() {
        this.setState((prevState) => ({
            showAbandonModal: !prevState.showAbandonModal
        }));
    }

    toggleRetryModal() {
        this.setState((prevState) => ({
            showRetryModal: !prevState.showRetryModal
        }));
    }

    render() {
        const buttonsDisabled = this.getSelectedJobs().size === 0;
        let inactiveClass;
        if (buttonsDisabled) {
            inactiveClass = 'inactive';
        }

        return (
            <DocumentTitle message="hardac.processes.jobs.title">
                <div className={ClassNames({'control-sidebar-open': this.state.showFiltersPanel})}>
                    <div className={ClassNames({'sidebar-opened': this.state.showFiltersPanel})}>
                        <Preloader show={this.state.showPreloader} fixed loadingDots>
                            <section className="content-header">
                                <h1>
                                    <i className="fas fa-tasks-alt"></i>
                                    &nbsp;
                                    {this.context.intl.messages['hardac.processes.jobs.title']}
                                    &nbsp;
                                    <small>{this.state.count}</small>
                                </h1>
                            </section>
                            <section className="content">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="container-fluid no-x-padding">
                                            <div className="row">
                                                <div className="col-md-6 show-grid">
                                                    <SearchBox disabled onChange={this.handleSearchTerm} value={this.getRouteState().get('name')}/>
                                                </div>
                                                <div className="col-md-6 show-grid">
                                                    <Button onClick={this.handleToggleFiltersPanel} bsSize="large" className="btn btn-primary-outline pull-right">
                                                        <i className="fas fa-sliders-h"></i>&nbsp;{this.context.intl.messages['common.filteringOptions']}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container-fluid no-x-padding">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="box">
                                                        <div className={`bulk-actions ${inactiveClass} padding-x-20 padding-y-20`}>
                                                            {/* we do not have api support for abandoning jobs
                                                            <Button
                                                                className="btn btn-danger-outline btn-bulk"
                                                                disabled={buttonsDisabled}
                                                                onClick={this.toggleAbandonModal}
                                                            >
                                                                <i className="fas fa-times"></i>&nbsp;{this.context.intl.messages['hardac.processes.jobs.abandon']}
                                                            </Button>&nbsp; */}
                                                            <Button
                                                                className="btn btn-success-outline btn-bulk"
                                                                disabled={buttonsDisabled}
                                                                onClick={this.toggleRetryModal}
                                                            >
                                                                <i className="fas fa-redo-alt"></i>&nbsp;{this.context.intl.messages['hardac.processes.jobs.retry']}
                                                            </Button>
                                                        </div>
                                                        <Table
                                                            activePage={Math.ceil((this.getRouteState().get('offset') || 0) / 20 ) || 0}
                                                            jobs={this.state.jobs}
                                                            onPageChange={this.handleLoadPage}
                                                            onSortChange={this.handleSortChange}
                                                            sortDirection={this.getRouteState().get('sort-direction') || 'desc'}
                                                            sortFieldName={this.getRouteState().get('sort-field-name') || 'JOB_NAME'}
                                                            totalPages={Math.ceil(this.state.total/20) || 0}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <FilterOptions
                                location={this.props.location}
                            />
                        </Preloader>
                        <AbandonModal show={this.state.showAbandonModal} onClose={this.toggleAbandonModal} onAbandon={this.handleAbandonJobs} />
                        <RetryModal show={this.state.showRetryModal} onClose={this.toggleRetryModal} onRetry={this.handleRetryJobs} />
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}

export default WithStoreOnRoute(Container.create(Browse));
