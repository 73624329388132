/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {LanguageCodeType} from '@wbdt-sie/brainiac-web-common';
import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import jQuery from 'jquery';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, Modal} from 'react-bootstrap';

import Item from './item/item';
import ItemEdit from './item/item-edit';
import TargetTypeSelect from './target-type';
import {TargetTypeActions} from './target-type-actions';
import TargetTypeStore from './target-type-store';
import {AssetConstants} from '../../../assets/asset-actions';
import {Alert} from '../../../common/notification/alert';
import SlidingPanel from '../../../common/sliding-panel/sliding-panel';
import {SlidingPanelActions} from '../../../common/sliding-panel/sliding-panel-actions';
import Pagination from '../../../common/table/pagination';
import {PublishingListActions, PublishingListConstants} from '../../publishing-list-actions';
import {PublishingListLocalizedActions} from '../../publishing-list-localized-actions';
import PublishingListStore from '../../publishing-list-store';

import './list-items.less';

require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('../../../styles/data-tables-brainiac.css');
// Load jQuery and register the datatables plugin.
require('datatables.net-responsive-bs');

const ASSET_TYPES_BY_NAME = Object.keys(AssetConstants.ASSET_TYPES).reduce((r, tName) => {
    let type = AssetConstants.ASSET_TYPES[tName];
    r[type.name] = type;
    return r;
}, {});

class Table extends Component {
    static get propTypes() {
        return {
            currentLanguage: PropTypes.string.isRequired,
            displayLinks: PropTypes.bool,
            disabled: PropTypes.bool,
            editName: PropTypes.string.isRequired,
            isTargetChanged: PropTypes.bool.isRequired,
            listItems: PropTypes.instanceOf(Immutable.List).isRequired,
            location: PropTypes.object.isRequired,
            offset: PropTypes.number.isRequired,
            publishingListLocalized: PropTypes.instanceOf(Immutable.Map).isRequired,
            size: PropTypes.number.isRequired,
            slidingPanelId: PropTypes.string.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
            router: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            item: PublishingListStore.getState().get('item'),
            publishingList: PublishingListStore.getState().get('publishingList'),
            showModal: PublishingListStore.getState().get('showModal')
        };
    }

    static getStores() {
        return [PublishingListStore];
    }

    static get defaultProps() {
        return {
            disabled: false,
            displayLinks: true
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            addItemId: Math.ceil(Math.random() * 100000),
            editItemId: Math.ceil(Math.random() * 100000),
            isTargetChanged: false,
            mode: 'add',
        }, this.constructor.calculateState());

        this.getCatalogs = this.getCatalogs.bind(this);
        this.getEditLink = this.getEditLink.bind(this);
        this.getUserGroups = this.getUserGroups.bind(this);
        this.handleAddItem = this.handleAddItem.bind(this);
        this.handleCancelAddSlidingPanel = this.handleCancelAddSlidingPanel.bind(this);
        this.handleChangeTarget = this.handleChangeTarget.bind(this);
        this.handleAssetCatalogCellMouseEnter = this.handleAssetCatalogCellMouseEnter.bind(this);
        this.handleTitleCatalogCellMouseEnter = this.handleTitleCatalogCellMouseEnter.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.renderRows = this.renderRows.bind(this);
    }

    componentDidMount() {
        this.$table = jQuery(`#list-item-table-${this.state.id}`);
        this.$tableAPI = this.$table.DataTable({
            autoWidth: false,
            columnDefs: [{
                // Add the control class to the last column. This colum will
                // contain the button to show the responsive data.
                className: 'control',
                targets:   -1,
                width: 20
            }, {
                className: 'row-reorder-handle',
                targets: 0
            }, {
                className: 'text-center',
                targets: 1
            }, {
                targets: 'no-sort',
                orderable: false
            }],
            iDisplayLength: 1,
            info: false,
            ordering: false,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            rowReorder: {
                selector: '.row-reorder-handle'
            },
            searching: false
        });

        // Add some custom handlers.
        this.$table.on('mouseenter', '.display-asset-catalogs', this.handleAssetCatalogCellMouseEnter);
        this.$table.on('mouseenter', '.display-title-catalogs', this.handleTitleCatalogCellMouseEnter);

        // Add some custom handlers.
        this.$tableAPI.on('row-reorder', this.handleReorder);
        this.renderRows(this.props);

        return;
    }

    componentWillUpdate(nextProps) {
        this.renderRows(nextProps);
        return;
    }

    componentWillUnmount() {
        if (this.$tableAPI) {
            this.$tableAPI.off('row-reorder');
            this.$tableAPI.destroy();
        }
        return;
    }

    getCatalogs(catalogs) {
        if (catalogs) {
            // Filter catalogs auto generated by Events
            const catalogsToShow = catalogs.filter(catalog => {
                return !catalog.get('name').toLowerCase().startsWith('event:');
            });
            catalogs = catalogsToShow.map(x => x.get('name')).reduce((r, v, ii) => {
                if (ii === 0) {
                    return `Catalogs: ${v}`;
                }

                return `${r}, ${v}`;
            }, this.context.intl.messages['assets.browse.no-catalogs']);
        } else {
            catalogs = this.context.intl.messages['assets.browse.loading-catalogs'];
        }
        return catalogs;
    }

    getEditLink(index, itemName, id) {
        let strippedItemName = this.removeHTMLTags(itemName);
        let editLink = strippedItemName;
        if (this.props.displayLinks) {
            editLink = `<a href="#" data-item-index="${index}" data-item-id="${id}" class="edit-item">${strippedItemName}</a>`;
        }
        return editLink;
    }

    getThumbnail(item, targetTypeName) {
        // This means the user added or replaced the imageUrl with a new one
        // that hasn't been submitted. Return and show nothing.
        if (item.get('newFile') || item.get('newVideoFile')) {
            const title = 'Thumbnail will be available after uploading the file to the server';
            return `<div class="parcel-tooltip" title="${title}"><i class="fas fa-info-circle"></i><span class="tooltip-inner">${title}</span></div>`;
        }
        if (item.get('imageUrl') && item.get('imageS3Path')) {
            if (item.get('videoUrl')) {
                return `<a href="#"><img src="${item.get('imageUrl')}" class="table-thumbnail"/></a> <i class="fas fa-video"></i>`;
            } else {
                return `<a href="#"><img src="${item.get('imageUrl')}" class="table-thumbnail"/></a>`;
            }
        } else if (item.get('videoUrl')) {
            return '<i class="fas fa-video"></i>';
        }

        if (item.get('assetId')) {
            if (targetTypeName === 'Asset') {
                let assetType = ASSET_TYPES_BY_NAME[item.get('displayAssetTypeName')];

                if (item.getIn(['asset', 'thumbnailUrl'])) {
                    return `<img class="table-thumbnail" src="${item.getIn(['asset', 'thumbnailUrl'])}" />`;
                }

                return `<i class="${ClassNames(assetType.icon)}"></i>`;
            }

            // Assume this is Title
            let title = item.get('asset');
            if (title && title.get('defaultImagePortraitThumbnailUrl')) {
                return `<img class="table-thumbnail" src="${title.get('defaultImagePortraitThumbnailUrl')}" />`;
            }

            return "<i class='far fa-newspaper'></i>";
        }

        // Internal Page, External Web Page, App Section
        return '<i class="fas fa-link"></i>';
    }

    getUserGroups(userGroups) {
        if (userGroups) {
            userGroups = userGroups.map(x => x.get('name')).reduce((r, v, ii) => {
                if (ii === 0) {
                    return `User Groups: ${v}`;
                }

                return `${r}, ${v}`;
            }, this.context.intl.messages['assets.browse.no-catalogs']);
        } else {
            userGroups = this.context.intl.messages['assets.browse.loading-catalogs'];
        }
        return userGroups;
    }

    handleAddItem() {
        //If Publishing List is Navigation Menu type, lets force the user to select internal pages
        PublishingListActions.clearItem();
        TargetTypeActions.clear();
        this.setState({
            mode: 'add'
        });
        if (this.state.publishingList.get('purposeType') === PublishingListConstants.PURPOSE_TYPES.NAVIGATION_MENU.id) {
            TargetTypeActions.updateTargetType(TargetTypeStore.getTargetTypes().find((target) => target.id === 3));
            SlidingPanelActions.show(`add-list-item-${this.state.addItemId}`);
            return;
        }

        if (this.state.isTargetChanged) {
            this.setState({
                isTargetChanged: false
            });
        }
        SlidingPanelActions.show(`add-list-item-${this.state.addItemId}`);
        return;
    }

    handleCancelAddSlidingPanel() {
        SlidingPanelActions.hide(`add-list-item-${this.state.addItemId}`);
        if (this.state.isTargetChanged) {
            PublishingListActions.show();
        }
    }

    handleChangeTarget() {
        TargetTypeActions.clear();
        this.setState({
            isTargetChanged: true,
        });
        PublishingListActions.hide();
        SlidingPanelActions.show(`add-list-item-${this.state.addItemId}`);
    }

    handlePageChange(nextPage) {
        PublishingListActions.goToItemsPage(nextPage);
        return;
    }

    handleAssetCatalogCellMouseEnter(event) {
        let asset = event.currentTarget.getAttribute('data-original-title');
        // If catalogs for this asset are already loaded, then return.
        if (asset !== this.context.intl.messages['assets.browse.loading-catalogs']) {
            return;
        }

        PublishingListActions.getAssetItemCatalogs(
            parseInt(event.currentTarget.getAttribute('data-item-index'), 10),
            parseInt(event.currentTarget.getAttribute('data-asset-id'), 10)
        );
        return;
    }

    handleTitleCatalogCellMouseEnter(event) {
        let asset = event.currentTarget.getAttribute('title');
        // If catalogs for this asset are already loaded, then return.
        if (asset !== this.context.intl.messages['assets.browse.loading-catalogs']) {
            return;
        }
        PublishingListActions.getTitleItemCatalogs(
            parseInt(event.currentTarget.getAttribute('data-item-index'), 10),
            parseInt(event.currentTarget.getAttribute('data-title-id'), 10)
        );
        return;
    }

    handleReorder(event, diff, edit) {
        event.preventDefault();
        let from = edit.triggerRow[0][0];
        if (diff.length < 2) {
            return;
        }

        let to;
        if (diff[0].oldPosition === from) {
            to = diff[0].newPosition;
        } else {
            to = diff[diff.length-1].newPosition;
        }

        setTimeout(() => {
            PublishingListActions.moveElement(from, to);
        }, 0);
        return;
    }

    handleRowClick(event) {
        switch (true) {
        // Handle click on an user's name.
        case !!~event.target.className.indexOf('edit-item'):
            // Prevent the default anchor click event.
            event.preventDefault();

            let itemIndex = parseInt(event.target.getAttribute('data-item-index'), 10);
            PublishingListActions.setItem(itemIndex);

            if (this.props.currentLanguage !== LanguageCodeType.DEFAULT_LANGUAGE) {
                let itemId = parseInt(event.target.getAttribute('data-item-id'), 10);
                PublishingListLocalizedActions.setItem(this.props.publishingListLocalized, itemId);
            }

            this.setState({
                mode: 'edit'
            });
            PublishingListActions.show();
            break;
        }
        return;
    }

    onHide() {
        PublishingListActions.clearItem();
        PublishingListActions.hide();
    }

    renderRows(props) {
        this.$tableAPI.clear();
        // Add data to the jQuery datatable.
        // But first apply client-side pagination
        props.listItems.slice(props.offset, props.offset + props.size).forEach((t, i) => {
            let targetTypeName = TargetTypeStore.getTargetTypeName(t.get('targetType'));
            let itemName;
            let itemThumbnail = this.getThumbnail(t, targetTypeName);
            // Get the Target URL, if asset then use asset name, if title then use title name
            let catalogOrUserGroups = '';
            let description = `<div class="publishing-list-description">${t.get('description') || ''}</div>`;
            if (t.get('assetId')) {
                if (targetTypeName === 'Asset') {
                    let asset = t.get('asset');
                    targetTypeName = `${targetTypeName} (${t.get('displayAssetTypeName')})`;
                    itemName = asset.get('assetName');
                    itemName = itemName || asset.get('name');
                    let catalogs = this.getCatalogs(t.get('catalogs'));
                    catalogOrUserGroups = `<a class="display-asset-catalogs parcel-tooltip" data-item-index="${t.get('displayOrder')}" data-asset-id="${t.get('assetId')}" title="${catalogs}"><i class="fas fa-book"></i><span class="tooltip-inner">${catalogs}</span></a>`;
                } else {
                    let title = t.get('asset');
                    itemName = 'No Title';
                    if (title) {
                        itemName = title.get('name');
                    }
                    let catalogs = this.getCatalogs(t.get('catalogs'));
                    catalogOrUserGroups = `<a class="display-title-catalogs parcel-tooltip" data-item-index="${t.get('displayOrder')}" data-title-id="${t.get('assetId')}" title="${catalogs}"><i class="fas fa-book"></i><span class="tooltip-inner">${catalogs}</span></a>`;
                }
            } else {
                itemName = t.get('targetUrl');
                let ugs = this.getUserGroups(t.get('userGroups'));
                catalogOrUserGroups = `<a class="display-user-groups parcel-tooltip" title="${ugs}"><i class="fas fa-book"></i><span class="tooltip-inner">${ugs}</span></a>`;
            }
            // This is the link used to edit the item (important, use the offset due to pagination!).
            let targetUrl = this.getEditLink(i + props.offset, itemName, t.get('id'));
            this.$tableAPI.row.add([
                // This is important due to the client-side pagination!
                i + props.offset + 1,
                itemThumbnail,
                targetUrl || '',
                targetTypeName || '',
                description,
                catalogOrUserGroups,
                '' // Add a last empty column for the datatable-responsive plugin.
            ]);

            return;
        });

        this.$tableAPI.draw(false);

        return;
    }

    removeHTMLTags(txt) {
        let rex = /(<([^>]+)>)/ig;
        return txt && txt.replace(rex, '');
    }

    render() {
        let addItemTitle = <span>{this.context.intl.messages['publishing-list.list-item.add-existing']}&nbsp;<small>{this.props.editName}</small></span>;

        let item = <ItemEdit
            changeTarget={this.handleChangeTarget}
            currentLanguage={this.props.currentLanguage}
            mode={this.state.mode}
            publishingListLocalized={this.props.publishingListLocalized}
        />;
        if (this.state.mode === 'add') {
            let targetTypeId = TargetTypeStore.getState().get('targetTypeId');
            item = <Item
                currentLanguage={this.props.currentLanguage}
                disabled={this.props.disabled}
                isTargetChanged={this.props.isTargetChanged}
                item={this.state.item}
                mode={this.state.mode}
                publishingListLocalized={this.props.publishingListLocalized}
                targetTypeId={targetTypeId}
                slidingPanelId={this.props.slidingPanelId}
            />;
        }
        return (
            <div>
                <h2>
                    <i className="far fa-file-image"></i>&nbsp;{this.context.intl.messages['publishing-list.list-item.tab.title']}:&nbsp;<small>{this.props.listItems.size}</small>
                    <Button disabled={this.props.currentLanguage !== LanguageCodeType.DEFAULT_LANGUAGE} bsStyle="primary" className="pull-right" onClick={this.handleAddItem}><i className="fas fa-plus-square"></i>&nbsp;{this.context.intl.messages['publishing-list.list-item.add-item.button']}</Button>
                </h2>
                <table id={`list-item-table-${this.state.id}`} className="table table-bordered table-striped responsive">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th><i className="far fa-image" title="Thumbnail"></i></th>
                            <th>{this.context.intl.messages['publishing-list.list-item.target']}</th>
                            <th>{this.context.intl.messages['publishing-list.list-item.item-type']}</th>
                            <th>{this.context.intl.messages['publishing-list.list-item.description']}</th>
                            <th><i className="fas fa-book" title="Catalog"></i></th>
                            <th className="no-sort"/>
                        </tr>
                    </thead>
                    <tbody onClick={this.handleRowClick}/>
                </table>

                <div className="row">
                    <div className="col-sm-7 col-sm-offset-5">
                        <Pagination
                            activePage={Math.ceil(this.props.offset / this.props.size) || 0}
                            onChange={this.handlePageChange}
                            totalPages={Math.ceil(this.props.listItems.size / this.props.size)}
                        />
                    </div>
                </div>

                <SlidingPanel
                    id={`add-list-item-${this.state.addItemId}`}
                    onCancel={this.handleCancelAddSlidingPanel}
                    title={addItemTitle}
                >
                    <div>
                        <TargetTypeSelect
                            currentLanguage={this.props.currentLanguage}
                            displayLinks={this.props.displayLinks}
                            isTargetChanged={this.state.isTargetChanged}
                            location={this.props.location}
                            publishingListLocalized={this.props.publishingListLocalized}
                            slidingPanelId={`add-list-item-${this.state.addItemId}`}
                        />
                    </div>
                </SlidingPanel>

                <Modal dialogClassName="modal-900w" show={this.state.showModal} onEnter={this.showPreloader} onHide={this.onHide}>
                    <Modal.Header className="bg-gray" closeButton>
                        <Modal.Title className="text-center">{this.context.intl.messages['publishing-list.list-item.item.detail']}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <Alert withHeader/>
                        {item}
                    </Modal.Body>
                    <Modal.Footer />
                </Modal>
            </div>
        );
    }
}

export default Container.create(Table);
