/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ControlLabel, FormControl, FormGroup} from 'react-bootstrap';

import BaseSelect from '../../../../common/base-select/base-select';
import {FormItem, FormRow, FormSection} from '../../../../common/form/form';
import {CompareImmutable} from '../../../../common/utils/utils';
import {WithValidations} from '../../../../common/validations/validations';
import config from '../../../../config/config.js';
import {ScriptActions} from '../../script-actions';
import ScriptStore, {ScriptValidations} from '../../script-store';


/**
 * Document props to watch.
 */
let scriptProps = [
    'active', 'assetComment', 'body', 'scriptType', 'copyrightYear', 'copyrightText',
    'keyword', 'name', 'updatedBy', 'updatedDate'
];

class Summary extends Component {

    static get propTypes() {
        return {
            asset: PropTypes.instanceOf(Immutable.Map).isRequired,
            disabled: PropTypes.bool,
            mode: PropTypes.string.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            isContentTypeDirty: false,
            selectedContentType: ScriptStore.getContentType(
                this.props.asset.get('contentType')
            )
        };

        this.handleSelectContentType = this.handleSelectContentType.bind(this);
    }


    componentWillReceiveProps(nextProps) {
        this.setState({
            selectedContentType: ScriptStore.getContentType(
                nextProps.asset.get('contentType')
            )
        });
        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.disabled !== this.props.disabled ||
            !CompareImmutable(nextProps.asset, this.props.asset, scriptProps)) {
            return true;
        }

        if (nextState.selectedContentType !== this.state.selectedContentType ) {
            return true;
        }

        return false;
    }

    handleSelectContentType(option) {
        if (!this.state.isContentTypeDirty) {
            this.setState({isContentTypeDirty: true});
        }

        if (option === null) {
            ScriptActions.update('contentType', null);
            return;
        }

        ScriptActions.update('contentType', option.id);

        return;
    }

    render() {
        let additionalInformation;
        let additionalInformationRows;

        if (this.props.mode === 'edit') {

            const {
                episodeNumber,
                fileCheckSum,
                fileInfo,
                fileName,
                filePath,
                fileSize,
                fileType,
                metaModel,
                mpmNumber,
                scriptType,
                seasonNumber,
                sourceUrl,
                title,
                updatedDate,
                updatedBy,
            } = this.props.asset.toJS();

            let updatedDateFormated = '-';
            if (updatedDate) {
                updatedDateFormated = Moment(updatedDate).tz(config.DefaultTimezone).format(
                    this.context.intl.messages['datetime-format']
                );
            }
            additionalInformation = <FormRow>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['asset.edit.last-modified-date']}</ControlLabel>
                    <FormControl.Static>{updatedDateFormated}</FormControl.Static>
                </FormGroup>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['asset.edit.last-modifier']}</ControlLabel>
                    <FormControl.Static>{updatedBy || '-'}</FormControl.Static>
                </FormGroup>
            </FormRow>;

            additionalInformationRows = <div>
                <h3><i className="far fa-file"></i>{this.context.intl.messages['asset.script.summary.fileInfo']}</h3>
                <FormSection>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['asset.script.summary.fileName']}</ControlLabel>
                            <FormControl.Static>{fileName || '-'}</FormControl.Static>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['asset.script.summary.fileInfo']}</ControlLabel>
                            <FormControl.Static>{fileInfo || '-'}</FormControl.Static>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['asset.script.summary.filePath']}</ControlLabel>
                            <FormControl.Static>{filePath || '-'}</FormControl.Static>
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['asset.script.summary.fileCheckSum']}</ControlLabel>
                            <FormControl.Static>{fileCheckSum || '-'}</FormControl.Static>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['asset.script.summary.fileType']}</ControlLabel>
                            <FormControl.Static>{fileType || '-'}</FormControl.Static>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['asset.script.summary.fileSize']}</ControlLabel>
                            <FormControl.Static>{fileSize || '-'}</FormControl.Static>
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['asset.script.summary.sourceUrl']}</ControlLabel>
                            <FormControl.Static>{sourceUrl || '-'}</FormControl.Static>
                        </FormGroup>
                    </FormRow>
                </FormSection>

                <h3><i className="fas fa-project-diagram"></i>{this.context.intl.messages['asset.efd.info']}</h3>
                <FormSection>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['asset.script.summary.metaModel']}</ControlLabel>
                            <FormControl.Static>{metaModel || '-'}</FormControl.Static>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['asset.script.summary.scriptType']}</ControlLabel>
                            <FormControl.Static>{scriptType || '-'}</FormControl.Static>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['asset.script.summary.title']}</ControlLabel>
                            <FormControl.Static>{title || '-'}</FormControl.Static>
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['asset.script.summary.mpmNumber']}</ControlLabel>
                            <FormControl.Static>{mpmNumber || '-'}</FormControl.Static>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['asset.script.summary.seasonNumber']}</ControlLabel>
                            <FormControl.Static>{seasonNumber || '-'}</FormControl.Static>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['asset.script.summary.episodeNumber']}</ControlLabel>
                            <FormControl.Static>{episodeNumber || '-'}</FormControl.Static>
                        </FormGroup>
                    </FormRow>
                </FormSection>
            </div>;
        }

        return (
            <div>
                <h3><i className="fas fa-info-circle"></i>{this.context.intl.messages['asset.info']}</h3>

                <FormSection>
                    <FormRow>
                        <FormGroup>
                            <FormRow>
                                <FormItem
                                    attr="assetName"
                                    label={this.context.intl.messages['asset.script.summary.name']}
                                    disabled={this.props.disabled}
                                    model={this.props.asset}
                                    required
                                    setter={ScriptActions.update}
                                    type="text"
                                    validations={ScriptValidations.assetName.validations}
                                />
                                <FormItem
                                    attr="assetComment"
                                    label={this.context.intl.messages['asset.script.summary.assetComment']}
                                    disabled={this.props.disabled}
                                    model={this.props.asset}
                                    required
                                    setter={ScriptActions.update}
                                    type="text"
                                    validations={ScriptValidations.assetComment.validations}
                                />
                            </FormRow>
                            <FormRow>
                                <FormGroup>
                                    <ControlLabel>{this.context.intl.messages['asset.script.contentType']}&nbsp;<span className="text-red">*</span></ControlLabel>
                                    <BaseSelect
                                        disabled={this.props.disabled}
                                        name={this.context.intl.messages['asset.script.contentType']}
                                        onChange={this.handleSelectContentType}
                                        options="contentType"
                                        store={ScriptStore}
                                        value={this.state.selectedContentType}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <ControlLabel>{this.context.intl.messages['common.active']}</ControlLabel>
                                    <FormItem
                                        attr="active"
                                        disabled={this.props.disabled}
                                        label={this.context.intl.messages['common.active']}
                                        model={this.props.asset}
                                        setter={ScriptActions.update}
                                        type="bool-checkbox"
                                    />
                                </FormGroup>
                            </FormRow>
                            <FormRow>
                                <FormItem
                                    attr="scriptLanguage"
                                    label={this.context.intl.messages['asset.script.summary.language']}
                                    disabled={this.props.disabled}
                                    model={this.props.asset}
                                    required
                                    setter={ScriptActions.update}
                                    type="text"
                                    validations={ScriptValidations.language.validations}
                                />
                                <FormItem
                                    attr="territory"
                                    label={this.context.intl.messages['asset.script.summary.territory']}
                                    disabled={this.props.disabled}
                                    model={this.props.asset}
                                    required
                                    setter={ScriptActions.update}
                                    type="text"
                                    validations={ScriptValidations.territory.validations}
                                />
                            </FormRow>
                        </FormGroup>
                    </FormRow>
                    <hr />
                    {additionalInformation}
                </FormSection>
                {additionalInformationRows}
            </div>
        );
    }

}

export default WithValidations(Summary);
