/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {MassUpdateConstants} from './mass-update-actions';
import Dispatcher from '../../../dispatcher/dispatcher';

class MassUpdateStore extends ReduceStore {

    getInitialState() {
        return Immutable.Map({
            assets: Immutable.List(),
            assignedCatalogs: Immutable.List(),
            catalogs: Immutable.List(),
            filters: '',
            massUpdateProcess: Immutable.Map(),
            mode: 'update',
            originalAssignedCatalogs: Immutable.List(),
            removeList: Immutable.List()
        });
    }

    reduce(state, action) {
        switch (action.actionType) {

        case MassUpdateConstants.MASS_UPDATE.CATALOG.ADD:
            state = state.updateIn([action.catalogList], catalogs => {
                return catalogs.push(action.catalog).sortBy(c => c.get('name'));
            });
            break;

        case MassUpdateConstants.MASS_UPDATE.CATALOG.REMOVE:
            state = state.updateIn([action.catalogList], catalogs =>
                catalogs.filter(c => c.get('id') !== action.catalog.get('id')).sortBy(c => c.get('name'))
            );
            break;

        case MassUpdateConstants.MASS_UPDATE.CLEAR:
            state = this.getInitialState();
            break;

        case MassUpdateConstants.MASS_UPDATE.CLEAR_REMOVELIST:
            const initialState = this.getInitialState();
            state = state.set('removeList', initialState.get('removeList'));
            break;

        case MassUpdateConstants.MASS_UPDATE.FINDBY.SUCCESS:
            state = state.merge({
                assets: action.rows,
                massUpdateProcess: action.massUpdateProcess
            });
            break;

        case MassUpdateConstants.MASS_UPDATE.GET.ASSET.CATALOGS:
            state = state.set('assignedCatalogs', action.catalogs.toSet().sortBy(c => c.get('name')).toList());
            state = state.set('originalAssignedCatalogs', state.get('assignedCatalogs'));
            break;

        case MassUpdateConstants.MASS_UPDATE.GET.ASSET.ERROR:
            state = state.setIn(['assets', action.assetIndex, 'errorLoading'], true);
            break;

        case MassUpdateConstants.MASS_UPDATE.GET.ASSET.SUCCESS:
            state = state.updateIn(['assets', action.assetIndex], asset => asset.merge({
                assetCatalogs: action.assetCatalogs.toSet().sortBy(c => c.get('name')),
                assetName: action.assetName,
                assetType: action.assetType
            }));
            break;

        case MassUpdateConstants.MASS_UPDATE.GET.CATALOGS:
            state = state.set('catalogs', action.catalogs.sortBy(c => c.get('name')));
            break;

        case MassUpdateConstants.MASS_UPDATE.GET.PROCESS:
            state = state.set('massUpdateProcess', action.massUpdateProcess);
            break;

        case MassUpdateConstants.MASS_UPDATE.SET.MODE:
            state = state.set('mode', action.mode);
            break;
        }
        return state;

    }
}

let store = new MassUpdateStore(Dispatcher);

export default store;
