/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {ControlLabel, FormControl, FormGroup} from 'react-bootstrap';

import {FormItem, FormRow, FormSection} from '../../../common/form/form';
import Config from '../../../config/config.js';
import {BrandActions} from '../../brand-actions';
import {BrandValidations} from '../../brand-store';

class Summary extends React.Component {
    static get propTypes() {
        return {
            brand: PropTypes.instanceOf(Immutable.Map).isRequired,
            disabled: PropTypes.bool,
            mode: PropTypes.string.isRequired,
        };
    }

    static get defaultProps() {
        return {
            disabled: true
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);
    }

    render() {
        const {updatedBy, updatedDate} = this.props.brand.toJS();
        const disabled = this.props.disabled;
        let additionalInfo;

        if (this.props.mode === 'edit') {
            let updatedDateFormatted = '-';
            if (updatedDate) {
                updatedDateFormatted = Moment(updatedDate).tz(Config.DefaultTimezone).format(this.context.intl.messages['datetime-format']);
            }
            additionalInfo = <FormRow>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['brands.summary.last-modified-date']}</ControlLabel>
                    <FormControl.Static>{updatedDateFormatted}</FormControl.Static>
                </FormGroup>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['brands.summary.last-modifier']}</ControlLabel>
                    <FormControl.Static>{updatedBy}</FormControl.Static>
                </FormGroup>
            </FormRow>;
        }

        return (
            <div>
                <h3><i className="fa fa-info-circle"></i>&nbsp;{this.context.intl.messages['brands.summary.info']}</h3>
                <FormSection>
                    <FormRow>
                        <FormItem
                            attr="name"
                            label={this.context.intl.messages['brands.summary.name']}
                            disabled={disabled}
                            model={this.props.brand}
                            md={6}
                            required
                            setter={BrandActions.update}
                            type="text"
                            validations={BrandValidations.name.validations}
                        />
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['brands.summary.status']}</ControlLabel>
                            <FormItem
                                attr="active"
                                label={this.context.intl.messages['brands.summary.active']}
                                disabled={this.props.disabled}
                                model={this.props.brand}
                                setter={BrandActions.update}
                                type="checkbox"
                            />
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <FormItem
                            attr="description"
                            label={this.context.intl.messages['brands.summary.description']}
                            disabled={disabled}
                            model={this.props.brand}
                            required
                            setter={BrandActions.update}
                            type="text"
                            validations={BrandValidations.description.validations}
                        />
                    </FormRow>
                    <hr />
                    {additionalInfo}
                </FormSection>
            </div>
        );
    }
}

export default Summary;
