/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React from 'react';
import {browserHistory} from 'react-router';
import url from 'url';

import {Alert} from './../common/notification/alert';
import LoginForm from './login-form';
import Config from '../config/config';
import SessionStore from '../user/session/session-store';


export default class Login extends React.Component {
    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    componentDidMount() {
        // If a user is logged in and trying load the login page
        // then we redirect him to the dashboard.
        if (SessionStore.sessionIsValid()) {
            browserHistory.push('/');
        }

        document.title = this.context.intl.messages['login.title'];

        return;
    }

    render() {
        let forgotPassUrl = url.resolve(Config.WBTVDBaseUrl, '/forgot-password');
        let termsOfUseUrl = url.resolve(Config.WBTVDBaseUrl, '/terms-of-use?from=signUp');
        return (
            <div className="login-page clearfix H(100%)">
                <div className="login-box Pos(a) T(0) B(0) L(0) R(0)">
                    <div className="login-logo">
                        <img src={ require('../img/logo-white-220px.png') }/>
                    </div>
                    <div className="login-box-body">
                        <Alert/>
                        <LoginForm {...this.props}/>
                    </div>
                    <br/>
                    <p className="text-center">
                        <a href={forgotPassUrl}>{this.context.intl.messages['login.forgot-password']}</a>
                        <span className="login-text-vbar">|</span>
                        <a href={termsOfUseUrl}>{this.context.intl.messages['login.sign-up']}</a>
                    </p>
                </div>
            </div>
        );
    }
}
