/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, FormGroup} from 'react-bootstrap';
import ReactSelect from 'react-select';

import ListBackgrounds from './table';
import DocumentTitle from '../../common/document-title';
import {MODAL_TYPE} from '../../common/notification/modal';
import {NotificationActions} from '../../common/notification/notification-actions';
import {WithStoreOnRoute} from '../../common/store-on-route';
import Preloader from '../../preloader/';
import PreloaderStore from '../../preloader/preloader-store';
import SessionStore from '../../user/session/session-store';
import {LoginBackgroundActions} from '../login-background-actions';
import LoginBackgroundStore from '../login-background-store';


class Browse extends Component {

    static get propTypes() {
        return {
            permissions: PropTypes.object.isRequired,
            location: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            backgrounds: LoginBackgroundStore.getState().get('backgrounds'),
            partner: LoginBackgroundStore.getState().get('partner'),
            partners: LoginBackgroundStore.getState().get('partners'),
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible'),
            selectAll: LoginBackgroundStore.getState().get('selectAll'),
            selectedBackgrounds: LoginBackgroundStore.getState().get('selectedBackgrounds')
        };
    }

    static getStores() {
        return [LoginBackgroundStore, PreloaderStore];
    }

    static getPermissions() {
        return {
            canReadLoginBackgrounds: SessionStore.canUser(SessionStore.PERMISSIONS.LOGIN_BACKGROUND.BROWSE),
            canRemoveLoginBackgrounds: SessionStore.canUser(SessionStore.PERMISSIONS.LOGIN_BACKGROUND.REMOVE),
            canUploadLoginBackgrounds: SessionStore.canUser(SessionStore.PERMISSIONS.LOGIN_BACKGROUND.UPLOAD)
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.handleFileInputChange = this.handleFileInputChange.bind(this);
        this.handleOpenFileInput = this.handleOpenFileInput.bind(this);
        this.handleRemoveSelectedBackgrounds = this.handleRemoveSelectedBackgrounds.bind(this);
        this.handleSelectPartner = this.handleSelectPartner.bind(this);
        this.showRemoveBackgroundsDialog = this.showRemoveBackgroundsDialog.bind(this);
    }

    componentDidMount() {
        LoginBackgroundActions.clear();
        LoginBackgroundActions.getPartners();
        return;
    }

    handleFileInputChange(attr) {
        if (
            this.refs[attr].files &&
            this.refs[attr].files[0]
        ) {
            LoginBackgroundActions.addBackground(this.state.partner.get('partner'), this.refs[attr].files[0]);
            LoginBackgroundActions.toggleSelectAll(false);
        }
    }

    handleOpenFileInput(attr) {
        this.refs[attr].click();
        return;
    }

    handleRemoveSelectedBackgrounds() {
        LoginBackgroundActions.removeBackgrounds(this.state.partner.get('partner'), LoginBackgroundStore.getState().get('selectedBackgrounds'));
        LoginBackgroundActions.toggleSelectAll(false);
        this.refs.loginBackgroundImage.value = null;
    }

    handleSelectPartner(partner) {
        if (partner) {
            LoginBackgroundActions.getPartnerBackgrounds(partner.partner);
        } else {
            partner = {};
        }
        LoginBackgroundActions.update('partner', Immutable.fromJS(partner));
        LoginBackgroundActions.toggleSelectAll(false);
        this.refs.loginBackgroundImage.value = null;
        return;
    }

    showRemoveBackgroundsDialog(event) {
        event.preventDefault();

        NotificationActions.show(
            MODAL_TYPE.DANGER,
            this.context.intl.messages['login-backgrounds.remove-backgrounds.modal.title'],
            this.context.intl.messages['login-backgrounds.remove-backgrounds.modal.body'],
            this.context.intl.messages['login-backgrounds.remove-backgrounds.modal.confirm'],
            this.handleRemoveSelectedBackgrounds
        );
    }

    render() {
        let inputStyle = {
            display: 'none'
        };
        let selectedPartner = this.state.partners.find((partner) => {
            return partner.id === this.state.partner.get('id');
        });
        return (
            <DocumentTitle
                message="document-titles.login-backgrounds"
            >
                <div>
                    <Preloader show={this.state.preloaderVisible} fixed loadingDots>
                        <section className="content-header">
                            <h1>
                                <i className="fa-regular fa-image-landscape"></i>&nbsp;{this.context.intl.messages['login-backgrounds.browse.title']}
                            </h1>
                        </section>
                        <section className="content">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="container-fluid no-x-padding">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="box">
                                                    <div className="box-body no-x-padding">
                                                        <div className="col-sm-12">
                                                            <div className="form-section">
                                                                <label>
                                                                    <i className="fa-solid fa-circle-info"></i>&nbsp;
                                                                    {this.context.intl.messages['login-backgrounds.disclaimer.title']}
                                                                </label>
                                                                <p>
                                                                    {this.context.intl.messages['login-backgrounds.disclaimer.text-1']}&nbsp;
                                                                    <a href="/publishing-list/help">
                                                                        {this.context.intl.messages['login-backgrounds.disclaimer.link']}&nbsp;
                                                                        <i className="fa-solid fa-arrow-up-right-from-square"></i>
                                                                    </a>
                                                                    &nbsp;{this.context.intl.messages['login-backgrounds.disclaimer.text-2']}
                                                                </p>

                                                            </div>
                                                            <hr />
                                                            <FormGroup className="col-md-3">
                                                                <ReactSelect
                                                                    getOptionLabel={data => data.name}
                                                                    getOptionValue={data => data.id}
                                                                    isClearable={false}
                                                                    isMulti={false}
                                                                    isDisabled={!this.props.permissions.canReadLoginBackgrounds}
                                                                    name="partner"
                                                                    onChange={this.handleSelectPartner}
                                                                    options={this.state.partners.toJS()}
                                                                    value={selectedPartner}
                                                                />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Button
                                                                    bsStyle="primary"
                                                                    disabled={!this.state.partner.get('id')}
                                                                    onClick={this.handleOpenFileInput.bind(this, 'loginBackgroundImage')}
                                                                >
                                                                    <i className="fas fa-upload"></i>
                                                                    &nbsp;
                                                                    {this.context.intl.messages['login-backgrounds.btn.upload']}
                                                                </Button>
                                                                <input
                                                                    accept="image/*"
                                                                    onChange={this.handleFileInputChange.bind(this, 'loginBackgroundImage')}
                                                                    ref="loginBackgroundImage"
                                                                    style={inputStyle}
                                                                    type="file"
                                                                />
                                                                &nbsp;
                                                                <div className={ClassNames('bulk-actions login-background')}>
                                                                    <Button
                                                                        bsStyle="danger"
                                                                        className="btn-bulk"
                                                                        disabled={!this.props.permissions.canRemoveLoginBackgrounds || !this.state.selectedBackgrounds.size}
                                                                        onClick={this.showRemoveBackgroundsDialog}>
                                                                        <i className="fas fa-times"></i>&nbsp;{this.context.intl.messages['login-backgrounds.btn.remove']}
                                                                    </Button>
                                                                </div>
                                                            </FormGroup>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <ListBackgrounds
                                                                backgrounds={this.state.backgrounds}
                                                                displayLinks={this.props.permissions.canReadLoginBackgrounds}
                                                                location={this.props.location}
                                                                onSelect={LoginBackgroundActions.toggleSelect}
                                                                onSelectAll={LoginBackgroundActions.toggleSelectAll}
                                                                selectAll={this.state.selectAll}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Preloader>
                </div>
            </DocumentTitle>
        );
    }
}

export default WithStoreOnRoute(Container.create(Browse));
