/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Moment from 'moment';

import {DownloadActions} from '../common/download/download-actions';
import {NotificationActions} from '../common/notification/notification-actions';
import Dispatcher from '../dispatcher/dispatcher';
import {PreloaderActions} from '../preloader/preloader-actions';
import Request from '../request';

import {UserActions, UserConstants} from '~/src/user/user-actions';

const CONSTANTS = {
    ANONYMOUS_ACCOUNT: {
        CREATE: 'anonymous_account.create'
    },
    ELASTIC_REINDEX: {
        TYPE: {
            TITLES: {id: 'titles'},
            ASSETS: {id: 'assets'},
            USERS: {id: 'users'},
            TALENT: {id: 'talent'}
        }
    },
    USAGE_GROUP: {
        OTHER: {id: 'OTHER', name: 'OTHER'},
        FYC: {id: 'FYC', name: 'FYC'},
    }
};

class UtilitiesActions {
    hboMaxImageIngest() {
        PreloaderActions.show('hbo-max-image-ingest.post');
        Request.post('asset/image/ingest-s3').exec().then(() => {
            PreloaderActions.hide('hbo-max-image-ingest.post');
            NotificationActions.showAlertSuccess('utilities.hbo-max-image-ingest.success');
            return;
        }).catch(err => {
            PreloaderActions.hide('hbo-max-image-ingest.post');
            NotificationActions.showAlertDanger('utilities.hbo-max-image-ingest.error');
            throw err;
        });
    }

    updateAnonymousAccounts(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ANONYMOUS_ACCOUNT.CREATE,
            attr: attr,
            value: value
        });
        return;
    }

    syncElasticSearch(type) {
        const endpoint = {
            titles: 'title/refresh-es',
            assets: 'asset/refresh-es',
            users: 'user/refresh-es',
            talent: 'talent/refresh-es',
            videoSearch: 'asset/video-search/refresh-es',
        };

        PreloaderActions.show('elasticsearch-reindex.get');
        Request.get(endpoint[type]).exec().then(() => {
            PreloaderActions.hide('elasticsearch-reindex.get');
            NotificationActions.showAlertSuccess(`utilities.elastic-search.sync.${type}.success`);
            return;
        }).catch(err => {
            PreloaderActions.hide('elasticsearch-reindex.get');
            NotificationActions.showAlertDanger(`utilities.elastic-search.sync.${type}.error`);
            throw err;
        });

    }

    syncElasticSearchByTitleId(titleId) {
        PreloaderActions.show('elasticsearch-reindex.get');
        Request.get(`title/${titleId}/refresh-es`).exec().then(() => {
            PreloaderActions.hide('elasticsearch-reindex.get');
            NotificationActions.showAlertSuccess('utilities.elastic-search.sync.title.success');
            return;
        }).catch(err => {
            PreloaderActions.hide('elasticsearch-reindex.get');
            NotificationActions.showAlertDanger('utilities.elastic-search.sync.title.error');
            throw err;
        });

    }

    syncRefreshImages() {
        PreloaderActions.show('refresh-images.get');
        Request.get('asset/image/refresh-cache').exec().then(() => {
            PreloaderActions.hide('refresh-images.get');
            NotificationActions.showAlertSuccess('utilities.refresh-image.sync.success');
            return;
        }).catch(err => {
            PreloaderActions.hide('refresh-images.get');
            NotificationActions.showAlertDanger('utilities.refresh-image.sync.error');
            throw err;
        });
    }

    syncRefreshPublishingList() {
        PreloaderActions.show('refresh-publishing-list.get');
        Request.post('web/publish-list/refresh-cache').exec().then(() => {
            PreloaderActions.hide('refresh-publishing-list.get');
            NotificationActions.showAlertSuccess('utilities.refresh-publishing-list.sync.success');
            return;
        }).catch(err => {
            PreloaderActions.hide('refresh-publishing-list.get');
            NotificationActions.showAlertDanger('utilities.refresh-publishing-list.sync.error');
            throw err;
        });
    }

    saveAnonymousAccounts(anonymousAccount) {
        let data = anonymousAccount.toJS();

        if (data.active) {
            data.active = 1;
        } else {
            data.active = 0;
        }
        if (data.validFromDate) {
            data.validFromDate = Moment(data.validFromDate).toISOString();
        } else {
            data.validFromDate = '';
        }
        if (data.validToDate) {
            data.validToDate = Moment(data.validToDate).toISOString();
        } else {
            data.validToDate = '';
        }
        if (data.numberOfAccounts) {
            data.numberOfAccounts = parseInt(data.numberOfAccounts);
        } else {
            data.numberOfAccounts = 0;
        }
        if (data.permissionPackageId) {
            data.permissionPackageId = parseInt(data.permissionPackageId);
        } else {
            data.permissionPackageId = 0;
        }

        PreloaderActions.show('anonymous-account.get');
        DownloadActions.startDownloadExecution('user/create-anonymous-account', {}, data, 'post', false).then(() => {
            PreloaderActions.hide('anonymous-account.get');
            NotificationActions.showAlertSuccess('utilities.generate-anonymous-accounts.sync.success');
            return;
        }).catch(err => {
            PreloaderActions.hide('anonymous-account.get');
            NotificationActions.showAlertDanger('utilities.generate-anonymous-accounts.sync.error');
            throw err;
        });
    }

    reportError() {
        const payload = {
            uri: 'sample/error/report/endpoint',
            uriBody: '{testing: true}',
            uriMethod: 'GET',
            userError: `Sample error report created at ${Date.now()}`,
        };
        UserActions.reportError(payload.userError, payload.uriMethod, payload.uri, payload.uriBody, UserConstants.USER_ERROR_TYPES.CLOUDFLARE.id, true);
    }

    triggerCloudflare() {
        // Send an API request specifically designed to trigger cloudflare request blocking. Note, what triggers Cloudflare in local/prod differs from QA/UAT envs
        const triggerHtml = '<script>let cloudflareTrigger = true;</script>' +
            '<script>let cloudflareTriggerInQA = true;</script>' +
            '<script>let cloudflareTriggerInUAT = true;</script>' +
            '<div><a title="trigger cloudflare" href="https//www.wbtvd.com?triggerCloudflare=true">' +
            '<img alt="trigger cloudflare" src="https://www.wbtvd.com/assets/075b0ae54c9855601f98.png" /><br />' +
            'Link to WBTVD</a><ul><li>trigger cloudflare in qa</li><li>trigger cloudflare in uat</li>' +
            '<li>trigger cloudflare in prod</li></ul></div>';
        Request.put('web/cloudflare')
            .send({html: triggerHtml})
            .exec().then(() => {
                // eslint-disable-next-line no-console
                console.warn('triggered cloudflare');
            });
    }
}

let actions = new UtilitiesActions();

export {
    actions as UtilitiesActions,
    CONSTANTS as UtilitiesConstants
};
