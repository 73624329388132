/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {BatchConstants} from './batch-actions';
import Validations from '../common/validations/validations';
import Dispatcher from '../dispatcher/dispatcher';
import {ActionHistoryConstants} from '../system/action-history/action-history-actions';

let findInConstant = function(constants, objectId) {
    return Immutable.fromJS(
        Object.keys(constants)
            .map(k => constants[k])
            .filter(obj => objectId === obj.id)[0]
    );
};

let getEmailGroupsNext = function(actionSelected, emailGroups, batchId, internal) {
    let emailGroupsNext = [];
    actionSelected.forEach(group => {
        emailGroupsNext.push({
            scheduleBatchId: batchId,
            groupId: group.get('id'),
            internal: internal
        });
    });

    emailGroups.forEach(g => {
        emailGroupsNext.push({
            scheduleBatchId: batchId,
            groupId: g.get('id'),
            internal: !internal,
        });
    });

    return emailGroupsNext;
};

const BatchValidations = {
    batchName: {
        label: 'scheduling.batch.summary.name',
        validations: [Validations.max(200), Validations.required]
    },
    executionDate: {
        label: 'scheduling.batch.summary.date',
        validations: [Validations.required]
    },
    executionTime: {
        label: 'scheduling.batch.summary.time',
        validations: [
            Validations.required,
            Validations.custom(
                function() {
                    return 'The scheduled batch cannot begin before the current time.';
                },
                function() {
                    let isValid = true;
                    let batch = store.getState().get('batch');
                    if (batch.get('executedAt') || !store.getState().get('batchDuplicated').isEmpty()) {
                        batch = store.getState().get('batchDuplicated');
                    }
                    const timeString = `${batch.get('executionDate')}T${batch.get('executionTime')}`;
                    const executionDateTime = new Date(timeString);
                    if (batch.get('executionTime') && !batch.get('executedAt')) {
                        isValid = executionDateTime.getTime() > Date.now();
                        return isValid;
                    }
                    return isValid;
                }
            )
        ]
    },
};

class BatchStore extends ReduceStore {

    getBatchActiveType(batchActiveTypeId) {
        return findInConstant(BatchConstants.BATCH_ACTIVE_TYPES, batchActiveTypeId);
    }

    getMFAOption(optionId) {
        return findInConstant(BatchConstants.BATCH_VIDEO_MFA_OPTIONS, optionId);
    }

    getTitleSynopsisLogLineDisplayType(optionId) {
        return findInConstant(BatchConstants.TITLE_SYNOPSIS_LOG_LINE_DISPLAY_TYPE, optionId);
    }

    getInitialState() {
        return Immutable.Map({
            batch: Immutable.Map({
                catalogs: Immutable.List(),
                emailGroups: Immutable.List(),
            }),
            batchActiveTypes: Immutable.fromJS(BatchConstants.toArray('BATCH_ACTIVE_TYPES')),
            batchDuplicated: Immutable.Map(),
            batches: Immutable.List(),
            catalogs: Immutable.List(),
            emailGroupsExternal: Immutable.List(),
            emailGroupsInternal: Immutable.List(),
            filters: Immutable.Map(),
            history: Immutable.List(),
            item: Immutable.Map(),
            items: Immutable.List(),
            mfaOptions: Immutable.fromJS(BatchConstants.toArray('BATCH_VIDEO_MFA_OPTIONS')),
            offset: 0,
            originalBatch: Immutable.Map(),
            originalItems: Immutable.List(),
            selectedTitle: Immutable.Map(),
            showAddChildrenDialog: false,
            showDuplicateBatchPreloader: false,
            showItemDetail: false,
            showPreloader: false,
            size: 0,
            total: 0,
            titleSynopsisLogLineDisplayTypeOptions: Immutable.fromJS(BatchConstants.toArray('TITLE_SYNOPSIS_LOG_LINE_DISPLAY_TYPE')),
            userGroups: Immutable.List()
        });
    }

    getValidations() {
        return Validations.validate(this.getState().get('batch'), BatchValidations);
    }

    reduce(state, action) {
        switch (action.actionType) {

        case BatchConstants.BATCHES.GET.ERROR:
        case BatchConstants.CATALOGS.GET.ERROR:
        case BatchConstants.DUPLICATE.SAVE.ERROR:
        case BatchConstants.DUPLICATE.SAVE.SUCCESS:
        case BatchConstants.FIND_BY_ID.ERROR:
        case BatchConstants.ITEMS.ITEM.ADD_CHILDREN.ERROR:
        case BatchConstants.SAVE.ERROR:
            state = state.set('showPreloader', false);
            break;

        case BatchConstants.BATCHES.GET.START:
        case BatchConstants.CATALOGS.GET.START:
        case BatchConstants.DUPLICATE.SAVE.START:
        case BatchConstants.FIND_BY_ID.START:
        case BatchConstants.ITEMS.ITEM.ADD_CHILDREN.START:
        case BatchConstants.SAVE.START:
            state = state.set('showPreloader', true);
            break;

        case BatchConstants.BATCHES.GET.SUCCESS:
            state = state.merge({
                batches: action.batches,
                offset: action.offset,
                showPreloader: false,
                size: action.size,
                total: action.total
            });
            break;

        case BatchConstants.CATALOGS.ADD:
            state = state.updateIn(['batch', 'catalogs'], catalogs => {
                return catalogs.push(action.catalog);
            });
            break;

        case BatchConstants.CATALOGS.GET.SUCCESS:
            state = state.merge({
                catalogs: action.catalogs,
                showPreloader: false
            });
            break;

        case BatchConstants.CATALOGS.REMOVE:
            state = state.updateIn(['batch', 'catalogs'], catalogs => {
                return catalogs.delete(action.index);
            });
            break;

        case BatchConstants.CLEAR:
            state = this.getInitialState();
            break;

        case BatchConstants.FILTER.CLEAR:
            state = state.set('filters', Immutable.Map());
            break;

        case BatchConstants.FILTER.SET:
            state = state.setIn(['filters', ...action.attr.split('.')], action.value);
            break;

        case BatchConstants.FIND_BY_ID.SUCCESS:
            state = state.merge({
                batch: action.batch,
                history: action.history,
                originalBatch: action.batch,
                showPreloader: false,
            });
            break;

        case BatchConstants.SAVE.SUCCESS:
            state = state.merge({
                batch: action.batch,
                originalBatch: action.batch,
                showPreloader: false,
            });
            break;

        case BatchConstants.ITEMS.SET:
            state = state.merge({
                items: action.items,
                originalItems: action.items
            });
            break;

        case BatchConstants.ITEMS.ADD:
            let itemsToAdd = [];
            action.items.forEach(item => {
                if (state.get('items').findIndex(i => item.get('id') === i.get('id')) === -1) {
                    itemsToAdd.push(item);
                }
            });
            state = state.update('items', items => items.concat(Immutable.fromJS(itemsToAdd)));
            state = state.set('showPreloader', false);
            break;

        case BatchConstants.ITEMS.DELETE:
            state = state.update('items', items => {
                return items.filter(i => action.item.get('id') !== i.get('id'));
            });
            break;

        case BatchConstants.ITEMS.ITEM.ADD_CHILDREN_MODAL.TOGGLE:
            state = state.set('showAddChildrenDialog', !state.get('showAddChildrenDialog'));
            if (state.get('showAddChildrenDialog')) {
                state = state.set('selectedTitle', action.title);
            }
            break;

        case BatchConstants.ITEMS.ITEM.DETAIL_MODAL.TOGGLE:
            state = state.set('showItemDetail', !state.get('showItemDetail'));
            break;

        case BatchConstants.ITEMS.ITEM.SET:
            state = state.merge({
                item: action.item
            });
            break;

        case BatchConstants.UPDATE:
            state = state.setIn(['batch', ...action.attr.split('.')], action.value);
            break;

        case BatchConstants.DUPLICATE.CLEAR:
            state = state.merge({
                batchDuplicated: Immutable.Map({})
            });
            break;

        case BatchConstants.DUPLICATE.SET.SUCCESS:
            state = state.merge({
                batchDuplicated: action.batch,
                showDuplicateBatchPreloader: false
            });
            break;

        case BatchConstants.DUPLICATE.SET.ERROR:
            state = state.set('showDuplicateBatchPreloader', false);
            break;

        case BatchConstants.DUPLICATE.SET.START:
            state = state.set('showDuplicateBatchPreloader', true);
            break;

        case BatchConstants.DUPLICATE.UPDATE_ATTR:
            state = state.setIn(['batchDuplicated', ...action.attr.split('.')], action.value);
            break;

        case BatchConstants.EMAIL_GROUPS.EXTERNAL.SET: {
            const batch = state.get('batch');
            const batchId = batch.get('id');
            let emailGroupsInternal = state.get('emailGroupsInternal');

            const emailGroupsNext = getEmailGroupsNext(action.selected, emailGroupsInternal, batchId, false);

            state = state.setIn(['batch', 'emailGroups'], Immutable.List(emailGroupsNext));
            state = state.set('emailGroupsExternal', Immutable.List(action.selected));

            break;
        }

        case BatchConstants.EMAIL_GROUPS.INTERNAL.SET: {
            const batch = state.get('batch');
            const batchId = batch.get('id');
            let emailGroupsExternal = state.get('emailGroupsExternal');

            const emailGroupsNext = getEmailGroupsNext(action.selected, emailGroupsExternal, batchId, true);

            state = state.setIn(['batch', 'emailGroups'], Immutable.List(emailGroupsNext));
            state = state.set('emailGroupsInternal', Immutable.List(action.selected));

            break;
        }

        case BatchConstants.USERGROUPS.SET:
            let userGroups = action.groups;

            let emailGroupsExternal = [];
            let emailGroupsInternal = [];

            const batchEmailGroups = state.get('batch').get('emailGroups');

            batchEmailGroups.forEach(batchGroup => {
                const batchEmailGroupId = batchGroup.get('groupId');
                const batchEmailGroupInternal = batchGroup.get('internal');
                const batchUserGroup = userGroups.find(userGroup => batchEmailGroupId === userGroup.get('id'));

                if (batchEmailGroupInternal) {
                    emailGroupsInternal.push(batchUserGroup);
                } else {
                    emailGroupsExternal.push(batchUserGroup);
                }
            });

            state = state.set('userGroups', Immutable.List(userGroups));
            state = state.set('emailGroupsExternal', Immutable.List(emailGroupsExternal));
            state = state.set('emailGroupsInternal', Immutable.List(emailGroupsInternal));
            break;

        case ActionHistoryConstants.ADD_NOTE.SUCCESS:
            if (action.actionObjectType === ActionHistoryConstants.ACTION_OBJECTS.SCHEDULEBATCH) {
                state = state.update('history', history => history.unshift(action.note));
            }
            break;
        }

        return state;
    }
}

const store = new BatchStore(Dispatcher);
export default store;
export {BatchValidations};
