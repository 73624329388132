/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React from 'react';

import {PermissionsAuditActions} from './permissions-audit-actions';
import PermissionsAuditStore from './permissions-audit-store';
import Permissions from '../../../roles/create/permissions';
import {PermissionActions} from '../../../security/permission/permission-actions';
import PermissionStore from '../../../security/permission/permission-store';

class PermissionsAudit extends React.Component {
    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get propTypes() {
        return {
            disabled: PropTypes.bool.isRequired,
            userId: PropTypes.string.isRequired
        };
    }

    static calculateState() {
        let state = PermissionStore.getState();

        return {
            APIPermissions: state.get('API'),
            CMSPermissions: state.get('CMS'),
            FYCPermissions: state.get('FYC'),
            permissionsData: PermissionsAuditStore.getState().get('permissionsData'),
            WBTVDPermissions: state.get('WBTVD')
        };
    }

    static getStores() {
        return [PermissionStore, PermissionsAuditStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();
    }

    componentDidMount() {
        PermissionActions.getPermissionTree();
        PermissionsAuditActions.getPermissions(this.props.userId);
    }

    render() {
        return (
            <div>
                <Permissions
                    disabled={this.props.disabled}
                    APIPermissions={this.state.APIPermissions}
                    CMSPermissions={this.state.CMSPermissions}
                    FYCPermissions={this.state.FYCPermissions}
                    permissionsData={this.state.permissionsData}
                    WBTVDPermissions={this.state.WBTVDPermissions}
                />
            </div>
        );
    }
}

export default Container.create(PermissionsAudit);
