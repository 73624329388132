/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';
import Moment from 'moment';

import {AlertTypes} from '../../common/notification/alert';
import {NotificationActions} from '../../common/notification/notification-actions';
import {UploadFile} from '../../common/utils/utils';
import Config from '../../config/config.js';
import Dispatcher from '../../dispatcher/dispatcher';
import Request from '../../request';
import {RouterActions} from '../../router/router-actions';
import {ActionHistoryConstants} from '../../system/action-history/action-history-actions';

require('moment-timezone');
let configtz = Moment().tz(Config.DefaultTimezone).format('ZZ');


const CONSTANTS = {
    FILTER: {
        CLEAR: 'title.mass_add.filter.clear',
        SET: 'title.mass_add.filter.set'
    },
    INGEST_HEADERS_STATUS_TYPES: {
        PENDING: {id: 'PENDING', name: 'Pending'},
        PROCESSED: {id: 'PROCESSED', name: 'Processed'},
        PROCESSING: {id: 'PROCESSING', name: 'Processing'},
        REJECTED: {id: 'REJECTED', name: 'Rejected'},
        FAILED: {id: 'FAILED', name: 'Failed'},
        CONTAINS_ERRORS: {id: 'CONTAINS_ERRORS', name: 'Contains Errors'},
        NOT_PROCESSED: {id: 'NOT_PROCESSED', name: 'Not Processed'}
    },
    MASS_ADD: {
        TOGGLE_SELECT: 'title.mass_update.select',
        TOGGLE_SELECT_ALL: 'title.mass_update.select.all',
        ADD: 'title.mass_update.add',
        CLEAR: 'title.mass_update.clear',
        RELATED_TITLE: {
            ADD: 'title.mass_update.related_title.add',
            CLEAR: 'title.mass_update.related_title.clear'
        },
        FINDBY: {
            SUCCESS: 'title.mass_update.find_by.success',
            ERROR: 'title.mass_update.find_by.error',
            START: 'title.mass_update.find_by.start'
        },
        SAVE: {
            SUCCESS: 'title.mass_update.save.success',
            ERROR: 'title.mass_update.save.error'
        },
        GET: {
            ERROR: 'title.mass_add_update.get.error',
            START: 'title.mass_add_update.get.start',
            SUCCESS: 'title.mass_add_update.get.success'
        }
    }
};

class MassAddUpdateActions {

    addRelatedTitle(title) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.MASS_ADD.RELATED_TITLE.ADD,
            title: title,
        });

    }

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.MASS_ADD.CLEAR
        });
    }

    clearRelatedTitle() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.MASS_ADD.RELATED_TITLE.CLEAR
        });
    }

    findById(titleIngestHeaderId) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.MASS_ADD.FINDBY.START,
            fileId: titleIngestHeaderId
        });
        let actionHistoryQuery = {
            'action-object': ActionHistoryConstants.ACTION_OBJECTS.TITLEINGESTHEADER,
            'object-id': titleIngestHeaderId,
            offset: 0,
            size: 50
        };
        Promise.all([
            Request.get(`title/ingest/${titleIngestHeaderId}`).exec(),
            Request.get('system/action-history').query(actionHistoryQuery).exec(),
            Request.get(`title/ingest/${titleIngestHeaderId}/rows`).exec()
        ])
            .spread((titleIngestHeaderRes, history, rowsRes) => {
                let titleIngestHeader = titleIngestHeaderRes.body;
                let titleRowList = rowsRes.body;

                titleIngestHeader.history = history.body.results;
                titleIngestHeader.history.sort(/*istanbul ignore next*/(h1, h2) => h2.actionDate.localeCompare(h1.actionDate));

                Dispatcher.dispatch({
                    actionType: CONSTANTS.MASS_ADD.FINDBY.SUCCESS,
                    massAddProcess: Immutable.fromJS(titleIngestHeader),
                    rows:Immutable.fromJS(titleRowList)
                });
                if (titleIngestHeader.parentTitleId) {
                    return Request.get(`title/${titleIngestHeader.parentTitleId}`).exec();
                }

            })
            .then(titleRes => {
                if (!titleRes) {
                    return;
                }
                this.addRelatedTitle(Immutable.fromJS(titleRes.body));
            })
            .catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.MASS_ADD.FINDBY.ERROR,
                    error: err
                });

                switch (err.status) {
                case 404:
                    RouterActions.notFound();
                    break;
                default:
                    NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'titles.mass-add.load.error');
                    break;
                }
                throw err;
            });
    }

    get(queryParams, offset, size) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.MASS_ADD.GET.START
        });

        queryParams = queryParams.toJS();

        ['start-date-created', 'start-date-updated'].forEach(/*istanbul ignore next*/attr => {
            let d = queryParams[attr];
            if (d) {
                d = Moment(d);
                if (d.isValid()) {
                    d = d.format('YYYY-MM-DDT00:00:00.000'+configtz);
                } else {
                    d = '';
                }
                queryParams[attr] = d;
            }
        });

        ['end-date-created', 'end-date-updated'].forEach(/*istanbul ignore next*/attr => {
            let d = queryParams[attr];
            if (d) {
                d = Moment(d);
                if (d.isValid()) {
                    d = d.format('YYYY-MM-DDT23:59:59.999'+configtz);
                } else {
                    d = '';
                }
                queryParams[attr] = d;
            }
        });

        offset = offset || queryParams.offset || 0;
        size = size || 20;
        queryParams.offset = offset ;
        queryParams.size = size;

        if (queryParams.status && !Array.isArray(queryParams.status)) {
            queryParams.status = [queryParams.status];
        }

        let titlesIngestedHeaderList;
        let offsetResponse, sizeResponse, totalResponse;

        Request.get('title/ingest')
            .query(queryParams)
            .exec()
            .then(response => {
                titlesIngestedHeaderList = response.body.results;
                offsetResponse = response.body.offset;
                sizeResponse = response.body.size;
                totalResponse = response.header['wbtv-total-count'];

                // Get rows for each titleHeader
                const rowsRequests = titlesIngestedHeaderList.reduce((rows, title) => {
                    rows[title.titleIngestHeaderId.toString()] = Request.get(`title/ingest/${title.titleIngestHeaderId}/rows`).exec().catch(err => {
                        NotificationActions.showAlert(
                            AlertTypes.ALERT_DANGER.name,
                            'sidebar.titles.mass-add/update.logs.get.ingested-row.error',
                            title.titleIngestHeaderId,
                        );
                        return err;
                    });
                    return rows;
                }, {});

                return Promise.props(rowsRequests);
            }).then(responses => {
                titlesIngestedHeaderList = titlesIngestedHeaderList.map(title => {
                    title.rows = [];
                    if (responses[title.titleIngestHeaderId.toString()]) {
                        title.rows = responses[title.titleIngestHeaderId.toString()].body;
                    }
                    return title;
                });

                // Get related title for each titleHeader
                const relatedTitlesRequests = titlesIngestedHeaderList.reduce((titles, title) => {
                    if (title.parentTitleId) {
                        titles[title.titleIngestHeaderId.toString()] = Request.get(`title/${title.parentTitleId}`).exec().catch(err => {
                            NotificationActions.showAlert(
                                AlertTypes.ALERT_DANGER.name,
                                'sidebar.titles.mass-add/update.logs.get.related-titles.error',
                                title.parentTitleId,
                            );
                            return err;
                        });
                    }
                    return titles;
                }, {});

                return Promise.props(relatedTitlesRequests);
            }).then(responses => {
                // Adding related title name to eacch titleHeader item
                titlesIngestedHeaderList = titlesIngestedHeaderList.map(title => {
                    title.relatedTitle = '';
                    if (responses[title.titleIngestHeaderId.toString()]) {
                        title.relatedTitle = responses[title.titleIngestHeaderId.toString()].body.name || '';
                    }
                    return title;
                });

                Dispatcher.dispatch({
                    actionType: CONSTANTS.MASS_ADD.GET.SUCCESS,
                    titlesLogs: Immutable.fromJS(titlesIngestedHeaderList),
                    offset: offsetResponse,
                    size: sizeResponse,
                    total: totalResponse
                });
            }).catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.MASS_ADD.GET.ERROR
                });

                NotificationActions.showAlertDanger('sidebar.titles.mass-add/update.logs.get.error');

                throw err;
            });
    }

    setFilter(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.FILTER.SET,
            attr: attr,
            value: value
        });
    }

    toggleSelect(value, index) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.MASS_ADD.TOGGLE_SELECT,
            index: index,
            value: value
        });
    }

    toggleSelectAll(value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.MASS_ADD.TOGGLE_SELECT_ALL,
            value: value
        });
    }

    updateRowStatus(rows, titleIngestHeader, status) {
        titleIngestHeader = titleIngestHeader.toJS();

        let titleIngestHeaderId = titleIngestHeader.titleIngestHeaderId;

        let updatedData = rows.toJS().map(data => {
            if (status === 'Reject all') {
                data.status = 'Rejected';
            } else {
                if (data.__selected) {
                    data.status = 'Confirmed';
                } else {
                    data.status = 'Rejected';
                }
            }
            delete data.__selected;
            return data;
        });

        let promise = Promise.resolve();

        if (titleIngestHeader.parentTitleId) {
            delete titleIngestHeader.history;
            promise = Request.put(`title/ingest/${titleIngestHeaderId}`).send(titleIngestHeader).exec();
        }

        return promise.then(
            () => Request.put(`title/ingest/${titleIngestHeaderId}/rows`).send(updatedData).exec()
        ).then(
            () => Request.post(`title/ingest/process/${titleIngestHeaderId}`).exec()
        ).then(
            () => RouterActions.redirect(`/titles/mass-add/${titleIngestHeaderId}/confirmation`)
        ).catch(err => {
            NotificationActions.showAlertDanger('sidebar.titles.mass-add/update.logs.put.ingested-row.error');

            throw err;
        });
    }

    uploadMassAddUpdate(titleIngestHeader, mode) {
        if (titleIngestHeader) {
            return UploadFile(
                'POST',
                'title/ingest',
                titleIngestHeader,
                new XMLHttpRequest()
            ).then((titleIngestHeaderRes) => {
                let id = JSON.parse(titleIngestHeaderRes).titleIngestHeaderId;
                if (mode === 'edit') {
                    RouterActions.redirect(`/titles/mass-update/${id}`);
                } else {
                    RouterActions.redirect(`/titles/mass-add/${id}`);
                }
            }).catch(err => {
                let errMsg = 'titles.mass-add.process.error';
                if (mode === 'edit') {
                    errMsg ='titles.mass-update.process.error';
                }
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, errMsg);
                throw err;
            });
        }
    }
}

let actions = new MassAddUpdateActions();

export {
    actions as MassAddUpdateActions,
    CONSTANTS as MassAddUpdateConstants
};
