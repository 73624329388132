/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ControlLabel, FormControl, FormGroup} from 'react-bootstrap';
import {Link} from 'react-router';

import GetBrainiacInfo from './get-brainiac-info';
import GetExistingTitlePanel from './get-existing-title-panel';
import PullFromOAP from './pull-from-oap';
import SendToOAP from './send-to-oap';
import Workflow from './workflow';
import {FormRow, FormSection} from '../../../common/form/form';
import {SectionPanel} from '../../../common/panel/panel';
import SlidingPanel from '../../../common/sliding-panel/sliding-panel';
import {SlidingPanelActions} from '../../../common/sliding-panel/sliding-panel-actions';
import {TimecodeFromSeconds, TimecodeToString} from '../../../common/utils/timecode';
import {WithValidations} from '../../../common/validations/validations';
import config from '../../../config/config.js';
import {VideoDeliveryConstants} from '../video-delivery-actions.js';
import VideoDeliveryStore from '../video-delivery-store';

class Summary extends Component {

    static get propTypes() {
        return {
            isDisabled: PropTypes.bool,
            location: PropTypes.object.isRequired,
            selectedTitle: PropTypes.instanceOf(Immutable.Map).isRequired,
            videoDelivery: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get defaultProps() {
        return {
            isDisabled: false,
            player: undefined,
            sasURL: undefined
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);
    }

    componentWillUnmount() {
        SlidingPanelActions.hide('getTitleInfoInVideoDelivery');
    }

    handleCancelGetTitleInfo() {
        SlidingPanelActions.hide('getTitleInfoInVideoDelivery');
    }

    render() {
        let {
            approvedBy,
            approvedByUserName,
            createdDate,
            displayName,
            frameRate,
            rejectedBy,
            rejectedByUserName,
            runtime,
            proxyFileLocation,
            sourceFileLocation,
            status,
            updatedDate
        } = this.props.videoDelivery.toJS();

        let updatedDateFormated = '-';
        if (updatedDate) {
            updatedDateFormated = Moment(updatedDate).tz(config.DefaultTimezone).format(this.context.intl.messages['datetime-format']);
        }

        let createdDateFormated = '-';
        if (createdDate) {
            createdDateFormated = Moment(createdDate).tz(config.DefaultTimezone).format(this.context.intl.messages['datetime-format']);
        }

        if (approvedBy) {
            approvedBy = (
                <Link to={`/accounts/${approvedBy}`}>{approvedByUserName}</Link>
            );
        }

        if (rejectedBy) {
            rejectedBy = (
                <Link to={`/accounts/${rejectedBy}`}>{rejectedByUserName}</Link>
            );
        }

        if (runtime) {
            runtime = TimecodeToString(TimecodeFromSeconds(runtime, frameRate));
        }

        let firstFrameTimecode = '-';
        if (this.props.videoDelivery.get('firstFrameTimecode')) {
            firstFrameTimecode = TimecodeToString(this.props.videoDelivery.get('firstFrameTimecode'));
        }

        let mediaInfo;
        try {
            mediaInfo = <pre>{JSON.stringify(JSON.parse(JSON.parse(this.props.videoDelivery.get('mediaInfo'))), null, 2)}</pre>;
        } catch (err) {
            mediaInfo = (
                <div>
                    <em>{this.context.intl.messages['hardac.video-inbox.media-info.error']}</em>
                    <pre>${this.props.videoDelivery.get('mediaInfo')}`</pre>
                </div>
            );
        }

        let statusName = '-';
        const statusObject = VideoDeliveryStore.getStatus(status);
        if (statusObject) {
            statusName = statusObject.name;
        }

        const fileInfo = <FormSection>
            <FormGroup>
                <ControlLabel>{this.context.intl.messages['hardac.video-inbox.source-file']}</ControlLabel>
                <FormControl disabled type="text" value={sourceFileLocation || '-'} />
            </FormGroup>
            <FormGroup>
                <ControlLabel>{this.context.intl.messages['hardac.video-inbox.proxy-file-location']}</ControlLabel>
                <FormControl disabled type="text" value={proxyFileLocation || '-'} />
            </FormGroup>
            <FormGroup>
                <ControlLabel>{this.context.intl.messages['hardac.video-inbox.file-name']}</ControlLabel>
                <FormControl disabled type="text" value={displayName || '-'} />
            </FormGroup>
            <FormGroup>
                <ControlLabel>{this.context.intl.messages['hardac.video-inbox.status']}</ControlLabel>
                <FormControl disabled type="text" value={statusName} />
            </FormGroup>
            <hr />
            <FormRow>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['hardac.video-inbox.frame-rate']}</ControlLabel>
                    <FormControl disabled type="text" value={frameRate || '-'} />
                </FormGroup>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['hardac.video-inbox.duration']}</ControlLabel>
                    <FormControl disabled type="text" value={runtime || '-'} />
                </FormGroup>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['hardac.video-inbox.start-time']}</ControlLabel>
                    <FormControl disabled type="text" value={firstFrameTimecode || '-'} />
                </FormGroup>
            </FormRow>
        </FormSection>;

        let sendHardacVideoToOAP;
        if (this.props.videoDelivery.get('syncOptionType') === VideoDeliveryConstants.SYNC_TO_OAP_TYPES.SEND_TO_OAP.id) {
            sendHardacVideoToOAP = <SendToOAP
                isDisabled={this.props.isDisabled}
                videoDelivery={this.props.videoDelivery}
            />;
        }

        let pullAudioProfileFromOAP;
        if (this.props.videoDelivery.get('syncOptionType') === VideoDeliveryConstants.SYNC_TO_OAP_TYPES.PULL_FROM_OAP.id) {
            pullAudioProfileFromOAP = <PullFromOAP
                isDisabled={this.props.isDisabled}
                videoDelivery={this.props.videoDelivery}
            />;
        }

        return (
            <div>
                <GetBrainiacInfo isDisabled={this.props.isDisabled} selectedTitle={this.props.selectedTitle} videoDelivery={this.props.videoDelivery} />
                <Workflow isDisabled={this.props.isDisabled} videoDelivery={this.props.videoDelivery} />
                {pullAudioProfileFromOAP}
                {sendHardacVideoToOAP}
                <SectionPanel title={this.context.intl.messages['hardac.processes.summary.info']}>{fileInfo}</SectionPanel>
                <SectionPanel title={this.context.intl.messages['hardac.video-inbox.media-info.title']}>{mediaInfo}</SectionPanel>
                <hr />
                <FormRow>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['hardac.video-inbox.approved-by']}</ControlLabel>
                        <FormControl.Static>{approvedBy || '-'}</FormControl.Static>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['hardac.video-inbox.rejected-by']}</ControlLabel>
                        <FormControl.Static>{rejectedBy || '-'}</FormControl.Static>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['hardac.video-inbox.created-date']}</ControlLabel>
                        <FormControl.Static>{createdDateFormated || '-'}</FormControl.Static>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['hardac.video-inbox.updated-date']}</ControlLabel>
                        <FormControl.Static>{updatedDateFormated || '-'}</FormControl.Static>
                    </FormGroup>
                </FormRow>
                <SlidingPanel
                    icon="fas fa-plus-circle"
                    id="getTitleInfoInVideoDelivery"
                    onCancel={this.handleCancelGetTitleInfo}
                    title={this.context.intl.messages['hardac.video-inbox.titles.add-title']}
                >
                    <GetExistingTitlePanel slidingPanelId="getTitleInfoInVideoDelivery" location={this.props.location} />
                </SlidingPanel>
            </div>
        );
    }
}

export default WithValidations(Summary);
