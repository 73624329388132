/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import createReactClass from 'create-react-class';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Row} from 'react-bootstrap';

import AddEditModal from './add-edit-modal';
import RemoveModal from './remove-modal';
import Entry from '../entry/entry.js';

export default createReactClass({
    propTypes: {
        actions: PropTypes.object.isRequired,
        attrName: PropTypes.string,
        editModal: PropTypes.func,
        formatter: PropTypes.func,
        permissions: PropTypes.object.isRequired,
        store: PropTypes.object.isRequired,
        title: PropTypes.string.isRequired,
    },

    contextTypes: {
        intl: PropTypes.object.isRequired
    },

    getDefaultProps: function() {
        return {
            attrName: 'name',
            editModal: AddEditModal,
            formatter: value => value,
        };
    },

    getInitialState: function() {
        return {
            items: this.props.store.getState().get('items'),
            loaded: this.props.store.getState().get('loaded'),
            loading: this.props.store.getState().get('loading'),
            showAddEditModal: false,
            showRemoveModal: false
        };
    },

    componentWillMount: function() {
        this.storeListeners = [
            this.props.store.addListener(() => {
                this.setState({
                    items: this.props.store.getState().get('items'),
                    loaded: this.props.store.getState().get('loaded'),
                    loading: this.props.store.getState().get('loading')
                });
            })
        ];
        return;
    },

    componentDidMount: function() {
        if (!this.state.loaded && !this.state.loading) {
            this.props.actions.get(0, 9999);
        }
        return;
    },

    shouldComponentUpdate: function(nextProps, nextState) {
        if (nextState.items !== this.state.items ||
            nextState.loaded !== this.state.loaded ||
            nextState.loading !== this.state.loading ||
            nextState.showAddEditModal !== this.state.showAddEditModal ||
            nextState.showRemoveModal !== this.state.showRemoveModal ||
            nextState.selected !== this.state.selected) {
            return true;
        }
        return false;
    },

    componentWillUnmount: function() {
        this.storeListeners.forEach(listener => listener.remove());
    },

    handleAdd: function() {
        this.setState({
            selected:Immutable.Map(),
            showAddEditModal:true
        });
    },

    handleEdit: function(item) {
        return () => {
            this.setState({
                selected:item,
                showAddEditModal:true
            });
        };
    },

    handleHideAdd: function() {
        this.setState({
            showAddEditModal:false
        });
    },

    handleHideRemove: function() {
        this.setState({
            showRemoveModal:false
        });
    },

    handleRemove: function(item) {
        return () => {
            this.setState({
                selected:item,
                showRemoveModal:true
            });
        };
    },

    render: function() {
        let editModal = React.createElement(this.props.editModal, {
            attrName: this.props.attrName,
            item:this.state.selected,
            onHide:this.handleHideAdd,
            onSave:this.props.actions.save.bind(this.props.actions),
            show:this.state.showAddEditModal,
            title:this.props.title,
            items:this.state.items
        });
        let removeModal = <RemoveModal
            item={this.state.selected}
            onHide={this.handleHideRemove}
            onRemove={this.props.actions.remove.bind(this.props.actions)}
            show={this.state.showRemoveModal}
            title={this.props.title}/>;

        let addButton = <Button
            className="margin-left-20"
            bsStyle="primary"
            onClick={this.handleAdd}><i className="fas fa-plus"/>&nbsp;{this.context.intl.messages['common.add-new']}
        </Button>;

        return (
            <div>
                {(this.props.permissions.canEdit || this.props.permissions.canCreate) && editModal}
                {this.props.permissions.canEdit && removeModal}
                <h4 className="page-header">{this.props.title}
                    {this.props.permissions.canCreate && addButton}
                </h4>
                <Row>
                    {this.state.items.map( item => {
                        const value = this.props.formatter(item.get(this.props.attrName));
                        return (
                            <Entry key={item.get('id')}
                                onEdit={this.handleEdit(item)}
                                onRemove={this.handleRemove(item)}
                                readOnly={!this.props.permissions.canEdit}
                                value={value}
                            />
                        );
                    })}
                </Row>
            </div>
        );
    }
});
