/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, Col, Row} from 'react-bootstrap';

import AssignedTable from './assigned';
import {MassUpdateActions, MassUpdateConstants} from './mass-update-actions';
import MassUpdateStore from './mass-update-store';
import UnassignedTable from './unassigned';
import DocumentTitle from '../../../common/document-title';
import {FormSection} from '../../../common/form/form';
import SearchBox from '../../../common/search-box/search-box';
import {Debounce} from '../../../common/utils/utils';
import ActionsMenu from '../../../layout/actions-menu/actions-menu';
import StickyHeader from '../../../layout/sticky-header/sticky-header';
import {RouterActions} from '../../../router/router-actions';

class Confirmation extends Component {

    static get propTypes() {
        return {
            params: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            assignedCatalogs: MassUpdateStore.getState().get('assignedCatalogs'),
            catalogs: MassUpdateStore.getState().get('catalogs'),
            filters: '',
            massUpdateProcess: MassUpdateStore.getState().get('massUpdateProcess'),
            mode: MassUpdateStore.getState().get('mode'),
            originalAssignedCatalogs: MassUpdateStore.getState().get('originalAssignedCatalogs'),
            removeList: MassUpdateStore.getState().get('removeList'),
            titles: MassUpdateStore.getState().get('titles')
        };
    }

    static getStores() {
        return [MassUpdateStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.handleBack = this.handleBack.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
    }

    componentDidMount() {
        MassUpdateActions.getCatalogs();
        MassUpdateActions.getConfirmationData(this.props.params.id);
        return;
    }

    handleBack() {
        MassUpdateActions.clearRemoveList();
        RouterActions.redirect(`/catalogs/title/mass-update/${this.props.params.id}`);
    }

    handleSave(action) {
        let catalogs;
        if (this.state.mode === 'remove') {
            catalogs = this.state.removeList;
        } else {
            catalogs = this.state.assignedCatalogs;
        }

        MassUpdateActions.save(
            action,
            this.props.params.id,
            catalogs,
            this.state.massUpdateProcess,
            this.state.originalAssignedCatalogs);
    }

    handleSearchTerm(term) {
        this.setState({
            'filters': term
        });
        return;
    }

    render() {
        let actionsMenu;
        let assignedCatalogsCount = this.state.assignedCatalogs.size;
        let totalTitles = '-';
        if (this.state.titles) {
            totalTitles = this.state.titles.size;
        }
        let unassignedCatalogs;

        switch (this.state.mode) {
        case 'update':
            actionsMenu = <ActionsMenu>
                <Button
                    bsSize="large"
                    className="btn btn-primary-outline Mr(3px) Mb(3px)"
                    onClick={this.handleSave.bind(this, MassUpdateConstants.MASS_UPDATE.OPERATION_TYPES.REPLACE)}
                >
                    <i className="fas fa-pencil-alt"/>&nbsp;{this.context.intl.messages['catalogs.mass-update.button.replace']}
                </Button>
                <Button
                    bsSize="large"
                    className="btn btn-primary-outline Mr(3px) Mb(3px)"
                    onClick={this.handleSave.bind(this, MassUpdateConstants.MASS_UPDATE.OPERATION_TYPES.ADD)}
                >
                    <i className="fas fa-plus"/>&nbsp;{this.context.intl.messages['catalogs.mass-update.button.add-to-existing']}
                </Button>
                <Button
                    bsSize="large"
                    className="btn btn-default-outline Mr(3px) Mb(3px)"
                    onClick={this.handleBack}
                >
                    <i className="fas fa-chevron-left"/>&nbsp;{this.context.intl.messages['catalogs.mass-update.button.back']}
                </Button>
            </ActionsMenu>;

            unassignedCatalogs = <div><h4>{this.context.intl.messages['asset.edit.catalogs-tab.unassigned-title']}</h4>
                <FormSection>
                    <div className="container-fluid no-x-padding">
                        <div className="row">
                            <div className="col-md-3"></div>
                            <div className="col-md-6 show-grid">
                                <SearchBox value={this.state.filters} onChange={this.handleSearchTerm}/>
                            </div>
                            <div className="col-md-3"></div>
                        </div>
                    </div>
                    <UnassignedTable
                        assignedCatalogs={this.state.assignedCatalogs}
                        catalogs={this.state.catalogs}
                        filters={this.state.filters}
                    />
                </FormSection>
            </div>;
            break;
        case 'remove':
            actionsMenu = <ActionsMenu>
                <Button
                    bsSize="large"
                    className="btn btn-danger-outline Mr(3px) Mb(3px)"
                    onClick={this.handleSave.bind(this, MassUpdateConstants.MASS_UPDATE.OPERATION_TYPES.REMOVE)}
                >
                    <i className="fas fa-plus"/>&nbsp;{this.context.intl.messages['catalogs.mass-update.button.remove-selected']}
                </Button>
                <Button
                    bsSize="large"
                    className="btn btn-default-outline Mr(3px) Mb(3px)"
                    onClick={this.handleBack}
                >
                    <i className="fas fa-chevron-left"/>&nbsp;{this.context.intl.messages['catalogs.mass-update.button.back']}
                </Button>
            </ActionsMenu>;
            break;
        }

        return (
            <DocumentTitle message="document-titles.catalogs-title.mass-update">
                <div>
                    <StickyHeader>
                        <div className="col-md-6">
                            <h1><i className="fas fa-book"></i>&nbsp;{this.context.intl.messages['catalogs.mass-update-title.title']}
                                &nbsp;<small>{totalTitles} {this.context.intl.messages['catalogs.mass-update-title-length']}</small>
                            </h1>
                        </div>
                        {actionsMenu}
                    </StickyHeader>
                    <section className="content" id="contentContainer">
                        <Row>
                            <Col xs={12}>
                                <div className="box">
                                    <div className="box-body padding-x-20">
                                        <h3>{this.context.intl.messages['catalogs.mass-update.step-three.title']}</h3>
                                        <div className="padding-y-20">
                                            <hr/>
                                            <h4>{this.context.intl.messages['asset.edit.catalogs-tab.assigned-title']}&nbsp;<small>&nbsp;{assignedCatalogsCount}</small></h4>
                                            <FormSection>
                                                <AssignedTable
                                                    assignedCatalogs={this.state.assignedCatalogs}
                                                    mode={this.state.mode}
                                                    removeList={this.state.removeList}
                                                />
                                            </FormSection>
                                            <hr/>
                                            {unassignedCatalogs}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </section>
                </div>
            </DocumentTitle>
        );
    }
}

export default Container.create(Confirmation);
