/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import ReactTable from 'react-table';

import Pagination from '~/src/common/table/pagination';

import 'react-table/react-table.css';

class UnassignedTable extends Component {
    static get propTypes() {
        return {
            activePage: PropTypes.number.isRequired,
            assignedUserGroups: PropTypes.instanceOf(Immutable.List).isRequired, // eslint-disable-line react/no-unused-prop-types
            onAdd: PropTypes.func.isRequired,
            onPageChange: PropTypes.func.isRequired,
            totalPages: PropTypes.number.isRequired,
            userGroups: PropTypes.instanceOf(Immutable.List).isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.handleAddUserGroup = this.handleAddUserGroup.bind(this);
    }

    handleAddUserGroup(event) {
        let id, userGroup;
        switch (true) {
        case !!~event.target.className.indexOf('add-user-group'):
            id = parseInt(event.target.getAttribute('data-user-group-id'), 10);
            userGroup = this.props.userGroups.find(c => c.get('id') === id);
            this.props.onAdd(userGroup);
            break;
        case !!~event.target.className.indexOf('fa-plus'):
            id = parseInt(event.target.parentNode.getAttribute('data-user-group-id'), 10);
            userGroup = this.props.userGroups.find(c => c.get('id') === id);
            this.props.onAdd(userGroup);
            break;
        }
        return;
    }

    render() {
        const unassignedUserGroups = this.props.userGroups.filter(c =>
            !this.props.assignedUserGroups.find(assignedUserGroup => c.get('id') === assignedUserGroup.get('id'))
        );

        const columns = [{
            Header: <strong className="sorting_desc">{this.context.intl.messages['authorized-partners.edit.user-groups.table.name']}</strong>,
            maxWidth: 225,
            Cell: c => {
                let userGroupName = c.original.get('name') || '-';
                return <div>{userGroupName}</div>;
            }
        }, {
            Header: <strong>{this.context.intl.messages['authorized-partners.edit.user-groups.table.description']}</strong>,
            Cell: c => {
                const description = c.original.get('description') || '-';
                return <div>{description}</div>;
            }
        }, {
            Header: <strong>{this.context.intl.messages['authorized-partners.edit.user-groups.table.status']}</strong>,
            maxWidth: 95,
            Cell: c => {
                let userGroupStatus = this.context.intl.messages['common.inactive'];
                if (c.original.get('isActive') === 1) {
                    userGroupStatus = this.context.intl.messages['common.active'];
                }
                return <div>{userGroupStatus}</div>;
            }
        }, {
            Header: <strong>{this.context.intl.messages['authorized-partners.edit.user-groups.table.actions']}</strong>,
            maxWidth: 80,
            Cell: c => {
                return (
                    <button className="btn btn-small bg-wb-blue add-user-group" data-user-group-id={c.original.get('id')} onClick={this.handleAddUserGroup}>
                        <i className="fas fa-plus"></i>&nbsp;{this.context.intl.messages['common.add']}
                    </button>
                );
            }
        }];

        const pagination = <div className="row">
            <div className="col-sm-7 col-sm-offset-5">
                <Pagination
                    activePage={this.props.activePage}
                    onChange={this.props.onPageChange}
                    totalPages={this.props.totalPages}
                />
            </div>
        </div>;

        return (
            <div>
                <ReactTable
                    className="-striped table table-bordered table-striped responsive"
                    columns={columns}
                    data={unassignedUserGroups}
                    getNoDataProps= {() => {
                        if (unassignedUserGroups.size) {
                            return {style: {display: 'none'}};
                        }
                        return {};
                    }}
                    id="unassigned-user-groups-table"
                    loading={false}
                    pageSize={20}
                    showPagination={false}
                    sortable={true}
                    resizable={true}
                />
                {pagination}
            </div>
        );
    }
}

export default UnassignedTable;
