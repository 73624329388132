/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';

import {TerritoryActions} from '../territory/territory-actions';
import TerritoryStore from '../territory/territory-store';

class CountrySelect extends React.Component {

    static getStores() {
        return [TerritoryStore];
    }

    static get propTypes() {
        return {
            disabled: PropTypes.bool,
            isClearable: PropTypes.bool,
            isMulti: PropTypes.bool,
            onChange: PropTypes.func.isRequired,
            selectedOptions: PropTypes.arrayOf(PropTypes.string)
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            countries: TerritoryStore.getState().get('items')
        };
    }

    static get defaultProps() {
        return {
            disabled: true,
            isClearable: true,
            isMulti: true,
            selectedOptions: []
        };
    }

    componentDidMount() {
        if (TerritoryStore.isEmpty()) {
            TerritoryActions.get(0, 99999);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.selectedOptions !== this.props.selectedOptions) {
            return true;
        }
        if (nextState.countries !== this.state.countries) {
            return true;
        }
        return false;
    }

    render() {
        const options = this.state.countries.toJS();
        const selectedOptions = options.filter(option => {
            if (this.props.selectedOptions.includes(option.name)) {
                return option;
            }
        });

        return (
            <Select
                getOptionLabel={data => data.name}
                getOptionValue={data => data.name}
                isClearable={this.props.isClearable}
                isMulti={this.props.isMulti}
                isDisabled={this.props.disabled}
                name="country"
                onChange={this.props.onChange}
                options={options}
                placeholder={this.context.intl.messages['common.country-select.placeholder']}
                value={selectedOptions}
            />

        );
    }
}

export default Container.create(CountrySelect);
