/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React from 'react';
import {ResponsiveEmbed} from 'react-bootstrap';

import HelpStore from './help-store';
import {VideoActions} from '../assets/video/video-actions';
import VideoStore from '../assets/video/video-store';
import DocumentTitle from '../common/document-title';
import {GetThumbnail} from '../common/utils/utils';
import Player from '../player';
import Preloader from '../preloader';

class VideoDetail extends React.Component {

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            isLoading: HelpStore.getState().get('isLoading'),
            item: HelpStore.getState().get('item'),
            playableVideo: VideoStore.getState().get('asset'),
            showPlayer: VideoStore.getState().get('showPlayer'),
            video: VideoStore.getState().get('asset')
        };
    }

    static getStores() {
        return [HelpStore, VideoStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();
        this.state.loading = true;

        this.handleShowPlayer = this.handleShowPlayer.bind(this);

    }

    componentDidMount() {
        VideoActions.clear();
    }

    handleShowPlayer() {
        VideoActions.showPlayer(this.state.item.get('video'));
        return;
    }

    render() {
        let description;
        let image;
        let thumbnail;
        let title = this.context.intl.messages['document-titles.help-videos'];
        let video;

        if (this.state.item !== undefined) {
            title = this.state.item.get('publishListDisplayName');
            description = this.state.item.get('description');
            thumbnail = GetThumbnail(this.state.item.getIn(['video', 'thumbnails']), 1200);
            video = this.state.item.get('video');
            if (thumbnail) {
                image = thumbnail.get('thumbnailUrl');
            }
        }

        let videoRenderer = (
            <ResponsiveEmbed a16by9>
                <div>
                    <img
                        className="img-thumbnail"
                        src={image}
                    />
                    <span
                        className="thumbnail-play-icon glyphicon glyphicon-play-circle"
                        onClick={this.handleShowPlayer.bind(this, video)}
                    ></span>
                </div>
            </ResponsiveEmbed>
        );

        if (this.state.showPlayer) {
            videoRenderer = (
                <ResponsiveEmbed a16by9>
                    <Player
                        position="static"
                        video={this.state.playableVideo}
                        src={VideoStore.convertVideoAssetToPlayerSource(this.state.playableVideo)}
                    />
                </ResponsiveEmbed>
            );
        }

        return (
            <DocumentTitle message="document-titles.help-videos">
                <div>
                    <Preloader
                        fixed
                        show={this.state.isLoading}
                    />
                    <section className="content-header">
                        <h1><i className="fas fa-info-circle"></i>&nbsp;{title}&nbsp;<small dangerouslySetInnerHTML={{__html: description}} /></h1>
                    </section>
                    <section className="content">
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="box">
                                    <div className="box-body padding-x-20 padding-y-20">
                                        {videoRenderer}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </DocumentTitle>
        );
    }
}

export default Container.create(VideoDetail);
