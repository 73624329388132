/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, ControlLabel, FormGroup} from 'react-bootstrap';

import BaseSelect from '../../../common/base-select/base-select';
import {FormItem, FormRow} from '../../../common/form/form';
import {HandleSelect} from '../../../lookup/lookup-select-helpers';
import {TitleActions} from '../../title-actions';
import TitleStore from '../../title-store';

class Link extends Component {
    static get propTypes() {
        return {
            disabled: PropTypes.bool,
            index: PropTypes.number.isRequired,
            link: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true
        };
    }

    static calculateState() {
        return {
            title: TitleStore.getState().get('title')
        };
    }

    static getStores() {
        return [TitleStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign(this.readState(props), this.constructor.calculateState());

        this.createHandlers = this.createHandlers.bind(this);
        this.handleMove = this.handleMove.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleSelectLinkType = this.handleSelectLinkType.bind(this);
        this.readState = this.readState.bind(this);
        this.updateLink = this.updateLink.bind(this);
    }

    componentWillMount() {
        this.createHandlers(this.props.index);
        return;
    }

    componentWillReceiveProps(nextProps/*, nextContext*/) {
        /**
         * Only update the state if the link changed.
         */
        if (nextProps.link !== this.props.link) {
            this.setState(this.readState(nextProps));
        }

        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.link !== this.props.link ||
            nextProps.disabled !== this.props.disabled) {
            return true;
        }

        if (nextState.selectedLinkType !== this.state.selectedLinkType) {
            return true;
        }

        return false;
    }

    componentWillUpdate(nextProps) {
        this.createHandlers(nextProps.index);
        return;
    }

    /**
     * The link objects are stored in TitleStore.getState().get('title').get('links')
     * the problem with this approach is that in order to update a link object
     * we need to know its position in the title.links array. To solve that, we can
     * create all handlers dynamically (right after we get the properties telling us
     * which link we are working on).
     */
    createHandlers(index) {
        this.handleSelectLinkType = HandleSelect(`links.${index}.linkType`, TitleActions.updateTitle, {valueAttr: 'id'});
        return;
    }

    handleMove(newPos) {
        /**
         * Prevent from moving the first link up
         * and the last link down.
         */
        if (newPos === -1 || newPos >= this.state.title.get('links').size) {
            return;
        }

        TitleActions.moveElement('links', 'rankWithinTitle', this.props.index, newPos);
        return;
    }

    handleRemove() {
        TitleActions.removeElement('links', this.props.index, 'rankWithinTitle');
        return;
    }

    handleSelectLinkType(value) {
        if (!this.state.isMovieLinkDirty) {
            this.setState({isMovieLinkDirty: true});
        }

        HandleSelect('links', TitleActions.updateTitle, {valueAttr: 'id'})(value);
        return;
    }

    readState(props) {
        return {
            selectedLinkType: TitleStore.getLinkType(props.link.get('linkType'))
        };
    }

    updateLink(attr, value) {
        TitleActions.updateTitle(`links.${this.props.index}.${attr}`, value);
        return;
    }

    render() {
        let noop = () => void 0;
        let disabled = this.props.disabled;
        return (
            <div className="box form-section">
                <span className={ClassNames('label', 'bg-wb-blue', {'clickable': !disabled})}
                    onClick={!disabled ? this.handleMove.bind(this, this.props.index - 1) : noop}
                ><i className="fas fa-chevron-up"></i></span>
                <span className="order-index">{this.props.index + 1}</span>
                <span className={ClassNames('label', 'bg-wb-blue', {'clickable': !disabled})}
                    onClick={!disabled ? this.handleMove.bind(this, this.props.index + 1) : noop}
                ><i className="fas fa-chevron-down"></i></span>&nbsp;
                <span className={ClassNames('label', 'bg-wb-blue', {'clickable': !disabled})}
                    onClick={!disabled ? this.handleMove.bind(this, 0) : noop}
                ><i className="fas fa-arrow-up"></i>&nbsp;{this.context.intl.messages['titles.create.summary.release.move-to-top']}</span>
                <Button
                    bsSize="sm"
                    bsStyle="danger"
                    className="pull-right"
                    disabled={disabled}
                    onClick={this.handleRemove}
                ><i className="fas fa-trash-alt"></i>&nbsp;{this.context.intl.messages['common.delete']}</Button>
                <hr />
                <FormRow>
                    <FormItem
                        attr="linkTargetURL"
                        disabled={this.props.disabled}
                        label={this.context.intl.messages['titles.create.link.url']}
                        md={5}
                        model={this.props.link}
                        setter={this.updateLink}
                        type="text"
                    />
                    <FormItem
                        attr="name"
                        disabled={this.props.disabled}
                        label={this.context.intl.messages['titles.create.link.local-title']}
                        md={4}
                        model={this.props.link}
                        setter={this.updateLink}
                        type="text"
                    />
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['titles.create.link.type']}</ControlLabel>
                        <BaseSelect
                            disabled={this.props.disabled}
                            name={this.context.intl.messages['titles.create.link.type']}
                            onChange={this.handleSelectLinkType}
                            options="links"
                            store={TitleStore}
                            value={this.state.selectedLinkType}
                        />
                    </FormGroup>
                </FormRow>
            </div>
        );
    }
}

export default Container.create(Link);
