/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {FormGroup} from 'react-bootstrap';

import NominationInfo from './nomination-info';
import Synopsis from './synopsis';
import TitleStyle from './title-style';
import TitleThumbnail from './title-thumbnail';

import {FormRow} from '~/src/common/form/form';
import {AlertsWarnings} from '~/src/common/validations/alerts-warnings';
import {EventActions, EventConstants} from '~/src/events/event-actions';
import EventStore from '~/src/events/event-store';

class PresentationSections extends React.Component {

    static get propTypes() {
        return {
            nominationInfo: PropTypes.instanceOf(Immutable.Map).isRequired,
            selectedSynopsisOption: PropTypes.string,
            selectedTitleStyleOption: PropTypes.string,
            selectedTitleThumbnailOption: PropTypes.string,
            title: PropTypes.instanceOf(Immutable.Map).isRequired,
            titleStyle: PropTypes.instanceOf(Immutable.Map).isRequired,
            titleStyleFiles: PropTypes.instanceOf(Immutable.Map).isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            selectedSynopsisOption: null,
            selectedTitleStyleOption: null,
            selectedTitleThumbnailOption: null,
        };
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.title.get('eventTitleId')) {
            EventActions.clearTitlePresentationOptions();
            EventActions.getTitlePresentationOptions(this.props.title.get('eventTitleId'));
        }
    }

    render() {
        let validations = EventStore.getTitleStyleValidations();
        if (this.props.selectedTitleStyleOption === EventConstants.TITLE_PRESENTATION.MODIFY_OPTIONS.INHERIT) {
            validations = []; // don't check title style validations if inherit option is selected
        }

        return (
            <div className="row">
                <div className="container-fluid no-x-padding">
                    <div className="col-lg-9">
                        <TitleStyle
                            selectedTitleStyleOption={this.props.selectedTitleStyleOption}
                            titleStyle={this.props.titleStyle}
                            titleStyleFiles={this.props.titleStyleFiles}
                        />
                        <hr />
                        <FormRow>
                            <FormGroup md={6}>
                                <TitleThumbnail
                                    titleStyle={this.props.titleStyle}
                                    titleStyleFiles={this.props.titleStyleFiles}
                                    selectedTitleThumbnailOption={this.props.selectedTitleThumbnailOption}
                                />
                                <hr />
                            </FormGroup>
                            <FormGroup md={6}>
                                <Synopsis
                                    selectedSynopsisOption={this.props.selectedSynopsisOption}
                                    titleStyle={this.props.titleStyle}
                                />
                                <hr />
                            </FormGroup>
                        </FormRow>
                        <NominationInfo
                            eventTitleId={this.props.title.get('eventTitleId')}
                            nominationInfo={this.props.nominationInfo}
                        />
                    </div>
                    <div className="col-lg-3">
                        <AlertsWarnings
                            title={this.context.intl.messages['common.alerts-warnings-title']}
                            validations={validations}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default PresentationSections;
