/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import {HelpActions} from './help-actions';
import HelpStore from './help-store';
import DocumentTitle from '../common/document-title';
import {BreakIntoGroups, GetThumbnail} from '../common/utils/utils';
import ImageLoader from '../image-loader/image-loader';
import Preloader from '../preloader';
import PreloaderStore from '../preloader/preloader-store';

class HelpVideo extends React.Component {
    static get propTypes() {
        return {
            item: PropTypes.instanceOf(Immutable.Map)
        };
    }

    static get defaultProps() {
        return {
            item: null
        };
    }

    showItem(item) {
        HelpActions.showItem(item);
    }

    render() {
        if (!this.props.item) {
            return null;
        }

        let thumbnail = GetThumbnail(this.props.item.getIn(['video', 'thumbnails']), 400);
        let image;
        if (thumbnail) {
            image = thumbnail.get('thumbnailUrl');
        }

        return (
            <div className="video-box col-md-4">
                <div className="padding-all-10 margin-bottom-20">
                    <div
                        className="video-thumbnail"
                        onClick={this.showItem.bind(this, this.props.item)}
                    >
                        <ImageLoader
                            alt={this.props.item.getIn(['video', 'assetComment'])}
                            className="img-responsive"
                            // eslint-disable-next-line @typescript-eslint/no-var-requires
                            placeholder={require('../layout/placeholder-horizontal.svg')}
                            src={image}
                        />
                        <span className="glyphicon glyphicon-play-circle thumbnail-play-icon"/>
                    </div>
                    <h4 dangerouslySetInnerHTML={{__html: this.props.item.get('description')}} />
                </div>
            </div>
        );
    }
}

class HelpVideos extends React.Component {

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            help: HelpStore.getState().get('helpPublishingList'),
            isLoading: PreloaderStore.getState().get('preloaderVisible')
        };
    }

    static getStores() {
        return [HelpStore, PreloaderStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        return;
    }

    componentDidMount() {
        HelpActions.get();
    }

    render() {
        return (
            <DocumentTitle message="document-titles.help-videos">
                <div>
                    <Preloader
                        fixed
                        loadingDots
                        show={this.state.isLoading}
                    />
                    <section className="content-header">
                        <h1><i className="fas fa-info-circle"></i>&nbsp;{this.context.intl.messages['document-titles.help-videos']}</h1>
                    </section>
                    <section className="content">
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="box">
                                    <div className="box-body padding-x-20 padding-y-20">
                                        {BreakIntoGroups(this.state.help.get('items'), 3).map((itemsRow, i) => (
                                            <div className="help-content row" key={i}>
                                                <HelpVideo item={itemsRow.get(0)} />
                                                <HelpVideo item={itemsRow.get(1)} />
                                                <HelpVideo item={itemsRow.get(2)} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </DocumentTitle>
        );
    }
}

export default Container.create(HelpVideos);
export {HelpVideo};
