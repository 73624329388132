/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

import {AlertTypes} from '../../../common/notification/alert';
import {NotificationActions} from '../../../common/notification/notification-actions';
import Dispatcher from '../../../dispatcher/dispatcher';
import Request from '../../../request';

const CONSTANTS = {
    CLEAR: 'user_errors.clear',
    FIND_ERRORS: {
        ERROR: 'user_errors.find_errors.error',
        START: 'user_errors.find_errors.start',
        SUCCESS: 'user_errors.find_errors.success'
    },
    GET: {
        ERROR: 'user_errors.get.error',
        START: 'user_errors.get.start',
        SUCCESS: 'user_errors.get.success'
    }
};

class UserErrorActions {
    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR,
        });
    }

    findErrorsByUserId(userId, queryParams) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.FIND_ERRORS.START,
        });

        queryParams = queryParams.toJS();
        queryParams.offset = 0;
        queryParams.size = 9999;

        Request.get(`user/error/user/${userId}`)
            .query(queryParams)
            .exec()
            .then(res => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.FIND_ERRORS.SUCCESS,
                    errors: res.body.results,
                    offset: res.body.offset,
                    size: res.body.size,
                    total: res.body.totalCount,
                });
            }).catch((error) => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.FIND_ERRORS.ERROR,
                    error
                });

                NotificationActions.showAlertDanger('accounts.create.user-errors.error');
                throw error;
            });
    }

    findErrors(queryParams) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.GET.START,
        });

        queryParams = queryParams.toJS();
        if (!queryParams.offset) {
            queryParams.offset = 0;
        }
        if (!queryParams.size) {
            queryParams.size = 20;
        }

        Request.get('user/error').query(queryParams).exec().then((res) => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.SUCCESS,
                errors: Immutable.fromJS(res.body.results),
                offset: res.body.offset,
                size: res.body.size,
                total: res.body.totalCount,
            });
        }).catch((error) => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.ERROR,
                error
            });

            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'accounts.user-errors.get.error');
            throw error;
        });
    }
}

const actions = new UserErrorActions();

export {
    actions as UserErrorActions,
    CONSTANTS as UserErrorConstants
};
