/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {HelpConstants} from './help-actions';
import Dispatcher from '../dispatcher/dispatcher';

class HelpStore extends ReduceStore {
    getInitialState() {
        return Immutable.fromJS({
            helpPublishingList: {
                items: []
            },
            isLoading: false
        });
    }

    reduce(state, action) {
        switch (action.actionType) {
        case HelpConstants.GET.ERROR:
            state = state.set('isLoading', false);
            break;

        case HelpConstants.GET.START:
            state = state.set('isLoading', true);
            break;

        case HelpConstants.GET.SUCCESS:
            state = state.merge({
                helpPublishingList: action.helpPublishingList,
                isLoading: false
            });
            break;

        case HelpConstants.SHOW_ITEM:
            state = state.merge({
                item: action.item,
                isLoading: false
            });
            break;
        }

        return state;
    }
}

export default new HelpStore(Dispatcher);
