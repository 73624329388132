/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {ForensicConstants} from './forensic-actions';
import Dispatcher from '../../../../dispatcher/dispatcher';


class ForensicStore extends ReduceStore {

    getInitialState() {
        let state = Immutable.fromJS({
            forensicUrls: [],
            total:0
        });
        return state;
    }

    reduce(state, action) {
        switch (action.actionType) {
        case ForensicConstants.CLEAR:
        case ForensicConstants.GET.START:
            state = this.getInitialState();
            break;
        case ForensicConstants.GET.SUCCESS:
            state = state.set('forensicUrls', action.forensicUrls)
                .set('total', action.total);
            break;
        }
        return state;
    }
}

let store = new ForensicStore(Dispatcher);

export default store;
