/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import LayoutStore from '../layout-store';

class StickyHeader extends Component {
    static get propTypes() {
        return {
            children: PropTypes.node
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            children: undefined
        };
    }

    static calculateState() {
        return {
            showSideBar: LayoutStore.getState().get('showSideBar')
        };
    }

    static getStores() {
        return [LayoutStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            contentHeight: 0,
        }, this.constructor.calculateState());

        this.onScroll = this.onScroll.bind(this);
        this.updateContentHeight = this.updateContentHeight.bind(this);
        this.updateStickyHeaderWidth = this.updateStickyHeaderWidth.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        window.addEventListener('resize', this.updateStickyHeaderWidth);
        window.addEventListener('scroll', this.onScroll);
        this.updateContentHeight();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.contentHeight !== prevState.contentHeight) {
            this.updateContentHeight();
        }

        if (this.state.showSideBar !== prevState.showSideBar) {
            setTimeout(/*istanbul ignore next*/() => {
                this.updateStickyHeaderWidth();
            }, 350);
        }

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateStickyHeaderWidth);
        window.removeEventListener('scroll', this.onScroll);

    }

    /*istanbul ignore next*/
    onScroll() {
        const stickyOffsetTop = this.refs.stickyHeaderContainer.offsetTop;

        // if header container is sticky and we are still scrolling up, don't remove sticky style
        if (this.refs.stickyHeaderContainer.classList.contains('sticky')) {
            const mainHeaderHeight = document.getElementById('mainHeader').offsetHeight;
            if (window.pageYOffset > stickyOffsetTop - mainHeaderHeight) {
                return;
            }
        }

        this.updateContentHeight();
        if (window.pageYOffset) {
            this.refs.stickyHeaderContainer.classList.add('sticky');
            document.getElementById('contentContainer').classList.add('on-scroll');
        } else {
            this.refs.stickyHeaderContainer.classList.remove('sticky');
            document.getElementById('contentContainer').classList.remove('on-scroll');
        }
        this.updateStickyHeaderWidth();
    }

    /*istanbul ignore next*/
    updateContentHeight() {
        //subtract 120 for the padding that gets added at the top and bottom for avoiding flickering
        let actualHeight = document.getElementById('contentContainer').offsetHeight - 120;
        if (document.getElementById('contentContainer').offsetHeight !== this.state.contentHeight) {
            this.setState({
                contentHeight: actualHeight
            });
        }
        if (actualHeight > 740 && actualHeight < 960) {
            document.getElementById('contentContainer').classList.add('bottomPadding');
        } else {
            document.getElementById('contentContainer').classList.remove('bottomPadding');
        }
    }

    /*istanbul ignore next*/
    updateStickyHeaderWidth() {
        /*
            If viewport is upgraded from smallest to largest (by maximization button click on browser)
            the clientWidth doesn't include the sidenav thus breaking the buttons. This Timeout fixes the issue.
        */
        setTimeout(() => {
            const parentwidth = document.getElementById('contentContainer')?.clientWidth;
            if (this.refs.stickyHeaderContainer?.style) {
                this.refs.stickyHeaderContainer.style.width = `${parentwidth}px`;
            }
        }, 300);
    }

    render() {
        return (
            <section className="content-header" id="sticky-header" ref="stickyHeaderContainer">
                {this.props.children}
            </section>
        );
    }
}

export default Container.create(StickyHeader);
