/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {AssetUserConstants} from './asset-user-actions';
import Dispatcher from '../dispatcher/dispatcher';

class AssetUserStore extends ReduceStore {

    getInitialState() {
        return Immutable.Map({
            activePage: 0,
            users: Immutable.List(),
            offset: 0,
            showPreloader: false,
            size: 20,
            total: 0,
            totalPages: 0
        });
    }

    reduce(state, action) {
        switch (action.actionType) {
        case AssetUserConstants.CLEAR:
            state = this.getInitialState();
            break;

        case AssetUserConstants.GET.ERROR:
            state = state.set('showPreloader', false);
            break;


        case AssetUserConstants.GET.SUCCESS:
            state = state.merge({
                activePage: Math.ceil(action.offset/action.size) || 0,
                offset: action.offset,
                selectedUsers: Immutable.List(),
                size: action.size,
                showPreloader: false,
                total: action.total,
                totalPages: Math.ceil(action.total/action.size) || 0,
                users: action.users
            });
            break;

        case AssetUserConstants.GET.START:
            state = state.set('showPreloader', true);
            break;
        }
        return state;
    }
}

let store = new AssetUserStore(Dispatcher);

export default store;
