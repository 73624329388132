/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

import Dispatcher from '../../dispatcher/dispatcher';
import Request from '../../request';
import LookupActions from '../lookup-actions';

const CONSTANTS = {
    GET: {
        ERROR: 'client-rep_actions.get.error',
        START: 'client-rep_actions.get.start',
        SUCCESS: 'client-rep_actions.get.success'
    },
    MESSAGES: {
        CREATE_ERROR: 'lookups.client-rep.create.error',
        CREATE_OK: 'lookups.client-rep.create.ok',
        REMOVE_ERROR: 'lookups.client-rep.remove.error',
        REMOVE_OK: 'lookups.client-rep.remove.ok',
        SAVE_ERROR: 'lookups.client-rep.save.error',
        SAVE_OK: 'lookups.client-rep.save.ok',
    },
    REMOVE_OK: 'client-rep_actions.remove',
    SAVE_OK: 'client-rep_actions.save',
};

class ClientRepActions extends LookupActions {

    constructor(path, CONSTS) {
        super(path, CONSTS);
    }

    get(offset, size) {
        Dispatcher.dispatch({
            actionType: this.CONSTANTS.GET.START
        });

        Request.get(this.path).query({
            offset: offset,
            size: size
        }).set('Accept', 'application/vnd.wbtv-v1+json;q=0.1').exec().then(res => {
            // Some API's return pagination objects,
            // some other return plain arrays.
            let items = [];
            res.body.results.forEach(cr => {
                let fullName = '';

                if (cr.name) {
                    fullName = cr.name;
                }

                if (cr.middleName) {
                    fullName += ' ' + cr.middleName;
                }

                if (cr.lastName) {
                    fullName += ' ' + cr.lastName;
                }

                let ucr = {id: cr.id, name: fullName};
                items.push(ucr);
            });

            Dispatcher.dispatch({
                actionType: this.CONSTANTS.GET.SUCCESS,
                offset: res.body.offset,
                items: Immutable.fromJS(items).sortBy(this.sortItemsBy),
                size: res.body.size,
                total: parseInt(res.header['wbtv-total-count'], 10)
            });
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: this.CONSTANTS.GET.ERROR
            });
            throw err;
        });

        return;
    }


}

let actions = new ClientRepActions('security/client-rep-group/rep', CONSTANTS);

export {
    actions as ClientRepActions,
    CONSTANTS as ClientRepConstants
};
