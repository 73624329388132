/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import jQuery from 'jquery';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import UserStore from '../../user/user-store';
import './mass-update.less';

require('../../styles/data-tables-brainiac.css');
require('datatables.net-responsive-bs');


class UserTable extends Component {
    static get propTypes() {
        return {
            users: PropTypes.instanceOf(Immutable.List).isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        this.$table = jQuery('#mass-update-accounts-users');
        let dataTableConfig = {
            autoWidth: false,
            columnDefs: [{
                orderable: false,
                targets: 'no-sort'
            }, {
                className: 'text-center',
                targets: 0
            }],
            iDisplayLength: 20,
            info: false,
            lengthChange: false,
            order: [[1, 'dec']],
            ordering: false,
            paging: true,
            pagingType: 'simple_numbers',
            responsive: false,
            searching: false,
            scrollX: true
        };

        this.$tableAPI = this.$table.DataTable(dataTableConfig);
        window.addEventListener('transitionend', this.handleResize);
        this.handleResize();
        return;
    }

    componentWillUpdate(nextProps/*, nextState */) {
        this.$tableAPI.clear();

        nextProps.users.forEach((user, i) => {
            let firstCol = i + 1;
            let groups = [];
            let roles = [];
            let row = [];

            const accountStatus = UserStore.getStatus(user);
            let status = '';
            let tip = '';

            if (!accountStatus) {
                status = `<span class="label bg-red" data-toggle="tooltip" title="${this.context.intl.messages['user.status.tooltip.non-existent']}" data-placement="top"><i class="fas fa-ellipsis-h"></i>&nbsp;<span class="hidden-xs hidden-sm"/></span>`;
            } else {
                if (accountStatus.tip) {
                    tip = this.context.intl.messages[accountStatus.tip];
                }
                status = `<span class="label ${accountStatus.label}" data-toggle="tooltip" title="${tip}" data-placement="top"><i class="${accountStatus.icon}"></i>&nbsp;<span class="hidden-xs hidden-sm">${accountStatus.description}</span></span>`;
            }

            if (user.get('groups') && user.get('groups').size) {
                user.get('groups').map(g => {
                    groups.push(g.get('name'));
                });
            }
            if (user.get('roles') && user.get('roles').size) {
                user.get('roles').map(r => {
                    roles.push(r.get('name'));
                });
            }

            row.push(
                firstCol,
                user.get('emailAddress', ''),
                status,
                groups.join(', '),
                roles.join(', ')
            );

            let currentRow = this.$tableAPI.row.add(row);

            if (user.get('statusMessage') !== null) {
                jQuery(currentRow.node()).addClass('mass-validate-error');
            }

            return;
        });

        this.$tableAPI.draw(false);
        this.handleResize();

        return;
    }

    handleResize() {
        /*Timeout only for the case when user adds title and modal shows up, need that timeout to do the adjust*/
        setTimeout(() => {
            this.$tableAPI.columns.adjust();
        }, 20);
        return;
    }

    render() {
        return (
            <div className="mass-update-accounts-table">
                <table id="mass-update-accounts-users" className="table table-bordered table-striped responsive">
                    <thead>
                        <tr>
                            <th className="no-arrow">{this.context.intl.messages['accounts.mass-update.row-header.index']}</th>
                            <th className="no-arrow">{this.context.intl.messages['accounts.mass-update.row-header.email']}</th>
                            <th className="no-arrow">{this.context.intl.messages['accounts.mass-update.row-header.status']}</th>
                            <th className="no-arrow">{this.context.intl.messages['accounts.mass-update.row-header.groups']}</th>
                            <th className="no-arrow">{this.context.intl.messages['accounts.mass-update.row-header.roles']}</th>
                        </tr>
                    </thead>
                    <tbody />
                </table>
            </div>
        );
    }
}

export default UserTable;
