/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {DOCUMENT_CT_TYPES} from '@wbdt-sie/brainiac-web-common';
import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import jQuery from 'jquery';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, Row} from 'react-bootstrap';

import SearchBox from '../../common/search-box/search-box';
import Pagination from '../../common/table/pagination';
import {GetThumbnail} from '../../common/utils/utils';
import {LayoutActions} from '../../layout/layout-actions';
import LayoutStore from '../../layout/layout-store';
import {RouterActions} from '../../router/router-actions';
import {AssetActions, AssetConstants} from '../asset-actions';
import AssetStore from '../asset-store';
import FilterOptions from '../filter-options/filter-options';
import {ImageConstants} from '../image/image-actions';
import {VideoConstants} from '../video/video-actions';

import {TimelineActions} from '~/src/hardac/timeline/timeline-actions';

require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('../../styles/data-tables-brainiac.css');
// Load jQuery and register the datatables plugin.
require('datatables.net-responsive-bs');

const noop = () => void 0;

const ASSET_TYPES_BY_ID = Object.keys(AssetConstants.ASSET_TYPES).reduce((r, tName) => {
    let type = AssetConstants.ASSET_TYPES[tName];
    r[type.id.toString()] = type;
    return r;
}, {});

const DELIVERY_TYPES_BY_ID = Object.keys(AssetConstants.DELIVERY_TYPES).reduce((r, dName) => {
    let type = AssetConstants.DELIVERY_TYPES[dName];
    r[type.id.toString()] = type;
    return r;
}, {});

const SOURCE_CONTENT_TYPE_BY_ID = Object.keys(AssetConstants.SOURCE_CONTENT_TYPE).reduce((r, sctName) => {
    let type = AssetConstants.SOURCE_CONTENT_TYPE[sctName];
    r[type.id.toString()] = type;
    return r;
}, {});

const createHeaders = (headerName, showName) => (component) => (
    <th
        className={component.getsortFieldNameClass(headerName)}
        key={headerName}
        onClick={component.handleHeaderClick.bind(component, headerName)}
    >
        {showName}
    </th>
);

const COLUMNS = {
    actions: {
        header: function() {
            return <th className="actions" key="actions">Actions</th>;
        }
    },
    active: {
        get: (asset, props, context) => {
            const active = asset.get('active');
            let icon = 'fas fa-check';
            let text = context.intl.messages['common.active'];
            if (!active) {
                icon = 'fas fa-times';
                text = context.intl.messages['common.inactive'];
            }
            return `<i class="${icon}"></i>&nbsp;<span class="hidden-xs hidden-sm">${text}`;
        },
        header: function() {
            return <th key="active">Status</th>;
        }
    },
    assetThumbnail: {
        get: (asset, props) => {
            const assetName = asset.get('assetName');
            const sourceContentType = asset.get('sourceContentType') || '';
            let assetType =
                SOURCE_CONTENT_TYPE_BY_ID[sourceContentType.toString()] ||
                ASSET_TYPES_BY_ID[asset.get('assetType').toString()] || {icon: 'fas fa-file-alt'};
            let icon = `<i class="${ClassNames(assetType.icon)}"></i>`;

            if (asset.get('thumbnails')) {
                let thumbnailUrl = asset.getIn(['thumbnails', 0, 'thumbnailUrl']);
                let thumbnail = GetThumbnail(asset.get('thumbnails'), 100);

                if (thumbnail) {
                    thumbnailUrl = thumbnail.get('thumbnailUrl');
                }

                if (thumbnailUrl) {
                    icon = `<img class="table-thumbnail" src="${thumbnailUrl}" alt="${assetName}" />`;
                }
            }

            switch (asset.get('assetType')) {
            case AssetConstants.ASSET_TYPES.AUDIO.id:
                if (props.displayAudioLinks) {
                    return `<a href="/assets/audio/${asset.get('id')}" class="edit-asset" target="${props.linkTarget}">${icon}</a>`;
                }

                return icon;
            case AssetConstants.ASSET_TYPES.IMAGE.id:
                if (props.displayImageLinks) {
                    return `<a href="/assets/image/${asset.get('id')}" class="edit-asset" target="${props.linkTarget}">${icon}</a>`;
                }

                return icon;
            case AssetConstants.ASSET_TYPES.MERCHANDISE.id:
                if (props.displayMerchandiseLinks) {
                    return `<a href="/assets/merchandise/${asset.get('id')}" class="edit-asset" target="${props.linkTarget}">${icon}</a>`;
                }

                return icon;
            case AssetConstants.ASSET_TYPES.SCRIPT.id:
                if (props.displayScriptLinks) {
                    return `<a href="/assets/script/${asset.get('id')}" class="edit-asset" target="${props.linkTarget}">${icon}</a>`;
                }

                return icon;
            case AssetConstants.ASSET_TYPES.VIDEO.id:
                //check sourceContentType first
                if (props.displayProxyLinks && sourceContentType === AssetConstants.SOURCE_CONTENT_TYPE.PROXY.id) {
                    return `<a href="/assets/proxy/${asset.get('id')}" class="edit-asset" target="${props.linkTarget}">${icon}</a>`;
                }
                if (props.displayVideoLinks) {
                    return `<a href="/assets/video/${asset.get('id')}" class="edit-asset" target="${props.linkTarget}">${icon}</a>`;
                }

                return icon;
            case AssetConstants.ASSET_TYPES.VIDEO_TIMELINE.id:
                if (props.displayVideoTimelineLinks) {
                    return `<a href="/assets/timeline/${asset.get('id')}" class="edit-asset" target="${props.linkTarget}">${icon}</a>`;
                }

                return icon;
            default:
                if (props.displayDocumentLinks) {
                    return `<a href="/assets/document/${asset.get('id')}" class="edit-asset" target="${props.linkTarget}">${icon}</a>`;
                }

                return icon;
            }
        },
        header: function() {
            return <th className="text-center" key="assetThumbnail"><i className="fas fa-camera"/></th>;
        }
    },
    assetName: {
        get: (asset, props) => {
            let assetName = asset.get('assetName');
            switch (asset.get('assetType')) {
            case AssetConstants.ASSET_TYPES.AUDIO.id:
                if (props.displayAudioLinks) {
                    return `<a href="/assets/audio/${asset.get('id')}" class="edit-asset" target="${props.linkTarget}">${assetName}</a>`;
                }

                return assetName;
            case AssetConstants.ASSET_TYPES.IMAGE.id:
                if (props.displayImageLinks) {
                    return `<a href="/assets/image/${asset.get('id')}" class="edit-asset" target="${props.linkTarget}">${assetName}</a>`;
                }

                return assetName;
            case AssetConstants.ASSET_TYPES.MERCHANDISE.id:
                if (props.displayMerchandiseLinks) {
                    return `<a href="/assets/merchandise/${asset.get('id')}" class="edit-asset" target="${props.linkTarget}">${assetName}</a>`;
                }

                return assetName;
            case AssetConstants.ASSET_TYPES.SCRIPT.id:
                if (props.displayScriptLinks) {
                    return `<a href="/assets/script/${asset.get('id')}" class="edit-asset" target="${props.linkTarget}">${assetName}</a>`;
                }

                return assetName;
            case AssetConstants.ASSET_TYPES.VIDEO.id:
                //check sourceContentType first
                if (props.displayProxyLinks && asset.get('sourceContentType') === AssetConstants.SOURCE_CONTENT_TYPE.PROXY.id) {
                    return `<a href="/assets/proxy/${asset.get('id')}" class="edit-asset" target="${props.linkTarget}">${assetName}</a>`;
                }
                if (props.displayVideoLinks) {
                    return `<a href="/assets/video/${asset.get('id')}" class="edit-asset" target="${props.linkTarget}">${assetName}</a>`;
                }

                return assetName;
            case AssetConstants.ASSET_TYPES.VIDEO_TIMELINE.id:
                if (props.displayVideoTimelineLinks) {
                    return `<a href="/assets/timeline/${asset.get('id')}" class="edit-asset" target="${props.linkTarget}">${assetName}</a>`;
                }

                return assetName;
            default:
                if (props.displayDocumentLinks) {
                    return `<a href="/assets/document/${asset.get('id')}" class="edit-asset" target="${props.linkTarget}">${assetName}</a>`;
                }

                return assetName;
            }
        },
        header: createHeaders('assetName', 'Name')
    },
    contentType: {
        get: (asset, props, context) => {
            const assetType = asset.get('assetType');
            let contentType = context.intl.messages['common.na'];
            switch (assetType) {
            case AssetConstants.ASSET_TYPES.IMAGE.id:
                if (ImageConstants.CONTENT_TYPE_MAP[asset.get('contentType')]) {
                    contentType = ImageConstants.CONTENT_TYPE_MAP[asset.get('contentType')].name;
                }
                break;
            case AssetConstants.ASSET_TYPES.VIDEO.id:
                if (VideoConstants.CONTENT_TYPE_BY_ID[asset.get('contentType')]) {
                    contentType = VideoConstants.CONTENT_TYPE_BY_ID[asset.get('contentType')].name;
                }
                break;
            case AssetConstants.ASSET_TYPES.DOCUMENT.id:
                if (DOCUMENT_CT_TYPES.CONTENT_TYPE_MAP[asset.get('contentType')]) {
                    contentType = DOCUMENT_CT_TYPES.CONTENT_TYPE_MAP[asset.get('contentType')].name;
                }
                break;
            }
            return contentType;
        },
        header: function() {
            return <th key="contentType">Content Type</th>;
        }
    },
    createdDate: {
        get: (asset, nextProps, context) => {
            let createdDate = Moment(asset.get('createdDate'));

            if (createdDate.isValid()) {
                return createdDate.format(context.intl.messages['date-format']);
            }

            return '-';
        },
        header: createHeaders('createdDate', 'Created Date')
    },
    deliveryType: {
        get: (asset, props, context) => {
            let deliveryType = context.intl.messages['common.na'];
            if (DELIVERY_TYPES_BY_ID[asset.get('deliveryType')]) {
                deliveryType = DELIVERY_TYPES_BY_ID[asset.get('deliveryType')].name;
            }
            return deliveryType;
        },
        header: function() {
            return <th key="deliveryType">Delivery Type</th>;
        }
    },
    assetTypeName: {
        get: (asset) => {
            // check sourceContentType first
            const sourceContentType = asset.get('sourceContentType');
            if (sourceContentType && sourceContentType === AssetConstants.SOURCE_CONTENT_TYPE.PROXY.id) {
                return AssetConstants.SOURCE_CONTENT_TYPE.PROXY.name;
            }
            return asset.get('displayAssetTypeName');
        },
        header: createHeaders('displayAssetTypeName', 'Asset Type')
    },
    fileType: {
        get: (asset, props, context) => {
            let fileType = context.intl.messages['common.na'];
            const sourceContentType = asset.get('sourceContentType');
            const fullResolutionFileType = asset.get('fullResolutionFileType');
            if (sourceContentType || fullResolutionFileType) {
                fileType = sourceContentType || fullResolutionFileType;
            }
            return fileType;
        },
        header: function() {
            return <th key="fileType">File Type</th>;
        }
    },
    hardacProxyAssetThumbnail: {
        get: (asset, props) => {
            const assetName = asset.get('assetName');
            const sourceContentType = SOURCE_CONTENT_TYPE_BY_ID[asset.get('sourceContentType')] || {icon: 'fas fa-file-alt'};
            let icon = `<i class="${ClassNames(sourceContentType.icon)}"></i>`;

            if (asset.get('thumbnails')) {
                let thumbnailUrl = asset.getIn(['thumbnails', 0, 'thumbnailUrl']);
                let thumbnail = GetThumbnail(asset.get('thumbnails'), 100);

                if (thumbnail) {
                    thumbnailUrl = thumbnail.get('thumbnailUrl');
                }

                if (thumbnailUrl) {
                    icon = `<img class="table-thumbnail" src="${thumbnailUrl}" alt="${assetName}" />`;
                }
            }

            switch (asset.get('sourceContentType')) {
            case AssetConstants.SOURCE_CONTENT_TYPE.PROXY.id:
                if (props.displayProxyLinks) {
                    return `<a href="/hardac/proxy/${asset.get('id')}" class="edit-asset" target="${props.linkTarget}">${icon}</a>`;
                }

                return icon;
            default:
                return icon;
            }
        },
        header: function() {
            return <th className="text-center" key="assetThumbnail"><i className="fas fa-camera"/></th>;
        }
    },
    hardacProxyAssetName: {
        get: (asset, props) => {
            const assetName = asset.get('assetName');
            switch (asset.get('sourceContentType')) {
            case AssetConstants.SOURCE_CONTENT_TYPE.PROXY.id:
                if (props.displayProxyLinks) {
                    return `<a href="/hardac/proxy/${asset.get('id')}" class="edit-asset" target="${props.linkTarget}">${assetName}</a>`;
                }
                return assetName;
            default:
                return assetName;
            }
        },
        header: createHeaders('assetName', 'Name')
    },
    hardacTimelineAssetThumbnail: {
        get: (asset, props) => {
            const assetName = asset.get('assetName');
            let assetType = ASSET_TYPES_BY_ID[asset.get('assetType').toString()] || {icon: 'fas fa-file-alt'};
            let icon = `<i class="${ClassNames(assetType.icon)}"></i>`;

            if (asset.get('thumbnails')) {
                let thumbnailUrl = asset.getIn(['thumbnails', 0, 'thumbnailUrl']);
                let thumbnail = GetThumbnail(asset.get('thumbnails'), 100);

                if (thumbnail) {
                    thumbnailUrl = thumbnail.get('thumbnailUrl');
                }

                if (thumbnailUrl) {
                    icon = `<img class="table-thumbnail" src="${thumbnailUrl}" alt="${assetName}" />`;
                }
            }

            switch (asset.get('assetType')) {
            case AssetConstants.ASSET_TYPES.VIDEO_TIMELINE.id:
                if (props.displayVideoTimelineLinks) {
                    return `<a href="/hardac/timeline/${asset.get('id')}" class="edit-asset" target="${props.linkTarget}">${icon}</a>`;
                }

                return icon;
            default:
                return icon;
            }
        },
        header: function() {
            return <th className="text-center" key="assetThumbnail"><i className="fas fa-camera"/></th>;
        }
    },
    hardacTimelineAssetName: {
        get: (asset, props) => {
            let assetName = asset.get('assetName');
            switch (asset.get('assetType')) {
            case AssetConstants.ASSET_TYPES.VIDEO_TIMELINE.id:
                if (props.displayVideoTimelineLinks) {
                    return `<a href="/hardac/timeline/${asset.get('id')}" class="edit-asset" target="${props.linkTarget}">${assetName}</a>`;
                }
                return assetName;
            default:
                return assetName;
            }
        },
        header: createHeaders('assetName', 'Name')
    },
    hardacTimelineMPMNumber: {
        get: (asset) => {
            const titlesList = asset.get('browseTitleList');
            const mpmList = titlesList.reduce((list, title) => {
                if (title.get('mpmNumber')) {
                    list.push(title.get('mpmNumber'));
                }
                return list;
            }, []);
            /* eslint-disable indent */
            return `
                <div>
                    ${mpmList.map((mpm, index) => {
                        if (index === mpmList.length - 1) {
                            return `<div>${mpm}</div>`;
                        } else {
                            return `<div>${mpm},</div>`;
                        }
                    }).join('')}
                </div>
            `;
        },
        header: () => <th key="mpm-number">MPM #</th>
    },
    hardacTimelineStatus: {
        get: (asset, props, context) => {
            const clips = asset.get('clips')?.toJS() || [];
            const {completed = [], processing = [], unpublished = [], failed = []} = TimelineActions.splitClips(Immutable.fromJS(clips));
            let icon, color, tooltipText;
            switch (true) {
            // All clips have successfully published
            case (completed.length && !processing.length && !unpublished.length && !failed.length):
                icon = 'fa-solid fa-check fa-fw';
                color = 'text-success';
                tooltipText = 'hardac.timelines.browse.status.published';
                break;
            // Publishing has started and no clips have failed
            case ((processing.length || completed.length) && !failed.length):
                icon = 'fa-solid fa-ellipsis-h fa-fw';
                color = 'text-primary';
                tooltipText = 'hardac.timelines.browse.status.publishing';
                break;
            // Some or all clips have failed publishing
            case (failed.length > 0):
                icon = 'fa-solid fa-xmark fa-fw';
                color = 'text-danger';
                tooltipText = 'hardac.timelines.browse.status.failed';
                break;
            // Timeline is ready to be clipped
            default:
                icon = 'fa-solid fa-scissors fa-fw';
                color = 'text-gray';
                tooltipText = 'hardac.timelines.browse.status.ready';
                break;
            // TODO missing Unavailable case
            }
            return `
                <span>
                    <span class="${color} parcel-tooltip" title="${context.intl.messages[tooltipText]}">
                        <i class="${icon}"></i>
                        <span class="tooltip-inner">${context.intl.messages[tooltipText]}</span>
                    </span>&nbsp;${completed.length}/${clips.length}
                </span>
            `;
        },
        header: () => <th key="clips-status">Clip Status</th>
    },
    publishing: {
        get: (asset, props, context) => {
            return `<a class="btn btn-default-outline" href="/hardac/timeline/${asset.get('id')}/edit-asset" target="${props.linkTarget}"><i class="fas fa-edit"></i> ${context.intl.messages['hardac.timelines.browse.edit-info']}</a>`;
        },
        header: function() {
            return <th key="publishing">Publishing</th>;
        }
    },
    catalogs: {
        get: (asset, nextProps, context) => {
            let catalogs = context.intl.messages['assets.browse.loading-catalogs'];
            if (asset.get('catalogs')) {
                // Filter catalogs auto generated by Events
                const catalogsToShow = asset.get('catalogs').filter(catalog => {
                    return !catalog.get('name').toLowerCase().startsWith('event:');
                });
                catalogs = catalogsToShow.map(t => t.get('name')).reduce((r, v, i) => {
                    if (i === 0) {
                        return `Catalogs: ${v}`;
                    }

                    return `${r}, ${v}`;
                }, context.intl.messages['assets.browse.no-catalogs']);
            }
            return `<div class="display-asset-catalogs parcel-tooltip" data-asset-id="${asset.get('id')}" title="${catalogs}"><i class="fas fa-book"></i><span class="tooltip-inner">${catalogs}</span></div>`;

        },
        header: function() {
            return <th className="actions" key="catalogs"><i className="fas fa-book"/></th>;
        }
    },
    runtime: {
        get: (asset, props, context) => {
            let runtime = context.intl.messages['common.na'];
            if (asset.get('runtime')) {
                runtime = asset.get('runtime');
            }
            return runtime;
        },
        header: function() {
            return <th key="runtime">Runtime</th>;
        }
    },
    mfa: {
        get: (asset) => {
            let mfaRequired = '<i class="fas fa-times"/>';
            if (asset.get('mfaRequired')) {
                mfaRequired = '<i class="fas fa-check"/>';
            }
            return mfaRequired;
        },
        header: () => <th key="mfa" className="no-sort no-arrow">MFA</th>
    },
};

class ListAssets extends Component {

    static get propTypes() {
        return {
            activePage: PropTypes.number.isRequired,
            children: PropTypes.element.isRequired,
            columns: PropTypes.array,
            columnDefs: PropTypes.array,
            complete: PropTypes.bool,
            defaultOrdering: PropTypes.bool,
            displayAudioLinks: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
            displayDocumentLinks: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
            displayMerchandiseLinks: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
            displayVideoLinks: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
            displayVideoTimelineLinks: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
            linkTarget: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
            onPageChange: PropTypes.func,
            onSearchChange: PropTypes.func,
            onSelect: PropTypes.func,
            onSelectAll: PropTypes.func,
            onSortChange: PropTypes.func,
            searchTerm: PropTypes.string.isRequired,
            showFilterOptions: PropTypes.bool,
            selectAll: PropTypes.bool,
            sortDirection: PropTypes.string.isRequired,
            sortFieldName: PropTypes.string.isRequired,
            totalPages: PropTypes.number.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
            router: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            showFiltersPanel: LayoutStore.getState().get('showFiltersPanel'),
            filters: AssetStore.getState().get('filters')
        };
    }

    static getStores() {
        return [LayoutStore, AssetStore];
    }

    static get defaultProps() {
        return {
            columns: ['assetThumbnail', 'assetTypeName', 'assetName', 'createdDate', 'catalogs'],
            columnDefs: [
                {
                    // Add the control class to the last column. This colum will
                    // contain the button to show the responsive data.
                    className: 'control',
                    targets: -1,
                    width: 20
                }, {
                    className: 'actions',
                    targets: 'actions'
                }, {
                    targets: 'no-sort',
                    orderable: false
                }, {
                    targets: [0], // assetThumbnail
                    className: 'text-center',
                    width: 60
                }, {
                    targets: [1], // assetType
                    width: 120
                }, {
                    targets: [2], // assetName
                }, {
                    targets: [3], // Created Date
                    width: '10%'
                }, {
                    targets: [4], // Catalogs
                    width: '10%'
                },
            ],
            complete: false,
            defaultOrdering: false,
            displayAudioLinks: false,
            displayDocumentLinks: false,
            displayImageLinks: false,
            displayMerchandiseLinks: false,
            displayVideoLinks: false,
            displayVideoTimelineLinks: false,
            linkTarget: '_self',
            onPageChange: noop,
            onSelect: undefined,
            onSelectAll: undefined,
            onSortChange: undefined,
            onSearchChange: undefined,
            selectAll: false,
            showFilterOptions: false
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.drawAssets = this.drawAssets.bind(this);
        this.getsortFieldNameClass = this.getsortFieldNameClass.bind(this);
        this.handleHeaderClick = this.handleHeaderClick.bind(this);
        this.handleActionsCellClick = this.handleActionsCellClick.bind(this);
        this.handleCatalogCellMouseEnter = this.handleCatalogCellMouseEnter.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.handleSelectAllClick = this.handleSelectAllClick.bind(this);
        this.handleToggleFiltersPanel = this.handleToggleFiltersPanel.bind(this);
    }

    componentDidMount() {
        this.$table = jQuery('#list-assets-table');
        this.$tableAPI = this.$table.DataTable({
            autoWidth: false,
            columnDefs: this.props.columnDefs,
            iDisplayLength: 1,
            info: false,
            ordering: this.props.defaultOrdering,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        });

        // Add some custom handlers.
        this.$table.on('mouseenter', '.display-asset-catalogs', this.handleCatalogCellMouseEnter);

        // Add custom Action handler
        this.$table.on('click', 'td.actions, li.actions', this.handleActionsCellClick);

        // Register global listeners.
        window.addEventListener('resize', this.handleResize);
        // And trigger the resize handler once so that the datatable
        // knows its initial dimensions.
        this.drawAssets(this.props);
        return;
    }

    componentWillUpdate(nextProps) {
        this.drawAssets(nextProps);
        return;
    }

    componentWillUnmount() {
        // check if undefinded only to enable render test
        if (this.$table) {
            this.$table.off('mouseenter', '.display-asset-catalogs');
        }
        // check if undefinded only to enable render test
        if (this.$tableAPI) {
            this.$tableAPI.destroy();
        }

        return;
    }

    drawAssets(props) {
        this.$tableAPI.clear();
        // Add data to the jQuery datatable.
        props.assets.forEach((asset, index) => {
            let row = this.props.columns.map(col => {
                let column = COLUMNS[col];
                if (col.name) {
                    column = COLUMNS[col.name];
                }
                // Override the getter if it was provided. This way
                // the module that includes the table can define custom
                // functions to read the values.
                if (col.get) {
                    column.get = col.get;
                }
                return column.get(asset, props, this.context);
            });

            // If requested, show the select in the first column.
            if (props.onSelectAll && props.onSelect) {
                let checkedStatus = '';
                if (asset.get('__selected')) {
                    checkedStatus = 'checked';
                }
                row.unshift(`<input type="checkbox" class="select-row" data-index="${index}" ${checkedStatus}/>`);
            }
            // Add a last empty column for the datatable-responsive plugin.
            row.push('');

            this.$tableAPI.row.add(row);

            return;
        });

        this.$tableAPI.draw(false);

        // Now, since the data has changed the columns widths, trigger
        // the resize handler in order to update the responsive feature.
        this.handleResize();
    }

    getsortFieldNameClass(headerName) {
        let r = 'sorting';

        if (headerName === this.props.sortFieldName) {
            r = `sorting_${this.props.sortDirection}`;
        }

        return r;
    }

    handleHeaderClick(headerName) {
        let newSortDirection = 'asc';
        if (this.props.sortFieldName === headerName && this.props.sortDirection === 'asc') {
            newSortDirection = 'desc';
        }

        if (this.props.onSortChange) {
            this.props.onSortChange(headerName, newSortDirection);
        }
        return;
    }
    /**
     * Send all click events over the "actions" cell
     * to the handler defined by the parent component.
     */
    handleActionsCellClick(event) {
        this.props.columns.filter(c => {
            return c.name === 'actions';
        })[0].onClick(event);
        return;
    }

    /**
     * Look for the catalogs on mouseenter.
     */
    handleCatalogCellMouseEnter(event) {
        let asset = event.currentTarget.getAttribute('title');
        // If catalogs for this asset are already loaded, then return.
        if (asset !== this.context.intl.messages['assets.browse.loading-catalogs']) {
            return;
        }

        AssetActions.getCatalogs(
            parseInt(event.currentTarget.getAttribute('data-asset-id'), 10)
        );
        return;
    }

    /**
     * Update the datatable columns size.
     */
    handleResize() {
        this.$tableAPI.responsive.recalc();
        return;
    }

    /**
     * This function is kind of "special" because it needs to handle
     * events bubbled from the data table rows, these rows cannot use
     * the JSX syntax because they are created by the data table
     * jQuery plugin instead of React.
     */
    handleRowClick(event) {
        switch (true) {
        // Handle click on a select input.
        case !!~event.target.className.indexOf('select-row'):
            event.preventDefault();
            const index = parseInt(event.target.getAttribute('data-index'), 10);
            this.props.onSelect(index, event.target.checked);
            break;
        // Handle click on an user's name.
        case !!~event.target.className.indexOf('edit-asset'):
            // If target _blank, then don't do anything, let the link work.
            let linkTarget = event.target.getAttribute('target');
            if (linkTarget === '_blank') {return;}
            // Prevent the default anchor click event.
            event.preventDefault();
            RouterActions.redirect(event.target.getAttribute('href'));
            break;
        }

        return;
    }

    handleToggleFiltersPanel() {
        LayoutActions.toggleFiltersPanel();
        return;
    }

    handleSelectAllClick(event) {
        this.props.onSelectAll(event.target.checked);
    }

    render() {
        let showSelect = !!(this.props.onSelectAll && this.props.onSelect);
        let pagination;

        if (!this.props.complete) {
            pagination = <div className="row">
                <div className="col-sm-7 col-sm-offset-5">
                    <Pagination
                        activePage={this.props.activePage}
                        onChange={this.props.onPageChange}
                        totalPages={this.props.totalPages}
                    />
                </div>
            </div>;
        }

        let search;
        if (this.props.onSearchChange) {
            search = <Row>
                <div className="col-md-6 show-grid">
                    <SearchBox onChange={this.props.onSearchChange} value={this.props.searchTerm}/>
                </div>
                <div className="col-md-6 show-grid">
                    <Button onClick={this.handleToggleFiltersPanel} bsStyle="primary" bsSize="large" className="pull-right">
                        <i className="fas fa-sliders-h"></i>&nbsp;{this.context.intl.messages['common.filteringOptions']}
                    </Button>
                </div>
            </Row>;
        }
        let filterPanel;
        if (this.state.showFiltersPanel && this.props.showFilterOptions) {
            filterPanel = <FilterOptions
                filters={this.state.filters}
                showCatalog={false}
                showDateRange={false}
            />;
        }
        return (
            <div className={ClassNames({'control-sidebar-open': this.state.showFiltersPanel && this.props.showFilterOptions})}>
                <div className={ClassNames({'sidebar-opened': this.state.showFiltersPanel && this.props.showFilterOptions})}>
                    {search}
                    {this.props.children}
                    <table id="list-assets-table" className="table table-bordered table-striped responsive">
                        <thead>
                            <tr>
                                {showSelect && <th className="no-sort no-arrow"><input type="checkbox" onChange={this.handleSelectAllClick} checked={this.props.selectAll}/></th>}
                                {this.props.columns.map(c => {
                                    let column = COLUMNS[c];
                                    if (c.name) {
                                        column = COLUMNS[c.name];
                                    }
                                    return column.header(this);
                                })}
                                <th className="no-sort"></th>
                            </tr>
                        </thead>
                        <tbody onClick={this.handleRowClick}></tbody>
                    </table>
                    {pagination}
                    {filterPanel}
                </div>
            </div>
        );
    }

}

export default Container.create(ListAssets);

export {
    ASSET_TYPES_BY_ID as AssetTypesById,
    SOURCE_CONTENT_TYPE_BY_ID as SourceContentTypeById
};
