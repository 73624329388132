/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ControlLabel, FormGroup, Tooltip, Label, OverlayTrigger} from 'react-bootstrap';
import ReactSelect from 'react-select';

import {sectionHeader} from './section-header';
import AssetStore from '../../../assets/asset-store';
import TimelineAssetStore from '../../../assets/timeline/timeline-asset-store';
import AssetRights from '../../../common/asset-rights/asset-rights';
import {AssetRightsConstants} from '../../../common/asset-rights/asset-rights-constants';
import BaseSelect from '../../../common/base-select/base-select';
import {FormItem, FormRow, FormSection} from '../../../common/form/form';
import {AudioProfileActions} from '../../../hardac/audio-profile-table/audio-profile-actions';
import AudioProfileStore from '../../../hardac/audio-profile-table/audio-profile-store';
import AudioProfileSelector from '../../audio-profile-table/audio-profile-selector';
import {VideoDeliveryActions} from '../video-delivery-actions.js';

export default class Options extends Component {

    static get propTypes() {
        return {
            audioProfilePresetId: PropTypes.number,
            isDisabled: PropTypes.bool,
            mediaInfoTotalAudioTracks: PropTypes.number,
            onSelectAudioProfilePreset: PropTypes.func,
            videoDelivery: PropTypes.instanceOf(Immutable.Map).isRequired,
        };
    }

    static get defaultProps() {
        return {
            audioProfilePresetId: 0,
            isDisabled: false,
            mediaInfoTotalAudioTracks: 0,
            onSelectAudioProfilePreset: () => void 0
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.handleSelectAudioProfilePreset = this.handleSelectAudioProfilePreset.bind(this);
        this.handleSelectDeliveryType = this.handleSelectDeliveryType.bind(this);
        this.handleSelectProtectionType = this.handleSelectProtectionType.bind(this);
        this.handleSelectTextedTextlessValue = this.handleSelectTextedTextlessValue.bind(this);
    }

    componentDidMount() {
        if (!AudioProfileStore.getState().get('audioProfilePresets').size) {
            AudioProfileActions.getAudioProfilePresets();
        }
    }

    handleSelectAudioProfilePreset(audioProfilePreset) {
        this.props.onSelectAudioProfilePreset(Immutable.fromJS(audioProfilePreset));
    }

    handleSelectDeliveryType(value) {
        if (value === null) {
            VideoDeliveryActions.updateVideoDelivery('deliveryType', null);
            return;
        }
        VideoDeliveryActions.updateVideoDelivery('deliveryType', value.id);
        return;
    }

    handleSelectProtectionType(value) {
        if (value === null) {
            VideoDeliveryActions.updateVideoDelivery('protectionType', null);
            return;
        }
        VideoDeliveryActions.updateVideoDelivery('protectionType', value.id);
        return;
    }

    handleSelectTextedTextlessValue(value) {
        VideoDeliveryActions.updateVideoDelivery('texted', value.texted);
        VideoDeliveryActions.updateVideoDelivery('textless', value.textless);
    }

    render() {
        const texted = this.props.videoDelivery.get('texted');
        const textless = this.props.videoDelivery.get('textless');

        const textedOptions = [
            {name: this.context.intl.messages['hardac.video-inbox.options.texted-textless.both'], texted: true, textless: true},
            {name: this.context.intl.messages['hardac.video-inbox.options.texted-textless.none'], texted: false, textless: false},
            {name: this.context.intl.messages['hardac.video-inbox.options.texted-textless.texted'], texted: true, textless: false},
            {name: this.context.intl.messages['hardac.video-inbox.options.texted-textless.textless'], texted: false, textless: true}
        ];
        const selectedTextedValue = textedOptions.find(o => (!!texted === o.texted && !!textless === o.textless));

        return (
            <div>
                {sectionHeader('fa-regular fa-file-lines',
                    this.context.intl.messages['hardac.video-inbox.options.serviceable-properties'],
                    null)}
                <FormSection>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.video-inbox.options.texted-textless']}</ControlLabel>
                            <ReactSelect
                                classNamePrefix="dropdown"
                                getOptionLabel={data => data.name}
                                getOptionValue={data => data}
                                isDisabled={this.props.isDisabled}
                                isClearable={false}
                                multi={false}
                                onChange={this.handleSelectTextedTextlessValue}
                                options={textedOptions.filter(o => !(o.texted && o.textless))}
                                value={selectedTextedValue}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.video-inbox.options.audio-configuration']}</ControlLabel>
                            <AudioProfileSelector
                                disabled={!this.props.mediaInfoTotalAudioTracks || this.props.isDisabled}
                                mediaInfoTotalAudioTracks={this.props.mediaInfoTotalAudioTracks}
                                onChange={this.handleSelectAudioProfilePreset}
                                presetId={this.props.audioProfilePresetId}
                                placeholder={''}
                            />
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.video-inbox.options.dubs']}</ControlLabel>
                            <FormItem
                                attr="hasDub"
                                label={this.context.intl.messages['hardac.video-inbox.options.has-dubs']}
                                disabled={this.props.isDisabled}
                                model={this.props.videoDelivery}
                                setter={VideoDeliveryActions.updateVideoDelivery}
                                type="checkbox"
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.video-inbox.options.subtitles']}</ControlLabel>
                            <FormItem
                                attr="hasSub"
                                label={this.context.intl.messages['hardac.video-inbox.options.has-subs']}
                                disabled={this.props.isDisabled}
                                model={this.props.videoDelivery}
                                setter={VideoDeliveryActions.updateVideoDelivery}
                                type="checkbox"
                            />
                        </FormGroup>
                    </FormRow>
                    <hr/>
                    <FormRow>
                        <FormGroup md="9">
                            <ControlLabel>{this.context.intl.messages['hardac.video-inbox.options.delivery-type']}</ControlLabel>
                            <BaseSelect
                                classNamePrefix="dropdown"
                                disabled={this.props.isDisabled}
                                isClearable={false}
                                multi={false}
                                name="deliveryType"
                                onChange={this.handleSelectDeliveryType}
                                options="deliveryType"
                                store={AssetStore}
                                value={AssetStore.getDeliveryType(this.props.videoDelivery.get('deliveryType'))}
                            />
                        </FormGroup>
                        <FormGroup md="3">
                            <ControlLabel>{this.context.intl.messages['hardac.video-inbox.options.delivery-formats']}</ControlLabel>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>{this.context.intl.messages['common.video-delivery-format.mxf-mp4.tooltip']}</Tooltip>
                                }>
                                <Label bsSize="md" className="label label-gray">{this.context.intl.messages['common.video-delivery-format.mxf-mp4']}</Label>
                            </OverlayTrigger>
                        </FormGroup>
                    </FormRow>
                </FormSection>
                {sectionHeader('fa-solid fa-shield-halved',
                    this.context.intl.messages['hardac.video-inbox.options.protection-type'],
                    null)}
                <FormSection>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.video-inbox.options.protection-type']}</ControlLabel>
                            <BaseSelect
                                classNamePrefix="dropdown"
                                disabled={this.props.isDisabled}
                                isClearable={true}
                                multi={false}
                                name="protectionType"
                                onChange={this.handleSelectProtectionType}
                                options="selectedProtectionType"
                                store={TimelineAssetStore}
                                value={TimelineAssetStore.getProtectionType(this.props.videoDelivery.get('protectionType'))}
                            />
                        </FormGroup>
                    </FormRow>
                </FormSection>
                {sectionHeader('fa-regular fa-copyright',
                    this.context.intl.messages['hardac.video-inbox.options.rights-info'],
                    null)}
                <FormSection>
                    <FormRow>
                        <FormGroup>
                            <AssetRights
                                defaultRightsInfoValue={AssetRightsConstants.RIGHTS_INFO_TYPES.DEFAULT.id}
                                disabled={this.props.isDisabled}
                                model={this.props.videoDelivery}
                                updater={VideoDeliveryActions.updateVideoDelivery}
                            />
                        </FormGroup>
                    </FormRow>
                </FormSection>
            </div>
        );
    }
}
