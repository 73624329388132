/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {LanguageCodeType} from '@wbdt-sie/brainiac-web-common';
import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {VideoConstants} from './video-actions';
import {AssetTabConstants} from '../../assets-tab/asset-tab-actions';
import {VideoContentTypes} from '../../assets-tab/categories';
import {AssetRightsConstants} from '../../common/asset-rights/asset-rights-constants';
import Validations from '../../common/validations/validations';
import Dispatcher from '../../dispatcher/dispatcher';
import enUS from '../../messages/en-US';
import {ActionHistoryConstants} from '../../system/action-history/action-history-actions';

import pick from '~/src/common/utils/pick';

/**
 * Look for a constant with id === objectId in a map.
 */

const EXACT_ALLOWED_WIDTH = 1920;
const EXACT_ALLOWED_HEIGHT = 1080;

const findInConstant = (constants, objectId) => {
    return Immutable.fromJS(
        Object.keys(constants)
            .map(k => constants[k])
            .filter(obj => objectId === obj.id)[0]
    );
};

const filterObjectsInConstants = (constants, arrayObjects = []) => {
    const resultArray = arrayObjects.map(objectItem => {
        if (Object.keys(constants).findIndex(key => constants[key].id === objectItem.get('id')) >= 0) {
            return objectItem;
        }
    });
    return Immutable.List(resultArray);
};

const VideoValidations = {
    assetName: {
        label: 'asset.video.create.name',
        validations: [Validations.max(255), Validations.missing()]
    },
    assetComment: {
        label: 'asset.video.create.assetComment',
        validations: [Validations.max(255)]
    },
    contentType: {
        label: 'asset.video.create.contentType',
        validations: [Validations.missing()]
    },
    copyrightText: {
        label: 'asset.video.create.copyrightText',
        validations: [Validations.max(200)]
    },
    copyrightYear: {
        label: 'asset.video.create.copyrightYear',
        validations: [Validations.max(4)]
    },
    creditStartSeconds: {
        validations: [Validations.custom(
            function() {
                return 'Credits Start Timecode contains errors. Correct format is 01:10:20:00 or you can leave it blank.';
            },
            function() {
                let isValid = true;
                let value = video.getState().get('asset').get('creditStartSeconds');
                if (value !== undefined && value !== null) {
                    let isValidTimecode = new RegExp(/(^(?:(?:[0-1][0-9]|[0-2][0-3]):)(?:[0-5][0-9]:){2}(?:[0-9][0-9])$)/);
                    isValid = isValidTimecode.test(value);
                }
                return isValid;
            }
        )]
    },
    deliveryType: {
        label: 'asset.video.create.delivery-type',
        validations: [Validations.missing()]
    },
    disclaimerText: {
        label: 'asset.video.create.disclaimer',
        validations: [Validations.max(4000)]
    },
    keyword: {
        label: 'asset.video.create.keyword',
        validations: [Validations.max(250)]
    },
    maxNumberOfView: {
        validations: [Validations.custom(
            function() {
                return 'Maximum Views Allowed must be a whole number between 0 and 100';
            },
            function() {
                let isValid = true;
                let value = video.getState().get('asset').get('maxNumberOfView');
                if (value !== undefined && value !== '' && value !== null) {
                    // Business rule is a whole number between 0..100
                    let isNumeric = new RegExp('^\\d+$');
                    isValid = isNumeric.test(value);
                    if (parseInt(value, 10) > 100) {
                        isValid = false;
                    }
                }
                return isValid;
            }
        )]
    },
    mediaKey: {
        label: 'asset.video.create.mediakey',
        validations: [
            Validations.max(50),
            Validations.info(
                () => {
                    return enUS['asset.video.create.cannot-activate-invalid-mediakey'];
                },
                () => {
                    let value = video.getState().get('asset').get('mediaKey');
                    return video.validateMediaKey(value);
                }
            )
        ]
    },
    notes: {
        label: 'asset.video.create.notes',
        validations: [Validations.max(1000)]
    },
    runtime: {
        label: 'asset.video.create.runtime',
        validations: [Validations.max(50)]
    },
    streamingDrmContentId: {
        label: 'asset.video.summary.screeners.streaming-drm-content-id',
        validations: [Validations.max(100)]
    },
    tapes: {
        label: 'asset.video.create.tapes',
        validations: [Validations.max(1000)]
    },
    watermarkText: {
        label: 'asset.video.create.watermark',
        validations: [Validations.max(4000)]
    }
};

const WebVTTFilesValidations = {
    captionField: {
        validations: [Validations.custom(
            function() {
                return 'File Type, Language and File are required fields in Captions & Subtitles.';
            },
            function() {
                const rules = [Validations.missing()];
                return video.getState().get('webVTTFiles').every((caption) => {
                    const fileType = caption.get('fileType');
                    const languageCode = caption.get('languageCode');

                    let isValid = true;
                    isValid = isValid && rules.every(r => r.validate(`${fileType}`));
                    isValid = isValid && rules.every(r => r.validate(languageCode));
                    return isValid;
                });
            }
        ), Validations.custom(
            function() {
                return 'Each video can have multiple file associated with it, but only 1 active per language and file type in Captions & Subtitles.';
            },
            function() {
                const cache = {};

                return video.getState().get('webVTTFiles').every((caption) => {
                    // we could have few inactive files for the same language and type
                    if (!caption.get('active')) {
                        return true;
                    }

                    const key = `${caption.get('languageCode')}__${caption.get('fileType')}`;
                    if (cache[key]) {
                        return false;
                    }

                    cache[key] = true;
                    return true;
                });
            }
        )]
    },
    notesField: {
        validations: [Validations.max(300)]
    },
};

const languageOptions = LanguageCodeType.toArray();

class VideoStore extends ReduceStore {
    validatePosterImageSize(img, intl) {
        if (img.naturalWidth === EXACT_ALLOWED_WIDTH && img.naturalHeight === EXACT_ALLOWED_HEIGHT) {
            return [];
        }

        return [{message: intl.messages['asset.video.poster.image.size'], severity: 1, type: 'custom'}];
    }

    validateMediaKey(value) {
        // BRAIN-4102 only media keys starting with HARDAC or BRAINIAC are considered valid for asset activation
        const mediaKey = value || '';
        return mediaKey.substr(0, 6) === 'HARDAC' || mediaKey.substr(0, 8) === 'BRAINIAC';
    }

    convertVideoAssetToPlayerSource(video) {
        if (!video) {
            return null;
        }

        return Immutable.fromJS({
            drmTokens: video.get('drmToken'),
            dash: video.getIn(['streams', 0, 'drmMpegDashUrl']),
            hls: video.getIn(['streams', 0, 'drmHlsUrl'])
        });
    }

    getAudioConfigType(audioConfigTypes) {
        return filterObjectsInConstants(VideoConstants.AUDIO_CONFIG_TYPES, audioConfigTypes);
    }

    getContentType(contentTypeId) {
        return findInConstant(VideoConstants.CONTENT_TYPES, contentTypeId);
    }

    getLanguage(languageId) {
        return findInConstant(languageOptions, languageId);
    }

    getValidations() {
        return Validations.validate(this.getState().get('asset'), VideoValidations);
    }

    getWebVTTFileType(fileTypeId) {
        return findInConstant(VideoConstants.WEB_VTT_FILE_TYPES, fileTypeId);
    }

    getWebVTTFileValidations() {
        const files = this.getState().get('webVTTFiles');
        return Validations.validate(files, pick(WebVTTFilesValidations, ['captionField']));
    }

    /**
     * Gets videos grouped by video content type
     * @param {Immutable.List} assets
     */
    groupAssetsByType(assets) {
        assets = assets.toJS();
        return assets.reduce((groups, asset) => {
            const videoType = VideoContentTypes[asset.contentType];
            if (videoType) {
                const list = groups[videoType] || [];
                groups[videoType] = [...list, asset];
            }
            return groups;
        }, {});
    }

    getInitialState() {
        return Immutable.Map({
            allPlatforms: Immutable.List(),
            asset: Immutable.Map({
                active: 0,
                disableVisualWatermark: false,
                disclaimerText: '',
                files: [],
                posterImageFile: {
                    name: ''
                },
                tapes: '',
                thumbnailFrameNumber: null,
                thumbnails: Immutable.List(),
            }),
            audioConfigType: Immutable.fromJS(VideoConstants.toArray('AUDIO_CONFIG_TYPES')),
            contentType: Immutable.fromJS(VideoConstants.toArray('CONTENT_TYPES')),
            history: Immutable.List(),
            platformsLoaded: false,
            initialSelectedPlatforms: Immutable.List(),
            languages: Immutable.fromJS(languageOptions),
            newWebVttData: Immutable.Map({
                file: null,
                showModal: false,
                vtt: Immutable.Map({}),
            }),
            originalAsset: Immutable.Map({
                active: true,
                tapes: '',
                thumbnails: Immutable.List(),
            }),
            originalFile: null,
            originalWebVTTFiles: Immutable.List(),
            selectedPlatforms: Immutable.List(),
            showPreloader: false,
            showPlayer: false,
            thumbnails: Immutable.List(),
            videoRightsMediaType: Immutable.fromJS(AssetRightsConstants.toArray('RIGHTS_MEDIA_TYPES')),
            videoRightsTermType: Immutable.fromJS(AssetRightsConstants.toArray('RIGHTS_TERM_TYPES')),
            videoRightsTerritoryType: Immutable.fromJS(AssetRightsConstants.toArray('RIGHTS_TERRITORY_TYPES')),
            videoRightsType: Immutable.fromJS(AssetRightsConstants.toArray('RIGHTS_TYPES')),
            webVTTFiles: Immutable.List(),
            webVTTFileType: Immutable.fromJS(VideoConstants.toArray('WEB_VTT_FILE_TYPES')),
        });
    }

    reduce(state, action) {
        switch (action.actionType) {
        case ActionHistoryConstants.ADD_NOTE.SUCCESS:
            if (action.actionObjectType === ActionHistoryConstants.ACTION_OBJECTS.VIDEO) {
                state = state.updateIn(['history'], history => history.unshift(action.note));
            }
            break;

        case AssetTabConstants.HIDE_DETAIL:
            state = state.set('showPlayer', false);
            break;

        case VideoConstants.VIDEO.ADD_CAPTION:
            state = state.updateIn(['webVTTFiles'],
                (webVTTFiles) => webVTTFiles.push(action.caption)
            );
            state = state.updateIn(['originalWebVTTFiles'],
                (webVTTFiles) => webVTTFiles.push(action.caption)
            );

            break;

        case VideoConstants.VIDEO.ALLOW_ON_ALL:
            state = state.merge({
                selectedPlatforms: []
            });
            break;

        case VideoConstants.VIDEO.CAPTION_CHANGE_ACTIVE_FLAG:
            state = state.updateIn(['webVTTFiles'],
                webVTTFiles => webVTTFiles.map((file) => {
                    if (file.get('videoWebVttId') === action.caption.get('videoWebVttId')) {
                        return file.set('active', action.active);
                    }
                    if (file.get('fileType') === action.caption.get('fileType') &&
                        file.get('languageCode') === action.caption.get('languageCode')) {
                        // reset active status for other files with the same type and language
                        return file.set('active', false);
                    }
                    return file;
                })
            );

            break;

        case VideoConstants.VIDEO.CHANGE_VTT_MODAL_VISIBILITY:
            const videoId = state.getIn(['asset', 'id']);
            state = state.set('newWebVttData', Immutable.fromJS({
                file: null,
                showModal: action.show,
                vtt: {
                    videoId,
                    s3Path: null,
                    languageCode: null,
                    fileType: 1,
                    videoSearchBatchId: null,
                    parentVideoWebVttId: null,
                    versionNumber: 1,
                    notes: '',
                    source: 'USER',
                    active: false,
                    status: 'UNREVIEWED',
                },
            }));
            break;

        case VideoConstants.VIDEO.CLEAR:
            state = this.getInitialState();
            break;

        case VideoConstants.VIDEO.DISABLE_ON_WEB:
            state = state.merge({
                selectedPlatforms: state.get('allPlatforms').filter(platform => platform.get('platformType') !== VideoConstants.VIDEO.PLATFORM_TYPE.WEB)
            });
            break;

        case VideoConstants.VIDEO.ENRICH_CAPTION:
            const enrichCaption = (files) => files.map((vtt) => {
                if (vtt.get('videoWebVttId') === action.caption.get('videoWebVttId')) {
                    return action.caption;
                }
                return vtt;
            });
            state = state.updateIn(['webVTTFiles'], enrichCaption);
            state = state.updateIn(['originalWebVTTFiles'], enrichCaption);
            break;

        case VideoConstants.VIDEO.EDIT_PLATFORMS:
            state = state.merge({
                selectedPlatforms: action.updateList
            });
            break;

        case VideoConstants.VIDEO.GET.ERROR:
            state = state.merge({
                showPreloader: false
            });
            break;

        case VideoConstants.VIDEO.GET.START:
            state = this.getInitialState();
            state = state.merge({
                showPreloader: true
            });
            break;

        case VideoConstants.VIDEO.GET.SUCCESS:
            state = state.merge({
                asset: action.asset,
                history: action.history,
                originalAsset: action.asset,
                showPreloader: false,
                originalWebVTTFiles: action.webVTTFiles,
                webVTTFiles: action.webVTTFiles
            });
            break;

        case VideoConstants.VIDEO.GET_ALL_PLATFORMS.SUCCESS:
            // Only adding the ones with platformType defined
            const availablePlatforms = action.allPlatforms.filter( platform => !!platform.get('platformType'));
            state = state.merge({
                showPreloader: false,
                allPlatforms: availablePlatforms
            });
            break;
        case VideoConstants.VIDEO.GET_SELECTED_PLATFORMS.SUCCESS:
            state = state.merge({
                showPreloader: false,
                selectedPlatforms: action.selectedPlatforms,
                initialSelectedPlatforms: action.selectedPlatforms,
                platformsLoaded: true
            });
            break;

        case VideoConstants.VIDEO.ONLY_ALLOW_ON_TV:
            state = state.merge({
                selectedPlatforms: state.get('allPlatforms').filter(platform => platform.get('platformType') === VideoConstants.VIDEO.PLATFORM_TYPE.TV)
            });
            break;

        case VideoConstants.VIDEO.REMOVE_CAPTION:
            const idxPredicate = (vtt) => vtt.get('videoWebVttId') === action.videoWebVttId;
            const captionIndex = state.get('webVTTFiles').findIndex(idxPredicate);
            const modifyCaptions = (captions) => {
                captions = captions.map((vtt) => {
                    if (vtt.get('parentVideoWebVttId') === action.videoWebVttId) {
                        return vtt.set('parentVideoWebVttId', null);
                    }
                    return vtt;
                }).toList();
                return captions.delete(captionIndex);
            };
            state = state.updateIn(['webVTTFiles'], modifyCaptions);
            break;

        case VideoConstants.VIDEO.GET_ALL_PLATFORMS.ERROR:
        case VideoConstants.VIDEO.GET_SELECTED_PLATFORMS.ERROR:
        case VideoConstants.VIDEO.SAVE.ERROR:
            state = state.merge({
                showPreloader: false
            });
            break;

        case VideoConstants.VIDEO.GET_ALL_PLATFORMS.START:
        case VideoConstants.VIDEO.GET_SELECTED_PLATFORMS.START:
        case VideoConstants.VIDEO.SAVE.START:
            state = state.merge({
                showPreloader: true
            });
            break;

        case VideoConstants.VIDEO.SAVE.SUCCESS:
            state = state.setIn(['asset', 'files'], []);
            state = state.setIn(['asset', 'posterImageFile'], {name: ''});
            state = state.merge({
                initialSelectedPlatforms: action.selectedPlatforms,
                showPreloader: false,
                originalAsset: state.get('asset'),
                originalFile: action.file,
                originalWebVTTFiles: state.get('webVTTFiles')
            });
            break;

        case VideoConstants.VIDEO.SHOW_PLAYER:
            state = state.set('showPlayer', true);
            state = state.set('asset', action.video);

            //update also originalAsset after request drmToken and resquest again streams the data
            state = state.setIn(['originalAsset', 'streams'], action.video.get('streams'));
            state = state.setIn(['originalAsset', 'drmToken'], action.video.get('drmToken'));
            break;

        case VideoConstants.VIDEO.UPDATE:
            state = state.setIn(['asset', ...action.attr.split('.')], action.value);
            break;

        case VideoConstants.VIDEO.UPDATE_NEW_VTT:
            state = state.setIn(['newWebVttData', ...action.attr.split('.')], action.value);
            break;

        case VideoConstants.VIDEO.UPDATE_VTT:
            const idx = state.get('webVTTFiles').findIndex((vtt) => vtt.get('videoWebVttId') === action.vttId);
            state = state.setIn(['webVTTFiles', idx, action.attr], action.value);
            break;
        case VideoConstants.VIDEO.SET_THUMBNAILS:
            state = state.merge({
                thumbnails: action.thumbnails
            });
            break;
        }
        return state;
    }
}

const video = new VideoStore(Dispatcher);

export default video;
export {VideoValidations, WebVTTFilesValidations};
