/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import jQuery from 'jquery';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {AssetTabActions} from './asset-tab-actions';

require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('datatables.net-rowreorder-bs/css/rowReorder.bootstrap.css');
require('../styles/data-tables-brainiac.css');
require('datatables.net-rowreorder');
require('datatables.net-responsive-bs');


class InnerVideoTable extends Component {

    static get propTypes() {
        return {
            assets: PropTypes.instanceOf(Immutable.List).isRequired,
            disabled: PropTypes.bool,
            order: PropTypes.number.isRequired,
            path: PropTypes.string.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
            router: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: false,
        };
    }

    constructor(props) {
        super(props);

        this.getTableId = this.getTableId.bind(this);
        this.handleReorder = this.handleReorder.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.initializeTable = this.initializeTable.bind(this);
        this.loadData = this.loadData.bind(this);
    }

    componentDidMount() {
        this.initializeTable();
        this.loadData(this.props);
        return;
    }


    shouldComponentUpdate(nextProps/*, nextState*/) {
        if (nextProps.assets !== this.props.assets || nextProps.order !== this.props.order) {
            return true;
        }
        return false;
    }

    componentWillUpdate(nextProps /*, prevState*/) {
        this.loadData(nextProps);
    }


    componentWillUnmount() {
        // check if undefinded only to enable render test
        if (this.$table && !this.props.disabled) {
            this.$table.off('row-reorder');
        }
        // check if undefinded only to enable render test
        if (this.$tableAPI) {
            this.$tableAPI.destroy();
        }
        return;
    }

    loadData(props) {
        this.$tableAPI.clear();
        props.assets.forEach((asset, order) => {
            let titleRow = `<a class="show-asset" order=${order} href="#">${asset.get('name')}</a>`;

            if (asset.get('mfaRequired')) {
                titleRow = `<a class="show-asset" order=${order} href="#"><i class="fas fa-lock-alt"></i>&nbsp;${asset.get('name')}</a>`;
            }

            this.$tableAPI.row.add([
                order+1,
                `<a class="video-stack-link unlink" order=${order}><i class="fas fa-unlink unlink" order=${order}/></a>`,
                titleRow,
                ''
            ]);
        });
        this.$tableAPI.draw(false);
        // Now, since the data has changed the columns widths, trigger
        // the resize handler in order to update the responsive feature.
        this.handleResize();
    }

    handleReorder( event, diff, edit ) {
        event.preventDefault();
        let fromPos = edit.triggerRow[0][0];
        if (diff.length < 2) {
            return;
        }
        let toPos;
        if (diff[0].oldPosition === fromPos) {
            toPos = diff[0].newPosition;
        } else {
            toPos = diff[diff.length-1].newPosition;
        }
        let path = this.props.path.split('.');
        setTimeout(() => {
            AssetTabActions.reorder(path, fromPos, toPos);
            return;
        }, 0);
    }

    /**
     * Update the datatable columns size.
     */
    handleResize() {
        this.$tableAPI.responsive.recalc();
        return;
    }

    getTableId() {
        let id = 'inner-video-table-'+this.props.path.split('.').join('-').split(' ').join('-').split('/').join('-').toLowerCase();
        return id;
    }

    initializeTable() {
        this.$table = jQuery('#'+this.getTableId());
        let reorder = '';
        if (!this.props.disabled) {
            reorder = 'row-reorder-handle';
        }
        let configTable = {
            autoWidth: false,
            columnDefs: [{
                // Add the control class to the last column. This colum will
                // contain the button to show the responsive data.
                className: 'control',
                targets:   -1,
                width: 20
            }, {
                className: 'row-reorder-handle',
                targets: 0
            }, {
                targets: 1,
                className: 'text-center row-index' + {reorder}
            }, {
                targets: 2,
                className: null
            }, {
                targets: 3,
                className: null
            }, {
                targets: 'no-sort',
                orderable: false
            }],
            iDisplayLength: 1,
            info: false,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        };
        if (!this.props.disabled) {
            configTable.rowReorder = {
                selector: '.row-reorder-handle'
            };
        }
        this.$tableAPI = this.$table.DataTable(configTable);
        // Add some custom handlers.
        if (!this.props.disabled) {
            this.$tableAPI.on('row-reorder', this.handleReorder);
        }
        // Register global listeners.
        window.addEventListener('resize', this.handleResize);
        // And trigger the resize handler once so that the datatable
        // knows its initial dimensions.
        this.$tableAPI.draw(false);
        this.handleResize();
    }

    handleRowClick(event) {
        let where;
        let order;
        switch (true) {
        case !!~event.target.className.indexOf('show-asset'):
            // Prevent the default anchor click event.
            event.preventDefault();
            where = this.props.path.split('.');
            order = parseInt(event.target.getAttribute('order'), 10);
            where.push(order);
            AssetTabActions.show(where, this.props.assets.get(order));
            break;
        case !!~event.target.className.indexOf('unlink'):
            // Prevent the default anchor click event.
            event.preventDefault();
            /* istanbul ignore if */
            if (this.props.disabled) {
                return;
            }
            where = this.props.path.split('.');
            order = parseInt(event.target.getAttribute('order'), 10);
            AssetTabActions.unlink(where, order);
            break;
        }
        return;
    }

    render() {
        return (
            <table id={this.getTableId()} className="table table-bordered table-striped responsive datatable-video-children">
                <tbody onClick={this.handleRowClick}>
                </tbody>
            </table>
        );
    }

}

export default InnerVideoTable;
