/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, ControlLabel, FormControl, FormGroup} from 'react-bootstrap';
import ReactTable from 'react-table';

import {FormRow, FormSection} from '../form/form';

class FileInput extends Component {
    static get propTypes() {
        return {
            accept: PropTypes.string.isRequired,
            disabled: PropTypes.bool,
            files: PropTypes.array.isRequired,
            id: PropTypes.string.isRequired,
            multiple: PropTypes.bool,
            onChange: PropTypes.func.isRequired,
            uploadedImageSrc: PropTypes.string,
            withoutFormSection: PropTypes.bool,
            withoutTable: PropTypes.bool,
        };
    }

    static get defaultProps() {
        return {
            disabled: false,
            multiple: false,
            uploadedImageSrc: undefined,
            withoutFormSection: false,
            withoutTable: false,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.dummyInput = null;

        this.setDummyInputRef = this.setDummyInputRef.bind(this);
        this.handleClickDummyInput = this.handleClickDummyInput.bind(this);
    }

    setDummyInputRef(element) {
        this.dummyInput = element;
    }

    handleClickDummyInput() {
        if (this.dummyInput) {
            this.dummyInput.click();
        }
    }

    render() {
        const dummyInputId = this.props.id + '-dummy-input';
        const propBag = {
            accept: this.props.accept,
            disabled: this.props.disabled,
            id: dummyInputId,
            onChange: this.props.onChange
        };
        let buttonText = 'common.select-file';
        let tableColumnText = 'common.file-list';
        if (this.props.multiple) {
            propBag.multiple = true;
            buttonText = 'common.select-files';
            tableColumnText = 'common.files-list';
        }
        const dummyInput = <input type="file" ref={this.setDummyInputRef} style={{display: 'none'}} {...propBag}/>;

        const pageSize = this.props.files.length;

        let column = [{
            Header:  <ControlLabel>{this.context.intl.messages[tableColumnText]}</ControlLabel>,
            width: '100%',
            maxWidth: '0',
            Cell: c => {
                return (
                    <FormControl.Static>
                        {c.original}
                    </FormControl.Static>
                );
            }
        }];

        const formRow = (<FormRow>
            <FormGroup>
                {dummyInput}
                <Button
                    bsStyle="default"
                    className="bg-wb-blue"
                    disabled={this.props.disabled}
                    onClick={this.handleClickDummyInput}
                >
                    <i className="fas fa-upload"></i>&nbsp;{this.context.intl.messages[buttonText]}
                </Button>
                {!this.props.withoutTable && <ReactTable
                    className="-striped responsive table-bordered table-striped file-input"
                    columns={column}
                    data={this.props.files}
                    getProps={() => {
                        return {
                            style: {
                                height: 'initial',
                                maxHeight: '200px',
                                marginTop: '10px'
                            }
                        };
                    }}
                    id={`filename-table-${this.props.id}`}
                    loading={false}
                    pageSize={pageSize}
                    resizable={false}
                    showPagination={false}
                    sortable={false}
                />}
                {this.props.uploadedImageSrc && <img alt="Recently uploaded" src={this.props.uploadedImageSrc} style={{marginTop: '10px', border: '1px solid #ddd', width: '100%', background: 'repeating-conic-gradient(#808080 0% 25%, transparent 0% 50%) 50% / 20px 20px'}} />}
            </FormGroup>
        </FormRow>);

        let returnContent = formRow;
        if (!this.props.withoutFormSection) {
            returnContent = (<FormSection>
                {formRow}
            </FormSection>);
        }

        return returnContent;
    }
}

export default FileInput;
