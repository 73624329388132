/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {UserErrorActions} from './user-errors-actions';
import UserErrorsStore from './user-errors-store';
import Table from '../../../common/user-errors-table/user-errors-table';
import Preloader from '../../../preloader';

import {WithStoreOnRoute} from '~/src/common/store-on-route';
class UserErrors extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            userId: PropTypes.string.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        const store = UserErrorsStore.getState();
        return {
            offset: store.get('offset'),
            showPreloader: store.get('showPreloader'),
            total: store.get('total'),
            userErrors: store.get('userErrors'),
        };
    }

    static getStores() {
        return [UserErrorsStore];
    }

    constructor(props) {
        super(props);

        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.init = this.init.bind(this);
    }


    componentDidMount() {
        this.init();
        return;
    }

    componentWillUpdate(nextProps) {
        if (this.props.location.query !== nextProps.location.query) {
            this.init(nextProps);
        }
        return;
    }

    handleLoadPage(pageNumber) {
        this.setRouteState('offset', pageNumber * 20)
            .apply();
        return;
    }

    handleSortChange(sortFieldName, sortDirection) {
        this.setRouteState('sort-field-name', sortFieldName)
            .setRouteState('sort-direction', sortDirection)
            .apply();
        return;
    }

    init(props) {
        const routeState = this.getRouteState(props || this.props);
        UserErrorActions.findErrorsByUserId(this.props.userId, routeState);
    }

    render() {
        const COLUMNS = ['userErrorId', 'userSessionId', 'uriMethod', 'uri', 'uriBody', 'userErrorType', 'userError', 'createdDate'];
        return (<Preloader show={this.state.showPreloader} fixed loadingDots>
            <section className="content-header">
                <h1>
                    <i className="fa fa-exclamation-triangle"></i>&nbsp;{this.context.intl.messages['accounts.create.user-errors.tab-title']}
                    &nbsp;<small>{this.state.total}</small>
                </h1>
            </section>
            <section className="content">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="container-fluid no-x-padding">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="box">
                                        <div className="box-body">
                                            <Table
                                                activePage={Math.ceil((this.state.offset || 0) / 20 ) || 0}
                                                columns={COLUMNS}
                                                onPageChange={this.handleLoadPage}
                                                onSortChange={this.handleSortChange}
                                                sortDirection={this.getRouteState().get('sort-direction') || 'asc'}
                                                sortFieldName={this.getRouteState().get('sort-field-name') || 'createdDate'}
                                                totalPages={Math.ceil(this.state.total/20) || 0}
                                                userErrors={this.state.userErrors}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Preloader>
        );
    }
}

export default WithStoreOnRoute(Container.create(UserErrors));
