/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';

import {NotificationActions} from '../common/notification/notification-actions';
import {UploadFile} from '../common/utils/utils';
import Dispatcher from '../dispatcher/dispatcher';
import Request from '../request';
import {RouterActions} from '../router/router-actions';

const CONSTANTS = {
    CLEAR: 'brands.clear',
    BRAND: {
        SAVE: {
            ERROR: 'brand.save.error',
            START: 'brand.save.start',
            SUCCESS: 'brand.save.success'
        }
    },
    BRANDS: {
        GET: {
            ERROR: 'brands.get.error',
            START: 'brands.get.start',
            SUCCESS: 'brands.get.success'
        }
    },
    FIND_BY_ID: {
        ERROR: 'brands.find-by-id.error',
        START: 'brands.find-by-id.start',
        SUCCESS: 'brands.find-by-id.success'
    },
    UPDATE: 'brand.update'
};

class BrandActions {

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
    }

    findById(id) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.FIND_BY_ID.START
        });

        Request.get(`lookup/brand/${id}`).exec()
            .then(response => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.FIND_BY_ID.SUCCESS,
                    brand: Immutable.fromJS(response.body),
                });
            }).catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.FIND_BY_ID.ERROR
                });
                switch (err.status) {
                case 404:
                    NotificationActions.showAlertDanger('brands.find-by-id.error.does-not-have-access');
                    break;
                default:
                    NotificationActions.showAlertDanger('common.load-error');
                    break;
                }
                throw err;
            });

        return;
    }

    get(queryParams) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.BRANDS.GET.START
        });

        queryParams = queryParams.toJS();
        queryParams.offset = 0 ;
        queryParams.size = 9999;

        Request.get('lookup/brand')
            .query(
                queryParams
            ).exec()
            .then(response => {
                const brands = Immutable.fromJS(response.body.results);
                Dispatcher.dispatch({
                    actionType: CONSTANTS.BRANDS.GET.SUCCESS,
                    brands,
                    total: response.header['wbtv-total-count'],
                });
                return;
            }).catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.BRANDS.GET.ERROR
                });

                switch (err.status) {
                case 404:
                    NotificationActions.showAlertDanger('brands.browse.get.error.does-not-have-access');
                    break;
                default:
                    NotificationActions.showAlertDanger('brands.browse.get.error.does-not-load');
                    break;
                }

                throw err;
            });

        return;
    }

    save(brand, logoFile) {
        let brandData = brand.toJS();
        let a = 0;
        if (brandData.active) {
            a = 1;
        }
        brandData.active = a;
        delete brandData.files;

        let isPost = true;
        let method = Request.post;
        let uri = 'lookup/brand';
        let mode = 'create';
        // Check if PUT.
        let id = brandData.id;
        if (id !== undefined) {
            isPost = false;
            method = Request.put;
            uri = `lookup/brand/${id}`;
            mode = 'edit';
        }

        const saveRequest = method(uri)
            .send(brandData).exec()
            .catch(err => {
                NotificationActions.showAlertDanger(`brands.${mode}.save.error`);
                throw err;
            });

        let uploadFileRequest;
        if (logoFile) {
            uploadFileRequest = UploadFile(
                'PUT', `lookup/brand/${id}/logo-file`, logoFile, new XMLHttpRequest()
            ).catch(err => {
                NotificationActions.showAlertDanger('brands.edit.upload.logo.error');
                throw err;
            });
        }

        Dispatcher.dispatch({
            actionType: CONSTANTS.BRAND.SAVE.START,
        });

        Promise.all([saveRequest, uploadFileRequest])
            .spread((brandResponse, logoResponse) => {
                let brandRes = brandResponse.body;
                if (logoResponse) {
                    brandRes = JSON.parse(logoResponse);
                }
                Dispatcher.dispatch({
                    actionType: CONSTANTS.BRAND.SAVE.SUCCESS,
                    brand: Immutable.fromJS(brandRes)
                });
                if (isPost) {
                    RouterActions.redirect(`/brands/${brandRes.id}`, true);
                }
                NotificationActions.showAlertSuccess(`brands.${mode}.save.success`);
            })
            .catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.BRAND.SAVE.ERROR,
                    error: err,
                });
            });

        return;
    }

    update(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.UPDATE,
            attr,
            value
        });

        return;
    }

}

let actions = new BrandActions();

export {
    actions as BrandActions,
    CONSTANTS as BrandConstants
};
