/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

import AddExistingPanel from './add-existing-panel';
import Summary from './summary/summary';
import ListAssets from '../../../assets/browse/list-assets';
import RouteValidator from '../../../common/custom-validator/route/route';
import DocumentTitle from '../../../common/document-title';
import {MODAL_TYPE} from '../../../common/notification/modal';
import {NotificationActions} from '../../../common/notification/notification-actions';
import {Tab, Tabs} from '../../../common/routing-tab/routing-tab';
import SearchBox from '../../../common/search-box/search-box';
import {History, SideNotes} from '../../../common/side-notes/side-notes';
import {SlidingPanelActions} from '../../../common/sliding-panel/sliding-panel-actions';
import {WithStoreOnRoute} from '../../../common/store-on-route';
import {Debounce} from '../../../common/utils/utils';
import {ErrorsWarnings} from '../../../common/validations/errors-warnings';
import {ERRORTYPE} from '../../../common/validations/helpers';
import ActionsMenu from '../../../layout/actions-menu/actions-menu';
import StickyHeader from '../../../layout/sticky-header/sticky-header';
import Preloader from '../../../preloader';
import {RouterActions} from '../../../router/router-actions';
import {GroupActions, GroupConstants} from '../../../security/group/group-actions';
import GroupStore from '../../../security/group/group-store';
import {ActionHistoryActions, ActionHistoryConstants} from '../../../system/action-history/action-history-actions';
import SessionStore from '../../../user/session/session-store';
import DeleteInfoModal from '../../delete-info-modal';
import UserGroups from '../../user-groups';


class Create extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            params: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired,
            route: RouteValidator // eslint-disable-line react/require-default-props
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            assetCatalog: GroupStore.getState().get('asset').get('group'),
            groupAssets: GroupStore.getState().get('asset').get('groupAssets'),
            history: GroupStore.getState().get('asset').get('history'),
            originalAssetCatalog: GroupStore.getState().get('asset').get('originalGroup'),
            originalGroupAssets: GroupStore.getState().get('asset').get('originalGroupAssets'),
            showPreloader: GroupStore.getState().get('showPreloader'),
            totalCount: GroupStore.getState().get('asset').get('totalCount'),
            userGroups: GroupStore.getState().get('group').get('userGroups')
        };
    }

    static getStores() {
        return [GroupStore];
    }

    static getPermissions() {
        return {
            canCreate: SessionStore.canUser(SessionStore.PERMISSIONS.CATALOGS.ASSET.CREATE),
            canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.CATALOGS.ASSET.EDIT),
            canReadAudioAssets: SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.AUDIO.READ),
            canReadDocumentAssets: SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.ASSET_DOCUMENT.READ),
            canReadGroups: SessionStore.canUser(SessionStore.PERMISSIONS.GROUPS.READ),
            canReadImageAssets: SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.ASSET_IMAGE.READ),
            canReadMerchandiseAssets: SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.MERCHANDISE.READ),
            canReadVideoAssets: SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.VIDEO.READ)
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            complete: false,
            activeTab: 0,
            showDeleteInfoModal: false
        }, this.constructor.calculateState());

        this.addNote = this.addNote.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
        this.isDirty = this.isDirty.bind(this);
        this.showDeleteDialog = this.showDeleteDialog.bind(this);
        this.toggleDeleteInfoModal = this.toggleDeleteInfoModal.bind(this);
    }

    componentWillMount() {
        GroupActions.clear();
        return;
    }

    componentDidMount() {
        this.init(this.props);
        return;
    }

    componentWillUpdate(nextProps) {
        if (this.props.location !== nextProps.location && nextProps.location.pathname.includes('assets')) {
            GroupActions.getGroupAssets(this.props.params.id, 20, this.getRouteState().get('offset'), this.getRouteState().get('asset'));
        }
        return;
    }

    addNote(description) {
        ActionHistoryActions.addNote(this.props.params.id, description, ActionHistoryConstants.ACTION_OBJECTS.GROUP, GroupConstants.ASSET.name);
    }

    handleCancel() {
        RouterActions.redirect('/catalogs/asset', true);
    }

    handleLoadPage(pageNumber) {
        // pageNumber x this.state.size = offset.
        this.setRouteState('offset', pageNumber * 20)
            .apply();
        return;
    }

    handleRemove() {
        GroupActions.remove(GroupConstants.ASSET, this.props.params.id);
        return;
    }

    handleSave() {
        let options = {
            messages: {
                error: `catalogs.asset.${this.props.route.mode}.error`,
                success: `catalogs.asset.${this.props.route.mode}.success`
            }
        };

        GroupActions.save(
            GroupConstants.ASSET,
            this.state.assetCatalog,
            options,
            this.state.groupAssets,
            this.state.originalGroupAssets
        );
        return;
    }

    handleSearchTerm(term) {
        // FIXME: review how to enable this again.
        // The main problem is when the user wants to search using
        // both the global search box and the filter options.
        this.setRouteState('asset', term)
            .setRouteState('size', 20)
            .clearRouteState('offset')
            .apply();
        return;
    }

    init(props) {
        if (props.route.mode === 'edit') {
            GroupActions.findGroupById(GroupConstants.ASSET, props.params.id);
            GroupActions.getCatalogUserGroups(props.params.id);
            GroupActions.getGroupAssets(props.params.id, this.getRouteState().get('size'), this.getRouteState().get('offset'), this.getRouteState().get('asset'));
        } else {
            GroupActions.clear();
        }
        RouterActions.registerRedirectCheck(() => {
            return this.isDirty();
        });
    }

    isDirty() {
        if (this.props.route.mode === 'create') {
            return false;
        }
        return !this.state.assetCatalog.equals(this.state.originalAssetCatalog);
    }

    handleAddExisting() {
        SlidingPanelActions.show('addExistingAssetsToCatalog');
    }

    showDeleteDialog() {
        if (this.state.originalAssetCatalog.get('isActive')) {
            this.toggleDeleteInfoModal();
        } else {
            NotificationActions.show(
                MODAL_TYPE.DANGER,
                this.context.intl.messages['catalogs.asset.edit.confirm-delete.asset'],
                this.context.intl.messages['catalogs.asset.edit.confirm-delete.body'],
                this.context.intl.messages['catalogs.asset.edit.confirm-delete.confirm'],
                this.handleRemove
            );
        }
    }

    toggleDeleteInfoModal() {
        this.setState((prevState)=>({
            showDeleteInfoModal: !prevState.showDeleteInfoModal
        }));
    }

    render() {
        let actionsMenu;
        let disabled = true;
        let mode = this.props.route.mode;
        let eventsAndNotes;
        let historyTab;
        let assetsTab;
        let userGroupsTab;

        let activePage = Math.ceil((this.props.location.query.offset || 0) / 20 ) || 0;
        let totalPages = Math.ceil(this.state.totalCount/20) || 0;

        let validations = GroupStore.getAssetsValidations();

        if (mode === 'create') {
            disabled = false;

            actionsMenu = <ActionsMenu
                canSave={!validations.length}
                onCancel={this.handleCancel}
                onSave={this.handleSave}
                saveText={this.context.intl.messages['catalogs.asset.create.button']}
            />;
        }

        let summaryRoute = '/catalogs/asset/create';
        if (mode === 'edit') {
            summaryRoute = `/catalogs/asset/${this.props.params.id}`;

            //STUDIO-10346: block users from editing event catalogs
            const isEventCatalog = this.state.assetCatalog.get('name')?.toLowerCase().startsWith('event:');
            disabled = !this.props.permissions.canEdit || isEventCatalog;
            if (isEventCatalog) {
                validations = [{
                    message: this.context.intl.messages['catalogs.event-catalogs.warning'],
                    severity: 2,
                    type: 'custom',
                    errorType: ERRORTYPE.WARNING,
                    icon: 'fas fa-flag',
                }];
            }

            actionsMenu = <ActionsMenu
                canDelete={!disabled}
                canEdit={!disabled}
                canSave={!validations.length && !disabled}
                hideSaveAndSync={!this.props.permissions.canEdit}
                onCancel={this.handleCancel}
                onDelete={this.showDeleteDialog}
                onSave={this.handleSave}
            />;

            eventsAndNotes = (
                <SideNotes
                    basePath={`/catalogs/asset/${this.props.params.id}`}
                    dialogMessage={this.context.intl.messages['common.side-notes.add-note.body']}
                    disabled={ !this.props.permissions.canEdit }
                    notes={this.state.history.toJS().slice(0, 4)}
                    onAddNote={this.addNote}
                    title={this.context.intl.messages['common.side-notes.title']}
                />
            );

            historyTab = <Tab route={`/catalogs/asset/${this.props.params.id}/history`} title={this.context.intl.messages['common.tab-title.history']} tabClassName="pull-right">
                <History
                    actionObject={ActionHistoryConstants.ACTION_OBJECTS.GROUP}
                    id={this.props.params.id}
                    pagination
                    pathname={this.props.location.pathname}
                    query={this.props.location.query}/>
            </Tab>;

            assetsTab = <Tab route={`/catalogs/asset/${this.props.params.id}/assets`} title={this.context.intl.messages['common.tab-title.assets']}>
                <h3>
                    <i className="fas fa-info-circle"></i>&nbsp;{this.context.intl.messages['common.tab-title.assets']}
                    <span className="pull-right">
                        <Button
                            bsStyle="primary"
                            className="bg-wb-blue"
                            onClick={this.handleAddExisting}
                            disabled={disabled}
                        >
                            <i className="fas fa-plus-square"></i>&nbsp;{this.context.intl.messages['common.add-existing']}
                        </Button>
                    </span>
                </h3>
                <hr/>
                <div className="col-md-6 col-md-offset-3">
                    <SearchBox onChange={this.handleSearchTerm} value={this.getRouteState().get('asset')}/>
                </div>
                <ListAssets
                    assets={this.state.groupAssets}
                    displayAudioLinks={this.props.permissions.canReadAudioAssets}
                    displayDocumentLinks={this.props.permissions.canReadDocumentAssets}
                    displayImageLinks={this.props.permissions.canReadImageAssets}
                    displayMerchandiseLinks={this.props.permissions.canReadMerchandiseAssets}
                    displayVideoLinks={this.props.permissions.canReadVideoAssets}
                    complete={this.state.complete}
                    activePage={activePage}
                    displayLinks={this.props.permissions.canReadTitles}
                    sortDirection={this.getRouteState().get('sort-direction') || 'desc'}
                    sortFieldName={this.getRouteState().get('sort-field-name') || 'updatedDate'}
                    onPageChange={this.handleLoadPage}
                    totalPages={totalPages}
                />
                <AddExistingPanel catalog={this.state.assetCatalog} />
            </Tab>;
            userGroupsTab = <Tab route={`/catalogs/asset/${this.props.params.id}/user-groups`} title={this.context.intl.messages['common.tab-title.user-groups']}>
                <h3><i className="far fa-users"></i>&nbsp;{this.context.intl.messages['common.tab-title.user-groups']}</h3>
                <hr/>
                <UserGroups
                    displayLinks={this.props.permissions.canReadGroups}
                    userGroups={this.state.userGroups}
                />
            </Tab>;
        }

        let summaryTab = <Tab route={summaryRoute} title={this.context.intl.messages['common.tab-title.summary']}>
            <Summary
                disabled={disabled}
                assetCatalog={this.state.assetCatalog}
                mode={this.props.route.mode}
            />
        </Tab>;


        let entityName;

        if (this.state.assetCatalog.get('name')) {
            entityName = this.state.assetCatalog.get('name');
        }

        return (
            <DocumentTitle
                message={`document-titles.${mode}-catalogs-assets`} entityName={entityName}
            >
                <div>
                    <StickyHeader>
                        <div className="col-md-6">
                            <h1>
                                <i className="fas fa-book"></i>&nbsp;{this.context.intl.messages[`catalogs.asset.${mode}.title`]}
                                &nbsp;
                            </h1>
                        </div>
                        {actionsMenu}
                    </StickyHeader>
                    <Preloader show={this.state.showPreloader} fixed loadingDots>
                        <section className="content" id="contentContainer">
                            <div className="row">
                                <div className="col-lg-9">
                                    <Tabs location={this.props.location}>
                                        {summaryTab}
                                        {assetsTab}
                                        {userGroupsTab}
                                        {historyTab}
                                    </Tabs>
                                </div>
                                <div className="col-lg-3">
                                    <ErrorsWarnings
                                        title={this.context.intl.messages['common.errors-warnings-title']}
                                        validations={validations}
                                    />
                                    {eventsAndNotes}
                                </div>
                            </div>
                        </section>
                    </Preloader>
                    <DeleteInfoModal show={this.state.showDeleteInfoModal} onHide={this.toggleDeleteInfoModal} />
                </div>
            </DocumentTitle>
        );
    }

}

export default WithStoreOnRoute(Container.create(Create));
