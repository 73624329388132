/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
*/

import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link} from 'react-router';
import ReactTable from 'react-table';

import {SortDates} from '../../common/utils/utils';

import 'react-table/react-table.css';

const sortStatus = (statusA, statusB) => {
    let sortResult;
    if (statusA === statusB) {
        sortResult = 0;
    } else {
        sortResult = -1;
        if (statusA > statusB) {
            sortResult = 1;
        }
    }
    return sortResult;
};

export default class Table extends Component {
    static get propTypes() {
        return {
            brands: PropTypes.instanceOf(Immutable.List).isRequired,
            displayLinks: PropTypes.bool,
            onSortChange: PropTypes.func.isRequired,
            sortDirection: PropTypes.string.isRequired,
            sortFieldName: PropTypes.string.isRequired,
        };
    }

    static get defaultProps() {
        return {
            displayLinks: false
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
            router: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            id: Math.ceil(Math.random() * 100000)
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.handleHeaderClick = this.handleHeaderClick.bind(this);
        this.gettHeaderNameClass = this.gettHeaderNameClass.bind(this);
    }

    gettHeaderNameClass(headerName) {
        let headerClassName = 'sorting';

        if (headerName === this.props.sortFieldName) {
            headerClassName = `sorting-${this.props.sortDirection}`;
        }

        return headerClassName;
    }

    handleHeaderClick(headerName) {
        if (!headerName) { // if column does not have sorting
            return;
        }

        let newSortDirection = 'asc';
        if (this.props.sortFieldName === headerName && this.props.sortDirection === 'asc') {
            newSortDirection = 'desc';
        }
        if (this.props.onSortChange) {
            this.props.onSortChange(headerName, newSortDirection);
        }
        return;
    }

    render() {
        const getHeader = this.gettHeaderNameClass;

        let columns = [{
            accessor: 'NAME',
            Header: props => (
                <strong className={getHeader(props.column.id)}>{this.context.intl.messages['brands.browse.table.name']}</strong>
            ),
            Cell: c => {
                let brandName = c.original.get('name');
                if (this.props.displayLinks) {
                    brandName = <Link to={`/brands/${c.original.get('id')}`}>{brandName}</Link>;
                }
                return (
                    <div>
                        {brandName}
                    </div>
                );
            }
        }, {
            id: 'STATUS',
            accessor: c => c.get('active'),
            Header: props => (
                <strong className={getHeader(props.column.id)}>{this.context.intl.messages['brands.browse.table.status']}</strong>
            ),
            Cell: c => {
                const active = c.original.get('active');
                let status;
                if (active) {
                    status = (
                        <span className="label bg-wb-blue"><i className="fas fa-check"></i>
                            <span className="hidden-xs hidden-sm">
                                {this.context.intl.messages['brands.browse.table.active']}
                            </span>
                        </span>
                    );
                } else {
                    status = (
                        <span className="label bg-white">
                            <span className="hidden-xs hidden-sm">
                                {this.context.intl.messages['brands.browse.table.inactive']}
                            </span>
                        </span>
                    );
                }

                return (
                    <div>
                        {status}
                    </div>
                );
            },
            sortMethod: (a, b) => sortStatus(a, b)
        }, {
            id: 'CREATED_DATE',
            accessor: c => c.get('createdDate'),
            Header: props => (
                <strong className={getHeader(props.column.id)}>{this.context.intl.messages['brands.browse.table.created-date']}</strong>
            ),
            Cell: c => {
                const createdDate = Moment(c.original.get('createdDate'));
                let createdDateFormated = '-';
                if (createdDate.isValid()) {
                    createdDateFormated = createdDate.format(this.context.intl.messages['date-format']);
                }
                return (
                    <div>
                        {createdDateFormated}
                    </div>
                );
            },
            sortMethod: (a, b) => SortDates(a, b)
        }, {
            id: 'UPDATED_DATE',
            accessor: c => c.get('updatedDate'),
            Header: props => (
                <strong className={getHeader(props.column.id)}>{this.context.intl.messages['brands.browse.table.updated-date']}</strong>
            ),
            Cell: c => {
                const updatedDate = Moment(c.original.get('updatedDate'));
                let updatedDateFormated = '-';
                if (updatedDate.isValid()) {
                    updatedDateFormated = updatedDate.format(this.context.intl.messages['date-format']);
                }
                return (
                    <div>
                        {updatedDateFormated}
                    </div>
                );
            },
            sortMethod: (a, b) => SortDates(a, b)
        }];

        const handleSortColumn = this.handleHeaderClick;

        return (
            <ReactTable
                className="-striped table-bordered table-striped responsive brands-table"
                columns={columns}
                data={this.props.brands}
                getNoDataProps= {() => {
                    if (this.props.brands.size) {
                        return {style: {display: 'none'}};
                    }
                    return {};
                }}
                id={`brands-table-${this.state.id}`}
                loading={false}
                showPagination={false}
                sortable={true}
                resizable={false}
                getTheadThProps={(state, rowInfo, column, instance) => {
                    return {
                        onClick: () => {
                            if (column.sortable !== false) {
                                instance.sortColumn(column);
                            }
                            handleSortColumn(column.id);
                        }
                    };
                }}
                pageSize={this.props.brands.size}
            />
        );
    }
}
