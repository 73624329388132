/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
*/

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import ReactTable from 'react-table';

import CompanyStore from '../../../lookup/company/company-store';
import LanguageStore from '../../../lookup/language/language-store';
import {MapToValues} from '../../../lookup/lookup-select-helpers';
import TerritoryStore from '../../../lookup/territory/territory-store';
import {TitleActions, TitleConstants} from '../../title-actions';
import TitleStore from '../../title-store';

import 'react-table/react-table.css';

export default class ReleasesTable extends Component {
    static get propTypes() {
        return {
            category: PropTypes.instanceOf(Immutable.Map).isRequired,
            releases: PropTypes.instanceOf(Immutable.List).isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
        };
    }

    render() {
        let columns = [{
            Header: <strong>{this.context.intl.messages['titles.create.release.order']}</strong>,
            width: 60,
            Cell: (c) => <div>{c.index + 1}</div>
        }, {
            Header: <strong>{this.context.intl.messages['titles.create.release.release-type']}</strong>,
            Cell: c => {
                const releaseType = TitleStore.getReleaseType(c.original.get('type'));
                return <div>{releaseType?.get('name')}</div>;
            }
        }, {
            Header: <strong>{this.context.intl.messages['titles.create.release.date-status']}</strong>,
            Cell: c => {
                const status = TitleStore.getDateStatusType(c.original.get('dateStatusType'));
                return <div>{status?.get('name')}</div>;
            }
        }, {
            Header: <strong>{this.context.intl.messages['titles.create.release.domestic-release-company']}</strong>,
            minWidth: 230,
            Cell: c => {
                const releaseCompany = MapToValues('id', CompanyStore)(c.original.get('domesticReleaseCompanyId'));
                return <div>{releaseCompany?.get('name')}</div>;
            }
        }, {
            Header: <strong>{this.context.intl.messages['titles.create.release.date-type']}</strong>,
            Cell: c => {
                const dayType = TitleStore.getDateType(c.original.get('dateType'));
                return <div>{dayType?.get('name')}</div>;
            }
        }, {
            Header: <strong>{this.context.intl.messages['titles.create.release.date']}</strong>,
            Cell: c => {
                const releaseDate = c.original.get('date');
                return <div>{releaseDate}</div>;
            }
        }, {
            Header: <strong>{this.context.intl.messages['titles.create.release.territory']}</strong>,
            Cell: c => {
                const territory = MapToValues('id', TerritoryStore)(c.original.get('territoryId'));
                return <div>{territory?.get('name')}</div>;
            }
        }, {
            Header: <strong>{this.context.intl.messages['titles.create.release.language']}</strong>,
            Cell: c => {
                const language = MapToValues('id', LanguageStore)(c.original.get('languageId'));
                return <div>{language?.get('name')}</div>;
            }
        }, {
            Header: <strong>{this.context.intl.messages['titles.create.release.content-type']}</strong>,
            minWidth: 200,
            Cell: c => {
                const contentType = TitleStore.getContentType(c.original.get('releaseContentType'));
                return <div>{contentType?.get('name')}</div>;
            }
        }, {
            Header: <strong>{this.context.intl.messages['titles.create.release.air-day']}</strong>,
            Cell: c => {
                const airDayType = TitleStore.getAirDayType(c.original.get('airDayType'));
                return <div>{airDayType?.get('name')}</div>;
            }
        }, {
            Header: <strong>{this.context.intl.messages['titles.create.release.air-time']}</strong>,
            Cell: c => {
                let airTimeValue = TitleStore.getAirTimeType(c.original.get('airHour'), c.original.get('airMinute'));
                if (TitleActions.getCategoryGroup(this.props.category) === TitleConstants.TITLE_CATEGORY_GROUPS.SERIES) {
                    airTimeValue = TitleStore.getAirTimeType(0, 0);
                }
                return <div>{airTimeValue?.get('name')}</div>;
            }
        }];
        return (
            <ReactTable
                className="-striped table-bordered table-striped responsive"
                columns={columns}
                data={this.props.releases}
                getNoDataProps= {() => {
                    if (this.props.releases.size) {
                        return {style: {display: 'none'}};
                    }
                    return {};
                }}
                id="title-releases-table-id"
                loading={false}
                pageSize={this.props.releases.size}
                showPagination={false}
                sortable={false}
                resizable={false}
            />
        );
    }
}
