/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, OverlayTrigger, Tooltip} from 'react-bootstrap';

import ClipInfo from './clip-info';
import PublishClipModal from './publish-clip-modal';
import TimelineAssetStore from '../../../assets/timeline/timeline-asset-store';
import {FormRow} from '../../../common/form/form';
import {Tab, Tabs} from '../../../common/routing-tab/routing-tab';
import SessionStore from '../../../user/session/session-store';
import {TimelineActions} from '../timeline-actions';
import TimelineStore from '../timeline-store';

const GetPublishMessage = (clip, context) => {
    const {name: clipName, contentType, active, deliveryType, protectionType} = clip.toJS();
    let contentTypeValue;
    if (contentType) {
        contentTypeValue = TimelineStore.getContentType(contentType).get('name');
    }
    let deliveryTypeValue;
    if (deliveryType !== undefined && deliveryType !== null) {
        deliveryTypeValue = TimelineStore.getDeliveryType(deliveryType).get('name');
    }
    let protectionTypeValue;
    if (protectionType !== undefined && protectionType !== null) {
        protectionTypeValue = TimelineAssetStore.getProtectionType(protectionType).get('name');
    }
    return (
        <div>
            <FormRow>
                <span>{context.intl.messages['hardac.timeline.clip.form.name']}</span>
                <i>{clipName || '-'}</i>
            </FormRow>
            <FormRow>
                <span>{context.intl.messages['hardac.timeline.clips.content-type']}</span>
                <i>{contentTypeValue || '-'}</i>
            </FormRow>
            <FormRow>
                <span>{context.intl.messages['hardac.timeline.summary.protection-type']}</span>
                <i>{protectionTypeValue || '-'}</i>
            </FormRow>
            <FormRow>
                <span>{context.intl.messages['hardac.timeline.summary.active']}</span>
                <i>{context.intl.messages[`common.boolean.${active}`]}</i>
            </FormRow>
            <FormRow>
                <span>{context.intl.messages['hardac.timeline.summary.delivery-type']}</span>
                <i>{deliveryTypeValue || '-'}</i>
            </FormRow>
        </div>
    );
};

class EditClipPanel extends Component {

    static get propTypes() {
        return {
            canEdit: PropTypes.bool.isRequired,
            canPublishAll: PropTypes.bool.isRequired,
            clip: PropTypes.instanceOf(Immutable.Map),
            hasUnpublishedClips: PropTypes.bool.isRequired,
            location: PropTypes.object.isRequired,
            player: PropTypes.object,
            selectedDeliveryType: PropTypes.instanceOf(Immutable.Map),
            sources: PropTypes.instanceOf(Immutable.Map).isRequired,
            timeline: PropTypes.instanceOf(Immutable.Map).isRequired,
            thumbnails: PropTypes.instanceOf(Immutable.List).isRequired,
            viewingMode: PropTypes.string.isRequired
        };
    }

    static get defaultProps() {
        return {
            clip: Immutable.fromJS({}),
            player: undefined,
            selectedDeliveryType: Immutable.fromJS({})
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static getPermissions() {
        return {
            canRead: SessionStore.canUser(SessionStore.PERMISSIONS.HARDAC.PROCESSES.READ)
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            showPublishModal: false
        };

        this.handleBackToTimeline = this.handleBackToTimeline.bind(this);
        this.handlePublishClip = this.handlePublishClip.bind(this);
        this.handleTogglePublishModal = this.handleTogglePublishModal.bind(this);
        return;
    }

    handleBackToTimeline() {
        TimelineActions.addUpdateClipToList(this.props.clip, 'unpublished');
        TimelineActions.update('showEditClipPanel', false);
        TimelineActions.clearSelectedClip();
        this.props.player?.api.plugin.apPointPlugin.actions.find(action => action.name === 'clearAllPoints').callback();
    }

    handlePublishClip() {
        TimelineActions.publishClip(this.props.clip, this.props.timeline);
    }

    handleTogglePublishModal() {
        this.setState((preveState) =>({
            showPublishModal: !preveState.showPublishModal
        }));
    }

    render() {
        if (this.props.clip.isEmpty()) {
            return null;
        }

        const clipId = this.props.clip.get('id');
        const baseRoute = `/hardac/timeline/${this.props.timeline.get('id')}/clips/${clipId}`;

        const clipInfoTab = <Tab route={baseRoute} title={this.context.intl.messages['common.tab-title.summary']}>
            <ClipInfo
                canEdit={this.props.canEdit}
                clip={this.props.clip}
                model={this.props.clip}
                player={this.props.player}
                selectedDeliveryType={this.props.selectedDeliveryType}
                sources={this.props.sources}
                thumbnails={this.props.thumbnails}
                timeline={this.props.timeline}
                viewingMode={this.props.viewingMode}
            />
        </Tab>;

        const publishMessage = GetPublishMessage(this.props.clip, this.context);

        // publish all button enabled
        let button;
        if (this.props.hasUnpublishedClips) {
            if (this.props.canPublishAll) {
                button = <Button
                    bsSize="md"
                    className="btn btn-primary-outline Mr(3px) Mb(3px)"
                    onClick={this.handleTogglePublishModal}
                >
                    <i className="fas fa-cloud-upload"></i>&nbsp;{this.context.intl.messages['hardac.timeline.clip.publish.clip']}
                </Button>;
            } else {
                const renderTooltip = <Tooltip id="button-tooltip">{this.context.intl.messages['hardac.timeline.clip.publish.disabled.tooltip']}</Tooltip>;
                button = <OverlayTrigger placement="top" overlay={renderTooltip}>
                    <Button
                        id="publish-all-disabled"
                        bsSize="md"
                        className="btn btn-default-outline Mr(3px) Mb(3px) disabled"
                        onClick={() => void 0}
                    >
                        <i className="fas fa-cloud-upload"></i>&nbsp;{this.context.intl.messages['hardac.timeline.clip.publish.disabled.text']}
                    </Button>
                </OverlayTrigger>;
            }
        }
        let publishingDisabled;
        if (!this.props.canPublishAll && this.props.hasUnpublishedClips) {
            publishingDisabled = <div className="text-danger pull-right padding-y-10 padding-bottom-10">
                <em><i className="fas fa-exclamation-triangle"></i> Publishing disabled: save Timeline before publishing.</em>
            </div>;
        }
        return (
            <div className="edit-clip-panel">
                <div className="padding-bottom-20">
                    <Button className="btn btn-default-outline Mr(3px) Mb(3px)" onClick={this.handleBackToTimeline}>
                        <i className="fas fa-arrow-left"></i>&nbsp;{this.context.intl.messages['hardac.timeline.clips.back-to-timeline']}
                    </Button>
                    <div className="pull-right">
                        {button}
                    </div>
                </div>
                {publishingDisabled}
                <Tabs location={this.props.location}>
                    {clipInfoTab}
                </Tabs>
                <PublishClipModal
                    onConfirmPublish={this.handlePublishClip}
                    onHide={this.handleTogglePublishModal}
                    show={this.state.showPublishModal}
                    title={this.context.intl.messages['hardac.timeline.clip.publish.clip']}
                    viewingMode={this.props.viewingMode}
                >
                    {publishMessage}
                </PublishClipModal>
            </div>
        );
    }
}

export default EditClipPanel;
export {
    GetPublishMessage
};
