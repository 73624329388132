/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

import FilterOptions from './filter-options';
import Table from './table';
import {AccountWizardActions} from '../account-wizard-actions';
import AccountWizardStore from '../account-wizard-store';

import DocumentTitle from '~/src/common/document-title';
import SearchBox from '~/src/common/search-box/search-box';
import {WithStoreOnRoute} from '~/src/common/store-on-route';
import {Debounce} from '~/src/common/utils/utils';
import {LayoutActions} from '~/src/layout/layout-actions';
import LayoutStore from '~/src/layout/layout-store';
import Preloader from '~/src/preloader';

class Browse extends Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
        };
    }

    static calculateState() {
        const batches = AccountWizardStore.getState();
        return {
            batches: batches.get('batches'),
            showFiltersPanel: LayoutStore.getState().get('showFiltersPanel'),
            showPreloader: batches.get('showPreloader'),
            total: batches.get('total'),
        };
    }

    static getStores() {
        return [AccountWizardStore, LayoutStore];
    }

    constructor(props) {
        super(props);

        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.handleToggleFiltersPanel = this.handleToggleFiltersPanel.bind(this);
    }

    componentDidMount() {
        // Trigger an action that will request the
        // initial data to the server.
        AccountWizardActions.clear();
        AccountWizardActions.get(this.getRouteState());

        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.location.query !== this.props.location.query ||
            nextState.showFiltersPanel !== this.state.showFiltersPanel ||
            nextState.preloaderVisible !== this.state.preloaderVisible ||
            nextState.batches !== this.state.batches ||
            nextState.total !== this.state.total) {
            return true;
        }

        return false;
    }

    componentWillUpdate(nextProps) {
        if (this.props.location !== nextProps.location) {
            AccountWizardActions.get(this.getRouteState(nextProps));
        }
        return;
    }

    /**
     * Handle clicks on pagination controls.
     * @param  {Number} pageNumber page to load.
     */
    handleLoadPage(pageNumber) {
        // pageNumber x this.state.size = offset.
        this.setRouteState('offset', pageNumber * 20)
            .apply();
        return;
    }

    handleSearchTerm(term) {
        // FIXME: review how to enable this again.
        // The main problem is when the user wants to search using
        // both the global search box and the filter options.

        this.setRouteState('search-name', term)
            .setRouteState('operator', 'AND')
            .clearRouteState('offset')
            .apply();
        return;
    }

    handleSortChange(sortFieldName, sortDirection) {
        this.setRouteState('sort-field-name', sortFieldName)
            .setRouteState('sort-direction', sortDirection)
            .apply();
        return;
    }

    handleToggleFiltersPanel() {
        LayoutActions.toggleFiltersPanel();
        return;
    }

    render() {
        let batchCaption = this.context.intl.messages['accounts.account-wizard.browse.batches'];
        if (this.state.total === 1) {
            batchCaption = this.context.intl.messages['accounts.account-wizard.browse.batch'];
        }

        return (
            <DocumentTitle
                message="document-titles.accounts.account-wizard.browse"
            >
                <div className={ClassNames({'control-sidebar-open': this.state.showFiltersPanel})}>
                    <div className={ClassNames({'sidebar-opened': this.state.showFiltersPanel})}>
                        <Preloader show={this.state.showPreloader} fixed loadingDots>
                            <section className="content-header">
                                <h1>
                                    <i className="fas fa-users-medical"></i>&nbsp;{this.context.intl.messages['accounts.account-wizard.browse.title']}
                                    &nbsp;<small>{this.state.total}&nbsp;{batchCaption}</small>
                                </h1>
                            </section>
                            <section className="content">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="container-fluid no-x-padding">
                                            <div className="row">
                                                <div className="col-md-6 show-grid">
                                                    <SearchBox onChange={this.handleSearchTerm} value={this.getRouteState().get('q')}/>
                                                </div>
                                                <div className="col-md-6 show-grid">
                                                    <Button onClick={this.handleToggleFiltersPanel} bsStyle="primary" bsSize="large" className="pull-right">
                                                        <i className="fas fa-sliders-h"></i>&nbsp;{this.context.intl.messages['common.filteringOptions']}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container-fluid no-x-padding">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="box">
                                                        <div className="box-body">
                                                            <Table
                                                                activePage={Math.ceil((this.getRouteState().get('offset') || 0) / 20 ) || 0}
                                                                batches={this.state.batches}
                                                                displayLinks={true}
                                                                onPageChange={this.handleLoadPage}
                                                                onSortChange={this.handleSortChange}
                                                                sortDirection={this.getRouteState().get('sort-direction')}
                                                                sortFieldName={this.getRouteState().get('sort-field-name')}
                                                                totalPages={Math.ceil(this.state.total/20) || 0}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <FilterOptions
                                location={this.props.location}
                            />
                        </Preloader>
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}

export default WithStoreOnRoute(
    Container.create(Browse)
);
