/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

import AddExistingPanel from './add-existing-panel';
import AddNewPanel from './add-new-panel';
import AssetDetail from './asset-detail';
import ItemsTable from './table';
import ImageStore from '../../../assets/image/image-store';
import VideoStore from '../../../assets/video/video-store';
import {SlidingPanelActions} from '../../../common/sliding-panel/sliding-panel-actions';
import {BatchConstants} from '../../batch-actions';

class ListItems extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            disabled: PropTypes.bool,
            item: PropTypes.instanceOf(Immutable.Map).isRequired,
            items: PropTypes.instanceOf(Immutable.List).isRequired,
            batch: PropTypes.instanceOf(Immutable.Map).isRequired,
            showItemDetail: PropTypes.bool.isRequired,
            type: PropTypes.string.isRequired,
        };
    }

    static get defaultProps() {
        return {
            disabled: true
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.handleAddExisting = this.handleAddExisting.bind(this);
    }

    handleAddExisting() {
        SlidingPanelActions.show('addExistingItemToBatch');
    }

    handleAddNew() {
        SlidingPanelActions.show('addNew');
    }

    render() {
        let titleIcon;
        let itemsGroups;
        switch (this.props.type) {
        case BatchConstants.BATCH_TYPES.DOCUMENT:
            titleIcon = 'far fa-file-alt';
            itemsGroups = {'': this.props.items.toJS()};
            break;
        case BatchConstants.BATCH_TYPES.IMAGE:
            titleIcon = 'fa fa-camera';
            itemsGroups = ImageStore.groupAssetsByType(this.props.items);
            break;
        case BatchConstants.BATCH_TYPES.VIDEO:
            titleIcon = 'fas fa-video';
            itemsGroups = VideoStore.groupAssetsByType(this.props.items);
            break;
        case BatchConstants.BATCH_TYPES.TITLE:
            titleIcon = 'far fa-newspaper';
            itemsGroups = {'': this.props.items.toJS()};
            break;
        }

        const existingPanelTitle = (
            <span>{this.context.intl.messages[`scheduling.batch.items-list.adding-existing.${this.props.type.toLowerCase()}`]}&nbsp;<small>{this.props.batch.get('batchName')}</small></span>
        );

        let addNewButton;
        if (this.props.type !== BatchConstants.BATCH_TYPES.TITLE) {
            addNewButton = (
                <Button
                    bsStyle="primary"
                    className="bg-wb-blue"
                    disabled={this.props.disabled}
                    onClick={this.handleAddNew}>
                    <i className="far fa-plus-square"/>&nbsp;{this.context.intl.messages['titles.create.assets.add-new']}
                </Button>
            );
        }

        return (
            <div>
                <h3>
                    <i className={titleIcon}></i>&nbsp;{this.context.intl.messages[`scheduling.batch.items-list.${this.props.type.toLowerCase()}s-title`]}&nbsp;<small>{this.props.items.size}</small>
                    <span className="pull-right">
                        {addNewButton}
                        &nbsp;
                        <Button
                            bsStyle="primary"
                            className="bg-wb-blue"
                            onClick={this.handleAddExisting}
                            disabled={this.props.disabled}>
                            <i className="fas fa-plus-square"></i>&nbsp;{this.context.intl.messages['common.add-existing']}
                        </Button>
                    </span>
                </h3>
                <hr/>
                <section>
                    {Object.keys(itemsGroups).map(key => (
                        <div>
                            <h4>{key}</h4>
                            <ItemsTable
                                disabled={this.props.disabled}
                                items={Immutable.fromJS(itemsGroups[key])}
                                type={this.props.type}
                                showItemDetail={this.props.showItemDetail}
                            />
                        </div>
                    ))}
                </section>
                <AddExistingPanel
                    location={this.props.location}
                    type={this.props.type}
                    title={existingPanelTitle}
                />
                <AddNewPanel
                    type={this.props.type}
                    batchName={this.props.batch.get('batchName')}
                />
                <AssetDetail
                    asset={this.props.item}
                    disabled={this.props.disabled}
                    type={this.props.type}
                    show={this.props.showItemDetail}
                />
            </div>
        );
    }
}

export default ListItems;
