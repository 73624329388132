/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';

import {NotificationActions} from '../common/notification/notification-actions';
import Dispatcher from '../dispatcher/dispatcher';
import {PreloaderActions} from '../preloader/preloader-actions';
import Request from '../request';
import {RouterActions} from '../router/router-actions';

const CONSTANTS = {
    GET: {
        ERROR: 'help.get.error',
        START: 'help.get.start',
        SUCCESS: 'help.get.success'
    },
    SHOW_ITEM: 'help.show.video'
};

class HelpActions {
    get() {
        PreloaderActions.show('help-actions.get');
        Dispatcher.dispatch({
            actionType: CONSTANTS.GET.START
        });

        // Fetch publish lists by name to get ids
        Request.get('web/publish-list').query({name: 'Brainiac Help Page', offset: 0}).exec().then(plRes => {
            let list = plRes.body.results[0];
            // No list means user doesn't have permissions to see this page.
            if (!list) {
                RouterActions.forbidden();
                return;
            }
            return [
                list,
                Request.get(`web/publish-list/${list.id}/my-items`).exec()
            ];
        }).spread((list, itemsRes) => {
            list.items = itemsRes.body.sort((a, b) => a.displayOrder - b.displayOrder);

            if (!list.items.length) {
                NotificationActions.showAlertWarning('help.errors.no-access');
            }

            // Add the items to the publishing list.
            return [
                list,
                Promise.all(
                    list.items.map(
                        item => Request.get(`asset/video/${item.assetId}`).exec().catch(err => {
                            // Catch errors if asset is not found.
                            NotificationActions.showAlertWarning('help.errors.video-not-found', item.assetId);
                            console.error(err);
                            return {body: {}};
                        })
                    )
                ),
                Promise.all(
                    list.items.map(
                        item => Request.get('asset/video/thumbnailURL').query({'video-id': item.assetId}).exec().catch(err => {
                            // Catch errors if no thumbnail.
                            console.error(err);
                            return {
                                body: [{
                                    thumbnailList: []
                                }]
                            };
                        })
                    )
                )
            ];
        }).spread((list, itemsDetailRes, itemsThumbnailsRes) => {
            list.items = list.items.map((item, index) => {
                item.video = itemsDetailRes[index].body;
                item.video.thumbnails = itemsThumbnailsRes[index].body[0]?.thumbnailList;
                return item;
            });

            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.SUCCESS,
                helpPublishingList: Immutable.fromJS(list)
            });

            PreloaderActions.hide('help-actions.get');
            return;
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.ERROR
            });

            NotificationActions.showAlertDanger('help.errors.loading');
            PreloaderActions.hide('help-actions.get');
            throw err;
        });
        return;
    }

    showItem(item) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SHOW_ITEM,
            item: item
        });
        RouterActions.redirect('/help/video-detail/', true);
    }
}

let actions = new HelpActions();

export {
    actions as HelpActions,
    CONSTANTS as HelpConstants,
};
