/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import jQuery from 'jquery';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, Modal} from 'react-bootstrap';

import GroupStore from '../../../security/group/group-store';
import {TitleActions, TitleConstants} from '../../title-actions';

require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('../../../styles/data-tables-brainiac.css');
require('datatables.net-responsive-bs');

class AssignedTable extends Component {
    static get propTypes() {
        return {
            assignedCatalogs: PropTypes.instanceOf(Immutable.List).isRequired,
            serieNavigation: PropTypes.instanceOf(Immutable.Map).isRequired,
            title: PropTypes.instanceOf(Immutable.Map).isRequired,
            name: PropTypes.string
        };
    }

    static get defaultProps() {
        return {
            name: 'catalogs'
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
        };
    }

    static calculateState() {
        return {
            catalog: Immutable.Map(),
            episode: false,
            season: false,
            serie: false,
            showRemoveCatalogModal: false
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.checkboxFrom = this.checkboxFrom.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleRemoveCatalog = this.handleRemoveCatalog.bind(this);
        this.handleRemoveSingleRelease = this.handleRemoveSingleRelease.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.openModal = this.openModal.bind(this);
    }

    componentDidMount() {
        this.$table = jQuery('#assigned-catalogs-table');
        this.$tableAPI = this.$table.DataTable({
            autoWidth: false,
            columnDefs: [{
                className: 'control',
                targets:   -1,
                width: 20
            }, {
                targets: 'no-sort',
                orderable: false
            }, {
                width: '122',
                targets: 1
            }, {
                width: '56',
                targets: 2
            }],
            order: [[1, 'dec']],
            iDisplayLength: 100,
            info: false,
            ordering: false,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        });

        window.addEventListener('resize', this.handleResize);

        this.handleResize();
        return;
    }

    componentWillUpdate(nextProps /*, nextState*/) {
        this.$tableAPI.clear();

        nextProps.assignedCatalogs.forEach((catalog, i) => {
            let row = [];

            const catalogDescription = catalog.get('description') || '';
            let catalogStatus = 'Inactive';
            if (catalog.get('isActive') === 1) {
                catalogStatus = 'Active';
            }
            let catalogType = '';
            if (catalog.get('titleCatalogType') !== null) {
                catalogType = GroupStore.getCatalogType(catalog.get('titleCatalogType')).get('name');
            }

            const nameAndDescription = `<div>${catalog.get('name')}<small class="d-block" style="font-size: 80%">${this.context.intl.messages['common.description']}: ${catalogDescription}</small></div>`;

            row.push(
                nameAndDescription,
                catalogType,
                catalogStatus,
                `<button class="btn btn-small bg-navy remove-catalog" data-index="${i}" data-catalog-id="${catalog.get('id')}"><i class="fas fa-times"></i>&nbsp;Remove</button>`,
                ''
            );

            this.$tableAPI.row.add(row);
            return;
        });

        this.$tableAPI.draw(false);
        this.handleResize();
        return;
    }

    componentWillUnmount() {
        if (this.$tableAPI) {
            this.$tableAPI.destroy();
        }
        return;
    }

    checkboxFrom(title, titleType, disabled, checked) {
        return (<div key={titleType}>
            <label>
                <input type="checkbox" checked={checked || this.state[titleType]} onChange={this.handleChange.bind(this, titleType)} disabled={disabled}/>
                <strong>&nbsp;{title}</strong>
            </label>
        </div>);
    }

    closeModal() {
        this.setState({
            showRemoveCatalogModal: false,
            episode: false,
            'mini-serie-episode': false,
            season: false,
            serie: false
        });
    }

    handleChange(titleType) {
        this.setState(state => {
            let newState = {};

            newState[titleType] = !state[titleType];

            switch (titleType) {
            case 'season':
                newState.episode = false;
                break;
            case 'serie':
                newState.season = !state[titleType];
                break;
            case 'mini-serie-episode':
                newState.serie = true;
                newState.episode = true;
                break;
            }

            return newState;
        });
    }

    handleRemoveCatalog(event) {
        switch (true) {
        case !!~event.target.className.indexOf('remove-catalog'):
            TitleActions.removeById(this.props.name, parseInt(event.target.getAttribute('data-catalog-id'), 10), 'id');
            break;
        case !!~event.target.className.indexOf('fa-times'):
            TitleActions.removeById(this.props.name, parseInt(event.target.parentNode.getAttribute('data-catalog-id'), 10), 'id');
            break;
        }

        TitleActions.removeCatalog(this.state.catalog, this.props.name, this.state.serie, this.state.season, this.state.episode);

        this.closeModal();
        return;
    }

    handleRemoveSingleRelease(event) {
        let domNode;
        switch (true) {
        case !!~event.target.className.indexOf('remove-catalog'):
            domNode = event.target;
            break;
        case !!~event.target.className.indexOf('fa-times'):
            domNode = event.target.parentNode;
            break;
        }

        // Needed to avoid running the code when users click on a different element.
        if (!domNode) {
            return;
        }

        const id = parseInt(domNode.getAttribute('data-catalog-id'), 10);
        const catalog = this.props.assignedCatalogs.find(c => c.get('id') === id);

        TitleActions.removeById(this.props.name, parseInt(domNode.getAttribute('data-catalog-id'), 10), 'id');
        TitleActions.removeCatalog(catalog, this.props.name);

        this.closeModal();

        return;
    }

    handleResize() {
        this.$tableAPI.responsive.recalc();
        return;
    }

    openModal(event) {
        let domNode;
        switch (true) {
        case !!~event.target.className.indexOf('remove-catalog'):
            domNode = event.target;
            break;
        case !!~event.target.className.indexOf('fa-times'):
            domNode = event.target.parentNode;
            break;
        }

        if (!domNode) {
            return;
        }

        const id = parseInt(domNode.getAttribute('data-catalog-id'), 10);
        const catalog = this.props.assignedCatalogs.find(c => c.get('id') === id);

        this.setState(() => ({
            catalog: catalog,
            showRemoveCatalogModal: true
        }));
        return;
    }

    render() {
        let handleTableClick;
        let checkboxes = [];
        let checkboxesContainer;
        let episodeNumber;
        let seasonNumber;
        let sn;
        if (this.props.title.get('id')) {
            sn = this.props.serieNavigation.getIn(['serieMap', this.props.title.get('id').toString()]);
        }
        let titleName = this.props.title.get('displayName');

        switch (this.props.title.get('categoryGroup')) {
        case TitleConstants.TITLE_CATEGORY_GROUPS.EPISODE:
            if (this.props.serieNavigation.get('seasons') && this.props.serieNavigation.get('seasons').size) {
                seasonNumber = 'X';
                episodeNumber = 'X';
                if (sn) {
                    seasonNumber = this.props.serieNavigation.getIn(['seasons', sn.get(0), 'seasonNumber']);
                    episodeNumber = this.props.serieNavigation.getIn(['seasons', sn.get(0), 'episodes', sn.get(1), 'episodeNumber']);
                }
                titleName = `${this.props.title.get('name')} (${this.props.serieNavigation.get('titleName')} - S${seasonNumber}E${episodeNumber})`;
                checkboxes = [this.checkboxFrom(titleName, 'episode', true, true),
                    this.checkboxFrom(`${this.props.serieNavigation.get('titleName')} - Series`, 'serie', false),
                    this.checkboxFrom(`${this.props.serieNavigation.get('titleName')} - Season ${seasonNumber}`, 'season', this.state.serie, this.state.serie)];
                handleTableClick = this.openModal;
            } else if (this.props.serieNavigation.get('episodes')) {
                miniSerieEpisodeCheckbox = this.checkboxFrom(`All Episodes of ${this.props.title.get('parentTitleDisplayName')}`, 'mini-serie-episode', false);
                checkboxes = [this.checkboxFrom(`${this.props.title.get('name')}`, 'serie', true, true), miniSerieEpisodeCheckbox];
                handleTableClick = this.openModal;
            } else {
                handleTableClick = this.handleRemoveSingleRelease;
            }
            break;
        case TitleConstants.TITLE_CATEGORY_GROUPS.MINI_SERIES:
            let miniSerieEpisodeCheckbox = '';
            if (this.props.serieNavigation.get('episodes')) {
                miniSerieEpisodeCheckbox = this.checkboxFrom(`All Episodes of ${this.props.title.get('name')}`, 'mini-serie-episode', false);
            }
            checkboxes = [this.checkboxFrom(`${this.props.title.get('name')}`, 'serie', true, true), miniSerieEpisodeCheckbox];
            handleTableClick = this.openModal;
            break;
        case TitleConstants.TITLE_CATEGORY_GROUPS.SEASON:
            seasonNumber = 'X';
            if (sn) {
                seasonNumber = this.props.serieNavigation.getIn(['seasons', sn.get(0), 'seasonNumber']);
            }
            checkboxes = [this.checkboxFrom(`${this.props.title.get('name')} - Season ${seasonNumber}`, 'season', true, true),
                this.checkboxFrom(`${this.props.title.get('name')} - Series`, 'serie', false),
                this.checkboxFrom(`All Episodes of ${this.props.title.get('name')} Season ${seasonNumber}`, 'episode', false)];
            handleTableClick = this.openModal;
            break;
        case TitleConstants.TITLE_CATEGORY_GROUPS.SERIES:
            checkboxes = [this.checkboxFrom(`${this.props.title.get('name')}`, 'serie', true, true),
                this.checkboxFrom(`All Seasons of ${this.props.title.get('name')}`, 'season', false),
                this.checkboxFrom(`All Episodes of ${this.props.title.get('name')}`, 'episode', !this.state.season)];
            handleTableClick = this.openModal;
            break;
        case TitleConstants.TITLE_CATEGORY_GROUPS.SINGLE_RELEASE:
            handleTableClick = this.handleRemoveSingleRelease;
            break;
        case TitleConstants.TITLE_CATEGORY_GROUPS.FORMAT_RIGHTS:
            handleTableClick = this.handleRemoveSingleRelease;
            break;
        }

        if (checkboxes.length > 0) {
            checkboxesContainer = <div className="margin-bottom-20">
                {checkboxes}
            </div>;
        }

        return (
            <div>
                <Modal show={this.state.showRemoveCatalogModal} onHide={this.closeModal}>
                    <Modal.Header className="bg-gray" closeButton>
                        <Modal.Title className="text-center">{this.context.intl.messages['titles.edit.catalogs-tab.remove-catalogs']}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <p>{this.context.intl.messages['titles.edit.catalogs-tab.remove-catalogs.suggest-line.one']} &quot;<b>{this.state.catalog.get('name')}</b>&quot;. {this.context.intl.messages['titles.edit.catalogs-tab.remove-catalogs.suggest-line.two']}</p>
                        </div>
                        {checkboxesContainer}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            bsStyle="primary" bsSize="large" className="pull-right"
                            onClick={this.handleRemoveCatalog}>{this.context.intl.messages['titles.edit.catalogs-tab.remove-catalogs.confirm']}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <table id="assigned-catalogs-table" className="table table-bordered table-striped responsive">
                    <thead>
                        <tr>
                            <th className="sorting_desc">{this.context.intl.messages['common.name-and-description']}</th>
                            <th>{this.context.intl.messages['titles.edit.catalogs-tab.assigned.catalog-type']}</th>
                            <th>{this.context.intl.messages['titles.edit.catalogs-tab.assigned.status']}</th>
                            <th>{this.context.intl.messages['titles.edit.catalogs-tab.assigned.actions']}</th>
                            <th className="no-sort"></th>
                        </tr>
                    </thead>
                    <tbody onClick={handleTableClick}>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default AssignedTable;
