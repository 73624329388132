/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ControlLabel, FormControl, FormGroup} from 'react-bootstrap';

import {FormItem, FormRow, FormSection} from '../../../common/form/form';
import {CompareImmutable} from '../../../common/utils/utils';
import config from '../../../config/config.js';
import BrandSelect from '../../../lookup/brand-select/brand-select';
import BrandStore from '../../../lookup/brand-select/brand-select-store';
import {GroupActions, GroupConstants} from '../../../security/group/group-actions';
import GroupStore, {UserGroupValidations} from '../../../security/group/group-store';

import BaseSelect from '~/src/common/base-select/base-select';

let userGroupProps = [
    'brandId', 'name', 'isActive', 'description',
    'updatedDate', 'updatedBy', 'groupClassificationType'
];

class Info extends Component {
    static get propTypes() {
        return {
            mode: PropTypes.string.isRequired,
            onBrandChange: PropTypes.func.isRequired,
            onClassificationChange: PropTypes.func.isRequired,
            permissions: PropTypes.object,
            userGroup: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            permissions: {}
        };
    }

    shouldComponentUpdate(nextProps) {
        if (!CompareImmutable(nextProps.userGroup, this.props.userGroup, userGroupProps)) {
            return true;
        }
        return false;
    }

    render() {
        const selectdBrand = BrandStore.findItemByKey('id', this.props.userGroup.get('brandId'));
        const selectedClassification = GroupStore.getClassification(this.props.userGroup.get('groupClassificationType'));
        let updatedDate = <div>N/A</div>;
        let lastModifier = <div>N/A</div>;
        let additionalInformation;

        if (this.props.mode === 'edit') {

            if (this.props.userGroup.get('updatedDate')) {
                updatedDate = Moment(this.props.userGroup.get('updatedDate')).tz(config.DefaultTimezone).format(this.context.intl.messages['datetime-format']);
            } else {
                updatedDate = '';
            }

            lastModifier = this.props.userGroup.get('updatedBy');

            additionalInformation = <FormRow>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['groups.user.edit.last-modified-date']}</ControlLabel>
                    <FormControl.Static>{updatedDate}</FormControl.Static>
                </FormGroup>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['groups.user.edit.last-modifier']}</ControlLabel>
                    <FormControl.Static>{lastModifier}</FormControl.Static>
                </FormGroup>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['groups.user.create.info.status']}</ControlLabel>
                    <FormItem attr="isActive"
                        disabled={!this.props.permissions.canEdit}
                        label={this.context.intl.messages['common.active']}
                        model={this.props.userGroup}
                        md={2}
                        setter={GroupActions.updateGroup.bind(this, GroupConstants.USER)}
                        type="checkbox"
                    />
                </FormGroup>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['groups.user.create.info.brainiac-group']}</ControlLabel>
                    <FormItem attr="isAdmin"
                        disabled={!this.props.permissions.canEdit}
                        label={this.context.intl.messages['groups.user.create.info.brainiac-group']}
                        model={this.props.userGroup}
                        md={2}
                        setter={GroupActions.updateGroup.bind(this, GroupConstants.USER)}
                        type="checkbox"
                    />
                </FormGroup>
            </FormRow>;
        }

        return (
            <FormSection title={this.context.intl.messages['groups.user.create.info.title']} iconClass="fas fa-info-circle">
                <FormRow>
                    <FormItem attr="name"
                        disabled={!this.props.permissions.canEdit}
                        label={this.context.intl.messages['groups.user.create.info.name']}
                        model={this.props.userGroup}
                        placeholder={this.context.intl.messages['groups.user.create.info.name.placeholder']}
                        setter={GroupActions.updateGroup.bind(this, GroupConstants.USER)}
                        type="text"
                        validations={UserGroupValidations.name.validations}
                    />
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['groups.user.create.brand']}</ControlLabel>
                        <BrandSelect
                            disabled={!this.props.permissions.canEdit}
                            onChange={this.props.onBrandChange}
                            value={selectdBrand}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['groups.user.create.info.classification']}</ControlLabel>
                        <BaseSelect
                            disabled={!this.props.permissions.canEdit}
                            name={this.context.intl.messages['groups.user.create.info.classification']}
                            onChange={this.props.onClassificationChange}
                            options="classificationTypes"
                            store={GroupStore}
                            value={selectedClassification}
                        />
                    </FormGroup>
                </FormRow>
                <FormRow>
                    <FormItem attr="description"
                        disabled={!this.props.permissions.canEdit}
                        label={this.context.intl.messages['groups.user.create.info.description']}
                        model={this.props.userGroup}
                        placeholder={this.context.intl.messages['groups.user.create.info.description.placeholder']}
                        setter={GroupActions.updateGroup.bind(this, GroupConstants.USER)}
                        type="textarea"
                        validations={UserGroupValidations.description.validations}
                    />
                </FormRow>
                <hr />
                {additionalInformation}
            </FormSection>
        );
    }
}

export default Info;
