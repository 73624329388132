/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {FormGroup} from 'react-bootstrap';
import {Link} from 'react-router';

import Table from './homepages-table';
import {FormSection, FormRow} from '../../../common/form/form';
import {HomepageConstants} from '../../../homepages/homepage-actions';
import {UserActions} from '../../../user/user-actions';
import UserStore from '../../../user/user-store';

class Homepages extends PureComponent {

    static get propTypes() {
        return {
            user: PropTypes.instanceOf(Immutable.Map).isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            homepages: UserStore.getState().get('homepages'),
        };
    }

    static getStores() {
        return [UserStore];
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        UserActions.getHomepages(this.props.user.get('id'));
    }

    render() {
        return (
            <FormSection title={this.context.intl.messages['accounts.edit.summary.homepages']} iconClass="fa-regular fa-home fa-fw">
                {this.state.homepages.map((homepage, index) => {
                    const contentType = homepage.getIn(['homePage', 'contentType']);

                    let contentTypeName = HomepageConstants.CONTENT_TYPE[contentType].displayName;
                    let home, titles;

                    switch (homepage.getIn(['homePage', 'contentType'])) {
                    case HomepageConstants.CONTENT_TYPE.HOME_PAGE.id:
                        home = homepage.getIn(['homePage', 'homePages']);
                        titles = this.context.intl.messages['common.na'];
                        break;
                    case HomepageConstants.CONTENT_TYPE.SINGLE_TITLE.id:
                    case HomepageConstants.CONTENT_TYPE.MULTIPLE_TITLES.id:
                        home = Immutable.fromJS([{
                            name: this.context.intl.messages[HomepageConstants.CONTENT_TYPE[contentType].homepageName]
                        }]);
                        titles = homepage.getIn(['homePage', 'homeTitles']);
                        break;
                    default: // NO_CONTENT_NO_DEFAULT
                        home = Immutable.fromJS([{
                            name: this.context.intl.messages[HomepageConstants.CONTENT_TYPE[contentType].homepageName]
                        }]);
                        titles = this.context.intl.messages['common.na'];
                        break;
                    }
                    return (
                        <FormRow key={`accounts-homepage-row-${index}`}>
                            <FormGroup>
                                <h4><i className="fas fa-handshake-alt"></i>&nbsp;
                                    <Link to={`/setup/authorized-partners/${homepage.get('partnerId')}`}>{homepage.get('partnerName')}</Link>
                                </h4>
                                <Table
                                    contentTypeName={contentTypeName}
                                    home={home}
                                    titles={titles}
                                />
                            </FormGroup>
                        </FormRow>
                    );
                })}
            </FormSection>
        );
    }
}

export default Container.create(Homepages);
