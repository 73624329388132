/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, ControlLabel, FormControl, FormGroup} from 'react-bootstrap';

import {sectionHeader} from './section-header';

import {FormRow, FormSection} from '~/src/common/form/form';
import {SectionPanel} from '~/src/common/panel/panel';
import {SlidingPanelActions} from '~/src/common/sliding-panel/sliding-panel-actions';
import {WithValidations} from '~/src/common/validations/validations';
import {AspectRatioActions} from '~/src/lookup/title/aspect-ratio/aspect-ratio-actions';
import AspectRatioStore from '~/src/lookup/title/aspect-ratio/aspect-ratio-store';
import {TitleActions, TitleConstants} from '~/src/titles/title-actions';

class GetBrainiacInfo extends Component {

    static get propTypes() {
        return {
            isDisabled: PropTypes.bool,
            videoDelivery: PropTypes.instanceOf(Immutable.Map).isRequired,
            selectedTitle: PropTypes.instanceOf(Immutable.Map).isRequired,
        };
    }

    static get defaultProps() {
        return {
            isDisabled: false
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            aspectRatioState: AspectRatioStore.getState()
        };
    }

    constructor(props) {
        super(props);

        this.handleGetBrainiacTitleInfo = this.handleGetBrainiacTitleInfo.bind(this);
    }

    componentDidMount() {
        AspectRatioActions.get(0, 9999);
    }

    handleGetBrainiacTitleInfo() {
        SlidingPanelActions.show('getTitleInfoInVideoDelivery');
    }

    render() {
        let mediaInfo = {};
        try {
            // Prevents page errors when mediaInfo contains invalid json
            mediaInfo = JSON.parse(JSON.parse(this.props.videoDelivery.get('mediaInfo')));
        } catch (err) {
            mediaInfo = {};
        }

        let fileDimensions;
        let frameRate;
        if (mediaInfo && mediaInfo.track && mediaInfo.track[1]) {
            const videoTrack = Immutable.fromJS(mediaInfo.track[1]);
            fileDimensions = `${videoTrack.get('Width')}x${videoTrack.get('Height')}`;
            frameRate = videoTrack.get('FrameRate');
        }

        let brainiacInfo;
        if (this.props.selectedTitle.get('id')) {
            let aspectRatio = '';
            if (this.props.selectedTitle.get('aspectRatioId')) {
                const lookup = AspectRatioStore.findItemByKey('id', this.props.selectedTitle.get('aspectRatioId'));
                if (lookup) {
                    aspectRatio = lookup.get('name');
                }
            }

            let episodeTitle = '';
            let seasonMPM = '';
            let episodeMPM = this.props.selectedTitle.get('mpmNumber') || '';
            let mainTitle = this.props.selectedTitle.get('displayName') || '';
            let season = this.props.selectedTitle.get('season') || '';

            switch (TitleActions.getCategoryGroup(this.props.selectedTitle.get('category'))) {
            case TitleConstants.TITLE_CATEGORY_GROUPS.EPISODE:
                episodeTitle = this.props.selectedTitle.get('displayName');
                mainTitle = this.props.selectedTitle.get('parentTitleDisplayName');
                episodeMPM = this.props.selectedTitle.get('mpmNumber');
                if (this.props.selectedTitle.get('parentTitle')) {
                    seasonMPM = this.props.selectedTitle.getIn(['parentTitle', 'mpmNumber']);
                    season = this.props.selectedTitle.getIn(['parentTitle', 'season']);
                }
                break;
            case TitleConstants.TITLE_CATEGORY_GROUPS.SEASON:
                mainTitle = this.props.selectedTitle.get('displayName');
                seasonMPM = this.props.selectedTitle.get('mpmNumber');
                episodeMPM = '';
                break;
            case TitleConstants.TITLE_CATEGORY_GROUPS.SERIES:
                mainTitle = this.props.selectedTitle.get('displayName');
                episodeMPM = '';
                break;
            }

            let genres;
            if (this.props.selectedTitle.get('genres')) {
                genres = this.props.selectedTitle.get('genres', []).join(', ');
            }

            brainiacInfo =
                <SectionPanel title={this.context.intl.messages['hardac.video-inbox.summary.send-to-oap.brainiac-info']}>
                    <FormSection>
                        <FormRow>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['hardac.video-inbox.summary.send-to-oap.brainiac-info.file-dimensions']}</ControlLabel>
                                <input type="text" className="form-control" value={fileDimensions} disabled readOnly={true} />
                            </FormGroup>
                        </FormRow>
                        <FormRow>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['hardac.video-inbox.summary.send-to-oap.brainiac-info.main-title']}</ControlLabel>
                                <input type="text" className="form-control" value={mainTitle} disabled readOnly={true} />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['hardac.video-inbox.summary.send-to-oap.brainiac-info.episode-title']}</ControlLabel>
                                <input type="text" className="form-control" value={episodeTitle} disabled />
                            </FormGroup>
                        </FormRow>
                        <FormRow>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['hardac.video-inbox.summary.send-to-oap.brainiac-info.season-mpm']}</ControlLabel>
                                <input type="text" className="form-control" value={seasonMPM} disabled />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['hardac.video-inbox.summary.send-to-oap.brainiac-info.episode-mpm']}</ControlLabel>
                                <input type="text" className="form-control" value={episodeMPM} disabled />
                            </FormGroup>
                        </FormRow>
                        <FormRow>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['hardac.video-inbox.summary.send-to-oap.brainiac-info.prod-num']}</ControlLabel>
                                <input type="text" className="form-control" value={this.props.selectedTitle.get('productionNumber') || ''} disabled />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['hardac.video-inbox.summary.send-to-oap.brainiac-info.season']}</ControlLabel>
                                <input type="text" className="form-control" value={season} disabled />
                            </FormGroup>
                        </FormRow>
                        <FormRow>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['hardac.video-inbox.summary.send-to-oap.brainiac-info.genre-type']}</ControlLabel>
                                <input type="text" className="form-control" value={genres} disabled />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['hardac.video-inbox.summary.send-to-oap.brainiac-info.trt']}</ControlLabel>
                                <input type="text" className="form-control" value={this.props.videoDelivery.get('displayName') || ''} disabled />
                            </FormGroup>
                        </FormRow>
                        <FormRow>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['hardac.video-inbox.summary.send-to-oap.brainiac-info.aspect-ratio']}</ControlLabel>
                                <input type="text" className="form-control" value={aspectRatio} disabled />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['hardac.video-inbox.summary.send-to-oap.brainiac-info.frame-rate']}</ControlLabel>
                                <input type="text" className="form-control" value={frameRate} disabled />
                            </FormGroup>
                        </FormRow>
                        <FormRow>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['hardac.video-inbox.summary.send-to-oap.brainiac-info.drop-frame']}</ControlLabel>
                                <input type="text" className="form-control" value={this.props.videoDelivery.get('dropFrame') || false} disabled />
                            </FormGroup>
                            <FormGroup>
                                <span />
                            </FormGroup>
                        </FormRow>
                    </FormSection>
                </SectionPanel>;
        }

        return (
            <div>
                <div>
                    {sectionHeader('fas fa-newspaper',
                        this.context.intl.messages['hardac.video-inbox.summary.send-to-oap.get-brainiac-info'],
                        this.context.intl.messages['hardac.video-inbox.summary.send-to-oap.description'])}
                    <FormSection>
                        <FormRow>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['hardac.video-inbox.summary.send-to-oap.connect-brainiac-title']}</ControlLabel>
                                <Button className="btn btn-primary-outline btn-block" disabled={this.props.isDisabled} onClick={this.handleGetBrainiacTitleInfo}>
                                    <i className="fas fa-sync"></i> {this.context.intl.messages['hardac.video-inbox.summary.send-to-oap.get-title-info']}
                                </Button>
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['hardac.video-inbox.summary.oap.title']} <span className="text-red">*</span></ControlLabel>
                                <FormControl type="text" disabled={true} readOnly={true} value={this.props.selectedTitle.get('name')} />
                            </FormGroup>
                        </FormRow>
                    </FormSection>
                </div>
                {brainiacInfo}
            </div>
        );
    }
}

export default WithValidations(GetBrainiacInfo);
