/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */
import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, ControlLabel, FormGroup} from 'react-bootstrap';
import Select from 'react-select';

import {PrivacyActions, PrivacyConstants} from '../privacy-actions';
import {PrivacyRequestValidations} from '../privacy-store';

import {DownloadActions} from '~/src/common/download/download-actions';
import {FormItem, FormRow, FormSection} from '~/src/common/form/form';
import {GetAttr} from '~/src/common/utils/utils';
import config from '~/src/config/config.js';

/**
 * Summary Tab and its content.
 */

const FormatJson = (json) => {
    if (!json) {
        return null;
    }
    return JSON.stringify(JSON.parse(json), null, 2);
};

class Summary extends Component {
    static get propTypes() {
        return {
            mode: PropTypes.string.isRequired,
            permissions: PropTypes.object.isRequired,
            privacyRequest: PropTypes.instanceOf(Immutable.Map).isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.handleExportFulfillmentResponse = this.handleExportFulfillmentResponse.bind(this);
        this.handleExportStatusResponse = this.handleExportStatusResponse.bind(this);
        this.handleSelectRegulationType = this.handleSelectRegulationType.bind(this);
        this.handleSelectRequestType = this.handleSelectRequestType.bind(this);
    }

    handleExportFulfillmentResponse() {
        DownloadActions.startDownloadExecution(`integration/ccpa/${this.props.privacyRequest.get('id')}/export/fulfillment-response`);
    }

    handleExportStatusResponse() {
        DownloadActions.startDownloadExecution(`integration/ccpa/${this.props.privacyRequest.get('id')}/export/status-response`);
    }

    handleSelectRegulationType(regulationType) {
        if (!regulationType) {
            PrivacyActions.update('regulationType', null);
            return;
        }

        PrivacyActions.update('regulationType', regulationType.id);
    }

    handleSelectRequestType(requestType) {
        if (!requestType) {
            PrivacyActions.update('requestType', null);
            return;
        }

        PrivacyActions.update('requestType', requestType.id);
    }

    render() {
        let requestInfoSection = <FormSection title={this.context.intl.messages['privacy.request-summary.title']} iconClass="fa-regular fa-circle-info">
            <FormRow>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['privacy.browse.subject']}</ControlLabel>
                    <div>{this.props.privacyRequest.get('subjectRequestId')}</div>
                </FormGroup>

                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['privacy.browse.status']}</ControlLabel>
                    <div>{this.props.privacyRequest.get('statusValue')}</div>
                </FormGroup>
            </FormRow>
            <FormRow>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['privacy.browse.request-type']}</ControlLabel>
                    <div>{this.props.privacyRequest.get('subjectRequestType')}</div>
                </FormGroup>

                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['privacy.browse.subject-id']}</ControlLabel>
                    <div>{this.props.privacyRequest.get('subjectId')}</div>
                </FormGroup>
            </FormRow>
            <hr />
            <FormRow>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['privacy.summary.message-received']}</ControlLabel>
                    <div><pre>{FormatJson(this.props.privacyRequest.get('requestData'))}</pre></div>
                </FormGroup>
            </FormRow>
            <hr />
            <FormRow>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['privacy.browse.date-received']}</ControlLabel>
                    <div>{Moment(this.props.privacyRequest.get('createdDate')).tz(config.DefaultTimezone).format(this.context.intl.messages['datetime-format'])}</div>
                </FormGroup>
            </FormRow>
        </FormSection>;

        let messageDetailsSection = <FormSection title={this.context.intl.messages['privacy.summary.message-details.title']} iconClass="fa-regular fa-arrow-up-from-bracket">
            <FormRow>
                <ControlLabel>{this.context.intl.messages['privacy.summary.message-details.status-response']}</ControlLabel>
                <Button style={{marginBottom: 5, padding: '0 5px'}} bsStyle="info" disabled={!this.props.privacyRequest.get('statusResponse')} className="btn-xs pull-right" onClick={this.handleExportStatusResponse}>
                    <i className="fa-regular fa-download"></i>&nbsp;{this.context.intl.messages['privacy.summary.export']}
                </Button>
            </FormRow>
            <FormRow>
                <div><pre>{FormatJson(this.props.privacyRequest.get('statusResponse'))}</pre></div>
            </FormRow>
            <br />
            <FormRow>
                <ControlLabel>{this.context.intl.messages['privacy.summary.message-details.fulfillment-response']}</ControlLabel>
                <Button style={{marginBottom: 5, padding: '0 5px'}} bsStyle="info" disabled={!this.props.privacyRequest.get('fulfillmentResponse')} className="btn-xs pull-right" onClick={this.handleExportFulfillmentResponse}>
                    <i className="fa-regular fa-download"></i>&nbsp;{this.context.intl.messages['privacy.summary.export']}
                </Button>
            </FormRow>
            <FormRow>
                <div><pre>{FormatJson(this.props.privacyRequest.get('fulfillmentResponse'))}</pre></div>
            </FormRow>
        </FormSection>;

        let errorsSection = <FormSection title={this.context.intl.messages['privacy.summary.errors.title']} iconClass="fa-regular fa-circle-exclamation">
            <FormRow>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['privacy.summary.message-details.error-message']}</ControlLabel>
                    <div><pre>{this.props.privacyRequest.get('errorMessage')}</pre></div>
                </FormGroup>
            </FormRow>
        </FormSection>;

        if (this.props.mode === 'create') {
            const requestTypeOptions = Object.keys(PrivacyConstants.REQUEST_TYPE).map(k => {
                const rt = PrivacyConstants.REQUEST_TYPE[k];
                return {name: this.context.intl.messages[rt.label], id: rt.id};
            });
            const selectedRequestType = requestTypeOptions.find(c => c.id === this.props.privacyRequest.get('requestType'));

            const regulationTypeOptions = Object.keys(PrivacyConstants.REGULATION_TYPE).map(k => {
                const rt = PrivacyConstants.REGULATION_TYPE[k];
                return {name: this.context.intl.messages[rt.label], id: rt.id};
            });
            const selectedRegulationType = requestTypeOptions.find(c => c.id === this.props.privacyRequest.get('regulationType'));

            requestInfoSection = <FormSection title={this.context.intl.messages['privacy.request-summary.title']} iconClass="fa-regular fa-circle-info">
                <FormRow>
                    <FormGroup>
                        <FormItem attr="requestId"
                            disabled={!this.props.permissions.canCreate}
                            label={this.context.intl.messages['privacy.summary.irr-id']}
                            model={this.props.privacyRequest}
                            setter={PrivacyActions.update}
                            type="text"
                            validations={PrivacyRequestValidations.requestId.validations}
                        />
                    </FormGroup>

                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['privacy.browse.regulation-type']}&nbsp;<span className="text-red">*</span></ControlLabel>
                        <br/>
                        <Select
                            getOptionLabel={GetAttr('name')}
                            getOptionValue={GetAttr('id')}
                            isClearable={true}
                            isMulti={false}
                            isDisabled={!this.props.permissions.canCreate}
                            name="regulationType"
                            onChange={this.handleSelectRegulationType}
                            options={regulationTypeOptions}
                            placeholder={this.context.intl.messages['common.select.placeholder']}
                            value={selectedRegulationType}
                        />
                    </FormGroup>

                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['privacy.browse.request-type']}&nbsp;<span className="text-red">*</span></ControlLabel>
                        <br/>
                        <Select
                            getOptionLabel={GetAttr('name')}
                            getOptionValue={GetAttr('id')}
                            isClearable={true}
                            isMulti={false}
                            isDisabled={!this.props.permissions.canCreate}
                            name="requestType"
                            onChange={this.handleSelectRequestType}
                            options={requestTypeOptions}
                            placeholder={this.context.intl.messages['common.select.placeholder']}
                            value={selectedRequestType}
                        />
                    </FormGroup>

                    <FormGroup>
                        <FormItem attr="email"
                            disabled={!this.props.permissions.canCreate}
                            label={this.context.intl.messages['privacy.summary.email']}
                            model={this.props.privacyRequest}
                            setter={PrivacyActions.update}
                            type="text"
                            validations={PrivacyRequestValidations.email.validations}
                        />
                    </FormGroup>
                </FormRow>
            </FormSection>;

            messageDetailsSection = undefined;
            errorsSection = undefined;
        }

        return (
            <div className="tab-pane padding-x-20 padding-bottom-20">
                {requestInfoSection}
                <br />
                {messageDetailsSection}
                <br />
                {errorsSection}
            </div>
        );
    }
}

export default Summary;
