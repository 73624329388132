/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ControlLabel, FormGroup} from 'react-bootstrap';

import Info from './info';
import {FormRow, FormSection} from '../../../common/form/form';
import Criteria from '../../../criteria/criteria';
import {GroupActions, GroupConstants} from '../../../security/group/group-actions';
import GroupSelect from '../../../security/group/group-select';


/**
 * Summary Tab and its content.
 */
class Summary extends Component {
    static get propTypes() {
        return {
            mode: PropTypes.string.isRequired,
            permissions: PropTypes.object,
            userGroup: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get defaultProps() {
        return {
            permissions: {}
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    handleAutoSyncChange(event) {
        let isChecked = 0;
        if (event.target.checked) {
            isChecked = 1;
        }
        GroupActions.updateGroup(GroupConstants.USER, 'autoSync', isChecked);
    }

    handleBrandChange(brand) {
        if (!brand) {
            // Reset the value.
            GroupActions.updateGroup(GroupConstants.USER, 'brandId', null);
            return;
        }

        GroupActions.updateGroup(GroupConstants.USER, 'brandId', brand.id);
        return;
    }

    handleClassificationChange(classification) {
        if (!classification.id) {
            // Reset the value.
            GroupActions.updateGroup(GroupConstants.USER, 'groupClassificationType', 0);
            return;
        }

        GroupActions.updateGroup(GroupConstants.USER, 'groupClassificationType', classification.id);
        return;
    }

    handleCompaniesChange(companies) {
        if (!companies) {
            // Reset the value.
            GroupActions.updateGroup(GroupConstants.USER, 'criteria.companies', Immutable.OrderedSet());
            return;
        }

        // Call the select action but with immutable objects.
        GroupActions.updateGroup(GroupConstants.USER, 'criteria.companies', Immutable.fromJS(companies).toSet().sortBy(c => c.get('name')).map(c => c.get('id')));
        return;
    }

    handleGroupsChange(groups) {
        if (!groups) {
            // Reset the value.
            GroupActions.updateGroup(GroupConstants.USER, 'criteria.groups', Immutable.OrderedSet());
            return;
        }

        // Call the select action but with immutable objects.
        GroupActions.updateGroup(GroupConstants.USER, 'criteria.groups', Immutable.fromJS(groups).toSet().sortBy(g => g.get('name')));
        return;
    }

    handleSelectAssetGroup(groups) {
        if (!groups) {
            // Reset the value.
            GroupActions.updateGroup(GroupConstants.USER, 'assetGroups', Immutable.OrderedSet());
            return;
        }

        // Call the select action but with immutable objects.
        GroupActions.updateGroup(GroupConstants.USER, 'assetGroups', Immutable.fromJS(groups).toSet().sortBy(g => g.get('name')));
        return;
    }

    handleSelectTitleGroup(groups) {
        if (!groups) {
            // Reset the value.
            GroupActions.updateGroup(GroupConstants.USER, 'titleGroups', Immutable.OrderedSet());
            return;
        }

        // Call the select action but with immutable objects.
        GroupActions.updateGroup(GroupConstants.USER, 'titleGroups', Immutable.fromJS(groups).toSet().sortBy(g => g.get('name')));
        return;
    }

    handleTerritoriesChange(territories) {
        if (!territories) {
            // Reset the value.
            GroupActions.updateGroup(GroupConstants.USER, 'criteria.territories', Immutable.OrderedSet());
            return;
        }

        // Call the select action but with immutable objects.
        GroupActions.updateGroup(GroupConstants.USER, 'criteria.territories', Immutable.fromJS(territories).toSet().sortBy(t => t.get('name')).map(t => t.get('id')));
        return;
    }

    render() {
        return (
            <div className="tab-pane padding-x-20 padding-bottom-20">
                <Info
                    mode={this.props.mode}
                    onBrandChange={this.handleBrandChange}
                    onClassificationChange={this.handleClassificationChange}
                    permissions={this.props.permissions}
                    userGroup={this.props.userGroup}
                />
                <FormSection title={this.context.intl.messages['groups.user.create.title-catalogs.title']} iconClass="fas fa-book">
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['groups.user.create.title-catalogs.choose']}</ControlLabel>
                            <GroupSelect
                                disabled={!this.props.permissions.canEdit}
                                onChange={this.handleSelectTitleGroup}
                                selected={this.props.userGroup.get('titleGroups')}
                                type={GroupConstants.TITLE}
                            />
                        </FormGroup>
                    </FormRow>
                </FormSection>
                <FormSection title={this.context.intl.messages['groups.user.create.asset-catalogs.title']} iconClass="far fa-file-video">
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['groups.user.create.asset-catalogs.choose']}</ControlLabel>
                            <GroupSelect
                                disabled={!this.props.permissions.canEdit}
                                onChange={this.handleSelectAssetGroup}
                                selected={this.props.userGroup.get('assetGroups')}
                                type={GroupConstants.ASSET}
                            />
                        </FormGroup>
                    </FormRow>
                </FormSection>
                <Criteria
                    autoSync={this.props.userGroup.get('autoSync') === 1}
                    disabled={!this.props.permissions.canEdit}
                    onAutoSyncChange={this.handleAutoSyncChange}
                    onCompaniesChange={this.handleCompaniesChange}
                    onTerritoriesChange={this.handleTerritoriesChange}
                    onGroupsChange={this.handleGroupsChange}
                    selectedCompanies={this.props.userGroup.getIn(['criteria', 'companies'], Immutable.OrderedSet())}
                    selectedTerritories={this.props.userGroup.getIn(['criteria', 'territories'], Immutable.OrderedSet())}
                    selectedGroups={this.props.userGroup.getIn(['criteria', 'groups'], Immutable.OrderedSet())}
                />
            </div>
        );
    }
}

export default Summary;
