/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import AddExistingAssets from '../../../assets-tab/add-existing-assets';
import {AssetTabActions} from '../../../assets-tab/asset-tab-actions';
import AssetTabStore from '../../../assets-tab/asset-tab-store';
import SlidingPanel from '../../../common/sliding-panel/sliding-panel';
import {SlidingPanelActions} from '../../../common/sliding-panel/sliding-panel-actions';

import {EventActions, EventConstants} from '~/src/events/event-actions';

class AddExistingPanel extends Component {
    static get propTypes() {
        return {
            event: PropTypes.instanceOf(Immutable.Map).isRequired,
            selectedAssetsToAdd: PropTypes.instanceOf(Immutable.Set),
        };
    }

    static get defaultProps() {
        return {
            selectedAssetsToAdd: Immutable.Set()
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.handleCancelPanel = this.handleCancelPanel.bind(this);
        this.handleClickAddSelectedItems = this.handleClickAddSelectedItems.bind(this);
        this.simulateCancelButton = this.simulateCancelButton.bind(this);
    }

    componentWillUnmount() {
        this.simulateCancelButton();
    }

    handleCancelPanel() {
        AssetTabActions.clearSelectedAssetsToAdd();
    }

    handleClickAddSelectedItems() {
        EventActions.addItems(this.props.event.get('id'), EventConstants.EVENT.ITEM_TYPES.ASSET, AssetTabStore.getState().get('selectedAssetsToAdd'));
        EventActions.copyTitlesFromAssets(this.props.event.get('id'), AssetTabStore.getState().get('selectedAssetsToAdd').toJS());
        AssetTabActions.clearSelectedAssetsToAdd();

        SlidingPanelActions.hide('addExistingAssetsToEvent');
        return;
    }

    simulateCancelButton() {
        SlidingPanelActions.hide('addExistingAssetsToEvent');
        this.handleCancelPanel();
    }

    render() {
        const addSelectedItems = (
            <button
                className="btn btn-md btn-success-outline"
                onClick={this.handleClickAddSelectedItems}
            >
                <i className="fas fa-plus"></i>&nbsp;
                {this.context.intl.messages['events.assets.add-assets-number'].replace('{}', this.props.selectedAssetsToAdd.size)}
            </button>
        );

        const columns = ['assetThumbnail', 'assetName', 'contentType', 'active', 'mfa', 'actions'];
        const columnDefs = [{
            // Add the control class to the last column. This colum will
            // contain the button to show the responsive data.
            className: 'control',
            targets:   -1,
            width: 20
        }, {
            targets: 'no-sort',
            orderable: false
        }, {
            targets: [3], // active
            className: 'text-center',
            width: 90
        }, {
            targets: [4], // mfa
            className: 'text-center',
            width: 10
        }];
        let addExistingItemsPanel = <AddExistingAssets
            assetType="video"
            columnDefs={columnDefs}
            columns={columns}
            displayLinks={false}
            isOutlineStyle
            showContentType={true}
            onlyAllowOneAsset={this.props.event.get('eventType') === EventConstants.EVENT_TYPES.BAFTA_EVENT.id}
        />;

        const title = <span>{this.context.intl.messages['events.assets.add-assets.title']}&nbsp;<small>{this.props.event.get('name')}</small></span>;

        return (
            <SlidingPanel
                cancelButtonClassNames="btn-md btn-navy-outline"
                extraButtons={addSelectedItems}
                icon="fas fa-plus-circle"
                id="addExistingAssetsToEvent"
                onCancel={this.handleCancelPanel}
                title={title}
                useH3Header={true}
            >
                {addExistingItemsPanel}
            </SlidingPanel>
        );
    }
}

export default AddExistingPanel;
