/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

// import {ActionHistoryConstants} from '../system/action-history/action-history-actions';
import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {LoginBackgroundConstants} from './login-background-actions';
import Dispatcher from '../dispatcher/dispatcher';

class LoginBackgroundStore extends ReduceStore {

    getInitialState() {
        let state = Immutable.Map({
            backgrounds: Immutable.List(),
            partner: Immutable.Map(),
            partners: Immutable.List(),
            selectedBackgrounds: Immutable.List()
        });
        return state;
    }

    reduce(state, action) {

        switch (action.actionType) {

        case LoginBackgroundConstants.CLEAR:
            state = this.getInitialState();
            break;

        case LoginBackgroundConstants.GET.SUCCESS:
            state = state.merge({
                backgrounds: action.backgrounds
            });
            break;

        case LoginBackgroundConstants.GET_PARTNERS:
            state = state.merge({
                partners: action.partners
            });
            break;

        case LoginBackgroundConstants.TOGGLE_SELECT:
            let index = state.get('backgrounds').findIndex(background => background.get('fileName') === action.fileName);
            // Toggle the item value.
            state = state.setIn(['backgrounds', index, '__selected'], action.value);
            // Update the selected array.
            state = state.set('selectedBackgrounds', state.get('backgrounds').filter(bg => !!bg.get('__selected')));
            state = state.set('selectAll', state.get('backgrounds').size === state.get('selectedBackgrounds').size);
            break;

        case LoginBackgroundConstants.TOGGLE_SELECT_ALL:
            // Toggle all items.
            state = state.merge({
                selectAll: action.value,
                backgrounds: state.get('backgrounds').map(u => u.set('__selected', action.value))
            });

            // Update the selectedBackgrounds list according to the current value,
            // no need to filter the array.
            if (action.value) {
                state = state.set('selectedBackgrounds', state.get('backgrounds'));
            } else {
                state = state.set('selectedBackgrounds', Immutable.List());
            }
            break;

        case LoginBackgroundConstants.UPDATE:
            state = state.set(...action.attr.split('.'), action.value);
            break;
        }

        return state;
    }
}

let store = new LoginBackgroundStore(Dispatcher);

export default store;
