/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import Catalogs from '../catalogs/catalogs';

const Local = ({assignedCatalogs, serieNavigation, title, canEdit}) => (
    <Catalogs
        assignedCatalogs={assignedCatalogs}
        canEdit={canEdit}
        serieNavigation={serieNavigation}
        title={title}
        name={'languageCatalogs'}
        type={'language'}
    />
);

Local.propTypes = {
    assignedCatalogs: PropTypes.instanceOf(Immutable.List).isRequired,
    canEdit: PropTypes.bool.isRequired,
    serieNavigation: PropTypes.instanceOf(Immutable.Map).isRequired,
    title: PropTypes.instanceOf(Immutable.Map).isRequired,
};

export default Local;
