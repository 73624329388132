/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {MassAddUpdateConstants} from './mass-add-update-actions';
import Dispatcher from '../../dispatcher/dispatcher';

class MassAddUpdateStore extends ReduceStore {
    // Return an action type by id.
    getInitialState() {
        return Immutable.Map({
            filters: Immutable.Map(),
            massAddUpdateLogs: Immutable.List(),
            massAddProcess: Immutable.Map(),
            offset: 0,
            relatedTitle: Immutable.Map(),
            rows: Immutable.List(),
            selectAll: false,
            showPreloader: false,
            size: 0,
            total: 0,
        });
    }

    reduce(state, action) {
        switch (action.actionType) {
        case MassAddUpdateConstants.MASS_ADD.CLEAR:
            state = this.getInitialState();
            break;

        case MassAddUpdateConstants.MASS_ADD.FINDBY.SUCCESS:
            state = state.merge({
                massAddProcess: action.massAddProcess,
                rows: action.rows
            });
            break;

        case MassAddUpdateConstants.MASS_ADD.RELATED_TITLE.ADD:
            state = state.setIn(['massAddProcess', 'parentTitleId'], action.title.get('id'));
            state = state.set('relatedTitle', action.title);
            break;

        case MassAddUpdateConstants.MASS_ADD.RELATED_TITLE.CLEAR:
            state = state.set('relatedTitle', Immutable.Map());
            break;

        case MassAddUpdateConstants.MASS_ADD.TOGGLE_SELECT:
            // Toggle the item value.
            state = state.setIn(['rows', action.index, '__selected'], action.value);
            state = state.set('selectAll', state.get('rows').every(row => row.get('__selected')));
            break;

        case MassAddUpdateConstants.MASS_ADD.TOGGLE_SELECT_ALL:
            // Toggle all items.
            state = state.merge({
                selectAll: action.value,
                rows: state.get('rows').map(u => u.set('__selected', action.value))
            });
            break;

        case MassAddUpdateConstants.MASS_ADD.GET.SUCCESS:
            state = state.merge({
                offset: action.offset,
                massAddUpdateLogs: action.titlesLogs,
                showPreloader: false,
                size: action.size,
                total: action.total
            });
            break;
        case MassAddUpdateConstants.MASS_ADD.GET.ERROR:
            state = state.set('showPreloader', false);
            break;

        case MassAddUpdateConstants.MASS_ADD.GET.START:
            state = state.set('showPreloader', true);
            break;

        case MassAddUpdateConstants.FILTER.CLEAR:
            state = state.set('filters', Immutable.Map());
            break;

        case MassAddUpdateConstants.FILTER.SET:
            state = state.setIn(['filters', ...action.attr.split('.')], action.value);
            break;

        }
        return state;

    }
}

let store = new MassAddUpdateStore(Dispatcher);

export default store;
