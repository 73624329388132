/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React from 'react';
import {ResponsiveEmbed} from 'react-bootstrap';

import Summary from './summary';
import {TimelineAssetActions, TimelineAssetConstants} from './timeline-asset-actions';
import TimelineAssetStore from './timeline-asset-store';
import {AssetTalentActions} from '../../assets/asset-talent-actions';
import AssetTalentStore from '../../assets/asset-talent-store';
import AssetCatalogStore from '../../assets/catalogs/asset-catalog-store';
import CatalogsTab from '../../assets/catalogs/catalogs';
import AssetTitleStore from '../../assets/manage-titles/asset-title-store';
import ManageTitleTab from '../../assets/manage-titles/titles';
import TalentTab from '../../assets/talent/talent';
import TitleTab from '../../assets/title/title';
import UserTab from '../../assets/user/user';
import DocumentTitle from '../../common/document-title';
import Panel from '../../common/panel/panel';
import {Tab, Tabs} from '../../common/routing-tab/routing-tab';
import {History, SideNotes} from '../../common/side-notes/side-notes';
import {IsHardacLocation} from '../../common/utils/utils';
import {AlertsWarnings} from '../../common/validations/alerts-warnings';
import WithPermissions from '../../decorators/with-permissions';
import ActionsMenu from '../../layout/actions-menu/actions-menu';
import LayoutStore from '../../layout/layout-store';
import StickyHeader from '../../layout/sticky-header/sticky-header';
import Preloader from '../../preloader';
import {RouterActions} from '../../router/router-actions';
import {ActionHistoryActions, ActionHistoryConstants} from '../../system/action-history/action-history-actions';
import SessionStore from '../../user/session/session-store';
import VideoPreviewModal from '../video-preview-modal';

import {VideoDeliveryActions} from '~/src/hardac/video-delivery/video-delivery-actions';
import VideoDeliveryStore from '~/src/hardac/video-delivery/video-delivery-store';

class Timeline extends React.Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            params: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            permissions: {}
        };
    }

    static getPermissions() {
        return {
            canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.HARDAC.TIMELINE.EDIT),
            canRead: SessionStore.canUser(SessionStore.PERMISSIONS.HARDAC.TIMELINE.READ),
            canReadCatalogs: SessionStore.canUser(SessionStore.PERMISSIONS.CATALOGS.ASSET.READ),
            canReadTalent: SessionStore.canUser(SessionStore.PERMISSIONS.TALENT.READ),
            canReadTitles: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.READ),
            canReadUsers: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.READ),
            canVideoPlayback: SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.VIDEO.ALLOW_PLAYBACK_CMS),
        };
    }

    static calculateState() {
        const ts = TimelineAssetStore.getState();
        const vds = VideoDeliveryStore.getState();
        return {
            audioProfile: ts.get('audioProfile'),
            brainiacAppTheme: LayoutStore.getState().get('brainiacAppTheme'),
            clips: ts.get('clips'),
            hardacAppTheme: LayoutStore.getState().get('hardacAppTheme'),
            history: ts.get('history'),
            oapShowCodes: vds.get('oapShowCodes'),
            originalTimeline: ts.get('originalTimeline'),
            player: ts.get('player'),
            showPlayer: ts.get('showPlayer'),
            showPreloader: ts.get('showPreloader'),
            timeline: ts.get('timeline'),
            video: ts.get('video')
        };
    }

    static getStores() {
        return [TimelineAssetStore, LayoutStore, VideoDeliveryStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            showPreviewModal: false,
        }, this.constructor.calculateState());

        this.addNote = this.addNote.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleShowPlayer = this.handleShowPlayer.bind(this);
        this.isDirty = this.isDirty.bind(this);
        this.togglePreviewModal = this.togglePreviewModal.bind(this);

        return;
    }

    componentDidMount() {
        TimelineAssetActions.findById(this.props.params.id);
        VideoDeliveryActions.getOapShowCodes();

        AssetTalentActions.clear();

        RouterActions.registerRedirectCheck((location) => {
            return this.isDirty(location);
        });

        return;
    }

    componentWillReceiveProps() {
        RouterActions.clearRedirectChecks();
        RouterActions.registerRedirectCheck((location) => {
            return this.isDirty(location);
        });
    }

    componentWillUnmount() {
        TimelineAssetActions.clear();

        return;
    }

    addNote(description) {
        ActionHistoryActions.addNote(this.props.params.id, description, ActionHistoryConstants.ACTION_OBJECTS.VIDEO);
    }


    handleCancel() {
        // Return hardac users to Browse Timelines, Brainiac users to Browse Assets
        if (this.props.location) {
            if (IsHardacLocation(this.props.location)) {
                RouterActions.redirect('/hardac/timelines', true);
                return;
            }
        }

        RouterActions.redirect('/assets', true);
    }

    handleSave() {
        const isHardac = IsHardacLocation(this.props.location);
        TimelineAssetActions.save(
            this.props.params.id,
            this.state.timeline,
            AssetCatalogStore.getState().get('assignedCatalogs'),
            AssetCatalogStore.getState().get('originalAssignedCatalogs'),
            AssetTalentStore.getState().get('talent'),
            AssetTalentStore.getState().get('originalTalent'),
            AssetTitleStore.getState().get('assignedTitles'),
            AssetTitleStore.getState().get('originalAssignedTitles'),
            isHardac
        );
    }

    handleShowPlayer() {
        if (
            this.state.timeline.get('proxyFile') &&
            this.state.timeline.getIn(['proxyFile', 'storageStatusType']) === TimelineAssetConstants.TIMELINE.STORAGE_STATUS_TYPE.AVAILABLE
        ) {
            TimelineAssetActions.showPlayer(this.state.timeline);
        }
        return;
    }

    togglePreviewModal() {
        this.setState((prevState) => ({
            showPreviewModal: !prevState.showPreviewModal
        }));
    }

    isDirty(location) {
        if (location) {
            //if just changing tabs no confirmation needed.
            let goingTo = location.pathname.split('/');
            let current = this.props.location.pathname.split('/');
            if (goingTo[2] === current[2] && goingTo[3] === current[3] && goingTo[4] === current[4]) {
                return false;
            }
        }
        return !this.state.timeline.equals(this.state.originalTimeline);
    }

    render() {
        const assetId = parseInt(this.props.params.id, 10);
        const assetName = this.state.timeline.get('assetName');
        let baseRoute, viewingMode;
        const isHardac = IsHardacLocation(this.props.location);
        if (isHardac) {
            baseRoute = `/hardac/timeline/${this.props.params.id}/edit-asset`;
            viewingMode = this.state.hardacAppTheme;
        } else {
            baseRoute = `/assets/timeline/${this.props.params.id}`;
            viewingMode = this.state.brainiacAppTheme;
        }

        let preview = <div className="text-center">N/A</div>;
        let eventsAndNotes = (
            <SideNotes
                basePath={`/assets/timeline/${this.props.params.id}`}
                dialogMessage={this.context.intl.messages['common.side-notes.add-note.body']}
                disabled={ !this.props.permissions.canEdit }
                notes={this.state.history.toJS().slice(0, 4)}
                onAddNote={this.addNote}
                title={this.context.intl.messages['common.side-notes.title']}
            />
        );

        let playIcon;
        if (this.props.permissions.canVideoPlayback) {
            playIcon = (
                <span
                    className="thumbnail-play-icon glyphicon glyphicon-play-circle"
                />
            );
        }

        if (this.state.timeline.get('proxyFile')) {
            preview = (
                <ResponsiveEmbed a16by9>
                    <a onClick={this.togglePreviewModal}>
                        {/* eslint-disable-next-line @typescript-eslint/no-var-requires */}
                        <img className="img-thumbnail" src={require('../../layout/placeholder-horizontal.svg')}/>
                        {playIcon}
                    </a>
                </ResponsiveEmbed>
            );
        }

        //tabs
        const catalogsTab = <Tab route={`${baseRoute}/catalogs`} title={this.context.intl.messages['common.tab-title.catalogs']}>
            <CatalogsTab
                assetId={assetId}
                displayLinks={this.props.permissions.canReadCatalogs}
            />
        </Tab>;
        const historyTab = <Tab route={`${baseRoute}/history`} title={this.context.intl.messages['common.tab-title.history']} tabClassName="pull-right">
            <History
                actionObject={ActionHistoryConstants.ACTION_OBJECTS.VIDEOTIMELINE}
                id={this.props.params.id}
                pagination
                pathname={this.props.location.pathname}
                query={this.props.location.query}
            />
        </Tab>;
        const summaryTab = <Tab route={baseRoute} title={this.context.intl.messages['common.tab-title.summary']}>
            <Summary
                clips={this.state.clips}
                disabled={!this.props.permissions.canEdit}
                isHardac={isHardac}
                timeline={this.state.timeline}
                oapShowCodes={this.state.oapShowCodes}
                tracks={this.state.audioProfile}
                viewingMode={viewingMode}
            />
        </Tab>;
        let titlesTab, talentTab, userTab;
        if (isHardac) {
            titlesTab = <Tab route={`${baseRoute}/titles`} title={this.context.intl.messages['common.tab-title.titles']}>
                <ManageTitleTab
                    assetId={assetId}
                    location={this.props.location}
                    displayLinks={this.props.permissions.canReadTitles}
                />
            </Tab>;
        } else {
            titlesTab = <Tab route={`${baseRoute}/titles`} title={this.context.intl.messages['common.tab-title.titles']}>
                <TitleTab
                    assetId={assetId}
                    displayLinks={this.props.permissions.canReadTitles}
                    location={this.props.location}
                />
            </Tab>;
            talentTab = <Tab route={`${baseRoute}/talent`} title={this.context.intl.messages['common.tab-title.talent']}>
                <TalentTab
                    assetId={assetId}
                    displayLinks={this.props.permissions.canReadTalent}
                    viewingMode={viewingMode}
                />
            </Tab>;
            userTab = <Tab route={`${baseRoute}/users`} title={this.context.intl.messages['common.tab-title.users']}>
                <UserTab
                    assetId={assetId}
                    displayLinks={this.props.permissions.canReadUsers}
                    location={this.props.location}
                />
            </Tab>;
        }

        let validations = TimelineAssetStore.getValidations();

        return (
            <DocumentTitle
                message="asset.timeline.title"
            >
                <div>
                    <StickyHeader>
                        <div className="col-md-6">
                            <h1>
                                <i className="fas fa-stream"></i>&nbsp;{this.context.intl.messages['asset.timeline.title']}
                                <br/><small>{assetName}</small>
                            </h1>
                        </div>
                        <ActionsMenu
                            canEdit={this.props.permissions.canEdit}
                            canSave={!validations.length && this.props.permissions.canEdit}
                            hideSaveAndSync={!this.props.permissions.canEdit}
                            onCancel={this.handleCancel}
                            onSave={this.handleSave}
                            saveText={this.context.intl.messages['hardac.timeline.save']}
                        />
                    </StickyHeader>
                    <Preloader show={this.state.showPreloader} fixed loadingDots>
                        <section className="content" id="contentContainer">
                            <div className="row">
                                <div className="col-lg-9">
                                    <Tabs location={this.props.location}>
                                        {summaryTab}
                                        {talentTab}
                                        {titlesTab}
                                        {userTab}
                                        {catalogsTab}
                                        {historyTab}
                                    </Tabs>
                                </div>
                                <div className="col-lg-3">
                                    <Panel
                                        collapsible
                                        defaultExpanded
                                        iconClass="far fa-play-circle"
                                        title={this.context.intl.messages['asset.timeline.preview']}>
                                        {preview}
                                        <a
                                            className="btn btn-lg btn-block btn-primary-outline Mt(20px)"
                                            href={`/hardac/timeline/${this.props.params.id}/clips`}
                                            target="_blank">
                                            <i className="fas fa-cut"></i>&nbsp;{this.context.intl.messages['asset.timeline.edit-clips.button']}
                                        </a>
                                    </Panel>
                                    <AlertsWarnings
                                        title={this.context.intl.messages['common.alerts-warnings-title']}
                                        validations={validations}
                                    />
                                    {eventsAndNotes}
                                </div>
                            </div>
                        </section>
                    </Preloader>
                    <VideoPreviewModal
                        onHide={this.togglePreviewModal}
                        onShowPlayer={this.handleShowPlayer}
                        show={this.state.showPreviewModal}
                        showPlayer={this.state.showPlayer}
                        video={this.state.video}
                        viewingMode={viewingMode}
                    />
                </div>
            </DocumentTitle>
        );
    }
}

export default (WithPermissions(Container.create(Timeline)));
export {
    Timeline
};
