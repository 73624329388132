/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import jQuery from 'jquery';
import PropTypes from 'prop-types';
import React from 'react';
import {Col, Row} from 'react-bootstrap';

import {MassUpdateActions} from './mass-update-actions';
import AuthLink from '../../../common/auth-link';
import DocumentTitle from '../../../common/document-title';

import 'bootstrap-fileinput/css/fileinput.css';
import 'bootstrap-fileinput/js/fileinput';

class MassUpdate extends React.Component {

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    componentDidMount() {
        MassUpdateActions.clear();
        // Manually start the fileinput plugin.
        this.$fileUpload = jQuery('#excel-upload');
        this.$fileUpload.fileinput({
            showPreview: false,
            showRemove: false,
            showUpload: false
        });
    }

    handleChange(event) {
        MassUpdateActions.uploadMassUpdate(event.target.files[0]);
    }

    render() {
        return (
            <DocumentTitle message="document-titles.catalogs-asset.mass-update">
                <div>
                    <section className="content-header">
                        <h1><i className="fas fa-book"></i>&nbsp;{this.context.intl.messages['catalogs.mass-update-asset.title']}</h1>
                    </section>
                    <div className="content">
                        <Row>
                            <Col xs={12}>
                                <div className="container-fluid no-x-padding">
                                    <Row>
                                        <Col xs={12}>
                                            <div className="box">
                                                <div className="box-body">
                                                    <h3>{this.context.intl.messages['catalogs.mass-update.step-one.title']}</h3>
                                                    <hr />
                                                    <div className="text-center">
                                                        <AuthLink
                                                            className="btn btn-lg btn-default"
                                                            href="asset/catalog/ingest/template"
                                                        >
                                                            <i className="fas fa-download"></i> {this.context.intl.messages['catalogs.mass-update.download-template']}
                                                        </AuthLink>
                                                    </div>
                                                    <hr />
                                                    <div className="row margin-bottom-20">
                                                        <div className="col-lg-8 col-lg-push-2 margin-bottom-10">
                                                            <div className="form-section">
                                                                <div className="form-group">
                                                                    <label>{this.context.intl.messages['catalogs.mass-update.upload-file']}</label>
                                                                    <input
                                                                        id="excel-upload"
                                                                        type="file"
                                                                        onChange={this.handleChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}

export default MassUpdate;
