/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, Col, Row, ControlLabel, FormGroup} from 'react-bootstrap';

import {DeliveryActions} from './delivery-actions';
import DeliveryStore from './delivery-store';
import MetadataExportLanguage from './metadata-export-language';
import MetadataExportSpec from './metadata-export-spec';
import Notification from './notification';
import BaseSelect from '../../../common/base-select/base-select';
import {FormRow, FormSection} from '../../../common/form/form';
import {WithValidations} from '../../../common/validations/validations';

class Delivery extends Component {

    static get propTypes() {
        return {
            disabled: PropTypes.bool,
            userId: PropTypes.string.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            broadcastStandard: DeliveryStore.getState().get('broadcastStandard'),
            fileFormat: DeliveryStore.getState().get('fileFormat'),
            notifications: DeliveryStore.getState().get('notifications'),
            originalNotifications: DeliveryStore.getState().get('originalNotifications'),
            originalProfile: DeliveryStore.getState().get('originalProfile'),
            profile: DeliveryStore.getState().get('profile')
        };
    }

    static getStores() {
        return [DeliveryStore];
    }

    static get defaultProps() {
        return {
            disabled: true
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            isBroadcastStandardDirty: false,
            isFileFormatDirty: false,
            notifications: DeliveryStore.getState().get('notifications'),
            profile: DeliveryStore.getState().get('profile'),
            selectedBroadcastStandard: DeliveryStore.getBroadcastStandardType(DeliveryStore.getState().get('profile').get('broadcastStandard')),
            selectedFileFormat: DeliveryStore.getFileFormatType(DeliveryStore.getState().get('profile').get('fileFormat'))
        }, this.constructor.calculateState());

        this.handleAdd = this.handleAdd.bind(this);
        this.handleSelectBroadcastStandard = this.handleSelectBroadcastStandard.bind(this);
        this.handleSelectFileFormat = this.handleSelectFileFormat.bind(this);
    }

    componentDidMount() {
        if (this.state.notifications.equals(this.state.originalNotifications) && this.state.profile.equals(this.state.originalProfile)) {
            DeliveryActions.clear();
            DeliveryActions.getNotificationsById(this.props.userId);
            DeliveryActions.getProfileById(this.props.userId);
        }
        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.disabled !== this.props.disabled) {
            return true;
        }

        if (nextState.notifications !== this.state.notifications ||
            nextState.profile !== this.state.profile ||
            nextState.selectedBroadcastStandard !== this.state.selectedBroadcastStandard ||
            nextState.selectedFileFormat !== this.state.selectedFileFormat) {
            return true;
        }

        return false;
    }

    handleAdd() {
        DeliveryActions.addNotification();
        return;
    }

    handleSelectBroadcastStandard(option) {
        if (!this.state.isBroadcastStandardDirty) {
            this.setState({isBroadcastStandardDirty: true});
        }
        if (option === null) {
            DeliveryActions.updateProfile('broadcastStandard', null);
            return;
        }
        DeliveryActions.updateProfile('broadcastStandard', option.id);
        return;
    }

    handleSelectFileFormat(option) {
        if (!this.state.isFileFormatDirty) {
            this.setState({isFileFormatDirty: true});
        }
        if (option === null) {
            DeliveryActions.updateProfile('fileFormat', null);
            return;
        }
        DeliveryActions.updateProfile('fileFormat', option.id);
        return;
    }

    getHandleSelect(attr) {
        return (value) => {
            let selectedValue = undefined;
            if (value) {
                selectedValue = value.id;
            }
            DeliveryActions.updateProfile(attr, selectedValue);
        };
    }

    render() {
        let selectedBroadcastStandard = DeliveryStore.getBroadcastStandardType(this.state.profile.get('broadcastStandard'));
        let selectedFileFormat = DeliveryStore.getFileFormatType(this.state.profile.get('fileFormat'));
        let notifications = this.state.notifications.map((n, i) => {
            return <Notification disabled={this.props.disabled} index={i} key={i} notification={n}/>;
        });
        return (
            <div className="tab-pane padding-x-20 padding-bottom-20">
                <FormSection title={this.context.intl.messages['accounts.edit.delivery.onboarding.title']}>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['accounts.edit.delivery.fileFormat']}</ControlLabel>
                            <BaseSelect
                                disabled={this.props.disabled}
                                name={this.context.intl.messages['accounts.edit.delivery.fileFormat']}
                                onChange={this.handleSelectFileFormat}
                                options="fileFormat"
                                store={DeliveryStore}
                                value={selectedFileFormat}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['accounts.edit.delivery.broadcastStandard']}</ControlLabel>
                            <BaseSelect
                                disabled={this.props.disabled}
                                name={this.context.intl.messages['accounts.edit.delivery.broadcastStandard']}
                                onChange={this.handleSelectBroadcastStandard}
                                options="broadcastStandard"
                                store={DeliveryStore}
                                value={selectedBroadcastStandard}
                            />
                        </FormGroup>
                    </FormRow>
                </FormSection>
                <FormSection title={this.context.intl.messages['accounts.edit.delivery.metadata.title']}>
                    <Row>
                        <Col md={5}>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['accounts.edit.delivery.metadata.export-spec']}</ControlLabel>
                                <MetadataExportSpec
                                    disabled={this.props.disabled}
                                    onChange={this.getHandleSelect('metadataExportSpec')}
                                    value={this.state.profile.get('metadataExportSpec')}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={5}>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['accounts.edit.delivery.metadata.language']}</ControlLabel>
                                <MetadataExportLanguage
                                    disabled={this.props.disabled}
                                    onChange={this.getHandleSelect('metadataExportPreferredLanguage')}
                                    value={this.state.profile.get('metadataExportPreferredLanguage')}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </FormSection>
                <FormSection title={this.context.intl.messages['accounts.edit.delivery.notifications.title']}>
                    {notifications}
                    <Row>
                        <Col md={4} mdOffset={4}>
                            <Button bsStyle="primary" className="btn-lg btn-block add-notification" onClick={this.handleAdd}>
                                <i className="fas fa-plus"/>&nbsp;{this.context.intl.messages['accounts.edit.delivery.notifications.add']}
                            </Button>
                        </Col>
                    </Row>
                </FormSection>
            </div>
        );
    }

}

export default WithValidations(Container.create(Delivery));
