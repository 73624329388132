/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Modal, ResponsiveEmbed} from 'react-bootstrap';

import {AssetActions, AssetConstants} from '../../../assets/asset-actions';
import {VideoActions} from '../../../assets/video/video-actions';
import VideoStore from '../../../assets/video/video-store';
import {AssetTabActions} from '../../../assets-tab/asset-tab-actions';
import AssetTabStore from '../../../assets-tab/asset-tab-store';
import {Alert} from '../../../common/notification/alert';
import {MODAL_TYPE} from '../../../common/notification/modal';
import {NotificationActions} from '../../../common/notification/notification-actions';
import {GetThumbnail} from '../../../common/utils/utils';
import Player from '../../../player';
import Preloader from '../../../preloader';
import {BatchActions, BatchConstants} from '../../batch-actions';

import WithPermissions from '~/src/decorators/with-permissions';
import SessionStore from '~/src/user/session/session-store';

class AssetDetail extends React.Component {

    static get propTypes() {
        return {
            asset: PropTypes.instanceOf(Immutable.Map).isRequired,
            disabled: PropTypes.bool,
            permissions: PropTypes.object.isRequired,
            type: PropTypes.string.isRequired,
            show: PropTypes.bool.isRequired,
        };
    }

    static get defaultProps() {
        return {
            disabled: false
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static getPermissions() {
        return {
            canVideoPlayback: SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.VIDEO.ALLOW_PLAYBACK_CMS),
        };
    }

    static calculateState() {
        return {
            playableVideo: VideoStore.getState().get('asset'),
            showPlayer: VideoStore.getState().get('showPlayer'),
            videos: AssetTabStore.getState().get('videos')
        };
    }

    static getStores() {
        return [AssetTabStore, VideoStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();
        this.state.loading = true;

        this.handleDelete = this.handleDelete.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
        this.handleLoadError = this.handleLoadError.bind(this);
        this.handleLoadSource = this.handleLoadSource.bind(this);
        this.showPreloader = this.showPreloader.bind(this);
        this.showDeleteDialog = this.showDeleteDialog.bind(this);
    }

    handleDelete() {
        BatchActions.deleteItem(this.props.asset);
        BatchActions.toggleShowItemDetail();
        return;
    }

    handleDownload() {
        const assetId = this.props.asset.get('assetId') || this.props.asset.get('id');

        AssetActions.download(assetId);
    }

    handleShowPlayer(asset) {
        VideoActions.showPlayer(asset);
        return;
    }

    handleHideModal() {
        BatchActions.toggleShowItemDetail();
        NotificationActions.clearQueue();
        AssetTabActions.hide();
    }

    handleLoadError() {
        this.setState(()=>({
            loading: false
        }));
        return;
    }

    handleLoadSource() {
        this.setState(() => ({
            loading: false
        }));
        return;
    }

    showPreloader() {
        this.setState(() => ({
            loading: true
        }));
        return;
    }

    showDeleteDialog() {
        NotificationActions.show(
            MODAL_TYPE.DANGER,
            this.context.intl.messages['scheduling.batch.items-list.confirm-delete.title'],
            this.context.intl.messages[`scheduling.batch.items-list.confirm-delete-${this.props.type.toLowerCase()}.body`],
            this.context.intl.messages['common.confirm'],
            this.handleDelete
        );
    }

    render() {
        let assetRenderer = null;
        let icon = null;
        let asset = this.props.asset;
        const id = asset.get('assetId') || asset.get('id');
        const editsource = `/assets/${this.props.type.toLowerCase()}/${id}`;

        switch (this.props.type) {

        case BatchConstants.BATCH_TYPES.DOCUMENT:
            icon = AssetConstants.ASSET_TYPES.DOCUMENT.icon;
            assetRenderer = (
                <a className="btn bg-navy btn-lg" onClick={this.handleDownload}>
                    <i className="fas fa-download"/> Download
                </a>
            );
            break;

        case BatchConstants.BATCH_TYPES.IMAGE:
            icon = AssetConstants.ASSET_TYPES.IMAGE.icon;
            let imageSrc = asset.get('previewUrl') || asset.getIn(['thumbnails', 0, 'previewUrl']) || '';
            assetRenderer = (
                <Preloader show={this.state.loading} fixed>
                    <img src={imageSrc} onError={this.handleLoadError} onLoad={this.handleLoadSource} style={{maxWidth:'100%'}}/>
                </Preloader>
            );
            break;

        case BatchConstants.BATCH_TYPES.VIDEO:
            icon = AssetConstants.ASSET_TYPES.VIDEO.icon;
            let thumbnailUrl = asset.get('thumbnailMediumUrl');
            if (!thumbnailUrl) {
                let thumbnail = GetThumbnail(asset.get('thumbnails'), 800);
                if (thumbnail) {
                    thumbnailUrl = thumbnail.get('thumbnailUrl');
                }
            }
            if (!thumbnailUrl) {
                // eslint-disable-next-line @typescript-eslint/no-var-requires
                thumbnailUrl = require('../../../layout/placeholder-horizontal.svg');
            }
            if (asset.get('assetId') === undefined) {
                asset = asset.set('assetId', asset.get('id'));
            }


            let playIcon;
            if (this.props.permissions.canVideoPlayback) {
                playIcon = (
                    <span
                        className="thumbnail-play-icon glyphicon glyphicon-play-circle"
                        onClick={this.handleShowPlayer.bind(this, asset)}
                    ></span>
                );
            }

            assetRenderer = (
                <ResponsiveEmbed a16by9>
                    <div>
                        <img
                            className="img-thumbnail"
                            src={thumbnailUrl}
                        />
                        {playIcon}
                    </div>
                </ResponsiveEmbed>
            );

            if (this.state.showPlayer) {
                assetRenderer = (
                    <ResponsiveEmbed a16by9>
                        <Player
                            position="static"
                            src={VideoStore.convertVideoAssetToPlayerSource(this.state.playableVideo)}
                            video={this.state.playableVideo}
                        />
                    </ResponsiveEmbed>
                );
            }
            break;
        }

        return (
            <Modal show={this.props.show} onEnter={this.showPreloader} onHide={this.handleHideModal}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="text-center">{this.context.intl.messages['scheduling.batch.asset-detail']}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <Alert withHeader/>
                    {assetRenderer}
                    <hr/>
                    <h3 style={{wordBreak: 'break-word'}}>
                        <i className={ClassNames(icon)}/>&nbsp;{asset.get('assetName') || asset.get('name')}
                    </h3>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-danger-outline pull-left" onClick={this.showDeleteDialog} disabled={this.props.disabled}>
                        <i className="fas fa-times-circle"/>&nbsp;{this.context.intl.messages['scheduling.batch.asset-detail.delete']}
                    </Button>
                    <Button className="btn btn-primary-outline" href={editsource} target="_blank">
                        <i className="fas fa-pencil-alt"/>&nbsp;{this.context.intl.messages['common.edit']}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const AssetDetailContainer = Container.create(AssetDetail);

export default WithPermissions(AssetDetailContainer);

export {
    AssetDetailContainer as AssetDetail
};
