/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import {v4 as UUID} from 'uuid';

import {GenericFetchAction} from './generic';
import {SelectCues} from '../bl';
import CCEditorStore from '../cc-editor-store';
import * as CCEditorConstants from '../constants';
import {ConvertToVTT} from '../helpers/cues';
import {GenerateRootRoute} from '../helpers/route';

import {VideoActions} from '~/src/assets/video/video-actions';
import VideoStore from '~/src/assets/video/video-store';
import {AlertTypes} from '~/src/common/notification/alert';
import {NotificationActions} from '~/src/common/notification/notification-actions';
import omit from '~/src/common/utils/omit';
import {UploadFile} from '~/src/common/utils/utils';
import Dispatcher from '~/src/dispatcher/dispatcher';
import Request from '~/src/request';
import {RouterActions} from '~/src/router/router-actions';


const not = <T>(predicate: Predicate<T>) => {
    return (val: T) => !predicate(val);
};

class CCEditorActions {
    addCue(gap: CCEditorRegion$Gap) {
        const cue: WBTVDCue = {
            align: 'center',
            endTime: gap.startTime + 2,
            line: 'auto',
            lineAlign: 'end',
            position: 'auto',
            positionAlign: 'auto',
            size: 100,
            snapToLines: true,
            startTime: gap.startTime,
            text: '',
            type: 'cue',
            id: UUID(),
            vertical: 'lr',
            region: null,
            isNew: true,
        };

        Dispatcher.dispatch({
            actionType: 'hardac.cc-editor.change-cues',
            cues: [...CCEditorStore.getState().cues, cue].sort(cueComparator),
        });

        SelectCues([cue]);
    }

    changeCues(changedCues: ReadonlyArray<WBTVDCue>) {
        const cues = CCEditorStore.getState().cues;
        const predicate = createOneOfPredicate(changedCues);
        const old = cues.filter(predicate);
        if (old) {
            Dispatcher.dispatch({
                actionType: 'hardac.cc-editor.commit',
                payload: {
                    type: 'change',
                    oldValue: old,
                    newValue: changedCues.map((cue) => ({...cue, isNew: false})),
                }
            });
        }

        Dispatcher.dispatch({
            actionType: 'hardac.cc-editor.change-cues',
            cues: mergeWith(cues, changedCues)
        });
    }

    createDuplicate(vtt: VideoWebVtt) {
        const duplicate: UnsavedVideoWebVtt = {
            ...omit(vtt, ['videoWebVttId']),
            active: false,
            notes: `Duplicate of ID: ${vtt.videoWebVttId}`,
            parentVideoWebVttId: vtt.parentVideoWebVttId || vtt.videoWebVttId,
            source: 'USER',
            status: 'UNREVIEWED',
        };

        Dispatcher.dispatch({
            actionType: 'hardac.cc-editor.create-duplicate',
            vtt: duplicate,
        });
    }

    clearDuplicate() {
        Dispatcher.dispatch({
            actionType: 'hardac.cc-editor.clear-duplicate',
        });
    }

    fetchVideoById(assetId: string, vttId: string) {
        GenericFetchAction('hardac.cc-editor.find-by-id', async() => {
            await VideoActions.findById(assetId);
            const asset = VideoStore.getState().get('asset');
            await Promise.all([
                await VideoActions.showPlayer(asset),
                await VideoActions.enrichVideoWebVTT(assetId, vttId)
            ]);
        });
    }

    initCues(cues: ReadonlyArray<WBTVDCue>) {
        Dispatcher.dispatch({
            actionType: 'hardac.cc-editor.init-cues',
            cues,
        });
    }

    saveDuplicate() {
        const duplicate = CCEditorStore.getState().duplicate;
        if (!duplicate) {
            return;
        }
        GenericFetchAction('hardac.cc-editor.save-vtt', async() => {
            const resp = await Request.post(`asset/video/${duplicate.videoId}/web-vtt/detail`).send(duplicate).exec();
            const vtt: VideoWebVtt = resp.body;
            const cues = CCEditorStore.getState().cues;
            const file = ConvertToVTT(cues);
            await uploadVttFile(vtt, file, 'POST');

            Dispatcher.dispatch({
                actionType: 'asset.video.add_caption',
                caption: Immutable.fromJS(vtt),
            });

            Dispatcher.dispatch({
                actionType: 'hardac.cc-editor.clear-duplicate',
            });

            RouterActions.redirect(GenerateRootRoute(vtt), true);
            NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'cc-editor.vtt.save.duplicate.success');
        }, 'cc-editor.vtt.save.error');
    }

    saveVTT(vtt: ImmutableMap<VideoWebVtt>, shouldUploadFile: boolean) {
        GenericFetchAction('hardac.cc-editor.save-vtt', async() => {
            let file: Blob | null = null;
            if (shouldUploadFile) {
                const cues = CCEditorStore.getState().cues;
                file = ConvertToVTT(cues);
            }
            await VideoActions.saveSingleVttFile(vtt, file);
        }, 'cc-editor.vtt.save.error');
    }

    shiftCues(time: number) {
        const cues = CCEditorStore.getState().cues;
        if (cues.length === 0) {
            return;
        }
        const firstCueStartTime = cues[0].startTime;
        const delta = time - firstCueStartTime;

        const shiftedCues = cues.map((cue) => {
            const startTime = cue.startTime + delta;
            const endTime = cue.endTime + delta;
            return {...cue, startTime, endTime};
        });

        Dispatcher.dispatch({
            actionType: 'hardac.cc-editor.change-cues',
            cues: shiftedCues,
        });
    }

    removeCues(removedCues: ReadonlyArray<WBTVDCue>) {
        const isEqual = createOneOfPredicate(removedCues);
        const cues = CCEditorStore.getState().cues;
        const originCue = cues.filter(isEqual);

        if (originCue.length) {
            Dispatcher.dispatch({
                actionType: 'hardac.cc-editor.commit',
                payload: {type: 'remove', origin: originCue},
            });
        }
        Dispatcher.dispatch({
            actionType: 'hardac.cc-editor.change-cues',
            cues: cues.filter(not(isEqual)),
        });
    }

    uploadFile(vtt: VideoWebVtt, file: File) {
        GenericFetchAction('hardac.cc-editor.save-vtt', async() => {
            await uploadVttFile(vtt, file, 'PUT');
            NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'cc-editor.vtt.save.success');
            VideoActions.enrichVideoWebVTT(vtt.videoId, vtt.videoWebVttId);
        }, 'cc-editor.vtt.save.error');
    }

    updateVttDuplicate<P extends keyof UnsavedVideoWebVtt>(prop: P, value: UnsavedVideoWebVtt[P]) {
        Dispatcher.dispatch({
            actionType: 'hardac.cc-editor.update-duplicate',
            prop,
            value,
        });
    }
}

export default new CCEditorActions();
export {
    CCEditorConstants,
};

function createOneOfPredicate(cues: ReadonlyArray<WBTVDCue>) {
    const cache = asMap(cues);
    return (c: WBTVDCue) => !!cache[c.id];
}

function mergeWith(cues: ReadonlyArray<WBTVDCue>, changedCues: ReadonlyArray<WBTVDCue>) {
    const cache = asMap(changedCues);
    return cues.map((c) => ({...c, ...cache[c.id]})).sort(cueComparator);
}

function cueComparator(a: WBTVDCue, b: WBTVDCue) {
    return a.startTime - b.startTime;
}

function asMap(cues: ReadonlyArray<WBTVDCue>) {
    return Object.fromEntries(cues.map((c) => [c.id, c]));
}

function uploadVttFile(vtt: VideoWebVtt, file: File, method: 'POST' | 'PUT') {
    const uri = `asset/video/${vtt.videoId}/web-vtt/${vtt.videoWebVttId}/file`;
    return UploadFile(method, uri, file);
}
