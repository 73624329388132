/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {UserErrorConstants} from './user-errors-actions';
import Dispatcher from '../../../dispatcher/dispatcher';


class UserErrorsStore extends ReduceStore {
    getInitialState() {
        return Immutable.Map({
            offset: 0,
            showPreloader: false,
            size: 0,
            total: 0,
            userErrors: Immutable.List(),
        });
    }

    reduce(state, action) {
        switch (action.actionType) {

        case UserErrorConstants.CLEAR:
            state = this.getInitialState();
            break;
        case UserErrorConstants.FIND_ERRORS.ERROR:
            state = state.merge({
                showPreloader: false
            });
            break;
        case UserErrorConstants.FIND_ERRORS.START:
            state = state.merge({
                showPreloader: true
            });
            break;
        case UserErrorConstants.FIND_ERRORS.SUCCESS:
            state = state.merge({
                userErrors: action.errors,
                offset: action.offset,
                showPreloader: false,
                size: action.size,
                total: action.total
            });
            break;
        case UserErrorConstants.GET.ERROR:
            state = state.merge({
                showPreloader: false
            });
            break;
        case UserErrorConstants.GET.START:
            state = state.merge({
                showPreloader: true
            });
            break;
        case UserErrorConstants.GET.SUCCESS:
            state = state.merge({
                userErrors: action.errors,
                offset: action.offset,
                showPreloader: false,
                size: action.size,
                total: action.total
            });
            break;
        }
        return state;
    }
}

const store = new UserErrorsStore(Dispatcher);

export default store;
