/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';
import {Alert} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';

import {FilteredNotAppliedModels$, GroupedModelsByActiveStatus, GroupedModelsByActiveStatus$, HasNewlyAssignedModels$, HasSomeModels$, OriginalModelsAsCache$} from './bl/stores';
import AiModelsTable from './table';
import NotAppliedModels from './table/not-applied-models';
import AiModelsActions from '../../ai-models-actions';


import WithRxSubscriptions from '~/src/decorators/with-rx-subscriptions';

type Props = {
    assetId: number,
    filteredNotAppliedModels: ReadonlyArray<VideoMachineLearningProject>,
    hasNewlyAssignedModels: boolean,
    hasSomeModels: boolean,
    models: GroupedModelsByActiveStatus,
    originalModelsAsCache: Dictionary<VideoMachineLearningProject>,
    showAiModelsPreloader: boolean,
}

type Group = keyof GroupedModelsByActiveStatus;

const GROUPS: Group[] = ['active', 'inactive'];

class AiModels extends React.PureComponent<Props> {

    componentDidMount() {
        setTimeout(() => {
            AiModelsActions.load(this.props.assetId);
        });
    }

    private renderAlertForGroup(group: Group) {
        if (group === 'active' && this.props.hasNewlyAssignedModels) {
            return (
                <p className="text-red">
                    <em>
                        <i className="fas fa-exclamation-triangle"/>
                        &nbsp;
                        <FormattedMessage id="asset.edit.ai-models-tab.active.alert" />
                    </em>
                </p>
            );
        }
    }

    render() {
        if (!this.props.hasSomeModels && !this.props.showAiModelsPreloader) {
            return (
                <Alert
                    bsClass="alert alert-danger Mt(20px)"
                >
                    <i className="icon fa fas fa-ban" />
                    <FormattedMessage id="asset.edit.ai-models-tab.messages.no-processable-models" />
                </Alert>
            );
        }

        return (
            <div>
                <h3>
                    <i className="fas fa-robot"/>
                    &nbsp;
                    <FormattedMessage id="asset.edit.ai-models-tab-title" />
                </h3>

                {GROUPS.map((group, idx) => (
                    <AiModelsTable
                        key={group}
                        alert={this.renderAlertForGroup(group)}
                        group={group}
                        models={this.props.models[group]}
                        originalModels={this.props.originalModelsAsCache}
                        showDelimiter={idx !== 0}
                    />
                ))}

                <NotAppliedModels
                    showDelimiter={this.props.models.active.length !== 0 || this.props.models.inactive.length !== 0}
                />
            </div>
        );
    }
}

export default WithRxSubscriptions(AiModels, {
    filteredNotAppliedModels: FilteredNotAppliedModels$,
    hasNewlyAssignedModels: HasNewlyAssignedModels$,
    hasSomeModels: HasSomeModels$,
    models: GroupedModelsByActiveStatus$,
    originalModelsAsCache: OriginalModelsAsCache$,
});
