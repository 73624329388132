/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {HelpPagesConstants} from './help-page-actions';
import Dispatcher from '../dispatcher/dispatcher';

class HelpPageStore extends ReduceStore {
    getInitialState() {
        return Immutable.fromJS({
            helpHtml: '',
            isLoading: false,
            id: undefined,
            originalHelpHtml: '',
            updatedBy: '',
            updatedDate: '',
        });
    }

    reduce(state, action) {
        switch (action.actionType) {
        case HelpPagesConstants.GET.START:
        case HelpPagesConstants.PUT.START:
            state = state.merge({
                isLoading: true
            });
            break;
        case HelpPagesConstants.GET.SUCCESS:
        case HelpPagesConstants.PUT.SUCCESS:
            state = state.merge({
                editMode: false,
                helpHtml: action.helpPage.webPageHtml,
                isLoading: false,
                id: action.helpPage.id,
                originalHelpHtml: action.helpPage.webPageHtml,
                updatedBy: action.helpPage.updatedBy,
                updatedDate: action.helpPage.updatedDate
            });
            break;

        case HelpPagesConstants.GET.ERROR:
            state = this.getInitialState();
            break;
        case HelpPagesConstants.PUT.ERROR:
            state = state.merge({
                isLoading: false
            });
            break;
        }

        return state;
    }
}

export default new HelpPageStore(Dispatcher);
