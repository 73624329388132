/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Checkbox, FormGroup} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';

import VttTable from './vtt-table';
import {CurrentVttFile$, VTTFiles$} from '../../bl';
import {STATUS_TYPES} from '../../constants';
import LabelWithIcon from '../common/label-with-icons';

import {VideoActions} from '~/src/assets/video/video-actions';
import VideoStore, {WebVTTFilesValidations} from '~/src/assets/video/video-store';
import {FormItem, FormRow} from '~/src/common/form/form';
import WithRxSubscriptions from '~/src/decorators/with-rx-subscriptions';

type Props = {
    listOfCaptions: Immutable.List<ImmutableMap<VideoWebVtt>>,
    vtt: ImmutableMap<VideoWebVtt> | undefined
}

class VttDetailsBlock extends React.PureComponent<Props> {
    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props: Props) {
        super(props);

        this.handleNotesChanged = this.handleNotesChanged.bind(this);
        this.handleStatusChanged = this.handleStatusChanged.bind(this);
    }

    private handleNotesChanged(field: keyof VideoWebVtt, value: string) {
        VideoActions.updateVtt(field, this.props.vtt?.get('videoWebVttId'), value);
    }

    private handleStatusChanged(event: React.FormEvent<Checkbox>) {
        if (event.target instanceof HTMLInputElement) {
            let status: VideoWebVttStatusType = STATUS_TYPES.UNREVIEWED;
            if (event.target.checked) {
                status = STATUS_TYPES.PASSEDQC;
            }
            VideoActions.updateVtt('status', this.props.vtt?.get('videoWebVttId'), status);
        }
    }

    render() {
        const vtt = this.props.vtt;
        if (!vtt) {
            return null;
        }
        let activeMessage = 'cc-editor.vtt.inactive';
        if (vtt.get('active')) {
            activeMessage = 'cc-editor.vtt.active';
        }

        const languageCode = normalizeVTTLanguageCode(vtt.get('languageCode'));
        const files = this.props.listOfCaptions.filter((f: ImmutableMap<VideoWebVtt>) => {
            return normalizeVTTLanguageCode(f.get('languageCode')) === languageCode;
        }).toList();

        return (
            <div>
                <FormRow>
                    <FormGroup>
                        <FormItem
                            attr="notes"
                            label={<LabelWithIcon icon="fa-comment-alt-edit" message="cc-editor.vtt.field.notes" />}
                            model={vtt}
                            setter={this.handleNotesChanged}
                            showErrorMessage={true}
                            type="input"
                            validations={WebVTTFilesValidations.notesField.validations}
                        />
                    </FormGroup>
                </FormRow>
                <FormRow>
                    <FormGroup>
                        <LabelWithIcon icon="far fa-eye" message="cc-editor.vtt.field.status" />
                        <Checkbox
                            checked={vtt.get('status') === STATUS_TYPES.PASSEDQC}
                            onChange={this.handleStatusChanged}
                        >
                            <FormattedMessage id="cc-editor.vtt.status.reviewed" />
                        </Checkbox>
                    </FormGroup>
                    <FormGroup>
                        <LabelWithIcon icon="fal fa-power-off" message="cc-editor.vtt.field.active" />
                        <p>
                            <FormattedMessage id={activeMessage} />
                        </p>
                    </FormGroup>
                </FormRow>
                <FormRow>
                    <FormGroup>
                        <LabelWithIcon icon="far fa-closed-captioning" message="cc-editor.vtt.field.type" />
                        <p>
                            {VideoStore.getWebVTTFileType(vtt.get('fileType'))?.get('label')}
                        </p>
                    </FormGroup>
                    <FormGroup>
                        <LabelWithIcon icon="fal fa-language" message="cc-editor.vtt.field.language" />
                        <p>
                            {VideoStore.getLanguage(languageCode)?.get('name')}
                        </p>
                    </FormGroup>
                </FormRow>

                <VttTable files={files} />
            </div>
        );
    }
}

export default WithRxSubscriptions(VttDetailsBlock, {
    listOfCaptions: VTTFiles$,
    vtt: CurrentVttFile$
});


function normalizeVTTLanguageCode(languageCode: string) {
    // This is for the language codes to match with the same enum we use
    // for metadata localization.
    languageCode = (languageCode || '').toUpperCase();
    if (languageCode === 'ENG') {
        // Normalize to two letter code, this will slowly migrate values.
        languageCode = 'EN';
    }
    return languageCode;
}
