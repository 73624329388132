/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

import {SEVERITY} from '../../common/validations/validations';

class ActionsMenu extends Component {
    static get propTypes() {
        return {
            canDelete: PropTypes.bool,
            canEdit: PropTypes.bool,
            canSave: PropTypes.bool,
            children: PropTypes.node,
            deleteText: PropTypes.string,
            hideSaveAndSync: PropTypes.bool,
            onCancel: PropTypes.func,
            onDelete: PropTypes.func,
            onElasticSearchSync: PropTypes.func,
            onSave: PropTypes.func,
            saveIcon: PropTypes.string,
            saveStyle: PropTypes.string,
            saveText: PropTypes.string,
            validations: PropTypes.array,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            canDelete: false,
            canEdit: false,
            canSave: false,
            children: undefined,
            deleteText: undefined,
            hideSaveAndSync: false,
            onCancel: undefined,
            onDelete: undefined,
            onElasticSearchSync: undefined,
            onSave: undefined,
            saveIcon: undefined,
            saveStyle: undefined,
            saveText: undefined,
            validations: [],
        };
    }

    constructor(props) {
        super(props);

        return;
    }

    render() {
        let cancelBtn;
        let deleteBtn;
        let elasticSearchBtn;
        let saveBtn;

        if (this.props.onCancel) {
            cancelBtn = <Button
                bsSize="large"
                className="btn btn-default-outline Mr(3px) Mb(3px)"
                onClick={this.props.onCancel}
            >
                <i className="fas fa-ban"/>&nbsp;{this.context.intl.messages['common.cancel']}
            </Button>;

        }

        if (this.props.onDelete) {
            deleteBtn = <Button
                bsSize="large"
                className="btn btn-danger-outline Mr(3px) Mb(3px)"
                disabled={!this.props.canDelete}
                onClick={this.props.onDelete}
            >
                <i className="fas fa-trash"/>&nbsp;{this.props.deleteText || this.context.intl.messages['common.delete']}
            </Button>;
        }

        if (!this.props.hideSaveAndSync && this.props.onElasticSearchSync) {
            elasticSearchBtn = <Button
                bsSize="large"
                className="btn btn-primary-outline Mr(3px) Mb(3px)"
                disabled={!this.props.canEdit}
                onClick={this.props.onElasticSearchSync}
            >
                <i className="fas fa-database"/>&nbsp;{this.context.intl.messages['elastic-search.sync']}
            </Button>;
        }

        let validationsWithNoWarnings = this.props.validations.filter(validation => validation.severity !== SEVERITY.WARNING); // ignore warnings

        if (!this.props.hideSaveAndSync && this.props.onSave) {
            let icon;

            if (!this.props.canEdit) {
                icon = 'fa-plus';
            } else {
                icon = 'fa-save';
            }

            let style;

            if ((!this.props.canSave || validationsWithNoWarnings.length)) {
                style = 'btn-default-outline';
            } else {
                style = 'btn-success-outline';
            }

            saveBtn = <Button
                bsSize="large"
                className={ClassNames(`Mr(3px) Mb(3px) btn ${this.props.saveStyle || style}`)}
                disabled={!this.props.canSave || validationsWithNoWarnings.length}
                onClick={this.props.onSave}
            >
                <i className={ClassNames(`fas ${this.props.saveIcon || icon}`)}/>&nbsp;{this.props.saveText || this.context.intl.messages['common.save']}
            </Button>;
        }

        return (
            <div className="col-md-6 pull-right text-right padding-bottom-20 no-x-padding text-center-sm">
                {saveBtn}
                {this.props.children}
                {elasticSearchBtn}
                {deleteBtn}
                {cancelBtn}
            </div>
        );
    }
}

export default ActionsMenu;
