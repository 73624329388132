/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React, {Component} from 'react';

require('./search-box.css');

class SearchBox extends Component {

    static get propTypes() {
        return {
            disabled: PropTypes.bool,
            onChange: PropTypes.func.isRequired,
            // Only used for the initial render.
            value: PropTypes.string, /* .isRequired */
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: false,
            value: undefined
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            term: this.props.value,
        };

        this.handleSearchTerm = this.handleSearchTerm.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.value !== nextProps.value) {
            this.setState({
                term: nextProps.value || ''
            });
        }
        return;
    }

    handleSearchTerm(event) {
        let term = event.target.value;

        this.props.onChange(term);

        this.setState({
            term: term
        });

        return;
    }

    render() {
        return (
            <div className="input-group search-input">
                <span className="input-group-addon"><i className="fas fa-search"></i></span>
                <input
                    className="form-control input-lg"
                    disabled={this.props.disabled}
                    onChange={this.handleSearchTerm}
                    placeholder={this.context.intl.messages['common.search']}
                    type="search"
                    value={this.state.term}
                />
            </div>
        );
    }
}

export default SearchBox;
