/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {Col, Modal, Row} from 'react-bootstrap';

import SearchBox from '../../../common/search-box/search-box';

class HotkeysInformationModal extends React.Component {
    static get propTypes() {
        return {
            hotkeysList: PropTypes.array.isRequired,
            onHide: PropTypes.func.isRequired,
            show: PropTypes.bool.isRequired,
            viewingMode: PropTypes.string.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            searchTerm: ''
        };

        this.handleHide = this.handleHide.bind(this);
        this.handleSearchTerm = this.handleSearchTerm.bind(this);
    }

    handleHide() {
        this.props.onHide();
    }

    handleSearchTerm(term) {
        this.setState(() => ({
            searchTerm: term
        }));
    }

    render() {
        let hotkeysList;
        if (this.state.searchTerm === '') {
            hotkeysList = this.props.hotkeysList;
        } else {
            const term = this.state.searchTerm.toLowerCase();
            hotkeysList = this.props.hotkeysList.filter(h => h.description.toLowerCase().indexOf(term) > -1);
        }

        return (
            <Modal
                className={ClassNames({'skin-dark': this.props.viewingMode === 'skin-dark'})}
                onHide={this.handleHide}
                show={this.props.show}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="text-center">
                        {this.context.intl.messages['hardac.timeline.hotkeys.keyboard-shortcuts']}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Row className="padding-bottom-20">
                        <Col md={6} mdOffset={3}>
                            <SearchBox onChange={this.handleSearchTerm} value={this.state.searchTerm}/>
                        </Col>
                    </Row>
                    <Row>
                        {
                            hotkeysList.map((key, i) => (
                                <div key={i} className="centered-content" style={{paddingtop:'5px', paddingBottom:'5px'}}>
                                    <div className="col-lg-2">
                                        <span className="label bg-gray">
                                            {key.defaultKey || key.keyCombo}
                                        </span>
                                    </div>
                                    <div className="col-lg-4">
                                        {key.description}
                                    </div>
                                </div>
                            ))
                        }
                    </Row>
                </Modal.Body>
            </Modal>
        );
    }
}

export default HotkeysInformationModal;
