/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

import {AccountWizardActions, AccountWizardConstants} from './account-wizard-actions';
import AccountWizardStore from './account-wizard-store';
import AddToEventPanel from './add-to-event-panel';
import PermissionsTab from './permissions-tab';
import Summary from './summary';
import {EventActions, EventConstants} from '../../events/event-actions';
import EventStore from '../../events/event-store';

import RouteValidator from '~/src/common/custom-validator/route/route';
import {MODAL_TYPE} from '~/src/common/notification/modal';
import {NotificationActions} from '~/src/common/notification/notification-actions';
import {Tab, Tabs} from '~/src/common/routing-tab/routing-tab';
import {ErrorsWarnings} from '~/src/common/validations/errors-warnings';
import ActionsMenu from '~/src/layout/actions-menu/actions-menu';
import StickyHeader from '~/src/layout/sticky-header/sticky-header';
import PartnerStore from '~/src/partner/partner-store';
import Preloader from '~/src/preloader/';
import PreloaderStore from '~/src/preloader/preloader-store';
import {RouterActions} from '~/src/router/router-actions';
import ClientRepGroupStore from '~/src/security/client-rep-group/client-rep-group-store';

class Review extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            params: PropTypes.object.isRequired,
            route: RouteValidator, // eslint-disable-line react/require-default-props
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            accountWizardProcess: AccountWizardStore.getState().get('accountWizardProcess'),
            accountWizardStoreState: AccountWizardStore.getState(),
            clientRepGroups: ClientRepGroupStore.getState().get('clientRepGroups'),
            defaultPartner: AccountWizardStore.getState().get('defaultPartner'),
            partners: PartnerStore.getState().get('partners'),
            pendingEvents: EventStore.getState().get('events'),
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible'),
            selectedPartners: AccountWizardStore.getState().get('partners'),
            selectedEventId: AccountWizardStore.getState().get('selectedEventId'),
            users: AccountWizardStore.getState().get('users'),
        };
    }

    static getStores() {
        return [EventStore, AccountWizardStore, ClientRepGroupStore, PreloaderStore];
    }

    constructor(props) {
        super(props);

        this.handleDelete = this.handleDelete.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    componentDidMount() {
        AccountWizardActions.findById(this.props.params.id);
        AccountWizardActions.getUserRowValidations(this.props.params.id);

        const params = Immutable.Map ({
            status: [EventConstants.STATUS_TYPES.BEFORE_EVENT.id, EventConstants.STATUS_TYPES.EVENT_RUNNING.id],
            offset: 0,
            size: 9999
        });
        EventActions.get(params);
        return;
    }

    componentDidUpdate(prevProps, prevState) {
        //Setting WBTVD by default for primary and selected partner.
        if (prevState.partners !== this.state.partners && !this.state.selectedPartners.size) {
            const partnersWithPartnerId = this.state.partners.map(partner => ({...partner.toJS(), partnerId: partner.get('id')}));
            setTimeout(() => {
                AccountWizardActions.setPartners(partnersWithPartnerId.filter((partner) => partner.clientId === 'WBTVD'));
                AccountWizardActions.setDefaultPartner(partnersWithPartnerId.find((partner) => partner.clientId === 'WBTVD'));
            }, 100);
        }
    }

    componentWillUnmount() {
        AccountWizardActions.setExceededUsers(false);
    }

    handleCancel() {
        RouterActions.redirect('/accounts/account-wizard/browse');
    }

    handleDelete() {
        AccountWizardActions.delete(this.props.params.id);
    }

    handleSave(createAccounts = false) {
        const defaultPartner = this.state.accountWizardStoreState.get('defaultPartner');
        const partnersData = this.state.accountWizardStoreState.get('partners').toJS().map(p => {
            p.defaultPartner = p.partnerId === defaultPartner.get('id') || p.id === defaultPartner.get('id');
            return p;
        });

        const batch = Immutable.fromJS({
            name: this.state.accountWizardStoreState.get('name'),
            clientRepGroups: this.state.accountWizardStoreState.get('selectedClientRepGroupIds'),
            createAccounts: createAccounts,
            defaultPartner: this.state.accountWizardStoreState.get('defaultPartner'),
            groups: this.state.accountWizardStoreState.get('groups'),
            id: this.props.params.id,
            accountWizardProcess: this.state.accountWizardStoreState.get('accountWizardProcess'),
            partners: Immutable.fromJS(partnersData),
            rows: this.state.users,
            roles: this.state.accountWizardStoreState.get('roles'),
            selectedEventId: this.state.accountWizardStoreState.get('selectedEventId')
        });

        AccountWizardActions.saveBatch(batch);
    }

    render() {
        let actionsMenu;
        let readOnly = false;
        let validationsStores;
        const baseRoute= `/accounts/account-wizard/${this.props.params.id}`;
        const batchName = this.state.accountWizardProcess.get('name');

        //Don't show alerts/warnings for Processed and Processing batches
        const status = this.state.accountWizardProcess.get('status');
        if (status !== AccountWizardConstants.BATCH_STATUS_TYPES.PROCESSED.id &&
            status !== AccountWizardConstants.BATCH_STATUS_TYPES.PROCESSING.id) {
            validationsStores = [AccountWizardStore];
        }

        if (this.state.accountWizardProcess.get('status') === AccountWizardConstants.BATCH_STATUS_TYPES.PROCESSED.id ||
            this.state.accountWizardProcess.get('status') === AccountWizardConstants.BATCH_STATUS_TYPES.PROCESSING.id) {
            readOnly = true;
        }

        const permissionsTab = (
            <Tab route={`${baseRoute}/permissions`} title={this.context.intl.messages['accounts.account-wizard.permissions-title']}>
                <PermissionsTab
                    location={this.props.location}
                    params={this.props.params}
                    readOnly={readOnly}
                    route={this.props.route}
                />
            </Tab>
        );
        let totalUsers = '-';

        if (this.state.users.size) {
            totalUsers = this.state.users.size;
        }

        const validUsers = this.state.users.size - AccountWizardStore.getUserRowValidationsWithSeverity('ERROR');
        let saveModal = () => {
            NotificationActions.show(
                MODAL_TYPE.APPROVE,
                this.context.intl.messages['accounts.account-wizard.confirmation.create.title'],
                this.context.intl.messages['accounts.account-wizard.confirmation.create.caption'],
                this.context.intl.messages['accounts.account-wizard.confirmation.create.confirm'],
                () => {
                    this.handleSave(true);
                }
            );
        };

        if (validUsers < this.state.users.size) {
            saveModal = () => {
                let caption = this.context.intl.messages['accounts.account-wizard.confirmation.create-partial.caption'];
                caption = caption.replace('VALID_USERS', validUsers);
                caption = caption.replace('TOTAL_USERS', totalUsers);
                NotificationActions.show(
                    MODAL_TYPE.WARNING,
                    this.context.intl.messages['accounts.account-wizard.confirmation.create-partial.title'],
                    caption,
                    this.context.intl.messages['accounts.account-wizard.confirmation.create-partial.confirm'],
                    () => {
                        this.handleSave(true);
                    }
                );
            };
        }

        const errorValidations = AccountWizardStore.getValidations(false);

        actionsMenu = <ActionsMenu onCancel={this.handleCancel}>
            <Button
                bsSize="large"
                className="btn btn-primary-outline Mr(3px) Mb(3px)"
                disabled={readOnly}
                onClick={() => this.handleSave(false)}
            >
                <i className="fas fa-save"/>&nbsp;{this.context.intl.messages['common.save']}
            </Button>
            <Button
                bsSize="large"
                className="btn btn-success-outline Mr(3px) Mb(3px)"
                disabled={validUsers === 0 || readOnly || errorValidations.length}
                onClick={saveModal}
            >
                <i className="fa-solid fa-users-medical"/>&nbsp;{this.context.intl.messages['accounts.account-wizard.create-accounts']}
            </Button>
        </ActionsMenu>;


        return (
            <div>
                <Preloader show={this.state.preloaderVisible} fixed loadingDots>
                    <StickyHeader>
                        <div className="col-md-6">
                            <h1>
                                <i className="fas fa-user"></i>&nbsp;{this.context.intl.messages['accounts.account-wizard.title']}
                                &nbsp;<small>{totalUsers} {this.context.intl.messages['accounts.account-wizard.accounts-length']}</small>
                                <small className="d-block padding-y-10">{batchName}</small>
                            </h1>
                        </div>
                        {actionsMenu}
                    </StickyHeader>
                    <section className="content" id="contentContainer">
                        <div className="row">
                            <div className="col-lg-9">
                                <Tabs location={this.props.location} title="title">
                                    <Tab route={baseRoute} title={this.context.intl.messages['events.summary']}>
                                        <Summary params={this.props.params} route={this.props.route} readOnly={readOnly}/>
                                    </Tab>
                                    {permissionsTab}
                                </Tabs>
                            </div>
                            <div className="col-lg-3">
                                <ErrorsWarnings title={this.context.intl.messages['common.errors-warnings-title']} stores={validationsStores} />
                                <AddToEventPanel
                                    pendingEvents={this.state.pendingEvents}
                                    readOnly={readOnly}
                                    selectedEventId={this.state.selectedEventId}
                                />
                            </div>
                        </div>
                    </section>
                </Preloader>
            </div>
        );
    }
}

export default Container.create(Review);
