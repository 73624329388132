/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

export default class LookupStore extends ReduceStore {

    constructor(dispatcher, CONSTANTS) {
        super(dispatcher);
        this.CONSTANTS = CONSTANTS;
    }

    findItemByKey(key, value) {
        return this.getState().get('items').find(i => i.get(key) === value);
    }

    getInitialState() {
        return Immutable.Map({
            items: Immutable.List(),
            loaded: false,
            loading: false,
            offset: 0,
            size: 0,
            total: 0
        });
    }

    isEmpty() {
        let state = this.getState();
        return !state.get('loaded') &&
            !state.get('loading') &&
            !state.get('items').size;
    }

    reduce(state, action) {
        switch (action.actionType) {
        case this.CONSTANTS.GET.ERROR:
            state = state.set('loading', false);
            break;

        case this.CONSTANTS.GET.START:
            state = state.set('loading', true);
            break;

        case this.CONSTANTS.GET.SUCCESS:
            state = state.merge({
                loaded: true,
                loading: false,
                items: action.items,
                offset: action.offset,
                size: action.size,
                total: action.total
            });
            break;
        case this.CONSTANTS.SAVE_OK:
            // if not loaded do nothing
            if (!state.get('loaded')) {
                break;
            }
            // update or add
            // this could be done faster using binarySearch
            // but for small number of items this is ok
            let id = action.item.get('id');
            items = state.get('items').filter( item => {return item.get('id') !== id;}).push(action.item).sortBy(item => {return item.get('name');});
            state = state.merge({
                items: items,
            });
            break;
        case this.CONSTANTS.REMOVE_OK:
            // if not loaded do nothing
            if (!state.get('loaded')) {
                break;
            }
            id = action.removed.get('id');
            let items = state.get('items').filter( item => {return item.get('id') !== id;});
            state = state.merge({
                items: items,
            });
            break;
        }

        return state;
    }
}
