/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';

import StorageHelper from '../utils/storage';

import ResizablePanel, {Props as ResizablePanel$Props} from './index'; // eslint-disable-line import/named

const STORAGE_GROUP_NAME = 'RESIZABLE_PANEL';

type Props = {
    storageKey: string,
} & ResizablePanel$Props;

export default class ResizablePanelWithPreservePositioning extends React.PureComponent<Props> {

    constructor(props: Props) {
        super(props);

        this.handleResizeEvent = this.handleResizeEvent.bind(this);

        this.initialSizes = StorageHelper.get(STORAGE_GROUP_NAME, props.storageKey) || props.panelsSize;
    }

    private initialSizes: number[] = [];

    handleResizeEvent(sizes: number[]) {
        StorageHelper.set(STORAGE_GROUP_NAME, this.props.storageKey, sizes);
    }

    render() {
        return (
            <ResizablePanel {...this.props} panelsSize={this.initialSizes} onResize={this.handleResizeEvent} />
        );
    }
}

