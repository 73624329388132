/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {AssetTitleConstants} from './asset-title-actions';
import Dispatcher from '../../dispatcher/dispatcher';
import {TimelineAssetConstants} from '../timeline/timeline-asset-actions';
import {VideoConstants} from '../video/video-actions';

class AssetTitlesStore extends ReduceStore {

    getInitialState() {
        return Immutable.Map({
            assignedTitles: Immutable.List(),
            titles: Immutable.List(),
            filters: Immutable.Map(),
            originalAssignedTitles: Immutable.List()
        });
    }

    reduce(state, action) {
        switch (action.actionType) {
        case AssetTitleConstants.ADD:
            state = state.updateIn(['assignedTitles'], titles => {
                return titles.push(action.title).sortBy(t => t.get('name'));
            });
            break;
        case AssetTitleConstants.FINDBY.SUCCESS:
            state = state.set('assignedTitles', action.titles);
            state = state.set('originalAssignedTitles', action.titles);
            break;
        case AssetTitleConstants.GET.SUCCESS:
            state = state.merge({
                titles: action.titles,
                offset: action.offset,
                size: action.size,
                total: action.total
            });
            break;
        case AssetTitleConstants.REMOVE:
            state = state.updateIn(['assignedTitles'], titles => {
                return titles.delete(action.index).sortBy(t => t.get('name'));
            });
            break;
        case VideoConstants.VIDEO.SAVE.SUCCESS:
        case TimelineAssetConstants.TIMELINE.SAVE.SUCCESS:
            state = state.merge({
                originalAssignedTitles: state.get('assignedTitles')
            });
            break;
        }

        return state;
    }
}

let store = new AssetTitlesStore(Dispatcher);

export default store;
