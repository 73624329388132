/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import MultipleCuesEditor from './multiple-cues-editor';
import SingleCueEditor from './single-cue-editor';
import {CurrentVttFile$, DuplicateVttFileAsImmutable$, HasChangedCue$, HasChangedVttDescription$, IsDuplicateModalVisible$, IsMasteringVttFile$, SelectedCues$} from '../../bl';
import CCEditorActions from '../../cc-editor-actions';
import CreateVttModal from '../common/create-vtt-modal';

import {MODAL_TYPE} from '~/src/common/notification/modal';
import {NotificationActions} from '~/src/common/notification/notification-actions';
import WithRxSubscriptions from '~/src/decorators/with-rx-subscriptions';
import {RouterActions} from '~/src/router/router-actions';

type Props = {
    duplicateVtt: ImmutableMap<UnsavedVideoWebVtt>,
    hasChangedCues: boolean,
    hasChangedDescription: boolean,
    isDuplicateModalVisible: boolean,
    isMasteringVttFile: boolean,
    selectedCues: ReadonlyArray<WBTVDCue>,
    video: ImmutableMap<PlayableVideoAsset>,
}

class CCEditorHeader extends React.PureComponent<Props> {
    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props: Props) {
        super(props);

        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.handleDuplicateClick = this.handleDuplicateClick.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);
        this.showDuplicateModal = this.showDuplicateModal.bind(this);
    }

    private handleCancelClick() {
        const assetId = this.props.video.get('id');
        RouterActions.redirect(`/assets/video/${assetId}`, true);
    }

    private handleDuplicateClick() {
        if (HasChangedCue$.value) {
            NotificationActions.show(
                MODAL_TYPE.DANGER,
                this.context.intl.messages['common.warning'],
                this.context.intl.messages['cc-editor.confirm.duplicate.unsaved-message'],
                this.context.intl.messages['common.ok'],
                this.showDuplicateModal,
            );
        } else {
            this.showDuplicateModal();
        }
    }

    private handleSaveClick() {
        if (CurrentVttFile$.value) {
            CCEditorActions.saveVTT(CurrentVttFile$.value, this.props.hasChangedCues);
        }
    }

    private showDuplicateModal() {
        const vtt = CurrentVttFile$.value;
        if (vtt) {
            CCEditorActions.createDuplicate(vtt.toJS());
        }
    }

    private renderWorkplace() {
        let workplace = (
            <div className="form-section">
                <em>
                    <FormattedMessage id="cc-editor.header.selection-tips" />
                </em>
            </div>
        );

        if (this.props.isMasteringVttFile) {
            return workplace;
        }

        if (this.props.selectedCues.length === 1) {
            workplace = (
                <SingleCueEditor
                    cue={this.props.selectedCues[0]}
                    frameRate={this.props.video.get('frameRate')}
                />
            );
        } else if (this.props.selectedCues.length > 0) {
            workplace = (
                <MultipleCuesEditor
                    cues={this.props.selectedCues}
                    frameRate={this.props.video.get('frameRate')}
                />
            );
        }

        return workplace;
    }

    render() {
        const hasChanges = this.props.hasChangedCues || this.props.hasChangedDescription;

        return (
            <div
                className="cc-editor-header content-header padding-top-20 padding-x-20 box-header with-border"
                data-testid="cc-editor-header"
            >
                <div className="pull-right h1">
                    <button
                        className="btn btn-primary-outline"
                        data-testid="duplicate-btn"
                        onClick={this.handleDuplicateClick}
                    >
                        <i className="fas fa-copy"/>
                        &nbsp;
                        <FormattedMessage id="cc-editor.header.duplicate" />
                    </button>

                    &nbsp;

                    <button
                        className="btn btn-success-outline"
                        data-testid="save-btn"
                        disabled={!hasChanges || this.props.isMasteringVttFile}
                        onClick={this.handleSaveClick}>
                        <i className="fas fa-save"/>
                        &nbsp;
                        <FormattedMessage id="common.save" />
                    </button>

                    &nbsp;

                    <button className="btn btn-default-outline" onClick={this.handleCancelClick}>
                        <i className="fas fa-times"/>
                        &nbsp;
                        <FormattedMessage id="common.cancel" />
                    </button>
                </div>

                <h1>
                    <i className="fas fa-closed-captioning" />
                    &nbsp;
                    <FormattedMessage id="cc-editor.title" />
                </h1>

                <small>{this.props.video.get('assetName')}</small>

                <CreateVttModal
                    hideTypeSelector
                    hideSourceSelector
                    confirmIcon="fas fa-copy"
                    confirmMessage="cc-editor.duplicate-modal.create"
                    className="skin-dark"
                    description="cc-editor.duplicate-modal.description"
                    onChangeVtt={CCEditorActions.updateVttDuplicate}
                    onHide={CCEditorActions.clearDuplicate}
                    onSave={CCEditorActions.saveDuplicate}
                    show={this.props.isDuplicateModalVisible}
                    title="cc-editor.duplicate-modal.title"
                    vtt={Immutable.fromJS(this.props.duplicateVtt || {})}
                />

                {this.renderWorkplace()}
            </div>
        );
    }
}

export default WithRxSubscriptions(CCEditorHeader, {
    duplicateVtt: DuplicateVttFileAsImmutable$,
    hasChangedCues: HasChangedCue$,
    hasChangedDescription: HasChangedVttDescription$,
    isDuplicateModalVisible: IsDuplicateModalVisible$,
    isMasteringVttFile: IsMasteringVttFile$,
    selectedCues: SelectedCues$,
});
