/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, ControlLabel, FormGroup, Modal} from 'react-bootstrap';
import Select from 'react-select';

import {Debounce} from '../../common/utils/utils';
import {TalentActions} from '../../talent/talent-actions';
import TalentStore from '../../talent/talent-store';
import AssetTalentStore from '../asset-talent-store';

class AddTalentModal extends Component {
    static get propTypes() {
        return {
            onAdd: PropTypes.func.isRequired,
            onHide: PropTypes.func.isRequired,
            show: PropTypes.bool.isRequired,
            viewingMode: PropTypes.string.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        const formatValues = talent => (
            talent.toJS().map(t => {
                t.value = [t.prefix, t.firstName, t.middleName, t.lastName, t.suffix].filter(term =>
                    term
                ).join(' ');
                return t;
            })
        );
        let addedTalents = this.getAddedTalents();
        let talentPool = TalentStore.getState().get('talentList');
        return {
            selected: TalentStore.getState().get('selected'),
            talentList: formatValues(
                talentPool.filter(talent => {
                    return !!addedTalents.findIndex(alreadyAddedTalent => alreadyAddedTalent.get('fullName') === talent.get('fullName')); // cannot use IDs because they don't match once added!
                })
            )
        };
    }

    static getStores() {
        return [TalentStore, AssetTalentStore];
    }

    static getAddedTalents() {
        return AssetTalentStore.getState().get('talent');
    }

    constructor(props) {
        super(props);

        this.handleAdd = this.handleAdd.bind(this);
        this.handleHide = this.handleHide.bind(this);
        this.handleSearchTalent = Debounce(this.handleSearchTalent.bind(this), 200);
    }

    handleAdd() {
        this.props.onAdd(this.state.selected);
        // close the modal.
        this.handleHide();
        return;
    }

    handleHide() {
        // Clear the store so that the modal is empty.
        TalentActions.setSelected(Immutable.List());
        // Hide the modal.
        this.props.onHide();
        return;
    }

    handleSearchTalent(value) {
        TalentActions.get(Immutable.Map({'aka-name': value}));
        return;
    }

    handleSelectTalent(value) {
        if (value === null) {
            value = [];
        }

        TalentActions.setSelected(Immutable.fromJS(value));
        return;
    }

    render() {
        let selected = this.state.selected.toJS();
        // This is so that the ReactSelect renders an empty select.
        if (selected === undefined) {
            selected = [];
        }

        return (
            <Modal onHide={this.handleHide} show={this.props.show} className={ClassNames({'skin-dark': this.props.viewingMode === 'skin-dark'})}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="text-center">{this.context.intl.messages['asset.talent.add.title']}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['asset.talent.add.name']}</ControlLabel>
                        <Select
                            classNamePrefix="dropdown"
                            getOptionLabel={data => data.value}
                            getOptionValue={data => data.talentId}
                            isClearable={true}
                            isMulti={true}
                            name="talent"
                            onChange={this.handleSelectTalent}
                            onInputChange={this.handleSearchTalent}
                            options={this.state.talentList}
                            placeholder={this.context.intl.messages['common.please-select']}
                            value={selected}
                        />
                    </FormGroup>
                </Modal.Body>

                <Modal.Footer>
                    <Button bsStyle="primary" onClick={this.handleAdd}><i className="fas fa-plus-square"></i>&nbsp;{this.context.intl.messages['asset.talent.add.title']}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default Container.create(AddTalentModal);
