/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {AlertTypes} from '../../common/notification/alert';
import {NotificationActions} from '../../common/notification/notification-actions';
import Dispatcher from '../../dispatcher/dispatcher';
import {PreloaderActions} from '../../preloader/preloader-actions';
import Request from '../../request';
import {RouterActions} from '../../router/router-actions';

const CONSTANTS = {
    ERROR: 'edit.domain.error',
    GET: 'edit.domain.get',
    LOAD: 'edit.domain.load',
    SAVE: 'edit.domain.save',
    UPDATE: 'edit.domain.update',
    CLEAR: 'edit.domain.clear'
};

class EditDomainActions {
    get(id) {
        const preloaderSource = 'edit-domain-actions.get';
        PreloaderActions.show(preloaderSource);

        Request.get(`user/domain-whitelist/${id}`).exec().then(res => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.GET,
                id: id
            });
            this.load(res.body);
        }).then(()=>{
            PreloaderActions.hide(preloaderSource);
            return;
        }).catch(err => {
            PreloaderActions.hide(preloaderSource);
            Dispatcher.dispatch({
                actionType: CONSTANTS.ERROR,
                error: err
            });

            switch (err.status) {
            case 404:
                RouterActions.notFound();
                break;
            default:
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
                break;
            }

            return;
        });
    }

    load(domain) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.LOAD,
            domain: domain
        });
        return;
    }

    error(err) {
        if (!err) {err = {status:0, message:''};}
        Dispatcher.dispatch({
            actionType: CONSTANTS.ERROR,
            error: err
        });
        NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'emails.whitelist.edit.error');
        return;
    }

    save(domain) {
        // Set the data to send to the server.
        let domainData = domain.toJS();

        // Assume POST
        let method = Request.post;
        let uri = 'user/domain-whitelist';

        // Check if PUT
        let id, mode;
        if (domainData.id !== undefined) {
            id = domainData.id;
            method = Request.put;
            uri = `user/domain-whitelist/${id}`;
            mode = 'edit';
        } else {
            mode = 'create';
        }

        // Remove extra properties the API doesn't expect
        ['createdBy', 'createdDate', 'displayOnlyPermissionPackageName', 'id', 'updatedBy', 'updatedDate']
            .forEach(param => {
                if (domainData[param]) {delete domainData[param];}
            });

        // conform data from checkboxes
        domainData.adminApproval = 0;
        if (domain.get('adminApproval')) {
            domainData.adminApproval = 1;
        }
        domainData.clientRepApproval = 0;
        if (domain.get('clientRepApproval')) {
            domainData.clientRepApproval = 1;
        }

        const preloaderSource = 'edit-domain-actions.save';
        PreloaderActions.show(preloaderSource);

        method(uri)
            .send(domainData)
            .exec()
            .then((ew) => {
                PreloaderActions.hide(preloaderSource);
                Dispatcher.dispatch({
                    actionType: CONSTANTS.SAVE
                });
                RouterActions.redirect(`/setup/email-whitelists/${ew.body.id}`, true);
                NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, `emails.whitelist.${mode}.success`);
            })
            .catch(err => {
                PreloaderActions.hide(preloaderSource);
                switch (err.status) {
                case 404:
                    NotificationActions.showAlertDanger('emails.whitelist.create.error.unique', domainData.domainName);
                    break;
                // Occurs when not all required fields are set
                case 422:
                    Dispatcher.dispatch({
                        actionType: CONSTANTS.ERROR,
                        error: err
                    });
                    NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'emails.whitelist.create.error.required');
                    break;
                default:
                    Dispatcher.dispatch({
                        actionType: CONSTANTS.ERROR,
                        error: err
                    });
                    NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, `emails.whitelist.${mode}.error`);
                    break;
                }
                return;
            });

    }

    update(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.UPDATE,
            attr: attr,
            value: value
        });
        return;
    }

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
        return;
    }
}

let actions = new EditDomainActions();

export {
    actions as EditDomainActions,
    CONSTANTS as EditDomainConstants
};
