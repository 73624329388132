/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router';

import BrainiacNavbar from './navigation';
import {DashboardActions} from '../dashboard/dashboard-actions';

export default class Header extends React.Component {
    static get propTypes() {
        return {
            applyLastWeekFilter: PropTypes.bool,
            defaultHomescreen: PropTypes.string.isRequired,
            location: PropTypes.shape({
                pathname: PropTypes.string.isRequired
            }).isRequired,
            savedFilters: PropTypes.object
        };
    }

    static get defaultProps() {
        return {
            applyLastWeekFilter: false,
            savedFilters: undefined
        };
    }

    render() {
        let headerLink = (
            <Link to={this.props.defaultHomescreen || DashboardActions.getDashboardLink(this.props.applyLastWeekFilter, this.props.savedFilters)} className="logo">
                <span className="logo-mini"><img alt="logo" src={ require('../img/logomark-white.png') } /></span>
                <span className="logo-lg"><img alt="logo" src={ require('../img/logo-white-220px.png') } /></span>
            </Link>
        );

        if (this.props.location.pathname.match('/hardac')) {
            headerLink = (
                <Link to={this.props.defaultHomescreen || '/hardac/video-inbox?operator=AND'} className="logo">
                    <span className="logo-mini"><img src={ require('../img/hardac-logomark.svg') }/></span>
                    <span className="logo-lg"><img src={ require('../img/hardac-logo.svg') }/></span>
                </Link>
            );
        }

        return (
            <div className="main-header" id="mainHeader">
                {headerLink}
                <BrainiacNavbar location={this.props.location} />
            </div>
        );
    }
}
