/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import React, {PropTypes, Component} from 'react';
import {Button, Modal} from 'react-bootstrap';

import {TitleConstants, TitleActions} from '../../title-actions';

class CascadeChangesModal extends Component {
    static get propTypes() {
        return {
            flags: PropTypes.object.isRequired,
            onClose: PropTypes.func,
            onConfirmSave: PropTypes.func,
            serieNavigation: PropTypes.instanceOf(Immutable.Map).isRequired,
            show: PropTypes.bool,
            title: PropTypes.instanceOf(Immutable.Map).isRequired,
        };
    }

    static get defaultProps() {
        return {
            onClose: () => void 0,
            onConfirmSave: () => void 0,
            show: false,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
        };
    }

    constructor(props) {
        super(props);

        this.checkboxFrom = this.checkboxFrom.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleSaveCascadeChanges = this.handleSaveCascadeChanges.bind(this);
    }

    checkboxFrom(title, titleType, disabled, checked) {
        return (<div key={titleType}>
            <label>
                <input type="checkbox" checked={checked || this.props.flags[titleType]} onChange={this.handleChange.bind(this, titleType)} disabled={disabled}/>
                <strong>&nbsp;{title}</strong>
            </label>
        </div>);
    }

    handleChange(titleType) {
        let newFlags = {};

        newFlags[titleType] = !this.props.flags[titleType];

        switch (titleType) {
        case 'season':
            newFlags.episode = false;
            break;
        case 'serie':
            newFlags.season = !this.props.flags[titleType];
            break;
        case 'mini-serie-episode':
            newFlags.serie = true;
            newFlags.episode = true;
            break;
        }
        const newState = {...this.props.flags, ...newFlags};
        TitleActions.updateCascadeFlags(newState);
    }

    handleCloseModal() {
        TitleActions.updateCascadeFlags({
            episode: false,
            'mini-serie-episode': false,
            season: false,
            serie: false
        });
        this.props.onClose();
    }

    handleSaveCascadeChanges() {
        this.handleCloseModal();
        this.props.onConfirmSave();
    }

    render() {
        const titleCategoryType = TitleConstants.TITLE_CATEGORY_GROUPS_DESC[this.props.title.get('categoryGroup')];

        let checkboxes = [];
        let checkboxesContainer;
        let episodeNumber;
        let seasonNumber;
        let sn;
        if (this.props.title.get('id')) {
            sn = this.props.serieNavigation.getIn(['serieMap', this.props.title.get('id').toString()]);
        }
        let titleName = this.props.title.get('displayName');

        switch (this.props.title.get('categoryGroup')) {
        case TitleConstants.TITLE_CATEGORY_GROUPS.EPISODE:
            if (this.props.serieNavigation.get('seasons') && this.props.serieNavigation.get('seasons').size) {
                seasonNumber = 'X';
                episodeNumber = 'X';
                if (sn) {
                    seasonNumber = this.props.serieNavigation.getIn(['seasons', sn.get(0), 'seasonNumber']);
                    episodeNumber = this.props.serieNavigation.getIn(['seasons', sn.get(0), 'episodes', sn.get(1), 'episodeNumber']);
                }
                titleName = `${this.props.title.get('name')} (${this.props.serieNavigation.get('titleName')} - S${seasonNumber}E${episodeNumber})`;
                checkboxes = [this.checkboxFrom(titleName, 'episode', true, true),
                    this.checkboxFrom(`${this.props.serieNavigation.get('titleName')} - Series`, 'serie', false),
                    this.checkboxFrom(`${this.props.serieNavigation.get('titleName')} - Season ${seasonNumber}`, 'season', this.props.flags.serie, this.props.flags.serie)];
            } else if (this.props.serieNavigation.get('episodes')) {
                miniSerieEpisodeCheckbox = this.checkboxFrom(`All Episodes of ${this.props.title.get('parentTitleDisplayName')}`, 'mini-serie-episode', false);
                checkboxes = [this.checkboxFrom(`${this.props.title.get('name')}`, 'serie', true, true), miniSerieEpisodeCheckbox];
            }
            break;
        case TitleConstants.TITLE_CATEGORY_GROUPS.MINI_SERIES:
            let miniSerieEpisodeCheckbox = '';
            if (this.props.serieNavigation.get('episodes')) {
                miniSerieEpisodeCheckbox = this.checkboxFrom(`All Episodes of ${this.props.title.get('name')}`, 'mini-serie-episode', false);
            }
            checkboxes = [this.checkboxFrom(`${this.props.title.get('name')}`, 'serie', true, true), miniSerieEpisodeCheckbox];
            break;
        case TitleConstants.TITLE_CATEGORY_GROUPS.SEASON:
            seasonNumber = 'X';
            if (sn) {
                seasonNumber = this.props.serieNavigation.getIn(['seasons', sn.get(0), 'seasonNumber']);
            }
            checkboxes = [this.checkboxFrom(`${this.props.title.get('name')} - Season ${seasonNumber}`, 'season', true, true),
                this.checkboxFrom(`${this.props.title.get('name')} - Series`, 'serie', false),
                this.checkboxFrom(`All Episodes of ${this.props.title.get('name')} Season ${seasonNumber}`, 'episode', false)];
            break;
        case TitleConstants.TITLE_CATEGORY_GROUPS.SERIES:
            checkboxes = [this.checkboxFrom(`${this.props.title.get('name')}`, 'serie', true, true),
                this.checkboxFrom(`All Seasons of ${this.props.title.get('name')}`, 'season', false),
                this.checkboxFrom(`All Episodes of ${this.props.title.get('name')}`, 'episode', !this.props.flags.season)];
            break;
        }

        if (checkboxes.length > 0) {
            checkboxesContainer = <div className="margin-bottom-20">
                {checkboxes}
            </div>;
        }

        return (
            <Modal show={this.props.show} onHide={this.handleCloseModal}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="text-center">{this.context.intl.messages['titles.create.summary.cascade.title']}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p>{this.context.intl.messages['titles.create.summary.cascade.suggest-line.one'].replace('{}', titleCategoryType)}</p>
                        <p>{this.context.intl.messages['titles.create.summary.cascade.suggest-line.two']}:</p>
                    </div>
                    {checkboxesContainer}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        bsStyle="default" bsSize="large" className="pull-left"
                        onClick={this.handleCloseModal}>{this.context.intl.messages['common.cancel']}
                    </Button>
                    <Button
                        bsStyle="primary" bsSize="large" className="pull-right"
                        onClick={this.handleSaveCascadeChanges}>{this.context.intl.messages['titles.create.summary.cascade.save']}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default CascadeChangesModal;
