/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {FormatTitleReleaseDate, LanguageCodeType} from '@wbdt-sie/brainiac-web-common';
import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, Col, ControlLabel, FormGroup, Modal, Row} from 'react-bootstrap';
import {Link} from 'react-router';
import ReactSelect from 'react-select';
import url from 'url';

import CascadeChangesModal from './cascade-changes-modal';
import Catalogs from './catalogs/catalogs';
import EventCatalogs from './catalogs/event-catalogs';
import ConfirmUpdatedSaveModal from './confirm-updated-save-modal';
import DashboardTable from './dashboard-table';
import Local from './local';
import Related from './related/related';
import {RelatedActions} from './related/related-actions';
import RelatedStore from './related/related-store';
import Style from './style/style';
import Subscriptions from './subscriptions/subscriptions';
import Summary from './summary/summary';
import Talent from './talent/talent';
import Users from './users/users';
import {AssetConstants} from '../../assets/asset-actions';
import {AssetTabActions} from '../../assets-tab/asset-tab-actions';
import AssetTabStore from '../../assets-tab/asset-tab-store';
import Assets from '../../assets-tab/assets';
import AddTabModal from '../../common/add-tab-modal';
import BaseSelect from '../../common/base-select/base-select';
import RouteValidator from '../../common/custom-validator/route/route';
import DocumentTitle from '../../common/document-title';
import {DownloadActions} from '../../common/download/download-actions';
import {MODAL_TYPE} from '../../common/notification/modal';
import {NotificationActions} from '../../common/notification/notification-actions';
import Panel from '../../common/panel/panel';
import {History, SideNotes} from '../../common/side-notes/side-notes';
import {GetAvailableTabs, GetTabDataByValue} from '../../common/utils/tab-utils';
import {AlertsWarnings} from '../../common/validations/alerts-warnings';
import {SEVERITY} from '../../common/validations/validations';
import Config from '../../config/config.js';
import StatusDialog from '../../dashboard/status-dialog';
import ActionsMenu from '../../layout/actions-menu/actions-menu';
import StickyHeader from '../../layout/sticky-header/sticky-header';
import Preloader from '../../preloader';
import PreloaderStore from '../../preloader/preloader-store';
import {RouterActions} from '../../router/router-actions';
import {ActionHistoryActions, ActionHistoryConstants} from '../../system/action-history/action-history-actions';
import SessionStore from '../../user/session/session-store';
import {EpisodeNavigation, SerieNavigation} from '../serie-navigation';
import SerieNavigationStore from '../serie-navigation/serie-navigation-store';
import {TitleActions, TitleConstants} from '../title-actions';
import {TitleLocalizedActions} from '../title-localized-actions';
import TitleLocalizedStore from '../title-localized-store';
import TitleStatusStore from '../title-status-store';
import TitleStore from '../title-store';

import {STATION_TYPE} from '~/src/assets/document/document-actions';

class Create extends Component {

    static get propTypes() {
        return {
            children: PropTypes.array.isRequired,
            location: PropTypes.object.isRequired,
            params: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired,
            route: RouteValidator
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }


    static get defaultProps() {
        return {
            route: undefined
        };
    }

    static getPermissions() {
        return {
            assets: {
                canBrowse: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.ASSETS.BROWSE),
                canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.ASSETS.EDIT), // to reorder, nest & add
            },
            audio: {
                canBrowse: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.SHOW.AUDIO),
            },
            videos: {
                canBrowse: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.SHOW.VIDEOS),
            },
            images: {
                canBrowse: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.SHOW.IMAGES),
            },
            merchandise: {
                canBrowse: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.SHOW.MERCHANDISE),
            },
            documents: {
                canBrowse: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.SHOW.DOCUMENTS),
            },
            scripts: {
                canBrowse: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.SHOW.SCRIPTS),
            },
            events: {
                canBrowse: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.SHOW.EVENTS),
            },
            canCreate: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.CREATE),
            canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.EDIT),
            canMarkForDelete: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.MARK_FOR_DELETE),
            canViewMetadataStatus: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.EDIT_METADATA_IN_TITLE_PAGE.STATUS.VIEW),
            canEditMetadataStatus: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.EDIT_METADATA_IN_TITLE_PAGE.STATUS.EDIT),
            canViewAdditionalInfo: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.ACCORDIONS.ADDITIONAL_INFO),
            canViewAlternativeTitles: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.ACCORDIONS.ALTERNATIVE_TITLES),
            canViewLanguages: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.ACCORDIONS.LANGUAGES),
            canViewLinks: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.ACCORDIONS.LINKS),
            canViewMasteringInfo: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.ACCORDIONS.MASTERING_INFO),
            canViewNonTheatricalRights: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.ACCORDIONS.NON_THEATRICAL_RIGHTS),
            canViewProductionAndDistribution: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.ACCORDIONS.PRODUCTION_DISTRIBUTION),
            canViewReleases: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.ACCORDIONS.RELEASES),
            canViewRights: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.ACCORDIONS.RIGHTS),
            canViewSynopsisLogLines: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.ACCORDIONS.SYNOPSIS_LOG),
            localized: {
                canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.SHOW.LOCAL),
                canRead: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.LOCALIZED.READ)
            },
            catalogs: {
                canBrowse: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.SHOW.CATALOGS),
                canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.CATALOGS.EDIT)
            },
            languageCatalogs: {
                canBrowse: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.SHOW.LOCAL),
                canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.CATALOGS.EDIT)
            },
            related: {
                canBrowse: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.SHOW.RELATED),
                canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.RELATED.EDIT)
            },
            stations: {
                canBrowse: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.SHOW.STATIONS)
            },
            style: {
                canBrowse: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.SHOW.STYLE),
                canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.STYLE.EDIT)
            },
            subscriptions: {
                canBrowse: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.SHOW.SUBSCRIPTIONS),
                canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.SUBSCRIPTIONS.EDIT)
            },
            talent: {
                canBrowse: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.SHOW.TALENT),
                canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.TALENT.EDIT)
            },
            users: {
                canBrowse: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.SHOW.USERS),
                canRead: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.READ)
            }
        };
    }

    static calculateState() {
        return {
            assets: AssetTabStore.getState().get('assets'),
            cascadeFlags: TitleStore.getState().getIn(['title', 'cascadeFlags']),
            originalTitleStyle: TitleStore.getState().get('originalTitleStyle'),
            originalTitle: TitleStore.getState().get('originalTitle'),
            parentReleases: TitleStore.getState().get('parentReleases'),
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible'),
            serieNavigation: SerieNavigationStore.getState().get('serieNavigation'),
            title: TitleStore.getState().get('title'),
            titleStyle: TitleStore.getState().get('titleStyle'),
            titleValidated: TitleStore.getState().get('titleValidated'),
            showPreloader: TitleStore.getState().get('showPreloader'),
            showTabsPreloader: TitleStore.getState().get('showTabsPreloader'),
            titleLocalized: TitleLocalizedStore.getState().get('titleLocalized'),
            originalTitleLocalized: TitleLocalizedStore.getState().get('originalTitleLocalized'),
            currentLanguage: TitleLocalizedStore.getState().get('currentLanguage'),
            showAddNew: TitleLocalizedStore.getState().get('showAddNew'),
            newLanguage: TitleLocalizedStore.getState().get('newLanguage'),
            field: TitleStatusStore.getState().get('field'),
            showStatusModal: TitleStatusStore.getState().get('show'),
            updatedDate: TitleStore.getState().get('updatedDate')
        };
    }

    static getStores() {
        return [AssetTabStore, PreloaderStore, SerieNavigationStore, TitleStore, TitleLocalizedStore, TitleStatusStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            activeTab:0,
            showAddTabModal: false,
            showCascadeChangesModal: false,
            showStatusDashboard: false,
        }, this.constructor.calculateState());

        this.addNote = this.addNote.bind(this);
        this.getActiveAssetTab = this.getActiveAssetTab.bind(this);
        this.getHasStyle = this.getHasStyle.bind(this);
        this.isActiveTab = this.isActiveTab.bind(this);
        this.isDirty = this.isDirty.bind(this);
        this.handleAddNewLocalization = this.handleAddNewLocalization.bind(this);
        this.handleAddTab = this.handleAddTab.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleCancelButton = this.handleCancelButton.bind(this);
        this.handleElasticSearchSync = this.handleElasticSearchSync.bind(this);
        this.handleExportMetadata = this.handleExportMetadata.bind(this);
        this.handleHideUpdatedSaveModal = this.handleHideUpdatedSaveModal.bind(this);
        this.handleLocalizationChange = this.handleLocalizationChange.bind(this);
        this.handleMarkForDeletion = this.handleMarkForDeletion.bind(this);
        this.handleNewLocalizationChange = this.handleNewLocalizationChange.bind(this);
        this.handleProceedSave = this.handleProceedSave.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleSelectTitleRelationshipType = this.handleSelectTitleRelationshipType.bind(this);
        this.handleToggleDashboard = this.handleToggleDashboard.bind(this);
        this.hasChanged = this.hasChanged.bind(this);
        this.showAddLocalization = this.showAddLocalization.bind(this);
        this.handleToggleShowAddTabModal = this.handleToggleShowAddTabModal.bind(this);
        this.onHide = this.onHide.bind(this);
        this.toTab = this.toTab.bind(this);
        this.toggleCascadeChangesModal = this.toggleCascadeChangesModal.bind(this);
        this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    }

    componentWillMount() {
        if (RelatedStore.getState().get('titleId') !== this.props.params.id) {
            RelatedActions.clear();
        }

        /* istanbul ignore next */
        if (this.props.location.query && this.props.location.query.parentId) {
            TitleActions.cloneParent();
        } else {
            TitleActions.clear();
            TitleLocalizedActions.clear();
        }
        return;
    }

    componentDidMount() {
        this.init(this.props);
        /* istanbul ignore next */
        RouterActions.registerRedirectCheck((location) => {
            return this.isDirty(location);
        });
        return;
    }

    shouldComponentUpdate(prevProps) {
        return this.props.params.id !== prevProps.params.id ||
            this.props.route.mode !== prevProps.route.mode ||
            this.props.location.query.parentId !== prevProps.location.query.parentId;
    }

    /* istanbul ignore next */
    componentDidUpdate(prevProps) {
        RouterActions.registerRedirectCheck((location) => {
            return this.isDirty(location);
        });
        if (prevProps.route.mode !== this.props.route.mode ||
            prevProps.params.id !== this.props.params.id ||
            prevProps.location.query.parentId !== this.props.location.query.parentId) {
            if (prevProps.location.query.parentId) {
                TitleActions.cloneParent();
            } else {
                TitleActions.clear();
                TitleLocalizedActions.clear();
            }
            this.init(this.props);
            RelatedActions.clear();
        }
        return;
    }

    componentWillUnmount() {
        AssetTabActions.clear();
        return;
    }

    addNote(description) {
        ActionHistoryActions.addNote(parseInt(this.props.params.id, 10), description, ActionHistoryConstants.ACTION_OBJECTS.TITLE);
    }

    /* istanbul ignore next */
    airDate(releases, messages) {
        if (!releases || !releases.size) {return;}

        const release = releases.first();

        return (
            <span>&nbsp;({FormatTitleReleaseDate(release, messages, true)})</span>
        );
    }

    /* istanbul ignore next */
    getActiveAssetTab() {
        let pathname = this.props.location.pathname;
        switch (true) {
        case !!(pathname.match(/\/images$/)):
            return AssetConstants.ASSET_MAIN_TYPES.IMAGE;
        case !!(pathname.match(/\/videos$/)):
            return AssetConstants.ASSET_MAIN_TYPES.VIDEO;
        case !!(pathname.match(/\/documents$/)):
            return AssetConstants.ASSET_MAIN_TYPES.DOCUMENT;
        case !!(pathname.match(/\/stations$/)):
            return STATION_TYPE;
        case !!(pathname.match(/\/audio$/)):
            return AssetConstants.ASSET_MAIN_TYPES.AUDIO;
        case !!(pathname.match(/\/merchandise$/)):
            return AssetConstants.ASSET_MAIN_TYPES.MERCHANDISE;
        case !!(pathname.match(/\/scripts$/)):
            return AssetConstants.ASSET_MAIN_TYPES.SCRIPT;
        }
        return null;
    }

    getAssetTabs() {
        const alwaysActiveAssets = ['image', 'video', 'document', 'station', 'script'];
        /* istanbul ignore next */
        const activeAssets = ['audio', 'merchandise'].filter(t => {
            let assetType = this.state.assets.get(t);
            if (t === 'video') {
                assetType = assetType.mergeDeepWith((oldVal, newVal) => {
                    return !!(oldVal+newVal);
                }, this.state.assets.get('video-timeline'));
            }
            return ['active', 'inactive'].some(a => !!assetType.get(a).size);
        });
        return [...alwaysActiveAssets, ...activeAssets];
    }

    /* istanbul ignore next */
    getHasStyle() {
        const hasBackgroundColor = !!this.state.title.get('appBackgroundColor');
        const hasTextColor = !!this.state.title.get('appTextColor');
        const hasBackgroundImage = !!this.state.title.get('appBackgroundImageS3Path');
        const hasBackgroundVideo = !!this.state.title.get('appBackgroundVideoS3Path');
        const hasBackgroundTitleTreatment = !!this.state.title.get('appBackgroundTitleTreatmentS3Path');
        return hasBackgroundColor || hasTextColor || hasBackgroundImage || hasBackgroundVideo || hasBackgroundTitleTreatment;
    }

    handleAddNewLocalization() {
        TitleLocalizedActions.addNew(this.state.newLanguage, this.state.title.get('synopsisValues'));
        TitleLocalizedActions.addNewHide();
    }

    handleAddTab(tab) {
        RouterActions.redirect(`/titles/${this.props.params.id}/${tab.route}`);
    }

    handleCancel() {
        RouterActions.redirect('/titles', true);
    }

    /* istanbul ignore next */
    handleCancelButton() {
        // If we are in an "add related title" workflow, cancel goes back to parent title
        if (this.props.location.query && this.props.location.query.parentId) {
            RouterActions.redirect(`/titles/${this.props.location.query.parentId}/related`);
        } else {
            RouterActions.redirect('/titles');
        }
    }

    handleElasticSearchSync() {
        TitleActions.syncElasticSearch(this.props.params.id);
    }

    handleExportMetadata() {
        DownloadActions.startDownloadExecution(`title/${this.props.params.id}/export-master-meta-data`, {
            language: this.state.currentLanguage
        });
    }

    handleLocalizationChange(value) {
        TitleLocalizedActions.setCurrentLanguage(value.value);
        RouterActions.redirect(`/titles/${this.props.params.id}/`);
    }

    handleNewLocalizationChange(value) {
        TitleLocalizedActions.setNewLanguage(value.value);
        RouterActions.redirect(`/titles/${this.props.params.id}/`);
    }

    /* istanbul ignore next */
    handleProceedSave() {
        const hasValidationErrors = TitleStore.getValidations().concat(
            TitleLocalizedStore.getValidations()
        ).filter(
            v => v.severity === SEVERITY.ALERT
        ).length;

        if (!hasValidationErrors) {
            let options;

            const title = this.state.title;

            if (title.get('id') !== undefined) {
                options = {
                    messages: {
                        error: 'titles.edit.error',
                        success: 'titles.edit.success',
                    }
                };
            }

            let assets = false;
            let originalAssets = AssetTabStore.getState().get('originalAssets');
            let currentAssets = AssetTabStore.getState().get('assets');

            if (originalAssets !== currentAssets) {
                assets = currentAssets;
            }

            let originalRelatedTitles = RelatedStore.getState().get('original');
            let relatedTitles = RelatedStore.getState().get('related');

            if (this.props.location.query &&
                this.props.location.query.parentId) {
                TitleActions.saveAndConnect(
                    title,
                    TitleStore.getState().get('originalTitle'),
                    assets, AssetTabStore.getState().get('activatedAssets'),
                    AssetTabStore.getState().get('deactivatedAssets'),
                    relatedTitles, originalRelatedTitles,
                    this.state.titleLocalized,
                    this.state.originalTitleLocalized,
                    this.state.titleStyle, this.state.originalTitleStyle,
                    this.state.addNewRelationshipType,
                    this.props.location.query.parentId,
                );
            } else {
                TitleActions.save(
                    title,
                    TitleStore.getState().get('originalTitle'),
                    assets, AssetTabStore.getState().get('activatedAssets'),
                    AssetTabStore.getState().get('deactivatedAssets'),
                    relatedTitles, originalRelatedTitles,
                    this.state.titleLocalized,
                    this.state.originalTitleLocalized,
                    this.state.titleStyle, this.state.originalTitleStyle,
                    options,
                );
            }
        }
    }

    handleRemoveLocalization() {
        TitleLocalizedActions.removeCurrentLanguage();
    }

    handleSave() {
        // BRAIN-3328 disables the save notification until the feature is reworked
        // if (this.state.title.get('id') !== undefined) {
        //     TitleActions.getUpdateDataById(this.state.title.get('id'), this.handleProceedSave);
        //     return;
        // }

        if (this.props.route.mode === 'edit' && TitleStore.hasCascadeChanges(this.state.title, this.state.originalTitle)) {
            this.toggleCascadeChangesModal();
        } else {
            this.handleProceedSave();
        }
    }

    toggleCascadeChangesModal() {
        this.setState(/* istanbul ignore next */ prevState => ({
            showCascadeChangesModal: !prevState.showCascadeChangesModal
        }));
    }

    handleSelectTitleRelationshipType(value) {
        this.setState({
            addNewRelationshipType: Immutable.fromJS(value)
        });
        switch (value.id) {
        case TitleConstants.TITLE_RELATIONSHIP_TYPE.HAS_REPACKAGED_FOR_SALES.id:
            TitleActions.updateTitle('releases', this.state.parentReleases);
            break;
        default:
            TitleActions.updateTitle('releases', Immutable.List());
            break;
        }
        return;
    }

    handleHideUpdatedSaveModal() {
        this.setState(/* istanbul ignore next */ () => ({
            updatedDate: undefined
        }));
    }

    handleToggleShowAddTabModal() {
        this.setState(/* istanbul ignore next */ prevState => ({
            showAddTabModal: !prevState.showAddTabModal
        }));
    }

    handleToggleDashboard() {
        this.setState(/* istanbul ignore next */ prevState => ({
            showStatusDashboard: !prevState.showStatusDashboard
        }));
    }

    /* istanbul ignore next */
    hasChanged() {
        if (this.props.route.mode === 'create') {
            return false;
        }
        let title = this.state.title
            .updateIn(['subscriptions'], subscriptions => {
                return subscriptions.map( subscription => {
                    return subscription.map( s => {
                        return s.delete('suggestedByUserName').delete('userName');
                    });
                });
            });

        let originalTitle = TitleStore.getState().get('originalTitle')
            .updateIn(['subscriptions'], subscriptions => {
                return subscriptions.map( subscription => {
                    return subscription.map( s => {
                        return s.delete('suggestedByUserName').delete('userName');
                    });
                });
            });

        let assets = AssetTabStore.getState().get('assets');
        let originalAssets = AssetTabStore.getState().get('originalAssets');

        let titleLocalized = TitleLocalizedStore.getState().get('titleLocalized');
        let originalTitleLocalized = TitleLocalizedStore.getState().get('originalTitleLocalized');

        const titleStyle = TitleStore.getState().get('titleStyle');
        const originalTitleStyle = TitleStore.getState().get('originalTitleStyle');

        if (!originalTitle.get('id')) {
            return false;
        }

        return !title.equals(originalTitle) ||
            !assets.equals(originalAssets) ||
            !titleLocalized.equals(originalTitleLocalized) ||
            !RelatedStore.getState().get('related').equals(RelatedStore.getState().get('original')) ||
            !titleStyle.equals(originalTitleStyle);
    }

    /* istanbul ignore next */
    init(props) {
        if (props.route.mode === 'edit') {
            TitleActions.findById(props.params.id, this.state.serieNavigation);
            if (props.permissions.localized.canRead) {
                TitleLocalizedActions.findById(props.params.id);
            } else {
                TitleLocalizedActions.clear();
            }
            // Assets tabs
            if (props.permissions.assets.canBrowse) {
                AssetTabActions.get(props.params.id, 'title');
            } else {
                AssetTabActions.clear();
            }
        }
    }

    /* istanbul ignore next */
    isActiveTab(tabName) {
        let isActive = false;
        let pathname = this.props.location.pathname;

        switch (true) {
        case !!(pathname.match(/\/style$/)):
            isActive = tabName === 'style';
            break;
        case !!(pathname.match(/\/images$/)):
            isActive = tabName === 'image';
            break;
        case !!(pathname.match(/\/videos$/)):
            isActive = tabName === 'video';
            break;
        case !!(pathname.match(/\/documents$/)):
            isActive = tabName === 'document';
            break;
        case !!(pathname.match(/\/stations$/)):
            isActive = tabName === 'station';
            break;
        case !!(pathname.match(/\/audio$/)):
            isActive = tabName === 'audio';
            break;
        case !!(pathname.match(/\/merchandise$/)):
            isActive = tabName === 'merchandise';
            break;
        case !!(pathname.match(/\/scripts$/)):
            isActive = tabName === 'script';
            break;
        case !!(pathname.match(/\/catalogs$/)):
            isActive = tabName === 'catalogs';
            break;
        case !!(pathname.match(/\/events$/)):
            isActive = tabName === 'events';
            break;
        case !!(pathname.match(/\/local$/)):
            isActive = tabName === 'local';
            break;
        case !!(pathname.match(/\/related$/)):
            isActive = tabName === 'related';
            break;
        case !!(pathname.match(/\/subscriptions$/)):
            isActive = tabName === 'subscriptions';
            break;
        case !!(pathname.match(/\/talent$/)):
            isActive = tabName === 'talent';
            break;
        case !!(pathname.match(/\/users$/)):
            isActive = tabName === 'users';
            break;
        case !!(pathname.match(/\/history$/)):
            isActive = tabName === 'history';
            break;
        default:
            isActive = tabName === 'summary';
        }

        if (isActive) {
            return 'active';
        }

        return;
    }

    /* istanbul ignore next */
    isDirty(location) {
        if (location) {
            let goingTo = location.pathname.split('/');
            let current = this.props.location.pathname.split('/');
            if (goingTo[1] === current[1] && /* entity */
                goingTo[2] === current[2] /* entity id */) {
                return false; // just changing tabs no confirmation needed.
            }
        }
        return this.hasChanged();
    }

    /* istanbul ignore next */
    onHide() {
        TitleLocalizedActions.addNewHide();
    }

    /* istanbul ignore next */
    showAddLocalization() {
        TitleLocalizedActions.addNewShow();
    }

    /* istanbul ignore next */
    toTab(tabName, tabUrl, title, disabled, className) {
        if (tabUrl) {
            tabUrl = `/${tabUrl}`;
        } else {
            tabUrl = '';
        }
        if (disabled) {
            return (
                <li key={tabName} className={ClassNames(this.isActiveTab(tabName), className)}>
                    <a className="tab-disabled">
                        {this.context.intl.messages[title]}
                    </a>
                </li>
            );
        }
        return (
            <li key={tabName} className={ClassNames(this.isActiveTab(tabName), className)}>
                <Link to={`/titles/${this.props.params.id}${tabUrl}`}>
                    {this.context.intl.messages[title]}
                </Link>
            </li>
        );
    }

    /* istanbul ignore next */
    toTabByConstantValue(tab, disabled) {
        return this.toTab(tab.value, tab.route, tab.message, disabled);
    }

    /* istanbul ignore next */
    handleMarkForDeletion() {
        TitleActions.markForDeletion(this.state.title.get('id'));
    }

    /* istanbul ignore next */
    toggleDeleteModal() {
        NotificationActions.show(
            MODAL_TYPE.DANGER,
            this.context.intl.messages['titles.edit.delete.confirm-delete.title'],
            this.context.intl.messages['titles.edit.delete.confirm-delete.body'],
            this.context.intl.messages['titles.edit.delete.confirm-delete.confirm'],
            this.handleMarkForDeletion
        );
    }

    /* istanbul ignore next */
    render() {
        let actionsMenu;
        let addTabModal;
        let defaultPhoto;
        let disabled = true;
        let eventsAndNotes = null;
        let mode = this.props.route.mode;
        let tabMenu;
        let validations = TitleStore.getValidations().concat(TitleLocalizedStore.getValidations());
        let relationshipType;
        let inWBTVD;
        let exportMetadata;
        let showDash;
        let statusDashboard;
        let toItem = (lc) => ({value:lc, label:LanguageCodeType.LANGUAGES[lc]});
        let localizationOptions = this.state.titleLocalized.reduce((options, tl) => {
            options.push(toItem(tl.get('languageCode')));
            return options;
        }, [toItem(LanguageCodeType.DEFAULT_LANGUAGE)]);
        let languageFooter;
        let localization;
        let localized = this.state.currentLanguage !== LanguageCodeType.DEFAULT_LANGUAGE;
        let styleTabLabel;
        const titleId = parseInt(this.props.params.id, 10);
        if (mode === 'create' && this.props.permissions.canCreate) {
            disabled = false;

            actionsMenu = <ActionsMenu
                canSave={this.props.permissions.canCreate}
                onCancel={this.handleCancelButton}
                onSave={this.handleSave}
                saveText={this.context.intl.messages['titles.create.add-title']}
                validations={validations}
            />;

            let baseRoute = '/titles/create';
            if (this.props.permissions.style.canBrowse) {
                styleTabLabel = (
                    <li className={this.isActiveTab('style')}>
                        <Link to={`${baseRoute}/style`}>{this.context.intl.messages['titles.create.style-tab-title']}</Link>
                    </li>
                );
            }
            tabMenu = (
                <ul className="nav nav-tabs">
                    <li className={this.isActiveTab('summary')}>
                        <Link to={baseRoute}>{this.context.intl.messages['titles.create.summary-tab-title']}</Link>
                    </li>
                </ul>
            );

            if (this.props.location.query && this.props.location.query.parentId) {
                relationshipType = <Row>
                    <Col mdOffset={1} md={7}>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['titles.create.related.select-type']}</ControlLabel>
                            <BaseSelect
                                disabled={false}
                                name="titleRelationshipType"
                                onChange={this.handleSelectTitleRelationshipType}
                                options="childrenRelationshipTypes"
                                store={RelatedStore}
                                value={this.state.addNewRelationshipType}
                            />
                        </FormGroup>
                    </Col>
                </Row>;
            }

        }

        let showUpdatedModal;

        if (mode === 'edit') {
            const tabsAndConditions = [
                {
                    canShow: () => true,
                    hasPermission: this.props.permissions.stations.canBrowse,
                    tabs: [{
                        message: 'titles.create.assets-tab-stations-title',
                        value: 'station',
                        route: 'stations'
                    }]
                },
                {
                    canShow: () => true,
                    hasPermission: this.props.permissions.images.canBrowse,
                    tabs: [{
                        message: 'titles.create.assets-tab-images-title',
                        value: 'image',
                        route: 'images'
                    }]
                },
                {
                    canShow: () => true,
                    hasPermission: this.props.permissions.scripts.canBrowse,
                    tabs: [{
                        message: 'titles.create.assets-tab-scripts-title',
                        value: 'script',
                        route: 'scripts'
                    }]
                },
                {
                    canShow: () => true,
                    hasPermission: this.props.permissions.videos.canBrowse,
                    tabs: [{
                        message: 'titles.create.assets-tab-videos-title',
                        value: 'video',
                        route: 'videos'
                    }]
                },
                {
                    canShow: (tabName) => (this.getAssetTabs().includes(tabName) || this.isActiveTab(tabName)),
                    hasPermission: this.props.permissions.merchandise.canBrowse,
                    tabs: [{
                        message: 'titles.create.assets-tab-merchandise-title',
                        value: 'merchandise',
                        route: 'merchandise'
                    }]
                },
                {
                    canShow: (tabName) => (this.getAssetTabs().includes(tabName) || this.isActiveTab(tabName)),
                    hasPermission: this.props.permissions.audio.canBrowse,
                    tabs: [{
                        message: 'titles.create.assets-tab-audio-title',
                        value: 'audio',
                        route: 'audio'
                    }]
                },
                {
                    canShow: () => true,
                    hasPermission: this.props.permissions.documents.canBrowse,
                    tabs: [{
                        message: 'titles.create.assets-tab-documents-title',
                        value: 'document',
                        route: 'documents'
                    }]
                },
                {
                    canShow: () => true,
                    hasPermission: this.props.permissions.catalogs.canBrowse,
                    tabs: [{
                        message: 'titles.edit.catalogs-tab-title',
                        value: 'catalogs',
                        route: 'catalogs'
                    }]
                },
                {
                    canShow: () => true,
                    hasPermission: this.props.permissions.events.canBrowse,
                    tabs: [{
                        message: 'titles.edit.event-catalogs-tab-title',
                        value: 'events',
                        route: 'events'
                    }]
                },
                {
                    canShow: (tabName) => (!!this.state.title.get('languageCatalogs').size || this.isActiveTab(tabName)),
                    hasPermission: this.props.permissions.languageCatalogs.canBrowse,
                    tabs: [{
                        message: 'titles.edit.local-tab-title',
                        value: 'local',
                        route: 'local'
                    }]
                },
                {
                    canShow: (tabName) => (this.getHasStyle() || this.isActiveTab(tabName)),
                    hasPermission: this.props.permissions.style.canBrowse,
                    tabs: [{
                        message: 'titles.create.style-tab-title',
                        value: 'style',
                        route: 'style'
                    }]
                },
                {
                    canShow: () => true,
                    hasPermission: this.props.permissions.talent.canBrowse,
                    tabs: [{
                        message: 'titles.create.talent-tab-title',
                        value: 'talent',
                        route: 'talent'
                    }]
                }
            ];
            const availableTabs = GetAvailableTabs(tabsAndConditions);
            actionsMenu = <ActionsMenu
                canEdit={this.props.permissions.canEdit}
                canSave={this.props.permissions.canEdit}
                canDelete={!this.state.originalTitle.get('active') && this.props.permissions.canMarkForDelete}
                deleteText={this.context.intl.messages['titles.edit.delete.label']}
                hideSaveAndSync={!this.props.permissions.canEdit}
                onCancel={this.handleCancel}
                onDelete={this.toggleDeleteModal}
                onElasticSearchSync={this.handleElasticSearchSync}
                onSave={this.handleSave}
                validations={validations}
            />;
            if (this.state.updatedDate !== undefined) {
                showUpdatedModal = !(Date.now()>Moment.utc(this.state.updatedDate)+60000);
            }

            if (this.props.permissions.canEdit) {
                disabled = false;
                if ((this.isActiveTab('summary') || this.isActiveTab('talent')) && this.props.permissions.localized.canRead) {
                    if (this.props.permissions.localized.canEdit) {
                        let removeLocalized = '';
                        if (localized) {
                            removeLocalized = <Button
                                bsStyle="danger"
                                className="btn btn-default btn-lg btn-block localization"
                                onClick={this.handleRemoveLocalization}><i className="fas fa-plus"></i>&nbsp;{this.context.intl.messages['titles.create.localization.remove-current']}
                            </Button>;
                        }
                        languageFooter = <div>
                            <Button
                                bsStyle="default"
                                className="btn btn-default btn-lg btn-block localization"
                                onClick={this.showAddLocalization}><i className="fas fa-plus"></i>&nbsp;{this.context.intl.messages['titles.create.localization.add-new']}
                            </Button>
                            {removeLocalized}
                        </div>;
                    }
                    localization = <Panel
                        collapsible
                        defaultExpanded
                        iconClass="fas fa-globe"
                        title={this.context.intl.messages['titles.create.localization.title']}
                        footer={languageFooter}>
                        <div className="box-body padding-x-20 d-block">
                            <p>{this.context.intl.messages['titles.create.localization.body']}:</p>
                            <FormGroup>
                                <ReactSelect
                                    name="localizationSelect"
                                    onChange={this.handleLocalizationChange}
                                    options={localizationOptions}
                                    value={toItem(this.state.currentLanguage)}
                                    clearable={false}
                                    style={{bordeColor:'#09E', background: '#F0FAFF'}}
                                />
                            </FormGroup>
                        </div>
                    </Panel>;
                }
            }

            defaultPhoto = <Panel
                collapsible
                defaultExpanded
                iconClass="far fa-image"
                title={this.context.intl.messages['titles.create.default-photo-title']}>
                <img src={this.state.title.get('defaultImagePortraitPreviewUrl')} className="img-thumbnail" />
            </Panel>;
            eventsAndNotes = (
                <SideNotes
                    basePath={`/titles/${this.props.params.id}`}
                    dialogMessage={this.context.intl.messages['common.side-notes.add-note.body']}
                    disabled={ !this.props.permissions.canEdit }
                    notes={this.state.title.get('history').toJS().slice(0, 4)}
                    onAddNote={this.addNote}
                    title={this.context.intl.messages['common.side-notes.title']}
                />
            );
            let assetsTabLabels;
            assetsTabLabels = ['image', 'video', 'document', 'station', 'audio', 'merchandise', 'script'].map(x => {
                if (availableTabs.get(x)) {
                    return this.toTabByConstantValue(GetTabDataByValue(tabsAndConditions, x), localized);
                }
            });

            let catalogsTabLabel;
            if (availableTabs.get('catalogs')) {
                catalogsTabLabel = this.toTabByConstantValue(GetTabDataByValue(tabsAndConditions, 'catalogs'), localized);
            }

            let eventCatalogsTabLabel;
            if (availableTabs.get('events')) {
                eventCatalogsTabLabel = this.toTabByConstantValue(GetTabDataByValue(tabsAndConditions, 'events'), localized);
            }

            let localTabLabel;
            if (availableTabs.get('local')) {
                localTabLabel = this.toTabByConstantValue(GetTabDataByValue(tabsAndConditions, 'local'), localized);
            }

            let historyTabLabel = this.toTab('history', 'history', 'common.tab-title.history', localized, 'pull-right');

            let relatedTabLabel;
            if (this.props.permissions.related.canBrowse) {
                relatedTabLabel = this.toTab('related', 'related', 'titles.create.related-tab-title', localized);
            }

            if (availableTabs.get('style')) {
                styleTabLabel = this.toTabByConstantValue(GetTabDataByValue(tabsAndConditions, 'style'), localized);
            }

            let subscriptionsLabel;
            if (this.props.permissions.subscriptions.canBrowse) {
                subscriptionsLabel = this.toTab('subscriptions', 'subscriptions', 'titles.create.subscriptions-tab-title', localized);
            }

            let talentTabLabel;
            if (availableTabs.get('talent') || localized) {
                talentTabLabel = this.toTabByConstantValue(GetTabDataByValue(tabsAndConditions, 'talent'), localized);
            }

            let usersTabLabel;
            if (this.props.permissions.users.canBrowse) {
                usersTabLabel = this.toTab('users', 'users', 'titles.create.users-tab-title', localized);
            }

            let addTabLabel;
            if (Object.keys(availableTabs.toJS()).some(t => !availableTabs.get(t)) && !localized) {
                addTabLabel= <li className={this.isActiveTab('add-tab')}>
                    <a href="#" onClick={() => {
                        this.handleToggleShowAddTabModal();
                    }}>{this.context.intl.messages['common.create.add-tab']}</a>
                </li>;
            }

            tabMenu = <ul className="nav nav-tabs">
                <li className={this.isActiveTab('summary')}>
                    <Link to={`/titles/${this.props.params.id}`}>{this.context.intl.messages['titles.create.summary-tab-title']}</Link>
                </li>
                {talentTabLabel}
                {assetsTabLabels}
                {catalogsTabLabel}
                {eventCatalogsTabLabel}
                {localTabLabel}
                {usersTabLabel}
                {relatedTabLabel}
                {subscriptionsLabel}
                {styleTabLabel}
                {addTabLabel}
                {historyTabLabel}
            </ul>;

            addTabModal = <AddTabModal
                availableTabs={availableTabs}
                onAdd={this.handleAddTab}
                onHide={this.handleToggleShowAddTabModal}
                show={this.state.showAddTabModal}
                tabsAndConditions={tabsAndConditions}
            />;
            let currentTab = '';
            let pathnames = this.props.location.pathname.split('/');
            if (pathnames.length > 3) {
                if (['images', 'videos', 'audio', 'merchandise', 'documents', 'stations'].indexOf(pathnames[3]) !== -1) {
                    currentTab = '/' + pathnames[3];
                }
            }
            inWBTVD = (
                <a
                    className="btn btn-navy-outline btn-sm"
                    href={url.resolve(Config.WBTVDBaseUrl, `titles/${this.props.params.id}${currentTab}`)}
                    target="_blank"
                >
                    <i className="fas fa-external-link"/>&nbsp;{this.context.intl.messages['titles.edit.view.in.wbtvd']}
                </a>
            );

            exportMetadata = (
                <Button
                    className="btn btn-default-outline btn-sm"
                    onClick={this.handleExportMetadata}
                >
                    <i className="fas fa-arrow-to-bottom"/>&nbsp;{this.context.intl.messages['titles.edit.export-metadata']}
                </Button>
            );

            statusDashboard = '';
            let showDashboardLabel = 'titles.edit.dashboard.show';
            if (this.props.permissions.canViewMetadataStatus) {
                if (this.state.showStatusDashboard) {
                    statusDashboard = (
                        <div className="row padding-bottom-20">
                            <DashboardTable title={this.state.title} canEdit={this.props.permissions.canEditMetadataStatus} />
                        </div>
                    );
                    showDashboardLabel = 'titles.edit.dashboard.hide';
                }
                showDash = (
                    <Button className="btn btn-navy-outline btn-sm" onClick={this.handleToggleDashboard}>
                        <i className="fas fa-tachometer-alt"/>&nbsp;{this.context.intl.messages[showDashboardLabel]}
                    </Button>
                );
            }
        }

        let currentKeys = localizationOptions.map(l => l.value);
        let newLocalizationOptions = Object.keys(LanguageCodeType.LANGUAGES)
            .filter(l => currentKeys.indexOf(l) === -1)
            .map(l => toItem(l))
            .sort((a, b) => a.label.localeCompare(b.label));

        let entityName;

        if (this.state.title.get('name')) {
            entityName = this.state.title.get('name');
        }

        return (
            <DocumentTitle
                message={`document-titles.${mode}-titles`} entityName={entityName}
            >
                <div>
                    <ConfirmUpdatedSaveModal
                        onConfirm={this.handleProceedSave}
                        onHide={this.handleHideUpdatedSaveModal}
                        updatedDate={this.state.updatedDate}
                        show={showUpdatedModal}
                    />
                    <Modal show={this.state.showAddNew} onHide={this.onHide}>
                        <Modal.Header className="bg-gray" closeButton>
                            <Modal.Title className="text-center">{this.context.intl.messages['titles.create.localization.add-new']}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Language:</p>
                            <FormGroup>
                                <ReactSelect
                                    name="newLocalizationSelect"
                                    onChange={this.handleNewLocalizationChange}
                                    options={newLocalizationOptions}
                                    value={toItem(this.state.newLanguage)}
                                    clearable={false}
                                    style={{bordeColor:'#09E', background: '#F0FAFF'}}
                                />
                            </FormGroup>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button bsStyle="primary" className="bg-wb-blue" onClick={this.handleAddNewLocalization} disabled={!this.state.newLanguage}>
                                <i className="fas fa-plus-square"/>&nbsp;{this.context.intl.messages['titles.create.localization.add-new']}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <StickyHeader>
                        <div className="row">
                            <div className="col-md-6">
                                <h1>
                                    <i className="far fa-newspaper"/>&nbsp;{this.context.intl.messages[`titles.${mode}.title`]}&nbsp;
                                    <span className="title-header-buttons padding-y-10">
                                        <EpisodeNavigation serieNavigation={this.state.serieNavigation} titleId={titleId} location={this.props.location} />
                                        &nbsp;{inWBTVD}&nbsp;{exportMetadata}&nbsp;{showDash}
                                    </span>
                                    <small className="d-block padding-y-10">{this.state.title.get('name')} {this.airDate(this.state.title.get('releases'), this.context.intl.messages)}</small>
                                </h1>
                            </div>
                            {actionsMenu}
                        </div>
                        {statusDashboard}
                        {addTabModal}
                    </StickyHeader>
                    <Preloader show={this.state.showPreloader || this.state.showTabsPreloader || this.state.preloaderVisible} fixed loadingDots>
                        <StatusDialog
                            field={this.state.field}
                            show={this.state.showStatusModal}
                            title={this.state.title}
                        />
                        <section className="content" id="contentContainer">
                            {relationshipType}
                            <div className="row">
                                <div className="col-lg-9">
                                    <div className="nav-tabs-custom">
                                        {tabMenu}
                                        <div className="tab-content">
                                            <div className="tab-pane padding-x-20 padding-bottom-20 active">
                                                {React.Children.map(this.props.children, c => {
                                                    switch (c.type) {
                                                    case Assets:
                                                        return React.cloneElement(c, {
                                                            assetTabType: this.getActiveAssetTab(),
                                                            caller: 'title',
                                                            disabled: !this.props.permissions.assets.canEdit || localized,
                                                            loadOnMount: true,
                                                            location: this.props.location,
                                                            id: titleId,
                                                            title: this.state.title.get('name'),
                                                            showCatalogsTab: true
                                                        });
                                                    case Catalogs:
                                                        return React.cloneElement(c, {
                                                            assignedCatalogs: this.state.title.get('catalogs'),
                                                            canEdit: this.props.permissions.catalogs.canEdit || localized,
                                                            serieNavigation: this.state.serieNavigation,
                                                            title: this.state.title
                                                        });
                                                    case EventCatalogs:
                                                        return React.cloneElement(c, {
                                                            assignedCatalogs: this.state.title.get('catalogs'),
                                                            canEdit: this.props.permissions.catalogs.canEdit || localized,
                                                            serieNavigation: this.state.serieNavigation,
                                                            title: this.state.title
                                                        });
                                                    case History:
                                                        return React.cloneElement(c, {
                                                            actionObject: ActionHistoryConstants.ACTION_OBJECTS.TITLE,
                                                            id: titleId,
                                                            pagination: true,
                                                            pathname: this.props.location.pathname,
                                                            query: this.props.location.query
                                                        });
                                                    case Local:
                                                        return React.cloneElement(c, {
                                                            assignedCatalogs: this.state.title.get('languageCatalogs'),
                                                            canEdit: this.props.permissions.languageCatalogs.canEdit || localized,
                                                            serieNavigation: this.state.serieNavigation,
                                                            title: this.state.title
                                                        });
                                                    case Related:
                                                        return React.cloneElement(c, {
                                                            disabled: !this.props.permissions.related.canEdit || localized,
                                                            id: titleId,
                                                            title: this.state.title,
                                                        });
                                                    case Style:
                                                        return React.cloneElement(c, {
                                                            disabled: !this.props.permissions.style.canEdit,
                                                            title: this.state.title,
                                                            titleStyle: this.state.titleStyle
                                                        });
                                                    case Subscriptions:
                                                        return React.cloneElement(c, {
                                                            disabled: !this.props.permissions.subscriptions.canEdit || localized,
                                                            title: this.state.title,
                                                            location: this.props.location
                                                        });
                                                    case Summary:
                                                        return React.cloneElement(c, {
                                                            canViewAdditionalInfo: this.props.permissions.canViewAdditionalInfo,
                                                            canViewAlternativeTitles: this.props.permissions.canViewAlternativeTitles,
                                                            canViewLanguages: this.props.permissions.canViewLanguages,
                                                            canViewLinks: this.props.permissions.canViewLinks,
                                                            canViewMasteringInfo: this.props.permissions.canViewMasteringInfo,
                                                            canViewNonTheatricalRights: this.props.permissions.canViewNonTheatricalRights,
                                                            canViewProductionAndDistribution: this.props.permissions.canViewProductionAndDistribution,
                                                            canViewReleases: this.props.permissions.canViewReleases,
                                                            canViewRights: this.props.permissions.canViewRights,
                                                            canViewSynopsisLogLines: this.props.permissions.canViewSynopsisLogLines,
                                                            canEditLocalized: this.props.permissions.localized.canEdit,
                                                            canViewLocalized: this.props.permissions.localized.canRead,
                                                            disabled: disabled,
                                                            mode: this.props.route.mode,
                                                            title: this.state.title,
                                                            currentLanguage: this.state.currentLanguage,
                                                            titleLocalized: this.state.titleLocalized.get(this.state.currentLanguage)
                                                        });
                                                    case Talent:
                                                        return React.cloneElement(c, {
                                                            disabled: !this.props.permissions.talent.canEdit,
                                                            title: this.state.title,
                                                            currentLanguage: this.state.currentLanguage,
                                                            titleLocalized: this.state.titleLocalized
                                                        });
                                                    case Users:
                                                        return React.cloneElement(c, {
                                                            displayLinks: this.props.permissions.users.canRead,
                                                            location: this.props.location,
                                                            titleId: titleId
                                                        });
                                                    }
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <SerieNavigation serieNavigation={this.state.serieNavigation} titleId={titleId} location={this.props.location}/>
                                    {defaultPhoto}
                                    <AlertsWarnings title={this.context.intl.messages['common.alerts-warnings-title']} stores={[AssetTabStore, TitleStore, TitleLocalizedStore]}/>
                                    {localization}
                                    {eventsAndNotes}
                                </div>
                            </div>
                        </section>
                    </Preloader>
                    <CascadeChangesModal
                        flags={this.state.cascadeFlags.toJS()}
                        onClose={this.toggleCascadeChangesModal}
                        onConfirmSave={this.handleProceedSave}
                        serieNavigation={this.state.serieNavigation}
                        show={this.state.showCascadeChangesModal}
                        title={this.state.title}
                    />
                </div>
            </DocumentTitle>
        );
    }

}

export default Container.create(Create);

export {
    Create
};
