/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
*/

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import ReactTable from 'react-table';

import 'react-table/react-table.css';

export default class EventMessageTable extends Component {
    static get propTypes() {
        return {
            data: PropTypes.instanceOf(Immutable.Map).isRequired,
            title: PropTypes.string.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
        };
    }

    static calculateState() {
        return {
            id: Math.ceil(Math.random() * 100000),
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();
    }

    render() {
        let columns = [{
            Header:  <strong>{this.props.title}</strong>,
            columns: [{
                headerClassName: 'hidden',
                Cell: c => {
                    return (
                        <div>
                            {c.original.get('key') || '-'}
                        </div>
                    );
                }
            }, {
                headerClassName: 'hidden',
                Cell: c => {
                    return (
                        <div>
                            {c.original.get('value') || '-'}
                        </div>
                    );
                }
            }]
        }];

        return (
            <section>
                <ReactTable
                    className="-striped table-bordered table-striped responsive"
                    columns={columns}
                    data={this.props.data}
                    getNoDataProps= {() => {
                        if (this.props.data.size) {
                            return {style: {display: 'none'}};
                        }
                        return {};
                    }}
                    id={`event-message-table-${this.state.id}`}
                    loading={false}
                    pageSize={26}
                    showPagination={false}
                    sortable={false}
                    resizable={false}
                />
            </section>
        );
    }
}
