/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';
import {IndexRoute, Route} from 'react-router';

import AccountWizard from './account-wizard/account-wizard';
import AccountWizardBrowse from './account-wizard/browse';
import PermissionsTab from './account-wizard/permissions-tab';
import AccountWizardReview from './account-wizard/review';
import Applicants from './applicants/applicants';
import Browse from './browse/browse';
import Create from './create/create';
import Delivery from './create/delivery/delivery';
import Management from './create/management/management';
import Permissions from './create/permissions/permissions';
import PermissionsAudit from './create/permissions-audit/permissions-audit';
import Subscriptions from './create/subscriptions/subscriptions';
import Summary from './create/summary/summary';
import UserErrors from './create/user-errors/user-errors';
import MassUpdateAvailability from './mass-update/availability';
import MassUpdateConfirmation from './mass-update/confirmation';
import MassUpdate from './mass-update/mass-update';
import ReviewUpdate from './mass-update/review-update';
import MassUpdateStatus from './mass-update/users-status';
import {History} from '../common/side-notes/side-notes';
import WithPermissions from '../decorators/with-permissions';
import {RequirePerm} from '../router/router-helpers';
import SessionStore from '../user/session/session-store';

let Accounts = <Route path="accounts">

    <IndexRoute
        component={WithPermissions(Browse)}
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.ACCOUNTS.BROWSE)}
    />

    <Route
        component={WithPermissions(Applicants)}
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.ACCOUNTS.APPLICANTS.BROWSE)}
        path="applicants"
    />

    <Route
        component={WithPermissions(Create)}
        entity="applicant"
        mode="create"
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.ACCOUNTS.APPLICANTS.CREATE)}
        path="applicants/create"
    >

        <IndexRoute component={Summary}/>
        <Route path="permissions" component={Permissions}/>
    </Route>

    <Route
        component={WithPermissions(Create)}
        entity="applicant"
        mode="edit"
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.ACCOUNTS.APPLICANTS.READ)}
        path="applicants/:id"
    >

        <IndexRoute component={Summary}/>
        <Route path="permissions" component={Permissions}/>
        <Route path="history" component={History}/>
    </Route>

    <Route
        component={AccountWizard}
        mode="create"
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.ACCOUNTS.ACCOUNT_WIZARD.UPLOAD)}
        path="account-wizard"
    />

    <Route
        component={AccountWizardBrowse}
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.ACCOUNTS.ACCOUNT_WIZARD.UPLOAD)}
        path="account-wizard/browse"
    />

    <Route
        component={AccountWizardReview}
        mode="create"
        path="account-wizard/:id"
    >
        <IndexRoute component={PermissionsTab}/>
        <Route path="permissions"/>
    </Route>

    <Route
        component={MassUpdate}
        mode="check"
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.ACCOUNTS.MASS_UPDATE.UPLOAD)}
        path="mass-check"
    />

    <Route
        component={MassUpdate}
        mode="edit"
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.ACCOUNTS.MASS_UPDATE.UPLOAD)}
        path="mass-update"
    />

    <Route
        component={MassUpdateAvailability}
        mode="check"
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.ACCOUNTS.MASS_UPDATE.UPLOAD)}
        path="mass-check/:id/availability"
    />

    <Route
        component={ReviewUpdate}
        mode="edit"
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.ACCOUNTS.MASS_UPDATE.UPLOAD)}
        path="mass-update/:id"
    />

    <Route
        component={MassUpdateConfirmation}
        mode="edit"
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.ACCOUNTS.MASS_UPDATE.UPLOAD)}
        path="mass-update/:id/confirmation"
    />

    <Route
        component={MassUpdateStatus}
        mode="edit"
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.ACCOUNTS.MASS_UPDATE.UPLOAD)}
        path="mass-update/:id/users-status"
    />

    <Route
        component={WithPermissions(Create)}
        entity="account"
        mode="edit"
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.ACCOUNTS.READ)}
        path=":id"
    >

        <IndexRoute component={Summary}/>
        <Route path="permissions" component={Permissions}/>
        <Route path="permissions-audit" component={PermissionsAudit}/>
        <Route path="management" component={Management}/>
        <Route path="delivery" component={Delivery}/>
        <Route path="history" component={History}/>
        <Route path="subscriptions" component={Subscriptions}/>
        <Route path="user-errors" component={UserErrors}/>
    </Route>

</Route>;

export default Accounts;
