/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import Immutable from 'immutable';
import jQuery from 'jquery';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, Col, Row} from 'react-bootstrap';

import PresentationPanel from './presentation-panel';

import SearchBox from '~/src/common/search-box/search-box';
import {SlidingPanelActions} from '~/src/common/sliding-panel/sliding-panel-actions';
import {WithStoreOnRoute} from '~/src/common/store-on-route';
import Pagination from '~/src/common/table/pagination';
import {EventActions} from '~/src/events/event-actions';
import {LayoutActions} from '~/src/layout/layout-actions';
import enUS from '~/src/messages/en-US';
import {TitleActions} from '~/src/titles/title-actions';

require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('~/src/styles/data-tables-brainiac.css');
require('datatables.net-rowreorder-bs/css/rowReorder.bootstrap.css');
require('datatables.net-rowreorder');
// Load jQuery and register the datatables plugin.
require('datatables.net-responsive-bs');

const noop = () => void 0;

const COLUMNS = {
    backgroundImage: {
        header: () => {
            return <th className="text-center" style={{width: 40}}><i className="fa-solid fa-id-card" /></th>;
        },
        get: (c) => {
            if (c.get('appBackgroundImageUrl')) {
                return `<img class="table-thumbnail" src="${c.get('appBackgroundImageUrl')}">`;
            }
            return '<span />';
        }
    },
    edit: {
        header: () => {
            return <th className="text-center" style={{width:45}}>{enUS['events.presentation.table.edit']}</th>;
        },
        get: (title, props) => {
            if (props.disabled) {
                return '<button class="btn btn-sm btn-primary-outline" disabled><i class="fa-solid fa-pencil"></i>&nbsp;Edit</button>';
            }
            if (!title.get('eventTitleId')) {
                return `
                    <a class="btn btn-sm btn-primary-outline parcel-tooltip" disabled title="${enUS['events.presentation.title.disabled']}">
                        <i class="fa-solid fa-pencil"></i>&nbsp;Edit
                        <span class="tooltip-inner">${enUS['events.presentation.title.disabled']}</span>
                    </a>
                `;
            }
            return `<button class="btn btn-sm btn-primary-outline edit-title-button" title-id="${title.get('titleId')}"><i class="fa-solid fa-pencil edit-title-button" title-id="${title.get('titleId')}"></i>&nbsp;Edit</button>`;
        }
    },
    name: {
        header: () => {
            return <th>{enUS['events.presentation.table.name']}</th>;
        },
        get: (title, props) => {
            let nameLink = title.get('name');
            if (title.get('eventTitleId') && !props.disabled) {
                nameLink = `<a class="edit-title" title-id="${title.get('titleId')}">${title.get('name')}</a>`;
            }

            const getImageIcon = (value) => {
                if (value) {
                    return '<i class="fa-solid fa-pen-to-square text-green"></i>';
                }
                return '<i class="fa-solid fa-link-horizontal text-muted"></i>';
            };

            const getNominationIcon = (value) => {
                if (value) {
                    return '<i class="fa-solid fa-pen-to-square text-green"></i>';
                }
                return '<i class="fa-solid fa-x text-red"></i>';
            };

            const titleInfo = [
                `${enUS['events.presentation.table.background']}: ` + getImageIcon(title.get('backgroundOverride')),
                `${enUS['events.presentation.table.synopsis']}: ` + getImageIcon(title.get('synopsisOverride')),
                `${enUS['events.presentation.table.title-thumbnail']}: ` + getImageIcon(title.get('thumbnailOverride')),
                `${enUS['events.presentation.table.nomination-info']}: ` + getNominationIcon(title.get('nominationInfo', Immutable.List()).size)
            ].join(' | ');
            return `<div>${nameLink}<br/><small>${titleInfo}</small></div>`;
        }
    },
    rowIndex: {
        header: (args) => {
            const props = args.props;
            let classNames = ['text-center no-sort no-arrow text-center'];
            if (!props.disabled) {
                classNames.push(['row-reorder-handle', 'row-index']);
            }

            return <th className={ClassNames(classNames)} style={{width:'40px'}} aria-label="#">{enUS['events.presentation.table.row-number']}</th>;
        },
        get: (t, props, context, index) => `<div class="text-center">${index + 1}</div>`
    },
    titleThumbnail: {
        header: () => {
            return <th className="text-center" style={{width: 40}}><i className="fa-solid fa-image-portrait" /></th>;
        },
        get: (c) => {
            const thumbnailUrl = c.get('defaultImagePortraitThumbnailUrl') || c.get('defaultImageHorizontalThumbnailUrl');
            if (thumbnailUrl) {
                return `<img class="table-thumbnail" src="${thumbnailUrl}">`;
            }
            return '<span />';
        }
    },
    videos: {
        header: () => {
            return <th className="text-center" style={{width:45}}>{enUS['events.presentation.table.videos']}</th>;
        },
        get: (title) => {
            return `<div class="text-center">${title.get('totalVideoAssets')}</div>`;
        }
    },
};

class ListTitles extends Component {
    static get propTypes() {
        return {
            activePage: PropTypes.number.isRequired,
            children: PropTypes.node.isRequired,
            columns: PropTypes.array,
            complete: PropTypes.bool,
            disabled: PropTypes.bool.isRequired,
            displayLinks: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
            filterButton: PropTypes.bool.isRequired,
            isOutlineStyle: PropTypes.bool,
            linkTarget: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
            location: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
            onPageChange: PropTypes.func,
            onSearchChange: PropTypes.func,
            onSelect: PropTypes.func,
            onSelectAll: PropTypes.func,
            onSortChange: PropTypes.func,
            selectAll: PropTypes.bool,
            sortDirection: PropTypes.string.isRequired,
            sortFieldName: PropTypes.string.isRequired,
            tableId: PropTypes.string,
            titles: PropTypes.instanceOf(Immutable.List).isRequired, // eslint-disable-line react/no-unused-prop-types
            totalPages: PropTypes.number.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
            router: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            columns: [],
            complete: false,
            displayLinks: false,
            linkTarget: '_self',
            location: undefined,
            filters: Immutable.Map(),
            isOutlineStyle: false,
            onSearchChange: undefined,
            onSelect: undefined,
            onSelectAll: undefined,
            onSortChange: undefined,
            onPageChange: noop,
            selectAll: false,
            tableId: 'list-titles-table',
            titles: Immutable.List()
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            selectedTitle: Immutable.Map(),
        };

        this.getsortFieldNameClass = this.getsortFieldNameClass.bind(this);
        this.handleActionsCellClick = this.handleActionsCellClick.bind(this);
        this.handleCatalogCellMouseEnter = this.handleCatalogCellMouseEnter.bind(this);
        this.handleHeaderClick = this.handleHeaderClick.bind(this);
        this.handleOpenPresentationPanel = this.handleOpenPresentationPanel.bind(this);
        this.handleReorder = this.handleReorder.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.handleSearchTerm = this.handleSearchTerm.bind(this);
        this.handleSelectAllClick = this.handleSelectAllClick.bind(this);
    }

    componentDidMount() {
        let tableId = this.props.tableId;
        this.$table = jQuery(`#${tableId}`);
        let className = '';
        if (!this.props.disabled) {
            className = 'row-reorder-handle';
        }
        const configTable = {
            autoWidth: false,
            columnDefs: [{
                // Add the control class to the last column. This colum will
                // contain the button to show the responsive data.
                className: 'control',
                targets:   -1,
                width: 20
            }, {
                className: className,
                targets: 0
            }, {
                className: 'actions',
                targets: 'actions'
            }, {
                targets: 'no-sort',
                orderable: false
            }],
            iDisplayLength: 1,
            info: false,
            ordering: false,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        };

        if (!this.props.disabled) {
            configTable.rowReorder = {
                selector: '.row-reorder-handle'
            };
        }

        this.$tableAPI = this.$table.DataTable(configTable);

        // Add some custom handlers.
        this.$table.on('mouseenter', '.display-title-catalogs', this.handleCatalogCellMouseEnter);
        this.$table.on('click', 'td.actions', this.handleActionsCellClick);
        if (!this.props.disabled) {
            this.$tableAPI.on('row-reorder', this.handleReorder);
        }

        // Register global listeners.
        window.addEventListener('resize', this.handleResize);
        // And trigger the resize handler once so that the datatable
        // knows its initial dimensions.
        this.drawTitles(this.props);
        return;
    }

    componentWillUpdate(nextProps) {
        this.drawTitles(nextProps);
        return;
    }

    componentWillUnmount() {
        // check if undefinded only to enable render test
        if (this.$table) {
            this.$table.off('mouseenter', '.display-title-catalogs');
        }

        if (this.$table && !this.props.disabled) {
            this.$table.off('row-reorder');
        }

        // check if undefinded only to enable render test
        try {
            // Wrapped in try/catch to prevent browser exception when used in slidingpanel
            if (this.$tableAPI) {
                this.$tableAPI.destroy();
            }
        } catch (e) {
            return;
        }

        return;
    }

    drawTitles(props) {
        this.$tableAPI.clear();

        // Add data to the jQuery datatable.
        props.titles.forEach((title, index) => {
            let row = this.props.columns.map(c => {
                let column = COLUMNS[c];
                if (c.name) {
                    column = COLUMNS[c.name];
                }
                // Override the getter if it was provided. This way
                // the module that includes the table can define custom
                // functions to read the values.
                if (c.get) {
                    column.get = c.get;
                }
                return column.get(title, props, this.context, index);
            });

            // If requested, show the select in the first column.
            if (this.props.onSelectAll && this.props.onSelect) {
                let checkedStatus = '';
                if (title.get('__selected')) {
                    checkedStatus = 'checked';
                }
                row.unshift(`<input type="checkbox" class="select-row" data-index="${index}" ${checkedStatus}/>`);
            }
            // Add a last empty column for the datatable-responsive plugin.
            row.push('');

            this.$tableAPI.row.add(row);

            return;
        });

        this.$tableAPI.draw(false);

        // Now, since the data has changed the columns widths, trigger
        // the resize handler in order to update the responsive feature.
        this.handleResize();
    }

    getsortFieldNameClass(headerName) {
        let r = 'sorting';

        if (headerName === this.props.sortFieldName) {
            r = `sorting_${this.props.sortDirection}`;
        }

        return r;
    }

    handleHeaderClick(headerName) {
        let newSortDirection = 'asc';
        if (this.props.sortFieldName === headerName && this.props.sortDirection === 'asc') {
            newSortDirection = 'desc';
        }

        if (this.props.onSortChange) {
            this.props.onSortChange(headerName, newSortDirection);
        }
        return;
    }

    handleReorder(event, diff, edit) {
        event.preventDefault();
        let from = edit.triggerRow[0][0];
        if (diff.length < 2) {
            return;
        }

        let to;
        if (diff[0].oldPosition === from) {
            to = diff[0].newPosition;
        } else {
            to = diff[diff.length-1].newPosition;
        }

        setTimeout(() => { // Prevents an error in datatables reorder plugin
            EventActions.reorderTitle(from, to);
            return;
        }, 0);
    }

    /**
     * Send all click events over the "actions" cell
     * to the handler defined by the parent component.
     */
    handleActionsCellClick(event) {
        this.props.columns.filter(c => c.name === 'actions')[0]?.onClick(event);
        return;
    }

    /**
     * Look for the catalogs on mouseenter.
     */
    handleCatalogCellMouseEnter(event) {
        const title = event.currentTarget.getAttribute('data-original-title');
        // If catalogs for this title are loaded, then return.
        if (title !== this.context.intl.messages['titles.browse.loading-catalogs']) {
            return;
        }

        TitleActions.getCatalogs(
            parseInt(event.currentTarget.getAttribute('data-title-id'), 10)
        );
        return;
    }

    /**
     * Update the datatable columns size.
     */
    handleResize() {
        this.$tableAPI.responsive.recalc();
        return;
    }

    /**
     * This function is kind of "special" because it needs to handle
     * events bubbled from the data table rows, these rows cannot use
     * the JSX syntax because they are created by the data table
     * jQuery plugin instead of React.
     */
    handleRowClick(event) {
        switch (true) {
        // Handle click on a select input.
        case !!~event.target.className.indexOf('select-row'):
            const index = parseInt(event.target.getAttribute('data-index'), 10);
            this.props.onSelect(index, event.target.checked);
            break;
        // Handle click on an user's name.
        case !!~event.target.className.indexOf('edit-title'):
            const titleId = event.target.getAttribute('title-id');
            this.handleOpenPresentationPanel(titleId);
            break;
        }

        return;
    }

    handleOpenPresentationPanel(titleId) {
        const selectedTitle = this.props.titles.find(title => title.get('titleId') === parseInt(titleId, 10));
        this.setState(()=>({
            selectedTitle
        }));
        SlidingPanelActions.show('editTitlePresentation');
    }

    handleSearchTerm(term) {
        this.props.onSearchChange(term);
        return;
    }

    handleToggleFiltersPanel() {
        LayoutActions.toggleFiltersPanel();
        return;
    }

    handleSelectAllClick(event) {
        this.props.onSelectAll(event.target.checked);
        return;
    }

    render() {
        let filterButton;
        let search;
        let showSelect = !!(this.props.onSelectAll && this.props.onSelect);

        if (this.props.filterButton) {
            let filtersStyle = {
                bsStyle: 'primary',
                bsSize: 'large',
                className: 'pull-right'
            };
            if (this.props.isOutlineStyle) {
                filtersStyle = {
                    className: 'btn btn-navy-outline pull-right ',
                    bsSize: 'medium'
                };
            }
            filterButton = <Col md={3}>
                <Button onClick={this.handleToggleFiltersPanel} bsSize="large" {...filtersStyle}>
                    <i className="fas fa-sliders-h"></i>&nbsp;{this.context.intl.messages['common.filteringOptions']}
                </Button>
            </Col>;

        }

        if (this.props.onSearchChange) {
            search = <Row>
                <Col md={6} mdOffset={3} className="show-grid">
                    <SearchBox value={this.getRouteState(this.props).get('title')} onChange={this.handleSearchTerm}/>
                </Col>
                {filterButton}
            </Row>;
        }

        let pagination;
        if (!this.props.complete) {
            pagination = <div className="row">
                <div className="col-sm-7 col-sm-offset-5">
                    <Pagination
                        activePage={this.props.activePage}
                        onChange={this.props.onPageChange}
                        totalPages={this.props.totalPages}
                    />
                </div>
            </div>;
        }

        return (
            <div>
                {search}
                {this.props.children}
                <table id={this.props.tableId} className="table table-bordered table-striped responsive">
                    <thead>
                        <tr>
                            {showSelect && <th className="no-sort"><input type="checkbox" onChange={this.handleSelectAllClick} checked={this.props.selectAll}/></th>}
                            {this.props.columns.map(c => {
                                if (c.header) {
                                    return c.header(this);
                                }
                                let column = COLUMNS[c];
                                if (c.name) {
                                    column = COLUMNS[c.name];
                                }
                                return column.header(this);
                            })}
                            <th className="no-sort"></th>
                        </tr>
                    </thead>
                    <tbody onClick={this.handleRowClick}>
                    </tbody>
                </table>
                {pagination}
                <PresentationPanel location={this.props.location} title={this.state.selectedTitle} />
            </div>
        );
    }
}

export default WithStoreOnRoute(ListTitles);
