/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import jQuery from 'jquery';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {Sort} from '../../common/local-search/filter';
import {WithStoreOnRoute} from '../../common/store-on-route';
import {HomepageConstants} from '../homepage-actions';

require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('datatables.net-responsive-bs');

const PAGE_TYPE = Object.values(HomepageConstants.PAGE_TYPE);

class Table extends Component {

    static get propTypes() {
        return {
            displayLinks: PropTypes.bool,
            filters: PropTypes.string,
            homepages: PropTypes.instanceOf(Immutable.List).isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
            router: PropTypes.object
        };
    }

    static get defaultProps() {
        return {
            displayLinks: false,
            filters: '',
            location: undefined,
            router: undefined
        };
    }

    constructor(props) {
        super(props);

        this.getSortByClass = this.getSortByClass.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.handleSort = this.handleSort.bind(this);
    }

    componentDidMount() {
        this.$table = jQuery('#homepage-table');
        this.$tableAPI = this.$table.DataTable({
            autoWidth: false,
            columnDefs: [{
                className: 'control',
                targets:   -1,
                width: 20
            }, {
                className: 'Whs(nw)',
                targets: 'no-wrap'
            }],
            iDisplayLength: 1,
            info: false,
            ordering: false,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        });

        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        return;
    }

    componentWillUpdate(nextProps) {
        this.$tableAPI.clear();

        let filterRegExp = new RegExp(nextProps.filters, 'i');

        let homepages = nextProps.homepages.filter(c => {
            let keep = c;
            if (keep && nextProps.filters) {
                keep = c.get('name').match(filterRegExp);
            }

            return keep;
        });

        homepages = Sort(homepages, this.getRouteState(nextProps).get('sortBy', 'name'), this.getRouteState(nextProps).get('sortDir', 'asc'));


        homepages.forEach((homepage) => {
            let created = Moment(homepage.get('createdDate'));
            if (created.isValid()) {
                created = created.format(this.context.intl.messages['date-format']);
            } else {
                created = '-';
            }

            let active = '<i class="fas fa-check-circle text-green"></i> <span class="hidden-xs hidden-sm"> Active</span>';
            let inactive = '<i class="fas fa-minus-circle text-red"></i> <span class="hidden-xs hidden-sm"> Inactive</span>';

            let homepageName = homepage.get('name');
            if (nextProps.displayLinks) {
                homepageName = `<a href="/homepage/${homepage.get('homePageId')}" class="edit-homepage">${homepage.get('name')}</a>`;
            }

            let templateName = '-';
            for (let j = 0; j < PAGE_TYPE.length; j++) {
                if (PAGE_TYPE[j].id === homepage.get('pageType')) {
                    templateName = PAGE_TYPE[j].name;
                    break;
                }
            }

            let row = [];
            let a = inactive;
            if (homepage.get('active')) {
                a = active;
            }
            let d = '';
            if (homepage.get('description')) {
                d = homepage.get('description');
            }
            row.push(
                homepageName,
                a,
                homepage.get('userCount'),
                d,
                created,
                templateName,
                ''
            );

            this.$tableAPI.row.add(row);

            return;
        });

        this.$tableAPI.draw(false);
        this.handleResize();

        return;
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    getSortByClass(column) {
        let r = 'sorting';

        if (column === this.getRouteState().get('sortBy', 'name')) {
            r = `sorting_${this.getRouteState().get('sortDir', 'asc')}`;
        }

        return r;
    }

    handleResize() {
        this.$tableAPI.responsive.recalc();
        return;
    }

    /**
     * This function is kind of "special" because it needs to handle
     * events bubbled from the data table rows, these rows cannot use
     * the JSX syntax because they are created by the data table
     * jQuery plugin instead of React.
     */
    handleRowClick(event) {
        switch (true) {
        case !!~event.target.className.indexOf('edit-homepage'):
            event.preventDefault();
            this.context.router.push(event.target.getAttribute('href'));
            break;
        }

        return;
    }

    handleSort(column) {
        let newSortDir = 'asc';
        if (this.getRouteState().get('sortBy', 'name') === column && this.getRouteState().get('sortDir', 'asc') === 'asc') {
            newSortDir = 'desc';
        }

        this.setRouteState('sortBy', column)
            .setRouteState('sortDir', newSortDir)
            .apply();
    }

    render() {
        return (
            <div>
                <table id="homepage-table" className="table table-bordered table-striped responsive">
                    <thead>
                        <tr>
                            <th className={this.getSortByClass('name')} onClick={this.handleSort.bind(this, 'name')}>Homepage Name</th>
                            <th className="no-wrap">Status</th>
                            <th className={this.getSortByClass('userCount')} onClick={this.handleSort.bind(this, 'userCount')}>Users</th>
                            <th className={this.getSortByClass('description')} onClick={this.handleSort.bind(this, 'description')}>Description</th>
                            <th className={this.getSortByClass('createdDate')} onClick={this.handleSort.bind(this, 'createdDate')}>Date Added</th>
                            <th className={this.getSortByClass('pageType')} onClick={this.handleSort.bind(this, 'pageType')}>Template</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody onClick={this.handleRowClick}>
                    </tbody>
                </table>
            </div>
        );
    }

}


export default WithStoreOnRoute(Table);
