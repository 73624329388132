/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {StarredConstants} from './starred-actions';
import Dispatcher from '../../../dispatcher/dispatcher';

class StarredStore extends ReduceStore {

    getInitialState() {
        return Immutable.Map({
            starred: Immutable.fromJS(JSON.parse(localStorage.getItem('__starred') || '[]'))
        });
    }

    reduce(state, action) {
        switch (action.actionType) {
        case StarredConstants.CLEAR:
            state = this.getInitialState();
            break;

        case StarredConstants.TOGGLE_STAR:
            state = state.update('starred', starred => {
                const {path, title, userId, permission} = action;
                const found = starred.find(s => path === s.get('path'));
                if (found) {
                    return starred.filter(s => path !== s.get('path'));
                }
                return starred.push(Immutable.Map({
                    path,
                    title,
                    userId,
                    permission
                }));
            });
            localStorage.setItem('__starred', JSON.stringify(state.get('starred').toJS()));
            break;
        }

        return state;
    }
}

const starredStore = new StarredStore(Dispatcher);

export default starredStore;
