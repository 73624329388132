/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';

import {AlertTypes} from '../../common/notification/alert';
import {NotificationActions} from '../../common/notification/notification-actions';
import {UploadFile} from '../../common/utils/utils';
import Dispatcher from '../../dispatcher/dispatcher';
import Request from '../../request';
import {RouterActions} from '../../router/router-actions';

import {PreloaderActions} from '~/src/preloader/preloader-actions';

const CONSTANTS = {
    MASS_UPDATE: {
        BULK_UPDATE: 'accounts.mass_update.bulk_update',
        CLEAR: 'accounts.mass_update.clear',
        FINDBY: {
            ERROR: 'accounts.mass_update.find_by.error',
            SUCCESS: 'accounts.mass_update.find_by.success'
        },
        GET: {
            AVAILABILITY: 'accounts.mass_update.get.availability',
            GROUPS: 'accounts.mass_update.get.groups',
            PARTNERS: 'accounts.mass_update.get.partners',
            ROLES: 'accounts.mass_update.get.roles',
            USERS: 'accounts.mass_update.get.users'
        },
        OPERATION_TYPES: {
            ADD: 'Add',
            REMOVE: 'Remove',
            UPDATE: 'Update'
        },
        SAVE: {
            ERROR: 'accounts.mass_update.save.error',
            SUCCESS: 'accounts.mass_update.save.success'
        },
        SET: {
            BRAINIAC_GROUPS: 'accounts.mass_update.set.brainiac_groups',
            CLIENT_REP_GROUPS: 'accounts.mass_update.set.client_rep_groups',
            DEFAULT_PARTNER: 'accounts.mass_update.set.default_partner',
            GROUPS: 'accounts.mass_update.set.groups',
            MODE: 'accounts.mass_update.set.mode',
            PARTNERS: 'accounts.mass_update.set.partners',
            PERMISSION_PACKAGE: 'accounts.mass_update.set.permission_package',
            ROLES: 'accounts.mass_update.set.roles',
            STATIONS: 'accounts.mass_update.set.stations',
            USERS_STATUS: 'accounts.mass_update.set.users_status',
        }
    }
};


class MassUpdateActions {

    bulkUpdate(attr, action) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.MASS_UPDATE.BULK_UPDATE,
            attr,
            bulkUpdate: action
        });
    }

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.MASS_UPDATE.CLEAR
        });
        return;
    }

    confirmPermissions(id, mode, massModeStep) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.MASS_UPDATE.SET.MODE,
            mode: mode
        });
        if (massModeStep) {
            RouterActions.redirect(`/accounts/mass-update/${id}/confirmation?mode=${massModeStep}`);
        } else {
            RouterActions.redirect(`/accounts/mass-update/${id}/confirmation`);
        }
    }

    findById(id) {
        Promise.all([
            Request.get(`user/mass-update/${id}`).exec(),
            Request.get(`user/mass-update/${id}/email`).exec()
        ])
            .spread((processRes, userRes) => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.MASS_UPDATE.FINDBY.SUCCESS,
                    massUpdateProcess: Immutable.fromJS(processRes.body),
                    users: Immutable.fromJS(userRes.body)
                });
                if (userRes.body.length === 0) {
                    NotificationActions.showAlertWarning('accounts.mass-update.no-users');
                }
            })
            .catch(err => {
                switch (err.status) {
                case 404:
                    RouterActions.notFound();
                    break;
                default:
                    Dispatcher.dispatch({
                        actionType: CONSTANTS.MASS_UPDATE.FINDBY.ERROR,
                        error: err
                    });
                    NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'accounts.mass-common.error.load');
                    break;
                }
                throw err;
            });
        return;
    }

    getAccountsAvailability(id) {
        Request.get(`user/mass-update/${id}/account-summary`).exec()
            .then(res => {
                const {existingAccountCount, nonExistingAccountCount, totalAccountCount, userMassUpdateId} = res.body;
                Dispatcher.dispatch({
                    actionType: CONSTANTS.MASS_UPDATE.GET.AVAILABILITY,
                    availability: Immutable.fromJS({
                        existingAccountCount,
                        nonExistingAccountCount,
                        totalAccountCount,
                        userMassUpdateId
                    })
                });
                RouterActions.redirect(`/accounts/mass-check/${id}/availability`);
            })
            .catch(err => {
                NotificationActions.showAlertDanger('accounts.mass-update.error.uploading-file');
                throw err;
            });
        return;
    }

    getConfirmationData(id) {
        Request.get(`user/mass-update/${id}/email`).exec()
            .then(res => {
                this.getUsersGroupsAndRoles(res.body);
                Dispatcher.dispatch({
                    actionType: CONSTANTS.MASS_UPDATE.GET.USERS,
                    users: Immutable.fromJS(res.body)
                });
            })
            .catch(err => {
                throw err;
            });
        return;
    }

    getGroups(i, groups) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.MASS_UPDATE.GET.GROUPS,
            groups: Immutable.fromJS(groups),
            userIndex: i
        });
        return;
    }

    getPartners(i, partners) {
        partners = partners.map(partner => {
            return {
                ...partner,
                id: partner.partnerId
            };
        });
        Dispatcher.dispatch({
            actionType: CONSTANTS.MASS_UPDATE.GET.PARTNERS,
            partners: Immutable.fromJS(partners),
            userIndex: i
        });
        return;
    }

    getRoles(i, roles) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.MASS_UPDATE.GET.ROLES,
            roles: Immutable.fromJS(roles),
            userIndex: i
        });
        return;
    }

    getUsersGroupsAndRoles(users) {
        users.forEach((user, i) => {
            let userId = user.userId;
            Promise.all([
                Request.get(`user/${userId}/group`).exec(),
                Request.get(`user/${userId}/role`).exec(),
                Request.get(`user/${userId}/partner`).exec(),
            ])
                .spread((groupRes, roleRes, partnerRes) => {
                    this.getGroups(i, groupRes.body);
                    this.getRoles(i, roleRes.body);
                    this.getPartners(i, partnerRes.body);
                });
        });
        return;
    }

    save(action, id, wbdGroups, brainiacGroups, stations, roles, defaultPartner, partners, clientRepGroupIds, excludeStations = false, userAccountStatus) {
        const preloaderSource = 'mass-update-actions.save';
        PreloaderActions.show(preloaderSource);

        //STUDIO-10497: wbdGroups, brainiacGroups and stations are sent as groupsData
        let groupsData = [];
        wbdGroups.forEach(g => {
            groupsData.push({
                groupId: g.get('id'),
                action: action
            });
        });
        brainiacGroups.forEach(g => {
            groupsData.push({
                groupId: g.get('id'),
                action: action
            });
        });

        if (!excludeStations) {
            stations.forEach(g => {
                groupsData.push({
                    groupId: g.get('id'),
                    action: action
                });
            });
        }

        let requests = [];
        let rolesData = roles.map(r => {
            return {
                roleId: r.get('id'),
                action: action
            };
        });
        let partnersData = partners.map(p => {
            let partner = {
                defaultPartner: false,
                partnerId: p.get('id'),
                action
            };
            if (p.get('id') === defaultPartner.get('id')) {
                partner.defaultPartner = true;
            }
            return partner;
        });

        if (groupsData.length) {
            requests.push(
                Request.put(`user/mass-update/${id}/group`).send(groupsData).exec()
            );
        }
        if (rolesData.size) {
            requests.push(
                Request.put(`user/mass-update/${id}/role`).send(rolesData.toJS()).exec()
            );
        }
        if (partnersData.size) {
            requests.push(
                Request.put(`user/mass-update/${id}/partner`).send(partnersData.toJS()).exec()
            );
        }
        if (clientRepGroupIds && clientRepGroupIds.length) {
            requests.push(
                Request.put(`user/mass-update/${id}/client-rep`).set('Accept', 'application/vnd.wbtv-v1+json;q=0.1').send(clientRepGroupIds).exec()
            );
        }

        if (action === CONSTANTS.MASS_UPDATE.OPERATION_TYPES.UPDATE) {
            requests.push(
                Request.put(`user/mass-update/${id}`).send({
                    overwriteGroups: true,
                    overwriteRoles: true,
                    overwritePartners: true,
                    overwriteStations: !excludeStations,
                    userAccountStatus
                }).exec()
            );
        }

        Promise.all(requests).then(() => {
            return Request.post(`user/mass-update/${id}/process`).exec().then(() => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.MASS_UPDATE.SAVE.SUCCESS
                });
                PreloaderActions.hide(preloaderSource);
                setTimeout(() => {
                    RouterActions.redirect('/accounts');
                    if (action === CONSTANTS.MASS_UPDATE.OPERATION_TYPES.UPDATE && userAccountStatus) {
                        NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, `accounts.mass-update.save.success${action}.${userAccountStatus}`);
                    } else {
                        NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, `accounts.mass-update.save.success${action}`);
                    }
                }, 1000);
            }).catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.MASS_UPDATE.SAVE.ERROR,
                    error: err
                });
                PreloaderActions.hide(preloaderSource);
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, `accounts.mass-update.save.error${action}`);
                throw err;
            });
        });
    }

    selectPermissionPackage(permissionPackage) {
        Request.get(`security/permission-package/${permissionPackage.get('id')}`).exec().then(res => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.MASS_UPDATE.SET.PERMISSION_PACKAGE,
                groups: Immutable.fromJS(res.body.groups).toSet(),
                roles: Immutable.fromJS(res.body.roles).toSet(),
                defaultPartnerId: res.body.defaultPartnerId,
                webPartnerIds: res.body.webPartnerIds,
            });
            return;
        });
        return;
    }

    setDefaultPartner(defaultPartner) {
        if (defaultPartner.partnerId) {
            defaultPartner = {
                ...defaultPartner,
                id: defaultPartner.partnerId
            };
        }

        Dispatcher.dispatch({
            actionType: CONSTANTS.MASS_UPDATE.SET.DEFAULT_PARTNER,
            defaultPartner: Immutable.fromJS(defaultPartner)
        });
        return;
    }

    setBrainiacGroups(groups) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.MASS_UPDATE.SET.BRAINIAC_GROUPS,
            selected: groups
        });

        return;
    }

    setGroups(groups) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.MASS_UPDATE.SET.GROUPS,
            selected: groups
        });
        return;
    }

    setStations(stations) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.MASS_UPDATE.SET.STATIONS,
            selected: stations
        });

        return;
    }

    setPartners(partners) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.MASS_UPDATE.SET.PARTNERS,
            selected: partners
        });
        return;
    }

    setRoles(roles) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.MASS_UPDATE.SET.ROLES,
            selected: roles
        });
        return;
    }

    setClientRepGroups(selectedClientRepGroupIds) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.MASS_UPDATE.SET.CLIENT_REP_GROUPS,
            selectedClientRepGroupIds: selectedClientRepGroupIds
        });
        return;
    }

    setUsersStatus(selectedUsersStatusId) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.MASS_UPDATE.SET.USERS_STATUS,
            selectedUsersStatusId: selectedUsersStatusId
        });
        return;
    }

    uploadMassUpdate(file, mode = 'edit') {
        if (file) {
            return UploadFile(
                'POST',
                'user/mass-update',
                file,
                new XMLHttpRequest()
            ).then((res) => {
                let id = JSON.parse(res).id;
                let statusMessage = JSON.parse(res).statusMessage;
                if (statusMessage !== null) {
                    let errorMsg;
                    switch (statusMessage) {
                    case 'Invalid file format. Please upload a CSV file.':
                        errorMsg = 'file-format';
                        break;
                    }
                    NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, `accounts.mass-update.error.${errorMsg}`);
                } else {
                    if (mode !== 'edit') {
                        this.getAccountsAvailability(id);
                    } else {
                        RouterActions.redirect(`/accounts/mass-update/${id}`);
                    }
                }
            }).catch(err => {
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'accounts.mass-update.error.uploading-file');
                throw err;
            });
        }
    }

}

let actions = new MassUpdateActions();

export {
    actions as MassUpdateActions,
    CONSTANTS as MassUpdateConstants
};
