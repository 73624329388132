/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {VideoDeliveryConstants} from './video-delivery-actions';
import Validations from '../../common/validations/validations';
import Dispatcher from '../../dispatcher/dispatcher';


/**
 * Look for a constant with id === objectId in a map.
 */
const findInConstant = (constants, objectId) => {
    return Object.keys(constants)
        .map(k => constants[k])
        .filter(obj => objectId === obj.id)[0];
};

const VideoDeliveryValidations = {
    selectedTitle: {
        label: 'hardac.video-inbox.summary.brainiac-title-info',
        validations: [
            Validations.missingIf(() =>
                store.getState().getIn(['selectedTitle', 'name']) === null || store.getState().getIn(['selectedTitle', 'name']) === undefined,
            'fas fa-asterisk'
            )
        ]
    },
    assetName: {
        label: 'hardac.video-inbox.summary.asset-name',
        validations: [
            Validations.missingIf(() =>
                store.getState().getIn(['videoDelivery', 'assetName']) === null ||
                store.getState().getIn(['videoDelivery', 'assetName']) === undefined ||
                store.getState().getIn(['videoDelivery', 'assetName']) === '',
            'fas fa-asterisk'
            )
        ]
    },
    contentType: {
        label: 'hardac.video-inbox.summary.content-type',
        validations: [
            Validations.missingIf(() =>
                store.getState().getIn(['videoDelivery', 'contentType']) === null || store.getState().getIn(['videoDelivery', 'contentType']) === undefined,
            'fas fa-asterisk'
            )
        ]
    },
    language: {
        label: 'hardac.video-inbox.summary.language',
        validations: [
            Validations.missingIf(() =>
                store.getState().getIn(['videoDelivery', 'language']) === null || store.getState().getIn(['videoDelivery', 'language']) === undefined,
            'fas fa-asterisk'
            )
        ]
    },
    showCode: {
        label: 'hardac.video-inbox.summary.show-code',
        validations: [
            Validations.missingIf(() =>
                store.getState().getIn(['videoDelivery', 'syncOptionType']) === VideoDeliveryConstants.SYNC_TO_OAP_TYPES.SEND_TO_OAP.id &&
                (store.getState().getIn(['videoDelivery', 'showCode']) === null ||
                store.getState().getIn(['videoDelivery', 'showCode']) === undefined ||
                store.getState().getIn(['videoDelivery', 'showCode']) === ''),
            'fas fa-asterisk'
            )
        ]
    },
    sourceFileLocation: {
        label: 'hardac.video-inbox.source-file',
        validations: [Validations.custom(
            function() {
                return 'Source File Location not found. Please contact HARDAC_support on Slack.';
            },
            function() {
                let isValid = true;
                if (store.getState().getIn(['videoDelivery', 'sourceFileLocation']) === null) {
                    isValid = false;
                }
                return isValid;
            }
        )]
    },
    barcodeNum: {
        label: 'hardac.video-inbox.summary.barcode',
        validations: [
            Validations.missingIf(() =>
                store.getState().getIn(['videoDelivery', 'syncOptionType']) === VideoDeliveryConstants.SYNC_TO_OAP_TYPES.PULL_FROM_OAP.id &&
                (store.getState().getIn(['videoDelivery', 'barcodeNum']) === null ||
                store.getState().getIn(['videoDelivery', 'barcodeNum']) === undefined ||
                store.getState().getIn(['videoDelivery', 'barcodeNum']) === ''),
            'fas fa-asterisk'
            )
        ]
    },
};

class VideoDeliveryStore extends ReduceStore {

    /* ignoreWarnings will prevent failed warning validations from being returned */
    getValidations(ignoreWarnings = false) {
        return Validations.validate(this.getState(), VideoDeliveryValidations, false, ignoreWarnings);
    }

    getStatus(statusId) {
        return findInConstant(VideoDeliveryConstants.STATUS_TYPES, statusId);
    }

    getInitialState() {
        return Immutable.fromJS({
            audioProfile: [],
            audioProfilePresetId: null,
            audioWaveforms: [],
            filters: {},
            mediaInfoTotalAudioTracks:  0,
            oapShowCodes: [],
            offset: 0,
            originalAudioProfile: [],
            originalVideoDelivery: {},
            selectedTitle: {},
            showPreloader: false,
            size: 0,
            sources: {},
            total: 0,
            videoDelivery: {},
            videoDeliveryList: [],
        });
    }

    reduce(state, action) {
        switch (action.actionType) {
        case VideoDeliveryConstants.CLEAR:
            state = this.getInitialState();
            break;

        case VideoDeliveryConstants.VIDEO_DELIVERY.APPROVE.SUCCESS:
        case VideoDeliveryConstants.VIDEO_DELIVERY.APPROVE.ERROR:
        case VideoDeliveryConstants.VIDEO_DELIVERY.FIND_BY_ID.ERROR:
        case VideoDeliveryConstants.VIDEO_DELIVERY.GET.ERROR:
        case VideoDeliveryConstants.VIDEO_DELIVERY.REJECT.ERROR:
            state = state.set('showPreloader', false);
            break;

        case VideoDeliveryConstants.AUDIO_PROFILE.ADD:
            const index = state.get('audioProfile').size;
            state = state.update(
                'audioProfile',
                ap => ap.push(Immutable.Map({
                    audioChannel: index+1,
                    type: null
                }))
            );
            break;

        case VideoDeliveryConstants.AUDIO_PROFILE.DELETE:
            state = state.update(
                'audioProfile',
                ap => ap.delete(action.index).map((r, i) => r.set('audioChannel', i+1))
            );
            break;

        case VideoDeliveryConstants.AUDIO_PROFILE.UPDATE:
            state = state.setIn(
                ['audioProfile', ...action.attr.split('.')],
                action.value
            );
            break;

        case VideoDeliveryConstants.AUDIO_PROFILE.SET:
            state = state.set('audioProfile', action.audioProfile);
            state = state.set('audioProfilePresetId', action.audioProfilePresetId);
            break;

        case VideoDeliveryConstants.AUDIO_PROFILE.MOVE_ELEMENT:
            const audioProfileList = state.get('audioProfile');
            const item = audioProfileList.get(action.from);
            state = state.set('audioProfile', audioProfileList.splice(action.from, 1).insert(action.to, item).map((r, i) =>
                r.set('audioChannel', i+1)
            ));
            break;

        case VideoDeliveryConstants.VIDEO_DELIVERY.AUDIO_CHANNELS.TOGGLE_ALL:
            //  If true mute all and enable only the selected channel,
            //  if false, enable all.

            // True if there are more than 1 audio track playing.
            const somePlaying = state.get('audioProfile').reduce((r, c) => {
                if (!c.get('isMuted')) {
                    return r + 1;
                }
                return r;
            }, 0) > 1;

            if (action.isMuted || somePlaying) {
                state = state.update(
                    'audioProfile',
                    ap => ap.map(c => c.set('isMuted', true))
                ).setIn(
                    ['audioProfile', action.index, 'isMuted'],
                    false
                );
            } else {
                state = state.update(
                    'audioProfile',
                    ap => ap.map(c => c.set('isMuted', false))
                );
            }
            break;

        case VideoDeliveryConstants.VIDEO_DELIVERY.AUDIO_TRACK.TOGGLE:
            state = state.updateIn(['audioProfile', action.index, 'isMuted'], isMuted => !isMuted);
            break;

        case VideoDeliveryConstants.VIDEO_DELIVERY.AUDIO_TRACK.TOGGLE_PROXY_LR:
            // Set to true the selected proxy L/R for the channel at action.index.
            // Set all others to false.
            state = state.update(
                'audioProfile',
                tracks => tracks.map((t, i) => t.set(action.lr, i === action.index))
            );
            break;

        case VideoDeliveryConstants.VIDEO_DELIVERY.AUDIO_WAVEFORMS.GET.SUCCESS:
            state = state.set('audioWaveforms', action.audioWaveforms);
            break;

        case VideoDeliveryConstants.VIDEO_DELIVERY.CLEAR_OAP:
            state = state.merge({
                selectedTitle: Immutable.Map(),
                videoDelivery: state.get('originalVideoDelivery')
            });

            break;
        case VideoDeliveryConstants.VIDEO_DELIVERY.GET.SUCCESS:
            state = state.merge({
                offset: action.offset,
                videoDeliveryList: action.videoDeliveryList,
                showPreloader: false,
                size: action.size,
                total: action.total
            });
            break;

        case VideoDeliveryConstants.VIDEO_DELIVERY.GET_OAP_BY_BARCODE.SUCCESS:
            state = state.setIn(['videoDelivery', 'barcodeNum'], action.barcode);
            break;

        case VideoDeliveryConstants.VIDEO_DELIVERY.GET_OAP_SHOWCODES.SUCCESS:
            state = state.set('oapShowCodes', action.oapShowCodes);
            break;

        case VideoDeliveryConstants.VIDEO_DELIVERY.FIND_BY_ID.SUCCESS:
            state = state.merge({
                audioProfile: action.audioProfile,
                originalAudioProfile: action.audioProfile,
                mediaInfoTotalAudioTracks: action.mediaInfoTotalAudioTracks,
                originalVideoDelivery: action.videoDelivery,
                videoDelivery: action.videoDelivery,
                showPreloader: false
            });
            if (action.videoDelivery.get('syncOptionType') === null) {
                state = state.setIn(['videoDelivery', 'syncOptionType'], VideoDeliveryConstants.SYNC_TO_OAP_TYPES.SEND_TO_OAP.id);
            }
            break;

        case VideoDeliveryConstants.VIDEO_DELIVERY.REJECT.SUCCESS:
            state = state.merge({
                showPreloader: false
            });
            state = state.setIn(['videoDelivery', 'status'], 'REJECTED');
            break;

        case VideoDeliveryConstants.VIDEO_DELIVERY.APPROVE.START:
        case VideoDeliveryConstants.VIDEO_DELIVERY.FIND_BY_ID.START:
        case VideoDeliveryConstants.VIDEO_DELIVERY.GET.START:
        case VideoDeliveryConstants.VIDEO_DELIVERY.REJECT.START:
            state = state.set('showPreloader', true);
            break;

        case VideoDeliveryConstants.FILTER.CLEAR:
            state = state.set('filters', Immutable.Map());
            break;

        case VideoDeliveryConstants.VIDEO_DELIVERY.SRC.GET.SUCCESS:
            state = state.setIn(['sources', action.videoDeliveryId.toString()], action.src);
            break;

        case VideoDeliveryConstants.FILTER.SET:
            state = state.setIn(['filters', ...action.attr.split('.')], action.value);
            break;

        case VideoDeliveryConstants.VIDEO_DELIVERY.UPDATE:
            state = state.setIn(
                ['videoDelivery', ...action.attr.split('.')],
                action.value
            );
            break;

        case VideoDeliveryConstants.UPDATE:
            state = state.setIn([...action.attr.split('.')], action.value);
            break;

        case VideoDeliveryConstants.VIDEO_DELIVERY.SET_SELECTED_TITLE:
            state = state.merge({
                selectedTitle: action.selectedTitle,
            });
            state = state.setIn(
                ['videoDelivery', 'titleId'],
                action.selectedTitle.get('id')
            );
            break;
        }

        return state;
    }
}

const store = new VideoDeliveryStore(Dispatcher);

export default store;

export {
    VideoDeliveryValidations
};
