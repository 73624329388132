/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Checkbox} from 'react-bootstrap';
import Select from 'react-select';

import {FormItem} from '../../../common/form/form';
import Panel from '../../../common/panel/panel';
import {WithStoreOnRoute} from '../../../common/store-on-route';
import {LayoutActions} from '../../../layout/layout-actions';
import SessionStore from '../../../user/session/session-store';
import {JobConstants, JobActions} from '../job-actions';
import JobStore from '../job-store';

const typeOptions = [];
Object.keys(JobConstants.JOB_TYPE).map((option) => (
    typeOptions.push(JobConstants.JOB_TYPE[option])
));
const TypeOptions = WithStoreOnRoute(class TypeOptions extends Component {
    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
        };
    }

    static get propTypes() {
        return {
            onChange: PropTypes.func,
            selected: PropTypes.number
        };
    }

    static get defaultProps() {
        return {
            onChange: undefined,
            selected: undefined
        };
    }

    render() {
        let selected = typeOptions.find((item) => {
            return item.id === this.props.selected;
        });

        // This is hacky, but if we pass undefined to Select value sometimes the internal state won't get cleared
        if (selected === undefined) {
            selected = null;
        }

        const options = typeOptions.sort((a, b) => a.label.localeCompare(b.label));

        return (
            <Panel title={this.context.intl.messages['hardac.processes.jobs.type']} collapsible defaultExpanded>
                <div data-style="display: block;" className="box-body">
                    <div className="form-group">
                        <Select
                            classNamePrefix="dropdown"
                            getOptionLabel={data => data.label}
                            getOptionValue={data => data.id}
                            isClearable={true}
                            isMulti={false}
                            name="type"
                            onChange={this.props.onChange}
                            options={options}
                            placeholder="Select..."
                            value={selected}
                        />
                    </div>
                </div>
            </Panel>
        );
    }
});

const statusOptions = [];
Object.keys(JobConstants.STATUS_TYPE).map((option) => (
    statusOptions.push(JobConstants.STATUS_TYPE[option])
));
const StatusOptions = WithStoreOnRoute(class StatusOptions extends Component {
    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
        };
    }

    static get propTypes() {
        return {
            onChange: PropTypes.func,
            selected: PropTypes.number
        };
    }

    static get defaultProps() {
        return {
            onChange: undefined,
            selected: undefined
        };
    }

    render() {
        let selected = statusOptions.find((item) => {
            return item.id === this.props.selected;
        });

        // This is hacky, but if we pass undefined to Select value sometimes the internal state won't get cleared
        if (selected === undefined) {
            selected = null;
        }

        const options = statusOptions.sort((a, b) => a.label.localeCompare(b.label));

        return (
            <Panel title={this.context.intl.messages['hardac.processes.jobs.status']} collapsible defaultExpanded>
                <div data-style="display: block;" className="box-body">
                    <div className="form-group">
                        <Select
                            classNamePrefix="dropdown"
                            getOptionLabel={data => data.label}
                            getOptionValue={data => data.id}
                            isClearable={true}
                            isMulti={false}
                            name="status"
                            onChange={this.props.onChange}
                            options={options}
                            placeholder="Select..."
                            value={selected}
                        />
                    </div>
                </div>
            </Panel>
        );
    }
});

const TimeStarted = WithStoreOnRoute(class TimeStarted extends Component {
    static get propTypes() {
        return {
            filters: PropTypes.instanceOf(Immutable.Map),
            location: PropTypes.object,
            onChange: PropTypes.func.isRequired
        };
    }
    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
        };
    }
    static get defaultProps() {
        return {
            filters: undefined,
            location: undefined,
        };
    }
    constructor(props) {
        super(props);

        this.setFormatDate = this.setFormatDate.bind(this);
    }

    setFormatDate(attr, value) {
        if (this.props.location) {
            this.setRouteState(attr, value)
                .setRouteState('operator', 'AND')
                .clearRouteState('offset')
                .apply();
            return;
        }
        this.props.onChange(attr, value);
    }

    render() {
        let model = this.props.filters;
        if (this.props.location) {
            model = this.getRouteState();
        }
        return (
            <Panel title={this.context.intl.messages['hardac.processes.jobs.time-started']} classTitle="box-title" collapsible defaultExpanded>
                <div className="box-body date-picker-filter">
                    <FormItem
                        label={this.context.intl.messages['filter-options.expire-date.from']}
                        attr="start-date-started"
                        datepicker={{popoverTargetOffset: '10px -36px', showYearDropdown: true}}
                        model={model}
                        setter={this.setFormatDate}
                        type="date"
                    />
                    <FormItem
                        label={this.context.intl.messages['filter-options.expire-date.to']}
                        attr="end-date-started"
                        datepicker={{popoverTargetOffset: '10px -36px', showYearDropdown: true}}
                        model={model}
                        setter={this.setFormatDate}
                        type="date"
                    />
                </div>
            </Panel>
        );
    }
});

const LastUpdated = WithStoreOnRoute(class LastUpdated extends Component {
    static get propTypes() {
        return {
            filters: PropTypes.instanceOf(Immutable.Map),
            location: PropTypes.object,
            onChange: PropTypes.func.isRequired
        };
    }
    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
        };
    }
    static get defaultProps() {
        return {
            filters: undefined,
            location: undefined,
            onChange: undefined
        };
    }
    constructor(props) {
        super(props);

        this.setFormatDate = this.setFormatDate.bind(this);
    }
    setFormatDate(attr, value) {
        if (this.props.location) {
            this.setRouteState(attr, value)
                .setRouteState('operator', 'AND')
                .clearRouteState('offset')
                .apply();
            return;
        }
        this.props.onChange(attr, value);
    }

    render() {
        let model = this.props.filters;
        if (this.props.location) {
            model = this.getRouteState();
        }
        return (
            <Panel title={this.context.intl.messages['hardac.processes.jobs.last-updated']} classTitle="box-title" collapsible defaultExpanded>
                <div className="box-body date-picker-filter">
                    <FormItem
                        label={this.context.intl.messages['filter-options.expire-date.from']}
                        attr="start-date-updated"
                        datepicker={{popoverTargetOffset: '10px -36px', showYearDropdown: true}}
                        model={model}
                        setter={this.setFormatDate}
                        type="date"
                    />
                    <FormItem
                        label={this.context.intl.messages['filter-options.expire-date.to']}
                        attr="end-date-updated"
                        datepicker={{popoverTargetOffset: '10px -36px', showYearDropdown: true}}
                        model={model}
                        setter={this.setFormatDate}
                        type="date"
                    />
                </div>
            </Panel>
        );
    }
});

class FilterOptions extends Component {
    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
        };
    }
    static get propTypes() {
        return {
            filters: PropTypes.instanceOf(Immutable.Map),
            location: PropTypes.object,
        };
    }

    static get defaultProps() {
        return {
            filters: undefined,
            location: undefined
        };
    }

    static calculateState() {
        return {
            autoRefreshObservable: JobStore.getState().get('autoRefreshObservable'),
            isAutoRefreshChecked: JobStore.getState().getIn(['autoRefresh', 'isChecked']),
            user: SessionStore.getState().get('authUser'),
        };
    }

    static getStores() {
        return [JobStore, SessionStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.handleToggleFiltersPanel = this.handleToggleFiltersPanel.bind(this);
        this.handleChangeAutoRefresh = this.handleChangeAutoRefresh.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.updateAutoRefresh = this.updateAutoRefresh.bind(this);
    }

    componentDidMount() {
        if (this.state.isAutoRefreshChecked && this.state.autoRefreshObservable === null) {
            this.updateAutoRefresh(true);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.filters !== this.props.filters ||
            nextProps.location !== this.props.location ||
            nextState.isAutoRefreshChecked !== this.state.isAutoRefreshChecked) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.filters !== this.props.filters || prevProps.location !== this.props.location) {
            if (this.state.isAutoRefreshChecked) {
                this.updateAutoRefresh(false);
                this.updateAutoRefresh(true);
            }
        }
    }

    componentWillUnmount() {
        if (this.state.isAutoRefreshChecked) {
            JobActions.cancelAutoRefresh(this.state.autoRefreshObservable);
        }
    }

    handleChangeAutoRefresh(event) {
        this.updateAutoRefresh(event.target.checked);
    }

    handleToggleFiltersPanel() {
        LayoutActions.toggleFiltersPanel();
    }

    onSelectChange(attr, selected) {
        if (this.props.location) {
            this.setRouteState(attr, selected?.id)
                .setRouteState('operator', 'AND')
                .clearRouteState('offset')
                .apply();
            return;
        }
        JobActions.setFilter(attr, selected.id || undefined);
        JobActions.setFilter('operator', 'AND');
        JobActions.get(
            JobStore.getState().get('filters'),
            0,
            20
        );
    }

    onChange(attr, value) {
        JobActions.setFilter(attr, value);
        JobActions.setFilter('operator', 'AND');
        JobActions.get(
            JobStore.getState().get('filters'),
            0,
            20
        );
    }

    updateAutoRefresh(isChecked) {
        JobActions.setAutoRefresh(
            isChecked,
            this.state.autoRefreshObservable,
            this.state.user.get('id'),
            this.getRouteState(this.props)
        );
    }

    render() {
        let typeSelected ;
        let statusSelected;

        if (this.props.location) {
            typeSelected = this.props.location.query.type || null;
            statusSelected = this.props.location.query.status || null;
        } else {
            typeSelected = JobStore.getState().get('filters').get('type')?.id || -1;
            statusSelected = JobStore.getState().get('filters').get('status')?.id || -1;
        }
        return (
            <div>
                <aside className="control-sidebar B(0) Ovx(h) Ovy(a)">
                    <div className="tab-content">
                        <p>
                            <button className="btn btn-block bg-navy" onClick={this.handleToggleFiltersPanel}>
                                <i className="fas fa-chevron-right pull-right"></i>&nbsp;{this.context.intl.messages['common.close-options']}
                            </button>
                        </p>
                        <hr/>
                        <div>
                            <Checkbox
                                checked={this.state.isAutoRefreshChecked}
                                onChange={this.handleChangeAutoRefresh}
                            >
                                {this.context.intl.messages['common.auto-refresh']}
                            </Checkbox>
                            <p>
                                <small>
                                    <em>{this.context.intl.messages['common.auto-refresh.description']}</em>
                                </small>
                            </p>
                        </div>
                        <hr/>
                        <TypeOptions
                            selected={typeSelected}
                            onChange={this.onSelectChange.bind(this, 'type')}
                        />
                        <StatusOptions
                            selected={statusSelected}
                            onChange={this.onSelectChange.bind(this, 'status')}
                        />
                        <TimeStarted
                            filters={this.props.filters}
                            onChange={this.onChange}
                            location={this.props.location}
                        />
                        <LastUpdated
                            filters={this.props.filters}
                            onChange={this.onChange}
                            location={this.props.location}
                        />
                    </div>
                </aside>
                <div style={{position: 'fixed', height: 'auto'}} className="control-sidebar-bg"></div>
            </div>
        );
    }
}

export default WithStoreOnRoute(Container.create(FilterOptions));

export {
    TypeOptions,
    StatusOptions,
    TimeStarted,
    LastUpdated
};
