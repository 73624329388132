/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, ControlLabel, FormControl, InputGroup, FormGroup, Modal} from 'react-bootstrap';

import SendToOAPModal from './send-to-oap-modal';
import {TimelineActions} from '../timeline-actions';

import {FormRow, FormSection} from '~/src/common/form/form';
import {SectionPanel} from '~/src/common/panel/panel';
import {TimecodeFromSeconds, TimecodeToString} from '~/src/common/utils/timecode';

class FileInfo extends React.Component {
    static get propTypes() {
        return {
            isEditInfo: PropTypes.bool.isRequired,
            oapShowCodes: PropTypes.instanceOf(Immutable.List),
            timeline: PropTypes.instanceOf(Immutable.Map).isRequired,
            viewingMode: PropTypes.string.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            oapShowCodes: Immutable.List(),
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            showExportAvidModal: false,
            showExportTimelineModal: false,
            showSendToOAPModal: false
        };

        this.handleCopyAvidFolderPath = this.handleCopyAvidFolderPath.bind(this);
        this.handleCopyExportFolderPath = this.handleCopyExportFolderPath.bind(this);
        this.handleExportAvid = this.handleExportAvid.bind(this);
        this.handleExportTimeline = this.handleExportTimeline.bind(this);
        this.handleToggleExportAvidModal = this.handleToggleExportAvidModal.bind(this);
        this.handleToggleExportTimelineModal = this.handleToggleExportTimelineModal.bind(this);
        this.handleToggleSendToOAPModal = this.handleToggleSendToOAPModal.bind(this);
    }

    handleCopyAvidFolderPath() {
        const value = '/mnt/MAM/HARDAC-ONPREM-RETURN-AVID';
        navigator.clipboard.writeText(value);
    }

    handleCopyExportFolderPath() {
        const value = '\\\\wwtvm-qgateway.amer.warnerbros.com\\MAM\\HARDAC-ONPREM-RETURN';
        navigator.clipboard.writeText(value);
    }

    handleExportAvid() {
        this.handleToggleExportAvidModal();
        TimelineActions.exportAvid(this.props.timeline.get('id'));
    }

    handleExportTimeline() {
        this.handleToggleExportTimelineModal();
        TimelineActions.exportTimeline(this.props.timeline.get('id'));
    }

    handleToggleExportAvidModal() {
        this.setState((prevState) => ({
            showExportAvidModal: !prevState.showExportAvidModal
        }));
    }

    handleToggleExportTimelineModal() {
        this.setState((prevState) => ({
            showExportTimelineModal: !prevState.showExportTimelineModal
        }));
    }

    handleToggleSendToOAPModal() {
        this.setState((prevState) => ({
            showSendToOAPModal: !prevState.showSendToOAPModal
        }));
    }

    render() {
        let {
            frameRate,
            runtime,
        } = this.props.timeline.toJS();

        const timeline = this.props.timeline;
        if (runtime && frameRate) {
            runtime = TimecodeToString(TimecodeFromSeconds(runtime, parseFloat(frameRate)));
        }

        let firstFrameTimecode = '-';
        if (timeline.get('firstFrameTimecode')) {
            firstFrameTimecode = TimecodeToString(timeline.get('firstFrameTimecode'));
        }

        let sendToOAPForm = <Button
            bsSize="large"
            className="btn btn-default-outline"
            disabled={false}
            onClick={this.handleToggleSendToOAPModal}
        ><i className="fa-solid fa-right-to-bracket" />{this.context.intl.messages['hardac.timeline.summary.send-to-oap.title']}</Button>;
        if (this.props.timeline.get('barcodeNum')) {
            sendToOAPForm = <div>
                <i className="fa-solid fa-check"/><span>{this.context.intl.messages['hardac.timeline.summary.send-to-oap.sent']}</span>
                <br/>
                <ControlLabel>{this.context.intl.messages['hardac.timeline.summary.send-to-oap.barcode']}</ControlLabel><span> {this.props.timeline.get('barcodeNum')}</span>
            </div>;
        }

        const modalExportTimeline = <Modal
            className={ClassNames({'skin-dark': this.props.viewingMode === 'skin-dark'})}
            show={this.state.showExportTimelineModal}
            onHide={this.handleToggleExportTimelineModal}
        >
            <Modal.Header className="bg-gray" closeButton>
                <Modal.Title className="text-center" >{this.context.intl.messages['hardac.timeline.export.modal.title']}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormSection>
                    <div className="text-center">
                        <i>{this.context.intl.messages['hardac.timeline.export.modal.body']}</i>
                    </div>
                </FormSection>
            </Modal.Body>
            <Modal.Footer>
                <Button className="pull-left Ml(5)" onClick={this.handleToggleExportTimelineModal}><i className="fas fa-ban" /> {this.context.intl.messages['common.cancel']}</Button>
                <Button className="btn btn-success-outline" onClick={this.handleExportTimeline}>
                    <i className="far fa-cloud-download" /> {this.context.intl.messages['hardac.timeline.export.modal.export-now']}
                </Button>
            </Modal.Footer>
        </Modal>;

        const modalExportAvid = <Modal
            className={ClassNames({'skin-dark': this.props.viewingMode === 'skin-dark'})}
            show={this.state.showExportAvidModal}
            onHide={this.handleToggleExportAvidModal}
        >
            <Modal.Header className="bg-gray" closeButton>
                <Modal.Title className="text-center" >{this.context.intl.messages['hardac.timeline.export.modal.title']}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormSection>
                    <div className="text-center">
                        <i>{this.context.intl.messages['hardac.timeline.export.modal.body']}</i>
                    </div>
                </FormSection>
            </Modal.Body>
            <Modal.Footer>
                <Button className="pull-left Ml(5)" onClick={this.handleToggleExportAvidModal}><i className="fas fa-ban" /> {this.context.intl.messages['common.cancel']}</Button>
                <Button className="btn btn-success-outline" onClick={this.handleExportAvid}>
                    <i className="far fa-cloud-download" /> {this.context.intl.messages['hardac.timeline.export.modal.export-now']}
                </Button>
            </Modal.Footer>
        </Modal>;

        const sendToOAPModal = <SendToOAPModal
            className={ClassNames({'skin-dark': this.props.viewingMode === 'skin-dark'})}
            isEditInfo={this.props.isEditInfo}
            oapShowCodes={this.props.oapShowCodes}
            onHide={this.handleToggleSendToOAPModal}
            show={this.state.showSendToOAPModal}
            timeline={this.props.timeline}
        />;

        const sourceStorageTierType = timeline.getIn(['sourceFile', 'storageTierType']);
        const proxyStorageTierType = timeline.getIn(['proxyFile', 'storageTierType']);

        return (
            <div>
                {modalExportAvid}
                {modalExportTimeline}
                {sendToOAPModal}
                <SectionPanel title={this.context.intl.messages['hardac.timeline.summary.file-info']}>
                    <FormSection>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.timeline.summary.file-name']}</ControlLabel>
                            <FormControl disabled type="text" value={timeline.get('displayName', '-')} />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.timeline.summary.source-path']}</ControlLabel>
                            <div className="pull-right">{this.context.intl.messages['hardac.timeline.summary.archive-status']}: {sourceStorageTierType}</div>
                            <FormControl disabled type="text" value={timeline.getIn(['sourceFile', 'fileUri']) || '-'} />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.timeline.summary.proxy-path']}</ControlLabel>
                            <div className="pull-right">{this.context.intl.messages['hardac.timeline.summary.archive-status']}: {proxyStorageTierType}</div>
                            <FormControl disabled type="text" value={timeline.getIn(['proxyFile', 'fileUri']) || '-'} />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.timeline.summary.streaming-proxy-path']}</ControlLabel>
                            <FormControl disabled type="text" value={timeline.getIn(['streamProxyFile', 'fileUri']) || '-'} />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.timeline.summary.thumbnails-sprite-path']}</ControlLabel>
                            <FormControl disabled type="text" value={timeline.getIn(['spriteFile', 'fileUri']) || '-'} />
                        </FormGroup>
                        <hr />
                        <FormRow>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['hardac.timeline.export.export-source-file']}</ControlLabel>
                                <br />
                                <Button
                                    bsSize="large"
                                    className="btn btn-default-outline"
                                    disabled={false}
                                    onClick={this.handleToggleExportTimelineModal}
                                ><i className="fa-solid fa-cloud-arrow-down" /> {this.context.intl.messages['hardac.timeline.export.export-timeline']}</Button>
                            </FormGroup>
                            <FormGroup>
                                <small><em>{this.context.intl.messages['hardac.timeline.export.export-timeline.description']}</em></small>
                            </FormGroup>
                        </FormRow>
                        <hr />
                        <FormRow>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['hardac.timeline.summary.export-folder-path']}</ControlLabel>
                                <InputGroup>
                                    <FormControl disabled type="text" value="\\wwtvm-qgateway.amer.warnerbros.com\MAM\HARDAC-ONPREM-RETURN" />
                                    <InputGroup.Addon style={{padding: 0}}>
                                        <Button
                                            bsSize="large"
                                            className="btn btn-default-outline"
                                            onClick={this.handleCopyExportFolderPath}
                                            style={{padding: '6px 12px'}}
                                        ><i className="fa-regular fa-copy" /></Button>
                                    </InputGroup.Addon>
                                </InputGroup>
                            </FormGroup>
                        </FormRow>
                        <hr />
                        <FormRow>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['hardac.timeline.summary.send-to-oap.title']}</ControlLabel>
                                <br />
                                {sendToOAPForm}
                            </FormGroup>
                            <FormGroup>
                                <small><em>{this.context.intl.messages['hardac.timeline.summary.send-to-oap.note']}</em></small>
                            </FormGroup>
                        </FormRow>
                        <hr />
                        <FormRow>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['hardac.timeline.export.export-avid']}</ControlLabel>
                                <br />
                                <Button
                                    bsSize="large"
                                    className="btn btn-default-outline"
                                    disabled={false}
                                    onClick={this.handleToggleExportAvidModal}
                                ><i className="fa-solid fa-cloud-arrow-down" /> {this.context.intl.messages['hardac.timeline.export.export-avid']}</Button>
                            </FormGroup>
                            <FormGroup>
                                <small><em>{this.context.intl.messages['hardac.timeline.export.export-avid.description']}</em></small>
                            </FormGroup>
                        </FormRow>
                        <hr />
                        <FormRow>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['hardac.timeline.export.export-avid.path.caption']}</ControlLabel>
                                <InputGroup>
                                    <FormControl disabled type="text" value={this.context.intl.messages['hardac.timeline.export.export-avid.path']} />
                                    <InputGroup.Addon style={{padding: 0}}>
                                        <Button
                                            bsSize="large"
                                            className="btn btn-default-outline"
                                            onClick={this.handleCopyAvidFolderPath}
                                            style={{padding: '6px 12px'}}
                                        ><i className="fa-regular fa-copy" /></Button>
                                    </InputGroup.Addon>
                                </InputGroup>
                            </FormGroup>
                        </FormRow>
                        <hr />
                        <FormRow>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['hardac.timeline.summary.duration']}</ControlLabel>
                                <FormControl disabled type="text" value={runtime || '-'} />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['hardac.timeline.summary.start-time']}</ControlLabel>
                                <FormControl disabled type="text" value={firstFrameTimecode} />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['hardac.timeline.summary.end-time']}</ControlLabel>
                                <FormControl disabled type="text" value="-" />
                            </FormGroup>
                        </FormRow>
                        <FormRow>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['hardac.timeline.summary.framerate']}</ControlLabel>
                                <FormControl disabled type="text" value={frameRate || '-'} />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['hardac.timeline.summary.container']}</ControlLabel>
                                <FormControl disabled type="text" value={timeline.get('encodeProfile', '-')} />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['hardac.timeline.summary.file-size']}</ControlLabel>
                                <FormControl disabled type="text" value="-" />
                            </FormGroup>
                        </FormRow>
                        <FormRow>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['hardac.timeline.summary.format']}</ControlLabel>
                                <FormControl disabled type="text" value={timeline.get('format', '-')} />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['hardac.timeline.summary.format-profile']}</ControlLabel>
                                <FormControl disabled type="text" value={timeline.get('formatProfile', '-')} />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['hardac.timeline.summary.sampled-width']}</ControlLabel>
                                <FormControl disabled type="text" value={timeline.get('width', '-')} />
                            </FormGroup>
                        </FormRow>
                        <FormRow>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['hardac.timeline.summary.sampled-height']}</ControlLabel>
                                <FormControl disabled type="text" value={timeline.get('height', '-')} />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['hardac.timeline.summary.pixel-aspect-ratio']}</ControlLabel>
                                <FormControl disabled type="text" value={timeline.get('pixelAspectRatio', '-')} />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['hardac.timeline.summary.display-aspect-ratio']}</ControlLabel>
                                <FormControl disabled type="text" value={timeline.get('displayAspectRatio', '-')} />
                            </FormGroup>
                        </FormRow>
                    </FormSection>
                </SectionPanel>
            </div>
        );
    }
}

export default FileInfo;
