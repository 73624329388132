/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {LanguageCodeType} from '@wbdt-sie/brainiac-web-common';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ControlLabel, FormGroup} from 'react-bootstrap';

import {FormRow, FormSection, LocalizedFormItem} from '../../../common/form/form';
import {SectionPanel} from '../../../common/panel/panel';
import {LocalizedTinyMCE} from '../../../common/tinymce/tinymce';
import {CompareImmutable} from '../../../common/utils/utils';
import {WithValidations} from '../../../common/validations/validations';
import CompanySelect from '../../../lookup/company/company-select';
import CompanyStore from '../../../lookup/company/company-store';
import {HandleSelect, MapToValues} from '../../../lookup/lookup-select-helpers';
import {TitleActions} from '../../title-actions';
import {TitleLocalizedActions} from '../../title-localized-actions';
import {TitleValidations} from '../../title-store';

/**
 * Title props to watch.
 */
const titleProps = [
    'promotionalLegalline', 'rightExpiration', 'rightsAcquiredFrom',
    'programLegalline', 'territory'
];

class Rights extends Component {

    static get propTypes() {
        return {
            currentLanguage: PropTypes.string,
            disabled: PropTypes.bool,
            expanded: PropTypes.bool,
            title: PropTypes.instanceOf(Immutable.Map).isRequired,
            titleLocalized: PropTypes.instanceOf(Immutable.Map)
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true,
            expanded: false,
            currentLanguage: undefined,
            titleLocalized: undefined
        };
    }

    constructor(props) {
        super(props);

        this.handleSelectRightsAcquiredFrom = HandleSelect('rightsAcquiredFrom', TitleActions.updateTitle, {multi: true}).bind(this);
        this.handleTinyMCEChange = this.handleTinyMCEChange.bind(this);
        this.mapRightsAcquiredFrom = MapToValues('id', CompanyStore).bind(this);
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.disabled !== this.props.disabled ||
            nextProps.titleLocalized !== this.props.titleLocalized || // actually have to check if name or selected language has changed
            nextProps.currentLanguage !== this.props.currentLanguage ||
            nextProps.expanded !== this.props.expanded ||
            !CompareImmutable(nextProps.title, this.props.title, titleProps)) {
            return true;
        }

        return false;
    }

    handleTinyMCEChange(f, attr) {
        return function handler(e) {
            f(attr, e.target.getContent());
            return;
        };
    }

    render() {
        let localized = this.props.currentLanguage !== LanguageCodeType.DEFAULT_LANGUAGE;
        let f = TitleActions.updateTitle;
        if (localized) {
            f = TitleLocalizedActions.update;
        }

        let localizedProgramLegalline = '';
        let localizedPromotionalLegalLine = '';
        if (this.props.titleLocalized) {
            localizedProgramLegalline = this.props.titleLocalized.get('programLegalline') || '';
            localizedPromotionalLegalLine = this.props.titleLocalized.get('promotionalLegalline') || '';
        }

        let disabledOnLocalized = this.props.disabled || localized;
        return (
            <SectionPanel
                expanded={this.props.expanded}
                title={this.context.intl.messages['titles.create.summary.rights']}
                validationState={this.getAccordionValidationState(this.props.title, titleProps, TitleValidations)}
                localized={localized}
            >
                <FormSection>
                    <FormRow>
                        <LocalizedFormItem
                            attr="territory"
                            label={this.context.intl.messages['titles.create.summary.territory']}
                            disabled={this.props.disabled}
                            max={80}
                            model={this.props.title}
                            setter={TitleActions.updateTitle}
                            type="text"
                            localized={localized}
                            localizedModel={this.props.titleLocalized}
                            localizedSetter={TitleLocalizedActions.update}
                        />
                        <LocalizedFormItem
                            attr="rightExpiration"
                            label={this.context.intl.messages['titles.create.summary.right-expiration']}
                            disabled={this.props.disabled}
                            max={80}
                            model={this.props.title}
                            setter={TitleActions.updateTitle}
                            type="text"
                            localized={localized}
                            localizedModel={this.props.titleLocalized}
                            localizedSetter={TitleLocalizedActions.update}
                        />
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['titles.create.summary.rights-acquired-from']}</ControlLabel>
                            <CompanySelect
                                getOptionLabel={data => data.name}
                                getOptionValue={data => data.id}
                                isClearable={true}
                                disabled={disabledOnLocalized}
                                multi={true}
                                name="rightsAcquiredFrom"
                                onChange={this.handleSelectRightsAcquiredFrom}
                                value={this.mapRightsAcquiredFrom(this.props.title.get('rightsAcquiredFrom'))}
                            />
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <LocalizedTinyMCE
                            content={this.props.title.get('programLegalline') || ''}
                            disabled={this.props.disabled}
                            id="standard-legal-line"
                            onChange={this.handleTinyMCEChange(f, 'programLegalline')}
                            localized={localized}
                            localizedContent={localizedProgramLegalline}
                            title={this.context.intl.messages['titles.create.summary.program-legal-line']}
                        />
                    </FormRow>
                    <FormRow>
                        <LocalizedTinyMCE
                            content={this.props.title.get('promotionalLegalline') || ''}
                            disabled={this.props.disabled}
                            id="extended-legal-line"
                            onChange={this.handleTinyMCEChange(f, 'promotionalLegalline')}
                            localized={localized}
                            localizedContent={localizedPromotionalLegalLine}
                            title={this.context.intl.messages['titles.create.summary.promotional-materials-legal-line']}
                        />
                    </FormRow>
                </FormSection>
            </SectionPanel>
        );
    }

}

export default WithValidations(Rights);
