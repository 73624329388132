/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import Immutable from 'immutable';
import jQuery from 'jquery';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Tooltip, OverlayTrigger} from 'react-bootstrap';

import {AssetTabActions} from './asset-tab-actions';
import Categories from './categories';
import InnerVideoTable from './inner-video-table';

import {SlidingPanelActions} from '~/src/common/sliding-panel/sliding-panel-actions';

require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('datatables.net-rowreorder-bs/css/rowReorder.bootstrap.css');
require('../styles/data-tables-brainiac.css');
// Load jQuery and register the datatables plugin.
require('datatables.net-rowreorder');
require('datatables.net-responsive-bs');


class VideoTableItem extends Component {

    static get propTypes() {
        return {
            asset: PropTypes.instanceOf(Immutable.Map).isRequired,
            columns: PropTypes.array,
            copyFromAsset: PropTypes.instanceOf(Immutable.Map),
            copyToAsset: PropTypes.instanceOf(Immutable.Map),
            disableStacking: PropTypes.bool,
            disabled: PropTypes.bool,
            onMoveAssetUp: PropTypes.func.isRequired,
            onReplaceVideo: PropTypes.func,
            order: PropTypes.number.isRequired,
            path: PropTypes.string.isRequired,
            thumbnails: PropTypes.instanceOf(Immutable.List).isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
            router: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            columns: [],
            copyFromAsset: null,
            copyToAsset: null,
            disabled: false,
            disableStacking: false,
            onReplaceVideo: () => void 0
        };
    }

    constructor(props) {
        super(props);

        this.handleLink = this.handleLink.bind(this);
        this.handleMoveAssetUp = this.handleMoveAssetUp.bind(this);
        this.handleShowAsset = this.handleShowAsset.bind(this);
        this.handleCatalogs = this.handleCatalogs.bind(this);
        this.handleReplaceVideo = this.handleReplaceVideo.bind(this);
    }

    shouldComponentUpdate(nextProps/*, nextState*/) {
        if (nextProps.asset !== this.props.asset ||
            nextProps.path !== this.props.path || // if asset or order change path will but for completion
            nextProps.order !== this.props.order ||
            nextProps.copyFromAsset && !nextProps.copyFromAsset.equals(this.props.copyFromAsset) ||
            nextProps.copyToAsset && !nextProps.copyToAsset.equals(this.props.copyToAsset) ||
            nextProps.thumbnails !== this.props.thumbnails) {
            return true;
        }
        return false;
    }

    handleLink(event) {
        event.preventDefault();
        if (this.props.disabled) {
            return;
        }
        let pathArray = this.props.path.split('.');
        pathArray = pathArray.splice(0, pathArray.length-1);
        AssetTabActions.link(pathArray, this.props.order-1, this.props.order-2);
    }

    handleCatalogs(event) {
        event.preventDefault();
        if (!this.props.asset.get('catalogs')) {
            AssetTabActions.getCatalogs(this.props.asset.get('assetId'), this.props.path.split('.'));
        }
    }

    handleMoveAssetUp(event) {
        event.preventDefault();
        this.props.onMoveAssetUp(this.props.order);
    }

    handleReplaceVideo(event) {
        event.preventDefault();
        this.props.onReplaceVideo(this.props.asset);
    }

    handleShowAsset(event) {
        event.preventDefault();
        AssetTabActions.show(this.props.path.split('.'), this.props.asset);
    }

    render() {
        let createdDate = Moment(this.props.asset.get('createdDate'));
        /* istanbul ignore else */
        if (createdDate.isValid()) {
            createdDate = createdDate.format(this.context.intl.messages['date-format']);
        } else {
            createdDate = '-';
        }
        let linkIcon = '';
        let handler;
        let stack = null;
        /* istanbul ignore else */
        if (!this.props.disableStacking) {
            /* istanbul ignore next */
            if (this.props.asset.get('children') && this.props.asset.get('children').size > 0) {
                linkIcon = 'far fa-object-ungroup';
            } else {
                if (this.props.order !== 1) {
                    linkIcon = 'fas fa-link';
                    handler = this.handleLink;
                }
            }

            stack = (
                <td onClick={handler}>
                    <a className="video-stack-link">
                        <i className={ClassNames(linkIcon)}/>
                    </a>
                </td>
            );
        }
        let path = this.props.path+'.children';
        let catalogs = this.context.intl.messages['titles.create.assets.loading-catalogs'];
        if (this.props.asset.get('catalogs')) {
            // Filter catalogs auto generated by Events
            const catalogsToShow = this.props.asset.get('catalogs').filter(catalog => {
                return !catalog.get('name').toLowerCase().startsWith('event:');
            });
            catalogs = catalogsToShow.map(t => t.get('name')).reduce((r, v, i) => {
                if (i === 0) {
                    return `Catalogs: ${v}`;
                }

                return `${r}, ${v}`;
            }, this.context.intl.messages['titles.create.assets.no-catalogs']);
        }
        let direction;
        if (this.props.columns.indexOf('direction') !== -1) {
            direction = <td>&nbsp;</td>;
            /* istsanbul ignore next */
            switch (true) {
            case this.props.asset.get('defaultVideoBanner') === 1:
                direction = (
                    <td className="text-center">
                        <i className="fas fa-expand-arrows-alt"></i>
                    </td>
                );
                break;
            }
        }
        let replaceVideo;
        if (this.props.columns.indexOf('replaceVideo') !== -1) {
            replaceVideo = <td className="text-center">
                <a className="video-move-top" onClick={this.handleReplaceVideo}>
                    <button className="move-to-top btn btn-primary-outline btn-sm parcel-tooltip" title={this.context.intl.messages['titles.create.assets.replace-video']}>
                        <i className="fa-solid fa-right-left"></i>
                        <span className="tooltip-inner">{this.context.intl.messages['titles.create.assets.replace-video']}</span>
                    </button>
                </a>
            </td>;
        }

        let tooltip = <Tooltip id={'tooltip-'+this.props.asset.get('assetId')} positionTop={0}>{catalogs}</Tooltip>;

        let imageSrc = this.props.thumbnails.find(
            th => th.get('videoId') === this.props.asset.get('assetId')
        )?.getIn(['thumbnailList', 0, 'thumbnailUrl']) || '';

        // FIXME: this happens when adding existing video assets. Normalize the code
        // so that it always works the same way.
        if (!imageSrc && this.props.asset.getIn(['thumbnails', 0, 'thumbnailUrl'])) {
            imageSrc = this.props.asset.getIn(['thumbnails', 0, 'thumbnailUrl']);
        }

        const isMFARequired = this.props.asset.get('mfaRequired');
        const isCopyFromAsset = this.props.asset.get('assetId') === this.props.copyFromAsset?.get('assetId') || this.props.asset.get('assetId') === this.props.copyFromAsset?.get('id');
        const isCopyToAsset = this.props.asset.get('assetId') === this.props.copyToAsset?.get('assetId');

        const copyFromAssetTooltip = (
            <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-copy-from-video-${this.props.asset.get('assetId')}`} positionTop={0}>
                {this.context.intl.messages['titles.create.assets.replace-video.copy-from-tooltip']}
            </Tooltip>}>
                <i className="glyphicon glyphicon-paste" data-toggle="tooltip"/>
            </OverlayTrigger>
        );

        const copyToAssetTooltip = (
            <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-copy-to-video-${this.props.asset.get('assetId')}`} positionTop={0}>
                {this.context.intl.messages['titles.create.assets.replace-video.copy-to-tooltip']}
            </Tooltip>}>
                <i className="glyphicon glyphicon-copy" data-toggle="tooltip"/>
            </OverlayTrigger>
        );

        let titleLink;
        switch (true) {
        case isMFARequired && isCopyFromAsset:
            titleLink = <span>{copyFromAssetTooltip}&nbsp;<i className="fas fa-lock-alt"></i>&nbsp;{this.props.asset.get('name')}</span>;
            break;
        case isMFARequired && isCopyToAsset:
            titleLink = <span>{copyToAssetTooltip}&nbsp;<i className="fas fa-lock-alt"></i>&nbsp;{this.props.asset.get('name')}</span>;
            break;
        case isMFARequired:
            titleLink = <span><i className="fas fa-lock-alt"></i>&nbsp;{this.props.asset.get('name')}</span>;
            break;
        case isCopyFromAsset:
            titleLink = <span>{copyFromAssetTooltip}&nbsp;{this.props.asset.get('name')}</span>;
            break;
        case isCopyToAsset:
            titleLink = <span>{copyToAssetTooltip}&nbsp;{this.props.asset.get('name')}</span>;
            break;
        default:
            titleLink = this.props.asset.get('name');
            break;
        }

        titleLink = <a href="#" onClick={this.handleShowAsset}>{titleLink}</a>;

        return (
            <tr>
                <td className={ClassNames({'row-reorder-handle':!this.props.disabled})}>{this.props.order}</td>
                <td>
                    <div className="video-thumbnail-container">
                        <a href="#">
                            <img src={imageSrc} className="table-thumbnail"/>
                        </a>
                    </div>
                </td>
                {direction}
                {stack}
                <td className="text-center">
                    <a className="video-move-top" onClick={this.handleMoveAssetUp}>
                        <button className="move-to-top btn btn-primary-outline btn-sm parcel-tooltip" title="Move To Top">
                            <i className="fas fa-arrow-up"></i>
                            <span className="tooltip-inner">Move To Top</span>
                        </button>
                    </a>
                </td>
                <td>{this.props.asset.get('contentType')}</td>
                <td>
                    {titleLink}
                    {
                        (this.props.asset.get('children') && this.props.asset.get('children').size > 0) &&
                        (<InnerVideoTable
                            assets={this.props.asset.get('children')}
                            columns={this.props.columns}
                            disabled={this.props.disabled}
                            disableStacking={this.props.disableStacking}
                            id={path}
                            order={this.props.order}
                            path={path}
                        />)
                    }
                </td>
                <td>{createdDate}</td>
                <td onMouseOver={this.handleCatalogs} >
                    <OverlayTrigger
                        overlay={tooltip} placement="bottom"
                        delayShow={300} delayHide={150}
                    >
                        <a className="display-asset-catalogs"
                            data-title-id={this.props.asset.get('assetId')}
                            data-toggle="tooltip"
                            data-placement="top">
                            <span className="hidden-for-sort">10</span>
                            <i className="fas fa-book"/>
                        </a>
                    </OverlayTrigger>
                </td>
                {replaceVideo}
                <td style={ {display:'none'} }/>
            </tr>
        );
    }
}

class VideoTable extends Component {

    static get propTypes() {
        return {
            assets: PropTypes.instanceOf(Immutable.List).isRequired,
            columns: PropTypes.array,
            copyFromAsset: PropTypes.instanceOf(Immutable.Map),
            copyToAsset: PropTypes.instanceOf(Immutable.Map),
            disableStacking: PropTypes.bool,
            disabled: PropTypes.bool.isRequired,
            path: PropTypes.string.isRequired,
            thumbnails: PropTypes.instanceOf(Immutable.List).isRequired,
            thumbnailsLoaded: PropTypes.bool.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
            router: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            columns: [],
            copyFromAsset: null,
            copyToAsset: null,
            disableStacking: false,
            displayLinks: false,
        };
    }

    constructor(props) {
        super(props);

        this.handleReorder = this.handleReorder.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.getTableId = this.getTableId.bind(this);
        this.initializeTable = this.initializeTable.bind(this);
        this.moveAssetUp = this.moveAssetUp.bind(this);
    }

    componentDidMount() {
        this.initializeTable();
        return;
    }

    shouldComponentUpdate(nextProps/*, nextState*/) {
        if (nextProps.copyFromAsset && !nextProps.copyFromAsset.equals(this.props.copyFromAsset) ||
            nextProps.assets !== this.props.assets ||
            nextProps.thumbnailsLoaded ||
            nextProps.copyToAsset && !nextProps.copyToAsset.equals(this.props.copyToAsset)) {
            return true;
        }
        return false;
    }

    componentDidUpdate(/* prevProps , prevState*/) {
        this.initializeTable();
    }

    componentWillUnmount() {
        // check if undefinded only to enable render test
        if (this.$table && !this.props.disabled) {
            this.$table.off('row-reorder');
        }
        // check if undefinded only to enable render test
        if (this.$tableAPI) {
            this.$tableAPI.destroy();
        }

        return;
    }

    handleReorder( event, diff, edit ) {
        event.preventDefault();
        let fromPos = edit.triggerRow[0][0];
        if (diff.length < 2) {
            return;
        }
        let toPos;
        if (diff[0].oldPosition === fromPos) {
            toPos = diff[0].newPosition;
        } else {
            toPos = diff[diff.length-1].newPosition;
        }
        setTimeout(() => {
            AssetTabActions.reorder(this.props.path.split('.'), fromPos, toPos);
            return;
        }, 0);
    }

    /**
     * Update the datatable columns size.
     */
    handleResize() {
        this.$tableAPI.responsive.recalc();
        return;
    }

    getTableId() {
        let id = 'video-assets-table-';
        id = id + this.props.path.split('.').join('-').split(' ').join('-').split('/').join('-').toLowerCase();
        return id;
    }

    initializeTable() {
        this.$table = jQuery('#'+this.getTableId());
        let classNameText = '';
        if (!this.props.disabled) {
            classNameText = ' row-reorder-handle';
        }
        //TODO change array
        let configTable = {
            autoWidth: false,
            columnDefs: [{
                // Add the control class to the last column. This colum will
                // contain the button to show the responsive data.
                className: 'control',
                targets:   -1,
                width: 20
            }, {
                targets: 0,
                className: `text-center row-index${classNameText}`,
                maxWidth: 20,
            }, {
                targets: 1,
                className: 'no-padding text-center'
            }, {
                targets: 'no-sort',
                orderable: false
            }],
            iDisplayLength: 1,
            info: false,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        };

        if (!this.props.disableStacking) {
            configTable.columnDefs.push({targets: 2, className: 'text-center'}, {targets: 3, className: 'text-center'});
        }

        if (!this.props.disabled) {
            configTable.rowReorder = {
                selector: '.row-reorder-handle'
            };
        }
        this.$tableAPI = this.$table.DataTable(configTable);
        // Add some custom handlers.
        if (!this.props.disabled) {
            this.$tableAPI.on('row-reorder', this.handleReorder);
        }
        // Register global listeners.
        window.addEventListener('resize', this.handleResize);
        // And trigger the resize handler once so that the datatable
        // knows its initial dimensions.
        this.$tableAPI.draw(false);

        this.handleResize();
    }

    // move up of its section
    moveAssetUp(order) {
        let orderValue;
        if (order === 0) {
            orderValue = 0;
        } else {
            orderValue = --order;
        }
        AssetTabActions.reorder(this.props.path.split('.'), orderValue, 0);
    }

    replaceVideo(asset) {
        AssetTabActions.selectCopyToAsset(asset);
        AssetTabActions.setAssetType('video');
        SlidingPanelActions.show('replaceVideo');
    }

    render() {
        let order = 0;
        if (this.$table && !this.props.disabled) {
            this.$table.off('row-reorder');
        }
        // check if undefinded only to enable render test
        if (this.$tableAPI) {
            this.$tableAPI.destroy();
        }
        delete this.$table;
        delete this.$tableAPI;
        let showStacking = null;
        if (!this.props.disableStacking) {
            showStacking = (
                <th className="no-sort text-center sorting_disabled" style={{width:'40px'}} aria-label="">
                    <i className="far fa-object-ungroup"/>
                </th>
            ) ;
        }
        let direction = (<th className="no-sort text-center sorting_disabled" style={ {width:'20px'} }>
            <strong>D</strong>
        </th>);

        let replaceVideo;
        if (this.props.columns.indexOf('replaceVideo') !== -1) {
            replaceVideo = <th className="no-sort sorting_disabled text-center" style={{maxWidth:'20px'}} aria-label={this.context.intl.messages['titles.create.assets.replace-video']}>
                <span className="hidden-for-sort">10</span>
                <i className="fa-solid fa-right-left"></i>
            </th>;
        }

        return (
            <div>
                <table id={this.getTableId()} className="table table-bordered table-striped responsive video-table-list">
                    <thead>
                        <tr>
                            <th className="no-sort no-arrow text-center row-reorder-handle row-index sorting_asc" style={{width:'40px'}} aria-label="#">#</th>
                            <th className="no-sort text-center no-padding sorting_disabled" style={{maxWidth:'40px'}} aria-label="">
                                <i className="far fa-image"/>
                            </th>
                            {direction}
                            {showStacking}
                            <th className="no-sort sorting_disabled text-center" aria-label="Type" style={{maxWidth:'40px'}}>
                                <i className="fas fa-arrow-up"/>
                            </th>
                            <th className="no-sort sorting_disabled" style={{maxWidth: '80px'}} aria-label="Type">Type</th>
                            <th className="no-sort sorting_disabled" aria-label="Name">Name</th>
                            <th className="no-sort sorting_disabled" style={{width:'92px'}} aria-label="Date Entered">Date Entered</th>
                            <th className="no-sort sorting_disabled" style={{maxWidth:'20px'}} aria-label="10">
                                <span className="hidden-for-sort">10</span>
                                <i className="fas fa-book"></i>
                            </th>
                            {replaceVideo}
                            <th className="no-sort control sorting_disabled"/>
                        </tr>
                    </thead>
                    <tbody onClick={this.handleRowClick}>
                        {this.props.assets.map((asset) => {
                            let itemPath = this.props.path + '.'+order;
                            order = order + 1;
                            return <VideoTableItem
                                key={asset.get('assetId')}
                                asset={asset}
                                columns={this.props.columns}
                                copyFromAsset={this.props.copyFromAsset}
                                copyToAsset={this.props.copyToAsset}
                                order={order}
                                path={itemPath}
                                disabled={this.props.disabled}
                                disableStacking={this.props.disableStacking}
                                onMoveAssetUp={this.moveAssetUp}
                                onReplaceVideo={this.replaceVideo}
                                thumbnails={this.props.thumbnails}
                            />;
                        })}
                    </tbody>
                </table>
            </div>
        );
    }

}

class VideoTableComponent extends Component {

    static get propTypes() {
        return {
            active: PropTypes.bool.isRequired,
            assets: PropTypes.instanceOf(Immutable.Map).isRequired,
            columns: PropTypes.array.isRequired,
            copyFromAsset: PropTypes.instanceOf(Immutable.Map),
            copyToAsset: PropTypes.instanceOf(Immutable.Map),
            disableStacking: PropTypes.bool,
            disabled: PropTypes.bool.isRequired,
            thumbnails: PropTypes.instanceOf(Immutable.List).isRequired,
            thumbnailsLoaded: PropTypes.bool.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            disableStacking: false,
            displayLinks: false,
            copyFromAsset: null,
            copyToAsset: null,
        };
    }

    shouldComponentUpdate(nextProps/*, nextState*/) {
        if (nextProps.assets !== this.props.assets ||
            nextProps.copyFromAsset && !nextProps.copyFromAsset.equals(this.props.copyFromAsset) ||
            nextProps.copyToAsset && !nextProps.copyToAsset.equals(this.props.copyToAsset) ||
            nextProps.thumbnails !== this.props.thumbnails) {
            return true;
        }
        return false;
    }

    render() {
        if (this.props.assets.size === 0) {
            return null;
        }
        let toRender = [];
        let sortedCategories = Object.keys(Categories.categoryOrder)
            .map( c => {return {category:c, order: Categories.categoryOrder[c]};})
            .sort( (a, b) => a.order - b.order);
        sortedCategories.forEach( (category) => {
            let activeValue = 'inactive';
            if (this.props.active) {
                activeValue = 'active';
            }
            let path = `video.${activeValue}`;
            if (this.props.assets.get(category.category)) {
                toRender.push(<div key={path+'.'+category.category} >
                    <h4>{category.category}</h4>
                    <VideoTable
                        assets={this.props.assets.get(category.category)}
                        active={this.props.active}
                        columns={this.props.columns}
                        copyFromAsset={this.props.copyFromAsset}
                        copyToAsset={this.props.copyToAsset}
                        disabled={this.props.disabled}
                        disableStacking={this.props.disableStacking}
                        path={path+'.'+category.category}
                        thumbnails={this.props.thumbnails}
                        thumbnailsLoaded={this.props.thumbnailsLoaded}
                    />
                </div>);
            }

        } );

        return (
            <div>
                {toRender}
            </div>
        );

    }

}

export default VideoTableComponent;
export {VideoTable};
export {VideoTableItem};
