/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import DocumentTitle from '../../common/document-title';
import {Tab, Tabs} from '../../common/routing-tab/routing-tab';
import Preloader from '../../preloader/';
import PreloaderStore from '../../preloader/preloader-store';
import SessionStore from '../../user/session/session-store';
import Company from '../company/company';
import Currency from '../currency/currency';
import DomainBlacklist from '../domain-blacklist/domain-blacklist';
import Genre from '../genre/genre';
import Language from '../language/language';
import Territory from '../territory/territory';
import Theme from '../theme/theme';

class Create extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible'),
        };
    }

    static getStores() {
        return [PreloaderStore];
    }

    static getPermissions() {
        return {
            company: {
                canBrowse: SessionStore.canUser(SessionStore.PERMISSIONS.SETUP.LOOKUPS.COMPANY.BROWSE),
                canCreate: SessionStore.canUser(SessionStore.PERMISSIONS.SETUP.LOOKUPS.COMPANY.CREATE),
                canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.SETUP.LOOKUPS.COMPANY.EDIT)
            },
            currency: {
                canBrowse: SessionStore.canUser(SessionStore.PERMISSIONS.SETUP.LOOKUPS.CURRENCY.BROWSE),
                canCreate: SessionStore.canUser(SessionStore.PERMISSIONS.SETUP.LOOKUPS.CURRENCY.CREATE),
                canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.SETUP.LOOKUPS.CURRENCY.EDIT)
            },
            domainBlacklist: {
                canBrowse: SessionStore.canUser(SessionStore.PERMISSIONS.SETUP.LOOKUPS.DOMAIN_BLACKLIST.BROWSE),
                canCreate: SessionStore.canUser(SessionStore.PERMISSIONS.SETUP.LOOKUPS.DOMAIN_BLACKLIST.CREATE),
                canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.SETUP.LOOKUPS.DOMAIN_BLACKLIST.EDIT)
            },
            genre: {
                canBrowse: SessionStore.canUser(SessionStore.PERMISSIONS.SETUP.LOOKUPS.GENRE.BROWSE),
                canCreate: SessionStore.canUser(SessionStore.PERMISSIONS.SETUP.LOOKUPS.GENRE.CREATE),
                canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.SETUP.LOOKUPS.GENRE.EDIT)
            },
            language: {
                canBrowse: SessionStore.canUser(SessionStore.PERMISSIONS.SETUP.LOOKUPS.LANGUAGE.BROWSE),
                canCreate: SessionStore.canUser(SessionStore.PERMISSIONS.SETUP.LOOKUPS.LANGUAGE.CREATE),
                canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.SETUP.LOOKUPS.LANGUAGE.EDIT)
            },
            territory: {
                canBrowse: SessionStore.canUser(SessionStore.PERMISSIONS.SETUP.LOOKUPS.TERRITORY.BROWSE),
                canCreate: SessionStore.canUser(SessionStore.PERMISSIONS.SETUP.LOOKUPS.TERRITORY.CREATE),
                canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.SETUP.LOOKUPS.TERRITORY.EDIT)
            },
            theme: {
                canBrowse: SessionStore.canUser(SessionStore.PERMISSIONS.SETUP.LOOKUPS.THEME.BROWSE),
                canCreate: SessionStore.canUser(SessionStore.PERMISSIONS.SETUP.LOOKUPS.THEME.CREATE),
                canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.SETUP.LOOKUPS.THEME.EDIT)
            },
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();
    }

    render() {

        let currency;
        if (this.props.permissions.currency.canBrowse) {
            currency = <Tab route="/setup/lookup/currency" title={this.context.intl.messages['lookups.currency.title']}>
                <Currency permissions={this.props.permissions.currency} />
            </Tab>;
        }

        let domainBlacklist;
        if (this.props.permissions.domainBlacklist.canBrowse) {
            domainBlacklist = <Tab route="/setup/lookup/domain-blacklist" title={this.context.intl.messages['lookups.domain_blacklist.title']}>
                <DomainBlacklist permissions={this.props.permissions.domainBlacklist}/>
            </Tab>;
        }

        let genre;
        if (this.props.permissions.genre.canBrowse) {
            genre = <Tab route="/setup/lookup/genre" title={this.context.intl.messages['lookups.genre.title']}>
                <Genre permissions={this.props.permissions.genre} />
            </Tab>;
        }

        let language;
        if (this.props.permissions.language.canBrowse) {
            language = <Tab route="/setup/lookup/language" title={this.context.intl.messages['lookups.language.title']}>
                <Language permissions={this.props.permissions.language}/>
            </Tab>;
        }

        let territory;
        if (this.props.permissions.territory.canBrowse) {
            territory = <Tab route="/setup/lookup/territory" title={this.context.intl.messages['lookups.territory.title']}>
                <Territory permissions={this.props.permissions.territory} />
            </Tab>;

        }

        let theme;
        if (this.props.permissions.theme.canBrowse) {
            theme = <Tab route="/setup/lookup/theme" title={this.context.intl.messages['lookups.theme.title']}>
                <Theme permissions={this.props.permissions.theme} />
            </Tab>;
        }

        let company;
        if (this.props.permissions.company.canBrowse) {
            company = <Tab route="/setup/lookup/company" title={this.context.intl.messages['lookups.company.title']}>
                <Company permissions={this.props.permissions.company} />
            </Tab>;
        }

        return (
            <DocumentTitle
                message="document-titles.lookup"
            >
                <div>
                    <Preloader show={this.state.preloaderVisible} fixed loadingDots>
                        <section className="content-header">
                            <h1>
                                <i className="fas fa-cog"/>&nbsp;{this.context.intl.messages['lookups.title']}
                            </h1>
                            <p className="padding-top-20 hidden-xs">
                                <em>{this.context.intl.messages['lookups.description']}</em>
                            </p>
                        </section>
                        <section className="content">
                            <div className="row">
                                <div className="col-lg-12">
                                    <Tabs location={this.props.location}>
                                        {territory}
                                        {currency}
                                        {theme}
                                        {genre}
                                        {language}
                                        {domainBlacklist}
                                        {company}
                                    </Tabs>
                                </div>
                            </div>
                        </section>
                    </Preloader>
                </div>
            </DocumentTitle>
        );
    }
}

export default Container.create(Create);
