/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
*/

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link} from 'react-router';
import ReactTable from 'react-table';

import {Intersperse} from '../../../common/utils/utils';

import 'react-table/react-table.css';

export default class Table extends Component {
    static get propTypes() {
        return {
            contentTypeName: PropTypes.string.isRequired,
            home: PropTypes.oneOfType([PropTypes.instanceOf(Immutable.List), PropTypes.string]).isRequired,
            titles: PropTypes.instanceOf(Immutable.List).isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);
    }

    render() {

        let columns = [{
            Header: <strong>{this.context.intl.messages['accounts.edit.summary.homepages.home']}</strong>,
            Cell: c => {
                const id = c.original.get('homePageId');
                const homepageName = c.original.get('name');
                let displayName = <div>{homepageName}</div>;
                if (id >= 0) { //we can have ids as 0
                    displayName = <Link to={`/homepage/${id}`}>{homepageName}</Link>;
                }
                return displayName;
            }
        }, {
            Header: <strong>{this.context.intl.messages['accounts.edit.summary.homepages.type']}</strong>,
            Cell: () => <div>{this.context.intl.messages[this.props.contentTypeName]}</div>
        }, {
            Header: <strong>{this.context.intl.messages['accounts.edit.summary.homepages.titles']}</strong>,
            Cell: () => {
                let displayTitles = this.props.titles;
                if (typeof this.props.titles !== 'string') {
                    let titles = this.props.titles.map(title =>
                        <Link key={title.get('id')} to={`/title/${title.get('id')}`}>{title.get('name') || title.get('displayName')}</Link>
                    );
                    titles = Intersperse(titles.toJS());
                    if (this.props.titles.size === 20) {
                        titles.push('...');
                    }
                    displayTitles = titles;
                }
                return <div>{displayTitles}</div>;
            },
            show: false //for now hide titles column
        }];

        return (
            <ReactTable
                className="-striped table-bordered table-striped responsive"
                columns={columns}
                data={this.props.home}
                getNoDataProps= {() => {
                    if (this.props.home.size) {
                        return {style: {display: 'none'}};
                    }
                    return {};
                }}
                id={'accounts-summary-homepages-table-id'}
                loading={false}
                showPagination={false}
                sortable={true}
                resizable={false}
            />
        );
    }
}
