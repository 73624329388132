/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Checkbox} from 'react-bootstrap';

import {FormItem} from '../../common/form/form';
import Panel from '../../common/panel/panel';
import {WithStoreOnRoute} from '../../common/store-on-route';
import {LayoutActions} from '../../layout/layout-actions';
import {ClientRepGroupActions} from '../../security/client-rep-group/client-rep-group-actions';
import ClientRepGroupStore from '../../security/client-rep-group/client-rep-group-store';
import {UserConstants} from '../../user/user-actions';

import CountrySelect from '~/src/lookup/country-select/country-select';


const AccountAlertOptions = WithStoreOnRoute(class AccountAlertOptions extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.getCheckboxes = this.getCheckboxes.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.isChecked = this.isChecked.bind(this);
    }

    handleChange(accountAlert, event) {
        if (event.target.checked) {
            this.addToSetRoute('account-alert', accountAlert.toString())
                .setRouteState('operator', 'AND')
                .clearRouteState('offset')
                .apply();
        } else {
            this.removeFromSetRoute('account-alert', accountAlert.toString())
                .clearRouteState('offset')
                .apply();
        }
    }

    getCheckboxes() {
        const checkboxes = Object.keys(UserConstants.ACCOUNT_ALERTS).map(alertType => {
            const accountAlert = UserConstants.ACCOUNT_ALERTS[alertType];
            if (accountAlert.disableFilter) {
                return;
            }

            return (
                <Checkbox
                    key={alertType.id}
                    checked={this.isChecked(accountAlert.id)}
                    onChange={this.handleChange.bind(this, accountAlert.id)}>
                    {accountAlert.description}
                </Checkbox>
            );
        })
            .filter(c=>{if (c) {return c;}});

        return checkboxes;
    }

    isChecked(accountAlert) {
        let accountAlerts = this.props.location.query['account-alert'];
        if (!accountAlerts) {
            return false;
        }
        if (!Array.isArray(accountAlerts)) {
            // single string
            return accountAlerts.toString() === accountAlert.toString();
        } else {
            // is in array
            return accountAlerts.indexOf(accountAlert.toString()) !== -1;
        }
    }

    render() {
        const checkboxes = this.getCheckboxes();
        return (
            <Panel title="Alerts" classTitle="box-title" collapsible defaultExpanded>
                <div className="filtering-options">
                    <div className="form-group">
                        {checkboxes}
                    </div>
                </div>
            </Panel>
        );
    }
});

const AccountTypeOptions = WithStoreOnRoute(class AccountTypeOptions extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
        };
    }

    constructor(props) {
        super(props);

        this.getCheckboxes = this.getCheckboxes.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.isChecked = this.isChecked.bind(this);
    }

    getCheckboxes() {
        return Object.keys(UserConstants.ACCOUNT_TYPES).map(typeName => {
            let type = UserConstants.ACCOUNT_TYPES[typeName];

            return (
                <Checkbox
                    key={typeName}
                    checked={this.isChecked(type.id)}
                    onChange={this.handleChange.bind(this, type.id)}>
                    {type.description}
                </Checkbox>
            );
        }).filter(c=>{if (c) {return c;}});
    }

    handleChange(type, event) {
        if (event.target.checked) {
            this.addToSetRoute('account-type', type.toString())
                .setRouteState('operator', 'AND')
                .clearRouteState('offset')
                .apply();
        } else {
            this.removeFromSetRoute('account-type', type.toString())
                .clearRouteState('offset')
                .apply();
        }
    }

    isChecked(type) {
        let accountType = this.props.location.query['account-type'];
        if (!accountType) {
            return false;
        }
        if (!Array.isArray(accountType)) {
            // single string
            return accountType.toString() === type.toString();
        } else {
            // is in array
            return accountType.indexOf(type.toString()) !== -1;
        }
    }

    render() {
        const checkboxes = this.getCheckboxes();
        return (
            <Panel title="Account Type" classTitle="box-title" collapsible defaultExpanded>
                <div className="filtering-options">
                    <div className="form-group">
                        {checkboxes}
                    </div>
                </div>
            </Panel>
        );
    }
});

const ClassificationOptions = WithStoreOnRoute(class ClassificationOptions extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
        };
    }

    constructor(props) {
        super(props);

        this.getCheckboxes = this.getCheckboxes.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.isChecked = this.isChecked.bind(this);
    }

    getCheckboxes() {
        return Object.keys(UserConstants.CLASSIFICATION_TYPE).map(typeName => {
            let type = UserConstants.CLASSIFICATION_TYPE[typeName];

            return (
                <Checkbox
                    key={typeName}
                    checked={this.isChecked(type.filter)}
                    onChange={this.handleChange.bind(this, type.filter)}>
                    {type.name}
                </Checkbox>
            );
        }).filter(c=>{if (c) {return c;}});
    }

    handleChange(type, event) {
        if (event.target.checked) {
            this.addToSetRoute('user-group-classification-type', type.toString())
                .setRouteState('operator', 'AND')
                .clearRouteState('offset')
                .apply();
        } else {
            this.removeFromSetRoute('user-group-classification-type', type.toString())
                .clearRouteState('offset')
                .apply();
        }
    }

    isChecked(type) {
        let classificationType = this.props.location.query['user-group-classification-type'];
        if (!classificationType) {
            return false;
        }
        if (!Array.isArray(classificationType)) {
            // single string
            return classificationType.toString() === type.toString();
        } else {
            // is in array
            return classificationType.indexOf(type.toString()) !== -1;
        }
    }

    render() {
        const checkboxes = this.getCheckboxes();
        return (
            <Panel title="Classification" classTitle="box-title" collapsible defaultExpanded>
                <div className="filtering-options account-filter-options">
                    <div className="form-group">
                        {checkboxes}
                    </div>
                </div>
            </Panel>
        );
    }
});

const CountryOptions = WithStoreOnRoute(class CountryOptions extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.onSelectChange = this.onSelectChange.bind(this);
    }

    onSelectChange(selected) {
        if (!selected || selected.length === 0) {
            this.clearRouteState('country');
        } else {
            selected = selected.map( s => s.name);
            this.setRouteState('country', selected);
        }
        this.setRouteState('operator', 'AND')
            .clearRouteState('offset')
            .apply();
    }

    render() {
        return (
            <Panel removePadding={true} title="Country" classTitle="box-title" collapsible defaultExpanded>
                <div data-style="display: block;" className="box-body">
                    <div className="form-group">
                        <CountrySelect
                            disabled={false}
                            isClearable={true}
                            isMulti={true}
                            onChange={this.onSelectChange}
                            selectedOptions={this.props.location.query.country}
                        />
                    </div>
                </div>
            </Panel>
        );
    }
});

const StatusOptions = WithStoreOnRoute(class StatusOptions extends Component {

    static get propTypes() {
        return {
            hideExpiredTemporaryStatuses: PropTypes.bool,
            hideExtraStatuses: PropTypes.bool,
            location: PropTypes.object.isRequired,
        };
    }

    static get defaultProps() {
        return {
            hideExpiredTemporaryStatuses: false,
            hideExtraStatuses: false,
        };
    }

    constructor(props) {
        super(props);

        this.getCheckboxes = this.getCheckboxes.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.isChecked = this.isChecked.bind(this);
    }

    getCheckboxes() {
        return Object.keys(UserConstants.STATUS).map(statusName => {
            let status = UserConstants.STATUS[statusName];
            if (this.props.hideExtraStatuses && status.extraStatus) {return;}
            if (this.props.hideExpiredTemporaryStatuses && status.isExpiredOrTemporary) {return;}

            return (
                <Checkbox
                    key={statusName}
                    checked={this.isChecked(status.id)}
                    onChange={this.handleChange.bind(this, status.id)}>
                    {status.description}
                </Checkbox>
            );
        }).filter(c=>{if (c) {return c;}});
    }

    handleChange(status, event) {
        if (event.target.checked) {
            this.addToSetRoute('account-status', status.toString())
                .setRouteState('operator', 'AND')
                .clearRouteState('offset')
                .apply();
        } else {
            this.removeFromSetRoute('account-status', status.toString())
                .clearRouteState('offset')
                .apply();
        }
    }

    isChecked(status) {
        let accountStatus = this.props.location.query['account-status'];
        if (!accountStatus) {
            return false;
        }
        if (!Array.isArray(accountStatus)) {
            // single string
            return accountStatus.toString() === status.toString();
        } else {
            // is in array
            return accountStatus.indexOf(status.toString()) !== -1;
        }
    }

    render() {
        const checkboxes = this.getCheckboxes();
        return (
            <Panel title="Status" classTitle="box-title" collapsible defaultExpanded>
                <div className="filtering-options account-filter-options">
                    <div className="form-group">
                        {checkboxes}
                    </div>
                </div>
            </Panel>
        );
    }
});

const RepGroupOptions = WithStoreOnRoute(class RegGroupOptions extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            clientRepGroups: ClientRepGroupStore.getState().get('clientRepGroups')
        };

        this.handleChange = this.handleChange.bind(this);
        this.isChecked = this.isChecked.bind(this);
    }

    componentWillMount() {
        this.storeListeners = [
            ClientRepGroupStore.addListener(() => {
                let state = ClientRepGroupStore.getState();
                this.setState({
                    clientRepGroups: state.get('clientRepGroups')
                });
                return;
            })
        ];

        return;
    }

    componentDidMount() {
        ClientRepGroupActions.get({offset:0, size:9999});
        return;
    }

    componentWillUnmount() {
        this.storeListeners.forEach(listener => listener.remove());
        return;
    }

    handleChange(repGroup, event) {
        if (event.target.checked) {
            this.addToSetRoute('rep-group', repGroup)
                .setRouteState('operator', 'AND')
                .clearRouteState('offset')
                .apply();
        } else {
            this.removeFromSetRoute('rep-group', repGroup)
                .clearRouteState('offset')
                .apply();
        }
    }

    isChecked(repGroup) {
        let rg = this.props.location.query['rep-group'];
        if (!rg) {
            return false;
        }
        if (!Array.isArray(rg)) {
            return rg === repGroup;
        }
        return rg.indexOf(repGroup) !== -1;
    }

    render() {
        const checkboxes = this.state.clientRepGroups.map((crp, index) => {
            return (
                <Checkbox
                    key={index}
                    checked={this.isChecked(crp.get('id').toString())}
                    onChange={this.handleChange.bind(this, crp.get('id').toString())}>
                    {crp.get('name')}
                </Checkbox>
            );
        });
        return (
            <Panel title="Rep Group" classTitle="box-title" collapsible defaultExpanded>
                <div className="filtering-options account-filter-options">
                    <div className="form-group">
                        {checkboxes}
                    </div>
                </div>
            </Panel>
        );
    }
});

const ApplyDateOptions = WithStoreOnRoute(class ApplyDateOptions extends Component {
    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.setFormatDate = this.setFormatDate.bind(this);
    }

    setFormatDate(attr, value) {
        this.setRouteState(attr, value)
            .setRouteState('operator', 'AND')
            .clearRouteState('offset')
            .apply();
    }

    render() {
        return (
            <Panel title="Apply Date" classTitle="box-title" collapsible defaultExpanded>
                <div className="date-picker-filter">
                    <FormItem
                        attr="start-apply-date"
                        datepicker={{popoverTargetOffset: '10px -36px'}}
                        label={this.context.intl.messages['filter-options.apply-date.from']}
                        model={this.getRouteState()}
                        setter={this.setFormatDate}
                        type="date"
                    />
                    <FormItem
                        attr="end-apply-date"
                        datepicker={{popoverTargetOffset: '10px -36px'}}
                        label={this.context.intl.messages['filter-options.apply-date.to']}
                        model={this.getRouteState()}
                        setter={this.setFormatDate}
                        type="date"
                    />
                </div>
            </Panel>
        );
    }
});

const ExpireDateOptions = WithStoreOnRoute(class ExpireDateOptions extends Component {
    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.setFormatDate = this.setFormatDate.bind(this);
    }

    setFormatDate(attr, value) {
        this.setRouteState(attr, value)
            .setRouteState('operator', 'AND')
            .clearRouteState('offset')
            .apply();
    }

    render() {
        return (
            <Panel title="Expire Date" classTitle="box-title" collapsible defaultExpanded>
                <div className="date-picker-filter">
                    <FormItem
                        attr="start-expire-date"
                        datepicker={{popoverTargetOffset: '10px -36px'}}
                        label={this.context.intl.messages['filter-options.expire-date.from']}
                        model={this.getRouteState()}
                        setter={this.setFormatDate}
                        type="date"
                    />
                    <FormItem
                        attr="end-expire-date"
                        datepicker={{popoverTargetOffset: '10px -36px'}}
                        label={this.context.intl.messages['filter-options.expire-date.to']}
                        model={this.getRouteState()}
                        setter={this.setFormatDate}
                        type="date"
                    />
                </div>
            </Panel>
        );
    }
});

class FilterOptions extends Component {

    static get propTypes() {
        return {
            hideExpiredTemporaryStatuses: PropTypes.bool,
            hideExtraStatuses: PropTypes.bool,
            location: PropTypes.object.isRequired,
        };
    }

    static get defaultProps() {
        return {
            hideExpiredTemporaryStatuses: false,
            hideExtraStatuses: false,
        };
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.location !== this.props.location;
    }

    handleToggleFiltersPanel() {
        LayoutActions.toggleFiltersPanel();
    }

    render() {
        return (
            <div>
                <aside className="control-sidebar B(0) Ovx(h) Ovy(a)">
                    <div className="tab-content">

                        <p>
                            <button className="btn btn-block bg-navy" onClick={this.handleToggleFiltersPanel}><i className="fas fa-chevron-right pull-right"></i>&nbsp;Close Options</button>
                        </p>

                        <AccountTypeOptions location={this.props.location}
                        />

                        <ClassificationOptions location={this.props.location}
                        />

                        <CountryOptions location={this.props.location}
                        />

                        <AccountAlertOptions
                            location={this.props.location}
                        />

                        <StatusOptions {...this.props} />

                        <RepGroupOptions
                            location={this.props.location}
                        />

                        <ApplyDateOptions
                            location={this.props.location}
                        />

                        <ExpireDateOptions
                            location={this.props.location}
                        />
                    </div>
                </aside>
                <div style={{position: 'fixed', height: 'auto'}} className="control-sidebar-bg"></div>
            </div>
        );
    }
}

export default WithStoreOnRoute(FilterOptions);

export {
    AccountAlertOptions,
    AccountTypeOptions,
    ClassificationOptions,
    CountryOptions,
    StatusOptions,
    RepGroupOptions,
    ApplyDateOptions,
    ExpireDateOptions
};
