/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';
import {Subject} from 'rxjs';

import {AssetCatalogActions} from '~/src/assets/catalogs/asset-catalog-actions';
import {DownloadActions} from '~/src/common/download/download-actions';
import {AlertTypes} from '~/src/common/notification/alert';
import {NotificationActions} from '~/src/common/notification/notification-actions';
import Dispatcher from '~/src/dispatcher/dispatcher';
import {PreloaderActions} from '~/src/preloader/preloader-actions';
import Request from '~/src/request';
import {RouterActions} from '~/src/router/router-actions';
import {ActionHistoryConstants} from '~/src/system/action-history/action-history-actions';
import {UserActions} from '~/src/user/user-actions';

const CONSTANTS = {
    ASSET: {
        categoryId: 2,
        GET: {
            START: 'group_actions.asset.get.start',
            SUCCESS: 'group_actions.asset.get.success',
            ERROR: 'group_actions.asset.get.error'
        },
        GROUP: {
            ADD: 'group_actions.asset.group.add',
            CLONE: 'group_actions.asset.group.clone',
            SAVE: {
                START: 'group_actions.asset.group.save.start',
                SUCCESS: 'group_actions.asset.group.save.success',
                ERROR: 'group_actions.asset.group.save.error',
            },
            GET: {
                START: 'group_actions.asset.group.get.start',
                SUCCESS: 'group_actions.asset.group.get.success',
                ERROR: 'group_actions.asset.group.get.error',
            },
            REMOVE: {
                START: 'group_actions.asset.group.remove.start',
                SUCCESS: 'group_actions.asset.group.remove.success',
                ERROR: 'group_actions.asset.group.remove.error',
            },
            UPDATE: 'group_actions.asset.group.update',
            DOWNLOAD: {
                ERROR: 'group_actions.asset.group.download.error'
            }
        },
        name: 'asset',
        browse: 'catalogs/asset',
        message: 'catalogs.asset'
    },
    CLEAR: 'group_actions.clear',
    CRITERIA_TYPES: {
        COUNTRY: {id: 1, name: 'Country'},
        COMPANY: {id: 2, name: 'Company'},
        USER_GROUP: {id: 3, name: 'User Groups'}
    },
    GET_CATALOG_USER_GROUPS: {
        START: 'security.catalog.user_group.start',
        SUCCESS: 'security.catalog.user_group.success',
        ERROR: 'security.catalog.user_group.error'
    },
    GET_GROUP_TITLES: {
        START: 'security.group.title.start',
        SUCCESS: 'security.group.title.success'
    },
    GET_GROUP_LANGUAGES: {
        START: 'security.group.language.start',
        SUCCESS: 'security.group.language.success',
        ERROR: 'security.group.language.error'
    },
    GET_GROUP_ASSETS: {
        START: 'security.group.asset.start',
        SUCCESS: 'security.group.asset.success'
    },
    GET_GROUP_PARTNERS: {
        START: 'security.group.partners.start',
        SUCCESS: 'security.group.partners.success',
        ERROR: 'security.group.partners.error'
    },
    LANGUAGE: {
        categoryId: 4, //translationId
        GET: {
            START: 'group_actions.language.get.start',
            SUCCESS: 'group_actions.language.get.success',
            ERROR: 'group_actions.language.get.error'
        },
        GROUP: {
            CLONE: 'group_actions.language.group.clone',
            SAVE: {
                START: 'group_actions.language.group.save.start',
                SUCCESS: 'group_actions.language.group.save.success',
                ERROR: 'group_actions.language.group.save.error',
            },
            GET: {
                START: 'group_actions.language.group.get.start',
                SUCCESS: 'group_actions.language.group.get.success',
                ERROR: 'group_actions.language.group.get.error',
            },
            REMOVE: {
                START: 'group_actions.language.group.remove.start',
                SUCCESS: 'group_actions.language.group.remove.success',
                ERROR: 'group_actions.language.group.remove.error',
            },
            UPDATE: 'group_actions.language.group.update'
        },
        name: 'language',
        browse: 'catalogs/language',
        message: 'catalogs.language'
    },
    SET: {
        FILTER: 'group_actions.set.filter'
    },
    STATION: {
        categoryId: 5,
        GET: {
            ERROR: 'group_actions.station.get.error',
            START: 'group_actions.station.get.start',
            SUCCESS: 'group_actions.station.get.success',
        },
        GROUP: {
            CLONE: 'group_actions.station.group.clone',
            SAVE: {
                ERROR: 'group_actions.station.group.save.error',
                START: 'group_actions.station.group.save.start',
                SUCCESS: 'group_actions.station.group.save.success',
            },
            GET: {
                ERROR: 'group_actions.station.group.get.error',
                START: 'group_actions.station.group.get.start',
                SUCCESS: 'group_actions.station.group.get.success',
            },
            REMOVE: {
                ERROR: 'group_actions.station.group.remove.error',
                START: 'group_actions.station.group.remove.start',
                SUCCESS: 'group_actions.station.group.remove.success',
            },
            UPDATE: 'group_actions.station.group.update'
        },
        browse: 'stations',
        message: 'stations',
        name: 'station',
    },
    STATION_GROUP: {
        categoryId: 6,
        ADD_STATION: 'group_actions.station_group.add_station',
        REMOVE_STATION: 'group_actions.station_group.remove_station',
        GET: {
            ERROR: 'group_actions.station.get.error',
            START: 'group_actions.station.get.start',
            SUCCESS: 'group_actions.station.get.success',
        },
        GROUP: {
            CLONE: 'group_actions.station.group.clone',
            SAVE: {
                ERROR: 'group_actions.station.group.save.error',
                START: 'group_actions.station.group.save.start',
                SUCCESS: 'group_actions.station.group.save.success',
            },
            GET: {
                ERROR: 'group_actions.station.group.get.error',
                START: 'group_actions.station.group.get.start',
                SUCCESS: 'group_actions.station.group.get.success',
            },
            REMOVE: {
                ERROR: 'group_actions.station.group.remove.error',
                START: 'group_actions.station.group.remove.start',
                SUCCESS: 'group_actions.station.group.remove.success',
            },
            UPDATE: 'group_actions.station.group.update'
        },
        browse: 'stations',
        message: 'stations',
        name: 'station',
    },
    TITLE: {
        categoryId: 1,
        GET: {
            START: 'group_actions.title.get.start',
            SUCCESS: 'group_actions.title.get.success',
            ERROR: 'group_actions.title.get.error'
        },
        GROUP: {
            CLONE: 'group_actions.title.group.clone',
            SAVE: {
                START: 'group_actions.title.group.save.start',
                SUCCESS: 'group_actions.title.group.save.success',
                ERROR: 'group_actions.title.group.save.error',
            },
            GET: {
                START: 'group_actions.title.group.get.start',
                SUCCESS: 'group_actions.title.group.get.success',
                ERROR: 'group_actions.title.group.get.error',
            },
            REMOVE: {
                START: 'group_actions.title.group.remove.start',
                SUCCESS: 'group_actions.title.group.remove.success',
                ERROR: 'group_actions.title.group.remove.error',
            },
            UPDATE: 'group_actions.title.group.update'
        },
        name: 'title',
        browse: 'catalogs/title',
        message: 'catalogs.title'
    },
    USER: {
        categoryId: 3,
        GET: {
            START: 'group_actions.user.get.start',
            SUCCESS: 'group_actions.user.get.success',
            ERROR: 'group_actions.user.get.error'
        },
        GROUP: {
            CLONE: 'group_actions.user.group.clone',
            SAVE: {
                START: 'group_actions.user.group.save.start',
                SUCCESS: 'group_actions.user.group.save.success',
                ERROR: 'group_actions.user.group.save.error',
            },
            CREATE: {
                START: 'group_actions.user.group.create.start',
                SUCCESS: 'group_actions.user.group.create.success',
                ERROR: 'group_actions.user.group.create.error',
            },
            GET: {
                START: 'group_actions.user.group.get.start',
                SUCCESS: 'group_actions.user.group.get.success',
                ERROR: 'group_actions.user.group.get.error',
            },
            REMOVE: {
                START: 'group_actions.user.group.remove.start',
                SUCCESS: 'group_actions.user.group.remove.success',
                ERROR: 'group_actions.user.group.remove.error',
            },
            UPDATE: 'group_actions.user.group.update'
        },
        name: 'user',
        browse: 'groups/user-groups',
        message: 'groups.user'
    },
    toArray: function(constant) {
        return Object.keys(this[constant])
            .map(k => this[constant][k])
            .sort((a, b) => a.name.localeCompare(b.name));
    },
};

class GroupActions {

    addAssetsToCatalog(groupAssets) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ASSET.GROUP.ADD,
            groupAssets
        });
    }

    addStationToStationGroup(station) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.STATION_GROUP.ADD_STATION,
            station
        });
    }

    removeStationFromStationGroup(index) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.STATION_GROUP.REMOVE_STATION,
            index
        });
    }

    /**
     * Clear the store. Restore the initial state.
     */
    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });

        return;
    }

    downloadAllResetPasswordTokens(groupId) {
        Request.get(`security/group/${groupId}/generate-password-reset-token`).query({
            'threaded': true
        }).then(res => {
            DownloadActions.resolveDownloadExecutionFor(res, `/groups/user-groups/download-reset-password-tokens/${res.body.downloadExecutionId}`);
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.ASSET.GROUP.DOWNLOAD.ERROR
            });
            NotificationActions.showAlertDanger('groups.user.export-users-with-reset-token.download.error');
            throw err;
        });
    }

    downloadAllUsers(groupId, newTab, groupRoute = 'groups/user-groups') {
        Request.get('user/export').query({
            'user-group-id': groupId
        }).then(res => {
            DownloadActions.resolveDownloadExecutionFor(res, `/${groupRoute}/download-users/${res.body.downloadExecutionId}`, newTab);
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.ASSET.GROUP.DOWNLOAD.ERROR
            });
            NotificationActions.showAlertDanger('groups.user.export-users.download.error');
            throw err;
        });
    }

    findGroupById(type, id) {
        Dispatcher.dispatch({
            actionType: type.GROUP.GET.START,
            id: id
        });

        let actionHistoryQuery = {
            'action-object': ActionHistoryConstants.ACTION_OBJECTS.GROUP,
            'object-id': id,
            offset: 0,
            size: 4
        };

        Promise.all([
            Request.get(`security/group/${id}`).exec(),
            Request.get('system/action-history').query(actionHistoryQuery).exec()
        ])
            .spread((group, historyRes) => {
                group = group.body;
                let history = historyRes.body.results;
                history.sort((h1, h2) => h2.actionDate.localeCompare(h1.actionDate));

                if (group.assettypeId) {
                    group.assetType = group.assettypeId;
                }
                group = Immutable.fromJS(group);

                Dispatcher.dispatch({
                    actionType: type.GROUP.GET.SUCCESS,
                    group: group,
                    history: Immutable.fromJS(history)
                });
                return;
            })
            .catch(err => {
                Dispatcher.dispatch({
                    actionType: type.GROUP.GET.ERROR,
                    error: err
                });
                switch (err.status) {
                case 404:
                    RouterActions.notFound();
                    break;
                default:
                    NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
                    break;
                }
                throw err;
            });
        return;
    }

    findStationGroupById(id, isStationGroup = false) {
        // isStationGroup indicates station groups that contain other stations. These groups do not have catalogs or users. See STUDIO-8962
        Dispatcher.dispatch({
            actionType: CONSTANTS.STATION.GROUP.GET.START,
            id
        });

        let stationGroup, history;

        const actionHistoryQuery = {
            'action-object': ActionHistoryConstants.ACTION_OBJECTS.GROUP,
            'object-id': id,
            offset: 0,
            size: 4
        };

        const preloaderSource = 'group-actions.findStationGroupById';
        PreloaderActions.show(preloaderSource);

        Promise.all([
            Request.get(`security/group/${id}`).exec(),
            Request.get('system/action-history').query(actionHistoryQuery).exec()
        ])
            .spread((group, historyRes) => {
                stationGroup = group.body;
                history = historyRes.body.results;
                history.sort((h1, h2) => h2.actionDate.localeCompare(h1.actionDate));

                stationGroup = Immutable.fromJS(stationGroup);

                let requests = [];
                if (!isStationGroup) {
                    requests.push(
                        Request.get(`security/group/${id}/asset-catalog`).exec(),
                        Request.get(`security/group/${id}/title-catalog`).exec(),
                    );
                } else {
                    requests.push(
                        Request.get(`security/group/${id}/station`).exec(),
                    );
                }

                return Promise.all(requests);
            })
            .then(reqs => {
                let assetCatalogs, titleCatalogs, childStations;
                if (!isStationGroup) {
                    assetCatalogs = reqs.splice(0, 1)[0];
                    titleCatalogs = reqs.splice(0, 1)[0];

                    stationGroup = stationGroup.merge({
                        assetGroups: Immutable.fromJS(assetCatalogs.body).toSet().sortBy(g => g.get('name')),
                        titleGroups: Immutable.fromJS(titleCatalogs.body).toSet().sortBy(g => g.get('name'))
                    });
                } else {
                    childStations = reqs.splice(0, 1)[0];

                    stationGroup = stationGroup.merge({
                        stationGroups: Immutable.fromJS(childStations.body).sortBy(g => g.get('name')),
                    });
                }

                Dispatcher.dispatch({
                    actionType: CONSTANTS.STATION.GROUP.GET.SUCCESS,
                    group: stationGroup,
                    history: Immutable.fromJS(history)
                });
                Dispatcher.dispatch({
                    actionType: CONSTANTS.STATION.GROUP.CLONE
                });

                PreloaderActions.hide(preloaderSource);

                return;
            }).catch(err => {
                PreloaderActions.hide(preloaderSource);

                switch (err.status) {
                case 404:
                    RouterActions.notFound();
                    break;
                default:
                    NotificationActions.showAlertDanger('stations.get.error');
                    break;
                }
                throw err;
            });
        return;
    }

    findUserGroupById(id) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.USER.GROUP.GET.START,
            id: id
        });

        let userGroup, history;

        let actionHistoryQuery = {
            'action-object': ActionHistoryConstants.ACTION_OBJECTS.GROUP,
            'object-id': id,
            offset: 0,
            size: 4
        };

        const preloaderSource = 'group-actions.findUserGroupById';
        PreloaderActions.show(preloaderSource);

        Promise.all([
            Request.get(`security/group/${id}`).exec(),
            Request.get('system/action-history').query(actionHistoryQuery).exec()
        ])
            .spread((group, historyRes) => {
                userGroup = group.body;
                history = historyRes.body.results;
                history.sort((h1, h2) => h2.actionDate.localeCompare(h1.actionDate));

                let companies = [];
                let territories = [];
                let userGroups = [];
                userGroup.userGroupCriteria.forEach(c => {
                    switch (c.criteriaType) {
                    case CONSTANTS.CRITERIA_TYPES.COMPANY.id:
                        companies.push(c.criteriaValue);
                        break;
                    case CONSTANTS.CRITERIA_TYPES.COUNTRY.id:
                        territories.push(c.criteriaValue);
                        break;
                    case CONSTANTS.CRITERIA_TYPES.USER_GROUP.id:
                        userGroups.push(c.criteriaValue);
                        break;
                    }
                });
                let criteria = {'companies': Immutable.fromJS(companies).toSet(), 'territories': Immutable.fromJS(territories).toSet()};
                userGroup.criteria = criteria;
                userGroup = Immutable.fromJS(userGroup);

                let ugr = userGroups.map ( r => Request.get(`security/group/${r}`).exec());
                return Promise.all([
                    Request.get(`security/group/${id}/asset-catalog`).exec(),
                    Request.get(`security/group/${id}/title-catalog`).exec(),
                    ...ugr
                ]);
            })
            .then(reqs => {
                let assetCatalogs = reqs.splice(0, 1)[0];
                let titleCatalogs = reqs.splice(0, 1)[0];
                let ugr = reqs.map( r => {
                    return {id: r.body.id, name:r.body.name};
                });
                userGroup = userGroup.merge({
                    assetGroups: Immutable.fromJS(assetCatalogs.body).toSet().sortBy(g => g.get('name')),
                    titleGroups: Immutable.fromJS(titleCatalogs.body).toSet().sortBy(g => g.get('name'))
                });
                userGroup = userGroup.update('criteria', criteria => criteria.set('groups', Immutable.fromJS(ugr).toSet().sortBy(g => g.get('name'))));
                Dispatcher.dispatch({
                    actionType: CONSTANTS.USER.GROUP.GET.SUCCESS,
                    userGroup: userGroup,
                    history: Immutable.fromJS(history)
                });
                Dispatcher.dispatch({
                    actionType: CONSTANTS.USER.GROUP.CLONE
                });

                return;
            }).then(()=>{
                PreloaderActions.hide(preloaderSource);
                return;
            }).catch(err => {
                PreloaderActions.hide(preloaderSource);
                Dispatcher.dispatch({
                    actionType: CONSTANTS.USER.GROUP.ERROR,
                    error: err
                });

                switch (err.status) {
                case 404:
                    RouterActions.notFound();
                    break;
                default:
                    NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
                    break;
                }
                throw err;
            });
        return;
    }

    get(type, preloader, options) {
        let rxGroups = new Subject();

        let defaults = {
            active: true,
            offset: 0,
            size: 20,
            sortDirection: 'asc',
            sortFieldName: 'name',
            includeUserCount: false
        };
        // Extend the default options with the options descriptor.
        Object.assign(defaults, options);

        Dispatcher.dispatch({
            actionType: type.GET.START,
        });

        preloader && PreloaderActions.show('group-actions.get');

        let query = {
            active: defaults.active,
            'category-id': type.categoryId,
            offset: defaults.offset,
            size: defaults.size,
            'sort-direction': defaults.sortDirection,
            'sort-field-name': defaults.sortFieldName,
            'include-user-count': defaults.includeUserCount
        };

        if (options?.admin) {
            query.admin = true;
        }

        let req = Request.get('security/group').query(query);

        if (defaults.filters) {
            req.query(defaults.filters.toJS());
        }

        req.exec().then(res => {
            rxGroups.next({
                actionType: type.GET.SUCCESS,
                offset: res.body.offset,
                groups: Immutable.fromJS(res.body.results),
                size: res.body.size,
                total: res.header['wbtv-total-count']
            });

            preloader && PreloaderActions.hide('group-actions.get');
            return;
        }).catch(err => {
            preloader && PreloaderActions.hide('group-actions.get');
            throw err;
        });

        rxGroups.pipe().subscribe(
            (event) => {
                Dispatcher.dispatch(event);
            },
            err => {
                throw err;
            }
        );
        return;
    }

    getGroupAssets(id, size = 20, offset = 0, asset = '') {
        Request.get(`security/asset-catalog/${id}/asset`)
            .query({
                size,
                offset,
                name: asset.trim()
            })
            .exec()
            .then((res) => {
                const assets = Immutable.fromJS(res.body.results);
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET_GROUP_ASSETS.SUCCESS,
                    groupAssets: assets,
                    offset: offset,
                    size: size,
                    totalCount: res.body.totalCount
                });
            })
            .catch((err) => {
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
                throw err;
            });
    }

    getGroupPartners(id) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.GET_GROUP_PARTNERS.START
        });

        Request.get(`security/group/${id}/partner`)
            .exec()
            .then((res) => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET_GROUP_PARTNERS.SUCCESS,
                    partners: Immutable.fromJS(res.body)
                });
            })
            .catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET_GROUP_PARTNERS.ERROR,
                    error: err
                });
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'groups.partners.error');
                throw err;
            });
    }

    getGroupTitles(id, size = 20, offset = 0, title = '', catalogType = CONSTANTS.TITLE.name) {
        let constant, catalogPath;
        switch (catalogType) {
        case CONSTANTS.LANGUAGE.name:
            catalogPath = 'translation-catalog';
            constant = CONSTANTS.GET_GROUP_LANGUAGES;
            break;
        case CONSTANTS.TITLE.name:
            catalogPath = 'title-catalog';
            constant = CONSTANTS.GET_GROUP_TITLES;
            break;
        }
        Dispatcher.dispatch({
            actionType: constant.START
        });
        Request.get(`security/${catalogPath}/${id}/title`)
            .query({
                size,
                offset,
                name: title.trim()
            })
            .exec()
            .then((res) => {
                const titles = Immutable.fromJS(res.body.results);
                Dispatcher.dispatch({
                    actionType: constant.SUCCESS,
                    groupTitles: titles,
                    offset: offset,
                    size: size,
                    totalCount: res.body.totalCount
                });
            })
            .catch((err) => {
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
                Dispatcher.dispatch({
                    actionType: constant.ERROR
                });
                throw err;
            });
    }

    getCatalogUserGroups(id) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.GET_CATALOG_USER_GROUPS.START
        });
        Request.get(`security/group/${id}/user-group`)
            .exec()
            .then((res) => {
                const groups = Immutable.fromJS(res.body);
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET_CATALOG_USER_GROUPS.SUCCESS,
                    userGroups: groups
                });
            })
            .catch((err) => {
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET_CATALOG_USER_GROUPS.ERROR
                });
                throw err;
            });
    }

    remove(type, id) {
        Dispatcher.dispatch({
            actionType: type.GROUP.REMOVE.START,
        });

        Request.del(`security/group/${id}`)
            .exec()
            .then(() => {
                Dispatcher.dispatch({
                    actionType: type.GROUP.REMOVE.SUCCESS,
                });

                RouterActions.redirect(`/${type.browse}`);
                NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, `${type.message}.delete.success`);
                return;
            })
            .catch(err => {
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, `${type.message}.delete.error`);
                throw err;
            });

        return;
    }

    /**
      * Remove a list of users from a certain group.
      */
    removeUsers(group, users, type = CONSTANTS.USER) {
        // Send a DELETE request for each user in the users array.
        Promise.all(users.map(
            u => Request.del(`user/${u.get('id')}/group/${group.get('id')}`).exec()
        ).toJS()).then(() => {
            NotificationActions.showAlertSuccess(`${type.message}.remove-users.success`);
            // Reload all users for the group.
            UserActions.findAllByGroupId(group.get('id'));
            return;
        });

        return;
    }

    save(type, group, options, groupItems, originalGroupItems) {
        let defaults = {
            messages: {
                error: `catalogs.${type.name}.create.error`,
                success: `catalogs.${type.name}.create.success`
            }
        };
        Object.assign(defaults, options);

        Dispatcher.dispatch({
            actionType: type.GROUP.SAVE.START,
        });

        // Set the data to send to the server.
        let groupData = group.toJS();
        ['history'].forEach(p => delete groupData[p]);

        groupData.groupCategoryType = type.categoryId;

        if (groupData.isActive) {
            groupData.isActive = 1;
        } else {
            groupData.isActive = 0;
        }

        if (groupData.assetType) {
            groupData.assettypeId = groupData.assetType;
            delete groupData.assetType;
        }

        // Assume POST.
        let isPost = true;
        let method = Request.post;
        let uri = 'security/group';

        // Check if PUT.
        let id = groupData.id;

        if (id !== undefined) {
            isPost = false;
            method = Request.put;
            uri = `security/group/${id}`;
        }

        let groupResponse;

        method(uri)
            .send(groupData)
            .exec()
            .then(res => {
                groupResponse = res.body;

                let saveAssets = [];
                if (type.categoryId === CONSTANTS.ASSET.categoryId && id) {
                    saveAssets = AssetCatalogActions.catalogAssetsSave(id, groupItems, originalGroupItems);
                }
                return Promise.all(saveAssets);
            })
            .then(() => {
                Dispatcher.dispatch({
                    actionType: type.GROUP.SAVE.SUCCESS,
                });
                if (isPost) {
                    RouterActions.redirect(`/catalogs/${type.name}/${groupResponse.id}`, true);
                }
                NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, defaults.messages.success);
                return;
            })
            .catch(err => {
                Dispatcher.dispatch({
                    actionType: type.GROUP.SAVE.ERROR,
                    error: err,
                });
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, defaults.messages.error);
                throw err;
            });

        return;
    }

    saveStationGroup(station, originalStationGroup, options, isStationGroup = false) {
        // isStationGroup specifies creation of a station that contains other stations, see STUDIO-8962.This type of station group does not have catalogs!
        let defaults = {
            messages: {
                error: 'stations.edit.error',
                success: 'stations.edit.success'
            }
        };
        Object.assign(defaults, options);

        // Set the data to send to the server.
        let stationGroupData = station.toJS();
        let groupType = CONSTANTS.STATION.categoryId;
        if (isStationGroup) {
            groupType = CONSTANTS.STATION_GROUP.categoryId;
        }
        stationGroupData.groupCategoryType = groupType;

        ['assetGroups', 'history', 'titleGroups', 'stationGroups'].forEach(attr => delete stationGroupData[attr]);
        let isActive;
        if (stationGroupData.isActive) {
            isActive = 1;
        } else {
            isActive = 0;
        }
        stationGroupData.isActive = isActive;

        // Assume POST.
        let isPost = true;
        let method = Request.post;
        let uri = 'security/group';

        // Check if PUT.
        let id = stationGroupData.id;
        if (id !== undefined) {
            isPost = false;
            method = Request.put;
            uri = `security/group/${id}`;
        }

        const preloaderSource = 'group-actions.saveStation';
        PreloaderActions.show(preloaderSource);
        let relations = [];
        if (!isStationGroup) {
            relations = [{
                attr: 'assetGroups',
                urlName: 'asset-catalog'
            }, {
                attr: 'titleGroups',
                urlName: 'title-catalog'
            }];
        } else {
            relations = [{
                attr: 'stationGroups',
                urlName: 'station'
            }];
        }

        method(uri)
            .send(stationGroupData)
            .exec()
            .then(res => {
                id = res.body.id;
                let requests = [];
                // Process relations.
                relations.forEach(relation => {
                    // If the array hasn't changed return immediatly.
                    if (station.get(relation.attr).toSet().equals(originalStationGroup.get(relation.attr).toSet())) {
                        return;
                    }

                    // Elements to add.
                    requests.push(...station.get(relation.attr).toSet().subtract(originalStationGroup.get(relation.attr).toSet())
                        .map(item => Request.post(`security/group/${id}/${relation.urlName}/${item.get('id')}`).exec()).toJS()
                    );

                    // Elements to remove.
                    requests.push(...originalStationGroup.get(relation.attr).toSet().subtract(station.get(relation.attr).toSet())
                        .map(item => Request.del(`security/group/${id}/${relation.urlName}/${item.get('id')}`).exec()).toJS()
                    );
                    return;
                });

                return Promise.all(requests);
            })
            .then(() => {
                PreloaderActions.hide(preloaderSource);
                Dispatcher.dispatch({
                    actionType: CONSTANTS.STATION.GROUP.CLONE
                });

                if (isPost) {
                    if (!isStationGroup) {
                        RouterActions.redirect(`/stations/${id}`, true);
                    } else {
                        RouterActions.redirect(`/station-groups/${id}`, true);
                    }
                }
                NotificationActions.showAlertSuccess(defaults.messages.success);

                return;
            })
            .catch(err => {
                PreloaderActions.hide(preloaderSource);
                NotificationActions.showAlertDanger(defaults.messages.error);
                throw err;
            });

        return;
    }

    saveUserGroup(userGroup, originalUserGroup, options) {
        let defaults = {
            messages: {
                error: 'groups.user.edit.error',
                success: 'groups.user.edit.success'
            }
        };
        Object.assign(defaults, options);

        // Set the data to send to the server.
        let userGroupData = userGroup.toJS();
        userGroupData.groupCategoryType = CONSTANTS.USER.categoryId;

        let userGroupCriteria = userGroupData.criteria.companies.map(c => {
            return {
                groupId: userGroupData.id,
                criteriaType: CONSTANTS.CRITERIA_TYPES.COMPANY.id,
                criteriaValue: c
            };
        });
        userGroupCriteria = userGroupCriteria.concat(
            userGroupData.criteria.territories.map(t => {
                return {
                    groupId: userGroupData.id,
                    criteriaType: CONSTANTS.CRITERIA_TYPES.COUNTRY.id,
                    criteriaValue: t.toString()
                };
            }));
        userGroupCriteria = userGroupCriteria.concat(
            userGroupData.criteria.groups.map(t => {
                return {
                    groupId: userGroupData.id,
                    criteriaType: CONSTANTS.CRITERIA_TYPES.USER_GROUP.id,
                    criteriaValue: t.id.toString()
                };
            }));

        userGroupData.userGroupCriteria = userGroupCriteria;

        ['assetGroups', 'criteria', 'history', 'titleGroups'].forEach(attr => delete userGroupData[attr]);
        let isActive;
        if (userGroupData.isActive) {
            isActive = 1;
        } else {
            isActive = 0;
        }
        userGroupData.isActive = isActive;

        // Assume POST.
        let isPost = true;
        let method = Request.post;
        let uri = 'security/group';

        // Check if PUT.
        let id = userGroupData.id;
        if (id !== undefined) {
            isPost = false;
            method = Request.put;
            uri = `security/group/${id}`;
        }

        const preloaderSource = 'group-actions.saveUserGroup';
        PreloaderActions.show(preloaderSource);

        method(uri)
            .send(userGroupData)
            .exec()
            .then(res => {
                id = res.body.id;
                return id;
            })
            .then(groupId => {
                //Testing criteria
                //Request.post(`security/group/${groupId}/one-time-sync`).send(userGroupCriteria).exec();

                let requests = [];
                // Process relations.
                [{
                    attr: 'assetGroups',
                    urlName: 'asset-catalog'
                }, {
                    attr: 'titleGroups',
                    urlName: 'title-catalog'
                }].forEach(relation => {
                    // If the array hasn't changed return immediatly.
                    if (userGroup.get(relation.attr).equals(originalUserGroup.get(relation.attr))) {
                        return;
                    }

                    // Elements to add.
                    requests.push(...userGroup.get(relation.attr).subtract(originalUserGroup.get(relation.attr))
                        .map(item => Request.post(`security/group/${groupId}/${relation.urlName}/${item.get('id')}`).exec()).toJS()
                    );

                    // Elements to remove.
                    requests.push(...originalUserGroup.get(relation.attr).subtract(userGroup.get(relation.attr))
                        .map(item => Request.del(`security/group/${groupId}/${relation.urlName}/${item.get('id')}`).exec()).toJS()
                    );
                    return;
                });

                return Promise.all(requests);
            })
            .then(() => {
                PreloaderActions.hide(preloaderSource);
                Dispatcher.dispatch({
                    actionType: CONSTANTS.USER.GROUP.CLONE
                });

                if (isPost) {
                    RouterActions.redirect(`/groups/user-groups/${id}`, true);
                }
                NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, defaults.messages.success);

                return;
            })
            .catch((err) => {
                PreloaderActions.hide(preloaderSource);
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, defaults.messages.error);
                throw err;
            });

        return;
    }

    setFilter(type, attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SET.FILTER,
            attr: attr,
            type: type,
            value: value
        });

        return;
    }

    syncElasticSearch(id) {
        Request.get(`security/group/${id}/refresh-users-es`).exec().then(() => {
            NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'elastic-search.sync.success');
            return;
        }).catch(err => {
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'elastic-search.sync.error');
            throw err;
        });
    }

    updateGroup(type, attr, value) {
        Dispatcher.dispatch({
            actionType: type.GROUP.UPDATE,
            attr: attr,
            type: type,
            value: value
        });
        return;
    }
}

let actions = new GroupActions();

export {
    actions as GroupActions,
    CONSTANTS as GroupConstants
};
