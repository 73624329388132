/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Checkbox} from 'react-bootstrap';

import {FormItem} from '../common/form/form';
import Panel from '../common/panel/panel';
import {WithStoreOnRoute} from '../common/store-on-route';
import {LayoutActions} from '../layout/layout-actions';

const DateRangeOptions = WithStoreOnRoute(class extends Component {
    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.setFormatDate = this.setFormatDate.bind(this);
    }

    setFormatDate(attr, value) {
        this.setRouteState(attr, value ? Moment(value).toISOString() : value)
            .clearRouteState('offset')
            .apply();
    }

    render() {
        return (
            <Panel title={this.context.intl.messages['guild.browse.filter.date.title']} classTitle="box-title" collapsible defaultExpanded>
                <div className="box-body date-picker-filter">
                    <FormItem
                        attr="start-date"
                        datepicker={{popoverTargetOffset: '10px -36px'}}
                        label={this.context.intl.messages['guild.browse.filter.date.from']}
                        model={this.getRouteState()}
                        setter={this.setFormatDate}
                        type="date"
                    />
                    <FormItem
                        attr="end-date"
                        datepicker={{popoverTargetOffset: '10px -36px'}}
                        label={this.context.intl.messages['guild.browse.filter.date.to']}
                        model={this.getRouteState()}
                        setter={this.setFormatDate}
                        type="date"
                    />
                </div>
            </Panel>
        );
    }
});

const ActiveOptions = WithStoreOnRoute(class extends Component {
    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setRouteState('show-inactive', event.target.checked)
            .apply();
        return;
    }

    render() {
        return (
            <Panel title={this.context.intl.messages['guild.browse.filter.status.title']} classTitle="box-title" collapsible defaultExpanded>
                <Checkbox
                    checked={this.getRouteState().get('show-inactive', false)}
                    onChange={this.handleChange}>
                    {this.context.intl.messages['guild.browse.filter.status.show-inactive']}
                </Checkbox>
            </Panel>
        );
    }
});

class FilterOptions extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.handleToggleFiltersPanel = this.handleToggleFiltersPanel.bind(this);
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.location !== this.props.location;
    }

    handleToggleFiltersPanel() {
        LayoutActions.toggleFiltersPanel();
    }

    render() {
        return (
            <div>
                <aside className="control-sidebar B(0) Ovx(h) Ovy(a)">
                    <div className="tab-content">
                        <p>
                            <button className="btn btn-block bg-navy" onClick={this.handleToggleFiltersPanel}><i className="fas fa-chevron-right pull-right"></i>&nbsp;Close Options</button>
                        </p>
                        <ActiveOptions location={this.props.location} />
                        <DateRangeOptions location={this.props.location} />
                    </div>
                </aside>
                <div style={{position: 'fixed', height: 'auto'}} className="control-sidebar-bg"></div>
            </div>
        );
    }
}

export default WithStoreOnRoute(FilterOptions);
