/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {ApplicantNavigationConstants} from './applicant-navigation-actions';
import Dispatcher from '../../dispatcher/dispatcher';

class ApplicantNavigationStore extends ReduceStore {

    getInitialState() {
        return Immutable.Map({
            next: undefined,
            offset: 0,
            prev: undefined
        });
    }

    reduce(state, action) {
        switch (action.actionType) {

        case ApplicantNavigationConstants.CLEAR:
            state = this.getInitialState();
            break;

        case ApplicantNavigationConstants.SET_NAVIGATION:
            state = state.merge({
                next: action.next,
                offset: action.newOffset,
                prev: action.prev,
                hasApplicants: action.hasApplicants
            });
            break;
        }
        return state;
    }
}

const store = new ApplicantNavigationStore(Dispatcher);

export default store;
