/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

import Table from './table';
import DocumentTitle from '../../common/document-title';
import SearchBox from '../../common/search-box/search-box';
import {WithStoreOnRoute} from '../../common/store-on-route';
import {Debounce} from '../../common/utils/utils';
import {LayoutActions} from '../../layout/layout-actions';
import LayoutStore from '../../layout/layout-store';
import Preloader from '../../preloader';
import SessionStore from '../../user/session/session-store';
import {BatchActions, BatchConstants} from '../batch-actions';
import BatchStore from '../batch-store';
import FilterOptions from '../filter-options/filter-options';

class Browse extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static getPermissions() {
        return {
            canEditDocumentBatch: SessionStore.canUser(SessionStore.PERMISSIONS.SCHEDULING.DOCUMENT_BATCHES.EDIT),
            canEditImageBatch: SessionStore.canUser(SessionStore.PERMISSIONS.SCHEDULING.IMAGE_BATCHES.EDIT),
            canEditTitleBatch: SessionStore.canUser(SessionStore.PERMISSIONS.SCHEDULING.TITLE_BATCHES.EDIT),
            canEditVideoBatch: SessionStore.canUser(SessionStore.PERMISSIONS.SCHEDULING.VIDEO_BATCHES.EDIT)
        };
    }

    static calculateState() {
        const bs = BatchStore.getState();
        return {
            batches: bs.get('batches'),
            filters: bs.get('filters'),
            showFiltersPanel: LayoutStore.getState().get('showFiltersPanel'),
            showPreloader: bs.get('showPreloader'),
            total: bs.get('total')
        };
    }

    static getStores() {
        return [BatchStore, LayoutStore];
    }

    constructor(props) {
        super(props);

        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
        this.handleSortChange = this.handleSortChange.bind(this);
    }

    componentDidMount() {
        BatchActions.clear();
        const batchType = this.props.location.pathname.split('/').pop();
        BatchActions.get(batchType, this.getRouteState());
        return;
    }

    componentDidUpdate(prevProps) {
        const batchType = this.props.location.pathname.split('/').pop();
        const prevBatchType = prevProps.location.pathname.split('/').pop();
        if (batchType !== prevBatchType) {
            BatchActions.clearFilter();
        }
        if (this.props.location !== prevProps.location) {
            BatchActions.get(batchType, this.getRouteState(this.props));
        }
        return;
    }

    /**
     * Handle clicks on pagination controls.
     * @param  {Number} pageNumber page to load.
     */
    handleLoadPage(pageNumber) {
        // pageNumber x this.state.size = offset.
        this.setRouteState('offset', pageNumber * 20)
            .apply();
        return;
    }

    handleSearchTerm(term) {
        this.setRouteState('batch-name', term)
            .setRouteState('operator', 'AND')
            .clearRouteState('offset')
            .apply();
        BatchActions.setFilter('batch-name', term);
        return;
    }

    handleSortChange(sortFieldName, sortDirection) {
        this.setRouteState('sort-field-name', sortFieldName)
            .setRouteState('sort-direction', sortDirection)
            .apply();
        return;
    }

    handleToggleFiltersPanel() {
        LayoutActions.toggleFiltersPanel();
        return;
    }

    render() {
        const batchType = this.props.location.pathname.split('/').pop();
        const searchValue = this.state.filters.get('batch-name') || this.getRouteState().get('batch-name');
        let displayLinks = false;
        switch (batchType.toUpperCase()) {
        case BatchConstants.BATCH_TYPES.DOCUMENT:
            displayLinks = this.props.permissions.canEditDocumentBatch;
            break;
        case BatchConstants.BATCH_TYPES.IMAGE:
            displayLinks = this.props.permissions.canEditImageBatch;
            break;
        case BatchConstants.BATCH_TYPES.TITLE:
            displayLinks = this.props.permissions.canEditTitleBatch;
            break;
        case BatchConstants.BATCH_TYPES.VIDEO:
            displayLinks = this.props.permissions.canEditVideoBatch;
            break;
        }

        return (
            <DocumentTitle
                message="document-titles.scheduling"
            >
                <div className={ClassNames({'control-sidebar-open': this.state.showFiltersPanel})}>
                    <div className={ClassNames({'sidebar-opened': this.state.showFiltersPanel})}>
                        <Preloader show={this.state.showPreloader} fixed loadingDots>
                            <section className="content-header">
                                <h1>
                                    <i className="far fa-stopwatch"></i>&nbsp;{this.context.intl.messages[`scheduling.${batchType}-batches.title`]}
                                    &nbsp;<small>{this.state.total}</small>
                                </h1>
                            </section>
                            <section className="content">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="container-fluid no-x-padding">
                                            <div className="row">
                                                <div className="col-md-6 show-grid">
                                                    <SearchBox onChange={this.handleSearchTerm} value={searchValue}/>
                                                </div>
                                                <div className="col-md-6 show-grid">
                                                    <Button onClick={this.handleToggleFiltersPanel} bsStyle="primary" bsSize="large" className="pull-right">
                                                        <i className="fas fa-sliders-h"></i>&nbsp;{this.context.intl.messages['common.filteringOptions']}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container-fluid no-x-padding">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="box">
                                                        <div className="box-body">
                                                            <Table
                                                                activePage={Math.ceil((this.getRouteState().get('offset') || 0) / 20 ) || 0}
                                                                batches={this.state.batches}
                                                                batchType={batchType}
                                                                displayLinks={displayLinks}
                                                                onPageChange={this.handleLoadPage}
                                                                onSortChange={this.handleSortChange}
                                                                sortDirection={this.getRouteState().get('sort-direction') || 'desc'}
                                                                sortFieldName={this.getRouteState().get('sort-field-name') || 'BATCH_NAME'}
                                                                totalPages={Math.ceil(this.state.total/20) || 0}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <FilterOptions
                                location={this.props.location}
                            />
                        </Preloader>
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}

export default WithStoreOnRoute(Container.create(Browse));
