/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {LanguageCodeType} from '@wbdt-sie/brainiac-web-common';
import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, Col, ControlLabel, FormGroup, Modal, Row} from 'react-bootstrap';
import {Link} from 'react-router';

import {FormItem, FormRow, FormSection, LocalizedFormItem} from '../../../../common/form/form';
import {Alert} from '../../../../common/notification/alert';
import {LocalizedTinyMCE} from '../../../../common/tinymce/tinymce';
import {CreateFile} from '../../../../common/utils/utils';
import {GroupConstants} from '../../../../security/group/group-actions';
import GroupSelect from '../../../../security/group/group-select';
import {PublishingListActions} from '../../../publishing-list-actions';
import {PublishingListLocalizedActions} from '../../../publishing-list-localized-actions';
import PublishingListStore, {PublishingListItemValidations} from '../../../publishing-list-store';
import TargetTypeStore from '../target-type-store';

class Item extends Component {

    static get propTypes() {
        return {
            changeTarget: PropTypes.func.isRequired,
            currentLanguage: PropTypes.string,
            disabled: PropTypes.bool,
            isTargetChanged: PropTypes.bool.isRequired,
            item: PropTypes.instanceOf(Immutable.Map).isRequired,
            itemLocalized: PropTypes.instanceOf(Immutable.Map).isRequired,
            mode: PropTypes.string.isRequired,
            targetTypeId: PropTypes.number.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            itemImageUploaded: PublishingListStore.getState().get('itemImageUploaded'),
            itemVideoUploaded: PublishingListStore.getState().get('itemVideoUploaded')
        };
    }

    static getStores() {
        return [PublishingListStore];
    }

    static get defaultProps() {
        return {
            currentLanguage: undefined,
            disabled: true,
            publishingListLocalized: undefined
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            showDescriptionModal: false
        }, this.constructor.calculateState());

        this.handleAddItem = this.handleAddItem.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleShowDescriptionModal = this.handleShowDescriptionModal.bind(this);
        this.handleCloseDescriptionModal = this.handleCloseDescriptionModal.bind(this);
        this.handleTinyMCEChange = this.handleTinyMCEChange.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleReplace = this.handleReplace.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.disabled !== this.props.disabled ||
            nextProps.item !== this.props.item ||
            nextProps.itemLocalized !== this.props.itemLocalized) {
            return true;
        }

        if (nextState.itemImageUploaded !== this.state.itemImageUploaded ||
            nextState.itemVideoUploaded !== this.state.itemVideoUploaded ||
            nextState.showDescriptionModal !== this.state.showDescriptionModal) {
            return true;
        }

        return false;
    }

    handleAddItem() {
        // Set either the Asset or Title reference Id
        // *** Asset Id is used for Title as well, in association with the targetType ***
        // FIXME: review all this logic.
        let item = this.props.item;
        let assetId = TargetTypeStore.getState().get('assetId');
        if (assetId) {
            item = item.merge({
                assetId
            });
        }

        // Set some of the derived values in the Item
        item = item.merge({
            targetType: this.props.targetTypeId
        });

        // By default, the action will try to get/refresh the thumbnail.
        let getThumbnail = true;
        // But if the user defined a custom image...
        let imageFileInput = this.state.itemImageUploaded;
        if (imageFileInput) {
            let imageFile = imageFileInput;
            item = item.set('newFile', imageFile);

            // Then don't try to get it.
            getThumbnail = false;
        }
        let videoFileInput = this.state.itemVideoUploaded;
        if (videoFileInput) {
            let videoFile = videoFileInput;
            item = item.set('newVideoFile', videoFile);
            //WPB-7219 this is because if hasAudio is null then the server returns the value of the video asset if any
            if (item.get('hasAudio') === undefined) {
                item = item.set('hasAudio', false);
            }
        }

        PublishingListActions.updateListItem(item, getThumbnail);
        PublishingListActions.hide();
        PublishingListActions.clearItem();
    }

    handleFileChange(input, attr) {
        if (
            this.refs[input].files &&
            this.refs[input].files[0]
        ) {
            let file = this.refs[input].files[0];
            file = CreateFile(file); //for custom images and videos
            PublishingListActions.updateAttr(attr, file);
        }
    }

    handleShowDescriptionModal() {
        this.setState({
            showDescriptionModal: true
        });
    }

    handleCloseDescriptionModal() {
        this.setState({
            showDescriptionModal: false
        });
    }

    handleSelectGroup(groups) {
        if (!groups || !groups.length) {
            // Reset the value.
            PublishingListActions.setItemUserGroups(Immutable.OrderedSet());
            return;
        }

        // Call the select action but with immutable objects.
        PublishingListActions.setItemUserGroups(Immutable.fromJS(groups).toSet().sortBy(g => g.get('name')));
        return;
    }

    handleTinyMCEChange(e) {
        switch (this.props.currentLanguage) {
        case LanguageCodeType.DEFAULT_LANGUAGE:
            PublishingListActions.updateListItemAttr('description', e.target.getContent());
            break;
        default:
            PublishingListLocalizedActions.updateItem('description', e.target.getContent());
            break;
        }
        return;
    }

    handleRemove(newFile, newS3FileUrl, newFileUrl) {
        let item = this.props.item;
        item = item.set(newFileUrl, undefined);
        item = item.set(newS3FileUrl, undefined);
        item = item.set(newFile, undefined);

        PublishingListActions.updateListItem(item);
    }

    handleReplace(attr) {
        this.refs[attr].click();
    }

    handleUpload(attr) {
        this.refs[attr].click();
        return;
    }

    render() {
        const item = this.props.item;
        const isHTML = item.get('isHTML');
        const isLocalized = this.props.currentLanguage !== LanguageCodeType.DEFAULT_LANGUAGE;
        let userGroups = <div></div>;
        let targetUrl = <div></div>;
        let itemImageUploaded = this.state.itemImageUploaded || this.props.item.toJS().newFile || {};
        let itemVideoUploaded = this.state.itemVideoUploaded || this.props.item.toJS().newVideoFile || {};

        let itemImageName;
        let itemVideoName;
        if (item.get('imageS3Path')) {
            itemImageName = item.get('imageS3Path').split('/');
            itemImageName = itemImageName[itemImageName.length -1];
        }
        if (item.get('videoS3Path')) {
            itemVideoName = item.get('videoS3Path').split('/');
            itemVideoName = itemVideoName[itemVideoName.length -1];
        }

        const groups = item.get('userGroups', Immutable.OrderedSet());
        const validations = PublishingListStore.getItemValidations();

        let description = (
            <LocalizedFormItem
                attr="description"
                disabled={this.props.disabled}
                handleShowDescriptionModal={this.handleShowDescriptionModal}
                localized={isLocalized}
                localizedModel={this.props.itemLocalized}
                localizedSetter={PublishingListLocalizedActions.updateItem}
                model={item}
                setter={PublishingListActions.updateListItemAttr}
                type="textarea"
            />
        );

        if (isHTML) {
            let itemLocalizedDescription = '';
            if (this.props.itemLocalized) {
                itemLocalizedDescription = this.props.itemLocalized.getIn(['description']);
            }
            description = (
                <LocalizedTinyMCE
                    content={item.get('description') || ''}
                    disabled={this.props.disabled}
                    enableLink={true}
                    forcedRootBlock="div"
                    formats={'Paragraph=p;Header 1=h1;Header 2=h2;Header 3=h3'}
                    handleShowDescriptionModal={this.handleShowDescriptionModal}
                    id="itemDescription"
                    onChange={this.handleTinyMCEChange}
                    localized={isLocalized}
                    localizedContent={itemLocalizedDescription}
                />
            );
        }

        if (this.props.targetTypeId > 2) {
            userGroups = <FormRow>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['publishing-list.list-item.item.user-group']}&nbsp;</ControlLabel>
                    <GroupSelect
                        disabled={this.props.disabled}
                        onChange={this.handleSelectGroup}
                        selected={groups}
                        type={GroupConstants.USER}
                    />
                </FormGroup>
            </FormRow>;
        }

        let save = <div></div>;
        if (this.props.mode !== 'edit' && !this.props.isTargetChanged) {
            save = (
                <Row className="row padding-bottom-20">
                    <Col md={2} mdOffset={10}>
                        <Button
                            className= "bg-wb-blue pull-right"
                            disabled={!!validations.length}
                            onClick={this.handleAddItem}>
                            <i className="fas fa-plus"></i>&nbsp;{this.context.intl.messages['publishing-list.list-item.item.save.button']}
                        </Button>
                    </Col>
                </Row>
            );
        }

        let customImagePreview;
        if (item.get('imageUrl') && item.get('imageS3Path')) {
            customImagePreview = <FormSection><FormRow>
                <FormGroup className="item-preview">
                    <img
                        className="W(a) Maw(100%)"
                        src={item.get('imageUrl')}
                    />
                </FormGroup>
                <FormGroup>
                    <FormRow>
                        <ControlLabel>
                            {this.context.intl.messages['publishing-list.list-item.item.upload.image']}&nbsp;
                        </ControlLabel>
                        <input
                            accept="image/*"
                            className="hide"
                            id="imageFile"
                            onChange={this.handleFileChange.bind(this, 'replaceImage', 'itemImageUploaded')}
                            ref="replaceImage"
                            type="file"
                        />
                    </FormRow>
                    <FormRow><p><small><a href={item.get('imageUrl')} target="_blank">{itemImageName}<i className="fas fa-external-link-alt"></i></a></small></p></FormRow>
                    <hr />
                    <FormRow>
                        <Button
                            className= "default text-red"
                            disabled={isLocalized}
                            onClick={this.handleReplace.bind(this, 'replaceImage')}>
                            <i className="fas fa-random"></i>&nbsp;{this.context.intl.messages['publishing-list.list-item.item.replace-image.button']}
                        </Button>
                        <Button
                            className= "default text-red"
                            disabled={isLocalized}
                            onClick={this.handleRemove.bind(this, 'newFile', 'imageS3Path', 'imageUrl')}>
                            <i className="fas fa-trash-alt"></i>&nbsp;{this.context.intl.messages['publishing-list.list-item.item.remove-image.button']}
                        </Button>
                    </FormRow>
                    <FormRow>
                        <em>{itemImageUploaded.name}</em>
                    </FormRow>
                </FormGroup>
            </FormRow></FormSection>;
        } else {
            customImagePreview = <FormSection><FormRow>
                <FormGroup className="item-preview">
                    <h1 className="text-center" style={{color: '#ccc'}}>
                        <i className="far fa-image"></i>
                    </h1>
                </FormGroup>
                <FormGroup>
                    <FormRow>
                        <ControlLabel>
                            {this.context.intl.messages['publishing-list.list-item.item.upload.image']}&nbsp;
                        </ControlLabel>
                        <input
                            accept="image/*"
                            className="hide"
                            id="imageFile"
                            onChange={this.handleFileChange.bind(this, 'uploadImage', 'itemImageUploaded')}
                            ref="uploadImage"
                            type="file"
                        />
                    </FormRow>
                    <FormRow><p><small>{this.context.intl.messages['publishing-list.list-item.item.none-selected']}</small></p></FormRow>
                    <hr />
                    <FormRow>
                        <Button
                            className= "default"
                            disabled={isLocalized}
                            onClick={this.handleUpload.bind(this, 'uploadImage')}>
                            <i className="fas fa-plus"></i>&nbsp;{this.context.intl.messages['publishing-list.list-item.item.add-image.button']}
                        </Button>
                    </FormRow>
                    <FormRow>
                        <em>{itemImageUploaded.name}</em>
                    </FormRow>
                </FormGroup>
            </FormRow></FormSection>;
        }
        let customVideoPreview;
        if (item.get('videoUrl') && item.get('videoS3Path')) {
            customVideoPreview = <FormSection><FormRow>
                <FormGroup className="item-preview">
                    <video
                        autoPlay
                        className="W(a) Maw(100%)"
                        controls
                        loop
                        src={item.get('videoUrl')}
                    >
                    </video>
                </FormGroup>
                <FormGroup>
                    <FormRow>
                        <ControlLabel>
                            {this.context.intl.messages['publishing-list.list-item.item.upload.video']}&nbsp;
                            (<a href="https://warnerbrostv.atlassian.net/wiki/spaces/PB/pages/66177419/Homepage+Management" target="_blank">{this.context.intl.messages['publishing-list.list-item.item.upload.help']}</a>)
                        </ControlLabel>
                        <input
                            accept="video/*"
                            className="hide"
                            id="videoFile"
                            onChange={this.handleFileChange.bind(this, 'replaceVideo', 'itemVideoUploaded')}
                            ref="replaceVideo"
                            type="file"
                        />
                    </FormRow>
                    <FormRow><p><small><a href={item.get('videoUrl')} target="_blank">{itemVideoName}&nbsp;<i className="fas fa-external-link-alt"></i></a></small></p></FormRow>
                    <FormRow>
                        <FormGroup>
                            <FormItem
                                attr="hasAudio"
                                disabled={this.props.disabled || isLocalized}
                                label={this.context.intl.messages['asset.video.create.has-audio']}
                                model={item}
                                setter={PublishingListActions.updateListItemAttr}
                                type="checkbox"
                            />
                        </FormGroup>
                    </FormRow>
                    <hr />
                    <FormRow>
                        <Button
                            className= "default text-red"
                            disabled={isLocalized}
                            onClick={this.handleReplace.bind(this, 'replaceVideo')}>
                            <i className="fas fa-random"></i>&nbsp;{this.context.intl.messages['publishing-list.list-item.item.replace-video.button']}
                        </Button>
                        <Button
                            className= "default text-red"
                            disabled={isLocalized}
                            onClick={this.handleRemove.bind(this, 'newVideoFile', 'videoS3Path', 'videoUrl')}>
                            <i className="fas fa-trash-alt"></i>&nbsp;{this.context.intl.messages['publishing-list.list-item.item.remove-video.button']}
                        </Button>
                    </FormRow>
                    <FormRow>
                        <em>{itemVideoUploaded.name}</em>
                    </FormRow>
                </FormGroup>
            </FormRow></FormSection>;
        } else {
            customVideoPreview = <FormSection><FormRow>
                <FormGroup className="item-preview">
                    <h1 className="text-center" style={{color: '#ccc'}}>
                        <i className="fas fa-video"></i>
                    </h1>
                </FormGroup>
                <FormGroup>
                    <FormRow>
                        <ControlLabel>
                            {this.context.intl.messages['publishing-list.list-item.item.upload.video']}&nbsp;
                        </ControlLabel>
                        <input
                            accept="video/*"
                            className="hide"
                            id="videoFile"
                            onChange={this.handleFileChange.bind(this, 'uploadVideo', 'itemVideoUploaded')}
                            ref="uploadVideo"
                            type="file"
                        />
                    </FormRow>
                    <FormRow><p><small>{this.context.intl.messages['publishing-list.list-item.item.none-selected']}</small></p></FormRow>
                    <hr />
                    <FormRow>
                        <FormGroup>
                            <Button
                                disabled={isLocalized}
                                className= "default"
                                onClick={this.handleUpload.bind(this, 'uploadVideo')}>
                                <i className="fas fa-plus"></i>&nbsp;{this.context.intl.messages['publishing-list.list-item.item.add-video.button']}
                            </Button>
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <em>{itemVideoUploaded.name}</em>
                    </FormRow>
                </FormGroup>
            </FormRow></FormSection>;
        }

        const isHidden = !this.props.isTargetChanged && this.props.mode !== 'edit';
        let targetTable;
        let targetTitle = <ControlLabel>
            {this.context.intl.messages['publishing-list.list-item.item.target.title']}&nbsp;
        </ControlLabel>;
        let targetType = this.props.targetTypeId;
        if (!this.props.targetTypeId) {
            targetType = item.get('targetType');
        }

        let parentTitleDisplayName = '';
        const parentTitleId = item.getIn(['asset', 'parentTitleId']);
        if (parentTitleId) {
            parentTitleDisplayName = item.getIn(['asset', 'parentTitleDisplayName']);
        }

        switch (targetType) {
        case 1: //title
            targetTable = <FormRow><table id="edit-list-item-table" className="table table-bordered table-striped responsive">
                <thead>
                    <tr>
                        <th>{this.context.intl.messages['publishing-list.list-item.target.table.running-order']}</th>
                        <th>{this.context.intl.messages['publishing-list.list-item.target.table.title-parent']}</th>
                        <th>{this.context.intl.messages['publishing-list.list-item.target.table.title']}</th>
                        <th>{this.context.intl.messages['publishing-list.list-item.target.table.title-type']}</th>
                        <th>{this.context.intl.messages['publishing-list.list-item.target.table.mpm']}</th>
                        <th>{this.context.intl.messages['publishing-list.list-item.target.table.title-id']}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{item.getIn(['asset', 'fullRunningOrder'])}</td>
                        <td><Link to={`/titles/${parentTitleId}`}>{parentTitleDisplayName}</Link></td>
                        <td><Link to={`/titles/${item.getIn(['asset', 'id'])}`}>{item.getIn(['asset', 'displayName'])}</Link></td>
                        <td>{item.getIn(['asset', 'categoryName'])}</td>
                        <td>{item.getIn(['asset', 'mpmNumber'])}</td>
                        <td>{item.getIn(['asset', 'id'])}</td>
                    </tr>
                </tbody>
            </table></FormRow>;
            break;
        case 2: //asset
            let displayAssetTypeName = item.get('displayAssetTypeName', '').toLowerCase();
            targetTable = <FormRow><table id="edit-list-item-table" className="table table-bordered table-striped responsive">

                <thead>
                    <tr>
                        <th>{this.context.intl.messages['publishing-list.list-item.target.table.asset-parent']}</th>
                        <th>{this.context.intl.messages['publishing-list.list-item.target.table.asset-name']}</th>
                        <th>{this.context.intl.messages['publishing-list.list-item.target.table.asset-type']}</th>
                        <th>{this.context.intl.messages['publishing-list.list-item.target.table.asset-id']}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><Link to={`/titles/${item.getIn(['asset', 'parentTitleId'])}`}>{item.getIn(['asset', 'parentTitleDisplayName'])}</Link></td>
                        <td><Link to={`/assets/${displayAssetTypeName}/${item.getIn(['asset', 'id'])}`}>{item.getIn(['asset', 'assetName'])}</Link></td>
                        <td>{item.get('displayAssetTypeName')}</td>
                        <td>{item.getIn(['asset', 'id'])}</td>
                    </tr>
                </tbody>
            </table></FormRow>;
            break;
        default: //item
            targetTitle = '';
            targetUrl = <FormRow><FormItem
                attr="targetUrl"
                disabled={this.props.disabled}
                label={this.context.intl.messages['publishing-list.list-item.item.target-link.title']}
                model={item}
                setter={PublishingListActions.updateListItemAttr}
                type="text"
                validations={PublishingListItemValidations.targetUrl.validations}
            /></FormRow>;
            break;
        }

        const formattedHTML = (
            <div>
                <h3>{this.context.intl.messages['publishing-list.list-item.item.formatted.title']}</h3>
                <div className="view-description" dangerouslySetInnerHTML={{__html: item.get('description')}}></div>
            </div>
        );

        let rawTitle = 'publishing-list.list-item.item.raw.title';
        if (isHTML) {
            rawTitle = 'publishing-list.list-item.item.raw-html.title';
        }

        return (
            <div>
                <div className="row">
                    <Modal show={this.state.showDescriptionModal} onEnter={this.showPreloader} onHide={this.handleCloseDescriptionModal}>
                        <Modal.Header className="bg-gray" closeButton>
                            <Modal.Title className="text-center">{this.context.intl.messages['publishing-list.list-item.item.original-description.title']}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Alert withHeader/>
                            {isHTML && formattedHTML}
                            <h3>{this.context.intl.messages[rawTitle]}</h3>
                            <pre className="description-html">{item.get('description')}</pre>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="btn btn-default" onClick={this.handleCloseDescriptionModal}>{this.context.intl.messages['common.close']}</Button>
                        </Modal.Footer>
                    </Modal>
                </div>
                <div className="row">
                    <FormSection>
                        {customImagePreview}
                        {customVideoPreview}
                        <FormSection>
                            <div className="text-align-left">
                                <FormRow>
                                    <div className="margin-bottom-10">
                                        {targetTitle}
                                        <Button className={ClassNames('btn btn-md btn-default margin-bottom-10 margin-left-10', {hidden: isHidden})} onClick={this.props.changeTarget}>
                                            <i className="fas fa-random"></i>&nbsp;
                                            {this.context.intl.messages['publishing-list.list-item.target.replace-target.button']}
                                        </Button>
                                    </div>
                                </FormRow>
                                {targetTable}
                                {targetUrl}
                                <FormRow>
                                    <LocalizedFormItem
                                        attr="displayName"
                                        label={this.context.intl.messages['publishing-list.list-item.item.display-name']}
                                        disabled={this.props.disabled}
                                        model={item}
                                        setter={PublishingListActions.updateListItemAttr}
                                        type="text"
                                        localized={isLocalized}
                                        localizedModel={this.props.itemLocalized}
                                        localizedSetter={PublishingListLocalizedActions.updateItem}
                                    />
                                </FormRow>
                                <FormRow>
                                    <FormGroup>
                                        <ControlLabel>
                                            {this.context.intl.messages['publishing-list.list-item.item.description']}
                                            <FormItem
                                                attr="isHTML"
                                                disabled={this.props.disabled}
                                                label={this.context.intl.messages['publishing-list.list-item.item.is-html']}
                                                model={item}
                                                setter={PublishingListActions.updateListItemAttr}
                                                type="checkbox"
                                            />
                                        </ControlLabel>
                                        <FormRow>
                                            {description}
                                        </FormRow>
                                    </FormGroup>
                                </FormRow>
                                {userGroups}
                                {save}
                            </div>
                        </FormSection>
                    </FormSection>
                </div>
            </div>
        );
    }
}

export default Container.create(Item);
