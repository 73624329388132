/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {NotificationConstants} from './notification-actions';
import Dispatcher from '../../dispatcher/dispatcher';
import {RouterConstants} from '../../router/router-actions';
import {SlidingPanelConstants} from '../sliding-panel/sliding-panel-actions';

class NotificationStore extends ReduceStore {

    getInitialState() {
        return Immutable.fromJS({
            mfaEnrollmentEmailModal: false,
            mfaPushNotificationModal: false,
            notifications: []
        });
    }

    reduce(state, action) {
        switch (action.actionType) {

        case NotificationConstants.ALERT.SHOW:
            if (state.get('notifications').size > 4) {
                state = state.update('notifications', notifications => notifications.shift());
            }
            state = state.update('notifications', notifications => notifications.push(Immutable.Map({
                arguments: action.arguments,
                message: action.message,
                type: action.type
            })));
            break;

        case NotificationConstants.MODAL.HIDE:
            if (action.modalId) {
                state = state.set(action.modalId, false);
            }
            break;

        case NotificationConstants.MODAL.SHOW:
            // Create the Modal Message
            let msg = Immutable.Map({
                type:action.type,
                title:action.title,
                message: action.message,
                confirmText: action.confirmText,
                show: true,
                onConfirm: action.onConfirm,
                onCancel: action.onCancel});
            // If there is already a notification then show this one on top
            let item = state.get('notifications').first();
            if (item) {
                state = state.update('notifications', notifications => notifications.unshift(msg));
            } else {
                state = state.update('notifications', notifications => notifications.push(msg));
            }
            break;

        case NotificationConstants.MODAL.SHOW_ID:
            state = state.set(action.modalId, true);
            break;

        case NotificationConstants.MODAL.SHOW_ON_TOP:
            state = state.update('notifications', notifications => notifications.unshift(Immutable.Map({
                type:action.type,
                title:action.title,
                message: action.message,
                confirmText: action.confirmText,
                show: true,
                onConfirm: action.onConfirm,
                onCancel: action.onCancel})));
            break;

        case NotificationConstants.HIDE:
            let index = action.index || 0;
            state = state.update('notifications', notifications => notifications.splice(index, 1));
            break;

        // Clear the notifications queue when the route
        // is going to change. Or when the sliding panel
        // hides.
        // TODO: improve this function to allow persistent notifications.
        case NotificationConstants.CLEAR:
        case RouterConstants.ROUTE_CHANGE_START:
        case SlidingPanelConstants.HIDE:
            state = this.getInitialState();
            break;

        default:
            break;
        }
        return state;
    }
}

let ns = new NotificationStore(Dispatcher);

export default ns;
