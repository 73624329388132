/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
*/

import ClassNames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';
import {Link} from 'react-router';
import ReactTable from 'react-table';

import {AssetConstants} from '../../../assets/asset-actions';
import DocumentStore from '../../../assets/document/document-store';
import VideoStore from '../../../assets/video/video-store';
import {MODAL_TYPE} from '../../../common/notification/modal';
import {NotificationActions} from '../../../common/notification/notification-actions';
import {BatchActions, BatchConstants} from '../../batch-actions';

import 'react-table/react-table.css';

export default class Table extends Component {
    static get propTypes() {
        return {
            disabled: PropTypes.bool,
            items: PropTypes.instanceOf(Immutable.List).isRequired,
            type: PropTypes.string.isRequired,
        };
    }

    static get defaultProps() {
        return {
            disabled: true
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            id: Math.ceil(Math.random() * 100000)
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.showDeleteDialog = this.showDeleteDialog.bind(this);
    }

    handleDeleteItem(item) {
        BatchActions.deleteItem(item);
    }

    handleShowItemDetail(item) {
        BatchActions.setItemData(item);
        BatchActions.toggleShowItemDetail();
    }

    showDeleteDialog(item) {
        NotificationActions.show(
            MODAL_TYPE.DANGER,
            this.context.intl.messages['scheduling.batch.items-list.confirm-delete.title'],
            this.context.intl.messages[`scheduling.batch.items-list.confirm-delete-${this.props.type.toLowerCase()}.body`],
            this.context.intl.messages['common.confirm'],
            this.handleDeleteItem.bind(this, item)
        );
    }

    getThumbnail(item) {
        if (this.props.type === BatchConstants.BATCH_TYPES.IMAGE || this.props.type === BatchConstants.BATCH_TYPES.VIDEO) {
            const thumbnailUrl = item.get('thumbnailUrl') || item.get('thumbnailSmallUrl') || item.getIn(['thumbnails', 0, 'thumbnailUrl']);
            if (thumbnailUrl) {
                return (
                    <img className="table-thumbnail" src={thumbnailUrl} alt="thumbnail" />
                );
            }
            return (
                <i className={ClassNames(AssetConstants.ASSET_TYPES[this.props.type].icon)}></i>
            );
        }
        return;
    }

    render() {
        let columns = [{
            Header: <strong><i className="far fa-image"></i></strong>,
            headerClassName: 'h-alignment',
            className: 'h-alignment',
            Cell: c => {
                return (
                    <div>
                        {this.getThumbnail(c.original) || '-'}
                    </div>
                );
            },
            width: 42,
            show: [BatchConstants.BATCH_TYPES.IMAGE, BatchConstants.BATCH_TYPES.VIDEO].includes(this.props.type)
        }, {
            Header: <strong>{this.context.intl.messages['scheduling.batch.items-list.table.format']}</strong>,
            headerClassName: 'h-alignment',
            className: 'h-alignment',
            Cell: c => {
                return (
                    <span className="label bg-gray">
                        <i className="far fa-image"/> {c.original.get('mediaFile') || '-'}
                    </span>
                );
            },
            width: 72,
            show: this.props.type === BatchConstants.BATCH_TYPES.IMAGE
        }, {
            Header: <strong>{this.context.intl.messages['scheduling.batch.items-list.table.type']}</strong>,
            Cell: c => {
                const contentType = VideoStore.getContentType(c.original.get('contentType'));
                return (
                    <div>
                        {contentType.get('name') || '-'}
                    </div>
                );
            },
            width: 72,
            show: this.props.type === BatchConstants.BATCH_TYPES.VIDEO
        }, {
            Header: <strong>{this.context.intl.messages['scheduling.batch.items-list.table.type']}</strong>,
            headerClassName: 'h-alignment',
            className: 'h-alignment',
            Cell: c => {
                const contentType = DocumentStore.getContentTypeAsString(c.original.get('contentType') || c.original.get('documentType'));
                return (
                    <div>
                        <span className="label bg-gray">{contentType}</span>
                    </div>
                );
            },
            width: 220,
            show: this.props.type === BatchConstants.BATCH_TYPES.DOCUMENT
        }, {
            Header: <strong>{this.context.intl.messages[`scheduling.batch.items-list.table.${this.props.type.toLowerCase()}-id`]}</strong>,
            Cell: c => {
                return (
                    <div>
                        {c.original.get('id') || '-'}
                    </div>
                );
            },
            width: 92,
            show: this.props.type !== BatchConstants.BATCH_TYPES.TITLE
        }, {
            Header: <strong>{this.context.intl.messages['scheduling.batch.items-list.table.name']}</strong>,
            Cell: c => {
                return (
                    <div>
                        <a onClick={this.handleShowItemDetail.bind(this, c.original)}>{c.original.get('assetName') || c.original.get('name') || '-'}</a>
                    </div>
                );
            },
            show: this.props.type !== BatchConstants.BATCH_TYPES.TITLE
        }, {
            Header: <strong>{this.context.intl.messages['scheduling.batch.items-list.table.name']}</strong>,
            Cell: c => {
                return (
                    <div>
                        <Link target="_blank" to={`/titles/${c.original.get('id')}`}>{c.original.get('name') || c.original.get('displayName')}</Link>
                    </div>
                );
            },
            show: this.props.type === BatchConstants.BATCH_TYPES.TITLE
        }, {
            Header: <strong>{this.context.intl.messages['scheduling.batch.items-list.table.category']}</strong>,
            Cell: c => {
                return (
                    <div>
                        {c.original.get('categoryName') || '-'}
                    </div>
                );
            },
            show: this.props.type === BatchConstants.BATCH_TYPES.TITLE
        }, {
            Header: <strong>{this.context.intl.messages['scheduling.batch.items-list.table.mpm']}</strong>,
            className: 'h-alignment',
            Cell: c => {
                return (
                    <div>
                        {c.original.get('mpmNumber') || '-'}
                    </div>
                );
            },
            width: 74,
            show: this.props.type === BatchConstants.BATCH_TYPES.TITLE
        }, {
            Header: <strong>{this.context.intl.messages['scheduling.batch.items-list.table.remove']}</strong>,
            className: 'h-alignment',
            Cell: c => {
                return (
                    <Button
                        bsSize="sm"
                        className="btn btn-navy-outline"
                        disabled={this.props.disabled}
                        onClick={this.showDeleteDialog.bind(this, c.original)}
                    >
                        <i className="fas fa-times"></i>&nbsp;{this.context.intl.messages['scheduling.batch.items-list.table.remove']}
                    </Button>
                );
            },
            width: 90
        }];

        return (
            <ReactTable
                className="-striped table-bordered table-striped responsive"
                columns={columns}
                data={this.props.items}
                defaultPageSize = { this.props.items.size }
                getNoDataProps= {() => {
                    if (this.props.items.size) {
                        return {style: {display: 'none'}};
                    }
                    return {};
                }}
                id={`batches-items-list-${this.state.id}`}
                loading={false}
                pageSize = { this.props.items.size }
                resizable={false}
                showPageSizeOptions = {false}
                showPagination={false}
                sortable={false}
            />
        );
    }
}
