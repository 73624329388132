/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React, {ReactNode} from 'react';
import {Button, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';
import {Column, CellInfo} from 'react-table'; // eslint-disable-line import/named

import AiModelsActions from '../../../ai-models-actions';
import {OriginalModelsAsCache$} from '../bl/stores';
import {VIDEO_INSIGHT_STATUS_TYPES} from '../constants';
import {IsAssignedModel, IsProcessingStatusType} from '../typeguards';

import {FormatDateToDefaultTZString} from '~/src/common/utils/date-converters';

// hack: CellInfo is not generic =(
type ModelCell = Omit<CellInfo, 'original'> & {original: VideoMachineLearningProject};

export type CellExtraProps = {
    messages: {
        'asset.edit.ai-models-tab.messages.run.tooltip': string,
        'date-format-title-release-0': string,
    },
}

const isOutdated = (model: VideoMachineLearningProjectDetail) => !model.active;

const LABEL_FOR_STATUS: { [key in Exclude<VideoInsightStatusType, VideoInsightStatusType$Processing>]: () => ReactNode} = {
    [VIDEO_INSIGHT_STATUS_TYPES.FAILED]: () => (
        <span>
            <span className="text-red">
                <i className="fas fa-exclamation-triangle"/>
            </span>
            &nbsp;
            <FormattedMessage id="asset.edit.ai-models-tab.table.status.failed" />
        </span>
    ),
    [VIDEO_INSIGHT_STATUS_TYPES.SUCCEEDED]: () => (
        <span>
            <span className="text-green">
                <i className="fas fa-check"/>
            </span>
            &nbsp;
            <FormattedMessage id="asset.edit.ai-models-tab.table.status.complete" />
        </span>
    ),
};

const MODEL_NAME: Column<VideoMachineLearningProject> = {
    accessor: 'projectName',
    Header: () => (
        <strong className="sorting">
            <FormattedMessage id="asset.edit.ai-models-tab.table.model" />
        </strong>
    ),
    Cell: (ci: ModelCell) => {
        let icon;

        if (ci.original.description) {
            icon = (
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id={`filename-tooltip-${ci.original.iterationId}`}>
                            {ci.original.description}
                        </Tooltip>
                    }>
                    <i className="far fa-info-circle"/>
                </OverlayTrigger>
            );
        }

        return (
            <span>
                {ci.original.projectName}
                &nbsp;
                {icon}
            </span>
        );
    },
    sortable: true,
};

const LAST_RUN: Column<VideoMachineLearningProject> = {
    Header: () => (
        <strong className="insightUpdatedDate">
            <FormattedMessage id="asset.edit.ai-models-tab.table.last-run" />
        </strong>
    ),
    Cell: (ci: ModelCell) => {
        const cellProps = ci.tdProps.rest as CellExtraProps;
        const model = ci.original;
        if (!IsAssignedModel(model)) {
            return <span />;
        }
        const date = model.insightUpdatedDate || model.insightCreatedDate;
        let icon;
        if (isOutdated(model)) {
            icon = (
                <span className="text-yellow">
                    <i className="fas fa-exclamation-triangle"/>
                    &nbsp;
                </span>
            );
        }
        return (
            <span>
                {icon}
                {FormatDateToDefaultTZString(date, cellProps.messages['date-format-title-release-0'])}
            </span>
        );
    },
    sortable: true,
    // sortMethod(a: Timestamp, b: Timestamp, desc: boolean) {
    //     let res = 0;
    //     const t1 = GetValueOfTimestamp(a);
    //     const t2 = GetValueOfTimestamp(b);
    //     if (t1 > t2) {
    //         res = 1;
    //     } else if (t1 < t2) {
    //         res = -1;
    //     }
    //     if (desc) {
    //         return -1 * res;
    //     }
    //     return res;
    // },
};

const UPDATED_DATE: Column<VideoMachineLearningProject> = {
    accessor: 'updatedDate',
    Header: () => (
        <strong className="sorting">
            <FormattedMessage id="asset.edit.ai-models-tab.table.updated-date" />
        </strong>
    ),
    Cell: (ci: ModelCell) => {
        const cellProps = ci.tdProps.rest as CellExtraProps;
        const date = ci.original.updatedDate || ci.original.createdDate;
        if (!date) {
            return <span/>;
        }
        return (
            <span>
                {FormatDateToDefaultTZString(date, cellProps.messages['date-format-title-release-0'])}
            </span>
        );
    },
    sortable: true,
    // sortMethod(a: Timestamp, b: Timestamp, desc: boolean) {
    //     let res = 0;
    //     const t1 = GetValueOfTimestamp(a);
    //     const t2 = GetValueOfTimestamp(b);
    //     if (t1 > t2) {
    //         res = 1;
    //     } else if (t1 < t2) {
    //         res = -1;
    //     }
    //     if (desc) {
    //         return -1 * res;
    //     }
    //     return res;
    // },
};

const ITERATION: Column<VideoMachineLearningProject> = {
    accessor: 'iterationName',
    Header: () => (
        <strong className="sorting">
            <FormattedMessage id="asset.edit.ai-models-tab.table.iteration" />
        </strong>
    ),
    Cell: (ci: ModelCell) => {
        return (
            <span>
                {ci.original.iterationName}
            </span>
        );
    },
    sortable: true,
};

const STATUS: Column<VideoMachineLearningProject> = {
    accessor: 'statusType',
    Header: () => (
        <strong className="sorting">
            <FormattedMessage id="asset.edit.ai-models-tab.table.status" />
        </strong>
    ),
    Cell: (ci: ModelCell) => {
        const model = ci.original;

        if (!IsAssignedModel(model)) {
            return (
                <span>
                    <span className="text-yellow">
                        <i className="fas fa-ellipsis-h"/>
                    </span>
                &nbsp;
                    <FormattedMessage id="asset.edit.ai-models-tab.table.status.processing" />
                </span>
            );
        }

        const status = model.statusType;
        if (!IsProcessingStatusType(status)) {
            const renderer = LABEL_FOR_STATUS[status];
            return renderer();
        }
        return (
            <span>
                <span className="text-yellow">
                    <i className="fas fa-ellipsis-h"/>
                </span>
                &nbsp;
                <FormattedMessage id="asset.edit.ai-models-tab.table.status.in-progress" />
            </span>
        );
    },
    sortable: true,
};

const RUN_AGAIN: Column<VideoMachineLearningProject> = {
    Header: () => <strong><FormattedMessage id="asset.edit.ai-models-tab.table.run-again" /></strong>,
    Cell: (ci: ModelCell) => {
        const model = ci.original;
        if (!IsAssignedModel(model) || IsProcessingStatusType(model.statusType)) {
            return <span />;
        }

        if (model.statusType === 'succeeded' && !isOutdated(model)) {
            return <span />;
        }

        const original = OriginalModelsAsCache$.value[model.projectId];
        const cellProps = ci.tdProps.rest as CellExtraProps;
        let disabled = true;
        let title = cellProps.messages['asset.edit.ai-models-tab.messages.run.tooltip'];
        if (IsAssignedModel(original) && original.insightActive === model.insightActive) {
            title = '';
            disabled = false;
        }

        // show button only for outdated or failed models
        const button = (
            <Button
                className="btn btn-primary-outline"
                disabled={disabled}
                data-testid="run-btn"
                title={title}
                onClick={() => AiModelsActions.runModel(model)}
            >
                <i className="fas fa-retweet"/>
                &nbsp;
                <FormattedMessage id="asset.edit.ai-models-tab.table.run-again" />
            </Button>
        );

        return button;

    },
};

const ACTIVE: Column<VideoMachineLearningProjectDetail> = {
    Header: () => <strong><FormattedMessage id="asset.edit.ai-models-tab.active.title" /></strong>,
    Cell: (ci: ModelCell) => {
        const model = ci.original;
        if (!IsAssignedModel(model) || IsProcessingStatusType(model.statusType)) {
            return <span />;
        }

        return (
            <button
                className="btn btn-sm btn-navy-outline"
                data-testid="deactivate-btn"
                key={ci.original.videoMachineLearningProjectId}
                onClick={() => AiModelsActions.deactivate(ci.original)}
            >
                <i className="fas fa-times"/>
               &nbsp;
                <FormattedMessage id="asset.edit.ai-models-tab.table.deactivate" />
            </button>
        );
    },
};

const INACTIVE: Column<VideoMachineLearningProjectDetail> = {
    Header: () => <strong><FormattedMessage id="asset.edit.ai-models-tab.active.title" /></strong>,
    Cell: (ci: ModelCell) => {
        const model = ci.original;
        if (!IsAssignedModel(model)) {
            return <span />;
        }

        return (
            <button
                className="btn btn-sm btn-navy-outline"
                data-testid="activate-btn"
                key={ci.original.videoMachineLearningProjectId}
                onClick={() => AiModelsActions.activate(model)}
            >
                <i className="fas fa-times"/>
                &nbsp;
                <FormattedMessage id="asset.edit.ai-models-tab.table.activate" />
            </button>
        );
    },
    sortable: false,
};

const ASSIGN: Column<VideoMachineLearningProjectDetail> = {
    Header: () => <strong><FormattedMessage id="asset.edit.ai-models-tab.active.title" /></strong>,
    Cell: (ci: ModelCell) => {
        return (
            <button
                className="btn btn-sm btn-navy-outline"
                data-testid="assign-btn"
                key={ci.original.videoMachineLearningProjectId}
                onClick={() => AiModelsActions.assign(ci.original)}
            >
                <i className="fas fa-times"/>
                &nbsp;
                <FormattedMessage id="asset.edit.ai-models-tab.table.activate" />
            </button>
        );
    },
    sortable: false,
};

export const ACTIVE_STATUS_COLUMNS = [
    MODEL_NAME,
    LAST_RUN,
    UPDATED_DATE,
    ITERATION,
    STATUS,
    RUN_AGAIN,
    ACTIVE,
];

export const INACTIVE_STATUS_COLUMNS = [
    MODEL_NAME,
    LAST_RUN,
    UPDATED_DATE,
    ITERATION,
    STATUS,
    INACTIVE,
];

export const NOT_APPLIED_MODELS_COLUMNS = [
    MODEL_NAME,
    UPDATED_DATE,
    ITERATION,
    ASSIGN,
];
