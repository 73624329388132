/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, FormGroup, Modal} from 'react-bootstrap';

import UsersNotFoundModal from './users-not-found-modal';
import {EventActions} from '../../../event-actions';

import {FormRow, FormSection} from '~/src/common/form/form';

export default class AddUsersBySpreadsheetModal extends Component {
    static get propTypes() {
        return {
            allUsers: PropTypes.instanceOf(Immutable.List).isRequired,
            eventId: PropTypes.string.isRequired,
            onHide: PropTypes.func.isRequired,
            show: PropTypes.bool.isRequired,
            userHitsAdded: PropTypes.number.isRequired,
            userTotalHitsMisses: PropTypes.number.isRequired,
            userBatchId: PropTypes.number.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.dummyInput = null;
        this.state = Object.assign({
            showUsersNotFoundModal: false
        });

        this.setDummyInputRef = this.setDummyInputRef.bind(this);
        this.handleClickDummyInput = this.handleClickDummyInput.bind(this);
        this.handleSelectSpreadsheet = this.handleSelectSpreadsheet.bind(this);
        this.handleToggleShowUsersNotFoundModal = this.handleToggleShowUsersNotFoundModal.bind(this);
    }

    setDummyInputRef(element) {
        this.dummyInput = element;
    }

    handleClickDummyInput() {
        if (this.dummyInput) {
            this.dummyInput.click();
        }
    }

    handleSelectSpreadsheet() {
        const spreadsheet = this.dummyInput.files[0];
        if (spreadsheet) {
            EventActions.selectUsersFromSpreadsheet(spreadsheet, this.props.eventId, this.props.allUsers).catch((err) => {
                this.handleToggleShowUsersNotFoundModal();
                throw err;
            });
            this.props.onHide();
        }
    }

    handleToggleShowUsersNotFoundModal() {
        this.setState((prevState) => ({
            showUsersNotFoundModal: !prevState.showUsersNotFoundModal
        }));
    }

    render() {
        const dummyInput = <input type="file" ref={this.setDummyInputRef} style={{display: 'none'}} onChange={this.handleSelectSpreadsheet}/>;

        return (
            <div>
                <UsersNotFoundModal
                    onHide={this.handleToggleShowUsersNotFoundModal}
                    show={this.state.showUsersNotFoundModal}
                    userHitsAdded={this.props.userHitsAdded}
                    userTotalHitsMisses={this.props.userTotalHitsMisses}
                    userBatchId={this.props.userBatchId}
                />
                <Modal onHide={this.props.onHide} show={this.props.show}>
                    <Modal.Header className="bg-gray" closeButton>
                        <Modal.Title className="text-center">{this.context.intl.messages['events.users.select-spreadsheet.modal.title']}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <FormSection>
                            <FormRow>
                                <FormGroup className="text-center">
                                    {dummyInput}
                                    <p>{this.context.intl.messages['events.users.select-spreadsheet.modal.caption']}</p>
                                    <Button
                                        className="btn btn-primary-outline bg-wb-blue btn-md"
                                        onClick={this.handleClickDummyInput}
                                    >
                                        <i className="fas fa-upload"></i>&nbsp;{this.context.intl.messages['events.users.select-spreadsheet.modal.button']}
                                    </Button>
                                </FormGroup>
                            </FormRow>
                        </FormSection>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button className="btn btn-default-outline pull-left" onClick={this.props.onHide}>
                            <i className="fas fa-ban"></i>&nbsp;{this.context.intl.messages['common.cancel']}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
