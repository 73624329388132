/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';

import OrientationCueBlock from './orientation-cue-block';
import {ApplyChangesToSelectedCues, HideCaptionPositioningModal, IsCaptionPositioningModalVisible$, SelectedCues$} from '../../bl';

import WithRxSubscriptions from '~/src/decorators/with-rx-subscriptions';


type Props = {
    cues: ReadonlyArray<WBTVDCue>,
    show: boolean,
}

type State = {
    orientation: CueOrientation,
}

type Stats = Record<keyof CueOrientation, Record<CueOrientation[keyof CueOrientation], number>>;

const ORIENTATION_KEYS: ReadonlyArray<keyof CueOrientation> = ['align', 'line'];

export class CaptionPositioningModal extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            orientation: detectCommonOrientation(props.cues),
        };
    }

    public static getDerivedStateFromProps(props: Props) {
        return {
            orientation: detectCommonOrientation(props.cues)
        };
    }

    private handleOrientationChanged = (orientation: CueOrientation) => this.setState(() => ({orientation}));

    private handleDoneEvent = () => {
        ApplyChangesToSelectedCues(this.state.orientation);
        HideCaptionPositioningModal();
    };

    render() {
        return (
            <Modal show={this.props.show} onHide={HideCaptionPositioningModal} className="skin-dark">
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="text-center">
                        <FormattedMessage id="cc-editor.settings-modal.header" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center" ref="modalBodyRef">
                    <OrientationCueBlock
                        orientation={this.state.orientation}
                        onChange={this.handleOrientationChanged}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="btn btn-success-outline pull-left"
                        data-testid="confirm-btn"
                        onClick={this.handleDoneEvent}
                    >
                        <i className="far fa-check"/>
                        &nbsp;
                        <FormattedMessage id="common.done" />
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default WithRxSubscriptions(CaptionPositioningModal, {
    cues: SelectedCues$,
    show: IsCaptionPositioningModalVisible$
});

function detectCommonOrientation(cues: ReadonlyArray<WBTVDCue>): CueOrientation {
    const stats: Stats = cues.reduce((memo, cue) => {
        return ORIENTATION_KEYS.reduce((m, key) => detectStatsFor(m, cue, key), memo);
    }, {align: {}, line: {}} as Stats);

    return ORIENTATION_KEYS.reduce((memo, key) => ({
        ...memo,
        [key]: getStatsMode(stats, key)
    }), {} as CueOrientation);
}

function getStatsMode<K extends keyof CueOrientation>(stats: Stats, key: K): CueOrientation[K] {
    const mode = Object.entries(stats[key]).reduce((prev, current) => {
        if (prev[1] > current[1]) {
            return prev;
        }
        return current;
    });
    const val = parseInt(mode[0], 10);
    if (isNaN(val)) {
        return mode[0] as CueOrientation[K];
    }
    return val as CueOrientation[K];
}

function getIncreasedHit<K extends keyof CueOrientation>(stats: Stats, cue: CueOrientation, key: K): number {
    return (stats[key][cue[key]] || 0) + 1;
}

function detectStatsFor(stats: Stats, cue: WBTVDCue, key: keyof CueOrientation): Stats {
    return {
        ...stats,
        [key]: {
            ...stats[key],
            [cue[key]]: getIncreasedHit(stats, cue, key)
        }
    };
}
