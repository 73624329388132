/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {DashboardConstants} from './dashboard-actions';
import Dispatcher from '../dispatcher/dispatcher';
import SessionStore from '../user/session/session-store';

class DashboardStore extends ReduceStore {

    getSavedDashboardFilters() {
        return JSON.parse(localStorage.getItem(`${DashboardConstants.SAVED_DASHBOARD_FILTERS}${SessionStore.getState().getIn(['authUser', 'id'])}`));
    }

    getInitialState() {
        const applyLastWeekFilter = JSON.parse(localStorage.getItem('__applyLastWeekFilter')) || false;
        return Immutable.Map({
            applyLastWeekFilter: applyLastWeekFilter
        });
    }

    reduce(state, action) {
        switch (action.actionType) {
        case DashboardConstants.TOGGLE.LAST_WEEK_FILTER:
            state = state.set('applyLastWeekFilter', action.isChecked);
            localStorage.setItem('__applyLastWeekFilter', state.get('applyLastWeekFilter'));
            break;
        }
        return state;
    }
}

const dashboardStore = new DashboardStore(Dispatcher);

export default dashboardStore;
