/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';

import Dispatcher from '~/src/dispatcher/dispatcher';

type State = DeepImmutable<{
    cues: WBTVDCue[],
    duplicate: Maybe<UnsavedVideoWebVtt>,
    history: CCEditorCommit[],
    originCues: WBTVDCue[],
    loading: boolean,
    savingVTT: boolean,
}>;

class CCEditorStore extends ReduceStore<State, DispatchAction> {
    getInitialState(): State {
        return {
            cues: [],
            duplicate: null,
            history: [],
            originCues: [],
            loading: false,
            savingVTT: false,
        };
    }

    reduce(state: State, action: CCEditorAction) {
        switch (action.actionType) {
        case 'hardac.cc-editor.change-cues':
            state = {...state, cues: action.cues};
            break;
        case 'hardac.cc-editor.commit':
            const history = [...state.history, action.payload];
            state = {...state, history};
            break;
        case 'hardac.cc-editor.create-duplicate':
            state = {...state, duplicate: action.vtt};
            break;
        case 'hardac.cc-editor.clear-duplicate':
            state = {...state, duplicate: null};
            break;
        case 'hardac.cc-editor.find-by-id.start':
            state = {...state, loading: true};
            break;
        case 'hardac.cc-editor.find-by-id.error':
        case 'hardac.cc-editor.find-by-id.success':
            state = {...state, loading: false};
            break;
        case 'hardac.cc-editor.save-vtt.start':
            state = {...state, savingVTT: true};
            break;
        case 'hardac.cc-editor.save-vtt.success':
        case 'hardac.cc-editor.save-vtt.error':
            state = {...state, savingVTT: false};
            break;
        case 'hardac.cc-editor.init-cues':
            state = {...state, cues: action.cues, originCues: action.cues};
            break;
        case 'hardac.cc-editor.update-duplicate':
            const duplicate = state.duplicate;
            if (duplicate) {
                state = {...state, duplicate: {...duplicate, [action.prop]: action.value}};
            }
            break;
        }

        return state;
    }
}

export default new CCEditorStore(Dispatcher);
