/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';
import {IndexRoute, Route} from 'react-router';

import Browse from './browse/browse';
import Create from './create/create';
import Assets from '../assets-tab/assets';
import WithPermissions from '../decorators/with-permissions';
import {RequirePerm} from '../router/router-helpers';
import Summary from './create/summary/summary';
import Titles from './create/titles/titles';
import {History} from '../common/side-notes/side-notes';
import SessionStore from '../user/session/session-store';

let Talent = <Route path="talent">
    <IndexRoute
        component={WithPermissions(Browse)}
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.TALENT.BROWSE)}
    />

    <Route
        component={WithPermissions(Create)}
        mode="create"
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.TALENT.CREATE)}
        path="create"
    >
        <IndexRoute component={Summary}/>
    </Route>

    <Route
        component={WithPermissions(Create)}
        mode="edit"
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.TALENT.READ)}
        path=":id"
    >
        <IndexRoute component={Summary}/>
        <Route path="assets" component={Assets}/>
        <Route path="history" component={History}/>
        <Route path="summary" component={Summary}/>
        <Route path="titles" component={Titles}/>
    </Route>
</Route>;

export default Talent;
