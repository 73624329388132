/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';

import {CompanyActions} from './company-actions';
import CompanyStore from './company-store';
import BaseSelect from '../../common/base-select/base-select';

export default createReactClass({
    propTypes: {
        components: PropTypes.object
    },

    getDefaultProps: function() {
        return {
            components: {}
        };
    },
    getter: function() {
        if (CompanyStore.isEmpty()) {
            CompanyActions.get(0, 9999);
        }
    },

    render: function() {
        return (
            <div style={{width: '100%'}}>
                <BaseSelect
                    components={this.props.components}
                    {...this.props}
                    getter={this.getter}
                    store={CompanyStore}
                />
            </div>
        );
    }
});
