/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Col, Modal, Row} from 'react-bootstrap';

import {NotificationActions} from '../notification-actions';
import NotificationStore from '../notification-store';

class MFAEnrollmentEmailModal extends React.Component {
    static get propTypes() {
        return {
            email: PropTypes.string
        };
    }

    static get defaultProps() {
        return {
            email: undefined
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            show: NotificationStore.getState().get('mfaEnrollmentEmailModal')
        };
    }

    static getStores() {
        return [NotificationStore];
    }

    handleDismiss() {
        NotificationActions.hideModal('mfaEnrollmentEmailModal');
        return;
    }

    render() {
        return (
            <Modal show={this.state.show}>
                <Modal.Header className="bg-wb-blue">
                    <Modal.Title className="text-center">{this.context.intl.messages['player.mfa.enrollment.modal.title']}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <img src={require('./mfa-enrollment-email.png')}/>
                    <br />
                    <br />
                    <p dangerouslySetInnerHTML={{
                        __html: this.context.intl.messages['player.mfa.enrollment.modal.body'].replace('%s', this.props.email)
                    }}/>
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col md={4} mdOffset={4}>
                            <Button
                                block
                                bsSize="large"
                                bsStyle="primary"
                                onClick={this.handleDismiss}
                            >
                                {this.context.intl.messages['common.ok']}
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default Container.create(MFAEnrollmentEmailModal);
