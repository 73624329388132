/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';
import {Route} from 'react-router';

import Help from './create';
import {HelpPagesConstants} from './help-page-actions';
import {RequirePerm} from '../router/router-helpers';

import WithPermissions from '~/src/decorators/with-permissions';
import SessionStore from '~/src/user/session/session-store';

const BuildHelpPageRoutes = (helpPage) => {
    const path = `${helpPage}/help`;

    return [
        <Route
            component={WithPermissions(Help)}
            helpPage={helpPage}
            key={`route-key-${helpPage}-view`}
            mode="view"
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.HELP_PAGES.VIEW)}
            path={path}>
            <Route path="history"/>
        </Route>,
        <Route
            component={WithPermissions(Help)}
            helpPage={helpPage}
            key={`route-key-${helpPage}-edit`}
            mode="edit"
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.HELP_PAGES.EDIT)}
            path={`${path}/edit`}>
            <Route path="history"/>
        </Route>
    ];
};

export default (
    <Route path="/">
        {HelpPagesConstants.ALL_HELP_PAGES.map(page => BuildHelpPageRoutes(page))}
    </Route>
);

export {BuildHelpPageRoutes};
