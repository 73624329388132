/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import ListItemTable from './table';
import {PublishingListActions} from '../../publishing-list-actions';
import PublishingListStore from '../../publishing-list-store';

class ListItems extends Component {

    static get propTypes() {
        return {
            currentLanguage: PropTypes.string.isRequired,
            displayLinks: PropTypes.bool,
            location: PropTypes.object.isRequired,
            publishingListId: PropTypes.number.isRequired,
            publishingListLocalized: PropTypes.instanceOf(Immutable.Map).isRequired,
            publishingListName: PropTypes.string.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            itemsOffset: PublishingListStore.getState().get('itemsOffset'),
            itemsSize: PublishingListStore.getState().get('itemsSize'),
            originalListItems: PublishingListStore.getState().get('originalListItems'),
            listItems: PublishingListStore.getState().get('listItems')
        };
    }

    static getStores() {
        return [PublishingListStore];
    }

    static get defaultProps() {
        return {
            disabled: true,
            displayLinks: true
        };
    }

    constructor(props) {
        super(props);

    }

    componentDidMount() {
        if (!this.state.listItems.size && !this.state.originalListItems.size) {
            PublishingListActions.getItems(this.props.publishingListId);
        }
        return;
    }

    render() {
        return (
            <div>
                <div>
                    <ListItemTable
                        currentLanguage={this.props.currentLanguage}
                        displayLinks={this.props.displayLinks}
                        editName={this.props.publishingListName}
                        listItems={this.state.listItems}
                        location={this.props.location}
                        offset={this.state.itemsOffset}
                        size={this.state.itemsSize}
                        publishingListId={this.props.publishingListId}
                        publishingListLocalized={this.props.publishingListLocalized}
                    />
                </div>
            </div>
        );
    }
}

export default Container.create(ListItems);
