/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {TimeFormat} from '@accurate-player/accurate-player-core';
import ClassNames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Modal, ResponsiveEmbed} from 'react-bootstrap';

import {VideoActions} from '../video/video-actions';
import VideoStore from '../video/video-store';

import {GetThumbnail} from '~/src/common/utils/utils';
import Player from '~/src/player/accurate-player';


export default class ThumbnailSelectionModal extends React.Component {
    static get propTypes() {
        return {
            asset: PropTypes.instanceOf(Immutable.Map).isRequired,
            canVideoPlayback: PropTypes.bool,
            onSelectImage: PropTypes.func.isRequired,
            onShowPlayer: PropTypes.func.isRequired,
            onSelectThumbnail: PropTypes.func.isRequired,
            show: PropTypes.bool,
            showPlayer: PropTypes.bool,
            thumbnails: PropTypes.instanceOf(Immutable.List).isRequired,
            toggle: PropTypes.func.isRequired,
            viewingMode: PropTypes.string.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            canVideoPlayback: false,
            show: false,
            showPlayer: false,
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            player: undefined
        };

        this.handleClickDummyInput = this.handleClickDummyInput.bind(this);
        this.handleSelectFrame = this.handleSelectFrame.bind(this);
        this.handleSelectPosterImage = this.handleSelectPosterImage.bind(this);
        this.handleShowPlayer = this.handleShowPlayer.bind(this);
        this.handleUpdateStatus = this.handleUpdateStatus.bind(this);
        this.setDummyInputRef = this.setDummyInputRef.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.player !== this.state.player && this.state.player) {
            const updateThumbnails = () => {
                const duration = this.state.player.api.getDuration(TimeFormat.Frame);
                VideoActions.updateThumbnailsStartTime(duration, this.props.thumbnails.toJS());
            };
            // load video duration time and update thumbnails with it
            this.state.player.on('playerLoaded', updateThumbnails.bind(this));
        }
    }

    handleSelectFrame() {
        const currentFrames = this.state.player.api.getCurrentTime(TimeFormat.Frame);
        this.props.onSelectThumbnail(currentFrames);
    }

    handleUpdateStatus(player) {
        this.setState({
            player
        });
    }

    handleShowPlayer() {
        this.props.onShowPlayer();
    }

    setDummyInputRef(element) {
        this.dummyInput = element;
    }

    handleClickDummyInput() {
        if (this.dummyInput) {
            this.dummyInput.click();
        }
    }

    handleSelectPosterImage(e) {
        this.props.onSelectImage(e);
        this.props.toggle();
    }

    render() {
        let assetRenderer;
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        let thumbnailUrl = require('../../layout/placeholder-horizontal.svg');
        let thumbnail = GetThumbnail(this.props.asset?.get('thumbnails'), 800);
        if (thumbnail) {
            thumbnailUrl = thumbnail.get('thumbnailUrl');
        }

        let playIcon;
        if (this.props.canVideoPlayback) {
            playIcon = (
                <span
                    className="thumbnail-play-icon glyphicon glyphicon-play-circle"
                    onClick={this.handleShowPlayer}
                ></span>
            );
        }

        assetRenderer = (
            <ResponsiveEmbed a16by9>
                <div>
                    <img
                        className="img-thumbnail"
                        src={thumbnailUrl}
                    />
                    {playIcon}
                </div>
            </ResponsiveEmbed>
        );
        if (this.props.showPlayer) {
            assetRenderer = (
                <ResponsiveEmbed a16by9>
                    <Player
                        position="static"
                        onUpdatePlayer={this.handleUpdateStatus}
                        src={VideoStore.convertVideoAssetToPlayerSource(this.props.asset)}
                        video={this.props.asset}
                        showThumbnails
                    />
                </ResponsiveEmbed>
            );
        }

        const dummyInput = <input type="file" ref={this.setDummyInputRef} style={{display: 'none'}} onChange={this.handleSelectPosterImage}/>;

        return (
            <Modal
                dialogClassName="modal-900w"
                className={ClassNames({'skin-dark': this.props.viewingMode === 'skin-dark'})}
                show={this.props.show}
                onHide={this.props.toggle}
            >
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="text-center">{this.context.intl.messages['asset.video.thumbnail.select-title']}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    {assetRenderer}
                    <div className="row padding-top-20">
                        <Button className="btn btn-success-outline" disabled={!this.props.showPlayer} onClick={this.handleSelectFrame}>
                            <i className="fas fa-check-square"></i>&nbsp;{this.context.intl.messages['asset.video.thumbnail.select-thumbnail']}
                        </Button>
                        <hr/>
                        <div className="row">
                            <h4>{this.context.intl.messages['asset.video.thumbnail.select-image']}</h4>
                            {dummyInput}
                            <Button
                                className="btn btn-primary-outline"
                                onClick={this.handleClickDummyInput}
                            >
                                <i className="fas fa-upload"></i>&nbsp;{this.context.intl.messages['asset.video.thumbnail.select-image']}
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-default-outline Ml(5)" onClick={this.props.toggle}>
                        <i className="fas fa-ban"></i>&nbsp;{this.context.intl.messages['common.close']}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
