/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import History from 'history'; // eslint-disable-line import/default
import React from 'react';

import {HasChangedCue$} from './bl';

import VideoStore from '~/src/assets/video/video-store';
import {RouterActions} from '~/src/router/router-actions';

type Props = {
    params: CCEditorRouteParams
}

export default class NavigationGuard extends React.PureComponent<Props> {

    constructor(props: Props) {
        super(props);
        this.isDirty = this.isDirty.bind(this);
    }

    componentDidMount() {
        RouterActions.registerRedirectCheck(this.isDirty);
    }

    componentDidUpdate() {
        RouterActions.registerRedirectCheck(this.isDirty);
    }

    componentWillUnmount() {
        RouterActions.clearRedirectChecks();
    }

    isDirty(location?: Pick<History.Location, 'pathname'>) {
        if (location) {
            const params = this.props.params;
            const goingTo = location.pathname.split('/');
            if (goingTo[4] === params.assetId && goingTo[6] === params.vttId) {
                return false; // just changing tabs no confirmation needed.
            }
        }

        let isDirty = false;
        isDirty = isDirty || HasChangedCue$.value;
        isDirty = isDirty || isChangedVttFile();
        return isDirty;
    }

    render() {
        return null;
    }
}

function isChangedVttFile() {
    const store = VideoStore.getState();
    return !store.get('originalWebVTTFiles').equals(store.get('webVTTFiles'));
}
