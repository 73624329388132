/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {JobConstants} from './job-actions';
import Dispatcher from '../../dispatcher/dispatcher';

import SessionStore from '~/src/user/session/session-store';

const findInConstant = (constants, objectId) => {
    return Object.keys(constants)
        .map(k => constants[k])
        .filter(obj => objectId === obj.id)[0];
};

class JobStore extends ReduceStore {

    getStatusType(statusTypeId) {
        return findInConstant(JobConstants.STATUS_TYPE, statusTypeId);
    }

    getInitialState() {
        const autoRefreshList = JSON.parse(localStorage.getItem('__autoRefreshJobs') || '[]');
        const userId = SessionStore.getUserId();
        const autoRefreshFromUser = autoRefreshList.find(item => item.userId === userId);
        return Immutable.Map({
            autoRefresh: Immutable.Map(autoRefreshFromUser),
            autoRefreshObservable: null,
            filters: Immutable.Map(),
            jobs: Immutable.List(),
            offset: 0,
            showPreloader: false,
            size: 0,
            total: 0,
        });
    }

    reduce(state, action) {
        switch (action.actionType) {
        case JobConstants.CLEAR:
            state = this.getInitialState();
            break;

        case JobConstants.JOB.GET.ERROR:
            state = state.set('showPreloader', false);
            break;

        case JobConstants.JOB.GET.SUCCESS:
            state = state.merge({
                offset: action.offset,
                jobs: action.jobs,
                showPreloader: false,
                size: action.size,
                total: action.total
            });
            break;

        case JobConstants.JOB.GET.START:
            state = state.set('showPreloader', true);
            break;

        case JobConstants.FILTER.SET:
            state = state.setIn(['filters', ...action.attr.split('.')], action.value);
            break;

        case JobConstants.JOB.SELECT_ALL:
            state = state.update('jobs', jobs => jobs.map(j => {
                if (action.ids.includes(j.get('processId'))) {
                    j = j.set('selected', action.selected);
                }
                return j;
            }));
            break;

        case JobConstants.JOB.UPDATE_JOB:
            const index = state.get('jobs').findIndex(j => j.get('processId') === action.id);

            state = state.setIn(['jobs', index, action.attr], action.value);
            break;

        case JobConstants.FILTERS.SET_AUTO_REFRESH:
            const userId = action.userId;

            state = state.set('autoRefresh', Immutable.Map({
                isChecked: action.isChecked,
                userId
            }));

            let autoRefreshList = JSON.parse(localStorage.getItem('__autoRefreshJobs') || '[]');
            const currentUserIndex = autoRefreshList.findIndex(item => item.userId === userId);
            if (currentUserIndex === -1) {
                autoRefreshList.push({
                    isChecked: action.isChecked,
                    userId
                });
            } else {
                autoRefreshList[currentUserIndex].isChecked = action.isChecked;
            }
            localStorage.setItem('__autoRefreshJobs', JSON.stringify(autoRefreshList));
            break;

        case JobConstants.FILTERS.SET_AUTO_REFRESH_OBSERVABLE:
            state = state.set('autoRefreshObservable', action.autoRefreshObs);
            break;
        }

        return state;
    }
}

const store = new JobStore(Dispatcher);

export default store;
