/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React from 'react';
import {Button} from 'react-bootstrap';
import {Link} from 'react-router';
import ResizeObserverPolyfill from 'resize-observer-polyfill';

import Clips from './clips';
import EditClipPanel from './clips/edit-clip-panel';
import HotkeysInformationModal from './hotkeys-information';
import Processes from './processes-table/';
import Summary from './summary';
import {TimelineActions, TimelineConstants} from './timeline-actions';
import VideoTimeline from './timeline-component';
import TimelineStore from './timeline-store';
import DocumentTitle from '../../common/document-title';
import ResizablePanels from '../../common/resizable-panel';
import {Tab, Tabs} from '../../common/routing-tab/routing-tab';
import {History} from '../../common/side-notes/side-notes';
import WithPermissions from '../../decorators/with-permissions';
import LayoutStore from '../../layout/layout-store';
import AccuratePlayer from '../../player/accurate-player';
import Preloader from '../../preloader';
import {RouterActions} from '../../router/router-actions';
import {ActionHistoryConstants} from '../../system/action-history/action-history-actions';
import SessionStore from '../../user/session/session-store';
import AudioProfileTable from '../audio-profile-table';
import {VideoDeliveryActions} from '../video-delivery/video-delivery-actions';
import VideoDeliveryStore from '../video-delivery/video-delivery-store';

import './timeline.less';

class Timeline extends React.Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            params: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            permissions: {}
        };
    }

    /*istanbul ignore next*/
    static getPermissions() {
        return {
            canViewMediaInfo: SessionStore.canUser(SessionStore.PERMISSIONS.HARDAC.TIMELINE.VIEW_MEDIA_INFO),
            canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.HARDAC.TIMELINE.EDIT),
            canPublish: SessionStore.canUser(SessionStore.PERMISSIONS.HARDAC.TIMELINE.PUBLISH),
            canRead: SessionStore.canUser(SessionStore.PERMISSIONS.HARDAC.TIMELINE.READ),
            canReadCatalogs: SessionStore.canUser(SessionStore.PERMISSIONS.CATALOGS.ASSET.READ),
            canReadProcesses: SessionStore.canUser(SessionStore.PERMISSIONS.HARDAC.PROCESSES.READ),
            canReadTalent: SessionStore.canUser(SessionStore.PERMISSIONS.TALENT.READ),
            canReadTitles: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.READ),
            canReadUsers: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.READ)
        };
    }

    static calculateState() {
        const ts = TimelineStore.getState();
        const vds = VideoDeliveryStore.getState();
        return {
            audioProfile: ts.get('audioProfile'),
            clips: ts.get('clips'),
            oapShowCodes: vds.get('oapShowCodes'),
            originalClips: ts.get('originalClips'),
            originalSelectedClip: ts.get('originalSelectedClip'),
            originalTimeline: ts.get('originalTimeline'),
            player: ts.get('player'),
            processes: ts.get('processes'),
            publishingInfo: ts.get('publishingInfo'),
            selectedClip: ts.get('selectedClip'),
            selectedDeliveryType: TimelineStore.getDeliveryType(ts.getIn(['selectedClip', 'deliveryType'])),
            showEditClipPanel: ts.get('showEditClipPanel'),
            showHotkeysModal: ts.get('showHotkeysModal'),
            showPreloader: ts.get('showPreloader'),
            sources: ts.get('sources'),
            thumbnails: ts.get('thumbnails'),
            timeline: ts.get('timeline'),
            viewingMode: LayoutStore.getState().get('hardacAppTheme')
        };
    }
    /*istanbul ignore next*/
    static getStores() {
        return [LayoutStore, TimelineStore, VideoDeliveryStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.isDirty = this.isDirty.bind(this);
        this.observeRefInfoTabs = this.observeRefInfoTabs.bind(this);
        this.setTabContentSize = this.setTabContentSize.bind(this);
        this.unobserveRefInfoTabs = this.unobserveRefInfoTabs.bind(this);
    }

    componentDidMount() {
        TimelineActions.findById(this.props.params.id);
        TimelineActions.getPublishingInfo(this.props.params.id);
        VideoDeliveryActions.getOapShowCodes();

        this.observeRefInfoTabs();

        RouterActions.registerRedirectCheck(/*istanbul ignore next*/(location) => {
            return this.isDirty(location);
        });
    }

    componentWillReceiveProps() {
        RouterActions.clearRedirectChecks();
        RouterActions.registerRedirectCheck(/*istanbul ignore next*/(location) => {
            return this.isDirty(location);
        });
    }

    componentWillUnmount() {
        TimelineActions.clear();

        this.unobserveRefInfoTabs();
    }

    handleCancel() {
        RouterActions.redirect('/hardac/timelines', true);
    }

    handleSave() {
        // If the edit clip panel is visible, then save the current clip
        // so that user don't loose any data.
        if (this.state.showEditClipPanel) {
            TimelineActions.addUpdateClipToList(this.state.selectedClip, 'unpublished');
        }

        TimelineActions.save(
            this.props.params.id,
            this.state.timeline,
            this.state.audioProfile,
            // Why not the this.state.clips? Because calculateState hasn't run yet.
            // If the previous if was true, then this.state.clips is out of sync with old data.
            TimelineStore.getState().get('clips'),
            this.state.originalClips
        );
    }

    handleUpdatePlayer(player) {
        TimelineActions.update('player', player);
    }

    /*istanbul ignore next*/
    isDirty(nextLocation) {
        if (nextLocation) {
            //if just changing tabs no confirmation needed.
            let goingTo = nextLocation.pathname.split('/');
            let current = this.props.location.pathname.split('/');
            if (goingTo[1] === current[1] && goingTo[2] === current[2]) {
                return false;
            }
        }
        return !this.state.timeline.equals(this.state.originalTimeline) ||
            !this.state.clips.equals(this.state.originalClips) ||
            !this.state.selectedClip.equals(this.state.originalSelectedClip);
    }

    /**
     * Observes infoTabs header when it resizes and tabs goes down, then we need to resize tab-content
     */
    /*istanbul ignore next*/
    observeRefInfoTabs() {
        const infoTabsRef = this.infoTabs;
        const resizeObserver = new ResizeObserverPolyfill(entries => {
            this.setTabContentSize(entries[0]);
        });
        resizeObserver.observe(infoTabsRef);

        //save in store in order to unobserve later
        this.setState(() => ({
            resizeObserver
        }));
    }

    /**
     * Update tabNavHeight in order to set tab-content height
     * @param {object} element tabs nav element
     */
    setTabContentSize(element) {
        const {height} = element.contentRect;
        this.setState(() => ({
            tabNavHeight: height,
        }));
    }

    unobserveRefInfoTabs() {
        const {resizeObserver} = this.state;
        resizeObserver.unobserve(this.infoTabs);
    }

    render() {
        let ap;
        let hotkeys = [];
        let videoTimeline;
        const assetName = this.state.timeline.get('assetName');
        const baseRoute = `/hardac/timeline/${this.props.params.id}`;
        const storageStatusType = this.state.timeline.getIn(['sourceFile', 'storageStatusType']);
        const allClips = this.state.clips.get('completed')
            .concat(this.state.clips.get('failed'))
            .concat(this.state.clips.get('processing'))
            .concat(this.state.clips.get('unpublished'));

        /*istanbul ignore next*/
        const canPublishAll = this.state.clips.equals(this.state.originalClips) && this.props.permissions.canPublish && this.props.permissions.canEdit;
        const hasUnpublishedClips = !!this.state.clips.get('unpublished').size;

        /*istanbul ignore if*/
        if (this.state.player) {
            const plugins = this.state.player.api.plugin;
            hotkeys = [...plugins.apHotkeyPlugin.configurations.values(), ...plugins.apPointPlugin.actions];
        }

        const hotkeysModal = (
            <HotkeysInformationModal
                hotkeysList={hotkeys}
                onHide={TimelineActions.toggleHotkeysModal}
                show={this.state.showHotkeysModal}
                viewingMode={this.state.viewingMode}
            />
        );

        //tabs
        const audioTab = <Tab route={`${baseRoute}/audio`} title={this.context.intl.messages['hardac.timeline.tab-title.audio']}>
            <AudioProfileTable
                audioProfile={this.state.audioProfile}
                isTab={true}
                readOnly={true}
                showCount={false}
            />
        </Tab>;

        const clipsTab = <Tab route={`${baseRoute}/clips`} title={this.context.intl.messages['hardac.timeline.tab-title.clips']}>
            <Clips
                canEdit={this.props.permissions.canEdit}
                canPublish={this.props.permissions.canPublish}
                canPublishAll={canPublishAll}
                clips={this.state.clips}
                hasUnpublishedClips={hasUnpublishedClips}
                originalClips={this.state.originalClips}
                player={this.state.player}
                sources={this.state.sources}
                timeline={this.state.timeline}
                thumbnails={this.state.thumbnails}
                viewingMode={this.state.viewingMode}
            />
        </Tab>;
        let processesTab;
        const canReadProcesses = this.props.permissions.canReadProcesses;
        /*istanbul ignore next*/
        if (canReadProcesses) {
            processesTab =
                <Tab route={`${baseRoute}/processes`} title={this.context.intl.messages['common.tab-title.processes']} tabClassName="pull-right">
                    <h3>{this.context.intl.messages['hardac.processes.title']} <Link
                        target="_blank"
                        className="small pull-right"
                        title=""
                        to={`/hardac/processes/v3/timeline/${this.props.params.id}/status`}>
                        {this.context.intl.messages['hardac.timeline.processes.check-status']}
                    </Link>
                    </h3>
                    <Processes
                        id={this.props.params.id}
                        processes={this.state.processes}
                    />
                </Tab>;
        }
        const historyTab = <Tab route={`${baseRoute}/history`} title={this.context.intl.messages['common.tab-title.history']} tabClassName="pull-right">
            <History
                actionObject={ActionHistoryConstants.ACTION_OBJECTS.VIDEOTIMELINE}
                id={this.props.params.id}
                pagination
                pathname={this.props.location.pathname}
                query={this.props.location.query}
            />
        </Tab>;
        const summaryTab = <Tab route={baseRoute} title={this.context.intl.messages['common.tab-title.summary']}>
            <Summary
                canViewMediaInfo={this.props.permissions.canViewMediaInfo}
                clips={allClips.size || 0}
                disabled={!this.props.permissions.canEdit}
                oapShowCodes={this.state.oapShowCodes}
                originalTimeline={this.state.originalTimeline}
                publishingInfo={this.state.publishingInfo}
                timeline={this.state.timeline}
                tracks={this.state.audioProfile.size}
                viewingMode={this.state.viewingMode}
            />
        </Tab>;

        /*istanbul ignore next*/
        if (!this.state.timeline.get('sourceFile')) {
            ap =
                <h4 className="timeline-placeholder">{this.context.intl.messages['hardac.timeline.source.not-available']}</h4>;
        } else if (!this.state.timeline.get('proxyFile')) {
            ap =
                <h4 className="timeline-placeholder">{this.context.intl.messages['hardac.timeline.proxy.not-available']}</h4>;
        } else if (storageStatusType !== TimelineConstants.TIMELINE.STORAGE_STATUS_TYPE.AVAILABLE) {
            ap =
                <h4 className="timeline-placeholder">{this.context.intl.messages['hardac.timeline.source.storage.not-available'].replace('{}', storageStatusType)}</h4>;
        } else if (this.state.sources) {
            if (!this.state.detached && this.state.sources.size > 0) {
                ap = (
                    <AccuratePlayer
                        onUpdatePlayer={this.handleUpdatePlayer}
                        showThumbnails={false}
                        src={this.state.sources.get(this.props.params.id.toString())}
                        video={this.state.timeline}
                    />
                );
            }
            videoTimeline = (
                <VideoTimeline
                    audioProfile={this.state.audioProfile}
                    canEdit={this.props.permissions.canEdit}
                    clips={this.state.clips}
                    player={this.state.player}
                    thumbnails={this.state.thumbnails}
                    timeline={this.state.timeline}
                    viewingMode={this.state.viewingMode}
                />
            );
        } else {
            ap =
                <h4 className="timeline-placeholder">{this.context.intl.messages['hardac.timeline.source.retrieve-in-progress']}</h4>;
        }

        const validations = TimelineStore.getTimelineValidations();

        return (
            <DocumentTitle
                message="hardac.timeline.title"
            >
                <Preloader show={this.state.showPreloader} fixed loadingDots>
                    <section className="hardac-content">
                        <ResizablePanels
                            displayDirection="column"
                            width="100%"
                            height="94vh"
                            panelsSize={[62, 38]}
                            panelsMinSize={[0, 0]}
                            panelsMaxSize={[100, 100]}
                            sizeUnitMeasure="%"
                            resizerColor="#161616"
                            resizerSize="10px"
                        >
                            <div style={{height: '100%'}}>
                                <ResizablePanels
                                    displayDirection="row"
                                    width="100%"
                                    height="inherit"
                                    panelsSize={[60, 40]}
                                    panelsMinSize={[33, 33]}
                                    panelsMaxSize={[66, 66]}
                                    sizeUnitMeasure="%"
                                    resizerColor="#161616"
                                    resizerSize="10px"
                                >
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        height: '100%',
                                        paddingTop: '8px'
                                    }}>
                                        {ap}
                                    </div>
                                    <div className="info-detail-container">
                                        <div className="content-header padding-top-20 padding-x-20">
                                            <h1>
                                                <i className="fas fa-stream"></i>&nbsp;{this.context.intl.messages['hardac.timeline.title']}
                                                <div className="pull-right">
                                                    <Button
                                                        bsSize="sm"
                                                        className="btn btn-success-outline Mr(3px) Mb(3px)"
                                                        disabled={!this.props.permissions.canEdit || validations.length}
                                                        onClick={this.handleSave}
                                                    >
                                                        <i className="fas fa-save"></i>&nbsp;{this.context.intl.messages['hardac.timeline.save']}
                                                    </Button>
                                                    <Button
                                                        bsSize="sm"
                                                        className="btn btn-danger-outline Mr(3px) Mb(3px)"
                                                        onClick={this.handleCancel}
                                                    >
                                                        <i className="fas fa-trash"></i>&nbsp;{this.context.intl.messages['common.cancel']}
                                                    </Button>
                                                </div>
                                                <br/><small>{assetName}</small>
                                            </h1>
                                        </div>
                                        <div className="hardac-clipping">
                                            <div className="info-container parent-content">
                                                <div
                                                    className={ClassNames({'left-content-hide': this.state.showEditClipPanel}, 'left-content')}>
                                                    <div className="padding-y-20 padding-x-20"
                                                        style={{height: `calc(100% - ${this.state.tabNavHeight}px)`}}
                                                        ref={ref => {
                                                            /*istanbul ignore next*/
                                                            if (ref) {
                                                                this.infoTabs = ref.querySelector('.nav');
                                                            }
                                                        }}>
                                                        <Tabs location={this.props.location}>
                                                            {summaryTab}
                                                            {audioTab}
                                                            {clipsTab}
                                                            {historyTab}
                                                            {processesTab}
                                                        </Tabs>
                                                    </div>
                                                </div>
                                                <div
                                                    className={ClassNames({'right-content-show': this.state.showEditClipPanel}, 'right-content')}>
                                                    <div className="clip-info padding-y-20 padding-x-20">
                                                        <EditClipPanel
                                                            canEdit={this.props.permissions.canEdit}
                                                            canPublishAll={canPublishAll}
                                                            clip={this.state.selectedClip}
                                                            hasUnpublishedClips={hasUnpublishedClips}
                                                            location={this.props.location}
                                                            player={this.state.player}
                                                            selectedDeliveryType={this.state.selectedDeliveryType}
                                                            sources={this.state.sources}
                                                            timeline={this.state.timeline}
                                                            thumbnails={this.state.thumbnails}
                                                            viewingMode={this.state.viewingMode}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ResizablePanels>
                            </div>
                            <div className="padding-x-20" style={{overflow: 'auto', 'scrollbar-width': 'none'}}>
                                {videoTimeline}
                            </div>
                        </ResizablePanels>
                    </section>
                    {hotkeysModal}
                </Preloader>
            </DocumentTitle>
        );
    }
}

export default (WithPermissions(Container.create(Timeline)));
export {
    Timeline
};
