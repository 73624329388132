/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, ControlLabel, FormGroup, Modal} from 'react-bootstrap';
import Select from 'react-select';

import {Debounce} from '~/src/common/utils/utils';
import {UserActions} from '~/src/user/user-actions';
import UserStore from '~/src/user/user-store';

class CopyPermissionsModal extends React.Component {
    static get propTypes() {
        return {
            onHide: PropTypes.func.isRequired,
            show: PropTypes.bool,
        };
    }

    static get defaultProps() {
        return {
            show: false,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
        };
    }

    static calculateState() {
        return {
            user: UserStore.getState().get('user'),
            users: UserStore.getState().get('users'),
        };
    }

    static getStores() {
        return [UserStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            selectedUser: Immutable.Map(),
        }, this.constructor.calculateState());

        this.handleClose = this.handleClose.bind(this);
        this.handleSelectUser = this.handleSelectUser.bind(this);
        this.handleSearchUser = Debounce(this.handleSearchUser.bind(this), 200);
        this.handleConfirmOverwritePermissions = this.handleConfirmOverwritePermissions.bind(this);
    }

    handleClose() {
        this.setState(() => ({
            selectedUser: Immutable.fromJS({})
        }));
        UserActions.clearUsers();
        this.props.onHide();

    }

    /**
     * This is awful, but I don't know a better option.
     *
     * React-Select has a loadOptions prop that works with an object of type:
     * function(value, cb) {}
     * But since we are using Flux, having a cb is an anti-pattern. In our case,
     * each time the component re-renders we pass the options Array as a new prop.
     *
     * The problem is that React-Select doesn't refresh if we change the options
     * Array, it needs to close and reopen the dropdown in order to show the new
     * options. But if we pass a dummy filterOption method (i.e.: a method that
     * returns all the options), then it refreshes the view in a real-time/sync
     * fashion.
     */
    handleFilterOption(opt) {
        return opt;
    }

    handleConfirmOverwritePermissions() {
        UserActions.overwritePermissions(this.state.user, this.state.selectedUser);
        this.handleClose();
    }

    handleSearchUser(value, {action}) {
        if (action === 'menu-close') {
            return;
        }
        UserActions.getSuggestedUsers(value);
        return;
    }

    handleSelectUser(value) {
        if (value === null) {
            value = {};
            UserActions.clearUsers();
        }

        this.setState(() => ({
            selectedUser: Immutable.fromJS(value)
        }));
        return;
    }

    render() {
        let selectedUserValue = this.state.selectedUser.toJS();
        if (!selectedUserValue.id) {
            selectedUserValue = '';
        }

        return (
            <Modal show={this.props.show} onHide={this.handleClose}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="text-center">{this.context.intl.messages['accounts.edit.copy-permissions.title']}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {this.context.intl.messages['accounts.edit.copy-permissions.description']}
                    </p>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['accounts.edit.copy-permissions.select.label']}</ControlLabel>
                        <Select
                            getOptionLabel={data => data.email}
                            getOptionValue={data => data.id}
                            isClearable={true}
                            isMulti={false}
                            filterOption={this.handleFilterOption}
                            name="users"
                            onChange={this.handleSelectUser}
                            onInputChange={this.handleSearchUser}
                            options={this.state.users.toJS()}
                            placeholder={this.context.intl.messages['accounts.edit.copy-permissions.select.placeholder']}
                            value={selectedUserValue}
                        />
                    </FormGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="btn btn-default-outline Mr(3px) Mb(3px) pull-left"
                        onClick={this.handleClose}
                    >
                        <i className="fas fa-times"/>&nbsp;{this.context.intl.messages['common.cancel']}
                    </Button>
                    <Button
                        className="btn btn-primary-outline Mr(3px) Mb(3px)"
                        onClick={this.handleConfirmOverwritePermissions}
                        disabled={this.state.selectedUser.isEmpty()}
                    >
                        {this.context.intl.messages['common.confirm']}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default Container.create(CopyPermissionsModal);
