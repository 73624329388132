/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import QueryString from 'querystring-es3';
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

import AddUserPanel from './add-user-panel.js';
import Summary from './summary/summary';
import RouteValidator from '../../common/custom-validator/route/route';
import DocumentTitle from '../../common/document-title';
import {DownloadActions} from '../../common/download/download-actions';
import ListUsers from '../../common/list-users/list-users';
import {MODAL_TYPE} from '../../common/notification/modal';
import {NotificationActions} from '../../common/notification/notification-actions';
import {Tab, Tabs} from '../../common/routing-tab/routing-tab';
import {History, SideNotes} from '../../common/side-notes/side-notes';
import SlidingPanel from '../../common/sliding-panel/sliding-panel';
import {SlidingPanelActions} from '../../common/sliding-panel/sliding-panel-actions';
import {WithStoreOnRoute} from '../../common/store-on-route';
import {AlertsWarnings} from '../../common/validations/alerts-warnings';
import ActionsMenu from '../../layout/actions-menu/actions-menu';
import StickyHeader from '../../layout/sticky-header/sticky-header';
import Preloader from '../../preloader/';
import PreloaderStore from '../../preloader/preloader-store';
import {RouterActions} from '../../router/router-actions';
import {ClientRepGroupActions} from '../../security/client-rep-group/client-rep-group-actions';
import ClientRepGroupStore from '../../security/client-rep-group/client-rep-group-store';
import {ActionHistoryActions, ActionHistoryConstants} from '../../system/action-history/action-history-actions';
import SessionStore from '../../user/session/session-store';
import {UserActions} from '../../user/user-actions';

class Create extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            params: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired,
            route: RouteValidator // eslint-disable-line react/require-default-props
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            clientRepGroup: ClientRepGroupStore.getState().get('clientRepGroup'),
            originalClientRepGroup: ClientRepGroupStore.getState().get('originalClientRepGroup'),
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible'),
            selectAll: ClientRepGroupStore.getState().get('selectAll'),
            selectedUsers: ClientRepGroupStore.getState().get('selectedUsers'),
            totalPages: ClientRepGroupStore.getState().get('totalPages'),
            users: ClientRepGroupStore.getState().get('users')
        };
    }

    static getStores() {
        return [ClientRepGroupStore, PreloaderStore];
    }

    static getPermissions() {
        return {
            canCreate: SessionStore.canUser(SessionStore.PERMISSIONS.GROUPS.CLIENT_REP_GROUPS.CREATE),
            canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.GROUPS.CLIENT_REP_GROUPS.EDIT),
            users: {
                canRead: SessionStore.canUser(SessionStore.PERMISSIONS.GROUPS.CLIENT_REP_GROUPS.USERS.READ),
                canRemove: SessionStore.canUser(SessionStore.PERMISSIONS.GROUPS.CLIENT_REP_GROUPS.USERS.REMOVE),
                canAdd: SessionStore.canUser(SessionStore.PERMISSIONS.GROUPS.CLIENT_REP_GROUPS.USERS.ADD)
            }
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            activeTab: 0,
            sortDirection: 'asc',
            sortFieldName: 'name'
        }, this.constructor.calculateState());

        this.addNewUser = this.addNewUser.bind(this);
        this.addNote = this.addNote.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleDeleteClientRepGroup = this.handleDeleteClientRepGroup.bind(this);
        this.handleExportUsers = this.handleExportUsers.bind(this);
        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleRemoveSelectedUsers = this.handleRemoveSelectedUsers.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.isDirty = this.isDirty.bind(this);
        this.showDeleteDialog = this.showDeleteDialog.bind(this);
        this.showRemoveUsersDialog = this.showRemoveUsersDialog.bind(this);
    }

    componentDidMount() {
        this.initStores(this.props);
        return;
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.route.mode !== this.props.route.mode ||
            nextProps.params.id !== this.props.params.id) {
            ClientRepGroupActions.clear();
            UserActions.clear();

            this.initStores(nextProps);
        }
        /* istanbul ignore else  */
        if (this.props.location.query !== nextProps.location.query) {
            ClientRepGroupActions.toggleSelectAll(false);
            ClientRepGroupActions.findUsersByClientRepGroupId(
                this.props.params.id,
                nextProps.location.query['sort-field-name'],
                nextProps.location.query['sort-direction'],
                nextProps.location.query.offset,
                nextProps.location.query.size,
            );
        }
        return;
    }

    shouldComponentUpdate(nextProps) {
        if (this.props.location.query !== nextProps.location.query) {
            return true;
        }

        return false;
    }

    addNewUser() {
        SlidingPanelActions.show('add-user-to-client-rep');
    }

    addNote(description) {
        ActionHistoryActions.addNote(this.props.params.id, description, ActionHistoryConstants.ACTION_OBJECTS.CLIENTREPGROUP);
    }

    handleCancel() {
        RouterActions.redirect('/groups/client-rep-groups', true);
    }

    handleDeleteClientRepGroup() {
        ClientRepGroupActions.deleteClientRepGroup(ClientRepGroupStore.getState().get('clientRepGroup'));
        return;
    }

    handleExportUsers() {
        DownloadActions.startDownloadExecution('user/export', {
            'client-rep-group-id': this.props.params.id
        });
    }

    handleLoadPage(pageNumber) {
        this.setRouteState('offset', pageNumber * parseInt(this.getRouteState().get('size', 20)))
            .apply();
        return;
    }

    handleRemoveSelectedUsers() {
        ClientRepGroupActions.removeUsers(this.state.clientRepGroup, this.state.selectedUsers);
        return;
    }

    handleSave() {
        let options = {
            messages: {
                error: `groups.client-rep-group.${this.props.route.mode}.error`,
                success: `groups.client-rep-group.${this.props.route.mode}.success`
            }
        };

        ClientRepGroupActions.saveClientRepGroup(
            ClientRepGroupStore.getState().get('clientRepGroup'),
            options
        );
        return;
    }

    handleSortChange(sortFieldName, sortDirection) {
        this.setState({
            sortDirection: sortDirection,
            sortFieldName: sortFieldName
        });
        let query = Object.assign(
            {},
            this.props.location.query,
            {
                'sort-field-name': sortFieldName,
                'sort-direction': sortDirection,
                'keep-scroll': true
            }
        );
        RouterActions.redirect(`${this.props.location.pathname}?${QueryString.stringify(query)}`);
        return;
    }

    initStores(props) {
        switch (props.route.mode) {
        case 'create':
            ClientRepGroupActions.clear();
            break;
        case 'edit':
            ClientRepGroupActions.findById(props.params.id);
            ClientRepGroupActions.findUsersByClientRepGroupId(
                props.params.id,
                this.getRouteState().get('sort-field-name', 'name'),
                this.getRouteState().get('sort-direction', 'asc'),
                this.getRouteState().get('offset', 0),
                this.getRouteState().get('size', 20),
            );
            break;
        }
        /* istanbul ignore next */
        RouterActions.registerRedirectCheck(() => {
            return this.isDirty();
        });
        return;
    }

    isDirty() {
        if (this.props.route.mode === 'create') {
            return false;
        }
        return !this.state.clientRepGroup.equals(this.state.originalClientRepGroup);
    }

    showDeleteDialog(event) {
        event.preventDefault();
        NotificationActions.show(
            MODAL_TYPE.DANGER,
            this.context.intl.messages['groups.client-rep-group.edit.modal.title'],
            this.context.intl.messages['groups.client-rep-group.edit.modal.body'],
            this.context.intl.messages['groups.client-rep-group.edit.modal.confirm'],
            this.handleDeleteClientRepGroup
        );
    }

    showRemoveUsersDialog(event) {
        event.preventDefault();

        NotificationActions.show(
            MODAL_TYPE.DANGER,
            this.context.intl.messages['groups.user.remove-users.modal.title'],
            this.context.intl.messages['groups.user.remove-users.modal.body'],
            this.context.intl.messages['groups.user.remove-users.modal.confirm'],
            this.handleRemoveSelectedUsers
        );
    }

    render() {
        let actionsMenu;
        let disableForm = true;
        let mode = this.props.route.mode;
        let intlPrefix = `${mode}`;
        let title;
        let groupId = this.state.clientRepGroup.get('id');
        let groupName = this.state.clientRepGroup.get('name');
        let eventsAndNotes;
        let validations = ClientRepGroupStore.getValidations();
        let warnings = ClientRepGroupStore.getWarnings();
        let baseRoute = `/groups/client-rep-groups/${this.props.params.id}`;

        if (mode === 'create') {
            baseRoute = '/groups/client-rep-groups/create';
            /* istanbul ignore else */
            if (this.props.permissions.canCreate) {
                disableForm = false;
            }

            actionsMenu = <ActionsMenu
                canSave={!validations.length}
                onCancel={this.handleCancel}
                onSave={this.handleSave}
                saveText={this.context.intl.messages['groups.client-rep-group.create.save']}
            />;

            title = <em>{this.context.intl.messages['groups.client-rep-group.create.title']}</em>;
        }

        let historyTab;
        let usersTab;
        let addUserPanel;
        let addUserPanelTitle;
        if (mode === 'edit') {
            /* istanbul ignore else */
            if (this.props.permissions.canEdit) {
                disableForm = false;
            }

            actionsMenu = <ActionsMenu
                canDelete={!warnings.length && this.props.permissions.canEdit}
                canEdit={this.props.permissions.canEdit}
                canSave={!validations.length && this.props.permissions.canEdit}
                onCancel={this.handleCancel}
                onDelete={this.showDeleteDialog}
                onSave={this.handleSave}
            />;

            title = <em>{this.context.intl.messages['groups.client-rep-group.edit.title']}</em>;

            /* istanbul ignore if */
            if (this.props.permissions.users.canRead && this.state.users.get('results')) {
                const activePage = Math.ceil((this.getRouteState().get('offset') || 0) / 20) || 0;

                addUserPanelTitle = <span>Add User <small>{groupName}</small></span>;
                addUserPanel = <AddUserPanel groupId={groupId} slidingPanelId="add-user-to-client-rep"/>;
                usersTab = <Tab route={`${baseRoute}/users`} title={this.context.intl.messages['groups.users']}>
                    <ListUsers
                        activePage={activePage}
                        columns={['flag', 'name', 'company', 'email', 'status', 'applicationDate']}
                        displayLinks={this.props.permissions.users.canRead}
                        onPageChange={this.handleLoadPage}
                        onSelect={ClientRepGroupActions.toggleSelect}
                        onSelectAll={ClientRepGroupActions.toggleSelectAll}
                        onSortChange={this.handleSortChange}
                        selectAll={this.state.selectAll}
                        sortDirection={this.state.sortDirection}
                        sortFieldName={this.state.sortFieldName}
                        totalPages={this.state.totalPages}
                        users={this.state.users.get('results')}
                    >
                        <div className="bulk-actions">
                            <Button
                                bsStyle="primary"
                                disabled={!(this.props.permissions.users.canAdd)}
                                onClick={this.addNewUser}><i className="fas fa-plus-square"></i>&nbsp;{this.context.intl.messages['groups.user.add-users']}</Button>
                            <Button
                                bsStyle="danger"
                                disabled={!(this.props.permissions.users.canRemove && this.state.selectedUsers.size)}
                                onClick={this.showRemoveUsersDialog}><i className="fas fa-trash-alt"></i>&nbsp;{this.context.intl.messages['groups.user.remove-users']}</Button>
                            <Button
                                className="btn btn-default pull-right"
                                disabled={!this.state.users.size}
                                onClick={this.handleExportUsers}
                            >
                                <i className="fas fa-download"></i> {this.context.intl.messages['groups.user.export-users']}
                            </Button>
                        </div>
                    </ListUsers>
                </Tab>;

                eventsAndNotes = (
                    <SideNotes
                        basePath={`/groups/client-rep-groups/${this.props.params.id}`}
                        dialogMessage={this.context.intl.messages['common.side-notes.add-note.body']}
                        disabled={!this.props.permissions.canEdit}
                        notes={this.state.clientRepGroup.get('history').toJS().slice(0, 4)}
                        onAddNote={this.addNote}
                        title={this.context.intl.messages['common.side-notes.title']}
                    />
                );

                historyTab = <Tab route={`${baseRoute}/history`} title={this.context.intl.messages['common.tab-title.history']} tabClassName="pull-right">
                    <History
                        actionObject={ActionHistoryConstants.ACTION_OBJECTS.CLIENTREPGROUP}
                        id={this.props.params.id}
                        pagination
                        pathname={this.props.location.pathname}
                        query={this.props.location.query}/>
                </Tab>;
            }
        }

        let summaryTab = <Tab route={baseRoute} title={this.context.intl.messages['groups.summary']}>
            <Summary mode={mode} clientRepGroup={this.state.clientRepGroup} disabled={disableForm}/>
        </Tab>;

        let entityName;

        /* istanbul ignore if */
        if (this.state.clientRepGroup.get('name')) {
            entityName = this.state.clientRepGroup.get('name');
        }

        return (
            <DocumentTitle
                message={`document-titles.${mode}-client-rep-groups`} entityName={entityName}
            >
                <div>
                    <StickyHeader>
                        <div className="col-md-6">
                            <h1>
                                <i className="fas fa-user-secret"></i>&nbsp;{this.context.intl.messages[`groups.client-rep-group.${intlPrefix}.title`]}
                                &nbsp;
                            </h1>
                            <p className="padding-top-20">
                                {title}
                            </p>
                        </div>
                        {actionsMenu}
                    </StickyHeader>
                    <Preloader show={this.state.preloaderVisible} fixed loadingDots>
                        <section className="content" id="contentContainer">
                            <div className="row">
                                <SlidingPanel id="add-user-to-client-rep" title={addUserPanelTitle} icon="fas fa-plus-circle">{addUserPanel}</SlidingPanel>
                                <div className="col-lg-9">
                                    <Tabs location={this.props.location}>
                                        {summaryTab}
                                        {usersTab}
                                        {historyTab}
                                    </Tabs>
                                </div>
                                <div className="col-lg-3">
                                    <AlertsWarnings
                                        title={this.context.intl.messages['common.alerts-warnings-title']}
                                        validations={validations.concat(warnings)}
                                    />
                                    {eventsAndNotes}
                                </div>
                            </div>
                        </section>
                    </Preloader>
                </div>
            </DocumentTitle>
        );
    }
}

export {Create as Create_BASE};
export default WithStoreOnRoute(
    Container.create(Create)
);
