/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Modal} from 'react-bootstrap';

import AddCatalogModal from './add-catalog-modal';
import AddExistingPanel from './add-existing-panel';
import ListAssets from '../../../assets/browse/list-assets';
import AssetTabStore from '../../../assets-tab/asset-tab-store';
import SearchBox from '../../../common/search-box/search-box';
import {SlidingPanelActions} from '../../../common/sliding-panel/sliding-panel-actions';
import {WithStoreOnRoute} from '../../../common/store-on-route';
import {Debounce} from '../../../common/utils/utils';
import Preloader from '../../../preloader';
import {EventConstants, EventActions} from '../../event-actions';
import EventStore from '../../event-store';

class Assets extends React.Component {
    static get propTypes() {
        return {
            disabled: PropTypes.bool
        };
    }

    static get defaultProps() {
        return {
            disabled: true
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        const eventState = EventStore.getState();
        return {
            allAssets: eventState.getIn(['event', 'assets']),
            assets: eventState.get('assetsToDisplay'),
            event: eventState.get('event'),
            numberOfPages: Math.ceil(eventState.getIn(['event', 'assets']).size / EventConstants.ASSETS.PAGE_SIZE),
            selectedAssetsToAdd: AssetTabStore.getState().get('selectedAssetsToAdd'),
            selectedAssetCatalogs: Immutable.OrderedSet(),
            showPreloader: eventState.get('showPreloader'),
        };
    }

    static getStores() {
        return [EventStore, AssetTabStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            currentPage: 0,
            toRemoveIds: [],
            showAddCatalogModal: false,
            showWarningModal: false,
            selectAll: false
        }, this.constructor.calculateState());

        this.cancelRemovingAssets = this.cancelRemovingAssets.bind(this);
        this.handleConfirmAddAssets = this.handleConfirmAddAssets.bind(this);
        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleRemoveAssets = this.handleRemoveAssets.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
        this.toggleAddCatalogModal = this.toggleAddCatalogModal.bind(this);
        this.toggleSelectAll = this.toggleSelectAll.bind(this);
        this.toggleWarningModal = this.toggleWarningModal.bind(this);
        this.handleSelectAssetCatalogs = this.handleSelectAssetCatalogs.bind(this);
        this.handleToggleSelect = this.handleToggleSelect.bind(this);
    }

    componentDidMount() {
        EventActions.getAssetsBatch(this.state.currentPage, this.state.allAssets);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.allAssets.size !== this.state.allAssets.size) {
            EventActions.getAssetsBatch(this.state.currentPage, this.state.allAssets);
        }
    }

    handleAddExisting() {
        SlidingPanelActions.show('addExistingAssetsToEvent');
    }

    handleConfirmAddAssets() {
        EventActions.copyAssetsAndTitlesFromAssetCatalog(this.state.event.get('id'), this.state.selectedAssetCatalogs);
    }

    handleLoadPage(pageNumber) {
        this.setState(()=>({
            currentPage: pageNumber
        }));
        EventActions.getAssetsBatch(pageNumber, this.state.allAssets);
    }

    handleRemoveAssets() {
        EventActions.removeItems(EventConstants.EVENT.ITEM_TYPES.ASSET, this.state.toRemoveIds);
        this.setState(() => ({
            toRemoveIds: [],
            selectAll: false,
        }));
        this.toggleWarningModal();
    }

    handleSelectAssetCatalogs(catalogs) {
        if (!catalogs) {
            // Reset the value.
            this.setState(() => ({
                selectedAssetCatalogs: Immutable.OrderedSet()
            }));
            return;
        }

        this.setState(() => ({
            selectedAssetCatalogs: Immutable.fromJS(catalogs).toSet().sortBy(g => g.get('name'))
        }));
    }

    handleSearchTerm(term) {
        this.setRouteState('name', term)
            .clearRouteState('offset')
            .apply();

        return;
    }

    handleToggleSelect(index, value) {
        if (value) {
            this.setState(prevState => ({
                toRemoveIds: [...prevState.toRemoveIds, prevState.assets.getIn([index, 'id'])],
                selectAll: (prevState.assets.size - prevState.toRemoveIds.length) < 2
            }));
        } else {
            this.setState(prevState => ({
                toRemoveIds: prevState.toRemoveIds.filter(id => id !== prevState.assets.getIn([index, 'id'])),
                selectAll: false,
            }));
        }
    }

    toggleAddCatalogModal() {
        this.setState((prevState) => ({
            showAddCatalogModal: !prevState.showAddCatalogModal
        }));
    }

    toggleSelectAll(value) {
        if (value) {
            this.setState(prevState => ({
                toRemoveIds: prevState.assets.toJS().map(asset => asset.id),
                selectAll: true
            }));
        } else {
            this.setState(()=>({
                toRemoveIds: [],
                selectAll: false
            }));
        }
    }

    toggleWarningModal() {
        this.setState(prevState => ({
            showWarningModal: !prevState.showWarningModal
        }));
    }

    cancelRemovingAssets() {
        this.setState(({
            selectAll: false,
            toRemoveIds: []
        }));
        this.toggleWarningModal();
    }

    /* istanbul ignore next */
    render() {
        const searchName = this.getRouteState().get('name');
        let disableSelectAssets;
        let disableChooseAssetCatalog;

        if (this.state.event.get('eventType') === EventConstants.EVENT_TYPES.BAFTA_EVENT.id) {
            disableChooseAssetCatalog = true;
            if (this.state.assets.size >= 1) {
                disableSelectAssets = true;
            }
        }
        let assets = this.state.assets;
        if (searchName) {
            assets = this.state.assets.filter(asset =>
                asset.get('assetName').toLowerCase().includes(this.getRouteState().get('name'))
            );
        }

        const columnDefs = [
            {
                // Add the control class to the last column. This colum will
                // contain the button to show the responsive data.
                className: 'control',
                targets: -1,
                width: 20
            },
            {
                targets: [0],
                className: 'text-center',
            }, {
                targets: [1],
                orderable: false
            }, {
                targets: [2],
            }, {
                targets: [3],
            }, {
                targets: [4],
                orderable: false
            }
        ];

        return (
            <Preloader show={this.state.showPreloader} fixed loadingDots>
                <div className="tab-pane padding-x-20 padding-bottom-20">
                    <h3><i className="fas fa-file-image"></i>&nbsp;{this.context.intl.messages['events.assets']}&nbsp;<small>{this.state.allAssets.size}</small></h3>
                    <hr/>
                    <div className="row">
                        <div className="col-md-6 text-center">
                            <h4>{this.context.intl.messages['events.assets.add-assets.description']}</h4>
                            <p><em>{this.context.intl.messages['events.assets.add-assets.description-select']}</em></p>
                            <Button bsSize="large" className="btn btn-primary-outline Mr(3px) Mb(3px)" onClick={this.handleAddExisting} disabled={this.props.disabled || disableSelectAssets}>
                                {this.context.intl.messages['events.assets.select-assets']}
                            </Button>
                        </div>
                        <div className="col-md-6 text-center">
                            <h4>{this.context.intl.messages['events.assets.copy-catalog']}</h4>
                            <p><em>{this.context.intl.messages['events.assets.copy-catalog.description']}</em></p>
                            <Button bsSize="large" className="btn btn-primary-outline Mr(3px) Mb(3px)" onClick={this.toggleAddCatalogModal} disabled={this.props.disabled || disableChooseAssetCatalog}>
                                {this.context.intl.messages['events.assets.copy-catalog.choose']}
                            </Button>
                        </div>
                    </div>
                    <hr/>
                    <div className="container-fluid no-x-padding">
                        <div className="row">
                            <div className="col-md-6 col-md-offset-3">
                                <SearchBox onChange={this.handleSearchTerm} value={this.getRouteState().get('asset')}/>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid no-x-padding">
                        <div className="bulk-actions">
                            <Button bsSize="medium" disabled={!this.state.toRemoveIds.length || this.props.disabled} className="btn btn-danger-outline" onClick={this.toggleWarningModal}>
                                <i className="fas fa-trash-alt"></i>&nbsp;{this.context.intl.messages['events.assets.remove']}
                            </Button>
                        </div>
                    </div>
                    <hr/>
                    <ListAssets
                        activePage={this.state.currentPage}
                        assets={assets.map(asset => {
                            if (this.state.toRemoveIds.includes(asset.get('id')) ) {
                                asset = asset.set('__selected', true);
                            }
                            return asset;
                        })}
                        columnDefs={columnDefs}
                        defaultOrdering
                        onPageChange={this.handleLoadPage}
                        columns={['assetThumbnail', 'assetName', 'contentType', 'active', 'mfa']}
                        onSelect={this.handleToggleSelect}
                        onSelectAll={this.toggleSelectAll}
                        selectAll={this.state.selectAll}
                        totalPages={this.state.numberOfPages}
                        displayVideoLinks={true}
                    />
                    <AddExistingPanel event={this.state.event} selectedAssetsToAdd={this.state.selectedAssetsToAdd}/>
                    <AddCatalogModal
                        disabled={this.props.disabled}
                        onHide={this.toggleAddCatalogModal}
                        onConfirmAddAssets={this.handleConfirmAddAssets}
                        onUpdateSelectedCatalogs={this.handleSelectAssetCatalogs}
                        show={this.state.showAddCatalogModal}
                        selectedAssetCatalogs={this.state.selectedAssetCatalogs}
                    />
                </div>
                <Modal onHide={this.toggleWarningModal} show={this.state.showWarningModal}>
                    <Modal.Header className="alert-danger" closeButton>
                        <Modal.Title className="text-center">
                            {this.context.intl.messages['events.assets.remove']}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>{this.context.intl.messages['events.assets.remove.modal.part-1']}</p>
                        <p>{this.context.intl.messages['events.assets.remove.modal.part-2']}</p>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            className="pull-left Ml(5)"
                            onClick={this.cancelRemovingAssets}
                        >
                            {this.context.intl.messages['common.cancel']}
                        </Button>
                        <Button
                            bsStyle="danger"
                            type="submit"
                            onClick={this.handleRemoveAssets}
                        >
                            {this.context.intl.messages['events.assets.remove']}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Preloader>
        );
    }
}

export default WithStoreOnRoute(Container.create(Assets));
