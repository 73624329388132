/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {Col, Row} from 'react-bootstrap';

export default class Pagination extends React.Component {
    static get propTypes() {
        return {
            activePage: PropTypes.number.isRequired,
            onChange: PropTypes.func.isRequired,
            totalPages: PropTypes.number.isRequired,
            pageSelector: PropTypes.bool
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            onChange: undefined,
            pageSelector: false
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            showPageSelectionControls: false,
            inputPageValue: 1
        };

        this.handlePageNumberClick = this.handlePageNumberClick.bind(this);
        this.handlePageNumberValueChange = this.handlePageNumberValueChange.bind(this);
        this.handleShowPageSelectionControls = this.handleShowPageSelectionControls.bind(this);

        return;
    }

    handleShowPageSelectionControls() {
        this.setState((prevState) => {
            return {showPageSelectionControls: !prevState.showPageSelectionControls};
        });
    }

    handlePageNumberClick(pageNumber, event) {
        event.preventDefault();

        if (pageNumber < 0 || pageNumber >= this.props.totalPages) {return;}

        this.setState(() => {
            return {
                inputPageValue: pageNumber+1,
                showPageSelectionControls: false
            };
        });
        this.props.onChange(pageNumber);

        return;
    }

    handlePageNumberValueChange(event) {
        const value = event.target.value;

        if (!isNaN(value)) {
            this.setState(() => {
                return {
                    inputPageValue: value
                };
            });
        }
    }

    render() {
        if (!this.props.totalPages) {
            return null;
        }

        // The pages array will contain all pagination elements.
        // We only need to show 5 page buttons: 2 previous, 1 active, 2 next
        const active = this.props.activePage;
        let pages = [active - 1, active, active + 1].map(n => {
            // Skip pages below 0 and pages greater than the total.
            // The false value will cause the element to be skipped later
            // in the filter function.
            if (n < 1 || n > (this.props.totalPages - 2)) {return false;}
            // All items will have the paginate_button class, but
            // only one of them will be active.
            let css = ClassNames('paginate_button', {
                'active': active === n
            });

            let page = (
                <li
                    className={css}
                    key={n}
                    onClick={this.handlePageNumberClick.bind(this, n)}
                >
                    <a href="#" className="btn btn-primary">{n + 1}</a>
                </li>
            );

            if (active === n ) {
                page = (
                    <li
                        className={css}
                        key={n}
                        onClick={this.handleShowPageSelectionControls}
                    >
                        <a href="#" className="btn btn-primary">{n + 1}</a>
                    </li>
                );
            }

            return page;
        }).filter(i => !!i);

        const isFirstPage = (active === 0);
        const isLastPage = (active === (this.props.totalPages - 1));
        let prevClass = ClassNames('btn btn-primary', {disabled: isFirstPage});
        let nextClass = ClassNames('btn btn-primary', {disabled: isLastPage});
        let firstPageClass = ClassNames('paginate_button', {active: isFirstPage});
        let firstPage = <li className={firstPageClass} key="first-page" onClick={this.handlePageNumberClick.bind(this, 0)}><a href="#" className="btn btn-primary">1</a></li>;
        if (isFirstPage) {
            firstPage = <li className={firstPageClass} key="first-page" onClick={this.handleShowPageSelectionControls}>
                <a href="#" className="btn btn-primary">1</a>
            </li>;
        }
        let lastPageClass = ClassNames('paginate_button', {active: isLastPage});
        let lastPage;
        if (this.props.totalPages > 1) {
            lastPage = <li className={lastPageClass} key="last-page" onClick={this.handlePageNumberClick.bind(this, this.props.totalPages - 1)}><a href="#" className="btn btn-primary">{this.props.totalPages}</a></li>;
            if (isLastPage) {
                lastPage = <li className={lastPageClass} key="last-page" onClick={this.handleShowPageSelectionControls}>
                    <a href="#" className="btn btn-primary">{this.props.totalPages}</a>
                </li>;
            }
        }
        let elipse1;
        let elipse2;
        if (active > 2 && this.props.totalPages > 5) {
            elipse1 = <li className="disabled" key="elipse1"><a href="#" className="btn btn-primary disabled">...</a></li>;
        }
        if ((this.props.totalPages > 5) && (active < (this.props.totalPages - 3))) {
            elipse2 = <li className="disabled" key="elipse2"><a href="#" className="btn btn-primary disabled">...</a></li>;
        }

        let pageSelection;
        if (this.props.pageSelector) {
            pageSelection = <Col sm={6} smOffset={1}>
                <Row>
                    <div className={ClassNames('input-group', 'page-selection-controls', {'page-selection-controls-display': this.state.showPageSelectionControls})} onClick={(e) => {e.stopPropagation();}}>
                        <input onChange={this.handlePageNumberValueChange} min={1} max={this.props.totalPages} value={this.state.inputPageValue} type="number" className="form-group"></input>
                        <a className="input-group-btn">
                            <btn className="btn btn-primary" onClick={this.handlePageNumberClick.bind(this, this.state.inputPageValue-1)}>
                                {this.context.intl.messages['common.pagination.go']}
                            </btn>
                        </a>
                    </div>
                </Row>
            </Col>;
        }


        return (
            <Col>
                <Row>
                    <ul className={ClassNames('pagination', {'page-selection': this.props.pageSelector})}>
                        <li className="paginate_button" onClick={this.handlePageNumberClick.bind(this, this.props.activePage - 1)}><a href="#" className={prevClass}>{this.context.intl.messages['common.pagination.previous']}</a></li>
                        {firstPage}
                        {elipse1}
                        {pages}
                        {elipse2}
                        {lastPage}
                        <li className="paginate_button" onClick={this.handlePageNumberClick.bind(this, this.props.activePage + 1)}><a href="#" className={nextClass}>{this.context.intl.messages['common.pagination.next']}</a></li>
                    </ul>
                </Row>
                {pageSelection}
            </Col>
        );
    }
}
