/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import DocumentTitle from '../../../common/document-title';
import ListUsers from '../../../common/list-users/list-users';
import SearchBox from '../../../common/search-box/search-box';
import {WithStoreOnRoute} from '../../../common/store-on-route';
import {Debounce} from '../../../common/utils/utils';
import Preloader from '../../../preloader/';
import PreloaderStore from '../../../preloader/preloader-store';
import SessionStore from '../../../user/session/session-store';
import {UserActions} from '../../../user/user-actions';
import UserStore from '../../../user/user-store';


class Browse extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            activePage: UserStore.getState().get('activePage'),
            offset: UserStore.getState().get('offset'),
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible'),
            size: UserStore.getState().get('size'),
            sortDirection: UserStore.getState().get('sortDirection'),
            sortFieldName: UserStore.getState().get('sortFieldName'),
            total: UserStore.getState().get('total'),
            users: UserStore.getState().get('users'),
            totalPages: UserStore.getState().get('totalPages')
        };
    }

    static getStores() {
        return [PreloaderStore, UserStore];
    }

    static getPermissions() {
        return {
            canReadUsers: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.READ)
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
        this.handleSortChange = this.handleSortChange.bind(this);
    }

    componentDidMount() {
        let routeState = this.getRouteState();
        UserActions.findAllClientReps(
            parseInt(routeState.get('offset', 0), 10),
            parseInt(routeState.get('size', 20), 10),
            routeState.get('q'),
            routeState.get('sort-field-name', 'name'),
            routeState.get('sort-direction', 'asc')
        );
        return;
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.location.query !== nextProps.location.query) {
            let routeState = this.getRouteState();
            UserActions.findAllClientReps(
                parseInt(routeState.get('offset', 0), 10),
                parseInt(routeState.get('size', 20), 10),
                routeState.get('q'),
                routeState.get('sort-field-name', 'name'),
                routeState.get('sort-direction', 'asc')
            );
        }
        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.location.query !== nextProps.location.query ||
            nextState.total !== this.state.total ||
            nextState.users !== this.state.users ||
            nextState.preloaderVisible !== this.state.preloaderVisible) {
            return true;
        }

        return false;
    }

    /**
     * Handle clicks on pagination controls.
     * @param  {Number} pageNumber page to load.
     */
    handleLoadPage(pageNumber) {
        // pageNumber x this.state.size = offset.
        this.setRouteState('offset', pageNumber * parseInt(this.getRouteState().get('size', 20)), 10)
            .apply();
        return;
    }

    handleSearchTerm(term) {
        this.setRouteState('q', term)
            .apply();
        return;
    }

    handleSortChange(sortFieldName, sortDirection) {
        UserActions.setSort(sortFieldName, sortDirection);
        this.setRouteState('sort-field-name', sortFieldName)
            .setRouteState('sort-direction', sortDirection)
            .apply();
        return;
    }

    render() {
        return (
            <DocumentTitle
                message="document-titles.client-rep-group"
            >
                <div>
                    <Preloader show={this.state.preloaderVisible} fixed loadingDots>
                        <section className="content-header">
                            <h1><i className="fas fa-user"></i>&nbsp;{this.context.intl.messages['groups.client-rep-group.users']}</h1>
                        </section>
                        <section className="content">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="container-fluid no-x-padding">
                                        <div className="row">
                                            <div className="col-md-6 show-grid">
                                                <SearchBox onChange={this.handleSearchTerm} value={this.getRouteState().get('q')}/>
                                            </div>
                                            <div className="col-md-6 show-grid"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="container-fluid no-x-padding">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="box">
                                                <div className="box-body padding-x-20 padding-y-20" >
                                                    <ListUsers
                                                        columns={['name', 'country', 'email', 'status', 'clientRepGroup', 'applicationDate']}
                                                        activePage={this.state.activePage}
                                                        displayLinks={this.props.permissions.canReadUsers}
                                                        onPageChange={this.handleLoadPage}
                                                        onSortChange={this.handleSortChange}
                                                        sortDirection={this.state.sortDirection}
                                                        sortFieldName={this.state.sortFieldName}
                                                        users={this.state.users}
                                                        totalPages={this.state.totalPages}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Preloader>
                </div>
            </DocumentTitle>
        );
    }
}

export {Browse as Browse_BASE};
export default WithStoreOnRoute(
    Container.create(Browse)
);
