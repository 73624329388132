/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import ListRelated from './list-related';
import {RelatedActions} from './related-actions';
import RelatedStore from './related-store';
import {TitleActions, TitleConstants} from '../../title-actions';
import './related.less';

class Related extends React.Component {
    static get propTypes() {
        return {
            disabled: PropTypes.bool,
            id: PropTypes.number.isRequired,
            location: PropTypes.object.isRequired,
            title: PropTypes.instanceOf(Immutable.Map).isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true
        };
    }

    static calculateState() {
        return {
            original: RelatedStore.getState().get('original'),
            related: RelatedStore.getState().get('related')
        };
    }

    static getStores() {
        return [RelatedStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState;

        return;
    }

    componentDidMount() {
        if (RelatedStore.getState().get('titleId') !== parseInt(this.props.id)) {
            RelatedActions.get(this.props.id);
        }
        return;
    }

    componentWillReceiveProps(nextProps) {
        if (!RelatedStore.getState().get('isLoading') &&
            RelatedStore.getState().get('titleId') !== parseInt(nextProps.id)) {
            RelatedActions.get(nextProps.id);
        }
        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.disabled !== this.props.disabled ||
            nextProps.title !== this.props.title ||
            nextProps.id !== this.props.id ||
            nextState.related.get('children') !== this.state.related.get('children') ||
            nextState.related.get('parents') !== this.state.related.get('parents') ||
            nextState.related.get('other') !== this.state.related.get('other') ) {
            return true;
        }
        return false;
    }

    render() {
        const isSeries = TitleActions.getCategoryGroup(this.props.title.get('category')) === TitleConstants.TITLE_CATEGORY_GROUPS.SERIES;

        return (
            <div className="related-tab-page">
                <h3><i className="fas fa-share-alt"></i>&nbsp;{this.context.intl.messages['titles.create.related-tab-title']}</h3>
                <hr />

                <ListRelated
                    disabled={this.props.disabled}
                    location={this.props.location}
                    name={this.context.intl.messages['titles.create.related.children']}
                    showSeasonNumColumn={isSeries}
                    showAddNewButton
                    relationLabel="parents"
                    relationName="children"
                    titleMPM={this.props.title.get('mpmNumber')}
                    titleName={this.props.title.get('name')}
                    titles={this.state.related.get('children')}
                />
                <hr />

                <ListRelated
                    disabled={this.props.disabled}
                    location={this.props.location}
                    name={this.context.intl.messages['titles.create.related.parents']}
                    relationLabel="parents"
                    relationName="parents"
                    titleMPM={this.props.title.get('mpmNumber')}
                    titleName={this.props.title.get('name')}
                    titles={this.state.related.get('parents')}
                />
                <hr />

                <ListRelated
                    disabled={this.props.disabled}
                    location={this.props.location}
                    name={this.context.intl.messages['titles.create.related.related']}
                    relationLabel="parents"
                    relationName="other"
                    titleMPM={this.props.title.get('mpmNumber')}
                    titleName={this.props.title.get('name')}
                    titles={this.state.related.get('other')}
                />
            </div>
        );
    }
}

export default Container.create(Related);

export {Related};
