/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';
import {IndexRoute, Route} from 'react-router';

import Create from './create/create';
import WithPermissions from '../decorators/with-permissions';
import {RequirePerm} from '../router/router-helpers';
import SessionStore from '../user/session/session-store';

let Lookup =
    <Route path="/setup/lookup">
        <IndexRoute
            component={WithPermissions(Create)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.SETUP.LOOKUPS.BROWSE)}
        />
        <Route
            path="territory"
            component={WithPermissions(Create)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.SETUP.LOOKUPS.TERRITORY.BROWSE)}
        />
        <Route
            path="currency"
            component={WithPermissions(Create)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.SETUP.LOOKUPS.CURRENCY.BROWSE)}
        />
        <Route
            path="theme"
            component={WithPermissions(Create)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.SETUP.LOOKUPS.THEME.BROWSE)}
        />
        <Route
            path="genre"
            component={WithPermissions(Create)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.SETUP.LOOKUPS.GENRE.BROWSE)}
        />
        <Route
            path="language"
            component={WithPermissions(Create)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.SETUP.LOOKUPS.LANGUAGE.BROWSE)}
        />
        <Route
            path="domain-blacklist"
            component={WithPermissions(Create)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.SETUP.LOOKUPS.DOMAIN_BLACKLIST.BROWSE)}
        />
        <Route
            path="company"
            component={WithPermissions(Create)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.SETUP.LOOKUPS.COMPANY.BROWSE)}
        />
    </Route>;

export default Lookup;
