/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Tab, Tabs} from 'react-bootstrap';

import Summary from './summary/summary';
import RouteValidator from '../../common/custom-validator/route/route';
import DocumentTitle from '../../common/document-title';
import {MODAL_TYPE} from '../../common/notification/modal';
import {NotificationActions} from '../../common/notification/notification-actions';
import {AlertsWarnings} from '../../common/validations/alerts-warnings';
import ActionsMenu from '../../layout/actions-menu/actions-menu';
import StickyHeader from '../../layout/sticky-header/sticky-header';
import Preloader from '../../preloader/';
import PreloaderStore from '../../preloader/preloader-store';
import {RouterActions} from '../../router/router-actions';
import {PermissionPackageActions} from '../../security/permission-package/permission-package-actions';
import PermissionPackageStore from '../../security/permission-package/permission-package-store';
import SessionStore from '../../user/session/session-store';

class Create extends Component {

    static get propTypes() {
        return {
            params: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired,
            /**
             * Validate custom props inside the route object.
             */
            route: RouteValidator // eslint-disable-line react/require-default-props
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            permissionPackage: PermissionPackageStore.getState().get('permissionPackage'),
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible')
        };
    }

    static getStores() {
        return [PermissionPackageStore, PreloaderStore];
    }

    static getPermissions() {
        return {
            canCreate: SessionStore.canUser(SessionStore.PERMISSIONS.PRESETS.CREATE),
            canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.PRESETS.EDIT)
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            activeTab: 0
        }, this.constructor.calculateState());

        this.handleCancel = this.handleCancel.bind(this);
        this.handleDeletePreset = this.handleDeletePreset.bind(this);
        this.handleSavePreset = this.handleSavePreset.bind(this);
        this.isDirty = this.isDirty.bind(this);
        this.showDeleteDialog = this.showDeleteDialog.bind(this);
    }

    componentWillMount() {
        PermissionPackageActions.clear();
    }

    componentDidMount() {
        this.initStores(this.props);
        return;
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.route.mode !== this.props.route.mode ||
            nextProps.params.id !== this.props.params.id) {

            PermissionPackageActions.clear();
            this.initStores(nextProps);
        }
        return;
    }

    shouldComponentUpdate(nextprops, nextState) {
        if (nextState.preloaderVisible !== this.state.preloaderVisible ||
            nextState.permissionPackage !== this.state.permissionPackage) {return true;}

        return false;
    }

    handleCancel() {
        RouterActions.redirect('/presets', true);
    }

    handleDeletePreset() {
        PermissionPackageActions.deletePreset(this.state.permissionPackage);
        return;
    }

    handleSavePreset() {
        let options = {
            messages: {
                error: `presets.${this.props.route.mode}.error`,
                success: `presets.${this.props.route.mode}.success`
            }
        };

        PermissionPackageActions.savePermissionPackage(this.state.permissionPackage, options);
        return;
    }

    initStores(props) {
        if (props.route.mode === 'edit') {
            PermissionPackageActions.findById(props.params.id);
        }
        RouterActions.registerRedirectCheck(() => {
            return this.isDirty();
        });
        return;
    }

    isDirty() {
        if (this.props.route.mode === 'create') {
            return false;
        }
        return !this.state.permissionPackage.equals(PermissionPackageStore.getState().get('originalPermissionPackage'));
    }

    showDeleteDialog(event) {
        event.preventDefault();

        NotificationActions.show(
            MODAL_TYPE.DANGER,
            this.context.intl.messages['presets.edit.modal.title'],
            this.context.intl.messages['presets.edit.modal.body'],
            this.context.intl.messages['presets.edit.modal.confirm'],
            this.handleDeletePreset
        );
    }

    render() {
        let actionsMenu;
        let mode = this.props.route.mode;
        let intlPrefix = `${mode}`;
        let disableForm = true;
        let validations = PermissionPackageStore.getValidations();

        if (mode === 'create') {
            if (this.props.permissions.canCreate) {
                disableForm = false;
            }

            actionsMenu = <ActionsMenu
                canSave={!validations.length}
                onCancel={this.handleCancel}
                onSave={this.handleSavePreset}
                saveText={this.context.intl.messages['presets.create.save']}
            />;
        }

        if (mode === 'edit') {
            if (this.props.permissions.canEdit) {
                disableForm = false;
            }

            actionsMenu = <ActionsMenu
                canDelete={this.props.permissions.canEdit}
                canEdit={this.props.permissions.canEdit}
                canSave={!validations.length && this.props.permissions.canEdit}
                onCancel={this.handleCancel}
                onDelete={this.showDeleteDialog}
                onSave={this.handleSavePreset}
            />;
        }

        let entityName;

        if (this.state.permissionPackage.get('name')) {
            entityName = this.state.permissionPackage.get('name');
        }

        return (
            <DocumentTitle
                message={`document-titles.${mode}-presets`} entityName={entityName}
            >
                <div>
                    <StickyHeader>
                        <div className="col-md-6">
                            <h1>
                                <i className="fas fa-list-ul"></i>&nbsp;{this.context.intl.messages[`presets.${intlPrefix}.title`]}
                            </h1>
                            <p className="padding-top-20">
                                <em>{this.context.intl.messages[`presets.${intlPrefix}.subtitle`]}.</em>
                            </p>
                        </div>
                        {actionsMenu}
                    </StickyHeader>
                    <Preloader show={this.state.preloaderVisible} fixed loadingDots>
                        <section className="content" id="contentContainer">
                            <div className="row">
                                <div className="col-lg-9">
                                    <Tabs
                                        className="nav-tabs-custom"
                                        id="preset-tabs"
                                    >
                                        <Tab eventKey={1} title={this.context.intl.messages['presets.edit.summary-tab-title']}>
                                            <Summary mode={mode} disabled={disableForm}/>
                                        </Tab>
                                    </Tabs>
                                </div>
                                <div className="col-lg-3">
                                    <AlertsWarnings
                                        title={this.context.intl.messages['common.alerts-warnings-title']}
                                        validations={validations}
                                    />
                                </div>
                            </div>
                        </section>
                    </Preloader>
                </div>
            </DocumentTitle>
        );
    }
}

export {Create};
export default Container.create(Create);
