/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Dispatcher from '../../dispatcher/dispatcher';

const CONSTANTS = {
    CLOSE_MODAL: 'notify_subscribers_now.close_modal',
    SHOW_MODAL: 'notify_subscribers_now.show_modal',
    UPDATE: 'notify_subscribers_now.update',
};

class NotifySubscribersNowActions {
    showModal(assetId, titleId, subscriptionTypeId, subject) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SHOW_MODAL,
            assetId,
            subscriptionTypeId,
            titleId,
            subject
        });
    }

    closeModal() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLOSE_MODAL,
        });
    }

    update(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.UPDATE,
            attr: attr,
            value: value
        });
        return;
    }
}

let actions = new NotifySubscribersNowActions();

export {
    actions as NotifySubscribersNowActions,
    CONSTANTS as NotifySubscribersNowConstants
};
