/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {TimelineConstants} from './timeline-actions';
import {AssetConstants} from '../../assets/asset-actions';
import {VideoConstants} from '../../assets/video/video-actions';
import {AssetRightsConstants} from '../../common/asset-rights/asset-rights-constants';
import Validations from '../../common/validations/validations';
import Dispatcher from '../../dispatcher/dispatcher';

const TimelineValidations = {
    assetName: {
        label: 'hardac.timeline.summary.name',
        validations: [Validations.max(200), Validations.required]
    },
    contentType: {validations: [Validations.required]},
    deliveryType: {validations: [Validations.required]},
    notes: {
        label: 'hardac.timeline.summary.notes',
        validations: [Validations.max(1000)]
    },
    protectionType: {validations: [Validations.required]}
};

const CreatingClipValidations = {
    name: {
        label: 'hardac.timeline.clip.form.name',
        validations: [Validations.required]
    },
    contentType: {validations: [Validations.required]},
    deliveryType: {validations: [Validations.required]},
    protectionType: {validations: [Validations.required]},
    notes: {
        label: 'hardac.timeline.clip.notes',
        validations: [Validations.max(1000)]
    }
};

const VideoClipValidations = {
    clipIn: {
        label: 'hardac.timeline.clips.clip-in',
        validations: [Validations.required, Validations.timecode]
    },
    clipOut: {
        label: 'hardac.timeline.clips.clip-out',
        validations: [Validations.required, Validations.timecode]
    },
    name: {
        label: 'hardac.timeline.clip.form.name',
        validations: [Validations.required]
    },
    notes: {
        label: 'hardac.timeline.clip.notes',
        validations: [Validations.max(1000)]
    }
};

/**
 * Look for a constant with id === objectId in a map.
 */
const findInConstant = (constants, objectId) => {
    return Immutable.fromJS(
        Object.keys(constants)
            .map(k => constants[k])
            .filter(obj => objectId === obj.id)[0]
    );
};

/**
 * Find clip in clips list
 */
const findClip = (clips, clip) => {
    return clips.find(c => {
        // Try to match by id first, if not, match by name.
        return (
            c.get('id') === clip.get('id') ||
            c.get('name') === clip.get('name')
        );
    });
};

class TimelineStore extends ReduceStore {
    getContentType(contentTypeId) {
        return findInConstant(VideoConstants.CONTENT_TYPES, contentTypeId);
    }

    getDeliveryType(deliveryTypeId) {
        return findInConstant(AssetConstants.DELIVERY_TYPES, deliveryTypeId);
    }

    getClipValidations() {
        return Validations.validate(this.getState().get('selectedClip'), VideoClipValidations);
    }

    getCreatingClipValidations() {
        return Validations.validate(this.getState().get('creatingClip'), CreatingClipValidations);
    }

    getTimelineValidations() {
        return Validations.validate(this.getState().get('timeline'), TimelineValidations);
    }

    getInitialState() {
        return Immutable.fromJS({
            audioProfile: [],
            clips: {
                completed: [],
                failed: [],
                processing: [],
                unpublished: []
            },
            contentType: VideoConstants.toArray('CONTENT_TYPES'),
            creatingClip: {},
            deliveryType: AssetConstants.toArray('DELIVERY_TYPES'),
            history: [],
            originalClips: Immutable.Map({
                completed: [],
                failed: [],
                processing: [],
                unpublished: []
            }),
            originalSelectedClip: {},
            player: null,
            protectionType: 'DRM',
            publishingInfo: {
                catalogs: [],
                talent: [],
                titles: []
            },
            selectedClip: {},
            showHotkeysModal: false,
            showPreloader: false,
            showEditClipPanel: false,
            sources: {},
            thumbnails: [],
            timeline: {},
            timelineRightsMediaType: Immutable.fromJS(AssetRightsConstants.toArray('RIGHTS_MEDIA_TYPES')),
            timelineRightsTermType: Immutable.fromJS(AssetRightsConstants.toArray('RIGHTS_TERM_TYPES')),
            timelineRightsTerritoryType: Immutable.fromJS(AssetRightsConstants.toArray('RIGHTS_TERRITORY_TYPES')),
            timelineRightsType: Immutable.fromJS(AssetRightsConstants.toArray('RIGHTS_TYPES')),
            originalTimeline: {},
            processes: [],
            reviewProcessesProgress: Immutable.Map(),
        });
    }

    reduce(state, action) {
        switch (action.actionType) {
        case TimelineConstants.TIMELINE.PROCESSES.REVIEW_PROGRESS.SET:
            state = state.merge({
                reviewProcessesProgress: action.reviewProcessesProgress
            });
            break;
        case TimelineConstants.CLEAR:
            state = this.getInitialState();
            break;

        case TimelineConstants.HOTKEYS_MODAL.TOGGLE:
            state = state.set('showHotkeysModal', !state.get('showHotkeysModal'));
            break;

        case TimelineConstants.TIMELINE.CLIP.SELECT:
            const originalSelectedClip = findClip(state.getIn(['originalClips', 'unpublished']), action.clip);
            state = state.merge({
                selectedClip: action.clip,
                originalSelectedClip
            });
            break;

        case TimelineConstants.TIMELINE.CLIP.CLEAR:
            state = state.merge({
                selectedClip: {},
                originalSelectedClip: {}
            });
            break;

        case TimelineConstants.TIMELINE.CLIP.UPDATE_VALUE:
            state = state.setIn(['selectedClip', ...action.attr.split('.')], action.value);
            if (action.attr === 'contentType' &&
                    ((action.value === VideoConstants.CONTENT_TYPES.FULL_EPISODE.id) ||
                    (action.value === VideoConstants.CONTENT_TYPES.FULL_PROGRAM.id) ||
                    (action.value === VideoConstants.CONTENT_TYPES.FEATURE.id)) &&
                !state.getIn(['selectedClip', 'protectionType'])) {
                state = state.setIn(['selectedClip', 'protectionType'], 'DRM');
            }

            // VideoClip's frame count update - set up values for API-side rounding

            /**
             * Taking into account a videoclip's frame values, like 'clipInFrame', 'clipOutFrame' and 'thumbnailFrameNum';
             * setting their homologous parameters 'clipInFrameRounded', 'clipOutFrameRounded' and 'thumbnailFrameRounded'
             * triggers the rounding calculations on the API-side.
             *
             * Therefore, upon saving the VideoClip and reloading the page, the clip's clipInFrame', 'clipOutFrame' and 'thumbnailFrameNum'
             * will have a value that may not strictly be what was originally set, because it has been rounded while getting saved/updated.
             *
             * PS: "So why don't we just skip the manual attribute set and use 'thumbnailFrameNum'? Why do we have to care about
             * two parameters when one is effectively going to overwrite the other? Wouldn't it be better to have just one?"
             *
             * Perhaps, but unfortunately we are not the only ones dealing with these values, and we wanted to have the rounding
             * logic centralized in the API. Compromises had to be made here, so we ended up with this slightly coupled logic.
             * */

            if (action.attr === 'thumbnailFrameNum') {
                state = state.setIn(['selectedClip', 'thumbnailFrameRounded'], action.value);
            }
            if (action.attr === 'clipInFrame') {
                state = state.setIn(['selectedClip', 'clipInFrameRounded'], action.value);
            }
            if (action.attr === 'clipOutFrame') {
                state = state.setIn(['selectedClip', 'clipOutFrameRounded'], action.value);
            }

            break;

        case TimelineConstants.TIMELINE.CLIPS.GET.SUCCESS:
            state = state.merge({
                clips: action.clips,
                originalClips: action.clips,
                showPreloader: false
            });

            const selectedClip = state.get('selectedClip');
            const showEditClipPanel = state.get('showEditClipPanel');
            if (!selectedClip.isEmpty() && showEditClipPanel && action.clips.get('unpublished').size) {
                const selectedClipUpdated = findClip(action.clips.get('unpublished'), selectedClip);
                state = state.merge({
                    selectedClip: selectedClipUpdated,
                    originalSelectedClip: selectedClipUpdated,
                });
            }
            break;

        case TimelineConstants.TIMELINE.CLIPS.UPDATE:
            // Must merge on displayOrder because new clips don't have any IDs
            state = state.mergeIn(['clips', action.status, action.clip.get('displayOrder')], action.clip);
            break;

        case TimelineConstants.TIMELINE.CLIPS.UPDATE_ATTR:
            state = state.setIn(
                ['clips', ...action.attr.split('.')],
                action.value
            );
            break;

        case TimelineConstants.TIMELINE.CLIP.DELETE:
            state = state.updateIn(['clips', action.status],
                clips => clips.filter(
                    c => c.get('displayOrder') !== action.displayOrder
                ).map(
                    (c, index) => c.set('displayOrder', index)
                )
            );
            break;

        case TimelineConstants.TIMELINES.AVID.EXPORT.START:
        case TimelineConstants.TIMELINES.EXPORT.START:
        case TimelineConstants.TIMELINE.CLIPS.GET.START:
        case TimelineConstants.TIMELINE.CLIPS.PUBLISH.START:
        case TimelineConstants.TIMELINE.FIND_BY_ID.START:
            state = state.set('showPreloader', true);
            break;

        case TimelineConstants.TIMELINE.FIND_BY_ID.SUCCESS:
            state = state.merge({
                audioProfile: action.audioProfile,
                clips: action.clips,
                history: action.history,
                originalClips: action.clips,
                originalTimeline: action.timeline,
                showPreloader: false,
                timeline: action.timeline
            });
            break;

        case TimelineConstants.TIMELINE.PROCESSES.GET.SUCCESS:
            state = state.merge({
                processes: action.processes
            });
            break;

        case TimelineConstants.TIMELINE.PROXY.SRC.GET.SUCCESS:
            state = state.setIn(['sources', action.timelineId.toString()], action.src);
            break;

        case TimelineConstants.TIMELINE.SAVE.START:
            state = state.merge({
                showPreloader: true
            });
            break;

        case TimelineConstants.TIMELINE.SAVE.SUCCESS:
            state = state.merge({
                originalTimeline: state.get('timeline'),
                showPreloader: false
            });
            break;
        case TimelineConstants.TIMELINE.SEND_TO_OAP.ERROR:
            state = state.merge({
                showPreloader: false
            });
            break;
        case TimelineConstants.TIMELINE.SEND_TO_OAP.START:
            state = state.merge({
                showPreloader: true
            });
            break;
        case TimelineConstants.TIMELINE.SEND_TO_OAP.SUCCESS:
            state = state.setIn(['timeline', 'barcodeNum'], action.barcode);
            state = state.merge({
                showPreloader: false
            });
            break;

        case TimelineConstants.TIMELINES.EXPORT.SUCCESS:
        case TimelineConstants.TIMELINES.EXPORT.ERROR:
        case TimelineConstants.TIMELINES.AVID.EXPORT.SUCCESS:
        case TimelineConstants.TIMELINES.AVID.EXPORT.ERROR:
        case TimelineConstants.TIMELINE.CLIPS.GET.ERROR:
        case TimelineConstants.TIMELINE.CLIPS.PUBLISH.SUCCESS:
        case TimelineConstants.TIMELINE.CLIPS.PUBLISH.ERROR:
        case TimelineConstants.TIMELINE.FIND_BY_ID.ERROR:
        case TimelineConstants.TIMELINE.SAVE.ERROR:
            state = state.set('showPreloader', false);
            break;

        case TimelineConstants.TIMELINE.UPDATE:
            state = state.setIn(['timeline', ...action.attr.split('.')], action.value);
            break;

        case TimelineConstants.UPDATE:
            state = state.setIn([...action.attr.split('.')], action.value);
            break;

        case TimelineConstants.TIMELINE.PUBLISHING_INFO.GET.SUCCESS:
            state = state.merge({
                publishingInfo: action.publishingInfo,
            });
            break;

        case TimelineConstants.TIMELINE.THUMBNAILS.GET.SUCCESS:
            state = state.merge({
                thumbnails: action.thumbnails
            });
            break;
        }

        return state;
    }
}

export default new TimelineStore(Dispatcher);
export {CreatingClipValidations, VideoClipValidations, TimelineValidations};
