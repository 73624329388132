/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React from 'react';

import BrainiacContent from './content';
import BrainiacHeader from './header';
import BrainiacSidebar from './sidebar';
import CountdownTimer from '../common/counter/counter';
import {FixedAlert} from '../common/notification/alert';
import MFAEnrollmentEmailModal from '../common/notification/mfa/mfa-enrollment-email-modal';
import MFAPushNotificationModal from '../common/notification/mfa/mfa-push-notification-modal';
import {Modal, MODAL_TYPE} from '../common/notification/modal';
import {NotificationActions} from '../common/notification/notification-actions';
import {Debounce, IsHardacLocation} from '../common/utils/utils';
import DashboardStore from '../dashboard/dashboard-store';
import {LayoutActions} from '../layout/layout-actions';
import LayoutStore from '../layout/layout-store';
import {SessionActions} from '../user/session/session-actions';
import SessionStore from '../user/session/session-store';

const screenSizes = {
    xs: 480,
    sm: 768,
    md: 992,
    lg: 1200
};

class Page extends React.Component {
    static get propTypes() {
        return {
            children: PropTypes.node.isRequired,
            location: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get childContextTypes() {
        return {
            onToggleSidebar: PropTypes.func.isRequired
        };
    }

    static getStores() {
        return [DashboardStore, LayoutStore, SessionStore];
    }

    static calculateState() {
        return {
            applyLastWeekFilter: DashboardStore.getState().get('applyLastWeekFilter'),
            brainiacAppTheme: LayoutStore.getState().get('brainiacAppTheme'),
            defaultHomescreen: SessionStore.getState().get('defaultHomescreen'),
            hardacAppTheme: LayoutStore.getState().get('hardacAppTheme'),
            ipv: SessionStore.getState().get('ipv'),
            timeoutWarning: SessionStore.getState().get('timeoutWarning')
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            collapsedSidebar:  false,
            windowWidth: window.innerWidth,
            openSidebar: true,
            contentHeight: this.calculateContentHeight()
        }, this.constructor.calculateState());

        this.firedTimeout = false;

        this.calculateClasses = this.calculateClasses.bind(this);
        this.getChildContext = this.getChildContext.bind(this);
        this.handleKeepAlive = this.handleKeepAlive.bind(this);
        this.handleResize = Debounce(this.handleResize.bind(this), 200);
        this.handleToggleSidebar = this.handleToggleSidebar.bind(this);

        return;
    }

    getChildContext() {
        return {
            onToggleSidebar: this.handleToggleSidebar
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        if (!!this.state.ipv && this.state.ipv !== 'IPV4') {
            NotificationActions.showAlertWarning('login.ip.warning', '/setup/help');
        }
    }

    componentDidUpdate() {
        if (this.state.timeoutWarning && !this.firedTimeout) {
            setTimeout(() => {
                const message = (
                    <div>
                        {this.context.intl.messages['session.timeoutWarning.message-pre']}<CountdownTimer initialTimeRemaining={SessionStore.getTimeToLogout()} completeCallback={this.onComplete} /> . {this.context.intl.messages['session.timeoutWarning.message-post']}
                    </div>
                );

                NotificationActions.showOnTop(MODAL_TYPE.NON_CANCELABLE,
                    this.context.intl.messages['session.timeoutWarning.title'],
                    message,
                    this.context.intl.messages['session.timeoutWarning.keep-alive-button'],
                    this.handleKeepAlive,
                    null
                );
                this.firedTimeout = true;
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    calculateContentHeight() {
        return window.innerHeight;
    }

    handleResize(/*e*/) {
        this.setState({
            contentHeight: this.calculateContentHeight(),
            windowWidth: window.innerWidth
        });
        return;
    }

    handleKeepAlive() {
        SessionActions.keepAlive();
        this.firedTimeout = false;
    }

    handleToggleSidebar() {
        if (this.state.windowWidth < (screenSizes.sm -1)) {
            this.setState(prevState => ({openSidebar: !prevState.openSidebar}));
        } else {
            this.setState(prevState => ({collapsedSidebar: !prevState.collapsedSidebar}));
        }
        LayoutActions.toggleSideBar();
    }

    calculateClasses() {
        let classes = '';
        if (this.state.collapsedSidebar === true) {
            classes = 'sidebar-collapse ';
        }
        if (this.state.windowWidth < (screenSizes.sm -1)) {
            classes = '';
            if (this.state.openSidebar === true) {
                classes = 'sidebar-open ';
            }
        }

        if (this.props.location.pathname.indexOf('/hardac') !== -1) {
            classes += this.state.hardacAppTheme;
        } else {
            classes += this.state.brainiacAppTheme;
        }

        return classes;
    }

    onComplete() {
        setTimeout(() => NotificationActions.hide());
        SessionActions.endSession('timeout');
    }

    render() {
        const content = this.props.children;

        let viewingMode;
        if (IsHardacLocation(this.props.location)) {
            viewingMode = this.state.hardacAppTheme;
        } else {
            viewingMode = this.state.brainiacAppTheme;
        }

        return (
            <div className={'wrapper ' + this.calculateClasses()}>
                <MFAEnrollmentEmailModal
                    email={SessionStore.getState().getIn(['authUser', 'email'])}
                />
                <MFAPushNotificationModal/>
                <BrainiacHeader
                    applyLastWeekFilter={this.state.applyLastWeekFilter}
                    defaultHomescreen={this.state.defaultHomescreen}
                    location={this.props.location}
                    savedFilters={DashboardStore.getSavedDashboardFilters()}
                />
                <BrainiacSidebar
                    applyLastWeekFilter={this.state.applyLastWeekFilter}
                    collapsed={this.state.collapsedSidebar}
                    location={this.props.location}
                    savedFilters={DashboardStore.getSavedDashboardFilters()}
                />
                <BrainiacContent className={ClassNames({'hardac-content-wrapper': this.props.location.pathname.indexOf('/hardac') !== -1})} minHeight={this.state.contentHeight} {...this.props}>
                    <Modal viewingMode={viewingMode}/>
                    <FixedAlert>
                        {content}
                    </FixedAlert>
                </BrainiacContent>
            </div>
        );
    }
}

export default Container.create(Page);
export {Page};
