/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import createReactClass from 'create-react-class';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import {AttributeFilter} from '../../common/local-search/filter';
import {Debounce} from '../../common/utils/utils';
import ListTitles from '../../titles/browse/list-titles';
import {AssetConstants} from '../asset-actions';
import {AssetTitleActions} from '../asset-title-actions';
import AssetTitleStore from '../asset-title-store';


export default createReactClass({
    propTypes: {
        asset: PropTypes.instanceOf(Immutable.Map),
        assetId: PropTypes.number.isRequired,
        assetType: PropTypes.string,
        displayLinks: PropTypes.bool,
        location: PropTypes.object.isRequired
    },

    contextTypes: {
        intl: PropTypes.object.isRequired
    },

    getDefaultProps: function() {
        return {
            asset: Immutable.Map,
            assetType: undefined,
            disabled: true,
            displayLinks: false,
        };
    },

    getInitialState: function() {
        return {
            searchTerm: '',
            titles: Immutable.List()
        };
    },

    componentWillMount: function() {
        this.storeListeners = [
            AssetTitleStore.addListener(() => {
                this.setState({
                    titles: AssetTitleStore.getState().get('titles'),
                });
                return;
            })
        ];
        return;
    },

    componentDidMount: function() {
        AssetTitleActions.get(this.props.assetId);
        return;
    },

    componentWillUnmount: function() {
        this.storeListeners.forEach(listener => listener.remove());
        return;
    },

    handleSearchTerm: Debounce(function(term) {
        this.setState({
            searchTerm: term
        });
        return;
    }, 200),

    render: function() {
        let filteredTitles = AttributeFilter(this.state.titles, ['mpmNumber', 'name', 'categoryName'], this.state.searchTerm);
        let columns = ['mpmNumber', 'title', 'titleReleaseDate', 'categoryName'];
        if (this.props.assetType === AssetConstants.ASSET_MAIN_TYPES.IMAGE && this.props.asset.get('active')) {
            /*istanbul ignore next*/
            let formatButton = function(orientation, value, icon, titleId) {
                let color = 'bg-navy';
                if (value) {
                    color = 'bg-wb-blue';
                }
                return `<button orientation=${orientation} titleId=${titleId} type="button" class="btn ${color}" title="${orientation}">
                    <i class="${icon}" orientation=${orientation} titleId=${titleId}></i>
                    </button>`;
            };
            /*istanbul ignore next*/
            columns.push({
                name: 'actions',
                get: function(title) {
                    return formatButton('Horizontal', title.getIn(['asset', 'defaultHorizontal']), 'fas fa-arrows-alt-h', title.get('id')) + '&nbsp;'+
                        formatButton('Portrait', title.getIn(['asset', 'defaultPortrait']), 'fas fa-arrows-alt-v', title.get('id')) + '&nbsp;'+
                        formatButton('Banner', title.getIn(['asset', 'defaultBanner']), 'fas fa-arrows-alt', title.get('id'));
                },
                header: function() {
                    return <th className="actions" key="actions">Set As Default</th>;
                },
                onClick: (e) => AssetTitleActions.toogleDefault(e.target.getAttribute('titleId'), e.target.getAttribute('orientation'))
            });
        }
        return (
            <div>
                <h3>
                    <i className="far fa-newspaper"></i>&nbsp;{this.context.intl.messages['asset.edit.titles-tab-title']}
                </h3>
                <hr/>
                <div>
                    <ListTitles
                        columns={columns}
                        complete
                        displayLinks={this.props.displayLinks}
                        location={this.props.location}
                        onSearchChange={this.handleSearchTerm}
                        titles={filteredTitles}
                    />
                </div>
            </div>
        );
    }
});
