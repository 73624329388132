/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

import AssignedTable from './assigned';
import UnassignedTable from './unassigned';

import DocumentTitle from '~/src/common/document-title';
import {DownloadActions} from '~/src/common/download/download-actions';
import {FormSection} from '~/src/common/form/form';
import SearchBox from '~/src/common/search-box/search-box';
import {Debounce} from '~/src/common/utils/utils';
import {ErrorsWarnings} from '~/src/common/validations/errors-warnings';
import {ERRORTYPE} from '~/src/common/validations/validations';
import ActionsMenu from '~/src/layout/actions-menu/actions-menu';
import StickyHeader from '~/src/layout/sticky-header/sticky-header';
import Preloader from '~/src/preloader';
import {GroupActions, GroupConstants} from '~/src/security/group/group-actions';
import GroupStore from '~/src/security/group/group-store';

class TrackingReport extends Component {
    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            groups: GroupStore.getState(),
            showPreloader: GroupStore.getState().get('showPreloader'),
        };
    }

    static getStores() {
        return [GroupStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            assignedCatalogs: Immutable.List(),
            filters: ''
        }, this.constructor.calculateState());

        this.handleAddCatalog = this.handleAddCatalog.bind(this);
        this.handleDownloadReport = this.handleDownloadReport.bind(this);
        this.handleRemoveCatalog = this.handleRemoveCatalog.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
    }

    componentDidMount() {
        GroupActions.get(GroupConstants.TITLE,
            true,
            {
                filters: Immutable.Map(),
                offset: 0,
                size: 9999
            }
        );
    }

    handleAddCatalog(catalog) {
        this.setState((prevState) => ({
            assignedCatalogs: prevState.assignedCatalogs.push(catalog)
        }));
    }

    handleDownloadReport() {
        const titleCatalogIds = this.state.assignedCatalogs.toJS().map(c => c.id);
        DownloadActions.startDownloadExecution('report/view-tracking-report', {'title-catalogs-id': titleCatalogIds});
    }

    handleRemoveCatalog(catalogId) {
        this.setState((prevState) => ({
            assignedCatalogs: prevState.assignedCatalogs.filter(c => c.get('id') !== catalogId)
        }));
    }

    handleSearchTerm(term) {
        this.setState(() => ({
            'filters': term
        }));
    }

    render() {
        let validations = [];
        if (!this.state.assignedCatalogs.size) {
            validations.push({
                message: this.context.intl.messages['analytics.tracking-report.download-report.validation'],
                severity: 1,
                type: 'custom',
                errorType: ERRORTYPE.ERROR,
                icon: 'fas fa-flag',
            });
        }

        return (
            <DocumentTitle
                message="document-titles.analytics.tracking-report"
            >
                <div>
                    <StickyHeader>
                        <div className="col-md-6">
                            <h1><i className="fas fa-file-chart-line"></i>&nbsp;{this.context.intl.messages['analytics.tracking-report.title']}</h1>
                        </div>
                        <ActionsMenu>
                            <Button
                                disabled={this.state.assignedCatalogs.size === 0}
                                onClick={this.handleDownloadReport}
                                className="btn btn-primary-outline"
                                bsSize="large">
                                <i className="fas fa-download"></i>&nbsp;{this.context.intl.messages['analytics.tracking-report.download-report']}
                            </Button>
                        </ActionsMenu>
                    </StickyHeader>
                    <Preloader show={this.state.showPreloader} fixed loadingDots>
                        <section className="content" id="contentContainer">
                            <div className="row">
                                <div className="col-lg-9">
                                    <div className="container-fluid no-x-padding">
                                        <div>
                                            <h4>{this.context.intl.messages['analytics.tracking-report.catalogs.assigned-title']}&nbsp;<small>{this.state.assignedCatalogs.size}</small></h4>
                                            <FormSection>
                                                <AssignedTable
                                                    assignedCatalogs={this.state.assignedCatalogs}
                                                    handleRemoveCatalog={this.handleRemoveCatalog}
                                                />
                                            </FormSection>
                                            <hr/>
                                            <h4>{this.context.intl.messages['analytics.tracking-report.catalogs.unassigned-title']}</h4>
                                            <FormSection>
                                                <div className="container-fluid no-x-padding">
                                                    <div className="row">
                                                        <div className="col-md-3"></div>
                                                        <div className="col-md-6 show-grid">
                                                            <SearchBox value={this.state.searchTerm} onChange={this.handleSearchTerm}/>
                                                        </div>
                                                        <div className="col-md-3"></div>
                                                    </div>
                                                </div>
                                                <UnassignedTable
                                                    assignedCatalogs={this.state.assignedCatalogs}
                                                    catalogs={this.state.groups.getIn(['title', 'groups'])}
                                                    filters={this.state.filters}
                                                    handleAddCatalog={this.handleAddCatalog}
                                                />
                                            </FormSection>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <ErrorsWarnings
                                        title={this.context.intl.messages['common.errors-warnings-title']}
                                        validations={validations}
                                    />
                                </div>
                            </div>
                        </section>
                    </Preloader>
                </div>
            </DocumentTitle>
        );
    }
}

export default Container.create(TrackingReport);
