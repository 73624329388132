/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {MarkerStyle} from '@accurate-player/accurate-player-components-external'; // eslint-disable-line import/named
import React from 'react';
import {FormattedMessage} from 'react-intl';

import {ChangedCuesMap$} from '../../bl';

import WithRxSubscriptions from '~/src/decorators/with-rx-subscriptions';

type Props = {
    changedCuesMap: DeepImmutable<Dictionary<boolean>>,
    converter: {
        secondsToFrames: (seconds: number) => number,
    },
    cues: ReadonlyArray<WBTVDCue>,
    readonly?: boolean,
    title: string,
}

const DEFAULT_MARKER_STYLE: MarkerStyle = {
    backgroundColor: '#0099ee',
    hover: {
        backgroundColor:'#0099ee',
    }
};
const CHANGED_CUE_MARKER_STYLE: MarkerStyle = {
    backgroundColor: 'darkgrey',
    hover: {
        backgroundColor: 'grey'
    },
    selected: {
        backgroundColor: 'aaffff'
    }
};
const DEFAULT_MARKER_STYLE_AS_STRING = JSON.stringify(DEFAULT_MARKER_STYLE);
const CHANGED_CUE_STYLE_AS_STRING = JSON.stringify(CHANGED_CUE_MARKER_STYLE);

class BaseTimelineRow extends React.PureComponent<Props> {
    constructor(props: Props) {
        super(props);

        this.renderSegment = this.renderSegment.bind(this);
    }

    private renderSegment(cue: WBTVDCue) {
        let markerStyle = DEFAULT_MARKER_STYLE_AS_STRING;
        let icon;
        if (!this.props.readonly && this.props.changedCuesMap[cue.id]) {
            icon = <i className="fas fa-pen" />;
            markerStyle = CHANGED_CUE_STYLE_AS_STRING;
        }

        return (
            <ap-timeline-marker
                key={cue.id}
                identifier={cue.id}
                markerStyle={markerStyle}
                start={this.props.converter.secondsToFrames(cue.startTime)}
                end={this.props.converter.secondsToFrames(cue.endTime)}
                data-cue-id={cue.id}
            >
                {icon}
            </ap-timeline-marker>
        );
    }

    render() {
        return (
            <ap-timeline-row>
                <div className="row-header" ref={initHeaderSlot}>
                    <FormattedMessage id={this.props.title} />
                </div>

                <ap-timeline-metadata renderer-type="canvas_2d">
                    {this.props.cues.map(this.renderSegment)}
                </ap-timeline-metadata>
            </ap-timeline-row>
        );
    }
}

export default WithRxSubscriptions(BaseTimelineRow, {
    changedCuesMap: ChangedCuesMap$
});

function initHeaderSlot(header?: Element | null) {
    header?.setAttribute('slot', 'header');
}
