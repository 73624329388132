/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ProgressBar} from 'react-bootstrap';

import {FormSection} from '../../../common/form/form';
import Config from '../../../config/config.js';
import {RouterActions} from '../../../router/router-actions';

const getStatus = (processesStatus = Immutable.Map()) => {
    let status = 'Running';

    if (processesStatus.every(p => p.get('completed'))) {
        status = 'Completed';
    }

    if (processesStatus.some(p => p.get('failed'))) {
        status = 'Failed';
    }

    return status;
};

const getTimeElapsed = (processesStatus = Immutable.Map()) => {
    let durations = 0;
    processesStatus.forEach(p => {
        const sentDate = p.get('sentDate');
        let lastDate = new Date();

        if (p.get('failureDate')) {
            lastDate = p.get('failureDate');
        }

        if (p.get('completeDate')) {
            lastDate = p.get('completeDate');
        }

        const start = Moment(sentDate).tz(Config.DefaultTimezone);
        const end = Moment(lastDate).tz(Config.DefaultTimezone);
        const duration = Moment.duration(end.diff(start));
        const durationMs = duration.asMilliseconds();
        durations += durationMs;
    });

    return Moment.duration(durations, 'milliseconds').humanize();
};

const getProgressPercentage = (processesStatus = Immutable.Map()) => {
    let progress = 0;
    if (!processesStatus.size) {return progress;}

    processesStatus.forEach(p => {
        const percentComplete = p.get('percentComplete');
        if (percentComplete) {
            progress += percentComplete/processesStatus.size;
        }
    });

    return progress;
};

class Summary extends Component {
    static get propTypes() {
        return {
            reviewProcessesProgress: PropTypes.instanceOf(Immutable.Map).isRequired,
            tasks: PropTypes.instanceOf(Immutable.List).isRequired,
            timelineId: PropTypes.number.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.handleRedirectToMessage = this.handleRedirectToMessage.bind(this);
    }

    handleRedirectToMessage(taskId) {
        if (taskId) {
            const messageId = this.props.reviewProcessesProgress.get(taskId).get('messageId');
            RouterActions.redirect(`/hardac/processes/v3/timeline/${this.props.timelineId}/status/messages/${messageId}`);
        }
    }

    render() {
        const status = getStatus(this.props.reviewProcessesProgress);
        const timeElapsed = getTimeElapsed(this.props.reviewProcessesProgress);
        const totalCompleted = this.props.reviewProcessesProgress.filter(p => p.get('completed')).size;
        const progress = getProgressPercentage(this.props.reviewProcessesProgress);

        const tasks = this.props.tasks.map(task => {
            let id, icon, textColor;
            let taskProgress = this.props.reviewProcessesProgress.get(task);

            if (!taskProgress) {
                id = task;
                icon = 'fas fa-ellipsis-h'; // didn't start
                textColor = 'text-gray';
            } else {
                if (taskProgress.get('started')) {
                    // Running
                    id = task;
                    icon = 'fas fa-ellipsis-h';
                    textColor = 'text-primary';
                } else {
                    id = task;
                    icon = 'fas fa-ellipsis-h'; // didn't start
                    textColor = 'text-gray';
                }

                if (taskProgress.get('completed')) {
                    id = task;
                    icon = 'fas fa-check';
                    textColor = 'text-success';
                }

                if (taskProgress.get('failed')) {
                    id = task;
                    icon = 'fas fa-times';
                    textColor = 'text-danger';
                }
            }

            return {
                id,
                label: this.context.intl.messages[`hardac.tasks.${id}`],
                icon,
                textColor
            };
        });

        let processBarColor;
        switch (true) {
        case (status === 'Completed'): processBarColor = 'success'; break; // if all tasks were completed
        case (status === 'Failed'): processBarColor = 'danger'; break;
        default: processBarColor = 'primary'; break;
        }

        return (
            <div className="process-detail">
                <FormSection>
                    <div className="col-md-4">
                        <div className="info-box">
                            <span className="info-box-icon bg-default"><i className="far fa-battery-half"></i></span>
                            <div className="info-box-content">
                                <span className="info-box-text">{this.context.intl.messages['hardac.processes.status']}</span>
                                <h3 className="no-top-margin">{status}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="info-box">
                            <span className="info-box-icon bg-default"><i className="far fa-tasks"></i></span>
                            <div className="info-box-content">
                                <span className="info-box-text">{this.context.intl.messages['hardac.processes.task-complete']}</span>
                                <h3 className="no-top-margin">{totalCompleted}/{tasks.size}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="info-box">
                            <span className="info-box-icon bg-default"><i className="far fa-stopwatch"></i></span>
                            <div className="info-box-content">
                                <span className="info-box-text">{this.context.intl.messages['hardac.processes.time-elapsed']}</span>
                                <h3 className="no-top-margin">{timeElapsed}</h3>
                            </div>
                        </div>
                    </div>
                </FormSection>

                <hr/>

                <ProgressBar striped bsStyle={processBarColor} now={parseInt(progress)} label={`${parseInt(progress)}%`} />

                <h3>{this.context.intl.messages['hardac.tasks.encoding-tasks']}</h3>
                <ul className="thread-list">
                    <li>
                        <ul className="thread">
                            {tasks.map((task, index) => {
                                let externalLink;
                                if (task.id) {
                                    externalLink = <a onClick={this.handleRedirectToMessage.bind(this, task.id)}><small>&nbsp;<i className="far fa-external-link"></i></small></a>;
                                }
                                return (
                                    <li key={index}>
                                        <span className={task.textColor}><i className={task.icon}></i></span>&nbsp;{task.label}
                                        {externalLink}
                                    </li>
                                );
                            })}
                        </ul>
                    </li>
                </ul>
            </div>
        );
    }
}

export default Summary;
