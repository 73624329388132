/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
*/

import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Tooltip, OverlayTrigger} from 'react-bootstrap';
import ReactTable from 'react-table';

import Pagination from '../../../common/table/pagination';
import {JobConstants, JobActions} from '../job-actions';
import JobStore from '../job-store';

import 'react-table/react-table.css';

export default class Table extends Component {
    static get propTypes() {
        return {
            activePage: PropTypes.number.isRequired,
            jobs: PropTypes.instanceOf(Immutable.List).isRequired,
            onPageChange: PropTypes.func.isRequired,
            onSortChange: PropTypes.func.isRequired,
            sortDirection: PropTypes.string.isRequired,
            sortFieldName: PropTypes.string.isRequired,
            totalPages: PropTypes.number.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
            router: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            selectAll: false,
        };

        this.getHeaderNameClass = this.getHeaderNameClass.bind(this);
        this.handleHeaderClick = this.handleHeaderClick.bind(this);
        this.handleSelectAll = this.handleSelectAll.bind(this);
        this.handleSelectRow = this.handleSelectRow.bind(this);
    }

    getHeaderNameClass(headerName) {
        let headerClassName = 'sorting';

        if (headerName === this.props.sortFieldName) {
            headerClassName = `sorting-${this.props.sortDirection}`;
        }

        return headerClassName;
    }

    handleHeaderClick(headerName) {
        if (!headerName || headerName === '_select') { // if column does not have sorting
            return;
        }

        let newSortDirection = 'asc';
        if (this.props.sortFieldName === headerName && this.props.sortDirection === 'asc') {
            newSortDirection = 'desc';
        }
        if (this.props.onSortChange) {
            this.props.onSortChange(headerName, newSortDirection);
        }
        return;
    }

    handleSelectAll() {
        const ids = this.props.jobs.filter(j => j.get('jobStatus') === JobConstants.STATUS_TYPE.FAILED.id).map(j => j.get('processId')).toJS();
        JobActions.selectAll(!this.state.selectAll, ids);
        this.setState((prevState) => ({selectAll: !prevState.selectAll}));
        return;
    }

    handleSelectRow(e) {
        JobActions.updateJob(parseInt(e.target.getAttribute('data-process-id'), 10), 'selected', e.target.checked);
        this.setState(() => ({selectAll: false}));
        return;
    }

    render() {
        const getHeader = this.getHeaderNameClass;

        let columns = [{
            accessor: '_select',
            maxWidth: 31,
            Header:  () => <span><input type="checkbox" disabled={!this.props.jobs.size} onChange={this.handleSelectAll} checked={this.state.selectAll} className="check-all"/></span>,
            Cell: c => {
                let result;
                if (c.original.get('jobStatus') === JobConstants.STATUS_TYPE.FAILED.id) {
                    result = <input className="bulk-checkbox" type="checkbox" data-process-id={c.original.get('processId')} onChange={this.handleSelectRow} checked={c.original.get('selected')}/>;
                }
                return <span>{result}</span>;
            }
        }, {
            accessor: 'JOB_TYPE',
            Header: props => (
                <strong className={getHeader(props.column.id)}>{this.context.intl.messages['hardac.processes.jobs.type']}</strong>
            ),
            maxWidth: 60,
            className: 'h-alignment',
            Cell: c => <OverlayTrigger overlay={
                <Tooltip id="job" width={'auto'}>{JobConstants.JOB_TYPE[c.original.get('jobType')].label}</Tooltip>}
            placement="top"
            delayShow={300}
            delayHide={150}>
                <i className={JobConstants.JOB_TYPE[c.original.get('jobType')].icon}></i>
            </OverlayTrigger>
        }, {
            accessor: 'JOB_NAME',
            maxWidth: 608,
            Header: props => (
                <strong className={getHeader(props.column.id)}>{this.context.intl.messages['hardac.processes.jobs.name']}</strong>
            ),
            Cell: c => {
                return (
                    <div>
                        {c.original.get('jobName')}&nbsp;
                        <a onClick={() => window.open(`/hardac/processes/${c.original.get('processId')}`)}>
                            <i className="far fa-external-link"></i>
                        </a>
                    </div>
                );
            }
        }, {
            accessor: 'JOB_STATUS',
            maxWidth: 133,
            Header: props => (
                <strong className={getHeader(props.column.id)}>{this.context.intl.messages['hardac.processes.jobs.status']}</strong>
            ),
            Cell: c => {
                const status = JobStore.getStatusType(c.original.get('jobStatus')) || {};
                return (
                    <div>
                        <i className={status.icon}></i>&nbsp;{status.label}
                    </div>
                );
            }
        }, {
            accessor: 'LAST_PROCESS',
            Header: props => (
                <strong className={getHeader(props.column.id)}>{this.context.intl.messages['hardac.processes.jobs.last-process']}</strong>
            ),
            maxWidth: 260,
            Cell: c => {
                const order = c.original.get('currentStepNumber');
                const processName = c.original.get('processType');
                return (
                    <div>
                        {`#${order}:`}&nbsp;<a>{processName}</a>
                    </div>
                );
            }
        }, {
            accessor: 'TIME_RUNNING',
            maxWidth: 162,
            Header: props => (
                <strong className={getHeader(props.column.id)}>{this.context.intl.messages['hardac.processes.jobs.time-running']}</strong>
            ),
            Cell: c => {
                const now = Moment();
                let timeRunning = '-';
                switch (c.original.get('jobStatus')) {
                case JobConstants.STATUS_TYPE.COMPLETE.id:
                    timeRunning = Moment.duration(Moment(c.original.get('finishedDate')).diff(c.original.get('initialStartDate'))).humanize();
                    break;
                case JobConstants.STATUS_TYPE.FAILED.id:
                    timeRunning = Moment.duration(Moment(c.original.get('failureDate')).diff(c.original.get('initialStartDate'))).humanize();
                    break;
                case JobConstants.STATUS_TYPE.RUNNING.id:
                    timeRunning = Moment.duration(now.diff(c.original.get('initialStartDate'))).humanize();
                    break;
                }
                return (
                    <div>
                        {timeRunning}
                    </div>
                );
            }
        }, {
            accessor: 'LAST_UPDATED',
            maxWidth: 167,
            Header: props => (
                <strong className={getHeader(props.column.id)}>{this.context.intl.messages['hardac.processes.jobs.last-updated']}</strong>
            ),
            Cell: c => {
                const lastUpdate = Moment(c.original.get('sentDate'));
                let lastUpdateFormated = '-';
                if (lastUpdate.isValid()) {
                    lastUpdateFormated = lastUpdate.format(this.context.intl.messages['datetime-format-0']);
                }
                return (
                    <div>
                        {lastUpdateFormated}
                    </div>
                );
            }
        }];

        let pagination = <div className="row">
            <div className="col-sm-7 col-sm-offset-5">
                <Pagination
                    activePage={this.props.activePage}
                    onChange={this.props.onPageChange}
                    totalPages={this.props.totalPages}
                />
            </div>
        </div>;

        const handleSortColumn = this.handleHeaderClick;

        return (
            <div className="box-body">
                <ReactTable
                    className="-striped table-bordered table-striped responsive"
                    columns={columns}
                    data={this.props.jobs}
                    getNoDataProps= {() => {
                        if (this.props.jobs.size) {
                            return {style: {display: 'none'}};
                        }
                        return {};
                    }}
                    id="browse-jobs-table"
                    loading={false}
                    showPagination={false}
                    sortable={false}
                    resizable={false}
                    getTheadThProps={(state, rowInfo, column) => ({
                        onClick: () => {
                            handleSortColumn(column.id);
                        }
                    })}
                />
                {pagination}
            </div>
        );
    }
}
