/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import LanguageSection, {LanguageSectionProps} from './language-section';

import {VideoActions} from '~/src/assets/video/video-actions';
import VideoStore from '~/src/assets/video/video-store';
import {SectionPanel} from '~/src/common/panel/panel';
import GroupBy from '~/src/common/utils/group-by';
import CreateVttModal from '~/src/hardac/cc-editor/components/common/create-vtt-modal';

type Props = {
    canEditCaptions: boolean,
    disabled: boolean,
    newWebVttData: ImmutableMap<{file: File | null, showModal: boolean, vtt: ImmutableMap<VideoWebVtt>}>
    webVTTFiles: Immutable.List<ImmutableMap<VideoWebVtt>>
}

export default class Captions extends React.PureComponent<Props> {

    private handleVttChanged<P extends keyof VideoWebVtt>(key: P, value: VideoWebVtt[P]) {
        VideoActions.updateNewVtt(`vtt.${key}`, value);
    }

    private handleFileSelected(file: File) {
        VideoActions.updateNewVtt('file', file);
    }

    private save = () => {
        const file = this.props.newWebVttData.get('file');
        const vtt = this.props.newWebVttData.get('vtt');
        if (file) {
            VideoActions.createNewVtt(vtt, file, this.props.webVTTFiles);
        }
    };

    render() {
        return (
            <SectionPanel
                title={<FormattedMessage id="asset.video.edit.captions.title" />}
                validationState={getValidationState(this.props.webVTTFiles.size)}
            >

                {groupByLanguage(this.props.webVTTFiles.toJS()).map((languageProps) => (
                    <LanguageSection
                        key={languageProps.language}
                        canEditCaptions={this.props.canEditCaptions}
                        disabled={this.props.disabled}
                        {...languageProps}
                    />
                ))}

                <div className="text-center Mt(20px)">
                    <button className="btn btn-lg btn-primary-outline" onClick={VideoActions.showCreateNewVttModal}>
                        <i className="fas fa-plus"/>
                        &nbsp;
                        <FormattedMessage id="common.add-new" />
                    </button>
                </div>
                <CreateVttModal
                    confirmIcon="fas fa-plus"
                    confirmMessage="cc-editor.add-new-vtt-modal.create-btn"
                    description="cc-editor.add-new-vtt-modal.description"
                    onChangeVtt={this.handleVttChanged}
                    onFileSelected={this.handleFileSelected}
                    onHide={VideoActions.hideNewVttModal}
                    onSave={this.save}
                    selectedFile={this.props.newWebVttData.get('file')}
                    show={this.props.newWebVttData.get('showModal')}
                    title="cc-editor.add-new-vtt-modal.header"
                    vtt={this.props.newWebVttData.get('vtt')}
                />

            </SectionPanel>
        );
    }
}

function groupByLanguage(vttFiles: VideoWebVtt[]): Array<Pick<LanguageSectionProps, 'language' | 'files'>> {
    const entries = Object.entries(GroupBy('languageCode', vttFiles));
    return entries.map(([languageCode, files]) => {
        const language = VideoStore.getLanguage(languageCode)?.get('name') || '';
        return {
            files,
            language,
        };
    });
}

function getValidationState(files: number): ValidationState | undefined {
    if (files === 0) {
        return;
    }

    const webVTTFileValidations = VideoStore.getWebVTTFileValidations();
    if (webVTTFileValidations.length) {
        return 'error';
    }

    return 'success';
}

