/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';

import {AlertTypes} from '../common/notification/alert';
import {NotificationActions} from '../common/notification/notification-actions';
import {ListDiff} from '../common/utils/utils';
import Dispatcher from '../dispatcher/dispatcher';
import {PreloaderActions} from '../preloader/preloader-actions';
import Request from '../request';
import {RouterActions} from '../router/router-actions';
import {ActionHistoryConstants} from '../system/action-history/action-history-actions';

const SECTIONS = {
    BILLBOARD: {
        id: 1,
        name: 'BILLBOARD',
        label: 'homepages.create.templates.billboard'
    },
    SMALL_BILLBOARD: {
        id: 2,
        name: 'SMALL_BILLBOARD',
        label: 'homepages.create.templates.small-billboard'
    },
    VIDEO_LIST: {
        id: 3,
        name: 'VIDEO_LIST',
        label: 'homepages.create.templates.video-list'
    },
    TITLE_ONE: {
        id: 3,
        name: 'TITLE_ONE',
        label: 'homepages.create.templates.title-one'
    },
    TITLE_TWO: {
        id: 4,
        name: 'TITLE_TWO',
        label: 'homepages.create.templates.title-two'
    },
    TITLE_THREE: {
        id: 5,
        name: 'TITLE_THREE',
        label: 'homepages.create.templates.title-three'
    },
    DYNAMIC_LIST: {
        id: 6,
        name: 'DYNAMIC_LIST',
        label: 'homepages.create.templates.additional-section'
    }
};

const CONSTANTS = {
    CLEAR: 'homepage.findby.clear',
    CONTENT_TYPE: {
        HOME_PAGE: {
            id: 'HOME_PAGE',
            displayName: 'accounts.edit.summary.homepages.content-type.home-page'
        },
        SINGLE_TITLE: {
            id: 'SINGLE_TITLE',
            displayName: 'accounts.edit.summary.homepages.content-type.single-title',
            homepageName: 'accounts.edit.summary.homepages.entry-point'
        },
        MULTIPLE_TITLES: {
            id: 'MULTIPLE_TITLES',
            displayName: 'accounts.edit.summary.homepages.content-type.multiple-titles',
            homepageName: 'accounts.edit.summary.homepages.entry-point'
        },
        NO_CONTENT_NO_DEFAULT: {
            id: 'NO_CONTENT_NO_DEFAULT',
            displayName: 'accounts.edit.summary.homepages.content-type.not-content',
            homepageName: 'accounts.edit.summary.homepages.no-access'
        },
    },
    FILTERS: {
        ACTIVE_OPTIONS: {
            ACTIVE: {id: 'ACTIVE', value: 1, name: 'Active'},
            INACTIVE: {id: 'INACTIVE', value: 0, name: 'Inactive'},
            BOTH: {id: 'BOTH', value: 2, name: 'Both'}
        }
    },
    FILTER: {
        CLEAR: 'homepage_actions.filter.clear',
        SET: 'homepage_actions.filter.set'
    },
    GET: {
        ERROR: 'homepage.get.error',
        SUCCESS: 'homepage.get.success'
    },
    HOMEPAGE: {
        GET: {
            SUCCESS: 'homepage_actions.homepage.get.success'
        },
        REMOVE: {
            ERROR: 'homepage_actions.homepage.remove.error',
            SUCCESS: 'homepage_actions.homepage.remove.success'
        },
        SAVE: {
            SUCCESS: 'homepage_actions.homepage.save.success'
        },
        UPDATE: 'homepage_actions.homepage.update',
        USERS: {
            GET: {
                SUCCESS: 'homepage_actions.homepage.users.get.success'
            }
        }
    },
    ITEMS: {
        GET: {
            SUCCESS: 'homepage.get.items.success'
        },
    },
    NAVIGATION_ITEMS: {
        GET: {
            SUCCESS: 'homepage.get.navigation_items.success'
        },
    },
    PAGE_TYPE: {
        LEGACY: {
            id: 1,
            label: 'homepages.create.templates.legacy',
            name: 'Legacy',
            CONTENT: [
                SECTIONS.BILLBOARD,
                SECTIONS.SMALL_BILLBOARD,
                SECTIONS.TITLE_ONE,
                SECTIONS.TITLE_TWO,
                SECTIONS.TITLE_THREE
            ],
            THUMBNAIL: require('./homepage-legacy.jpg')
        },
        PLAYLIST: {
            id: 2,
            label: 'homepages.create.templates.playlist',
            name: 'Playlist',
            CONTENT: [
                SECTIONS.BILLBOARD,
                SECTIONS.VIDEO_LIST
            ],
            THUMBNAIL: require('./homepage-playlist.jpg')
        },
        UPFRONTS: {
            id: 3,
            label: 'homepages.create.templates.upfronts',
            name: 'Upfronts',
            CONTENT: [
                SECTIONS.BILLBOARD,
                SECTIONS.TITLE_ONE,
                SECTIONS.TITLE_TWO
            ],
            THUMBNAIL: require('./homepage-upfronts.jpg')
        },
        PRESS_RELEASE: {
            id: 4,
            label: 'homepages.create.templates.press-release',
            name: 'Press Release',
            CONTENT: [
                SECTIONS.BILLBOARD,
                SECTIONS.VIDEO_LIST
            ],
            THUMBNAIL: require('./homepage-press.jpg')
        },
    },
    SECTION: {
        ADD: 'homepage.sections.add',
        REMOVE: 'homepage.sections.remove'
    },
    USER_GROUPS: {
        GET: {
            ERROR: 'homepage.user_groups.get.error',
            SUCCESS: 'homepage.user_groups.get.success'
        }
    }
};

class HomepageActions {
    addSection() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SECTION.ADD
        });
    }

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR,
        });
    }

    clearFilter() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.FILTER.CLEAR
        });
    }

    get(queryParams) {
        queryParams = queryParams.toJS();
        queryParams.active = CONSTANTS.FILTERS.ACTIVE_OPTIONS[queryParams.active || CONSTANTS.FILTERS.ACTIVE_OPTIONS.ACTIVE.id].id;
        queryParams.offset = queryParams.offset || 0;
        queryParams.size = queryParams.size || 99999;

        const req = Request.get('web/homepage').query(queryParams);

        const preloaderSource = 'homepage-actions.get';
        PreloaderActions.show(preloaderSource);
        req.exec()
            .then(res => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET.SUCCESS,
                    homepages: Immutable.fromJS(res.body).sortBy(r => r.get('name'))
                });
                return;
            }).then(()=>{
                PreloaderActions.hide(preloaderSource);
                return;
            })
            .catch((err) => {
                PreloaderActions.hide(preloaderSource);
                switch (err.status) {
                case 404:
                    RouterActions.notFound();
                    break;
                default:
                    NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
                    break;
                }
                throw err;
            });

        return;
    }

    findById(id) {
        if (!id) {
            return;
        }

        let homepage;

        let actionHistoryQuery = {
            'action-object': ActionHistoryConstants.ACTION_OBJECTS.HOMEPAGE,
            'object-id': id,
            offset: 0,
            size: 4
        };

        const preloaderSource = 'homepage-actions.findById';
        PreloaderActions.show(preloaderSource);

        Promise.all([
            Request.get(`web/homepage/${id}/user-group`).exec(),
            Request.get('system/action-history').query(actionHistoryQuery).exec(),
            Request.get(`web/homepage/${id}`).exec(),
            Request.get(`web/homepage/${id}/partner`).exec().catch(err => {
                switch (err.status) {
                case 404:
                    // Catch the error.
                    // But fix the promise by returning a valid value that will not break the logic:
                    return {body: []};
                }
            }),
            Request.get(`web/homepage/${id}/section`).exec()
        ]).spread((groupsRes, historyRes, homepageRes, partnersRes, sectionsRes) => {
            homepage = homepageRes.body;

            homepage.history = historyRes.body.results;
            homepage.history.sort((h1, h2) => h2.actionDate.localeCompare(h1.actionDate));
            homepage.partners = partnersRes.body;
            homepage.sections = sectionsRes.body;

            return Promise.all(groupsRes.body.map(
                g => Request.get(`security/group/${g.groupId}`).exec()
            ));
        }).then(groupsRes => {

            homepage.groups = groupsRes.map(res => res.body);

            return Promise.all(homepage.partners.map(
                p => Request.get(`partner/${p.partnerId}`).exec().catch(err => {
                    switch (err.status) {
                    case 404:
                        // Catch the error.
                        NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-partner.error');
                        // But fix the promise by returning a valid value that will not break the logic:
                        return {body: null};
                    }
                })
            ));

        }).then(partnersRes => {

            homepage.partners = partnersRes.map(res => res.body).filter(partner => !!partner);

            return Promise.all(homepage.sections.map(
                section => Request.get(`web/publish-list/${section.publishListId}`).exec()
            ));
        }).then(publishingListRes => {

            homepage.sections = homepage.sections.map((section, index) => {
                section.publishingList = publishingListRes[index].body;
                return section;
            });
            return Promise.all(homepage.sections.map(
                item => Request.get(`web/publish-list/${item.publishListId}/items`).exec()
            ));
        }).then(itemsRes => {

            homepage.sections = homepage.sections.map((section, index) => {
                section.publishingList.items = itemsRes[index].body;
                section.publishingList.items.sort((a, b) => a.displayOrder - b.displayOrder);
                return section;
            });

            homepage = Immutable.fromJS(homepage);
            homepage = homepage.update('groups', g => g.toOrderedSet().sortBy(g2 => g2.get('name')));
            homepage = homepage.update('partners', p => p.toOrderedSet().sortBy(p2 => p2.get('name')));

            Dispatcher.dispatch({
                actionType: CONSTANTS.HOMEPAGE.GET.SUCCESS,
                homepage: homepage
            });
            return;
        }).then(()=>{
            PreloaderActions.hide(preloaderSource);
            return;
        }).catch(err => {
            PreloaderActions.hide(preloaderSource);
            switch (err.status) {
            case 404:
                RouterActions.notFound();
                break;
            default:
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
                break;
            }
            throw err;
        });

        return;
    }

    getItems(id, sectionIndex, sectionType) {
        let items;
        Request.get(`web/publish-list/${id}/items`).exec()
            .then(res => {
                let reqs=[];
                items = res;
                res.body.forEach(item => {
                    switch (true) {
                    case (item.targetType === 1):
                        reqs.push(Request.get(`title/${item.assetId}`).exec());
                        break;
                    case (item.targetType === 2):
                        let assetType = item.displayAssetTypeName.toLowerCase();
                        reqs.push(Request.get(`asset/${assetType}/${item.assetId}`).exec());
                        break;
                    }
                });
                return Promise.all(reqs);
            })
            .then(resps => {
                let i = 0;
                items.body.forEach(item => {
                    switch (true) {
                    case (item.targetType === 1):
                        item.name = resps[i].body.name;
                        i++;
                        break;
                    case (item.targetType === 2):
                        item.name = resps[i].body.assetName;
                        i++;
                        break;
                    }

                });
                Dispatcher.dispatch({
                    actionType: CONSTANTS.ITEMS.GET.SUCCESS,
                    items: Immutable.fromJS(items.body).sortBy(r => r.get('displayOrder')),
                    sectionIndex: sectionIndex
                });
            })
            .catch(err => {
                switch (err.status) {
                case 404:
                    NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, `homepages.list-items.error.section${sectionType}`);
                    break;
                default:
                    NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
                    break;
                }
                throw err;
            });
        return;
    }

    getNavigationItems(id) {
        Request.get(`web/publish-list/${id}/items`).exec()
            .then(res => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.NAVIGATION_ITEMS.GET.SUCCESS,
                    items: Immutable.fromJS(res.body).sortBy(r => r.get('displayOrder')),
                });
            })
            .catch(err => {
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
                throw err;
            });
        return;
    }

    getUsers(id, filters, offset=0, size=20, sortFieldName='name', sortDirection='asc') {
        let query = {};
        if (filters.name) {
            query.name = filters.name;
        }

        const preloaderSource = 'homepage-actions.getUsers';
        PreloaderActions.show(preloaderSource);

        Request.get(`web/homepage/${id}/user`).query({
            offset,
            size,
            'sort-field': sortFieldName,
            'sort-order': sortDirection
        }).query(query).exec().then(usersRes => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.HOMEPAGE.USERS.GET.SUCCESS,
                filters: Immutable.fromJS(filters),
                offset,
                size: usersRes.body.size,
                total: usersRes.body.totalCount,
                users: Immutable.fromJS(usersRes.body.results)
            });

            return;
        }).then(()=>{
            PreloaderActions.hide(preloaderSource);
            return;
        }).catch(e => {
            PreloaderActions.hide(preloaderSource);
            throw e;
        });

        return;
    }

    remove(id) {
        const preloaderSource = 'homepage-actions.remove';
        PreloaderActions.show(preloaderSource);

        Request.del(`web/homepage/${id}`)
            .exec()
            .then(() => {
                PreloaderActions.hide(preloaderSource);
                Dispatcher.dispatch({
                    actionType: CONSTANTS.HOMEPAGE.REMOVE.SUCCESS
                });

                RouterActions.redirect('/homepage');
                NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'homepages.delete.success');
                return;
            })
            .catch(err => {
                PreloaderActions.hide(preloaderSource);
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'homepages.delete.error');
                throw err;
            });
    }

    removeSection(sectionIndex) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SECTION.REMOVE,
            sectionIndex
        });
    }

    save(homepage, originalHomepage) {
        let dataHomepage = homepage.toJS();
        // Remove extra attribtues.
        ['groups', 'history', 'partners', 'sections'].forEach(p => delete dataHomepage[p]);

        let activeValue = 0;
        if (dataHomepage.active) {
            activeValue = 1;
        }
        dataHomepage.active = activeValue;

        // Assume POST.
        let method = Request.post;
        let uri = 'web/homepage';
        // Check if PUT.
        let id = dataHomepage.homePageId;
        if (id !== undefined) {
            method = Request.put;
            uri = `web/homepage/${id}`;
        }

        // Create Default Messages
        const defaults = {
            messages: {
                error: 'homepages.save.error',
                success: 'homepages.save.success'
            }
        };

        const preloaderSource = 'homepage-actions.save';
        PreloaderActions.show(preloaderSource);

        method(uri)
            .send(dataHomepage)
            .exec()
            .then(res => {
                id = res.body.homePageId;

                let requests = [];
                if (homepage.get('groups') !== originalHomepage.get('groups')) {
                    // Add/Remove groups by sending a PUT with the list of ids.
                    requests.push({
                        data: homepage.get('groups').map(g => g.get('id')).toArray(),
                        method: 'put',
                        url: `web/homepage/${id}/user-group`
                    });
                }

                if (homepage.get('sections') !== originalHomepage.get('sections')) {
                    // Remove sections.
                    requests.push(...ListDiff(originalHomepage.get('sections'), homepage.get('sections'), 'publishListId', 'sectionType', 'displayOrder').map(
                        s => {
                            return {
                                method: 'del',
                                url: `web/homepage/section/${s.get('homePageSectionId')}`
                            };
                        }
                    ).toArray());

                    // Add sections.
                    requests.push(...ListDiff(homepage.get('sections'), originalHomepage.get('sections'), 'publishListId', 'sectionType', 'displayOrder').map(
                        s => {
                            return {
                                data: {
                                    publishListId: s.get('publishListId'),
                                    sectionType: s.get('sectionType'),
                                    displayOrder: s.get('displayOrder')
                                },
                                method: 'post',
                                url: `web/homepage/${id}/section`
                            };
                        }
                    ).toArray());
                }

                if (homepage.get('partners') !== originalHomepage.get('partners')) {
                    // Add/Remove partners by sending a PUT with the list of ids.
                    requests.push({
                        data: homepage.get('partners').map(p => p.get('id')).toArray(),
                        method: 'put',
                        url: `web/homepage/${id}/partner`
                    });
                }

                return Promise.mapSeries(requests, (r) => {
                    let req = Request[r.method](r.url);
                    if (r.data) {
                        req.send(r.data);
                    }
                    return req.exec();
                });
            }).then(() => {
                PreloaderActions.hide(preloaderSource);
                Dispatcher.dispatch({
                    actionType: CONSTANTS.HOMEPAGE.SAVE.SUCCESS
                });

                this.findById(id);
                RouterActions.redirect(`/homepage/${id}`, true);
                NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, defaults.messages.success);
                return;
            })
            .catch(err => {
                PreloaderActions.hide(preloaderSource);
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, defaults.messages.error);
                throw err;
            });
        return;
    }

    setFilter(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.FILTER.SET,
            attr,
            value
        });
    }

    update(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.HOMEPAGE.UPDATE,
            attr: attr,
            value: value
        });

        return;
    }
}

let actions = new HomepageActions();

export {
    actions as HomepageActions,
    CONSTANTS as HomepageConstants,
    SECTIONS as HomepageSections
};
