/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {BrandSelectConstants} from './brand-select-actions';
import Dispatcher from '../../dispatcher/dispatcher';
import LookupStore from '../lookup-store';

class BrandSelectStore extends LookupStore {

    constructor(dispatcher, CONSTANTS) {
        super(dispatcher, CONSTANTS);
    }
}

let store = new BrandSelectStore(Dispatcher, BrandSelectConstants);

export default store;
