/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

import Dispatcher from '../../dispatcher/dispatcher';
import Request from '../../request';
// import {UserConstants} from '../../user/user-actions';

const CONSTANTS = {
    ACCOUNT_BATCH_SIZE: 20,
    CLEAR: 'account_navigation.clear',
    SET_NAVIGATION: 'account_navigation.set_navigation'
};

class AccountNavigationActions {

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
    }

    setNavigation(id, offset = 0, params) {
        if (!id) {
            return;
        }

        let queryParams = Object.assign({'sort-field-name': 'updatedDate', 'sort-direction': 'desc'}, params.toJS());
        queryParams.offset = offset;
        queryParams.size = CONSTANTS.ACCOUNT_BATCH_SIZE;

        Request.get('user').query(queryParams).exec()
            .then(res => {
                let accounts = Immutable.fromJS(res.body.results);
                let accountIndex = accounts.findIndex(account => account.get('id') === id);
                let next;
                let newOffset = offset + CONSTANTS.ACCOUNT_BATCH_SIZE;
                let prev;
                let hasAccounts = !!accounts.size;

                if (accountIndex !== -1) {
                    newOffset = offset;
                    if (accountIndex !== 0) {
                        prev = accounts.getIn([accountIndex - 1, 'id']);
                    }
                    if (accountIndex + 1 < accounts.size) {
                        next = accounts.getIn([accountIndex + 1, 'id']);
                    }

                    switch (accountIndex) {
                    case 0:
                        if (newOffset !== 0) {
                            newOffset--;
                        }
                        break;
                    case CONSTANTS.ACCOUNT_BATCH_SIZE - 1:
                        newOffset++;
                        break;
                    }
                }
                Dispatcher.dispatch({
                    actionType: CONSTANTS.SET_NAVIGATION,
                    next,
                    newOffset,
                    prev,
                    hasAccounts
                });
                return;
            }).catch(err => {
                throw err;
            });
        return;
    }
}

const actions = new AccountNavigationActions();

export {
    actions as AccountNavigationActions,
    CONSTANTS as AccountNavigationConstants
};
