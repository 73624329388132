/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

const RIGHTS_TYPES = {
    RIGHTS_MEDIA_TYPES: {
        ALL_MEDIA: {id: 'All Media', name: 'All Media'},
        BROADCAST_ONLY: {id: 'Broadcast Only', name: 'Broadcast Only'},
        WEB_ONLY: {id: 'Web Only', name: 'Web Only'},
        SEE_NOTES: {id: 'See Notes', name: 'See Notes'},
    },
    RIGHTS_TERRITORY_TYPES: {
        WORLDWIDE: {id: 'Worldwide', name: 'Worldwide'},
        INTERNATIONAL: {id: 'International', name: 'International'},
        US_AND_CANADA: {id: 'U.S. and Canada', name: 'U.S. and Canada'},
        SEE_NOTES: {id: 'See Notes', name: 'See Notes'},
    },
    RIGHTS_TERM_TYPES: {
        PERP: {id: 'Perp', name: 'Perp'},
        SEE_NOTES: {id: 'See Notes', name: 'See Notes'}
    },
    RIGHTS_TYPES: {
        PICTURE_AND_MUSIC: {id: 'Picture and Music', name: 'Picture and Music'},
        PICTURE_ONLY: {id: 'Picture Only', name: 'Picture Only'},
        SEE_NOTES: {id: 'See Notes', name: 'See Notes'}
    },
};

const CONSTANTS = {
    RIGHTS_INFO_TYPES: {
        DEFAULT: {
            id: 'default',
            name: 'Picture and Music, Worldwide, All Media, Perp',
            values: {
                videoRightsType: RIGHTS_TYPES.RIGHTS_TYPES.PICTURE_AND_MUSIC.id,
                videoRightsTerritoryType: RIGHTS_TYPES.RIGHTS_TERRITORY_TYPES.WORLDWIDE.id,
                videoRightsMediaType: RIGHTS_TYPES.RIGHTS_MEDIA_TYPES.ALL_MEDIA.id,
                videoRightsTermType: RIGHTS_TYPES.RIGHTS_TERM_TYPES.PERP.id,
                rightsNotes: '',
            }
        },
        CUSTOM: {id: 'custom', name: 'Custom'}
    },
    ...RIGHTS_TYPES,
    toArray: function(constant) {
        return Object.keys(this[constant])
            .map(k => this[constant][k])
            .sort((a, b) => a.name.localeCompare(b.name));
    }
};

export {
    CONSTANTS as AssetRightsConstants
};
