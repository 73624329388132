/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router';

export default createReactClass({

    contextTypes: {
        intl: PropTypes.object.isRequired
    },

    render: function() {
        return (
            <div className="container error-page">
                <h2 className="headline text-yellow"><i className="fas fa-key"></i></h2>
                <div className="error-content">
                    <h3><i className="icon fas fa-exclamation-triangle text-yellow"></i> {this.context.intl.messages['errors.access-denied.title']}</h3>
                    <p>{this.context.intl.messages['errors.access-denied.body.description']}</p>
                    <p>{this.context.intl.messages['errors.access-denied.body.directive']}</p>
                    <p>{this.context.intl.messages['errors.access-denied.body.return-pre-link']} <Link to="/">{this.context.intl.messages['errors.access-denied.body.return-link-text']}</Link>{this.context.intl.messages['errors.access-denied.body.return-post-link']}</p>
                </div>
            </div>
        );
    }
});
