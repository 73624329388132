/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {FormGroup, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {Link} from 'react-router';

import {FormRow, FormSection} from '../../../common/form/form';

class PublishingInfo extends React.Component {
    static get propTypes() {
        return {
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            publishingInfo: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        return;
    }

    render() {
        const {
            catalogs,
            talent,
            titles,
            totalCatalogs,
            totalTalent,
            totalTitles,
            totalUsers
        } = this.props.publishingInfo.toJS();

        let catalogsTooltip = <Tooltip className="publishing-info-tooltip" id="tooltip-catalogs">
            {
                catalogs.map(c => c.name).reduce((r, v, i) => {
                    if (i === 0) {
                        return `${this.context.intl.messages['hardac.timeline.summary.catalogs']}: ${v}`;
                    }
                    if (i === 2 && totalCatalogs > 3) {
                        return `${r}, ${v} +${totalCatalogs - 3} ${this.context.intl.messages['hardac.timeline.summary.publishing-info.tooltip.suffix']}`;
                    }
                    return `${r}, ${v}`;
                }, this.context.intl.messages['hardac.timeline.summary.publishing-info.tooltip.no-items'])
            }
        </Tooltip>;
        let talentTooltip = <Tooltip className="publishing-info-tooltip" id="tooltip-talent">
            {
                talent.map(c => c.fullName).reduce((r, v, i) => {
                    if (i === 0) {
                        return `${this.context.intl.messages['hardac.timeline.summary.talent']}: ${v}`;
                    }
                    if (i === 2 && totalTalent > 3) {
                        return `${r}, ${v} +${totalTalent - 3} ${this.context.intl.messages['hardac.timeline.summary.publishing-info.tooltip.suffix']}`;
                    }
                    return `${r}, ${v}`;
                }, this.context.intl.messages['hardac.timeline.summary.publishing-info.tooltip.no-items'])
            }
        </Tooltip>;
        let titlesTooltip = <Tooltip className="publishing-info-tooltip" id="tooltip-titles">
            {
                titles.map(c => c.name).reduce((r, v, i) => {
                    if (i === 0) {
                        return `${this.context.intl.messages['hardac.timeline.summary.titles']}: ${v}`;
                    }
                    if (i === 2 && totalTitles > 3) {
                        return `${r}, ${v} +${totalTitles - 3} ${this.context.intl.messages['hardac.timeline.summary.publishing-info.tooltip.suffix']}`;
                    }
                    return `${r}, ${v}`;
                }, this.context.intl.messages['hardac.timeline.summary.publishing-info.tooltip.no-items'])
            }
        </Tooltip>;
        let usersTooltipText = this.context.intl.messages['hardac.timeline.summary.publishing-info.tooltip.no-items'];
        if (totalUsers > 0) {
            usersTooltipText = `${totalUsers} ${this.context.intl.messages['hardac.timeline.summary.users']}`;
        }
        let usersTooltip = <Tooltip className="publishing-info-tooltip" id="tooltip-users">
            {usersTooltipText}
        </Tooltip>;

        const editInBrainiac = (
            <a
                className="btn btn-default-outline"
                href={`/hardac/timeline/${this.props.id}/edit-asset`}
                target="_blank">
                <i className="fas fa-edit"></i>&nbsp;{this.context.intl.messages['hardac.timeline.summary.edit.button']}
            </a>
        );

        return (
            <div>
                <h3>
                    <i className="icon-brainiac-logomark"></i>&nbsp;{this.context.intl.messages['hardac.timeline.summary.publishing-info']}
                    <div className="pull-right">
                        {editInBrainiac}
                    </div>
                </h3>
                <p><small>{this.context.intl.messages['hardac.timeline.summary.publishing-info.subtitle']}</small></p>
                <FormSection>
                    <FormRow>
                        <FormGroup className="text-center">
                            <h4><i className="fal fa-newspaper"></i>&nbsp;{this.context.intl.messages['hardac.timeline.summary.titles']}</h4>
                            <OverlayTrigger overlay={titlesTooltip} placement="top" delayShow={300} delayHide={150}>
                                <Link
                                    target="_blank"
                                    title=""
                                    to={`/assets/timeline/${this.props.id}/titles`}>
                                    {totalTitles}
                                </Link>
                            </OverlayTrigger>
                        </FormGroup>
                        <FormGroup className="text-center">
                            <h4><i className="fas fa-book"></i>&nbsp;{this.context.intl.messages['hardac.timeline.summary.catalogs']}</h4>
                            <OverlayTrigger overlay={catalogsTooltip} placement="top" delayShow={300} delayHide={150}>
                                <Link
                                    target="_blank"
                                    title=""
                                    to={`/assets/timeline/${this.props.id}/catalogs`}>
                                    {totalCatalogs}
                                </Link>
                            </OverlayTrigger>
                        </FormGroup>
                        <FormGroup className="text-center">
                            <h4><i className="fas fa-user"></i>&nbsp;{this.context.intl.messages['hardac.timeline.summary.users']}</h4>
                            <OverlayTrigger overlay={usersTooltip} placement="top" delayShow={300} delayHide={150}>
                                <Link
                                    target="_blank"
                                    title=""
                                    to={`/assets/timeline/${this.props.id}/users`}>
                                    {totalUsers}
                                </Link>
                            </OverlayTrigger>
                        </FormGroup>
                        <FormGroup className="text-center">
                            <h4><i className="fas fa-star"></i>&nbsp;{this.context.intl.messages['hardac.timeline.summary.talent']}</h4>
                            <OverlayTrigger overlay={talentTooltip} placement="top" delayShow={300} delayHide={150}>
                                <Link
                                    target="_blank"
                                    title=""
                                    to={`/assets/timeline/${this.props.id}/talent`}>
                                    {totalTalent}
                                </Link>
                            </OverlayTrigger>
                        </FormGroup>
                    </FormRow>
                </FormSection>
            </div>
        );
    }
}

export default PublishingInfo;
