/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';
import {Link} from 'react-router';

export class NameDescriptionRenderer extends Component {
    static get propTypes() {
        return {
            data: PropTypes.object.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);
    }

    render() {
        const catalogDescription = this.props.data?.description || '';
        const catalogName = this.props.data?.name;
        const style = {fontSize: '80%'};
        return (
            <div>
                <div>{catalogName}</div>
                <small className="d-block" style={style}>
                    {this.context.intl.messages['common.description']}: {catalogDescription}
                </small>
            </div>
        );
    }
}

export class ButtonCellRenderer extends Component {
    static get propTypes() {
        return {
            data: PropTypes.object.isRequired,
            onClick: PropTypes.func.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);

    }

    handleClick() {
        this.props.onClick(this.props.data);
    }

    render() {
        return (
            <div>
                <Button
                    bsSize="sm"
                    className="add-catalog bg-wb-blue"
                    onClick={this.handleClick}
                >
                    <i className="fas fa-plus"/>&nbsp;{this.context.intl.messages['common.add']}
                </Button>
            </div>
        );
    }
}

export class NameLinkRenderer extends Component {
    static get propTypes() {
        return {
            data: PropTypes.object.isRequired,
            displayLinks: PropTypes.bool
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            displayLinks: false,
        };
    }

    constructor(props) {
        super(props);
    }

    render() {
        let content = this.props.data?.name;
        if (this.props.displayLinks) {
            content = <Link to={`/setup/authorized-partners/${this.props.data?.id}`}>{content}</Link>;
        }

        return (
            <div>
                {content}
            </div>
        );
    }
}

/* istanbul ignore next */
export class DateMomentRenderer extends Component {
    static get propTypes() {
        return {
            data: PropTypes.object.isRequired,
            format: PropTypes.string.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            displayLinks: false,
        };
    }

    constructor(props) {
        super(props);
    }

    render() {
        const createdDate = Moment(this.props.data?.createdDate);
        let createdDateFormated = '-';
        if (createdDate.isValid()) {
            createdDateFormated = createdDate.format(this.props.format);
        }

        return <div>{createdDateFormated}</div>;
    }
}
