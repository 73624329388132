/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {GenericFetchAction} from '~/src/hardac/cc-editor/cc-editor-actions/generic';
import Request from '~/src/request';

class MachineLearningActions {
    async get(activeFilter?: string, offset?: string) {
        await GenericFetchAction('machine-learning.project.get', async() => {
            const queryParams: Record<string, string | number> = {};
            if (activeFilter) {
                queryParams['active-filter-value'] = activeFilter;
            }
            if (offset) {
                queryParams.offset = offset;
            }
            const {body: {results, totalCount}} = await Request.get(
                'integration/machine-learning/project'
            )
                .query(queryParams)
                .exec();
            return {
                results,
                totalCount
            };
        });
    }
}

export default new MachineLearningActions();
