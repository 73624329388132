import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, Modal} from 'react-bootstrap';

export class ConfirmUserRemovalModal extends Component {
    static get propTypes() {
        return {
            onConfirm: PropTypes.func,
            onHide: PropTypes.func,
            show: PropTypes.bool.isRequired
        };
    }

    static get defaultProps() {
        return {
            onConfirm: () => void 0,
            onHide: () => void 0
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.handleConfirm = this.handleConfirm.bind(this);
    }

    handleConfirm() {
        this.props.onHide();
        this.props.onConfirm();
    }

    render() {
        return <Modal onHide={this.props.onHide} show={this.props.show}>
            <Modal.Header className="alert-danger" closeButton>
                <Modal.Title className="text-center">
                    {this.context.intl.messages['accounts.account-wizard.confirmation.removal.title']}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {this.context.intl.messages['accounts.account-wizard.confirmation.removal.caption']}
            </Modal.Body>

            <Modal.Footer>
                <Button
                    className="pull-left Ml(5) bg-navy"
                    onClick={this.props.onHide}
                >
                    {this.context.intl.messages['common.cancel']}
                </Button>
                <Button
                    bsStyle="danger"
                    type="submit"
                    onClick={this.handleConfirm}
                >
                    {this.context.intl.messages['accounts.account-wizard.confirmation.removal.confirm']}
                </Button>
            </Modal.Footer>
        </Modal>;
    }
}
