/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import createReactClass from 'create-react-class';
import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Alert} from 'react-bootstrap';

import {NotificationActions} from './notification-actions';
import NotificationStore from './notification-store';

const AlertTypes = {
    ALERT_DANGER: {
        bsClass: 'alert-danger',
        icon: 'fas fa-ban',
        name: 'ALERT_DANGER'
    },
    ALERT_INFO: {
        bsClass: 'bg-navy',
        icon: 'fas fa-info-circle',
        name: 'ALERT_INFO'
    },
    ALERT_SUCCESS: {
        bsClass: 'alert-success',
        dismissAfter: 10000,
        icon: 'fas fa-check',
        name: 'ALERT_SUCCESS'
    },
    ALERT_WARNING: {
        bsClass: 'alert-warning',
        icon: 'fas fa-exclamation-triangle',
        name: 'ALERT_WARNING'
    }
};

class FixedAlert extends Component {

    static get propTypes() {
        return {
            children: PropTypes.element
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            children: undefined
        };
    }

    static calculateState() {
        return {
            correction: 0,
            notifications: NotificationStore.getState().get('notifications')
        };
    }

    static getStores() {
        return [NotificationStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        return;
    }

    componentDidUpdate(oldProps, oldState) {
        if (this.state.notifications.size !== oldState.notifications.size) {
            setTimeout( () => {
                // Prevent TypeError: Cannot read properties of undefined (reading 'getBoundingClientRect') during auto logout due to session expiration
                if (this.refs.fixedAlerts) {
                    let h = this.refs.fixedAlerts.getBoundingClientRect().height;
                    this.setState({height: h});
                }
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        let h = this.refs.fixedAlerts.getBoundingClientRect().height;
        this.setState({height: h});
    }

    render() {
        return (
            <div>
                <section className="fixed-alerts content-header" ref="fixedAlerts">
                    <AlertWrapper/>
                </section>
                <div style={{paddingTop:this.state.height}}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

const FixedAlertContainer = Container.create(FixedAlert);

let AlertWrapper = createReactClass({
    propTypes: {
        withHeader: PropTypes.bool
    },

    contextTypes: {
        intl: PropTypes.object.isRequired
    },

    getDefaultProps: function() {
        return {
            withHeader: false,
        };
    },

    getInitialState: function() {
        return this.readState();
    },

    componentWillMount: function() {
        this.storeListeners = [
            NotificationStore.addListener(() => {
                this.clearTimeouts();
                this.setState(this.readState());
                return;
            })
        ];
        return;
    },

    shouldComponentUpdate: function(nextProps, nextState) {
        return this.state.notifications !== nextState.notifications;
    },

    componentWillUnmount: function() {
        this.storeListeners.forEach(listener => listener.remove());
    },

    clearTimeouts: function() {
        this.state.notifications.forEach(n => {
            clearTimeout(n.timeout);
        });
    },

    handleDismiss: function(value) {
        let index = value || 0;
        NotificationActions.hide(index);
        return;
    },

    readState: function() {
        let notifications = [];
        NotificationStore.getState().get('notifications').forEach((n, index) => {
            let notification = {
                alertType: AlertTypes[n.get('type')],
                arguments: n.get('arguments'),
                index: index,
                message: n.get('message')
            };

            // Set timeout for notifications that have dismissAfter value
            if (notification.alertType !== undefined) {
                if (notification.alertType.dismissAfter) {
                    notification.timeout = setTimeout(this.handleDismiss.bind(this, index), AlertTypes[n.get('type')].dismissAfter);
                }
                notifications.push(notification);
            }

        });

        let state = {
            notifications
        };

        return state;
    },

    render: function() {
        if (this.state.notifications.length === 0) {return null;}

        let elem = this.state.notifications.map((notification, i) => {

            let message = this.context.intl.messages[notification.message];
            if (notification.arguments) {
                notification.arguments.forEach((a) => {
                    message = message.replace('{}', a);
                });
            }

            return (
                <Alert
                    bsClass={ClassNames('alert', notification.alertType.bsClass)}
                    key={i}
                    onDismiss={this.handleDismiss.bind(this, notification.index)}
                >
                    <i className={ClassNames(
                        'icon fa',
                        notification.alertType.icon
                    )}></i><span dangerouslySetInnerHTML={{__html: message}}></span>
                </Alert>
            );
        });

        // Wrap in the header if necessary.
        if (this.props.withHeader) {
            return (
                <section className="content-header">{elem}</section>
            );
        }

        return (
            <div>{elem}</div>
        );
    }
});

export {
    AlertTypes,
    AlertWrapper as Alert,
    FixedAlertContainer as FixedAlert
};
