/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import createReactClass from 'create-react-class';
import React from 'react';

import {AudioActions} from './audio-actions';
import AudioStore from './audio-store';
import BaseSelect from '../../../common/base-select/base-select';

export default createReactClass({
    render: function() {
        return (
            <BaseSelect
                {...this.props}
                getter={AudioActions.get.bind(AudioActions, 0, 9999)}
                store={AudioStore}
            />
        );
    }
});
