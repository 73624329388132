/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {PushNotificationConstants} from './push-notification-actions';
import Validations from '../common/validations/validations';
import Dispatcher from '../dispatcher/dispatcher';

const PushNotificationStoreValidations = {
    messageTitle: {label: 'push-notifications.edit.message-title', validations: [Validations.required, Validations.max(20)]},
    messageBody: {label: 'push-notifications.edit.message-body', validations: [Validations.required, Validations.max(160)]}
};

class PushNotificationStore extends ReduceStore {

    getInitialState() {
        return Immutable.Map({
            offset: 0,
            originalPushNotification: Immutable.Map({
                isActive: true
            }),
            partners: Immutable.List(),
            pushNotification: Immutable.Map({
                history: Immutable.List(),
                isActive: true,
                userGroups: Immutable.OrderedSet()
            }),
            pushNotificationAsset: Immutable.Map(),
            pushNotificationTitle: Immutable.Map(),
            pushNotifications: Immutable.List(),
            preloaderVisible: false,
            showSendModal: false,
            size: 0,
            total: 0,
            users: Immutable.Map({
                filters: Immutable.Map(),
                offset: 0,
                results: Immutable.List(),
                size: 20,
                totalCount: 0
            })
        });
    }

    getValidations() {
        return Validations.validate(this.getState().get('pushNotification'), PushNotificationStoreValidations);
    }

    reduce(state, action) {
        switch (action.actionType) {
        case PushNotificationConstants.CLEAR:
            state = this.getInitialState();
            break;
        case PushNotificationConstants.HIDE_SEND_MODAL:
            state = state.set('showSendModal');
            break;
        case PushNotificationConstants.PUSH_NOTIFICATION.CLEAR_TARGET:
            state = state.merge({
                'pushNotificationAsset': Immutable.Map(),
                'pushNotificationTitle': Immutable.Map()
            });
            state = state.mergeIn(['pushNotification'], {
                'mediaAssetId': undefined,
                'titleId': undefined
            });
            break;
        case PushNotificationConstants.PUSH_NOTIFICATION.GET.ERROR:
        case PushNotificationConstants.PUSH_NOTIFICATION.FIND_BY_ID.ERROR:
        case PushNotificationConstants.PUSH_NOTIFICATION.PARTNER.GET.ERROR:
        case PushNotificationConstants.PUSH_NOTIFICATION.SAVE.ERROR:
        case PushNotificationConstants.PUSH_NOTIFICATION.USERS.GET.ERROR:
            state = state.set('showPreloader', false);
            break;
        case PushNotificationConstants.PUSH_NOTIFICATION.GET.SUCCESS:
            state = state.merge({
                offset: action.offset,
                pushNotifications: action.pushNotifications,
                showPreloader: false,
                size: action.size,
                total: action.total
            });
            break;
        case PushNotificationConstants.PUSH_NOTIFICATION.FIND_BY_ID.SUCCESS:
            state = state.merge({
                pushNotification: action.pushNotification,
                originalPushNotification: action.pushNotification,
                showPreloader: false
            });
            break;
        case PushNotificationConstants.PUSH_NOTIFICATION.PARTNER.GET.SUCCESS:
            state = state.merge({'partners': action.partners});
            break;
        case PushNotificationConstants.PUSH_NOTIFICATION.SEND.ERROR:
            state = state.setIn(['originalPushNotification', 'sendDescription'], 'send');
            state = state.merge({
                showSendModal: false,
                showPreloader: false
            });
            break;
        case PushNotificationConstants.PUSH_NOTIFICATION.SAVE.SUCCESS:
            state = state.merge({
                originalPushNotification: state.get('pushNotification'),
                showPreloader: false
            });
            break;
        case PushNotificationConstants.PUSH_NOTIFICATION.SEND.SUCCESS:
            state = state.merge({
                showSendModal: false,
                showPreloader: false,
            });
            state = state.setIn(['originalPushNotification', 'sendDescription'], 'send');
            break;

        case PushNotificationConstants.PUSH_NOTIFICATION.GET.START:
        case PushNotificationConstants.PUSH_NOTIFICATION.FIND_BY_ID.START:
        case PushNotificationConstants.PUSH_NOTIFICATION.PARTNER.GET.START:
        case PushNotificationConstants.PUSH_NOTIFICATION.SAVE.START:
        case PushNotificationConstants.PUSH_NOTIFICATION.SEND.START:
        case PushNotificationConstants.PUSH_NOTIFICATION.USERS.GET.START:
            state = state.set('showPreloader', true);
            break;

        case PushNotificationConstants.PUSH_NOTIFICATION.UPDATE:
            state = state.setIn(['pushNotification', ...action.attr.split('.')], action.value);
            break;
        case PushNotificationConstants.PUSH_NOTIFICATION.UPDATE_ASSET:
            state = state.set('pushNotificationAsset', action.value);
            break;
        case PushNotificationConstants.PUSH_NOTIFICATION.UPDATE_TITLE:
            state = state.set('pushNotificationTitle', action.value);
            break;
        case PushNotificationConstants.PUSH_NOTIFICATION.USERS.GET.SUCCESS:
            state = state.mergeIn(['users'], {
                filters: action.filters,
                offset: action.offset,
                results: action.users,
                size: action.size,
                total: action.total
            });
            break;
        case PushNotificationConstants.SHOW_SEND_MODAL:
            state = state.set('showSendModal', true);
            break;
        default:
            break;
        }

        return state;
    }
}

let store = new PushNotificationStore(Dispatcher);

export default store;

export {PushNotificationStoreValidations};
