/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

let activeStatus = {
    ACTIVE:{id:1, apiName:'ACTIVE', name:'Active'},
    BOTH:{id:2, apiName:'BOTH', name:'Both'},
    INACTIVE:{id:3, apiName:'INACTIVE', name:'Inactive'}
};

let status = {
    INACTIVE:{id:1, apiName:'INACTIVE', statusStyle:'status-yellow', bsStyle:'warning', value:'Inactive'},
    ACTIVE:{id:2, apiName:'ACTIVE', statusStyle:'status-blue', bsStyle:'info', value:'Active'},
    MISSING:{id:3, apiName:'MISSING', statusStyle:'status-red', bsStyle:'danger', value:'Missing'},
    PLACEHOLDER:{id:4, apiName:'PLACEHOLDER', statusStyle:'status-yellow', bsStyle:'warning', value:'Placeholder'},
    DONE:{id:5, apiName:'DONE', statusStyle:'status-blue', bsStyle:'info', value:'Done'},
    SENT:{id:6, apiName:'SENT', statusStyle:'status-green', bsStyle:'success', value:'Sent'},
    RECEIVED:{id:7, apiName:'RECEIVED', statusStyle:'status-green', bsStyle:'success', value:'Received'},
    PUBLISHED:{id:8, apiName:'PUBLISHED', statusStyle:'status-green', bsStyle:'success', value:'Published'},
    FAILED:{id:9, apiName:'FAILED', statusStyle:'status-red', bsStyle:'danger', value:'Failed'},
    NO_COVERAGE:{id:10, apiName:'NO_COVERAGE', statusStyle:'status-grey', bsStyle:'default', value:'No coverage'},
    NOT_AVAILABLE:{id:11, apiName:'NOT_AVAILABLE', statusStyle:'status-green', bsStyle:'success', value:'Not Available'},
    TALENT_ADDED:{id:12, apiName:'TALENT_ADDED', statusStyle:'status-green', bsStyle:'success', value:'Talent Added'},
    FILE_UPLOADED:{id:13, apiName:'FILE_UPLOADED', statusStyle:'status-green', bsStyle:'success', value:'File Uploaded'},
    NOT_NEEDED:{id:14, apiName:'NOT_NEEDED', statusStyle:'status-grey', bsStyle:'default', value:'Not needed'},
    STAGED:{id:15, apiName:'STAGED', statusStyle:'status-green', bsStyle:'success', value:'Staged'},
    DONE_FIXED:{id:16, apiName:'DONE_FIXED', statusStyle:'status-blue', bsStyle:'info', value:'Done-Fixed'},
    PROXY_ONLY:{id:17, apiName:'PROXY_ONLY', statusStyle:'status-blue', bsStyle:'info', value:'Proxy Only'},
    NO_SCREENER:{id:18, apiName:'NO_SCREENER', statusStyle:'status-grey', bsStyle:'default', value:'No Screener'},
    NO_MASTER:{id:19, apiName:'NO_MASTER', statusStyle:'status-grey', bsStyle:'default', value:'No master'},
    UNKNOWN:{id:20, apiName:'UNKNOWN', statusStyle:'status-yellow', bsStyle:'warning', value:'Unknown'},
    ADDED:{id:21, apiName:'ADDED', statusStyle:'status-blue', bsStyle:'info', value:'Added'},
    HIDDEN:{id:22, apiName:'HIDDEN', statusStyle:'status-yellow', bsStyle:'warning', value:'Hidden'}
};

let fields = {
    NAME:{id:1, width:'210px', apiName:'NAME', name: 'Name', values:[status.MISSING, status.PLACEHOLDER, status.DONE]},
    MPM:{id:2, width:'48px', apiName:'MPM', name: 'MPM', values:[status.MISSING, status.DONE]},
    ACTIVE:{id:3, width:'60px', apiName:'ACTIVE', name: 'Status', noRemove: true, values:[status.ACTIVE, status.INACTIVE]},
    GALLERY:{id:4, width:'80px', apiName:'GALLERY', name: 'Gallery', values:[status.MISSING, status.SENT, status.RECEIVED, status.PUBLISHED, status.DONE, status.FAILED, status.NO_COVERAGE], headerColor: 'red'},
    KEY_ART:{id:5, width:'90px', apiName:'KEY_ART', name: 'Key Art', values:[status.MISSING, status.SENT, status.RECEIVED, status.PUBLISHED, status.DONE, status.FAILED, status.PLACEHOLDER], headerColor: 'red'},
    DEFAULT_IMAGE:{id:6, width:'120px', apiName:'DEFAULT_IMAGE', name: 'Default Image', values:[status.MISSING, status.PLACEHOLDER, status.DONE], headerColor: 'red'},
    LOGO:{id:7, width:'100px', apiName:'LOGO', name: 'Logo', values:[status.MISSING, status.SENT, status.RECEIVED, status.PUBLISHED, status.DONE, status.FAILED, status.PLACEHOLDER], headerColor: 'red'},
    EPISODIC_UNIT_PHOTO:{id:8, width:'140px', apiName:'EPISODIC_UNIT_PHOTO', name: 'Episodic/Unit Photo', values:[status.MISSING, status.SENT, status.RECEIVED, status.PUBLISHED, status.DONE, status.FAILED, status.NO_COVERAGE], headerColor: 'red'},
    SOCIAL_MEDIA:{id:9, width:'120px', apiName:'SOCIAL_MEDIA', name: 'Social Media', values:[status.MISSING, status.SENT, status.RECEIVED, status.PUBLISHED, status.DONE, status.FAILED], headerColor: 'red'},
    CREDITS:{id:10, width:'100px', apiName:'CREDITS', name: 'Credits', values:[status.MISSING, status.NOT_AVAILABLE, status.TALENT_ADDED, status.FILE_UPLOADED, status.DONE, status.NOT_NEEDED], headerColor: 'green'},
    SCREENER:{id:12, width:'100px', apiName:'SCREENER', name: 'Screener', values:[status.MISSING, status.RECEIVED, status.PUBLISHED, status.DONE, status.DONE_FIXED, status.PROXY_ONLY, status.NO_SCREENER, status.FAILED, status.PLACEHOLDER], headerColor: 'blue'},
    VAM:{id:14, width:'80px', apiName:'VAM', name: 'VAM', values:[status.MISSING, status.RECEIVED, status.FAILED, status.PUBLISHED, status.DONE, status.DONE_FIXED, status.PROXY_ONLY, status.NOT_NEEDED], headerColor: 'blue'},
    TRAILER_PROMO:{id:15, width:'110px', apiName:'TRAILER_PROMO', name: 'Trailer/Promo', values:[status.MISSING, status.PLACEHOLDER, status.PUBLISHED, status.DONE, status.NOT_NEEDED], headerColor: 'blue'},
    PRIF:{id:16, width:'80px', apiName:'PRIF', name: 'PRIF', values:[status.MISSING, status.PUBLISHED, status.DONE, status.NOT_NEEDED], headerColor: 'green'},
    SYNOPSIS:{id:17, width:'80px', apiName:'SYNOPSIS', name: 'Synopsis', values:[status.MISSING, status.PLACEHOLDER, status.PUBLISHED, status.DONE, status.NOT_NEEDED], headerColor: 'green'},
    SHORT_SYNOPSIS:{id:18, width:'110px', apiName:'SHORT_SYNOPSIS', name: 'Short Synopsis', values:[status.MISSING, status.PLACEHOLDER, status.PUBLISHED, status.DONE, status.NOT_NEEDED], headerColor: 'green'},
    LOG_LINE_180:{id:19, width:'100px', apiName:'LOG_LINE_180', name: '180 Log Line', values:[status.MISSING, status.PLACEHOLDER, status.PUBLISHED, status.DONE, status.NOT_NEEDED], headerColor: 'green'},
    LOG_LINE_60:{id:20, width:'100px', apiName:'LOG_LINE_60', name: '60 Log Line', values:[status.MISSING, status.PLACEHOLDER, status.PUBLISHED, status.DONE, status.NOT_NEEDED], headerColor: 'green'},
    PRESS_KIT:{id:21, width:'100px', apiName:'PRESS_KIT', name: 'Press Kit', values:[status.MISSING, status.PUBLISHED, status.DONE, status.NOT_NEEDED], headerColor: 'green'},
    RELEASE_DATE:{id:22, width:'109px', apiName:'RELEASE_DATE', name: 'Release Date', values:[status.MISSING, status.PLACEHOLDER, status.DONE]},
    RELEASE_COMPANY:{id:23, width:'140px', apiName:'RELEASE_COMPANY', name: 'Release Company', values:[status.MISSING, status.DONE, status.NOT_NEEDED], headerColor: 'yellow'},
    RELEASE_TIME:{id:24, width:'100px', apiName:'RELEASE_TIME', name: 'Release Time', values:[status.MISSING, status.DONE, status.PLACEHOLDER, status.NOT_NEEDED], headerColor: 'yellow'},
    GENRE:{id:25, width:'80px', apiName:'GENRE', name: 'Genre', values:[status.MISSING, status.DONE], headerColor: 'gray'},
    THEMES:{id:26, width:'80px', apiName:'THEMES', name: 'Themes', values:[status.MISSING, status.DONE], headerColor: 'gray'},
    CATALOGS:{id:27, width:'80px', apiName:'CATALOGS', name: 'Catalogs', values:[status.MISSING, status.DONE, status.ADDED], headerColor: 'gray'},
    MASTERING_INFO:{id:28, width:'120px', apiName:'MASTERING_INFO', name: 'Mastering Info', values:[status.UNKNOWN, status.NOT_NEEDED, status.DONE], headerColor: 'gray'},
    LAST_UPDATED:{id:29, width:'109px', apiName:'LAST_UPDATED', name: 'Last Updated'},
    SCRIPTS:{id:30, width:'100px', apiName:'SCRIPTS', name: 'Scripts', values:[status.MISSING, status.PUBLISHED], headerColor: 'green'},
    RELEASE_COMPANY_PLATFORM:{id:31, width:'200px', apiName:'RELEASE_COMPANY_PLATFORM', name: 'Release Company/Platform'},
};

const synopsisMetadataFields = [
    Object.assign({propName: 'synopsis'}, fields.SYNOPSIS),
    Object.assign({propName: 'shortSynopsis'}, fields.SHORT_SYNOPSIS),
    Object.assign({propName: 'log180Lines'}, fields.LOG_LINE_180),
    Object.assign({propName: 'log60Lines'}, fields.LOG_LINE_60),
];

let episode = {
    titleTypeDescription: 'Episode',
    roDescription: '',
    fields: [
        fields.NAME,
        fields.MPM,
        fields.DEFAULT_IMAGE,
        fields.EPISODIC_UNIT_PHOTO,
        fields.SOCIAL_MEDIA,
        fields.CREDITS,
        fields.SCREENER,
        fields.VAM,
        fields.TRAILER_PROMO,
        fields.SYNOPSIS,
        fields.SHORT_SYNOPSIS,
        fields.LOG_LINE_180,
        fields.LOG_LINE_60,
        fields.RELEASE_DATE,
        fields.RELEASE_COMPANY,
        fields.RELEASE_TIME,
        fields.CATALOGS,
        fields.LAST_UPDATED,
        fields.SCRIPTS,
        fields.THEMES,
        fields.MASTERING_INFO,
        fields.KEY_ART,
    ]
};

let season = {
    titleTypeDescription: 'Season',
    roDescription: '',
    fields: [
        fields.NAME,
        fields.MPM,
        fields.GALLERY,
        fields.KEY_ART,
        fields.DEFAULT_IMAGE,
        fields.LOGO,
        fields.SOCIAL_MEDIA,
        fields.CREDITS,
        fields.TRAILER_PROMO,
        fields.PRIF,
        fields.SYNOPSIS,
        fields.SHORT_SYNOPSIS,
        fields.PRESS_KIT,
        fields.RELEASE_DATE,
        fields.RELEASE_COMPANY,
        fields.RELEASE_TIME,
        fields.GENRE,
        fields.THEMES,
        fields.CATALOGS,
        fields.MASTERING_INFO,
        fields.LAST_UPDATED,
        fields.SCRIPTS,
        fields.LOG_LINE_180,
        fields.LOG_LINE_60,
    ]
};

let serie = {
    titleTypeDescription: 'Series',
    roDescription: 'SERIES',
    fields: [
        fields.NAME,
        fields.MPM,
        fields.GALLERY,
        fields.KEY_ART,
        fields.DEFAULT_IMAGE,
        fields.LOGO,
        fields.SOCIAL_MEDIA,
        fields.CREDITS,
        fields.TRAILER_PROMO,
        fields.PRIF,
        fields.SYNOPSIS,
        fields.SHORT_SYNOPSIS,
        fields.RELEASE_DATE,
        fields.RELEASE_COMPANY,
        fields.RELEASE_TIME,
        fields.GENRE,
        fields.THEMES,
        fields.CATALOGS,
        fields.MASTERING_INFO,
        fields.LAST_UPDATED,
        fields.SCRIPTS,
        fields.LOG_LINE_180,
        fields.LOG_LINE_60,
    ]
};

let singleRelease = {
    titleTypeDescription: 'Theatrical Feature',
    roDescription: 'FEATURE',
    fields: [
        fields.NAME,
        fields.MPM,
        fields.GALLERY,
        fields.KEY_ART,
        fields.DEFAULT_IMAGE,
        fields.LOGO,
        fields.EPISODIC_UNIT_PHOTO,
        fields.SOCIAL_MEDIA,
        fields.CREDITS,
        fields.SCREENER,
        fields.TRAILER_PROMO,
        fields.PRIF,
        fields.SYNOPSIS,
        fields.SHORT_SYNOPSIS,
        fields.PRESS_KIT,
        fields.RELEASE_DATE,
        fields.RELEASE_COMPANY,
        fields.GENRE,
        fields.THEMES,
        fields.CATALOGS,
        fields.MASTERING_INFO,
        fields.LAST_UPDATED,
        fields.SCRIPTS,
        fields.LOG_LINE_180,
        fields.LOG_LINE_60,
    ]
};

let event = {
    titleTypeDescription: 'Event',
    roDescription: 'EVENT',
    fields: [
        fields.NAME,
        fields.MPM,
        fields.DEFAULT_IMAGE,
        fields.EPISODIC_UNIT_PHOTO,
        fields.CATALOGS,
        fields.LAST_UPDATED,
        fields.SCRIPTS,
        fields.THEMES,
        fields.MASTERING_INFO,
        fields.KEY_ART,
        fields.LOG_LINE_180,
        fields.LOG_LINE_60,
    ]
};

let segment = {
    titleTypeDescription: 'Segment',
    roDescription: 'SEGMENT',
    fields: [
        fields.NAME,
        fields.MPM,
        fields.DEFAULT_IMAGE,
        fields.EPISODIC_UNIT_PHOTO,
        fields.CREDITS,
        fields.SCREENER,
        fields.SYNOPSIS,
        fields.LOG_LINE_180,
        fields.LOG_LINE_60,
        fields.RELEASE_DATE,
        fields.RELEASE_COMPANY,
        fields.RELEASE_TIME,
        fields.CATALOGS,
        fields.LAST_UPDATED,
        fields.SCRIPTS,
        fields.THEMES,
        fields.MASTERING_INFO,
        fields.KEY_ART,
    ]
};

let titleTypes = {
    THEATRICAL_FEATURES: {id: 1, titleType: singleRelease},
    ANIMATED_FEATURES: {id: 2, titleType: singleRelease},
    MADE_FOR_VIDEO_FEATURES: {id: 3, titleType: singleRelease},
    SEASON_HALF_HOUR: {id: 4, titleType: season},
    SEASON_ONE_HOUR: {id: 5, titleType: season},
    TALK_SHOW_SEASON: {id: 6, titleType: season},
    GAME_SHOW_SEASON: {id: 7, titleType: season},
    ANIMATED_SERIES_SEASON: {id: 8, titleType: season},
    CARTOONS: {id: 9, titleType: serie},
    MINI_SERIES: {id: 10, titleType: serie},
    NETWORK: {id: 11, titleType: singleRelease},
    CABLE: {id: 12, titleType: singleRelease},
    PAY_TV: {id: 13, titleType: singleRelease},
    SPECIALS: {id: 14, titleType: singleRelease},
    ANIMATED_SPECIALS: {id: 15, titleType: singleRelease},
    COMEDY_SPECIALS: {id: 16, titleType: singleRelease},
    MUSIC_SPECIALS: {id: 17, titleType: singleRelease},
    SPORTS: {id: 18, titleType: singleRelease},
    DOCUMENTARIES: {id: 19, titleType: singleRelease},
    SHORT_PROGRAMS: {id: 20, titleType: serie},
    MAKING_OF: {id: 21, titleType: singleRelease},
    EPISODE: {id: 23, titleType: episode},
    SERIES_HALF_HOUR: {id: 24, titleType: serie},
    SERIES_ONE_HOUR: {id: 25, titleType: serie},
    TALK_SHOW: {id: 26, titleType: serie},
    GAME_SHOW: {id: 27, titleType: serie},
    ANIMATED_SERIES: {id: 28, titleType: serie},
    FORMAT_RIGHTS: {id: 29, titleType: singleRelease},
    REALITY: {id: 30, titleType: serie},
    REALITY_SEASON: {id: 31, titleType: episode},
    DIRETC_TO_VIDEO: {id: 32, titleType: singleRelease},
    ANIMATION: {id: 33, titleType: singleRelease},
    CARTOONS_SEASON: {id: 34, titleType: season},
    SHORT_PROGRAMS_SEASON: {id: 35, titleType: season},
    EVENT: {id: 36, titleType: event},
    OTHER_PRODUCTS: {id: 37, titleType: singleRelease},
    SEGMENT: {id: 38, titleType: segment},
    PROGRAMMING_PACKAGE: {id: 39, titleType: singleRelease},
    HBO_FILMS: {id: 40, titleType: singleRelease},
    DOCUMENTARY_FILMS: {id: 41, titleType: singleRelease},
    LIMITED_SERIES: {id: 42, titleType: serie},
    DOCUSERIES: {id: 43, titleType: serie},
    LIMITED_SERIES_SEASON: {id: 44, titleType: season},
    DOCUSERIES_SEASON: {id: 45, titleType: season},
    WB_HBO_MAX_RELEASE: {id: 46, titleType: singleRelease},
};

let titleTypesMap = Object.keys(titleTypes).reduce( (o, k) => {
    o[titleTypes[k].id] = titleTypes[k];
    return o;
}, {});

let titleTypesFilterValues = [
    {id: 1, name: 'Single Release'},
    {id: 2, name: 'Series/Season'},
    {id: 3, name:'Episode'},
    {id: 4, name:'Segment'},
    {id: 5, name:'Event'}
];

let relatedTitlesFilterValues = [
    {id: 'parents', name: 'Parents'},
    {id: 'children', name: 'Children'},
    {id: 'related', name: 'Related'}
];

let titleStatusOrderValues = [
    {id: 'PARENT_NAME_ASC', name: 'show (A-Z)'},
    {id: 'SEASON_NUMBER_ASC', name: 'season asc'},
    {id: 'EPISODE_NUMBER_ASC', name:'episode asc'},
    {id: 'RUNNING_ORDER_ASC', name:'running order asc'},
    {id: 'RELEASE_DATE_ASC', name:'release date asc'},
    {id: 'PARENT_NAME_DESC', name: 'show (Z-A)'},
    {id: 'SEASON_NUMBER_DESC', name: 'season desc'},
    {id: 'EPISODE_NUMBER_DESC', name:'episode desc'},
    {id: 'RUNNING_ORDER_DESC', name:'running order desc'},
    {id: 'RELEASE_DATE_DESC', name:'release date desc'}
];

export {
    activeStatus as ACTIVE_STATUS,
    fields as TITLE_FIELDS,
    synopsisMetadataFields as SYNOPSIS_METADATA_FIELDS,
    relatedTitlesFilterValues as RELATED_TITLES_FILTER_VALUES,
    status as TITLE_FIELDS_STATUS,
    titleTypes as TITLE_TYPE_FIELDS,
    titleTypesFilterValues as TITLE_TYPE_FILTER_VALUES,
    titleTypesMap as TITLE_TYPE_FIELDS_MAP,
    titleStatusOrderValues as TITLE_STATUS_SORT_ORDER
};
