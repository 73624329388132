/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

const Note = ({note}) => {
    return (
        <div className="row">
            <div className="col-md-12">
                <div>
                    <blockquote>
                        <i className="fas fa-quote-left"/>&nbsp;{note.changeDescription}
                    </blockquote>
                </div>
            </div>
        </div>
    );
};

Note.propTypes = {
    note: PropTypes.instanceOf(Immutable.Map).isRequired
};

export default Note;
