/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import jQuery from 'jquery';
import PropTypes from 'prop-types';
import React from 'react';
import {Button} from 'react-bootstrap';

import AddTitlePanel from './add-title-panel.js';
import {SubscriptionsActions, SubscriptionsConstants} from './subscriptions-actions';

import {Sort} from '~/src/common/local-search/filter';
import SlidingPanel from '~/src/common/sliding-panel/sliding-panel';
import {SlidingPanelActions} from '~/src/common/sliding-panel/sliding-panel-actions';
import {TitleConstants} from '~/src/titles/title-actions';

require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('~/src/styles/data-tables-brainiac.css');
// Load jQuery and register the datatables plugin.
require('datatables.net-responsive-bs');

class ListSubscriptions extends React.Component {

    static get propTypes() {
        return {
            label: PropTypes.string.isRequired,
            location: PropTypes.object.isRequired,
            subscriptions: PropTypes.instanceOf(Immutable.List).isRequired,
            titlesToAdd: PropTypes.instanceOf(Immutable.List).isRequired,
            totalCount: PropTypes.number.isRequired,
            userId: PropTypes.string.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            isSubscriptionTypeDirty: false,
            sortBy: 'userName',
            sortDir: 'asc',
            subscriptionIndex: undefined,
            selectedSubscriptionType: undefined
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.addSubscriptions = this.addSubscriptions.bind(this);
        this.handleChangeSubscriptionType = this.handleChangeSubscriptionType.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.handleSaveAndClose = this.handleSaveAndClose.bind(this);
        this.updateRows = this.updateRows.bind(this);
    }

    componentDidMount() {
        this.$table = jQuery(`#user-subscriptions-table-${this.props.userId}`);
        this.$tableAPI = this.$table.DataTable({
            autoWidth: false,
            columnDefs: [{
                // Add the control class to the last column. This colum will
                // contain the button to show the responsive data.
                className: 'control',
                targets:   -1,
                width: 20
            }, {
                className: 'actions',
                targets: 3,
                width: 20
            }],
            iDisplayLength: 20,
            info: true,
            ordering: false,
            lengthChange: false,
            paging: true,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: true
        });
        this.updateRows(this.props, this.state);
        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.location.query !== this.props.location.query ||
            nextProps.subscriptions !== this.props.subscriptions ||
            nextProps.titlesToAdd !== this.props.titlesToAdd ||
            nextProps.totalCount !== this.props.totalCount ||
            nextProps.userId !== this.props.userId ||
            nextState.selectedSubscriptionType !== this.state.selectedSubscriptionType) {
            return true;
        }
        return false;
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.location.query !== this.props.location.query ||
            nextProps.subscriptions !== this.props.subscriptions ||
            nextProps.titlesToAdd !== this.props.titlesToAdd ||
            nextProps.totalCount !== this.props.totalCount ||
            nextProps.userId !== this.props.userId ||
            nextState.selectedSubscriptionType !== this.state.selectedSubscriptionType) {
            this.updateRows(nextProps, nextState);
            return;
        }

        return;
    }

    componentWillUnmount() {
        if (this.$tableAPI) {
            this.$tableAPI.destroy();
        }
        return;
    }

    addSubscriptions(panel) {
        SlidingPanelActions.show(panel);
    }

    handleCancelSlidingPanel() {
        SubscriptionsActions.clearTitles();
        return;
    }

    handleChangeSubscriptionType(value) {
        if (!this.state.isSubscriptionTypeDirty) {
            this.setState({isSubscriptionTypeDirty: true});
        }

        this.setState({
            selectedSubscriptionType: value,
            isSubscriptionTypeDirty: !!value && !!value.id
        });
    }

    /**
     * This function is kind of "special" because it needs to handle
     * events bubbled from the data table rows, these rows cannot use
     * the JSX syntax because they are created by the data table
     * jQuery plugin instead of React.
     */
    handleRowClick(event) {
        switch (true) {
        // Handle click on a remove button.
        case !!~event.target.className.indexOf('remove-subscription'):
            SubscriptionsActions.remove(parseInt(event.target.getAttribute('data-index'), 10));
            break;
        }

        return;
    }

    handleSaveAndClose() {
        SubscriptionsActions.save(this.props.titlesToAdd, this.state.selectedSubscriptionType);
        SlidingPanelActions.hide(`add-user-subscription-${this.props.userId}`);
        return;
    }

    /*istanbul ignore next*/
    updateRows(props, state) {
        this.$tableAPI.clear();

        let subscriptions = props.subscriptions;
        subscriptions = Sort(subscriptions, state.sortBy, state.sortDir);

        // Add sorted data to the jQuery datatable.
        subscriptions.forEach((s, i) => {
            let titleName = s.get('titleName') || '';
            switch (s.get('categoryId')) {
            case TitleConstants.TITLE_TYPES.ANIMATED_SERIES_SEASON.id:
            case TitleConstants.TITLE_TYPES.CARTOONS_SEASON.id:
            case TitleConstants.TITLE_TYPES.DOCUSERIES_SEASON.id:
            case TitleConstants.TITLE_TYPES.GAME_SHOW_SEASON.id:
            case TitleConstants.TITLE_TYPES.LIMITED_SERIES_SEASON.id:
            case TitleConstants.TITLE_TYPES.REALITY_SEASON.id:
            case TitleConstants.TITLE_TYPES.SEASON_HALF_HOUR.id:
            case TitleConstants.TITLE_TYPES.SEASON_ONE_HOUR.id:
            case TitleConstants.TITLE_TYPES.SHORT_PROGRAMS_SEASON.id:
            case TitleConstants.TITLE_TYPES.TALK_SHOW_SEASON.id:
                titleName = `${titleName} - Season ${s.get('seasonNumber')}`;
                break;
            case TitleConstants.TITLE_TYPES.EPISODE.id:
                titleName = `${titleName} - ${s.get('episodeData')} E${s.get('episodeNumber')}`;
                break;
            }

            const subTypes = SubscriptionsConstants.toArray(SubscriptionsConstants.SUBSCRIPTION_CONTENT_TYPES);
            const subscriptionType = subTypes.find(st => st.get('id') === s.get('subscriptionContentType')) || Immutable.Map();

            this.$tableAPI.row.add([
                `<a href="/titles/${s.get('titleId')}" target="_blank">${titleName}</a>`,
                subscriptionType.get('name') || '',
                s.get('suggestedByFullName') || '',
                `<button class="btn bg-navy remove-subscription" data-index="${i}"><i class="fas fa-trash-alt"></i>&nbsp;Unsubscribe</button>`,
                ''
            ]);
            return;
        });

        this.$tableAPI.draw(false);
        return;
    }

    render() {
        let addTitlePanelId = `add-user-subscription-${this.props.userId}`;
        let addTitlePanel = <AddTitlePanel
            location={this.props.location}
            onChangeSubscriptionType={this.handleChangeSubscriptionType}
            selectedSubscriptionType={this.state.selectedSubscriptionType}
            slidingPanelId={addTitlePanelId}
            userId={this.props.userId}/>;
        let addTitlePanelTitle = <span>{this.context.intl.messages['accounts.create.subscriptions.add-subscription.title']}</span>;

        const addSubscriptionButton = <Button className="btn btn-large bg-green"
            onClick={this.handleSaveAndClose}
            disabled={!this.state.isSubscriptionTypeDirty || !this.props.titlesToAdd.size}
        >
            <i className="fas fa-plus"></i>&nbsp;{this.context.intl.messages['accounts.create.subscriptions.add-subscriptions.btn']}
        </Button>;

        return (
            <div>
                <SlidingPanel
                    extraButtons={addSubscriptionButton}
                    icon="fas fa-plus-circle"
                    id={addTitlePanelId}
                    onCancel={this.handleCancelSlidingPanel}
                    subscriptions={this.props.subscriptions}
                    title={addTitlePanelTitle}
                >
                    {addTitlePanel}
                </SlidingPanel>
                <h3>{this.props.label}&nbsp;<Button bsStyle="primary" className="pull-right" onClick={this.addSubscriptions.bind(this, addTitlePanelId)}><i className="fas fa-plus-square"></i>&nbsp;{this.context.intl.messages['accounts.create.subscriptions-list.add-subscription']}</Button></h3>
                <table id={`user-subscriptions-table-${this.props.userId}`} className="table table-bordered table-striped responsive">
                    <thead>
                        <tr>
                            <th>{this.context.intl.messages['accounts.create.subscriptions-list.title-name']}</th>
                            <th>{this.context.intl.messages['accounts.create.subscriptions-list.subscription-type']}</th>
                            <th>{this.context.intl.messages['accounts.create.subscriptions-list.suggested-by']}</th>
                            <th>{this.context.intl.messages['accounts.create.subscriptions-list.remove']}</th>
                            <th className="no-sort"></th>
                        </tr>
                    </thead>
                    <tbody onClick={this.handleRowClick}></tbody>
                </table>
            </div>
        );
    }
}

export default ListSubscriptions;
