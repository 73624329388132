/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';
import Moment from 'moment';

import GuildStore from './guild-store';
import {DownloadActions} from '../common/download/download-actions';
import {AlertTypes} from '../common/notification/alert';
import {NotificationActions} from '../common/notification/notification-actions';
import {UploadFile} from '../common/utils/utils';
import Dispatcher from '../dispatcher/dispatcher';
import Request from '../request';
import {RouterActions} from '../router/router-actions';
import {ActionHistoryConstants} from '../system/action-history/action-history-actions';

const CONSTANTS = {
    CLEAR: 'guild_actions.clear',
    GUILD: {
        DEACTIVATE: {
            ERROR: 'guild_actions.guild.deactivate.error',
            START: 'guild_actions.guild.deactivate.start',
            SUCCESS: 'guild_actions.guild.deactivate.success',
        },
        FIND_BY_ID: {
            ERROR: 'guild_actions.guild.find_by_id.error',
            START: 'guild_actions.guild.find_by_id.start',
            SUCCESS: 'guild_actions.guild.find_by_id.success',
        },
        GET: {
            ERROR: 'guild_actions.guild.get.error',
            START: 'guild_actions.guild.get.start',
            SUCCESS: 'guild_actions.guild.get.success',
        },
        RESET: {
            ERROR: 'guild_actions.guild.reset.error',
            START: 'guild_actions.guild.reset.start',
            SUCCESS: 'guild_actions.guild.reset.success',
        },
        SAVE: {
            ERROR: 'guild_actions.guild.save.error',
            START: 'guild_actions.guild.save.start',
            SUCCESS: 'guild_actions.guild.save.success',
        },
        STATUS: {
            INACTIVE: {
                id: 0,
                description: 'Inactive',
                label: 'bg-red',
                tip: 'guild.summary.status.inactive'
            },
            ACTIVE: {
                id: 1,
                description: 'Active',
                label: 'bg-wb-blue',
                tip: 'guild.summary.status.active'
            }
        },
        SYNC_ALL_ACCOUNTS: {
            ERROR: 'guild_actions.guild.sync_all_accounts.error',
            START: 'guild_actions.guild.sync_all_accounts.start',
            SUCCESS: 'guild_actions.guild.sync_all_accounts.success',
        },
        UPDATE: 'guild_actions.guild.update',
        USER: {
            BULK_ADD: {
                ERROR: 'guild_actions.guild.user.bulk_add.error',
                START: 'guild_actions.guild.user.bulk_add.start',
                SUCCESS: 'guild_actions.guild.user.bulk_add.success',
            },
            BULK_ADD_CSV: {
                ERROR: 'guild_actions.guild.user.bulk_add_csv.error',
                START: 'guild_actions.guild.user.bulk_add_csv.start',
                SUCCESS: 'guild_actions.guild.user.bulk_add_csv.success',
            },
            CHILD_ACCOUNTS_ADD: {
                ERROR: 'guild_actions.guild.user.child_accounts_add.error',
                START: 'guild_actions.guild.user.child_accounts_add.start',
                SUCCESS: 'guild_actions.guild.user.child_accounts_add.success',
            },
            GET: {
                ERROR: 'guild_actions.guild.user.get.error',
                START: 'guild_actions.guild.user.get.start',
                SUCCESS: 'guild_actions.guild.user.get.success',
            }
        },
        guildStatusMap: function(active) {
            if (active) {
                return this.STATUS.ACTIVE;
            }
            return this.STATUS.INACTIVE;
        }
    }
};

class GuildActions {

    bulkAddUsers(guild, count, asAdmin) {
        let data = {
            createAdminAccount: asAdmin,
            guildId: guild.get('id'),
            numberOfAccounts: count,
        };

        Dispatcher.dispatch({
            actionType: CONSTANTS.GUILD.USER.BULK_ADD.START,
        });

        DownloadActions.startDownloadExecution('user/create-anonymous-account', {}, data, 'post')
            .then(() => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GUILD.USER.BULK_ADD.SUCCESS,
                });

                NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'guild.bulk_add.success');
                this.getUsers(guild.get('id'), GuildStore.getState().get('offset'), GuildStore.getState().get('size'));
            }).catch((error) => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GUILD.USER.BULK_ADD.ERROR,
                    error
                });
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'guild.bulk_add.error');
                throw error;
            });
    }

    bulkAddUsersCSV(guild, csvFile) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.GUILD.USER.BULK_ADD_CSV.START,
        });

        UploadFile(
            'POST',
            `user/create-anonymous-account-csv?guild-id=${guild.get('id')}`,
            csvFile,
            new XMLHttpRequest()
        ).then(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.GUILD.USER.BULK_ADD_CSV.SUCCESS,
            });

            NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'guild.bulk_add.success');
            this.getUsers(guild.get('id'), GuildStore.getState().get('offset'), GuildStore.getState().get('size'));
        }).catch((error) => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.GUILD.USER.BULK_ADD_CSV.ERROR,
                error
            });
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'guild.bulk_add.error');
            throw error;
        });
    }

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
    }

    createChildAccounts(guild, csvFile) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.GUILD.USER.CHILD_ACCOUNTS_ADD.START,
        });

        UploadFile(
            'POST',
            `user/create-child-code-login-account-csv?guild-id=${guild.get('id')}`,
            csvFile,
            new XMLHttpRequest()
        ).then(res => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.GUILD.USER.CHILD_ACCOUNTS_ADD.SUCCESS,
            });

            RouterActions.redirect(`/guild/${guild.get('id')}`);
            NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'guild.accounts.child_accounts.success');

            // Start download in a new window
            const body = JSON.parse(res);
            window.open(`/download/${body.downloadExecutionId}?type=child-accounts`, '_blank');
        }).catch((error) => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.GUILD.USER.CHILD_ACCOUNTS_ADD.ERROR,
                error
            });
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'guild.accounts.child_accounts.error');
            throw error;
        });
    }


    deactivate(guild) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.GUILD.DEACTIVATE.START
        });
        let data = guild.toJS();
        data.active = false;
        Request.put(`guild/${data.id}`).send(data).exec().then(res => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.GUILD.DEACTIVATE.SUCCESS,
                guild: Immutable.fromJS(res.body)
            });
            NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'guild.edit.deactivate.success');
        }).catch((error) => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.GUILD.DEACTIVATE.ERROR,
                error,
            });
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'guild.edit.deactivate.error');
            throw error;
        });
    }

    findById(id) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.GUILD.FIND_BY_ID.START,
        });

        const actionHistoryQuery = {
            'action-object': ActionHistoryConstants.ACTION_OBJECTS.GUILD,
            'object-id': id,
            offset: 0,
            size: 4
        };

        Promise.all([
            Request.get(`guild/${id}`).exec(),
            Request.get('system/action-history').query(actionHistoryQuery).exec()
        ]).spread((guildRes, historyRes) => {
            const guild = guildRes.body;
            let history = historyRes.body.results;
            history.sort((h1, h2) => h2.actionDate.localeCompare(h1.actionDate));
            Dispatcher.dispatch({
                actionType: CONSTANTS.GUILD.FIND_BY_ID.SUCCESS,
                guild: Immutable.fromJS(guild),
                history: Immutable.fromJS(history)
            });
            this.getUsers(id);
        }).catch((error) => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.GUILD.FIND_BY_ID.ERROR,
                error
            });

            RouterActions.redirect('/404');
            throw error;
        });
    }

    get(offset, size, filter, showInactive, startDate, endDate) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.GUILD.GET.START,
        });

        Request.get('guild')
            .query({
                active: !showInactive,
                'from-date': startDate,
                name: filter,
                offset,
                size,
                'to-date': endDate,
            })
            .exec()
            .then(res => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GUILD.GET.SUCCESS,
                    guilds: res.body.results,
                    offset: res.body.offset,
                    size: res.body.size,
                    total: res.body.totalCount,
                });
            }).catch((error) => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GUILD.GET.ERROR,
                    error
                });

                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'guild.get.error');
                throw error;
            });
    }

    getUsers(guildId, offset = 0, size = 15) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.GUILD.USER.GET.START,
        });

        Request.get(`guild/${guildId}/user`)
            .query({
                //filter,
                offset,
                size,
            })
            .exec()
            .then(res => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GUILD.USER.GET.SUCCESS,
                    users: res.body.results,
                    offset: res.body.offset,
                    size: res.body.size,
                    total: res.body.totalCount,
                });
            }).catch((error) => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GUILD.USER.GET.ERROR,
                    error
                });

                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'guild.user.get.error');
                throw error;
            });
    }

    reset(guild, resetStartDate, resetEndDate) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.GUILD.RESET.START
        });
        let data = guild.toJS();
        data.active = true;
        data.validStartDate = resetStartDate;
        data.validEndDate = resetEndDate;
        Request.put(`guild/${data.id}/date-range`).send(data).exec().then(res => res).catch((error) => {
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'guild.summary.sync-date-range.reset.error');
            throw error;
        }).then(() => {
            return Request.put(`guild/${data.id}`).send(data).exec().then(res => res).catch((error) => {
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'guild.edit.update.error');
                throw error;
            });
        }).then(res => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.GUILD.SYNC_ALL_ACCOUNTS.SUCCESS
            });
            Dispatcher.dispatch({
                actionType: CONSTANTS.GUILD.RESET.SUCCESS,
                guild: Immutable.fromJS(res.body)
            });
            RouterActions.redirect(`/guild/${res.body.id}`, true);
            NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'guild.edit.reset.success');
        }).catch((error) => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.GUILD.RESET.ERROR,
                error,
            });
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'guild.edit.reset.error');
            throw error;
        });
    }

    save(guild) {
        const data = guild.toJS();
        let method = 'post';
        let url = 'guild';
        let update = data.id !== undefined;

        data.anonymousSignUp = !!data.anonymousSignUp;

        if (update) {
            method = 'put';
            url = `guild/${data.id}`;
        } else {
            // We need to set these values when creating the guild or we would get 422'd otherwise
            data.active = true;
            data.generateId = true;

            // Also only care about the dates during creation.
            data.validStartDate = Moment(data.validStartDate).toISOString();
            data.validEndDate = Moment(data.validEndDate).toISOString();
        }

        Dispatcher.dispatch({
            actionType: CONSTANTS.GUILD.SAVE.START,
        });

        Request[method](url).send(data).exec().then(res => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.GUILD.SAVE.SUCCESS,
                guild: Immutable.fromJS(res.body)
            });

            RouterActions.redirect(`/guild/${res.body.id}`, true);
            let message = 'guild.create.success';
            if (update) {
                message = 'guild.save.success';
            }
            NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, message);
        }).catch((error) => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.GUILD.SAVE.ERROR,
                error,
            });

            let message = 'guild.create.error';
            if (update) {
                message = 'guild.save.error';
            }
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, message);
            throw error;
        });
    }

    syncAllAccounts(guild) {
        const data = guild.toJS();
        data.validStartDate = Moment(data.validStartDate).toISOString();
        data.validEndDate = Moment(data.validEndDate).toISOString();

        Dispatcher.dispatch({
            actionType: CONSTANTS.GUILD.SYNC_ALL_ACCOUNTS.START,
        });
        Request.put(`guild/${data.id}/date-range`).send(data).exec().then(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.GUILD.SYNC_ALL_ACCOUNTS.SUCCESS
            });
            NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'guild.summary.sync-date-range.success');
        }).catch((error) => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.GUILD.SYNC_ALL_ACCOUNTS.ERROR,
                error,
            });
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'guild.summary.sync-date-range.error');
            throw error;
        });
    }

    updateGuild(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.GUILD.UPDATE,
            attr: attr,
            value: value
        });
    }
}

const actions = new GuildActions();

export {
    actions as GuildActions,
    CONSTANTS as GuildConstants
};
