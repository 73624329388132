/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';

import {AlertTypes} from '../../../common/notification/alert';
import {NotificationActions} from '../../../common/notification/notification-actions';
import Dispatcher from '../../../dispatcher/dispatcher';
import {PreloaderActions} from '../../../preloader/preloader-actions';
import Request from '../../../request';
import {TitleStatusActions} from '../../title-status-actions';

const CONSTANTS = {
    ADD: 'title_related_actions.add',
    CLEAR: 'title_related_actions.clear',
    GET: {
        ERROR: 'title_related_actions.get.error',
        START: 'title_related_actions.get.start',
        SUCCESS: 'title_related_actions.get.success'
    },
    MOVE_ELEMENT: 'title_related_actions.move_element',
    REMOVE_ELEMENT: 'title_related_actions.remove_element',
};

class RelatedActions {

    /**
     * Save a related title so that the server gives us the orderWithinParent
     * and the relation messages.
     * Then dispatch an action to add the related title to the right array.
     *
     * @param {Immutable.Map} title
     * @param {Immutable.Map} relationshipType
     * @param {String} relationName where to add the related title (one of children, other or parents).
     */
    addRelatedTitle(title, relationshipType, relationName) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ADD,
            title: title,
            relationName: relationName,
            relationshipType: relationshipType
        });
        return;
    }

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
        return;
    }

    get(id) {
        let relatedTitles;
        id = parseInt(id);

        Dispatcher.dispatch({
            actionType: CONSTANTS.GET.START
        });

        const preloaderSource = 'related-actions.get';
        PreloaderActions.show(preloaderSource);

        Request.get(`title/${id}/related-title`).query({active: false}).exec().then(data => {
            // load data from each title
            relatedTitles = data.body;

            let relatedPromises = relatedTitles.map(t => {
                let relatedId = t.childTitleId;
                if (t.parentTitleId !== id) {
                    relatedId = t.parentTitleId;
                }

                return Request.get(`title/${relatedId}`).exec().catch(err => {
                    PreloaderActions.hide(preloaderSource);
                    NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'titles.edit.related-title.load.title.error', relatedId);
                    throw err;
                });
            });
            return Promise.all(relatedPromises);
        }).catch(err => {
            PreloaderActions.hide(preloaderSource);
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'titles.edit.related-title.load.error');
            throw err;
        }).then(related => {
            // load data from each title
            relatedTitles = relatedTitles.map((rt, i) =>{
                return Immutable.fromJS(related[i].body).merge(rt);
            });

            let children = Immutable.List();
            let other = Immutable.List();
            let parents = Immutable.List();

            relatedTitles = Immutable.fromJS(relatedTitles);
            relatedTitles.forEach(t => {
                if (TitleStatusActions.findRelationTypeById(t.get('relationshipType')) === 'children') {children = children.push(t);}
                if (TitleStatusActions.findRelationTypeById(t.get('relationshipType')) === 'parents') {parents = parents.push(t);}
                if (TitleStatusActions.findRelationTypeById(t.get('relationshipType')) === 'other') {other = other.push(t);}
            });
            /**
             * Finally, return the map with the three arrays.
             */
            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.SUCCESS,
                titleId: id,
                children: Immutable.fromJS(children.sortBy(t => t.get('orderWithinParent'))),
                other: Immutable.fromJS(other.sortBy(t => t.get('orderWithinParent'))),
                parents: Immutable.fromJS(parents.sortBy(t => t.get('orderWithinParent')))
            });

            PreloaderActions.hide(preloaderSource);
            return;
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.ERROR
            });

            PreloaderActions.hide(preloaderSource);
            throw err;
        });
    }

    moveElement(relation, orderBy, from, to) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.MOVE_ELEMENT,
            from: from,
            orderBy: orderBy,
            relation: relation,
            to: to
        });
        return;
    }

    removeElement(relation, index, orderBy) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.REMOVE_ELEMENT,
            index: index,
            orderBy: orderBy,
            relation: relation
        });
        return;
    }

    save(id, related) {
        /**
         *  Transform the related maps:
         *  related {
         *      children: [],
         *      other: [],
         *      parents: []
         *  }
         *  to a List (the flatten(1) shows how many levels to flatten),
         *  so, originalRelated.toList().flatten(1) is equal to:
         *  [...children, ...other, ...parents]
         */
        let data = related.toList().flatten(1).map(r => {
            return {
                parentTitleId: id,
                childTitleId: r.get('id'),
                orderWithinParent: r.get('orderWithinParent'),
                relationshipType: r.get('relationshipType')
            };
        }).toJS();

        const preloaderSource = 'related-actions.save';
        PreloaderActions.show(preloaderSource);
        return Request.put(`title/${id}/related-title`).send(data).exec().then(()=>{
            PreloaderActions.hide(preloaderSource);
            return;
        }).catch(err => {
            PreloaderActions.hide(preloaderSource);
            throw err;
        });
    }

}

let actions = new RelatedActions();

export {
    actions as RelatedActions,
    CONSTANTS as RelatedConstants
};
