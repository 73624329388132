/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ControlLabel, FormGroup} from 'react-bootstrap';

import {FormSection, FormRow} from '../common/form/form';
import {GetAttr} from '../common/utils/utils';
import TerritorySelect from '../lookup/territory/territory-select';
import TerritoryStore from '../lookup/territory/territory-store';
import {GroupConstants} from '../security/group/group-actions';
import GroupSelect from '../security/group/group-select';
import UserCompanySelect from '../user/company/user-company-select';


class Criteria extends Component {

    static get propTypes() {
        return {
            autoSync: PropTypes.bool,
            disabled: PropTypes.bool,
            onAutoSyncChange: PropTypes.func.isRequired,
            onCompaniesChange: PropTypes.func.isRequired,
            onGroupsChange: PropTypes.func.isRequired,
            onTerritoriesChange: PropTypes.func.isRequired,
            selectedCompanies: PropTypes.instanceOf(Immutable.OrderedSet).isRequired,
            selectedGroups: PropTypes.instanceOf(Immutable.OrderedSet).isRequired,
            selectedTerritories: PropTypes.instanceOf(Immutable.OrderedSet).isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            autoSync: false,
            disabled: true
        };
    }

    static calculateState() {
        return {
            territories: TerritoryStore.getState().get('items')
        };
    }

    static getStores() {
        return [TerritoryStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (
            nextProps.autoSync !== this.props.autoSync ||
            nextProps.selectedCompanies !== this.props.selectedCompanies ||
            nextProps.selectedTerritories !== this.props.selectedTerritories ||
            nextProps.selectedGroups !== this.props.selectedGroups ||

            nextState.territories !== this.state.territories) {
            return true;
        }

        return false;
    }

    render() {

        let selectedCompanies = this.props.selectedCompanies || Immutable.OrderedSet();
        let selectedTerritories = this.props.selectedTerritories || Immutable.OrderedSet();
        let selectedGroups = this.props.selectedGroups || Immutable.OrderedSet();

        selectedTerritories = selectedTerritories.map( (t) => {
            let ti = parseInt(t);
            return this.state.territories.find( territory => territory.get('id') === ti);
        });

        selectedCompanies = selectedCompanies.map( (c) => {
            // here i've to create it as don't have all companies at once
            return {id: c, name: c};
        });

        return (
            <div className="padding-bottom-20">
                <FormSection iconClass="far fa-object-group" title={this.context.intl.messages['criteria.title']}>
                    <FormRow additionalClass="form-group">
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['criteria.select.territory']}</ControlLabel>
                            <TerritorySelect
                                disabled={this.props.disabled}
                                onChange={this.props.onTerritoriesChange}
                                value={selectedTerritories}
                                multi
                            />
                        </FormGroup>
                    </FormRow>
                    <FormRow additionalClass="form-group">
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['criteria.select.companies']}</ControlLabel>
                            <UserCompanySelect
                                getOptionLabel={GetAttr('name')}
                                getOptionValue={GetAttr('id')}
                                isClearable={true}
                                disabled={this.props.disabled}
                                onChange={this.props.onCompaniesChange}
                                selected={selectedCompanies}
                                multi={true}
                            />
                        </FormGroup>
                    </FormRow>
                    <FormRow additionalClass="form-group">
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['criteria.select.user-groups']}</ControlLabel>
                            <GroupSelect
                                disabled={this.props.disabled}
                                onChange={this.props.onGroupsChange}
                                selected={selectedGroups}
                                type={GroupConstants.USER}/>
                        </FormGroup>
                    </FormRow>
                    <hr/>
                    <FormRow additionalClass="padding-bottom-20">
                        <div>
                            <ControlLabel>{this.context.intl.messages['criteria.auto-sync']}</ControlLabel>
                            <em>{this.context.intl.messages['criteria.auto-sync-description']}</em>
                            <br/>
                            <input type="checkbox" onChange={this.props.onAutoSyncChange} checked={this.props.autoSync}/>
                        </div>
                    </FormRow>
                </FormSection>
            </div>
        );
    }
}

export {Criteria as Criteria_BASE};
export default Container.create(Criteria);
