/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

const routePropValidator = (props) => {
    // Test the main prop.
    if (!props.route || typeof props.route !== 'object') {
        throw new Error(`Required prop route was not found or is not of type Object. Found "${props.route}".`);
    }

    // Test the route.mode value.
    if (!~['create', 'edit', 'duplicate', 'view'].indexOf(props.route.mode)) {
        throw new Error(`Required prop route.mode was not found or is not one of ["create", "edit", "view" or "duplicate"]. Found "${props.route.mode}".`);
    }

    return;
};

export default routePropValidator;
