/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import QueryString from 'querystring-es3';
import React, {PureComponent} from 'react';
import {ValueType} from 'react-select/lib/types';

import ActiveFilterView from './active-filter-view';

import {
    ActiveFilterContainerProps,
    ActiveFilterContainerState,
    ActiveFilterOptionType,
} from '~/src/hardac/machine-learning/active-filter/types';
import {RouterActions} from '~/src/router/router-actions';

const activeFilterOptions = [
    {label: 'active', value: 'true'},
    {label: 'inactive', value: 'false'},
];

class ActiveFilter extends PureComponent<
    ActiveFilterContainerProps,
    ActiveFilterContainerState
> {
    constructor(props: ActiveFilterContainerProps) {
        super(props);

        const query = QueryString.parse(
            props.location.search?.replace('?', '') ?? ''
        );
        const filterValue =
            activeFilterOptions?.find(
                (option) => option.value === query['active-filter-value']
            ) ?? null;

        this.state = {
            activeFilterValue: filterValue,
        };
    }

    handleActiveFilterChange = (
        selected: ActiveFilterOptionType | null
    ) => {
        let queryString = this.props.location.search?.replace('?', '') ?? '';

        if (selected) {
            const queryStringObject = QueryString.parse(queryString);
            queryString = QueryString.stringify({
                ...queryStringObject,
                'active-filter-value': selected.value,
            });
        } else {
            const queryStringObject = QueryString.parse(queryString);
            delete queryStringObject['active-filter-value'];
            queryString = QueryString.stringify(queryStringObject);
        }

        if (queryString) {
            RouterActions.redirect(
                `${this.props.location.pathname}?${queryString}`
            );
        } else {
            RouterActions.redirect(this.props.location.pathname);
        }

        this.setState({
            activeFilterValue: selected,
        });
    };

    render() {
        return (
            <ActiveFilterView
                onChange={this.handleActiveFilterChange}
                options={activeFilterOptions}
                value={this.state.activeFilterValue}
            />
        );
    }
}

export default ActiveFilter;
