/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {map, filter} from 'rxjs/operators';

import {MultiCuesSelectionClickSource, SingleCueClickSource} from './sources';
import {
    ActiveRegions$,
    Cues$,
    HideCaptionPositioningModal,
    IsShiftCaptionsModalVisible$,
    IsSyncTime$,
    ListOfRegions$,
    ResetSelectedCues,
    SelectedCues$,
    SelectCues,
    SetShiftingTime,
} from './stores';
import CCEditorStore from '../cc-editor-store';
import {ScrollIntoRegion} from '../helpers/scroll-into-region';

export * from './stores';

// behavior
Cues$.subscribe(ResetSelectedCues);
ListOfRegions$.subscribe(ResetSelectedCues);
ActiveRegions$.subscribe(handleActiveRegionChanged);
SelectedCues$.subscribe(HideCaptionPositioningModal);
IsShiftCaptionsModalVisible$.subscribe(detectStartTimeOfFirstCaption);
SingleCueClickSource.pipe(
    map(findCueById),
    filter(isDefined),
    map((cue) => [cue]),
).subscribe(SelectCues);
MultiCuesSelectionClickSource.pipe(
    map(findCueById),
    map(combineSelectionsWith),
    filter(isDefined),
).subscribe(SelectCues);


function handleActiveRegionChanged(regions: CCEditorRegion[]) {
    const region = regions.find((r) => r.type === 'cue');
    if (!IsSyncTime$.value || !region) {
        return;
    }
    const id = region.id;
    const cues = CCEditorStore.getState().cues;
    const idx = cues.findIndex((c) => c.id === id);
    if (idx >= 0) {
        ScrollIntoRegion(cues[Math.max(0, idx - 2)]);
    }
}

function findCueById(id: string) {
    return CCEditorStore.getState().cues.find(c => c?.id === id);
}

function combineSelectionsWith(cue: WBTVDCue) {
    const cues = SelectedCues$.value;
    const st = cues[0]?.startTime;
    const et = last(cues)?.endTime;

    if (typeof st !== 'number' || typeof et !== 'number') {
        console.error("Couldn't detect start/end time for selected cues", cues);
        return;
    }

    const startTime = Math.min(cue.startTime, st);
    const endTime = Math.max(cue.endTime, et);

    return Cues$.value.filter((c) => startTime <= c.startTime && endTime >= c.endTime);
}

function last<T>(arr: ArrayLike<T>): T | undefined {
    if (arr.length) {
        return arr[arr.length - 1];
    }
}

function isDefined<T>(arg: T | null | undefined): arg is T {
    return arg !== null && arg !== undefined;
}

function detectStartTimeOfFirstCaption() {
    const cue = Cues$.value[0];
    if (cue) {
        SetShiftingTime(cue.startTime);
    } else {
        SetShiftingTime(0);
    }
}
