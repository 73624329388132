/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {DOCUMENT_CT_TYPES} from '@wbdt-sie/brainiac-web-common';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import Select from 'react-select';

import {FormItem} from '../../common/form/form';
import Panel from '../../common/panel/panel';
import {WithStoreOnRoute} from '../../common/store-on-route';
import {GetAttr} from '../../common/utils/utils';
import {LayoutActions} from '../../layout/layout-actions';
import {AssetActions, AssetConstants} from '../asset-actions';
import AssetStore from '../asset-store';

import {AudioConstants} from '~/src/assets/audio/audio-actions';
import DocumentStore from '~/src/assets/document/document-store';
import {ImageConstants} from '~/src/assets/image/image-actions';
import {MerchandiseConstants} from '~/src/assets/merchandise/merchandise-actions';
import {ScriptConstants} from '~/src/assets/script/script-actions';
import {VideoConstants} from '~/src/assets/video/video-actions';
import BaseSelect from '~/src/common/base-select/base-select';


const ActiveOptions = WithStoreOnRoute(class ActiveOptions extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object,
            onChange: PropTypes.func,
            selected: PropTypes.string
        };
    }

    static get defaultProps() {
        return {
            location: undefined,
            onChange: undefined,
            selected: ''
        };
    }

    constructor(props) {
        super(props);

        this.setActiveFilter = this.setActiveFilter.bind(this);
    }

    setActiveFilter(e) {
        if (!e || e.value === undefined) {return;}

        const filterAttr = 'active-type';

        if (this.props.location) {
            this.setRouteState(filterAttr, e.value)
                .setRouteState('operator', 'AND')
                .clearRouteState('offset')
                .apply();
            return;
        }
        AssetActions.setFilter(filterAttr, e.value);

        if (this.props.onChange) {
            this.props.onChange();
        }
    }

    render() {
        const options = [
            {name: 'Active', value: 'ACTIVE'},
            {name: 'Both', value: 'BOTH'},
            {name: 'Inactive', value: 'INACTIVE'}
        ];

        let selected = options[0];
        if (this.props.selected) {
            selected = options.find(c => c.value === this.props.selected);
        }

        return (
            <Panel title="Status" classTitle="box-title" collapsible defaultExpanded>
                <div data-style="display: block;" className="box-body">
                    <div className="form-group">
                        <Select
                            classNamePrefix="dropdown"
                            getOptionLabel={GetAttr('name')}
                            getOptionValue={GetAttr('value')}
                            isClearable={false}
                            isMulti={false}
                            name="active-type"
                            options={options}
                            placeholder="Select..."
                            value={selected}
                            onChange={this.setActiveFilter}
                        />
                    </div>
                </div>
            </Panel>
        );
    }
});

const AssetTypeOptions = WithStoreOnRoute(class AssetTypeOptions extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object,
            onChange: PropTypes.func.isRequired,
            value: PropTypes.number
        };
    }

    static get defaultProps() {
        return {
            location: {},
            value:0
        };
    }

    componentDidMount() {
        if (!this.props.location) {
            AssetActions.setFilter('asset-type', '');
        }
        return;
    }

    render() {
        let options = [];
        Object.keys(AssetConstants.ASSET_TYPES).map((option) => (
            options.push(AssetConstants.ASSET_TYPES[option])
        ));

        options.push(AssetConstants.SOURCE_CONTENT_TYPE.PROXY); //Adding video with sourceContentType as Proxy

        const selected = options.find((item) => {
            return item.id === this.props.value;
        });

        return (
            <Panel title="Asset Type" classTitle="box-title" collapsible defaultExpanded>
                <div data-style="display: block;" className="box-body">
                    <div className="form-group">
                        <Select
                            getOptionLabel={GetAttr('name')}
                            getOptionValue={GetAttr('id')}
                            isClearable={true}
                            isMulti={false}
                            name="asset-type"
                            onChange={this.props.onChange}
                            options={options}
                            placeholder="Select..."
                            value={selected}
                        />
                    </div>
                </div>
            </Panel>
        );
    }
});

const ContentTypeOptions = WithStoreOnRoute(class ContentTypeOptions extends Component {

    static get propTypes() {
        return {
            assetType: PropTypes.number.isRequired,
            location: PropTypes.object,
            onChange: PropTypes.func.isRequired,
            value: PropTypes.number
        };
    }

    static get defaultProps() {
        return {
            includeImageContentTypes: false,
            location: {},
            value:0
        };
    }

    componentDidMount() {
        if (!this.props.location) {
            AssetActions.setFilter('content-type', '');
        } else {
            // Set default filter
            AssetActions.setFilter('content-type', VideoConstants.CONTENT_TYPES.FULL_EPISODE.id);
        }
        return;
    }

    render() {
        if (this.props.assetType === AssetConstants.ASSET_TYPES.DOCUMENT.id) {
            // browse documents needs contentType with groupBy
            const options = Object.keys(DOCUMENT_CT_TYPES.CONTENT_TYPE_MAP).map(id => DOCUMENT_CT_TYPES.CONTENT_TYPE_MAP[id]);
            const selected = options.find((item) => {
                return item.id === this.props.value;
            });
            return (
                <Panel title="Content Type" classTitle="box-title" collapsible defaultExpanded>
                    <div data-style="display: block;" className="box-body">
                        <div className="form-group">
                            <BaseSelect
                                disabled={false}
                                groupBy="subType"
                                name="content-type"
                                onChange={this.props.onChange}
                                options="documentType"
                                store={DocumentStore}
                                value={selected}
                            />
                        </div>
                    </div>
                </Panel>
            );
        }

        let options = [];

        // Asset endpoint expects numeric content-type id values for video, audio, document, merchandise and script assets
        const videoContentTypes = VideoConstants.toArray('CONTENT_TYPES');
        const documentContentTypes = Object.keys(DOCUMENT_CT_TYPES.CONTENT_TYPE_MAP).map(id => DOCUMENT_CT_TYPES.CONTENT_TYPE_MAP[id]);
        const audioContentTypes = Object.keys(AudioConstants.CONTENT_TYPES).map(id => AudioConstants.CONTENT_TYPES[id]);
        const merchandiseContentTypes = Object.keys(MerchandiseConstants.CONTENT_TYPES).map(id => MerchandiseConstants.CONTENT_TYPES[id]);
        const scriptContentTypes = Object.keys(ScriptConstants.CONTENT_TYPES).map(id => ScriptConstants.CONTENT_TYPES[id]);

        // Asset endpoint expects text content-type name values for all other assets
        const imageContentTypes = Object.keys(ImageConstants.CONTENT_TYPE_MAP).map(id => {
            const res = ImageConstants.CONTENT_TYPE_MAP[id];
            return {
                ...res,
                id: res.name
            };
        });

        switch (this.props.assetType) {
        case AssetConstants.ASSET_TYPES.IMAGE.id:
            options = imageContentTypes;
            break;
        case AssetConstants.ASSET_TYPES.VIDEO_TIMELINE.id:
        case AssetConstants.ASSET_TYPES.VIDEO.id:
            options = videoContentTypes;
            break;
        case AssetConstants.ASSET_TYPES.AUDIO.id:
            options = audioContentTypes;
            break;
        case AssetConstants.ASSET_TYPES.DOCUMENT.id:
            options = documentContentTypes;
            break;
        case AssetConstants.ASSET_TYPES.MERCHANDISE.id:
            options = merchandiseContentTypes;
            break;
        case AssetConstants.ASSET_TYPES.SCRIPT.id:
            options = scriptContentTypes;
            break;
        default:
            // Show all content type options (for browse all with no asset type selection)
            options = options.concat(
                imageContentTypes,
                videoContentTypes,
                audioContentTypes,
                documentContentTypes,
                merchandiseContentTypes,
                scriptContentTypes
            );
        }

        options = options.sort((a, b) => a.name.localeCompare(b.name));

        const selected = options.find((item) => {
            return item.id === this.props.value;
        });

        return (
            <Panel title="Content Type" classTitle="box-title" collapsible defaultExpanded>
                <div data-style="display: block;" className="box-body">
                    <div className="form-group">
                        <Select
                            getOptionLabel={GetAttr('name')}
                            getOptionValue={GetAttr('id')}
                            isClearable={true}
                            isMulti={false}
                            name="content-type"
                            onChange={this.props.onChange}
                            options={options}
                            placeholder="Select..."
                            value={selected}
                        />
                    </div>
                </div>
            </Panel>
        );
    }
});

const CatalogOptions = WithStoreOnRoute(Container.create(class CatalogOptions extends Component {

    static get propTypes() {
        return {
            onChange: PropTypes.func.isRequired,
            value: PropTypes.number
        };
    }

    static calculateState() {
        return {
            catalogs: AssetStore.getState().get('catalogs')
        };
    }

    static get defaultProps() {
        return {
            value: 0
        };
    }

    static getStores() {
        return [AssetStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();
    }

    componentDidMount() {
        AssetActions.getAllCatalogs(0, 999999);
        return;
    }

    render() {
        let selected = this.state.catalogs.toJS().find((item) => {
            return item.id === this.props.value;
        });

        return (
            <Panel title="Catalog" classTitle="box-title" collapsible defaultExpanded>
                <div data-style="display: block;" className="box-body">
                    <div className="form-group">
                        <Select
                            classNamePrefix="dropdown"
                            getOptionLabel={GetAttr('name')}
                            getOptionValue={GetAttr('id')}
                            isClearable={true}
                            isMulti={false}
                            name="catalog-id"
                            onChange={this.props.onChange}
                            options={this.state.catalogs.toJS()}
                            placeholder="Select..."
                            value={selected}
                        />
                    </div>
                </div>
            </Panel>
        );
    }
}));

const DateRangeOptions = WithStoreOnRoute(class DateRangeOptions extends Component {

    static get propTypes() {
        return {
            filters: PropTypes.instanceOf(Immutable.Map),
            location: PropTypes.object,
            onChange: PropTypes.func.isRequired
        };
    }
    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            location: {},
            filters: undefined,
        };
    }

    constructor(props) {
        super(props);

        this.setFormatDate = this.setFormatDate.bind(this);
    }

    setFormatDate(attr, value) {
        if (this.props.location) {
            this.setRouteState(attr, value)
                .setRouteState('operator', 'AND')
                .clearRouteState('offset')
                .apply();
            return;
        }
        AssetActions.setFilter(attr, value);
    }

    render() {
        let model = this.props.filters;
        if (this.props.location) {
            model = this.getRouteState();
        }

        return (
            <Panel title="Created Date" classTitle="box-title" collapsible defaultExpanded>
                <div className="box-body date-picker-filter">
                    <FormItem
                        label={this.context.intl.messages['filter-options.expire-date.from']}
                        attr="start-date-created"
                        datepicker={{popoverTargetOffset: '10px -36px', showYearDropdown: true}}
                        model={model}
                        onChange={this.props.onChange}
                        setter={this.setFormatDate}
                        type="date"
                    />
                    <FormItem
                        label={this.context.intl.messages['filter-options.expire-date.to']}
                        attr="end-date-created"
                        datepicker={{popoverTargetOffset: '10px -36px', showYearDropdown: true}}
                        model={model}
                        onChange={this.props.onChange}
                        setter={this.setFormatDate}
                        type="date"
                    />
                </div>
            </Panel>
        );
    }
});

const DeliveryTypeOptions = WithStoreOnRoute(class DeliveryTypeOptions extends Component {

    static get propTypes() {
        return {
            onChange: PropTypes.func.isRequired,
            value: PropTypes.string
        };
    }

    static get defaultProps() {
        return {
            location: {},
            value: ''
        };
    }

    render() {
        const options = Object.keys(AssetConstants.DELIVERY_TYPES)
            .map(k => ({name: AssetConstants.DELIVERY_TYPES[k].name, id: k}))
            .sort((a, b) => a.name.localeCompare(b.name));
        const selected = options.find((item) => {
            return item.name === this.props.value || item.id === this.props.value;
        });

        return (
            <Panel title="Delivery Type" classTitle="box-title" collapsible defaultExpanded>
                <div data-style="display: block;" className="box-body">
                    <div className="form-group">
                        <Select
                            getOptionLabel={GetAttr('name')}
                            getOptionValue={GetAttr('id')}
                            isClearable={true}
                            isMulti={false}
                            name="delivery-type"
                            onChange={this.props.onChange}
                            options={options}
                            placeholder="Select..."
                            value={selected}
                        />
                    </div>
                </div>
            </Panel>
        );
    }
});

class FilterOptions extends Component {

    static get propTypes() {
        return {
            filters: PropTypes.instanceOf(Immutable.Map).isRequired,
            assetType: PropTypes.number.isRequired,
            location: PropTypes.object,
            showActive: PropTypes.bool,
            showAssetType: PropTypes.bool,
            showCatalog: PropTypes.bool,
            showContentType: PropTypes.bool,
            showDateRange: PropTypes.bool,
            showDeliveryType: PropTypes.bool,
        };
    }

    static get defaultProps() {
        return {
            location: undefined,
            showActive: true,
            showAssetType: true,
            showCatalog: true,
            showContentType: true,
            showDateRange: true,
            showDeliveryType: true,
        };
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.filters !== this.props.filters ||
            nextProps.location !== this.props.location) {
            return true;
        }
        return false;
    }

    handleToggleFiltersPanel() {
        LayoutActions.toggleFiltersPanel();
    }

    handleSelectStore(attr, selected) {
        if (attr === 'asset-type') {
            // set correct filter taking in account Proxy is a Video Asset with sourceContentType as Proxy
            if (selected?.id === AssetConstants.SOURCE_CONTENT_TYPE.PROXY.id) {
                AssetActions.setFilter('asset-type', null);
                attr = 'video-source-content-type';
            } else {
                AssetActions.setFilter('video-source-content-type', null);
            }
        }

        let s;
        if (selected) {
            s = selected.id;
        }

        AssetActions.setFilter(attr, s);
        this.onChange();
    }

    onChange() {
        AssetActions.setFilter('operator', 'AND');

        AssetActions.get(
            AssetStore.getState().get('filters'),
            0,
            20
        );
    }

    handleSelectLocation(attr, selected) {
        if (attr === 'asset-type') {
            if (selected?.id === AssetConstants.ASSET_TYPES.VIDEO.id || selected?.id === AssetConstants.SOURCE_CONTENT_TYPE.PROXY.id) {
                // if filter by Video to not show sourceContentType Proxy
                if (selected?.id === AssetConstants.ASSET_TYPES.VIDEO.id) {
                    this.setRouteState('video-source-content-type', AssetConstants.SOURCE_CONTENT_TYPE.PRO_RES.id);
                }
                // if filter by Proxy to show only sourceContentType as Proxy
                if (selected?.id === AssetConstants.SOURCE_CONTENT_TYPE.PROXY.id) {
                    this.clearRouteState('asset-type');
                    attr = 'video-source-content-type';
                }
            } else {
                this.clearRouteState('video-source-content-type');
            }
        }

        // location related functions
        let s;
        if (selected) {
            s = selected.id;
        }
        this.setRouteState(attr, s)
            .setRouteState('operator', 'AND')
            .clearRouteState('offset')
            .apply();
    }

    render() {
        let dateRange;
        let catalogDisplay;
        let activeOptions;
        let assetTypeOptions;
        let contentTypeOptions;
        let deliveryTypeOptions;

        if (this.props.location) {
            // location mode
            if (this.props.showActive) {
                activeOptions = <ActiveOptions
                    selected={this.props.location.query['active-type']}
                    location={this.props.location}
                />;
            }

            if (this.props.showAssetType) {
                assetTypeOptions = <AssetTypeOptions
                    onChange={this.handleSelectLocation.bind(this, 'asset-type')}
                    location={this.props.location}
                    value={parseInt(this.props.location.query['asset-type'] || -1)}
                />;
            }

            if (this.props.showContentType) {
                contentTypeOptions = <ContentTypeOptions
                    onChange={this.handleSelectLocation.bind(this, 'content-type')}
                    location={this.props.location}
                    value={parseInt(this.props.location.query['content-type'] || -1)}
                    assetType={parseInt(this.props.location.query['asset-type'] || this.props.assetType)}
                />;
            }

            if (this.props.showDeliveryType) {
                deliveryTypeOptions = <DeliveryTypeOptions
                    onChange={this.handleSelectLocation.bind(this, 'delivery-type')}
                    location={this.props.location}
                    value={this.props.location.query['delivery-type'] || ''}
                />;
            }

            if (this.props.showDateRange) {
                dateRange = <DateRangeOptions
                    location={this.props.location}
                />;
            }
            if (this.props.showCatalog) {
                catalogDisplay = <CatalogOptions
                    onChange={this.handleSelectLocation.bind(this, 'catalog-id')}
                    value={parseInt(this.props.location.query['catalog-id'] || -1)}
                />;
            }
        } else {
            // legacy (store) mode
            if (this.props.showActive) {
                activeOptions = <ActiveOptions
                    selected={AssetStore.getState().getIn(['filters', 'active-type'])}
                    onChange={this.onChange}
                />;
            }

            if (this.props.showAssetType) {
                let f = -1;
                if (AssetStore.getState().get('filters').get('asset-type')) {
                    f = AssetStore.getState().get('filters').get('asset-type');
                }
                assetTypeOptions = <AssetTypeOptions
                    onChange={this.handleSelectStore.bind(this, 'asset-type')}
                    value={f}
                />;
            }

            if (this.props.showContentType) {
                let f = -1;
                if (AssetStore.getState().get('filters').get('content-type')) {
                    f = AssetStore.getState().get('filters').get('content-type');
                }
                contentTypeOptions = <ContentTypeOptions
                    onChange={this.handleSelectStore.bind(this, 'content-type')}
                    value={f}
                />;
            }

            if (this.props.showDeliveryType) {
                let f = '';
                if (AssetStore.getState().get('filters').get('delivery-type')) {
                    f = AssetStore.getState().get('filters').get('delivery-type');
                }
                deliveryTypeOptions = <DeliveryTypeOptions
                    onChange={this.handleSelectStore.bind(this, 'delivery-type')}
                    value={f}
                />;
            }

            if (this.props.showDateRange) {
                dateRange = <DateRangeOptions
                    filters={this.props.filters}
                    onChange={this.onChange}
                />;
            }

            if (this.props.showCatalog) {
                let f = -1;
                if (AssetStore.getState().get('filters').get('catalog-id')) {
                    f = AssetStore.getState().get('filters').get('catalog-id');
                }
                catalogDisplay = <CatalogOptions
                    onChange={this.handleSelectStore.bind(this, 'catalog-id')}
                    value = {f}
                />;
            }
        }
        return (
            <div>
                <aside className="control-sidebar B(0) Ovx(h) Ovy(a)">

                    <div className="tab-content">

                        <p>
                            <button className="btn btn-block bg-navy" onClick={this.handleToggleFiltersPanel}><i className="fas fa-chevron-right pull-right"></i>&nbsp;Close Options</button>
                        </p>
                        {activeOptions}

                        {assetTypeOptions}

                        {contentTypeOptions}

                        {deliveryTypeOptions}

                        {dateRange}

                        {catalogDisplay}

                    </div>

                </aside>

                <div style={{position: 'fixed', height: 'auto'}} className="control-sidebar-bg"></div>
            </div>
        );
    }
}

export default WithStoreOnRoute(FilterOptions);

export {
    ActiveOptions,
    AssetTypeOptions,
    ContentTypeOptions,
    DateRangeOptions,
    CatalogOptions
};
