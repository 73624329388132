/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React from 'react';
import {Button, Checkbox, ControlLabel, FormGroup, Modal} from 'react-bootstrap';

import {FormRow, FormSection} from '~/src/common/form/form';
import {EventActions} from '~/src/events/event-actions';


class ImportTokensModal extends React.Component {
    static get propTypes() {
        return {
            eventId: PropTypes.number.isRequired,
            hide: PropTypes.func.isRequired,
            show: PropTypes.bool.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            importDisclaimerRead: false
        };

        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleImportTokenCSV = this.handleImportTokenCSV.bind(this);
    }

    handleCloseModal() {
        this.setState(() => ({
            importDisclaimerRead: false
        }));
        this.props.hide();
    }

    handleCheckboxChange() {
        this.setState((prevState) => ({
            importDisclaimerRead: !prevState.importDisclaimerRead
        }));
    }


    handleImportTokenCSV() {
        if (document.getElementById('csvFileTokens').files.length === 0) {
            return;
        }

        this.handleCloseModal();
        EventActions.importLoginTokens(this.props.eventId, document.getElementById('csvFileTokens').files[0]);
    }

    render() {
        return (
            <Modal onHide={this.handleCloseModal} show={this.props.show}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="text-center">
                        {this.context.intl.messages['events.users.import_tokens.modal.title']}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        <p>{this.context.intl.messages['events.users.import_tokens.modal.notice.title']}</p>
                        <ul>
                            <li>{this.context.intl.messages['events.users.import_tokens.modal.item-1']}</li>
                            <li>{this.context.intl.messages['events.users.import_tokens.modal.item-2']}</li>
                            <li>{this.context.intl.messages['events.users.import_tokens.modal.item-3']}</li>
                        </ul>
                        <FormRow>
                            <Checkbox
                                key="importDisclaimerKey"
                                checked={this.state.importDisclaimerRead}
                                onChange={this.handleCheckboxChange}>
                                <b>{this.context.intl.messages['events.users.import_tokens.modal.confirmation']}</b>
                            </Checkbox>
                        </FormRow>
                        <FormSection>
                            <FormRow>
                                <FormGroup>
                                    <ControlLabel>{this.context.intl.messages['events.users.import_tokens.modal.label']}</ControlLabel>
                                    <input
                                        accept=".csv"
                                        className="Mb(3px)"
                                        id="csvFileTokens"
                                        type="file"
                                    />
                                </FormGroup>
                            </FormRow>
                        </FormSection>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button className="pull-left Ml(5)" onClick={this.handleCloseModal}>
                        {this.context.intl.messages['common.cancel']}
                    </Button>
                    <Button bsStyle="primary" type="submit"
                        disabled={!this.state.importDisclaimerRead}
                        onClick={this.handleImportTokenCSV}>
                        {this.context.intl.messages['events.users.import_tokens.modal.ok']}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ImportTokensModal;
