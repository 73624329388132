/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import PresentationSections from './presentation-sections';
import SlidingPanel from '../../../common/sliding-panel/sliding-panel';
import EventStore from '../../event-store';

import {SlidingPanelActions} from '~/src/common/sliding-panel/sliding-panel-actions';
import {EventActions, EventConstants} from '~/src/events/event-actions';

class PresentationPanel extends Component {
    static get propTypes() {
        return {
            title: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        const es = EventStore.getState();
        return {
            allAssets : es.getIn(['event', 'assets']),
            allTitles : es.getIn(['event', 'titles']),
            titleStyle: es.get('titleStyle'),
            nominationInfo: es.get('nominationInfo'),
            originalNominationInfo: es.get('originalNominationInfo'),
            originalTitleStyleFiles: es.get('originalTitleStyleFiles'),
            selectedSynopsisOption: es.get('selectedSynopsisOption'),
            selectedTitleThumbnailOption: es.get('selectedTitleThumbnailOption'),
            selectedTitleStyleOption: es.get('selectedTitleStyleOption'),
            titleStyleFiles: es.get('titleStyleFiles'),
        };
    }

    static getStores() {
        return [EventStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.handleViewTitle = this.handleViewTitle.bind(this);
        this.handleSaveTitle = this.handleSaveTitle.bind(this);
    }

    componentWillUnmount() {
        SlidingPanelActions.hide('editTitlePresentation');
    }

    handleCancelPanel() {
        EventActions.clearTitlePresentationOptions();
    }

    handleSaveTitle() {
        EventActions.saveTitlePresentationOptions(
            this.props.title.get('eventTitleId'),
            this.state.titleStyle,
            this.state.titleStyleFiles,
            this.state.originalTitleStyleFiles,
            this.state.selectedTitleStyleOption,
            this.state.selectedTitleThumbnailOption,
            this.state.selectedSynopsisOption,
            this.state.nominationInfo,
            this.state.originalNominationInfo
        ).then(() => {
            // Reload the presentation styles from api to ensure the list-titles table is refreshed when this panel closes
            EventActions.getPresentationTitles(this.state.allTitles, this.state.allAssets);
        });

        SlidingPanelActions.hide('editTitlePresentation');
    }

    handleViewTitle() {
        window.open(`/titles/${this.props.title.get('titleId')}`, '_blank');
    }

    render() {
        let validations = EventStore.getTitleStyleValidations();
        if (this.state.selectedTitleStyleOption === EventConstants.TITLE_PRESENTATION.MODIFY_OPTIONS.INHERIT) {
            validations = []; // don't check title style validations if inherit option is selected
        }

        const addSelectedItems = (
            <span>
                <button
                    className="btn btn-primary-outline"
                    onClick={this.handleViewTitle}
                >
                    <i className="fa-solid fa-arrow-up-right-from-square"></i>&nbsp;{this.context.intl.messages['events.presentation.panel.view-title']}
                </button>
                &nbsp;
                <button
                    className="btn btn-success-outline"
                    onClick={this.handleSaveTitle}
                    disabled={validations.length}
                >
                    <i className="fa-solid fa-check"></i>&nbsp;{this.context.intl.messages['events.presentation.panel.save-options']}
                </button>
            </span>
        );

        const title = (
            <span>
                {this.context.intl.messages['events.presentation.panel']}
                &nbsp;
                <small>{this.props.title.get('name')}</small>
            </span>
        );

        return (
            <SlidingPanel
                cancelButtonClassNames="btn-md btn-navy-outline"
                extraButtons={addSelectedItems}
                icon="fa-regular fa-presentation-screen"
                id="editTitlePresentation"
                onCancel={this.handleCancelPanel}
                title={title}
                useH3Header={true}
            >
                <PresentationSections
                    nominationInfo={this.state.nominationInfo}
                    selectedSynopsisOption={this.state.selectedSynopsisOption}
                    selectedTitleStyleOption={this.state.selectedTitleStyleOption}
                    selectedTitleThumbnailOption={this.state.selectedTitleThumbnailOption}
                    title={this.props.title}
                    titleStyle={this.state.titleStyle}
                    titleStyleFiles={this.state.titleStyleFiles}
                />
            </SlidingPanel>
        );
    }
}

export default Container.create(PresentationPanel);
