/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Dispatcher from '../../../dispatcher/dispatcher';

const CONSTANTS = {
    CLEAR: 'publishing-list.list-item.target-type.clear',
    SHOW_ITEM: 'publishing-list.list-item.target-type.show-item',
    UPDATE_TARGET_TYPE: 'publishing-list.list-item.target-type.update',

    toArray: function(constant) {
        return Object.keys(this[constant])
            .map(k => this[constant][k])
            .sort((a, b) => a.name.localeCompare(b.name));
    }
};

class TargetTypeActions {
    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
    }

    updateTargetType(tt) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.UPDATE_TARGET_TYPE,
            targetType: tt.value,
            targetTypeId: tt.id
        });
        return;
    }

    showItem(ttId, assetId, assetType) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SHOW_ITEM,
            targetType: 'item',
            targetTypeId: ttId,
            assetId: assetId,
            assetType: assetType
        });
        return;
    }

}

let actions = new TargetTypeActions();

export {
    actions as TargetTypeActions,
    CONSTANTS as TargetTypeConstants
};
