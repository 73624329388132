/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {TalentAKAConstants} from './talent-aka-actions';
import Dispatcher from '../dispatcher/dispatcher';


class TalentAKAStore extends ReduceStore {
    getInitialState() {
        return Immutable.Map({
            akas: Immutable.Map()
        });
    }

    reduce(state, action) {
        switch (action.actionType) {

        case TalentAKAConstants.TALENT_AKA.GET.START:
            state = state.set('isLoading', true);
            break;

        case TalentAKAConstants.TALENT_AKA.GET.SUCCESS:
            state = state.setIn(['akas', action.talentId.toString()], action.akas);
            state = state.set('isLoading', false);
            break;

        case TalentAKAConstants.CLEAR:
            state = this.getInitialState();
            break;

        }

        return state;
    }
}

let store = new TalentAKAStore(Dispatcher);

export default store;
