
/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import Language from './language';
import {SectionPanel} from '../../../common/panel/panel';

class Languages extends Component {
    static get propTypes() {
        return {
            expanded: PropTypes.bool,
            disabled: PropTypes.bool.isRequired,
            title: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get defaultProps() {
        return {
            expanded: false
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    shouldComponentUpdate(nextProps/*, nextState*/) {
        if (this.props.title.get('languageAvailability') !== nextProps.title.get('languageAvailability') ||
            this.props.expanded !== nextProps.expanded) {
            return true;
        }
        return false;
    }

    render() {
        const languages = this.props.title.get('languageAvailability').map((l, i) => <Language disabled={this.props.disabled} index={i} key={i} language={l}/>);

        return (
            <SectionPanel title={this.context.intl.messages['titles.create.summary.languages']} expanded={this.props.expanded}>
                {languages}
            </SectionPanel>
        );
    }
}

export default Languages;
