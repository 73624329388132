/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {AlertTypes} from '~/src/common/notification/alert';
import {NotificationActions} from '~/src/common/notification/notification-actions';
import Dispatcher from '~/src/dispatcher/dispatcher';

type TypeGuard<AT extends string> = GenericFetchAction<AT, any> extends DispatchAction ? AT : never;

export async function GenericFetchAction<T, AT extends string>(
    action: TypeGuard<AT>,
    fetcher: () => Promise<T>,
    errorMessage?: string,
) {
    Dispatcher.dispatch({
        actionType: `${action}.start`,
    } as DispatchAction);

    try {
        const payload = await fetcher();
        Dispatcher.dispatch({
            actionType: `${action}.success`,
            payload
        } as DispatchAction);
    } catch (e) {
        Dispatcher.dispatch({
            actionType: `${action}.error`,
            error: e,
        } as DispatchAction);

        if (errorMessage) {
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, errorMessage);
        }
    }
}

