/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';

import {AssetTalentActions} from '../../assets/asset-talent-actions';
import {AssetCatalogActions} from '../../assets/catalogs/asset-catalog-actions';
import {AssetTitleActions} from '../../assets/manage-titles/asset-title-actions';
import {NotificationActions} from '../../common/notification/notification-actions';
import {AudioProfileToArray} from '../../common/utils/utils';
import Dispatcher from '../../dispatcher/dispatcher';
import Request from '../../request';
import {ActionHistoryConstants} from '../../system/action-history/action-history-actions';

const CONSTANTS = {
    TIMELINE: {
        FIND_BY_ID: {
            ERROR: 'asset.timeline.find-by-id.error',
            START: 'asset.timeline.find-by-id.start',
            SUCCESS: 'asset.timeline.find-by-id.success'
        },
        CLEAR: 'asset.timeline.clear',
        PROTECTION_TYPES: {
            CLEAR: {id: 'clear', name: 'Clear'},
            DRM: {id: 'DRM', name: 'DRM'},
            FORENSIC_A_B: {id: 'forensic_a_b', name: 'Forensic A/B'}
        },
        SAVE: {
            ERROR: 'asset.timeline.save.error',
            START: 'asset.timeline.save.start',
            SUCCESS: 'asset.timeline.save.success',
        },
        SEND_TO_OAP: {
            ERROR: 'asset.timeline.send_to_oap.error',
            START: 'asset.timeline.send_to_oap.start',
            SUCCESS: 'asset.timeline.send_to_oap.success',
        },
        SHOW_PLAYER: 'asset.timeline.show.player',
        STORAGE_STATUS_TYPE: {
            AVAILABLE: 'AVAILABLE',
            CREATING: 'CREATING',
            RESTORE: 'RESTORE'
        },
        UPDATE: 'asset.timeline.update',
        toArray: function(constant) {
            return Object.keys(this[constant])
                .map(k => this[constant][k])
                .sort((a, b) => a.name.localeCompare(b.name));
        }
    },
    VIDEO: {
        GET: {
            SUCCESS: 'asset.timeline.video.get.success'
        }
    }
};

class TimelineAssetActions {

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TIMELINE.CLEAR
        });

        return;
    }

    findById(id) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TIMELINE.FIND_BY_ID.START,
            id
        });

        const timelinePromise = Request.get(`asset/video-timeline/${id}`).exec().then(r => r).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.TIMELINE.FIND_BY_ID.ERROR,
            });
            NotificationActions.showAlertDanger('hardac.timeline.find-by-id.error');
            throw err;
        });

        const clipPromise = Request.get(`asset/video-timeline/${id}/clip`).exec().then(r => r).catch(err => {
            NotificationActions.showAlertDanger('hardac.timeline.find-by-id.clips.error');
            throw err;
        });

        const audioProfilePromise = Request.get(`asset/video-timeline/${id}/audio`).exec().then(r => r).catch(err => {
            NotificationActions.showAlertDanger('hardac.timeline.find-by-id.audio-profile.error');
            throw err;
        });

        const actionHistoryQuery = {
            'action-object': ActionHistoryConstants.ACTION_OBJECTS.VIDEOTIMELINE,
            'object-id': id,
            offset: 0,
            size: 4
        };
        const historyPromise = Request.get('system/action-history').query(actionHistoryQuery).exec().then(r => r).catch(err => {
            NotificationActions.showAlertDanger('hardac.timeline.find-by-id.history.error');
            throw err;
        });

        Promise.all([
            timelinePromise,
            clipPromise,
            audioProfilePromise,
            historyPromise
        ]).spread((timelineRes, clipRes, audioProfileRes, historyRes) => {
            let history = historyRes.body.results;
            history.sort((h1, h2) => h2.actionDate.localeCompare(h1.actionDate));
            let audioProfile = AudioProfileToArray(audioProfileRes.body);
            Dispatcher.dispatch({
                actionType: CONSTANTS.TIMELINE.FIND_BY_ID.SUCCESS,
                audioProfile: Immutable.fromJS(audioProfile),
                clips: Immutable.fromJS(clipRes.body.sort((a, b) => a.displayOrder - b.displayOrder)),
                history: Immutable.fromJS(history),
                timeline: Immutable.fromJS(timelineRes.body)
            });
            return;
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.TIMELINE.FIND_BY_ID.ERROR
            });

            throw err;
        });
        return;
    }

    /**
     * Save timeline data, clips, assignedCatalogs and talent
     *
     * @param {string} timelineId
     * @param {Immutable.List} audioProfile
     * @param {Immutable.List} clips
     * @param {Immutable.List} originalClips
     * @param {Immutable.List} assignedCatalogs
     * @param {Immutable.List} originalAssignedCatalogs
     * @param {Immutable.List} talent
     * @param {Immutable.List} originalTalent
     * @param {Immutable.List} assignedTitles
     * @param {Immutable.List} originalAssignedTitles
     */
    save(id, timeline, assignedCatalogs, originalAssignedCatalogs, talent, originalTalent, assignedTitles, originalAssignedTitles, manageTitles = false) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TIMELINE.SAVE.START,
        });
        // FIXME: temp fix, remove mediaInfo object so that Cloudflare
        // doesn't block our requests.
        let t = ['mediaInfo'].reduce((r, p) => {
            return r.delete(p);
        }, timeline).toJS();
        if (t.active) {
            t.active = 1;
        } else {
            t.active = 0;
        }
        const saveTimelineRequest = Request.put(`asset/video-timeline/${id}`).send(t).exec().then(r => r).catch(err => {
            NotificationActions.showAlertDanger('hardac.timeline.save.summary.error');
            throw err;
        });
        Promise.all([
            saveTimelineRequest,
            ...AssetCatalogActions.assetCatalogSave(id, assignedCatalogs, originalAssignedCatalogs),
            ...AssetTalentActions.assetTalentSave(id, talent, originalTalent)
        ]).then(() => {
            let requests = [];
            if (manageTitles) {
                requests.push(AssetTitleActions.assetTitlesSave(timeline, assignedTitles, originalAssignedTitles));
            }
            return Promise.all(requests);
        }).then(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.TIMELINE.SAVE.SUCCESS,
            });
            NotificationActions.showAlertSuccess('hardac.timeline.save.success');
            return;
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.TIMELINE.SAVE.ERROR
            });
            NotificationActions.showAlertDanger('hardac.timeline.save.error');
            throw err;
        });
        return;
    }

    sendToOAP(timelineId, showCode) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TIMELINE.SEND_TO_OAP.START
        });

        const requestBody = {
            showCode: showCode
        };

        Request.put(`asset/video-timeline/${timelineId}/send-to-oap`)
            .send(requestBody)
            .exec()
            .then((res) => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.TIMELINE.SEND_TO_OAP.SUCCESS,
                    barcode: res.body.Barcode_Serial_No_t
                });
                NotificationActions.showAlertSuccess('asset.timeline.send-to-oap.success');
                return;
            }).catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.TIMELINE.SEND_TO_OAP.ERROR
                });
                NotificationActions.showAlertDanger('asset.timeline.send-to-oap.error');
                throw err;
            });
        return;
    }

    showPlayer(timeline) {
        const proxyFileId = timeline.getIn(['proxyFile', 'id']);
        const timelineId = timeline.get('id');

        Request.get(`asset/video-timeline/${timelineId}/asset-file/${proxyFileId}/streamURL`).exec()
            .then(proxyResponse => {
                const {body: {dashLocatorUri, hlsLocatorUri, videoDrmTokens}} = proxyResponse;
                const streams = Immutable.fromJS([{
                    drmMpegDashUrl: dashLocatorUri,
                    drmHlsUrl: hlsLocatorUri
                }]);
                const drmToken = Immutable.fromJS(videoDrmTokens);

                timeline = timeline.set('streams', streams);
                timeline = timeline.set('drmToken', drmToken);
                Dispatcher.dispatch({
                    actionType: CONSTANTS.TIMELINE.SHOW_PLAYER,
                    video: timeline
                });
                return;
            })
            .catch(err => {
                NotificationActions.showAlertDanger('hardac.timeline.proxy.src.get.error');
                err.defaultPrevented = true;
                throw err;
            });
        return;
    }

    update(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TIMELINE.UPDATE,
            attr,
            value
        });

        return;
    }

}

const actions = new TimelineAssetActions();

export {
    actions as TimelineAssetActions,
    CONSTANTS as TimelineAssetConstants
};
