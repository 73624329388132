/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

/* eslint-disable no-unused-vars */

export type TimeData = {
    hours: number,
    minutes: number,
    seconds: number,
    ms: number,
};

export type FrameData = {
    hours: number,
    minutes: number,
    seconds: number,
    frames: number,
}

type FactoryOptions = {
    offset: string,
    frameRate: number,
}

const DEFAULT_OPTIONS: FactoryOptions = {
    offset: '00:00:00:000',
    frameRate: 23.976
};

function create(opts: Partial<FactoryOptions> = {}) {
    const options = {...DEFAULT_OPTIONS, ...opts};
    const frameRate = options.frameRate;
    let offset = options.offset;
    if (typeof offset !== 'string') {
        offset = '00:00:00:000';
    }

    const offsetInSeconds = timeToSeconds(offset);

    function secondsToTimecode(value: number) {
        return frameDataToTimecode(secondsToFrameData(value, frameRate));
    }

    function frameDataToTimecode(frameData: FrameData) {
        const seconds = offsetInSeconds + timeDataToSeconds({
            hours: frameData.hours,
            minutes: frameData.minutes,
            seconds: frameData.seconds,
            ms: 0,
        });

        const timeData = secondsToTimeData(seconds);
        return convertToString([timeData.hours, timeData.minutes, timeData.seconds, frameData.frames]);
    }

    function timeToTimecode(value: string) {
        return secondsToTimecode(timeToSeconds(value));
    }

    function timecodeToTime(value: string) {
        return secondsToTime(timecodeToSeconds(value));
    }

    function timecodeToSeconds(value: string) {
        const frameData = timecodeToFrameData(value);
        const seconds = timeDataToSeconds({
            hours: frameData.hours,
            minutes: frameData.minutes,
            seconds: frameData.seconds,
            ms: 0,
        }) - offsetInSeconds;

        const timeData = secondsToTimeData(seconds);

        const framesCount = frameDataToFrames({
            hours: timeData.hours,
            minutes: timeData.minutes,
            seconds: timeData.seconds,
            frames: frameData.frames,
        }, frameRate);

        return framesCount / frameRate;
    }

    function secondsToFrames(seconds: number) {
        return framesInSeconds(seconds, frameRate);
    }

    function framesToSeconds(framesCount: number) {
        return framesCount / frameRate;
    }

    function framesToTime(framesCount: number, showMs = true) {
        return secondsToTime(framesCount / frameRate, showMs);
    }

    function framesToTimecode(framesCount: number) {
        return frameDataToTimecode(framesToFrameData(framesCount, frameRate));
    }

    function frameDataToSeconds(data: FrameData) {
        return framesToSeconds(frameDataToFrames(data, frameRate));
    }

    return {
        framesToSeconds, framesToTime, framesToTimecode,
        timecodeToSeconds, timecodeToTime, timeToTimecode,
        secondsToFrames, secondsToTime, secondsToTimecode,
        secondsToFrameData, frameDataToSeconds,
    };
}

export default {
    create
};

function timecodeToFrameData(timecode: string) {
    return parse(timecode, '^([012]\\d):(\\d\\d):(\\d\\d):(\\d\\d)$', 'frames');
}

function frameDataToFrames(frameData: FrameData, frameRate: number) {
    return (frameData.hours * 3600 + frameData.minutes * 60 + frameData.seconds) * Math.round(frameRate) + frameData.frames;
}

function secondsToFrameData(value: number, frameRate: number) {
    return framesToFrameData(framesInSeconds(value, frameRate), frameRate);
}

function framesToFrameData(totalFramesCount: number, frameRate: number): FrameData {
    const roundFrameRate = Math.round(frameRate);

    const hours = Math.floor(totalFramesCount / (roundFrameRate * 3600));
    const minutes = Math.floor(totalFramesCount / (roundFrameRate * 60)) % 60;
    const seconds = Math.floor(totalFramesCount / roundFrameRate) % 60;
    const framesCount = totalFramesCount % roundFrameRate;

    return {hours, minutes, seconds, frames: framesCount};
}

function framesInSeconds(value: number, frameRate: number) {
    return Math.floor(value * frameRate);
}

function timeToSeconds(time: string) {
    const timeData = parse(time, '^([012]\\d):(\\d\\d):(\\d\\d):(\\d\\d\\d)$', 'ms');
    return timeDataToSeconds(timeData);
}

function secondsToTime(seconds: number, showMs = true, msDelimiter = ':') {
    const data = secondsToTimeData(seconds);
    const arr: Array<string | number> = [data.hours, data.minutes, data.seconds];
    const res = convertToString(arr);
    if (showMs) {
        return `${res}${msDelimiter}${pad3(data.ms)}`;
    }
    return res;
}

export function secondsToTimeData(value: number): TimeData {
    const hours = Math.floor(value / 3600);
    const minutes = Math.floor(value / 60) % 60;
    const seconds = Math.floor(value) % 60;
    const ms = Math.round(value % 1 * 1000);
    return {hours, minutes, seconds, ms};
}

function timeDataToSeconds(value: TimeData) {
    return (value.hours * 3600) + (value.minutes * 60) + value.seconds + (value.ms / 1000);
}

function convertToString(arr: Array<string | number>) {
    return arr.map(pad).join(':');
}

function parse(str: string, pattern: string, factorialName: 'ms'): TimeData;
function parse(str: string, pattern: string, factorialName: 'frames'): FrameData;
function parse(str: string, pattern: string, factorialName: any): any {
    const parts = str.match(pattern);
    if (!parts) {
        throw new Error('Time string is incorrect');
    }
    const hours = parseInt(parts[1]);
    const minutes = parseInt(parts[2]);
    const seconds = parseInt(parts[3]);
    const factorial = parseInt(parts[4]);
    return {hours, minutes, seconds, [factorialName]: factorial};
}

function pad(number: number) {
    if (typeof number !== 'number') {
        return number;
    }
    if (number >= 10) {
        return '' + number;
    }
    return '0' + number;
}

function pad3(number: number) {
    return ('0' + pad(number)).substr(-3);
}
