/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import jQuery from 'jquery';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, ControlLabel, FormGroup} from 'react-bootstrap';

import BulkAddModal from '../../common/bulk-add-modal/bulk-add-modal';
import {DownloadActions} from '../../common/download/download-actions';
import {FormRow, FormSection} from '../../common/form/form';
import {MODAL_TYPE} from '../../common/notification/modal';
import {NotificationActions} from '../../common/notification/notification-actions';
import SearchBox from '../../common/search-box/search-box';
import {WithStoreOnRoute} from '../../common/store-on-route';
import Pagination from '../../common/table/pagination';
import {Debounce} from '../../common/utils/utils';
import Preloader from '../../preloader';
import {UserConstants} from '../../user/user-actions';
import {GuildActions} from '../guild-actions';
import GuildStore from '../guild-store';

require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('../../styles/data-tables-brainiac.css');
require('datatables.net-responsive-bs');

class Table extends React.Component {
    static get propTypes() {
        return {
            activePage: PropTypes.number.isRequired,
            users: PropTypes.instanceOf(Immutable.List).isRequired,
            userCount: PropTypes.func.isRequired,
            onPageChange: PropTypes.func.isRequired,
            totalPages: PropTypes.number.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
        };
    }

    constructor(props) {
        super(props);

        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        this.$table = jQuery('#list-users-table');
        let dataTableConfig = {
            autoWidth: false,
            order: [[1, 'dec']],
            iDisplayLength: 100,
            info: false,
            ordering: false,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        };
        this.$tableAPI = this.$table.DataTable(dataTableConfig);
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    }

    componentWillUpdate(nextProps) {
        this.$tableAPI.clear();

        this.props.userCount(nextProps.users.size);

        nextProps.users.forEach((user) => {
            let row = [];
            let status = Object.values(UserConstants.STATUS).filter((stat) => stat.id === user.get('accountStatus'))[0];

            row.push(
                `<a href="/accounts/${user.get('id')}">${user.get('fullName')}</a>`,
                user.get('email'),
                `<span class="label ${status.label}" title="${this.context.intl.messages[status.tip]}" data-toggle="tooltip"><i class="fa ${status.icon}"></i> <span class="hidden-xs hidden-sm">  ${status.description}</span></span>`,
                '',
            );

            this.$tableAPI.row.add(row);

            return;
        });

        this.$tableAPI.draw(false);

        this.handleResize();
    }

    componentWillUnmount() {
        if (this.$tableAPI) {
            this.$tableAPI.destroy();
        }

        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        this.$tableAPI.responsive.recalc();
    }

    render() {
        let pagination;
        if (this.props.onPageChange) {
            pagination = (
                <div className="row">
                    <div className="col-sm-7 col-sm-offset-5">
                        <Pagination
                            activePage={this.props.activePage}
                            onChange={this.props.onPageChange}
                            totalPages={this.props.totalPages}
                        />
                    </div>
                </div>
            );

        }

        return (
            <div>
                <table id="list-users-table" className="table table-bordered table-striped responsive">
                    <thead>
                        <tr>
                            <th rowSpan="1" colSpan="1">{this.context.intl.messages['common.name']}</th>
                            <th rowSpan="1" colSpan="1">{this.context.intl.messages['common.email']}</th>
                            <th rowSpan="1" colSpan="1">{this.context.intl.messages['common.status']}</th>
                        </tr>
                    </thead>
                    <tbody onClick={this.handleRowClick}>
                    </tbody>
                </table>
                {pagination}
            </div>
        );
    }
}

class Accounts extends React.Component {
    static get propTypes() {
        return {
            disabled: PropTypes.bool.isRequired,
            guildId: PropTypes.number.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
        };
    }

    static calculateState() {
        return {
            guild: GuildStore.getState().get('guild'),
            showPreloader: GuildStore.getState().get('showPreloader'),
            size: GuildStore.getState().get('size'),
            total: GuildStore.getState().get('total'),
            users: GuildStore.getState().get('users'),
        };
    }

    static getStores() {
        return [GuildStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            showBulkAddModal: false
        }, this.constructor.calculateState());

        this.getUserCount = this.getUserCount.bind(this);
        this.handleBulkAddAccounts = this.handleBulkAddAccounts.bind(this);
        this.handleBulkAddAccountsCSV = this.handleBulkAddAccountsCSV.bind(this);
        this.handleCreateChildAccounts = this.handleCreateChildAccounts.bind(this);
        this.handleDownloadUsers = this.handleDownloadUsers.bind(this);
        this.handleHideBulkAddModal = this.handleHideBulkAddModal.bind(this);
        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
        this.showBulkAddModal = this.showBulkAddModal.bind(this);
        this.showBulkAddCSVModal = this.showBulkAddCSVModal.bind(this);
        this.showCreateChildAccountsModal = this.showCreateChildAccountsModal.bind(this);
    }

    componentDidMount() {
        GuildActions.getUsers(this.props.guildId, this.getRouteState().get('offset'), this.state.size, this.getRouteState().get('name'));
    }

    getUserCount() {
        this.refs.userCount.innerHTML = this.state.total;
    }

    handleBulkAddAccounts(bulkAddCount, bulkAddAdmin) {
        if (bulkAddCount === undefined || bulkAddCount === '' || bulkAddCount <= 0) {
            return;
        }

        GuildActions.bulkAddUsers(this.state.guild, bulkAddCount, bulkAddAdmin);
    }

    handleBulkAddAccountsCSV() {
        if (document.getElementById('csvFile').files.length === 0) {
            return;
        }

        GuildActions.bulkAddUsersCSV(this.state.guild, document.getElementById('csvFile').files[0]);
    }

    handleCreateChildAccounts() {
        if (document.getElementById('csvChildAccountsFile').files.length === 0) {
            return;
        }

        GuildActions.createChildAccounts(this.state.guild, document.getElementById('csvChildAccountsFile').files[0]);
    }

    handleDownloadUsers() {
        DownloadActions.startDownloadExecution(`guild/${this.props.guildId}/user?download=true`);
    }

    handleHideBulkAddModal() {
        this.setState({
            showBulkAddModal: false
        });
    }

    handleLoadPage(pageNumber) {
        this.setRouteState('offset', pageNumber * this.state.size).apply();
        GuildActions.getUsers(this.props.guildId, pageNumber * this.state.size, this.state.size, this.getRouteState().get('name'));
    }

    handleSearchTerm(term) {
        this.setRouteState('name', term).clearRouteState('offset').apply();
        GuildActions.getUsers(this.props.guildId, 0, this.state.size, term);
    }

    showBulkAddModal() {
        this.setState({
            showBulkAddModal: true
        });
    }

    showBulkAddCSVModal(event) {
        event.preventDefault();
        let modal = (
            <FormSection>
                <FormRow>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['guild.accounts.bulk_generate_csv.modal.label']}</ControlLabel>
                        <input
                            accept=".csv"
                            className="Mb(3px)"
                            id="csvFile"
                            onChange={this.handleSelectCSV}
                            type="file"
                        />
                    </FormGroup>
                </FormRow>
            </FormSection>
        );

        NotificationActions.show(
            MODAL_TYPE.DEFAULT,
            this.context.intl.messages['guild.accounts.bulk_generate_csv.modal.title'],
            modal,
            this.context.intl.messages['guild.accounts.bulk_generate_csv.modal.ok'],
            this.handleBulkAddAccountsCSV
        );
    }

    showCreateChildAccountsModal(event) {
        event.preventDefault();
        let modal = (
            <FormSection>
                <FormRow>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['guild.accounts.child_accounts.modal.label']}</ControlLabel>
                        <input
                            accept=".csv"
                            className="Mb(3px)"
                            id="csvChildAccountsFile"
                            onChange={this.handleSelectCSV}
                            type="file"
                        />
                    </FormGroup>
                </FormRow>
            </FormSection>
        );

        NotificationActions.show(
            MODAL_TYPE.DEFAULT,
            this.context.intl.messages['guild.accounts.child_accounts.modal.title'],
            modal,
            this.context.intl.messages['guild.accounts.child_accounts.modal.ok'],
            this.handleCreateChildAccounts
        );
    }

    render() {
        let activePage = Math.ceil((this.getRouteState().get('offset') || 0) / this.state.size) || 0;
        let disableDownload = !this.state.users.size;
        let totalPages = Math.ceil(this.state.total / this.state.size) || 0;

        const bulkAddAccountsModal = (
            <BulkAddModal
                onHide={this.handleHideBulkAddModal}
                onBulkAddAccounts={this.handleBulkAddAccounts}
                show={this.state.showBulkAddModal}
                showAdminAccountsCheck={true}
            />
        );

        return (
            <div>
                <Preloader show={this.state.showPreloader} fixed loadingDots>
                    <h3>
                        <i className="fa fa-user"></i>&nbsp;{this.context.intl.messages['guild.accounts.users']}&nbsp;<small ref="userCount"></small>
                        <Button bsStyle="default" className="pull-right" disabled={!this.props.disabled} onClick={this.showCreateChildAccountsModal}>
                            <i className="fas fa-upload"></i>&nbsp;{this.context.intl.messages['guild.accounts.child_accounts.btn']}
                        </Button>
                        &nbsp;
                        <Button bsStyle="default" className="pull-right Mr(3px)" disabled={!this.props.disabled} onClick={this.showBulkAddCSVModal}>
                            <i className="fas fa-upload"></i>&nbsp;{this.context.intl.messages['guild.accounts.import']}
                        </Button>
                        &nbsp;
                        <Button bsStyle="default" className="pull-right Mr(3px)" disabled={!this.props.disabled} onClick={this.showBulkAddModal}>
                            <i className="fas fa-users"></i>&nbsp;{this.context.intl.messages['guild.accounts.bulk_generate']}
                        </Button>
                    </h3>
                    <hr></hr>
                    <div className="container-fluid no-x-padding">
                        <div className="row">
                            <div className="col-md-6 show-grid">
                                <SearchBox onChange={this.handleSearchTerm} value={this.getRouteState().get('name')}/>
                            </div>
                        </div>
                        <div className="container-fluid no-x-padding">
                            <div className="row">
                                <div className="box">
                                    <div className="box-body padding-y-20" >
                                        <a
                                            className="btn btn-default pull-right Mr(3px) margin-bottom-10"
                                            disabled={disableDownload}
                                            onClick={this.handleDownloadUsers}
                                        >
                                            <i className="fas fa-download"></i>&nbsp;{this.context.intl.messages['guild.accounts.download.btn']}
                                        </a>
                                        <Table
                                            activePage={activePage}
                                            users={this.state.users}
                                            userCount={this.getUserCount}
                                            onPageChange={this.handleLoadPage}
                                            totalPages={totalPages}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Preloader>
                {bulkAddAccountsModal}
            </div>
        );
    }
}

export default WithStoreOnRoute(Container.create(Accounts));

export {
    Table
};
