/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import jQuery from 'jquery';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {Sort} from '../../common/local-search/filter';
import {WithStoreOnRoute} from '../../common/store-on-route';

require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('datatables.net-responsive-bs');

class Table extends Component {

    static get propTypes() {
        return {
            backgrounds: PropTypes.instanceOf(Immutable.List).isRequired,
            displayLinks: PropTypes.bool,
            onSelect: PropTypes.func,
            onSelectAll: PropTypes.func,
            selectAll: PropTypes.bool
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
            router: PropTypes.object
        };
    }

    static get defaultProps() {
        return {
            displayLinks: false,
            onSelect: undefined,
            onSelectAll: undefined,
            router: undefined,
            selectAll: false
        };
    }

    constructor(props) {
        super(props);

        this.getSortByClass = this.getSortByClass.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.handleSort = this.handleSort.bind(this);
    }

    componentDidMount() {
        this.$table = jQuery('#background-table');
        this.$tableAPI = this.$table.DataTable({
            autoWidth: false,
            columnDefs: [{
                className: 'control',
                targets:   -1,
                width: 20
            }, {
                className: 'Whs(nw)',
                targets: 'no-wrap'
            }],
            iDisplayLength: 1,
            info: false,
            ordering: false,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        });

        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        return;
    }

    componentWillUpdate(nextProps) {
        this.$tableAPI.clear();

        let backgrounds = Sort(nextProps.backgrounds, this.getRouteState(nextProps).get('sortBy', 'name'), this.getRouteState(nextProps).get('sortDir', 'asc'));

        backgrounds.forEach((background, index) => {

            let lastModifiedDate = Moment(background.get('lastModifiedDate'));
            if (lastModifiedDate.isValid()) {
                lastModifiedDate = lastModifiedDate.format(this.context.intl.messages['date-format']);
            } else {
                lastModifiedDate = '-';
            }
            let row = [];

            if (nextProps.onSelectAll && nextProps.onSelect) {
                row.unshift(`<input type="checkbox" class="remove-item" data-name="${background.get('fileName')}" data-index="${index}" ${background.get('__selected') ? 'checked' : ''}/>`);
            }

            let backgroundName = background.get('fileName');
            if (nextProps.displayLinks) {
                backgroundName = `<a href="${background.get('url')}" target="_blank">${background.get('fileName')}</a>`;
            }

            row.push(
                backgroundName,
                lastModifiedDate,
                background.get('fileSize') ? background.get('fileSize') : '',
                ''
            );

            this.$tableAPI.row.add(row);

            return;
        });

        this.$tableAPI.draw(false);
        this.handleResize();

        return;
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    getSortByClass(column) {
        let r = 'sorting';

        if (column === this.getRouteState().get('sortBy', 'name')) {
            r = `sorting_${this.getRouteState().get('sortDir', 'asc')}`;
        }

        return r;
    }

    handleResize() {
        this.$tableAPI.responsive.recalc();
        return;
    }

    /**
     * This function is kind of "special" because it needs to handle
     * events bubbled from the data table rows, these rows cannot use
     * the JSX syntax because they are created by the data table
     * jQuery plugin instead of React.
     */
    handleRowClick(event) {
        switch (true) {
        case !!~event.target.className.indexOf('remove-item'):
            this.props.onSelect(event.target.checked, event.target.getAttribute('data-name'));
            break;
        case !!~event.target.className.indexOf('remove-all'):
            this.props.onSelectAll(event.target.checked);
            break;
        }
        return;
    }

    handleSort(column) {
        let newSortDir = 'asc';
        if (this.getRouteState().get('sortBy', 'name') === column && this.getRouteState().get('sortDir', 'asc') === 'asc') {
            newSortDir = 'desc';
        }
        this.setRouteState('sortBy', column)
            .setRouteState('sortDir', newSortDir)
            .apply();
    }

    render() {
        let showSelect = !!(this.props.onSelectAll && this.props.onSelect);
        return (
            <div>
                <table id="background-table" className="table table-bordered table-striped responsive">
                    <thead>
                        <tr>
                            {showSelect && <th className="no-sort"><input checked={this.props.selectAll} className="remove-all" disabled={!this.props.backgrounds.size} onClick={this.handleRowClick} type="checkbox"/></th>}
                            <th
                                className={this.getSortByClass('fileName')}
                                onClick={this.handleSort.bind(this, 'fileName')}>
                                {this.context.intl.messages['login-backgrounds.name']}
                            </th>
                            <th
                                className={this.getSortByClass('lastModifiedDate')}
                                onClick={this.handleSort.bind(this, 'lastModifiedDate')}>
                                {this.context.intl.messages['login-backgrounds.last-modified']}
                            </th>
                            <th className="no-wrap">{this.context.intl.messages['login-backgrounds.size']}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody onClick={this.handleRowClick}>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default WithStoreOnRoute(Table);
