/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {DropdownButton, Glyphicon, MenuItem} from 'react-bootstrap';
import {Link} from 'react-router';

class SerieNavigation extends React.Component {
    static get propTypes() {
        return {
            location: PropTypes.object,
            serieNavigation: PropTypes.instanceOf(Immutable.Map),
            titleId: PropTypes.number.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            location: undefined,
            serieNavigation: undefined
        };
    }

    render() {
        let currentTab = '';
        let episodesLinks;
        let episodesTitle = this.context.intl.messages['titles.create.nav.sections.episodes.title'];
        let hasSeasonSerieNavLinks = true;
        let season;
        let seasonsLinks;
        let seasonsTitle = this.context.intl.messages['titles.create.nav.sections.season.title'];
        let seriesLink;

        if (this.props.location) {
            let pathnames = this.props.location.pathname.split('/');
            if (pathnames.length > 3) {
                currentTab = '/' + pathnames[3];
            }
        }
        if (!this.props.serieNavigation || !this.props.serieNavigation.get('serieMap') || !this.props.titleId) {
            // render null if data is not available
            return null;
        }

        let where = this.props.serieNavigation.getIn(['serieMap', `${this.props.titleId}`]);
        if (!where) {
            return null;
        }

        switch (where.size) {
        case 0: // serie
            seasonsTitle = this.context.intl.messages['titles.create.nav.sections.series.title'];
            break;
        case 2: // episode
            let episode = this.props.serieNavigation.getIn(['seasons', where.get(0), 'episodes', where.get(1)]);
            episodesTitle = this.context.intl.messages['titles.create.nav.sections.episode.title']+ ' ' +episode.get('episodeNumber');
            // fallthrough
        default: // season & episode (size should be 2 and 2 only)
            if (this.props.serieNavigation.get('seasons')) {
                season = this.props.serieNavigation.getIn(['seasons', where.get(0)]);
                seasonsTitle = this.context.intl.messages['titles.create.nav.sections.season.title']+ ' ' + season.get('seasonNumber');
                episodesLinks = season.get('episodes').map( (e, i) => {
                    const linkUrl = `/titles/${e.get('titleId')}${currentTab}`;
                    return (
                        <MenuItem className="as-link" componentClass={Link} key={i} href={linkUrl} to={linkUrl}>{this.context.intl.messages['titles.create.nav.sections.episode.title']} {e.get('episodeNumber')}</MenuItem>
                    );
                });
            } else {
                seasonsTitle = this.context.intl.messages['titles.create.nav.sections.series.title'];
                episode = this.props.serieNavigation.get('episodes').find(e => e.get('childTitleId') === parseInt(this.props.titleId, 10));
                episodesTitle = this.context.intl.messages['titles.create.nav.sections.episode.title']+ ' ' + episode.get('orderWithinParent');
            }
        }

        if (this.props.serieNavigation.get('seasons')) {
            seasonsLinks = this.props.serieNavigation.get('seasons').map((s, i) => {
                const linkUrl = `/titles/${s.get('titleId')}${currentTab}`;
                return (
                    <MenuItem className="as-link" componentClass={Link} key={i} href={linkUrl} to={linkUrl}>{this.context.intl.messages['titles.create.nav.sections.season.title']} {s.get('seasonNumber')}</MenuItem>
                );
            });
        } else {
            episodesLinks = this.props.serieNavigation.get('episodes').map( (e, i) => {
                const linkUrl = `/titles/${e.get('childTitleId')}${currentTab}`;
                return (
                    <MenuItem className="as-link" componentClass={Link} key={i} href={linkUrl} to={linkUrl}>{this.context.intl.messages['titles.create.nav.sections.episode.title']} {e.get('orderWithinParent')}</MenuItem>
                );
            });
        }

        const seriesUrl = `/titles/${this.props.serieNavigation.get('titleId')}${currentTab}`;
        seriesLink = <MenuItem className="as-link" componentClass={Link} href={seriesUrl} to={seriesUrl}>{this.context.intl.messages['titles.create.nav.sections.series.title']}</MenuItem>;

        if ((!seasonsLinks || !seasonsLinks.size) &&
            (!episodesLinks || !episodesLinks.size)) {
            hasSeasonSerieNavLinks = false;
        }

        return (
            <div className="padding-top-15 padding-x-15 margin-bottom-20 serie-navigation">
                <div className="row">
                    <div className="col-sm-6">
                        <DropdownButton
                            className="btn btn-default-outline btn-block dropdown-toggle"
                            disabled={!hasSeasonSerieNavLinks}
                            id="season-serie-navigation"
                            title={seasonsTitle}
                        >
                            {seriesLink}
                            {seasonsLinks}
                        </DropdownButton>
                    </div>
                    <div className="col-sm-6" style={{paddingLeft:'0px'}}>
                        <DropdownButton
                            className="btn btn-default-outline btn-block dropdown-toggle"
                            disabled={!episodesLinks || !episodesLinks.size}
                            id="episode-serie-navigation"
                            title={episodesTitle}
                        >
                            {episodesLinks}
                        </DropdownButton>
                    </div>
                </div>
            </div>
        );
    }
}

class EpisodeNavigation extends React.Component {
    static get propTypes() {
        return {
            location: PropTypes.object,
            serieNavigation: PropTypes.instanceOf(Immutable.Map),
            titleId: PropTypes.number.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            location: undefined,
            serieNavigation:undefined
        };
    }

    handleLinkClick(href, event) {
        if (!href) {
            event.preventDefault();
        }

        return;
    }

    render() {
        // if no episode => show nothing
        if (!this.props.serieNavigation || !this.props.serieNavigation.get('serieMap') || !this.props.titleId) {
            // render null if data is not available
            return null;
        }

        let where = this.props.serieNavigation.getIn(['serieMap', `${this.props.titleId}`]);

        // mini series episode check
        let hasSeason = this.props.serieNavigation.get('seasons') !== undefined;
        let hasEpisode = this.props.serieNavigation.get('episodes') !== undefined;
        let isMiniSerieEpisode = !hasSeason && hasEpisode && this.props.serieNavigation.get('titleId') !== parseInt(this.props.titleId, 10);

        if (!where || where.size !== 2 && !isMiniSerieEpisode) {
            return null;
        }

        let currentTab = '';
        if (this.props.location) {
            let pathnames = this.props.location.pathname.split('/');
            if (pathnames.length > 3) {
                currentTab = '/' + pathnames[3];
            }
        }

        // Initialize the previous and next with something falsy but different
        // from undefined so that the Link object doesn't break.
        // This is because the "to" property must not be undefined or null.
        let episodeOrder;
        let episodes;
        let previous = '';
        let next = '';

        if (isMiniSerieEpisode) {
            episodes = this.props.serieNavigation.get('episodes');
            episodeOrder = where.get(0);
            if (episodeOrder !== 0) {
                previous = `/titles/${episodes.getIn([episodeOrder - 1, 'childTitleId'])}${currentTab}`;
            }
            if (episodeOrder + 1 < episodes.size) {
                next = `/titles/${episodes.getIn([episodeOrder + 1, 'childTitleId'])}${currentTab}`;
            }
        } else {
            episodes = this.props.serieNavigation.getIn(['seasons', where.get(0), 'episodes']);
            episodeOrder = where.get(1);
            if (episodeOrder !== 0) {
                previous = `/titles/${episodes.getIn([episodeOrder - 1, 'titleId'])}${currentTab}`;
            }
            if (episodeOrder + 1 < episodes.size) {
                next = `/titles/${episodes.getIn([episodeOrder + 1, 'titleId'])}${currentTab}`;
            }
        }

        return (
            <span className="d-inline-block">
                <Link to={previous} disabled={!previous} className="btn btn-primary-outline btn-sm" onClick={this.handleLinkClick.bind(this, previous)}><Glyphicon glyph="chevron-left"/></Link>&nbsp;
                <Link to={next} disabled={!next} className="btn btn-primary-outline btn-sm" onClick={this.handleLinkClick.bind(this, next)}><Glyphicon glyph="chevron-right"/></Link>
            </span>
        );
    }
}

export {
    EpisodeNavigation,
    SerieNavigation
};
