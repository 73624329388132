/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {TitleConstants} from './title-actions';
import {AssetTabConstants} from '../assets-tab/asset-tab-actions';
import Validations from '../common/validations/validations';
import Dispatcher from '../dispatcher/dispatcher';
import enUS from '../messages/en-US';
import Request from '../request';
import {ActionHistoryConstants} from '../system/action-history/action-history-actions';
import SessionStore from '../user/session/session-store';

import TalentStore from '~/src/talent/talent-store';

/**
 * Look for a constant with id === objectId in a map.
 */
const findInConstant = function(constants, objectId) {
    return Immutable.fromJS(
        Object.keys(constants)
            .map(k => constants[k])
            .filter(obj => objectId === obj.id)[0]
    );
};

const TitleValidations = {
    actionType: {validations: [Validations.required]},
    appBackgroundColor: {
        label: 'titles.create.style.background-color',
        validations: [Validations.hexadecimal]
    },
    appTextColor: {
        label: 'titles.create.style.text-color',
        validations: [Validations.hexadecimal]
    },
    aspectRatioId: {validations: []},
    audioId: {validations: []},
    category: {validations: [Validations.required]},
    copyrightYear: {validations: []},
    madeForType: {validations: [Validations.required]},
    mpmNumber: {
        validations: [Validations.promise(
            () => enUS['titles.alerts-warnings.mpm-number.pending'],
            () => enUS['titles.alerts-warnings.mpm-number.error'],
            value => Request.get('title')
                .query({
                    'mpm-number': value,
                    active: 'BOTH',
                    operator: 'AND',
                    'sort-field-name': 'updatedDate',
                    'sort-direction': 'desc'
                })
                .exec()
                .then(res => !res.body.results.some(title => title.mpmNumber === value))
                .catch(() => {
                    throw new Error(enUS['titles.alerts-warnings.mpm-number.failed']);
                })
        )]
    },
    movieColor: {validations: []},
    name: {validations: [Validations.required]},
    parentalRatingId: {validations: []},
    referenceId: {
        label: 'titles.create.summary.reference-id',
        validations: [Validations.alphaNumericNoSpaces,
            Validations.promise(
                () => enUS['titles.alerts-warnings.reference-id.pending'],
                () => enUS['titles.alerts-warnings.reference-id.error'],
                value => Request.get('title')
                    .query({
                        'reference-id': value,
                        active: 'BOTH',
                        operator: 'AND',
                        'sort-field-name': 'updatedDate',
                        'sort-direction': 'desc'
                    })
                    .exec()
                    .then(res => !res.body.results.some(title => title.referenceId === value))
                    .catch(() => {
                        throw new Error(enUS['titles.alerts-warnings.reference-id.failed']);
                    })
            )]
    },
    sourceMediaId: {validations: []},
    standardId: {validations: []},
    releaseSeasonDisplayName: {
        // Adding the release season display name validation here so we only see one error for all the releases
        validations: [Validations.custom(
            () => 'Display Name is required on the first release if any release has a date type of Display Name',
            () => {
                const releases = store.getState().getIn(['title', 'releases']);
                if (releases.some(c => c.get('dateType') === TitleConstants.RELEASE.DATE_TYPES.DISPLAY_NAME.id)) {
                    return !!releases.first().get('seasonDisplayName');
                }

                return true;
            }
        )]
    },
    /* STUDIO-13276: disable the log lines validations, because some titles in the database have more than 160/80 characters
    log60Lines: {
        label: 'titles.create.summary.log-60-lines',
        validations: [
            Validations.max(60)
        ]
    },
    log180Lines: {
        label: 'titles.create.summary.log-180-lines',
        validations: [
            Validations.max(180)
        ]
    }, */
    releases: {
        airDayType: {
            validations: []
        },
        airHour: {
            validations: []
        },
        date: {
            validations: []
        },
        dateStatusType: {
            validations: []
        },
        dateType: {
            validations: []
        },
        domesticReleaseCompanyId: {
            validations: []
        },
        languageId: {
            validations: []
        },
        seasonDisplayName: {
            label: 'titles.create.summary.releases.release-display-name',
            validations: [Validations.requiredIf(() => {
                const releases = store.getState().getIn(['title', 'releases']);
                return releases.some(c => c.get('dateTytpe') === TitleConstants.RELEASE.DATE_TYPES.DISPLAY_NAME.id);
            }), Validations.max(20)]
        },
        territoryId: {
            validations: []
        },
        type: {
            validations: []
        }
    },

    // This validations are only valid when category has a certain value.
    // These functions compare the category of the title in the TitleStore
    // to be one of the types in the array.
    runningOrder: {validations: [Validations.requiredIf(function() {
        return [
            TitleConstants.TITLE_TYPES.EPISODE.id
        ].indexOf(store.getState().getIn(['title', 'category'])) !== -1;
    })]},
    season: {validations: [Validations.requiredIf(function() {
        return [
            TitleConstants.TITLE_TYPES.ANIMATED_SERIES_SEASON.id,
            TitleConstants.TITLE_TYPES.CARTOONS_SEASON.id,
            TitleConstants.TITLE_TYPES.DOCUSERIES_SEASON.id,
            TitleConstants.TITLE_TYPES.GAME_SHOW_SEASON.id,
            TitleConstants.TITLE_TYPES.LIMITED_SERIES_SEASON.id,
            TitleConstants.TITLE_TYPES.REALITY_SEASON.id,
            TitleConstants.TITLE_TYPES.SEASON_HALF_HOUR.id,
            TitleConstants.TITLE_TYPES.SEASON_ONE_HOUR.id,
            TitleConstants.TITLE_TYPES.SHORT_PROGRAMS_SEASON.id,
            TitleConstants.TITLE_TYPES.TALK_SHOW_SEASON.id
        ].indexOf(store.getState().getIn(['title', 'category'])) !== -1;
    })]},
    talent: {
        validations: [Validations.custom(() => {
            // build message
            const talent = store.getState().getIn(['title', 'talent']).toJS();
            const duplicatedTalent = {};
            Object.keys(talent).forEach(keyList => {
                const talentList = talent[keyList] || [];
                const talentCounter = talentList.reduce((counter, talentItem) => {
                    counter[talentItem.talentId] = counter[talentItem.talentId] || {count: 0, talentItem};
                    counter[talentItem.talentId].count = counter[talentItem.talentId].count + 1;
                    return counter;
                }, {});
                if (Object.keys(talentCounter).some(counterKey => talentCounter[counterKey].count > 1)) {
                    duplicatedTalent[keyList] = talentCounter;
                }
            });

            const duplicatedNames = Object.keys(duplicatedTalent).map(keyList => {
                return `${TalentStore.getTalentRole(parseInt(keyList, 10)).get('name')}: ${Object.keys(duplicatedTalent[keyList]).reduce((list, keyItem) => {
                    if (duplicatedTalent[keyList][keyItem].count > 1) {
                        list.push(duplicatedTalent[keyList][keyItem].talentItem.displayTalentFullName);
                    }
                    return list;
                }, []).join(', ')}`;
            }).join('. ');
            return `Talent fields have duplicated entries: ${duplicatedNames}`;
        }, () => {
            let isValid = true;
            const talent = store.getState().getIn(['title', 'talent']).toJS();
            Object.keys(talent).forEach(keyList => {
                const talentList = talent[keyList] || [];
                const talentCounter = talentList.reduce((counter, talentItem) => {
                    counter[talentItem.talentId] = counter[talentItem.talentId] || 0;
                    counter[talentItem.talentId] = counter[talentItem.talentId] + 1;
                    return counter;
                }, {});
                if (Object.keys(talentCounter).some(counterKey => talentCounter[counterKey] > 1)) {
                    isValid = false;
                }
            });
            return isValid;
        })]
    }
};

class TitleStore extends ReduceStore {

    // Return air day type by id.
    getAirDayType(id) {
        return findInConstant(TitleConstants.RELEASE.AIR_DAY_TYPES, id);
    }

    // Return an air time type by hour and minutes.
    getAirTimeType(hour, minutes) {
        if (minutes === 0) {
            minutes = '00';
        }

        return Immutable.fromJS(
            TitleConstants.RELEASE.AIR_TIME_TYPES.filter(a => a.id === `${hour}:${minutes}`)[0]
        );
    }

    // Return an action type by id.
    getActionType(id) {
        return findInConstant(TitleConstants.ACTION_TYPES, id);
    }

    getCategory(title) {
        return Object.values(TitleConstants.TITLE_TYPES).filter(category => title.get('category') === category.id)[0] || {};
    }

    getAddTitlePanelFilters() {
        return JSON.parse(localStorage.getItem(`${TitleConstants.SAVED_ADD_TITLE_PANEL_FILTERS}${SessionStore.getState().getIn(['authUser', 'id'])}`));
    }

    // Return a date type by id.
    getDateType(id) {
        return findInConstant(TitleConstants.RELEASE.DATE_TYPES, id);
    }

    // Return a contentType by id.
    getContentType(id) {
        return findInConstant(TitleConstants.RELEASE.CONTENT_TYPES, id);
    }

    // Return a date-status type by id.
    getDateStatusType(id) {
        return findInConstant(TitleConstants.RELEASE.DATE_STATUS_TYPES, id);
    }

    // Return a film format type by id.
    getFilmFormatType(id) {
        return findInConstant(TitleConstants.FILM_FORMAT_TYPES, id);
    }

    // Return a link type by id.
    getLinkType(id) {
        return findInConstant(TitleConstants.LINK_TYPES, id);
    }

    getInitialState() {
        let state = Immutable.Map({
            actionTypes: TitleConstants.toArray(TitleConstants.ACTION_TYPES),
            filmFormatTypes: TitleConstants.toArray(TitleConstants.FILM_FORMAT_TYPES),
            filters: Immutable.Map(),
            madeForTypes: TitleConstants.toArray(TitleConstants.MADE_FOR_TYPES),
            mpaaRatingsTypes: TitleConstants.toArray(TitleConstants.MPAA_RATING_TYPES),
            movieColors: TitleConstants.toArray(TitleConstants.MOVIE_COLOR_TYPES),
            links: TitleConstants.toArray(TitleConstants.LINK_TYPES),
            offset: 0,
            parentReleases: Immutable.List(),
            parentalRatingsTypes: TitleConstants.toArray(TitleConstants.PARENTAL_RATING_TYPES),
            ratingReasonTypes: TitleConstants.toArray(TitleConstants.RATING_REASON_TYPES),
            release: Immutable.Map({
                airDayTypes: TitleConstants.toArray(TitleConstants.RELEASE.AIR_DAY_TYPES, {sortBy: 'id'}),
                airTimeTypes: Immutable.fromJS(TitleConstants.RELEASE.AIR_TIME_TYPES),
                contentTypes: TitleConstants.toArray(TitleConstants.RELEASE.CONTENT_TYPES),
                dateStatusTypes: TitleConstants.toArray(TitleConstants.RELEASE.DATE_STATUS_TYPES),
                dateTypes: TitleConstants.toArray(TitleConstants.RELEASE.DATE_TYPES),
                releaseTypes: TitleConstants.toArray(TitleConstants.RELEASE.RELEASE_TYPES)
            }),
            selectedTitle: this.newTitle(),
            size: 20,
            showPreloader: false,
            showTabsPreloader: false,
            sortDirection: 'desc',
            sortFieldName: 'updatedDate',
            title: this.newTitle(),
            titleStyle: Immutable.Map({
                appBackgroundImage: {},
                appBackgroundVideo: {},
                appBackgroundTitleTreatment: {}
            }),
            titleTypes: TitleConstants.toArray(TitleConstants.TITLE_TYPES),
            titleValidated: Immutable.Map(),
            titles: Immutable.List(),
            deletedTitles: Immutable.List(),
            total: 0
        });

        state = state.merge({
            originalTitle: state.get('title'),
            originalTitleStyle: state.get('titleStyle')
        });

        return state;
    }

    // Return a made-for type by id.
    getMadeForType(id) {
        return findInConstant(TitleConstants.MADE_FOR_TYPES, id);
    }

    // Return a mpaa-rating type by id.
    getMPAARatingType(id) {
        return findInConstant(TitleConstants.MPAA_RATING_TYPES, id);
    }

    // Return a movie-color type by id.
    getMovieColorType(id) {
        return findInConstant(TitleConstants.MOVIE_COLOR_TYPES, id);
    }

    // Return a parental-rating type by id.
    getParentalRatingType(id) {
        return findInConstant(TitleConstants.PARENTAL_RATING_TYPES, id);
    }

    getRelationshipType(id) {
        return findInConstant(TitleConstants.TITLE_RELATIONSHIP_TYPE, id);
    }

    // Return a list of rating-reason-type from a list of ids.
    getRatingReasonTypes(ratingReasons) {
        return ratingReasons.map(rr => findInConstant(TitleConstants.RATING_REASON_TYPES, rr.get('id')));
    }

    // Return a release-type by id.
    getReleaseType(id) {
        return findInConstant(TitleConstants.RELEASE.RELEASE_TYPES, id);
    }

    // Return a release-type by id.
    getSubscriptionContentType(id) {
        return findInConstant(TitleConstants.SUBSCRIPTION_CONTENT_TYPES, id);
    }

    // Return a title type by id.
    getTitleType(id) {
        return findInConstant(TitleConstants.TITLE_TYPES, id);
    }

    getValidations() {
        return Validations.validate(this.getState().get('title'), TitleValidations);
    }

    // check changes in genres, languages and themes
    hasCascadeChanges(title, originalTitle) {
        const hasSameItems = (list, originalList) =>
            list.size === originalList.size &&
            list.every(listItem => originalList.find(originalListItem => originalListItem.get('id') === listItem.get('id')));

        const category = title.get('categoryGroup');
        let hasCascadeChanges;
        switch (category) {
        case TitleConstants.TITLE_CATEGORY_GROUPS.EPISODE:
        case TitleConstants.TITLE_CATEGORY_GROUPS.MINI_SERIES:
        case TitleConstants.TITLE_CATEGORY_GROUPS.SEASON:
        case TitleConstants.TITLE_CATEGORY_GROUPS.SERIES:
            const isGenresDirty = !hasSameItems(title.get('genres'), originalTitle.get('genres'));
            const isLanguagesDisty = !hasSameItems(title.get('languages'), originalTitle.get('languages'));
            const isThemesDirty = !hasSameItems(title.get('themes'), originalTitle.get('themes'));
            if (isGenresDirty || isLanguagesDisty || isThemesDirty) {
                hasCascadeChanges = true;
            }
            break;
        default:
            hasCascadeChanges = false;
            break;
        }
        return hasCascadeChanges;
    }

    newTitle() {
        return Immutable.Map({
            active: true,
            catalogs: Immutable.List(),
            cascadeFlags: Immutable.Map({ // for genres, languages and themes
                episode: false,
                'mini-serie-episode': false,
                season: false,
                serie: false
            }),
            fieldStatusValues: Immutable.List(),
            filmFormat: TitleConstants.FILM_FORMAT_TYPES.TWO_DIMENSIONAL.id,
            genres: Immutable.List(),
            history: Immutable.List(),
            languageAvailability: Immutable.List(),
            languageCatalogs: Immutable.List(),
            languages: Immutable.List(),
            links: Immutable.List(),
            movieColor: TitleConstants.MOVIE_COLOR_TYPES.COLOR.id,
            name: '',
            productionCompanies: Immutable.List(),
            ratingReasons: Immutable.List(),
            releases: Immutable.List(),
            removeCatalogs: Immutable.List(),
            rightsAcquiredFrom: Immutable.List(),
            subscriptions: Immutable.Map({
                '1': Immutable.List(),
                '2': Immutable.List(),
                '3': Immutable.List(),
                '4': Immutable.List(),
                '5': Immutable.List(),
                '6': Immutable.List()
            }),
            synopsisValues: Immutable.List(),
            talent: Immutable.Map(),
            themes: Immutable.List()
        });
    }

    reduce(state, action) {
        let newSynopsis;
        switch (action.actionType) {
        case AssetTabConstants.GET.START:
            state = state.merge({showTabsPreloader: true});
            break;

        case AssetTabConstants.GET.SUCCESS:
        case AssetTabConstants.GET.ERROR:
            state = state.merge({showTabsPreloader: false});
            break;

        case AssetTabConstants.REMOVE:
            if (action.entityType === 'title' &&
                state.getIn(['title', 'defaultImagePortraitId']) === action.asset.get('assetId')) {
                // With the current UI, it only matters if the user removes the default portrait image
                // because it's the only one that is visible in the "Default Photo" section.
                state = state.mergeIn(['title'], {
                    defaultImagePortraitFullResolutionUrl: null,
                    defaultImagePortraitId: null,
                    defaultImagePortraitPreviewUrl: null,
                    defaultImagePortraitThumbnailUrl: null
                });
            }
            break;

        case AssetTabConstants.SET_DEFAULT:
            if (action.entityType === 'title' && action.type === 'defaultPortrait') {
                // The following code acts on the current value, but knowing it will toggle
                // it's value in the asset-tab-store. If current value is 0, then make it default.
                // If current value is 1, then remove it.
                if (!action.asset.get('defaultPortrait')) {
                    state = state.mergeIn(['title'], {
                        defaultImagePortraitFullResolutionUrl: action.asset.getIn(['thumbnails', 0, 'fullResolutionUrl']),
                        defaultImagePortraitId: action.asset.get('assetId'),
                        defaultImagePortraitPreviewUrl: action.asset.getIn(['thumbnails', 0, 'previewUrl']),
                        defaultImagePortraitThumbnailUrl: action.asset.getIn(['thumbnails', 0, 'thumbnailUrl'])
                    });
                } else {
                    state = state.mergeIn(['title'], {
                        defaultImagePortraitFullResolutionUrl: null,
                        defaultImagePortraitId: null,
                        defaultImagePortraitPreviewUrl: null,
                        defaultImagePortraitThumbnailUrl: null
                    });
                }
            }
            break;

        case ActionHistoryConstants.ADD_NOTE.SUCCESS:
            if (action.actionObjectType === ActionHistoryConstants.ACTION_OBJECTS.TITLE) {
                state = state.updateIn(['title', 'history'], history => history.unshift(action.note));
            }
            break;

        case TitleConstants.CLEAR:
            state = this.getInitialState();
            break;

        case TitleConstants.FILTER.CLEAR:
            state = state.set('filters', Immutable.Map());
            break;

        case TitleConstants.FILTER.SET:
            state = state.setIn(['filters', ...action.attr.split('.')], action.value);
            break;

        case TitleConstants.TITLE.CATALOGS.ADD:
            let catalog = action.catalog;
            catalog = catalog.merge({
                'include-series': action.includeSerie,
                'include-season': action.includeSeason,
                'include-episode': action.includeEpisode
            });
            state = state.updateIn(['title', action.name], catalogs => {
                return catalogs.push(catalog).sortBy(c => c.get('name'));
            });
            break;

        case TitleConstants.TITLE.CATALOGS.REMOVE:
            let rmCatalog = action.catalog;
            rmCatalog = rmCatalog.merge({
                'include-series': action.includeSerie,
                'include-season': action.includeSeason,
                'include-episode': action.includeEpisode
            });

            state = state.updateIn(['title', action.name], catalogs => {
                return catalogs.filter(c => c.get('id') !== rmCatalog.get('id')).sortBy(c => c.get('name'));
            });

            state = state.updateIn(['title', 'removeCatalogs'], catalogs => {
                return catalogs.push(rmCatalog);
            });
            break;


        case TitleConstants.TITLE.CATALOGS.GET.SUCCESS:
            let index = state.get('titles').findIndex(t => t.get('id') === action.titleId);
            state = state.setIn(['titles', index, 'catalogs'], action.catalogs);
            break;

        case TitleConstants.TITLE.CASCADE_UPDATE:
            state = state.setIn(['title', 'cascadeFlags'], Immutable.Map({
                episode: action.episode,
                'mini-serie-episode': action['mini-serie-episode'],
                season: action.season,
                serie: action.serie
            }));
            break;

        case TitleConstants.TITLE.CLONE:
            // Also reset the newTitleStyle so that custom uploaded files don't
            // get re-processed again.
            const newTitleStyle = this.getInitialState().get('titleStyle');
            state = state.merge({
                originalTitle: state.get('title'),
                originalTitleStyle: newTitleStyle,
                titleStyle: newTitleStyle,
                updatedDate: undefined
            });
            break;

        case TitleConstants.TITLE.CLONE_PARENT:
            let categoryGroup = state.getIn(['title', 'categoryGroup']);
            // on every clone
            state = state.mergeIn(['title'], {
                defaultImageHorizontalId: null,
                defaultImagePortraitId: null,
                eidr: '',
                episodeCount: undefined,
                id: undefined,
                mpmNumber: '',
                referenceId: undefined,
                talent: Immutable.Map(),
                brmProductNumber: '',
                category: undefined,
                history: Immutable.List(),
                runningOrder: null,
                season: null,
            });
            // releases are removed next, need to keep them in case of repackaged for sales
            state = state.set('parentReleases', state.getIn(['title', 'releases']));
            // not on series (assuming child will be a season)
            // seasons must persist title name and releases so removing for the rest
            if (categoryGroup !== TitleConstants.TITLE_CATEGORY_GROUPS.SERIES) {
                state = state.mergeIn(['title'], {
                    name: '',
                    releases: Immutable.List(),
                });
            }
            //clean subscriptions
            state = state.mergeIn(['title'], {
                subscriptions: this.newTitle().get('subscriptions')
            });
            // only on seasons (assuming child will be an episode)
            if (categoryGroup === TitleConstants.TITLE_CATEGORY_GROUPS.SEASON) {
                state = state.mergeIn(['title'], {
                    aka1: '',
                    aka2: '',
                    ika1: '',
                    ika2: '',
                    pka1: '',
                    pka2: '',
                    alternativeTitle1: '',
                    alternativeTitle2: '',
                    shortSynopsis: '',
                    synopsis: '',
                    log60Lines: '',
                    log180Lines: ''
                });
            }
            // Reset the original as a new title.
            state = state.set('originalTitle', this.newTitle());
            break;

        case TitleConstants.TITLE.DELETED.GET.SUCCESS:
            state = state.merge({
                deletedTitles: action.deletedTitles.reverse()
            });
            break;

        case TitleConstants.TITLE.DELETED.SET:
            state = state.merge({
                selectedTitle: action.title
            });
            break;

        case TitleConstants.TITLE.GET.SUCCESS:
            let title = action.title;
            title = title.set('removeCatalogs', Immutable.List());
            title = title.set('cascadeFlags', Immutable.Map({
                episode: false,
                'mini-serie-episode': false,
                season: false,
                serie: false
            }));
            state = state.merge({
                originalTitle: title,
                title: title,
                showPreloader:false
            });
            break;
        case AssetTabConstants.REPLACE_VIDEO.START:
        case TitleConstants.TITLE.MARK_FOR_DELETION.START:
        case TitleConstants.TITLE.MARK_FOR_DELETION.SUCCESS:
        case TitleConstants.TITLE.GET.START:
            state = state.merge({
                showPreloader:true
            });
            break;
        case AssetTabConstants.REPLACE_VIDEO.SUCCESS:
        case AssetTabConstants.REPLACE_VIDEO.ERROR:
        case TitleConstants.TITLE.MARK_FOR_DELETION.ERROR:
        case TitleConstants.TITLE.GET.ERROR:
            state = state.merge({
                showPreloader:false
            });
            break;

        case TitleConstants.TITLE.GET_UPDATE_DATA.START:
            state = state.merge({
                showPreloader: true
            });
            break;
        case TitleConstants.TITLE.GET_UPDATE_DATA.ERROR:
            state = state.merge({
                showPreloader: false
            });
            break;
        case TitleConstants.TITLE.GET_UPDATE_DATA.SUCCESS:
            state = state.merge({
                showPreloader: false,
                updatedDate: action.updatedDate
            });
            break;
        case TitleConstants.TITLE.MOVE_ELEMENT:
            state = state.updateIn(['title', ...action.relation.split('.')], relation => {
                // Get the element to move.
                const item = relation.get(action.from);
                /**
                 * 1) Remove the item from the array with splice (that will update the
                 *    other elements indexes).
                 * 2) Insert the removed item in its new position (that will update the
                 *    other elements indexes).
                 * 3) Re-assign the orderInTitle value for each release following the
                 *    new array order.
                 */
                return relation.splice(action.from, 1).insert(action.to, item).map((r, i) => {
                    return r.set(action.orderBy, i);
                });
            });
            break;

        case TitleConstants.TITLE.RELEASES.ADD:
            state = state.updateIn(
                ['title', 'releases'],
                releases => releases.push(
                    action.release.set('orderInTitle', releases.size + 1)
                )
            );
            break;

        case TitleConstants.TITLE.LINKS.ADD:
            state = state.updateIn(['title', 'links'], links => {
                return links.push(Immutable.Map({
                    rankWithinTitle: links.size + 1
                }));
            });
            break;

        case TitleConstants.TITLE.REMOVE_ELEMENT:
            state = state.updateIn(['title', ...action.relation.split('.')], relation => {
                /**
                 * Just remove the element at the desired index. Then re-assign
                 * the oderInTitle value for all remaining elements in the array.
                 */
                relation = relation.delete(action.index);
                if (action.orderBy) {
                    relation = relation.map((r, i) => {
                        return r.set(action.orderBy, i);
                    });
                }
                return relation;
            });
            break;

        case TitleConstants.TITLE.REMOVE_BY_ID:
            state = state.updateIn(['title', ...action.relation.split('.')], relation => {
                return relation.filter(r => r.get(action.idField) !== action.id);
            });
            break;

        case TitleConstants.TITLE.SUBSCRIPTION.ADD:
            let userIds = state.getIn(['title', 'subscriptions', action.subscriptionContentType.toString()]).map(u => {
                return {
                    id: u.get('userId')
                };
            });

            if (!userIds.find(u => u.id === action.userId)) {
                state = state.updateIn(['title', 'subscriptions', action.subscriptionContentType.toString()], subscriptions => {
                    return subscriptions.push(Immutable.Map({
                        suggestedByUserId: action.suggestedByUserId,
                        suggestedByFullName: action.suggestedByUserName, // Required to display name in title > subscriptions table
                        subscriptionContentType: action.subscriptionContentType,
                        titleId: action.titleId,
                        userId: action.userId,
                        clientFullName: action.userName, // Required to display name in title > subscriptions table
                    }));
                });
            }
            break;

        case TitleConstants.TITLE.SUBSCRIPTION.GET_USERS.SUCCESS:
            let users = action.subscriptionsUsers.map( u => {
                return Immutable.Map({
                    id: u.get('id'),
                    name: `${u.get('name')} ${u.get('lastName')}`
                });
            });
            let subscriptionsList = state.getIn(['title', 'subscriptions']);
            subscriptionsList.map((subscriptionType, i) => {
                subscriptionType.forEach((s, j) => {
                    let user = users.find(u => u.get('id') === s.get('userId')) || Immutable.Map();
                    let suggestedByUser = users.find(u => u.get('id') === s.get('suggestedByUserId')) || Immutable.Map();
                    state = state.mergeIn(['title', 'subscriptions', i, j], {
                        userName: user.get('name') || '-',
                        suggestedByUserName: suggestedByUser.get('name') || '-'
                    });
                });
            });
            break;
        case TitleConstants.TITLE.SYNOPSIS.ADD:
            newSynopsis = {
                titleId: action.titleId,
                usageType: action.synopsisType,
                synopsis: '',
                shortSynopsis: '',
                log180Lines: '',
                log60Lines: ''
            };
            state = state.setIn(['title', 'synopsisValues'], Immutable.fromJS([...state.getIn(['title', 'synopsisValues']), newSynopsis]));
            break;
        case TitleConstants.TITLE.TALENT.ADD_OR_REPLACE:
            // Add or replace the selected talent to a talentList.
            state = state.updateIn(['title', 'talent', action.talentRoleType.id.toString()], talentList => {
                if (!talentList) {
                    talentList = Immutable.List();
                }
                // Set the TalentTitle values.
                if (action.index === undefined) {
                    return talentList.push(action.talent.merge({
                        orderInTitle: talentList.size,
                        titleId: state.getIn(['title', 'id'])
                    }));
                }

                const oldTalent = talentList.get(action.index);
                return talentList.set(action.index, action.talent.merge({
                    characterNameInTitle: oldTalent.get('characterNameInTitle'),
                    orderInTitle:  oldTalent.get('orderInTitle'),
                    titleId: state.getIn(['title', 'id'])
                }));
            });
            break;

        case TitleConstants.TITLE.TALENT.UPDATE_AKA:
            state = state.updateIn(['title', 'talent', action.talentRoleType.id.toString()], talentList => {
                const mergedTalentList = talentList.mergeIn([action.index.toString()], {
                    akaId: action.aka.get('id'),
                    displayAKAFullName: action.aka.get('fullName'),
                    fullName: action.aka.get('fullName')
                });
                return mergedTalentList;
            });
            break;

        case TitleConstants.TITLE.UPDATE:
            state = state.setIn(['title', ...action.attr.split('.')], action.value);

            // set hideSynopsisLogLines to false/undefined if synopsis, shortSynopsis, log180Lines and log60Lines are empty
            if (['synopsis', 'shortSynopsis', 'log180Lines', 'log60Lines'].includes(action.attr) &&
                !state.getIn(['title', 'synopsis']) && !state.getIn(['title', 'shortSynopsis']) &&
                !state.getIn(['title', 'log180Lines']) && !state.getIn(['title', 'log60Lines'])) {
                if (state.getIn(['title', 'id'])) {
                    state = state.setIn(['title', 'hideSynopsisLogLines'], false); // if edit mode set to false
                } else {
                    state = state.setIn(['title', 'hideSynopsisLogLines'], undefined); // if create mode restore to undefined
                }
            }
            break;

        case TitleConstants.TITLE.VALIDATED.UPDATE:
            state = state.setIn(['titleValidated', ...action.attr.split('.')], action.value);
            break;

        case TitleConstants.TITLES.GET.SUCCESS:
            state = state.merge({
                offset: action.offset,
                size: action.size,
                titles: action.titles,
                total: action.total
            });
            break;
        case TitleConstants.TITLES.APPLY_SAVED_FILTERS:
            state = state.set({
                filters: Immutable.fromJS(action),
                size: action.size,
                offset: action.offset,
                sortDirection: action.sortDirection,
                sortFieldName: action.sortFieldName
            });
            break;
        case TitleConstants.TITLES.SET.FILTERS:
            state = state.set('filters', action.filters);
            break;

        case TitleConstants.TITLE_STYLE.UPDATE:
            state = state.setIn(['titleStyle', ...action.attr.split('.')], action.value);
            break;

        case TitleConstants.SORT.SET:
            state = state.merge({
                sortFieldName: action.sortFieldName,
                sortDirection: action.sortDirection
            });
            break;

        case TitleConstants.TITLE_STATUS.UPDATE:
            state = state.setIn(['title', 'fieldStatusValues'], action.fieldStatusValues);
            state = state.setIn(['originalTitle', 'fieldStatusValues'], action.fieldStatusValues);
            if (action.active !== undefined && action.active !== null) {
                state = state.setIn(['title', 'active'], action.active);
                state = state.setIn(['originalTitle', 'active'], action.active);
            }
            break;

        case TitleConstants.TITLE.CLEAR_UNUSED_FIELDS:
            state = state.set('title', action.title);
            break;
        }
        return state;
    }
}

const store = new TitleStore(Dispatcher);

export default store;
export {TitleValidations};
