/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

/**
 * Utilities package. Please keep this codebase at minumum.
 * Only add really really really important functions that
 * will make our lives better.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';
import Moment from 'moment';
import url from 'url';

import config from '../../config/config.js';
import Request from '../../request';
import SessionStore from '../../user/session/session-store';

/* eslint-disable @typescript-eslint/no-this-alias */

/**
 * Take an immutable list and break it into groups of N members.
 */
const BreakIntoGroups = function(arr, n) {
    let groups = Immutable.List();
    for (let i = 0; i < arr.size; i += n) {
        groups = groups.push(arr.slice(i, i + n));
    }

    return groups;
};

/**
 * Take two immutable objects and compare two subsets defined
 * by the props array.
 *
 * @param Immutable newObj
 * @param Immutable oldObj
 * @param Array props
 *
 * @return Boolean true if both subsets are equal.
 */
const CompareImmutable = function(newObj, oldObj, props) {
    if (!props || props.length === 0) {
        return newObj.equals(oldObj);
    }

    let filterProps = function(v, k) {
        return props.indexOf(k) !== -1;
    };

    let a = newObj.filter(filterProps);

    let b = oldObj.filter(filterProps);

    return a.equals(b);
};

/**
 * Take two immutable List and compare using CompareImmutable
 * 'soft equality'
 *
 * @param Immutable List a
 * @param Immutable List b
 * @param Array props
 *
 * @return Boolean true if both subsets are equal.
 */
const CompareImmutableList = function(a, b, props) {
    if (a.size !== b.size) {
        return false;
    }
    return a.every( (v, i) => {return CompareImmutable(v, b.get(i), props);});
};

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
const Debounce = function(func, wait, immediate) {
    let timeout;
    return function() {
        let context = this, args = arguments;
        let later = function() {
            timeout = null;
            if (!immediate) {func.apply(context, args);}
        };
        let callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) {func.apply(context, args);}
    };
};

const GetFormattedDateHourForInput = function(timestamp, timezone, format) {
    if (!timestamp) {
        return null;
    }
    return Moment.tz(timestamp, timezone).format(format);
};

// Return a thumbnail close to a provided size.
const GetThumbnail = (thumbnails = Immutable.List(), width) => {
    if (!thumbnails.size) {
        return;
    }

    if (!width) {
        return thumbnails.get(0);
    }

    return thumbnails.reduce((r, t) => {
        let rValue = Math.abs(r.get('width') - width);
        let tValue = Math.abs(t.get('width') - width);

        if (rValue < tValue) {
            // r is closest to width than t.
            return r;
        }

        return t;
    });
};

const IsMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function() {
        return (IsMobile.Android() || IsMobile.BlackBerry() || IsMobile.iOS() || IsMobile.Opera() || IsMobile.Windows());
    }
};

/**
 * ListDiff
 * Get the difference between two lists.
 */
const ListDiff = function(list1, list2, ...idAttrs) {
    return list1.filter(
        obj1 => !list2.find(
            obj2 => idAttrs.every(
                attr => obj2.get(attr) === obj1.get(attr)
            )
        )
    );
};

/*istanbul ignore next*/
const StartDownload = (href) => {
    // Create a link element and click it to start the download.
    const linkElement = document.createElement('a');
    linkElement.setAttribute(
        'href',
        href
    );
    linkElement.setAttribute('style', 'display: none');
    const evt = document.createEvent('MouseEvents');
    evt.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, linkElement);
    linkElement.dispatchEvent(evt);
    linkElement.remove();
};

/**
 * Take two immutable Set and do subtract using CompareImmutable
 *
 * @param Immutable Set a
 * @param Immutable Set b
 * @param Array props
 *
 * @return a NewSet
 *
 */
const SubtractImmutableSet = function(a, b, props) {
    return a.filter( (v) => {
        return !b.some( (vb) => {
            return CompareImmutable(v, vb, props);
        });
    });
};

// eslint-disable-next-line @typescript-eslint/no-var-requires
const encode = require('lean-he/encode');

const UploadFile = function(method, path, file, xhr = new XMLHttpRequest()) {
    let fullUrl = url.resolve(config.ApiBaseUrl, path);
    return new Promise((resolve, reject) => {
        let fd = new FormData();
        fd.append('file', file);
        xhr.open(method, fullUrl);
        xhr.setRequestHeader('Authorization', SessionStore.getAuthorization());

        // Setup Response Handler
        xhr.onreadystatechange = function() {
            if (xhr.readyState === 4) { // 4 is Done (the operation is complete)
                /*istanbul ignore next*/
                if (xhr.status >= 200 && xhr.status < 300) { // is an ok response :)
                    window.__xhr = xhr;
                    if (xhr.response) {
                        resolve(xhr.response);
                        return;
                    } else {
                        resolve(xhr.status);
                        return;
                    }
                } else {
                    reject(xhr.response);
                    return;
                }
            }
        };

        // Send the request
        xhr.send(fd);
    });
};

const UploadToAmazon = function(assetId, imageFile, path, xhr) {
    return new Promise((resolve, reject) => {
        Request.get(`asset/image/${assetId}/${path}`).query({
            'file-name': imageFile.name
        }).exec().then(response => {
            let preSignedUrl = response.body.url;

            xhr.open('PUT', preSignedUrl);
            // Setup Response Handler
            xhr.onreadystatechange = function() {
                if (xhr.status >= 200 && xhr.status < 300) { // is an ok response :)
                    return resolve();
                } else {
                    return reject({
                        assetId: assetId,
                        fileName: imageFile.name
                    });
                }
            };

            // Send the request.
            // imageFile is an instance of File which in turn has Blob as its prototype.
            // Passing imageFile to the send() method uploads the Blob of bytes.
            xhr.send(imageFile);
            return;
        }).catch(() => {
            return reject({
                assetId: assetId,
                fileName: imageFile.name
            });
        });
        return;
    });
};

// FormatImageSrc will return image path rooted if they are not
// base64 encoded
const FormatImageSrc = (src) => {
    if (!src) {
        return;
    }
    if (src.substr(0, 5) === 'data:') {
        return src;
    }
    return `/${src}`;
};

// Audio Profile utils.
const ArrayToAudioProfile = (id, audioProfileArray) => {
    const ap = {};
    if (id !== undefined) {
        ap.mediaAssetId = parseInt(id, 10);
    }

    audioProfileArray.forEach(channel => {
        ap[`audioChannel${channel.get('audioChannel')}`] = channel.get('type');
        return;
    });

    return ap;
};

const AudioProfileToArray = audioProfile => {
    if (!audioProfile) {
        return [];
    }

    const audioChannelRegExp = /^audioChannel(\d+)/;

    return Object.keys(
        audioProfile
    ).filter(
        k => audioChannelRegExp.test(k) && audioProfile[k] !== null
    ).map(k => ({
        audioChannel: parseInt(audioChannelRegExp.exec(k)[1], 10),
        type: audioProfile[k]
    }));
};

const FormatOAPAudioChannelName = (channelNum) => `Audio_Channel_${String(channelNum).padStart(2, '0')}_t`;

/**
 * Escapes html tags and international symbols to html entities
 * @param {String} html string
 */
const GetEncodedHTML = html => {
    if (!html) {return '';}
    return encode(html);
};

/**
 * Creates File with new random name
 * @param {File} file object
 */
/*istanbul ignore next*/
const CreateFile = (file) => {
    const fullName = file.name.split('.');
    const fileName = fullName.slice(0, fullName.length-1).join('.');
    const fileType = fullName[fullName.length-1];
    const randomName = `${fileName}-${Math.ceil(Math.random() * 100000)}.${fileType}`;
    const options = {type: file.type};
    try {
        return new File([file], randomName, options);
    } catch (e) {
        let myBlob = new Blob([file], options || {});
        myBlob.lastModified = new Date();
        myBlob.name = randomName;
        return myBlob;
    }
};

/**
 * Take a trigger event and returns true if it was valid key
 * @param event
 * @returns {boolean}
 */
const IsNotTriggerKey = (event) => {
    const enterKeyCode = 13;
    const spaceKeyCode = 32;

    return (event.type === 'keyup' && (event.keyCode !== enterKeyCode && event.keyCode !== spaceKeyCode));
};

/** Return an array with separator between each element. Example: return a comma seperated list of Links (https://stackoverflow.com/a/23619085)
 * @param {Array} arr array
 * @param {String} sep seperator
 * @returns {Array}
 */
const Intersperse = (arr, sep = ', ') => {
    if (arr.length === 0) {
        return [];
    }

    return arr.slice(1).reduce((xs, x) => {
        return xs.concat([sep, x]);
    }, [arr[0]]);
};

/**
 * Sorting method to use for sorting dates
 * @param {string} a
 * @param {string} b
 */
const SortDates = (a, b) => {
    a = new Date(a).getTime();
    b = new Date(b).getTime();
    let sortResult;
    if (a === b) {
        sortResult = 0;
    } else {
        sortResult = -1;
        if (a > b) {
            sortResult = 1;
        }
    }
    return sortResult;
};

/**
 * Determine if browser location contains hardac path
 */
const IsHardacLocation = (location) => {
    if (location) {
        return location.pathname.includes('hardac');
    }

    return false;
};

/**
 * Get attr from data, the reason for this being here is Unit test and CC
 */
const GetAttr = (attr) => {
    return (data) => data[attr];
};

export {
    ArrayToAudioProfile,
    AudioProfileToArray,
    BreakIntoGroups,
    CompareImmutable,
    CompareImmutableList,
    CreateFile,
    Debounce,
    FormatImageSrc,
    FormatOAPAudioChannelName,
    GetAttr,
    GetEncodedHTML,
    GetFormattedDateHourForInput,
    GetThumbnail,
    Intersperse,
    IsHardacLocation,
    IsMobile,
    IsNotTriggerKey,
    ListDiff,
    SortDates,
    StartDownload,
    SubtractImmutableSet,
    UploadFile,
    UploadToAmazon,
};
