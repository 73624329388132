/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import ReactTable from 'react-table';

import {GroupActions} from '../../security/group/group-actions';

export default class Partners extends Component {
    static get propTypes() {
        return {
            id: PropTypes.number.isRequired,
            partners: PropTypes.instanceOf(Immutable.List)
        };
    }

    static get defaultProps() {
        return {
            partners: Immutable.List()
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        GroupActions.getGroupPartners(this.props.id);
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.id !== this.props.id || nextProps.partners !== this.props.partners) {
            return true;
        }
        return false;
    }

    render() {
        let columns = [
            {
                Header: <strong>{this.context.intl.messages['groups.partners.name']}</strong>,
                minWidth: 25,
                Cell: c => {
                    const partnerName = c.original.get('name');
                    return (
                        <p>{partnerName}</p>
                    );
                }
            },
            {
                Header: <strong>{this.context.intl.messages['groups.partners.client']}</strong>,
                minWidth: 25,
                Cell: c => {
                    const client = c.original.get('clientId');
                    return (
                        <p>{client}</p>
                    );
                }
            },
            {
                Header: <strong>{this.context.intl.messages['groups.partners.description']}</strong>,
                minWidth: 100,
                Cell: c => {
                    const description = c.original.get('description');
                    return (
                        <p>{description}</p>
                    );
                }
            }
        ].map(col => {
            col.style = {
                whiteSpace: 'unset',
                wordBreak: 'break-word'
            };
            return col;
        });

        return (
            <div className="group-partners">
                <ReactTable
                    className="-striped table-bordered table-striped"
                    columns={columns}
                    data = {this.props.partners}
                    getNoDataProps= {() => {
                        if (this.props.partners.size) {
                            return {style: {display: 'none'}};
                        }
                        return {};
                    }}
                    loading={false}
                    showPagination={false}
                    sortable={false}
                    resizable={false}
                    pageSize={this.props.partners.size}
                />
            </div>
        );
    }
}
