/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, ControlLabel, FormControl, FormGroup} from 'react-bootstrap';

import {FormRow} from '../../../common/form/form';
import {TitleActions} from '../../title-actions';

const ELEMENT_TYPE = {
    AUDIO: {id: 0, description: 'Audio'},
    SUBTITLE: {id: 1, description: 'Subtitle'}
};

class Language extends Component {
    static get propTypes() {
        return {
            index: PropTypes.number.isRequired,
            language: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.handleRemove = this.handleRemove.bind(this);
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.language !== this.props.language;
    }

    handleRemove() {
        TitleActions.removeElement('languageAvailability', this.props.index);
        return;
    }

    render() {
        let element = this.props.language.get('elementType') === ELEMENT_TYPE.AUDIO.id ? ELEMENT_TYPE.AUDIO.description : ELEMENT_TYPE.SUBTITLE.description;
        return (
            <div className="box form-section">
                <FormRow>
                    <FormGroup md={3}>
                        <ControlLabel>{this.context.intl.messages['titles.create.language.language']}</ControlLabel>
                        <FormControl
                            readOnly
                            type="text"
                            value={this.props.language.get('languageDesc')}
                        />
                    </FormGroup>
                    <FormGroup md={3}>
                        <ControlLabel>{this.context.intl.messages['titles.create.language.element']}</ControlLabel>
                        <FormControl
                            readOnly
                            type="text"
                            value={element}
                        />
                    </FormGroup>
                    <FormGroup md={3}>
                        <ControlLabel>{this.context.intl.messages['titles.create.language.version']}</ControlLabel>
                        <FormControl
                            readOnly
                            type="text"
                            value={this.props.language.get('versionDesc')}
                        />
                    </FormGroup>
                    <FormGroup md={2}>
                        <ControlLabel>{this.context.intl.messages['titles.create.language.available-date']}</ControlLabel>
                        <FormControl
                            readOnly
                            type="text"
                            value={this.props.language.get('availableDate')}
                        />
                    </FormGroup>
                    <FormGroup md={1}>
                        <ControlLabel>&nbsp;</ControlLabel>
                        <Button
                            bsSize="sm"
                            bsStyle="danger"
                            className="pull-right"
                            onClick={this.handleRemove}
                        ><i className="fas fa-trash-alt"></i>&nbsp;{this.context.intl.messages['common.delete']}</Button>
                    </FormGroup>
                </FormRow>
            </div>
        );
    }
}

export default Language;
