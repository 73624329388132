/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

import AssetCatalogStore from '../assets/catalogs/asset-catalog-store';
import {AlertsWarnings} from '../common/validations/alerts-warnings';
import {SEVERITY} from '../common/validations/validations';
import Preloader from '../preloader/';
import PreloaderStore from '../preloader/preloader-store';

import {AssetCatalogActions} from '~/src/assets/catalogs/asset-catalog-actions';

class AddNewAssets extends Component {

    static get propTypes() {
        return {
            addConstants: PropTypes.string.isRequired,
            actions: PropTypes.object.isRequired,
            children: PropTypes.element,
            filePanel: PropTypes.node,
            store: PropTypes.object.isRequired,
            fileId: PropTypes.string.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
            router: PropTypes.object.isRequired,
        };
    }

    static get getDefaultProps() {
        return {
            filePanel: undefined,
            children: undefined,
        };
    }

    static calculateState() {
        return {
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible')
        };
    }

    static getStores() {
        return [PreloaderStore];
    }


    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.handleSave = this.handleSave.bind(this);
    }

    componentDidMount() {
        this.props.actions.clear();
        AssetCatalogActions.clear();
    }

    handleSave() {
        const asset = this.props.store.getState().get('asset');
        const files = asset.get('files') || [];
        const assignedCatalogs = AssetCatalogStore.getState().get('assignedCatalogs');
        const originalAssignedCatalogs = AssetCatalogStore.getState().get('originalAssignedCatalogs');
        this.props.actions.saveAndAdd(asset, files, this.props.addConstants, assignedCatalogs, originalAssignedCatalogs);
        return;
    }

    render() {
        let validations = this.props.store.getValidations();
        let imageFileValidations = [];
        /* istanbul ignore if */
        if (this.props.fileId === 'image-file') {
            imageFileValidations = this.props.store.getImageTypeValidations();
        }
        let createButtonStyle = 'success';
        let disabled = false;
        /* istanbul ignore if */
        if (validations.length && validations.find(v => v.severity === SEVERITY.ALERT)) {
            disabled = true;
            createButtonStyle = 'default';
        }

        return (
            <div className="row">
                <Preloader show={this.state.preloaderVisible} fixed loadingDots>
                    <div className="container-fluid no-x-padding">
                        <div className="col-lg-9">
                            {this.props.children}
                        </div>
                        <div className="col-lg-3">
                            <AlertsWarnings title={this.context.intl.messages['common.alerts-warnings-title']} validations={[...validations, ...imageFileValidations]}/>
                            <div>
                                {this.props.filePanel}
                            </div>
                            <div>
                                <Button
                                    bsStyle={createButtonStyle}
                                    className="btn-lg btn-block"
                                    disabled={disabled}
                                    onClick={this.handleSave}><i className="fas fa-plus"></i>&nbsp;{this.context.intl.messages['titles.create.assets.create.button']}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Preloader>
            </div>
        );
    }
}

export default Container.create(AddNewAssets);
