/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import {Col, Row} from 'react-bootstrap';

import {SlidingPanelActions} from './sliding-panel-actions';
import SlidingPanelStore from './sliding-panel-store';
import {Alert} from '../notification/alert';

/* SlidingPanel: How to use it
 id: unique identifier used to have multiple panel in the same page

<SlidingPanel id="addExisting" title={this.props.title} icon="fas fa-plus-circle">
    <AddExistingAssets assetType={this.state.assetType}/>
</SlidingPanel>
*/
export default createReactClass({
    propTypes: {
        cancelButtonClassNames: PropTypes.string,
        children: PropTypes.node,
        extraButtons: PropTypes.node,
        icon: PropTypes.string,
        id: PropTypes.string,
        onCancel: PropTypes.func,
        title: PropTypes.node,
        useH3Header: PropTypes.bool,
    },

    contextTypes: {
        intl: PropTypes.object.isRequired
    },

    getDefaultProps: function() {
        return {
            cancelButtonClassNames: 'btn-large bg-navy', // TODO: The latest designs have this button as btn-md btn-navy-outline
            children: undefined,
            extraButtons: undefined,
            icon: '',
            id: '',
            onCancel: () => void 0,
            title: undefined,
            useH3Header: false, // TODO the latest designs have the header wrapped in a h3 not h2
        };
    },

    getInitialState: function() {
        return {
            show: SlidingPanelStore.isVisible(this.props.id),
        };
    },

    componentWillMount: function() {
        this.storeListeners = [
            SlidingPanelStore.addListener(() => {
                this.setState({
                    show: SlidingPanelStore.isVisible(this.props.id),
                });
                return;
            }),
        ];
        return;
    },

    componentWillUnmount: function() {
        this.storeListeners.forEach(listener => listener.remove());
        return;
    },

    close: function() {
        SlidingPanelActions.hide(this.props.id);
        this.props.onCancel();
        return;
    },

    render: function() {
        let open = null;
        if (this.state.show) {
            open = 'control-sidebar-open';
        }

        let childrenToShow = null;
        if (this.state.show) {
            childrenToShow = this.props.children;
        }

        const headerContent = (
            <span>
                <i className={ClassNames(this.props.icon)}/> {this.props.title}
                <span className="pull-right">
                    {this.props.extraButtons}
                    &nbsp;
                    <button
                        className={`btn ${this.props.cancelButtonClassNames} control-sidebar-toggle`}
                        data-toggle="control-sidebar"
                        onClick={this.close}>
                        <i className="fas fa-times"></i> {this.context.intl.messages['common.cancel']}
                    </button>
                </span>
            </span>);
        let headerContentContainer = <h2>{headerContent}</h2>;
        if (this.props.useH3Header) {
            headerContentContainer = <h3>{headerContent}</h3>;
        }
        return (
            <aside className={ClassNames('control-sidebar', 'control-sidebar-editor', open)}>
                <Row>
                    <Col md={8} mdOffset={2}>
                        <Alert withHeader/>
                    </Col>
                </Row>
                <div className="tab-content">
                    <div className="container-fluid no-x-padding">
                        <Row>
                            <Col md={8} mdOffset={2} id="control-sidebar-content">
                                <div id="control-sidebar-content-1" style={{display: 'block'}}>
                                    {headerContentContainer}
                                    <hr/>
                                    <div className="containeaction.r-fluid no-x-padding">
                                        {childrenToShow}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </aside>
        );
    }
});
