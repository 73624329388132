/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';
import Moment from 'moment';

import {STATION_TYPE} from './document/document-actions';
import {AlertTypes} from '../common/notification/alert';
import {NotificationActions} from '../common/notification/notification-actions';
import {GetEncodedHTML, StartDownload} from '../common/utils/utils';
import config from '../config/config.js';
import Dispatcher from '../dispatcher/dispatcher';
import {PreloaderActions} from '../preloader/preloader-actions';
import Request from '../request';

// Require for Proper Timezone Display
require('moment-timezone');
const configtz = Moment().tz(config.DefaultTimezone).format('ZZ');

const CONSTANTS = {
    ASSET: {
        CATALOG: {
            GET: {
                SUCCESS: 'asset_actions.asset.catalog.get.success'
            }
        }
    },
    // TBD: icons
    ASSET_TYPES: {
        IMAGE: {id: 1, name: 'Image', icon: 'fas fa-camera'},
        VIDEO: {id: 2, name: 'Video', icon: 'fas fa-video'},
        AUDIO: {id: 3, name: 'Audio', icon: 'fas fa-volume-up'},
        DOCUMENT: {id: 21, name: 'Document', icon: 'fas fa-file-alt'},
        MERCHANDISE: {id: 13, name: 'Merchandise', icon: 'fas fa-file-alt'},
        SCRIPT: {id: 22, name: 'Script', icon: 'fas fa-file-alt'},
        VIDEO_TIMELINE: {id: 23, name: 'Video Timeline', icon: 'fas fa-stream'}
    },
    ASSET_MAIN_TYPES : { // name will probably change
        IMAGE:'image',
        VIDEO:'video',
        AUDIO:'audio',
        DOCUMENT:'document',
        MERCHANDISE: 'merchandise',
        SCRIPT: 'script',
        VIDEO_TIMELINE: 'video-timeline'
    },
    CATALOGS: {
        GET: {
            SUCCESS: 'asset_actions.catalogs.get.success'
        }
    },
    CLEAR: 'asset_actions.clear',
    CLIPS: {
        GET: {
            SUCCESS: 'asset_actions.clips.get.success'
        }
    },
    DELIVERY_TYPES: {
        ON_DEMAND: {id: 0, name: 'On Demand'},
        NEEDS_APPROVAL: {id: 1, name: 'Needs Approval'},
        NON_SERVICEABLE: {id: 2, name: 'Non Serviceable'},
    },
    FILTER: {
        CLEAR: 'asset_actions.filter.clear',
        SET: 'asset_actions.filter.set'
    },
    MIME_TYPES_IMAGE: {
        APPLICATION_DASH: {id: 'application/dash+xml', name: 'application/dash+xml'},
        APPLICATION_MPEG: {id: 'application/vnd.apple.mpegurl', name: 'application/vnd.apple.mpegurl'},
        APPLICATION_SSTR: {id: 'application/vnd.ms-sstr+xml', name: 'application/vnd.ms-sstr+xml'},
        APPLICATION_ZIP: {id: 'application/zip', name: 'application/zip'},
        IMAGE_BMP: {id: 'image/bmp', name: 'image/bmp'},
        IMAGE_GIF: {id: 'image/gif', name: 'image/gif'},
        IMAGE_PNG: {id: 'image/jpeg', name: 'image/jpeg'},
        IMAGE_JPEG: {id: 'image/png', name: 'image/png'},
        IMAGE_SVG: {id: 'image/svg+xml', name: 'image/svg+xml'},
        VIDEO_MP4: {id: 'video/mp4', name: 'video/mp4'},
        VIDEO_OGG: {id: 'video/ogg', name: 'video/ogg'}
    },
    MIME_TYPES_VIDEO: {
        APPLICATION_DASH: {id: 'application/dash+xml', name: 'application/dash+xml'},
        APPLICATION_MPEG: {id: 'application/vnd.apple.mpegurl', name: 'application/vnd.apple.mpegurl'},
        APPLICATION_SSTR: {id: 'application/vnd.ms-sstr+xml', name: 'application/vnd.ms-sstr+xml'},
        VIDEO_MP4: {id: 'video/mp4', name: 'video/mp4'},
        VIDEO_OGG: {id: 'video/ogg', name: 'video/ogg'}
    },
    MIME_TYPES_AUDIO: {
        AUDIO_ACC: {id: 'audio/aac', name: 'audio/aac'},
        AUDIO_MPEG: {id: 'audio/mpeg', name: 'audio/mpeg'},
        AUDIO_MP4: {id: 'audio/mp4', name: 'audio/mp4'},
        AUDIO_OGG: {id: 'audio/ogg', name: 'audio/ogg'},
        AUDIO_VND: {id: 'audio/vnd.wav', name: 'audio/vnd.wav'},
        AUDIO_VORBIS: {id: 'audio/vorbis', name: 'audio/vorbis'},
        AUDIO_XAIFF: {id: 'audio/x-aiff', name: 'audio/x-aiff'},
        AUDIO_MPEGURL: {id: 'audio/x-mpegurl', name: 'audio/x-mpegurl'}
    },
    MIME_TYPES_DOCUMENT: {
        APPLICATION_MSWORD: {id: 'application/msword', name: 'application/msword'},
        APPLICATION_PDF: {id: 'application/pdf', name: 'application/pdf'},
        APPLICATION_RTF: {id: 'application/rtf', name: 'application/rtf'},
        APPLICATION_OPENXMLFORMATS: {id: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', name: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'},
        APPLICATION_XML: {id: 'application/xml', name: 'application/xml'},
        TEXT_PLAIN: {id: 'text/plain', name: 'text/plain'},
    },
    SORT: {
        SET: 'asset_actions.sort.set'
    },
    SOURCE_CONTENT_TYPE: {
        PROXY: {id: 'Proxy', name: 'Proxy', icon: 'fas fa-tv-retro'},
        PRO_RES: {id: 'PRO_RES'},
    },
    GET: {
        SUCCESS: 'asset_actions.get.success',
    },
    THUMBNAILS: {
        GET: {
            SUCCESS: 'asset_actions.thumbnails.get.success'
        }
    },

    toArray: function(constant) {
        return Object.keys(this[constant])
            .map(k => this[constant][k])
            .sort((a, b) => a.name.localeCompare(b.name));
    }

};

export const MainAssetTypesMap = {
    [CONSTANTS.ASSET_MAIN_TYPES.IMAGE]: CONSTANTS.ASSET_MAIN_TYPES.IMAGE,
    [CONSTANTS.ASSET_MAIN_TYPES.VIDEO]: CONSTANTS.ASSET_MAIN_TYPES.VIDEO,
    [CONSTANTS.ASSET_MAIN_TYPES.DOCUMENT]: CONSTANTS.ASSET_MAIN_TYPES.DOCUMENT,
    [STATION_TYPE]: CONSTANTS.ASSET_MAIN_TYPES.DOCUMENT,
    [CONSTANTS.ASSET_MAIN_TYPES.AUDIO]: CONSTANTS.ASSET_MAIN_TYPES.AUDIO,
    [CONSTANTS.ASSET_MAIN_TYPES.MERCHANDISE]: CONSTANTS.ASSET_MAIN_TYPES.MERCHANDISE,
    [CONSTANTS.ASSET_MAIN_TYPES.SCRIPT]: CONSTANTS.ASSET_MAIN_TYPES.SCRIPT,
    [CONSTANTS.ASSET_MAIN_TYPES.VIDEO_TIMELINE]: CONSTANTS.ASSET_MAIN_TYPES.VIDEO_TIMELINE,
};

class AssetActions {
    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
    }

    clearFilter() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.FILTER.CLEAR
        });
    }

    download(assetId) {
        return Request.get('asset/download').query({
            'asset-id': assetId,
        }).exec().then((res) => {
            // Start the download by simulating a click in the browser.
            StartDownload(res.body.downloadUrl);
        }).catch(err => {
            NotificationActions.showAlertDanger('asset.download.error');
            throw err;
        });
    }

    get(queryParams, offset, size, sortFieldName, sortDirection, includeAdditionalData = true) {
        queryParams = queryParams.toJS();
        ['start-date-created'].forEach( attr => {
            let d = queryParams[attr];
            if (d) {
                d = Moment(d);
                if (d.isValid()) {
                    d = d.format('YYYY-MM-DDT00:00:00.000'+configtz);
                } else {
                    d = '';
                }
                queryParams[attr] = d;
            }
        });

        ['end-date-created'].forEach( attr => {
            let d = queryParams[attr];
            if (d) {
                d = Moment(d);
                if (d.isValid()) {
                    d = d.format('YYYY-MM-DDT23:59:59.999'+configtz);
                } else {
                    d = '';
                }
                queryParams[attr] = d;
            }
        });

        PreloaderActions.show('asset-actions.get');

        offset = offset || queryParams.offset || 0;
        size = size || 20;
        queryParams.offset = offset ;
        queryParams.size = size;
        queryParams['sort-field-name'] = sortFieldName || queryParams['sort-field-name'] || 'updatedDate';
        queryParams['sort-direction'] = sortDirection || queryParams['sort-direction'] || 'desc';

        let assets;

        Request.get('asset').query(queryParams).exec().then(res => {
            assets = res.body.results;

            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.SUCCESS,
                filters: Immutable.fromJS(assets),
                offset: offset,
                size: size,
                assets: Immutable.fromJS(assets),
                total: parseInt(res.header['wbtv-total-count'], 10)
            });

            PreloaderActions.hide('asset-actions.get');

            let getIds = (type) => {
                return assets.reduce((r, a) => {
                    if (a.assetType === type.id) {
                        r.push(a.id);
                    }
                    return r;
                }, []);
            };

            let additionalData = {};

            if (includeAdditionalData) {
                /**
                 *  Get all image thumbnails.
                 */
                let imageIds = getIds(CONSTANTS.ASSET_TYPES.IMAGE);
                if (imageIds.length) {
                    additionalData.imagesThumbnails = Request.get('asset/image/url').query({'image-id': imageIds}).exec();
                }

                /**
                 *  Get all video thumbnails.
                 */
                let videoIds = getIds(CONSTANTS.ASSET_TYPES.VIDEO);
                if (videoIds.length) {
                    additionalData.videosThumbnails = Request.get('asset/video/thumbnailURL').query({'video-id': videoIds}).exec();
                }

                /**
                 *  Get all clips.
                 */
                if (queryParams['asset-type'] === CONSTANTS.ASSET_TYPES.VIDEO_TIMELINE.id.toString()) {
                    additionalData.timelineClips = Promise.all(assets.map(timeline =>
                        Request.get(`asset/video-timeline/${timeline.id}/clip`).exec().then(response => ({
                            timelineId: timeline.id,
                            response,
                        }))
                    ));
                }
            }

            return Promise.props(additionalData);
        }).then(additionalData => {
            if (additionalData.imagesThumbnails) {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.THUMBNAILS.GET.SUCCESS,
                    thumbnails: additionalData.imagesThumbnails.body.reduce((r, thumbnail) => {
                        r[thumbnail.imageId.toString()] = thumbnail;
                        return r;
                    }, {})
                });
            }

            if (additionalData.videosThumbnails) {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.THUMBNAILS.GET.SUCCESS,
                    thumbnails: additionalData.videosThumbnails.body.reduce((r, vt) => {
                        r[vt.videoId.toString()] = vt;
                        return r;
                    }, {})
                });
            }

            if (additionalData.timelineClips) {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.CLIPS.GET.SUCCESS,
                    clips: additionalData.timelineClips.reduce((r, clipList) => {
                        r[clipList.timelineId.toString()] = clipList.response.body;
                        return r;
                    }, {})
                });
            }

            return;
        }).catch(err => {
            PreloaderActions.hide('asset-actions.get');
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
            throw err;
        });

        return;
    }

    getAllCatalogs(offset, size) {
        Request.get('security/group').query({
            'category-id': 2,
            offset: offset,
            size: size
        }).exec().then(res => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.CATALOGS.GET.SUCCESS,
                catalogs: Immutable.fromJS(res.body.results),
                offset: offset,
                size:size
            });
            return;
        });

        return;
    }

    getCatalogs(assetId) {
        Request.get(`asset/${assetId}/catalog`).exec().then(res => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.ASSET.CATALOG.GET.SUCCESS,
                catalogs: Immutable.fromJS(res.body),
                assetId: assetId
            });
            return;
        });

        return;
    }

    syncElasticSearch(assetId) {
        Request.get(`asset/${assetId}/refresh-es`).exec().then(() => {
            NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'elastic-search.sync.success');
            return;
        }).catch(err => {
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'elastic-search.sync.error');
            throw err;
        });
    }

    migrateToCloudFront(assetId) {
        let queryParams = {
            force: true
        };
        Request.get(`asset/image/${assetId}/seeds3`).query(queryParams).exec().then(() => {
            NotificationActions.showAlertSuccess('asset.image.source-migrated.success');
        }).catch(err => {
            NotificationActions.showAlertDanger('asset.image.source-migrated.failed');
            throw err;
        });
    }

    sendSubscriptionNotifications(titleId, assetId, subscriptionTypeId, subject, body) {
        const mailData = {
            customNotificationSubject: subject,
            customNotificationMessage: GetEncodedHTML(body),
        };
        Request.put(`title/${titleId}/user-subscription/${subscriptionTypeId}/asset/${assetId}/notify`).send(mailData).exec().then(() => {
            NotificationActions.showAlertSuccess('asset.subscription.send-notifications.success');
        }).catch(err => {
            NotificationActions.showAlertDanger('asset.subscription.send-notifications.failed');
            throw err;
        });
    }


    setFilter(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.FILTER.SET,
            attr: attr,
            value: value
        });
    }
    setSort(sortFieldName, sortDirection) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SORT.SET,
            sortFieldName: sortFieldName,
            sortDirection: sortDirection
        });
    }
}

const actions = new AssetActions();

export {
    actions as AssetActions,
    CONSTANTS as AssetConstants
};
