/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import Moment from 'moment';

import {TitleConstants} from './title-actions';
import {AlertTypes} from '../common/notification/alert';
import {NotificationActions} from '../common/notification/notification-actions';
import config from '../config/config.js';
import {
    ACTIVE_STATUS,
    TITLE_TYPE_FIELDS,
    SYNOPSIS_METADATA_FIELDS,
    TITLE_FIELDS_STATUS
} from '../dashboard/fields';
import Dispatcher from '../dispatcher/dispatcher';
import {PreloaderActions} from '../preloader/preloader-actions';
import Request from '../request';

require('moment-timezone');
const configtz = Moment().tz(config.DefaultTimezone).format('ZZ');

const CONSTANTS = {
    CLEAR:'title.status..clear',
    GET: {
        SUCCESS: 'title.status.get.success',
        START:'title.status.get.start',
        ERROR:'title.status.get.error'
    },
    HIDE: 'title.status.hide',
    SAVE: {
        SUCCESS: 'title.status.save.success'
    },
    SET_ACTIVE: {
        SUCCESS: 'title.status.set_active.success'
    },
    SHOW: 'title.status.show'
};

class TitleStatusActions {
    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
    }

    findRelationTypeById(id) {
        const r = {
            [TitleConstants.TITLE_RELATIONSHIP_TYPE.EPISODE.id]: 'children',
            [TitleConstants.TITLE_RELATIONSHIP_TYPE.SEASON.id]: 'children',
            [TitleConstants.TITLE_RELATIONSHIP_TYPE.SEGMENT.id]: 'children',
            [TitleConstants.TITLE_RELATIONSHIP_TYPE.HAS_EPISODE.id]: 'parents',
            [TitleConstants.TITLE_RELATIONSHIP_TYPE.HAS_SEASON.id]: 'parents',
            [TitleConstants.TITLE_RELATIONSHIP_TYPE.HAS_SEGMENT.id]: 'parents'
        };
        return r[id] || 'other';
    }

    get(queryParams) {
        queryParams = queryParams.toJS();
        if (!queryParams.active) {
            queryParams.active = ACTIVE_STATUS.BOTH.apiName;
        }

        PreloaderActions.show('title-status-actions.get');

        queryParams.offset = queryParams.offset || 0;
        queryParams.size = queryParams.size || 20;

        if (queryParams['related-type']) {
            const relatedTypeAPI = {
                'parents': [TitleConstants.TITLE_RELATIONSHIP_TYPE.HAS_EPISODE.enum_value,
                    TitleConstants.TITLE_RELATIONSHIP_TYPE.HAS_SEASON.enum_value,
                    TitleConstants.TITLE_RELATIONSHIP_TYPE.HAS_SEGMENT.enum_value],
                'children': [TitleConstants.TITLE_RELATIONSHIP_TYPE.EPISODE.enum_value,
                    TitleConstants.TITLE_RELATIONSHIP_TYPE.SEASON.enum_value,
                    TitleConstants.TITLE_RELATIONSHIP_TYPE.SEGMENT.enum_value],
                'related': [TitleConstants.TITLE_RELATIONSHIP_TYPE.ALTERNATIVE_VERSION.enum_value, TitleConstants.TITLE_RELATIONSHIP_TYPE.DIRECTORS_CUT.enum_value,
                    TitleConstants.TITLE_RELATIONSHIP_TYPE.FEATURE.enum_value, TitleConstants.TITLE_RELATIONSHIP_TYPE.FORMAT.enum_value,
                    TitleConstants.TITLE_RELATIONSHIP_TYPE.MOW.enum_value, TitleConstants.TITLE_RELATIONSHIP_TYPE.MINI_SERIES.enum_value,
                    TitleConstants.TITLE_RELATIONSHIP_TYPE.PILOT.enum_value, TitleConstants.TITLE_RELATIONSHIP_TYPE.PILOT_MOW.enum_value,
                    TitleConstants.TITLE_RELATIONSHIP_TYPE.PREQUEL.enum_value, TitleConstants.TITLE_RELATIONSHIP_TYPE.REISSUE.enum_value,
                    TitleConstants.TITLE_RELATIONSHIP_TYPE.SEQUEL.enum_value, TitleConstants.TITLE_RELATIONSHIP_TYPE.OTHER.enum_value,
                    TitleConstants.TITLE_RELATIONSHIP_TYPE.REMAKE.enum_value, TitleConstants.TITLE_RELATIONSHIP_TYPE.LOCAL_PRODUCTION.enum_value,
                    TitleConstants.TITLE_RELATIONSHIP_TYPE.REPACKAGED_FOR_SALES.enum_value, TitleConstants.TITLE_RELATIONSHIP_TYPE.HAS_ALTERNATIVE_VERSION.enum_value,
                    TitleConstants.TITLE_RELATIONSHIP_TYPE.HAS_DIRECTORS_CUT.enum_value, TitleConstants.TITLE_RELATIONSHIP_TYPE.HAS_FEATURE.enum_value,
                    TitleConstants.TITLE_RELATIONSHIP_TYPE.HAS_FORMAT.enum_value, TitleConstants.TITLE_RELATIONSHIP_TYPE.HAS_MINI_SERIES.enum_value,
                    TitleConstants.TITLE_RELATIONSHIP_TYPE.HAS_MOW.enum_value, TitleConstants.TITLE_RELATIONSHIP_TYPE.HAS_PILOT.enum_value,
                    TitleConstants.TITLE_RELATIONSHIP_TYPE.HAS_PILOT_MOW.enum_value, TitleConstants.TITLE_RELATIONSHIP_TYPE.HAS_PREQUEL.enum_value,
                    TitleConstants.TITLE_RELATIONSHIP_TYPE.HAS_REISSUE.enum_value, TitleConstants.TITLE_RELATIONSHIP_TYPE.HAS_SEQUEL.enum_value,
                    TitleConstants.TITLE_RELATIONSHIP_TYPE.HAS_OTHER.enum_value, TitleConstants.TITLE_RELATIONSHIP_TYPE.HAS_REMAKE.enum_value,
                    TitleConstants.TITLE_RELATIONSHIP_TYPE.HAS_LOCAL_PRODUCTION.enum_value, TitleConstants.TITLE_RELATIONSHIP_TYPE.HAS_REPACKAGED_FOR_SALES.enum_value]
            };

            queryParams['related-type'] = relatedTypeAPI[queryParams['related-type']];
        }

        ['start-date-created', 'start-release-date'].forEach( attr => {
            let d = queryParams[attr];
            if (d) {
                d = Moment(d);
                if (d.isValid()) {
                    d = d.format('YYYY-MM-DDT00:00:00.000'+configtz);
                } else {
                    d = '';
                }
                queryParams[attr] = d;
            }
        });

        ['end-date-created', 'end-release-date'].forEach( attr => {
            let d = queryParams[attr];
            if (d) {
                d = Moment(d);
                if (d.isValid()) {
                    d = d.format('YYYY-MM-DDT23:59:59.999'+configtz);
                } else {
                    d = '';
                }
                queryParams[attr] = d;
            }
        });

        let arrayFromQuery = (val) => {
            if (!val) {
                return [];
            }
            if (!Array.isArray(val)) {
                return [val];
            }
            return val;
        };

        // title atribute ~ status magic
        let attrs = arrayFromQuery(queryParams['field-name-type']);
        let statuses = arrayFromQuery(queryParams['field-status-type']);
        delete queryParams['field-name-type'];
        delete queryParams['field-status-type'];
        if (queryParams.q) {
            queryParams['search-title-name'] = queryParams.q;
            delete queryParams.q;
        }

        if (attrs.length > 0 && statuses.length > 0) {
            queryParams['field-name-type'] = [];
            queryParams['field-status-type'] = [];
            attrs.forEach(a => {
                statuses.forEach(s => {
                    queryParams['field-name-type'].push(a);
                    queryParams['field-status-type'].push(s);
                });
            });
        }

        // as sort is order dependant i will manually create sort part of url
        let sortItems = [0, 1, 2, 3];
        let querySort = sortItems.reduce( (s, x) => {
            let sort = queryParams[`S${x}`];
            if (sort) {
                sort = sort.split('_');
                s += `sort-field-name=${sort[0]}_${sort[1]}&sort-direction=${sort[2].toLowerCase()}&`;
            }
            return s;
        }, '');
        if (querySort) {
            querySort = '?' + querySort;
        }
        sortItems.forEach( x => {
            delete queryParams[`S${x}`];
        });

        Request.get(`title/status${querySort}`)
            .query(queryParams)
            .exec()
            .then(res => {
                PreloaderActions.hide('title-status-actions.get');

                const results = res.body.results;
                const categoryGroupMap = Object.keys(TitleConstants.TITLE_TYPES).reduce( (c, t) => {
                    c[TitleConstants.TITLE_TYPES[t].id.toString()] = TitleConstants.TITLE_TYPES[t].categoryGroup;
                    return c;
                }, {});
                let titles = results.map(title => {
                    title.categoryGroup = categoryGroupMap[''+title.category];

                    const titleCategory = title.category;

                    const validFields = Object.values(TITLE_TYPE_FIELDS).reduce((result, ttf) => {
                        if (ttf.id === titleCategory) {
                            result = ttf.titleType.fields;
                        }

                        return result;
                    }, null);

                    if (!validFields) {
                        return title;
                    }

                    title.fieldStatusValues = title.fieldStatusValues.filter(
                        field => validFields.some(vf => vf.id === field.titleFieldNameType)
                    );

                    return title;
                });

                Request.get('lookup/company').query({offset: 0, size: 9999}).exec().then(response => {
                    titles.forEach((title) => {
                        if (title.titleReleaseList[0]?.domesticReleaseCompanyId) {
                            const titleWithReleaseCompany = response.body.results.find(company => company.id === title.titleReleaseList[0]?.domesticReleaseCompanyId);
                            title.titleReleaseList[0].domesticReleaseCompanyName = titleWithReleaseCompany.name;
                        }
                    });

                    Dispatcher.dispatch({
                        actionType: CONSTANTS.GET.SUCCESS,
                        titles: Immutable.fromJS(titles),
                        total: parseInt(res.header['wbtv-total-count'], 10)
                    });
                });
            })
            .catch(err => {
                PreloaderActions.hide('title-status-actions.get');
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
                throw err;
            });

        return;
    }

    hide() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.HIDE
        });
        return;
    }

    // Get title by id, change the active value and
    // save it.
    setActive(title, valueObject) {
        Request.get(`title/${title.get('id')}`).exec().then(res => {
            let apiTitle = res.body;
            let active = 0;
            if (valueObject.get('value') === 'Active') {
                active = 1;
            }
            apiTitle.active = active;
            return Request.put(`title/${title.get('id')}`).send(apiTitle).exec();
        }).then(titleRes => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.SET_ACTIVE.SUCCESS,
                title: Immutable.fromJS(titleRes.body)
            });

            // in case we are on title page
            Dispatcher.dispatch({
                actionType: TitleConstants.TITLE_STATUS.UPDATE,
                fieldStatusValues: Immutable.fromJS(titleRes.body.fieldStatusValues),
                active: titleRes.body.active
            });

            this.hide();
            NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'dashboard.field.update.success');
        }).catch(err => {
            this.hide();
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'dashboard.field.update.error');
            throw err;
        });
        return;
    }

    setStatus(title, field, value) {
        if (field.get('apiName') === 'ACTIVE') {
            this.setActive(title, value);
            return;
        }
        let fieldStatusValues = title.get('fieldStatusValues');
        // check if is modifing a value, adding a new one or removing
        let index = fieldStatusValues.findIndex(
            f => f.get('titleFieldNameType') === field.get('id')
        );
        if (!value) {
            // removing
            if (index === -1) {
                // previous status was unset, nothing to do
                this.hide();
                return;
            }
            fieldStatusValues = fieldStatusValues.delete(index);
        } else {
            // adding
            if (index === -1) {
                // not found add as new
                fieldStatusValues = fieldStatusValues.push(Immutable.fromJS({
                    titleFieldNameType: field.get('id'),
                    titleFieldStatusType: value.get('id')
                }));
            } else {
                // update
                fieldStatusValues = fieldStatusValues.update(index, val => val.set('titleFieldStatusType', value.get('id')));
            }
        }

        Request.put(`title/${title.get('id')}/status`).send(fieldStatusValues.toJS()).exec().then(res => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.SAVE.SUCCESS,
                titleId: title.get('id'),
                updatedStatus: Immutable.fromJS(res.body)
            });

            //in case we are on title page
            Dispatcher.dispatch({
                actionType: TitleConstants.TITLE_STATUS.UPDATE,
                fieldStatusValues: Immutable.fromJS(res.body)
            });

            this.hide();
            NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'dashboard.field.update.success');
            return;
        }).catch(err => {
            this.hide();
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'dashboard.field.update.error');
            throw err;
        });
    }

    hideAllSynopsisMetadata(title) {
        return this.setAllSynopsisMetadataStatuses(title, Immutable.fromJS(TITLE_FIELDS_STATUS.HIDDEN));
    }
    publishAllSynopsisMetadata(title) {
        return this.setAllSynopsisMetadataStatuses(title, Immutable.fromJS(TITLE_FIELDS_STATUS.PUBLISHED));
    }
    setAllSynopsisMetadataStatuses(title, statusValue) {
        let fieldStatusValues = title.get('fieldStatusValues');
        Immutable.fromJS(SYNOPSIS_METADATA_FIELDS).forEach((field) => {
            const titlePropVal = title.get(field.get('propName'));
            let newStatus = statusValue;
            if (!titlePropVal || titlePropVal === '') {
                newStatus = Immutable.fromJS(TITLE_FIELDS_STATUS.MISSING);
            }

            let index = fieldStatusValues.findIndex(f => f.get('titleFieldNameType') === field.get('id'));

            if (index === -1) {
                // not found add as new
                fieldStatusValues = fieldStatusValues.push(Immutable.fromJS({
                    titleFieldNameType: field.get('id'),
                    titleFieldStatusType: newStatus.get('id')
                }));
            } else {
                // update
                fieldStatusValues = fieldStatusValues.update(index, val => val.set('titleFieldStatusType', newStatus.get('id')));
            }

        });

        Request.put(`title/${title.get('id')}/status`).send(fieldStatusValues.toJS()).exec().then(res => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.SAVE.SUCCESS,
                titleId: title.get('id'),
                updatedStatus: Immutable.fromJS(res.body)
            });

            //in case we are on title page
            Dispatcher.dispatch({
                actionType: TitleConstants.TITLE_STATUS.UPDATE,
                fieldStatusValues: Immutable.fromJS(res.body)
            });

            this.hide();
            return;
        }).catch(err => {
            this.hide();
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'dashboard.field.update.error');
            throw err;
        });
    }

    show(title, field) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SHOW,
            field: Immutable.fromJS(field),
            title
        });
        return;
    }

}

const actions = new TitleStatusActions();

export {
    actions as TitleStatusActions,
    CONSTANTS as TitleStatusConstants
};
