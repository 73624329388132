/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {UserErrorActions} from '../../../accounts/create/user-errors/user-errors-actions';
import UserErrorStore from '../../../accounts/create/user-errors/user-errors-store';
import DocumentTitle from '../../../common/document-title';
import {WithStoreOnRoute} from '../../../common/store-on-route';
import Table from '../../../common/user-errors-table/user-errors-table';
import Preloader from '../../../preloader';

class Browse extends Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        const store = UserErrorStore.getState();
        return {
            errors: store.get('userErrors'),
            offset: store.get('offset'),
            showPreloader: store.get('showPreloader'),
            total: store.get('total'),
        };
    }

    static getStores() {
        return [UserErrorStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.init = this.init.bind(this);
    }


    componentDidMount() {
        this.init();
        return;
    }

    componentWillUpdate(nextProps) {
        if (this.props.location.query !== nextProps.location.query) {
            this.init(nextProps);
        }
        return;
    }

    componentWillUnmount() {
        UserErrorActions.clear();
    }

    handleLoadPage(pageNumber) {
        this.setRouteState('offset', pageNumber * 20)
            .apply();
        return;
    }

    handleSortChange(sortFieldName, sortDirection) {
        this.setRouteState('sort-field-name', sortFieldName)
            .setRouteState('sort-direction', sortDirection)
            .apply();
        return;
    }

    init(props) {
        const routeState = this.getRouteState(props || this.props);
        UserErrorActions.findErrors(routeState);
    }

    render() {
        const COLUMNS = ['userId', 'userErrorId', 'userSessionId', 'uriMethod', 'uri', 'uriBody', 'userErrorType', 'userError', 'createdDate'];

        return (
            <DocumentTitle
                message="document-titles.accounts.user-errors"
            >
                <Preloader show={this.state.showPreloader} fixed loadingDots>
                    <section className="content-header">
                        <h1>
                            <i className="fas fa-exclamation-triangle"></i>&nbsp;{this.context.intl.messages['accounts.user-errors']}
                        </h1>
                    </section>
                    <section className="content">
                        <div className="container-fluid no-x-padding">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="box">
                                        <div className="box-body padding-x-20 padding-y-20">
                                            <Table
                                                activePage={Math.ceil((this.state.offset || 0) / 20 ) || 0}
                                                columns={COLUMNS}
                                                onPageChange={this.handleLoadPage}
                                                onSortChange={this.handleSortChange}
                                                sortDirection={this.getRouteState().get('sort-direction') || 'asc'}
                                                sortFieldName={this.getRouteState().get('sort-field-name') || 'createdDate'}
                                                totalPages={Math.ceil(this.state.total/20) || 0}
                                                userErrors={this.state.errors}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Preloader>
            </DocumentTitle>
        );
    }
}

export default WithStoreOnRoute(Container.create(Browse));
export {Browse};
