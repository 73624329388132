/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */
import Dispatcher from '../dispatcher/dispatcher';

const CONSTANTS = {
    APPS: {
        BRAINIAC: 'brainiacAppTheme',
        HARDAC: 'hardacAppTheme'
    },
    CLEAR: 'layout_actions.clear',
    DARK_THEME: 'skin-dark',
    LIGHT_THEME: 'skin-blue',
    THEME: {
        SET: 'layout_actions.theme.set'
    },
    TOGGLE: {
        APP: 'layout_actions.toggle.app',
        FILTERS_PANEL: 'layout_actions.toggle.filters.panel',
        SIDE_BAR: 'layout_actions.toggle.side.bar',
    }
};

class LayoutActions {
    setTheme(themeName, themeValue) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.THEME.SET,
            themeName,
            themeValue
        });

        return;
    }

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
    }

    toggleFiltersPanel() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TOGGLE.FILTERS_PANEL
        });

        return;
    }

    toggleSideBar() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TOGGLE.SIDE_BAR
        });

        return;
    }
}

const layoutActions = new LayoutActions();

export {
    layoutActions as LayoutActions,
    CONSTANTS as LayoutConstants
};
