/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

import {AlertTypes} from '../common/notification/alert';
import {NotificationActions} from '../common/notification/notification-actions';
import Dispatcher from '../dispatcher/dispatcher';
import {PreloaderActions} from '../preloader/preloader-actions';
import Request from '../request';

export default class LookupActions {

    constructor(path, CONSTS) {
        this.CONSTANTS = CONSTS;
        this.path = path;
    }

    get(offset, size) {
        Dispatcher.dispatch({
            actionType: this.CONSTANTS.GET.START
        });

        const preloaderSource = 'lookup-actions.get';
        PreloaderActions.show(preloaderSource);

        Request.get(this.path)
            .query({
                offset: offset,
                size: size
            })
            .exec()
            .then(res => {
                // Some API's return pagination objects,
                // some other return plain arrays.
                let items = [];
                switch (true) {
                case Array.isArray(res.body):
                    items = res.body;
                    offset = 0;
                    size = res.body.length;
                    break;
                case Array.isArray(res.body.results):
                    items = res.body.results;
                    offset = res.body.offset;
                    size = res.body.size;
                    break;
                }

                Dispatcher.dispatch({
                    actionType: this.CONSTANTS.GET.SUCCESS,
                    offset: offset,
                    items: Immutable.fromJS(items).sortBy(this.sortItemsBy),
                    size: size,
                    total: parseInt(res.header['wbtv-total-count'], 10)
                });
            }).then(()=>{
                PreloaderActions.hide(preloaderSource);
                return;
            })
            .catch(err => {
                PreloaderActions.hide(preloaderSource);
                Dispatcher.dispatch({
                    actionType: this.CONSTANTS.GET.ERROR
                });
                throw err;
            });

        return;
    }

    remove(item) {
        let id = item.get('id');

        const preloaderSource = 'lookup-actions.remove';
        PreloaderActions.show(preloaderSource);

        Request.del(`${this.path}/${id}`).exec().then(() => {
            PreloaderActions.hide(preloaderSource);
            Dispatcher.dispatch({
                actionType: this.CONSTANTS.REMOVE_OK,
                removed: item
            });
            NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, this.CONSTANTS.MESSAGES.REMOVE_OK);
        }).catch(err => {
            PreloaderActions.hide(preloaderSource);
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, this.CONSTANTS.MESSAGES.REMOVE_ERROR);
            throw err;
        });
    }

    save(item) {

        let itemJS = item.toJS();

        // Assume POST.
        let method = Request.post;
        let uri = this.path;
        let messages = {
            ok : this.CONSTANTS.MESSAGES.CREATE_OK,
            error: this.CONSTANTS.MESSAGES.CREATE_ERROR
        };
        // Check if PUT.
        let id = itemJS.id;
        if (id !== undefined) {
            method = Request.put;
            uri = `${this.path}/${id}`;
            messages = {
                ok : this.CONSTANTS.MESSAGES.SAVE_OK,
                error: this.CONSTANTS.MESSAGES.SAVE_ERROR
            };
        }

        const preloaderSource = 'lookup-actions.save';
        PreloaderActions.show(preloaderSource);

        method(uri)
            .send(itemJS)
            .exec()
            .then(res => {
                Dispatcher.dispatch({
                    actionType: this.CONSTANTS.SAVE_OK,
                    item: Immutable.fromJS(res.body)
                });
                NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, messages.ok);
            })
            .then(() => {
                PreloaderActions.hide(preloaderSource);
                return;
            })
            .catch(err => {
                PreloaderActions.hide(preloaderSource);
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, messages.error);
                throw err;
            });
        return;
    }

    /**
     *  If needed, any other action-creator can override this
     *  function.
     */
    sortItemsBy(item) {
        return item.get('name');
    }
}
