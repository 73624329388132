/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';
import {IndexRoute, Route} from 'react-router';

import Browse from './browse/browse';
import WithPermissions from '../decorators/with-permissions';
import {RequirePerm} from '../router/router-helpers';
import SessionStore from '../user/session/session-store';

let LoginBackgrounds =
    <Route path="/login-background">
        <IndexRoute
            component={WithPermissions(Browse)}
            onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.LOGIN_BACKGROUND.BROWSE)}/>
    </Route>;

export default LoginBackgrounds;
