import {Container} from 'flux/utils';
import React from 'react';

import {DashboardConstants} from '../dashboard/dashboard-actions';
import {RouterActions} from '../router/router-actions';
import SessionStore from '../user/session/session-store';

class HomeRedirect extends React.Component {
    static getStores() {
        return [SessionStore];
    }

    static calculateState() {
        return {
            defaultHomescreen: SessionStore.getState().get('defaultHomescreen') || ''
        };
    }

    componentDidMount() {
        let defaultHomescreen = this.state.defaultHomescreen;
        const pathname = defaultHomescreen.split('?')[0];

        if (!defaultHomescreen || pathname === '/') {
            defaultHomescreen = DashboardConstants.DEFAULT_DASHBOARD_FILTER;
        }

        if (defaultHomescreen) {
            RouterActions.redirect(defaultHomescreen);
        }
    }

    render() {
        return <div />;
    }
}

export default Container.create(HomeRedirect);
