/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {LanguageCodeType} from '@wbdt-sie/brainiac-web-common';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ControlLabel, FormControl, FormGroup} from 'react-bootstrap';

import BaseSelect from '../../../common/base-select/base-select';
import {FormItem, FormRow, FormSection, LocalizedFormItem} from '../../../common/form/form';
import TinyMCE from '../../../common/tinymce/tinymce';
import {CompareImmutable} from '../../../common/utils/utils';
import config from '../../../config/config.js';
import {HandleSelect} from '../../../lookup/lookup-select-helpers';
import {PublishingListActions} from '../../publishing-list-actions';
import {PublishingListLocalizedActions} from '../../publishing-list-localized-actions';
import PublishingListStore, {PublishingListValidations} from '../../publishing-list-store';

const publishingProps = [
    'active', 'displayName', 'name', 'description', 'purposeType', 'summaryLinkURL'
];

class Summary extends Component {
    static get propTypes() {
        return {
            currentLanguage: PropTypes.string,
            disabled: PropTypes.bool,
            mode: PropTypes.string.isRequired,
            publishingList: PropTypes.instanceOf(Immutable.Map).isRequired,
            publishingListLocalized: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            publishingList: PublishingListStore.getState().get('publishingList')
        };
    }

    static getStores() {
        return [PublishingListStore];
    }

    static get defaultProps() {
        return {
            currentLanguage: undefined,
            disabled: true,
            publishingList: Immutable.Map(),
            publishingListLocalized: undefined
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            isPurposeDirty: false,
            selectedPurpose: PublishingListStore.getPurposeTypes(props.publishingList.get('purposeType'))
        }, this.constructor.calculateState());

        this.handleSelectPurpose = this.handleSelectPurpose.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            selectedPurpose: PublishingListStore.getPurposeTypes(nextProps.publishingList.get('purposeType'))
        });
        return;
    }

    shouldComponentUpdate(nextProps, nextState/*, nextContext*/) {
        if (nextProps.disabled !== this.props.disabled ||
            !CompareImmutable(nextProps.publishingList, this.props.publishingList, publishingProps)) {
            return true;
        }

        if (nextState.selectedPurpose !== this.state.selectedPurpose) {
            return true;
        }
        return false;
    }

    handleSelectPurpose(value) {
        if (!this.state.isPurposeDirty) {
            this.setState({isPurposeDirty: true});
        }

        HandleSelect('purposeType', PublishingListActions.update, {valueAttr: 'id'})(value);
        return;
    }

    handleTinyMCEChange(attr) {
        return function handler(e) {
            PublishingListActions.update(attr, e.target.getContent());
            return;
        };
    }

    render() {
        let localized = this.props.currentLanguage !== LanguageCodeType.DEFAULT_LANGUAGE;
        let disabledOnLocalized = this.props.disabled || localized;
        let updatedDate = <div>{this.context.intl.messages['common.na']}</div>;
        let updatedBy = <div>{this.context.intl.messages['common.na']}</div>;
        let additionalInformation;
        let id = <div>{this.context.intl.messages['common.na']}</div>;

        let listId = <div></div>;
        /*
        let listId = <FormItem
             attr="id"
             label={this.context.intl.messages['publishing-list.id']}
             disabled={this.props.disabled}
             model={this.props.publishingList}
             setter={PublishingListActions.update}
             type="text"
             validations={PublishingListValidations.id.validations}
         />;*/

        if (this.props.mode === 'edit') {

            updatedDate = '-';
            if (this.props.publishingList.get('updatedDate')) {
                updatedDate = Moment(this.props.publishingList.get('updatedDate')).tz(config.DefaultTimezone).format(this.context.intl.messages['datetime-format']);
            }

            updatedBy = this.props.publishingList.get('updatedBy');

            id = this.props.publishingList.get('id');

            additionalInformation = <FormRow>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['publishing-list.last-modified-date']}</ControlLabel>
                    <FormControl.Static>{updatedDate}</FormControl.Static>
                </FormGroup>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['publishing-list.last-modifier']}</ControlLabel>
                    <FormControl.Static>{updatedBy}</FormControl.Static>
                </FormGroup>
            </FormRow>;

            listId = <FormGroup>
                <ControlLabel>{this.context.intl.messages['publishing-list.id']}</ControlLabel>
                <FormControl.Static>{id}</FormControl.Static>
            </FormGroup>;
        }

        return (
            <div>
                <h3><i className="fas fa-info-circle"></i>{this.context.intl.messages['publishing-list.info']}</h3>
                <FormSection>
                    <FormRow>
                        <FormItem
                            attr="name"
                            label={this.context.intl.messages['publishing-list.name']}
                            disabled={disabledOnLocalized}
                            md={6}
                            model={this.props.publishingList}
                            setter={PublishingListActions.update}
                            type="text"
                            validations={PublishingListValidations.name.validations}
                        />
                        <LocalizedFormItem
                            attr="displayName"
                            label={this.context.intl.messages['publishing-list.display-name']}
                            disabled={this.props.disabled}
                            md={6}
                            model={this.props.publishingList}
                            setter={PublishingListActions.update}
                            type="text"
                            validations={PublishingListValidations.displayName.validations}
                            localized={this.props.currentLanguage !== LanguageCodeType.DEFAULT_LANGUAGE}
                            localizedModel={this.props.publishingListLocalized}
                            localizedSetter={PublishingListLocalizedActions.update}
                        />
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['publishing-list.purpose']}&nbsp;<span className="text-red">*</span></ControlLabel>
                            <BaseSelect
                                disabled={disabledOnLocalized}
                                md={6}
                                name={this.context.intl.messages['publishing-list.purpose']}
                                onChange={this.handleSelectPurpose}
                                options="purposeList"
                                store={PublishingListStore}
                                value={this.state.selectedPurpose}
                            />
                        </FormGroup>
                        {listId}
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['publishing-list.status']}</ControlLabel>
                            <FormItem
                                attr="active"
                                disabled={disabledOnLocalized}
                                label={this.context.intl.messages['common.active']}
                                setter={PublishingListActions.update}
                                model={this.props.publishingList}
                                type="checkbox"
                            />
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['publishing-list.description']}</ControlLabel>
                            <TinyMCE
                                content={this.props.publishingList.get('description') || ''}
                                disabled={disabledOnLocalized}
                                id="description"
                                onChange={this.handleTinyMCEChange('description')}
                                forcedRootBlock="div"
                            />
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <FormItem
                                attr="summaryLinkURL"
                                label={this.context.intl.messages['publishing-list.summary-link-url']}
                                disabled={disabledOnLocalized}
                                md={8}
                                model={this.props.publishingList}
                                setter={PublishingListActions.update}
                                type="text"
                                validations={PublishingListValidations.summaryLinkURL.validations}
                            />
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <LocalizedFormItem
                                attr="summaryLinkName"
                                label={this.context.intl.messages['publishing-list.summary-link-name']}
                                disabled={this.props.disabled}
                                md={8}
                                model={this.props.publishingList}
                                setter={PublishingListActions.update}
                                type="text"
                                validations={PublishingListValidations.summaryLinkName.validations}
                                localized={this.props.currentLanguage !== LanguageCodeType.DEFAULT_LANGUAGE}
                                localizedModel={this.props.publishingListLocalized}
                                localizedSetter={PublishingListLocalizedActions.update}
                            />
                        </FormGroup>
                    </FormRow>
                    <hr />
                    {additionalInformation}
                </FormSection>
            </div>
        );
    }
}

export default Container.create(Summary);
