/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export default class Menu extends React.Component {
    static get propTypes() {
        return {
            children: PropTypes.node.isRequired,
            collapsible: PropTypes.bool,
            title: PropTypes.string.isRequired
        };
    }

    static get defaultProps() {
        return {
            collapsible: false
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            open: false
        };

        this.handleClick = this.handleClick.bind(this);
    }


    handleClick(event) {
        event.preventDefault();

        this.setState(prevState => ({
            open: !prevState.open
        }));

        return;
    }

    render() {
        let title = this.props.title;
        if (this.props.collapsible) {
            title = <a href="#" onClick={this.handleClick} style={{display: 'contents'}}>
                <div>
                    {this.props.title}
                    <i className="fas fa-angle-left pull-right"></i>
                </div>
            </a>;
        }
        const menu = <ul className={ClassNames('sidebar-menu', {collapsible: this.props.collapsible, active: this.state.open})}>
            <li className="header">
                {title}
            </li>
            {this.props.children}
        </ul>;

        return menu;
    }
}
