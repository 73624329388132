/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, Col, Row} from 'react-bootstrap';

import AddTalentModal from './add-talent-modal';
import {AttributeFilter} from '../../common/local-search/filter';
import SearchBox from '../../common/search-box/search-box';
import {Debounce} from '../../common/utils/utils';
import TalentTable from '../../talent/browse/table';
import {AssetTalentActions} from '../asset-talent-actions';
import AssetTalentStore from '../asset-talent-store';

class Talent extends Component {
    static get propTypes() {
        return {
            assetId: PropTypes.number.isRequired,
            displayLinks: PropTypes.bool.isRequired,
            viewingMode: PropTypes.string.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true
        };
    }

    static calculateState() {
        return {
            talent: AssetTalentStore.getState().get('talent'),
            originalTalent: AssetTalentStore.getState().get('originalTalent')
        };
    }

    static getStores() {
        return [AssetTalentStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            searchTerm: '',
            showModal: false
        }, this.constructor.calculateState());

        this.removeTalent = this.removeTalent.bind(this);
        this.showAddTalent = this.showAddTalent.bind(this);
        this.hideAddTalent = this.hideAddTalent.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
    }

    componentDidMount() {
        if (this.state.talent.equals(this.state.originalTalent)) {
            AssetTalentActions.get(this.props.assetId);
        }
        return;
    }

    addTalent(talent) {
        let talentJS = talent.toJS();
        talentJS.forEach( t => {
            // FIXME: review the API, it feels like something changed names here.
            t.id = t.talentId || t.id;
        });
        AssetTalentActions.add(Immutable.fromJS(talentJS));
    }

    removeTalent() {
        let toRemove = this.state.talent.filter(t => {
            return t.get('checked');
        });
        AssetTalentActions.remove(toRemove);
    }

    showAddTalent() {
        this.setState({
            showModal: true
        });
    }

    hideAddTalent() {
        this.setState({
            showModal: false
        });
    }

    handleSearchTerm(term) {
        this.setState({
            searchTerm: term
        });

        return;
    }

    checkAll(talent) {
        return function(checked) {
            AssetTalentActions.check(talent, checked);
        };
    }

    render() {
        let modal;
        let filteredTalent = AttributeFilter(this.state.talent, ['firstName', 'middleName', 'lastName', 'suffix'], this.state.searchTerm);
        if (this.state.showModal) {
            modal = <AddTalentModal
                onAdd={this.addTalent}
                onHide={this.hideAddTalent}
                show={this.state.showModal}
                viewingMode={this.props.viewingMode}
            />;
        }

        return (
            <div>
                <h3>
                    <i className="fas fa-star"></i>&nbsp;{this.context.intl.messages['asset.talent.tab.title']}
                </h3>
                <hr/>
                <Row>
                    <Col md={6} mdOffset={3}>
                        <SearchBox onChange={this.handleSearchTerm} value={this.state.searchTerm}/>
                    </Col>
                </Row>
                <div className="bulk-actions">
                    <Button
                        className="danger bg-navy"
                        disabled={!filteredTalent.some(t => {return t.get('checked');})}
                        onClick={this.removeTalent}>
                        <i className="fas fa-times"></i>&nbsp;{this.context.intl.messages['asset.talent.tab.remove']}
                    </Button>
                    <Button
                        bsStyle="primary"
                        className="bg-wb-blue pull-right"
                        onClick={this.showAddTalent}>
                        <i className="fas fa-plus-square"></i>&nbsp;{this.context.intl.messages['asset.talent.tab.add']}
                    </Button>
                </div>
                <div>
                    <TalentTable displayLinks={this.props.displayLinks}
                        talent={filteredTalent}
                        onSelect={AssetTalentActions.check}
                        onSelectAll={this.checkAll(filteredTalent)}
                    />
                </div>
                {modal}
            </div>
        );
    }
}

export default Container.create(Talent);
