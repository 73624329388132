/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {ControlLabel, FormGroup} from 'react-bootstrap';
import Select from 'react-select';

import {AssetRightsActions} from './asset-rights-actions';
import {AssetRightsConstants} from './asset-rights-constants';
import AssetRightsStore from './asset-rights-store';
import BaseSelect from '../base-select/base-select';
import {FormItem} from '../form/form';


class AssetRights extends React.Component {
    static get propTypes() {
        return {
            defaultRightsInfoValue: PropTypes.string,
            disabled: PropTypes.bool,
            model: PropTypes.instanceOf(Immutable.Map).isRequired,
            updater: PropTypes.func.isRequired,
            validations: PropTypes.shape({
                notes: PropTypes.shape({
                    validations: PropTypes.array.isRequired
                })
            }).isRequired
        };
    }

    static get defaultProps() {
        return {
            defaultRightsInfoValue: null,
            disabled: true,
            validations: {notes: {validations: []}}
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);
        const assetRightsState = AssetRightsStore.getState();
        this.state = {
            isRightsMediaTypeDirty: false,
            isRightsTermTypeDirty: false,
            isRightsTerritoryTypeDirty: false,
            isRightsTypeDirty: false,
            selectedRightsMediaType: AssetRightsStore.getRightsMediaType(props.model.get('videoRightsMediaType')),
            selectedRightsTermType: AssetRightsStore.getRightsTermType(props.model.get('videoRightsTermType')),
            selectedRightsTerritoryType: AssetRightsStore.getRightsTerritoryType(props.model.get('videoRightsTerritoryType')),
            selectedRightsType: AssetRightsStore.getRightsType(props.model.get('videoRightsType')),
            selectedRightsInfoType: assetRightsState.get('rightsMatchedType').toJS()
        };

        this.handleSelectRightsInfo = this.handleSelectRightsInfo.bind(this);
        this.handleSelectRightsMediaType = this.handleSelectRightsMediaType.bind(this);
        this.handleSelectRightsTermType = this.handleSelectRightsTermType.bind(this);
        this.handleSelectRightsTerritoryType = this.handleSelectRightsTerritoryType.bind(this);
        this.handleSelectRightsType = this.handleSelectRightsType.bind(this);
    }


    componentWillReceiveProps(nextProps) {
        const matchedRightsInfoType = AssetRightsStore.getRightsInfoTypeFromValues(
            nextProps.model.get('videoRightsType'),
            nextProps.model.get('videoRightsTerritoryType'),
            nextProps.model.get('videoRightsMediaType'),
            nextProps.model.get('videoRightsTermType'),
            nextProps.model.get('rightsNotes'),
        );

        AssetRightsActions.saveMatchedRightsInfoType(matchedRightsInfoType);

        this.setState({
            selectedRightsMediaType: AssetRightsStore.getRightsMediaType(nextProps.model.get('videoRightsMediaType')),
            selectedRightsTermType: AssetRightsStore.getRightsTermType(nextProps.model.get('videoRightsTermType')),
            selectedRightsTerritoryType: AssetRightsStore.getRightsTerritoryType(nextProps.model.get('videoRightsTerritoryType')),
            selectedRightsType: AssetRightsStore.getRightsType(nextProps.model.get('videoRightsType')),
            selectedRightsInfoType: matchedRightsInfoType
        });
        return;
    }

    handleSelectRightsMediaType(value) {
        if (!this.state.isRightsMediaTypeDirty) {
            this.setState({isRightsMediaTypeDirty: true});
        }

        if (value === null) {
            this.props.updater('videoRightsMediaType', null);
            return;
        }

        this.props.updater('videoRightsMediaType', value.id);
        return;
    }

    handleSelectRightsTermType(value) {
        if (!this.state.isRightsTermTypeDirty) {
            this.setState({isRightsTermTypeDirty: true});
        }

        if (value === null) {
            this.props.updater('videoRightsTermType', null);
            return;
        }

        this.props.updater('videoRightsTermType', value.id);
        return;
    }

    handleSelectRightsTerritoryType(value) {
        if (!this.state.isRightsTerritoryTypeDirty) {
            this.setState({isRightsTerritoryTypeDirty: true});
        }

        if (value === null) {
            this.props.updater('videoRightsTerritoryType', null);
            return;
        }

        this.props.updater('videoRightsTerritoryType', value.id);
        return;
    }

    handleSelectRightsType(value) {
        if (!this.state.isRightsTypeDirty) {
            this.setState({isRightsTypeDirty: true});
        }

        if (value === null) {
            this.props.updater('videoRightsType', null);
            return;
        }

        this.props.updater('videoRightsType', value.id);
        return;
    }

    handleSelectRightsInfo(selected) {
        if (selected.id === AssetRightsConstants.RIGHTS_INFO_TYPES.DEFAULT.id) {
            this.handleSelectRightsType({id: AssetRightsConstants.RIGHTS_TYPES.PICTURE_AND_MUSIC.id});
            this.handleSelectRightsTerritoryType({id: AssetRightsConstants.RIGHTS_TERRITORY_TYPES.WORLDWIDE.id});
            this.handleSelectRightsMediaType({id: AssetRightsConstants.RIGHTS_MEDIA_TYPES.ALL_MEDIA.id});
            this.handleSelectRightsTermType({id: AssetRightsConstants.RIGHTS_TERM_TYPES.PERP.id});
            this.props.updater('rightsNotes', '');
        }
        this.setState({
            selectedRightsInfoType: selected
        });
    }

    render() {
        let rightsForm = <div>
            <FormGroup>
                <ControlLabel>{this.context.intl.messages['common.asset-rights.rights']}</ControlLabel>
                <BaseSelect
                    classNamePrefix="dropdown"
                    disabled={this.props.disabled}
                    isClearable={true}
                    multi={false}
                    name={this.context.intl.messages['common.asset-rights.rights']}
                    onChange={this.handleSelectRightsType}
                    options="rightsType"
                    store={AssetRightsStore}
                    value={this.state.selectedRightsType}
                />
            </FormGroup>
            <FormGroup>
                <ControlLabel>{this.context.intl.messages['common.asset-rights.territory']}</ControlLabel>
                <BaseSelect
                    classNamePrefix="dropdown"
                    disabled={this.props.disabled}
                    isClearable={true}
                    multi={false}
                    name={this.context.intl.messages['common.asset-rights.territory']}
                    onChange={this.handleSelectRightsTerritoryType}
                    options="rightsTerritoryType"
                    store={AssetRightsStore}
                    value={this.state.selectedRightsTerritoryType}
                />
            </FormGroup>
            <FormGroup>
                <ControlLabel>{this.context.intl.messages['common.asset-rights.media']}</ControlLabel>
                <BaseSelect
                    classNamePrefix="dropdown"
                    disabled={this.props.disabled}
                    isClearable={true}
                    multi={false}
                    name={this.context.intl.messages['common.asset-rights.media']}
                    onChange={this.handleSelectRightsMediaType}
                    options="rightsMediaType"
                    store={AssetRightsStore}
                    value={this.state.selectedRightsMediaType}
                />
            </FormGroup>
            <FormGroup>
                <ControlLabel>{this.context.intl.messages['common.asset-rights.terms']}</ControlLabel>
                <BaseSelect
                    classNamePrefix="dropdown"
                    disabled={this.props.disabled}
                    isClearable={true}
                    multi={false}
                    name={this.context.intl.messages['common.asset-rights.terms']}
                    onChange={this.handleSelectRightsTermType}
                    options="rightsTermType"
                    store={AssetRightsStore}
                    value={this.state.selectedRightsTermType}
                />
            </FormGroup>
            <FormItem
                attr="rightsNotes"
                disabled={ this.props.disabled }
                label={ this.context.intl.messages['common.asset-rights.notes'] }
                model={ this.props.model }
                setter={ this.props.updater }
                type="textarea"
                validations={this.props.validations.notes.validations}
            />
        </div>;

        let rightsInfo;
        if (this.props.defaultRightsInfoValue) {
            rightsInfo = <FormGroup>
                <ControlLabel>{this.context.intl.messages['common.asset-rights.rights-info']}</ControlLabel>
                <Select
                    classNamePrefix="dropdown"
                    isDisabled={this.props.disabled}
                    getOptionLabel={data => data.name}
                    getOptionValue={data => data.id}
                    isClearable={false}
                    name="rightsInfo"
                    onChange={this.handleSelectRightsInfo}
                    options={AssetRightsConstants.toArray('RIGHTS_INFO_TYPES')}
                    value={this.state.selectedRightsInfoType}
                />
            </FormGroup>;

            if (this.state.selectedRightsInfoType.id === AssetRightsConstants.RIGHTS_INFO_TYPES.DEFAULT.id) {
                rightsForm = <div />;
            }
        }

        return (
            <div className="rights">
                {rightsInfo}
                {rightsForm}
            </div>
        );
    }
}

export default AssetRights;
