/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */


import {LanguageCodeType} from '@wbdt-sie/brainiac-web-common';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ControlLabel, FormControl, FormGroup} from 'react-bootstrap';
import Select from 'react-select';

import {sectionHeader} from './section-header';
import {VideoDeliveryActions, VideoDeliveryConstants} from '../video-delivery-actions';

import {VideoConstants} from '~/src/assets/video/video-actions';
import {FormRow, FormSection} from '~/src/common/form/form';
import {WithValidations} from '~/src/common/validations/validations';

class Workflow extends Component {

    static get propTypes() {
        return {
            isDisabled: PropTypes.bool,
            videoDelivery: PropTypes.instanceOf(Immutable.Map).isRequired,
        };
    }

    static get defaultProps() {
        return {
            isDisabled: false
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.handleSetAssetName = this.handleSetAssetName.bind(this);
        this.handleSetField = this.handleSetField.bind(this);
        this.handleOapOption = this.handleOapOption.bind(this);
    }

    handleOapOption(e) {
        const optionId = Immutable.fromJS(e).get('id');
        VideoDeliveryActions.updateVideoDelivery('syncOptionType', optionId);
        switch (optionId) {
        case VideoDeliveryConstants.SYNC_TO_OAP_TYPES.NO_TRANSFER.id:
            VideoDeliveryActions.updateVideoDelivery('sendToOap', false);
            VideoDeliveryActions.updateVideoDelivery('srcIsProxy', false);
            VideoDeliveryActions.updateVideoDelivery('syncInterplay', false);
            VideoDeliveryActions.updateVideoDelivery('barcodeNum', '');
            VideoDeliveryActions.updateVideoDelivery('showCode', '');
            VideoDeliveryActions.updateVideoDelivery('referenceStandard', '');
            break;
        case VideoDeliveryConstants.SYNC_TO_OAP_TYPES.PROXY_INGEST.id:
            VideoDeliveryActions.updateVideoDelivery('sendToOap', false);
            VideoDeliveryActions.updateVideoDelivery('srcIsProxy', true);
            VideoDeliveryActions.updateVideoDelivery('syncInterplay', false);
            VideoDeliveryActions.updateVideoDelivery('barcodeNum', '');
            VideoDeliveryActions.updateVideoDelivery('showCode', '');
            VideoDeliveryActions.updateVideoDelivery('referenceStandard', '');
            break;
        case VideoDeliveryConstants.SYNC_TO_OAP_TYPES.SEND_TO_OAP.id:
            VideoDeliveryActions.updateVideoDelivery('sendToOap', true);
            VideoDeliveryActions.updateVideoDelivery('srcIsProxy', false);
            VideoDeliveryActions.updateVideoDelivery('syncInterplay', true);
            VideoDeliveryActions.updateVideoDelivery('barcodeNum', '');
            break;
        case VideoDeliveryConstants.SYNC_TO_OAP_TYPES.PULL_FROM_OAP.id:
            VideoDeliveryActions.updateVideoDelivery('sendToOap', false);
            VideoDeliveryActions.updateVideoDelivery('srcIsProxy', false);
            VideoDeliveryActions.updateVideoDelivery('syncInterplay', false);
            VideoDeliveryActions.updateVideoDelivery('showCode', '');
            VideoDeliveryActions.updateVideoDelivery('referenceStandard', '');
            VideoDeliveryActions.updateVideoDelivery('barcodeNum', '');
            break;
        default:
            break;
        }
    }

    handleSetAssetName(e) {
        VideoDeliveryActions.updateVideoDelivery('assetName', e.target.value);
    }

    handleSetField(e, a) {
        VideoDeliveryActions.updateVideoDelivery(Immutable.fromJS(a).get('name'), Immutable.fromJS(e).get('id'));
    }

    render() {
        const contentType = this.props.videoDelivery.get('contentType');
        const contentTypeOptions = VideoConstants.toArray('CONTENT_TYPES');
        const selectedContentType = contentTypeOptions.find((elem) => elem.id === contentType);

        const language = this.props.videoDelivery.get('language');
        const languageOptions = LanguageCodeType.toArray();
        const selectedLanguage = languageOptions.find((elem) => elem.id === language);
        const oapSyncOptions = Object.keys(VideoDeliveryConstants.SYNC_TO_OAP_TYPES).map(option => VideoDeliveryConstants.SYNC_TO_OAP_TYPES[option]);

        return (
            <div>
                <div>
                    {sectionHeader('fas fa-sync',
                        this.context.intl.messages['hardac.video-inbox.summary.send-to-oap.workflow'],
                        this.context.intl.messages['hardac.video-inbox.summary.send-to-oap.workflow.description'])}
                    <FormSection>
                        <FormRow>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['hardac.video-inbox.summary.send-to-oap.workflow.asset-name']} <span className="text-red">*</span></ControlLabel>
                                <FormControl
                                    disabled={this.props.isDisabled}
                                    type="text"
                                    value={this.props.videoDelivery.get('assetName')}
                                    onChange={this.handleSetAssetName}
                                />
                            </FormGroup>
                        </FormRow>
                        <FormRow>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['hardac.video-inbox.summary.send-to-oap.workflow.content-type']} <span className="text-red">*</span></ControlLabel>
                                <Select
                                    classNamePrefix="dropdown"
                                    isDisabled={this.props.isDisabled}
                                    getOptionLabel={data => data.name}
                                    getOptionValue={data => data.id}
                                    isClearable={false}
                                    isMulti={false}
                                    isSearchable={true}
                                    name="contentType"
                                    options={contentTypeOptions}
                                    placeholder={this.context.intl.messages['common.select.placeholder']}
                                    value={selectedContentType}
                                    onChange={this.handleSetField}
                                />
                            </FormGroup>
                        </FormRow>
                        <FormRow>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['hardac.video-inbox.summary.send-to-oap.workflow.language']} <span className="text-red">*</span></ControlLabel>
                                <Select
                                    classNamePrefix="dropdown"
                                    isDisabled={this.props.isDisabled}
                                    getOptionLabel={data => data.name}
                                    getOptionValue={data => data.id}
                                    isClearable={false}
                                    isMulti={false}
                                    isSearchable={true}
                                    name="language"
                                    options={languageOptions}
                                    placeholder={this.context.intl.messages['common.select.placeholder']}
                                    value={selectedLanguage}
                                    onChange={this.handleSetField}
                                />
                            </FormGroup>
                        </FormRow>

                        <FormRow>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['hardac.video-inbox.summary.send-to-oap.workflow.sync-options']} <span className="text-red">*</span></ControlLabel>
                                <Select
                                    classNamePrefix="dropdown"
                                    isDisabled={this.props.isDisabled}
                                    getOptionLabel={data => data.name}
                                    getOptionValue={data => data.id}
                                    isClearable={false}
                                    isMulti={false}
                                    isSearchable={true}
                                    name="sendToOap"
                                    options={oapSyncOptions}
                                    placeholder="Select..."
                                    value={oapSyncOptions.find(c => c.id === this.props.videoDelivery.get('syncOptionType', VideoDeliveryConstants.SYNC_TO_OAP_TYPES.SEND_TO_OAP.id))}
                                    onChange={this.handleOapOption}
                                />
                            </FormGroup>
                        </FormRow>
                    </FormSection>
                </div>
            </div>
        );
    }
}

export default WithValidations(Workflow);
