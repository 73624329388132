/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import AccountInformation from './account-information';
import AdditionalInformation from './additional-information';
import ContactInformation from './contact-information';
import Homepages from './homepages';
import UserInformation from './user-information';
import {UserConstants} from '../../../user/user-actions';

/**
 * Summary Tab and its content.
 */
const Summary = (props) => {
    const {user, userType, mode} = props;
    let sections;
    if (mode === 'create') {
        sections = (
            <div>
                <UserInformation user={user} {...props}/>
                <hr />
                <ContactInformation user={user} {...props}/>
            </div>
        );
    }
    if (mode === 'edit') {
        switch (userType.get('id')) {
        case UserConstants.USER_TYPES.PRIMARY.id:
        case UserConstants.USER_TYPES.SECONDARY.id:
            sections = (
                <div>
                    <UserInformation user={user} userType={userType} {...props}/>
                    <hr />
                    <ContactInformation user={user} userType={userType} {...props}/>
                    <hr />
                    <AccountInformation user={user} {...props}/>
                    <hr />
                    <Homepages user={user}/>
                </div>
            );
            break;
        case UserConstants.USER_TYPES.ANONYMOUS.id:
            sections = (
                <div>
                    <AccountInformation user={user} {...props}/>
                    <hr />
                    <Homepages user={user}/>
                    <hr />
                    <AdditionalInformation user={user} userType={userType} {...props}/>
                </div>
            );
            break;
        }
    }
    return (
        <div className="tab-pane padding-x-20 padding-bottom-20">
            {sections}
        </div>
    );
};

Summary.propTypes = {
    disabled: PropTypes.bool.isRequired,
    mode: PropTypes.string.isRequired,
    user: PropTypes.instanceOf(Immutable.Map).isRequired,
    userType: PropTypes.instanceOf(Immutable.Map).isRequired
};

export default Summary;
