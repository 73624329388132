/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ControlLabel, FormGroup} from 'react-bootstrap';
import Select from 'react-select';

import {sectionHeader} from './section-header';
import {VideoDeliveryActions, VideoDeliveryConstants} from '../video-delivery-actions';
import VideoDeliveryStore from '../video-delivery-store';

import {FormRow, FormSection} from '~/src/common/form/form';
import {WithValidations} from '~/src/common/validations/validations';

class SendToOAP extends Component {

    static get propTypes() {
        return {
            isDisabled: PropTypes.bool,
            videoDelivery: PropTypes.instanceOf(Immutable.Map).isRequired,
        };
    }

    static get defaultProps() {
        return {
            isDisabled: false
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        const vds = VideoDeliveryStore.getState();
        return {
            oapShowCodes: vds.get('oapShowCodes'),
        };
    }

    static getStores() {
        return [VideoDeliveryStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.handleSetField = this.handleSetField.bind(this);
    }

    componentDidMount() {
        setTimeout(() => {
            // Workaround to prevent 'cannot dispatch in the middle of a dispatch' exception
            VideoDeliveryActions.getOapShowCodes();
        }, 100);
    }

    handleSetField(e, a) {
        VideoDeliveryActions.updateVideoDelivery(Immutable.fromJS(a).get('name'), Immutable.fromJS(e).get('id'));
    }

    getSelectedStandardOption(videoDelivery, standardOptions) {
        if (!videoDelivery || !standardOptions) {
            return;
        }

        let result = standardOptions.find((elem) => elem.id === videoDelivery.get('referenceStandard'));
        if (!videoDelivery.get('referenceStandard')) {
            switch (parseFloat(videoDelivery.get('frameRate')).toFixed(2)) {
            case '23.98':
                result = standardOptions.find((elem) => elem.id === VideoDeliveryConstants.STANDARD_TYPES.HD_1080p_23_98.id);
                break;
            case '29.97':
            case '59.94':
                result = standardOptions.find((elem) => elem.id === VideoDeliveryConstants.STANDARD_TYPES.HD_1080i_59_94.id);
                break;
            default:
                break;
            }
        }

        return result;
    }

    render() {
        const standardOptions = Object.keys(VideoDeliveryConstants.STANDARD_TYPES).map(option => VideoDeliveryConstants.STANDARD_TYPES[option]);
        const selectedStandard = this.getSelectedStandardOption(this.props.videoDelivery, standardOptions);

        const showCodeOptions = this.state.oapShowCodes.map(c => ({
            id: c.get('showCode'),
            name: `${c.get('showCode')} | ${c.get('showTitle')}`
        }));

        const selectedShowCode = showCodeOptions.find((elem) => elem.id === `${this.props.videoDelivery.get('showCode')}`);

        return (
            <div>
                <div>
                    {sectionHeader('fas fa-pencil',
                        this.context.intl.messages['hardac.video-inbox.summary.send-to-oap.editable-info'],
                        this.context.intl.messages['hardac.video-inbox.summary.send-to-oap.editable-info.description'])}
                    <FormSection>
                        <FormRow>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['hardac.video-inbox.summary.send-to-oap.editable-info.show-code']} <span className="text-red">*</span></ControlLabel>
                                <Select
                                    classNamePrefix="dropdown"
                                    isDisabled={this.props.isDisabled}
                                    getOptionLabel={data => data.name}
                                    getOptionValue={data => data.id}
                                    isClearable={false}
                                    isMulti={false}
                                    isSearchable={true}
                                    name="showCode"
                                    options={showCodeOptions}
                                    placeholder={this.context.intl.messages['common.select.placeholder']}
                                    value={selectedShowCode}
                                    onChange={this.handleSetField}
                                />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['hardac.video-inbox.summary.send-to-oap.editable-info.standard']}</ControlLabel>
                                <Select
                                    classNamePrefix="dropdown"
                                    isDisabled={this.props.isDisabled}
                                    getOptionLabel={data => data.name}
                                    getOptionValue={data => data.id}
                                    isClearable={false}
                                    isMulti={false}
                                    isSearchable={true}
                                    name="referenceStandard"
                                    options={standardOptions}
                                    placeholder={this.context.intl.messages['common.select.placeholder']}
                                    value={selectedStandard}
                                    onChange={this.handleSetField}
                                />
                            </FormGroup>
                        </FormRow>
                    </FormSection>
                </div>
            </div>
        );
    }
}

export default WithValidations(Container.create(SendToOAP));
