/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import jQuery from 'jquery';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import Pagination from '../../common/table/pagination';

require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('../../styles/data-tables-brainiac.css');
require('datatables.net-responsive-bs');

export default class Table extends Component {
    static get propTypes() {
        return {
            activePage: PropTypes.number.isRequired,
            children: PropTypes.node.isRequired,
            displayLinks: PropTypes.bool,
            onPageChange: PropTypes.func.isRequired,
            onSelect: PropTypes.func.isRequired,
            onSelectAll: PropTypes.func.isRequired,
            onSortChange: PropTypes.func.isRequired,
            sortDirection: PropTypes.string.isRequired,
            sortFieldName: PropTypes.string.isRequired,
            talent: PropTypes.instanceOf(Immutable.List).isRequired,
            totalPages: PropTypes.number.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
            router: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            displayLinks: false
        };
    }

    constructor(props) {
        super(props);

        this.getSortFieldNameClass = this.getSortFieldNameClass.bind(this);
        this.handleHeaderClick = this.handleHeaderClick.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.handleSelectAllClick = this.handleSelectAllClick.bind(this);
        this.populateRows = this.populateRows.bind(this);
    }

    componentDidMount() {
        this.$table = jQuery('#list-talent-table');
        let dataTableConfig = {
            autoWidth: false,
            columnDefs: [{
                // Add the control class to the last column. This colum will
                // contain the button to show the responsive data.
                className: 'control',
                targets:   -1,
                width: 20
            }, {
                targets: 'no-sort',
                orderable: false
            }, {
                className: 'no-padding text-center',
                targets: 0
            }],
            order: [[1, 'dec']],
            iDisplayLength: 100,
            info: false,
            ordering: false,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        };
        if (this.props.onSelect) {
            dataTableConfig.columnDefs.push({
                className: 'no-padding text-center',
                targets: 1
            });
        }
        this.$tableAPI = this.$table.DataTable(dataTableConfig);
        // Register global listeners.
        window.addEventListener('resize', this.handleResize);
        // And trigger the resize handler once so that the datatable
        // knows its initial dimensions.
        this.handleResize();
        this.populateRows(this.props.talent, this.props.displayLinks, this.props.onSelect);
        return;
    }

    componentWillUpdate(nextProps) {
        this.populateRows(nextProps.talent, nextProps.displayLinks, nextProps.onSelect);
        return;
    }

    componentWillUnmount() {
        // check if undefinded only to enable render test
        if (this.$tableAPI) {
            this.$tableAPI.destroy();
        }

        return;
    }

    populateRows(talentList, displayLinks, onSelect) {
        this.$tableAPI.clear();

        // Add data to the jQuery datatable.
        talentList.forEach( (talent, index) => {
            let row = [];

            let talentId = talent.get('id');

            let thumbnail = talent.getIn(['thumbnails', 0]);
            let picture = `<img class="table-thumbnail" src="${ require('../../img/talent-thumb.jpg') }"/>`;
            if (thumbnail) {
                picture = `<img class="table-thumbnail" src="${ thumbnail.get('thumbnailUrl') }"/>`;
            }

            let firstName = talent.get('firstName') || '';
            let middleName = talent.get('middleName') || '';
            let lastName = talent.get('lastName') || '';
            let suffix = talent.get('suffix') || '';

            if (displayLinks) {
                picture = `<a href="/talent/${talentId}" class="edit-talent">${picture}</a>`;
                firstName = `<a href="/talent/${talentId}" class="edit-talent">${firstName}</a>`;
                middleName = `<a href="/talent/${talentId}" class="edit-talent">${middleName}</a>`;
                lastName = `<a href="/talent/${talentId}" class="edit-talent">${lastName}</a>`;
                suffix = `<a href="/talent/${talentId}" class="edit-talent">${suffix}</a>`;
            }

            let createdDate = Moment(talent.get('createdDate'));
            if (createdDate.isValid()) {
                createdDate = createdDate.format(this.context.intl.messages['date-format']);
            } else {
                createdDate = '-';
            }

            row.push(
                picture,
                firstName,
                middleName,
                lastName,
                suffix,
                createdDate,
                ''
            );
            if (onSelect) {
                let checkedStatus = '';
                if (talent.get('checked')) {
                    checkedStatus = 'checked';
                }
                row.unshift(`<input type="checkbox" class="select-row" data-index="${ index }" ${checkedStatus}/>`);
            }
            this.$tableAPI.row.add(row);

            return;
        });

        this.$tableAPI.draw(false);
        // Now, since the data has changed the columns widths, trigger
        // the resize handler in order to update the responsive feature.
        this.handleResize();
    }

    getSortFieldNameClass(headerName) {

        let r = 'sorting';

        if (headerName === this.props.sortFieldName) {
            r = `sorting_${this.props.sortDirection}`;
        }

        return r;
    }

    handleHeaderClick(headerName) {
        let newSortDirection = 'asc';
        if (this.props.sortFieldName === headerName && this.props.sortDirection === 'asc') {
            newSortDirection = 'desc';
        }

        if (this.props.onSortChange) {
            this.props.onSortChange(headerName, newSortDirection);
        }
        return;
    }

    /**
     * Update the datatable columns size.
     */
    handleResize() {
        this.$tableAPI.responsive.recalc();
        return;
    }

    /**
     * This function is kind of "special" because it needs to handle
     * events bubbled from the data table rows, these rows cannot use
     * the JSX syntax because they are created by the data table
     * jQuery plugin instead of React.
     */
    handleRowClick(event) {
        switch (true) {
        // Handle click on an user's name.
        case !!~event.target.className.indexOf('edit-talent'):
            // Prevent the default anchor click event.
            event.preventDefault();
            this.context.router.push(event.target.getAttribute('href'));
            break;
        case !!~event.target.className.indexOf('select-row'):
            // Prevent the default anchor click event.
            event.preventDefault();
            let index = parseInt(event.target.getAttribute('data-index'), 10);
            this.props.onSelect(Immutable.List([this.props.talent.get(index)]), event.target.checked);
            break;
        }

        return;
    }

    handleSelectAllClick(event) {
        this.props.onSelectAll(event.target.checked);
        return;
    }

    render() {
        let pagination;
        if (this.props.onPageChange) {
            pagination = (
                <div className="row">
                    <div className="col-sm-7 col-sm-offset-5">
                        <Pagination
                            activePage={this.props.activePage}
                            onChange={this.props.onPageChange}
                            totalPages={this.props.totalPages}
                        />
                    </div>
                </div>
            );

        }
        // check for size > 0 because every of empty list is true :(
        let selectAll = this.props.talent.size > 0 && this.props.talent.every((t) => {
            return t.get('checked');
        });

        let checkbox = null;
        if (this.props.onSelect) {
            checkbox = <th className="no-sort"><input type="checkbox" onChange={this.handleSelectAllClick} checked={selectAll} disabled={this.props.talent.size === 0}/></th>;
        }

        return (
            <div>
                {this.props.children}
                <table id="list-talent-table" className="-striped table table-bordered table-striped responsive">
                    <thead>
                        <tr>
                            {checkbox}
                            <th className="no-sort text-center sorting_disabled" rowSpan="1" colSpan="1" ><i className="fas fa-camera" title=""/></th>
                            <th className={this.getSortFieldNameClass('firstName')} onClick={this.handleHeaderClick.bind(this, 'firstName')} rowSpan="1" colSpan="1">First Name</th>
                            <th className={this.getSortFieldNameClass('middleName')} onClick={this.handleHeaderClick.bind(this, 'middleName')} rowSpan="1" colSpan="1">Middle Name</th>
                            <th className={this.getSortFieldNameClass('lastName')} onClick={this.handleHeaderClick.bind(this, 'lastName')} rowSpan="1" colSpan="1">Last Name</th>
                            <th rowSpan="1" colSpan="1">Suffix</th>
                            <th className={this.getSortFieldNameClass('createdDate')} onClick={this.handleHeaderClick.bind(this, 'createdDate')} rowSpan="1" colSpan="1">Date Created</th>
                            <th className="no-sort"></th>
                        </tr>
                    </thead>
                    <tbody onClick={this.handleRowClick}>
                    </tbody>
                </table>
                {pagination}
            </div>
        );
    }
}
