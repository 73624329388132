/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {ControlLabel, FormControl, FormGroup} from 'react-bootstrap';

import {FormItem, FormRow, FormSection} from '../../../common/form/form';
import {CompareImmutable} from '../../../common/utils/utils';
import config from '../../../config/config.js';
import TerritorySelect from '../../../lookup/territory/territory-select';
import {ClientRepGroupActions} from '../../../security/client-rep-group/client-rep-group-actions';
import {ClientRepGroupValidations} from '../../../security/client-rep-group/client-rep-group-store';

class Summary extends React.Component {

    static get propTypes() {
        return {
            clientRepGroup: PropTypes.instanceOf(Immutable.Map).isRequired,
            disabled: PropTypes.bool,
            mode: PropTypes.string.isRequired,
        };
    }

    static get defaultProps() {
        return {
            disabled: true
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.handleTerritoriesChange = this.handleTerritoriesChange.bind(this);
    }

    shouldComponentUpdate(nextProps) {
        if (!CompareImmutable(nextProps.clientRepGroup, this.props.clientRepGroup)) {
            return true;
        }
        return false;
    }

    handleTerritoriesChange(territories) {
        if (!territories) {
            ClientRepGroupActions.updateClientRepGroup('territories', Immutable.OrderedSet());
            return;
        }
        ClientRepGroupActions.updateClientRepGroup('territories', Immutable.fromJS(territories).toSet().sortBy(g => g.get('name')));
        return;
    }

    render() {
        let updatedDate = <div>{this.context.intl.messages['common.na']}</div>;
        let lastModifier = <div>{this.context.intl.messages['common.na']}</div>;
        let additionalInformation;

        if (this.props.mode === 'edit') {
            if (this.props.clientRepGroup.get('updatedDate')) {
                updatedDate = Moment(this.props.clientRepGroup.get('updatedDate')).tz(config.DefaultTimezone).format(this.context.intl.messages['datetime-format']);
            } else {
                updatedDate = '-';
            }

            lastModifier = this.props.clientRepGroup.get('lastModifier');

            additionalInformation = <FormRow>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['groups.client-rep-group.edit.last-modified-date']}</ControlLabel>
                    <FormControl.Static>{updatedDate}</FormControl.Static>
                </FormGroup>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['groups.client-rep-group.edit.last-modifier']}</ControlLabel>
                    <FormControl.Static>{lastModifier}</FormControl.Static>
                </FormGroup>
            </FormRow>;
        }
        return (
            <div className="tab-pane padding-x-20 padding-bottom-20">
                <FormSection title={this.context.intl.messages['groups.client-rep-group.create.info.title']} iconClass="fas fa-info-circle">
                    <FormRow>
                        <FormItem attr="name"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['groups.client-rep-group.create.info.name']}
                            model={this.props.clientRepGroup}
                            onlySpaces
                            placeholder={this.context.intl.messages['groups.client-rep-group.create.info.name.placeholder']}
                            setter={ClientRepGroupActions.updateClientRepGroup}
                            type="text"
                            validations={ClientRepGroupValidations.name.validations}
                        />
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['groups.client-rep-group.summary.territory']}</ControlLabel>
                            <TerritorySelect
                                disabled={this.props.disabled}
                                multi
                                name="territories"
                                onChange={this.handleTerritoriesChange}
                                value={this.props.clientRepGroup.get('territories')}
                                setter={ClientRepGroupActions.setTerritories}
                            />
                        </FormGroup>
                    </FormRow>
                    <hr />
                    {additionalInformation}
                </FormSection>
            </div>
        );
    }
}

export default Summary;
