/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';


import Table from './table';

import DocumentTitle from '~/src/common/document-title';
import SearchBox from '~/src/common/search-box/search-box';
import {WithStoreOnRoute} from '~/src/common/store-on-route';
import {Debounce} from '~/src/common/utils/utils';
import FilterOptions from '~/src/groups/browse/filter-options';
import {LayoutActions} from '~/src/layout/layout-actions';
import LayoutStore from '~/src/layout/layout-store';
import Preloader from '~/src/preloader';
import {GroupActions, GroupConstants} from '~/src/security/group/group-actions';
import GroupStore from '~/src/security/group/group-store';
import SessionStore from '~/src/user/session/session-store';

class Browse extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static getPermissions() {
        return {
            canView: SessionStore.canUser(SessionStore.PERMISSIONS.STATIONS.CONFIGURATION),
        };
    }

    static calculateState() {
        const store = GroupStore.getState();

        return {
            stations: store.getIn(['station', 'groups']),
            showFiltersPanel: LayoutStore.getState().get('showFiltersPanel'),
            showPreloader: store.get('showPreloader'),
            total: store.getIn(['station', 'total'])
        };
    }

    static getStores() {
        return [LayoutStore, GroupStore];
    }

    constructor(props) {
        super(props);

        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
        this.init = this.init.bind(this);
        this.handleLoadPage = this.handleLoadPage.bind(this);
    }


    componentDidMount() {
        this.init();
    }

    componentWillUpdate(nextProps) {
        if (this.props.location.query !== nextProps.location.query) {
            this.init(nextProps);
        }
        return;
    }

    handleLoadPage(pageNumber) {
        this.setRouteState('offset', pageNumber * 20)
            .apply();
        return;
    }

    init(props) {
        const routeState = this.getRouteState(props || this.props);
        let status = undefined;
        if (!routeState.get('show-inactive')) {
            status = true;
        }
        GroupActions.get(GroupConstants.STATION_GROUP,
            true,
            {
                filters: routeState.remove('offset').remove('size'),
                includeUserCount: true,
                offset: routeState.get('offset', 0),
                size: routeState.get('size', 20),
                sortDirection: routeState.get('sort-direction', 'asc'),
                sortFieldName: routeState.get('sort-field-name', 'name'),
                active: status
            }
        );
    }

    handleSearchTerm(term) {
        this.setRouteState('name', term).clearRouteState().apply();
    }

    handleToggleFiltersPanel() {
        LayoutActions.toggleFiltersPanel();
        return;
    }

    render() {
        return (
            <DocumentTitle
                message="document-titles.stations"
            >
                <div className={ClassNames({'control-sidebar-open': this.state.showFiltersPanel})}>
                    <div className={ClassNames({'sidebar-opened': this.state.showFiltersPanel})}>
                        <Preloader show={this.state.showPreloader} fixed loadingDots>
                            <section className="content-header">
                                <h1>
                                    <i className="fa-regular fa-retro-tv"></i>&nbsp;{this.context.intl.messages['station-groups.browse.title']}
                                    &nbsp;<small>{this.state.total}</small>
                                </h1>
                            </section>
                            <section className="content">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="container-fluid no-x-padding">
                                            <div className="row">
                                                <div className="col-md-6 show-grid">
                                                    <SearchBox onChange={this.handleSearchTerm} value={this.getRouteState().get('name')}/>
                                                </div>
                                                <div className="col-md-6 show-grid">
                                                    <Button onClick={this.handleToggleFiltersPanel} bsSize="large" className="btn btn-primary-outline pull-right">
                                                        <i className="fas fa-sliders-h"></i>&nbsp;{this.context.intl.messages['common.filteringOptions']}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container-fluid no-x-padding">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="box">
                                                        <div className="box-body">
                                                            <Table
                                                                activePage={Math.ceil((this.getRouteState().get('offset') || 0) / 20 ) || 0}
                                                                stations={this.state.stations}
                                                                onPageChange={this.handleLoadPage}
                                                                displayLinks={this.props.permissions.canView}
                                                                totalPages={Math.ceil(this.state.total/20) || 0}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <FilterOptions
                                location={this.props.location}
                                showChildGroupId={false}
                            />
                        </Preloader>
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}

export default WithStoreOnRoute(Container.create(Browse));
