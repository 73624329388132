/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React, {Component} from 'react';
import Select from 'react-select';

import {VideoDeliveryConstants} from '../video-delivery-actions';

import Panel from '~/src/common/panel/panel';
import {WithStoreOnRoute} from '~/src/common/store-on-route';
import {GetAttr} from '~/src/common/utils/utils';
import {LayoutActions} from '~/src/layout/layout-actions';

const StatusOptions = WithStoreOnRoute(class StatusOptions extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
        };
    }

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(value) {
        if (value?.id) {
            this.setRouteState('status', value.id)
                .setRouteState('operator', 'AND')
                .clearRouteState('offset')
                .apply();
        } else {
            this.clearRouteState('status')
                .clearRouteState('offset')
                .apply();
        }
    }

    render() {
        const statusOptions = VideoDeliveryConstants.toArray('STATUS_TYPES');
        let selected = statusOptions.find((item) => {
            return item.id === this.props.location.query.status;
        });

        // This is hacky, but if we pass undefined to Select value sometimes the internal state won't get cleared
        if (selected === undefined) {
            selected = null;
        }

        return (
            <Panel title="Status Type" classTitle="box-title" collapsible defaultExpanded>
                <div data-style="display: block;" className="box-body">
                    <div className="form-group">
                        <Select
                            classNamePrefix="dropdown"
                            getOptionLabel={GetAttr('name')}
                            getOptionValue={GetAttr('id')}
                            isClearable={true}
                            isMulti={false}
                            name="status"
                            onChange={this.handleChange}
                            options={statusOptions}
                            placeholder="Select..."
                            value={selected}
                        />
                    </div>
                </div>
            </Panel>
        );
    }
});

const EncodingPlatformOptions = WithStoreOnRoute(class EncodingPlatformOptions extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
        };
    }

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(value) {
        if (value?.id) {
            this.setRouteState('encode-platform-type', value.id)
                .setRouteState('operator', 'AND')
                .clearRouteState('offset')
                .apply();
        } else {
            this.clearRouteState('encode-platform-type')
                .clearRouteState('offset')
                .apply();
        }
    }

    render() {
        const encodingPlatformOptions = VideoDeliveryConstants.toArray('ENCODE_PLATFORM_TYPES');
        let selected = encodingPlatformOptions.find((item) => {
            return item.id === this.props.location.query['encode-platform-type'];
        });

        // This is hacky, but if we pass undefined to Select value sometimes the internal state won't get cleared
        if (selected === undefined) {
            selected = null;
        }

        return (
            <Panel title="Encoding Platform" classTitle="box-title" collapsible defaultExpanded>
                <div data-style="display: block;" className="box-body">
                    <div className="form-group">
                        <Select
                            classNamePrefix="dropdown"
                            getOptionLabel={GetAttr('name')}
                            getOptionValue={GetAttr('id')}
                            isClearable={true}
                            isMulti={false}
                            name="encode-platform-type"
                            onChange={this.handleChange}
                            options={encodingPlatformOptions}
                            placeholder="Select..."
                            value={selected}
                        />
                    </div>
                </div>
            </Panel>
        );
    }
});


class FilterOptions extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
        };
    }

    constructor(props) {
        super(props);

        this.handleToggleFiltersPanel = this.handleToggleFiltersPanel.bind(this);
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.location !== this.props.location) {
            return true;
        }

        return false;
    }

    handleToggleFiltersPanel() {
        LayoutActions.toggleFiltersPanel();
    }

    render() {
        return (
            <div>
                <aside className="control-sidebar B(0) Ovx(h) Ovy(a)">

                    <div className="tab-content">

                        <p>
                            <button className="btn btn-block bg-navy" onClick={this.handleToggleFiltersPanel}><i className="fas fa-chevron-right pull-right"></i>&nbsp;Close Options</button>
                        </p>

                        <StatusOptions {...this.props} />

                        <EncodingPlatformOptions {...this.props} />
                    </div>

                </aside>

                <div style={{position: 'fixed', height: 'auto'}} className="control-sidebar-bg"></div>
            </div>
        );
    }
}

export default WithStoreOnRoute(FilterOptions);

export {
    StatusOptions,
    EncodingPlatformOptions
};
