/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';

import CaptionsTable from './captions-table';

import {FormSection} from '~/src/common/form/form';

type Status = 'active' | 'inactive';

export type LanguageSectionProps = {
    canEditCaptions: boolean,
    disabled: boolean,
    language: string,
    files: ReadonlyArray<VideoWebVtt>
}

export default function LanguageSection(props: LanguageSectionProps) {
    const map = groupByActiveFlag(props.files);
    const hasActiveFileForType = map.active.reduce((memo, file) => {
        memo[file.fileType] = true;
        return memo;
    }, {} as Record<WebVttFileType, boolean>);
    return (
        <FormSection title={props.language} titleLevel={4}>
            <CaptionsTable
                key="active"
                canEditCaptions={props.canEditCaptions}
                disabled={props.disabled}
                files={map.active}
                hasActiveFileForType={hasActiveFileForType}
                isActive={true}
                language={props.language}
            />
            <CaptionsTable
                key="inactive"
                canEditCaptions={props.canEditCaptions}
                disabled={props.disabled}
                files={map.inactive}
                hasActiveFileForType={hasActiveFileForType}
                isActive={false}
                language={props.language}
            />
        </FormSection>
    );
}

function groupByActiveFlag(files: ReadonlyArray<VideoWebVtt>): Record<Status, ReadonlyArray<VideoWebVtt>> {
    return files.reduce((memo: Record<Status, VideoWebVtt[]>, file) => {
        if (file.active) {
            memo.active.push(file);
        } else {
            memo.inactive.push(file);
        }
        return memo;
    }, {'active': [], 'inactive': []});
}
