/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {SlidingPanelConstants} from './sliding-panel-actions';
import Dispatcher from '../../dispatcher/dispatcher';

class SlidingPanelStore extends ReduceStore {

    getInitialState() {
        return Immutable.Map();
    }

    isVisible(id) {
        let show = this.getState().get(''+id);
        return !!show;
    }

    reduce(state, action) {
        switch (action.actionType) {

        case SlidingPanelConstants.SHOW:
            state = state.set(''+action.id, true);
            break;

        case SlidingPanelConstants.HIDE:
            state = state.delete(''+action.id);
            break;
        }
        return state;
    }
}

let store = new SlidingPanelStore(Dispatcher);

export default store;
