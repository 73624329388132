/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

import Catalogs from './catalogs';
import DuplicateModal from './duplicate-modal';
import ListItems from './list-items';
import Summary from './summary';
import Users from './users';
import RouteValidator from '../../common/custom-validator/route/route';
import DocumentTitle from '../../common/document-title';
import {Tab, Tabs} from '../../common/routing-tab/routing-tab';
import {History, SideNotes} from '../../common/side-notes/side-notes';
import {AlertsWarnings} from '../../common/validations/alerts-warnings';
import ActionsMenu from '../../layout/actions-menu/actions-menu';
import StickyHeader from '../../layout/sticky-header/sticky-header';
import Preloader from '../../preloader';
import {RouterActions} from '../../router/router-actions';
import {ActionHistoryActions, ActionHistoryConstants} from '../../system/action-history/action-history-actions';
import SessionStore from '../../user/session/session-store';
import {BatchActions, BatchConstants} from '../batch-actions';
import BatchStore from '../batch-store';

class Create extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            params: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired,
            route: RouteValidator
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            route: undefined
        };
    }

    static getPermissions() {
        return {
            canCreateDocumentBatch: SessionStore.canUser(SessionStore.PERMISSIONS.SCHEDULING.DOCUMENT_BATCHES.CREATE),
            canCreateImageBatch: SessionStore.canUser(SessionStore.PERMISSIONS.SCHEDULING.IMAGE_BATCHES.CREATE),
            canCreateTitleBatch: SessionStore.canUser(SessionStore.PERMISSIONS.SCHEDULING.TITLE_BATCHES.CREATE),
            canCreateVideoBatch: SessionStore.canUser(SessionStore.PERMISSIONS.SCHEDULING.VIDEO_BATCHES.CREATE),
            canEditDocumentBatch: SessionStore.canUser(SessionStore.PERMISSIONS.SCHEDULING.DOCUMENT_BATCHES.EDIT),
            canEditImageBatch: SessionStore.canUser(SessionStore.PERMISSIONS.SCHEDULING.IMAGE_BATCHES.EDIT),
            canEditTitleBatch: SessionStore.canUser(SessionStore.PERMISSIONS.SCHEDULING.TITLE_BATCHES.EDIT),
            canEditVideoBatch: SessionStore.canUser(SessionStore.PERMISSIONS.SCHEDULING.VIDEO_BATCHES.EDIT)
        };
    }

    static calculateState() {
        return {
            batch: BatchStore.getState().get('batch'),
            batchDuplicated: BatchStore.getState().get('batchDuplicated'),
            history: BatchStore.getState().get('history'),
            item: BatchStore.getState().get('item'),
            items: BatchStore.getState().get('items'),
            originalBatch: BatchStore.getState().get('originalBatch'),
            originalItems: BatchStore.getState().get('originalItems'),
            showDuplicateBatchPreloader: BatchStore.getState().get('showDuplicateBatchPreloader'),
            showItemDetail: BatchStore.getState().get('showItemDetail'),
            showPreloader: BatchStore.getState().get('showPreloader')
        };
    }

    static getStores() {
        return [BatchStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            showDuplicateModal: false
        }, this.constructor.calculateState());

        this.addNote = this.addNote.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleOpenDuplicateModal = this.handleOpenDuplicateModal.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.isDirty = this.isDirty.bind(this);
        this.toggleDuplicateModal = this.toggleDuplicateModal.bind(this);

        return;
    }

    componentDidMount() {
        if (this.props.route.mode === 'edit') {
            BatchActions.findById(this.props.params.id);
        } else {
            BatchActions.clear();
        }
        RouterActions.registerRedirectCheck((location) => {
            return this.isDirty(location);
        });

        return;
    }

    componentWillReceiveProps() {
        RouterActions.registerRedirectCheck((location) => {
            return this.isDirty(location);
        });
    }

    addNote(description) {
        ActionHistoryActions.addNote(this.props.params.id, description, ActionHistoryConstants.ACTION_OBJECTS.SCHEDULEBATCH);
    }

    handleCancel(batchType) {
        RouterActions.redirect(`/scheduling/${batchType}`, true);
    }

    handleOpenDuplicateModal() {
        BatchActions.setDuplicate(this.state.originalBatch);
        this.toggleDuplicateModal();
    }

    handleSave(batchType) {
        BatchActions.save(batchType, this.state.batch, this.state.items);
        return;
    }

    isDirty(location) {
        if (location) {
            let goingTo = location.pathname.split('/');
            let current = this.props.location.pathname.split('/');
            if (goingTo[1] === current[1] && goingTo[2] === current[2] &&//entity and subentity
                goingTo[3] === current[3] //entity id
            ) {
                return false; // just changing tabs no confirmation needed.
            }
        }

        return !this.state.items.equals(this.state.originalItems)
            || !this.state.batch.equals(BatchStore.getState().get('originalBatch'));
    }

    toggleDuplicateModal() {
        this.setState(prevState => ({
            showDuplicateModal: !prevState.showDuplicateModal
        }));
    }

    render() {
        const batchType = this.props.location.pathname.split('/')[2];
        let baseRoute = `/scheduling/${batchType}/create`;
        let disabled = true;
        let canDuplicate = false;
        let entityName;
        let eventsAndNotes;
        let historyTab;
        let usersTab;
        let mode = this.props.route.mode;
        let validations = BatchStore.getValidations();

        switch (batchType.toUpperCase()) {
        case BatchConstants.BATCH_TYPES.DOCUMENT:
            disabled = !this.props.permissions.canEditDocumentBatch;
            if (mode === 'create') {
                disabled = !this.props.permissions.canCreateDocumentBatch;
            }
            canDuplicate = this.props.permissions.canCreateDocumentBatch;
            break;
        case BatchConstants.BATCH_TYPES.IMAGE:
            disabled = !this.props.permissions.canEditImageBatch;
            if (mode === 'create') {
                disabled = !this.props.permissions.canCreateImageBatch;
            }
            canDuplicate = this.props.permissions.canCreateImageBatch;
            break;
        case BatchConstants.BATCH_TYPES.TITLE:
            disabled = !this.props.permissions.canEditTitleBatch;
            if (mode === 'create') {
                disabled = !this.props.permissions.canCreateTitleBatch;
            }
            canDuplicate = this.props.permissions.canCreateTitleBatch;
            break;
        case BatchConstants.BATCH_TYPES.VIDEO:
            disabled = !this.props.permissions.canEditVideoBatch;
            if (mode === 'create') {
                disabled = !this.props.permissions.canCreateVideoBatch;
            }
            canDuplicate = this.props.permissions.canCreateVideoBatch;
            break;
        }

        // Disable the form if the batch has already been executed.
        if (this.state.batch.get('executedAt')) {
            disabled = true;
        }

        let duplicateBtn;
        if (mode === 'edit') {
            baseRoute = `/scheduling/${batchType}/${this.props.params.id}`;
            entityName = this.state.batch.get('batchName');
            eventsAndNotes = (
                <SideNotes
                    basePath={baseRoute}
                    dialogMessage={this.context.intl.messages['common.side-notes.add-note.body']}
                    disabled={false}
                    notes={this.state.history.toJS()}
                    onAddNote={this.addNote}
                    title={this.context.intl.messages['common.side-notes.title']}
                />
            );
            historyTab = <Tab route={`${baseRoute}/history`} title={this.context.intl.messages['common.tab-title.history']} tabClassName="pull-right">
                <History
                    actionObject={ActionHistoryConstants.ACTION_OBJECTS.SCHEDULEBATCH}
                    id={this.props.params.id}
                    pagination
                    pathname={this.props.location.pathname}
                    query={this.props.location.query}/>
            </Tab>;

            usersTab = <Tab route={`${baseRoute}/users`} title={this.context.intl.messages['common.tab-title.users']}>
                <Users
                    displayLinks={false}
                    batchId={this.props.params.id}
                    location={this.props.location}
                />
            </Tab>;

            if (canDuplicate) {
                duplicateBtn = (
                    <Button
                        bsSize="large"
                        className="btn btn-primary-outline Mr(3px) Mb(3px)"
                        disabled={false}
                        onClick={this.handleOpenDuplicateModal}
                    >
                        <i className="fas fa-copy"/>&nbsp;{this.context.intl.messages['scheduling.batch.duplicate']}
                    </Button>
                );
            }
        }

        const itemsTab = <Tab route={`${baseRoute}/${batchType}s-list`} title={this.context.intl.messages[`common.tab-title.${batchType}s`]}>
            <ListItems
                item={this.state.item}
                items={this.state.items}
                batch={this.state.batch}
                location={this.props.location}
                disabled={disabled}
                showItemDetail={this.state.showItemDetail}
                type={batchType.toUpperCase()}
            />
        </Tab>;
        const catalogsTab = <Tab route={`${baseRoute}/catalogs`} title={this.context.intl.messages['common.tab-title.catalogs']}>
            <Catalogs
                assignedCatalogs={this.state.batch.get('catalogs')}
                disabled={disabled}
                type={batchType.toUpperCase()}
            />
        </Tab>;
        const summaryTab = <Tab route={baseRoute} title={this.context.intl.messages['common.tab-title.summary']}>
            <Summary
                items={this.state.items}
                batch={this.state.batch}
                disabled={disabled}
                mode={mode}
                type={batchType.toUpperCase()}
            />
        </Tab>;

        let showEditIcon = !disabled;
        if (mode === 'create') {
            showEditIcon = false;
        }

        return (
            <DocumentTitle
                message={`document-titles.${mode}-batch.${batchType}`}
                entityName={entityName}
            >
                <div>
                    <StickyHeader style={{'margin-bottom': '50px'}}>
                        <div className="col-md-6">
                            <h1>
                                <i className="far fa-stopwatch"></i>&nbsp;{this.context.intl.messages[`scheduling.batch.${batchType}.title`]}
                                <br/><small>{this.state.batch.get('batchName')}</small>
                            </h1>
                        </div>
                        <ActionsMenu
                            canEdit={showEditIcon}
                            canSave={!disabled && !validations.length}
                            onCancel={this.handleCancel.bind(this, batchType)}
                            onSave={this.handleSave.bind(this, batchType)}
                        >
                            {duplicateBtn}
                        </ActionsMenu>
                    </StickyHeader>
                    <Preloader show={this.state.showPreloader} fixed loadingDots>
                        <section className="content" id="contentContainer">
                            <div className="row">
                                <div className="col-lg-9">
                                    <Tabs location={this.props.location}>
                                        {summaryTab}
                                        {catalogsTab}
                                        {itemsTab}
                                        {usersTab}
                                        {historyTab}
                                    </Tabs>
                                </div>
                                <div className="col-lg-3">
                                    <AlertsWarnings title={this.context.intl.messages['common.alerts-warnings-title']} validations={validations}/>
                                    {eventsAndNotes}
                                </div>
                            </div>
                        </section>
                        <DuplicateModal
                            batch={this.state.batchDuplicated}
                            disabled={!canDuplicate}
                            onHide={this.toggleDuplicateModal}
                            show={this.state.showDuplicateModal}
                            showPreloader={this.state.showDuplicateBatchPreloader}
                            type={batchType}
                        />
                    </Preloader>
                </div>
            </DocumentTitle>
        );
    }
}

export default Container.create(Create);
