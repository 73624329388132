/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export default class Panel extends React.Component {
    static get propTypes() {
        return {
            additionalHeader: PropTypes.node,
            additionalHeaderStyle: PropTypes.string,
            children: PropTypes.node,
            classTitle: PropTypes.string,
            collapsible: PropTypes.bool,
            defaultExpanded: PropTypes.bool,
            extraClasses: PropTypes.array,
            footer: PropTypes.node,
            hasAnimation: PropTypes.bool,
            iconClass: PropTypes.string,
            removePadding: PropTypes.bool,
            title: PropTypes.string,
            useH4: PropTypes.bool,
            withBorder: PropTypes.bool
        };
    }

    static get defaultProps() {
        return {
            additionalHeader: undefined,
            additionalHeaderStyle: undefined,
            children: undefined,
            classTitle: undefined,
            collapsible: false,
            defaultExpanded: false,
            extraClasses: [],
            footer: undefined,
            hasAnimation: false,
            iconClass: undefined,
            removePadding: false,
            title: undefined,
            useH4: false,
            withBorder: true,
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            expanded: props.defaultExpanded
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleDefaultState = this.handleDefaultState.bind(this);

        return;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.defaultExpanded !== this.props.defaultExpanded) {
            this.handleDefaultState(this.props.defaultExpanded);
        }
    }

    handleDefaultState(defaultExpanded) {
        this.setState(() => {
            return {
                expanded: defaultExpanded
            };
        });

    }

    handleClick(event) {
        if (!this.props.collapsible) {return;}
        event.preventDefault();
        this.setState(prevState => ({expanded : !prevState.expanded}));
        return;
    }

    render() {
        let collapsibleButton;
        if (this.state.expanded) {
            collapsibleButton = <i className="btn btn-box-tool fa pull-right fa-minus"></i>;
        } else {
            collapsibleButton = <i className="btn btn-box-tool fa pull-right fa-plus"></i>;
        }

        let additionalHeader;
        if (this.props.additionalHeader) {
            additionalHeader = <span>&nbsp;{this.props.additionalHeader}</span>;
        }

        let bChildren;
        let bChildrenClasses = [];
        if (this.props.hasAnimation) {
            bChildrenClasses.push('panel-box-body');
        }
        if (!this.props.collapsible || this.state.expanded || this.props.hasAnimation) {
            bChildrenClasses.push('box-body');
            if (!this.props.removePadding) {
                bChildrenClasses.push('padding-x-20');
            }
            bChildren = <div className={bChildrenClasses.join(' ')}>{this.props.children}</div>;
        }

        let headerStyle = 'box-header';
        if (this.props.withBorder) {
            headerStyle += ' with-border';
        }
        if (this.props.additionalHeaderStyle) {
            headerStyle += ' '+this.props.additionalHeaderStyle;
        }

        let footerStyle = 'box-footer';
        if (this.props.withBorder) {
            footerStyle += ' with-border';
        }
        let footer = <div className={footerStyle}>
            {this.props.footer}
        </div>;

        let className = 'h3-style';
        if (this.props.classTitle) {
            className += ' ' + this.props.classTitle;
        }

        let button;
        if (this.props.collapsible) {
            button = collapsibleButton;
        }

        let f;
        if ((!this.props.collapsible || this.state.expanded) && this.props.footer) {
            f = footer;
        }

        const content = <div>
            {button}
            <i className={this.props.iconClass}></i>&nbsp;{this.props.title}
            {additionalHeader}
        </div>;

        let header = <h3 onClick={this.handleClick} className={className}>{content}</h3>;
        if (this.props.useH4) {
            header = <h4 onClick={this.handleClick} className={className}>{content}</h4>;
        }

        return (
            <div className={ClassNames('box', ...this.props.extraClasses, {'expanded': this.state.expanded})}>
                <div className={headerStyle}>
                    {header}
                </div>
                {bChildren}
                {f}
            </div>
        );
    }
}

/**
 * Full-width collapsible panel.
 */
class SectionPanel extends React.Component {
    static get propTypes() {
        return {
            children: PropTypes.node.isRequired,
            expanded: PropTypes.bool,
            localized: PropTypes.bool,
            title: PropTypes.string.isRequired,
            validationState: PropTypes.string
        };
    }

    static get defaultProps() {
        return {
            expanded: false,
            localized: false,
            validationState: ''
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            collapsed: !this.props.expanded
        };

        this.handleClick = this.handleClick.bind(this);
        return;
    }

    componentDidUpdate(prevProps) {
        if (this.props.expanded !== prevProps.expanded) {
            this.setState({ // eslint-disable-line react/no-did-update-set-state
                collapsed: !this.props.expanded
            });
        }
    }

    handleClick() {
        this.setState(prevState => ({
            collapsed: !prevState.collapsed
        }));
        return;
    }

    render() {
        let icons = {
            error: 'fas fa-times text-red',
            success: 'fas fa-check text-blue'
        };

        let indicator;
        if (this.props.validationState) {
            indicator = <i className={icons[this.props.validationState]}></i>;
        }

        let title = this.props.title;
        if (this.props.localized) {
            title = <em style={{color:'#8fdaff'}}>{title}</em>;
        }

        return (
            <div className="panel panel-default Mt(20px)">
                <div className="panel-heading bg-navy clickable" onClick={this.handleClick}>
                    <div className="panel-title">
                        <h4>
                            <i className={ClassNames({'fas fa-plus': this.state.collapsed, 'fas fa-minus': !this.state.collapsed})}></i>&nbsp;{title}&nbsp;{indicator}
                        </h4>
                    </div>
                </div>
                <div className={ClassNames('padding-x-20 padding-y-20', {collapse: this.state.collapsed})}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export {
    SectionPanel
};
