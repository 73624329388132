/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import jQuery from 'jquery';
import PropTypes from 'prop-types';
import React from 'react';

import {MassUpdateActions} from './mass-update-actions';

require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('../../../styles/data-tables-brainiac.css');
require('datatables.net-responsive-bs');

class UnassignedTable extends React.Component {

    static get propTypes() {
        return {
            assignedCatalogs: PropTypes.instanceOf(Immutable.List).isRequired,
            catalogs: PropTypes.instanceOf(Immutable.List).isRequired,
            filters: PropTypes.string.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.handleAddCatalog = this.handleAddCatalog.bind(this);
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        this.$table = jQuery('#unassigned-catalogs-table');
        this.$tableAPI = this.$table.DataTable({
            autoWidth: false,
            columnDefs: [{
                className: 'control',
                targets: -1,
                width: 20
            }, {
                targets: 'no-sort',
                orderable: false
            }],
            order: [[1, 'dec']],
            iDisplayLength: 100,
            info: false,
            ordering: false,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            scrollY: '250px',
            scrollCollapse: true,
            searching: false
        });

        window.addEventListener('resize', this.handleResize);

        this.handleResize();
        return;
    }

    componentWillUpdate(nextProps /*, nextState*/) {
        this.$tableAPI.clear();

        let filterRegExp = new RegExp(nextProps.filters, 'i');

        let unassignedCatalogs = nextProps.catalogs.filter(c => {
            let keep = !nextProps.assignedCatalogs.find(assignedCatalog => c.get('id') === assignedCatalog.get('id'));

            if (keep && nextProps.filters) {
                keep = c.get('name').match(filterRegExp);
            }

            return keep;
        });

        unassignedCatalogs.forEach(catalog => {
            let row = [];

            let catalogDescription = catalog.get('description') || '';
            let catalogStatus;
            if (catalog.get('isActive') === 1) {
                catalogStatus = this.context.intl.messages['catalogs.mass-update.status.active'];
            } else {
                catalogStatus = this.context.intl.messages['catalogs.mass-update.status.inactive'];
            }

            row.push(
                catalog.get('name'),
                catalogDescription,
                catalogStatus,
                `<button class="btn btn-small bg-wb-blue add-catalog" data-catalog-id="${catalog.get('id')}"><i class="fas fa-plus"></i>&nbsp;${this.context.intl.messages['catalogs.mass-update.button.add']}</button>`,
                ''
            );

            this.$tableAPI.row.add(row);

            return;
        });

        this.$tableAPI.draw(false);
        this.handleResize();

        return;
    }

    componentWillUnmount() {
        if (this.$tableAPI) {
            this.$tableAPI.destroy();
        }
        return;
    }

    handleResize() {
        this.$tableAPI.responsive.recalc();
        return;
    }

    handleAddCatalog(event) {
        let catalog, id;
        switch (true) {
        case !!~event.target.className.indexOf('add-catalog'):
            id = parseInt(event.target.getAttribute('data-catalog-id'), 10);
            catalog = this.props.catalogs.find(c => c.get('id') === id);
            MassUpdateActions.addCatalog(catalog);
            break;
        case !!~event.target.className.indexOf('fa-plus'):
            id = parseInt(event.target.parentNode.getAttribute('data-catalog-id'), 10);
            catalog = this.props.catalogs.find(c => c.get('id') === id);
            MassUpdateActions.addCatalog(catalog);
            break;
        }
        return;
    }

    render() {
        return (
            <table id="unassigned-catalogs-table" className="table table-bordered table-striped responsive">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Status</th>
                        <th>Actions</th>
                        <th className="no-sort"></th>
                    </tr>
                </thead>
                <tbody onClick={this.handleAddCatalog}>
                </tbody>
            </table>
        );
    }
}

export default UnassignedTable;
