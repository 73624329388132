/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {AlertTypes} from './alert';
import Dispatcher from '../../dispatcher/dispatcher';

const CONSTANTS = {
    ALERT: {
        SHOW: 'alert.show'
    },
    CLEAR: 'notifications.clear',
    MODAL: {
        HIDE: 'notifications.modal.hide',
        SHOW: 'modal.show',
        SHOW_ID: 'notifications.modal.show_id',
        SHOW_ON_TOP: 'modal.show.on.top'
    },
    // Hides next notification
    HIDE: 'notifications.hide'
};

const noop = () => void 0;

class NotificationActions {
    clearQueue() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
        return;
    }

    hide(index) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.HIDE,
            index: index
        });
        return;
    }

    hideModal(modalId) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.MODAL.HIDE,
            modalId
        });
        return;
    }

    show(type, title, message, confirmText, onConfirm, onCancel = noop) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.MODAL.SHOW,
            type: type,
            title: title,
            message: message,
            confirmText: confirmText,
            onConfirm: onConfirm,
            onCancel: onCancel
        });
        return;
    }

    showAlert(type, message) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ALERT.SHOW,
            arguments: [].slice.call(arguments, 2),
            message: message,
            type: type
        });
        return;
    }

    showAlertDanger(message, ...args) {
        this.showAlert(AlertTypes.ALERT_DANGER.name, message, ...args);
        return;
    }

    showAlertSuccess(message, ...args) {
        this.showAlert(AlertTypes.ALERT_SUCCESS.name, message, ...args);
        return;
    }

    showAlertInfo(message, ...args) {
        this.showAlert(AlertTypes.ALERT_INFO.name, message, ...args);
        return;
    }

    showAlertWarning(message, ...args) {
        this.showAlert(AlertTypes.ALERT_WARNING.name, message, ...args);
        return;
    }

    showModal(modalId) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.MODAL.SHOW_ID,
            modalId
        });

        return;
    }

    showOnTop(type, title, message, confirmText, onConfirm, onCancel) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.MODAL.SHOW_ON_TOP,
            type: type,
            title: title,
            message: message,
            confirmText: confirmText,
            onConfirm: onConfirm,
            onCancel: onCancel
        });
        return;
    }

}

const actions = new NotificationActions();

export {
    actions as NotificationActions,
    CONSTANTS as NotificationConstants
};
