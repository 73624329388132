/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

export default function FetchCues(video: HTMLMediaElement, src: string): Promise<TextTrackCueList | null> {
    return new Promise((resolve, reject) => {
        Array.from(video.children).forEach((el) => video.removeChild(el));
        video.crossOrigin = 'true';

        const htmlTrack = document.createElement('track');
        htmlTrack.default = true;
        htmlTrack.srclang = 'xx';
        htmlTrack.kind = 'captions';
        htmlTrack.src = src;

        video.appendChild(htmlTrack);
        const textTrack = Array.from(video.textTracks).find(t => t.language === 'xx');
        if (!textTrack) {
            reject(new Error('cc-editor.error.no-vtt'));
            return;
        }
        htmlTrack.onload = () => resolve(textTrack.cues);
        htmlTrack.onerror = () => reject(new Error('cc-editor.error.load-vtt'));
    });
}
