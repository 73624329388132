/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
*/

import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link} from 'react-router';
import ReactTable from 'react-table';

import Pagination from '../../../../common/table/pagination';

import 'react-table/react-table.css';

export default class Table extends Component {
    static get propTypes() {
        return {
            activePage: PropTypes.number.isRequired,
            massAddUpdateLogs: PropTypes.instanceOf(Immutable.List).isRequired,
            onPageChange: PropTypes.func.isRequired,
            onSortChange: PropTypes.func.isRequired,
            sortDirection: PropTypes.string.isRequired,
            sortFieldName: PropTypes.string.isRequired,
            totalPages: PropTypes.number.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
            router: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            id: Math.ceil(Math.random() * 100000),
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();
        this.gettHeaderNameClass = this.gettHeaderNameClass.bind(this);
        this.handleHeaderClick = this.handleHeaderClick.bind(this);
    }

    handleHeaderClick(headerName) {
        if (headerName === 'RELATED_TITLE') { // related title doesnt have sorting
            return;
        }

        let newSortDirection = 'asc';
        if (this.props.sortFieldName === headerName && this.props.sortDirection === 'asc') {
            newSortDirection = 'desc';
        }
        if (this.props.onSortChange) {
            this.props.onSortChange(headerName, newSortDirection);
        }
        return;
    }

    gettHeaderNameClass(headerName) {
        let headerClassName = 'sorting';

        if (headerName === this.props.sortFieldName) {
            headerClassName = `sorting-${this.props.sortDirection}`;
        }

        return headerClassName;
    }

    render() {
        const getHeader = this.gettHeaderNameClass;

        let columns = [{
            accessor: 'BATCH_ID',
            Header: props => (
                <strong className={getHeader(props.column.id)}>{this.context.intl.messages['titles.mass-add.top-table-header.batch-id']}</strong>
            ),
            className: 'h-alignment',
            width: 95,
            Cell: c => (
                <div>
                    <Link to={`/titles/mass-add/${c.original.get('titleIngestHeaderId')}`}>{c.original.get('titleIngestHeaderId')}</Link>
                </div>
            )
        }, {
            accessor: 'STATUS',
            Header: props => (
                <strong className={getHeader(props.column.id)}>{this.context.intl.messages['titles.mass-add.top-table-header.status']}</strong>
            ),
            width: 120,
            Cell: c => (
                <div>
                    {c.original.get('status') || '-'}
                </div>
            )
        }, {
            accessor: 'UPDATED_DATE',
            Header: props => (
                <strong className={getHeader(props.column.id)}>{this.context.intl.messages['titles.mass-add.top-table-header.updated-date']}</strong>
            ),
            width: 135,
            Cell: c => {
                let updatedDate = Moment(c.original.get('updatedDate'));
                if (updatedDate.isValid()) {
                    updatedDate = updatedDate.format(this.context.intl.messages['date-format']);
                } else {
                    updatedDate = '-';
                }
                return (
                    <div>
                        {updatedDate}
                    </div>
                );
            }
        }, {
            accessor: 'UPDATED_BY',
            Header: props => (
                <strong className={getHeader(props.column.id)}>{this.context.intl.messages['titles.mass-add.top-table-header.updated-by']}</strong>
            ),
            width: 250,
            Cell: c => (
                <div>
                    {c.original.get('updatedBy') || '-'}
                </div>
            )
        }, {
            accessor: 'CREATED_DATE',
            Header: props => (
                <strong className={getHeader(props.column.id)}>{this.context.intl.messages['titles.mass-add.top-table-header.created-date']}</strong>
            ),
            width: 130,
            Cell: c => {
                let createdDate = Moment(c.original.get('createdDate'));
                if (createdDate.isValid()) {
                    createdDate = createdDate.format(this.context.intl.messages['date-format']);
                } else {
                    createdDate = '-';
                }
                return (
                    <div>
                        {createdDate}
                    </div>
                );
            }
        }, {
            accessor: 'CREATED_BY',
            Header: props => (
                <strong className={getHeader(props.column.id)}>{this.context.intl.messages['titles.mass-add.top-table-header.created-by']}</strong>
            ),
            width: 250,
            Cell: c => (
                <div>
                    {c.original.get('createdBy') || '-'}
                </div>
            )
        }, {
            accessor: 'PROCESSED_COUNT',
            Header: props => (
                <strong className={getHeader(props.column.id)}><i className="fas fa-check text-green"></i></strong>
            ),
            width: 50,
            Cell: c => (
                <div>
                    {c.original.get('numberProcessed')}
                </div>
            )
        }, {
            accessor: 'ERROR_COUNT',
            Header: props => (
                <strong className={getHeader(props.column.id)}><i className="fas fa-exclamation-triangle text-red"></i></strong>
            ),
            width: 50,
            Cell: c => (
                <div>
                    {c.original.get('numberError')}
                </div>
            )
        }, {
            accessor: 'REJECTED_COUNT',
            Header: props => (
                <strong className={getHeader(props.column.id)}><i className="fas fa-ban"></i></strong>
            ),
            width: 50,
            Cell: c => (
                <div>
                    {c.original.get('numberRejected')}
                </div>
            )
        }, {
            accessor: 'RELATED_TITLE',
            Header: <strong className="table-padding padding-left">{this.context.intl.messages['titles.mass-add.top-table-header.related-title']}</strong>,
            width: 500,
            Cell: c => (
                <div>
                    {c.original.get('relatedTitle') || '-'}
                </div>
            )
        }];

        let pagination = <div className="row">
            <div className="col-sm-7 col-sm-offset-5">
                <Pagination
                    activePage={this.props.activePage}
                    onChange={this.props.onPageChange}
                    totalPages={this.props.totalPages}
                />
            </div>
        </div>;

        const handleSortColumn = this.handleHeaderClick;

        return (
            <section className="content">
                <ReactTable
                    className="-striped mass-add-update-logs responsive"
                    columns={columns}
                    data={this.props.massAddUpdateLogs}
                    getNoDataProps= {() => {
                        if (this.props.massAddUpdateLogs.size) {
                            return {style: {display: 'none'}};
                        }
                        return {};
                    }}
                    id={`mass-add-update-logs-table-${this.state.id}`}
                    loading={false}
                    showPagination={false}
                    sortable={false}
                    resizable={false}
                    getTheadThProps={(state, rowInfo, column) => ({
                        onClick: () => {
                            handleSortColumn(column.id);
                        }
                    })}
                />
                {pagination}
            </section>
        );
    }
}
