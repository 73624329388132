/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {ControlLabel, FormControl, FormGroup} from 'react-bootstrap';

import RouteValidator from '../../../common/custom-validator/route/route';
import {FormSection, FormRow, FormItem} from '../../../common/form/form';
import config from '../../../config/config.js';
import {TalentActions} from '../../talent-actions';
import TalentStore, {TalentStoreValidations} from '../../talent-store';

class TalentInfo extends React.Component {
    static get propTypes() {
        return {
            disabled: PropTypes.bool.isRequired,
            route: RouteValidator // eslint-disable-line react/require-default-props
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static getStores() {
        return [TalentStore];
    }

    static calculateState() {
        return {
            talent: TalentStore.getState().get('talent')
        };
    }

    render() {
        let mode = this.props.route.mode;
        let lastUpdatedBlock = null;
        if (mode === 'edit') {
            let updatedDate;

            if (this.state.talent.get('updatedDate')) {
                updatedDate = Moment(this.state.talent.get('updatedDate')).tz(config.DefaultTimezone).format(this.context.intl.messages['datetime-format']);
            } else {
                updatedDate = '';
            }

            let lastModifier;

            if (this.state.talent.get('updatedBy')) {
                lastModifier = this.state.talent.get('updatedBy');
            } else {
                lastModifier = '-';
            }

            lastUpdatedBlock = <FormRow>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['talent.edit.last-modified-date']}</ControlLabel>
                    <FormControl.Static>{updatedDate}</FormControl.Static>
                </FormGroup>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['talent.edit.last-modifier']}</ControlLabel>
                    <FormControl.Static>{lastModifier}</FormControl.Static>
                </FormGroup>
            </FormRow>;
        }
        return (
            <FormSection iconClass="fas fa-info-circle" title={this.context.intl.messages['talent.create.talent-info.title']}>
                <FormRow>
                    <FormItem attr="prefix"
                        disabled={this.props.disabled}
                        label={this.context.intl.messages['talent.create.talent-info.prefix']}
                        md={4}
                        model={this.state.talent}
                        onlySpaces
                        setter={TalentActions.updateTalent}
                        type="text"
                        validations={TalentStoreValidations.prefix.validations} />
                </FormRow>
                <FormRow>
                    <FormItem attr="firstName"
                        disabled={this.props.disabled}
                        label={this.context.intl.messages['talent.create.talent-info.first-name']}
                        model={this.state.talent}
                        onlySpaces
                        setter={TalentActions.updateTalent}
                        type="name"
                        validations={TalentStoreValidations.firstName.validations} />
                    <FormItem attr="middleName"
                        disabled={this.props.disabled}
                        label={this.context.intl.messages['talent.create.talent-info.middle-name']}
                        model={this.state.talent}
                        onlySpaces
                        setter={TalentActions.updateTalent}
                        type="name"
                        validations={TalentStoreValidations.middleName.validations} />
                    <FormItem attr="lastName"
                        disabled={this.props.disabled}
                        label={this.context.intl.messages['talent.create.talent-info.last-name']}
                        model={this.state.talent}
                        onlySpaces
                        setter={TalentActions.updateTalent}
                        type="name"
                        validations={TalentStoreValidations.lastName.validations} />
                </FormRow>
                <FormRow>
                    <FormItem attr="suffix"
                        disabled={this.props.disabled}
                        label={this.context.intl.messages['talent.create.talent-info.suffix']}
                        md={4}
                        model={this.state.talent}
                        onlySpaces
                        setter={TalentActions.updateTalent}
                        type="text"
                        validations={TalentStoreValidations.suffix.validations} />
                </FormRow>
                {lastUpdatedBlock && (<hr/>)}
                {lastUpdatedBlock}
            </FormSection>
        );
    }
}

export default Container.create(TalentInfo);
