/* istanbul ignore file */
/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {STATION_SUBSCRIPTION_CT_TYPES} from '@wbdt-sie/brainiac-web-common';
import Promise from 'bluebird';
import Immutable from 'immutable';
import Moment from 'moment';

import {RelatedActions} from './create/related/related-actions';
import {SerieNavigationActions} from './serie-navigation/serie-navigation-actions';
import {TitleLocalizedActions} from './title-localized-actions';
import {TitleStatusActions} from './title-status-actions';
import {AssetTabActions} from '../assets-tab/asset-tab-actions';
import {AlertTypes} from '../common/notification/alert';
import {NotificationActions} from '../common/notification/notification-actions';
import {SlidingPanelActions} from '../common/sliding-panel/sliding-panel-actions';
import {GetEncodedHTML, UploadFile} from '../common/utils/utils';
import config from '../config/config.js';
import {SYNOPSIS_METADATA_FIELDS, TITLE_FIELDS_STATUS} from '../dashboard/fields';
import Dispatcher from '../dispatcher/dispatcher';
import {PreloaderActions} from '../preloader/preloader-actions';
import Request from '../request';
import {RouterActions} from '../router/router-actions';
import {ActionHistoryConstants} from '../system/action-history/action-history-actions';

// Require for Proper Timezone Display
require('moment-timezone');
let configtz = Moment().tz(config.DefaultTimezone).format('ZZ');

const TITLE_CATEGORY_GROUPS = {
    SERIES: 1,
    SEASON: 2,
    EPISODE: 3,
    SINGLE_RELEASE: 4,
    FORMAT_RIGHTS: 5,
    MINI_SERIES: 6
};

let TITLE_CATEGORY_GROUPS_DESC = {};
TITLE_CATEGORY_GROUPS_DESC[TITLE_CATEGORY_GROUPS.EPISODE] = 'Episode';
TITLE_CATEGORY_GROUPS_DESC[TITLE_CATEGORY_GROUPS.SEASON] = 'Season';
TITLE_CATEGORY_GROUPS_DESC[TITLE_CATEGORY_GROUPS.SERIES] = 'Series';
TITLE_CATEGORY_GROUPS_DESC[TITLE_CATEGORY_GROUPS.SINGLE_RELEASE] = 'Theatrical Feature';
TITLE_CATEGORY_GROUPS_DESC[TITLE_CATEGORY_GROUPS.FORMAT_RIGHTS] = 'Format';
TITLE_CATEGORY_GROUPS_DESC[TITLE_CATEGORY_GROUPS.MINI_SERIES] = 'Mini Series';

const CONSTANTS = {
    FILTERS: {
        ACTIVE_OPTIONS: {
            ACTIVE: {id: 'ACTIVE', name: 'Active'},
            BOTH: {id: 'BOTH', name: 'Both'},
            INACTIVE: {id: 'INACTIVE', name: 'Inactive'}
        }
    },
    ACTION_TYPES: {
        LIVE_ACTION: {id: 1, name: 'Live Action'},
        ANIMATION: {id: 2, name: 'Animation'},
        LIVE_ACTION_AND_ANIMATION: {id: 3, name: 'Live Action & Animation'},
    },
    TITLE_CATALOG_TYPES: {
        Regular: {id: 0, name: 'Regular'},
        Library: {id: 1, name: 'Library'},
        SlateYear: {id: 2, name: 'Slate Year'},
        CurrentSlateYear: {id: 3, name: 'Current Slate Year'},
        Screeners: {id: 4, name: 'Screeners'},
        Syndication: {id: 5, name: 'Syndication'}
    },
    TITLE_CATEGORY_GROUPS: TITLE_CATEGORY_GROUPS,
    TITLE_CATEGORY_GROUPS_DESC: TITLE_CATEGORY_GROUPS_DESC,
    CLEAR: 'title_actions.clear',
    FILM_FORMAT_TYPES: {
        TWO_DIMENSIONAL: {id: 0, name: '2D'},
        THREE_DIMENSIONAL: {id: 1, name: '3D'},
        IMAX: {id: 2, name: 'IMAX'},
        TWO_DIMENSIONAL_OR_IMAX: {id: 3, name: '2D/IMAX'},
        THREE_DIMENSIONAL_IMAX: {id: 4, name: '3D IMAX'},
        TWO_DIMENSIONAL_OR_THREE_DIMENSIONAL: {id: 5, name: '2D/3D'},
        TWO_DIMENSIONAL_OR_THREE_DIMENSIONAL_IMAX: {id: 6, name: '2D/3D IMAX'},
        THREE_DIMENSIONAL_OR_IMAX: {id: 7, name: '3D/IMAX'}
    },
    FILTER: {
        CLEAR: 'title_actions.filter.clear',
        SET: 'title_actions.filter.set'
    },
    SORT: {
        SET: 'title_actions.sort.set'
    },
    MADE_FOR_TYPES: {
        TV: {id: 1, name: 'TV'},
        FILM: {id: 2, name: 'Film'},
        HOME_VIDEO: {id: 3, name: 'Home Video'},
        Web: {id: 4, name: 'Web'}
    },
    MOVIE_COLOR_TYPES: {
        COLOR_UNKNOWN: {id: 0, name: 'Color Unknown'},
        COLOR: {id: 1, name: 'Color'},
        B_W: {id: 2, name: 'B&W'},
        COLORIZED: {id: 3, name: 'Colorized'},
        BW_COLOR: {id: 4, name: 'B&W Color'},
        COLOR_COLORIed: {id: 5, name: 'Color Colorized'}
    },
    MPAA_RATING_TYPES: {
        NO_RATING: {id: 'No Rating', name: 'No Rating'},
        M: {id: 'M', name: 'M'},
        R: {id: 'R', name: 'R'},
        PG: {id: 'PG', name: 'PG'},
        PG_13: {id: 'PG-13', name: 'PG-13'},
        NC_17: {id: 'NC-17', name: 'NC-17'},
        G: {id: 'G', name: 'G'},
        X: {id: 'X', name: 'X'}
    },
    PARENTAL_RATING_TYPES: {
        NO_PARENTAL_RATING: {id: 0, name: 'No Parental Rating'},
        TV_Y: {id: 1, name: 'TV-Y'},
        TV_Y7: {id: 2, name: 'TV-Y7'},
        TV_G: {id: 3, name: 'TV-G'},
        TV_PG: {id: 4, name: 'TV-PG'},
        TV_14: {id: 5, name: 'TV-14'},
        TV_MA: {id: 6, name: 'TV-MA'}
    },
    LINK_TYPES: {
        CLIP_LIBRARY: {id: 6, name: 'Clip Library'},
        INTERNAL_LINK: {id: 11, name: 'Internal Link'},
        EXTERNAL_LINK: {id: 12, name: 'External Link'}
    },
    RATING_REASON_TYPES: {
        AC: {id: 'AC', name: 'AC'},
        AL: {id: 'AL', name: 'AL'},
        V: {id: 'V', name: 'V'},
        MV: {id: 'MV', name: 'MV'},
        BN: {id: 'BN', name: 'BN'},
        N: {id: 'N', name: 'N'},
        SC: {id: 'SC', name: 'SC'},
        NA: {id: 'NA', name: 'NA'},
        GL: {id: 'GL', name: 'GL'},
        GV: {id: 'GV', name: 'GV'},
        RP: {id: 'RP', name: 'RP'},
        D: {id: 'D', name: 'D'},
        S: {id: 'S', name: 'S'},
        L: {id: 'L', name: 'L'},
        FV: {id: 'FV', name: 'FV'}
    },
    RELEASE: {
        AIR_DAY_TYPES: {
            SUNDAY: {id: 0, name: 'Sunday'},
            MONDAY: {id: 1, name: 'Monday'},
            TUESDAY: {id: 2, name: 'Tuesday'},
            WEDNESDAY: {id: 3, name: 'Wednesday'},
            THURSDAY: {id: 4, name: 'Thursday'},
            FRIDAY: {id: 5, name: 'Friday'},
            SATURDAY: {id: 6, name: 'Saturday'},
            TBA: {id: 7, name: 'TBA'},
            NA: {id: 8, name: 'N/A'}
        },
        /**
         * AIR_TIME_TYPES is a list of 48 times separated by half an hour. Times are displayed in
         * 12hr format for ease of use, but are persisted as 24hr
         */
        AIR_TIME_TYPES: (function() {
            let values = [];
            for (let i = 0; i < 24; i++) {
                let hour = i;
                if (i === 0) {
                    hour = 12;
                } else if (i > 12) {
                    hour -= 12;
                }
                let amPm = 'AM';
                if (i >= 12) {
                    amPm = 'PM';
                }

                values.push({
                    airHour: i,
                    airMinute: 0,
                    id: `${i}:00`,
                    name: `${hour}:00 ${amPm}`
                }, {
                    airHour: i,
                    airMinute: 30,
                    id: `${i}:30`,
                    name: `${hour}:30 ${amPm}`
                });
            }
            return values;
        })(),
        CONTENT_TYPES: {
            VOD: {id: 'VOD', name: 'Video on Demand (Rental policy for temporary viewing)'},
            SVOD: {id: 'SVOD', name: 'Subscription Video on Demand'},
            AVOD: {id: 'AVOD', name: 'Advertising Video on Demand'},
            TVOD: {id: 'TVOD', name: 'Transactional Video on Demand'},
            PVOD: {id: 'PVOD', name: 'Premium Video on Demand (Broadcast Theatrical)'},
            EST: {id: 'EST', name: 'Electronic Sell-Through (Digital purchase for unlimited viewing)'},
            DVD_OR_BLU_RAY: {id: 'DVD_OR_BLU_RAY', name: 'DVD/Blu-ray'},
            THEATRICAL: {id: 'THEATRICAL', name: 'Theatrical'},
            BROADCAST: {id: 'BROADCAST', name: 'Broadcast'},
        },
        DATE_STATUS_TYPES: {
            FIRM: {id: 0, name: 'Firm'},
            TENTATIVE: {id: 1, name: 'Tentative'},
            TBA: {id: 2, name: 'TBA'}
        },
        DATE_TYPES: {
            FULL: {id: 0, name: 'Full'},
            MONTH_YEAR: {id: 1, name: 'Month Year'},
            SEASON_YEAR: {id: 2, name: 'Season Year'},
            YEAR: {id: 3, name: 'Year'},
            TBA: {id: 4, name: 'TBA'},
            DISPLAY_NAME: {id: 5, name: 'Display Name'},
        },
        RELEASE_TYPES: {
            RELEASE: {id: 0, name: 'Release'},
            RE_RELEASE: {id: 1, name: 'Re-Release'},
            VIDEO_RELEASE: {id: 2, name: 'Video-Release'}
        }
    },
    SAVED_ADD_TITLE_PANEL_FILTERS: '__brainiacAddTitlePanelFilters-',
    SUBSCRIPTION_CONTENT_TYPES: STATION_SUBSCRIPTION_CT_TYPES,
    TITLE: {
        CASCADE_UPDATE: 'title_actions.title.cascade.update',
        CATALOGS: {
            ADD: 'title_actions.title.catalog.add',
            REMOVE: 'title_actions.title.catalog.remove',
            GET: {
                SUCCESS: 'title_actions.title.catalog.get.success'
            }
        },
        CLEAR_UNUSED_FIELDS: 'title_actions.title.clear_unused_fields',
        CLONE: 'title_actions.title.clone',
        CLONE_PARENT: 'title_actions.title.clone.parent',
        DELETED: {
            GET: {
                SUCCESS: 'title_actions.title.deleted.get.success',
            },
            SET: 'title_actions.title.deleted.set'
        },
        GET: {
            SUCCESS: 'title_actions.title.get.success',
            START:'title_actions.title.get.start',
            ERROR:'title_actions.title.get.ERROR'
        },
        GET_UPDATE_DATA: {
            START:'title_actions.title.get_update_data.start',
            SUCCESS: 'title_actions.title.get_update_data.success',
            ERROR:'title_actions.title.get_update_data.error'
        },
        LINKS: {
            ADD: 'title_actions.title.links.add'
        },
        MARK_FOR_DELETION: {
            START:'title_actions.title.mark_for_deletion.start',
            SUCCESS: 'title_actions.title.mark_for_deletion.success',
            ERROR:'title_actions.title.mark_for_deletion.error'
        },
        MOVE_ELEMENT: 'title_actions.title.move_element',
        RELATED: {
            ADD: 'title_actions.title.related.add',
            ADD_NEW: 'title_actions.title.related.add.new'
        },
        RELEASES: {
            ADD: 'title_actions.title.releases.add'
        },
        REMOVE_ELEMENT: 'title_actions.title.remove_element',
        REMOVE_BY_ID: 'title_actions.title.remove_by_id',
        SUBSCRIPTION: {
            ADD: 'title_actions.title.subscription.add',
            GET_USERS: {
                SUCCESS: 'title_actions.title.subscription.get_users.success',
            },
            REMOVE: 'title_actions.title.subscription.remove'
        },
        SYNOPSIS: {
            ADD: 'title_actions.titles.synopsis.add'
        },
        TALENT: {
            ADD_OR_REPLACE: 'title_actions.title.talent.add_or_replace',
            EDIT: 'title_actions.title.talent.edit',
            UPDATE_AKA: 'title_actions.title.talent.update_aka'
        },
        UPDATE: 'title_actions.title.update',
        VALIDATED: {
            UPDATE: 'title_actions.titles.validated.update'
        }
    },
    TITLE_RELATIONSHIP_TYPE: {
        ALTERNATIVE_VERSION: {id: 1, name: 'Alternative Version', children: 'is a alternative version of', parents: 'has the alternative version', enum_value: 'ALTERNATIVE_VERSION'},
        DIRECTORS_CUT: {id: 2, name: 'Director\'s Cut', children: 'is a director\'s cut of', parents: "has the director's cut", enum_value: 'DIRECTORS_CUT'},
        FEATURE: {id: 3, name: 'Feature', children: 'is a feature', parents: 'has a feature', enum_value: 'FEATURE'},
        FORMAT: {id: 4, name: 'Format', children: 'is a format of', parents: 'has the format', enum_value: 'FORMAT'},
        MINI_SERIES: {id: 5, name: 'Mini Series', children: 'is a mini series', parents: 'part of a mini series', enum_value: 'MINI_SERIES'},
        MOW: {id: 6, name: 'MOW', children: 'is a movie of the week', parents: 'has a movie of the week', enum_value: 'MOW'},
        PILOT: {id: 7, name: 'Pilot', children: 'is a pilot', parents: 'has a pilot', enum_value: 'PILOT'},
        PILOT_MOW: {id: 8, name: 'Pilot MOW', children: 'is a pilot movie of the week', parents: 'has a pilot movie of the week', enum_value: 'PILOT_MOW'},
        PREQUEL: {id: 9, name: 'Prequel', children: 'is a prequel to', parents: 'has the prequel', enum_value: 'PREQUEL'},
        REISSUE: {id: 10, name: 'Reissue', children: 'is a reissue', parents: 'has a reissue', enum_value: 'REISSUE'},
        SEQUEL: {id: 11, name: 'Sequel', children: 'is the sequel to ', parents: 'has the sequel', enum_value: 'SEQUEL'},
        OTHER: {id: 100, name: 'Other', children: '', parents: 'has ', enum_value: 'OTHER'},
        EPISODE: {id: 120, name: 'Episode', children: 'is an episode of', parents: 'has the episode', enum_value: 'EPISODE'},
        SEASON: {id: 121, name: 'Season', children: 'is a season of', parents: 'has the season', enum_value: 'SEASON'},
        REMAKE: {id: 122, name: 'Remake', children: 'is a remake of', parents: 'has the remake', enum_value: 'REMAKE'},
        LOCAL_PRODUCTION: {id: 123, name: 'Local Production', children: 'is a local production of', parents: 'is locally produced as', enum_value: 'LOCAL_PRODUCTION'},
        SEGMENT: {id: 124, name: 'Segment', children: 'is a segment of', parents: 'has the segment', enum_value: 'SEGMENT'},
        REPACKAGED_FOR_SALES: {id: 225, name: 'Repackaged For Sales', children: 'is repackaged for sales as', parents: 'originally premiered as', enum_value: 'REPACKAGED_FOR_SALES'},

        HAS_ALTERNATIVE_VERSION: {id: 201, name: 'Alternative Version', parents: 'is a alternative version of', children: 'has the alternative version', enum_value: 'HAS_ALTERNATIVE_VERSION'},
        HAS_DIRECTORS_CUT: {id: 202, name: 'Director\'s Cut', parents: 'is a director\'s cut of', children: 'has the director\'s cut', enum_value: 'HAS_DIRECTORS_CUT'},
        HAS_FEATURE: {id: 203, name: 'Feature', parents: 'is a feature', children: 'has a feature', enum_value: 'HAS_FEATURE'},
        HAS_FORMAT: {id: 204, name: 'Format', parents: 'is a format of', children: 'has the format', enum_value: 'HAS_FORMAT'},
        HAS_MINI_SERIES: {id: 205, name: 'Mini Series', parents: 'is a mini series', children: 'part of a mini series', enum_value: 'HAS_MINI_SERIES'},
        HAS_MOW: {id: 206, name: 'MOW', parents: 'is a movie of the week', children: 'has a movie of the week', enum_value: 'HAS_MOW'},
        HAS_PILOT: {id: 207, name: 'Pilot', parents: 'is a pilot', children: 'has a pilot', enum_value: 'HAS_PILOT'},
        HAS_PILOT_MOW: {id: 208, name: 'Pilot MOW', parents: 'is a pilot movie of the week', children: 'has a pilot movie of the week', enum_value: 'HAS_PILOT_MOW'},
        HAS_PREQUEL: {id: 209, name: 'Prequel', parents: 'is a prequel to', children: 'has the prequel', enum_value: 'HAS_PREQUEL'},
        HAS_REISSUE: {id: 210, name: 'Reissue', parents: 'is a reisse', children: 'has a reissue', enum_value: 'HAS_REISSUE'},
        HAS_SEQUEL: {id: 211, name: 'Sequel', parents: 'is the sequel to', children: 'has the sequel', enum_value: 'HAS_SEQUEL'},
        HAS_OTHER: {id: 200, name: 'Other', parents: '', children: 'has ', enum_value: 'HAS_OTHER'},
        HAS_EPISODE: {id: 220, name: 'Episode', parents: 'is an episode of', children: 'has the episode', enum_value: 'HAS_EPISODE'},
        HAS_SEASON: {id: 221, name: 'Season', parents: 'is a season of', children: 'has the season', enum_value: 'HAS_SEASON'},
        HAS_REMAKE: {id: 222, name: 'Remake', parents: 'is a remake of', children: 'has the remake', enum_value: 'HAS_REMAKE'},
        HAS_LOCAL_PRODUCTION: {id: 223, name: 'Local Production', parents: 'is a local production of', children: 'is locally produced as', enum_value: 'HAS_LOCAL_PRODUCTION'},
        HAS_SEGMENT: {id: 224, name: 'Segment', parents: 'is a segment of', children: 'has the segment', enum_value: 'HAS_SEGMENT'},
        HAS_REPACKAGED_FOR_SALES: {id: 125, name: 'Repackaged For Sales', parents: 'is repackaged for sales as', children: 'originally premiered as', enum_value: 'HAS_REPACKAGED_FOR_SALES'}
    },
    TITLE_TYPES: { // TODO: We should share CONSTANTS between WBTVD and CMS
        THEATRICAL_FEATURES: {id: 1, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE, name: 'Theatrical Features'},
        ANIMATED_FEATURES: {id: 2, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE, name: 'Animated Features'},
        MADE_FOR_VIDEO_FEATURES: {id: 3, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE, name: 'Made-For-Video Features'},
        SEASON_HALF_HOUR: {id: 4, categoryGroup: TITLE_CATEGORY_GROUPS.SEASON, name: 'Half Hour Series Season'},
        SEASON_ONE_HOUR: {id: 5, categoryGroup: TITLE_CATEGORY_GROUPS.SEASON, name: 'One Hour Series Season'},
        TALK_SHOW_SEASON: {id: 6, categoryGroup: TITLE_CATEGORY_GROUPS.SEASON, name: 'Talk Show Season'},
        GAME_SHOW_SEASON: {id: 7, categoryGroup: TITLE_CATEGORY_GROUPS.SEASON, name: 'Game Show Season'},
        ANIMATED_SERIES_SEASON: {id: 8, categoryGroup: TITLE_CATEGORY_GROUPS.SEASON, name: 'Animated Series Season'},
        CARTOONS: {id: 9, categoryGroup: TITLE_CATEGORY_GROUPS.SERIES, name: 'Individual Cartoons'},
        MINI_SERIES: {id: 10, categoryGroup: TITLE_CATEGORY_GROUPS.MINI_SERIES, name: 'Mini Series'},
        NETWORK: {id: 11, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE, name: 'Made-For-TV Movies - Network'},
        CABLE: {id: 12, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE, name: 'Made-For-TV Movies - Cable'},
        PAY_TV: {id: 13, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE, name: 'Made-For-TV Movies - Pay TV'},
        SPECIALS: {id: 14, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE, name: 'Specials'},
        ANIMATED_SPECIALS: {id: 15, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE, name: 'Animated Specials'},
        COMEDY_SPECIALS: {id: 16, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE, name: 'Comedy Specials'},
        MUSIC_SPECIALS: {id: 17, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE, name: 'Music Specials'},
        SPORTS: {id: 18, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE, name: 'Sports Programming'},
        DOCUMENTARIES: {id: 19, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE, name: 'Documentaries'},
        SHORT_PROGRAMS: {id: 20, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE, name: 'Short Programs'},
        MAKING_OF: {id: 21, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE, name: 'Making Of'},
        EPISODE: {id: 23, categoryGroup: TITLE_CATEGORY_GROUPS.EPISODE, name: 'Episode'},
        SERIES_HALF_HOUR: {id: 24, categoryGroup: TITLE_CATEGORY_GROUPS.SERIES, name: 'Series - Half Hour'},
        SERIES_ONE_HOUR: {id: 25, categoryGroup: TITLE_CATEGORY_GROUPS.SERIES, name: 'Series - One Hour'},
        TALK_SHOW: {id: 26, categoryGroup: TITLE_CATEGORY_GROUPS.SERIES, name: 'Talk Shows'},
        GAME_SHOW: {id: 27, categoryGroup: TITLE_CATEGORY_GROUPS.SERIES, name: 'Game Shows'},
        ANIMATED_SERIES: {id: 28, categoryGroup: TITLE_CATEGORY_GROUPS.SERIES, name: 'Animated Series'},
        FORMAT_RIGHTS: {id: 29, categoryGroup: TITLE_CATEGORY_GROUPS.FORMAT_RIGHTS, name: 'Format Rights'},
        REALITY: {id: 30, categoryGroup: TITLE_CATEGORY_GROUPS.SERIES, name: 'Reality'},
        REALITY_SEASON: {id: 31, categoryGroup: TITLE_CATEGORY_GROUPS.SEASON, name: 'Reality Season'},
        DIRETC_TO_VIDEO: {id: 32, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE, name: 'Direct To Video'},
        ANIMATION: {id: 33, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE, name: 'Made-For-Video Animation'},
        CARTOONS_SEASON: {id: 34, categoryGroup: TITLE_CATEGORY_GROUPS.SEASON, name: 'Individual Cartoons - Season'},
        SHORT_PROGRAMS_SEASON: {id: 35, categoryGroup: TITLE_CATEGORY_GROUPS.SEASON, name: 'Short Programs - Season'},
        EVENT: {id: 36, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE, name: 'Event'},
        OTHER_PRODUCTS: {id: 37, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE, name: 'Other Products'},
        SEGMENT: {id: 38, categoryGroup: TITLE_CATEGORY_GROUPS.EPISODE, name: 'Segment'},
        PROGRAMMING_PACKAGE: {id: 39, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE, name: 'Programming Package'},
        HBO_FILMS: {id: 40, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE, name: 'HBO Films'},
        DOCUMENTARY_FILMS: {id: 41, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE, name: 'Documentary Films'},
        LIMITED_SERIES: {id: 42, categoryGroup: TITLE_CATEGORY_GROUPS.SERIES, name: 'Limited Series'},
        DOCUSERIES: {id: 43, categoryGroup: TITLE_CATEGORY_GROUPS.SERIES, name: 'Docuseries'},
        LIMITED_SERIES_SEASON: {id: 44, categoryGroup: TITLE_CATEGORY_GROUPS.SEASON, name: 'Limited Series Season'},
        DOCUSERIES_SEASON: {id: 45, categoryGroup: TITLE_CATEGORY_GROUPS.SEASON, name: 'Docuseries Season'},
        WB_HBO_MAX_RELEASE: {id: 46, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE, name: 'Warner Bros: HBO Max Release'},
    },
    TITLES: {
        APPLY_SAVED_FILTERS: 'title_actions.titles.apply_savedfilters',
        SET: {
            FILTERS: 'title_actions.titles.set.filters'
        },
        GET: {
            SUCCESS: 'title_actions.titles.get.success',
            ERROR: 'title_actions.titles.error',
        }
    },
    TITLE_STATUS: {
        UPDATE: 'title-actions.title-status.update'
    },
    TITLE_STYLE: {
        UPDATE: 'title_actions.title_style.update'
    },
    SYNOPSIS_TYPES: {
        DEFAULT: {id: 'DEFAULT', name: 'Default Synopsis'},
        TEMPORARY: {id: 'TEMPORARY', name: 'Temporary Synopsis'},
        AIRLINE: {id: 'AIRLINE', name: 'Airline Synopsis'},
        SCREENER: {id: 'SCREENER', name: 'Screener Synopsis'},
        AWARDS: {id: 'AWARDS', name: 'Awards Synopsis'},
    },

    toArray: function(constant, options) {
        let defaults = {
            sortBy: 'name'
        };
        Object.assign(defaults, options);

        return Immutable.fromJS(
            Object.keys(constant)
                .map(k => constant[k])
        ).sortBy(v => v.get(defaults.sortBy));
    },
    toJSArray: function(constant) {
        return Object.keys(this[constant])
            .map(k => this[constant][k])
            .sort((a, b) => a.name.localeCompare(b.name));
    }
};

let titleCategoryMap = Object.keys(CONSTANTS.TITLE_TYPES).reduce( (c, titleType) => {
    c[CONSTANTS.TITLE_TYPES[titleType].id] = CONSTANTS.TITLE_TYPES[titleType];
    return c;
}, {});

const TITLE_RELATIONS = [{
    attr: 'catalogs',
    url: {
        base: 'title',
        child: 'catalog'
    }
}, {
    attr: 'languageCatalogs',
    url: {
        base: 'title',
        child: 'catalog/translation'
    }
}, {
    attr: 'genres',
    url: {
        base: 'title',
        child: 'genre'
    }
}, {
    attr: 'languageAvailability',
    emptyResponse: [],
    optional: true,
    url: {
        base: 'title',
        child: 'language-availability'
    }
}, {
    attr: 'languages',
    url: {
        base: 'title',
        child: 'language'
    }
}, {
    attr: 'links',
    url: {
        base: 'title',
        child: 'link'
    }
}, {
    attr: 'productionCompanies',
    url: {
        base: 'title',
        child: 'production-companies'
    }
}, {
    attr: 'ratingReasons',
    url: {
        base: 'title',
        child: 'rating-reason'
    }
}, {
    attr: 'releases',
    url: {
        base: 'title',
        child: 'release'
    }
}, {
    attr: 'rightsAcquiredFrom',
    url: {
        base: 'title',
        child: 'rights-acquired-from'
    }
}, {
    attr: 'subscriptions',
    url: {
        base: 'title',
        child: 'user-subscription'
    }
}, {
    attr: 'talent',
    url: {
        base: 'title',
        child: 'talent'
    }
}, {
    attr: 'themes',
    url: {
        base: 'title',
        child: 'theme'
    }
}, {
    attr: 'fieldStatusValues',
    url: {
        base: 'title',
        child: 'status'
    }
}, {
    attr: 'synopsisValues',
    url: {
        base: 'title',
        child: 'synopsis'
    }
}];

class TitleActions {
    // TODO: move getCategory, addCategory and seasonFromFullRunningOrder
    // to a helper object or export them separated as they are not actions :)
    getCategoryGroup(category) {
        let categoryGroup = undefined;
        let cat = titleCategoryMap[category];
        if (cat) {
            categoryGroup = cat.categoryGroup;
        }
        return categoryGroup;
    }

    addCategory(title) {
        let category = titleCategoryMap[title.category];
        if (category) {
            title.categoryGroup = category.categoryGroup;
        }
        return title;
    }

    seasonFromFullRunningOrder(fullRunningOrder) {
        let season = 'X';
        if (fullRunningOrder && fullRunningOrder.length > 5) {
            season = parseInt(fullRunningOrder.slice(1, 3));
        }
        return season;
    }

    addCatalog(catalog, catalogTypeName = 'catalogs', includeSerie = false, includeSeason = false, includeEpisode = false) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TITLE.CATALOGS.ADD,
            catalog,
            includeSerie,
            includeSeason,
            includeEpisode,
            name: catalogTypeName
        });
    }

    addLink() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TITLE.LINKS.ADD
        });
    }

    addNewRelatedTitle(title, originalTitle,
        assets, activatedAssets, deactivatedAssets,
        localized, originalLocalized,
        relatedTitles, originalRelatedTitles,
        saveBeforeRedirect) {
        if (!saveBeforeRedirect) {
            RouterActions.redirect(`/titles/create?parentId=${title.get('id')}`);
            return;
        }
        this._save(title, originalTitle,
            assets, activatedAssets, deactivatedAssets,
            relatedTitles, originalRelatedTitles,
            localized, originalLocalized
        ).spread( () => {
            RouterActions.redirect(`/titles/create?parentId=${title.get('id')}`);
            NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'titles.edit.success');
        }).catch(err => {
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'titles.edit.error');
            throw err;
        });
        return;
    }

    addRelease(release) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TITLE.RELEASES.ADD,
            release
        });
        return;
    }

    addSubscription(subscriptionContentType, slidingPanelId, titleId, userId, userName, suggestedByUserId, suggestedByUserName) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TITLE.SUBSCRIPTION.ADD,
            subscriptionContentType: subscriptionContentType,
            suggestedByUserId: suggestedByUserId,
            suggestedByUserName: suggestedByUserName,
            titleId: titleId,
            userId: userId,
            userName: userName
        });
        SlidingPanelActions.hide(slidingPanelId);
        return;
    }

    addSynopsis(synopsisType, titleId) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TITLE.SYNOPSIS.ADD,
            synopsisType: synopsisType,
            titleId: titleId
        });
    }

    addOrReplaceTalent(talentRoleType, aka, index = undefined) {
        Request.get(`talent/${aka.get('talentId')}`).exec().then(res => {
            // Merge the talent and the aka. In this case, we only need the
            // talent's name.
            let talent = aka.merge({
                displayTalentPrefix: res.body.prefix,
                displayTalentFirstName: res.body.firstName,
                displayTalentLastName: res.body.lastName,
                displayTalentSuffix: res.body.suffix,
                displayTalentFullName: res.body.fullName,
                roleInTitle: talentRoleType.id
            });

            // This is done for compatibility when using an aka from
            // in-table-dropdown.
            // TODO: review the differences between the talent/AKA objects
            // from the moment they are used in the "add talent modal".
            if (index !== undefined) {
                talent = talent.merge({
                    akaId: aka.get('id'),
                    displayAKAFullName: aka.get('fullName'),
                    roleInTitle: talentRoleType.id
                });
                [
                    'createdBy', 'displayOrder', 'firstName', 'fullName', 'id',
                    'lastName', 'middleName', 'prefix', 'suffix', 'updatedBy'
                ].forEach(
                    a => talent = talent.delete(a)
                );
            }

            Dispatcher.dispatch({
                actionType: CONSTANTS.TITLE.TALENT.ADD_OR_REPLACE,
                index,
                talent,
                talentRoleType
            });

            return;
        }).catch(err => {
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'titles.create.talent.add-talent.error');
            throw err;
        });
    }

    applySavedFilters(saved) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TITLES.APPLY_SAVED_FILTERS,
            saved
        });
    }

    clearTitleFields(title) {
        title = title.toJS();

        let fieldsToRemove = [];
        switch (title.category) {
        case CONSTANTS.TITLE_TYPES.ANIMATED_SERIES_SEASON.id:
        case CONSTANTS.TITLE_TYPES.CARTOONS_SEASON.id:
        case CONSTANTS.TITLE_TYPES.DOCUSERIES_SEASON.id:
        case CONSTANTS.TITLE_TYPES.GAME_SHOW_SEASON.id:
        case CONSTANTS.TITLE_TYPES.LIMITED_SERIES_SEASON.id:
        case CONSTANTS.TITLE_TYPES.REALITY_SEASON.id:
        case CONSTANTS.TITLE_TYPES.SEASON_HALF_HOUR.id:
        case CONSTANTS.TITLE_TYPES.SEASON_ONE_HOUR.id:
        case CONSTANTS.TITLE_TYPES.SHORT_PROGRAMS_SEASON.id:
        case CONSTANTS.TITLE_TYPES.TALK_SHOW_SEASON.id:
            fieldsToRemove.push('runningOrder');
            break;

        case CONSTANTS.TITLE_TYPES.EPISODE.id:
            fieldsToRemove.push('season', 'episodeCount', 'isEpisodeCountFinal');
            break;

        case CONSTANTS.TITLE_TYPES.ANIMATED_SERIES.id:
        case CONSTANTS.TITLE_TYPES.CARTOONS.id:
        case CONSTANTS.TITLE_TYPES.DOCUSERIES.id:
        case CONSTANTS.TITLE_TYPES.GAME_SHOW.id:
        case CONSTANTS.TITLE_TYPES.LIMITED_SERIES.id:
        case CONSTANTS.TITLE_TYPES.REALITY.id:
        case CONSTANTS.TITLE_TYPES.SERIES_HALF_HOUR.id:
        case CONSTANTS.TITLE_TYPES.SERIES_ONE_HOUR.id:
        case CONSTANTS.TITLE_TYPES.SHORT_PROGRAMS.id:
        case CONSTANTS.TITLE_TYPES.TALK_SHOW.id:
            fieldsToRemove.push('season', 'runningOrder', 'isEpisodeCountFinal');
            break;
        default:
            fieldsToRemove.push('season', 'runningOrder', 'isEpisodeCountFinal', 'episodeCount');
        }
        fieldsToRemove.forEach(field => delete title[field]);

        const titleUpdated = Immutable.fromJS(title);

        Dispatcher.dispatch({
            actionType: CONSTANTS.TITLE.CLEAR_UNUSED_FIELDS,
            title: titleUpdated
        });

        return titleUpdated;
    }

    syncElasticSearch(titleId) {
        Request.get(`title/${titleId}/refresh-es`).exec().then(() => {
            NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'elastic-search.sync.success');
            return;
        }).catch(err => {
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'elastic-search.sync.error');
            throw err;
        });
    }

    updateCascadeFlags(newFlags) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TITLE.CASCADE_UPDATE,
            episode: newFlags.episode,
            'mini-serie-episode': newFlags['mini-serie-episode'],
            season: newFlags.season,
            serie: newFlags.serie
        });
    }

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
    }

    clearFilter() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.FILTER.CLEAR
        });
    }

    cloneParent() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TITLE.CLONE_PARENT
        });

        AssetTabActions.clear();
        RelatedActions.clear();
    }

    _encodeArrayHTML(toEncodeArray) {
        const propertiesToEncode = ['log60Lines', 'log180Lines', 'shortSynopsis', 'synopsis'];
        toEncodeArray.forEach(obj => {
            propertiesToEncode.forEach(prop => {
                obj[prop] = GetEncodedHTML(obj[prop]);
            });
        });
        return toEncodeArray;
    }

    findById(id, serieNavigation) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TITLE.GET.START,
            id: id
        });
        Request.get(`title/${id}`).exec().then(res => {
            let title = res.body;
            title.categoryGroup = Object.keys(CONSTANTS.TITLE_TYPES).reduce((c, t) => {
                let titleCategoryGroup = c;
                if (CONSTANTS.TITLE_TYPES[t].id === title.category) {
                    titleCategoryGroup = CONSTANTS.TITLE_TYPES[t].categoryGroup;
                }
                return titleCategoryGroup;
            }, undefined);

            // Pilot is a boolean, but it gets here as a String like "0".
            title.pilot = !!parseInt(title.pilot, 10);
            return [Immutable.fromJS(title), ...this.readRelations(id)];
        }).spread((
            title,
            catalogs, languageCatalogs, genres, languageAvailability, languages, links, productionCompanies,
            ratingReasons, releases, rightsAcquiredFrom, subscriptions, talent, themes,
            fieldStatusValues, synopsisValues, history
        ) => {
            history = history.body.results;
            history.sort((h1, h2) => h2.actionDate.localeCompare(h1.actionDate));
            /**
             * This transforms things like: {genreId: 9033, titleId: 3939}
             * into {id: 9033}.
             */
            let pluck = function(attr) {
                return function(obj) {
                    return {id: obj[attr]};
                };
            };

            const titleFieldStatusValues = Immutable.fromJS(fieldStatusValues.body);
            const fsvFiltered = titleFieldStatusValues.filter(fsv => SYNOPSIS_METADATA_FIELDS.some(smf => smf.id === fsv.get('titleFieldNameType')));
            const hasHidden = fsvFiltered.some(f => f.get('titleFieldStatusType') === TITLE_FIELDS_STATUS.HIDDEN.id);

            title = title.merge({
                catalogs:  Immutable.fromJS(catalogs.body).sortBy(c => c.get('name')),
                languageCatalogs:  Immutable.fromJS(languageCatalogs.body).sortBy(c => c.get('name')),
                fieldStatusValues: titleFieldStatusValues,
                hideSynopsisLogLines: hasHidden,
                genres:  Immutable.fromJS(genres.body.map(pluck('genreId'))),
                languages:  Immutable.fromJS(languages.body.map(pluck('languageId'))),
                languageAvailability: Immutable.fromJS(languageAvailability.body),
                links: Immutable.fromJS(links.body),
                productionCompanies: Immutable.fromJS(productionCompanies.body.map(pluck('productionCompanyId'))),
                ratingReasons:  Immutable.fromJS(ratingReasons.body.map(pluck('ratingReason'))),
                // Releases is an array and we need all the info.
                releases: Immutable.fromJS(releases.body).map(r => {
                    // Add default values for the releases in case they need to be saved.
                    // This is needed to avoid server errors when updating existing releases.
                    let nullOrUndefined = v => [null, undefined].indexOf(v) !== -1;
                    let airDayType = 8;
                    let airHour = 12;
                    let airMinute = 0;
                    if (!nullOrUndefined(r.get('airDayType'))) {
                        airDayType = r.get('airDayType');
                        airHour = r.get('airHour');
                    }
                    if (!nullOrUndefined(r.get('airMinute'))) {
                        airMinute = r.get('airMinute');
                    }
                    return r.merge({
                        airDayType: airDayType,
                        airHour: airHour,
                        airMinute: airMinute
                    });
                }).sortBy(r => r.get('orderInTitle')),
                rightsAcquiredFrom: Immutable.fromJS(rightsAcquiredFrom.body.map(pluck('companyId'))),
                // Group subscriptions by subscriptionContentType.
                subscriptions: Immutable.fromJS(subscriptions.body.reduce((s, t) => {
                    // Failsafe in case a new subscription type is added
                    // and we don't update the default values.

                    // Fixes a strange case where a subscription came back with a null subscriptionContentType value
                    if (!t.subscriptionContentType) {
                        return s;
                    }

                    if (!s[t.subscriptionContentType]) {
                        s[t.subscriptionContentType.toString()] = [];
                    }

                    s[t.subscriptionContentType.toString()].push(t);
                    return s;
                }, {
                    '1': [],
                    '2': [],
                    '3': [],
                    '4': [],
                    '5': [],
                    '6': [],
                    '7': []
                })),
                synopsisValues: Immutable.fromJS(synopsisValues.body),
                // Group talent by role.
                talent: Immutable.fromJS(talent.body.reduce((r, t) => {
                    if (r[t.roleInTitle]) {
                        r[t.roleInTitle].push(t);
                    } else {
                        r[t.roleInTitle] = [t];
                    }

                    return r;
                }, {})),
                themes: Immutable.fromJS(themes.body.map(pluck('themeId'))),
                history: Immutable.fromJS(history)
            });

            switch (title.get('categoryGroup')) {
            case TITLE_CATEGORY_GROUPS.MINI_SERIES:
                SerieNavigationActions.getMiniSerie(title.get('id'), serieNavigation);
                break;
            case TITLE_CATEGORY_GROUPS.SERIES:
            case TITLE_CATEGORY_GROUPS.SEASON:
            case TITLE_CATEGORY_GROUPS.EPISODE:
                SerieNavigationActions.get(title.get('id'), serieNavigation);
                break;
            }

            console.warn('title-actions.findById title', {title: title.toJSON()});

            Dispatcher.dispatch({
                actionType: CONSTANTS.TITLE.GET.SUCCESS,
                title: title
            });
        }).catch(err => {
            console.error(`title-actions.findById error loading id ${id}`, {
                err,
            });
            Dispatcher.dispatch({
                actionType: CONSTANTS.TITLE.GET.ERROR,
                err: err
            });
            if (err.status === 404 && err.response.error.url.indexOf(id) !== -1) {
                RouterActions.notFound();
            } else {
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
            }
            throw err;
        });
    }

    findDeletedTitleById(id, deletedTitle) {
        PreloaderActions.show('title-actions.find-deleted-title-by-id');

        // Prevent loading deleted titles data twice if the user has already visited the browse page to populate TitleStore.deletedTitles
        let requests = [];
        if (!deletedTitle) {
            requests.push(Request.get('title/deleted-title').exec());
        }

        Promise.all(requests).then((deletedTitlesResponses) => {
            if (deletedTitlesResponses[0]?.body) {
                deletedTitle = Immutable.fromJS(deletedTitlesResponses[0].body).find(t => t.get('id') === parseInt(id, 10));
            }
            return deletedTitle;
        }).then((title) => {
            if (!title) {
                PreloaderActions.hide('title-actions.find-deleted-title-by-id');
                RouterActions.notFound();
                return;
            }

            const actionHistoryQuery = {
                'action-object': ActionHistoryConstants.ACTION_OBJECTS.TITLE,
                'object-id': id,
                offset: 0,
                size: 4
            };

            Request.get('system/action-history').query(actionHistoryQuery).exec().then((historyRes) => {
                let history = historyRes.body.results;
                history.sort((h1, h2) => h2.actionDate.localeCompare(h1.actionDate));
                title = title.merge({
                    history: Immutable.fromJS(history)
                });

                Dispatcher.dispatch({
                    actionType: CONSTANTS.TITLE.DELETED.SET,
                    title
                });
            }).then(() => {
                PreloaderActions.hide('title-actions.find-deleted-title-by-id');
            });

            return;
        }).catch(err => {
            PreloaderActions.hide('title-actions.find-deleted-title-by-id');
            NotificationActions.showAlertDanger('titles.deleted-title.load.error');
            throw err;
        });
    }

    get(queryParams, offset, size, sortFieldName, sortDirection) {
        queryParams = queryParams.toJS();

        if (queryParams['field-status-type'] || queryParams['field-name-type']) {
            // If either is present, they must be equal length arrays or API will error with 422
            let fst = Immutable.List();
            if (queryParams['field-status-type']) {fst = Immutable.List(queryParams['field-status-type']);}
            let fnt = Immutable.List();
            if (queryParams['field-name-type']) {fnt = Immutable.List(queryParams['field-name-type']);}

            if (fst.size !== fnt.size) {
                return;
            }
        }

        if (queryParams.category) {
            queryParams.category = queryParams.category.id;
        } else if (queryParams['category-id']) {
            queryParams.category = queryParams['category-id'];
        }

        ['start-date-created', 'start-release-date'].forEach( attr => {
            let d = queryParams[attr];
            if (d) {
                d = Moment(d);
                if (d.isValid()) {
                    d = d.format('YYYY-MM-DDT00:00:00.000'+configtz);
                } else {
                    d = '';
                }
                queryParams[attr] = d;
            }
        });

        ['end-date-created', 'end-release-date'].forEach( attr => {
            let d = queryParams[attr];
            if (d) {
                d = Moment(d);
                if (d.isValid()) {
                    d = d.format('YYYY-MM-DDT23:59:59.999'+configtz);
                } else {
                    d = '';
                }
                queryParams[attr] = d;
            }
        });

        PreloaderActions.show('title-actions.get');

        offset = offset || queryParams.offset || 0;
        size = size || 20;
        queryParams.offset = offset ;
        queryParams.size = size;
        queryParams['sort-field-name'] = sortFieldName || queryParams['sort-field-name'] || 'UPDATEDDATE';
        queryParams['sort-direction'] = sortDirection || queryParams['sort-direction'] || 'desc';

        Request.get('title').query(queryParams).exec().then(res => {
            PreloaderActions.hide('title-actions.get');

            Dispatcher.dispatch({
                actionType: CONSTANTS.TITLES.GET.SUCCESS,
                offset: offset,
                size: size,
                titles: Immutable.fromJS(res.body.results),
                total: parseInt(res.header['wbtv-total-count'], 10)
            });

            return;
        }).catch(err => {
            PreloaderActions.hide('title-actions.get');

            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
            throw err;
        });

        return;
    }

    getCatalogs(titleId) {
        const preloaderSource = 'title-actions.getCatalogs';
        PreloaderActions.show(preloaderSource);

        Request.get(`title/${titleId}/catalog`).exec().then(res => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.TITLE.CATALOGS.GET.SUCCESS,
                catalogs: Immutable.fromJS(res.body),
                titleId: titleId
            });

            return;
        }).then(()=>{
            PreloaderActions.hide(preloaderSource);
            return;
        }).catch(err => {
            PreloaderActions.hide(preloaderSource);
            throw err;
        });

        return;
    }

    getDeletedTitles(searchTerm) {
        PreloaderActions.show('title-actions.get-deleted');

        let queryParams = {};
        if (searchTerm) {
            queryParams['search-title-name'] = searchTerm;
        }

        Request.get('title/deleted-title').query(queryParams).exec().then(res => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.TITLE.DELETED.GET.SUCCESS,
                deletedTitles: Immutable.fromJS(res.body)
            });

            return;
        }).then(() => {
            PreloaderActions.hide('title-actions.get-deleted');
            return;
        }).catch(err => {
            PreloaderActions.hide('title-actions.get-deleted');
            NotificationActions.showAlert(
                AlertTypes.ALERT_DANGER.name,
                'titles.delete-queue.error'
            );
            throw err;
        });
    }

    getUpdateDataById(titleId, proceedFunc) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TITLE.GET_UPDATE_DATA.START
        });
        return Request.get(`title/${titleId}`).exec().then(res => {
            const title = res.body;

            if (Date.now()>Moment.utc(title.updatedDate)+60000) {
                proceedFunc();
            }

            Dispatcher.dispatch({
                actionType: CONSTANTS.TITLE.GET_UPDATE_DATA.SUCCESS,
                updatedDate: title.updatedDate
            });
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.TITLE.GET_UPDATE_DATA.ERROR
            });
            throw err;
        });
    }

    markForDeletion(titleId) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TITLE.MARK_FOR_DELETION.START
        });

        Request.del(`title/${titleId}`).exec().then(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.TITLE.MARK_FOR_DELETION.SUCCESS
            });
            NotificationActions.showAlertSuccess('titles.edit.delete.success');
            RouterActions.redirect('/titles/deleted');
            return;
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.TITLE.MARK_FOR_DELETION.ERROR
            });
            NotificationActions.showAlertDanger('titles.edit.delete.error');
            throw err;
        });
    }

    moveElement(relation, orderBy, from, to) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TITLE.MOVE_ELEMENT,
            from: from,
            orderBy: orderBy,
            relation: relation,
            to: to
        });
        return;
    }

    readRelations(id) {
        let requests = TITLE_RELATIONS.map(relation => {
            let r = Request.get(`${relation.url.base}/${id}/${relation.url.child}`).exec();
            if (relation.optional) {
                r = r.catch(err => {
                    // If optional, then treat a 404 as an empty response.
                    if (err.status === 404) {
                        console.error(err);
                        return {body: relation.emptyResponse};
                    }

                    throw err;
                });
            }

            return r;
        });

        let actionHistoryQuery = {
            'action-object': ActionHistoryConstants.ACTION_OBJECTS.TITLE,
            'object-id': id,
            offset: 0,
            size: 4
        };

        requests.push(Request.get('system/action-history').query(actionHistoryQuery).exec());

        return requests;
    }

    removeCatalog(catalog, catalogTypeName = 'catalogs', includeSerie = false, includeSeason = false, includeEpisode = false) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TITLE.CATALOGS.REMOVE,
            catalog,
            includeSerie,
            includeSeason,
            includeEpisode,
            name: catalogTypeName
        });
    }

    removeElement(relation, index, orderBy) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TITLE.REMOVE_ELEMENT,
            index: index,
            orderBy: orderBy,
            relation: relation
        });
        return;
    }

    removeById(relation, id, idField) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TITLE.REMOVE_BY_ID,
            id: id,
            idField: idField,
            relation: relation
        });
        return;
    }

    _save(title, originalTitle,
        assets, activatedAssets, deactivatedAssets,
        relatedTitles, originalRelatedTitles,
        localized, originalLocalized,
        titleStyle, originalTitleStyle,
        parentTitleId) {

        // clear title fields based on title type, and update in store
        title = this.clearTitleFields(title);

        let titleData = title.delete('history').delete('hideSynopsisLogLines').toJS();

        // Any tinyMCE textbox that allows HTML needs to be added here. Note, API must unescape these fields in html responses.
        [
            'awards',
            'frontendComment',
            'masteringComments',
            'nonTheatricalRights',
            'programLegalline',
            'promotionalLegalline',
            'shortSynopsis',
            'synopsis',
        ].forEach(p => {
            titleData[p] = GetEncodedHTML(titleData[p]);
            return;
        });

        // Delete extra properties we added to the model.
        delete titleData.categoryGroup;
        delete titleData.removeCatalogs;
        delete titleData.cascadeFlags;

        TITLE_RELATIONS.forEach(r => delete titleData[r.attr]);
        // Set booleans.
        ['active', 'pilot', 'silentMovie'].forEach(p => {
            if (titleData[p]) {
                titleData[p] = 1;
            } else {
                titleData[p] = 0;
            }

            return;
        });

        // Set booleans that allow null.
        ['isEpisodeCountFinal'].forEach(p => {
            if (titleData[p] === true) {
                titleData[p] = 1;
                return;
            }

            if (titleData[p] === false) {
                titleData[p] = 0;
                return;
            }

            titleData[p] = null;
            return;
        });

        // Define if post or put.
        let method = 'post';
        let path = 'title';

        const isNewTitle = title.get('id') === undefined;
        if (!isNewTitle) {
            method = 'put';
            path = `title/${title.get('id')}`;
        }

        // Set as parentTitleId the titleId where user is coming from.
        // TODO: check if this is still necessary.
        if (titleData.parentTitleId !== null && parentTitleId) {
            titleData.parentTitleId = parentTitleId;
        }

        let titleId;
        return Request[method](path).send(titleData).exec().then(res => {
            titleId = res.body.id;
            let promises = [res, ...this.saveRelations(res, title, originalTitle)];
            if (assets) {
                promises.push(
                    AssetTabActions.save(
                        titleId,
                        assets,
                        activatedAssets,
                        deactivatedAssets,
                        'title'
                    )
                );
            }
            if (relatedTitles !== originalRelatedTitles) {
                promises.push(
                    RelatedActions.save(
                        titleId,
                        relatedTitles
                    )
                );
            }
            if (localized !== originalLocalized) {
                let talents = title.get('talent');
                promises.push(
                    TitleLocalizedActions.save(titleId, localized, talents)
                );
            }
            if (titleStyle !== originalTitleStyle) {
                promises.push(
                    ...this.saveTitleStyle(titleId, title, titleStyle)
                );
            }
            return promises;
        }).catch (err => {
            if (isNewTitle && titleId) {
                NotificationActions.showAlert(
                    AlertTypes.ALERT_DANGER.name,
                    'titles.create.error.after-post',
                    titleId
                );
            }

            throw err;
        });
    }

    save(title, originalTitle,
        assets, activatedAssets, deactivatedAssets,
        relatedTitles, originalRelatedTitles,
        localized, originalLocalized,
        titleStyle, originalTitleStyle,
        options
    ) {
        let defaults = {
            messages: {
                error: 'titles.create.error',
                success: 'titles.create.success'
            }
        };
        Object.assign(defaults, options);
        const preloaderSource = 'title-actions.save';
        PreloaderActions.show(preloaderSource);
        this._save(
            title, originalTitle,
            assets, activatedAssets, deactivatedAssets,
            relatedTitles, originalRelatedTitles,
            localized, originalLocalized,
            titleStyle, originalTitleStyle
        ).spread(res => {
            PreloaderActions.hide(preloaderSource);

            let copyTitle = title;
            if (copyTitle.get('id') === undefined) {copyTitle = copyTitle.set('id', res.body.id);}

            if (copyTitle.get('hideSynopsisLogLines')) {
                TitleStatusActions.hideAllSynopsisMetadata(copyTitle);
            } else {
                if (originalTitle.get('hideSynopsisLogLines') !== copyTitle.get('hideSynopsisLogLines')) {
                    TitleStatusActions.publishAllSynopsisMetadata(copyTitle);
                }
            }

            if (title.get('id') === undefined) {
                // was a create
                RouterActions.redirect(`/titles/${res.body.id}`, true);
            }

            Dispatcher.dispatch({
                actionType: CONSTANTS.TITLE.CLONE
            });

            NotificationActions.showAlert(
                AlertTypes.ALERT_SUCCESS.name,
                defaults.messages.success
            );

            return;
        }).catch(err => {
            PreloaderActions.hide(preloaderSource);

            NotificationActions.showAlert(
                AlertTypes.ALERT_DANGER.name,
                defaults.messages.error
            );

            throw err;
        });
        return;
    }

    saveAndConnect(
        title, originalTitle,
        assets, activatedAssets, deactivatedAssets,
        relatedTitles, originalRelatedTitles,
        localized, originalLocalized,
        titleStyle, originalTitleStyle,
        relation, parentId
    ) {
        this._save(
            title, originalTitle,
            assets, activatedAssets, deactivatedAssets,
            relatedTitles, originalRelatedTitles,
            localized, originalLocalized,
            titleStyle, originalTitleStyle,
            parentId
        ).spread(res => {
            title = title.set('id', res.body.id);
            title = title.merge({
                relationshipType: relation.get('id')
            });

            Dispatcher.dispatch({
                actionType: CONSTANTS.TITLE.RELATED.ADD_NEW,
                title
            });

            RouterActions.redirect(`/titles/${parentId}/related`);
            NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'titles.create.success');
            return;
        }).catch(err => {
            NotificationActions.showAlert(
                AlertTypes.ALERT_DANGER.name,
                'titles.create.error'
            );

            throw err;
        });
    }

    saveAddTitlePanelFilters(id, filterSortState) {
        localStorage.setItem(`${CONSTANTS.SAVED_ADD_TITLE_PANEL_FILTERS}${id}`, JSON.stringify(filterSortState));

        return;
    }

    saveRelations(res, title, originalTitle) {
        let id = res.body.id;
        // Save all requests promises here.
        let requests = [];
        // Process relations.
        TITLE_RELATIONS.forEach(relation => {
            if (
                relation.attr === 'fieldStatusValues' ||
                // If the array hasn't changed return immediatly.
                title.get(relation.attr).equals(originalTitle.get(relation.attr))
            ) {
                return;
            }
            /**
             * Skip special relations.
             * Both methods here return only 1 promise representing all the requests
             * done to update the related-titles/releases arrays. These requests must be
             * executed in sync.
             */
            switch (relation.attr) {

            case 'catalogs':
                let catalogsArr = title.get(relation.attr);
                let originalCatalogsArr = originalTitle.get(relation.attr);

                // Elements to add.
                requests.push(
                    ...catalogsArr.filter(
                        item => !originalCatalogsArr.find(i => item.get('id') === i.get('id'))
                    ).map(
                        item => Request.post(`${relation.url.base}/${id}/${relation.url.child}/${item.get('id')}`).query({
                            'include-series':item.get('include-series'),
                            'include-season':item.get('include-season'),
                            'include-episode':item.get('include-episode'),
                        }).exec().catch(e => {
                            // do not throw error for adding duplicated values
                            console.error(e);
                            return;
                        })
                    ).toJS()
                );
                // Elements to remove.
                requests.push(
                    ...originalCatalogsArr.filter(
                        item => !catalogsArr.find(i => item.get('id') === i.get('id'))
                    ).map(item => {
                        const rmCatalog = title.get('removeCatalogs').find(c => c.get('id') === item.get('id'));
                        return Request.del(`${relation.url.base}/${id}/${relation.url.child}/${item.get('id')}`).query({
                            'include-series':rmCatalog.get('include-series'),
                            'include-season':rmCatalog.get('include-season'),
                            'include-episode':rmCatalog.get('include-episode'),
                        }).exec();
                    }).toJS()
                );
                break;

            case 'languageCatalogs':
                let languageCatalogsArr = title.get(relation.attr);
                let originalLanguageCatalogsArr = originalTitle.get(relation.attr);

                // Elements to add.
                requests.push(
                    ...languageCatalogsArr.filter(
                        item => !originalLanguageCatalogsArr.find(i => item.get('id') === i.get('id'))
                    ).map(
                        item => Request.post(`${relation.url.base}/${id}/${relation.url.child}/${item.get('id')}`).query({
                            'include-series':item.get('include-series'),
                            'include-season':item.get('include-season'),
                            'include-episode':item.get('include-episode'),
                        }).exec().catch(e => {
                            // do not throw error for adding duplicated values
                            console.error(e);
                            return;
                        })
                    ).toJS()
                );
                // Elements to remove.
                requests.push(
                    ...originalLanguageCatalogsArr.filter(
                        item => !languageCatalogsArr.find(i => item.get('id') === i.get('id'))
                    ).map(item =>
                        Request.del(`${relation.url.base}/${id}/${relation.url.child}/${item.get('id')}`).exec()
                    ).toJS()
                );
                break;

            case 'languageAvailability' :
                let toDelete = originalTitle.get('languageAvailability').toSet()
                    .subtract(title.get('languageAvailability').toSet())
                    .map(l => Request.del(`title/${id}/language-availability/${l.get('titleLanguageAvailabilityId')}`).exec())
                    .toJS();
                requests.push(...toDelete);
                break;

            case 'links':
                // First iterate links array and set titleId to match id to prevent 422 error when adding a season to Friends
                // This only happens when user is creating a new child title with copied data from the parent. The titleId here is
                // the parent's title one instead of the newly created title.
                const links = title.get('links').map(c => {
                    let l = c.toJS();
                    l.titleId = id;
                    return Immutable.fromJS(l);
                });

                // Save all links in one PUT call
                requests.push(
                    Request.put(`title/${id}/link`).send(links.toList().toJS()).exec()
                );
                break;

            case 'releases':
                // Save all releases in one PUT call
                let releasesList = title.get('releases').toList().toJS();
                releasesList.forEach(release => {
                    let boxOfficeIsFinal = 0;
                    if (release.boxOfficeIsFinal) {
                        boxOfficeIsFinal = 1;
                    }
                    release.boxOfficeIsFinal = boxOfficeIsFinal;
                });
                requests.push(
                    Request.put(`title/${id}/release`).send(releasesList).exec()
                );
                break;

            case 'subscriptions':
                requests.push(
                    Request.put(`title/${id}/user-subscription/brainiac-cms`)
                        .send(title.get('subscriptions').toList().flatten(1).toJS().map((s) => {
                            // Removing this attributes that are only used in the table view, not required by the API
                            ['userName', 'suggestedByUserName'].forEach(p => delete s[p]);
                            return s;
                        }))
                        .exec()
                );
                break;

            case 'talent':
                requests.push(
                    Request.put(`title/${id}/talent`)
                        .send(title.get('talent').toList().flatten(1).toJS().map((t, i) => {
                            // Override the order so that the server doesn't blow up
                            // because of duplicated numbers.
                            t.orderInTitle = i;
                            // Do some cleanup too...
                            delete t.displayAKAFullName;
                            delete t.displayTalentFullName;
                            delete t.fullName;
                            return t;
                        }))
                        .exec()
                );
                break;

            case 'genres':
            case 'languages':
            case 'themes':
                const cascadeFlags = title.get('cascadeFlags');
                const listArr = title.get(relation.attr);
                const originalListArr = originalTitle.get(relation.attr);

                // Elements to add.
                requests.push(
                    ...listArr.filter(
                        item => !originalListArr.find(i => item.get('id') === i.get('id'))
                    ).map(
                        item => Request.post(`${relation.url.base}/${id}/${relation.url.child}/${item.get('id')}`).query({
                            'include-series': cascadeFlags.get('serie'),
                            'include-season': cascadeFlags.get('season'),
                            'include-episode': cascadeFlags.get('episode'),
                        }).exec().catch(e => {
                            // do not throw error for adding duplicated values
                            console.error(e);
                            return;
                        })
                    ).toJS()
                );
                // Elements to remove.
                requests.push(
                    ...originalListArr.filter(
                        item => !listArr.find(i => item.get('id') === i.get('id'))
                    ).map(item => {
                        return Request.del(`${relation.url.base}/${id}/${relation.url.child}/${item.get('id')}`).query({
                            'include-series': cascadeFlags.get('serie'),
                            'include-season': cascadeFlags.get('season'),
                            'include-episode': cascadeFlags.get('episode'),
                        }).exec();
                    }).toJS()
                );
                break;

            case 'synopsisValues':
                let oldSynopsis;
                let synopsisValues = this._encodeArrayHTML(title.get('synopsisValues').toJS());
                let originalSynopsisValues = this._encodeArrayHTML(originalTitle.get('synopsisValues').toJS());
                synopsisValues.forEach(s => {
                    if (!s.titleSynopsisId) {
                        requests.push(
                            Request.post(`title/${id}/synopsis`)
                                .send(s)
                                .exec()
                        );
                    } else {
                        oldSynopsis = originalSynopsisValues.find(os => os.titleSynopsisId === s.titleSynopsisId);
                        if (oldSynopsis !== s) {
                            requests.push(
                                Request.put(`title/${id}/synopsis/${s.titleSynopsisId}`)
                                    .send(s)
                                    .exec()
                            );
                        }
                    }
                });
                requests.push(
                    ...originalSynopsisValues.filter(
                        item => !synopsisValues.find(i => i.titleSynopsisId === item.titleSynopsisId)
                    ).map(item => {
                        return Request.del(`title/${id}/synopsis/${item.titleSynopsisId}`).exec();
                    })
                );
                break;

            default:
                let arr = title.get(relation.attr);
                let originalArr = originalTitle.get(relation.attr);

                // Elements to add.
                requests.push(
                    ...arr.filter(
                        item => !originalArr.find(i => item.get('id') === i.get('id'))
                    ).map(
                        item => Request.post(`${relation.url.base}/${id}/${relation.url.child}/${item.get('id')}`).exec()
                    ).toJS()
                );
                // Elements to remove.
                requests.push(
                    ...originalArr.filter(
                        item => !arr.find(i => item.get('id') === i.get('id'))
                    ).map(item =>
                        Request.del(`${relation.url.base}/${id}/${relation.url.child}/${item.get('id')}`).exec()
                    ).toJS()
                );

                break;
            }

            return;
        });
        return requests;
    }

    saveTitleStyle(titleId, title, titleStyle) {
        const endpoints = {
            appBackgroundImage: `title/${titleId}/app-background-image`,
            appBackgroundVideo: `title/${titleId}/app-background-video`,
            appBackgroundTitleTreatment: `title/${titleId}/app-title-treatment`
        };
        const errorMessages = {
            appBackgroundImage: 'titles.create.style.background-image.error',
            appBackgroundVideo: 'titles.create.style.background-video.error',
            appBackgroundTitleTreatment: 'titles.create.style.title-treatment.error'
        };
        return Object.entries(endpoints).map(entries => {
            const [attr, endpoint] = entries;
            // Look carefully! size is the size of the File to upload in bytes
            // not an Immutable object property.
            // Not having a size means there's no file selected.
            if (!titleStyle.get(attr).size) {
                // However, we still need to check if the user only removed
                // the existing image without uploading a new one.
                if (titleStyle.getIn(['remove', attr]) === true) {
                    return Request.del(endpoint).exec().catch(e => {
                        NotificationActions.showAlertDanger(`${errorMessages[attr]}.delete-old-image`);
                        throw e;
                    });
                }

                // If not, then just return null because the user
                // hasn't changed anything.
                return null;
            }

            let del = Promise.resolve();
            let uploadMethod = 'POST';
            if (title.get(`${attr}S3Path`)) {
                del = Request.del(endpoint).exec().catch(e => {
                    // Notify the user and keep throwing the value.
                    NotificationActions.showAlertDanger(`${errorMessages[attr]}.delete-old-image`);
                    throw e;
                });
                uploadMethod = 'PUT';
            }
            return del.then(
                () => UploadFile(
                    uploadMethod,
                    endpoint,
                    titleStyle.get(attr),
                    new XMLHttpRequest()
                ).catch(e => {
                    // Notify the user and keep throwing the value.
                    NotificationActions.showAlertDanger(`${errorMessages[attr]}.upload`);
                    throw e;
                }).then(
                    () => Request.get(`title/${titleId}`).exec().catch(e => {
                        // Important: don't throw since this is only for UI
                        // purposes. Just tell the user to reload the page.
                        NotificationActions.showAlertDanger('common.reload-page');
                        console.error(e);
                        return;
                    })
                ).then(titleRes => {
                    const s3Label = `${attr}S3Path`;
                    const urlLabel = `${attr}Url`;
                    this.updateTitle(s3Label, titleRes.body[s3Label]);
                    this.updateTitle(urlLabel, titleRes.body[urlLabel]);
                    return;
                })
            );
        }).filter(p => p !== null);
    }

    setDeletedTitle(title) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TITLE.DELETED.SET,
            title
        });
    }

    setFilter(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.FILTER.SET,
            attr: attr,
            value: value
        });
    }

    setSort(sortFieldName, sortDirection) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SORT.SET,
            sortFieldName: sortFieldName,
            sortDirection: sortDirection
        });
    }

    undeleteTitle(titleId) {
        PreloaderActions.show('title-actions.undelete-title');
        Request.put(`title/${titleId}/undelete`).exec().then(() => {
            PreloaderActions.hide('title-actions.undelete-title');
            RouterActions.redirect('/titles/deleted');
            NotificationActions.showAlertSuccess('titles.deleted-title.undelete.success');
        }).catch((err) => {
            PreloaderActions.hide('title-actions.undelete-title');
            NotificationActions.showAlertDanger('titles.deleted-title.undelete.error');
            console.error(err);
        });
    }

    updateAKA(talentRoleType, aka, index) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TITLE.TALENT.UPDATE_AKA,
            aka,
            index,
            talentRoleType
        });
    }

    updateTitle(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TITLE.UPDATE,
            attr,
            value
        });

        return;
    }

    // Update the TitleStyle object. Mainly used
    // to store references to the files to upload.
    updateTitleStyle(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TITLE_STYLE.UPDATE,
            attr,
            value
        });

        return;
    }

    updateValidated(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TITLE.VALIDATED.UPDATE,
            attr,
            value
        });
    }
}

let actions = new TitleActions();

export {
    actions as TitleActions,
    CONSTANTS as TitleConstants
};
