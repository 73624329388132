/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';
import url from 'url';

import {SessionActions, SessionConstants} from './session-actions';
import config from '../../config/config.js';
import Dispatcher from '../../dispatcher/dispatcher';


let __timeoutInterval;

const POOLING_INTERVAL = 60000; // 5 seconds TODO: set a highier value

/**
 * Map of all permissions.
 */
const PERMISSIONS = {
    ACCOUNTS: {
        APPLICANTS: {
            BROWSE: 'brainiacCMS://accounts.applicants.browse',
            CREATE: 'brainiacCMS://accounts.applicants.create',
            EDIT: 'brainiacCMS://accounts.applicants.edit',
            PERMISSIONS: {
                APPROVE: 'brainiacCMS://accounts.applicants.permissions.approve',
                APPROVE_WITHOUT_PERMISSION: 'brainiacCMS://accounts.applicants.permissions.approve-without-permission',
                DENY: 'brainiacCMS://accounts.applicants.permissions.deny',
                EDIT_ROLES_GROUPS: 'brainiacCMS://accounts.applicants.permissions.edit-roles-groups',
                PERMISSION_PACKAGES: 'brainiacCMS://accounts.applicants.permissions.permision-packages',
                READ: 'brainiacCMS://accounts.applicants.permissions.read',
                REJECT: 'brainiacCMS://accounts.applicants.permissions.reject',
                VALIDATE: 'brainiacCMS://accounts.applicants.permissions.validate'
            },
            READ: 'brainiacCMS://accounts.applicants.read'
        },
        BROWSE: 'brainiacCMS://accounts.browse',
        EDIT: 'brainiacCMS://accounts.edit',
        IMPERSONATE_USER: 'brainiacCMS://accounts.impersonate-user',
        MANAGEMENT: {
            BAN: 'brainiacCMS://accounts.management.ban',
            DEACTIVATE: 'brainiacCMS://accounts.management.deactivate',
            EXPORT_USERS: 'brainiacCMS://accounts.management.export.users',
            READ: 'brainiacCMS://accounts.management.read',
            UNLOCK: 'brainiacCMS://accounts.management.unlock',
            SET_PASSWORD: 'brainiacCMS://accounts.management.set_password'
        },
        ACCOUNT_WIZARD:{
            UPLOAD: 'brainiacCMS://accounts.mass-add.upload'
        },
        MASS_UPDATE: {
            UPLOAD: 'brainiacCMS://accounts.mass-update.upload',
        },
        PERMISSIONS: {
            EDIT: 'brainiacCMS://accounts.permissions.edit',
            EDIT_ROLES_GROUPS: 'brainiacCMS://accounts.permissions.edit-roles-groups',
            PERMISSION_PACKAGES: 'brainiacCMS://accounts.permissions.permision-packages',
            READ: 'brainiacCMS://accounts.permissions.read'
        },
        PERMISSIONS_AUDIT: {
            READ: 'brainiacCMS://accounts.permissions-audit.browse'
        },
        READ: 'brainiacCMS://accounts.read',
        SUBSCRIPTIONS: {
            READ: 'brainiacCMS://accounts.subscriptions.browse',
            EDIT: 'brainiacCMS://accounts.subscriptions.edit'
        },
        VIEW_USER_ERRORS: 'brainiacCMS://accounts.applicants.view.user.errors',
    },
    ANALYTICS: {
        REPORTS: {
            BROWSE: 'brainiacCMS://analytics.reports.browse',
        },
    },
    ASSETS: {
        AUDIO: {
            CREATE: 'brainiacCMS://assets.audio.create',
            EDIT: 'brainiacCMS://assets.audio.edit',
            READ: 'brainiacCMS://assets.audio.read'
        },
        BROWSE_ALL: 'brainiacCMS://assets.browse-all',
        CATALOGS: {
            BROWSE: 'brainiacCMS://assets.catalogs.browse',
            EDIT: 'brainiacCMS://assets.catalogs.edit'
        },
        ASSET_DOCUMENT: {
            BROWSE: 'brainiacCMS://assets.document.browse',
            CREATE: 'brainiacCMS://assets.document.create',
            EDIT: 'brainiacCMS://assets.document.edit',
            READ: 'brainiacCMS://assets.document.read'
        },
        ASSET_IMAGE: {
            BROWSE: 'brainiacCMS://assets.image.browse',
            CREATE: 'brainiacCMS://assets.image.create',
            EDIT: 'brainiacCMS://assets.image.edit',
            READ: 'brainiacCMS://assets.image.read'
        },
        MERCHANDISE: {
            CREATE: 'brainiacCMS://assets.merchandise.create',
            EDIT: 'brainiacCMS://assets.merchandise.edit',
            READ: 'brainiacCMS://assets.merchandise.read'
        },
        SCRIPT: {
            CREATE: 'brainiacCMS://assets.script.create',
            EDIT: 'brainiacCMS://assets.script.edit',
            READ: 'brainiacCMS://assets.script.read'
        },
        VIDEO: {
            ALLOW_PLAYBACK_CMS: 'brainiacCMS://assets.video.allow-playback',
            BROWSE: 'brainiacCMS://assets.video.browse',
            CREATE: 'brainiacCMS://assets.video.create',
            DOWNLOAD_FORENSIC_DATA: 'brainiacCMS://assets.video.download-forensic-data',
            EDIT: 'brainiacCMS://assets.video.edit',
            GEORESTRICTION: 'brainiacCMS://assets.video.geo-restriction',
            READ: 'brainiacCMS://assets.video.read',
            RESTRICTIONS_TAB: 'brainiacCMS://assets.video.restrictions-tab',
            VIEW_FORENSIC_URLS: 'brainiacCMS://assets.video.view-forensic-urls',
        },
    },
    BRANDS: {
        BROWSE: 'brainiacCMS://brands.browse',
        CREATE: 'brainiacCMS://brands.create',
        EDIT: 'brainiacCMS://brands.edit'
    },
    CATALOGS: {
        ASSET: {
            BROWSE: 'brainiacCMS://catalogs.asset.browse',
            COPY: 'brainiacCMS://catalogs.asset.copy',
            CREATE: 'brainiacCMS://catalogs.asset.create',
            EDIT: 'brainiacCMS://catalogs.asset.edit',
            MASS_UPDATE: {
                UPLOAD: 'brainiacCMS://catalogs.asset.mass-update.upload',
            },
            READ: 'brainiacCMS://catalogs.asset.read'
        },
        TITLE: {
            BROWSE: 'brainiacCMS://catalogs.title.browse',
            CREATE: 'brainiacCMS://catalogs.title.create',
            EDIT: 'brainiacCMS://catalogs.title.edit',
            MASS_UPDATE: {
                UPLOAD: 'brainiacCMS://catalogs.title.mass-update.upload',
            },
            READ: 'brainiacCMS://catalogs.title.read'
        },
        LANGUAGE: {
            BROWSE: 'brainiacCMS://catalogs.language.browse',
            CREATE: 'brainiacCMS://catalogs.language.create',
            EDIT: 'brainiacCMS://catalogs.language.edit',
            MASS_UPDATE: {
                UPLOAD: 'brainiacCMS://catalogs.language.mass-update.upload',
            },
            READ: 'brainiacCMS://catalogs.language.read'
        }
    },
    EVENTS: {
        BROWSE: 'brainiacCMS://events.browse',
        BROWSE_ARCHIVED: 'brainiacCMS://events.browse-archived',
        CREATE: 'brainiacCMS://events.create',
        EDIT: 'brainiacCMS://events.edit',
        EXPORT_VIEWS: 'brainiacCMS://events.export-views',
        EXPORT_VIEWS_WITH_COSTS: 'brainiacCMS://events.export-views-costs',
    },
    GROUPS: {
        BROWSE: 'brainiacCMS://groups.browse',
        CREATE: 'brainiacCMS://groups.create',
        EDIT: 'brainiacCMS://groups.edit',
        READ: 'brainiacCMS://groups.read',
        CLIENT_REP_GROUPS: {
            BROWSE: 'brainiacCMS://groups.client_rep_groups.browse',
            BROWSECR: 'brainiacCMS://groups.client_rep_groups.client_reps.browse',
            CREATE: 'brainiacCMS://groups.client_rep_groups.create',
            EDIT: 'brainiacCMS://groups.client_rep_groups.edit',
            READ: 'brainiacCMS://groups.client_rep_groups.read',
            USERS: {
                READ: 'brainiacCMS://groups.client_rep_groups.users.read',
                REMOVE: 'brainiacCMS://groups.client_rep_groups.users.remove',
                ADD: 'brainiacCMS://groups.client_rep_groups.users.add'
            }
        },
        USERS: {
            READ: 'brainiacCMS://groups.users.read',
            REMOVE: 'brainiacCMS://groups.users.remove',
            SYNC_ES: 'brainiacCMS://groups.users.sync_es'
        }
    },
    GUILDS: {
        CREATE: 'brainiacCMS://guilds.create',
        BROWSE: 'brainiacCMS://guilds.browse',
        READ: 'brainiacCMS://guilds.read',
        RESET: 'brainiacCMS://guilds.reset',
        UPDATE: 'brainiacCMS://guilds.update',
    },
    HARDAC: {
        CC_EDITOR: {
            EDIT: 'brainiacCMS://titles.captions.edit',
        },
        CONFIGURATION: {
            EDIT: 'brainiacCMS://hardac.configuration.edit',
        },
        PROCESSES: {
            READ: 'brainiacCMS://hardac.processes.read',
            BROWSE: 'brainiacCMS://hardac.processes.browse',
        },
        JOBS: {
            BROWSE: 'brainiacCMS://hardac.jobs.browse',
        },
        MACHINE_LEARNING: {
            READ: 'brainiacCMS://hardac.machine-learning.read',
            BROWSE: 'brainiacCMS://hardac.machine-learning.browse',
        },
        PROXY: {
            BROWSE: 'brainiacCMS://hardac.proxies.browse',
            EDIT: 'brainiacCMS://hardac.proxy.edit',
            READ: 'brainiacCMS://hardac.proxies.read',
        },
        TIMELINE: {
            BROWSE: 'brainiacCMS://timeline.browse',
            VIEW_MEDIA_INFO: 'brainiacCMS://timeline.view-media-info',
            EDIT: 'brainiacCMS://timeline.edit',
            PUBLISH: 'brainiacCMS://timeline.publish',
            READ: 'brainiacCMS://timeline.read',
        },
        VIDEO_DELIVERY: {
            APPROVE: 'brainiacCMS://video_delivery.approve',
            BROWSE: 'brainiacCMS://video_delivery.browse',
            READ: 'brainiacCMS://video_delivery.read',
            REJECT: 'brainiacCMS://video_delivery.reject'
        }
    },
    HELP_PAGES: {
        VIEW: 'brainiacCMS://help-pages.view',
        EDIT: 'brainiacCMS://help-pages.edit'
    },
    HELP_VIDEOS: {
        BROWSE: 'brainiacCMS://help-videos.browse',
    },
    HISTORY: {
        BROWSE: 'brainiacCMS://history.browse',
    },
    HOMEPAGE: {
        BROWSE: 'brainiacCMS://homepage.browse',
        CREATE: 'brainiacCMS://homepage.create',
        EDIT: 'brainiacCMS://homepage.edit',
        READ: 'brainiacCMS://homepage.read',
        USERS: {
            BROWSE: 'brainiacCMS://homepage.users.browse'
        }
    },
    HOMESCREEN: {
        BROWSE: 'brainiacCMS://homescreen.browse',
        CREATE: 'brainiacCMS://homescreen.create',
        EDIT: 'brainiacCMS://homescreen.edit',
        READ: 'brainiacCMS://homescreen.read',
    },
    LOGIN_BACKGROUND: {
        BROWSE: 'brainiacCMS://login-background.browse',
        REMOVE: 'brainiacCMS://login-background.remove',
        UPLOAD: 'brainiacCMS://login-background.upload'
    },
    PRESETS: {
        BROWSE: 'brainiacCMS://presets.browse',
        CREATE: 'brainiacCMS://presets.create',
        EDIT: 'brainiacCMS://presets.edit',
        READ: 'brainiacCMS://presets.read'
    },
    PRIVACY_REQUESTS: {
        BROWSE: 'brainiacCMS://privacy-requests.browse',
        CREATE: 'brainiacCMS://privacy-requests.create',
    },
    PUBLISHING: {
        BROWSE: 'brainiacCMS://publishing-list.browse',
        CREATE: 'brainiacCMS://publishing-list.create',
        EDIT: 'brainiacCMS://publishing-list.edit',
        LOCALIZED: {
            EDIT: 'brainiacCMS://publishing-list.localized.edit',
            READ: 'brainiacCMS://publishing-list.localized.read',
        },
        READ: 'brainiacCMS://publishing-list.read',
        REMOVE: 'brainiacCMS://publishing-list.remove'
    },
    PUSH_NOTIFICATIONS: {
        BROWSE: 'brainiacCMS://push-notifications.browse',
        CREATE: 'brainiacCMS://push-notifications.create',
        EDIT: 'brainiacCMS://push-notifications.edit',
        READ: 'brainiacCMS://push-notifications.read'
    },
    ROLES: {
        BROWSE: 'brainiacCMS://roles.browse',
        CREATE: 'brainiacCMS://roles.create',
        EDIT: 'brainiacCMS://roles.edit',
        READ: 'brainiacCMS://roles.read',
        USERS: {
            READ: 'brainiacCMS://roles.users.read',
            REMOVE: 'brainiacCMS://roles.users.remove'
        }
    },
    SCHEDULING: {
        DOCUMENT_BATCHES: {
            BROWSE: 'brainiacCMS://scheduling.document-batches.browse',
            CREATE: 'brainiacCMS://scheduling.document-batches.create',
            EDIT: 'brainiacCMS://scheduling.document-batches.edit'
        },
        IMAGE_BATCHES: {
            BROWSE: 'brainiacCMS://scheduling.image-batches.browse',
            CREATE: 'brainiacCMS://scheduling.image-batches.create',
            EDIT: 'brainiacCMS://scheduling.image-batches.edit'
        },
        TITLE_BATCHES: {
            BROWSE: 'brainiacCMS://scheduling.title-batches.browse',
            CREATE: 'brainiacCMS://scheduling.title-batches.create',
            EDIT: 'brainiacCMS://scheduling.title-batches.edit'
        },
        VIDEO_BATCHES: {
            BROWSE: 'brainiacCMS://scheduling.video-batches.browse',
            CREATE: 'brainiacCMS://scheduling.video-batches.create',
            EDIT: 'brainiacCMS://scheduling.video-batches.edit'
        },
    },
    SETUP: {
        AUTHORIZED_PARTNERS: {
            BROWSE: 'brainiacCMS://setup.authorized_partners.browse',
            CREATE: 'brainiacCMS://setup.authorized_partners.create',
            EDIT: 'brainiacCMS://setup.authorized_partners.edit',
            READ: 'brainiacCMS://setup.authorized_partners.read',
        },
        EMAIL_WHITELIST: {
            BROWSE: 'brainiacCMS://setup.email_whitelist.browse',
            CREATE: 'brainiacCMS://setup.email_whitelist.create',
            EDIT: 'brainiacCMS://setup.email_whitelist.edit',
            READ: 'brainiacCMS://setup.email_whitelist.read',
        },
        LOOKUPS: {
            BROWSE: 'brainiacCMS://setup.lookups.browse',
            COMPANY: {
                BROWSE: 'brainiacCMS://setup.lookups.company.browse',
                CREATE: 'brainiacCMS://setup.lookups.company.create',
                EDIT: 'brainiacCMS://setup.lookups.company.edit',
            },
            CURRENCY:{
                BROWSE: 'brainiacCMS://setup.lookups.currency.browse',
                CREATE: 'brainiacCMS://setup.lookups.currency.create',
                EDIT: 'brainiacCMS://setup.lookups.currency.edit',
            },
            DOMAIN_BLACKLIST: {
                BROWSE: 'brainiacCMS://setup.lookups.domain_blacklist.browse',
                CREATE: 'brainiacCMS://setup.lookups.domain_blacklist.create',
                EDIT: 'brainiacCMS://setup.lookups.domain_blacklist.edit',
            },
            GENRE:{
                BROWSE: 'brainiacCMS://setup.lookups.genres.browse',
                CREATE: 'brainiacCMS://setup.lookups.genres.create',
                EDIT: 'brainiacCMS://setup.lookups.genres.edit',
            },
            LANGUAGE:{
                BROWSE: 'brainiacCMS://setup.lookups.languages.browse'
            },
            TERRITORY:{
                BROWSE: 'brainiacCMS://setup.lookups.territories.browse',
                CREATE: 'brainiacCMS://setup.lookups.territories.create',
                EDIT: 'brainiacCMS://setup.lookups.territories.edit',
            },
            THEME:{
                BROWSE: 'brainiacCMS://setup.lookups.themes.browse',
                CREATE: 'brainiacCMS://setup.lookups.themes.create',
                EDIT: 'brainiacCMS://setup.lookups.themes.edit',
            }
        },
        UTILITIES: {
            BROWSE: 'brainiacCMS://setup.utilities.browse'
        }
    },
    STATIONS: {
        CONFIGURATION: 'brainiacCMS://stations.configuration',
        SEND_DOCUMENT_NOTIFICATIONS: 'brainiacCMS://stations.send-document-notifications',
    },
    TALENT: {
        BROWSE: 'brainiacCMS://talent.browse',
        CREATE: 'brainiacCMS://talent.create',
        EDIT: 'brainiacCMS://talent.edit',
        READ: 'brainiacCMS://talent.read',
        ASSETS: {
            BROWSE: 'brainiacCMS://talent.assets.browse',
            EDIT: 'brainiacCMS://talent.assets.edit'
        },
        TITLES: {
            BROWSE: 'brainiacCMS://talent.titles.browse'
        }
    },
    TITLES: {
        ACCORDIONS: {
            ADDITIONAL_INFO: 'brainiacCMS://title.accordion.additional-info.view',
            ALTERNATIVE_TITLES: 'brainiacCMS://title.accordion.alternative-titles.view',
            LINKS: 'brainiacCMS://title.accordion.links.view',
            LANGUAGES: 'brainiacCMS://title.accordion.languages.view',
            MASTERING_INFO: 'brainiacCMS://title.accordion.mastering-info.view',
            NON_THEATRICAL_RIGHTS: 'brainiacCMS://title.accordion.non-theatrical-rights.view',
            PRODUCTION_DISTRIBUTION: 'brainiacCMS://title.accordion.production-distribution.view',
            RELEASES: 'brainiacCMS://title.accordion.releases.view',
            RIGHTS: 'brainiacCMS://title.accordion.rights.view',
            SYNOPSIS_LOG: 'brainiacCMS://title.accordion.synopsis-log.view'
        },
        BROWSE: 'brainiacCMS://titles.browse',
        CREATE: 'brainiacCMS://titles.create',
        EDIT: 'brainiacCMS://titles.edit',
        LOCALIZED: {
            READ: 'brainiacCMS://titles.localized.read',
            EDIT: 'brainiacCMS://titles.localized.edit'
        },
        MARK_FOR_DELETE: 'brainiacCMS://titles.mark-for-delete',
        READ: 'brainiacCMS://titles.read',
        VIEW_DELETED_SECTION: 'brainiacCMS://titles.view-deleted-titles-section',
        MASS_ADD:{
            UPLOAD: 'brainiacCMS://titles.mass-add.upload',
            BROWSE: 'brainiacCMS://titles.mass-add.browse'
        },
        MASS_UPDATE:{
            UPLOAD: 'brainiacCMS://titles.mass-update.upload'
        },
        METADATA: {
            STATUS: {
                VIEW: 'brainiacCMS://title.metadata.status.view',
                EDIT: 'brainiacCMS://title.metadata.status.edit'
            }
        },
        EDIT_METADATA_IN_TITLE_PAGE: {
            STATUS: {
                VIEW: 'brainiacCMS://title.edit-metadata-in-title-page.status.view',
                EDIT: 'brainiacCMS://title.edit-metadata-in-title-page.status.edit'
            }
        },
        SHOW: {
            MERCHANDISE: 'brainiacCMS://titles.assets.merchandise.browse',
            SUMMARY: 'brainiacCMS://titles.summary.browse',
            SCRIPTS: 'brainiacCMS://titles.assets.scripts.browse',
            STATIONS: 'brainiacCMS://titles.assets.stations.browse',
            USERS: 'brainiacCMS://titles.users.browse',
            LOCAL: 'brainiacCMS://titles.language-catalogs.browse',
            RELATED: 'brainiacCMS://titles.related.browse',
            STYLE: 'brainiacCMS://titles.style.browse',
            TALENT: 'brainiacCMS://titles.talent.browse',
            AUDIO: 'brainiacCMS://titles.assets.audio.browse',
            SUBSCRIPTIONS: 'brainiacCMS://titles.subscriptions.browse',
            DOCUMENTS: 'brainiacCMS://titles.assets.documents.browse',
            IMAGES: 'brainiacCMS://titles.assets.images.browse',
            VIDEOS: 'brainiacCMS://titles.assets.videos.browse',
            EVENTS: 'brainiacCMS://titles.events.browse',
            CATALOGS: 'brainiacCMS://titles.catalogs.browse',
        },
        ASSETS: {
            BROWSE: 'brainiacCMS://titles.assets.browse',
            EDIT: 'brainiacCMS://titles.assets.edit'
        },
        CATALOGS: {
            BROWSE: 'brainiacCMS://titles.catalogs.browse',
            EDIT: 'brainiacCMS://titles.catalogs.edit'
        },
        LANGUAGE_CATALOGS: {
            BROWSE: 'brainiacCMS://titles.language-catalogs.browse',
            EDIT: 'brainiacCMS://titles.language-catalogs.edit'
        },
        RELATED: {
            BROWSE: 'brainiacCMS://titles.related.browse',
            EDIT: 'brainiacCMS://titles.related.edit'
        },
        REPLACE_VIDEO_METADATA: 'brainiacCMS://titles.replace-video-metadata',
        STYLE: {
            BROWSE: 'brainiacCMS://titles.style.browse',
            EDIT: 'brainiacCMS://titles.style.edit'
        },
        SUBSCRIPTIONS: {
            BROWSE: 'brainiacCMS://titles.subscriptions.browse',
            EDIT: 'brainiacCMS://titles.subscriptions.edit',
            USERS: {
                READ: 'brainiacCMS://titles.subscriptions.users.read'
            }
        },
        TALENT: {
            BROWSE: 'brainiacCMS://titles.talent.browse',
            EDIT: 'brainiacCMS://titles.talent.edit'
        },
        USERS: {
            BROWSE: 'brainiacCMS://titles.users.browse'
        },
        WORK_ORDERS: {
            BROWSE: 'brainiacCMS://titles.work-orders.browse',
            CREATE: 'brainiacCMS://titles.work-orders.create',
            EDIT: 'brainiacCMS://titles.work-orders.edit',
            READ: 'brainiacCMS://titles.work-orders.read'
        },
    },
    VIDEO_PLAYBACK_RESTRICTIONS: {
        ONE_IP_ADDRESS: 'api://one-ip-address',
        TWO_IP_ADDRESSES: 'api://two-ip-addresses',
    }
};

class SessionStore extends ReduceStore {

    constructor(dispatcher, permissions) {
        super(dispatcher);
        this.PERMISSIONS = permissions;
        // So you can call instance methods inside a constructor...
        // who would have guessed?
        if (this.isSessionValid()) {
            this.__setInterval(POOLING_INTERVAL);
        }
    }

    __setInterval(poolingInterval) {
        if (__timeoutInterval) {
            return;
        }
        __timeoutInterval = setInterval(() => {
            this.__ticker(new Date(localStorage.__lastRequest));
        }, poolingInterval);
    }

    __clearInterval() {
        if (__timeoutInterval) {
            clearInterval(__timeoutInterval);
            __timeoutInterval = null;
        }
    }

    __ticker(lastRequest) {
        let delta = new Date() - lastRequest;
        if (delta > (config.SessionTimeout*1000)) {
            // reached timeout login out.
            SessionActions.endSession('timeout');
        } else if (delta > (config.SessionTimeoutWarning*1000)) {
            SessionActions.timeoutWarning();
        } else {
            SessionActions.clearTimeoutWarning();
        }
    }

    newSession(uuid) {
        if (!uuid) {
            throw 'Error: SessionStore.newSession uuid can\'t be null';
        }
        localStorage.__token = uuid;
        this.__updateLastRequest();
        this.__setInterval(POOLING_INTERVAL);
    }

    endSession() {
        localStorage.removeItem('__jwt');
        localStorage.removeItem('__lastLoginTimestamp');
        localStorage.removeItem('__lastRequest');
        localStorage.removeItem('__permissions');
        localStorage.removeItem('__token');
        localStorage.removeItem('__user');
        this.__clearInterval();
    }

    clearJWTToken(state) {
        return state.merge({
            __jwt: {
                inProgress: false,
                token: null
            }
        });
    }

    __updateLastRequest() {
        localStorage.__lastRequest = new Date();
    }

    __getToken() {
        return localStorage.__token;
    }

    isExpired() {
        let delta = new Date() - new Date(localStorage.__lastRequest);
        return ( delta < (config.SessionTimeout*1000));
    }

    isSessionValid() {
        if (!localStorage.__token || !localStorage.__lastRequest) {
            return false;
        }
        let delta = new Date() - new Date(localStorage.__lastRequest);
        return ( delta < (config.SessionTimeout*1000));
    }

    getTimeToLogout() {
        let delta = new Date() - new Date(localStorage.__lastRequest);
        let timeToLogout = (config.SessionTimeout*1000) - delta;
        let timeToLogoutValue = 0;
        if (timeToLogout > 0) {
            timeToLogoutValue = timeToLogout;
        }
        return timeToLogoutValue;
    }

    getUserId() {
        return JSON.parse(localStorage.getItem('__user'))?.id;
    }

    sessionIsValid() {
        // check if token exists
        if (!localStorage.__token) {
            return false;
        }

        // check if expired
        return this.isExpired();
    }

    canUser(key) {
        return this.getState().get('permissions').some(p => p.get('url') === key);
    }

    getInitialState() {
        return Immutable.Map({
            // Try to read the permissions and auth user from the localStorage.
            authUser: Immutable.fromJS(JSON.parse(localStorage.getItem('__user')) || {roles: []}),
            defaultHomescreen: localStorage.getItem('__defaultHomescreen'),
            ipv: undefined,
            lastLoginTimestamp: localStorage.getItem('__lastLoginTimestamp'),
            login: Immutable.Map({
                inProgress: false
            }),
            permissions: Immutable.fromJS(JSON.parse(localStorage.getItem('__permissions')) || {}),
            timeoutWarning: false,
            mfaData: Immutable.Map({
                userId: '',
                authorization: '',
                verifyCodeInProgress: false
            }),
            __jwt: Immutable.fromJS({
                inProgress: false,
                token: localStorage.getItem('__jwt')
            }),
        });
    }

    getAuthorization(type) {
        switch (type) {
        case 'jwt':
            const jwt = this.getState().getIn(['__jwt', 'token']);

            // If it's not already requesting a token.
            if (!this.getState().getIn(['__jwt', 'inProgress'])) {
                // Then renew it.
                SessionActions.renewJWT(jwt);
            }

            return jwt;
        default:
            return localStorage.getItem('__token');
        }
    }

    // Return a partner object similar to the ones returned by WTBVD's SessionStore.
    // This is to offer a common interface for SessionStore objects.
    getPartner() {
        return {id: config.WBTVPartnerId};
    }

    hasPermission(permissions, key) {
        return permissions.some(p => p.get('uri') === key || p.get('url') === key);
    }

    hasRole(roleName) {
        return this.getState().getIn(['authUser', 'roles']).some(r => r.get('name') === roleName);
    }

    isServiceAdmin() {
        return this.hasRole('Service Admin');
    }

    // Parse the url into an object, add the Authorization query string param
    // and return the formatted string.
    withAuth(uri) {
        // true to parse the query string object too.
        let urlObject = url.parse(uri, true);
        // Search must be undefined, if not, the format() method
        // ignores the query object.
        delete urlObject.search;
        urlObject.query.Authorization = this.__getToken();
        return url.format(urlObject);
    }

    reduce(state, action) {
        switch (action.actionType) {
        case SessionConstants.CLEAR_TIMEOUT_WARNING:
            state = state.set('timeoutWarning', false);
            break;

        case SessionConstants.GET.AUTH_USER.ROLES.SUCCESS:
            state = state.setIn(['authUser', 'roles'], action.roles);
            // Update the user data in local storage.
            localStorage.setItem('__user', JSON.stringify(state.get('authUser').toJS()));
            break;

        case SessionConstants.GET.AUTH_USER.SUCCESS:
            state = state.set('authUser', action.user);
            // Save them in the local storage to allow reloading while the session
            // is still valid.
            localStorage.setItem('__user', JSON.stringify(action.user.toJS()));
            break;

        case SessionConstants.GET.PERMISSIONS.SUCCESS:
            // Update the state with the authenticated user permissions.
            state = state.merge({
                permissions: action.permissions,
            });
            // Save them in the local storage to allow reloading while the session
            // is still valid.
            localStorage.setItem('__permissions', JSON.stringify(action.permissions.toJS()));
            break;

        case SessionConstants.END_SESSION:
            // Clear data in local storage.
            this.endSession();
            state = this.clearJWTToken(state);
            // And clear the state object.
            state = this.getInitialState();
            break;

        case SessionConstants.LAST_LOGIN.GET.SUCCESS:
            state = state.set('lastLoginTimestamp', action.lastLoginTimestamp);
            localStorage.setItem('__lastLoginTimestamp', action.lastLoginTimestamp);
            break;

        case SessionConstants.LOGIN.START:
            // Clear data in local storage.
            this.endSession();
            state = this.clearJWTToken(state);
            // And clear the state object.
            state = this.getInitialState();
            state = state.setIn(['login', 'inProgress'], true);
            break;

        case SessionConstants.LOGIN.ERROR:
            state = state.merge({
                login: Immutable.Map({
                    inProgress: false
                })
            });
            break;

        case SessionConstants.LOGIN.SUCCESS:
            state = state.merge({
                login: Immutable.Map({
                    inProgress: false
                })
            });
            break;

        case SessionConstants.JWT.GET.ERROR:
            state = state.mergeIn(['__jwt'], {
                inProgress: false,
                token: null
            });
            // Remove old value from localStorage.
            localStorage.removeItem('__jwt');
            break;

        case SessionConstants.JWT.GET.START:
            state = state.mergeIn(['__jwt'], {
                inProgress: true,
                token: null
            });
            // Remove old value from localStorage.
            localStorage.removeItem('__jwt');
            break;

        case SessionConstants.JWT.GET.SUCCESS:
            state = state.mergeIn(['__jwt'], {
                inProgress: false,
                token: action.jwt
            });
            localStorage.setItem('__jwt', action.jwt);
            break;

        // Login user.
        case SessionConstants.NEW_SESSION:
            state = state.merge({
                ipv: action.ipv
            });
            this.newSession(action.uuid);
            break;

        case SessionConstants.GET.MFA_DATA.SUCCESS:
            state = state.merge({
                mfaData: Immutable.Map({
                    userId: action.userId,
                    authorization: action.uuid
                })
            });
            break;

        case SessionConstants.TIMEOUT_WARNING:
            state = state.set('timeoutWarning', true);
            break;

        case SessionConstants.UPDATE:
            this.__updateLastRequest();
            state = state.set('timeoutWarning', false);
            break;

        case SessionConstants.GET.MFA_VERIFY_CODE.ERROR:
            state = state.setIn(['mfaData', 'verifyCodeInProgress'], false);
            break;

        case SessionConstants.GET.MFA_VERIFY_CODE.START:
            state = state.setIn(['mfaData', 'verifyCodeInProgress'], true);
            break;

        case SessionConstants.GET.MFA_VERIFY_CODE.SUCCESS:
            state = state.setIn(['mfaData', 'verifyCodeInProgress'], false);
            break;

        case SessionConstants.SET_DEFAULT_HOMESCREEN:
            state = state.set('defaultHomescreen', action.homepage);
            localStorage.setItem('__defaultHomescreen', action.homepage);
            break;
        }

        return state;
    }
}

export default new SessionStore(Dispatcher, PERMISSIONS);
