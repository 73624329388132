/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import Messages from './messages';
import Summary from './summary';
import DocumentTitle from '../../../common/document-title';
import {Tab, Tabs} from '../../../common/routing-tab/routing-tab';
import ActionsMenu from '../../../layout/actions-menu/actions-menu';
import Preloader from '../../../preloader';
import {RouterActions} from '../../../router/router-actions';
import SessionStore from '../../../user/session/session-store';
import {TimelineActions} from '../../timeline/timeline-actions';
import TimelineStore from '../../timeline/timeline-store';
import {ProcessesConstants /*, ProcessesActions*/} from '../processes-actions';
import ProcessesStore from '../processes-store';

import './process-detail.less';

// for QC Proxy
const filterProcessesTypes = Immutable.List([
    ProcessesConstants.PROCESS_TYPES.BLOB_COPY_LTS.id,
    ProcessesConstants.PROCESS_TYPES.BLOB_COPY_TIMELINE_STEREO.id,
    ProcessesConstants.PROCESS_TYPES.ENCODE_FOR_TIMELINE_STEREO.id,
]);

class ProcessesDetail extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired,
            params: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            permissions: {}
        };
    }

    static getPermissions() {
        return {
            canReadProcesses: SessionStore.canUser(SessionStore.PERMISSIONS.HARDAC.PROCESSES.READ),
            canReadTimeline: SessionStore.canUser(SessionStore.PERMISSIONS.HARDAC.TIMELINE.READ),
        };
    }

    static calculateState() {
        return {
            timeline: TimelineStore.getState().get('timeline'),
            timelineProcesses: TimelineStore.getState().get('processes'),
            reviewProcessesProgress: TimelineStore.getState().get('reviewProcessesProgress'),
            process: ProcessesStore.getState().get('process'),
            progress: ProcessesStore.getState().get('progress'),
            messages: ProcessesStore.getState().get('messages'),
            showPreloader: ProcessesStore.getState().get('showPreloader'),
        };
    }

    static getStores() {
        return [ProcessesStore, TimelineStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            selectedTimelineId: undefined,
        }, this.constructor.calculateState());

        this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount() {
        TimelineActions.findById(this.props.params.timelineId);
        TimelineActions.getProcesses(this.props.params.timelineId, Immutable.List(filterProcessesTypes), true);
        return;
    }

    componentWillUpdate(nextProps) {
        if (this.props.params.timelineId !== nextProps.params.timelineId) {
            TimelineActions.getProcesses(nextProps.params.timelineId, Immutable.List(filterProcessesTypes), true);
        }
        return;
    }

    handleCancel() {
        RouterActions.redirect(`/hardac/timeline/${this.props.params.timelineId}/processes`, true);
    }

    render() {
        const baseRoute = `/hardac/processes/v3/timeline/${this.props.params.timelineId}/status`;

        const summaryTab = (
            <Tab
                route={baseRoute}
                title={this.context.intl.messages['hardac.processes.summary']}
            >
                <Summary
                    reviewProcessesProgress={this.state.reviewProcessesProgress}
                    tasks={filterProcessesTypes}
                    timelineId={this.props.params.timelineId}
                />
            </Tab>
        );

        const messagesTab = (
            <Tab
                route={`${baseRoute}/messages`}
                title={this.context.intl.messages['hardac.processes.messages.title']}
            >
                <Messages
                    timelineId={this.props.params.timelineId}
                    processes={this.state.timelineProcesses}
                    location={this.props.location}
                    permissions={this.props.permissions}
                />
            </Tab>
        );

        const actionsMenu = (
            <ActionsMenu
                onCancel={this.handleCancel}
            />
        );

        return (
            <DocumentTitle
                message={'document-titles.hardac.processes'} entityName={this.state.timeline.get('displayName')}
            >
                <div>
                    <Preloader show={this.state.showPreloader} fixed loadingDots>
                        <section className="content-header">
                            <div className="col-md-6">
                                <h3>
                                    <i className="fas fa-tasks-alt"></i>&nbsp;{this.context.intl.messages['hardac.tasks.timeline-encoding-status']}
                                    <br/><small>{this.state.timeline.get('displayName')}</small>
                                </h3>
                            </div>
                            {actionsMenu}
                        </section>
                        <section className="content" id="contentContainer">
                            <div className="row">
                                <div className="col-lg-12">
                                    <Tabs location={this.props.location}>
                                        {summaryTab}
                                        {messagesTab}
                                    </Tabs>
                                </div>
                            </div>
                        </section>
                    </Preloader>
                </div>
            </DocumentTitle>
        );
    }
}

export default Container.create(ProcessesDetail);
