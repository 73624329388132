import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button} from 'react-bootstrap';

import AccuratePlayer from '../../player/accurate-player';

const BigBuckBunnyURL = 'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4';

export default class DetachedPlayer extends React.Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired
        };
    }
    constructor(props) {
        super(props);

        this.state = {
            videoSrc: this.props.location?.query?.src || BigBuckBunnyURL,
            nextVideoSrc: this.props.location?.query?.src || BigBuckBunnyURL,
        };

        this.handleButton = this.handleButton.bind(this);
        this.handleNextVideoUrl = this.handleNextVideoUrl.bind(this);
    }

    handleButton() {
        window.open(`/hardac/detached-player?src=${this.state.nextVideoSrc}`, '_blank').focus();
        this.setState(/*istanbul ignore next*/(p) => ({
            nextVideoSrc: p.videoSrc,
        }));
    }

    handleNextVideoUrl(e) {
        e.persist();
        this.setState(/*istanbul ignore next*/() => ({
            nextVideoSrc: e.target.value
        }));
    }

    render() {
        const src = Immutable.fromJS({
            dash: this.state.videoSrc
        });


        return <div>
            <AccuratePlayer
                audioProfile={Immutable.List()}
                enableSessionRefresh={false}
                showThumbnails={false}
                src={src}
                video={Immutable.Map()}
                onUpdatePlayer={this.handleUpdatePlayer}
            />

            <br />
            <div className="form-group gray-wrapper col-md-8 col-md-offset-2">
                <div className="col-md-10">
                    <label className="control-label"><i className="fas fa-video"></i> Player Video URL (Dash)</label>
                    <input type="text" value={this.state.nextVideoSrc} className="form-control" onChange={this.handleNextVideoUrl} placeholder={BigBuckBunnyURL} />
                </div>
                <div className="col-md-2">
                    <br />
                    <Button
                        bsSize="md"
                        className="btn btn-success-outline Mr(3px) Mb(3px)"
                        disabled={this.state.videoSrc === this.state.nextVideoSrc || !this.state.nextVideoSrc}
                        onClick={this.handleButton}
                    >
                        <i className="fas fa-external-link"/>&nbsp;Open Tab
                    </Button>
                </div>
            </div>
        </div>;
    }
}
