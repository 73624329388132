/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */


import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import SelectExistingUsers from './select-existing-users';

import SlidingPanel from '~/src/common/sliding-panel/sliding-panel';
import {SlidingPanelActions} from '~/src/common/sliding-panel/sliding-panel-actions';
import {WithStoreOnRoute} from '~/src/common/store-on-route';
import {EventActions} from '~/src/events/event-actions';

class SelectUsersPanel extends Component {
    static get propTypes() {
        return {
            allUsers: PropTypes.instanceOf(Immutable.List).isRequired,
            event: PropTypes.instanceOf(Immutable.Map).isRequired,
            location: PropTypes.object.isRequired,
            selectedUsers: PropTypes.instanceOf(Immutable.List),
        };
    }

    static get defaultProps() {
        return {
            selectedUsers: Immutable.List()
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.handleCancelPanel = this.handleCancelPanel.bind(this);
        this.handleClickAddSelectedItems = this.handleClickAddSelectedItems.bind(this);
        this.simulateCancelButton = this.simulateCancelButton.bind(this);
    }

    componentWillUnmount() {
        this.simulateCancelButton();
    }

    handleCancelPanel() {
        EventActions.clearAddedUsers();
        this.emptyRouteState();
        return;
    }

    handleClickAddSelectedItems() {
        EventActions.addUsersToEvent(this.props.event.get('eventId'), this.props.selectedUsers, this.props.allUsers);
        SlidingPanelActions.hide('selectUsersForEvent');
        EventActions.clearAddedUsers();
        return;
    }

    simulateCancelButton() {
        SlidingPanelActions.hide('selectUsersForEvent');
        EventActions.clearAddedUsers();
    }

    render() {
        const addSelectedItems = (
            <button
                className="btn btn-md btn-success-outline"
                onClick={this.handleClickAddSelectedItems}
            >
                <i className="fas fa-plus"></i>&nbsp;
                {this.context.intl.messages['events.users.manually-select.add-number-users'].replace('{}', this.props.selectedUsers.size)}
            </button>
        );

        const title = <span>{this.context.intl.messages['events.users.manually-select.add-users']}&nbsp;<small>{this.props.event.get('name')}</small></span>;

        return (
            <SlidingPanel
                cancelButtonClassNames="btn-md btn-navy-outline"
                extraButtons={addSelectedItems}
                icon="fas fa-plus-circle"
                id="selectUsersForEvent"
                onCancel={this.handleCancelPanel}
                title={title}
                useH3Header={true}
            >
                <SelectExistingUsers displayLinks={false} location={this.props.location} />
            </SlidingPanel>
        );
    }
}

export default WithStoreOnRoute(SelectUsersPanel);
