/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import ReactTable from 'react-table';

import {AssetTitleActions} from './asset-title-actions';
import {TitleColumns} from './titles';

import Config from '~/src/config/config';

import 'react-table/react-table.css';

class AssignedTable extends Component {
    static get propTypes() {
        return {
            assetId: PropTypes.number,
            assignedTitles: PropTypes.instanceOf(Immutable.List).isRequired,
            displayLinks: PropTypes.bool // eslint-disable-line react/no-unused-prop-types
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            displayLinks: false,
            assetId: 0,
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            id: Math.ceil(Math.random() * 100000)
        };

        this.handleOpenInWBTVD = this.handleOpenInWBTVD.bind(this);
    }

    handleOpenInWBTVD(title) {
        window.open(`${Config.WBTVDBaseUrl}/titles/${title.get('id')}/videos/${this.props.assetId}`);
    }

    handleRemoveTitle(dataIndex) {
        AssetTitleActions.remove(dataIndex);
        return;
    }

    render() {
        let context = this.context;
        let props = this.props;

        const columns = [{
            Header: <strong>{this.context.intl.messages['asset.edit.titles-tab.mpm']}</strong>,
            width: 80,
            Cell: c => (
                <div>{TitleColumns.mpmNumber.get(c.original)}</div>
            )
        }, {
            Header: <strong>{this.context.intl.messages['asset.edit.titles-tab.title']}</strong>,
            Cell: c => (
                <div>{TitleColumns.title.get(c.original, props)}</div>
            )
        }, {
            Header: <strong>{this.context.intl.messages['asset.edit.titles-tab.release-date']}</strong>,
            width: 180,
            Cell: c => (
                <div>{TitleColumns.titleReleaseDate.get(c.original, null, context)}</div>
            )
        }, {
            Header: <strong>{this.context.intl.messages['asset.edit.titles-tab.title-type']}</strong>,
            width: 150,
            Cell: c => (
                <div>{TitleColumns.categoryGroupName.get(c.original)}</div>
            )
        }, {
            Header: <strong>{this.context.intl.messages['asset.edit.titles-tab.wbtvd']}</strong>,
            maxWidth: 64,
            show: this.props.assetId > 0,
            Cell: (c) => (
                <div className="text-center" style={{width: '100%'}}>
                    <a title={this.context.intl.messages['asset.edit.titles-tab.wbtvd.tooltip']} onClick={() => this.handleOpenInWBTVD(c.original)}><i className="fas fa-external-link" /></a>
                </div>
            )
        }, {
            Header: <strong>{this.context.intl.messages['asset.edit.titles-tab.actions']}</strong>,
            width: 105,
            Cell: c => (
                <div>
                    <button className="btn btn-small bg-navy remove-title" onClick={this.handleRemoveTitle.bind(this, c.index)}>
                        <i className="fas fa-times"></i>&nbsp;{this.context.intl.messages['common.remove']}
                    </button>
                </div>
            )
        }];

        return (
            <ReactTable
                className="-striped table-bordered table-striped responsive assigned-titles-table"
                columns={columns}
                data={this.props.assignedTitles}
                getNoDataProps= {() => {
                    if (this.props.assignedTitles.size) {
                        return {style: {display: 'none'}};
                    }
                    return {};
                }}
                id={`assigned-titles-${this.state.id}`}
                loading={false}
                pageSize={this.props.assignedTitles.size}
                resizable={false}
                showPagination={false}
                sortable={false}
            />
        );
    }
}

export default AssignedTable;
