/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {BehaviorSubject} from 'rxjs';

import {ReadonlySubject} from '~/src/common/utils/readonly-subject';

type RFn = () => void;

export function CreateToggleStore(defaultValue = false): [BehaviorSubject<boolean>, (val?: boolean) => void, RFn, RFn] {
    const bs = new BehaviorSubject(defaultValue);
    const readonlySubject = ReadonlySubject(bs);
    const toggle = (value?: boolean) => {
        if (typeof value === 'boolean') {
            bs.next(value);
        } else {
            bs.next(!bs.value);
        }
    };

    const setToTrue = () => bs.next(true);
    const setToFalse = () => bs.next(false);

    return [readonlySubject, toggle, setToTrue, setToFalse];
}

export function CreateSimpleStore<T>(defaultValue: T): [BehaviorSubject<T>, (value: T) => void, RFn] {
    const bs = new BehaviorSubject<T>(defaultValue);
    const readonlySubject = ReadonlySubject(bs);
    const setValue = (value: T) => bs.next(value);
    const reset = () => bs.next(defaultValue);
    return [readonlySubject, setValue, reset];
}

