/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import {ChangeCurrentVttId} from './bl';
import CCEditorActions from './cc-editor-actions';
import CCEditorLayout from './cc-editor-layout';
import CCEditorStore from './cc-editor-store';
import NavigationGuard from './navigation-guard';

import VideoStore from '~/src/assets/video/video-store';
import DocumentTitle from '~/src/common/document-title';
import Preloader from '~/src/preloader';

import './cc-editor.css';

type Props = {
    location: unknown,
    params: CCEditorRouteParams
}

type State = {
    loading: boolean,
    saving: boolean,
    showPlayer: boolean,
    video: ImmutableMap<PlayableVideoAsset> | null,
    vttFiles: Immutable.List<ImmutableMap<VideoWebVtt>>,
}

class CCEditor extends React.PureComponent<Props, State> {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            params: PropTypes.exact({
                assetId: PropTypes.string.isRequired,
                vttId: PropTypes.string.isRequired,
            }).isRequired,
        };
    }

    static calculateState(): State {
        return {
            loading: CCEditorStore.getState().loading,
            saving: CCEditorStore.getState().savingVTT,
            showPlayer: VideoStore.getState().get('showPlayer'),
            video: VideoStore.getState().get('asset'),
            vttFiles: VideoStore.getState().get('webVTTFiles')
        };
    }

    static getStores() {
        return [CCEditorStore, VideoStore];
    }

    state = CCEditor.calculateState();

    componentDidMount() {
        this.reloadVttInfo(this.props.params);
    }

    componentWillUpdate(props: Props) {
        if (this.props.params.vttId !== props.params.vttId) {
            this.reloadVttInfo(props.params);
        }
    }

    private reloadVttInfo(params: CCEditorRouteParams) {
        CCEditorActions.fetchVideoById(params.assetId, params.vttId);
        ChangeCurrentVttId(params.vttId);
    }

    render() {
        let content;
        if (this.state.video && this.state.showPlayer && !this.state.loading) {
            content = (
                <CCEditorLayout
                    location={this.props.location}
                    video={this.state.video}
                />
            );
        }

        return (
            <DocumentTitle message="cc-editor.title">
                <Preloader fixed loadingDots show={this.state.loading || this.state.saving}>
                    {content}

                    <NavigationGuard params={this.props.params} />
                </Preloader>
            </DocumentTitle>
        );
    }
}

export default Container.create(CCEditor);
