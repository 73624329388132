/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ControlLabel} from 'react-bootstrap';

import Summary from './summary';
import RouteValidator from '../../common/custom-validator/route/route';
import DocumentTitle from '../../common/document-title';
import FileInput from '../../common/file-input';
import Panel from '../../common/panel/panel';
import {Tab, Tabs} from '../../common/routing-tab/routing-tab';
import {ErrorsWarnings} from '../../common/validations/errors-warnings';
import ActionsMenu from '../../layout/actions-menu/actions-menu';
import StickyHeader from '../../layout/sticky-header/sticky-header';
import Preloader from '../../preloader';
import {RouterActions} from '../../router/router-actions';
import SessionStore from '../../user/session/session-store';
import {BrandActions} from '../brand-actions';
import BrandStore from '../brand-store';

class Create extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            params: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired,
            route: RouteValidator
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            route: undefined
        };
    }

    static getPermissions() {
        return {
            canCreate: SessionStore.canUser(SessionStore.PERMISSIONS.BRANDS.CREATE),
            canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.BRANDS.EDIT),
        };
    }

    static calculateState() {
        return {
            brand: BrandStore.getState().get('brand'),
            originalBrand: BrandStore.getState().get('originalBrand'),
            showPreloader: BrandStore.getState().get('showPreloader')
        };
    }

    static getStores() {
        return [BrandStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            uploadedBrandLogoImageError: [],
            uploadedBrandLogoImageSrc: null,
        }, this.constructor.calculateState());

        this.handleAddBrandLogo = this.handleAddBrandLogo.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.isDirty = this.isDirty.bind(this);

        return;
    }

    componentDidMount() {
        if (this.props.route.mode === 'edit') {
            BrandActions.findById(this.props.params.id);
        } else {
            BrandActions.clear();
        }

        RouterActions.registerRedirectCheck(() => {
            return this.isDirty();
        });

        return;
    }

    componentWillReceiveProps() {
        RouterActions.registerRedirectCheck(() => {
            return this.isDirty();
        });
    }

    handleAddBrandLogo(e) {
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = (p) => {
            let image = new Image();
            image.src = p.target.result;
            image.size = p.loaded; // filesize in bytes
            image.onload = () => {
                this.setState(() => ({
                    uploadedBrandLogoImageSrc: image.src,
                    uploadedBrandLogoImageError: BrandStore.validateBrandLogoImageSize(image, this.context.intl)
                }));
            };
        };
        BrandActions.update('files', [...e.target.files]);
    }


    handleCancel() {
        RouterActions.redirect('/brands', true);
    }

    handleSave() {
        const brandLogoElement = document.getElementById('brand-logo-file-dummy-input');
        let logoFile;
        if (brandLogoElement) {
            logoFile = brandLogoElement.files[0];
        }

        BrandActions.save(this.state.brand, logoFile);
        return;
    }

    isDirty() {
        if (this.props.route.mode === 'create') {
            return false;
        }

        return !this.state.brand.equals(BrandStore.getState().get('originalBrand'));
    }

    render() {
        let mode = this.props.route.mode;
        let baseRoute, disabled;
        let actionsMenu;
        let validations = BrandStore.getValidations();
        if (mode === 'create') {
            baseRoute = '/brands/create';
            disabled = !this.props.permissions.canCreate;
            actionsMenu = (
                <ActionsMenu
                    canSave={!validations.length && !this.state.uploadedBrandLogoImageError.length}
                    onCancel={this.handleCancel}
                    onSave={this.handleSave}
                    saveText={this.context.intl.messages[`brands.${mode}.save.text`]}
                />
            );
        } else {
            baseRoute = `/brands/${this.props.params.id}`;
            disabled = !this.props.permissions.canEdit;
            actionsMenu = (
                <ActionsMenu
                    canEdit={this.props.permissions.canEdit}
                    canSave={!validations.length && !this.state.uploadedBrandLogoImageError.length}
                    onCancel={this.handleCancel}
                    onSave={this.handleSave}
                    saveText={this.context.intl.messages[`brands.${mode}.save.text`]}
                />
            );
        }
        let entityName;
        let brandLogo;

        const summaryTab = <Tab route={baseRoute} title={this.context.intl.messages['common.tab-title.summary']}>
            <Summary
                brand={this.state.brand}
                disabled={disabled}
                mode={mode}
            />
        </Tab>;

        if (mode === 'edit') {
            const logoSourceUrl = this.state.brand.get('logoSourceS3Path');
            const brandLogoFiles = this.state.brand.get('files', Immutable.List()).map(f => f.name);
            let logo;
            if (logoSourceUrl) {
                logo = (<img src={this.state.brand.get('logoSourceUrl')} className="img-thumbnail" style={{background: 'repeating-conic-gradient(#808080 0% 25%, transparent 0% 50%) 50% / 20px 20px'}} />);
            }
            brandLogo = (
                <Panel
                    collapsible
                    defaultExpanded
                    iconClass="far fa-image"
                    title={this.context.intl.messages['brands.edit.upload.logo.title']}
                >
                    {logo}
                    <ControlLabel>{this.context.intl.messages['brands.edit.upload.logo.label']}</ControlLabel>
                    <FileInput
                        id="brand-logo-file"
                        accept=".png"
                        disabled={disabled}
                        files={brandLogoFiles}
                        onChange={this.handleAddBrandLogo}
                        uploadedImageSrc={this.state.uploadedBrandLogoImageSrc}
                    />
                    <br />
                    <div>
                        <strong>{this.context.intl.messages['brands.edit.upload.logo-requirements']}</strong>
                        <ul>
                            <li>{this.context.intl.messages['brands.edit.upload.logo-requirements.filesize']}</li>
                            <li>{this.context.intl.messages['brands.edit.upload.logo-requirements.extension']}</li>
                            <li>{this.context.intl.messages['brands.edit.upload.logo-requirements.dimensions']}</li>
                        </ul>
                    </div>
                </Panel>
            );
            entityName = this.state.brand.get('name');
        }

        return (
            <DocumentTitle message={'document-titles.brands'} entityName={entityName}>
                <div>
                    <StickyHeader>
                        <div className="col-md-6">
                            <h1>
                                <i className="fas fa-tag"></i>&nbsp;{this.context.intl.messages[`brands.${mode}.title`]}
                                &nbsp;
                                <small>{this.state.brand.get('name')}</small>
                            </h1>
                            <p className="padding-top-20">
                                <em>{this.context.intl.messages[`brands.summary.${mode}`]}</em>
                            </p>
                        </div>
                        {actionsMenu}
                    </StickyHeader>
                    <Preloader show={this.state.showPreloader} fixed loadingDots>
                        <section className="content" id="contentContainer">
                            <div className="row">
                                <div className="col-lg-9">
                                    <Tabs location={this.props.location}>
                                        {summaryTab}
                                    </Tabs>
                                </div>
                                <div className="col-lg-3">
                                    <ErrorsWarnings
                                        title={this.context.intl.messages['common.errors-warnings-title']}
                                        validations={[...validations, ...this.state.uploadedBrandLogoImageError]}
                                    />
                                    {brandLogo}
                                </div>
                            </div>
                        </section>
                    </Preloader>
                </div>
            </DocumentTitle>
        );
    }
}

export {Create as BrandsCreate_BASE};
export default Container.create(Create);
