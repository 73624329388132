/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import AppStyle from '~/src/common/app-styles/app';
import {TitleActions} from '~/src/titles/title-actions';

export default class Style extends React.Component {
    static get propTypes() {
        return {
            disabled: PropTypes.bool,
            title: PropTypes.instanceOf(Immutable.Map).isRequired,
            titleStyle: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: false
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    render() {
        return (
            <div>
                <h3><i className="fa-regular fa-paintbrush-pencil"></i>&nbsp;{this.context.intl.messages['titles.create.style.app-styles']}</h3>
                <AppStyle
                    actions={TitleActions}
                    disabled={this.props.disabled}
                    title={this.props.title}
                    titleStyle={this.props.titleStyle}
                />
            </div>
        );
    }
}
