/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import Table from './table';
import {TalentConstants} from '../../../talent/talent-actions';
import TalentStore from '../../../talent/talent-store';

class Talent extends React.Component {
    static get propTypes() {
        return {
            currentLanguage: PropTypes.string.isRequired,
            disabled: PropTypes.bool,
            title: PropTypes.instanceOf(Immutable.Map).isRequired,
            titleLocalized: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true
        };
    }

    static getStores() {
        return [TalentStore];
    }

    static calculateState() {
        return {
            talentOptions: TalentStore.getState().get('talentList')
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (
            nextProps.disabled !== this.props.disabled ||
            nextProps.title.get('talent') !== this.props.title.get('talent') ||
            nextProps.title.get('id') !== this.props.title.get('id') ||
            nextProps.titleLocalized !== this.props.titleLocalized
        ) {
            return true;
        }

        if (
            this.state.talentOptions !== nextState.talentOptions
        ) {
            return true;
        }

        return false;
    }

    render() {
        const talent = this.props.title.get('talent');

        return (
            <div>
                <h3><i className="fas fa-star"></i>&nbsp;{this.context.intl.messages['titles.create.talent-tab-title']}</h3>
                <hr />

                <Table
                    currentLanguage={this.props.currentLanguage}
                    disabled={false}
                    label={this.context.intl.messages['titles.create.talent.actor']}
                    talent={talent.get(TalentConstants.TALENT_ROLES_TYPES.ACTOR.id.toString()) || Immutable.List()}
                    talentOptions={this.state.talentOptions}
                    talentRoleType={TalentConstants.TALENT_ROLES_TYPES.ACTOR}
                    title={this.props.title}
                    titleLocalized={this.props.titleLocalized}
                />
                <hr />

                <Table
                    currentLanguage={this.props.currentLanguage}
                    disabled={false}
                    label={this.context.intl.messages['titles.create.talent.director']}
                    talent={talent.get(TalentConstants.TALENT_ROLES_TYPES.DIRECTOR.id.toString()) || Immutable.List()}
                    talentOptions={this.state.talentOptions}
                    talentRoleType={TalentConstants.TALENT_ROLES_TYPES.DIRECTOR}
                    title={this.props.title}
                    titleLocalized={this.props.titleLocalized}
                />
                <hr />

                <Table
                    currentLanguage={this.props.currentLanguage}
                    disabled={false}
                    label={this.context.intl.messages['titles.create.talent.producer']}
                    talent={talent.get(TalentConstants.TALENT_ROLES_TYPES.PRODUCER.id.toString()) || Immutable.List()}
                    talentOptions={this.state.talentOptions}
                    talentRoleType={TalentConstants.TALENT_ROLES_TYPES.PRODUCER}
                    title={this.props.title}
                    titleLocalized={this.props.titleLocalized}
                />
                <hr />

                <Table
                    currentLanguage={this.props.currentLanguage}
                    disabled={false}
                    label={this.context.intl.messages['titles.create.talent.voice-talent']}
                    talent={talent.get(TalentConstants.TALENT_ROLES_TYPES.VOICE_TALENT.id.toString()) || Immutable.List()}
                    talentOptions={this.state.talentOptions}
                    talentRoleType={TalentConstants.TALENT_ROLES_TYPES.VOICE_TALENT}
                    title={this.props.title}
                    titleLocalized={this.props.titleLocalized}
                />
                <hr />

                <Table
                    currentLanguage={this.props.currentLanguage}
                    disabled={false}
                    label={this.context.intl.messages['titles.create.talent.host']}
                    talent={talent.get(TalentConstants.TALENT_ROLES_TYPES.HOST.id.toString()) || Immutable.List()}
                    talentOptions={this.state.talentOptions}
                    talentRoleType={TalentConstants.TALENT_ROLES_TYPES.HOST}
                    title={this.props.title}
                    titleLocalized={this.props.titleLocalized}
                />
                <hr />

                <Table
                    currentLanguage={this.props.currentLanguage}
                    disabled={false}
                    label={this.context.intl.messages['titles.create.talent.writer']}
                    talent={talent.get(TalentConstants.TALENT_ROLES_TYPES.WRITER.id.toString()) || Immutable.List()}
                    talentOptions={this.state.talentOptions}
                    talentRoleType={TalentConstants.TALENT_ROLES_TYPES.WRITER}
                    title={this.props.title}
                    titleLocalized={this.props.titleLocalized}
                />
                <hr />

                <Table
                    currentLanguage={this.props.currentLanguage}
                    disabled={false}
                    label={this.context.intl.messages['titles.create.talent.musician']}
                    talent={talent.get(TalentConstants.TALENT_ROLES_TYPES.MUSICIAN.id.toString()) || Immutable.List()}
                    talentOptions={this.state.talentOptions}
                    talentRoleType={TalentConstants.TALENT_ROLES_TYPES.MUSICIAN}
                    title={this.props.title}
                    titleLocalized={this.props.titleLocalized}
                />
                <hr />

                <Table
                    currentLanguage={this.props.currentLanguage}
                    disabled={false}
                    label={this.context.intl.messages['titles.create.talent.choreographer']}
                    talent={talent.get(TalentConstants.TALENT_ROLES_TYPES.CHOREOGRAPHER.id.toString()) || Immutable.List()}
                    talentOptions={this.state.talentOptions}
                    talentRoleType={TalentConstants.TALENT_ROLES_TYPES.CHOREOGRAPHER}
                    title={this.props.title}
                    titleLocalized={this.props.titleLocalized}
                />
                <hr />

                <Table
                    currentLanguage={this.props.currentLanguage}
                    disabled={false}
                    label={this.context.intl.messages['titles.create.talent.executive-producer']}
                    talent={talent.get(TalentConstants.TALENT_ROLES_TYPES.EXECUTIVE_PRODUCER.id.toString()) || Immutable.List()}
                    talentOptions={this.state.talentOptions}
                    talentRoleType={TalentConstants.TALENT_ROLES_TYPES.EXECUTIVE_PRODUCER}
                    title={this.props.title}
                    titleLocalized={this.props.titleLocalized}
                />
                <hr />

                <Table
                    currentLanguage={this.props.currentLanguage}
                    disabled={false}
                    label={this.context.intl.messages['titles.create.talent.guest-star']}
                    talent={talent.get(TalentConstants.TALENT_ROLES_TYPES.GUEST_STAR.id.toString()) || Immutable.List()}
                    talentOptions={this.state.talentOptions}
                    talentRoleType={TalentConstants.TALENT_ROLES_TYPES.GUEST_STAR}
                    title={this.props.title}
                    titleLocalized={this.props.titleLocalized}
                />
                <hr />

                <Table
                    currentLanguage={this.props.currentLanguage}
                    disabled={false}
                    label={this.context.intl.messages['titles.create.talent.author']}
                    talent={talent.get(TalentConstants.TALENT_ROLES_TYPES.AUTHOR.id.toString()) || Immutable.List()}
                    talentOptions={this.state.talentOptions}
                    talentRoleType={TalentConstants.TALENT_ROLES_TYPES.AUTHOR}
                    title={this.props.title}
                    titleLocalized={this.props.titleLocalized}
                />
                <hr />

                <Table
                    currentLanguage={this.props.currentLanguage}
                    disabled={false}
                    label={this.context.intl.messages['titles.create.talent.animated-character']}
                    talent={talent.get(TalentConstants.TALENT_ROLES_TYPES.ANIMATED_CHARACTER.id.toString()) || Immutable.List()}
                    talentOptions={this.state.talentOptions}
                    talentRoleType={TalentConstants.TALENT_ROLES_TYPES.ANIMATED_CHARACTER}
                    title={this.props.title}
                    titleLocalized={this.props.titleLocalized}
                />
                <hr />

                <Table
                    currentLanguage={this.props.currentLanguage}
                    disabled={false}
                    label={this.context.intl.messages['titles.create.talent.misc-crew']}
                    talent={talent.get(TalentConstants.TALENT_ROLES_TYPES.MISC_CREW.id.toString()) || Immutable.List()}
                    talentOptions={this.state.talentOptions}
                    talentRoleType={TalentConstants.TALENT_ROLES_TYPES.MISC_CREW}
                    title={this.props.title}
                    titleLocalized={this.props.titleLocalized}
                />
                <hr />

                <Table
                    currentLanguage={this.props.currentLanguage}
                    disabled={false}
                    label={this.context.intl.messages['titles.create.talent.creator']}
                    talent={talent.get(TalentConstants.TALENT_ROLES_TYPES.CREATOR.id.toString()) || Immutable.List()}
                    talentOptions={this.state.talentOptions}
                    talentRoleType={TalentConstants.TALENT_ROLES_TYPES.CREATOR}
                    title={this.props.title}
                    titleLocalized={this.props.titleLocalized}
                />
            </div>
        );
    }
}

export default Container.create(Talent);
