/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

import Messages from './messages';
import Summary from './summary';
import DocumentTitle from '../../../common/document-title';
import {Tab, Tabs} from '../../../common/routing-tab/routing-tab';
import ActionsMenu from '../../../layout/actions-menu/actions-menu';
import Preloader from '../../../preloader';
import {RouterActions} from '../../../router/router-actions';
import SessionStore from '../../../user/session/session-store';
import {ProcessesActions} from '../processes-actions';
import ProcessesStore from '../processes-store';

class ProcessesDetail extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            permissions: PropTypes.object,
            params: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            errorMessage: ProcessesStore.getState().get('errorMessage'),
            process: ProcessesStore.getState().get('process'),
            showPreloader: ProcessesStore.getState().get('showPreloader'),
        };
    }

    static getStores() {
        return [ProcessesStore];
    }

    static get defaultProps() {
        return {
            permissions: {}
        };
    }

    static getPermissions() {
        return {
            canRead: SessionStore.canUser(SessionStore.PERMISSIONS.HARDAC.PROCESSES.READ),
        };
    }

    constructor(props) {
        super(props);

        this.handleCancel = this.handleCancel.bind(this);
        this.handleReprocess = this.handleReprocess.bind(this);
    }

    componentDidMount() {
        ProcessesActions.findById(this.props.params.id);
    }

    handleCancel() {
        RouterActions.redirect('/hardac/processes', true);
    }

    handleReprocess() {
        ProcessesActions.reprocessProcessById(this.props.params.id);
    }

    render() {
        const entityName = this.state.process.get('processType');

        const baseRoute = `/hardac/processes/${this.props.params.id}`;

        const summaryTab = (
            <Tab
                route={baseRoute}
                title={this.context.intl.messages['hardac.processes.summary']}
            >
                <Summary
                    errorMessage={this.state.errorMessage}
                    process={this.state.process}
                />
            </Tab>
        );

        const messagesTab = (
            <Tab
                route={`${baseRoute}/messages`}
                title={this.context.intl.messages['hardac.processes.messages.title']}
            >
                <Messages
                    processId={this.props.params.id}
                    location={this.props.location}
                    permissions={this.props.permissions}
                />
            </Tab>
        );

        let reprocessButton;
        if (!this.state.process.get('processComplete')) {
            reprocessButton = (
                <Button
                    bsSize="large"
                    className="btn btn-primary-outline Mr(3px) Mb(3px)"
                    onClick={this.handleReprocess}
                >
                    <i className="fas fa-redo"></i>&nbsp;{this.context.intl.messages['hardac.processes.reprocess']}
                </Button>
            );
        }

        const actionsMenu = (
            <ActionsMenu
                onCancel={this.handleCancel}
            >
                {reprocessButton}
            </ActionsMenu>
        );

        return (
            <DocumentTitle
                message={'document-titles.hardac.processes'} entityName={entityName}
            >
                <div>
                    <Preloader show={this.state.showPreloader} fixed loadingDots>
                        <section className="content-header">
                            <div className="col-md-6">
                                <h3>
                                    <i className="fas fa-eye"></i>&nbsp;{this.context.intl.messages['hardac.processes.details.title']}
                                    <br/><small>{this.state.process.get('processType')}</small>
                                </h3>
                                <p className="padding-top-10">
                                    <em>{this.context.intl.messages['hardac.processes.details.title']}</em>
                                </p>
                            </div>
                            {actionsMenu}
                        </section>
                        <section className="content" id="contentContainer">
                            <div className="row">
                                <div className="col-lg-12">
                                    <Tabs location={this.props.location}>
                                        {summaryTab}
                                        {messagesTab}
                                    </Tabs>
                                </div>
                            </div>
                        </section>
                    </Preloader>
                </div>
            </DocumentTitle>
        );
    }
}

export default Container.create(ProcessesDetail);
