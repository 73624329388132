/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Checkbox} from 'react-bootstrap';
import Select from 'react-select';

import {FormItem} from '../../common/form/form';
import Panel from '../../common/panel/panel';
import {WithStoreOnRoute} from '../../common/store-on-route';
import {GetAttr} from '../../common/utils/utils';
import {LayoutActions} from '../../layout/layout-actions';
import {EventActions, EventConstants} from '../event-actions';
import EventStore from '../event-store';

export const noop = () => void 0;

const CreatedByOptions = WithStoreOnRoute(class CreatedByOptions extends Component {
    static get propTypes() {
        return {
            onChange: PropTypes.func,
            options: PropTypes.instanceOf(Immutable.List).isRequired,
            selected: PropTypes.string
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            onChange: () => noop,
            selected: undefined
        };
    }

    render() {
        let selected = this.props.selected;
        if (selected) {
            selected = this.props.options.toJS().find((item) => item.name === selected);
        }
        // This is hacky, but if we pass undefined to Select value sometimes the internal state won't get cleared
        if (selected === undefined) {
            selected = null;
        }

        return (
            <Panel title="Created By" classTitle="box-title" collapsible defaultExpanded>
                <div className="form-group">
                    <Select
                        classNamePrefix="dropdown"
                        getOptionLabel={GetAttr('name')}
                        getOptionValue={GetAttr('id')}
                        isClearable={true}
                        isMulti={false}
                        name="created-by"
                        onChange={this.props.onChange}
                        options={this.props.options.toJS()}
                        placeholder={this.context.intl.messages['common.select.placeholder']}
                        value={selected}
                    />
                </div>
            </Panel>
        );
    }
});

const EndDateOptions = WithStoreOnRoute(class EndDateOptions extends Component {
    static get propTypes() {
        return {
            filters: PropTypes.instanceOf(Immutable.Map),
            location: PropTypes.object,
            onChange: PropTypes.func
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            filters: undefined,
            location: undefined,
            onChange: undefined
        };
    }

    constructor(props) {
        super(props);

        this.setFormatDate = this.setFormatDate.bind(this);
    }

    setFormatDate(attr, value) {
        if (this.props.location) {
            this.setRouteState(attr, value)
                .setRouteState('operator', 'AND')
                .clearRouteState('offset')
                .apply();
            return;
        }

        this.props.onChange(attr, value);
    }

    render() {
        let model = this.props.filters;
        if (this.props.location) {
            model = this.getRouteState();
        }
        return (
            <Panel title={this.context.intl.messages['filter-options.end-date']} classTitle="box-title" collapsible defaultExpanded>
                <div className="box-body date-picker-filter">
                    <FormItem
                        label={this.context.intl.messages['filter-options.date.from']}
                        attr="begin-to-date"
                        datepicker={{popoverTargetOffset: '10px -36px', showYearDropdown: true}}
                        model={model}
                        setter={this.setFormatDate}
                        type="date"
                    />
                    <FormItem
                        label={this.context.intl.messages['filter-options.date.to']}
                        attr="end-to-date"
                        datepicker={{popoverTargetOffset: '10px -36px', showYearDropdown: true}}
                        model={model}
                        setter={this.setFormatDate}
                        type="date"
                    />
                </div>
            </Panel>
        );
    }
});

const EventTypeOptions = WithStoreOnRoute(class EventTypeOptions extends Component {
    static get propTypes() {
        return {
            onChange: PropTypes.func,
            selected: PropTypes.number
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            onChange: undefined,
            selected: undefined
        };
    }

    constructor(props) {
        super(props);

        this.dataMessage = this.dataMessage.bind(this);
    }

    dataMessage(data) {
        return this.context.intl.messages[data.message];
    }

    render() {
        let selected = this.props.selected;
        const eventTypeOptions = [];
        //BRAIN-3907: update to filter out the not yet implemented event types
        Object.keys(EventConstants.EVENT_TYPES).filter(key =>
            EventConstants.EVENT_TYPES[key]?.id === EventConstants.EVENT_TYPES.FYC_ADMIN_EVENT.id ||
            EventConstants.EVENT_TYPES[key]?.id === EventConstants.EVENT_TYPES.FYC_SCREENING.id ||
            EventConstants.EVENT_TYPES[key]?.id === EventConstants.EVENT_TYPES.PRESS_SCREENING.id ||
            EventConstants.EVENT_TYPES[key]?.id === EventConstants.EVENT_TYPES.BAFTA_EVENT.id ).map((option) => (
            eventTypeOptions.push(EventConstants.EVENT_TYPES[option])
        ));

        if (selected) {
            selected = eventTypeOptions.find((item) => item.id === selected);
        }

        // This is hacky, but if we pass undefined to Select value sometimes the internal state won't get cleared
        if (selected === undefined) {
            selected = null;
        }

        return (
            <Panel title="Event Type" classTitle="box-title" collapsible defaultExpanded>
                <div data-style="display: block;" className="box-body">
                    <div className="form-group">
                        <Select
                            classNamePrefix="dropdown"
                            getOptionLabel={this.dataMessage}
                            getOptionValue={GetAttr('id')}
                            isClearable={true}
                            isMulti={false}
                            name="event-type"
                            onChange={this.props.onChange}
                            options={eventTypeOptions}
                            placeholder={this.context.intl.messages['common.select.placeholder']}
                            value={selected}
                        />
                    </div>
                </div>
            </Panel>
        );
    }
});

const StartDateOptions = WithStoreOnRoute(class StartDateOptions extends Component {
    static get propTypes() {
        return {
            filters: PropTypes.instanceOf(Immutable.Map),
            location: PropTypes.object,
            onChange: PropTypes.func
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            filters: undefined,
            location: undefined,
            onChange: undefined
        };
    }

    constructor(props) {
        super(props);

        this.setFormatDate = this.setFormatDate.bind(this);
    }

    setFormatDate(attr, value) {
        if (this.props.location) {
            this.setRouteState(attr, value)
                .setRouteState('operator', 'AND')
                .clearRouteState('offset')
                .apply();
            return;
        }

        this.props.onChange(attr, value);
    }

    render() {
        let model = this.props.filters;
        if (this.props.location) {
            model = this.getRouteState();
        }
        return (
            <Panel title={this.context.intl.messages['filter-options.start-date']} classTitle="box-title" collapsible defaultExpanded>
                <div className="box-body date-picker-filter">
                    <FormItem
                        label={this.context.intl.messages['filter-options.date.from']}
                        attr="begin-from-date"
                        datepicker={{popoverTargetOffset: '10px -36px', showYearDropdown: true}}
                        model={model}
                        setter={this.setFormatDate}
                        type="date"
                    />
                    <FormItem
                        label={this.context.intl.messages['filter-options.date.to']}
                        attr="end-from-date"
                        datepicker={{popoverTargetOffset: '10px -36px', showYearDropdown: true}}
                        model={model}
                        setter={this.setFormatDate}
                        type="date"
                    />
                </div>
            </Panel>
        );
    }
});

const StatusTypeOptions = WithStoreOnRoute(class StatusTypeOptions extends Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.isChecked = this.isChecked.bind(this);
        this.getCheckboxes = this.getCheckboxes.bind(this);
    }

    handleChange(status, event) {
        if (event.target.checked) {
            this.addToSetRoute('status', status)
                .setRouteState('operator', 'AND')
                .clearRouteState('offset')
                .apply();
        } else {
            this.removeFromSetRoute('status', status)
                .clearRouteState('offset')
                .clearRouteState('operator')
                .apply();
        }
    }

    isChecked(status) {
        let eventStatus = this.props.location.query.status;
        if (!eventStatus) {
            return false;
        }
        if (!Array.isArray(eventStatus)) {
            // single string
            return eventStatus.toString() === status.toString();
        } else {
            // is in array
            return eventStatus.indexOf(status.toString()) !== -1;
        }
    }

    getCheckboxes() {
        let checkboxes = Object.keys(EventConstants.STATUS_TYPES).map(statusName => {
            let status = EventConstants.STATUS_TYPES[statusName];
            return (
                <Checkbox
                    key={statusName}
                    checked={this.isChecked(status.id)}
                    onChange={this.handleChange.bind(this, status.id)}>
                    {this.context.intl.messages[status.description]}
                </Checkbox>
            );
        }).filter(c=>{if (c) {return c;}});

        return checkboxes;
    }

    render() {
        const checkboxes = this.getCheckboxes();
        return (
            <Panel title="Status" classTitle="box-title" collapsible defaultExpanded>
                <div className="filtering-options">
                    <div className="form-group">
                        {checkboxes}
                    </div>
                </div>
            </Panel>
        );
    }
});

const userTypeOptions = [];
//BRAIN-3907: update to filter out the not yet implemented account type
Object.keys(EventConstants.ACCOUNT_TYPES).filter(key => EventConstants.ACCOUNT_TYPES[key]?.id !== EventConstants.ACCOUNT_TYPES.TOKENIZED_CHILD.id).map((option) => (
    userTypeOptions.push(EventConstants.ACCOUNT_TYPES[option])
));
const UserTypeOptions = WithStoreOnRoute(class UserTypeOptions extends Component {
    static get propTypes() {
        return {
            onChange: PropTypes.func,
            selected: PropTypes.number
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            onChange: undefined,
            selected: undefined
        };
    }

    render() {
        let selected = this.props.selected;
        if (selected) {
            selected = userTypeOptions.find((item) => item.id === selected);
        }

        // This is hacky, but if we pass undefined to Select value sometimes the internal state won't get cleared
        if (selected === undefined) {
            selected = null;
        }

        return (
            <Panel title="User Type" classTitle="box-title" collapsible defaultExpanded>
                <div data-style="display: block;" className="box-body">
                    <div className="form-group">
                        <Select
                            classNamePrefix="dropdown"
                            getOptionLabel={data => this.context.intl.messages[data.message]}
                            getOptionValue={data => data.id}
                            isClearable={true}
                            isMulti={false}
                            name="user-type"
                            onChange={this.props.onChange}
                            options={userTypeOptions}
                            placeholder={this.context.intl.messages['common.select.placeholder']}
                            value={selected}
                        />
                    </div>
                </div>
            </Panel>
        );
    }
});

class FilterOptions extends Component {

    static get propTypes() {
        return {
            createdByOptions: PropTypes.instanceOf(Immutable.List),
            filters: PropTypes.instanceOf(Immutable.Map),
            location: PropTypes.object,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            createdByOptions: Immutable.List(),
            filters: undefined,
            location: undefined
        };
    }

    constructor(props) {
        super(props);

        this.handleToggleFiltersPanel = this.handleToggleFiltersPanel.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.filters !== this.props.filters ||
            nextProps.location !== this.props.location ||
            nextProps.createdByOptions !== this.props.createdByOptions) {
            return true;
        }

        return false;
    }

    handleToggleFiltersPanel() {
        LayoutActions.toggleFiltersPanel();
    }

    onChange(attr, value) {
        EventActions.setFilter(attr, value);
        EventActions.setFilter('operator', 'AND');
        EventActions.get(
            EventStore.getState().get('filters'),
            0,
            20
        );
    }

    onSelectChange(attr, selected) {
        const selectedId = selected?.id || undefined;
        if (this.props.location) {
            this.setRouteState(attr, selectedId)
                .setRouteState('operator', 'AND')
                .clearRouteState('offset')
                .apply();
            return;
        }
        EventActions.setFilter(attr, selectedId);
        EventActions.setFilter('operator', 'AND');
        EventActions.get(
            EventStore.getState().get('filters'),
            0,
            20
        );
    }

    render() {
        let userType, eventType, createdBy;
        if (this.props.location) {
            userType = this.props.location.query['user-type'];
            eventType = this.props.location.query['event-type'];
            createdBy = this.props.location.query['created-by'];
        } else {
            userType = EventStore.getState().getIn(['filters', 'user-type', 'id']) || -1 ;
            eventType = EventStore.getState().getIn(['filters', 'event-type', 'id']) || -1 ;
            createdBy = EventStore.getState().getIn(['filters', 'created-by', 'id']) || undefined ;
        }

        return (
            <div>
                <aside className="control-sidebar B(0) Ovx(h) Ovy(a)">
                    <div className="tab-content">
                        <p>
                            <button className="btn btn-block bg-navy" onClick={this.handleToggleFiltersPanel}>
                                <i className="fas fa-chevron-right pull-right"></i>&nbsp;{this.context.intl.messages['filter-options.close-options.button']}
                            </button>
                        </p>
                        <StartDateOptions
                            filters={this.props.filters}
                            location={this.props.location}
                            onChange={this.onChange}
                        />
                        <EndDateOptions
                            filters={this.props.filters}
                            location={this.props.location}
                            onChange={this.onChange}
                        />
                        <CreatedByOptions
                            onChange={this.onSelectChange.bind(this, 'created-by')}
                            options={this.props.createdByOptions}
                            selected={createdBy}
                        />
                        <StatusTypeOptions
                            location={this.props.location}
                        />
                        <UserTypeOptions
                            selected={userType}
                            onChange={this.onSelectChange.bind(this, 'user-type')}
                        />
                        <EventTypeOptions
                            selected={eventType}
                            onChange={this.onSelectChange.bind(this, 'event-type')}
                        />
                    </div>
                </aside>
                <div style={{position: 'fixed', height: 'auto'}} className="control-sidebar-bg"></div>
            </div>
        );
    }
}

export default WithStoreOnRoute(FilterOptions);

export {
    CreatedByOptions,
    EndDateOptions,
    EventTypeOptions,
    StartDateOptions,
    StatusTypeOptions,
    UserTypeOptions,
};
