/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React from 'react';

import CuesList from './cues-list';
import CCEditorHeader from './header';
import Settings from './settings';
import {GenerateRootRoute} from '../helpers/route';

import {Tabs, Tab} from '~/src/common/routing-tab/routing-tab';

type Props = {
    location: unknown,
    video: ImmutableMap<PlayableVideoAsset>,
    vtt: VideoWebVtt,
}

export default class CCEditorContent extends React.PureComponent<Props> {
    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    render() {
        const rootRoute = GenerateRootRoute(this.props.vtt);
        return (
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <CCEditorHeader
                    video={this.props.video}
                />

                <div className="padding-y-20 padding-x-20">
                    <Tabs location={this.props.location}>
                        <Tab
                            route={rootRoute}
                            title={this.context.intl.messages['cc-editor.tab-title.captions']}
                        >
                            <CuesList
                                frameRate={this.props.video.get('frameRate')}
                            />
                        </Tab>
                        <Tab
                            route={`${rootRoute}/settings`}
                            title={this.context.intl.messages['cc-editor.tab-title.settings']}
                        >
                            <Settings video={this.props.video} />
                        </Tab>
                    </Tabs>
                </div>
            </div>
        );
    }
}
