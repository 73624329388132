/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import QueryString from 'querystring-es3';
import React, {PureComponent} from 'react';

import Pagination from '~/src/common/table/pagination';
import {PagesProps, PagesState} from '~/src/hardac/machine-learning/paging/types';
import {RouterActions} from '~/src/router/router-actions';

const DEFAULT_PAGE_SIZE = 20;

class Paging extends PureComponent<PagesProps, PagesState> {
    constructor(props: PagesProps) {
        super(props);

        const query = QueryString.parse(
            props.location.search?.replace('?', '') ?? ''
        );

        let offset;

        if (Array.isArray(query.offset)) {
            offset = query?.offset[0];
        } else {
            offset = query?.offset;
        }

        this.state = {
            pageNumber: Math.trunc((Number(offset) || 0) / 20),
        };
    }

    handlePageChange = (pageNumber: number) => {
        const query = QueryString.parse(
            this.props.location.search?.replace('?', '') ?? ''
        );

        query.offset = ((pageNumber || 0) * DEFAULT_PAGE_SIZE).toString() || '';

        RouterActions.redirect(
            `${this.props.location.pathname}?${QueryString.stringify(query)}`
        );

        this.setState({pageNumber});
    };

    render() {
        const totalItemsCount = this.props.totalItems;
        const totalPages = Math.ceil(totalItemsCount/DEFAULT_PAGE_SIZE);
        return (
            <Pagination
                onChange={this.handlePageChange}
                activePage={this.state.pageNumber}
                totalPages={totalPages}
            />
        );
    }
}

export default Paging;
