/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */


import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ControlLabel, FormControl, FormGroup} from 'react-bootstrap';

import BaseSelect from '../../../common/base-select/base-select';
import {FormItem, FormRow, FormSection} from '../../../common/form/form';
import config from '../../../config/config.js';
import {PartnerActions} from '../../../partner/partner-actions';
import PartnerStore, {PartnerStoreValidations} from '../../../partner/partner-store';

class Summary extends Component {

    static get propTypes() {
        return {
            disabled: PropTypes.bool,
            mode: PropTypes.string.isRequired,
            partner: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            selectedPlatformType: PartnerStore.getPlatformType(this.props.partner.get('platformType')),
            selectedUsageType: PartnerStore.getUsageType(this.props.partner.get('usageType'))
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState(() => ({
            selectedPlatformType: PartnerStore.getPlatformType(nextProps.partner.get('platformType')),
            selectedUsageType: PartnerStore.getUsageType(nextProps.partner.get('usageType'))
        }));
    }

    handleSelectPlatformType(value) {
        PartnerActions.updatePartner('platformType', Immutable.fromJS(value?.id));
    }

    handleSelectUsageType(value) {
        PartnerActions.updatePartner('usageType', Immutable.fromJS(value?.id));
    }

    render() {
        let additionalInformation;
        let readOnly = this.props.disabled;
        let security;

        if (this.props.mode === 'edit') {
            let lastModifier = '-';
            let updatedDate = '';

            if (this.props.partner.get('updatedDate')) {
                updatedDate = Moment(this.props.partner.get('updatedDate'))
                    .tz(config.DefaultTimezone)
                    .format(this.context.intl.messages['datetime-format']);
            }

            if (this.props.partner.get('updatedBy')) {
                lastModifier = this.props.partner.get('updatedBy');
            }

            additionalInformation = <FormRow>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['authorized-partners.edit.last-modified-date']}</ControlLabel>
                    <FormControl.Static>{updatedDate}</FormControl.Static>
                </FormGroup>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['authorized-partners.edit.last-modifier']}</ControlLabel>
                    <FormControl.Static>{lastModifier}</FormControl.Static>
                </FormGroup>
            </FormRow>;

            readOnly = true;

            security = <FormSection title={this.context.intl.messages['authorized-partners.create.security.title']} iconClass="fas fa-key">
                <FormRow>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['authorized-partners.create.security']}</ControlLabel>
                        <FormControl.Static>{this.props.partner.get('clientKey')}</FormControl.Static>
                    </FormGroup>
                </FormRow>
            </FormSection>;
        }

        return (
            <div className="tab-pane padding-x-20 padding-bottom-20">
                <FormSection title={this.context.intl.messages['authorized-partners.create.info.title']} iconClass="fas fa-info-circle">
                    <FormRow>
                        <FormItem attr="name"
                            disabled={readOnly}
                            label={this.context.intl.messages['authorized-partners.create.info.name']}
                            model={this.props.partner}
                            onlySpaces
                            placeholder={this.context.intl.messages['authorized-partners.create.info.name.placeholder']}
                            setter={PartnerActions.updatePartner}
                            type="name"
                            validations={PartnerStoreValidations.name.validations}
                        />
                        <FormItem attr="displayName"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['authorized-partners.create.info.display']}
                            model={this.props.partner}
                            onlySpaces
                            placeholder={this.context.intl.messages['authorized-partners.create.info.display.placeholder']}
                            setter={PartnerActions.updatePartner}
                            type="text"
                        />
                    </FormRow>
                    <FormRow>
                        <FormItem attr="clientId"
                            disabled={readOnly}
                            label={this.context.intl.messages['authorized-partners.create.info.client']}
                            model={this.props.partner}
                            onlySpaces
                            placeholder={this.context.intl.messages['authorized-partners.create.info.client.placeholder']}
                            setter={PartnerActions.updatePartner}
                            type="text"
                            validations={PartnerStoreValidations.clientId.validations}
                        />
                    </FormRow>
                    <FormRow>
                        <FormItem attr="defaultSiteUrl"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['authorized-partners.create.info.default-site-url']}
                            model={this.props.partner}
                            onlySpaces
                            placeholder={this.context.intl.messages['authorized-partners.create.info.default-site-url.placeholder']}
                            setter={PartnerActions.updatePartner}
                            type="text"
                            validations={PartnerStoreValidations.defaultSiteUrl.validations}
                        />
                    </FormRow>
                    <FormRow>
                        <FormItem attr="signature"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['authorized-partners.create.info.signature']}
                            model={this.props.partner}
                            onlySpaces
                            placeholder={this.context.intl.messages['authorized-partners.create.info.signature.placeholder']}
                            setter={PartnerActions.updatePartner}
                            type="text"
                        />
                    </FormRow>
                    <FormRow>
                        <FormItem attr="description"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['authorized-partners.create.info.description']}
                            model={this.props.partner}
                            placeholder={this.context.intl.messages['authorized-partners.create.info.description.placeholder']}
                            setter={PartnerActions.updatePartner}
                            type="textarea"
                            validations={PartnerStoreValidations.description.validations}
                        />
                    </FormRow>
                    <FormRow>
                        <FormItem attr="defaultCopyright"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['authorized-partners.create.info.copyright']}
                            model={this.props.partner}
                            onlySpaces
                            placeholder={this.context.intl.messages['authorized-partners.create.info.copyright.placeholder']}
                            setter={PartnerActions.updatePartner}
                            type="text"
                        />
                    </FormRow>
                    <FormRow>
                        <FormItem
                            attr="sessionTimeoutDuration"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['authorized-partners.create.info.session-timeout']}
                            md={5}
                            model={this.props.partner}
                            setter={PartnerActions.updatePartner}
                            type="number"
                        />
                        <FormItem
                            attr="sessionLifespan"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['authorized-partners.create.info.session-life-span']}
                            md={5}
                            model={this.props.partner}
                            setter={PartnerActions.updatePartner}
                            type="number"
                        />
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['common.status']}</ControlLabel>
                            <FormItem
                                attr="active"
                                disabled={this.props.disabled}
                                label={this.context.intl.messages['authorized-partners.create.info.active']}
                                model={this.props.partner}
                                setter={PartnerActions.updatePartner}
                                type="checkbox"
                            />
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <FormItem attr="cdnBillingCode"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['authorized-partners.create.info.cdn-billing-code']}
                            model={this.props.partner}
                            setter={PartnerActions.updatePartner}
                            type="text"
                        />
                    </FormRow>
                    <hr />
                    {additionalInformation}
                </FormSection>
                <FormSection title={this.context.intl.messages['authorized-partners.create.info.platform']} iconClass="fas fa-tv-retro">
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['authorized-partners.create.info.platform.type']}</ControlLabel>
                            <BaseSelect
                                classNamePrefix="dropdown"
                                disabled={this.props.disabled}
                                name={this.context.intl.messages['authorized-partners.create.info.platform.type']}
                                onChange={this.handleSelectPlatformType}
                                options="platformType"
                                store={PartnerStore}
                                value={this.state.selectedPlatformType}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['authorized-partners.create.info.platform.usage']}</ControlLabel>
                            <BaseSelect
                                classNamePrefix="dropdown"
                                disabled={this.props.disabled}
                                name={this.context.intl.messages['authorized-partners.create.info.platform.usage']}
                                onChange={this.handleSelectUsageType}
                                options="usageType"
                                store={PartnerStore}
                                value={this.state.selectedUsageType}
                            />
                        </FormGroup>
                    </FormRow>
                </FormSection>
                {security}
            </div>
        );
    }

}

export default Summary;
