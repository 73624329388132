/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';
import Moment from 'moment';

import {AlertTypes} from '../../common/notification/alert';
import {NotificationActions} from '../../common/notification/notification-actions';
import config from '../../config/config.js';
import Dispatcher from '../../dispatcher/dispatcher';
import {PreloaderActions} from '../../preloader/preloader-actions';
import Request from '../../request';
import {RouterActions} from '../../router/router-actions';
import {TitleActions} from '../title-actions';

// Require for Proper Timezone Display
require('moment-timezone');
let configtz = Moment().tz(config.DefaultTimezone).format('ZZ');

const CONSTANTS = {
    ADD_TITLES: 'title.work-orders.add-titles',
    ADD_TITLES_HIDE: 'title.work-orders.add-titles-hide',
    ADD_TITLES_SHOW: 'title.work-orders.add-titles-show',
    CLEAR:'title.work-orders.clear',
    EXPORT_FALLBACK_ENGLISH: [
        {id: 0, name: 'titles.work-orders.summary.export-fallback-english.off'},
        {id: 1, name: 'titles.work-orders.summary.export-fallback-english.on'}
    ],
    EXPORT_LANGUAGES: [
        {id: 'EL', name: 'Greek'},
        {id: 'EN', name: 'English'},
        {id: 'FR', name: 'French'},
        {id: 'FR_CA', name: 'French (Canadian)'},
        {id: 'GER', name: 'German'},
        {id: 'ITA', name: 'Italian'},
        {id: 'JPN', name: 'Japanese'},
        {id: 'KO', name: 'Korean'},
        {id: 'POR', name: 'Portuguese (Euro)'},
        {id: 'POR_BR', name: 'Portuguese (Brazil)'},
        {id: 'SPA', name: 'Spanish (Castilian)'},
        {id: 'SPA_LAT', name: 'Spanish Latin America'},
        {id: 'TR', name: 'Turkish'},
        {id: 'ZH_HANS', name: 'Chinese'},
    ],
    EXPORT_SPECIFICATION: [
        {id: 1, name: 'Generic'},
        {id: 2, name: 'Netflix'},
        {id: 3, name: 'Mass Update Template'},
        {id: 4, name: 'Amazon'},
        {id: 5, name: 'Hulu'},
        {id: 6, name: 'Tubi'}
    ],
    EXPORT_SPECIFICATION_BY_NAME: {
        MASS_UPDATE: 3
    },
    FIND_BY_ID: {
        ERROR: 'title.work-orders.find-by-id.error',
        START: 'title.work-orders.find-by-id.start',
        SUCCESS: 'title.work-orders.find-by-id.success',
        RECIPIENT_USER: 'title.work-orders.find-by-id.recipient_user'
    },
    GET: {
        SUCCESS: 'title.work-orders.get.success',
        START:'title.work-orders.get.start',
        ERROR:'title.work-orders.get.error'
    },
    PAGE_SIZE: 20,
    REMOVE_OK: 'title.work-orders.remove.ok',
    REMOVE_TITLE: 'title.work-orders.remove.title',
    REORDER: 'title.work-orders.reorder',
    SAVE: {
        SUCCESS: 'title.work-orders.save.success',
        ERROR:'title.work-orders.save.error'
    },
    STATUS: [
        {id:1, name: 'Active', icon: 'fas fa-play-circle text-wb-blue'},
        {id:2, name: 'Complete', icon: 'fas fa-check-circle text-green'},
        {id:3, name: 'Canceled', icon: 'fas fa-minus-circle text-red'},
        {id:4, name: 'On Hold', icon: 'fas fa-pause-circle'},
        {id:5, name: 'Archived', icon: 'fas fa-archive'}
    ],
    STATUS_BY_NAME: {
        ACTIVE: 1,
        COMPLETE: 2,
        CANCELED: 3,
        ONHOLD: 4,
        ARCHIVED: 5
    },
    UPDATE: 'title.work-orders.update'
};

const TITLE_BATCH = 20;

class WorkOrdersActions {

    addTitles(ids) {
        PreloaderActions.show('work-orders-actions.addTitles');
        let batches = [];
        while (ids.length) {
            batches.push(ids.splice(0, TITLE_BATCH));
        }
        return Promise.all(batches.map(r => Request.get('title').query({'title-id': r}).exec()))
            .then(titles => {
                titles = titles.reduce( (arr, r) => {
                    return arr.concat(r.body.results);
                }, []);
                PreloaderActions.hide('work-orders-actions.addTitles');
                Dispatcher.dispatch({
                    actionType: CONSTANTS.ADD_TITLES,
                    titles: Immutable.fromJS(titles.map(t => TitleActions.addCategory(t)))
                });
            }).catch(err => {
                PreloaderActions.hide('work-orders-actions.addTitles');
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'titles.work-orders.export.add-titles.error');
                throw err;
            });
    }

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
    }

    findById(id) {
        PreloaderActions.show('work-orders-actions.findById');
        Dispatcher.dispatch({
            actionType: CONSTANTS.FIND_BY_ID.START,
            id:id
        });
        Promise.all([
            Request.get(`work-order/${id}`).exec(),
            Request.get(`work-order/${id}/title`).exec()
        ]).spread((woRes, woeRes) => {
            let wo = Immutable.fromJS(woRes.body);

            let ordered = wo.get('workOrderTitles').sortBy(wot => wot.get('displayOrder'));
            let idToPosition = ordered.reduce((map, elem, i) => {
                map[elem.get('titleId')] = i;
                return map;
            }, {});

            let woe = Immutable.fromJS(woeRes.body.workOrderTitlesExport);
            if (!woe) {
                woe = Immutable.List();
            }
            // FIXME: remove sort when API sorts things the right way.
            woe = woe.sort((a, b) => {
                let posA = idToPosition[a.get('titleId')];
                let posB = idToPosition[b.get('titleId')];

                return posA - posB;
            });

            PreloaderActions.hide('work-orders-actions.findById');
            Dispatcher.dispatch({
                actionType: CONSTANTS.FIND_BY_ID.SUCCESS,
                workOrder: wo,
                workOrderTitles: woe
            });
            if (wo.get('recipientUserId')) {
                return Request.get(`user/${wo.get('recipientUserId')}`).exec();
            }
            return;
        }).then(userResponse => {
            if (!userResponse) {
                return;
            }
            Dispatcher.dispatch({
                actionType: CONSTANTS.FIND_BY_ID.RECIPIENT_USER,
                recipientUser: Immutable.fromJS(userResponse.body)
            });
        }).catch(err => {
            PreloaderActions.hide('work-orders-actions.findById');
            Dispatcher.dispatch({
                actionType: CONSTANTS.FIND_BY_ID.ERROR,
                err: err
            });
            if (err.status === 404 && err.response.error.url.indexOf(id) !== -1) {
                RouterActions.notFound();
            } else {
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
            }
            throw err;
        });
    }

    hide() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ADD_TITLES_HIDE
        });
    }

    get(queryParams) {
        queryParams = queryParams.toJS();
        PreloaderActions.show('work-orders-actions.get');
        if (queryParams.q) {
            queryParams.name = queryParams.q;
            delete queryParams.q;
        }

        queryParams.offset = queryParams.offset || 0;
        queryParams.size = queryParams.size || CONSTANTS.PAGE_SIZE ;

        Request.get('work-order')
            .query(queryParams)
            .exec()
            .then(res => {
                PreloaderActions.hide('work-orders-actions.get');
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET.SUCCESS,
                    workOrders: Immutable.fromJS(res.body.results),
                    total: parseInt(res.header['wbtv-total-count'], 10)
                });
                return;
            })
            .catch(err => {
                PreloaderActions.hide('work-orders-actions.get');
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
                throw err;
            });
        return;
    }

    reorder(from, to) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.REORDER,
            from,
            to
        });
    }

    remove(id) {
        PreloaderActions.show('work-orders-actions.remove');
        Request.del(`work-order/${id}`)
            .exec()
            .then(() => {
                PreloaderActions.hide('work-orders-actions.remove');
                Dispatcher.dispatch({
                    actionType: CONSTANTS.REMOVE_OK,
                });

                RouterActions.redirect('/titles/work-orders');
                NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'titles.work-orders.edit.remove.success');
                return;
            })
            .catch(() => {
                PreloaderActions.hide('work-orders-actions.remove');
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'titles.work-orders.edit.remove.error');
                return;
            });
    }
    removeTitle(index) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.REMOVE_TITLE,
            index,
        });
    }
    save(workOrder, workOrderTitles) {
        PreloaderActions.show('work-orders-actions.save');
        let workOrderData = workOrder.toJS();

        workOrderData.workOrderTitles = workOrderTitles.toJS().map((t, i) => {
            return {
                titleId: t.titleId,
                workOrderId: t.workOrderId,
                displayOrder: i
            };
        });
        if (workOrderData.dueDate) {
            workOrderData.dueDate = Moment(workOrderData.dueDate);
            if (workOrderData.dueDate.isValid()) {
                workOrderData.dueDate = workOrderData.dueDate.format('YYYY-MM-DDT00:00:00.000'+configtz);
            }
        }
        let method = Request.post;
        let uri = 'work-order';
        let message = 'titles.work-orders.create.success';

        // Defaults needsArtWork to false if undefined/null
        workOrderData.needsArtWork = workOrderData.needsArtWork || false;

        let id = workOrderData.id;
        if (id !== undefined) {
            method = Request.put;
            uri = `work-order/${id}`;
            message = 'titles.work-orders.edit.success';
        }

        method(uri)
            .send(workOrderData)
            .exec()
            .then(res => {
                PreloaderActions.hide('work-orders-actions.save');
                if (!id) {
                    RouterActions.redirect(`/titles/work-orders/${res.body.id}`, true);
                } else {
                    Dispatcher.dispatch({
                        actionType: CONSTANTS.SAVE.SUCCESS,
                        workOrder,
                        workOrderTitles
                    });
                }
                NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, message);
                return;
            })
            .catch(err => {
                PreloaderActions.hide('work-orders-actions.save');
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'titles.work-orders.save.error');
                throw err;
            });
        return;
    }

    show(title) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ADD_TITLES_SHOW,
            title
        });
    }

    update(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.UPDATE,
            attr,
            value
        });
        return;
    }
}

let actions = new WorkOrdersActions();

export {
    actions as WorkOrdersActions,
    CONSTANTS as WorkOrdersConstants
};
