/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';
import {FormattedMessage} from 'react-intl';

import UploadCaptionsModal from './upload-captions-modal';
import {CurrentVttFile$, IsMasteringVttFile$, ShowUploadModal} from '../../bl';
import NoBrBlock from '../common/nobr-block';
import TitledBlock from '../common/titled-block';

import WithRxSubscriptions from '~/src/decorators/with-rx-subscriptions';

type Props = {
    isMasteringVttFile: boolean,
    vtt: ImmutableMap<VideoWebVtt> | undefined
}

function VttUploadBlock(props: Props) {
    const filename = props.vtt?.get('s3Path')?.split('/').pop() || '';
    const href = props.vtt?.get('externalUrl') as string;
    return (
        <div>
            <TitledBlock title="cc-editor.vtt.field.filename" icon="far fa-file-code">
                <p>
                    {filename}
                </p>
            </TitledBlock>

            <TitledBlock title="cc-editor.tab.settings.import-export" icon="far fa-file-export">
                <NoBrBlock>
                    <button
                        className="btn btn-primary-outline"
                        disabled={props.isMasteringVttFile}
                        onClick={ShowUploadModal}
                    >
                        <i className="fas fa-upload"/>
                        &nbsp;
                        <FormattedMessage id="cc-editor.tab.settings.import"/>
                    </button>
                </NoBrBlock>
                <NoBrBlock>
                    <a
                        className="btn btn-primary-outline"
                        href={href}
                        download={filename}
                    >
                        <i className="fas fa-download"/>
                        &nbsp;
                        <FormattedMessage id="cc-editor.tab.settings.export"/>
                    </a>
                </NoBrBlock>

                <UploadCaptionsModal />
            </TitledBlock>
        </div>
    );
}

export default WithRxSubscriptions(VttUploadBlock, {
    isMasteringVttFile: IsMasteringVttFile$,
    vtt: CurrentVttFile$
});

