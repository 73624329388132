/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Checkbox, Col, ControlLabel, Grid, FormControl, FormGroup, Row, InputGroup} from 'react-bootstrap';

import DatePicker from './date-picker';
import SectionHeader from './section-header';
import {FramesToTimecodeString} from '../utils/timecode';
import {Debounce} from '../utils/utils';
import {WithValidations} from '../validations/validations';

// Require datepicker styles.
import 'react-datepicker/dist/react-datepicker.css';

const EVENT_BLUR = 'blur';
const EVENT_CHANGE = 'change';

let FormItem = WithValidations(class FormItem extends Component {
    static get displayName() {
        return 'FormItem';
    }

    static get propTypes() {
        return {
            addonBefore: PropTypes.node,
            attr: PropTypes.string.isRequired,
            children: PropTypes.element,
            datepicker: PropTypes.object,
            disabled: PropTypes.bool,
            firstFrame: PropTypes.number,
            frameRate: PropTypes.number,
            label: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.string.isRequired]),
            legend: PropTypes.any, // Changed from element since we check typeof below anyway
            localizedModel: PropTypes.object,
            model: PropTypes.object.isRequired,
            noLabel: PropTypes.bool,
            notifyEvent: PropTypes.oneOf([EVENT_BLUR, EVENT_CHANGE]),
            onBlur: PropTypes.func,
            onChange: PropTypes.func,
            placeholder: PropTypes.string,
            regex: PropTypes.instanceOf(RegExp),
            setter: PropTypes.func.isRequired,
            showErrorMessage: PropTypes.bool,
            showErrors: PropTypes.bool,
            type: PropTypes.string,
            validatedSetter: PropTypes.func,
            validations: PropTypes.array
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            addonBefore: undefined,
            children: undefined,
            datepicker: {},
            disabled: undefined,
            firstFrame: undefined,
            frameRate: undefined,
            label: undefined,
            legend: undefined,
            localizedModel: undefined,
            noLabel: undefined,
            notifyEvent: EVENT_CHANGE,
            onChange: () => void 0,
            onBlur: () => void 0,
            placeholder: undefined,
            regex: undefined,
            showErrors: undefined,
            showErrorMessage: false,
            type: undefined,
            validatedSetter: () => void 0,
            validations: []
        };
    }


    constructor(props) {
        super(props);

        let value = this.readValue(this.props);

        this.state = {
            dirty: false,
            value,
            validatedValue: value
        };

        this.readValue = this.readValue.bind(this);
        this.getInputType = this.getInputType.bind(this);
        this.handleChange = this.handleChange.bind(this);
        if (props.notifyEvent === EVENT_BLUR) {
            this.notifyChange = this.notifyChange.bind(this);
        } else {
            this.notifyChange = Debounce(this.notifyChange.bind(this), 400);
        }
        this.handleBlur = this.handleBlur.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        let value = this.readValue(nextProps);

        if (this.state.value !== value) {
            this.setState({
                value,
                validatedValue: value
            });
        }

        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.validations !== nextProps.validations ||
            this.props.disabled !== nextProps.disabled) {
            return true;
        }

        if (this.state.dirty !== nextState.dirty ||
            this.state.value !== nextState.value ||
            this.state.validatedValue !== nextState.validatedValue) {
            return true;
        }

        return false;
    }

    /**
     * Read the input value from the provided properties.
    */
    readValue(props) {
        let value;
        switch (props.type) {
        case 'date':
            value = props.model.getIn(props.attr.split('.'));
            if (value === undefined) {
                return undefined;
            }
            value = Moment(value);
            if (!value.isValid()) {
            // Really special case!
            // The React-DatePicker component needs the value to be undefined.
                return undefined;
            }
            break;
        case 'timecode':
            let f = props.model.getIn(props.attr.split('.'));
            if (this.props.firstFrame) {
                f += this.props.firstFrame;
            }
            value = FramesToTimecodeString(f, this.props.frameRate);
            break;
        // Default type is also text.
        case 'text':
        default:
            value = props.model.getIn(props.attr.split('.'));
            break;
        }
        // React-Bootstrap warning: (first if condition)
        // Warning: `value` prop on `input` should not be null.
        // Consider using the empty string to clear the component
        // or `undefined` for uncontrolled components.
        //
        // React-Bootstrap warning v2: (second if condition)
        // FormControl is changing a uncontrolled input of type text
        // to be controlled. Input elements should not switch from
        // uncontrolled to controlled (or vice versa).
        // Decide between using a controlled or uncontrolled input
        // element for the lifetime of the component.
        if (value === null || value === undefined) {
            value = '';
        }
        return value;
    }

    getInputType() {
        let type;
        switch (this.props.type) {
        case 'email':
        case 'name':
            type = 'text';
            break;
        default:
            type = this.props.type;
            break;
        }
        return type;
    }

    handleBlur(event) {
        let value = event.target.value;

        // update the input
        this.setState({
            value
        });

        if (this.props.notifyEvent === EVENT_BLUR) {
            this.notifyChange(value); // notify the model
        }

        return;
    }

    handleChange(event) {
        let value;

        switch (this.props.type) {
        case 'checkbox':
            // Really hate checkboxes.
            value = event.target.checked;
            break;
        case 'bool-checkbox':
            // If you hate checkboxes, imagine now that we're gonna make it boolean.
            value = +event.target.checked;
            break;
        case 'date':
            // Datepicker sends a Moment object.
            if (event) {
                value = event.format('YYYY-MM-DD');
            }
            break;
        case 'time':
            if (event) {
                value = event.format('hh:mm a');
            }
            break;
        default:
            value = event.target.value;
            break;
        }

        // update the input.
        this.setState({
            value
        });

        if (this.props.notifyEvent === EVENT_CHANGE) { // if it is, for example, onBlur, just set state without notifying changes
            this.notifyChange(value); // notify the model. Note that this function is debounced.
        }

        return;
    }

    notifyChange(value) {
        this.waitForValidations(this.state.value, true, this.props.validations)
            .then(() => {
                this.setState({
                    validatedValue: value
                });

                this.props.validatedSetter(this.props.attr, true);
            })
            // Validation canceled, prevent warning
            .catch(() => void 0);

        this.props.setter(this.props.attr, value);

        switch (this.props.notifyEvent) {
        case EVENT_BLUR:
            this.props.onBlur(value);
            break;
        case EVENT_CHANGE:
        default:
            this.props.onChange(value);
        }

        this.setState({
            dirty: true
        });

        this.props.validatedSetter(this.props.attr, false);

        return;
    }

    render() {
        let label;
        let legend = this.props.legend;
        let type = this.getInputType();
        let validations = this.props.validations;

        if (!this.props.noLabel) {
            label = this.props.label;
            if (validations.some(r => r.type === 'required' || r.type === 'missing')) {
                label = <span>{this.props.label}&nbsp;<span className="text-red">*</span></span>;
            }
            label = <ControlLabel>{label}</ControlLabel>;
        }

        if (typeof legend === 'string') {
            legend = (
                <small>
                    <em>{legend}</em>
                </small>
            );
        }

        let formControl = <FormControl
            disabled={this.props.disabled}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            placeholder={this.props.placeholder}
            type={type}
            value={this.state.value}
        />;

        if (this.props.addonBefore) {
            formControl = <InputGroup>
                <InputGroup.Addon>{this.props.addonBefore}</InputGroup.Addon>
                {formControl}
            </InputGroup>;
        }

        switch (type) {
        case 'checkbox':
            label = null;
            let checked = '';
            if (this.state.value) {
                checked = 'checked';
            }

            formControl = <Checkbox
                checked={checked}
                disabled={this.props.disabled}
                onChange={this.handleChange}>
                {this.props.label}
            </Checkbox>;
            break;
        case 'bool-checkbox':
            label = null;
            let boolChecked = 0;
            if (this.state.value) {
                boolChecked = 1;
            }
            formControl = <Checkbox
                checked={boolChecked}
                disabled={this.props.disabled}
                onChange={this.handleChange}>
                {this.props.label}
            </Checkbox>;
            break;

        case 'date':
        case 'time':
            /**
             * TODO: Configure popover placement
             * https://hacker0x01.github.io/react-datepicker/#example-14
             * And re-style the clear button.
             */
            let selectedValue = this.state.value;
            if ( selectedValue && !Moment.isMoment(selectedValue)) {
                selectedValue = Moment(this.state.value);
                if (!selectedValue.isValid()) {
                    selectedValue = null;
                }
            }
            formControl = <DatePicker
                className={ClassNames('form-control', {'addon-padding': this.props.addonBefore})}
                disabled={this.props.disabled}
                onChange={this.handleChange}
                placeholderText={this.context.intl.messages['common.please-select']}
                selected={selectedValue}
                {...this.props.datepicker}
            />;
            if (this.props.addonBefore) {
                formControl = <div className="datetime-picker">
                    {this.props.addonBefore}
                    {formControl}
                </div>;
            }
            break;

        case 'select':
            formControl = <FormControl
                componentClass="select"
                disabled={this.props.disabled}
                onChange={this.handleChange}
                placeholder={this.props.placeholder}
                value={this.state.value}>
                {this.props.children}
            </FormControl>;
            break;

        case 'textarea':
            formControl = <FormControl
                componentClass="textarea"
                disabled={this.props.disabled}
                onChange={this.handleChange}
                placeholder={this.props.placeholder}
                value={this.state.value}/>;
            break;


        case 'timecode':
            formControl = <FormControl // timecode's form-control triggers only with onBlur() event
                disabled={this.props.disabled}
                onBlur={this.handleBlur}
                onChange={this.handleChange} // just updates the value without triggering any further state changes
                notifyEvent={this.props.notifyEvent}
                placeholder={this.props.placeholder}
                type={type}
                value={this.state.value}
            />;
            break;

        default:
            // This is here to avoid overriding of selects (because
            // they have children too and will enter here).
            if (this.props.children) {
                formControl = this.props.children;
            }
            break;
        }

        let errorMessageToShow;
        let errorMessage = this.getValidationError(this.state.value, this.state.dirty, validations, this.state.validatedValue);
        if (errorMessage && this.props.showErrorMessage) {
            errorMessageToShow = (<p className="text-red">{errorMessage}</p>);
        }

        return (
            <FormGroup className={ClassNames({'no-margin': this.props.noLabel && this.props.localizedModel === undefined})} validationState={this.getValidationState(this.state.value, this.state.dirty, validations, this.state.validatedValue)}>
                {label}
                {formControl}
                {legend}
                {errorMessageToShow}
            </FormGroup>
        );
    }
});

class LocalizedFormItem extends Component {

    static get propTypes() {
        return {
            attr: PropTypes.string.isRequired,
            handleShowDescriptionModal: PropTypes.func,
            localizedAttr: PropTypes.string,
            localized: PropTypes.bool,
            localizedModel: PropTypes.object,
            localizedSetter: PropTypes.func.isRequired,
            model: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            handleShowDescriptionModal: undefined,
            localized: false,
            localizedModel: undefined,
            localizedAttr: undefined
        };
    }

    render() {
        let item = <FormItem {...this.props}/>;

        let viewFullDescriptionLink;
        if (this.props.handleShowDescriptionModal) {
            viewFullDescriptionLink = (
                <small>
                    <a className="clickable" onClick={this.props.handleShowDescriptionModal}>
                        {this.context.intl.messages['common.handleClick.description']}
                    </a>
                </small>
            );
        }

        if (this.props.localized) {
            item = (
                <div className="active-field">
                    <FormItem
                        {...this.props}
                        attr={this.props.localizedAttr || this.props.attr}
                        model={this.props.localizedModel}
                        setter={this.props.localizedSetter}
                    />
                    <div className="hint">
                        <small>
                            <strong>English:</strong>
                        </small>
                        &nbsp;<small className="mce-locale-description active-field" dangerouslySetInnerHTML={{__html: this.props.model.getIn(this.props.attr.split('.')) || ' - '}}></small>
                        {viewFullDescriptionLink}
                    </div>
                </div>
            );
        }
        return item;
    }
}

const GRID_SIZE=12;

class FormRow extends Component {
    static get propTypes() {
        return {
            additionalClass: PropTypes.string,
            children: PropTypes.oneOfType([
                PropTypes.array,
                PropTypes.object,
                PropTypes.string
            ]),
        };
    }

    static get defaultProps() {
        return {
            additionalClass: '',
            children: undefined
        };
    }

    render() {
        let fixedSizeItems = 0;
        let reserved = 0;
        let currentAutoChild = 0;
        let childCount = React.Children.count(this.props.children);
        React.Children.map(this.props.children, function(child) {
            if (child.props.md) {
                reserved += child.props.md;
                fixedSizeItems += 1;
            }
        });
        let autoSizeItems = childCount - fixedSizeItems;
        let total = reserved;
        return (
            <Row className={ClassNames(this.props.additionalClass)}>
                {React.Children.map(this.props.children, function(child) {
                    let calculatedSize = child.props.md;
                    // if autoSizeItems == 0 all children should have defined
                    // lengths and this should not be executed, but if someone
                    // have messed up with the counts
                    // lets avoid a division by zero error
                    if (!calculatedSize && autoSizeItems > 0) {
                        currentAutoChild += 1;
                        // last autochild, get the reminder
                        if (currentAutoChild === autoSizeItems) {
                            calculatedSize = GRID_SIZE - total;
                        } else {
                            // Integer division :)
                            calculatedSize = ((GRID_SIZE - reserved)/autoSizeItems) >> 0;
                            total += calculatedSize;
                        }
                    }
                    return <Col md={calculatedSize }>{child}</Col>;
                })}
            </Row>
        );
    }
}

class FormSection extends Component {
    static get propTypes() {
        return {
            iconClass: PropTypes.string,
            title: PropTypes.node,
            titleLevel: PropTypes.number,
            children: PropTypes.oneOfType([
                PropTypes.array,
                PropTypes.object,
                PropTypes.string
            ])
        };
    }

    static get defaultProps() {
        return {
            iconClass: undefined,
            title: undefined,
            titleLevel: 3,
            children: undefined
        };
    }

    render() {
        return (
            <div>
                <SectionHeader
                    iconClass={this.props.iconClass}
                    level={this.props.titleLevel}
                    title={this.props.title}
                />
                <Grid className="form-section" fluid>
                    {this.props.children}
                </Grid>
            </div>
        );
    }
}

export {
    EVENT_CHANGE,
    EVENT_BLUR,
    FormSection,
    FormRow,
    FormItem,
    LocalizedFormItem,
};
