/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Modal} from 'react-bootstrap';

import {FormItem, FormRow, FormSection} from '../form/form';
import Validations from '../validations/validations';


class BulkAddModal extends React.Component {
    static get propTypes() {
        return {
            canSave: PropTypes.bool,
            generatesAdminCodes: PropTypes.bool,
            note: PropTypes.string,
            onBulkAddAccounts: PropTypes.func.isRequired,
            onCheckChanges: PropTypes.func,
            onHide: PropTypes.func.isRequired,
            onSave: PropTypes.func,
            show: PropTypes.bool.isRequired,
            showAdminAccountsCheck: PropTypes.bool
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            canSave: true,
            generatesAdminCodes: false,
            note: undefined,
            onCheckChanges: () => {false;},
            onSave: void 0,
            showAdminAccountsCheck: true,
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            model: Immutable.fromJS({
                bulkAddAdmin: false || props.generatesAdminCodes,
                bulkAddCount: null
            }),
            showChangesConfirmation: false
        };

        this.handleBulkAddAccounts = this.handleBulkAddAccounts.bind(this);
        this.handleHide = this.handleHide.bind(this);
        this.handleSaveAndBulkAdd = this.handleSaveAndBulkAdd.bind(this);
        this.handleToggleShowChangesConfirmation = this.handleToggleShowChangesConfirmation.bind(this);
        this.handleValueChange = this.handleValueChange.bind(this);
        this.isValid = this.isValid.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.generatesAdminCodes !== prevProps.generatesAdminCodes) {
            this.handleValueChange('bulkAddAdmin', this.props.generatesAdminCodes);
        }
    }

    handleValueChange(attr, value) {
        this.setState(prevState => {
            return {model: prevState.model.setIn([attr], value)};
        });
    }

    handleBulkAddAccounts(event, ignoreSave) {
        if (this.props.onCheckChanges() && !ignoreSave) {
            this.handleToggleShowChangesConfirmation();
        } else {
            this.props.onBulkAddAccounts(this.state.model.get('bulkAddCount'), this.state.model.get('bulkAddAdmin'));
            this.handleHide();
        }
    }

    handleHide() {
        if (this.props.show) {
            this.props.onHide();
        }

        setTimeout(() => {
            this.setState({
                model: Immutable.fromJS({
                    bulkAddAdmin: this.props.generatesAdminCodes || false,
                    bulkAddCount: null
                }),
                showChangesConfirmation: false
            });
        }, 1000);
    }

    handleToggleShowChangesConfirmation() {
        this.setState(prevState => {
            return {
                showChangesConfirmation: !prevState.showChangesConfirmation
            };
        });
        this.props.onHide();
    }

    handleSaveAndBulkAdd() {
        this.props.onSave();
        this.handleBulkAddAccounts(undefined, true);
    }

    isValid() {
        const count = this.state.model.get('bulkAddCount');
        return Validations.required.validate(count) && Validations.custom(
            () => this.context.intl.messages['common.accounts.bulk_generate.modal.count.error'],
            () => Number(this.state.model.get('bulkAddCount')) > 0
        ).validate(count);
    }

    render() {
        let adminAccountsCheck;
        if (this.props.showAdminAccountsCheck) {
            adminAccountsCheck = <FormRow>
                <FormItem
                    attr={'bulkAddAdmin'}
                    disabled={this.props.generatesAdminCodes}
                    label={this.context.intl.messages['common.accounts.bulk_generate.modal.admin']}
                    model={this.state.model}
                    setter={this.handleValueChange}
                    type="checkbox"
                />
            </FormRow>;
        }
        let note;
        if (this.props.note) {
            note = <p>{this.props.note}</p>;
        }

        let modalTitle = this.context.intl.messages['common.accounts.bulk_generate.modal.title'];
        let inputLabel = this.context.intl.messages['common.accounts.bulk_generate.modal.count'];

        if (this.props.generatesAdminCodes) {
            modalTitle = this.context.intl.messages['events.users.bulk_add.modal.title'];
            inputLabel = this.context.intl.messages['events.users.bulk_add.modal.count'];
        }

        return (
            <div>
                <Modal onHide={this.handleHide} show={this.props.show}>
                    <Modal.Header className="bg-gray" closeButton>
                        <Modal.Title className="text-center">
                            {modalTitle}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {note}
                        <FormSection>
                            <FormRow>
                                <FormItem
                                    attr={'bulkAddCount'}
                                    label={inputLabel}
                                    model={this.state.model}
                                    setter={this.handleValueChange}
                                    type="number"
                                    showErrorMessage={true}
                                    validations={[Validations.required, Validations.custom(
                                        () => this.context.intl.messages['common.accounts.bulk_generate.modal.count.error'],
                                        () => Number(this.state.model.get('bulkAddCount')) > 0
                                    )]}
                                />
                            </FormRow>
                            {adminAccountsCheck}
                        </FormSection>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button className="pull-left Ml(5)" onClick={this.handleHide}>
                            {this.context.intl.messages['common.cancel']}
                        </Button>
                        <Button bsStyle="primary" type="submit"
                            disabled={!this.isValid()}
                            onClick={this.handleBulkAddAccounts}>
                            {this.context.intl.messages['common.accounts.bulk_generate.modal.ok']}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal onHide={this.handleToggleShowChangesConfirmation} show={this.state.showChangesConfirmation}>
                    <Modal.Header className="alert-success" closeButton>
                        <Modal.Title className="text-center">
                            {this.context.intl.messages['common.accounts.bulk_generate.confirm.title']}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>{this.context.intl.messages['common.accounts.bulk_generate.confirm.message']}</p>
                        <br/>
                        <p>{this.context.intl.messages['common.accounts.bulk_generate.confirm.secondary-message']}</p>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button className="pull-left Ml(5)" onClick={this.handleToggleShowChangesConfirmation}>
                            {this.context.intl.messages['common.cancel']}
                        </Button>
                        <Button bsStyle="primary" onClick={this.handleBulkAddAccounts.bind(this, true)}>
                            {this.context.intl.messages['common.accounts.bulk_generate.confirm.ignore']}
                        </Button>
                        <Button bsStyle="success" type="submit"
                            disabled={!this.props.canSave}
                            onClick={this.handleSaveAndBulkAdd}>
                            {this.context.intl.messages['common.accounts.bulk_generate.confirm.save']}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default BulkAddModal;
