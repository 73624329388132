{
    "accounts.applicants.bulk-approve.confirm-button": "Confirm",
    "accounts.applicants.bulk-approve.error": "Unable to approve the applicants, please try again.",
    "accounts.applicants.bulk-approve.error.assign-permissions": "Unable to Approve Applicants, please assign Permissions",
    "accounts.applicants.bulk-approve.error.user": "Error approving applicant: {}.",
    "accounts.applicants.bulk-approve.selected-count": "applicant(s) selected for approval.",
    "accounts.applicants.bulk-approve.success": "The following applicants have been approved: {}",
    "accounts.applicants.bulk-approve.title": "Please Confirm Approval",
    "accounts.applicants.bulk-deny.deny-button": "Deny",
    "accounts.applicants.bulk-deny.error": "Unable to deny all applicants, please try again.",
    "accounts.applicants.bulk-deny.error.user": "Error denying applicant: {}.",
    "accounts.applicants.bulk-deny.selected-count": "applicant(s) selected for denial.",
    "accounts.applicants.bulk-deny.success": "The following applicants have been denied: {}",
    "accounts.applicants.bulk-deny.title": "Please Confirm Denial",
    "accounts.applicants.title": "Applicant Queue",
    "accounts.applicants.total": "New Applicants",
    "accounts.create.activation-settings.send-email.info-text": "Once the profile is approved, the Client Rep will be sent an email with the activation link to provide to the client, which MUST be used by the client to complete activation. You can also use the password reset link on the Management tab.",
    "accounts.create.activation-settings.send-email.info-title": "If Box Is NOT Checked: ",
    "accounts.create.activation-settings.send-email": "Send Activation Email",
    "accounts.create.activation-settings": "Activation Settings",
    "accounts.create.add-applicant": "Add Applicant",
    "accounts.create.applicant.error": "Unable to create Applicant Profile, please try again",
    "accounts.create.applicant.success": "Applicant Profile created",
    "accounts.create.applicant.title": "Create Applicant",
    "accounts.create.approve-deny.add-note-confirm": "Add Note & Confirm",
    "accounts.create.approve-deny.approve-title": "Please Confirm Approval",
    "accounts.create.approve-deny.approve-warning-title": "Approval Error",
    "accounts.create.approve-deny.confirm-approve": "Confirm",
    "accounts.create.approve-deny.continue": "Continue Anyway?",
    "accounts.create.approve-deny.deny-reasons": "Please add a note explaining the reason for denial:",
    "accounts.create.approve-deny.deny-title": "Please Confirm Denial",
    "accounts.create.approve-deny.flags": "The following flags apply to this account:",
    "accounts.create.approve-deny.no-flags-message": "No flags apply to this account.",
    "accounts.create.approve-deny": "Approve / Deny",
    "accounts.create.companyIsNew" : "This will add a new Company to the User Company List",
    "accounts.create.management-tab-title": "Management",
    "accounts.create.management.active-sessions": "Active Sessions",
    "accounts.create.management.active-sessions.date-time": "Date/Time",
    "accounts.create.management.active-sessions.device-name": "Device Name",
    "accounts.create.management.active-sessions.ip-address": "IP Address",
    "accounts.create.management.active-sessions.partner": "Partner",
    "accounts.create.management.active-sessions.session": "Session",
    "accounts.create.management.ban-user.button": "Ban User",
    "accounts.create.management.ban-user.error": "Unable to ban user, please contact support",
    "accounts.create.management.ban-user.success": "User has been banned",
    "accounts.create.management.ban-user.message": "Ban this user so they cannot re-apply to the site",
    "accounts.create.management.ban-user.ban-reason": "Please add a note explaining the reason for banning:",
    "accounts.create.management.ban-user.title": "Ban User",
    "accounts.create.management.unban-user.button": "Unban User",
    "accounts.create.management.unban-user.error": "Unable to unban user, please contact support",
    "accounts.create.management.unban-user.success": "User has been unbanned",
    "accounts.create.management.unban-user.message": "Unban this user so the can re-apply to the site",
    "accounts.create.management.unban-user.title": "Unban User",
    "accounts.create.management.clear-okta-history.button": "Reset Okta ID",
    "accounts.create.management.clear-okta-history.error": "Unable to reset Okta ID.",
    "accounts.create.management.clear-okta-history.message": "Clears Okta ID and MFA Enrollment",
    "accounts.create.management.clear-okta-history.modal.message": "This action clears user's Okta ID and MFA Enrollment. Continue?",
    "accounts.create.management.clear-okta-history.success": "User's Okta ID and MFA Enrollment has been cleared.",
    "accounts.create.management.clear-okta-history.title": "Reset Okta ID",
    "accounts.create.management.deactivate": "Deactivate Account",
    "accounts.create.management.deactivate-message": "Deactivates current user.",
    "accounts.create.management.deactivate.error": "Unable to deactivate user.",
    "accounts.create.management.deactivate.modal.confirm": "Add Note & Confirm",
    "accounts.create.management.deactivate.modal.message": "Please add a note explaining the reason for deactivation:",
    "accounts.create.management.deactivate.modal.title": "Please Confirm Deactivation",
    "accounts.create.management.deactivate.success": "User has been deactivated, and can no longer be authenticated.",
    "accounts.create.management.end-active-sessions.button": "End Sessions",
    "accounts.create.management.end-active-sessions.error": "Unable to end all active sessions.",
    "accounts.create.management.end-active-sessions.message": "User is currently logged into",
    "accounts.create.management.end-active-sessions.modal.message": "This action logs out all of the user's current sessions. Continue?",
    "accounts.create.management.end-active-sessions.sessions": "sessions.",
    "accounts.create.management.end-active-sessions.success": "All active sessions have been logged out.",
    "accounts.create.management.end-active-sessions.title": "End Active Sessions",
    "accounts.create.management.password-create.additional-requirements": "Additional Requirements",
    "accounts.create.management.password-create.additional-requirements.not-repeated": "Password can't be repeated from the last 5 used",
    "accounts.create.management.password-create.additional-requirements.protect-privacy": "To protect your privacy we are unable to validate the following password requirements before submission:",
    "accounts.create.management.password-create.confirm-password": "Confirm Passord",
    "accounts.create.management.password-create.error": "Unable to set password, please try again.",
    "accounts.create.management.password-create.message": "Manually sets a new password for user, without sending a notification.",
    "accounts.create.management.password-create.new-password": "New Password",
    "accounts.create.management.password-create.note": "Creating a new password will erase previous password. The user will not be notified of the change.",
    "accounts.create.management.password-create.re-enter.new-password": "Re-Enter New Password",
    "accounts.create.management.password-create.set-password": "Set Password",
    "accounts.create.management.password-create.success": "Password updated successfully.",
    "accounts.create.management.password-create.title": "Create Password",
    "accounts.create.management.password-create.validation-check.title": "Your new password must:",
    "accounts.create.management.password-reset-link.button": "Generate Link",
    "accounts.create.management.password-reset-link.error": "Unable to generate password reset link, please try again.",
    "accounts.create.management.password-reset-link.linkmessage": "Copy and paste this link and send to the client:",
    "accounts.create.management.password-reset-link.message": "Generates the password reset link and does not send an email to the client.",
    "accounts.create.management.password-reset-link.success": "A password reset link has been generated.",
    "accounts.create.management.password-reset-link.title": "Password Reset Link",
    "accounts.create.management.password-reset.button": "Send Email",
    "accounts.create.management.password-reset.error": "Unable to send password reset email to the client.",
    "accounts.create.management.password-reset.message": "Sends a password reset link to the email address on the account (as well as resetting locked out restrictions).",
    "accounts.create.management.password-reset.success": "A password reset email has been sent to the client.",
    "accounts.create.management.password-reset.title": "Password Reset",
    "accounts.create.management.reactivate": "Reactivate Account",
    "accounts.create.management.reactivate-message": "Reactivates current user.",
    "accounts.create.management.reactivate.error": "Unable to reactivate user.",
    "accounts.create.management.reactivate.success": "User has been reactivated.",
    "accounts.create.management.resend-mfa": "Re-Send MFA enrollment",
    "accounts.create.management.resend-mfa.message": "Sends MFA enrollment email to the user.",
    "accounts.create.management.reset-ip-count": "Reset IP Count",
    "accounts.create.management.reset-ip-count.description-append": " to 0. Are you sure?",
    "accounts.create.management.reset-ip-count.description-prepend": "This will reset the IP count for ",
    "accounts.create.management.reset-view-count": "Reset View Count",
    "accounts.create.management.reset-view-count.description-append": " to 0. Are you sure?",
    "accounts.create.management.reset-view-count.description-prepend": "This will reset the view count for ",
    "accounts.create.management.session-count.error": "Unable to retrieve user session count.",
    "accounts.create.management.unlock-user.button": "Unlock User",
    "accounts.create.management.unlock-user.error": "Unable to unlock user.",
    "accounts.create.management.unlock-user.message.locked": "User is currently locked out.",
    "accounts.create.management.unlock-user.message.temp-locked": "User is temporarily locked out.",
    "accounts.create.management.unlock-user.message.unlocked": "User is not currently locked out.",
    "accounts.create.management.unlock-user.success": "User has been unlocked, and can now log in.",
    "accounts.create.management.unlock-user.title": "Unlock User",
    "accounts.create.management.video-playback-restrictions": "Video Playback Restrictions",
    "accounts.create.management.video-playback-restrictions.error": "Unable to retrieve restricted playback video watch list for user",
    "accounts.create.management.video-playback-restrictions.ip-addresses": "IP Addresses",
    "accounts.create.management.video-playback-restrictions.reset": "Reset",
    "accounts.create.management.video-playback-restrictions.reset-ip-count": "Reset IP Count",
    "accounts.create.management.video-playback-restrictions.reset-ip-count.error": "Unable to reset restricted video IP count for user.",
    "accounts.create.management.video-playback-restrictions.reset-ip-count.success": "Restricted video IP count has been reset for user.",
    "accounts.create.management.video-playback-restrictions.reset-views": "Reset Views",
    "accounts.create.management.video-playback-restrictions.reset-views.error": "Unable to reset restricted video view count for user.",
    "accounts.create.management.video-playback-restrictions.reset-views.success": "Restricted video view count has been reset for user.",
    "accounts.create.management.video-playback-restrictions.status": "Status",
    "accounts.create.management.video-playback-restrictions.unique-ips": "Unique IPs",
    "accounts.create.management.video-playback-restrictions.video-asset": "Video Asset",
    "accounts.create.management.video-playback-restrictions.video-count": "Video Count",
    "accounts.create.management.video-playback-restrictions.view-count": "View Count",
    "accounts.create.okta-id": "Okta Id",
    "accounts.create.permissions-audit-tab-title": "Permissions Audit",
    "accounts.create.permissions-tab-title": "Permissions",
    "accounts.create.permissions.account-expiration-date": "Account Expiration Date",
    "accounts.create.permissions.add-group": "Add an additional group",
    "accounts.create.permissions.add-role": "Add an additional role",
    "accounts.create.permissions.add-station": "Add an additional station",
    "accounts.create.permissions.brainiac-groups": "Brainiac User Group",
    "accounts.create.permissions.event-groups": "Event Groups",
    "accounts.create.permissions.expiration": "Expiration",
    "accounts.create.permissions.groups": "WBTVD/WBD Groups",
    "accounts.create.permissions.permissions-package-placeholder": "Select Package",
    "accounts.create.permissions.permissions-package": "Permissions Package",
    "accounts.create.permissions.presets": "Presets",
    "accounts.create.permissions.push-notifications": "Notifications",
    "accounts.create.permissions.request-custom-permissions": "Request Custom Permissions",
    "accounts.create.permissions.roles": "Roles",
    "accounts.create.permissions.stations": "Stations",
    "accounts.create.recently-watched.list.date-watched": "Date Watched",
    "accounts.create.recently-watched.list.timestamp": "Timestamp",
    "accounts.create.recently-watched.list.title": "Title",
    "accounts.create.recently-watched.list.video-name": "Video Name",
    "accounts.create.recently-watched.title": "Recently Watched",
    "accounts.create.save-profile-and-approve": "Save Profile & Approve",
    "accounts.create.save-profile-and-deny": "Save Profile & Deny",
    "accounts.create.save-profile": "Save Profile",
    "accounts.create.send-activation-email": "Send Activation Email",
    "accounts.create.subscriptions-list.add-subscription": "Add Subscription",
    "accounts.create.subscriptions-list.remove": "Remove",
    "accounts.create.subscriptions-list.subscription-type": "Subscription Type",
    "accounts.create.subscriptions-list.suggested-by": "Suggested By",
    "accounts.create.subscriptions-list.title-name": "Title Name",
    "accounts.create.subscriptions-tab-title": "Subscriptions",
    "accounts.create.subscriptions-tab.recently-watched": "Recently Watched",
    "accounts.create.subscriptions-tab.subscriptions": "Subscriptions",
    "accounts.create.subscriptions.add-subscriptions.btn": "Add Subscriptions",
    "accounts.create.subscriptions.add-subscription.title": "Add Subscription",
    "accounts.create.subscriptions.add.btn": "Add",
    "accounts.create.subscriptions.add.type": "Please Select Subscription Type",
    "accounts.create.subscriptions.loading-error": "Error loading user Subscriptions",
    "accounts.create.subscriptions.remove.btn": "Remove",
    "accounts.create.subscriptions.titles.to-add": "Titles to Be Added",
    "accounts.create.subscriptions.titles": "Add Titles",
    "accounts.create.summary-tab-title": "Summary",
    "accounts.create.summary.additional-information": "Additional Information",
    "accounts.create.summary.apply-date": "Apply Date",
    "accounts.create.summary.city": "City",
    "accounts.create.summary.client-rep-group": "Client Rep Group",
    "accounts.create.summary.company": "Company / US Station",
    "accounts.create.summary.contact-information": "Contact Information",
    "accounts.create.summary.contact-placeholder": "Select a Contact",
    "accounts.create.summary.contact.error": "Unable to load Contact, please try again",
    "accounts.create.summary.contact": "Contact",
    "accounts.create.summary.country-placeholder": "Select a Country",
    "accounts.create.summary.country": "Country",
    "accounts.create.summary.daytime-phone": "Phone",
    "accounts.create.summary.email": "Email",
    "accounts.create.summary.expiration-date": "Account Expiration",
    "accounts.create.summary.fax": "Fax",
    "accounts.create.summary.first-name": "First Name",
    "accounts.create.summary.guilds.get.error": "Unable to retrieve guilds, please try again",
    "accounts.create.summary.information.anonymous": "Anonymous",
    "accounts.create.summary.information.guilds": "Guilds",
    "accounts.create.summary.information.id": "ID",
    "accounts.create.summary.information.primary": "Primary",
    "accounts.create.summary.information.related-token": "Token",
    "accounts.create.summary.information.related-type": "Type",
    "accounts.create.summary.information.related": "Related Accounts",
    "accounts.create.summary.information.secondary": "Secondary",
    "accounts.create.summary.information.token": "Token",
    "accounts.create.summary.information.type": "Account Type",
    "accounts.create.summary.information.web-partners": "Web Partners",
    "accounts.create.summary.information": "Account Information",
    "accounts.create.summary.last-name": "Last Name",
    "accounts.create.summary.middle-name": "Middle Name",
    "accounts.create.summary.partner.error": "Unable to load Partner, please try again",
    "accounts.create.summary.postal-code": "Postal Code",
    "accounts.create.summary.preferred-language": "Language",
    "accounts.create.summary.referrers-email": "Referrer's Email",
    "accounts.create.summary.related-accounts.get.error": "Unable to retrieve related accounts, please try again",
    "accounts.create.summary.related-user-placeholder": "Select an User",
    "accounts.create.summary.related-user": "Related User",
    "accounts.create.summary.responsibilities": "Responsibilities",
    "accounts.create.summary.responsibility.acquisitions": "Acquisitions",
    "accounts.create.summary.responsibility.administration": "Administration",
    "accounts.create.summary.responsibility.business-development": "Business Development",
    "accounts.create.summary.responsibility.contract-administration": "Contract Administration",
    "accounts.create.summary.responsibility.digital-media": "Digital Media",
    "accounts.create.summary.responsibility.distribution": "Distribution",
    "accounts.create.summary.responsibility.dubbing": "Dubbing",
    "accounts.create.summary.responsibility.finance": "Finance",
    "accounts.create.summary.responsibility.human-resources": "Human Resources",
    "accounts.create.summary.responsibility.information-technology": "Information Technology",
    "accounts.create.summary.responsibility.legal": "Legal",
    "accounts.create.summary.responsibility.marketing": "Marketing",
    "accounts.create.summary.responsibility.metadata-acquisition": "Metadata Acquisition",
    "accounts.create.summary.responsibility.other": "Other",
    "accounts.create.summary.responsibility.press": "Press",
    "accounts.create.summary.responsibility.producer": "Producer",
    "accounts.create.summary.responsibility.program-development": "Program Development",
    "accounts.create.summary.responsibility.programming": "Programming",
    "accounts.create.summary.responsibility.publicity": "Publicity",
    "accounts.create.summary.responsibility.research": "Research",
    "accounts.create.summary.responsibility.rights": "Rights",
    "accounts.create.summary.responsibility.sales": "Sales",
    "accounts.create.summary.responsibility.social-media": "Social Media",
    "accounts.create.summary.responsibility.standards-and-practices": "Standards and Practices",
    "accounts.create.summary.responsibility.technical-perations": "Technical Operations",
    "accounts.create.summary.responsibility.traffic-coordinator": "Traffic Coordinator",
    "accounts.create.summary.state": "State",
    "accounts.create.summary.status": "Status",
    "accounts.create.summary.street-address": "Street Address",
    "accounts.create.summary.user-information": "User Information",
    "accounts.create.summary.user-title": "Title",
    "accounts.create.summary.web-address": "Web Address",
    "accounts.create.token" : "Token",
    "accounts.create.user-errors.error": "Error retreiving users errors.",
    "accounts.create.user-errors.tab-title": "User Errors",
    "accounts.create.user-errors.table.header.date-time": "Date & Time",
    "accounts.create.user-errors.table.header.error-id": "Error ID",
    "accounts.create.user-errors.table.header.error-type": "Error Type",
    "accounts.create.user-errors.table.header.session-id": "Session ID",
    "accounts.create.user-errors.table.header.show-body": "Show Body",
    "accounts.create.user-errors.table.header.uri-method": "URI Method",
    "accounts.create.user-errors.table.header.uri": "URI",
    "accounts.create.user-errors.table.header.uri-body": "URI Body",
    "accounts.create.user-errors.table.header.user-error": "User Error",
    "accounts.create.user-errors.table.header.user-id": "User ID",
    "accounts.create.validations.error": "Unable to load user validations. Please contact support",
    "accounts.create.warnOnTrue.body" : "Do you want to continue?",
    "accounts.create.warnOnTrue.confirm" : "Continue",
    "accounts.create.warnOnTrue.title" : "Please Confirm",
    "accounts.edit.account.btn.next": "Edit Next Account",
    "accounts.edit.account.btn.previous": "Edit Previous Account",
    "accounts.edit.account.error": "Unable to update User Account, please try again",
    "accounts.edit.account.success": "User Account updated",
    "accounts.edit.account.title": "Edit Account",
    "accounts.edit.action-history.title": "Activity",
    "accounts.edit.activation-settings.email-not-sent": "Activation Email NOT Sent",
    "accounts.edit.activation-settings.email-sent": "Activation Email Sent",
    "accounts.edit.activation-settings.send-email.info-title": "To reset password: ",
    "accounts.edit.applicant.btn.next": "Edit Next Applicant",
    "accounts.edit.applicant.btn.previous": "Edit Previous Applicant",
    "accounts.edit.applicant.error": "Unable to update Applicant Profile, please try again",
    "accounts.edit.applicant.success": "Applicant Profile updated",
    "accounts.edit.applicant.title": "Edit Applicant",
    "accounts.edit.copy-permissions": "Copy Permissions",
    "accounts.edit.copy-permissions.button-label": "Copies permissions from this account to another account",
    "accounts.edit.copy-permissions.confirm.error": "There was an error copying permissions to {}. Please try again.",
    "accounts.edit.copy-permissions.confirm.success": "Permissions successfully copied to {}.",
    "accounts.edit.copy-permissions.description": "Copy permissions from this account to user account specified below. This action will overwrite all the groups, roles, and partners for the user specified below and will remove all their permissions that currently exist on that account",
    "accounts.edit.copy-permissions.groups.error": "Unable to retrieve groups. Please try again.",
    "accounts.edit.copy-permissions.partners.error": "Unable to retrieve partners. Please try again.",
    "accounts.edit.copy-permissions.roles.error": "Unable to retrieve roles. Please try again.",
    "accounts.edit.copy-permissions.select.label": "Find User",
    "accounts.edit.copy-permissions.select.placeholder": "Search",
    "accounts.edit.copy-permissions.suggested.error": "There was an error loading suggested users. Please try again.",
    "accounts.edit.copy-permissions.title": "Overwrite User Permissions",
    "accounts.edit.delivery-tab-title": "Delivery",
    "accounts.edit.delivery.broadcastStandard": "Broadcast Standard",
    "accounts.edit.delivery.editing": "Editing System",
    "accounts.edit.delivery.fileFormat": "File Format",
    "accounts.edit.delivery.it.email": "IT Email",
    "accounts.edit.delivery.it.name": "IT Name",
    "accounts.edit.delivery.it.phone": "IT Phone",
    "accounts.edit.delivery.metadata.export-spec": "Metadata Export Spec",
    "accounts.edit.delivery.metadata.language": "Preferred Metadata Language",
    "accounts.edit.delivery.metadata.title": "Metadata Delivery",
    "accounts.edit.delivery.notifications.add": "Add Notification",
    "accounts.edit.delivery.notifications.email": "Email",
    "accounts.edit.delivery.notifications.name": "Name",
    "accounts.edit.delivery.notifications.title": "Notifications",
    "accounts.edit.delivery.onboarding.title": "Onboarding Information",
    "accounts.edit.duplicated-emails.title": "Duplicate Account",
    "accounts.edit.duplicated-emails.description": " - Additional Account(s) With This Name Found:",
    "accounts.edit.notes.title": "Notes",
    "accounts.edit.save-duplicate": "There is currently another user that saved simultaneously while on this page, please check the action history before proceeding with a refresh",
    "accounts.edit.summary.homepages": "Homepages",
    "accounts.edit.summary.homepages.content-type.home-page": "Homepage",
    "accounts.edit.summary.homepages.content-type.multiple-titles": "Multiple Titles",
    "accounts.edit.summary.homepages.content-type.not-content": "N/A",
    "accounts.edit.summary.homepages.content-type.single-title": "Single Title",
    "accounts.edit.summary.homepages.entry-point": "(Programmatic Entry Point)",
    "accounts.edit.summary.homepages.get.error": "Unable to load Homepages, please try again",
    "accounts.edit.summary.homepages.home": "Home",
    "accounts.edit.summary.homepages.no-access": "(No Access)",
    "accounts.edit.summary.homepages.titles": "Titles",
    "accounts.edit.summary.homepages.type": "Type",
    "accounts.edit.unlock.success": "User temporary lock has been removed. Be careful, as next failed login will lock the user account in Okta.",
    "accounts.export-users": "Export Users",
    "accounts.export-users.error": "Error while exporting list of users",
    "accounts.account-wizard.accounts-length": "Accounts uploaded. Please review below and add permissions.",
    "accounts.account-wizard.add-to-event.caption": "After users are created, they will be added to the selected event. Please note, new users can only be added to PENDING or ACTIVE events.",
    "accounts.account-wizard.add-to-event.event": "Event",
    "accounts.account-wizard.add-to-event.no-events": "There are currently no Pending Events this batch can be added to.",
    "accounts.account-wizard.add-to-event.panel-title": "Add To Event",
    "accounts.account-wizard.batch-create.error": "There was an error creating the batch",
    "accounts.account-wizard.batch-info": "Batch Info",
    "accounts.account-wizard.batch-name.caption": "Name of batch, viewable when browsing.",
    "accounts.account-wizard.batch-name.title": "Batch Name",
    "accounts.account-wizard.batch-save.error": "There was an error saving the batch changes",
    "accounts.account-wizard.batch-save.success": "Batch changes have been successfully saved",
    "accounts.account-wizard.batch-status-types.contains-errors": "Contains Errors",
    "accounts.account-wizard.batch-status-types.contains-errors.tooltip": "Contains Errors: Click batch name for errors",
    "accounts.account-wizard.batch-status-types.contains-errors.tooltip-edit": "There are user rows which contain errors. Remove those user rows or edit them to continue.",
    "accounts.account-wizard.batch-status-types.pending": "Pending",
    "accounts.account-wizard.batch-status-types.pending.tooltip": "Pending: Batch is waiting to be created",
    "accounts.account-wizard.batch-status-types.processed": "Processed",
    "accounts.account-wizard.batch-status-types.processed.tooltip": "Processed: Batch has successfully been created",
    "accounts.account-wizard.batch-status-types.processing": "Processing",
    "accounts.account-wizard.batch-status-types.processing.tooltip": "Processing: Batch is being created",
    "accounts.account-wizard.batch-status-types.rejected": "Rejected",
    "accounts.account-wizard.batch-status-types.rejected.tooltip": "Rejected: Batch was not created",
    "accounts.account-wizard.browse.batch": "Batch",
    "accounts.account-wizard.browse.batch-name": "Batch Name",
    "accounts.account-wizard.browse.batches": "Batches",
    "accounts.account-wizard.browse.created": "Created",
    "accounts.account-wizard.browse.created-by": "Created By",
    "accounts.account-wizard.browse.created-date": "Created Date",
    "accounts.account-wizard.browse.error": "Error Browsing Batches.",
    "accounts.account-wizard.browse.event-name": "Event Name",
    "accounts.account-wizard.browse.last-updated": "Last Updated",
    "accounts.account-wizard.browse.status": "Status",
    "accounts.account-wizard.browse.title": "Browse Account Wizard Batches",
    "accounts.account-wizard.browse.updated": "Updated",
    "accounts.account-wizard.browse.user-count": "User Count",
    "accounts.account-wizard.button.add": "Add Permissions",
    "accounts.account-wizard.confirmation.create-partial.caption": "There are errors within this batch but we can still run this batch. Please note that VALID_USERS out of TOTAL_USERS will be processed and the remaining errors will need to handled within a new batch",
    "accounts.account-wizard.confirmation.create-partial.confirm": "Create",
    "accounts.account-wizard.confirmation.create-partial.title": "Not all users valid",
    "accounts.account-wizard.confirmation.create.caption": "Are you sure you want to create accounts?",
    "accounts.account-wizard.confirmation.create.confirm": "Create Accounts",
    "accounts.account-wizard.confirmation.create.title": "Confirm Creating Accounts",
    "accounts.account-wizard.confirmation.removal.caption": "Are you sure you want to remove the selected user(s)?",
    "accounts.account-wizard.confirmation.removal.confirm": "Remove Users",
    "accounts.account-wizard.confirmation.removal.title": "Confirm User Removal",
    "accounts.account-wizard.contact-client-rep-group.caption": "Contacts to add to batch of users upon creation.",
    "accounts.account-wizard.contact-client-rep-group.title": "Contact/Client Rep Group",
    "accounts.account-wizard.create-accounts": "Create Accounts",
    "accounts.account-wizard.create-empty-batch": "Create Empty Batch",
    "accounts.account-wizard.create-empty-batch.button": "Start Without CSV",
    "accounts.account-wizard.create-empty-batch.description": "Create a new empty batch without uploading a CSV",
    "accounts.account-wizard.create-empty-batch.error": "Error creating empty batch. Please try again.",
    "accounts.account-wizard.create-empty-batch.success": "Empty account wizard batch created successfully",
    "accounts.account-wizard.delete-batch.error": "There was an error while deleting the batch",
    "accounts.account-wizard.delete-batch.success": "Batch deleted successfully",
    "accounts.account-wizard.details.1": "Please upload a",
    "accounts.account-wizard.details.2": "to start using the Account Wizard Import tool",
    "accounts.account-wizard.download-template": "Download Template",
    "accounts.account-wizard.error.contains-errors": "Contains errors. Please review.",
    "accounts.account-wizard.error.exceeded": "Error Processing CSV: Please make sure your batch has less than 200 users.",
    "accounts.account-wizard.error.load": "Unable to load accounts, please try again",
    "accounts.account-wizard.error.processing": "Error processing csv. Please try again.",
    "accounts.account-wizard.error.uploading": "Error uploading file. Please try again.",
    "accounts.account-wizard.groups.create.text": "Add an additional group",
    "accounts.account-wizard.groups.title": "Groups",
    "accounts.account-wizard.permissions-package": "Permissions Package",
    "accounts.account-wizard.permissions-package-placeholder": "Select Package",
    "accounts.account-wizard.permissions-title": "Permissions",
    "accounts.account-wizard.presets": "Presets",
    "accounts.account-wizard.primary-partner.caption": "Default portal users will access.",
    "accounts.account-wizard.request-custom-permissions": "Request Custom Permissions",
    "accounts.account-wizard.roles.create.text": "Add an additional group",
    "accounts.account-wizard.roles.title": "Roles",
    "accounts.account-wizard.save.error": "There was an error saving permissions",
    "accounts.account-wizard.save.success": "Accounts with selected permissions successfully added",
    "accounts.account-wizard.start-steps.title":"Steps to start the Account Wizard process:",
    "accounts.account-wizard.status": "Status",
    "accounts.account-wizard.step.import.title": "Step 1: Import Spreadsheet or Start Batch Without CSV",
    "accounts.account-wizard.step.review-uploaded.title": "Step 2: Review Uploaded Accounts",
    "accounts.account-wizard.step.update-permissions.title": "Step 3: Add Permissions",
    "accounts.account-wizard.title": "Account Wizard Import",
    "accounts.account-wizard.upload-file":"Upload file",
    "accounts.account-wizard.user-list": "User List",
    "accounts.account-wizard.user-list.add-new-user": "Add New User",
    "accounts.account-wizard.user-list.click-on-a-cell": "Click on a cell in the table below to edit it.",
    "accounts.account-wizard.user-list.company": "Company",
    "accounts.account-wizard.user-list.country": "Country",
    "accounts.account-wizard.user-list.edit-users": "Edit Users",
    "accounts.account-wizard.user-list.edit-users.modal.important-notes": "Important Notes",
    "accounts.account-wizard.user-list.edit-users.modal.important-notes.after-confirming": "After confirming changes, you will still need to save the batch to commit them.",
    "accounts.account-wizard.user-list.edit-users.modal.important-notes.fields-left-blank": "Fields above left blank will NOT be changed.",
    "accounts.account-wizard.user-list.edit-users.modal.important-notes.fields-with-values": "Fields with values will change for ALL selected users.",
    "accounts.account-wizard.user-list.edit-users.modal.users-selected": "users selected",
    "accounts.account-wizard.user-list.email": "Email",
    "accounts.account-wizard.user-list.first-name": "First Name",
    "accounts.account-wizard.user-list.last-name": "Last Name",
    "accounts.account-wizard.user-list.remove-users": "Remove Users",
    "accounts.account-wizard.validations.error": "Unable to load batch validations",
    "accounts.account-wizard.web-partners.caption": "Web portals users will have access to.",
    "accounts.mass-check.title": "Mass Check Accounts",
    "accounts.mass-check.start-steps.title": "Steps to start a Mass Check process:",
    "accounts.mass-check.step.review-uploaded.title": "Step 3: Review Uploaded Accounts",
    "accounts.mass-check.step.update-permissions.title": "Step 4: Update Permissions",
    "accounts.mass-common.button.back": "Back",
    "accounts.mass-common.download-template": "Download Template",
    "accounts.mass-common.error.load": "Unable to load accounts, please try again",
    "accounts.mass-common.start-steps.1":"Download the \"Template\" file above",
    "accounts.mass-common.start-steps.2":"In rows, fill in all fields for each user being added. The limit is 200 users for each batch",
    "accounts.mass-common.start-steps.3":"Save the file as \".CSV\"",
    "accounts.mass-common.start-steps.4":"Upload the file below",
    "accounts.mass-common.step.import.title": "Step 1: Import Spreadsheet",
    "accounts.mass-common.upload-file":"Upload file",
    "accounts.mass-update.accounts-length": "Accounts uploaded. Please review below and update permissions.",
    "accounts.mass-update.accounts-uploaded": "Accounts Uploaded",
    "accounts.mass-update.brainiac-groups": "Brainiac User Groups",
    "accounts.mass-update.button.add": "Add/Update Permissions",
    "accounts.mass-update.button.add-to-existing": "Add to Existing",
    "accounts.mass-update.button.change-status": "Change Status",
    "accounts.mass-update.button.remove": "Remove Permissions",
    "accounts.mass-update.button.remove-selected": "Remove Selected Permissions",
    "accounts.mass-update.button.save": "Save Permissions",
    "accounts.mass-update.button.update-and-overwrite": "Update and Overwrite",
    "accounts.mass-update.client-rep-group.title": "Client Rep group",
    "accounts.mass-update.client-rep-group.update.text": "Add Client Rep group",
    "accounts.mass-update.confirmation.title": "Accounts Updated",
    "accounts.mass-update.download-template":"Download Template",
    "accounts.mass-update.error.file-format": "Invalid file format. Please upload a CSV file.",
    "accounts.mass-update.error.line-item": "One or more rows can't be updated.",
    "accounts.mass-update.error.multiple-columns": "File contains multiple columns. Remove all columns other than email.",
    "accounts.mass-update.error.uploading-file": "Error uploading file. Please try again.",
    "accounts.mass-update.exclude-stations": "Exclude Stations",
    "accounts.mass-update.exclude-stations.description": "- Station assignments will be ignored for this batch",
    "accounts.mass-update.existing-accounts": "Existing Accounts",
    "accounts.mass-update.existing-accounts.description": "of the {} uploaded accounts already exist in the system.",
    "accounts.mass-update.existing-accounts.mass-update": "Mass Update These Accounts",
    "accounts.mass-update.existing-accounts.mass-update-description": "Mass Update only existing accounts in batch",
    "accounts.mass-update.export-all-accounts": "Export Batch as Partitioned List",
    "accounts.mass-update.export-all-accounts.description": "Download spreadsheet of Existing/Nonexistent users",
    "accounts.mass-update.groups.remove.text": "Select groups to remove",
    "accounts.mass-update.groups.update.text": "Add additional groups",
    "accounts.mass-update.no-users": "There are no users to be updated, please add users and try again",
    "accounts.mass-update.non-existent-accounts": "Nonexistent Accounts",
    "accounts.mass-update.non-existent-accounts.description": "of the {} uploaded accounts do not exist in the system.",
    "accounts.mass-update.non-existent-accounts.export": "Export Nonexistent Accounts",
    "accounts.mass-update.non-existent-accounts.export-description": "Exports a list of non-existent emails",
    "accounts.mass-update.permissions-package": "Permissions Package",
    "accounts.mass-update.permissions-package-placeholder": "Select Package",
    "accounts.mass-update.permissions-title": "Permissions",
    "accounts.mass-update.presets": "Presets",
    "accounts.mass-update.request-custom-permissions": "Request Custom Permissions",
    "accounts.mass-update.roles.remove.text": "Select roles to remove",
    "accounts.mass-update.roles.title": "Roles",
    "accounts.mass-update.roles.update.text": "Add additional roles",
    "accounts.mass-update.row-header.email": "Email",
    "accounts.mass-update.row-header.error": "Error",
    "accounts.mass-update.row-header.groups": "Existing Groups",
    "accounts.mass-update.row-header.index": "#",
    "accounts.mass-update.row-header.roles": "Existing Roles",
    "accounts.mass-update.row-header.status": "Status",
    "accounts.mass-update.save.error": "There was an error saving permissions",
    "accounts.mass-update.save.errorAdd": "Add Permissions failed.",
    "accounts.mass-update.save.errorRemove": "Remove Permissions failed.",
    "accounts.mass-update.save.errorUpdate":"Update and Overwrite Permissions failed.",
    "accounts.mass-update.save.success": "Accounts successfully updated",
    "accounts.mass-update.save.successAdd": "Add Permissions successful.",
    "accounts.mass-update.save.successRemove": "Remove Permissions successful.",
    "accounts.mass-update.save.successUpdate":"Update and Overwrite Permissions successful.",
    "accounts.mass-update.save.successUpdate.3":"Users status updated to Pending successfully",
    "accounts.mass-update.save.successUpdate.4":"Users status updated to Removed successfully",
    "accounts.mass-update.start-steps.title":"Steps to start a Mass Update process:",
    "accounts.mass-update.stations": "Stations",
    "accounts.mass-update.stations.remove.text": "Select stations to remove",
    "accounts.mass-update.stations.update.text": "Add additional stations",
    "accounts.mass-update.step.account-check.title": "Step 2: Existing Account Check",
    "accounts.mass-update.step.review-uploaded.title": "Step 2: Review Uploaded Accounts",
    "accounts.mass-update.step.update-permissions.title": "Step 3: Update Permissions",
    "accounts.mass-update.step-two.errors-found":"Errors were found.",
    "accounts.mass-update.step-two.errors-found.text": "For more information, hover over the flag icons.",
    "accounts.mass-update.title": " Mass Update Accounts",
    "accounts.mass-update.users-status.title": "Status",
    "accounts.mass-update.users-status.update.text": "Update status",
    "accounts.mass-update.wbd-groups": "WBTVD/WBD Groups",
    "accounts.relations.save.client-rep.error": "Unable to save Client Rep",
    "accounts.relations.save.group.error": "Unable to save Group",
    "accounts.relations.save.partner.error": "Unable to save Partner",
    "accounts.relations.save.role.error": "Unable to save Role",
    "accounts.title": "Accounts",
    "accounts.user-errors": "User Errors",
    "accounts.user-errors.get.error": "There was an error loading user errors, please contact Support if the problem persists",

    "analytics.reports.get-types.error": "There was an error loading report types, please try again later or contact your client representative if the problem persists",
    "analytics.reports.get.error": "There was an error loading reports, please try again later or contact your client representative if the problem persists",
    "analytics.reports.select-placeholder": "Select report type",
    "analytics.reports.table.columns.created-date": "Created date",
    "analytics.reports.table.columns.file": "File name",
    "analytics.reports.title": "Reports",
    "analytics.reports.types.guild-daily-video-views": "Daily guild video views",
    "analytics.tracking-report.catalogs.assigned-title": "Title Catalogs Included In Report",
    "analytics.tracking-report.catalogs.unassigned-title": "Available Title Catalogs",
    "analytics.tracking-report.download-report": "Download Report",
    "analytics.tracking-report.download-report.validation": "You must select one or more title catalogs for inclusion before the report can be created and downloaded.",
    "analytics.tracking-report.title": "Tracking Report",

    "asset-summary.image-thumbnails.load-error": "There was an error loading image thumbnails",
    "asset-summary.load-error": "Unable to retrieve assets",
    "asset-summary.video-thumbnails.load-error": "There was an error loading video thumbnails",
    "asset.audio.create.assetComment": "Display Name",
    "asset.audio.create.contentType": "Content Type",
    "asset.audio.create.copyrightText": "Copyright Text",
    "asset.audio.create.copyrightYear": "Copyright Year",
    "asset.audio.create.description": "Description",
    "asset.audio.create.error": "Unable to create Audio, please try again",
    "asset.audio.create.info": "Please fill out the form below to create a new audio asset.",
    "asset.audio.create.keyword": "Keyword",
    "asset.audio.create.mediakey": "Media Key",
    "asset.audio.create.name": "Name",
    "asset.audio.create.name.placeholder": "Enter here...",
    "asset.audio.create.runtime": "Runtime",
    "asset.audio.create.success": "Audio Created",
    "asset.audio.create.title": "Create Audio Asset",
    "asset.audio.create.upload": "Attach Audio File",
    "asset.audio.edit.error": "Unable to Save Audio, please try again",
    "asset.audio.edit.info": "Please review asset info below.",
    "asset.audio.edit.success": "Audio Successfully Saved",
    "asset.audio.edit.title": "Edit Audio Asset",
    "asset.audio.edit.upload": "Replace File",
    "asset.audio.load-error": "Error Loading Asset Audio",
    "asset.audio.preview": "Preview File",
    "asset.audio.preview.unsupported": "Your browser does not support HTML5 audio.",
    "asset.audio.side-notes.title": "Notes",
    "asset.catalogs.load-error": "There was an error loading asset catalogs",
    "asset.detail.notify-subscribers-now": "Notify Subscribers Now",
    "asset.detail.notify-subscribers-now.body": "Body",
    "asset.detail.notify-subscribers-now.custom-message": "Custom Message",
    "asset.detail.notify-subscribers-now.description": "Do you wish to immediately notify all of this title's relevant subscribers that this document has been added?",
    "asset.detail.notify-subscribers-now.description.note": "Users who have been subscribed to this specific document that have already received this email previously will not receive this email again.",
    "asset.detail.notify-subscribers-now.description.note.prefix": "Note: ",
    "asset.detail.notify-subscribers-now.notify-subscribers": "Notify Subscribers",
    "asset.detail.notify-subscribers-now.subject": "Subject",
    "asset.detail.toggle-active.0": "Make Active",
    "asset.detail.toggle-active.1": "Make Inactive",
    "asset.detail.video.load-error": "There was an error loading the video streamURL",
    "asset.document.assetComment": "Display Name",
    "asset.document.body": "Body",
    "asset.document.contentType": "Content Type",
    "asset.document.copyright.text": "Copyright Text",
    "asset.document.copyright.year": "Copyright Year",
    "asset.document.create.error": "Unable to create Document, please try again",
    "asset.document.create.info": "Please fill out the form below to create a new document asset.",
    "asset.document.create.success": "Document Created",
    "asset.document.create.title": "Create Document Asset",
    "asset.document.edit.error": "Unable to update Document, please try again",
    "asset.document.edit.info": "Please review document info below.",
    "asset.document.edit.success": "Document Updated",
    "asset.document.edit.title": "Edit Document Asset",
    "asset.document.file.download": "Download File",
    "asset.document.file.replace": "Replace File",
    "asset.document.file.upload": "Upload File",
    "asset.document.keywords": "Keywords",
    "asset.document.load-error": "Error Loading Asset Document",
    "asset.document.name": "Name",
    "asset.document.name.placeholder": "Enter here...",
    "asset.document.run-date": "Run Date",
    "asset.document.side-notes.title": "Notes",
    "asset.download.error": "There was an error generating the download URL, please try again or contact your client representative if the problem persists ",

    "asset.edit.ai-models-tab-title": "A.I. Tags",
    "asset.edit.ai-models-tab.active.alert": "Newly assigned model will not run until asset is saved.",
    "asset.edit.ai-models-tab.active.description": "Shows In Search",
    "asset.edit.ai-models-tab.status": "Status",
    "asset.edit.ai-models-tab.active.title": "Active",
    "asset.edit.ai-models-tab.inactive.title": "Inactive",
    "asset.edit.ai-models-tab.inactive.description": "Hidden From Search",
    "asset.edit.ai-models-tab.messages.activate-confirm.title": "Activate",
    "asset.edit.ai-models-tab.messages.activate-confirm.message": "Replace existing active {language} file with",
    "asset.edit.ai-models-tab.messages.no-content-ai-model": "Workflow Start (ContentAI) must complete and active before additional ContentAI models can be activated",
    "asset.edit.ai-models-tab.messages.no-processable-models": "Initial A.I. processing not run.",
    "asset.edit.ai-models-tab.messages.no-processed-asset": "This asset is not eligible for A.I. processing due to its Content Type and Delivery Type.",
    "asset.edit.ai-models-tab.messages.no-vi-model": "Video Indexer must complete before additional custom models can be activated",
    "asset.edit.ai-models-tab.messages.run.failed": "Error occurred on run model",
    "asset.edit.ai-models-tab.messages.run.success": "The model was run successfully",
    "asset.edit.ai-models-tab.messages.run.tooltip": "You must save before running",
    "asset.edit.ai-models-tab.table.activate": "Activate",
    "asset.edit.ai-models-tab.table.deactivate": "Deactivate",
    "asset.edit.ai-models-tab.table.iteration": "Iteration",
    "asset.edit.ai-models-tab.table.last-run": "Last Run",
    "asset.edit.ai-models-tab.table.model": "A.I. Models",
    "asset.edit.ai-models-tab.table.run-again": "Run Again",
    "asset.edit.ai-models-tab.table.status": "Status",
    "asset.edit.ai-models-tab.table.status.complete": "Complete",
    "asset.edit.ai-models-tab.table.status.failed": "Failed",
    "asset.edit.ai-models-tab.table.status.in-progress": "In Progress",
    "asset.edit.ai-models-tab.table.status.processing": "Processing",
    "asset.edit.ai-models-tab.table.updated-date": "Model Updated",
    "asset.edit.ai-models-tab.table.unassigned-models": "Unassigned A.I. Models",

    "asset.edit.catalogs-tab-title": "Catalogs",
    "asset.edit.catalogs-tab.actions": "Actions",
    "asset.edit.catalogs-tab.assigned-title": "Assigned Catalogs",
    "asset.edit.catalogs-tab.copy-catalogs": "Copy Catalogs",
    "asset.edit.catalogs-tab.copy-catalogs.description": "Copies catalogs from this video asset to another video asset",
    "asset.edit.catalogs-tab.copy-catalogs.error": "Unable to load Asset Catalogs, please try again.",
    "asset.edit.catalogs-tab.copy-catalogs.failure": "Something went wrong while copying catalogs, please try again later",
    "asset.edit.catalogs-tab.copy-catalogs.modal.description": "Copy the catalogs from this asset to the selected asset.",
    "asset.edit.catalogs-tab.copy-catalogs.modal.label": "Video Asset",
    "asset.edit.catalogs-tab.copy-catalogs.modal.placeholder": "Search",
    "asset.edit.catalogs-tab.copy-catalogs.modal.title": "Copy Asset Catalogs",
    "asset.edit.catalogs-tab.copy-catalogs.success": "Catalogs successfully copied!",
    "asset.edit.catalogs-tab.event-catalog-title": "Autogenerated By Events",
    "asset.edit.catalogs-tab.status": "Status",
    "asset.edit.catalogs-tab.type": "Type",
    "asset.edit.catalogs-tab.unassigned-title": "Unassigned Catalogs",
    "asset.edit.event-catalogs-tab-title": "Events",
    "asset.edit.event-catalogs-tab.title": "Events Catalogs (Autogenerated)",
    "asset.edit.event-catalogs.warning": "Warning: adding or removing autogenerated catalogs may cause unexpected errors for events.  This should be done only for troubleshooting or in rare edge cases.",
    "asset.edit.last-modified-date": "Last Modified Date",
    "asset.edit.last-modifier": "Last Modifier",
    "asset.edit.titles-tab-title": "Titles",
    "asset.edit.titles-tab.actions": "Actions",
    "asset.edit.titles-tab.assigned-title": "Assigned Titles",
    "asset.edit.titles-tab.mpm": "MPM #",
    "asset.edit.titles-tab.release-date": "Release Date",
    "asset.edit.titles-tab.title": "Title",
    "asset.edit.titles-tab.title-type": "Title Type",
    "asset.edit.titles-tab.unassigned-title": "Unassigned Titles",
    "asset.edit.titles-tab.wbtvd": "WBTVD",
    "asset.edit.titles-tab.wbtvd.tooltip": "View this title and asset in WBTVD",
    "asset.efd.info": "EFD Info",
    "asset.error.incomplete-save": "Some asset data could not be saved (catalogs and/or talent), please review asset and save again.",
    "asset.filter-by.all": "All Asset Types",
    "asset.filter-by.audio": "Audio",
    "asset.filter-by.document": "Documents",
    "asset.filter-by.image": "Image",
    "asset.filter-by.merchandise": "Merchandise",
    "asset.filter-by.script": "Script",
    "asset.filter-by.video": "Video",
    "asset.filter-by.video-timeline": "Video Timeline",
    "asset.image.assetComment": "Display Name",
    "asset.image.caption": "Caption",
    "asset.image.contentType": "Content Type",
    "asset.image.copyrightText": "Copyright Text",
    "asset.image.copyrightYear": "Copyright Year",
    "asset.image.create.error": "Unable to create Image Asset \"{}\", please try creating your Image Asset again",
    "asset.image.create.error.incomplete-save": "Image asset \"{}\" was created/edited successfully but it failed to upload file \"{}\", please try upload file again.",
    "asset.image.create.info": "Please fill out the form below to create a new image asset.",
    "asset.image.create.success": "Image Asset \"{}\" successfully created",
    "asset.image.create.title": "Create New Image(s)",
    "asset.image.create.upload.rendition": "Full Resolution Rendition",
    "asset.image.create.upload.rendition.title": "Add Rendition",
    "asset.image.create.upload.source": "Source File",
    "asset.image.create.upload.source.title": "Upload File",
    "asset.image.credit": "Credit",
    "asset.image.defaultBanner.remove": "Remove as Default Banner",
    "asset.image.defaultBanner.set": "Set As Default Banner",
    "asset.image.defaultHorizontal.remove": "Remove as Default Horizontal",
    "asset.image.defaultHorizontal.set": "Set As Default Horizontal",
    "asset.image.defaultTitleTreatment.remove": "Remove as Logo/Title Treatment",
    "asset.image.defaultTitleTreatment.set": "Set as Logo/Title Treatment",
    "asset.image.defaultVertical.remove": "Remove as Default Vertical",
    "asset.image.defaultVertical.set": "Set As Default Vertical",
    "asset.image.deliveryType": "Delivery Type",
    "asset.image.edit.download.source.title": "Download File",
    "asset.image.edit.error": "Unable to update Image, please try again",
    "asset.image.edit.info": "Please review asset info below.",
    "asset.image.edit.success": "Image Updated",
    "asset.image.edit.title": "Edit Image Asset",
    "asset.image.edit.upload.rendition": "Replace Rendition",
    "asset.image.edit.upload.rendition.title": "Rendition Preview",
    "asset.image.edit.upload.source": "Replace File",
    "asset.image.edit.upload.source.title": "Replace File",
    "asset.image.error.file-extension": "File \"{}\" is not allowed image, please choose files with allowed extension",
    "asset.image.error.incomplete-save": "Some asset data could not be saved (catalogs and/or talent), please review asset \"{}\" and save again.",
    "asset.image.image-preview": "Preview Image",
    "asset.image.image-preview.dimensions.not-available": "dimensions N/A",
    "asset.image.isExclusive": "Exclusive",
    "asset.image.isPublicAsset": "Public Asset",
    "asset.image.keywords": "Keywords",
    "asset.image.load-error": "Error Loading Asset Image",
    "asset.image.make-inactive.message": "After making this image inactive, it will not be allowed to be set as default vertical/horizontal and it will not be set as logo/title treatment.",
    "asset.image.make-inactive.title": "Please confirm action",
    "asset.image.marsAssetType": "MARS Asset Type",
    "asset.image.mimeType": "Mime Type",
    "asset.image.name": "Name",
    "asset.image.source-migrated": "Regenerate Renditions",
    "asset.image.source-migrated.failed": "Failed to seed image to S3, please try again.",
    "asset.image.source-migrated.success": "Image seeded to S3.",
    "asset.image.summary.title": "Summary",
    "asset.image.type-file": "File Type",
    "asset.image.upload.error": "Upload image failed, please try again",
    "asset.info": "Asset Info",
    "asset.merchandise.create.assetComment": "Display Name",
    "asset.merchandise.create.contact": "Contact",
    "asset.merchandise.create.contentType": "Merchandise Type",
    "asset.merchandise.create.copyrightText":"Copyright Text",
    "asset.merchandise.create.copyrightYear":"Copyright Year",
    "asset.merchandise.create.description":"Description",
    "asset.merchandise.create.error": "Unable to create Merchandise, please try again",
    "asset.merchandise.create.info": "Please fill out the form below to create a new merchandise asset.",
    "asset.merchandise.create.keywords" : "Keywords",
    "asset.merchandise.create.name": "Name",
    "asset.merchandise.create.photo-preview.button": "Remove Photo",
    "asset.merchandise.create.photo-preview.title": "Merchandise Detail",
    "asset.merchandise.create.price": "Price",
    "asset.merchandise.create.sku": "SKU",
    "asset.merchandise.create.success": "Merchandise Created",
    "asset.merchandise.create.title": "Create Merchandise Asset",
    "asset.merchandise.create.upload": "Photos",
    "asset.merchandise.edit.error": "Unable to update Merchandise, please try again",
    "asset.merchandise.edit.info": "Please review asset info below.",
    "asset.merchandise.edit.success": "Merchandise Updated",
    "asset.merchandise.edit.title": "Edit Merchandise Asset",
    "asset.merchandise.load-error": "Error Loading Asset Merchandise",
    "asset.proxy.edit.parent-timeline": "Edit Timeline",
    "asset.proxy.edit.title": "Edit Proxy Asset",
    "asset.proxy.find-by-id.clip.error": "There was a problem loading proxy information, please reload the page.",
    "asset.proxy.find-by-id.error": "There was a problem loading video information, please reload the page.",
    "asset.proxy.find-by-id.history.error": "There was a problem loading proxy action history, please try again.",
    "asset.proxy.find-by-id.thumbnails.error": "Error Loading Video Thumbnails",
    "asset.proxy.preview": "Preview Proxy",
    "asset.proxy.save": "Save",
    "asset.proxy.save.error": "Unable to update Proxy",
    "asset.proxy.save.success": "Proxy Successfully Saved",
    "asset.proxy.save.summary.error": "There was an error saving the proxy summary, please try again",
    "asset.proxy.summary.created": "Created Date",
    "asset.proxy.summary.created-by": "Created By",
    "asset.proxy.summary.display-name": "Display Name",
    "asset.proxy.summary.forensic": "Forensic A/B",
    "asset.proxy.summary.forensic-enabled": "A/B Enabled",
    "asset.proxy.summary.name": "Name",
    "asset.proxy.summary.proxy-info": "Proxy Info",
    "asset.proxy.summary.updated": "Last Modified Date",
    "asset.proxy.summary.updated-by": "Last Modifier",
    "asset.proxy.title": "Edit Proxy Asset",
    "asset.remove.from.talent": "Remove From Talent",
    "asset.remove.from.title": "Remove From Title",
    "asset.script.body": "Body",
    "asset.script.contentType": "Content Type",
    "asset.script.copyright.text": "Copyright Text",
    "asset.script.copyright.year": "Copyright Year",
    "asset.script.create.error": "Unable to create Script, please try again",
    "asset.script.create.info": "Please fill out the form below to create a new script asset.",
    "asset.script.create.success": "Script Created",
    "asset.script.create.title": "Create Script Asset",
    "asset.script.edit.error": "Unable to save Script. Please try again",
    "asset.script.edit.info": "Please review script info below.",
    "asset.script.edit.success": "Script Successfully Saved",
    "asset.script.edit.title": "Edit Script Asset",
    "asset.script.file.download": "Download File",
    "asset.script.file.replace": "Replace File",
    "asset.script.file.upload": "Upload File",
    "asset.script.load-error": "Error Loading Asset Script",
    "asset.script.summary.assetComment": "Display Name",
    "asset.script.summary.contentType": "Content Type",
    "asset.script.summary.episodeNumber": "Episode Number",
    "asset.script.summary.fileCheckSum": "File CheckSum",
    "asset.script.summary.fileInfo": "File Info",
    "asset.script.summary.fileName": "File Name",
    "asset.script.summary.filePath": "File Path",
    "asset.script.summary.fileSize": "File Size",
    "asset.script.summary.fileType": "File Type",
    "asset.script.summary.fullResolutionUrl": "Full Resolution URL",
    "asset.script.summary.language": "Language",
    "asset.script.summary.metaModel": "Meta Model",
    "asset.script.summary.mpmNumber": "MPM Number",
    "asset.script.summary.name": "Name",
    "asset.script.summary.previewUrl": "Preview URL",
    "asset.script.summary.scriptType": "Script Type",
    "asset.script.summary.seasonNumber": "Season Number",
    "asset.script.summary.sourceUrl": "Source URL",
    "asset.script.summary.territory": "Territory",
    "asset.script.summary.thumbnailUrl": "Thumbnail URL",
    "asset.script.summary.title": "Title",
    "asset.subscription.send-notifications.failed": "There was an error sending subscription notifications. Please contact support.",
    "asset.subscription.send-notifications.success": "Subscription notifications successfully sent",
    "asset.talent.add.name": "Name (Credited As)",
    "asset.talent.add.title": "Add Talent",
    "asset.talent.tab.add": "Add Talent",
    "asset.talent.tab.remove": "Remove",
    "asset.talent.tab.title": "Talent",
    "asset.timeline.edit-clips.button": "View/Edit Clips",
    "asset.timeline.preview": "Preview Timeline",
    "asset.timeline.send-to-oap.error": "Error sending Timeline to OAP.",
    "asset.timeline.send-to-oap.success": "Successfully sent to OAP.",
    "asset.timeline.summary.active": "Active",
    "asset.timeline.summary.brainiac.button": "Edit in Brainiac",
    "asset.timeline.summary.content-type": "Content Type",
    "asset.timeline.summary.delivery-type": "Delivery Type",
    "asset.timeline.summary.editor-info": "Editor Info",
    "asset.timeline.summary.hardac.button": "Edit in HARDAC",
    "asset.timeline.summary.name": "Timeline Name",
    "asset.timeline.summary.protection-type": "Protection Type",
    "asset.timeline.summary.timeline-info": "Timeline Info",
    "asset.timeline.title": "Edit Timeline Asset",
    "asset.user.tab.title": "Users",
    "asset.users.load-error": "There was an error loading the list of users, the users tab will show empty.",
    "asset.video.create.assetComment": "Display Name",
    "asset.video.create.audio-configuration":"Audio Configuration",
    "asset.video.create.cannot-activate-invalid-mediakey": "You cannot activate a video asset that does not have a HARDAC or BRAINIAC media key.",
    "asset.video.create.contentType": "Content Type",
    "asset.video.create.copyrightText": "Copyright Text",
    "asset.video.create.copyrightYear": "Copyright Year",
    "asset.video.create.credits-start-timecode": "Credits Start Timecode",
    "asset.video.create.delivery-formats": "Delivery Formats",
    "asset.video.create.delivery-type": "Delivery Type",
    "asset.video.create.disable-offline-playback": "Disable Offline Playback",
    "asset.video.create.disable-offline-playback.legend": "Removes ability to download offline copy of video",
    "asset.video.create.disable-visual-watermark": "Disable Visual Watermark",
    "asset.video.create.disable-visual-watermark.legend": "Hides watermark on video playback",
    "asset.video.create.disclaimer":"Disclaimer",
    "asset.video.create.dubs":"Dubs",
    "asset.video.create.enable-geo-restriction": "Enable Geo-restriction",
    "asset.video.create.enable-geo-restriction.error": "Sorry, there was an error while enabling geo-restriction for this asset. Please contact support.",
    "asset.video.create.enable-geo-restriction.modal.body": "To enable geo restriction for this asset, please enter the region identifier then click the button below. The Limelight forensic URLs will be updated to include the region identifier and the Akamai url will be removed.",
    "asset.video.create.enable-geo-restriction.modal.label": "Geo-restriction region identifier",
    "asset.video.create.enable-geo-restriction.modal.note": "Note: this action cannot be undone!",
    "asset.video.create.enable-geo-restriction.success": "Geo-restriction has been enabled for this asset",
    "asset.video.create.error": "Unable to Create Video, please try again",
    "asset.video.create.forensic-url-required": "Has Forensic URLs",
    "asset.video.create.has-audio": "Has Audio",
    "asset.video.create.has-dubs":"Has Dubs",
    "asset.video.create.has-sub":"Has Subs",
    "asset.video.create.info": "Please fill out the form below to create a new video asset.",
    "asset.video.create.keywords": "Keywords",
    "asset.video.create.language":"Language",
    "asset.video.create.media": "Media",
    "asset.video.create.mediakey": "Media Key",
    "asset.video.create.mfa-required": "Require MFA",
    "asset.video.create.mfa-required.legend": "Requires multi-factor authentication before viewing",
    "asset.video.create.name": "Name",
    "asset.video.create.name.placeholder": "Enter here...",
    "asset.video.create.notes": "Notes",
    "asset.video.create.on-dete": "On DETE",
    "asset.video.create.persistent-watermark": "Persistent Watermark",
    "asset.video.create.persistent-watermark.legend": "Watermark will display on screen during duration of video",
    "asset.video.create.reference-id": "Reference ID",
    "asset.video.create.rights": "Rights",
    "asset.video.create.rights-info": "Rights Info",
    "asset.video.create.runtime": "Runtime",
    "asset.video.create.serviceable": "Serviceable",
    "asset.video.create.subtitles": "Subtitles",
    "asset.video.create.success": "Video Created",
    "asset.video.create.tapes": "Barcode",
    "asset.video.create.terms": "Terms",
    "asset.video.create.territory": "Territory",
    "asset.video.create.texted":"Texted",
    "asset.video.create.texted-textless":"Texted/Textless",
    "asset.video.create.textless":"Textless",
    "asset.video.create.title": "Create Video Asset",
    "asset.video.create.upload": "Videos",
    "asset.video.create.videoSynopsis": "Video Synopsis",
    "asset.video.create.watermark": "Custom Watermark",
    "asset.video.create.watermark.legend": "If this box has text, replaces a portion of the default watermark text with above text",
    "asset.video.defaultVideoBanner.remove": "Remove As Default Video Banner",
    "asset.video.defaultVideoBanner.set": "Set As Default Video Banner",

    "asset.video.edit.captions.actions": "Actions",
    "asset.video.edit.captions.add": "Add Captions/Subtitles",
    "asset.video.edit.captions.date": "Date",
    "asset.video.edit.captions.delete.confirmation": "Are you sure you want to delete this file and its modified child versions?",
    "asset.video.edit.captions.delete.title": "Delete File",
    "asset.video.edit.captions.filename": "File: {filename}",
    "asset.video.edit.captions.vtt-file.delete.error": "Error deleting captions/subtitles file.",
    "asset.video.edit.captions.vtt-file.manifest-regen.error": "Error updating video manifest to include/remove a captions/subtitles file.",
    "asset.video.edit.captions.vtt-file.upload.error": "Error uploading captions/subtitles file.",
    "asset.video.edit.captions.id": "ID",
    "asset.video.edit.captions.make-active": "Make Active",
    "asset.video.edit.captions.make-inactive": "Make Inactive",
    "asset.video.edit.captions.notes": "Notes",
    "asset.video.edit.captions.source": "Source",
    "asset.video.edit.captions.source.mastering": "Mastering",
    "asset.video.edit.captions.source.user": "User",
    "asset.video.edit.captions.source.vi": "VI",
    "asset.video.edit.captions.status": "Status",
    "asset.video.edit.captions.status.passedqc": "Reviewed / Passed QC",
    "asset.video.edit.captions.status.unreviewed": "Not Reviewed Yet",
    "asset.video.edit.captions.title": "Captions & Subtitles",
    "asset.video.edit.captions.type": "Type",
    "asset.video.edit.captions.type.cc": "CC",
    "asset.video.edit.captions.type.sub": "SUB",

    "asset.video.edit.add-image": "Add Image",
    "asset.video.edit.error": "Unable to Save Video, please try again",
    "asset.video.edit.info": "Please review asset info below.",
    "asset.video.edit.success": "Video Successfully Saved",
    "asset.video.edit.summary.disclaimer.title": "Disclaimer",
    "asset.video.edit.summary.streaming-urls.title": "Streaming Urls",
    "asset.video.edit.summary.watermark.title": "Visual Watermark",
    "asset.video.edit.title": "Edit Video Asset",
    "asset.video.error.streams": "Error Loading Video Streams",
    "asset.video.error.thumbnails": "Error Loading Video Thumbnails",
    "asset.video.forensic-url.title": "Forensic URLs",
    "asset.video.hardac-source": "HARDAC Source",
    "asset.video.info": "Presentation Properties",
    "asset.video.info-playback-properties": "Playback Properties",
    "asset.video.info-serviceable-properties": "Serviceable Properties",
    "asset.video.info-source-properties": "Source Properties",
    "asset.video.load-error": "Error Loading Asset Video",
    "asset.video.mfa": "MFA",

    "asset.video.playback-restrictions": "Playback Restrictions",
    "asset.video.playback-restrictions.error.partner-api": "Sorry, there was an error loading the video restrictions data. Check your permissions and try again.",
    "asset.video.playback-restrictions.platform.all-platforms": "Allowed Platforms",
    "asset.video.playback-restrictions.platform.allow-on-all.description": "The default for all videos, allows video playback on all platforms.",
    "asset.video.playback-restrictions.platform.allow-on-all": "Allow On All",
    "asset.video.playback-restrictions.platform.custom-advanced.description": "Customized settings, which require defining which partners will allow playback.",
    "asset.video.playback-restrictions.platform.custom-advanced": "Custom/Advanced",
    "asset.video.playback-restrictions.platform.disable-on-web.description": "Disables playback on web platforms. Content will only be visible in apps.",
    "asset.video.playback-restrictions.platform.disable-on-web": "Disable On Web",
    "asset.video.playback-restrictions.platform.only-allow-on-tv-platforms.description": "Disables playback on platforms except TV-based apps.",
    "asset.video.playback-restrictions.platform.only-allow-on-tv-platforms": "Only Allow On TV Platforms",
    "asset.video.playback-restrictions.platform.select-platform": "Select platforms...",
    "asset.video.playback-restrictions.platform": "Platform",
    "asset.video.playback-restrictions.playback-number.important-note.description": "This field defines how many times this video can be watched by each user.",
    "asset.video.playback-restrictions.playback-number.important-note": "Important Note",
    "asset.video.playback-restrictions.playback-number.max-views-allowed.description": "Leave empty if there are no restrictions.",
    "asset.video.playback-restrictions.playback-number.max-views-allowed": "Maximum Views Allowed",
    "asset.video.playback-restrictions.playback-number": "Playback Number",
    "asset.video.playback-restrictions.restrict-by-ip-address.description": "If this box is checked, some user roles will restrict how many IP Addresses an individual can use to access this asset.",
    "asset.video.playback-restrictions.restrict-by-ip-address": "Restrict By IP Address",
    "asset.video.playback-restrictions.restrictions": "Restrictions",

    "asset.video.poster.image.size": "Poster Images must be exactly 1920 x 1080 px.",
    "asset.video.poster.image": "Poster Image",
    "asset.video.side-notes.title": "Notes",
    "asset.video.thumbnail.replace-image": "Replace Image",
    "asset.video.thumbnail.save-error": "Error saving Video Thumbnail frame",
    "asset.video.thumbnail.select-image": "Select Image",
    "asset.video.thumbnail.select-frame": "Choose Frame From Video",
    "asset.video.thumbnail.select-thumbnail": "Select Thumbnail",
    "asset.video.thumbnail.select-title": "Select New Thumbnail",
    "asset.video.upload": "Attach Video File",
    "asset.video.video-preview": "Preview Video",
    "asset.video.web-vtt.load-error": "Error retrieving WebVTT files for CC and subtitles",

    "assets.browse.asset-type.all.title": "Browse Assets",
    "assets.browse.asset-type.document.title": "Browse Documents",
    "assets.browse.asset-type.image.title": "Browse Images",
    "assets.browse.asset-type.video.title": "Browse Videos",
    "assets.browse.loading-catalogs": "Loading Catalogs...",
    "assets.browse.no-catalogs": "No Catalogs",

    "authorized-partners.api-keys.modal.add": "Add",
    "authorized-partners.api-keys.modal.api-key": "Api Key",
    "authorized-partners.api-keys.modal.api-key.placeholder": "Api Key ...",
    "authorized-partners.api-keys.modal.title": "Add Api Key",
    "authorized-partners.api-keys.modal.user": "User",
    "authorized-partners.api-keys.modal.user.placeholder": "Search user...",
    "authorized-partners.client": "Client",
    "authorized-partners.create-partner": "Create Authorized Partner",
    "authorized-partners.create.error": "Unable to create Authorized Partner, please try again",
    "authorized-partners.create.info.active": "Active",
    "authorized-partners.create.info.cdn-billing-code": "CDN Billing Code",
    "authorized-partners.create.info.client": "Client",
    "authorized-partners.create.info.client.placeholder": "Please enter client",
    "authorized-partners.create.info.default-site-url": "Default Site URL",
    "authorized-partners.create.info.default-site-url.placeholder": "Please enter Partner's URL",
    "authorized-partners.create.info.copyright": "Copyright",
    "authorized-partners.create.info.copyright.placeholder": "Please enter a copyright",
    "authorized-partners.create.info.description": "Description",
    "authorized-partners.create.info.description.placeholder": "Enter here...",
    "authorized-partners.create.info.display": "Display Name",
    "authorized-partners.create.info.display.placeholder": "Please enter a display name...",
    "authorized-partners.create.info.name": "Name",
    "authorized-partners.create.info.name.placeholder": "Please enter name",
    "authorized-partners.create.info.platform": "Platform",
    "authorized-partners.create.info.platform.type": "Platform Type",
    "authorized-partners.create.info.platform.usage": "Usage Type",
    "authorized-partners.create.info.session-life-span": "Session Life Span (hours)",
    "authorized-partners.create.info.session-timeout": "Session Timeout (minutes)",
    "authorized-partners.create.info.signature": "Signature",
    "authorized-partners.create.info.signature.placeholder": "Please enter signature...",
    "authorized-partners.create.info.title": "Authorized Partner Info",
    "authorized-partners.create.roles.choose": "Choose roles to add",
    "authorized-partners.create.roles.title": "Roles",
    "authorized-partners.create.security": "Client Key",
    "authorized-partners.create.security.title": "Security",
    "authorized-partners.create.success": "Authorized Partner created successfully",
    "authorized-partners.create.title": "Create an Authorized Partner",
    "authorized-partners.create.title.info": "Please fill out the form below to create a new authorized partner.",
    "authorized-partners.create.upload.logo.error": "Unable to upload file.",
    "authorized-partners.create.upload.logo.label": "Replace Logo",
    "authorized-partners.create.upload.logo.title": "Logo",
    "authorized-partners.date-added": "Date added",
    "authorized-partners.delete.error": "Unable to delete Authorized Partner",
    "authorized-partners.delete.success": "Authorized Partner Deleted",
    "authorized-partners.description": "Description",
    "authorized-partners.edit.api.keys": "API Keys",
    "authorized-partners.edit.error": "Unable to save changes",
    "authorized-partners.edit.last-modified-date": "Last Modified Date",
    "authorized-partners.edit.last-modifier": "Last Modifier",
    "authorized-partners.edit.modal.body": "Are you sure you want to Delete this Authorized Partner?",
    "authorized-partners.edit.modal.confirm": "Confirm",
    "authorized-partners.edit.modal.title": "Please Confirm Deletion",
    "authorized-partners.edit.stations.assigned": "Assigned Stations",
    "authorized-partners.edit.stations.error": "Sorry, there was an error loading Stations, please contact support.",
    "authorized-partners.edit.stations.unassigned": "Unassigned Stations",
    "authorized-partners.edit.stations": "Stations",
    "authorized-partners.edit.success": "Authorized Partner Saved",
    "authorized-partners.edit.title.info": "Review the authorized partner information below.",
    "authorized-partners.edit.title": "Edit an Authorized Partner",
    "authorized-partners.edit.user-groups.assigned": "Assigned User Groups",
    "authorized-partners.edit.user-groups.error": "Sorry, there was an error loading User Groups, please contact support.",
    "authorized-partners.edit.user-groups.table.actions": "Actions",
    "authorized-partners.edit.user-groups.table.description": "Description",
    "authorized-partners.edit.user-groups.table.name": "Name",
    "authorized-partners.edit.user-groups.table.status": "Status",
    "authorized-partners.edit.user-groups.unassigned": "Unassigned User Groups",
    "authorized-partners.edit.user-groups": "User Groups",
    "authorized-partners.partner-name": "Partner Name",
    "authorized-partners.save.error": "Unable to save changes",
    "authorized-partners.save.groups.error": "Unable to save group change. Please contact support",
    "authorized-partners.save.success": "Authorized Partner saved",
    "authorized-partners.summary": "Summary",
    "authorized-partners.title": "Authorized Partners",

    "brands.browse.get.error.does-not-have-access": "Failed to load brands, you may not have access to the data. Please contact your administrator.",
    "brands.browse.get.error.does-not-load": "Failed to load brands, please try reloading the page or contact your administrator.",
    "brands.browse.table.active": "Active",
    "brands.browse.table.created-date": "Created Date",
    "brands.browse.table.inactive": "Inactive",
    "brands.browse.table.name": "Name",
    "brands.browse.table.status": "Status",
    "brands.browse.table.updated-date": "Updated Date",
    "brands.browse.title": "Brands",
    "brands.create.logo.image.dimensions": "Uploaded logo image dimensions ({}) do not meet the required dimensions ({})",
    "brands.create.logo.image.filesize": "Uploaded logo image file size ({}) exceeds the maximum file size of 1MB",
    "brands.create.save.error": "Unable to create Brand",
    "brands.create.save.success": "Brand created",
    "brands.create.save.text": "Create",
    "brands.create.title": "Create Brand",
    "brands.edit.save.error": "Unable to update Brand",
    "brands.edit.save.success": "Brand updated",
    "brands.edit.save.text": "Save",
    "brands.edit.title": "Edit Brand",
    "brands.edit.upload.logo-requirements": "Logo file requirements",
    "brands.edit.upload.logo-requirements.dimensions": "Logo must be 500px x 500px",
    "brands.edit.upload.logo-requirements.extension": "Logo must be a PNG",
    "brands.edit.upload.logo-requirements.filesize": "Logo cannot be larger than 1MB in size",
    "brands.edit.upload.logo.error": "Failed to upload brand logo image. Please contact support.",
    "brands.edit.upload.logo.label": "Replace Brand Logo Image",
    "brands.edit.upload.logo.title": "Brand Logo Image",
    "brands.find-by-id.error.does-not-have-access": "Failed to load brand, you may not have access to the data. Please contact your administrator.",
    "brands.summary.active": "Active",
    "brands.summary.create": "Create Brand Summary",
    "brands.summary.description": "Description",
    "brands.summary.edit": "Edit Brand Summary",
    "brands.summary.info": "Brand Info",
    "brands.summary.last-modified-date": "Last Modified Date",
    "brands.summary.last-modifier": "Last Modifier",
    "brands.summary.name": "Name",
    "brands.summary.status": "Status",

    "catalogs.asset.add-assets": "Add Assets",
    "catalogs.asset.browse": "Browse Asset Catalogs",
    "catalogs.asset.create.button": "Create Asset Catalog",
    "catalogs.asset.create.error": "Unable to create Asset Catalog, please try again",
    "catalogs.asset.create.success": "Asset Catalog created successfully",
    "catalogs.asset.create.title": "Create Asset Catalog",
    "catalogs.asset.criteria.contentType": "Content Type",
    "catalogs.asset.criteria.existingCatalog": "Existing Catalog",
    "catalogs.asset.criteria.title": "Title",
    "catalogs.asset.delete.error": "Unable to delete Asset Catalog",
    "catalogs.asset.delete.success": "Asset Catalog Deleted",
    "catalogs.asset.edit.asset": "Edit Asset Catalog",
    "catalogs.asset.edit.confirm-delete.asset" : "Please Confirm Deletion",
    "catalogs.asset.edit.confirm-delete.body" : "Are you sure you want to Delete this Asset Catalog?",
    "catalogs.asset.edit.confirm-delete.confirm" : "Confirm",
    "catalogs.asset.edit.error": "Unable to edit Asset Catalog, please try again",
    "catalogs.asset.edit.success": "Asset Catalog edited successfully",
    "catalogs.asset.edit.title": "Edit Asset Catalog",
    "catalogs.asset.type": "Asset Type",
    "catalogs.delete.action-required": "Action Required",
    "catalogs.delete.description": "Before deleting a catalog, you must make it inactive and hit save.",
    "catalogs.delete.title": "Note About Deleting",
    "catalogs.description": "Description",
    "catalogs.event-catalogs.warning": "This catalog is not editable in this screen.  Please make your changes in the event.",
    "catalogs.info": "Catalog Info",
    "catalogs.language.browse": "Browse Language Catalogs",
    "catalogs.language.create.button": "Create Language Catalog",
    "catalogs.language.create.error": "Unable to create Language Catalog, please try again",
    "catalogs.language.create.success": "Language Catalog created successfully",
    "catalogs.language.create.title": "Create Language Catalog",
    "catalogs.language.delete.error": "Unable to delete Language Catalog",
    "catalogs.language.delete.success": "Language Catalog Deleted",
    "catalogs.language.edit.confirm-delete.body" : "Are you sure you want to Delete this Language Catalog?",
    "catalogs.language.edit.confirm-delete.confirm" : "Confirm",
    "catalogs.language.edit.confirm-delete.title" : "Please Confirm Deletion",
    "catalogs.language.edit.error": "Unable to edit Language Catalog, please try again",
    "catalogs.language.edit.success": "Language Catalog edited successfully",
    "catalogs.language.edit.title": "Edit Language Catalog",
    "catalogs.last-modified-date": "Last Modified Date",
    "catalogs.last-modifier": "Last Modifier",
    "catalogs.mass-update-asset-length": "Assets uploaded.",
    "catalogs.mass-update-asset.step-two.title": "Step 2: Review Uploaded Assets",
    "catalogs.mass-update-asset.title": "Mass Update Asset Catalogs",
    "catalogs.mass-update-language.title": "Mass Update Language Catalogs",
    "catalogs.mass-update-title-length": "Titles uploaded.",
    "catalogs.mass-update-title.step-two.title": "Step 2: Review Uploaded Titles",
    "catalogs.mass-update-title.title": "Mass Update Title Catalogs",
    "catalogs.mass-update.button.add": "Add",
    "catalogs.mass-update.button.add-replace": "Add/Replace",
    "catalogs.mass-update.button.add-to-existing": "Add to Existing",
    "catalogs.mass-update.button.back": "Back",
    "catalogs.mass-update.button.remove": "Remove",
    "catalogs.mass-update.button.remove-selected": "Remove Selected",
    "catalogs.mass-update.button.replace": "Replace",
    "catalogs.mass-update.download-template":"Download Template",
    "catalogs.mass-update.error.file-format": "Invalid file format. Please upload a CSV file.",
    "catalogs.mass-update.error.loading": "Error loading. Please try again.",
    "catalogs.mass-update.error.loading.asset.info": "There was an error loading this value. You can still update catalogs for this asset by using 'Replace' mode.",
    "catalogs.mass-update.error.loading.title.info": "There was an error loading this value. You can still update catalogs for this title by using 'Replace' mode.",
    "catalogs.mass-update.error.uploading-file": "Error uploading file. Please try again.",
    "catalogs.mass-update.language.title": "Mass Update Language Catalogs",
    "catalogs.mass-update.row-header.asset-id": "Asset Id",
    "catalogs.mass-update.row-header.asset-type": "Asset Type",
    "catalogs.mass-update.row-header.catalogs": "Existing Catalogs",
    "catalogs.mass-update.row-header.index": "#",
    "catalogs.mass-update.row-header.name": "Name",
    "catalogs.mass-update.row-header.title-id": "Title Id",
    "catalogs.mass-update.row-header.title-type": "Title Type",
    "catalogs.mass-update.save.error": "There was an error saving catalogs",
    "catalogs.mass-update.save.error.noCatalogs": "You haven't selected any new Catalogs to add.",
    "catalogs.mass-update.save.errorAdd": "Add Catalogs failed.",
    "catalogs.mass-update.save.errorRemove": "Remove Catalogs failed.",
    "catalogs.mass-update.save.errorReplace":"Replace Catalogs failed.",
    "catalogs.mass-update.save.success": "Mass Update Asset Catalogs successfully updated",
    "catalogs.mass-update.save.successAdd": "Add Catalogs successful.",
    "catalogs.mass-update.save.successRemove": "Remove Catalogs successful.",
    "catalogs.mass-update.save.successReplace":"Replace Catalogs successful.",
    "catalogs.mass-update.status.active": "Active",
    "catalogs.mass-update.status.inactive": "Inactive",
    "catalogs.mass-update.step-one.title": "Step 1: Import Spreadsheet",
    "catalogs.mass-update.step-three.title": "Step 3: Update Catalogs",
    "catalogs.mass-update.upload-file":"Upload file",
    "catalogs.name": "Catalog name",
    "catalogs.status": "Status",
    "catalogs.summary-tab-title": "Summary",
    "catalogs.title.browse": "Browse Title Catalogs",
    "catalogs.title.create.button": "Create Title Catalog",
    "catalogs.title.create.error": "Unable to create Title Catalog, please try again",
    "catalogs.title.create.success": "Title Catalog created successfully",
    "catalogs.title.create.title": "Create Title Catalog",
    "catalogs.title.delete.error": "Unable to delete Title Catalog",
    "catalogs.title.delete.success": "Title Catalog Deleted",
    "catalogs.title.edit.confirm-delete.body" : "Are you sure you want to Delete this Title Catalog?",
    "catalogs.title.edit.confirm-delete.confirm" : "Confirm",
    "catalogs.title.edit.confirm-delete.title" : "Please Confirm Deletion",
    "catalogs.title.edit.error": "Unable to edit Title Catalog, please try again",
    "catalogs.title.edit.success": "Title Catalog edited successfully",
    "catalogs.title.edit.title": "Edit Title Catalog",
    "catalogs.station.create.button": "Create Station",
    "catalogs.station.create.error": "Unable to create Station Catalog, please contact support",
    "catalogs.station.create.success": "Station created successfully",
    "catalogs.station.delete.error": "Unable to delete Station",
    "catalogs.station.delete.success": "Station Deleted",
    "catalogs.station.edit.confirm-delete.body" : "Are you sure you want to Delete this Station?",
    "catalogs.station.edit.confirm-delete.confirm" : "Confirm",
    "catalogs.station.edit.confirm-delete.title" : "Please Confirm Deletion",
    "catalogs.station.edit.error": "Unable to edit Station, please try again",
    "catalogs.station.edit.success": "Station edited successfully",
    "catalogs.type": "Catalog Type",

    "cc-editor.add-new-vtt-modal.header": "Add New",
    "cc-editor.add-new-vtt-modal.description": "Creates a new file and associates with current asset.",
    "cc-editor.add-new-vtt-modal.create-btn": "Create New",
    "cc-editor.cue.cancel.confirm": "Are you sure you want to reset all changes for selected item(s)?",
    "cc-editor.cue.remove.confirm": "Are you sure you want to remove selected item(s)?",
    "cc-editor.confirm.duplicate.unsaved-message": "You have unsaved changes which will not appear in the duplicate. Are you sure you want to create a duplicate?",
    "cc-editor.duplicate-modal.create": "Create Duplicate",
    "cc-editor.duplicate-modal.description": "Creates a new caption file, based on the currently selected caption file.",
    "cc-editor.duplicate-modal.title": "Duplicate Caption File",
    "cc-editor.error.no-vtt": "Error occurred on detect VTT file",
    "cc-editor.error.load-vtt": "Error occurred on load VTT file'",
    "cc-editor.header.duplicate": "Duplicate",
    "cc-editor.header.link-tooltip": "Keeps same clip length when changing start or end time",
    "cc-editor.header.selection-tips": "Click caption text to edit, or hold \"shift\" key to select multiple.",
    "cc-editor.header.validations.end-time.overlaps": "Your end timecode occurs after the next dialogue start timecode.",
    "cc-editor.header.validations.start-time.overlaps": "Your start timecode occurs before the prior dialogue end timecode.",
    "cc-editor.settings-modal.header": "Caption Settings",
    "cc-editor.settings-modal.orientation": "Orientation",
    "cc-editor.shift-caption-modal.description": "Changing the start time will ripple to all subsequent captions.",
    "cc-editor.shift-caption-modal.header": "Overwrite Caption Timing?",
    "cc-editor.shift-caption-modal.start": "Start",
    "cc-editor.shift-caption-modal.title": "Shift Caption Start Time",
    "cc-editor.tab-title.captions": "Captions",
    "cc-editor.tab-title.settings": "Settings",
    "cc-editor.tab.settings.export": "Export Captions",
    "cc-editor.tab.settings.global": "Global Options",
    "cc-editor.tab.settings.import": "Import Captions",
    "cc-editor.tab.settings.import-export": "Import/Export",
    "cc-editor.tab.settings.import-file": "Import Caption File",
    "cc-editor.tab.settings.shift-captions": "Shift Start Time For All Captions",
    "cc-editor.tab.settings.shift-captions-start": "Shift Caption Start",
    "cc-editor.tab.settings.video-asset": "Video Asset",
    "cc-editor.title": "Caption Editor",
    "cc-editor.timeline.current": "Current captions",
    "cc-editor.timeline.origin": "Original captions",
    "cc-editor.upload.modal.description": "This action will erase existing caption data and replace captions with the uploaded file.",
    "cc-editor.upload.modal.header": "Overwrite Captions?",
    "cc-editor.upload.modal.messages.incorrect-file-type": "You are selected incorrect type of file. Please, select correct VTT file",
    "cc-editor.upload.modal.messages.no-file-selected": "Something went wrong. You didn't select any file",
    "cc-editor.video-asset.find-by-id.error": "Failed to load asset, you may not have access to the data. Please contact your administrator.",
    "cc-editor.video-player.sync.label": "Sync Playback Video/Captions/Timeline",
    "cc-editor.vtt.active": "Active",
    "cc-editor.vtt.field.active": "Active/Inactive",
    "cc-editor.vtt.field.date": "Date",
    "cc-editor.vtt.field.filename": "Caption Filename",
    "cc-editor.vtt.field.id": "ID",
    "cc-editor.vtt.field.language": "Language",
    "cc-editor.vtt.field.notes": "Notes",
    "cc-editor.vtt.field.parent": "Parent",
    "cc-editor.vtt.field.source": "Source",
    "cc-editor.vtt.field.status": "Status",
    "cc-editor.vtt.field.type": "Type",
    "cc-editor.vtt.inactive": "Inactive",
    "cc-editor.vtt.save.error": "Unable to save WebVTT file, please try again",
    "cc-editor.vtt.save.duplicate.success": "Duplicate of VTT file was created successfully",
    "cc-editor.vtt.save.success": "VTT file was uploaded successfully",
    "cc-editor.vtt.status.reviewed": "Reviewed / Passed QC",
    "cc-editor.vtt.source.vi": "VI",
    "cc-editor.vtt.source.mastering": "MASTERING",
    "cc-editor.vtt.source.user": "USER",
    "cc-editor.vtt.upload-file": "Upload File",

    "common.accounts.bulk_generate.confirm.ignore": "Discard Changes and Add Users",
    "common.accounts.bulk_generate.confirm.message": "This event has unsaved changes.",
    "common.accounts.bulk_generate.confirm.save": "Add Users and Save Changes",
    "common.accounts.bulk_generate.confirm.secondary-message": "Adding users to the event without saving will discard those changes.",
    "common.accounts.bulk_generate.confirm.title": "Confirm Add Users",
    "common.accounts.bulk_generate.modal.admin": "Generate this batch as admin accounts",
    "common.accounts.bulk_generate.modal.count.error": "Additional Accounts must be greater than zero",
    "common.accounts.bulk_generate.modal.count": "Additional Accounts Needed",
    "common.accounts.bulk_generate.modal.ok": "Generate Accounts",
    "common.accounts.bulk_generate.modal.title": "Bulk Create Anonymous Accounts",
    "common.actions": "Actions",
    "common.active": "Active",
    "common.add-existing": "Add Existing",
    "common.add-new": "Add New",
    "common.add-users": "Add Users",
    "common.add": "Add",
    "common.alerts-warnings-title": "Alerts / Warnings",
    "common.alerts-warnings.error.capitalization": "should start with a capital letter.",
    "common.alerts-warnings.error.domainInBlacklist": "is from a blacklisted domain",
    "common.alerts-warnings.error.max": "is too long.",
    "common.alerts-warnings.error.min": "is too short.",
    "common.alerts-warnings.error.notEmail": "is not a valid email address format.",
    "common.alerts-warnings.error.required": "is a required field.",
    "common.approve": "Approve",
    "common.asset-rights.media": "Media",
    "common.asset-rights.notes": "Notes",
    "common.asset-rights.rights-info": "Rights Info",
    "common.asset-rights.rights": "Rights",
    "common.asset-rights.terms": "Terms",
    "common.asset-rights.territory": "Territory",
    "common.auto-refresh.description": "Automatically refreshes table every few seconds",
    "common.auto-refresh": "Auto Refresh",
    "common.boolean.false": "No",
    "common.boolean.true": "Yes",
    "common.cancel": "Cancel",
    "common.choose-group": "Choose Group",
    "common.close-options": "Close Options",
    "common.close": "Close",
    "common.collapse-all": "Collapse All",
    "common.confirm-change": "Confirm Change",
    "common.confirm": "Confirm",
    "common.country-select.placeholder": "Select Country",
    "common.create-asset": "Create Asset",
    "common.create.add-tab.modal.button": "Add Tab",
    "common.create.add-tab.modal.select": "Select an Available Tab",
    "common.create.add-tab.modal.title": "Add Tab",
    "common.create.add-tab": "+",
    "common.delete": "Delete",
    "common.deny": "Deny",
    "common.description": "Description",
    "common.done": "Done",
    "common.download-csv-template": "Download CSV Template",
    "common.duplicate": "Duplicate",
    "common.edit": "Edit",
    "common.email": "Email",
    "common.errors-warnings-title": "Errors / Warnings",
    "common.expand-all": "Expand All",
    "common.export-data": "Export Data",
    "common.export-users": "Export List of Users",
    "common.exportExcel": "Generate Excel of Results",
    "common.file-list": "File:",
    "common.files-list": "File(s):",
    "common.filteringOptions": "Filtering Options",
    "common.handleClick.description": "View Full Description",
    "common.history.error": "There was an error while loading Action History, please try again.",
    "common.inactive": "Inactive",
    "common.last-modified-date": "Last Modified Date",
    "common.last-modifier": "Last Modifier",
    "common.learn-more": "Learn more about usage in the help page",
    "common.load-error": "Sorry, there was an error loading page data, please try again",
    "common.load-partner.error": "Sorry, there was an error loading partners, please try again.",
    "common.na": "N/A",
    "common.name-and-description": "Name & Description",
    "common.name": "Name",
    "common.note": "Note",
    "common.ok": "OK",
    "common.pagination.go": "Go!",
    "common.pagination.next": "Next",
    "common.pagination.previous": "Prev",
    "common.partner-select.placeholder": "Select Partner",
    "common.please-select": "Please Select",
    "common.reject": "Reject",
    "common.reload-page": "Please reload the page to see your latest changes",
    "common.remove": "Remove",
    "common.request-info": "Request Info",
    "common.save-and-close": "Save & Close",
    "common.save-and-create": "Save & Create",
    "common.save": "Save",
    "common.search": "Search",
    "common.select-catalogs": "Select Catalogs",
    "common.select-file": "Select File",
    "common.select-files": "Select File(s)",
    "common.select-group": "Select Group",
    "common.select-spreadsheet": "Select Spreadsheet",
    "common.select-users": "Select Users",
    "common.select.placeholder": "Select...",
    "common.select": "Select",
    "common.selected": "Selected",
    "common.show-inactive": "Show Inactive",
    "common.side-notes.add-new": "Add New",
    "common.side-notes.add-note.body": "Add note:",
    "common.side-notes.add-note.button": "Add Note",
    "common.side-notes.add-note.error" : "Note failed to save, please try again",
    "common.side-notes.add-note.success" : "Note added",
    "common.side-notes.add-note.title": "Add Note",
    "common.side-notes.empty.notes" : "No Notes Yet",
    "common.side-notes.title": "Activity & Notes",
    "common.sourceFiles": "Source File(s)",
    "common.star.add": "Add to Favorites",
    "common.star.remove": "Remove from Favorites",
    "common.status": "Status",
    "common.submit": "Submit",
    "common.tab-title.assets": "Assets",
    "common.tab-title.audio": "Audio",
    "common.tab-title.catalogs": "Catalogs",
    "common.tab-title.documents": "Documents",
    "common.tab-title.history": "History",
    "common.tab-title.images": "Images",
    "common.tab-title.merchandise": "Merchandise",
    "common.tab-title.processes": "Processes",
    "common.tab-title.scripts": "Scripts",
    "common.tab-title.summary": "Summary",
    "common.tab-title.talent": "Talent",
    "common.tab-title.titles": "Titles",
    "common.tab-title.user-groups": "User Groups",
    "common.tab-title.users": "Users",
    "common.tab-title.videos": "Videos",
    "common.type": "Type",
    "common.user-company-select": "Select Company",
    "common.video-delivery-format.mp4.tooltip": "Video is deliverable as a streamable H.264 file.",
    "common.video-delivery-format.mp4": "MP4",
    "common.video-delivery-format.mxf-mp4.tooltip": "Video is deliverable as an XDCAM editing master or streamable H.264 file.",
    "common.video-delivery-format.mxf-mp4": "MXF/MP4",
    "common.video-delivery-format.mxf.tooltip": "Video is deliverable as an XDCAM editing master.",
    "common.video-delivery-format.mxf": "MXF",
    "common.video-delivery-format.na.tooltip": "Video is not available for delivery.",
    "common.video-delivery-format.na": "N/A",
    "common.warning": "Warning",

    "criteria.auto-sync": "Auto Sync",
    "criteria.auto-sync-description": "(Only users that meet the criteria will be in the group)",
    "criteria.auto-sync-status": "Active",
    "criteria.select.companies": "Companies",
    "criteria.select.territory": "Territory",
    "criteria.select.user-groups": "User Groups",
    "criteria.title" : "Criteria",

    "dashboard.alert.invalid.field": "Field Status is not valid for selected title",
    "dashboard.dashboards.filter.all": "All",
    "dashboard.dashboards.filter.metadata": "Metadata Dashboard",
    "dashboard.dashboards.filter.photo": "Photo Dashboard",
    "dashboard.dashboards.filter.video": "Video Dashboard",
    "dashboard.dashboards.no-homepage.p1": "Hello,",
    "dashboard.dashboards.no-homepage.p2": "We noticed that you don't have a default homepage set up for Brainiac. Please follow the instructions below to set up a homepage for Brainiac!",
    "dashboard.dashboards.no-homepage.p3": "Click on a section in Brainiac that you to set as your default homepage",
    "dashboard.dashboards.no-homepage.p4": "Click on the house icon in the header of Brainiac next to the Hamburger icon",
    "dashboard.dashboards.no-homepage.p5": "Confirm these changes and you're done!",
    "dashboard.dashboards.no-homepage.p6": "If you have additional questions, please reach out to Brainiac Support Squad and we will respond to you as soon as we can!",
    "dashboard.dashboards.no-homepage.p7": "Thank you & have a great day,",
    "dashboard.dashboards.no-homepage.p8": "Brainiac",
    "dashboard.field.update.error": "Unable to Update Title Metatdata Status",
    "dashboard.field.update.success": "Title Metatdata Status Updated",
    "dashboard.filters.cleared": "Custom Filters Cleared",
    "dashboard.filters.clear-default-filters": "Clear Default Filters",
    "dashboard.filters.saved": "Custom Filters Saved",
    "dashboard.filters.export-list": "Export List",
    "dashboard.filters.save-as-default": "Save As Default",
    "dashboard.title": "Dashboard | WBTVD Brainiac",

    "date-format": "DD MMM YYYY",
    "date-format-shortened": "D MMM YYYY",
    "date-format-hyphen": "MMM-DD-YYYY",
    "date-format-title-release-0": "MMMM D, YYYY",
    "date-format-title-release-1": "MMMM YYYY",
    "date-format-title-release-2": "YYYY",
    "date-format-title-release-3": "YYYY",
    "date-format-title-release-4": "",
    "date-selector-format": "MM/DD/YYYY",
    "date-format-TBA": "TBA",
    "date-format-season-0": "Winter",
    "date-format-season-1": "Spring",
    "date-format-season-2": "Summer",
    "date-format-season-3": "Fall",
    "date-format-season-default": "Midseason",

    "datetime-format": "DD MMM YYYY hh:mm:ss A z",
    "datetime-format-0": "D MMM YYYY h:mm A",
    "datetime-hybrid-format": "D MMM YYYY @ h:mm a",
    "datetime-short-format": "MM/DD/YY @ hh:mm a",
    "datetime-full-ampm-tz": "M/D/YYYY @ h:mm A (z)",

    "document-titles.accounts.account-wizard.browse": "Browse Account Wizard Batches",
    "document-titles.accounts": "Accounts",
    "document-titles.accounts.user-errors": "User Errors",
    "document-titles.analytics.scheduled-reports": "Scheduled Reports",
    "document-titles.analytics.tracking-report": "Tacking Report",
    "document-titles.applicants": "Applicants",
    "document-titles.assets.browse.all": "Browse Assets",
    "document-titles.assets.browse.document": "Browse Documents",
    "document-titles.assets.browse.image": "Browse Images",
    "document-titles.assets.browse.video": "Browse Videos",
    "document-titles.authorized-partners": "Authorized Partners",
    "document-titles.brands": "Brands",
    "document-titles.browse-work-orders": "Browse Work Orders",
    "document-titles.catalogs-asset": "Asset Catalogs",
    "document-titles.catalogs-asset.mass-update": "Mass Update Asset Catalogs",
    "document-titles.catalogs-language": "Language Catalogs",
    "document-titles.catalogs-language.mass-update": "Mass Update Language Catalogs",
    "document-titles.catalogs-title": "Title Catalogs",
    "document-titles.catalogs-title.mass-update": "Mass Update Title Catalogs",
    "document-titles.client-rep-group": "Client Rep Group",
    "document-titles.common-download": "Download",
    "document-titles.configuration": "Configuration",
    "document-titles.create-audio": "Create Audio",
    "document-titles.create-authorized-partners": "Create Partner",
    "document-titles.create-batch.document": "Create Document Batch",
    "document-titles.create-batch.image": "Create Image Batch",
    "document-titles.create-batch.title": "Create Title Batch",
    "document-titles.create-batch.video": "Create Video Batch",
    "document-titles.create-catalogs-assets": "Create Asset Catalogs",
    "document-titles.create-client-rep-groups": "Create Client Rep Group",
    "document-titles.create-document": "Create Document",
    "document-titles.create-domain": "Create Domain",
    "document-titles.create-group": "Create Group",
    "document-titles.create-homepage": "Create Homepage",
    "document-titles.create-homescreen": "Create Homescreen",
    "document-titles.create-image": "Create Image",
    "document-titles.create-language-catalogs": "Create Language Catalogs",
    "document-titles.create-merchandise": "Create Merchandise",
    "document-titles.create-presets": "Create Presets",
    "document-titles.create-publishing-list": "Create Publishing List",
    "document-titles.create-push-notifications": "Create Push Notifications",
    "document-titles.create-roles": "Create Roles",
    "document-titles.create-station": "Create Station",
    "document-titles.create-station-group": "Create Station Group",
    "document-titles.create-talent": "Create Talent",
    "document-titles.create-title-catalogs": "Create Title Catalogs",
    "document-titles.create-titles": "Create Title",
    "document-titles.create-video": "Create Video",
    "document-titles.create-workorder": "Create Work Order",
    "document-titles.create.accounts": "Create Accounts",
    "document-titles.deleted-title": "Deleted Title",
    "document-titles.edit-audio": "Edit Audio",
    "document-titles.edit-authorized-partners": "Edit Partner",
    "document-titles.edit-batch.document": "Edit Document Batch",
    "document-titles.edit-batch.image": "Edit Image Batch",
    "document-titles.edit-batch.title": "Edit Title Batch",
    "document-titles.edit-batch.video": "Edit Video Batch",
    "document-titles.edit-catalogs-assets": "Edit Asset Catalogs",
    "document-titles.edit-client-rep-groups": "Edit Client Rep Group",
    "document-titles.edit-document": "Edit Document",
    "document-titles.edit-domain": "Edit Domain",
    "document-titles.edit-group": "Edit Group",
    "document-titles.edit-homepage": "Edit Homepage",
    "document-titles.edit-homescreen": "Edit Homescreen",
    "document-titles.edit-image": "Edit Image",
    "document-titles.edit-language-catalogs": "Edit Language Catalogs",
    "document-titles.edit-merchandise": "Edit Merchandise",
    "document-titles.edit-presets": "Edit Presets",
    "document-titles.edit-publishing-list": "Edit Publishing List",
    "document-titles.edit-push-notifications": "Edit Push Notifications",
    "document-titles.edit-roles": "Edit Roles",
    "document-titles.edit-station": "Edit Station",
    "document-titles.edit-station-group": "Edit Station Group",
    "document-titles.edit-talent": "Edit Talent",
    "document-titles.edit-title-catalogs": "Edit Title Catalogs",
    "document-titles.edit-titles": "Edit Title",
    "document-titles.edit-video": "Edit Video",
    "document-titles.edit-workorder": "Edit Work Order",
    "document-titles.edit.accounts": "Create Accounts",
    "document-titles.events": "Browse Events",
    "document-titles.groups-title": "Groups",
    "document-titles.hardac.processes": "View Process",
    "document-titles.hardac.processes.messages": "Process Messages",
    "document-titles.hardac.processes.messages.event-message": "Event Message",
    "document-titles.hardac.proxies": "Proxies",
    "document-titles.hardac.video-inbox": "Video Inbox",
    "document-titles.help-videos": "Help Videos",
    "document-titles.home": "Home",
    "document-titles.homepages": "Homepages",
    "document-titles.homescreens": "Homescreens",
    "document-titles.login-backgrounds": "Login Backgrounds",
    "document-titles.lookup": "Lookups",
    "document-titles.page-title-suffix": "Brainiac WBTVD.com",
    "document-titles.presets": "Presets",
    "document-titles.privacy": "Privacy Requests",
    "document-titles.publishing-list": "Publishing List",
    "document-titles.scheduling": "Scheduling",
    "document-titles.station-groups": "Browse Station Groups",
    "document-titles.stations": "Browse Stations",
    "document-titles.talent": "Talent",
    "document-titles.titles-deleted-title": "Delete Queue",
    "document-titles.titles-title": "Titles",
    "document-titles.utilities": "Utilities",
    "document-titles.whitelist": "Whitelist",

    "download.alternative-download.click": "click here",
    "download.alternative-download.text": "If your download does not begin automatically, ",
    "download.in-progress": "There’s a download in progress. It will be canceled if you leave the page, are you sure?",
    "download.preparing-files.text": "The files you requested are currently being processed and will be packaged together as a single download. This process may take a few minutes. The download will begin immediately when the process is complete, but please do not close this browser tab in the meantime.",
    "download.preparing-files.title": "Preparing Files For Download...",
    "download.progress.preparing-zip": "All files packaged. Getting zip file ready for download, this may take a while",
    "download.progress.text": "Packaging Files:",
    "download.return.home.page.phrase.1": "When your download is complete,",
    "download.return.home.page.phrase.2": "click here",
    "download.return.home.page.phrase.3": "to return to the home page.",
    "download.title.child-accounts": "Download Child Accounts",
    "download.title.data-export": "Data Export",
    "download.title.user-group-export": "User Group Export",

    "elastic-search.sync": "Sync Elastic Search",
    "elastic-search.sync.error": "Unable to sync Elastic Search, please try again.",
    "elastic-search.sync.success": "Elastic Search sync completed",

    "emails.whitelist.add-a-domain": "Add a Domain",
    "emails.whitelist.browse.actions": "Actions",
    "emails.whitelist.browse.approved-by": "Approved by",
    "emails.whitelist.browse.date-added": "Date added",
    "emails.whitelist.browse.domain": "Domain",
    "emails.whitelist.browse.notes": "Notes",
    "emails.whitelist.browse.permissions-package": "Permissions Package",
    "emails.whitelist.create.error": "An error occurred while adding the whitelist domain",
    "emails.whitelist.create.error.required": "One or more required fields are invalid.",
    "emails.whitelist.create.error.unique": "Domain name must be a unique name and \"{}\" already exists",
    "emails.whitelist.create.save": "Add",
    "emails.whitelist.create.success": "The whitelist domain has been successfully added",
    "emails.whitelist.create.title": "Add a Whitelist Domain",
    "emails.whitelist.edit-domain": "Edit",
    "emails.whitelist.edit.automatically-approved-by": "Automatically Approved by",
    "emails.whitelist.edit.client-rep": "Client Rep",
    "emails.whitelist.edit.cms-admin": "CMS Admin",
    "emails.whitelist.edit.domain-name": "Domain name",
    "emails.whitelist.edit.domain-name.placeholder": "Please enter domain name",
    "emails.whitelist.edit.error": "An error occurred while updating the whitelist domain",
    "emails.whitelist.edit.form.domain-info": "Domain Info",
    "emails.whitelist.edit.form.notes": "Notes",
    "emails.whitelist.edit.notes": "Please explain why this domain is whitelisted",
    "emails.whitelist.edit.notes.placeholder": "Enter note here...",
    "emails.whitelist.edit.permissions-package": "Permissions Package",
    "emails.whitelist.edit.permissions-package-placeholder": "Select Package",
    "emails.whitelist.edit.success": "The whitelist domain has been successfully updated",
    "emails.whitelist.edit.title": "Edit a Whitelist Domain",
    "emails.whitelist.remove-domain": "Remove",
    "emails.whitelist.remove-domain.confirm": "Are you sure you want to remove this item?",
    "emails.whitelist.remove.error": "An error occurred while removing the whitelist domain",
    "emails.whitelist.remove.success": "The whitelist domain was successfully removed",
    "emails.whitelist.title": "Email Whitelist",

    "error": "Error",

    "errors.access-denied.body.description": "Your account does not have access to this page.",
    "errors.access-denied.body.directive": "Please contact the site administrator if you need access to this page.",
    "errors.access-denied.body.return-link-text": "return to dashboard",
    "errors.access-denied.body.return-post-link": ".",
    "errors.access-denied.body.return-pre-link": "Meanwhile, you may",
    "errors.access-denied.title": "Access Denied.",
    "errors.asset-not-found.body.description": "The asset you requested could not be found.",
    "errors.asset-not-found.title": "Asset Not Found.",
    "errors.not-found.body.check-url": "Please check the URL again or",
    "errors.not-found.body.contact-administrator": " notify an administrator",
    "errors.not-found.body.dashboard": " return to dashboard",
    "errors.not-found.body.description": "The page you requested could not be found.",
    "errors.not-found.body.display-in-error": " if you believe this message was displayed in error.",
    "errors.not-found.body.meanwhile": "Meanwhile, you may",
    "errors.not-found.title": "Page Not Found.",

    "events.assets": "Assets",
    "events.assets.add-assets": "Add Assets",
    "events.assets.add-assets-number": "Add {} Assets",
    "events.assets.add-assets.description": "Manually Add Assets",
    "events.assets.add-assets.description-select": "Select Assets Individually",
    "events.assets.add-assets.title": "Add Video Asset",
    "events.assets.copy-catalog": "Copy Assets From Catalog",
    "events.assets.copy-catalog.choose": "Choose Asset Catalog",
    "events.assets.copy-catalog.description": "Add all assets from existing Catalog",
    "events.assets.copy-catalog.error": "An error occurred while retreiving assets and title related data. Please try again.",
    "events.assets.copy-catalog.modal.asset-catalogs": "Asset Catalog(s)",
    "events.assets.copy-catalog.modal.description": "Copies list of assets from a catalog to this event. The titles associated to those assets will be added and displayed in the Titles tab.",
    "events.assets.data.get.error": "An error occurred while retreiving assets data. Please try again.",
    "events.assets.remove": "Remove Assets",
    "events.assets.remove.modal.part-1": "The selected assets will be removed from this Event.",
    "events.assets.remove.modal.part-2": "However, access to their related Titles will remain until those Titles are also manually removed from the Event, using the “Titles” tab.",
    "events.assets.select-assets": "Select Assets",
    "events.browse.created-by": "Created By",
    "events.browse.end-date": "End Date",
    "events.browse.event-type": "Event Type",
    "events.browse.name-description": "Name & Created By",
    "events.browse.start-date": "Start Date",
    "events.browse.status": "Status",
    "events.browse.status-type.description.active": "Active",
    "events.browse.status-type.description.ended": "Ended",
    "events.browse.status-type.description.failed-to-start": "Failed To Start",
    "events.browse.status-type.description.manually-expiring": "Manually Expired",
    "events.browse.status-type.description.pending": "Pending",
    "events.browse.status-type.description.starting": "Starting",
    "events.browse.status-type.description.stopping": "Stopping",
    "events.browse.title": "Browse Pending/Active Events",
    "events.browse.user-count": "User Count",
    "events.browse.user-type": "User Type",
    "events.create.error": "Unable to create event, please try again",
    "events.create.success": "Event created successfully",
    "events.create.title": "Create Event",
    "events.created-by.get.error": "There was an error getting the created by user list, please try again later",
    "events.duplicate.error": "An error occurred while duplicating the event",
    "events.duplicate.success": "Event duplicated successfully",
    "events.duplicate.title": "Duplicate Event",
    "events.edit.duplicate": "Duplicate",
    "events.edit.duplicate.modal.create-duplicate": "Create Duplicate",
    "events.edit.duplicate.modal.duplicate-event": "Duplicate Event",
    "events.edit.expire.now": "Expire now",
    "events.edit.preview-emails": "Preview Emails",
    "events.edit.preview-emails.error": "Unable to send preview emails, please contact support",
    "events.edit.preview-emails.modal.body": "Send event preview emails to {}?",
    "events.edit.preview-emails.modal.send-emails": "Send Preview Emails",
    "events.edit.preview-emails.success": "Event email previews have been sent to {}",
    "events.edit.restart.now": "Restart Event",
    "events.edit.start.now": "Start now",
    "events.edit.title": "Edit Event",
    "events.event-locked-error": "Unable to complete your request, this event is currently locked by another process. Please try again in few moments. For more information, refer to the History tab.",
    "events.events-tab.child-events": "Child Events",
    "events.events-tab.title": "Events",
    "events.expire.error": "An error occurred while expiring the event. Please check your start date and make sure it is past the current time",
    "events.expire.success": "The event has been expired",
    "events.export.list.cost": "Export Event Costs",
    "events.export.list.options": "Export Options",
    "events.export.list.otl": "Export OTL Requests",
    "events.export.list.users": "Export List of All Users",
    "events.export.list.views": "Export User Views",
    "events.get-fyc-admin-events.error": "There was an error getting the fyc admin event list, please contact support",
    "events.get-fyc-events.error": "There was an error getting the fyc events list, please contact support",
    "events.get.error": "There was an error getting the events list, please try again later",
    "events.mass-update-users": "Update Removed Accounts",
    "events.mass-update-users.error": "There was an error creating the batch for removed users, please try again",
    "events.mass-update-users.modal.description": "You’ve requested that we create a mass update batch with only the removed users for this event, click confirm and we’ll start the process for you!",
    "events.mass-update-users.modal.title": "Create Mass Update Batch",
    "events.notifications": "Notifications",
    "events.notifications.custom-image.button.remove": "Remove Image",
    "events.notifications.custom-image.button.view": "View Image",
    "events.notifications.custom-image.description": "Images should be 700px wide and as tall as needed, but 1032px height is recommended",
    "events.notifications.custom-image.modal.title": "View Custom E-mail Image",
    "events.notifications.custom-image.title": "Custom Image",
    "events.notifications.description": "E-mail notifications will be sent automatically when the event starts.",
    "events.notifications.error.adding-image": "Error adding the new custom email image",
    "events.notifications.error.deleting-image": "Error deleting the existing custom email image",
    "events.notifications.options.do-not-send": "Do Not Send E-mail",
    "events.notifications.options.do-not-send.description": "Disables e-mail notification",
    "events.notifications.options.send-custom": "Send Custom E-mail",
    "events.notifications.options.send-custom.description": "Enables custom e-mail notification",
    "events.notifications.options.send-custom.message-body": "Message Body",
    "events.notifications.options.send-custom.subject": "Subject",
    "events.notifications.options.send-custom.subject.placeholder": "New Content Available For You on WBTVD.com",
    "events.notifications.options.send-standard": "Send Standard E-mail Template",
    "events.notifications.options.send-standard.description": "Enables standard e-mail notification",
    "events.notifications.partner-logo.description": "Logo from Authorized Partner listed on summary tab",
    "events.notifications.partner-logo.label": "Show Partner Logo On Top of E-mail",
    "events.notifications.partner-logo.title": "Partner Logo",
    "events.notifications.send-email-notification.button": "Send email notification now",
    "events.notifications.send-email-notification.button.ok": "Manually Send Email",
    "events.notifications.send-email-notification.debounce-error": "Please wait 5 minutes before re sending Email Notification again",
    "events.notifications.send-email-notification.disclaimer": "Would you like to send this email notification now to all users of this event?  (Note: It will also send automatically when a pending event starts.)",
    "events.notifications.send-email-notification.error": "Error sending Email Notification",
    "events.notifications.send-email-notification.header": "Manually Send Email Notification",
    "events.notifications.send-email-notification.success": "Email Notification sent successfully",
    "events.notifications.send-me-preview": "Send Me Preview",
    "events.notifications.title": "User E-Mail Notifications",
    "events.otl.export.error": "An error occurred while exporting OTL Requests list. Please contact support.",
    "events.presentation": "Presentation",
    "events.presentation.artwork-orientation": "Artwork Orientation",
    "events.presentation.artwork-orientation.please-note": "Please Note",
    "events.presentation.artwork-orientation.please-note.description": "FYC Events require information about the orientation of the artwork to display properly. For more information, please see the links in the help document.",
    "events.presentation.get-presentation-title.error": "An error occurred while retrieving the presentation title. Please try again.",
    "events.presentation.get-presentation-titles.error": "An error occurred while retrieving the presentation titles. Please try again.",
    "events.presentation.modify-option.custom": "Custom",
    "events.presentation.modify-option.inherit": "Inherit From Title",
    "events.presentation.nomination-info": "Nomination Info",
    "events.presentation.nomination-info.add-item": "Add Item",
    "events.presentation.nomination-info.click-on-a-cell": "Click on a cell in the table below to edit it.",
    "events.presentation.nomination-info.column1": "Column 1",
    "events.presentation.nomination-info.column2": "Column 2",
    "events.presentation.nomination-info.description": "Apps in horizontal layouts expect 2 columns of information to be displayed, which will stack on top of each other in portrait orientations.",
    "events.presentation.nomination-info.get.error": "An error occurred while retrieving the nomination info. Please try again.",
    "events.presentation.nomination-info.save.error": "An error occurred while saving title nomination info. Please try again.",
    "events.presentation.nomination-info.type": "Type",
    "events.presentation.nomination-info.value": "Value",
    "events.presentation.panel": "Title Presentation",
    "events.presentation.panel.save-options": "Save Options",
    "events.presentation.panel.view-title": "View Title",
    "events.presentation.save-presentation-title.error": "An error occurred while saving the presentation title. Please try again.",
    "events.presentation.synopsis": "Synopsis",
    "events.presentation.synopsis.description": "Modify the title synopsis for this event only.",
    "events.presentation.table.background": "Background",
    "events.presentation.table.edit": "Edit",
    "events.presentation.table.name": "Name",
    "events.presentation.table.nomination-info": "Nomination Info",
    "events.presentation.table.row-number": "#",
    "events.presentation.table.synopsis": "Synopsis",
    "events.presentation.table.title-thumbnail": "Title Thumbnail",
    "events.presentation.table.videos": "Videos",
    "events.presentation.title": "Title Selection Menu",
    "events.presentation.title-style.description": "Modify the title style for this event only.",
    "events.presentation.title-style.inherit-warning": "Selecting “Inherit from Title” will overwrite any custom selections.",
    "events.presentation.title-style.save.error": "An error occurred while saving title style. Please try again.",
    "events.presentation.title-style.title": "App Styles",
    "events.presentation.title-thumbnail": "Title Thumbnail",
    "events.presentation.title-thumbnail.description": "Replace Title Thumbnail used for this event only.",
    "events.presentation.title-thumbnail.error.delete-old-image": "Error deleting old Thumbnail Image",
    "events.presentation.title-thumbnail.error.upload": "Error uploading Thumbnail Image",
    "events.presentation.title.description": "Only top-level titles (Series/Features, not Episodes/Seasons) will appear here.",
    "events.presentation.title.disabled": "Title must be saved before edit",
    "events.remove.titles": "Remove Titles",
    "events.remove.users": "Remove Users",
    "events.save.error": "Unable to save changes",
    "events.save.success": "Event saved successfully",
    "events.summary": "Summary",
    "events.summary.brand": "Brand",
    "events.summary.create-note": "Important Note",
    "events.summary.create-note.caption": "Event Type and User Type can only be modified during the event creation step.",
    "events.summary.created-by": "Created By",
    "events.summary.created-date": "Created Date",
    "events.summary.dates": "Start/End Dates",
    "events.summary.dates.caption": "Note: Only sets dates on new accounts. \"Sync All Accounts\" applies to existing accounts.",
    "events.summary.dates.end": "Event End",
    "events.summary.dates.end.date": "End Date",
    "events.summary.dates.end.date-time": "End Date/Time",
    "events.summary.dates.end.date.caption": "Last available date for Event access",
    "events.summary.dates.end.time": "End Time",
    "events.summary.dates.end.time.caption": "Event End Time",
    "events.summary.dates.important-note": "Important Note",
    "events.summary.dates.important-note.caption": "Updating access for all the accounts in the event will take time to process. The system will begin granting access to some accounts up to 5 minutes prior to the scheduled start time, in order to make sure all accounts are updated in time. The event start date must occur after the current date.",
    "events.summary.dates.start": "Event Start",
    "events.summary.dates.start.date": "Start Date",
    "events.summary.dates.start.date-time": "Start Date/Time",
    "events.summary.dates.start.date.caption": "First available date for Event access",
    "events.summary.dates.start.time": "Start Time",
    "events.summary.dates.start.time.caption": "Event Start Time",
    "events.summary.dates.timezone": "Time Zone Location",
    "events.summary.dates.timezone.caption": "Note: Modifying time zone will change the displayed start/end times to match new location.",
    "events.summary.dates.timezone.placeholder": "Select a city",
    "events.summary.description": "Description",
    "events.summary.duplicated.assets-titles": "Assets & Titles",
    "events.summary.duplicated.from": "Duplicated From",
    "events.summary.duplicated.go-to": "Go to parent event",
    "events.summary.duplicated.none": "None",
    "events.summary.duplicated.notifications-messaging": "Notifications/Messaging",
    "events.summary.duplicated.properties": "Duplicated Properties",
    "events.summary.duplicated.users": "Users",
    "events.summary.duplication-options": "Duplication Options",
    "events.summary.duplication-options.assets-titles": "Assets/Titles",
    "events.summary.duplication-options.assets-titles.label": "Carry Over Assets & Titles",
    "events.summary.duplication-options.important-note": "Important Note",
    "events.summary.duplication-options.important-note.text": "Carrying users over will make the following fields read-only: Event Type, User Type, Authorized Partner, and Role.",
    "events.summary.duplication-options.notification-settings": "Notification Settings",
    "events.summary.duplication-options.notification-settings.label": "Keep Notifications/Messaging",
    "events.summary.duplication-options.original-event": "Original Event",
    "events.summary.duplication-options.users": "Users",
    "events.summary.duplication-options.users.label": "Carry Over Users",
    "events.summary.event-access": "Event Access",
    "events.summary.event-info": "Event Info",
    "events.summary.event-name": "Event Name",
    "events.summary.event-type": "Event Type",
    "events.summary.event-type.option.bafta-event": "BAFTA Event",
    "events.summary.event-type.option.fyc-admin-event": "FYC Admin Event",
    "events.summary.event-type.option.fyc-screening": "FYC Screening",
    "events.summary.event-type.option.press-site-screening": "Press Screening",
    "events.summary.event-type.option.screening-presentation": "Screening Presentation",
    "events.summary.event-type.option.single-screener": "Single Screener",
    "events.summary.event-type.placeholder": "Select an Event Type",
    "events.summary.expire-now.modal.add-note-and-expire-event": "Add Note & Expire Event",
    "events.summary.expire-now.modal.body": "Expiring the event early will prevent access for all users and cannot be undone.",
    "events.summary.expire-now.modal.please-add-a-note": "Please add a note explaining the event expiration:",
    "events.summary.expire-now.modal.title": "Confirm Event Expiration",
    "events.summary.homepage": "Homepage",
    "events.summary.homepage.automatic": "Automatic",
    "events.summary.homescreen": "Homescreen",
    "events.summary.homescreen.automatic": "Automatic",
    "events.summary.link-to-fyc-admin-event": "Link to FYC Admin Event",
    "events.summary.partners": "Authorized Partner",
    "events.summary.partners.caption": "Adds Event Group to Authorized Partner when event starts.",
    "events.summary.partners.caption-bafta": "Authorized Partner is set automatically for BAFTA Events.",
    "events.summary.partners.caption-fyc-screening": "Authorized Partner is set automatically for FYC Events.",
    "events.summary.permission-package": "Permission Package",
    "events.summary.permission-package.caption": "Adds (doesn't remove) permissions for selected users.",
    "events.summary.permission-package.caption-traditional-login": "Permission packages cannot be set for traditional users.",
    "events.summary.permission-package.placeholder": "Select Permission Package",
    "events.summary.reference-id": "Reference ID",
    "events.summary.restart-now.error": "There was an error while restarting the event",
    "events.summary.restart-now.message.after": "An event cannot be restarted more than 7 days after its end date.",
    "events.summary.restart-now.message.before": "You cannot restart an event until the end date is changed to the future.",
    "events.summary.restart-now.modal.body": "This will set the start date and time to the current date and time but it may take a few minutes for users to receive full access.",
    "events.summary.restart-now.modal.confirm": "Restart Event",
    "events.summary.restart-now.modal.title": "Confirm Restart Event",
    "events.summary.restart-now.success": "The event has been scheduled to restart. This may take a few minutes for users to receive full access.",
    "events.summary.role": "Role",
    "events.summary.role.caption": "Adds base role for all created users.",
    "events.summary.start-now.error": "There was an error while starting the event",
    "events.summary.start-now.modal.body": "This will set the start date and time to the current date and time but it may take a few minutes for users to receive full access.",
    "events.summary.start-now.modal.confirm": "Start Event",
    "events.summary.start-now.success": "The event has been scheduled to start. This may take a few minutes for users to receive full access.",
    "events.summary.status": "Status",
    "events.summary.updated-by": "Updated By",
    "events.summary.updated-date": "Updated Date",
    "events.summary.user-type": "User Type",
    "events.summary.user-type.option.tokenized-anonymous": "Anonymous",
    "events.summary.user-type.option.tokenized-child": "Tokenized Child",
    "events.summary.user-type.option.traditional-login": "Traditional Login",
    "events.summary.user-type.placeholder": "Select a User Type",
    "events.titles": "Titles",
    "events.titles.add-titles": "Add Titles",
    "events.titles.add-titles.button": "Add {} Titles (plus any parent titles)",
    "events.titles.already-selected": "Already Selected",
    "events.titles.auto-add-titles": "Auto-Add Titles",
    "events.titles.deselect-title": "Deselect Title",
    "events.titles.manually-add": "Manually Add Titles",
    "events.titles.select-individually": "Select Titles Individually",
    "events.titles.select-title": "Select Title",
    "events.titles.select-titles": "Select Titles",
    "events.titles.sync-titles": "Sync Titles with Assets",
    "events.titles.sync-titles.desc": "Chooses titles based on the assets you selected on the assets tab",
    "events.users": "Users",
    "events.users.adding-error": "There was an error adding the users.",
    "events.users.adding-success": "{} of {} users added successfully.",
    "events.users.already-selected": "Already Added",
    "events.users.bulk_add": "Bulk Create Anonymous Accounts",
    "events.users.bulk_add.admin": "Bulk Generate Admin Codes",
    "events.users.bulk_add.button": "Bulk Create Accounts",
    "events.users.bulk_add.button.admin": "Create Admin Codes",
    "events.users.bulk_add.description": "Creates and adds new anonymous accounts to event",
    "events.users.bulk_add.description.admin": "Creates and adds admin codes for FYC Admin Events",
    "events.users.bulk_add.error": "There was an error adding the users.",
    "events.users.bulk_add.modal.title": "Bulk Create Admin Codes",
    "events.users.bulk_add.modal.count": "Admin Codes Required",
    "events.users.bulk_add.note": "This process may take a few minutes. You may need to wait and refresh your browser to display the updates.",
    "events.users.bulk_add.success": "The bulk generate accounts process has started successfully.",
    "events.users.copy-group": "Copy Users From Group",
    "events.users.copy-group.description": "Add all users from existing User Group - does not sync afterwards",
    "events.users.copy-group.modal.description": "Copies list of users from a user group to this event.",
    "events.users.copy-group.modal.user-groups": "User Group(s)",
    "events.users.deselect-user": "Deselect",
    "events.users.export.error": "An error occurred while exporting users. Please contact support.",
    "events.users.get-error": "An error occurred while retreiving users. Please try again.",
    "events.users.import_tokens.add.error": "There was an error adding the users, please try again later",
    "events.users.import_tokens.add.success": "Users added successfully",
    "events.users.import_tokens.button": "Import Tokens",
    "events.users.import_tokens.description": "Create Accounts From Spreadsheet of Tokens",
    "events.users.import_tokens.modal.confirmation": "I have read this important information",
    "events.users.import_tokens.modal.item-1": "No two events should have the same anonymous token - whoever is hitting the submit button is acknowledging that the imported list has been validated.",
    "events.users.import_tokens.modal.item-2": "Hitting submit more than once can cause multiple jobs to run and please do not do so; if a job seems to take a long time to run, rather than hit submit again on the same job, reach out to Extreme.",
    "events.users.import_tokens.modal.item-3": "The runtime of the job is a factor of the number of tokens imported - make a strong warning for uploading more than X tokens (replace X with a reasonable number).",
    "events.users.import_tokens.modal.label": "Please select the CSV file you want to import",
    "events.users.import_tokens.modal.notice.title": "Please take notice before importing accounts:",
    "events.users.import_tokens.modal.ok": "Import accounts",
    "events.users.import_tokens.modal.title": "Import accounts",
    "events.users.import_tokens": "Import Login Tokens",
    "events.users.manually-select": "Manually Select Users",
    "events.users.manually-select.add-number-users": "Add {} Users",
    "events.users.manually-select.add-users": "Add Users",
    "events.users.manually-select.description": "Select Users Individually",
    "events.users.removing-error": "There was an error removing the users.",
    "events.users.removing-success": "Users removed successfully.",
    "events.users.select-spreadsheet.description.csv-file": "CSV file",
    "events.users.select-spreadsheet.description.that-contains": "that contains the users information",
    "events.users.select-spreadsheet.description.upload": "Upload a",
    "events.users.select-spreadsheet.modal.button": "Upload Spreadsheet",
    "events.users.select-spreadsheet.modal.caption": "Add up to 4,000 existing user accounts to the event, from a list of email addresses.",
    "events.users.select-spreadsheet.modal.title": "Add Users By Spreadsheet",
    "events.users.select-spreadsheet.not-found.alert-caption": "However, some of the users in this spreadsheet do not exist in Brainiac.",
    "events.users.select-spreadsheet.not-found.button": "Account Wizard Import",
    "events.users.select-spreadsheet.not-found.second-caption": "A batch was automatically created for you with the users that do not exist. Please Use the Mass Import to add the remaining users:",
    "events.users.select-spreadsheet.not-found.title": "{} of {} users have been added to the event.",
    "events.users.select-user": "Select",
    "events.users.table.account-source": "Source",
    "events.users.table.account-source.generated": "Generated",
    "events.users.table.account-source.imported": "Imported",
    "events.users.table.account-source.just-in-time": "Just In Time",
    "events.users.table.application-date": "Apply Date",
    "events.users.table.classification": "Classification",
    "events.users.table.classification.unclassified": "Unclassified",
    "events.users.table.client-rep-group": "Client Rep",
    "events.users.table.company": "Company",
    "events.users.table.created-date": "Created Date",
    "events.users.table.email": "Email",
    "events.users.table.login-token": "Login Token",
    "events.users.table.name": "Name",
    "events.users.table.status": "Status",
    "events.users.user-import": "Account Wizard",
    "events.views.export.error": "An error occurred while exporting user views. Please contact support.",

    "filter-options.apply-date.from": "From Date",
    "filter-options.apply-date.to": "To Date",
    "filter-options.close-options.button": "Close Options",
    "filter-options.created-date": "Created Date",
    "filter-options.date.from": "From Date",
    "filter-options.date.to": "To Date",
    "filter-options.end-date": "End Date",
    "filter-options.execution-date": "Start Date",
    "filter-options.expire-date.from": "From Date",
    "filter-options.expire-date.to": "To Date",
    "filter-options.mpm-number": "MPM Number",
    "filter-options.reference-id": "Reference ID",
    "filter-options.related-type.mpm-number": "Title Related to (MPM)",
    "filter-options.related-type.mpm-number.placeholder": "Enter MPM",
    "filter-options.related-type.relation-type": "Relation Type",
    "filter-options.release-date": "Release Date",
    "filter-options.release-date.last-week": "Last Week",
    "filter-options.status": "Status",
    "filter-options.start-date": "Start Date",
    "filter-options.select.placeholder": "Select...",
    "filter-options.title": "Title",
    "filter-options.title-type": "Title Type",
    "filter-options.updated-date": "Updated Date",

    "groups.client-rep-group.add.user": "Add",
    "groups.client-rep-group.create.error": "Unable to create Client Rep Group, please try again",
    "groups.client-rep-group.create.info.description": "Description",
    "groups.client-rep-group.create.info.description.placeholder": "Enter here...",
    "groups.client-rep-group.create.info.name": "Name",
    "groups.client-rep-group.create.info.name.placeholder": "Please enter name",
    "groups.client-rep-group.create.info.status": "Status",
    "groups.client-rep-group.create.info.title": "Client Rep Group Info",
    "groups.client-rep-group.create.save": "Create a Client Rep Group",
    "groups.client-rep-group.create.success": "Client Rep Group created successfully",
    "groups.client-rep-group.create.title": "Please fill out the form below to create a new client rep group.",
    "groups.client-rep-group.delete.error": "Unable to delete Client Rep Group",
    "groups.client-rep-group.delete.success": "Client Rep Group Deleted",
    "groups.client-rep-group.edit.error": "Unable to save changes",
    "groups.client-rep-group.edit.last-modified-date": "Last Modified Date",
    "groups.client-rep-group.edit.last-modifier": "Last Modifier",
    "groups.client-rep-group.edit.modal.body": "Are you sure you want to Delete this Client Rep Group?",
    "groups.client-rep-group.edit.modal.confirm": "Confirm",
    "groups.client-rep-group.edit.modal.title": "Please Confirm Deletion",
    "groups.client-rep-group.edit.success": "Client Rep Group Saved",
    "groups.client-rep-group.edit.title": "Review the client rep group information below.",
    "groups.client-rep-group.save.error": "Unable to save changes",
    "groups.client-rep-group.save.success": "Client Rep Group saved",
    "groups.client-rep-group.summary.territory": "Territories",
    "groups.client-rep-group.summary.territory-placeholder": "Select a Territory",
    "groups.client-rep-group.title": "Client Rep Groups",
    "groups.client-rep-group.users": "Client Rep Users",
    "groups.create-group": "Create Group",

    "groups.filter.status": "Status",
    "groups.filter.syndication-title": "Syndication Title",

    "groups.partners": "Partners",
    "groups.partners.client": "Client",
    "groups.partners.description": "Description",
    "groups.partners.name": "Name",
    "groups.partners.error": "Failed to load Partners",

    "groups.summary": "Summary",
    "groups.user.add-users.modal.body": "Are you sure you want to add users?",
    "groups.user.add-users.modal.confirm": "Add",
    "groups.user.add-users.modal.title": "Please Confirm",
    "groups.user.add-users.success": "Successfully added users",
    "groups.user.add-users": "Add Users",
    "groups.user.create.asset-catalogs.choose": "Choose asset catalogs to add",
    "groups.user.create.asset-catalogs.title": "Asset Catalogs",
    "groups.user.create.brand": "Brand",
    "groups.user.create.criteria.auto-sync.text": "(Only users that meet the criteria will be in the group)",
    "groups.user.create.criteria.auto-sync": "Auto Sync",
    "groups.user.create.criteria.companies": "Companies",
    "groups.user.create.criteria.territories": "Territories",
    "groups.user.create.criteria.title": "Criteria",
    "groups.user.create.criteria.user-groups": "User Groups",
    "groups.user.create.error": "Unable to create User Group, please try again",
    "groups.user.create.info.brainiac-group": "Brainiac Group",
    "groups.user.create.info.classification": "Classification",
    "groups.user.create.info.description.placeholder": "Enter here...",
    "groups.user.create.info.description": "Description",
    "groups.user.create.info.name.placeholder": "Please enter name",
    "groups.user.create.info.name": "Group name",
    "groups.user.create.info.status": "Status",
    "groups.user.create.info.title": "Group Info",
    "groups.user.create.success": "User Group created successfully",
    "groups.user.create.title-catalogs.choose": "Choose title catalogs to add",
    "groups.user.create.title-catalogs.title": "Title Catalogs",
    "groups.user.create.title": "Please fill out the form below to create a new user group.",
    "groups.user.delete.error": "Unable to delete User Group",
    "groups.user.delete.success": "User Group Deleted",
    "groups.user.edit.elastic-search.sync": "Sync Elastic Search Users",
    "groups.user.edit.error": "Unable to save changes",
    "groups.user.edit.last-modified-date": "Last Modified Date",
    "groups.user.edit.last-modifier": "Last Modifier",
    "groups.user.edit.modal.body": "Are you sure you want to Delete this User Group?",
    "groups.user.edit.modal.confirm": "Confirm",
    "groups.user.edit.modal.title": "Please Confirm Deletion",
    "groups.user.edit.success": "User Group Saved",
    "groups.user.edit.title": "Review the user group information below.",
    "groups.user.export-users-with-reset-token.download.error": "Error Downloading List of Users with Password Reset Tokens, please try again.",
    "groups.user.export-users-with-reset-token": "Export List of Users with Password Reset Tokens",
    "groups.user.export-users.download.error": "Error Downloading List of Users, please try again.",
    "groups.user.export-users": "Export List of Users",
    "groups.user.remove-users.modal.body": "Are you sure you want to remove users?",
    "groups.user.remove-users.modal.confirm": "Remove",
    "groups.user.remove-users.modal.title": "Please Confirm",
    "groups.user.remove-users.success": "Successfully removed users from group",
    "groups.user.remove-users": "Remove Users",
    "groups.user.save.error": "Unable to save changes",
    "groups.user.save.success": "User Group saved",
    "groups.user.title": "User Groups",
    "groups.users": "Users",

    "guild.accounts.bulk_generate": "Bulk Generate Accounts",
    "guild.accounts.bulk_generate_csv.modal.ok": "Import accounts",
    "guild.accounts.bulk_generate_csv.modal.title": "Import accounts",
    "guild.accounts.bulk_generate_csv.modal.label": "Please select the CSV file you want to import",
    "guild.accounts.child_accounts.btn": "Create Child Accounts",
    "guild.accounts.child_accounts.error": "There was an error creating child accounts, please try again later",
    "guild.accounts.child_accounts.modal.ok": "Create Child Accounts",
    "guild.accounts.child_accounts.modal.title": "Create Child Accounts",
    "guild.accounts.child_accounts.modal.label": "Please select the CSV file you want to generate child accounts from",
    "guild.accounts.child_accounts.success": "Child Accounts created successfully",
    "guild.accounts.download.btn": "Download Accounts",
    "guild.accounts.import": "Import Accounts",
    "guild.accounts.title": "Accounts",
    "guild.accounts.users": "Users",
    "guild.browse.filter.date.title": "Date Range",
    "guild.browse.filter.date.from": "From Date",
    "guild.browse.filter.date.to": "To Date",
    "guild.browse.filter.status.title": "Status",
    "guild.browse.filter.status.show-inactive": "Show Inactive",
    "guild.browse.title": "Browse Guilds",
    "guild.bulk_add.error": "There was an error adding the users, please try again later",
    "guild.bulk_add.success": "Users added successfully",
    "guild.create.success": "Guild created successfully",
    "guild.create.error": "There was an error creating the guild, please try again later",
    "guild.create.title": "Create Guild",
    "guild.delete.success": "Guild deleted successfully",
    "guild.delete.error": "There was an error deleting the guild, please try again later",
    "guild.delete.modal.body": "Are you sure you want to delete this Guild?",
    "guild.delete.modal.confirm": "Confirm",
    "guild.delete.modal.title": "Please Confirm Deletion",
    "guild.edit.deactivate.btn": "Deactivate",
    "guild.edit.deactivate.error": "There was an error deactivating the guild, please try again later",
    "guild.edit.deactivate.success": "Guild deactivated successfully",
    "guild.edit.title": "Edit Guild",
    "guild.edit.reset.btn": "Reset",
    "guild.edit.reset.error": "There was an error resetting the guild, please try again later",
    "guild.edit.reset.success": "Guild reseted successfully",
    "guild.edit.update.error": "There was an error updating the guild.",
    "guild.get.error": "Error getting guild list, please try again later",
    "guild.reset.title": "Reset Guild",
    "guild.save.error": "There was an error saving the guild, please try again later",
    "guild.save.success": "Guild saved successfully",
    "guild.summary.active.label.false": "Inactive",
    "guild.summary.active.label.true": "Active",
    "guild.summary.active.title": "Status",
    "guild.summary.cdn_code": "Limelight CDN Code",
    "guild.summary.cdn_code.caption": "Used for billing of video usage",
    "guild.summary.dates.apply.button": "Sync All Accounts",
    "guild.summary.dates.apply.button.disabled": "No accounts available to sync",
    "guild.summary.dates.apply.caption": "Sets start/end date on all accounts in this guild.",
    "guild.summary.dates.apply.title": "Apply Start/End Date To All",
    "guild.summary.dates.end": "End Date",
    "guild.summary.dates.end.caption": "Last available date for guild access",
    "guild.summary.dates.end.hour": "End Time",
    "guild.summary.dates.end.hour.caption": "Guild End Time",
    "guild.summary.dates.note": "Note: ",
    "guild.summary.dates.note.text": "Only sets dates on new accounts. \"Sync All Accounts\" applies to existing accounts.",
    "guild.summary.dates.select.text": "Please select new Start/End dates.",
    "guild.summary.dates.start": "Start Date",
    "guild.summary.dates.start.caption": "First available date for guild access",
    "guild.summary.dates.start.hour": "Start Time",
    "guild.summary.dates.start.hour.caption": "Guild Start Time",
    "guild.summary.dates.title": " Start/End Dates",
    "guild.summary.dates.timezone": "Time Zone Location",
    "guild.summary.dates.timezone.caption": "Used to localize time zone",
    "guild.summary.dates.timezone.placeholder": "Please, select a city",
    "guild.summary.email": "Email Template",
    "guild.summary.email.caption": "Defines styling of emails sent to users",
    "guild.summary.info": "Guild Info",
    "guild.summary.last_modifier": "Last Modifier",
    "guild.summary.name": "Guild Name",
    "guild.summary.name.caption": "Internal/administrative name for guild",
    "guild.summary.partner.caption": "Partner associated with this Guild",
    "guild.summary.partner.label": "Authorized Partner",
    "guild.summary.partner.placeholder": "Select Partner",
    "guild.summary.permission_package.caption": "Default permissions assigned to new users",
    "guild.summary.permission_package.label": "Permission Package",
    "guild.summary.permission_package.placeholder": "Select Permission Package",
    "guild.summary.public_signup.title": "Public Signup",
    "guild.summary.public_signup.label": "This guild has public sign-ups",
    "guild.summary.sync-date-range.error": "There was an error syncing date range to all accounts, please try again.",
    "guild.summary.sync-date-range.reset.error": "There was an error syncing date range, please try again.",
    "guild.summary.sync-date-range.success": "Date range was set to all accounts successfully.",
    "guild.summary.title": "Summary",
    "guild.summary.updated_date": "Last Modified Date",
    "guild.summary.status.active": "Active",
    "guild.summary.status.inactive": "Inactive",
    "guild.user.get.error": "Error getting guild users, please try again later",

    "hardac.audio-profile.add": "Add Channel",
    "hardac.audio-profile.channel-type": "Channel Type",
    "hardac.audio-profile.channel": "#",
    "hardac.audio-profile.lookups.get.error": "There was an error loading audio channel lookups, please try reloading the page or contact your administrator.",
    "hardac.audio-profile.presets.get.error": "There was an error loading audio profile presets, please try reloading the page or contact your administrator.",
    "hardac.audio-profile.select-no-audio-presets": "No Audio Presets",
    "hardac.audio-profile.select": "Select Audio Preset",
    "hardac.audio-profile.tracks.no": "No",
    "hardac.audio-profile.tracks.plural": "Audio Tracks",
    "hardac.audio-profile.tracks.singular": "Audio Track",
    "hardac.audio-profile": "Audio",
    "hardac.encode.mediaservicesv3.dispatched": "Dispatch AMS v3 Encode",
    "hardac.encode.mediaservicesv3.processing": "Processing AMS v3 Encode",
    "hardac.encode.mediaservicesv3.scheduled": "Scheduled AMS v3 Encode",
    "hardac.processes.acknowledge-date": "Acknowledge Date",
    "hardac.processes.complete-date": "Complete Date",
    "hardac.processes.details.title": "View Process",
    "hardac.processes.error.does-not-load": "Failed to load processes, please try reloading the page or contact your administrator.",
    "hardac.processes.error-message": "Error Message",
    "hardac.processes.event-handler-class-name": "Event Handler Class Name",
    "hardac.processes.event-message-sent": "Event Message Sent",
    "hardac.processes.event-type": "Event Type",
    "hardac.processes.failure-error-code": "Failure Error Code",
    "hardac.processes.failure-date": "Failure Date",
    "hardac.processes.failure-message": "Failure Message",
    "hardac.processes.filters.process": "Process",
    "hardac.processes.find-by-id.error.does-not-have-access": "Failed to load process, you may not have access to the data. Please contact your administrator.",
    "hardac.processes.find-by-id.error.does-not-load": "Failed to load process, please try reloading the page or contact your administrator.",
    "hardac.processes.get.error.does-not-have-access": "Failed to load processes, you may not have access to the data. Please contact your administrator.",
    "hardac.processes.get-error-message.error": "Failed to load process error message, please try reloading the page or contact your administrator.",
    "hardac.processes.handler-id": "Handler Id",
    "hardac.processes.id": "Id",
    "hardac.processes.jobs.abandon.modal.body.are-you-sure": "Are you sure you want to abandon the selected jobs?",
    "hardac.processes.jobs.abandon.modal.body": "Abandoning jobs will stop processing the selected videos and cannot be undone.",
    "hardac.processes.jobs.abandon.modal.title": "Abandon Job(s)",
    "hardac.processes.jobs.abandon": "Abandon",
    "hardac.processes.jobs.get.error": "Failed to load jobs, please try reloading the page or contact your administrator.",
    "hardac.processes.jobs.last-process": "Last Process",
    "hardac.processes.jobs.last-updated": "Last Updated",
    "hardac.processes.jobs.name": "Name",
    "hardac.processes.jobs.retry.modal.body": "This action will attempt to reprocess the selected videos.",
    "hardac.processes.jobs.retry.modal.title": "Retry Selected Videos",
    "hardac.processes.jobs.retry": "Retry",
    "hardac.processes.jobs.started-by": "Started by",
    "hardac.processes.jobs.status": "Status",
    "hardac.processes.jobs.time-running": "Time Running",
    "hardac.processes.jobs.time-started": "Time Started",
    "hardac.processes.jobs.title": "Browse Jobs",
    "hardac.processes.jobs.type": "Type",
    "hardac.processes.log-event-id": "Log Event Id",
    "hardac.processes.log-event-name": "Log Event Name",
    "hardac.processes.log-event-url": "Log Event Url",
    "hardac.processes.messages.error-loading-event-messages": "Failed to load messages, please go to Messages tab in order to check messages in detail",
    "hardac.processes.messages.error.does-not-load": "Failed to load process messages, please try reloading the page or contact your administrator.",
    "hardac.processes.messages.error-message": "Error Message",
    "hardac.processes.messages.event-message": "Event Message",
    "hardac.processes.messages.event-message.encoder-context": "Encoder Context",
    "hardac.processes.messages.event-message.event-type": "Event Type",
    "hardac.processes.messages.event-message.event-time": "Event Time",
    "hardac.processes.messages.event-message.get.error.does-not-have-access": "Failed to load message, you may not have access to the data. Please contact your administrator.",
    "hardac.processes.messages.event-message.get.error.does-not-load": "Failed to load message, please try reloading the page or contact your administrator.",
    "hardac.processes.messages.event-message.operation-context": "Operation Context",
    "hardac.processes.messages.event-message.reprocess": "Reprocess",
    "hardac.processes.messages.event-message.reprocess.error.does-not-have-access": "Failed to load reprocess message, you may not have access to the data. Please contact your administrator.",
    "hardac.processes.messages.event-message.reprocess.error.does-not-load": "Failed to reprocess message, please try reloading the page or contact your administrator.",
    "hardac.processes.messages.event-message.reprocess.success": "Event message successfully reprocessed",
    "hardac.processes.messages.event-message.save.success": "Event message successfully saved",
    "hardac.processes.messages.event-message.save.error.does-not-have-access": "Failed to save message, you may not have access to the data. Please contact your administrator.",
    "hardac.processes.messages.event-message.save.error.does-not-load": "Failed to save message, please try reloading the page or contact your administrator.",
    "hardac.processes.messages.event-message.topic": "Topic",
    "hardac.processes.messages.event-message.workflow-job-name": "Workflow Job Name",
    "hardac.processes.messages.id": "Id",
    "hardac.processes.messages.get.error.does-not-have-access": "Failed to load process messages, you may not have access to the data. Please contact your administrator.",
    "hardac.processes.messages.processed-date": "Processed Date",
    "hardac.processes.messages.received-date": "Received Date",
    "hardac.processes.messages.title": "Messages",
    "hardac.processes.object-id": "Object Id",
    "hardac.processes.process-complete": "Process Complete",
    "hardac.processes.process-type": "Process Type",
    "hardac.processes.process-type-value": "Process Type Value",
    "hardac.processes.received-date": "Received Date",
    "hardac.processes.reprocess": "Reprocess",
    "hardac.processes.reprocess.error.404": "Failed to reprocess, you may not have access to perform this operation. Please contact your administrator.",
    "hardac.processes.reprocess.error.422": "Failed to reprocess message, please try reloading the page or contact your administrator.",
    "hardac.processes.reprocess.success": "Reprocess was scheduled successfuly",
    "hardac.processes.sent-date": "Sent Date",
    "hardac.processes.summary": "Summary",
    "hardac.processes.summary.failure": "Failure Info",
    "hardac.processes.summary.info": "File Info",
    "hardac.processes.status": "Status",
    "hardac.processes.status.encode.for.inbox.proxy": "Encode for Inbox Proxy",
    "hardac.processes.task-complete": "Tasks Complete",
    "hardac.processes.time-elapsed": "Time Elapsed",
    "hardac.processes.title": "Processes",

    "hardac.processes.machine-learning": "A.I. Machine Learning",

    "hardac.proxies.browse.title": "Browse Proxies",

    "hardac.setup.configuration.get.error": "Unable to load Hardac configuration.",
    "hardac.setup.configuration.get.forbidden": "You are not authorized to edit Hardac configuration, please contact your service admin.",
    "hardac.setup.configuration.hardac-configuration.caption": "Set values for various global video configurations.",
    "hardac.setup.configuration.hardac-configuration": "HARDAC Configuration",
    "hardac.setup.configuration.options.cdns": "CDNs",
    "hardac.setup.configuration.options.forensic-handling": "Forensic Handling",
    "hardac.setup.configuration.options.other-feature-flags": "Other Feature Flags",
    "hardac.setup.configuration.options": "Options",
    "hardac.setup.configuration.save.error": "There was an error saving the Hardac configuration.",
    "hardac.setup.configuration.save.forbidden": "You are not authorized to edit Hardac configuration, please contact your service admin.",
    "hardac.setup.configuration.save.success": "Hardac configuration successfully saved",
    "hardac.tasks.blob.copy.lts": "Copy Asset to Long Term Storage",
    "hardac.tasks.blob.copy.timeline.stereo": "Copying Stereo Timeline Proxy to Streaming Storage",
    "hardac.tasks.encode.for.timeline.stereo": "Encoding Stereo Timeline Proxy",
    "hardac.tasks.timeline-encoding-status": "Timeline Encoding Status",
    "hardac.tasks.encoding-tasks": "Encoding Tasks",
    "hardac.timelines.browse.title": "Browse Timelines",
    "hardac.timelines.browse.edit-info": "Edit Info",
    "hardac.timelines.browse.status.failed": "Failed: Some or all clips have failed publishing",
    "hardac.timelines.browse.status.published": "Published: All clips have successfully published",
    "hardac.timelines.browse.status.publishing": "Publishing: Publishing has started and no clips have failed",
    "hardac.timelines.browse.status.ready": "Ready: Timeline is ready to be clipped",

    "hardac.timeline.audio-profile": "Audio Profile",
    "hardac.timeline.clip.active": "Active",
    "hardac.timeline.clip.add": "Add Clip",
    "hardac.timeline.clip.create": "Create New Clip",
    "hardac.timeline.clip.create.title": "Create New Clip",
    "hardac.timeline.clip.delete": "Delete Clip",
    "hardac.timeline.clip.delete.confirm.message": "Are you sure you want to delete \"{}\"?",
    "hardac.timeline.clip.delete.not-available": "Not available.",
    "hardac.timeline.clip.deliveryType": "Delivery Type",
    "hardac.timeline.clip.display-name": "Asset Display Name",
    "hardac.timeline.clip.display-name.description": "Display name of published asset",
    "hardac.timeline.clip.dubs": "Dubs",
    "hardac.timeline.clip.edit": "Edit clip",
    "hardac.timeline.clip.form.name": "Asset Name",
    "hardac.timeline.clip.front-end-preview": "Front-End Preview",
    "hardac.timeline.clip.hasDub": "Has Dubs",
    "hardac.timeline.clip.hasDubLabel": "Dubbed",
    "hardac.timeline.clip.hasSub": "Has Subs",
    "hardac.timeline.clip.hasSubLabel": "Subtitles",
    "hardac.timeline.clip.info": "Clip Info",
    "hardac.timeline.clip.language": "Language",
    "hardac.timeline.clip.mfaRequired": "MFA Required",
    "hardac.timeline.clip.protection-type": "Protection Type",
    "hardac.timeline.clip.publish.clip": "Publish Clip",
    "hardac.timeline.clip.publish.disabled.tooltip": "Publishing disabled: save Timeline before publishing.",
    "hardac.timeline.clip.publish.disabled.text": "Disabled Until Save",
    "hardac.timeline.clip.publish.enabled": "Publish clip",
    "hardac.timeline.clip.publish.error": "Unable to publish Video Asset \"{}\".",
    "hardac.timeline.clip.publish.now": "Publish Now",
    "hardac.timeline.clip.publish.success": "Video Asset \"{}\" published successfully",
    "hardac.timeline.clip.reprocess.not-available": "Not available.",
    "hardac.timeline.clip.status": "Status",
    "hardac.timeline.clip.subtitles": "Subtitles",
    "hardac.timeline.clip.texted-textless": "Texted/Textless",
    "hardac.timeline.clip.texted-textless.both": "Both",
    "hardac.timeline.clip.texted-textless.none": "--",
    "hardac.timeline.clip.texted-textless.texted": "Texted",
    "hardac.timeline.clip.texted-textless.textless": "Textless",
    "hardac.timeline.clips": "Clips",
    "hardac.timeline.clips.back-to-timeline": "Back To Timeline",
    "hardac.timeline.clips.clip-in": "Clip in",
    "hardac.timeline.clips.clip-out": "Clip out",
    "hardac.timeline.clips.content-type": "Content Type",
    "hardac.timeline.clips.duration": "Duration",
    "hardac.timeline.clips.end": "End",
    "hardac.timeline.clips.failed-clips": "Failed Clips",
    "hardac.timeline.clips.get.error": "There was a problem loading timeline clips, please try again.",
    "hardac.timeline.clips.name": "Name",
    "hardac.timeline.clips.processing-clips": "Processing Clips",
    "hardac.timeline.clips.publish": "Publish Clips",
    "hardac.timeline.clips.publish-all": "Publish All",
    "hardac.timeline.clips.publish-all.error": "Unable to publish Video Assets.",
    "hardac.timeline.clips.publish-all.success": "Video Assets published successfully.",
    "hardac.timeline.clips.published-clips": "Published Clips",
    "hardac.timeline.clips.title": "Clips",
    "hardac.timeline.clips.thumbnail": "Thumbnail",
    "hardac.timeline.clips.unpublished-clips": "Unpublished Clips",
    "hardac.timeline.controls.clear-clip": "Clear Points",
    "hardac.timeline.controls.set-inpoint": "Set Inpoint",
    "hardac.timeline.controls.set-outpoint": "Set Outpoint",
    "hardac.timeline.export-bulk.error": "There was a problem exporting the timeline with id {} to on-prem storage, please try contacting brainaic support for additional assistance.",
    "hardac.timeline.export-bulk.selected-count": "timeline(s) selected for export.",
    "hardac.timeline.export-bulk.success": "Timelines export to on-prem storage has been initiated.",
    "hardac.timeline.export.export-avid": "Send to Avid",
    "hardac.timeline.export.export-avid.description": "This will export to Interplay using the folder below.",
    "hardac.timeline.export.export-avid.path": "/mnt/MAM/HARDAC-ONPREM-RETURN-AVID",
    "hardac.timeline.export.export-avid.path.caption": "Avid Export Folder Path",
    "hardac.timeline.export.error": "There was a problem exporting the timeline to on-prem storage, please try contacting brainaic support for additional assistance.",
    "hardac.timeline.export.export-source-file": "Export Source File",
    "hardac.timeline.export.export-timeline.description": "This will download the source file to on-prem storage so you can work with the file offline.",
    "hardac.timeline.export.export-timeline": "Export Timeline",
    "hardac.timeline.export.export-timelines": "Export Timelines",
    "hardac.timeline.export.modal.body": "This will export the source file to on-prem storage. Are you sure?",
    "hardac.timeline.export.modal.export-now": "Export Now",
    "hardac.timeline.export.modal.title": "Export Timeline",
    "hardac.timeline.export.modal.titles": "Export Timelines",
    "hardac.timeline.export.success": "Timeline export to on-prem storage has been initiated.",
    "hardac.timeline.find-by-id.audio-profile.error": "There was a problem loading timeline audio profile, please try again.",
    "hardac.timeline.find-by-id.clips.error": "There was a problem loading timeline clips, please try again.",
    "hardac.timeline.find-by-id.error": "There was a problem loading timeline information, please reload the page.",
    "hardac.timeline.find-by-id.history.error": "There was a problem loading timeline action history, please try again.",
    "hardac.timeline.hotkeys": "Hotkeys",
    "hardac.timeline.hotkeys.keyboard-shortcuts": "Keyboard shortcuts",
    "hardac.timeline.processes.check-status": "Check Status",
    "hardac.timeline.proxy.not-available": "Proxy file is not available yet, please try in a few minutes.",
    "hardac.timeline.proxy.src.get.error": "There was an error requesting the proxy URL for playback, please reload the page.",
    "hardac.timeline.save": "Save",
    "hardac.timeline.save.audio-profile.error": "There was an error saving the timeline audio profile, please try again",
    "hardac.timeline.save.error": "Unable to update Timeline",
    "hardac.timeline.save.clips.error": "There was an error saving the timeline clips, please try again",
    "hardac.timeline.save.success": "Timeline Successfully Saved",
    "hardac.timeline.save.summary.error": "There was an error saving the timeline summary, please try again",
    "hardac.timeline.source.not-available": "The timeline has no source file available",
    "hardac.timeline.source.retrieve-in-progress": "Getting media URL for playback...",
    "hardac.timeline.source.storage.not-available": "The timeline's source file is being processed, please check back later. Current status: {}",
    "hardac.timeline.thumbnail": "Thumbnail",
    "hardac.timeline.thumbnail.selection-modal.button": "Select Thumbnail",
    "hardac.timeline.thumbnail.selection-modal.text": "Choose Frame From Clip",
    "hardac.timeline.thumbnail.selection-modal.title": "Select New Thumbnail",
    "hardac.timeline.thumbnails": "Thumbnails",
    "hardac.timeline.send-to-oap.confirm": " Send Now",
    "hardac.timeline.send-to-oap.error": "Error sending Timeline to OAP.",
    "hardac.timeline.send-to-oap.note": "Sends relevant video data, including the fields below, to On Air Pro.",
    "hardac.timeline.send-to-oap.show-code": "Show Code",
    "hardac.timeline.send-to-oap.success": "Successfully sent to OAP.",
    "hardac.timeline.send-to-oap.title": "Send to OAP",
    "hardac.timeline.summary.active": "Active",
    "hardac.timeline.summary.archive-status": "Archive Status",
    "hardac.timeline.summary.audio-channels": "Audio Channels",
    "hardac.timeline.summary.audio-configuration": "Audio Configuration",
    "hardac.timeline.summary.barcode": "Barcode",
    "hardac.timeline.summary.catalogs": "Catalogs",
    "hardac.timeline.summary.clips": "Clips",
    "hardac.timeline.summary.container": "Container",
    "hardac.timeline.summary.content-type": "Content Type",
    "hardac.timeline.summary.created-by": "Created By",
    "hardac.timeline.summary.created": "Created Date",
    "hardac.timeline.summary.delivery-formats": "Delivery Formats",
    "hardac.timeline.summary.delivery-type": "Delivery Type",
    "hardac.timeline.summary.display-aspect-ratio": "Display Aspect Ratio",
    "hardac.timeline.summary.dubs": "Dubs",
    "hardac.timeline.summary.duration": "Duration",
    "hardac.timeline.summary.edit.button": "Edit Info",
    "hardac.timeline.summary.end-time": "End Time",
    "hardac.timeline.summary.export-folder-path": "Export Folder Path",
    "hardac.timeline.summary.file-info": "File Info",
    "hardac.timeline.summary.file-name": "File Name",
    "hardac.timeline.summary.file-size": "File Size",
    "hardac.timeline.summary.first-frame-timecode": "First Frame Timecode",
    "hardac.timeline.summary.format-profile": "Format Profile",
    "hardac.timeline.summary.format": "Format",
    "hardac.timeline.summary.framerate": "Framerate",
    "hardac.timeline.summary.has-dubs": "Has Dubs",
    "hardac.timeline.summary.has-subs": "Has Subs",
    "hardac.timeline.summary.info": "Timeline Info",
    "hardac.timeline.summary.language": "Language",
    "hardac.timeline.summary.media-info.error": "Failed formatting Media Info, raw value: ",
    "hardac.timeline.summary.media-info": "Media Info",
    "hardac.timeline.summary.mfa-required": "Require MFA",
    "hardac.timeline.summary.mpm-number": "MPM #",
    "hardac.timeline.summary.name": "Timeline Name",
    "hardac.timeline.summary.pixel-aspect-ratio": "Pixel Aspect Ratio",
    "hardac.timeline.summary.protection-type": "Protection Type",
    "hardac.timeline.summary.proxy-only": "Proxy only",
    "hardac.timeline.summary.proxy-path": "Proxy Path",
    "hardac.timeline.summary.proxy.left": "Proxy Left Channel",
    "hardac.timeline.summary.proxy.right": "Proxy Right Channel",
    "hardac.timeline.summary.publishing-info.subtitle": "Publishing info will be inherited by clipped assets",
    "hardac.timeline.summary.publishing-info.tooltip.no-items": "No items",
    "hardac.timeline.summary.publishing-info.tooltip.suffix": "More",
    "hardac.timeline.summary.publishing-info": "Publishing Info",
    "hardac.timeline.summary.sampled-height": "Sampled Height",
    "hardac.timeline.summary.sampled-width": "Sampled Width",
    "hardac.timeline.summary.send-to-oap.barcode": "Barcode:",
    "hardac.timeline.summary.send-to-oap.note": "This will send relevant video data to On Air Pro.",
    "hardac.timeline.summary.send-to-oap.sent": "Already Sent",
    "hardac.timeline.summary.send-to-oap.title": " Send to OAP",
    "hardac.timeline.summary.serviceable-properties": "Serviceable Properties",
    "hardac.timeline.summary.source-file-location": "Source File Location",
    "hardac.timeline.summary.source-path": "Source Path",
    "hardac.timeline.summary.start-time": "Start Time",
    "hardac.timeline.summary.stereo": "Stereo Information",
    "hardac.timeline.summary.streaming-proxy-path": "Streaming Proxy Path",
    "hardac.timeline.summary.subtitles": "Subtitles",
    "hardac.timeline.summary.talent": "Talent",
    "hardac.timeline.summary.tape-barcode": "Tape Barcode",
    "hardac.timeline.summary.texted-textless.both": "Both",
    "hardac.timeline.summary.texted-textless.none": "--",
    "hardac.timeline.summary.texted-textless.texted": "Texted",
    "hardac.timeline.summary.texted-textless.textless": "Textless",
    "hardac.timeline.summary.texted-textless": "Texted/Textless",
    "hardac.timeline.summary.thumbnails-sprite-path": "Thumbnails Sprite Path",
    "hardac.timeline.summary.titles": "Titles",
    "hardac.timeline.summary.tracks": "Tracks",
    "hardac.timeline.summary.updated-by": "Last Modifier",
    "hardac.timeline.summary.updated": "Last Modified Date",
    "hardac.timeline.summary.users": "Users",

    "hardac.timeline.tab-title.audio": "Audio",
    "hardac.timeline.tab-title.clips": "Clips",
    "hardac.timeline.title": "Video Timeline",

    "hardac.video-inbox.approve.error": "There was a problem when approving, please try again",
    "hardac.video-inbox.approve.forbidden": "You are not authorized to approve a video delivery, please contact your service admin.",
    "hardac.video-inbox.approve.success": "Video Delivery has been approved",
    "hardac.video-inbox.approve.title": "Approve Video Delivery",
    "hardac.video-inbox.approved-by": "Approved By",
    "hardac.video-inbox.created-date": "Created Date",
    "hardac.video-inbox.duration": "Duration",
    "hardac.video-inbox.encoding-platform": "Encoding Platform",
    "hardac.video-inbox.error.does-not-have-access": "Failed to load video deliveries, you may not have access to the data. Please contact your administrator.",
    "hardac.video-inbox.error.does-not-load": "Failed to load video deliveries, please try reloading the page or contact your administrator.",
    "hardac.video-inbox.file-name": "File Name",
    "hardac.video-inbox.frame-rate": "Frame Rate",
    "hardac.video-inbox.histoy": "History",
    "hardac.video-inbox.media-info.error": "Failed formatting Media Info, raw value: ",
    "hardac.video-inbox.media-info.title": "Media Info",
    "hardac.video-inbox.name": "Name",
    "hardac.video-inbox.options.audio-configuration": "Audio Configuration",
    "hardac.video-inbox.options.delivery-formats": "Delivery Formats",
    "hardac.video-inbox.options.delivery-type": "Delivery Type",
    "hardac.video-inbox.options.dubs": "Dubs",
    "hardac.video-inbox.options.has-dubs": "Has Dubs",
    "hardac.video-inbox.options.has-subs": "Has Subs",
    "hardac.video-inbox.options.protection-type": "Protection Type",
    "hardac.video-inbox.options.rights-info": "Rights Info",
    "hardac.video-inbox.options.serviceable-properties": "Serviceable Properties",
    "hardac.video-inbox.options.subtitles": "Subtitles",
    "hardac.video-inbox.options.texted-textless": "Texted/Textless",
    "hardac.video-inbox.options.texted-textless.both": "Both",
    "hardac.video-inbox.options.texted-textless.none": "--",
    "hardac.video-inbox.options.texted-textless.texted": "Texted",
    "hardac.video-inbox.options.texted-textless.textless": "Textless",
    "hardac.video-inbox.proxy-left": "Proxy Left",
    "hardac.video-inbox.proxy-file-location": "Proxy File Location",
    "hardac.video-inbox.proxy-right": "Proxy Right",
    "hardac.video-inbox.reject.error": "There was a problem when rejecting, please try again",
    "hardac.video-inbox.reject.forbidden": "You are not authorized to reject a video delivery, please contact your service admin.",
    "hardac.video-inbox.reject.reasons": "Please add a note explaining the reason for rejection:",
    "hardac.video-inbox.reject.success": "Video Delivery has been rejected",
    "hardac.video-inbox.reject.title": "Please Confirm Rejection",
    "hardac.video-inbox.rejected-by": "Rejected by",
    "hardac.video-inbox.reviewed-by": "Reviewed by",
    "hardac.video-inbox.runtime": "Runtime",
    "hardac.video-inbox.save.error": "There was a problem saving, please try again",
    "hardac.video-inbox.save.forbidden": "You are not authorized to save a video delivery, please contact your service admin.",
    "hardac.video-inbox.save.success": "Video Delivery has been saved",
    "hardac.video-inbox.src-url.error": "There was an error requesting the source URL for playback, please reload the page.",
    "hardac.video-inbox.source.not-available": "Video Delivery has no source file location available",
    "hardac.video-inbox.source.retrieve-in-progress": "Getting media URL for playback...",
    "hardac.video-inbox.source-file": "Source File Location",
    "hardac.video-inbox.status": "Status",
    "hardac.video-inbox.start-time": "Start Time",
    "hardac.video-inbox.summary.asset-name": "Asset Name",
    "hardac.video-inbox.summary.barcode": "Barcode",
    "hardac.video-inbox.summary.brainiac-title-info": "Title",
    "hardac.video-inbox.summary.content-type": "Content Type",
    "hardac.video-inbox.summary.language": "Language",
    "hardac.video-inbox.summary.oap.description": "Determines whether to send data to OAP, grab existing audio data from OAP, or neither.",
    "hardac.video-inbox.summary.oap.oap-sync-options": "OAP Sync Options",
    "hardac.video-inbox.summary.oap.title": "Title",
    "hardac.video-inbox.summary.pull-from-oap.barcode.error": "The barcode you've entered is not valid, please review and try again",
    "hardac.video-inbox.summary.pull-from-oap.barcode": "Barcode",
    "hardac.video-inbox.summary.pull-from-oap.empty-oap-metadata.error": "The barcode you've entered did not return any OAP metadata, please review and try again",
    "hardac.video-inbox.summary.pull-from-oap.get-oap-info.description": "Enter the barcode of the OAP asset to get audio profile.",
    "hardac.video-inbox.summary.pull-from-oap.get-oap-info": "Get OAP Info",
    "hardac.video-inbox.summary.pull-from-oap.incomplete-audio-profile.error": "Audio profile may be incomplete! Unable to create audio channels for: {}.",
    "hardac.video-inbox.summary.pull-from-oap.oap-audio-config.audio-configuration": "Audio Configuration Imported from OAP",
    "hardac.video-inbox.summary.pull-from-oap.oap-audio-config.audio-tab": "Audio Tab",
    "hardac.video-inbox.summary.pull-from-oap.oap-audio-config.before-approving": " before approving",
    "hardac.video-inbox.summary.pull-from-oap.oap-audio-config.description": "Please ensure the audio profile is correct on the ",
    "hardac.video-inbox.summary.pull-from-oap.oap-audio-config": "OAP Audio Config",
    "hardac.video-inbox.summary.pull-from-oap.pull-audio-profile-from-oap": "Pull Audio Profile From OAP",
    "hardac.video-inbox.summary.pull-from-oap.pull-oap-audio-profile": "Pull OAP Audio Profile",
    "hardac.video-inbox.summary.send-to-oap.brainiac-info.aspect-ratio": "Aspect Ratio",
    "hardac.video-inbox.summary.send-to-oap.brainiac-info.drop-frame": "Drop Frame",
    "hardac.video-inbox.summary.send-to-oap.brainiac-info.episode-mpm": "Episode MPM",
    "hardac.video-inbox.summary.send-to-oap.brainiac-info.episode-title": "Episode Title",
    "hardac.video-inbox.summary.send-to-oap.brainiac-info.file-dimensions": "File Dimensions",
    "hardac.video-inbox.summary.send-to-oap.brainiac-info.frame-rate": "Frame Rate",
    "hardac.video-inbox.summary.send-to-oap.brainiac-info.genre-type": "Genre/Type",
    "hardac.video-inbox.summary.send-to-oap.brainiac-info.main-title": "Main Title",
    "hardac.video-inbox.summary.send-to-oap.brainiac-info.prod-num": "Prod #",
    "hardac.video-inbox.summary.send-to-oap.brainiac-info.season-mpm": "Season MPM",
    "hardac.video-inbox.summary.send-to-oap.brainiac-info.season": "Season",
    "hardac.video-inbox.summary.send-to-oap.brainiac-info.trt": "TRT",
    "hardac.video-inbox.summary.send-to-oap.brainiac-info": "Brainiac Info",
    "hardac.video-inbox.summary.send-to-oap.connect-brainiac-title": "Connect Brainiac Title to Video",
    "hardac.video-inbox.summary.send-to-oap.description": "Search for the title this video will be directly related to. Use the child title, not the parent.",
    "hardac.video-inbox.summary.send-to-oap.editable-info.description": "These fields will populate Brainiac and OAP video data.",
    "hardac.video-inbox.summary.send-to-oap.editable-info.show-code": "Show Code",
    "hardac.video-inbox.summary.send-to-oap.editable-info.standard": "Standard",
    "hardac.video-inbox.summary.send-to-oap.editable-info": "Editable Info",
    "hardac.video-inbox.summary.send-to-oap.workflow.asset-name": "Asset Name",
    "hardac.video-inbox.summary.send-to-oap.workflow.audio-configuration.audio-tab": "Audio Tab",
    "hardac.video-inbox.summary.send-to-oap.workflow.audio-configuration.description": "Please ensure the audio profile is correct on the ",
    "hardac.video-inbox.summary.send-to-oap.workflow.audio-configuration": "Audio Configuration",
    "hardac.video-inbox.summary.send-to-oap.workflow.category": "Category",
    "hardac.video-inbox.summary.send-to-oap.workflow.content-type": "Content Type",
    "hardac.video-inbox.summary.send-to-oap.workflow.description": "Determines whether to send data to OAP, grab existing audio data from OAP, or neither.",
    "hardac.video-inbox.summary.send-to-oap.workflow.division": "Division",
    "hardac.video-inbox.summary.send-to-oap.workflow.element-type": "Element Type",
    "hardac.video-inbox.summary.send-to-oap.workflow.file-dimensions": "File Dimensions",
    "hardac.video-inbox.summary.send-to-oap.workflow.image-quality": "Image Quality",
    "hardac.video-inbox.summary.send-to-oap.workflow.language": "Language",
    "hardac.video-inbox.summary.send-to-oap.workflow.programming": "Programming",
    "hardac.video-inbox.summary.send-to-oap.workflow.serviceable": "Serviceable",
    "hardac.video-inbox.summary.send-to-oap.workflow.sync-options": "Sync Options",
    "hardac.video-inbox.summary.send-to-oap.workflow.sync-to-interplay": "Sync To Interplay",
    "hardac.video-inbox.summary.send-to-oap.workflow": "Workflow",
    "hardac.video-inbox.summary.send-to-oap.get-brainiac-info": "Get Brainiac Info",
    "hardac.video-inbox.summary.send-to-oap.get-title-info": "Get Brainiac Title Info",
    "hardac.video-inbox.summary.send-to-oap.show-codes.error": "Unable to load show codes from OAP",
    "hardac.video-inbox.summary.show-code": "Show Code",
    "hardac.video-inbox.summary.title.information": "Info",
    "hardac.video-inbox.summary.title": "QC Video",
    "hardac.video-inbox.summary": "Summary",
    "hardac.video-inbox.title": "Video Inbox",
    "hardac.video-inbox.titles.add-title": "Add Title",
    "hardac.video-inbox.updated-date": "Updated Date",
    "hardac.video-inbox.warning.no-proxy": "Video delivery does not have a proxy file, playback is not available.",

    "help.errors.loading": "Error loading Help page data, please try again.",
    "help.errors.no-access": "You do not have access to the Help Videos",
    "help.errors.video-not-found": "Could not load video {}",

    "help-pages.by": "by",
    "help-pages.edit-help-page": "Edit Help Page",
    "help-pages.edit-help-page.description": "Reminder: this page is to help users understand the functions of the CMS. Please review best practices here before editing.",
    "help-pages.help-pages": "Help Pages",
    "help-pages.last-updated": "Last Updated:",
    "help-pages.modal.title": "Cancel Edit Page Change",
    "help-pages.modal.body": "You are about to lose your changes in this current section.",
    "help-pages.save.success": "Help page saved",
    "help-pages.title": "Help:",
    "help-pages.titles.accounts": "Accounts",
    "help-pages.titles.assets": "Assets",
    "help-pages.titles.brands": "Brands",
    "help-pages.titles.catalogs": "Catalogs",
    "help-pages.titles.events": "Events",
    "help-pages.titles.groups": "Groups",
    "help-pages.titles.guild": "Integrations",
    "help-pages.titles.history": "History",
    "help-pages.titles.publishing-list": "Publishing",
    "help-pages.titles.push-notifications": "Push Notifications",
    "help-pages.titles.presets": "Presets",
    "help-pages.titles.reports": "Analytics",
    "help-pages.titles.roles": "Roles",
    "help-pages.titles.scheduling": "Scheduling",
    "help-pages.titles.setup": "Setup",
    "help-pages.titles.stations": "Stations",
    "help-pages.titles.talent": "Talent",
    "help-pages.titles.titles": "Titles",

    "history-tab-title": "History",
    "history.browse": "Browse History",
    "history.browse.action": "Action",
    "history.browse.admin": "Admin",
    "history.browse.date-time": "Date & Time",
    "history.browse.detail": "Detail",
    "history.browse.object": "Object",
    "history.browse.object-type": "Object Type",
    "history.changed-field.date-format": "YYYY-MM-DD HH:mm:ss.SSSSSS",
    "history.changed-field.new-value": "New Value",
    "history.changed-field.old-value": "Old Value",
    "history.default-view": "Default View",
    "history.event.date": "ll",
    "history.event.hours": "MM/DD/YY @ hh:mm a",
    "history.extended-view": "Extended View",
    "history.load-error": "Sorry, there was an error loading history data, please try again",
    "history.qc-video.read-only": "ORIGINAL QC PAGE (READ-ONLY)",
    "history.title": "All History",
    "history_actions.add_note.error" : "Note failed to save, please try again",
    "history_actions.add_note.success" : "Note added",

    "homepages.browse.loading-user-groups": "Loading User Groups...",
    "homepages.browse.no-user-groups": "No User Groups",
    "homepages.browse.title": "Browse Homepages",
    "homepages.create.button": "Create Homepage",
    "homepages.create.groups.chose": "Choose user groups to add",
    "homepages.create.groups.title": "User Groups",
    "homepages.create.lists.title": "Lists",
    "homepages.create.navigation-menu.description": "Navigation Menus determine which custom navigation tabs will appear for users of this homepage.",
    "homepages.create.navigation-menu": "Navigation Menu",
    "homepages.create.partners.title": "Partners",
    "homepages.create.templates.additional-section": "List",
    "homepages.create.templates.additional-section.add": "Add List",
    "homepages.create.templates.billboard": "Billboard",
    "homepages.create.templates.legacy": "Legacy ",
    "homepages.create.templates.playlist": "Playlist ",
    "homepages.create.templates.press-release": "Press Release ",
    "homepages.create.templates.small-billboard": "Video Billboard",
    "homepages.create.templates.title-one": "Title One",
    "homepages.create.templates.title-three": "Title Three",
    "homepages.create.templates.title-two": "Title Two",
    "homepages.create.templates.title": "Template",
    "homepages.create.templates.upfronts": "Upfronts ",
    "homepages.create.templates.video-list": "Video List",
    "homepages.create.title": "Create Homepage",
    "homepages.delete.error": "Error trying to delete the Homepage",
    "homepages.delete.success":"Successfully deleted the Homepage",
    "homepages.description": "Description",
    "homepages.edit.cant-change": " (Cannot be changed)",
    "homepages.edit.confirm-delete" : "Please Confirm Deletion",
    "homepages.edit.confirm-delete.body" : "Are you sure you want to Delete this Homepage?",
    "homepages.edit.confirm-delete.confirm" : "Confirm",
    "homepages.edit.title": "Edit Homepage",
    "homepages.id": "ID#",
    "homepages.info": "Homepage Info",
    "homepages.last-modified-date": "Last Modified Date",
    "homepages.list-items.edit-list-items": "Edit List Items",
    "homepages.list-items.error.section1": "There was an error loading items for Billboard.",
    "homepages.list-items.error.section2": "There was an error loading items for Video Billboard.",
    "homepages.list-items.error.section3": "There was an error loading items for Title One.",
    "homepages.list-items.error.section4": "There was an error loading items for Title Two.",
    "homepages.list-items.error.section5": "There was an error loading items for Title Three.",
    "homepages.list-items": "List Items",
    "homepages.more": " More",
    "homepages.name": "Homepage name",
    "homepages.name.placeholder": "Please enter homepage name",
    "homepages.plus": " + ",
    "homepages.save.error": "Unable to save homepage",
    "homepages.save.success": "Homepage saved",
    "homepages.status": "Status",

    "homescreens.browse.title": "Browse Homescreens",
    "homescreens.browse.usergroups.empty": "No User Groups assigned",
    "homescreens.browse.usergroups.loading": "Loading ...",
    "homescreens.create.billboard": "Billboard",
    "homescreens.create.button": "Create Homescreen",
    "homescreens.create.groups.title": "User Groups",
    "homescreens.create.lists.add": "Add List",
    "homescreens.create.lists.remove": "Remove",
    "homescreens.create.lists.title": "Lists",
    "homescreens.create.partners.placeholder": "Select Partner",
    "homescreens.create.partners.title": "Partners",
    "homescreens.create.title": "Create Homescreen",
    "homescreens.delete.error": "Error trying to delete the Homescreen",
    "homescreens.delete.success":"Successfully deleted the Homescreen",
    "homescreens.description": "Description",
    "homescreens.edit.confirm-delete" : "Please Confirm Deletion",
    "homescreens.edit.confirm-delete.body" : "Are you sure you want to Delete this Homescreen?",
    "homescreens.edit.confirm-delete.confirm" : "Confirm",
    "homescreens.edit.title": "Edit Homescreen",
    "homescreens.info": "Homescreen Info",
    "homescreens.list-items.does-not-have-access": "Failed to load publishing list with id {}, you may not have access to the data. Please contact your administrator.",
    "homescreens.list-items.does-not-load": "Failed to load publishing list with id {}, please try reloading the page or contact your administrator.",
    "homescreens.more": " More",
    "homescreens.name": "Homescreen name",
    "homescreens.name.placeholder": "Please enter homescreen name",
    "homescreens.plus": " + ",
    "homescreens.save.error": "Unable to save homescreen",
    "homescreens.save.success": "Homescreen saved",
    "homescreens.status": "Status",

    "login-backgrounds.browse.title": "Login Backgrounds",
    "login-backgrounds.btn.remove": "Delete Selected",
    "login-backgrounds.btn.upload": "Upload Files",
    "login-backgrounds.disclaimer.link": "Publishing Help Page",
    "login-backgrounds.disclaimer.text-1": "Please review the Login Background portion of the",
    "login-backgrounds.disclaimer.text-2": "for more information about best practices for creating and displaying these images.",
    "login-backgrounds.disclaimer.title": "Important Note",
    "login-backgrounds.error.get-backgrounds": "Error loading backgrounds, please try again.",
    "login-backgrounds.error.get-partners": "Error loading partners, please try again.",
    "login-backgrounds.error.remove": "Error deleting background(s), please try again.",
    "login-backgrounds.error.upload": "Error uploading background, please try again.",
    "login-backgrounds.last-modified": "Last Modified",
    "login-backgrounds.name": "Name",
    "login-backgrounds.remove-backgrounds.modal.body": "Are you sure you want to remove background(s)?",
    "login-backgrounds.remove-backgrounds.modal.confirm": "Remove",
    "login-backgrounds.remove-backgrounds.modal.title": "Please Confirm",
    "login-backgrounds.size": "Size",
    "login-backgrounds.success.remove": "Background(s) removed successfully",
    "login-backgrounds.success.upload": "Background uploaded successfully",
    "login.browser.not.supported": "Sorry, your browser is not fully supported. Please use Google Chrome for a better experience.",
    "login.email": "Email",
    "login.error.default": "Login failed! Please try again",
    "login.error.mfa.400": "Error trying to send the MFA push notification. Please try again or contact your Client Representative.",
    "login.error.mfa.code.rejected": "Please enter a correct Okta's verification in order to access the content.",
    "login.error.mfa.rejected": "Please approve Okta's MFA Notification in order to access the content.",
    "login.error.not-found": "The email and password you entered don't match.",
    "login.error.too-many-requests": "There was an error processing your request. Please try again in 10 minutes.",
    "login.forgot-password": "Forgot Password",
    "login.ip.warning": "You are not fully on the VPN, you may experience issues updating titles or uploading files. Please see the help page <a href='{}'>here</a> if you are having issues.",
    "login.login.in-progress": "Logging In...",
    "login.logout.invalid": "You have been logged out due to an invalid session.",
    "login.logout.success": "You have logged out successfully.",
    "login.logout.timeout": "You have been logged out due to inactivity.",
    "login.mfa.verifying-token": "Verifying code...",
    "login.password": "Password",
    "login.sign-in": "Sign In",
    "login.sign-up": "Sign Up",
    "login.title": "Login | WBTVD Brainiac",

    "lookups.client-rep.title": "Client Rep",
    "lookups.company.create.error": "Error creating company",
    "lookups.company.create.ok": "Company created successfully",
    "lookups.company.remove.error": "Error removing company",
    "lookups.company.remove.ok": "Company removed successfully",
    "lookups.company.save.error": "Error saving company",
    "lookups.company.save.ok": "Company updated successfully",
    "lookups.company.title": "Companies",
    "lookups.create.error": "Lookup with this name already exists",
    "lookups.currency.code": "3 Letter Code",
    "lookups.currency.create.error": "Error creating currency",
    "lookups.currency.create.ok": "Currency created successfully",
    "lookups.currency.remove.error": "Error removing currency",
    "lookups.currency.remove.ok": "Currency removed successfully",
    "lookups.currency.save.error": "Error saving currency",
    "lookups.currency.save.ok": "Currency updated successfully",
    "lookups.currency.title": "Currency",
    "lookups.description": "Hover over individual items to view control options.",
    "lookups.domain_blacklist.create.error": "Unable to create Lookup",
    "lookups.domain_blacklist.create.ok": "Lookup created",
    "lookups.domain_blacklist.remove.error": "Unable to delete Lookup",
    "lookups.domain_blacklist.remove.ok": "Lookup deleted",
    "lookups.domain_blacklist.save.error": "Unable to update Lookup",
    "lookups.domain_blacklist.save.ok": "Lookup updated",
    "lookups.domain_blacklist.title": "Domain Blacklist",
    "lookups.genre.create.error": "Error creating genre",
    "lookups.genre.create.ok": "Genre created successfully",
    "lookups.genre.remove.error": "Error removing genre",
    "lookups.genre.remove.ok": "Genre removed successfully",
    "lookups.genre.save.error": "Error saving genre",
    "lookups.genre.save.ok": "Genre updated successfully",
    "lookups.genre.title": "Genres",
    "lookups.language.create.error": "Error creating language",
    "lookups.language.create.ok": "Language created successfully",
    "lookups.language.remove.error": "Error removing language",
    "lookups.language.remove.ok": "Language removed successfully",
    "lookups.language.save.error": "Error saving language",
    "lookups.language.save.ok": "Language updated successfully",
    "lookups.language.title": "Languages",
    "lookups.remove.msg1": "Removing a {{entity}} may reduce functionality of the site. This action cannot be undone.",
    "lookups.remove.msg2": "To complete this action, type \"Remove\" in the box below.",
    "lookups.territory.create.error": "Error creating territory",
    "lookups.territory.create.ok": "Country created successfully",
    "lookups.territory.remove.error": "Error removing territory",
    "lookups.territory.remove.ok": "Country removed successfully",
    "lookups.territory.save.error": "Error saving territory",
    "lookups.territory.save.ok": "Country updated successfully",
    "lookups.territory.title": "Country",
    "lookups.theme.create.error": "Error creating theme",
    "lookups.theme.create.ok": "Theme created successfully",
    "lookups.theme.remove.error": "Error removing theme",
    "lookups.theme.remove.ok": "Theme removed successfully",
    "lookups.theme.save.error": "Error saving theme",
    "lookups.theme.save.ok": "Theme updated successfully",
    "lookups.theme.title": "Themes",
    "lookups.title": "Lookups",
    "lookups.user-select.title": "Client Select",

    "mfa.enrollment.error": "Unable to send MFA Enrollment.",
    "mfa.enrollment.sent": "MFA Enrollment sent.",

    "multi-tenancy.modal.title": "Set Custom Homepage",
    "multi-tenancy.modal.body": "You've selected this page as your primary designated homepage, please confirm.",

    "navigation.last-login": "Last Login:",
    "navigation.profile": "Profile",
    "navigation.sign-out": "Sign out",

    "page-title.homepages.browse": "Browse Homepages",
    "page-title.publishing-list.browse": "Browse Publishing Lists",

    "partners.title": "Partners",
    "partners.primary-partner": "Primary Partner",
    "partners.primary-partner.applicant-error": "Primary Partner (under \"Permissions\" tab)",
    "partners.primary-partner.caption": "Default portal users will access.",
    "partners.web-partners": "Web Partners",
    "partners.web-partners.caption": "Web portals users will have access to.",

    "permission.actions.get.error": "There was an error loading the permission list, please try again later",
    "permission.actions.get_by_id.error": "There was an error loading this permission, please try again later",
    "permission.actions.user.get.error": "There was an error loading the user list, please try again later",
    "permission.browse.description": "View the roles associated with each permission",
    "permission.browse.title": "Permissions Audit",
    "permission.create.description": "Edit Permission and view list of users and associated roles.",
    "permission.create.title": "Edit Permission",
    "permission.summary.associated_roles": "Associated Roles",
    "permission.summary.info": "Permission Info",
    "permission.summary.path_and_name": "Name and Path",
    "permission.summary.title": "Summary",
    "permission.users.title": "Users",
    "permission.users.table.company": "Company",
    "permission.users.table.email": "Email",
    "permission.users.table.loading_roles": "Loading Roles",
    "permission.users.table.name": "Name",
    "permission.users.table.roles": "Member of roles featuring this permission: ",

    "permissions.api-functions": "API Functions",
    "permissions.brainiac-cms-functions": "Brainiac CMS Functions",
    "permissions.wbtvd-functions": "WBTVD Functions",
    "permissions.fyc-functions": "FYC Functions",

    "player.errors.drm.has-license-but-no-source": "The video you want to play has a DRM license but no valid streams.",
    "player.errors.drm.incomplete-license": "The video you want to play has incomplete DRM information. Please try again or contact your Administrator.",
    "player.errors.drm.token.404": "Error requesting DRM playback authorization. Please try again, if the problem persists, contact your Client Representative.",
    "player.errors.mfa.400": "Error trying to send the MFA push notification. Please try again or contact your Client Representative.",
    "player.errors.mfa.rejected": "Please approve Okta's MFA Notification in order to play the video.",
    "player.errors.mfa.status": "There was a problem reaching Okta for MFA status. Please try again.",
    "player.errors.mfa.unknown-status": "There was a problem reaching Okta for MFA status. Please try again.",
    "player.errors.no-sources": "There are no playable sources for this video.",
    "player.errors.streams.400": "There was an error while getting the streams to play the video. Please try again or contact your Client Representative.",
    "player.mfa.enrollment.modal.body": "An email has been sent to <b>%s</b><br/>Please open the email on your phone and follow the instructions on how to activate Okta Verify App for your account.",
    "player.mfa.enrollment.modal.title": "Secure Content",
    "player.mfa.push-notification.modal.body": "A notification has been sent to the Okta Verify App on your phone. Please approve to continue. OR you may",
    "player.mfa.push-notification.modal.body.code": "enter your verification code",
    "player.mfa.push-notification.modal.title": "Secure Content",
    "player.mfa.verification-code.modal.body": "A 6-digit code will display in the Okta app. Please enter the code below",
    "player.mfa.verification-code.modal.body.enter-code": "Enter code",

    "presets.browse.date-added": "Date Added",
    "presets.browse.description": "Description",
    "presets.browse.preset-name": "Preset Name",
    "presets.browse.status": "Status",
    "presets.browse.title": "Permissions Preset Packages",
    "presets.create.client-rep-groups.chose": "Choose who has access to this",
    "presets.create.client-rep-groups.title": "Client Rep Groups",
    "presets.create.error": "Unable to create Preset Package, please try again",
    "presets.create.groups.chose": "Choose user groups to add",
    "presets.create.groups.title": "User Groups",
    "presets.create.info.description": "Description",
    "presets.create.info.description.placeholder": "Enter here...",
    "presets.create.info.name": "Name",
    "presets.create.info.name.placeholder": "Please enter name",
    "presets.create.info.status": "Status",
    "presets.create.info.title": "Preset Info",
    "presets.create.partner.title": "Partner",
    "presets.create.roles.chose": "Choose roles to add",
    "presets.create.roles.title": "Roles",
    "presets.create.save": "Create Preset",
    "presets.create.stations.chose": "Choose stations to add",
    "presets.create.stations.title": "Stations",
    "presets.create.subtitle": "Please fill out the form below to create a new permissions preset packages",
    "presets.create.success": "Preset created successfully",
    "presets.create.title": "Create a Preset",
    "presets.delete.error": "Unable to delete Preset",
    "presets.delete.success": "Preset Deleted",
    "presets.edit.delete": "Delete",
    "presets.edit.error": "Unable to save changes",
    "presets.edit.last-modified-date": "Last Modified Date",
    "presets.edit.modal.body": "Are you sure you want to Delete this Preset?",
    "presets.edit.modal.confirm": "Confirm",
    "presets.edit.modal.title": "Please Confirm Deletion",
    "presets.edit.subtitle": "Review the permissions preset package information below",
    "presets.edit.success": "Preset Saved",
    "presets.edit.summary-tab-title": "Summary",
    "presets.edit.title": "Edit Preset",
    "presets.save.error": "Unable to save changes",
    "presets.save.success": "Preset saved",

    "privacy.browse.date-received": "Date Received",
    "privacy.browse.regulation-type": "Regulation Type",
    "privacy.browse.request-type": "Request Type",
    "privacy.browse.status": "Status",
    "privacy.browse.subject-id": "Subject ID",
    "privacy.browse.subject": "Subject Request ID",
    "privacy.browse.title": "Browse Privacy Requests",
    "privacy.create-request.error": "There was an error creating the privacy request, please contact support",
    "privacy.create-request.success": "Privacy Request has been created successfully",
    "privacy.create-request": "Create Request",
    "privacy.find_by_id.error": "There was an error loading the privacy request, please contact support",
    "privacy.get.error": "There was an error getting the privacy requests, please contact support",
    "privacy.regulation-type.ccpa": "CCPA",
    "privacy.regulation-type.cpra": "CPRA",
    "privacy.regulation-type.usa": "USA",
    "privacy.request-summary.title": "Request Info",
    "privacy.request-type.access": "Access",
    "privacy.request-type.correct": "Correct",
    "privacy.request-type.delete": "Delete",
    "privacy.summary.email": "Email",
    "privacy.summary.errors.title": "Errors",
    "privacy.summary.export": "Export",
    "privacy.summary.irr-id": "IRR ID",
    "privacy.summary.message-details.error-message": "Error Message",
    "privacy.summary.message-details.fulfillment-response": "Fulfillment Response",
    "privacy.summary.message-details.status-response": "Status Response",
    "privacy.summary.message-details.title": "Message Details",
    "privacy.summary.message-received": "Message Received",
    "privacy.summary.title": "Summary",
    "privacy.title.create": "Create Privacy Request",
    "privacy.title.edit": "View Privacy Request",

    "publishing-list.add-item.select.placeholder": "Select One",
    "publishing-list.add-item.title.desire-asset": "Please browse or search to find the desired asset.",
    "publishing-list.add-item.title.desire-title": "Please browse or search to find the desired title.",
    "publishing-list.add-item.title.item-info": "Item Info",
    "publishing-list.add-item.title.media-assets": "Browse Media Assets ",
    "publishing-list.add-item.title.titles": "Browse Titles ",
    "publishing-list.browse.title": "Browse Publishing Lists",
    "publishing-list.create.button": "Create",
    "publishing-list.create.error": "Unable to Create List",
    "publishing-list.create.info": "Please fill out the form below to create a new publishing list.",
    "publishing-list.create.title": "Create Publishing List",
    "publishing-list.delete.error": "Error trying to delete the Publishing List",
    "publishing-list.delete.success": "Successfully deleted the Publishing List",
    "publishing-list.description": "Description",
    "publishing-list.display-name": "Display Name",
    "publishing-list.edit.error": "Unable to Update List",
    "publishing-list.edit.info": "Review the publishing list information below.",
    "publishing-list.edit.modal.body": "Are you sure you want to Delete this Publishing List?",
    "publishing-list.edit.modal.confirm": "Confirm",
    "publishing-list.edit.modal.title": "Please Confirm Deletion",
    "publishing-list.edit.title": "Edit Publishing List",
    "publishing-list.failed.load": "Failed to load publishing list",
    "publishing-list.failed.load-history": "Failed to load publishing list history",
    "publishing-list.failed.load-items": "Failed to load publishing list items",
    "publishing-list.id": "ID#",
    "publishing-list.image":"Publishing List Image",
    "publishing-list.image.upload": "Publishing List Image",
    "publishing-list.info": "List Info",
    "publishing-list.last-modified-date": "Last Modified Date",
    "publishing-list.last-modifier": "Last Modifier",
    "publishing-list.list-item.add-existing": "Add an item to ",
    "publishing-list.list-item.add-item.button": "Add an Item to List",
    "publishing-list.list-item.catalog": "Catalog",
    "publishing-list.list-item.description": "Description",
    "publishing-list.list-item.item-type": "Item Type",
    "publishing-list.list-item.item.add-image.button": "Add Image",
    "publishing-list.list-item.item.add-video.button": "Add Video",
    "publishing-list.list-item.item.asset.add": "Add",
    "publishing-list.list-item.item.detail": "Item Detail",
    "publishing-list.list-item.item.description": "Item Description",
    "publishing-list.list-item.item.display-name": "Item Display Name",
    "publishing-list.list-item.item.edit": "Edit Publishing List Item",
    "publishing-list.list-item.item.edit.modal.body": "Are you sure you want to Delete this Publish List Item?",
    "publishing-list.list-item.item.edit.remove": "Remove",
    "publishing-list.list-item.item.formatted.title": "Formatted:",
    "publishing-list.list-item.item.image-preview": "Image Preview",
    "publishing-list.list-item.item.is-html": "Use Rich Text Editor (for editing HTML)",
    "publishing-list.list-item.item.none-selected": "None Selected",
    "publishing-list.list-item.item.original-description.title": "Original Description",
    "publishing-list.list-item.item.raw.title": "Raw:",
    "publishing-list.list-item.item.raw-html.title": "Raw HTML:",
    "publishing-list.list-item.item.remove-image.button": "Remove Image",
    "publishing-list.list-item.item.remove-video.button": "Remove Video",
    "publishing-list.list-item.item.replace-image.button": "Replace Image",
    "publishing-list.list-item.item.replace-video.button": "Replace Video",
    "publishing-list.list-item.item.save.button": "Add Item",
    "publishing-list.list-item.item.save.error": "Error saving Publishing List Item",
    "publishing-list.list-item.item.save.success": "List Item successfully added",
    "publishing-list.list-item.item.target-link.title": "Target Link",
    "publishing-list.list-item.item.target.title": "Target",
    "publishing-list.list-item.item.targetUrl": "Target Link",
    "publishing-list.list-item.item.title.add": "Add",
    "publishing-list.list-item.item.upload.help": "Help",
    "publishing-list.list-item.item.upload.image": "Custom Image",
    "publishing-list.list-item.item.upload.video": "Custom Video",
    "publishing-list.list-item.item.user-group": "User Groups",
    "publishing-list.list-item.item.video-preview": "Video Preview",
    "publishing-list.list-item.select.targetType": "Please Select Target Type",
    "publishing-list.list-item.tab.title": "List Items",
    "publishing-list.list-item.target": "Target",
    "publishing-list.list-item.target.item.detail": "Item Detail",
    "publishing-list.list-item.target.replace-target.button": "Replace Target",
    "publishing-list.list-item.target.table.asset-id": "ID",
    "publishing-list.list-item.target.table.asset-name": "Asset Name",
    "publishing-list.list-item.target.table.asset-parent": "Parent",
    "publishing-list.list-item.target.table.asset-type": "Asset Type",
    "publishing-list.list-item.target.table.mpm": "MPM",
    "publishing-list.list-item.target.table.running-order": "RO",
    "publishing-list.list-item.target.table.title": "Title",
    "publishing-list.list-item.target.table.title-id": "Title ID",
    "publishing-list.list-item.target.table.title-parent": "Parent",
    "publishing-list.list-item.target.table.title-type": "Title Type",
    "publishing-list.name": "Name",
    "publishing-list.purpose": "Purpose",
    "publishing-list.replace-publishing-list.image":"Replace Image",
    "publishing-list.save.error": "Error saving a Publishing List",
    "publishing-list.save.success": "Publishing List Saved",
    "publishing-list.status": "Status",
    "publishing-list.summary-link-name": "Summary Link Name",
    "publishing-list.summary-link-url": "Summary Link URL",
    "publishing-list.summary-tab-title": "Summary",

    "push-notifications.add-item.title.desire-asset": "Please browse or search to find the desired asset.",
    "push-notifications.add-item.title.desire-title": "Please browse or search to find the desired title.",
    "push-notifications.add-item.title.media-assets": "Browse Media Assets ",
    "push-notifications.add-item.title.titles": "Browse Titles ",
    "push-notifications.asset.add": "Add",
    "push-notifications.browse.title": "Browse Push Notifications",
    "push-notifications.create.save": "Save Message",
    "push-notifications.create.title": "Create Push Notification",
    "push-notifications.edit.message-body": "Message Body",
    "push-notifications.edit.message-title": "Message Title",
    "push-notifications.edit.partner": "Partner ID",
    "push-notifications.edit.push-notification-info": "Notification Info",
    "push-notifications.edit.push-notification.target": "Target",
    "push-notifications.edit.push-notification.target-button": "Select Push Notification Target",
    "push-notifications.edit.save.error": "There was an error trying to save the push notification",
    "push-notifications.edit.save.success.create": "Push notification created",
    "push-notifications.edit.save.success.update": "Push notification updated",
    "push-notifications.edit.send.error": "There was an error trying to send the push notification",
    "push-notifications.edit.send.error-already-sent": "There was an error trying to send the push notification (or it may have already been sent)",
    "push-notifications.edit.send-modal.cancel.button": "Cancel",
    "push-notifications.edit.send-modal.send-push-notification.button": "Send Notification",
    "push-notifications.edit.send.success": "Push notification sent",
    "push-notifications.edit.summary-tab-title": "Summary",
    "push-notifications.edit.title": "Edit Push Notification",
    "push-notifications.edit.user-group": "User Groups",
    "push-notifications.message-body.character-limit": "/160",
    "push-notifications.message-body.recommended-characters": " Recommended: less than 160 characters",
    "push-notifications.message-title.character-limit": "/20",
    "push-notifications.message-title.recommended-characters": " Recommended: less than 20 characters",
    "push-notifications.send.button": "Send Now",
    "push-notifications.title": "Push Notifications",
    "push-notifications.title.add": "Add",

    "request.cloudflare.error": "Cloudflare error, contact your Brainiac administrator.",

    "roles.create.description": "Create role, set permissions, and criteria.",
    "roles.create.duplicate-modal.body": "This will create a new role by duplicating the current role and all permissions. Are you sure?",
    "roles.create.duplicate-modal.title": "Duplicate Role",
    "roles.create.duplicate.error": "There was an error while duplicating the role. Please contact support.",
    "roles.create.duplicate.success": "Role has been successfully duplicated",
    "roles.create.save": "Create Role",
    "roles.create.title": "Create Role",
    "roles.edit.confirm-delete.body" : "Are you sure?",
    "roles.edit.confirm-delete.confirm" : "Delete Role",
    "roles.edit.confirm-delete.title" : "Please Confirm Deletion",
    "roles.edit.description": "Edit Role summary, permissions, and users.",
    "roles.edit.last-modified-date": "Last Modified Date",
    "roles.edit.last-modifier": "Last Modifier",
    "roles.edit.load.error": "Unable to load Roles, please try again",
    "roles.edit.load.error.broken-permissions": "Some permissions are corrupted, they will display as checkboxes without labels. Here is the raw data: {}",
    "roles.edit.name": "Name",
    "roles.edit.permissions-tab-title": "Permissions",
    "roles.edit.remove-users": "Remove Users",
    "roles.edit.remove-users.error": "An error ocurred while removing users from role, please try again",
    "roles.edit.remove-users.modal.body": "Are you sure you want to remove users?",
    "roles.edit.remove-users.modal.confirm": "Remove",
    "roles.edit.remove-users.modal.title": "Please Confirm",
    "roles.edit.remove-users.success": "Successfully removed users from role",
    "roles.edit.remove.error": "Unable to delete Role",
    "roles.edit.remove.success": "The role was successfully removed",
    "roles.edit.role-info": "Role Info",
    "roles.edit.role.description": "Description",
    "roles.edit.save.error": "An error occurred while saving your role, please try again",
    "roles.edit.save.success": "Role updated",
    "roles.edit.status": "Status",
    "roles.edit.status-active": "Active",
    "roles.edit.summary-tab-title": "Summary",
    "roles.edit.title": "Edit Role",
    "roles.edit.users-tab-title": "Users",
    "roles.title": "Roles",

    "scheduling.batch.asset-detail": "Asset Detail",
    "scheduling.batch.asset-detail.delete": "Remove From Batch",
    "scheduling.batch.items-list.add-document": "Add Assets",
    "scheduling.batch.items-list.add-image": "Add Assets",
    "scheduling.batch.items-list.add-title": "Add Titles",
    "scheduling.batch.items-list.add-title.add-children-dialog.title": "Add Title?",
    "scheduling.batch.items-list.add-title.add-children-dialog.body": "You have added \"{}\". Would you like to also add",
    "scheduling.batch.items-list.add-video": "Add Assets",
    "scheduling.batch.items-list.adding-existing.add-title": "Add Title",
    "scheduling.batch.items-list.adding-existing.document": "Add Existing Asset",
    "scheduling.batch.items-list.adding-existing.image": "Add Existing Asset",
    "scheduling.batch.items-list.adding-existing.remove-title": "Remove Title",
    "scheduling.batch.items-list.adding-existing.title": "Add Existing Title",
    "scheduling.batch.items-list.adding-existing.video": "Add Existing Asset",
    "scheduling.batch.items-list.confirm-delete.title": "Please Confirm Deletion",
    "scheduling.batch.items-list.confirm-delete-document.body": "Are you sure you want to Remove this Document from the Batch?",
    "scheduling.batch.items-list.confirm-delete-image.body": "Are you sure you want to Remove this Image from the Batch?",
    "scheduling.batch.items-list.confirm-delete-title.body": "Are you sure you want to Remove this Title from the Batch?",
    "scheduling.batch.items-list.confirm-delete-video.body": "Are you sure you want to Remove this Video from the Batch?",
    "scheduling.batch.items-list.documents-title": "Documents in Batch",
    "scheduling.batch.items-list.get-children.error": "There was a problem getting titles, please try again.",
    "scheduling.batch.items-list.get-documents.error": "There was a problem loading Batch Documents, please try again.",
    "scheduling.batch.items-list.get-images.error": "There was a problem loading Batch Images, please try again.",
    "scheduling.batch.items-list.get-titles.error": "There was a problem loading Batch Titles, please try again.",
    "scheduling.batch.items-list.get-videos.error": "There was a problem loading Batch Videos, please try again.",
    "scheduling.batch.items-list.images-title": "Images in Batch",
    "scheduling.batch.items-list.table.category": "Category",
    "scheduling.batch.items-list.table.document-id": "Asset ID",
    "scheduling.batch.items-list.table.format": "Format",
    "scheduling.batch.items-list.table.image-id": "Asset ID",
    "scheduling.batch.items-list.table.mpm": "MPM #",
    "scheduling.batch.items-list.table.name": "Name",
    "scheduling.batch.items-list.table.other": "Other",
    "scheduling.batch.items-list.table.remove": "Remove",
    "scheduling.batch.items-list.table.title-id": "Title ID",
    "scheduling.batch.items-list.table.type": "Type",
    "scheduling.batch.items-list.table.video-id": "Asset ID",
    "scheduling.batch.items-list.titles-title": "Titles in Batch",
    "scheduling.batch.items-list.videos-title": "Videos in Batch",
    "scheduling.batch.catalogs.get.error" : "There was a problem loading catalogs, please try again.",
    "scheduling.batch.document.save.error": "An error occurred while saving your Document Scheduling Batch, please try again",
    "scheduling.batch.document.save.success": "Document Scheduling Batch Successfully Saved",
    "scheduling.batch.document.title": "Document Scheduling Batch",
    "scheduling.batch.duplicate": "Duplicate",
    "scheduling.batch.duplicate.modal.confirm": "Duplicate",
    "scheduling.batch.duplicate.modal.title": "Duplicate Scheduling Batch",
    "scheduling.batch.duplicate.save.error": "An error occurred while saving your new Batch, please try again",
    "scheduling.batch.duplicate.save.success": "Duplicated Batch Successfully Saved",
    "scheduling.batch.find-by-id.error.does-not-have-access": "Failed to load batch, you may not have access to the data. Please contact your administrator.",
    "scheduling.batch.find-by-id.history.error": "There was a problem loading batch action history, please try again.",
    "scheduling.batch.image.save.error": "An error occurred while saving your Image Scheduling Batch, please try again",
    "scheduling.batch.image.save.success": "Image Scheduling Batch Successfully Saved",
    "scheduling.batch.image.title": "Image Scheduling Batch",
    "scheduling.batch.summary.active": "Active",
    "scheduling.batch.summary.active.description": "Sets future active/inactive status",
    "scheduling.batch.summary.apply-to-children": "Apply to all children",
    "scheduling.batch.summary.created": "Created Date",
    "scheduling.batch.summary.created-by": "Created By",
    "scheduling.batch.summary.date": "Date",
    "scheduling.batch.summary.delivery-type": "Delivery Type",
    "scheduling.batch.summary.hide-show-synopsis-log-lines": "Hide/Show Synopsis & Log Lines",
    "scheduling.batch.summary.hide-show-synopsis-log-lines.description": "Hidden fields will not be displayed outside of Brainiac",
    "scheduling.batch.summary.info": "Batch Info",
    "scheduling.batch.summary.last-modified": "Last Modified Date",
    "scheduling.batch.summary.last-modifier": "Last Modifier",
    "scheduling.batch.summary.mfa": "MFA",
    "scheduling.batch.summary.name": "Name",
    "scheduling.batch.summary.time": "Time",
    "scheduling.batch.summary.eta": "ETA",
    "scheduling.batch.summary.system-time": "System Time",
    "scheduling.batch.summary.total-assets": "Total Assets",
    "scheduling.batch.summary.total-catalogs": "Total Catalogs",
    "scheduling.batch.summary.total-titles": "Total Titles",
    "scheduling.batch.summary.turn-off-disclaimer-notice": "Turn off disclaimer notice",
    "scheduling.batch.summary.email.email-recipients": "E-mail Recipients",
    "scheduling.batch.summary.email.add-external-groups": "Add External Announcement Groups",
    "scheduling.batch.summary.email.add-internal-groups": "Add Internal Confirmation Groups",
    "scheduling.batch.summary.email.view-template": "View Template",
    "scheduling.batch.summary.email.get-user-groups.error": "There was a problem loading batch email notification groups, please try again.",
    "scheduling.batch.users.load-error": "There was an error loading the list of users, the users tab will show empty.",
    "scheduling.batch.users.tab.title": "Users",
    "scheduling.batch.title.save.error": "An error occurred while saving your Title Scheduling Batch, please try again",
    "scheduling.batch.title.save.success": "Title Scheduling Batch Successfully Saved",
    "scheduling.batch.title.title": "Title Scheduling Batch",
    "scheduling.batch.video.save.error": "An error occurred while saving your Video Scheduling Batch, please try again",
    "scheduling.batch.video.save.success": "Video Scheduling Batch Successfully Saved",
    "scheduling.batch.video.title": "Video Scheduling Batch",
    "scheduling.batches.assets": "Assets",
    "scheduling.batches.catalogs": "Catalogs",
    "scheduling.batches.created-date": "Created Date",
    "scheduling.batches.get.error.does-not-load": "Failed to load {} batches, please try reloading the page or contact your administrator.",
    "scheduling.batches.get.error.does-not-have-access": "Failed to load {} batches, you may not have access to the data. Please contact your administrator.",
    "scheduling.batches.name": "Name",
    "scheduling.batches.start-date": "Start Date/Time",
    "scheduling.batches.status": "Status",
    "scheduling.batches.status.completed": "Completed",
    "scheduling.batches.status.completed.tooltip": "Batch job complete",
    "scheduling.batches.status.pending": "Pending",
    "scheduling.batches.status.pending.tooltip": "Batch job has not yet started",
    "scheduling.batches.titles": "Titles",
    "scheduling.batches.users": "Users",
    "scheduling.document-batches.title": "Browse Document Batches",
    "scheduling.image-batches.title": "Browse Image Batches",
    "scheduling.title-batches.title": "Browse Title Batches",
    "scheduling.video-batches.title": "Browse Video Batches",

    "session.timeoutWarning.keep-alive-button": "Keep Alive",
    "session.timeoutWarning.message-post": " Click the 'Keep Alive' button to continue",
    "session.timeoutWarning.message-pre": "Your session will expire in ",
    "session.timeoutWarning.title": "Your session is about to expire",

    "sidebar.accounts.applicants": "Applicants",
    "sidebar.accounts.browse": "Browse Accounts",
    "sidebar.accounts.create-applicant": "Add Applicant",
    "sidebar.accounts.mass-check": "Mass Check",
    "sidebar.accounts.mass-ingest.browse": "Browse Account Wizard",
    "sidebar.accounts.mass-ingest": "Account Wizard",
    "sidebar.accounts.mass-ingest/update": "Wizard/Mass Update",
    "sidebar.accounts.mass-update": "Mass Update",
    "sidebar.accounts.user-errors": "User Errors",
    "sidebar.accounts": "Accounts",
    "sidebar.analytics.scheduled-reports": "Scheduled Reports",
    "sidebar.analytics.tracking-report": "Tracking Report",
    "sidebar.analytics": "Analytics",
    "sidebar.assets.browse.all": "Browse All",
    "sidebar.assets.browse.document": "Browse Documents",
    "sidebar.assets.browse.image": "Browse Images",
    "sidebar.assets.browse.video": "Browse Videos",
    "sidebar.assets.browse": "Browse Assets",
    "sidebar.assets.create.audio": "Audio",
    "sidebar.assets.create.document": "Document",
    "sidebar.assets.create.image": "Image",
    "sidebar.assets.create.merchandise": "Merchandise",
    "sidebar.assets.create.script": "Script",
    "sidebar.assets.create.video": "Video",
    "sidebar.assets.create": "Add Asset",
    "sidebar.assets": "Assets",
    "sidebar.brainiac-navigation": "BRAINIAC NAVIGATION",
    "sidebar.brainiac": "Brainiac",
    "sidebar.brands": "Brands",
    "sidebar.browse": "Browse",
    "sidebar.catalogs.asset.mass-update": "Mass Update",
    "sidebar.catalogs.asset": "Asset Catalogs",
    "sidebar.catalogs.language.mass-update": "Mass Update",
    "sidebar.catalogs.language": "Language Catalogs",
    "sidebar.catalogs.title.mass-update": "Mass Update",
    "sidebar.catalogs.title": "Title Catalogs",
    "sidebar.catalogs": "Catalogs",
    "sidebar.client-rep.browse": "Client Reps",
    "sidebar.create": "Add",
    "sidebar.events.add": "Add Event",
    "sidebar.events.archived": "Browse Archived",
    "sidebar.events.browse": "Browse Events",
    "sidebar.events.help": "Events Help",
    "sidebar.events": "Events",
    "sidebar.favorites": "Favorites",
    "sidebar.groups.client-rep-groups": "Client Rep Groups",
    "sidebar.groups.user-groups": "User Groups",
    "sidebar.groups": "Groups",
    "sidebar.guilds.add": "Add New Guild",
    "sidebar.guilds.browse": "Browse Guilds",
    "sidebar.guilds": "Guilds",
    "sidebar.hardac-navigation": "HARDAC NAVIGATION",
    "sidebar.hardac.ai.browse": "Browse A.I. Models",
    "sidebar.hardac.ai": "A.I.",
    "sidebar.hardac.jobs": "Browse Jobs",
    "sidebar.hardac.processes": "Browse All Processes",
    "sidebar.hardac.proxies.browse": "Browse Proxies",
    "sidebar.hardac.proxies": "Proxies",
    "sidebar.hardac.setup.configuration": "Configuration",
    "sidebar.hardac.setup": "Setup",
    "sidebar.hardac.timelines.browse": "Browse Timelines",
    "sidebar.hardac.timelines.video-qc-inbox": "Video QC Inbox",
    "sidebar.hardac.timelines": "Timelines",
    "sidebar.hardac": "HARDAC",
    "sidebar.help-videos": "Help Videos",
    "sidebar.help": " Help",
    "sidebar.homepages": "Homepages",
    "sidebar.homescreens": "Homescreens",
    "sidebar.login-backgrounds": "Login Backgrounds",
    "sidebar.presets": "Presets",
    "sidebar.privacy.browse": "Browse",
    "sidebar.privacy": "Privacy",
    "sidebar.publishing-list": "Publishing List",
    "sidebar.publishing": "Publishing",
    "sidebar.push-notifications": "Push Notifications",
    "sidebar.roles.permissions-audit": "Permissions Audit",
    "sidebar.roles": "Roles",
    "sidebar.scheduling.document": " Document Batches",
    "sidebar.scheduling.image": " Image Batches",
    "sidebar.scheduling.title": " Title Batches",
    "sidebar.scheduling.video": " Video Batches",
    "sidebar.scheduling": "Scheduling",
    "sidebar.setup.authorized-partners": "Authorized Partners",
    "sidebar.setup.email-whitelist": "Email Whitelist",
    "sidebar.setup.lookups": "Lookups",
    "sidebar.setup.utilities": "Utilities",
    "sidebar.setup": "Setup",
    "sidebar.site-navigation": "SITE NAVIGATION",
    "sidebar.starred": "STARRED",
    "sidebar.stations": "Stations",
    "sidebar.stations.station-groups": "Station Groups",
    "sidebar.system": "System",
    "sidebar.talent.browse": "Browse Talent",
    "sidebar.talent.create": "Add Talent",
    "sidebar.talent": "Talent",
    "sidebar.titles.add": "Add Title",
    "sidebar.titles.browse": "Browse Titles",
    "sidebar.titles.delete-queue": "Delete Queue",
    "sidebar.titles.mass-add-titles": "Mass Add Titles",
    "sidebar.titles.mass-add/update.logs.get.error": "Error trying to get ingested titles. Please try again",
    "sidebar.titles.mass-add/update.logs.get.ingested-row.error": "Error trying to get ingested title row with id {}. Please try again",
    "sidebar.titles.mass-add/update.logs.put.ingested-row.error": "Error trying to set ingested title row. Please try again",
    "sidebar.titles.mass-add/update.logs.get.related-titles.error": "Error trying to get realated title with id {}. Please try again",
    "sidebar.titles.mass-add/update.logs": "Mass Add/Update Log",
    "sidebar.titles.mass-add/update": "Mass Add/Update",
    "sidebar.titles.mass-update-titles": "Mass Update Titles",
    "sidebar.titles.work-orders-title": "Title Metadata",
    "sidebar.titles.work-orders.browse": "Browse Work Order",
    "sidebar.titles.work-orders.create": "Add Work Order",
    "sidebar.titles": "Titles",
    "sidebar.viewing-mode.dark": "Dark Mode",
    "sidebar.viewing-mode.light": "Light Mode",
    "sidebar.viewing-mode": "Viewing Mode",
    "sidebar.work-space": "WORKSPACE",

    "station-groups.browse.date-added": "Date Added",
    "station-groups.browse.description": "Description",
    "station-groups.browse.users": "Users",
    "station-groups.browse.station-name": "Station Group Name",
    "station-groups.browse.status": "Status",
    "station-groups.browse.title": "Station Groups",
    "station-groups.create.error": "Unable to create Station Group, please contact support",
    "station-groups.create.info.description.placeholder": "Enter here...",
    "station-groups.create.info.description": "Description",
    "station-groups.create.info.name.placeholder": "Please enter name",
    "station-groups.create.info.name": "Station Group name",
    "station-groups.create.info.status": "Status",
    "station-groups.create.info.title": "Station Group Info",
    "station-groups.create.success": "Station Group created successfully",
    "station-groups.create.text": "Create Station Group",
    "station-groups.create.title": "Please fill out the form below to create a new Station Group.",
    "station-groups.edit.error": "Unable to save changes, please contact support",
    "station-groups.edit.last-modified-date": "Last Modified Date",
    "station-groups.edit.last-modifier": "Last Modifier",
    "station-groups.edit.title": "Review the Station Group information below.",
    "station-groups.edit.success": "Station Group Saved",
    "station-groups.get.error": "Error getting Station Group, please contact support",

    "stations.browse.date-added": "Date Added",
    "stations.browse.description": "Description",
    "stations.browse.users": "Users",
    "stations.browse.station-name": "Station Name",
    "stations.browse.status": "Status",
    "stations.browse.title": "Stations",
    "stations.create.asset-catalogs.choose": "Choose asset catalogs to add",
    "stations.create.asset-catalogs.title": "Asset Catalogs",
    "stations.create.error": "Unable to create Station, please contact support",
    "stations.create.info.description.placeholder": "Enter here...",
    "stations.create.info.description": "Description",
    "stations.create.info.name.placeholder": "Please enter name",
    "stations.create.info.name": "Station name",
    "stations.create.info.status": "Status",
    "stations.create.info.title": "Station Info",
    "stations.create.success": "Station created successfully",
    "stations.create.text": "Create Station",
    "stations.create.title": "Please fill out the form below to create a new Station.",
    "stations.create.title-catalogs.choose": "Choose title catalogs to add",
    "stations.create.title-catalogs.title": "Title Catalogs",
    "stations.edit.error": "Unable to save changes, please contact support",
    "stations.edit.last-modified-date": "Last Modified Date",
    "stations.edit.last-modifier": "Last Modifier",
    "stations.edit.title": "Review the Station information below.",
    "stations.edit.success": "Station Saved",
    "stations.get.error": "Error getting Station, please contact support",
    "stations.remove-users.success": "Successfully removed users from station",

    "talent.aka.get.error": "Error getting AKAs for a talent.",
    "talent.browse.title": "Talent",
    "talent.create.bio.title": "Bio",
    "talent.create.save-and-create": "Create & Add Another",
    "talent.create.save-button": "Create Talent",
    "talent.create.save.error": "An error occurred while saving your talent, please try again",
    "talent.create.save.success": "Talent created",
    "talent.create.subtitle": "Please fill out the form below to create new talent.",
    "talent.create.summary-tab-title": "Summary",
    "talent.create.talent-info.first-name": "First Name",
    "talent.create.talent-info.last-name": "Last Name",
    "talent.create.talent-info.middle-name": "Middle Name",
    "talent.create.talent-info.prefix": "Prefix",
    "talent.create.talent-info.suffix": "Suffix",
    "talent.create.talent-info.title": "Talent Info",
    "talent.create.title": "Create Talent",
    "talent.edit.add-aka": "Add AKA",
    "talent.edit.add-note.body": " Add note to talent:",
    "talent.edit.add-note.error" : "Note failed to save, please try again",
    "talent.edit.add-note.success" : "Note added",
    "talent.edit.aka.title": "Credited As",
    "talent.edit.assets-tab-title": "Assets",
    "talent.edit.confirmDelete.body" : "Are you sure you want to Delete this Talent?",
    "talent.edit.confirmDelete.confirm" : "Confirm",
    "talent.edit.confirmDelete.title" : "Please Confirm Deletion",
    "talent.edit.default-photo-title": "Default Photo",
    "talent.edit.last-modified-date": "Last Modified Date",
    "talent.edit.last-modifier": "Last Modifier",
    "talent.edit.picture.subtitle": "Visit the Assets tab to change default image.",
    "talent.edit.remove.error": "An error occurred while removing your talent",
    "talent.edit.remove.success": "The talent was successfully removed",
    "talent.edit.save.success": "Talent updated",
    "talent.edit.title": "Edit Talent",
    "talent.edit.titles-tab-title": "Titles",
    "talent.localized.error.load": "There was an error loading talent localizations",

    "titles.alerts-warnings.mpm-number.error": "Title with this MPM Number already exists.",
    "titles.alerts-warnings.mpm-number.failed": "Could not validate the MPM Number against the server.",
    "titles.alerts-warnings.mpm-number.pending": "Pending MPM Number uniqueness validation.",
    "titles.alerts-warnings.reference-id.error": "Title with this Reference ID already exists.",
    "titles.alerts-warnings.reference-id.failed": "Could not validate the Reference ID against the server.",
    "titles.alerts-warnings.reference-id.pending": "Pending Reference ID uniqueness validation.",
    "titles.approve-btn":" Approve Selected Titles",
    "titles.approve-selected-title":"Please confirm the fields below have been imported correctly. Select Approve Selected Titles to complete the creation of the imported titles, or Reject ALL to abort the entire batch.",
    "titles.browse.loading-catalogs": "Loading Catalogs...",
    "titles.browse.no-catalogs": "No Catalogs",
    "titles.create.add-title": "Add Title",
    "titles.create.assets-tab-audio-title": "Audio",
    "titles.create.assets-tab-documents-title": "Documents",
    "titles.create.assets-tab-stations-title": "Station Docs",
    "titles.create.assets-tab-images-title": "Images",
    "titles.create.assets-tab-merchandise-title": "Merchandise",
    "titles.create.assets-tab-scripts-title": "Scripts",
    "titles.create.assets-tab-timelines-title": "Video Timelines",
    "titles.create.assets-tab-videos-title": "Videos",
    "titles.create.assets.active": "active",
    "titles.create.assets.add-existing.confirm": "Add Assets",
    "titles.create.assets.add-existing": "Add Existing Asset",
    "titles.create.assets.add-new.images.note.example-name": "(i.e. \"image\" will become \"image001\", etc.)",
    "titles.create.assets.add-new.images.note.metadata-fields": "Filled metadata fields will apply to all",
    "titles.create.assets.add-new.images.note.serialized-names": "Name & Display Name will be serialized",
    "titles.create.assets.add-new.images.note.when-condition": "When adding multiple files:",
    "titles.create.assets.add-new.images": "Add New Image(s)",
    "titles.create.assets.add-new.title": "Add New Asset",
    "titles.create.assets.add-new": "Add New",
    "titles.create.assets.audio": "Audio",
    "titles.create.assets.confirmDelete.body" : "Are you sure you want to Remove this Asset from the Title?",
    "titles.create.assets.confirmDelete.confirm" : "Confirm",
    "titles.create.assets.confirmDelete.title" : "Please Confirm Deletion",
    "titles.create.assets.create.button": "Create Asset",
    "titles.create.assets.documents": "Document",
    "titles.create.assets.stations": "Station Docs",
    "titles.create.assets.image": "Image",
    "titles.create.assets.inactive": "inactive",
    "titles.create.assets.loading-catalogs": "Loading Catalogs...",
    "titles.create.assets.media-assets": "Media Assets",
    "titles.create.assets.merchandise": "Merchandise",
    "titles.create.assets.no-catalogs": "No Catalogs",
    "titles.create.assets.replace-video.copy-from-tooltip": "This video is the latest video and has replaced an older asset",
    "titles.create.assets.replace-video.copy-to-tooltip": "This video is an old version and was replaced by a newer video asset",
    "titles.create.assets.replace-video.error": "There was an error replacing your video. Please contact Support",
    "titles.create.assets.replace-video.panel.instructions": "Instructions: Please select the NEW version of the video asset you want to replace, then click the Replace Video button to confirm the changes.",
    "titles.create.assets.replace-video.panel.modal.body.note": "Confirming these changes will also set the old version of the video to inactive but may also copy other important data such as the relationship type of videos from events and the video views from forensically protected videos.",
    "titles.create.assets.replace-video.panel.modal.body.please-confirm": "Please confirm if you want to replace",
    "titles.create.assets.replace-video.panel.modal.body.select-data.event-catalogs": "Event Catalogs",
    "titles.create.assets.replace-video.panel.modal.body.select-data.metadata": "Metadata",
    "titles.create.assets.replace-video.panel.modal.body.select-data.restrictions": "Restrictions",
    "titles.create.assets.replace-video.panel.modal.body.select-data.title-catalogs": "Title Catalogs",
    "titles.create.assets.replace-video.panel.modal.body.select-data": "Select which data to copy:",
    "titles.create.assets.replace-video.panel.modal.title": "Confirm Replace Video",
    "titles.create.assets.replace-video.panel.note": "Note: This feature copies video data from one video asset to another video but may also copy down other important data such as the relationship of videos from events (old version videos that are part of events will be updated to the new version) and the video views for forensically protected videos",
    "titles.create.assets.replace-video.success": "Your video has been successfully replaced and your changes have been saved",
    "titles.create.assets.replace-video": "Replace Video",
    "titles.create.assets.scripts": "Scripts",
    "titles.create.assets.timelines": "Video Timelines",
    "titles.create.assets.upload-file": "Upload File",
    "titles.create.assets.video": "Video",
    "titles.create.default-photo-title": "Default Photo",
    "titles.create.edit-title-tab-title": "Titles",
    "titles.create.error.after-post": "The title has been partially created before the page encountered an error. <a href='/titles/{}'>Please go to the new title page and review all data.</a>",
    "titles.create.error": "There has been an error creating the title",
    "titles.create.language.available-date": "Avail Date",
    "titles.create.language.element": "Element",
    "titles.create.language.language": "Language",
    "titles.create.language.version": "Version",
    "titles.create.link.add": "Add Link",
    "titles.create.link.local-title": "Link Title",
    "titles.create.link.type": "Type",
    "titles.create.link.url": "URL",
    "titles.create.localization.add-new": "Add Metadata Language",
    "titles.create.localization.body": "View in Language",
    "titles.create.localization.remove-current": "Remove Current Language",
    "titles.create.localization.save.error": "An error occurred while saving your localizations",
    "titles.create.localization.title": "Localization",
    "titles.create.nav.sections.episode.title": "EPISODE",
    "titles.create.nav.sections.episodes.title": "EPISODES",
    "titles.create.nav.sections.season.title": "SEASON",
    "titles.create.nav.sections.series.title": "SERIES",
    "titles.create.nav.sections.title": "SECTIONS",
    "titles.create.preview-modal.view-in-wbtvd.disabled": "Add the video to a title in order to be displayed in WBTVD",
    "titles.create.preview-modal.view-in-wbtvd": "View In WBTVD",
    "titles.create.preview-modal": "Preview Video Asset",
    "titles.create.related-tab-title": "Related",
    "titles.create.related.add-existing.add": "Add",
    "titles.create.related.add-existing.children": "Add Existing Title as Children",
    "titles.create.related.add-existing.other": "Add Existing Title as Related",
    "titles.create.related.add-existing.parents": "Add Existing Title as Parent",
    "titles.create.related.add-existing.relationship-type": "Select the Relationship Type",
    "titles.create.related.children": "Children",
    "titles.create.related.dashboard-button": "View in Dashboard",
    "titles.create.related.errors.duplicated": "Cannot add: this title is already a related title",
    "titles.create.related.errors.select.relationship": "Please select a relationship type first",
    "titles.create.related.list.actions": "Actions",
    "titles.create.related.list.add-new.msg": "You have unsaved changes which will be committed if you continue.",
    "titles.create.related.list.add-new.title": "Continue?",
    "titles.create.related.list.cancel-return": "Cancel & Return",
    "titles.create.related.list.category": "Category",
    "titles.create.related.list.continue-save": "Continue & Save",
    "titles.create.related.list.mpm-number": "MPM #",
    "titles.create.related.list.relation-type": "Relation Type",
    "titles.create.related.list.season-number": "Season #",
    "titles.create.related.list.title-name": "Title Name",
    "titles.create.related.parents": "Parents",
    "titles.create.related.related": "Related",
    "titles.create.related.select-type": "Select the Relationship Type:",
    "titles.create.release.add": "Add Release",
    "titles.create.release.air-day": "Air Day",
    "titles.create.release.air-time": "Air Time",
    "titles.create.release.alert": "To Edit the Releases section, set your view back to the default",
    "titles.create.release.content-type": "Content Type",
    "titles.create.release.date-status": "Status",
    "titles.create.release.date-type": "Date Type",
    "titles.create.release.date": "Release Date",
    "titles.create.release.domestic-release-company": "Releasing Company/Network",
    "titles.create.release.language": "Language",
    "titles.create.release.order": "Order",
    "titles.create.release.release-type": "Release Type",
    "titles.create.release.territory": "Territory",
    "titles.create.style-tab-title": "Style",
    "titles.create.style.app-styles": "App Styles",
    "titles.create.style.asset-modal.button.remove": "Remove From Title",
    "titles.create.style.asset-modal.title": "Asset Detail",
    "titles.create.style.background-color-paragraph": "Gradient & Background of Title Page",
    "titles.create.style.background-color-placeholder": "HEX Code",
    "titles.create.style.background-color": "Background Color",
    "titles.create.style.background-image-choose": "Choose Image",
    "titles.create.style.background-image-paragraph": "Replaces Key Art, used as Background of Title Page",
    "titles.create.style.background-image-update": "View/Edit Image",
    "titles.create.style.background-image.error.delete-old-image": "Error deleting old Background Image for App Styles",
    "titles.create.style.background-image.error.upload": "Error uploading Background Image for App Styles",
    "titles.create.style.background-image": "Background Image",
    "titles.create.style.background-video-choose": "Choose Video",
    "titles.create.style.background-video-paragraph": "Used as Background of Title Page - Replace Image",
    "titles.create.style.background-video-update": "View/Edit Video",
    "titles.create.style.background-video.error.delete-old-image": "Error deleting old Background Video for App Styles",
    "titles.create.style.background-video.error.upload": "Error uploading Background Video for App Styles",
    "titles.create.style.background-video": "Background Video",
    "titles.create.style.text-color-paragraph": "Default is white (#FFFFFF)",
    "titles.create.style.text-color-placeholder": "FFFFFF",
    "titles.create.style.text-color": "Text Color",
    "titles.create.style.title-treatment-choose": "Choose Title Treatment",
    "titles.create.style.title-treatment-paragraph": "Display if present, falls back to plain text otherwise.",
    "titles.create.style.title-treatment-update": "View/Edit Title Treatment",
    "titles.create.style.title-treatment.error.delete-old-image": "Error deleting old Title Treatment Image for App Styles",
    "titles.create.style.title-treatment.error.upload": "Error uploading Title Treatment Image for App Styles",
    "titles.create.style.title-treatment": "Title Treatment",
    "titles.create.subscriptions-tab-title": "Subscriptions",
    "titles.create.subscriptions.add-subscriber.add": "Add",
    "titles.create.subscriptions.add-subscriber.title": "Add User Subscription",
    "titles.create.subscriptions.add-subscriber": "Add Subscriber",
    "titles.create.subscriptions.list.suggested-by": "Suggested By",
    "titles.create.subscriptions.list.unsubscribe": "Unsubscribe",
    "titles.create.subscriptions.list.user": "User",
    "titles.create.success": "Title created",
    "titles.create.summary-tab-title": "Summary",
    "titles.create.summary.action-type": "Action Type",
    "titles.create.summary.active": "Active",
    "titles.create.summary.additional-info": "Additional Info",
    "titles.create.summary.aka-1": "AKA 1",
    "titles.create.summary.aka-2": "AKA 2",
    "titles.create.summary.alternative-title-1": "Alternative Title 1",
    "titles.create.summary.alternative-title-2": "Alternative Title 2",
    "titles.create.summary.alternative-titles": "Alternative Titles",
    "titles.create.summary.aspect-ratio": "Aspect Ratio",
    "titles.create.summary.audio": "Audio",
    "titles.create.summary.awards": "Awards",
    "titles.create.summary.cascade.save": "Save Title",
    "titles.create.summary.cascade.suggest-line.one": "You have made changes to this {}'s Genres, Languages and/or Themes",
    "titles.create.summary.cascade.suggest-line.two": "Would you like those changes to also affect",
    "titles.create.summary.cascade.title": "SAVE CHANGES",
    "titles.create.summary.copyright-year": "Copyright Year",
    "titles.create.summary.eidr": "EIDR",
    "titles.create.summary.episode-count": "Episode Count",
    "titles.create.summary.expand-collapse.collapse": "Collapse All",
    "titles.create.summary.expand-collapse.expand": "Expand All",
    "titles.create.summary.film-format": "Film Format",
    "titles.create.summary.front-end-comment": "Frontend Comment",
    "titles.create.summary.genres": "Genres",
    "titles.create.summary.hide-synopsis-log-lines.description":"Prevent synopsis and log lines from being visible outside of Brainiac",
    "titles.create.summary.hide-synopsis-log-lines.note":"Note: This will also set the dashboard status of these elements to \"HIDDEN\" until this box is unchecked.",
    "titles.create.summary.hide-synopsis-log-lines": "Hide Synopsis / Log Lines",
    "titles.create.summary.ika-1": "IKA 1",
    "titles.create.summary.ika-2": "IKA 2",
    "titles.create.summary.is-episode-count-final": "Episode Count Is Final",
    "titles.create.summary.languages": "Languages",
    "titles.create.summary.links": "Links",
    "titles.create.summary.log-180-lines.limit": " characters / 180",
    "titles.create.summary.log-180-lines": "Short Log Line",
    "titles.create.summary.log-60-lines.limit": " characters / 60",
    "titles.create.summary.log-60-lines": "Mini Log Line",
    "titles.create.summary.made-for-type": "Made For",
    "titles.create.summary.mastering-comment": "Mastering Comment",
    "titles.create.summary.mastering-info": "Mastering Info",
    "titles.create.summary.movie-color": "Movie Color",
    "titles.create.summary.mpaa-rating": "MPAA Rating",
    "titles.create.summary.mpm": "MPM #",
    "titles.create.summary.non-theatrical-rights-airline-date": "Airline Date",
    "titles.create.summary.non-theatrical-rights": "Non-Theatrical Rights",
    "titles.create.summary.parental-content-rating": "Parental Content Rating",
    "titles.create.summary.pilot": "Pilot",
    "titles.create.summary.pka-1": "PKA 1",
    "titles.create.summary.pka-2": "PKA 2",
    "titles.create.summary.presentation-credits": "Presentation Credits",
    "titles.create.summary.production-and-distribution": "Production & Distribution",
    "titles.create.summary.production-company": "Production Company",
    "titles.create.summary.production-number": "Production #",
    "titles.create.summary.program-legal-line": "Program Legal Line",
    "titles.create.summary.promotional-materials-legal-line": "Promotional Materials Legal Line",
    "titles.create.summary.rating-reason": "Rating Reason",
    "titles.create.summary.reference-id": "Reference ID",
    "titles.create.summary.release.move-to-top": "Move To Top",
    "titles.create.summary.releases": "Releases",
    "titles.create.summary.releases.display-name": "Display Name",
    "titles.create.summary.releases.display-name.legend": "Note: You can only have one display name field and it's the first entry of the releases section",
    "titles.create.summary.releases.release-display-name": "Release Display Name",
    "titles.create.summary.right-expiration": "Right Exp",
    "titles.create.summary.rights-acquired-from": "Rights Acquired From(s)",
    "titles.create.summary.rights": "Rights",
    "titles.create.summary.running-order": "Running Order",
    "titles.create.summary.runtime-count": "Runtime",
    "titles.create.summary.season": "Season",
    "titles.create.summary.short-synopsis": "Short Synopsis",
    "titles.create.summary.silent-movie": "Silent Movie",
    "titles.create.summary.source-media": "Source Media",
    "titles.create.summary.standard": "Standard",
    "titles.create.summary.synopsis-and-log-lines": "Synopsis / Log Lines",
    "titles.create.summary.synopsis-type.airline": "Airline",
    "titles.create.summary.synopsis-type.awards": "Awards",
    "titles.create.summary.synopsis-type.default": "Default",
    "titles.create.summary.synopsis-type.screener": "Screeners",
    "titles.create.summary.synopsis-type.temporary": "Temporary",
    "titles.create.summary.synopsis.modal.confirm": "Add New Synopsis",
    "titles.create.summary.synopsis.modal.label": "Synopsis Type",
    "titles.create.summary.synopsis.modal.title": "Add Synopsis",
    "titles.create.summary.synopsis": "Synopsis",
    "titles.create.summary.territory": "Territory",
    "titles.create.summary.themes": "Themes",
    "titles.create.summary.title-info": "Title Info",
    "titles.create.summary.title-name": "Title Name",
    "titles.create.summary.title-number": "Product # (BRM)",
    "titles.create.summary.title-type": "Title Type",
    "titles.create.talent-tab-title": "Talent",
    "titles.create.talent.actor": "Actor",
    "titles.create.talent.add-talent.add": "Add Talent",
    "titles.create.talent.add-talent.character":  "Character Name/Comment",
    "titles.create.talent.add-talent.edit": "Edit Talent",
    "titles.create.talent.add-talent.error": "There was an error adding talent, please try again",
    "titles.create.talent.add-talent.name": "Name (Credited As)",
    "titles.create.talent.add-talent.suggested.load-error": "There was an error loading suggested talents for this title",
    "titles.create.talent.add-talent.talent-exists-warning.as": "as",
    "titles.create.talent.add-talent.talent-exists-warning": "This person already exists as talent under the field",
    "titles.create.talent.animated-character":"Animated Character",
    "titles.create.talent.author":"Author",
    "titles.create.talent.choreographer":"Choreographer",
    "titles.create.talent.creator":"Created By",
    "titles.create.talent.director": "Director",
    "titles.create.talent.executive-producer":"Executive Producer",
    "titles.create.talent.guest-star":"Guest Star",
    "titles.create.talent.host":"Host",
    "titles.create.talent.list.actions": "Actions",
    "titles.create.talent.list.character-name": "Character Name/Comment",
    "titles.create.talent.list.comment": "Comment",
    "titles.create.talent.list.credited-as": "Credited As",
    "titles.create.talent.list.name": "Name",
    "titles.create.talent.list.remove": "Remove",
    "titles.create.talent.list.thumbnail-tooltip": "Thumbnail",
    "titles.create.talent.misc-crew":"Miscellaneous Crew",
    "titles.create.talent.musician":"Musician",
    "titles.create.talent.producer":"Producer",
    "titles.create.talent.voice-talent":"Voice Talent",
    "titles.create.talent.writer":"Writer",
    "titles.create.title": "Add Title",
    "titles.create.users-tab-title": "Users",
    "titles.deleted-title.load.error": "There was an error loading deleted title, please contact support",
    "titles.deleted-title.modal.body": "You've requested that we reinstate this title and remove it from the delete queue, reactivating this title will add back the catalog known as \"all videos\" but all title relationships with this title prior  to marking it as deleted will not be re-instated",
    "titles.deleted-title.undelete.error": "There was an error with unmarking this title for deletion, please contact support",
    "titles.deleted-title.undelete.success": "Title was marked for undeletion",
    "titles.deleted-title.unmark-title-for-deletion": "Unmark Title for Deletion",
    "titles.deleted-title": "Deleted Title",
    "titles.edit.catalogs-tab-title": "Catalogs",
    "titles.edit.catalogs-tab.add-catalogs.confirm": "Add titles to catalog",
    "titles.edit.catalogs-tab.add-catalogs.suggest-line.one": "You have added: ",
    "titles.edit.catalogs-tab.add-catalogs.suggest-line.two": "Would you like to also add to the catalog:",
    "titles.edit.catalogs-tab.add-catalogs": "ADD TO CATALOG",
    "titles.edit.catalogs-tab.assigned-title": "Assigned Catalogs",
    "titles.edit.catalogs-tab.assigned.actions": "Actions",
    "titles.edit.catalogs-tab.assigned.catalog-type": "Type",
    "titles.edit.catalogs-tab.assigned.status": "Status",
    "titles.edit.catalogs-tab.catalog-settings.add.label": "Existing catalogs will not be removed, but any new catalogs defined here will be added.",
    "titles.edit.catalogs-tab.catalog-settings.add": "Add to Existing",
    "titles.edit.catalogs-tab.catalog-settings.overwrite.label": "All existing catalogs will be cleared and replaced with the selections below.",
    "titles.edit.catalogs-tab.catalog-settings.overwrite": "Update & Overwrite",
    "titles.edit.catalogs-tab.catalog-settings": "Catalog Settings",
    "titles.edit.catalogs-tab.remove-catalogs.confirm": "Remove titles from catalog",
    "titles.edit.catalogs-tab.remove-catalogs.suggest-line.one": "You have removed: ",
    "titles.edit.catalogs-tab.remove-catalogs.suggest-line.two": "Would you like to also remove from the catalog:",
    "titles.edit.catalogs-tab.remove-catalogs": "REMOVE FROM CATALOG",
    "titles.edit.catalogs-tab.unassigned-title": "Unassigned Catalogs",
    "titles.edit.confirm-modal.caption": "This title was recently saved on:",
    "titles.edit.confirm-modal.question": "Are you sure you want to save?",
    "titles.edit.confirm-modal.title": "Confirm save?",
    "titles.edit.dashboard.hide": "Hide Dash",
    "titles.edit.dashboard.show": "Show Dash",
    "titles.edit.error": "There has been an error updating the title",
    "titles.edit.delete.action-required": "Action Required",
    "titles.edit.delete.confirm-delete.body" : "You've requested that we delete this title. This title will be deleted in 30 days, placed in the delete queue, and will have an action history record",
    "titles.edit.delete.confirm-delete.confirm" : "Confirm",
    "titles.edit.delete.confirm-delete.title" : "Please Confirm Deletion",
    "titles.edit.delete.description": "Before deleting a title, you must make it inactive and hit save.",
    "titles.edit.delete.error" : "There was an error marking this title for deletion. Please try again later",
    "titles.edit.delete.label" : "Mark for Deletion",
    "titles.edit.delete.success" : "Title was successfully marked for deletion",
    "titles.edit.delete.title": "Note About Deleting",
    "titles.edit.event-catalogs-tab-title": "Events",
    "titles.edit.event-catalogs.title": "Events Catalogs (Autogenerated)",
    "titles.edit.event-catalogs.warning": "Warning: adding or removing autogenerated catalogs may cause unexpected errors for events.  This should be done only for troubleshooting or in rare edge cases.",
    "titles.edit.export-metadata": "Metadata",
    "titles.edit.local-tab-title": "Local",
    "titles.edit.related-title.load.error": "There was a problem getting the related titles. The page will display empty.",
    "titles.edit.related-title.load.title.error": "There was a problem getting the related title with id {}. The page will display empty.",
    "titles.edit.success": "Title updated",
    "titles.edit.title": "Edit Title",
    "titles.edit.view.in.wbtvd": "WBTVD",
    "titles.delete-queue": "Delete Queue",
    "titles.delete-queue.error": "Unable to load deleted titles.",
    "titles.delete-queue.days-until-deletion": "Days Until Deletion",
    "titles.delete-queue.deletion-date": "Deletion Date",
    "titles.delete-queue.title": "Title",
    "titles.delete-queue.title-type": "Title Type",
    "titles.mass-add.confirmation-message.errors-download":"Download spreadsheet of failed rows",
    "titles.mass-add.confirmation-message.errors-found":"Errors were found.",
    "titles.mass-add.confirmation-message.errors-message":"Some rows were not imported, and are marked in red below. For more information, hover over the flag icons. ",
    "titles.mass-add.confirmation-message.rows-rejected":"row was rejected.",
    "titles.mass-add.confirmation-message.successful":"rows added successfully.",
    "titles.mass-add.confirmation.add-related-title":"Choose Related Title",
    "titles.mass-add.confirmation.title":"Step 3: Confirmation",
    "titles.mass-add.download-file-tab-title":"Download the file",
    "titles.mass-add.download-tab-title":"Download Template",
    "titles.mass-add.fill-tab-title":"Fill in all required fields, marked with *",
    "titles.mass-add.load.error": "There was an error loading the mass add process, please try again",
    "titles.mass-add.placeholder-tab-title":"Steps to start a Mass Add process:",
    "titles.mass-add.process.error": "There was an error processing the mass add file, please try again",
    "titles.mass-add.row-title.action-type":"Action Type",
    "titles.mass-add.row-title.active":"Active/Inactive",
    "titles.mass-add.row-title.aka-1":"AKA1",
    "titles.mass-add.row-title.aka-2":"AKA2",
    "titles.mass-add.row-title.alternative-title-1":"Alternative Title 1",
    "titles.mass-add.row-title.alternative-title-2":"Alternative title 2",
    "titles.mass-add.row-title.aspect-ratio":"Aspect Ratio",
    "titles.mass-add.row-title.audio":"Audio",
    "titles.mass-add.row-title.cast-and-credits":"Cast and Credits",
    "titles.mass-add.row-title.catalogs":"Catalogs",
    "titles.mass-add.row-title.copyright":"Copyright Year",
    "titles.mass-add.row-title.episode-count":"Episode Count",
    "titles.mass-add.row-title.episode-number":"Episode #",
    "titles.mass-add.row-title.genres":"Genres",
    "titles.mass-add.row-title.ika-1":"IKA 1",
    "titles.mass-add.row-title.ika-2":"IKA 2",
    "titles.mass-add.row-title.languages":"Languages",
    "titles.mass-add.row-title.log-180-lines":"Log 180 Lines",
    "titles.mass-add.row-title.log-60-lines":"Log 60 Lines",
    "titles.mass-add.row-title.made-for":"Made For",
    "titles.mass-add.row-title.mastering-comment":"Mastering Comment",
    "titles.mass-add.row-title.mpm":"MPM",
    "titles.mass-add.row-title.parental-content-rating":"Parental Content Rating",
    "titles.mass-add.row-title.pka-1":"PKA 1",
    "titles.mass-add.row-title.pka-2":"PKA 2",
    "titles.mass-add.row-title.presentation-credits":"Presentation Credits",
    "titles.mass-add.row-title.product-brm":"Product # (BRM)",
    "titles.mass-add.row-title.production-companies":"Production Companies",
    "titles.mass-add.row-title.program-legal-line":"Program Legal Line",
    "titles.mass-add.row-title.promotional-materials-legal-line":"Promotional Materials Legal Line",
    "titles.mass-add.row-title.relationship-order":"Relationship order",
    "titles.mass-add.row-title.relationship-type":"Relationship Type",
    "titles.mass-add.row-title.release-company":"Release Company/Network",
    "titles.mass-add.row-title.release-date-type":"(Release) Date Type",
    "titles.mass-add.row-title.release-date":"(Release) Release Date (MM/dd/yyyy)",
    "titles.mass-add.row-title.release-language":"(Release) Language",
    "titles.mass-add.row-title.release-local-title":"(Release) Local Title",
    "titles.mass-add.row-title.release-status":"(Release) Status",
    "titles.mass-add.row-title.release-territory":"(Release) Territory",
    "titles.mass-add.row-title.release-time":"(Release) Air Time",
    "titles.mass-add.row-title.release-type":"(Release) Release Type",
    "titles.mass-add.row-title.runtime":"Runtime",
    "titles.mass-add.row-title.season-number":"Season #",
    "titles.mass-add.row-title.short-synopsis":"Short Synopsis",
    "titles.mass-add.row-title.source-media":"Source Media",
    "titles.mass-add.row-title.standard":"Standard",
    "titles.mass-add.row-title.synopsis":"Synopsis",
    "titles.mass-add.row-title.themes":"Themes",
    "titles.mass-add.row-title.title-id":"Title ID",
    "titles.mass-add.row-title.title":"Title",
    "titles.mass-add.row-title.type":"Type",
    "titles.mass-add.save-tab-title":"Save as \".CSV\"",
    "titles.mass-add.summary-tab-title": "Step 1: Import Spreadsheet",
    "titles.mass-add.title": "Mass Add Titles",
    "titles.mass-add.titles-length": "Titles",
    "titles.mass-add.top-table-add-related.add":"+ Add",
    "titles.mass-add.top-table-add-related.clear":"Clear",
    "titles.mass-add.top-table-add-related.edit":"Edit",
    "titles.mass-add.top-table-add-related.none":"None ",
    "titles.mass-add.top-table-button.undo":"Undo Batch ",
    "titles.mass-add.top-table-header.batch-id":"Batch ID ",
    "titles.mass-add.top-table-header.created-by":"Created By ",
    "titles.mass-add.top-table-header.created-date":"Created Date ",
    "titles.mass-add.top-table-header.related-title":"Related Title ",
    "titles.mass-add.top-table-header.status":"Status ",
    "titles.mass-add.top-table-header.undo":"Undo",
    "titles.mass-add.top-table-header.updated-by":"Updated By ",
    "titles.mass-add.top-table-header.updated-date":"Updated Date ",
    "titles.mass-add.upload-file":"Upload file",
    "titles.mass-add.upload-tab-title":"Upload file",
    "titles.mass-log.summary-tab-title":"Back To Mass Log",
    "titles.mass-update.logs.title": "Mass Add/Update Title Log",
    "titles.mass-update.placeholder-tab-title":"Steps to start a Mass Update process:",
    "titles.mass-update.process.error": "There was an error processing the mass update file, please try again",
    "titles.mass-update.title": "Mass Update Titles",
    "titles.reject-btn":"Reject ALL",
    "titles.table.body.title.parent": "Parent: ",
    "titles.table.header.category-name": "Title Type",
    "titles.table.header.mpm-number": "MPM #",
    "titles.table.header.ro": "RO",
    "titles.table.header.synopsis-usage-type": "Synopsis Type",
    "titles.table.header.title-and-parent-title": "Title and Parent",
    "titles.table.header.title-release-date": "Release Date",
    "titles.title": "Browse Titles",
    "titles.validate-title.summary-tab-title":"Step 2: Validate Titles & Save ",
    "titles.videos.error": "There was an error loading the video, please try again",
    "titles.work-orders.create.last-modified-date":"Last Modified Date",
    "titles.work-orders.create.last-modifier":"Last Modifier",
    "titles.work-orders.create.success": "Work Order created",
    "titles.work-orders.create.summary-tab-title": "Summary",
    "titles.work-orders.create.title": "Add Work Order",
    "titles.work-orders.edit.confirm-delete.body" : "Are you sure?",
    "titles.work-orders.edit.confirm-delete.confirm" : "Delete Work Order",
    "titles.work-orders.edit.confirm-delete.title" : "Please Confirm Deletion",
    "titles.work-orders.edit.remove.error": "Unable to remove Work Order",
    "titles.work-orders.edit.remove.success": "Work Order successfully removed",
    "titles.work-orders.edit.success": "Work Order updated",
    "titles.work-orders.edit.title": "Edit Work Order",
    "titles.work-orders.export.add-titles-dialog.add-metadata": "You have added \"{}\". Would you like to also add title metadata for",
    "titles.work-orders.export.add-titles-dialog.add-selected": "Add Selected",
    "titles.work-orders.export.add-titles-dialog.not-add": "Do Not Add",
    "titles.work-orders.export.add-titles-dialog.title": "Add Title?",
    "titles.work-orders.export.add-titles.add": "Add",
    "titles.work-orders.export.add-titles.error": "Title/s can't be added please try again later",
    "titles.work-orders.export.add-titles": "Add Title",
    "titles.work-orders.export.list": "Export List",
    "titles.work-orders.export.sliding-panel.subtitle": "Please browse or search to find the desired title",
    "titles.work-orders.export.sliding-panel.title": "Browse Titles",
    "titles.work-orders.export.title": "Export Items",
    "titles.work-orders.save.error": "There was an error saving WorkOrder please try again.",
    "titles.work-orders.summary.art-work.text": "Order artwork for titles in work order",
    "titles.work-orders.summary.art-work": "Artwork Needed?",
    "titles.work-orders.summary.client": "Client",
    "titles.work-orders.summary.completed-date": "Completed Date",
    "titles.work-orders.summary.created-date": "Created Date",
    "titles.work-orders.summary.due-date": "Due Date",
    "titles.work-orders.summary.export-fallback-english.off": "Keep missing fields blank",
    "titles.work-orders.summary.export-fallback-english.on": "Substitute English in missing fields",
    "titles.work-orders.summary.export-fallback-english": "If Requested Language Unavailable",
    "titles.work-orders.summary.export-spec": "Export Spec",
    "titles.work-orders.summary.id": "ID#",
    "titles.work-orders.summary.info": "List Info",
    "titles.work-orders.summary.last-modified-date": "Last Modified Date",
    "titles.work-orders.summary.last-modifier": "Last Modifier",
    "titles.work-orders.summary.name": "Name",
    "titles.work-orders.summary.related-user": "Related User",
    "titles.work-orders.summary.status": "Status",

    "user.account-alerts.tooltip.account-locked": "Account Locked: This account is locked",
    "user.account-alerts.tooltip.client-rep-country": "Country: Your client rep is not valid for your country",
    "user.account-alerts.tooltip.country-not-found": "Country Name Invalid: Country name is not valid",
    "user.account-alerts.tooltip.domain-blacklist": "Domain Blacklisted: This email domain is blacklisted",
    "user.account-alerts.tooltip.duplicate-accounts": "Duplicate Account: Another Account With This Name Exists",
    "user.account-alerts.tooltip.duplicate-email": "Duplicate Email: Another account with this email exists",
    "user.account-alerts.tooltip.duplicate-name": "Duplicate Name: Another account with this name exists",
    "user.account-alerts.tooltip.generic-error": "This row contains an error that must be fixed",
    "user.account-alerts.tooltip.invalid-email": "Invalid Email: Email address is not valid",
    "user.account-alerts.tooltip.locked-out": "Locked out: Too many unsuccessful login attempts",
    "user.account-alerts.tooltip.account-wizard-row-valid": "Fields have been validated and user is cleared to be added to Brainiac.",
    "user.account-alerts.tooltip.missing-company": "Missing Company: Company name must be entered before continuing",
    "user.account-alerts.tooltip.missing-country": "Missing Country: Country must be selected before continuing",
    "user.account-alerts.tooltip.missing-email": "Missing Email: Email address must be entered before continuing",
    "user.account-alerts.tooltip.missing-firstname": "Missing First Name: First name must be entered before continuing",
    "user.account-alerts.tooltip.missing-lastname": "Missing Last Name: Last name must be entered before continuing",
    "user.account-alerts.tooltip.multiple-issues": "Multiple Issues: User has multiple alerts that require attention",
    "user.account-alerts.tooltip.request-permission": "Access Request: User is requesting additional access",

    "user.error-reporting.error": "Failed to submit error report",
    "user.error-reporting.success": "An error report has been automatically submitted with additional diagnostic data",
    "user.error-types.cloudflare": "Cloudflare",
    "user.error-types.ip-limitation": "IP Limitation",
    "user.error-types.permission-denied": "Permission Denied",
    "user.import-status.pending.tooltip": "Pending import",
    "user.import-status.success.tooltip": "Imported successfully",
    "user.status.tooltip.active": "User has normal access and has logged in.",
    "user.status.tooltip.approved": "Approved by Client Rep, awaiting Admin response.",
    "user.status.tooltip.denied": "Rejected by Admin and denied access.",
    "user.status.tooltip.expired": "Expired account.",
    "user.status.tooltip.needs-review": "Applicant has not been reviewed.",
    "user.status.tooltip.non-existent": "User does not exist.",
    "user.status.tooltip.pending": "User has not logged in yet.",
    "user.status.tooltip.pre-approved": "Approved by whitelist policy, awaiting Admin response.",
    "user.status.tooltip.pre-validated": "Automatically validated by whitelist policy, awaiting Client Rep response.",
    "user.status.tooltip.rejected": "Rejected by Client Rep, awaiting Admin response.",
    "user.status.tooltip.removed": "Access removed by Admin.",
    "user.status.tooltip.temp-locked": "User temporary locked out due to too many failed login attempts.",
    "user.status.tooltip.temporary": "Temporary user.",
    "user.status.tooltip.unknown": "",
    "user.status.tooltip.validated": "Validated by Admin, awaiting Client Rep response.",
    "user.status.tooltip.banned": "User Banned.",

    "utilities.create-anonymous.active": "Active",
    "utilities.create-anonymous.create-admin-account": "Create Admin Account",
    "utilities.create-anonymous.default-domain-name": "Default Domain Name",
    "utilities.create-anonymous.number-accounts": "Number of Accounts",
    "utilities.create-anonymous.number-packages": "Number of Packages",
    "utilities.create-anonymous.valid-from": "Valid From",
    "utilities.create-anonymous.valid-to": "Valid To",
    "utilities.elastic-reindex.assets.sync": "Elastic Search Assets",
    "utilities.elastic-reindex.talent.sync": "Elastic Search Talent",
    "utilities.elastic-reindex.video-search.sync": "Elastic Search Moments",
    "utilities.elastic-reindex.title-id.sync": "Elastic Search Title ID",
    "utilities.elastic-reindex.title-id.modal.title": "Re-Index Elastic Search By Title ID",
    "utilities.elastic-reindex.title-id.modal.body": "Enter a title ID to begin an Elastic Search Re-Index for only that title",
    "utilities.elastic-reindex.title-id.modal.confirm": "Re-Index Elastic Search",
    "utilities.elastic-reindex.title": "Elastic Search Re-Index",
    "utilities.elastic-reindex.titles.sync": "Elastic Search Titles",
    "utilities.elastic-reindex.users.sync": "Elastic Search Users",
    "utilities.elastic-search.sync.assets.error": "Unable to sync Elastic Search Assets, please try again.",
    "utilities.elastic-search.sync.assets.success": "Assets Elastic Search sync started",
    "utilities.elastic-search.sync.talent.error": "Unable to sync Elastic Search Talent, please try again.",
    "utilities.elastic-search.sync.talent.success": "Talent Elastic Search sync started",
    "utilities.elastic-search.sync.title.error": "Unable to sync Elastic Search for Title ID, please try again.",
    "utilities.elastic-search.sync.title.success": "Title ID Elastic Search sync started",
    "utilities.elastic-search.sync.titles.error": "Unable to sync Elastic Search Titles, please try again.",
    "utilities.elastic-search.sync.titles.success": "Titles Elastic Search sync started",
    "utilities.elastic-search.sync.users.error": "Unable to sync Elastic Search Users, please try again.",
    "utilities.elastic-search.sync.users.success": "Users Elastic Search sync started",
    "utilities.error-reporting.create-cloudflare-error": "Trigger Cloudflare",
    "utilities.error-reporting.create-error": "Report Error",
    "utilities.error-reporting.description": "Use these buttons to test the Brainiac error reporting system",
    "utilities.error-reporting": "Error Reporting",
    "utilities.forensic-video.download-button": "Download Forensic Video Report",
    "utilities.forensic-video.from": "From Date",
    "utilities.forensic-video.title": "Forensic Video Usage Report",
    "utilities.forensic-video.to": "To Date",
    "utilities.forensic-video.usage-group.title": "Usage Group",
    "utilities.generate-anonymous-accounts.save": "Create anonymous accounts",
    "utilities.generate-anonymous-accounts.sync.error": "Unable to generate anonymous accounts, please try again.",
    "utilities.generate-anonymous-accounts.sync.success": "Anonymous accounts created",
    "utilities.generate-anonymous-accounts.title": "Generate anonymous accounts",
    "utilities.hbo-max-image-ingest.button": "Start HBO Max Image Ingest",
    "utilities.hbo-max-image-ingest.description": "Upload the Images you want to ingest to the 'Prod-Image-Ingest' AWS S3 bucket",
    "utilities.hbo-max-image-ingest.error": "Unable to Ingest HBO Max Images",
    "utilities.hbo-max-image-ingest.success": "HBO Max Images Ingested Successfully",
    "utilities.hbo-max-image-ingest.title": "HBO Max Image Ingest",
    "utilities.refresh-image.sync.error": "Unable to sync Refresh Image URL cache, please try again.",
    "utilities.refresh-image.sync.success": "Refresh Image URL cache sync completed",
    "utilities.refresh-image.title": "Refresh Image",
    "utilities.refresh-publishing-list.sync.error": "Unable to sync Refresh Publishing List URL cache, please try again.",
    "utilities.refresh-publishing-list.sync.success": "Refresh Publishing List URL cache sync completed",
    "utilities.refresh-publishing-list.title": "Refresh Publishing List",
    "utilities.refresh-url-cache.title": "Refresh URL cache",
    "utilities.title": "Utilities",

    "warning": "Warning"
}
