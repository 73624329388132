/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {LanguageCodeType} from '@wbdt-sie/brainiac-web-common';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import ReactSelect from 'react-select';

import {TalentAKAActions} from '../../../talent/talent-aka-actions';
import TalentAKAStore from '../../../talent/talent-aka-store';
import {TitleActions} from '../../title-actions';
import {TitleLocalizedActions} from '../../title-localized-actions';

import 'react-table/react-table.css';

class AKADropdown extends React.Component {
    static get propTypes() {
        return {
            currentLanguage: PropTypes.string.isRequired,
            disabled: PropTypes.bool,
            talent: PropTypes.object.isRequired,
            talentRoleType: PropTypes.object.isRequired,
            value: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static getStores() {
        return [TalentAKAStore];
    }

    static calculateState() {
        return {
            akas: TalentAKAStore.getState().get('akas')
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();
    }


    handleAKAChange(index, language, aka) {
        if (language === LanguageCodeType.LANGUAGES_BY_NAME.ENGLISH) {
            TitleActions.updateAKA(
                this.props.talentRoleType,
                aka,
                index
            );

            return;
        }
        TitleLocalizedActions.updateAKA(
            this.props.talentRoleType,
            aka,
            index
        );
    }

    handleSearchAKAMenuOpen(talentId) {
        if (!this.state.akas.get(talentId.toString())) {
            TalentAKAActions.getAKAs(talentId);
        }
    }

    render() {
        let talentId = this.props.talent.original.get('talentId');
        let options = Immutable.List();
        if (this.state.akas.get(talentId.toString())) {
            options = this.state.akas.get(talentId.toString()).filter(a => a.get('languageCode') === this.props.currentLanguage);
        }
        let akaComponent = (
            <ReactSelect
                disabled={this.props.disabled}
                getOptionLabel={a => a.get('fullName') || a.get('displayAKAFullName')}
                getOptionValue={a => a.get('talentId')}
                name="displayAKAFullName"
                onChange={this.handleAKAChange.bind(this, this.props.talent.index, this.props.currentLanguage)}
                onMenuOpen={this.handleSearchAKAMenuOpen.bind(this, talentId)}
                options={options}
                value={this.props.value}
                style={{bordeColor:'#09E', background: '#F0FAFF'}}
            />
        );
        return akaComponent;
    }
}

export default Container.create(AKADropdown);
