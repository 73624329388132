/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

// TODO: use enum instead if it won't break js code
export const SEVERITY: Record<'ALERT' | 'WARNING', Severity> = {
    ALERT: 1,
    WARNING: 2
};

export const ERRORTYPE: Record<'ERROR' | 'WARNING' | 'INFO', ErrorType> = {
    ERROR: 1,
    WARNING: 2,
    INFO: 3
};

export const ValidationApi = {
    GetAccordionValidationState,
    GetValidationState,
    GetValidationError,
    WaitForValidations,
};

/**
 * Get validation state from [model]
 *
 * @param {Immutable Map} model - The model itself
 * @param {array} attr - Input list
 * @param {object} modelValidationRules - Model store Validations array
 *
 * @return {String|undefined} 'error', 'success' or undefined
 */
export function GetAccordionValidationState(model: ImmutableMap<Dictionary<any>>, attr: string[], modelValidationRules: ValidationRules) {
    let isSuccessful = false;
    const hasErrors = attr.some(value => {
        let modelValue = model.get(value);
        if (modelValue && modelValue.toJS) {
            modelValue = modelValue.toJS();
        }

        if (!isSuccessful) {
            if ([undefined, null, '', false].indexOf(modelValue) === -1 &&
                !(Array.isArray(modelValue) && !modelValue.length)) {
                isSuccessful = true;
            }

        }

        const rules = modelValidationRules[value];
        if (rules) {
            // definitely it will be better to call `GetValidationState` without `ValidationApi` object
            // we are using object just for have ability to mock function inside out tests
            return ValidationApi.GetValidationState(modelValue, true, rules.validations);
        }

        return undefined;
    });

    if (hasErrors) {
        return 'error';
    }

    if (isSuccessful) {
        return 'success';
    }

    return;
}

export function GetValidationState(value: string, isDirty: boolean, validations: ValidationDescription[], validatedValue?: string) {
    if (!isDirty || !validations.length) {return;}

    return validations.reduce((r, v) => {
        if (r === 'error') {
            return 'error';
        }

        if (!v.validate(value, validatedValue)) {
            if (v.severity === SEVERITY.ALERT) {
                return 'error';
            }
            return 'warning';
        }
        return r || undefined;
    }, undefined);
}

export function GetValidationError(value: string, isDirty: boolean, validations: ValidationDescription[], validatedValue: string) {
    if (!isDirty || !validations.length) {return undefined;}
    const failingValidation = validations.find(validation => !validation.validate(value, validatedValue));
    let result = undefined;
    if (failingValidation) {
        result = failingValidation.getMessage(value);
    }
    return result;
}

export function WaitForValidations(value: string, isDirty: boolean, validations: ValidationDescription[]) {
    if (!isDirty || !validations.length) {return Promise.resolve();}

    const promises = validations
        .filter(IsPromisedValidation)
        .map((v) => v.revalidate(value));

    return Promise.all(promises);
}

export function IsPromisedValidation(v: ValidationDescription): v is PromisedValidationDescription {
    return v.type === 'promise';
}
