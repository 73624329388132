/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ControlLabel, FormGroup} from 'react-bootstrap';
import ReactSelect from 'react-select';
import Select from 'react-select';

import {FormSection, FormRow, FormItem} from '../../../common/form/form';
import {CompareImmutable} from '../../../common/utils/utils';
import {WithValidations} from '../../../common/validations/validations';
import ClientRepSelect from '../../../lookup/client-rep/client-rep-select';
import {ClientRepGroupActions} from '../../../security/client-rep-group/client-rep-group-actions';
import ClientRepGroupStore from '../../../security/client-rep-group/client-rep-group-store';
import {WorkOrdersConstants} from '../../../titles/work-orders/work-orders-actions';
import UserCompanySelect from '../../../user/company/user-company-select';
import {UserActions, UserConstants} from '../../../user/user-actions';
import UserStore, {UserValidations} from '../../../user/user-store';

const userProps = [
    'name', 'middleName', 'lastName', 'clientRep',
    'company', 'email', 'clientRepGroups', 'companyIsNew',
    'accountStatus', 'accountType', 'applicationDate', 'jobTitle'
];

class UserInformation extends Component {

    static get propTypes() {
        return {
            disabled: PropTypes.bool,
            mode: PropTypes.string.isRequired,
            user: PropTypes.instanceOf(Immutable.Map).isRequired,
            userType: PropTypes.instanceOf(Immutable.Map)
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true,
            userType: Immutable.Map()
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            clientRepGroups: ClientRepGroupStore.getState().get('clientRepGroups'),
            isContactDirty: false,
        };

        this.handleSelectClientRep = this.handleSelectClientRep.bind(this);
        this.handleSelectClientRepGroup = this.handleSelectClientRepGroup.bind(this);
        this.handleSelectCompany = this.handleSelectCompany.bind(this);
        this.getHandleSelect = this.getHandleSelect.bind(this);
        this.updateUserAndValidations = this.updateUserAndValidations.bind(this);
    }

    componentWillMount() {
        this.storeListeners = [
            ClientRepGroupStore.addListener(() => {
                this.setState({
                    clientRepGroups: ClientRepGroupStore.getState().get('clientRepGroups')
                });
                return;
            })
        ];
        return;
    }

    componentDidMount() {
        ClientRepGroupActions.get();
        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!CompareImmutable(nextProps.user, this.props.user, userProps) || nextProps.user !== this.props.user) {
            return true;
        }

        if (nextState.clientRepGroups !== this.state.clientRepGroups ||
            nextState.isContactDirty !== this.state.isContactDirty) {
            return true;
        }

        return false;
    }

    componentWillUnmount() {
        this.storeListeners.forEach(listener => listener.remove());
        return;
    }

    getHandleSelect(attr) {
        return (value) => {
            let valueId;
            if (value) {
                valueId = value.id;
            }
            UserActions.updateUser(attr, valueId);
        };
    }

    handleSelectClientRep(clientRep) {
        if (!this.state.isContactDirty) {
            this.setState({isContactDirty: true});
        }
        clientRep = clientRep || {};
        UserActions.updateUser('contact', clientRep.id);
        UserActions.updateContact(Immutable.fromJS(clientRep));
        return;
    }

    handleSelectClientRepGroup(clientRepGroups) {
        // The second condition covers a special case (bug?) where the react-select
        // returns an empty array instead of the expected null value.+
        // See https://github.com/JedWatson/react-select/pull/773
        if (!clientRepGroups || clientRepGroups.length === 0) {
            UserActions.updateUser('clientRepGroups', Immutable.List());
            return;
        }

        UserActions.updateUser('clientRepGroups', Immutable.fromJS(clientRepGroups));
        return;
    }

    handleSelectCompany(company, isNew) {
        let companyName;
        if (company) {
            companyName = company.name;
        }

        UserActions.updateUser('company', companyName);
        UserActions.updateUser('companyIsNew', isNew);
    }

    updateUserAndValidations(attr, value) {
        UserActions.updateUser(attr, value);

        // This is a bit hacky, we are using the existing account wizard user row validations api to power real time applicant validation
        setTimeout(() => {
            const massAddUserRow = Immutable.fromJS({
                firstName: this.props.user.get('name'),
                lastName: this.props.user.get('lastName'),
                emailaddress: this.props.user.get('email'),
            });

            UserActions.updateUserValidations(massAddUserRow);
        }, 10);
    }

    render() {
        let clientRepGroupValue;
        let company = {};
        let companyValue = this.props.user.get('company');
        if (companyValue) {
            company = {id:companyValue, name:companyValue};
        }
        let companyIsNew = !!this.props.user.get('companyIsNew');

        let contactLabel = <span>{this.context.intl.messages['accounts.create.summary.contact']}&nbsp;<span className="text-red">*</span></span>;
        let contact = this.props.user.get('clientRep');
        let selectedLanguage = WorkOrdersConstants.EXPORT_LANGUAGES.find((item) => {
            return item.id === this.props.user.get('defaultLanguageCode');
        });

        const disabled = this.props.disabled || (this.props.userType.get('id') === UserConstants.USER_TYPES.SECONDARY.id);

        let clientRepGroupsPlaceholder = this.context.intl.messages['common.select-group'];
        let languagePlaceholder = this.context.intl.messages['common.please-select'];
        if (disabled && (this.props.userType.get('id') === UserConstants.USER_TYPES.SECONDARY.id)) {
            clientRepGroupsPlaceholder = '-';
            languagePlaceholder = '-';
        }

        let applicationDate = <div>{this.context.intl.messages['common.na']}</div>;
        let status = this.context.intl.messages['common.na'];
        if (this.props.mode === 'edit') {

            if (this.props.user.get('clientRepGroups').size) {
                // Assume user.clientRepGroups to be an array of 1.
                clientRepGroupValue = this.props.user.get('clientRepGroups').toJS();
            }

            if (this.props.user.get('applicationDate')) {
                applicationDate = <div>{Moment(this.props.user.get('applicationDate')).format(this.context.intl.messages['date-format'])}</div>;
            }

            // Status Value as well as tooltip
            let st = UserStore.getStatus(this.props.user) || {
                description: '',
                icon: '',
                label: ''
            };
            let tip = '';
            if (st.tip) {
                tip = this.context.intl.messages[st.tip];
            }
            status = <span className={ClassNames('label', st.label)} data-toggle="tooltip" title={tip} data-placement="top"><i className={ClassNames(st.icon)}></i>&nbsp;<span className="hidden-xs hidden-sm">{st.description}</span></span>;
        }

        return (
            <FormSection iconClass="fa-regular fa-user" title={this.context.intl.messages['accounts.create.summary.user-information']}>
                <FormRow>
                    <FormItem attr="name"
                        label={this.context.intl.messages['accounts.create.summary.first-name']}
                        disabled={disabled}
                        model={this.props.user}
                        setter={this.updateUserAndValidations}
                        type="name"
                        validations={UserValidations.name.validations}
                    />
                    <FormItem attr="middleName"
                        label={this.context.intl.messages['accounts.create.summary.middle-name']}
                        disabled={disabled}
                        model={this.props.user}
                        setter={UserActions.updateUser}
                        type="name"
                        validations={UserValidations.middleName.validations}
                    />
                    <FormItem attr="lastName"
                        label={this.context.intl.messages['accounts.create.summary.last-name']}
                        disabled={disabled}
                        model={this.props.user}
                        setter={this.updateUserAndValidations}
                        type="name"
                        validations={UserValidations.lastName.validations}
                    />
                </FormRow>
                <FormRow>
                    <FormGroup md={4} validationState={this.getValidationState(companyIsNew, true, UserValidations.companyIsNew.validations)}>
                        <ControlLabel><span>{this.context.intl.messages['accounts.create.summary.company']}&nbsp;<span className="text-red">*</span></span></ControlLabel>
                        <UserCompanySelect
                            allowCreate
                            getOptionLabel={data => data.name}
                            getOptionValue={data => data.id}
                            labelKey={'name'}
                            isClearable={true}
                            disabled={disabled}
                            onChange={this.handleSelectCompany}
                            selected={company}
                        />
                    </FormGroup>
                    <FormGroup md={8}>
                        <FormItem attr="email"
                            label={this.context.intl.messages['accounts.create.summary.email']}
                            disabled={disabled}
                            model={this.props.user}
                            setter={this.updateUserAndValidations}
                            type="email"
                            validations={UserValidations.email.validations}
                        />
                    </FormGroup>
                </FormRow>
                <FormRow>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['accounts.create.summary.client-rep-group']}</ControlLabel>
                        <Select
                            getOptionLabel={data => data.name}
                            getOptionValue={data => data.id}
                            isClearable={true}
                            isDisabled={disabled}
                            isMulti={true}
                            name="clientRepGroups"
                            onChange={this.handleSelectClientRepGroup}
                            options={this.state.clientRepGroups.toJS()}
                            placeholder={clientRepGroupsPlaceholder}
                            value={clientRepGroupValue}
                        />
                    </FormGroup>
                    <FormGroup validationState={this.getValidationState(this.props.user.get('contact'), this.state.isContactDirty, UserValidations.contact.validations)}>
                        <ControlLabel>{contactLabel}</ControlLabel>
                        <ClientRepSelect
                            getOptionLabel={data => data.name}
                            getOptionValue={data => data.id}
                            isClearable={true}
                            disabled={disabled}
                            name={this.context.intl.messages['accounts.create.summary.contact']}
                            onChange={this.handleSelectClientRep}
                            placeholder={this.context.intl.messages['accounts.create.summary.contact-placeholder']}
                            value={contact}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormItem attr="jobTitle"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['accounts.create.summary.user-title']}
                            model={this.props.user}
                            setter={UserActions.updateUser}
                            type="text"
                            validations={UserValidations.jobTitle.validations}
                        />
                    </FormGroup>
                </FormRow>
                <FormRow>
                    <FormGroup md={4}>
                        <ControlLabel>{this.context.intl.messages['accounts.create.summary.preferred-language']}</ControlLabel>
                        <ReactSelect
                            getOptionLabel={data => data.name}
                            getOptionValue={data => data.id}
                            isClearable={true}
                            isMulti={false}
                            isDisabled={disabled}
                            name="preferredLanguage"
                            onChange={this.getHandleSelect('defaultLanguageCode')}
                            options={WorkOrdersConstants.EXPORT_LANGUAGES}
                            placeholder={languagePlaceholder}
                            value={selectedLanguage}
                        />
                    </FormGroup>
                    <FormGroup md={4} />
                    <FormGroup md={2}>
                        <ControlLabel>{this.context.intl.messages['accounts.create.summary.apply-date']}</ControlLabel>
                        {applicationDate}
                    </FormGroup>
                    <FormGroup md={2}>
                        <ControlLabel>{this.context.intl.messages['accounts.create.summary.status']}</ControlLabel>
                        <br/>
                        {status}
                    </FormGroup>
                </FormRow>
            </FormSection>
        );
    }
}

export default WithValidations(UserInformation);
