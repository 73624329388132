/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import ReactTable from 'react-table';

import Config from '../../../config/config.js';
import {TimelineActions} from '../timeline-actions';
import '../timeline.less';

export default class Processes extends React.Component {
    static get propTypes() {
        return {
            id: PropTypes.string.isRequired,
            processes: PropTypes.instanceOf(Immutable.List).isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    componentDidMount() {
        TimelineActions.getProcesses(this.props.id);
    }

    render() {
        let columns = [
            {
                Header: <strong>{this.context.intl.messages['hardac.processes.id']}</strong>,
                minWidth: 50,
                Cell: c => {
                    const processId = c.original.get('id');
                    return (
                        <a target="_blank" href={`/hardac/processes/${processId}`}>{processId}</a>
                    );
                }
            },
            {
                Header: <strong>{this.context.intl.messages['hardac.processes.process-type']}</strong>,
                minWidth: 100,
                Cell: c => {
                    const processType = c.original.get('processType');
                    return (
                        <p>{processType}</p>
                    );
                }
            },
            {
                Header: <strong>{this.context.intl.messages['hardac.processes.event-type']}</strong>,
                minWidth: 120,
                Cell: c => {
                    const eventType = c.original.get('eventType');
                    return (
                        <p>{eventType}</p>
                    );
                }
            },
            {
                Header: <strong>{this.context.intl.messages['hardac.processes.sent-date']}</strong>,
                minWidth: 110,
                Cell: c => {
                    const sentDate = c.original.get('sentDate');
                    return (
                        <div>
                            {Moment(sentDate).tz(Config.DefaultTimezone).format(this.context.intl.messages['datetime-format'])}
                        </div>
                    );
                }
            },
            {
                Header: <strong>{this.context.intl.messages['hardac.processes.process-complete']}</strong>,
                minWidth: 70,
                Cell: c => {
                    const isProcessComplete = c.original.get('processComplete');
                    if (isProcessComplete) {
                        return (
                            <i className="fas fa-check"></i>
                        );
                    }
                    return (
                        <i className="fas fa-ellipsis-h"></i>
                    );
                }
            }
        ];

        return (
            <div className="timeline-processes">
                <ReactTable
                    className="-striped table-bordered responsive timeline-processes-table"
                    columns={columns}
                    data = {this.props.processes}
                    getNoDataProps= {() => {
                        if (this.props.processes.size) {
                            return {style: {display: 'none'}};
                        }
                        return {};
                    }}
                    id="timeline-processes"
                    loading={false}
                    pageSize={this.props.processes.size}
                    showPagination={false}
                    sortable={false}
                    resizable={true}
                />
            </div>
        );
    }
}

