/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, ControlLabel, FormGroup} from 'react-bootstrap';

import BaseSelect from '../../../common/base-select/base-select';
import {FormItem, FormRow} from '../../../common/form/form';
import {WithValidations} from '../../../common/validations/validations';
import CompanySelect from '../../../lookup/company/company-select';
import CompanyStore from '../../../lookup/company/company-store';
import CurrencyStore from '../../../lookup/currency/currency-store';
import LanguageSelect from '../../../lookup/language/language-select';
import LanguageStore from '../../../lookup/language/language-store';
import {HandleSelect, MapToValues} from '../../../lookup/lookup-select-helpers';
import TerritorySelect from '../../../lookup/territory/territory-select';
import TerritoryStore from '../../../lookup/territory/territory-store';
import {TitleActions, TitleConstants} from '../../title-actions';
import TitleStore, {TitleValidations} from '../../title-store';

class Release extends Component {

    static get propTypes() {
        return {
            category: PropTypes.instanceOf(Immutable.Map).isRequired,
            disabled: PropTypes.bool,
            index: PropTypes.number.isRequired,
            release: PropTypes.instanceOf(Immutable.Map).isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true
        };
    }

    static getStores() {
        return [CompanyStore, CurrencyStore, LanguageStore, TerritoryStore];
    }

    static calculateState() {
        return {
            currencies: CurrencyStore.getState().get('items'),
            languages: LanguageStore.getState().get('items'),
            territories: TerritoryStore.getState().get('items')
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            airDayTypeIsDirty: false,
            airTimeIsDirty: false,
            dateTypeIsDirty: false,
            dateStatusTypeIsDirty: false,
            domesticReleaseCompanyIdIsDirty: false,
            languageIdIsDirty: false,
            typeIsDirty: false,
            territoryIdIsDirty: false,
            selectedDomesticReleaseCompany: MapToValues('id', CompanyStore)(props.release.get('domesticReleaseCompanyId')),
            selectedLanguage: MapToValues('id', LanguageStore)(this.props.release.get('languageId')),
            selectedTerritory: MapToValues('id', TerritoryStore)(this.props.release.get('territoryId')),
            selectedAirDay: TitleStore.getAirDayType(this.props.release.get('airDayType')),
            selectedAirTime: TitleStore.getAirTimeType(this.props.release.get('airHour'), this.props.release.get('airMinute')),
            selectedContentType: TitleStore.getContentType(this.props.release.get('releaseContentType')),
            selectedDateType: TitleStore.getDateType(this.props.release.get('dateType')),
            selectedDateStatusType: TitleStore.getDateStatusType(this.props.release.get('dateStatusType')),
            selectedReleaseType: TitleStore.getReleaseType(this.props.release.get('type')),
        }, this.constructor.calculateState());

        this.handleSelect = this.handleSelect.bind(this);
        this.handleMove = this.handleMove.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleSelectAirTime = this.handleSelectAirTime.bind(this);
        this.updateRelease = this.updateRelease.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        /**
         * Only update the state if the release changed.
         */
        if (!nextProps.release.equals(this.props.release)) {
            this.setState({
                selectedDomesticReleaseCompany: MapToValues('id', CompanyStore)(nextProps.release.get('domesticReleaseCompanyId')),
                selectedLanguage: MapToValues('id', LanguageStore)(nextProps.release.get('languageId')),
                selectedTerritory: MapToValues('id', TerritoryStore)(nextProps.release.get('territoryId')),
                selectedAirDay: TitleStore.getAirDayType(nextProps.release.get('airDayType')),
                selectedAirTime: TitleStore.getAirTimeType(nextProps.release.get('airHour'), nextProps.release.get('airMinute')),
                selectedContentType: TitleStore.getContentType(nextProps.release.get('releaseContentType')),
                selectedDateType: TitleStore.getDateType(nextProps.release.get('dateType')),
                selectedDateStatusType: TitleStore.getDateStatusType(nextProps.release.get('dateStatusType')),
                selectedReleaseType: TitleStore.getReleaseType(nextProps.release.get('type')),
            });
        }

        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.release !== this.props.release ||
            nextProps.disabled !== this.props.disabled ||
            nextProps.category !== this.props.category) {
            return true;
        }

        if (nextState.selectedAirDay !== this.state.selectedAirDay ||
            nextState.selectedAirTime !== this.state.selectedAirTime ||
            nextState.selectedContentType !== this.state.selectedContentType ||
            nextState.selectedDateType !== this.state.selectedDateType ||
            nextState.selectedDateStatusType !== this.state.selectedDateStatusType ||
            nextState.selectedDomesticReleaseCompany !== this.state.selectedDomesticReleaseCompany ||
            nextState.selectedLanguage !== this.state.selectedLanguage ||
            nextState.selectedReleaseType !== this.state.selectedReleaseType ||
            nextState.selectedTerritory !== this.state.selectedTerritory ||
            nextState.territories !== this.state.territories ||
            nextState.languages !== this.state.languages ||
            nextState.currencies !== this.state.currencies) {
            return true;
        }

        return false;
    }

    handleSelect(attr, value) {
        let isDirty = attr+'IsDirty';

        if (!this.state[isDirty]) {
            this.setState({
                [isDirty]: true
            });
        }

        HandleSelect(`releases.${this.props.index}.${attr}`, TitleActions.updateTitle, {valueAttr: 'id'})(value);

        // Clear the seasonDisplayName field so API doesn't change dateType to 5 during PUT
        if (attr === 'dateType' && value?.id !== TitleConstants.RELEASE.DATE_TYPES.DISPLAY_NAME.id) {
            this.updateRelease('seasonDisplayName', null);
        }
        return;
    }

    handleMove(newPos) {
        /**
         * Prevent from moving the first release up
         * and the last release down.
         */
        if (newPos === -1 || newPos >= TitleStore.getState().get('title').get('releases').size) {
            return;
        }

        TitleActions.moveElement('releases', 'orderInTitle', this.props.index, newPos);
        return;
    }

    handleRemove() {
        TitleActions.removeElement('releases', this.props.index, 'orderInTitle');
        return;
    }

    /**
     * This is a really special case, because air time is a
     * "constant" that only exists in the client, the server
     * stores the hour and minute for the release, but we offer
     * the user only a set of hours and minutes to pick from.
     */
    handleSelectAirTime(value) {

        if (!this.state.airTimeIsDirty) {
            this.setState({
                airTimeIsDirty: true
            });
        }

        if (value === null) {
            TitleActions.updateTitle(`releases.${this.props.index}.airHour`, 0);
            TitleActions.updateTitle(`releases.${this.props.index}.airMinute`, 0);
            return;
        }

        TitleActions.updateTitle(`releases.${this.props.index}.airHour`, parseInt(value.id.split(':')[0], 10));
        TitleActions.updateTitle(`releases.${this.props.index}.airMinute`, parseInt(value.id.split(':')[1], 10));
        return;
    }

    updateRelease(attr, value) {
        TitleActions.updateTitle(`releases.${this.props.index}.${attr}`, value);
        return;
    }

    render() {
        let noop = () => void 0;
        let disabled = this.props.disabled;

        let airTimeDisabled = this.props.disabled;
        let airTimeValue = this.state.selectedAirTime;
        let dateStatusDisabled = this.props.disabled;

        switch (TitleActions.getCategoryGroup(this.props.category)) {
        case TitleConstants.TITLE_CATEGORY_GROUPS.SERIES:
            airTimeDisabled = true;
            airTimeValue = TitleStore.getAirTimeType(0, 0);
            break;
        case TitleConstants.TITLE_CATEGORY_GROUPS.EPISODE:
            dateStatusDisabled = true;
            break;
        default:
            break;
        }

        let handleMoveUp = noop;
        let handleMoveDown = noop;
        let handleMoveTop = noop;
        if (!disabled) {
            handleMoveUp = this.handleMove.bind(this, this.props.index - 1);
            handleMoveDown = this.handleMove.bind(this, this.props.index + 1);
            handleMoveTop = this.handleMove.bind(this, 0);
        }

        let displayNameRow;
        if (this.state.selectedDateType?.get('id') === TitleConstants.RELEASE.DATE_TYPES.DISPLAY_NAME.id) {
            displayNameRow = <FormRow>
                <FormItem
                    md={6}
                    attr="seasonDisplayName"
                    label={this.context.intl.messages['titles.create.summary.releases.display-name']}
                    disabled={this.props.index !== 0}
                    model={this.props.release}
                    legend={this.context.intl.messages['titles.create.summary.releases.display-name.legend']}
                    setter={this.updateRelease}
                    type="text"
                    validations={TitleValidations.releases.seasonDisplayName.validations}
                />
            </FormRow>;
        }

        return (
            <div className="box form-section">
                <span className={ClassNames('label', 'bg-wb-blue', {'clickable': !disabled})}
                    onClick={handleMoveUp}
                ><i className="fas fa-chevron-up"></i></span>
                <span className="order-index">{this.props.index + 1}</span>
                <span className={ClassNames('label', 'bg-wb-blue', {'clickable': !this.props.disabled})}
                    onClick={handleMoveDown}
                ><i className="fas fa-chevron-down"></i></span>&nbsp;
                <span className={ClassNames('label', 'bg-wb-blue', {'clickable': !this.props.disabled})}
                    onClick={handleMoveTop}
                ><i className="fas fa-arrow-up"></i>&nbsp;{this.context.intl.messages['titles.create.summary.release.move-to-top']}</span>
                <Button
                    bsSize="sm"
                    bsStyle="danger"
                    className="pull-right"
                    disabled={this.props.disabled}
                    onClick={this.handleRemove}
                ><i className="fas fa-trash-alt"></i>&nbsp;{this.context.intl.messages['common.delete']}</Button>
                <hr />
                <FormRow>
                    <FormGroup validationState={this.getValidationState(
                        this.props.release.get('type'),
                        this.state.typeIsDirty,
                        TitleValidations.releases.type.validations
                    )}>
                        <ControlLabel>{this.context.intl.messages['titles.create.release.release-type']}</ControlLabel>
                        <BaseSelect
                            disabled={this.props.disabled}
                            name={this.context.intl.messages['titles.create.release.release-type']}
                            onChange={this.handleSelect.bind(this, 'type')}
                            options="release.releaseTypes"
                            store={TitleStore}
                            value={this.state.selectedReleaseType}
                        />
                    </FormGroup>
                    <FormGroup validationState={this.getValidationState(
                        this.props.release.get('dateStatusType'),
                        this.state.dateStatusTypeIsDirty,
                        TitleValidations.releases.dateStatusType.validations
                    )}>
                        <ControlLabel>{this.context.intl.messages['titles.create.release.date-status']}</ControlLabel>
                        <BaseSelect
                            disabled={this.props.disabled || dateStatusDisabled}
                            name={this.context.intl.messages['titles.create.release.date-status']}
                            onChange={this.handleSelect.bind(this, 'dateStatusType')}
                            options="release.dateStatusTypes"
                            store={TitleStore}
                            value={this.state.selectedDateStatusType}
                        />
                    </FormGroup>
                    <FormGroup validationState={this.getValidationState(
                        this.props.release.get('domesticReleaseCompanyId'),
                        this.state.domesticReleaseCompanyIdIsDirty,
                        TitleValidations.releases.domesticReleaseCompanyId.validations
                    )}>
                        <ControlLabel>{this.context.intl.messages['titles.create.release.domestic-release-company']}</ControlLabel>
                        <CompanySelect
                            getOptionLabel={data => data.name}
                            getOptionValue={data => data.id}
                            isClearable={true}
                            disabled={this.props.disabled}
                            name={this.context.intl.messages['titles.create.release.domestic-release-company']}
                            onChange={this.handleSelect.bind(this, 'domesticReleaseCompanyId')}
                            value={this.state.selectedDomesticReleaseCompany}
                        />
                    </FormGroup>
                </FormRow>
                <FormRow>
                    <FormGroup validationState={this.getValidationState(
                        this.props.release.get('dateType'),
                        this.state.dateTypeIsDirty,
                        TitleValidations.releases.dateType.validations
                    )}>
                        <ControlLabel>{this.context.intl.messages['titles.create.release.date-type']}</ControlLabel>
                        <BaseSelect
                            disabled={this.props.disabled}
                            name={this.context.intl.messages['titles.create.release.date-type']}
                            onChange={this.handleSelect.bind(this, 'dateType')}
                            options="release.dateTypes"
                            store={TitleStore}
                            value={this.state.selectedDateType}
                        />
                    </FormGroup>
                    <FormItem
                        attr="date"
                        disabled={this.props.disabled}
                        label={this.context.intl.messages['titles.create.release.date']}
                        model={this.props.release}
                        setter={this.updateRelease}
                        type="date"
                        validations={TitleValidations.releases.date.validations}
                    />
                    <FormGroup validationState={this.getValidationState(
                        this.props.release.get('territoryId'),
                        this.state.territoryIdIsDirty,
                        TitleValidations.releases.territoryId.validations
                    )}>
                        <ControlLabel>{this.context.intl.messages['titles.create.release.territory']}</ControlLabel>
                        <TerritorySelect
                            disabled={this.props.disabled}
                            name={this.context.intl.messages['titles.create.release.territory']}
                            onChange={this.handleSelect.bind(this, 'territoryId')}
                            value={this.state.selectedTerritory}
                        />
                    </FormGroup>
                </FormRow>
                {displayNameRow}
                <FormRow>
                    <FormGroup validationState={this.getValidationState(
                        this.props.release.get('languageId'),
                        this.state.languageIdIsDirty,
                        TitleValidations.releases.languageId.validations
                    )}>
                        <ControlLabel>{this.context.intl.messages['titles.create.release.language']}</ControlLabel>
                        <LanguageSelect
                            disabled={this.props.disabled}
                            name={this.context.intl.messages['titles.create.release.language']}
                            onChange={this.handleSelect.bind(this, 'languageId')}
                            value={this.state.selectedLanguage}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['titles.create.release.content-type']}</ControlLabel>
                        <BaseSelect
                            disabled={this.props.disabled}
                            name="releaseContentType"
                            onChange={this.handleSelect.bind(this, 'releaseContentType')}
                            options="release.contentTypes"
                            store={TitleStore}
                            value={this.state.selectedContentType}
                        />
                    </FormGroup>
                </FormRow>
                <FormRow>
                    <FormGroup validationState={this.getValidationState(
                        this.props.release.get('airDayType'),
                        this.state.airDayTypeIsDirty,
                        TitleValidations.releases.airDayType.validations
                    )}>
                        <ControlLabel>{this.context.intl.messages['titles.create.release.air-day']}</ControlLabel>
                        <BaseSelect
                            disabled={this.props.disabled}
                            name={this.context.intl.messages['titles.create.release.air-day']}
                            onChange={this.handleSelect.bind(this, 'airDayType')}
                            options="release.airDayTypes"
                            store={TitleStore}
                            value={this.state.selectedAirDay}
                        />
                    </FormGroup>
                    <FormGroup validationState={this.getValidationState(
                        this.props.release.get('airHour'),
                        this.state.airTimeIsDirty,
                        TitleValidations.releases.airHour.validations
                    )}>
                        <ControlLabel>{this.context.intl.messages['titles.create.release.air-time']}</ControlLabel>
                        <BaseSelect
                            disabled={airTimeDisabled}
                            name={this.context.intl.messages['titles.create.release.air-time']}
                            onChange={this.handleSelectAirTime}
                            options="release.airTimeTypes"
                            store={TitleStore}
                            value={airTimeValue}
                        />
                    </FormGroup>
                </FormRow>
            </div>
        );
    }
}

export default WithValidations(Container.create(Release));
