/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import PartnerTable from './table';
import DocumentTitle from '../../common/document-title';
import SearchBox from '../../common/search-box/search-box';
import {Debounce} from '../../common/utils/utils';
import {PartnerActions} from '../../partner/partner-actions';
import PartnerStore from '../../partner/partner-store';
import Preloader from '../../preloader/';
import PreloaderStore from '../../preloader/preloader-store';
import SessionStore from '../../user/session/session-store';

class Browse extends Component {

    static get propTypes() {
        return {
            permissions: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            partners: PartnerStore.getState().get('partners'),
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible'),
            total: PartnerStore.getState().get('total'),
        };
    }

    static getStores() {
        return [PreloaderStore, PartnerStore];
    }

    static getPermissions() {
        return {
            canReadPartners: SessionStore.canUser(SessionStore.PERMISSIONS.SETUP.AUTHORIZED_PARTNERS.READ)
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            searchTerm: '',
        }, this.constructor.calculateState());

        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
    }

    componentDidMount() {
        PartnerActions.get(0, 9999);
        return;
    }

    handleSearchTerm(term) {
        this.setState({
            'searchTerm': term
        });
        return;
    }

    render() {
        let filtered = this.state.partners;
        let total = this.state.total;
        let partnersCount = `(${this.state.total} ${this.context.intl.messages['authorized-partners.title']})`;
        // istanbul ignore next
        if (this.state.searchTerm) {
            filtered = this.state.partners.filter( p => p.get('name', '').toLowerCase().match(this.state.searchTerm.toLowerCase()));
            partnersCount = `(${filtered.size} ${this.context.intl.messages['authorized-partners.title']})`;
            total = filtered.size;
        }

        return (
            <DocumentTitle
                message="document-titles.authorized-partners"
            >
                <div>
                    <Preloader show={this.state.preloaderVisible} fixed loadingDots>
                        <section className="content-header">
                            <h1>
                                <i className="fas fa-list-ul"></i>&nbsp;{this.context.intl.messages['authorized-partners.title']}
                                &nbsp;<small>{partnersCount}</small>
                            </h1>
                        </section>
                        <section className="content">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="container-fluid no-x-padding">
                                        <div className="row">
                                            <div className="col-md-3"></div>
                                            <div className="col-md-6 show-grid">
                                                <SearchBox value={this.state.searchTerm} onChange={this.handleSearchTerm}/>
                                            </div>
                                            <div className="col-md-3"></div>
                                        </div>
                                    </div>
                                    <div className="container-fluid no-x-padding">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="box">
                                                    <div className="box-body">
                                                        <PartnerTable
                                                            displayLinks={this.props.permissions.canReadPartners}
                                                            partners={filtered}
                                                            total={total}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Preloader>
                </div>
            </DocumentTitle>
        );
    }
}

export default Container.create(Browse);
