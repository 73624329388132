/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
*/

import Immutable from 'immutable';
import React, {PropTypes} from 'react';
import {Button, ControlLabel, FormGroup, Modal} from 'react-bootstrap';
import Select from 'react-select';

import {TimelineAssetActions} from '../../../assets/timeline/timeline-asset-actions';
import {TimelineActions} from '../timeline-actions';

import {FormSection} from '~/src/common/form/form';

class SendToOAPModal extends React.Component {
    static get propTypes() {
        return {
            className: PropTypes.string,
            isEditInfo: PropTypes.bool,
            oapShowCodes: PropTypes.instanceOf(Immutable.List),
            onHide: PropTypes.func.isRequired,
            show: PropTypes.bool,
            timeline: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            className: '',
            isEditInfo: false,
            oapShowCodes: Immutable.List(),
            show: false
        };
    }

    constructor(props) {
        super(props);

        this.handleConfirm = this.handleConfirm.bind(this);
        this.handleSetShowcode = this.handleSetShowcode.bind(this);
    }

    handleConfirm() {
        this.props.onHide();
        let sendToOAPMethod = TimelineActions.sendToOAP;
        if (this.props.isEditInfo) {
            sendToOAPMethod = TimelineAssetActions.sendToOAP;
        }
        sendToOAPMethod(this.props.timeline.get('id'), this.props.timeline.get('showCode'));
    }

    handleSetShowcode(e) {
        TimelineActions.updateTimeline('showCode', e.id);
    }

    render() {
        const showCodeOptions = this.props.oapShowCodes.map(c => ({
            id: c.get('showCode'),
            name: `${c.get('showCode')} | ${c.get('showTitle')}`
        }));

        const selectedShowCode = showCodeOptions.find((elem) => elem.id === `${this.props.timeline.get('showCode')}`);

        return <Modal className={this.props.className} onHide={this.props.onHide} show={this.props.show}>
            <Modal.Header className="bg-gray" closeButton>
                <Modal.Title className="text-center">{this.context.intl.messages['hardac.timeline.send-to-oap.title']}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <FormSection>
                    <div className="text-center">
                        <em>{this.context.intl.messages['hardac.timeline.send-to-oap.note']}</em>
                    </div>
                    <hr/>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['hardac.video-inbox.summary.send-to-oap.editable-info.show-code']}<span className="text-red">&nbsp;*</span></ControlLabel>
                        <Select
                            classNamePrefix="dropdown"
                            isDisabled={false}
                            getOptionLabel={data => data.name}
                            getOptionValue={data => data.id}
                            isClearable={false}
                            isMulti={false}
                            isSearchable={true}
                            name="showCode"
                            options={showCodeOptions}
                            placeholder={this.context.intl.messages['common.select.placeholder']}
                            value={selectedShowCode}
                            onChange={this.handleSetShowcode}
                        />
                    </FormGroup>
                </FormSection>
            </Modal.Body>

            <Modal.Footer>
                <Button
                    className="btn btn-default-outline pull-left Ml(5)"
                    onClick={this.props.onHide}
                >
                    <i className="fas fa-ban"/> {this.context.intl.messages['common.cancel']}<i/>
                </Button>
                <Button
                    className="btn btn-success-outline"
                    disabled={!this.props.timeline.get('showCode')}
                    type="submit"
                    onClick={this.handleConfirm}
                >
                    <i className="fas fa-right-to-bracket"/>{this.context.intl.messages['hardac.timeline.send-to-oap.confirm']}
                </Button>
            </Modal.Footer>
        </Modal>;
    }
}

export default SendToOAPModal;
