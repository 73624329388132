/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

/**
 * Package used as reference
 * https://www.npmjs.com/package/resizable-panels-react
 */

import PropTypes from 'prop-types';
import React, {Component} from 'react';

export default class Resizer extends Component {
    static get propTypes() {
        return {
            color: PropTypes.string.isRequired,
            direction: PropTypes.string.isRequired,
            onMouseDown: PropTypes.func.isRequired,
            size: PropTypes.string.isRequired
        };
    }

    getStyle() {
        if (this.props.direction === 'column') {
            return {
                width: '100%',
                height: this.props.size,
                background: this.props.color,
                cursor: 'row-resize'
            };
        }

        return {
            width: this.props.size,
            height: '100%',
            background: this.props.color,
            cursor: 'col-resize'
        };
    }

    render() {
        return <div onMouseDown={this.props.onMouseDown} style={this.getStyle()} />;
    }
}
