/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Col, Row} from 'react-bootstrap';

import IngestRowsTable from './ingest-rows-table';
import {MassAddUpdateActions} from './mass-add-update-actions';
import MassAddUpdateStore from './mass-add-update-store';
import Table from './table';
import {RouterActions} from '../../router/router-actions';

class ValidateAndSave extends React.Component {
    static get propTypes() {
        return {
            params: PropTypes.object.isRequired,
            route: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }
    static calculateState() {
        return {
            massAddProcess: MassAddUpdateStore.getState().get('massAddProcess'),
            relatedTitle: MassAddUpdateStore.getState().get('relatedTitle'),
            rows: MassAddUpdateStore.getState().get('rows')
        };
    }

    static getStores() {
        return [MassAddUpdateStore];
    }

    componentDidMount() {
        MassAddUpdateActions.findById(this.props.params.id);
    }

    approveSelectedRows(status) {
        MassAddUpdateActions.updateRowStatus(
            this.state.rows,
            this.state.massAddProcess,
            status
        );
    }

    handleOpenMassAddLogs() {
        RouterActions.redirect('/titles/mass-update-logs', true);
    }

    render() {
        let titleMode = this.context.intl.messages['titles.mass-add.title'];

        if (this.props.route.mode === 'edit') {
            titleMode = this.context.intl.messages['titles.mass-update.title'];
        }

        const approveButtonDisabled = !this.state.rows.some(c => c.get('__selected'));

        return (
            <div>
                <section className="content-header">
                    <h1><i className="far fa-newspaper"></i>&nbsp;{titleMode}
                        &nbsp;<small>{this.state.rows.size} {this.context.intl.messages['titles.mass-add.titles-length']}</small>
                    </h1>
                </section>
                <div>
                    <div className="content">
                        <Row>
                            <Col xs={12}>
                                <div className="container-fluid no-x-padding">
                                    <Row>
                                        <Col xs={12}>
                                            <div className="box">
                                                <div className="box-body">
                                                    <h3>{this.context.intl.messages['titles.validate-title.summary-tab-title']}
                                                        <a className="btn btn-default pull-right" onClick={this.handleOpenMassAddLogs}>
                                                            <i className="fas fa-reply"></i>{this.context.intl.messages['titles.mass-log.summary-tab-title']}
                                                        </a>
                                                    </h3>
                                                    <hr/>
                                                    <Table
                                                        massAddProcess={this.state.massAddProcess}
                                                        relatedTitle={this.state.relatedTitle}
                                                        step="validateAndSave"/>
                                                    <hr/>
                                                    <p className="text-center">
                                                        {this.context.intl.messages['titles.approve-selected-title']}
                                                    </p>
                                                    <div className="text-center">
                                                        <Button
                                                            bsStyle="success"
                                                            className="btn-lg margin-bottom-20"
                                                            disabled={approveButtonDisabled}
                                                            onClick={this.approveSelectedRows.bind(this, 'Approve selected')}
                                                            id="progress-indicator-button">
                                                            <i className="fas fa-check"></i>&nbsp;{this.context.intl.messages['titles.approve-btn']}
                                                        </Button>&nbsp;
                                                        <a className="btn btn-lg btn-danger margin-bottom-20"
                                                            data-toggle="modal"
                                                            onClick={this.approveSelectedRows.bind(this, 'Reject all')}
                                                            data-target="#modal-reject">
                                                            <i className="fas fa-trash-alt"></i>&nbsp;{this.context.intl.messages['titles.reject-btn']}
                                                        </a>
                                                    </div>
                                                    <IngestRowsTable step="validateAndSave" rows={this.state.rows}/>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default Container.create(ValidateAndSave);
