/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, FormGroup, Modal} from 'react-bootstrap';

import {FormRow, FormSection} from '~/src/common/form/form';

export default class UsersNotFoundModal extends Component {
    static get propTypes() {
        return {
            onHide: PropTypes.func.isRequired,
            show: PropTypes.bool.isRequired,
            userHitsAdded: PropTypes.number.isRequired,
            userTotalHitsMisses: PropTypes.number.isRequired,
            userBatchId: PropTypes.number.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.handleViewBatch = this.handleViewBatch.bind(this);
    }

    handleViewBatch() {
        window.open(`/accounts/account-wizard/${this.props.userBatchId}`, '_blank');
        this.props.onHide();
    }

    render() {
        const totalUsers = this.props.userTotalHitsMisses;
        return (
            <Modal onHide={this.props.onHide} show={this.props.show}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="text-center">
                        {this.context.intl.messages['events.users.select-spreadsheet.not-found.title'].replace('{}', this.props.userHitsAdded).replace('{}', totalUsers)}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <FormSection>
                        <FormRow>
                            <FormGroup className="text-center">
                                <p className="text-red"><i className="fas fa-exclamation-triangle"></i>&nbsp;{this.context.intl.messages['events.users.select-spreadsheet.not-found.alert-caption']}</p>
                                <p>{this.context.intl.messages['events.users.select-spreadsheet.not-found.second-caption']}</p>
                                <Button
                                    className="btn btn-primary-outline bg-wb-blue btn-md"
                                    onClick={this.handleViewBatch}
                                >
                                    {this.context.intl.messages['events.users.select-spreadsheet.not-found.button']}&nbsp;<i className="fas fa-users-medical"></i>
                                </Button>
                            </FormGroup>
                        </FormRow>
                    </FormSection>
                </Modal.Body>

                <Modal.Footer>
                    <Button className="btn btn-default-outline pull-left" onClick={this.props.onHide}>
                        <i className="fas fa-ban"></i>&nbsp;{this.context.intl.messages['common.close']}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
