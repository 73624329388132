/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {MerchandiseConstants} from './merchandise-actions';
import Validations, {SEVERITY} from '../../common/validations/validations';
import Dispatcher from '../../dispatcher/dispatcher';
import {ActionHistoryConstants} from '../../system/action-history/action-history-actions';

/**
 * Look for a constant with id === objectId in a map.
 */
let findInConstant = function(constants, objectId) {
    return Immutable.fromJS(
        Object.keys(constants)
            .map(k => constants[k])
            .filter(obj => objectId === obj.id)[0]
    );
};

const MerchandiseAssetValidations = {
    assetComment: {
        label: 'asset.merchandise.create.assetComment',
        validations: [Validations.max(255)]
    },
    file: {
        validations: [
            Validations.custom(
                () => {
                    return 'Suggested file types: BMP, DASH, GIF, JPEG, MPEGURL, MP4, OGG, PNG, SSTR, SVG, ZIP.';
                },
                () => {
                    let isValid = true;
                    const file = merchandiseStore.getState().getIn(['asset', 'file']);
                    if (file) {
                        file.forEach(asset => {
                            if (!merchandiseStore.hasAllowedMimeType(asset)) {
                                isValid = false;
                            }
                        });
                    }
                    return isValid;
                },
                SEVERITY.WARNING
            ),
            Validations.custom(
                () => {
                    return 'Files without extension are not allowed.';
                },
                () => {
                    let isValid = true;
                    const file = merchandiseStore.getState().getIn(['asset', 'file']);
                    if (file) {
                        file.forEach(asset => {
                            if (!merchandiseStore.hasExtension(asset)) {
                                isValid = false;
                            }
                        });
                    }
                    return isValid;
                },
                SEVERITY.ALERT
            )
        ]
    },
    name: {
        label: 'asset.merchandise.create.name',
        validations: [Validations.max(200), Validations.required]
    },
    price: {
        label: 'asset.merchandise.create.price',
        validations: [Validations.max(50)]
    },
    sku: {
        label: 'asset.merchandise.create.sku',
        validations: [Validations.max(50)]
    }
};


class MerchandiseStore extends ReduceStore {

    // Return an action type by id.
    getContentType(contentTypeId) {
        return findInConstant(MerchandiseConstants.CONTENT_TYPES, contentTypeId);
    }

    getInitialState() {
        let state = Immutable.Map({
            asset: Immutable.Map({
                active: 1,
                contentType: this.getContentType(
                    MerchandiseConstants.CONTENT_TYPES.GENERAL.id
                ),
                files: []
            }),
            contentTypes: Immutable.fromJS(MerchandiseConstants.toArray('CONTENT_TYPES')),
            history: Immutable.List(),
            originalFile: null,
            showPreloader: false
        });
        state = state.set('originalAsset', state.get('asset'));

        return state;
    }

    getValidations() {
        return Validations.validate(this.getState().get('asset'), MerchandiseAssetValidations);
    }

    hasExtension(file) {
        let extension = file.name.match(/\.[0-9a-z]+$/i) || [];
        return extension[0];

    }

    hasAllowedMimeType(file) {
        return file.type.match('application.*|image.*|video.*');
    }

    reduce(state, action) {
        switch (action.actionType) {
        case ActionHistoryConstants.ADD_NOTE.SUCCESS:
            if (action.actionObjectType === ActionHistoryConstants.ACTION_OBJECTS.MERCHANDISE) {
                state = state.updateIn(['history'], history => history.unshift(action.note));
            }
            break;

        case MerchandiseConstants.MERCHANDISE.CLEAR:
            state = this.getInitialState();
            break;

        case MerchandiseConstants.MERCHANDISE.GET.START:
            state = this.getInitialState();
            state = state.merge({
                showPreloader: true
            });
            break;

        case MerchandiseConstants.MERCHANDISE.SAVE.ERROR:
            state = state.merge({
                showPreloader: false
            });
            break;

        case MerchandiseConstants.MERCHANDISE.SAVE.START:
            state = state.merge({
                showPreloader: true
            });
            break;

        case MerchandiseConstants.MERCHANDISE.SAVE.SUCCESS:
            state = state.setIn(['asset', 'id'], action.id);
            state = state.setIn(['asset', 'files'], []);
            state = state.merge({
                originalAsset: state.get('asset'),
                originalFile: action.file,
                showPreloader: false
            });
            break;

        case MerchandiseConstants.MERCHANDISE.GET.SUCCESS:
            state = state.merge({
                asset: action.asset,
                history: action.history,
                originalAsset: action.asset,
                showPreloader: false
            });
            break;

        case MerchandiseConstants.MERCHANDISE.UPDATE:
            state = state.setIn(['asset', ...action.attr.split('.')], action.value);
            break;

        }
        return state;
    }
}

let merchandiseStore = new MerchandiseStore(Dispatcher);

export default merchandiseStore;

export {MerchandiseAssetValidations};
