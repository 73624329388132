/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';

import SettingsModal from './caption-positioning-modal';
import {ApplyChangesToSelectedCues, ResetSelectedCues, IsChangedSelectedCue$, ShowCaptionPositioningModal, VideoDuration$} from '../../bl';
import CCEditorActions from '../../cc-editor-actions';
import NoBrBlock from '../common/nobr-block';
import TimecodeField from '../common/timecode-field';
import ToggleButton from '../common/toggle-button';

import {MODAL_TYPE} from '~/src/common/notification/modal';
import {NotificationActions} from '~/src/common/notification/notification-actions';
import WithRxSubscriptions from '~/src/decorators/with-rx-subscriptions';

type Props = {
    cue: WBTVDCue,
    frameRate?: number,
    isChanged: boolean,
}

type State = {
    isLinkedTime: boolean,
}

export class SingleCueEditor extends React.PureComponent<Props, State> {

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props: Props) {
        super(props);

        this.state = {
            isLinkedTime: true,
        };

        this.cancelEditing = this.cancelEditing.bind(this);
        this.handleEndTimeChanged = this.handleEndTimeChanged.bind(this);
        this.handleSaveRequest = this.handleSaveRequest.bind(this);
        this.handleStartTimeChanged = this.handleStartTimeChanged.bind(this);
        this.removeCue = this.removeCue.bind(this);
        this.showRemovingConfirmation = this.showRemovingConfirmation.bind(this);
        this.toggleLinkedTime = this.toggleLinkedTime.bind(this);
    }

    private applyDeltaToTime(delta: number) {
        this.emitCueChanges(makeDeltaTime(this.props.cue, delta));
    }

    private async cancelEditing() {
        let handler = ResetSelectedCues;
        if (this.props.cue.isNew) {
            handler = this.removeCue;
        }

        if (this.props.isChanged) {
            const confirmText = this.context.intl.messages['common.confirm'];
            const message = this.context.intl.messages['cc-editor.cue.cancel.confirm'];
            NotificationActions.show(MODAL_TYPE.DANGER, confirmText, message, confirmText, handler);
        } else {
            handler();
        }
    }

    private emitCueChanges(cue: Partial<WBTVDCue>) {
        ApplyChangesToSelectedCues(cue);
    }

    private handleEndTimeChanged(time: number) {
        if (this.state.isLinkedTime) {
            this.applyDeltaToTime(time - this.props.cue.endTime);
        } else {
            this.emitCueChanges({endTime: time});
        }
    }

    private handleSaveRequest() {
        CCEditorActions.changeCues([this.props.cue]);
    }

    private handleStartTimeChanged(time: number) {
        if (this.state.isLinkedTime) {
            this.applyDeltaToTime(time - this.props.cue.startTime);
        } else {
            this.emitCueChanges({startTime: time});
        }
    }

    private async removeCue() {
        CCEditorActions.removeCues([this.props.cue]);
    }

    private showRemovingConfirmation() {
        const confirmText = this.context.intl.messages['common.confirm'];
        const message = this.context.intl.messages['cc-editor.cue.remove.confirm'];

        NotificationActions.show(MODAL_TYPE.DANGER, confirmText, message, confirmText, this.removeCue);
    }

    private toggleLinkedTime(isLinkedTime: boolean) {
        this.setState(() => ({isLinkedTime}));
    }

    render() {
        let toggleIconClassName = 'fa-unlink';
        if (this.state.isLinkedTime) {
            toggleIconClassName = 'fa-link';
        }
        const isCorrectPeriod = this.props.cue.endTime > this.props.cue.startTime;
        return (
            <div className="cue-editor form-section">
                <NoBrBlock>
                    <TimecodeField
                        data-testid="start-time"
                        frameRate={this.props.frameRate}
                        label="Start"
                        onInput={this.handleStartTimeChanged}
                        time={this.props.cue.startTime}
                    />
                </NoBrBlock>
                <NoBrBlock>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="link-tooltip">
                                <FormattedMessage id="cc-editor.header.link-tooltip" />
                            </Tooltip>
                        }>
                        <ToggleButton
                            className="btn-sm"
                            data-testid="toggle-time-link-button"
                            isPushed={this.state.isLinkedTime}
                            onChange={this.toggleLinkedTime}
                        >
                            <i className={Classnames('fas', toggleIconClassName)} />
                        </ToggleButton>
                    </OverlayTrigger>
                </NoBrBlock>
                <NoBrBlock>
                    <TimecodeField
                        data-testid="end-time"
                        frameRate={this.props.frameRate}
                        label="End"
                        onInput={this.handleEndTimeChanged}
                        time={this.props.cue.endTime}
                    />
                </NoBrBlock>
                <NoBrBlock>
                    <button
                        className="btn btn-sm btn-default-outline"
                        data-testid="settings-button"
                        onClick={ShowCaptionPositioningModal}
                    >
                        <i className="fas fa-cog"/>
                    </button>
                </NoBrBlock>
                <NoBrBlock>
                    <button
                        className="btn btn-sm btn-success-outline cue-action-btn"
                        data-testid="save-button"
                        disabled={!this.props.isChanged || !isCorrectPeriod}
                        onClick={this.handleSaveRequest}
                    >
                        <i className="fas fa-check"/>
                    </button>
                    <button
                        className="btn btn-sm btn-danger-outline cue-action-btn"
                        data-testid="cancel-button"
                        onClick={this.cancelEditing}
                    >
                        <i className="fas fa-times"/>
                    </button>
                    <button
                        className="btn btn-sm btn-danger-outline cue-action-btn"
                        data-testid="remove-button"
                        onClick={this.showRemovingConfirmation}
                    >
                        <i className="fas fa-trash"/>
                    </button>
                </NoBrBlock>

                <SettingsModal />
            </div>
        );
    }
}

export default WithRxSubscriptions(SingleCueEditor, {
    isChanged: IsChangedSelectedCue$,
});

function normalizeTime(time: number) {
    return Math.round((time * 1000)) / 1000;
}

function makeDeltaTime(cue: WBTVDCue, delta: number): Pick<WBTVDCue, 'endTime' | 'startTime'> {
    const startTime = Math.max(0, normalizeTime(cue.startTime + delta));
    const endTime = normalizeTime(cue.endTime + delta);
    return {startTime, endTime};
}
