/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {HistoryConstants} from './browse-history-actions';
import Dispatcher from '../dispatcher/dispatcher';

class HistoryStore extends ReduceStore {
    getInitialState() {
        return Immutable.Map({
            filters: Immutable.Map(),
            history: Immutable.List(),
            historyItem: Immutable.Map(),
            historyActionItems: Immutable.Map(),
            offset: 0,
            showPreloader: false,
            showHistoryInDetailModal: false,
            size: 20,
            total: 20
        });
    }

    reduce(state, action) {
        switch (action.actionType) {
        case HistoryConstants.HISTORY.GET.START:
            state = state.merge({
                showPreloader: true,
            });
            break;

        case HistoryConstants.HISTORY.GET.SUCCESS:
            state = state.merge({
                history: action.history,
                offset: action.offset,
                showPreloader: false,
                size: action.size,
                total: action.total
            });
            break;

        case HistoryConstants.HISTORY.GET.ERROR:
            state = state.merge({
                showPreloader: false,
            });
            break;

        case HistoryConstants.HISTORY.MODAL_DETAIL.OPEN:
            let historyItemId = parseInt(action.historyItemId);
            let historyList = state.get('history');
            let historyItemFound = historyList.find((item) => {
                return item.get('id') === historyItemId;
            });

            if (historyItemFound) {
                state = state.merge({
                    historyItem: historyItemFound,
                    showHistoryInDetailModal: true
                });
            }
            break;

        case HistoryConstants.HISTORY.MODAL_DETAIL.CLOSE:
            state = state.merge({
                showHistoryInDetailModal: false
            });
            break;

        case HistoryConstants.FILTER.CLEAR:
            state = state.set('filters', Immutable.Map());
            break;

        case HistoryConstants.FILTER.SET:
            state = state.setIn(['filters', ...action.attr.split('.')], action.value);
            break;

        case HistoryConstants.HISTORY.GET_ACTION_OBJECT_ITEM.SUCCESS:
            state = state.setIn(['historyActionItems', action.key], action.historyActionItem);

            state.get('history').forEach((item, index) => {
                let actionObjectFound = HistoryConstants.FILTERS.OBJECT_TYPE_OPTIONS[item.get('actionObjectType')];
                if (actionObjectFound) {
                    let objectCommonType = actionObjectFound.commonActionObject;
                    let actionObject = state.getIn(['historyActionItems', `${item.get('objectId')}-${objectCommonType}`]);
                    if (actionObject) {
                        state = state.mergeIn(['history', index], {
                            actionObjectDescription: actionObject.get('actionObjectDescription'),
                            actionObjectBody: actionObject.get('actionObjectBody')
                        });
                    }
                }
            });
            break;
        }
        return state;
    }
}

let store = new HistoryStore(Dispatcher);

export default store;
