/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Classnames from 'classnames';
import React from 'react';

import {ApplyChangesToSelectedCues} from '../../bl';

type Props = {
    className?: string,
    converter: {
        secondsToTimecode: (seconds: number) => string,
    }
    cue: WBTVDCue,
    mode: 'view' | 'edit',
}

export default class CueItem extends React.Component<Props> {

    constructor(props: Props) {
        super(props);

        this.handleChangeTextEvent = this.handleChangeTextEvent.bind(this);

        this.cueText = props.cue.text;
    }

    shouldComponentUpdate(props: Props) {
        return this.cueText !== props.cue.text || this.props.mode !== props.mode || this.props.className !== props.className;
    }

    private cueText = '';

    private handleChangeTextEvent(ev: React.FormEvent<HTMLDivElement>) {
        if (ev.target instanceof HTMLDivElement) {
            const value = ev.target.innerText || '';
            this.cueText = value;
            ApplyChangesToSelectedCues({...this.props.cue, text: value});
        }
    }

    render() {
        const {className, converter, cue, mode} = this.props;
        const from = converter.secondsToTimecode(cue.startTime);
        const to = converter.secondsToTimecode(cue.endTime);

        return (
            <div className="cue-item-wrapper">
                <div
                    className={Classnames('cue-item', className, mode)}
                    data-cue-id={cue.id}>
                    <div className="cue-item-time">
                        <small>{from}&nbsp;-&nbsp;{to}</small>
                    </div>

                    <div
                        className="cue-item-text"
                        contentEditable={mode === 'edit'}
                        dangerouslySetInnerHTML={{__html: cue.text.split('\n').join('<br/>')}}
                        key={mode}
                        onInput={this.handleChangeTextEvent}
                    />
                </div>
            </div>
        );
    }
}
