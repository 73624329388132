/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {HomepageConstants, HomepageSections} from './homepage-actions';
import Validations from '../common/validations/validations';
import Dispatcher from '../dispatcher/dispatcher';
import {ActionHistoryConstants} from '../system/action-history/action-history-actions';

const HomepageValidations = {
    name: {
        validations: [Validations.required]
    },
    pageType: {
        validations: [Validations.required]
    },
    active: {
        validations: [Validations.required]
    },
    navigationPublishListId: {
        validations: [Validations.required]
    },
    sections: {
        validations: [Validations.custom(
            function() {
                return 'Please complete all lists';
            },
            function(value) {
                let PAGE_TYPE = Object.values(HomepageConstants.PAGE_TYPE).filter(pt => pt.id === store.getState().getIn(['homepage', 'pageType']))[0];
                const additionalSection = value.filter(s => s.get('sectionType') === HomepageSections.DYNAMIC_LIST.id);
                const filledAdditionalSection = additionalSection.filter(s => !s.get('publishingList').equals(Immutable.fromJS({items:[]})));

                return value.filter(s => !!s).size-filledAdditionalSection.size === PAGE_TYPE.CONTENT.length;
            }
        )]
    }
};

class HomepageStore extends ReduceStore {
    getInitialState() {
        let state = Immutable.Map({
            filters: Immutable.Map(),
            homepage: Immutable.Map({
                active: true,
                groups: Immutable.OrderedSet(),
                history: Immutable.List(),
                navigationItems: Immutable.List(),
                pageType: HomepageConstants.PAGE_TYPE.LEGACY.id,
                partners: Immutable.OrderedSet(),
                sections: Immutable.List()
            }),
            homepages: Immutable.List(),
            offset: 0,
            size: 20,
            total: 0,
            users: Immutable.Map({
                filters: Immutable.Map(),
                offset: 0,
                results: Immutable.List(),
                size: 20,
                totalCount: 0
            })
        });

        state = state.set('originalHomepage', state.get('homepage'));

        return state;
    }

    getValidations() {
        return Validations.validate(this.getState().get('homepage'), HomepageValidations);
    }

    reduce(state, action) {
        let sections, filteredSections;

        switch (action.actionType) {
        case ActionHistoryConstants.ADD_NOTE.SUCCESS:
            if (action.actionObjectType === ActionHistoryConstants.ACTION_OBJECTS.HOMEPAGE) {
                state = state.updateIn(['homepage', 'history'], history => history.unshift(action.note));
            }
            break;

        case HomepageConstants.SECTION.ADD:
            sections = state.getIn(['homepage', 'sections']);
            filteredSections = sections.filter(s => s.get('sectionType') === HomepageSections.DYNAMIC_LIST.id);
            state = state.setIn(['homepage', 'sections'], sections.push(Immutable.fromJS({
                sectionType: HomepageSections.DYNAMIC_LIST.id,
                displayOrder: filteredSections.size,
                publishingList: {items: []},
            })));
            break;

        case HomepageConstants.SECTION.REMOVE:
            sections = state.getIn(['homepage', 'sections']);
            state = state.setIn(['homepage', 'sections'], sections.delete(action.sectionIndex));
            break;

        case HomepageConstants.CLEAR:
            state = this.getInitialState();
            break;


        case HomepageConstants.HOMEPAGE.GET.SUCCESS:
            state = state.merge({
                homepage: action.homepage,
                originalHomepage: action.homepage
            });
            break;

        case HomepageConstants.GET.SUCCESS:
            state = state.merge({
                homepages: action.homepages
            });
            break;

        case HomepageConstants.HOMEPAGE.SAVE.SUCCESS:
            state = state.set('originalHomepage', state.get('homepage'));
            break;

        case HomepageConstants.HOMEPAGE.UPDATE:
            state = state.setIn(['homepage', ...action.attr.split('.')], action.value);
            break;

        case HomepageConstants.HOMEPAGE.USERS.GET.SUCCESS:
            state = state.mergeIn(['users'], {
                filters: action.filters,
                offset: action.offset,
                results: action.users,
                size: action.size,
                total: action.total
            });
            break;

        case HomepageConstants.ITEMS.GET.SUCCESS:
            state = state.setIn(['homepage', 'sections', action.sectionIndex, 'publishingList', 'items'], action.items);
            break;

        case HomepageConstants.NAVIGATION_ITEMS.GET.SUCCESS:
            state = state.setIn(['homepage', 'navigationItems'], action.items);
            state = state.setIn(['originalHomepage', 'navigationItems'], action.items);
            break;

        case HomepageConstants.FILTER.CLEAR:
            state = state.set('filters', Immutable.Map());
            break;

        case HomepageConstants.FILTER.SET:
            state = state.setIn(['filters', ...action.attr.split('.')], action.value);
            break;
        }

        return state;
    }
}

let store = new HomepageStore(Dispatcher);

export default store;

export {HomepageValidations};
