/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React from 'react';
import {ProgressBar} from 'react-bootstrap';

import {DownloadActions, DownloadConstants} from './download-actions';
import DownloadStore from './download-store';
import {RouterActions} from '../../router/router-actions';
import DocumentTitle from '../document-title';
import {IsNotTriggerKey} from '../utils/utils';

class Download extends React.Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            params: PropTypes.object.isRequired,
            type: PropTypes.string,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
        };
    }

    static get defaultProps() {
        return {
            showId: false,
            type: 'data-export'
        };
    }

    static calculateState() {
        return {
            downloadExecution: DownloadStore.getState().get('downloadExecution')
        };
    }

    static getStores() {
        return [DownloadStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.handleDownload = this.handleDownload.bind(this);
        this.isDirty = this.isDirty.bind(this);

        return;
    }

    componentDidMount() {
        RouterActions.registerRedirectCheck((location) => {
            return this.isDirty(location);
        });
        DownloadActions.watchDownloadExecution(this.props.params.id, true);
        return;
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.state.downloadExecution.get('status') === DownloadConstants.EXECUTION_STATUS.COMPLETED &&
            this.state.downloadExecution.get('fileName') !== prevState.downloadExecution.get('fileName')
        ) {
            this.handleDownload();
        }

        return;
    }

    componentWillUnmount() {
        DownloadActions.clear();
    }

    isDirty() {
        return (this.state.downloadExecution.get('status') !== DownloadConstants.EXECUTION_STATUS.COMPLETED);
    }

    handleDownload(event) {
        if (event && IsNotTriggerKey(event)) {
            event.preventDefault();

            return;
        }

        DownloadActions.downloadExecutionZip(this.state.downloadExecution.get('id'));
    }

    render() {
        let progress;
        let progressDescription;
        let preparingZip;
        const {downloadExecution} = this.state;

        const percent = Math.min(
            Math.round(downloadExecution.get('processed') * 100 / downloadExecution.get('total')),
            100
        );

        progressDescription = <h3>{this.context.intl.messages['download.progress.text']} {percent}%</h3>;

        if (this.state.downloadExecution.get('preparingZip')) {
            const progressPadding = ' '.repeat(this.state.downloadExecution.get('statusCounter'));
            const progressDots = '.'.repeat(this.state.downloadExecution.get('statusCounter'));

            let prepZipText = '';
            if (!this.state.downloadExecution.get('fileName')) {
                prepZipText = `${progressPadding}${this.context.intl.messages['download.progress.preparing-zip']}${progressDots}`;
            }

            preparingZip = <h4 className="Whs(pre)">{prepZipText}</h4>;

            progressDescription = (
                <div>
                    {progressDescription}
                    {preparingZip}
                </div>
            );
        }

        if (this.state.downloadExecution.get('total')) {
            progress = (
                <div>
                    <ProgressBar striped bsStyle="success" now={this.state.downloadExecution.get('processed') * 100 / this.state.downloadExecution.get('total', 1)} />
                    {progressDescription}
                </div>
            );
        }

        let alternativeDownload;
        if (this.state.downloadExecution.get('fileName') && this.state.downloadExecution.get('status') === DownloadConstants.EXECUTION_STATUS.COMPLETED) {
            alternativeDownload = (
                <p>
                    <em>
                        {this.context.intl.messages['download.alternative-download.text']}
                        <a
                            className="auth-link"
                            onClick={this.handleDownload}
                            onKeyUp={this.handleDownload}
                            role="button"
                            tabIndex="0"
                        >
                            {this.context.intl.messages['download.alternative-download.click']}
                        </a>.
                    </em>
                </p>
            );
        }

        const goBackToHome = (
            <p>{this.context.intl.messages['download.return.home.page.phrase.1']}&nbsp;<a href="/">{this.context.intl.messages['download.return.home.page.phrase.2']}&nbsp;</a>{this.context.intl.messages['download.return.home.page.phrase.3']}</p>
        );

        let pageTitle = `download.title.${this.props.type}`;
        if (this.props.location.query && this.props.location.query.type) {
            pageTitle = `download.title.${this.props.location.query.type}`;
        }

        return (
            <DocumentTitle message="document-titles.common-download">
                <div>
                    <div className="title-header">
                        <div className="container">
                            <div className="title-header-text text-center"><h1><strong>{this.context.intl.messages[pageTitle]}</strong></h1></div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3"></div>
                            <div className="col-md-6">
                                <div className="text-center">
                                    <img
                                        alt={this.context.intl.messages['download.preparing-files.text']}
                                        className="img-responsive"
                                        src={require('./download-animation.gif')}
                                    />
                                </div>
                                <div className="text-center">
                                    <h2 className="margin-top-0">{this.context.intl.messages['download.preparing-files.title']}</h2>
                                    <p>{this.context.intl.messages['download.preparing-files.text']}</p>
                                </div>
                                <hr className="margin-y-40"/>
                                <div className="text-center" style={{'minHeight': '96px'}}>
                                    {progress}
                                    {alternativeDownload}
                                    {goBackToHome}
                                </div>
                            </div>
                            <div className="col-md-3"></div>
                        </div>
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}

export {Download};

export default Container.create(Download);
