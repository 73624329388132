/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {LanguageCodeType} from '@wbdt-sie/brainiac-web-common';
import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, ControlLabel, FormControl, FormGroup} from 'react-bootstrap';

import BaseSelect from '../../../common/base-select/base-select';
import {FormItem, FormRow, FormSection, LocalizedFormItem} from '../../../common/form/form';
import {CompareImmutable} from '../../../common/utils/utils';
import {WithValidations} from '../../../common/validations/validations';
import config from '../../../config/config.js';
import {HandleSelect} from '../../../lookup/lookup-select-helpers';
import {TitleActions, TitleConstants} from '../../title-actions';
import {TitleLocalizedActions} from '../../title-localized-actions';
import TitleStore, {TitleValidations} from '../../title-store';

/**
 * Title props to watch.
 */
let titleProps = [
    'actionType', 'active', 'category', 'copyrightYear', 'eidr', 'filmFormat',
    'madeForType', 'movieColor', 'mpmNumber', 'name', 'pilot', 'silentMovie',
    'brmProductNumber', 'referenceId',

    // Fields that apply only to some title types.
    'isEpisodeCountFinal', 'season', 'episodeCount'
];

class TitleInfo extends Component {

    static get propTypes() {
        return {
            currentLanguage: PropTypes.string,
            disabled: PropTypes.bool,
            disableExpandButton: PropTypes.bool,
            expanded: PropTypes.bool,
            mode: PropTypes.oneOf(['create', 'edit']),
            onToggleExpand: PropTypes.func,
            title: PropTypes.instanceOf(Immutable.Map).isRequired,
            titleLocalized: PropTypes.instanceOf(Immutable.Map)
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            currentLanguage: undefined,
            disabled: true,
            disableExpandButton: false,
            expanded: false,
            mode: undefined,
            onToggleExpand: void 0,
            titleLocalized: undefined
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            isActionTypeDirty: false,
            isFilmFormatTypeDirty: false,
            isMadeForTypeDirty: false,
            isMovieColorDirty: false,
            selectedActionType: TitleStore.getActionType(this.props.title.get('actionType')) || Immutable.Map(),
            selectedFilmFormatType: TitleStore.getFilmFormatType(this.props.title.get('filmFormat')) || Immutable.Map(),
            selectedMadeForType: TitleStore.getMadeForType(this.props.title.get('madeForType')) || Immutable.Map(),
            selectedMovieColor: TitleStore.getMovieColorType(this.props.title.get('movieColor')) || Immutable.Map()
        };

        this.handleSelectActionType = this.handleSelectActionType.bind(this);
        this.handleSelectFilmFormatType = this.handleSelectFilmFormatType.bind(this);
        this.handleSelectMadeForType = this.handleSelectMadeForType.bind(this);
        this.handleSelectMovieColor = this.handleSelectMovieColor.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        // The title only contains the ids, so we use the store to
        // get the full object with id and name.
        this.setState({
            selectedActionType: TitleStore.getActionType(nextProps.title.get('actionType')) || Immutable.Map(),
            selectedFilmFormatType: TitleStore.getFilmFormatType(nextProps.title.get('filmFormat')) || Immutable.Map(),
            selectedMadeForType: TitleStore.getMadeForType(nextProps.title.get('madeForType')) || Immutable.Map(),
            selectedMovieColor: TitleStore.getMovieColorType(nextProps.title.get('movieColor')) || Immutable.Map()
        });
        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.disabled !== this.props.disabled ||
            nextProps.titleLocalized !== this.props.titleLocalized || // actually have to check if name or selected language has changed
            nextProps.currentLanguage !== this.props.currentLanguage ||
            nextProps.expanded !== this.props.expanded ||
            !CompareImmutable(nextProps.title, this.props.title, titleProps)) {
            return true;
        }

        if (nextState.isActionTypeDirty !== this.state.isActionTypeDirty ||
            nextState.isFilmFormatTypeDirty !== this.state.isFilmFormatTypeDirty ||
            nextState.isMadeForTypeDirty !== this.state.isMadeForTypeDirty ||
            nextState.isMovieColorDirty !== this.state.isMovieColorDirty ||
            !nextState.selectedActionType.equals(this.state.selectedActionType) ||
            !nextState.selectedFilmFormatType.equals(this.state.selectedFilmFormatType) ||
            !nextState.selectedMadeForType.equals(this.state.selectedMadeForType) ||
            !nextState.selectedMovieColor.equals(this.state.selectedMovieColor)) {
            return true;
        }

        return false;
    }

    handleSelectActionType(value) {
        if (!this.state.isActionTypeDirty) {
            this.setState({isActionTypeDirty: true});
        }

        HandleSelect('actionType', TitleActions.updateTitle, {valueAttr: 'id'})(value);
        return;
    }

    handleSelectFilmFormatType(value) {
        if (!this.state.isFilmFormatTypeDirty) {
            this.setState({isFilmFormatTypeDirty: true});
        }

        HandleSelect('filmFormat', TitleActions.updateTitle, {valueAttr: 'id'})(value);
        return;
    }
    handleSelectMadeForType(value) {
        if (!this.state.isMadeForTypeDirty) {
            this.setState({isMadeForTypeDirty: true});
        }

        HandleSelect('madeForType', TitleActions.updateTitle, {valueAttr: 'id'})(value);
        return;
    }
    handleSelectMovieColor(value) {
        if (!this.state.isMovieColorDirty) {
            this.setState({isMovieColorDirty: true});
        }

        HandleSelect('movieColor', TitleActions.updateTitle, {valueAttr: 'id'})(value);
        return;
    }
    render() {
        let localized = this.props.currentLanguage !== LanguageCodeType.DEFAULT_LANGUAGE;
        let disabledOnLocalized = this.props.disabled || localized;
        let updatedBy = this.context.intl.messages['common.na'];
        let updatedDate = this.context.intl.messages['common.na'];

        if (this.props.mode === 'edit') {
            if (this.props.title.get('updatedDate')) {
                updatedDate = Moment(this.props.title.get('updatedDate')).tz(config.DefaultTimezone).format(this.context.intl.messages['datetime-format']);
            }
            updatedBy = this.props.title.get('updatedBy');
        }

        let expandAllButton = <span />;
        if (!this.props.disableExpandButton) {
            expandAllButton = <div><i className="fas fa-plus"></i>&nbsp;{this.context.intl.messages['titles.create.summary.expand-collapse.expand']}</div>;
            if (this.props.expanded) {
                expandAllButton = <div><i className="fas fa-minus"></i>&nbsp;{this.context.intl.messages['titles.create.summary.expand-collapse.collapse']}</div>;
            }

            expandAllButton = <Button className="text-center bg-navy" onClick={this.props.onToggleExpand}>{expandAllButton}</Button>;
        }

        /**
         * Show some extra fields depending on the title.category value.
         */
        let typeFields;
        let episodeFields = [
            <FormItem
                attr="episodeCount"
                label={this.context.intl.messages['titles.create.summary.episode-count']}
                disabled={disabledOnLocalized || TitleStore.getCategory(this.props.title).categoryGroup === TitleConstants.TITLE_CATEGORY_GROUPS.SERIES}
                key={0}
                model={this.props.title}
                setter={TitleActions.updateTitle}
                type="text"
            />
        ];

        switch (this.props.title.get('category')) {
        case TitleConstants.TITLE_TYPES.ANIMATED_SERIES_SEASON.id:
        case TitleConstants.TITLE_TYPES.CARTOONS_SEASON.id:
        case TitleConstants.TITLE_TYPES.DOCUSERIES_SEASON.id:
        case TitleConstants.TITLE_TYPES.GAME_SHOW_SEASON.id:
        case TitleConstants.TITLE_TYPES.LIMITED_SERIES_SEASON.id:
        case TitleConstants.TITLE_TYPES.REALITY_SEASON.id:
        case TitleConstants.TITLE_TYPES.SEASON_HALF_HOUR.id:
        case TitleConstants.TITLE_TYPES.SEASON_ONE_HOUR.id:
        case TitleConstants.TITLE_TYPES.SHORT_PROGRAMS_SEASON.id:
        case TitleConstants.TITLE_TYPES.TALK_SHOW_SEASON.id:
            typeFields = <div>
                <hr/>
                <FormRow>
                    <FormItem
                        attr="season"
                        label={this.context.intl.messages['titles.create.summary.season']}
                        disabled={disabledOnLocalized}
                        model={this.props.title}
                        validations={TitleValidations.season.validations}
                        setter={TitleActions.updateTitle}
                        type="text"
                    />
                    {episodeFields}
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['titles.create.summary.is-episode-count-final']}</ControlLabel>
                        <FormItem
                            attr="isEpisodeCountFinal"
                            disabled={disabledOnLocalized}
                            label=""
                            model={this.props.title}
                            setter={TitleActions.updateTitle}
                            type="checkbox"
                        />
                    </FormGroup>
                </FormRow>
            </div>;
            break;

        case TitleConstants.TITLE_TYPES.EPISODE.id:
            typeFields = <div>
                <hr/>
                <FormRow>
                    <FormItem
                        attr="runningOrder"
                        label={this.context.intl.messages['titles.create.summary.running-order']}
                        disabled={disabledOnLocalized}
                        model={this.props.title}
                        setter={TitleActions.updateTitle}
                        type="text"
                        validations={TitleValidations.runningOrder.validations}
                    />
                </FormRow>
            </div>;
            break;

        case TitleConstants.TITLE_TYPES.ANIMATED_SERIES.id:
        case TitleConstants.TITLE_TYPES.CARTOONS.id:
        case TitleConstants.TITLE_TYPES.DOCUSERIES.id:
        case TitleConstants.TITLE_TYPES.GAME_SHOW.id:
        case TitleConstants.TITLE_TYPES.LIMITED_SERIES.id:
        case TitleConstants.TITLE_TYPES.REALITY.id:
        case TitleConstants.TITLE_TYPES.SERIES_HALF_HOUR.id:
        case TitleConstants.TITLE_TYPES.SERIES_ONE_HOUR.id:
        case TitleConstants.TITLE_TYPES.SHORT_PROGRAMS.id:
        case TitleConstants.TITLE_TYPES.TALK_SHOW.id:
        case TitleConstants.TITLE_TYPES.MINI_SERIES.id:
            typeFields = <div>
                <hr/>
                <FormRow>
                    {episodeFields}
                </FormRow>
            </div>;
            break;
        }

        return (
            <FormSection iconClass="fas fa-info-circle" title={this.context.intl.messages['titles.create.summary.title-info']}>
                <FormRow>
                    <LocalizedFormItem
                        attr="name"
                        label={this.context.intl.messages['titles.create.summary.title-name']}
                        disabled={this.props.disabled}
                        model={this.props.title}
                        validations={TitleValidations.name.validations}
                        setter={TitleActions.updateTitle}
                        type="text"
                        localized={this.props.currentLanguage !== LanguageCodeType.DEFAULT_LANGUAGE}
                        localizedModel={this.props.titleLocalized}
                        localizedSetter={TitleLocalizedActions.update}
                    />
                </FormRow>
                <FormRow>
                    <FormGroup validationState={this.getValidationState(
                        this.props.title.get('madeForType'),
                        this.state.isMadeForTypeDirty,
                        TitleValidations.madeForType.validations
                    )}>
                        <ControlLabel>{this.context.intl.messages['titles.create.summary.made-for-type']}&nbsp;<span className="text-red">*</span></ControlLabel>
                        <BaseSelect
                            disabled={disabledOnLocalized}
                            name={this.context.intl.messages['titles.create.summary.made-for-type']}
                            onChange={this.handleSelectMadeForType}
                            options="madeForTypes"
                            store={TitleStore}
                            value={this.state.selectedMadeForType}
                        />
                    </FormGroup>
                    <FormGroup validationState={this.getValidationState(
                        this.props.title.get('actionType'),
                        this.state.isActionTypeDirty,
                        TitleValidations.actionType.validations
                    )}>
                        <ControlLabel>{this.context.intl.messages['titles.create.summary.action-type']}&nbsp;<span className="text-red">*</span></ControlLabel>
                        <BaseSelect
                            disabled={disabledOnLocalized}
                            name={this.context.intl.messages['titles.create.summary.action-type']}
                            onChange={this.handleSelectActionType}
                            options="actionTypes"
                            store={TitleStore}
                            value={this.state.selectedActionType}
                        />
                    </FormGroup>
                </FormRow>
                <FormRow>
                    <FormItem
                        attr="brmProductNumber"
                        label={this.context.intl.messages['titles.create.summary.title-number']}
                        disabled={disabledOnLocalized}
                        max={50}
                        model={this.props.title}
                        setter={TitleActions.updateTitle}
                        type="text"
                    />
                    <FormItem
                        attr="mpmNumber"
                        label={this.context.intl.messages['titles.create.summary.mpm']}
                        disabled={disabledOnLocalized}
                        max={50}
                        model={this.props.title}
                        setter={TitleActions.updateTitle}
                        type="text"
                        validatedSetter={TitleActions.updateValidated}
                        validations={TitleValidations.mpmNumber.validations}
                    />
                </FormRow>
                <FormRow>
                    <FormItem
                        attr="productionNumber"
                        label={this.context.intl.messages['titles.create.summary.production-number']}
                        disabled={disabledOnLocalized}
                        max={50}
                        md={3}
                        model={this.props.title}
                        setter={TitleActions.updateTitle}
                        type="text"
                    />
                    <FormItem
                        attr="referenceId"
                        label={this.context.intl.messages['titles.create.summary.reference-id']}
                        disabled={disabledOnLocalized}
                        max={50}
                        md={3}
                        model={this.props.title}
                        setter={TitleActions.updateTitle}
                        type="text"
                        validatedSetter={TitleActions.updateValidated}
                        validations={TitleValidations.referenceId.validations}
                    />
                    <FormItem
                        attr="eidr"
                        label={this.context.intl.messages['titles.create.summary.eidr']}
                        disabled={disabledOnLocalized}
                        max={40}
                        model={this.props.title}
                        setter={TitleActions.updateTitle}
                        type="text"
                    />
                </FormRow>
                <FormRow>
                    <FormItem
                        attr="copyrightYear"
                        label={this.context.intl.messages['titles.create.summary.copyright-year']}
                        disabled={disabledOnLocalized}
                        max={50}
                        model={this.props.title}
                        validations={TitleValidations.copyrightYear.validations}
                        setter={TitleActions.updateTitle}
                        type="text"
                    />
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['common.status']}</ControlLabel>
                        <FormItem
                            attr="active"
                            label={this.context.intl.messages['titles.create.summary.active']}
                            disabled={disabledOnLocalized}
                            model={this.props.title}
                            setter={TitleActions.updateTitle}
                            type="checkbox"
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['titles.create.summary.pilot']}</ControlLabel>
                        <FormItem
                            attr="pilot"
                            label={this.context.intl.messages['titles.create.summary.pilot']}
                            disabled={disabledOnLocalized}
                            model={this.props.title}
                            setter={TitleActions.updateTitle}
                            type="checkbox"
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['titles.create.summary.silent-movie']}</ControlLabel>
                        <FormItem
                            attr="silentMovie"
                            label={this.context.intl.messages['titles.create.summary.silent-movie']}
                            disabled={disabledOnLocalized}
                            model={this.props.title}
                            setter={TitleActions.updateTitle}
                            type="checkbox"
                        />
                    </FormGroup>
                </FormRow>
                <FormRow>
                    <FormGroup validationState={this.getValidationState(
                        this.props.title.get('movieColor'),
                        this.state.isMovieColorDirty,
                        TitleValidations.movieColor.validations
                    )}>
                        <ControlLabel>{this.context.intl.messages['titles.create.summary.movie-color']}</ControlLabel>
                        <BaseSelect
                            disabled={disabledOnLocalized}
                            name={this.context.intl.messages['titles.create.summary.movie-color']}
                            onChange={this.handleSelectMovieColor}
                            options="movieColors"
                            store={TitleStore}
                            value={this.state.selectedMovieColor}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['titles.create.summary.film-format']}</ControlLabel>
                        <BaseSelect
                            disabled={disabledOnLocalized}
                            name={this.context.intl.messages['titles.create.summary.film-format']}
                            onChange={this.handleSelectFilmFormatType}
                            options="filmFormatTypes"
                            store={TitleStore}
                            value={this.state.selectedFilmFormatType}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormItem
                            attr="runTime"
                            label={this.context.intl.messages['titles.create.summary.runtime-count']}
                            disabled={disabledOnLocalized}
                            model={this.props.title}
                            setter={TitleActions.updateTitle}
                            type="number"
                        />
                    </FormGroup>
                </FormRow>
                {typeFields}
                <div>
                    <hr/>
                    <FormRow>
                        <FormGroup md={5}>
                            <ControlLabel>{this.context.intl.messages['common.last-modified-date']}</ControlLabel>
                            <FormControl.Static>{updatedDate}</FormControl.Static>
                        </FormGroup>
                        <FormGroup md={5}>
                            <ControlLabel>{this.context.intl.messages['common.last-modifier']}</ControlLabel>
                            <FormControl.Static>{updatedBy}</FormControl.Static>
                        </FormGroup>
                        {expandAllButton}
                    </FormRow>
                </div>
            </FormSection>
        );
    }
}

export default WithValidations(TitleInfo);
