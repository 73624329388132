/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
*/

import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Tooltip, OverlayTrigger} from 'react-bootstrap';
import {Link} from 'react-router';
import ReactTable from 'react-table';

import {GetFormattedDateHourForInput} from '../../common/utils/utils';
import EventStore from '../event-store';

import Pagination from '~/src/common/table/pagination';

import 'react-table/react-table.css';

export default class Table extends Component {
    static get propTypes() {
        return {
            activePage: PropTypes.number.isRequired,
            displayLinks: PropTypes.bool,
            events: PropTypes.instanceOf(Immutable.List).isRequired,
            linkTarget: PropTypes.string,
            onPageChange: PropTypes.func.isRequired,
            showEventTypeColumn: PropTypes.bool,
            totalPages: PropTypes.number.isRequired,
        };
    }

    static get defaultProps() {
        return {
            displayLinks: false,
            linkTarget: '_self',
            showEventTypeColumn: true,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
            router: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            id: Math.ceil(Math.random() * 100000),
            sortBy: '',
            sortDir: '',
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.getSortByClass = this.getSortByClass.bind(this);
        this.handleSort = this.handleSort.bind(this);
    }

    getSortByClass(column) {
        let r = 'sorting';
        if (column === this.state.sortBy) {
            r = `sorting_${this.state.sortDir}`;
        }
        return r;
    }

    handleSort(column) {
        let newSortDir = 'asc';
        if (this.state.sortBy === column && this.state.sortDir === 'asc') {
            newSortDir = 'desc';
        }
        this.setState({
            sortBy: column,
            sortDir: newSortDir
        });
    }


    render() {
        const getSortClass = this.getSortByClass;
        const handleSort = this.handleSort;

        let columns = [{
            id: 'name',
            minWidth: 150,
            accessor: c => c.get('name'),
            Header: <strong className={getSortClass('name')}>{this.context.intl.messages['events.browse.name-description']}</strong>,
            Cell: c => {
                let eventName = c.original.get('name');
                if (this.props.displayLinks) {
                    eventName = <Link target={this.props.linkTarget} to={`/events/${c.original.get('eventId')}`}>{eventName}</Link>;
                }
                const description = c.original.get('description');
                if (description) {
                    eventName = (
                        <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-event-description-${c.original.get('eventId')}`} positionTop={0}>
                            <span>{description}</span>
                        </Tooltip>}>
                            <span>{eventName}</span>
                        </OverlayTrigger>
                    );
                }
                const createdBy = c.original.get('createdBy') || this.context.intl.messages['common.na'];
                return (
                    <div>
                        {eventName}<br />
                        <span style={{fontSize: 12}}>{this.context.intl.messages['events.browse.created-by']}: {createdBy}</span>
                    </div>
                );
            }
        }, {
            id: 'startScheduleDate',
            accessor: c => c.get('startScheduleDate'),
            Header: <strong className={getSortClass('dates')}>{this.context.intl.messages['events.browse.start-date']}</strong>,
            Cell: c => {
                const startDate = Moment(c.original.get('startScheduleDate'));
                let startDateFormatted = this.context.intl.messages['common.na'];
                if (startDate.isValid()) {
                    startDateFormatted = GetFormattedDateHourForInput(
                        startDate,
                        c.original.get('displayTimeZone'),
                        this.context.intl.messages['datetime-format-0']
                    );
                }
                const timeZone = c.original.get('displayTimeZone') || this.context.intl.messages['common.na'];
                const displayTimeZone = <i className="fas fa-globe" data-toggle="tooltip" title={timeZone} data-original-title={timeZone} />;
                return (
                    <div>
                        {startDateFormatted}&nbsp;{displayTimeZone}
                    </div>
                );
            }
        }, {
            id: 'endScheduleDate',
            accessor: c => c.get('endScheduleDate'),
            Header: <strong className={getSortClass('dates')}>{this.context.intl.messages['events.browse.end-date']}</strong>,
            Cell: c => {
                const endDate = Moment(c.original.get('endScheduleDate'));
                let endDateFormatted = this.context.intl.messages['common.na'];
                if (endDate.isValid()) {
                    endDateFormatted = GetFormattedDateHourForInput(
                        endDate,
                        c.original.get('displayTimeZone'),
                        this.context.intl.messages['datetime-format-0']
                    );
                }
                const timeZone = c.original.get('displayTimeZone') || this.context.intl.messages['common.na'];
                const displayTimeZone = <i className="fas fa-globe" data-toggle="tooltip" title={timeZone} data-original-title={timeZone} />;
                return (
                    <div>
                        {endDateFormatted}&nbsp;{displayTimeZone}
                    </div>
                );
            }
        }, {
            id: 'status',
            minWidth: 50,
            accessor: c => c.get('eventStatusType'),
            Header: <strong className={getSortClass('status')}>{this.context.intl.messages['events.browse.status']}</strong>,
            Cell: c => {
                let status = this.context.intl.messages['common.na'];
                if (c.original.get('eventStatusType')) {
                    const eventStatusType = EventStore.getStatusType(c.original.get('eventStatusType'));
                    status = <span><i className={eventStatusType.icon}></i>&nbsp;<span className="hidden-xs hidden-sm">{this.context.intl.messages[eventStatusType.description]}</span></span>;
                }
                return (
                    <div>
                        {status}
                    </div>
                );
            }
        }, {
            id: 'userCount',
            minWidth: 60,
            accessor: c => c.get('userCount'),
            Header: <strong className={getSortClass('status')}>{this.context.intl.messages['events.browse.user-count']}</strong>,
            Cell: c => {
                const userCount = c.original.get('userCount');
                return <div>{userCount}</div>;
            }
        }];

        if (this.props.showEventTypeColumn) {
            columns.push({
                id: 'event_type',
                accessor: c => c.get('eventType'),
                Header: <strong className={getSortClass('event_type')}>{this.context.intl.messages['events.browse.event-type']}</strong>,
                Cell: c => {
                    const eventType = EventStore.getEventType(c.original.get('eventType'));
                    const accountType = EventStore.getAccountType(c.original.get('accountType'));
                    const eventAndUserType = `${this.context.intl.messages[eventType.message]} (${this.context.intl.messages[accountType.message]})`;
                    return (
                        <div>
                            {eventAndUserType}
                        </div>
                    );
                }
            });
        }

        const pagination = <div className="row">
            <div className="col-sm-7 col-sm-offset-5">
                <Pagination
                    activePage={this.props.activePage}
                    onChange={this.props.onPageChange}
                    totalPages={this.props.totalPages}
                />
            </div>
        </div>;

        return (
            <div>
                <ReactTable
                    className="-striped table-bordered table-striped responsive events-table"
                    columns={columns}
                    data={this.props.events}
                    getNoDataProps= {() => {
                        if (this.props.events.size) {
                            return {style: {display: 'none'}};
                        }
                        return {};
                    }}
                    loading={false}
                    showPagination={false}
                    sortable={true}
                    resizable={false}
                    pageSize={this.props.events.size}
                    getTheadThProps={(state, rowInfo, column, instance) => {
                        return {
                            onClick: () => {
                                if (column.sortable !== false) {
                                    instance.sortColumn(column);
                                }
                                handleSort(column.id);
                            }
                        };
                    }}
                />
                {pagination}
            </div>
        );
    }
}
