/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {LanguageCodeType} from '@wbdt-sie/brainiac-web-common';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

import Item from './item';
import {SlidingPanelActions} from '../../../../common/sliding-panel/sliding-panel-actions';
import {PublishingListActions} from '../../../publishing-list-actions';
import {PublishingListLocalizedActions} from '../../../publishing-list-localized-actions';
import PublishingListStoreLocalized from '../../../publishing-list-localized-store';
import PublishingListStore from '../../../publishing-list-store';

class ItemEdit extends Component {
    static get propTypes() {
        return {
            changeTarget: PropTypes.func.isRequired,
            currentLanguage: PropTypes.string.isRequired,
            disabled: PropTypes.bool,
            publishingListLocalized: PropTypes.instanceOf(Immutable.Map).isRequired,
            slidingPanelId: PropTypes.string.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            item: PublishingListStore.getState().get('item'),
            itemImageUploaded: PublishingListStore.getState().get('itemImageUploaded'),
            itemVideoUploaded: PublishingListStore.getState().get('itemVideoUploaded'),
            itemLocalized: PublishingListStoreLocalized.getState().get('itemLocalized'),
        };
    }

    static getStores() {
        return [PublishingListStore, PublishingListStoreLocalized];
    }

    static get defaultProps() {
        return {
            disabled: false
        };
    }

    constructor(props) {
        super(props);

        this.handleDelete = this.handleDelete.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    handleDelete() {
        PublishingListActions.deleteListItem(this.state.item.get('displayOrder'));
        SlidingPanelActions.hide(this.props.slidingPanelId);
        PublishingListActions.clearItem();
        PublishingListActions.hide();
        return;
    }

    handleSave() {
        let item = this.state.item;
        let imageFile = this.state.itemImageUploaded;
        let videoFile = this.state.itemVideoUploaded;

        if (imageFile) {
            item = item.set('newFile', imageFile);
            PublishingListActions.updateAttr('itemImageUploaded', null);
        }

        if (videoFile) {
            item = item.set('newVideoFile', videoFile);
            PublishingListActions.updateAttr('itemVideoUploaded', null);
        }

        switch (this.props.currentLanguage) {
        case LanguageCodeType.DEFAULT_LANGUAGE:
            PublishingListActions.updateListItem(item);
            break;
        default:
            PublishingListLocalizedActions.updateListItems(this.props.currentLanguage, this.state.itemLocalized);
            break;
        }

        PublishingListActions.hide();
        PublishingListActions.clearItem();
        return;
    }

    render() {
        let item = this.state.item;
        return (
            <div>
                <Item
                    currentLanguage={this.props.currentLanguage}
                    item={item}
                    itemLocalized={this.state.itemLocalized}
                    mode="edit"
                    changeTarget={this.props.changeTarget}
                    disabled={this.props.disabled}
                    publishingListLocalized={this.props.publishingListLocalized}
                    slidingPanelId={this.props.slidingPanelId}
                    targetTypeId={item.get('targetType')} />
                <div className="col-sm-9">
                    <br/>
                    <div>
                        <div className="pull-right">
                            <Button bsStyle="danger" onClick={this.handleDelete}><i className="fas fa-trash-alt fa-plus"></i>&nbsp;{this.context.intl.messages['publishing-list.list-item.item.edit.remove']}</Button>

                            &nbsp;
                            <Button
                                bsStyle="primary"
                                disabled={!!PublishingListStore.getItemValidations().length}
                                onClick={this.handleSave}
                            ><i className="fas fa-save fa-plus"></i>&nbsp;{this.context.intl.messages['common.save']}</Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Container.create(ItemEdit);
