/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React, {Component} from 'react';
import ReactSelect from 'react-select';

import {WorkOrdersConstants} from '../../../titles/work-orders/work-orders-actions';


class MetadataExportLanguage extends Component {
    static get propTypes() {
        return {
            disabled: PropTypes.bool,
            onChange: PropTypes.func.isRequired,
            value: PropTypes.number,
        };
    }

    static get defaultProps() {
        return {
            disabled: true,
            value: undefined,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);
        this.handleMetadataExportLanguageChange = this.handleMetadataExportLanguageChange.bind(this);
        return;
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.disabled !== this.props.disabled ||
            nextProps.value !== this.props.value) {
            return true;
        }
        return false;
    }

    handleMetadataExportLanguageChange(value) {
        this.props.onChange(value);
    }

    render() {
        let selectedLanguage = WorkOrdersConstants.EXPORT_LANGUAGES.find((item) => {
            return item.id === this.props.value;
        });
        return (
            <ReactSelect
                getOptionLabel={data => data.name}
                getOptionValue={data => data.id}
                isClearable={true}
                isMulti={false}
                isDisabled={this.props.disabled}
                name="languages"
                onChange={this.handleMetadataExportLanguageChange}
                options={WorkOrdersConstants.EXPORT_LANGUAGES}
                value={selectedLanguage}
            />
        );
    }
}

export default MetadataExportLanguage;
