/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React from 'react';

class CustomMenu extends React.Component {
    static get propTypes() {
        return {
            children: PropTypes.array.isRequired
        };
    }

    focusNext() {
        // noop
    }

    render() {
        return (
            <ul className="dropdown-menu">
                {this.props.children}
            </ul>
        );
    }
}

class CustomToggle extends React.Component {
    static get propTypes() {
        return {
            children: PropTypes.array.isRequired,
            id: PropTypes.string.isRequired,
            onClick: PropTypes.func
        };
    }

    static get defaultProps() {
        return {
            onClick: () => void 0,
        };
    }

    render() {
        return (
            <a id={this.props.id} onClick={this.props.onClick}>
                {this.props.children}
            </a>
        );
    }
}

export {
    CustomMenu,
    CustomToggle
};
