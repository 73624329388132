/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */
import Dispatcher from '../../dispatcher/dispatcher';

const CONSTANTS = {
    ASSET_RIGHTS: {
        SAVE_MATCHED_RIGHTS: 'asset_rights.save_matched_rights'
    }
};

class AssetRightsActions {
    saveMatchedRightsInfoType(value) {
        setTimeout(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.ASSET_RIGHTS.SAVE_MATCHED_RIGHTS,
                value,
            });
        }, 0);
    }
}

const actions = new AssetRightsActions();

export {
    actions as AssetRightsActions,
    CONSTANTS as AssetRightsStoreConstants
};
