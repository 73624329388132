/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {AlertTypes} from '../../common/notification/alert';
import {NotificationActions} from '../../common/notification/notification-actions';
import Dispatcher from '../../dispatcher/dispatcher';
import {PreloaderActions} from '../../preloader/preloader-actions';
import Request from '../../request';

const CONSTANTS = {
    GET: 'whitelist.get',
    LOAD: 'whitelist.load',
    REMOVE: 'whitelist.remove'
};

/* eslint-disable @typescript-eslint/no-this-alias */

class WhitelistActions {
    get() {
        let wa = this;

        Dispatcher.dispatch({
            actionType: CONSTANTS.GET
        });

        const preloaderSource = 'whitelist-actions.get';
        PreloaderActions.show(preloaderSource);

        Request.get('user/domain-whitelist').query({size: 9999, offset: 0}).exec().then(res => {
            wa.load(res.body.results);
            return;
        }).then(()=>{
            PreloaderActions.hide(preloaderSource);
            return;
        }).catch(() => {
            PreloaderActions.hide(preloaderSource);
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
            return;
        });

        return;
    }

    load(data) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.LOAD,
            data: data
        });

        return;
    }

    remove(id) {
        let wa = this;

        Dispatcher.dispatch({
            actionType: CONSTANTS.REMOVE,
            id: id
        });

        // remove the domain from the db
        Request.del(`user/domain-whitelist/${id}`).exec().then(() => {
            NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'emails.whitelist.remove.success');
            setTimeout(() => wa.get(), 0);
            return;
        }).catch(() => {
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'emails.whitelist.remove.error');
            return;
        });
    }

}

let actions = new WhitelistActions();

export {
    actions as WhitelistActions,
    CONSTANTS as WhitelistConstants
};
