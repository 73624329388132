/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, ControlLabel, FormGroup, Modal} from 'react-bootstrap';
import Select from 'react-select';

import {AssetCatalogActions} from './asset-catalog-actions';
import AssetCatalogStore from './asset-catalog-store';
import {AssetActions, AssetConstants} from '../asset-actions';
import AssetStore from '../asset-store';

import {Debounce, GetAttr} from '~/src/common/utils/utils';

class CopyCatalogsModal extends React.Component {
    static get propTypes() {
        return {
            onHide: PropTypes.func.isRequired,
            show: PropTypes.bool,
            isEventCatalog: PropTypes.bool,
        };
    }

    static get defaultProps() {
        return {
            show: false,
            isEventCatalog: false,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
        };
    }

    static calculateState() {
        return {
            assignedCatalogs: AssetCatalogStore.getState().get('assignedCatalogs'),
            targetAssetId: AssetCatalogStore.getState().get('targetAssetId'),
            targetCopyCatalogs: AssetCatalogStore.getState().get('targetCopyCatalogs'),
            videoAssets: AssetStore.getState().get('assets'),
        };
    }

    static getStores() {
        return [AssetCatalogStore, AssetStore];
    }

    constructor(props) {
        super(props);

        this.handleClose = this.handleClose.bind(this);
        this.handleSearchVideoAsset = Debounce(this.handleSearchVideoAsset.bind(this), 500);
        this.handleCopyCatalogs = this.handleCopyCatalogs.bind(this);
    }

    componentDidMount() {
        AssetActions.clear();
        AssetCatalogActions.clearSelectedCopyTarget();

    }

    handleClose() {
        AssetActions.clear();
        AssetCatalogActions.clearSelectedCopyTarget();
        this.props.onHide();
    }

    handleCopyCatalogs() {
        AssetCatalogActions.copyCatalogs(this.state.targetAssetId, this.state.targetCopyCatalogs, this.state.assignedCatalogs, this.props.isEventCatalog);
        this.handleClose();
    }

    handleSearchVideoAsset(value, {action}) {
        if (action === 'menu-close') {
            return;
        }

        const params = Immutable.Map ({
            'asset-type': AssetConstants.ASSET_TYPES.VIDEO.id,
            'asset-name': value,
            'active-type': 'ACTIVE',
            operator: 'AND'
        });
        AssetActions.get(params, 0, 99999, 'updatedDate', 'desc', false);
    }

    handleSelectVideoAsset(value) {
        if (value === null) {
            AssetActions.clear();
            return;
        }

        AssetCatalogActions.findTargetAssetByAssetId(value.id);
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={this.handleClose}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="text-center">{this.context.intl.messages['asset.edit.catalogs-tab.copy-catalogs.modal.title']}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {this.context.intl.messages['asset.edit.catalogs-tab.copy-catalogs.modal.description']}
                    </p>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['asset.edit.catalogs-tab.copy-catalogs.modal.label']}</ControlLabel>
                        <Select
                            getOptionLabel={GetAttr('assetName')}
                            getOptionValue={GetAttr('id')}
                            isClearable={true}
                            isMulti={false}
                            name="videoAssets"
                            onChange={this.handleSelectVideoAsset}
                            onInputChange={this.handleSearchVideoAsset}
                            options={this.state.videoAssets.toJS()}
                            placeholder={this.context.intl.messages['asset.edit.catalogs-tab.copy-catalogs.modal.placeholder']}
                        />
                    </FormGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="btn btn-default-outline Mr(3px) Mb(3px) pull-left"
                        onClick={this.handleClose}
                    >
                        <i className="fas fa-times"/>&nbsp;{this.context.intl.messages['common.cancel']}
                    </Button>
                    <Button
                        className="btn btn-primary-outline Mr(3px) Mb(3px)"
                        onClick={this.handleCopyCatalogs}
                        disabled={!this.state.targetAssetId}
                    >
                        {this.context.intl.messages['common.confirm']}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default Container.create(CopyCatalogsModal);
