/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {ClientRepGroupConstants} from './client-rep-group-actions';
import Validations from '../../common/validations/validations';
import Dispatcher from '../../dispatcher/dispatcher';

const ClientRepGroupValidations = {
    name: {
        label: 'groups.client-rep-group.create.info.name',
        validations: [
            Validations.max(50), Validations.required,
        ]
    }
};

const ClientRepGroupWarnings = {
    name: {
        label: 'groups.users',
        validations: [
            Validations.customWarning(
                function() {
                    return '“Delete Button” is disabled because this client rep group has users assigned. Please manually remove all the users to enable deleting the group.';
                },
                function() {
                    return !store.getState().getIn(['users', 'results'])?.size;
                }
            )
        ]
    }
};


class ClientRepGroupStore extends ReduceStore {

    getInitialState() {
        return Immutable.Map({
            offset: 0,
            clientRepGroup: Immutable.Map({
                history: Immutable.List(),
                name: '',
                territories: Immutable.OrderedSet()
            }),
            clientRepGroups: Immutable.List(),
            originalClientRepGroup: Immutable.Map({
                name: '',
                territories: Immutable.OrderedSet()
            }),
            preloaderVisible: false,
            selectedUsers: Immutable.List(),
            showPreloader: false,
            sortDirection: 'desc',
            sortFieldName: 'updatedDate',
            users: Immutable.List(),
            size: 0,
            total: 0
        });
    }

    getValidations() {
        return Validations.validate(this.getState().get('clientRepGroup'), ClientRepGroupValidations);
    }

    getWarnings() {
        return Validations.validate(this.getState().get('clientRepGroup'), ClientRepGroupWarnings);
    }

    reduce(state, action) {
        switch (action.actionType) {
        case ClientRepGroupConstants.CLEAR:
            state = this.getInitialState();
            break;

        case ClientRepGroupConstants.GET.ERROR:
        case ClientRepGroupConstants.GET.USERS.ERROR:
            state = state.merge({
                showPreloader: false,
            });
            break;

        case ClientRepGroupConstants.LOAD:
            state = state.merge({
                offset: action.offset,
                size: action.size,
                total: action.total,
                clientRepGroups: action.clientRepGroups,
            });
            break;

        case ClientRepGroupConstants.UPDATE:
            state = state.setIn(['clientRepGroup', ...action.attr.split('.')], action.value);
            break;

        case ClientRepGroupConstants.GET.SUCCESS:
            state = state.merge({
                clientRepGroup: action.clientRepGroup,
                originalClientRepGroup: action.clientRepGroup,
                selectedUsers: Immutable.List(),
                showPreloader: false,
            });
            break;

        case ClientRepGroupConstants.SAVE.SUCCESS:
            state = state.merge({
                originalClientRepGroup: action.clientRepGroup,
            });
            break;

        case ClientRepGroupConstants.GET.USERS.START:
            state = state.merge({
                preloaderVisible: true
            });
            break;

        case ClientRepGroupConstants.GET.USERS.SUCCESS:
            state = state.merge({
                activePage: Math.ceil(action.offset/action.size) || 0,
                offset: action.offset,
                preloaderVisible: false,
                selectedUsers: Immutable.List(),
                showPreloader: false,
                size: action.size,
                total: action.total,
                totalPages: Math.ceil(action.total/action.size) || 0,
                users: action.users
            });
            break;

        case ClientRepGroupConstants.SORT.SET:
            state = state.merge({
                sortFieldName: action.sortFieldName,
                sortDirection: action.sortDirection
            });
            break;

        case ClientRepGroupConstants.USERS.TOGGLE_SELECT:
            // Toggle the item value.
            state = state.setIn(['users', 'results', action.index, '__selected'], action.value);
            // Update the selected array.
            state = state.set('selectedUsers', state.getIn(['users', 'results']).filter(u => !!u.get('__selected')));
            state = state.set('selectAll', state.getIn(['users', 'results']).size === state.get('selectedUsers').size);
            break;

        case ClientRepGroupConstants.USERS.TOGGLE_SELECT_ALL:
            // Toggle all items.
            state = state.merge({
                selectAll: action.value,
                users: {
                    results: state.getIn(['users', 'results']).map(u => u.set('__selected', action.value))
                }
            });
            // Update the selectedUsers list according to the current value,
            // no need to filter the array.
            if (action.value) {
                state = state.set('selectedUsers', state.getIn(['users', 'results']));
            } else {
                state = state.set('selectedUsers', Immutable.List());
            }
            break;

        }

        return state;
    }
}

let store = new ClientRepGroupStore(Dispatcher);

export default store;

export {ClientRepGroupValidations};
