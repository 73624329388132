/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {DOCUMENT_CT_TYPES} from '@wbdt-sie/brainiac-web-common';
import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {AssetTabConstants} from './asset-tab-actions';
import Categories from './categories';
import {ImageConstants} from '../assets/image/image-actions';
import {ScriptConstants} from '../assets/script/script-actions';
import {VideoConstants} from '../assets/video/video-actions';
import Validations, {SEVERITY} from '../common/validations/validations';
import Dispatcher from '../dispatcher/dispatcher';


const AssetTabValidations = {
    defaultBanner: {
        label: 'asset.document.name',
        validations: [Validations.custom(
            /* istanbul ignore next */
            () => 'This Title has a default video banner and a default banner image',
            /* istanbul ignore next */
            () => {
                let assetType = ['video', 'image'];
                let bothBanners = [];

                assetType.forEach(assets => {
                    let assetsEach = store.getState().get('assets').get(`${assets}`).get('active');
                    assetsEach.forEach(types => {
                        types.forEach(attr => {
                            /* istanbul ignore next */
                            if (attr.get('defaultVideoBanner') === 1) {
                                bothBanners.push(attr);
                            } else if (attr.get('defaultBanner') === 1) {
                                bothBanners.push(attr);
                            }
                        });
                    });
                });
                return bothBanners.length !== 2;
            },
            SEVERITY.WARNING
        )]
    }
};

class AssetTabStore extends ReduceStore {

    // Return an action type by id.
    getInitialState() {
        let assets = Immutable.fromJS({
            video: {
                active: {},
                inactive: {}
            },
            audio: {
                active: {},
                inactive: {}
            },
            image: {
                active: {},
                inactive: {}
            },
            document: {
                active: {},
                inactive: {}
            },
            station: {
                active: {},
                inactive: {}
            },
            merchandise: {
                active: {},
                inactive: {}
            },
            script: {
                active: {},
                inactive: {}
            },
            'video-timeline': {
                active: {},
                inactive: {}
            }
        });

        let thumbnails = Immutable.fromJS({
            image: [],
            imageThumbnailsLoaded: false,
            video: [],
            videoThumbnailsLoaded: false,
        });

        let state = Immutable.fromJS({
            assets: assets,
            copyFromAsset: Immutable.Map(),
            copyToAsset: Immutable.Map(),
            originalAssets: assets,
            assetDetails: {
                show:false,
                asset: {},
                path: []
            },
            selectedAssetsToAdd: Immutable.Set(),
            showAssetType:'all',
            assetType: '',
            assetUrl: null,
            thumbnails: thumbnails,
            videos: Immutable.Map()
        });

        state = state.merge({
            activatedAssets: Immutable.Set(),
            deactivatedAssets: Immutable.Set()
        });

        return state;
    }

    getValidations() {
        return Validations.validate(this.getState().get('assets'), AssetTabValidations);
    }

    // this should came from the server!!!! :(
    // please kill me for writing this :'(
    quickAndDirtyContentTypeToText(assetType, contentType) {
        /*istanbul ignore next*/
        switch (assetType) {
        case 'station':
        case 'document':
            const contentTypeMap = DOCUMENT_CT_TYPES.CONTENT_TYPE_MAP;
            if (contentType in contentTypeMap) {
                return contentTypeMap[contentType].name;
            }
            break;
        case 'image':
            contentType = ImageConstants.CONTENT_TYPE_MAP[contentType];
            let contentTypeName = 'Other';
            if (contentType) {
                contentTypeName = contentType.name;
            }
            return contentTypeName;
        case 'video':
            return Object.keys(VideoConstants.CONTENT_TYPES).filter(
                ct => VideoConstants.CONTENT_TYPES[ct].id === contentType
            ).map(
                ct => VideoConstants.CONTENT_TYPES[ct].name
            )[0];
        case 'script':
            return Object.keys(ScriptConstants.CONTENT_TYPES).filter(
                ct => ScriptConstants.CONTENT_TYPES[ct].id === contentType
            ).map(
                ct => ScriptConstants.CONTENT_TYPES[ct].name
            )[0];
        case 'video-timeline':
            return Object.keys(VideoConstants.CONTENT_TYPES).filter(
                ct => VideoConstants.CONTENT_TYPES[ct].id === contentType
            ).map(
                ct => VideoConstants.CONTENT_TYPES[ct].name
            )[0];
        }
        return contentType;
    }

    reduce(state, action) {
        let asset, where, to;
        switch (action.actionType) {
        /* istanbul ignore next */
        case AssetTabConstants.SET_TITLEID: {
            // Iterate the entire asset tree to set titleId on newly added assets
            state = state.updateIn(['assets'], type_root => type_root.map(
                active_root => active_root.map(
                    root => root.map(
                        categ => categ.map(a => {
                            if (action.assets.map(c => c.assetId).includes(a.get('assetId'))) {
                                a = a.set('titleId', action.titleId);
                            }
                            return a;
                        })
                    )
                )
            ));
        }
            break;
        case AssetTabConstants.ADD:
            let category;
            action.items.forEach(assetItem => {
                where = ['assets'];
                let active = 'inactive';
                if (assetItem.get('active')) {
                    active = 'active';
                }
                let cat = Categories.getCategory(action.assetType, assetItem.get('contentType'));
                where = ['assets'].concat(action.assetType, active, cat);
                category = state.getIn(where);
                // CHECK: this shouldn't happen (I think)
                /* istanbul ignore else */
                if (!category) {
                    category = Immutable.List();
                }
                // names are not consistent between different asset types and
                // list vs single item gets :(
                let assetName = assetItem.get('assetName') || assetItem.get('name');
                // TODO: this makes no sense as contentType is used before to get category
                /* istanbul ignore next */
                let contentType = assetItem.get('contentType') || assetItem.get('assetType');
                let itemAsset = {
                    assetId: assetItem.get('id'),
                    assetType: action.assetType.toUpperCase(),
                    children:[],
                    contentType: this.quickAndDirtyContentTypeToText(action.assetType, contentType),
                    contentTypeId: assetItem.get('contentTypeId'),
                    createdDate: assetItem.get('createdDate'),
                    defaultHorizontal: 0,
                    defaultPortrait: 0,
                    isActive: assetItem.get('active'),
                    isRecentlyAdded: true,
                    mfaRequired: assetItem.get('mfaRequired'),
                    name: assetName,
                    runtime: assetItem.get('runtime'),
                    thumbnails: [{
                        previewUrl: assetItem.get('previewUrl'),
                        thumbnailUrl: assetItem.get('thumbnailUrl')
                    }]
                };
                category = category.unshift(Immutable.fromJS(itemAsset));
                category = category.map((categoryItem, index) => {
                    categoryItem = categoryItem.set('assetOrder', index);
                    return categoryItem;
                });

                state = state.setIn(where, category);

                // Finally, if addedd succesfully, then remove the asset from the list of
                // assets to add.
                state = state.update('selectedAssetsToAdd', assets => {
                    /* istanbul ignore next */
                    const found = assets.find(a => assetItem.get('id') === a.get('id'));
                    /* istanbul ignore if */
                    if (found) {
                        return assets.remove(found);
                    }
                    return assets;
                });
            });
            break;

        case AssetTabConstants.CATALOG.GET.SUCCESS:
            where = ['assets'].concat(action.assetPath);
            state = state.setIn(where.concat('catalogs'), action.catalogs);
            where = ['originalAssets'].concat(action.assetPath);
            state = state.setIn(where.concat('catalogs'), action.catalogs);
            break;

        case AssetTabConstants.CLEAR:
            state = this.getInitialState();
            break;

        case AssetTabConstants.CLEAR_SELECTED_ASSETS_TO_ADD:
            state = state.set('selectedAssetsToAdd', Immutable.Set());
            break;

        case AssetTabConstants.GET.SUCCESS:
            state = state.set('assets', action.assets);
            state = state.set('originalAssets', action.assets);
            state = state.set('caller', action.caller);
            state = state.set('id', action.id);
            break;

        case AssetTabConstants.HIDE_DETAIL:
            state = state.set('assetDetails', Immutable.fromJS({
                asset: {},
                path: [],
                show: false
            }));
            break;

        case AssetTabConstants.LINK:
            where = ['assets'].concat(action.assetPath);
            category = state.getIn(where);
            let item = category.get(action.from);
            to = category.get(action.to);
            item = item.set('parentStackAssetId', to.get('assetId') );
            to = to.set('children', to.get('children').push(item));
            category = category.set(action.to, to);
            category = category.remove(action.from);
            state = state.setIn(where, category);
            break;

        case AssetTabConstants.REMOVE:
            where = ['assets'].concat(action.assetPath);
            state = state.deleteIn(where);
            break;

        case AssetTabConstants.REORDER:
            where = ['assets'].concat(action.assetPath);
            category = state.getIn(where);
            item = category.get(action.from);
            to = action.to; // < action.from ? action.to : action.to - 1;
            category = category.remove(action.from);
            category = category.insert(to, item);
            state = state.setIn(where, category);
            break;

        case AssetTabConstants.SAVE.SUCCESS:
            state = state.merge({
                activatedAssets: Immutable.Set(),
                deactivatedAssets: Immutable.Set(),
                originalAssets: state.get('assets')
            });
            break;

        case AssetTabConstants.SELECT_ASSET_TO_ADD:
            state = state.update('selectedAssetsToAdd', assets => {
                /* istanbul ignore next */
                const found = assets.find(a => action.asset.get('id') === a.get('id'));
                if (found) {
                    return assets.remove(found);
                }

                return assets.add(action.asset);
            });
            break;

        case AssetTabConstants.SELECT_COPY_FROM_ASSET:
            // CopyFrom video assets use id instead of assetId
            /* istanbul ignore if */
            if (state.getIn(['copyFromAsset', 'id']) === action.asset.get('id')) {
                state = state.set('copyFromAsset', Immutable.Map());
            } else {
                state = state.set('copyFromAsset', action.asset);
            }
            break;

        case AssetTabConstants.SELECT_COPY_TO_ASSET:
            // CopyTo video assets use assetId instead of id
            /* istanbul ignore if */
            if (state.getIn(['copyToAsset', 'assetId']) === action.asset.get('assetId')) {
                state = state.set('copyFromAsset', Immutable.Map());
            } else {
                state = state.set('copyToAsset', action.asset);
            }
            break;

        case AssetTabConstants.REPLACE_VIDEO_CLEAR:
            state = state.set('copyFromAsset', Immutable.Map());
            state = state.set('copyToAsset', Immutable.Map());
            break;

        case AssetTabConstants.SET_ASSET_TYPE:
            state = state.set('assetType', action.assetType);
            break;

        case AssetTabConstants.SET_ASSET_URL:
            state = state.set('assetUrl', action.assetUrl);
            break;

        case AssetTabConstants.SET_DEFAULT:
            where = ['assets'].concat(action.assetPath);

            // If currentValue is 0/false/null, the next value is 1, then make all other images 0.
            let currentValue = action.asset.get(action.type);
            /* istanbul ignore else */
            if (!currentValue) {
                let assetSubtype = where.slice(0, 3);
                // Iterate all descendants and set flag in zero to reset the previous default image.
                state = state.updateIn(assetSubtype, root => root.map(
                    categ => categ.map(
                        // type is one of defaultHorizontal, defaultVertical or defaultBanner.
                        a => a.set(action.type, 0)
                    )
                ));

                // Create an array with the values that are not part of the action object
                // so that we can change them to 0.
                let defaults = ['defaultBanner', 'defaultHorizontal', 'defaultPortrait', 'defaultTitleTreatment', 'defaultVideoBanner'].reduce( (o, v) => {
                    let value = 0;
                    if (v === action.type) {
                        value = 1;
                    }
                    o[v] = value;
                    return o;
                }, {});

                state = state.mergeIn(where, defaults);
            } else {
                state = state.setIn([...where, action.type], 0);
            }

            break;

        case AssetTabConstants.SET_DEFAULT_CLEAR:
            where = ['assets'].concat(action.assetPath);
            // Create an array with the values that are not part of the action object
            // so that we can change them to 0.
            let defaults = ['defaultBanner', 'defaultHorizontal', 'defaultPortrait', 'defaultTitleTreatment', 'defaultVideoBanner'].reduce( (o, v) => {
                o[v] = 0;
                return o;
            }, {});
            state = state.mergeIn(where, defaults);

            break;

        case AssetTabConstants.SET_THUMBNAILS:
            let vidOrImg = 'videoThumbnailsLoaded';
            /* istanbul ignore else */
            if (action.type === 'image') {
                vidOrImg = 'imageThumbnailsLoaded';
            }
            action.thumbnails.forEach((toAdd) => {
                state = state.updateIn(['thumbnails', action.type], thumbnails => {
                    return thumbnails.push(toAdd);
                });
            });
            state = state.setIn(['thumbnails', vidOrImg], state.getIn(['thumbnails', action.type]).size === action.elementsCount);

            break;

        case AssetTabConstants.SHOW_ASSET_TYPE:
            state = state.set('showAssetType', action.assetType);
            break;

        case AssetTabConstants.SHOW_DETAIL:
            asset = action.asset;
            /* istanbul ignore else */
            if (!asset) {
                where = ['assets'].concat(action.assetPath);
                asset = state.getIn(where);
            }
            state = state.set('assetDetails', Immutable.fromJS({
                asset: asset,
                path: action.assetPath,
                show: true
            }));
            break;

        /* istanbul ignore next */
        case AssetTabConstants.TOGGLE_ACTIVE:
            // Where is the asset right now:
            where = ['assets'].concat(action.assetPath);
            asset = state.getIn(where);
            // To is the same as where, but we use slice to remove the last element because that element is
            // the index in the array.
            if (where[6] !== undefined) {
                // if child and no longer going to be child, then need to slice it out the children part as well.
                to = ['assets'].concat(action.assetPath).slice(0, -3);
                // And also remove the value from the video.
                asset = asset.set('parentStackAssetId', null);
            } else {
                to = ['assets'].concat(action.assetPath).slice(0, -1);
            }
            // Swap 'active' and 'inactive' for the tables display
            // and save a reference in the list of assets to activate/deactivate.
            let setActive = 1;
            let assetDescription = Immutable.Map({
                id: action.asset.get('assetId'),
                type: action.asset.get('assetType').toLowerCase()
            });

            if (to[2] === 'active') {
                setActive = 0;
                to[2] = 'inactive';
                state = state.update('activatedAssets', a => a.remove(assetDescription));
                state = state.update('deactivatedAssets', da => da.add(assetDescription));
            } else {
                to[2] = 'active';
                state = state.update('activatedAssets', a => a.add(assetDescription));
                state = state.update('deactivatedAssets', da => da.remove(assetDescription));
            }

            // Swap 'active' and 'inactive' on the asset too.
            asset = asset.set('isActive', setActive);
            // And its children.
            asset = asset.update('children', c => c.map(v => {
                // For each children, add or remove it from the list of assets
                // to activate or deactivate.
                let childDescription = Immutable.Map({
                    id: v.get('assetId'),
                    type: v.get('assetType').toLowerCase()
                });
                if (setActive === 0) {
                    state = state.update('activatedAssets', a => a.remove(childDescription));
                    state = state.update('deactivatedAssets', da => da.add(childDescription));
                } else {
                    state = state.update('activatedAssets', a => a.add(childDescription));
                    state = state.update('deactivatedAssets', da => da.remove(childDescription));
                }

                // Finally update the object and return.
                return v.set('isActive', setActive);
            }));

            // Remove from the previous category.
            state = state.removeIn(where);
            // If the list is empty, then remove the list entirely so that the table doesn't show
            // up in the UI.
            if (!state.getIn(where.slice(0, -1)).size) {
                state = state.removeIn(where.slice(0, -1));
            }

            // Add to the new one.
            state = state.updateIn(to, categoryList => {
                if (!categoryList) {
                    categoryList = Immutable.List();
                }

                return categoryList.push(asset);
            });
            break;

        case AssetTabConstants.UNLINK:
            where = ['assets'].concat(action.assetPath);

            // remove from inner table
            category = state.getIn(where);
            item = category.get(action.order);
            category = category.remove(action.order);
            state = state.setIn(where, category);

            // add to parent
            let pos = where[where.length -2];
            where = where.slice(0, -2);
            category = state.getIn(where);
            item = item.set('parentStackAssetId', null);
            category = category.insert(pos+1, item);
            state = state.setIn(where, category);
            break;

        }
        return state;
    }
}

const store = new AssetTabStore(Dispatcher);

export default store;
export {
    AssetTabValidations
};

