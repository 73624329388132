/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import Info from './info';

import UserGroups from '~/src/authorized-partners/create/user-groups';
import {GroupActions} from '~/src/security/group/group-actions';

/**
 * Summary Tab and its content.
 */
class Summary extends Component {
    static get propTypes() {
        return {
            disabled: PropTypes.bool,
            location: PropTypes.object.isRequired,
            mode: PropTypes.string.isRequired,
            stationGroup: PropTypes.instanceOf(Immutable.Map).isRequired,
        };
    }

    static get defaultProps() {
        return {
            disabled: true,
            permissions: {}
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    render() {
        return (
            <div className="tab-pane padding-x-20 padding-bottom-20">
                <Info
                    mode={this.props.mode}
                    disabled={this.props.disabled}
                    stationGroup={this.props.stationGroup}
                />
                <hr/>
                <UserGroups
                    assignedUserGroups={this.props.stationGroup.get('stationGroups')}
                    groupType="stations"
                    location={this.props.location}
                    onAdd={GroupActions.addStationToStationGroup}
                    onRemove={GroupActions.removeStationFromStationGroup}
                />
            </div>
        );
    }
}

export default Summary;
