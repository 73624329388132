/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import jQuery from 'jquery';
import PropTypes from 'prop-types';
import React from 'react';

require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('../../styles/data-tables-brainiac.css');
require('datatables.net-responsive-bs');

let noop = () => void 0;

export default class Table extends React.Component {
    static get propTypes() {
        return {
            columns: PropTypes.arrayOf(
                PropTypes.shape({
                    getHeader: PropTypes.func.isRequired,
                    getValue: PropTypes.func.isRequired,
                    sortFieldName: PropTypes.string,
                    width: PropTypes.number,
                })
            ).isRequired,
            cellMouseEnter: PropTypes.func,
            items: PropTypes.instanceOf(Immutable.List).isRequired,
            rowClick: PropTypes.func,
            sortChange: PropTypes.func,
            sortDirection: PropTypes.string,
            sortFieldName: PropTypes.string,
        };
    }

    static get defaultProps() {
        return {
            cellMouseEnter: noop,
            rowClick: noop,
            sortChange: noop,
            sortDirection: undefined,
            sortFieldName: undefined,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
        };
    }

    constructor(props) {
        super(props);

        this.init = this.init.bind(this);
        this.drawItems = this.drawItems.bind(this);
        this.handleCellMouseEnter = this.handleCellMouseEnter.bind(this);
        this.handleHeaderClick = this.handleHeaderClick.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
    }

    componentDidMount() {
        this.drawItems(this.props.items, this.props.columns);
    }

    componentWillUpdate(nextProps) {
        this.drawItems(nextProps.items, nextProps.columns);
    }

    componentWillUnmount() {
        if (this.$tableAPI) {
            this.$tableAPI.destroy();
        }
    }

    drawItems(items, columns) {
        this.$tableAPI.clear();
        // Add data to the jQuery datatable.
        items.forEach((item, itemIndex) => {
            let row = columns.map(c => {
                let v = c.getValue(item, itemIndex, this.context.intl);
                if (v === undefined || v === null) {
                    return '-';
                }

                return v;
            });

            // Add a last empty column for the datatable-responsive plugin.
            row.push('');

            this.$tableAPI.row.add(row);
        });

        this.$tableAPI.draw(false);


        // Now, since the data has changed the columns widths, trigger
        // the resize handler in order to update the responsive feature.
        this.$tableAPI.responsive.recalc();
    }

    getSortFieldNameClass(column) {
        let className;

        if (column.sortFieldName) {
            if (column.sortFieldName === this.props.sortFieldName) {
                className = `sorting_${this.props.sortDirection}`;
            } else {
                className = 'sorting';
            }
        }

        return className;
    }

    handleCellMouseEnter(event) {
        this.props.cellMouseEnter(event);
    }

    handleHeaderClick(column) {
        if (!column.sortFieldName) {
            return;
        }

        let newSortDirection = 'asc';
        if (this.props.sortFieldName === column.sortFieldName && this.props.sortDirection === 'asc') {
            newSortDirection = 'desc';
        }

        this.props.sortChange(column.sortFieldName, newSortDirection);
    }

    handleRowClick(event) {
        this.props.rowClick(event);
    }

    init(table) {
        this.$table = jQuery(table);
        this.$tableAPI = this.$table.DataTable({
            autoWidth: false,
            columnDefs: [{
                // Add the control class to the last column. This colum will
                // contain the button to show the responsive data.
                className: 'control',
                targets: -1,
                width: 20
            }].concat(this.props.columns.map((column) => {
                return {
                    targets: this.props.columns.indexOf(column),
                    width: column.width,
                };
            })),
            iDisplayLength: 1,
            info: false,
            ordering: false,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        });

        this.$table.on('mouseenter', 'td', this.handleCellMouseEnter);
    }

    render() {
        return (
            <table
                className="table table-bordered table-striped responsive"
                ref={this.init}
            >
                <thead>
                    <tr>
                        {this.props.columns.map(c => (
                            <th className={this.getSortFieldNameClass(c)} onClick={this.handleHeaderClick.bind(this, c)}>
                                {c.getHeader(this.context.intl)}
                            </th>
                        ))}
                        <th className="no-sort"></th>
                    </tr>
                </thead>
                <tbody onClick={this.handleRowClick}>
                </tbody>
            </table>
        );
    }
}
