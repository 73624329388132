/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
*/

import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link} from 'react-router';
import ReactTable from 'react-table';

import Pagination from '../../common/table/pagination';
import {BatchConstants} from '../batch-actions';
import BatchStatus from '../common/batch-status';
import 'react-table/react-table.css';

export default class Table extends Component {
    static get propTypes() {
        return {
            activePage: PropTypes.number.isRequired,
            batches: PropTypes.instanceOf(Immutable.List).isRequired,
            batchType: PropTypes.string.isRequired,
            displayLinks: PropTypes.bool,
            onPageChange: PropTypes.func.isRequired,
            onSortChange: PropTypes.func.isRequired,
            sortDirection: PropTypes.string.isRequired,
            sortFieldName: PropTypes.string.isRequired,
            totalPages: PropTypes.number.isRequired
        };
    }

    static get defaultProps() {
        return {
            displayLinks: false
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
            router: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            id: Math.ceil(Math.random() * 100000)
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.handleHeaderClick = this.handleHeaderClick.bind(this);
        this.gettHeaderNameClass = this.gettHeaderNameClass.bind(this);
    }

    gettHeaderNameClass(headerName) {
        let headerClassName = 'sorting';

        if (headerName === this.props.sortFieldName) {
            headerClassName = `sorting-${this.props.sortDirection}`;
        }

        return headerClassName;
    }

    handleHeaderClick(headerName) {
        if (!headerName) { // if column does not have sorting
            return;
        }

        let newSortDirection = 'asc';
        if (this.props.sortFieldName === headerName && this.props.sortDirection === 'asc') {
            newSortDirection = 'desc';
        }
        if (this.props.onSortChange) {
            this.props.onSortChange(headerName, newSortDirection);
        }
        return;
    }

    render() {
        const getHeader = this.gettHeaderNameClass;

        let columns = [{
            accessor: 'BATCH_NAME',
            Header:  props => (
                <strong className={getHeader(props.column.id)}>{this.context.intl.messages['scheduling.batches.name']}</strong>
            ),
            minWidth: 200,
            maxWidth: 280,
            Cell: c => {
                let batchName = c.original.get('batchName');
                if (this.props.displayLinks) {
                    batchName = <Link to={`/scheduling/${this.props.batchType}/${c.original.get('id')}`}>{batchName}</Link>;
                }
                return (
                    <div>
                        {batchName || '-'}
                    </div>
                );
            }
        }, {
            accessor: 'EXECUTION_DATE',
            Header: props => (
                <strong className={getHeader(props.column.id)}>{this.context.intl.messages['scheduling.batches.start-date']}</strong>
            ),
            Cell: c => {
                const executionDate = Moment(c.original.get('executionDate'));
                let executionDateFormated = '-';
                if (executionDate.isValid()) {
                    executionDateFormated = executionDate.format(this.context.intl.messages['date-format']);
                }
                const executionTime = c.original.get('executionTime');
                let executionTimeFormated = '-';
                if (executionTime) {
                    executionTimeFormated = Moment(executionTime, ['HH:mm']).format('hh:mm a');
                }
                return (
                    <div>
                        {`${executionDateFormated} ${executionTimeFormated}`}
                    </div>
                );
            }
        }, {
            accessor: 'CREATED_DATE',
            Header: props => (
                <strong className={getHeader(props.column.id)}>{this.context.intl.messages['scheduling.batches.created-date']}</strong>
            ),
            Cell: c => {
                const createdDate = Moment(c.original.get('createdDate'));
                let createdDateFormated = '-';
                if (createdDate.isValid()) {
                    createdDateFormated = createdDate.format(this.context.intl.messages['date-format']);
                }
                return (
                    <div>
                        {createdDateFormated}
                    </div>
                );
            }
        }, {
            accessor: 'EXECUTED_AT',
            Header: props => (
                <strong className={getHeader(props.column.id)}><i className="fa fa-info-circle"></i>&nbsp;{this.context.intl.messages['scheduling.batches.status']}</strong>
            ),
            Cell: c => {
                const executedAt = c.original.get('executedAt');
                return (
                    <div style={{paddingBottom: '3px'}}>
                        <BatchStatus
                            completedMessage={this.context.intl.messages['scheduling.batches.status.completed']}
                            completedMessageTooltip={this.context.intl.messages['scheduling.batches.status.completed.tooltip']}
                            executedAt={executedAt}
                            pendingMessage={this.context.intl.messages['scheduling.batches.status.pending']}
                            pendingMessageTooltip={this.context.intl.messages['scheduling.batches.status.pending.tooltip']}
                        />
                    </div>
                );
            }
        }, {
            Header: <strong>{this.context.intl.messages['scheduling.batches.assets']}</strong>,
            Cell: c => {
                return (
                    <div>
                        {c.original.get('assetCount')}
                    </div>
                );
            },
            show: this.props.batchType.toUpperCase() !== BatchConstants.BATCH_TYPES.TITLE
        }, {
            Header: <strong>{this.context.intl.messages['scheduling.batches.titles']}</strong>,
            Cell: c => {
                return (
                    <div>
                        {c.original.get('titleCount')}
                    </div>
                );
            },
            show: this.props.batchType.toUpperCase() === BatchConstants.BATCH_TYPES.TITLE
        }, {
            Header: <strong>{this.context.intl.messages['scheduling.batches.catalogs']}</strong>,
            Cell: c => {
                return (
                    <div>
                        {c.original.get('catalogCount')}
                    </div>
                );
            }
        }];

        let pagination = <div className="row">
            <div className="col-sm-7 col-sm-offset-5">
                <Pagination
                    activePage={this.props.activePage}
                    onChange={this.props.onPageChange}
                    totalPages={this.props.totalPages}
                />
            </div>
        </div>;

        const handleSortColumn = this.handleHeaderClick;

        return (
            <div>
                <ReactTable
                    className="-striped table-bordered table-striped responsive scheduling-table"
                    columns={columns}
                    data={this.props.batches}
                    getNoDataProps= {() => {
                        if (this.props.batches.size) {
                            return {style: {display: 'none'}};
                        }
                        return {};
                    }}
                    id={`batches-table-${this.state.id}`}
                    loading={false}
                    showPagination={false}
                    sortable={false}
                    resizable={false}
                    getTheadThProps={(state, rowInfo, column) => ({
                        onClick: () => {
                            handleSortColumn(column.id);
                        }
                    })}
                />
                {pagination}
            </div>
        );
    }
}
