/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Moment from 'moment';

import {SEVERITY, ERRORTYPE, ValidationApi} from './helpers';
import enUS from '../../messages/en-US';
import {TimecodeToString} from '../utils/timecode';

const noop = () => void 0;

const SkipIfEmpty = validator => {
    return v => {
        if (v === undefined || v === null || v === '') {
            return true;
        }

        return validator(v);
    };
};

const isUpperCase = function(c) {
    return c === c.toUpperCase() && c !== c.toLowerCase();
};

// Common password list taken from the top 100 passwords listed here:
// https://en.wikipedia.org/wiki/Wikipedia:10,000_most_common_passwords#Top_100
const CommonPasswords = ['123456', 'password', '12345678', 'qwerty', '123456789', '12345', '1234', '111111', '1234567', 'dragon',
    '123123', 'baseball', 'abc123', 'football', 'monkey', 'letmein', '696969', 'shadow', 'master', '666666', 'qwertyuiop', '123321',
    'mustang', '1234567890', 'michael', '654321', 'pussy', 'superman', '1qaz2wsx', '7777777', 'fuckyou', '121212', '000000', 'qazwsx',
    '123qwe', 'killer', 'trustno1', 'jordan', 'jennifer', 'zxcvbnm', 'asdfgh', 'hunter', 'buster', 'soccer', 'harley', 'batman', 'andrew',
    'tigger', 'sunshine', 'iloveyou', 'fuckme', '2000', 'charlie', 'robert', 'thomas', 'hockey', 'ranger', 'daniel', 'starwars', 'klaster',
    '112233', 'george', 'asshole', 'computer', 'michelle', 'jessica', 'pepper', '1111', 'zxcvbn', '555555', '11111111', '131313', 'freedom',
    '777777', 'pass', 'fuck', 'maggie', '159753', 'aaaaaa', 'ginger', 'princess', 'joshua', 'cheese', 'amanda', 'summer', 'love', 'ashley',
    '6969', 'nicole', 'chelsea', 'biteme', 'matthew', 'access', 'yankees', '987654321', 'dallas', 'austin', 'thunder', 'taylor', 'matrix'];

class ValidationCanceledError extends Error {}

/**
 * Validations
 *
 * validate method MUST return TRUE if value is VALID.
 */
const Validations = {
    alphaNumericNoSpaces: {
        getMessage: (label) =>{
            return `${label} must contain only letters or numbers and no spaces`;
        },
        severity: SEVERITY.ALERT,
        type: 'alphaNumericNoSpaces',
        errorType: ERRORTYPE.ERROR,
        icon: 'fas fa-flag',
        validate: (value) => {
            if (!value) {
                return true;
            }

            const reAlphaNumeric = /^[a-zA-Z0-9]+$/;
            return reAlphaNumeric.test(value);
        }
    },
    capitalization: {
        getMessage: label => `${label} does not start with a capital letter`,
        severity: SEVERITY.WARNING,
        type: 'capitalization',
        errorType: ERRORTYPE.WARNING,
        icon: 'fas fa-flag',
        validate: SkipIfEmpty(value => {
            let items = value.split(' ');
            return items.every(item => {
                if (item.length !== 0) {
                    return item[0].toUpperCase() === item[0];
                }
                return true;
            });
        })
    },

    custom: function(getMessage, validate, severity = SEVERITY.ALERT, icon = 'fas fa-flag') {
        return {
            getMessage,
            severity,
            errorType: ERRORTYPE.ERROR,
            icon,
            type: 'custom',
            validate
        };
    },

    customWarning: function(getMessage, validate, severity = SEVERITY.WARNING, icon = 'fas fa-flag') {
        return {
            getMessage,
            severity,
            errorType: ERRORTYPE.WARNING,
            icon,
            type: 'customWarning',
            validate
        };
    },

    date: {
        getMessage: label => `${label} is not a date`,
        severity: SEVERITY.ALERT,
        type: 'date',
        errorType: ERRORTYPE.ERROR,
        icon: 'fas fa-flag',
        validate: SkipIfEmpty(value => {
            return Moment(value).isValid();
        })
    },

    email: {
        getMessage: label => `${label} is not an email`,
        severity: SEVERITY.ALERT,
        type: 'email',
        errorType: ERRORTYPE.ERROR,
        icon: 'fas fa-flag',
        validate: SkipIfEmpty(value => {
            //let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            // this is "official" regex for emails.
            // but according to wbtv's specs x@x.x should be valid
            // (that's why I changed it to support one letter top domains)
            let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;

            return re.test(value);
        })
    },

    excessiveCapitalization: {
        getMessage: label => `${label} may contain excessive capitalization`,
        severity: SEVERITY.WARNING,
        type: 'excessiveCapitalization',
        errorType: ERRORTYPE.WARNING,
        icon: 'fas fa-flag',
        validate: SkipIfEmpty(value => {
            let previous = false;
            for (let i=0; i < value.length; i++) {
                if (isUpperCase(value.charAt(i))) {
                    if (previous) {
                        return false;
                    }
                    previous = true;
                } else {
                    previous = false;
                }
            }
            return true;
        })
    },

    hexadecimal: {
        getMessage: (label) =>{
            return `${label} must be an hexadecimal value (letters [a-f], numbers [0-9]) and length must be 3 or 6 characters`;
        },
        severity: SEVERITY.ALERT,
        type: 'hexadecimal',
        errorType: ERRORTYPE.ERROR,
        icon: 'fas fa-flag',
        validate: (value) => {
            if (!value) {
                return true;
            }

            const regexHexadecimal = /^([0-9A-Fa-f]{6}|[0-9A-Fa-f]{3})$/;
            return regexHexadecimal.test(value);
        }
    },

    info: (getMessage, validate, severity = SEVERITY.WARNING) => {
        return {
            getMessage,
            severity,
            type: 'customWarning',
            errorType: ERRORTYPE.INFO,
            icon: 'fas fa-info-circle',
            validate
        };
    },

    max: (n, type = 'string', icon = 'fas fa-flag') => {
        return {
            getMessage: label => `${label} length is larger than the allowed value (${n})`,
            severity: SEVERITY.ALERT,
            errorType: ERRORTYPE.ERROR,
            icon: icon,
            type: 'max',
            validate: SkipIfEmpty(value => {
                if (type === 'string') {
                    value = value.toString().length;
                }
                return value <= n;
            })
        };
    },

    min: function(n, type) {
        if (!type) {
            type = 'string';
        }
        return {
            getMessage: label => `${label} length is smaller than the allowed value (${n})`,
            severity: SEVERITY.ALERT,
            type: 'min',
            errorType: ERRORTYPE.ERROR,
            icon: 'fas fa-flag',
            validate: SkipIfEmpty(value => {
                if (type === 'string') {
                    value = value.toString().length;
                }
                return value >= n;
            })
        };
    },

    missing: (icon = 'fas fa-flag') => {
        return {
            getMessage: (label) => `Missing ${label}`,
            severity: SEVERITY.ALERT,
            errorType: ERRORTYPE.ERROR,
            icon: icon,
            type: 'missing',
            validate: function(value) {
                if (Array.isArray(value)) {
                    return !!value.length;
                }
                if (value && value.toJS && value.isEmpty && value.isEmpty()) {
                    return false;
                }
                return ['', null, undefined].indexOf(value) === -1;
            }
        };
    },

    missingIf: (condition, icon = 'fas fa-flag') => {
        return {
            getMessage: (label) => `Missing ${label}`,
            severity: SEVERITY.ALERT,
            type: 'missing',
            errorType: ERRORTYPE.ERROR,
            icon: icon,
            validate: function(value) {
                // If condition is truthy, then validate the value
                // using the standard required validation.
                if (condition()) {
                    return Validations.missing(icon).validate(value);
                }

                // If not, return true by default (value is valid).
                return true;
            }
        };
    },

    noIllegalCharacters: {
        getMessage: label => `${label} contains illegal characters`,
        severity: SEVERITY.ALERT,
        errorType: ERRORTYPE.ERROR,
        icon: 'fas fa-flag',
        type: 'noIllegalCharacters',
        validate: SkipIfEmpty(value => {
            let reSymbols = /[<|>]/;
            return value.search(reSymbols) === -1;
        })
    },

    noSymbols: {
        getMessage: label => `${label} must not contain symbols`,
        severity: SEVERITY.ALERT,
        type: 'noSymbols',
        errorType: ERRORTYPE.ERROR,
        icon: 'fas fa-flag',
        validate: SkipIfEmpty(value => {
            let reSymbols = /[!@#$%^&*()+=|\\<>/?]/;
            return value.search(reSymbols) === -1;
        })
    },

    onlySpaces: {
        getMessage: label => `${label} must contain only spaces as whitespace`,
        severity: SEVERITY.ALERT,
        type: 'onlySpaces',
        errorType: ERRORTYPE.ERROR,
        icon: 'fas fa-flag',
        validate: SkipIfEmpty(value => {
            let reBlanks = /[\t|\r|\n]/;
            return value.search(reBlanks) === -1;
        })
    },

    passwordHasUppercase: {
        getMessage: function(label) {
            return `${label} must contain at least 1 UPPERCASE letter`;
        },
        severity: SEVERITY.ALERT,
        type: 'password',
        errorType: ERRORTYPE.ERROR,
        icon: 'fas fa-flag',
        validate: function(value) {

            let re = /^(?=.*[A-Z])/;
            return re.test(value);
        }
    },

    passwordHasLowercase: {
        getMessage: function(label) {
            return `${label} must contain at least 1 lowercase letter`;
        },
        severity: SEVERITY.ALERT,
        type: 'password',
        errorType: ERRORTYPE.ERROR,
        icon: 'fas fa-flag',
        validate: function(value) {

            let re = /^(?=.*[a-z])/;
            return re.test(value);
        }
    },

    passwordHasNumberAndSymbol: {
        getMessage: function(label) {
            return `${label} must contain at least 1 number and 1 allowed symbol ! @ # $ % * &`;
        },
        severity: SEVERITY.ALERT,
        type: 'password',
        errorType: ERRORTYPE.ERROR,
        icon: 'fas fa-flag',
        validate: function(value) {
            const reSymbolValid = /^(?=.*[!#$%@*&])/;
            const reSymbolInvalid = /^(?=.*[-^()_+ |~=`{}[\]:";'<>?,./ ])/;
            const reNumber = /^(?=.*[\d])/;
            const reAlpha = /^(?=.*[a-zA-Z])/;
            return reSymbolValid.test(value) && reNumber.test(value) && reAlpha.test(value) && !reSymbolInvalid.test(value);
        }
    },

    passwordHasNumberOrSymbol: {
        getMessage: function(label) {
            return `${label} must contain at least 1 number or symbol`;
        },
        severity: SEVERITY.ALERT,
        type: 'password',
        errorType: ERRORTYPE.ERROR,
        icon: 'fas fa-flag',
        validate: function(value) {

            let re = /^(?=.*[-\d!@#$%^&*()_+ |~=`{}[\]:";'<>?,./])/;
            return re.test(value);
        }
    },

    passwordLength: {
        getMessage: function(label) {
            return `${label} must contain 12 to 20 characters`;
        },
        severity: SEVERITY.ALERT,
        type: 'password',
        errorType: ERRORTYPE.ERROR,
        icon: 'fas fa-flag',
        validate: function(value) {
            return value.length >= 12 && value.length <= 20;
        }
    },

    passwordDoesNotContain: (words) => {
        return {
            getMessage: label => `${label} must not contain your first name, last name or username`,
            severity: SEVERITY.ALERT,
            type: 'password',
            errorType: ERRORTYPE.ERROR,
            icon: 'fas fa-flag',
            validate: SkipIfEmpty(value => {
                if (!words) {return true;}

                return words.map(w => w.trim()).every(w => value.search(new RegExp(w, 'i')) === -1);
            })
        };
    },

    passwordNotCommon: {
        getMessage: function(label) {
            return `${label} must not contain commonly used passwords`;
        },
        severity: SEVERITY.ALERT,
        type: 'password',
        errorType: ERRORTYPE.ERROR,
        icon: 'fas fa-flag',
        validate: SkipIfEmpty(value => {
            return CommonPasswords.every(p => {
                return value.search(new RegExp(p, 'i')) === -1;
            });
        })
    },

    promise: function(getPendingMessage, getErrorMessage, validate) {
        let initValue;
        let lastValidatedValue;
        let promiseError;
        let valid = true;

        let getPromiseErrorMessage = () => promiseError.message;

        let validation = {
            getMessage: getPendingMessage,
            severity: SEVERITY.WARNING,
            type: 'promise',
            // force to revalidate async
            revalidate: (value) => {
                promiseError = null;

                let promise = validate(value)
                    .then(v => {
                        if (validation.promise !== promise) {
                            throw new ValidationCanceledError;
                        }

                        lastValidatedValue = value;
                        valid = v;
                        validation.promise = null;

                        return v;
                    })
                    .catch(err => {
                        if (err instanceof ValidationCanceledError) {
                            throw err;
                        }

                        promiseError = err;
                    });

                validation.promise = promise;

                return promise;
            },
            validate: (value, validatedValue) => {
                // waiting for initialize the value
                if (initValue === undefined) {
                    if (validatedValue === undefined) {
                        return true;
                    }

                    initValue = validatedValue;
                }

                // is the initial value
                if (initValue === value) {
                    return true;
                }

                // if the promise throws an error, it shows a warning
                if (promiseError) {
                    validation.getMessage = getPromiseErrorMessage;
                    return false;
                }

                // in alerts-warnings hasn't the validatedValue when call to Validations.validate
                // use instead the last from FormItem.getValidationState
                if (validatedValue === undefined) {
                    validatedValue = lastValidatedValue;
                }
                lastValidatedValue = validatedValue;

                // the value has been validated
                if (lastValidatedValue === value) {
                    validation.getMessage = getErrorMessage;
                    return valid;
                }

                validation.getMessage = getPendingMessage;
                return false;
            }
        };

        return validation;
    },

    /**
     * Required rule
     */
    required: {
        getMessage: () => 'Field is required',
        severity: SEVERITY.ALERT,
        type: 'required',
        errorType: ERRORTYPE.ERROR,
        icon: 'fas fa-flag',
        validate: function(value) {
            if (Array.isArray(value)) {
                return !!value.length;
            }
            if (value && value.toJS && value.isEmpty && value.isEmpty()) {
                return false;
            }
            return ['', null, undefined].indexOf(value) === -1;
        }
    },

    responsibilities: {
        getMessage: label => `${label} is not in the current list of standard values`,
        severity: SEVERITY.WARNING,
        type: 'responsibilities',
        validate: SkipIfEmpty(value => {
            return ['Acquisitions',
                'Sales',
                'Programming',
                'Marketing',
                'Publicity',
                'Social Media',
                'Digital Media',
                'Press',
                'Traffic Coordinator',
                'Technical Operations',
                'Standards and Practices',
                'Dubbing',
                'Metadata Acquisition',
                'Research',
                'Distribution',
                'Program Development',
                'Rights',
                'Finance',
                'Legal',
                'Contract Administration',
                'Human Resources',
                'Information Technology',
                'Producer',
                'Administration',
                'Business Development'
            ].indexOf(value) !== -1;
        })
    },

    /**
     * RequiredIf rule.
     *
     * The value will be required only if the result of condition is true.
     */
    requiredIf: function(condition) {
        return {
            getMessage: noop,
            severity: SEVERITY.ALERT,
            type: 'required',
            errorType: ERRORTYPE.ERROR,
            icon: 'fas fa-flag',
            validate: function(value) {
                // If condition is truthy, then validate the value
                // using the standard required validation.
                if (condition()) {
                    return Validations.required.validate(value);
                }

                // If not, return true by default (value is valid).
                return true;
            }
        };
    },

    stringMatch: (getMessage, stringValue) => {
        return {
            getMessage,
            severity: SEVERITY.ALERT,
            type: 'stringMatch',
            validate: function(value) {
                return stringValue().toString() === value;
            }
        };
    },

    timecode: {
        getMessage: label => `${label} is not a valid timecode (hh:mm:ss:ff)`,
        severity: SEVERITY.ALERT,
        type: 'timecode',
        errorType: ERRORTYPE.ERROR,
        icon: 'fas fa-flag',
        validate: SkipIfEmpty(value => {
            if (typeof value !== 'string') {
                value = TimecodeToString(value);
            }
            return /^(\d{2,}[:])([0-5][0-9][:]){2}\d{2,}$/.test(value);
        })
    },

    url: {
        getMessage: label => `${label} is not a valid URL`,
        severity: SEVERITY.ALERT,
        type: 'url',
        errorType: ERRORTYPE.ERROR,
        icon: 'fas fa-flag',
        validate: SkipIfEmpty(value => {
            // source: Regular Expression for URL validation - https://gist.github.com/dperini/729294
            // featured also in "In search of the perfect URL validation regex" - https://mathiasbynens.be/demo/url-regex
            let re = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i;
            return re.test(value);
        })
    },

    /**
     * warnOnTrue.
     *
     * Will rise warningMessage if passed value is true.
     */
    warnOnTrue: {
        getMessage: function(message) {
            return message;
        },
        severity: SEVERITY.WARNING,
        type: 'warnOnTrue',
        errorType: ERRORTYPE.ERROR,
        icon: 'fas fa-flag',
        validate: SkipIfEmpty(value => {
            return !value;
        })
    },
    /**
     * Validate a model against a set of validation rules
     *
     * @return [Errors] array of errors
     */
    validate: function(model, validationRules, instantFeedback = false, ignoreWarnings = false) {
        let errors = [];

        /**
         * Iterate the validationRules object. This object contains
         * a set of validations for each attribute we want to check.
         * E.g.: {
         *     name: {validations: [required, max(200)]}
         * }
         */
        Object.keys(validationRules).forEach(attrName => {
            // Get the value we want to check.
            const value = model.getIn(attrName.split('.'));

            // Get optional fn if we do not want to check validations in some specific cases
            const isOptional = validationRules[attrName].isOptional;
            const skipRule = isOptional && isOptional();

            if (skipRule) {
                return errors;
            }

            // Get the array of rules and iterate it.
            const rules = validationRules[attrName].validations;
            if (rules) {
                rules.forEach(r => {
                    // For each rule, check if the value is ok.
                    const {severity, type} = r;

                    // Does value pass? true|false
                    let valid = r.validate(value);

                    // Work around for promise based validations on title create - add new related title
                    if (r.type === 'promise' && !r.promise && !value) {valid = true;}

                    let message = enUS[validationRules[attrName].message];
                    if (validationRules[attrName].label) {
                        message = enUS[validationRules[attrName].label];
                    }

                    if (!valid) {
                        if (r.severity === SEVERITY.WARNING && ignoreWarnings) {
                            return;
                        }

                        return errors.push({
                            message: r.getMessage(message),
                            errorType: r.errorType,
                            icon: r.icon,
                            type,
                            severity
                        });
                    }

                    // Getting here means the rule passed, now check if caller
                    // requested instant feedback as you type
                    // (like the password reset page)
                    if (valid && instantFeedback) {
                        errors.push({
                            message: r.getMessage(message),
                            valid,
                            errorType: r.errorType,
                            icon: r.icon,
                            severity,
                            type
                        });

                        return;
                    }
                });
            } else {
                if (value) {
                    if (value.map && Object.prototype.toString.apply(value.map) === '[object Function]') {
                        value.forEach(singleValue => {
                            errors = errors.concat(this.validate(singleValue, validationRules[attrName]));
                        });
                        return errors;
                    }
                    return errors.concat(this.validate(value, validationRules[attrName]));
                }
            }
        });

        return errors;
    },

    asWarning: (v) => {
        return Object.assign({}, v, {errorType: ERRORTYPE.WARNING, severity: SEVERITY.WARNING});
    }
};

const WithValidations = Base => {
    return class WithValidationsImpl extends Base {
        static get displayName() {
            return `WithValidations(${Base.displayName})`;
        }

        /**
         * Get validation state from [model]
         */
        getAccordionValidationState(model, attr, modelValidationRules) {
            return ValidationApi.GetAccordionValidationState(model, attr, modelValidationRules);
        }

        getValidationState(value, isDirty, validations, validatedValue) {
            return ValidationApi.GetValidationState(value, isDirty, validations, validatedValue);
        }

        getValidationError(value, isDirty, validations, validatedValue) {
            return ValidationApi.GetValidationError(value, isDirty, validations, validatedValue);
        }

        waitForValidations(value, isDirty, validations) {
            return ValidationApi.WaitForValidations(value, isDirty, validations);
        }
    };
};

export default Validations;
export {
    SEVERITY,
    ERRORTYPE,
    WithValidations
};
