/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

// import {ActionHistoryConstants} from '../system/action-history/action-history-actions';
import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {HomescreenConstants} from './homescreen-actions';
import Validations from '../common/validations/validations';
import Dispatcher from '../dispatcher/dispatcher';

const HomescreenValidations = {
    name: {
        validations: [Validations.required]
    }
};

class HomescreenStore extends ReduceStore {
    getInitialState() {
        let state = Immutable.Map({
            homescreen: Immutable.fromJS({
                active: true,
                billboard: undefined,
                groups:[],
                history:[],
                partners: Immutable.OrderedSet(),
                publishingLists: []
            }),
            homescreens: Immutable.List(),
            offset: 0,
            size: 20,
            total: 0,
        });
        state = state.set('originalHomescreen', state.get('homescreen'));
        return state;
    }

    getValidations() {
        return Validations.validate(this.getState().get('homescreen'), HomescreenValidations);
    }

    reduce(state, action) {
        switch (action.actionType) {
        case HomescreenConstants.CLEAR:
            state = this.getInitialState();
            break;

        case HomescreenConstants.FIND_BY_ID.SUCCESS:
            state = state.merge({
                homescreen: action.homescreen
            });
            state = state.set('originalHomescreen', state.get('homescreen'));
            break;

        case HomescreenConstants.GET.SUCCESS:
            state = state.merge({
                homescreens: action.homescreens
            });
            break;

        case HomescreenConstants.PUBLISHING_LIST.ADD:
            state = state.updateIn(['homescreen', 'publishingLists'], pl => pl.push(Immutable.fromJS({
                publishingList: {
                    items:[]
                }
            })));
            break;

        case HomescreenConstants.PUBLISHING_LIST.REMOVE:
            state = state.deleteIn(['homescreen', 'publishingLists', action.section]);
            break;

        case HomescreenConstants.SAVE.SUCCESS:
            state = state.set('originalHomescreen', state.get('homescreen'));
            break;

        case HomescreenConstants.UPDATE:
            state = state.setIn(['homescreen', ...action.attr.split('.')], action.value);
            break;

        case HomescreenConstants.ITEMS.GET.SUCCESS:
            state = state.setIn(['homescreen', ...(action.section.split('.')), 'publishingList', 'items'], action.items);
            break;

        case HomescreenConstants.USER_GROUPS.GET.SUCCESS:
            state = state.update('homescreens', homescreens => {
                return homescreens.map( h => {
                    if (h.get('id') === action.homeScreenId) {
                        return h.set('userGroups', action.groups);
                    }
                    return h;
                });
            });
            break;

        case HomescreenConstants.FILTER.CLEAR:
            state = state.set('filters', Immutable.Map());
            break;

        case HomescreenConstants.FILTER.SET:
            state = state.setIn(['filters', ...action.attr.split('.')], action.value);
            break;
        }
        return state;
    }
}

let store = new HomescreenStore(Dispatcher);

export default store;

export {HomescreenValidations};
