/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';

import {NotificationActions} from '../common/notification/notification-actions';
import Dispatcher from '../dispatcher/dispatcher';
import Request from '../request';
import {RouterActions} from '../router/router-actions';
import {GroupConstants} from '../security/group/group-actions';
import {ActionHistoryConstants} from '../system/action-history/action-history-actions';

const CONSTANTS = {
    BATCH_TYPES: {
        DOCUMENT: 'DOCUMENT',
        IMAGE: 'IMAGE',
        TITLE: 'TITLE',
        VIDEO: 'VIDEO'
    },
    BATCH_ACTIVE_TYPES: {
        ACTIVE: {id: 1, name: 'Set to Active'},
        INACTIVE: {id: 0, name: 'Set to Inactive'},
        NO_CHANGE: {id: 2, name: 'Do not change'}, // Psuedo type used only client side, synonymous with active `null`
    },
    BATCH_VIDEO_MFA_OPTIONS: {
        ENABLED: {id: true, name: 'Enable MFA'},
        DISABLED: {id: false, name: 'Disable MFA'},
        NO_CHANGE: {id: null, name: 'Do not change'}
    },
    BATCHES: {
        GET: {
            ERROR: 'scheduling.batches.get.error',
            START: 'scheduling.batches.get.start',
            SUCCESS: 'scheduling.batches.get.success'
        },
    },
    CATALOGS: {
        ADD: 'scheduling.catalogs.add',
        GET: {
            ERROR: 'scheduling.catalogs.get.error',
            START: 'scheduling.catalogs.get.start',
            SUCCESS: 'scheduling.catalogs.get.success',
        },
        REMOVE: 'scheduling.catalogs.remove'
    },
    DUPLICATE: {
        CLEAR: 'scheduling.batch.duplicate.clear',
        SAVE: {
            ERROR: 'scheduling.batch.duplicate.save.error',
            SUCCESS: 'scheduling.batch.duplicate.save.success',
            START: 'scheduling.batch.duplicate.save.start',
        },
        SET: {
            ERROR: 'scheduling.batch.duplicate.set.error',
            SUCCESS: 'scheduling.batch.duplicate.set.success',
            START: 'scheduling.batch.duplicate.set.start',
        },
        UPDATE_ATTR: 'scheduling.batch.duplicate.update.attribute',
    },
    CLEAR: 'scheduling.clear',
    FILTER: {
        CLEAR: 'scheduling.filter.clear',
        SET: 'scheduling.filter.set'
    },
    FIND_BY_ID: {
        ERROR: 'scheduling.find-by-id.error',
        START: 'scheduling.find-by-id.start',
        SUCCESS: 'scheduling.find-by-id.success'
    },
    ITEMS: { // images, documents, videos or titles on a batch
        ADD: 'scheduling.batches.items.add',
        DELETE: 'scheduling.batches.items.delete',
        SET: 'scheduling.batches.items.set',
        ITEM: {
            ADD_CHILDREN: {
                ERROR: 'scheduling.batches.items.add-children.error',
                START: 'scheduling.batches.items.add-children.start'
            },
            ADD_CHILDREN_MODAL: {
                TOGGLE: 'scheduling.batches.items.add-children-modal.toggle',
            },
            DETAIL_MODAL: {
                TOGGLE: 'scheduling.batches.items.detail-modal.toggle',
            },
            SET: 'scheduling.batches.items.item.set'
        }
    },
    SAVE: {
        ERROR: 'scheduling.save.error',
        START: 'scheduling.save.start',
        SUCCESS: 'scheduling.save.success',
    },
    STATUS: {
        PENDING: {id: 0, name: 'Pending'},
        COMPLETED: {id: 1, name: 'Completed'},
        ALL: {id: 2, name: 'All'}
    },
    TITLE_SYNOPSIS_LOG_LINE_DISPLAY_TYPE: {
        NO_CHANGE: {id: 'NoChange', name: 'No Change'},
        HIDDEN: {id: 'SetHidden', name: 'Set to Hidden'},
        PUBLISHED: {id: 'SetPublished', name: 'Set to Published'}
    },
    UPDATE: 'scheduling.update',
    USERGROUPS: {
        SET: 'scheduling.batches.usergroups.set',
    },
    EMAIL_GROUPS: {
        EXTERNAL: {
            SET: 'scheduling.email-groups.external.set'
        },
        INTERNAL: {
            SET: 'scheduling.email-groups.internal.set'
        },
    },

    toArray: function(constant) {
        return Object.keys(this[constant])
            .map(k => this[constant][k])
            .sort((a, b) => a.name.localeCompare(b.name));
    }
};

class BatchActions {

    addCatalog(catalog) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CATALOGS.ADD,
            catalog: Immutable.fromJS({groupId: catalog.get('id')})
        });
    }

    addChildren(ids) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ITEMS.ITEM.ADD_CHILDREN.START
        });
        Promise.all(ids.map(t => Request.get(`title/${t}`).exec()))
            .then(titlesRes => {
                const titles = titlesRes.map(titleRes => titleRes.body);
                Dispatcher.dispatch({
                    actionType: CONSTANTS.ITEMS.ADD,
                    items: Immutable.fromJS(titles)
                });
                return;
            }).catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.ITEMS.ITEM.ADD_CHILDREN.ERROR
                });
                NotificationActions.showAlertDanger('scheduling.batch.items-list.get-children.error');
                throw err;
            });

        return;
    }

    addItems(items) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ITEMS.ADD,
            items: Immutable.fromJS(items)
        });
    }

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
    }

    /**
     * Delete duplicated batch from the store after user closes modal.
    */
    cleanDuplicate() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.DUPLICATE.CLEAR
        });
    }

    clearFilter() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.FILTER.CLEAR
        });
    }

    deleteItem(item) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ITEMS.DELETE,
            item
        });
    }

    findById(id) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.FIND_BY_ID.START
        });
        const batchPromise = Request.get(`schedule-batch-update/${id}`).exec().then(r => r).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.FIND_BY_ID.ERROR
            });
            switch (err.status) {
            case 404:
                NotificationActions.showAlertDanger('scheduling.batch.find-by-id.error.does-not-have-access');
                break;
            default:
                NotificationActions.showAlertDanger('common.load-error');
                break;
            }
            throw err;
        });
        const actionHistoryQuery = {
            'action-object': ActionHistoryConstants.ACTION_OBJECTS.SCHEDULEBATCH,
            'object-id': id,
            offset: 0,
            size: 4
        };
        const historyPromise = Request.get('system/action-history').query(actionHistoryQuery).exec().then(r => r).catch(err => {
            NotificationActions.showAlertDanger('scheduling.batch.find-by-id.history.error');
            throw err;
        });

        Promise.all([
            batchPromise, historyPromise
        ]).spread((batchRes, historyRes) => {
            let history = historyRes.body.results;
            history.sort((h1, h2) => h2.actionDate.localeCompare(h1.actionDate));

            // Workaround to transform batchRes.body.active value when null to "Do not change" (id === 2)
            if (batchRes.body.active !== CONSTANTS.BATCH_ACTIVE_TYPES.ACTIVE.id && batchRes.body.active !== CONSTANTS.BATCH_ACTIVE_TYPES.INACTIVE.id) {
                batchRes.body.active = CONSTANTS.BATCH_ACTIVE_TYPES.NO_CHANGE.id;
            }

            Dispatcher.dispatch({
                actionType: CONSTANTS.FIND_BY_ID.SUCCESS,
                batch: Immutable.fromJS(batchRes.body),
                history: Immutable.fromJS(history)
            });

            const {scheduleBatchType, assets, titles, emailGroups} = batchRes.body;
            let itemsList;
            switch (scheduleBatchType.toUpperCase()) {
            case CONSTANTS.BATCH_TYPES.DOCUMENT:
            case CONSTANTS.BATCH_TYPES.IMAGE:
            case CONSTANTS.BATCH_TYPES.VIDEO:
                itemsList = assets;
                break;
            case CONSTANTS.BATCH_TYPES.TITLE:
                itemsList = titles;
                break;
            }
            if (itemsList.length) {
                this.getItemsData(scheduleBatchType, itemsList);
            }

            if (emailGroups.length) {
                this.getEmailGroupsData(emailGroups);
            }

            return;
        }).catch(err => {
            throw err;
        });

        return;
    }

    get(batchType, queryParams, offset, size) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.BATCHES.GET.START
        });

        queryParams = queryParams.toJS();
        queryParams['batch-type'] = batchType;
        offset = offset || queryParams.offset || 0;
        size = size || 20;
        queryParams.offset = offset ;
        queryParams.size = size;

        Request.get('schedule-batch-update')
            .query(
                queryParams
            ).exec()
            .then(response => {
                const batches = Immutable.fromJS(response.body.results);
                Dispatcher.dispatch({
                    actionType: CONSTANTS.BATCHES.GET.SUCCESS,
                    batches,
                    offset: response.body.offset,
                    size: response.body.size,
                    total: response.header['wbtv-total-count'],
                });
                return;
            }).catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.BATCHES.GET.ERROR
                });

                switch (err.status) {
                case 404:
                    NotificationActions.showAlertDanger('scheduling.batches.get.error.does-not-have-access', batchType);
                    break;
                default:
                    NotificationActions.showAlertDanger('scheduling.batches.get.error.does-not-load', batchType);
                    break;
                }

                throw err;
            });

        return;
    }

    getCatalogs(type) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CATALOGS.GET.START
        });

        // set ASSET as default category
        let category = GroupConstants.ASSET.categoryId;
        if (type === CONSTANTS.BATCH_TYPES.TITLE) {
            category = GroupConstants.TITLE.categoryId;
        }

        Request.get('security/group')
            .query({
                active: true,
                'category-id': category,
                offset: 0,
                size: 9999,
                'sort-direction': 'asc',
                'sort-field-name': 'name',
            })
            .exec()
            .then((res) => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.CATALOGS.GET.SUCCESS,
                    catalogs: Immutable.fromJS(res.body.results)
                });
            })
            .catch((err) => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.CATALOGS.GET.ERROR
                });
                NotificationActions.showAlertDanger('scheduling.batch.catalogs.get.error');
                throw err;
            });
    }

    getItemsData(scheduleBatchType, itemsList) {
        const batchType = scheduleBatchType.toLowerCase();

        let itemsRequests;
        switch (scheduleBatchType) {
        case CONSTANTS.BATCH_TYPES.DOCUMENT:
        case CONSTANTS.BATCH_TYPES.IMAGE:
        case CONSTANTS.BATCH_TYPES.VIDEO:
            itemsRequests = itemsList.map(item => {
                return Request.get(`asset/${batchType}/${item.mediaAssetId}`).exec().catch(err => {
                    NotificationActions.showAlertDanger(`scheduling.batch.items-list.get-${scheduleBatchType.toLowerCase()}s.error`);
                    throw err;
                });
            });
            break;
        case CONSTANTS.BATCH_TYPES.TITLE:
            itemsRequests = itemsList.map(item => {
                return Request.get(`title/${item.titleId}`).exec().catch(err => {
                    NotificationActions.showAlertDanger(`scheduling.batch.items-list.get-${scheduleBatchType.toLowerCase()}s.error`);
                    throw err;
                });
            });
            break;
        }

        Promise.all(itemsRequests)
            .then(itemsRes => {
                const items = itemsRes.map(itemRes => itemRes.body);
                Dispatcher.dispatch({
                    actionType: CONSTANTS.ITEMS.SET,
                    items: Immutable.fromJS(items)
                });

                return;

            }).catch(err => {
                throw err;
            });

        return;
    }

    getEmailGroupsData(emailGroupsList = []) {
        let userGroupsRequests = emailGroupsList.map(group => {
            return Request.get(`security/group/${group.groupId}`).exec().catch(err => {
                NotificationActions.showAlertDanger('scheduling.batch.summary.email.get-user-groups.error');
                throw err;
            });
        });

        Promise.all(userGroupsRequests)
            .then(groupsRes => {
                const userGroups = groupsRes.map(groupRes => groupRes.body);

                Dispatcher.dispatch({
                    actionType: CONSTANTS.USERGROUPS.SET,
                    groups: Immutable.fromJS(userGroups)
                });

                return;

            }).catch(err => {
                throw err;
            });

        return;
    }

    removeCatalog(index) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CATALOGS.REMOVE,
            index: index
        });
    }

    save(type, batch, itemsList) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SAVE.START,
        });

        let batchData = batch.toJS();

        // Workaround to transform batchData.active value when "Do not change" (id === 2)
        if (batchData.active === CONSTANTS.BATCH_ACTIVE_TYPES.NO_CHANGE.id) {
            batchData.active = null;
        } else {
            if (batchData.active) {
                batchData.active = CONSTANTS.BATCH_ACTIVE_TYPES.ACTIVE.id;
            } else {
                batchData.active = CONSTANTS.BATCH_ACTIVE_TYPES.INACTIVE.id;
            }
        }

        if (!batchData.scheduleBatchType) {
            batchData.scheduleBatchType = CONSTANTS.BATCH_TYPES[type.toUpperCase()];
        }

        let isPost = true;
        let method = Request.post;
        let uri = 'schedule-batch-update';
        // Check if PUT.
        let id = batchData.id;
        if (id !== undefined) {
            isPost = false;
            method = Request.put;
            uri = `schedule-batch-update/${id}`;
        }

        if (itemsList) {
            let itemsListProp, itemsListPropId;
            let items = itemsList.toJS();
            switch (type.toUpperCase()) {
            case CONSTANTS.BATCH_TYPES.DOCUMENT:
            case CONSTANTS.BATCH_TYPES.IMAGE:
            case CONSTANTS.BATCH_TYPES.VIDEO:
                itemsListPropId = 'mediaAssetId';
                itemsListProp = 'assets';
                break;
            case CONSTANTS.BATCH_TYPES.TITLE:
                itemsListPropId = 'titleId';
                itemsListProp = 'titles';
                break;
            }
            batchData[itemsListProp] = items.map(item => ({scheduleBatchId: id, [itemsListPropId]: item.id}));
        }

        method(uri)
            .send(batchData)
            .exec()
            .then(res => {
                // Workaround to transform batchRes.body.active value when null to "Do not change" (id === 2)
                if (res.body.active !== CONSTANTS.BATCH_ACTIVE_TYPES.ACTIVE.id && res.body.active !== CONSTANTS.BATCH_ACTIVE_TYPES.INACTIVE.id) {
                    res.body.active = CONSTANTS.BATCH_ACTIVE_TYPES.NO_CHANGE.id;
                }

                Dispatcher.dispatch({
                    actionType: CONSTANTS.SAVE.SUCCESS,
                    batch: Immutable.fromJS(res.body)
                });

                if (itemsList) {
                    const {scheduleBatchType, assets, titles} = res.body;
                    let items;
                    switch (type.toUpperCase()) {
                    case CONSTANTS.BATCH_TYPES.DOCUMENT:
                    case CONSTANTS.BATCH_TYPES.IMAGE:
                    case CONSTANTS.BATCH_TYPES.VIDEO:
                        items = assets;
                        break;
                    case CONSTANTS.BATCH_TYPES.TITLE:
                        items = titles;
                        break;
                    }
                    this.getItemsData(scheduleBatchType, items);
                }

                if (isPost) {
                    RouterActions.redirect(`/scheduling/${type}/${res.body.id}`, true);
                }
                NotificationActions.showAlertSuccess(`scheduling.batch.${type}.save.success`);
                return;
            })
            .catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.SAVE.ERROR,
                    error: err,
                });
                NotificationActions.showAlertDanger(`scheduling.batch.${type}.save.error`);
                throw err;
            });

        return;
    }

    /**
     * Save duplicatedBatch batch and redirect to new tab
     * @param {Immutable.Map} batch
     */
    saveDuplicatedBatch(batch) {
        let batchData = batch.toJS();
        let type = batchData.scheduleBatchType.toLowerCase();

        // Workaround to transform batchData.active value when "Do not change" (id === 2)
        if (batchData.active === CONSTANTS.BATCH_ACTIVE_TYPES.NO_CHANGE.id) {
            batchData.active = null;
        } else {
            if (batchData.active) {
                batchData.active = CONSTANTS.BATCH_ACTIVE_TYPES.ACTIVE.id;
            } else {
                batchData.active = CONSTANTS.BATCH_ACTIVE_TYPES.INACTIVE.id;
            }
        }

        Dispatcher.dispatch({
            actionType: CONSTANTS.DUPLICATE.SAVE.START,
        });

        Request.post('schedule-batch-update')
            .send(batchData).exec()
            .then(res => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.DUPLICATE.SAVE.SUCCESS,
                });

                NotificationActions.showAlertSuccess('scheduling.batch.duplicate.save.success');
                window.open(`/scheduling/${type}/${res.body.id}`);
                return;
            })
            .catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.DUPLICATE.SAVE.ERROR,
                    error: err,
                });
                NotificationActions.showAlertDanger('scheduling.batch.duplicate.save.error');
                throw err;
            });

        return;
    }

    /**
     * Set a copy of batch and save on batchDuplicated on store
     * where its batchName must follow next format: it should increment to the next available value,
     * i.e. Look up all Scheduling Batches that share the Source Name and increment the greatest
     * value among them.
     * @param {Immutable.Map} batch
     */
    setDuplicate(batch) {
        batch = batch.toJS();
        delete batch.id;
        const queryParams = {
            'batch-name': batch.batchName,
            'sort-direction': 'DESC',
            'batch-type': batch.scheduleBatchType,
            offset: 0,
            size: 200,
        };

        // Delete execution data if the batch has been already executed
        if (batch.executedAt) {
            ['executedAt', 'executionDate', 'executionErrors', 'executionTime'].forEach(p => delete batch[p]);
        }

        Dispatcher.dispatch({
            actionType: CONSTANTS.DUPLICATE.SET.START,
        });
        Request.get('schedule-batch-update')
            .query(queryParams).exec()
            .then(response => {
                const batches = response.body.results;

                let greaterNumber = batches.reduce((greater, b) => {
                    const endOfName = b.batchName.slice(batch.batchName.length);
                    const number = parseInt((endOfName.match(/\d+$/) || []).pop()); // get number at the end of string
                    return Math.max(number || 0, greater);
                }, -1);

                const nextNumber = Math.max(++greaterNumber, 1);
                batch.batchName = `${batch.batchName} ${nextNumber}`;

                Dispatcher.dispatch({
                    actionType: CONSTANTS.DUPLICATE.SET.SUCCESS,
                    batch: Immutable.fromJS(batch)
                });

                return;
            }).catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.DUPLICATE.SET.ERROR
                });

                switch (err.status) {
                case 404:
                    NotificationActions.showAlertDanger('scheduling.batches.get.error.does-not-have-access', batch.scheduleBatchType);
                    break;
                default:
                    NotificationActions.showAlertDanger('scheduling.batches.get.error.does-not-load', batch.scheduleBatchType);
                    break;
                }

                throw err;
            });

        return;
    }

    setFilter(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.FILTER.SET,
            attr: attr,
            value: value
        });
    }

    setItemData(item) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ITEMS.ITEM.SET,
            item
        });
    }

    setEmailGroupsExternal(groups) {
        let selectedGroups = groups || Immutable.List();
        Dispatcher.dispatch({
            actionType: CONSTANTS.EMAIL_GROUPS.EXTERNAL.SET,
            selected: selectedGroups
        });

        return;
    }

    setEmailGroupsInternal(groups) {
        let selectedGroups = groups || Immutable.List();
        Dispatcher.dispatch({
            actionType: CONSTANTS.EMAIL_GROUPS.INTERNAL.SET,
            selected: selectedGroups
        });

        return;
    }

    toggleShowAddChildrenModal(title) {
        let selectedTitle = title || Immutable.Map();
        Dispatcher.dispatch({
            actionType: CONSTANTS.ITEMS.ITEM.ADD_CHILDREN_MODAL.TOGGLE,
            title: selectedTitle
        });
    }

    toggleShowItemDetail() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ITEMS.ITEM.DETAIL_MODAL.TOGGLE
        });
    }

    update(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.UPDATE,
            attr,
            value
        });

        return;
    }

    /**
     * Update batchDuplicated attr with value on store
     * @param {*} attr
     * @param {*} value
     */
    updateBatchDuplicated(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.DUPLICATE.UPDATE_ATTR,
            attr,
            value
        });

        return;
    }
}

let actions = new BatchActions();

export {
    actions as BatchActions,
    CONSTANTS as BatchConstants
};
