/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router';

import RootItem from './root-item.js';
import Star from './starred/star';

export default class Item extends React.Component {
    static get propTypes() {
        return {
            children: PropTypes.node.isRequired,
            className: PropTypes.string,
            href: PropTypes.string,
            iconClass: PropTypes.string,
            onClick: PropTypes.func,
            parentTitle: PropTypes.string,
            permission: PropTypes.string
        };
    }

    static get defaultProps() {
        return {
            className: '',
            href: undefined,
            iconClass: undefined,
            onClick: () => void 0,
            parentTitle: '',
            permission: undefined
        };
    }

    render() {
        const {href, iconClass, children, className, onClick, parentTitle} = this.props;

        let link = (
            <a className="Cur(p)">
                {iconClass && <RootItem iconClass={iconClass}/>}
                <span>{children}</span>
            </a>
        );

        if (href) {
            link = (<Link to={href}>
                {iconClass && <RootItem iconClass={iconClass}/>}
                <span>{children}</span>
            </Link>);
        }

        let childrenTitle = children;
        if (Array.isArray(children)) {
            childrenTitle = children.join('').trim();
        }
        let starredTitle = childrenTitle;
        if (parentTitle) {
            starredTitle = `${parentTitle?.trim()} - ${childrenTitle}`;
        }

        return (
            <li className={className} onClick={onClick}>
                {link}
                {href && <Star path={href} title={starredTitle} permission={this.props.permission}/>}
            </li>
        );
    }
}
