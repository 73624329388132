/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, ControlLabel, FormGroup} from 'react-bootstrap';
import {Link} from 'react-router';
import ReactSelect from 'react-select';

import {TargetTypeActions} from './target-type-actions';
import {FormSection, FormRow, FormItem} from '../../common/form/form';
import {GroupConstants} from '../../security/group/group-actions';
import GroupSelect from '../../security/group/group-select';
import {PushNotificationActions} from '../push-notification-actions';
import PushNotificationStore, {PushNotificationStoreValidations} from '../push-notification-store';

const messageBodyLimit = 160;
const messageTitleLimit = 20;

class Summary extends Component {
    static get propTypes() {
        return {
            disabled: PropTypes.bool,
            handleTargetClick: PropTypes.func.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true
        };
    }

    static calculateState() {
        const pns = PushNotificationStore.getState();
        return {
            pushNotification: pns.get('pushNotification'),
            pushNotificationAsset: pns.get('pushNotificationAsset'),
            pushNotificationTitle: pns.get('pushNotificationTitle'),
        };
    }

    static getStores() {
        return [PushNotificationStore];
    }

    constructor(props) {
        super(props);

        // TODO: move state to store. and connect to missing API
        this.state = Object.assign({
            selectedGroups: Immutable.OrderedSet(),
            showTargetButton: true
        }, this.constructor.calculateState());
    }

    handleCleanTarget() {
        PushNotificationActions.clearTarget();
        TargetTypeActions.clear();
    }

    handleSelectGroup(groups) {
        if (!groups) {
            // Reset the value.
            PushNotificationActions.updatePushNotification();
            return;
        }
        // Call the select action but with immutable objects.
        PushNotificationActions.updatePushNotification('userGroups', Immutable.fromJS(groups).toSet().sortBy(g => g.get('name')));
    }

    handleTinyMCEChange(attr) {
        return function handler(e) {
            PushNotificationActions.updatePushNotification(attr, e.target.getContent());
            return;
        };
    }

    render() {
        let target =(
            <Button bsStyle="primary" onClick={this.props.handleTargetClick}>
                <i className="fas fa-plus-square"></i>&nbsp;
                {this.context.intl.messages['push-notifications.edit.push-notification.target-button']}
            </Button>
        );

        if (this.state.pushNotificationAsset.size) {
            target =
                <div>
                    <Link to={`/assets/${this.state.pushNotificationAsset.get('displayAssetTypeName').toLowerCase()}/${this.state.pushNotificationAsset.get('id')}`}>
                        {this.state.pushNotificationAsset.get('assetName')}
                    </Link>&nbsp;
                    <span className="fas fa-times-circle" onClick={this.handleCleanTarget}></span>
                </div>;
        } else if (this.state.pushNotificationTitle.size) {
            target =
                <div>
                    <Link to={`/titles/${this.state.pushNotificationTitle.get('id')}`}>
                        {this.state.pushNotificationTitle.get('displayName')}
                    </Link>&nbsp;
                    <span className="fas fa-times-circle" onClick={this.handleCleanTarget}></span>
                </div>;
        } else if (this.state.pushNotification.get('mediaAssetId')) {
            target =
                <div>
                    <ControlLabel>{`Asset: ${this.state.pushNotification.get('mediaAssetId')}`}</ControlLabel>
                    <span className="fas fa-times-circle" onClick={this.handleCleanTarget}></span>
                </div>;
        } else if (this.state.pushNotification.get('titleId')) {
            target =
                <div>
                    <Link to={`/titles/${this.state.pushNotification.get('titleId')}`} target="_blank">
                        {`Title: ${this.state.pushNotification.get('titleId')}`}
                    </Link>&nbsp;
                    <span className="fas fa-times-circle" onClick={this.handleCleanTarget}></span>
                </div>;
        }

        let messageTitleCount;
        if (this.state.pushNotification.get('messageTitle')) {
            if (this.state.pushNotification.getIn(['messageTitle']).length > 0) {
                messageTitleCount = `(${messageTitleLimit - this.state.pushNotification.getIn(['messageTitle']).length} ${this.context.intl.messages['push-notifications.message-title.character-limit']})`;
                if (this.state.pushNotification.getIn(['messageTitle']).length >= messageTitleLimit) {
                    messageTitleCount = <span className="text-red">({this.state.pushNotification.getIn(['messageTitle']).length})</span>;
                }
            }
        }

        let messageTitleLabel = <ControlLabel>
            {this.context.intl.messages['push-notifications.edit.message-title']}
            <span className="text-red"> * </span>
            {messageTitleCount}
            <small><em>{this.context.intl.messages['push-notifications.message-title.recommended-characters']}</em></small>
        </ControlLabel>;

        let messageBodyCount;
        if (this.state.pushNotification.get('messageBody')) {
            if (this.state.pushNotification.getIn(['messageBody']).length > 0) {
                messageBodyCount = `(${messageBodyLimit - this.state.pushNotification.getIn(['messageBody']).length}${this.context.intl.messages['push-notifications.message-body.character-limit']})`;
                if (this.state.pushNotification.getIn(['messageBody']).length >= messageBodyLimit) {
                    messageBodyCount = <span className="text-red">({this.state.pushNotification.getIn(['messageBody']).length})</span>;
                }
            }
        }

        let messageBodyLabel = <ControlLabel>
            {this.context.intl.messages['push-notifications.edit.message-body']}
            <span className="text-red"> * </span>
            {messageBodyCount}
            <small><em>{this.context.intl.messages['push-notifications.message-body.recommended-characters']}</em></small>
        </ControlLabel>;

        return (
            <div>
                <FormSection iconClass="fas fa-info-circle" title={this.context.intl.messages['push-notifications.edit.push-notification-info']}>
                    <FormRow>
                        <FormGroup>
                            <FormItem attr="messageTitle"
                                disabled={this.props.disabled}
                                label={messageTitleLabel}
                                md={12}
                                model={this.state.pushNotification}
                                onlySpaces
                                setter={PushNotificationActions.updatePushNotification}
                                type="text"
                                validations={PushNotificationStoreValidations.messageTitle.validations} />
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <FormItem
                                attr="messageBody"
                                label={messageBodyLabel}
                                model={this.state.pushNotification}
                                setter={PushNotificationActions.updatePushNotification}
                                type="textarea"
                                validations={PushNotificationStoreValidations.messageBody.validations} />
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['push-notifications.edit.push-notification.target']}</ControlLabel><br/>
                            {target}
                        </FormGroup>
                    </FormRow>
                    <hr/>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['push-notifications.edit.partner']}</ControlLabel>
                            <ReactSelect
                                getOptionLabel={data => data.name}
                                getOptionValue={data => data.id}
                                isClearable={false}
                                isMulti={false}
                                isDisabled={true}
                                name="partner"
                                options={[]}
                                value={{}}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['push-notifications.edit.user-group']}</ControlLabel>
                            <GroupSelect
                                disabled={this.props.disabled}
                                onChange={this.handleSelectGroup}
                                selected={this.state.pushNotification.get('userGroups')}
                                selectedKey="id"
                                type={GroupConstants.USER}
                            />
                        </FormGroup>
                    </FormRow>
                </FormSection>
            </div>
        );
    }
}

export default Container.create(Summary);
