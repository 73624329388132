/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

import {AlertTypes} from '../../common/notification/alert';
import {NotificationActions} from '../../common/notification/notification-actions';
import Dispatcher from '../../dispatcher/dispatcher';
import {PreloaderActions} from '../../preloader/preloader-actions';
import Request from '../../request';
import {RouterActions} from '../../router/router-actions';
import {GroupConstants} from '../group/group-actions';

const CONSTANTS = {
    FILTERS: {
        ACTIVE_OPTIONS: {
            ACTIVE: {id: 'ACTIVE', name: 'Active'},
            BOTH: {id: 'BOTH', name: 'Both'},
            INACTIVE: {id: 'INACTIVE', name: 'Inactive'}
        }
    },
    CLEAR: 'security.permission.package.clear',
    FILTER: {
        CLEAR: 'security.permission.package.filter.clear',
        SET: 'security.permission.package.filter.set'
    },
    LOAD: 'security.permission.package.load',
    PRESET: {
        CREATE: {
            ERROR: 'security.permission.package.preset.create.error',
            SUCCESS: 'security.permission.package.preset.create.success'
        },
        ERROR: 'security.permission.package.preset.error',
        GET: {
            START: 'security.permission.package.preset.start',
            SUCCESS: 'security.permission.package.preset.load'
        },
        REMOVE: {
            ERROR: 'security.permission.package.preset.remove.error',
            SUCCESS: 'security.permission.package.preset.remove.success'
        },
        UPDATE: {
            ERROR: 'security.permission.package.preset.update.error',
            SUCCESS: 'security.permission.package.preset.update.success'
        }
    },
    SELECT: 'security.permission.package.select',
    SET: {
        BRAINIAC_GROUPS: 'security.permission.package.set.brainiac-groups',
        CLIENT_REP_GROUPS: 'security.permission.package.set.client-rep-groups',
        GROUPS: 'security.permission.package.set.groups',
        ROLES: 'security.permission.package.set.roles',
        STATIONS: 'security.permission.package.set.stations',
    },
};

class PermissionPackageActions {
    /**
     * Clear the store. Restore the initial state.
     */
    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });

        return;
    }

    deletePreset(permissionPackage) {
        const preloaderSource = 'permission-package-actions.deletePreset';
        PreloaderActions.show(preloaderSource);

        Request.del(`security/permission-package/${permissionPackage.get('id')}`)
            .exec()
            .then(() => {
                PreloaderActions.hide(preloaderSource);
                Dispatcher.dispatch({
                    actionType: CONSTANTS.PRESET.REMOVE.SUCCESS,
                });

                RouterActions.redirect('/presets');
                NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'presets.delete.success');
                return;
            })
            .catch(() => {
                PreloaderActions.hide(preloaderSource);
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'presets.delete.error');
                return;
            });

        return;
    }

    /**
     * Find a preset by its id.
     * @param {Object} id
     */
    findById(id) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.PRESET.GET.START,
            id: id
        });

        const preloaderSource = 'permission-package-actions.findById';
        PreloaderActions.show(preloaderSource);

        Request.get(`security/permission-package/${id}`)
            .exec()
            .then(res => {
                let permissionPackage = Immutable.fromJS(res.body);
                // Groups are separated into brainiacGroups and stations to make the create preset page logic more simple
                permissionPackage = permissionPackage.merge({
                    brainiacGroups: permissionPackage.get('groups').filter(g => g.get('isAdmin')).toSet().sortBy(g => g.get('name')),
                    clientRepGroups: permissionPackage.get('clientRepGroups').toSet().sortBy(g => g.get('name')),
                    groups: permissionPackage.get('groups').filter(g => !g.get('isAdmin') && g.get('groupCategoryType') !== GroupConstants.STATION.categoryId).toSet().sortBy(g => g.get('name')),
                    roles: permissionPackage.get('roles').toSet().sortBy(g => g.get('name')),
                    stations: permissionPackage.get('groups').filter(g => g.get('groupCategoryType') === GroupConstants.STATION.categoryId),
                });

                Dispatcher.dispatch({
                    actionType: CONSTANTS.PRESET.GET.SUCCESS,
                    permissionPackage: permissionPackage
                });

                return;
            })
            .then(()=>{
                PreloaderActions.hide(preloaderSource);
                return;
            })
            .catch(err => {
                PreloaderActions.hide(preloaderSource);
                Dispatcher.dispatch({
                    actionType: CONSTANTS.PRESET.ERROR,
                    error: err
                });

                switch (err.status) {
                case 404:
                    RouterActions.notFound();
                    break;
                default:
                    NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
                    break;
                }

                return;
            });
        return;
    }

    get(queryParams, offset, size, sortByName = true) {
        let params = queryParams.toJS();

        offset = offset || params.offset || 0;
        size = size || params.size;
        params.offset = offset ;
        params.size = size;
        params.active = false;

        // Translate active option to true/false/undefined for this endpoint
        switch (queryParams.get('active')) {
        case CONSTANTS.FILTERS.ACTIVE_OPTIONS.ACTIVE.id:
            params.active = true;
            break;
        case CONSTANTS.FILTERS.ACTIVE_OPTIONS.INACTIVE.id:
            params.active = false;
            break;
        case CONSTANTS.FILTERS.ACTIVE_OPTIONS.BOTH.id:
        default:
            params.active = undefined;
            break;
        }

        const preloaderSource = 'permission-package-actions.get';
        PreloaderActions.show(preloaderSource);

        Request.get('security/permission-package')
            .query(params)
            .exec()
            .then(res => {
                let permissionPackages = Immutable.fromJS(res.body.results).map(p => {
                    ['clientRepGroups', 'groups', 'roles'].forEach(attr => {
                        if (p.get(attr) === undefined) {
                            p.set(attr, Immutable.OrderedSet());
                        }
                        return;
                    });

                    return p;
                });

                if (sortByName) {
                    permissionPackages = permissionPackages.sortBy(p => p.get('name'));
                }

                Dispatcher.dispatch({
                    actionType: CONSTANTS.LOAD,
                    offset: res.body.offset,
                    permissionPackages,
                    size: res.body.size,
                    total: res.header['wbtv-total-count']
                });
            }).then(()=>{
                PreloaderActions.hide(preloaderSource);
                return;
            }).catch(err => {
                PreloaderActions.hide(preloaderSource);

                throw err;
            });

        return;
    }

    savePermissionPackage(permissionPackage, options) {
        let data = permissionPackage.toJS();

        let isActive = 0;
        if (data.isActive) {
            isActive = 1;
        }
        data.isActive = isActive;

        const groups = data.groups.map(g => {return {id: g.id};});
        const stationGroups = data.stations?.map(g => {return {id: g.id};});
        const brainiacGroups = data.brainiacGroups?.map(g => {return {id: g.id};});
        const allGroups = [].concat(stationGroups || []).concat(groups || []).concat(brainiacGroups || []);
        data.groups = allGroups;

        data.roles = data.roles.map(r => {return {id: r.id};});
        delete data.stations;
        delete data.brainiacGroups;

        // Assume POST.
        let method = Request.post;
        let uri = 'security/permission-package';

        // Check if PUT.
        let id = data.id;
        if (id !== undefined) {
            method = Request.put;
            uri = `security/permission-package/${id}`;
        }

        const preloaderSource = 'permission-package-actions.savePermissionPackage';
        PreloaderActions.show(preloaderSource);

        method(uri)
            .send(data)
            .exec()
            .then(res => {
                PreloaderActions.hide(preloaderSource);
                if (id !== undefined) {
                    // PUT
                    Dispatcher.dispatch({
                        actionType: CONSTANTS.PRESET.UPDATE.SUCCESS,
                    });
                } else {
                    // POST
                    id = res.body.id;
                    Dispatcher.dispatch({
                        actionType: CONSTANTS.PRESET.CREATE.SUCCESS,
                    });
                    RouterActions.redirect(`/presets/${id}`, true);
                }

                NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, options.messages.success);
                return;
            })
            .catch(() => {
                PreloaderActions.hide(preloaderSource);
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, options.messages.error);
                return;
            });

        return;
    }

    select(permissionPackage) {
        /**
        * When the user selects a new preset permissions package
        * we need to fetch the package by id to get the list of
        * groups, roles and client rep groups in the package.
        */
        Request.get(`security/permission-package/${permissionPackage.get('id')}`)
            .exec()
            .then(res => {
                /**
                 * Once we have all lists, we can dispatch all actions:
                 *  - load groups.
                 *  - load roles.
                 *  - load defaultPartnerId
                 * It's very important to transform the arrays into
                 * immutable sets, so that we don't end up with duplicated
                 * elements.
                 */
                Dispatcher.dispatch({
                    actionType: CONSTANTS.SELECT,
                    groups: Immutable.fromJS(res.body.groups).toSet(),
                    roles: Immutable.fromJS(res.body.roles).toSet(),
                    defaultPartnerId: res.body.defaultPartnerId,
                    webPartnerIds: res.body.webPartnerIds,
                });

                return;
            });
        return;
    }

    setBrainiacGroups(groups) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SET.BRAINIAC_GROUPS,
            groups
        });
    }

    setClientRepGroups(clientRepGroups) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SET.CLIENT_REP_GROUPS,
            clientRepGroups: clientRepGroups
        });
    }

    setFilter(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.FILTER.SET,
            attr: attr,
            value: value
        });
    }

    setGroups(groups) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SET.GROUPS,
            groups: groups
        });
        return;
    }

    setStations(stations) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SET.STATIONS,
            stations
        });
        return;
    }

    setRoles(roles) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SET.ROLES,
            roles: roles
        });
        return;
    }

    updatePreset(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.PRESET.UPDATE,
            attr: attr,
            value: value
        });
        return;
    }
}

const actions = new PermissionPackageActions();

export {
    actions as PermissionPackageActions,
    CONSTANTS as PermissionPackageConstants
};
