/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';

import {AssetTabActions} from '../assets-tab/asset-tab-actions';
import {AlertTypes} from '../common/notification/alert';
import {NotificationActions} from '../common/notification/notification-actions';
import {GetEncodedHTML} from '../common/utils/utils';
import Dispatcher from '../dispatcher/dispatcher';
import {PreloaderActions} from '../preloader/preloader-actions';
import Request from '../request';
import {RouterActions} from '../router/router-actions';
import {ActionHistoryConstants} from '../system/action-history/action-history-actions';

const CONSTANTS = {
    CLEAR: 'talent_actions.clear',
    TALENT: {
        ADD_NOTE_OK: 'talent_actions.talent.addNote.ok',
        AKA: {
            ADD: 'talent_actions.talent.aka.add',
            REMOVE: 'talent_actions.talent.aka.remove',
            UPDATE: 'talent_actions.talent.aka.attribute.update'
        },
        ASSETS: {
            THUMBNAILS: {
                GET: {
                    SUCCESS: 'talent_actions.talent.assets.thumbnails.get.success'
                }
            }
        },
        GET: {
            SUCCESS: 'talent_actions.talent.get.success',
        },
        SET: {
            FILTERS: 'talent_actions.talent.set.filters'
        },
        FIND_BY_ID: 'talent_actions.talent.findById',
        FIND_BY_ID_OK: 'talent_actions.talent.findById.ok',
        FIND_BY_ID_ERROR: 'talent_actions.talent.findById.error',
        REMOVE_OK: 'talent_actions.talent.remove.ok',
        SAVE: 'talent_actions.talent.save',
        SAVE_OK: 'talent_actions.talent.save.ok',
        SAVE_ERROR: 'talent_actions.talent.save.error',
        SELECTED: 'talent_actions.talent.selected',
        UPDATE: 'talent_actions.talent.attribute.update'
    },
    TALENT_ROLES_TYPES: {
        ACTOR: {id: 1, name: 'Actor'},
        ANIMATED_CHARACTER: {id: 12, name: 'Animated Character'},
        AUTHOR: {id: 11, name: 'Author'},
        CHOREOGRAPHER: {id: 8, name: 'Choreographer'},
        CREATOR: {id: 14, name: 'Created By'},
        DIRECTOR: {id: 2, name: 'Director'},
        EXECUTIVE_PRODUCER: {id: 9, name: 'Executive Producer'},
        GUEST_STAR: {id: 10, name: 'Guest Star'},
        HOST: {id: 5, name: 'Host'},
        MISC_CREW: {id: 13, name: 'Miscellaneous Crew'},
        MUSICIAN: {id: 7, name: 'Musician'},
        PRODUCER: {id: 3, name: 'Producer'},
        VOICE_TALENT: {id: 4, name: 'Voice Talent'},
        WRITER: {id: 6, name: 'Writer'}
    }
};

class TalentActions {
    addAKA() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TALENT.AKA.ADD
        });
    }

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
    }

    create(talent, createAnother) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TALENT.SAVE,
            id: undefined
        });
        let akas = talent.get('akas').toJS();
        let jsTalent = talent.toJS();

        jsTalent.bio = GetEncodedHTML(jsTalent.bio);
        delete jsTalent.akas;
        delete jsTalent.history;
        delete jsTalent.titles;

        return Request.post('talent').send(jsTalent).exec().then(res => {
            let id = res.body.id;
            akas = akas.map(aka => {
                aka.displayOrder += 1;
                return Request.post(`talent/${id}/aka`).send(aka).exec();
            });

            return Promise.all(akas).then(() => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.TALENT.SAVE_OK
                });
                if (!createAnother) {
                    RouterActions.redirect(`/talent/${id}`, true);
                } else {
                    Dispatcher.dispatch({
                        actionType: CONSTANTS.CLEAR,
                    });
                }
                NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'talent.create.save.success');
            });
        }).catch((err) => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.TALENT.SAVE_ERROR,
                error: err,
            });

            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'talent.create.save.error');
            throw err;
        });
    }

    findById(id) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TALENT.FIND_BY_ID,
            id: id
        });

        const actionHistoryQuery = {
            'action-object': ActionHistoryConstants.ACTION_OBJECTS.TALENT,
            'object-id': id,
            offset: 0,
            size: 4
        };

        Promise.all([
            Request.get(`talent/${id}`).exec(),
            Request.get(`talent/${id}/aka`).exec(),
            Request.get('system/action-history').query(actionHistoryQuery).exec(),
            Request.get(`talent/${id}/title-role`).exec()
        ])
            .spread((talent, akas, history, titles) => {
                let t = talent.body;
                if (!t.bio) {
                    t.bio = '';
                }
                t.akas = akas.body;
                t.akas.sort((aka1, aka2) => aka1.displayOrder - aka2.displayOrder);
                t.history = history.body.results;
                t.history.sort((h1, h2) => h2.actionDate.localeCompare(h1.actionDate));
                t.titles = titles.body;
                talent = Immutable.fromJS(t);

                Dispatcher.dispatch({
                    actionType: CONSTANTS.TALENT.FIND_BY_ID_OK,
                    talent
                });
            })
            .catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.TALENT.FIND_BY_ID_ERROR,
                    error: err
                });

                switch (err.status) {
                case 404:
                    RouterActions.notFound();
                    break;
                default:
                    NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
                    break;
                }

                throw err;
            });
    }

    get(filters, offset=0, size=20, sortDirection='asc', sortFieldName='lastName', showPreloader = true) {
        let query = filters.toJS();
        query.offset = offset;
        query.size = size;
        query['sort-direction'] = sortDirection;
        query['sort-field-name'] = sortFieldName;

        if (showPreloader) {
            PreloaderActions.show('talent-actions.get');
        }

        Request.get('talent').query(query).exec().then(res => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.TALENT.GET.SUCCESS,
                offset: offset,
                size: size,
                talentList: Immutable.fromJS(res.body.results),
                total: parseInt(res.header['wbtv-total-count'], 10)
            });

            let defaultImageIds = res.body.results.filter(talent => talent.defaultImagePortraitId).map(talent => talent.defaultImagePortraitId);
            return this.getAssets(defaultImageIds);
        }).then(()=>{
            if (showPreloader) {
                PreloaderActions.hide('talent-actions.get');
            }
        }).catch(() => {
            if (showPreloader) {
                PreloaderActions.hide('talent-actions.get');
            }
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
            return;
        });
        return;
    }

    /**
     * Get assets based on assetId
     * @param  {ids} ids Array of assets Id
     * @return {[type]}    [description]
     */
    getAssets(ids) {
        if (!ids.length) {
            return;
        }
        return Request.get('asset/image/url').query({'image-id': ids}).exec().then(assetRes => {
            let images = assetRes.body.reduce((r, asset) => {
                r[asset.imageId.toString()] = [asset];
                return r;
            }, {});
            Dispatcher.dispatch({
                actionType: CONSTANTS.TALENT.ASSETS.THUMBNAILS.GET.SUCCESS,
                thumbnails: Immutable.fromJS(images)
            });
            return;
        }).catch(err => {
            throw err;
        });
    }

    getSuggestedTalent(titleId) {
        Request.get(`title/${titleId}/talent/suggested`).exec().then(res => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.TALENT.GET.SUCCESS,
                offset: 0,
                size: res.body.length,
                talentList: Immutable.fromJS(res.body),
                total: res.body.length
            });
            return;
        }).catch(err => {
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'titles.create.talent.add-talent.suggested.load-error');
            throw err;
        });
        return;
    }

    remove(id) {
        Request.del(`talent/${id}`).exec().then(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.TALENT.REMOVE_OK,
            });

            RouterActions.redirect('/talent');
            NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'talent.edit.remove.success');
            return;
        }).catch(() => {
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'talent.edit.remove.error');
            return;
        });
    }

    removeAKA(aka) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TALENT.AKA.REMOVE,
            aka:aka
        });
    }

    save(talent, originalAKAS, deletedAKAS, assets, activatedAssets, deactivatedAssets) {
        let id = talent.get('id');
        let akas = talent.get('akas');
        let assetToTalent = 1;

        Dispatcher.dispatch({
            actionType: CONSTANTS.TALENT.SAVE,
            id: id
        });


        let jsTalent = talent.toJS();
        jsTalent.bio = GetEncodedHTML(jsTalent.bio);
        delete jsTalent.akas;
        delete jsTalent.history;
        delete jsTalent.titles;
        delete jsTalent.fullName;

        let p = Request.put(`talent/${id}`).send(jsTalent).exec().then(() => {
            if (assets) {
                assetToTalent = AssetTabActions.save(id, assets, activatedAssets, deactivatedAssets, 'talent');
            }

            return assetToTalent;
        });

        let ops = [];
        deletedAKAS.forEach(aka => {
            // if id === undefined is a new one that the user deleted
            // so nothing to do in that case
            if (aka.get('id')) {
                ops.push(
                    Request.del(`talent/${id}/aka/${aka.get('id')}`)
                );
            }
        });
        akas.forEach((aka, k) => {
            let akaJS = aka.toJS();
            delete akaJS.fullName;
            if (!aka.get('id')) {
                // POST is new
                ops.push(
                    Request.post(`talent/${id}/aka`).send(akaJS)
                );
            } else {
                // check if changed
                if (originalAKAS.size <= k || !originalAKAS.get(k).equals(aka)) {
                    // some change ocurred @ least reposition
                    ops.push(
                        Request.put(`talent/${id}/aka/${aka.get('id')}`).send(akaJS)
                    );
                }
            }
        });
        ops.forEach((op) => {
            p = p.then(() => {
                return op.exec();
            });
        });
        p.then(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.TALENT.SAVE_OK
            });
            this.findById(id);
            NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'talent.edit.save.success');
        }).catch((err) => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.TALENT.SAVE_ERROR,
                error: err,
            });

            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'talent.create.save.error');
            throw err;
        });

        return;
    }

    setFilters(filters) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TALENT.SET.FILTERS,
            filters: filters
        });

        return;
    }

    setSelected(selected) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TALENT.SELECTED,
            selected: selected
        });

        return;
    }

    setTalent(talent) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TALENT.FIND_BY_ID_OK,
            talent: talent
        });

        return;
    }

    updateAka(order) {
        return (attr, value) => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.TALENT.AKA.UPDATE,
                order: order,
                attr: attr,
                value: value
            });
            return;
        };
    }

    updateTalent(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TALENT.UPDATE,
            attr: attr,
            value: value
        });
        return;
    }
}

const actions = new TalentActions();

export {
    actions as TalentActions,
    CONSTANTS as TalentConstants
};
