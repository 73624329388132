/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Col, Row, ControlLabel} from 'react-bootstrap';

import PartnerSelect from './partner-select';
import {FormSection} from '../common/form/form';

class Partners extends React.PureComponent {

    static get propTypes() {
        return {
            defaultPartner: PropTypes.instanceOf(Immutable.Map),
            disabled: PropTypes.bool,
            onSelectPrimaryPartner: PropTypes.func.isRequired,
            onSelectPartners: PropTypes.func.isRequired,
            partners: PropTypes.instanceOf(Immutable.List),
            required: PropTypes.bool
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            defaultPartner: Immutable.Map(),
            disabled: true,
            hideWebPartners: false,
            partners: Immutable.List(),
            required: true
        };
    }

    constructor(props) {
        super(props);

        this.handleSelectPartners = this.handleSelectPartners.bind(this);
        this.handleSelectPrimaryPartner = this.handleSelectPrimaryPartner.bind(this);
    }

    handleSelectPartners(partners) {
        this.props.onSelectPartners(partners);
        return;
    }

    handleSelectPrimaryPartner(partner) {
        this.props.onSelectPrimaryPartner(partner);
        return;
    }

    render() {
        let required;

        if (this.props.required) {
            required = (
                <span className="text-red">*</span>
            );
        }

        return (
            <FormSection title={this.context.intl.messages['partners.title']} iconClass="fa-regular fa-handshake">
                <Row className="padding-y-10">
                    <Col md={6}>
                        <ControlLabel><span>{this.context.intl.messages['partners.web-partners']} {required}</span></ControlLabel>
                        <PartnerSelect
                            disabled={this.props.disabled}
                            onChange={this.handleSelectPartners}
                            partnerType="webPartners"
                            selected={this.props.partners}
                            selectedKey="id"
                        />
                        <small>
                            <em>{this.context.intl.messages['partners.web-partners.caption']}</em>
                        </small>
                    </Col>
                    <Col md={6}>
                        <ControlLabel><span>{this.context.intl.messages['partners.primary-partner']} {required}</span></ControlLabel>
                        <PartnerSelect
                            availableOptions={this.props.partners}
                            disabled={this.props.disabled}
                            isClearable={true}
                            isMulti={false}
                            onChange={this.handleSelectPrimaryPartner}
                            partnerType="primaryPartner"
                            selected={this.props.defaultPartner}
                            selectedKey="id"
                        />
                        <small>
                            <em>{this.context.intl.messages['partners.primary-partner.caption']}</em>
                        </small>
                    </Col>
                </Row>
            </FormSection>
        );
    }
}

export default Partners;
