/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import QueryString from 'querystring-es3';
import React, {Component} from 'react';

import ListUsers from '../../../common/list-users/list-users';
import {Debounce} from '../../../common/utils/utils';
import {RouterActions} from '../../../router/router-actions';
import {BatchUserActions} from '../../batch-user-actions';
import BatchUserStore from '../../batch-user-store';

class Users extends Component {
    static get propTypes() {
        return {
            batchId: PropTypes.number.isRequired,
            displayLinks: PropTypes.bool,
            location: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            displayLinks: false
        };
    }

    static calculateState() {
        return {
            activePage: BatchUserStore.getState().get('activePage'),
            total: BatchUserStore.getState().get('total'),
            totalPages: BatchUserStore.getState().get('totalPages'),
            users: BatchUserStore.getState().get('users'),
        };
    }

    static getStores() {
        return [BatchUserStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({}, this.constructor.calculateState());

        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
        this.handleSortChange = this.handleSortChange.bind(this);
    }

    componentDidMount() {
        BatchUserActions.clear();
        BatchUserActions.get(
            this.props.batchId,
            Immutable.Map(),
            this.props.location.query.offset,
            this.props.location.query.size,
            this.props.location.query['sort-field-name'],
            this.props.location.query['sort-direction']);
        return;
    }

    componentDidUpdate(prevProps) {
        if (this.props.batchId !== prevProps.batchId ||
            this.props.location !== prevProps.location) {
            BatchUserActions.get(
                this.props.batchId,
                Immutable.fromJS(Object.assign({}, {email: this.props.location.query.email})),
                this.props.location.query.offset,
                this.props.location.query.size,
                this.props.location.query['sort-field-name'],
                this.props.location.query['sort-direction']);
        }
        return;
    }

    handleLoadPage(pageNumber) {
        const size = this.props.location.query.size || 20;

        let query = Object.assign(
            {},
            this.props.location.query,
            {
                'offset': pageNumber*size,
                'size': size
            }
        );

        RouterActions.redirect(`${this.props.location.pathname}?${QueryString.stringify(query)}`);

        return;
    }

    handleSearchTerm(term) {
        let query = Object.assign(
            {},
            this.props.location.query,
            {
                email: term,
                offset: 0,
                size: 20
            }
        );

        RouterActions.redirect(`${this.props.location.pathname}?${QueryString.stringify(query)}`);

        return;
    }

    handleSortChange(sortFieldName, sortDirection) {
        let query = Object.assign(
            {},
            this.props.location.query,
            {
                'sort-field-name': sortFieldName,
                'sort-direction': sortDirection,
                'keep-scroll': true,
                offset: 0,
                size: 20
            }
        );

        RouterActions.redirect(`${this.props.location.pathname}?${QueryString.stringify(query)}`);
        return;
    }

    render() {
        let columns = ['name', 'company', 'email', 'status', 'clientRepGroup'];
        const sortFieldName = this.props.location.query['sort-field-name'] || 'name';
        const sortDirection = this.props.location.query['sort-direction'] || 'asc';
        return (
            <div>
                <h3>
                    <i className="fas fa-user"></i>&nbsp;{this.context.intl.messages['scheduling.batch.users.tab.title']}
                </h3>
                <hr/>
                <div>
                    <ListUsers
                        activePage={this.state.activePage}
                        columns={columns}
                        displayLinks={this.props.displayLinks}
                        onPageChange={this.handleLoadPage}
                        onSearchChange={this.handleSearchTerm}
                        onSortChange={this.handleSortChange}
                        sortDirection={sortDirection}
                        sortFieldName={sortFieldName}
                        totalPages={this.state.totalPages}
                        users={this.state.users}
                    />
                </div>
            </div>
        );
    }
}

export default Container.create(Users);
