/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {
    ApTimelineAudioWaveform,
    ApTimelineBasic,
    ApTimelineGroup,
    ApTimelineMarker,
    ApTimelineMetadata,
    ApTimelineThumbnail,
    ApTimelineThumbnails
} from '@accurate-player/accurate-player-components-external';
import {TimeFormat} from '@accurate-player/accurate-player-core';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {FrameRateToFraction} from '../../../common/utils/timecode';
import {Debounce} from '../../../common/utils/utils';
import {AudioProfileActions} from '../../audio-profile-table/audio-profile-actions';
import AudioProfileStore from '../../audio-profile-table/audio-profile-store';
import {UpdateMarkerInPlayer} from '../clips/clip-info';
import CreateClipModal from '../clips/create-clip-modal';
import {TimelineActions} from '../timeline-actions';
import TimelineStore from '../timeline-store';
import './video-timeline-theme-dark.css';

ApTimelineBasic.register();
ApTimelineGroup.register();
ApTimelineMarker.register();
ApTimelineAudioWaveform.register();
ApTimelineMetadata.register();
ApTimelineThumbnail.register();
ApTimelineThumbnails.register();

class VideoTimeline extends Component {
    static get propTypes() {
        return {
            // audioProfile: PropTypes.instanceOf(Immutable.List).isRequired,
            canEdit: PropTypes.bool,
            clips: PropTypes.instanceOf(Immutable.Map).isRequired,
            player: PropTypes.object,
            thumbnails: PropTypes.instanceOf(Immutable.List).isRequired,
            timeline: PropTypes.instanceOf(Immutable.Map).isRequired,
            viewingMode: PropTypes.string.isRequired
        };
    }

    static get defaultProps() {
        return {
            canEdit: false,
            player: undefined
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        const ts = TimelineStore.getState();
        const aps = AudioProfileStore.getState();
        return {
            audioLookups: aps.get('audioLookups'),
            selectedClip: ts.get('selectedClip'),
            creatingClip: ts.get('creatingClip'),
            showEditClipPanel: ts.get('showEditClipPanel')
        };
    }

    static getStores() {
        return [AudioProfileStore, TimelineStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign(
            {
                isClipsGroupCollapsed: false,
                isThumbnailsGroupCollapsed: true,
                showCreateClipModal: false,
            },
            this.constructor.calculateState()
        );

        this.handleClearPoints = this.handleClearPoints.bind(this);
        this.handleCloseCreateClipModal = this.handleCloseCreateClipModal.bind(this);
        this.handleCreateClip = this.handleCreateClip.bind(this);
        this.handleGroupClick = this.handleGroupClick.bind(this);
        this.handlePointDragChange = Debounce(this.handlePointDragChange.bind(this), 200);
        this.handleSetEndAndOpenClip = this.handleSetEndAndOpenClip.bind(this);
        this.initTimelineThumbnails = this.initTimelineThumbnails.bind(this);
        this.toggleCollapsed = this.toggleCollapsed.bind(this);
        this.toggleCreateClipModal = this.toggleCreateClipModal.bind(this);

        return;
    }

    componentDidMount() {
        if (!this.state.audioLookups.size) {
            AudioProfileActions.getAudioLookups();
        }
    }

    componentDidUpdate(oldProps) {
        if (oldProps.player !== this.props.player && this.props.player) {
            this.refs.apTimeline.player = this.props.player;
            this.refs.apTimeline.apPointDragChange = this.handlePointDragChange;

            const updateThumbnails = () => {
                const duration = this.props.player.api.getDuration(TimeFormat.Frame);
                TimelineActions.updateThumbnailsStartTime(duration, this.props.thumbnails.toJS());
            };
            this.props.player.on('playerLoaded', updateThumbnails.bind(this));

            this.props.player.api.plugin.apHotkeyPlugin.setHotkey('m', () => {
                this.handleCreateClip();
            }, 'Create clip');

            this.props.player.api.plugin.apHotkeyPlugin.setHotkey('e', () => {
                this.handleSetEndAndOpenClip();
            }, 'Ended');
        }

        if (this.refs.clipsGroup) {
            this.refs.clipsGroup.collapsed = this.state.isClipsGroupCollapsed;
        }

        if (this.refs.thumbnailsGroup) {
            this.refs.thumbnailsGroup.collapsed = this.state.isThumbnailsGroupCollapsed;
        }
    }

    handleClearPoints() {
        this.runApPointPluginAction('clearAllPoints');
    }

    handleCloseCreateClipModal() {
        this.handleClearPoints();
        this.toggleCreateClipModal();
    }

    handleContent(elem) {
        if (!elem) {return;}

        elem.setAttribute('slot', 'content');
    }

    handleCreateClip() {
        const selectedClipIn = this.props.player.api.plugin.apPointPlugin.points.in;
        const selectedClipOut = this.props.player.api.plugin.apPointPlugin.points.out;

        if (
            selectedClipIn?.frame > selectedClipOut?.frame || // Do not allow backwards clips.
            selectedClipIn?.frame === selectedClipOut?.frame || // Do not allow same in and out point
            !this.state.selectedClip.isEmpty() // Do not create in edit mode
        ) {return;}

        if (selectedClipIn && selectedClipOut) {
            // Create a new clip and inherit some values from the parent timeline.
            const clip = Immutable.Map({
                // Timeline is an asset, the boolean is 0 or 1, make it true or false
                active: !!this.props.timeline.get('active'),
                clipInFrame: selectedClipIn.frame,
                clipOutFrame: selectedClipOut.frame,
                clipInFrameRounded: selectedClipIn.frame, // sending this will trigger rounding calculations API-side, so when the page reloads, 'clipInFrame' may be slightly different
                clipOutFrameRounded: selectedClipOut.frame, // ^ditto, but with 'clipOutFrame'
                /* for a bigger, better explanation of why this rounding logic exists here, visit the UPDATE_VALUE action in the TimelineStore and look for the big 'thumbnailFrameNum' comment block */
                contentType: this.props.timeline.get('contentType'),
                frameRate: this.props.timeline.get('frameRate'),
                deliveryType: this.props.timeline.get('deliveryType'),
                displayOrder: this.props.clips.get('unpublished').size,
                hasDub: this.props.timeline.get('hasDub'),
                hasSub: this.props.timeline.get('hasSub'),
                language: this.props.timeline.get('language'),
                name: this.props.timeline.get('assetName'),
                protectionType: this.props.timeline.get('protectionType'),
                rightsNotes: this.props.timeline.get('rightsNotes'),
                texted: this.props.timeline.get('texted'),
                textless: this.props.timeline.get('textless'),
                videoRightsType: this.props.timeline.get('videoRightsType'),
                videoRightsTerritoryType: this.props.timeline.get('videoRightsTerritoryType'),
                videoRightsMediaType: this.props.timeline.get('videoRightsMediaType'),
                videoRightsTermType: this.props.timeline.get('videoRightsTermType')
            });

            TimelineActions.update('creatingClip', clip);

            this.toggleCreateClipModal();
        }

    }

    toggleCreateClipModal() {
        this.setState((prevState) => ({
            showCreateClipModal: !prevState.showCreateClipModal
        }));
    }

    handleGroupClick(e) {
        const group = e.target;
        group.collapsed = !group.collapsed;
    }

    handleHeader(header) {
        if (!header) {return;}

        header.setAttribute('slot', 'header');
    }

    handleMarkerClick(clip) {
        UpdateMarkerInPlayer(this.props.player, this.props.timeline, clip);
        if (clip.get('published')) {
            TimelineActions.update('showEditClipPanel', false);
            return;
        }
        TimelineActions.setSelectedClip(clip);
        TimelineActions.update('showEditClipPanel', true);
    }

    handlePointDragChange() {
        if (!this.state.showEditClipPanel) {
            return;
        }

        // Update selected clip in and out points
        const selectedClipIn = this.props.player.api.plugin.apPointPlugin.points.in;
        const selectedClipOut = this.props.player.api.plugin.apPointPlugin.points.out;
        TimelineActions.updateSelectedClipAttr('clipInFrame', selectedClipIn.frame);
        TimelineActions.updateSelectedClipAttr('clipOutFrame', selectedClipOut.frame);
        // Update the array so that the timeline is in sync with the user input.
        TimelineActions.addUpdateClipToList(this.state.selectedClip, 'unpublished');
        // Move current time indicator to in point
        this.runApPointPluginAction('seekToIn');
    }

    handleSetEndAndOpenClip() {
        if (!this.props.player.api.plugin.apPointPlugin.points.in) {
            return;
        }

        // Set out point as end
        const duration = this.props.player.api.getDuration(TimeFormat.Frame);
        this.props.player.api.plugin.apPointPlugin.setOutPointFrame(duration, FrameRateToFraction(this.props.timeline.get('frameRate')));

        this.handleCreateClip();
    }

    initTimelineAudioWaveform(waveform) {
        if (waveform) {
            waveform.apDataRequest = range => {
                // Generate array of numbers with random values from 0 to 1
                const samples = [...Array(Math.floor(range.end - range.start)).keys()].map(() => Math.random());
                const sampleRange = {
                    start: range.start,
                    end: range.end,
                    samples
                };
                waveform.api.addSampleRange(sampleRange);
            };
        }
    }

    initTimelineThumbnails(timelineThumbnail) {
        if (!timelineThumbnail) {return;}

        timelineThumbnail.data = this.props.thumbnails.toJS();
    }

    runApPointPluginAction(actionName) {
        this.props.player.api.plugin.apPointPlugin.actions.find(action => action.name === actionName).callback();
    }

    toggleCollapsed(attr) {
        this.setState(prev => ({
            [attr]: !prev[attr]
        }));

        return;
    }

    render() {
        if (!this.props.player) {
            return null;
        }

        const clips = this.props.clips.get('completed')
            .concat(this.props.clips.get('processing'))
            .concat(this.props.clips.get('failed'))
            .concat(this.props.clips.get('unpublished'))
            .map((c) => {
                // As per the updated designs, all clips have the same color.
                // let bgColor = '#ACACAC';
                // if (c.get('contentType') !== null) {
                //     bgColor = VideoStore.getContentType(c.get('contentType')).get('color');
                // }
                c = c.set('bgColor', '#0099ee');
                return c;
            });

        const createClipModal = (
            <CreateClipModal
                canEdit={this.props.canEdit}
                clip={this.state.creatingClip}
                onHide={this.handleCloseCreateClipModal}
                show={this.state.showCreateClipModal}
                viewingMode={this.props.viewingMode}
            />
        );

        let thumbnailsGroupIcon = 'fa-regular fa-chevron-down';
        if (!this.state.isThumbnailsGroupCollapsed) {
            thumbnailsGroupIcon = 'fa-regular fa-chevron-up';
        }
        return (
            <ap-timeline-basic ref="apTimeline" style={{paddingTop: '20px'}}>
                <ap-timeline-group ref="thumbnailsGroup">
                    <div className="row-header timeline-clips-header" ref={this.handleHeader}>
                        <div className="collapse-group" style={{cursor: 'pointer', width: '100%'}} onClick={this.toggleCollapsed.bind(this, 'isThumbnailsGroupCollapsed')}>
                            <span>{this.context.intl.messages['hardac.timeline.thumbnails']}</span>
                            <span className="pull-right padding-x-20"><i className={thumbnailsGroupIcon} /></span>
                        </div>
                    </div>
                    <ap-timeline-row>
                        <ap-timeline-thumbnails>
                            <ap-timeline-thumbnail
                                ref={this.initTimelineThumbnails.bind(this.props.thumbnails)}
                            ></ap-timeline-thumbnail>
                        </ap-timeline-thumbnails>
                    </ap-timeline-row>
                </ap-timeline-group>
                <ap-timeline-group ref="clipsGroup">
                    <div className="row-header timeline-clips-header" ref={this.handleHeader}>
                        <span className="collapse-group" onClick={this.toggleCollapsed.bind(this, 'isClipsGroupCollapsed')}>{this.context.intl.messages['hardac.timeline.clips']}</span>
                        <div className="timeline-clips-buttons">
                            <button className="btn btn-sm btn-default-outline Mr(3px)" data-toggle="tooltip" title={this.context.intl.messages['hardac.timeline.controls.set-inpoint']} onClick={this.runApPointPluginAction.bind(this, 'setInPoint')}>I</button>
                            <button className="btn btn-sm btn-default-outline Mr(3px)" data-toggle="tooltip" title={this.context.intl.messages['hardac.timeline.controls.set-outpoint']} onClick={this.runApPointPluginAction.bind(this, 'setOutPoint')}>O</button>
                            {/*
                                We have been asked to remove this button, however I find it quite useful, so just keep the code
                                in case we are asked to get it back 🙃
                                <button className="btn btn-sm btn-default-outline Mr(3px)" data-toggle="tooltip" title={this.context.intl.messages['hardac.timeline.controls.clear-clip']} onClick={this.handleClearPoints}><i className="far fa-trash-alt"></i></button>
                            */}
                            <button
                                className="btn btn-sm btn-default-outline Mr(3px)"
                                data-toggle="tooltip"
                                title={this.context.intl.messages['hardac.timeline.clip.add']}
                                onClick={this.handleCreateClip}
                                disabled={!this.state.selectedClip.isEmpty()}
                            >
                                <i className="far fa-plus"></i>
                            </button>
                        </div>
                    </div>
                    <div ref={this.handleContent}>
                        <ap-timeline-metadata renderer-type="canvas_2d" collapsed={true}>
                            {clips.map((clip, i) => (
                                <ap-timeline-marker
                                    key={i}
                                    markerStyle={JSON.stringify({
                                        backgroundColor: clip.get('bgColor'),
                                        hover: {
                                            // Thought it was styles over the same component,
                                            // but not, it's a new element on top.
                                            // Hide it so that the original is still visible.
                                            opacity: 0
                                        },
                                        opacity: 0.4
                                    })}
                                    start={clip.get('clipInFrame')}
                                    end={clip.get('clipOutFrame')}
                                    onDoubleClick={this.handleMarkerClick.bind(this, clip)}
                                />
                            )).toJS()}
                        </ap-timeline-metadata>
                    </div>
                    {clips.map((clip, i) => (
                        <ap-timeline-row key={i}>
                            <div className="row-header" ref={this.handleHeader}>
                                <span>
                                    {clip.get('name')}
                                </span>
                            </div>
                            <ap-timeline-metadata select={true} renderer-type="dom">
                                <ap-timeline-marker
                                    markerStyle={JSON.stringify({
                                        backgroundColor: clip.get('bgColor'),
                                        hover: {
                                            backgroundColor: clip.get('bgColor')
                                        },
                                        selected: {
                                            backgroundColor: '#aaffff'
                                        }
                                    })}
                                    start={clip.get('clipInFrame')}
                                    end={clip.get('clipOutFrame')}
                                    onDoubleClick={this.handleMarkerClick.bind(this, clip)}
                                />
                            </ap-timeline-metadata>
                        </ap-timeline-row>
                    )).toJS()}
                </ap-timeline-group>
                {
                // Temp until we decide if audio waveforms are or not part of this screen.
                // <ap-timeline-group onClick={this.handleGroupClick}>
                //     <span className="row-header" ref={this.handleHeader}>
                //         {this.context.intl.messages['hardac.timeline.audio-profile']}
                //     </span>
                //     {this.props.audioProfile.map((audioProfile, i) => {
                //         // For demonstration purposes we use a random waveform style
                //         const waveformTypes = ['stroke', 'stroke_fill', 'rect'];
                //         const waveformType = waveformTypes[Math.floor(Math.random() * waveformTypes.length)];
                //
                //         let description = '';
                //         const l = this.state.audioLookups.find(
                //             lookup => audioProfile.get('type') === lookup.get('value')
                //         );
                //         if (l) {
                //             description = l.get('description');
                //         }
                //
                //         return (
                //             <ap-timeline-row key={i}>
                //                 <div className="row-header timeline-clips-header" ref={this.handleHeader}>
                //                     <span>
                //                         #{audioProfile.get('audioChannel')} - {description}
                //                     </span>
                //                 </div>
                //                 <ap-timeline-audio-waveform
                //                     mirror={true}
                //                     origo={0.5}
                //                     ref={this.initTimelineAudioWaveform}
                //                     type={waveformType}
                //                 ></ap-timeline-audio-waveform>
                //             </ap-timeline-row>
                //         );
                //     })}
                // </ap-timeline-group>
                }
                {createClipModal}
            </ap-timeline-basic>
        );
    }
}

export default Container.create(VideoTimeline);
