/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, Col, Row} from 'react-bootstrap';

import Release from './release';
import ReleasesTable from './releases-table';
import {SectionPanel} from '../../../common/panel/panel';
import {WithValidations} from '../../../common/validations/validations';
import {CurrencyActions} from '../../../lookup/currency/currency-actions';
import CurrencyStore from '../../../lookup/currency/currency-store';
import LanguageStore from '../../../lookup/language/language-store';
import {MapToValues} from '../../../lookup/lookup-select-helpers';
import {TerritoryActions} from '../../../lookup/territory/territory-actions';
import TerritoryStore from '../../../lookup/territory/territory-store';
import {TitleValidations} from '../../../titles/title-store';
import {TitleActions, TitleConstants} from '../../title-actions';

const GRID_VIEW = 'GRID_VIEW';
const LIST_VIEW = 'LIST_VIEW';

class Releases extends Component {

    static get propTypes() {
        return {
            disabled: PropTypes.bool,
            expanded: PropTypes.bool,
            title: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true,
            expanded: false
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            display: GRID_VIEW
        };

        this.handleAdd = this.handleAdd.bind(this);
        this.handleChangeDisplayView = this.handleChangeDisplayView.bind(this);
    }

    componentWillMount() {
        CurrencyActions.get(0, 9999);
        TerritoryActions.get(0, 9999);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.title.get('releases') !== nextProps.title.get('releases') ||
            this.props.title.get('category') !== nextProps.title.get('category') ||
            this.props.disabled !== nextProps.disabled ||
            this.props.expanded !== nextProps.expanded ||
            this.state.display !== nextState.display) {
            return true;
        }

        return false;
    }

    handleAdd() {
        // Build a release object with all default values. Since we don't like
        // to access stores in the actions we put that logic here in the handler.

        // default to Tentative, except for episode which defaults to Firm
        let defaultDateStatusType = TitleConstants.RELEASE.DATE_STATUS_TYPES.TENTATIVE.id;
        if (this.props.title.get('category') === TitleConstants.TITLE_TYPES.EPISODE.id) {
            defaultDateStatusType = TitleConstants.RELEASE.DATE_STATUS_TYPES.FIRM.id;
        }

        let release = Immutable.Map({
            airDayType: 8,
            airHour: undefined,
            airMinute: 0,
            boxOfficeCurrencyId: MapToValues('name', CurrencyStore)('USD|U.S. Dollar').get('id'),
            dateStatusType: defaultDateStatusType,
            dateType: 0,
            domesticReleaseCompanyId: undefined,
            languageId: MapToValues('name', LanguageStore)('English').get('id'),
            territoryId: MapToValues('name', TerritoryStore)('U.S.').get('id'),
            type: 0
        });
        TitleActions.addRelease(release);
        return;
    }

    handleChangeDisplayView(view) {
        this.setState(() => ({
            display: view
        }));
    }

    render() {
        const releasesList = this.props.title.get('releases');

        let releases = releasesList.map((r, i) => {
            return <Release disabled={this.props.disabled} index={i} key={i} release={r} category={this.props.title.get('category')}/>;
        });

        let validation;

        if (releasesList.size) {
            releasesList.some(release => {
                validation = this.getAccordionValidationState(
                    release,
                    Object.keys(TitleValidations.releases),
                    TitleValidations.releases);

                return (validation === 'error' || validation === 'warning');
            });
        }

        let displayButtons;
        if (releasesList.size) {
            displayButtons = (
                <div className="padding-y-10" style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row-reverse'}}>
                    <div className="text-center">
                        <span
                            className={ClassNames('display-type-switch glyphicon glyphicon glyphicon-th', {active: this.state.display === GRID_VIEW})}
                            style={{marginRight: 5}}
                            onClick={this.handleChangeDisplayView.bind(this, GRID_VIEW)}
                            title="Grid"
                        />
                        <span
                            className={ClassNames('display-type-switch glyphicon glyphicon glyphicon-align-justify', {active: this.state.display === LIST_VIEW})}
                            onClick={this.handleChangeDisplayView.bind(this, LIST_VIEW)}
                            title="List"
                        />
                    </div>
                    {this.state.display === LIST_VIEW && (
                        <span className="text-yellow"><i className="fas fa-exclamation-triangle" />&nbsp;<b>{this.context.intl.messages['titles.create.release.alert']}</b></span>
                    )}
                </div>
            );
        }

        let releaseContent = (
            <div>
                {releases}
                <Row>
                    <Col md={4} mdOffset={4}>
                        <Button bsStyle="primary" className="btn-lg btn-block" onClick={this.handleAdd} disabled={this.props.disabled}>
                            <i className="fas fa-plus"/>&nbsp;{this.context.intl.messages['titles.create.release.add']}
                        </Button>
                    </Col>
                </Row>
            </div>
        );
        if (this.state.display === LIST_VIEW) {
            releaseContent = <ReleasesTable releases={releasesList} category={this.props.title.get('category')} />;
        }

        return (
            <SectionPanel
                expanded={this.props.expanded}
                title={this.context.intl.messages['titles.create.summary.releases']}
                validationState={validation}
            >
                {displayButtons}
                {releaseContent}
            </SectionPanel>
        );
    }

}

export default WithValidations(Releases);
