/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
*/

import PropTypes from 'prop-types';
import React from 'react';

import {AudioProfileConstants} from './audio-profile-actions';
import AudioProfileStore from './audio-profile-store';
import BaseSelect from '../../common/base-select/base-select';

const AudioProfileSelector = ({disabled, mediaInfoTotalAudioTracks, onChange, presetId, placeholder}) => {
    const filterOptions = (options) => {
        return options.filter(o => o.get('id') === AudioProfileConstants.AUDIO_PROFILE.CUSTOM_PROFILE_ID || o.get('audioChannels', []).size === parseInt(mediaInfoTotalAudioTracks));
    };

    return <BaseSelect
        classNamePrefix="dropdown"
        disabled={disabled}
        labelKey={'profileName'}
        isClearable={false}
        multi={false}
        filterOptions={(o) => filterOptions(o)}
        name="audio-profile-presets"
        onChange={onChange}
        options="audioProfilePresets"
        store={AudioProfileStore}
        value={AudioProfileStore.getPreset(presetId)}
        valueKey={'id'}
        placeholder={placeholder}
    />;
};

AudioProfileSelector.propTypes = {
    disabled: PropTypes.bool,
    mediaInfoTotalAudioTracks: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    presetId: PropTypes.number.isRequired
};

AudioProfileSelector.defaultProps = {
    disabled: true,
    mediaInfoTotalAudioTracks: 0
};

export default AudioProfileSelector;
