/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {TitleActions} from '../../title-actions';

import ListUsers from '~/src/common/list-users/list-users';
import {Debounce} from '~/src/common/utils/utils';
import SessionStore from '~/src/user/session/session-store';
import {UserActions, UserConstants} from '~/src/user/user-actions';
import UserStore from '~/src/user/user-store';

class AddUserPanel extends Component {
    static get propTypes() {
        return {
            slidingPanelId: PropTypes.string.isRequired,
            subscriptionContentType: PropTypes.number.isRequired,
            subscriptions: PropTypes.instanceOf(Immutable.List).isRequired,
            titleId: PropTypes.number.isRequired
        };
    }

    static calculateState() {
        const us = UserStore.getState();
        return {
            activePage: us.get('activePage'),
            filters: us.get('filters'),
            size: us.get('size'),
            sortDirection: us.get('sortDirection'),
            sortFieldName: us.get('sortFieldName'),
            totalPages: us.get('totalPages'),
            users: us.get('users'),
            authUser: SessionStore.getState().get('authUser'),
        };
    }

    static getStores() {
        return [UserStore, SessionStore];
    }

    constructor(props) {
        super(props);

        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
        this.handleSortChange = this.handleSortChange.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.filters !== this.state.filters ||
            nextState.users !== this.state.users) {
            return true;
        }

        return false;
    }

    handlePageChange(pageNumber) {
        // pageNumber x this.state.size = offset.
        UserActions.get(
            this.state.filters,
            pageNumber * this.state.size,
            this.state.size,
            this.state.sortFieldName,
            this.state.sortDirection);
        return;
    }

    handleRowClick(event) {
        if (~event.target.className.indexOf('add-user-panel') ||
            ~event.target.className.indexOf('fa-plus')) {
            let user;
            if (~event.target.className.indexOf('fa-plus')) {
                user = this.state.users.find(u => u.get('id') === parseInt(event.target.parentNode.getAttribute('data-user-id'), 10)).toJS();
            } else {
                user = this.state.users.find(u => u.get('id') === parseInt(event.target.getAttribute('data-user-id'), 10)).toJS();
            }
            let suggestedBy = this.state.authUser.toJS();
            TitleActions.addSubscription(
                this.props.subscriptionContentType,
                this.props.slidingPanelId,
                this.props.titleId,
                user.id,
                `${user.name} ${user.lastName}`,
                suggestedBy.id,
                `${suggestedBy.name} ${suggestedBy.lastName}`
            );
        }
        return;
    }

    handleSearchTerm(term) {
        UserActions.clearFilter();
        UserActions.setFilter('email', term);
        UserActions.setFilter('company', term);
        UserActions.setFilter('name', term);
        UserActions.setFilter('account-type', UserConstants.ACCOUNT_TYPES.PRIMARY.id);
        UserActions.get(this.state.filters, 0, 20, this.state.sortFieldName, this.state.sortDirection);
        return;
    }

    handleSortChange(sortFieldName, sortDirection) {
        UserActions.setSort(sortFieldName, sortDirection);
        UserActions.get(this.state.filters, 0, this.state.size, this.state.sortFieldName, this.state.sortDirection);
        return;
    }

    render() {
        let columns = [
            'name', 'company', 'email',
            'status', 'clientRepGroup',
            {
                name: 'actions',
                get: function(user, props, context) {
                    let disabled = '';
                    if (user.get('disableButton')) {
                        disabled = ' disabled="true" ';
                    }
                    return `<button
                        ${disabled}
                        class="btn btn-primary add-user-panel"
                        data-user-id="${user.get('id')}"><i class="fas fa-plus"></i>&nbsp;${context.intl.messages['titles.create.subscriptions.add-subscriber.add']}</button>`;
                },
                onClick: this.handleRowClick
            }
        ];

        // Update the user state so we can disable the Add button for users who are already subscribed
        const usersFlagDisabled = this.state.users.map(u => {
            if (this.props.subscriptions.some(s => s.get('userId') === u.get('id'))) {
                u = u.set('disableButton', true);
            }
            return u;
        });

        return (
            <div>
                <ListUsers
                    activePage={this.state.activePage}
                    columns={columns}
                    onSearchChange={this.handleSearchTerm}
                    onSortChange={this.handleSortChange}
                    onPageChange={this.handlePageChange}
                    searchTerm={this.state.filters.get('email')}
                    sortDirection={this.state.sortDirection}
                    sortFieldName={this.state.sortFieldName}
                    totalPages={this.state.totalPages}
                    users={usersFlagDisabled}/>
            </div>
        );
    }
}

export default Container.create(AddUserPanel);
