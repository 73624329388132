/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import ReactTable from 'react-table';

import {AssetTitleActions} from './asset-title-actions';
import {TitleColumns} from './titles';
import Pagination from '../../common/table/pagination';

import 'react-table/react-table.css';

const noop = () => void 0;

class UnassignedTable extends Component {
    static get propTypes() {
        return {
            activePage: PropTypes.number.isRequired,
            assignedTitles: PropTypes.instanceOf(Immutable.List).isRequired,
            displayLinks: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
            filters: PropTypes.string.isRequired,
            onPageChange: PropTypes.func,
            onSortChange: PropTypes.func,
            sortDirection: PropTypes.string.isRequired,
            sortFieldName: PropTypes.string.isRequired,
            titles: PropTypes.instanceOf(Immutable.List).isRequired,
            totalPages: PropTypes.number.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            displayLinks: false,
            onSortChange: undefined,
            onPageChange: noop,
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            id: Math.ceil(Math.random() * 100000)
        };

        this.handleAddTitle = this.handleAddTitle.bind(this);
        this.gettHeaderNameClass = this.gettHeaderNameClass.bind(this);
        this.handleHeaderClick = this.handleHeaderClick.bind(this);
    }

    shouldComponentUpdate(nextProps /*, nextState*/) {
        if (nextProps.assignedTitles !== this.props.assignedTitles ||
            nextProps.titles !== this.props.titles ||
            nextProps.filters !== this.props.filters) {
            return true;
        }
        return false;
    }

    handleAddTitle(title) {
        AssetTitleActions.add(title);
        return;
    }

    handleHeaderClick(headerName) {
        let newSortDirection = 'asc';
        if (this.props.sortFieldName === headerName && this.props.sortDirection === 'asc') {
            newSortDirection = 'desc';
        }

        if (this.props.onSortChange) {
            this.props.onSortChange(headerName, newSortDirection);
        }
        return;
    }

    gettHeaderNameClass(headerName) {
        let headerClassName = 'sorting';

        if (headerName === this.props.sortFieldName) {
            headerClassName = `sorting-${this.props.sortDirection}`;
        }

        return headerClassName;
    }

    render() {
        let context = this.context;
        let props = this.props;

        const filterRegExp = new RegExp(props.filters, 'i');

        const unassignedTitles = props.titles.filter(t => {
            let keep = !props.assignedTitles.find(assignedTitle => t.get('id') === assignedTitle.get('id'));

            if (keep && props.filters) {
                keep = t.get('name').match(filterRegExp);
            }

            return keep;
        });

        const getHeader = this.gettHeaderNameClass;

        const columns = [{
            accessor: 'MPMNUMBER',
            Header: params => (
                <strong className={getHeader(params.column.id)}>{this.context.intl.messages['asset.edit.titles-tab.mpm']}</strong>
            ),
            width: 80,
            Cell: c => (
                <div>{TitleColumns.mpmNumber.get(c.original)}</div>
            )
        }, {
            accessor: 'NAME',
            Header: params => (
                <strong className={getHeader(params.column.id)}>{this.context.intl.messages['asset.edit.titles-tab.title']}</strong>
            ),
            Cell: c => (
                <div>{TitleColumns.title.get(c.original, props)}</div>
            )
        }, {
            accessor: 'TITLERELEASEDATE',
            Header: params => (
                <strong className={getHeader(params.column.id)}>{this.context.intl.messages['asset.edit.titles-tab.release-date']}</strong>
            ),
            width: 180,
            Cell: c => (
                <div>{TitleColumns.titleReleaseDate.get(c.original, null, context)}</div>
            )
        }, {
            accessor: 'CATEGORY',
            Header: params => (
                <strong className={getHeader(params.column.id)}>{this.context.intl.messages['asset.edit.titles-tab.title-type']}</strong>
            ),
            width: 150,
            Cell: c => (
                <div>{TitleColumns.categoryGroupName.get(c.original)}</div>
            )
        }, {
            Header: <strong>{this.context.intl.messages['asset.edit.titles-tab.actions']}</strong>,
            width: 85,
            Cell: c => (
                <div>
                    <button className="btn btn-small bg-wb-blue add-title" onClick={this.handleAddTitle.bind(this, c.original)}>
                        <i className="fas fa-plus"></i>&nbsp;{this.context.intl.messages['common.add']}
                    </button>
                </div>
            )
        }];

        const pagination = (
            <div className="row">
                <div className="col-sm-7 col-sm-offset-5">
                    <Pagination
                        activePage={this.props.activePage}
                        onChange={this.props.onPageChange}
                        totalPages={this.props.totalPages}
                    />
                </div>
            </div>
        );

        const handleSortColumn = this.handleHeaderClick;

        return (
            <div>
                <ReactTable
                    className="-striped table-bordered table-striped responsive unassigned-titles-table"
                    columns={columns}
                    data={unassignedTitles}
                    getNoDataProps= {() => {
                        if (unassignedTitles.size) {
                            return {style: {display: 'none'}};
                        }
                    }}
                    id={`unassigned-titles-${this.state.id}`}
                    loading={false}
                    pageSize={20}
                    resizable={false}
                    showPagination={false}
                    sortable={false}
                    getTheadThProps={(state, rowInfo, column) => ({
                        onClick: () => {
                            handleSortColumn(column.id);
                        }
                    })}
                />
                {pagination}
            </div>
        );
    }
}

export default UnassignedTable;
