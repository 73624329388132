/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

import {NotificationActions} from '../../common/notification/notification-actions';
import {AudioProfileToArray} from '../../common/utils/utils';
import Dispatcher from '../../dispatcher/dispatcher';
import Request from '../../request';

const CHANNELS = {
    CENTER_5_1: 30,
    LEFT_FRONT_5_1: 10,
    LS_LEFT_SURROUND_5_1: 50,
    RIGHT_FRONT_5_1: 20,
    RS_RIGHT_SURROUND_5_1: 60,
    SUB_LFE_5_1: 40,
    STEREO_MIX_L: 70,
    STEREO_MIX_R: 80,
};

const PROFILES_BY_LAYOUT = {
    'C L R Ls Rs LFE': [{
        audioChannel: 1,
        type: CHANNELS.CENTER_5_1
    }, {
        audioChannel: 2,
        type: CHANNELS.LEFT_FRONT_5_1
    }, {
        audioChannel: 3,
        type: CHANNELS.RIGHT_FRONT_5_1
    }, {
        audioChannel: 4,
        type: CHANNELS.LS_LEFT_SURROUND_5_1
    }, {
        audioChannel: 5,
        type: CHANNELS.RS_RIGHT_SURROUND_5_1
    }, {
        audioChannel: 6,
        type: CHANNELS.SUB_LFE_5_1
    }]
};

const CONSTANTS = {
    CHANNELS: CHANNELS,
    AUDIO_PROFILE: {
        CUSTOM_PROFILE_ID: -1,
        LOOKUPS: {
            GET: {
                SUCCESS: 'audio-profile.lookups.get.success'
            }
        },
        PRESETS: {
            GET: {
                SUCCESS: 'audio-profile.presets.get.success'
            }
        }
    },
    PROFILES_BY_LAYOUT
};

class AudioProfileActions {
    getAudioLookups() {
        Request.get('asset/audio-channel/lookup').exec().then(response => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.AUDIO_PROFILE.LOOKUPS.GET.SUCCESS,
                audioLookups: Immutable.fromJS(response.body)
            });
            return;
        }).catch(err => {
            NotificationActions.showAlertDanger('hardac.audio-profile.lookups.get.error');
            throw err;
        });

        return;
    }

    getAudioProfilePresets() {
        Request.get('asset/audio-profile')
            .exec()
            .then(response => {

                let audioProfilePresets = response.body.map(preset => {
                    return {
                        id: preset.id,
                        profileName: preset.profileName,
                        audioChannels: AudioProfileToArray(preset),
                        proxyLeftChannel: preset.proxyLeftChannel,
                        proxyRightChannel: preset.proxyRightChannel
                    };
                });

                // Add Custom preset with 16 empty audio channels
                audioProfilePresets.push({
                    id: CONSTANTS.AUDIO_PROFILE.CUSTOM_PROFILE_ID,
                    profileName: 'Custom',
                    audioChannels: AudioProfileToArray({
                        audioChannel1: 0,
                        audioChannel2: 0,
                        audioChannel3: 0,
                        audioChannel4: 0,
                        audioChannel5: 0,
                        audioChannel6: 0,
                        audioChannel7: 0,
                        audioChannel8: 0,
                        audioChannel9: 0,
                        audioChannel10: 0,
                        audioChannel11: 0,
                        audioChannel12: 0,
                        audioChannel13: 0,
                        audioChannel14: 0,
                        audioChannel15: 0,
                        audioChannel16: 0,
                    }),
                    proxyLeftChannel: null,
                    proxyRightChannel: null,
                });

                audioProfilePresets = Immutable.fromJS(audioProfilePresets).sort((a, b) => {
                    let a_channels = a.get('audioChannels').size;
                    let b_channels = b.get('audioChannels').size;
                    // If profiles have different number of channels, sort by that
                    if (a_channels !== b_channels) {
                        return a_channels-b_channels;
                    }
                    // If same number of channels, then sort by profile name.
                    return a.get('profileName', '').localeCompare(b.get('profileName', ''));
                });

                Dispatcher.dispatch({
                    actionType: CONSTANTS.AUDIO_PROFILE.PRESETS.GET.SUCCESS,
                    audioProfilePresets
                });
                return;
            }).catch(err => {
                NotificationActions.showAlertDanger('hardac.audio-profile.presets.get.error');
                throw err;
            });

        return;
    }
}

const actions = new AudioProfileActions();

export {
    actions as AudioProfileActions,
    CONSTANTS as AudioProfileConstants
};
