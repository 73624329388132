/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';

import {CurrentVttFile$, HideUploadModal, IsUploadModalVisible$} from '../../bl';
import CCEditorActions from '../../cc-editor-actions';
import VttFileSelector from '../common/vtt-file-selector';

import WithRxSubscriptions from '~/src/decorators/with-rx-subscriptions';

type Props = {
    show: boolean,
}

type State = {
    file: File | null,
}

class UploadCaptionsModal extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            file: null,
        };

        this.handleFileSelectedEvent = this.handleFileSelectedEvent.bind(this);
        this.handleUploadEvent = this.handleUploadEvent.bind(this);
    }

    private handleFileSelectedEvent(file: File | null) {
        this.setState(() => ({file}));
    }

    private handleUploadEvent() {
        const file = this.state.file;
        const vtt = CurrentVttFile$.value;

        if (!file || !vtt) {
            return;
        }

        CCEditorActions.uploadFile(vtt.toJS(), file);
        HideUploadModal();
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={HideUploadModal} className="skin-dark">
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="text-center">
                        <FormattedMessage id="cc-editor.tab.settings.import-file" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <h4>
                        <i className="fas fa-exclamation-triangle"/>
                        &nbsp;
                        <FormattedMessage id="cc-editor.upload.modal.header" />
                    </h4>
                    <p>
                        <em>
                            <FormattedMessage id="cc-editor.upload.modal.description" />
                        </em>
                    </p>
                    <VttFileSelector
                        className="form-section"
                        onFileSelected={this.handleFileSelectedEvent}
                        show={this.props.show}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="btn btn-success-outline pull-right"
                        disabled={!this.state.file}
                        onClick={this.handleUploadEvent}
                    >
                        <i className="fas fa-copy"/>
                        &nbsp;
                        <FormattedMessage id="cc-editor.tab.settings.import-file" />
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default WithRxSubscriptions(UploadCaptionsModal, {
    show: IsUploadModalVisible$
});
