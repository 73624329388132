/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Col, ControlLabel, FormGroup} from 'react-bootstrap';
import {Link} from 'react-router';
import Select from 'react-select';


class ListSection extends React.Component {

    static get propTypes() {
        return {
            disabled: PropTypes.bool,
            handleSelect: PropTypes.func.isRequired,
            handleRemove: PropTypes.func,
            listNumber: PropTypes.number,
            publishingLists: PropTypes.array.isRequired,
            section: PropTypes.object.isRequired,
            selectedValue: PropTypes.instanceOf(Immutable.Map),
            separator: PropTypes.bool
        };
    }

    static get defaultProps() {
        return {
            disabled: true,
            handleRemove: void 0,
            listNumber: undefined,
            selectedValue: undefined,
            separator: false
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

    }

    render() {
        let className = `homepage-select-${this.props.section.name.toLowerCase()}`;
        let maxMenuHeight = 300; //default react-select prop value
        let listItems;
        let placeholder = `${this.context.intl.messages['common.select']} ${this.context.intl.messages[this.props.section.label]}`;
        let publishingList;

        if (document.querySelector(`.${className}`) &&
            (window.innerHeight - parseInt(document.querySelector(`.${className}`).getBoundingClientRect().bottom, 10)) < 300) {
            maxMenuHeight = 136;
        }

        if (this.props.selectedValue) {
            publishingList = this.props.selectedValue.toJS().publishingList;

            let itemsToShow = publishingList.items.slice(0, 4);

            itemsToShow = itemsToShow.reduce((prev, next, i) => {
                prev += next.name || next.description;
                if (i < itemsToShow.length-1) {
                    prev+= ', ';
                }
                return prev;
            }, '');

            itemsToShow = itemsToShow.replace(/<\/?(div|h\d|i|p|strong)>/g, '');

            let link;
            let more;
            let redirection;

            if (publishingList.items.length > 4) {
                redirection = `/publishing-list/${publishingList.id}`;
                more = `${this.context.intl.messages['homepages.plus']} ${publishingList.items.length - 4} ${this.context.intl.messages['homepages.more']}`;
                link = <Link to={redirection}>{more}</Link>;
            }

            if (itemsToShow) {
                const editButton = <a
                    target="_blank"
                    className="btn btn-xs btn-primary-outline Mr(3px) Mb(3px) pull-right"
                    href={`/publishing-list/${publishingList.id}/list-items`}><i className="fa-solid fa-pencil"/>&nbsp;{this.context.intl.messages['homepages.list-items.edit-list-items']}
                </a>;

                listItems = (
                    <div className="display-list-contents">
                        <ControlLabel>{this.context.intl.messages['homepages.list-items']}</ControlLabel> {editButton}<br/>
                        <small>{itemsToShow}{link && ', '}{link}</small>
                    </div>
                );
            }
        } else {
            publishingList = '';
        }

        let removeOrMandatory = <span className="text-red">*</span>;
        if (this.props.handleRemove) {
            removeOrMandatory = (<span style={{fontWeight:'normal'}} onClick={this.props.handleRemove}>
                (<a href="#" className="remove-list">
                    <i className="fas fa-times-circle"></i>&nbsp;{this.context.intl.messages['homescreens.create.lists.remove']}
                </a>)
            </span>);
        }

        return (
            <div
                className={ClassNames('row homepage-list homepage-list-legacy homepage-list-upfronts homepage-list-playlist', {'last-child': this.props.separator})}
                key={this.props.section.name}>
                <Col md={6}>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages[this.props.section.label]}&nbsp;{this.props.listNumber}&nbsp;{removeOrMandatory}</ControlLabel>
                        <br/>
                        <Select
                            className={className}
                            getOptionLabel={data => data.name}
                            getOptionValue={data => data.id}
                            isClearable={true}
                            isMulti={false}
                            isDisabled={this.props.disabled}
                            maxMenuHeight={maxMenuHeight}
                            name={this.props.section.name}
                            onChange={this.props.handleSelect}
                            options={this.props.publishingLists}
                            placeholder={placeholder}
                            value={publishingList}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    {listItems}
                </Col>
            </div>
        );
    }
}

export default ListSection;
