/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {LanguageCodeType} from '@wbdt-sie/brainiac-web-common';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, FormGroup, Modal} from 'react-bootstrap';
import ReactSelect from 'react-select';

import ListItems from './list-items';
import Summary from './summary/summary';
import RouteValidator from '../../common/custom-validator/route/route';
import DocumentTitle from '../../common/document-title';
import FileInput from '../../common/file-input';
import {MODAL_TYPE} from '../../common/notification/modal';
import {NotificationActions} from '../../common/notification/notification-actions';
import Panel from '../../common/panel/panel';
import {Tab, Tabs} from '../../common/routing-tab/routing-tab';
import {History, SideNotes} from '../../common/side-notes/side-notes';
import {AlertsWarnings} from '../../common/validations/alerts-warnings';
import ActionsMenu from '../../layout/actions-menu/actions-menu';
import StickyHeader from '../../layout/sticky-header/sticky-header';
import Preloader from '../../preloader/';
import PreloaderStore from '../../preloader/preloader-store';
import {RouterActions} from '../../router/router-actions';
import {ActionHistoryActions, ActionHistoryConstants} from '../../system/action-history/action-history-actions';
import SessionStore from '../../user/session/session-store';
import {PublishingListActions, PublishingListConstants} from '../publishing-list-actions';
import {PublishingListLocalizedActions} from '../publishing-list-localized-actions';
import PublishingListLocalizedStore from '../publishing-list-localized-store';
import PublishingListStore from '../publishing-list-store';


class Create extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            params: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired,
            route: RouteValidator // eslint-disable-line react/require-default-props
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            currentLanguage: PublishingListLocalizedStore.getState().get('currentLanguage'),
            itemLocalized: PublishingListLocalizedStore.getState().get('itemLocalized'),
            listItems: PublishingListStore.getState().get('listItems'),
            listItemsLocalized: PublishingListLocalizedStore.getState().get('listItemsLocalized'),
            newLanguage: PublishingListLocalizedStore.getState().get('newLanguage'),
            originalListItems: PublishingListStore.getState().get('originalListItems'),
            originalListItemsLocalized: PublishingListLocalizedStore.getState().get('originalListItemsLocalized'),
            originalPublishingList: PublishingListStore.getState().get('originalPublishingList'),
            originalPublishingListLocalized: PublishingListLocalizedStore.getState().get('originalPublishingListLocalized'),
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible'),
            publishingList: PublishingListStore.getState().get('publishingList'),
            publishingListImageFiles: PublishingListStore.getState().get('publishingListImageFiles', Immutable.List()).map(f => f.name),
            showAddNew: PublishingListLocalizedStore.getState().get('showAddNew'),
            publishingListLocalized: PublishingListLocalizedStore.getState().get('publishingListLocalized')
        };
    }

    static getStores() {
        return [PreloaderStore, PublishingListStore, PublishingListLocalizedStore];
    }

    static getPermissions() {
        return {
            canCreate: SessionStore.canUser(SessionStore.PERMISSIONS.PUBLISHING.CREATE),
            canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.PUBLISHING.EDIT),
            canRemove: SessionStore.canUser(SessionStore.PERMISSIONS.PUBLISHING.REMOVE),
            localized: {
                canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.PUBLISHING.LOCALIZED.EDIT),
                canRead: SessionStore.canUser(SessionStore.PERMISSIONS.PUBLISHING.LOCALIZED.READ)
            }
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            activeTab: 0
        }, this.constructor.calculateState());

        this.addNote = this.addNote.bind(this);
        this.handleAddNewLocalization = this.handleAddNewLocalization.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleLocalizationChange = this.handleLocalizationChange.bind(this);
        this.handleNewLocalizationChange = this.handleNewLocalizationChange.bind(this);
        this.handleChangePublishingListImage = this.handleChangePublishingListImage.bind(this);
        this.handleRemoveLocalization = this.handleRemoveLocalization.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.isDirty = this.isDirty.bind(this);
        this.isListInUse = this.isListInUse.bind(this);
        this.isActiveTab = this.isActiveTab.bind(this);
        this.onHide = this.onHide.bind(this);
        this.showDeleteDialog = this.showDeleteDialog.bind(this);
        this.showAddLocalization = this.showAddLocalization.bind(this);
    }

    componentDidMount() {
        this.init(this.props);
        return;
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location !== this.props.location) {
            RouterActions.registerRedirectCheck((location) => {
                return this.isDirty(location);
            });
        }
    }

    componentWillUnmount() {
        PublishingListActions.clear();
        PublishingListLocalizedActions.clear();
    }

    addNote(description) {
        ActionHistoryActions.addNote(this.props.params.id, description, ActionHistoryConstants.ACTION_OBJECTS.PUBLISHLIST);
    }

    handleAddNewLocalization() {
        PublishingListLocalizedActions.addNew(this.state.newLanguage);
        PublishingListLocalizedActions.addNewHide();
    }

    handleCancel() {
        RouterActions.redirect('/publishing-list', true);
    }

    handleDelete() {
        PublishingListActions.deleteList(PublishingListStore.getState().get('publishingList'));
        return;
    }

    handleLocalizationChange(value) {
        PublishingListLocalizedActions.setCurrentLanguage(value.value);
    }

    handleNewLocalizationChange(value) {
        PublishingListLocalizedActions.setNewLanguage(value.value);
    }

    handleChangePublishingListImage(e) {
        PublishingListActions.updateAttr('publishingListImageFiles', [...e.target.files]);
    }

    handleRemoveLocalization() {
        PublishingListLocalizedActions.removeCurrentLanguage();
    }

    handleSave() {
        let publishingListImage = document.getElementById('publishing-list-image-dummy-input').files[0];
        let options = {
            messages: {
                error: 'publishing-list.save.error',
                success: 'publishing-list.save.success'
            }
        };

        PublishingListActions.save(
            this.state.publishingList,
            this.state.originalPublishingList,
            this.state.listItems,
            this.state.originalListItems,
            options,
            publishingListImage,
            this.state.publishingListLocalized,
            this.state.originalPublishingListLocalized,
            this.state.listItemsLocalized,
            this.state.originalListItemsLocalized
        );

        return;
    }

    init(props) {
        if (props.route.mode === 'edit') {
            PublishingListActions.findById(props.params.id);
            PublishingListActions.getItems(props.params.id);
        } else {
            PublishingListActions.clear();
            PublishingListLocalizedActions.clear();
        }
        RouterActions.registerRedirectCheck((location) => {
            return this.isDirty(location);
        });
    }

    isActiveTab(tabName) {
        let isActive = false;
        let pathname = this.props.location.pathname;

        switch (true) {
        case !!(pathname.match(/\/list-items$/)):
            isActive = tabName === 'list-items';
            break;
        default:
            isActive = tabName === 'summary';
        }

        if (isActive) {
            return 'active';
        }

        return;
    }

    isDirty(location) {
        if (this.props.route.mode === 'create') {
            return false;
        }

        let listItems = this.state.listItems.map( l => l.get('id'));
        let originalListItems = this.state.originalListItems.map( l => l.get('id'));

        if (location) {
            let goingTo = location.pathname.split('/');
            let current = this.props.location.pathname.split('/');
            if (goingTo[1] === current[1] && /* entity */
                goingTo[2] === current[2] /* entity id */) {
                return false; // just changing tabs no confirmation needed.
            }
        }

        return (!this.state.publishingList.equals(this.state.originalPublishingList) ||
                !this.state.publishingListLocalized.equals(this.state.originalPublishingListLocalized) ||
                !this.state.listItemsLocalized.equals(this.state.originalListItemsLocalized) ||
                !listItems.equals(originalListItems));
    }

    isListInUse() {
        return Object.keys(
            PublishingListConstants.KEEP_LIST
        ).find(
            listId => listId === this.props.params.id
        ) !== undefined;
    }

    onHide() {
        PublishingListLocalizedActions.addNewHide();
    }

    showAddLocalization() {
        PublishingListLocalizedActions.addNewShow();
    }

    showDeleteDialog() {
        NotificationActions.show(
            MODAL_TYPE.DANGER,
            this.context.intl.messages['publishing-list.edit.modal.title'],
            this.context.intl.messages['publishing-list.edit.modal.body'],
            this.context.intl.messages['publishing-list.edit.modal.confirm'],
            this.handleDelete
        );
    }

    render() {
        let actionsMenu;
        let disableForm = true;
        let imagePreview;
        let mode = this.props.route.mode;
        let uploadedFile = this.state.publishingList.get('imageS3Path') || '';
        let fileUpload;
        let filename = uploadedFile.split('/').pop();
        let eventsAndNotes = null;
        let historyTab = null;
        let validations = PublishingListStore.getValidations();
        let toItem = (lc) => ({value:lc, label:LanguageCodeType.LANGUAGES[lc]});
        let localizationOptions = this.state.publishingListLocalized.reduce((options, tl) => {
            options.push(toItem(tl.get('languageCode')));
            return options;
        }, [toItem(LanguageCodeType.DEFAULT_LANGUAGE)]);
        let languageFooter;
        let localization;
        let localized = this.state.currentLanguage !== LanguageCodeType.DEFAULT_LANGUAGE;
        let baseRoute = `/publishing-list/${this.props.params.id}`;
        if (mode === 'create') {
            baseRoute = '/publishing-list';
            disableForm = false;
            fileUpload = <div>
                <Panel iconClass="far fa-image" title={this.context.intl.messages['publishing-list.image.upload']} additionalStyle="padding-x-20" collapsible defaultExpanded>
                    <FileInput
                        accept="image/*"
                        files={this.state.publishingListImageFiles}
                        id="publishing-list-image"
                        onChange={this.handleChangePublishingListImage}
                    />
                </Panel>
            </div>;

            actionsMenu = <ActionsMenu
                canSave={!validations.length}
                onCancel={this.handleCancel}
                onSave={this.handleSave}
                saveText={this.context.intl.messages['publishing-list.create.button']}
            />;
        }

        let listItemTab;
        let listName;

        if (mode === 'edit') {
            listName = PublishingListStore.getState().getIn(['publishingList', 'name']);
            if (this.props.permissions.canEdit) {
                disableForm = false;
            }
            if (uploadedFile) {
                imagePreview = <Panel
                    collapsible
                    defaultExpanded
                    iconClass="fas fa-camera"
                    title={this.context.intl.messages['asset.image.image-preview']}>
                    <img src={this.state.publishingList.get('imageUrl')} className="img-thumbnail" />
                    <p className="image-dimensions">({filename})</p>
                </Panel>;
                fileUpload = <div>
                    <Panel iconClass="far fa-image" title={this.context.intl.messages['publishing-list.replace-publishing-list.image']} additionalStyle="padding-x-20" collapsible defaultExpanded>
                        <FileInput
                            accept="image/*"
                            files={this.state.publishingListImageFiles}
                            id="publishing-list-image"
                            onChange={this.handleChangePublishingListImage}
                        />
                    </Panel>
                </div>;
            } else {
                fileUpload = <div>
                    <Panel iconClass="far fa-image" title={this.context.intl.messages['publishing-list.image']} additionalStyle="padding-x-20" collapsible defaultExpanded>
                        <FileInput
                            accept="image/*"
                            files={this.state.publishingListImageFiles}
                            id="publishing-list-image"
                            onChange={this.handleChangePublishingListImage}
                        />
                    </Panel>
                </div>;
            }

            actionsMenu = (
                <ActionsMenu
                    canDelete={!this.isListInUse() && this.props.permissions.canEdit}
                    canEdit={this.props.permissions.canEdit}
                    canSave={!validations.length && this.props.permissions.canEdit}
                    onDelete={this.showDeleteDialog}
                    onCancel={this.handleCancel}
                    onSave={this.handleSave}
                />
            );

            eventsAndNotes = (
                <SideNotes
                    basePath={`/publishing-list/${this.props.params.id}`}
                    dialogMessage={this.context.intl.messages['common.side-notes.add-note.body']}
                    disabled={ !this.props.permissions.canEdit }
                    notes={this.state.publishingList.get('history').toJS().slice(0, 4)}
                    onAddNote={this.addNote}
                    title={this.context.intl.messages['common.side-notes.title']}
                />
            );

            historyTab = (
                <Tab disabled={this.state.currentLanguage !== LanguageCodeType.DEFAULT_LANGUAGE}
                    route={`${baseRoute}/history`}
                    title={this.context.intl.messages['common.tab-title.history']}
                    tabClassName="pull-right"
                >
                    <History
                        actionObject={ActionHistoryConstants.ACTION_OBJECTS.PUBLISHLIST}
                        id={this.props.params.id}
                        pagination
                        pathname={this.props.location.pathname}
                        query={this.props.location.query}
                    />
                </Tab>
            );

            /*List of items asociated to current language*/
            let listItemsLocalized = this.state.listItemsLocalized.get(this.state.currentLanguage);

            listItemTab = (
                <Tab
                    className="padding-x-20 padding-bottom-20"
                    route={`${baseRoute}/list-items`}
                    title={this.context.intl.messages['publishing-list.list-item.tab.title']}
                >
                    <ListItems
                        currentLanguage={this.state.currentLanguage}
                        publishingListId={parseInt(this.props.params.id, 10)}
                        displayLinks={this.props.permissions.canRead}
                        location={this.props.location}
                        publishingListName={listName}
                        publishingListLocalized={listItemsLocalized}
                    />
                </Tab>
            );

            if ((this.isActiveTab('list-items') || this.isActiveTab('summary')) && this.props.permissions.localized.canRead) {
                if (this.props.permissions.localized.canEdit) {
                    let removeLocalized = '';
                    if (localized) {
                        removeLocalized = <Button
                            bsStyle="danger"
                            className="btn btn-default btn-lg btn-block localization"
                            onClick={this.handleRemoveLocalization}><i className="fas fa-plus"></i>&nbsp;{this.context.intl.messages['titles.create.localization.remove-current']}
                        </Button>;
                    }
                    languageFooter = <div>
                        <Button
                            bsStyle="default"
                            className="btn btn-default btn-lg btn-block localization"
                            onClick={this.showAddLocalization}><i className="fas fa-plus"></i>&nbsp;{this.context.intl.messages['titles.create.localization.add-new']}
                        </Button>
                        {removeLocalized}
                    </div>;
                }

                localization = <Panel
                    collapsible
                    defaultExpanded
                    iconClass="fas fa-globe"
                    title={this.context.intl.messages['titles.create.localization.title']}
                    footer={languageFooter}>
                    <div className="box-body padding-x-20" style={{display: 'block'}}>
                        <p>{this.context.intl.messages['titles.create.localization.body']}:</p>
                        <FormGroup>
                            <ReactSelect
                                name="localizationSelect"
                                onChange={this.handleLocalizationChange}
                                options={localizationOptions}
                                value={toItem(this.state.currentLanguage)}
                                clearable={false}
                                style={{bordeColor:'#09E', background: '#F0FAFF'}}
                            />
                        </FormGroup>
                    </div>
                </Panel>;
            }

        }

        let summaryTab = <Tab route={baseRoute} title={this.context.intl.messages['publishing-list.summary-tab-title']}>
            <Summary
                currentLanguage={this.state.currentLanguage}
                disabled={disableForm}
                publishingList={this.state.publishingList}
                publishingListLocalized={this.state.publishingListLocalized.get(this.state.currentLanguage)}
                mode={this.props.route.mode}
            />
        </Tab>;

        let currentKeys = localizationOptions.map(l => l.value);
        let newLocalizationOptions = Object.keys(LanguageCodeType.LANGUAGES)
            .filter(l => currentKeys.indexOf(l) === -1)
            .map(l => toItem(l))
            .sort((a, b) => a.label.localeCompare(b.label));

        let entityName;

        if (this.state.publishingList.get('name')) {
            entityName = this.state.publishingList.get('name');
        }

        return (
            <DocumentTitle
                message={`document-titles.${mode}-publishing-list`} entityName={entityName}
            >
                <div>
                    <Modal show={this.state.showAddNew} onHide={this.onHide}>
                        <Modal.Header className="bg-gray" closeButton>
                            <Modal.Title className="text-center">{this.context.intl.messages['titles.create.localization.add-new']}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Language:</p>
                            <FormGroup>
                                <ReactSelect
                                    name="newLocalizationSelect"
                                    onChange={this.handleNewLocalizationChange}
                                    options={newLocalizationOptions}
                                    value={toItem(this.state.newLanguage)}
                                    clearable={false}
                                    style={{bordeColor:'#09E', background: '#F0FAFF'}}
                                />
                            </FormGroup>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button bsStyle="primary" className="bg-wb-blue" onClick={this.handleAddNewLocalization} disabled={!this.state.newLanguage}>
                                <i className="fas fa-plus-square"/>&nbsp;{this.context.intl.messages['titles.create.localization.add-new']}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <StickyHeader>
                        <div className="col-md-6">
                            <h1>
                                <i className="far fa-list-alt"></i>&nbsp;{this.context.intl.messages[`publishing-list.${mode}.title`]}&nbsp;<small>{listName}</small>
                            </h1>
                            <p className="padding-top-20">
                                <em>{this.context.intl.messages[`publishing-list.${mode}.info`]}</em>
                            </p>
                        </div>
                        {actionsMenu}
                    </StickyHeader>
                    <Preloader show={this.state.preloaderVisible} fixed loadingDots>
                        <section className="content" id="contentContainer">
                            <div className="row">
                                <div className="col-lg-9">
                                    <Tabs location={this.props.location}>
                                        {summaryTab}
                                        {listItemTab}
                                        {historyTab}
                                    </Tabs>
                                </div>
                                <div className="col-lg-3">
                                    <AlertsWarnings
                                        title={this.context.intl.messages['common.alerts-warnings-title']}
                                        stores={[PublishingListStore]}
                                    />
                                    {imagePreview}
                                    {fileUpload}
                                    {localization}
                                    {eventsAndNotes}
                                </div>
                            </div>
                        </section>
                    </Preloader>
                </div>
            </DocumentTitle>
        );
    }

}

export default Container.create(Create);
