/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {ScriptConstants} from './script-actions';
import Validations from '../../common/validations/validations';
import Dispatcher from '../../dispatcher/dispatcher';
import {ActionHistoryConstants} from '../../system/action-history/action-history-actions';

const ScriptValidations = {
    assetName: {
        label: 'asset.script.summary.name',
        validations: [Validations.max(200), Validations.required]
    },
    assetComment: {
        label: 'asset.script.summary.assetComment',
        validations: [Validations.max(255)]
    },
    contentType: {
        label: 'asset.script.summary.contentType',
        validations: [Validations.required]
    },
    language: {
        label: 'asset.script.summary.language',
        validations: [Validations.max(255)]
    },
    territory: {
        label: 'asset.script.summary.territory',
        validations: [Validations.max(255)]
    }
};

class ScriptStore extends ReduceStore {
    getContentType(contentTypeId) {
        return Immutable.fromJS(
            Object.keys(ScriptConstants.CONTENT_TYPES)
                .map(k => ScriptConstants.CONTENT_TYPES[k])
                .filter(obj => contentTypeId === obj.id)[0]
        );
    }

    getInitialState() {
        return Immutable.Map({
            asset: Immutable.Map({
                active: 1,
                files: []
            }),
            contentType: Immutable.fromJS(ScriptConstants.toArray('CONTENT_TYPES')),
            history: Immutable.List(),
            originalAsset: Immutable.Map({
                active: 1,
            }),
            showPreloader: false
        });
    }

    getValidations() {
        return Validations.validate(this.getState().get('asset'), ScriptValidations);
    }

    reduce(state, action) {
        switch (action.actionType) {
        case ActionHistoryConstants.ADD_NOTE.SUCCESS:
            if ( action.actionObjectType === ActionHistoryConstants.ACTION_OBJECTS.DOCUMENT ) {
                state = state.updateIn(['history'], history => history.unshift(action.note));
            }
            break;

        case ScriptConstants.SCRIPT.CLEAR:
            state = this.getInitialState();
            break;

        case ScriptConstants.SCRIPT.GET.ERROR:
            state = state.merge({
                showPreloader: false
            });
            break;

        case ScriptConstants.SCRIPT.GET.START:
            state = state.merge({
                showPreloader: true
            });
            break;

        case ScriptConstants.SCRIPT.GET.SUCCESS:
            state = state.merge({
                asset: action.asset,
                history: action.history,
                originalAsset: action.asset,
                showPreloader: false
            });
            break;

        case ScriptConstants.SCRIPT.UPDATE:
            state = state.setIn(['asset', ...action.attr.split('.')], action.value);
            break;

        case ScriptConstants.SCRIPT.SAVE.ERROR:
            state = state.merge({
                showPreloader: false
            });
            break;

        case ScriptConstants.SCRIPT.SAVE.START:
            state = state.merge({
                showPreloader: true
            });
            break;

        case ScriptConstants.SCRIPT.SAVE.SUCCESS:
            state = state.setIn(['asset', 'id'], action.id);
            state = state.setIn(['asset', 'files'], []);
            state = state.merge({
                originalAsset: state.get('asset'),
                showPreloader: false
            });
            break;

        }
        return state;
    }
}

let store = new ScriptStore(Dispatcher);

export default store;

export {ScriptValidations};
