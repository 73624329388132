/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import createReactClass from 'create-react-class';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {FormGroup} from 'react-bootstrap';

import AKAS from './akas';
import TalentInfo from './talent-info';
import {FormSection, FormRow} from '../../../common/form/form';
import TinyMCE from '../../../common/tinymce/tinymce';
import {TalentActions} from '../../talent-actions';
import {TalentStoreValidations} from '../../talent-store';

export default createReactClass({
    propTypes: {
        talent: PropTypes.instanceOf(Immutable.Map),
    },

    contextTypes: {
        intl: PropTypes.object.isRequired
    },

    getDefaultProps: function() {
        return {
            talent: Immutable.Map(),
        };
    },

    handleBioChange: function(e) {
        TalentActions.updateTalent('bio', e.target.getContent());
        return;
    },

    render: function() {
        return (
            <div>
                <TalentInfo {...this.props}/>
                <AKAS {...this.props} akas={this.props.talent.get('akas')}/>
                <FormSection iconClass="fas fa-file-alt" title={this.context.intl.messages['talent.create.bio.title']}>
                    <FormRow>
                        <FormGroup>
                            <TinyMCE
                                content={this.props.talent.get('bio')}
                                id="id-1"
                                resize={true}
                                onChange={this.handleBioChange}
                                validations={TalentStoreValidations.bio.validations}
                            />
                        </FormGroup>
                    </FormRow>
                </FormSection>
            </div>
        );
    }
});
