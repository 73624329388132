/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {PermissionConstants} from './permission-actions';
import Dispatcher from '../../dispatcher/dispatcher';

// let tokenregexp = /{{[^}]+}}/g;

class PermissionStore extends ReduceStore {

    getInitialState() {
        return Immutable.Map({
            API: Immutable.Map(),
            CMS: Immutable.Map(),
            WBTVD: Immutable.Map(),
            FYC: Immutable.Map(),
            permission: Immutable.Map(),
            roles: Immutable.List(),
            showPreloader: false,
            users: Immutable.Map({
                list: Immutable.List(),
                offset: 0,
                size: 20,
                total: 0,
            }),
            userRoles: Immutable.List(),
        });
    }

    reduce(state, action) {
        switch (action.actionType) {
        case PermissionConstants.PERMISSION.GET.START:
            state = this.getInitialState();
            break;

        case PermissionConstants.PERMISSION.GET.SUCCESS:
            let root = {};
            action.permissions.forEach(function(permission) {
                let items = permission.permissionPath.split('/').splice(2);
                if (items.length < 2) {
                    for (let i = items.length; i < 2; i++ ) {
                        items.unshift('Invalid Nesting');
                    }
                }
                let i = 0;
                let node = root;
                items.forEach(function(item) {
                    i++;
                    let key = item;
                    if (i === items.length) {
                        // last one add item
                        // check if an existing node is present
                        if (node[key]) {
                            // disambiguate
                            key = key + ` (${permission.mainFuncPermissionId})`;
                        }
                        // add
                        node[key] = permission;

                    } else {
                        // add map
                        if (node[key] === undefined) {
                            node[key] = {};
                        }
                        node = node[key];
                    }
                });
            });
            state = state.set(action.root, Immutable.fromJS(root));
            break;
        case PermissionConstants.PERMISSION.GET_BY_ID.START:
            state = state.set('showPreloader', true);
            break;
        case PermissionConstants.PERMISSION.GET_BY_ID.SUCCESS:
            state = state.set('permission', Immutable.fromJS(action.permission))
                .set('showPreloader', false);
            break;
        case PermissionConstants.PERMISSION.USER.GET.START:
            state = state.set('showPreloader', true);
            break;
        case PermissionConstants.PERMISSION.USER.GET.SUCCESS:
            state = state.set('showPreloader', false)
                .mergeIn(['users'], {
                    offset: action.offset,
                    total: action.total,
                    list: Immutable.fromJS(action.users),
                });
            break;
        case PermissionConstants.PERMISSION.ROLES.GET.SUCCESS:
            state = state.setIn(['roles', action.permissionId], action.roles);
            break;
        case PermissionConstants.PERMISSION.ROLES.USER.GET.SUCCESS:
            state = state.setIn(['userRoles', action.userId], action.roles);
            break;
        default:
            break;
        }
        return state;
    }
}

let ps = new PermissionStore(Dispatcher);

export default ps;
