/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

import Table from './table-logs';
import DocumentTitle from '../../../../common/document-title';
import {WithStoreOnRoute} from '../../../../common/store-on-route';
import {LayoutActions} from '../../../../layout/layout-actions';
import LayoutStore from '../../../../layout/layout-store';
import Preloader from '../../../../preloader';
import {MassAddUpdateActions, MassAddUpdateConstants} from '../../mass-add-update-actions';
import MassAddUpdateStore from '../../mass-add-update-store';
import FilterOptions from '../filter-options';

class Browse extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            showFiltersPanel: LayoutStore.getState().get('showFiltersPanel'),
            massAddUpdateLogs: MassAddUpdateStore.getState().get('massAddUpdateLogs'),
            total: MassAddUpdateStore.getState().get('total'),
            showPreloader: MassAddUpdateStore.getState().get('showPreloader'),
        };
    }

    static getStores() {
        return [LayoutStore, MassAddUpdateStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();
        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleToggleFiltersPanel = this.handleToggleFiltersPanel.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
    }

    componentDidMount() {
        MassAddUpdateActions.clear();
        if (this.props.location.query.status) {
            MassAddUpdateActions.get(this.getRouteState());
            return;
        }
        this.setRouteState('operator', 'AND')
            .apply();
        return;
    }

    componentWillUpdate(nextProps) {
        if (this.props.location !== nextProps.location) {
            if (!nextProps.location.query.status) {
                const statusConstants = MassAddUpdateConstants.INGEST_HEADERS_STATUS_TYPES;
                const allStatus = Object.keys(statusConstants).map(status => statusConstants[status].id);
                MassAddUpdateActions.get(this.getRouteState(nextProps).set('status', Immutable.fromJS(allStatus)));
            } else {
                MassAddUpdateActions.get(this.getRouteState(nextProps));
            }
        }
        return;
    }

    /**
     * Handle clicks on pagination controls.
     * @param  {Number} pageNumber page to load.
     */
    handleLoadPage(pageNumber) {
        // pageNumber x this.state.size = offset.
        this.setRouteState('offset', pageNumber * 20)
            .apply();
        return;
    }

    handleSortChange(sortFieldName, sortDirection) {
        this.setRouteState('sort-field-name', sortFieldName)
            .setRouteState('sort-direction', sortDirection)
            .apply();
        return;
    }

    handleToggleFiltersPanel() {
        LayoutActions.toggleFiltersPanel();
        return;
    }

    render() {
        return (
            <DocumentTitle
                message="titles.mass-update.logs.title"
            >
                <div className={ClassNames({'control-sidebar-open': this.state.showFiltersPanel})}>
                    <div className={ClassNames({'sidebar-opened': this.state.showFiltersPanel})}>
                        <Preloader show={this.state.showPreloader} fixed loadingDots>
                            <section className="content-header">
                                <h1>
                                    <i className="far fa-newspaper"></i>
                                    &nbsp;
                                    {this.context.intl.messages['titles.mass-update.logs.title']}
                                    &nbsp;
                                    <small>{`${this.state.total} Batches`}</small>
                                </h1>
                            </section>
                            <section className="content">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="container-fluid no-x-padding">
                                            <div className="row">
                                                <div className="col-md-6 show-grid"/>
                                                <div className="col-md-6 show-grid">
                                                    <Button onClick={this.handleToggleFiltersPanel} bsStyle="primary" bsSize="large" className="pull-right">
                                                        <i className="fas fa-sliders-h"></i>&nbsp;{this.context.intl.messages['common.filteringOptions']}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container-fluid no-x-padding">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="box">
                                                        <div className="box-body">
                                                            <Table
                                                                activePage={Math.ceil((this.getRouteState().get('offset') || 0) / 20 ) || 0}
                                                                massAddUpdateLogs={this.state.massAddUpdateLogs}
                                                                onPageChange={this.handleLoadPage}
                                                                onSortChange={this.handleSortChange}
                                                                sortDirection={this.getRouteState().get('sort-direction') || 'asc'}
                                                                sortFieldName={this.getRouteState().get('sort-field-name') || 'BATCH_ID'}
                                                                totalPages={Math.ceil(this.state.total/20) || 0}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <FilterOptions
                                location={this.props.location}
                            />
                        </Preloader>
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}

export default WithStoreOnRoute(Container.create(Browse));
