/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import QueryString from 'querystring-es3';
import React, {Component} from 'react';

import Table from './table';
import DocumentTitle from '../../common/document-title';
import SearchBox from '../../common/search-box/search-box';
import {WithStoreOnRoute} from '../../common/store-on-route';
import {Debounce} from '../../common/utils/utils';
import Preloader from '../../preloader/';
import PreloaderStore from '../../preloader/preloader-store';
import {RouterActions} from '../../router/router-actions';
import SessionStore from '../../user/session/session-store';
import {TalentActions} from '../talent-actions';
import TalentStore from '../talent-store';


class Browse extends Component {

    static get propTypes() {
        return {
            permissions: PropTypes.object.isRequired,
            location: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static getPermissions() {
        return {
            canReadTalent: SessionStore.canUser(SessionStore.PERMISSIONS.TALENT.READ)
        };
    }

    static calculateState() {
        return {
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible'),
            talentList: TalentStore.getState().get('talentList'),
            thumbnails: TalentStore.getState().get('thumbnails'),
            total: TalentStore.getState().get('total')
        };
    }

    static getStores() {
        return [TalentStore, PreloaderStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
        this.handleSortChange = this.handleSortChange.bind(this);
    }

    componentDidMount() {
        // FIXME: review usage of this.getRouteState. It doesn't make much
        // sense to keep it as a pattern when we move to React 17.
        TalentActions.get(this.getRouteState(),
            this.props.location.query.offset,
            this.props.location.query.size,
            this.props.location.query['sort-direction'],
            this.props.location.query['sort-field-name']);
        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.location.query !== this.props.location.query ||
            nextState.total !== this.state.total ||
            nextState.talentList !== this.state.talentList ||
            nextState.preloaderVisible !== this.state.preloaderVisible ||
            nextState.thumbnails !== this.state.thumbnails) {
            return true;
        }

        return false;
    }

    componentWillUpdate(nextProps) {
        if (this.props.location !== nextProps.location) {
            // FIXME: same here, review getRouteState usage.
            TalentActions.get(this.getRouteState(nextProps),
                nextProps.location.query.offset,
                nextProps.location.query.size,
                nextProps.location.query['sort-direction'],
                nextProps.location.query['sort-field-name']);
        }
        return;
    }

    componentWillUnmount() {
        TalentActions.clear();
    }

    /**
     * Handle clicks on pagination controls.
     * @param  {Number} pageNumber page to load.
     */
    handleLoadPage(pageNumber) {
        this.setRouteState('offset', pageNumber * 20)
            .apply();
        return;
    }

    handleSearchTerm(term) {
        this.setRouteState('name', term)
            .clearRouteState('offset')
            .apply();
    }

    handleSortChange(sortFieldName, sortDirection) {
        // FIXME: this is done because of store-on-route.
        this.setState({
            sortDirection: sortDirection,
            sortFieldName: sortFieldName
        });
        let query = Object.assign(
            {},
            this.props.location.query,
            {
                'sort-field-name': sortFieldName,
                'sort-direction': sortDirection,
                'keep-scroll': true
            }
        );

        RouterActions.redirect(`${this.props.location.pathname}?${QueryString.stringify(query)}`);
        return;
    }

    render() {
        /**
         * Set the thumbnails key inside each talent
         */
        let talentList = this.state.talentList.map(talent => {
            let defaultImagePortraitId = talent.get('defaultImagePortraitId');
            let thumbs;
            if (defaultImagePortraitId) {
                thumbs = this.state.thumbnails.get(defaultImagePortraitId.toString());
            }
            return talent.set('thumbnails', thumbs);
        });

        let activePage = Math.ceil((this.getRouteState().get('offset') || 0) / 20 ) || 0;
        let totalPages = Math.ceil(this.state.total / 20) || 0;

        return (
            <DocumentTitle
                message="document-titles.talent"
            >
                <div>
                    <Preloader show={this.state.preloaderVisible} fixed loadingDots>
                        <section className="content-header">
                            <h1>
                                <i className="fas fa-star"></i>&nbsp;{this.context.intl.messages['talent.browse.title']}
                                &nbsp;<small>{this.state.total}</small>
                            </h1>
                        </section>
                        <section className="content">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="container-fluid no-x-padding">
                                        <div className="row">
                                            <div className="col-md-6 show-grid">
                                                <SearchBox onChange={this.handleSearchTerm} value={this.getRouteState().get('name')}/>
                                            </div>
                                            <div className="col-md-6 show-grid"/>
                                        </div>
                                    </div>
                                    <div className="container-fluid no-x-padding">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="box">
                                                    <div className="box-body padding-x-20 padding-y-20" >
                                                        <Table
                                                            activePage={activePage}
                                                            displayLinks={this.props.permissions.canReadTalent}
                                                            onPageChange={this.handleLoadPage}
                                                            onSortChange={this.handleSortChange}
                                                            sortDirection={this.state.sortDirection}
                                                            sortFieldName={this.state.sortFieldName}
                                                            talent={talentList}
                                                            totalPages={totalPages}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Preloader>
                </div>
            </DocumentTitle>
        );
    }
}

export default WithStoreOnRoute(
    Container.create(Browse)
);
