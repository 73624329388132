/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Col, Row, Button} from 'react-bootstrap';

import {MassUpdateActions} from './mass-update-actions';
import MassUpdateStore from './mass-update-store';
import AuthLink from '../../common/auth-link';
import {RouterActions} from '../../router/router-actions';

class MassUpdateAvailability extends Component {
    static get propTypes() {
        return {
            params: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            availability: MassUpdateStore.getState().get('availability'),
        };
    }

    static getStores() {
        return [MassUpdateStore];
    }

    constructor(props) {
        super(props);

        this.handleGoToMassUpdate = this.handleGoToMassUpdate.bind(this);
    }

    componentDidMount() {
        if (!this.state.availability.get('userMassUpdateId')) {
            MassUpdateActions.getAccountsAvailability(this.props.params.id);
        }
    }

    handleGoToMassUpdate() {
        RouterActions.redirect(`/accounts/mass-update/${this.state.availability.get('userMassUpdateId')}?mode=check`);
    }

    render() {

        const {nonExistingAccountCount, existingAccountCount, totalAccountCount, userMassUpdateId} = this.state.availability.toJS();

        return (
            <div>
                <section className="content-header">
                    <h1>
                        <i className="fas fa-user"></i>&nbsp;{this.context.intl.messages['accounts.mass-check.title']}
                        <small style={{display: 'block', paddingTop: '5px'}}>
                            {totalAccountCount}&nbsp;{this.context.intl.messages['accounts.mass-update.accounts-uploaded']}
                        </small>
                    </h1>
                </section>
                <div className="content">
                    <Row>
                        <Col xs={12}>
                            <div className="container-fluid no-x-padding">
                                <Row>
                                    <Col xs={12}>
                                        <div className="box">
                                            <div className="box-body">
                                                <h3>{this.context.intl.messages['accounts.mass-update.step.account-check.title']}</h3>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-md-6 text-center">
                                                        <h3>
                                                            <i className="fas fa-user-times text-red"></i>
                                                            {nonExistingAccountCount}&nbsp;{this.context.intl.messages['accounts.mass-update.non-existent-accounts']}
                                                        </h3>
                                                        <div className="form-section">
                                                            <p>
                                                                <strong>{nonExistingAccountCount}</strong>&nbsp;
                                                                {this.context.intl.messages['accounts.mass-update.non-existent-accounts.description'].replace('{}', totalAccountCount)}</p>
                                                            <p>
                                                                <AuthLink
                                                                    className="btn btn-lg btn-primary-outline"
                                                                    disabled={!nonExistingAccountCount}
                                                                    href={`user/mass-update/${userMassUpdateId}/non-existing-account`}
                                                                >
                                                                    <i className="fas fa-download"></i>&nbsp;{this.context.intl.messages['accounts.mass-update.non-existent-accounts.export']}
                                                                </AuthLink>
                                                            </p>
                                                            <p><small><em>{this.context.intl.messages['accounts.mass-update.non-existent-accounts.export-description']}</em></small></p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 text-center">
                                                        <h3>
                                                            <i className="fas fa-user-check text-green"></i>
                                                            {existingAccountCount}&nbsp;{this.context.intl.messages['accounts.mass-update.existing-accounts']}
                                                        </h3>
                                                        <div className="form-section">
                                                            <p>
                                                                <strong>{existingAccountCount}</strong>&nbsp;
                                                                {this.context.intl.messages['accounts.mass-update.existing-accounts.description'].replace('{}', totalAccountCount)}</p>
                                                            <p>
                                                                <Button
                                                                    bsSize="lg"
                                                                    className="btn btn-success-outline Mr(3px) Mb(3px)"
                                                                    disabled={!existingAccountCount}
                                                                    onClick={this.handleGoToMassUpdate}
                                                                >
                                                                    <i className="fas fa-user-edit"></i>&nbsp;{this.context.intl.messages['accounts.mass-update.existing-accounts.mass-update']}
                                                                </Button>
                                                            </p>
                                                            <p>
                                                                <small><em>{this.context.intl.messages['accounts.mass-update.existing-accounts.mass-update-description']}</em></small>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="text-center">
                                                    <p>
                                                        <AuthLink
                                                            className="btn btn-lg btn-default-outline"
                                                            disabled={!totalAccountCount}
                                                            href={`user/mass-update/${userMassUpdateId}/all-account`}
                                                        >
                                                            <i className="fas fa-download"></i>&nbsp;{this.context.intl.messages['accounts.mass-update.export-all-accounts']}
                                                        </AuthLink>
                                                    </p>
                                                    <p>
                                                        <small><em>{this.context.intl.messages['accounts.mass-update.export-all-accounts.description']}</em></small>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default Container.create(MassUpdateAvailability);

export {MassUpdateAvailability};
