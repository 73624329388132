/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React from 'react';
import {Modal, Button} from 'react-bootstrap';

export default class AppStylePreviewModal extends React.Component {

    static get propTypes() {
        return {
            bgColor: PropTypes.number,
            children: PropTypes.element,
            onHide: PropTypes.func,
            onRemove: PropTypes.func,
            show: PropTypes.bool
        };
    }

    static get defaultProps() {
        return {
            bgColor: undefined,
            children: undefined,
            onHide: () => void 0,
            onRemove: () => void 0,
            show: false
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.onHide}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="text-center">{this.context.intl.messages['titles.create.style.asset-modal.title']}</Modal.Title>
                </Modal.Header>

                <Modal.Body className="text-center">
                    <div className="embed-responsive embed-responsive-16by9" style={{backgroundColor: `#${this.props.bgColor}`}}>
                        {this.props.children}
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        bsStyle="default"
                        onClick={this.props.onRemove}
                    >
                        <i className="fas fa-times-circle"></i>
                        &nbsp;
                        {this.context.intl.messages['titles.create.style.asset-modal.button.remove']}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
