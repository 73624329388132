/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import * as fbEmitter from 'fbemitter';
import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';
import {BehaviorSubject, Subscriber, Subscription, combineLatest} from 'rxjs';

import {ReadonlySubject} from './readonly-subject';

import pick from '~/src/common/utils/pick';

class AutoClosableBehaviorSubject<T> extends BehaviorSubject<T> {
    private subscribeToExternalSourceIfNeeded!: () => void;
    private unsubscribeFromExternalSourceIfNeeded!: () => void;
    constructor(value: T, subscribeToExternalSourceIfNeeded: () => void, unsubscribeFromExternalSourceIfNeeded: () => void) {
        super(value);
        this.subscribeToExternalSourceIfNeeded = subscribeToExternalSourceIfNeeded;
        this.unsubscribeFromExternalSourceIfNeeded = unsubscribeFromExternalSourceIfNeeded;
    }

    _subscribe(subscriber: Subscriber<T>): Subscription {
        const subscription = super._subscribe(subscriber);
        if (!this.isStopped) {
            this.subscribeToExternalSourceIfNeeded();
        }
        return subscription;
    }

    next(val: T) {
        if (this.isStopped || this.observers.length === 0) {
            this.unsubscribeFromExternalSourceIfNeeded();
            return;
        }
        super.next(val);
    }
}

export function AddLazyFluxListener<
    State extends Dictionary<unknown>,
    K extends keyof State & string,
>(store: ReduceStore<ImmutableMap<State>, unknown> | ReduceStore<State, unknown>, keys: K[], listener: (dependencies: Pick<State, K>) => unknown, autoInitialize = false) {
    let dependencies = pickState(store.getState(), keys);
    if (autoInitialize) {
        listener(dependencies);
    }

    return store.addListener(() => {
        const newestDependencies = pickState(store.getState(), keys);
        const isChanged = keys.some((k) => dependencies[k] !== newestDependencies[k]);

        if (isChanged) {
            listener(newestDependencies);
            dependencies = newestDependencies;
        }
    });
}

export function ConvertFluxStoreToBehaviorSubject<
    State extends Dictionary<unknown>,
    K extends keyof State & string,
>(store: ReduceStore<ImmutableMap<State>, unknown> | ReduceStore<State, unknown>, key: K): BehaviorSubject<State[K]> {
    let fluxSubscription: null | fbEmitter.EventSubscription = null;
    const initValue = pickState(store.getState(), [key])[key];
    const bs = new AutoClosableBehaviorSubject(initValue, subscribeToFluxIfNeeded, unsubscribeFromFluxIfNeeded);

    function subscribeToFluxIfNeeded() {
        fluxSubscription = fluxSubscription || AddLazyFluxListener(store, [key], handleFluxStoreChanged, true);
    }

    function unsubscribeFromFluxIfNeeded() {
        if (fluxSubscription) {
            fluxSubscription.remove();
        }
        fluxSubscription = null;
    }

    function handleFluxStoreChanged() {
        bs.next(pickState(store.getState(), [key])[key]);
    }

    return ReadonlySubject(bs);
}

export function TransformBehaviorSubject<A, R>(source: BehaviorSubject<A>, transformer: (val: A, current?: R) => R): BehaviorSubject<R>;
export function TransformBehaviorSubject<A, B, R>(source1: BehaviorSubject<A>, source2: BehaviorSubject<B>, transformer: (val1: A, val2: B, current?: R) => R): BehaviorSubject<R>;
export function TransformBehaviorSubject<A, B, C, R>(source1: BehaviorSubject<A>, source2: BehaviorSubject<B>, source3: BehaviorSubject<C>, transformer: (val1: A, val2: B, val3: C, current?: R) => R): BehaviorSubject<R>;
export function TransformBehaviorSubject<R>(...sources: any[]): BehaviorSubject<R> {
    const transformer = sources.pop() as (...data: any[]) => R;
    const fromBS = (subjects: BehaviorSubject<any>[]) => transformer.apply(this, subjects.map((bs) => bs.value));
    const withCurrent = (...values: any[]) => transformer.apply(this, [...values, subject.value]);
    const subject = new BehaviorSubject(fromBS(sources));
    combineLatest.apply(this, [...sources, withCurrent]).subscribe((val: R) => {
        if (val !== subject.value) {
            subject.next(val);
        }
    });
    return ReadonlySubject(subject);
}

function pickState<State extends Dictionary<unknown>, K extends keyof State & string>(state: State | ImmutableMap<State>, keys: K[]): Pick<State, K> {
    if (isImmutableMap(state)) {
        return keys.reduce((memo, key) => ({...memo, [key]: state.get(key)}), {} as Pick<State, K>);
    } else {
        return pick(state, keys);
    }
}

function isImmutableMap<T extends Dictionary<unknown>>(maybeMap: unknown): maybeMap is ImmutableMap<T> {
    return Immutable.Map.isMap(maybeMap);
}
