/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import ListSubscriptions from './list-subscriptions';
import {UserActions, UserConstants} from '../../../user/user-actions';
import {TitleConstants} from '../../title-actions';

import Preloader from '~/src/preloader/';
import PreloaderStore from '~/src/preloader/preloader-store';

class Subscriptions extends Component {

    static get propTypes() {
        return {
            disabled: PropTypes.bool,
            title: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible'),
        };
    }

    static getStores() {
        return [PreloaderStore];
    }


    static get defaultProps() {
        return {
            disabled: true
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({}, this.constructor.calculateState());
    }

    componentDidMount() {
        UserActions.clear();
        UserActions.setFilter('account-type', UserConstants.ACCOUNT_TYPES.PRIMARY.id);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.disabled !== this.props.disabled ||
            nextProps.title.get('subscriptions') !== this.props.title.get('subscriptions') ||
            nextState.preloaderVisible !== this.state.preloaderVisible ||
            nextProps.title !== this.props.title) {
            return true;
        }
        return false;
    }

    render() {
        const subscriptions = this.props.title.get('subscriptions');

        let sections = [];
        Object.keys(TitleConstants.SUBSCRIPTION_CONTENT_TYPES).forEach(k => {
            const ct = TitleConstants.SUBSCRIPTION_CONTENT_TYPES[k];
            const ctSubscriptions = subscriptions.get(ct.id.toString(), Immutable.List());

            if (ctSubscriptions.size) {
                sections.push(<div key={`asset-subscription-content-type-${ct.id}`}>
                    <ListSubscriptions
                        clientTableSearch={true}
                        label={ct.name}
                        subscriptions={ctSubscriptions}
                        subscriptionContentType={ct}
                        title={this.props.title}
                        tableId={`title-user-subscriptions-content-type-${ct.id}`}
                    />
                    <hr />
                </div>);
            }
        });

        return (
            <div>
                <h3><i className="fas fa-rss"></i>&nbsp;{this.context.intl.messages['titles.create.subscriptions-tab-title']}</h3>
                <hr />
                <Preloader show={this.state.preloaderVisible} fixed loadingDots>
                    {sections}
                </Preloader>
            </div>
        );
    }
}
export default Container.create(Subscriptions);
