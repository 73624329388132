/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {AlertTypes} from '~/src/common/notification/alert';
import {NotificationActions} from '~/src/common/notification/notification-actions';
import {GetEncodedHTML, UploadFile} from '~/src/common/utils/utils';
import Dispatcher from '~/src/dispatcher/dispatcher';
import Request from '~/src/request';

const CONSTANTS = {
    ALL_HELP_PAGES: [
        'accounts',
        'assets',
        'brands',
        'catalogs',
        'events',
        'guild',
        'groups',
        'history',
        'presets',
        'push-notifications',
        'publishing-list',
        'reports',
        'roles',
        'scheduling',
        'setup',
        'stations',
        'talent',
        'titles',
    ],
    GET: {
        ERROR: 'help-pages.get.error',
        START: 'help-pages.get.start',
        SUCCESS: 'help-pages.get.success'
    },
    PUT: {
        ERROR: 'help-pages.put.error',
        START: 'help-pages.put.start',
        SUCCESS: 'help-pages.put.success'
    },
};

class HelpPageActions {
    get(pageType) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.GET.START
        });

        Request.get(`web/page/${pageType}`).exec().then(res => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.SUCCESS,
                helpPage: res.body
            });
        }).catch(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.ERROR
            });
        });
        return;
    }

    savePage(pageType, payload) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.PUT.START
        });

        const newHelpPage = {webPageHtml: GetEncodedHTML(payload)};

        Request.put(`web/page/${pageType}`).send(newHelpPage).exec().then(res => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.PUT.SUCCESS,
                helpPage: res.body
            });
            NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'help-pages.save.success');
        }).catch(() => {
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
            Dispatcher.dispatch({
                actionType: CONSTANTS.PUT.ERROR
            });
        });
        return;
    }

    uploadImage(blobInfo, progress, pageType) {
        // Supports tinymce v6 images_upload_handler
        // blobInfo: image binary
        // progress: progress callback

        return UploadFile(
            'POST',
            `web/page/file/${pageType}`,
            blobInfo.blob(),
            new XMLHttpRequest(),
        ).then(uploadRes => {
            return uploadRes;
        });
    }
}

const actions = new HelpPageActions();

export {
    actions as HelpPageActions,
    CONSTANTS as HelpPagesConstants,
};
