/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, Table} from 'react-bootstrap';

import {MassAddUpdateActions} from './mass-add-update-actions';
import SlidingPanel from '../../common/sliding-panel/sliding-panel';
import {SlidingPanelActions} from '../../common/sliding-panel/sliding-panel-actions';
import AddExistingTitle from '../create/related/add-existing-title';

const COLUMNS = {
    status: {
        get: function(properties) {
            return <td key="status">{properties.massAddProcess.get('status') || ''}</td>;
        },
        header: function(context) {
            return <th key="status" className="no-arrow">{context.messages['titles.mass-add.top-table-header.status']}</th>;
        }
    },
    batchId: {
        get: function(properties) {
            return <td key="batchId">{properties.massAddProcess.get('titleIngestHeaderId') || ''}</td>;
        },
        header: function(context) {
            return <th key="BatchId" className="no-arrow">{context.messages['titles.mass-add.top-table-header.batch-id']}</th>;
        }
    },
    relatedTitle: {
        get: function(properties, context, handleAddExisting, handleClear) {

            let titleName = properties.relatedTitle.get('name') || '';
            if (properties.step === 'confirmation') {
                return <td key="relatedTitle">{titleName}</td>;
            }

            let relatedTitleAction;

            if (titleName) {
                relatedTitleAction = (
                    <div>
                        <a className="primary" onClick={handleAddExisting}>
                            <i className="fas fa-pencil-alt"></i>
                            &nbsp;{context.messages['titles.mass-add.top-table-add-related.edit']}
                        </a>&nbsp;|&nbsp;
                        <a onClick={handleClear}>
                            <i className="fas fa-ban"></i>
                            &nbsp;{context.messages['titles.mass-add.top-table-add-related.clear']}
                        </a>
                    </div>
                );
            } else {
                relatedTitleAction = (
                    <div>
                        {context.messages['titles.mass-add.top-table-add-related.none']}
                        <a className="primary" onClick={handleAddExisting}>
                            &nbsp;{context.messages['titles.mass-add.top-table-add-related.add']}
                        </a>
                    </div>
                );
            }
            return (
                <td key="relatedTitle">
                    {titleName}
                    {relatedTitleAction}
                </td>
            );
        },
        header: function(context) {
            return <th key="relatedTitle" className="no-arrow">{context.messages['titles.mass-add.top-table-header.related-title']}</th>;
        }
    },
    createdBy: {
        get: function(properties) {
            return <td key="createdBy">{properties.massAddProcess.get('createdBy') || ''}</td>;
        },
        header: function(context) {
            return <th key="createdBy" className="no-arrow">{context.messages['titles.mass-add.top-table-header.created-by']}</th>;
        }
    },
    createdDate: {
        get: function(properties, context) {
            let createdDate = Moment(properties.massAddProcess.get('createdDate'));
            if (createdDate.isValid()) {
                return <td key="createdDate">{createdDate.format(context.messages['date-format'])}</td>;
            } else {
                return <td key="createdDate">-</td>;
            }
        },
        header: function(context) {
            return <th key="createdDate" className="no-arrow">{context.messages['titles.mass-add.top-table-header.created-date']}</th>;
        }
    },
    updatedBy: {
        get: function(properties) {
            return <td key="updatedBy">{properties.massAddProcess.get('updatedBy') || ''}</td>;
        },
        header: function(context) {
            return <th key="updatedBy" className="no-arrow">{context.messages['titles.mass-add.top-table-header.updated-by']}</th>;
        }
    },
    updatedDate: {
        get: function(properties, context) {
            let updatedDate = Moment(properties.massAddProcess.get('updatedDate'));
            if (updatedDate.isValid()) {
                return <td key="updatedDate">{updatedDate.format(context.messages['date-format'])}</td>;
            } else {
                return <td key="updatedDate">-</td>;
            }
        },
        header: function(context) {
            return <th key="updatedDate" className="no-arrow">{context.messages['titles.mass-add.top-table-header.updated-date']}</th>;
        }
    },
    undo: {
        get: function(properties, context) {
            return (
                <td key="undo">
                    <Button href="#" bsStyle="danger">
                        <i className="fas fa-undo"></i> {context.messages['titles.mass-add.top-table-button.undo']}
                    </Button>
                </td>
            );
        },
        header: function(context) {
            return <th key="undo" className="no-arrow">{context.messages['titles.mass-add.top-table-header.undo']}</th>;
        }
    },
    complete: {
        get: function(properties) {
            return <td key="complete">{properties.complete}</td>;
        },
        header: function() {
            return <th key="complete" className="no-arrow"><i className="fas fa-check text-green"></i></th>;
        }
    },
    failed: {
        get: function(properties) {
            return <td key="failed">{properties.failed}</td>;
        },
        header: function() {
            return <th key="failed" className="no-arrow"><i className="fas fa-exclamation-triangle text-red"></i></th>;
        }
    },
    rejected: {
        get: function(properties) {
            return <td key="rejected">{properties.rejected}</td>;
        },
        header: function() {
            return <th key="rejected" className="no-arrow"><i className="fas fa-ban"></i></th>;
        }
    }
};

class MassAddTable extends Component {
    static get propTypes() {
        return {
            columns: PropTypes.array,
            failed: PropTypes.number.isRequired, // eslint-disable-line react/no-unused-prop-types
            massAddProcess: PropTypes.instanceOf(Immutable.Map).isRequired, // eslint-disable-line react/no-unused-prop-types
            rejected: PropTypes.number.isRequired, // eslint-disable-line react/no-unused-prop-types
            relatedTitle: PropTypes.instanceOf(Immutable.Map).isRequired, // eslint-disable-line react/no-unused-prop-types
            step: PropTypes.string.isRequired // eslint-disable-line react/no-unused-prop-types
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            columns: ['status', 'batchId', 'relatedTitle', 'createdBy', 'createdDate', 'updatedBy', 'updatedDate'],
            displayLinks: false,
        };
    }

    constructor(props) {
        super(props);

        this.buildBody = this.buildBody.bind(this);
        this.buildHeaders = this.buildHeaders.bind(this);
    }

    buildBody() {
        return this.props.columns.map(columnName => {
            let column = COLUMNS[columnName];
            if (columnName === 'relatedTitle') {
                return column.get(this.props, this.context.intl, this.handleAddExisting, this.handleClear);
            }
            return column.get(this.props, this.context.intl);
        });

    }

    buildHeaders() {
        return this.props.columns.map(columnName => {
            let column = COLUMNS[columnName];
            return column.header(this.context.intl);
        });
    }

    handleClear() {
        MassAddUpdateActions.clearRelatedTitle();
        return;
    }

    handleAddExisting() {
        SlidingPanelActions.show('related-titles/mass-add');
        return;
    }

    onSelect(title) {
        MassAddUpdateActions.addRelatedTitle(title);
    }

    render() {
        return (
            <div>
                <Table bordered striped>
                    <thead>
                        <tr>
                            {this.buildHeaders()}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {this.buildBody()}
                        </tr>
                    </tbody>
                </Table>
                <SlidingPanel
                    icon="fas fa-plus-circle"
                    id="related-titles/mass-add"
                    title={this.context.intl.messages['titles.mass-add.confirmation.add-related-title']}
                >
                    <AddExistingTitle onSelect={this.onSelect} showRelationshipType={false} slidingPanelId="related-titles/mass-add"/>
                </SlidingPanel>
            </div>
        );
    }
}

export default MassAddTable;
