/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React from 'react';

import ListRecentlyWatched from './list-recently-watched';
import ListSubscriptions from './list-subscriptions';
import {SubscriptionsActions} from './subscriptions-actions';
import SubscriptionsStore from './subscriptions-store';

class Subscriptions extends React.Component {
    static get propTypes() {
        return {
            disabled: PropTypes.bool.isRequired,
            displayVideoLinks: PropTypes.bool.isRequired,
            location: PropTypes.object.isRequired,
            userId: PropTypes.string.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        let subscriptionsState = SubscriptionsStore.getState();

        return {
            originalSubscriptions: subscriptionsState.get('originalSubscriptions'),
            subscriptions: subscriptionsState.get('subscriptions'),
            subscriptionsUsers: subscriptionsState.get('subscriptionsUsers'),
            titlesToAdd: subscriptionsState.get('titlesToAdd'),
            totalCount: subscriptionsState.get('totalCount')
        };
    }

    static getStores() {
        return [SubscriptionsStore];
    }

    static defaultProps() {
        return {
            disabled: true
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();
    }

    componentDidMount() {
        if (this.state.subscriptions.equals(this.state.originalSubscriptions)) {
            SubscriptionsActions.clear();
            SubscriptionsActions.findById(this.props.userId);
        }
        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.disabled !== this.props.disabled ||
            nextProps.location.query !== this.props.location.query ||

            nextState.subscriptions !== this.state.subscriptions ||
            nextState.subscriptionsUsers !== this.state.subscriptionsUsers ||
            nextState.titlesToAdd !== this.state.titlesToAdd ||
            nextState.totalCount !== this.state.totalCount) {
            return true;
        }
        return false;
    }

    render() {
        return (
            <div>
                <ListSubscriptions
                    label={this.context.intl.messages['accounts.create.subscriptions-tab.subscriptions']}
                    location={this.props.location}
                    subscriptions={this.state.subscriptions}
                    totalCount={this.state.totalCount}
                    titlesToAdd={this.state.titlesToAdd}
                    userId={this.props.userId}
                />
                <hr/>
                <ListRecentlyWatched
                    displayVideoLinks={this.props.displayVideoLinks}
                    label={this.context.intl.messages['accounts.create.recently-watched.title']}
                    location={this.props.location}
                    userId={this.props.userId}
                />
            </div>
        );
    }
}

export default Container.create(Subscriptions);
