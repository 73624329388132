/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

import DocumentTitle from '../../common/document-title';
import ListUsers from '../../common/list-users/react-list-users';
import {MODAL_TYPE} from '../../common/notification/modal';
import {NotificationActions} from '../../common/notification/notification-actions';
import SearchBox from '../../common/search-box/search-box';
import {WithStoreOnRoute} from '../../common/store-on-route';
import {Debounce} from '../../common/utils/utils';
import {LayoutActions} from '../../layout/layout-actions';
import LayoutStore from '../../layout/layout-store';
import Preloader from '../../preloader/';
import PreloaderStore from '../../preloader/preloader-store';
import SessionStore from '../../user/session/session-store';
import {UserActions, UserConstants} from '../../user/user-actions';
import UserStore from '../../user/user-store';
import FilterOptions from '../filter-options/filter-options';

class Applicants extends Component {

    static get propTypes() {
        return {
            permissions: PropTypes.object.isRequired,
            location: PropTypes.object.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible'),
            showFiltersPanel: LayoutStore.getState().get('showFiltersPanel'),
            activePage: UserStore.getState().get('activePage'),
            selectAll: UserStore.getState().get('selectAll'),
            selectedUsers: UserStore.getState().get('selectedUsers'),
            total: UserStore.getState().get('total'),
            totalPages: UserStore.getState().get('totalPages'),
            users: UserStore.getState().get('users')
        };
    }

    static getStores() {
        return [PreloaderStore, LayoutStore, UserStore];
    }

    static get defaultProps() {
        return {
            disabled: true,
            passwordResetAllowed: false,
            unlockUserAllowed: false
        };
    }


    static getPermissions() {
        return {
            canReadApplicants: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.APPLICANTS.READ),
            canApproveWithoutPermissions: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.APPLICANTS.PERMISSIONS.APPROVE_WITHOUT_PERMISSION),
            showApproveDenyButtons: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.APPLICANTS.PERMISSIONS.APPROVE) ||
                SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.APPLICANTS.PERMISSIONS.DENY) ||
                SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.APPLICANTS.PERMISSIONS.REJECT) ||
                SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.APPLICANTS.PERMISSIONS.VALIDATE)
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            deniedComments: '',
        }, this.constructor.calculateState());

        this.handleDeniedCommentsChange = this.handleDeniedCommentsChange.bind(this);
        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.handleToggleFiltersPanel = this.handleToggleFiltersPanel.bind(this);
        this.handleToggleSelectApplicant = this.handleToggleSelectApplicant.bind(this);
        this.onConfirmApprove = this.onConfirmApprove.bind(this);
        this.onConfirmDeny = this.onConfirmDeny.bind(this);
        this.showApproveDialog = this.showApproveDialog.bind(this);
        this.showDenyDialog = this.showDenyDialog.bind(this);
    }

    componentDidMount() {
        UserActions.clear();
        let routeState = this.getRouteState();
        if (!routeState.get('operator')) {
            this.setRouteState('operator', 'OR')
                .setRouteState('account-status', Immutable.Set([UserConstants.STATUS.APPROVED.id,
                    UserConstants.STATUS.PRE_APPROVED.id,
                    UserConstants.STATUS.VALIDATED.id,
                    UserConstants.STATUS.PRE_VALIDATED.id,
                    UserConstants.STATUS.NEEDS_REVIEW.id,
                    UserConstants.STATUS.REJECTED.id]))
                .apply();
            return;
        }
        UserActions.get(
            routeState
        );
        return;
    }

    /* istanbul ignore next */
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.location.query !== this.props.location.query ||
            nextState.preloaderVisible !== this.state.preloaderVisible ||
            nextState.selectedUsers !== this.state.selectedUsers ||
            nextState.showFiltersPanel !== this.state.showFiltersPanel ||
            nextState.total !== this.state.total ||
            nextState.users !== this.state.users) {
            return true;
        }
        return false;
    }

    componentWillUpdate(nextProps) {
        if (this.props.location !== nextProps.location) {
            UserActions.toggleSelectAll(false);
            UserActions.get(this.getRouteState(nextProps));
        }
        return;
    }

    handleDeniedCommentsChange(e) {
        this.setState({
            deniedComments: e.target.value
        });
        return;
    }

    /**
     * Handle clicks on pagination controls.
     * @param  {Number} pageNumber page to load.
     */
    handleLoadPage(pageNumber) {
        this.setRouteState('offset', pageNumber * 20)
            .apply();
        return;
    }

    handleSearchTerm(term) {
        this.setRouteState('q', term)
            .apply();
        return;
    }

    handleSortChange(sortFieldName, sortDirection) {
        this.setRouteState('sort-field-name', sortFieldName)
            .setRouteState('sort-direction', sortDirection)
            .apply();
        return;
    }

    handleToggleFiltersPanel() {
        LayoutActions.toggleFiltersPanel();
        return;
    }

    handleToggleSelectApplicant(id, value) {
        UserActions.toggleSelect(this.state.users.findIndex(u => u.get('id') === id), value);
    }

    onConfirmApprove() {
        UserActions.bulkApprove(this.state.selectedUsers, this.getRouteState(this.props));
    }

    onConfirmDeny() {
        UserActions.bulkDeny(this.state.selectedUsers, this.state.deniedComments, this.getRouteState(this.props));
    }

    showApproveDialog(event) {
        event.preventDefault();

        let confirmMessage = <div>
            <p><strong>{this.state.selectedUsers.size}</strong> {this.context.intl.messages['accounts.applicants.bulk-approve.selected-count']}</p>
        </div>;

        NotificationActions.show(
            MODAL_TYPE.APPROVE,
            this.context.intl.messages['accounts.applicants.bulk-approve.title'],
            confirmMessage,
            this.context.intl.messages['accounts.applicants.bulk-approve.confirm-button'],
            this.onConfirmApprove
        );
    }

    showDenyDialog(event) {
        event.preventDefault();
        let denyMessage = <div>
            <p><strong>{this.state.selectedUsers.size}</strong> {this.context.intl.messages['accounts.applicants.bulk-deny.selected-count']}</p>
            <label className="control-label"><i className="fas fa-pencil-alt"></i>&nbsp;{this.context.intl.messages['accounts.create.approve-deny.deny-reasons']}</label>
            <textarea
                className="form-control"
                onChange={this.handleDeniedCommentsChange}
            >
            </textarea>
        </div>;

        NotificationActions.show(
            MODAL_TYPE.DANGER,
            this.context.intl.messages['accounts.applicants.bulk-deny.title'],
            denyMessage,
            this.context.intl.messages['accounts.applicants.bulk-deny.deny-button'],
            this.onConfirmDeny
        );
    }

    render() {
        const userListColumns = [
            'SELECT', 'ACCOUNTALERTS', 'NAME', 'COMPANY', 'EMAIL', 'STATUS',
            'CLIENT_REP_GROUP', 'APPLICATION_DATE'
        ];

        let selectedMessage = '';
        /* istanbul ignore next */
        if (this.state.selectedUsers.size) {
            selectedMessage = ` (${this.state.selectedUsers.size} ${this.context.intl.messages['common.selected']})`;
        }

        let buttonDiv;
        /* istanbul ignore if */
        if (this.props.permissions.showApproveDenyButtons) {
            let disableActions = !this.state.selectedUsers.size;
            let canApprove = SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.APPLICANTS.PERMISSIONS.APPROVE) ||
                SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.APPLICANTS.PERMISSIONS.VALIDATE);
            let canDeny = SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.APPLICANTS.PERMISSIONS.DENY) ||
                SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.APPLICANTS.PERMISSIONS.REJECT);

            buttonDiv = <div className="Pb(10px)">
                {canApprove &&
                    <Button bsStyle="success" className="Mr(3px)" disabled={disableActions} onClick={this.showApproveDialog}>
                        <i className="fas fa-check"></i>&nbsp;{this.context.intl.messages['common.approve']}
                    </Button>
                }
                {canDeny &&
                    <Button bsStyle="danger" className="Mr(3px)" disabled={disableActions} onClick={this.showDenyDialog}>
                        <i className="fas fa-times"></i>&nbsp;{this.context.intl.messages['common.deny']}
                    </Button>
                }
            </div>;
        }

        return (
            <DocumentTitle
                message="document-titles.applicants"
            >
                <div className={ClassNames({'control-sidebar-open': this.state.showFiltersPanel})}>
                    <div className={ClassNames({'sidebar-opened': this.state.showFiltersPanel})}>
                        <Preloader show={this.state.preloaderVisible} fixed loadingDots>
                            <section className="content-header">
                                <h1>
                                    <i className="fas fa-user-plus"></i>&nbsp;{this.context.intl.messages['accounts.applicants.title']}
                                    &nbsp;<small>{this.state.total}&nbsp;{this.context.intl.messages['accounts.applicants.total']}{selectedMessage}</small>
                                </h1>
                            </section>
                            <section className="content">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="container-fluid no-x-padding">
                                            <div className="row">
                                                <div className="col-md-6 show-grid">
                                                    <SearchBox onChange={this.handleSearchTerm} value={this.getRouteState().get('q')}/>
                                                </div>
                                                <div className="col-md-6 show-grid">
                                                    <Button onClick={this.handleToggleFiltersPanel} bsStyle="primary" bsSize="large" className="pull-right">
                                                        <i className="fas fa-sliders-h"></i>&nbsp;{this.context.intl.messages['common.filteringOptions']}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container-fluid no-x-padding">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="box">
                                                    <div className="box-body">
                                                        {buttonDiv}
                                                        <ListUsers
                                                            activePage={this.state.activePage}
                                                            applicants={true}
                                                            columns={userListColumns}
                                                            displayLinks={this.props.permissions.canReadApplicants}
                                                            location={this.props.location}
                                                            onPageChange={this.handleLoadPage}
                                                            onSortChange={this.handleSortChange}
                                                            onSelect={this.handleToggleSelectApplicant}
                                                            onSelectAll={UserActions.toggleSelectAll}
                                                            selectAll={this.state.selectAll}
                                                            sortDirection={this.getRouteState().get('sort-direction') || 'desc'}
                                                            sortFieldName={this.getRouteState().get('sort-field-name') || 'updatedDate'}
                                                            totalPages={this.state.totalPages}
                                                            users={this.state.users}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <FilterOptions
                                location={this.props.location}
                                hideExtraStatuses={true}
                            />
                        </Preloader>
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}

export default WithStoreOnRoute(
    Container.create(Applicants)
);
