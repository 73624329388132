/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import Summary from './summary/summary';
import RouteValidator from '../../../common/custom-validator/route/route';
import DocumentTitle from '../../../common/document-title';
import {Tab, Tabs} from '../../../common/routing-tab/routing-tab';
import SearchBox from '../../../common/search-box/search-box';
import {History, SideNotes} from '../../../common/side-notes/side-notes';
import {WithStoreOnRoute} from '../../../common/store-on-route';
import {Debounce} from '../../../common/utils/utils';
import {ErrorsWarnings} from '../../../common/validations/errors-warnings';
import ActionsMenu from '../../../layout/actions-menu/actions-menu';
import StickyHeader from '../../../layout/sticky-header/sticky-header';
import Preloader from '../../../preloader';
import {RouterActions} from '../../../router/router-actions';
import {GroupActions, GroupConstants} from '../../../security/group/group-actions';
import GroupStore from '../../../security/group/group-store';
import {ActionHistoryActions, ActionHistoryConstants} from '../../../system/action-history/action-history-actions';
import ListTitles from '../../../titles/browse/list-titles';
import SessionStore from '../../../user/session/session-store';

class Create extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            params: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired,
            route: RouteValidator // eslint-disable-line react/require-default-props
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            groupTitles: GroupStore.getState().get('language').get('groupTitles'),
            history: GroupStore.getState().get('language').get('history'),
            originalLanguageCatalog: GroupStore.getState().get('language').get('originalGroup'),
            showPreloader: GroupStore.getState().get('showPreloader'),
            languageCatalog: GroupStore.getState().get('language').get('group'),
            totalCount: GroupStore.getState().get('language').get('totalCount')
        };
    }

    static getStores() {
        return [GroupStore];
    }

    static getPermissions() {
        return {
            canReadTitles: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.READ)
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            complete: false,
            activeTab: 0,
        }, this.constructor.calculateState());

        this.addNote = this.addNote.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
    }

    componentDidMount() {
        this.init(this.props);
        return;
    }

    componentWillUpdate(nextProps) {
        if (this.props.location !== nextProps.location && nextProps.location.pathname.includes('titles')) {
            GroupActions.getGroupTitles(this.props.params.id, 20, this.getRouteState().get('offset'), this.getRouteState().get('title'), GroupConstants.LANGUAGE.name);
        }
        return;
    }

    addNote(description) {
        ActionHistoryActions.addNote(this.props.params.id, description, ActionHistoryConstants.ACTION_OBJECTS.GROUP, GroupConstants.LANGUAGE.name);
    }

    handleCancel() {
        RouterActions.redirect('/catalogs/language', true);
    }

    handleLoadPage(pageNumber) {
        // pageNumber x this.state.size = offset.
        this.setRouteState('offset', pageNumber * 20)
            .apply();
        return;
    }

    /**
     * Handle clicks on pagination controls.
     * @param  {Number} pageNumber page to load.
     */

    handleSearchTerm(term) {
        // FIXME: review how to enable this again.
        // The main problem is when the user wants to search using
        // both the global search box and the filter options.
        this.setRouteState('title', term)
            .setRouteState('size', 20)
            .clearRouteState('offset')
            .apply();
        return;
    }

    init(props) {
        if (props.route.mode === 'edit') {
            GroupActions.findGroupById(GroupConstants.LANGUAGE, props.params.id);
            GroupActions.getGroupTitles(props.params.id, this.getRouteState().get('offset'), this.getRouteState().get('offset'), this.getRouteState().get('title'), GroupConstants.LANGUAGE.name);
        } else {
            GroupActions.clear();
        }
    }

    render() {
        let actionsMenu;
        let mode = this.props.route.mode;
        let eventsAndNotes;
        let historyTab;
        let titlesTab;

        let activePage = Math.ceil((this.props.location.query.offset || 0) / 20 ) || 0;
        let totalPages = Math.ceil(this.state.totalCount/20) || 0;

        let validations = GroupStore.getLanguageValidations();

        let summaryRoute = `/catalogs/language/${this.props.params.id}`;

        // The false values are hard-coded and should be removed once the endpoint gets fixed [BRAIN-1551]
        actionsMenu = <ActionsMenu
            canDelete={false}
            canEdit={false}
            canSave={false}
            hideSaveAndSync={!this.props.permissions.canEdit}
            onCancel={this.handleCancel}
            onDelete={false}
            onSave={false}
        />;

        eventsAndNotes = (
            <SideNotes
                basePath={`/catalogs/language/${this.props.params.id}`}
                dialogMessage={this.context.intl.messages['common.side-notes.add-note.body']}
                disabled={true}
                notes={this.state.history.toJS().slice(0, 4)}
                onAddNote={this.addNote}
                title={this.context.intl.messages['common.side-notes.title']}
            />
        );

        historyTab = <Tab route={`/catalogs/language/${this.props.params.id}/history`} title={this.context.intl.messages['common.tab-title.history']} tabClassName="pull-right">
            <History
                actionObject={ActionHistoryConstants.ACTION_OBJECTS.GROUP}
                id={this.props.params.id}
                pagination
                pathname={this.props.location.pathname}
                query={this.props.location.query}
            />
        </Tab>;

        titlesTab = <Tab route={`/catalogs/language/${this.props.params.id}/titles`} title={this.context.intl.messages['common.tab-title.titles']}>
            <h3><i className="far fa-newspaper"></i>&nbsp;{this.context.intl.messages['common.tab-title.titles']}</h3>
            <hr/>
            <div className="col-md-6 col-md-offset-3">
                <SearchBox onChange={this.handleSearchTerm} value={this.getRouteState().get('language')}/>
            </div>
            <ListTitles
                activePage={activePage}
                complete={this.state.complete}
                displayLinks={this.props.permissions.canReadTitles}
                onPageChange={this.handleLoadPage}
                sortDirection={this.getRouteState().get('sort-direction') || 'desc'}
                sortFieldName={this.getRouteState().get('sort-field-name') || 'updatedDate'}
                titles={this.state.groupTitles}
                totalPages={totalPages}
            />
        </Tab>;

        let summaryTab = <Tab route={summaryRoute} title={this.context.intl.messages['common.tab-title.summary']}>
            <Summary
                disabled={true}
                languageCatalog={this.state.languageCatalog}
                mode={this.props.route.mode}
            />
        </Tab>;

        let entityName;

        if (this.state.languageCatalog.get('name')) {
            entityName = this.state.languageCatalog.get('name');
        }

        return (
            <DocumentTitle
                message={`document-titles.${mode}-language-catalogs`} entityName={entityName}
            >
                <div>
                    <StickyHeader>
                        <div className="col-md-6">
                            <h1>
                                <i className="fas fa-book"></i>&nbsp;{this.context.intl.messages[`catalogs.language.${mode}.title`]}
                                &nbsp;
                            </h1>
                        </div>
                        {actionsMenu}
                    </StickyHeader>
                    <Preloader show={this.state.showPreloader} fixed loadingDots>
                        <section className="content" id="contentContainer">
                            <div className="row">
                                <div className="col-lg-9">
                                    <Tabs location={this.props.location}>
                                        {summaryTab}
                                        {titlesTab}
                                        {historyTab}
                                    </Tabs>
                                </div>
                                <div className="col-lg-3">
                                    <ErrorsWarnings title={this.context.intl.messages['common.errors-warnings-title']} validations={validations}/>
                                    {eventsAndNotes}
                                </div>
                            </div>
                        </section>
                    </Preloader>
                </div>
            </DocumentTitle>
        );
    }
}

export default WithStoreOnRoute(Container.create(Create));
