/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, ControlLabel, Modal, FormGroup} from 'react-bootstrap';

import {FormSection} from '~/src/common/form/form';
import {GroupConstants} from '~/src/security/group/group-actions';
import GroupSelect from '~/src/security/group/group-select';

class AddCatalogModal extends React.Component {

    static get propTypes() {
        return {
            disabled: PropTypes.bool,
            onConfirmAddAssets: PropTypes.func.isRequired,
            onHide: PropTypes.func.isRequired,
            onUpdateSelectedCatalogs: PropTypes.func.isRequired,
            selectedAssetCatalogs: PropTypes.instanceOf(Immutable.OrderedSet).isRequired,
            show: PropTypes.bool,
        };
    }

    static get defaultProps() {
        return {
            disabled: true,
            show: false,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
        };
    }

    constructor(props) {
        super(props);

        this.handleClose = this.handleClose.bind(this);
        this.handleConfirmAddAssets = this.handleConfirmAddAssets.bind(this);
        this.handleSelectAssetCatalogs = this.handleSelectAssetCatalogs.bind(this);
    }

    handleClose() {
        this.props.onUpdateSelectedCatalogs();
        this.props.onHide();
    }

    handleConfirmAddAssets() {
        this.props.onConfirmAddAssets();
        this.handleClose();
    }

    handleSelectAssetCatalogs(groups) {
        this.props.onUpdateSelectedCatalogs(groups);
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={this.handleClose}>
                <Modal.Header className="bg-gray">
                    <Modal.Title className="text-center" >{this.context.intl.messages['events.assets.copy-catalog']}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormSection>
                        <p className="text-center">{this.context.intl.messages['events.assets.copy-catalog.modal.description']}</p>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['events.assets.copy-catalog.modal.asset-catalogs']}</ControlLabel>&nbsp;
                            <GroupSelect
                                disabled={this.props.disabled}
                                onChange={this.handleSelectAssetCatalogs}
                                selected={this.props.selectedAssetCatalogs}
                                type={GroupConstants.ASSET}
                            />
                        </FormGroup>
                    </FormSection>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="btn btn-default-outline pull-left Ml(5)"
                        onClick={this.handleClose}
                    >
                        <i className="fas fa-ban" />&nbsp;{this.context.intl.messages['common.cancel']}
                    </Button>
                    <Button
                        className="btn btn-primary-outline"
                        disabled={!this.props.selectedAssetCatalogs.size}
                        onClick={this.handleConfirmAddAssets}
                    >
                        <i className="fas fa-plus" />&nbsp;{this.context.intl.messages['events.assets.add-assets']}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default AddCatalogModal;
