/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

import Dispatcher from '../../dispatcher/dispatcher';
import Request from '../../request';
import {UserConstants} from '../../user/user-actions';

const CONSTANTS = {
    APPLICANT_BATCH_SIZE: 50,
    CLEAR: 'applicant_navigation.clear',
    SET_NAVIGATION: 'applicant_navigation.set_navigation'
};

class ApplicantNavigationActions {

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
    }

    setNavigation(id, offset = 0, params) {
        if (!id) {
            return;
        }

        let statusList = [
            UserConstants.STATUS.APPROVED.id,
            UserConstants.STATUS.PRE_APPROVED.id,
            UserConstants.STATUS.VALIDATED.id,
            UserConstants.STATUS.PRE_VALIDATED.id,
            UserConstants.STATUS.NEEDS_REVIEW.id,
            UserConstants.STATUS.REJECTED.id
        ];
        if (params) {
            params = params.toJS();
            statusList = params['account-status'];
        }

        let queryParams = {};
        queryParams.offset = offset;
        queryParams.size = CONSTANTS.APPLICANT_BATCH_SIZE;
        queryParams['sort-field-name'] = 'updatedDate';
        queryParams['sort-direction'] = 'desc';
        queryParams['account-status'] = statusList;

        Request.get('user').query(queryParams).exec()
            .then(res => {
                let applicants = Immutable.fromJS(res.body.results);
                let applicantIndex = applicants.findIndex(applicant => applicant.get('id') === id);
                let next;
                let newOffset = offset + CONSTANTS.APPLICANT_BATCH_SIZE;
                let prev;
                let hasApplicants = !!applicants.size;

                if (applicantIndex !== -1) {
                    newOffset = offset;
                    if (applicantIndex !== 0) {
                        prev = applicants.getIn([applicantIndex - 1, 'id']);
                    }
                    if (applicantIndex + 1 < applicants.size) {
                        next = applicants.getIn([applicantIndex + 1, 'id']);
                    }

                    switch (applicantIndex) {
                    case 0:
                        if (newOffset !== 0) {
                            newOffset--;
                        }
                        break;
                    case CONSTANTS.APPLICANT_BATCH_SIZE - 1:
                        newOffset++;
                        break;
                    }
                }
                Dispatcher.dispatch({
                    actionType: CONSTANTS.SET_NAVIGATION,
                    next,
                    newOffset,
                    prev,
                    hasApplicants
                });
                return;
            }).catch(err => {
                throw err;
            });
        return;
    }
}

const actions = new ApplicantNavigationActions();

export {
    actions as ApplicantNavigationActions,
    CONSTANTS as ApplicantNavigationConstants
};
