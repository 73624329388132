/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {FormatTitleReleaseDate} from '@wbdt-sie/brainiac-web-common';
import ClassNames from 'classnames';
import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {TITLE_FIELDS, TITLE_FIELDS_STATUS, TITLE_TYPE_FIELDS_MAP, SYNOPSIS_METADATA_FIELDS} from './fields';
import AGGridWrapper from '../common/ag-grid';
import {WithStoreOnRoute} from '../common/store-on-route';
import {TitleConstants} from '../titles/title-actions';
import {TitleStatusActions} from '../titles/title-status-actions';

let TITLE_TYPES = {};
TITLE_TYPES[TitleConstants.TITLE_CATEGORY_GROUPS.EPISODE] = 'Episode';
TITLE_TYPES[TitleConstants.TITLE_CATEGORY_GROUPS.SEASON] = 'Season';
TITLE_TYPES[TitleConstants.TITLE_CATEGORY_GROUPS.SERIES] = 'Series';
TITLE_TYPES[TitleConstants.TITLE_CATEGORY_GROUPS.SINGLE_RELEASE] = 'Theatrical Feature';
TITLE_TYPES[TitleConstants.TITLE_CATEGORY_GROUPS.FORMAT_RIGHTS] = 'Format';

const FIELD_VALUES = Object.keys(TITLE_FIELDS_STATUS).reduce( /* istanbul ignore next */ (m, k) => {
    const s = TITLE_FIELDS_STATUS[k];
    m[s.id] = s;
    return m;
}, {});

const showDialog = function(title, field) {
    TitleStatusActions.show(title, field);
};


const seasonFromFullRunningOrder = function(fullRunningOrder) {
    let season = 0;
    if (fullRunningOrder && fullRunningOrder.length > 5) {
        season = parseInt(fullRunningOrder.slice(1, 3));
    }
    return season;
};

class CommonColumnCellRenderer extends React.Component {
    static get propTypes() {
        return {
            data: PropTypes.object.isRequired,
            value: PropTypes.func.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);
    }

    render() {
        const title = this.props.data;
        return this.props.value(title, this.context);
    }
}

const synopsisMetadataColumnByField = function(fieldDefinition, canEditProp) {
    return {
        id: fieldDefinition.id,
        headerName: fieldDefinition.name,
        minWidth: parseInt(fieldDefinition.width, 10),
        headerColor: fieldDefinition.headerColor,
        width: parseInt(fieldDefinition.width, 10)*1.2,
        values: fieldDefinition.values,
        isEditable: /* istanbul ignore next */(title) => {
            const valid = TITLE_TYPE_FIELDS_MAP[title.get('category')]?.titleType.fields.filter( f => f.id === fieldDefinition.id);
            return valid?.length > 0;
        },
        cellStyle: {width: parseInt(fieldDefinition.width, 10)},
        cellRendererFramework: CommonColumnCellRenderer,
        cellRendererParams: {
            value: (title) => /* istanbul ignore next */{
                let value = '';
                let style = 'title-status status-cell';
                let fields = title.get('fieldStatusValues').filter( f => f.get('titleFieldNameType') === fieldDefinition.id);
                let editable = canEditProp;

                const titleFieldStatusValues = title.get('fieldStatusValues');
                const fsvFiltered = titleFieldStatusValues.filter(fsv => SYNOPSIS_METADATA_FIELDS.some(smf => smf.id === fsv.get('titleFieldNameType')));
                const hideSynopsisLogLines = fsvFiltered.some(f => f.get('titleFieldStatusType') === TITLE_FIELDS_STATUS.HIDDEN.id);

                if (fields.size) {
                    let fieldValue = FIELD_VALUES[fields.get(0).get('titleFieldStatusType')];

                    if (fieldValue === TITLE_FIELDS_STATUS.HIDDEN || (fieldValue === TITLE_FIELDS_STATUS.MISSING && hideSynopsisLogLines)) {
                        value = <div>
                            {fieldValue.value.toUpperCase()}
                            <br/>
                            <a href="#" className="status-history cursor-not-allowed" title="">
                                <i className="fas fa-lock-alt"/>
                            </a>
                        </div>;
                        editable = false;
                        style += ' cursor-not-allowed ';
                    } else {
                        value = <div>
                            {fieldValue.value.toUpperCase()}
                        </div>;
                    }

                    style += ' '+ fieldValue.statusStyle;
                }
                let onClick;
                const valid = TITLE_TYPE_FIELDS_MAP[title.get('category')].titleType.fields.filter( f => f.id === fieldDefinition.id);
                if (valid.length > 0) {
                    if (editable) {
                        onClick = showDialog.bind(this, title, fieldDefinition);
                    }
                } else {
                    style = 'non-editable status-cell';
                }
                return <div className={style} onClick={onClick}>{value}</div>;
            }
        }
    };
};

const columnByField = function(fieldDefinition, canEditProp) {
    return {
        id: fieldDefinition.id,
        headerName: fieldDefinition.name,
        values: fieldDefinition.values,
        headerColor: fieldDefinition.headerColor,
        minWidth: parseInt(fieldDefinition.width, 10),
        width: parseInt(fieldDefinition.width, 10)*1.2,
        isEditable: /* istanbul ignore next */(title) => {
            const valid = TITLE_TYPE_FIELDS_MAP[title.get('category')]?.titleType.fields.filter( f => f.id === fieldDefinition.id);
            return valid?.length > 0;
        },
        cellRendererFramework: CommonColumnCellRenderer,
        cellRendererParams: {
            value: /* istanbul ignore next */(title) => {
                let value = '';
                let style = 'title-status status-cell';

                let fields = title.get('fieldStatusValues').filter( f => f.get('titleFieldNameType') === fieldDefinition.id);

                if (fields.size) {
                    let fieldValue = FIELD_VALUES[fields.get(0).get('titleFieldStatusType')];
                    value = <div>
                        {fieldValue.value.toUpperCase()}
                    </div>;

                    style += ' '+ fieldValue.statusStyle;
                }
                let onClick;
                let valid = TITLE_TYPE_FIELDS_MAP[title.get('category')].titleType.fields.filter( f => f.id === fieldDefinition.id);
                if (valid.length > 0) {
                    // editable;
                    if (canEditProp) {
                        onClick = showDialog.bind(this, title, fieldDefinition);
                    }
                } else {
                    style = 'non-editable status-cell';
                }
                return <div className={style} onClick={onClick}>{value}</div>;
            }
        }
    };
};

const getColumns = function(canEdit) {
    return [
        {
            headerName: 'RO',
            minWidth: 40,
            width: 80,
            cellRendererFramework: CommonColumnCellRenderer,
            cellRendererParams: {
                value: /* istanbul ignore next */(title) => {
                    let value = <div className="text-center status-cell">&nbsp;</div>;

                    switch (title.get('categoryGroup')) {
                    case TitleConstants.TITLE_CATEGORY_GROUPS.EPISODE:
                        let season = seasonFromFullRunningOrder(title.get('fullRunningOrder'));
                        value = <div className="text-center status-cell"><small className="text-gray">S{season}</small>{title.get('runningOrder')}</div>;
                        break;
                    case TitleConstants.TITLE_CATEGORY_GROUPS.SEASON:
                        value = <div className="text-center status-cell"><small className="text-gray">S{title.get('season')}</small></div>;
                        break;
                    case TitleConstants.TITLE_CATEGORY_GROUPS.SERIES:
                        value = <div className="text-center status-cell"><small className="text-gray">SERIES</small></div>;
                        break;
                    case TitleConstants.TITLE_CATEGORY_GROUPS.SINGLE_RELEASE:
                        value = <div className="text-center status-cell"><small className="text-gray">FEATURE</small></div>;
                        break;
                    }

                    return value;
                }
            }
        },
        {
            headerName: 'Parent',
            minWidth: 280,
            cellRendererFramework: CommonColumnCellRenderer,
            cellRendererParams: {
                value: /* istanbul ignore next */(title) => {
                    let value = '';
                    switch (title.get('categoryGroup')) {
                    case TitleConstants.TITLE_CATEGORY_GROUPS.EPISODE:
                        let season = seasonFromFullRunningOrder(title.get('fullRunningOrder'));
                        value = <a href={`/titles/${title.get('parentTitleId')}`}>{title.get('parentTitleDisplayName')} (S{season})</a>;
                        break;
                    case TitleConstants.TITLE_CATEGORY_GROUPS.SEASON:
                        value = <a href={`/titles/${title.get('parentTitleId')}`}>{title.get('parentTitleDisplayName')}</a>;
                        break;
                    default:
                        value = <a href={`/titles/${title.get('id')}`}> {title.get('displayName')}</a>;
                    }
                    return <div className="status-cell">{value}</div>;

                }
            }
        },
        {
            headerName: 'Title',
            minWidth: 280,
            width: 310,
            cellRendererFramework: CommonColumnCellRenderer,
            cellRendererParams: {
                value: /* istanbul ignore next */(title) => {
                    let value = '';
                    switch (title.get('categoryGroup')) {
                    case TitleConstants.TITLE_CATEGORY_GROUPS.EPISODE:
                        value = <a href={`/titles/${title.get('id')}`}> {title.get('displayName')}</a>;
                        break;
                    case TitleConstants.TITLE_CATEGORY_GROUPS.SEASON:
                        value = <a href={`/titles/${title.get('id')}`}> {title.get('displayName')} (S{title.get('season')})</a>;
                        break;
                    }
                    return <div className="status-cell">{value}</div>;
                }
            }
        },
        {
            headerName: 'Title Type',
            minWidth: 200,
            cellRendererFramework: CommonColumnCellRenderer,
            cellRendererParams: {
                value: /* istanbul ignore next */(title) => {
                    return <div className="status-cell">{title.get('categoryName')}</div>;
                }
            }
        },
        {
            headerName: 'MPM',
            width: 80,
            cellRendererFramework: CommonColumnCellRenderer,
            cellRendererParams: {
                value: /* istanbul ignore next */(title) => <div className="title-status status-cell">{title.get('mpmNumber')}</div>
            }
        },
        {
            id: TITLE_FIELDS.RELEASE_DATE.id,
            headerName: TITLE_FIELDS.RELEASE_DATE.name,
            headerColor: TITLE_FIELDS.RELEASE_DATE.headerColor,
            values: TITLE_FIELDS.RELEASE_DATE.values,
            minWidth: parseInt(TITLE_FIELDS.RELEASE_DATE.width, 10),
            width: parseInt(TITLE_FIELDS.RELEASE_DATE.width, 10)*1.2,
            cellRendererFramework: CommonColumnCellRenderer,
            cellRendererParams: {
                value: /* istanbul ignore next */(title, context) => {
                    let t = title;
                    if (!t.get('titleReleaseDate')) {
                        t = title.getIn(['releases', 0]);
                    }

                    return <div className="title-status status-cell">{FormatTitleReleaseDate(t, context.intl.messages)}</div>;
                }
            }
        },
        {
            id: TITLE_FIELDS.RELEASE_COMPANY_PLATFORM.id,
            headerName: TITLE_FIELDS.RELEASE_COMPANY_PLATFORM.name,
            headerColor: TITLE_FIELDS.RELEASE_COMPANY_PLATFORM.headerColor,
            values: TITLE_FIELDS.RELEASE_COMPANY_PLATFORM.values,
            minWidth: parseInt(TITLE_FIELDS.RELEASE_COMPANY_PLATFORM.width, 10),
            width: parseInt(TITLE_FIELDS.RELEASE_COMPANY_PLATFORM.width, 10)*1.2,
            cellRendererFramework: CommonColumnCellRenderer,
            cellRendererParams: {
                value: /* istanbul ignore next */(title) => {
                    const domesticReleaseCompanyName = title.getIn(['titleReleaseList', 0, 'domesticReleaseCompanyName']);
                    return <div className="title-status status-cell">{domesticReleaseCompanyName}</div>;
                }
            }
        },
        {
            id: TITLE_FIELDS.LAST_UPDATED.id,
            headerName: TITLE_FIELDS.LAST_UPDATED.name,
            headerColor: TITLE_FIELDS.LAST_UPDATED.headerColor,
            values: TITLE_FIELDS.LAST_UPDATED.values,
            minWidth: parseInt(TITLE_FIELDS.LAST_UPDATED.width, 10),
            width: parseInt(TITLE_FIELDS.LAST_UPDATED.width, 10)*1.2,
            cellRendererFramework: CommonColumnCellRenderer,
            cellRendererParams: {
                value: /* istanbul ignore next */(title, context) => {
                    const updatedDate = Moment(title.get('updatedDate')).format(context.intl.messages['date-format-title-release-0']);

                    return <div className="title-status status-cell">{updatedDate}</div>;
                }
            }
        },
        {
            id: TITLE_FIELDS.ACTIVE.id,
            headerName: TITLE_FIELDS.ACTIVE.name,
            headerColor: TITLE_FIELDS.ACTIVE.headerColor,
            values: TITLE_FIELDS.ACTIVE.values,
            minWidth: parseInt(TITLE_FIELDS.ACTIVE.width, 10),
            width: parseInt(TITLE_FIELDS.ACTIVE.width, 10)*1.2,
            cellRendererFramework: CommonColumnCellRenderer,
            cellRendererParams: {
                value: /* istanbul ignore next */(title) => {
                    let onClick;
                    if (canEdit) {
                        onClick = showDialog.bind(this, title, TITLE_FIELDS.ACTIVE);
                    }

                    let fieldValue = TITLE_FIELDS_STATUS.INACTIVE;
                    let style = TITLE_FIELDS_STATUS.INACTIVE.statusStyle;
                    if (title.get('active') === 1) {
                        style = TITLE_FIELDS_STATUS.ACTIVE.statusStyle;
                        fieldValue = TITLE_FIELDS_STATUS.ACTIVE;
                    }

                    return (
                        <div
                            className={ClassNames('title-status status-cell', style)}
                            onClick={onClick}
                        >{fieldValue.value.toUpperCase()}</div>
                    );
                }
            }
        },
        columnByField(TITLE_FIELDS.GALLERY, canEdit),
        columnByField(TITLE_FIELDS.KEY_ART, canEdit),
        columnByField(TITLE_FIELDS.DEFAULT_IMAGE, canEdit),
        columnByField(TITLE_FIELDS.LOGO, canEdit),
        columnByField(TITLE_FIELDS.EPISODIC_UNIT_PHOTO, canEdit),
        columnByField(TITLE_FIELDS.SOCIAL_MEDIA, canEdit),
        columnByField(TITLE_FIELDS.SCREENER, canEdit),
        columnByField(TITLE_FIELDS.VAM, canEdit),
        columnByField(TITLE_FIELDS.TRAILER_PROMO, canEdit),
        columnByField(TITLE_FIELDS.PRIF, canEdit),
        synopsisMetadataColumnByField(TITLE_FIELDS.SYNOPSIS, canEdit),
        synopsisMetadataColumnByField(TITLE_FIELDS.SHORT_SYNOPSIS, canEdit),
        synopsisMetadataColumnByField(TITLE_FIELDS.LOG_LINE_180, canEdit),
        synopsisMetadataColumnByField(TITLE_FIELDS.LOG_LINE_60, canEdit),
        {
            id: TITLE_FIELDS.PRESS_KIT.id,
            headerName: TITLE_FIELDS.PRESS_KIT.name,
            values: TITLE_FIELDS.PRESS_KIT.values,
            width: parseInt(TITLE_FIELDS.PRESS_KIT.width, 10),
            cellRendererFramework: CommonColumnCellRenderer,
            cellRendererParams: {
                value: /* istanbul ignore next */(title) => {
                    let fields = title.get('fieldStatusValues').filter( f => f.get('titleFieldNameType') === TITLE_FIELDS.PRESS_KIT.id);
                    let onClick;
                    let style = 'title-status status-cell';
                    let value = '';

                    switch (title.get('categoryGroup')) {
                    case TitleConstants.TITLE_CATEGORY_GROUPS.SEASON:
                    case TitleConstants.TITLE_CATEGORY_GROUPS.SINGLE_RELEASE:
                        if (fields.size) {
                            let fieldValue = FIELD_VALUES[fields.get(0).get('titleFieldStatusType')];
                            value = <div>
                                {fieldValue.value.toUpperCase()}
                            </div>;

                            style += ' '+ fieldValue.statusStyle;
                        }
                        if (canEdit) {
                            onClick = showDialog.bind(this, title, TITLE_FIELDS.PRESS_KIT);
                        }
                        break;
                    default:
                        style = 'non-editable status-cell';
                        break;
                    }

                    return <div className={style} onClick={onClick}>{value}</div>;
                }
            }
        },
        columnByField(TITLE_FIELDS.SCRIPTS, canEdit),
        columnByField(TITLE_FIELDS.CREDITS, canEdit),
        columnByField(TITLE_FIELDS.RELEASE_COMPANY, canEdit),
        columnByField(TITLE_FIELDS.RELEASE_TIME, canEdit),
        columnByField(TITLE_FIELDS.GENRE, canEdit),
        columnByField(TITLE_FIELDS.THEMES, canEdit),
        columnByField(TITLE_FIELDS.CATALOGS, canEdit),
        columnByField(TITLE_FIELDS.MASTERING_INFO, canEdit),
    ];
};

class Table extends Component {

    static get propTypes() {
        return {
            canEdit: PropTypes.bool,
            hide: PropTypes.instanceOf(Immutable.Set),
            titles: PropTypes.instanceOf(Immutable.List).isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            canEdit: false,
            hide: Immutable.Set()
        };
    }

    constructor(props) {
        super(props);
    }

    render() {
        let hide = this.props.hide;
        const rowData = this.props.titles;

        const canEdit = this.props.canEdit;
        const columnsDefs = getColumns(canEdit);

        const filtered = columnsDefs.filter(/* istanbul ignore next */c => !hide.has(c.id));

        return (
            <AGGridWrapper
                columnDefs={filtered}
                defaultColDef={{
                    sortable: false
                }}
                rowHeight={44}
                rowData={rowData}
                onGridSizeChanged={/* istanbul ignore next */()=> void 0}
                serverSidePagination
                pagination={false}
                paginationPageSize={40}
            />
        );
    }
}

export default WithStoreOnRoute(Table);

// for testing porpouses!
export {
    seasonFromFullRunningOrder,
    showDialog,
    columnByField,
    getColumns,
    CommonColumnCellRenderer
};
