/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {PublishingListConstants} from './publishing-list-actions';
import Validations from '../common/validations/validations';
import Dispatcher from '../dispatcher/dispatcher';
import {ActionHistoryConstants} from '../system/action-history/action-history-actions';

/**
 * Look for a constant with id === objectId in a map.
 */
let findInConstant = function(constants, objectId) {
    return Immutable.fromJS(
        Object.keys(constants)
            .map(k => constants[k])
            .filter(obj => objectId === obj.id)[0]
    );
};

const PublishingListValidations = {
    displayName: {
        label: 'publishing-list.display-name',
        validations: [Validations.max(50), Validations.required]
    },
    name: {
        label: 'publishing-list.name',
        validations: [Validations.max(50), Validations.required]
    },
    id: {
        label: 'publishing-list.id',
        validations: [Validations.max(15)]
    },
    description: {
        label: 'publishing-list.description',
        validations: [Validations.max(1000)]
    },
    purposeType: {
        label: 'publishing-list.purpose',
        validations: [Validations.required]
    },
    summaryLinkURL: {
        label: 'publishing-list.summary-link-url',
        validations: [Validations.max(400)]
    },
    summaryLinkName: {
        label: 'publishing-list.summary-link-name',
        validations: [Validations.max(200)]
    },
    listItems: {
        label: 'publishing-list.list-item.tab.title',
        validations: [Validations.custom(
            function() {
                return 'This list contains items that cannot be displayed.';
            },
            function() {
                let isValid = true;
                if (store.getState().getIn(['publishingList', 'purposeType']) === PublishingListConstants.PURPOSE_TYPES.NAVIGATION_MENU.id) {
                    store.getState().get('listItems').forEach(item => {
                        if (item.get('targetType') !== 3) {
                            isValid = false;
                        }
                    });
                }
                return isValid;
            }
        )]
    },
};

const PublishingListItemValidations = {
    targetUrl: {
        label: 'publishing-list.list-item.item.targetUrl',
        validations: [Validations.requiredIf(() => {
            const targetType = store.getState().getIn(['item', 'targetType']);
            return targetType !== PublishingListConstants.TARGET_TYPES.TITLE && targetType !== PublishingListConstants.TARGET_TYPES.ASSET;
        }), Validations.max(200)]
    },
};

class PublishingListStore extends ReduceStore {
    getInitialState() {
        let state = Immutable.Map({
            filters: Immutable.Map(),
            offset: 0,
            item: Immutable.Map({
                isHTML: true,
                userGroups: Immutable.List()
            }),
            // Paging for list items.
            itemsOffset: 0,
            itemsSize: 1000,
            listItems: Immutable.List(),
            publishingList: Immutable.Map({
                active: true,
                history: Immutable.List()
            }),
            itemImageUploaded: null,
            itemVideoUploaded: null,
            newLanguage: undefined,
            purposeList: Immutable.fromJS(PublishingListConstants.toArray('PURPOSE_TYPES')),
            publishingListImageFiles: [],
            publishingLists: Immutable.List(),
            size: 0,
            showAddNew: false,
            showModal: false,
            total: 0
        });

        return state.merge({
            originalListItems: state.get('listItems'),
            originalPublishingList: state.get('publishingList')
        });
    }

    // Return a purpose by id.
    getPurposeTypes(id) {
        return findInConstant(PublishingListConstants.PURPOSE_TYPES, id);
    }

    getValidations() {
        return Validations.validate(this.getState().get('publishingList'), PublishingListValidations);
    }

    getItemValidations() {
        return Validations.validate(this.getState().get('item'), PublishingListItemValidations);
    }

    reduce(state, action) {
        switch (action.actionType) {
        case ActionHistoryConstants.ADD_NOTE.SUCCESS:
            if (action.actionObjectType === ActionHistoryConstants.ACTION_OBJECTS.PUBLISHLIST) {
                state = state.updateIn(['publishingList', 'history'], history => history.unshift(action.note));
            }
            break;

        case PublishingListConstants.CLEAR:
            state = this.getInitialState();
            break;

        case PublishingListConstants.FINDBY.START:
            state = this.getInitialState();
            break;

        case PublishingListConstants.FINDBY.SUCCESS:
            state = state.merge({
                publishingList: action.publishingList,
                originalPublishingList: action.publishingList
            });
            break;

        case PublishingListConstants.HIDE_DETAIL:
            state = state.set('showModal', false);
            break;

        case PublishingListConstants.HIDE_FULL_DESCRIPTION:
            state = state.set('showDescriptionModal', false);
            break;

        case PublishingListConstants.IMAGE.SAVE.SUCCESS:
            state = state.mergeIn(['publishingList'], {
                imageS3Path: action.imageS3Path,
                imageUrl: action.imageUrl
            });
            state = state.set('originalPublishingList', state.get('publishingList'));
            state = state.set('publishingListImageFiles', []);
            break;

        case PublishingListConstants.ITEMS.CATALOGS.GET.SUCCESS:
            state = state.setIn(['listItems', action.index, 'catalogs'], action.catalogs);
            break;

        case PublishingListConstants.ITEMS.CLEAR:
            state = state.merge({
                itemImageUploaded: null,
                itemVideoUploaded: null,
                listItems: Immutable.List(),
                originalListItems: Immutable.List()
            });
            break;

        case PublishingListConstants.ITEMS.GO_TO_PAGE:
            state = state.set(
                'itemsOffset',
                action.pageNumber * state.get('itemsSize')
            );
            break;

        case PublishingListConstants.ITEMS.EDIT.CLEAR:
            state = state.set('item', Immutable.Map({
                isHTML: true,
                userGroups: Immutable.List(),
            }));
            state = state.merge({
                itemImageUploaded: null,
                itemVideoUploaded: null
            });
            break;

        case PublishingListConstants.ITEMS.EDIT.DELETE:
            state = state.update('listItems', items => {
                return items.remove(action.index).map(
                    // Update displayOrder to the new index.
                    (item, index) => item.set('displayOrder', index)
                );
            });
            break;

        case PublishingListConstants.ITEMS.EDIT.SET:
            state = state.set('item', state.getIn(['listItems', action.index]));
            break;

        case PublishingListConstants.ITEMS.EDIT.SAVE:
            state = state.merge({
                item: action.item
            });
            state = state.update('listItems', items => {
                if (action.item.get('displayOrder') !== undefined) {
                    return items.set(action.item.get('displayOrder'), action.item);
                } else {
                    return items.unshift(action.item).map((item, index) => item.set('displayOrder', index));
                }
            });
            break;

        case PublishingListConstants.ITEMS.EDIT.SET_GROUPS:
            state = state.setIn(['item', 'userGroups'], action.groups);
            break;

        case PublishingListConstants.ITEMS.EDIT.UPDATE_ATTR:
            state = state.setIn(['item', ...action.attr.split('.')], action.value);
            break;

        case PublishingListConstants.ITEMS.GET.SUCCESS:
            state = state.merge({
                listItems: action.items,
                originalListItems: action.items
            });
            break;

        case PublishingListConstants.ITEMS.MOVE:
            state = state.update('listItems', items => {
                let item = items.get(action.from);

                return items.remove(action.from).insert(action.to, item).map((a, i) => {
                    return a.set('displayOrder', i);
                });
            });

            break;

        case PublishingListConstants.ITEMS.THUMBNAILS.GET.SUCCESS:
            state = state.update('listItems', items => items.map(item => {
                // Skip everything that is not a video.
                if (item.get('displayAssetTypeName') !== 'Video') {
                    return item;
                }

                let thumbnails = action.thumbnails[item.get('assetId').toString()];
                if (thumbnails &&
                    thumbnails.thumbnailList &&
                    thumbnails.thumbnailList[0]) {
                    item = item.setIn(['asset', 'thumbnailUrl'], thumbnails.thumbnailList[0].thumbnailUrl);
                }

                return item;
            }));
            break;

        case PublishingListConstants.GET.SUCCESS:
            state = state.merge({
                offset: action.offset,
                size: action.size,
                publishingLists: action.publishingLists,
                total: action.total
            });
            break;

        case PublishingListConstants.UPDATE:
            state = state.setIn(['publishingList', ...action.attr.split('.')], action.value);
            break;

        case PublishingListConstants.UPDATE_ATTR:
            state = state.set(action.attr, action.value);
            break;

        case PublishingListConstants.SAVE.SUCCESS:
            state = state.setIn(['publishingList', 'id'], action.id);
            state = state.set('originalPublishingList', state.get('publishingList'));
            state = state.set('originalListItems', state.get('listItems'));
            break;

        case PublishingListConstants.SHOW_DETAIL:
            state = state.set('showModal', true);
            break;

        case PublishingListConstants.SHOW_FULL_DESCRIPTION:
            state = state.set('showDescriptionModal', true);
            break;

        case PublishingListConstants.FILTER.CLEAR:
            state = state.set('filters', Immutable.Map());
            break;

        case PublishingListConstants.FILTER.SET:
            state = state.setIn(['filters', ...action.attr.split('.')], action.value);
            break;
        }


        return state;
    }
}

let store = new PublishingListStore(Dispatcher);

export default store;

export {
    PublishingListValidations,
    PublishingListItemValidations
};
