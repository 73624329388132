/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import React from 'react';

/**
 * Given a time when the clip start then get thumbnail found at that frame time
 * @param {Immutable.Map} clip clip with time frame information start and end
 * @param {Array} thumbnails all thumbnails with their time frame information
 * @param {object} expectedSize to scale to expected size
 * @param {object} extraStyles extra styles to apply to thumbnail
 */
const GetThumbnailFromClip = (clip, thumbnails, expectedSize, extraStyles) => {
    let thumbnailFrame = clip.get('clipInFrame');
    if (clip.get('thumbnailFrameNum')) {
        thumbnailFrame = clip.get('thumbnailFrameNum');
    }

    return GetThumbnailAtFrame(thumbnailFrame, thumbnails, expectedSize, extraStyles);
};

/**
 * Get thumbnail found at a frame time
 * @param {number} thumbnailFrame time frame data
 * @param {Array} thumbnails all thumbnails with their time frame information
 * @param {object} expectedSize to scale to expected size
 * @param {object} extraStyles extra styles to apply to thumbnail
 */
const GetThumbnailAtFrame = (thumbnailFrame, thumbnails, expectedSize, extraStyles) => {
    const thumbnailAtFrame = thumbnails.find((thumbnail, index) => {
        const nextThumbnail = thumbnails[index+1];
        if (nextThumbnail) {
            return thumbnailFrame > thumbnail.start && thumbnailFrame < nextThumbnail.start;
        } else {
            return thumbnailFrame > thumbnail.start;
        }
    });

    return buildImage(thumbnailAtFrame, expectedSize, extraStyles);
};

/**
 * Build img element with the thumbnail data
 * and if we want to scale to an expectedSize then we scale
 * and we can add extra styles too
 * @param {object} thumbnail data with sprite url, width, height, x and y position
 * @param {object} expectedSize to scale to expected size
 * @param {object} extraStyles extra styles to apply to thumbnail
 */
const buildImage = (thumbnail, expectedSize, extraStyles) => {
    let thumbnailSprite = <img/>;
    if (thumbnail) {
        const {x, y, width, height} = thumbnail.srcDrawRect;
        let thumbnailStyle = {
            background: `url(${thumbnail.src}) ${-x}px ${-y}px`,
            width: `${width}px`,
            height: `${height}px`
        };
        if (expectedSize) {
            const percentage = expectedSize.width / width;
            thumbnailStyle.transform = `scale(${percentage})`;
        }
        if (extraStyles) {
            thumbnailStyle = {
                ...thumbnailStyle,
                ...extraStyles
            };
        }
        thumbnailSprite = <img style={thumbnailStyle}/>;
    }

    return thumbnailSprite;
};

/**
 * Take a frameRate (or any decimal value) and return
 * a fraction. Currently used by the Accurate Player.
 * @param {number} f frameRate to convert
 */
const FrameRateToFraction = f => {
    if (f === undefined || f === null) {
        throw new Error('FrameRateToFraction: missing frameRate param');
    }

    const gcd = (a, b) => {
        if (!b) {return a;}

        return gcd(b, a % b);
    };

    const isFloat = f % 1 !== 0;
    let decimals = 0;
    if (isFloat) {
        decimals = f.toString().split('.')[1].length;
    }

    let denominator = 10 ** decimals;
    let numerator = f * denominator;

    let divisor = gcd(numerator, denominator);
    numerator /= divisor;
    denominator /= divisor;

    return {
        numerator,
        denominator
    };
};

const FramesToSeconds = (frameCount, frameRate) => {
    if (frameRate === undefined || frameRate === null) {
        throw new Error('FramesToSeconds: missing frameRate param');
    }

    const totalSeconds = Math.floor(frameCount / frameRate);
    return totalSeconds;
};

const FramesToTimecodeString = (f, frameRate) => {
    return TimecodeToString(TimecodeFromFrames(f, frameRate));
};

// Timecode helpers.
const TimecodeFromFrames = (frameCount, frameRate, dropFrame = false) => {
    if (frameRate === undefined || frameRate === null) {
        throw new Error('TimecodeFromFrames: missing frameRate param');
    }

    const totalSeconds = FramesToSeconds(frameCount, frameRate);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor(totalSeconds / 60) - (hours * 60);
    const seconds = totalSeconds - (minutes * 60) - (hours * 3600);
    // Remove decimals and always keep the integer part.
    const f = Math.floor(frameCount % frameRate);

    return Immutable.Map({
        hours,
        minutes,
        seconds,
        frames: f,
        dropFrame
    });
};

// Currently used to pretty print runtime experessed in seconds.
// Doesn't have to be frame accurate.
const TimecodeFromSeconds = (inputSeconds, frameRate, dropFrame = false) => {
    const totalSeconds = Math.floor(inputSeconds);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor(totalSeconds / 60) - (hours * 60);
    const seconds = totalSeconds - (minutes * 60) - (hours * 3600);
    const f = Math.floor((inputSeconds % 1) * frameRate);

    return Immutable.Map({
        hours,
        minutes,
        seconds,
        frames: f,
        dropFrame
    });
};

const TimecodeFromString = (timecode, dropFrame = false) => {
    const parts = timecode.split(/:|;/);

    let hours = 0, minutes = 0, seconds = 0, f = 0;
    if (parts[0]) {
        hours = parseInt(parts[0], 10);
    }
    if (parts[1]) {
        minutes = parseInt(parts[1], 10);
    }
    if (parts[2]) {
        seconds = parseInt(parts[2], 10);
    }
    if (parts[3]) {
        f = parseInt(parts[3], 10);
    }

    return Immutable.Map({
        hours,
        minutes,
        seconds,
        frames: f,
        dropFrame
    });
};

const TimecodeToFrames = (timecode, frameRate) => {
    if (frameRate === undefined || frameRate === null) {
        throw new Error('TimecodeToFrames: missing frameRate param');
    }

    return (timecode.get('hours') * 3600 + timecode.get('minutes') * 60 + timecode.get('seconds')) * frameRate + timecode.get('frames');
};

const TimecodeToString = timecode => {
    const sHours = timecode.get('hours').toString().padStart(2, '0');
    const sMinutes = timecode.get('minutes').toString().padStart(2, '0');
    const sSeconds = timecode.get('seconds').toString().padStart(2, '0');
    const sFrames = timecode.get('frames').toString().padStart(2, '0');

    return `${sHours}:${sMinutes}:${sSeconds}:${sFrames}`;
};

export {
    GetThumbnailAtFrame,
    GetThumbnailFromClip,
    FrameRateToFraction,
    FramesToSeconds,
    FramesToTimecodeString,
    TimecodeFromFrames,
    TimecodeFromSeconds,
    TimecodeFromString,
    TimecodeToFrames,
    TimecodeToString,
};
