/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {BrandConstants} from './brand-actions';
import Validations from '../common/validations/validations';
import Dispatcher from '../dispatcher/dispatcher';

import {SEVERITY, ERRORTYPE} from '~/src/common/validations/helpers';

const ALLOWED_BRAND_LOGO_WIDTH = 500;
const ALLOWED_BRAND_LOGO_HEIGHT = 500;
const ALLOWED_BRAND_LOGO_MAX_FILESIZE = 1024*1024; // 1mb

const BrandValidations = {
    name: {
        label: 'brands.summary.name',
        validations: [Validations.max(250), Validations.missing()]
    },
    description: {
        label: 'brands.summary.description',
        validations: [Validations.max(4000), Validations.missing()]
    }
};

class BrandStore extends ReduceStore {
    validateBrandLogoImageSize(img, intl) {
        if (img.size > ALLOWED_BRAND_LOGO_MAX_FILESIZE) {
            let message = intl.messages['brands.create.logo.image.filesize'];
            message = message.replace('{}', `${img.size} bytes`);

            return [{
                severity: SEVERITY.ALERT,
                errorType: ERRORTYPE.ERROR,
                message: message,
            }];
        }

        if (img.naturalWidth !== ALLOWED_BRAND_LOGO_WIDTH && img.naturalHeight !== ALLOWED_BRAND_LOGO_HEIGHT) {
            let message = intl.messages['brands.create.logo.image.dimensions'];
            message = message.replace('{}', `${img.naturalWidth}x${img.naturalHeight}px`);
            message = message.replace('{}', `${ALLOWED_BRAND_LOGO_WIDTH}x${ALLOWED_BRAND_LOGO_HEIGHT}px`);

            return [{
                severity: SEVERITY.ALERT,
                errorType: ERRORTYPE.ERROR,
                message: message,
            }];
        }

        return [];
    }

    getInitialState() {
        return Immutable.Map({
            brand: Immutable.Map(
                {files: []}
            ),
            brands: Immutable.List(),
            originalBrand: Immutable.Map(),
            showPreloader: false,
            total: 0,
        });
    }

    getValidations() {
        return Validations.validate(this.getState().get('brand'), BrandValidations);
    }

    reduce(state, action) {
        switch (action.actionType) {

        case BrandConstants.CLEAR:
            state = this.getInitialState();
            break;

        case BrandConstants.BRAND.SAVE.ERROR:
        case BrandConstants.FIND_BY_ID.ERROR:
        case BrandConstants.BRANDS.GET.ERROR:
            state = state.set('showPreloader', false);
            break;

        case BrandConstants.BRAND.SAVE.START:
        case BrandConstants.FIND_BY_ID.START:
        case BrandConstants.BRANDS.GET.START:
            state = state.set('showPreloader', true);
            break;

        case BrandConstants.UPDATE:
            state = state.setIn(['brand', ...action.attr.split('.')], action.value);
            break;

        case BrandConstants.FIND_BY_ID.SUCCESS:
            state = state.merge({
                brand: action.brand,
                originalBrand: action.brand,
                showPreloader: false,
            });
            break;

        case BrandConstants.BRAND.SAVE.SUCCESS:
            state = state.merge({
                brand: action.brand,
                originalBrand: action.brand,
                showPreloader: false,
            });
            break;

        case BrandConstants.BRANDS.GET.SUCCESS:
            state = state.merge({
                brands: action.brands,
                showPreloader: false,
                total: action.total,
            });
            break;
        }

        return state;
    }
}

export default new BrandStore(Dispatcher);
export {BrandValidations};
