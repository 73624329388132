/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';
import {Link} from 'react-router';
import ReactTable from 'react-table';

import AddExistingTitle from './add-existing-title';
import AddNewTitleModal from './add-new-title';
import {RelatedActions} from './related-actions';
import RelatedStore from './related-store';
import AssetTabStore from '../../../assets-tab/asset-tab-store';
import SlidingPanel from '../../../common/sliding-panel/sliding-panel';
import {SlidingPanelActions} from '../../../common/sliding-panel/sliding-panel-actions';
import SerieNavigationStore from '../../serie-navigation/serie-navigation-store';
import {TitleActions} from '../../title-actions';
import TitleLocalizedStore from '../../title-localized-store';
import TitleStore from '../../title-store';
import CascadeChangesModal from '../cascade-changes-modal';

import 'react-table/react-table.css';

class ListRelated extends Component {
    static get propTypes() {
        return {
            defaultShowModal: PropTypes.bool,
            /**
             * relationLabel is one of parent or children, it represents
             * which side of the relationship we want to use for the message.
             */
            location: PropTypes.object.isRequired,
            name: PropTypes.string.isRequired,
            /**
             * relationName is the name of the array where we are storing
             * the related titles. Right now our structure is:
             * Title: {
             *     related: {
             *         children: [],
             *         other: [],
             *         parents: []
             *     }
             * }
             */
            relationLabel: PropTypes.oneOf(['children', 'parents']).isRequired, // eslint-disable-line react/no-unused-prop-types
            relationName: PropTypes.oneOf(['children', 'other', 'parents']).isRequired,
            showAddNewButton: PropTypes.bool,
            showSeasonNumColumn: PropTypes.bool,
            titleMPM: PropTypes.string.isRequired,
            titleName: PropTypes.string.isRequired,
            titles: PropTypes.instanceOf(Immutable.List).isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            defaultShowModal: false,
            location: undefined,
            showAddNewButton: false,
            showSeasonNumColumn: false,
        };
    }

    static calculateState() {
        return {
            assets: AssetTabStore.getState().get('assets'),
            activatedAssets: AssetTabStore.getState().get('activatedAssets'),
            cascadeFlags: TitleStore.getState().getIn(['title', 'cascadeFlags']),
            deactvatedAssets: AssetTabStore.getState().get('deactvatedAssets'),
            original: RelatedStore.getState().get('original'),
            originalAssets: AssetTabStore.getState().get('originalAssets'),
            originalTitle: TitleStore.getState().get('originalTitle'),
            originalTitleLocalized: TitleLocalizedStore.getState().get('originalTitleLocalized'),
            related: RelatedStore.getState().get('related'),
            serieNavigation: SerieNavigationStore.getState().get('serieNavigation'),
            title: TitleStore.getState().get('title'),
            titleLocalized: TitleLocalizedStore.getState().get('titleLocalized'),
        };
    }

    static getStores() {
        return [TitleStore, AssetTabStore, TitleLocalizedStore, RelatedStore, SerieNavigationStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            id: Math.ceil(Math.random() * 100000),
            showModal: props.defaultShowModal,
            showCascadeChangesModal: false,
        }, this.constructor.calculateState());

        this.handleAddNew = this.handleAddNew.bind(this);
        this.handleCloseAddNew = this.handleCloseAddNew.bind(this);
        this.handleConfirmChanges = this.handleConfirmChanges.bind(this);
        this.handleAddExisting = this.handleAddExisting.bind(this);
        this.handleReorder = this.handleReorder.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleSaveChanges = this.handleSaveChanges.bind(this);
        this.toggleCascadeChangesModal = this.toggleCascadeChangesModal.bind(this);
        this.updatePosition = this.updatePosition.bind(this);
    }

    handleAddNew() {
        if (!this.state.title.equals(this.state.originalTitle)) {
            this.setState({showModal: true});
        } else {
            this.setState({showModal: false});
            // the rest of addNewRelatedTitle params are needed only when save is true
            TitleActions.addNewRelatedTitle(this.state.title);
        }

        return;
    }

    handleCloseAddNew() {
        this.setState({showModal: false});
        return;
    }

    handleConfirmChanges() {
        if (TitleStore.hasCascadeChanges(this.state.title, this.state.originalTitle)) {
            this.setState({showModal: false});
            this.toggleCascadeChangesModal();
        } else {
            this.handleSaveChanges();
        }
    }

    handleSaveChanges() {
        let assets = false;
        let current = this.state.assets;
        if (!this.state.originalAssets.equals(current)) {
            assets = current;
        }
        TitleActions.addNewRelatedTitle(
            this.state.title,
            this.state.originalTitle,
            assets,
            this.state.activatedAssets,
            this.state.deactvatedAssets,
            this.state.titleLocalized,
            this.state.originalTitleLocalized,
            this.state.related,
            this.state.original,
            true
        );
    }

    handleAddExisting() {
        SlidingPanelActions.show(`related-title-sliding-${this.state.id}`);
        return;
    }

    /* dragFrom and position are the location into the page (in pixels) before and after dragging the element,
    substract both values and divide by row height. Row.index is the initial position and dragDiff the new one */
    updatePosition(e, row, position) {
        if (e.target.draggable === true) {
            let dragDiff = Math.round((parseInt(this.state.dragFrom, 10) - parseInt(position, 10)) / 50);
            dragDiff = -(dragDiff);
            let newPosition = row.index + dragDiff;
            this.handleReorder(row.index, Math.abs(newPosition));
        }
    }

    handleReorder(from, to) {
        setTimeout(() => {
            RelatedActions.moveElement(this.props.relationName, 'orderWithinParent', from, to);
        }, 0);
        return;
    }

    handleRemove(index) {
        RelatedActions.removeElement(this.props.relationName, parseInt(index, 10), 'orderWithinParent');
        return;
    }

    toggleCascadeChangesModal() {
        this.setState(prevState => ({
            showCascadeChangesModal: !prevState.showCascadeChangesModal
        }));
    }

    render() {
        let columns = [{
            className: 'h-alignment row-reorder-handle',
            Header: () => <strong>#</strong>,
            headerClassName: 'h-alignment',
            maxWidth: 40,
            Cell: (c) => {
                return (
                    <div data-index={c.original.get('orderWithinParent')}>
                        {c.original.get('orderWithinParent')}
                    </div>
                );
            }
        }, {
            accessor: row => row.get('relationshipType'),
            Header: () => <strong>{this.context.intl.messages['titles.create.related.list.relation-type']}</strong>,
            id: 'relationshipType',
            maxWidth: 140
        },
        {
            Cell: (c) => <Link to={`/titles/${c.original.get('titleId')}`}>{c.original.get('titleName')}</Link>,
            Header: () => <strong>{this.context.intl.messages['titles.create.related.list.title-name']}</strong>,
        },
        {
            accessor: row => row.get('category'),
            Header: () => <strong>{this.context.intl.messages['titles.create.related.list.category']}</strong>,
            id: 'category',
            maxWidth: 150
        },
        {
            accessor: row => row.get('mpm') || '-',
            Header: () =><strong>{this.context.intl.messages['titles.create.related.list.mpm-number']}</strong>,
            id: 'mpm',
            maxWidth: 90
        },
        {
            accessor: 'actions',
            className: 'h-alignment',
            headerClassName: 'h-alignment',
            Cell: (c) => <Button bsStyle="primary" className="bg-navy remove-related-title" onClick={() => this.handleRemove(c.original.get('index'))}><i className="fas fa-trash-alt"></i>&nbsp;{this.context.intl.messages['common.remove']}</Button>,
            Header: () => <strong>{this.context.intl.messages['titles.create.related.list.actions']}</strong>,
            maxWidth: 110
        }];

        if (this.props.showSeasonNumColumn) {
            columns.splice(2, 0, {
                accessor: row => row.get('season') || '-',
                className: 'h-alignment',
                Header: () => <strong>{this.context.intl.messages['titles.create.related.list.season-number']}</strong>,
                headerClassName: 'h-alignment',
                id: 'season',
                maxWidth: 90
            });
        }

        const data = Immutable.fromJS(this.props.titles.map((t, i) => {
            let relationshipType = '-';
            if (t.get('relationshipType')) {
                relationshipType = TitleStore.getRelationshipType(t.get('relationshipType')).get(this.props.relationLabel);
            }

            return Immutable.fromJS({
                orderWithinParent: t.get('orderWithinParent'),
                relationshipType: relationshipType,
                season: t.get('season'),
                titleName: t.get('name'),
                titleId: t.get('id'),
                category: TitleStore.getTitleType(t.get('category')).get('name'),
                mpm: t.get('mpmNumber'),
                actions: 'actions',
                index: i,
            });
        }));

        let modal;
        if (this.state.showModal) {
            modal = <AddNewTitleModal
                onConfirmSave={this.handleConfirmChanges}
                onHide={this.handleCloseAddNew}
                show={this.state.showModal}
            />;
        }

        let addNewButton;
        if (this.props.showAddNewButton) {
            addNewButton = <Button bsStyle="primary" className="pull-right" onClick={this.handleAddNew}><i className="fas fa-plus-square"></i>&nbsp;{this.context.intl.messages['common.add-new']}</Button>;
        }

        let slidingPanelTitle = <span>
            {this.context.intl.messages[`titles.create.related.add-existing.${this.props.relationName}`]}&nbsp;<small>{this.props.titleName}</small>
        </span>;

        return (
            <div>
                <h3>{this.props.name}:&nbsp;{this.props.titles.size}
                    <Link className="pull-right btn btn-primary" to={`/?related-type=${this.props.name.toLowerCase()}&related-to-mpm=${this.props.titleMPM}`}><i className="fas fa-tachometer-alt"></i>&nbsp;{this.context.intl.messages['titles.create.related.dashboard-button']}</Link>
                    <Button bsStyle="primary" className="pull-right" onClick={this.handleAddExisting}><i className="fas fa-plus-square"></i>&nbsp;{this.context.intl.messages['common.add-existing']}</Button>
                    {addNewButton}
                </h3>

                <ReactTable
                    className="-striped table-bordered table-striped related-list-tables responsive"
                    columns={columns}
                    data={data}
                    getNoDataProps= {() => {
                        if (data.size) {
                            return {style: {display: 'none'}};
                        }
                        return {};
                    }}
                    getTrProps={() => {
                        return {
                            draggable: false
                        };
                    }}
                    getTdProps={(state, row, td) => {
                        let isDraggable = td.className.indexOf('row-reorder-handle') !== -1;
                        return {
                            draggable: isDraggable,
                            onDragEnd: (e) => {
                                this.updatePosition(e, row, e.screenY);
                            },
                            onDragStart: (e) => {
                                e.dataTransfer.setData('application/node type', this);
                                // Note: do not refactor this to the function form of setState, it will break the reorder functionality,
                                // due to the event recycling and e.screenY being undefined.
                                this.setState({
                                    dragFrom: e.screenY
                                });
                            }
                        };
                    }}
                    id={`related-table-${this.props.name}`}
                    loading={false}
                    pageSize={data.size}
                    showPagination={false}
                    sortable={false}
                    resizable={false}
                />
                <SlidingPanel
                    icon="fas fa-plus-circle"
                    id={`related-title-sliding-${this.state.id}`}
                    title={slidingPanelTitle}
                >
                    <AddExistingTitle relation={this.props.relationName} slidingPanelId={`related-title-sliding-${this.state.id}`} location={this.props.location}/>
                </SlidingPanel>
                {modal}
                <CascadeChangesModal
                    flags={this.state.cascadeFlags.toJS()}
                    onClose={this.toggleCascadeChangesModal}
                    onConfirmSave={this.handleSaveChanges}
                    serieNavigation={this.state.serieNavigation}
                    show={this.state.showCascadeChangesModal}
                    title={this.state.title}
                />
            </div>
        );
    }
}

export default Container.create(ListRelated);
