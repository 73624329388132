/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

type V = string | number | symbol;

/**
 * Creates an object composed of keys generated as a value of each element of `collection` from some `key`.
 * The corresponding value of each key is an array of elements responsible for generating the key.
 * @param {string} key key of the object, value of that would be used for groupings.
 * @param {Array} collection The collection to iterate over.
 * @returns {Object} Returns the composed aggregate object.
 * @example
 *
 * groupBy('code', [{id: 1, code: 4}, {id: 2: code: 4}, {id: 3, code: 2}])
 * // => { '4': [{id: 1, code: 4}, {id: 2: code: 4}}], '2': [{id: 3, code: 2}] }
 */
export default function groupBy<K extends string, T extends { [k in K]: V }>(key: K, collection: T[]): Record<T[K], T[]> {
    return collection.reduce((memo, obj) => {
        const val = obj[key];
        memo[val] = memo[val] || [];
        memo[val].push(obj);
        return memo;
    }, {} as Record<T[K], T[]>);
}
