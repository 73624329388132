/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import {from, of, timer} from 'rxjs';
import {concatMap, flatMap, map, takeWhile, skipWhile} from 'rxjs/operators';

import {NotificationActions} from '../../common/notification/notification-actions';
import Dispatcher from '../../dispatcher/dispatcher';
import Request from '../../request';

const CONSTANTS = {
    CLEAR: 'hardac.processes.clear',
    PROCESS_TYPES: {
        BLOB_COPY_LTS: {
            id: 'blob.copy.lts',
            EVENT_TYPES: {
                BLOB_COPY_SCHEDULED: {id: 'hardac.blob.copy.scheduled'},
                BLOB_CREATED_SUCCESS: {id: 'hardac.blob.created.success'}
            }
        },
        BLOB_COPY_TIMELINE_STEREO: {
            id: 'blob.copy.timeline.stereo',
            EVENT_TYPES: {
                BLOB_COPY_SCHEDULED: {id: 'hardac.blob.copy.scheduled'},
                BLOB_CREATED_SUCCESS: {id: 'hardac.blob.created.success'}
            }
        },
        ENCODE_FOR_INBOX_PROXY: {
            id: 'encode.for.inbox.proxy',
            EVENT_TYPES: {
                AMSV3_ENCODE_DISPATCHED: {id: 'hardac.encode.mediaservicesv3.dispatched'},
                AMSV3_ENCODE_SCHEDULED: {id: 'hardac.encode.mediaservicesv3.scheduled'},
                AMSV3_ENCODE_PROCESSING: {id: 'hardac.encode.mediaservicesv3.processing'},
                AMSV3_ENCODE_SUCCESS: {id: 'hardac.encode.mediaservicesv3.success'},
                HARDAC_FAILURE: {id: 'hardac.failure'}
            }
        },
        ENCODE_FOR_TIMELINE_STEREO: {
            id: 'encode.for.timeline.stereo',
            EVENT_TYPES: {
                ENCODE_CLOUDPORT_DISPATCHED: {id: 'hardac.encode.cloudport.dispatched'},
                ENCODE_CLOUDPORT_PROCESSING: {id: 'hardac.encode.cloudport.processing'},
                ENCODE_CLOUDPORT_SCHEDULED: {id: 'hardac.encode.cloudport.scheduled'},
                ENCODE_CLOUDPORT_SUCCESS: {id: 'hardac.encode.cloudport.success'}
            }
        }
    },
    FILTER: {
        SET: 'hardac.processes.filter.set',
    },
    MESSAGES: {
        CLEAR: 'hardac.processes.messages.clear',
        GET: {
            ERROR: 'hardac.processes.messages.get.error',
            START: 'hardac.processes.messages.get.start',
            SUCCESS: 'hardac.processes.messages.get.success'
        },
        EVENT_MESSAGE: {
            GET: {
                ERROR: 'hardac.processes.messages.event-message.get.error',
                START: 'hardac.processes.messages.event-message.get.start',
                SUCCESS: 'hardac.processes.messages.event-message.get.success'
            },
            RE_PROCESS: {
                ERROR: 'hardac.processes.messages.event-message.re-process.error',
                START: 'hardac.processes.messages.event-message.re-process.start',
                SUCCESS: 'hardac.processes.messages.event-message.re-process.success'
            },
            SAVE: {
                ERROR: 'hardac.processes.messages.event-message.save.error',
                START: 'hardac.processes.messages.event-message.save.start',
                SUCCESS: 'hardac.processes.messages.event-message.save.success'
            }
        }
    },
    PROCESSES: {
        FIND_BY_ID: {
            ERROR: 'hardac.processes.find-by-id.error',
            START: 'hardac.processes.find-by-id.start',
            SUCCESS: 'hardac.processes.find-by-id.success'
        },
        GET: {
            ERROR: 'hardac.processes.get.error',
            START: 'hardac.processes.get.start',
            SUCCESS: 'hardac.processes.get.success'
        },
        GET_ERROR_MESSAGE: {
            SUCCESS: 'hardac.processes.get-error-message.success'
        },
        PROGRESS: {
            SET: 'hardac.processes.progress.set',
        },
        REPROCESS: {
            ERROR: 'hardac.processes.reprocess.error',
            START: 'hardac.processes.reprocess.start',
            SUCCESS: 'hardac.processes.reprocess.success'
        },
        REVIEW_PROGRESS: {
            START: 'hardac.processes.review-progress.start',
            SET: 'hardac.processes.review-progress.set'
        }
    },
    UPDATE: 'hardac.process.update'
};

class ProcessesActions {
    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
    }

    clearMessages() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.MESSAGES.CLEAR
        });
    }

    findById(id) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.PROCESSES.FIND_BY_ID.START
        });
        let process, errorMessage;
        const errorMessageQuery = {
            offset: 0,
            processed: false,
            size: 1
        };

        Request.get(`integration/hardac/event/${id}`)
            .exec()
            .then(response => {
                process = Immutable.fromJS(response.body);

                if (response.body.failureDate) {
                    return Request.get(`integration/hardac/event/${id}/message`).query(errorMessageQuery).exec().catch(err => {
                        NotificationActions.showAlertDanger('hardac.processes.get-error-message.error');
                        throw {
                            errorMessage: true,
                            err
                        };
                    });
                }

                return;
            }).then(response => {
                if (response && response.body.results.length) {
                    errorMessage = Immutable.fromJS(response.body.results[0]);
                }

                Dispatcher.dispatch({
                    actionType: CONSTANTS.PROCESSES.FIND_BY_ID.SUCCESS,
                    errorMessage,
                    process
                });
                return;
            }).catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.PROCESSES.FIND_BY_ID.ERROR
                });

                if (err.errorMessage) {return;}

                switch (err.status) {
                case 404:
                    NotificationActions.showAlertDanger('hardac.processes.find-by-id.error.does-not-have-access');
                    break;
                default:
                    NotificationActions.showAlertDanger('hardac.processes.find-by-id.error.does-not-load');
                    break;
                }

                throw err;
            });

        return;
    }

    get(queryParams, offset, size) {
        queryParams = queryParams.toJS();
        Dispatcher.dispatch({
            actionType: CONSTANTS.PROCESSES.GET.START
        });

        offset = offset || queryParams.offset || 0;
        size = size || 20;
        queryParams.offset = offset ;
        queryParams.size = size;
        Request.get('integration/hardac/event')
            .query(
                queryParams
            ).exec()
            .then(response => {
                const processes = Immutable.fromJS(response.body.results);
                Dispatcher.dispatch({
                    actionType: CONSTANTS.PROCESSES.GET.SUCCESS,
                    offset: response.body.offset,
                    size: response.body.size,
                    total: response.header['wbtv-total-count'],
                    processes
                });
                return;
            }).catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.PROCESSES.GET.ERROR
                });

                switch (err.status) {
                case 404:
                    NotificationActions.showAlertDanger('hardac.processes.get.error.does-not-have-access');
                    break;
                default:
                    NotificationActions.showAlertDanger('hardac.processes.error.does-not-load');
                    break;
                }

                throw err;
            });

        return;
    }

    getMessageById(id) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.MESSAGES.EVENT_MESSAGE.GET.START
        });

        Request.get(`integration/hardac/message/${id}`)
            .exec()
            .then(response => {
                const message = Immutable.fromJS(response.body);
                Dispatcher.dispatch({
                    actionType: CONSTANTS.MESSAGES.EVENT_MESSAGE.GET.SUCCESS,
                    message
                });
                return;
            }).catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.MESSAGES.EVENT_MESSAGE.GET.ERROR
                });

                switch (err.status) {
                case 404:
                    NotificationActions.showAlertDanger('hardac.processes.messages.event-message.get.error.does-not-have-access');
                    break;
                default:
                    NotificationActions.showAlertDanger('hardac.processes.messages.event-message.get.error.does-not-load');
                    break;
                }

                throw err;
            });

        return;
    }

    getMessagesByProcessIds(processes = Immutable.List(), queryParams = Immutable.fromJS({}), offset, size) {
        queryParams = queryParams.toJS();
        Dispatcher.dispatch({
            actionType: CONSTANTS.MESSAGES.GET.START
        });

        offset = offset || queryParams.offset || 0;
        size = size || 20;
        queryParams.offset = offset ;
        queryParams.size = size;

        const messagesRequests = processes.map(p => {
            return Request.get(`integration/hardac/event/${p.get('id')}/message`)
                .exec()
                .then(response => {
                    return Immutable.fromJS(response.body.results).reverse();
                }).catch(err => {
                    Dispatcher.dispatch({
                        actionType: CONSTANTS.MESSAGES.GET.ERROR
                    });

                    switch (err.status) {
                    case 404:
                        NotificationActions.showAlertDanger('hardac.processes.messages.get.error.does-not-have-access');
                        break;
                    default:
                        NotificationActions.showAlertDanger('hardac.processes.messages.error.does-not-load');
                        break;
                    }

                    throw err;
                });
        });

        Promise.all(messagesRequests).then((res) => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.MESSAGES.GET.SUCCESS,
                offset: 0,
                size: Immutable.fromJS(res).flatten(1).size,
                total: Immutable.fromJS(res).flatten(1).size,
                messages: Immutable.fromJS(res).flatten(1)
            });

            return;
        }).catch(err => {
            throw err;
        });

        return;
    }

    getMessagesByProcessId(id, queryParams = Immutable.fromJS({}), offset, size) {
        queryParams = queryParams.toJS();
        Dispatcher.dispatch({
            actionType: CONSTANTS.MESSAGES.GET.START
        });

        offset = offset || queryParams.offset || 0;
        size = size || 20;
        queryParams.offset = offset ;
        queryParams.size = size;
        Request.get(`integration/hardac/event/${id}/message`)
            .query(
                queryParams
            ).exec()
            .then(response => {
                const messages = Immutable.fromJS(response.body.results);
                Dispatcher.dispatch({
                    actionType: CONSTANTS.MESSAGES.GET.SUCCESS,
                    offset: response.body.offset,
                    size: response.body.size,
                    total: response.header['wbtv-total-count'],
                    messages
                });
                return;
            }).catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.MESSAGES.GET.ERROR
                });

                switch (err.status) {
                case 404:
                    NotificationActions.showAlertDanger('hardac.processes.messages.get.error.does-not-have-access');
                    break;
                default:
                    NotificationActions.showAlertDanger('hardac.processes.messages.error.does-not-load');
                    break;
                }

                throw err;
            });

        return;
    }

    reprocessEventMessageById(id) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.MESSAGES.EVENT_MESSAGE.RE_PROCESS.START,
        });

        Request.put(`integration/hardac/event-grid-message/${id}/reprocess`)
            .exec()
            .then(() => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.MESSAGES.EVENT_MESSAGE.RE_PROCESS.SUCCESS,
                });
                NotificationActions.showAlertSuccess('hardac.processes.messages.event-message.reprocess.success');
            }).catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.MESSAGES.EVENT_MESSAGE.RE_PROCESS.ERROR,
                });

                switch (err.status) {
                case 404:
                    NotificationActions.showAlertDanger('hardac.processes.messages.event-message.reprocess.error.does-not-have-access');
                    break;
                default:
                    NotificationActions.showAlertDanger('hardac.processes.messages.event-message.reprocess.error.does-not-load');
                    break;
                }
            });

        return;
    }

    reprocessProcessById(id) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.PROCESSES.REPROCESS.START,
        });

        Request.put(`/integration/hardac/event/${id}/resend`)
            .exec()
            .then(() => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.PROCESSES.REPROCESS.SUCCESS,
                });
                NotificationActions.showAlertSuccess('hardac.processes.reprocess.success');
            }).catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.PROCESSES.REPROCESS.ERROR,
                });

                switch (err.status) {
                case 404:
                    NotificationActions.showAlertDanger('hardac.processes.reprocess.error.404');
                    break;
                default:
                    NotificationActions.showAlertDanger('hardac.processes.reprocess.error.422');
                    break;
                }

                throw err;
            });
    }

    reviewMessages(process, messages, messagesTasks) {
        let progress = 0;
        let tasksCompleted = {};
        let tasksFailed = {};
        let tasksRunning = {};
        let lastProcessingPercent = 0;
        let lastProcessingUd;

        const processType = process.processType;

        if (processType === CONSTANTS.PROCESS_TYPES.ENCODE_FOR_INBOX_PROXY.id) { //qc inbox proxy case
            const allMessages = messages.reverse();
            const EVENT_TYPES = CONSTANTS.PROCESS_TYPES.ENCODE_FOR_INBOX_PROXY.EVENT_TYPES;
            let i = 0, j = 0;
            let messagesToReview = messagesTasks; //check all messages tasks first
            while (i < messagesToReview.length && j < allMessages.length) {
                const task = messagesToReview[i];
                const taskEventType = task.eventType.id;
                const message = allMessages[j];
                const eventMessage = JSON.parse(message.eventMessage)[0];
                const eventType = eventMessage.eventType;
                if (eventType === EVENT_TYPES.HARDAC_FAILURE.id) {
                    delete tasksRunning[taskEventType];
                    tasksFailed[taskEventType] = {task, id: message.id};
                    i++;
                    j++;
                } else {
                    if (task.finished(eventMessage)) {
                        i++;
                        j++;
                        delete tasksRunning[taskEventType];
                        tasksCompleted[taskEventType] = {task, id: message.id};
                        if (!(eventType === EVENT_TYPES.AMSV3_ENCODE_SUCCESS.id && eventMessage.data.outputs)) { // when finish processing no needed to add percentage
                            progress += task.weight;
                        }
                    } else {
                        j++;
                        tasksRunning[taskEventType] = {task, id: message.id};
                    }
                }

                // each event message that belongs to processing will add a progress
                if (eventType === EVENT_TYPES.AMSV3_ENCODE_PROCESSING.id && taskEventType === EVENT_TYPES.AMSV3_ENCODE_PROCESSING.id) { //if processing add percent to progress
                    lastProcessingUd = task.weight/100;
                    const percent = eventMessage.data.percentComplete;
                    const increment = (percent - lastProcessingPercent) * lastProcessingUd;
                    progress += parseFloat(increment.toFixed(2));
                    lastProcessingPercent = percent;
                }
                if (eventType === EVENT_TYPES.AMSV3_ENCODE_SUCCESS.id && eventMessage.data.outputs && taskEventType === EVENT_TYPES.AMSV3_ENCODE_PROCESSING.id) { //if processing has finished and outputs has arrived
                    const increment = (100 - lastProcessingPercent) * lastProcessingUd;
                    progress += parseFloat(increment.toFixed(2));
                }

                //check again against failed messages tasks if we still have messages not reviewed
                if (j < allMessages.size && i === messagesToReview.length && Object.keys(tasksFailed).length) {
                    messagesToReview = Object.keys(tasksFailed).map(key => tasksFailed[key].task);
                    i = 0;
                }
            }
            //if messages arrives disordered or they never arrives, neither failed messages, then show notification
            if (i < messagesToReview.length - 1 && (process.processComplete || process.failureDate)) {
                NotificationActions.showAlertWarning('hardac.processes.messages.error-loading-event-messages');
            }
        }
        Dispatcher.dispatch({
            actionType: CONSTANTS.PROCESSES.PROGRESS.SET,
            progress: Immutable.fromJS({
                tasksCompleted,
                tasksFailed,
                tasksRunning,
                progress: Math.round(progress)
            })
        });
    }

    reviewProgress(processId, tasks) {
        let complete = false;
        timer(
            0,
            500
        ).pipe(
            skipWhile(() => !!complete),
            flatMap(() => {
                complete = true;
                return from(Promise.all([
                    Request.get(`integration/hardac/event/${processId}`),
                    Request.get(`integration/hardac/event/${processId}/message`)
                ]));
            }),
            map(res => {
                complete = false;

                const process = res[0].body;
                const {failureDate, processComplete} = process;
                let messages = null;
                if (res[1]) {
                    messages = res[1].body.results;
                }
                if (messages) {
                    this.update('messages', Immutable.fromJS(messages));
                    this.reviewMessages(res[0].body, messages, tasks);
                }
                this.update('process', Immutable.fromJS(process));
                return {
                    processComplete,
                    failureDate
                };
            }),
            concatMap(action => {
                if (action.processComplete === true || action.failureDate) {
                    return of(action, null);
                }
                return of(action);
            }),
            takeWhile(action => !!action),
        ).subscribe(() => void 0, err => {
            throw err;
        }, () => void 0);

        return;
    }

    /* reviewProcessesProgress(processes = Immutable.List([])) {
        let status = {};
        let statusObj = {scheduled: false, started: false, percentComplete: 0, completed: false, failed: false};
        status[CONSTANTS.PROCESS_TYPES.BLOB_COPY_LTS.id] = Object.assign({}, statusObj);
        status[CONSTANTS.PROCESS_TYPES.BLOB_COPY_TIMELINE_STEREO.id] = Object.assign({}, statusObj);
        status[CONSTANTS.PROCESS_TYPES.ENCODE_FOR_TIMELINE_STEREO.id] = Object.assign({}, statusObj);

        Dispatcher.dispatch({
            actionType: CONSTANTS.PROCESSES.REVIEW_PROGRESS.START,
            processes: processes
        });

        return;
    } */

    saveEventMessage(messageToSave) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.MESSAGES.EVENT_MESSAGE.SAVE.START
        });

        Request.put(`integration/hardac/message/${messageToSave.get('id')}`)
            .send(messageToSave.toJS())
            .exec()
            .then(response => {
                const message = Immutable.fromJS(response.body);
                Dispatcher.dispatch({
                    actionType: CONSTANTS.MESSAGES.EVENT_MESSAGE.SAVE.SUCCESS,
                    message
                });
                NotificationActions.showAlertSuccess('hardac.processes.messages.event-message.save.success');
                return;
            }).catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.MESSAGES.EVENT_MESSAGE.SAVE.ERROR
                });

                switch (err.status) {
                case 404:
                    NotificationActions.showAlertDanger('hardac.processes.messages.event-message.save.error.does-not-have-access');
                    break;
                default:
                    NotificationActions.showAlertDanger('hardac.processes.messages.event-message.save.error.does-not-have-access');
                    break;
                }

                throw err;
            });

        return;
    }

    setFilter(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.FILTER.SET,
            attr: attr,
            value: value
        });
    }

    update(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.UPDATE,
            attr,
            value
        });

        return;
    }
}

let actions = new ProcessesActions();

export {
    actions as ProcessesActions,
    CONSTANTS as ProcessesConstants
};
