/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {MassUpdateConstants} from './mass-update-actions';
import Validations from '../../common/validations/validations';
import Dispatcher from '../../dispatcher/dispatcher';
import {GroupConstants} from '../../security/group/group-actions';

const MassUpdateValidations = {
    users: {
        validations: [Validations.custom(
            function() {
                return "One or more rows can't be updated.";
            },
            function() {
                let isValid = true;
                let statusRules = [Validations.warnOnTrue];
                store.getState().get('users').forEach(user => {
                    let statusMessage = user.get('statusMessage');
                    let validate = function(rules, value) {
                        rules.forEach(r => {
                            if (!r.validate(value)) {
                                isValid = false;
                            }
                        });
                    };
                    validate(statusRules, statusMessage);
                });
                return isValid;
            }
        )]
    }
};

class MassUpdateStore extends ReduceStore {

    getInitialState() {
        return Immutable.Map({
            availability: Immutable.Map({
                existingAccountCount: 0,
                nonExistingAccountCount: 0,
                totalAccountCount: 0,
                userMassUpdateId: null
            }),
            brainiacGroups: Immutable.List(),
            bulkUpdateGroup: false,
            bulkUpdateRole: false,
            defaultPartner: Immutable.Map(),
            groups: Immutable.List(),
            massUpdateBatches: Immutable.List(),
            massUpdateProcess: Immutable.Map(),
            mode: 'update',
            partners: Immutable.List(),
            roles: Immutable.List(),
            selectedClientRepGroupIds: null,
            stations: Immutable.List(),
            users: Immutable.List(),
            wbdGroups: Immutable.List(),
        });
    }

    getValidations() {
        return Validations.validate(this.getState(), MassUpdateValidations);
    }

    reduce(state, action) {
        switch (action.actionType) {

        case MassUpdateConstants.MASS_UPDATE.BULK_UPDATE:
            if (action.bulkUpdate) {
                state = state.merge({
                    bulkUpdateGroup: true,
                    bulkUpdateRole: true
                });
            } else {
                state = state.set(action.attr, action.bulkUpdate);
            }
            break;

        case MassUpdateConstants.MASS_UPDATE.CLEAR:
            state = this.getInitialState();
            break;

        case MassUpdateConstants.MASS_UPDATE.FINDBY.SUCCESS:
            state = state.merge({
                massUpdateProcess: action.massUpdateProcess,
                users: action.users
            });
            break;

        case MassUpdateConstants.MASS_UPDATE.GET.GROUPS:
            state = state.update('users', users => {
                return users.map((user, i) => {
                    if (i === action.userIndex) {
                        user = user.set('groups', action.groups);
                    }
                    return user;
                });
            });
            let newGroups = action.groups.reduce((list, group) => {
                if (state.get('groups').findIndex(g => g.get('id') === group.get('id')) === -1) {
                    list.push(group);
                }
                return list;
            }, []);
            state = state.updateIn(['groups'], groups => groups.concat(Immutable.Set(newGroups).sortBy(g => g.get('name'))));
            state = state.set('wbdGroups', state.get('groups').filter(g => g.get('groupCategoryType') !== GroupConstants.STATION.categoryId && !g.get('isAdmin')));
            state = state.set('brainiacGroups', state.get('groups').filter(g => g.get('groupCategoryType') !== GroupConstants.STATION.categoryId && g.get('isAdmin')));
            state = state.set('stations', state.get('groups').filter(g => g.get('groupCategoryType') === GroupConstants.STATION.categoryId));
            break;

        case MassUpdateConstants.MASS_UPDATE.GET.PARTNERS:
            state = state.update('users', users => {
                return users.map((user, i) => {
                    if (i === action.userIndex) {
                        user = user.set('partners', action.partners);
                    }
                    return user;
                });
            });
            let newPartners = action.partners.reduce((list, partner) => {
                if (state.get('partners').findIndex(p => p.get('id') === partner.get('id')) === -1) {
                    list.push(partner);
                }
                return list;
            }, []);
            state = state.updateIn(['partners'], partners => partners.concat(Immutable.Set(newPartners).sortBy(p => p.get('name'))));
            break;

        case MassUpdateConstants.MASS_UPDATE.GET.ROLES:
            state = state.update('users', users => {
                return users.map((user, i) => {
                    if (i === action.userIndex) {
                        user = user.set('roles', action.roles);
                    }
                    return user;
                });
            });
            let newRoles = action.roles.reduce((list, role) => {
                if (state.get('roles').findIndex(r => r.get('id') === role.get('id')) === -1) {
                    list.push(role);
                }
                return list;
            }, []);
            state = state.updateIn(['roles'], roles => roles.concat(Immutable.Set(newRoles).sortBy(r => r.get('name'))));
            break;

        case MassUpdateConstants.MASS_UPDATE.GET.USERS:
            state = state.set('users', action.users);
            break;

        case MassUpdateConstants.MASS_UPDATE.SET.CLIENT_REP_GROUPS:
            state = state.set('selectedClientRepGroupIds', action.selectedClientRepGroupIds);
            break;

        case MassUpdateConstants.MASS_UPDATE.SET.BRAINIAC_GROUPS:
            state = state.set('brainiacGroups', action.selected);
            break;

        case MassUpdateConstants.MASS_UPDATE.SET.DEFAULT_PARTNER:
            state = state.set('defaultPartner', action.defaultPartner);
            break;

        case MassUpdateConstants.MASS_UPDATE.SET.GROUPS:
            state = state.set('wbdGroups', action.selected);
            break;

        case MassUpdateConstants.MASS_UPDATE.SET.MODE:
            state = state.set('mode', action.mode);
            break;

        case MassUpdateConstants.MASS_UPDATE.SET.PARTNERS:
            state = state.set('partners', action.selected);
            const partnerIndex = state.get('partners').keySeq().findIndex(partner =>
                partner.get('id') === state.getIn(['defaultPartner', 'id'])
            );
            if (partnerIndex === -1) {
                state = state.set('defaultPartner', Immutable.Map());
            }
            break;

        case MassUpdateConstants.MASS_UPDATE.SET.ROLES:
            state = state.set('roles', action.selected);
            break;

        case MassUpdateConstants.MASS_UPDATE.SET.STATIONS:
            state = state.set('stations', action.selected);
            break;

        case MassUpdateConstants.MASS_UPDATE.SET.USERS_STATUS:
            state = state.set('selectedUsersStatusId', action.selectedUsersStatusId);
            break;

        case MassUpdateConstants.MASS_UPDATE.GET.AVAILABILITY:
            state = state.set('availability', action.availability);
            break;

        }
        return state;

    }
}

let store = new MassUpdateStore(Dispatcher);

export default store;
export {MassUpdateValidations};
