/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import jQuery from 'jquery';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import Pagination from '../../common/table/pagination';
import {GroupActions, GroupConstants} from '../../security/group/group-actions';
import GroupStore from '../../security/group/group-store';


require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('datatables.net-responsive-bs');

const COLUMNS = [
    {name: 'Group Name', key: 'name'},
    {name: 'Status', key: 'is_active'},
    {name: 'Users', key: 'numberOfItemsInGroup'},
    {name: 'Description', key: 'description'},
    {name: 'Date Added', key: 'created_date'},
];

class GroupTable extends Component {
    static get propTypes() {
        return {
            displayLinks: PropTypes.bool,
            groups: PropTypes.instanceOf(Immutable.Map).isRequired,
            groupsCount: PropTypes.func.isRequired,
            onSortChange: PropTypes.func.isRequired,
            pageSize: PropTypes.number.isRequired,
            showInactive: PropTypes.bool,
            sortDirection: PropTypes.string.isRequired,
            sortFieldName: PropTypes.string.isRequired,
            type : PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object,
            router: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            displayLinks: false,
            showInactive: false
        };
    }

    constructor(props) {
        super(props);

        this.getSortFieldNameClass = this.getSortFieldNameClass.bind(this);
        this.handleHeaderClick = this.handleHeaderClick.bind(this);
        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);

        return;
    }

    componentDidMount() {
        this.$table = jQuery('#groups-table').DataTable({
            autoWidth: false,
            columnDefs: [{
                // Add the control class to the last column. This colum will
                // contain the button to show the responsive data.
                className: 'control',
                targets:   -1,
                width: 20
            }],
            iDisplayLength: 1,
            info: false,
            ordering: false,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        });

        // Register global listeners.
        window.addEventListener('resize', this.handleResize);

        // TODO: this is kind of cheating... maybe move the groups.forEach
        // to the render method?
        this.componentWillUpdate(this.props, this.state);
        return;
    }

    componentWillUpdate(props) {
        this.$table.clear();
        // Add data to the jQuery datatable.

        props.groups.get('groups').forEach((group) => {
            let created = Moment(group.get('createdDate'));
            if (created.isValid()) {
                created = created.format(this.context.intl.messages['date-format']);
            } else {
                created = '-';
            }

            let active = '<i class="fas fa-check-circle text-green"></i> <span class="hidden-xs hidden-sm"> Active</span>';
            let inactive = '<i class="fas fa-minus-circle text-red"></i> <span class="hidden-xs hidden-sm"> Inactive</span>';

            let groupDescription = '';
            let groupName = group.get('name');
            let groupStatus = inactive;

            if (props.displayLinks) {
                groupName = `<a href="/groups/user-groups/${group.get('id')}" class="edit-group">${group.get('name')}</a>`;
            }
            if (group.get('description')) {
                groupDescription = group.get('description');
            }
            if (group.get('isActive')) {
                groupStatus = active;
            }

            this.$table.row.add([
                groupName,
                groupStatus,
                group.get('numberOfItemsInGroup'),
                groupDescription,
                created,
                ''
            ]);

            return;
        });
        props.groupsCount();

        this.$table.draw(false);
        // Now, since the data has changed the columns widths, trigger
        // the resize handler in order to update the responsive feature.
        this.handleResize();

        return;
    }

    componentWillUnmount() {
        // Remove global listeners.
        window.removeEventListener('resize', this.handleResize);
    }

    getSortFieldNameClass(column) {
        let r = 'sorting';

        if (column.key === this.props.sortFieldName) {
            r = `sorting_${this.props.sortDirection}`;
        }

        return r;
    }

    handleHeaderClick(column) {
        let newSortDirection = 'asc';
        if (this.props.sortFieldName === column.key && this.props.sortDirection === 'asc') {
            newSortDirection = 'desc';
        }

        this.props.onSortChange(column.key, newSortDirection);

        return;
    }

    /**
     * Handle clicks on pagination controls.
     * @param  {Number} pageNumber page to load.
     */
    handleLoadPage(pageNumber) {
        let status = true;
        if (this.props.showInactive) {
            status = undefined;
        }
        let options = {
            active: status,
            filters: GroupStore.getState().get(this.props.type.name).get('filters'),
            offset: pageNumber * this.props.groups.get('size'),
            size: this.props.pageSize,
            sortDirection: this.props.sortDirection,
            sortFieldName: this.props.sortFieldName,
        };
        if (this.props.type.categoryId === GroupConstants.USER.categoryId) {
            options.includeUserCount = true;
        }
        GroupActions.get(
            this.props.type,
            true,
            options
        );
        return;
    }

    /**
     * Update the datatable columns size.
     */
    handleResize() {
        this.$table.responsive.recalc();
        return;
    }

    /**
     * This function is kind of "special" because it needs to handle
     * events bubbled from the data table rows, these rows cannot use
     * the JSX syntax because they are created by the data table
     * jQuery plugin instead of React.
     */
    handleRowClick(event) {
        switch (true) {
        // Handle click on an applicant's name.
        case !!~event.target.className.indexOf('edit-group'):
            // Prevent the default anchor click event.
            event.preventDefault();
            this.context.router.push(event.target.getAttribute('href'));
            break;
        }

        return;
    }

    render() {
        const activePage = Math.floor(this.props.groups.get('offset')/this.props.pageSize);
        const totalPages = Math.ceil(this.props.groups.get('total')/this.props.pageSize);
        return (
            <div>
                <table id="groups-table" className="table table-bordered table-striped responsive">
                    <thead>
                        <tr>
                            {COLUMNS.map((column) => {
                                return (
                                    <th
                                        className={this.getSortFieldNameClass(column)}
                                        onClick={this.handleHeaderClick.bind(this, column)}
                                    >
                                        {column.name}
                                    </th>
                                );
                            })}
                            <th className="no-sort"></th>
                        </tr>
                    </thead>
                    <tbody onClick={this.handleRowClick}>
                    </tbody>
                </table>
                <div className="row">
                    <div className="col-sm-7 col-sm-offset-5">
                        <Pagination
                            activePage={activePage}
                            onChange={this.handleLoadPage}
                            totalPages={totalPages}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default GroupTable;
