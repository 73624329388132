/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {AssetCatalogConstants} from './asset-catalog-actions';
import Dispatcher from '../../dispatcher/dispatcher';
import {AudioConstants} from '../audio/audio-actions';
import {DocumentConstants} from '../document/document-actions';
import {ImageConstants} from '../image/image-actions';
import {MerchandiseConstants} from '../merchandise/merchandise-actions';
import {TimelineAssetConstants} from '../timeline/timeline-asset-actions';
import {VideoConstants} from '../video/video-actions';

class AssetCatalogStore extends ReduceStore {

    getInitialState() {
        return Immutable.Map({
            assignedCatalogs: Immutable.List(),
            catalogs: Immutable.List(),
            filters: Immutable.Map(),
            originalAssignedCatalogs: Immutable.List(),
            targetCopyCatalogs: Immutable.List(),
            targetAssetId: null
        });
    }

    reduce(state, action) {
        switch (action.actionType) {
        case AssetCatalogConstants.ADD:
            state = state.updateIn(['assignedCatalogs'], catalogs => {
                return catalogs.push(action.catalog).sortBy(c => c.get('name'));
            });
            break;
        case AssetCatalogConstants.CLEAR:
            state = this.getInitialState();
            break;
        case AssetCatalogConstants.FINDBY.SUCCESS:
            state = state.set('assignedCatalogs', action.catalogs);
            state = state.set('originalAssignedCatalogs', action.catalogs);
            break;
        case AssetCatalogConstants.TARGET_COPY_ASSET.SUCCESS:
            state = state.set('targetCopyCatalogs', action.targetCopyCatalogs);
            state = state.set('targetAssetId', action.targetAssetId);
            break;
        case AssetCatalogConstants.TARGET_COPY_ASSET.CLEAR:
            state = state.set('targetCopyCatalogs', Immutable.List(),);
            state = state.set('targetAssetId', null);
            break;
        case AssetCatalogConstants.GET.SUCCESS:
            state = state.merge({
                catalogs: action.catalogs
            });
            break;
        case AssetCatalogConstants.REMOVE:
            state = state.updateIn(['assignedCatalogs'], catalogs => {
                return catalogs.delete(action.index).sortBy(c => c.get('name'));
            });
            break;
        case AudioConstants.AUDIO.SAVE.SUCCESS:
        case DocumentConstants.DOCUMENT.SAVE.SUCCESS:
        case ImageConstants.IMAGE.SAVE.SUCCESS:
        case MerchandiseConstants.MERCHANDISE.SAVE.SUCCESS:
        case VideoConstants.VIDEO.SAVE.SUCCESS:
        case TimelineAssetConstants.TIMELINE.SAVE.SUCCESS:
            state = state.merge({
                originalAssignedCatalogs: state.get('assignedCatalogs')
            });
            break;
        case AssetCatalogConstants.REMOVE_BY_ID:
            state = state.updateIn(['assignedCatalogs'], catalogs => {
                return catalogs.filter(c => c.get('id') !== action.id);
            });
            break;
        }

        return state;
    }
}

let store = new AssetCatalogStore(Dispatcher);

export default store;
