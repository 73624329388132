/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {ConfigurationConstants} from './configuration-actions';

import Dispatcher from '~/src/dispatcher/dispatcher';

class ConfigurationStore extends ReduceStore {
    getInitialState() {
        return Immutable.fromJS({
            configuration: {},
            originalConfiguration: {},
            showPreloader: false,
        });
    }

    reduce(state, action) {
        switch (action.actionType) {
        case ConfigurationConstants.CLEAR:
            state = this.getInitialState();
            break;

        case ConfigurationConstants.CONFIGURATION.GET.ERROR:
            state = state.set('showPreloader', false);
            break;

        case ConfigurationConstants.CONFIGURATION.GET.START:
            state = state.set('showPreloader', true);
            break;

        case ConfigurationConstants.CONFIGURATION.GET.SUCCESS:
            state = state.merge({
                configuration: action.configuration,
                originalConfiguration: action.configuration,
                showPreloader: false,
            });
            break;

        case ConfigurationConstants.CONFIGURATION.SAVE.SUCCESS:
            state = state.merge({
                originalConfiguration: state.get('configuration')
            });
            break;

        case ConfigurationConstants.CONFIGURATION.UPDATE_ATTRIBUTE:
            state = state.setIn(['configuration', action.attr, action.field], action.value);
            break;
        }
        return state;
    }
}

const store = new ConfigurationStore(Dispatcher);

export default store;
