/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';

import {AlertTypes} from '../common/notification/alert';
import {NotificationActions} from '../common/notification/notification-actions';
import {UploadFile} from '../common/utils/utils';
import Dispatcher from '../dispatcher/dispatcher';
import {PreloaderActions} from '../preloader/preloader-actions';
import Request from '../request';
import {RouterActions} from '../router/router-actions';
import {GroupConstants} from '../security/group/group-actions';

const CONSTANTS = {
    API_KEY: {
        ADD: 'partner.api-key.add',
        CLEAR: 'partner.api-key.clear',
        REMOVE: 'partner.api-key.remove',
        ADD_MODAL: {
            HIDE: 'partner.api-key.add-modal.hide',
            SHOW: 'partner.api-key.add-modal.show'
        }
    },
    CLEAR: 'partner.clear',
    CLONE: 'partner.clone',
    CREATE: {
        ERROR: 'partner.create.error',
        SUCCESS: 'partner.create.success'
    },
    GET: {
        ERROR: 'partner.get.error',
        START: 'partner.get.start',
        SUCCESS: 'partner.get.success'
    },
    LOAD: 'partner.load',
    PLATFORM_TYPE: {
        DESKTOP: {id: 'DESKTOP', name: 'Desktop Apps'},
        MOBILE: {id: 'MOBILE', name: 'Mobile'},
        TV: {id: 'TV', name: 'TV'},
        WEB: {id: 'WEB', name: 'Web'},
    },
    REMOVE: {
        ERROR: 'partner.remove.error',
        SUCCESS: 'partner.remove.success'
    },
    STATION: {
        ADD: 'partner.station.add',
        GET: {
            SUCCESS: 'partner.station.get.success'
        },
        REMOVE: 'partner.station.remove'
    },
    UPDATE: 'partner.update',
    USAGE_TYPE: {
        API: {id: 'API', name: 'API'},
        APPLICATION: {id: 'APPLICATION', name: 'Application'}
    },
    USER_GROUP: {
        ADD: 'partner.user_group.add',
        GET: {
            SUCCESS: 'partner.user_group.get.success'
        },
        REMOVE: 'partner.user_group.remove'
    },
    toArray: function(constant) {
        return Object.keys(this[constant])
            .map(k => this[constant][k])
            .sort((a, b) => a.name.localeCompare(b.name));
    },
};

class PartnerActions {

    addPartnerApiKey(apiKey) {
        let partnerApiKey = Immutable.fromJS({
            apiKey: apiKey.get('key'),
            userId: apiKey.getIn(['user', 'id']),
            user: apiKey.get('user')
        });
        Dispatcher.dispatch({
            actionType: CONSTANTS.API_KEY.ADD,
            apiKey: partnerApiKey
        });
        return;
    }

    addUserGroup(group) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.USER_GROUP.ADD,
            group
        });
        return;
    }

    addStation(station) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.STATION.ADD,
            station
        });
        return;
    }

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
        return;
    }

    clearPartnerApiKey() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.API_KEY.CLEAR,
        });
        return;
    }

    deletePartner(partner) {
        const preloaderSource = 'partner-actions.deletePartner';
        PreloaderActions.show(preloaderSource);

        Request.del(`partner/${partner.get('id')}`)
            .exec()
            .then(() => {
                PreloaderActions.hide(preloaderSource);
                Dispatcher.dispatch({
                    actionType: CONSTANTS.REMOVE.SUCCESS,
                });

                RouterActions.redirect('/setup/authorized-partners');
                NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'authorized-partners.delete.success');
                return;
            })
            .catch(() => {
                PreloaderActions.hide(preloaderSource);
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'authorized-partners.delete.error');
                return;
            });

        return;
    }

    deletePartnerApiKey(index) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.API_KEY.REMOVE,
            index: index
        });
        return;
    }

    findById(id) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.GET.START,
            id: id
        });
        let authorizedPartner;

        const preloaderSource = 'partner-actions.findById';
        PreloaderActions.show(preloaderSource);

        Promise.all([
            Request.get(`partner/${id}`).exec(),
            Request.get(`partner/${id}/api-key`).exec(),
            Request.get(`partner/${id}/group?size=9999`).exec()
        ])
            .spread((partnerRes, apiKeyRes, groupRes) => {
                const userGroups = groupRes.body.results.filter(group => group.groupCategoryType === GroupConstants.USER.categoryId);
                const stations = groupRes.body.results.filter( group => group.groupCategoryType === GroupConstants.STATION.categoryId);
                authorizedPartner = Immutable.fromJS(partnerRes.body)
                    .set('apiKeys', Immutable.fromJS(apiKeyRes.body))
                    .set('userGroups', Immutable.fromJS(userGroups))
                    .set('stations', Immutable.fromJS(stations));
                let users = Immutable.fromJS(apiKeyRes.body).map(k => k.get('userId')).toSet();
                return Promise.all(users.map(u => {
                    return Request.get(`user/${u}`).exec();
                }).toJS());
            })
            .then((users) => {
                let userMap = users.reduce( (um, u) => {
                    um[u.body.id] = u.body;
                    return um;
                }, {});
                authorizedPartner = authorizedPartner.updateIn(['apiKeys'], (keys) => {
                    return keys.map( key => {
                        let user = userMap[key.get('userId')];
                        /*istanbul ignore next*/
                        if (user) {
                            key = key.set('user', Immutable.fromJS(user));
                        }
                        return key;
                    });
                });

                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET.SUCCESS,
                    partner: authorizedPartner
                });
                PreloaderActions.hide(preloaderSource);
                return;
            })
            .catch((err) => {
                PreloaderActions.hide(preloaderSource);
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET.ERROR,
                    error: err
                });
                switch (err.status) {
                case 404:
                    RouterActions.notFound();
                    break;
                default:
                    NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
                    break;
                }
                return;
            });
        return;
    }

    get(offset, size) {
        const preloaderSource = 'partner-actions.get';
        PreloaderActions.show(preloaderSource);

        Request.get('partner')
            .query({
                offset: offset,
                size: size
            })
            .exec()
            .then(res => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.LOAD,
                    offset: res.body.offset,
                    partners: Immutable.fromJS(res.body.results)
                        .sortBy(p => p.get('name')),
                    size: res.body.size,
                    total: res.header['wbtv-total-count']
                });
                PreloaderActions.hide(preloaderSource);
                return;
            }).catch(err => {
                PreloaderActions.hide(preloaderSource);
                throw err;
            });

        return;
    }

    getStations(queryParams) {
        const preloaderSource = 'partner-actions.getStations';
        PreloaderActions.show(preloaderSource);

        queryParams = queryParams.toJS();

        let defaults = {
            active: true,
            offset: queryParams.offset || 0,
            size: queryParams.size || 20,
            name: queryParams.name,
            'sort-direction': 'asc',
            'sort-field-name': 'name',
        };

        Request.get('security/group')
            .query({
                ...defaults,
                'category-id': GroupConstants.STATION.categoryId,
            })
            .exec()
            .then((res) => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.STATION.GET.SUCCESS,
                    stations: Immutable.fromJS(res.body.results),
                    total: res.body.totalCount
                });
                PreloaderActions.hide(preloaderSource);
            })
            .catch((err) => {
                PreloaderActions.hide(preloaderSource);
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'authorized-partners.edit.stations.error');
                throw err;
            });
    }

    getUserGroups(queryParams) {
        const preloaderSource = 'partner-actions.getUserGroups';
        PreloaderActions.show(preloaderSource);

        queryParams = queryParams.toJS();

        let defaults = {
            active: true,
            offset: queryParams.offset || 0,
            size: queryParams.size || 20,
            name: queryParams.name,
            'sort-direction': 'asc',
            'sort-field-name': 'name',
        };


        Request.get('security/group')
            .query({
                ...defaults,
                'category-id': 3,
            })
            .exec()
            .then((res) => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.USER_GROUP.GET.SUCCESS,
                    userGroups: Immutable.fromJS(res.body.results),
                    total: res.body.totalCount
                });
                PreloaderActions.hide(preloaderSource);
            })
            .catch((err) => {
                PreloaderActions.hide(preloaderSource);
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'authorized-partners.edit.user-groups.error');
                throw err;
            });
    }

    hideAddApiKeyModal() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.API_KEY.ADD_MODAL.HIDE
        });
        return;
    }

    removeStation(index) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.STATION.REMOVE,
            index
        });
        return;
    }

    removeUserGroup(index) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.USER_GROUP.REMOVE,
            index
        });
        return;
    }

    savePartner(partner, originalPartner, options, logoFile) {
        const preloaderSource = 'partner-actions.savePartner';
        PreloaderActions.show(preloaderSource);

        let defaults = {
            messages: {
                error: 'authorized-partners.edit.error',
                success: 'authorized-partners.edit.success'
            }
        };

        Object.assign(defaults, options);

        let originalKeys = originalPartner.get('apiKeys').toSet();
        let apiKeys = partner.get('apiKeys').toSet();
        let toRemove = originalKeys.subtract(apiKeys).toJS();
        let toAdd = apiKeys.subtract(originalKeys).toJS();
        let userGroups = partner.get('userGroups').concat(partner.get('stations'));
        let originalUserGroups = originalPartner.get('userGroups').concat(originalPartner.get('stations'));

        let partnerData = partner.toJS();
        ['apiKeys', 'newApiKey', 'userGroups', 'stations', 'files'].forEach(k => delete partnerData[k]);

        // Assume POST.
        let isPost = true;
        let method = Request.post;
        let uri = 'partner';
        // Check if PUT.
        let id = partnerData.id;
        if (id !== undefined) {
            isPost = false;
            method = Request.put;
            uri = `partner/${id}`;
        }

        delete partnerData.apiKeys;

        method(uri)
            .send(partnerData)
            .exec()
            .then(res => {
                id = res.body.id;
                let ops = [];

                ops = ops.concat(toAdd.map( r => {
                    return Request.post(`partner/${id}/api-key`)
                        .send({
                            apiKey: r.apiKey,
                            userId: r.userId
                        })
                        .exec();
                }));

                ops = ops.concat(toRemove.map( r => {
                    return Request.del(`partner/${id}/api-key/${r.id}`)
                        .exec();
                }));

                // Add or remove groups (includes stations)
                if (!userGroups.equals(originalUserGroups)) {
                    const filteredPostRequest = userGroups.filter(group => !originalUserGroups.find(g => group.get('id') === g.get('id')))
                        .map(item => Request.post(`partner/${id}/group/${item.get('id')}`).exec().then(req => req)
                            .catch(err => {
                                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'authorized-partners.save.groups.error');
                                throw err;
                            })).toJS();

                    const filteredDelRequest = originalUserGroups.filter(group => !userGroups.find(i => group.get('id') === i.get('id')))
                        .map(item => Request.del(`partner/${id}/group/${item.get('id')}`).exec().then(req => req)
                            .catch(err => {
                                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'authorized-partners.save.groups.error');
                                throw err;
                            })).toJS();

                    ops.push(...filteredPostRequest);
                    ops.push(...filteredDelRequest);
                }

                let uploadFileRequest = Promise.resolve();
                if (logoFile) {
                    uploadFileRequest = UploadFile(
                        'PUT', `partner/${id}/logo-file`, logoFile, new XMLHttpRequest()
                    ).then(() => {
                        // Reload the partner data to ensure the logo source field is updated in the store
                        this.findById(id);
                    }).catch(err => {
                        NotificationActions.showAlertDanger('authorized-partners.create.upload.logo.error');
                        throw err;
                    });
                }

                ops.push(uploadFileRequest);

                return Promise.all(ops);
            })
            .then((responses) => {
                // if we added keys, we need to save ids
                Dispatcher.dispatch({
                    actionType: CONSTANTS.CLONE,
                    updatedKeys: Immutable.fromJS(toAdd.map( (key, i) => {
                        return responses[i].body;
                    }))
                });

                if (isPost) {
                    RouterActions.redirect(`/setup/authorized-partners/${id}`, true);
                }

                PreloaderActions.hide(preloaderSource);
                NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, defaults.messages.success);
            })
            .catch(err => {
                PreloaderActions.hide(preloaderSource);
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, defaults.messages.error);
                throw err;
            });

        return;
    }

    showAddApiKeyModal() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.API_KEY.ADD_MODAL.SHOW
        });
        return;
    }

    updatePartner(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.UPDATE,
            attr: attr,
            value: value
        });
        return;
    }

}

const actions = new PartnerActions();

export {
    actions as PartnerActions,
    CONSTANTS as PartnerConstants
};
