/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ScrollIntoView} from './scroll-into-view';

export function ScrollIntoRegion(region: WBTVDCue) {
    const el = document.querySelector(`[data-cue-id="${region.id}"]`);
    if (el instanceof HTMLElement) {
        const rect = document.querySelector('[data-testid="cc-editor-header"]')?.getBoundingClientRect();
        const offset = rect?.height ?? 0;
        ScrollIntoView(el, {useAnimation: true, offset: -1 * offset});
    }
}
