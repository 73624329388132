/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

import {AlertTypes} from '../../common/notification/alert';
import {NotificationActions} from '../../common/notification/notification-actions';
import Dispatcher from '../../dispatcher/dispatcher';
import Request from '../../request';

const CONSTANTS = {
    CLEAR: 'analytics.reports.clear',
    FIND_REPORTS: {
        ERROR: 'analytics.reports.get.error',
        START: 'analytics.reports.get.start',
        SUCCESS: 'analytics.reports.get.success'
    },
    FIND_REPORT_TYPES: {
        ERROR: 'analytics.reports.get-types.error',
        START: 'analytics.reports.get-types.start',
        SUCCESS: 'analytics.reports.get-types.success'
    },
};

class ReportActions {
    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR,
        });
    }

    findReports(reportType) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.FIND_REPORTS.START,
        });

        Request.get(`report/export-name/${reportType}/file`).exec().then((res) => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.FIND_REPORTS.SUCCESS,
                reports: Immutable.fromJS(res.body)
            });
        }).catch((error) => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.FIND_REPORTS.ERROR,
                error
            });

            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'analytics.reports.get.error');
            throw error;
        });
    }

    findReportTypes() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.FIND_REPORT_TYPES.START,
        });

        Request.get('report/export-name').exec().then((res) => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.FIND_REPORT_TYPES.SUCCESS,
                reportTypes: Immutable.fromJS(res.body)
            });
        }).catch((error) => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.FIND_REPORT_TYPES.ERROR,
                error
            });

            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'analytics.reports.get-types.error');
            throw error;
        });
    }
}

const actions = new ReportActions();

export {
    actions as ReportActions,
    CONSTANTS as ReportConstants,
};
