/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

type ScrollOptions = {
    useAnimation: boolean,
    animationDuration: number,
    offset: number,
}

const DEFAULT_SCROLL_OPTIONS: ScrollOptions = {
    useAnimation: false,
    animationDuration: 250,
    offset: 0,
};

export function ScrollIntoView(el: Element, options: Partial<ScrollOptions>) {
    const {animationDuration, offset, useAnimation} = {...DEFAULT_SCROLL_OPTIONS, ...options};
    const scrollableParent = getScrollableParent(el);

    if (!scrollableParent) {
        return;
    }

    const rect = el.getBoundingClientRect();
    const parentRect = scrollableParent.getBoundingClientRect();

    const startPosition = scrollableParent.scrollTop;
    const delta = Math.floor(rect.top - parentRect.top) + offset;
    let destination = startPosition + delta;
    if (scrollableParent === mainScrollingElement()) {
        destination = delta;
    }

    if (useAnimation) {
        animateMe(function _scrollStep(easing: number) {
            _scrollTo(scrollableParent, startPosition + (destination - startPosition) * easing);
        }, animationDuration);
    } else {
        _scrollTo(scrollableParent, destination);
    }
}

function getScrollableParent(node: any): Element | null {
    const body = document.body;
    while (node) {
        if (hasScroll(node)) {
            if (node === body || node.contains(body)) {
                return mainScrollingElement();
            }
            return node;
        }

        if (!node.parentElement) {
            return null;
        }
        node = node.parentElement;
    }

    return null;
}

export function mainScrollingElement(): Element {
    return document.scrollingElement || document.documentElement;
}

function hasScroll(el: HTMLElement): boolean {
    return el.scrollHeight > (el.clientHeight || el.offsetHeight) + 2;
}

function _scrollTo(el: Element, topPosition: number): void {
    el.scrollTop = topPosition;
}

function animateMe(
    callback: (val: number) => void,
    duration = DEFAULT_SCROLL_OPTIONS.animationDuration,
    easingFn = quadratic
) {
    const startTime = _now();
    const destTime = startTime + duration;

    function step() {
        const now = _now();

        if (now >= destTime) {
            const lastStep = 1;
            callback(lastStep);
            return;
        }

        const easing = easingFn((now - startTime) / duration);
        callback(easing);
        requestAnimationFrame(step);
    }

    step();
}

function _now(): number {
    if (performance && performance.now) {
        return performance.now();
    }
    return Date.now();
}

function quadratic(k: number) {
    return k * (2 - k);
}
