/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {
    ApTimelineAudioWaveform,
    ApTimelineBasic,
    ApTimelineGroup,
    ApTimelineMarker,
    ApTimelineMetadata
} from '@accurate-player/accurate-player-components-external';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import AudioProfileStore from '../../audio-profile-table/audio-profile-store';

import '../../timeline/timeline-component/video-timeline-theme-dark.css';

ApTimelineBasic.register();
ApTimelineGroup.register();
ApTimelineMarker.register();
ApTimelineAudioWaveform.register();
ApTimelineMetadata.register();

export default class DeliveryTimeline extends React.Component {
    static get propTypes() {
        return {
            audioProfile: PropTypes.instanceOf(Immutable.List).isRequired,
            audioWaveforms: PropTypes.instanceOf(Immutable.List).isRequired,
            player: PropTypes.object,
            // videoDelivery: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get defaultProps() {
        return {
            player: undefined
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.handleGroupClick = this.handleGroupClick.bind(this);

        return;
    }

    componentDidUpdate(oldProps) {
        if (oldProps.player !== this.props.player && this.props.player) {
            this.refs.apTimeline.player = this.props.player;
        }
    }

    handleGroupClick(e) {
        const group = e.target;
        group.collapsed = !group.collapsed;
    }

    handleHeader(header) {
        if (!header) {return;}

        header.setAttribute('slot', 'header');
    }

    initTimelineAudioWaveform(s, waveform) {
        if (waveform) {
            waveform.sampleScoreThreshold = 10;
            let lastRequestHash;
            waveform.apDataRequest = (range, canvasWidth) => {
                const requestHash = `${range.start}-${range.end}-${canvasWidth}`;
                if (requestHash === lastRequestHash) {
                    return;
                }

                lastRequestHash = requestHash;

                const sampleRange = {
                    start: 0,
                    end: s.length,
                    samples: s
                };
                waveform.api.addSampleRange(sampleRange);
            };
        }
    }

    render() {
        if (!this.props.player || !this.props.audioWaveforms) {
            return null;
        }

        return (
            <ap-timeline-basic ref="apTimeline" style={{paddingTop: '20px', 'max-width': '99.5%'}}>
                <ap-timeline-group onClick={this.handleGroupClick}>
                    <span className="row-header" ref={this.handleHeader}>
                        {this.context.intl.messages['hardac.timeline.audio-profile']}
                    </span>
                    {
                        this.props.audioProfile.map((audioProfile, i) => {
                            const audioWaveform = (this.props.audioWaveforms.get(i) || Immutable.List()).toJS();
                            // const waveformTypes = ['stroke', 'stroke_fill', 'rect'];
                            const maxMin = audioWaveform.reduce((r, d) => {
                                if (d > r.max) {
                                    r.max = d;
                                }

                                if (d < r.min) {
                                    r.min = d;
                                }

                                return r;
                            }, {max: 0, min: 0});

                            let description;
                            if (audioProfile.get('type')) {
                                description = ` - ${AudioProfileStore.getLookup(audioProfile.get('type')).get('description')}`;
                            }
                            return (
                                <ap-timeline-row key={i}>
                                    <div className="row-header timeline-clips-header" ref={this.handleHeader}>
                                        <span>
                                            #{audioProfile.get('audioChannel')}{description}
                                        </span>
                                    </div>
                                    <ap-timeline-audio-waveform
                                        ref={this.initTimelineAudioWaveform.bind(this, audioWaveform)}
                                        sample-min={maxMin.min}
                                        sample-max={maxMin.max}
                                        type="stroke_fill"
                                    ></ap-timeline-audio-waveform>
                                </ap-timeline-row>
                            );
                        })
                    }
                </ap-timeline-group>
            </ap-timeline-basic>
        );
    }
}
