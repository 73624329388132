/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';
import {Button, ButtonGroup} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';

import NoBrBlock from '../common/nobr-block';

type Props = {
    orientation: CueOrientation,
    onChange: (orientation: Partial<WBTVDCue>) => void,
}

export default class OrientationCueBlock extends React.PureComponent<Props> {
    private changeAlignTo = (align: AlignSetting, position: LineAndPositionSetting) => {
        this.props.onChange({...this.props.orientation, align, position, size: 100});
    };

    private changeLineAlignTo = (line: LineAndPositionSetting) => {
        this.props.onChange({...this.props.orientation, line, snapToLines: false});
    };

    private handleLeftAlignClick = () => this.changeAlignTo('left', 10);
    private handleRightAlignClick = () => this.changeAlignTo('right', 90);
    private handleCenterAlignClick = () => this.changeAlignTo('center', 50);

    private handleTopLineClick = () => this.changeLineAlignTo(10);
    private handleCenterLineClick = () => this.changeLineAlignTo(50);
    private handleBottomLineClick = () => this.changeLineAlignTo(90);

    render() {
        const align = this.props.orientation.align;
        const line = this.props.orientation.line.toString();
        let leftBtnStyle = 'btn btn-default';
        let centerBtnStyle = 'btn btn-default';
        let rightBtnStyle = 'btn btn-default';

        let topBtnStyle = 'btn btn-default';
        let middleBtnStyle = 'btn btn-default';
        let bottomBtnStyle = 'btn btn-default';

        if (align === 'left') {
            leftBtnStyle = 'btn btn-primary-outline';
        } else if (align === 'right') {
            rightBtnStyle = 'btn btn-primary-outline';
        } else if (align === 'center') {
            centerBtnStyle = 'btn btn-primary-outline';
        }

        if (line === '10') {
            topBtnStyle = 'btn btn-primary-outline';
        } else if (line === '50') {
            middleBtnStyle = 'btn btn-primary-outline';
        } else if (line === '90' || line === 'auto') {
            bottomBtnStyle = 'btn btn-primary-outline';
        }

        return (
            <div className="form-section text-center">
                <FormattedMessage id="cc-editor.settings-modal.orientation" />
                <div className="padding-bottom-20">
                    <NoBrBlock>
                        <ButtonGroup>
                            <Button
                                className={leftBtnStyle}
                                data-testid="left-align-btn"
                                onClick={this.handleLeftAlignClick}
                            >
                                <i className="fas fa-align-left" />
                            </Button>
                            <Button
                                className={centerBtnStyle}
                                data-testid="center-align-btn"
                                onClick={this.handleCenterAlignClick}
                            >
                                <i className="fas fa-align-center" />
                            </Button>
                            <Button
                                className={rightBtnStyle}
                                data-testid="right-align-btn"
                                onClick={this.handleRightAlignClick}
                            >
                                <i className="fas fa-align-right" />
                            </Button>
                        </ButtonGroup>
                    </NoBrBlock>
                    <NoBrBlock>
                        <ButtonGroup>
                            <Button
                                className={topBtnStyle}
                                data-testid="top-align-btn"
                                onClick={this.handleTopLineClick}
                            >
                                <i className="fas fa-arrow-to-top" />
                            </Button>
                            <Button
                                className={middleBtnStyle}
                                data-testid="middle-align-btn"
                                onClick={this.handleCenterLineClick}
                            >
                                <i className="fas fa-horizontal-rule" />
                            </Button>
                            <Button
                                className={bottomBtnStyle}
                                data-testid="bottom-align-btn"
                                onClick={this.handleBottomLineClick}
                            >
                                <i className="fas fa-arrow-to-bottom" />
                            </Button>
                        </ButtonGroup>
                    </NoBrBlock>
                </div>
            </div>
        );
    }
}
