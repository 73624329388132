/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

// React dependencies.
import {DatadogClient} from '@wbdt-sie/brainiac-web-common'; // Analytics and diagnostics.
import React from 'react';
import {render} from 'react-dom';
import {IntlProvider} from 'react-intl';
import {browserHistory, IndexRoute, Route, Router} from 'react-router';

// Polyfills
import './common/utils/polyfills';

// Brainiac Client components.
import Accounts from './accounts/accounts';
import Reports from './analytics/reports';
import UserErrors from './analytics/user-errors';
import Assets from './assets/assets';
import AuthorizedPartners from './authorized-partners/authorized-partners';
import Brands from './brands';
import BrowseHistory from './browse-history/browse-history';
import Catalogs from './catalogs/catalogs';
import ClientRepGroups from './client-rep-groups/client-rep-groups';
import Download from './common/download';
import Config from './config/config';
import Dashboard from './dashboard/dashboard';
import WithPermissions from './decorators/with-permissions';
import Emails from './emails/emails';
import AccessDenied from './errors/access-denied';
import AssetNotFound from './errors/asset-not-found';
import NotFound from './errors/not-found';
import Events from './events/events';
import Groups from './groups/groups';
import Guild from './guilds/guild';
import Hardac from './hardac';
import DetachedPlayer from './hardac/timeline/detached-player';
import Help from './help';
import HelpPages from './help-pages';
import Homepage from './homepages/homepages';
import Homescreen from './homescreens';
import HomeRedirect from './layout/home-redirect';
import BrainiacPage from './layout/page';
import Login from './login/login';
import LoginBackground from './login-background';
import {name as packageName, version as packageVersion} from '../package.json';
import Logout from './logout/logout';
import Lookup from './lookup/lookup';
import enUS from './messages/en-US';
import Permissions from './permission/permission';
import Presets from './presets/presets';
import Privacy from './privacy/privacy';
import Publishing from './publishing-list/publishing-list';
import PushNotifications from './push-notifications/push-notifications';
import Roles from './roles/roles';
import {RequireAuth, RequirePerm} from './router/router-helpers';
import Scheduling from './scheduling';
import StationGroups from './station-groups';
import Stations from './stations';
import Talent from './talent/talent';
import Titles from './titles/titles';
import SessionStore from './user/session/session-store';
import Utilities from './utilities/utilities';

import 'bootstrap/dist/css/bootstrap.css';

import './styles/skin-dark.css';
// font awesome
import './styles/fontawesome/light.css';
import './styles/fontawesome/fontawesome.css';
import './styles/fontawesome/solid.css';
import './styles/fontawesome/regular.css';

// Require for Proper Timezone Display
import 'moment-timezone';

// Require local styles and fonts.
import './styles/atomic.less';
import './styles/small-screens.css';

import './styles/skin-brainiac.less'; // Contains our overrides for AdminLTE
import './styles/skin-blue.css';
import './styles/admin-lte.css';

DatadogClient.init(
    {name: packageName, version: packageVersion},
    Config
);

// Render our router to start.
render(
    <IntlProvider locale="en" messages={enUS}>
        <Router history={browserHistory}>
            <Route path="/login" component={Login}/>
            <Route path="/logout" component={Logout}/>
            <Route path="/hardac/detached-player" component={DetachedPlayer}/>

            <Route path="/" component={BrainiacPage} onEnter={RequireAuth}>
                <IndexRoute component={HomeRedirect} />

                <Route
                    path="/dashboard"
                    component={WithPermissions(Dashboard)}
                />

                {/* Sorry for not respecting the alphabetical order, HelpPages has to go first so accounts/help takes the static and not the accounts/:id option */}
                {HelpPages}

                {Accounts}

                {Assets}

                {Catalogs}

                {ClientRepGroups}

                {Events}

                {Groups}

                {Hardac}

                {Help}

                {Homepage}

                {Homescreen}

                {LoginBackground}

                {PushNotifications}

                <Route path="/presets" component={WithPermissions(Presets.Browse)}
                    onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.PRESETS.BROWSE)}/>
                <Route path="/presets/create" component={WithPermissions(Presets.Create)} mode="create"
                    onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.PRESETS.CREATE)}/>
                <Route path="/presets/:id(/:active_tab)" component={WithPermissions(Presets.Create)} mode="edit"
                    onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.PRESETS.READ)}/>

                {Roles}

                {Brands}

                {AuthorizedPartners}

                <Route path="/setup/email-whitelists" component={Emails.Whitelist}
                    onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.SETUP.EMAIL_WHITELIST.BROWSE)}/>
                <Route path="/setup/email-whitelists/create" component={Emails.EditDomain} mode="create"
                    onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.SETUP.EMAIL_WHITELIST.CREATE)}/>
                <Route path="/setup/email-whitelists/:id(/:active_tab)" component={Emails.EditDomain} mode="edit"
                    onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.SETUP.EMAIL_WHITELIST.READ)}/>

                {Lookup}

                {Utilities}

                {Publishing}

                {Scheduling}

                {Stations}

                {StationGroups}

                {Talent}

                {Titles}

                {BrowseHistory}

                {Guild}

                {Privacy}

                {Permissions}

                {Reports}

                {UserErrors}

                <Route path="/access-denied" component={AccessDenied}/>
                <Route path="/asset-not-found" component={AssetNotFound}/>
                <Route path="/download/:id" component={Download}/>

                <Route path="*" component={NotFound}/>
            </Route>
        </Router>
    </IntlProvider>,
    document.querySelector('#brainiac-app')
);
