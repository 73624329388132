/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';

import {IsAssignedModel} from './create/ai-models/typeguards';

import Dispatcher from '~/src/dispatcher/dispatcher';

export type State = DeepImmutable<{
    assetId: number | null,
    loading: boolean,
    models: VideoMachineLearningProject[],
    notAppliedModels: VideoMachineLearningProject[],
    originalModels: VideoMachineLearningProjectDetail[],
}>;

class AssetAiModelsStore extends ReduceStore<State, DispatchAction> {
    getInitialState(): State {
        return {
            assetId: null,
            loading: false,
            models: [],
            notAppliedModels: [],
            originalModels: [],
        };
    }

    reduce(state: State, action: AssetAiModelsActions | VideoStoreActions) {
        switch (action.actionType) {
        case 'asset.video.save.success':
            state = this.getInitialState();
            break;
        case 'asset_ai_models_actions.model.assign':
            state = {
                ...state,
                models: [...state.models, action.model],
                notAppliedModels: state.notAppliedModels.filter(isNot(action.model)),
            };
            break;
        case 'asset_ai_models_actions.load.error':
            state = {...state, loading: false, models: []};
            break;
        case 'asset_ai_models_actions.load.start':
            state = {...state, loading: true};
            break;
        case 'asset_ai_models_actions.load.success':
            state = {
                ...state,
                assetId: action.payload.assetId,
                loading: false,
                models: action.payload.models,
                notAppliedModels: action.payload.notAppliedModels,
                originalModels: action.payload.models,
            };
            break;
        case 'asset_ai_models_actions.model.unassign':
            state = {
                ...state,
                models: state.models.filter(isNot(action.model)),
                notAppliedModels: [...state.notAppliedModels, action.model],
            };
            break;
        case 'asset_ai_models_actions.model.update':
            const models = state.models.map((model) => {
                if (isSameModel(action.model as VideoMachineLearningProject, model)) {
                    return {...model, ...action.model};
                }
                return model;
            });
            state = {...state, models};
            break;
        }

        return state;
    }
}

export default new AssetAiModelsStore(Dispatcher);

function isNot(model: VideoMachineLearningProject) {
    return (m: VideoMachineLearningProject) => {
        return !isSameModel(model, m);
    };
}

function isSameModel(model1: VideoMachineLearningProject, model2: VideoMachineLearningProject) {
    if (IsAssignedModel(model1) && IsAssignedModel(model2)) {
        return model1.videoInsightId === model2.videoInsightId;
    }

    return model1.videoMachineLearningProjectId === model2.videoMachineLearningProjectId;
}
