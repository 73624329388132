/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {AgGridReact} from 'ag-grid-react';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import '~/src/styles/ag-theme-brainiac.css';

export class AGGridWrapper extends Component {
    static get propTypes() {
        return {
            columnDefs: PropTypes.array.isRequired,
            defaultColDef: PropTypes.object,
            headerHeight: PropTypes.number,
            pagination: PropTypes.bool,
            paginationPageSize: PropTypes.number,
            rowData: PropTypes.array.isRequired,
            rowHeight: PropTypes.number,
            suppressCellSelection: PropTypes.bool,
        };
    }

    static get defaultProps() {
        return {
            defaultColDef: {
                autoHeight: true,
                sortable: true,
            },
            headerHeight: 40,
            pagination: true,
            paginationPageSize: 20,
            rowHeight: 25,
            suppressCellSelection: true,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);
    }

    render() {
        return <div style={{width: '100%', height: '100%'}} className="ag-theme-brainiac">
            <AgGridReact
                onGridSizeChanged={/* istanbul ignore next */(params) => {
                    // We use onGridSizeChanged rather than onGridReady because we can depend on it firing when a grid
                    // is initially rendered hidden (in a panel, in a different tab, etc) but later made visible.

                    params.api.sizeColumnsToFit();
                    params.api.resetRowHeights();
                }}
                {...this.props}
            />
        </div>;
    }
}

export default AGGridWrapper;
