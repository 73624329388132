/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';

import {AlertTypes} from '../common/notification/alert';
import {NotificationActions} from '../common/notification/notification-actions';
import {CompareImmutableList, SubtractImmutableSet} from '../common/utils/utils';
import Dispatcher from '../dispatcher/dispatcher';
import Request from '../request';

const CONSTANTS = {
    ADD: 'asset_talent_actions.add',
    CHECK: 'asset_talent_actions.check',
    CLEAR: 'asset_talent_actions.clear',
    GET: {
        SUCCESS: 'asset_talent_actions.get.success'
    },
    REMOVE: 'asset_talent_actions.remove'
};

class AssetTalentActions {
    add(talent) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ADD,
            talent:talent
        });
    }

    check(talent, checked) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CHECK,
            talent: talent,
            checked: checked
        });
    }

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
    }

    get(assetId) {
        Request.get(`asset/${assetId}/talent`)
            .exec()
            .then(res => {
                let talent = res.body;
                let lastOrder = 0;

                if (talent.length > 0) {
                    lastOrder = talent[talent.length-1].assetOrder;
                }

                return Promise.all([lastOrder, ...talent.map((assetToTalent) => {
                    return Request.get(`talent/${assetToTalent.talentId}`).exec();
                })]);
            })
            .then( talentData => {
                let lastOrder = talentData.shift();
                let talent = talentData.map(td => {
                    return td.body;
                });
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET.SUCCESS,
                    lastOrder: lastOrder,
                    talent: Immutable.fromJS(talent)
                });
                return;
            })
            .catch(err => {
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
                throw err;
            });

        return;
    }

    remove(talent) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.REMOVE,
            talent:talent
        });
    }

    assetTalentSave(assetId, talent, originalTalent) {
        if (CompareImmutableList(talent, originalTalent, ['id'])) {
            return [];
        }
        let originalSet = originalTalent.toSet();
        let newSet = talent.toSet();
        let toRemove = SubtractImmutableSet(originalSet, newSet, ['id']);
        let toAdd = SubtractImmutableSet(newSet, originalSet, ['id']);
        let ops = [];
        // Remove
        ops = ops.concat(toRemove.map(t => {
            return Request.del(`asset/${assetId}/talent/${t.get('id')}`).exec();
        }).toArray());
        // add
        ops = ops.concat(toAdd.map(t => {
            return Request.post(`asset/${assetId}/talent`).send({
                talentId:t.get('id'),
                assetOrder:t.get('assetOrder')
            }).exec();
        }).toArray());
        return ops;
    }
}

let actions = new AssetTalentActions();

export {
    actions as AssetTalentActions,
    CONSTANTS as AssetTalentConstants
};
