/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
require('./preloader.less');

export default createReactClass({
    propTypes: {
        bsSize: PropTypes.string,
        children: PropTypes.node,
        fixed: PropTypes.bool,
        loadingDots: PropTypes.bool,
        show: PropTypes.bool
    },

    getDefaultProps: function() {
        return {
            bsSize: '',
            children: undefined,
            fixed: false,
            loadingDots: false,
            show: true
        };
    },

    shouldComponentUpdate: function(nextProps) {
        if (this.props.show !== nextProps.show
            || this.props.fixed !== nextProps.fixed
            || this.props.children !== nextProps.children) {
            return true;
        }
        return false;
    },

    render: function() {
        let style;
        let loader;
        if (this.props.children) {
            style = {position: 'relative'};
        }

        if (!this.props.loadingDots) {
            loader = (
                <i className="spinner fas fa-sync-alt fa-spin" />
            );
        } else {
            loader = (
                <div className="loading-animation to-top">
                    <div className="bounce1"/>
                    <div className="bounce2"/>
                    <div className="bounce3"/>
                </div>
            );
        }

        return (
            <div style={style}>
                <div className={ClassNames(`preloader preloader-${this.props.bsSize}`, {fixed: this.props.fixed, hidden: !this.props.show})}>
                    {loader}
                </div>
                {this.props.children}
            </div>
        );
    }
});
