/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import LookupActions from '../../lookup-actions';

const CONSTANTS = {
    GET: {
        ERROR: 'audio_actions.get.error',
        START: 'audio_actions.get.start',
        SUCCESS: 'audio_actions.get.success'
    }
};

class AudioActions extends LookupActions {

    constructor(path, CONSTS) {
        super(path, CONSTS);
    }

}

let actions = new AudioActions('title/lookup/audio', CONSTANTS);

export {
    actions as AudioActions,
    CONSTANTS as AudioConstants
};
