/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Sanitize} from '@wbdt-sie/brainiac-web-common';
import ClassNames from 'classnames';
import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Checkbox, Col, Row, Tooltip, OverlayTrigger, Label} from 'react-bootstrap';
import ReactTable from 'react-table';

import config from '../../config/config';
import {UserConstants} from '../../user/user-actions';
import UserStore from '../../user/user-store';
import Pagination from '../table/pagination';

import 'react-table/react-table.css';

export default class ListUsers extends Component {
    static get propTypes() {
        return {
            activePage: PropTypes.number,
            applicants: PropTypes.bool,
            columns: PropTypes.array,
            displayLinks: PropTypes.bool,
            location: PropTypes.object.isRequired,
            onPageChange: PropTypes.func,
            onSelect: PropTypes.func,
            onSelectAll: PropTypes.func,
            onSortChange: PropTypes.func.isRequired,
            selectAll: PropTypes.bool,
            sortDirection: PropTypes.string.isRequired,
            sortFieldName: PropTypes.string.isRequired,
            totalPages: PropTypes.number,
            users: PropTypes.instanceOf(Immutable.List)
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object
        };
    }

    static get defaultProps() {
        return {
            activePage: 0,
            applicants: false,
            columns: ['NAME', 'COMPANY', 'EMAIL', 'STATUS', 'CLIENT_REP_GROUP'],
            displayLinks: false,
            onSelect: undefined,
            onSelectAll: undefined,
            selectAll: false,
            onPageChange: void 0,
            totalPages: 0,
            users: undefined
        };
    }

    constructor(props) {
        super(props);

        this.handleHeaderClick = this.handleHeaderClick.bind(this);
        this.getHeaderNameClass = this.getHeaderNameClass.bind(this);
        this.selectAll = this.selectAll.bind(this);
    }

    getHeaderNameClass(headerName) {
        let headerClassName = 'sorting';

        if (headerName === this.props.sortFieldName) {
            headerClassName = `sorting-${this.props.sortDirection}`;
        }

        return headerClassName;
    }

    handleHeaderClick(headerName) {
        const sortableHeaders = ['ACCOUNTSALERTS', 'NAME', 'COMPANY', 'EMAIL', 'APPLICATION_DATE'];

        if (sortableHeaders.includes(headerName)) {
            let newSortDirection = 'asc';
            if (this.props.sortFieldName === headerName && this.props.sortDirection === 'asc') {
                newSortDirection = 'desc';
            }
            if (this.props.onSortChange) {
                this.props.onSortChange(headerName, newSortDirection);
            }
        }

        return;
    }

    selectAll(size) {
        if (this.state.selected.length < size) {
            this.setState({
                selected: Array.from({length: size}, /* istanbul ignore next */ (x, i) => i)
            });
        } else {
            this.setState({
                selected: []
            });
        }
    }

    render() {
        let pagination;
        if (this.props.onPageChange) {
            pagination = <Row>
                <Col md={12} className="text-center">
                    <Pagination
                        activePage={this.props.activePage}
                        onChange={this.props.onPageChange}
                        totalPages={this.props.totalPages}
                    />
                </Col>
            </Row>;
        }

        const getHeader = this.getHeaderNameClass;
        const handleSortColumn = this.handleHeaderClick;

        const COLUMNS = [
            {
                accessor: 'SELECT',
                maxWidth: 35,
                Header: () => {
                    return <Checkbox
                        checked={this.props.selectAll}
                        disabled={false}
                        onClick={this.props.onSelectAll.bind(this, !this.props.selectAll)}
                    />;
                },
                Cell: (c) => {
                    let id = c.original.get('eventUserId');
                    if (this.props.applicants) {
                        id = c.original.get('id');
                    }
                    const checked = c.original.get('__selected');
                    return <Checkbox
                        checked={checked}
                        disabled={false}
                        onClick={this.props.onSelect.bind(this, id, !checked)}
                    />;
                },
                sortable: false
            },
            {
                accessor: 'ACCOUNTALERTS',
                maxWidth: 55,
                Header: /* istanbul ignore next */ (props) => {
                    return <strong><i className={ClassNames(getHeader(props.column.id), 'fas fa-flag')} /></strong>;
                },
                Cell: /* istanbul ignore next */ (c) => {
                    const alerts = c.original.get('accountAlerts', Immutable.List()).toJS();
                    let alertFlag;
                    if (alerts.length === 1) {
                        alertFlag = UserConstants.ACCOUNT_ALERTS[alerts[0]];
                    } else if (alerts.length > 1) {
                        alertFlag = UserConstants.ACCOUNT_ALERTS.MULTIPLE_ISSUES;
                    }

                    if (alertFlag) {
                        const flag = <div>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip><span>{this.context.intl.messages[alertFlag.tip]}</span></Tooltip>
                                }>
                                <i className={`${alertFlag.icon}`}/>
                            </OverlayTrigger>
                        </div>;
                        return flag;
                    }

                    return null;

                }
            },
            {
                accessor: 'LOGIN_TOKEN',
                Header: /* istanbul ignore next */ () => <strong>{this.context.intl.messages['events.users.table.login-token']}</strong>,
                Cell: /* istanbul ignore next */ (c) => {
                    let loginToken = c.original.getIn(['anonymousAccount', 'id']);

                    if (this.props.displayLinks) {
                        loginToken = <a href={`/accounts/${c.original.get('userId')}`} className="edit-account">{loginToken}</a>;
                    }

                    return <div>{loginToken}</div>;
                }
            },
            {
                accessor: 'NAME',
                maxWidth: 260,
                Header: /* istanbul ignore next */ (props) => <strong className={getHeader(props.column.id)}>{this.context.intl.messages['events.users.table.name']}</strong>,
                Cell: /* istanbul ignore next */ (c) => {
                    let userName = Sanitize(c.original.get('fullName', '') || c.original.getIn(['anonymousAccount', 'id']));
                    let userId = c.original.get('id') || c.original.get('userId');

                    if (this.props.displayLinks) {
                        let userPath = 'accounts';
                        if (this.props.applicants) {
                            userPath = 'accounts/applicants';
                        }
                        let params = '';
                        if (this.props.location?.search) {
                            params = this.props.location.search;
                        }
                        userName = <a href={`/${userPath}/${userId}${params}`} className="edit-account">{userName}</a>;
                    }

                    return <div>{userName}</div>;
                }
            },
            {
                accessor: 'COMPANY',
                maxWidth: 160,
                Header: /* istanbul ignore next */ (props) => <strong className={getHeader(props.column.id)}>{this.context.intl.messages['events.users.table.company']}</strong>,
                Cell: /* istanbul ignore next */ (c) => <div>{Sanitize(c.original.get('company'))}</div>
            },
            {
                accessor: 'CREATED_DATE',
                Header: /* istanbul ignore next */ () => <strong>{this.context.intl.messages['events.users.table.created-date']}</strong>,
                Cell: /* istanbul ignore next */  (c) => {
                    return <div>{Moment(c.original.getIn(
                        ['anonymousAccount', 'createdDate']
                    ))?.tz(config.DefaultTimezone).format(this.context.intl.messages['datetime-hybrid-format'])}</div>;
                }
            },
            {
                accessor: 'EMAIL',
                maxWidth: 250,
                Header: /* istanbul ignore next */ (props) => <strong className={getHeader(props.column.id)}>{this.context.intl.messages['events.users.table.email']}</strong>,
                Cell: /* istanbul ignore next */ (c) => <div>{Sanitize(c.original.get('emailAddress') || c.original.get('email'))}</div>
            },
            {
                accessor: 'STATUS',
                className: 'h-alignment',
                maxWidth: 120,
                Header: /* istanbul ignore next */ () => <strong>{this.context.intl.messages['events.users.table.status']}</strong>,
                Cell: /* istanbul ignore next */ (c) => {
                    // Status Value as well as tooltip
                    let status = UserStore.getStatus(c.original) || {};
                    let tip = this.context.intl.messages[status.tip];
                    if (!status.tip) {
                        tip = '';
                    }
                    return <div>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip><span>{tip}</span></Tooltip>
                            }>
                            <Label bsSize="md" className={`label ${status.label}`}><i className={`${status.icon}`}></i>&nbsp;{status.description}</Label>
                        </OverlayTrigger>
                    </div>;
                }
            },
            {
                accessor: 'CLIENT_REP_GROUP',
                Header: /* istanbul ignore next */ () => {
                    let clientRepHeader = this.context.intl.messages['events.users.table.client-rep-group'];
                    return <strong>{clientRepHeader}</strong>;
                },
                Cell: /* istanbul ignore next */ (c) => {
                    let clientRepGroupName = '-';
                    if (c.original.get('clientRepGroups')?.get(0)) {
                        clientRepGroupName = c.original.get('clientRepGroups')?.map(rg => rg.get('name')).join(', ');
                    }

                    return <div>{clientRepGroupName}</div>;
                }
            },
            {
                accessor: 'USER_GROUP_CLASSIFICATION',
                Header: /* istanbul ignore next */ () => <strong>{this.context.intl.messages['events.users.table.classification']}</strong>,
                /* istanbul ignore next */
                Cell: /* istanbul ignore next */ (c) => {
                    let classification = this.context.intl.messages['events.users.table.classification.unclassified'];
                    if (c.original.get('userGroupClassificationValue')) {
                        classification = UserStore.getClassification(c.original.get('userGroupClassificationValue')).name;
                    }
                    return <div>{classification}</div>;
                }
            },
            {
                accessor: 'APPLICATION_DATE',
                maxWidth: 120,
                Header: /* istanbul ignore next */ (props) => <strong className={getHeader(props.column.id)}>{this.context.intl.messages['events.users.table.application-date']}</strong>,
                Cell: /* istanbul ignore next */ (c) => {
                    return <div>{Moment(c.original.get('applicationDate'))?.tz(config.DefaultTimezone).format(this.context.intl.messages['date-format'])}</div>;
                }
            },
            {
                accessor: 'ACCOUNT_SOURCE_TYPE',
                Header: /* istanbul ignore next */ () => <strong>Source</strong>,
                Cell: /* istanbul ignore next */ (c) => {
                    let accountSourceType;
                    switch (c.original.getIn(['anonymousAccount', 'accountSourceType'])) {
                    case 'Generated':
                        accountSourceType = 'generated';
                        break;
                    case 'Imported':
                        accountSourceType = 'imported';
                        break;
                    case 'JustInTime':
                        accountSourceType = 'just-in-time';
                        break;
                    }
                    return <div>{this.context.intl.messages[`events.users.table.account-source.${accountSourceType}`]}</div>;
                }

            }
        ];

        return (
            <div>
                <ReactTable
                    className="-striped table-bordered table-striped responsive"
                    columns={COLUMNS.filter(/* istanbul ignore next */ col => this.props.columns.includes(col.accessor))}
                    data={this.props.users}
                    getNoDataProps= {/* istanbul ignore next */ () => {
                        if (this.props.users.size) {
                            return {style: {display: 'none'}};
                        }
                        return {};
                    }}
                    id={'list-users-table-id'}
                    loading={false}
                    showPagination={false}
                    sortable={false}
                    resizable={true}
                    pageSize={this.props.users.size}
                    getTheadThProps={/* istanbul ignore next */ (state, rowInfo, column) => ({
                        onClick: () => {
                            handleSortColumn(column.id);
                        }
                    })}
                />
                {pagination}
            </div>
        );
    }
}
