/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Config from '../config/config';
import SessionStore from '../user/session/session-store';

/**
 * Base function to check if an user is authenticated.
 */
const RequireAuth = (nextState, replace) => {
    if (!SessionStore.sessionIsValid()) {
        let previousURL = `${nextState.location.pathname}${nextState.location.search}`;
        if (previousURL === '/') {
            replace('/login');
        } else {
            replace(`/login?next=${encodeURIComponent(previousURL)}`);
        }
    }
    return;
};

/**
 * Base function to check if an user has permission to access
 * a specific URI.
 */
const RequirePerm = (permission, nextState, replace) => {
    if (!SessionStore.canUser(permission)) {
        if (Config.Environment !== Config.Environments.PRODUCTION) {
            console.error(`*** router-helpers.js RequirePerm: missing permission ${permission}`);
        }
        replace('/access-denied');
    }
    if (!SessionStore.isSessionValid()) {
        replace('/login');
    }
    return;
};

export {
    RequireAuth,
    RequirePerm
};
