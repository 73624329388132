/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import SessionStore from './session/session-store';
import {UserConstants} from './user-actions';

import Validations, {ERRORTYPE, SEVERITY} from '~/src/common/validations/validations';
import Dispatcher from '~/src/dispatcher/dispatcher';
import PartnerStore from '~/src/partner/partner-store';
import {GroupConstants} from '~/src/security/group/group-actions';
import {PermissionPackageConstants} from '~/src/security/permission-package/permission-package-actions';
import {ActionHistoryConstants} from '~/src/system/action-history/action-history-actions';

const isNotPrimary = () => store.getState().getIn(['userType', 'id']) && store.getState().getIn(['userType', 'id']) !== UserConstants.USER_TYPES.PRIMARY.id;

const UserValidations = {
    address: {
        label: 'accounts.create.summary.street-address',
        validations: [Validations.noIllegalCharacters, Validations.max(150)]
    },
    city: {
        label: 'accounts.create.summary.city',
        validations: [Validations.noIllegalCharacters, Validations.capitalization, Validations.excessiveCapitalization, Validations.max(50)]
    },
    companyIsNew: {
        message: 'accounts.create.companyIsNew',
        validations: [Validations.warnOnTrue]
    },
    company: {
        isOptional: isNotPrimary,
        label: 'accounts.create.summary.company',
        validations: [Validations.noIllegalCharacters, Validations.max(100), Validations.missing()]
    },
    contact: {
        isOptional: isNotPrimary,
        label: 'accounts.create.summary.contact',
        validations: [Validations.missing()]
    },
    country: {
        isOptional: isNotPrimary,
        label: 'accounts.create.summary.country',
        validations: [Validations.missing()]
    },
    dayPhone: {
        isOptional: isNotPrimary,
        label: 'accounts.create.summary.daytime-phone',
        validations: [Validations.noIllegalCharacters, Validations.max(30), Validations.onlySpaces]
    },
    email: {
        isOptional: isNotPrimary,
        label: 'accounts.create.summary.email',
        validations: [Validations.email, Validations.max(100), Validations.missing()]
    },
    fax: {
        label: 'accounts.create.summary.fax',
        validations: [Validations.noIllegalCharacters, Validations.max(30), Validations.onlySpaces]
    },
    jobTitle: {
        label: 'accounts.create.summary.user-title',
        validations: [Validations.noIllegalCharacters, Validations.capitalization, Validations.excessiveCapitalization, Validations.max(50)]
    },
    lastName: {
        isOptional: isNotPrimary,
        label: 'accounts.create.summary.last-name',
        validations: [Validations.noIllegalCharacters, Validations.capitalization, Validations.excessiveCapitalization, Validations.max(50), Validations.onlySpaces, Validations.missing()]
    },
    middleName: {
        label: 'accounts.create.summary.middle-name',
        validations: [Validations.noIllegalCharacters, Validations.capitalization, Validations.excessiveCapitalization, Validations.max(50), Validations.onlySpaces]
    },
    name: {
        isOptional: isNotPrimary,
        label: 'accounts.create.summary.first-name',
        validations: [Validations.noIllegalCharacters, Validations.capitalization, Validations.excessiveCapitalization, Validations.max(50), Validations.onlySpaces, Validations.missing()]
    },
    referrerEmail: {
        label: 'accounts.create.summary.referrers-email',
        validations: [Validations.email, Validations.max(50)]
    },
    responsibility: {
        label: 'accounts.create.summary.responsibilities',
        validations: [Validations.noIllegalCharacters, Validations.responsibilities, Validations.max(250)]
    },
    state: {
        label: 'accounts.create.summary.state',
        validations: [Validations.noIllegalCharacters, Validations.max(50)]
    },
    zipCode: {
        label: 'accounts.create.summary.postal-code',
        validations: [Validations.noIllegalCharacters, Validations.max(50)]
    },
    defaultPartner: {
        label: 'partners.primary-partner.applicant-error',
        validations: [Validations.missingIf(() => {
            const isAnonymous = store.getState().getIn(['userType', 'id']) !== UserConstants.ACCOUNT_TYPES.ANONYMOUS.id;
            return isAnonymous;
        })]
    },
    partners: {
        label: 'partners.web-partners',
        validations: [Validations.missingIf(() => {
            const isAnonymous = store.getState().getIn(['userType', 'id']) !== UserConstants.ACCOUNT_TYPES.ANONYMOUS.id;
            return isAnonymous;
        })]
    }
};

const userClassificationMap = Object.keys(UserConstants.CLASSIFICATION_TYPE).reduce( (o, k) => {
    o[UserConstants.CLASSIFICATION_TYPE[k].id] = UserConstants.CLASSIFICATION_TYPE[k];
    return o;
}, {});

const userStatusMap = Object.keys(UserConstants.STATUS).reduce( (o, k) => {
    o[UserConstants.STATUS[k].id] = UserConstants.STATUS[k];
    return o;
}, {});

class UserStore extends ReduceStore {

    getInitialState() {
        let state = Immutable.Map({
            activePage: 0,
            duplicatedEmails: Immutable.List(),
            filters: Immutable.Map(),
            homepages: Immutable.List(),
            isSendingMFAEnrollmentEmail: false,
            offset: 0,
            passwordResetLink: '',
            relatedAccounts: Immutable.List(),
            restrictedIPVideos: Immutable.List(),
            restrictedVideos: Immutable.List(),
            selectedUsers: Immutable.List(),
            selectAll: false,
            size: 20,
            sortDirection: 'desc',
            sortFieldName: 'updatedDate',
            total: 0,
            totalPages: 0,
            user: Immutable.Map({
                clientRep: Immutable.Map(),
                clientRepGroups: Immutable.List(),
                defaultPartner: Immutable.Map(),
                eventGroups: Immutable.OrderedSet(), // event groups
                groups: Immutable.OrderedSet(), // user groups
                brainiacGroups: Immutable.OrderedSet(), // brainiac groups
                guilds: Immutable.OrderedSet(),
                history: Immutable.List(),
                notes: Immutable.List(),
                partners: Immutable.OrderedSet(),
                roles: Immutable.OrderedSet(),
                sendUserActivationEmail: false,
                stations: Immutable.OrderedSet(),
                userActive: false
            }),
            userValidations: Immutable.List(),
            users: Immutable.List(),
            userSessionCount: 0,
            userType: Immutable.Map(),
            showPreloader: false
        });

        state = state.set('originalUser', state.get('user'));

        return state;
    }

    getClassification(id) {
        return userClassificationMap[id];
    }

    /**
     * Returns the right User Status to display
     */
    getStatus(user) {
        return userStatusMap[user.get('accountStatus')];
    }

    getUserType(user) {
        let userType;
        switch (true) {
        case (user.get('anonymousAccount') !== null && user.get('parentUserId') === null):
            userType = UserConstants.USER_TYPES.ANONYMOUS;
            break;
        case (user.get('anonymousAccount') !== null && user.get('parentUserId') !== null):
            userType = UserConstants.USER_TYPES.SECONDARY;
            break;
        default:
            userType = UserConstants.USER_TYPES.PRIMARY;
        }
        return Immutable.fromJS(userType);
    }

    getValidations(includeApiUserValidations = false) {
        let validations = Validations.validate(this.getState().get('user'), UserValidations);
        let serverValidations = this.getState().get('user').get('validations');

        if (serverValidations) {
            validations = validations.concat(serverValidations.toArray().map((v) => {
                return {
                    type:v.get('validationType'),
                    message: v.get('validationMessage'),
                    severity: SEVERITY.WARNING
                };
            }));
        }

        if (includeApiUserValidations) {
            const userValidations = this.getState().get('userValidations');
            userValidations.toJS().forEach(urv => {
                const accountAlert = UserConstants.ACCOUNT_ALERTS[urv.userValidationType];

                // Only include specific api validations (dupe name, dupe email, etc)
                if (accountAlert && accountAlert.applyToApplicant) {
                    let errorType = ERRORTYPE.ERROR;
                    let severity = SEVERITY.ALERT;

                    if (urv.severityType === 'WARNING') {
                        errorType = ERRORTYPE.WARNING;
                        severity = SEVERITY.WARNING;
                    }

                    const validation = {
                        errorType,
                        severity,
                        icon: accountAlert.icon,
                        message: accountAlert.errorText,
                    };
                    validations.push(validation);
                } else {
                    console.error(`user-store.getValidations Unable to find matching account alert for user validation type: ${urv.userValidationType}`);
                }
            });
        }

        return validations;
    }

    getApplicantApprovalValidations(applicant) {
        if (!applicant) {return [{message: 'Applicant record missing'}];}

        let error = {message: 'Unable to Approve Applicant, please assign Permissions'};
        if (SessionStore.isServiceAdmin()) {
            // Ensure applicant has 1+ role and 1+ group
            if (!applicant.get('roles').size || !applicant.get('groups').size) {
                return [error];
            }
        } else {
            if ([UserConstants.STATUS.VALIDATED.id,
                UserConstants.STATUS.PRE_VALIDATED.id,
                UserConstants.STATUS.APPROVED.id,
                UserConstants.STATUS.PRE_APPROVED.id,
                UserConstants.STATUS.NEEDS_REVIEW.id
            ].indexOf(parseInt(applicant.get('accountStatus'))) !== -1) {
                if (!applicant.get('roles').size || !applicant.get('groups').size) {
                    return [error];
                } else if (applicant.get('groups').size === 1 && applicant.get('groups').first().get('name') === 'Applicant') {
                    return [error];
                }
            }
        }
        return [];
    }

    isEventGroup(group) {
        return group.get('name').toLowerCase().startsWith('event');
    }

    reduce(state, action) {
        switch (action.actionType) {
        case ActionHistoryConstants.ADD_NOTE.SUCCESS:
            if (action.actionObjectType === ActionHistoryConstants.ACTION_OBJECTS.USER) {
                state = state.updateIn(['user', 'notes'], note => {
                    return note.unshift(action.note);
                });
            }
            break;
        /**
         * Add groups, roles, partners, stations to the current user.
         */
        case PermissionPackageConstants.SELECT:
            const primaryPartner = PartnerStore.getPartner(action.defaultPartnerId);
            const partners = action.webPartnerIds.map(partner => PartnerStore.getPartner(partner));
            const stations = action.groups.filter(g => g.get('groupCategoryType') === GroupConstants.STATION.categoryId).sortBy(g => g.get('name'));

            state = state.mergeIn(['user'], {
                brainiacGroups: action.groups.filter(g => g.get('isAdmin')),
                defaultPartner: primaryPartner,
                groups: action.groups.sortBy(g => g.get('name')),
                partners,
                roles: action.roles.sortBy(r => r.get('name')),
                stations
            });
            break;

        case UserConstants.CLEAR:
            state = this.getInitialState();
            break;

        case UserConstants.FILTER.CLEAR:
            state = state.set('filters', Immutable.Map());
            break;

        case UserConstants.SUGGESTED_USERS.CLEAR:
            state = state.set('users', Immutable.List());
            break;

        case UserConstants.SUGGESTED_USERS.GET.SUCCESS:
            state = state.set('users', action.users);
            break;

        case UserConstants.FILTER.SET:
            state = state.setIn(['filters', ...action.attr.split('.')], action.value);
            break;

        case UserConstants.SORT.SET:
            state = state.merge({
                sortFieldName: action.sortFieldName,
                sortDirection: action.sortDirection
            });
            break;

        case UserConstants.GET.USER.SUCCESS:
            state = state.set('user', action.user);
            // Replace day phone with evening phone if empty - BRAIN-3828
            if (!state.getIn(['user', 'dayPhone']) && state.getIn(['user', 'eveningPhone'])) {
                state = state.setIn(['user', 'dayPhone'], state.getIn(['user', 'eveningPhone']));
            }
            state = state.set('userType', this.getUserType(action.user)); // update user type
            break;

        case UserConstants.GET.USERS.SUCCESS:
            state = state.merge({
                activePage: Math.ceil(action.offset/action.size) || 0,
                offset: action.offset,
                selectedUsers: Immutable.List(),
                size: action.size,
                total: action.total,
                totalPages: Math.ceil(action.total/action.size) || 0,
                users: action.users
            });
            break;

        case UserConstants.SEND_MFA_ENROLLMENT_EMAIL.ERROR:
        case UserConstants.SEND_MFA_ENROLLMENT_EMAIL.SUCCESS:
            state = state.set('isSendingMFAEnrollmentEmail', false);
            break;
        case UserConstants.SEND_MFA_ENROLLMENT_EMAIL.START:
            state = state.set('isSendingMFAEnrollmentEmail', true);
            break;

        case UserConstants.USER.CLONE:
            state = state.set('originalUser', state.get('user'));
            break;

        case UserConstants.USER.SESSIONCOUNT.SUCCESS:
            state = state.set('userSessionCount', action.sessionCount);
            break;

        case UserConstants.USER.PASSWORDRESET.SUCCESS:
            state = state.set('passwordResetLink', action.passwordResetLink);
            break;

        case UserConstants.USER.SET.GROUPS:
            state = state.mergeIn(['user'], {
                groups: action.selected,
                eventGroups: action.selected.filter(this.isEventGroup),
            });
            break;

        case UserConstants.USER.SET.PASSWORDRESET_ALLOWED:
            state = state.setIn(['user', 'passwordResetAllowed'], action.passwordResetAllowed);
            break;

        case UserConstants.USER.SET.VALIDATIONS:
            state = state.mergeIn(['user'], {
                validations: action.validations
            });
            break;

        case UserConstants.USER.SET.ROLES:
            state = state.mergeIn(['user'], {
                roles: action.selected
            });
            break;

        case UserConstants.USER.SET.STATIONS:
            state = state.mergeIn(['user'], {
                stations: action.selected
            });
            break;

        case UserConstants.USER.SET.EVENT_GROUPS:
            state = state.mergeIn(['user'], {
                eventGroups: action.selected
            });
            break;

        case UserConstants.USER.SET.BRAINIAC_GROUPS:
            state = state.mergeIn(['user'], {
                brainiacGroups: action.selected
            });
            break;

        case UserConstants.USER.RESTRICTED_IP_VIDEOS.SUCCESS:
            state = state.set('restrictedIPVideos', action.videos);
            break;

        case UserConstants.USER.RESTRICTED_VIDEOS.SUCCESS:
            state = state.set('restrictedVideos', action.videos);
            break;

        case UserConstants.USER.VALIDATIONS.SUCCESS:
            state = state.set('userValidations', action.userValidations);
            break;

        case UserConstants.USERS.TOGGLE_SELECT:
            // Toggle the item value.
            state = state.setIn(['users', action.index, '__selected'], action.value);
            // Update the selected array.
            state = state.set('selectedUsers', state.get('users').filter(u => !!u.get('__selected')));
            state = state.set('selectAll', state.get('users').size === state.get('selectedUsers').size);
            break;

        case UserConstants.USERS.TOGGLE_SELECT_ALL:
            // Toggle all items.
            state = state.merge({
                selectAll: action.value,
                users: state.get('users').map(u => u.set('__selected', action.value))
            });

            // Update the selectedUsers list according to the current value,
            // no need to filter the array.
            if (action.value) {
                state = state.set('selectedUsers', state.get('users'));
            } else {
                state = state.set('selectedUsers', Immutable.List());
            }
            break;

        case UserConstants.USERS.UPDATE: {
            const index = state.get('users').findIndex(c => {return c.get('id') === action.user.get('id');});
            state = state.setIn(['users', index], action.user);
            break;
        }

        case UserConstants.USER.UPDATE:
            state = state.setIn(['user', ...action.attr.split('.')], action.value);
            break;

        case UserConstants.USER.UPDATE_CONTACT:
            state = state.setIn(['user', 'clientRep'], action.value);
            break;

        case UserConstants.USER.UPDATE_PARTNERS:
            state = state.mergeIn(['user'], {
                partners: action.selected
            });
            const partnerIndex = state.getIn(['user', 'partners']).keySeq().findIndex(partner =>
                partner.get('id') === state.getIn(['user', 'defaultPartner', 'id'])
            );
            if (partnerIndex === -1) {
                state = state.setIn(['user', 'defaultPartner'], Immutable.Map());
            }
            break;

        case UserConstants.USER.DEACTIVATE.START:
        case UserConstants.USER.SET_PASSWORD_AS_ADMIN.START:
        case UserConstants.USER.REACTIVATE.START:
            state = state.set('showPreloader', true);
            break;

        case UserConstants.USER.DEACTIVATE.ERROR:
        case UserConstants.USER.DEACTIVATE.SUCCESS:
        case UserConstants.USER.REACTIVATE.ERROR:
        case UserConstants.USER.REACTIVATE.SUCCESS:
        case UserConstants.USER.SET_PASSWORD_AS_ADMIN.ERROR:
        case UserConstants.USER.SET_PASSWORD_AS_ADMIN.SUCCESS:
            state = state.set('showPreloader', false);
            break;

        case UserConstants.USER.GET_RELATED_ACCOUNTS.SUCCESS:
            state = state.set('relatedAccounts', action.relatedAccounts);
            break;

        case UserConstants.USER.GUILDS.SUCCESS:
            state = state.setIn(['user', 'guilds'], action.guilds);
            break;

        case UserConstants.USER.UPDATE_HOMEPAGES:
            state = state.set('homepages', action.homepages);
            break;

        case UserConstants.UPDATE:
            state = state.setIn([...action.attr.split('.')], action.value);
            break;
        }

        return state;
    }
}

const store = new UserStore(Dispatcher);

export default store;
export {
    UserValidations
};
