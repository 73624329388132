/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Dispatcher from '../../../dispatcher/dispatcher';

const CONSTANTS = {
    CLEAR: 'starred_actions.clear',
    TOGGLE_STAR: 'starred_actions.toggle_star'
};

class StarredActions {
    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
    }

    toggleStar(path, title, userId, permission) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TOGGLE_STAR,
            path,
            title,
            userId,
            permission
        });

        return;
    }
}

const starredActions = new StarredActions();

export {
    starredActions as StarredActions,
    CONSTANTS as StarredConstants
};
