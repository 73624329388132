/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import Table from './table';
import {PrivacyActions} from '../privacy-actions';
import PrivacyStore from '../privacy-store';

import DocumentTitle from '~/src/common/document-title';
import {WithStoreOnRoute} from '~/src/common/store-on-route';
import Preloader from '~/src/preloader';
import SessionStore from '~/src/user/session/session-store';

class Browse extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static getPermissions() {
        return {
            canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.PRIVACY_REQUESTS.BROWSE),
        };
    }

    static calculateState() {
        const store = PrivacyStore.getState();
        return {
            privacyRequests: store.get('privacyRequests'),
            showPreloader: store.get('showPreloader'),
            total: store.get('total')
        };
    }

    static getStores() {
        return [PrivacyStore];
    }

    constructor(props) {
        super(props);

        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.init = this.init.bind(this);
    }


    componentDidMount() {
        this.init();
    }

    componentWillUpdate(nextProps) {
        if (this.props.location.query !== nextProps.location.query) {
            this.init(nextProps);
        }
        return;
    }

    handleLoadPage(pageNumber) {
        // pageNumber x this.state.size = offset.
        this.setRouteState('offset', pageNumber * 20)
            .apply();
        return;
    }

    init(props) {
        const routeState = this.getRouteState(props || this.props);
        PrivacyActions.get(routeState);
    }

    render() {
        return (
            <DocumentTitle
                message="document-titles.privacy"
            >
                <div className={ClassNames({'control-sidebar-open': this.state.showFiltersPanel})}>
                    <div className={ClassNames({'sidebar-opened': this.state.showFiltersPanel})}>
                        <Preloader show={this.state.showPreloader} fixed loadingDots>
                            <section className="content-header">
                                <h1>
                                    <i className="fa-regular fa-user-shield"></i>&nbsp;{this.context.intl.messages['privacy.browse.title']}
                                    &nbsp;<small>{this.state.total}</small>
                                </h1>
                            </section>
                            <section className="content">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="container-fluid no-x-padding">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="box">
                                                        <div className="box-body">
                                                            <Table
                                                                activePage={Math.ceil((this.getRouteState().get('offset') || 0) / 20 ) || 0}
                                                                displayLinks={true}
                                                                onPageChange={this.handleLoadPage}
                                                                privacyRequests={this.state.privacyRequests}
                                                                totalPages={Math.ceil(this.state.total/20) || 0}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </Preloader>
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}

export default WithStoreOnRoute(Container.create(Browse));
