/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import RootItem from './root-item.js';

export default class Tree extends React.Component {
    static get propTypes() {
        return {
            children: PropTypes.node.isRequired,
            expanded: PropTypes.bool,
            iconClass: PropTypes.string,
            title: PropTypes.string.isRequired
        };
    }

    static get defaultProps() {
        return {
            expanded: false,
            iconClass: undefined
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            open: props.expanded
        };

        this.handleClick = this.handleClick.bind(this);

        return;
    }

    handleClick(event) {
        event.preventDefault();

        this.setState(prevState => ({
            open: !prevState.open
        }));

        return;
    }

    render() {
        const {title, children} = this.props;

        return (
            <li className={ClassNames('treeview', {active: this.state.open === true})}>
                <a href="#" onClick={this.handleClick}>
                    {this.props.iconClass && <RootItem iconClass={this.props.iconClass}/>}
                    <span>{this.props.title}</span>
                    <i className="fas fa-angle-left pull-right"></i>
                </a>
                <ul className="treeview-menu">
                    {
                        React.Children.map(children, c => {
                            if (c) {
                                return React.cloneElement(c, {parentTitle: title});
                            }
                        })
                    }
                </ul>
            </li>
        );
    }
}
