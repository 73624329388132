/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Dispatcher from '../../dispatcher/dispatcher';

const CONSTANTS = {
    HIDE: 'sliding.panel.hide',
    SHOW: 'sliding.panel.show',
};

class SlidingPanelActions {

    show(id) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SHOW,
            id: id
        });
    }

    hide(id) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.HIDE,
            id: id
        });
    }

}

let actions = new SlidingPanelActions();

export {
    actions as SlidingPanelActions,
    CONSTANTS as SlidingPanelConstants
};
