/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ControlLabel} from 'react-bootstrap';

import ApiKeyList from './api-key-list';
import AddApiKeyModal from './api-key-list/add-api-key';
import Summary from './summary/summary';
import UserGroups from './user-groups';

import RouteValidator from '~/src/common/custom-validator/route/route';
import DocumentTitle from '~/src/common/document-title';
import FileInput from '~/src/common/file-input';
import {MODAL_TYPE} from '~/src/common/notification/modal';
import {NotificationActions} from '~/src/common/notification/notification-actions';
import {Tab, Tabs} from '~/src/common/routing-tab/routing-tab';
import {AlertsWarnings} from '~/src/common/validations/alerts-warnings';
import Config from '~/src/config/config';
import ActionsMenu from '~/src/layout/actions-menu/actions-menu';
import StickyHeader from '~/src/layout/sticky-header/sticky-header';
import {PartnerActions} from '~/src/partner/partner-actions';
import PartnerStore from '~/src/partner/partner-store';
import Preloader from '~/src/preloader/';
import PreloaderStore from '~/src/preloader/preloader-store';
import {RouterActions} from '~/src/router/router-actions';
import {GroupConstants} from '~/src/security/group/group-actions';
import GroupStore from '~/src/security/group/group-store';
import SessionStore from '~/src/user/session/session-store';

class Create extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            params: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired,
            route: RouteValidator // eslint-disable-line react/require-default-props
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            originalPartner: PartnerStore.getState().get('originalPartner'),
            partner: PartnerStore.getState().get('partner'),
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible'),
            showAddApiKeyModal: PartnerStore.getState().get('showAddApiKeyModal')
        };
    }

    static getStores() {
        return [GroupStore, PartnerStore, PreloaderStore];
    }

    /* istanbul ignore next */
    static getPermissions() {
        return {
            canCreate: SessionStore.canUser(SessionStore.PERMISSIONS.SETUP.AUTHORIZED_PARTNERS.CREATE),
            canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.SETUP.AUTHORIZED_PARTNERS.EDIT),
            canViewStations: SessionStore.canUser(SessionStore.PERMISSIONS.STATIONS.CONFIGURATION),
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            uploadedPartnerLogoImageSrc: null,
        }, this.constructor.calculateState());

        this.handleAddPartnerLogo = this.handleAddPartnerLogo.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleDeletePartner = this.handleDeletePartner.bind(this);
        this.handleSavePartner = this.handleSavePartner.bind(this);
        this.isDirty = this.isDirty.bind(this);
        this.showDeleteDialog = this.showDeleteDialog.bind(this);
    }

    componentDidMount() {
        this.initStores(this.props);
        RouterActions.registerRedirectCheck((location) => {
            return this.isDirty(location);
        });
        return;
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.route.mode !== this.props.route.mode ||
            nextProps.params.id !== this.props.params.id) {
            this.initStores(nextProps);
        }
        RouterActions.registerRedirectCheck((location) => {
            return this.isDirty(location);
        });
        return;
    }

    handleAddPartnerLogo(e) {
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = /* istanbul ignore next */ (p) => {
            let image = new Image();
            image.src = p.target.result;
            image.size = p.loaded; // filesize in bytes
            image.onload = () => {
                this.setState(() => ({
                    uploadedPartnerLogoImageSrc: image.src,
                }));
            };
        };
        PartnerActions.updatePartner('files', [...e.target.files]);
    }

    handleCancel() {
        RouterActions.redirect('/setup/authorized-partners', true);
    }

    handleDeletePartner() {
        PartnerActions.deletePartner(this.state.partner);
        return;
    }

    handleSavePartner() {
        const partnerLogoElement = document.getElementById('partner-logo-file-dummy-input');

        let logoFile;
        if (partnerLogoElement) {
            logoFile = partnerLogoElement.files[0];
        }

        let options = {
            messages: {
                error: `authorized-partners.${this.props.route.mode}.error`,
                success: `authorized-partners.${this.props.route.mode}.success`
            }
        };

        PartnerActions.savePartner(
            this.state.partner,
            this.state.originalPartner,
            options,
            logoFile
        );

        this.setState(() => ({uploadedPartnerLogoImageSrc: undefined}));

        return;
    }

    initStores(props) {
        switch (props.route.mode) {
        case 'create':
            PartnerActions.clear();
            break;
        case 'edit':
            PartnerActions.clear();
            PartnerActions.findById(props.params.id);
            break;
        }
        return;
    }

    isDirty(location) {
        if (location) {
            let goingTo = location.pathname.split('/');
            let current = this.props.location.pathname.split('/');
            if (goingTo[1] === current[1] && goingTo[2] === current[2] &&//entity and subentity
                goingTo[3] === current[3] //entity id
            ) {
                return false; // just changing tabs no confirmation needed.
            }
        }
        if (this.props.route.mode === 'create') {
            return false;
        }
        return !this.state.partner.equals(PartnerStore.getState().get('originalPartner'));
    }

    showDeleteDialog() {
        NotificationActions.show(
            MODAL_TYPE.DANGER,
            this.context.intl.messages['authorized-partners.edit.modal.title'],
            this.context.intl.messages['authorized-partners.edit.modal.body'],
            this.context.intl.messages['authorized-partners.edit.modal.confirm'],
            this.handleDeletePartner
        );
    }

    render() {
        let actionsMenu;
        let apiKeysTab;
        let baseRoute;
        let disableForm = true;
        let entityName;
        let logo;
        let userGroupsTab;
        let stationsTab;
        let validations = PartnerStore.getValidations();

        // STUDIO-10521 add the cache-bust querystring parameter to prevent cloudfront caching issues during upload/save
        let logoSourceUrl = `/${this.state.partner.get('logoSourceS3Path')}?cache-bust=${Date.now()}`;
        if (Config.Environment === Config.Environments.DEV) {
            // Prepend the proper environment to prevent Parcel returning empty image on /page/partner-logo/id.png route
            logoSourceUrl = 'https://brainiacqa.wbtvd.com' + logoSourceUrl;
        }

        const mode = this.props.route.mode;

        if (mode === 'create') {
            baseRoute = '/setup/authorized-partners/create';
        } else {
            baseRoute = `/setup/authorized-partners/${this.props.params.id}`;
        }

        if (this.state.partner.get('logoSourceS3Path')) {
            logo = (<img alt="" className="img-thumbnail" src={logoSourceUrl} style={{background: 'repeating-conic-gradient(#808080 0% 25%, transparent 0% 50%) 50% / 20px 20px'}} />);
        }

        if (mode === 'create') {
            if (this.props.permissions.canCreate) {
                disableForm = false;
            }

            actionsMenu = <ActionsMenu
                canCreate={this.props.permissions.canCreate}
                canSave={!validations.length}
                onCancel={this.handleCancel}
                onSave={this.handleSavePartner}
                saveText={this.context.intl.messages['authorized-partners.create-partner']}
            />;
        }

        if (mode === 'edit') {
            if (this.props.permissions.canEdit) {
                disableForm = false;
            }

            actionsMenu = <ActionsMenu
                canDelete={this.state.originalPartner.get('apiKeys').size <= 0}
                canEdit={this.props.permissions.canEdit}
                canSave={!validations.length && this.props.permissions.canEdit}
                onCancel={this.handleCancel}
                onDelete={this.showDeleteDialog}
                onSave={this.handleSavePartner}
            />;

            apiKeysTab = <Tab route={`${baseRoute}/api-keys`} title={this.context.intl.messages['authorized-partners.edit.api.keys']}>
                <ApiKeyList apiKeys={this.state.partner.get('apiKeys')} displayLinks />
            </Tab>;

            userGroupsTab = <Tab route={`${baseRoute}/user-groups`} title={this.context.intl.messages['authorized-partners.edit.user-groups']}>
                <UserGroups
                    assignedUserGroups={this.state.partner.get('userGroups').filter(g => g.get('groupCategoryType') !== GroupConstants.STATION.categoryId)}
                    groupType="user-groups"
                    location={this.props.location}
                    onAdd={PartnerActions.addUserGroup}
                    onRemove={PartnerActions.removeUserGroup}
                />
            </Tab>;

            if (this.props.permissions.canViewStations) {
                stationsTab = <Tab route={`${baseRoute}/stations`} title={this.context.intl.messages['authorized-partners.edit.stations']}>
                    <UserGroups
                        assignedUserGroups={this.state.partner.get('stations')}
                        groupType="stations"
                        location={this.props.location}
                        onAdd={PartnerActions.addStation}
                        onRemove={PartnerActions.removeStation}
                    />
                </Tab>;
            }
        }

        const partnerLogoFiles = this.state.partner.get('files', Immutable.List()).map(f => f.name);
        let partnerLogo = (
            <div className="box">
                <div className="box-header with-border">
                    <h3>
                        <i className="fas fa-info-circle"></i>
                        {this.context.intl.messages['authorized-partners.create.upload.logo.title']}
                    </h3>
                </div>
                <div className="box-body padding-x-20">
                    {logo}
                    <ControlLabel>{this.context.intl.messages['authorized-partners.create.upload.logo.label']}</ControlLabel>
                    <FileInput
                        id="partner-logo-file"
                        accept="image/*"
                        disabled={disableForm}
                        files={partnerLogoFiles}
                        onChange={this.handleAddPartnerLogo}
                        uploadedImageSrc={this.state.uploadedPartnerLogoImageSrc}
                    />
                </div>
            </div>
        );

        let summaryTab = <Tab route={baseRoute} title={this.context.intl.messages['authorized-partners.summary']}>
            <Summary
                disabled={disableForm}
                mode={mode}
                partner={this.state.partner}
                showErrors={this.state.showErrors}
            />
        </Tab>;

        if (this.state.partner.get('name')) {
            entityName = this.state.partner.get('name');
        }

        return (
            <DocumentTitle
                message={`document-titles.${mode}-authorized-partners`} entityName={entityName}
            >
                <div>
                    <StickyHeader>
                        <div className="col-md-6">
                            <h1>
                                <i className="fas fa-users"></i>&nbsp;{this.context.intl.messages[`authorized-partners.${mode}.title`]}
                                &nbsp;
                            </h1>
                            <p className="padding-top-20">
                                <em>{this.context.intl.messages[`authorized-partners.${mode}.title.info`]}</em>
                            </p>
                        </div>
                        {actionsMenu}
                    </StickyHeader>
                    <Preloader show={this.state.preloaderVisible} fixed loadingDots>
                        <section className="content" id="contentContainer">
                            <div className="row">
                                <div className="col-lg-9">
                                    <Tabs location={this.props.location}>
                                        {summaryTab}
                                        {apiKeysTab}
                                        {userGroupsTab}
                                        {stationsTab}
                                    </Tabs>
                                </div>
                                <div className="col-lg-3">
                                    <AlertsWarnings
                                        title={this.context.intl.messages['common.alerts-warnings-title']}
                                        validations={validations}
                                    />
                                    {partnerLogo}
                                </div>
                            </div>
                        </section>
                    </Preloader>
                    <AddApiKeyModal showModal={this.state.showAddApiKeyModal} onHide={PartnerActions.hideAddApiKeyModal} partner={this.state.partner}/>
                </div>
            </DocumentTitle>
        );
    }
}

export default Container.create(Create);
