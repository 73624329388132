/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ControlLabel, FormControl, FormGroup} from 'react-bootstrap';

import BaseSelect from '../../../../common/base-select/base-select';
import {FormItem, FormRow, FormSection} from '../../../../common/form/form';
import TinyMCE from '../../../../common/tinymce/tinymce';
import {CompareImmutable} from '../../../../common/utils/utils';
import {WithValidations} from '../../../../common/validations/validations';
import config from '../../../../config/config.js';
import {AssetConstants} from '../../../asset-actions';
import AssetStore from '../../../asset-store';
import {ImageActions} from '../../image-actions';
import ImageStore, {ImageValidations} from '../../image-store';

/**
 * Image props to watch.
 */
const imageProps = [
    'active', 'assetComment', 'caption', 'assetName', 'copyrightText', 'copyrightYear', 'credit', 'deliveryType',
    'keyword', 'mimeType', 'updatedBy', 'updatedDate', 'contentType'
];

class Summary extends Component {

    static get propTypes() {
        return {
            asset: PropTypes.instanceOf(Immutable.Map).isRequired,
            disabled: PropTypes.bool,
            mode: PropTypes.string.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            isDeliveryTypeDirty: false,
            isImageTypeDirty: false,
            isMimeTypeDirty: false,
            selectedDeliveryType: AssetStore.getDeliveryType(this.props.asset.get('deliveryType')),
            selectedMimeType: AssetStore.getAssetMimeType(AssetConstants.MIME_TYPES_IMAGE, this.props.asset.get('mimeType')),
            selectedImageType: ImageStore.getImageType(
                this.props.asset.get('imageSuperType'),
                this.props.asset.get('contentType')
            )
        };

        this.handleSelectDeliveryType = this.handleSelectDeliveryType.bind(this);
        this.handleSelectImageType = this.handleSelectImageType.bind(this);
        this.handleSelectMimeType = this.handleSelectMimeType.bind(this);
        this.handleTinyMCEChange = this.handleTinyMCEChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            selectedDeliveryType: AssetStore.getDeliveryType(nextProps.asset.get('deliveryType')),
            selectedMimeType: AssetStore.getAssetMimeType(AssetConstants.MIME_TYPES_IMAGE, nextProps.asset.get('mimeType')),
            selectedImageType: ImageStore.getImageType(
                nextProps.asset.get('imageSuperType'),
                nextProps.asset.get('contentType')
            )
        });
        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.disabled !== this.props.disabled ||
            !CompareImmutable(nextProps.asset, this.props.asset, imageProps)) {
            return true;
        }

        if (nextState.selectedDeliveryType !== this.state.selectedDeliveryType ||
            nextState.selectedMimeType !== this.state.selectedMimeType ||
            nextState.selectedImageType !== this.state.selectedImageType) {
            return true;
        }

        return false;
    }

    handleSelectDeliveryType(option) {
        if (!this.state.isDeliveryTypeDirty) {
            this.setState({isDeliveryTypeDirty: true});
        }
        if (option === null) {
            ImageActions.update('deliveryType', null);
            return;
        }
        ImageActions.update('deliveryType', option.id);
        return;
    }

    handleSelectMimeType(option) {
        if (!this.state.isMimeTypeDirty) {
            this.setState({isMimeTypeDirty: true});
        }
        if (option === null) {
            ImageActions.update('mimeType', null);
            return;
        }
        ImageActions.update('mimeType', option.id);
        return;
    }

    handleSelectImageType(option) {
        if (!this.state.isImageTypeDirty) {
            this.setState({isImageTypeDirty: true});
        }

        if (option === null) {
            ImageActions.update('imageSuperType', null);
            ImageActions.update('contentType', null);
            return;
        }

        ImageActions.update('imageSuperType', option.imageSuperType);
        ImageActions.update('contentType', option.id);

        return;
    }

    handleTinyMCEChange(attr) {
        return function handler(e) {
            ImageActions.update(attr, e.target.getContent());
            return;
        };
    }

    render() {
        let additionalInformation;
        let fileType;
        let updatedBy = <div>{this.context.intl.messages['common.na']}</div>;
        let updatedDate = <div>{this.context.intl.messages['common.na']}</div>;

        if (this.props.mode === 'edit') {
            updatedBy = this.props.asset.get('updatedBy') || '-';
            updatedDate = '-';
            if (this.props.asset.get('updatedDate')) {
                updatedDate = Moment(this.props.asset.get('updatedDate')).tz(config.DefaultTimezone).format(this.context.intl.messages['datetime-format']);
            }
            additionalInformation = (<div>
                <hr />
                <FormRow>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['asset.edit.last-modified-date']}</ControlLabel>
                        <FormControl.Static>{updatedDate}</FormControl.Static>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['asset.edit.last-modifier']}</ControlLabel>
                        <FormControl.Static>{updatedBy}</FormControl.Static>
                    </FormGroup>
                </FormRow>
            </div>);

            fileType = '';
            let sourceUrlName, sourceUrlType, fullResolutionUrlName, fullResolutionUrlType;
            const sourceUrl = this.props.asset.get('sourceUrl');
            const fullResolutionUrl = this.props.asset.get('fullResolutionUrl');
            if (sourceUrl) {
                sourceUrlName = sourceUrl.split('?')[0].split('/').pop();
                sourceUrlType = sourceUrl.split('?')[0].split('.').pop();
            }
            if (fullResolutionUrl) {
                fullResolutionUrlName = fullResolutionUrl.split('?')[0].split('/').pop();
                fullResolutionUrlType = fullResolutionUrl.split('?')[0].split('.').pop();
            }
            const loadingGif = 'loading-image.gif';
            if ((sourceUrl && sourceUrlName !== loadingGif) || (fullResolutionUrl && fullResolutionUrlName!== loadingGif)) {
                fileType = sourceUrlType || fullResolutionUrlType;
            }
        }

        return (
            <div>
                <h3><i className="fas fa-info-circle"></i>{this.context.intl.messages['asset.info']}</h3>
                <FormSection>
                    <FormRow>
                        <FormItem
                            attr="assetName"
                            label={this.context.intl.messages['asset.image.name']}
                            disabled={this.props.disabled}
                            model={this.props.asset}
                            setter={ImageActions.update}
                            type="text"
                            validations={ImageValidations.assetName.validations}
                        />
                        <FormItem
                            attr="assetComment"
                            label={this.context.intl.messages['asset.image.assetComment']}
                            disabled={this.props.disabled}
                            model={this.props.asset}
                            setter={ImageActions.update}
                            type="text"
                            validations={ImageValidations.assetComment.validations}
                        />
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['asset.image.contentType']}&nbsp;<span className="text-red">*</span></ControlLabel>
                            <BaseSelect
                                disabled={this.props.disabled}
                                groupBy="subType"
                                name={this.context.intl.messages['asset.image.contentType']}
                                onChange={this.handleSelectImageType}
                                options="contentType"
                                store={ImageStore}
                                validations={ImageValidations.contentType.validations}
                                value={this.state.selectedImageType}
                            />
                        </FormGroup>
                        <FormItem
                            attr="credit"
                            label={this.context.intl.messages['asset.image.credit']}
                            disabled={this.props.disabled}
                            model={this.props.asset}
                            setter={ImageActions.update}
                            type="text"
                            validations={ImageValidations.credit.validations}
                        />
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['asset.image.deliveryType']}&nbsp;<span className="text-red">*</span></ControlLabel>
                            <BaseSelect
                                disabled={this.props.disabled}
                                name={this.context.intl.messages['asset.image.deliveryType']}
                                onChange={this.handleSelectDeliveryType}
                                options="deliveryType"
                                store={AssetStore}
                                validations={ImageValidations.deliveryType.validations}
                                value={this.state.selectedDeliveryType}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['asset.image.mimeType']}&nbsp;</ControlLabel>
                            <BaseSelect
                                disabled={this.props.disabled}
                                name={this.context.intl.messages['asset.image.mimeType']}
                                onChange={this.handleSelectMimeType}
                                options="imageMimeTypes"
                                store={AssetStore}
                                value={this.state.selectedMimeType}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['asset.image.type-file']}&nbsp;</ControlLabel>
                            <input type="text" value={fileType} className="form-control" readOnly="true" />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['common.status']}</ControlLabel>
                            <FormItem
                                attr="active"
                                disabled={this.props.disabled}
                                label={this.context.intl.messages['common.active']}
                                model={this.props.asset}
                                setter={ImageActions.update}
                                type="bool-checkbox"
                            />
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <FormItem attr="copyrightYear"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['asset.image.copyrightYear']}
                            setter={ImageActions.update}
                            md={3}
                            model={this.props.asset}
                            type="text"
                            validations={ImageValidations.copyrightYear.validations}
                        />
                        <FormItem attr="copyrightText"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['asset.image.copyrightText']}
                            setter={ImageActions.update}
                            model={this.props.asset}
                            type="text"
                            validations={ImageValidations.copyrightText.validations}
                        />
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['asset.image.caption']}</ControlLabel>
                            <TinyMCE
                                content={this.props.asset.get('caption', '')}
                                id="caption"
                                onChange={this.handleTinyMCEChange('caption')}
                            />
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <FormItem attr="keyword"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['asset.image.keywords']}
                            setter={ImageActions.update}
                            model={this.props.asset}
                            type="text"
                            validations={ImageValidations.keyword.validations}
                        />
                    </FormRow>
                    {additionalInformation}
                </FormSection>
            </div>
        );
    }

}

export default WithValidations(Summary);
