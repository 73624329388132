/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
*/

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import ReactTable from 'react-table';

import {getColumns} from '../../../dashboard/table';
import 'react-table/react-table.css';

export default class Table extends Component {
    static get propTypes() {
        return {
            title: PropTypes.instanceOf(Immutable.Map).isRequired,
            canEdit: PropTypes.bool.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            id: Math.ceil(Math.random() * 100000)
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();
    }

    render() {
        const filteredFieldStatus = getColumns(this.props.canEdit).filter(field => {
            const headerName = field.headerName;
            // dont show first 5 columns
            if (headerName === 'RO' || headerName === 'Parent' || headerName === 'Title' || headerName === 'Title Type' || headerName === 'MPM') {
                return false;
            } else {
                if (field.isEditable) {
                    return field.isEditable(this.props.title);
                } else {
                    return true;
                }
            }
        });

        let columnsMap = filteredFieldStatus.reduce((data, c) => {
            data[c.headerName] = {
                headerColor: c.headerColor,
                value: c.cellRendererParams.value(this.props.title, this.context)
            };
            return data;
        }, {});

        let columns = filteredFieldStatus.map(col => {
            // Get column width
            const headerWidth = col.headerName.length;
            let valueWidth = 0;
            const value = columnsMap[col.headerName].value.props.children;
            if (value) {
                valueWidth = value.length || 0;
            }
            const maxWidth = (Math.max(headerWidth, valueWidth) * 5) + 70;
            let width = 99;

            if (maxWidth > 99) {
                width = maxWidth;
            }

            // Get column color
            const headerColor = columnsMap[col.headerName].headerColor;
            return {
                Header: <strong>{col.headerName}</strong>,
                headerClassName: `dashboard-border-${headerColor}`,
                width,
                className: 'no-padding',
                Cell: /* istanbul ignore next */ c => {
                    return c.original.getIn([col.headerName, 'value']).toJS() || '-';
                }
            };
        });

        const columnsData = Immutable.fromJS([columnsMap]);

        return (
            <ReactTable
                className="-striped table-bordered table-striped responsive"
                columns={columns}
                data={columnsData}
                getNoDataProps= {/* istanbul ignore next */() => {
                    if (columnsData.size) {
                        return {style: {display: 'none'}};
                    }
                    return {};
                }}
                id={`dasboard-items-table-${this.state.id}`}
                loading={false}
                pageSize = {columnsData.size}
                showPagination={false}
                sortable={false}
                resizable={false}
            />
        );
    }
}
