/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {from, of, timer} from 'rxjs';
import {concatMap, filter, finalize, mergeMap, map, takeWhile} from 'rxjs/operators';

import {NotificationActions} from '~/src/common/notification/notification-actions';
import {StartDownload} from '~/src/common/utils/utils';
import Dispatcher from '~/src/dispatcher/dispatcher';
import Request from '~/src/request';
import {RouterActions} from '~/src/router/router-actions';

const CONSTANTS = {
    CLEAR: 'download_actions.clear',
    EXECUTION_ID: {
        GET: {
            SUCCESS: 'download_actions.execution_id.get.success'
        }
    },
    EXECUTION_STATUS: {
        START: 1,
        RUNNING: 2,
        COMPLETED: 3,
        FAILED: 4,
        GET: {
            END: 'download_actions.execution_status.get.end',
            START: 'download_actions.execution_status.get.start',
            SUCCESS: 'download_actions.execution_status.get.success'
        }
    }
};

class DownloadActions {
    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
    }

    downloadExecutionZip(executionId) {
        return Request.get(`asset/download-execution/${executionId}/file`).then((res) => {
            // Start the download by simulating a click in the browser.
            StartDownload(res.body.downloadUrl);
        }).catch(err => {
            NotificationActions.showAlertDanger('asset.download.error');
            throw err;
        });
    }

    /**
     * Process a generic threaded download execution, redirecting the user to the target page while querying for process
     * completion in the background.
     *
     * @param {*} res The original triggering request. Its body must have a defined 'downloadExecutionId' parameter.
     * @param {*} redirectTo The page where the user shall be redirected to and wait for his download to complete.
     * @returns
     */
    resolveDownloadExecutionFor(res, redirectTo, newTab) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.EXECUTION_ID.GET.SUCCESS,
            downloadExecutionId: res.body.downloadExecutionId
        });

        if (newTab) {
            window.open(redirectTo, '_blank');
        } else {
            RouterActions.redirect(redirectTo);
        }
    }

    startDownloadExecution(endpointUrl, query = {}, body, method = 'get', openDownloadPage = true) {
        let request = Request[method](endpointUrl).query(query);
        if (body) {
            request = request.send(body);
        }

        return request.exec().then(res=> {
            if (!openDownloadPage) {
                return;
            }

            const downloadExecutionId = res.body.downloadExecutionId;
            // Start download in a new window
            window.open(`/download/${downloadExecutionId}`, '_blank');
        });
    }

    watchDownloadExecution(downloadExecutionId, fakeProgress = false, fakeAmount = 0.01) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.EXECUTION_ID.GET.SUCCESS,
            downloadExecutionId
        });

        let requestInProgress = false;
        let progress = 0;
        timer(
            0,
            500
        ).pipe(
            filter(() => !requestInProgress),
            mergeMap(() => {
                requestInProgress = true;
                return from(Request.get(`asset/download-execution/${downloadExecutionId}`));
            }),
            map(downloadStatusRes => {
                requestInProgress = false;
                if (fakeProgress && downloadStatusRes.body.status === CONSTANTS.EXECUTION_STATUS.RUNNING) {
                    progress += fakeAmount;
                } else {
                    progress = downloadStatusRes.body.currentFileCount;
                }
                return {
                    actionType: CONSTANTS.EXECUTION_STATUS.GET.SUCCESS,
                    fileName: downloadStatusRes.body.fileName,
                    processed: progress,
                    progressMessage: downloadStatusRes.body.progressMessage,
                    status: downloadStatusRes.body.status,
                    total: downloadStatusRes.body.totalFileCount
                };
            }),
            concatMap(action => {
                // Ugly hack because takeWhile doesn't include last predicate.
                if (
                    action.status === CONSTANTS.EXECUTION_STATUS.COMPLETED ||
                    action.status === CONSTANTS.EXECUTION_STATUS.FAILED
                ) {
                    return of(action, null);
                }

                return of(action);
            }),
            takeWhile(action => !!action),
            finalize(() => Dispatcher.dispatch({
                actionType: CONSTANTS.EXECUTION_STATUS.GET.END
            }))
        ).subscribe(
            action => Dispatcher.dispatch(action),
            null,
            () => void 0
        );
    }
}

const actions = new DownloadActions();

export {
    actions as DownloadActions,
    CONSTANTS as DownloadConstants,
};
