/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import Summary from './summary/summary';
import RouteValidator from '../../../common/custom-validator/route/route';
import DocumentTitle from '../../../common/document-title';
import FileInput from '../../../common/file-input';
import Panel from '../../../common/panel/panel';
import {Tab, Tabs} from '../../../common/routing-tab/routing-tab';
import {History, SideNotes} from '../../../common/side-notes/side-notes';
import {AlertsWarnings} from '../../../common/validations/alerts-warnings';
import ActionsMenu from '../../../layout/actions-menu/actions-menu';
import StickyHeader from '../../../layout/sticky-header/sticky-header';
import Preloader from '../../../preloader';
import {RouterActions} from '../../../router/router-actions';
import {ActionHistoryActions, ActionHistoryConstants} from '../../../system/action-history/action-history-actions';
import SessionStore from '../../../user/session/session-store';
import {AssetActions} from '../../asset-actions';
import {AssetTalentActions} from '../../asset-talent-actions';
import AssetTalentStore from '../../asset-talent-store';
import AssetUserStore from '../../asset-user-store';
import {AssetCatalogActions} from '../../catalogs/asset-catalog-actions';
import AssetCatalogStore from '../../catalogs/asset-catalog-store';
import CatalogsTab from '../../catalogs/catalogs';
import TalentTab from '../../talent/talent';
import TitleTab from '../../title/title';
import UserTab from '../../user/user';
import {DocumentActions} from '../document-actions';
import DocumentStore from '../document-store';


class Create extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object,
            permissions: PropTypes.object.isRequired,
            params: PropTypes.object.isRequired,
            route: RouteValidator
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            route: undefined,
            location: undefined
        };
    }

    static getPermissions() {
        return {
            canCreate: SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.ASSET_DOCUMENT.CREATE),
            canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.ASSET_DOCUMENT.EDIT),
            canReadCatalogs: SessionStore.canUser(SessionStore.PERMISSIONS.CATALOGS.ASSET.READ),
            canReadTalent: SessionStore.canUser(SessionStore.PERMISSIONS.TALENT.READ),
            canReadTitles: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.READ),
            canReadUsers: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.READ)
        };
    }

    static calculateState() {
        return {
            asset: DocumentStore.getState().get('asset'),
            history: DocumentStore.getState().get('history'),
            originalAsset: DocumentStore.getState().get('originalAsset'),
            originalDocument: DocumentStore.getState().get('originalDocument'),
            showPreloader: DocumentStore.getState().get('showPreloader'),
            showUserPreloader: AssetUserStore.getState().get('showPreloader')
        };
    }

    static getStores() {
        return [AssetUserStore, DocumentStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            activeTab:0
        }, this.constructor.calculateState());

        this.addNote = this.addNote.bind(this);
        this.handleAddFiles = this.handleAddFiles.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
        this.handleElasticSearchSync = this.handleElasticSearchSync.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.isDirty = this.isDirty.bind(this);
    }

    componentDidMount() {
        this.init(this.props);
        return;
    }

    addNote(description) {
        ActionHistoryActions.addNote(this.props.params.id, description, ActionHistoryConstants.ACTION_OBJECTS.DOCUMENT);
    }

    handleAddFiles(e) {
        DocumentActions.update('files', [...e.target.files]);
    }

    handleDownload() {
        AssetActions.download(this.state.asset.get('id'));
    }

    handleElasticSearchSync() {
        AssetActions.syncElasticSearch(this.props.params.id);
    }

    handleSave() {
        let options = {
            messages: {
                error: `asset.document.${this.props.route.mode}.error`,
                success: `asset.document.${this.props.route.mode}.success`
            }
        };

        const documentFile = this.state.asset.get('files') || [];

        DocumentActions.save(this.state.asset,
            documentFile,
            AssetCatalogStore.getState().get('assignedCatalogs'),
            AssetCatalogStore.getState().get('originalAssignedCatalogs'),
            AssetTalentStore.getState().get('talent'),
            AssetTalentStore.getState().get('originalTalent'),
            options,
            this.state.originalDocument);

        return;
    }

    handleCancel() {
        RouterActions.redirect('/assets', true);
    }

    isDirty() {
        if (this.props.route.mode === 'create') {
            return false;
        }

        if (location) {
            let goingTo = location.pathname.split('/');
            let current = this.props.location.pathname.split('/');
            if (goingTo[1] === current[1] && /* asset */
                goingTo[2] === current[2] && /* document */
                goingTo[3] === current[3]) /* asset id */ {
                return false; // just changing tabs no confirmation needed.
            }
        }

        return !this.state.asset.equals(this.state.originalAsset) ||
        !AssetCatalogStore.getState().get('assignedCatalogs').equals(AssetCatalogStore.getState().get('originalAssignedCatalogs')) ||
        !AssetTalentStore.getState().get('talent').equals(AssetTalentStore.getState().get('originalTalent'));
    }


    init(props) {
        if (props.route.mode === 'edit') {
            DocumentActions.findById(props.params.id);
        } else {
            DocumentActions.clear();
            AssetTalentActions.clear();
            AssetCatalogActions.clear();
        }
        RouterActions.registerRedirectCheck(() => {
            return this.isDirty();
        });
    }

    render() {
        let actionsMenu;
        let disableForm = true;
        let mode = this.props.route.mode;
        let uploadedFile = this.state.asset.get('documentURL') || '';
        let fileDownload;
        let fileUpload;
        let filename = uploadedFile.split('/').pop();
        let documentFiles = [];
        if (this.state.asset.get('files')) {
            documentFiles = this.state.asset.get('files', Immutable.List()).map(f => f.name);
        }

        let validations = DocumentStore.getValidations();
        let eventsAndNotes = null;

        if (uploadedFile !== '') {
            fileUpload = <div>
                <Panel collapsible iconClass="fas fa-upload" title={this.context.intl.messages['asset.document.file.replace']} additionalStyle="padding-x-20">
                    <FileInput
                        accept="document/*"
                        id="document-file"
                        files={documentFiles}
                        onChange={this.handleAddFiles}
                    />
                </Panel>
            </div>;
        } else {
            fileUpload = <div>
                <Panel collapsible defaultExpanded iconClass="fas fa-upload" title={this.context.intl.messages['asset.document.file.upload']} additionalStyle="padding-x-20">
                    <i>{filename}</i>
                    <FileInput
                        accept="document/*"
                        id="document-file"
                        files={documentFiles}
                        onChange={this.handleAddFiles}
                    />
                </Panel>
            </div>;
        }

        // catalogs tab for create mode
        let catalogsTab;

        let baseRoute = `/assets/document/${this.props.params.id}`;
        if (mode === 'create') {
            baseRoute = '/assets/document/create';
            if (this.props.permissions.canCreate) {
                disableForm = false;
            }

            catalogsTab = <Tab route="/assets/document/create/catalogs" title={this.context.intl.messages['asset.edit.catalogs-tab-title']}>
                <CatalogsTab
                    displayLinks={this.props.permissions.canReadCatalogs}
                />
            </Tab>;

            actionsMenu = <ActionsMenu
                canSave={!validations.length}
                onCancel={this.handleCancel}
                onSave={this.handleSave}
                saveText={this.context.intl.messages['common.create-asset']}
            />;
        }

        // Tabs
        let historyTab;
        let talentTab;
        let titlesTab;
        let userTab;

        if (mode === 'edit') {
            if (this.props.permissions.canEdit) {
                disableForm = false;
            }
            if (uploadedFile !== '') {
                fileDownload = <div>
                    <Panel iconClass="fas fa-download" title={this.context.intl.messages['asset.document.file.download']} additionalStyle="padding-x-20">
                        <a className="Cur(p)" style={{wordBreak: 'break-word'}} onClick={this.handleDownload} target="_blank">{filename}</a>
                    </Panel>
                </div>;
            }

            actionsMenu = <ActionsMenu
                canEdit={this.props.permissions.canEdit}
                canSave={!validations.length && this.props.permissions.canEdit}
                hideSaveAndSync={!this.props.permissions.canEdit}
                onCancel={this.handleCancel}
                onElasticSearchSync={this.handleElasticSearchSync}
                onSave={this.handleSave}
            />;

            eventsAndNotes = (
                <SideNotes
                    basePath={`/assets/document/${this.props.params.id}`}
                    dialogMessage={this.context.intl.messages['common.side-notes.add-note.body']}
                    disabled={ !this.props.permissions.canEdit }
                    notes={this.state.history.toJS().slice(0, 4)}
                    onAddNote={this.addNote}
                    title={this.context.intl.messages['common.side-notes.title']}
                />
            );

            historyTab = <Tab route={`${baseRoute}/history`} title={this.context.intl.messages['common.tab-title.history']} tabClassName="pull-right">
                <History
                    actionObject={ActionHistoryConstants.ACTION_OBJECTS.DOCUMENT}
                    id={this.props.params.id}
                    pagination
                    pathname={this.props.location.pathname}
                    query={this.props.location.query}/>
            </Tab>;

            titlesTab = <Tab route={`${baseRoute}/titles`} title={this.context.intl.messages['titles.create.edit-title-tab-title']}>
                <TitleTab
                    assetId={parseInt(this.props.params.id, 10)}
                    displayLinks={this.props.permissions.canReadTitles}
                    location={this.props.location}
                />
            </Tab>;
            talentTab = <Tab route={`${baseRoute}/talent`} title={this.context.intl.messages['asset.talent.tab.title']}>
                <TalentTab
                    assetId={parseInt(this.props.params.id, 10)}
                    displayLinks={this.props.permissions.canReadTalent}
                />
            </Tab>;
            userTab = <Tab route={`${baseRoute}/users`} title={this.context.intl.messages['asset.user.tab.title']}>
                <UserTab
                    assetId={parseInt(this.props.params.id, 10)}
                    displayLinks={this.props.permissions.canReadUsers}
                    location={this.props.location}
                />
            </Tab>;
            catalogsTab = <Tab route={`${baseRoute}/catalogs`} title={this.context.intl.messages['asset.edit.catalogs-tab-title']}>
                <CatalogsTab
                    assetId={parseInt(this.props.params.id, 10)}
                    displayLinks={this.props.permissions.canReadCatalogs}
                />
            </Tab>;
        }

        let summaryTab = <Tab route={baseRoute} title={this.context.intl.messages['titles.create.summary-tab-title']}>
            <Summary disabled={disableForm} asset={this.state.asset} mode={mode}/>
        </Tab>;

        let entityName;

        if (this.state.asset.get('name')) {
            entityName = this.state.asset.get('name');
        }

        return (
            <DocumentTitle
                message={`document-titles.${mode}-document`} entityName={entityName}
            >
                <div>
                    <StickyHeader>
                        <div className="col-md-6">
                            <h1>
                                <i className="fas fa-file-alt"></i>&nbsp;{this.context.intl.messages[`asset.document.${mode}.title`]}
                                &nbsp;
                                <small>{this.state.asset.get('name')}</small>
                            </h1>
                            <p className="padding-top-20">
                                <em>{this.context.intl.messages[`asset.document.${mode}.info`]}</em>
                            </p>
                        </div>
                        {actionsMenu}
                    </StickyHeader>
                    <Preloader show={this.state.showPreloader||this.state.showUserPreloader} fixed loadingDots>
                        <section className="content" id="contentContainer">
                            <div className="row">
                                <div className="col-lg-9">
                                    <Tabs location={this.props.location}>
                                        {summaryTab}
                                        {titlesTab}
                                        {talentTab}
                                        {userTab}
                                        {catalogsTab}
                                        {historyTab}
                                    </Tabs>
                                </div>
                                <div className="col-lg-3">
                                    <AlertsWarnings
                                        title={this.context.intl.messages['common.alerts-warnings-title']}
                                        validations={validations}
                                    />
                                    {fileDownload}
                                    {fileUpload}
                                    {eventsAndNotes}
                                </div>
                            </div>
                        </section>
                    </Preloader>
                </div>
            </DocumentTitle>
        );
    }
}
export default Container.create(Create);
