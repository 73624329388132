/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link} from 'react-router';

import Config from '../../../config/config.js';
import {ActionHistoryConstants} from '../../../system/action-history/action-history-actions';
import {HistoryConstants} from '../history-actions';

class Event extends Component {
    static get propTypes() {
        return {
            note: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.getDescriptionTable = this.getDescriptionTable.bind(this);
    }

    getDescriptionTable() {
        let format = value => {
            if (value !== undefined && typeof value === 'string' && Moment.utc(value, this.context.intl.messages['history.changed-field.date-format'], true).isValid()) {
                return Moment.utc(value).tz(Config.DefaultTimezone).format(this.context.intl.messages['datetime-format']);
            }
            return value;
        };
        let changedFields = this.props.note.changeDescription;
        if (typeof(this.props.note.changedFields) === 'string') {
            changedFields = this.props.note.changedFields;
        }
        if (typeof(this.props.note.changedFields) === 'object' && !Array.isArray(this.props.note.changedFields)) {
            changedFields = (
                <div className="col-md-12 padding-y-10">
                    <div className="col-md-6 old-value-column">
                        <div className="history-field-header">{this.context.intl.messages['history.changed-field.old-value']}</div>
                        <div className="history-field-old">
                            <p>{format(this.props.note.changedFields.oldValue)}</p>
                        </div>
                    </div>
                    <div className="col-md-6 new-value-column">
                        <div className="history-field-header">{this.context.intl.messages['history.changed-field.new-value']}</div>
                        <div className="history-field-new">
                            <p>{format(this.props.note.changedFields.newValue)}</p>
                        </div>
                    </div>
                </div>
            );
        }
        return changedFields;
    }

    getTimelineCreationLink(note) {
        const {changedFields} = note;
        if (!changedFields || !Array.isArray(changedFields)) {
            return JSON.stringify(changedFields);
        }
        const qcVideoId = changedFields.find(field => field.fieldName === 'id')?.newValue;
        const qcVideoName = changedFields.find(field => field.fieldName === 'assetName')?.newValue;
        return (
            <div className="col-md-12 padding-y-10">
                <div className="col-md-6 old-value-column">
                    <div className="history-field-header">{this.context.intl.messages['history.qc-video.read-only']}</div>
                    <div>
                        <Link target="_blank" to={`/hardac/video-inbox/${qcVideoId}`} className="qc-link">
                            {qcVideoName}&nbsp;<i className="fa-solid fa-arrow-up-right-from-square"/>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        let description, displayDescription;
        let bodyStyle = 'history-field-new';
        if (HistoryConstants.ACTION_HISTORY_TYPE_DATA[this.props.note.actionHistoryType].action === 'removed') {
            bodyStyle = 'history-field-old';
        }

        // In case Timeline creation do not show all fields changed, we will show only a link to QC video
        const isTimelineCreation = this.props.note.actionHistoryType === ActionHistoryConstants.TYPES.CREATE &&
            this.props.note.actionObjectType.toLowerCase() === ActionHistoryConstants.ACTION_OBJECTS.VIDEOTIMELINE.toLocaleLowerCase();
        if (isTimelineCreation) {
            description = this.getTimelineCreationLink(this.props.note);
        } else {
            description = this.getDescriptionTable(this.props.note);
        }

        if (this.props.note.actionHistoryType === ActionHistoryConstants.TYPES.DELETE && this.props.note.displayDescription !== this.props.note.changeDescription) {
            displayDescription = this.props.note.displayDescription;
        }

        if (description && description.type !== 'div') {
            description = (
                <div className="col-md-12">
                    <div className={bodyStyle}>
                        {description}
                    </div>
                </div>
            );
        }
        if (displayDescription) {
            displayDescription = (
                <div className="col-md-12">
                    <div className={bodyStyle}>
                        {displayDescription}
                    </div>
                </div>
            );
        }

        return (
            <div className="row">
                {displayDescription}
                {description}
            </div>
        );
    }
}

export default Event;
