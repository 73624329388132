/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {ControlLabel, FormGroup, FormControl} from 'react-bootstrap';
import Select from 'react-select';

import {FormSection, FormRow, FormItem} from '../../common/form/form';
import config from '../../config/config.js';
import {PartnerActions} from '../../partner/partner-actions';
import PartnerStore from '../../partner/partner-store';
import {PermissionPackageActions, PermissionPackageConstants} from '../../security/permission-package/permission-package-actions';
import PermissionPackageStore from '../../security/permission-package/permission-package-store';
import {GuildActions, GuildConstants} from '../guild-actions';
import GuildStore, {GuildValidations, GuildDateRangeValidations} from '../guild-store';

import {GetAttr} from '~/src/common/utils/utils';

import '../guilds.less';

class Summary extends React.Component {
    static getPermissions() {
        return {};
    }

    static getStores() {
        return [GuildStore, PermissionPackageStore, PartnerStore];
    }

    static get propTypes() {
        return {
            mode: PropTypes.string.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            guild: GuildStore.getState().get('guild'),
            permissionPackages: PermissionPackageStore.getState().get('permissionPackages'),
            partners: PartnerStore.getState().get('partners'),
            selectedCityTimezone: GuildStore.getState().getIn(['guild', 'displayTimezone']),
            userCount: GuildStore.getState().get('total'),
        };
    }

    constructor(props) {
        super(props);
        const orderWithNumbers = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});

        this.state = Object.assign(
            {allTimezones: Moment.tz.names().sort(orderWithNumbers.compare)},
            this.constructor.calculateState()
        );

        this.formatDateHourForInput = this.formatDateHourForInput.bind(this);
        this.handleChangeDateDay = this.handleChangeDateDay.bind(this);
        this.handleChangeDateTime = this.handleChangeDateTime.bind(this);
        this.handleSelectCity = this.handleSelectCity.bind(this);
        this.handleSyncAllAccounts = this.handleSyncAllAccounts.bind(this);
    }

    componentDidMount() {
        PermissionPackageActions.get(Immutable.fromJS({active: PermissionPackageConstants.FILTERS.ACTIVE_OPTIONS.ACTIVE.id}), 0, 99999);
        PartnerActions.get(0, 99999);
    }

    handleAnonymousSignupChange(anonymousSignup) {
        if (!anonymousSignup) {
            GuildActions.updateGuild('partnerId', null);
            GuildActions.updateGuild('emailTemplateName', null);
        }
    }

    handleSelectPartner(partner) {
        GuildActions.updateGuild('partnerId', partner.id);
    }

    handleSelectCity(timeZone) {
        if (this.state.guild.get('validStartDate')) {
            GuildActions.updateGuild('validStartDate', Moment.tz(this.state.guild.get('validStartDate'), timeZone).format());
        }

        if (this.state.guild.get('validEndDate')) {
            GuildActions.updateGuild('validEndDate', Moment.tz(this.state.guild.get('validEndDate'), timeZone).format());
        }
        GuildActions.updateGuild('displayTimezone', timeZone);
    }

    handleChangeDateTime(attr, hour) {
        const notIsoDate = Moment.tz(this.state.guild.get(attr), this.state.selectedCityTimezone);
        const hours = Moment(hour, 'hh:mm a');

        notIsoDate.set({hour: hours.hour(), minute: hours.minute()});
        GuildActions.updateGuild(attr, notIsoDate.format());
    }

    cancelDateFuction(value, attr) {
        /*To if its cancel, clear date*/
        if (!value) {
            GuildActions.updateGuild(attr, value);
        }
    }

    handleChangeDateDay(attr, day) {
        let notIsoDate = Moment.tz(day, this.state.selectedCityTimezone).set({hour: 0, minute: 0});

        if (attr === 'validEndDate') {
            notIsoDate = Moment.tz(day, this.state.selectedCityTimezone).set({hour: 23, minute: 59});
        }
        GuildActions.updateGuild(attr, notIsoDate.format());
    }

    formatDateHourForInput(timestamp, timezone, format) {
        if (!timestamp) {
            return null;
        }

        return Moment.tz(timestamp, timezone).format(format);
    }


    handleSelectPermissionPackage(permissionPackage) {
        GuildActions.updateGuild('permissionPackageId', permissionPackage.id);
    }

    handleSyncAllAccounts() {
        GuildActions.syncAllAccounts(this.state.guild);
    }

    render() {
        let additionalInformation;
        let anonymousSignUp;
        let applyDatesButton;
        let guildHasUsers = this.state.userCount > 0;
        let status;
        let updatedBy = <div>N/A</div>;
        let updatedDate = <div>N/A</div>;
        const req = (<span className="text-red">&nbsp;*</span>);
        const selectedPartner = PartnerStore.getState().get('partners').toJS().find((elem) => {
            return elem.id === GuildStore.getState().getIn(['guild', 'partnerId']);
        });
        const selectedPermissionPackage = PermissionPackageStore.getState().get('permissionPackages').toJS().find((elem) => {
            return elem.id === GuildStore.getState().getIn(['guild', 'permissionPackageId']);
        });
        const validations = GuildStore.getValidations();
        const dateFormat = 'MM/DD/YYYY';
        const timeFormat = 'hh:mm a';
        let startEndDateNote;

        if (this.props.mode === 'edit') {
            let title = '';
            startEndDateNote = <small>
                <em><strong>{this.context.intl.messages['guild.summary.dates.note']}</strong>{this.context.intl.messages['guild.summary.dates.note.text']}</em>
            </small>;
            if (!guildHasUsers) {
                title = this.context.intl.messages['guild.summary.dates.apply.button.disabled'];
            }
            applyDatesButton = <div>
                <ControlLabel>{this.context.intl.messages['guild.summary.dates.apply.title']}</ControlLabel>
                <button
                    className="btn btn-primary btn-lg btn-block"
                    disabled={!guildHasUsers || validations.length}
                    onClick={this.handleSyncAllAccounts}
                    title={title}
                >
                    <i className="far fa-calendar-alt"></i>&nbsp;{this.context.intl.messages['guild.summary.dates.apply.button']}
                </button>
                <small>
                    <em>{this.context.intl.messages['guild.summary.dates.apply.caption']}</em>
                </small>
            </div>;
            const st = GuildConstants.GUILD.guildStatusMap(this.state.guild.get('active')) || {
                description: '',
                icon: '',
                label: ''
            };
            const tip = this.context.intl.messages[st.tip];
            status = <div>
                <ControlLabel>{this.context.intl.messages['guild.summary.active.title']}</ControlLabel>
                <FormControl.Static><span className={ClassNames('label', st.label)} data-toggle="tooltip" title={tip} data-placement="top"><i className={ClassNames(st.icon)}></i>&nbsp;<span className="hidden-xs hidden-sm">{st.description}</span></span></FormControl.Static>
            </div>;

            updatedBy = '-';
            if (this.state.guild.get('updatedBy')) {
                updatedBy = this.state.guild.get('updatedBy');
            }
            updatedDate = '-';
            if (this.state.guild.get('updatedDate')) {
                updatedDate = Moment(this.state.guild.get('updatedDate')).tz(config.DefaultTimezone).format(this.context.intl.messages['datetime-format']);
            }
            additionalInformation = <FormRow>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['guild.summary.updated_date']}</ControlLabel>
                    <FormControl.Static>{updatedDate}</FormControl.Static>
                </FormGroup>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['guild.summary.last_modifier']}</ControlLabel>
                    <FormControl.Static>{updatedBy}</FormControl.Static>
                </FormGroup>
            </FormRow>;
        }

        if (this.state.guild.get('anonymousSignUp')) {
            anonymousSignUp = (
                <FormRow>
                    <FormGroup md={6}>
                        <ControlLabel>{this.context.intl.messages['guild.summary.partner.label']}{req}</ControlLabel>
                        <Select
                            className="authorized-partner-dropdown"
                            getOptionLabel={GetAttr('name')}
                            getOptionValue={GetAttr('id')}
                            isClearable={false}
                            isMulti={false}
                            name="partnerId"
                            onChange={this.handleSelectPartner}
                            options={this.state.partners.toJS()}
                            isSearchable={false}
                            placeholder={this.context.intl.messages['guild.summary.partner.placeholder']}
                            value={selectedPartner}
                        />
                        <small>
                            <em>{this.context.intl.messages['guild.summary.partner.caption']}</em>
                        </small>
                    </FormGroup>
                    <FormItem attr="emailTemplateName"
                        label={this.context.intl.messages['guild.summary.email']}
                        legend={this.context.intl.messages['guild.summary.email.caption']}
                        onlySpaces
                        type="text"
                        model={this.state.guild}
                        setter={GuildActions.updateGuild}
                        validations={GuildValidations.emailTemplateName.validations}
                    />
                </FormRow>
            );
        }

        return (
            <div>
                <h3><i className="fas fa-shield-alt"></i>{this.context.intl.messages['guild.summary.info']}</h3>
                <FormSection>
                    <FormRow>
                        <FormItem attr="name"
                            label={this.context.intl.messages['guild.summary.name']}
                            legend={this.context.intl.messages['guild.summary.name.caption']}
                            md={6}
                            onlySpaces
                            type="text"
                            model={this.state.guild}
                            setter={GuildActions.updateGuild}
                            validations={GuildValidations.name.validations}
                        />
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['guild.summary.public_signup.title']}</ControlLabel>
                            <FormItem
                                attr="anonymousSignUp"
                                label={this.context.intl.messages['guild.summary.public_signup.label']}
                                model={this.state.guild}
                                onChange={this.handleAnonymousSignupChange}
                                setter={GuildActions.updateGuild}
                                type="checkbox"
                            />
                        </FormGroup>
                        <FormGroup>
                            {status}
                        </FormGroup>
                    </FormRow>
                    {anonymousSignUp}
                    <FormRow>
                        <FormGroup md={6}>
                            <ControlLabel>{this.context.intl.messages['guild.summary.permission_package.label']}{req}</ControlLabel>
                            <Select
                                className="permission-package-dropdown"
                                getOptionLabel={GetAttr('name')}
                                getOptionValue={GetAttr('id')}
                                isClearable={false}
                                isMulti={false}
                                isSearchable={true}
                                name="permissionPackageId"
                                onChange={this.handleSelectPermissionPackage}
                                options={this.state.permissionPackages.toJS()}
                                placeholder={this.context.intl.messages['guild.sum.permission_package.placeholder']}
                                value={selectedPermissionPackage}
                            />
                            <small>
                                <em>{this.context.intl.messages['guild.summary.permission_package.caption']}</em>
                            </small>
                        </FormGroup>
                        <FormItem attr="cdnBillingCode"
                            label={this.context.intl.messages['guild.summary.cdn_code']}
                            legend={this.context.intl.messages['guild.summary.cdn_code.caption']}
                            md={6}
                            onlySpaces
                            type="text"
                            model={this.state.guild}
                            setter={GuildActions.updateGuild}
                            validations={GuildValidations.cdnBillingCode.validations}
                        />
                    </FormRow>
                    <hr/>
                    {additionalInformation}
                </FormSection>
                <h3 className="guild-dates">
                    <i className="far fa-calendar-alt"></i>{this.context.intl.messages['guild.summary.dates.title']}
                    {startEndDateNote}
                </h3>
                <FormSection>
                    <FormRow>
                        <FormGroup md={6}>
                            <ControlLabel>{this.context.intl.messages['guild.summary.dates.timezone']}{req}</ControlLabel>
                            <Select
                                className="time-zone-location-dropdown"
                                getOptionLabel={data => data.replace('_', ' ')}
                                getOptionValue={data => data}
                                isClearable={false}
                                isMulti={false}
                                isSearchable={true}
                                name="displayTimezone"
                                onChange={this.handleSelectCity}
                                options={this.state.allTimezones}
                                placeholder={this.context.intl.messages['guild.summary.dates.timezone.placeholder']}
                                value={[this.state.selectedCityTimezone]}
                            />
                            <small>
                                <em>{this.context.intl.messages['guild.summary.dates.timezone.caption']}</em>
                            </small>
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <FormGroup className="guild-dates" md={6}>
                            <FormItem
                                addonBefore={<i className="far fa-calendar-alt"></i>}
                                attr="validStartDate"
                                datepicker={{
                                    selected: this.formatDateHourForInput(this.state.guild.get('validStartDate'), this.state.selectedCityTimezone, dateFormat)
                                }}
                                label={this.context.intl.messages['guild.summary.dates.start']}
                                model={this.state.guild}
                                onChange={(value) =>this.cancelDateFuction(value, 'validStartDate')}
                                onlySpaces
                                setter={this.handleChangeDateDay}
                                type="date"
                                validations={GuildValidations.validStartDate.validations}
                            />
                            <small>
                                <em>{this.context.intl.messages['guild.summary.dates.start.caption']}</em>
                            </small>
                        </FormGroup>
                        <FormGroup className="guild-dates" md={6}>
                            <FormItem
                                addonBefore={<i className="far fa-clock"></i>}
                                attr="validStartDate"
                                datepicker={{
                                    isClearable: false,
                                    popoverTargetOffset: '10px -36px',
                                    shouldCloseOnSelect: true,
                                    showTimeSelect: true,
                                    showTimeSelectOnly: true,
                                    value: this.formatDateHourForInput(this.state.guild.get('validStartDate'), this.state.selectedCityTimezone, timeFormat)
                                }}
                                disabled={!this.state.guild.getIn(['validStartDate'])}
                                label={this.context.intl.messages['guild.summary.dates.start.hour']}
                                model={this.state.guild}
                                setter={this.handleChangeDateTime}
                                type="time"
                                validations={GuildDateRangeValidations.updateDateRange.validations}
                            />
                            <small>
                                <em>{this.context.intl.messages['guild.summary.dates.start.hour.caption']}</em>
                            </small>
                        </FormGroup>
                    </FormRow>
                    <hr></hr>
                    <FormRow>
                        <div className="guild-dates">
                            <FormItem
                                addonBefore={<i className="far fa-calendar-alt"></i>}
                                attr="validEndDate"
                                datepicker={{
                                    selected: this.formatDateHourForInput(this.state.guild.get('validEndDate'), this.state.selectedCityTimezone, dateFormat)
                                }}
                                label={this.context.intl.messages['guild.summary.dates.end']}
                                model={this.state.guild}
                                onChange={(value) =>this.cancelDateFuction(value, 'validEndDate')}
                                onlySpaces
                                type="date"
                                setter={this.handleChangeDateDay}
                                validations={GuildValidations.validEndDate.validations}
                            />
                            <small>
                                <em>{this.context.intl.messages['guild.summary.dates.end.caption']}</em>
                            </small>
                        </div>
                        <div className="guild-dates">
                            <FormItem
                                addonBefore={<i className="far fa-clock"></i>}
                                attr="validEndDate"
                                datepicker={{
                                    isClearable: false,
                                    popoverTargetOffset: '10px -36px',
                                    shouldCloseOnSelect: true,
                                    showTimeSelect: true,
                                    showTimeSelectOnly: true,
                                    value: this.formatDateHourForInput(this.state.guild.get('validEndDate'), this.state.selectedCityTimezone, timeFormat)
                                }}
                                disabled={!this.state.guild.getIn(['validEndDate'])}
                                label={this.context.intl.messages['guild.summary.dates.end.hour']}
                                model={this.state.guild}
                                setter={this.handleChangeDateTime}
                                type="time"
                                validations={GuildDateRangeValidations.updateDateRange.validations}
                            />
                            <small>
                                <em>{this.context.intl.messages['guild.summary.dates.end.hour.caption']}</em>
                            </small>
                        </div>
                    </FormRow>
                    <hr></hr>
                    <FormRow>
                        <FormGroup md={6}>
                            {applyDatesButton}
                        </FormGroup>
                    </FormRow>
                </FormSection>
            </div>
        );
    }
}

export default Container.create(Summary);
