/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import ListUsers from '../../common/list-users/list-users';
import {Debounce} from '../../common/utils/utils';
import {ClientRepGroupActions} from '../../security/client-rep-group/client-rep-group-actions';
import {UserActions} from '../../user/user-actions';
import UserStore from '../../user/user-store';

class AddUserPanel extends Component {

    static get propTypes() {
        return {
            groupId: PropTypes.number.isRequired,
            slidingPanelId: PropTypes.string.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }


    static calculateState() {
        return {
            activePage: UserStore.getState().get('activePage'),
            filters: UserStore.getState().get('filters'),
            size: UserStore.getState().get('size'),
            sortDirection: UserStore.getState().get('sortDirection'),
            sortFieldName: UserStore.getState().get('sortFieldName'),
            totalPages: UserStore.getState().get('totalPages'),
            users: UserStore.getState().get('users')
        };
    }

    static getStores() {
        return [UserStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.initStores = this.initStores.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
        this.handleSortChange = this.handleSortChange.bind(this);
    }

    componentDidMount() {
        this.initStores(this.props);
        return;
    }

    componentWillReceiveProps(nextProps) {
        this.initStores(nextProps);
        return;
    }

    initStores() {
        UserActions.clearFilter();
        UserActions.get(UserStore.getState().get('filters'), 0, 20, UserStore.getState().get('sortFieldName'), UserStore.getState().get('sortDirection'));
        return;
    }

    handlePageChange(pageNumber) {
        // pageNumber x this.state.size = offset.
        UserActions.get(
            UserStore.getState().get('filters'),
            pageNumber * this.state.size,
            this.state.size,
            UserStore.getState().get('sortFieldName'),
            UserStore.getState().get('sortDirection'));
        return;
    }

    handleRowClick(event) {
        let addUserTarget;
        switch (true) {
        case !!~event.target.className.indexOf('add-user-panel'):
            addUserTarget = event.target;
            break;
        /* istanbul ignore next */
        case !!~event.target.parentNode.className.indexOf('add-user-panel'):
            // Work around for fontAwesome "fas fa-plus" icon click
            addUserTarget = event.target.parentNode;
            break;
        }
        /* istanbul ignore next */
        if (addUserTarget) {
            ClientRepGroupActions.addExistingUser(
                addUserTarget.getAttribute('data-user-id'),
                this.props.groupId,
                this.props.slidingPanelId
            );
        }

        return;
    }

    handleSearchTerm(term) {
        UserActions.clearFilter();
        UserActions.setFilter('email', term);
        UserActions.setFilter('company', term);
        UserActions.setFilter('name', term);
        UserActions.get(UserStore.getState().get('filters'), 0, 20, UserStore.getState().get('sortFieldName'), UserStore.getState().get('sortDirection'));
        return;
    }

    handleSortChange(sortFieldName, sortDirection) {
        UserActions.setSort(sortFieldName, sortDirection);
        UserActions.get(UserStore.getState().get('filters'), 0, this.state.size, UserStore.getState().get('sortFieldName'), UserStore.getState().get('sortDirection'));
        return;
    }

    render() {
        let columns = [
            'name', 'company', 'email',
            'status', 'clientRepGroup',
            {
                name: 'actions',
                get(user, props, context) {
                    return `<button
                        class="btn btn-primary add-user-panel"
                        data-user-id="${user.get('id')}"><i class="fas fa-plus"></i>&nbsp;${context.intl.messages['groups.client-rep-group.add.user']}</button>`;
                },
                onClick: this.handleRowClick
            }
        ];

        return (
            <div>
                <ListUsers
                    activePage={this.state.activePage}
                    columns={columns}
                    onSearchChange={this.handleSearchTerm}
                    onSortChange={this.handleSortChange}
                    onPageChange={this.handlePageChange}
                    searchTerm={this.state.filters.get('email')}
                    sortDirection={this.state.sortDirection}
                    sortFieldName={this.state.sortFieldName}
                    totalPages={this.state.totalPages}
                    users={this.state.users}/>
            </div>
        );
    }
}

export default Container.create(AddUserPanel);
