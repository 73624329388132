/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ProgressBar} from 'react-bootstrap';

import {FormSection} from '../../../common/form/form';
import Config from '../../../config/config.js';
import {RouterActions} from '../../../router/router-actions';

const getHoursElapsed = (startDate) => {
    const start = Moment(startDate).tz(Config.DefaultTimezone);
    const end = Moment(new Date()).tz(Config.DefaultTimezone);
    return end.diff(start, 'hours');
};

const getTimeElapsed = (process) => {
    const {sentDate, processComplete, failureDate, processCompleteDate} = process.toJS();
    let lastDate = new Date();
    if (failureDate) {
        lastDate = failureDate;
    }
    if (processComplete) {
        lastDate = processCompleteDate;
    }
    const start = Moment(sentDate).tz(Config.DefaultTimezone);
    const end = Moment(lastDate).tz(Config.DefaultTimezone);
    const duration = Moment.duration(end.diff(start));
    return duration.humanize();
};

const getStatus = (process) => {
    const {processComplete, sentDate, failureDate} = process.toJS();
    let status = 'Running';
    if (getHoursElapsed(sentDate) > 12) {
        status = 'Stalled';
    }
    if (failureDate) {
        status = 'Failed';
    }
    if (processComplete) {
        status = 'Completed';
    }
    return status;
};

class Summary extends Component {
    static get propTypes() {
        return {
            process: PropTypes.instanceOf(Immutable.Map).isRequired,
            progress: PropTypes.instanceOf(Immutable.Map).isRequired,
            tasks: PropTypes.array.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.handleRedirectToMessage = this.handleRedirectToMessage.bind(this);
    }

    handleRedirectToMessage(messageId) {
        if (messageId) {
            RouterActions.redirect(`/hardac/processes/v2/${this.props.process.get('id')}/messages/${messageId}`);
        }
    }

    render() {
        const status = getStatus(this.props.process);
        const timeElapsed = getTimeElapsed(this.props.process);
        const processType = this.props.process.get('processType');
        const {tasksCompleted, tasksFailed, tasksRunning, progress} = this.props.progress.toJS();
        const totalCompleted = Object.keys(tasksCompleted).length;

        const tasks = this.props.tasks.map(message => {
            let id, icon, textColor;
            switch (false) {
            case !tasksCompleted[message.eventType.id]: //completed
                id = tasksCompleted[message.eventType.id].id;
                icon = 'fas fa-check';
                textColor = 'text-success';
                break;
            case !tasksFailed[message.eventType.id]: //failed
                id = tasksFailed[message.eventType.id].id;
                icon = 'fas fa-times';
                textColor = 'text-danger';
                break;
            case !tasksRunning[message.eventType.id]: //running
                icon = 'fas fa-ellipsis-h';
                textColor = 'text-primary';
                break;
            default:
                icon = 'fas fa-ellipsis-h'; //didn't start
                textColor = 'text-gray';
            }
            return {
                id,
                label: this.context.intl.messages[message.eventType.id],
                icon,
                textColor
            };
        });

        let processBarColor;
        switch (true) {
        case (status === 'Completed' && progress === 100): processBarColor = 'success'; break; // if all tasks were completed
        case (status === 'Failed'): processBarColor = 'danger'; break;
        default: processBarColor = 'primary'; break;
        }

        return (
            <div className="process-detail">
                <FormSection>
                    <div className="col-md-4">
                        <div className="info-box">
                            <span className="info-box-icon bg-default"><i className="far fa-battery-half"></i></span>
                            <div className="info-box-content">
                                <span className="info-box-text">{this.context.intl.messages['hardac.processes.status']}</span>
                                <h3 className="no-top-margin">{status}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="info-box">
                            <span className="info-box-icon bg-default"><i className="far fa-tasks"></i></span>
                            <div className="info-box-content">
                                <span className="info-box-text">{this.context.intl.messages['hardac.processes.task-complete']}</span>
                                <h3 className="no-top-margin">{totalCompleted}/{tasks.length}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="info-box">
                            <span className="info-box-icon bg-default"><i className="far fa-stopwatch"></i></span>
                            <div className="info-box-content">
                                <span className="info-box-text">{this.context.intl.messages['hardac.processes.time-elapsed']}</span>
                                <h3 className="no-top-margin">{timeElapsed}</h3>
                            </div>
                        </div>
                    </div>
                </FormSection>

                <hr/>

                <ProgressBar striped bsStyle={processBarColor} now={parseInt(progress)} label={`${parseInt(progress)}%`} />

                <h3>{this.context.intl.messages[`hardac.processes.status.${processType}`]}</h3>
                <ul className="thread-list">
                    <li>
                        <ul className="thread">
                            {tasks.map((task, index) => {
                                let externalLink;
                                if (task.id) {
                                    externalLink = <a onClick={this.handleRedirectToMessage.bind(this, task.id)}><small>&nbsp;<i className="far fa-external-link"></i></small></a>;
                                }
                                return (
                                    <li key={index}>
                                        <span className={task.textColor}><i className={task.icon}></i></span>&nbsp;{task.label}
                                        {externalLink}
                                    </li>
                                );
                            })}
                        </ul>
                    </li>
                </ul>
            </div>
        );
    }
}

export default Summary;
