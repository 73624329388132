/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {AssetCatalogActions} from './asset-catalog-actions';

import {NameDescriptionRenderer, ButtonCellRenderer} from '~/src/common/ag-grid/cell-renderers/index.js';
import AGGridWrapper from '~/src/common/ag-grid/index.js';

export class UnassignedAG extends Component {
    static get propTypes() {
        return {
            assignedCatalogs: PropTypes.instanceOf(Immutable.List).isRequired,
            catalogs: PropTypes.instanceOf(Immutable.List).isRequired,
            filters: PropTypes.string.isRequired,
            showEventCatalogs: PropTypes.bool
        };
    }

    static get defaultProps() {
        return {
            showEventCatalogs: false
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.filterCatalogs = this.filterCatalogs.bind(this);
        this.handleAddCatalog = this.handleAddCatalog.bind(this);
    }

    filterCatalogs(catalogs) {
        const filterRegExp = new RegExp(this.props.filters, 'i');

        return catalogs.filter(c => {
            let keep = !this.props.assignedCatalogs.find(assignedCatalog => c.get('id') === assignedCatalog.get('id'));

            if (keep && this.props.filters) {
                keep = c.get('name').match(filterRegExp);
            }

            if (keep) {
                if (this.props.showEventCatalogs) {
                    keep = c.get('name').toLowerCase().startsWith('event:');
                } else {
                    keep = !c.get('name').toLowerCase().startsWith('event:');
                }
            }

            return keep;
        });
    }

    handleAddCatalog(e) {
        const catalogId = e.id;
        const catalog = this.props.catalogs.find(c => c.get('id') === catalogId);
        AssetCatalogActions.add(catalog);
    }

    render() {
        const unassignedCatalogs = this.filterCatalogs(this.props.catalogs);

        const columnDefs = [
            {headerName: this.context.intl.messages['common.name-and-description'], field: 'name', cellRendererFramework: NameDescriptionRenderer, flex: 1},
            {headerName: this.context.intl.messages['common.type'], field: 'displayAssetType', width: 50, maxWidth: 150},
            {headerName: this.context.intl.messages['common.status'], field: 'catalogStatus', width: 100, suppressSizeToFit: true},
            {headerName: this.context.intl.messages['common.actions'], field: 'actions', sortable: false, width: 100, cellRendererFramework: ButtonCellRenderer, cellRendererParams: {onClick: this.handleAddCatalog}, suppressSizeToFit: true},
        ];

        const rowData = unassignedCatalogs.map((c) => {
            let catalogStatus = this.context.intl.messages['common.inactive'];
            if (c.get('isActive') === 1) {
                catalogStatus = this.context.intl.messages['common.active'];
            }

            return Object.assign({}, c.toJS(), {
                catalogStatus: catalogStatus,
            });
        }).toJS();

        return <AGGridWrapper
            columnDefs={columnDefs}
            paginationPageSize={10}
            rowData={rowData}
            rowHeight={55}
        />;
    }
}

export default UnassignedAG;
