/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import jQuery from 'jquery';
import PropTypes from 'prop-types';
import React from 'react';

require('../../../styles/data-tables-brainiac.css');
require('datatables.net-responsive-bs');

class TitleTable extends React.Component {

    static get propTypes() {
        return {
            titles: PropTypes.instanceOf(Immutable.List).isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        this.$table = jQuery('#mass-update-title-catalogs');
        let dataTableConfig = {
            autoWidth: false,
            columnDefs: [{
                orderable: false,
                targets: 'no-sort'
            }, {
                className: 'text-center',
                targets: 0
            }],
            iDisplayLength: 20,
            info: false,
            lengthChange: false,
            order: [[1, 'dec']],
            ordering: false,
            paging: false,
            pagingType: 'simple_numbers',
            responsive: false,
            searching: false,
            scrollX: true
        };

        this.$tableAPI = this.$table.DataTable(dataTableConfig);
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        return;
    }

    componentWillUpdate(nextProps/*, nextState */) {
        this.$tableAPI.clear();
        nextProps.titles.forEach((title, i) => {
            let catalogs = [];
            let firstCol = i + 1;
            let row = [];
            let status;

            if (title.get('errorLoading')) {
                status = `<i class="fas fa-exclamation-triangle text-orange" title="${this.context.intl.messages['catalogs.mass-update.error.loading.title.info']}"></i>`;
            } else if (title.get('titleCatalogs') && title.get('titleCatalogs').size) {
                catalogs = title.get('titleCatalogs').map(c => c.get('name'));
            }

            row.push(
                firstCol,
                title.get('titleId', ''),
                status || title.get('titleType', ''),
                status || title.get('titleName', ''),
                status || catalogs.join(', ')
            );

            this.$tableAPI.row.add(row);
            return;
        });

        this.$tableAPI.draw(false);
        this.handleResize();

        return;
    }

    handleResize() {
        this.$tableAPI.responsive.recalc();
        return;
    }

    render() {
        return (
            <div className="mass-update-title-catalogs-table">
                <table id="mass-update-title-catalogs" className="table table-bordered table-striped responsive">
                    <thead>
                        <tr>
                            <th className="no-arrow">{this.context.intl.messages['catalogs.mass-update.row-header.index']}</th>
                            <th className="no-arrow">{this.context.intl.messages['catalogs.mass-update.row-header.title-id']}</th>
                            <th className="no-arrow">{this.context.intl.messages['catalogs.mass-update.row-header.title-type']}</th>
                            <th className="no-arrow">{this.context.intl.messages['catalogs.mass-update.row-header.name']}</th>
                            <th className="no-arrow">{this.context.intl.messages['catalogs.mass-update.row-header.catalogs']}</th>
                        </tr>
                    </thead>
                    <tbody />
                </table>
            </div>
        );
    }
}

export default TitleTable;
