/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import createReactClass from 'create-react-class';
import Immutable from 'immutable';
import jQuery from 'jquery';
import PropTypes from 'prop-types';
import React from 'react';

import {GroupConstants} from '../security/group/group-actions';

export default createReactClass({
    propTypes: {
        displayLinks: PropTypes.bool,
        userGroups: PropTypes.instanceOf(Immutable.List).isRequired
    },

    contextTypes: {
        intl: PropTypes.object.isRequired,
        router: PropTypes.object.isRequired
    },

    getDefaultProps: function() {
        return {
            displayLinks: false,
            userGroups: Immutable.List()
        };
    },

    componentDidMount: function() {
        this.$table = jQuery('#list-groups-table');
        this.$tableAPI = this.$table.DataTable({
            autoWidth: false,
            columnDefs: [{
                // Add the control class to the last column. This colum will
                // contain the button to show the responsive data.
                className: 'control',
                targets:   -1,
                width: 20
            }, {
                targets: 'no-sort',
                orderable: false
            }, {
                className: 'no-padding text-center',
                targets: 0
            }],
            order: [[1, 'dec']],
            iDisplayLength: 100,
            info: false,
            ordering: false,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        });
        // Register global listeners.
        window.addEventListener('resize', this.handleResize);

        this.renderRows();
        return;
    },

    componentDidUpdate: function() {
        this.renderRows();

        return;
    },

    componentWillUnmount: function() {
        // check if undefinded only to enable render test
        if (this.$tableAPI) {
            this.$tableAPI.destroy();
        }
        return;
    },

    handleResize: function() {
        this.$tableAPI.responsive.recalc();
        return;
    },

    handleRowClick: function(event) {
        switch (true) {
        // Handle click on an user's name.
        case !!~event.target.className.indexOf('edit-group'):
            // Prevent the default anchor click event.
            event.preventDefault();
            this.context.router.push(event.target.getAttribute('href'));
            break;
        }

        return;
    },

    renderRows: function() {
        this.$tableAPI.clear();
        // Add data to the jQuery datatable.
        this.props.userGroups.forEach(group => {
            let row = [];
            // Name    Description

            let groupId = group.get('id');
            let groupName = group.get('name') || '';
            let description = group.get('description') || '';

            if (this.props.displayLinks) {
                if (group.get('groupCategoryType') === GroupConstants.STATION.categoryId) {
                    groupName = `<a href="/stations/${groupId}" class="edit-station">${groupName}</a>`;
                } else {
                    groupName = `<a href="/groups/user-groups/${groupId}" class="edit-group">${groupName}</a>`;
                }
            }

            row.push(
                groupName,
                description,
                ''
            );
            this.$tableAPI.row.add(row);

            return;
        });

        this.$tableAPI.draw(false);
        // Now, since the data has changed the columns widths, trigger
        // the resize handler in order to update the responsive feature.
        this.handleResize();

        return;
    },

    render: function() {
        return (
            <div className="tab-pane padding-x-20 padding-bottom-20">
                <table id="list-groups-table" className="table table-bordered table-striped responsive">
                    <thead>
                        <tr>
                            <th rowSpan="1" colSpan="1">Name</th>
                            <th rowSpan="1" colSpan="1">Description</th>
                            <th className="no-sort"></th>
                        </tr>
                    </thead>
                    <tbody onClick={this.handleRowClick}>
                    </tbody>
                </table>
            </div>
        );
    }
});
