/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Modal} from 'react-bootstrap';

import Assets from './assets';
import EventsTab from './events-tab';
import Notifications from './notifications';
import PresentationTab from './presentation/index';
import Summary from './summary';
import Titles from './titles/index';
import Users from './users';
import {EventActions, EventConstants} from '../event-actions';
import EventStore from '../event-store';

import RouteValidator from '~/src/common/custom-validator/route/route';
import DocumentTitle from '~/src/common/document-title';
import {Tab, Tabs} from '~/src/common/routing-tab/routing-tab';
import {History, SideNotes} from '~/src/common/side-notes/side-notes';
import {ErrorsWarnings} from '~/src/common/validations/errors-warnings';
import ActionsMenu from '~/src/layout/actions-menu/actions-menu';
import StickyHeader from '~/src/layout/sticky-header/sticky-header';
import Preloader from '~/src/preloader';
import {RouterActions} from '~/src/router/router-actions';
import {ActionHistoryConstants, ActionHistoryActions} from '~/src/system/action-history/action-history-actions';
import SessionStore from '~/src/user/session/session-store';

class Create extends React.Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            params: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired,
            route: RouteValidator,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            route: undefined
        };
    }

    static getPermissions() {
        return {
            canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.EVENTS.CREATE),
        };
    }

    static getStores() {
        return [EventStore];
    }

    static calculateState() {
        const eventState = EventStore.getState();
        return {
            event: eventState.get('event'),
            history: eventState.get('history'),
            lastEventEmailSent: EventStore.getState().get('lastEventEmailSent'),
            nominationInfo: eventState.get('nominationInfo'),
            originalEvent: eventState.get('originalEvent'),
            originalNominationInfo: eventState.get('originalNominationInfo'),
            originalTitleStyle: eventState.get('originalTitleStyle'),
            originalTitleStyleFiles: eventState.get('originalTitleStyleFiles'),
            showPreloader: eventState.get('showPreloader'),
            titleStyle: eventState.get('titleStyle'),
            titleStyleFiles: eventState.get('titleStyleFiles'),
            usersToDisplay: eventState.get('usersToDisplay'), // Prevents 'No Users' warning from occasionally appearing
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            showPreviewEmailsModal: false,
        }, this.constructor.calculateState());

        this.addNote = this.addNote.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleDuplicateEvent = this.handleDuplicateEvent.bind(this);
        this.handlePreviewEmails = this.handlePreviewEmails.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.isDirty = this.isDirty.bind(this);
        this.togglePreviewEmailsModal = this.togglePreviewEmailsModal.bind(this);
    }

    componentDidMount() {
        this.init(this.props);
        RouterActions.registerRedirectCheck((location) => {
            return this.isDirty(location);
        });

        return;
    }

    componentWillReceiveProps(nextProps) {
        RouterActions.registerRedirectCheck((location) => {
            return this.isDirty(location);
        });
        if (nextProps.params.id !== this.props.params.id) {
            this.init(nextProps);
        }
    }

    addNote(description) {
        ActionHistoryActions.addNote(this.props.params.id, description, ActionHistoryConstants.ACTION_OBJECTS.EVENT);
    }

    handleCancel() {
        RouterActions.redirect('/events', true);
    }

    handleDuplicateEvent() {
        RouterActions.redirect(`/events/${this.props.params.id}/duplicate`);
    }

    handlePreviewEmails() {
        this.togglePreviewEmailsModal();
        EventActions.sendPreviewEmails(this.props.params.id, SessionStore.getState().getIn(['authUser', 'email']));
    }

    handleSave() {
        EventActions.save(this.state.event, this.props.route.mode === 'duplicate');
    }

    init(props) {
        EventActions.clear();
        EventActions.getFYCAdminEvents();
        if (['edit', 'duplicate'].includes(props.route.mode)) {
            EventActions.getFilteredEventUsers(props.params.id, Immutable.fromJS({size: 20})); // BRAIN-4110 We need to load some users here so the event-store validation for 'No Users' works properly!
            EventActions.findById(props.params.id, props.route.mode === 'duplicate');
        }
    }

    isDirty(location) {
        if (location) {
            let goingTo = location.pathname.split('/');
            let current = this.props.location.pathname.split('/');
            if (goingTo[1] === current[1] && /* events */
                goingTo[2] === current[2] /* events id */) {
                return false; // just changing tabs no confirmation needed.
            }
        }

        if (['create', 'duplicate'].includes(this.props.route.mode)) {
            return false;
        }

        return !this.state.originalEvent.equals(this.state.event) ||
            !this.state.originalTitleStyle.equals(this.state.titleStyle) ||
            !this.state.originalTitleStyleFiles.equals(this.state.titleStyleFiles) ||
            !this.state.originalNominationInfo.equals(this.state.nominationInfo);
    }

    togglePreviewEmailsModal() {
        this.setState((prevState) => ({
            showPreviewEmailsModal: !prevState.showPreviewEmailsModal
        }));
    }

    render() {
        const mode = this.props.route.mode;

        let actionsMenu;
        let baseRoute;
        let disabled = true;
        let duplicateEventButton;
        let previewEmailsButton;
        let notificationsDisabled = true;
        let startEndDatesDisabled = true;

        let historyTab;
        let notificationsTab;
        let presentationTab;
        let eventsAndNotes;
        let saveText;

        if (['create', 'duplicate'].includes(mode)) {
            baseRoute = '/events/create';
            if (this.props.permissions.canEdit) {
                disabled = false;
                notificationsDisabled = false;
                startEndDatesDisabled = false;
            }
            saveText = this.context.intl.messages['common.save-and-create'];
        } else {
            baseRoute = `/events/${this.props.params.id}`;
            if (this.props.permissions.canEdit) {
                disabled = false;
                notificationsDisabled = false;
                startEndDatesDisabled = false;
                switch (this.state.event.get('eventStatusType')) {
                case EventConstants.STATUS_TYPES.EVENT_STARTING.id:
                    notificationsDisabled = true;
                    break;
                case EventConstants.STATUS_TYPES.EVENT_STOPPING.id:
                case EventConstants.STATUS_TYPES.MANUALLY_EXPIRING.id:
                    notificationsDisabled = true;
                    disabled = true;
                    startEndDatesDisabled = true;
                    break;
                case EventConstants.STATUS_TYPES.EVENT_ENDED.id:
                    notificationsDisabled = true;
                    disabled = true;
                    startEndDatesDisabled = !EventStore.isEventRestartable(this.state.originalEvent, -7);
                    break;
                }
            }

            if (this.state.event.get('eventType') !== EventConstants.EVENT_TYPES.FYC_ADMIN_EVENT.id) {
                const duplicateEventDisabled = (mode === 'create') || (!this.state.originalEvent.equals(this.state.event));
                duplicateEventButton = <Button bsSize="large" className="btn btn-primary-outline Mr(3px) Mb(3px)" disabled={duplicateEventDisabled} onClick={this.handleDuplicateEvent}>
                    <i className="fas fa-copy"></i>&nbsp;{this.context.intl.messages['events.edit.duplicate']}
                </Button>;
            }

            const previewEmailsButtonDisabled = (mode === 'create') || (!this.state.originalEvent.equals(this.state.event));
            previewEmailsButton = <Button bsSize="large" className="btn btn-primary-outline Mr(3px) Mb(3px)" disabled={previewEmailsButtonDisabled} onClick={this.togglePreviewEmailsModal}>
                <i className="fas fa-envelope"></i>&nbsp;{this.context.intl.messages['events.edit.preview-emails']}
            </Button>;

            //Tokenized anonymous users can't receive mails, so this is irrelevant for those users.
            if (this.state.event.get('accountType') !== EventConstants.ACCOUNT_TYPES.TOKENIZED_ANONYMOUS.id) {
                notificationsTab = (
                    <Tab route={`${baseRoute}/notifications`} title={this.context.intl.messages['events.notifications']}>
                        <Notifications
                            disabled={notificationsDisabled}
                            event={this.state.event}
                            lastEventEmailSent={this.state.lastEventEmailSent}
                            location={this.props.location}
                        />
                    </Tab>
                );
            }

            historyTab = <Tab route={`${baseRoute}/history`} title={this.context.intl.messages['common.tab-title.history']} tabClassName="pull-right">
                <History
                    actionObject={ActionHistoryConstants.ACTION_OBJECTS.EVENT}
                    id={this.props.params.id}
                    pagination
                    pathname={this.props.location.pathname}
                    query={this.props.location.query}/>
            </Tab>;

            eventsAndNotes = (
                <SideNotes
                    basePath={`/events/${this.props.params.id}`}
                    dialogMessage={this.context.intl.messages['common.side-notes.add-note.body']}
                    disabled={!this.props.permissions.canEdit}
                    notes={this.state.history.toJS().slice(0, 4)}
                    onAddNote={this.addNote}
                    title={this.context.intl.messages['common.side-notes.title']}
                />
            );

            saveText = this.context.intl.messages['common.save'];
        }

        let usersTab, assetsTab, titlesTab, eventsTab;
        if (this.props.route.mode !== 'duplicate') {
            if (this.props.route.mode !== 'create') {
                // BRAIN-3904 users/assets/titles tabs are only shown in edit mode, after create
                usersTab = (
                    <Tab route={`${baseRoute}/users`} title={this.context.intl.messages['events.users']}>
                        <Users
                            canSave={!EventStore.getValidations(true).length && !disabled}
                            eventId={this.props.params.id}
                            location={this.props.location}
                            mode={mode}
                            disabled={disabled}
                            onSave={this.handleSave}
                        />
                    </Tab>
                );

                if (this.state.event.get('eventType') !== EventConstants.EVENT_TYPES.FYC_ADMIN_EVENT.id) {
                    assetsTab = (
                        <Tab route={`${baseRoute}/assets`} title={this.context.intl.messages['events.assets']}>
                            <Assets eventId={this.props.params.id} location={this.props.location} disabled={disabled}/>
                        </Tab>
                    );

                    titlesTab = (
                        <Tab route={`${baseRoute}/titles`} title={this.context.intl.messages['events.titles']}>
                            <Titles eventId={this.props.params.id} location={this.props.location} disabled={disabled}/>
                        </Tab>
                    );
                }

                if (this.state.event.get('eventType') === EventConstants.EVENT_TYPES.FYC_ADMIN_EVENT.id) {
                    eventsTab = (
                        <Tab route={`${baseRoute}/events`} title={this.context.intl.messages['events.events-tab.title']}>
                            <EventsTab
                                location={this.props.location}
                            />
                        </Tab>
                    );
                }
            }

            if (this.state.event.get('eventType') === EventConstants.EVENT_TYPES.FYC_SCREENING.id) {
                presentationTab = (
                    <Tab route={`${baseRoute}/presentation`} title={this.context.intl.messages['events.presentation']}>
                        <PresentationTab
                            disabled={notificationsDisabled}
                            rowReorderDisabled={disabled}
                            event={this.state.event}
                            location={this.props.location}
                        />
                    </Tab>
                );
            }
        }

        let eventName = this.state.event.get('displayName') || this.state.event.get('name');
        if (this.props.route.mode === 'duplicate') {
            eventName = '';
        }

        actionsMenu = <ActionsMenu
            canEdit={!disabled}
            canSave={!EventStore.getValidations(true).length && !disabled}
            onCancel={this.handleCancel}
            onSave={this.handleSave}
            saveText={saveText}
        >
            {duplicateEventButton}
            {previewEmailsButton}
        </ActionsMenu>;

        const confirmSendPreviewEmailsModal = (
            <Modal backdrop="static" show={this.state.showPreviewEmailsModal} onHide={this.togglePreviewEmailsModal}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="text-center" >{this.context.intl.messages['events.edit.preview-emails.modal.send-emails']}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {this.context.intl.messages['events.edit.preview-emails.modal.body'].replace('{}', SessionStore.getState().getIn(['authUser', 'email']))}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button bsStyle="default" className="pull-left" onClick={this.togglePreviewEmailsModal}>
                        <i className="fas fa-ban" /> {this.context.intl.messages['common.cancel']}</Button>
                    <Button className="btn btn-primary-outline" onClick={this.handlePreviewEmails}>
                        <i className="far fa-envelope" /> {this.context.intl.messages['events.edit.preview-emails.modal.send-emails']}
                    </Button>
                </Modal.Footer>
            </Modal>
        );

        return <DocumentTitle
            message={`events.${mode}.title`}
        >
            <Preloader show={this.state.showPreloader} fixed loadingDots>
                <StickyHeader>
                    <div className="col-md-6 sticky-header-text">
                        <h1>
                            <i className="far fa-calendar-star"></i>&nbsp;{this.context.intl.messages[`events.${mode}.title`]}
                            &nbsp;
                            <small style={{display: 'block', paddingTop: 5}}>{eventName}</small>
                        </h1>
                    </div>
                    {actionsMenu}
                </StickyHeader>
                <section className="content" id="contentContainer">
                    <div className="row">
                        <div className="col-lg-9">
                            <Tabs location={this.props.location} title="title">
                                <Tab route={baseRoute} title={this.context.intl.messages['events.summary']}>
                                    <Summary
                                        mode={mode}
                                        disabled={disabled}
                                        startEndDatesDisabled={startEndDatesDisabled}
                                    />
                                </Tab>
                                {usersTab}
                                {assetsTab}
                                {titlesTab}
                                {eventsTab}
                                {notificationsTab}
                                {presentationTab}
                                {historyTab}
                            </Tabs>
                        </div>
                        <div className="col-lg-3">
                            <ErrorsWarnings
                                title={this.context.intl.messages['common.errors-warnings-title']}
                                validations={EventStore.getValidations(false)}
                            />
                            {eventsAndNotes}
                        </div>
                    </div>
                </section>
                {confirmSendPreviewEmailsModal}
            </Preloader>
        </DocumentTitle>;
    }
}

export {Create};
export default Container.create(Create);
