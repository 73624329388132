/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import AssignedTable from './assigned';
import UnassignedTable from './unassigned';
import {FormRow, FormSection} from '../../../common/form/form';
import SearchBox from '../../../common/search-box/search-box';
import {Debounce} from '../../../common/utils/utils';
import {BatchActions} from '../../batch-actions';
import BatchStore from '../../batch-store';

class Catalogs extends Component {

    static get propTypes() {
        return {
            assignedCatalogs: PropTypes.instanceOf(Immutable.List).isRequired,
            disabled: PropTypes.bool,
            type: PropTypes.string.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
            router: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            catalogs: BatchStore.getState().get('catalogs'),
            overwriteCatalog: BatchStore.getState().getIn(['batch', 'overwriteCatalog'])
        };
    }

    static getStores() {
        return [BatchStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            filters: ''
        }, this.constructor.calculateState());

        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
        this.handleOverwriteCatalog = this.handleOverwriteCatalog.bind(this);
    }

    componentDidMount() {
        BatchActions.getCatalogs(this.props.type);
    }

    handleOverwriteCatalog(value) {
        this.setState(() => ({
            overwriteCatalog: value
        }));
        BatchActions.update('overwriteCatalog', value);
    }

    handleSearchTerm(term) {
        this.setState({
            'filters': term
        });
        return;
    }

    render() {
        return (
            <div>
                <h3><i className="fas fa-sliders-v-square"></i>&nbsp;{this.context.intl.messages['titles.edit.catalogs-tab.catalog-settings']}</h3>
                <div>
                    <FormSection>
                        <FormRow>
                            <div className="radio">
                                <label className="radio-inline">
                                    <input
                                        checked={this.state.overwriteCatalog}
                                        disabled={this.props.disabled}
                                        id="overwrite"
                                        name="overwrite"
                                        onClick={this.handleOverwriteCatalog.bind(this, true)}
                                        type="radio"
                                    />
                                    <strong>{this.context.intl.messages['titles.edit.catalogs-tab.catalog-settings.overwrite']}</strong>
                                </label>
                                <p><small><em>{this.context.intl.messages['titles.edit.catalogs-tab.catalog-settings.overwrite.label']}</em></small></p>
                            </div>
                        </FormRow>
                        <hr/>
                        <FormRow>
                            <div className="radio">
                                <label className="radio-inline">
                                    <input
                                        checked={!this.state.overwriteCatalog}
                                        disabled={this.props.disabled}
                                        id="add"
                                        name="add"
                                        onClick={this.handleOverwriteCatalog.bind(this, false)}
                                        type="radio"
                                    />
                                    <strong>{this.context.intl.messages['titles.edit.catalogs-tab.catalog-settings.add']}</strong>
                                </label>
                                <p><small><em>{this.context.intl.messages['titles.edit.catalogs-tab.catalog-settings.add.label']}</em></small></p>
                            </div>
                        </FormRow>
                    </FormSection>
                    <hr/>
                </div>
                <h3><i className="fas fa-book"></i>&nbsp;{this.context.intl.messages['titles.edit.catalogs-tab-title']}</h3>
                <div className="tab-pane">
                    <hr/>
                    <h4>{this.context.intl.messages['titles.edit.catalogs-tab.assigned-title']}</h4>
                    <FormSection>
                        <AssignedTable
                            assignedCatalogs={this.props.assignedCatalogs}
                            catalogs={this.state.catalogs}
                            disabled={this.props.disabled}
                        />
                    </FormSection>
                    <hr/>
                    <h4>{this.context.intl.messages['titles.edit.catalogs-tab.unassigned-title']}</h4>
                    <FormSection>
                        <div className="container-fluid no-x-padding">
                            <div className="row">
                                <div className="col-md-3"></div>
                                <div className="col-md-6 show-grid">
                                    <SearchBox value={this.state.searchTerm} onChange={this.handleSearchTerm}/>
                                </div>
                                <div className="col-md-3"></div>
                            </div>
                        </div>
                        <UnassignedTable
                            assignedCatalogs={this.props.assignedCatalogs}
                            catalogs={this.state.catalogs}
                            disabled={this.props.disabled}
                            filters={this.state.filters}
                        />
                    </FormSection>
                </div>
            </div>
        );
    }

}

export default Container.create(Catalogs);
