/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';

import Dispatcher from '~/src/dispatcher/dispatcher';

class MachineLearningStore extends ReduceStore<
    MachineLearningState,
    DispatchAction
> {
    getInitialState() {
        return {
            loading: false,
            error: false,
            data: {
                results: [],
                totalCount: 0,
            },
        };
    }

    reduce(state: MachineLearningState, action: MachineLearningGetAction) {
        switch (action.actionType) {
        case 'machine-learning.project.get.start':
            state = {
                ...state,
                loading: true,
                error: false,
            };
            break;
        case 'machine-learning.project.get.error':
            state = {
                ...state,
                loading: false,
                error: true,
            };
            break;
        case 'machine-learning.project.get.success':
            state = {
                ...state,
                data: {
                    results: [...action.payload.results],
                    totalCount: action.payload.totalCount,
                },
                loading: false,
                error: false,
            };
            break;
        }
        return state;
    }
}

export default new MachineLearningStore(Dispatcher);
