/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import jQuery from 'jquery';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {Button} from 'react-bootstrap';

import FilterOptions from './filter-options';
import {GuildActions} from './guild-actions';
import GuildStore from './guild-store';
import DocumentTitle from '../common/document-title';
import SearchBox from '../common/search-box/search-box';
import {WithStoreOnRoute} from '../common/store-on-route';
import Pagination from '../common/table/pagination';
import {Debounce} from '../common/utils/utils';
import {LayoutActions} from '../layout/layout-actions';
import LayoutStore from '../layout/layout-store';
import Preloader from '../preloader';
import {RouterActions} from '../router/router-actions';
import SessionStore from '../user/session/session-store';

require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('../styles/data-tables-brainiac.css');
require('datatables.net-responsive-bs');

class Table extends React.Component {
    static get propTypes() {
        return {
            activePage: PropTypes.number.isRequired,
            canReadGuilds: PropTypes.bool.isRequired,
            guilds: PropTypes.instanceOf(Immutable.List).isRequired,
            guildCount: PropTypes.func.isRequired,
            onPageChange: PropTypes.func.isRequired,
            totalPages: PropTypes.number.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
        };
    }

    constructor(props) {
        super(props);

        this.handleResize = this.handleResize.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
    }

    componentDidMount() {
        this.$table = jQuery('#list-guilds-table');
        let dataTableConfig = {
            autoWidth: false,
            order: [[1, 'dec']],
            iDisplayLength: 100,
            info: false,
            ordering: false,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        };
        this.$tableAPI = this.$table.DataTable(dataTableConfig);
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    }

    componentWillUpdate(nextProps) {
        this.$tableAPI.clear();

        this.props.guildCount();

        nextProps.guilds.forEach((guild) => {
            let row = [];
            let id = guild.get('id');
            let guildName = guild.get('name');

            if (nextProps.canReadGuilds) {
                guildName = `<a href="/guild/${id}" class="guild">${guildName}</a>`;
            }

            const guildUsers = guild.get('userCount') || this.context.intl.messages['common.na'];

            row.push(
                guildName,
                guildUsers,
                Moment(guild.get('validStartDate')).format(this.context.intl.messages['date-format']),
                Moment(guild.get('validEndDate')).format(this.context.intl.messages['date-format']),
                '',
            );

            this.$tableAPI.row.add(row);

            return;
        });

        this.$tableAPI.draw(false);

        this.handleResize();
    }

    componentWillUnmount() {
        if (this.$tableAPI) {
            this.$tableAPI.destroy();
        }

        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        this.$tableAPI.responsive.recalc();
    }

    handleRowClick(event) {
        switch (true) {
        case !!~event.target.className.indexOf('guild'):
            event.preventDefault();
            RouterActions.redirect(event.target.getAttribute('href'));
            break;
        }
    }

    render() {
        let pagination;
        if (this.props.onPageChange) {
            pagination = (
                <div className="row">
                    <div className="col-sm-7 col-sm-offset-5">
                        <Pagination
                            activePage={this.props.activePage}
                            onChange={this.props.onPageChange}
                            totalPages={this.props.totalPages}
                        />
                    </div>
                </div>
            );

        }

        return (
            <div>
                <table id="list-guilds-table" className="table table-bordered table-striped responsive">
                    <thead>
                        <tr>
                            <th rowSpan="1" colSpan="1">Guild Name</th>
                            <th rowSpan="1" colSpan="1">Users</th>
                            <th rowSpan="1" colSpan="1">Start Date</th>
                            <th rowSpan="1" colSpan="1">End Date</th>
                        </tr>
                    </thead>
                    <tbody onClick={this.handleRowClick}>
                    </tbody>
                </table>
                {pagination}
            </div>
        );
    }
}

class Browse extends React.Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static getPermissions() {
        return {
            canReadGuilds: SessionStore.canUser(SessionStore.PERMISSIONS.GUILDS.READ),
        };
    }

    static calculateState() {
        return {
            showFiltersPanel: LayoutStore.getState().get('showFiltersPanel'),
            showPreloader: GuildStore.getState().get('showPreloader'),
            guilds: GuildStore.getState().get('guilds'),
            size: GuildStore.getState().get('size'),
            total: GuildStore.getState().get('total')
        };
    }

    static getStores() {
        return [GuildStore, LayoutStore];
    }

    constructor(props) {
        super(props);

        this.getGuildCount = this.getGuildCount.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 300);
        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleToggleFiltersPanel = this.handleToggleFiltersPanel.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    componentWillUpdate(nextProps) {
        if (this.props.location.query !== nextProps.location.query) {
            this.refresh(nextProps);
        }
        return;
    }

    getGuildCount() {
        this.refs.guildCount.innerHTML = this.state.total;
    }

    handleSearchTerm(term) {
        this.setRouteState('name', term).clearRouteState('offset').apply();
    }

    handleLoadPage(pageNumber) {
        this.setRouteState('offset', pageNumber * this.state.size).apply();
    }

    handleToggleFiltersPanel() {
        LayoutActions.toggleFiltersPanel();
    }

    refresh(props) {
        const routeState = this.getRouteState(props || this.props);

        GuildActions.get(
            routeState.get('offset', 0),
            this.state.size,
            routeState.get('name', ''),
            !!routeState.get('show-inactive'),
            routeState.get('start-date'),
            routeState.get('end-date'),
        );
    }

    render() {
        let activePage = Math.ceil((this.getRouteState().get('offset') || 0) / this.state.size ) || 0;
        let totalPages = Math.ceil(this.state.total / this.state.size) || 0;

        return (<DocumentTitle
            message="guild.browse.title"
        >
            <div className={ClassNames({'control-sidebar-open': this.state.showFiltersPanel})}>
                <div className={ClassNames({'sidebar-opened': this.state.showFiltersPanel})}>
                    <Preloader show={this.state.showPreloader} fixed loadingDots>
                        <section className="content-header">
                            <h1>
                                <i className="fas fa-shield-alt"></i>&nbsp;{this.context.intl.messages['guild.browse.title']}
                                &nbsp;<small ref="guildCount"></small>
                            </h1>
                        </section>
                        <section className="content">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="container-fluid no-x-padding">
                                        <div className="row">
                                            <div className="col-md-6 show-grid">
                                                <SearchBox onChange={this.handleSearchTerm} value={this.getRouteState().get('name')}/>
                                            </div>
                                            <div className="col-md-6 show-grid">
                                                <Button onClick={this.handleToggleFiltersPanel} bsStyle="primary" bsSize="large" className="pull-right">
                                                    <i className="fas fa-sliders-h"></i>&nbsp;{this.context.intl.messages['common.filteringOptions']}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container-fluid no-x-padding">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="box">
                                                    <div className="box-body padding-x-20 padding-y-20" >
                                                        <Table
                                                            activePage={activePage}
                                                            canReadGuilds={this.props.permissions.canReadGuilds}
                                                            filter={this.getRouteState().get('name')}
                                                            guilds={this.state.guilds}
                                                            guildCount={this.getGuildCount}
                                                            onPageChange={this.handleLoadPage}
                                                            totalPages={totalPages}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <FilterOptions
                            location={this.props.location}
                        />
                    </Preloader>
                </div>
            </div>
        </DocumentTitle>);
    }
}

export default WithStoreOnRoute(Container.create(Browse));

export {
    Table
};
