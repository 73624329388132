/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {HistoryConstants} from './history-actions';
import Dispatcher from '../../dispatcher/dispatcher';

class HistoryStore extends ReduceStore {
    getInitialState() {
        return Immutable.fromJS({
            history: {
                results: [],
                totalCount: 0
            }
        });
    }

    reduce(state, action) {
        switch (action.actionType) {
        case HistoryConstants.CLEAR:
            return this.getInitialState();

        case HistoryConstants.GET.SUCCESS:
            return state = state.set('history', action.history);

        default:
            return state;
        }
    }
}

export default new HistoryStore(Dispatcher);
