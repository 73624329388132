/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';
import {FormattedMessage} from 'react-intl';

import ShiftCaptionsModal from './shift-captions-modal';
import {ShowShiftCaptionsModal, IsMasteringVttFile$} from '../../bl';
import TitledBlock from '../common/titled-block';

import WithRxSubscriptions from '~/src/decorators/with-rx-subscriptions';

type Props = {
    isMasteringVttFile: boolean,
}

function ShiftCaptionsBlock(props: Props) {
    return (
        <div>
            <TitledBlock title="cc-editor.tab.settings.global" icon="fas fa-cogs">
                <p>
                    <FormattedMessage id="cc-editor.tab.settings.shift-captions" />
                </p>

                <button
                    className="btn btn-primary-outline"
                    disabled={props.isMasteringVttFile}
                    onClick={ShowShiftCaptionsModal}
                >
                    <i className="fas fa-hourglass-start"/>
                    &nbsp;
                    <FormattedMessage id="cc-editor.tab.settings.shift-captions-start" />
                </button>
            </TitledBlock>

            <ShiftCaptionsModal />
        </div>
    );
}

export default WithRxSubscriptions(ShiftCaptionsBlock, {
    isMasteringVttFile: IsMasteringVttFile$,
});
