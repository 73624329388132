/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import AssignedTable from './assigned';
import UnassignedTable from './unassigned';
import {FormSection} from '../../../common/form/form';
import SearchBox from '../../../common/search-box/search-box';
import {Debounce} from '../../../common/utils/utils';
import {PartnerActions} from '../../../partner/partner-actions';
import PartnerStore from '../../../partner/partner-store';

import {WithStoreOnRoute} from '~/src/common/store-on-route';

class UserGroups extends Component {

    static get propTypes() {
        return {
            assignedUserGroups: PropTypes.instanceOf(Immutable.List).isRequired,
            groupType: PropTypes.oneOf(['user-groups', 'stations']),
            location: PropTypes.object.isRequired,
            onAdd: PropTypes.func.isRequired,
            onRemove: PropTypes.func.isRequired,
        };
    }

    static get defaultProps() {
        return {
            groupType: 'user-groups'
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            stations: PartnerStore.getState().get('stations'),
            userGroups: PartnerStore.getState().get('userGroups'),
            totalStations: PartnerStore.getState().get('totalStations'),
            totalUsers: PartnerStore.getState().get('totalUsers'),
        };
    }

    static getStores() {
        return [PartnerStore];
    }

    constructor(props) {
        super(props);

        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
    }

    componentDidMount() {
        if (this.state.stations.size === 0) {
            PartnerActions.getStations(this.getRouteState());
        }

        if (this.state.userGroups.size === 0) {
            PartnerActions.getUserGroups(this.getRouteState());
        }

        return;
    }

    componentWillUpdate(nextProps) {
        if (this.props.location !== nextProps.location) {
            if (this.props.location.pathname.includes('user-groups')) {
                PartnerActions.getUserGroups(this.getRouteState(nextProps));
            }
            if (this.props.location.pathname.includes('stations') || this.props.location.pathname.includes('station-groups')) {
                PartnerActions.getStations(this.getRouteState(nextProps));
            }
        }
        return;
    }

    handleLoadPage(pageNumber) {
        this.setRouteState('offset', pageNumber * 20)
            .apply();
        return;
    }

    handleSearchTerm(term) {
        this.setRouteState('name', term)
            .setRouteState('operator', 'AND')
            .clearRouteState('offset')
            .apply();
        return;
    }

    render() {
        let icon = 'fas fa-users';
        let unassignedGroups = this.state.userGroups;
        let totalGroups = this.state.totalUsers;
        if (this.props.groupType === 'stations') {
            unassignedGroups = this.state.stations;
            icon = 'fas fa-tv-retro';
            totalGroups = this.state.totalStations;
        }

        return (
            <div>
                <h3><i className={icon}></i>&nbsp;{this.context.intl.messages[`authorized-partners.edit.${this.props.groupType}`]}</h3>
                <div className="tab-pane">
                    <hr/>
                    <h4>{this.context.intl.messages[`authorized-partners.edit.${this.props.groupType}.assigned`]}</h4>
                    <FormSection>
                        <AssignedTable
                            assignedUserGroups={this.props.assignedUserGroups}
                            onRemove={this.props.onRemove}
                            groupType={this.props.groupType}
                        />
                    </FormSection>
                    <hr/>
                    <h4>{this.context.intl.messages[`authorized-partners.edit.${this.props.groupType}.unassigned`]}</h4>
                    <FormSection>
                        <div className="container-fluid no-x-padding">
                            <div className="row">
                                <div className="col-md-3"></div>
                                <div className="col-md-6 show-grid">
                                    <SearchBox value={this.getRouteState().get('name')} onChange={this.handleSearchTerm} onAdd={this.props.onAdd} />
                                </div>
                                <div className="col-md-3"></div>
                            </div>
                        </div>
                        <UnassignedTable
                            activePage={Math.ceil((this.getRouteState().get('offset') || 0) / 20 ) || 0}
                            assignedUserGroups={this.props.assignedUserGroups}
                            onAdd={this.props.onAdd}
                            onPageChange={this.handleLoadPage}
                            userGroups={unassignedGroups}
                            totalPages={Math.ceil(totalGroups/20) || 0}
                        />
                    </FormSection>
                </div>
            </div>
        );
    }
}

export default WithStoreOnRoute(
    Container.create(UserGroups)
);
