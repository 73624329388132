/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {IsProcessingStatusType} from './typeguards';

export const VIDEO_INSIGHT_TYPES = {
    VI: 'vi',
    CV: 'cv',
} as const;

export const VIDEO_INSIGHT_STATUS_TYPES = {
    PROCESSING_INDEX: 'processing_index',
    SUCCEEDED: 'succeeded',
    FAILED: 'failed',
    PROCESSING_TRANSCRIPT: 'processing_transcript',
    CREATING: 'creating',
    SCORING_MODEL: 'scoring_model',
} as const;

export const VIDEO_INSIGHT_PROCESSING_STATUS_TYPES: VideoInsightStatusType$Processing[] =
    Object.values(VIDEO_INSIGHT_STATUS_TYPES).filter(IsProcessingStatusType);

export const CATEGORY_TYPES = {
    SHOT_TYPE: 'shot_type',
    SETTING: 'setting',
    CLOTHING: 'clothing',
    WEATHER: 'weather',
    WEATHER_PHENOMENA: 'weather_phenomena',
    VI: 'vi',
    CONTENT_AI_AWS_REKOGNITION: 'aws_rekognition_video_celebs',
    CONTENT_AI_ATT_DEFAULT: 'default_att',
    BRAINIAC_WORKFLOW_START: 'brainiac_workflow_start',
} as const;
