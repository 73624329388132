/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import ReactTable from 'react-table';

import SearchBox from '~/src/common/search-box/search-box';
import Pagination from '~/src/common/table/pagination';
import {Debounce} from '~/src/common/utils/utils';

import 'react-table/react-table.css';

class AssignedTable extends Component {
    static get propTypes() {
        return {
            assignedUserGroups: PropTypes.instanceOf(Immutable.List).isRequired, // eslint-disable-line react/no-unused-prop-types
            groupType: PropTypes.oneOf(['user-groups', 'stations']),
            onRemove: PropTypes.func.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            groupType: 'user-groups'
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            offset: 0,
            pageSize: 20,
            name: ''
        };
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
        this.handleRemoveUserGroup = this.handleRemoveUserGroup.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
    }

    handleRemoveUserGroup(event) {
        switch (true) {
        case !!~event.target.className.indexOf('remove-user-group'):
            this.props.onRemove(parseInt(event.target.getAttribute('data-index')));
            break;
        case !!~event.target.className.indexOf('fa-times'):
            this.props.onRemove(parseInt(event.target.parentNode.getAttribute('data-index')));
            break;
        }
        return;
    }

    handlePageSizeChange(pageNumber) {
        this.setState((prevState) => ({
            offset: pageNumber * prevState.pageSize,
        }));
    }

    handleSearchTerm(term) {
        this.setState((prevState)=> {
            let offset = prevState.offset;
            if (term === '') {
                offset = 0;
            }
            return {
                name: term,
                offset
            };
        });
        return;
    }

    render() {
        const columns = [{
            Header: <strong className="sorting_desc">{this.context.intl.messages['authorized-partners.edit.user-groups.table.name']}</strong>,
            maxWidth: 225,
            Cell: c => {
                let userGroupName = c.original.get('name') || '-';
                return <div>{userGroupName}</div>;
            }
        }, {
            Header: <strong>{this.context.intl.messages['authorized-partners.edit.user-groups.table.description']}</strong>,
            Cell: c => {
                const description = c.original.get('description') || '-';
                return <div>{description}</div>;
            }
        }, {
            Header: <strong>{this.context.intl.messages['authorized-partners.edit.user-groups.table.status']}</strong>,
            maxWidth: 95,
            Cell: c => {
                let userGroupStatus = this.context.intl.messages['common.inactive'];
                if (c.original.get('isActive') === 1) {
                    userGroupStatus = this.context.intl.messages['common.active'];
                }
                return <div>{userGroupStatus}</div>;
            }
        }, {
            Header: <strong>{this.context.intl.messages['authorized-partners.edit.user-groups.table.actions']}</strong>,
            maxWidth: 100,
            Cell: c => {
                return (
                    <button className="btn btn-small bg-navy remove-user-group" data-user-group-id={c.original.get('id')} data-index={c.index} onClick={this.handleRemoveUserGroup}>
                        <i className="fas fa-times"></i>&nbsp;{this.context.intl.messages['common.remove']}
                    </button>
                );
            }
        }];

        const filterRegExp = new RegExp(this.state.name.toLowerCase(), 'i');
        let assignedUserGroups = this.props.assignedUserGroups.filter(userGroup => userGroup.get('name').toLowerCase().match(filterRegExp));
        let totalPages = 1;
        let activePage = 0;
        if (assignedUserGroups.size > this.state.pageSize) {
            assignedUserGroups = assignedUserGroups.slice(this.state.offset, this.state.offset + this.state.pageSize);
            totalPages = Math.ceil(this.props.assignedUserGroups.size/this.state.pageSize);
            activePage = Math.ceil((this.state.offset || 0) / this.state.pageSize ) || 0;
        }
        const pagination = (
            <div className="row">
                <div className="col-sm-7 col-sm-offset-5">
                    <Pagination
                        activePage={activePage}
                        onChange={this.handlePageSizeChange}
                        totalPages={totalPages}
                    />
                </div>
            </div>
        );
        const searchbox = (
            <div className="container-fluid no-x-padding">
                <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-6 show-grid">
                        <SearchBox value={this.state.name} onChange={this.handleSearchTerm} />
                    </div>
                    <div className="col-md-3"></div>
                </div>
            </div>
        );

        return (
            <div>
                {searchbox}
                <ReactTable
                    className="-striped table table-bordered table-striped responsive"
                    columns={columns}
                    data={assignedUserGroups}
                    getNoDataProps= {() => {
                        if (this.props.assignedUserGroups.size) {
                            return {style: {display: 'none'}};
                        }
                        return {};
                    }}
                    id="assigned-user-groups-table"
                    loading={false}
                    pageSize={this.state.pageSize}
                    showPagination={false}
                    sortable={false}
                    resizable={false}
                />
                {pagination}
            </div>
        );
    }
}

export default AssignedTable;
