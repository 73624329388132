/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {AssetTitleConstants} from './asset-title-actions';
import {ImageConstants} from './image/image-actions';
import Dispatcher from '../dispatcher/dispatcher';

class AssetTitleStore extends ReduceStore {

    getInitialState() {
        return Immutable.Map({
            titles: Immutable.List(),
            originalTitles: Immutable.List(),
            lastOrder:0
        });
    }

    reduce(state, action) {
        switch (action.actionType) {
        case AssetTitleConstants.CLEAR:
            state = this.getInitialState();
            break;

        case AssetTitleConstants.GET.SUCCESS:
            state = state.merge({
                titles: action.titles,
                originalTitles: action.titles,
                lastOrder: action.lastOrder
            });
            break;
        case AssetTitleConstants.TOOGLE_DEFAULT:
            state = state.update('titles', (titles) => {
                titles = titles.map( title => {
                    if (title.get('id') === action.titleId) {
                        let selected = `default${action.orientation}`;
                        let val = title.getIn(['asset', selected]);
                        let asset = {
                            defaultHorizontal: false,
                            defaultPortrait: false,
                            defaultBanner: false
                        };
                        asset[selected] = !val;
                        title = title.set('asset', Immutable.fromJS(asset));
                    }
                    return title;
                });
                return titles;
            });
            break;
        case ImageConstants.IMAGE.SAVE.SUCCESS:
            state = state.merge({
                originalTitles: state.get('titles')
            });
            break;
        }
        return state;
    }
}

let store = new AssetTitleStore(Dispatcher);

export default store;
