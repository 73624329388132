/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {ControlLabel, FormControl, FormGroup} from 'react-bootstrap';
import Select from 'react-select';

import StartEndDates from './start-end-dates';
import {EventActions, EventConstants} from '../event-actions.js';
import EventStore from '../event-store';

import {FormItem, FormSection, FormRow} from '~/src/common/form/form';
import {CompareImmutable} from '~/src/common/utils/utils';
import config from '~/src/config/config.js';
import BrandSelect from '~/src/lookup/brand-select/brand-select';
import {BrandSelectActions} from '~/src/lookup/brand-select/brand-select-actions';
import BrandStore from '~/src/lookup/brand-select/brand-select-store';
import PartnerSelect from '~/src/partner/partner-select';
import PartnerStore from '~/src/partner/partner-store';
import {PermissionPackageActions, PermissionPackageConstants} from '~/src/security/permission-package/permission-package-actions';
import PermissionPackageStore from '~/src/security/permission-package/permission-package-store';
import RoleSelect from '~/src/security/role/role-select';

class Summary extends React.Component {

    static get propTypes() {
        return {
            disabled: PropTypes.bool,
            mode: PropTypes.oneOf(['create', 'edit', 'duplicate']).isRequired,
            startEndDatesDisabled: PropTypes.bool
        };
    }

    static get defaultProps() {
        return {
            disabled: true,
            startEndDatesDisabled: true
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static getStores() {
        return [EventStore, PartnerStore, PermissionPackageStore];
    }

    static calculateState() {
        const eventState = EventStore.getState();
        return {
            activeAdminEvents: eventState.get('activeAdminEvents'),
            event: eventState.get('event'),
            originalEvent: eventState.get('originalEvent'),
            partners: PartnerStore.getState().get('partners'),
            permissionPackages: PermissionPackageStore.getState().get('permissionPackages'),
            selectedPermissionPackages: eventState.get('selectedPermissionPackages')
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({}, this.constructor.calculateState());

        this.handleChangeDateDay = this.handleChangeDateDay.bind(this);
        this.handleChangeDateTime = this.handleChangeDateTime.bind(this);
        this.handleLinkToFYCAdminEvent = this.handleLinkToFYCAdminEvent.bind(this);
        this.handleSelectAccountType = this.handleSelectAccountType.bind(this);
        this.handleSelectBrand = this.handleSelectBrand.bind(this);
        this.handleSelectCity = this.handleSelectCity.bind(this);
        this.handleSelectEventType = this.handleSelectEventType.bind(this);
        this.handleSelectPartner = this.handleSelectPartner.bind(this);
        this.handleSelectPermissionPackages = this.handleSelectPermissionPackages.bind(this);
        this.handleSelectRole = this.handleSelectRole.bind(this);
    }

    componentDidMount() {
        EventActions.get(Immutable.Map(), 0, 20);
        BrandSelectActions.get(0, 9999);
        PermissionPackageActions.get(Immutable.fromJS({active: PermissionPackageConstants.FILTERS.ACTIVE_OPTIONS.ACTIVE.id}), 0, 99999);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!CompareImmutable(nextState.event, this.state.event)) {
            return true;
        }
        return false;
    }

    cancelDateFunction(value, attr) {
        /*To if its cancel, clear date*/
        if (!value) {
            EventActions.updateEvent(attr, value);
        }
    }

    handleChangeDateDay(attr, day) {
        const utcDate = EventActions.getChangeDateDayUtc(attr, day, this.state.event.get('displayTimeZone'));

        EventActions.updateEvent(attr, utcDate);
    }

    handleChangeDateTime(attr, hour) {
        const utcTime = EventActions.getChangeDateTimeUtc(this.state.event.get(attr), hour, this.state.event.get('displayTimeZone'));

        EventActions.updateEvent(attr, utcTime);
    }

    handleLinkToFYCAdminEvent(attr, value) {
        // Update event state with rootEventId and linkToFYCAdminEvent value
        EventActions.updateEvent('linkToFYCAdminEvent', value);

        let rootEventId = null;
        if (value && !!this.state.activeAdminEvents.size) {
            rootEventId = this.state.activeAdminEvents.getIn([0, 'eventId']);
        }

        EventActions.updateEvent('rootEventId', rootEventId);
    }

    handleSelectAccountType(accountType) {
        if (!accountType) {
            EventActions.updateEvent('accountType', null);
            return;
        }

        if (accountType.id === EventConstants.ACCOUNT_TYPES.TOKENIZED_ANONYMOUS.id) {
            const selectedRole = Immutable.fromJS({
                id: 22006,
                name: 'Limited Series Viewer' // BRAIN-3463 default to this role for tokenized anonymous events
            });

            EventActions.updateEvent('selectedRole', selectedRole);
        }
        EventActions.updateEvent('accountType', accountType.id);
    }

    handleSelectBrand(brand) {
        if (!brand) {
            EventActions.updateEvent('brand', null);
            return;
        }

        EventActions.updateEvent('brand', Immutable.fromJS(brand));
    }

    handleSelectCity(timeZone) {
        if (this.state.event.get('startScheduleDate')) {
            EventActions.updateEvent('startScheduleDate', EventActions.getScheduleDateUtc(this.state.event.get('startScheduleDate'), timeZone));
        }

        if (this.state.event.get('endScheduleDate')) {
            EventActions.updateEvent('endScheduleDate', EventActions.getScheduleDateUtc(this.state.event.get('endScheduleDate'), timeZone));
        }
        EventActions.updateEvent('displayTimeZone', timeZone);
    }

    handleSelectEventType(eventType) {
        if (!eventType) {
            EventActions.updateEvent('eventType', null);
            return;
        }
        EventActions.updateEvent('eventType', eventType.id);

        let selectedRole = Immutable.fromJS({
            id: 22006,
            name: 'Limited Series Viewer' // BRAIN-3991 default to this role
        });

        const isBaftaEvent = eventType.id === EventConstants.EVENT_TYPES.BAFTA_EVENT.id;
        const isFYCScreening = eventType.id === EventConstants.EVENT_TYPES.FYC_SCREENING.id;
        const isFYCAdminEvent = eventType.id === EventConstants.EVENT_TYPES.FYC_ADMIN_EVENT.id;
        if (isFYCScreening || isFYCAdminEvent || isBaftaEvent) {
            EventActions.updateEvent('accountType', EventConstants.ACCOUNT_TYPES.TOKENIZED_ANONYMOUS.id);
            selectedRole = Immutable.fromJS({
                id: 22012,
                name: 'Base FYC User' // BRAIN-3991 default to this role for FYC Screening events
            });
        }


        if (this.state.activeAdminEvents.size) {
            this.handleLinkToFYCAdminEvent('linkToFYCAdminEvent', isFYCScreening);
        }

        EventActions.updateEvent('selectedRole', selectedRole);
    }

    handleSelectPartner(partner) {
        if (!partner) {
            EventActions.setPartners(Immutable.OrderedSet());
            return;
        }
        EventActions.setPartners(Immutable.fromJS([partner]).toSet().sortBy(p => p.name).map(p => {
            return {
                partnerId: p.get('id')
            };
        }));
    }

    handleSelectPermissionPackages(permissionPackages) {
        if (!permissionPackages) {
            EventActions.setPermissionPackages(Immutable.OrderedSet());
            return;
        }
        EventActions.setPermissionPackages(Immutable.fromJS(permissionPackages).toSet().sortBy(p => p.name));
    }

    handleSelectRole(role) {
        EventActions.updateEvent('selectedRole', role);
    }

    /* istanbul ignore next */
    render() {
        let event;

        const eventTypeOptions = Object.keys(EventConstants.EVENT_TYPES).map(e => EventConstants.EVENT_TYPES[e]);
        const isDuplicateMode = this.props.mode === 'duplicate';
        const isDuplicateUserCopy = (isDuplicateMode && this.state.event.get('copyUsers'));
        let duplicatedDetail = null;
        let eventTypeSelection = <Select
            className="event-info"
            isDisabled={isDuplicateMode}
            getOptionLabel={data => this.context.intl.messages[data.message]}
            getOptionValue={data => data.id}
            isClearable={true}
            isMulti={false}
            isSearchable={false}
            name="eventType"
            onChange={this.handleSelectEventType}
            options={eventTypeOptions}
            placeholder={this.context.intl.messages['events.summary.event-type.placeholder']}
            value={EventStore.getEventType(this.state.event.get('eventType'))}
        />;

        const accountTypeOptions = Object.keys(EventConstants.ACCOUNT_TYPES).map(e => EventConstants.ACCOUNT_TYPES[e]);
        const isBaftaEvent = this.state.event.get('eventType') === EventConstants.EVENT_TYPES.BAFTA_EVENT.id;
        const isFYCAdminEvent = this.state.event.get('eventType') === EventConstants.EVENT_TYPES.FYC_ADMIN_EVENT.id;
        const isFYCScreening = this.state.event.get('eventType') === EventConstants.EVENT_TYPES.FYC_SCREENING.id;
        let accountTypeSelection = <Select
            className="event-info"
            isDisabled={isDuplicateUserCopy || isFYCScreening || isFYCAdminEvent || isBaftaEvent}
            getOptionLabel={data => this.context.intl.messages[data.message]}
            getOptionValue={data => data.id}
            isClearable={true}
            isMulti={false}
            isSearchable={false}
            name="accountType"
            onChange={this.handleSelectAccountType}
            options={accountTypeOptions}
            placeholder={this.context.intl.messages['events.summary.user-type.placeholder']}
            value={EventStore.getAccountType(this.state.event.get('accountType'))}
        />;

        let created;
        let modified;
        let homepage;
        let homescreen;
        let homeRow;
        let createNote = <div>
            <ControlLabel>{this.context.intl.messages['events.summary.create-note']}</ControlLabel>
            <br/>
            <small>
                <em>{this.context.intl.messages['events.summary.create-note.caption']}</em>
            </small>
            <hr/>
        </div>;
        const req = <span className="text-red">*</span>;

        const isAnonymousScreening = (this.state.event.get('accountType') === EventConstants.ACCOUNT_TYPES.TOKENIZED_ANONYMOUS.id);

        let duplicationOptions;
        if (isDuplicateMode) {
            duplicationOptions = <div>
                <h3><i className="fa-regular fa-copy"></i>&nbsp;{this.context.intl.messages['events.summary.duplication-options']}</h3>
                <FormSection>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['events.summary.duplication-options.original-event']}</ControlLabel>
                            <p><a target="_blank" href={`/events/${this.state.event.get('parentEventId')}`}>{this.state.event.get('parentEventName')}&nbsp;<i className="fa-solid fa-arrow-up-right-from-square" /></a></p>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['events.summary.duplication-options.important-note']}</ControlLabel>
                            <p><small><em>{this.context.intl.messages['events.summary.duplication-options.important-note.text']}</em></small></p>
                        </FormGroup>
                    </FormRow>
                    <hr />
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['events.summary.duplication-options.users']}</ControlLabel>
                            <FormItem type="checkbox"
                                attr="copyUsers"
                                disabled={isAnonymousScreening}
                                label={this.context.intl.messages['events.summary.duplication-options.users.label']}
                                model={this.state.event}
                                setter={EventActions.updateEvent}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['events.summary.duplication-options.assets-titles']}</ControlLabel>
                            <FormItem type="checkbox"
                                attr="copyAssetsTitles"
                                label={this.context.intl.messages['events.summary.duplication-options.assets-titles.label']}
                                model={this.state.event}
                                setter={EventActions.updateEvent}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['events.summary.duplication-options.notification-settings']}</ControlLabel>
                            <FormItem type="checkbox"
                                attr="copyNotificationSettings"
                                label={this.context.intl.messages['events.summary.duplication-options.notification-settings.label']}
                                model={this.state.event}
                                setter={EventActions.updateEventNotification}
                            />
                        </FormGroup>
                    </FormRow>
                </FormSection>
                <hr/>
            </div>;
        }

        if (this.props.mode === 'edit') {
            event = this.state.event;
            createNote = null;
            let eventTypeLabel = '-';
            let accountTypeLabel = '-';

            if (event.get('parentEventId')) {
                let duplicatedProps = [];
                if (event.get('copyUsers')) {
                    duplicatedProps.push(this.context.intl.messages['events.summary.duplicated.users']);
                }
                if (event.get('copyNotificationSettings') || event.get('copyNotification')) {
                    duplicatedProps.push(this.context.intl.messages['events.summary.duplicated.notifications-messaging']);
                }
                if (event.get('copyAssetsTitles')) {
                    duplicatedProps.push(this.context.intl.messages['events.summary.duplicated.assets-titles']);
                }

                if (!duplicatedProps.length) {
                    duplicatedProps.push(this.context.intl.messages['events.summary.duplicated.none']);
                }

                duplicatedDetail = <div>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['events.summary.duplicated.from']}</ControlLabel>
                            <FormControl.Static>
                                <p>
                                    <a target="_blank" href={`/events/${event.get('parentEventId')}`}>{this.context.intl.messages['events.summary.duplicated.go-to']}&nbsp;<i className="fa-solid fa-arrow-up-right-from-square" /></a>
                                </p>
                            </FormControl.Static>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['events.summary.duplicated.properties']}&nbsp;</ControlLabel>
                            <FormControl.Static>{duplicatedProps.join(', ')}</FormControl.Static>
                        </FormGroup>
                    </FormRow>
                    <hr/>
                </div>;
            }
            if (event.get('eventType')) {
                eventTypeLabel = this.context.intl.messages[EventStore.getEventType(event.get('eventType')).message];
            }
            if (event.get('accountType')) {
                accountTypeLabel = this.context.intl.messages[EventStore.getAccountType(event.get('accountType')).message];
            }
            eventTypeSelection = <FormControl.Static>{eventTypeLabel}</FormControl.Static>;
            accountTypeSelection = <FormControl.Static>{accountTypeLabel}</FormControl.Static>;

            let createdDate = '-';
            let createdBy = '-';
            if (event.get('createdDate')) {
                createdDate = Moment(event.get('createdDate'))
                    .tz(config.DefaultTimezone)
                    .format(this.context.intl.messages['datetime-format']);
            }
            if (event.get('createdBy')) {
                createdBy = event.get('createdBy');
            }
            created = (
                <div>
                    <hr/>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['events.summary.created-date']}</ControlLabel>
                            <FormControl.Static>{createdDate}</FormControl.Static>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['events.summary.created-by']}</ControlLabel>
                            <FormControl.Static>{createdBy}</FormControl.Static>
                        </FormGroup>
                    </FormRow>
                </div>
            );

            let modifiedDate = '-';
            let modifiedBy = '-';
            if (event.get('updatedDate')) {
                modifiedDate = Moment(event.get('updatedDate'))
                    .tz(config.DefaultTimezone)
                    .format(this.context.intl.messages['datetime-format']);
            }
            if (event.get('updatedBy')) {
                modifiedBy = event.get('updatedBy');
            }
            modified = (
                <div>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['events.summary.updated-date']}</ControlLabel>
                            <FormControl.Static>{modifiedDate}</FormControl.Static>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['events.summary.updated-by']}</ControlLabel>
                            <FormControl.Static>{modifiedBy}</FormControl.Static>
                        </FormGroup>
                    </FormRow>
                </div>
            );

            homepage = (
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['events.summary.homepage']}</ControlLabel>
                    <FormControl.Static>{this.context.intl.messages['events.summary.homepage.automatic']}</FormControl.Static>
                </FormGroup>
            );
            homescreen = (
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['events.summary.homescreen']}</ControlLabel>
                    <FormControl.Static>{this.context.intl.messages['events.summary.homescreen.automatic']}</FormControl.Static>
                </FormGroup>
            );

            homeRow = (
                <div>
                    <hr/>
                    <FormRow>
                        {homepage}
                        {homescreen}
                    </FormRow>
                </div>
            );
        }

        const eventType = (
            <FormGroup key="formgroup-eventType">
                <ControlLabel>{this.context.intl.messages['events.summary.event-type']}&nbsp;{req}</ControlLabel>
                {eventTypeSelection}
            </FormGroup>
        );

        const accountType = (
            <FormGroup key="formgroup-accountType">
                <ControlLabel>{this.context.intl.messages['events.summary.user-type']}&nbsp;{req}</ControlLabel>
                {accountTypeSelection}
            </FormGroup>
        );

        const selectedBrandId = this.state.event.getIn(['brand', 'id']);
        const selectedBrand = BrandStore.findItemByKey('id', selectedBrandId);
        let brandSelectionDisabled = this.props.disabled || [
            EventConstants.STATUS_TYPES.EVENT_RUNNING.id,
            EventConstants.STATUS_TYPES.EVENT_ENDED.id,
            EventConstants.STATUS_TYPES.EVENT_STOPPING.id].includes(this.state.event.get('eventStatusType'));

        const brandSelection = (
            <FormGroup key="formgroup-brandSelection">
                <ControlLabel>{this.context.intl.messages['events.summary.brand']}</ControlLabel>
                <BrandSelect
                    disabled={brandSelectionDisabled}
                    onChange={this.handleSelectBrand}
                    value={selectedBrand}
                />
            </FormGroup>
        );

        let status = this.context.intl.messages['common.na'];
        if (this.state.event.get('eventStatusType')) {
            const eventStatusType = EventStore.getStatusType(this.state.event.get('eventStatusType'));
            status = <span><i className={eventStatusType.icon}></i>&nbsp;<span className="hidden-xs hidden-sm">{this.context.intl.messages[eventStatusType.description]}</span></span>;
        }

        const eventStatus = <FormGroup key="formgroup-eventStatus">
            <ControlLabel>{this.context.intl.messages['events.summary.status']}</ControlLabel>
            <div>{status}</div>
        </FormGroup>;

        const eventNameLabel = <span>{this.context.intl.messages['events.summary.event-name']}&nbsp;<span className="text-red">*</span></span>;
        const eventName = (
            <FormGroup>
                <FormItem attr="name"
                    disabled={this.props.disabled}
                    label={eventNameLabel}
                    model={this.state.event}
                    type="text"
                    setter={EventActions.updateEvent}
                />
            </FormGroup>
        );

        const referencId = (
            <FormGroup>
                <FormItem attr="displayName"
                    disabled={this.props.disabled}
                    label={this.context.intl.messages['events.summary.reference-id']}
                    model={this.state.event}
                    type="text"
                    setter={EventActions.updateEvent}
                />
            </FormGroup>
        );

        const eventDescriptionLabel = <span>{this.context.intl.messages['events.summary.description']}</span>;
        const eventDescription = (
            <FormGroup>
                <FormItem attr="description"
                    label={eventDescriptionLabel}
                    model={this.state.event}
                    type="textarea"
                    disabled={this.props.disabled}
                    setter={EventActions.updateEvent}
                />
            </FormGroup>
        );

        const selectedPartners = this.state.event.get('partners').toJS().map(p => p.partnerId);
        const selectedPartnersData = this.state.partners.filter((elem) => {
            return selectedPartners.includes(elem.get('id'));
        });

        let partnerSelection = <div>
            <PartnerSelect
                disabled={this.props.disabled || this.state.event.get('eventStatusType') === EventConstants.STATUS_TYPES.EVENT_RUNNING.id || isDuplicateUserCopy}
                isClearable={true}
                isMulti={false}
                onChange={this.handleSelectPartner}
                partnerType="webPartners"
                selected={selectedPartnersData}
                styles={{
                    menu: (provided) => ({
                        ...provided,
                        'z-index': 3
                    })
                }}
            />
            <small>
                <em>{this.context.intl.messages['events.summary.partners.caption']}</em>
            </small>
        </div>;
        if (isFYCScreening) {
            partnerSelection = <small>
                <br/>
                <em>{this.context.intl.messages['events.summary.partners.caption-fyc-screening']}</em>
            </small>;
        }
        if (isBaftaEvent) {
            partnerSelection = <small>
                <br/>
                <em>{this.context.intl.messages['events.summary.partners.caption-bafta']}</em>
            </small>;
        }
        let partners = (
            <FormGroup>
                <ControlLabel>{this.context.intl.messages['events.summary.partners']}</ControlLabel>
                {partnerSelection}
            </FormGroup>
        );

        const selectedPermissionPackages = this.state.permissionPackages.toJS().filter((elem) => {
            return this.state.selectedPermissionPackages.map(p => p.get('id')).includes(elem.id);
        });
        let permissionPackagesOrRoleSelectionLabel = this.context.intl.messages['events.summary.permission-package'];
        let permissionPackagesOrRoleSelection = <div>
            <Select
                getOptionLabel={data => data.name}
                getOptionValue={data => data.id}
                isClearable={true}
                isDisabled={this.props.disabled || isDuplicateUserCopy}
                isMulti={true}
                isSearchable={true}
                name="permissionPackages"
                onChange={this.handleSelectPermissionPackages}
                options={this.state.permissionPackages.toJS()}
                placeholder={this.context.intl.messages['events.summary.permission-package.placeholder']}
                value={selectedPermissionPackages}
                styles={{
                    menu: (provided) => ({
                        ...provided,
                        'z-index': 3
                    })
                }}
            />
            <small>
                <em>{this.context.intl.messages['events.summary.permission-package.caption']}</em>
            </small>
        </div>;
        if (this.state.event.get('accountType') === EventConstants.ACCOUNT_TYPES.TRADITIONAL_LOGIN.id) {
            permissionPackagesOrRoleSelection = <small>
                <br/>
                <em>{this.context.intl.messages['events.summary.permission-package.caption-traditional-login']}</em>
            </small>;
        }
        if (isAnonymousScreening) {
            permissionPackagesOrRoleSelectionLabel = this.context.intl.messages['events.summary.role'];
            permissionPackagesOrRoleSelection = <div>
                <RoleSelect
                    disabled={this.props.disabled || isFYCScreening || isBaftaEvent}
                    isMulti={false}
                    onChange={this.handleSelectRole}
                    selected={Immutable.fromJS([this.state.event.get('selectedRole')])}
                    showInactive={false}
                />
                <small>
                    <em>{this.context.intl.messages['events.summary.role.caption']}</em>
                </small>
            </div>;
        }

        const permissionPackagesOrRole = (
            <FormGroup>
                <ControlLabel>{permissionPackagesOrRoleSelectionLabel}</ControlLabel>
                {permissionPackagesOrRoleSelection}
            </FormGroup>
        );

        let eventTypeRowChildren = [
            eventType,
            accountType,
            brandSelection
        ];

        if (!isDuplicateMode) {
            eventTypeRowChildren.push(eventStatus);
        }

        let linkToFYCAdminEvent;
        if (isFYCScreening) {
            linkToFYCAdminEvent = <FormRow>
                <FormGroup key="formgroup-linkEvent">
                    <ControlLabel>{this.context.intl.messages['events.summary.link-to-fyc-admin-event']}</ControlLabel>
                    <FormItem type="checkbox"
                        attr="linkToFYCAdminEvent"
                        disabled={this.state.activeAdminEvents.size === 0 || this.props.disabled}
                        label={this.context.intl.messages['events.summary.link-to-fyc-admin-event']}
                        model={this.state.event}
                        setter={this.handleLinkToFYCAdminEvent}
                    />
                </FormGroup>
            </FormRow>;
        }

        return (
            <div>
                {duplicationOptions}
                <h3><i className="far fa-info-circle"></i>{this.context.intl.messages['events.summary.event-info']}</h3>
                <FormSection>
                    {createNote}
                    {duplicatedDetail}
                    <FormRow>
                        {eventTypeRowChildren}
                    </FormRow>
                    {linkToFYCAdminEvent}
                    <hr/>
                    <FormRow>
                        {eventName}
                        {referencId}
                    </FormRow>
                    <FormRow>
                        {eventDescription}
                    </FormRow>
                    {created}
                    {modified}
                </FormSection>
                <hr/>
                <h3><i className="far fa-plug"></i>{this.context.intl.messages['events.summary.event-access']}</h3>
                <FormSection>
                    <FormRow>
                        {partners}
                        {permissionPackagesOrRole}
                    </FormRow>
                    {homeRow}
                </FormSection>
                <hr/>
                <StartEndDates
                    cancelDateFunction={this.cancelDateFunction}
                    disabled={this.props.startEndDatesDisabled}
                    event={this.state.event}
                    originalEvent={this.state.originalEvent}
                    handleChangeDateDay={this.handleChangeDateDay}
                    handleChangeDateTime={this.handleChangeDateTime}
                    handleSelectCity={this.handleSelectCity}
                    mode={this.props.mode}
                    req={req}
                />
            </div>
        );
    }
}

export default Container.create(Summary);
