/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';
import {Alert} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';

// const VTT_CONTENT_TYPE = 'text/vtt';

type Props = {
    className?: string,
    show: boolean,
    onFileSelected: (file: File | null) => void,
}

type State = {
    errorMessage: string | null,
}

export default class VttFileSelector extends React.PureComponent<Props, State> {
    constructor(props: never) {
        super(props);

        this.state = {
            errorMessage: null,
        };

        this.handleDismissAlert = this.handleDismissAlert.bind(this);
        this.handleFileSelectedEvent = this.handleFileSelectedEvent.bind(this);
        this.showErrorMessage = this.showErrorMessage.bind(this);
    }

    componentDidUpdate(oldProps: Props) {
        if (this.props.show !== oldProps.show) {
            this.resetSelectedFile();
        }
    }

    private handleDismissAlert() {
        this.setState(() => ({errorMessage: null}));
    }

    private handleFileSelectedEvent(ev: React.SyntheticEvent<HTMLInputElement>) {
        this.props.onFileSelected(null);
        this.setState(() => ({errorMessage: null}));
        const field = ev.target;
        if (!(field instanceof HTMLInputElement)) {
            console.error('target of handler should be an input field');
            return;
        }
        const file = field.files?.item(0);
        if (!file) {
            this.showErrorMessage('cc-editor.upload.modal.messages.no-file-selected');
        // } else if (file.type === VTT_CONTENT_TYPE) {
        } else {
            this.props.onFileSelected(file);
            // this.showErrorMessage('cc-editor.upload.modal.messages.incorrect-file-type');
        }
    }

    private resetSelectedFile() {
        const field = this.refs.fileSelector;
        if (field instanceof HTMLInputElement) {
            field.value = '';
        }
    }

    private showErrorMessage(errorMessage: string) {
        this.setState(() => ({errorMessage}));
    }

    private renderAlertMessage() {
        const message = this.state.errorMessage;
        if (!message) {
            return;
        }

        return (
            <div className="col-md-12">
                <Alert
                    bsClass="alert alert-danger"
                    onDismiss={this.handleDismissAlert}
                >
                    <i className="icon fa fas fa-ban" />
                    <FormattedMessage id={message} />
                </Alert>
            </div>
        );
    }

    render() {
        return (
            <div className={this.props.className}>
                <div className="col-md-12 form-group">
                    <input
                        ref="fileSelector"
                        accept="text/vtt"
                        type="file"
                        onChange={this.handleFileSelectedEvent}
                    />
                </div>

                {this.renderAlertMessage()}
            </div>
        );
    }
}
