/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ControlLabel, FormGroup} from 'react-bootstrap';

import BaseSelect from '../../../common/base-select/base-select';
import {FormRow, FormSection} from '../../../common/form/form';
import {SectionPanel} from '../../../common/panel/panel';
import TinyMCE from '../../../common/tinymce/tinymce';
import {CompareImmutable} from '../../../common/utils/utils';
import {WithValidations} from '../../../common/validations/validations';
import GenreSelect from '../../../lookup/genre/genre-select';
import GenreStore from '../../../lookup/genre/genre-store';
import LanguageSelect from '../../../lookup/language/language-select';
import LanguageStore from '../../../lookup/language/language-store';
import {HandleSelect, MapToValues} from '../../../lookup/lookup-select-helpers';
import ThemeSelect from '../../../lookup/theme/theme-select';
import ThemeStore from '../../../lookup/theme/theme-store';
import {TitleActions} from '../../title-actions';
import TitleStore, {TitleValidations} from '../../title-store';

/**
 * Title props to watch.
 */
const titleProps = [
    'awards', 'frontendComment', 'genres', 'languages',
    'mpaaRatings', 'parentalRatingId', 'ratingReasons', 'themes'
];

class AdditionalInfo extends Component {

    static get propTypes() {
        return {
            disabled: PropTypes.bool,
            expanded: PropTypes.bool,
            title: PropTypes.instanceOf(Immutable.Map).isRequired,
            showErrors: PropTypes.bool
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true,
            expanded: false,
            showErrors: undefined
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            isGenresDirty: false,
            isParentalRatingTypeDirty: false,
            selectedMPAARatingType: TitleStore.getMPAARatingType(this.props.title.get('mpaaRatings')) || Immutable.Map(),
            selectedParentalRatingType: TitleStore.getParentalRatingType(this.props.title.get('parentalRatingId')) || Immutable.Map(),
            selectedRatingReasons: TitleStore.getRatingReasonTypes(this.props.title.get('ratingReasons')) || Immutable.Map()
        };

        this.handleSelectGenres = this.handleSelectGenres.bind(this);
        this.handleSelectLanguages = HandleSelect('languages', TitleActions.updateTitle, {multi: true}).bind(this);
        this.handleSelectRatingReasons = HandleSelect('ratingReasons', TitleActions.updateTitle, {multi: true}).bind(this);
        this.handleSelectMPAARatings = HandleSelect('mpaaRatings', TitleActions.updateTitle, {multi: false, valueAttr: 'id'}).bind(this);
        this.handleSelectParentalRatingId = this.handleSelectParentalRatingId.bind(this);
        this.handleSelectThemes = HandleSelect('themes', TitleActions.updateTitle, {multi: true}).bind(this);
        this.handleTinyMCEChange = this.handleTinyMCEChange.bind(this);
        this.mapGenres = MapToValues('id', GenreStore).bind(this);
        this.mapLanguages = MapToValues('id', LanguageStore).bind(this);
        this.mapThemes = MapToValues('id', ThemeStore).bind(this);
    }

    componentWillReceiveProps(nextProps) {
        // The title only contains the ids, so we use the store to
        // get the full object with id and name.
        this.setState({
            selectedMPAARatingType: TitleStore.getMPAARatingType(nextProps.title.get('mpaaRatings')) || Immutable.Map(),
            selectedParentalRatingType: TitleStore.getParentalRatingType(nextProps.title.get('parentalRatingId')) || Immutable.Map(),
            selectedRatingReasons: TitleStore.getRatingReasonTypes(nextProps.title.get('ratingReasons')) || Immutable.Map()
        });

        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.disabled !== this.props.disabled ||
            nextProps.expanded !== this.props.expanded ||
            nextProps.showErrors !== this.props.showErrors ||
            !CompareImmutable(nextProps.title, this.props.title, titleProps)) {
            return true;
        }

        if (nextState.isGenresDirty !== nextState.isGenresDirty ||
            nextState.isParentalRatingTypeDirty !== this.state.isParentalRatingTypeDirty ||
            !nextState.selectedParentalRatingType.equals(this.state.selectedParentalRatingType) ||
            !nextState.selectedMPAARatingType.equals(this.state.selectedMPAARatingType)) {
            return true;
        }

        return false;
    }

    handleSelectGenres(value) {
        if (!this.state.isGenresDirty) {
            this.setState({isGenresDirty: true});
        }

        HandleSelect('genres', TitleActions.updateTitle, {multi: true})(value);
        return;
    }

    handleSelectParentalRatingId(value) {
        if (!this.state.isParentalRatingTypeDirty) {
            this.setState({isParentalRatingTypeDirty: true});
        }

        HandleSelect('parentalRatingId', TitleActions.updateTitle, {multi: false, valueAttr: 'id'})(value);
        return;
    }

    handleTinyMCEChange(attr) {
        return function handler(e) {
            TitleActions.updateTitle(attr, e.target.getContent());
            return;
        };
    }

    render() {
        return (
            <SectionPanel
                expanded={this.props.expanded}
                title={this.context.intl.messages['titles.create.summary.additional-info']}
                validationState={this.getAccordionValidationState(this.props.title, titleProps, TitleValidations)}
            >
                <FormSection>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['titles.create.summary.languages']}</ControlLabel>
                            <LanguageSelect
                                disabled={this.props.disabled}
                                multi
                                name="languages"
                                onChange={this.handleSelectLanguages}
                                value={this.mapLanguages(this.props.title.get('languages'))}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['titles.create.summary.genres']}</ControlLabel>
                            <GenreSelect
                                disabled={this.props.disabled}
                                multi
                                name={this.context.intl.messages['titles.create.summary.genres']}
                                onChange={this.handleSelectGenres}
                                value={this.mapGenres(this.props.title.get('genres'))}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['titles.create.summary.themes']}</ControlLabel>
                            <ThemeSelect
                                disabled={this.props.disabled}
                                multi
                                name="themes"
                                onChange={this.handleSelectThemes}
                                value={this.mapThemes(this.props.title.get('themes'))}
                            />
                        </FormGroup>
                    </FormRow>
                </FormSection>

                <hr/>

                <FormSection>
                    <FormRow>
                        <FormGroup validationState={this.getValidationState(this.props.title.get('parentalRatingId'), this.state.isParentalRatingTypeDirty, TitleValidations.parentalRatingId.validations)}>
                            <ControlLabel>{this.context.intl.messages['titles.create.summary.parental-content-rating']}</ControlLabel>
                            <BaseSelect
                                disabled={this.props.disabled}
                                name={this.context.intl.messages['titles.create.summary.parental-content-rating']}
                                onChange={this.handleSelectParentalRatingId}
                                options="parentalRatingsTypes"
                                store={TitleStore}
                                value={this.state.selectedParentalRatingType}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['titles.create.summary.mpaa-rating']}</ControlLabel>
                            <BaseSelect
                                disabled={this.props.disabled}
                                name="mpaaRatings"
                                onChange={this.handleSelectMPAARatings}
                                options="mpaaRatingsTypes"
                                store={TitleStore}
                                value={this.state.selectedMPAARatingType}
                            />
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['titles.create.summary.rating-reason']}</ControlLabel>
                            <BaseSelect
                                disabled={this.props.disabled}
                                multi
                                name="ratingReasons"
                                store={TitleStore}
                                options="ratingReasonTypes"
                                onChange={this.handleSelectRatingReasons}
                                value={this.state.selectedRatingReasons}
                            />
                        </FormGroup>
                    </FormRow>
                </FormSection>

                <hr/>

                <FormSection>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['titles.create.summary.front-end-comment']}</ControlLabel>
                            <TinyMCE
                                content={this.props.title.get('frontendComment') || ''}
                                disabled={this.props.disabled}
                                id="frontend-comment"
                                onChange={this.handleTinyMCEChange('frontendComment')}
                            />
                        </FormGroup>
                    </FormRow>
                </FormSection>

                <hr/>

                <FormSection>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['titles.create.summary.awards']}</ControlLabel>
                            <TinyMCE
                                disabled={this.props.disabled}
                                content={this.props.title.get('awards') || ''}
                                id="awards"
                                onChange={this.handleTinyMCEChange('awards')}
                            />
                        </FormGroup>
                    </FormRow>
                </FormSection>
            </SectionPanel>
        );
    }
}

export default WithValidations(AdditionalInfo);
