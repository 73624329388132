/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {AudioConstants} from './audio-actions';
import Validations from '../../common/validations/validations';
import Dispatcher from '../../dispatcher/dispatcher';
import {ActionHistoryConstants} from '../../system/action-history/action-history-actions';

/**
 * Look for a constant with id === objectId in a map.
 */
let findInConstant = function(constants, objectId) {
    return Immutable.fromJS(
        Object.keys(constants)
            .map(k => constants[k])
            .filter(obj => objectId === obj.id)[0]
    );
};

const AudioAssetValidations = {
    name: {
        label: 'asset.audio.create.name',
        validations: [Validations.max(200), Validations.required]
    },
    assetComment: {
        label: 'asset.audio.create.assetComment',
        validations: [Validations.max(255)]
    },
    mediaKey: {
        label: 'asset.audio.create.mediakey',
        validations: [Validations.max(50)]
    },
    runtime: {
        label: 'asset.audio.create.runtime',
        validations: [Validations.max(50)]
    },
    copyrightYear: {
        label: 'asset.audio.create.copyrightYear',
        validations: [Validations.max(4)]
    },
    copyrightText: {
        label: 'asset.audio.create.copyrightText',
        validations: [Validations.max(200)]
    },
    description: {
        label: 'asset.audio.create.description',
        validations: [Validations.max(1000)]
    },
    keyword: {
        label: 'asset.audio.create.keyword',
        validations: [Validations.max(250)]
    },
    contentType: {
        label: 'asset.audio.create.contentType',
        validations: [Validations.required]
    },
};

class AudioStore extends ReduceStore {

    // Return an action type by id.
    getContentType(contentTypeId) {
        return findInConstant(AudioConstants.CONTENT_TYPES, contentTypeId);
    }

    getInitialState() {
        return Immutable.Map({
            contentType: Immutable.fromJS(AudioConstants.toArray('CONTENT_TYPES')),
            asset: Immutable.Map({
                active: 1,
                files: []
            }),
            history: Immutable.List(),
            originalAsset: Immutable.Map({
                active: 1,
            }),
            originalAudio: null,
            showPreloader: false
        });
    }

    getValidations() {
        return Validations.validate(this.getState().get('asset'), AudioAssetValidations);
    }

    reduce(state, action) {
        switch (action.actionType) {
        case ActionHistoryConstants.ADD_NOTE.SUCCESS:
            if ( action.actionObjectType === ActionHistoryConstants.ACTION_OBJECTS.AUDIO ) {
                state = state.updateIn(['history'], history => history.unshift(action.note));
            }
            break;

        case AudioConstants.AUDIO.CLEAR:
            state = this.getInitialState();
            break;

        case AudioConstants.AUDIO.GET.START:
            state = this.getInitialState();
            state = state.merge({
                showPreloader: true
            });
            break;

        case AudioConstants.AUDIO.GET.SUCCESS:
            state = state.merge({
                asset: action.asset,
                history: action.history,
                originalAsset: action.asset,
                showPreloader: false
            });
            break;

        case AudioConstants.AUDIO.UPDATE:
            state = state.setIn(['asset', ...action.attr.split('.')], action.value);
            break;

        case AudioConstants.AUDIO.SAVE.ERROR:
            state = state.merge({
                showPreloader: false
            });
            break;

        case AudioConstants.AUDIO.SAVE.START:
            state = state.merge({
                showPreloader: true
            });
            break;

        case AudioConstants.AUDIO.SAVE.SUCCESS:
            state = state.setIn(['asset', 'id'], action.id);
            state = state.setIn(['asset', 'files'], []);
            state = state.merge({
                originalAsset: state.get('asset'),
                originalAudio: action.audioFile,
                showPreloader: false
            });
            break;
        }

        return state;
    }
}

let audio = new AudioStore(Dispatcher);

export default audio;

export {AudioAssetValidations};
