/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {RoleConstants} from './role-actions';
import Validations from '../../common/validations/validations';
import Dispatcher from '../../dispatcher/dispatcher';

const RoleStoreValidations = {
    name: {label: 'roles.edit.name', validations: [Validations.required, Validations.max(50)]},
    description: {label: 'roles.edit.role.description', validations: [Validations.required, Validations.max(250)]}
};

class RoleStore extends ReduceStore {

    getInitialState() {
        return Immutable.Map({
            basePermission: Immutable.Set(),
            offset: 0,
            originalRole: Immutable.Map({
                isActive: true
            }),
            role: Immutable.Map({
                history: Immutable.List(),
                isActive: true
            }),
            roles: Immutable.List(),
            selectedPermission: Immutable.Set(),
            size: 0,
            total: 0
        });
    }

    getValidations() {
        return Validations.validate(this.getState().get('role'), RoleStoreValidations);
    }

    reduce(state, action) {
        switch (action.actionType) {
        case RoleConstants.LOAD:
            state = state.merge({
                offset: action.offset,
                roles: action.roles,
                size: action.size,
                total: action.total
            });
            break;
        case RoleConstants.CLEAR:
            state = this.getInitialState();
            break;
        case RoleConstants.FIND_BY_ID.SUCCESS:
            state = state.merge({
                role: action.role,
                originalRole: action.role
            });
            break;
        case RoleConstants.GET_ROLE_PERMISSIONS.SUCCESS:
            state = state.merge({
                selectedPermission: action.permissions,
                basePermission: action.permissions
            });
            break;

        case RoleConstants.ROLE.SAVE_OK:
            state = state.merge({
                basePermission: state.get('selectedPermission'),
                originalRole: state.get('role')
            });
            break;

        case RoleConstants.ROLE_PERMISSION.ADD:
            state = state.merge({
                selectedPermission: state.get('selectedPermission').union(action.ids)
            });
            break;
        case RoleConstants.ROLE_PERMISSION.REMOVE:
            state = state.merge({
                selectedPermission: state.get('selectedPermission').subtract(action.ids)
            });
            break;
        case RoleConstants.ROLE.UPDATE:
            state = state.setIn(['role', ...action.attr.split('.')], action.value);
            break;

        default:
            break;
        }

        return state;
    }
}

let store = new RoleStore(Dispatcher);

export default store;

export {RoleStoreValidations};
