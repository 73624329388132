/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';
import {Route} from 'react-router';

import CCEditor from './cc-editor';

import {RequirePerm} from '~/src/router/router-helpers';
import SessionStore from '~/src/user/session/session-store';

const routes = (
    <Route
        component={CCEditor}
        onEnter={RequirePerm.bind(this, SessionStore.PERMISSIONS.HARDAC.CC_EDITOR.EDIT)}
        path="cc-editor/assets/:assetId/vtt/:vttId"
    >
        <Route path="settings"/>
    </Route>
);

export default routes;
