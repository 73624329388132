/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React, {PropTypes} from 'react';
import {Label, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';

const VideoAssetFormatLabel = ({data}) => {
    let style = 'default';
    if (data.className === 'label label-danger') {
        style = 'danger';
    }

    return <OverlayTrigger
        placement="top"
        overlay={
            <Tooltip><FormattedMessage id={data.tooltip}/></Tooltip>
        }>
        <Label bsSize="md" bsStyle={style}><FormattedMessage id={data.text}/></Label>
    </OverlayTrigger>;
};

VideoAssetFormatLabel.propTypes = {
    data: PropTypes.object
};

VideoAssetFormatLabel.defaultProps = {
    data: {
        className: 'label label-danger',
        text: 'common.video-delivery-format.na',
        tooltip: 'common.video-delivery-format.na.tooltip',
    }
};

export default VideoAssetFormatLabel;
