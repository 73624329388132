/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import SetCaretPosition from './set-caret-position';

type Direction = 'prev' | 'next';
type DirectionIterator = keyof NonDocumentTypeChildNode;

export default function FocusFieldFactory(direction: Direction, position = 0) {
    let prop: DirectionIterator = 'previousElementSibling';
    if (direction === 'next') {
        prop = 'nextElementSibling';
    }

    return function focusField(el: HTMLInputElement) {
        let prev: Element | null = el;
        while ((prev = prev[prop])) {
            if (prev instanceof HTMLInputElement) {
                prev.focus();
                SetCaretPosition(prev, position, prev.value);
                break;
            }
        }
    };
}
