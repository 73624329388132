/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import Avatar from './../../avatar';
import Event from './event';
import Note from './note';
import Config from '../../../config/config.js';
import {ActionHistoryConstants} from '../../../system/action-history/action-history-actions';
import {HistoryConstants} from '../history-actions';


import './default-view.less';

const getNoteData = (note, context) => {
    const {actionDate, actionHistoryType, changedFields, actor} = note;
    const actionObjectType = HistoryConstants.ACTION_OBJECT_TYPE_DATA[note.actionObjectType] || {};
    const hours = Moment(actionDate).tz(Config.DefaultTimezone).format(context.intl.messages['history.event.hours']);
    const formattedHours = hours.split('@')[1].trim();
    const icon = actionObjectType.icon || 'far fa-edit';
    let iconColor = HistoryConstants.ACTION_HISTORY_TYPE_DATA[actionHistoryType]?.iconColor;
    let actionType = HistoryConstants.ACTION_HISTORY_TYPE_DATA[actionHistoryType]?.action;
    let objectTypeDisplayName = actionObjectType.displayName;
    if (typeof(changedFields) === 'object' && !Array.isArray(changedFields)) {
        objectTypeDisplayName = changedFields.fieldName;
        actionType = HistoryConstants.CHANGED_FIELDS_ACTION[changedFields.action].action;
        iconColor = HistoryConstants.CHANGED_FIELDS_ACTION[changedFields.action].iconColor;
    }
    return {icon, iconColor, formattedHours, actor, actionType, objectTypeDisplayName};
};

class HistoryDefaultView extends Component {
    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get propTypes() {
        return {
            history: PropTypes.instanceOf(Immutable.List).isRequired
        };
    }

    getChangedFields(note) {
        let changeDescription;
        try {
            changeDescription = JSON.parse(note.changeDescription);
        } catch (e) {
            // console.error(e);
            // This is a parse error. It's ok, sometimes the value is JSON,
            // sometimes it's not :)
            return note.changeDescription;
        }

        // WPB-8883 > Do not display Table rows for updatedBy/updatedDate history items
        if (note.actionHistoryType === ActionHistoryConstants.TYPES.UPDATE) {
            changeDescription.changedFields = changeDescription.changedFields.filter(c => !(c.fieldName === 'updatedDate' || c.fieldName === 'updatedBy'));
            if (!changeDescription.changedFields.length) {
                return note.displayDescription;
            }
        }

        // WPB-5479 > Display Delete action history using a table
        // just like the updates.
        if (note.actionHistoryType === ActionHistoryConstants.TYPES.DELETE) {
            changeDescription.changedFields = Object.keys(
                changeDescription
            ).filter(
                k => !k.match(/(created|updated)(By|Date)/)
            ).map(
                k => {
                    let oldValue = changeDescription[k];
                    if (Array.isArray(oldValue)) {
                        return oldValue;
                    }

                    return {
                        action: 'Remove',
                        fieldName: k,
                        oldValue
                    };
                }
            );
            changeDescription.changedFields = changeDescription.changedFields.reduce((r, nested) => r.concat(nested), []);
        }
        return changeDescription.changedFields;
    }

    render() {
        const historyGroups = this.props.history.toJS().reduce((groups, note) => {
            const actionDate = note.actionDate.substring(0, 10);
            const list = groups[actionDate] || [];
            //TODO delete this if server sends each field changed in a separate note, just keep else in that case
            let changedFields = this.getChangedFields(note);

            // In case Timeline creation do not show all fields changed, we will show only a link to QC video
            const isTimelineCreation = note.actionHistoryType === ActionHistoryConstants.TYPES.CREATE &&
                note.actionObjectType.toLowerCase() === ActionHistoryConstants.ACTION_OBJECTS.VIDEOTIMELINE.toLocaleLowerCase();

            if (Array.isArray(changedFields) && !isTimelineCreation) {
                changedFields = changedFields.map(changes => ({...note, changedFields: changes}));
                groups[actionDate] = [...list, ...changedFields];
            } else {
                groups[actionDate] = [...list, {...note, changedFields}];
            }
            return groups;
        }, {});

        let notes = [];
        Object.keys(historyGroups).map(key => {
            const group = historyGroups[key];
            const formattedDate = Moment(key).tz(Config.DefaultTimezone).format(this.context.intl.messages['history.event.date']);
            notes.push(
                <li className="time-label">
                    <span className="bg-white"><span className="text-muted">{formattedDate}</span></span>
                </li>
            );

            group.forEach(note => {
                let noteBody;
                if (note.actionHistoryType === ActionHistoryConstants.TYPES.USER_GENERATED) {
                    noteBody = <Note note={note}/>;
                } else {
                    noteBody = <Event displayFull={true} note={note}/>;
                }
                const {icon, iconColor, formattedHours, actor, actionType, objectTypeDisplayName} = getNoteData(note, this.context);
                const avatar = <Avatar name={actor} />;
                notes.push(
                    <li>
                        <i className={`${icon} ${iconColor}`}></i>
                        <div className="timeline-item">
                            <span className="time">
                                <i className="far fa-clock"></i>&nbsp;
                                {formattedHours}
                            </span>
                            <h3 className="timeline-header overflow-unset">
                                {/* here it is missing to change email href="mailto:Rosemary.Marez@warnerbros.com" */}
                                <a>{avatar}&ensp;&nbsp;{actor}</a>&nbsp;{actionType}&nbsp;<strong>{objectTypeDisplayName}</strong>
                            </h3>
                            <div className="timeline-body">
                                {noteBody}
                            </div>
                        </div>
                    </li>
                );
            });
        });
        return (
            <section>
                <div className="history-content">
                    <ul className="timeline timeline-inverse">
                        {notes}
                        <li>
                            <i className="far fa-clock bg-gray"></i>
                        </li>
                    </ul>
                </div>
            </section>
        );
    }
}

export default HistoryDefaultView;
