/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
*/

import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link} from 'react-router';
import ReactTable from 'react-table';

import AccountWizardStore from '../account-wizard-store';

import Pagination from '~/src/common/table/pagination';
import Config from '~/src/config/config.js';

import 'react-table/react-table.css';
require('../../../styles/data-tables-brainiac.css');

const TableId = 'account-wizard-batch-browse-table-id';
export default class Table extends Component {
    static get propTypes() {
        return {
            activePage: PropTypes.number.isRequired,
            batches: PropTypes.instanceOf(Immutable.List).isRequired,
            displayLinks: PropTypes.bool,
            onPageChange: PropTypes.func.isRequired,
            onSortChange: PropTypes.func.isRequired,
            sortDirection: PropTypes.string.isRequired,
            sortFieldName: PropTypes.string.isRequired,
            totalPages: PropTypes.number.isRequired,
        };
    }

    static get defaultProps() {
        return {
            displayLinks: false
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
            router: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.handleHeaderClick = this.handleHeaderClick.bind(this);
        this.getHeaderNameClass = this.getHeaderNameClass.bind(this);
    }

    getHeaderNameClass(headerName) {
        let headerClassName = 'sorting';

        if (headerName === this.props.sortFieldName) {
            headerClassName = `sorting-${this.props.sortDirection}`;
        }

        return headerClassName;
    }

    handleHeaderClick(headerName) {
        if (!headerName) {
            return;
        }

        let newSortDirection = 'asc';
        if (this.props.sortFieldName === headerName && this.props.sortDirection === 'asc') {
            newSortDirection = 'desc';
        }

        if (this.props.onSortChange) {
            this.props.onSortChange(headerName, newSortDirection);
        }
    }

    render() {
        const getHeader = this.getHeaderNameClass;
        /*istanbul ignore next */
        let columns = [{
            id: 'NAME',
            accessor: c => c.get('name'),
            Header: props => (
                <strong className={getHeader(props.column.id)}>{this.context.intl.messages['accounts.account-wizard.browse.batch-name']}</strong>
            ),
            maxWidth: 400,
            Cell: c => {
                // Leaving the option to use the id here in order to have access to the batches without a name
                let batchName = c.original.get('name') || c.original.get('id');
                if (this.props.displayLinks) {
                    batchName = <Link to={`/accounts/account-wizard/${c.original.get('id')}`}>{batchName}</Link>;
                }
                return (
                    <div>
                        {batchName || '-'}
                    </div>
                );
            },
            sortable: true
        }, {
            id: 'STATUS',
            accessor: c => c.get('status'),
            Header: props => (
                <strong className={getHeader(props.column.id)}>{this.context.intl.messages['accounts.account-wizard.browse.status']}</strong>
            ),
            maxWidth: 300,
            Cell: c => {
                let status = this.context.intl.messages['common.na'];
                if (c.original.get('status')) {
                    status = c.original.get('status');
                    const batchStatusType = AccountWizardStore.getStatusType(c.original.get('status'));
                    if (batchStatusType) {
                        const description = this.context.intl.messages[batchStatusType.description];
                        const tooltip = this.context.intl.messages[batchStatusType.tooltip];
                        status = <span className="parcel-tooltip">
                            <i title={tooltip} className={batchStatusType.icon}></i>&nbsp;<span className="hidden-xs hidden-sm">{description}</span>
                            <span className="tooltip-inner" style={{position: 'absolute'}}>{tooltip}</span>
                        </span>;
                    }
                }
                return status;
            },
            sortable: true
        }, {
            id: 'updated_date',
            accessor: c => c.get('updated_date'),
            Header: props => (
                <strong className={getHeader(props.column.id)}>{this.context.intl.messages['accounts.account-wizard.browse.last-updated']}</strong>
            ),
            maxWidth: 300,
            Cell: c => {
                const updatedDateShort = Moment(c.original.get('updatedDate')).tz(Config.DefaultTimezone).format(this.context.intl.messages['date-format-shortened']);
                const updatedDatetime = Moment(c.original.get('updatedDate')).tz(Config.DefaultTimezone).format(this.context.intl.messages['datetime-format-0']);
                const createdDatetime = Moment(c.original.get('createdDate')).tz(Config.DefaultTimezone).format(this.context.intl.messages['datetime-format-0']);
                const title = `${this.context.intl.messages['accounts.account-wizard.browse.updated']}: ${updatedDatetime},\n${this.context.intl.messages['accounts.account-wizard.browse.created']}: ${createdDatetime}`;
                return (
                    <div>
                        {updatedDateShort} <i className="fa-solid fa-info-circle" data-toggle="tooltip" title={title} data-original-title={title} />
                    </div>
                );
            },
            sortable: true
        }, {
            id: 'percent_complete',
            accessor: c => c.get('percent_complete'),
            Header: props => (
                <strong className={getHeader(props.column.id)}>{this.context.intl.messages['accounts.account-wizard.browse.user-count']}</strong>
            ),
            maxWidth: 300,
            Cell: c => {
                const processed = c.original.get('numberProcessed');
                const total = c.original.get('numberRecords');
                return (
                    <div>
                        {processed}/{total}
                    </div>
                );
            },
            sortable: true
        }, {
            id: 'EVENT_NAME',
            accessor: c => c.get('eventName'),
            Header: props => (
                <strong className={getHeader(props.column.id)}>{this.context.intl.messages['accounts.account-wizard.browse.event-name']}</strong>
            ),
            maxWidth: 300,
            Cell: c => {
                let eventName = c.original.get('eventName') || 'N/A';
                if (this.props.displayLinks && c.original.get('eventId')) {
                    eventName = <Link to={`/events/${c.original.get('eventId')}`}>{eventName}</Link>;
                }
                return (
                    <div>
                        {eventName}
                    </div>
                );
            },
            sortable: true
        }];

        const handleSortColumn = this.handleHeaderClick;

        const pagination = <div className="row">
            <div className="col-sm-7 col-sm-offset-5">
                <Pagination
                    activePage={this.props.activePage}
                    onChange={this.props.onPageChange}
                    totalPages={this.props.totalPages}
                />
            </div>
        </div>;

        return (
            <div id={TableId}>
                <ReactTable
                    className="-striped table-bordered table-striped responsive account-wizard-batches-table"
                    columns={columns}
                    data={this.props.batches}
                    getNoDataProps= {/*istanbul ignore next*/() => {
                        if (this.props.batches.size) {
                            return {style: {display: 'none'}};
                        }
                        return {};
                    }}
                    id={TableId}
                    loading={false}
                    pageSize={20}
                    showPagination={false}
                    sortable={true}
                    resizable={true}
                    getTheadThProps={/*istanbul ignore next*/(state, rowInfo, column) => {
                        return {
                            onClick: () => {
                                if (column.sortable) {
                                    handleSortColumn(column.id);
                                }
                            }
                        };
                    }}
                />
                {pagination}
            </div>
        );
    }
}
