/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {DatadogClient, DatadogConstants} from '@wbdt-sie/brainiac-web-common';

import {WBTVDAnalytics} from './wbtvd-analytics';


class Analytics {
    pageViewEvent() {
        WBTVDAnalytics.event('analytics/page/view', {
            resolution: window.screen.width + 'x' + window.screen.height,
            title: document.title,
            url: window.location.href,
            viewportSize: window.innerWidth + 'x' + window.innerHeight,
        });
    }

    logEvent(e) {
        // Enable for development purposes
        // console.log(`*** Analytics event: ${e}`);
        return e;
    }

    userLoginStartEvent() {
        // User clicked the login button to begin login process
        const e = DatadogConstants.ACTIONS.LOGIN.START;
        this.logEvent(e);
        DatadogClient.addAction(e);
    }

    userLoginSuccessEvent() {
        // User successfully completed login workflow
        const e = DatadogConstants.ACTIONS.LOGIN.SUCCESS;
        this.logEvent(e);
        DatadogClient.addAction(e);
        WBTVDAnalytics.event('analytics/user/login');
    }

    userLoginErrorEvent() {
        // User had an error during login
        const e = DatadogConstants.ACTIONS.LOGIN.ERROR;
        this.logEvent(e);
        DatadogClient.addAction(e);
    }

    userLoginMFAChallengeSentEvent() {
        // User logged in and was prompted for MFA
        const e = DatadogConstants.ACTIONS.LOGIN.MFA_CHALLENGE_SENT;
        this.logEvent(e);
        DatadogClient.addAction(e);
    }

    userLoginMFAChallengeCompleteEvent() {
        // User completed MFA challenge
        const e = DatadogConstants.ACTIONS.LOGIN.MFA_CHALLENGE_COMPLETED;
        this.logEvent(e);
        DatadogClient.addAction(e);
    }

    videoPlaybackStartEvent() {
        // User clicked the play button on a video thumbnail
        const e = DatadogConstants.ACTIONS.VIDEO_PLAYBACK.START;
        this.logEvent(e);
        DatadogClient.addAction(e);
    }

    videoPlaybackSuccessEvent() {
        // Player has actually begun playback
        const e = DatadogConstants.ACTIONS.VIDEO_PLAYBACK.SUCCESS;
        this.logEvent(e);
        DatadogClient.addAction(e);
    }

    videoPlaybackErrorEvent() {
        // User experienced any kind of error during playback
        const e = DatadogConstants.ACTIONS.VIDEO_PLAYBACK.ERROR;
        this.logEvent(e);
        DatadogClient.addAction(e);
    }

    videoPlaybackEvent(videoId, titleId, type, watchtime, url, sourceType, fullscreen, errorCode, errorMessage) {
        this.logEvent('videoPlaybackEvent');

        WBTVDAnalytics.event('analytics/video/playback', {
            errorCode,
            errorMessage,
            fullscreen,
            playerName: 'AMP',
            sourceType,
            titleId,
            type,
            url,
            videoId,
            watchtime,
        });
    }
}

const analytics = new Analytics();

export default analytics;
