/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */


import Immutable from 'immutable';
import jQuery from 'jquery';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {WithStoreOnRoute} from '../../common/store-on-route';
import Pagination from '../../common/table/pagination';
import {HistoryConstants} from '../browse-history-actions';

require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('datatables.net-responsive-bs');

class Table extends Component {
    static get propTypes() {
        return {
            activePage: PropTypes.number,
            history: PropTypes.instanceOf(Immutable.List).isRequired,
            onPageChange: PropTypes.func,
            onShowHistoryDetailModal: PropTypes.func,
            totalPages: PropTypes.number,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
            router: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            activePage: 0,
            onPageChange: undefined,
            onShowHistoryDetailModal: undefined,
            totalPages: 0
        };
    }

    constructor(props) {
        super(props);
        this.isJson = this.isJson.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
    }

    componentDidMount() {
        this.$table = jQuery('#history-table');
        this.$tableAPI = this.$table.DataTable({
            autoWidth: false,
            columnDefs: [{
                // Add the control class to the last column. This colum will
                // contain the button to show the responsive data.
                className: 'control',
                targets:   -1,
                width: 20
            }, {
                className: 'Whs(nw)',
                targets: 'no-wrap'
            }, {
                targets: 5,
                render:function(data) {
                    let dataToShow = data.detail.length > 85 ? `${data.detail.substr(0, 65)}...` : data.detail;
                    return `${dataToShow}
                    <a class="show-detail-modal"
                       href="#"
                       id="${data.historyItemId}"
                       style="position: absolute; right: 3%;">
                       <i class="fas fa-external-link-alt" id="${data.historyItemId}"></i>
                    </a>`;
                }
            }],
            iDisplayLength: 1,
            info: false,
            ordering: false,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        });
        // Register global listeners.
        window.addEventListener('resize', this.handleResize);
        // And trigger the resize handler once so that the datatable
        // knows its initial dimensions.
        this.handleResize();
        return;
    }

    componentWillUpdate(nextProps) {
        this.$tableAPI.clear();

        let historyList = nextProps.history;

        historyList.forEach((item) => {

            let actionDate = Moment(item.get('actionDate'));
            if (actionDate.isValid()) {
                actionDate = actionDate.format(this.context.intl.messages['datetime-format']);
            } else {
                actionDate = '-';
            }

            let actorName = item.get('actor', '');
            let action = item.get('actionHistoryType', '');
            let objectType = item.get('actionObjectType', '');

            let objectDescription = item.get('actionObjectDescription', '');
            let objectHistoryType = HistoryConstants.FILTERS.OBJECT_TYPE_OPTIONS[objectType];
            let notFound = /^Object Not Found/.test(objectDescription);
            if (objectHistoryType && objectHistoryType.getHistoryObjectItemUrl && !notFound) {
                let hrefUrl = '';
                if (objectType === 'AssetToCatalog' && item.get('actionObjectBody')) {
                    let temporalObjectType = item.get('actionObjectBody').get('displayAssetTypeName');
                    hrefUrl = HistoryConstants.FILTERS.OBJECT_TYPE_OPTIONS[temporalObjectType].getHistoryObjectItemUrl(item.get('objectId'));
                } else {
                    hrefUrl = objectHistoryType.getHistoryObjectItemUrl(item.get('objectId'));
                }
                objectDescription = `<a href=${hrefUrl}>${objectDescription}</a>`;
            }

            let detailList = item.get('changeDescription', '');
            /* istanbul ignore if */
            if (this.isJson(detailList)) {
                let detailChanges = JSON.parse(detailList);

                let changes = '';
                if (!Array.isArray(detailChanges.changedFields)) {
                    for (let prop in detailChanges) {
                        changes += `${prop}`;
                    }
                } else {
                    detailChanges.changedFields.forEach((detailItem) => {
                        changes += `${detailItem.fieldName} `;
                    });
                }
                detailList = `Attributes: ${changes}`;
            }
            let detail = {
                detail: detailList,
                historyItemId: item.get('id')
            };

            let row = [];
            row.push(
                actionDate,
                actorName,
                objectDescription,
                objectType,
                action,
                detail,
                ''
            );

            this.$tableAPI.row.add(row);

        });

        this.$tableAPI.draw(false);

        this.handleResize();

        return false;
    }

    componentWillUnmount() {
        // Remove global listeners.
        window.removeEventListener('resize', this.handleResize);
    }

    /* istanbul ignore next */
    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    handleResize() {
        this.$tableAPI.responsive.recalc();
        return;
    }

    /**
    * This function is kind of "special" because it needs to handle
    * events bubbled from the data table rows, these rows cannot use
    * the JSX syntax because they are created by the data table
    **/
    handleRowClick(event) {
        if (event.target.className === 'fas fa-external-link-alt' ||
            event.target.className === 'show-detail-modal') {

            // Prevent the default anchor click event.
            event.preventDefault();

            this.props.onShowHistoryDetailModal(event.target.id);
        }

        return;
    }

    render() {
        let pagination = (
            <div className="row">
                <div className="col-sm-7 col-sm-offset-5">
                    <Pagination
                        activePage={this.props.activePage}
                        onChange={this.props.onPageChange}
                        totalPages={this.props.totalPages}
                    />
                </div>
            </div>
        );

        return (
            <div>
                <table id="history-table" className="table table-bordered table-striped responsive">
                    <thead>
                        <tr>
                            <th>{this.context.intl.messages['history.browse.date-time']}</th>
                            <th>{this.context.intl.messages['history.browse.admin']}</th>
                            <th>{this.context.intl.messages['history.browse.object']}</th>
                            <th>{this.context.intl.messages['history.browse.object-type']}</th>
                            <th>{this.context.intl.messages['history.browse.action']}</th>
                            <th>{this.context.intl.messages['history.browse.detail']}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody onClick={this.handleRowClick}>
                    </tbody>
                </table>
                {pagination}
            </div>
        );
    }

}

export default WithStoreOnRoute(Table);
