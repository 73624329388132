/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import QueryString from 'querystring-es3';
import React, {Component} from 'react';

import ListUsers from '../../../common/list-users/list-users';
import {Debounce} from '../../../common/utils/utils';
import {RouterActions} from '../../../router/router-actions';
import {UserActions} from '../../../user/user-actions';
import UserStore from '../../../user/user-store';

class Users extends Component {
    static get propTypes() {
        return {
            displayLinks: PropTypes.bool,
            location: PropTypes.object.isRequired,
            titleId: PropTypes.number.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        const us = UserStore.getState();
        return {
            activePage: us.get('activePage'),
            total: us.get('total'),
            totalPages: us.get('totalPages'),
            users: us.get('users'),
        };
    }

    static getStores() {
        return [UserStore];
    }

    static get defaultProps() {
        return {
            displayLinks: false
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            filters: Immutable.Map(),
            size: 20,
            sortDirection: 'asc',
            sortFieldName: 'name',
        }, this.constructor.calculateState());

        this.handleSearchChange = Debounce(this.handleSearchChange.bind(this), 200);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.handleUsersTabPageChange = this.handleUsersTabPageChange.bind(this);
    }

    componentDidMount() {
        UserActions.findAllByTitleId(
            this.props.titleId,
            this.state.filters,
            this.props.location.query.offset,
            this.props.location.query.size,
            this.props.location.query['sort-field-name'],
            this.props.location.query['sort-direction']);
        return;
    }

    componentWillUpdate(nextProps, nextState) {
        if (this.props.titleId !== nextProps.titleId ||
            this.state.filters !== nextState.filters ||
            this.props.location !== nextProps.location) {
            UserActions.findAllByTitleId(
                nextProps.titleId,
                nextState.filters,
                nextProps.location.query.offset,
                nextProps.location.query.size,
                nextProps.location.query['sort-field-name'],
                nextProps.location.query['sort-direction']);
        }
        return;
    }

    handleSearchChange(term) {
        this.setState({
            filters: Immutable.fromJS({
                'email': term,
                'company': term,
                'name': term
            })
        });
        return;
    }

    handleSortChange(sortFieldName, sortDirection) {
        this.setState({
            sortDirection: sortDirection,
            sortFieldName: sortFieldName
        });
        let query = Object.assign(
            {},
            this.props.location.query,
            {
                'sort-field-name': sortFieldName,
                'sort-direction': sortDirection,
                'keep-scroll': true
            }
        );

        RouterActions.redirect(`${this.props.location.pathname}?${QueryString.stringify(query)}`);
        return;
    }

    handleUsersTabPageChange(pageNumber) {
        let query = Object.assign(
            {},
            this.props.location.query,
            {
                'offset': pageNumber*this.state.size,
                'size': this.state.size
            }
        );

        RouterActions.redirect(`${this.props.location.pathname}?${QueryString.stringify(query)}`);

        return;
    }

    render() {
        return (
            <div>
                <h3><i className="fas fa-user"></i>&nbsp;{this.context.intl.messages['titles.create.users-tab-title']}&nbsp;<small>{this.state.total}</small></h3>

                <hr/>

                <ListUsers
                    activePage={this.state.activePage}
                    columns={['name', 'company', 'email', 'status', 'clientRepGroup']}
                    displayLinks={this.props.displayLinks}
                    onSortChange={this.handleSortChange}
                    onPageChange={this.handleUsersTabPageChange}
                    onSearchChange={this.handleSearchChange}
                    sortFieldName={this.state.sortFieldName}
                    sortDirection={this.state.sortDirection}
                    totalPages={this.state.totalPages}
                    users={this.state.users}
                />
            </div>
        );
    }
}

export default Container.create(Users);
