/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';

import {AssetTabActions} from '../../assets-tab/asset-tab-actions';
import {AlertTypes} from '../../common/notification/alert';
import {NotificationActions} from '../../common/notification/notification-actions';
import {SlidingPanelActions} from '../../common/sliding-panel/sliding-panel-actions';
import {UploadFile} from '../../common/utils/utils';
import Dispatcher from '../../dispatcher/dispatcher';
import Request from '../../request';
import {RouterActions} from '../../router/router-actions';
import {ActionHistoryConstants} from '../../system/action-history/action-history-actions';
import {AssetTalentActions} from '../asset-talent-actions';
import {AssetCatalogActions} from '../catalogs/asset-catalog-actions';

const CONSTANTS = {
    DOCUMENT: {
        ADD: 'asset.document.add',
        CLEAR: 'asset.document.clear',
        GET: {
            ERROR: 'asset.document.get.error',
            START: 'asset.document.get.start',
            SUCCESS: 'asset.document.get.success'
        },
        SAVE: {
            ERROR: 'asset.document.save.error',
            START: 'asset.document.save.start',
            SUCCESS: 'asset.document.save.success'
        },
        UPDATE: 'asset.document.update'
    },
};

export const STATION_TYPE = 'station';

class DocumentActions {
    clear() {
        setTimeout(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.DOCUMENT.CLEAR
            });
        }, 0);
    }

    findById(documentId) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.DOCUMENT.GET.START,
            documentId: documentId,
        });

        let actionHistoryQuery = {
            'action-object': ActionHistoryConstants.ACTION_OBJECTS.DOCUMENT,
            'object-id': documentId,
            offset: 0,
            size: 4
        };

        Promise.all([
            Request.get(`asset/document/${documentId}`).exec(),
            Request.get('system/action-history').query(actionHistoryQuery).exec()
        ]).spread((doc, history) => {
            doc = doc.body;
            history = history.body.results;
            history.sort((h1, h2) => h2.actionDate.localeCompare(h1.actionDate));
            doc.active = doc.active === 1;

            Dispatcher.dispatch({
                actionType: CONSTANTS.DOCUMENT.GET.SUCCESS,
                asset: Immutable.fromJS(doc),
                history: Immutable.fromJS(history)
            });
            return;
        }).catch((err) => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.DOCUMENT.GET.ERROR,
                error: err
            });
            switch (err.status) {
            case 404:
                RouterActions.notFound();
                break;
            default:
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'asset.document.load-error');
                break;
            }
            throw err;
        });

        return;
    }

    save(asset, documentFile, assignedCatalogs, originalAssignedCatalogs, talent, originalTalent, options, originalDocument) {
        let defaults = {
            messages: {
                error: 'asset.document.create.error',
                success: 'asset.document.create.success'
            }
        };
        Object.assign(defaults, options);

        let id;

        Dispatcher.dispatch({
            actionType: CONSTANTS.DOCUMENT.SAVE.START
        });

        this._save(asset, documentFile, originalDocument)
            .spread((res, documentFileRes) => {
                id = res.body.id;
                return Promise.all([
                    documentFileRes,
                    ...AssetCatalogActions.assetCatalogSave(id, assignedCatalogs, originalAssignedCatalogs),
                    ...AssetTalentActions.assetTalentSave(id, talent, originalTalent),
                ]);
            })
            .spread(documentFileRes => {
                RouterActions.redirect(`/assets/document/${id}`, true);
                if (documentFileRes) {
                    let fileResp = JSON.parse(documentFileRes);
                    this.update('uploadedFile', fileResp.uploadedFile);
                }
                Dispatcher.dispatch({
                    actionType: CONSTANTS.DOCUMENT.SAVE.SUCCESS,
                    id,
                    documentFile: documentFile[0]
                });
                NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, defaults.messages.success);
                return;
            })
            .catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.DOCUMENT.SAVE.ERROR
                });

                // If asset has been created, then redirect to Edit Asset page
                // so that successive calls to save don't generate any new assets.
                if (err.assetId) {
                    RouterActions.redirect(`/assets/document/${err.assetId}`, true);
                    NotificationActions.showAlertDanger('asset.error.incomplete-save');
                    throw err;
                }

                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, defaults.messages.error);
                throw err;
            });
        return;
    }

    saveAndAdd(asset, documentFile, addConstants, assignedCatalogs, originalAssignedCatalogs) {
        let defaults = {
            messages: {
                error: 'asset.document.create.error',
                success: 'asset.document.create.success'
            }
        };

        Dispatcher.dispatch({
            actionType: CONSTANTS.DOCUMENT.SAVE.START
        });

        this._save(asset, documentFile)
            .spread((res, documentFileRes) => {
                if (documentFileRes) {
                    let fileResp = JSON.parse(documentFileRes);
                    this.update('uploadedFile', fileResp.uploadedFile);
                }

                const addCatalogs = AssetCatalogActions.assetCatalogSave(res.body.id, assignedCatalogs, originalAssignedCatalogs);

                return Promise.props({
                    catalogs: Promise.all(addCatalogs),
                    documentResponse: res
                });

            }).then(response => {
                const {documentResponse} = response;

                Dispatcher.dispatch({
                    actionType: CONSTANTS.DOCUMENT.SAVE.SUCCESS,
                    id: documentResponse.body.id
                });
                asset = asset.set('id', documentResponse.body.id);

                SlidingPanelActions.hide('addNew');
                AssetTabActions.add('document', Immutable.List([asset]), addConstants);
                NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, defaults.messages.success);
                return;
            })
            .catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.DOCUMENT.SAVE.ERROR
                });
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, defaults.messages.error);
                throw err;
            });
        return;
    }

    _save(asset, documentFile, originalDocument) {
        let documentData = asset.toJS();
        ['history', 'titles', 'files'].forEach(p => delete documentData[p]);
        if (documentFile) {
            documentFile = documentFile[0];
        }
        let id = asset.get('id');
        let fileExists = asset.get('documentURL');
        let method = Request.post;
        let uri = 'asset/document';
        if (documentFile === originalDocument) {
            documentFile = null;
        }

        if (documentData.active) {
            documentData.active = 1;
        } else {
            documentData.active = 0;
        }

        if (id !== undefined) {
            method = Request.put;
            uri = `asset/document/${id}`;
        }

        let uploadMethod = 'POST';
        if (fileExists) {
            uploadMethod = 'PUT';
        }
        return method(uri).send(documentData).exec().then(res => {
            if (documentFile) {
                let upload = UploadFile(
                    uploadMethod,
                    `asset/document/${res.body.id}/file`,
                    documentFile,
                    new XMLHttpRequest()
                ).catch(e => {
                    throw {
                        assetId: res.body.id,
                        e
                    };
                });

                return [res, upload];
            } else {
                return [res];
            }
        });
    }

    update(attr, value) {
        setTimeout(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.DOCUMENT.UPDATE,
                attr: attr,
                value: value
            });
        }, 0);
        return;
    }
}

let actions = new DocumentActions();

export {
    actions as DocumentActions,
    CONSTANTS as DocumentConstants
};
