/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

import Table from './table';
import DocumentTitle from '../../common/document-title';
import SearchBox from '../../common/search-box/search-box';
import {WithStoreOnRoute} from '../../common/store-on-route';
import {Debounce} from '../../common/utils/utils';
import {LayoutActions} from '../../layout/layout-actions';
import LayoutStore from '../../layout/layout-store';
import Preloader from '../../preloader';
import SessionStore from '../../user/session/session-store';
import {BrandActions} from '../brand-actions';
import BrandStore from '../brand-store';
import FilterOptions from '../filter-options/filter-options';

class Browse extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static getPermissions() {
        return {
            canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.BRANDS.BROWSE),
        };
    }

    static calculateState() {
        const bs = BrandStore.getState();
        return {
            brands: bs.get('brands'),
            filters: bs.get('filters'),
            showFiltersPanel: LayoutStore.getState().get('showFiltersPanel'),
            showPreloader: bs.get('showPreloader'),
            total: bs.get('total')
        };
    }

    static getStores() {
        return [BrandStore, LayoutStore];
    }

    constructor(props) {
        super(props);

        this.state = {
            sortFieldName: 'NAME',
            sortDirection: 'desc'
        };

        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
        this.handleSortChange = this.handleSortChange.bind(this);
    }

    componentDidMount() {
        BrandActions.clear();
        BrandActions.get(this.getRouteState());
        return;
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            BrandActions.get(this.getRouteState(this.props));
        }
        return;
    }

    handleSearchTerm(term) {
        this.setRouteState('name', term)
            .setRouteState('operator', 'AND')
            .clearRouteState('offset')
            .apply();
        return;
    }

    handleSortChange(sortFieldName, sortDirection) {
        this.setState(() => ({
            sortFieldName,
            sortDirection
        }));
        return;
    }

    handleToggleFiltersPanel() {
        LayoutActions.toggleFiltersPanel();
        return;
    }

    render() {
        return (
            <DocumentTitle
                message="document-titles.brands"
            >
                <div className={ClassNames({'control-sidebar-open': this.state.showFiltersPanel})}>
                    <div className={ClassNames({'sidebar-opened': this.state.showFiltersPanel})}>
                        <Preloader show={this.state.showPreloader} fixed loadingDots>
                            <section className="content-header">
                                <h1>
                                    <i className="fas fa-tag"></i>&nbsp;{this.context.intl.messages['brands.browse.title']}
                                    &nbsp;<small>{this.state.total}</small>
                                </h1>
                            </section>
                            <section className="content">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="container-fluid no-x-padding">
                                            <div className="row">
                                                <div className="col-md-6 show-grid">
                                                    <SearchBox onChange={this.handleSearchTerm} value={this.getRouteState().get('name')}/>
                                                </div>
                                                <div className="col-md-6 show-grid">
                                                    <Button onClick={this.handleToggleFiltersPanel} bsStyle="primary" bsSize="large" className="pull-right">
                                                        <i className="fas fa-sliders-h"></i>&nbsp;{this.context.intl.messages['common.filteringOptions']}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container-fluid no-x-padding">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="box">
                                                        <div className="box-body">
                                                            <Table
                                                                activePage={Math.ceil((this.getRouteState().get('offset') || 0) / 20 ) || 0}
                                                                brands={this.state.brands}
                                                                displayLinks={this.props.permissions.canEdit}
                                                                onSortChange={this.handleSortChange}
                                                                sortDirection={this.state.sortDirection}
                                                                sortFieldName={this.state.sortFieldName}
                                                                totalPages={Math.ceil(this.state.total/20) || 0}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <FilterOptions
                                location={this.props.location}
                            />
                        </Preloader>
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}

export default WithStoreOnRoute(Container.create(Browse));
