/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, Col, Row} from 'react-bootstrap';

import {MassUpdateActions} from './mass-update-actions';
import MassUpdateStore from './mass-update-store';
import UserTable from './user-table';
import {AlertsWarnings} from '../../common/validations/alerts-warnings';
import ActionsMenu from '../../layout/actions-menu/actions-menu';
import StickyHeader from '../../layout/sticky-header/sticky-header';
import {RouterActions} from '../../router/router-actions';

class ReviewUpdate extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            params: PropTypes.object.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            massUpdateProcess: MassUpdateStore.getState().get('massUpdateProcess'),
            massUpdateUsers: MassUpdateStore.getState().get('users'),
        };
    }

    static getStores() {
        return [MassUpdateStore];
    }

    constructor(props) {
        super(props);

        this.handleUpdatePermissions = this.handleUpdatePermissions.bind(this);
        this.handleUpdateStatus = this.handleUpdateStatus.bind(this);
    }

    componentDidMount() {
        MassUpdateActions.findById(this.props.params.id);

        return;
    }

    handleCancel() {
        RouterActions.redirect('/accounts/mass-update');
    }

    handleUpdatePermissions(mode, massModeStep) {
        MassUpdateActions.confirmPermissions(this.props.params.id, mode, massModeStep);
    }

    handleUpdateStatus() {
        const query = this.props.location.query;
        if (query.mode) {
            RouterActions.redirect(`/accounts/mass-update/${this.props.params.id}/users-status?mode=${query.mode}`);
        } else {
            RouterActions.redirect(`/accounts/mass-update/${this.props.params.id}/users-status`);
        }
    }

    render() {
        let massMode = 'update';
        let totalUsers = '-';

        //to use in steps texts
        let massModeStep = massMode;
        const queryMode = this.props.location.query.mode;
        if (queryMode) {
            massModeStep = queryMode;
        }

        const validations = MassUpdateStore.getValidations();

        if (this.state.massUpdateUsers.size) {
            totalUsers = this.state.massUpdateUsers.size;
        }

        const actionsMenu = <ActionsMenu onCancel={this.handleCancel}>
            <Button
                bsSize="large"
                className="btn btn-primary-outline Mr(3px) Mb(3px)"
                disabled={validations.length}
                onClick={this.handleUpdatePermissions.bind(this, 'update', massModeStep)}
            >
                <i className="fas fa-plus"/>&nbsp;{this.context.intl.messages['accounts.mass-update.button.add']}
            </Button>
            <Button
                bsSize="large"
                className="btn btn-primary-outline Mr(3px) Mb(3px)"
                disabled={validations.length}
                onClick={this.handleUpdateStatus.bind(this, 'update')}
            >
                <i className="fas fa-plus"/>&nbsp;{this.context.intl.messages['accounts.mass-update.button.change-status']}
            </Button>
            <Button
                bsSize="large"
                className="btn btn-danger-outline Mr(3px) Mb(3px)"
                disabled={validations.length}
                onClick={this.handleUpdatePermissions.bind(this, 'remove', massModeStep)}
            >
                <i className="fas fa-times"/>&nbsp;{this.context.intl.messages['accounts.mass-update.button.remove']}
            </Button>
        </ActionsMenu>;

        const users = this.state.massUpdateUsers.toJS().map(user => {
            user.groups = user.groups.filter(g => g.isActive === 1);
            return user;
        });

        const userTable = <UserTable pageSize={20} users={Immutable.fromJS(users)}/>;

        return (
            <div>
                <StickyHeader>
                    <div className="col-md-6">
                        <h1>
                            <i className="fas fa-user"></i>&nbsp;{this.context.intl.messages['accounts.mass-update.title']}
                            &nbsp;
                            &nbsp;<small>{totalUsers} {this.context.intl.messages['accounts.mass-update.accounts-length']}</small>
                        </h1>
                    </div>
                    {actionsMenu}
                </StickyHeader>
                <section className="content" id="contentContainer">
                    <Row>
                        <Col xs={9}>
                            <div className="box">
                                <div className="box-body padding-x-20">
                                    <h3>{this.context.intl.messages[`accounts.mass-${massModeStep}.step.review-uploaded.title`]}</h3>
                                    {userTable}
                                </div>
                            </div>
                        </Col>
                        <Col xs={3}>
                            <AlertsWarnings title={this.context.intl.messages['common.alerts-warnings-title']} stores={[MassUpdateStore]}/>
                        </Col>
                    </Row>
                </section>
            </div>
        );
    }
}

export default Container.create(ReviewUpdate);
