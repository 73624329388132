/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {LanguageCodeType} from '@wbdt-sie/brainiac-web-common';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, OverlayTrigger, Tooltip} from 'react-bootstrap';
import ReactTable from 'react-table';

import AddTalentModal from './add-talent-modal';
import AKADropdown from './aka-dropdown';
import {EVENT_BLUR, LocalizedFormItem} from '../../../common/form/form';
import {Debounce} from '../../../common/utils/utils';
import {TalentActions, TalentConstants} from '../../../talent/talent-actions';
import {TitleActions} from '../../title-actions';
import {TitleLocalizedActions} from '../../title-localized-actions';

import 'react-table/react-table.css';

export default class Table extends React.Component {
    static get propTypes() {
        return {
            currentLanguage: PropTypes.string.isRequired,
            disabled: PropTypes.bool.isRequired,
            label: PropTypes.string.isRequired,
            talent: PropTypes.instanceOf(Immutable.List).isRequired,
            talentRoleType: PropTypes.object.isRequired,
            title: PropTypes.instanceOf(Immutable.Map).isRequired,
            titleLocalized: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            id: Math.ceil(Math.random() * 100000),
            modalMode: 'add',
            showModal: false,
            talentIndex: undefined
        };
    }

    static get defaultProps() {
        return {
            disabled: true
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.handleAdd = this.handleAdd.bind(this);
        this.handleRemoveTalent = this.handleRemoveTalent.bind(this);
        this.handleReorder = this.handleReorder.bind(this);
        this.handleSearchTalent = Debounce(this.handleSearchTalent.bind(this), 200);
        this.handleUpdateCharacterName = this.handleUpdateCharacterName.bind(this);
        this.updatePosition = this.updatePosition.bind(this);
        this.toggleAddExisting = this.toggleAddExisting.bind(this);
    }

    handleAdd(talent) {
        TitleActions.addOrReplaceTalent(
            this.props.talentRoleType,
            talent
        );
        return;
    }

    handleRemoveTalent(event) {
        TitleActions.removeElement(
            `talent.${this.props.talentRoleType.id}`,
            parseInt(event.target.getAttribute('data-index'), 10),
            'orderInTitle'
        );
        return;
    }

    handleReorder(from, to) {
        let talentRoleType = this.props.talent.get(0).get('roleInTitle');
        setTimeout(() => {
            TitleActions.moveElement(`talent.${talentRoleType}`, 'orderInTitle', from, to);
        }, 0);

        return;
    }

    handleSearchTalent(language) {
        TalentActions.get(
            Immutable.Map({
                language
            })
        );
        return;
    }

    handleUpdateCharacterName(index, item, value) {
        if (this.props.currentLanguage !== LanguageCodeType.DEFAULT_LANGUAGE) {
            TitleLocalizedActions.updateCharacterName(item, value, this.props.talent.get(index));
        }
        return;
    }

    toggleAddExisting(mode) {
        this.setState(prevState => {
            let state = {
                modalMode: mode,
                showModal: !prevState.showModal
            };

            if (prevState.showModal) {
                state.talentIndex = undefined;
            }

            return state;
        });
        return;
    }

    /*dragFrom and position are the location into the page (in pixels) before and after dragging the element,
    substract both values and divide by row height. Row.index is the initial position and dragDiff the new one*/
    updatePosition(e, row, position) {
        if (e.target.draggable === true) {
            let dragDiff = Math.round((parseInt(this.state.dragFrom, 10) - parseInt(position, 10)) / 50);
            dragDiff = -(dragDiff);
            let newPosition = row.index + dragDiff;
            this.handleReorder(row.index, Math.abs(newPosition));
        }
    }

    render() {
        let localized = this.props.currentLanguage !== LanguageCodeType.DEFAULT_LANGUAGE;
        let titleLocalized = this.props.titleLocalized.get(this.props.currentLanguage);
        let modal;
        let pageSize = this.props.talent.size || 0;
        let tooltip = (<Tooltip id="thumbnail">{this.context.intl.messages['titles.create.talent.list.thumbnail-tooltip']}</Tooltip>);

        if (this.state.showModal) {
            modal = (
                <AddTalentModal
                    currentLanguage={this.props.currentLanguage}
                    mode={this.state.modalMode}
                    onAdd={this.handleAdd}
                    onHide={this.toggleAddExisting}
                    show={this.state.showModal}
                    titleId={this.props.title.get('id')}
                    titleLocalized={this.props.titleLocalized}
                    existingTalentForRoleType={this.props.talent}
                />
            );
        }

        let characterNameOrComment = 'titles.create.talent.list.character-name';
        switch (this.props.talentRoleType.id) {
        case TalentConstants.TALENT_ROLES_TYPES.AUTHOR.id:
        case TalentConstants.TALENT_ROLES_TYPES.CHOREOGRAPHER.id:
        case TalentConstants.TALENT_ROLES_TYPES.CREATOR.id:
        case TalentConstants.TALENT_ROLES_TYPES.DIRECTOR.id:
        case TalentConstants.TALENT_ROLES_TYPES.EXECUTIVE_PRODUCER.id:
        case TalentConstants.TALENT_ROLES_TYPES.MUSICIAN.id:
        case TalentConstants.TALENT_ROLES_TYPES.PRODUCER.id:
        case TalentConstants.TALENT_ROLES_TYPES.WRITER.id:
            characterNameOrComment = 'titles.create.talent.list.comment';
            break;
        }

        let columns = [{
            Header: <strong>#</strong>,
            headerClassName: 'h-alignment',
            className: 'h-alignment row-reorder-handle',
            width: 60,
            Cell: (t) => {
                return (
                    <div data-index={t.index}>{t.index + 1}</div>
                );
            },
        }, {
            Header: <OverlayTrigger placement="top" overlay={tooltip}><i className="far fa-image"></i></OverlayTrigger>,
            headerClassName: 'h-alignment',
            className: 'h-alignment',
            width: 60
        }, {
            Header: <strong>{this.context.intl.messages['titles.create.talent.list.name']}</strong>,
            accessor: row => row.get('displayTalentFullName'),
            id: 'displayTalentFullName',
        }, {
            Header: <strong>{this.context.intl.messages['titles.create.talent.list.credited-as']}</strong>,
            className: 'select-alignment',
            Cell: t => {
                let defaultLanguage;
                let value = t.original;
                if (localized) {
                    defaultLanguage = (
                        <div className="credited-as-hint">
                            <small>
                                <strong>English:</strong>
                                &nbsp;<em>{t.original.get('displayAKAFullName') || t.original.get('fullName')}</em>
                            </small>
                        </div>
                    );
                    let talentId = t.original.get('talentId');
                    value = titleLocalized.getIn(
                        ['talent', this.props.talentRoleType.id.toString()], Immutable.List()
                    ).filter(
                        aka => !!aka
                    ).find(
                        aka => aka.get('talentId') === talentId
                    ) || null; // The select needs null in order to clear the element. Undefined is not enough.
                }
                return (
                    <div style={{marginTop: '5px'}}>
                        <AKADropdown
                            className="credited-as"
                            currentLanguage={this.props.currentLanguage}
                            disabled={false}
                            talent={t}
                            talentRoleType={this.props.talentRoleType}
                            value={value}
                        />
                        {defaultLanguage}

                    </div>
                );
            }
        }, {
            Header: <strong>{this.context.intl.messages[characterNameOrComment]}</strong>,
            className: 'h-alignment',
            Cell: (t) => {
                let localizedAKAIndex = -1;
                if (titleLocalized) {
                    const talentsLocalized = titleLocalized.get('talent');
                    let localizedRoles = talentsLocalized.get(this.props.talentRoleType.id.toString());
                    if (localizedRoles) {
                        // FIXME: review why this could be a map and make it always a list. Probably something is not being saved the right way.
                        if (Immutable.Map.isMap(localizedRoles)) {
                            localizedRoles = localizedRoles.toList();
                        }
                        let talentId = t.original.get('talentId');
                        localizedAKAIndex = localizedRoles.findIndex(localizedTalent => {
                            return localizedTalent.get('talentId') === talentId;
                        });
                    }
                }
                return (
                    <LocalizedFormItem
                        attr={`talent.${this.props.talentRoleType.id}.${t.index}.characterNameInTitle`}
                        localizedAttr={`talent.${this.props.talentRoleType.id}.${localizedAKAIndex}.characterNameInTitle`}
                        data-index={t.index}
                        disabled={this.props.disabled}
                        model={this.props.title}
                        noLabel={true}
                        notifyEvent={EVENT_BLUR}
                        setter={TitleActions.updateTitle}
                        type="text"
                        localized={localized}
                        localizedModel={titleLocalized}
                        localizedSetter={this.handleUpdateCharacterName.bind(this, t.index)}
                        value={t.original.get('characterNameInTitle')}
                    />
                );
            },
        }, {
            Header: <strong>{this.context.intl.messages['titles.create.talent.list.actions']}</strong>,
            className: 'h-alignment',
            Cell: (t) => {
                let removeBtn = null;
                if (this.props.currentLanguage === LanguageCodeType.DEFAULT_LANGUAGE) {
                    removeBtn = <Button bsStyle="default" className="bg-navy remove-talent" data-index={t.index} onClick={this.handleRemoveTalent.bind(this)}><i className="fas fa-trash-alt"></i>&nbsp;{this.context.intl.messages['titles.create.talent.list.remove']}</Button>;
                }
                return removeBtn;
            },
            width: 100
        }];

        return (
            <div>
                <h3>{this.props.label}:&nbsp;{this.props.talent.size}<Button bsStyle="primary" className="pull-right" disabled={localized} onClick={this.toggleAddExisting.bind(this, 'add')}><i className="fas fa-plus-square"></i>&nbsp;{this.context.intl.messages['titles.create.talent.add-talent.add']}</Button></h3>
                <ReactTable
                    className={`-striped responsive title-talent-table title-talent-table-${this.state.id}`}
                    columns={columns}
                    data={this.props.talent}
                    getTrProps={() => {
                        return {
                            draggable: false
                        };
                    }}
                    getTdProps={(state, row, td) => {
                        let isDraggable = td.className.indexOf('row-reorder-handle') !== -1;
                        return {
                            draggable: isDraggable,
                            onDragEnd: (e) => {
                                this.updatePosition(e, row, e.screenY);
                            },
                            onDragStart: (e) => {
                                e.dataTransfer.setData('application/node type', this);
                                // Note: do not refactor this to the function form of setState, it will break the reorder functionality,
                                // due to the event recycling and e.screenY being undefined.
                                this.setState({
                                    dragFrom: e.screenY
                                });
                            }
                        };
                    }}
                    getNoDataProps= {() => {
                        if (this.props.talent.size) {
                            return {style: {display: 'none'}};
                        }
                        return {};
                    }}
                    id={`title-talent-table-${this.state.id}`}
                    loading={false}
                    pageSize={pageSize}
                    showPagination={false}
                    sortable={false}
                    resizable={false}
                />
                {modal}
            </div>
        );
    }
}
