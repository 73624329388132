/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Moment from 'moment';
import QueryString from 'querystring-es3';

import {AlertTypes} from '../common/notification/alert';
import {NotificationActions} from '../common/notification/notification-actions';
import Dispatcher from '../dispatcher/dispatcher';
import {RouterActions} from '../router/router-actions';

const CONSTANTS = {
    DASHBOARD_OPTIONS: {
        ALL: '/dashboard?active=ACTIVE&S0=RELEASE_DATE_DESC',
        METADATA: '/dashboard?active=ACTIVE&S0=RELEASE_DATE_DESC&operator=AND&hidden=4&hidden=5&hidden=6&hidden=8&hidden=7&hidden=9&hidden=14&hidden=15&hidden=30&hidden=12',
        PHOTO: '/dashboard?active=ACTIVE&S0=RELEASE_DATE_DESC&operator=AND&hidden=12&hidden=14&hidden=10&hidden=15&hidden=17&hidden=28&hidden=19&hidden=20&hidden=18&hidden=16&hidden=21&hidden=23&hidden=24&hidden=27&hidden=25&hidden=26',
        VIDEO: '/dashboard?active=ACTIVE&S0=RELEASE_DATE_DESC&hidden=4&hidden=5&hidden=6&hidden=7&hidden=8&hidden=9&hidden=10&hidden=16&hidden=17&hidden=19&hidden=18&hidden=20&hidden=23&hidden=21&hidden=30&hidden=28&hidden=27&hidden=24&hidden=26&hidden=25&operator=AND'
    },
    DEFAULT_DASHBOARD_FILTER: '/dashboard?active=ACTIVE&S0=RELEASE_DATE_DESC',
    SAVED_DASHBOARD_FILTERS: '__brainiacDashboardFilters-',
    TOGGLE: {
        LAST_WEEK_FILTER: 'dashboard_actions.toggle.last_week_filter'
    },
};

// STUDIO-9453: This start/end dates logic mirrors how wbtvd new this week works: /apps/wbtvd/src/home/sections/new-this-week/index.js
CONSTANTS.END_DATE = Moment().format('YYYY-MM-DD');
CONSTANTS.START_DATE = Moment().subtract(6, 'days').format('YYYY-MM-DD');

CONSTANTS.getDefaultDashboardFilter = () => {
    return CONSTANTS.DEFAULT_DASHBOARD_FILTER;
};

CONSTANTS.getLastWeekDashboardFilter = (filters) => {
    let defaultDashboardFilter = CONSTANTS.DEFAULT_DASHBOARD_FILTER;
    const endReleaseDate = CONSTANTS.END_DATE;
    const startReleaseDate = CONSTANTS.START_DATE;
    if (filters) {
        defaultDashboardFilter = filters;
    }
    return `${defaultDashboardFilter}&start-release-date=${startReleaseDate}&end-release-date=${endReleaseDate}`;
};

class DashboardActions {

    clearSavedFilters(id) {
        localStorage.removeItem(`${CONSTANTS.SAVED_DASHBOARD_FILTERS}${id}`);
        RouterActions.redirect(CONSTANTS.DEFAULT_DASHBOARD_FILTER);
        NotificationActions.showAlert(
            AlertTypes.ALERT_SUCCESS.name,
            'dashboard.filters.cleared'
        );
        return;
    }

    getDashboardFilters(applyLastWeekFilter, savedFilters) {
        let filters = savedFilters || CONSTANTS.getDefaultDashboardFilter();
        if (applyLastWeekFilter) {
            filters = CONSTANTS.getLastWeekDashboardFilter(savedFilters);
        }
        return filters;
    }

    getDashboardLink(applyLastWeekFilter, savedFilters) {
        let filters;
        let defaultFilters = CONSTANTS.getDefaultDashboardFilter();

        if (savedFilters === null && !applyLastWeekFilter) {
            filters = defaultFilters;
        } else if (savedFilters === null && applyLastWeekFilter) {
            filters = CONSTANTS.getLastWeekDashboardFilter(defaultFilters);
        } else if (savedFilters !== null && applyLastWeekFilter) {
            delete savedFilters?.['end-release-date'];
            delete savedFilters?.['start-release-date'];
            filters = CONSTANTS.getLastWeekDashboardFilter(`dashboard?${QueryString.stringify(savedFilters)}`);
        } else {
            filters = `dashboard?${QueryString.stringify(savedFilters)}`;
        }

        return filters;
    }

    saveDashboardFilters(id, query, applyLastWeekFilter) {
        localStorage.setItem(`${CONSTANTS.SAVED_DASHBOARD_FILTERS}${id}`, JSON.stringify(query));
        const filters = this.getDashboardLink(applyLastWeekFilter, query);
        RouterActions.redirect(filters);
        NotificationActions.showAlert(
            AlertTypes.ALERT_SUCCESS.name,
            'dashboard.filters.saved',
        );
        return;
    }

    toggleLastWeekFilter(isChecked) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TOGGLE.LAST_WEEK_FILTER,
            isChecked
        });
        return;
    }
}

const dashboardActions = new DashboardActions();

export {
    dashboardActions as DashboardActions,
    CONSTANTS as DashboardConstants
};
