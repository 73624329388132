/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {LanguageCodeType} from '@wbdt-sie/brainiac-web-common';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import ReactSelect from 'react-select';

import {FormSection, FormRow, FormItem} from '../../../common/form/form';
import {TalentActions} from '../../talent-actions';

const LOCALIZED_LANGUAGES = Object.keys(LanguageCodeType.LANGUAGES).map(l => ({
    label: LanguageCodeType.LANGUAGES[l],
    value: l
})).sort((a, b) => a.label.localeCompare(b.label));

class AKA extends React.Component {
    static get propTypes() {
        return {
            aka: PropTypes.instanceOf(Immutable.Map).isRequired,
            disabled: PropTypes.bool
        };
    }

    static get defaultProps() {
        return {
            disabled: false
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    handleLocalizationChange(aka, e) {
        TalentActions.updateAka(aka.get('displayOrder'))('languageCode', e.value);
    }

    handleRemove(aka) {
        TalentActions.removeAKA(aka);
    }

    render() {
        let order = this.props.aka.get('displayOrder');
        let removeButton;
        if (order !== 1) {
            removeButton = (
                <Button
                    bsStyle="default"
                    className="btn-sm btn-block pull-right"
                    onClick={this.handleRemove.bind(this, this.props.aka)}
                >
                    <i className="fas fa-trash-alt"/>&nbsp;{this.context.intl.messages['common.delete']}
                </Button>
            );
        }
        return (
            <div className="padding-bottom-20">
                <FormSection>
                    <Row>
                        <Col md={1}>
                            <span className="order-index">{order}</span>
                        </Col>
                        <Col md={3}>
                            <ReactSelect
                                clearable={false}
                                name="akaLanguageCode"
                                onChange={this.handleLocalizationChange.bind(this, this.props.aka)}
                                options={LOCALIZED_LANGUAGES}
                                value={LOCALIZED_LANGUAGES.filter(l => l.value === this.props.aka.get('languageCode'))[0]}
                                style={{bordeColor:'#09E', background: '#F0FAFF'}}
                            />
                        </Col>
                        <Col md={2} mdOffset={6}>
                            {removeButton}
                        </Col>
                    </Row>
                    <hr/>
                    <FormRow>
                        <FormItem attr="prefix"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['talent.create.talent-info.prefix']}
                            max={50}
                            md={1}
                            model={this.props.aka}
                            onlySpaces
                            setter={TalentActions.updateAka(order)}
                            type="text" />
                        <FormItem attr="firstName"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['talent.create.talent-info.first-name']}
                            max={50}
                            md={3}
                            model={this.props.aka}
                            onlySpaces
                            setter={TalentActions.updateAka(order)}
                            type="name" />
                        <FormItem attr="middleName"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['talent.create.talent-info.middle-name']}
                            max={50}
                            md={3}
                            model={this.props.aka}
                            onlySpaces
                            setter={TalentActions.updateAka(order)}
                            type="name" />
                        <FormItem attr="lastName"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['talent.create.talent-info.last-name']}
                            max={50}
                            md={4}
                            model={this.props.aka}
                            onlySpaces
                            setter={TalentActions.updateAka(order)}
                            type="name" />
                        <FormItem attr="suffix"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['talent.create.talent-info.suffix']}
                            max={50}
                            md={1}
                            model={this.props.aka}
                            onlySpaces
                            setter={TalentActions.updateAka(order)}
                            type="text" />
                    </FormRow>
                </FormSection>
            </div>
        );
    }
}

export default class AKAS extends React.Component {
    static get propTypes() {
        return {
            akas: PropTypes.instanceOf(Immutable.List),
            permissions: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            akas: Immutable.List()
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    handleAddAKA() {
        TalentActions.addAKA();
    }

    render() {
        return (
            <div>
                <h3>
                    <i className="fas fa-street-view"></i>&nbsp;{this.context.intl.messages['talent.edit.aka.title']}
                </h3>
                {this.props.akas.map(
                    aka => (<AKA aka={aka} key={`key_${aka.get('displayOrder')}`} {...this.props} />)
                )}
                <Row>
                    <Col md={4} mdOffset={4}>
                        <Button bsStyle="primary" className="btn-lg btn-block" onClick={this.handleAddAKA}>
                            <i className="fas fa-plus"/>&nbsp;{this.context.intl.messages['talent.edit.add-aka']}
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    }
}

export {
    AKA
};
