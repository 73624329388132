/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {FormGroup, ControlLabel} from 'react-bootstrap';
import Select from 'react-select';

import PresentationTable from './presentation-table';

import {FormSection, FormRow} from '~/src/common/form/form';
import {EventActions, EventConstants} from '~/src/events/event-actions';

class PresentationTab extends React.Component {

    static get propTypes() {
        return {
            disabled: PropTypes.bool,
            event: PropTypes.instanceOf(Immutable.Map).isRequired,
            location: PropTypes.object.isRequired,
            rowReorderDisabled: PropTypes.bool.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true
        };
    }

    constructor(props) {
        super(props);

        this.handleSelectArtworkOrientation = this.handleSelectArtworkOrientation.bind(this);
    }

    handleSelectArtworkOrientation(e) {
        if (!e) {
            EventActions.updateEvent('eventArtworkOrientationType', null);
            return;
        }

        EventActions.updateEvent('eventArtworkOrientationType', e.id);
    }

    render() {
        const artworkOrientationOptions = Object.keys(EventConstants.ARTWORK_ORIENTATION_TYPES).map(k => EventConstants.ARTWORK_ORIENTATION_TYPES[k]);
        const selectedArtworkOrientation = artworkOrientationOptions.find((o) => o.id === this.props.event.get('eventArtworkOrientationType'));

        return (
            <div className="event-presentation">
                <h3><i className="fa-regular fa-table-cells"></i> {this.context.intl.messages['events.presentation.title']}</h3>
                <p>
                    <em>
                        {this.context.intl.messages['events.presentation.title.description']}
                    </em>
                </p>

                <hr />

                <FormSection>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['events.presentation.artwork-orientation']}</ControlLabel>&nbsp;<span className="text-red">*</span>
                            <Select
                                isDisabled={this.props.disabled}
                                getOptionLabel={data => data.name}
                                getOptionValue={data => data.id}
                                isClearable={true}
                                name="eventArtworkOrientationType"
                                onChange={this.handleSelectArtworkOrientation}
                                options={artworkOrientationOptions}
                                value={selectedArtworkOrientation}
                            />
                        </FormGroup>
                        <FormGroup>
                            <div>
                                <strong>{this.context.intl.messages['events.presentation.artwork-orientation.please-note']}</strong>
                                <p>
                                    <small><em>{this.context.intl.messages['events.presentation.artwork-orientation.please-note.description']}</em></small>
                                </p>
                            </div>
                        </FormGroup>
                    </FormRow>
                </FormSection>

                <hr />

                <PresentationTable disabled={this.props.rowReorderDisabled} event={this.props.event} location={this.props.location}/>
            </div>
        );
    }
}

export default PresentationTab;
