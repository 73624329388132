/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';

import {IsHardacLocation} from '../common/utils/utils';

import './layout.less';

export default createReactClass({
    contextTypes: {
        defaultHomescreen: PropTypes.string.isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired
        }).isRequired,
        onToggleModal: PropTypes.func.isRequired
    },

    handleClick: function(event) {
        event.preventDefault();

        this.context.onToggleModal();

        return;
    },

    render() {
        let systemClass = 'braniac';
        let iconClass = 'fa-house-circle-check';

        if (!this.context.defaultHomescreen) {
            iconClass = 'fa-house-circle-exclamation';
        }

        if (IsHardacLocation(this.context.location)) {
            systemClass = 'hardac';
        }

        return (
            <a className={ClassNames('Mr(3px)', 'multi-tenancy-button', systemClass)} onClick={this.handleClick}>
                <i className={ClassNames('fa-regular', iconClass)}></i>
            </a>
        );
    },
});
