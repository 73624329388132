/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';

import {AssetTabActions} from '../../assets-tab/asset-tab-actions';
import {AlertTypes} from '../../common/notification/alert';
import {NotificationActions} from '../../common/notification/notification-actions';
import {SlidingPanelActions} from '../../common/sliding-panel/sliding-panel-actions';
import {UploadFile} from '../../common/utils/utils';
import Dispatcher from '../../dispatcher/dispatcher';
import Request from '../../request';
import {RouterActions} from '../../router/router-actions';
import {ActionHistoryConstants} from '../../system/action-history/action-history-actions';
import {AssetTalentActions} from '../asset-talent-actions';
import {AssetCatalogActions} from '../catalogs/asset-catalog-actions';

const CONSTANTS = {
    CONTENT_TYPES: {
        THE_890: {
            id: 1,
            name: '890'
        },
        ANTI_PIRACY: {
            id: 2,
            name: 'Anti Piracy'
        },
        CCSL: {
            id: 3,
            name: 'CCSL'
        },
        DIALOGUE_LIST: {
            id: 4,
            name: 'Dialogue List'
        },
        DUBBING_CARD: {
            id: 5,
            name: 'Dubbing Card'
        },
        EDIT_DECISION_LIST: {
            id: 6,
            name: 'Edit Decision List'
        },
        FORCED_890: {
            id: 7,
            name: 'Forced 890'
        },
        FORCED_PAC: {
            id: 8,
            name: 'Forced PAC'
        },
        FORCED_SMI: {
            id: 9,
            name: 'Forced SMI'
        },
        FORCED_SRT: {
            id: 10,
            name: 'Forced SRT'
        },
        FORCED_TEXT: {
            id: 11,
            name: 'Forced Text'
        },
        FORMAT_SHEETS: {
            id: 12,
            name: 'Format Sheets'
        },
        MUSIC_CUES: {
            id: 13,
            name: 'Music Cues'
        },
        PAC: {
            id: 14,
            name: 'PAC'
        },
        PROMO: {
            id: 15,
            name: 'Promo'
        },
        SMI: {
            id: 16,
            name: 'SMI'
        },
        SPOTTING_SUBTITLE_LIST: {
            id: 17,
            name: 'Spotting/Subtitle List'
        },
        SRT: {
            id: 18,
            name: 'SRT'
        },
        STL: {
            id: 19,
            name: 'STL'
        },
        TEXT: {
            id: 20,
            name: 'Text'
        },
        TRAILERS: {
            id: 21,
            name: 'Trailers'
        },
        TRANSLATION: {
            id: 22,
            name: 'Translation'
        },
        VISA_CARD: {
            id: 23,
            name: 'Visa Card'
        },
        AS_BROADCAST: {
            id: 24,
            name: 'As Broadcast'
        },
        SHOOTING: {
            id: 25,
            name: 'Shooting'
        }
    },
    SCRIPT: {
        ADD: 'asset.script.add',
        CLEAR: 'asset.script.clear',
        GET: {
            ERROR: 'asset.script.get.error',
            START: 'asset.script.get.start',
            SUCCESS: 'asset.script.get.success'
        },
        SAVE: {
            ERROR: 'asset.script.save.error',
            START: 'asset.script.save.start',
            SUCCESS: 'asset.script.save.success'
        },
        UPDATE: 'asset.script.update'
    },

    toArray: function(constant) {
        return Object.keys(this[constant])
            .map(k => this[constant][k])
            .sort((a, b) => a.name.localeCompare(b.name));
    }
};

class ScriptActions {
    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SCRIPT.CLEAR
        });
    }

    findById(id) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SCRIPT.GET.START,
            id
        });

        let actionHistoryQuery = {
            'action-object': ActionHistoryConstants.ACTION_OBJECTS.SCRIPT,
            'object-id': id,
            offset: 0,
            size: 4
        };

        Promise.all([
            Request.get(`asset/script/${id}`).exec(),
            Request.get('system/action-history').query(actionHistoryQuery).exec()
        ]).spread((asset, history) => {
            asset = asset.body;
            history = history.body.results;
            history.sort((h1, h2) => h2.actionDate.localeCompare(h1.actionDate));
            asset.active = asset.active === 1;

            Dispatcher.dispatch({
                actionType: CONSTANTS.SCRIPT.GET.SUCCESS,
                asset: Immutable.fromJS(asset),
                history: Immutable.fromJS(history)
            });
            return;
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.SCRIPT.GET.ERROR,
                error: err
            });
            switch (err.status) {
            case 404:
                RouterActions.notFound();
                break;
            default:
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'asset.script.load-error');
                break;
            }
            throw err;
        });

        return;
    }

    save(asset, scriptFile, assignedCatalogs, originalAssignedCatalogs, talent, originalTalent, options) {
        let defaults = {
            messages: {
                error: 'asset.script.create.error',
                success: 'asset.script.create.success'
            }
        };
        Object.assign(defaults, options);

        let id;

        Dispatcher.dispatch({
            actionType: CONSTANTS.SCRIPT.SAVE.START
        });

        this._save(asset, scriptFile).spread(res => {
            id = res.body.id;
            return Promise.all([
                ...AssetCatalogActions.assetCatalogSave(id, assignedCatalogs, originalAssignedCatalogs),
                ...AssetTalentActions.assetTalentSave(id, talent, originalTalent)
            ]);
        }).then(() => {
            RouterActions.redirect(`/assets/script/${id}`, true);
            Dispatcher.dispatch({
                actionType: CONSTANTS.SCRIPT.SAVE.SUCCESS,
                id
            });
            NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, defaults.messages.success);
            return;
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.SCRIPT.SAVE.ERROR
            });

            // If asset has been created, then redirect to Edit Asset page
            // so that successive calls to save don't generate any new assets.
            if (err.assetId) {
                RouterActions.redirect(`/assets/script/${err.assetId}`, true);
                NotificationActions.showAlertDanger('asset.error.incomplete-save');
                throw err;
            }

            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, defaults.messages.error);
            throw err;
        });
        return;
    }

    saveAndAdd(asset, scriptFile, addConstants) {
        let defaults = {
            messages: {
                error: 'asset.script.create.error',
                success: 'asset.script.create.success'
            }
        };

        Dispatcher.dispatch({
            actionType: CONSTANTS.SCRIPT.SAVE.START
        });

        this._save(asset, scriptFile)
            .spread((res, scriptFileRes) => {
                if (scriptFileRes) {
                    let scriptFileResp = JSON.parse(scriptFileRes);
                    this.update('uploadedFile', scriptFileResp.uploadedFile);
                }
                Dispatcher.dispatch({
                    actionType: CONSTANTS.SCRIPT.SAVE.SUCCESS,
                    id: res.body.id
                });
                asset = asset.set('id', res.body.id);
                SlidingPanelActions.hide('addNew');
                AssetTabActions.add('script', Immutable.List([asset]), addConstants);
                NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, defaults.messages.success);
                return;
            })
            .catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.SCRIPT.SAVE.ERROR
                });
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, defaults.messages.error);
                throw err;
            });
        return;
    }

    _save(asset, scriptFile) {
        let assetData = asset.toJS();
        ['history', 'titles', 'files'].forEach(p => delete assetData[p]);
        if (scriptFile) {
            scriptFile = scriptFile[0];
        }
        let id = asset.get('id');
        let fileExists = asset.get('fileName');
        let method = Request.post;
        let fileMethod = 'POST';
        let uri = 'asset/script';
        if (assetData.active) {
            assetData.active = 1;
        } else {
            assetData.active = 0;
        }
        if (id !== undefined) {
            method = Request.put;
            uri = `asset/script/${id}`;
        }
        if (fileExists) {
            fileMethod = 'PUT';
        }

        return method(uri).send(assetData).exec().then(res => {
            if (scriptFile) {
                let upload = UploadFile(
                    fileMethod,
                    `asset/script/${res.body.id}/file`,
                    scriptFile,
                    new XMLHttpRequest()
                ).catch(e => {
                    throw {
                        assetId: res.body.id,
                        e
                    };
                });

                return [res, upload];
            } else {
                return [res];
            }
        });
    }

    update(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SCRIPT.UPDATE,
            attr: attr,
            value: value
        });

        return;
    }
}

let actions = new ScriptActions();

export {
    actions as ScriptActions,
    CONSTANTS as ScriptConstants
};
