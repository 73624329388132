/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import AddExistingTitles from './add-existing-titles';
import SlidingPanel from '../../../common/sliding-panel/sliding-panel';
import {SlidingPanelActions} from '../../../common/sliding-panel/sliding-panel-actions';
import {EventActions} from '../../event-actions';
import EventStore from '../../event-store';

class AddExistingPanel extends Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        const es = EventStore.getState();
        return {
            eventTitle: es.getIn(['event', 'name']),
            selectedTitles: es.get('selectedTitles'),
            selectedTitlesToDisplay: es.get('selectedTitlesToDisplay')
        };
    }

    static getStores() {
        return [EventStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            selectedTitlesToDisplay: [],
        }, this.constructor.calculateState());

        this.handleAddTitle = this.handleAddTitle.bind(this);
        this.handleCancelPanel = this.handleCancelPanel.bind(this);
        this.handleClickAddSelectedItems = this.handleClickAddSelectedItems.bind(this);
        this.simulateCancelButton = this.simulateCancelButton.bind(this);
    }

    componentWillUnmount() {
        this.simulateCancelButton();
    }

    clearSelectedTitles() {
        this.setState(() => ({
            selectedTitles: Immutable.List(),
            selectedTitlesToDisplay: []
        }));
    }

    handleAddTitle(title) {
        if (this.state.selectedTitles.some(t => t.get('titleId') === title.get('id'))) {
            EventActions.removeTitleFromList(title.get('id'));
            return;
        }
        EventActions.addToDisplayTitles(title.get('id'));

        if (title.get('parentTitleId')) {
            EventActions.getParentTitle(title.get('id'));
            return;
        }
        EventActions.addTitleToList(title?.toJS());
    }

    handleCancelPanel() {
        EventActions.clearAddedTitles();
    }

    handleClickAddSelectedItems() {
        EventActions.addTitlesToList();
        SlidingPanelActions.hide('addTitlesToEvent');
        return;
    }

    simulateCancelButton() {
        SlidingPanelActions.hide('addTitlesToEvent');
        this.handleCancelPanel();
    }

    render() {
        const addSelectedItems = (
            <button
                className="btn btn-success-outline"
                onClick={this.handleClickAddSelectedItems}
                disabled={!this.state.selectedTitles.size}
            >
                <i className="fas fa-plus"></i>&nbsp;{this.context.intl.messages['events.titles.add-titles.button'].replace('{}', this.state.selectedTitlesToDisplay.size)}
            </button>
        );

        const title = (
            <span>
                {this.context.intl.messages['events.titles.add-titles']}
                &nbsp;
                <small>{this.state.eventTitle}</small>
            </span>
        );

        return (
            <SlidingPanel
                cancelButtonClassNames="btn-md btn-navy-outline"
                extraButtons={addSelectedItems}
                icon="fas fa-plus-circle"
                id="addTitlesToEvent"
                onCancel={this.handleCancelPanel}
                title={title}
                useH3Header={true}
            >
                <AddExistingTitles location={this.props.location} onAddTitle={this.handleAddTitle} />
            </SlidingPanel>
        );
    }
}

export default Container.create(AddExistingPanel);
