/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
*/

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link} from 'react-router';
import ReactTable from 'react-table';

import {Intersperse} from '../../../common/utils/utils';

import 'react-table/react-table.css';

export default class Table extends Component {
    static get propTypes() {
        return {
            onSortChange: PropTypes.func.isRequired,
            sortDirection: PropTypes.string.isRequired,
            sortFieldName: PropTypes.string.isRequired,
            users: PropTypes.instanceOf(Immutable.List).isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.handleHeaderClick = this.handleHeaderClick.bind(this);
        this.getHeaderClassName = this.getHeaderClassName.bind(this);
    }

    getHeaderClassName(headerName) {
        let headerClassName = 'sorting';

        if (headerName === this.props.sortFieldName) {
            headerClassName = `sorting-${this.props.sortDirection}`;
        }

        return headerClassName;
    }

    handleHeaderClick(headerName) {
        if (!headerName) { // if column does not have sorting
            return;
        }

        let newSortDirection = 'asc';
        if (this.props.sortFieldName === headerName && this.props.sortDirection === 'asc') {
            newSortDirection = 'desc';
        }
        if (this.props.onSortChange) {
            this.props.onSortChange(headerName, newSortDirection);
        }
        return;
    }

    render() {
        const getHeader = this.getHeaderClassName;

        let columns = [{
            accessor: 'ID',
            Header:  props => (
                <strong className={getHeader(props.column.id)}>
                    {this.context.intl.messages['accounts.create.summary.information.id']}
                </strong>
            ),
            Cell: c => <Link to={`/accounts/${c.original.get('userId')}`}>{c.original.get('userId')}</Link>
        }, {
            accessor: 'TYPE',
            Header:  props => (
                <strong className={getHeader(props.column.id)}>
                    {this.context.intl.messages['accounts.create.summary.information.related-type']}
                </strong>
            ),
            Cell: c => <p><span className={`label ${c.original.get('color')}`}>{c.original.get('displayName')}</span></p>
        }, {
            accessor: 'TOKEN',
            Header:  props => (
                <strong className={getHeader(props.column.id)}>
                    {this.context.intl.messages['accounts.create.summary.information.related-token']}
                </strong>
            ),
            Cell: c => <div>{c.original.get('token')}</div>
        }, {
            accessor: 'WEBPARTNERS',
            Header:  props => (
                <strong className={getHeader(props.column.id)}>
                    {this.context.intl.messages['accounts.create.summary.information.web-partners']}
                </strong>
            ),
            Cell: c => {
                let partners = this.context.intl.messages['common.na'];
                if (c.original.get('partners').size) {
                    partners = c.original.get('partners').map((p) => <Link key={`${Math.ceil(Math.random() * 100000)}`} to={`/setup/authorized-partners/${p.get('id')}`}>{p.get('name')}</Link>);
                    partners = Intersperse(partners.toJS());
                }
                return <div>{partners}</div>;
            }
        }, {
            accessor: 'GUILDS',
            Header:  props => (
                <strong className={getHeader(props.column.id)}>
                    {this.context.intl.messages['accounts.create.summary.information.guilds']}
                </strong>
            ),
            Cell: c => {
                let guilds = this.context.intl.messages['common.na'];
                if (c.original.get('guilds').size) {
                    guilds = c.original.get('guilds').map((g) => <Link key={`${Math.ceil(Math.random() * 100000)}`} to={`/guild/${g.get('id')}`}>{g.get('name')}</Link>);
                    guilds = Intersperse(guilds.toJS());
                }
                return <div>{guilds}</div>;
            }
        }];

        const handleSortColumn = this.handleHeaderClick;

        return (
            <ReactTable
                className="-striped table-bordered table-striped responsive"
                columns={columns}
                data={this.props.users}
                getNoDataProps= {() => {
                    if (this.props.users.size) {
                        return {style: {display: 'none'}};
                    }
                    return {};
                }}
                id={'accounts-additional-information-table-id'}
                loading={false}
                showPagination={false}
                sortable={true}
                resizable={false}
                getTheadThProps={(state, rowInfo, column, instance) => ({
                    onClick: () => {
                        if (column.sortable !== false) {
                            instance.sortColumn(column);
                        }
                        handleSortColumn(column.id);
                    }
                })}
                pageSize={this.props.users.size}
            />
        );
    }
}
