/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import createReactClass from 'create-react-class';
import React from 'react';

import {TerritoryActions} from './territory-actions';
import TerritoryStore from './territory-store';
import BaseSelect from '../../common/base-select/base-select';


export default createReactClass({
    getter: function() {
        setTimeout(() => {
            if (TerritoryStore.isEmpty()) {
                TerritoryActions.get(0, 9999);
            }
        });

        return;
    },

    render: function() {
        return (
            <div style={{width: '100%'}}>
                <BaseSelect
                    {...this.props}
                    getter={this.getter}
                    store={TerritoryStore}
                />
            </div>
        );
    }
});
