/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {FormatTitleReleaseDate} from '@wbdt-sie/brainiac-web-common';
import Immutable from 'immutable';
import jQuery from 'jquery';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, Col, Row} from 'react-bootstrap';

import SearchBox from '../../common/search-box/search-box';
import {WithStoreOnRoute} from '../../common/store-on-route';
import Pagination from '../../common/table/pagination';
import {LayoutActions} from '../../layout/layout-actions';
import {TitleActions, TitleConstants} from '../title-actions';

require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('../../styles/data-tables-brainiac.css');
// Load jQuery and register the datatables plugin.
require('datatables.net-responsive-bs');

const noop = () => void 0;
let createHeaders = function(headerName, showName) {
    return function(component) {
        return <th onClick={component.handleHeaderClick.bind(component, headerName)} className={component.getsortFieldNameClass(headerName)} key={headerName}>{showName}</th>;
    };
};
/**
 * Column definitions for the Titles Table.
 *
 * All column objects have the following attributes:
 *  - header: column header to display, it is a <th> element with a key prop.
 *  - get (optional): function that gets the value to display based on the title,
 *    component properties and context. If get function is not defined, it must
 *    be provided by the columns prop.
 */
const COLUMNS = {
    actions: {
        header: function() {
            return <th className="actions" key="actions">Actions</th>;
        }
    },
    catalogs: {
        get: function(title, props, context) {
            let catalogs = context.intl.messages['titles.browse.loading-catalogs'];
            if (title.get('catalogs')) {
                // Filter catalogs auto generated by Events
                const catalogsToShow = title.get('catalogs').filter(catalog => {
                    return !catalog.get('name').toLowerCase().startsWith('event:');
                });
                catalogs = catalogsToShow.map(t => t.get('name')).reduce((r, v, i) => {
                    if (i === 0) {
                        return `Catalogs: ${v}`;
                    }

                    return `${r}, ${v}`;
                }, context.intl.messages['titles.browse.no-catalogs']);
            }

            return `<div class="display-title-catalogs parcel-tooltip" data-title-id="${title.get('id')}" title="${catalogs}"><i class="fas fa-book"></i><span class="tooltip-inner">${catalogs}</span></div>`;
        },
        header: function() {
            return <th className="actions" key="catalogs"><i className="fas fa-book"/></th>;
        }
    },
    categoryGroupName: {
        get: function(title) {
            let gr = TitleActions.getCategoryGroup(title.get('category'));
            return TitleConstants.TITLE_CATEGORY_GROUPS_DESC[gr];
        },
        header: createHeaders('categoryGroupName', 'Title Group Type')
    },
    categoryName: {
        get: function(title) {
            return title.get('categoryName');
        },
        header: createHeaders('categoryName', 'Title Type')
    },
    mpmNumber: {
        get: function(title) {
            return title.get('mpmNumber');
        },
        header: createHeaders('mpmNumber', 'MPM #')
    },
    parentTitle: {
        get: function(title, props) {
            let parentTitle = title.get('displayName');
            let id = title.get('id');
            switch (TitleActions.getCategoryGroup(title.get('category'))) {
            case TitleConstants.TITLE_CATEGORY_GROUPS.EPISODE:
                let season = TitleActions.seasonFromFullRunningOrder(title.get('fullRunningOrder'));
                parentTitle = `${title.get('parentTitleDisplayName') || 'SERIE'} (S${season})`;
                id = title.get('parentTitleId');
                break;
            case TitleConstants.TITLE_CATEGORY_GROUPS.SEASON:
                parentTitle = title.get('parentTitleDisplayName') || title.get('displayName');
                break;
            }
            if (props.displayLinks) {
                parentTitle = `<a href="/titles/${id}" class="edit-title" target="${props.linkTarget}">${parentTitle}</a>`;
            }
            return parentTitle;
        },
        header: createHeaders('parentTitleDisplayName', 'Parent')
    },
    ro: {
        get: function(title) {
            switch (TitleActions.getCategoryGroup(title.get('category'))) {
            case TitleConstants.TITLE_CATEGORY_GROUPS.EPISODE:
                let season = TitleActions.seasonFromFullRunningOrder(title.get('fullRunningOrder'));
                return `<small class="text-gray">S${season}</small>${title.get('runningOrder')}`;
            case TitleConstants.TITLE_CATEGORY_GROUPS.SEASON:
                return `<small class="text-gray">S${title.get('season')}</small>`;
            case TitleConstants.TITLE_CATEGORY_GROUPS.SERIES:
                return '<small class="text-gray">SERIES</small>';
            case TitleConstants.TITLE_CATEGORY_GROUPS.SINGLE_RELEASE:
                return '<small class="text-gray">FEATURE</small>';
            }
            return '&nbsp;';
        },
        header: createHeaders('ro', 'RO')
    },
    title: {
        get: function(title, props) {
            let titleName = title.get('name');
            switch (title.get('category')) {
            case TitleConstants.TITLE_TYPES.ANIMATED_SERIES_SEASON.id:
            case TitleConstants.TITLE_TYPES.CARTOONS_SEASON.id:
            case TitleConstants.TITLE_TYPES.DOCUSERIES_SEASON.id:
            case TitleConstants.TITLE_TYPES.GAME_SHOW_SEASON.id:
            case TitleConstants.TITLE_TYPES.LIMITED_SERIES_SEASON.id:
            case TitleConstants.TITLE_TYPES.REALITY_SEASON.id:
            case TitleConstants.TITLE_TYPES.SEASON_HALF_HOUR.id:
            case TitleConstants.TITLE_TYPES.SEASON_ONE_HOUR.id:
            case TitleConstants.TITLE_TYPES.SHORT_PROGRAMS_SEASON.id:
            case TitleConstants.TITLE_TYPES.TALK_SHOW_SEASON.id:
                titleName = `${titleName} - Season ${title.get('season')}`;
                break;
            case TitleConstants.TITLE_TYPES.EPISODE.id:
                titleName = `${titleName} - ${title.get('parentTitleDisplayName') || ''} ${title.get('fullRunningOrder') || ''}`;
                break;
            }
            if (props.displayLinks) {
                titleName = `<a href="/titles/${title.get('id')}" class="edit-title" target="${props.linkTarget}">${titleName}</a>`;
            }
            return titleName;
        },
        header: createHeaders('name', 'Title')
    },
    title2: {
        get: function(title, props) {
            let titleName = '';
            switch (TitleActions.getCategoryGroup(title.get('category'))) {
            case TitleConstants.TITLE_CATEGORY_GROUPS.EPISODE:
                titleName = title.get('displayName');
                break;
            case TitleConstants.TITLE_CATEGORY_GROUPS.SEASON:
                titleName = `${title.get('displayName')} (S${title.get('season')})`;
                break;
            }
            if (props.displayLinks) {
                titleName = `<a href="/titles/${title.get('id')}" class="edit-title" target="${props.linkTarget}">${titleName}</a>`;
            }
            return titleName;
        },
        header: createHeaders('name', 'Title')
    },
    titleReleaseDate: {
        get: function(title, props, context) {
            return FormatTitleReleaseDate(title, context.intl.messages);
        },
        header: createHeaders('titleReleaseDate', 'Release Date')
    }
};

class ListTitles extends Component {
    static get propTypes() {
        return {
            activePage: PropTypes.number.isRequired,
            children: PropTypes.node.isRequired,
            columns: PropTypes.array,
            complete: PropTypes.bool,
            displayLinks: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
            filterButton: PropTypes.bool.isRequired,
            isOutlineStyle: PropTypes.bool,
            linkTarget: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
            location: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
            onPageChange: PropTypes.func,
            onSearchChange: PropTypes.func,
            onSelect: PropTypes.func,
            onSelectAll: PropTypes.func,
            onSortChange: PropTypes.func,
            selectAll: PropTypes.bool,
            sortDirection: PropTypes.string.isRequired,
            sortFieldName: PropTypes.string.isRequired,
            tableId: PropTypes.string,
            titles: PropTypes.instanceOf(Immutable.List).isRequired, // eslint-disable-line react/no-unused-prop-types
            totalPages: PropTypes.number.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
            router: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            columns: ['mpmNumber', 'title', 'titleReleaseDate', 'categoryName', 'catalogs'],
            complete: false,
            displayLinks: false,
            filters: Immutable.Map(),
            isOutlineStyle: false,
            linkTarget: '_self',
            location: undefined,
            onPageChange: noop,
            onSearchChange: undefined,
            onSelect: undefined,
            onSelectAll: undefined,
            onSortChange: undefined,
            selectAll: false,
            tableId: 'list-titles-table',
            titles: Immutable.List()
        };
    }

    constructor(props) {
        super(props);

        this.getsortFieldNameClass = this.getsortFieldNameClass.bind(this);
        this.handleHeaderClick = this.handleHeaderClick.bind(this);
        this.handleActionsCellClick = this.handleActionsCellClick.bind(this);
        this.handleCatalogCellMouseEnter = this.handleCatalogCellMouseEnter.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.handleSearchTerm = this.handleSearchTerm.bind(this);
        this.handleSelectAllClick = this.handleSelectAllClick.bind(this);
    }

    componentDidMount() {
        let tableId = this.props.tableId;
        this.$table = jQuery(`#${tableId}`);
        this.$tableAPI = this.$table.DataTable({
            autoWidth: false,
            columnDefs: [{
                // Add the control class to the last column. This colum will
                // contain the button to show the responsive data.
                className: 'control',
                targets:   -1,
                width: 20
            }, {
                className: 'actions',
                targets: 'actions'
            }, {
                targets: 'no-sort',
                orderable: false
            }],
            iDisplayLength: 1,
            info: false,
            ordering: false,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        });

        // Add some custom handlers.
        this.$table.on('mouseenter', '.display-title-catalogs', this.handleCatalogCellMouseEnter);
        this.$table.on('click', 'td.actions', this.handleActionsCellClick);

        // Register global listeners.
        window.addEventListener('resize', this.handleResize);
        // And trigger the resize handler once so that the datatable
        // knows its initial dimensions.
        this.drawTitles(this.props);
        return;
    }

    componentWillUpdate(nextProps) {
        this.drawTitles(nextProps);
        return;
    }

    componentWillUnmount() {
        // check if undefinded only to enable render test
        if (this.$table) {
            this.$table.off('mouseenter', '.display-title-catalogs');
        }
        // check if undefinded only to enable render test
        try {
            // Wrapped in try/catch to prevent browser exception when used in slidingpanel
            if (this.$tableAPI) {
                this.$tableAPI.destroy();
            }
        } catch (e) {
            return;
        }

        return;
    }

    drawTitles(props) {
        this.$tableAPI.clear();

        // Add data to the jQuery datatable.
        props.titles.forEach((title, index) => {
            let row = this.props.columns.map(c => {
                let column = COLUMNS[c];
                if (c.name) {
                    column = COLUMNS[c.name];
                }
                // Override the getter if it was provided. This way
                // the module that includes the table can define custom
                // functions to read the values.
                if (c.get) {
                    column.get = c.get;
                }
                return column.get(title, props, this.context);
            });

            // If requested, show the select in the first column.
            if (this.props.onSelectAll && this.props.onSelect) {
                let checkedStatus = '';
                if (title.get('__selected')) {
                    checkedStatus = 'checked';
                }
                row.unshift(`<input type="checkbox" class="select-row" data-index="${index}" ${checkedStatus}/>`);
            }
            // Add a last empty column for the datatable-responsive plugin.
            row.push('');

            this.$tableAPI.row.add(row);

            return;
        });

        this.$tableAPI.draw(false);

        // Now, since the data has changed the columns widths, trigger
        // the resize handler in order to update the responsive feature.
        this.handleResize();
    }

    getsortFieldNameClass(headerName) {
        let r = 'sorting';

        if (headerName === this.props.sortFieldName) {
            r = `sorting_${this.props.sortDirection}`;
        }

        return r;
    }

    handleHeaderClick(headerName) {
        let newSortDirection = 'asc';
        if (this.props.sortFieldName === headerName && this.props.sortDirection === 'asc') {
            newSortDirection = 'desc';
        }

        if (this.props.onSortChange) {
            this.props.onSortChange(headerName, newSortDirection);
        }
        return;
    }

    /**
     * Send all click events over the "actions" cell
     * to the handler defined by the parent component.
     */
    handleActionsCellClick(event) {
        this.props.columns.filter(c => {
            return c.name === 'actions';
        })[0].onClick(event);
        return;
    }

    /**
     * Look for the catalogs on mouseenter.
     */
    handleCatalogCellMouseEnter(event) {
        let title = event.currentTarget.getAttribute('title');

        // If catalogs for this title are loaded, then return.
        if (title !== this.context.intl.messages['titles.browse.loading-catalogs']) {
            return;
        }
        TitleActions.getCatalogs(
            parseInt(event.currentTarget.getAttribute('data-title-id'), 10)
        );
    }

    /**
     * Update the datatable columns size.
     */
    handleResize() {
        this.$tableAPI.responsive.recalc();
        return;
    }

    /**
     * This function is kind of "special" because it needs to handle
     * events bubbled from the data table rows, these rows cannot use
     * the JSX syntax because they are created by the data table
     * jQuery plugin instead of React.
     */
    handleRowClick(event) {
        switch (true) {
        // Handle click on a select input.
        case !!~event.target.className.indexOf('select-row'):
            let index = parseInt(event.target.getAttribute('data-index'), 10);
            this.props.onSelect(index, event.target.checked);
            break;
        // Handle click on an user's name.
        case !!~event.target.className.indexOf('edit-title'):
            // If target _blank, then don't do anything, let the link work.
            let linkTarget = event.target.getAttribute('target');
            if (linkTarget === '_blank') {return;}
            // Prevent the default anchor click event.
            event.preventDefault();
            this.context.router.push(event.target.getAttribute('href'));
            break;
        }

        return;
    }

    handleSearchTerm(term) {
        this.props.onSearchChange(term);
        return;
    }

    handleToggleFiltersPanel() {
        LayoutActions.toggleFiltersPanel();
        return;
    }

    handleSelectAllClick(event) {
        this.props.onSelectAll(event.target.checked);
        return;
    }

    render() {
        let filterButton;
        let search;
        let showSelect = !!(this.props.onSelectAll && this.props.onSelect);

        if (this.props.filterButton) {
            let filtersStyle = {
                bsStyle: 'primary',
                bsSize: 'large',
                className: 'pull-right',
            };
            if (this.props.isOutlineStyle) {
                filtersStyle = {
                    className: 'btn btn-navy-outline pull-right',
                    bsSize: 'medium',
                };
            }
            filterButton = <Col md={3}>
                <Button onClick={this.handleToggleFiltersPanel} bsSize="large" {...filtersStyle}>
                    <i className="fas fa-sliders-h"></i>&nbsp;{this.context.intl.messages['common.filteringOptions']}
                </Button>
            </Col>;

        }

        if (this.props.onSearchChange) {
            search = <Row>
                <Col md={6} mdOffset={3} className="show-grid">
                    <SearchBox value={this.getRouteState(this.props).get('title')} onChange={this.handleSearchTerm}/>
                </Col>
                {filterButton}
            </Row>;
        }

        let pagination;
        if (!this.props.complete) {
            pagination = <div className="row">
                <div className="col-sm-7 col-sm-offset-5">
                    <Pagination
                        activePage={this.props.activePage}
                        onChange={this.props.onPageChange}
                        totalPages={this.props.totalPages}
                    />
                </div>
            </div>;
        }

        return (
            <div>
                {search}
                {this.props.children}
                <table id={this.props.tableId} className="table table-bordered table-striped responsive">
                    <thead>
                        <tr>
                            {showSelect && <th className="no-sort"><input type="checkbox" onChange={this.handleSelectAllClick} checked={this.props.selectAll}/></th>}
                            {this.props.columns.map(c => {
                                if (c.header) {
                                    return c.header(this);
                                }
                                let column = COLUMNS[c];
                                if (c.name) {
                                    column = COLUMNS[c.name];
                                }
                                return column.header(this);
                            })}
                            <th className="no-sort"></th>
                        </tr>
                    </thead>
                    <tbody onClick={this.handleRowClick}>
                    </tbody>
                </table>
                {pagination}
            </div>
        );
    }
}

export default WithStoreOnRoute(ListTitles);
