/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

import CCEditorStore from '../cc-editor-store';
import {SOURCE_TYPES} from '../constants';
import {ConvertCuesToRegionsWithGaps} from '../helpers/cues';

import VideoStore from '~/src/assets/video/video-store';
import {ConvertFluxStoreToBehaviorSubject, TransformBehaviorSubject} from '~/src/common/utils/flux-to-rx-helpers';
import {CreateSimpleStore, CreateToggleStore} from '~/src/common/utils/rx-store-helpers';

// simple stores
export const [CurrentTime$, UpdateTime] = CreateSimpleStore(0);
export const [CurrentVttId$, ChangeCurrentVttId] = CreateSimpleStore<string | null>(null);
export const [
    IsCaptionPositioningModalVisible$, ChangeCaptionPositioningModalVisibility, ShowCaptionPositioningModal, HideCaptionPositioningModal
] = CreateToggleStore();
export const [
    IsShiftCaptionsModalVisible$, ChangeShiftCaptionsModalVisibility, ShowShiftCaptionsModal, HideShiftCaptionsModal
] = CreateToggleStore();
export const [IsUploadModalVisible$, ChangeUploadModalVisibility, ShowUploadModal, HideUploadModal] = CreateToggleStore();
export const [IsSyncTime$, ChangeSyncTimeToggler] = CreateToggleStore(true);
export const [VideoDuration$, UpdateVideoDuration] = CreateSimpleStore(Number.MAX_SAFE_INTEGER);
export const [SelectedCues$, SelectCues, ResetSelectedCues] = CreateSimpleStore<ReadonlyArray<WBTVDCue>>([]);
export const [ShiftingTime$, SetShiftingTime] = CreateSimpleStore(0);

// flux stores
export const VideoAsset$ = ConvertFluxStoreToBehaviorSubject(VideoStore, 'asset');
export const VTTFiles$ = ConvertFluxStoreToBehaviorSubject(VideoStore, 'webVTTFiles');
export const OriginalVTTFiles$ = ConvertFluxStoreToBehaviorSubject(VideoStore, 'originalWebVTTFiles');
export const Cues$ = ConvertFluxStoreToBehaviorSubject(CCEditorStore, 'cues');
export const OriginCues$ = ConvertFluxStoreToBehaviorSubject(CCEditorStore, 'originCues');
const DuplicateVttFile$ = ConvertFluxStoreToBehaviorSubject(CCEditorStore, 'duplicate');

// computed stores
export const CurrentVttFile$ = TransformBehaviorSubject(VTTFiles$, CurrentVttId$, findCurrentVttFile);
export const ListOfRegions$ = TransformBehaviorSubject(Cues$, VideoDuration$, convertCuesToRegions);
export const IsChangedSelectedCue$ = TransformBehaviorSubject(SelectedCues$, ListOfRegions$, isSelectedCueChangedDetector);
export const PlayerSources$ = TransformBehaviorSubject(VideoAsset$, VideoStore.convertVideoAssetToPlayerSource);
export const ActiveRegions$ = TransformBehaviorSubject(ListOfRegions$, CurrentTime$, activeRegionsDetector);
export const ActiveRegionsAsMap$ = TransformBehaviorSubject(ActiveRegions$, activeRegionsAsCache);
export const ChangedCuesMap$ = TransformBehaviorSubject(Cues$, OriginCues$, detectChangedCues);
export const HasChangedCue$ = TransformBehaviorSubject(ChangedCuesMap$, hasChangedCue);
export const HasChangedVttDescription$ = TransformBehaviorSubject(VTTFiles$, OriginalVTTFiles$, hasDifferences);
export const IsDuplicateModalVisible$ = TransformBehaviorSubject(DuplicateVttFile$, exists);
export const DuplicateVttFileAsImmutable$ = TransformBehaviorSubject(DuplicateVttFile$, asImmutable);
export const IsMasteringVttFile$ = TransformBehaviorSubject(CurrentVttFile$, isMastering);

// additional actions
export const ApplyChangesToSelectedCues = (cue: Partial<WBTVDCue>) => {
    const cues = SelectedCues$.value.map(c => ({...c, ...cue, isNew: false}));
    SelectCues(cues);
};

function isSelectedCueChangedDetector(selectedCues: ReadonlyArray<WBTVDCue>, regions: ReadonlyArray<CCEditorRegion>) {
    const selected = keyBy('id', selectedCues);
    // TODO: use regions as cache
    return regions.some((region) => {
        const regionId = region.id;
        if (!selected[regionId]) {
            return false;
        }
        return !Immutable.fromJS(region).equals(Immutable.fromJS(selected[regionId]));
    });
}

function activeRegionsDetector(
    regions: ReadonlyArray<CCEditorRegion>,
    time: number,
    current: ReadonlyArray<CCEditorRegion>,
): ReadonlyArray<CCEditorRegion> {
    const inRange = inRangeCreator(time);
    if (current?.length > 0 && current.every(inRange)) {
        const startIdx = regions.indexOf(current[0]);
        const lastIdx = regions.indexOf(current[current.length - 1]);

        const neighbors = [regions[startIdx - 1], regions[lastIdx + 1]].filter(_ => _);
        if (!neighbors.some(inRange)) {
            return current;
        }
    }
    return regions.filter(inRange);
}

function activeRegionsAsCache(regions: ReadonlyArray<CCEditorRegion>): DeepImmutable<Dictionary<CCEditorRegion>> {
    return keyBy('id', regions);
}

function convertCuesToRegions(cues: ReadonlyArray<WBTVDCue>, duration: number) {
    if (cues.length > 0) {
        return ConvertCuesToRegionsWithGaps(cues, duration);
    } else {
        return [];
    }
}

function detectChangedCues(cues: WBTVDCue[], originCues: WBTVDCue[]) {
    const cache = keyBy('id', originCues);
    const res: Dictionary<boolean> = {};
    for (const cue of cues) {
        res[cue.id] = !Immutable.fromJS(cue).equals(Immutable.fromJS(cache[cue.id]));
    }
    return res as DeepImmutable<Dictionary<boolean>>;
}

function hasChangedCue(map: DeepImmutable<Dictionary<boolean>>) {
    return Object.keys(map).some((key) => map[key]);
}

function hasDifferences<K, V>(etalon: Immutable.Iterable<K, V>, compareTo: Immutable.Iterable<K, V>) {
    return !etalon?.equals(compareTo);
}

function inRangeCreator(time: number) {
    return (cue: CCEditorRegion) => {
        if (cue.type === 'gap') {
            return cue.startTime <= time && cue.endTime > time;
        }
        return cue.startTime <= time && cue.endTime >= time;
    };
}

function keyBy<K extends string, T extends { [idx in K]: string }>(identifier: K, arr: T[] | ReadonlyArray<T>): DeepImmutable<Dictionary<T>> {
    const memo: Dictionary<T> = {} as Dictionary<T>;
    for (const e of arr) {
        memo[e[identifier]] = e;
    }
    return memo as DeepImmutable<Dictionary<T>>;
}

function findCurrentVttFile(files: Immutable.List<ImmutableMap<VideoWebVtt>>, id: string | null): ImmutableMap<VideoWebVtt> | undefined {
    return files.find((vtt) => vtt?.get('videoWebVttId').toString() === id);
}

function asImmutable<T extends Dictionary<unknown>>(val: Maybe<T>): ImmutableMap<T> {
    return Immutable.fromJS(val || {});
}

function exists(val: unknown): boolean {
    return val !== null && val !== void 0;
}

function isMastering(vtt: ImmutableMap<VideoWebVtt> | null) {
    return vtt?.get('source') === SOURCE_TYPES.MASTERING;
}
