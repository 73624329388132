/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {AbrPlayer} from '@accurate-player/accurate-player-abr';
import {
    ApTimelineAudioWaveform,
    ApTimelineBasic,
    ApTimelineGroup,
    ApTimelineMarker,
    ApTimelineMetadata,
} from '@accurate-player/accurate-player-components-external';
import React from 'react';

import BaseTimelineRow from './base-timeline-row';
import {ChangedCuesMap$, Cues$, OriginCues$} from '../../bl';
import {DEFAULT_FRAME_RATE} from '../../constants';

import SMPTE from '~/src/common/utils/smpte';
import WithRxSubscriptions from '~/src/decorators/with-rx-subscriptions';

// TODO: move me to a separate package
ApTimelineBasic.register();
ApTimelineGroup.register();
ApTimelineMarker.register();
ApTimelineAudioWaveform.register();
ApTimelineMetadata.register();

type Props = {
    abrPlayer: AbrPlayer | null,
    cues: ReadonlyArray<WBTVDCue>,
    frameRate: number,
    originCues: ReadonlyArray<WBTVDCue>
}

class CCEditorTimeline extends React.PureComponent<Props> {
    componentDidUpdate(props: Props) {
        if (props.frameRate !== this.props.frameRate) {
            this.smpte = SMPTE.create({frameRate: this.props.frameRate || DEFAULT_FRAME_RATE});
        }
        if (this.props.abrPlayer && this.refs.apTimeline instanceof ApTimelineBasic && (this.refs.apTimeline.player !== this.props.abrPlayer)) {
            this.refs.apTimeline.player = this.props.abrPlayer;
        }
    }

    smpte = SMPTE.create({frameRate: DEFAULT_FRAME_RATE});

    // declare refs: {
    //     apTimeline: ApTimelineBasic,
    // }

    // initTimelineAudioWaveform(waveform: ApTimelineAudioWaveform | null) {
    //     if (!waveform) {
    //         return;
    //     }
    //     waveform.sampleScoreThreshold = 10;
    //     let lastRequestHash = '';
    //     waveform.apDataRequest = (range: {start: number, end: number}, canvasWidth: number) => {
    //         const requestHash = `${range.start}-${range.end}-${canvasWidth}`;
    //         if (requestHash === lastRequestHash) {
    //             return;
    //         }

    //         lastRequestHash = requestHash;

    //         // for test only till API will be ready
    //         const s = [
    //             .3, .5, .9, .9, .9, .8, .5, .1, 0, 0, 0, .6, .7, 1, .4, .1, .5, .7, .8, .2,
    //         ];

    //         const sampleRange: SampleRange = {
    //             start: 0,
    //             end: s.length,
    //             samples: s
    //         };
    //         waveform.api.addSampleRange(sampleRange);
    //     };
    // }

    render() {
        return (
            <ap-timeline-basic ref="apTimeline">
                {/* <ap-timeline-group>
                    <span className="row-header" ref={handleHeader}>
                        Audio Profile
                    </span>
                    <ap-timeline-row>
                        <div className="row-header timeline-clips-header" ref={handleHeader}>
                            <span>!</span>
                        </div>
                        <ap-timeline-audio-waveform
                            ref={this.initTimelineAudioWaveform}
                            sample-min={0}
                            sample-max={10}
                            type="stroke_fill"
                            // type={AudioWaveformDrawType.STROKE_FILL}
                        />
                    </ap-timeline-row>
                </ap-timeline-group> */}

                <ap-timeline-group>
                    <BaseTimelineRow
                        cues={this.props.originCues}
                        converter={this.smpte}
                        readonly
                        title="cc-editor.timeline.origin"
                    />
                    <BaseTimelineRow
                        cues={this.props.cues}
                        converter={this.smpte}
                        title="cc-editor.timeline.current"
                    />
                </ap-timeline-group>
            </ap-timeline-basic>
        );
    }
}

export default WithRxSubscriptions(CCEditorTimeline, {
    changedCuesMap: ChangedCuesMap$,
    cues: Cues$,
    originCues: OriginCues$,
});
