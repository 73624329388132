/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {LanguageCodeType} from '@wbdt-sie/brainiac-web-common';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ControlLabel, FormGroup} from 'react-bootstrap';

import {FormRow, FormSection, LocalizedFormItem} from '../../../common/form/form';
import {SectionPanel} from '../../../common/panel/panel';
import {CompareImmutable} from '../../../common/utils/utils';
import {WithValidations} from '../../../common/validations/validations';
import CompanySelect from '../../../lookup/company/company-select';
import CompanyStore from '../../../lookup/company/company-store';
import {HandleSelect, MapToValues} from '../../../lookup/lookup-select-helpers';
import {TitleActions} from '../../title-actions';
import {TitleLocalizedActions} from '../../title-localized-actions';
import {TitleValidations} from '../../title-store';

/**
 * Title props to watch.
 */

const titleProps = ['productionCompanies', 'presentationCredits'];

class ProductionAndDistribution extends Component {

    static get propTypes() {
        return {
            currentLanguage: PropTypes.string,
            disabled: PropTypes.bool,
            expanded: PropTypes.bool,
            title: PropTypes.instanceOf(Immutable.Map),
            titleLocalized: PropTypes.instanceOf(Immutable.Map)
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true,
            expanded: false,
            currentLanguage: undefined,
            title: undefined,
            titleLocalized: undefined
        };
    }

    constructor(props) {
        super(props);

        this.handleSelectProductionCompanies = HandleSelect('productionCompanies', TitleActions.updateTitle, {multi: true}).bind(this);
        this.mapProductionCompanies = MapToValues('id', CompanyStore).bind(this);
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.disabled !== this.props.disabled ||
            nextProps.titleLocalized !== this.props.titleLocalized || // actually have to check if name or selected language has changed
            nextProps.currentLanguage !== this.props.currentLanguage ||
            nextProps.expanded !== this.props.expanded ||
            !CompareImmutable(nextProps.title, this.props.title, titleProps)) {
            return true;
        }

        return false;
    }

    render() {
        let localized = this.props.currentLanguage !== LanguageCodeType.DEFAULT_LANGUAGE;
        let disabledOnLocalized = this.props.disabled || localized;

        return (
            <SectionPanel
                expanded={this.props.expanded}
                title={this.context.intl.messages['titles.create.summary.production-and-distribution']}
                validationState={this.getAccordionValidationState(this.props.title, titleProps, TitleValidations)}
                localized={localized}
            >
                <FormSection>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['titles.create.summary.production-company']}</ControlLabel>
                            <CompanySelect
                                isClearable={true}
                                disabled={disabledOnLocalized}
                                multi={true}
                                name="productionCompanies"
                                onChange={this.handleSelectProductionCompanies}
                                value={this.mapProductionCompanies(this.props.title.get('productionCompanies'))}
                            />
                        </FormGroup>
                        <LocalizedFormItem
                            attr="presentationCredits"
                            label={this.context.intl.messages['titles.create.summary.presentation-credits']}
                            disabled={this.props.disabled}
                            model={this.props.title}
                            setter={TitleActions.updateTitle}
                            type="textarea"
                            localized={localized}
                            localizedModel={this.props.titleLocalized}
                            localizedSetter={TitleLocalizedActions.update}
                        />
                    </FormRow>
                </FormSection>
            </SectionPanel>
        );
    }

}

export default WithValidations(ProductionAndDistribution);
