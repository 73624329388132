/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React from 'react';

import MFAEnrollmentEmailModal from '../common/notification/mfa/mfa-enrollment-email-modal';
import MFAPushNotificationModal from '../common/notification/mfa/mfa-push-notification-modal';
import {DashboardConstants} from '../dashboard/dashboard-actions';
import {SessionActions} from '../user/session/session-actions';
import SessionStore from '../user/session/session-store';

class LoginForm extends React.Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            defaultHomescreen: SessionStore.getState().get('defaultHomescreen'),
            login: SessionStore.getState().get('login')
        };
    }

    static getStores() {
        return [SessionStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign(
            {
                email: undefined,
                password: undefined
            },
            this.constructor.calculateState()
        );

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleShowPassword = this.handleShowPassword.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
        return;
    }

    handleShowPassword(event) {
        let passwordInput = this.refs.passwordInput;
        if (passwordInput.type === 'password') {
            passwordInput.type = 'text';
            event.target.className = 'glyphicon glyphicon-eye-close form-control-feedback';
        } else {
            passwordInput.type = 'password';
            event.target.className = 'glyphicon glyphicon-eye-open form-control-feedback';
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        SessionActions.login(this.state.email, this.state.password, this.props.location.query.next || this.state.defaultHomescreen || DashboardConstants.DEFAULT_DASHBOARD_FILTER);

        return;
    }

    render() {
        let inProgress = this.state.login.get('inProgress');

        let loginButtonText = this.context.intl.messages['login.sign-in'];
        let loginButtonProgress;
        if (inProgress) {
            loginButtonText = this.context.intl.messages['login.login.in-progress'];
            loginButtonProgress = (
                <i className="fas fa-sync-alt fa-spin" />
            );
        }

        return (
            <div>
                <MFAEnrollmentEmailModal
                    email={this.state.email}
                />
                <MFAPushNotificationModal/>
                <form name="login" onSubmit={this.handleSubmit}>
                    <div className="form-group has-feedback">
                        <input autoFocus type="email" className="form-control" name="email" placeholder={this.context.intl.messages['login.email']} onChange={this.handleInputChange}/>
                        <span className="glyphicon glyphicon-envelope form-control-feedback"></span>
                    </div>
                    <div className="form-group has-feedback">
                        <input
                            className="form-control"
                            name="password"
                            onChange={this.handleInputChange}
                            placeholder={this.context.intl.messages['login.password']}
                            ref="passwordInput"
                            type="password"
                        />
                        <span onClick={this.handleShowPassword} className="glyphicon glyphicon-eye-open form-control-feedback" style={{pointerEvents: 'all'}}></span>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <button type="submit" disabled={inProgress} className="btn btn-primary btn-block btn-flat">
                                {loginButtonText} {loginButtonProgress}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
export default Container.create(LoginForm);
