/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, ControlLabel, FormControl, FormGroup} from 'react-bootstrap';

import EventMessageTable from './event-message-data-table';
import DocumentTitle from '../../../../common/document-title';
import {FormSection, FormRow} from '../../../../common/form/form';
import Config from '../../../../config/config.js';
import ActionsMenu from '../../../../layout/actions-menu/actions-menu';
import Preloader from '../../../../preloader';
import {RouterActions} from '../../../../router/router-actions';
import SessionStore from '../../../../user/session/session-store';
import {ProcessesActions} from '../../processes-actions';
import ProcessesStore from '../../processes-store';


class EventMessage extends Component {
    static get propTypes() {
        return {
            params: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
        };
    }

    static calculateState() {
        return {
            message: ProcessesStore.getState().get('message'),
            originalMessage: ProcessesStore.getState().get('originalMessage'),
            showPreloader: ProcessesStore.getState().get('showPreloader'),
        };
    }

    static getStores() {
        return [ProcessesStore];
    }

    static get defaultProps() {
        return {
            permissions: {}
        };
    }

    static getPermissions() {
        return {
            canRead: SessionStore.canUser(SessionStore.PERMISSIONS.HARDAC.PROCESSES.READ),
        };
    }

    constructor(props) {
        super(props);

        this.handleCancel = this.handleCancel.bind(this);
        this.handleEmitChange = this.handleEmitChange.bind(this);
        this.handleReprocess = this.handleReprocess.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.isDirty = this.isDirty.bind(this);
    }

    componentDidMount() {
        this.init(this.props);
        return;
    }

    handleCancel() {
        RouterActions.redirect(`/hardac/processes/v3/timeline/${this.props.params.timelineId}/status`, true);
    }

    isDirty() {
        return !this.state.message.equals(this.state.originalMessage);
    }

    init(props) {
        ProcessesActions.getMessageById(props.params.id);
        RouterActions.registerRedirectCheck(() => {
            return this.isDirty();
        });
    }

    handleEmitChange(event) {
        if (this.isJSONData(event.target.textContent)) {
            ProcessesActions.update('message.eventMessage', JSON.stringify([JSON.parse(event.target.textContent)]));
        }
    }

    handleReprocess() {
        ProcessesActions.reprocessEventMessageById(this.props.params.id);
    }

    handleSave() {
        ProcessesActions.saveEventMessage(this.state.message);
    }

    isJSONData(data) {
        try {
            JSON.parse(data);
            return true;
        } catch (err) {
            return false;
        }
    }

    render() {
        let eventMessageData, eventMessageEditSection;
        if (this.state.message.get('eventMessage')) {
            let eventMessage = {};
            if (this.isJSONData(this.state.message.get('eventMessage'))) {
                eventMessage = JSON.parse(this.state.message.get('eventMessage'))[0];
            }
            const eventTime = eventMessage.eventTime;
            const eventTimeFormated = eventTime ?
                Moment(eventTime).tz(Config.DefaultTimezone).format(this.context.intl.messages['datetime-format']) : '-';
            let {encoderContext, operationContext, workflowJobName} = eventMessage.data || {};
            if (encoderContext) {
                encoderContext = Object.keys(encoderContext).map(key => ({key, value: encoderContext[key]}));
            } else {
                encoderContext = {};
            }
            if (operationContext) {
                operationContext = Object.keys(operationContext).map(key => ({key, value: operationContext[key]}));
            } else {
                operationContext = {};
            }

            eventMessageData = (
                <div>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.processes.messages.event-message.event-type']}</ControlLabel>
                            <FormControl.Static>{eventMessage.eventType || '-'}</FormControl.Static>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.processes.messages.event-message.event-time']}</ControlLabel>
                            <FormControl.Static>{eventTimeFormated}</FormControl.Static>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.processes.messages.event-message.workflow-job-name']}</ControlLabel>
                            <FormControl.Static>{workflowJobName || '-'}</FormControl.Static>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.processes.messages.error-message']}</ControlLabel>
                            <FormControl.Static>{this.state.message.get('errorMessage') || '-'}</FormControl.Static>
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['hardac.processes.messages.event-message.topic']}</ControlLabel>
                            <FormControl.Static>{eventMessage.topic || '-'}</FormControl.Static>
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <EventMessageTable
                                data={Immutable.fromJS(operationContext)}
                                title={this.context.intl.messages['hardac.processes.messages.event-message.operation-context']}
                            />
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <EventMessageTable
                                data={Immutable.fromJS(encoderContext)}
                                title={this.context.intl.messages['hardac.processes.messages.event-message.encoder-context']}
                            />
                        </FormGroup>
                    </FormRow>
                </div>
            );

            eventMessageEditSection = (
                <div>
                    <FormRow>
                        <pre
                            contentEditable="true"
                            onBlur={this.handleEmitChange}
                        >
                            {JSON.stringify(eventMessage, null, 2)}
                        </pre>
                    </FormRow>
                </div>
            );
        }

        const actionsMenu = (
            <ActionsMenu
                canEdit={true}
                canSave={this.isDirty()}
                onCancel={this.handleCancel}
                onSave={this.handleSave}
                saveText={this.context.intl.messages['common.save']}
            >
                <Button
                    bsSize="large"
                    className="btn btn-primary-outline Mr(3px) Mb(3px)"
                    disabled={this.state.message.get('messageProcessed')}
                    onClick={this.handleReprocess}
                >
                    <i className="fas fa-redo"></i>&nbsp;{this.context.intl.messages['hardac.processes.messages.event-message.reprocess']}
                </Button>
            </ActionsMenu>
        );

        return (
            <DocumentTitle
                message={'document-titles.hardac.processes.messages.event-message'}
            >
                <div>
                    <Preloader show={this.state.showPreloader} fixed loadingDots>
                        <section className="content-header">
                            <div className="col-md-6">
                                <h3>
                                    {this.context.intl.messages['hardac.processes.messages.event-message']}
                                </h3>
                            </div>
                            {actionsMenu}
                        </section>
                        <section className="content" id="contentContainer">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h3><i className="fas fa-info-circle"></i>&nbsp;{this.context.intl.messages['hardac.processes.summary.info']}</h3>
                                    <FormSection>
                                        {eventMessageData}
                                        {eventMessageEditSection}
                                    </FormSection>
                                </div>
                            </div>
                        </section>
                    </Preloader>
                </div>
            </DocumentTitle>
        );
    }
}

export default Container.create(EventMessage);
