/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

import WhitelistStore from './whitelist-store';
import WhitelistTable from './whitelist-table';
import DocumentTitle from '../../common/document-title';
import Preloader from '../../preloader/';
import PreloaderStore from '../../preloader/preloader-store';
import {RouterActions} from '../../router/router-actions';


class Whitelist extends Component {

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible'),
            whitelist: WhitelistStore.getInitialState()
        };
    }

    static getStores() {
        return [PreloaderStore, WhitelistStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.handleAddDomain = this.handleAddDomain.bind(this);
    }

    handleAddDomain(event) {
        event.preventDefault();
        RouterActions.redirect('/setup/email-whitelists/create');
        return;
    }

    render() {
        return (
            <DocumentTitle message="document-titles.whitelist">
                <div>
                    <Preloader show={this.state.preloaderVisible} fixed loadingDots>
                        <section className="content-header">
                            <h1>
                                <i className="far fa-envelope"></i>&nbsp;{this.context.intl.messages['emails.whitelist.title']}
                            </h1>
                        </section>
                        <section className="content">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="container-fluid no-x-padding">
                                        <div className="row">
                                            <div className="col-md-12 show-grid">
                                                <Button bsStyle="primary" bsSize="large" onClick={this.handleAddDomain}>
                                                    <i className="fas fa-plus"></i>&nbsp;{this.context.intl.messages['emails.whitelist.add-a-domain']}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container-fluid no-x-padding">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="box">
                                                    <div className="box-body">
                                                        <WhitelistTable/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Preloader>
                </div>
            </DocumentTitle>
        );
    }

}


export default Container.create(Whitelist);
