/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Dispatcher from '../dispatcher/dispatcher';

const CONSTANTS = {
    CLEAR: 'preloader.clear',
    SHOW: 'preloader.show',
    HIDE: 'preloader.hide',
};

class PreloaderActions {

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
        return;
    }

    /**
    * Hide the preloader. "source" argument is an optional caller identifier
    */
    hide(source) {
        // use setTimeout to prevent 'cannot dispatch during a dispatch' errors

        setTimeout(()=>{
            Dispatcher.dispatch({
                actionType: CONSTANTS.HIDE,
                source: source
            });
        }, 0);
    }

    /**
    * Show the preloader. "source" argument is an optional caller identifier
    */
    show(source) {
        // use setTimeout to prevent 'cannot dispatch during a dispatch' errors

        setTimeout(()=>{
            Dispatcher.dispatch({
                actionType: CONSTANTS.SHOW,
                source: source
            });
        }, 0);
    }
}

let actions = new PreloaderActions();

export {
    actions as PreloaderActions,
    CONSTANTS as PreloaderConstants
};
