/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {NotifySubscribersNowConstants} from './notify-subscribers-now-actions';
import Dispatcher from '../../dispatcher/dispatcher';

class NotifySubscribersNowStore extends ReduceStore {

    getInitialState() {
        let state = Immutable.fromJS({
            assetId: null,
            showModal: false,
            subject: null,
            subscriptionTypeId: null,
            titleId: null,
        });
        return state;
    }

    reduce(state, action) {
        switch (action.actionType) {
        case NotifySubscribersNowConstants.SHOW_MODAL:
            state = state.set('assetId', action.assetId);
            state = state.set('showModal', true);
            state = state.set('subject', action.subject);
            state = state.set('subscriptionTypeId', action.subscriptionTypeId);
            state = state.set('titleId', action.titleId);
            break;
        case NotifySubscribersNowConstants.CLOSE_MODAL:
            state = state.set('showModal', false);
            break;
        case NotifySubscribersNowConstants.UPDATE:
            state = state.setIn([...action.attr.split('.')], action.value);
            break;
        }
        return state;
    }
}

const store = new NotifySubscribersNowStore(Dispatcher);

export default store;
