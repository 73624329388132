/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import React from 'react';

import {SOURCE_TYPE_LABELS} from '../../constants';
import {GenerateRootRoute} from '../../helpers/route';

import Table, {ColumnDescription} from '~/src/common/table';
import {FormatDateToDefaultTZString} from '~/src/common/utils/date-converters';

type Props = {
    files: Immutable.List<ImmutableMap<VideoWebVtt>>
}

const COLUMNS: ReadonlyArray<ColumnDescription<ImmutableMap<VideoWebVtt>>> = [{
    getHeader: (intl) => intl.messages['cc-editor.vtt.field.id'],
    getValue: (vtt) => `${vtt.get('videoWebVttId')}`,
}, {
    getHeader: (intl) => intl.messages['cc-editor.vtt.field.date'],
    getValue: (vtt, idx, intl) => FormatDateToDefaultTZString(vtt.get('createdDate'), intl.messages['date-format-title-release-0']),
}, {
    getHeader: (intl) => intl.messages['cc-editor.vtt.field.source'],
    getValue: (vtt, idx, intl) => {
        const source = vtt.get('source');
        if (typeof source !== 'string') {
            return null;
        }
        return intl.messages[SOURCE_TYPE_LABELS[source]];
    },
}, {
    getHeader: (intl) => intl.messages['cc-editor.vtt.field.parent'],
    getValue: (item) => {
        const parentId = item.get('parentVideoWebVttId');
        if (typeof parentId !== 'number') {
            return null;
        }
        const url = GenerateRootRoute(item.toJS(), 'parentVideoWebVttId');
        return `<a target="_blank" href="${url}">${parentId}</a>`;
    }
}];

export default function VttTable(props: Props) {
    return (
        <Table columns={COLUMNS} items={props.files} />
    );
}
