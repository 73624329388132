/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
*/

import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link} from 'react-router';
import ReactTable from 'react-table';

import Pagination from '../../../../common/table/pagination';

import 'react-table/react-table.css';

export default class Table extends Component {
    static get propTypes() {
        return {
            activePage: PropTypes.number.isRequired,
            displayLinks: PropTypes.bool.isRequired,
            messages: PropTypes.instanceOf(Immutable.List).isRequired,
            onPageChange: PropTypes.func.isRequired,
            processId: PropTypes.string.isRequired,
            totalPages: PropTypes.number.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
            router: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            id: Math.ceil(Math.random() * 100000)
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();
    }

    render() {
        let columns = [{
            Header:  <strong>{this.context.intl.messages['hardac.processes.messages.id']}</strong>,
            width: 75,
            Cell: c => {
                let id = c.original.get('id');
                if (this.props.displayLinks) {
                    id = <Link to={`/hardac/processes/v2/${this.props.processId}/messages/${id}`}>{id}</Link>;
                }
                return (
                    <div>
                        {id}
                    </div>
                );
            }
        }, {
            Header: <strong>{this.context.intl.messages['hardac.processes.messages.received-date']}</strong>,
            Cell: c => {
                const receivedDate = Moment(c.original.get('receivedDate'));
                let receivedDateFormated = '-';
                if (receivedDate.isValid()) {
                    receivedDateFormated = receivedDate.format(this.context.intl.messages['date-format']);
                }
                return (
                    <div>
                        {receivedDateFormated}
                    </div>
                );
            }
        }, {
            Header: <strong>{this.context.intl.messages['hardac.processes.messages.processed-date']}</strong>,
            Cell: c => {
                const processedDate = Moment(c.original.get('processedDate'));
                let processedDateFormated = '-';
                if (processedDate.isValid()) {
                    processedDateFormated = processedDate.format(this.context.intl.messages['date-format']);
                }
                return (
                    <div>
                        {processedDateFormated}
                    </div>
                );
            }
        }, {
            Header: <strong>{this.context.intl.messages['hardac.processes.messages.error-message']}</strong>,
            minWidth: 220,
            Cell: c => {
                return (
                    <div>
                        {c.original.get('errorMessage')}
                    </div>
                );
            }
        }];

        let pagination = <div className="row">
            <div className="col-sm-7 col-sm-offset-5">
                <Pagination
                    activePage={this.props.activePage}
                    onChange={this.props.onPageChange}
                    totalPages={this.props.totalPages}
                />
            </div>
        </div>;

        return (
            <section>
                <ReactTable
                    className="-striped table-bordered table-striped responsive"
                    columns={columns}
                    data={this.props.messages}
                    getNoDataProps= {() => {
                        if (this.props.messages.size) {
                            return {style: {display: 'none'}};
                        }
                        return {};
                    }}
                    id={`processes-messages-table-${this.state.id}`}
                    loading={false}
                    showPagination={false}
                    sortable={false}
                    resizable={false}
                />
                {pagination}
            </section>
        );
    }
}
