/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {AssetTalentConstants} from './asset-talent-actions';
import {AudioConstants} from './audio/audio-actions';
import {DocumentConstants} from './document/document-actions';
import {ImageConstants} from './image/image-actions';
import {MerchandiseConstants} from './merchandise/merchandise-actions';
import {TimelineAssetConstants} from './timeline/timeline-asset-actions';
import {VideoConstants} from './video/video-actions';
import Dispatcher from '../dispatcher/dispatcher';

class AssetTalentStore extends ReduceStore {

    getInitialState() {
        return Immutable.Map({
            talent: Immutable.List(),
            originalTalent: Immutable.List(),
            lastOrder:0
        });
    }

    reduce(state, action) {
        switch (action.actionType) {
        case AssetTalentConstants.ADD:
            let lastOrder = state.get('lastOrder') +1 ;
            let newTalent = [];
            action.talent.forEach(t => {
                t = t.set('assetOrder', lastOrder++);
                newTalent.push(t);
            });
            state = state.set('talent', state.get('talent').concat(Immutable.List(newTalent)));
            state = state.set('lastOrder', lastOrder);
            break;
        case AssetTalentConstants.CHECK:
            let ids = action.talent.map( t => {return t.get('id');}).toJS();
            state = state.updateIn(['talent'], (talent) => {
                let talentJS = talent.toJS();
                talentJS.forEach((t) => {
                    if (ids.indexOf(t.id) !== -1) {
                        t.checked = action.checked;
                    }
                });
                return Immutable.fromJS(talentJS);
            });
            break;
        case AssetTalentConstants.CLEAR:
            state = this.getInitialState();
            break;
        case AssetTalentConstants.GET.SUCCESS:
            state = state.set('talent', action.talent);
            state = state.set('originalTalent', action.talent);
            state = state.set('lastOrder', action.lastOrder);
            break;
        case AssetTalentConstants.REMOVE:
            ids = action.talent.map(t => {return t.get('id');});
            let keep = state.get('talent').filter(t => {
                return ids.indexOf(t.get('id')) === -1;
            });
            state = state.set('talent', keep);
            break;
        case AudioConstants.AUDIO.SAVE.SUCCESS:
        case DocumentConstants.DOCUMENT.SAVE.SUCCESS:
        case ImageConstants.IMAGE.SAVE.SUCCESS:
        case MerchandiseConstants.MERCHANDISE.SAVE.SUCCESS:
        case VideoConstants.VIDEO.SAVE.SUCCESS:
        case TimelineAssetConstants.TIMELINE.SAVE.SUCCESS:
            state = state.merge({
                originalTalent: state.get('talent')
            });
            break;
        }

        return state;
    }
}

let store = new AssetTalentStore(Dispatcher);

export default store;
