/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';

import {AlertTypes} from '../common/notification/alert';
import {NotificationActions} from '../common/notification/notification-actions';
import {UploadFile} from '../common/utils/utils';
import Dispatcher from '../dispatcher/dispatcher';
import {PreloaderActions} from '../preloader/preloader-actions';
import Request from '../request';
import {RouterActions} from '../router/router-actions';

const CONSTANTS = {
    CLEAR: 'login_background_actions.clear',
    GET: {
        ERROR: 'login_background_actions.get.error',
        SUCCESS: 'login_background_actions.get.success'
    },
    GET_PARTNERS: 'login_background_actions.get_partners',
    REMOVE_BACKGROUND: 'login_background_actions.remove_background',
    TOGGLE_SELECT: 'login_background_actions.select',
    TOGGLE_SELECT_ALL: 'login_background_actions.select.all',
    UPDATE: 'login_background_actions.update',
    UPLOAD_BACKGROUND: 'login_background_actions.upload_background'
};

class LoginBackgroundActions {

    addBackground(partnerId, file) {
        if (!file) {return;}
        const preloaderSource = 'login_background_actions.upload_background';
        PreloaderActions.show(preloaderSource);
        return UploadFile(
            'PUT',
            `web/login-content/${partnerId}/file`,
            file,
            new XMLHttpRequest(),
        ).then(() => {
            PreloaderActions.hide(preloaderSource);
            NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'login-backgrounds.success.upload');
            this.getPartnerBackgrounds(partnerId);
        }).catch(err => {
            PreloaderActions.hide(preloaderSource);
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'login-backgrounds.error.upload');
            throw err;
        });
    }

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
    }

    getPartnerBackgrounds(partnerId) {
        const preloaderSource = 'login_background_actions.get';
        PreloaderActions.show(preloaderSource);
        Request.get(`web/login-content/${partnerId}`)
            .exec()
            .then(res => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET.SUCCESS,
                    backgrounds: Immutable.fromJS(res.body).sortBy(r => r.get('fileName'))
                });
                PreloaderActions.hide(preloaderSource);
                return;
            })
            .catch((err) => {
                PreloaderActions.hide(preloaderSource);
                switch (err.status) {
                case 404:
                    RouterActions.notFound();
                    break;
                default:
                    NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'login-backgrounds.error.get-backgrounds');
                    break;
                }
                throw err;
            });
        return;
    }

    getPartners() {
        const preloaderSource = 'login_background_actions.get_partners';
        PreloaderActions.show(preloaderSource);
        Request.get('web/login-content/partner')
            .exec()
            .then(res => {
                let partners = [];
                res.body.map(p => {
                    let newP = Object.assign({}, p);
                    newP.id = p.partner;
                    newP.name = p.partner.split('_').join(' ');
                    partners.push(newP);
                    if (p.partner !== p.partnerSuperType) {
                        partners.push({'id': p.partnerSuperType, 'name': p.partnerSuperType.split('_').join(' '), 'partner': p.partnerSuperType, 'partnerSuperType': p.partnerSuperType});
                    }
                });

                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET_PARTNERS,
                    partners: Immutable.fromJS(partners).toSet().sort()
                });
                PreloaderActions.hide(preloaderSource);
                return;
            })
            .catch((err) => {
                PreloaderActions.hide(preloaderSource);
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'login-backgrounds.error.get-partners');
                throw err;
            });
        return;
    }

    removeBackgrounds(partnerId, files) {
        const preloaderSource = 'login_background_actions.remove_background';
        PreloaderActions.show(preloaderSource);
        // Send a DELETE request for each file in the files array.
        Promise.all(files.map(
            file => Request.del(`web/login-content/${partnerId}/${encodeURIComponent(file.get('fileName'))}`)
                .exec()).toJS())
            .then(() => {
                PreloaderActions.hide(preloaderSource);
                NotificationActions.showAlertSuccess('login-backgrounds.success.remove');
                this.getPartnerBackgrounds(partnerId);
                return;
            })
            .catch((err) => {
                PreloaderActions.hide(preloaderSource);
                NotificationActions.showAlertDanger('login-backgrounds.error.remove');
                throw err;
            });
        return;
    }

    toggleSelect(value, fileName) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TOGGLE_SELECT,
            fileName,
            value
        });

        return;
    }

    toggleSelectAll(value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TOGGLE_SELECT_ALL,
            value: value
        });

        return;
    }

    update(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.UPDATE,
            attr: attr,
            value: value
        });

        return;
    }

}

let actions = new LoginBackgroundActions();

export {
    actions as LoginBackgroundActions,
    CONSTANTS as LoginBackgroundConstants
};
