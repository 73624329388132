/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React, {Component} from 'react';

import {BrandSelectActions} from './brand-select-actions';
import BrandSelectStore from './brand-select-store';
import BaseSelect from '../../common/base-select/base-select';

class BrandSelect extends Component {
    getter() {
        setTimeout(() => {
            if (BrandSelectStore.isEmpty()) {
                BrandSelectActions.get(0, 9999);
            }
        });
        return;
    }
    render() {
        return (
            <BaseSelect
                {...this.props}
                getter={this.getter}
                store={BrandSelectStore}
            />
        );
    }
}
export default BrandSelect;
