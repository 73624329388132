/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {LanguageCodeType} from '@wbdt-sie/brainiac-web-common';
import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {PublishingListLocalizedConstants} from './publishing-list-localized-actions';
import Validations from '../common/validations/validations';
import Dispatcher from '../dispatcher/dispatcher';

const PublishingListLocalizedValidations = {
    displayName: {validations: [Validations.required]},
};

class PublishingListLocalizedStore extends ReduceStore {

    getInitialState() {
        let state = Immutable.fromJS({
            currentLanguage: LanguageCodeType.DEFAULT_LANGUAGE,
            itemLocalized: Immutable.Map(),
            listItemsLocalized: Immutable.List(),
            newLanguage: undefined,
            publishingListLocalized: Immutable.Map(),
            showAddNew: false
        });
        return state.merge({
            originalListItemsLocalized: state.get('listItemsLocalized'),
            originalPublishingListLocalized: state.get('publishingListLocalized')
        });
    }

    getValidations() {
        return this.getState().get('publishingListLocalized').reduce( (arr, loc) => {
            return arr.concat(Validations.validate(loc, PublishingListLocalizedValidations));
        }, []);
    }

    reduce(state, action) {
        switch (action.actionType) {

        case PublishingListLocalizedConstants.CLEAR:
        case PublishingListLocalizedConstants.GET.START:
            state = this.getInitialState();
            break;

        case PublishingListLocalizedConstants.GET.SUCCESS:
            state = state.merge({
                publishingListLocalized: action.publishingListLocalized,
                originalPublishingListLocalized: action.publishingListLocalized
            });
            break;

        case PublishingListLocalizedConstants.ADD_NEW:

            state = state.setIn(['publishingListLocalized', action.languageCode], Immutable.Map({
                languageCode: action.languageCode
            })).set('currentLanguage', action.languageCode);


            state = state.setIn(['listItemsLocalized', action.languageCode], Immutable.List()).set('currentLanguage', action.languageCode);
            break;

        case PublishingListLocalizedConstants.ADD_NEW_SHOW:
            state = state.set('showAddNew', true);
            break;

        case PublishingListLocalizedConstants.ADD_NEW_HIDE:
            state = state.set('showAddNew', false);
            break;

        case PublishingListLocalizedConstants.ITEM.EDIT.SET:
            if (!action.itemsLocalized) {
                action.itemsLocalized = Immutable.List();
            }

            if (action.itemsLocalized.get(0)) {
                let item = action.itemsLocalized.find(i => {
                    if (i.getIn(['publishListItemId']) === action.itemId) {
                        return i;
                    }
                });

                if (!item) {
                    item = Immutable.Map({'publishListItemId': action.itemId, 'languageCode': state.get('currentLanguage')});
                }
                state = state.set('itemLocalized', item);
            } else {
                state = state.set('itemLocalized', Immutable.Map({'publishListItemId': action.itemId, 'languageCode': state.get('currentLanguage')}));
            }
            break;

        case PublishingListLocalizedConstants.ITEM.GET.SUCCESS:
            state = state.merge({
                listItemsLocalized: action.listItemsLocalized,
                originalListItemsLocalized: action.listItemsLocalized
            });
            break;

        case PublishingListLocalizedConstants.REMOVE_CURRENT_LANGUAGE:
            state = state.removeIn(['publishingListLocalized', state.get('currentLanguage')])
                .set('currentLanguage', LanguageCodeType.DEFAULT_LANGUAGE);

            state = state.removeIn(['listItemsLocalized', state.get('currentLanguage')])
                .set('currentLanguage', LanguageCodeType.DEFAULT_LANGUAGE);
            break;

        case PublishingListLocalizedConstants.SET_CURRENT_LANGUAGE:
            state = state.set('currentLanguage', action.languageCode);
            break;

        case PublishingListLocalizedConstants.SET_NEW_LANGUAGE:
            state = state.set('newLanguage', action.languageCode);
            break;

        case PublishingListLocalizedConstants.UPDATE:
            state = state.setIn(['publishingListLocalized', state.get('currentLanguage'), ...action.attr.split('.')], action.value);
            break;

        case PublishingListLocalizedConstants.ITEM.UPDATE:
            state = state.setIn(['itemLocalized', ...action.attr.split('.')], action.value);
            break;

        case PublishingListLocalizedConstants.ITEMS.UPDATE:
            if (!state.getIn(['listItemsLocalized', action.currentLanguage])) {
                state = state.setIn(['listItemsLocalized', action.currentLanguage], Immutable.List());
            }

            let oldItems = state.get('listItemsLocalized');
            if (state.getIn(['listItemsLocalized', action.currentLanguage]).get(0)) {
                state.getIn(['listItemsLocalized', state.get('currentLanguage')]).map((item, index) => {
                    if (item.getIn(['publishListItemId'])) {
                        if (item.get('publishListItemId') === action.itemLocalized.get('publishListItemId')) {
                            state = state.mergeIn(['listItemsLocalized', state.get('currentLanguage'), index], action.itemLocalized);
                        }
                    }
                });
                if (state.get('listItemsLocalized') === oldItems) {
                    let index = state.getIn(['listItemsLocalized', state.get('currentLanguage')]).size;
                    state = state.mergeIn(['listItemsLocalized', state.get('currentLanguage'), index], action.itemLocalized);
                }
            } else {
                state = state.mergeIn(['listItemsLocalized', state.get('currentLanguage'), 0], action.itemLocalized);
            }
            break;

        case PublishingListLocalizedConstants.ITEM.SAVE.SUCCESS:
            state = state.set('originalListItemsLocalized', state.get('listItemsLocalized'));
            break;

        case PublishingListLocalizedConstants.SAVE.SUCCESS:
            state = state.set('originalPublishingListLocalized', state.get('publishingListLocalized'));
            break;
        }
        return state;
    }
}

let store = new PublishingListLocalizedStore(Dispatcher);

export default store;
export {PublishingListLocalizedValidations};
