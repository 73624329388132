/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {LanguageCodeType} from '@wbdt-sie/brainiac-web-common';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, DropdownButton, MenuItem} from 'react-bootstrap';

import AddTitle from './add-title';
import ExportList from './export-list';
import {DownloadActions} from '../../../common/download/download-actions';
import SlidingPanel from '../../../common/sliding-panel/sliding-panel';
import {SlidingPanelActions} from '../../../common/sliding-panel/sliding-panel-actions';
import {CompareImmutable} from '../../../common/utils/utils';
import {WorkOrdersConstants} from '../work-orders-actions';

let attrs = [
    'workOrderTitles',
    'exportSpecification'
];

let slidingPanelId = 'add-title-to-work-order-sliding-panel';

class Export extends Component {

    static get propTypes() {
        return {
            canReadTitles: PropTypes.bool,
            disabled: PropTypes.bool,
            location: PropTypes.object.isRequired,
            workOrder: PropTypes.instanceOf(Immutable.Map).isRequired,
            workOrderTitles: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true,
            canReadTitles: false
        };
    }

    constructor(props) {
        super(props);

        this.handleAddItem = this.handleAddItem.bind(this);
    }

    shouldComponentUpdate(nextProps/*, nextState, nextContext*/) {
        if (nextProps.disabled !== this.props.disabled ||
            nextProps.workOrderTitles !== this.props.workOrderTitles ||
            !CompareImmutable(nextProps.workOrder, this.props.workOrder, attrs)) {

            return true;
        }
        return false;
    }

    handleAddItem() {
        SlidingPanelActions.show(slidingPanelId);
        return;
    }

    render() {
        let titles = this.props.workOrderTitles || Immutable.List();
        let slidingPanelTitle = <span>
            {this.context.intl.messages['titles.work-orders.export.sliding-panel.title']}&nbsp;<small>{this.context.intl.messages['titles.work-orders.export.sliding-panel.subtitle']}</small>
        </span>;
        let id = this.props.workOrder.get('id');
        let format = this.props.workOrder.get('exportSpecification');

        return (
            <div>
                <h3><i className="far fa-list-alt"></i>{this.context.intl.messages['titles.work-orders.export.title']}&nbsp;<small>{titles.size}</small>
                    <div className="pull-right">
                        <DropdownButton
                            className="btn-block dropdown-toggle"
                            disabled={!id}
                            bsStyle="default"
                            title={<span><i className="fas fa-download"></i>{this.context.intl.messages['titles.work-orders.export.list']}</span>}
                        >
                            {Object.keys(LanguageCodeType.LANGUAGES).sort((a, b) => LanguageCodeType.LANGUAGES[a].localeCompare(LanguageCodeType.LANGUAGES[b])).filter(
                                lang => {
                                    if (format === WorkOrdersConstants.EXPORT_SPECIFICATION_BY_NAME.MASS_UPDATE) {
                                        return lang === LanguageCodeType.LANGUAGES_BY_NAME.ENGLISH;
                                    }

                                    return true;
                                }
                            ).map(
                                lang => {
                                    let query = {
                                        'export-specification': format,
                                        language: lang
                                    };

                                    return (
                                        <MenuItem key={lang} onClick={() => DownloadActions.startDownloadExecution(`work-order/${id}/export-master-meta-data`, query)}>
                                            {LanguageCodeType.LANGUAGES[lang]}
                                        </MenuItem>
                                    );
                                }
                            )}
                        </DropdownButton>
                        &nbsp;
                        <Button bsStyle="primary" className="bg-wb-blue" onClick={this.handleAddItem}>
                            <i className="fas fa-plus-square"></i>&nbsp;Add an Item to List
                        </Button>
                    </div>
                </h3>
                <ExportList displayLinks={this.props.canReadTitles} titles={titles} />
                <SlidingPanel
                    icon="far fa-newspaper"
                    id={slidingPanelId}
                    title={slidingPanelTitle}
                >
                    <AddTitle location={this.props.location} slidingPanelId={slidingPanelId}/>
                </SlidingPanel>
            </div>
        );
    }

}

export default Export;
