/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {DOCUMENT_CT_TYPES, STATION_DOC_ASSET_NAMING, isStation} from '@wbdt-sie/brainiac-web-common';
import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ControlLabel, FormControl, FormGroup} from 'react-bootstrap';

import BaseSelect from '../../../../common/base-select/base-select';
import {FormItem, FormRow, FormSection} from '../../../../common/form/form';
import TinyMCE from '../../../../common/tinymce/tinymce';
import {CompareImmutable} from '../../../../common/utils/utils';
import {WithValidations} from '../../../../common/validations/validations';
import config from '../../../../config/config.js';
import {AssetConstants} from '../../../asset-actions';
import AssetStore from '../../../asset-store';
import {DocumentActions} from '../../document-actions';
import DocumentStore, {DocumentAssetValidations} from '../../document-store';


/**
 * Document props to watch.
 */
let documentProps = [
    'active', 'assetComment', 'body', 'documentType', 'copyrightYear', 'copyrightText',
    'keyword', 'name', 'updatedBy', 'updatedDate'
];

class Summary extends Component {

    static get propTypes() {
        return {
            asset: PropTypes.instanceOf(Immutable.Map).isRequired,
            disabled: PropTypes.bool,
            filterContentType: PropTypes.func,
            mode: PropTypes.string.isRequired,
            title: PropTypes.string,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true,
            filterContentType: undefined,
            title: undefined
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            isContentTypeDirty: false,
            isMimeTypeDirty: false,
            selectedMimeType: AssetStore.getAssetMimeType(AssetConstants.MIME_TYPES_DOCUMENT, this.props.asset.get('mimeType')),
            selectedContentType: DocumentStore.getContentType(
                this.props.asset.get('documentSuperType'),
                this.props.asset.get('documentType')
            )
        };

        this.handleRunDateChange = this.handleRunDateChange.bind(this);
        this.handleSelectContentType = this.handleSelectContentType.bind(this);
        this.handleSelectMimeType = this.handleSelectMimeType.bind(this);
        this.handleTinyMCEChange = this.handleTinyMCEChange.bind(this);
        this.setAssetName = this.setAssetName.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.title && !this.state.isContentTypeDirty && this.props.asset.get('name') === nextProps.asset.get('name')) {
            DocumentActions.update('name', this.getTitleName(this.props.title));
        }
        this.setState({
            selectedContentType: DocumentStore.getContentType(
                nextProps.asset.get('documentSuperType'),
                nextProps.asset.get('documentType')
            ),
            selectedMimeType: AssetStore.getAssetMimeType(AssetConstants.MIME_TYPES_DOCUMENT, nextProps.asset.get('mimeType')),
        });
        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.disabled !== this.props.disabled ||
            !CompareImmutable(nextProps.asset, this.props.asset, documentProps)) {
            return true;
        }

        if (nextState.selectedContentType !== this.state.selectedContentType ||
            nextState.selectedMimeType !== this.state.selectedMimeType) {
            return true;
        }

        return false;
    }

    componentWillUnmount() {
        if (this.props.title) {
            DocumentActions.clear();
        }
    }

    getTitleName(titleName) {
        // Returns reformatted title name with special attention for those names ending in ", The" or ", A":
        // Big Bang Theory, The --> The Big Bang Theory
        // Nightmare On Elm Steet, A --> Nightmare On Elm Steet, A
        if (titleName.endsWith(', The')) {
            return `The ${titleName.substring(0, titleName.length - 5)}`;
        }

        if (titleName.endsWith(', A')) {
            return `A ${titleName.substring(0, titleName.length - 3)}`;
        }

        return titleName;
    }

    handleRunDateChange(attr, value) {
        DocumentActions.update(attr, value);
        this.setAssetName(this.props.asset.get('documentType'), value);
    }

    handleSelectContentType(option) {
        const lastSelectionST = this.state.selectedContentType?.toJS();
        if (option === null) {
            DocumentActions.update('documentSuperType', null);
            DocumentActions.update('documentType', null);
            DocumentActions.update('formatRunDate', null);
            DocumentActions.update('assetComment', null);
            DocumentActions.update('name', null);
            return;
        }

        this.setState({isContentTypeDirty: true});

        DocumentActions.update('documentSuperType', option.documentSuperType);
        DocumentActions.update('documentType', option.id);

        if (!DOCUMENT_CT_TYPES.isStationDocumentRunDateAllowed(option.id)) {
            // Remove any runDate value if the content type is not a format sheet or air schedules
            DocumentActions.update('formatRunDate', null);
        }

        //Only do renaming if document is of DTDS type.
        if (isStation(option)) {
            this.setAssetName(option.id, this.props.asset.get('formatRunDate'));
            return;
        }

        if (isStation(lastSelectionST)) {
            DocumentActions.update('name', '');
            DocumentActions.update('assetComment', '');
        }
        return;
    }

    handleSelectMimeType(option) {
        if (!this.state.isMimeTypeDirty) {
            this.setState({isMimeTypeDirty: true});
        }
        if (option === null) {
            DocumentActions.update('mimeType', null);
            return;
        }
        DocumentActions.update('mimeType', option.id);
        return;
    }

    handleTinyMCEChange(attr) {
        return function handler(e) {
            DocumentActions.update(attr, e.target.getContent());
            return;
        };
    }

    setAssetName(contentTypeId, formatRunDate) {
        // Autofill the Name and Display Name fields (see STUDIO-9969 for business logic)
        const ct = DOCUMENT_CT_TYPES.CONTENT_TYPE_MAP[contentTypeId];

        let assetName = '';
        let runDate = Moment();
        if (formatRunDate) {
            runDate = Moment(formatRunDate);
        }
        switch (ct.assetNaming) {
        case STATION_DOC_ASSET_NAMING.SELECTED_MONTH:
            if (runDate.isValid()) {
                assetName = runDate.format('MMMM');
            }
            break;

        case STATION_DOC_ASSET_NAMING.SELECTED_DAY_OF_WEEK:
            if (runDate.isValid()) {
                assetName = runDate.format('dddd');
            }
            break;

        case STATION_DOC_ASSET_NAMING.WEEKEND:
            assetName = 'Weekend';
            break;

        case STATION_DOC_ASSET_NAMING.DEFAULT:
        default:
            assetName = ct.name;
            break;
        }

        DocumentActions.update('name', assetName);
        DocumentActions.update('assetComment', assetName);
    }

    render() {
        let updatedDate = <div>N/A</div>;
        let updatedBy = <div>N/A</div>;
        let additionalInformation;
        let runDate = <div></div>;

        if (this.props.mode === 'edit') {

            if (this.props.asset.get('updatedDate')) {
                updatedDate = Moment(this.props.asset.get('updatedDate')).tz(config.DefaultTimezone).format(this.context.intl.messages['datetime-format']);
            } else {
                updatedDate = '-';
            }

            if (this.props.asset.get('updatedBy')) {
                updatedBy = this.props.asset.get('updatedBy');
            } else {
                updatedBy = '-';
            }

            additionalInformation = <FormRow>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['asset.edit.last-modified-date']}</ControlLabel>
                    <FormControl.Static>{updatedDate}</FormControl.Static>
                </FormGroup>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['asset.edit.last-modifier']}</ControlLabel>
                    <FormControl.Static>{updatedBy}</FormControl.Static>
                </FormGroup>
            </FormRow>;
        }

        if (DOCUMENT_CT_TYPES.isStationDocumentRunDateAllowed(this.state.selectedContentType?.get('id'))) {
            runDate = <FormGroup md={4}>
                <FormItem
                    attr="formatRunDate"
                    label={<ControlLabel>{this.context.intl.messages['asset.document.run-date']}&nbsp;<span className="text-red">*</span></ControlLabel>}
                    model={this.props.asset}
                    setter={this.handleRunDateChange}
                    type="date"
                />
            </FormGroup>;
        }

        return (
            <div>
                <h3><i className="fas fa-info-circle"></i>{this.context.intl.messages['asset.info']}</h3>
                <FormSection>
                    <FormRow>
                        <FormItem
                            attr="name"
                            label={this.context.intl.messages['asset.document.name']}
                            disabled={this.props.disabled}
                            model={this.props.asset}
                            required
                            setter={DocumentActions.update}
                            type="text"
                            validations={DocumentAssetValidations.name.validations}
                        />
                        <FormItem
                            attr="assetComment"
                            label={this.context.intl.messages['asset.document.assetComment']}
                            disabled={this.props.disabled}
                            model={this.props.asset}
                            required
                            setter={DocumentActions.update}
                            type="text"
                            validations={DocumentAssetValidations.assetComment.validations}
                        />
                    </FormRow>
                    <FormRow>
                        <FormGroup md={6}>
                            <ControlLabel>{this.context.intl.messages['asset.document.contentType']}&nbsp;<span className="text-red">*</span></ControlLabel>
                            <BaseSelect
                                disabled={this.props.disabled}
                                filterOptions={this.props.filterContentType}
                                groupBy="subType"
                                name={this.context.intl.messages['asset.document.contentType']}
                                onChange={this.handleSelectContentType}
                                options="documentType"
                                store={DocumentStore}
                                value={this.state.selectedContentType}
                            />
                        </FormGroup>
                        <FormGroup md={2}>
                            <ControlLabel>{this.context.intl.messages['common.status']}</ControlLabel>
                            <FormItem
                                attr="active"
                                disabled={this.props.disabled}
                                label={this.context.intl.messages['common.active']}
                                model={this.props.asset}
                                setter={DocumentActions.update}
                                type="bool-checkbox"
                            />
                        </FormGroup>
                        {runDate}
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['asset.image.mimeType']}&nbsp;</ControlLabel>
                            <BaseSelect
                                disabled={this.props.disabled}
                                name={this.context.intl.messages['asset.image.mimeType']}
                                onChange={this.handleSelectMimeType}
                                options="documentMimeTypes"
                                store={AssetStore}
                                value={this.state.selectedMimeType}
                            />
                        </FormGroup>
                        <FormItem attr="keyword"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['asset.document.keywords']}
                            setter={DocumentActions.update}
                            model={this.props.asset}
                            type="text"
                            validations={DocumentAssetValidations.keyword.validations}
                        />
                    </FormRow>
                    <FormRow>
                        <FormItem attr="copyrightYear"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['asset.document.copyright.year']}
                            setter={DocumentActions.update}
                            md={3}
                            model={this.props.asset}
                            type="text"
                            validations={DocumentAssetValidations.copyrightYear.validations}
                        />
                        <FormItem attr="copyrightText"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['asset.document.copyright.text']}
                            setter={DocumentActions.update}
                            model={this.props.asset}
                            type="text"
                            validations={DocumentAssetValidations.copyrightText.validations}
                        />
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['asset.document.body']}</ControlLabel>
                            <TinyMCE
                                content={this.props.asset.get('body') || ''}
                                id="body-comment"
                                onChange={this.handleTinyMCEChange('body')}
                            />
                        </FormGroup>
                    </FormRow>
                    <hr />
                    {additionalInformation}
                </FormSection>
            </div>
        );
    }
}

export default WithValidations(Summary);
