/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import Summary from './summary/summary';
import DocumentTitle from '../../../common/document-title';
import FileInput from '../../../common/file-input';
import Panel from '../../../common/panel/panel';
import {Tab, Tabs} from '../../../common/routing-tab/routing-tab';
import {History, SideNotes} from '../../../common/side-notes/side-notes';
import {AlertsWarnings} from '../../../common/validations/alerts-warnings';
import ActionsMenu from '../../../layout/actions-menu/actions-menu';
import StickyHeader from '../../../layout/sticky-header/sticky-header';
import Preloader from '../../../preloader';
import {RouterActions} from '../../../router/router-actions';
import {ActionHistoryActions, ActionHistoryConstants} from '../../../system/action-history/action-history-actions';
import SessionStore from '../../../user/session/session-store';
import {AssetActions} from '../../asset-actions';
import {AssetTalentActions} from '../../asset-talent-actions';
import AssetTalentStore from '../../asset-talent-store';
import AssetUserStore from '../../asset-user-store';
import AssetCatalogStore from '../../catalogs/asset-catalog-store';
import CatalogsTab from '../../catalogs/catalogs';
import TalentTab from '../../talent/talent';
import TitleTab from '../../title/title';
import UserTab from '../../user/user';
import {ScriptActions} from '../script-actions';
import ScriptStore from '../script-store';

class Create extends React.Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            params: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired,
            route: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static getPermissions() {
        return {
            canCreate: SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.SCRIPT.CREATE),
            canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.SCRIPT.EDIT),
            canReadCatalogs: SessionStore.canUser(SessionStore.PERMISSIONS.CATALOGS.ASSET.READ),
            canReadTalent: SessionStore.canUser(SessionStore.PERMISSIONS.TALENT.READ),
            canReadTitles: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.READ),
            canReadUsers: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.READ)
        };
    }

    static getStores() {
        return [AssetUserStore, ScriptStore];
    }

    static calculateState() {
        return {
            asset: ScriptStore.getState().get('asset'),
            history: ScriptStore.getState().get('history'),
            originalAsset: ScriptStore.getState().get('originalAsset'),
            showPreloader: ScriptStore.getState().get('showPreloader'),
            showUserPreloader: AssetUserStore.getState().get('showPreloader')
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.addNote = this.addNote.bind(this);
        this.handleAddFile = this.handleAddFile.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
        this.handleElasticSearchSync = this.handleElasticSearchSync.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.isDirty = this.isDirty.bind(this);
        this.init = this.init.bind(this);

        return;
    }

    componentDidMount() {
        this.init(this.props);
        return;
    }

    addNote(description) {
        ActionHistoryActions.addNote(this.props.params.id, description, ActionHistoryConstants.ACTION_OBJECTS.SCRIPT);
    }

    handleAddFile(e) {
        ScriptActions.update('files', [...e.target.files]);
    }

    handleDownload() {
        AssetActions.download(this.props.params.id);
    }

    handleElasticSearchSync() {
        AssetActions.syncElasticSearch(this.props.params.id);
    }

    handleSave() {
        let options = {
            messages: {
                error: `asset.script.${this.props.route.mode}.error`,
                success: `asset.script.${this.props.route.mode}.success`
            }
        };

        const scriptFiles = this.state.asset.get('files') || [];

        ScriptActions.save(
            this.state.asset,
            scriptFiles,
            AssetCatalogStore.getState().get('assignedCatalogs'),
            AssetCatalogStore.getState().get('originalAssignedCatalogs'),
            AssetTalentStore.getState().get('talent'),
            AssetTalentStore.getState().get('originalTalent'),
            options
        );

        return;
    }

    handleCancel() {
        RouterActions.redirect('/assets', true);
    }

    isDirty() {
        if (this.props.route.mode === 'create') {
            return false;
        }

        if (location) {
            let goingTo = location.pathname.split('/');
            let current = this.props.location.pathname.split('/');
            if (goingTo[1] === current[1] && /* asset */
                goingTo[2] === current[2] && /* script */
                goingTo[3] === current[3]) /* asset id */ {
                return false; // just changing tabs no confirmation needed.
            }
        }
        return !this.state.asset.equals(this.state.originalAsset) ||
        !AssetCatalogStore.getState().get('assignedCatalogs').equals(AssetCatalogStore.getState().get('originalAssignedCatalogs')) ||
        !AssetTalentStore.getState().get('talent').equals(AssetTalentStore.getState().get('originalTalent'));
    }


    init(props) {
        if (props.route.mode === 'edit') {
            ScriptActions.findById(props.params.id);
        } else {
            ScriptActions.clear();
            AssetTalentActions.clear();
        }
        RouterActions.registerRedirectCheck(() => {
            return this.isDirty();
        });
    }

    render() {
        let actionsMenu;
        let disableForm = true;
        let mode = this.props.route.mode;
        let fileDownload;
        let fileUpload;
        let filename = this.state.asset.get('fileName');
        let fileList = [];
        if (this.state.asset.get('files')) {
            fileList = this.state.asset.get('files', Immutable.List()).map(f => f.name);
        }

        let validations = ScriptStore.getValidations();
        let eventsAndNotes = null;

        fileUpload = <div>
            <Panel collapsible defaultExpanded iconClass="fas fa-upload" title={this.context.intl.messages['asset.script.file.upload']} additionalStyle="padding-x-20">
                <i>{filename}</i>
                <FileInput id="script-file" accept="application/pdf" onChange={this.handleAddFile} files={fileList}/>
            </Panel>
        </div>;

        let baseRoute = `/assets/script/${this.props.params.id}`;
        if (mode === 'create') {
            baseRoute = '/assets/script/create';
            if (this.props.permissions.canCreate) {
                disableForm = false;
            }

            actionsMenu = <ActionsMenu
                canSave={!validations.length}
                onCancel={this.handleCancel}
                onSave={this.handleSave}
                saveText={this.context.intl.messages['common.create-asset']}
            />;
        }

        // Tabs
        let catalogsTab;
        let historyTab;
        let talentTab;
        let titlesTab;
        let userTab;

        if (mode === 'edit') {
            if (this.props.permissions.canEdit) {
                disableForm = false;
            }

            fileUpload = <div>
                <Panel collapsible defaultExpanded iconClass="fas fa-upload" title={this.context.intl.messages['asset.script.file.replace']} additionalStyle="padding-x-20">
                    <FileInput id="script-file" accept="application/pdf" onChange={this.handleAddFile} files={fileList}/>
                </Panel>
            </div>;

            if (filename !== '') {
                fileDownload = <div>
                    <Panel collapsible iconClass="fas fa-download" title={this.context.intl.messages['asset.script.file.download']} additionalStyle="padding-x-20">
                        <a className="Cur(p)" onClick={this.handleDownload} style={{wordBreak: 'break-word'}} target="_blank">{filename}</a>
                    </Panel>
                </div>;
            }

            actionsMenu = <ActionsMenu
                canEdit={this.props.permissions.canEdit}
                canSave={!validations.length && this.props.permissions.canEdit}
                hideSaveAndSync={!this.props.permissions.canEdit}
                onCancel={this.handleCancel}
                onElasticSearchSync={this.handleElasticSearchSync}
                onSave={this.handleSave}
            />;

            eventsAndNotes = (
                <SideNotes
                    basePath={`/assets/script/${this.props.params.id}`}
                    dialogMessage={this.context.intl.messages['common.side-notes.add-note.body']}
                    disabled={ !this.props.permissions.canEdit }
                    notes={this.state.history.toJS().slice(0, 4)}
                    onAddNote={this.addNote}
                    title={this.context.intl.messages['common.side-notes.title']}
                />
            );

            historyTab = <Tab route={`${baseRoute}/history`} title={this.context.intl.messages['common.tab-title.history']} tabClassName="pull-right">
                <History
                    actionObject={ActionHistoryConstants.ACTION_OBJECTS.SCRIPT}
                    id={this.props.params.id}
                    pagination
                    pathname={this.props.location.pathname}
                    query={this.props.location.query}/>
            </Tab>;

            titlesTab = <Tab route={`${baseRoute}/titles`} title={this.context.intl.messages['titles.create.edit-title-tab-title']}>
                <TitleTab
                    assetId={parseInt(this.props.params.id, 10)}
                    displayLinks={this.props.permissions.canReadTitles}
                    location={this.props.location}
                />
            </Tab>;
            talentTab = <Tab route={`${baseRoute}/talent`} title={this.context.intl.messages['asset.talent.tab.title']}>
                <TalentTab
                    assetId={parseInt(this.props.params.id, 10)}
                    displayLinks={this.props.permissions.canReadTalent}
                />
            </Tab>;
            userTab = <Tab route={`${baseRoute}/users`} title={this.context.intl.messages['asset.user.tab.title']}>
                <UserTab
                    assetId={parseInt(this.props.params.id, 10)}
                    displayLinks={this.props.permissions.canReadUsers}
                    location={this.props.location}
                />
            </Tab>;
            catalogsTab = <Tab route={`${baseRoute}/catalogs`} title={this.context.intl.messages['asset.edit.catalogs-tab-title']}>
                <CatalogsTab
                    assetId={parseInt(this.props.params.id, 10)}
                    displayLinks={this.props.permissions.canReadCatalogs}
                />
            </Tab>;
        }

        let summaryTab = <Tab route={baseRoute} title={this.context.intl.messages['titles.create.summary-tab-title']}>
            <Summary disabled={disableForm} asset={this.state.asset} mode={mode}/>
        </Tab>;

        let entityName;
        if (this.state.asset.get('assetName')) {
            entityName = this.state.asset.get('assetName');
        }

        return (
            <DocumentTitle
                message={`asset.script.${mode}.title`} entityName={entityName}
            >
                <div>
                    <StickyHeader>
                        <div className="col-md-6">
                            <h1>
                                <i className="far fa-file-video"></i>&nbsp;{this.context.intl.messages[`asset.script.${mode}.title`]}
                                &nbsp;
                                <small>{this.state.asset.get('assetName')}</small>
                            </h1>
                            <p className="padding-top-20">
                                <em>{this.context.intl.messages[`asset.script.${mode}.info`]}</em>
                            </p>
                        </div>
                        {actionsMenu}
                    </StickyHeader>
                    <Preloader show={this.state.showPreloader||this.state.showUserPreloader} fixed loadingDots>
                        <section className="content" id="contentContainer">
                            <div className="row">
                                <div className="col-lg-9">
                                    <Tabs location={this.props.location}>
                                        {summaryTab}
                                        {titlesTab}
                                        {talentTab}
                                        {userTab}
                                        {catalogsTab}
                                        {historyTab}
                                    </Tabs>
                                </div>
                                <div className="col-lg-3">
                                    <AlertsWarnings
                                        title={this.context.intl.messages['common.alerts-warnings-title']}
                                        validations={validations}
                                    />
                                    {fileDownload}
                                    {fileUpload}
                                    {eventsAndNotes}
                                </div>
                            </div>
                        </section>
                    </Preloader>
                </div>
            </DocumentTitle>
        );
    }
}

export default Container.create(Create);
