/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import url from 'url';

import {version} from '../../package.json';
import Config from '../config/config';
import Request from '../request';
import SessionStore from '../user/session/session-store';

const CONSTANTS = {
    PLATFORMS: {
        ANDROID: 'ANDROID',
        IOS: 'IOS',
        LINUX: 'LINUX',
        MACOS: 'MACOS',
        ROKU: 'ROKU',
        TVOS: 'TVOS',
        WINDOWS: 'WINDOWS',
    },
    BROWSERS: {
        CHROME: 'CHROME',
        EDGE: 'EDGE',
        FIREFOX: 'FIREFOX',
        INTERNET_EXPLORER: 'INTERNET_EXPLORER',
        NOT_AVAILABLE: 'NOT_AVAILABLE',
        OPERA: 'OPERA',
        SAFARI: 'SAFARI'
    },
    VIDEO: {
        PLAYBACK_ERROR_TYPE: {
            BROWSER_NOT_SUPPORTED: 'BROWSER_NOT_SUPPORTED',
            DRM_NOT_SUPPORTED: 'DRM_NOT_SUPPORTED',
            DRM_REQUEST_FAILED: 'DRM_REQUEST_FAILED',
            UNKNOWN_ERROR: 'UNKNOWN_ERROR',
        },
        PLAYBACK_TYPE: {
            COMPLETE: 'PLAYBACK_COMPLETE',
            ERROR: 'PLAYBACK_ERROR',
            PAUSE: 'PLAYBACK_PAUSE',
            RESUME: 'PLAYBACK_RESUME',
            START: 'PLAYBACK_START',
        },
    },
};

const ANALYTICS_API = {
    [Config.Environments.DEV]: 'https://dev.analytics.wbtvd.com/',
    [Config.Environments.PRODUCTION]: 'https://analytics.wbtvd.com/',
    [Config.Environments.QA]: 'https://dev.analytics.wbtvd.com/',
    [Config.Environments.UAT]: 'https://uat.analytics.wbtvd.com/'
};

class WBTVDAnalytics {
    constructor() {
        this.api = ANALYTICS_API[Config.Environment];
    }

    event(path, event) {
        if (Config.Environment === Config.Environments.DEV || Config.Environment === Config.Environments.QA) {
            // Don't send analytics events in dev or QA environments
            return;
        }

        const jwt = SessionStore.getAuthorization('jwt');
        if (!jwt) {
            console.error('Error reading JWT token.');
            return;
        }

        const nav = window.navigator;
        Request.post(url.resolve(this.api, path)).send(Object.assign({
            operatingSystem: analyticsExtraInfo.getPlatform(nav),
            browser: analyticsExtraInfo.getBrowser(nav),
            appVersion: analyticsExtraInfo.getVersion(version),
            language: analyticsExtraInfo.getBrowserLanguage(nav)
        }, event)
        ).set(
            'Authorization', jwt
        ).exec().then(res => res).catch(err => {
            throw err;
        });
    }
}

class RequestExtraInfo {
    getBrowser(nav) {
        let browser;
        const browserData = this.getBrowserData(nav).toLowerCase();

        switch (true) {
        case browserData.includes('chrome'):
            browser = CONSTANTS.BROWSERS.CHROME;
            break;
        case browserData.includes('edge'):
            browser = CONSTANTS.BROWSERS.EDGE;
            break;
        case browserData.includes('firefox'):
            browser = CONSTANTS.BROWSERS.FIREFOX;
            break;
        case browserData.includes('internet explorer'):
            browser = CONSTANTS.BROWSERS.INTERNET_EXPLORER;
            break;
        case browserData.includes('opera'):
            browser = CONSTANTS.BROWSERS.OPERA;
            break;
        case browserData.includes('safari'):
            browser = CONSTANTS.BROWSERS.SAFARI;
            break;
        default:
            browser = CONSTANTS.BROWSERS.NOT_AVAILABLE;
        }
        return browser;
    }

    getBrowserData(nav) {
        let userAgent = nav.userAgent;
        let browserData = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        let versionData;
        if (/trident/i.test(browserData[1])) {
            versionData = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
            return `Internet Explorer ${versionData[1] || ''}`;
        }
        if (browserData[1] === 'Chrome') {
            versionData = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
            if (versionData !== null) {
                return `${versionData[1].replace('OPR', 'Opera')} ${versionData[2]}`;
            }
        }
        if (browserData[2]) {
            browserData = [browserData[1], browserData[2]];
        } else {
            browserData = [nav.appName, nav.appVersion, '-?'];
        }
        if ((versionData = userAgent.match(/version\/(\d+)/i)) !== null) {
            browserData.splice(1, 1, versionData[1]);
        }
        return `${browserData[0]} ${browserData[1]}`;
    }

    getBrowserLanguage(nav) {
        return nav.language || nav.userLanguage;
    }

    getPlatform(nav) {
        let OSName;
        let navData = nav.appVersion || nav.userAgent || nav.vendor || window.opera;
        navData = navData.toLowerCase();

        switch (true) {
        case navData.includes('android'):
            OSName = CONSTANTS.PLATFORMS.ANDROID;
            break;
        case navData.includes('ipad'):
        case navData.includes('iphone'):
        case navData.includes('ipod'):
            OSName = CONSTANTS.PLATFORMS.IOS;
            break;
        case navData.includes('linux'):
            OSName = CONSTANTS.PLATFORMS.LINUX;
            break;
        case navData.includes('mac'):
            OSName = CONSTANTS.PLATFORMS.MACOS;
            break;
        case navData.includes('roku'):
            OSName = CONSTANTS.PLATFORMS.ROKU;
            break;
        case navData.includes('webtv'):
            OSName = CONSTANTS.PLATFORMS.TVOS;
            break;
        case navData.includes('win'):
            OSName = CONSTANTS.PLATFORMS.WINDOWS;
            break;
        }
        return OSName;
    }

    getVersion(appVersion) {
        return appVersion || '';
    }
}

const wbtvdAnalytics = new WBTVDAnalytics();
const analyticsExtraInfo = new RequestExtraInfo();

export {
    wbtvdAnalytics as WBTVDAnalytics,
    CONSTANTS as WBTVDAnalyticsConstants,
    analyticsExtraInfo as WBTVDAnalyticsExtraInfo
};
