import Moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDatePicker from 'react-datepicker';

import {IsMobile} from '../utils/utils';

export default class DatePicker extends React.Component {
    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            onChange: () => void 0,
            showYearDropdown: true,
            className: undefined,
            dateFormat: undefined,
            disabled: undefined,
            placeholder: undefined,
            maxDate: undefined,
            minDate: undefined,
            selected: undefined
        };
    }

    static get propTypes() {
        return {
            className: PropTypes.string,
            dateFormat: PropTypes.string,
            disabled: PropTypes.bool,
            onChange: PropTypes.func,
            placeholder: PropTypes.string,
            maxDate: PropTypes.string,
            minDate: PropTypes.string,
            selected: PropTypes.object
        };
    }

    fromMoment(value) {
        if (!value || !value.isValid()) {
            return '';
        }

        return value.format('YYYY-MM-DD');
    }

    onChangeMobile(event) {
        this.props.onChange(Moment(event.target.value));
    }

    toMoment(value) {
        if (!value) {
            return null;
        }

        value = Moment(value);

        return value.isValid() ? value : null;
    }

    render() {
        let {maxDate, minDate, selected} = this.props;

        maxDate = this.toMoment(maxDate);
        minDate = this.toMoment(minDate);
        selected = this.toMoment(selected);

        if (!IsMobile.any()) {
            return <ReactDatePicker
                dateFormat={this.context.intl.messages['date-format']}
                isClearable={!this.props.disabled}
                {...this.props}
                onChange={(value) => this.props.onChange(value)}
                maxDate={maxDate}
                minDate={minDate}
                fixedHeight={true}
                popperPlacement="top"
                selected={selected}
            />;
        }

        return <input
            className={this.props.className}
            max={this.fromMoment(maxDate)}
            min={this.fromMoment(minDate)}
            disabled={this.props.disabled}
            onChange={(this.onChangeMobile.bind(this))}
            value={this.fromMoment(selected)}
            type="date"
        />;
    }
}
