/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {FormSection, FormRow, FormItem} from '../../common/form/form';
import config from '../../config/config.js';
import {RoleActions} from '../../security/role/role-actions';
import RoleStore, {RoleStoreValidations} from '../../security/role/role-store';

class Summary extends Component {
    static get propTypes() {
        return {
            disabled: PropTypes.bool,
            mode: PropTypes.string.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true
        };
    }

    static calculateState() {
        return {
            role: RoleStore.getState().get('role')
        };
    }

    static getStores() {
        return [RoleStore];
    }

    constructor(props) {
        super(props);

        // TODO: move state to store. and connect to missing API
        this.state = Object.assign({
            selectedCompanies: Immutable.OrderedSet(),
            selectedTerritories: Immutable.OrderedSet(),
            selectedGroups: Immutable.OrderedSet()
        }, this.constructor.calculateState());

    }

    render() {
        let modified = <div></div>;
        if (this.props.mode === 'edit') {
            modified = <FormRow additionalClass="padding-bottom-20">
                <div>
                    <label>{this.context.intl.messages['roles.edit.last-modified-date']}</label>
                    <br/>
                    {this.state.role.get('updatedDate') ?
                        Moment(this.state.role.get('updatedDate')).tz(config.DefaultTimezone).format(this.context.intl.messages['datetime-format']) : ' - '}
                </div>
                <div>
                    <label>{this.context.intl.messages['roles.edit.last-modifier']}</label>
                    <br/>
                    {this.state.role.get('updatedBy')}
                </div>
            </FormRow>;
        }

        return (
            <div className="tab-pane padding-x-20 padding-bottom-20">
                <FormSection iconClass="fas fa-info-circle" title={this.context.intl.messages['roles.edit.role-info']}>
                    <FormRow>
                        <FormItem attr="name"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['roles.edit.name']}
                            model={this.state.role}
                            onlySpaces
                            setter={RoleActions.updateRole}
                            type="text"
                            validations={RoleStoreValidations.name.validations} />
                        <div>
                            <label>{this.context.intl.messages['roles.edit.status']}</label>
                            <FormItem type="checkbox"
                                attr="isActive"
                                disabled={this.props.disabled}
                                label={this.context.intl.messages['roles.edit.status-active']}
                                model={this.state.role}
                                setter={RoleActions.updateRole}/>
                        </div>
                    </FormRow>
                    <hr/>
                    <FormRow>
                        <FormItem attr="description"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['roles.edit.role.description']}
                            model={this.state.role}
                            onlySpaces
                            setter={RoleActions.updateRole}
                            type="text"
                            validations={RoleStoreValidations.description.validations} />
                    </FormRow>
                    <hr/>
                    {modified}
                </FormSection>
            </div>
        );
    }
}

export default Container.create(Summary);
