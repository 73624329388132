/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import ClientRepGroupTable from './table';
import DocumentTitle from '../../common/document-title';
import SearchBox from '../../common/search-box/search-box';
import {WithStoreOnRoute} from '../../common/store-on-route';
import {Debounce} from '../../common/utils/utils';
import Preloader from '../../preloader/';
import PreloaderStore from '../../preloader/preloader-store';
import {ClientRepGroupActions} from '../../security/client-rep-group/client-rep-group-actions';
import ClientRepGroupStore from '../../security/client-rep-group/client-rep-group-store';
import SessionStore from '../../user/session/session-store';

class Browse extends Component {

    static get propTypes() {
        return {
            permissions: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            clientRepGroups: ClientRepGroupStore.getState().get('clientRepGroups'),
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible'),
            total: ClientRepGroupStore.getState().get('total'),
        };
    }

    static getStores() {
        return [PreloaderStore, ClientRepGroupStore];
    }

    static getPermissions() {
        return {
            canReadClientRepGroups: SessionStore.canUser(SessionStore.PERMISSIONS.GROUPS.CLIENT_REP_GROUPS.READ)
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.getClientRepGroupsCount = this.getClientRepGroupsCount.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
    }

    componentDidMount() {
        ClientRepGroupActions.get(0, 20);
        return;
    }

    getClientRepGroupsCount(count) {
        this.refs.clientRepGroupsCount.innerHTML = `(${count} ${this.context.intl.messages['groups.client-rep-group.title']})`;
        return;
    }

    handleSearchTerm(term) {
        this.setRouteState('q', term)
            .clearRouteState('offset')
            .apply();
    }

    render() {
        return (
            <DocumentTitle
                message="document-titles.client-rep-group"
            >
                <div>
                    <Preloader show={this.state.preloaderVisible} fixed loadingDots>
                        <section className="content-header">
                            <h1>
                                <i className="fas fa-list-ul"></i>&nbsp;{this.context.intl.messages['groups.client-rep-group.title']}
                                &nbsp;<small ref="clientRepGroupsCount"></small>
                            </h1>
                        </section>
                        <section className="content">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="container-fluid no-x-padding">
                                        <div className="row">
                                            <div className="col-md-6 show-grid">
                                                <SearchBox value={this.getRouteState().get('q')} onChange={this.handleSearchTerm}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container-fluid no-x-padding">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="box">
                                                    <div className="box-body">
                                                        <ClientRepGroupTable
                                                            displayLinks={this.props.permissions.canReadClientRepGroups}
                                                            filters={this.getRouteState().get('q')}
                                                            clientRepGroups={this.state.clientRepGroups}
                                                            clientRepGroupsCount={this.getClientRepGroupsCount}
                                                            pageSize={20}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Preloader>
                </div>
            </DocumentTitle>
        );
    }
}

export default WithStoreOnRoute(Container.create(Browse));
