/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import jQuery from 'jquery';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {AssetCatalogActions} from './asset-catalog-actions';

require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('../../styles/data-tables-brainiac.css');
require('datatables.net-responsive-bs');

class AssignedTable extends Component {
    static get propTypes() {
        return {
            assignedCatalogs: PropTypes.instanceOf(Immutable.List).isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        this.$table = jQuery('#assigned-catalogs-table');
        this.$tableAPI = this.$table.DataTable({
            autoWidth: false,
            columnDefs: [{
                className: 'control',
                targets:   -1,
                width: 20
            }, {
                targets: 'no-sort',
                orderable: false
            }, {
                width: '122',
                targets: 1
            }, {
                width: '56',
                targets: 2
            }],
            order: [[1, 'dec']],
            iDisplayLength: 100,
            info: false,
            ordering: false,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        });

        window.addEventListener('resize', this.handleResize);

        this.handleResize();
        return;
    }

    componentWillUpdate(nextProps /*, nextState*/) {
        this.$tableAPI.clear();

        nextProps.assignedCatalogs.forEach((catalog, i) => {
            let row = [];
            const catalogDescription = catalog.get('description') || '';
            const id = catalog.get('id');
            let catalogStatus = 'Inactive';
            if (catalog.get('isActive') === 1) {catalogStatus = 'Active';}

            const catalogType = catalog.get('displayAssetType') || '';
            const nameAndDescription = `<div>${catalog.get('name')}<small class="d-block" style="font-size: 80%">${this.context.intl.messages['common.description']}: ${catalogDescription}</small></div>`;

            row.push(
                nameAndDescription,
                catalogType,
                catalogStatus,
                `<button class="btn btn-small bg-navy remove-catalog" data-index="${i}" data-id="${id}"><i class="fas fa-times"></i>&nbsp;Remove</button>`,
                ''
            );

            this.$tableAPI.row.add(row);
            return;
        });

        this.$tableAPI.draw(false);
        this.handleResize();
        return;
    }

    componentWillUnmount() {
        if (this.$tableAPI) {
            this.$tableAPI.destroy();
        }
        return;
    }

    handleResize() {
        this.$tableAPI.responsive.recalc();
        return;
    }

    handleRemoveCatalog(event) {
        switch (true) {
        case !!~event.target.className.indexOf('remove-catalog'):
            AssetCatalogActions.removeById(parseInt(event.target.getAttribute('data-id')));
            break;
        case !!~event.target.className.indexOf('fa-times'):
            AssetCatalogActions.removeById(parseInt(event.target.parentNode.getAttribute('data-id')));
            break;
        }
        return;
    }

    render() {
        return (
            <table id="assigned-catalogs-table" className="-striped table table-bordered table-striped responsive">
                <thead>
                    <tr>
                        <th className="sorting_desc">{this.context.intl.messages['common.name-and-description']}</th>
                        <th>{this.context.intl.messages['asset.edit.catalogs-tab.type']}</th>
                        <th>{this.context.intl.messages['asset.edit.catalogs-tab.status']}</th>
                        <th>{this.context.intl.messages['asset.edit.catalogs-tab.actions']}</th>
                        <th className="no-sort"></th>
                    </tr>
                </thead>
                <tbody onClick={this.handleRemoveCatalog}>
                </tbody>
            </table>
        );
    }

}

export default AssignedTable;
