/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import createReactClass from 'create-react-class';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Modal} from 'react-bootstrap';

import {FormItem} from '../../common/form/form';
import UpdateInfo from '../../common/update-info/update-info';
import Validations from '../../common/validations/validations';

let duplicatedLookupFn = (allItems, attrName, value) => {
    let lookupItem = allItems.find(item => item.get(attrName).toLowerCase() === value.toLowerCase());
    return lookupItem === undefined;
};

export default createReactClass({
    propTypes: {
        attrName: PropTypes.string,
        item: PropTypes.instanceOf(Immutable.Map),
        items: PropTypes.instanceOf(Immutable.List),
        onHide: PropTypes.func.isRequired,
        onSave: PropTypes.func.isRequired,
        show: PropTypes.bool,
        title: PropTypes.string.isRequired
    },

    contextTypes: {
        intl: PropTypes.object.isRequired,
    },

    getDefaultProps: function() {
        return {
            attrName: 'name',
            item: Immutable.Map(),
            items: Immutable.List(),
            show: false,
        };
    },

    getInitialState: function() {
        return {
            item: Immutable.Map(),
        };
    },

    componentWillReceiveProps: function(nextProps) {
        this.setState({
            item: nextProps.item,
        });
        return;
    },

    updateAttr: function(attr, value) {
        this.setState(prevState => ({
            item: prevState.item.setIn([...attr.split('.')], value)
        }));
    },

    handleSave: function() {
        this.props.onHide();
        this.props.onSave(this.state.item);
    },

    isValid: function() {
        // check name
        let itemName = this.state.item.get(this.props.attrName);
        return Validations.required.validate(itemName)
            && Validations.max(50).validate(itemName)
            && Validations.custom(
                () => this.context.intl.messages['lookups.create.error'],
                duplicatedLookupFn.bind(duplicatedLookupFn, this.props.items, this.props.attrName)
            ).validate(itemName);
    },

    render: function() {
        let title;
        let additionalInformation;

        if (this.props.item === Immutable.Map()) {
            title = this.context.intl.messages['common.add'];
        } else {
            title = this.context.intl.messages['common.edit'];
            additionalInformation = <UpdateInfo updatedDate={this.props.item.get('updatedDate')} updatedBy={this.props.item.get('updatedBy')} />;
        }
        return (
            <Modal show={this.props.show} onHide={this.props.onHide}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="text-center">{title + ' ' + this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormItem
                        type="string"
                        attr={this.props.attrName}
                        label={this.props.title + ' ' + this.context.intl.messages['common.name']}
                        model={this.state.item}
                        required
                        showErrorMessage={true}
                        setter={this.updateAttr}
                        validations={[
                            Validations.max(50),
                            Validations.required,
                            Validations.custom(
                                () => this.context.intl.messages['lookups.create.error'],
                                duplicatedLookupFn.bind(duplicatedLookupFn, this.props.items, this.props.attrName)
                            )
                        ]}/>
                    {additionalInformation}
                </Modal.Body>
                <Modal.Footer>
                    <Button bsStyle="default" className="pull-left" onClick={this.props.onHide}>{this.context.intl.messages['common.cancel']}</Button>
                    <Button bsStyle="primary"
                        disabled={!this.isValid()}
                        className="bg-wb-blue"
                        onClick={this.handleSave}>{this.context.intl.messages['common.save']}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
});
