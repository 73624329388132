/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import Messages from './messages';
import Summary from './summary';
import DocumentTitle from '../../../common/document-title';
import {Tab, Tabs} from '../../../common/routing-tab/routing-tab';
import ActionsMenu from '../../../layout/actions-menu/actions-menu';
import Preloader from '../../../preloader';
import {RouterActions} from '../../../router/router-actions';
import SessionStore from '../../../user/session/session-store';
import {ProcessesActions, ProcessesConstants} from '../processes-actions';
import ProcessesStore from '../processes-store';

import './process-detail.less';

// for QC Proxy
const qcProxyTasks = [{
    eventType: ProcessesConstants.PROCESS_TYPES.ENCODE_FOR_INBOX_PROXY.EVENT_TYPES.AMSV3_ENCODE_DISPATCHED,
    finished: eventMessage =>
        eventMessage.eventType === ProcessesConstants.PROCESS_TYPES.ENCODE_FOR_INBOX_PROXY.EVENT_TYPES.AMSV3_ENCODE_DISPATCHED.id
    ,
    weight: 10,
}, {
    eventType: ProcessesConstants.PROCESS_TYPES.ENCODE_FOR_INBOX_PROXY.EVENT_TYPES.AMSV3_ENCODE_SCHEDULED,
    finished: eventMessage =>
        eventMessage.eventType === ProcessesConstants.PROCESS_TYPES.ENCODE_FOR_INBOX_PROXY.EVENT_TYPES.AMSV3_ENCODE_SCHEDULED.id
    ,
    weight: 10,
}, {
    eventType: ProcessesConstants.PROCESS_TYPES.ENCODE_FOR_INBOX_PROXY.EVENT_TYPES.AMSV3_ENCODE_PROCESSING,
    finished: eventMessage =>
        eventMessage.eventType === ProcessesConstants.PROCESS_TYPES.ENCODE_FOR_INBOX_PROXY.EVENT_TYPES.AMSV3_ENCODE_SUCCESS.id
    ,
    weight: 80
}];

class ProcessesDetail extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            permissions: PropTypes.object,
            params: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            process: ProcessesStore.getState().get('process'),
            progress: ProcessesStore.getState().get('progress'),
            messages: ProcessesStore.getState().get('messages'),
            showPreloader: ProcessesStore.getState().get('showPreloader'),
        };
    }

    static getStores() {
        return [ProcessesStore];
    }

    static get defaultProps() {
        return {
            permissions: {}
        };
    }

    static getPermissions() {
        return {
            canRead: SessionStore.canUser(SessionStore.PERMISSIONS.HARDAC.PROCESSES.READ),
        };
    }

    constructor(props) {
        super(props);

        this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount() {
        ProcessesActions.findById(this.props.params.id);
    }

    componentDidUpdate(prevProps, prevState) {
        const processId = this.state.process.get('id');
        const prevProcessId = prevState.process.get('id');
        if (processId !== prevProcessId) {
            ProcessesActions.reviewProgress(this.props.params.id, qcProxyTasks);
        }
    }

    handleCancel() {
        RouterActions.redirect('/hardac/processes', true);
    }

    render() {
        const entityName = this.state.process.get('processType');

        const baseRoute = `/hardac/processes/v2/${this.props.params.id}`;

        const summaryTab = (
            <Tab
                route={baseRoute}
                title={this.context.intl.messages['hardac.processes.summary']}
            >
                <Summary
                    process={this.state.process}
                    progress={this.state.progress}
                    tasks={qcProxyTasks}
                />
            </Tab>
        );

        const messagesTab = (
            <Tab
                route={`${baseRoute}/messages`}
                title={this.context.intl.messages['hardac.processes.messages.title']}
            >
                <Messages
                    processId={this.props.params.id}
                    location={this.props.location}
                    permissions={this.props.permissions}
                />
            </Tab>
        );

        const actionsMenu = (
            <ActionsMenu
                onCancel={this.handleCancel}
            />
        );

        return (
            <DocumentTitle
                message={'document-titles.hardac.processes'} entityName={entityName}
            >
                <div>
                    <Preloader show={this.state.showPreloader} fixed loadingDots>
                        <section className="content-header">
                            <div className="col-md-6">
                                <h3>
                                    <i className="fas fa-tasks-alt"></i>&nbsp;{this.context.intl.messages['hardac.processes.status']}
                                    <br/><small>{this.state.process.get('processType')}</small>
                                </h3>
                            </div>
                            {actionsMenu}
                        </section>
                        <section className="content" id="contentContainer">
                            <div className="row">
                                <div className="col-lg-12">
                                    <Tabs location={this.props.location}>
                                        {summaryTab}
                                        {messagesTab}
                                    </Tabs>
                                </div>
                            </div>
                        </section>
                    </Preloader>
                </div>
            </DocumentTitle>
        );
    }
}

export default Container.create(ProcessesDetail);
