/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import Select from 'react-select';

import Panel from '../../common/panel/panel';
import {WithStoreOnRoute} from '../../common/store-on-route';
import {LayoutActions} from '../../layout/layout-actions';
import {PermissionPackageConstants} from '../../security/permission-package/permission-package-actions';
import PermissionPackageStore from '../../security/permission-package/permission-package-store';

const noop = () => void 0;

const ActiveOptions = WithStoreOnRoute(class ActiveOptions extends Component {
    static get propTypes() {
        return {
            location: PropTypes.object,
            onChange: PropTypes.func,
            selected: PropTypes.number
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            location: undefined,
            onChange: noop,
            selected: undefined
        };
    }

    constructor(props) {
        super(props);

        this.setActiveFilter = this.setActiveFilter.bind(this);
    }

    setActiveFilter(e) {
        const filterAttr = 'active';
        let value = 'ACTIVE';

        if (e) {
            value = e.id;
        }

        if (this.props.location) {
            this.setRouteState(filterAttr, value)
                .setRouteState('operator', 'AND')
                .clearRouteState('offset')
                .apply();
        }

        this.props.onChange(filterAttr, value);
    }

    render() {
        let options = [];
        Object.keys(PermissionPackageConstants.FILTERS.ACTIVE_OPTIONS).map((option) => (
            options.push(PermissionPackageConstants.FILTERS.ACTIVE_OPTIONS[option])
        ));

        let selected = options[0];
        if (this.props.selected) {
            selected = options.find(op => op.id === this.props.selected);
        }

        return (
            <Panel title={this.context.intl.messages['filter-options.status']} classTitle="box-title" collapsible defaultExpanded>
                <div data-style="display: block;" className="box-body">
                    <div className="form-group">
                        <Select
                            getOptionLabel={data => data.name}
                            getOptionValue={data => data.id}
                            isClearable={false}
                            isMulti={false}
                            name="active"
                            options={options}
                            placeholder={this.context.intl.messages['filter-options.select.placeholder']}
                            value={selected}
                            onChange={this.setActiveFilter}
                        />
                    </div>
                </div>
            </Panel>
        );
    }
});

class FilterOptions extends Component {

    static get propTypes() {
        return {
            filters: PropTypes.instanceOf(Immutable.Map),
            location: PropTypes.object,
            showActive: PropTypes.bool,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            filters: undefined,
            location: undefined,
            showActive: true,
        };
    }

    constructor(props) {
        super(props);

        this.handleToggleFiltersPanel = this.handleToggleFiltersPanel.bind(this);
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.filters !== this.props.filters ||
            nextProps.location !== this.props.location) {
            return true;
        }

        return false;
    }

    handleToggleFiltersPanel() {
        LayoutActions.toggleFiltersPanel();
    }

    render() {
        let activeValue;

        if (this.props.location) {
            activeValue = this.props.location.query.active;
        } else {
            activeValue = PermissionPackageStore.getState().getIn(['filters', 'active']);
        }

        let activeOptions;

        if (this.props.showActive) {
            activeOptions = <ActiveOptions
                location={this.props.location}
                selected={activeValue}
            />;
        }

        return (
            <div>
                <aside className="control-sidebar B(0) Ovx(h) Ovy(a)">
                    <div className="tab-content">
                        <p>
                            <button className="btn btn-block bg-navy" onClick={this.handleToggleFiltersPanel}>
                                <i className="fas fa-chevron-right pull-right"></i>&nbsp;{this.context.intl.messages['filter-options.close-options.button']}
                            </button>
                        </p>
                        {activeOptions}
                    </div>
                </aside>
                <div style={{position: 'fixed', height: 'auto'}} className="control-sidebar-bg"></div>
            </div>
        );
    }
}

export default WithStoreOnRoute(FilterOptions);

export {
    ActiveOptions
};
