/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ControlLabel, FormGroup} from 'react-bootstrap';

import {FormSection, FormRow, FormItem} from '../../../common/form/form';
import {CompareImmutable} from '../../../common/utils/utils';
import {WithValidations} from '../../../common/validations/validations';
import TerritorySelect from '../../../lookup/territory/territory-select';
import {UserActions, UserConstants} from '../../../user/user-actions';
import {UserValidations} from '../../../user/user-store';

const userProps = [
    'country', 'referrerEmail'
];

class ContactInformation extends Component {

    static get propTypes() {
        return {
            disabled: PropTypes.bool,
            user: PropTypes.instanceOf(Immutable.Map).isRequired,
            userType: PropTypes.instanceOf(Immutable.Map)
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true,
            userType: Immutable.Map()
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            isTerritoryDirty: false
        };

        this.handleTerritoryChange = this.handleTerritoryChange.bind(this);
    }
    /**
     * The first if compares the props, the second one
     * compares the state.
     */
    shouldComponentUpdate(nextProps, nextState) {
        if (!CompareImmutable(nextProps.user, this.props.user, userProps)) {
            return true;
        }

        if (nextState.territories !== this.state.territories ||
            nextState.isTerritoryDirty !== this.state.isTerritoryDirty) {
            return true;
        }

        return false;
    }

    handleTerritoryChange(territory) {
        if (!this.state.isTerritoryDirty) {
            this.setState({isTerritoryDirty: true});
        }
        UserActions.updateUser('country', Immutable.fromJS(territory));
        return;
    }

    render() {
        // Territory label with the "*" to show it's required.
        const territoryLabel = <span>{this.context.intl.messages['accounts.create.summary.country']}&nbsp;<span className="text-red">*</span></span>;

        const disabled = this.props.disabled || (this.props.userType.get('id') === UserConstants.USER_TYPES.SECONDARY.id);

        return (
            <FormSection title={this.context.intl.messages['accounts.create.summary.contact-information']} iconClass="far fa-envelope">
                <FormRow>
                    <FormGroup md={4} validationState={this.getValidationState(this.props.user.get('country'), this.state.isTerritoryDirty, UserValidations.country.validations)}>
                        <ControlLabel>{territoryLabel}</ControlLabel>
                        <TerritorySelect
                            disabled={disabled}
                            name={this.context.intl.messages['accounts.create.summary.country']}
                            onChange={this.handleTerritoryChange}
                            placeholder={this.context.intl.messages['accounts.create.summary.country-placeholder']}
                            value={this.props.user.get('country')}
                        />
                    </FormGroup>

                    <FormItem md={4} attr="referrerEmail"
                        disabled={this.props.disabled}
                        label={this.context.intl.messages['accounts.create.summary.referrers-email']}
                        model={this.props.user}
                        setter={UserActions.updateUser}
                        type="text"
                        validations={UserValidations.referrerEmail.validations}
                    />
                </FormRow>
            </FormSection>
        );
    }
}

export default WithValidations(ContactInformation);
