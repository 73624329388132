/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';

import NumberField from './number-field';
import {DEFAULT_FRAME_RATE} from '../../constants';

import omit from '~/src/common/utils/omit';
import SMPTE, {FrameData} from '~/src/common/utils/smpte';

type Props = {
    frameRate?: number;
    label: string;
    onInput: (time: number) => void;
    time: number;
} & Partial<HTMLDivElement>;

const ORIGIN_PROPS: Array<keyof Props> = [
    'frameRate', 'label', 'onInput', 'time'
];

export default class TimecodeField extends React.PureComponent<Props> {
    constructor(props: Props) {
        super(props);

        this.handleHoursChanged = this.handleHoursChanged.bind(this);
        this.handleMinutesChanged = this.handleMinutesChanged.bind(this);
        this.handleFramesChanged = this.handleFramesChanged.bind(this);
        this.handleSecondsChanged = this.handleSecondsChanged.bind(this);

        this.frameRate = this.props.frameRate || DEFAULT_FRAME_RATE;
        this.smpte = SMPTE.create({frameRate: this.frameRate});
        this.maxFramesCount = Math.floor(this.frameRate);
    }

    private smpte = SMPTE.create();
    private maxFramesCount = 0;
    private frameRate = DEFAULT_FRAME_RATE;

    private handleHoursChanged(hours: number) {
        this.applyTime({hours});
    }

    private handleMinutesChanged(minutes: number) {
        this.applyTime({minutes});
    }

    private handleFramesChanged(framesCount: number) {
        this.applyTime({frames: framesCount});
    }

    private handleSecondsChanged(seconds: number) {
        this.applyTime({seconds});
    }

    private applyTime(fd: Partial<FrameData>) {
        const data = this.smpte.secondsToFrameData(this.props.time, this.frameRate);
        let time = this.smpte.frameDataToSeconds({...data, ...fd});
        time = Math.ceil((time * 1000)) / 1000;
        this.props.onInput(time);
    }

    render() {
        const data = this.smpte.secondsToFrameData(this.props.time, this.frameRate);
        return (
            <div className="timecode-field" {...omit(this.props, ORIGIN_PROPS)}>
                {this.props.label}
                <span> : </span>
                <NumberField
                    data-testid="hours"
                    value={data.hours}
                    max={99}
                    onInput={this.handleHoursChanged}
                />
                <span> : </span>
                <NumberField
                    data-testid="minutes"
                    value={data.minutes}
                    max={59}
                    onInput={this.handleMinutesChanged}
                />
                <span> : </span>
                <NumberField
                    data-testid="seconds"
                    value={data.seconds}
                    max={59}
                    onInput={this.handleSecondsChanged}
                />
                <span> : </span>
                <NumberField
                    data-testid="frames"
                    value={data.frames}
                    max={this.maxFramesCount}
                    onInput={this.handleFramesChanged}
                />
            </div>
        );
    }
}
