/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import Select from 'react-select';

import {HistoryActions, HistoryConstants} from './../browse-history-actions';
import {FormItem} from '../../common/form/form';
import Panel from '../../common/panel/panel';
import {WithStoreOnRoute} from '../../common/store-on-route';
import {LayoutActions} from '../../layout/layout-actions';
import HistoryStore from '../browse-history-store';

let objectTypeOptions = [];
Object.keys(HistoryConstants.FILTERS.OBJECT_TYPE_OPTIONS).map((option) => (
    objectTypeOptions.push(HistoryConstants.FILTERS.OBJECT_TYPE_OPTIONS[option])
));

let ObjectTypeOptions = WithStoreOnRoute(class ObjectTypeOptions extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object,
            onChange: PropTypes.func,
            selected: PropTypes.string
        };
    }

    static get defaultProps() {
        return {
            location: {},
            onChange: undefined,
            selected: ''
        };
    }

    constructor(props) {
        super(props);

        this.setObjectTypeFilter = this.setObjectTypeFilter.bind(this);
    }

    setObjectTypeFilter(e) {
        const filterAttr = 'action-object';
        let value = e? e.key : '';

        if (this.props.location) {
            this.setRouteState(filterAttr, value)
                .setRouteState('operator', 'AND')
                .clearRouteState('offset')
                .apply();
            return;
        }

        HistoryActions.setFilter(filterAttr, value);
        this.props.onChange();
    }

    render() {
        let selected = objectTypeOptions.find((item) => {
            return item.key === this.props.selected;
        });

        return (
            <Panel title="Object Type" classTitle="box-title" collapsible defaultExpanded>
                <div data-style="display: block;" className="box-body">
                    <div className="form-group">
                        <Select
                            getOptionLabel={data => data.name}
                            getOptionValue={data => data.id}
                            isClearable={true}
                            isMulti={false}
                            name="objectType"
                            onChange={this.setObjectTypeFilter}
                            options={objectTypeOptions}
                            placeholder="Select..."
                            value={selected}
                        />
                    </div>
                </div>
            </Panel>
        );
    }
});

let AdminOptions = WithStoreOnRoute(class AdminOptions extends Component {
    static get propTypes() {
        return {
            filters: PropTypes.instanceOf(Immutable.Map),
            location: PropTypes.object,
            onChange: PropTypes.func
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            filters: undefined,
            location: {},
            onChange: undefined
        };
    }

    constructor(props) {
        super(props);

        this.setAdminOptions = this.setAdminOptions.bind(this);
    }

    setAdminOptions(attr, value) {
        if (this.props.location) {
            this.setRouteState(attr, value)
                .setRouteState('operator', 'AND')
                .clearRouteState('offset')
                .apply();
            return;
        }
        HistoryActions.setFilter(attr, value);
    }

    render() {
        let model = this.props.location ? this.getRouteState() : this.props.filters;
        return (
            <Panel title="Admin" classTitle="box-title" collapsible defaultExpanded>
                <div className="box-body">
                    <FormItem
                        attr="actor"
                        label={this.context.intl.messages['history.browse.admin']}
                        model={model}
                        onChange={this.props.onChange}
                        setter={this.setAdminOptions}
                        type="text"
                    />
                </div>
            </Panel>
        );
    }
});

let objectTypeActions = [];
Object.keys(HistoryConstants.FILTERS.ACTION_TYPES).map((action) => (
    objectTypeActions.push(HistoryConstants.FILTERS.ACTION_TYPES[action])
));

let ActionOptions = WithStoreOnRoute(class ActionOptions extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object,
            onChange: PropTypes.func,
            selected: PropTypes.string
        };
    }

    static get defaultProps() {
        return {
            location: {},
            onChange: undefined,
            selected: ''
        };
    }

    constructor(props) {
        super(props);

        this.setObjectActionFilter = this.setObjectActionFilter.bind(this);
    }

    setObjectActionFilter(e) {
        const filterAttr = 'type';
        let value = e? e.key : '';

        if (this.props.location) {
            this.setRouteState(filterAttr, value)
                .setRouteState('operator', 'AND')
                .clearRouteState('offset')
                .apply();
            return;
        }

        HistoryActions.setFilter(filterAttr, value);
        this.props.onChange();
    }

    render() {
        let selected = objectTypeActions.find((item) => {
            return item.key === this.props.selected;
        });

        return (
            <Panel title="Action" classTitle="box-title" collapsible defaultExpanded>
                <div data-style="display: block;" className="box-body">
                    <div className="form-group">
                        <Select
                            getOptionLabel={data => data.name}
                            getOptionValue={data => data.id}
                            isClearable={true}
                            isMulti={false}
                            name="objectType"
                            onChange={this.setObjectActionFilter}
                            options={objectTypeActions}
                            placeholder="Select..."
                            value={selected}
                        />
                    </div>
                </div>
            </Panel>
        );
    }
});

let DateRangeOptions = WithStoreOnRoute(class DateRangeOptions extends Component {

    static get propTypes() {
        return {
            filters: PropTypes.instanceOf(Immutable.Map),
            location: PropTypes.object,
            onChange: PropTypes.func
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            filters: undefined,
            location: {},
            onChange: undefined
        };
    }

    constructor(props) {
        super(props);

        this.setFormatDate = this.setFormatDate.bind(this);
    }

    setFormatDate(attr, value) {
        if (this.props.location) {
            this.setRouteState(attr, value)
                .setRouteState('operator', 'AND')
                .clearRouteState('offset')
                .apply();
            return;
        }
        HistoryActions.setFilter(attr, value);
    }

    render() {
        let model = this.props.location ? this.getRouteState() : this.props.filters;
        return (
            <Panel title="Date Range" classTitle="box-title" collapsible defaultExpanded>
                <div className="box-body date-picker-filter">
                    <FormItem
                        label={this.context.intl.messages['filter-options.expire-date.from']}
                        attr="start-action-date"
                        datepicker={{popoverTargetOffset: '10px -36px', showYearDropdown: true}}
                        model={model}
                        onChange={this.props.onChange}
                        setter={this.setFormatDate}
                        type="date"
                    />
                    <FormItem
                        label={this.context.intl.messages['filter-options.expire-date.to']}
                        attr="end-action-date"
                        datepicker={{popoverTargetOffset: '10px -36px', showYearDropdown: true}}
                        model={model}
                        onChange={this.props.onChange}
                        setter={this.setFormatDate}
                        type="date"
                    />
                </div>
            </Panel>
        );
    }
});

class FilterOptions extends Component {

    static get propTypes() {
        return {
            filters: PropTypes.instanceOf(Immutable.Map),
            location: PropTypes.object
        };
    }

    static get defaultProps() {
        return {
            filters: undefined,
            location: {}
        };
    }

    constructor(props) {
        super(props);

        this.handleToggleFiltersPanel = this.handleToggleFiltersPanel.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.filters !== this.props.filters ||
            nextProps.location !== this.props.location) {
            return true;
        }

        return false;
    }

    handleToggleFiltersPanel() {
        LayoutActions.toggleFiltersPanel();
    }

    onChange() {
        HistoryActions.setFilter('operator', 'AND');
        HistoryActions.getHistory(
            HistoryStore.getState().get('filters'),
            0,
            20
        );
    }

    render() {
        let activeType;
        let activeAction;
        if (this.props.location.query) {
            activeType = this.props.location.query['start-action-date'];
            activeAction = this.props.location.query.operator;
        }
        return (
            <div>
                <aside className="control-sidebar B(0) Ovx(h) Ovy(a)">

                    <div className="tab-content">

                        <p>
                            <button className="btn btn-block bg-navy" onClick={this.handleToggleFiltersPanel}><i className="fas fa-chevron-right pull-right"></i>&nbsp;Close Options</button>
                        </p>

                        <ObjectTypeOptions
                            selected={activeType}
                            location={this.props.location}
                            onChange={this.onChange}
                        />

                        <AdminOptions
                            filters={this.props.filters}
                            location={this.props.location}
                        />

                        <ActionOptions
                            selected={activeAction}
                            filters={this.props.filters}
                            location={this.props.location}
                        />

                        <DateRangeOptions
                            filters={this.props.filters}
                            location={this.props.location}
                        />

                    </div>

                </aside>

                <div style={{position: 'fixed', height: 'auto'}} className="control-sidebar-bg"></div>
            </div>
        );
    }

}
export default WithStoreOnRoute(FilterOptions);

export {
    ObjectTypeOptions,
    AdminOptions,
    ActionOptions,
    DateRangeOptions
};
