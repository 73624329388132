/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, Col, Row} from 'react-bootstrap';

import {MassUpdateActions} from './mass-update-actions';
import MassUpdateStore from './mass-update-store';
import AssetTable from './table';
import DocumentTitle from '../../../common/document-title';
import ActionsMenu from '../../../layout/actions-menu/actions-menu';
import StickyHeader from '../../../layout/sticky-header/sticky-header';
import {RouterActions} from '../../../router/router-actions';

class Review extends Component {

    static get propTypes() {
        return {
            params: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            assets: MassUpdateStore.getState().get('assets'),
            massUpdateProcess: MassUpdateStore.getState().get('massUpdateProcess')
        };
    }

    static getStores() {
        return [MassUpdateStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.handleAction = this.handleAction.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount() {
        MassUpdateActions.findById(this.props.params.id);
        return;
    }

    handleAction(mode) {
        MassUpdateActions.setMode(this.props.params.id, mode);
    }

    handleCancel() {
        RouterActions.redirect('/catalogs/asset/mass-update');
    }

    render() {
        let totalAssets = this.state.assets ? this.state.assets.size : '-';
        return (
            <DocumentTitle message="document-titles.catalogs-asset.mass-update">
                <div>
                    <StickyHeader>
                        <div className="col-md-6">
                            <h1>
                                <i className="fas fa-book"></i>&nbsp;{this.context.intl.messages['catalogs.mass-update-asset.title']}
                                &nbsp;<small>{totalAssets}&nbsp;{this.context.intl.messages['catalogs.mass-update-asset-length']}</small>
                            </h1>
                        </div>
                        <ActionsMenu onCancel={this.handleCancel}>
                            <Button
                                bsSize="large"
                                className="btn btn-primary-outline Mr(3px) Mb(3px)"
                                onClick={this.handleAction.bind(this, 'update')}
                            >
                                <i className="fas fa-plus"/>&nbsp;{this.context.intl.messages['catalogs.mass-update.button.add-replace']}
                            </Button>
                            <Button
                                bsSize="large"
                                className="btn btn-danger-outline Mr(3px) Mb(3px)"
                                onClick={this.handleAction.bind(this, 'remove')}
                            >
                                <i className="fas fa-times"/>&nbsp;{this.context.intl.messages['catalogs.mass-update.button.remove']}
                            </Button>
                        </ActionsMenu>
                    </StickyHeader>
                    <section className="content" id="contentContainer">
                        <Row>
                            <Col xs={12}>
                                <div className="box">
                                    <div className="box-body padding-x-20">
                                        <h3>{this.context.intl.messages['catalogs.mass-update-asset.step-two.title']}</h3>
                                        <AssetTable pageSize={20} assets={this.state.assets}/>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </section>
                </div>
            </DocumentTitle>
        );
    }
}

export default Container.create(Review);
