/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */
import {STATION_SUBSCRIPTION_CT_TYPES} from '@wbdt-sie/brainiac-web-common';
import Promise from 'bluebird';
import Immutable from 'immutable';

import {CompareImmutableList} from '../../../common/utils/utils';
import Dispatcher from '../../../dispatcher/dispatcher';
import Request from '../../../request';
import {TitleConstants} from '../../../titles/title-actions';
import SessionStore from '../../../user/session/session-store';

const CONSTANTS = {
    CLEAR: 'subscriptions_actions.clear',
    CLONE: 'subscriptions_actions.clone',
    SAVE: 'subscriptions_actions.save',
    GET: {
        ASSETS: {
            ERROR: 'subscriptions_actions.get.assets.error',
            SUCCESS: 'subscriptions_actions.get.assets.success'
        },
        EPISODE: 'subscriptions_actions.get.episode',
        SUBSCRIPTIONS: {
            ERROR: 'subscriptions_actions.get.subscriptions.error',
            SUCCESS: 'subscriptions_actions.get.subscriptions.success'
        },
        TITLES: {
            ERROR: 'subscriptions_actions.get.titles.error',
            SUCCESS: 'subscriptions_actions.get.titles.success'
        },
        WATCHLIST: {
            ERROR: 'subscriptions_actions.get.watchlist.error',
            SUCCESS: 'subscriptions_actions.get.watchlist.success'
        }
    },
    SUBSCRIPTION_CONTENT_TYPES: STATION_SUBSCRIPTION_CT_TYPES,
    SUBSCRIPTION: {
        ADD: 'subscriptions_actions.subscription.add',
        REMOVE: 'subscriptions_actions.subscription.remove',
        TITLE: {
            ADD: 'subscriptions_actions.subscription.title.add',
            CLEAR: 'subscriptions_actions.subscription.title.clear',
            REMOVE: 'subscriptions_actions.subscription.title.remove',
        }
    },

    toArray: function(constant, options) {
        let defaults = {
            sortBy: 'name'
        };
        Object.assign(defaults, options);

        return Immutable.fromJS(
            Object.keys(constant)
                .map(k => constant[k])
        ).sortBy(v => v.get(defaults.sortBy));
    },

};

class SubscriptionsActions {

    add(subscriptionContentType, title, titleId, suggestedBy) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SUBSCRIPTION.ADD,
            subscriptionContentType: subscriptionContentType,
            suggestedByUserId: suggestedBy.get('id'),
            suggestedByUserName: suggestedBy.get('fullName'),
            title: title,
            titleId: titleId
        });
        return;
    }

    addTitle(title) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SUBSCRIPTION.TITLE.ADD,
            title: Immutable.fromJS(title)
        });
        return;
    }

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
    }

    clearTitles() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SUBSCRIPTION.TITLE.CLEAR
        });
    }

    clone() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLONE
        });
    }

    findById(userId) {
        Request.get(`user/${userId}/title-user-subscription`).query({size: 99999}).exec()
            .then(subscriptionsRes => {
                let subscriptions = Immutable.fromJS(subscriptionsRes.body.results);
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET.SUBSCRIPTIONS.SUCCESS,
                    subscriptions: subscriptions,
                    totalCount: subscriptionsRes.body.totalCount
                });
                return subscriptions;
            })
            .then(subscriptions => {
                this.getTitlesDetail(subscriptions);
            })
            .catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET.SUBSCRIPTIONS.ERROR,
                    error: err
                });
                throw err;
            });
        return;
    }

    getEpisodeData(id) {
        let episodeData;
        Request.get(`title/${id}/season-episode-list`).query({active: false}).exec()
            .then(res => {
                episodeData = `${res.body.seasons[0].titleName} S${res.body.seasons[0].seasonNumber}`;
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET.EPISODE,
                    episodeId: id,
                    episodeData: episodeData
                });
                return;
            });
        return;
    }

    getTitles(titleIds) {
        let users = Immutable.Set(titleIds).toJS();
        let requests = users.map(function(id) {
            return Request.get(`title/${id}`).exec();
        });

        Promise.all(requests).then(responses => {
            let results = responses.map(response => {
                if (response.body.category === TitleConstants.TITLE_TYPES.EPISODE.id) {
                    this.getEpisodeData(response.body.id);
                }
                return response.body;
            });
            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.TITLES.SUCCESS,
                subscriptionsTitles: Immutable.fromJS(results)
            });
        }).catch(err => {
            throw err;
        });
        return;
    }

    getTitlesDetail(subscriptions) {
        let titleIds = [];
        subscriptions.forEach((s) => {
            if (s.get('titleName') === null && s.get('titleId') !== null && s.get('titleId') !== 0) {
                titleIds.push(s.get('titleId'));
            }
            return;
        });
        if (titleIds.length) {
            this.getTitles(titleIds);
        }
    }

    getWatchList(userId, offset) {
        offset = offset || 0;
        Request.get(`user/${userId}/watch-list`)
            .query({offset: offset, size: 20})
            .exec()
            .then(recentlyWatchedRes => {
                let recentlyWatchedList = Immutable.fromJS(recentlyWatchedRes.body.results[0].details);
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET.WATCHLIST.SUCCESS,
                    recentlyWatched: recentlyWatchedList,
                    rwOffset: recentlyWatchedRes.body.offset,
                    rwTotalCount: recentlyWatchedRes.body.totalCount
                });
                return recentlyWatchedList;
            })
            .then(recentlyWatchedList => {
                this.getWatchListAssets(recentlyWatchedList);
            })
            .catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET.WATCHLIST.ERROR,
                    error: err
                });
                throw err;
            });
        return;
    }

    getWatchListAssets(watchListAssets) {
        let assetIds = [];
        watchListAssets.forEach((a) => {
            if (a.get('assetId') !== null && a.get('assetId') !== 0) {
                assetIds.push(a.get('assetId'));
            }
            return;
        });
        let assets = Immutable.Set(assetIds).toJS();
        let requests = assets.map(function(id) {
            return Request.get(`asset/video/${id}`).exec();
        });

        if (assetIds.length) {
            Promise.all(requests).then(responses => {
                let results = responses.map(response => response.body);
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET.ASSETS.SUCCESS,
                    watchListAssets: Immutable.fromJS(results)
                });
            }).catch(err => {
                throw err;
            });
        }
    }

    remove(index) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SUBSCRIPTION.REMOVE,
            index: index
        });
        return;
    }

    removeTitle(id) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SUBSCRIPTION.TITLE.REMOVE,
            id: id
        });
        return;
    }

    save(titles, subscriptionType) {
        titles.forEach(title => {
            this.add(subscriptionType.id, title, title.get('id'), SessionStore.getState().get('authUser'));
        });
        this.clearTitles();
        return;
    }
}

let subscriptionsSave = function(userId, subscriptions, originalSubscriptions) {
    if (CompareImmutableList(subscriptions, originalSubscriptions, ['subscriptionContentType', 'titleId', 'suggestedByUserId', 'userId'])) {
        return [];
    }
    let newSet = subscriptions.toSet();
    let toAddFormats = newSet.map(t => {
        if (t.get('userId')) {
            return t;
        }
        return {
            suggestedByUserId: t.get('suggestedByUserId'),
            subscriptionContentType: t.get('subscriptionContentType'),
            titleId: t.get('titleId'),
            userId: userId
        };
    });

    Dispatcher.dispatch({
        actionType: CONSTANTS.SAVE
    });

    return [Request.put(`user/${userId}/title-user-subscription`).send(toAddFormats).exec()];
};

let actions = new SubscriptionsActions();

export {
    actions as SubscriptionsActions,
    subscriptionsSave as SubscriptionsSave,
    CONSTANTS as SubscriptionsConstants
};
