/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Modal, Button} from 'react-bootstrap';

import {SlidingPanelActions} from '../../../common/sliding-panel/sliding-panel-actions';
import {SerieNavigationActions} from '../../serie-navigation/serie-navigation-actions';
import SerieNavigationStore from '../../serie-navigation/serie-navigation-store';
import {TitleActions, TitleConstants} from '../../title-actions';
import {WorkOrdersActions} from '../work-orders-actions';

class AddTitlesDialog extends Component {
    static get propTypes() {
        return {
            show: PropTypes.bool.isRequired,
            slidingPanelId: PropTypes.string.isRequired,
            title: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            serieNavigation: SerieNavigationStore.getState().get('serieNavigation')
        };
    }

    static getStores() {
        return [SerieNavigationStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            serie: false,
            season: false,
            episode: false
        }, this.constructor.calculateState());

        this.addMulti = this.addMulti.bind(this);
        this.checkboxFrom = this.checkboxFrom.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.show !== this.props.show) {
            this.setState(()=> {
                return {
                    serie: false,
                    season: false,
                    episode: false
                };
            });
        }
        if (!nextProps.title.equals(this.props.title)) {
            setTimeout( () => {
                SerieNavigationActions.get(nextProps.title.get('id'), this.state.serieNavigation);
            }, 0);
        }
    }

    addMulti() {
        // self is always selected :)
        let titles = [this.props.title.get('id')];
        let sn = this.state.serieNavigation.getIn(['serieMap', this.props.title.get('id').toString()]);
        switch (TitleActions.getCategoryGroup(this.props.title.get('category'))) {
        case TitleConstants.TITLE_CATEGORY_GROUPS.EPISODE:
            if (this.state.season) {
                titles.push(this.state.serieNavigation.getIn(['seasons', sn.get(0), 'titleId']));
            }
            if (this.state.serie) {
                titles.push(this.state.serieNavigation.get('titleId'));
            }
            break;
        case TitleConstants.TITLE_CATEGORY_GROUPS.SEASON:
            if (this.state.serie) {
                titles.push(this.state.serieNavigation.get('titleId'));
            }
            if (this.state.episode) {
                titles = titles.concat(this.state.serieNavigation.getIn(['seasons', sn.get(0), 'episodes']).map( e => e.get('titleId')).toJS());
            }
            break;
        case TitleConstants.TITLE_CATEGORY_GROUPS.SERIES:
            if (this.state.season) {
                titles = titles.concat(this.state.serieNavigation.get('seasons').map( s => s.get('titleId')).toJS());
            }
            if (this.state.episode) {
                titles = titles.concat(this.state.serieNavigation.get('seasons').reduce( (a, s) => {
                    return a.concat(s.get('episodes').map( e => e.get('titleId')).toJS());
                }, []));
            }
            break;
        }
        WorkOrdersActions.hide();
        WorkOrdersActions.addTitles(titles);
        SlidingPanelActions.hide(this.props.slidingPanelId);
        return;
    }

    checkboxFrom(title, titleType, disabled) {
        return (<div className="form-check" key={titleType}>
            <label className="form-check-label">
                <input className="form-check-input" type="checkbox" checked={disabled || this.state[titleType]} onChange={this.handleChange.bind(this, titleType)} disabled={disabled}/>&nbsp;{title}
            </label>
        </div>);
    }

    handleChange(titleType) {
        this.setState(state => {
            return {
                [titleType]: !state[titleType]
            };
        });
    }

    render() {
        let checkboxes = [];
        let sn = this.props.title.get('id') ? this.state.serieNavigation.getIn(['serieMap', this.props.title.get('id').toString()]) : undefined;
        let seasonNumber;
        let episodeNumber;
        let titleName = this.props.title.get('displayName');
        switch (TitleActions.getCategoryGroup(this.props.title.get('category'))) {
        case TitleConstants.TITLE_CATEGORY_GROUPS.EPISODE:
            seasonNumber = sn ? this.state.serieNavigation.getIn(['seasons', sn.get(0), 'seasonNumber']) : 'X';
            episodeNumber = sn ? this.state.serieNavigation.getIn(['seasons', sn.get(0), 'episodes', sn.get(1), 'episodeNumber']) : 'X';
            titleName = `${this.props.title.get('name')} (${this.props.title.get('parentTitleDisplayName')} - S${seasonNumber}E${episodeNumber})`;
            checkboxes = [this.checkboxFrom(titleName, 'episode', true),
                this.checkboxFrom(`${this.props.title.get('parentTitleDisplayName')} - Series`, 'serie', false),
                this.checkboxFrom(`${this.props.title.get('parentTitleDisplayName')} - Season ${seasonNumber}`, 'season', false)];
            break;
        case TitleConstants.TITLE_CATEGORY_GROUPS.SEASON:
            seasonNumber = sn ? this.state.serieNavigation.getIn(['seasons', sn.get(0), 'seasonNumber']) : 'X';
            checkboxes = [this.checkboxFrom(`${this.props.title.get('name')} - Season ${seasonNumber}`, 'season', true),
                this.checkboxFrom(`${this.props.title.get('parentTitleDisplayName')} - Series`, 'serie', false),
                this.checkboxFrom(`All episodes of ${this.props.title.get('name')} Season ${seasonNumber}`, 'episode', false)];
            break;
        case TitleConstants.TITLE_CATEGORY_GROUPS.SERIES:
            checkboxes = [this.checkboxFrom(`${this.props.title.get('name')}`, 'serie', true),
                this.checkboxFrom(`All Seasons of ${this.props.title.get('name')}`, 'season', false),
                this.checkboxFrom(`All episodes of ${this.props.title.get('name')}`, 'episode', false)];
            break;
        }

        const message = <p>{this.context.intl.messages['titles.work-orders.export.add-titles-dialog.add-metadata'].replace('{}', titleName)}:</p>;

        return (
            <Modal show={this.props.show} onHide={WorkOrdersActions.hide}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="text-center">{this.context.intl.messages['titles.work-orders.export.add-titles-dialog.title']}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {message}
                    {checkboxes}
                </Modal.Body>
                <Modal.Footer>
                    <Button bsStyle="primary" className="pull-left bg-wb-blue" onClick={this.addMulti}><i className="fas fa-plus"></i>&nbsp;{this.context.intl.messages['titles.work-orders.export.add-titles-dialog.add-selected']}</Button>
                    <Button onClick={WorkOrdersActions.hide}><i className="fas fa-times-circle"></i>&nbsp;{this.context.intl.messages['titles.work-orders.export.add-titles-dialog.not-add']}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default Container.create(AddTitlesDialog);
