/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';

import {AlertTypes} from '../common/notification/alert';
import {NotificationActions} from '../common/notification/notification-actions';
import Dispatcher from '../dispatcher/dispatcher';
import Request from '../request';

const CONSTANTS = {
    CLEAR: 'asset_title_actions.clear',
    GET: {
        SUCCESS: 'asset_title_actions.get.success'
    },
    TOOGLE_DEFAULT: 'asset_title_actions.toogle.default'
};

class AssetTitleActions {
    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
        return;
    }

    get(assetId) {
        Request.get(`asset/${assetId}/title`).exec().then(res => {
            let titles = res.body;
            let lastOrder = 0;
            if (titles.length > 0) {
                lastOrder = titles[titles.length-1].assetOrder;
            }
            return Promise.props({
                lastOrder: lastOrder,
                assetsToTitle: titles,
                titles: Promise.all(titles.map(assetToTitle => Request.get(`title/${assetToTitle.titleId}`).exec()))
            });
        }).then( titlesData => {
            let lastOrder = titlesData.lastOrder;
            let titles = titlesData.titles.map((td, i) => {
                let title = td.body;
                title.asset = {};
                title = ['defaultHorizontal', 'defaultPortrait', 'defaultBanner'].reduce( (t, attr) => {
                    t.asset[attr] = titlesData.assetsToTitle[i][attr] === 1;
                    return t;
                }, title);
                return title;
            });
            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.SUCCESS,
                lastOrder: lastOrder,
                titles: Immutable.fromJS(titles)
            });
            return;
        }).catch(err => {
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
            throw err;
        });

        return;
    }

    toogleDefault(titleId, orientation) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TOOGLE_DEFAULT,
            titleId: parseInt(titleId),
            orientation
        });
    }

    assetTitleSave(assetId, titles, originalTitles) {
        let titleDefaultImages = titles.map( (title, i) => {
            let titleDefaultImage;
            if (!title.get('asset').equals(originalTitles.getIn([i, 'asset']))) {
                // actually has to do something
                titleDefaultImage = ['Horizontal', 'Portrait', 'Banner'].reduce( (t, prop) => {
                    let titleAssetPropName = `defaultImage${prop}Id`;
                    // set current value
                    t[titleAssetPropName] = null;

                    let newProp = title.getIn(['asset', `default${prop}`]);
                    if (originalTitles.getIn([i, 'asset', `default${prop}`]) !== newProp) {
                        // if changed set new value
                        if (newProp) {
                            t[titleAssetPropName] = assetId;
                        } else {
                            t[`clearDefaultImage${prop}`] = true;
                        }
                    }
                    return t;
                }, {titleId: title.get('id')});
            }
            return titleDefaultImage;
        }).toJS();
        let needUpdate = titleDefaultImages.filter( t => !!t);
        return needUpdate.map( (t) => {
            let id = t.titleId;
            delete t.titleId;
            return Request.put(`title/${id}/default-image`).send(t).exec();
        });
    }

}

let actions = new AssetTitleActions();

export {
    actions as AssetTitleActions,
    CONSTANTS as AssetTitleConstants,
};
