/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import createReactClass from 'create-react-class';
import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {ControlLabel, FormControl, FormGroup} from 'react-bootstrap';

import {FormItem, FormRow, FormSection} from '../../../common/form/form';
import {CompareImmutable} from '../../../common/utils/utils';
import config from '../../../config/config.js';
import {PermissionPackageActions} from '../../../security/permission-package/permission-package-actions';
import {PermissionPackageStoreValidations} from '../../../security/permission-package/permission-package-store';


let presetProps = [
    'presetName', 'isActive', 'presetDescription',
    'updatedDate'
];

export default createReactClass({
    propTypes: {
        disabled: PropTypes.bool,
        mode: PropTypes.string.isRequired,
        preset: PropTypes.instanceOf(Immutable.Map).isRequired
    },

    contextTypes: {
        intl: PropTypes.object.isRequired
    },

    getDefaultProps: function() {
        return {
            disabled: true
        };
    },

    shouldComponentUpdate: function(nextProps) {
        if (!CompareImmutable(nextProps.preset, this.props.preset, presetProps)) {
            return true;
        }
        return false;
    },

    render: function() {
        let updatedDate = <div>N/A</div>;
        let additionalInformation;

        if (this.props.mode === 'edit') {

            updatedDate = '-';
            if (this.props.preset.get('updatedDate')) {
                updatedDate = Moment(this.props.preset.get('updatedDate')).tz(config.DefaultTimezone).format(this.context.intl.messages['datetime-format']);
            }

            additionalInformation = <FormRow>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['presets.edit.last-modified-date']}</ControlLabel>
                    <FormControl.Static>{updatedDate}</FormControl.Static>
                </FormGroup>
            </FormRow>;
        }

        return (
            <FormSection title={this.context.intl.messages['presets.create.info.title']} iconClass="fas fa-info-circle">
                <FormRow>
                    <FormItem attr="name"
                        disabled={this.props.disabled}
                        label={this.context.intl.messages['presets.create.info.name']}
                        model={this.props.preset}
                        onlySpaces
                        placeholder={this.context.intl.messages['presets.create.info.name.placeholder']}
                        setter={PermissionPackageActions.updatePreset}
                        type="text"
                        validations={PermissionPackageStoreValidations.name.validations}
                    />
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['presets.create.info.status']}</ControlLabel>
                        <FormItem attr="isActive"
                            disabled={this.props.disabled}
                            label="Active"
                            model={this.props.preset}
                            md={5}
                            setter={PermissionPackageActions.updatePreset}
                            type="checkbox"
                        />
                    </FormGroup>
                </FormRow>
                <FormRow>
                    <FormItem attr="description"
                        disabled={this.props.disabled}
                        label={this.context.intl.messages['presets.create.info.description']}
                        model={this.props.preset}
                        placeholder={this.context.intl.messages['presets.create.info.description.placeholder']}
                        setter={PermissionPackageActions.updatePreset}
                        type="textarea"
                        validations={PermissionPackageStoreValidations.description.validations}
                    />
                </FormRow>
                <hr />
                {additionalInformation}
            </FormSection>
        );
    }
});
