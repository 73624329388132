/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Modal} from 'react-bootstrap';

class PublishClipModal extends React.Component {
    static get propTypes() {
        return {
            children: PropTypes.node.isRequired,
            onConfirmPublish: PropTypes.func.isRequired,
            onHide: PropTypes.func.isRequired,
            show: PropTypes.bool.isRequired,
            title: PropTypes.string.isRequired,
            viewingMode: PropTypes.string.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.handleConfirmPublish = this.handleConfirmPublish.bind(this);
        this.handleHide = this.handleHide.bind(this);
    }

    handleConfirmPublish() {
        this.props.onConfirmPublish();
        this.handleHide();
    }

    handleHide() {
        this.props.onHide();
    }

    render() {
        return (
            <Modal
                className={ClassNames({'skin-dark': this.props.viewingMode === 'skin-dark'})}
                onHide={this.handleHide}
                show={this.props.show}>
                <Modal.Header className="bg-gray" closeButton={true}>
                    <Modal.Title className="text-center" >{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="publish-modal">
                        {this.props.children}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-default-outline pull-left Ml(5)" onClick={this.handleHide}>{this.context.intl.messages['common.cancel']}</Button>
                    <Button className="btn btn-success-outline" onClick={this.handleConfirmPublish}>
                        <i className="far fa-cloud-upload"></i>&nbsp;{this.context.intl.messages['hardac.timeline.clip.publish.now']}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default PublishClipModal;
