/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

const cache: Record<string, Record<string, any>> = {};
const cloneDeep = <T>(val: T): T => JSON.parse(JSON.stringify(val));
const isObject = (val: unknown): boolean => typeof val === 'object' && val !== null;
const has = (obj: unknown, prop: string): boolean => Object.prototype.hasOwnProperty.call(obj, prop);

class LocalStorageHelper {
    set<T>(groupName: string, key: string, value: T): void {
        try {
            value = _safer(value);
            const group = _getGroup(groupName);
            const data = group[key];
            if (isObject(data) && isObject(value) && !Array.isArray(data)) {
                group[key] = Object.assign(data, value);
            } else {
                group[key] = value;
            }

            localStorage.setItem(groupName, JSON.stringify(group));
        } catch (e) {
            console.error('error on set data to localStorage', e);
        }
    }

    get<T>(groupName: string, key: string): T {
        const res = _getGroup<T>(groupName)[key];
        return _safer(res);
    }

    remove(groupName: string, key: string): void {
        this.set(groupName, key, void 0);
    }

    clear(groupName: string): void {
        try {
            delete cache[groupName];
            localStorage.removeItem(groupName);
        } catch (e) {
            console.error('error on set data to localStorage', e);
        }
    }
}

export default new LocalStorageHelper();

function _getGroup<T>(propName: string): Record<string, T> {
    if (has(cache, propName)) {
        return cache[propName];
    }

    const s = localStorage.getItem(propName);
    let stored: Record<string, T> = {};
    if (s) {
        try {
            stored = JSON.parse(s);
        } catch (e) {
            stored = {};
        }
    }

    cache[propName] = stored;

    return stored;
}

function _safer<T>(val: T): T {
    if (isObject(val) || Array.isArray(val)) {
        return cloneDeep(val);
    }
    return val;
}
