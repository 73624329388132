/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button} from 'react-bootstrap';

import Accounts from './accounts';
import ResetGuildModal from './reset-guild-modal';
import Summary from './summary';
import RouteValidator from '../../common/custom-validator/route/route';
import DocumentTitle from '../../common/document-title';
import {Tab, Tabs} from '../../common/routing-tab/routing-tab';
import {History, SideNotes} from '../../common/side-notes/side-notes';
import {AlertsWarnings} from '../../common/validations/alerts-warnings';
import ActionsMenu from '../../layout/actions-menu/actions-menu';
import StickyHeader from '../../layout/sticky-header/sticky-header';
import Preloader from '../../preloader';
import {RouterActions} from '../../router/router-actions';
import {ActionHistoryActions, ActionHistoryConstants} from '../../system/action-history/action-history-actions';
import SessionStore from '../../user/session/session-store';
import {GuildActions} from '../guild-actions';
import GuildStore from '../guild-store';

class Create extends React.Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            params: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired,
            route: RouteValidator.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static getPermissions() {
        return {
            canCreateGuilds: SessionStore.canUser(SessionStore.PERMISSIONS.GUILDS.CREATE),
            canResetGuilds: SessionStore.canUser(SessionStore.PERMISSIONS.GUILDS.RESET),
            canUpdateGuilds: SessionStore.canUser(SessionStore.PERMISSIONS.GUILDS.UPDATE),
        };
    }

    static getStores() {
        return [GuildStore];
    }

    static calculateState() {
        return {
            guild: GuildStore.getState().get('guild'),
            history: GuildStore.getState().get('history'),
            originalGuild: GuildStore.getState().get('originalGuild'),
            showPreloader: GuildStore.getState().get('showPreloader'),
            syncAllAccounts: GuildStore.getState().get('syncAllAccounts')
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            showResetModal: false
        }, this.constructor.calculateState());

        this.addNote = this.addNote.bind(this);
        this.handleDeactivate = this.handleDeactivate.bind(this);
        this.handleHideResetModal = this.handleHideResetModal.bind(this);
        this.handleResetDateChanged = this.handleResetDateChanged.bind(this);
        this.handleShowResetModal = this.handleShowResetModal.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.hasChanged = this.hasChanged.bind(this);
        this.isDirty = this.isDirty.bind(this);

    }

    componentDidMount() {
        this.init(this.props);
        RouterActions.registerRedirectCheck((location) => {
            return this.isDirty(location);
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.params.id !== this.props.params.id) {
            this.init(nextProps);
        }
        RouterActions.registerRedirectCheck((location) => {
            return this.isDirty(location);
        });
    }

    componentWillUnmount() {
        GuildActions.clear();
        RouterActions.clearRedirectChecks();
        return;
    }

    addNote(description) {
        ActionHistoryActions.addNote(this.props.params.id, description, ActionHistoryConstants.ACTION_OBJECTS.GUILD);
    }

    handleCancel() {
        RouterActions.redirect('/guild', true);
    }

    handleDeactivate() {
        GuildActions.deactivate(this.state.guild);
    }

    handleHideResetModal() {
        this.setState(() => {
            return {
                showResetModal: false
            };
        });
    }

    handleShowResetModal() {
        this.setState(() => {
            return {
                showResetModal: true,
                resetStartDate: GuildStore.getState().get('guild').get('validStartDate'),
                resetEndDate: GuildStore.getState().get('guild').get('validEndDate')
            };
        });
    }

    handleResetDateChanged(attr, value) {
        this.setState(() => {
            const newState = {
                [attr]: value
            };
            return newState;
        });
    }

    handleReset() {
        GuildActions.reset(this.state.guild, this.state.resetStartDate, this.state.resetEndDate);
        this.setState(() => {
            return {
                showResetModal: false
            };
        });
    }

    handleSave() {
        GuildActions.save(this.state.guild);
    }

    hasChanged() {
        return !this.state.guild.equals(this.state.originalGuild);
    }

    init(props) {
        if (props.route.mode === 'create') {
            GuildActions.clear();
        } else {
            GuildActions.findById(props.params.id);
        }
        RouterActions.registerRedirectCheck((location) => {
            return this.isDirty(location);
        });
    }

    isDirty(location) {
        if (location) {
            let goingTo = location.pathname.split('/');
            let current = this.props.location.pathname.split('/');
            if (goingTo[1] === current[1] && /* entity */
                goingTo[2] === current[2] /* entity id */) {
                return false; // just changing tabs no confirmation needed.
            }
        }
        return this.hasChanged();
    }

    render() {
        let baseRoute = `/guild/${this.props.params.id}`;
        let accountsTab;
        let actionsMenu;
        let dateRangeValidations = [];
        let eventsAndNotes;
        let historyTab;
        let mode = this.props.route.mode;
        let summaryRoute;
        if (mode === 'create') {
            summaryRoute = '/guild/create';
        } else {
            summaryRoute = `/guild/${this.props.params.id}`;
        }
        let toggleActivationButton;
        let validations = GuildStore.getValidations();

        const resetGuildModal = <ResetGuildModal
            onHide={this.handleHideResetModal}
            onConfirm={this.handleReset}
            resetDates={Immutable.fromJS({
                resetStartDate: this.state.resetStartDate,
                resetEndDate: this.state.resetEndDate
            })}
            onChange={this.handleResetDateChanged}
            show={this.state.showResetModal}
        />;

        if (mode === 'create') {
            actionsMenu = <ActionsMenu
                canSave={!validations.length && this.props.permissions.canCreateGuilds}
                onCancel={this.handleCancel}
                onSave={this.handleSave}
            />;

        } else {
            if (!this.state.guild.get('active')) {
                toggleActivationButton = <Button bsSize="large" className="btn btn-info-outline Mr(3px) Mb(3px)" onClick={this.handleShowResetModal} disabled={!this.props.permissions.canResetGuilds}>
                    <i className="fas fa-repeat"></i>&nbsp;{this.context.intl.messages['guild.edit.reset.btn']}
                </Button>;
            } else {
                toggleActivationButton = <Button bsSize="large" className="btn btn-danger-outline Mr(3px) Mb(3px)" onClick={this.handleDeactivate}>
                    <i className="fas fa-times"></i>&nbsp;{this.context.intl.messages['guild.edit.deactivate.btn']}
                </Button>;
            }
            actionsMenu = <ActionsMenu
                canEdit={this.props.permissions.canUpdateGuilds}
                canSave={!validations.length && this.props.permissions.canUpdateGuilds}
                onCancel={this.handleCancel}
                onSave={this.handleSave}
            >
                {toggleActivationButton}
            </ActionsMenu>;
            dateRangeValidations = GuildStore.getDateRangeValidations();
            accountsTab = (
                <Tab route={`${baseRoute}/accounts`} title={this.context.intl.messages['guild.accounts.title']}>
                    <Accounts guildId={this.props.params.id} disabled={this.state.guild.get('active')} location={this.props.location}/>
                </Tab>
            );

            eventsAndNotes = (
                <SideNotes
                    basePath={baseRoute}
                    dialogMessage={this.context.intl.messages['common.side-notes.add-note.body']}
                    disabled={ !this.props.permissions.canUpdateGuilds }
                    notes={this.state.history.toJS().slice(0, 4)}
                    onAddNote={this.addNote}
                    title={this.context.intl.messages['common.side-notes.title']}
                />
            );

            historyTab = <Tab route={`${baseRoute}/history`} title={this.context.intl.messages['common.tab-title.history']} tabClassName="pull-right">
                <History
                    actionObject={ActionHistoryConstants.ACTION_OBJECTS.GUILD}
                    id={this.props.params.id}
                    pagination
                    pathname={this.props.location.pathname}
                    query={this.props.location.query}/>
            </Tab>;
        }

        return <DocumentTitle
            message={`guild.${mode}.title`}
        >
            <div>
                <StickyHeader>
                    <div className="col-md-6">
                        <h1>
                            <i className="fa fa-edit"></i>&nbsp;{this.context.intl.messages[`guild.${mode}.title`]}
                            &nbsp;
                            <small>{this.state.guild.get('name')}</small>
                        </h1>
                    </div>
                    {actionsMenu}
                </StickyHeader>
                <Preloader show={this.state.showPreloader} fixed loadingDots>
                    <section className="content" id="contentContainer">
                        <div className="row">
                            <div className="col-lg-9">
                                <Tabs location={this.props.location}>
                                    <Tab route={summaryRoute} title={this.context.intl.messages['guild.summary.title']}>
                                        <Summary mode={mode}/>
                                    </Tab>
                                    {accountsTab}
                                    {historyTab}
                                </Tabs>
                            </div>
                            <div className="col-lg-3">
                                <AlertsWarnings
                                    title={this.context.intl.messages['common.alerts-warnings-title']}
                                    validations={[...validations, ...dateRangeValidations]}
                                />
                                {eventsAndNotes}
                            </div>
                        </div>
                    </section>
                </Preloader>
                {resetGuildModal}
            </div>
        </DocumentTitle>;
    }
}

export default Container.create(Create);
