/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import Select from 'react-select';

import Panel from '../../../common/panel/panel';
import {WithStoreOnRoute} from '../../../common/store-on-route';
import {LayoutActions} from '../../../layout/layout-actions';
import {ProcessesActions} from '../processes-actions';
import ProcessesStore from '../processes-store';

let ProcessedOptions = WithStoreOnRoute(class ProcessedOptions extends Component {
    static get propTypes() {
        return {
            location: PropTypes.object,
            onChange: PropTypes.func,
            selected: PropTypes.number
        };
    }

    static get defaultProps() {
        return {
            location: undefined,
            onChange: undefined,
            selected: undefined
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
        };
    }

    constructor(props) {
        super(props);

        this.setProcessedFilter = this.setProcessedFilter.bind(this);
    }

    setProcessedFilter(e) {
        const filterAttr = 'processed';
        let value = 'false';
        if (e && e.value === 'true') {
            value = 'true';
        }

        if (this.props.location) {
            this.setRouteState(filterAttr, value)
                .setRouteState('operator', 'AND')
                .clearRouteState('offset')
                .apply();
            return;
        }
        ProcessesActions.setFilter(filterAttr, value);
        this.props.onChange();
    }

    render() {
        let options = [
            {name: 'Incomplete', value: 'false'},
            {name: 'Both', value: 'true'},
        ];

        let selected = options[0];
        if (this.props.selected === 'true') {
            selected = options[1];
        }

        return (
            <Panel title={this.context.intl.messages['hardac.processes.filters.process']} collapsible defaultExpanded>
                <div data-style="display: block;" className="box-body">
                    <div className="form-group">
                        <Select
                            classNamePrefix="dropdown"
                            getOptionLabel={data => data.name}
                            getOptionValue={data => data.value}
                            isClearable={true}
                            isMulti={false}
                            name="processed"
                            options={options}
                            placeholder="Select..."
                            value={selected}
                            onChange={this.setProcessedFilter}
                        />
                    </div>
                </div>
            </Panel>
        );
    }
});

class FilterOptions extends Component {

    static get propTypes() {
        return {
            filters: PropTypes.instanceOf(Immutable.Map),
            location: PropTypes.object,
        };
    }

    static get defaultProps() {
        return {
            filters: undefined,
            location: undefined
        };
    }

    constructor(props) {
        super(props);

        this.handleToggleFiltersPanel = this.handleToggleFiltersPanel.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.filters !== this.props.filters ||
            nextProps.location !== this.props.location) {
            return true;
        }

        return false;
    }

    handleToggleFiltersPanel() {
        LayoutActions.toggleFiltersPanel();
    }

    onChange() {
        ProcessesActions.setFilter('operator', 'AND');
        ProcessesActions.get(
            ProcessesStore.getState().get('filters'),
            0,
            20
        );
    }

    render() {
        let activeValue;
        if (this.props.location) {
            activeValue = this.props.location.query.processed;
        } else {
            activeValue = ProcessesStore.getState().getIn(['filters', 'processed']);
        }

        return (
            <div>
                <aside className="control-sidebar B(0) Ovx(h) Ovy(a)">
                    <div className="tab-content">
                        <p>
                            <button className="btn btn-block bg-navy" onClick={this.handleToggleFiltersPanel}><i className="fas fa-chevron-right pull-right"></i>&nbsp;Close Options</button>
                        </p>
                        <ProcessedOptions
                            selected={activeValue}
                            location={this.props.location}
                            onChange={this.onChange}
                        />

                    </div>
                </aside>
                <div style={{position: 'fixed', height: 'auto'}} className="control-sidebar-bg"></div>
            </div>
        );
    }
}

export default WithStoreOnRoute(FilterOptions);

export {
    ProcessedOptions
};
