/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import jQuery from 'jquery';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import config from '../../../config/config.js';
import {TitleActions, TitleConstants} from '../../title-actions';
import {WorkOrdersActions} from '../work-orders-actions';

require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('datatables.net-rowreorder-bs/css/rowReorder.bootstrap.css');
require('datatables.net-rowreorder');
require('datatables.net-responsive-bs');

class ExportList extends Component {

    static get propTypes() {
        return {
            displayLinks: PropTypes.bool,
            titles: PropTypes.instanceOf(Immutable.List).isRequired,
            disabled: PropTypes.bool
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
            router: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            displayLinks: false,
            disabled: false
        };
    }

    constructor(props) {
        super(props);

        this.handleResize = this.handleResize.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.handleReorder = this.handleReorder.bind(this);
    }

    componentDidMount() {
        this.$table = jQuery('#export-titles-table');
        let configTable = {
            autoWidth: false,
            columnDefs: [
                {
                    className: 'row-reorder-handle',
                    targets: 0
                },
                {className: 'control', targets:   -1, width: 20},
                {targets: 'no-sort', orderable: false}
            ],
            iDisplayLength: 1,
            info: false,
            ordering: false,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        };

        if (!this.props.disabled) {
            configTable.rowReorder = {
                selector: '.row-reorder-handle'
            };
        }
        this.$tableAPI = this.$table.DataTable(configTable);

        // Add some custom handlers
        if (!this.props.disabled) {
            this.$tableAPI.on('row-reorder', this.handleReorder);
        }
        // Register global listeners.
        window.addEventListener('resize', this.handleResize);

        this.componentDidUpdate(this.props);
        return;

    }

    componentDidUpdate() {
        this.$tableAPI.clear();

        let fmt = (d) => {
            let md = Moment(d);
            if (md.isValid()) {
                return md.tz(config.DefaultTimezone).format(this.context.intl.messages['date-format']);
            }
            return '-';
        };

        this.props.titles.forEach((t, i) => {
            const categoryGroup = TitleActions.getCategoryGroup(t.get('category'));

            let parentTitle = t.get('titleDisplayName');

            if (this.props.displayLinks) {
                parentTitle = `<a href="/titles/${t.get('titleId')}" target="_blank">${t.get('titleDisplayName')}</a>`;
            }

            switch (categoryGroup) {
            case TitleConstants.TITLE_CATEGORY_GROUPS.EPISODE:
                let season = TitleActions.seasonFromFullRunningOrder(t.get('fullRunningOrder'));
                parentTitle = `${t.get('parentTitleDisplayName') || 'SERIE'} (S${season})`;

                if (this.props.displayLinks) {
                    parentTitle = `<a href="/titles/${t.get('parentTitleId')}" target="_blank">${parentTitle}</a>`;
                }

                break;
            case TitleConstants.TITLE_CATEGORY_GROUPS.SEASON:
                parentTitle = t.get('parentTitleDisplayName') || t.get('titleDisplayName'); // TODO: this is because in seasons parentTitleDisplayName seems to be null

                if (this.props.displayLinks) {
                    parentTitle = `<a href="/titles/${t.get('parentTitleId')}" target="_blank">${parentTitle}</a>`;
                }

                break;
            }

            let titleName = '';
            switch (categoryGroup) {
            case TitleConstants.TITLE_CATEGORY_GROUPS.EPISODE:
                titleName = t.get('titleDisplayName');

                if (this.props.displayLinks) {
                    titleName = `<a href="/titles/${t.get('titleId')}" target="_blank"> ${titleName}</a>`;
                }

                break;
            case TitleConstants.TITLE_CATEGORY_GROUPS.SEASON:
                titleName = `${t.get('titleDisplayName')} (${t.get('fullRunningOrder')})`;
                if (this.props.displayLinks) {
                    titleName = `<a href="/titles/${t.get('titleId')}" target="_blank"> ${titleName}</a>`;
                }

                break;
            }

            let runningOrder = '';
            switch (categoryGroup) {
            case TitleConstants.TITLE_CATEGORY_GROUPS.EPISODE:
                let season = TitleActions.seasonFromFullRunningOrder(t.get('fullRunningOrder'));
                runningOrder = `<small class="text-gray">S${season}</small>${t.get('runningOrder')}`;
                break;
            case TitleConstants.TITLE_CATEGORY_GROUPS.SEASON:
                runningOrder = `<small class="text-gray">${t.get('fullRunningOrder')}</small>`;
                break;
            case TitleConstants.TITLE_CATEGORY_GROUPS.SERIES:
                runningOrder = '<small class="text-gray">SERIES</small>';
                break;
            case TitleConstants.TITLE_CATEGORY_GROUPS.SINGLE_RELEASE:
                runningOrder = '<small class="text-gray">FEATURE</small>';
                break;
            }
            this.$tableAPI.row.add( [
                i + 1,
                runningOrder,
                parentTitle || '',
                titleName,
                t.get('categoryName') || '',
                t.get('mpmNumber') || '',
                fmt(t.get('titleReleaseDate')),
                `<button class="btn remove-title-button bg-navy" data-index="${i}"><i class="fas fa-trash-alt"></i> Remove</button>`,
                ''
            ]);
        });

        this.$tableAPI.draw(false);
        // Now, since the data has changed the columns widths, trigger
        // the resize handler in order to update the responsive feature.
        this.handleResize();

        return;
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        // check if undefinded only to enable render test
        if (this.$table && !this.props.disabled) {
            this.$table.off('row-reorder');
        }
        // check if undefinded only to enable render test
        if (this.$tableAPI) {
            this.$tableAPI.destroy();
        }
        return;
    }

    /**
     * Update the datatable columns size.
     */
    handleResize() {
        this.$tableAPI.responsive.recalc();
        return;
    }

    /**
     * This function is kind of "special" because it needs to handle
     * events bubbled from the data table rows, these rows cannot use
     * the JSX syntax because they are created by the data table
     * jQuery plugin instead of React.
     */
    handleRowClick(event) {
        switch (true) {
        // Handle click on an autorized partner's name.
        case !!~event.target.className.indexOf('remove-title-button'):
            // Prevent the default anchor click event.
            event.preventDefault();
            let index = parseInt(event.target.getAttribute('data-index'), 10);
            WorkOrdersActions.removeTitle(index);
            break;
        }

        return;
    }

    handleReorder( event, diff, edit ) {
        event.preventDefault();
        let fromPos = edit.triggerRow[0][0];
        if (diff.length < 2) {
            return;
        }
        let toPos;
        if (diff[0].oldPosition === fromPos) {
            toPos = diff[0].newPosition;
        } else {
            toPos = diff[diff.length-1].newPosition;
        }
        setTimeout(() => {
            WorkOrdersActions.reorder(fromPos, toPos);
            return;
        }, 0);
    }

    render() {
        return (
            <div>
                <table id="export-titles-table" className="table table-bordered table-striped responsive">
                    <thead>
                        <tr>
                            <th className="no-arrow no-sort text-center row-reorder-handle sorting_asc">#</th>
                            <th className="no-arrow no-sort text-center row-reorder-handle sorting_asc">RO</th>
                            <th className="no-arrow no-sort">Parent</th>
                            <th className="no-arrow no-sort">Title</th>
                            <th className="no-arrow no-sort">Title Type</th>
                            <th className="no-arrow no-sort">MPM</th>
                            <th className="no-arrow no-sort">Release Date</th>
                            <th className="no-arrow no-sort">Remove</th>
                            <th className="no-sort"></th>
                        </tr>
                    </thead>
                    <tbody onClick={this.handleRowClick}>
                    </tbody>
                </table>
            </div>
        );
    }

}

export default ExportList;
