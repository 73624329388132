/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Modal, ControlLabel, FormControl, FormGroup, Checkbox} from 'react-bootstrap';

import {NotifySubscribersNowActions} from './notify-subscribers-now-actions';
import NotifySubscribersNowStore from './notify-subscribers-now-store';
import TinyMCE from '../../common/tinymce/tinymce';

import {AssetActions} from '~/src/assets/asset-actions';

class NotifySubscribersNowModal extends React.Component {
    static get propTypes() {
        return {
            onNotifySubscribers: PropTypes.func,
        };
    }

    static get defaultProps() {
        return {
            onNotifySubscribers: () => void 0
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
            router: PropTypes.object.isRequired,
        };
    }

    static calculateState() {
        const store = NotifySubscribersNowStore.getState();
        return {
            assetId: store.get('assetId'),
            show: store.get('showModal'),
            subject: store.get('subject'),
            subscriptionTypeId: store.get('subscriptionTypeId'),
            titleId: store.get('titleId'),
        };
    }

    static getStores() {
        return [NotifySubscribersNowStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            body: null,
            customMessage: true,
        }, this.constructor.calculateState());

        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleSubjectChange = this.handleSubjectChange.bind(this);
        this.handleTinyMCEChange = this.handleTinyMCEChange.bind(this);
        this.notifySubscribers = this.notifySubscribers.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    handleTinyMCEChange() {
        return function handler(e) {
            this.setState({body: e?.target.getContent()});
            return;
        }.bind(this);
    }

    handleSubjectChange(e) {
        NotifySubscribersNowActions.update('subject', e.target.value);
    }

    handleCheckboxChange(e) {
        this.setState({
            body: null,
            customMessage: e.target.checked,
        });

        NotifySubscribersNowActions.update('subject', null);
    }

    notifySubscribers() {
        let subject = null;
        let body = null;

        if (this.state.customMessage) {
            subject = this.state.subject;
            body = this.state.body;
        }

        AssetActions.sendSubscriptionNotifications(
            this.state.titleId,
            this.state.assetId,
            this.state.subscriptionTypeId,
            subject,
            body,
        );

        this.props.onNotifySubscribers();
        NotifySubscribersNowActions.closeModal();
        NotifySubscribersNowActions.update('subject', null);
        this.setState({
            body: null
        });

    }

    onHide() {
        NotifySubscribersNowActions.closeModal();
        NotifySubscribersNowActions.update('subject', null);
        this.setState({
            body: null
        });
    }

    render() {
        const notifyButtonDisabled = this.state.customMessage && (!this.state.subject || !this.state.body);

        return (
            <Modal backdrop="static" show={this.state.show} onHide={this.onHide}>
                <Modal.Header className="bg-green text-center" closeButton>
                    <Modal.Title>
                        {this.context.intl.messages['asset.detail.notify-subscribers-now']}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pull-left">
                    <div>
                        <p>{this.context.intl.messages['asset.detail.notify-subscribers-now.description']}</p>
                        <p>
                            <b>{this.context.intl.messages['asset.detail.notify-subscribers-now.description.note.prefix']}</b>
                            {this.context.intl.messages['asset.detail.notify-subscribers-now.description.note']}
                        </p>
                    </div>
                    <FormGroup>
                        <Checkbox checked={this.state.customMessage} onChange={this.handleCheckboxChange}>
                            {this.context.intl.messages['asset.detail.notify-subscribers-now.custom-message']}
                        </Checkbox>
                    </FormGroup>
                    {this.state.customMessage && <div>
                        <FormGroup>
                            <ControlLabel>
                                {this.context.intl.messages['asset.detail.notify-subscribers-now.subject']}
                            </ControlLabel>
                            <FormControl type="text" value={this.state.subject} onChange={this.handleSubjectChange}/>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>
                                {this.context.intl.messages['asset.detail.notify-subscribers-now.body']}
                            </ControlLabel>
                            <TinyMCE content={this.state.body} id="email-body-subscribers" onChange={this.handleTinyMCEChange('')} />
                        </FormGroup>
                    </div>}
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-default-outline Mr(3px) Mb(3px) pull-left" onClick={this.onHide}>
                        <i className="fas fa-times" />
                        &nbsp;{this.context.intl.messages['common.cancel']}
                    </Button>
                    <Button disabled={notifyButtonDisabled} bsStyle="success" target="_blank" onClick={this.notifySubscribers}>
                        {this.context.intl.messages['asset.detail.notify-subscribers-now.notify-subscribers']}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default Container.create(NotifySubscribersNowModal);
