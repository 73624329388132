/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import {Modal, Button} from 'react-bootstrap';

export default createReactClass({

    propTypes: {
        children: PropTypes.node,
        onHide: PropTypes.func.isRequired,
        path: PropTypes.string,
        showModal: PropTypes.bool,
    },

    contextTypes: {
        intl: PropTypes.object.isRequired
    },

    getDefaultProps: function() {
        return {
            children: undefined,
            path: undefined,
            showModal: false
        };
    },

    render: function() {
        return (
            <Modal show={this.props.showModal} onHide={this.props.onHide}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="text-center">{this.context.intl.messages['asset.merchandise.create.photo-preview.title']}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <img className="responsive-image" src={this.props.path}/>
                    <hr/>
                    {this.props.children}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        bsStyle="default"
                        onClick={this.props.onHide}>
                        <i className="fas fa-times-circle"/>&nbsp;{this.context.intl.messages['common.close']}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
});
