/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';

import {AlertTypes} from '../common/notification/alert';
import {NotificationActions} from '../common/notification/notification-actions';
import Config from '../config/config';
import Dispatcher from '../dispatcher/dispatcher';
import {PreloaderActions} from '../preloader/preloader-actions';
import Request from '../request';
import {RouterActions} from '../router/router-actions';

const CONSTANTS = {
    CLEAR: 'push-notifications.clear',
    ERROR: 'push-notifications.error',
    HIDE_SEND_MODAL: 'push-notification.hide.send-modal',
    PUSH_NOTIFICATION: {
        CLEAR_TARGET: 'push-notification.clear-target',
        FIND_BY_ID: {
            ERROR: 'push-notifications.findById.error',
            START: 'push-notifications.findById.start',
            SUCCESS: 'push-notifications.findById.success'
        },
        GET: {
            ERROR: 'push-notifications.get.error',
            START: 'push-notifications.get.start',
            SUCCESS: 'push-notifications.get.success'
        },
        PARTNER: {
            GET: {
                ERROR: 'push-notifications.partner.get.error',
                START: 'push-notifications.partner.get.start',
                SUCCESS: 'push-notifications.partner.get.success',
            },
        },
        SAVE: {
            ERROR: 'push-notifications.save.error',
            START: 'push-notifications.save.start',
            SUCCESS: 'push-notifications.save.success',
        },
        SEND: {
            ERROR: 'push-notifications.send.error',
            START: 'push-notifications.send.start',
            SUCCESS: 'push-notifications.send.success',
        },
        UPDATE: 'push-notifications.update.notification',
        UPDATE_ASSET: 'push-notifications.update-asset',
        UPDATE_TITLE: 'push-notifications.update-title',
        USERS: {
            GET: {
                ERROR: 'push-notifications.users.get.error',
                START: 'push-notifications.users.get.start',
                SUCCESS: 'push-notifications.users.get.success',
            }
        }
    },
    SHOW_SEND_MODAL: 'push-notification.show.send-modal',
};

class PushNotificationActions {

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
    }

    clearTarget() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.PUSH_NOTIFICATION.CLEAR_TARGET,
        });
    }

    findById(id) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.PUSH_NOTIFICATION.FIND_BY_ID.START,
            id: id
        });

        Promise.all([
            Request.get(`system/push/message/${id}`).exec()
        ])
            .spread((pushNotificationRes/*, history*/) => {
                let pushNotification = pushNotificationRes.body;

                pushNotification = Immutable.fromJS(pushNotification);
                Dispatcher.dispatch({
                    actionType: CONSTANTS.PUSH_NOTIFICATION.FIND_BY_ID.SUCCESS,
                    pushNotification
                });
                return;
            }).catch((err) => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.PUSH_NOTIFICATION.FIND_BY_ID.ERROR,
                    error: err
                });

                switch (err.status) {
                case 404:
                    RouterActions.notFound();
                    break;
                default:
                    NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'common.load-error');
                    break;
                }
            });
    }

    get(offset=0, size=20) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.PUSH_NOTIFICATION.GET.START
        });
        Request.get('system/push/message')
            .query({
                offset,
                size
            })
            .exec()
            .then(res => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.PUSH_NOTIFICATION.GET.SUCCESS,
                    offset: res.body.offset,
                    pushNotifications: Immutable.fromJS(res.body.results).sortBy(r => r.get('id')),
                    size: res.body.size,
                    total: res.header['wbtv-total-count']
                });
                return;
            }).catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.PUSH_NOTIFICATION.GET.ERROR
                });

                throw err;
            });

        return;
    }

    getPartners(offset, size) {
        PreloaderActions.show('push-notification-actions.getPartners');
        Dispatcher.dispatch({
            actionType: CONSTANTS.PUSH_NOTIFICATION.PARTNER.GET.START
        });

        Request.get('partner')
            .query({
                offset: offset,
                size: size
            })
            .exec()
            .then(res => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.PUSH_NOTIFICATION.PARTNER.GET.SUCCESS,
                    offset: res.body.offset,
                    partners: Immutable.fromJS(res.body.results)
                        .map((p, i) => (i, p.get('clientId'))),
                    size: res.body.size,
                    total: res.header['wbtv-total-count']
                });
                PreloaderActions.hide('push-notification-actions.getPartners');
            }).catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.PUSH_NOTIFICATION.PARTNER.GET.ERROR
                });
                PreloaderActions.hide('push-notification-actions.getPartners');
                throw err;
            });

        return;
    }

    getUsers(id, filters, offset=0, size=20) {
        PreloaderActions.show('push-notification-actions.getUsers');
        Dispatcher.dispatch({
            actionType: CONSTANTS.PUSH_NOTIFICATION.USERS.GET.START
        });

        let query = {};
        if (filters.name) {
            query.name = filters.name;
        }

        Request.get(`system/push/message/${id}/user`).query({
            offset,
            size
        }).query(query).exec().then(usersRes => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.PUSH_NOTIFICATION.USERS.GET.SUCCESS,
                filters: Immutable.fromJS(filters),
                offset,
                size: usersRes.body.size,
                total: usersRes.body.totalCount,
                users: Immutable.fromJS(usersRes.body.results)
            });

            PreloaderActions.hide('push-notification-actions.getUsers');
            return;
        }).catch(e => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.PUSH_NOTIFICATION.USERS.GET.ERROR
            });
            PreloaderActions.hide('push-notification-actions.getUsers');
            throw e;
        });

        return;
    }

    hideSendModal() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.HIDE_SEND_MODAL,
        });
    }

    savePushNotification(id, notification, pushNotificationAsset, pushNotificationTitle) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.PUSH_NOTIFICATION.SAVE.START,
            id: id
        });

        let method = Request.post;
        let uri = 'system/push/message';
        if (id !== undefined) {
            // create => PUT
            method = Request.put;
            uri = `system/push/message/${id}`;
        }

        const jsNotification = notification.toJS();
        jsNotification.environment = Config.APIEnvironment;

        if (pushNotificationAsset.size) {
            jsNotification.mediaAssetId = pushNotificationAsset.get('id');
        } else if (pushNotificationTitle.size) {
            jsNotification.titleId = pushNotificationTitle.get('id');
        }
        jsNotification.userGroups.forEach(u => {
            delete u.numberOfItemsInGroup;
        });
        ['history', 'isActive', 'partners', 'pushNotificationAsset', 'pushNotificationTitle', 'sendDescription'].forEach(p => delete jsNotification[p]);
        //jsNotification.isActive = jsNotification.isActive ? 1 : 0;

        method(uri).send(jsNotification).exec().then(res => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.PUSH_NOTIFICATION.SAVE.SUCCESS
            });
            if (!id) {
                RouterActions.redirect(`/push-notifications/${res.body.id}`, true);
                NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'push-notifications.edit.save.success.create');
            } else {
                NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'push-notifications.edit.save.success.update');
            }
            return;
        }).catch(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.PUSH_NOTIFICATION.SAVE.ERROR
            });

            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'push-notifications.edit.save.error');
            return;
        });
    }

    sendNotification(id) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.PUSH_NOTIFICATION.SEND.START,
        });

        Request.post(`system/push/message/${id}/send`).send().exec().then(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.PUSH_NOTIFICATION.SEND.SUCCESS
            });

            NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, 'push-notifications.edit.send.success');
            return;
        }).catch((err) => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.PUSH_NOTIFICATION.SEND.ERROR,
                error: err
            });
            switch (err.status) {
            case 422:
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'push-notifications.edit.send.error-already-sent');
                break;
            default:
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'push-notifications.edit.send.error');
                break;
            }
            return;
        });

    }

    showSendModal() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SHOW_SEND_MODAL,
        });
    }

    updatePushNotification(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.PUSH_NOTIFICATION.UPDATE,
            attr,
            value
        });
        return;
    }

    updatePushNotificationAsset(value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.PUSH_NOTIFICATION.UPDATE_ASSET,
            value
        });
        return;
    }

    updatePushNotificationTitle(value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.PUSH_NOTIFICATION.UPDATE_TITLE,
            value
        });
        return;
    }
}

let actions = new PushNotificationActions();

export {
    actions as PushNotificationActions,
    CONSTANTS as PushNotificationConstants
};
