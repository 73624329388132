/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

class MediaPanel extends Component {
    static get propTypes() {
        return {
            addExisting: PropTypes.func,
            addNew: PropTypes.func,
            children: PropTypes.object.isRequired,
            description: PropTypes.node,
            disabled: PropTypes.bool,
            icon: PropTypes.string.isRequired,
            noSeparator: PropTypes.bool,
            title: PropTypes.string.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            addExisting: undefined,
            addNew: undefined,
            description: undefined,
            disabled: true,
            noSeparator: false,
            replaceVideo: undefined,
        };
    }

    render() {
        let addNewButton;
        let addExistingButton;
        if (this.props.addNew) {
            addNewButton=<Button
                bsStyle="primary"
                className="bg-wb-blue"
                disabled={this.props.disabled}
                onClick={this.props.addNew}>
                <i className="far fa-plus-square"/>&nbsp;{this.context.intl.messages['titles.create.assets.add-new']}
            </Button>;
        }
        if (this.props.addExisting) {
            addExistingButton=<Button
                bsStyle="primary"
                className="bg-wb-blue"
                disabled={this.props.disabled}
                onClick={this.props.addExisting}>
                <i className="fas fa-plus-square"/>&nbsp;{this.context.intl.messages['common.add-existing']}
            </Button>;
        }
        let active;
        let inactive;
        React.Children.forEach(this.props.children, function(t) {
            if (t.props.active) {
                active = t;
            } else {
                inactive = t;
            }
        });
        let activeNode = null;
        if (active && active.props.assets.size !== 0) {
            activeNode=(<div>
                <h4>Active</h4>
                <div className="form-section">
                    {active}
                </div>
            </div>);
        }
        let inactiveNode = null;
        if (inactive && inactive.props.assets.size !== 0) {
            inactiveNode=(<div>
                <h4>Inactive</h4>
                <div className="form-section">
                    {inactive}
                </div>
            </div>);
        }
        let description;
        if (this.props.description) {
            description = this.props.description;
        }
        return (
            <div className={ClassNames({'media-asset-all':!this.props.noSeparator})}>
                <h3><i className={ClassNames(this.props.icon)}/>&nbsp;{this.props.title}
                    {description}
                    <span className="pull-right">{addNewButton}&nbsp;{addExistingButton}</span>
                </h3>
                {activeNode}
                {inactiveNode}
            </div>
        );

    }
}

export default MediaPanel;
