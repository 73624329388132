/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {AssetConstants} from './asset-actions';
import Dispatcher from '../dispatcher/dispatcher';

let findInConstant = function(constants, objectId) {
    return Immutable.fromJS(
        Object.keys(constants)
            .map(k => constants[k])
            .filter(obj => objectId === obj.id)[0]
    );
};

class AssetStore extends ReduceStore {

    getDeliveryType(deliveryTypeId) {
        return findInConstant(AssetConstants.DELIVERY_TYPES, deliveryTypeId);
    }

    getAssetMimeType(constants, mimeTypeId) {
        return findInConstant(constants, mimeTypeId);
    }

    getInitialState() {
        return Immutable.Map({
            filters: Immutable.Map(),
            offset: 0,
            size: 20,
            sortFieldName: 'updatedDate',
            sortDirection: 'desc',
            asset: Immutable.Map(),
            assetTypes: Immutable.fromJS(AssetConstants.toArray('ASSET_TYPES')),
            assets: Immutable.List(),
            catalogs: Immutable.List(),
            deliveryType: Immutable.fromJS(AssetConstants.toArray('DELIVERY_TYPES')),
            audioMimeTypes: Immutable.fromJS(AssetConstants.toArray('MIME_TYPES_AUDIO')),
            documentMimeTypes: Immutable.fromJS(AssetConstants.toArray('MIME_TYPES_DOCUMENT')),
            imageMimeTypes: Immutable.fromJS(AssetConstants.toArray('MIME_TYPES_IMAGE')),
            videoMimeTypes: Immutable.fromJS(AssetConstants.toArray('MIME_TYPES_VIDEO')),
            total: 0,
        });
    }

    reduce(state, action) {
        switch (action.actionType) {
        case AssetConstants.ASSET.CATALOG.GET.SUCCESS:
            let index = state.get('assets').findIndex(t => t.get('id') === action.assetId);
            state = state.setIn(
                ['assets', index, 'catalogs'],
                action.catalogs
            );
            break;

        case AssetConstants.CATALOGS.GET.SUCCESS:
            state = state.set('catalogs', action.catalogs);
            break;

        case AssetConstants.CLEAR:
            state = this.getInitialState();
            break;

        case AssetConstants.FILTER.CLEAR:
            state = state.set('filters', Immutable.Map());
            break;

        case AssetConstants.FILTER.SET:
            state = state.setIn(['filters', ...action.attr.split('.')], action.value);
            break;
        case AssetConstants.SORT.SET:
            state = state.merge({
                sortFieldName: action.sortFieldName,
                sortDirection: action.sortDirection
            });
            break;

        case AssetConstants.GET.SUCCESS:
            state = state.merge({
                offset: action.offset,
                size: action.size,
                assets: action.assets,
                total: action.total
            });
            break;

        case AssetConstants.THUMBNAILS.GET.SUCCESS:
            state = state.update('assets', assets => {
                return assets.map(a => {
                    let t = action.thumbnails[a.get('id').toString()];
                    if (t) {
                        /**
                         * The server returns a list of thumbnails for videos and a single
                         * thumbnail for images. Putting the image thumbnail inside an array
                         * makes handling data in the view easier.
                         */
                        a = a.set('thumbnails', Immutable.fromJS(t.thumbnailList || [t]));
                    }

                    return a;
                });
            });
            break;
        case AssetConstants.CLIPS.GET.SUCCESS:
            state = state.update('assets', assets => {
                return assets.map(a => {
                    a = a.set('clips', Immutable.fromJS(action.clips[a.get('id').toString()] || []));
                    return a;
                });
            });
            break;
        }
        return state;
    }
}

let store = new AssetStore(Dispatcher);

export default store;
