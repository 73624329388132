/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

import {CompareImmutable, CompareImmutableList} from '../../../common/utils/utils';
import Dispatcher from '../../../dispatcher/dispatcher';
import Request from '../../../request';

const CONSTANTS = {
    BROADCAST_STANDARD_TYPES: {
        STANDARD_1080P23_98: {id: '1080p23.98', name: '1080p23.98'},
        STANDARD_1080P25: {id: '1080p25', name: '1080p25'},
        STANDARD_1080I50: {id: '1080i50', name: '1080i50'},
        STANDARD_1080I59_94: {id: '1080i59.94', name: '1080i59.94'},
        NTSC: {id: 'NTSC', name: 'NTSC'},
        PAL: {id: 'PAL', name: 'PAL'}
    },
    CLEAR: 'delivery_actions.clear',
    FILE_FORMAT_TYPES: {
        XDCAM: {id: 'XDCAM', name: 'XDCAM'},
        PRO_RES: {id: 'ProRes', name: 'ProRes'},
        H264: {id: 'H.264', name: 'H.264'}
    },
    NOTIFICATION: {
        ADD: 'delivery_actions.notification.add',
        CLONE: 'delivery_actions.notification.clone',
        GET: 'delivery_actions.notification.get',
        REMOVE: 'delivery_actions.notification.remove',
        UPDATE: 'delivery_actions.notification.update'
    },
    PROFILE: {
        CLEAR: 'delivery_actions.profile.clear',
        CLONE: 'delivery_actions.profile.clone',
        GET: 'delivery_actions.profile.get',
        UPDATE: 'delivery_actions.profile.update'
    },
    SET: {
        VALIDATIONS: 'delivery_actions.validations'
    },

    toArray: function(constant) {
        return Object.keys(this[constant])
            .map(k => this[constant][k])
            .sort((a, b) => a.name.localeCompare(b.name));
    }
};

class DeliveryActions {

    addNotification() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.NOTIFICATION.ADD
        });
    }

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
    }

    getNotificationsById(userId) {
        Request.get(`user/${userId}/delivery-profile/notification`)
            .exec()
            .then(res => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.NOTIFICATION.GET,
                    notifications: Immutable.fromJS(res.body)
                });
                return;
            });
        return;
    }

    getProfileById(userId) {
        Request.get(`user/${userId}/delivery-profile`)
            .exec()
            .then(res => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.PROFILE.GET,
                    profile: Immutable.fromJS(res.body)
                });
                return;
            })
            .catch((err) => {
                switch (err.status) {
                case 404:
                    Dispatcher.dispatch({
                        actionType: CONSTANTS.PROFILE.CLEAR
                    });
                    break;
                }
                throw err;
            });
        return;
    }

    removeNotification(index, notification) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.NOTIFICATION.REMOVE,
            index: index,
            notification: notification
        });
    }

    updateNotifications(orderIn, attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.NOTIFICATION.UPDATE,
            attr: attr,
            orderIn: orderIn,
            value: value
        });
        return;
    }

    updateProfile(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.PROFILE.UPDATE,
            attr: attr,
            value: value
        });
        return;
    }
}

let deliveryNotificationSave = function(userId, notifications, originalNotifications) {
    let op = [];
    if (!CompareImmutableList(notifications, originalNotifications)) {
        let notificationsData = notifications.toJS();
        notificationsData.forEach((n, i) => {
            n.notificationNumber = i;
            delete n.orderIn;
        });
        op = Request.put(`user/${userId}/delivery-profile/notification`)
            .send(notificationsData)
            .exec()
            .then(res => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.NOTIFICATION.CLONE,
                    notifications: Immutable.fromJS(res.body)
                });
            })
            .catch(err => {
                throw err;
            });
    }
    return op;
};

let deliveryProfileSave = function(userId, profile, originalProfile) {
    let method = Request.post;
    if (originalProfile.size) {
        method = Request.put;
    }
    let op = [];
    if (!CompareImmutable(profile, originalProfile)) {
        op = method(`user/${userId}/delivery-profile`)
            .send(profile)
            .exec()
            .then(res => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.PROFILE.CLONE,
                    profile: Immutable.fromJS(res.body)
                });
            });
    }
    return op;
};

let actions = new DeliveryActions();

export {
    actions as DeliveryActions,
    deliveryNotificationSave as DeliveryNotificationSave,
    deliveryProfileSave as DeliveryProfileSave,
    CONSTANTS as DeliveryConstants
};
