/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import jQuery from 'jquery';
import PropTypes from 'prop-types';
import React from 'react';

import {MassAddUpdateActions} from './mass-add-update-actions';
import MassAddUpdateStore from './mass-add-update-store';

require('../../styles/data-tables-brainiac.css');

const HEADERS = [
    {title:'titles.mass-add.row-title.title', required:true},
    {title:'titles.mass-add.row-title.type', required:true},
    {title:'titles.mass-add.row-title.mpm', required:false},
    {title:'titles.mass-add.row-title.copyright', required:false},
    {title:'titles.mass-add.row-title.active', required:false},
    {title:'titles.mass-add.row-title.aka-1', required:false},
    {title:'titles.mass-add.row-title.aka-2', required:false},
    {title:'titles.mass-add.row-title.pka-1', required:false},
    {title:'titles.mass-add.row-title.pka-2', required:false},
    {title:'titles.mass-add.row-title.ika-1', required:false},
    {title:'titles.mass-add.row-title.ika-2', required:false},
    {title:'titles.mass-add.row-title.alternative-title-1', required:false},
    {title:'titles.mass-add.row-title.alternative-title-2', required:false},
    {title:'titles.mass-add.row-title.release-company', required:false},
    {title:'titles.mass-add.row-title.runtime', required:false},
    {title:'titles.mass-add.row-title.synopsis', required:false},
    {title:'titles.mass-add.row-title.production-companies', required:false},
    {title:'titles.mass-add.row-title.genres', required:false},
    {title:'titles.mass-add.row-title.themes', required:false},
    {title:'titles.mass-add.row-title.presentation-credits', required:false},
    {title:'titles.mass-add.row-title.relationship-order', required:false},
    {title:'titles.mass-add.row-title.catalogs', required:false},
    {title:'titles.mass-add.row-title.title-id', required:false},
    {title:'titles.mass-add.row-title.made-for', required:true},
    {title:'titles.mass-add.row-title.action-type', required:true},
    {title:'titles.mass-add.row-title.product-brm', required:false},
    {title:'titles.mass-add.row-title.season-number', required:false},
    {title:'titles.mass-add.row-title.episode-number', required:false},
    {title:'titles.mass-add.row-title.episode-count', required:false},
    {title:'titles.mass-add.row-title.short-synopsis', required:false},
    {title:'titles.mass-add.row-title.log-180-lines', required:false},
    {title:'titles.mass-add.row-title.log-60-lines', required:false},
    {title:'titles.mass-add.row-title.program-legal-line', required:false},
    {title:'titles.mass-add.row-title.promotional-materials-legal-line', required:false},
    {title:'titles.mass-add.row-title.languages', required:false},
    {title:'titles.mass-add.row-title.parental-content-rating', required:false},
    {title:'titles.mass-add.row-title.mastering-comment', required:false},
    {title:'titles.mass-add.row-title.aspect-ratio', required:false},
    {title:'titles.mass-add.row-title.audio', required:false},
    {title:'titles.mass-add.row-title.standard', required:false},
    {title:'titles.mass-add.row-title.source-media', required:false},
    {title:'titles.mass-add.row-title.release-type', required:false},
    {title:'titles.mass-add.row-title.release-status', required:false},
    {title:'titles.mass-add.row-title.release-date-type', required:false},
    {title:'titles.mass-add.row-title.release-date', required:false},
    {title:'titles.mass-add.row-title.release-territory', required:false},
    {title:'titles.mass-add.row-title.release-language', required:false},
    {title:'titles.mass-add.row-title.release-time', required:false},
    {title:'titles.mass-add.row-title.cast-and-credits', required:false},
    {title:'titles.mass-add.row-title.relationship-type', required:false},
    {title:'titles.mass-add.row-title.release-local-title', required:false}
];

export class IngestRowsTable extends React.Component {
    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            displayLinks: false,
            step: ''
        };
    }

    static get propTypes() {
        return {
            rows: PropTypes.instanceOf(Immutable.List).isRequired,
            step: PropTypes.string
        };
    }

    static calculateState() {
        return {
            selectAll: MassAddUpdateStore.getState().get('selectAll'),
            rows: MassAddUpdateStore.getState().get('rows')
        };
    }

    static getStores() {
        return [MassAddUpdateStore];
    }

    constructor(props) {
        super(props);
        this.handleSelectAllClick = this.handleSelectAllClick.bind(this);
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        this.$table = jQuery('#list-title-table');
        let dataTableConfig = {
            autoWidth: false,
            columnDefs: [{
                orderable: false,
                targets: 'no-sort'
            }, {
                className: 'no-padding text-center',
                targets: 0
            }],
            iDisplayLength: 100,
            info: false,
            order: [[1, 'dec']],
            ordering: false,
            paging: false,
            responsive: false,
            searching: false,
            scrollX: true
        };

        this.$tableAPI = this.$table.DataTable(dataTableConfig);
        this.$table.on('change', '.select-row', this.handleSelectClick);
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        return;
    }

    componentWillUpdate(nextProps/*, nextState */) {
        this.$tableAPI.clear();
        nextProps.rows.forEach((titleRowList, index) => {
            let row = [];
            let firstCol;
            if (this.props.step === 'validateAndSave') {
                let selected = '';
                if (titleRowList.get('__selected')) {
                    selected = 'checked';
                }
                firstCol = `<input type="checkbox" class="select-row"  data-index="${index}" ${selected}/>`;
            } else {
                switch (titleRowList.get('status')) {
                case 'Complete':
                    firstCol = '<i class="fas fa-check text-green"></i>';
                    break;
                case 'Rejected':
                    firstCol = '<i class="fas fa-ban"></i>';
                    break;
                default:
                    let errorMessage = titleRowList.get('importErrors');
                    firstCol = `<div class="parcel-tooltip"><i class="fas fa-exclamation-triangle text-red" title="${errorMessage}"></i><span class="tooltip-inner">${errorMessage}</span></div>`;
                }
            }

            row.push(
                firstCol,
                titleRowList.get('title') || '',
                titleRowList.get('type') || '',
                titleRowList.get('mpmNumber') || '',
                titleRowList.get('copyrightYear') || '',
                titleRowList.get('active') || '',
                titleRowList.get('aka1') || '',
                titleRowList.get('aka2')|| '',
                titleRowList.get('pka1') || '',
                titleRowList.get('pka2') || '',
                titleRowList.get('ika1') || '',
                titleRowList.get('ika2') || '',
                titleRowList.get('alternativeTitle1') || '',
                titleRowList.get('alternativeTitle2') || '',
                titleRowList.get('releaseCompany') || '',
                titleRowList.get('runtime') || '',
                titleRowList.get('synopsis')|| '',
                titleRowList.get('productionCompany') || '',
                titleRowList.get('genre') || '',
                titleRowList.get('theme') || '',
                titleRowList.get('presentationCredits')|| '',
                titleRowList.get('relationshipOrder') || '',
                titleRowList.get('catalog') || '',
                titleRowList.get('titleId') || '',
                titleRowList.get('madeFor') || '',
                titleRowList.get('actionType') || '',
                titleRowList.get('productBrm') || '',
                titleRowList.get('seasonNumber') || '',
                titleRowList.get('episodeNumber') || '',
                titleRowList.get('episodeCount') || '',
                titleRowList.get('shortSynopsis') || '',
                titleRowList.get('log180Lines') || '',
                titleRowList.get('log60Lines') || '',
                titleRowList.get('programLegalLine') || '',
                titleRowList.get('promotionalMaterialsLegalLine') || '',
                titleRowList.get('languages') || '',
                titleRowList.get('parentalContentRating') || '',
                titleRowList.get('masteringComment') || '',
                titleRowList.get('aspectRatio') || '',
                titleRowList.get('audio') || '',
                titleRowList.get('standard') || '',
                titleRowList.get('sourceMedia') || '',
                titleRowList.get('releaseType') || '',
                titleRowList.get('releaseStatus') || '',
                titleRowList.get('releaseDateType') || '',
                titleRowList.get('releaseDate') || '',
                titleRowList.get('releaseTerritory') || '',
                titleRowList.get('releaseLanguage') || '',
                titleRowList.get('releaseTime') || '',
                titleRowList.get('castAndCredits') || '',
                titleRowList.get('relationshipType') || '',
                titleRowList.get('releaseLocalTitle') || ''
            );

            let currentRow = this.$tableAPI.row.add(row);

            if (this.props.step === 'confirmation') {
                switch (titleRowList.get('status')) {
                case 'Complete':
                    break;
                case 'Rejected':
                    jQuery(currentRow.node()).addClass('mass-validate-rejected');
                    break;
                default:
                    jQuery(currentRow.node()).addClass('mass-validate-error');
                }
            }

            return;
        });

        this.$tableAPI.draw(false);

        this.handleResize();

        return;
    }

    buildHeaders() {
        return HEADERS.map((header, index) => {
            let required = '';
            if (header.required) {
                required = ' *';
            }
            return <th key={index} className="no-arrow">{this.context.intl.messages[header.title] + required}</th>;
        });
    }

    handleResize() {
        this.$tableAPI.responsive.recalc();
        return;
    }

    handleSelectAllClick(event) {
        MassAddUpdateActions.toggleSelectAll(event.target.checked);
        return;
    }

    handleSelectClick(event) {
        let data = event.target.outerHTML;
        let indexId = data.split('"');
        let selectRowIndex = parseInt(indexId[5]);
        MassAddUpdateActions.toggleSelect(event.target.checked, selectRowIndex);
        return;
    }

    render() {
        let header;
        switch (this.props.step) {
        case 'validateAndSave':
            header = <input type="checkbox" onChange={this.handleSelectAllClick} checked={this.state.selectAll} className="check-all"/>;
            break;
        case 'confirmation':
            header = <i className="fas fa-check"></i>;
            break;
        }
        return (
            <div className="mass-validate-table">
                <table id="list-title-table" className="table table-bordered table-striped display nowrap">
                    <thead>
                        <tr>
                            <th className="no-arrow no-sort sorting_asc">
                                {header}
                            </th>
                            {this.buildHeaders()}
                        </tr>
                    </thead>
                    <tbody/>
                </table>
            </div>
        );
    }
}
export default Container.create(IngestRowsTable);
