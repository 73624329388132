/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {DeliveryConstants} from './delivery-actions';

import Validations from '~/src/common/validations/validations';
import Dispatcher from '~/src/dispatcher/dispatcher';

const findInConstant = function(constants, objectId) {
    return Immutable.fromJS(
        Object.keys(constants)
            .map(k => constants[k])
            .filter(obj => objectId === obj.id)[0]
    );
};

const DeliveryValidations = {
    notifications: {
        validations: [Validations.custom(
            function() {
                return 'There is an error in Delivery Notifications.';
            },
            function() {
                let isValid = true;
                let nameRules = [Validations.max(50), Validations.missing()];
                let emailRules = [Validations.email, Validations.max(100), Validations.missing()];
                store.getState().get('notifications').forEach(notification => {
                    let notificationName = notification.get('notificationName');
                    let email = notification.get('notificationEmail');
                    let validate = function(rules, value) {
                        rules.forEach(r => {
                            if (!r.validate(value)) {
                                isValid = false;
                            }
                        });
                    };
                    validate(nameRules, notificationName);
                    validate(emailRules, email);
                });
                return isValid;
            }
        ),
        Validations.custom(
            function() {
                return 'Name should start with a capital letter.';
            },
            function() {
                let isValid = true;
                let nameRules = [Validations.capitalization];
                store.getState().get('notifications').forEach(notification => {
                    let notificationName = notification.get('notificationName');
                    let validate = function(rules, value) {
                        rules.forEach(r => {
                            if (!r.validate(value)) {
                                isValid = false;
                            }
                        });
                    };
                    validate(nameRules, notificationName);
                });
                return isValid;
            }
        )]
    }
};

class DeliveryStore extends ReduceStore {

    getBroadcastStandardType(broadcastStandardId) {
        return findInConstant(DeliveryConstants.BROADCAST_STANDARD_TYPES, broadcastStandardId);
    }

    getFileFormatType(fileFormatId) {
        return findInConstant(DeliveryConstants.FILE_FORMAT_TYPES, fileFormatId);
    }

    getInitialState() {
        let state = Immutable.Map({
            broadcastStandard: Immutable.fromJS(DeliveryConstants.toArray('BROADCAST_STANDARD_TYPES')),
            notifications: Immutable.List(),
            profile: Immutable.Map(),
            fileFormat: Immutable.fromJS(DeliveryConstants.toArray('FILE_FORMAT_TYPES'))
        });

        state = state.set('originalProfile', state.get('profile'));
        state = state.set('originalNotifications', state.get('notifications'));

        return state;
    }

    getValidations() {
        return Validations.validate(this.getState().get('notifications'), DeliveryValidations);
    }

    reduce(state, action) {
        switch (action.actionType) {

        case DeliveryConstants.CLEAR:
            state = this.getInitialState();
            break;

        case DeliveryConstants.NOTIFICATION.ADD:
            state = state.updateIn(['notifications'], notifications => {
                return notifications.push(Immutable.Map({
                    orderIn: notifications.size + 1
                }));
            });
            break;

        case DeliveryConstants.NOTIFICATION.CLONE:
            state = state.merge({
                originalNotifications: action.notifications,
                notifications: action.notifications,
            });
            break;

        case DeliveryConstants.NOTIFICATION.GET:
            let ns = action.notifications.map( (n, i) => {
                n.orderIn = i;
                return n;
            });
            state = state.merge({
                notifications: ns,
                originalNotifications: ns
            });
            break;

        case DeliveryConstants.NOTIFICATION.REMOVE:
            state = state.updateIn(['notifications'], notifications => {
                return notifications.delete(action.index);
            });
            break;

        case DeliveryConstants.NOTIFICATION.UPDATE:
            state = state.update('notifications', notifications => {
                return notifications.map((n, i) => {
                    if (i === action.orderIn) {
                        n = n.set(...action.attr.split('.'), action.value);
                    }
                    return n;
                });
            });
            break;

        case DeliveryConstants.PROFILE.CLEAR:
            state = state.merge({
                profile: Immutable.Map(),
                originalProfile: Immutable.Map()
            });
            break;

        case DeliveryConstants.PROFILE.CLONE:
            state = state.merge({
                originalProfile: action.profile,
                profile: action.profile,
            });
            break;

        case DeliveryConstants.PROFILE.GET:
            state = state.merge({
                profile: action.profile,
                originalProfile: action.profile
            });
            break;

        case DeliveryConstants.PROFILE.UPDATE:
            state = state.setIn(['profile', ...action.attr.split('.')], action.value);
            break;

        case DeliveryConstants.SET.VALIDATIONS:
            state = state.merge({
                validations: action.validations
            });
            break;
        }

        return state;
    }
}

const store = new DeliveryStore(Dispatcher);

export default store;
export {
    DeliveryValidations
};
