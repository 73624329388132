/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import AddExistingTitles from './add-existing-titles';
import AddExistingAssets from '../../../assets-tab/add-existing-assets';
import {AssetTabActions} from '../../../assets-tab/asset-tab-actions';
import AssetTabStore from '../../../assets-tab/asset-tab-store';
import SlidingPanel from '../../../common/sliding-panel/sliding-panel';
import {SlidingPanelActions} from '../../../common/sliding-panel/sliding-panel-actions';
import {BatchActions, BatchConstants} from '../../batch-actions';

class AddExistingPanel extends Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            type: PropTypes.string.isRequired,
            title: PropTypes.node.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            selectedTitles: Immutable.List()
        };

        this.handleAddTitle = this.handleAddTitle.bind(this);
        this.handleCancelPanel = this.handleCancelPanel.bind(this);
        this.handleClickAddSelectedItems = this.handleClickAddSelectedItems.bind(this);
    }

    clearSelectedTitles() {
        this.setState(() => ({
            selectedTitles: Immutable.List()
        }));
    }

    handleAddTitle(title) {
        const titleIndex = this.state.selectedTitles.findIndex(t => t.get('id') === title.get('id'));
        if (titleIndex === -1) {
            this.setState(prevState => ({
                selectedTitles: prevState.selectedTitles.push(title)
            }));
        } else {
            this.setState(prevState => ({
                selectedTitles: prevState.selectedTitles.remove(titleIndex)
            }));
        }
    }

    handleCancelPanel() {
        this.clearSelectedTitles();
        AssetTabActions.clearSelectedAssetsToAdd();
    }

    handleClickAddSelectedItems() {
        switch (this.props.type.toUpperCase()) {
        case BatchConstants.BATCH_TYPES.DOCUMENT:
        case BatchConstants.BATCH_TYPES.IMAGE:
        case BatchConstants.BATCH_TYPES.VIDEO:
            BatchActions.addItems(AssetTabStore.getState().get('selectedAssetsToAdd').toJS());
            AssetTabActions.clearSelectedAssetsToAdd();
            break;
        case BatchConstants.BATCH_TYPES.TITLE:
            BatchActions.addItems(this.state.selectedTitles.toJS());
            this.clearSelectedTitles();
        }

        SlidingPanelActions.hide('addExistingItemToBatch');
        return;
    }

    render() {
        const addSelectedItems = (
            <button
                className="btn btn-large bg-green"
                onClick={this.handleClickAddSelectedItems}
            >
                <i className="fas fa-plus"></i>&nbsp;{this.context.intl.messages[`scheduling.batch.items-list.add-${this.props.type.toLowerCase()}`]}
            </button>
        );

        let addExistingItemsPanel;
        switch (this.props.type.toUpperCase()) {
        case BatchConstants.BATCH_TYPES.DOCUMENT:
        case BatchConstants.BATCH_TYPES.IMAGE:
        case BatchConstants.BATCH_TYPES.VIDEO:
            addExistingItemsPanel = <AddExistingAssets displayLinks={false} assetType={this.props.type.toLowerCase()}/>;
            break;
        case BatchConstants.BATCH_TYPES.TITLE:
            addExistingItemsPanel = <AddExistingTitles location={this.props.location} onAddTitle={this.handleAddTitle} selectedTitles={this.state.selectedTitles} />;
        }

        return (
            <SlidingPanel
                extraButtons={addSelectedItems}
                icon="fas fa-plus-circle"
                id="addExistingItemToBatch"
                onCancel={this.handleCancelPanel}
                title={this.props.title}
            >
                {addExistingItemsPanel}
            </SlidingPanel>
        );
    }
}

export default AddExistingPanel;
