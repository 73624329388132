/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';

import {AlertTypes} from '../../common/notification/alert';
import {NotificationActions} from '../../common/notification/notification-actions';
import Dispatcher from '../../dispatcher/dispatcher';
import {PreloaderActions} from '../../preloader/preloader-actions';
import Request from '../../request';

const CONSTANTS = {
    PERMISSION: {
        GET: {
            ERROR: 'security.permission.get.error',
            START: 'security.permission.get.start',
            SUCCESS: 'security.permission.get.success',
        },
        GET_BY_ID: {
            ERROR: 'security.permission.get_by_id.error',
            START: 'security.permission.get_by_id.start',
            SUCCESS: 'security.permission.get_by_id.success',
        },
        ROLES: {
            GET: {
                ERROR: 'security.permission.roles.get.error',
                START: 'security.permission.roles.get.start',
                SUCCESS: 'security.permission.roles.get.success',
            },
            USER: {
                GET: {
                    ERROR: 'security.permission.roles.user.get.error',
                    START: 'security.permission.roles.user.get.start',
                    SUCCESS: 'security.permission.roles.get.user.success',
                },
            }
        },
        USER: {
            GET: {
                ERROR: 'security.permission.user.get.error',
                START: 'security.permission.user.get.start',
                SUCCESS: 'security.permission.get.user.success',
            },
        },
    },
    SITES: {
        API: {
            PERMISSION_TYPE: 3,
            SITE_TYPE: 4,
        },
        CMS: {
            PERMISSION_TYPE: 4,
            SITE_TYPE: 5,
        },
        FYC: {
            PERMISSION_TYPE: 6,
            SITE_TYPE: 7,
        },
        WBTVD: {
            PERMISSION_TYPE: 5,
            SITE_TYPE: 6,
        },
    }
};

class PermissionActions {
    getPermission(permissionId) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.PERMISSION.GET_BY_ID.START
        });

        Promise.all([Request.get(`security/permission/${permissionId}`).exec(),
            Request.get(`security/permission/${permissionId}/path`).exec()])
            .spread((permissionRes, pathRes) => {
                const permission = permissionRes.body;
                permission.path = pathRes.body.permissionPath;

                Dispatcher.dispatch({
                    actionType: CONSTANTS.PERMISSION.GET_BY_ID.SUCCESS,
                    permission,
                });
            }).catch((error) => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.PERMISSION.GET_BY_ID.ERROR,
                    error,
                });

                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'permission.actions.get_by_id.error');
                throw error;
            });
    }

    getPermissionTree() {
        PreloaderActions.show('permission-actions.getPermissionTree');
        Dispatcher.dispatch({
            actionType: CONSTANTS.PERMISSION.GET.START
        });

        Promise.all([
            // Fetch Brainiac Permissions
            Request.get('security/permission-function').query({
                'site-type': CONSTANTS.SITES.CMS.SITE_TYPE,
                'permission-type': CONSTANTS.SITES.CMS.PERMISSION_TYPE
            }).exec(),
            // Fetch Api Permissions
            Request.get('security/permission-function').query({
                'site-type': CONSTANTS.SITES.API.SITE_TYPE,
                'permission-type': CONSTANTS.SITES.API.PERMISSION_TYPE
            }).exec(),
            // Fetch WBTVD Permissions
            Request.get('security/permission-function').query({
                'site-type': CONSTANTS.SITES.WBTVD.SITE_TYPE,
                'permission-type': CONSTANTS.SITES.WBTVD.PERMISSION_TYPE
            }).exec(),
            // Fetch FYC Permissions
            Request.get('security/permission-function').query({
                'site-type': CONSTANTS.SITES.FYC.SITE_TYPE,
                'permission-type': CONSTANTS.SITES.FYC.PERMISSION_TYPE
            }).exec()
        ]).spread((cms, api, wbtvd, fyc) => {
            let getBrokenPermissions = (...permissions) => {
                let result = [];

                permissions.forEach(perms => {
                    result = result.concat(perms.filter(p => !p.mainFuncPermissionId));
                    return;
                });

                return result;
            };

            let brokenPermissions = getBrokenPermissions(api.body, cms.body, wbtvd.body);
            if (brokenPermissions.length) {
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'roles.edit.load.error.broken-permissions', JSON.stringify(brokenPermissions));
            }

            this.load('API', api.body);
            this.load('CMS', cms.body);
            this.load('WBTVD', wbtvd.body);
            this.load('FYC', fyc.body);
            PreloaderActions.hide('permission-actions.getPermissionTree');
            return;
        }).catch((err) => {
            NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'permission.actions.get.error');
            PreloaderActions.hide('permission-actions.getPermissionTree');
            throw err;
        });
        return;
    }

    load(root, permissions) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.PERMISSION.GET.SUCCESS,
            root: root,
            permissions: permissions
        });
    }

    getRoles(permissionId) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.PERMISSION.ROLES.GET.START,
        });

        Request.get(`security/permission/${permissionId}/role`)
            .exec()
            .then(res => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.PERMISSION.ROLES.GET.SUCCESS,
                    permissionId,
                    roles: res.body,
                });
            }).catch((error) => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.PERMISSION.ROLES.GET.ERROR,
                    error
                });

                throw error;
            });
    }

    getUsers(permissionId, offset, size, filter, sortFieldName, sortDirection) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.PERMISSION.USER.GET.START,
        });

        Request.get(`security/permission/${permissionId}/user`)
            .query({
                name: filter,
                offset,
                size,
                'sort-direction': sortDirection,
                'sort-field-name': sortFieldName,
            })
            .exec()
            .then(res => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.PERMISSION.USER.GET.SUCCESS,
                    users: res.body.results,
                    offset: res.body.offset,
                    size: res.body.size,
                    total: res.body.totalCount,
                });
            }).catch((error) => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.PERMISSION.USER.GET.ERROR,
                    error
                });

                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'permission.actions.user.get.error');
                throw error;
            });
    }

    // Get permission roles associated with a user
    getUserRoles(permissionId, userId) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.PERMISSION.ROLES.USER.GET.START,
        });

        Request.get(`security/permission/${permissionId}/role`)
            .query({
                'user-id': userId
            })
            .exec()
            .then(res => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.PERMISSION.ROLES.USER.GET.SUCCESS,
                    roles: res.body,
                    userId,
                });
            }).catch((error) => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.PERMISSION.ROLES.USER.GET.ERROR,
                    error
                });

                throw error;
            });
    }
}

let actions = new PermissionActions();

export {
    actions as PermissionActions,
    CONSTANTS as PermissionConstants
};
