/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import jQuery from 'jquery';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Modal, Col, Row} from 'react-bootstrap';

import {FormItem} from '../../../common/form/form';
import Validations from '../../../common/validations/validations';
import {SessionActions} from '../../../user/session/session-actions';
import SessionStore from '../../../user/session/session-store';
import {Alert} from '../alert';
import NotificationStore from '../notification-store';

import './mfa-push-notification-modal.less';

class MFAPushNotificationModal extends React.Component {
    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            show: NotificationStore.getState().get('mfaPushNotificationModal'),
            userId: SessionStore.getState().getIn(['mfaData', 'userId']),
            token: SessionStore.getState().getIn(['mfaData', 'authorization']),
            verifyCodeInProgress: SessionStore.getState().getIn(['mfaData', 'verifyCodeInProgress'])
        };
    }

    static getStores() {
        return [NotificationStore, SessionStore];
    }

    constructor(props) {
        super(props);

        this.state = {
            code: Immutable.fromJS({verificationCode: ''})
        };

        this.showEnterVerificationCode = this.showEnterVerificationCode.bind(this);
        this.handleCodeChange = this.handleCodeChange.bind(this);
        this.handleSendCode = this.handleSendCode.bind(this);
        this.hideVerificationCodeMessage = this.hideVerificationCodeMessage.bind(this);
        this.isValid = this.isValid.bind(this);
    }

    showEnterVerificationCode() {
        jQuery(this.refs.mfaRef).animate({left: '-600px'});
    }

    handleSendCode() {
        SessionActions.verifyToken(this.state.userId, this.state.code.get('verificationCode'), this.state.token);
    }

    handleCodeChange(attr, value) {
        this.setState(prevState => ({
            code: prevState.code.setIn([...attr.split('.')], value)
        }));
    }

    hideVerificationCodeMessage() {
        jQuery(this.refs.mfaRef).animate({left: '0px'});
    }

    isValid() {
        let itemName = this.state.code.get('verificationCode');
        return Validations.required.validate(itemName)
            && Validations.max(6).validate(itemName)
            && Validations.min(6).validate(itemName)
            && !this.state.verifyCodeInProgress;
    }

    render() {
        let inProgress = this.state.verifyCodeInProgress;
        let loginButtonText = this.context.intl.messages['common.submit'];
        let loginButtonProgress;
        if (inProgress) {
            loginButtonText = this.context.intl.messages['login.mfa.verifying-token'];
            loginButtonProgress = (
                <i className="fas fa-sync-alt fa-spin" />
            );
        }

        return (
            <Modal show={this.state.show}>
                <Modal.Header className="bg-wb-blue">
                    <Modal.Title className="text-center">{this.context.intl.messages['player.mfa.push-notification.modal.title']}</Modal.Title>
                </Modal.Header>
                <Alert/>
                <div className="mfa-outer-wrap">
                    <div className="mfa-middle-wrap" ref="mfaRef">
                        <div className="mfa-inner-wrap">
                            <Modal.Body className="text-center">
                                <img src={require('./mfa-push-notification.png')}/>
                                <br />
                                <br />
                                <p>
                                    {this.context.intl.messages['player.mfa.push-notification.modal.body']}&nbsp;
                                    <a onClick={this.showEnterVerificationCode}>{this.context.intl.messages['player.mfa.push-notification.modal.body.code']}</a>
                                </p>
                            </Modal.Body>
                        </div>
                        <div className="mfa-inner-wrap">
                            <Modal.Body>
                                <Row>
                                    <Col className="text-center mfa-form" style={{display: 'flex', justifyContent: 'center'}}>
                                        <FormItem
                                            type="number"
                                            attr={'verificationCode'}
                                            label={this.context.intl.messages['player.mfa.verification-code.modal.body']}
                                            model={this.state.code}
                                            placeholder={this.context.intl.messages['player.mfa.verification-code.modal.body.enter-code']}
                                            required
                                            showErrorMessage={true}
                                            setter={this.handleCodeChange}
                                            validations={[
                                                Validations.max(6),
                                                Validations.min(6),
                                                Validations.required
                                            ]}
                                        />
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Col className="text-center">
                                    <div className="row">
                                        <div className="col-xs-6">
                                            <Button bsStyle="default" className="btn btn-block btn-lg btn-flat" onClick={this.hideVerificationCodeMessage}>
                                                {this.context.intl.messages['common.cancel']}
                                            </Button>
                                        </div>
                                        <div className="col-xs-6">
                                            <Button bsStyle="primary" className="btn btn-primary btn-lg btn-block" type="submit" disabled={!this.isValid()} onClick={this.handleSendCode}>
                                                {loginButtonText} {loginButtonProgress}
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            </Modal.Footer>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default Container.create(MFAPushNotificationModal);
