/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {ControlLabel, FormControl, FormGroup} from 'react-bootstrap';

import {FormItem, FormRow, FormSection} from '../../../../common/form/form';
import config from '../../../../config/config.js';
import {GroupActions, GroupConstants} from '../../../../security/group/group-actions';
import {LanguageCatalogsValidations} from '../../../../security/group/group-store';

class Summary extends React.Component {
    static get propTypes() {
        return {
            disabled: PropTypes.bool,
            mode: PropTypes.string.isRequired,
            languageCatalog: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);
    }

    render() {
        let updatedDate = <div>N/A</div>;
        let updatedBy = <div>N/A</div>;
        let additionalInformation;

        if (this.props.mode === 'edit') {
            updatedBy = this.props.languageCatalog.get('updatedBy');
            if (this.props.languageCatalog.get('updatedDate')) {
                updatedDate = Moment(this.props.languageCatalog.get('updatedDate')).tz(config.DefaultTimezone).format(this.context.intl.messages['datetime-format']);
            } else {
                updatedDate = '-';
            }
            additionalInformation = <FormRow>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['catalogs.last-modified-date']}</ControlLabel>
                    <FormControl.Static>{updatedDate}</FormControl.Static>
                </FormGroup>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['catalogs.last-modifier']}</ControlLabel>
                    <FormControl.Static>{updatedBy}</FormControl.Static>
                </FormGroup>
            </FormRow>;
        }
        return (
            <div>
                <FormSection title={this.context.intl.messages['catalogs.info']} iconClass="fas fa-info-circle">
                    <FormRow>
                        <FormItem
                            attr="name"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['catalogs.name']}
                            max={50}
                            model={this.props.languageCatalog}
                            setter={GroupActions.updateGroup.bind(this, GroupConstants.LANGUAGE)}
                            type="text"
                            validations={LanguageCatalogsValidations.name.validations}
                        />
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['catalogs.status']}</ControlLabel>
                            <FormItem
                                attr="isActive"
                                disabled={this.props.disabled}
                                label="Active"
                                md={5}
                                model={this.props.languageCatalog}
                                setter={GroupActions.updateGroup.bind(this, GroupConstants.LANGUAGE)}
                                type="checkbox"
                            />
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <FormItem
                            attr="description"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['catalogs.description']}
                            max={1000}
                            model={this.props.languageCatalog}
                            setter={GroupActions.updateGroup.bind(this, GroupConstants.LANGUAGE)}
                            type="textarea"
                            validations={LanguageCatalogsValidations.description.validations}
                        />
                    </FormRow>
                    <hr />
                    {additionalInformation}
                </FormSection>
            </div>
        );
    }
}

export default Summary;
