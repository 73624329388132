/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

import HistoryTable from './table';
import SearchBox from '../../common/search-box/search-box';
import {WithStoreOnRoute} from '../../common/store-on-route';
import {Debounce} from '../../common/utils/utils';
import {LayoutActions} from '../../layout/layout-actions';
import LayoutStore from '../../layout/layout-store';
import Preloader from '../../preloader/';
import {HistoryActions} from '../browse-history-actions';
import HistoryStore from '../browse-history-store';
import FilterOptions from '../filter-options/filter-options';
import HistoryDetailModal from '../modal/history-detail-modal';

let Browse = class Browse extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }


    static calculateState() {
        return {
            showFiltersPanel: LayoutStore.getState().get('showFiltersPanel'),
            history: HistoryStore.getState().get('history'),
            showPreloader: HistoryStore.getState().get('showPreloader'),
            historyItem: HistoryStore.getState().get('historyItem'),
            total: HistoryStore.getState().get('total'),
            historyActionItems: HistoryStore.getState().get('historyActionItems'),
            showHistoryInDetailModal: HistoryStore.getState().get('showHistoryInDetailModal')
        };
    }

    static getStores() {
        return [LayoutStore, HistoryStore];
    }

    static get defaultProps() {
        return {
            route: undefined,
            passwordResetAllowed: false,
            unlockUserAllowed: false
        };
    }


    static getPermissions() {
        return {};
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
        this.handleToggleFiltersPanel = this.handleToggleFiltersPanel.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        // initial data to the server.
        HistoryActions.clearFilter();
        this.setRouteState({}).apply();
        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.location.query !== this.props.location.query ||
            nextState.showFiltersPanel !== this.state.showFiltersPanel ||
            nextState.showPreloader !== this.state.showPreloader ||
            nextState.historyItem !== this.state.historyItem ||
            nextState.history !== this.state.history ||
            nextState.total !== this.state.total ||
            nextState.historyActionItems !== this.state.historyActionItems ||
            nextState.showHistoryInDetailModal !== this.state.showHistoryInDetailModal) {
            return true;
        }

        return false;
    }

    componentWillUpdate(nextProps) {
        if (this.props.location !== nextProps.location) {
            HistoryActions.getHistory(this.getRouteState(nextProps));
        }
        return;
    }

    /**
     * Handle clicks on pagination controls.
     * @param  {Number} pageNumber page to load.
     */
    handleLoadPage(pageNumber) {
        this.setRouteState('offset', pageNumber * 20)
            .apply();
        return;
    }

    handleSearchTerm(term) {
        // This is a local search
        this.setRouteState('actor', term)
            .setRouteState('operator', 'AND')
            .clearRouteState('offset')
            .apply();
        return;
    }

    handleToggleFiltersPanel() {
        LayoutActions.toggleFiltersPanel();
        return;
    }

    openModal(historyItemId) {
        HistoryActions.findHistoryObject(historyItemId);
    }

    closeModal() {
        HistoryActions.closeHistoryModalDetail();
    }

    render() {
        return (
            <div className={ClassNames({'control-sidebar-open': this.state.showFiltersPanel})}>
                <div className={ClassNames({'sidebar-opened': this.state.showFiltersPanel})}>
                    <Preloader show={this.state.showPreloader} fixed loadingDots>
                        <section className="content-header">
                            <h1>
                                <i className="fas fa-history"></i>&nbsp;{this.context.intl.messages['history.browse']}
                            </h1>
                        </section>
                        <section className="content">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="container-fluid no-x-padding">
                                        <div className="row">
                                            <div className="col-md-6 show-grid">
                                                <SearchBox value={this.getRouteState().get('actor')} onChange={this.handleSearchTerm}/>
                                            </div>
                                            <div className="col-md-6 show-grid">
                                                <Button onClick={this.handleToggleFiltersPanel} bsStyle="primary" bsSize="large" className="pull-right">
                                                    <i className="fas fa-sliders-h"></i>&nbsp;{this.context.intl.messages['common.filteringOptions']}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container-fluid no-x-padding">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="box">
                                                    <div className="box-body padding-x-20 padding-y-20">
                                                        <HistoryTable
                                                            activePage={Math.ceil((this.getRouteState().get('offset') || 0) / 20 ) || 0}
                                                            history = {this.state.history}
                                                            location = {this.props.location}
                                                            onPageChange={this.handleLoadPage}
                                                            totalPages={Math.ceil(this.state.total/20) || 0}
                                                            historyActionItems = {this.state.historyActionItems}
                                                            onShowHistoryDetailModal = {this.openModal}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <FilterOptions
                            location={this.props.location}
                        />
                    </Preloader>
                    <HistoryDetailModal
                        onClose={this.closeModal}
                        show={this.state.showHistoryInDetailModal}
                        historyItem={this.state.historyItem}
                    />
                </div>
            </div>
        );
    }
};

export {Browse as Browse_BASE};
export default WithStoreOnRoute(Container.create(Browse));
