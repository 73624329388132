/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {v4 as UUID} from 'uuid';

import {MIN_DURATION_OF_CUE} from '../constants';

import pick from '~/src/common/utils/pick';
import SMPTE from '~/src/common/utils/smpte';

const PROPS: Array<keyof Omit<WBTVDCue, 'type'| 'isNew'>> = [
    'align', 'endTime', 'id', 'line', 'lineAlign', 'position', 'positionAlign',
    'size', 'snapToLines', 'text', 'vertical', 'startTime',
];

export function ConvertCues(list: TextTrackCueList): WBTVDCue[] {
    return Array.from(list).map((cue: VTTCue) => {
        const c = pick(cue, PROPS);
        if (!c.id) {
            c.id = cue.id = UUID();
        }
        return {type: 'cue', ...c};
    });
}

export function ConvertCuesToRegionsWithGaps(cues: ReadonlyArray<WBTVDCue>, duration: number): ReadonlyArray<CCEditorRegion> {
    let prevCueEndTime = 0;
    const regions = cues.reduce((memo, cue) => {
        if (cue.startTime > prevCueEndTime + MIN_DURATION_OF_CUE) {
            memo.push(createGap(prevCueEndTime, cue.startTime));
        }
        prevCueEndTime = cue.endTime;
        memo.push(cue);
        return memo;
    }, [] as CCEditorRegion[]);
    if (duration > prevCueEndTime + MIN_DURATION_OF_CUE) {
        regions.push(createGap(prevCueEndTime, duration));
    }
    return regions;
}

export function ConvertToVTT(cues: ReadonlyArray<WBTVDCue>) {
    const smpte = SMPTE.create();
    const content = cues.reduce((sb, cue) => {
        const settings = takeSettingsAsString(cue);
        return sb.append('\r\n')
            .append(smpte.secondsToTime(cue.startTime, true, '.'))
            .append(' --> ')
            .append(smpte.secondsToTime(cue.endTime, true, '.'))
            .append(settings)
            .append('\r\n')
            .append(cue.text)
            .append('\r\n');
    }, new StringBuffer('WEBVTT\r\n'));

    const blob = new Blob([content.toString()], {type: 'text/plain;charset=utf-8'});
    const uniq = UUID().split('-').pop();
    return new File([blob], `cc-editor-${uniq}.vtt`);
}

function createGap(startTime: number, endTime: number): CCEditorRegion$Gap {
    return {type: 'gap', id: UUID(), startTime, endTime};
}

function takeSettingsAsString(cue: WBTVDCue) {
    const sb = new StringBuffer();
    if (cue.line && cue.line !== 'auto') {
        sb.append(`line:${cue.line}%`);
    }

    if (cue.position && cue.position !== 'auto') {
        sb.append(`position:${cue.position}%`);
    }

    if (cue.align && cue.align !== 'center') {
        sb.append(`align:${cue.align}`);
    }

    if (cue.size) {
        sb.append(`size:${cue.size}%`);
    }

    if (cue.vertical) {
        sb.append(`vertical:${cue.vertical}`);
    }

    if (cue.region?.id) {
        sb.append(`vertical:${cue.region.id}`);
    }

    return sb.toString(' ');
}

class StringBuffer {
    private strings: string[] = [];

    constructor(str = '') {
        this.append(str);
    }

    public append(str: string) {
        this.strings.push(str);
        return this;
    }

    public toString(delimiter = '') {
        return this.strings.join(delimiter);
    }
}
