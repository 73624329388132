/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Sanitize} from '@wbdt-sie/brainiac-web-common';
import Immutable from 'immutable';
import jQuery from 'jquery';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, Col, Row} from 'react-bootstrap';

import SearchBox from '../../common/search-box/search-box';
import config from '../../config/config.js';
import {UserConstants} from '../../user/user-actions';
import UserStore from '../../user/user-store';
import Pagination from '../table/pagination';

require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('../../styles/data-tables-brainiac.css');
// Load jQuery and register the datatables plugin.
require('datatables.net-responsive-bs');

export const createHeaders = function(headerName, showName) {
    return function(component) {
        return <th onClick={component.handleHeaderClick.bind(component, headerName)} className={component.getSortFieldNameClass(headerName)} key={headerName}>{showName}</th>;
    };
};

const ANONYMOUS_ACCOUNT_SOURCE_TYPES = {
    Generated: 'Generated',
    Imported: 'Imported',
    JustInTime: 'Just In Time'
};

/**
 * Column definitions.
 */
/* istanbul ignore next */
const COLUMNS = {
    actions: {
        header: function() {
            return <th className="actions" key="actions">Actions</th>;
        }
    },
    accountSourceType: {
        get: function(user) {
            let accountSourceType = user.getIn(['anonymousAccount', 'accountSourceType']);
            return ANONYMOUS_ACCOUNT_SOURCE_TYPES[accountSourceType];
        },
        header: createHeaders('account_source_type', 'Source')
    },
    applicationDate: {
        get: function(user, props, context) {
            return Moment(user.get('applicationDate')).tz(config.DefaultTimezone).format(context.intl.messages['date-format']);
        },
        header: createHeaders('application_date', 'Apply Date')
    },
    buttonSelect: {
        get: function(user) {
            const userId = user.get('id');
            let toggleUserButton = `<button class="btn btn-small btn-primary-outline toggle-user-button" user-id="${userId}"><i class="fas fa-plus toggle-user-button" user-id="${userId}"></i>&nbsp;Select</button>`;

            if (user.get('__selected')) {
                toggleUserButton = `<button class="btn btn-small btn-danger-outline toggle-user-button" user-id="${userId}"><i class="fas fa-times toggle-user-button" user-id="${userId}"></i>&nbsp;Deselect</button>`;
            }

            return toggleUserButton;
        },
        header: createHeaders('button_select', 'Select')
    },
    clientRepGroup: {
        get: function(user) {
            let clientRepGroupName = '-';
            if (user.get('clientRepGroups').get(0)) {
                clientRepGroupName = user.get('clientRepGroups').map(c => c.get('name')).join(', ');
            }

            return clientRepGroupName;
        },
        header: function() {
            return <th key="client-rep-group">Rep Group</th>;
        }
    },
    createdDate: {
        get: function(user, props, context) {
            return Moment(user.getIn(['anonymousAccount', 'createdDate'])).tz(config.DefaultTimezone).format(context.intl.messages['datetime-hybrid-format']);
        },
        header: createHeaders('created_date', 'Created Date')
    },
    company: {
        get: function(user) {
            return Sanitize(user.get('company'));
        },
        header: createHeaders('company', 'Company')
    },
    country: {
        get: function(user) {
            return user.get('country');
        },
        header: createHeaders('country', 'Country')
    },
    device: {
        get: function(user) {
            return user.get('deviceType');
        },
        header: createHeaders('device', 'Device')
    },
    email: {
        get: function(user) {
            return Sanitize(user.get('email'));
        },
        header: createHeaders('email', 'Email')
    },
    emailAddress: {
        get: function(user) {
            return Sanitize(user.get('emailAddress'));
        },
        header: createHeaders('email', 'Email')
    },
    expirationDate: {
        get: function(user, props, context) {
            let expiration = Moment(user.get('expirationDate', null)).tz(config.DefaultTimezone);
            if (expiration.isValid()) {
                return expiration.format(context.intl.messages['date-format']);
            }

            return '-';
        },
        header: createHeaders('expirationDate', 'Expire Date')
    },
    flag: {
        get: function(user, props, context) {
            const alerts = user.get('accountAlerts', Immutable.List()).toJS();
            let alertFlag;
            if (alerts.length === 1) {
                alertFlag = UserConstants.ACCOUNT_ALERTS[alerts[0]];
            } else if (alerts.length > 1) {
                alertFlag = UserConstants.ACCOUNT_ALERTS.MULTIPLE_ISSUES;
            }

            if (alertFlag) {
                return `<div class="parcel-tooltip">
                    <i class="${alertFlag.icon}" title="${context.intl.messages[alertFlag.tip]}"></i>
                    <span class="tooltip-inner">${context.intl.messages[alertFlag.tip]}</span>
                </div>`;
            }

            return '';
        },

        header: createHeaders('accountAlerts', <i className="fas fa-flag"></i>)
    },
    loginToken: {
        get: function(user, props) {
            let loginToken = user.getIn(['anonymousAccount', 'id']);
            if (props.displayLinks) {
                loginToken = `<a href="/accounts/${user.get('id')}" class="edit-account">${loginToken}</a>`;
            }

            return loginToken;
        },
        header: createHeaders('login_token', 'Login Token')
    },
    name: {
        get: function(user, props) {
            let userName = Sanitize(user.get('fullName', ''));
            let userPath = 'accounts';

            if (props.applicants) {
                userPath = 'accounts/applicants';
            }
            if (props.displayLinks) {
                let params = '';
                if (props.location?.search) {
                    params = props.location.search;
                }
                userName = `<a href="/${userPath}/${user.get('id')}${params}" class="edit-account">${userName}</a>`;
            }

            return userName;
        },
        header: createHeaders('name', 'Name')
    },
    platform: {
        get: function(user) {
            return user.get('platformString');
        },
        header: createHeaders('platform', 'Platform')
    },
    status: {
        get: function(user, props, context) {
            // Status Value as well as tooltip
            let status = UserStore.getStatus(user);
            let tip = context.intl.messages[status.tip];
            if (!status.tip) {
                tip = '';
            }
            return `<span class="label ${status.label} parcel-tooltip" title="${tip}"><i class="${status.icon}"></i>&nbsp;<span class="hidden-xs hidden-sm">${status.description}</span><span class="tooltip-inner">${tip}</span></span>`;
        },
        header: function() {
            return <th key="status">Status</th>;
        }
    },
    //TODO add functionality when API is ready
    userGroup: {
        get: function() {
            return '';
        },
        header: function() {
            return <th className="no-sort" key="flag"><i className="fas fa-users"></i></th>;
        }
    },
};


class ListUsers extends Component {

    static get propTypes() {
        return {
            activePage: PropTypes.number,
            applicants: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
            children: PropTypes.object,
            columns: PropTypes.array,
            displayLinks: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
            location: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
            onButtonSelect: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
            onExportUsers: PropTypes.func,
            onLoadPage: PropTypes.func,
            onPageChange: PropTypes.func,
            onSearchChange: PropTypes.func,
            onSelect: PropTypes.func,
            onSelectAll: PropTypes.func,
            onSortChange: PropTypes.func,
            searchTerm: PropTypes.string,
            selectAll: PropTypes.bool,
            sortDirection: PropTypes.string,
            sortFieldName: PropTypes.string,
            tableId: PropTypes.string,
            totalPages: PropTypes.number,
            users: PropTypes.instanceOf(Immutable.List) // eslint-disable-line react/no-unused-prop-types
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object,
            router: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            activePage: 0,
            applicants: false,
            children:undefined,
            columns: [
                'flag', 'name', 'company', 'email', 'status', 'applicationDate'
            ],
            displayLinks: false,
            location: undefined,
            onButtonSelect: undefined,
            onExportUsers: undefined,
            onLoadPage: undefined,
            onPageChange: undefined,
            onSearchChange: undefined,
            onSelect: undefined,
            onSortChange: undefined,
            onSelectAll: undefined,
            searchTerm:'',
            selectAll: false,
            sortDirection:'',
            sortFieldName:'',
            tableId: 'list-users-table',
            totalPages: 0,
            users: undefined
        };
    }

    constructor(props) {
        super(props);

        this.drawUsers = this.drawUsers.bind(this);
        this.getSortFieldNameClass = this.getSortFieldNameClass.bind(this);
        this.handleHeaderClick = this.handleHeaderClick.bind(this);
        this.handleActionsCellClick = this.handleActionsCellClick.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.handleSelectAllClick = this.handleSelectAllClick.bind(this);

        return;
    }

    componentDidMount() {
        if (this.props.onLoadPage) {
            this.props.onLoadPage();
        }
        this.$table = jQuery(`#${this.props.tableId}`);
        this.$tableAPI = this.$table.DataTable({
            autoWidth: false,
            columnDefs: [{
                // Add the control class to the last column. This colum will
                // contain the button to show the responsive data.
                className: 'control',
                targets:   -1,
                width: 20
            }, {
                className: 'actions',
                targets: 'actions'
            }, {
                targets: 'no-sort',
                orderable: false
            }],
            iDisplayLength: 1,
            info: false,
            ordering: false,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        });

        this.$table.on('click', 'td.actions', this.handleActionsCellClick);

        // Register global listeners.
        window.addEventListener('resize', this.handleResize);
        // And trigger the resize handler once so that the datatable
        // knows its initial dimensions.
        this.drawUsers(this.props);
        return;
    }

    componentWillUpdate(nextProps) {
        this.drawUsers(nextProps);
        return;
    }

    componentWillUnmount() {
        if (this.$table) {
            this.$table.off('click', 'td.actions');
        }

        if (this.$tableAPI) {
            this.$tableAPI.destroy();
        }

        return;
    }

    drawUsers(props) {
        this.$tableAPI.clear();

        // Add data to the jQuery datatable.
        props.users.forEach((user, index) => {
            let row = this.props.columns.map(c => {
                let column = COLUMNS[c];
                if (c.name) {
                    column = COLUMNS[c.name];
                }
                // Override the getter if it was provided. This way
                // the module that includes the table can define custom
                // functions to read the values.
                if (c.get) {
                    column.get = c.get;
                }
                return column.get(user, props, this.context);
            });

            // If requested, show the select in the first column.
            if (props.onSelectAll && props.onSelect) {
                let checkedStatus = '';
                if (user.get('__selected')) {
                    checkedStatus = 'checked';
                }
                row.unshift(`<input type="checkbox" class="select-row" data-index="${index}" ${checkedStatus}/>`);
            }

            // Add a last empty column for the datatable-responsive plugin.
            row.push('');

            this.$tableAPI.row.add(row);
            return;
        });

        this.$tableAPI.draw(false);

        // Now, since the data has changed the columns widths, trigger
        // the resize handler in order to update the responsive feature.
        this.handleResize();

    }

    getSortFieldNameClass(headerName) {

        let r = 'sorting';

        if (headerName === this.props.sortFieldName) {
            r = `sorting_${this.props.sortDirection}`;
        }

        return r;
    }

    handleHeaderClick(headerName) {
        let newSortDirection = 'asc';
        if (this.props.sortFieldName === headerName && this.props.sortDirection === 'asc') {
            newSortDirection = 'desc';
        }

        if (this.props.onSortChange) {
            this.props.onSortChange(headerName, newSortDirection);
        }
        return;
    }


    /**
     * Send all click events over the "actions" cell
     * to the handler defined by the parent component.
     */
    handleActionsCellClick(event) {
        this.props.columns.filter(c => {
            return c.name === 'actions';
        })[0].onClick(event);
        return;
    }

    /**
     * Update the datatable columns size.
     */
    handleResize() {
        this.$tableAPI.responsive.recalc();
        this.$table.on('click', 'td.child', this.handleActionsCellClick);
        return;
    }

    /**
     * This function is kind of "special" because it needs to handle
     * events bubbled from the data table rows, these rows cannot use
     * the JSX syntax because they are created by the data table
     * jQuery plugin instead of React.
     */
    handleRowClick(event) {
        switch (true) {
        // Handle click on a select input.
        case !!~event.target.className.indexOf('select-row'):
            let index = parseInt(event.target.getAttribute('data-index'), 10);
            this.props.onSelect(index, event.target.checked);
            break;
        // Handle click on select button
        case !!~event.target.className.indexOf('toggle-user-button'):
            event.preventDefault();
            const id = event.target.getAttribute('user-id');
            this.props.onButtonSelect(id);
            break;
        // Handle click on an user's name.
        case !!~event.target.className.indexOf('edit-account'):
            // Prevent the default anchor click event.
            event.preventDefault();
            this.context.router.push(event.target.getAttribute('href'));
            break;
        }

        return;
    }

    handleSelectAllClick(event) {
        this.props.onSelectAll(event.target.checked);
        return;
    }

    render() {
        let showSelect = !!(this.props.onSelectAll && this.props.onSelect);

        let exportUsers;
        if (this.props.onExportUsers) {
            exportUsers = <div className="bulk-actions">
                <Button
                    className="btn btn-default pull-right"
                    disabled={!this.props.users.size}
                    onClick={this.props.onExportUsers}
                >
                    <i className="fas fa-download"></i> {this.context.intl.messages['common.export-users']}
                </Button>
            </div>;
        }

        let search;
        if (this.props.onSearchChange) {
            search = <Row>
                <Col md={6} mdOffset={3}>
                    <SearchBox onChange={this.props.onSearchChange} value={this.props.searchTerm}/>
                </Col>
                <Col md={3}>
                    {exportUsers}
                </Col>
            </Row>;
        }

        let pagination;
        if (this.props.onPageChange) {
            pagination = <Row>
                <Col md={12} className="text-center">
                    <Pagination
                        activePage={this.props.activePage}
                        onChange={this.props.onPageChange}
                        totalPages={this.props.totalPages}
                    />
                </Col>
            </Row>;
        }

        return (
            <div>
                {search}
                {this.props.children}
                <table id={this.props.tableId} className="-striped table table-bordered table-striped responsive">
                    <thead>
                        <tr>
                            {showSelect && <th className="no-sort"><input type="checkbox" onChange={this.handleSelectAllClick} checked={this.props.selectAll}/></th>}
                            {this.props.columns.map(c => {
                                let column = COLUMNS[c];
                                if (c.name) {
                                    column = COLUMNS[c.name];
                                }
                                return column.header(this);
                            })}
                            <th className="no-sort"></th>
                        </tr>
                    </thead>
                    <tbody onClick={this.handleRowClick}>
                    </tbody>
                </table>
                {pagination}
            </div>
        );
    }

}

export default ListUsers;
