/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';

import {GroupActions, GroupConstants} from './group-actions';
import GroupStore from './group-store';

import {Debounce, GetAttr} from '~/src/common/utils/utils';

export const isBrainiacGroup = (props) => props.type.categoryId === GroupConstants.USER.categoryId && props.subtype === 'brainiacGroups';

/**
 * Add groups section.
 */
class GroupSelect extends React.Component {
    static get propTypes() {
        return {
            disabled: PropTypes.bool,
            filterOption: PropTypes.func,
            name: PropTypes.string,
            onChange: PropTypes.func.isRequired,
            selected: PropTypes.oneOfType([
                PropTypes.instanceOf(Immutable.OrderedSet),
                PropTypes.instanceOf(Immutable.List)
            ]).isRequired,
            showInactive: PropTypes.bool,
            type: PropTypes.object.isRequired,
            subtype: PropTypes.object,
        };
    }

    static get defaultProps() {
        return {
            disabled: true,
            filterOption: /*istanbul ignore next*/(option) => option,
            name: 'groups',
            showInactive: false,
            subtype: null
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
        };
    }

    static calculateState(prevState, props) {
        return {
            groups: GroupStore.getState().get(props.type.name).get('groups'),
            isLoading: GroupStore.getState().get('isLoading')
        };
    }

    static getStores() {
        return [GroupStore];
    }

    constructor(props) {
        super(props);

        this.handleInputChange = Debounce(this.handleInputChange.bind(this), 200);
    }

    componentDidMount() {
        // Fetch some groups from the server.
        let query = {
            active: !this.props.showInactive
        };

        if (isBrainiacGroup(this.props)) {
            query.admin = true;
        }

        GroupActions.get(this.props.type, false, query);

    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.selected !== this.props.selected ||
            nextProps.showInactive !== this.props.showInactive ||
            nextProps.subtype !== this.props.subtype ||
            nextProps.type !== this.props.type) {
            return true;
        }

        return nextState.groups !== this.state.groups ||
            nextState.isLoading !== this.state.isLoading;
    }

    componentWillUpdate(nextProps) {
        if (nextProps.showInactive !== this.props.showInactive) {
            let query = {
                active: !nextProps.showInactive
            };

            if (isBrainiacGroup(nextProps)) {
                query.admin = true;
            }

            GroupActions.get(this.props.type, false, {
                filters: GroupStore.getState().get(this.props.type.name).get('filters'),
                ...query
            });
        }
    }

    handleInputChange(value) {
        if (value) {
            GroupActions.setFilter(this.props.type, 'name', value);
        } else {
            GroupActions.setFilter(this.props.type, 'name', undefined);
        }

        let query = {
            active: !this.props.showInactive
        };

        if (isBrainiacGroup(this.props)) {
            query.admin = true;
        }
        GroupActions.get(this.props.type, false, {
            filters: GroupStore.getState().get(this.props.type.name).get('filters'),
            ...query
        });


    }

    render() {
        let placeholder;
        switch (this.props.type.name) {
        case GroupConstants.ASSET.name:
            placeholder = this.context.intl.messages['common.select-catalogs'];
            break;
        default:
            placeholder = this.context.intl.messages['common.select-group'];
            break;
        }

        return (
            <Select
                filterOption={this.props.filterOption}
                getOptionLabel={GetAttr('name')}
                getOptionValue={GetAttr('id')}
                isClearable={true}
                isMulti={true}
                isDisabled={this.props.disabled}
                isLoading={this.state.isLoading}
                name={this.props.name}
                onChange={this.props.onChange}
                onInputChange={this.handleInputChange}
                options={this.state.groups.toJS()}
                placeholder={placeholder}
                value={this.props.selected?.toJS()}
            />
        );
    }
}

export default Container.create(GroupSelect, {withProps:true});
export {GroupSelect as GroupSelect_BASE};
