/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, Modal, ControlLabel, FormGroup} from 'react-bootstrap';
import ReactSelect from 'react-select';

import {FormItem, FormRow} from '../../../common/form/form';
import {Debounce} from '../../../common/utils/utils';
import {PartnerActions} from '../../../partner/partner-actions';
import {UserActions} from '../../../user/user-actions';
import UserStore from '../../../user/user-store';

class AddApiKey extends Component {

    static get propTypes() {
        return {
            onHide: PropTypes.func,
            partner: PropTypes.instanceOf(Immutable.Map),
            showModal: PropTypes.bool,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            users: UserStore.getState().get('users')
        };
    }

    static getStores() {
        return [UserStore];
    }

    static get defaultProps() {
        return {
            onHide: () => void 0,
            partner: Immutable.fromJS({}),
            showModal: false
        };
    }

    constructor(props) {
        super(props);

        this.searchUsers = Debounce(this.searchUsers.bind(this), 200);
        this.onAdd = this.onAdd.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    componentDidMount() {
        UserActions.get(Immutable.Map());
    }

    handleUserChange(value) {
        PartnerActions.updatePartner('newApiKey.user', value ? Immutable.fromJS(value) : undefined);
    }

    searchUsers(term) {
        UserActions.get(Immutable.fromJS({q:term}));
        return;
    }

    onAdd() {
        PartnerActions.addPartnerApiKey(this.props.partner.get('newApiKey'));
        PartnerActions.clearPartnerApiKey();
        this.props.onHide();
    }

    onClose() {
        PartnerActions.clearPartnerApiKey();
        this.props.onHide();
    }

    render() {
        let value = this.props.partner.getIn(['newApiKey', 'user'], undefined);
        return (
            <Modal show={this.props.showModal} onHide={this.onClose}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="text-center">{this.context.intl.messages['authorized-partners.api-keys.modal.title']}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormRow>
                        <FormItem attr="newApiKey.key"
                            label={this.context.intl.messages['authorized-partners.api-keys.modal.api-key']}
                            model={this.props.partner}
                            placeholder={this.context.intl.messages['authorized-partners.api-keys.modal.api-key.placeholder']}
                            setter={PartnerActions.updatePartner}
                            required
                            type="text"
                        />
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{(<strong>{this.context.intl.messages['authorized-partners.api-keys.modal.user']}</strong>)}</ControlLabel>
                            <ReactSelect
                                getOptionLabel={u => `${u.get('fullName')} (${u.get('email')})`}
                                getOptionValue={u => u.get('id')}
                                onChange={this.handleUserChange}
                                onInputChange={this.searchUsers}
                                options={this.state.users}
                                placeholder={this.context.intl.messages['authorized-partners.api-keys.modal.user.placeholder']}
                                value={value}
                                valueKey="id"
                            />
                        </FormGroup>
                    </FormRow>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        bsStyle="default"
                        onClick={this.onClose}>
                        <i className="fas fa-times-circle"/>&nbsp;{this.context.intl.messages['common.cancel']}
                    </Button>
                    <Button
                        disabled={!this.props.partner.getIn(['newApiKey', 'user']) || !this.props.partner.getIn(['newApiKey', 'key']) }
                        bsStyle="success"
                        onClick={this.onAdd}>
                        <i className="fas fa-plus"/>&nbsp;{this.context.intl.messages['authorized-partners.api-keys.modal.add']}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default Container.create(AddApiKey);
