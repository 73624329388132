/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {AssetActions} from '../../../../assets/asset-actions';
import AssetStore from '../../../../assets/asset-store';
import ListAssets from '../../../../assets/browse/list-assets';
import {SlidingPanelActions} from '../../../../common/sliding-panel/sliding-panel-actions';
import {Debounce} from '../../../../common/utils/utils';
import {PublishingListActions} from '../../../publishing-list-actions';
import {TargetTypeActions} from '../target-type-actions';

class ItemAsset extends Component {
    static get propTypes() {
        return {
            slidingPanelId: PropTypes.string.isRequired,
            targetTypeId: PropTypes.number.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        const as = AssetStore.getState();
        return {
            assets: as.get('assets'),
            filters: as.get('filters'),
            offset: as.get('offset'),
            size: as.get('size'),
            sortFieldName: as.get('sortFieldName'),
            sortDirection: as.get('sortDirection'),
            total: as.get('total'),
        };
    }

    static getStores() {
        return [AssetStore];
    }

    constructor(props) {
        super(props);

        this.handleAddAsset = this.handleAddAsset.bind(this);
        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
        this.handleSortChange = this.handleSortChange.bind(this);
    }

    componentDidMount() {
        AssetActions.clear();
        AssetActions.get(AssetStore.getState().get('filters'), 0, 20,
            AssetStore.getState().get('sortFieldName'),
            AssetStore.getState().get('sortDirection'));
        return;
    }

    shouldComponentUpdate(nextProps, nextState /*, nextContext*/) {
        return nextState.assets !== this.state.assets;
    }

    getAsset(assetId) {
        let assets = AssetStore.getState().get('assets');
        let asset;
        assets.map(a => {
            if (a.toJS().id.toString() === assetId) {
                asset = a;
            }
        });
        return asset;
    }

    handleAddAsset(event) {
        if (~event.target.className.indexOf('add-asset-panel')||
            ~event.target.className.indexOf('fa-plus')) {
            let assetId = event.target.getAttribute('data-asset-id');
            let assetType = event.target.getAttribute('data-asset-type');
            if (~event.target.className.indexOf('fa-plus')) {
                assetId = event.target.parentNode.getAttribute('data-asset-id');
                assetType = event.target.parentNode.getAttribute('data-asset-type');
            }
            PublishingListActions.updateListItemAttr('asset', this.getAsset(assetId));
            PublishingListActions.updateListItemAttr('displayAssetTypeName', assetType);
            PublishingListActions.updateListItemAttr('assetId', this.getAsset(assetId).get('id'));
            PublishingListActions.updateListItemAttr('targetType', this.props.targetTypeId);
            SlidingPanelActions.hide(this.props.slidingPanelId);
            PublishingListActions.show();
            TargetTypeActions.showItem(this.props.targetTypeId, assetId, assetType);
        }

        return;
    }

    handleLoadPage(pageNumber) {
        // pageNumber x this.state.size = offset.
        AssetActions.get(AssetStore.getState().get('filters'), pageNumber * this.state.size, this.state.size,
            AssetStore.getState().get('sortFieldName'),
            AssetStore.getState().get('sortDirection')
        );
        return;
    }

    handleSearchTerm(term) {
        AssetActions.setFilter('asset-name', term);
        AssetActions.setFilter('operator', 'AND');
        AssetActions.get(AssetStore.getState().get('filters'), 0, 20, AssetStore.getState().get('sortFieldName'), AssetStore.getState().get('sortDirection'));
        return;
    }

    handleSortChange(sortFieldName, sortDirection) {
        AssetActions.setSort(sortFieldName, sortDirection);
        AssetActions.get(AssetStore.getState().get('filters'), 0, this.state.size, AssetStore.getState().get('sortFieldName'), AssetStore.getState().get('sortDirection'));
        return;
    }

    render() {
        let state = AssetStore.getState();
        let assets = state.get('assets');
        let totalPages = Math.ceil(state.get('total') / state.get('size'));
        let offset = state.get('offset');
        let size = state.get('size');
        let columns = ['assetThumbnail', 'assetTypeName', 'assetName', 'createdDate',
            {
                name: 'actions',
                get: function(asset, props, context) {
                    return `<button
                        class="btn btn-primary add-asset-panel"
                        data-asset-id="${asset.get('id')}"
                        data-asset-type="${asset.get('displayAssetTypeName')}"><i class="fas fa-plus"></i>&nbsp;${context.intl.messages['publishing-list.list-item.item.asset.add']}</button>`;
                },
                onClick: this.handleAddAsset
            }
        ];
        return (
            <div>
                <h3>
                    <i className="far fa-file-image"></i>{this.context.intl.messages['publishing-list.add-item.title.media-assets']}
                    <small>{this.context.intl.messages['publishing-list.add-item.title.desire-asset']}</small>
                </h3>
                <ListAssets
                    assets={assets}
                    activePage={Math.ceil(offset/size) || 0}
                    columns={columns}
                    displayAudioLinks={true}
                    displayDocumentLinks={true}
                    displayImageLinks={true}
                    displayMerchandiseLinks={true}
                    displayVideoLinks={true}
                    linkTarget="_blank"
                    onSearchChange={this.handleSearchTerm}
                    onPageChange={this.handleLoadPage}
                    onSortChange={this.handleSortChange}
                    showFilterOptions={true}
                    sortDirection={this.state.sortDirection}
                    sortFieldName={this.state.sortFieldName}
                    totalPages={totalPages}
                />
            </div>
        );
    }
}

export default Container.create(ItemAsset);
