/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ControlLabel, FormGroup} from 'react-bootstrap';
import Select from 'react-select';

import {AccountWizardActions} from './account-wizard-actions';
import AccountWizardStore from './account-wizard-store';

import {FormItem, FormRow, FormSection} from '~/src/common/form/form';
import {WithValidations} from '~/src/common/validations/validations';
import Partners from '~/src/partner/partners';
import PreloaderStore from '~/src/preloader/preloader-store';
import {GroupConstants} from '~/src/security/group/group-actions';
import GroupSelect from '~/src/security/group/group-select';
import {PermissionPackageActions, PermissionPackageConstants} from '~/src/security/permission-package/permission-package-actions';
import PermissionPackageStore from '~/src/security/permission-package/permission-package-store';
import RoleSelect from '~/src/security/role/role-select';

class PermissionsTab extends Component {

    static get propTypes() {
        return {
            readOnly: PropTypes.bool.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            defaultPartner: AccountWizardStore.getState().get('defaultPartner'),
            groups: AccountWizardStore.getState().get('groups'),
            partners: AccountWizardStore.getState().get('partners'),
            roles: AccountWizardStore.getState().get('roles'),
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible'),
            permissionPackages: PermissionPackageStore.getState().get('permissionPackages')
        };
    }

    static getStores() {
        return [AccountWizardStore, PreloaderStore, PermissionPackageStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            selectedPermissionPackage: undefined,
        }, this.constructor.calculateState());

        this.handleRequestAdditionalSecurity = this.handleRequestAdditionalSecurity.bind(this);
        this.handleSelectGroup = this.handleSelectGroup.bind(this);
        this.handleSelectPartners = this.handleSelectPartners.bind(this);
        this.handleSelectPermissionPackage = this.handleSelectPermissionPackage.bind(this);
        this.handleSelectPrimaryPartner = this.handleSelectPrimaryPartner.bind(this);
        this.handleSelectRole = this.handleSelectRole.bind(this);
    }

    componentDidMount() {
        PermissionPackageActions.get(Immutable.fromJS({active: PermissionPackageConstants.FILTERS.ACTIVE_OPTIONS.ACTIVE.id}), 0, 999999);
        return;
    }

    handleRequestAdditionalSecurity(attr, value) {
        if (!value) {
            AccountWizardActions.setAdditionalSecurity(null);
            return;
        }

        AccountWizardActions.setAdditionalSecurity(value);
    }

    handleSelectGroup(groups) {
        if (!groups) {
            AccountWizardActions.setGroups(Immutable.OrderedSet());
            return;
        }

        AccountWizardActions.setGroups(Immutable.fromJS(groups));
        return;
    }

    handleSelectPartners(partners) {
        if (!partners) {
            AccountWizardActions.setPartners(Immutable.OrderedSet());
            return;
        }

        partners = partners.map(p => {
            // partnerId must be added prior to first save to db
            if (!p.partnerId) {
                p.partnerId = p.id;
            }
            return p;
        });

        AccountWizardActions.setPartners(Immutable.fromJS(partners));
    }

    handleSelectPrimaryPartner(defaultPartner) {
        if (!defaultPartner) {
            AccountWizardActions.setDefaultPartner({});
            return;
        }
        AccountWizardActions.setDefaultPartner(defaultPartner);
    }

    handleSelectRole(roles) {
        if (!roles) {
            AccountWizardActions.setRoles(Immutable.OrderedSet());
            return;
        }

        AccountWizardActions.setRoles(Immutable.fromJS(roles));
        return;
    }

    handleSelectPermissionPackage(permissionPackage) {
        this.setState({
            selectedPermissionPackage: permissionPackage
        });

        if (permissionPackage) {
            // Trigger the select action with the desired immutable object.
            AccountWizardActions.selectPermissionPackage(this.state.permissionPackages.find((elem) => {
                return elem.get('id') === permissionPackage.id;
            }));
        }
        return;
    }

    render() {
        const defaultPartner = this.state.defaultPartner;
        const selectedPartners = this.state.partners;
        const selectedGroups = this.state.groups;
        const selectedRoles = this.state.roles;

        return (
            <div>
                <FormSection title={this.context.intl.messages['accounts.account-wizard.presets']} iconClass="fas fa-list-ul">
                    <FormRow>
                        <FormGroup md={4}>
                            <ControlLabel>{this.context.intl.messages['accounts.account-wizard.permissions-package']}</ControlLabel>
                            <Select
                                getOptionLabel={data => data.name}
                                getOptionValue={data => data.id}
                                isClearable
                                name="permissions-package"
                                onChange={this.handleSelectPermissionPackage}
                                options={this.state.permissionPackages.toJS()}
                                placeholder={this.context.intl.messages['accounts.account-wizard.permissions-package-placeholder']}
                                value={this.state.selectedPermissionPackage}
                            />
                        </FormGroup>
                        <FormItem type="textarea"
                            attr="requestAdditionalSecurity"
                            label={this.context.intl.messages['accounts.account-wizard.request-custom-permissions']}
                            model={AccountWizardStore.getState()}
                            setter={this.handleRequestAdditionalSecurity}
                        />
                    </FormRow>
                </FormSection>
                <div>
                    <hr/>
                    <Partners
                        defaultPartner={defaultPartner}
                        disabled={this.props.readOnly}
                        onSelectPrimaryPartner={this.handleSelectPrimaryPartner}
                        onSelectPartners={this.handleSelectPartners}
                        partners={selectedPartners}
                        required={true}
                    />
                </div>
                <FormSection title={this.context.intl.messages['accounts.account-wizard.groups.title']} iconClass="fa-regular fa-users">
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['accounts.account-wizard.groups.create.text']}</ControlLabel>
                            <GroupSelect
                                disabled={this.props.readOnly}
                                onChange={this.handleSelectGroup}
                                selected={selectedGroups}
                                type={GroupConstants.USER}
                            />
                        </FormGroup>
                    </FormRow>
                </FormSection>
                <hr/>
                <FormSection title={this.context.intl.messages['accounts.account-wizard.roles.title']} iconClass="fa-regular fa-male">
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['accounts.account-wizard.roles.create.text']}</ControlLabel>
                            <RoleSelect
                                disabled={this.props.readOnly}
                                onChange={this.handleSelectRole}
                                selected={selectedRoles}
                            />
                        </FormGroup>
                    </FormRow>
                </FormSection>
            </div>
        );
    }
}

export default WithValidations(Container.create(PermissionsTab));
