/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';
import {browserHistory} from 'react-router';

import {CurrentVttFile$, HideShiftCaptionsModal, IsShiftCaptionsModalVisible$, SetShiftingTime, ShiftingTime$} from '../../bl';
import CCEditorActions from '../../cc-editor-actions';
import {GenerateRootRoute} from '../../helpers/route';
import TimecodeField from '../common/timecode-field';

import WithRxSubscriptions from '~/src/decorators/with-rx-subscriptions';

type Props = {
    show: boolean,
    time: number,
    vtt: ImmutableMap<VideoWebVtt> | undefined,
}

class ShiftCaptionsModal extends React.PureComponent<Props> {
    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    handleShiftingEvent = () => {
        CCEditorActions.shiftCues(ShiftingTime$.value);
        HideShiftCaptionsModal();
        if (this.props.vtt) {
            browserHistory.push(GenerateRootRoute(this.props.vtt.toJS()));
        }
    };

    render() {
        return (
            <Modal show={this.props.show} onHide={HideShiftCaptionsModal} className="skin-dark">
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="text-center">
                        <FormattedMessage id="cc-editor.shift-caption-modal.title" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <h4>
                        <i className="fas fa-exclamation-triangle"/>
                        &nbsp;
                        <FormattedMessage id="cc-editor.shift-caption-modal.header" />
                    </h4>
                    <p>
                        <em>
                            <FormattedMessage id="cc-editor.shift-caption-modal.description" />
                        </em>
                    </p>
                    <div className="form-section">
                        <div className="row">
                            <div className="col-md-12 form-group">
                                <TimecodeField
                                    label={this.context.intl.messages['cc-editor.shift-caption-modal.start']}
                                    time={this.props.time}
                                    onInput={SetShiftingTime}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="btn btn-success-outline pull-right"
                        data-testid="confirm-btn"
                        onClick={this.handleShiftingEvent}
                    >
                        <i className="fas fa-hourglass-start"/>
                        &nbsp;
                        <FormattedMessage id="cc-editor.shift-caption-modal.title" />
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default WithRxSubscriptions(ShiftCaptionsModal, {
    show: IsShiftCaptionsModalVisible$,
    time: ShiftingTime$,
    vtt: CurrentVttFile$,
});
