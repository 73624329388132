/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {WhitelistConstants} from './whitelist-actions';
import Dispatcher from '../../dispatcher/dispatcher';

class WhitelistStore extends ReduceStore {

    getInitialState() {
        return Immutable.List();
    }

    reduce(state, action) {
        switch (action.actionType) {
        case WhitelistConstants.GET:
            state = this.getInitialState();
            break;
        case WhitelistConstants.LOAD:
            state = Immutable.List(action.data);
            break;
        case WhitelistConstants.REMOVE:
            break;
        }
        return state;
    }
}

let ws = new WhitelistStore(Dispatcher);

export default ws;
