/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import AddExistingAssets from '../../../assets-tab/add-existing-assets';
import {AssetTabActions} from '../../../assets-tab/asset-tab-actions';
import AssetTabStore from '../../../assets-tab/asset-tab-store';
import SlidingPanel from '../../../common/sliding-panel/sliding-panel';
import {SlidingPanelActions} from '../../../common/sliding-panel/sliding-panel-actions';

import {GroupActions} from '~/src/security/group/group-actions';

class AddExistingPanel extends Component {
    static get propTypes() {
        return {
            catalog: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.handleCancelPanel = this.handleCancelPanel.bind(this);
        this.handleClickAddSelectedItems = this.handleClickAddSelectedItems.bind(this);
    }


    handleCancelPanel() {
        AssetTabActions.clearSelectedAssetsToAdd();
    }

    handleClickAddSelectedItems() {
        GroupActions.addAssetsToCatalog(AssetTabStore.getState().get('selectedAssetsToAdd').toJS());
        AssetTabActions.clearSelectedAssetsToAdd();
        SlidingPanelActions.hide('addExistingAssetsToCatalog');
        return;
    }

    render() {
        const addSelectedItems = (
            <button
                className="btn btn-large bg-green"
                onClick={this.handleClickAddSelectedItems}
            >
                <i className="fas fa-plus"></i>&nbsp;{this.context.intl.messages['events.assets.add-assets']}
            </button>
        );

        const addExistingItemsPanel = (
            <AddExistingAssets
                displayLinks={false}
                showAssetType
                columns={['assetThumbnail', 'assetId', 'assetType', 'assetName', 'dateEntered', 'actions']}
            />
        );

        const title = <span>{this.context.intl.messages['catalogs.asset.add-assets']}&nbsp;<small>{this.props.catalog.get('name')}</small></span>;

        return (
            <SlidingPanel
                extraButtons={addSelectedItems}
                icon="fas fa-plus-circle"
                id="addExistingAssetsToCatalog"
                onCancel={this.handleCancelPanel}
                title={title}
            >
                {addExistingItemsPanel}
            </SlidingPanel>
        );
    }
}

export default AddExistingPanel;
