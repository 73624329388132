/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {RelatedConstants} from './related-actions';
import Dispatcher from '../../../dispatcher/dispatcher';
import {TitleConstants} from '../../title-actions';
import {TitleStatusActions} from '../../title-status-actions';

class RelatedStore extends ReduceStore {

    getInitialState() {
        let related = Immutable.fromJS({
            children: [],
            parents:[],
            other:[]
        });
        let state = Immutable.fromJS({
            childrenRelationshipTypes: TitleConstants.toArray(TitleConstants.TITLE_RELATIONSHIP_TYPE).filter(t => t.get('id') < 200),
            isLoading: false,
            parentsRelationshipTypes: TitleConstants.toArray(TitleConstants.TITLE_RELATIONSHIP_TYPE).filter(t => t.get('id') > 199),
            related:related,
            original:related
        });
        return state;
    }

    /**
     * From a title id, return true if the title is already
     * present in any of the title.related collections.
     */
    isRelatedTitle(id) {
        let related = this.getState().get('related');

        return related.get('children').concat(
            related.get('other'),
            related.get('parents')
        ).some(t => t.get('id') === id);
    }

    reduce(state, action) {
        switch (action.actionType) {
        case RelatedConstants.ADD:
            let title = action.title.set('relationshipType', action.relationshipType.get('id'));
            // Add the related title to the array it belongs.
            state = state.updateIn(
                ['related', action.relationName],
                relation => relation.push(title.set('orderWithinParent', relation.size + 1))
            );
            break;

        case RelatedConstants.CLEAR:
            // save related data if exists
            let titleAddNewRelated = state.get('titleAddNewRelated', null);
            state = this.getInitialState();
            if (titleAddNewRelated) {
                state = state.set('titleAddNewRelated', titleAddNewRelated);
            }
            break;

        case RelatedConstants.MOVE_ELEMENT:
            state = state.updateIn(['related', action.relation], relation => {
                // Get the element to move.
                let item = relation.get(action.from);
                /**
                 * 1) Remove the item from the array with splice (that will update the
                 *    other elements indexes).
                 * 2) Insert the removed item in its new position (that will update the
                 *    other elements indexes).
                 * 3) Re-assign the orderInTitle value for each release following the
                 *    new array order.
                 */
                return relation.splice(action.from, 1).insert(action.to, item).map((r, i) => {
                    return r.set(action.orderBy, i + 1);
                });
            });
            break;

        case RelatedConstants.REMOVE_ELEMENT:
            state = state.updateIn(['related', action.relation], relation => {
                /**
                 * Just remove the element at the desired index. Then re-assign
                 * the orderInTitle value for all remaining elements in the array.
                 */
                relation = relation.delete(action.index);
                if (action.orderBy) {
                    relation = relation.map((r, i) => {
                        return r.set(action.orderBy, i + 1);
                    });
                }

                return relation;
            });
            break;

        case RelatedConstants.GET.ERROR:
            state = state.set('isLoading', false);
            break;

        case RelatedConstants.GET.START:
            state = state.set('isLoading', true);
            break;

        case RelatedConstants.GET.SUCCESS:
            let related = Immutable.fromJS({
                children: action.children,
                parents: action.parents,
                other: action.other
            });
            state = state.merge({
                isLoading: false,
                related,
                original: related,
                titleId: action.titleId
            });

            if (state.get('titleAddNewRelated') && state.get('titleAddNewRelated').get('id')) {
                let relatedTitle = state.get('titleAddNewRelated');
                // add relation
                // Add the related title to the array it belongs.
                state = state.updateIn(
                    ['related', TitleStatusActions.findRelationTypeById(relatedTitle.get('relationshipType'))],
                    relation => relation.push(relatedTitle.set('orderWithinParent', relation.size + 1))
                );
                state = state.delete('titleAddNewRelated');
                break;
            }
            break;

        case TitleConstants.TITLE.RELATED.ADD_NEW:
            state = state.set('titleAddNewRelated', action.title);
            break;

        case TitleConstants.TITLE.CLONE:
            state = state.set('original', state.get('related'));
            break;

        }
        return state;
    }
}

let store = new RelatedStore(Dispatcher);
window.relatedStore = store;
export default store;
