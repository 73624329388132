/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {DOCUMENT_CT_TYPES} from '@wbdt-sie/brainiac-web-common';
import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {DocumentConstants} from './document-actions';
import Validations from '../../common/validations/validations';
import Dispatcher from '../../dispatcher/dispatcher';
import {ActionHistoryConstants} from '../../system/action-history/action-history-actions';


const DocumentAssetValidations = {
    name: {
        label: 'asset.document.name',
        validations: [Validations.max(200), Validations.required]
    },
    assetComment: {
        label: 'asset.document.assetComment',
        validations: [Validations.max(255)]
    },
    copyrightYear: {
        label: 'asset.document.copyright.year',
        validations: [Validations.max(4)]
    },
    copyrightText: {
        label: 'asset.document.copyright.text',
        validations: [Validations.max(200)]
    },
    documentType: {
        label: 'asset.document.contentType',
        validations: [Validations.required]
    },
    keyword: {
        label: 'asset.document.keywords',
        validations: [Validations.max(250)]
    },
    formatRunDate: {
        validations: [Validations.custom(
            function() {
                return 'Please select a Run Date.';
            },
            function() {
                let isValid = true;
                if (!DOCUMENT_CT_TYPES.isStationDocumentRunDateAllowed(store.getState().getIn(['asset', 'documentType']))) {
                    return isValid;
                }

                if (!store.getState().getIn(['asset', 'formatRunDate'])) {
                    isValid = false;
                }
                return isValid;
            }

        )]}

};

class DocumentStore extends ReduceStore {

    getContentType(documentSuperType, documentType) {
        if (documentSuperType === undefined || documentSuperType === null) {
            return;
        }

        const result = Object.keys(DOCUMENT_CT_TYPES.CONTENT_TYPES)
            .map(k => DOCUMENT_CT_TYPES.CONTENT_TYPES[k])
            .filter(ct => ct.id === documentSuperType)[0];

        if (documentType === undefined || documentType === null) {
            return Immutable.fromJS(result);
        }

        return Immutable.fromJS(
            Object.keys(result.subType)
                .map(k => result.subType[k])
                .filter(st => st.id === documentType)[0]
        );
    }

    getContentTypeAsString(subTypeId) {
        const allContentTypes = Object.keys(DOCUMENT_CT_TYPES.CONTENT_TYPES).reduce((result, key) => {
            return {...result, ...DOCUMENT_CT_TYPES.CONTENT_TYPES[key].subType};
        }, {});

        const contentTypeKey = Object.keys(allContentTypes).find(key => allContentTypes[key].id === subTypeId, {});
        return allContentTypes[contentTypeKey].name;
    }

    // with stations category as first option
    getContentTypeList() {
        let list = Immutable.fromJS(DOCUMENT_CT_TYPES.toArray('CONTENT_TYPES'));
        const stationCategory = list.find(item => item.get('id') === DOCUMENT_CT_TYPES.CONTENT_TYPES.STATION_DOCUMENT.id);
        const filteredList = list.filter(item => item.get('id') !== DOCUMENT_CT_TYPES.CONTENT_TYPES.STATION_DOCUMENT.id);
        list = filteredList.unshift(stationCategory);
        return list;
    }

    getInitialState() {
        return Immutable.Map({
            documentType: this.getContentTypeList(),
            asset: Immutable.Map({
                active: 1,
                files: []
            }),
            history: Immutable.List(),
            originalAsset: Immutable.Map({
                active: 1,
            }),
            originalDocument: null,
            showPreloader: false
        });
    }

    getValidations() {
        return Validations.validate(this.getState().get('asset'), DocumentAssetValidations);
    }

    reduce(state, action) {
        switch (action.actionType) {
        case ActionHistoryConstants.ADD_NOTE.SUCCESS:
            if ( action.actionObjectType === ActionHistoryConstants.ACTION_OBJECTS.DOCUMENT ) {
                state = state.updateIn(['history'], history => history.unshift(action.note));
            }
            break;

        case DocumentConstants.DOCUMENT.CLEAR:
            state = this.getInitialState();
            break;

        case DocumentConstants.DOCUMENT.GET.ERROR:
            state = state.merge({
                showPreloader: false
            });
            break;

        case DocumentConstants.DOCUMENT.GET.START:
            state = state.merge({
                showPreloader: true
            });
            break;

        case DocumentConstants.DOCUMENT.GET.SUCCESS:
            state = state.merge({
                asset: action.asset,
                history: action.history,
                originalAsset: action.asset,
                showPreloader: false
            });
            break;

        case DocumentConstants.DOCUMENT.UPDATE:
            state = state.setIn(['asset', ...action.attr.split('.')], action.value);
            break;

        case DocumentConstants.DOCUMENT.SAVE.ERROR:
            state = state.merge({
                showPreloader: false
            });
            break;

        case DocumentConstants.DOCUMENT.SAVE.START:
            state = state.merge({
                showPreloader: true
            });
            break;

        case DocumentConstants.DOCUMENT.SAVE.SUCCESS:
            state = state.setIn(['asset', 'id'], action.id);
            state = state.setIn(['asset', 'files'], []);
            state = state.merge({
                originalAsset: state.get('asset'),
                originalDocument: action.documentFile,
                showPreloader: false
            });
            break;

        }
        return state;
    }
}

const store = new DocumentStore(Dispatcher);

export default store;

export {DocumentAssetValidations};
