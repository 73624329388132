/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {BatchConstants} from '../../batch-actions';

import AddNewPanelContainer from '~/src/assets-tab/panels/add-new-panel';

export default class AddNewPanel extends Component {

    static get propTypes() {
        return {
            type: PropTypes.string.isRequired,
            batchName: PropTypes.string.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    render() {
        const addNewTitle = this.context.intl.messages['titles.create.assets.add-new.title'];

        let addPanel;
        switch (this.props.type.toUpperCase()) {
        case BatchConstants.BATCH_TYPES.DOCUMENT:
        case BatchConstants.BATCH_TYPES.IMAGE:
        case BatchConstants.BATCH_TYPES.VIDEO:
            addPanel = (
                <AddNewPanelContainer
                    addConstants={BatchConstants.ITEMS.ADD}
                    assetType={this.props.type.toLowerCase()}
                    headerTitle={addNewTitle}
                    title={this.props.batchName}
                />
            );
            break;
        }

        return (
            <div>
                {addPanel}
            </div>
        );
    }
}
